import React from 'react';
import { Button, Text } from '@wix/wix-base-ui';
import { connectWithScope, type InferComponentProps } from '@/apilib';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '@/i18n';
import { PromoteScope } from '../../../scope';
import { MarketingPanel } from '../common/marketingPanel';
import promoteBi from '../common/bi/promoteBi';

export interface OwnProps {}

type FacebookAdsPanelProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  OwnProps
>;

const TAB_NAME = 'FacebookAdsPanel';
const PRODUCT_NAME = 'Facebook&InstagramAds';

const FacebookAdsPanel = React.memo<FacebookAdsPanelProps>(
  (props: FacebookAdsPanelProps) => {
    React.useEffect(() => {
      props.sendBi(promoteBi.events.TAB_VIEW, {
        ...props.biBaseParams,
        tab_name: TAB_NAME,
        product_name: PRODUCT_NAME,
      });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onPressCTA = () => {
      props.sendBi(promoteBi.events.PRODUCT_CLICK, {
        ...props.biBaseParams,
        tab_name: TAB_NAME,
        product_name: PRODUCT_NAME,
      });
      props.navigateToFacebookAds();
    };

    return (
      <MarketingPanel
        logo={
          <symbols.symbol name="promoteFacebookAdsPanelLogo" className="logo" />
        }
        title={
          <Text
            skin="standard"
            size="large"
            weight="bold"
            enableEllipsis={false}
          >
            {'Promote_Facebook_Ads_Title'}
          </Text>
        }
        body={
          <Text skin="standard" size="small" enableEllipsis={false}>
            {'Promote_Facebook_Ads_Description'}
          </Text>
        }
        actions={
          <Button onClick={onPressCTA} className="btn-md">
            {translate('Promote_Facebook_Ads_MainButton')}
          </Button>
        }
      />
    );
  },
);

const mapStateToProps = (scope: PromoteScope) => {
  const biBaseParams = promoteBi.biUtils.getBiBaseParams(scope);

  return {
    biBaseParams,
    msid: scope.editorAPI.dsRead.generalInfo.getMetaSiteId(),
  };
};

const mapDispatchToProps = (scope: PromoteScope) => {
  return {
    navigateToFacebookAds: () =>
      scope.editorAPI.account.openSettings({ path: '/paid-ads' }),
    sendBi: (event: ValueOf<typeof promoteBi.events>, parameters: object) => {
      promoteBi.report(event, parameters);
    },
  };
};

FacebookAdsPanel.displayName = 'FacebookAdsPanel';

export default connectWithScope(
  () => PromoteScope,
  FacebookAdsPanel,
  mapStateToProps,
  mapDispatchToProps,
);
