import type { EditorAPI } from '@/editorAPI';

import type { CompRef } from 'types/documentServices';
import type {
  EditorMode,
  SelectionData,
  SelectionType,
  SkinSchema,
} from './skins.types';

export const getCurrentEditorMode = (editorAPI: EditorAPI): EditorMode => {
  const isZoomMode = editorAPI.zoomMode.isInZoomMode();

  if (editorAPI.isMobileEditor()) {
    return isZoomMode ? 'mobileZoomOut' : 'mobile';
  }

  if (editorAPI.zoomMode.isLeftShrinkedStageZoomOutActive()) {
    return 'desktopLeftShrinked';
  }

  if (editorAPI.zoomMode.isRightShrinkedStageZoomOutActive()) {
    return 'desktopRightShrinked';
  }

  return isZoomMode ? 'desktopZoomOut' : 'desktop';
};

export const getSectionLikeSelectionType = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  {
    hoveredSectionLike,
    selectedSectionLike,
    focusedSectionLike,
  }: SelectionData,
): SelectionType => {
  const isHovered = editorAPI.utils.isSameRef(compRef, hoveredSectionLike);
  const isSelected = editorAPI.utils.isSameRef(compRef, selectedSectionLike);
  const isChildSelected =
    focusedSectionLike &&
    !isSelected &&
    editorAPI.utils.isSameRef(compRef, focusedSectionLike);

  if (isSelected) {
    return 'selected';
  }
  if (isChildSelected) {
    return 'focused';
  }

  return isHovered ? 'hovered' : undefined;
};

export const pickSkinFromSchema = <S extends SkinSchema<any>>(
  schema: S,
  mode: EditorMode,
  selectionType: SelectionType,
): S extends SkinSchema<infer T> ? T : never => {
  return schema[mode]?.[selectionType];
};
