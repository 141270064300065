// @ts-nocheck
import _ from 'lodash';

import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';

import { getSupportedTabs } from '@/advancedStylePanel';

import { isAppWidget } from '@/documentServices';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import {
  getOnPresetChanged,
  getNotifyPresetChanged,
} from './widgetPresetsUtils';

const { boxSlideShowUtils } = util;
const { isBoxSlideShowType, getComponentsInCurrentSlide } = boxSlideShowUtils;

const MULTI_COMP_STYLE_PANEL =
  'advancedStylePanel.multiComponentAdvancedStylePanel';
const WIDGET_DESIGN_COMP_STYLE_PANEL =
  'advancedStylePanel.widgetDesignAdvancedStylePanel';

const getVisibleComponents = (
  containerRef: CompRef,
  groupedComponents: CompRef[],
  isBoxSlideShowType,
  getComponentsInCurrentSlide,
) => {
  if (isBoxSlideShowType(containerRef)) {
    const componentsInCurrentSlide = getComponentsInCurrentSlide(containerRef);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _.filter(
      groupedComponents,
      (comp) =>
        comp.id === containerRef.id ||
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find
        _.find(componentsInCurrentSlide, ['id', comp.id]),
    );
  }

  return groupedComponents;
};

const getControllerRef = (editorAPI: EditorAPI, compRef: CompRef) => {
  return editorAPI.components.findAncestor(
    compRef,
    (ancestorRef) => isAppWidget(editorAPI.dsRead, ancestorRef),
    {
      includeScopeOwner: true,
    },
  );
};
const getContainerRef = (editorAPI: EditorAPI, controllerRef: CompRef) =>
  editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(controllerRef)[0];

const getCompRole = (editorAPI, compRef) => {
  return editorAPI.dsRead.platform.controllers.connections.getPrimaryConnection(
    compRef,
  )?.role;
};
const getAllComponentsWithoutRef = (editorAPI, root) => {
  return editorAPI.dsRead.deprecatedOldBadPerformanceApis.components
    .getChildrenFromFull(root, true)
    .filter((c) => {
      return (
        editorAPI.components.getType(c) !==
        'wysiwyg.viewer.components.RefComponent'
      );
    });
};

const getConnectedComponents = (editorAPI, controllerRef) => {
  if (isAppWidget(editorAPI.dsRead, controllerRef)) {
    return getAllComponentsWithoutRef(editorAPI, controllerRef);
  }

  return editorAPI.dsRead.platform.controllers.connections.getConnectedComponents(
    controllerRef,
    true,
  );
};

const compIsIncludedInGroup = (editorAPI, compRef, groups) => {
  const { compTypes = [], roles = [] } = groups;
  return (
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(compTypes, editorAPI.components.getType(compRef)) ||
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(roles, getCompRole(editorAPI, compRef))
  );
};

const findTabOfComponent = (
  editorAPI,
  compRef,
  widgetDesign,
  connectedComponents,
) => {
  const supportedTabs = getSupportedTabs(
    editorAPI,
    widgetDesign.tabs,
    connectedComponents,
  );
  return supportedTabs.find((tab) =>
    compIsIncludedInGroup(editorAPI, compRef, tab.groups),
  );
};

const selectWidget = (editorAPI, compRef, controllerRef) => {
  editorAPI.selection.deselectComponents(compRef);
  if (
    !editorAPI.components.is.selectable(controllerRef) &&
    editorAPI.components.is.controlledByParent(controllerRef)
  ) {
    const containerRef =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
        controllerRef,
      );
    editorAPI.selection.selectComponentByCompRef(containerRef);
  } else {
    editorAPI.selection.selectComponentByCompRef(controllerRef);
  }
};

const getWidgetDesignData = (editorAPI, controllerRef) => {
  if (!controllerRef) {
    return;
  }

  const deviceType = stateManagement.mobile.selectors.getDeviceType(
    editorAPI.store.getState(),
  );

  const gfppDataFromApp =
    editorAPI.dsRead.platform.controllers.getStageData(controllerRef)?.gfpp;
  const widgetDesignDataFromApp = _.get(
    gfppDataFromApp,
    `${deviceType}.widgetDesign`,
  );

  if (widgetDesignDataFromApp) {
    const containerRef = getContainerRef(editorAPI, controllerRef);
    return _.defaults({}, widgetDesignDataFromApp, {
      tabs: getDefaultTab(editorAPI, containerRef),
    });
  }
};

const getTabLabelAndCompsInGroup = (
  editorAPI,
  controllerRef,
  compRef,
  widgetDesign,
) => {
  const connectedComponents = getConnectedComponents(editorAPI, controllerRef);
  const tab = findTabOfComponent(
    editorAPI,
    compRef,
    widgetDesign,
    connectedComponents,
  );
  if (!tab) {
    return {};
  }

  const { label, groups } = tab;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  const componentsInGroup = _.filter(connectedComponents, (compRef) =>
    compIsIncludedInGroup(editorAPI, compRef, groups),
  );
  return {
    label,
    componentsInGroup,
  };
};

function openEmptyGlobalDesign(editorAPI, title, notifyPresetChanged) {
  editorAPI.panelManager.openComponentPanel(
    MULTI_COMP_STYLE_PANEL,
    {
      selectedComponent: [],
      groupMapping: {},
      title,
      keyByComp: {},
      onPresetChanged: notifyPresetChanged,
      shouldTranslate: false,
    },
    true,
  );
}

function openGlobalDesign(
  globalDesign,
  editorAPI,
  notifyPresetChanged,
  controllerRef,
) {
  if (_.isEmpty(globalDesign.groupMapping)) {
    return openEmptyGlobalDesign(
      editorAPI,
      globalDesign.title,
      notifyPresetChanged,
    );
  }
  const connectionsStageData =
    editorAPI.dsRead.platform.controllers.getStageData(
      controllerRef,
    ).connections;
  const rolesWithDesignMapping = _.pickBy(
    connectionsStageData,
    'designMapping',
  );

  const connectedComponents = getConnectedComponents(editorAPI, controllerRef);
  const designMapping = connectedComponents.reduce((acc, comp) => {
    const role = getCompRole(editorAPI, comp);
    const type = editorAPI.dsRead.components.getType(comp);
    const mapping = rolesWithDesignMapping?.[role]?.designMapping?.[type];
    if (mapping) {
      acc[comp.id] = mapping;
    }
    return acc;
  }, {});

  editorAPI.panelManager.openComponentPanel(
    MULTI_COMP_STYLE_PANEL,
    {
      groupMapping: globalDesign.groupMapping,
      presets: globalDesign.presets,
      title: globalDesign.title,
      presetsTitle: globalDesign.presetsTitle,
      onPresetChanged: notifyPresetChanged,
      designMapping,
      customHelpId: globalDesign.customHelpId || globalDesign.helpId,
      presetHelpId: globalDesign.presetHelpId || globalDesign.helpId,
      shouldTranslate: false,
    },
    true,
  );
}

const getDefaultTab = (editorAPI, containerRef) => {
  const containerRole = getCompRole(editorAPI, containerRef);
  return [
    {
      label: translate('Design_Presets_Widget_Design_Panel_Section_Name1'),
      groups: { roles: [containerRole] },
    },
  ];
};

function openEmptyWidgetDesign(
  editorAPI,
  widgetDesign,
  notifyPresetChanged,
  containerRef,
  connectedComponents,
) {
  if (widgetDesign.tabs?.length === 0) {
    widgetDesign.tabs = getDefaultTab(editorAPI, containerRef);
  }

  const widgetDesignProps = _.defaults({}, widgetDesign, {
    tabs: getDefaultTab(editorAPI, containerRef),
    onPresetChanged: notifyPresetChanged,
    customHelpId: widgetDesign.helpId,
    presetHelpId: widgetDesign.helpId,
    shouldTranslate: false,
    connectedComponents,
  });
  return editorAPI.panelManager.openComponentPanel(
    WIDGET_DESIGN_COMP_STYLE_PANEL,
    widgetDesignProps,
    true,
  );
}

function openWidgetDesign(
  widgetDesign,
  editorAPI,
  onPresetChanged,
  controllerRef,
) {
  const containerRef = getContainerRef(editorAPI, controllerRef);
  const connectedComponents = getConnectedComponents(editorAPI, controllerRef);
  if (_.isEmpty(widgetDesign.tabs)) {
    return openEmptyWidgetDesign(
      editorAPI,
      widgetDesign,
      onPresetChanged,
      containerRef,
      connectedComponents,
    );
  }

  const widgetDesignProps = getWidgetDesignProps(
    widgetDesign,
    controllerRef,
    editorAPI,
    onPresetChanged,
  );

  return editorAPI.panelManager.openComponentPanel(
    WIDGET_DESIGN_COMP_STYLE_PANEL,
    widgetDesignProps,
    true,
  );
}

function getWidgetDesignProps(
  widgetDesign,
  controllerRef,
  editorAPI,
  onPresetChangedFunction?,
) {
  const onPresetChanged =
    onPresetChangedFunction || getOnPresetChanged(editorAPI, controllerRef);
  const containerRef = getContainerRef(editorAPI, controllerRef);
  const connectedComponents = getConnectedComponents(editorAPI, controllerRef);
  return _.defaults({}, widgetDesign, {
    onPresetChanged,
    customHelpId: widgetDesign.helpId,
    presetHelpId: widgetDesign.helpId,
    shouldTranslate: false,
    connectedComponents,
    containerRef,
  });
}
function openComponentLevelWidgetDesign(
  widgetDesign,
  editorAPI,
  controllerRef,
  compRef?,
) {
  const onPresetChanged = getOnPresetChanged(editorAPI, controllerRef);
  if (compRef) {
    _.set(widgetDesign, 'selectedInnerComponent', compRef);
  }
  return openWidgetDesign(
    widgetDesign,
    editorAPI,
    onPresetChanged,
    controllerRef,
  );
}

function onNotificationLinkClick(
  widgetDesign,
  editorAPI,
  controllerRef,
  compRef,
) {
  selectWidget(editorAPI, compRef, controllerRef);
  return openComponentLevelWidgetDesign(widgetDesign, editorAPI, controllerRef);
}

function triggerWidgetDesignNotification(
  editorAPI: EditorAPI,
  compRef: object,
) {
  const controllerRef = getControllerRef(editorAPI, compRef);
  const widgetDesign = getWidgetDesignData(editorAPI, controllerRef);
  if (!widgetDesign) {
    return;
  }

  _.set(widgetDesign, 'selectedInnerComponent', compRef);
  const tabLabelAndCompsInGroup = getTabLabelAndCompsInGroup(
    editorAPI,
    controllerRef,
    compRef,
    widgetDesign,
  );

  if (
    _.isEmpty(tabLabelAndCompsInGroup) ||
    tabLabelAndCompsInGroup?.componentsInGroup.length <= 1
  ) {
    return;
  }

  const { label } = tabLabelAndCompsInGroup;
  const displayName = editorAPI.components.getDisplayName(compRef);
  const caption = translate(
    'Notifications_AppWidget_ApplyDesignToLikeComponents_Link',
    {
      groupName: label,
    },
  );
  const message = translate(
    'Notifications_AppWidget_ApplyDesignToLikeComponents_Text',
    {
      compLabel: _.lowerCase(displayName),
      groupName: _.lowerCase(label),
    },
  );

  const notification = {
    message,
    title: 'Widget Design',
    type: 'info',
    link: {
      caption,
      onNotificationLinkClick: onNotificationLinkClick.bind(
        null,
        widgetDesign,
        editorAPI,
        controllerRef,
        compRef,
      ),
    },
    shouldTranslate: false,
  };

  return editorAPI.store.dispatch(
    stateManagement.notifications.actions.showUserActionNotification(
      notification,
    ),
  );
}

function openWidgetLevelWidgetDesign(widgetDesign, editorAPI, controllerRef) {
  const notifyPresetChanged = getNotifyPresetChanged(editorAPI, controllerRef);
  if (widgetDesign.groupMapping) {
    return openGlobalDesign(
      widgetDesign,
      editorAPI,
      notifyPresetChanged,
      controllerRef,
    );
  }

  const onPresetChanged = getOnPresetChanged(editorAPI, controllerRef);

  return openWidgetDesign(
    widgetDesign,
    editorAPI,
    onPresetChanged,
    controllerRef,
  );
}

function openWidgetDesignFromRcm(
  widgetDesign,
  editorAPI,
  controllerRef,
  compRef,
) {
  selectWidget(editorAPI, compRef, controllerRef);
  return openWidgetLevelWidgetDesign(widgetDesign, editorAPI, controllerRef);
}

const getWidgetDesignRcmItems = (
  editorAPI,
  designItem,
  firstControllableComponents,
  rightClickMenuOrigin,
) => {
  const compRef = _.head(firstControllableComponents);
  const controllerRef = getControllerRef(editorAPI, compRef);
  const widgetDesign = getWidgetDesignData(editorAPI, controllerRef);
  if (!widgetDesign) {
    return;
  }
  const tabLabelAndCompsInGroup = getTabLabelAndCompsInGroup(
    editorAPI,
    controllerRef,
    compRef,
    widgetDesign,
  );

  if (_.isEmpty(tabLabelAndCompsInGroup)) {
    return;
  }

  const { label, componentsInGroup } = tabLabelAndCompsInGroup;
  const containerRef = getContainerRef(editorAPI, controllerRef);
  const visibleComponents = getVisibleComponents(
    containerRef,
    componentsInGroup,
    isBoxSlideShowType.bind(null, editorAPI.dsRead),
    getComponentsInCurrentSlide.bind(null, editorAPI.dsRead),
  );
  const componentType = editorAPI.components.getType(
    firstControllableComponents,
  );
  const displayName = editorAPI.components.getDisplayName(compRef);

  const getOnMouseEnterFn = (compRefs) => {
    const {
      locateComponentAndHighlightMultiComponents,
      locateComponentAndHighlight,
    } = stateManagement.componentLocator.actions;
    const action = Array.isArray(compRefs)
      ? locateComponentAndHighlightMultiComponents
      : locateComponentAndHighlight;
    return () => {
      editorAPI.store.dispatch(action(compRefs, false));
    };
  };

  const removeHighlights = (compRefs) => {
    editorAPI.store.dispatch(
      stateManagement.highlights.actions.removeCompsFromHighlights(compRefs),
    );
  };

  const getOnMouseLeaveFn = (compRefs) => () => removeHighlights(compRefs);
  const getOnClickFn = (callback, selectedComponents, ...args) => {
    return () => {
      callback(...args);
      editorAPI.bi.event(coreBi.events.actions.CLICK, {
        action_name: designItem.tooltip,
        component_id: firstControllableComponents[0]?.id,
        component_type: componentType,
        origin: rightClickMenuOrigin,
      });
      editorAPI.closeRightClickMenu();
      removeHighlights(selectedComponents);
    };
  };

  const getSubItem = (label, onClick, onMouseEnter, onMouseLeave) => ({
    enabled: true,
    label,
    onClick,
    onMouseEnter,
    onMouseLeave,
    shouldTranslate: false,
  });

  const defaultOnClick = getOnClickFn(
    designItem.onClick,
    firstControllableComponents,
    editorAPI,
    firstControllableComponents,
    { preventNotificationTrigger: true },
  );
  const componentLevelOnClick = getOnClickFn(
    openComponentLevelWidgetDesign,
    visibleComponents,
    widgetDesign,
    editorAPI,
    controllerRef,
    compRef,
  );
  const widgetLevelOnClick = getOnClickFn(
    openWidgetDesignFromRcm,
    [containerRef],
    widgetDesign,
    editorAPI,
    controllerRef,
    compRef,
  );

  const defaultItem = getSubItem(
    translate('PLATFORM_Widget_Design_Right_Click_Menu_Design_list1', {
      Component_Label: displayName,
    }),
    defaultOnClick,
    getOnMouseEnterFn(compRef),
    getOnMouseLeaveFn(compRef),
  );

  const componentLevelItem = getSubItem(
    translate('PLATFORM_Widget_Design_Right_Click_Menu_Design_list2', {
      Group_Name: label,
    }),
    componentLevelOnClick,
    getOnMouseEnterFn(visibleComponents),
    getOnMouseLeaveFn(visibleComponents),
  );
  const widgetLevelItem = getSubItem(
    translate('PLATFORM_Widget_Design_Right_Click_Menu_Design_list3', {
      Widget_Name: widgetDesign?.title,
    }),
    widgetLevelOnClick,
    getOnMouseEnterFn(containerRef),
    getOnMouseLeaveFn(containerRef),
  );

  return [defaultItem, componentLevelItem, widgetLevelItem];
};

export {
  openWidgetLevelWidgetDesign,
  openComponentLevelWidgetDesign,
  triggerWidgetDesignNotification,
  getWidgetDesignRcmItems,
  getVisibleComponents,
  WIDGET_DESIGN_COMP_STYLE_PANEL,
  getWidgetDesignProps,
};
