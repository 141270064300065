'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

const moreBehaviorHelper = require("./moreBehaviorHelper")
module.exports =
    {
        "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
        "hide": false,
        "title": "add_section_label_arrows",
        "subNavigationTitle": "add_section_label_arrows",
        "presetTitle": "add_section_label_arrows",
        "tooltipTitle": "add_section_label_arrows",
        "automationId": "add-panel-section-arrowsSection",
        "subNavigationHide": false,
        "showSectionHeader": true,
        "additionalBehaviours": {
            "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
            "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
            "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
            "iconEnabledForComps": {}
        },
        "props": {
            "image": "addPanelData/sections/arrowsSection_en/arrowsSection_en.png",
            "imageHover": null,
            "items": [{
                "id": "Shape_Arrows_1",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 75,
                        "height": 93,
                        "x": 14,
                        "y": 31,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "a3c153_03baee92ba004374873f770e61a41698.svg",
                        "title": "Arrows -43.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#342216"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dlw"
                },
                "preset": {"rect": {"width": 53, "height": 78, "x": 0, "y": 0}, "tags": null}
            }, {
                "id": "Shape_Arrows_2",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 90,
                        "height": 90,
                        "x": 64,
                        "y": 31,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "svgshape.v2.Svg_a043452a96a74b3aaf7a7f9a1395e250"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dm3"
                },
                "preset": {"rect": {"width": 50, "height": 77, "x": 53, "y": 0}, "tags": null}
            }, {
                "id": "Shape_Arrows_3",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 93,
                        "height": 93,
                        "x": 119,
                        "y": 31,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "svgshape.v2.Svg_9edf125d86ca4b349b5d104ec7a3efe8"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dm9"
                },
                "preset": {"rect": {"width": 65, "height": 77, "x": 103, "y": 0}, "tags": null}
            }, {
                "id": "Shape_Arrows_4",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 84,
                        "height": 93,
                        "x": 174,
                        "y": 30,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "svgshape.v2.Svg_923620d1cde24954af6af57a4798e2b1"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dme"
                },
                "preset": {"rect": {"width": 53, "height": 78, "x": 168, "y": 0}, "tags": null}
            }, {
                "id": "Shape_Arrows_5",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 81,
                        "height": 93,
                        "x": 230,
                        "y": 30,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "svgshape.v2.Svg_f855e181b2a84756a5ce7604a0c2292e"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dmk"
                },
                "preset": {"rect": {"width": 50, "height": 78, "x": 221, "y": 0}, "tags": null}
            }, {
                "id": "Shape_Arrows_6",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 81,
                        "height": 93,
                        "x": 283,
                        "y": 30,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "svgshape.v2.Svg_c03816afbe984f118968803a5d5fd27f"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dmx"
                },
                "preset": {"rect": {"width": 53, "height": 77, "x": 271, "y": 1}, "tags": null}
            }, {
                "id": "Shape_Arrows_7",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 129,
                        "height": 51,
                        "x": 5,
                        "y": 107,
                        "scale": 1,
                        "rotationInDegrees": 90,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "svgshape.v2.Svg_7f173714b5fc41b0a56b9576fee4a480"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dn3"
                },
                "preset": {"rect": {"width": 53, "height": 77, "x": 0, "y": 77}, "tags": null}
            }, {
                "id": "Shape_Arrows_8",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 129,
                        "height": 99,
                        "x": 59,
                        "y": 110,
                        "scale": 1,
                        "rotationInDegrees": 90,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "svgshape.v2.Svg_61ff849ad9a548f7bd6828909987ef06"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dnc"
                },
                "preset": {"rect": {"width": 50, "height": 77, "x": 53, "y": 77}, "tags": null}
            }, {
                "id": "Shape_Arrows_9",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 42,
                        "height": 126,
                        "x": 126,
                        "y": 95,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "9a75abcd83524a1a9b048847ed30cd3b.svg",
                        "title": "Arrow   "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dnj"
                },
                "preset": {"rect": {"width": 65, "height": 76, "x": 103, "y": 77}, "tags": null}
            }, {
                "id": "Shape_Arrows_10",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 63,
                        "height": 132,
                        "x": 184,
                        "y": 94,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "b8e01ab6fa3046a2a53e7c166b95828c.svg",
                        "title": "Thin Arrow"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dnr"
                },
                "preset": {"rect": {"width": 53, "height": 76, "x": 168, "y": 78}, "tags": null}
            }, {
                "id": "Shape_Arrows_11",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 63,
                        "height": 129,
                        "x": 233,
                        "y": 94,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "2aee660a836d4a4b99dfebd0dae620e5.svg",
                        "title": "Arrow    "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dnz"
                },
                "preset": {"rect": {"width": 50, "height": 76, "x": 221, "y": 78}, "tags": null}
            }, {
                "id": "Shape_Arrows_12",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 54,
                        "height": 129,
                        "x": 288,
                        "y": 94,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "61206f30f8e148189388716d93868471.svg",
                        "title": "Arrow Outline"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6do8"
                },
                "preset": {"rect": {"width": 53, "height": 75, "x": 271, "y": 78}, "tags": null}
            }, {
                "id": "Shape_Arrows_13",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 78,
                        "height": 129,
                        "x": 13,
                        "y": 169,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "6493f44d3cfb4aea9d56d2259c41ed30.svg",
                        "title": "Arrow     "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#566FB8"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6doj"
                },
                "preset": {"rect": {"width": 53, "height": 73, "x": -1, "y": 154}, "tags": null}
            }, {
                "id": "Shape_Arrows_14",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 72,
                        "height": 129,
                        "x": 67,
                        "y": 169,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "af74919ab6a7498abd5be02f9c87517e.svg",
                        "title": "Curved End Arrow"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#566FB8"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dov"
                },
                "preset": {"rect": {"width": 50, "height": 73, "x": 53, "y": 154}, "tags": null}
            }, {
                "id": "Shape_Arrows_15",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 78,
                        "height": 129,
                        "x": 121,
                        "y": 169,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "933393f060fc4c94a0648c0190b6217b.svg",
                        "title": "Parallel Lines Arrow"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#566FB8"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dp5"
                },
                "preset": {"rect": {"width": 56, "height": 73, "x": 103, "y": 154}, "tags": null}
            }, {
                "id": "Shape_Arrows_16",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 42,
                        "height": 129,
                        "x": 181,
                        "y": 169,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "f2a07043faf24420913908d84b0f5e00.svg",
                        "title": "Feather Arrow"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#566FB8"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dpl"
                },
                "preset": {"rect": {"width": 63, "height": 73, "x": 159, "y": 154}, "tags": null}
            }, {
                "id": "Shape_Arrows_17",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 51,
                        "height": 129,
                        "x": 235,
                        "y": 169,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "27995c2be8db45a686390302b7a3d05c.svg",
                        "title": "Arrow      "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#566FB8"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dpv"
                },
                "preset": {"rect": {"width": 50, "height": 73, "x": 221, "y": 154}, "tags": null}
            }, {
                "id": "Shape_Arrows_18",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 42,
                        "height": 129,
                        "x": 290,
                        "y": 169,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "dc2a2000287b402595d55ee6d09df516.svg",
                        "title": "Arrow       "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#566FB8"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dq5"
                },
                "preset": {"rect": {"width": 53, "height": 73, "x": 271, "y": 154}, "tags": null}
            }, {
                "id": "Shape_Arrows_19",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 84,
                        "height": 48,
                        "x": 12,
                        "y": 246,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "55a01cd53daf43459ec43967a1e316d7.svg",
                        "title": "Arrow Head"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#B45AD3"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dqf"
                },
                "preset": {"rect": {"width": 53, "height": 52, "x": -1, "y": 227}, "tags": null}
            }, {
                "id": "Shape_Arrows_20",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 90,
                        "height": 48,
                        "x": 64,
                        "y": 246,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "820eb164c7ca4456bd04e94ef847042e.svg",
                        "title": "Arrow Head "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#B45AD3"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dqr"
                },
                "preset": {"rect": {"width": 50, "height": 52, "x": 52, "y": 227}, "tags": null}
            }, {
                "id": "Shape_Arrows_21",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 87,
                        "height": 66,
                        "x": 119,
                        "y": 243,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "76a984d82d1343c09cc9d0a149ad8523.svg",
                        "title": "Arrow Lines"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#B45AD3"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dr1"
                },
                "preset": {"rect": {"width": 65, "height": 52, "x": 102, "y": 227}, "tags": null}
            }, {
                "id": "Shape_Arrows_22",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 87,
                        "height": 57,
                        "x": 174,
                        "y": 244,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "794720bd22ae4d1ba58b15a0440cb8f9.svg",
                        "title": "Arrow Head Outline"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#B45AD3"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6drd"
                },
                "preset": {"rect": {"width": 54, "height": 53, "x": 167, "y": 227}, "tags": null}
            }, {
                "id": "Shape_Arrows_23",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 87,
                        "height": 69,
                        "x": 229,
                        "y": 242,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "e90849a9b3ca4cb4ba3c73b7552af18a.svg",
                        "title": "Arrow Head"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#B45AD3"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6drp"
                },
                "preset": {"rect": {"width": 50, "height": 52, "x": 220, "y": 227}, "tags": null}
            }, {
                "id": "Shape_Arrows_24",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 90,
                        "height": 66,
                        "x": 282,
                        "y": 243,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "c27e8bb389234ad292ae27b8bb6561ac.svg",
                        "title": "Arrow Triangle"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#B45AD3"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6ds6"
                },
                "preset": {"rect": {"width": 53, "height": 52, "x": 270, "y": 227}, "tags": null}
            }, {
                "id": "Shape_Arrows_25",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 99,
                        "height": 99,
                        "x": 10,
                        "y": 295,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "ac3b6fba8be04938b5f46dd4ab7a078f.svg",
                        "title": "Arrow Icon"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#663898"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dsj"
                },
                "preset": {"rect": {"width": 53, "height": 64, "x": -1, "y": 279}, "tags": null}
            }, {
                "id": "Shape_Arrows_26",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 96,
                        "height": 96,
                        "x": 64,
                        "y": 296,
                        "scale": 1,
                        "rotationInDegrees": 180,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "2e075d51aae7437ab3e94aea6652d0ca.svg",
                        "title": "Down Arrow in Circle"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#663898"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dsv"
                },
                "preset": {"rect": {"width": 50, "height": 64, "x": 53, "y": 279}, "tags": null}
            }, {
                "id": "Shape_Arrows_27",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 93,
                        "height": 96,
                        "x": 118,
                        "y": 296,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "4f8aa3763742476c926e9529fcbf8186.svg",
                        "title": "Up Arrow "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#663898"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dt9"
                },
                "preset": {"rect": {"width": 56, "height": 64, "x": 102, "y": 279}, "tags": null}
            }, {
                "id": "Shape_Arrows_28",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 96,
                        "height": 96,
                        "x": 172,
                        "y": 296,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "61d88723a33940068e18dbe4731032fc.svg",
                        "title": "Arrow in Square "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#663898"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dtm"
                },
                "preset": {"rect": {"width": 63, "height": 64, "x": 158, "y": 279}, "tags": null}
            }, {
                "id": "Shape_Arrows_29",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 96,
                        "height": 96,
                        "x": 228,
                        "y": 296,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "feb973bf5ddb495a8bd69bf34d625656.svg",
                        "title": "Arrow in Square  "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#663898"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dtz"
                },
                "preset": {"rect": {"width": 50, "height": 64, "x": 221, "y": 279}, "tags": null}
            }, {
                "id": "Shape_Arrows_30",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 93,
                        "height": 96,
                        "x": 281,
                        "y": 296,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "8657879fb4c84826a9243a519bee3def.svg",
                        "title": "Arrow in Square    "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#663898"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dui"
                },
                "preset": {"rect": {"width": 53, "height": 64, "x": 270, "y": 279}, "tags": null}
            }, {
                "id": "Shape_Arrows_31",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 96,
                        "height": 99,
                        "x": 10,
                        "y": 362,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "32981cec0a334a0dbba3f0e613cf5c1e.svg",
                        "title": "Arrow in Square   "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FF4F4F"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6duw"
                },
                "preset": {"rect": {"width": 53, "height": 63, "x": 0, "y": 343}, "tags": null}
            }, {
                "id": "Shape_Arrows_32",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 99,
                        "height": 99,
                        "x": 63,
                        "y": 362,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "fe8e69b55eee4793aa838c998d7faf66.svg",
                        "title": "Arrow in Circle"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FF4F4F"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dvb"
                },
                "preset": {"rect": {"width": 50, "height": 63, "x": 53, "y": 343}, "tags": null}
            }, {
                "id": "Shape_Arrows_33",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 99,
                        "height": 99,
                        "x": 117,
                        "y": 362,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "0bc85b0e5e8d4615a1544fa209aa2959.svg",
                        "title": "Arrow in Circle "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FF4F4F"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dvq"
                },
                "preset": {"rect": {"width": 56, "height": 63, "x": 103, "y": 343}, "tags": null}
            }, {
                "id": "Shape_Arrows_34",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 99,
                        "height": 99,
                        "x": 172,
                        "y": 362,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "cfd032f1f656402f8b11b85992bc35f5.svg",
                        "title": "Arrow in Square"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FF4F4F"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dw6"
                },
                "preset": {"rect": {"width": 61, "height": 63, "x": 158, "y": 343}, "tags": null}
            }, {
                "id": "Shape_Arrows_35",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 99,
                        "height": 99,
                        "x": 227,
                        "y": 362,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "82ebb3135bbb49829e0257b696e437bc.svg",
                        "title": "Straight Arrow"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FF4F4F"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dwr"
                },
                "preset": {"rect": {"width": 50, "height": 63, "x": 219, "y": 343}, "tags": null}
            }, {
                "id": "Shape_Arrows_36",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 99,
                        "height": 99,
                        "x": 281,
                        "y": 362,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "d5d385b830624c0bb4be383a13221bca.svg",
                        "title": "Arrow Head Icon"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FF4F4F"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dx9"
                },
                "preset": {"rect": {"width": 54, "height": 63, "x": 269, "y": 343}, "tags": null}
            }, {
                "id": "Shape_Arrows_37",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 93,
                        "height": 60,
                        "x": 11,
                        "y": 428,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "fdd83ccdb151429d9154b2a150f34273.svg",
                        "title": "Arrow     "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#83E6D4"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dxq"
                },
                "preset": {"rect": {"width": 53, "height": 53, "x": -1, "y": 406}, "tags": null}
            }, {
                "id": "Shape_Arrows_38",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 93,
                        "height": 69,
                        "x": 65,
                        "y": 426,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "6ff7685340a54920a414066e588a75d3.svg",
                        "title": "Triangle Arrow "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#83E6D4"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dy5"
                },
                "preset": {"rect": {"width": 50, "height": 53, "x": 52, "y": 406}, "tags": null}
            }, {
                "id": "Shape_Arrows_39",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 93,
                        "height": 69,
                        "x": 118,
                        "y": 426,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "aab041befffb45bfb9061529a268e9a4.svg",
                        "title": "Squares Arrow"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#83E6D4"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dyn"
                },
                "preset": {"rect": {"width": 56, "height": 53, "x": 102, "y": 406}, "tags": null}
            }, {
                "id": "Shape_Arrows_40",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 93,
                        "height": 57,
                        "x": 173,
                        "y": 428,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "9b3f41d349624bfd9b407cd70535962e.svg",
                        "title": "Rounded Arrow "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#83E6D4"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dza"
                },
                "preset": {"rect": {"width": 62, "height": 53, "x": 158, "y": 406}, "tags": null}
            }, {
                "id": "Shape_Arrows_41",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 81,
                        "height": 72,
                        "x": 230,
                        "y": 426,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "0fb3d78e88c14eabbc5daf7d3d9bba81.svg",
                        "title": "Abstract Arrow"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#83E6D4"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6dzs"
                },
                "preset": {"rect": {"width": 50, "height": 53, "x": 220, "y": 406}, "tags": null}
            }, {
                "id": "Shape_Arrows_42",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 93,
                        "height": 69,
                        "x": 282,
                        "y": 426,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "1e9ea7b179834b2fb84946da63d9625f.svg",
                        "title": "Arrow    "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#83E6D4"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6e08"
                },
                "preset": {"rect": {"width": 53, "height": 53, "x": 270, "y": 406}, "tags": null}
            }, {
                "id": "Shape_Arrows_43",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 90,
                        "height": 69,
                        "x": 12,
                        "y": 483,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "ab57d690c1384bba97182ec3d951bb80.svg",
                        "title": "Arrow Outline "
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6e0p"
                },
                "preset": {"rect": {"width": 53, "height": 87, "x": -1, "y": 459}, "tags": null}
            }, {
                "id": "Shape_Arrows_44",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 90,
                        "height": 66,
                        "x": 64,
                        "y": 484,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "a322d0d23a59439bab1689107c9be464.svg",
                        "title": "Lines Arrow"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6e1d"
                },
                "preset": {"rect": {"width": 51, "height": 87, "x": 52, "y": 459}, "tags": null}
            }, {
                "id": "Shape_Arrows_45",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 69,
                        "height": 90,
                        "x": 122,
                        "y": 480,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "c024895e3915487780c276abcc08e13c.svg",
                        "title": "Pyramid"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6e1u"
                },
                "preset": {"rect": {"width": 56, "height": 87, "x": 103, "y": 459}, "tags": null}
            }, {
                "id": "Shape_Arrows_46",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 99,
                        "height": 69,
                        "x": 172,
                        "y": 483,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "f93e28484aa64416b8e2b3cb010b1795.svg",
                        "title": "Triangle Arrow"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6e2c"
                },
                "preset": {"rect": {"width": 61, "height": 87, "x": 159, "y": 459}, "tags": null}
            }, {
                "id": "Shape_Arrows_47",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 87,
                        "height": 72,
                        "x": 229,
                        "y": 483,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "d78b2ee03fc347469c149cbde7bf665c.svg",
                        "title": "Halftone Arrow"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6e2x"
                },
                "preset": {"rect": {"width": 50, "height": 87, "x": 220, "y": 459}, "tags": null}
            }, {
                "id": "Shape_Arrows_48",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 84,
                        "height": 69,
                        "x": 283,
                        "y": 483,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "221ef96ac4b244bfb481f8c9c92e52e6.svg",
                        "title": "Dotted Arrow"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "stretch"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#424242"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "#ED1566",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqi6e3k"
                },
                "preset": {"rect": {"width": 53, "height": 87, "x": 271, "y": 459}, "tags": null}
            }],
            "compTypes": ["wysiwyg.viewer.components.VectorImage"]
        },
        "sectionFooter": {
            "label": "add_section_more_arrows",
            "action": moreBehaviorHelper,
            "category": "VECTOR_ART",
            "openSource": "add_panel_basic_shapes_arrows",
            "path": "public/ab62ca24cd194952aad0707eead0c0f7/3419319fe1204913b679bc856cc0efff"
        },
        "help": {"hide": false, "text": "add_section_info_text_arrows"}
    }
