'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const experiment = require('experiment')
const util = require('@/util')
const languageCode = util.languages.getLanguageCode()

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_vk',
    'subNavigationTitle': 'add_section_label_vk',
    'presetTitle': 'add_section_label_vk',
    'tooltipTitle': 'add_section_label_vk',
    "automationId": "add-panel-section-vkSection",
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': experiment.isOpen('se_newSocialCategoryAddPanel') && languageCode === 'en' ? {
        "image": `addPanelData/sections/vkSection_${languageCode}/vkSection_${languageCode}.png`,
        "imageHover": null,
        "items": [{
            "id": "Social_VK_1",
            "structure": {
                "type": "Component",
                "skin": "skins.viewer.vkshare.VKShareSkin",
                "layout": {
                    "width": 65,
                    "height": 21,
                    "x": 10,
                    "y": 20,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.VKShareButton",
                "data": {
                    "type": "VKShareButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": "ButtonWithoutCounter",
                    "text": "Share",
                    "urlFormat": "slash",
                    "isHttpsEnabled": true
                },
                "props": {"type": "VKShareProperties", "metaData": {"schemaVersion": "1.0"}, "rightAngles": false},
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.VKShareButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "skins.viewer.vkshare.VKShareSkin"
                },
                "activeModes": {},
                "id": "comp-jp1erhtp"
            },
            "preset": {
                "rect": {"width": 85, "height": 61, "x": 0, "y": 0},
                "tags": null,
                "label": "add_preset_Social_VK_1",
                "labelClasses": "align-left"
            }
        }, {
            "id": "Social_VK_2",
            "structure": {
                "type": "Component",
                "skin": "skins.viewer.vkshare.VKShareSkin",
                "layout": {
                    "width": 108,
                    "height": 21,
                    "x": 95,
                    "y": 20,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.VKShareButton",
                "data": {
                    "type": "VKShareButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": "Button",
                    "text": "Share",
                    "urlFormat": "slash",
                    "isHttpsEnabled": true
                },
                "props": {"type": "VKShareProperties", "metaData": {"schemaVersion": "1.0"}, "rightAngles": false},
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.VKShareButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "skins.viewer.vkshare.VKShareSkin"
                },
                "activeModes": {},
                "id": "comp-jp1esysy"
            },
            "preset": {
                "rect": {"width": 134, "height": 61, "x": 85, "y": 0},
                "tags": null,
                "label": "add_preset_Social_VK_2",
                "labelClasses": "align-left"
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.VKShareButton"]
    } : {
        'image': languageCode === 'en' ? `addPanelData/sections/vkSection_en/vkSection_en.v3.png` :
            `addPanelData/sections/vkSection_${languageCode}/vkSection_${languageCode}.png`,
        'imageHover': null,
        'items': [{
            'id': 'Social_VK_1',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.vkshare.VKShareSkin',
                'layout': {
                    'width': 36,
                    'height': 36,
                    'x': 10,
                    'y': 20,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.VKShareButton',
                'data': {
                    'type': 'VKShareButton',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': 'Icon',
                    'urlFormat': 'hashBang',
                    'text': 'Share'
                },
                'props': {'type': 'VKShareProperties', 'metaData': {'schemaVersion': '1.0'}, 'rightAngles': false},
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'vks1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.vkshare.VKShareSkin'
                },
                'id': 'ic20f9ce'
            },
            'preset': {
                'rect': {'width': 324, 'height': 72, 'x': 0, 'y': 0},
                'label': 'add_preset_Social_VK_1',
                'tags': null,
                'labelClasses': 'align-left'
            }
        }, {
            'id': 'Social_VK_2',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.vkshare.VKShareSkin',
                'layout': {
                    'width': 100,
                    'height': 21,
                    'x': 10,
                    'y': 89,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.VKShareButton',
                'data': {
                    'type': 'VKShareButton',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': 'Button',
                    'urlFormat': 'hashBang',
                    'text': 'Share'
                },
                'props': {'type': 'VKShareProperties', 'metaData': {'schemaVersion': '1.0'}, 'rightAngles': false},
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'vks1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.vkshare.VKShareSkin'
                },
                'id': 'ic20ftwz'
            },
            'preset': {
                'rect': {'width': 324, 'height': 59, 'x': 0, 'y': 70},
                'label': 'add_preset_Social_VK_2',
                'tags': null,
                'labelClasses': 'align-left'
            }
        }],
        'compTypes': ['wysiwyg.viewer.components.VKShareButton']
    }
}
