import type { CompRef } from '@wix/document-services-types';

import { array } from '@/util';

import type { AnimationsScope } from '../../scope';

import { EntranceAnimationAPI } from './entranceApi';
import { OngoingAnimationAPI } from './ongoingApi';
import { ScrollAnimationAPI } from './scrollApi';
import type { AnimationType, EffectData, ScrollEffectData } from '../types';
import type { AnimationAPI } from './baseAnimationsApi';

export const createAnimationsApi = (scope: AnimationsScope) => {
  const { editorAPI } = scope;

  const ongoing = new OngoingAnimationAPI(editorAPI);

  const entrance = new EntranceAnimationAPI(
    editorAPI,
    (compRef, effectRef) => ongoing.updateTrigger(compRef, effectRef),
    (compRef) => ongoing.updateTrigger(compRef, null),
  );

  const scroll = new ScrollAnimationAPI(editorAPI);

  ongoing.setFindDependentEffect(
    (compRef: CompRef) => entrance.findReaction(compRef)?.effect,
  );

  const apis = {
    entrance,
    ongoing,
    scroll,
  };

  return {
    cleanAllAnimations: (compRef: CompRef | CompRef[]) =>
      array.applyForAllAsync(
        (comp) => apis.entrance.cleanAllAnimations(comp),
        compRef,
      ),
    findExistingAnimation: (compRef: CompRef, animationType: AnimationType) =>
      apis[animationType].findExistingAnimation(compRef),
    getEffectData: (compRef: CompRef, animationType: AnimationType) =>
      apis[animationType].getEffectData(compRef),
    getMobileOverrideEffectData: (
      compRef: CompRef,
      animationType: AnimationType,
    ) => apis[animationType].getMobileOverrideEffectData(compRef),
    setAnimation: (
      compRef: CompRef | CompRef[],
      params: { animationType: AnimationType; effectData: EffectData },
    ) =>
      array.applyForAllAsync(
        (comp) =>
          apis[params.animationType].setAnimation(comp, params.effectData),
        compRef,
      ),
    removeAnimation: (
      compRef: CompRef | CompRef[],
      animationType: AnimationType,
    ) =>
      array.applyForAllAsync(
        (comp) => apis[animationType].removeAnimation(comp),
        compRef,
      ),

    // @TODO change preview functions:
    // 1. They don't care about animationType
    // 2. Separate fns for start/stop/update Scroll animation, as they work in a different way
    previewAnimation: (
      compRef: CompRef | CompRef[],
      params: { animationType: AnimationType; effectData: EffectData },
    ) =>
      array.applyForAll(
        (comp) =>
          (
            apis[params.animationType]
              .previewAnimation as AnimationAPI['previewAnimation']
          )(comp, params.effectData),
        compRef,
      ),
    stopPreviewAnimation: (
      compRef: CompRef | CompRef[],
      animationType: AnimationType,
    ) =>
      array.applyForAll(
        (comp) => apis[animationType].stopPreviewAnimation(comp),
        compRef,
      ),
    updatePreviewAnimation: (
      compRef: CompRef | CompRef[],
      effectData: ScrollEffectData,
    ) => {
      array.applyForAll(
        (comp) => apis.scroll.updatePreviewAnimation(comp, effectData),
        compRef,
      );
    },
  };
};
