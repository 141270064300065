import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import constants from '@/constants';
import * as coreBi from '@/coreBi';
import type { EditorAPI } from '@/editorAPI';
import type { Dispatch, StateMapperArgs } from 'types/redux';
import type { EditorState } from '@/stateManagement';

const { setSiteUserPreferences } = stateManagement.userPreferences.actions;
const { closePanelByNameAction } = stateManagement.panels.actions;
const { componentChangedUndoSendBi } = stateManagement.multilingual.actions;
const { undo } = stateManagement.history.actions;
const { event: sendBiEvent } = stateManagement.bi.actions;

const { design_layout_panel_selection } = coreBi.events.multilingual;

const { DONT_SHOW_AGAIN: DONT_SHOW_AGAIN_KEY } =
  constants.USER_PREFS.MULTILINGUAL.COMPONENT_CHANGED_WARNING;

const getBiParams = (
  _dispatch: AnyFixMe,
  _getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => {
  const { language } = editorAPI;
  const page_id = editorAPI.pages.getCurrentPageId();
  const page_name = editorAPI.pages.getPageTitle(page_id);

  return {
    primary_lang: language.original.get().languageCode,
    current_secondary_lng: language.current.get(),
    page_id,
    page_name,
  };
};

const getUndoBiParams = (editorAPI: EditorAPI) => {
  const biParams =
    editorAPI.documentServices.history.getUndoLastSnapshotParams();
  return {
    pageId: biParams?.currentPage,
    date_of_action: biParams?.date_of_action,
    actionName: biParams?.label,
    component_id: biParams?.component_id,
    component_type: biParams?.component_type,
  };
};

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: StateMapperArgs,
) => editorAPI;

const mapDispatchToProps = (dispatch: AnyFixMe, { panelName }: AnyFixMe) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  return {
    sendClosedBi: (
      button_name: AnyFixMe,
      dont_show_again_indication: AnyFixMe,
    ) => {
      const biParams = dispatch(getBiParams);

      dispatch(
        sendBiEvent(design_layout_panel_selection, {
          button_name,
          dont_show_again_indication,
          ...biParams,
        }),
      );
    },

    closePanel: (dontShowAgain: AnyFixMe) => {
      dispatch(setSiteUserPreferences(DONT_SHOW_AGAIN_KEY, dontShowAgain));
      dispatch(closePanelByNameAction(panelName));
    },

    undo: () => {
      const biParams = getUndoBiParams(editorAPI);
      dispatch(undo());
      dispatch(componentChangedUndoSendBi('dialogPanel', biParams));
    },
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
);
