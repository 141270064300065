:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._saleIndicatorA_1ms8n_7 {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  background: #eedbf4; }
  ._saleIndicatorA_1ms8n_7 ._saleIndicatorA__icon_1ms8n_13 {
    width: 34.21px;
    height: 34.21px;
    margin-left: calc( (24px - 34.21px) / 2);
    margin-top: calc( (24px - 34.21px) / 2); }
    ._saleIndicatorA_1ms8n_7 ._saleIndicatorA__icon_1ms8n_13 path {
      fill: #9a27d5; }
  ._saleIndicatorA_active_1ms8n_20 {
    background: #c161f0; }
    ._saleIndicatorA_active_1ms8n_20 ._saleIndicatorA__icon_1ms8n_13 path {
      fill: white; }
