import React from 'react';
import { Tooltip } from '@wix/wix-base-ui';
import { symbol } from '@wix/santa-editor-symbols';
import type { HiddenInteractionItemProps } from './interactionsHiddenComponentsMapper';

class HiddenInteractionItem extends React.Component<HiddenInteractionItemProps> {
  static displayName = 'HiddenInteractionItem';

  showComponent() {
    this.props.showComponent(this.props.compRef, this.props.numOfHiddenItems);
  }

  getCompIconStyle() {
    return this.props.css
      ? Object.assign({}, this.props.css, {
          backgroundImage: `url(${this.props.iconUrl})`,
        })
      : {};
  }

  render() {
    const MySymbol = symbol;
    return (
      <div
        data-comp={this.props.compRef.id}
        className="hidden-interaction-item"
        onClick={this.showComponent.bind(this)}
      >
        <div className="icon" style={this.getCompIconStyle()}>
          {this.props.svgName && (
            <MySymbol key="hiddenItemIcon" name={this.props.svgName} />
          )}
        </div>

        <div className="label">{this.props.label}</div>

        <Tooltip content="mobile_hidden_items_show_tooltip">
          <div className="add-button">
            <MySymbol className="plus" name="plusBig" key="plus-icon" />
          </div>
        </Tooltip>
      </div>
    );
  }
}

export default HiddenInteractionItem;
