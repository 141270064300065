// @ts-nocheck
import React from 'react';
import {
  Composites,
  InfoIcon,
  RadioButtons,
  TextLabel,
} from '@wix/wix-base-ui';

export default class extends React.Component {
  static displayName = 'radiogroup';

  render() {
    return (
      <Composites.RadioButtonsLabeled>
        <InfoIcon text={this.props.infoText} />
        <TextLabel value={this.props.label} />
        <RadioButtons
          value={this.props.valueLink.value}
          onChange={this.props.valueLink.requestChange}
          options={this.props.options}
        />
      </Composites.RadioButtonsLabeled>
    );
  }
}
