import { SectionsManager } from './sectionsManager';
import { EditorCoreApiKey } from '@/apis';

import {
  getComponentsBelowPosition,
  shouldTreatAsSection,
  shiftComponents,
} from './utils';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

export { SectionTypes } from './constants';

export {
  shouldApplySectionResizeIndication,
  getComponentsBelowPosition,
  shiftComponents,
  isSectionsOnStageEnabled,
  shouldTreatAsSection,
} from './utils';

export type { Section } from './types';

export const initSectionsOnStage = (editorAPI: EditorAPI) => {
  editorAPI.host.getAPI(EditorCoreApiKey).hooks.initReady.promise.then(() => {
    const sectionsManager = new SectionsManager();
    sectionsManager.init(editorAPI);

    editorAPI.registerAfterDuplicateComponentCallback(
      (
        editorAPI: EditorAPI,
        [componentRef]: CompRef[],
        [originalComponentRef]: CompRef[],
      ) => {
        if (!shouldTreatAsSection(editorAPI, originalComponentRef)) return;

        const originalComponentLayout =
          editorAPI.components.layout.get_rect(originalComponentRef);
        const componentsToShift = getComponentsBelowPosition(
          editorAPI,
          originalComponentLayout.y,
        );
        shiftComponents(
          editorAPI,
          componentsToShift.filter(
            (ref) =>
              ![componentRef.id, originalComponentRef.id].includes(ref.id),
          ),
          originalComponentLayout.height,
        );
      },
    );
  });
};
