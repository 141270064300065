import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { Text } from '@wix/wix-base-ui';

import { translate } from '@/i18n';
import * as panels from '@/panels';

const { ConfirmationPanelFrame, MessagePanelFrame } = panels.frames;

interface NewWorkspaceConcurrentUserPanelFrameProps {
  panelName: string;
  confirmLabel: string;
  cancelLabel?: string;
  footnote?: string;
  theme?: 'standard' | 'destructive' | 'premium';
  title: string;
  symbolName: string;
  hideCloseButton?: boolean;
  cancelOnEscPress?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  onHelp?: () => void;
}

export const NewWorkspaceConcurrentUserPanelFrame: React.FC<
  NewWorkspaceConcurrentUserPanelFrameProps
> = (props) => {
  const footnote = props.footnote && (
    <Text
      size="small"
      skin="secondary"
      shouldTranslate={false}
      enableEllipsis={false}
    >
      {props.footnote}
    </Text>
  );

  return (
    <MessagePanelFrame
      className="new-workspace-concurrent-user-panel"
      panelName={props.panelName}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      cancelLabel={translate(props.cancelLabel)}
      confirmLabel={translate(props.confirmLabel)}
      onHelp={props.onHelp}
      theme={props.theme}
      title={translate(props.title)}
      illustration={<symbols.symbol name={props.symbolName} />}
      footnote={footnote}
      hideCloseButton={props.hideCloseButton}
      cancelOnClickOutside={false}
      cancelOnEscPress={props.cancelOnEscPress}
    >
      <Text
        size="medium"
        weight="normal"
        skin="secondary"
        enableEllipsis={false}
        shouldTranslate={false}
      >
        {props.children}
      </Text>
    </MessagePanelFrame>
  );
};

interface ConcurrentUserPanelFrameProps {
  panelName: string;
  confirmLabel: string;
  cancelLabel: string;
  footnote?: string;
  type?: string;
  title: string;
  symbolName: string;
  hideCloseButton?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  onHelp?: () => void;
  onClose: () => void;
}

export const ConcurrentUserPanelFrame: React.FC<
  ConcurrentUserPanelFrameProps
> = (props) => {
  return (
    <ConfirmationPanelFrame
      panelName={props.panelName}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      onClose={props.onClose}
      cancelLabel={props.cancelLabel}
      confirmLabel={props.confirmLabel}
      shouldHideCancelButton={!props.cancelLabel}
      shouldHideCloseButton={props.hideCloseButton}
      footerMessage={props.footnote}
      noHelpBtn={!props.onHelp}
      onHelp={props.onHelp}
      type={props.type}
      panelTitle={translate(props.title)}
      className="concurrent-user-panel"
    >
      <div className="panel-inner">
        <div className="concurrent-users-panel-img">
          <symbols.symbol name={props.symbolName} />
        </div>
        {props.children}
      </div>
    </ConfirmationPanelFrame>
  );
};
