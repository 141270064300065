import { notifications, panels } from '@/stateManagement';
import { translate } from '@/i18n';
import { TRANSLATIONS } from '../../../translations';
import { isSection, getNonDuplicatableData } from '../../../api';

import type { Scope } from '@/sections';
import type { CompRef } from 'types/documentServices';

const { showUserActionNotification } = notifications.actions;

export const createCannotCopyOrDuplicatePlugin =
  (scope: Scope) =>
  ([compRef]: CompRef[]) => {
    const { editorAPI, components } = scope;
    if (!isSection(scope, compRef)) return;

    const nonDuplicatableData = getNonDuplicatableData(scope, compRef);

    let notificationMessage: string;
    let notificationHelpId: string; // TODO: add learn more link for notification

    const isMobile = editorAPI.isMobileEditor();

    if (isMobile) {
      notificationMessage = translate(
        TRANSLATIONS.NOTIFICATIONS.CANNOT_DUPLICATE_SECTION_MOBILE,
      );
      notificationHelpId = 'a780e263-47f0-40d2-81ac-0a7719b9dab0';
    } else if (nonDuplicatableData?.type === 'containsNonDuplicatableTPA') {
      notificationMessage = translate(
        TRANSLATIONS.NOTIFICATIONS.CANNOT_DUPLICATE_SECTION_WITH_WIDGET,
        {
          widget_name: components.getDisplayName(
            nonDuplicatableData.nonDuplicatableTPARef,
          ),
        },
      );
      notificationHelpId = 'faa30f31-4afb-41cf-858a-5271e436f75b';
    }

    if (!notificationMessage) return;

    editorAPI.store.dispatch(
      showUserActionNotification({
        message: notificationMessage,
        title: notificationMessage,
        type: 'warning',
        link: notificationHelpId
          ? {
              caption: translate('Notifications_Learn_More_Link'),
              onNotificationLinkClick: () => {
                editorAPI.store.dispatch(
                  panels.actions.openHelpCenter(notificationHelpId),
                );
              },
            }
          : undefined,
        shouldTranslate: false,
      }),
    );
  };
