import {
  menuMigrationFail,
  menuMigrationSuccess,
} from '@wix/bi-logger-editor/v2';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as stateManagement from '@/stateManagement';
import { biLogger, editorModel } from '@/util';
import { EditorParamsApiKey } from '@/apis';
import { createMenuApi } from '../../API/menuAPI';
import {
  MENUS_USAGE_TYPES,
  MENUS_TYPE_BEFORE_ADVANCED_MENUS_MIGRATION_KEY,
} from '../../constants';
import { migrateCustomMenusToAdvancedMenus } from './migrateCustom';
import { migrateCustomProMenusToAdvancedMenus } from './migrateCustomPro';
import type { EditorAPI } from '@/editorAPI';
import type { DataForMigration } from '../getMenusUsageData';

const BEFORE_ADVANCED_MENUS_MIGRATION_HISTORY_LABEL =
  'beforeAdvancedMenusMigration';

const runMigration = async (
  editorAPI: EditorAPI,
  dataForMigration: DataForMigration,
) => {
  const menuAPI = createMenuApi(editorAPI);

  const { siteMenusType } = dataForMigration;

  const allMenuComps = [
    ...menuAPI.getMenuComponents(),
    ...menuAPI.getMobileMenuComponents(),
  ];

  const migrateMenus =
    siteMenusType === MENUS_USAGE_TYPES.CUSTOM
      ? migrateCustomMenusToAdvancedMenus
      : migrateCustomProMenusToAdvancedMenus;

  editorAPI.savePublish.lockSavePublish();

  await migrateMenus(editorAPI, menuAPI, allMenuComps, dataForMigration);

  await menuAPI.removeMenusWithoutConnectedComponents();

  editorAPI.savePublish.unlockSavePublish();

  if (
    !editorAPI.dsRead.generalInfo.isDraft() &&
    !editorAPI.dsRead.generalInfo.isFirstSave()
  ) {
    await new Promise((resolve, reject) => {
      editorAPI.saveManager.saveInBackground(
        resolve,
        reject,
        'advancedMenusMigration',
      );
    });
  }

  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      MENUS_TYPE_BEFORE_ADVANCED_MENUS_MIGRATION_KEY,
      siteMenusType,
    ),
  );
};

export const migrateToAdvancedMenus = async (
  editorAPI: EditorAPI,
  dataForMigration: DataForMigration,
) => {
  const {
    siteCreationWizard: isSiteCreationFlow,
    isInsideEditorX,
    isInsideAppStudio,
  } = editorAPI.host.getAPI(EditorParamsApiKey);

  if (
    isSiteCreationFlow ||
    isInsideEditorX ||
    isInsideAppStudio ||
    editorModel.isImpersonated
  ) {
    return;
  }

  const startTime = Date.now();

  editorAPI.history.add(BEFORE_ADVANCED_MENUS_MIGRATION_HISTORY_LABEL);

  try {
    await runMigration(editorAPI, dataForMigration);
  } catch (e: any) {
    editorAPI.history.performUndoUntilLabel(
      BEFORE_ADVANCED_MENUS_MIGRATION_HISTORY_LABEL,
      true,
    );

    editorAPI.savePublish.unlockSavePublish();

    ErrorReporter.captureException(e, {
      tags: { advancedMenusMigration: true },
    });

    biLogger.report(menuMigrationFail({ errorMessage: e?.message }));

    console.error('[Advanced menus migration]:', e);

    return;
  }

  (window as any).___advanced_menus_open___ = true;

  biLogger.report(
    menuMigrationSuccess({
      duration: Date.now() - startTime,
    }),
  );
};
