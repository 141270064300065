// @ts-nocheck
import addPanelDataConsts from '@/addPanelDataConsts';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: 'add_section_label_disqus_comments',
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
    iconEnabledForComps: {},
  },
  props: {
    image: 'addPanelData/sections/disqusSection_en/disqusSection_en.png',
    imageHover: null,
    items: [
      {
        id: 'Blog_Disqus_1',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.common.components.disquscomments.viewer.skins.DisqusCommentsSkin',
          layout: {
            width: 500,
            height: 407,
            x: 0,
            y: 1,
            scale: 1,
            rotationInDegrees: 0,
          },
          componentType:
            'wysiwyg.common.components.disquscomments.viewer.DisqusComments',
          data: {
            type: 'DisqusComments',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            disqusId: '',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'disq2',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: { groups: {}, properties: {}, propertiesSource: {} },
            componentClassName:
              'wysiwyg.common.components.disquscomments.viewer.DisqusComments',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.common.components.disquscomments.viewer.skins.DisqusCommentsSkin',
          },
          id: 'comp-id7erk97',
        },
        preset: { rect: { width: 324, height: 212, x: 1, y: 0 }, tags: null },
      },
    ],
    compTypes: [
      'wysiwyg.common.components.disquscomments.viewer.DisqusComments',
    ],
  },
  help: { hide: false, text: 'add_section_info_text_disqus_comments' },
};
