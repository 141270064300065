:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._welcome-page-header_1v3uc_7 {
  padding-top: 25px; }
  ._welcome-page-header_1v3uc_7 ._linkContainer_1v3uc_9 {
    text-align: center;
    margin-top: 4px;
    margin-bottom: 27px; }
  ._welcome-page-header_1v3uc_7 ._illustration_1v3uc_13 {
    margin-top: 1px;
    width: 125px; }
  ._welcome-page-header_1v3uc_7 ._info_1v3uc_16 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 18px; }
  ._welcome-page-header_1v3uc_7 ._description_1v3uc_22 {
    padding: 0 24px;
    text-align: center;
    color: #3b4057;
    max-width: 220px; }
    ._welcome-page-header_1v3uc_7 ._description_1v3uc_22 .control-label-base {
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; }
  ._welcome-page-header_1v3uc_7 ._title_1v3uc_34 {
    margin: 6px 0 10px;
    color: #3b4057; }
  ._welcome-page-header_1v3uc_7 ._actions-container_1v3uc_37 {
    padding-right: 22px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    ._welcome-page-header_1v3uc_7 ._actions-container_1v3uc_37 ._settings-icon_1v3uc_42 {
      margin-left: 16px;
      cursor: pointer; }
      ._welcome-page-header_1v3uc_7 ._actions-container_1v3uc_37 ._settings-icon_1v3uc_42 path {
        fill: #868aa5; }
      ._welcome-page-header_1v3uc_7 ._actions-container_1v3uc_37 ._settings-icon_1v3uc_42:hover path {
        fill: #116dff; }
