import ascendMenuPanel from './ascendMenuPanel/ascendMenuPanel';
import customerManagementPanel from './customerManagementPanel/customerManagementPanel';
import marketingPanel from './marketingPanel/marketingPanel';
import upgradeAscendPanel from './upgradeAscendPanel/upgradeAscendPanel';
import analyticsPanel from './analyticsPanel/analyticsPanel';
import firstTimeExperience from './firstTimeExperience/firstTimeExperience';
import financePanel from './financePanel/financePanel';
import virtualPhoneNumberPanel from './virtualPhoneNumberPanel/virtualPhoneNumberPanel';

export {
  ascendMenuPanel,
  customerManagementPanel,
  marketingPanel,
  upgradeAscendPanel,
  firstTimeExperience,
  analyticsPanel,
  financePanel,
  virtualPhoneNumberPanel,
};
