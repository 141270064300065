import { SelectedSectionBox, HoveredSectionBox } from './SectionBox/SectionBox';
import { ChildBoxes } from './ChildBoxes/ChildBoxes';
import {
  HoveredSiteSegmentBox,
  SelectedSiteSegmentBox,
} from './SiteSegmentBox/SiteSegmentBox';

import type { Scope } from '../scope';

export const bootsrapComponentsContribution = ({ workspace }: Scope) => {
  workspace.contributeStageComponent(ChildBoxes);
  workspace.contributeMouseCatcherComponent(SelectedSectionBox);
  workspace.contributeMouseCatcherComponent(HoveredSectionBox);

  workspace.contributeMouseCatcherComponent(HoveredSiteSegmentBox);
  workspace.contributeMouseCatcherComponent(SelectedSiteSegmentBox);
};
