import { EditorAPIKey } from '@/apis';
import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';

import type { CompRef, RelativePosition } from 'types/documentServices';
import type { Shell } from '@/apilib';
import type { PanelProps } from '@/stateManagement';

export function createA11yApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);

  const isAccessibilitySupported = (compRef: CompRef) => {
    const isAdvancedA11yEnabled =
      stateManagement.userPreferences.selectors.getSiteUserPreferences(
        constants.USER_PREFS.ACCESSIBILITY.ADVANCED_SETTINGS,
      );
    if (
      !isAdvancedA11yEnabled(editorAPI.store.getState()) ||
      !editorAPI.components.is.a11yConfigurable([compRef])
    ) {
      return false;
    }
    const currentA11yAttributes = _.pick(
      editorAPI.components.data.get(compRef)?.a11y ?? {},
      constants.ACCESSIBILITY.A11Y_ATTRIBUTES,
    );
    return Object.keys(currentA11yAttributes).length > 0;
  };

  const PANEL_WIDTH = 288;
  const PANEL_EDGE_OFFSET = 20;
  const PANEL_OFFSET_Y = 96;

  const openA11yPanel = (
    compRef: CompRef,
    position?: RelativePosition,
    panelProps?: Partial<PanelProps>,
  ) => {
    const props = {
      selectedComponent: [compRef],
      selectedComponents: [compRef],
      selectedComponentType: editorAPI.components.getType(compRef),
      ...panelProps,
    };

    const { top: minTop, left: minLeft } =
      stateManagement.domMeasurements.selectors.getPreviewPosition(
        editorAPI.store.getState(),
      );

    if (!position) {
      const scroll = editorAPI.scroll.get();
      const cursor = editorAPI.cursor.get();

      position = {
        left: Math.max(minLeft + PANEL_EDGE_OFFSET, cursor.x - PANEL_WIDTH),
        top: Math.max(minTop, cursor.y - scroll.scrollTop),
      };
    } else {
      position = {
        left: position.left - PANEL_WIDTH - PANEL_EDGE_OFFSET,
        top: Math.max(
          minTop + PANEL_EDGE_OFFSET,
          position.top - PANEL_OFFSET_Y,
        ),
      };
    }

    editorAPI.panelManager.openComponentPanel(
      'compPanels.dynamicPanels.a11ySettingsPanel',
      {
        ...props,
        style: position,
      },
    );
  };

  return {
    isAccessibilitySupported,
    openA11yPanel,
  };
}

export type A11yApi = ReturnType<typeof createA11yApi>;
