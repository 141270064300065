._container_3bz3g_1 {
  width: 100%;
  height: 100%;
  position: relative; }

._preview_3bz3g_6,
._overlay_3bz3g_7 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

._iconContainer_3bz3g_14 {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 6px;
  left: 6px; }

._dark_3bz3g_21 {
  color: white !important; }
  ._dark_3bz3g_21 path {
    fill: white; }

._media_3bz3g_26 {
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.35)); }
