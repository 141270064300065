import React from 'react';
import { MessageModal, Checkbox } from '@wix/wix-base-ui';
import _ from 'lodash';

import * as util from '@/util';
import { translate } from '@/i18n';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import * as higherOrderComponents from '@/higherOrderComponents';

import useOnlyOnce from '../../hooks/useOnlyOnce';
import useKeyboardShortcuts from '../../hooks/useKeyboardShortcuts';
import PanelCloseOrigin from '../panelCloseOrigin';

import type {
  ProvidedPanelFrameProps,
  PanelFrameProps,
} from '@/stateManagement';
import type {
  ProvidedUserPreferencesProps,
  ConsumedUserPreferencesProps,
} from '@/higherOrderComponents';
import type { Shortcuts } from '../../hooks/useKeyboardShortcuts';

const OuterClick = util.outerClick;

interface DontShowAgainCheckboxProps {
  value: boolean;
  onChange: (checked: boolean) => void;
}

const DontShowAgainCheckbox: React.FC<DontShowAgainCheckboxProps> = ({
  value,
  onChange,
}) => (
  <Checkbox
    labelAfterSymbol={true}
    label={translate('Notification_Box_Dont_Show_Again')}
    shouldTranslate={false}
    value={value}
    onChange={onChange}
    dataHook="MessagePanelFrame-dont-show-again"
  />
);

export interface MessagePanelFrameProps {
  dataHook?: string;
  panelName: string;
  className?: string;
  title: string;
  footnote?: string | React.ReactNode;
  illustration: string | React.ReactNode;
  close: (origin: string) => void;
  confirmLabel: string;
  onConfirm?: () => void;
  confirmBtnProps?: object;
  cancelLabel?: string;
  onCancel?: (origin: string) => void;
  onHelp?: () => void;
  dontShowAgain?: boolean;
  dontShowAgainKey?: string;
  toggleDontShowAgain?: (checked: boolean) => void;
  userPrefType?: string;
  hideCloseButton?: boolean;
  cancelOnEscPress?: boolean;
  cancelOnClickOutside?: boolean;
  confirmOnEnterPress?: boolean;
  theme?: 'standard' | 'destructive' | 'premium';
  sideActions?: React.ReactNode;
  onSecondaryAction?: () => void;
  enableOnApproveAction?: boolean;
}

export const MessagePanelFrame: React.FC<MessagePanelFrameProps> = (props) => {
  const [isMainActionEnable, setIsMainActionEnable] =
    React.useState<boolean>(false);
  // render side actions
  const sideActions =
    props.dontShowAgainKey && props.userPrefType ? (
      <DontShowAgainCheckbox
        value={props.dontShowAgain}
        onChange={(checked: boolean) => {
          setIsMainActionEnable(checked);
          props.toggleDontShowAgain(checked);
        }}
      />
    ) : null;

  // get callbacks
  const handleCancel = (origin: string) => {
    props.onCancel?.(origin);
    props.close(origin);
  };
  const handleConfirm = (origin: string) => {
    props.onConfirm?.();
    props.close(origin);
  };
  const handleSecondaryAction = () => {
    props.onSecondaryAction?.();
    props.close(PanelCloseOrigin.SECONDARY_ACTION);
  };
  const [
    handleCancelOnlyOnce,
    handleConfirmOnlyOnce,
    handleSecondaryActionOnlyOnce,
  ] = useOnlyOnce(handleCancel, handleConfirm, handleSecondaryAction);

  // add shortcuts
  const shortcuts: Shortcuts = {
    esc: () => {
      if (props.cancelOnEscPress) {
        handleCancelOnlyOnce(PanelCloseOrigin.ESCAPE_PRESS);
      }
    },
  };

  if (props.confirmOnEnterPress) {
    shortcuts.enter = () => handleConfirmOnlyOnce(PanelCloseOrigin.ENTER_PRESS);
  }

  useKeyboardShortcuts(shortcuts);

  const className = util.cx(
    'panel-frame-container',
    'screen-centered-frame',
    'message-panel-frame-z-index',
    props.className,
  );

  const onCloseButtonClick = props.hideCloseButton
    ? null
    : () => handleCancelOnlyOnce(PanelCloseOrigin.HEADER_CLOSE_BUTTON);

  const onOuterClick = () =>
    props.cancelOnClickOutside &&
    handleCancelOnlyOnce(PanelCloseOrigin.CLICK_OUTSIDE);

  return (
    <div className={className}>
      <div
        className="dark-frame-overlay"
        data-hook="MessagePanelFrame-overlay"
      />
      <OuterClick onOuterClick={onOuterClick}>
        <MessageModal
          dataHook={props.dataHook}
          theme={props.theme}
          title={props.title}
          content={props.children}
          illustration={props.illustration}
          primaryButtonText={props.confirmLabel}
          primaryButtonOnClick={() =>
            handleConfirmOnlyOnce(PanelCloseOrigin.CONFIRM_BUTTON)
          }
          primaryButtonProps={{
            disabled: props.enableOnApproveAction ? !isMainActionEnable : false,
            ...props.confirmBtnProps,
          }}
          secondaryButtonText={props.cancelLabel}
          secondaryButtonOnClick={() =>
            props.onSecondaryAction
              ? handleSecondaryActionOnlyOnce()
              : handleCancelOnlyOnce(PanelCloseOrigin.DONE_BUTTON)
          }
          sideActions={props.sideActions || sideActions}
          footnote={props.footnote}
          onCloseButtonClick={onCloseButtonClick}
          onHelpButtonClick={props.onHelp}
        />
      </OuterClick>
    </div>
  );
};

MessagePanelFrame.defaultProps = {
  dataHook: 'MessagePanelFrame',
  hideCloseButton: false,
  cancelOnEscPress: true,
  cancelOnClickOutside: true,
};

export type MessagePanelFrameOwnProps = Omit<
  Omit<MessagePanelFrameProps, keyof ProvidedPanelFrameProps>,
  keyof ProvidedUserPreferencesProps
> &
  ConsumedUserPreferencesProps;

export default _.flow(
  //provides close behavior
  stateManagement.panels.hoc.panelFrame(constants.PANEL_TYPES.MESSAGE),
  //provides dontShowAgain behavior
  higherOrderComponents.linkUserPreferences(),
)(MessagePanelFrame) as unknown as React.ComponentType<
  MessagePanelFrameOwnProps & PanelFrameProps
>;
