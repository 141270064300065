import type { EditorAPI } from '@/editorAPI';
import { constants } from '@/platform';
import { MEMBERS_AREA_V2 } from '@wix/app-definition-ids';

export const hasCustomSignupLightbox = (editorAPI: EditorAPI): boolean => {
  const getCustomSignupPageId =
    editorAPI.dsRead.siteMembers.getCustomSignupPageId();
  const customSignupPage = editorAPI.components.get.byId(getCustomSignupPageId);

  const children =
    (customSignupPage && editorAPI.components.getChildren(customSignupPage)) ||
    [];
  return children.some(
    (childRef) =>
      editorAPI.components.getType(childRef) === constants.FORM_COMPONENT_TYPE,
  );
};

export const hasCustomSignInLightbox = (editorAPI: EditorAPI): boolean => {
  const getCustomSignInPageId =
    editorAPI.dsRead.siteMembers.getCustomSignInPageId();
  const customSignInPage = editorAPI.components.get.byId(getCustomSignInPageId);

  const children =
    (customSignInPage && editorAPI.components.getChildren(customSignInPage)) ||
    [];
  return children.some(
    (childRef) =>
      editorAPI.components.getType(childRef) === constants.POPUP_COMPONENT_TYPE,
  );
};

export const shouldShowSignupTab = (editorAPI: EditorAPI): boolean => {
  const pagesData = editorAPI.dsRead.pages.getPagesData();
  const hasRestrictedPageOrNewMA =
    editorAPI.isDesktopEditor() &&
    pagesData.some(
      (data) =>
        data.pageSecurity?.requireLogin ||
        data.appDefinitionId === MEMBERS_AREA_V2,
    );

  return (
    hasRestrictedPageOrNewMA ||
    hasCustomSignupLightbox(editorAPI) ||
    hasCustomSignInLightbox(editorAPI)
  );
};
