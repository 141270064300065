// @ts-nocheck
import React from 'react';

export default class extends React.Component {
  static displayName = 'superAppPagesPanel';

  render() {
    return (
      <div className="site-app-pages-panel">
        <this.props.superApp.panel.pages.template />
      </div>
    );
  }
}
