const NUM_ICONS = 25;

const ROW_SIZE = 280;
const ROW_HEIGHT = 300;

const iconWidth = ROW_SIZE / 5;
const iconHeight = ROW_HEIGHT / 5;

const pointyStar = '3eb9dd5655264d309eb2f77d849168f6.svg';
const star = '503047d3d3884f6097b82dec7af52fa8.svg';
const liquidStar = '83dbe73f919743d4ad6c6e372975209d.svg';
const fatLiquidStar = 'ea08a3a5ec014d94b2b1cd4d6709d67d.svg';
const emptyStar = '6aaad40b4f9942c8b44af7900107ad4d.svg';

const squareStar = '5c464291fcab4eab8e184e6550de6fdb.svg';
const circleStar = '05378efaa01c474eb81d55b0e0144068.svg';
const circleHeart = 'ddf8eb1d8f96444b81fd394c7f4f46c4.svg';
const heart = 'df738905bc4f402cb8291135f9310a45.svg';
const chubbyHeart = 'c156ade1a0c5419593b933eacb9debc8.svg';

const circle = 'cf545b123b6e4523b228d62bfa0dea9b.svg';
const hexagon = '0c2746b4100c432bb0d7e97b5f4c9f63.svg';
const triangle = '9cca9e9c03ec45e28cb046998aedd32d.svg';
const square = '909695c1e003409ba70b5561666c7c4d.svg';
const circleDollar = '0883aeb72ae94a799245dfee8fff84ed.svg';

const circleCheck = 'cd69cc6678764dbca8ec434ba22ffd92.svg';
const smiley = 'a51b7595385747538b1b266b0df6a72e.svg';
const alien = '23dc714b88c1462eac760cfb220971b1.svg';
const skull = '1585955e378047cab2eb9115df640018.svg';
const metal = '9b1b2f988b1140249b10add04c80a9bb.svg';

const crown = 'b838622f232d4dacaac167891186c5f1.svg';
const paw = 'aefb0ade069d4eae8a80345c3d523326.svg';
const bones = 'aafa5b605f33410585ec6a5f76555f1a.svg';
const flower = '7b6e580c17be459c891d2b62323cacbd.svg';
const drop = '60f538cf3d404e7db027663b71bba058.svg';

const svgIds = [
  pointyStar,
  star,
  liquidStar,
  fatLiquidStar,
  emptyStar,
  squareStar,
  circleStar,
  circleHeart,
  heart,
  chubbyHeart,
  circle,
  hexagon,
  triangle,
  square,
  circleDollar,
  circleCheck,
  smiley,
  alien,
  skull,
  metal,
  crown,
  paw,
  bones,
  flower,
  drop,
];

function createItem(
  compType: AnyFixMe,
  idx: AnyFixMe,
  width: AnyFixMe,
  height: AnyFixMe,
  x: AnyFixMe,
  y: AnyFixMe,
) {
  return {
    id: `RatingIcon_${idx}`,
    structure: {
      layout: {
        width: 18,
        height: 18,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: `wixui.${compType}`,
      type: 'Component',
      skin: 'wixui.skins.Rating',
      data: {
        type: `${compType}`,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        svgId: svgIds[idx],
      },
      props: {
        type: `${compType}Properties`,
        metaData: {
          schemaVersion: '1.0',
        },
      },
      style: {
        type: 'TopLevelStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {},
        componentClassName: `wixui.${compType}`,
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wixui.skins.Rating',
      },
      connections: {},
    },
    preset: {
      rect: {
        width,
        height,
        x,
        y,
      },
    },
  };
}

function createItems(compType: AnyFixMe) {
  const items = [];

  for (let i = 0; i < NUM_ICONS; i++) {
    items.push(
      createItem(
        compType,
        i,
        iconWidth,
        iconHeight,
        (i % 5) * iconWidth,
        Math.floor(i / 5) * iconHeight,
      ),
    );
  }

  return items;
}

export default {
  create(compType: AnyFixMe, compTitle: AnyFixMe) {
    return {
      getDesignSections() {
        return {
          titles: [compTitle],
          sections: [
            {
              type: 'SECTIONS_TYPES_PRESET',
              hide: false,
              showSectionHeader: true,
              additionalBehaviours: {
                labelMode: 'none',
                infoIcon: 'none',
                hoverImageAction: 'none',
                iconEnabledForComps: {},
              },
              props: {
                image: 'compPanels/ratings/ratingIcons.png',
                imageHover: null as AnyFixMe,
                items: createItems(compType),
              },
              help: {
                hide: true,
                text: '',
              },
            },
          ],
        };
      },
    };
  },
};
