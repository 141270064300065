import type {
  CompRef,
  CompVariantPointer,
  Pointer,
} from 'types/documentServices';

import type { AnimationsScope } from '@/animations';

import type {
  TriggerType,
  TriggerParams,
  ReactionParams,
  Reaction,
  AnimationType,
} from './types';
import {
  effectTypes,
  triggerParams,
  animationTypeToTriggerMap,
} from './configs';

export const createAnimationsHelpers = ({ editorAPI }: AnimationsScope) => {
  function getCompRefs(compRef: CompRef) {
    const { DESKTOP, MOBILE } = editorAPI.viewMode.VIEW_MODES;

    const isMobileOnlyComp =
      editorAPI.mobile.mobileOnlyComponents.isMobileOnlyComponent(compRef.id);
    const defaultCompRef = {
      id: compRef.id,
      type: isMobileOnlyComp ? MOBILE : DESKTOP,
    } as CompVariantPointer;
    const compRefWithMobileVariant = editorAPI.components.variants.getPointer(
      defaultCompRef,
      [editorAPI.mobile.getMobileVariant()],
    );

    return { defaultCompRef, compRefWithMobileVariant };
  }

  function getTriggerParams(compRef: CompRef, animationType: AnimationType) {
    const dependentEffect =
      animationType === 'loop' && loop.getDependentEffect(compRef);

    return triggerParams[animationType](dependentEffect);
  }

  function findTriggerRef(compRef: CompRef, triggerType: TriggerType) {
    const allTriggers = editorAPI.components.triggers.getAll(compRef);
    const existingTrigger = allTriggers.find(
      (ref) =>
        editorAPI.components.triggers.get(compRef, ref).trigger === triggerType,
    );

    return existingTrigger;
  }

  function getTriggerRef(
    compRef: CompRef,
    triggerType: TriggerType,
    triggerParams: TriggerParams['params'] = {},
  ) {
    const existingTrigger = findTriggerRef(compRef, triggerType);

    if (existingTrigger) {
      return existingTrigger;
    }

    return editorAPI.components.triggers.add(compRef, {
      trigger: triggerType,
      params: triggerParams,
    });
  }

  function hasReactionsInTrigger(compRef: CompRef, triggerType: TriggerType) {
    const { defaultCompRef, compRefWithMobileVariant } = getCompRefs(compRef);
    const reactions = [
      ...(editorAPI.components.reactions.get(defaultCompRef) ?? []),
      ...(editorAPI.components.reactions.get(compRefWithMobileVariant) ?? []),
    ] as Reaction[];

    return reactions.find((reaction) => reaction.triggerType === triggerType);
  }

  function removeTriggerIfNeeded(compRef: CompRef, triggerType: TriggerType) {
    const triggerRef = findTriggerRef(compRef, triggerType);

    if (!hasReactionsInTrigger(compRef, triggerType)) {
      editorAPI.components.triggers.remove(compRef, triggerRef);
    }
  }

  function findReactionByTriggerType(
    compRef: CompRef,
    triggerType: TriggerType,
  ) {
    const reactions = editorAPI.components.reactions.get(compRef) as Reaction[];

    return reactions?.find(
      (reaction: Reaction) => reaction.triggerType === triggerType,
    );
  }

  function findReaction(compRef: CompRef, animationType: AnimationType) {
    const reactions = editorAPI.components.reactions.get(compRef) as Reaction[];

    return reactions?.find((reaction: Reaction) =>
      animationTypeToTriggerMap[animationType].includes(reaction.triggerType),
    );
  }

  function createReaction(
    compRef: CompRef,
    triggerRef: Pointer,
    effectRef: Pointer,
    reactionParams: ReactionParams,
  ) {
    return editorAPI.components.reactions.add(compRef, triggerRef, {
      effect: effectRef,
      ...reactionParams,
    });
  }

  function removeReaction(
    compRef: CompVariantPointer | CompRef,
    reaction: Reaction,
  ) {
    const triggerRef = findTriggerRef(compRef, reaction.triggerType);

    editorAPI.components.reactions.remove(
      compRef,
      triggerRef,
      reaction.pointer,
    );
  }

  function disableReaction(
    compRef: CompVariantPointer | CompRef,
    animationType: AnimationType,
  ) {
    const triggerRef = findTriggerRef(
      compRef,
      getTriggerParams(compRef, animationType).trigger,
    );

    editorAPI.components.reactions.disable(compRef, triggerRef);
  }

  function setEmptyEffect(
    compPointer: CompVariantPointer | CompRef,
    animationType: AnimationType,
    effectRef?: Pointer,
  ) {
    // should be here?
    const { type, valueType } = effectTypes[animationType];

    const emptyEffectObject = {
      type,
      name: '',
      value: {
        type: valueType,
        namedEffect: null as null,
      },
    };

    if (!effectRef) {
      return editorAPI.components.effects.add(compPointer, emptyEffectObject);
    }

    editorAPI.components.effects.update(
      compPointer as CompVariantPointer,
      effectRef,
      emptyEffectObject,
    );

    return effectRef;
  }

  function getEffectData(
    compPointer: CompRef | CompVariantPointer,
    effectRef?: Pointer,
  ) {
    if (!effectRef) return;
    return editorAPI.components.effects.get(compPointer, effectRef);
  }

  function hasMobileOverrides(compRef: CompRef | CompVariantPointer) {
    if (!compRef.variants?.length) {
      compRef = getCompRefs(compRef).compRefWithMobileVariant;
    }

    return !!editorAPI.components.reactions.get(compRef);
  }

  const loop = {
    getDependentEffect(compRef: CompRef) {
      return findReaction(compRef, 'entrance')?.effect;
    },
    getAnotherTriggerType(triggerType: TriggerType) {
      if (triggerType === 'page-visible') return 'animation-end';

      if (triggerType === 'animation-end') return 'page-visible';
    },
  };

  return {
    getCompRefs,
    getTriggerParams,
    findTriggerRef,
    getTriggerRef,
    hasReactionsInTrigger,
    removeTriggerIfNeeded,
    findReactionByTriggerType,
    findReaction,
    createReaction,
    removeReaction,
    disableReaction,
    setEmptyEffect,
    getEffectData,
    hasMobileOverrides,
    loop,
  };
};
