'use strict'

const columnsClassicSection = require('../sections/strip/columnsClassicSection')
const columnsContactSection = require('../sections/strip/columnsContactSelection')
const columnsWelcomeSection = require('../sections/strip/columnsWelcomeSection')
const columnsAboutSection = require('../sections/strip/columnsAboutSection')
const columnsServicesSection = require('../sections/strip/columnsServicesSection')
const columnsTeamSection = require('../sections/strip/columnsTeamSection')
const columnsTestimonialsSection = require('../sections/strip/columnsTestimonialsSection')
const stripsSection = require('../sections/strip/stripsSection')

module.exports = {
    stripContainerContactSection: columnsContactSection,
    stripContainerClassicSection: columnsClassicSection,
    stripContainerWelcomeSection: columnsWelcomeSection,
    stripContainerAboutSection: columnsAboutSection,
    stripContainerServicesSection: columnsServicesSection,
    stripContainerTeamSection: columnsTeamSection,
    stripContainerTestimonialsSection: columnsTestimonialsSection,
    stripsSection
}
