// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['design_section_label_explore_playbutton'],
      sections: [
        addPanel.sections.getSections().music.singleTrackPlayerSection,
      ],
    };
  },
};
