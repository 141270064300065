import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'reactDOM';
import _ from 'lodash';

import {
  hoc,
  cx,
  workspace,
  animation,
  promiseUtils,
  type CancelablePromise,
} from '@/util';
import * as coreBi from '@/coreBi';

import connect from './mobileFrameConnect';
import {
  MobileFrameComponent as DefaultMobileFrameComponent,
  NewWorkspaceMobileFrameComponent,
} from './mobileFrameComponent';

import type { SendBiFunction } from 'types/bi';
import type { MobileFramePosition } from 'types/core';

const { makeCancelablePromise, isCancelablePromiseCancelError } = promiseUtils;

export interface MobileFrameOwnProps {
  frameless: boolean;
}

export interface MobileFrameStateProps {
  siteScale: number;
  previewMode: boolean;
  siteX: number;
  shouldHideShadow: boolean;
}

export interface MobileFrameDispatchProps {
  refreshMobileFramePosition: (rect: MobileFramePosition) => void;
  sendBI: SendBiFunction;
}

type MobileFrameProps = MobileFrameOwnProps &
  MobileFrameDispatchProps &
  MobileFrameStateProps;

class MobileFrameComponent extends React.Component<MobileFrameProps> {
  static displayName = 'mobileFrame';

  // propTypes used in renderWhenMutated HOC
  static propTypes = {
    siteScale: PropTypes.number.isRequired,
    previewMode: PropTypes.bool.isRequired,
    siteX: PropTypes.number.isRequired,
    refreshMobileFramePosition: PropTypes.func.isRequired,
    sendBI: PropTypes.func,
  };

  private isNewWorkspace: boolean = workspace.isNewWorkspaceEnabled();
  private waitingForUpdatePosition:
    | CancelablePromise<MobileFramePosition>
    | undefined;

  frameRef: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount() {
    this.updateMobileFramePosition();
  }

  componentDidUpdate() {
    this.updateMobileFramePosition();
  }

  componentWillUnmount() {
    this.waitingForUpdatePosition?.cancel();
  }

  mobileDeviceBtnClick = (event: AnyFixMe) => {
    const { clientX } = event;
    const centerX = window.innerWidth / 2;
    const distanceFromCenter = Math.abs(clientX - centerX);

    if (distanceFromCenter < 25) {
      const frameBottomDOMNode: HTMLElement = _.head(
        ReactDOM.findDOMNode(this).getElementsByClassName(
          'mobile-frame-bottom',
        ),
      );
      const boundingClientRect = frameBottomDOMNode.getBoundingClientRect();

      const yLocationRelativeToBottomFrameImg =
        event.clientY - boundingClientRect.top;

      if (
        yLocationRelativeToBottomFrameImg > 14 &&
        yLocationRelativeToBottomFrameImg < 58
      ) {
        this.props.sendBI(
          coreBi.events.mobileEditor.MOBILE_EDITOR_DEVICE_CLICKED,
        );
      }
    }
  };

  async updateMobileFramePosition() {
    this.waitingForUpdatePosition?.cancel();
    this.waitingForUpdatePosition = makeCancelablePromise(
      animation.waitForAnimationEnd<MobileFramePosition>(
        this.getFrameClientPosition,
      ),
    );

    try {
      const mobileFramePosition = await this.waitingForUpdatePosition.promise;

      this.props.refreshMobileFramePosition(mobileFramePosition);
    } catch (error) {
      if (!isCancelablePromiseCancelError(error)) {
        throw error;
      }
    }
  }

  getFrameClientPosition = () => {
    return _.pick(
      ReactDOM.findDOMNode(this.frameRef.current)?.getBoundingClientRect(),
      ['top', 'left', 'height', 'width'],
    );
  };

  getFrameStyle = () => {
    const style: any = {
      left: this.props.siteX,
    };

    //TODO Setting this property causes the hunging of the mobile frame during switch to Preview or Zoom Out.
    // It seems that without it mobile frame work fine.
    // Check if it needed for the new layout.
    if (this.isNewWorkspace) {
      delete style.left;
    }

    if (!this.props.previewMode && this.props.siteScale !== 1) {
      style.height = `${Math.round(100 / this.props.siteScale)}%`;
      style.transform = `scale(${this.props.siteScale})`;
    }

    return style;
  };

  render() {
    const className = cx('mobile-frame', {
      previewMode: this.props.previewMode,
      frameless: this.props.frameless,
    });

    const Component = this.isNewWorkspace
      ? NewWorkspaceMobileFrameComponent
      : DefaultMobileFrameComponent;

    return (
      <Component
        ref={this.frameRef}
        frameStyle={this.getFrameStyle()}
        className={className}
        noShadow={this.props.shouldHideShadow}
        frameless={this.props.frameless}
        onMobileDeviceBtnClick={this.mobileDeviceBtnClick}
      />
    );
  }
}

export default hoc.renderWhenMutated(MobileFrameComponent);

export const MobileFrame = _.flow(
  hoc.renderWhenMutated,
  connect,
)(MobileFrameComponent);

MobileFrame.pure = MobileFrameComponent;
