// @ts-nocheck
import constants from '@/constants';
import { createSource, PrivateSite } from '@wix/media-manager-sdk';
import _ from 'lodash';
import React from 'react';
import { translate } from '@/i18n';
import ItemsSection from './itemsSection';
import { constants as mediaManagerPanelConstants } from '@/mediaManagerPanelInfra';

const { MEDIA_MANAGER_MEDIA_TYPES } = constants;

const ALL_MEDIA_TYPES = Object.values(MEDIA_MANAGER_MEDIA_TYPES).join(',');

const { ITEMS_LOADING_OPTIONS } = mediaManagerPanelConstants;

const UPLOAD_SUCCEEDED = 'succeeded';

class SiteFilesSection extends React.Component {
  constructor(props) {
    super(props);

    this.uploadListenerCb = null;

    this.state = {
      key: 'site-files-section',
    };

    this.adapter = createSource(PrivateSite, {
      siteMediaToken: props.siteMediaToken,
      version: 'G6',
    });

    _.bindAll(this, ['loadMedia', 'handleMediaManagerOpen']);
  }

  componentDidMount() {
    this.uploadListenerCb = this.getUploadListenerCb();
    this.props.mediaManager.addUploadProgressListener(this.uploadListenerCb);
  }

  componentWillUnmount() {
    this.props.mediaManager.removeUploadProgressListener(this.uploadListenerCb);
  }

  getUploadListenerCb() {
    return (items: unknown[]) => {
      const isAllDone = items.every((item) => item.status === UPLOAD_SUCCEEDED);
      if (!isAllDone) return;
      this.setState({ key: items[0].itemId });
    };
  }

  loadMedia() {
    return this.adapter.items
      .recent(
        Object.assign({}, ITEMS_LOADING_OPTIONS, {
          mediaType: this.props.mediaType || ALL_MEDIA_TYPES,
        }),
      )
      .then((response) => response.data);
  }

  handleMediaManagerOpen() {
    this.props.openMediaManager({
      path: 'private',
      biSource: 'site-files-section',
    });
  }

  getItemsSectionProps() {
    return Object.assign(
      {
        title: this.props.title || 'Media_Panel_Recently_Added_Section_Label',
        loadMedia: this.loadMedia,
        action: this.handleMediaManagerOpen,
        helpTitle: this.props.helpTitle
          ? translate(this.props.helpTitle)
          : null,
        helpDescription: this.props.helpDescription
          ? translate(this.props.helpDescription)
          : null,
      },
      _.pick(this.props, [
        'id',
        'shouldDisplayPresetName',
        'presetWidth',
        'onItemClick',
        'showSectionHeader',
        'disabledContentPaddings',
        'disabledHeaderMargins',
        'contentStyle',
        'startItemDrag',
        'registerItem',
        'automationId',
      ]),
    );
  }

  render() {
    return (
      <ItemsSection key={this.state.key} {...this.getItemsSectionProps()} />
    );
  }
}

export default SiteFilesSection;
