import React, { useEffect, useRef, type FC } from 'react';

export const AutoScroller: FC<any> = ({ children, className, ...props }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    //setTimeout(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
    //}, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(props)]);

  return (
    <div className={className} ref={containerRef}>
      {children}
    </div>
  );
};
