import _ from 'lodash';
import {
  experiment,
  editorDataFunction,
  componentInstanceData,
} from '@wix/editor-component-data-resolver';

import { LEGACY_WIX_APPS_NAMES } from '@/constants';
import coreUtilsLib from 'coreUtilsLib';

import type { ComponentEditorMetaDataDefinition } from '../types';

const { blogAppPartNames } = coreUtilsLib;

const metaDataForDisabling = {
  duplicatable: false,
  resizable: false,
  rotatable: false,
  disabledKnobs: ['RESIZE_TOP', 'RESIZE_LEFT', 'RESIZE_BOTTOM', 'RESIZE_RIGHT'],
  verticallyResizable: false,
  horizontallyResizable: false,
};

const LEGACY_WIX_APPS_IDS = Object.values(LEGACY_WIX_APPS_NAMES);

const getMetadataForDisabling = () => {
  return _.mapValues(metaDataForDisabling, (value: any) => {
    return editorDataFunction(
      componentInstanceData('componentData'),
      experiment('newBlogMagicMigration'),
      (compData: AnyFixMe, newBlogMagicMigration) => {
        if (LEGACY_WIX_APPS_IDS.includes(compData.appPartName)) {
          return value;
        }
        if (newBlogMagicMigration) {
          return value;
        }
      },
    );
  });
};

const metaData: ComponentEditorMetaDataDefinition = {
  ...getMetadataForDisabling(),
  pinnable: false,
  selectable: true,
  containable: editorDataFunction(
    componentInstanceData('componentData'),
    (compData: AnyFixMe) => {
      const UNCONTAINABLE_APPPARTS = [
        blogAppPartNames.FEED,
        blogAppPartNames.SINGLE_POST,
        blogAppPartNames.RELATED_POSTS,
      ];

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      return !_.includes(UNCONTAINABLE_APPPARTS, compData.appPartName);
    },
  ),
};

export default metaData;
