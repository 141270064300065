import React from 'react';
import { InstalledAppActions } from './installedAppActions';
import { useSelectedApp } from '../selectedAppContext/selectedAppContext';

export const InstalledAppActionsButton = () => {
  const { app } = useSelectedApp().selectedApp;
  return (
    <InstalledAppActions
      origin="app details page"
      app={app}
      contextMenuProps={{
        dataHook: 'installed-app-actions-button',
        alignment: 'RIGHT',
        direction: 'MIDDLE',
      }}
    />
  );
};
