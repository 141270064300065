import React from 'react';
import _ from 'lodash';
import experiment from 'experiment';
import {
  tourMaker,
  AssistantOperation,
  type IAssistantTask,
} from '@wix/tour-maker';

import { EditorAPIKey, EditorCoreApiKey } from '@/apis';
import { i18n } from '@/i18n';
import * as util from '@/util';

import ToursTooltip from '../components/tourTooltip';
import createTourBIHandler from './createTourBIHandler';
import { editorLoaded } from '../tours/util';
import { getShouldOpenAgainState, OpenAgainStates } from '../utils/stageUtils';
import { TourNames, TOURS_CONFIG } from '../tours/config';
import { TourStartOrigin } from '../';

import type { Shell, SlotKey } from '@/apilib';

export interface ToursAPI {
  startTour: (
    tourToStart: IAssistantTask,
    origin?: string,
    options?: { shouldTranslate: boolean },
  ) => Promise<AssistantOperation>;
  startTourByName: (tourName: TourNames, origin: TourStartOrigin) => void;
  stopTour: () => void;
  isRunning: () => boolean;
  getActiveTour: () => IAssistantTask;
  createTour: (
    tourCreator: (shell: Shell, origin?: TourStartOrigin) => IAssistantTask,
    origin?: TourStartOrigin,
  ) => IAssistantTask;
  shouldShowTour: (tourName: TourNames) => boolean;
  startOnLoad: (tourName: TourNames) => void;
}

export const createToursAPI = (shell: Shell): ToursAPI => {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const tourBIHandler = createTourBIHandler(editorAPI);

  const initTourManager = _.once((options?: { shouldTranslate?: boolean }) => {
    tourMaker.config({
      translateFn: options.shouldTranslate ? (str) => i18n.t(str) : undefined,
      tooltipComponent: ToursTooltip,
      highestZIndex: 1000000001,
      customArrowComponent: function customArrowComponent() {
        return React.createElement('div');
      },
      customBackdropStyle: {
        background: '#000',
        opacity: 0.4,
      },
      eventHandler: tourBIHandler,
    });
  });

  return {
    async startTour(
      tourToStart,
      origin?,
      { shouldTranslate } = { shouldTranslate: true },
    ) {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.TOUR_START,
      );

      initTourManager({ shouldTranslate });

      tourToStart.customData = { origin };

      const result = await tourMaker.startTask(tourToStart);

      if (
        result === AssistantOperation.Complete ||
        result === AssistantOperation.Stop
      ) {
        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.TOUR_START,
        );
      }

      return result;
    },
    startTourByName(tourName: TourNames, origin) {
      const { create: tourConfig } = TOURS_CONFIG[tourName];

      const tour = this.createTour(tourConfig, origin);
      this.startTour(tour);
    },
    stopTour() {
      tourMaker.stop();
    },
    getActiveTour() {
      return tourMaker.getActiveTask();
    },
    isRunning() {
      return !!this.getActiveTour();
    },
    createTour(tourCreator, origin) {
      return tourCreator(shell, origin);
    },
    shouldShowTour(tourName: TourNames) {
      const editorAPI = shell.getAPI(EditorAPIKey);
      const { key } = TOURS_CONFIG[tourName];
      const shouldShowTourAgain = getShouldOpenAgainState(editorAPI, key);

      return shouldShowTourAgain !== OpenAgainStates.SHOULD_NOT_OPEN_AGAIN;
    },
    async startOnLoad(tourName: TourNames) {
      const editorAPI = shell.getAPI(EditorAPIKey);
      const editorCoreAPI = shell.getAPI(EditorCoreApiKey);

      const { key, create: tourConfig } = TOURS_CONFIG[tourName];

      await editorLoaded(editorAPI, editorCoreAPI);

      const shouldShowTourAgain = getShouldOpenAgainState(editorAPI, key);

      if (
        shouldShowTourAgain !== OpenAgainStates.SHOULD_NOT_OPEN_AGAIN ||
        experiment.isOpen('se_sectionsMigrationAlwaysOpenFTE')
      ) {
        const tour = this.createTour(tourConfig, TourStartOrigin.AppInit);
        await this.startTour(tour);
      }
    },
  };
};

export const ToursApiKey: SlotKey<ToursAPI> = {
  name: 'editorTours',
  public: true,
};
