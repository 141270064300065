// @ts-nocheck
import _ from 'lodash';

const createComponentHandleFromStructure = (compStructure, width, height) => ({
  structure: compStructure,
  position: null,
  absoluteDimensions: {
    width,
    height,
  },
});

function getPlaceholderXY(
  element,
  selectedItem,
  isPolygonSection,
  elementBoundingRect,
) {
  if (isPolygonSection) {
    return {
      x: selectedItem.rect.left,
      y: selectedItem.rect.top,
    };
  }
  return elementBoundingRect;
}

function getMouseRelativePosition(
  clientX,
  clientY,
  elementBoundingRect,
  x,
  y,
  isPolygonSection,
) {
  return isPolygonSection
    ? {
        x: clientX - x,
        y: clientY - y,
      }
    : {
        x: clientX - elementBoundingRect.x,
        y: clientY - elementBoundingRect.y,
      };
}

function createDragPayload(
  responsiveStructure,
  options,
  width,
  height,
  mouseRelativePosition,
  rect,
  x,
  y,
  clientX,
  clientY,
) {
  return {
    placeholder: {
      type: 'css',
      style: {
        width: rect.width,
        height: rect.height,
        background: 'rgba(31, 119, 255, 0.3) no-repeat center center',
        border: '1px solid #2077ff',
      },
      initialPosition: {
        x,
        y,
      },
    },
    placeholderDimensions: {
      height,
      width,
    },
    mouseRelativePosition,
    data: {
      componentStructure: {
        structure: responsiveStructure,
        placeholderDimensions: {
          width: rect.width,
          height: rect.height,
        },
        actualDimensions: {
          width,
          height,
        },
        options,
      },
    },
    origin: { x: clientX, y: clientY },
  };
}

function getComponentDimensions(rect, placeholder) {
  if (placeholder) {
    return {
      width: placeholder.width,
      height: placeholder.height,
    };
  }

  return {
    width: rect?.width ?? 100,
    height: rect?.height ?? 100,
  };
}

export default {
  getEditorPresetDragPayload(
    clientX,
    clientY,
    sectionItems,
    structureTransformation,
    options,
    polygonSectionItem,
  ) {
    const isPolygonSection =
      polygonSectionItem && !!sectionItems[0].preset.shape;
    const element = _.head(window.document.elementsFromPoint(clientX, clientY));
    const elementId = element?.id;
    const selectedItemIndex = isPolygonSection
      ? undefined
      : sectionItems.findIndex((item) => item.id === elementId);
    const selectedItem = isPolygonSection
      ? polygonSectionItem
      : sectionItems[selectedItemIndex];
    if (!selectedItem) {
      return null;
    }

    options.presetId = selectedItem.id || selectedItem.itemId;
    options.position = selectedItemIndex;

    const elementBoundingRect = element.getBoundingClientRect();

    const rect = selectedItem.rect || selectedItem.preset.rect;

    const { width, height } = getComponentDimensions(
      rect,
      selectedItem.placeholder,
    );

    const { x, y } = getPlaceholderXY(
      element,
      selectedItem,
      isPolygonSection,
      elementBoundingRect,
    );
    const mouseRelativePosition = getMouseRelativePosition(
      clientX,
      clientY,
      elementBoundingRect,
      x,
      y,
      isPolygonSection,
    );

    const compHandle = createComponentHandleFromStructure(
      selectedItem.structure,
      width,
      height,
    );
    const newCompStructure =
      selectedItem.structure.layoutResponsive || selectedItem.structure.layouts
        ? selectedItem.structure
        : structureTransformation(compHandle);

    return createDragPayload(
      newCompStructure,
      options,
      width,
      height,
      mouseRelativePosition,
      rect,
      x,
      y,
      clientX,
      clientY,
    );
  },
  getDragPayloadFromPresetPayload(payload, { clientX, clientY }) {
    if (!payload) {
      return;
    }

    const {
      mouseRelativePosition,
      placeholderDimensions,
      forceUseActualDimensions,
      actualDimensions,
      placeholderPosition,
      structure,
      options,
    } = payload;
    // TODO: Can we find a better way to get component height / width ?
    const dimensions = forceUseActualDimensions
      ? actualDimensions
      : placeholderDimensions;
    if (!dimensions) {
      return;
    }

    const { width, height } = dimensions;
    return createDragPayload(
      structure,
      options,
      width,
      height,
      mouseRelativePosition,
      placeholderDimensions,
      placeholderPosition.x,
      placeholderPosition.y,
      clientX,
      clientY,
    );
  },
};
