:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._wrapper_sylmo_7 {
  padding: 6px 0;
  position: relative; }

._button_sylmo_11 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%; }
  ._button_sylmo_11:hover, ._button_sylmo_11._active_sylmo_17 {
    fill: #116dff; }
  ._button_sylmo_11._disabled_sylmo_19, ._button_sylmo_11:disabled {
    fill: #868aa5;
    pointer-events: none; }

._floating-block_sylmo_23 {
  position: absolute;
  left: calc(100% + 12px);
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  pointer-events: none; }
  ._wrapper_sylmo_7:hover ._floating-block_sylmo_23 {
    opacity: 1; }
