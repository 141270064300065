{
  "v": "5.9.0",
  "fr": 25,
  "ip": 0,
  "op": 275,
  "w": 640,
  "h": 670,
  "nm": "Comp 1",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 17,
      "h": 18,
      "p": "http://static.parastorage.com/services/santa-resources/dist/editor/ai/panels/welcomePanel/components/questionnairePanel/animationPanel/star.png",
      "e": 0
    },
    {
      "id": "image_1",
      "w": 563,
      "h": 503,
      "p": "http://static.parastorage.com/services/santa-resources/dist/editor/ai/panels/welcomePanel/components/questionnairePanel/animationPanel/card.v9.png",
      "e": 0
    },
    {
      "id": "comp_0",
      "nm": "Your site is ready!!!",
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 5,
          "nm": "Your Site is Ready!",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0], "y": [1] },
                  "o": { "x": [0.2], "y": [0] },
                  "t": 193,
                  "s": [0]
                },
                { "t": 202, "s": [100] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "s": true,
              "x": { "a": 0, "k": 353, "ix": 3 },
              "y": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0], "y": [1] },
                    "o": { "x": [0.2], "y": [0] },
                    "t": 193,
                    "s": [636]
                  },
                  { "t": 202, "s": [630] }
                ],
                "ix": 4
              }
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [200, 30],
                    "ps": [-100, -15],
                    "s": 20,
                    "f": "WixMadeforTextApp-Regular",
                    "t": "Your Site is Ready!",
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 26,
                    "ls": 0,
                    "fc": [1, 1, 1]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": { "g": 1, "a": { "a": 0, "k": [0, 0], "ix": 2 } },
            "a": [
              {
                "nm": "Animator 1",
                "s": {
                  "t": 0,
                  "xe": { "a": 0, "k": 0, "ix": 7 },
                  "ne": { "a": 0, "k": 0, "ix": 8 },
                  "a": { "a": 0, "k": 100, "ix": 4 },
                  "b": 1,
                  "rn": 0,
                  "sh": 1,
                  "sm": { "a": 0, "k": 62, "ix": 6 },
                  "s": { "a": 0, "k": 100, "ix": 1 },
                  "e": { "a": 0, "k": 0, "ix": 2 },
                  "r": 1
                },
                "a": { "o": { "a": 0, "k": 0, "ix": 9 } }
              },
              {
                "nm": "Animator 2",
                "s": {
                  "t": 0,
                  "xe": { "a": 0, "k": 0, "ix": 7 },
                  "ne": { "a": 0, "k": 0, "ix": 8 },
                  "a": { "a": 0, "k": 100, "ix": 4 },
                  "b": 1,
                  "rn": 0,
                  "sh": 1,
                  "sm": { "a": 0, "k": 98, "ix": 6 },
                  "r": 1
                },
                "a": { "o": { "a": 0, "k": 100, "ix": 9 } }
              }
            ]
          },
          "ip": 182,
          "op": 682,
          "st": 182,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "Circle",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [339.412, 577.198, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [22, 22, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "d": 1,
                  "ty": "el",
                  "s": { "a": 0, "k": [229.743, 229.743], "ix": 2 },
                  "p": { "a": 0, "k": [0, 0], "ix": 3 },
                  "nm": "Ellipse Path 1",
                  "mn": "ADBE Vector Shape - Ellipse",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 12, "ix": 5 },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [-550.13, 206.067], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [69.843, 69.843], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Ellipse 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": { "a": 0, "k": 0, "ix": 1 },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0], "y": [1] },
                    "o": { "x": [0.8], "y": [0] },
                    "t": 195,
                    "s": [0]
                  },
                  { "t": 211, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 83,
          "op": 583,
          "st": 83,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          "nm": "Check Mark",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [387.125, 935.375, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-176.25, -310.75],
                        [-170, -306],
                        [-162.5, -320]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 2, "ix": 5 },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
                  "o": { "a": 0, "k": 0, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": { "a": 0, "k": 0, "ix": 1 },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 191,
                    "s": [0]
                  },
                  { "t": 198, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 8,
          "op": 464,
          "st": -36,
          "bm": 0
        }
      ]
    },
    {
      "id": "comp_1",
      "nm": "All",
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 5,
          "nm": "Generating Text...",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.833], "y": [0.833] },
                  "o": { "x": [0.167], "y": [0.167] },
                  "t": 94,
                  "s": [0]
                },
                {
                  "i": { "x": [0], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 100,
                  "s": [100]
                },
                {
                  "i": { "x": [0], "y": [1] },
                  "o": { "x": [0.2], "y": [0] },
                  "t": 191,
                  "s": [100]
                },
                { "t": 201, "s": [0] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "s": true,
              "x": { "a": 0, "k": 353, "ix": 3 },
              "y": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0], "y": [1] },
                    "o": { "x": [0.2], "y": [0] },
                    "t": 191,
                    "s": [630]
                  },
                  { "t": 201, "s": [621] }
                ],
                "ix": 4
              }
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [200, 30],
                    "ps": [-100, -15],
                    "s": 20,
                    "f": "WixMadeforTextApp-Regular",
                    "t": "Generating Text...",
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 26,
                    "ls": 0,
                    "fc": [1, 1, 1]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": { "g": 1, "a": { "a": 0, "k": [0, 0], "ix": 2 } },
            "a": [
              {
                "nm": "Animator 1",
                "s": {
                  "t": 0,
                  "xe": { "a": 0, "k": 0, "ix": 7 },
                  "ne": { "a": 0, "k": 0, "ix": 8 },
                  "a": { "a": 0, "k": 100, "ix": 4 },
                  "b": 1,
                  "rn": 0,
                  "sh": 1,
                  "sm": { "a": 0, "k": 62, "ix": 6 },
                  "s": { "a": 0, "k": 100, "ix": 1 },
                  "e": { "a": 0, "k": 0, "ix": 2 },
                  "r": 1
                },
                "a": { "o": { "a": 0, "k": 0, "ix": 9 } }
              },
              {
                "nm": "Animator 2",
                "s": {
                  "t": 0,
                  "xe": { "a": 0, "k": 0, "ix": 7 },
                  "ne": { "a": 0, "k": 0, "ix": 8 },
                  "a": { "a": 0, "k": 100, "ix": 4 },
                  "b": 1,
                  "rn": 0,
                  "sh": 1,
                  "sm": { "a": 0, "k": 100, "ix": 6 },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.833], "y": [0.833] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "t": 125,
                        "s": [83]
                      },
                      {
                        "i": { "x": [0.833], "y": [0.833] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "t": 137,
                        "s": [100]
                      },
                      {
                        "i": { "x": [0.833], "y": [0.833] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "t": 156,
                        "s": [83]
                      },
                      {
                        "i": { "x": [0.833], "y": [0.833] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "t": 168,
                        "s": [100]
                      },
                      {
                        "i": { "x": [0.833], "y": [0.833] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "t": 188,
                        "s": [83]
                      },
                      { "t": 200, "s": [100] }
                    ],
                    "ix": 2
                  },
                  "r": 1
                },
                "a": { "o": { "a": 0, "k": 100, "ix": 9 } }
              }
            ]
          },
          "ip": 0,
          "op": 500,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          "nm": "Big Star 3",
          "refId": "image_0",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 360, "ix": 10 },
            "p": { "a": 0, "k": [143, 394.75, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [8.5, 9, 0], "ix": 1, "l": 2 },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 145,
                  "s": [0, 0, 100]
                },
                {
                  "i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 154,
                  "s": [148, 148, 100]
                },
                { "t": 165, "s": [0, 0, 100] }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 500,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 2,
          "nm": "Big Star 2",
          "refId": "image_0",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 360, "ix": 10 },
            "p": { "a": 0, "k": [473, 334.75, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [8.5, 9, 0], "ix": 1, "l": 2 },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 131,
                  "s": [0, 0, 100]
                },
                {
                  "i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 140,
                  "s": [130, 130, 100]
                },
                { "t": 153, "s": [0, 0, 100] }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 500,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 2,
          "nm": "Big Star 1",
          "refId": "image_0",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 360, "ix": 10 },
            "p": { "a": 0, "k": [223, 274.75, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [8.5, 9, 0], "ix": 1, "l": 2 },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 102,
                  "s": [0, 0, 100]
                },
                {
                  "i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 111,
                  "s": [92, 92, 100]
                },
                { "t": 123, "s": [0, 0, 100] }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 500,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 2,
          "nm": "Glyph.png",
          "cl": "png",
          "refId": "image_0",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 120,
                  "s": [360]
                },
                { "t": 149, "s": [0] }
              ],
              "ix": 10
            },
            "p": { "a": 0, "k": [223, 614.75, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [8.5, 9, 0], "ix": 1, "l": 2 },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 90,
                  "s": [0, 0, 100]
                },
                {
                  "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 99,
                  "s": [92, 92, 100]
                },
                {
                  "i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
                  "o": { "x": [0.167, 0.167, 0.167], "y": [0, 0, 0] },
                  "t": 107,
                  "s": [72, 72, 100]
                },
                {
                  "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 132,
                  "s": [0, 0, 100]
                },
                {
                  "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 141,
                  "s": [92, 92, 100]
                },
                {
                  "i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
                  "o": { "x": [0.167, 0.167, 0.167], "y": [0, 0, 0] },
                  "t": 149,
                  "s": [72, 72, 100]
                },
                { "t": 157, "s": [0, 0, 100] }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 500,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 2,
          "nm": "Glyph.png",
          "cl": "png",
          "refId": "image_0",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.667], "y": [1] },
                  "o": { "x": [0.333], "y": [0] },
                  "t": 128,
                  "s": [0]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 142,
                  "s": [360]
                },
                { "t": 171, "s": [0] }
              ],
              "ix": 10
            },
            "p": { "a": 0, "k": [213.5, 625.5, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [8.5, 9, 0], "ix": 1, "l": 2 },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.2, 0.2, 0.833], "y": [1, 1, 1] },
                  "o": { "x": [0.2, 0.2, 0.167], "y": [0, 0, 0] },
                  "t": 111,
                  "s": [0, 0, 100]
                },
                {
                  "i": { "x": [0.2, 0.2, 0.833], "y": [1, 1, 1] },
                  "o": { "x": [0.2, 0.2, 0.167], "y": [0, 0, 0] },
                  "t": 119,
                  "s": [120, 120, 100]
                },
                {
                  "i": { "x": [0.2, 0.2, 0.833], "y": [1, 1, 1] },
                  "o": { "x": [0.167, 0.167, 0.167], "y": [0, 0, 0] },
                  "t": 128,
                  "s": [100, 100, 100]
                },
                {
                  "i": { "x": [0.2, 0.2, 0.833], "y": [1, 1, 1] },
                  "o": { "x": [0.2, 0.2, 0.167], "y": [0, 0, 0] },
                  "t": 162,
                  "s": [120, 120, 100]
                },
                {
                  "i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
                  "o": { "x": [0.167, 0.167, 0.167], "y": [0, 0, 0] },
                  "t": 171,
                  "s": [100, 100, 100]
                },
                { "t": 177, "s": [0, 0, 100] }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 500,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 5,
          "nm": "Belle Boutique is a women’s clothing boutique that specializes in unique, high-quality pieces. We have a variety of styles and are always updating our inventory. Our stylists are passionate about helping our customers always find the perfect outfit. ",
          "sr": 1.3,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "s": true,
              "x": { "a": 0, "k": 307.5, "ix": 3 },
              "y": { "a": 0, "k": 381, "ix": 4 }
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [359.000061035156, 311],
                    "ps": [-167.000030517578, -45],
                    "s": 17,
                    "f": "WixMadeforTextApp-Regular",
                    "t": "Belle Boutique is a women’s clothing boutique that specializes in unique, high-quality pieces. We have a variety of styles and are always updating our inventory. Our stylists are passionate about helping our customers always find the perfect outfit. ",
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 26,
                    "ls": 0,
                    "fc": [1, 1, 1]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": { "g": 2, "a": { "a": 0, "k": [0, 0], "ix": 2 } },
            "a": [
              {
                "nm": "Animator 1",
                "s": {
                  "t": 0,
                  "xe": { "a": 0, "k": 0, "ix": 7 },
                  "ne": { "a": 0, "k": 0, "ix": 8 },
                  "a": { "a": 0, "k": 100, "ix": 4 },
                  "b": 1,
                  "rn": 0,
                  "sh": 1,
                  "sm": { "a": 0, "k": 100, "ix": 6 },
                  "s": { "a": 0, "k": 100, "ix": 1 },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.2], "y": [1] },
                        "o": { "x": [0.2], "y": [0] },
                        "t": 132.008,
                        "s": [0]
                      },
                      { "t": 199.999544270833, "s": [100] }
                    ],
                    "ix": 2
                  },
                  "r": 1
                },
                "a": { "o": { "a": 0, "k": 0, "ix": 9 } }
              }
            ]
          },
          "ip": 54.0083333333333,
          "op": 272.3975,
          "st": 54.0083333333333,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 5,
          "nm": "Our Story",
          "sr": 1.3,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "s": true,
              "x": { "a": 0, "k": 319.349, "ix": 3 },
              "y": { "a": 0, "k": 323, "ix": 4 }
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [302, 90],
                    "ps": [-151, -45],
                    "s": 42,
                    "f": "WixMadeforDisplayApp-Bold",
                    "t": "Our Story",
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 35,
                    "ls": 0,
                    "fc": [1, 1, 1]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": { "g": 1, "a": { "a": 0, "k": [0, 0], "ix": 2 } },
            "a": [
              {
                "nm": "Animator 1",
                "s": {
                  "t": 0,
                  "xe": { "a": 0, "k": 0, "ix": 7 },
                  "ne": { "a": 0, "k": 0, "ix": 8 },
                  "a": { "a": 0, "k": 100, "ix": 4 },
                  "b": 1,
                  "rn": 0,
                  "sh": 1,
                  "sm": { "a": 0, "k": 100, "ix": 6 },
                  "s": { "a": 0, "k": 100, "ix": 1 },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0], "y": [1] },
                        "o": { "x": [0.5], "y": [0] },
                        "t": 111.197,
                        "s": [0]
                      },
                      { "t": 150.388216145833, "s": [100] }
                    ],
                    "ix": 2
                  },
                  "r": 1
                },
                "a": { "o": { "a": 0, "k": 0, "ix": 9 } }
              }
            ]
          },
          "ip": 54.0083333333333,
          "op": 272.3975,
          "st": 54.0083333333333,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 5,
          "nm": "Every website has a story and users want to hear yours. This is a great opportunity to give a full background on who you are and what your site has to offer. You may like to talk about how you got started and share your professional journey. ",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.2], "y": [1] },
                  "o": { "x": [0], "y": [0] },
                  "t": 29,
                  "s": [0]
                },
                { "t": 44, "s": [100] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "s": true,
              "x": { "a": 0, "k": 307.5, "ix": 3 },
              "y": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 29,
                    "s": [397]
                  },
                  { "t": 44, "s": [381] }
                ],
                "ix": 4
              }
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [327, 311],
                    "ps": [-151, -45],
                    "s": 17,
                    "f": "WixMadeforTextApp-Regular",
                    "t": "Every website has a story and users want to hear yours. This is a great opportunity to give a full background on who you are and what your site has to offer. You may like to talk about how you got started and share your professional journey. ",
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 26,
                    "ls": 0,
                    "fc": [1, 1, 1]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": { "g": 2, "a": { "a": 0, "k": [0, 0], "ix": 2 } },
            "a": [
              {
                "nm": "Animator 1",
                "s": {
                  "t": 0,
                  "xe": { "a": 0, "k": 0, "ix": 7 },
                  "ne": { "a": 0, "k": 0, "ix": 8 },
                  "a": { "a": 0, "k": 100, "ix": 4 },
                  "b": 1,
                  "rn": 0,
                  "sh": 1,
                  "sm": { "a": 0, "k": 100, "ix": 6 },
                  "s": { "a": 0, "k": 100, "ix": 1 },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.2], "y": [1] },
                        "o": { "x": [0.2], "y": [0] },
                        "t": 60,
                        "s": [100]
                      },
                      { "t": 82, "s": [0] }
                    ],
                    "ix": 2
                  },
                  "r": 1
                },
                "a": { "o": { "a": 0, "k": 0, "ix": 9 } }
              }
            ]
          },
          "ip": 0,
          "op": 500,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 5,
          "nm": "I’m a Title",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.2], "y": [1] },
                  "o": { "x": [0.2], "y": [0] },
                  "t": 17,
                  "s": [0]
                },
                { "t": 33, "s": [100] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "s": true,
              "x": { "a": 0, "k": 319.349, "ix": 3 },
              "y": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.984], "y": [0.833] },
                    "o": { "x": [0.016], "y": [0.167] },
                    "t": 17,
                    "s": [333]
                  },
                  {
                    "i": { "x": [0.833], "y": [1] },
                    "o": { "x": [0.003], "y": [-1.345] },
                    "t": 33,
                    "s": [323]
                  },
                  { "t": 100, "s": [323] }
                ],
                "ix": 4
              }
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [302, 90],
                    "ps": [-151, -45],
                    "s": 42,
                    "f": "WixMadeforDisplayApp-Bold",
                    "t": "I’m a Title",
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 35,
                    "ls": 0,
                    "fc": [1, 1, 1]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": { "g": 1, "a": { "a": 0, "k": [0, 0], "ix": 2 } },
            "a": [
              {
                "nm": "Animator 1",
                "s": {
                  "t": 0,
                  "xe": { "a": 0, "k": 0, "ix": 7 },
                  "ne": { "a": 0, "k": 0, "ix": 8 },
                  "a": { "a": 0, "k": 100, "ix": 4 },
                  "b": 1,
                  "rn": 0,
                  "sh": 1,
                  "sm": { "a": 0, "k": 100, "ix": 6 },
                  "s": { "a": 0, "k": 100, "ix": 1 },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0], "y": [1] },
                        "o": { "x": [0.5], "y": [0] },
                        "t": 80,
                        "s": [100]
                      },
                      { "t": 101, "s": [0] }
                    ],
                    "ix": 2
                  },
                  "r": 1
                },
                "a": { "o": { "a": 0, "k": 0, "ix": 9 } }
              }
            ]
          },
          "ip": 0,
          "op": 500,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 11,
          "ty": 2,
          "nm": "Glass Frame",
          "refId": "image_1",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [305, 384, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [281.5, 251.5, 0], "ix": 1, "l": 2 },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0, 0, 0.833], "y": [1, 1, 1] },
                  "o": { "x": [0.2, 0.2, 0.167], "y": [0, 0, 0] },
                  "t": 0,
                  "s": [0, 0, 100]
                },
                { "t": 9, "s": [100, 100, 100] }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 500,
          "st": 0,
          "bm": 0
        }
      ]
    }
  ],
  "fonts": {
    "list": [
      {
        "fName": "WixMadeforDisplayApp-Bold",
        "fFamily": "Wix Madefor Display App",
        "fStyle": "Bold",
        "ascent": 71.9985961914062
      },
      {
        "fName": "WixMadeforTextApp-Regular",
        "fFamily": "Wix Madefor Text App",
        "fStyle": "Regular",
        "ascent": 71.9985961914062
      }
    ]
  },
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "Your site is ready!!!",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 232,
              "s": [100]
            },
            { "t": 237, "s": [0] }
          ],
          "ix": 11
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": {
          "s": true,
          "x": { "a": 0, "k": 320, "ix": 3 },
          "y": {
            "a": 1,
            "k": [
              {
                "i": { "x": [0.667], "y": [1] },
                "o": { "x": [0.333], "y": [0] },
                "t": 232,
                "s": [384]
              },
              { "t": 237, "s": [374] }
            ],
            "ix": 4
          }
        },
        "a": { "a": 0, "k": [320, 384, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "w": 640,
      "h": 768,
      "ip": 0,
      "op": 500,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 0,
      "nm": "All",
      "refId": "comp_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 241,
              "s": [100]
            },
            { "t": 246, "s": [0] }
          ],
          "ix": 11
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": {
          "s": true,
          "x": { "a": 0, "k": 320, "ix": 3 },
          "y": {
            "a": 1,
            "k": [
              {
                "i": { "x": [0.667], "y": [1] },
                "o": { "x": [0.333], "y": [0] },
                "t": 241,
                "s": [384]
              },
              { "t": 246, "s": [354] }
            ],
            "ix": 4
          }
        },
        "a": { "a": 0, "k": [320, 384, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "w": 640,
      "h": 768,
      "ip": 0,
      "op": 500,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": [],
  "chars": [
    {
      "ch": "I",
      "size": 42,
      "style": "Bold",
      "w": 27.7,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [20.6, -71.5],
                      [7.1, -71.5],
                      [7.1, 0],
                      [20.6, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "I",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "I",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "’",
      "size": 42,
      "style": "Bold",
      "w": 26.3,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.334, 2.2],
                      [-0.834, 2.3],
                      [0, 1.934],
                      [1.533, 1.734],
                      [2.466, 0],
                      [1.466, -1.533],
                      [0, -2.2],
                      [-1.1, -1.366],
                      [-1.734, -0.4],
                      [1.166, -2.333],
                      [1.733, -2],
                      [0, 0]
                    ],
                    "o": [
                      [1.333, -2.2],
                      [0.833, -2.3],
                      [0, -2.733],
                      [-1.534, -1.733],
                      [-2.267, 0],
                      [-1.467, 1.534],
                      [0, 1.8],
                      [1.1, 1.367],
                      [-0.267, 2],
                      [-1.167, 2.334],
                      [0, 0],
                      [1.533, -1.733]
                    ],
                    "v": [
                      [17, -52.3],
                      [20.25, -59.05],
                      [21.5, -65.4],
                      [19.2, -72.1],
                      [13.2, -74.7],
                      [7.6, -72.4],
                      [5.4, -66.8],
                      [7.05, -62.05],
                      [11.3, -59.4],
                      [9.15, -52.9],
                      [4.8, -46.4],
                      [12.7, -46.4]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "’",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "’",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "m",
      "size": 42,
      "style": "Bold",
      "w": 91.8,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [3.3, 3.7],
                      [5.8, 0],
                      [2.266, -0.7],
                      [1.933, -1.633],
                      [1.133, -2.933],
                      [2.7, 1.9],
                      [3.866, 0],
                      [2.933, -1.5],
                      [1.6, -4],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.1, 2.334],
                      [-1.767, 1.1],
                      [-2, 0],
                      [-1.634, -1.9],
                      [0, -3.866],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.334, 2.5],
                      [-3.067, 0],
                      [-1.6, -1.9],
                      [0, -3.866],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, -6.933],
                      [-3.3, -3.7],
                      [-2.267, 0],
                      [-2.267, 0.7],
                      [-1.934, 1.634],
                      [-1.134, -3.8],
                      [-2.7, -1.9],
                      [-3, 0],
                      [-2.934, 1.5],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -3.8],
                      [1.1, -2.333],
                      [1.766, -1.1],
                      [2.733, 0],
                      [1.633, 1.9],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -5.666],
                      [2.333, -2.5],
                      [2.733, 0],
                      [1.6, 1.9],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [86.5, -30.4],
                      [81.55, -46.35],
                      [67.9, -51.9],
                      [61.1, -50.85],
                      [54.8, -47.35],
                      [50.2, -40.5],
                      [44.45, -49.05],
                      [34.6, -51.9],
                      [25.7, -49.65],
                      [18.9, -41.4],
                      [18.9, -50.3],
                      [5.8, -50.3],
                      [5.8, 0],
                      [19, 0],
                      [19, -24.9],
                      [20.65, -34.1],
                      [24.95, -39.25],
                      [30.6, -40.9],
                      [37.15, -38.05],
                      [39.6, -29.4],
                      [39.6, 0],
                      [52.8, 0],
                      [52.8, -24.9],
                      [56.3, -37.15],
                      [64.4, -40.9],
                      [70.9, -38.05],
                      [73.3, -29.4],
                      [73.3, 0],
                      [86.5, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "m",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "m",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": " ",
      "size": 42,
      "style": "Bold",
      "w": 19.5,
      "data": {},
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "a",
      "size": 42,
      "style": "Bold",
      "w": 57.4,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-2.934, 1.333],
                      [-1.867, 2.334],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.066, 3.1],
                      [3.7, 1.667],
                      [4.933, 0],
                      [3.8, -1.5],
                      [2.666, -2.666],
                      [0, 0],
                      [-2.467, 1.134],
                      [-2.934, 0],
                      [-2.267, -1.8],
                      [-0.2, -3.266],
                      [0, 0],
                      [4.433, -2.933],
                      [0, -5.133],
                      [-1.634, -2.466],
                      [-2.934, -1.4],
                      [-3.867, 0]
                    ],
                    "o": [
                      [2.933, -1.333],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -4.466],
                      [-2.067, -3.1],
                      [-3.7, -1.666],
                      [-4.467, 0],
                      [-3.8, 1.5],
                      [0, 0],
                      [1.866, -1.933],
                      [2.466, -1.133],
                      [3.733, 0],
                      [2.266, 1.8],
                      [0, 0],
                      [-7.4, 0],
                      [-4.434, 2.934],
                      [0, 3.267],
                      [1.633, 2.467],
                      [2.933, 1.4],
                      [3.666, 0]
                    ],
                    "v": [
                      [33.1, -0.4],
                      [40.3, -5.9],
                      [40.3, 0],
                      [52.3, 0],
                      [52.3, -30.9],
                      [49.2, -42.25],
                      [40.55, -49.4],
                      [27.6, -51.9],
                      [15.2, -49.65],
                      [5.5, -43.4],
                      [12.4, -35.4],
                      [18.9, -40],
                      [27, -41.7],
                      [36, -39],
                      [39.7, -31.4],
                      [28.1, -31.4],
                      [10.35, -27],
                      [3.7, -14.9],
                      [6.15, -6.3],
                      [13, -0.5],
                      [23.2, 1.6]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "a",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [1.7, 1.3],
                      [0, 2.134],
                      [-2.2, 1.267],
                      [-3.6, 0],
                      [0, 0],
                      [0, 0],
                      [2.4, -1.466],
                      [3, 0]
                    ],
                    "o": [
                      [-1.7, -1.3],
                      [0, -2.4],
                      [2.2, -1.266],
                      [0, 0],
                      [0, 0],
                      [-1.134, 2.467],
                      [-2.4, 1.467],
                      [-2.734, 0]
                    ],
                    "v": [
                      [19.65, -10.25],
                      [17.1, -15.4],
                      [20.4, -20.9],
                      [29.1, -22.8],
                      [39.7, -22.8],
                      [39.7, -16.4],
                      [34.4, -10.5],
                      [26.3, -8.3]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "a",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "a",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "T",
      "size": 42,
      "style": "Bold",
      "w": 64.9,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [39.2, -59.9],
                      [61.6, -59.9],
                      [61.6, -71.5],
                      [3.3, -71.5],
                      [3.3, -59.9],
                      [25.7, -59.9],
                      [25.7, 0],
                      [39.2, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "T",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "T",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "i",
      "size": 42,
      "style": "Bold",
      "w": 24.9,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.534, 1.5],
                      [0, 2.134],
                      [1.533, 1.5],
                      [2.266, 0],
                      [1.533, -1.5],
                      [0, -2.2],
                      [-1.534, -1.5],
                      [-2.267, 0]
                    ],
                    "o": [
                      [1.533, -1.5],
                      [0, -2.2],
                      [-1.534, -1.5],
                      [-2.267, 0],
                      [-1.534, 1.5],
                      [0, 2.134],
                      [1.533, 1.5],
                      [2.266, 0]
                    ],
                    "v": [
                      [18.1, -61.25],
                      [20.4, -66.7],
                      [18.1, -72.25],
                      [12.4, -74.5],
                      [6.7, -72.25],
                      [4.4, -66.7],
                      [6.7, -61.25],
                      [12.4, -59]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "i",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [19, -50.3],
                      [5.8, -50.3],
                      [5.8, 0],
                      [19, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "i",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "i",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "t",
      "size": 42,
      "style": "Bold",
      "w": 40.8,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 3.467],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-9.134, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [-3.334, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 9.134],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [38.4, -10.8],
                      [30.3, -10.8],
                      [25.3, -16],
                      [25.3, -39.6],
                      [37.8, -39.6],
                      [37.8, -50.3],
                      [25.3, -50.3],
                      [25.3, -63.6],
                      [12.1, -63.6],
                      [12.1, -50.3],
                      [3.3, -50.3],
                      [3.3, -39.6],
                      [12.1, -39.6],
                      [12.1, -13.7],
                      [25.8, 0],
                      [38.4, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "t",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "t",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "l",
      "size": 42,
      "style": "Bold",
      "w": 25,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [19.1, -72],
                      [5.9, -72],
                      [5.9, 0],
                      [19.1, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "l",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "l",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "e",
      "size": 42,
      "style": "Bold",
      "w": 60.9,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [6.066, 0],
                      [2.9, 2.1],
                      [0.866, 3.934],
                      [0, 0],
                      [0, 0],
                      [2.2, 3.934],
                      [3.866, 2.234],
                      [5, 0],
                      [4.1, -2.266],
                      [2.366, -4.033],
                      [0, -5.266],
                      [-2.467, -4],
                      [-4.434, -2.266],
                      [-5.8, 0],
                      [-3.967, 1.633],
                      [-2.6, 2.867],
                      [0, 0]
                    ],
                    "o": [
                      [-4.2, 0],
                      [-2.9, -2.1],
                      [0, 0],
                      [0, 0],
                      [0, -5.2],
                      [-2.2, -3.933],
                      [-3.867, -2.233],
                      [-5.2, 0],
                      [-4.1, 2.267],
                      [-2.367, 4.034],
                      [0, 5.267],
                      [2.466, 4],
                      [4.433, 2.266],
                      [4.733, 0],
                      [3.966, -1.633],
                      [0, 0],
                      [-4.067, 4.6]
                    ],
                    "v": [
                      [34.1, -8.8],
                      [23.45, -11.95],
                      [17.8, -21],
                      [56.9, -21],
                      [56.9, -25.6],
                      [53.6, -39.3],
                      [44.5, -48.55],
                      [31.2, -51.9],
                      [17.25, -48.5],
                      [7.55, -39.05],
                      [4, -25.1],
                      [7.7, -11.2],
                      [18.05, -1.8],
                      [33.4, 1.6],
                      [46.45, -0.85],
                      [56.3, -7.6],
                      [49.3, -15.7]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "e",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-2.267, -2.166],
                      [-0.534, -3.666],
                      [0, 0],
                      [-2.367, 2.134],
                      [-3.467, 0]
                    ],
                    "o": [
                      [2.266, 2.167],
                      [0, 0],
                      [0.666, -3.733],
                      [2.366, -2.133],
                      [3.4, 0]
                    ],
                    "v": [
                      [39.6, -38.35],
                      [43.8, -29.6],
                      [17.8, -29.6],
                      [22.35, -38.4],
                      [31.1, -41.6]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "e",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "e",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "E",
      "size": 17,
      "style": "Regular",
      "w": 61.2,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [56.1, -7],
                      [19.3, -7],
                      [19.3, -33.1],
                      [54.3, -33.1],
                      [54.3, -40.1],
                      [19.3, -40.1],
                      [19.3, -64.5],
                      [56.1, -64.5],
                      [56.1, -71.5],
                      [11.3, -71.5],
                      [11.3, 0],
                      [56.1, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "E",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "E",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "v",
      "size": 17,
      "style": "Regular",
      "w": 53.8,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [50.6, -49.2],
                      [42.8, -49.2],
                      [27.1, -8],
                      [11.4, -49.2],
                      [3.2, -49.2],
                      [23.1, 0],
                      [30.7, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "v",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "v",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "e",
      "size": 17,
      "style": "Regular",
      "w": 57.6,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [2.466, -1.466],
                      [3.066, 0],
                      [3.1, 3.134],
                      [0.533, 4.934],
                      [0, 0],
                      [0, 0],
                      [1.933, 3.9],
                      [3.433, 2.2],
                      [4.466, 0],
                      [3.633, -2.266],
                      [2.1, -3.966],
                      [0, -5],
                      [-2.1, -3.966],
                      [-3.767, -2.266],
                      [-4.934, 0],
                      [-3.5, 1.766],
                      [-2.6, 3.6],
                      [0, 0]
                    ],
                    "o": [
                      [-2.467, 1.467],
                      [-4.867, 0],
                      [-3.1, -3.133],
                      [0, 0],
                      [0, 0],
                      [0, -5.066],
                      [-1.934, -3.9],
                      [-3.434, -2.2],
                      [-4.534, 0],
                      [-3.634, 2.267],
                      [-2.1, 3.967],
                      [0, 5],
                      [2.1, 3.967],
                      [3.766, 2.266],
                      [4.2, 0],
                      [3.5, -1.766],
                      [0, 0],
                      [-2, 3.067]
                    ],
                    "v": [
                      [39.2, -7.3],
                      [30.9, -5.1],
                      [18.95, -9.8],
                      [13.5, -21.9],
                      [52.2, -21.9],
                      [52.2, -24.9],
                      [49.3, -38.35],
                      [41.25, -47.5],
                      [29.4, -50.8],
                      [17.15, -47.4],
                      [8.55, -38.05],
                      [5.4, -24.6],
                      [8.55, -11.15],
                      [17.35, -1.8],
                      [30.4, 1.6],
                      [41.95, -1.05],
                      [51.1, -9.1],
                      [45.9, -14.1]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "e",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-2.634, -2.866],
                      [-0.467, -4.866],
                      [0, 0],
                      [-2.9, 2.934],
                      [-4.134, 0]
                    ],
                    "o": [
                      [2.633, 2.867],
                      [0, 0],
                      [0.6, -4.733],
                      [2.9, -2.933],
                      [4.133, 0]
                    ],
                    "v": [
                      [39.55, -39.8],
                      [44.2, -28.2],
                      [13.6, -28.2],
                      [18.85, -39.7],
                      [29.4, -44.1]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "e",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "e",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "r",
      "size": 17,
      "style": "Regular",
      "w": 37.6,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [3.266, -1.8],
                      [1.666, -4.266],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.6, 3.234],
                      [-2.634, 1.567],
                      [-3.067, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [-3.467, 0],
                      [-3.267, 1.8],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -5.066],
                      [1.6, -3.233],
                      [2.633, -1.566],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [33.6, -50],
                      [23.5, -47.3],
                      [16.1, -38.2],
                      [16.1, -49.2],
                      [8.5, -49.2],
                      [8.5, 0],
                      [16.2, 0],
                      [16.2, -20.6],
                      [18.6, -33.05],
                      [24.95, -40.25],
                      [33.5, -42.6],
                      [35.2, -42.6],
                      [35.2, -50]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "r",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "r",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "y",
      "size": 17,
      "style": "Regular",
      "w": 53.9,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.266, -1.067],
                      [2.266, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.034, 1.9],
                      [-1.334, 3.333],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.734, 2.066],
                      [-1.267, 1.066],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.333, 0],
                      [2.033, -1.9],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [27.1, -8],
                      [11.4, -49.2],
                      [3.2, -49.2],
                      [23.2, 0.2],
                      [20.9, 6.5],
                      [17.9, 11.2],
                      [12.6, 12.8],
                      [6.8, 12.8],
                      [6.8, 19.4],
                      [12.6, 19.4],
                      [22.15, 16.55],
                      [27.2, 8.7],
                      [50.6, -49.2],
                      [42.8, -49.2]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "y",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "y",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": " ",
      "size": 17,
      "style": "Regular",
      "w": 22.5,
      "data": {},
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "w",
      "size": 17,
      "style": "Regular",
      "w": 76.8,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [73, -49.2],
                      [65.4, -49.2],
                      [53.9, -7.9],
                      [42.3, -49.2],
                      [34.5, -49.2],
                      [22.9, -8.3],
                      [11.7, -49.2],
                      [3.8, -49.2],
                      [18.4, 0],
                      [27.1, 0],
                      [38.3, -39.5],
                      [49.6, 0],
                      [58.2, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "w",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "w",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "b",
      "size": 17,
      "style": "Regular",
      "w": 62.8,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [-3.067, -1.7],
                      [-3.867, 0],
                      [-3.567, 2.266],
                      [-2.067, 3.934],
                      [0, 5.067],
                      [2, 3.967],
                      [3.466, 2.267],
                      [4.533, 0],
                      [3.2, -1.933],
                      [1.666, -3.2],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [1.666, 2.867],
                      [3.066, 1.7],
                      [4.6, 0],
                      [3.566, -2.266],
                      [2.066, -3.933],
                      [0, -5],
                      [-2, -3.966],
                      [-3.467, -2.266],
                      [-3.934, 0],
                      [-3.2, 1.934],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [16.1, -7.8],
                      [23.2, -0.95],
                      [33.6, 1.6],
                      [45.85, -1.8],
                      [54.3, -11.1],
                      [57.4, -24.6],
                      [54.4, -38.05],
                      [46.2, -47.4],
                      [34.2, -50.8],
                      [23.5, -47.9],
                      [16.2, -40.2],
                      [16.2, -72],
                      [8.5, -72],
                      [8.5, 0],
                      [16.1, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "b",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.467, 3.134],
                      [-2.534, 1.767],
                      [-3.267, 0],
                      [-2.434, -1.666],
                      [-1.367, -2.933],
                      [0, -3.8],
                      [1.433, -2.933],
                      [2.5, -1.666],
                      [3.333, 0],
                      [2.933, 2.167],
                      [1.2, 3.534],
                      [0, 0]
                    ],
                    "o": [
                      [1.466, -3.133],
                      [2.533, -1.766],
                      [3.2, 0],
                      [2.433, 1.667],
                      [1.366, 2.934],
                      [0, 3.8],
                      [-1.434, 2.934],
                      [-2.5, 1.667],
                      [-3.8, 0],
                      [-2.934, -2.166],
                      [0, 0],
                      [0, -4.133]
                    ],
                    "v": [
                      [18.4, -34.1],
                      [24.4, -41.45],
                      [33.1, -44.1],
                      [41.55, -41.6],
                      [47.25, -34.7],
                      [49.3, -24.6],
                      [47.15, -14.5],
                      [41.25, -7.6],
                      [32.5, -5.1],
                      [22.4, -8.35],
                      [16.2, -16.9],
                      [16.2, -23.2]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "b",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "b",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "s",
      "size": 17,
      "style": "Regular",
      "w": 49.2,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-3.467, -1.633],
                      [-4.8, 0],
                      [-3.034, 1.3],
                      [-1.734, 2.367],
                      [0, 3.067],
                      [3, 2.367],
                      [7.266, 1.067],
                      [1.733, 1.1],
                      [0, 1.867],
                      [-2.067, 1.5],
                      [-3.267, 0],
                      [-2.434, -1.433],
                      [-1.867, -2.466],
                      [0, 0],
                      [3.266, 1.6],
                      [4.133, 0],
                      [3.533, -2.7],
                      [0, -4.533],
                      [-2.834, -2.133],
                      [-7, -1.133],
                      [-1.9, -1.3],
                      [0, -2.2],
                      [2.233, -1.666],
                      [3.6, 0],
                      [2.766, 1.434],
                      [2.133, 2.467],
                      [0, 0]
                    ],
                    "o": [
                      [3.466, 1.633],
                      [3.866, 0],
                      [3.033, -1.3],
                      [1.733, -2.366],
                      [0, -4.333],
                      [-3, -2.366],
                      [-4.4, -0.666],
                      [-1.734, -1.1],
                      [0, -2.333],
                      [2.066, -1.5],
                      [3.133, 0],
                      [2.433, 1.434],
                      [0, 0],
                      [-2.534, -2.933],
                      [-3.267, -1.6],
                      [-5.6, 0],
                      [-3.534, 2.7],
                      [0, 3.8],
                      [2.833, 2.134],
                      [4.6, 0.667],
                      [1.9, 1.3],
                      [0, 2.667],
                      [-2.234, 1.667],
                      [-3.467, 0],
                      [-2.767, -1.433],
                      [0, 0],
                      [2.733, 2.867]
                    ],
                    "v": [
                      [12, -0.85],
                      [24.4, 1.6],
                      [34.75, -0.35],
                      [41.9, -5.85],
                      [44.5, -14],
                      [40, -24.05],
                      [24.6, -29.2],
                      [15.4, -31.85],
                      [12.8, -36.3],
                      [15.9, -42.05],
                      [23.9, -44.3],
                      [32.25, -42.15],
                      [38.7, -36.3],
                      [43.7, -41.6],
                      [35, -48.4],
                      [23.9, -50.8],
                      [10.2, -46.75],
                      [4.9, -35.9],
                      [9.15, -27],
                      [23.9, -22.1],
                      [33.65, -19.15],
                      [36.5, -13.9],
                      [33.15, -7.4],
                      [24.4, -4.9],
                      [15.05, -7.05],
                      [7.7, -12.9],
                      [2.7, -7.6]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "s",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "s",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "i",
      "size": 17,
      "style": "Regular",
      "w": 24.7,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [1.033, 1.034],
                      [1.533, 0],
                      [1.033, -1.033],
                      [0, -1.6],
                      [-1.034, -1.033],
                      [-1.534, 0],
                      [-1.034, 1.034],
                      [0, 1.6]
                    ],
                    "o": [
                      [-1.034, -1.033],
                      [-1.534, 0],
                      [-1.034, 1.034],
                      [0, 1.6],
                      [1.033, 1.034],
                      [1.533, 0],
                      [1.033, -1.033],
                      [0, -1.6]
                    ],
                    "v": [
                      [16.15, -71.75],
                      [12.3, -73.3],
                      [8.45, -71.75],
                      [6.9, -67.8],
                      [8.45, -63.85],
                      [12.3, -62.3],
                      [16.15, -63.85],
                      [17.7, -67.8]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "i",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [16.2, -49.2],
                      [8.5, -49.2],
                      [8.5, 0],
                      [16.2, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "i",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "i",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "t",
      "size": 17,
      "style": "Regular",
      "w": 34.9,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 3.4],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.8, -1.9],
                      [-3.467, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [-3.2, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 3.534],
                      [1.8, 1.9],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [34.1, -6.6],
                      [25, -6.6],
                      [20.2, -11.7],
                      [20.2, -42.6],
                      [33.4, -42.6],
                      [33.4, -49.2],
                      [20.2, -49.2],
                      [20.2, -63.6],
                      [12.5, -63.6],
                      [12.5, -49.2],
                      [3.1, -49.2],
                      [3.1, -42.6],
                      [12.5, -42.6],
                      [12.5, -11],
                      [15.2, -2.85],
                      [23.1, 0],
                      [34.1, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "t",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "t",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "h",
      "size": 17,
      "style": "Regular",
      "w": 58.9,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [3.2, 3.767],
                      [5.733, 0],
                      [2.9, -2],
                      [1.4, -3.8],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.8, 3.934],
                      [-4.667, 0],
                      [-2.1, -2.733],
                      [0, -5],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, -6.866],
                      [-3.2, -3.766],
                      [-4.067, 0],
                      [-2.9, 2],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -6.733],
                      [2.8, -3.933],
                      [3.8, 0],
                      [2.1, 2.734],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [51.3, -29.2],
                      [46.5, -45.15],
                      [33.1, -50.8],
                      [22.65, -47.8],
                      [16.2, -39.1],
                      [16.2, -72],
                      [8.5, -72],
                      [8.5, 0],
                      [16.2, 0],
                      [16.2, -22.1],
                      [20.4, -38.1],
                      [31.6, -44],
                      [40.45, -39.9],
                      [43.6, -28.3],
                      [43.6, 0],
                      [51.3, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "h",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "h",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "a",
      "size": 17,
      "style": "Regular",
      "w": 54.3,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-3, 1.5],
                      [-1.867, 2.534],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.733, 2.967],
                      [3.133, 1.634],
                      [4.2, 0],
                      [5.6, -5.866],
                      [0, 0],
                      [-5.667, 0],
                      [-2.5, -2.433],
                      [0, -4.133],
                      [0, 0],
                      [0, 0],
                      [3.833, -2.933],
                      [0, -5],
                      [-1.5, -2.366],
                      [-2.667, -1.333],
                      [-3.467, 0]
                    ],
                    "o": [
                      [3, -1.5],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -4],
                      [-1.734, -2.966],
                      [-3.134, -1.633],
                      [-7.667, 0],
                      [0, 0],
                      [3.933, -4.733],
                      [4.333, 0],
                      [2.5, 2.434],
                      [0, 0],
                      [0, 0],
                      [-6.467, 0],
                      [-3.834, 2.934],
                      [0, 3],
                      [1.5, 2.367],
                      [2.666, 1.333],
                      [3.733, 0]
                    ],
                    "v": [
                      [32.1, -0.65],
                      [39.4, -6.7],
                      [39.4, 0],
                      [46.7, 0],
                      [46.7, -31],
                      [44.1, -41.45],
                      [36.8, -48.35],
                      [25.8, -50.8],
                      [5.9, -42],
                      [10.9, -37.1],
                      [25.3, -44.2],
                      [35.55, -40.55],
                      [39.3, -30.7],
                      [39.3, -30.3],
                      [25.5, -30.3],
                      [10.05, -25.9],
                      [4.3, -14],
                      [6.55, -5.95],
                      [12.8, -0.4],
                      [22, 1.6]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "a",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [1.966, 1.734],
                      [0, 2.867],
                      [-2.467, 1.7],
                      [-4.467, 0],
                      [0, 0],
                      [0, 0],
                      [2.866, -1.966],
                      [3.866, 0]
                    ],
                    "o": [
                      [-1.967, -1.733],
                      [0, -3.133],
                      [2.466, -1.7],
                      [0, 0],
                      [0, 0],
                      [-1.2, 3.267],
                      [-2.867, 1.967],
                      [-3.267, 0]
                    ],
                    "v": [
                      [15.25, -7.4],
                      [12.3, -14.3],
                      [16, -21.55],
                      [26.4, -24.1],
                      [39.3, -24.1],
                      [39.3, -15.6],
                      [33.2, -7.75],
                      [23.1, -4.8]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "a",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "a",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "o",
      "size": 17,
      "style": "Regular",
      "w": 60.1,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-3.734, 2.266],
                      [-2.1, 3.934],
                      [0, 5.067],
                      [2.1, 3.934],
                      [3.733, 2.267],
                      [4.8, 0],
                      [3.733, -2.266],
                      [2.1, -3.933],
                      [0, -5.066],
                      [-2.1, -3.933],
                      [-3.734, -2.266],
                      [-4.734, 0]
                    ],
                    "o": [
                      [3.733, -2.266],
                      [2.1, -3.933],
                      [0, -5.066],
                      [-2.1, -3.933],
                      [-3.734, -2.266],
                      [-4.734, 0],
                      [-3.734, 2.267],
                      [-2.1, 3.934],
                      [0, 5.067],
                      [2.1, 3.934],
                      [3.733, 2.266],
                      [4.8, 0]
                    ],
                    "v": [
                      [42.8, -1.8],
                      [51.55, -11.1],
                      [54.7, -24.6],
                      [51.55, -38.1],
                      [42.8, -47.4],
                      [30, -50.8],
                      [17.3, -47.4],
                      [8.55, -38.1],
                      [5.4, -24.6],
                      [8.55, -11.1],
                      [17.3, -1.8],
                      [30, 1.6]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "o",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [2.466, 1.667],
                      [1.4, 2.934],
                      [0, 3.8],
                      [-1.4, 2.9],
                      [-2.467, 1.667],
                      [-3.267, 0],
                      [-2.467, -1.666],
                      [-1.4, -2.9],
                      [0, -3.866],
                      [1.4, -2.933],
                      [2.466, -1.666],
                      [3.333, 0]
                    ],
                    "o": [
                      [-2.467, -1.666],
                      [-1.4, -2.933],
                      [0, -3.866],
                      [1.4, -2.9],
                      [2.466, -1.666],
                      [3.333, 0],
                      [2.466, 1.667],
                      [1.4, 2.9],
                      [0, 3.8],
                      [-1.4, 2.934],
                      [-2.467, 1.667],
                      [-3.267, 0]
                    ],
                    "v": [
                      [21.4, -7.6],
                      [15.6, -14.5],
                      [13.5, -24.6],
                      [15.6, -34.75],
                      [21.4, -41.6],
                      [30, -44.1],
                      [38.7, -41.6],
                      [44.5, -34.75],
                      [46.6, -24.6],
                      [44.5, -14.5],
                      [38.7, -7.6],
                      [30, -5.1]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "o",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "o",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "n",
      "size": 17,
      "style": "Regular",
      "w": 58.9,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [3.233, 3.7],
                      [5.666, 0],
                      [3.133, -1.766],
                      [1.6, -4.266],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.467, 3.234],
                      [-2.334, 1.5],
                      [-2.667, 0],
                      [-2.167, -2.633],
                      [0, -5.2],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, -7],
                      [-3.234, -3.7],
                      [-3.467, 0],
                      [-3.134, 1.767],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -5.133],
                      [1.466, -3.233],
                      [2.333, -1.5],
                      [3.666, 0],
                      [2.166, 2.634],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [51.3, -29.2],
                      [46.45, -45.25],
                      [33.1, -50.8],
                      [23.2, -48.15],
                      [16.1, -39.1],
                      [16.1, -49.2],
                      [8.5, -49.2],
                      [8.5, 0],
                      [16.2, 0],
                      [16.2, -22.1],
                      [18.4, -34.65],
                      [24.1, -41.75],
                      [31.6, -44],
                      [40.35, -40.05],
                      [43.6, -28.3],
                      [43.6, 0],
                      [51.3, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "n",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "n",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "d",
      "size": 17,
      "style": "Regular",
      "w": 62.8,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.133, 1.867],
                      [3.866, 0],
                      [3.566, -2.266],
                      [2.033, -3.966],
                      [0, -5],
                      [-2.067, -3.933],
                      [-3.567, -2.266],
                      [-4.6, 0],
                      [-3.067, 1.7],
                      [-1.667, 2.867],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.667, -3.133],
                      [-3.134, -1.866],
                      [-4.467, 0],
                      [-3.567, 2.267],
                      [-2.034, 3.967],
                      [0, 5.067],
                      [2.066, 3.934],
                      [3.566, 2.266],
                      [3.866, 0],
                      [3.066, -1.7],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [54.3, 0],
                      [54.3, -72],
                      [46.6, -72],
                      [46.6, -40.5],
                      [39.4, -48],
                      [28.9, -50.8],
                      [16.85, -47.4],
                      [8.45, -38.05],
                      [5.4, -24.6],
                      [8.5, -11.1],
                      [16.95, -1.8],
                      [29.2, 1.6],
                      [39.6, -0.95],
                      [46.7, -7.8],
                      [46.7, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "d",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.4, 2.934],
                      [-2.5, 1.667],
                      [-3.2, 0],
                      [-2.5, -1.766],
                      [-1.4, -3.133],
                      [0, -4.133],
                      [0, 0],
                      [2.933, -2.166],
                      [3.8, 0],
                      [2.533, 1.667],
                      [1.433, 2.934],
                      [0, 3.8]
                    ],
                    "o": [
                      [1.4, -2.933],
                      [2.5, -1.666],
                      [3.266, 0],
                      [2.5, 1.767],
                      [1.4, 3.134],
                      [0, 0],
                      [-1.2, 3.534],
                      [-2.934, 2.167],
                      [-3.267, 0],
                      [-2.534, -1.666],
                      [-1.434, -2.933],
                      [0, -3.8]
                    ],
                    "v": [
                      [15.6, -34.7],
                      [21.45, -41.6],
                      [30, -44.1],
                      [38.65, -41.45],
                      [44.5, -34.1],
                      [46.6, -23.2],
                      [46.6, -16.9],
                      [40.4, -8.35],
                      [30.3, -5.1],
                      [21.6, -7.6],
                      [15.65, -14.5],
                      [13.5, -24.6]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "d",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "d",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "u",
      "size": 17,
      "style": "Regular",
      "w": 58.7,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.733, -2.1],
                      [3.4, 0],
                      [2.133, 2.5],
                      [0, 4.8],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-3.334, -3.533],
                      [-5.534, 0],
                      [-2.9, 1.7],
                      [-1.6, 2.867],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [-1.134, 3.534],
                      [-2.734, 2.1],
                      [-3.867, 0],
                      [-2.134, -2.5],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 6.667],
                      [3.333, 3.534],
                      [3.733, 0],
                      [2.9, -1.7],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [50.2, -49.2],
                      [42.5, -49.2],
                      [42.5, -16.7],
                      [36.7, -8.25],
                      [27.5, -5.1],
                      [18.5, -8.85],
                      [15.3, -19.8],
                      [15.3, -49.2],
                      [7.6, -49.2],
                      [7.6, -19],
                      [12.6, -3.7],
                      [25.9, 1.6],
                      [35.85, -0.95],
                      [42.6, -7.8],
                      [42.6, 0],
                      [50.2, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "u",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "u",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": ".",
      "size": 17,
      "style": "Regular",
      "w": 24.2,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.1, 1.133],
                      [0, 1.667],
                      [1.1, 1.134],
                      [1.666, 0],
                      [1.1, -1.133],
                      [0, -1.666],
                      [-1.1, -1.133],
                      [-1.667, 0]
                    ],
                    "o": [
                      [1.1, -1.133],
                      [0, -1.666],
                      [-1.1, -1.133],
                      [-1.667, 0],
                      [-1.1, 1.134],
                      [0, 1.667],
                      [1.1, 1.133],
                      [1.666, 0]
                    ],
                    "v": [
                      [16.25, -0.5],
                      [17.9, -4.7],
                      [16.25, -8.9],
                      [12.1, -10.6],
                      [7.95, -8.9],
                      [6.3, -4.7],
                      [7.95, -0.5],
                      [12.1, 1.2]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": ".",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": ".",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "T",
      "size": 17,
      "style": "Regular",
      "w": 60,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [34, -64.4],
                      [56.2, -64.4],
                      [56.2, -71.5],
                      [3.8, -71.5],
                      [3.8, -64.4],
                      [26, -64.4],
                      [26, 0],
                      [34, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "T",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "T",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "g",
      "size": 17,
      "style": "Regular",
      "w": 62.8,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.1, 1.834],
                      [4.066, 0],
                      [3.533, -2.266],
                      [2.066, -3.9],
                      [0, -4.866],
                      [-2.034, -3.866],
                      [-3.6, -2.2],
                      [-4.6, 0],
                      [-3.034, 1.6],
                      [-1.667, 2.734],
                      [0, 0],
                      [1.433, -2.8],
                      [2.533, -1.567],
                      [3.333, 0],
                      [3.866, 5.266],
                      [0, 0],
                      [-3.567, -1.634],
                      [-4.267, 0],
                      [-3.7, 2.2],
                      [-2.1, 3.8],
                      [0, 4.866]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [-1.6, -3.266],
                      [-3.1, -1.833],
                      [-4.467, 0],
                      [-3.534, 2.267],
                      [-2.067, 3.9],
                      [0, 4.934],
                      [2.033, 3.867],
                      [3.6, 2.2],
                      [3.866, 0],
                      [3.033, -1.6],
                      [0, 0],
                      [0, 3.667],
                      [-1.434, 2.8],
                      [-2.534, 1.566],
                      [-6.534, 0],
                      [0, 0],
                      [2.466, 3],
                      [3.566, 1.633],
                      [4.733, 0],
                      [3.7, -2.2],
                      [2.1, -3.8],
                      [0, 0]
                    ],
                    "v": [
                      [54.3, -49.2],
                      [46.7, -49.2],
                      [46.7, -40.4],
                      [39.65, -48.05],
                      [28.9, -50.8],
                      [16.9, -47.4],
                      [8.5, -38.15],
                      [5.4, -25],
                      [8.45, -11.8],
                      [16.9, -2.7],
                      [29.2, 0.6],
                      [39.55, -1.8],
                      [46.6, -8.3],
                      [46.6, -4.3],
                      [44.45, 5.4],
                      [38.5, 11.95],
                      [29.7, 14.3],
                      [14.1, 6.4],
                      [9, 11.6],
                      [18.05, 18.55],
                      [29.8, 21],
                      [42.45, 17.7],
                      [51.15, 8.7],
                      [54.3, -4.3]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "g",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.4, 2.867],
                      [-2.5, 1.634],
                      [-3.2, 0],
                      [-2.5, -1.766],
                      [-1.4, -3.133],
                      [0, -4.133],
                      [0, 0],
                      [2.933, -2.2],
                      [3.8, 0],
                      [2.533, 1.6],
                      [1.433, 2.834],
                      [0, 3.734]
                    ],
                    "o": [
                      [1.4, -2.866],
                      [2.5, -1.633],
                      [3.266, 0],
                      [2.5, 1.767],
                      [1.4, 3.134],
                      [0, 0],
                      [-1.2, 3.467],
                      [-2.934, 2.2],
                      [-3.267, 0],
                      [-2.534, -1.6],
                      [-1.434, -2.833],
                      [0, -3.733]
                    ],
                    "v": [
                      [15.6, -34.9],
                      [21.45, -41.65],
                      [30, -44.1],
                      [38.65, -41.45],
                      [44.5, -34.1],
                      [46.6, -23.2],
                      [46.6, -17.9],
                      [40.4, -9.4],
                      [30.3, -6.1],
                      [21.6, -8.5],
                      [15.65, -15.15],
                      [13.5, -25]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "g",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "g",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "p",
      "size": 17,
      "style": "Regular",
      "w": 62.8,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [-3, -1.633],
                      [-3.934, 0],
                      [-3.567, 2.266],
                      [-2.067, 3.934],
                      [0, 5.067],
                      [2, 3.967],
                      [3.466, 2.267],
                      [4.533, 0],
                      [3.233, -1.966],
                      [1.666, -3.266],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [1.666, 2.8],
                      [3, 1.633],
                      [4.6, 0],
                      [3.566, -2.266],
                      [2.066, -3.933],
                      [0, -5],
                      [-2, -3.966],
                      [-3.467, -2.266],
                      [-3.934, 0],
                      [-3.234, 1.967],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [16.2, -7.5],
                      [23.2, -0.85],
                      [33.6, 1.6],
                      [45.85, -1.8],
                      [54.3, -11.1],
                      [57.4, -24.6],
                      [54.4, -38.05],
                      [46.2, -47.4],
                      [34.2, -50.8],
                      [23.45, -47.85],
                      [16.1, -40],
                      [16.1, -49.2],
                      [8.5, -49.2],
                      [8.5, 19.4],
                      [16.2, 19.4]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "p",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.467, 3.134],
                      [-2.534, 1.767],
                      [-3.267, 0],
                      [-2.434, -1.666],
                      [-1.367, -2.933],
                      [0, -3.8],
                      [1.433, -2.933],
                      [2.5, -1.666],
                      [3.333, 0],
                      [2.933, 2.167],
                      [1.2, 3.534],
                      [0, 0]
                    ],
                    "o": [
                      [1.466, -3.133],
                      [2.533, -1.766],
                      [3.2, 0],
                      [2.433, 1.667],
                      [1.366, 2.934],
                      [0, 3.8],
                      [-1.434, 2.934],
                      [-2.5, 1.667],
                      [-3.8, 0],
                      [-2.934, -2.166],
                      [0, 0],
                      [0, -4.133]
                    ],
                    "v": [
                      [18.4, -34.1],
                      [24.4, -41.45],
                      [33.1, -44.1],
                      [41.55, -41.6],
                      [47.25, -34.7],
                      [49.3, -24.6],
                      [47.15, -14.5],
                      [41.25, -7.6],
                      [32.5, -5.1],
                      [22.4, -8.35],
                      [16.2, -16.9],
                      [16.2, -23.2]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "p",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "p",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "f",
      "size": 17,
      "style": "Regular",
      "w": 35.4,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.867, 0.967],
                      [-2, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -8],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -2.133],
                      [0.866, -0.966],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-7.6, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [20.4, -42.6],
                      [33.8, -42.6],
                      [33.8, -49.2],
                      [20.4, -49.2],
                      [20.4, -59.5],
                      [21.7, -64.15],
                      [26, -65.6],
                      [33.8, -65.6],
                      [33.8, -72],
                      [24.1, -72],
                      [12.7, -60],
                      [12.7, -49.2],
                      [3.3, -49.2],
                      [3.3, -42.6],
                      [12.7, -42.6],
                      [12.7, 0],
                      [20.4, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "f",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "f",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "l",
      "size": 17,
      "style": "Regular",
      "w": 24.7,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [16.2, -72],
                      [8.5, -72],
                      [8.5, 0],
                      [16.2, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "l",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "l",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "c",
      "size": 17,
      "style": "Regular",
      "w": 51.6,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [6, 0],
                      [3.1, 3.567],
                      [0, 5.867],
                      [-3.067, 3.534],
                      [-4.867, 0],
                      [-2.467, -1.4],
                      [-1.4, -2.533],
                      [0, 0],
                      [8.333, 0],
                      [3.733, -2.233],
                      [2.133, -3.933],
                      [0, -5.133],
                      [-2.1, -3.933],
                      [-3.7, -2.233],
                      [-4.8, 0],
                      [-4.734, 6.4],
                      [0, 0]
                    ],
                    "o": [
                      [-5, 0],
                      [-3.1, -3.566],
                      [0, -5.933],
                      [3.066, -3.533],
                      [3.133, 0],
                      [2.466, 1.4],
                      [0, 0],
                      [-4.667, -6.4],
                      [-4.8, 0],
                      [-3.734, 2.234],
                      [-2.134, 3.934],
                      [0, 5.134],
                      [2.1, 3.934],
                      [3.7, 2.233],
                      [8.333, 0],
                      [0, 0],
                      [-3.267, 5.334]
                    ],
                    "v": [
                      [30.3, -5.1],
                      [18.15, -10.45],
                      [13.5, -24.6],
                      [18.1, -38.8],
                      [30, -44.1],
                      [38.4, -42],
                      [44.2, -36.1],
                      [49.7, -41.2],
                      [30.2, -50.8],
                      [17.4, -47.45],
                      [8.6, -38.2],
                      [5.4, -24.6],
                      [8.55, -11],
                      [17.25, -1.75],
                      [30, 1.6],
                      [49.6, -8],
                      [44.2, -13.1]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "c",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "c",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "k",
      "size": 17,
      "style": "Regular",
      "w": 53.9,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [16.2, -23.2],
                      [41.8, 0],
                      [52.9, 0],
                      [24.4, -25.5],
                      [50.2, -49.2],
                      [39.9, -49.2],
                      [16.2, -26.9],
                      [16.2, -72],
                      [8.5, -72],
                      [8.5, 0],
                      [16.2, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "k",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "k",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "Y",
      "size": 17,
      "style": "Regular",
      "w": 65.5,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [36.8, -31.2],
                      [61.7, -71.5],
                      [53, -71.5],
                      [33, -37.7],
                      [13.1, -71.5],
                      [3.8, -71.5],
                      [28.8, -31.4],
                      [28.8, 0],
                      [36.8, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "Y",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "Y",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "m",
      "size": 17,
      "style": "Regular",
      "w": 90.3,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [3.066, 3.734],
                      [5.666, 0],
                      [3.033, -2.2],
                      [1.333, -4],
                      [2.6, 2.167],
                      [4, 0],
                      [2.766, -2.033],
                      [1.333, -3.666],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.6, 3.934],
                      [-4.4, 0],
                      [-1.934, -2.733],
                      [0, -4.933],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.6, 3.934],
                      [-4.467, 0],
                      [-1.934, -2.733],
                      [0, -4.933],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, -6.933],
                      [-3.067, -3.733],
                      [-4.2, 0],
                      [-3.034, 2.2],
                      [-1.067, -4.066],
                      [-2.6, -2.166],
                      [-3.867, 0],
                      [-2.767, 2.034],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -6.733],
                      [2.6, -3.933],
                      [3.533, 0],
                      [1.933, 2.734],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -6.733],
                      [2.6, -3.933],
                      [3.533, 0],
                      [1.933, 2.734],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [82.7, -29.2],
                      [78.1, -45.2],
                      [65, -50.8],
                      [54.15, -47.5],
                      [47.6, -38.2],
                      [42.1, -47.55],
                      [32.2, -50.8],
                      [22.25, -47.75],
                      [16.1, -39.2],
                      [16.1, -49.2],
                      [8.5, -49.2],
                      [8.5, 0],
                      [16.2, 0],
                      [16.2, -22.1],
                      [20.1, -38.1],
                      [30.6, -44],
                      [38.8, -39.9],
                      [41.7, -28.4],
                      [41.7, 0],
                      [49.4, 0],
                      [49.4, -22.1],
                      [53.3, -38.1],
                      [63.9, -44],
                      [72.1, -39.9],
                      [75, -28.4],
                      [75, 0],
                      [82.7, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "m",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "m",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "j",
      "size": 17,
      "style": "Regular",
      "w": 24.7,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [1.033, 1.034],
                      [1.533, 0],
                      [1.033, -1.033],
                      [0, -1.6],
                      [-1.034, -1.033],
                      [-1.534, 0],
                      [-1.034, 1.034],
                      [0, 1.6]
                    ],
                    "o": [
                      [-1.034, -1.033],
                      [-1.534, 0],
                      [-1.034, 1.034],
                      [0, 1.6],
                      [1.033, 1.034],
                      [1.533, 0],
                      [1.033, -1.033],
                      [0, -1.6]
                    ],
                    "v": [
                      [16.15, -71.75],
                      [12.3, -73.3],
                      [8.45, -71.75],
                      [6.9, -67.8],
                      [8.45, -63.85],
                      [12.3, -62.3],
                      [16.15, -63.85],
                      [17.7, -67.8]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "j",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 8],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.9, -1],
                      [2, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [7.666, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 2.2],
                      [-0.9, 1],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [4.7, 19.4],
                      [16.2, 7.4],
                      [16.2, -49.2],
                      [8.5, -49.2],
                      [8.5, 6.7],
                      [7.15, 11.5],
                      [2.8, 13],
                      [-0.3, 13],
                      [-0.3, 19.4]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "j",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "j",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "O",
      "size": 42,
      "style": "Bold",
      "w": 86.4,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-4.667, 1.866],
                      [-3.434, 3.367],
                      [-1.867, 4.534],
                      [0, 5.4],
                      [1.866, 4.534],
                      [3.433, 3.367],
                      [4.666, 1.867],
                      [5.533, 0],
                      [4.633, -1.866],
                      [3.433, -3.366],
                      [1.9, -4.533],
                      [0, -5.333],
                      [-1.9, -4.533],
                      [-3.434, -3.366],
                      [-4.634, -1.866],
                      [-5.534, 0]
                    ],
                    "o": [
                      [4.666, -1.866],
                      [3.433, -3.366],
                      [1.866, -4.533],
                      [0, -5.333],
                      [-1.867, -4.533],
                      [-3.434, -3.366],
                      [-4.667, -1.866],
                      [-5.534, 0],
                      [-4.634, 1.867],
                      [-3.434, 3.367],
                      [-1.9, 4.534],
                      [0, 5.4],
                      [1.9, 4.534],
                      [3.433, 3.367],
                      [4.633, 1.866],
                      [5.533, 0]
                    ],
                    "v": [
                      [58.5, -1.2],
                      [70.65, -9.05],
                      [78.6, -20.9],
                      [81.4, -35.8],
                      [78.6, -50.6],
                      [70.65, -62.45],
                      [58.5, -70.3],
                      [43.2, -73.1],
                      [27.95, -70.3],
                      [15.85, -62.45],
                      [7.85, -50.6],
                      [5, -35.8],
                      [7.85, -20.9],
                      [15.85, -9.05],
                      [27.95, -1.2],
                      [43.2, 1.6]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "O",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [3.6, 2.167],
                      [2.033, 3.834],
                      [0, 4.934],
                      [-2.034, 3.8],
                      [-3.6, 2.167],
                      [-4.734, 0],
                      [-3.6, -2.166],
                      [-2.034, -3.8],
                      [0, -4.933],
                      [2.033, -3.833],
                      [3.6, -2.166],
                      [4.733, 0]
                    ],
                    "o": [
                      [-3.6, -2.166],
                      [-2.034, -3.833],
                      [0, -4.933],
                      [2.033, -3.8],
                      [3.6, -2.166],
                      [4.733, 0],
                      [3.6, 2.167],
                      [2.033, 3.8],
                      [0, 4.934],
                      [-2.034, 3.834],
                      [-3.6, 2.167],
                      [-4.734, 0]
                    ],
                    "v": [
                      [30.7, -13.65],
                      [22.25, -22.65],
                      [19.2, -35.8],
                      [22.25, -48.9],
                      [30.7, -57.85],
                      [43.2, -61.1],
                      [55.7, -57.85],
                      [64.15, -48.9],
                      [67.2, -35.8],
                      [64.15, -22.65],
                      [55.7, -13.65],
                      [43.2, -10.4]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "O",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "O",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "u",
      "size": 42,
      "style": "Bold",
      "w": 59.8,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.266, -1.6],
                      [2.8, 0],
                      [1.766, 1.834],
                      [0, 3.6],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-3.5, -3.5],
                      [-5.867, 0],
                      [-2.8, 1.433],
                      [-1.667, 2.534],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [-0.934, 2.6],
                      [-2.267, 1.6],
                      [-3.067, 0],
                      [-1.767, -1.833],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 6.667],
                      [3.5, 3.5],
                      [3.6, 0],
                      [2.8, -1.433],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [54, -50.3],
                      [40.8, -50.3],
                      [40.8, -18.1],
                      [36, -11.8],
                      [28.4, -9.4],
                      [21.15, -12.15],
                      [18.5, -20.3],
                      [18.5, -50.3],
                      [5.3, -50.3],
                      [5.3, -18.9],
                      [10.55, -3.65],
                      [24.6, 1.6],
                      [34.2, -0.55],
                      [40.9, -6.5],
                      [40.9, 0],
                      [54, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "u",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "u",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "r",
      "size": 42,
      "style": "Bold",
      "w": 39.8,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [2.1, -0.666],
                      [1.8, -1.566],
                      [1.133, -2.733],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.8, 2.767],
                      [-4.667, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [-2, 0],
                      [-2.1, 0.667],
                      [-1.8, 1.567],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -6.266],
                      [2.8, -2.766],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [35.3, -51.2],
                      [29.15, -50.2],
                      [23.3, -46.85],
                      [18.9, -40.4],
                      [18.9, -50.3],
                      [5.8, -50.3],
                      [5.8, 0],
                      [19, 0],
                      [19, -21.5],
                      [23.2, -35.05],
                      [34.4, -39.2],
                      [37.1, -39.2],
                      [37.1, -51.2]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "r",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "r",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "S",
      "size": 42,
      "style": "Bold",
      "w": 67.9,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-5.4, -2.466],
                      [-6.867, 0],
                      [-4.467, 1.833],
                      [-2.534, 3.367],
                      [0, 4.534],
                      [4.5, 3.567],
                      [9.666, 1.467],
                      [2.433, 1.334],
                      [0, 2.334],
                      [-2.734, 1.734],
                      [-4.334, 0],
                      [-3.534, -1.9],
                      [-2.467, -3.266],
                      [0, 0],
                      [5, 2.434],
                      [6.4, 0],
                      [4.266, -1.866],
                      [2.433, -3.333],
                      [0, -4.333],
                      [-4.334, -3.333],
                      [-9.667, -1.466],
                      [-2.567, -1.633],
                      [0, -2.666],
                      [2.833, -1.833],
                      [4.866, 0],
                      [4.166, 1.967],
                      [2.6, 3.267],
                      [0, 0]
                    ],
                    "o": [
                      [5.4, 2.466],
                      [5.866, 0],
                      [4.466, -1.833],
                      [2.533, -3.366],
                      [0, -6.133],
                      [-4.5, -3.566],
                      [-6.467, -1],
                      [-2.434, -1.333],
                      [0, -2.933],
                      [2.733, -1.733],
                      [4.2, 0],
                      [3.533, 1.9],
                      [0, 0],
                      [-3.467, -4],
                      [-5, -2.433],
                      [-5.467, 0],
                      [-4.267, 1.867],
                      [-2.434, 3.334],
                      [0, 5.6],
                      [4.333, 3.334],
                      [6.6, 1],
                      [2.566, 1.634],
                      [0, 3],
                      [-2.834, 1.834],
                      [-4.534, 0],
                      [-4.167, -1.966],
                      [0, 0],
                      [3.666, 4]
                    ],
                    "v": [
                      [16.9, -2],
                      [35.3, 1.7],
                      [50.8, -1.05],
                      [61.3, -8.85],
                      [65.1, -20.7],
                      [58.35, -35.25],
                      [37.1, -42.8],
                      [23.75, -46.3],
                      [20.1, -51.8],
                      [24.2, -58.8],
                      [34.8, -61.4],
                      [46.4, -58.55],
                      [55.4, -50.8],
                      [64.4, -59.8],
                      [51.7, -69.45],
                      [34.6, -73.1],
                      [20, -70.3],
                      [9.95, -62.5],
                      [6.3, -51],
                      [12.8, -37.6],
                      [33.8, -30.4],
                      [47.55, -26.45],
                      [51.4, -20],
                      [47.15, -12.75],
                      [35.6, -10],
                      [22.55, -12.95],
                      [12.4, -20.8],
                      [3.3, -11.7]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "S",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "S",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "o",
      "size": 42,
      "style": "Bold",
      "w": 63.7,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-4.2, 2.3],
                      [-2.367, 4.034],
                      [0, 5.2],
                      [2.366, 4.034],
                      [4.2, 2.267],
                      [5.466, 0],
                      [4.2, -2.266],
                      [2.366, -4.033],
                      [0, -5.2],
                      [-2.367, -4.033],
                      [-4.2, -2.3],
                      [-5.4, 0]
                    ],
                    "o": [
                      [4.2, -2.3],
                      [2.366, -4.033],
                      [0, -5.2],
                      [-2.367, -4.033],
                      [-4.2, -2.266],
                      [-5.4, 0],
                      [-4.2, 2.267],
                      [-2.367, 4.034],
                      [0, 5.2],
                      [2.366, 4.034],
                      [4.2, 2.3],
                      [5.466, 0]
                    ],
                    "v": [
                      [46.3, -1.85],
                      [56.15, -11.35],
                      [59.7, -25.2],
                      [56.15, -39.05],
                      [46.3, -48.5],
                      [31.8, -51.9],
                      [17.4, -48.5],
                      [7.55, -39.05],
                      [4, -25.2],
                      [7.55, -11.35],
                      [17.4, -1.85],
                      [31.8, 1.6]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "o",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [2.6, 2.934],
                      [0, 4.667],
                      [-2.6, 2.934],
                      [-4.2, 0],
                      [-2.6, -2.933],
                      [0, -4.6],
                      [2.6, -2.933],
                      [4.266, 0]
                    ],
                    "o": [
                      [-2.6, -2.933],
                      [0, -4.6],
                      [2.6, -2.933],
                      [4.266, 0],
                      [2.6, 2.934],
                      [0, 4.667],
                      [-2.6, 2.934],
                      [-4.2, 0]
                    ],
                    "v": [
                      [21.6, -13.8],
                      [17.7, -25.2],
                      [21.6, -36.5],
                      [31.8, -40.9],
                      [42.1, -36.5],
                      [46, -25.2],
                      [42.1, -13.8],
                      [31.8, -9.4]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "o",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "o",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "y",
      "size": 42,
      "style": "Bold",
      "w": 59,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.933, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.934, 2.3],
                      [-1.867, 4.466],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.6, 3.8],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.866, 0],
                      [2.933, -2.3],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [29.7, -13.1],
                      [15.3, -50.3],
                      [1.7, -50.3],
                      [23.2, 0.1],
                      [22.1, 2.9],
                      [13.8, 8.6],
                      [8, 8.6],
                      [8, 19.4],
                      [14.5, 19.4],
                      [26.2, 15.95],
                      [33.4, 5.8],
                      [57.3, -50.3],
                      [44.1, -50.3]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "y",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "y",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Display App"
    },
    {
      "ch": "B",
      "size": 17,
      "style": "Regular",
      "w": 67.7,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [-3.367, 1.667],
                      [-1.867, 3.034],
                      [0, 4],
                      [2.1, 3.067],
                      [3.666, 1.467],
                      [-1.567, 2.534],
                      [0, 3.267],
                      [1.8, 2.834],
                      [3.2, 1.567],
                      [4.266, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [4.466, 0],
                      [3.366, -1.666],
                      [1.866, -3.033],
                      [0, -4.2],
                      [-2.1, -3.066],
                      [2.733, -1.533],
                      [1.566, -2.533],
                      [0, -3.8],
                      [-1.8, -2.833],
                      [-3.2, -1.566],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [38.9, 0],
                      [50.65, -2.5],
                      [58.5, -9.55],
                      [61.3, -20.1],
                      [58.15, -31],
                      [49.5, -37.8],
                      [55.95, -43.9],
                      [58.3, -52.6],
                      [55.6, -62.55],
                      [48.1, -69.15],
                      [36.9, -71.5],
                      [10.6, -71.5],
                      [10.6, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "B",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-2.4, -2.166],
                      [0, -3.666],
                      [2.366, -2.166],
                      [4.2, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [2.4, 2.167],
                      [0, 3.8],
                      [-2.367, 2.167],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.133, 0]
                    ],
                    "v": [
                      [46.6, -61.25],
                      [50.2, -52.5],
                      [46.65, -43.55],
                      [36.8, -40.3],
                      [18.6, -40.3],
                      [18.6, -64.5],
                      [36.8, -64.5]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "B",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 2,
                "ty": "sh",
                "ix": 3,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [-2.534, -2.333],
                      [0, -4.066],
                      [2.533, -2.366],
                      [4.4, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [4.4, 0],
                      [2.533, 2.334],
                      [0, 4.067],
                      [-2.534, 2.367],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [18.6, -33.3],
                      [38.9, -33.3],
                      [49.3, -29.8],
                      [53.1, -20.2],
                      [49.3, -10.55],
                      [38.9, -7],
                      [18.6, -7]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "B",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "B",
            "np": 6,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "q",
      "size": 17,
      "style": "Regular",
      "w": 62.8,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.133, 1.867],
                      [3.933, 0],
                      [3.566, -2.266],
                      [2.033, -3.966],
                      [0, -5],
                      [-2.067, -3.933],
                      [-3.567, -2.266],
                      [-4.6, 0],
                      [-3, 1.633],
                      [-1.667, 2.8],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.667, -3.2],
                      [-3.134, -1.866],
                      [-4.467, 0],
                      [-3.567, 2.267],
                      [-2.034, 3.967],
                      [0, 5.067],
                      [2.066, 3.934],
                      [3.566, 2.266],
                      [3.933, 0],
                      [3, -1.633],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [54.3, 19.4],
                      [54.3, -49.2],
                      [46.7, -49.2],
                      [46.7, -40.4],
                      [39.5, -48],
                      [28.9, -50.8],
                      [16.85, -47.4],
                      [8.45, -38.05],
                      [5.4, -24.6],
                      [8.5, -11.1],
                      [16.95, -1.8],
                      [29.2, 1.6],
                      [39.6, -0.85],
                      [46.6, -7.5],
                      [46.6, 19.4]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "q",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.4, 2.934],
                      [-2.5, 1.667],
                      [-3.2, 0],
                      [-2.5, -1.766],
                      [-1.4, -3.133],
                      [0, -4.133],
                      [0, 0],
                      [2.933, -2.166],
                      [3.8, 0],
                      [2.533, 1.667],
                      [1.433, 2.934],
                      [0, 3.8]
                    ],
                    "o": [
                      [1.4, -2.933],
                      [2.5, -1.666],
                      [3.266, 0],
                      [2.5, 1.767],
                      [1.4, 3.134],
                      [0, 0],
                      [-1.2, 3.534],
                      [-2.934, 2.167],
                      [-3.267, 0],
                      [-2.534, -1.666],
                      [-1.434, -2.933],
                      [0, -3.8]
                    ],
                    "v": [
                      [15.6, -34.7],
                      [21.45, -41.6],
                      [30, -44.1],
                      [38.65, -41.45],
                      [44.5, -34.1],
                      [46.6, -23.2],
                      [46.6, -16.9],
                      [40.4, -8.35],
                      [30.3, -5.1],
                      [21.6, -7.6],
                      [15.65, -14.5],
                      [13.5, -24.6]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "q",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "q",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "’",
      "size": 17,
      "style": "Regular",
      "w": 23,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.334, 3.5],
                      [0, 2.867],
                      [1.1, 1.134],
                      [1.733, 0],
                      [1.066, -1.133],
                      [0, -1.733],
                      [-0.967, -1.1],
                      [-1.4, -0.133],
                      [1, -2.366],
                      [1.6, -2.133],
                      [0, 0]
                    ],
                    "o": [
                      [1.333, -3.5],
                      [0, -1.933],
                      [-1.1, -1.133],
                      [-1.667, 0],
                      [-1.067, 1.134],
                      [0, 1.467],
                      [0.966, 1.1],
                      [-0.134, 1.867],
                      [-1, 2.367],
                      [0, 0],
                      [2.066, -2.466]
                    ],
                    "v": [
                      [15.6, -58.15],
                      [17.6, -67.7],
                      [15.95, -72.3],
                      [11.7, -74],
                      [7.6, -72.3],
                      [6, -68],
                      [7.45, -64.15],
                      [11, -62.3],
                      [9.3, -55.95],
                      [5.4, -49.2],
                      [10.5, -49.2]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "’",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "’",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "z",
      "size": 17,
      "style": "Regular",
      "w": 47.4,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [43.2, -6.4],
                      [13.4, -6.4],
                      [42.3, -42.7],
                      [42.3, -49.2],
                      [5.2, -49.2],
                      [5.2, -42.7],
                      [33.1, -42.7],
                      [4.3, -6.4],
                      [4.3, 0],
                      [43.2, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "z",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "z",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": ",",
      "size": 17,
      "style": "Regular",
      "w": 24.2,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.334, 3.433],
                      [0, 3.067],
                      [1.066, 1.2],
                      [1.733, 0],
                      [1.1, -1.133],
                      [0, -1.666],
                      [-0.9, -1.066],
                      [-1.467, -0.2],
                      [3.2, -4.4],
                      [0, 0]
                    ],
                    "o": [
                      [1.333, -3.434],
                      [0, -1.733],
                      [-1.067, -1.2],
                      [-1.667, 0],
                      [-1.1, 1.134],
                      [0, 1.534],
                      [0.9, 1.066],
                      [-0.467, 4.333],
                      [0, 0],
                      [2.066, -2.467]
                    ],
                    "v": [
                      [15.9, 5.35],
                      [17.9, -4.4],
                      [16.3, -8.8],
                      [12.1, -10.6],
                      [7.95, -8.9],
                      [6.3, -4.7],
                      [7.65, -0.8],
                      [11.2, 1.1],
                      [5.7, 14.2],
                      [10.8, 14.2]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": ",",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": ",",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "-",
      "size": 17,
      "style": "Regular",
      "w": 32.3,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [28.8, -30.5],
                      [3.5, -30.5],
                      [3.5, -23.5],
                      [28.8, -23.5]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "-",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "-",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "W",
      "size": 17,
      "style": "Regular",
      "w": 98.4,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [95.8, -71.5],
                      [87.6, -71.5],
                      [70.8, -9.5],
                      [54.5, -71.5],
                      [46, -71.5],
                      [29.9, -10],
                      [13.2, -71.5],
                      [4.6, -71.5],
                      [25, 0],
                      [34.4, 0],
                      [50.2, -60.6],
                      [66, 0],
                      [75.4, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "W",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "W",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "O",
      "size": 17,
      "style": "Regular",
      "w": 82.9,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-4.267, 1.866],
                      [-3.167, 3.367],
                      [-1.734, 4.567],
                      [0, 5.334],
                      [1.733, 4.534],
                      [3.166, 3.367],
                      [4.266, 1.867],
                      [5, 0],
                      [4.233, -1.866],
                      [3.166, -3.366],
                      [1.733, -4.533],
                      [0, -5.333],
                      [-1.734, -4.566],
                      [-3.167, -3.366],
                      [-4.234, -1.866],
                      [-5, 0]
                    ],
                    "o": [
                      [4.266, -1.866],
                      [3.166, -3.366],
                      [1.733, -4.566],
                      [0, -5.333],
                      [-1.734, -4.533],
                      [-3.167, -3.366],
                      [-4.267, -1.866],
                      [-5, 0],
                      [-4.234, 1.867],
                      [-3.167, 3.367],
                      [-1.734, 4.534],
                      [0, 5.334],
                      [1.733, 4.567],
                      [3.166, 3.367],
                      [4.233, 1.866],
                      [5, 0]
                    ],
                    "v": [
                      [55.3, -1.2],
                      [66.45, -9.05],
                      [73.8, -20.95],
                      [76.4, -35.8],
                      [73.8, -50.6],
                      [66.45, -62.45],
                      [55.3, -70.3],
                      [41.4, -73.1],
                      [27.55, -70.3],
                      [16.45, -62.45],
                      [9.1, -50.6],
                      [6.5, -35.8],
                      [9.1, -20.95],
                      [16.45, -9.05],
                      [27.55, -1.2],
                      [41.4, 1.6]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "O",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [4, 2.6],
                      [2.266, 4.567],
                      [0, 5.8],
                      [-2.267, 4.5],
                      [-4, 2.6],
                      [-5.2, 0],
                      [-4, -2.6],
                      [-2.267, -4.5],
                      [0, -5.866],
                      [2.266, -4.566],
                      [4, -2.6],
                      [5.266, 0]
                    ],
                    "o": [
                      [-4, -2.6],
                      [-2.267, -4.566],
                      [0, -5.866],
                      [2.266, -4.5],
                      [4, -2.6],
                      [5.266, 0],
                      [4, 2.6],
                      [2.266, 4.5],
                      [0, 5.8],
                      [-2.267, 4.567],
                      [-4, 2.6],
                      [-5.2, 0]
                    ],
                    "v": [
                      [27.6, -9.5],
                      [18.2, -20.25],
                      [14.8, -35.8],
                      [18.2, -51.35],
                      [27.6, -62],
                      [41.4, -65.9],
                      [55.3, -62],
                      [64.7, -51.35],
                      [68.1, -35.8],
                      [64.7, -20.25],
                      [55.3, -9.5],
                      [41.4, -5.6]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "O",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "O",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "G",
      "size": 20,
      "style": "Regular",
      "w": 78.5,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.8, -2.1],
                      [4.6, 0],
                      [4.033, 2.534],
                      [2.266, 4.567],
                      [0, 5.934],
                      [-2.234, 4.5],
                      [-3.867, 2.6],
                      [-5, 0],
                      [-3.567, -1.9],
                      [-2.667, -4.133],
                      [0, 0],
                      [4.733, 2.3],
                      [5.733, 0],
                      [4.166, -1.866],
                      [3.1, -3.366],
                      [1.733, -4.533],
                      [0, -5.333],
                      [-1.7, -4.533],
                      [-3.1, -3.366],
                      [-4.167, -1.866],
                      [-4.867, 0],
                      [-3.834, 1.733],
                      [-2.4, 3.267],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.067, 3.6],
                      [-3.8, 2.1],
                      [-5.267, 0],
                      [-4.034, -2.533],
                      [-2.267, -4.566],
                      [0, -5.866],
                      [2.233, -4.5],
                      [3.866, -2.6],
                      [4.933, 0],
                      [3.566, 1.9],
                      [0, 0],
                      [-3.2, -4.466],
                      [-4.734, -2.3],
                      [-4.867, 0],
                      [-4.167, 1.867],
                      [-3.1, 3.367],
                      [-1.734, 4.534],
                      [0, 5.4],
                      [1.7, 4.534],
                      [3.1, 3.367],
                      [4.166, 1.866],
                      [4.866, 0],
                      [3.833, -1.733],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [70.7, -37.3],
                      [40.8, -37.3],
                      [40.8, -30.3],
                      [63, -30.3],
                      [63, -17.3],
                      [54.2, -8.75],
                      [41.6, -5.6],
                      [27.65, -9.4],
                      [18.2, -20.05],
                      [14.8, -35.8],
                      [18.15, -51.35],
                      [27.3, -62],
                      [40.6, -65.9],
                      [53.35, -63.05],
                      [62.7, -54],
                      [68.4, -59.5],
                      [56.5, -69.65],
                      [40.8, -73.1],
                      [27.25, -70.3],
                      [16.35, -62.45],
                      [9.1, -50.6],
                      [6.5, -35.8],
                      [9.05, -20.9],
                      [16.25, -9.05],
                      [27.15, -1.2],
                      [40.7, 1.6],
                      [53.75, -1],
                      [63.1, -8.5],
                      [63.1, 0],
                      [70.7, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "G",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "G",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "x",
      "size": 20,
      "style": "Regular",
      "w": 48.3,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [24.1, -20.8],
                      [38.2, 0],
                      [47.4, 0],
                      [29.1, -25.5],
                      [45.6, -49.2],
                      [36.8, -49.2],
                      [24.1, -30],
                      [11.6, -49.2],
                      [2.6, -49.2],
                      [19.2, -25.5],
                      [0.9, 0],
                      [10, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "x",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "x",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "S",
      "size": 20,
      "style": "Regular",
      "w": 64.1,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-11.534, 0],
                      [-4.034, 1.833],
                      [-2.267, 3.267],
                      [0, 4.2],
                      [4.1, 3.234],
                      [9.533, 1.467],
                      [2.733, 1.8],
                      [0, 3.2],
                      [-3.034, 2.334],
                      [-5.267, 0],
                      [-3.434, -1.733],
                      [-3.134, -3.733],
                      [0, 0],
                      [10.866, 0],
                      [3.833, -1.766],
                      [2.166, -3.066],
                      [0, -4],
                      [-3.934, -2.966],
                      [-9.067, -1.466],
                      [-2.9, -2.033],
                      [0, -3.733],
                      [3.366, -2.6],
                      [5.6, 0],
                      [6.333, 7.734],
                      [0, 0]
                    ],
                    "o": [
                      [5.2, 0],
                      [4.033, -1.833],
                      [2.266, -3.266],
                      [0, -6.066],
                      [-4.1, -3.233],
                      [-6.4, -1],
                      [-2.734, -1.8],
                      [0, -4.133],
                      [3.033, -2.333],
                      [4.4, 0],
                      [3.433, 1.734],
                      [0, 0],
                      [-7.267, -8.266],
                      [-4.867, 0],
                      [-3.834, 1.767],
                      [-2.167, 3.067],
                      [0, 5.467],
                      [3.933, 2.967],
                      [6.933, 1.134],
                      [2.9, 2.034],
                      [0, 4.334],
                      [-3.367, 2.6],
                      [-9, 0],
                      [0, 0],
                      [7.6, 8.734]
                    ],
                    "v": [
                      [33.5, 1.6],
                      [47.35, -1.15],
                      [56.8, -8.8],
                      [60.2, -20],
                      [54.05, -33.95],
                      [33.6, -41],
                      [19.9, -45.2],
                      [15.8, -52.7],
                      [20.35, -62.4],
                      [32.8, -65.9],
                      [44.55, -63.3],
                      [54.4, -55.1],
                      [60, -60.7],
                      [32.8, -73.1],
                      [19.75, -70.45],
                      [10.75, -63.2],
                      [7.5, -52.6],
                      [13.4, -39.95],
                      [32.9, -33.3],
                      [47.65, -28.55],
                      [52, -19.9],
                      [46.95, -9.5],
                      [33.5, -5.6],
                      [10.5, -17.2],
                      [4.8, -11.5]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "S",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "S",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "R",
      "size": 20,
      "style": "Regular",
      "w": 69.5,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-3.367, 3.567],
                      [0, 5.4],
                      [1.866, 3],
                      [3.333, 1.667],
                      [4.466, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.533, -0.733],
                      [3.366, -3.566],
                      [0, -4.066],
                      [-1.867, -3],
                      [-3.334, -1.666],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [18.6, -31.3],
                      [31.5, -31.3],
                      [55.5, 0],
                      [65.7, 0],
                      [40.7, -31.5],
                      [54.05, -37.95],
                      [59.1, -51.4],
                      [56.3, -62],
                      [48.5, -69],
                      [36.8, -71.5],
                      [10.6, -71.5],
                      [10.6, 0],
                      [18.6, 0]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "R",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-2.5, -2.3],
                      [0, -4.133],
                      [2.5, -2.333],
                      [4.4, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [2.5, 2.3],
                      [0, 4.067],
                      [-2.5, 2.334],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.4, 0]
                    ],
                    "v": [
                      [47.15, -61.05],
                      [50.9, -51.4],
                      [47.15, -41.8],
                      [36.8, -38.3],
                      [18.6, -38.3],
                      [18.6, -64.5],
                      [36.8, -64.5]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "R",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "R",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    },
    {
      "ch": "!",
      "size": 20,
      "style": "Regular",
      "w": 24.2,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-0.134, 2.667],
                      [-0.134, 3.434],
                      [-0.167, 3.634],
                      [-0.134, 3.167],
                      [-0.067, 2.1],
                      [0, 0.334],
                      [0.866, 0.967],
                      [1.333, 0],
                      [0.9, -0.966],
                      [0, -1.733],
                      [-0.067, -2.1],
                      [-0.134, -3.166],
                      [-0.167, -3.633],
                      [-0.134, -3.433],
                      [-0.134, -2.666],
                      [-0.067, -1.133],
                      [0, 0]
                    ],
                    "o": [
                      [0.133, -2.666],
                      [0.133, -3.433],
                      [0.166, -3.633],
                      [0.133, -3.166],
                      [0.066, -2.1],
                      [0, -1.733],
                      [-0.867, -0.966],
                      [-1.267, 0],
                      [-0.9, 0.967],
                      [0, 0.334],
                      [0.066, 2.1],
                      [0.133, 3.167],
                      [0.166, 3.634],
                      [0.133, 3.434],
                      [0.133, 2.667],
                      [0, 0],
                      [0.066, -1.133]
                    ],
                    "v": [
                      [15, -26.1],
                      [15.4, -35.25],
                      [15.85, -45.85],
                      [16.3, -56.05],
                      [16.6, -63.95],
                      [16.7, -67.6],
                      [15.4, -71.65],
                      [12.1, -73.1],
                      [8.85, -71.65],
                      [7.5, -67.6],
                      [7.6, -63.95],
                      [7.9, -56.05],
                      [8.35, -45.85],
                      [8.8, -35.25],
                      [9.2, -26.1],
                      [9.5, -20.4],
                      [14.7, -20.4]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "!",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.1, 1.133],
                      [0, 1.667],
                      [1.1, 1.134],
                      [1.666, 0],
                      [1.1, -1.133],
                      [0, -1.666],
                      [-1.1, -1.133],
                      [-1.667, 0]
                    ],
                    "o": [
                      [1.1, -1.133],
                      [0, -1.666],
                      [-1.1, -1.133],
                      [-1.667, 0],
                      [-1.1, 1.134],
                      [0, 1.667],
                      [1.1, 1.133],
                      [1.666, 0]
                    ],
                    "v": [
                      [16.25, -0.5],
                      [17.9, -4.7],
                      [16.25, -8.9],
                      [12.1, -10.6],
                      [7.95, -8.9],
                      [6.3, -4.7],
                      [7.95, -0.5],
                      [12.1, 1.2]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "!",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "!",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Wix Madefor Text App"
    }
  ]
}
