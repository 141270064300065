import createReactClass from 'create-react-class';
import * as util from '@/util';
import firstTimeTourMixin from '../firstTimeTourMixin';
import React from 'react';
import * as superAppMenuInfra from '@/superAppMenuInfra';
import * as wixStoreDataProvider from '../../../../common/wixStoreDataProvider';
import * as wixStoreEditorActionsService from '../../../../common/wixStoreEditorActionsService';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'templateStep1',
  mixins: [
    util.propTypesFilterMixin as React.Mixin<any, any>,
    firstTimeTourMixin,
  ],
  componentDidMount() {
    wixStoreEditorActionsService.firstTimePanelOpen(
      'welcome_stores_template',
      this.props.openType,
    );
  },
  async nextSlideWithNavigation() {
    if (
      this.getEditorAPI().dsRead.pages.getFocusedPageId() !==
      this.props.superAppMainPageId
    ) {
      return wixStoreEditorActionsService
        .doFirstSave('welcome_stores_template')
        .then(() => {
          this.props.nextSlide();
          this.getEditorAPI().pages.navigateTo(this.getMainStorePageId());
        })
        .catch(() => {
          // Do nothing
        });
    }
    this.props.nextSlide();
  },
  getMainStorePageId() {
    return wixStoreDataProvider.getMainAppPageId();
  },
  render() {
    const data = wixStoreDataProvider.getFirstTimeTourTemplateData();
    const mainImage = util.workspace.isNewWorkspaceEnabled()
      ? 'onlineStoreSpecialBtnPromotional_NewWorkspace'
      : 'onlineStoreSpecialBtnPromotional';

    return (
      <superAppMenuInfra.firstTimeTourPanels.getStartedSlide
        {...this.filteredProps()}
        nextSlide={this.nextSlideWithNavigation}
        superApp={this.props.superApp}
        appWasManuallyInstalled={this.props.appWasManuallyInstalled}
        title={data.title}
        subTitle={data.subtitle}
        pros={data.bullets}
        nextButtonLabel={data.cta}
        mainImage={mainImage}
        footer={this.getUpgradeText()}
        upgradeLink={this.getUpgradeLinkText()}
      />
    );
  },
});
