'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const i18n = require('@/i18n')
module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_titles",
    "subNavigationTitle": "add_section_label_titles",
    "presetTitle": "add_section_label_titles",
    "tooltipTitle": "add_section_label_titles",
    "automationId": "add-panel-section-titlesSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": `addPanelData/sections/LocalizationAddPanelTitles/titlesSection_${util.languages.getLanguageCode()}.png`,
        "imageHover": null,
        "items": [{
            "id": "Text_Titles_1",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 229,
                    "height": 62,
                    "x": 7,
                    "y": 4,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:44px\"><span style=\"font-size:44px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1d.text')}</span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1d"
            },
            "preset": {"rect": {"width": 324, "height": 74, "x": 0, "y": 0}, "tags": "Big Title\n"}
        }, {
            "id": "Text_Titles_2",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 303,
                    "height": 36,
                    "x": 11,
                    "y": 74,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:24px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"font-size:24px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw19.text')}</span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw19"
            },
            "preset": {"rect": {"width": 324, "height": 45, "x": 0, "y": 74}, "tags": "ALL CAPS TITLE\n"}
        }, {
            "id": "Text_Titles_3",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 258,
                    "height": 32,
                    "x": 9,
                    "y": 127,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size: 23px;\"><span style=\"font-family:futura-lt-w01-book,sans-serif;\"><span style=\"font-size:23px;\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1b.text')}</span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1b"
            },
            "preset": {"rect": {"width": 324, "height": 48, "x": 0, "y": 118}, "tags": "Small Title\n"}
        }, {
            "id": "Text_Titles_4",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 261,
                    "height": 32,
                    "x": 10,
                    "y": 179,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:23px\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:libre baskerville,serif\"><span style=\"font-size:23px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1m.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1m"
            },
            "preset": {"rect": {"width": 324, "height": 58, "x": 0, "y": 166}, "tags": "Business Title\n"}
        }, {
            "id": "Text_Titles_5",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 262,
                    "height": 44,
                    "x": 9,
                    "y": 225,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:27px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:belinda-w00-regular,script\"><span style=\"font-size:27px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1c.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1c"
            },
            "preset": {"rect": {"width": 324, "height": 48, "x": 0, "y": 224}, "tags": "Story Title\n"}
        }, {
            "id": "Text_Titles_6",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 246,
                    "height": 40,
                    "x": 10,
                    "y": 282,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size: 28px;\"><span style=\"font-size:28px;\"><span style=\"font-family:helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif\"><span style=\"letter-spacing:0.05em\"><span style=\"font-weight:bold\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1e.text')}</span></span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1e"
            },
            "preset": {"rect": {"width": 324, "height": 57, "x": 0, "y": 272}, "tags": "Classic Title\n"}
        }, {
            "id": "Text_Titles_7",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 296,
                    "height": 71,
                    "x": 8,
                    "y": 344,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size: 70px; line-height: 1em;\"><span style=\"font-size:70px\"><span style=\"font-family:anton,sans-serif\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1k.text')}</span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1k"
            },
            "preset": {"rect": {"width": 324, "height": 94, "x": 0, "y": 329}, "tags": "HUGE TITLE\n"}
        }, {
            "id": "Text_Titles_8",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 286,
                    "height": 29,
                    "x": 9,
                    "y": 438,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:27px; line-height:1em\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"font-size:27px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1f.text')}</span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1f"
            },
            "preset": {"rect": {"width": 324, "height": 57, "x": 0, "y": 423}, "tags": "Pleasant Title\n"}
        }, {
            "id": "Text_Titles_9",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 300,
                    "height": 31,
                    "x": 12,
                    "y": 487,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:22px\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:baskervillemtw01-smbdit,serif\"><span style=\"font-size:22px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1g.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1g"
            },
            "preset": {"rect": {"width": 324, "height": 48, "x": 0, "y": 480}, "tags": "ART OF THE TITLE\n"}
        }, {
            "id": "Text_Titles_10",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 304,
                    "height": 31,
                    "x": 8,
                    "y": 537,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:28px; line-height:1em\"><span style=\"letter-spacing:0.12em;\"><span style=\"font-family:cinzel,serif\"><span style=\"font-size:28px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1h.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1h"
            },
            "preset": {"rect": {"width": 324, "height": 52, "x": 0, "y": 528}, "tags": "ELEGANT TITLE\n"}
        }, {
            "id": "Text_Titles_11",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 300,
                    "height": 43,
                    "x": 8,
                    "y": 586,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size: 35px; line-height: 1.2em;\"><span style=\"font-size:35px\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"letter-spacing:0.01em\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1o.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1o"
            },
            "preset": {"rect": {"width": 324, "height": 58, "x": 0, "y": 580}, "tags": "Bold Title\n"}
        }, {
            "id": "Text_Titles_12",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 246,
                    "height": 64,
                    "x": 8,
                    "y": 644,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:60px; line-height:1em\"><span style=\"font-size:60px\"><span style=\"font-family:trend-sans-w00-four,sans-serif\"><span style=\"color:#292929\">${i18n.translate('comp-ixraf8gv.text')}</span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixraf8gv"
            },
            "preset": {"rect": {"width": 324, "height": 79, "x": 0, "y": 638}, "tags": "TITLE\n"}
        }, {
            "id": "Text_Titles_13",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 242,
                    "height": 26,
                    "x": 10,
                    "y": 725,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:18px\"><span style=\"letter-spacing:0.15em\"><span style=\"font-family:georgia,palatino,book antiqua,palatino linotype,serif\"><span style=\"font-size:18px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw2q.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw2q"
            },
            "preset": {"rect": {"width": 324, "height": 45, "x": 0, "y": 717}, "tags": "minimal title\n"}
        }, {
            "id": "Text_Titles_14",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 271,
                    "height": 45,
                    "x": 8,
                    "y": 773,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:40px; line-height:1em\"><span style=\"letter-spacing:0em\"><span style=\"font-family:snellroundhandw01-scrip,cursive\"><span style=\"font-size:40px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw20.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw20"
            },
            "preset": {"rect": {"width": 324, "height": 66, "x": 0, "y": 762}, "tags": "Fairytale Title\n"}
        }, {
            "id": "Text_Titles_15",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 278,
                    "height": 42,
                    "x": 9,
                    "y": 830,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:30px\"><span style=\"letter-spacing:0.03em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:30px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw21.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw21"
            },
            "preset": {"rect": {"width": 324, "height": 56, "x": 0, "y": 828}, "tags": "The Skinny Title\n"}
        }, {
            "id": "Text_Titles_16",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 298,
                    "height": 55,
                    "x": 11,
                    "y": 888,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:70px; line-height:0.5em\"><span style=\"letter-spacing:0.01em\"><span style=\"font-family:peaches-and-cream-regular-w00,script\"><span style=\"font-size:70px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw22.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw22"
            },
            "preset": {"rect": {"width": 324, "height": 74, "x": 0, "y": 884}, "tags": "Romantic Title\n"}
        }, {
            "id": "Text_Titles_17",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 295,
                    "height": 30,
                    "x": 13,
                    "y": 972,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size: 28px; line-height: 1em;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"font-size:28px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw24.text')}</span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw24"
            },
            "preset": {"rect": {"width": 324, "height": 55, "x": 0, "y": 958}, "tags": "MAGAZINE TITLE \n"}
        }, {
            "id": "Text_Titles_18",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 260,
                    "height": 25,
                    "x": 10,
                    "y": 1025,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:20px; line-height:1.2em\"><span style=\"letter-spacing:0.09em\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif\"><span style=\"font-size:20px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1r.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1r"
            },
            "preset": {"rect": {"width": 324, "height": 47, "x": 0, "y": 1013}, "tags": "Short & Simple Title\n"}
        }, {
            "id": "Text_Titles_19",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 306,
                    "height": 45,
                    "x": 9,
                    "y": 1067,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:42px; line-height:1em\"><span style=\"letter-spacing:0.02em\"><span style=\"font-family:bree-w01-thin-oblique,sans-serif\"><span style=\"font-size:42px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1v.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1v"
            },
            "preset": {"rect": {"width": 324, "height": 61, "x": 0, "y": 1060}, "tags": "Jumbo Title\n"}
        }, {
            "id": "Text_Titles_20",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 244,
                    "height": 35,
                    "x": 8,
                    "y": 1131,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size: 30px; line-height: 1em;\"><span style=\"font-family:din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif\"><span style=\"font-size:30px\"><span style=\"letter-spacing:0em\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1t.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1t"
            },
            "preset": {"rect": {"width": 324, "height": 49, "x": 0, "y": 1121}, "tags": "Medium Title\n"}
        }, {
            "id": "Text_Titles_21",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 280,
                    "height": 41,
                    "x": 10,
                    "y": 1178,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:33px; line-height:1.2em\"><span style=\"font-size:33px\"><span style=\"font-family:baskervillemtw01-smbdit,serif\"><span style=\"letter-spacing:0em\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1p.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1p"
            },
            "preset": {"rect": {"width": 324, "height": 56, "x": 0, "y": 1170}, "tags": "Historic Title\n"}
        }, {
            "id": "Text_Titles_22",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 286,
                    "height": 74,
                    "x": 9,
                    "y": 1233,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size: 70px; line-height: 1em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\"><span style=\"font-size:70px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1i.text')}</span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1i"
            },
            "preset": {"rect": {"width": 324, "height": 86, "x": 0, "y": 1226}, "tags": "TALL TITLE\n"}
        }, {
            "id": "Text_Titles_23",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 208,
                    "height": 23,
                    "x": 10,
                    "y": 1319,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:20px; line-height:1em\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:nimbus-sans-tw01con,sans-serif\"><span style=\"font-size:20px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1j.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1j"
            },
            "preset": {"rect": {"width": 324, "height": 41, "x": 0, "y": 1312}, "tags": "small running title\n"}
        }, {
            "id": "Text_Titles_24",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 304,
                    "height": 38,
                    "x": 9,
                    "y": 1362,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size: 38px; line-height: 1em;\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:playfair display,serif\"><span style=\"font-size:38px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw2m.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw2m"
            },
            "preset": {"rect": {"width": 324, "height": 59, "x": 0, "y": 1353}, "tags": "FASHION TITLE\n"}
        }, {
            "id": "Text_Titles_25",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 286,
                    "height": 32,
                    "x": 9,
                    "y": 1421,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size: 28px; line-height: 1em;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:kepler-w03-light-scd-cp,serif\"><span style=\"font-size:28px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw2o.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw2o"
            },
            "preset": {"rect": {"width": 324, "height": 48, "x": 0, "y": 1411}, "tags": "Universal Title\n"}
        }, {
            "id": "Text_Titles_26",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 284,
                    "height": 68,
                    "x": 7,
                    "y": 1459,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:61px; line-height:1em\"><span style=\"letter-spacing:-0.05em;\"><span style=\"font-size:61px\"><span style=\"font-family:sail,serif\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1x.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1x"
            },
            "preset": {"rect": {"width": 324, "height": 72, "x": 0, "y": 1459}, "tags": "Entitled\n"}
        }, {
            "id": "Text_Titles_27",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 271,
                    "height": 29,
                    "x": 11,
                    "y": 1542,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:28px; line-height:1em\"><span style=\"letter-spacing:0.15em\"><span style=\"font-size:28px\"><span style=\"font-family:marzo-w00-regular,fantasy\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1s.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1s"
            },
            "preset": {"rect": {"width": 324, "height": 55, "x": 0, "y": 1531}, "tags": "Ghostly Title\n"}
        }, {
            "id": "Text_Titles_28",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 174,
                    "height": 44,
                    "x": 11,
                    "y": 1587,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size: 38px; line-height: 1em;\"><span style=\"font-size:38px\"><span style=\"font-family:cookie,cursive\"><span style=\"letter-spacing:0em\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw32.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw32"
            },
            "preset": {"rect": {"width": 324, "height": 50, "x": 0, "y": 1586}, "tags": "Cute Title\n"}
        }, {
            "id": "Text_Titles_29",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 274,
                    "height": 32,
                    "x": 10,
                    "y": 1645,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size: 23px;\"><span style=\"letter-spacing:0.15em;\"><span style=\"font-family:impact,impact-w01-2010,impact-w02-2010,impact-w10-2010,sans-serif;\"><span style=\"font-size:23px;\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw2z.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw2z"
            },
            "preset": {"rect": {"width": 324, "height": 53, "x": 0, "y": 1636}, "tags": "SMALL BOLD TITLE\n"}
        }, {
            "id": "Text_Titles_30",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 306,
                    "height": 38,
                    "x": 10,
                    "y": 1694,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:30px; line-height:1.2em\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:raleway,sans-serif\"><span style=\"font-size:30px\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw1n.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw1n"
            },
            "preset": {"rect": {"width": 324, "height": 54, "x": 0, "y": 1689}, "tags": "What’s My Title?\n"}
        }, {
            "id": "Text_Titles_31",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 298,
                    "height": 45,
                    "x": 10,
                    "y": 1744,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:35px; line-height:1.2em\"><span style=\"font-size:35px\"><span style=\"font-family:reklamescriptw00-medium,cursive\"><span style=\"color:#292929\">${i18n.translate('comp-ixr9mw2u.text')}</span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixr9mw2u"
            },
            "preset": {"rect": {"width": 324, "height": 58, "x": 0, "y": 1743}, "tags": "Funky Title\n"}
        }, {
            "id": "Text_Titles_32",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 298,
                    "height": 32,
                    "x": 10,
                    "y": 1805,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:23px\"><span style=\"letter-spacing:0.15em\"><span style=\"font-family:josefin slab,serif\"><span style=\"font-size:23px\"><span style=\"color:#292929\">${i18n.translate('comp-ixrbqz71.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixrbqz71"
            },
            "preset": {"rect": {"width": 324, "height": 45, "x": 0, "y": 1801}, "tags": "Delicate Title\n"}
        }, {
            "id": "Text_Titles_33",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 299,
                    "height": 54,
                    "x": 9,
                    "y": 1853,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size:45px; line-height:1em\"><span style=\"letter-spacing:0em\"><span style=\"font-size:45px\"><span style=\"font-family:sacramento,cursive\"><span style=\"color:#292929\">${i18n.translate('comp-ixrbs0br.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixrbs0br"
            },
            "preset": {"rect": {"width": 324, "height": 77, "x": 0, "y": 1846}, "tags": "Beautiful Title\n"}
        }, {
            "id": "Text_Titles_34",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 299,
                    "height": 31,
                    "x": 9,
                    "y": 1928,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": `<h2 class=\"font_2\" style=\"font-size: 30px; line-height: 1em;\"><span style=\"letter-spacing:0.15em\"><span style=\"font-family:open sans condensed,sans-serif\"><span style=\"font-size:30px\"><span style=\"color:#292929\">${i18n.translate('comp-ixrbtouw.text')}</span></span></span></span></h2>\n`,
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "txtNew",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "f0": "normal normal normal 45px/1.4em arial+black {color_14}",
                            "f1": "normal normal normal 13px/1.4em arial+black {color_11}",
                            "f10": "normal normal normal 10px/1.4em Arial {color_15}",
                            "f2": "normal normal normal 26px/1.4em arial+black {color_14}",
                            "f3": "normal normal normal 40px/1.4em arial+black {color_14}",
                            "f4": "normal normal normal 30px/1.4em sacramento {color_14}",
                            "f5": "normal normal normal 22px/1.4em Open+Sans {color_14}",
                            "f6": "normal normal normal 19px/1.4em Open+Sans {color_14}",
                            "f7": "normal normal normal 15px/1.4em helvetica-w01-light {color_15}",
                            "f8": "normal normal normal 13px/1.4em helvetica-w01-light {color_15}",
                            "f9": "normal normal normal 12px/1.4em Arial {color_15}"
                        },
                        "propertiesSource": {
                            "f0": "value",
                            "f1": "value",
                            "f10": "value",
                            "f2": "value",
                            "f3": "value",
                            "f4": "value",
                            "f5": "value",
                            "f6": "value",
                            "f7": "value",
                            "f8": "value",
                            "f9": "value"
                        }
                    },
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                },
                "activeModes": {},
                "id": "comp-ixrbtouw"
            },
            "preset": {"rect": {"width": 324, "height": 52, "x": 0, "y": 1923}, "tags": "NARROW TITLE\n"}
        }],
        "compTypes": ["wysiwyg.viewer.components.WRichText"]
    },
    "help": {"hide": false, "text": "add_section_info_text_titles"}
}
