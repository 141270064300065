import React from 'react';
import { Text, Button } from '@wix/wix-base-ui';
import styles from './Section.scss';

export const Section: React.FC<{
  heading: string;
  children: React.ReactNode;
  linkText?: string;
  onLinkClick?: () => void;
  dataHook?: string;
}> = ({ heading, children, linkText, onLinkClick, dataHook }) => (
  <div className={styles.section} data-hook={dataHook}>
    <div className={styles.heading}>
      <Text skin="standard" size="small" weight="bold" shouldTranslate={false}>
        {heading}
      </Text>
      {linkText ? (
        <Button
          className={`btn-text btn-sm ${styles.link}`}
          onClick={onLinkClick}
        >
          {linkText}
        </Button>
      ) : null}
    </div>

    <div className={styles.content}>{children}</div>
  </div>
);
