// @ts-nocheck
function add(editorAPI, appData, token, options) {
  return new Promise(function (resolve) {
    editorAPI.dsActions.waitForChangesApplied(function () {
      editorAPI.history.add(
        options.label,
        options.params !== undefined ? { __appParams: options.params } : null,
        options.historySnapshotId,
      );
      resolve();
    });
  });
}

export { add };
