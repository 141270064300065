// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import * as newBlogBi from '../../../newBlogBi/newBlogBi';
import * as blogUtils from '../../../utils/blogUtils';
import React from 'react';
import * as baseUI from '@/baseUI';
import * as symbols from '@wix/santa-editor-symbols';

class QuickMigrationStatusPanel extends React.Component {
  static displayName = 'QuickMigrationStatusPanel';

  addNewBlog = () => {
    this.props.startQuickMigrationProp(this.props.sendBi, {
      action: 'switch',
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
    });
  };

  openHelpArticle = () => {
    this.props.sendBi(newBlogBi.events.BLOG_QUICK_MIGRATION_LEARN_MORE, {
      role: 'owner',
      action: 'switch',
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
      instance_id: null,
    });
    this.props.openHelpCenter(blogUtils.helpArticles?.oldBlogMigration);
  };

  render() {
    return (
      <div className="new-blog-migration-panel">
        <symbols.symbol name="newBlogIcon" className="newBlogIcon" />
        <div className="box">
          <h4>{translate('SiteApp_Blog_Migration_Panel_Title')}</h4>

          <p>
            {translate('SiteApp_Blog_Migration_Panel_Description')}
            <span> </span>
            <a onClick={this.openHelpArticle}>
              {translate('SiteApp_Blog_Migration_Panel_LearnMore')}
            </a>
          </p>

          <baseUI.button
            label="SiteApp_Blog_Migration_Promo_Button_Switch"
            onClick={this.addNewBlog}
            className="btn-confirm-primary btn-switch"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ editorAPI }) => ({
  startQuickMigrationProp: _.partial(blogUtils.startQuickMigration, editorAPI),
});

const mapDispatchToProps = (dispatch) => ({
  openHelpCenter: (helpId) =>
    dispatch(stateManagement.panels.actions.openHelpCenter(helpId)),
  sendBi: (event, parameters) =>
    dispatch(stateManagement.bi.actions.event(event, parameters)),
});

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(QuickMigrationStatusPanel);
ConnectedComponent.pure = QuickMigrationStatusPanel;

export default ConnectedComponent;
