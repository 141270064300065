// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import mobileWizardData from './mobileWizardData.json';
import React from 'react';
import MobileWizardSingleStep from './mobileWizardSingleStep';

const selectors = stateManagement.mobile.selectors.mobileWizard;
const actions = stateManagement.mobile.actions.mobileWizard;
const BACK_TO_TOP_BUTTON_POINTER = { id: 'BACK_TO_TOP_BUTTON', type: 'MOBILE' };

const availableValues = ['true', 'false'];

//TYPE WAS GENERATED, remove this line when reviewed
interface MobileWizardSelectBackToTopProps {
  backToTop?: string;
  setBackToTop: FunctionFixMe;
  init?: FunctionFixMe;
}

class MobileWizardSelectBackToTop extends React.Component<MobileWizardSelectBackToTopProps> {
  static displayName = 'MobileWizardSelectBackToTop';

  static propTypes = {
    backToTop: PropTypes.string,
    setBackToTop: PropTypes.func.isRequired,
    init: PropTypes.func,
  };

  componentDidMount() {
    this.props.init();
  }

  getStepOptions = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(availableValues, (boolAsString) => {
      const { label, imageSrc, automationId } = _.get(
        mobileWizardData[BACK_TO_TOP_BUTTON_POINTER.id],
        boolAsString,
      );
      return {
        value: boolAsString,
        isSelected: String(this.props.backToTop) === boolAsString,
        label,
        imageSrc,
        automationId,
      };
    });
  };

  setSelectedOption = (option) => {
    const bool = option.value === 'true';
    this.props.setBackToTop(bool);
  };

  render() {
    return (
      <MobileWizardSingleStep
        headerLabel="Mobile_Wizard_Welcome_BackToTop_Title"
        actionToTakeLabel="Mobile_Wizard_Welcome_AddFeature_Line"
        automationId="mobile-wizard-top-button-step"
        options={this.getStepOptions()}
        onOptionSelected={this.setSelectedOption}
      />
    );
  }
}

const stateToProps = ({ state }) => ({
  backToTop:
    selectors.backToTop.getSelectedBackToTop(state) ||
    selectors.getLastSaved(state, 'backToTop'), // 'true' || 'false' as strings
});

const dispatchToProps = (dispatch) => ({
  init() {
    dispatch(actions.initBackToTopStep());
  },
  setBackToTop(bool) {
    dispatch(actions.setSelectedBackToTop(bool));
  },
});

const connected = _.flow(
  util.hoc.renderWhenMutated,
  util.hoc.connect(util.hoc.STORES.STATE_ONLY, stateToProps, dispatchToProps),
)(MobileWizardSelectBackToTop);
connected.pure = MobileWizardSelectBackToTop;
export default connected;
