'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports =
  {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_media_player_masked_label",
    "automationId": "add-panel-section-videoBoxMaskedSection",
    "showSectionHeader": true,
    "additionalBehaviours": {
      "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
      "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.VIDEO,
      "iconEnabledForComps": {}
    },
    "props": {
      'image': 'addPanelData/sections/videoSection_en/videoBoxMasked.v3.png',
      'retinaImage': {
        'src': 'addPanelData/sections/videoSection_en/videoBoxMasked-@2.v3.png',
        'dimensions': {
          'width': 648,
          'height': 1640
        }
      },
      "imageHover": null,
      "items": [
        {
          "id": "VideoBox_VideoBoxMasked_1",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "nzg-823",
              "pageId": "rs06z"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-434",
                  "pageId": "rs06z"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 85,
                  "height": 85,
                  "x": 86,
                  "y": 125,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kr81fuy4",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "nzg-570",
                    "autoGenerated": false,
                    "pageId": "rs06z"
                  },
                  "playerId": "comp-kr81fuy4"
                },
                "mobileHints": {
                  "type": "MobileHints",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-793",
                    "pageId": "rs06z"
                  },
                  "author": "studio",
                  "offsetX": 92,
                  "offsetY": 126,
                  "offsetOrigin": "leftTop",
                  "recommendedScale": 1,
                  "recommendedWidth": 56,
                  "recommendedHeight": 56,
                  "orderIndex": 0
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "xae-428",
                    "pageId": "rs06z"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "xae-429",
                      "pageId": "rs06z"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-565",
                        "pageId": "rs06z"
                      },
                      "name": "playButton",
                      "svgId": "0da768_661bc0cafffa432db3753ad5d17e4f10.svg"
                    }
                  ]
                },
                "style": {
                  "type": "ComponentStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-567",
                    "pageId": "rs06z"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "custom",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "nzg-838",
                  "pageId": "rs06z"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 206,
                  "y": 292,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kr81fuy4",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "nzg-581",
                    "autoGenerated": false,
                    "pageId": "rs06z"
                  },
                  "showStoryboard": "time",
                  "playerId": "comp-kr81fuy4"
                },
                "mobileHints": {
                  "type": "MobileHints",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-795",
                    "pageId": "rs06z"
                  },
                  "author": "studio",
                  "offsetX": 98,
                  "offsetY": 89,
                  "offsetOrigin": "leftBottom",
                  "recommendedScale": 1,
                  "recommendedWidth": 51,
                  "recommendedHeight": 38,
                  "orderIndex": 1
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-572",
                    "pageId": "rs06z"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "nzg-577",
                      "pageId": "rs06z"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-576",
                        "pageId": "rs06z"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_9c02fee4517e40e1bfe9581b4cfa3750.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-575",
                        "pageId": "rs06z"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-573",
                        "pageId": "rs06z"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "nzg-574",
                          "pageId": "rs06z"
                        },
                        "overrideColors": {
                          "color1": "#ACAEAF"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "ComponentStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-578",
                    "pageId": "rs06z"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "disabledcolor": "#8d8d8d",
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d"
                    },
                    "propertiesSource": {
                      "disabledcolor": "value",
                      "maincolor": "value",
                      "textcolor": "value"
                    }
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "custom",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
              "width": 257,
              "height": 336,
              "x": 19,
              "y": 21,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kr81fuwv",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "nzg-555",
                "pageId": "rs06z"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "toggle",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "mobileHints": {
              "type": "MobileHints",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "dau-126",
                "pageId": "rs06z"
              },
              "author": "studio",
              "recommendedScale": 1,
              "recommendedWidth": 241,
              "recommendedHeight": 309
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "2k1-123",
                "pageId": "rs06z"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "2k1-124",
                  "pageId": "rs06z"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "2k1-125",
                    "pageId": "rs06z"
                  },
                  "title": "Flower Vases",
                  "videoId": "11062b_0bcdf51b268e4f4fbb1a2038440f04cb",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 270,
                      "height": 480,
                      "url": "video/11062b_0bcdf51b268e4f4fbb1a2038440f04cb/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 406,
                      "height": 720,
                      "url": "video/11062b_0bcdf51b268e4f4fbb1a2038440f04cb/720p/mp4/file.mp4"
                    },
                    {
                      "quality": "1080p",
                      "width": 1080,
                      "height": 1920,
                      "url": "video/11062b_0bcdf51b268e4f4fbb1a2038440f04cb/1080p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "2k1-126",
                      "pageId": "rs06z"
                    },
                    "title": "",
                    "uri": "11062b_0bcdf51b268e4f4fbb1a2038440f04cbf000.jpg",
                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/babd428d-abdf-463a-ac71-a23e3cafaffa/all/any/all",
                    "width": 1080,
                    "height": 1920,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 20.36,
                  "preload": "auto",
                  "artist": {
                    "id": "7302700",
                    "name": "pexels"
                  },
                  "generatedPosters": [
                    "11062b_0bcdf51b268e4f4fbb1a2038440f04cbf000.jpg",
                    "11062b_0bcdf51b268e4f4fbb1a2038440f04cbf001.jpg",
                    "11062b_0bcdf51b268e4f4fbb1a2038440f04cbf002.jpg",
                    "11062b_0bcdf51b268e4f4fbb1a2038440f04cbf003.jpg"
                  ],
                  "hasAudio": false,
                  "fps": "50/1",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_0bcdf51b268e4f4fbb1a2038440f04cb/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ],
                  "mask": {
                    "x": 0,
                    "y": 0,
                    "width": 100,
                    "height": 100,
                    "svgId": "cf545b123b6e4523b228d62bfa0dea9b.svg",
                    "mode": "alpha"
                  }
                },
                "color": "#212121",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
              },
              "cssStyle": {},
              "themeMappings": {},
              "charas": "design-knmwtfrl"
            },
            "style": {
              "type": "ComponentStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "nzg-560",
                "pageId": "rs06z"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kr81fuy4"
          },
          "preset": {
            "rect": {
              "width": 148,
              "height": 190,
              "x": 0,
              "y": 0
            },
            "mediaManager": {
              "path": "public/8e256233-1752-4026-9341-54036e7f875e/babd428d-abdf-463a-ac71-a23e3cafaffa"
            }
          }
        },
        {
          "id": "VideoBox_VideoBoxMasked_2",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "dau-91",
              "pageId": "rs06z"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-852",
                  "pageId": "rs06z"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 80,
                  "height": 80,
                  "x": 114,
                  "y": 130,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kr81fuzj",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "nzg-600",
                    "autoGenerated": false,
                    "pageId": "rs06z"
                  },
                  "playerId": "comp-kr81fuzj"
                },
                "mobileHints": {
                  "type": "MobileHints",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-797",
                    "pageId": "rs06z"
                  },
                  "author": "studio",
                  "offsetX": 96,
                  "offsetY": 110,
                  "offsetOrigin": "leftTop",
                  "recommendedScale": 1,
                  "recommendedWidth": 51,
                  "recommendedHeight": 51,
                  "orderIndex": 0
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "xae-861",
                    "pageId": "rs06z"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "xae-415",
                      "pageId": "rs06z"
                    },
                    "overrideColors": {
                      "color1": "color_11"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "xae-862",
                        "pageId": "rs06z"
                      },
                      "name": "playButton",
                      "svgId": "0da768_152a8490c7444b949a51e30f2ac4d9a4.svg"
                    }
                  ]
                },
                "style": {
                  "type": "ComponentStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-597",
                    "pageId": "rs06z"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "custom",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "nzg-840",
                  "pageId": "rs06z"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 258,
                  "y": 296,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kr81fuzj",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "nzg-611",
                    "autoGenerated": false,
                    "pageId": "rs06z"
                  },
                  "showStoryboard": "time",
                  "playerId": "comp-kr81fuzj"
                },
                "mobileHints": {
                  "type": "MobileHints",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-799",
                    "pageId": "rs06z"
                  },
                  "author": "studio",
                  "offsetX": 97,
                  "offsetY": 72,
                  "offsetOrigin": "leftBottom",
                  "recommendedScale": 1,
                  "recommendedWidth": 51,
                  "recommendedHeight": 38,
                  "orderIndex": 1
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-602",
                    "pageId": "rs06z"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "nzg-607",
                      "pageId": "rs06z"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-606",
                        "pageId": "rs06z"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_9c02fee4517e40e1bfe9581b4cfa3750.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-605",
                        "pageId": "rs06z"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-603",
                        "pageId": "rs06z"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "nzg-604",
                          "pageId": "rs06z"
                        },
                        "overrideColors": {
                          "color1": "#ACAEAF"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "ComponentStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-608",
                    "pageId": "rs06z"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "disabledcolor": "#8d8d8d",
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d"
                    },
                    "propertiesSource": {
                      "disabledcolor": "value",
                      "maincolor": "value",
                      "textcolor": "value"
                    }
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "custom",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
              "width": 309,
              "height": 340,
              "x": 354,
              "y": 17,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kr81fuwv",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "nzg-585",
                "autoGenerated": false,
                "pageId": "rs06z"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "toggle",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "unmute",
                "rollOut": "mute"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "mobileHints": {
              "type": "MobileHints",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "dau-128",
                "pageId": "rs06z"
              },
              "author": "studio",
              "recommendedScale": 1,
              "recommendedWidth": 244,
              "recommendedHeight": 271
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "2k1-130",
                "pageId": "rs06z"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "2k1-131",
                  "pageId": "rs06z"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "2k1-132",
                    "pageId": "rs06z"
                  },
                  "title": "Blue Water",
                  "videoId": "11062b_a38d9d34d04f4f6eb710f18346db6293",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 854,
                      "height": 480,
                      "url": "video/11062b_a38d9d34d04f4f6eb710f18346db6293/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 1280,
                      "height": 720,
                      "url": "video/11062b_a38d9d34d04f4f6eb710f18346db6293/720p/mp4/file.mp4"
                    },
                    {
                      "quality": "1080p",
                      "width": 1920,
                      "height": 1080,
                      "url": "video/11062b_a38d9d34d04f4f6eb710f18346db6293/1080p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "2k1-133",
                      "pageId": "rs06z"
                    },
                    "title": "",
                    "uri": "11062b_a38d9d34d04f4f6eb710f18346db6293f000.jpg",
                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1/all/any/all",
                    "width": 1920,
                    "height": 1080,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 22.42,
                  "preload": "auto",
                  "artist": {
                    "id": "180616751",
                    "name": "gettyimages"
                  },
                  "generatedPosters": [
                    "11062b_a38d9d34d04f4f6eb710f18346db6293f000.jpg",
                    "11062b_a38d9d34d04f4f6eb710f18346db6293f001.jpg",
                    "11062b_a38d9d34d04f4f6eb710f18346db6293f002.jpg",
                    "11062b_a38d9d34d04f4f6eb710f18346db6293f003.jpg"
                  ],
                  "hasAudio": false,
                  "fps": "30000/1001",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_a38d9d34d04f4f6eb710f18346db6293/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ],
                  "mask": {
                    "x": 0,
                    "y": 0,
                    "width": 100,
                    "height": 100,
                    "svgId": "11062b_c7c7c38386db4576a09e19b056715bfd.svg",
                    "mode": "alpha"
                  }
                },
                "color": "#212121",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
              },
              "cssStyle": {},
              "themeMappings": {},
              "charas": "design-kn32tyaq"
            },
            "style": {
              "type": "ComponentStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "nzg-590",
                "pageId": "rs06z"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kr81fuzj"
          },
          "preset": {
            "rect": {
              "width": 176,
              "height": 190,
              "x": 148,
              "y": 0
            },
            "mediaManager": {
              "path": "public/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1"
            }
          }
        },
        {
          "id": "VideoBox_VideoBoxMasked_3",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "dau-115",
              "pageId": "rs06z"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "dau-114",
                  "pageId": "rs06z"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 89,
                  "height": 89,
                  "x": 189,
                  "y": 151,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kr81fuzp",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "nzg-630",
                    "autoGenerated": false,
                    "pageId": "rs06z"
                  },
                  "playerId": "comp-kr81fuzp"
                },
                "mobileHints": {
                  "type": "MobileHints",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-801",
                    "pageId": "rs06z"
                  },
                  "author": "studio",
                  "offsetX": 105,
                  "offsetY": 92,
                  "offsetOrigin": "leftTop",
                  "recommendedScale": 1,
                  "recommendedWidth": 50,
                  "recommendedHeight": 50,
                  "orderIndex": 0
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "xae-564",
                    "pageId": "rs06z"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "xae-449",
                      "pageId": "rs06z"
                    },
                    "overrideColors": {
                      "color1": "color_11",
                      "color2": "color_15"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "xae-565",
                        "pageId": "rs06z"
                      },
                      "name": "playButton",
                      "svgId": "0da768_b94b0c8d9ad140b397386b0796eab836.svg"
                    }
                  ]
                },
                "style": {
                  "type": "ComponentStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-627",
                    "pageId": "rs06z"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "custom",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "dau-116",
                  "pageId": "rs06z"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 417,
                  "y": 348,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kr81fuzp",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "nzg-641",
                    "autoGenerated": false,
                    "pageId": "rs06z"
                  },
                  "showStoryboard": "time",
                  "playerId": "comp-kr81fuzp"
                },
                "mobileHints": {
                  "type": "MobileHints",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-803",
                    "pageId": "rs06z"
                  },
                  "author": "studio",
                  "offsetX": 104,
                  "offsetY": 48,
                  "offsetOrigin": "leftBottom",
                  "recommendedScale": 1,
                  "recommendedWidth": 51,
                  "recommendedHeight": 44,
                  "orderIndex": 1
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-632",
                    "pageId": "rs06z"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "nzg-637",
                      "pageId": "rs06z"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-636",
                        "pageId": "rs06z"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_9c02fee4517e40e1bfe9581b4cfa3750.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-635",
                        "pageId": "rs06z"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-633",
                        "pageId": "rs06z"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "nzg-634",
                          "pageId": "rs06z"
                        },
                        "overrideColors": {
                          "color1": "#ACAEAF"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "ComponentStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-638",
                    "pageId": "rs06z"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "disabledcolor": "#8d8d8d",
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d"
                    },
                    "propertiesSource": {
                      "disabledcolor": "value",
                      "maincolor": "value",
                      "textcolor": "value"
                    }
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "custom",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
              "width": 468,
              "height": 392,
              "x": 15,
              "y": 445,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kr81fuwv",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "nzg-615",
                "pageId": "rs06z"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "toggle",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "mobileHints": {
              "type": "MobileHints",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "dau-130",
                "pageId": "rs06z"
              },
              "author": "studio",
              "recommendedScale": 1,
              "recommendedWidth": 260,
              "recommendedHeight": 234
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "2k1-136",
                "pageId": "rs06z"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "2k1-137",
                  "pageId": "rs06z"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "2k1-138",
                    "pageId": "rs06z"
                  },
                  "title": "Hand Sculpture",
                  "videoId": "11062b_a2485e3503c647fca921189dbb7b5105",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 854,
                      "height": 480,
                      "url": "video/11062b_a2485e3503c647fca921189dbb7b5105/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 1280,
                      "height": 720,
                      "url": "video/11062b_a2485e3503c647fca921189dbb7b5105/720p/mp4/file.mp4"
                    },
                    {
                      "quality": "1080p",
                      "width": 1920,
                      "height": 1080,
                      "url": "video/11062b_a2485e3503c647fca921189dbb7b5105/1080p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "2k1-139",
                      "pageId": "rs06z"
                    },
                    "title": "",
                    "uri": "11062b_a2485e3503c647fca921189dbb7b5105f000.jpg",
                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/3892dca7-115f-4b62-ba8d-11a7b220ab76/all/any/all",
                    "width": 1920,
                    "height": 1080,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 2.97,
                  "preload": "auto",
                  "artist": {
                    "id": "427577",
                    "name": "Unsplash"
                  },
                  "generatedPosters": [
                    "11062b_a2485e3503c647fca921189dbb7b5105f000.jpg",
                    "11062b_a2485e3503c647fca921189dbb7b5105f001.jpg",
                    "11062b_a2485e3503c647fca921189dbb7b5105f002.jpg",
                    "11062b_a2485e3503c647fca921189dbb7b5105f003.jpg"
                  ],
                  "hasAudio": false,
                  "fps": "30",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "files/video/11062b_a2485e3503c647fca921189dbb7b5105/repackage/hls"
                    }
                  ],
                  "mask": {
                    "x": 0,
                    "y": 0,
                    "width": 100,
                    "height": 100,
                    "svgId": "11062b_f07b59222c134fc9858a063bfff085d2.svg",
                    "mode": "alpha"
                  }
                },
                "color": "#212121",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
              },
              "cssStyle": {},
              "themeMappings": {},
              "charas": "design-knmwt61u"
            },
            "style": {
              "type": "ComponentStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "nzg-620",
                "pageId": "rs06z"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kr81fuzp"
          },
          "preset": {
            "rect": {
              "width": 324,
              "height": 274,
              "x": 0,
              "y": 190
            },
            "mediaManager": {
              "path": "public/8e256233-1752-4026-9341-54036e7f875e/3892dca7-115f-4b62-ba8d-11a7b220ab76"
            }
          }
        },
        {
          "id": "VideoBox_VideoBoxMasked_4",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "dau-298",
              "pageId": "rs06z"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "dau-296",
                  "pageId": "rs06z"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 153,
                  "height": 154,
                  "x": 60,
                  "y": 94,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kr81fuzw",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "nzg-660",
                    "autoGenerated": false,
                    "pageId": "rs06z"
                  },
                  "playerId": "comp-kr81fuzw"
                },
                "mobileHints": {
                  "type": "MobileHints",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-805",
                    "pageId": "rs06z"
                  },
                  "author": "studio",
                  "offsetX": 51,
                  "offsetY": 80,
                  "offsetOrigin": "leftTop",
                  "recommendedScale": 1,
                  "recommendedWidth": 141,
                  "recommendedHeight": 143,
                  "orderIndex": 0
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "xae-598",
                    "pageId": "rs06z"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "xae-599",
                      "pageId": "rs06z"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-655",
                        "pageId": "rs06z"
                      },
                      "name": "playButton",
                      "svgId": "0da768_70d326d6112c4597bee7759165a86dc2.svg"
                    }
                  ]
                },
                "style": {
                  "type": "ComponentStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-657",
                    "pageId": "rs06z"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "custom",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "dau-297",
                  "pageId": "rs06z"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 223,
                  "y": 299,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kr81fuzw",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "nzg-671",
                    "autoGenerated": false,
                    "pageId": "rs06z"
                  },
                  "showStoryboard": "time",
                  "playerId": "comp-kr81fuzw"
                },
                "mobileHints": {
                  "type": "MobileHints",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-807",
                    "pageId": "rs06z"
                  },
                  "author": "studio",
                  "offsetX": 142,
                  "offsetY": 45,
                  "offsetOrigin": "leftBottom",
                  "recommendedScale": 1,
                  "recommendedWidth": 51,
                  "recommendedHeight": 35,
                  "orderIndex": 1
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-662",
                    "pageId": "rs06z"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "nzg-667",
                      "pageId": "rs06z"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-666",
                        "pageId": "rs06z"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_9c02fee4517e40e1bfe9581b4cfa3750.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-665",
                        "pageId": "rs06z"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-663",
                        "pageId": "rs06z"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "nzg-664",
                          "pageId": "rs06z"
                        },
                        "overrideColors": {
                          "color1": "#ACAEAF"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "ComponentStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-668",
                    "pageId": "rs06z"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "disabledcolor": "#8d8d8d",
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d"
                    },
                    "propertiesSource": {
                      "disabledcolor": "value",
                      "maincolor": "value",
                      "textcolor": "value"
                    }
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "custom",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
              "width": 274,
              "height": 343,
              "x": 17,
              "y": 984,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kr81fuwv",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "nzg-645",
                "autoGenerated": false,
                "pageId": "rs06z"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "toggle",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "unmute",
                "rollOut": "mute"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "mobileHints": {
              "type": "MobileHints",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "dau-132",
                "pageId": "rs06z"
              },
              "author": "studio",
              "recommendedScale": 1,
              "recommendedWidth": 244,
              "recommendedHeight": 303
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "2k1-142",
                "pageId": "rs06z"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "2k1-143",
                  "pageId": "rs06z"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "2k1-144",
                    "pageId": "rs06z"
                  },
                  "title": "Stones and Marble",
                  "videoId": "11062b_dc8bfc68320a417fb6233a9b507c020d",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 854,
                      "height": 480,
                      "url": "video/11062b_dc8bfc68320a417fb6233a9b507c020d/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 1280,
                      "height": 720,
                      "url": "video/11062b_dc8bfc68320a417fb6233a9b507c020d/720p/mp4/file.mp4"
                    },
                    {
                      "quality": "1080p",
                      "width": 1920,
                      "height": 1080,
                      "url": "video/11062b_dc8bfc68320a417fb6233a9b507c020d/1080p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "2k1-145",
                      "pageId": "rs06z"
                    },
                    "title": "",
                    "uri": "11062b_dc8bfc68320a417fb6233a9b507c020df000.jpg",
                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/3892dca7-115f-4b62-ba8d-11a7b220ab76/all/any/all",
                    "width": 1920,
                    "height": 1080,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 8,
                  "preload": "auto",
                  "artist": {
                    "id": "1211487111",
                    "name": "GettyImages"
                  },
                  "generatedPosters": [
                    "11062b_dc8bfc68320a417fb6233a9b507c020df000.jpg",
                    "11062b_dc8bfc68320a417fb6233a9b507c020df001.jpg",
                    "11062b_dc8bfc68320a417fb6233a9b507c020df002.jpg",
                    "11062b_dc8bfc68320a417fb6233a9b507c020df003.jpg"
                  ],
                  "hasAudio": false,
                  "fps": "25/1",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_dc8bfc68320a417fb6233a9b507c020d/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ],
                  "mask": {
                    "x": 0,
                    "y": 0,
                    "width": 100,
                    "height": 100,
                    "svgId": "2428012b6769448081719f3fde9f0f78.svg",
                    "mode": "alpha"
                  }
                },
                "color": "#212121",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
              },
              "cssStyle": {},
              "themeMappings": {},
              "charas": "design-ko9v9k1k"
            },
            "style": {
              "type": "ComponentStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "nzg-650",
                "pageId": "rs06z"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kr81fuzw"
          },
          "preset": {
            "rect": {
              "width": 172,
              "height": 210,
              "x": 0,
              "y": 464
            },
            "mediaManager": {
              "path": "public/8e256233-1752-4026-9341-54036e7f875e/3892dca7-115f-4b62-ba8d-11a7b220ab76"
            }
          }
        },
        {
          "id": "VideoBox_VideoBoxMasked_5",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "dau-300",
              "pageId": "rs06z"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "dau-292",
                  "pageId": "rs06z"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 90,
                  "height": 90,
                  "x": 75,
                  "y": 127,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kr81fv02",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "nzg-690",
                    "autoGenerated": false,
                    "pageId": "rs06z"
                  },
                  "playerId": "comp-kr81fv02"
                },
                "mobileHints": {
                  "type": "MobileHints",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-809",
                    "pageId": "rs06z"
                  },
                  "author": "studio",
                  "offsetX": 42,
                  "offsetY": 95,
                  "offsetOrigin": "leftTop",
                  "recommendedScale": 1,
                  "recommendedWidth": 156,
                  "recommendedHeight": 156,
                  "orderIndex": 0
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "xae-710",
                    "pageId": "rs06z"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "xae-573",
                      "pageId": "rs06z"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "xae-711",
                        "pageId": "rs06z"
                      },
                      "name": "playButton",
                      "svgId": "0da768_9ef4717e013a4d529487731c6c2a49d5.svg"
                    }
                  ]
                },
                "style": {
                  "type": "ComponentStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-687",
                    "pageId": "rs06z"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "custom",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "dau-236",
                  "pageId": "rs06z"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 189,
                  "y": 301,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kr81fv02",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "nzg-701",
                    "autoGenerated": false,
                    "pageId": "rs06z"
                  },
                  "showStoryboard": "time",
                  "playerId": "comp-kr81fv02"
                },
                "mobileHints": {
                  "type": "MobileHints",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-811",
                    "pageId": "rs06z"
                  },
                  "author": "studio",
                  "offsetX": 148,
                  "offsetY": 56,
                  "offsetOrigin": "leftBottom",
                  "recommendedScale": 1,
                  "recommendedWidth": 51,
                  "recommendedHeight": 40,
                  "orderIndex": 1
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-692",
                    "pageId": "rs06z"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "nzg-697",
                      "pageId": "rs06z"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-696",
                        "pageId": "rs06z"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_9c02fee4517e40e1bfe9581b4cfa3750.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-695",
                        "pageId": "rs06z"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-693",
                        "pageId": "rs06z"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "nzg-694",
                          "pageId": "rs06z"
                        },
                        "overrideColors": {
                          "color1": "#ACAEAF"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "ComponentStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-698",
                    "pageId": "rs06z"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "disabledcolor": "#8d8d8d",
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d"
                    },
                    "propertiesSource": {
                      "disabledcolor": "value",
                      "maincolor": "value",
                      "textcolor": "value"
                    }
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "custom",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
              "width": 240,
              "height": 345,
              "x": 358,
              "y": 984,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kr81fuwv",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "nzg-675",
                "pageId": "rs06z"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "toggle",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "mobileHints": {
              "type": "MobileHints",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "dau-134",
                "pageId": "rs06z"
              },
              "author": "studio",
              "recommendedScale": 1,
              "recommendedWidth": 241,
              "recommendedHeight": 347
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "2k1-148",
                "pageId": "rs06z"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "2k1-149",
                  "pageId": "rs06z"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "2k1-150",
                    "pageId": "rs06z"
                  },
                  "title": "Forest",
                  "videoId": "11062b_d578b9d4ffba48c68d086ec29fe9e6f0",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 854,
                      "height": 480,
                      "url": "video/11062b_d578b9d4ffba48c68d086ec29fe9e6f0/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 1280,
                      "height": 720,
                      "url": "video/11062b_d578b9d4ffba48c68d086ec29fe9e6f0/720p/mp4/file.mp4"
                    },
                    {
                      "quality": "1080p",
                      "width": 1920,
                      "height": 1080,
                      "url": "video/11062b_d578b9d4ffba48c68d086ec29fe9e6f0/1080p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "2k1-151",
                      "pageId": "rs06z"
                    },
                    "title": "",
                    "uri": "11062b_d578b9d4ffba48c68d086ec29fe9e6f0f000.jpg",
                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1/all/any/all",
                    "width": 1920,
                    "height": 1080,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 40.8,
                  "preload": "auto",
                  "artist": {
                    "id": "117972864",
                    "name": "gettyimages"
                  },
                  "generatedPosters": [
                    "11062b_d578b9d4ffba48c68d086ec29fe9e6f0f000.jpg",
                    "11062b_d578b9d4ffba48c68d086ec29fe9e6f0f001.jpg",
                    "11062b_d578b9d4ffba48c68d086ec29fe9e6f0f002.jpg",
                    "11062b_d578b9d4ffba48c68d086ec29fe9e6f0f003.jpg"
                  ],
                  "hasAudio": false,
                  "fps": "25/1",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_d578b9d4ffba48c68d086ec29fe9e6f0/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ],
                  "mask": {
                    "x": 0,
                    "y": 0,
                    "width": 100,
                    "height": 100,
                    "svgId": "11062b_d08c8a317bed41a48ee11d6a90ac56ad.svg",
                    "mode": "alpha"
                  }
                },
                "color": "#212121",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
              },
              "cssStyle": {},
              "themeMappings": {},
              "charas": "design-kn60b10b"
            },
            "style": {
              "type": "ComponentStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "nzg-680",
                "pageId": "rs06z"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kr81fv02"
          },
          "preset": {
            "rect": {
              "width": 152,
              "height": 210,
              "x": 172,
              "y": 464
            },
            "mediaManager": {
              "path": "public/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1"
            }
          }
        },
        {
          "id": "VideoBox_VideoBoxMasked_6",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "nzg-830",
              "pageId": "rs06z"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "2k1-157",
                  "pageId": "rs06z"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 99,
                  "height": 98,
                  "x": 92,
                  "y": 74,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kr81fv09",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "nzg-720",
                    "autoGenerated": false,
                    "pageId": "rs06z"
                  },
                  "playerId": "comp-kr81fv09"
                },
                "mobileHints": {
                  "type": "MobileHints",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-813",
                    "pageId": "rs06z"
                  },
                  "author": "studio",
                  "offsetX": 79,
                  "offsetY": 64,
                  "offsetOrigin": "leftTop",
                  "recommendedScale": 1,
                  "recommendedWidth": 85,
                  "recommendedHeight": 84,
                  "orderIndex": 0
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "xae-728",
                    "pageId": "rs06z"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "xae-627",
                      "pageId": "rs06z"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "xae-729",
                        "pageId": "rs06z"
                      },
                      "name": "playButton",
                      "svgId": "0da768_88bec97e49f946ddae2d0684fb5f830b.svg"
                    }
                  ]
                },
                "style": {
                  "type": "ComponentStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-717",
                    "pageId": "rs06z"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "custom",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "nzg-848",
                  "pageId": "rs06z"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 232,
                  "y": 203,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kr81fv09",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "nzg-731",
                    "autoGenerated": false,
                    "pageId": "rs06z"
                  },
                  "showStoryboard": "time",
                  "playerId": "comp-kr81fv09"
                },
                "mobileHints": {
                  "type": "MobileHints",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-815",
                    "pageId": "rs06z"
                  },
                  "author": "studio",
                  "offsetX": 114,
                  "offsetY": 27,
                  "offsetOrigin": "leftBottom",
                  "recommendedScale": 1,
                  "recommendedWidth": 51,
                  "recommendedHeight": 38,
                  "orderIndex": 1
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-722",
                    "pageId": "rs06z"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "nzg-727",
                      "pageId": "rs06z"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-726",
                        "pageId": "rs06z"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_9c02fee4517e40e1bfe9581b4cfa3750.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-725",
                        "pageId": "rs06z"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-723",
                        "pageId": "rs06z"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "nzg-724",
                          "pageId": "rs06z"
                        },
                        "overrideColors": {
                          "color1": "#ACAEAF"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "ComponentStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-728",
                    "pageId": "rs06z"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "disabledcolor": "#8d8d8d",
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d"
                    },
                    "propertiesSource": {
                      "disabledcolor": "value",
                      "maincolor": "value",
                      "textcolor": "value"
                    }
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "custom",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
              "width": 283,
              "height": 247,
              "x": 20,
              "y": 1425,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kr81fuwv",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "nzg-705",
                "pageId": "rs06z"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "toggle",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "mobileHints": {
              "type": "MobileHints",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "dau-135",
                "pageId": "rs06z"
              },
              "author": "studio",
              "recommendedScale": 1,
              "recommendedWidth": 244,
              "recommendedHeight": 213
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "2k1-153",
                "pageId": "rs06z"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "2k1-154",
                  "pageId": "rs06z"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "2k1-155",
                    "pageId": "rs06z"
                  },
                  "title": "Sand Dunes",
                  "videoId": "11062b_d6c2bcf6fd5146f2afc7cd492d424e5d",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 854,
                      "height": 480,
                      "url": "video/11062b_d6c2bcf6fd5146f2afc7cd492d424e5d/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 1280,
                      "height": 720,
                      "url": "video/11062b_d6c2bcf6fd5146f2afc7cd492d424e5d/720p/mp4/file.mp4"
                    },
                    {
                      "quality": "1080p",
                      "width": 1920,
                      "height": 1080,
                      "url": "video/11062b_d6c2bcf6fd5146f2afc7cd492d424e5d/1080p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "2k1-156",
                      "pageId": "rs06z"
                    },
                    "title": "",
                    "uri": "11062b_d6c2bcf6fd5146f2afc7cd492d424e5df000.jpg",
                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1/all/any/all",
                    "width": 1920,
                    "height": 1080,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 40,
                  "preload": "auto",
                  "artist": {
                    "id": "",
                    "name": ""
                  },
                  "generatedPosters": [
                    "11062b_d6c2bcf6fd5146f2afc7cd492d424e5df000.jpg",
                    "11062b_d6c2bcf6fd5146f2afc7cd492d424e5df001.jpg",
                    "11062b_d6c2bcf6fd5146f2afc7cd492d424e5df002.jpg",
                    "11062b_d6c2bcf6fd5146f2afc7cd492d424e5df003.jpg"
                  ],
                  "hasAudio": false,
                  "fps": "25/1",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_d6c2bcf6fd5146f2afc7cd492d424e5d/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ],
                  "mask": {
                    "x": 0,
                    "y": 0,
                    "width": 100,
                    "height": 100,
                    "svgId": "920d428e12a94009ba057bba0b836932.svg",
                    "mode": "alpha"
                  }
                },
                "color": "#212121",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
              },
              "cssStyle": {},
              "themeMappings": {},
              "charas": "design-kocy1qe6"
            },
            "style": {
              "type": "ComponentStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "nzg-710",
                "pageId": "rs06z"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kr81fv09"
          },
          "preset": {
            "rect": {
              "width": 162,
              "height": 146,
              "x": 0,
              "y": 674
            },
            "mediaManager": {
              "path": "public/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1"
            }
          }
        },
        {
          "id": "VideoBox_VideoBoxMasked_7",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "dau-152",
              "pageId": "rs06z"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-766",
                  "pageId": "rs06z"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 88,
                  "height": 88,
                  "x": 96,
                  "y": 79,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kr81fv0h",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "nzg-750",
                    "autoGenerated": false,
                    "pageId": "rs06z"
                  },
                  "playerId": "comp-kr81fv0h"
                },
                "mobileHints": {
                  "type": "MobileHints",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-817",
                    "pageId": "rs06z"
                  },
                  "author": "studio",
                  "offsetX": 86,
                  "offsetY": 72,
                  "offsetOrigin": "leftTop",
                  "recommendedScale": 1,
                  "recommendedWidth": 66,
                  "recommendedHeight": 66,
                  "orderIndex": 0
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "xae-754",
                    "pageId": "rs06z"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "xae-657",
                      "pageId": "rs06z"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "xae-755",
                        "pageId": "rs06z"
                      },
                      "name": "playButton",
                      "svgId": "0da768_152a8490c7444b949a51e30f2ac4d9a4.svg"
                    }
                  ]
                },
                "style": {
                  "type": "ComponentStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-747",
                    "pageId": "rs06z"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "custom",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "nzg-850",
                  "pageId": "rs06z"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 229,
                  "y": 203,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kr81fv0h",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "nzg-761",
                    "autoGenerated": false,
                    "pageId": "rs06z"
                  },
                  "showStoryboard": "time",
                  "playerId": "comp-kr81fv0h"
                },
                "mobileHints": {
                  "type": "MobileHints",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-819",
                    "pageId": "rs06z"
                  },
                  "author": "studio",
                  "offsetX": 102,
                  "offsetY": 35,
                  "offsetOrigin": "leftBottom",
                  "recommendedScale": 1,
                  "recommendedWidth": 51,
                  "recommendedHeight": 38,
                  "orderIndex": 1
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-752",
                    "pageId": "rs06z"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "nzg-757",
                      "pageId": "rs06z"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-756",
                        "pageId": "rs06z"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_9c02fee4517e40e1bfe9581b4cfa3750.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-755",
                        "pageId": "rs06z"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "nzg-753",
                        "pageId": "rs06z"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "nzg-754",
                          "pageId": "rs06z"
                        },
                        "overrideColors": {
                          "color1": "#ACAEAF"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "ComponentStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "nzg-758",
                    "pageId": "rs06z"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "disabledcolor": "#8d8d8d",
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d"
                    },
                    "propertiesSource": {
                      "disabledcolor": "value",
                      "maincolor": "value",
                      "textcolor": "value"
                    }
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "custom",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
              "width": 280,
              "height": 247,
              "x": 363,
              "y": 1425,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kr81fuwv",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "nzg-735",
                "pageId": "rs06z"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "toggle",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "mobileHints": {
              "type": "MobileHints",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "dau-137",
                "pageId": "rs06z"
              },
              "author": "studio",
              "recommendedScale": 1,
              "recommendedWidth": 239,
              "recommendedHeight": 211
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "2k1-164",
                "pageId": "rs06z"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "2k1-165",
                  "pageId": "rs06z"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "2k1-166",
                    "pageId": "rs06z"
                  },
                  "title": "Pool",
                  "videoId": "11062b_f4ce85282b784b75a9aae8628fea4414",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 854,
                      "height": 480,
                      "url": "video/11062b_f4ce85282b784b75a9aae8628fea4414/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 1280,
                      "height": 720,
                      "url": "video/11062b_f4ce85282b784b75a9aae8628fea4414/720p/mp4/file.mp4"
                    },
                    {
                      "quality": "1080p",
                      "width": 1920,
                      "height": 1080,
                      "url": "video/11062b_f4ce85282b784b75a9aae8628fea4414/1080p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "2k1-167",
                      "pageId": "rs06z"
                    },
                    "title": "",
                    "uri": "11062b_f4ce85282b784b75a9aae8628fea4414f000.jpg",
                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/3892dca7-115f-4b62-ba8d-11a7b220ab76/all/any/all",
                    "width": 1920,
                    "height": 1080,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 3.07,
                  "preload": "auto",
                  "artist": {
                    "id": "",
                    "name": ""
                  },
                  "generatedPosters": [
                    "11062b_f4ce85282b784b75a9aae8628fea4414f000.jpg",
                    "11062b_f4ce85282b784b75a9aae8628fea4414f001.jpg",
                    "11062b_f4ce85282b784b75a9aae8628fea4414f002.jpg",
                    "11062b_f4ce85282b784b75a9aae8628fea4414f003.jpg"
                  ],
                  "hasAudio": true,
                  "fps": "30/1",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_f4ce85282b784b75a9aae8628fea4414/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ],
                  "mask": {
                    "x": 0,
                    "y": 0,
                    "width": 100,
                    "height": 100,
                    "svgId": "11062b_9dd6c4ee165047b7be8c9b7c0f6a7214.svg",
                    "mode": "alpha"
                  }
                },
                "color": "#212121",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
              },
              "cssStyle": {},
              "themeMappings": {},
              "charas": "design-kn33edon"
            },
            "style": {
              "type": "ComponentStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "nzg-740",
                "pageId": "rs06z"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kr81fv0h"
          },
          "preset": {
            "rect": {
              "width": 162,
              "height": 146,
              "x": 162,
              "y": 674
            },
            "mediaManager": {
              "path": "public/8e256233-1752-4026-9341-54036e7f875e/3892dca7-115f-4b62-ba8d-11a7b220ab76"
            }
          }
        }
      ],
      "compTypes": [
        "wysiwyg.viewer.components.MediaPlayer"
      ]
    },
    "subNavigationTitle": "add_section_media_player_masked_label",
    "presetTitle": "add_section_media_player_masked_label",
    "tooltipTitle": "add_section_media_player_masked_label",
    "subNavigationHide": false,
    "help": {
      "hide": false,
      "text": "add_section_media_player_masked_label_tooltip_title"
    }
  }
