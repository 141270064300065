'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const moreBehaviorHelper = require("./moreBehaviorHelper")
module.exports =
    {
        "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
        "hide": false,
        "title": "add_section_vectorart_featured_label",
        "subNavigationTitle": "add_section_vectorart_featured_label",
        "presetTitle": "add_section_vectorart_featured_label",
        "tooltipTitle": "add_section_vectorart_featured_label",
        "automationId": "add-panel-section-featuredSection",
        "subNavigationHide": false,
        "showSectionHeader": true,
        "additionalBehaviours": {
            "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
            "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
            "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
            "iconEnabledForComps": {}
        },
        "props": {
            "image": "addPanelData/sections/vectorArt/featuredSection.v1.png",
            "retinaImage": {
                "src": "addPanelData/sections/vectorArt/featuredSection-@2x.v1.png",
                "dimensions": {
                    "width": 648,
                    "height": 1364
                }
            },
            "imageHover": null,
            "items": [{
                "id": "VectorArt_Featured_1",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 114,
                        "height": 334,
                        "x": 10,
                        "y": 19,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "0740dd29f3974d4c9178b8d39f5940cb.svg",
                        "title": "Add_Panel_media-3-07",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w44",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rmza"
                },
                "preset": {
                    "rect": {"width": 58, "height": 167, "x": 10, "y": 19},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "7, 67, 23, 19, 47, 7, 67, 21, 66, 69, 81, 115, 69, 127, 74, 164, 60, 170, 58, 189, 20, 194, 19, 172, 6, 167, 2, 107"
                    }
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/0b353409-784f-433f-99c3-d5a056542771"}
            }, {
                "id": "VectorArt_Featured_2",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 268,
                        "height": 92,
                        "x": 179,
                        "y": 23,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "4431a8dffbcc4169b10bf364fee0b7f2.svg",
                        "title": "Fox",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w4b",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rmzk"
                },
                "preset": {
                    "rect": {"width": 134, "height": 47, "x": 179, "y": 25},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "173, 24, 318, 79"}
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/a17ccc1a-f162-409c-b0d4-15086792d99e"}
            }, {
                "id": "VectorArt_Featured_3",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 154,
                        "height": 122,
                        "x": 82,
                        "y": 28,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "1a1e41bd6dd04ee68bf9ebb24c9c7250.svg",
                        "title": "media-57-08",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w47",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rmzc"
                },
                "preset": {
                    "rect": {"width": 77, "height": 62, "x": 82, "y": 30},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "118, 101, 89, 87, 77, 80, 79, 30, 127, 14, 168, 48, 166, 81"}
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/6749e76d-af9a-4501-8d15-e9fe8e4b2546"}
            }, {
                "id": "VectorArt_Featured_4",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 86,
                        "height": 214,
                        "x": 105,
                        "y": 86,
                        "scale": 1,
                        "rotationInDegrees": 202.89179441273427,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "ac79b1c943d14b1f94bf8a06a8659f17.svg",
                        "title": "media-88-05",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w4i",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rmzt"
                },
                "preset": {
                    "rect": {"width": 83, "height": 117, "x": 86, "y": 82},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "77, 188, 95, 123, 139, 112, 162, 89, 182, 99, 136, 178, 108, 192"
                    }
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/a17ccc1a-f162-409c-b0d4-15086792d99e"}
            }, {
                "id": "VectorArt_Featured_5",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 148,
                        "height": 260,
                        "x": 170,
                        "y": 94,
                        "scale": 1,
                        "rotationInDegrees": 7.627834539629475,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "05e320b675324ac2bbcfca7d8180a701.svg",
                        "title": "Letter J",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w4f",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rmzi"
                },
                "preset": {
                    "rect": {"width": 94, "height": 142, "x": 160, "y": 88},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "205, 86, 265, 92, 228, 203, 239, 222, 186, 237, 148, 201, 159, 188, 158, 157, 179, 129, 190, 89"
                    }
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/7b13106f-d0ec-47d2-a065-c8abb201a9b0"}
            }, {
                "id": "VectorArt_Featured_6",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 174,
                        "height": 129,
                        "x": 253,
                        "y": 118,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "aad2a01d11fa4e4189322b274555ace9.svg",
                        "title": "media-30-05",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w4m",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rmzq"
                },
                "preset": {
                    "rect": {"width": 60, "height": 44, "x": 252, "y": 118},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "284, 95, 316, 146, 325, 171, 243, 172, 248, 154"}
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/8aac0e8e-7b25-42d8-a3f1-058d0b449757"}
            }, {
                "id": "VectorArt_Featured_7",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 104,
                        "height": 154,
                        "x": 263,
                        "y": 199,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "9f66a05416b3492b94f3c2e1ba9ec662.svg",
                        "title": "media-89-02",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w4q",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rmzx"
                },
                "preset": {
                    "rect": {"width": 52, "height": 77, "x": 263, "y": 199},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "242, 193, 277, 291, 324, 290, 322, 191"}
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/a17ccc1a-f162-409c-b0d4-15086792d99e"}
            }, {
                "id": "VectorArt_Featured_8",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 198,
                        "height": 242.39999999999998,
                        "x": 10,
                        "y": 203,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "3943e2a044854dfbae0fbe56ec72c7d9.svg",
                        "title": "Tiger",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w52",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rmz4"
                },
                "preset": {
                    "rect": {"width": 166, "height": 202, "x": 10, "y": 203},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "15, 263, 1, 235, 4, 205, 57, 195, 84, 206, 77, 228, 59, 234, 86, 245, 112, 290, 126, 316, 197, 379, 170, 416, 147, 410, 112, 405, 98, 412, 43, 374, 31, 355, 10, 341, 6, 292"
                    }
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/7e60138c-95de-4778-871c-2f39d6a4f967"}
            }, {
                "id": "VectorArt_Featured_9",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 122,
                        "height": 120,
                        "x": 105,
                        "y": 216,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "1560da69dc2141a1b232ec2cdf5abf6d.svg",
                        "title": "media-16-14",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w4z",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rmzn"
                },
                "preset": {
                    "rect": {"width": 62, "height": 61, "x": 105, "y": 218},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "126, 284, 142, 295, 175, 288, 166, 231, 136, 201, 108, 225, 94, 230, 97, 266"
                    }
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/ffb0b503-c871-4b4f-8081-b198245d3c78"}
            }, {
                "id": "VectorArt_Featured_10",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 140.4,
                        "height": 148.79999999999998,
                        "x": 185,
                        "y": 233,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "9c931cc37e7d418289278be2eb5ae14e.svg",
                        "title": "Ship",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w4u",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rmzf"
                },
                "preset": {
                    "rect": {"width": 118, "height": 125, "x": 185, "y": 232},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "196, 251, 218, 240, 226, 230, 247, 227, 284, 317, 314, 307, 313, 332, 278, 342, 270, 358, 233, 373, 194, 364, 173, 341, 182, 305, 180, 265"
                    }
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/531bf609-9695-4b56-b727-4a763dcf7b7a"}
            }, {
                "id": "VectorArt_Featured_11",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 64,
                        "height": 196,
                        "x": 282,
                        "y": 357,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "6a5c1d4bcc0942afbd027910e3433fc0.svg",
                        "title": "featured-Ice-cream",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w5d",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rn0n"
                },
                "preset": {
                    "rect": {"width": 32, "height": 98, "x": 282, "y": 357},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "271, 359, 319, 342, 320, 459, 283, 464"}
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/6dbbb664-3789-4555-b53d-e30928482ce6"}
            }, {
                "id": "VectorArt_Featured_12",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 180,
                        "height": 124,
                        "x": 182,
                        "y": 399,
                        "scale": 1,
                        "rotationInDegrees": 22.2820885678085,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "3a6d1230e7534a20a777fc1a56fb6a38.svg",
                        "title": "Lips",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w5a",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rn0i"
                },
                "preset": {
                    "rect": {"width": 108, "height": 92, "x": 173, "y": 387},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "269, 391, 279, 467, 253, 484, 228, 474, 195, 436, 168, 423, 200, 390"
                    }
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/6749e76d-af9a-4501-8d15-e9fe8e4b2546"}
            }, {
                "id": "VectorArt_Featured_13",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 200,
                        "height": 186,
                        "x": 10,
                        "y": 389,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "3403927e2b7942efbcda4cd8453ab1a6.svg",
                        "title": "Leaves ",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w56",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rn0b"
                },
                "preset": {
                    "rect": {"width": 100, "height": 94, "x": 10, "y": 390},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "98, 459, 115, 453, 115, 425, 48, 390, 0, 380, 9, 459, 45, 494, 63, 480, 89, 488"
                    }
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/a17ccc1a-f162-409c-b0d4-15086792d99e"}
            }, {
                "id": "VectorArt_Featured_14",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 129.6,
                        "height": 144,
                        "x": 90,
                        "y": 427,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "4548ea920e2d47dabc7b55e513f4ab8d.svg",
                        "title": "media-40-07",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w3z",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rn0f"
                },
                "preset": {
                    "rect": {"width": 108, "height": 120, "x": 90, "y": 427},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "135, 414, 211, 457, 211, 521, 149, 560, 76, 507, 119, 473, 120, 417"
                    }
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/8248fb92-3ca8-42f8-a5cb-8713ced427c8"}
            }, {
                "id": "VectorArt_Featured_15",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 111,
                        "height": 150,
                        "x": 231,
                        "y": 481,
                        "scale": 1,
                        "rotationInDegrees": 95,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "76ce09ecf6d64014bddd48380fd1a6a2.svg",
                        "title": "featured-gem",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w3r",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j9fk9rmb"
                },
                "preset": {
                    "rect": {"width": 58, "height": 45, "x": 222, "y": 482},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "239, 488, 220, 506, 220, 530, 238, 532, 269, 514, 286, 483, 265, 482"
                    }
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/20df9cf2-9b45-48b4-86b2-85c4ab16d2e9"}
            }, {
                "id": "VectorArt_Featured_16",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 206,
                        "height": 186,
                        "x": 5,
                        "y": 508,
                        "scale": 1,
                        "rotationInDegrees": 78.98026808530807,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "ef9c8862f4044290a5171a74c048c5e0.svg",
                        "title": "media-33-08",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w3v",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rn07"
                },
                "preset": {
                    "rect": {"width": 114, "height": 122, "x": 0, "y": 497},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "27, 503, 6, 547, 24, 599, 46, 610, 89, 610, 117, 553, 58, 500"}
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/6dbbb664-3789-4555-b53d-e30928482ce6"}
            }, {
                "id": "VectorArt_Featured_17",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 240,
                        "height": 180,
                        "x": 114,
                        "y": 511,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "2b8532de9bc440c098d71c81396421d0.svg",
                        "title": "media-85-03",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w5g",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rn04"
                },
                "preset": {
                    "rect": {"width": 200, "height": 150, "x": 114, "y": 513},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "317, 501, 282, 493, 263, 530, 244, 539, 200, 544, 147, 560, 112, 605, 119, 678, 213, 678, 213, 619, 248, 679, 304, 670, 320, 657, 322, 586, 292, 570, 318, 543"
                    }
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/8f648b47-ff8f-404e-b462-508f1571e31f"}
            }, {
                "id": "VectorArt_Featured_18",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 228,
                        "height": 117,
                        "x": 19,
                        "y": 622,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "d728f9ae96c64d15819f6bbd6a2559bf.svg",
                        "title": "media-30-15",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w5j",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6rn00"
                },
                "preset": {
                    "rect": {"width": 76, "height": 39, "x": 19, "y": 624},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "15, 615, 98, 617, 98, 665, 13, 667"}
                },
                "mediaManager": {"path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/7e60138c-95de-4778-871c-2f39d6a4f967"}
            }],
            "compTypes": ["wysiwyg.viewer.components.VectorImage"]
        },
        "sectionFooter": {
            "styleOverride": {"paddingTop": "25px"},
            "label": "add_section_vectorart_featured_more_tooltip_title",
            "action": moreBehaviorHelper,
            "category": "VECTOR_ART",
            "openSource": "add_panel_vector_art_all",
            "path": "public/3980fbc8-b1b0-4ebd-9bda-2c0578ca9462/d9b8c13b-6067-4103-bdb7-cbe035b07711"
        },
        "help": {"hide": false, "text": "add_section_vectorart_featured_tooltip_description"}
    }
