import type { ScrollPosition } from 'types/core';
import type {
  CompRef,
  Point,
  CompStructure,
  CompLayout,
} from 'types/documentServices';

export interface ComponentData {
  compRef: CompRef;
  isShowOnAllPages: boolean;
  positionRelativeToSnug: Point;
}

export interface SavedComponentSerializedData {
  components: CompStructure[];
  componentsData: {
    [key: string]: ComponentData;
  };
  originalScrollPosition: ScrollPosition;
  snugLayoutRelativeToStructure: CompLayout;
  theme: AnyFixMe;
}

interface SavedComponentMetaData {
  guid: string;
  copiedOnMobile: boolean;
  metaSiteId: string;
  siteUploadToken: string;
}

export interface SavedComponentPreset {
  metaData: SavedComponentMetaData;
  serializedData: SavedComponentSerializedData;
}
