import { translate } from '@/i18n';

export default {
  title: translate('Help_Widget_Title'),
  articleSeo: translate('Help_Widget_Article_Get_Found_SEO'),
  subTitle: translate('Help_Widget_Subtitle'),
  articleVelo: translate('Help_Widget_Article_Velo_Learn_More'),
  articleDevices: translate('Help_Widget_Article_Compatible_Mobile_Devices'),
  articleBg: translate('Help_Widget_Article_Change_Mobile_Background'),
  articleBasic: translate('Help_Widget_Article_Add_Elements'),
  articleVisitVelo: translate('Help_Widget_Article_Velo_Resources_Support'),
  articleMobile1: translate('Help_Widget_Article_Make_Mobile_Site'),
  articleMobile2: translate('Help_Widget_Article_Enable_Mobile_Friendly'),
  articleConnect: translate('Help_Widget_Article_Connect_Domain'),
  articleSave: translate('Help_Widget_Article_Save_Preview_Publish'),
  articleHideOnMobile: translate('Help_Widget_Article_Hide_Element_Mobile'),
};
