//eslint-disable-next-line @wix/santa-editor/scoped-imports
import {
  type ForumWelcomeDispatchProps,
  mapDispatchToForumWelcomeSectionProps,
} from '@/addPanelData/mappers';
import { wrapWithPropsTransformer } from '../utils';

export interface ForumWelcomeProps {
  forumMainAction: () => void;
}

export const forumWelcomeMapper = wrapWithPropsTransformer<
  ForumWelcomeDispatchProps,
  ForumWelcomeProps
>(mapDispatchToForumWelcomeSectionProps, (props) => ({
  forumMainAction: props.installForumApp,
}));
