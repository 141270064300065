import React from 'react';
import { translate } from '@/i18n';
import * as baseUI from '@/baseUI';
import { symbol as SymbolComponent } from '@wix/santa-editor-symbols';

interface ISuperAppTooltip {
  title: string;
  text: string;
  label: string;
  image: string;
  onClick: (e: unknown) => void;
}

const SuperAppTooltip = (props: ISuperAppTooltip) => {
  return (
    <div className="superAppTooltip">
      <div className="left">
        {!!props.title && (
          <h3 className="title" key="title">
            {translate(props.title)}
          </h3>
        )}
        {!!props.text && (
          <p className="content" key="content">
            {translate(props.text)}
          </p>
        )}
        <baseUI.button
          className="button"
          label={props.label}
          onClick={props.onClick}
        />
      </div>
      <SymbolComponent name={props.image} />
    </div>
  );
};

export default SuperAppTooltip;
