// @ts-nocheck
import React from 'react';
import { translate } from '@/i18n';

export default class extends React.Component {
  static displayName = 'tpaProductPageSeo';

  render() {
    return (
      <div className="seo-google tab-inner">
        <p className="title">{translate('Pages_Store_Product_SEO_Title')}</p>

        <div className="google-preview">
          <p className="google-preview-title">
            <span className="google-preview-title-site-name">
              {`${this.props.siteName} | `}
            </span>
            <span>{translate('Pages_Store_Product_SEO_Preview')}</span>
          </p>
          <p className="google-preview-url">
            {this.props.publicUrl.replace(/#!.*/, '#!product-title')}
          </p>
          <p className="google-preview-description">
            {translate('Pages_Store_Product_SEO_Placeholder')}
          </p>
        </div>
      </div>
    );
  }
}
