// @ts-nocheck
import gfppDataUtils from '../../utils/gfppDataUtils';
import constants from '../../utils/gfppConsts';

const { ACTIONS } = constants;

const settingsPanel = {
  label: 'gfpp_mainaction_contactform',
  isApplied: gfppDataUtils.getDataDefinedFn('toEmailAddress'),
  isSelected: gfppDataUtils.getPanelStateFn(constants.PANELS.SETTINGS),
  onClick: gfppDataUtils.getTogglePanelFn(
    constants.PANELS.SETTINGS,
    'toEmailAddress',
  ),
};

export default {
  mainActions: [settingsPanel],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.DESIGN,
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId: '3bf1c6fd-b3e5-47f3-9ab4-22beb2a1ce4c',
    },
  },
};
