import designPanelFactory from './core/designPanelFactory';
import changeStyleDesignPanel from './panelTypes/changeStyleDesignPanel';
import stylablePanel from './panelTypes/stylablePanel/stylablePanel';
import customDesignPanel from './core/customDesignPanel';

export {
  customDesignPanel,
  designPanelFactory as designPanel,
  changeStyleDesignPanel,
  stylablePanel,
};
