import experiment from 'experiment';
import * as stateManagement from '@/stateManagement';
import { SiteGlobalDataApiKey } from '@/apis';
import { fedopsLogger } from '@/util';
import constants from '@/constants';
import { utils as coreUtils } from '@/core';
import { PANEL_NAME } from './constants';
import { INJECTION_MASTER_PAGE_WHITE_LIST } from '../../injectionMasterPageWhiteList';

import type {
  Dispatch,
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type {
  DispatchProps,
  ErrorData,
  OnInjectionSuccessFnParams,
  QuestionnaireParams,
  StateProps,
  SuccessPageInjectionResponse,
  SuccessSiteInjectionResponse,
} from './types';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import type { EditorState } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import { HttpClient, type HttpResponse } from '@wix/http-client';
import {
  replaceImagesInTemplates,
  replaceImagesWithFunnelSuggestions,
} from './welcomePanelHelpers';
import {
  isApplicable,
  getSiteDescription,
  getContext,
} from '@wix/ambassador-genie-v1-compose/http';
import type {
  IsApplicableResponse,
  GetSiteDescriptionResponse,
  GetContextResponse,
} from '@wix/ambassador-genie-v1-compose/types';
import { ErrorReporter } from '@wix/editor-error-reporter';

const setSourceData = (editorAPI: EditorAPI, ids: string[]) => {
  ids.forEach((id: string) => {
    coreUtils.componentSourceFeatureUtils.updateComponentSource(editorAPI, {
      compRef: editorAPI.components.get.byId(id),
      source: 'ai',
    });
  });
};

const mapStateToProps: MapStateToProps<StateProps> = ({ editorAPI }) => {
  const siteGlobalDataApi = editorAPI.host.getAPI(SiteGlobalDataApiKey);
  const businessType = siteGlobalDataApi.getBusinessType();
  const businessName = siteGlobalDataApi.getBusinessName() ?? '';

  return {
    businessType,
    businessName,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  onClose: (origin?: string) => (dispatch: Dispatch) => {
    dispatch(
      stateManagement.panels.actions.closePanelByName(PANEL_NAME, origin),
    );
  },
  onSubmit:
    (
      params: QuestionnaireParams,
      isAutosubmitFlow: boolean,
      onSuccess: ({
        requestParams,
        responseParams,
        requestStartTime,
        isAutosubmitFlow,
      }: OnInjectionSuccessFnParams) => void,
      onFailure: (response: ErrorData) => void,
    ): ThunkAction =>
    (dispatch, getState, { editorAPI, host }) => {
      const requestStartTime: number = Date.now();
      const pageId = editorAPI.pages.getCurrentPageId();
      const templateId = editorAPI.generalInfo.getSiteOriginalTemplateId();

      dispatch(stateManagement.ai.actions.setIsAiTermsAndConditionsShown(true));

      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.AI_TEXT_CREATOR.SITE_INJECTION,
      );

      const excludeMasterPage =
        !experiment.isOpen('se_ignoreInjectionMasterPageWhiteList') &&
        !INJECTION_MASTER_PAGE_WHITE_LIST.has(templateId);

      const isFunnelImagesEnabled = experiment.isOpen('se_funnelImagesPOC');
      const isSuggestPhotosEnabled = experiment.isOpen(
        'se_suggestPhotosInTemplates',
      );

      if (experiment.isOpen('se_aiInjectionIntoTemplateAllPages')) {
        // @ts-expect-error
        editorAPI.ai.content.updateSiteContent(
          params.businessType?.displayName,
          params.businessName,
          params.businessDescription,
          async (response: SuccessSiteInjectionResponse) => {
            fedopsLogger.interactionEnded(
              fedopsLogger.INTERACTIONS.AI_TEXT_CREATOR.SITE_INJECTION,
            );

            if (isSuggestPhotosEnabled) {
              await dispatch(() => replaceImagesInTemplates(editorAPI));
            }

            onSuccess({
              requestParams: params,
              responseParams: response,
              requestStartTime,
              isAutosubmitFlow,
            });

            if (isFunnelImagesEnabled) {
              replaceImagesWithFunnelSuggestions(editorAPI);
            }

            if (experiment.isOpen('se_siteCompletionSourceData')) {
              const changedComponentsIds = response.reduce(
                (accumulator, currentValue) => {
                  accumulator.push(
                    ...Object.values(currentValue?.pageSuggestion?.idMap ?? {}),
                  );

                  return accumulator;
                },
                [],
              );

              setSourceData(editorAPI, changedComponentsIds);
            }
          },
          onFailure,
          { excludeMasterPage },
        );
      } else {
        // @ts-expect-error
        editorAPI.ai.content.updatePageContent(
          pageId,
          params.businessType?.displayName,
          params.businessName,
          params.businessDescription,
          async (response: SuccessPageInjectionResponse) => {
            fedopsLogger.interactionEnded(
              fedopsLogger.INTERACTIONS.AI_TEXT_CREATOR.SITE_INJECTION,
            );

            if (isSuggestPhotosEnabled) {
              await dispatch(() => replaceImagesInTemplates(editorAPI));
            }

            onSuccess({
              requestParams: params,
              responseParams: [
                {
                  pageId,
                  pageSuggestion: response.suggestionResults,
                },
              ],
              requestStartTime,
              isAutosubmitFlow,
            });

            if (isFunnelImagesEnabled) {
              replaceImagesWithFunnelSuggestions(editorAPI);
            }

            if (experiment.isOpen('se_siteCompletionSourceData')) {
              const changedComponentsIds: string[] = Object.values(
                response.suggestionResults.idMap,
              );

              setSourceData(editorAPI, changedComponentsIds);
            }
          },
          onFailure,
          { excludeMasterPage },
        );
      }

      const siteGlobalDataAPI = host.getAPI(SiteGlobalDataApiKey);
      siteGlobalDataAPI.setBusinessType(params.businessType);
    },
  highlightLeftBarMenu: () => (dispatch: Dispatch) => {
    dispatch(stateManagement.leftBar.actions.highlightMenu());
  },
  unHighlightLeftBarMenu: () => (dispatch: Dispatch) => {
    dispatch(stateManagement.leftBar.actions.unhighlightMenu());
  },
  sendBI:
    (event: BiEventDefinition, params: BiEventFields = {}) =>
    (dispatch: Dispatch) => {
      dispatch(stateManagement.bi.actions.event(event, params));
    },
  getParentId:
    (id: string) =>
    (
      dispatch: Dispatch,
      getState: () => EditorState,
      { editorAPI }: { editorAPI: EditorAPI },
    ) =>
      editorAPI.components.getContainer(editorAPI.components.get.byId(id))?.id,
  getAdditionalBIData:
    (componentId: string) =>
    (
      dispatch: Dispatch,
      getState: () => EditorState,
      { editorAPI }: { editorAPI: EditorAPI },
    ) => {
      //TODO: investigate sentry reports, why componentRef is null
      try {
        const componentRef = editorAPI.components.get.byId(componentId);
        const pageId = editorAPI.components.getPage(componentRef).id;
        const sectionType =
          editorAPI.sections.getClosestSection(componentRef)?.type || 'null';

        return {
          componentType: componentRef.type,
          pageId,
          parentComponentType:
            editorAPI.components.getContainer(componentRef)?.type,
          sectionType,
        };
      } catch (e) {
        ErrorReporter.captureException(e, {
          tags: { aiWelcomePanelMapperBiAdditionalData: true },
          extra: {
            componentId,
          },
        });
      }
    },
  isFunnelDataApplicable:
    (onSuccess: (result: boolean) => void): ThunkAction =>
    async (_dispatch, _getState, { editorAPI }) => {
      const httpClient = new HttpClient({
        getAppToken: () =>
          editorAPI.documentServices.platform.getAppDataByApplicationId('-666')
            ?.instance,
      });

      const responce: HttpResponse<IsApplicableResponse> =
        await httpClient.request(isApplicable({}));
      const result: boolean = responce.data.applicable;

      onSuccess(result);
    },
  getDescriptionFromFunnel:
    (onSuccess: (result: string) => void): ThunkAction =>
    async (_dispatch, _getState, { editorAPI }) => {
      const httpClient = new HttpClient({
        getAppToken: () =>
          editorAPI.documentServices.platform.getAppDataByApplicationId(
            constants.APPLICATIONS.META_SITE_APPLICATION_ID,
          )?.instance,
      });

      if (experiment.isOpen('se_useFunnelSiteDescription')) {
        const responce: HttpResponse<GetSiteDescriptionResponse> =
          await httpClient.request(getSiteDescription({}));

        onSuccess(responce.data.siteDescription.value);
        return;
      }

      const responce: HttpResponse<GetContextResponse> =
        await httpClient.request(getContext({}));

      onSuccess(responce.data.context.value);
    },
};

const mergeProps = (
  { businessType, businessName }: StateProps,
  dispatchProps: DispatchProps,
  ownProps: {},
) => {
  const { onSubmit, getDescriptionFromFunnel, ...restDispatchProps } =
    dispatchProps;

  const autoSubmitForm = async (
    onSuccess: (params: OnInjectionSuccessFnParams) => void,
    onFailure: (response: ErrorData) => void,
  ) => {
    const businessDescription = await new Promise<string>((resolve) => {
      getDescriptionFromFunnel(resolve);
    });
    const isAutosubmitFlow = true;

    return onSubmit(
      {
        businessName,
        businessType,
        businessDescription,
      },
      isAutosubmitFlow,
      onSuccess,
      onFailure,
    );
  };

  return {
    ...ownProps,
    ...restDispatchProps,
    onSubmit,
    autoSubmitForm,
  };
};

export { mapStateToProps, mapDispatchToProps, mergeProps };
