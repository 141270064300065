module.exports = {
  id: "Stylable_StylableButton_7",
  preset: { rect: { width: 66, height: 76, x: 192, y: 220 }, tags: null },
  structure: {
    type: "Component",
    metaData: { sig: "4ie-510", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
    layout: {
      width: 45,
      height: 45,
      x: 202,
      y: 236,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: "wixui.StylableButton",
    parent: "comp-knzvqsjj",
    data: {
      type: "StylableButton",
      metaData: {
        isPreset: false,
        schemaVersion: "1.0",
        isHidden: false,
        sig: "q4x-303",
        pageId: "c1dmp",
      },
      label: "Location",
      svgId: "11062b_85cba89d33e448e9a8dc168a54135ef0.svg",
    },
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "b80-380", pageId: "c1dmp" },
    },
    style: {
      type: "ComponentStyle",
      metaData: {
        isPreset: false,
        schemaVersion: "1.0",
        isHidden: false,
        sig: "4ie-513",
        pageId: "c1dmp",
      },
      style: {
        properties: {
          "$st-css":
            ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 7px;background: #EFEFF3;border: 0px solid rgb(239, 239, 243)}.root:hover {\n    background: #D2D2D3;\n}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #000000;display: none}.root::icon{transition: inherit;fill: #C74300;width: 31px;height: 31px}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      componentClassName: "wixui.StylableButton",
      pageId: "",
      compId: "",
      styleType: "custom",
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    id: "comp-km90qrh1",
    components: [],
  },
};
