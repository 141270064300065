import type { Page } from './types';
import type { Scope } from './scope';

export interface PageRedirect {
  prev: string;
  next: string;
}

export function storePagesData(scope: Scope, pages: Page[]): void {
  const { store } = scope;
  store.setPagesData(pages);
}

export function getPagesData(scope: Scope): Page[] {
  const { store } = scope;
  return store.getPagesData();
}
