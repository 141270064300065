type PanelContent = {
  [key in 'custom' | 'custom_pro']: {
    title: string;
    items: string[];
    helpId: string;
  };
};

export const PANEL_CONTENT: PanelContent = {
  custom: {
    title: 'custom_menu_migration_main_title',
    items: [
      'custom_menu_migration_main_text_line_1',
      'custom_menu_migration_main_text_line_2',
      'custom_menu_migration_main_text_line_3',
    ],
    helpId: 'afb2c0ad-1282-4b17-9071-6bbdc9570d95',
  },
  custom_pro: {
    title: 'custom_menu_migration_advanced_title',
    items: [
      'custom_menu_migration_advanced_text_line_1',
      'custom_menu_migration_advanced_text_line_2',
      'custom_menu_migration_advanced_text_line_3',
    ],
    helpId: '0bca2b16-7cee-4192-9da8-906a675d5c19',
  },
};

export const PRIMARY_BUTTON_TEXT = 'custom_menu_migration_button';
export const SECONDARY_BUTTON_TEXT = 'custom_menu_migration_learn_more_link';
