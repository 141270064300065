import { isDataModeOn } from '@/wixData';
import addPanelDataConsts from '@/addPanelDataConsts';

import { openAddPanel } from '../../utils/utils';

import type { EditorAPI } from '@/editorAPI';

import type { ConditionStructure, ConditionTranslations } from '../types';

const METADATA_KEY = 'isDataModeRequired';
const TRANSLATION_KEY = 'isDataModeCondition';

export interface DataModeRequiredCondition {
  extras: {
    [METADATA_KEY]?: boolean;
  };
  translations: {
    [TRANSLATION_KEY]?: ConditionTranslations;
  };
}

export const getDataModeConditionStructure = (
  editorAPI: EditorAPI,
): ConditionStructure<
  typeof METADATA_KEY,
  typeof TRANSLATION_KEY,
  DataModeRequiredCondition
> => ({
  metadataKey: METADATA_KEY,
  translationsKey: TRANSLATION_KEY,
  check: () => !isDataModeOn(editorAPI),
  getAction: () => {
    return () => {
      openAddPanel(
        editorAPI,
        addPanelDataConsts.CATEGORIES_ID.DATABASE_WELCOME,
        'databaseWelcomeSection',
      );
    };
  },
});
