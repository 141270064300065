// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import * as utils from '@/util';
import { translate } from '@/i18n';
import * as blogManagerFrame from '../../blogManager/blogManagerFrame';
import * as blogBi from '../../utils/bi/blogBi';
import * as blogUtils from '../../utils/blogUtils';
import React from 'react';
import * as baseUI from '@/baseUI';
import { CustomScroll } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import QuickMigrationStatusPanel from '../../newAppMenu/panels/quickMigration/quickMigrationStatusPanel';
import DeleteOldBlogPanel from '../../newAppMenu/panels/quickMigration/deleteOldBlogPanel/deleteOldBlogPanel';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'BlogManagePanel',
  mixins: [core.mixins.editorAPIMixin],
  getDefaultProps() {
    return {
      image: utils.media.getMediaUrl(
        'blog/appMenuPanel/managePanelWelcome.png',
      ),
    };
  },
  getInitialState() {
    const editorAPI = this.getEditorAPI();
    return {
      showDeleteOldBlogPanel: blogUtils.shouldShowDeleteOldBlogPanel(editorAPI),
      showNewBlogPanel:
        blogUtils.isNewBlogQuickMigrationEnabled() &&
        !blogUtils.siteHasNewBlog(editorAPI),
    };
  },
  addPost() {
    //todo guid for events
    const params = {
      button_name: 'AddPost',
      app_id: 'guid',
      app_name: 'blog',
    };
    this.getEditorAPI().bi.event(
      blogBi.events.MANAGE_PANEL_BUTTON_CLICK,
      params,
    );
    this._openBlogManager('newPost');
  },
  manageBlog() {
    //todo guid for events
    const params = {
      button_name: 'ManageBlog',
      app_id: 'guid',
      app_name: 'blog',
    };
    this.getEditorAPI().bi.event(
      blogBi.events.MANAGE_PANEL_BUTTON_CLICK,
      params,
    );
    this._openBlogManager('allPosts');
  },
  _openBlogManager(blogManagerAppPathType) {
    blogManagerFrame.open(
      this.getEditorAPI(),
      blogManagerAppPathType,
      '5th_button',
    );
  },
  render() {
    return (
      <div className="blog-manage-panel">
        <CustomScroll heightRelativeToParent="100%">
          <div className="blog-manage-panel-container">
            <div className="content">
              <symbols.symbol name="blogManager" className="illustration" />
              <p>{translate('SiteApp_Blog_ManagePanel_TextHeader')}</p>

              <div className="buttons-container">
                <div>
                  <baseUI.button
                    label="SiteApp_Blog_ManagePanel_AddPost_Button_Label"
                    onClick={this.addPost}
                    className="manage-btn btn-confirm-secondary"
                  />
                </div>
                <div>
                  <baseUI.button
                    label="SiteApp_Blog_ManagePanel_ManageBlog_Button_Label"
                    onClick={this.manageBlog}
                    className="manage-btn btn-confirm-primary"
                  />
                </div>
              </div>
            </div>

            {this.state.showNewBlogPanel ? (
              <QuickMigrationStatusPanel key="quickMigrationStatusPanel" />
            ) : null}
            {this.state.showDeleteOldBlogPanel ? (
              <DeleteOldBlogPanel key="deleteOldBlogPanel" />
            ) : null}
          </div>
        </CustomScroll>
      </div>
    );
  },
});
