import React from 'react';
import { frames } from '@/panels';
import { translate as t } from '@/i18n';
import { hoc } from '@/util';

import type { InferComponentProps } from '@/apilib';
import type { Dispatch, MapDispatchToPropsFunction } from 'types/redux';
import { panels } from '@/stateManagement';

const { MessagePanelFrame } = frames;

interface ProhibitedPasteFromFixedStageMessageOwnProps {
  panelName: string;
  origin: string;
}

type ProhibitedPasteFromFixedStageMessageProps = InferComponentProps<
  null,
  typeof mapDispatchToProps,
  ProhibitedPasteFromFixedStageMessageOwnProps
>;

const ProhibitedPasteFromFixedStageMessage: React.FC<
  ProhibitedPasteFromFixedStageMessageProps
> = ({ panelName, openLearnMore }) => {
  return (
    <MessagePanelFrame
      panelName={panelName}
      title={t('fluid_paste_modal_title')}
      confirmLabel={t('fluid_paste_modal_main_cta')}
      cancelLabel={t('fluid_paste_modal_secondary_cta')}
      onSecondaryAction={openLearnMore}
      illustration={null}
    >
      {t('fluid_paste_modal_body')}
    </MessagePanelFrame>
  );
};

interface ProhibitedPasteFromFixedStageMessageDispatchProps {
  openLearnMore: () => void;
}

const mapDispatchToProps: MapDispatchToPropsFunction<
  ProhibitedPasteFromFixedStageMessageDispatchProps,
  ProhibitedPasteFromFixedStageMessageOwnProps
> = (dispatch: Dispatch) => ({
  openLearnMore: () => {
    dispatch(
      panels.actions.openHelpCenter('55f3a147-578d-49e6-a93a-e74f2e63a280'),
    );
  },
});

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(ProhibitedPasteFromFixedStageMessage);
