// @ts-nocheck
import _ from 'lodash';
import tinyMenu from './updateThemePlugins/tinyMenu';
import designDataThemeChangeHandler from './updateThemePlugins/designDataThemeChangeHandler';

const skins = {
  'wysiwyg.viewer.skins.mobile.TinyMenuSkin': tinyMenu,
};

function executeHooks(styleId, style) {
  const hook = skins[style.skin];
  if (hook) {
    hook(styleId, style);
  }
}

export default {
  register(editorAPI) {
    editorAPI.theme.styles.registerHook(executeHooks);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/bind
    const designDataThemeChangeListener = _.bind(
      designDataThemeChangeHandler,
      {},
      editorAPI,
    );
    editorAPI.dsActions.theme.events.onChange.addListener(
      designDataThemeChangeListener,
    );
  },
};
