import type { EntryPoint } from '@/apilib';
import { EditorAPIKey } from '@/apis';
import { createComponentsStageModesApi } from './componentsStageModesWrapper';
import { ComponentsStageModesApiKey } from './publicApiKey';

export const ComponentsStageModesEntrypoint: EntryPoint = {
  name: 'ComponentsStageModesApi',
  declareAPIs: () => [ComponentsStageModesApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(ComponentsStageModesApiKey, () =>
      createComponentsStageModesApi(shell),
    );
  },
};
