import React from 'react';
import { Divider } from '@wix/wix-base-ui';

interface DividifyProps {
  long?: boolean;
  children: React.ReactNode[];
}

export const Dividify: React.FC<DividifyProps> = ({ long, children }) => {
  const length = React.Children.count(children);

  return (
    <>
      {React.Children.map(children, (Child, index) => (
        <>
          {Child}
          {index !== length - 1 && <Divider long={long} />}
        </>
      ))}
    </>
  );
};
