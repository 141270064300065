// @ts-nocheck
import _ from 'lodash';
import * as superApps from '../superApps';
import * as superAppsGfppDataCacheService from './superAppsGfppDataCacheService';

const getCustomMainActions = function () {
  return superAppsGfppDataCacheService.getCustomMainActions();
};

const getCustomMainActionsFor = function (editorAPI, appDefId, widgetId) {
  const mainActions = getCustomMainActions();
  const appActions = mainActions[appDefId];
  if (appActions?.[widgetId]) {
    return appActions[widgetId];
  } else if (appActions?.[appDefId]) {
    return appActions[appDefId];
  }
};

const hasCustomActions = function (appDefId) {
  const mainActions = getCustomMainActions();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  return !_.isUndefined(mainActions[appDefId]);
};

const hasExternalUpgrade = function (appDefId, widgetId) {
  if (superApps.hasPremiumOffering(appDefId)) {
    const mainActions = getCustomMainActions();
    const appActions = mainActions[appDefId];
    if (appActions?.[widgetId]) {
      return appActions[widgetId].showUpgrade;
    } else if (appActions?.[appDefId]) {
      return appActions[appDefId].showUpgrade;
    }
  }
  return false;
};

const shouldShowSettingsInSecondaryLang = function (appDefId, widgetId) {
  const mainActions = getCustomMainActions();
  const appActions = mainActions[appDefId];
  if (appActions?.[widgetId]) {
    return appActions[widgetId].showSettingsInSecondaryLang;
  } else if (appActions?.[appDefId]) {
    return appActions[appDefId].showSettingsInSecondaryLang;
  }
  return false;
};

const shouldShowTranslate = function (appDefId, widgetId) {
  const mainActions = getCustomMainActions();
  const appActions = mainActions[appDefId];
  if (appActions?.[widgetId]) {
    return appActions[widgetId].showTranslate;
  } else if (appActions?.[appDefId]) {
    return appActions[appDefId].showTranslate;
  }
  return false;
};

export {
  getCustomMainActionsFor,
  hasCustomActions,
  shouldShowSettingsInSecondaryLang,
  shouldShowTranslate,
  hasExternalUpgrade,
};
