import React from 'react';

import { cx } from '@/util';

import styles from './leftBar.scss';

interface LeftBarOverlayProps {
  withoutShadow: boolean;
  onOuterClick: () => void;
}

const LeftBarOverlay: React.FC<LeftBarOverlayProps> = (props) => {
  return (
    <div
      className={cx({
        [styles.highlightOverlay]: true,
        [styles.highlightOverlayWithoutShadow]: props.withoutShadow,
      })}
      onClick={props.onOuterClick}
      data-hook="leftBar-highlightOverlay"
    />
  );
};

export default LeftBarOverlay;
