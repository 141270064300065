import constants from '@/constants';

import { createAfterDuplicateComponentPlugin } from './afterDuplicate';
import { createBeforeComponentDuplicatePlugin } from './beforeDuplicate';
import { createCannotCopyOrDuplicateSiteSegment } from './cannotCopyOrDuplicateSiteSegment';
import { createCannotRemoveSiteSegmentPlugin } from './cannotRemoveSiteSegment';
import { verticalAnchorsMenuCompNamePlugin } from './verticalAnchorsMenuCompName';
import { handleCompAttachedToSection } from './compAttachedToSection';
import { handleComponentAddedToStage } from './componentAdded';

import type { Scope } from '@/sections';

const bootstrapCompPlugins = (scope: Scope) => {
  const { editorAPI, copyPaste, components } = scope;

  const afterDuplicateComponentPlugin =
    createAfterDuplicateComponentPlugin(scope);
  const beforeDuplicatePlugin = createBeforeComponentDuplicatePlugin(scope);
  const cannotCopyOrDuplicateSiteSegmentPlugin =
    createCannotCopyOrDuplicateSiteSegment(scope);
  const cannotRemoveSiteSegmentPlugin =
    createCannotRemoveSiteSegmentPlugin(scope);

  copyPaste.hooks.beforeDuplicate.tap(beforeDuplicatePlugin);
  copyPaste.hooks.cannotDuplicate.tap(cannotCopyOrDuplicateSiteSegmentPlugin);
  editorAPI.registerAfterDuplicateComponentCallback(
    afterDuplicateComponentPlugin,
  );
  editorAPI.pluginService.registerPlugin(
    editorAPI.pluginService.pluginConstants.CANNOT_COPY,
    constants.COMP_TYPES.HEADER,
    (editorAPI, { compRefs }) =>
      cannotCopyOrDuplicateSiteSegmentPlugin(compRefs),
  );
  editorAPI.pluginService.registerPlugin(
    editorAPI.pluginService.pluginConstants.CANNOT_COPY,
    constants.COMP_TYPES.FOOTER,
    (editorAPI, { compRefs }) =>
      cannotCopyOrDuplicateSiteSegmentPlugin(compRefs),
  );

  editorAPI.registerCannotRemovePlugin(
    constants.COMP_TYPES.HEADER,
    cannotRemoveSiteSegmentPlugin,
  );
  editorAPI.registerCannotRemovePlugin(
    constants.COMP_TYPES.FOOTER,
    cannotRemoveSiteSegmentPlugin,
  );

  scope.hooks.compAttachedToSection.tap(({ compRef }) =>
    handleCompAttachedToSection(scope, compRef),
  );
  components.hooks.componentAddedToStage.tap((data) => {
    handleComponentAddedToStage(scope, data);
  });

  editorAPI.registerCompNamePlugin(
    constants.COMP_TYPES.ANCHORS_MENU,
    verticalAnchorsMenuCompNamePlugin,
  );
};

export { bootstrapCompPlugins };
