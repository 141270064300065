import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { DATA_BINDING } from '@wix/app-definition-ids';
import {
  ContextMenu,
  ContextMenuAction,
  ContextMenuContent,
  Text,
  WixBaseUiEnvironmentProvider,
} from '@wix/wix-base-ui';
import * as core from '@/core';
import * as baseUI from '@/baseUI';
import * as util from '@/util';
import constants from '@/constants';
import { translate } from '@/i18n';
import { origin, EVENTS } from '../consts';
import { collectionClicked } from '../bi';
import { getSchemaActions } from '../collectionContextMenuItems';
import ContextMenuItem from './contextMenuItem/contextMenuItem';
import SharedCollectionLabel from './sharedCollectionLabel/sharedCollectionLabel';

import type { BiEventDefinition, BiEventFields } from 'types/bi';
import type { Action, CollectionItemCount } from '../types';
import {
  mapStateToProps,
  type CollectionItemStateProps,
} from './collectionItem.mapper';

type ContextMenuAction = Action & { applicationId: string };

const {
  permissionsByType,
  translationKeyByTypeOfPermissions,
  TYPE_OF_CUSTOM_PERMISSIONS,
  TYPE_OF_PRIVATE_DATA_PERMISSIONS,
} = constants.WIX_DATA.permissionData;

const biEvents = {
  CLICK_ON_CONTEXT_MENU: {
    src: 38,
    evid: 825,
    fields: {
      collection_id: 'string',
      collection_name: 'string',
      collection_type: 'string',
    },
  },
  CLICK_ON_CONTEXT_MENU_ACTION: {
    src: 38,
    evid: 823,
    fields: {
      collection_id: 'string',
      collection_name: 'string',
      collection_type: 'string',
      category: 'string',
    },
  },
};

interface CollectionItemOwnProps {
  aid?: string;
  collectionId: string;
  collectionName: string;
  collectionPermissions?: Record<string, unknown>;
  iconName: string;
  isEditable: boolean;
  isSharedCollection?: boolean;
  sendBi: (event: BiEventDefinition, params: BiEventFields) => void;
}

interface CollectionItemProps
  extends CollectionItemOwnProps,
    CollectionItemStateProps {}

// eslint-disable-next-line react/prefer-es6-class
const CollectionItem = createReactClass<CollectionItemProps>({
  displayName: 'CollectionItem',

  propTypes: {
    collectionId: PropTypes.string.isRequired,
    collectionName: PropTypes.string.isRequired,
    collectionPermissions: PropTypes.object,
    iconName: PropTypes.string.isRequired,
    isEditable: PropTypes.bool.isRequired,
  },

  mixins: [core.mixins.editorAPIMixin],

  getInitialState() {
    return {
      contextMenuActions: [],
    };
  },

  getTypeOfPermissions(): string {
    const { collectionPermissions } = this.props;
    if (!collectionPermissions) {
      return TYPE_OF_PRIVATE_DATA_PERMISSIONS;
    }
    const typeOfPermissions = _.findKey(
      permissionsByType,
      collectionPermissions,
    );
    return typeOfPermissions || TYPE_OF_CUSTOM_PERMISSIONS;
  },

  getContextMenuActions() {
    const editorAPI = this.getEditorAPI();

    const schemaActions = getSchemaActions({
      editorAPI,
      schemaId: this.props.collectionId,
    });

    const { applicationId }: { applicationId: string } =
      editorAPI.dsRead.platform.getAppDataByAppDefId(DATA_BINDING);

    return schemaActions.map((schemaAction) => ({
      applicationId,
      ...schemaAction,
    }));
  },

  handleContextMenuClick(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation(); // Avoid triggering the click on the collection item itself.

    const editorAPI = this.getEditorAPI();
    editorAPI.bi.event(biEvents.CLICK_ON_CONTEXT_MENU, {
      collection_id: this.props.collectionId,
      collection_name: this.props.collectionName,
      collection_type: this.getTypeOfPermissions(),
    });
  },

  onContextMenuActionClick({ event, applicationId }: ContextMenuAction) {
    const editorAPI = this.getEditorAPI();

    editorAPI.bi.event(biEvents.CLICK_ON_CONTEXT_MENU_ACTION, {
      collection_id: this.props.collectionId,
      collection_name: this.props.collectionName,
      collection_type: this.getTypeOfPermissions(),
      category: event,
    });

    editorAPI.dsActions.platform.notifyApplication(applicationId, {
      eventType: event,
      eventPayload: {
        collectionId: this.props.collectionId,
        origin,
      },
    });
  },

  openContentManager() {
    this.props.sendBi(collectionClicked, {
      collection_name: this.props.collectionName,
      collectionId: this.props.collectionId,
    });
    const editorAPI = this.getEditorAPI();
    const { applicationId } =
      editorAPI.dsRead.platform.getAppDataByAppDefId(DATA_BINDING);
    editorAPI.dsActions.platform.notifyApplication(applicationId, {
      eventType: EVENTS.databasePanelCollectionSelected,
      eventPayload: {
        collectionName: this.props.collectionId,
        origin,
      },
    });
  },

  getPermissionLabel() {
    const translationForTypeOfPermissions = translate(
      translationKeyByTypeOfPermissions[
        this.getTypeOfPermissions() as keyof typeof translationKeyByTypeOfPermissions
      ],
    );

    return translate('SiteApp_WixData_DataManager_Tab_Permissions_Label', {
      collectionType: translationForTypeOfPermissions,
    });
  },

  getItemCountLabel() {
    let countLabel;

    const isSandbox = !!this.props.itemCounts?.sandboxItems;

    const liveCount = this.props.itemCounts?.liveItems?.find(
      (itemCount: CollectionItemCount) =>
        itemCount.collectionId === this.props.collectionId,
    )?.itemCount;

    const sandboxCount = this.props.itemCounts?.sandboxItems?.find(
      (itemCount: CollectionItemCount) =>
        itemCount.collectionId === this.props.collectionId,
    )?.itemCount;

    if (liveCount === undefined) {
      return '';
    }

    if (isSandbox) {
      if (sandboxCount === undefined) {
        return '';
      }
      countLabel = translate(
        'SiteApp_WixData_DataManager_Tab_Item_Count_withSandbox',
        {
          liveCount,
          sandboxCount,
        },
      );
    } else {
      countLabel = translate('SiteApp_WixData_DataManager_Tab_Item_Count', {
        count: liveCount,
      });
    }

    return `${countLabel} • `;
  },

  render() {
    return (
      <div
        data-collection-id={this.props.collectionId}
        onMouseOver={() => {
          {
            this.setState({ contextMenuShouldBeShown: true });
          }
        }}
        onMouseLeave={() => {
          {
            if (!this.contextMenuIsOpen)
              this.setState({ contextMenuShouldBeShown: false });
          }
        }}
        onClick={this.openContentManager}
        className="collection-item"
        data-hook="collection-item"
      >
        <div className="collection-item-icon">
          <baseUI.symbol
            aid="collection-item-icon"
            name={this.props.iconName}
          />
        </div>

        <div className="collection-info">
          <div className="collection-properties">
            <Text
              size="small"
              skin="secondary"
              weight="thin"
              shouldTranslate={false}
              children={this.props.collectionName}
              className="collection-name"
              dataHook="collection-name"
            />
            {this.props.isSharedCollection && <SharedCollectionLabel />}
          </div>
          <WixBaseUiEnvironmentProvider madefor>
            <Text
              shouldTranslate={false}
              size="tiny"
              weight="thin"
              children={this.getItemCountLabel() + this.getPermissionLabel()}
              dataHook="details-label"
            />
          </WixBaseUiEnvironmentProvider>
        </div>

        <div
          onMouseEnter={() => {
            {
              this.mouseIsOverContextMenu = true;
            }
          }}
          onMouseUp={() => {
            {
              if (this.mouseIsOverContextMenu)
                this.setState({
                  contextMenuActions: this.getContextMenuActions(),
                });
            }
          }}
          onClick={this.handleContextMenuClick}
          onMouseLeave={() => {
            {
              this.mouseIsOverContextMenu = false;
            }
          }}
          className="context-menu"
        >
          {this?.state?.contextMenuShouldBeShown ? (
            <ContextMenu
              key="contextMenu"
              onToggle={(isOpen: boolean) => {
                {
                  if (!isOpen)
                    this.setState({
                      contextMenuShouldBeShown: false,
                      contextMenuActions: [],
                    });
                  this.contextMenuIsOpen = isOpen;
                }
              }}
              className="flat"
              maxWidth={'350px'}
            >
              <ContextMenuContent>
                {this.state.contextMenuActions.map(
                  (
                    contextMenuAction: ContextMenuAction,
                    contextMenuActionIndex: number,
                  ) => (
                    <ContextMenuAction
                      key={`context-menu-action${contextMenuActionIndex}`}
                      automationId={contextMenuAction.event}
                      onClick={() => {
                        this.onContextMenuActionClick(contextMenuAction);
                      }}
                      // @ts-expect-error
                      value={contextMenuAction.event}
                    >
                      <ContextMenuItem
                        symbolName={contextMenuAction.icon}
                        label={contextMenuAction.title}
                      />
                    </ContextMenuAction>
                  ),
                )}
              </ContextMenuContent>
            </ContextMenu>
          ) : null}
        </div>
      </div>
    );
  },
});

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
)(CollectionItem);
