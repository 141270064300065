import { BasePublicApi } from '@/apilib';

import type { AiScope as Scope } from './scope';
import tools from './aiAssistantTools';
import pageContextTool from './aiAssistantTools/tools/pageContext';

function registerEditorTools(scope: Scope) {
  const editorAPI = scope.editorAPI;
  const registerToolsAPI =
    editorAPI.documentServices.ai.conversation.registerTool;

  tools.forEach(({ name, func: callback }) => {
    registerToolsAPI({
      name,
      // @ts-expect-error
      func: (...params: unknown[]) => callback(scope, ...params),
    });
  });
}

export class AiPublicApi extends BasePublicApi<Scope> {
  registerEditorTools = this.bindScope(registerEditorTools);
  testContextTool = this.bindScope(pageContextTool.func);
}
