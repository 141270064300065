'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const i18n = require('@/i18n')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_preset_list_events',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': `addPanelData/sections/listEventsSection_${util.languages.getLanguageCode()}/listEventsSection_${util.languages.getLanguageCode()}.png`,
        'imageHover': null,
        'items': [{
            'id': 'Lists_List_Events_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 278,
                    'height': 548,
                    'x': 106,
                    'y': 111,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti9ea8f5d9'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'name': 'EventList_i6cmwyut522',
                            'version': 0,
                            'baseTypes': [],
                            'fields': [{
                                'displayName': i18n.translate('i9ea8f7u_1.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9ea8f7u_1.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'Strng_sTxt1',
                                'searchable': false,
                                'displayName': i18n.translate('i9ea8f7u_1.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i9ea8f7u_1.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'name': 'i985ggnd',
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i9ea8f7u_1.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i9ea8f7u_1.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i9ea8f7u_1.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'name': 'wxRchTxt_sTxt2',
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false
                            }, {
                                'name': 'i985up10',
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i9ea8f7u_1.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9ea8f7u_1.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'i98685zk',
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i9ea8f7u_1.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i9ea8f7u_1.structure.custom.template.type.fields.6.displayName'),
                                'name': 'i9862u4x',
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i9ea8f7u_1.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i9ea8f7u_1.structure.custom.template.type.fields.7.displayName'),
                                'name': 'i98k5707',
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {
                                    '_type': 'wix:Map',
                                    'i6cmznh8': {
                                        'protocol': 'http',
                                        '_type': 'wix:ExternalLink',
                                        'target': '_blank',
                                        'address': 'www.wix.com'
                                    }
                                },
                                'computed': false
                            }],
                            'displayName': i18n.translate('i9ea8f7u_1.structure.custom.template.type.displayName')
                        },
                        'views': [{
                            'name': 'EventListView_i6cmwyuu523_dup_i6m4o0ub18_dup_i6qm3pa4333_dup_i6rz3y58285_dup_i721db2b303_dup_i7ekcvtg4_dup_i9ea8f4i118',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'EventListView_i6cmwyuu523_dup_i6m4o0ub18_dup_i6qm3pa4333_dup_i6rz3y58285_dup_i721db2b303_dup_i7ekcvtg4_dup_i9ea8f4i118'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'EventListView_i6cmwyuu523_dup_i6m4o0ub18_dup_i6qm3pa4333_dup_i6rz3y58285_dup_i721db2b303_dup_i7ekcvtg4_dup_i9ea8f4i118'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9ea8f4i'
                        }, {
                            'name': 'EventListView_i6cmwyuu523_dup_i6m4o0ub18_dup_i6qm3pa4333_dup_i6rz3y58285_dup_i721db2b303_dup_i7ekcvtg4_dup_i9ea8f4i118',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'EventListView_i6cmwyuu523_dup_i6m4o0ub18_dup_i6qm3pa4333_dup_i6rz3y58285_dup_i721db2b303_dup_i7ekcvtg4_dup_i9ea8f4i118'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'EventListView_i6cmwyuu523_dup_i6m4o0ub18_dup_i6qm3pa4333_dup_i6rz3y58285_dup_i721db2b303_dup_i7ekcvtg4_dup_i9ea8f4i118'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9ea8f4i',
                            'format': 'Mobile'
                        }, {
                            'name': 'EventListView_i6cmwyuu523_dup_i6m4o0ub18_dup_i6qm3pa4333_dup_i6rz3y58285_dup_i721db2b303_dup_i7ekcvtg4_dup_i9ea8f4i118',
                            'forType': 'EventList_i6cmwyut522',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i9862u6g65',
                                            'data': 'i9862u4x',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i9862u6g65_proxy',
                                                    'data': 'i9862u4x',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 12,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true,
                                                'height': '220',
                                                'box-align': 'left'
                                            }
                                        }, {
                                            'id': 'fld_i986861642',
                                            'data': 'i98685zk',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 218,
                                                'items': [{
                                                    'id': 'fld_i986861642_proxy',
                                                    'data': 'i98685zk',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 12,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'items': [],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 10
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'pack': 'start',
                                                'items': [{
                                                    'id': 'fld_i985ggp733',
                                                    'data': 'i985ggnd',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i985ggp733_proxy',
                                                            'data': 'i985ggnd',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'style': 'Body L',
                                                                'format': 'mmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hfwrbaju',
                                                    'data': 'Strng_sTxt1',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hfwrbaju_proxy',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 16,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_hfxscm3b',
                                            'data': 'title',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'items': [{
                                                    'id': 'fld_hfxscm3b_proxy',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'style': 'Body L',
                                                        'bold': false,
                                                        'italic': false,
                                                        'underline': false,
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 3
                                                },
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            }
                                        }, {
                                            'id': 'fld_hfxsg737',
                                            'data': 'wxRchTxt_sTxt2',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'items': [{
                                                    'id': 'fld_hfxsg737_proxy',
                                                    'data': 'wxRchTxt_sTxt2',
                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 12,
                                                    'after': 12,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            }
                                        }, {
                                            'id': 'fld_i985up2981',
                                            'data': 'i985up10',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'items': [{
                                                    'id': 'fld_i985up2981_proxy',
                                                    'data': 'i985up10',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'style': 'Heading S',
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            },
                                            'experiment': 'NewListFields'
                                        }, {
                                            'id': 'fld_i98k572l113',
                                            'data': 'i98k5707',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 160,
                                                'height': '40',
                                                'items': [{
                                                    'id': 'fld_i98k572l113_proxy',
                                                    'data': 'i98k5707',
                                                    'comp': {'name': 'Button2', 'align': 'center', 'style': 'b1'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 20,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'start',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }, {
                            'name': 'EventListView_i6cmwyuu523_dup_i6m4o0ub18_dup_i6qm3pa4333_dup_i6rz3y58285_dup_i721db2b303_dup_i7ekcvtg4_dup_i9ea8f4i118',
                            'forType': 'EventList_i6cmwyut522',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i9862u6g65',
                                            'data': 'i9862u4x',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i9862u6g65_proxy',
                                                    'data': 'i9862u4x',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 12,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true,
                                                'height': '220',
                                                'box-align': 'left'
                                            }
                                        }, {
                                            'id': 'fld_i986861642',
                                            'data': 'i98685zk',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 220,
                                                'items': [{
                                                    'id': 'fld_i986861642_proxy',
                                                    'data': 'i98685zk',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 12,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'items': [],
                                                        'orientation': 'horizontal',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Left section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'min-width': 0, 'spacerAfter': 0}
                                                }, {
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'start',
                                                        'items': [{
                                                            'id': 'Center_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Center_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': [{
                                                                    'id': 'fld_i985ggp733',
                                                                    'data': 'i985ggnd',
                                                                    'comp': {
                                                                        'name': 'TextField',
                                                                        'width': 240,
                                                                        'items': [{
                                                                            'id': 'fld_i985ggp733_proxy',
                                                                            'data': 'i985ggnd',
                                                                            'comp': {
                                                                                'name': 'Date',
                                                                                'style': 'Body L',
                                                                                'format': 'mmm dd, yyyy',
                                                                                'color': 'color_14'
                                                                            },
                                                                            'layout': {'text-align': 'left'}
                                                                        }],
                                                                        'labelPosition': 'none',
                                                                        'spacers': {
                                                                            'before': 0,
                                                                            'after': 11,
                                                                            'xax-before': 0,
                                                                            'xax-after': 0
                                                                        },
                                                                        'hidden': false,
                                                                        'width-mode': 'auto',
                                                                        'min-lines': '0',
                                                                        'max-lines': '0',
                                                                        'box-align': 'end'
                                                                    },
                                                                    'experiment': 'NewListFields'
                                                                }, {
                                                                    'id': 'fld_hfwrbaju',
                                                                    'data': 'Strng_sTxt1',
                                                                    'comp': {
                                                                        'name': 'TextField',
                                                                        'width': 240,
                                                                        'items': [{
                                                                            'id': 'fld_hfwrbaju_proxy',
                                                                            'data': 'Strng_sTxt1',
                                                                            'comp': {
                                                                                'name': 'Label',
                                                                                'style': 'Body L',
                                                                                'color': 'color_14'
                                                                            },
                                                                            'layout': {'text-align': 'left'}
                                                                        }],
                                                                        'hidden': false,
                                                                        'width-mode': 'auto',
                                                                        'min-lines': '0',
                                                                        'max-lines': '0',
                                                                        'labelPosition': 'none',
                                                                        'spacers': {
                                                                            'before': 0,
                                                                            'after': 3,
                                                                            'xax-before': 3,
                                                                            'xax-after': 0
                                                                        },
                                                                        'box-align': 'start'
                                                                    }
                                                                }]
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Center_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Center_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': [],
                                                                'pack': 'end'
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 0}
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'def_3_0',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Right section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_hfxscm3b',
                                            'data': 'title',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 240,
                                                'items': [{
                                                    'id': 'fld_hfxscm3b_proxy',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'style': 'Body L',
                                                        'color': 'color_14',
                                                        'bold': false,
                                                        'italic': false,
                                                        'underline': false
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            }
                                        }, {
                                            'id': 'fld_hfxsg737',
                                            'data': 'wxRchTxt_sTxt2',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 240,
                                                'items': [{
                                                    'id': 'fld_hfxsg737_proxy',
                                                    'data': 'wxRchTxt_sTxt2',
                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 12,
                                                    'after': 12,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            }
                                        }, {
                                            'id': 'fld_i985up2981',
                                            'data': 'i985up10',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 240,
                                                'items': [{
                                                    'id': 'fld_i985up2981_proxy',
                                                    'data': 'i985up10',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'style': 'Heading S',
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            },
                                            'experiment': 'NewListFields'
                                        }, {
                                            'id': 'fld_i98k572l113',
                                            'data': 'i98k5707',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 44,
                                                'items': [{
                                                    'id': 'fld_i98k572l113_proxy',
                                                    'data': 'i98k5707',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 20,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true, 'originalWidth': 270},
                            'format': 'Mobile'
                        }],
                        'items': [{
                            'Strng_sTxt1': i18n.translate('i9ea8f7u_1.structure.custom.template.items.0.Strng_sTxt1'),
                            'title': i18n.translate('i9ea8f7u_1.structure.custom.template.items.0.title'),
                            'wxRchTxt_sTxt2': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ea8f7u_1.structure.custom.template.items.0.wxRchTxt_sTxt2')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6cmznh8': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                },
                                'i98k5707': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i985ggnd': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i985up10': i18n.translate('i9ea8f7u_1.structure.custom.template.items.0.i985up10'),
                            'i9862u4x': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i98685zk': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'c7ad2b64020342d0ae1a1feace158fe9.jpg',
                                'width': 1920,
                                'height': 1280,
                                'alt': ''
                            },
                            'i98k5707': i18n.translate('i9ea8f7u_1.structure.custom.template.items.0.i98k5707')
                        }, {
                            'Strng_sTxt1': i18n.translate('i9ea8f7u_1.structure.custom.template.items.1.Strng_sTxt1'),
                            'title': i18n.translate('i9ea8f7u_1.structure.custom.template.items.1.title'),
                            'wxRchTxt_sTxt2': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ea8f7u_1.structure.custom.template.items.1.wxRchTxt_sTxt2')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6cmznh8': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                },
                                'i98k5707': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i985ggnd': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i985up10': i18n.translate('i9ea8f7u_1.structure.custom.template.items.1.i985up10'),
                            'i9862u4x': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i98685zk': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'd967ba93f0314c78924edc8a8c8cfa15.jpg',
                                'width': 1920,
                                'height': 1275,
                                'alt': ''
                            },
                            'i98k5707': i18n.translate('i9ea8f7u_1.structure.custom.template.items.1.i98k5707')
                        }, {
                            'Strng_sTxt1': i18n.translate('i9ea8f7u_1.structure.custom.template.items.2.Strng_sTxt1'),
                            'title': i18n.translate('i9ea8f7u_1.structure.custom.template.items.2.title'),
                            'wxRchTxt_sTxt2': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ea8f7u_1.structure.custom.template.items.2.wxRchTxt_sTxt2')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6cmznh8': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                },
                                'i98k5707': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i985ggnd': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i985up10': i18n.translate('i9ea8f7u_1.structure.custom.template.items.2.i985up10'),
                            'i9862u4x': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i98685zk': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'bef2ef0f0a3343aba2fa7e746baa781c.jpg',
                                'width': 4434,
                                'height': 3729,
                                'alt': ''
                            },
                            'i98k5707': i18n.translate('i9ea8f7u_1.structure.custom.template.items.2.i98k5707')
                        }],
                        'displayName': i18n.translate('i9ea8f7u_1.structure.custom.template.displayName')
                    }
                },
                'id': 'i9ea8f7u_1'
            },
            'preset': {
                'rect': {'width': 162, 'height': 297, 'x': 0, 'y': 0},
                'label': 'i9ea8f7u_1.structure.custom.template.displayName',
                'tags': null
            }
        },
        {
            'id': 'Lists_List_Events_2', 'structure': {
                'type': 'Component', 'skin': 'wysiwyg.viewer.skins.AppPartSkin', 'layout': {
                    'width': 427,
                    'height': 468,
                    'x': 743,
                    'y': 151,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2', 'data': {
                    'type': 'AppBuilderComponent', 'metaData': {
                        'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false
                    },
                    'appInnerID': '2', 'appPartName': 'appparti9ea8f6012'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {
                        'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false
                    },
                    'style': {
                        'groups': {},
                        'properties': {
                            'rd': '7px'
                        },
                        'propertiesSource': {
                            'rd': 'value'
                        }
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'fields': [{
                                'displayName': i18n.translate('i9ea8f7u_2.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9ea8f7u_2.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'Strng_sTxt1',
                                'searchable': false,
                                'displayName': i18n.translate('i9ea8f7u_2.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i9ea8f7u_2.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i9ea8f7u_2.structure.custom.template.type.fields.2.displayName'),
                                'name': 'i98723ix',
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i9ea8f7u_2.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i9ea8f7u_2.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'name': 'wxRchTxt_sTxt2',
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i9ea8f7u_2.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9ea8f7u_2.structure.custom.template.type.fields.4.defaultValue'),
                                'name': 'i986xt6n',
                                'computed': false
                            }, {
                                'name': 'i74qitov',
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i9ea8f7u_2.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i9ea8f7u_2.structure.custom.template.type.fields.6.displayName'),
                                'name': 'i74qklqs',
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i9ea8f7u_2.structure.custom.template.type.fields.7.defaultValue'),
                                'name': 'i74q7yix',
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i9ea8f7u_2.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'version': 0,
                            'baseTypes': [],
                            'name': 'EventList_i74n1ocq17',
                            'displayName': i18n.translate('i9ea8f7u_2.structure.custom.template.type.displayName')
                        },
                        'views': [{
                            'name': 'EventListView_i74n1oct18_dup_i7ekcvv25_dup_i9ea8f5g119',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'EventListView_i74n1oct18_dup_i7ekcvv25_dup_i9ea8f5g119'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'EventListView_i74n1oct18_dup_i7ekcvv25_dup_i9ea8f5g119'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9ea8f5g'
                        }, {
                            'name': 'EventListView_i74n1oct18_dup_i7ekcvv25_dup_i9ea8f5g119',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'EventListView_i74n1oct18_dup_i7ekcvv25_dup_i9ea8f5g119'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'EventListView_i74n1oct18_dup_i7ekcvv25_dup_i9ea8f5g119'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9ea8f5g',
                            'format': 'Mobile'
                        }, {
                            'name': 'EventListView_i74n1oct18_dup_i7ekcvv25_dup_i9ea8f5g119',
                            'forType': 'EventList_i74n1ocq17',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i74qitpo402',
                                            'data': 'i74qitov',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 410,
                                                'items': [{
                                                    'id': 'fld_i74qitpo402_proxy',
                                                    'data': 'i74qitov',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 11,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true,
                                                'height': 230,
                                                'box-align': 'center'
                                            }
                                        }, {
                                            'id': 'fld_i74qklrq427',
                                            'data': 'i74qklqs',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 410,
                                                'height': 255,
                                                'items': [{
                                                    'id': 'fld_i74qklrq427_proxy',
                                                    'data': 'i74qklqs',
                                                    'comp': {
                                                        'name': 'Video',
                                                        'lightTheme': false,
                                                        'showControls': 'always_show',
                                                        'showinfo': false
                                                    },
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 15,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }, {
                                            'id': 'fld_hfxscm3b',
                                            'data': 'title',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'items': [{
                                                    'id': 'fld_hfxscm3b_proxy',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'style': 'Heading S',
                                                        'bold': false,
                                                        'italic': false,
                                                        'underline': false,
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 3
                                                },
                                                'hidden': false,
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {'id': 'topSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 10
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'pack': 'center',
                                                'items': [{
                                                    'id': 'fld_i98723kh50',
                                                    'data': 'i98723ix',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i98723kh50_proxy',
                                                            'data': 'i98723ix',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mm/dd/yy',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 12,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hfwrbaju',
                                                    'data': 'Strng_sTxt1',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfwrbaju_proxy',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_hfxsg737',
                                            'data': 'wxRchTxt_sTxt2',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'items': [{
                                                    'id': 'fld_hfxsg737_proxy',
                                                    'data': 'wxRchTxt_sTxt2',
                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 7,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': false,
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            }
                                        }, {
                                            'id': 'fld_i986xt81125',
                                            'data': 'i986xt6n',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'items': [{
                                                    'id': 'fld_i986xt81125_proxy',
                                                    'data': 'i986xt6n',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'style': 'Heading S',
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 13,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true,
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            },
                                            'experiment': 'NewListFields'
                                        }, {
                                            'id': 'fld_i74q7ykt345',
                                            'data': 'i74q7yix',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 160,
                                                'items': [{
                                                    'id': 'fld_i74q7ykt345_proxy',
                                                    'data': 'i74q7yix',
                                                    'comp': {'name': 'Button2', 'style': 'b1'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true,
                                                'height': 40,
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }, {
                            'name': 'EventListView_i74n1oct18_dup_i7ekcvv25_dup_i9ea8f5g119',
                            'forType': 'EventList_i74n1ocq17',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i74qitpo402',
                                            'data': 'i74qitov',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i74qitpo402_proxy',
                                                    'data': 'i74qitov',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 11,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true,
                                                'height': 200,
                                                'box-align': 'center'
                                            }
                                        }, {
                                            'id': 'fld_i74qklrq427',
                                            'data': 'i74qklqs',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i74qklrq427_proxy',
                                                    'data': 'i74qklqs',
                                                    'comp': {
                                                        'name': 'Video',
                                                        'lightTheme': false,
                                                        'showControls': 'always_show',
                                                        'showinfo': false
                                                    },
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 15,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true,
                                                'height': 220,
                                                'box-align': 'center'
                                            }
                                        }, {
                                            'id': 'fld_hfxscm3b',
                                            'data': 'title',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 260,
                                                'items': [{
                                                    'id': 'fld_hfxscm3b_proxy',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'style': 'Heading S',
                                                        'bold': false,
                                                        'italic': false,
                                                        'underline': false,
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': false,
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {'id': 'topSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }, {
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'items': [{
                                                            'id': 'Center_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'items': [{
                                                                    'id': 'fld_i98723kh50',
                                                                    'data': 'i98723ix',
                                                                    'comp': {
                                                                        'name': 'TextField',
                                                                        'width': 280,
                                                                        'width-mode': 'auto',
                                                                        'min-lines': '0',
                                                                        'max-lines': '0',
                                                                        'items': [{
                                                                            'id': 'fld_i98723kh50_proxy',
                                                                            'data': 'i98723ix',
                                                                            'comp': {
                                                                                'name': 'Date',
                                                                                'format': 'mm/dd/yy',
                                                                                'style': 'Body L',
                                                                                'color': 'color_14'
                                                                            },
                                                                            'layout': {'text-align': 'center'}
                                                                        }],
                                                                        'labelPosition': 'none',
                                                                        'spacers': {
                                                                            'before': 0,
                                                                            'after': 0,
                                                                            'xax-before': 0,
                                                                            'xax-after': 0
                                                                        },
                                                                        'hidden': false
                                                                    },
                                                                    'experiment': 'NewListFields'
                                                                }],
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Center_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'pack': 'center'
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Center_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'items': [{
                                                                    'id': 'fld_hfwrbaju',
                                                                    'data': 'Strng_sTxt1',
                                                                    'comp': {
                                                                        'name': 'TextField',
                                                                        'width': 280,
                                                                        'width-mode': 'auto',
                                                                        'min-lines': '0',
                                                                        'max-lines': '0',
                                                                        'items': [{
                                                                            'id': 'fld_hfwrbaju_proxy',
                                                                            'data': 'Strng_sTxt1',
                                                                            'comp': {
                                                                                'name': 'Label',
                                                                                'style': 'Body L',
                                                                                'color': 'color_14'
                                                                            },
                                                                            'layout': {'text-align': 'center'}
                                                                        }],
                                                                        'labelPosition': 'none',
                                                                        'spacers': {
                                                                            'before': 0,
                                                                            'after': 0,
                                                                            'xax-before': 0,
                                                                            'xax-after': 0
                                                                        },
                                                                        'hidden': false
                                                                    }
                                                                }],
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Center_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'pack': 'center'
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_hfxsg737',
                                            'data': 'wxRchTxt_sTxt2',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 260,
                                                'items': [{
                                                    'id': 'fld_hfxsg737_proxy',
                                                    'data': 'wxRchTxt_sTxt2',
                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 7,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': false,
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            }
                                        }, {
                                            'id': 'fld_i986xt81125',
                                            'data': 'i986xt6n',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 260,
                                                'items': [{
                                                    'id': 'fld_i986xt81125_proxy',
                                                    'data': 'i986xt6n',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'style': 'Heading S',
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 13,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true,
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            },
                                            'experiment': 'NewListFields'
                                        }, {
                                            'id': 'fld_i74q7ykt345',
                                            'data': 'i74q7yix',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 260,
                                                'items': [{
                                                    'id': 'fld_i74q7ykt345_proxy',
                                                    'data': 'i74q7yix',
                                                    'comp': {'name': 'Button2', 'style': 'b1'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true,
                                                'height': 44,
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true, 'originalWidth': 490},
                            'format': 'Mobile'
                        }], 'items': [{
                            'Strng_sTxt1': i18n.translate('i9ea8f7u_2.structure.custom.template.items.0.Strng_sTxt1'),
                            'title': i18n.translate('i9ea8f7u_2.structure.custom.template.items.0.title'),
                            'wxRchTxt_sTxt2': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ea8f7u_2.structure.custom.template.items.0.wxRchTxt_sTxt2')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i74q7yix': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i74q7yix': i18n.translate('i9ea8f7u_2.structure.custom.template.items.0.i74q7yix'),
                            'i74qitov': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'c7ad2b64020342d0ae1a1feace158fe9.jpg',
                                'width': 1920,
                                'height': 1280,
                                'alt': ''
                            },
                            'i74qklqs': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i986xt6n': i18n.translate('i9ea8f7u_2.structure.custom.template.items.0.i986xt6n'),
                            'i98723ix': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'}
                        }, {
                            'Strng_sTxt1': i18n.translate('i9ea8f7u_2.structure.custom.template.items.1.Strng_sTxt1'),
                            'title': i18n.translate('i9ea8f7u_2.structure.custom.template.items.1.title'),
                            'wxRchTxt_sTxt2': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ea8f7u_2.structure.custom.template.items.1.wxRchTxt_sTxt2')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i74q7yix': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i74q7yix': i18n.translate('i9ea8f7u_2.structure.custom.template.items.1.i74q7yix'),
                            'i74qitov': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'd967ba93f0314c78924edc8a8c8cfa15.jpg',
                                'width': 1920,
                                'height': 1275,
                                'alt': ''
                            },
                            'i74qklqs': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i986xt6n': i18n.translate('i9ea8f7u_2.structure.custom.template.items.1.i986xt6n'),
                            'i98723ix': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'}
                        }, {
                            'Strng_sTxt1': i18n.translate('i9ea8f7u_2.structure.custom.template.items.2.Strng_sTxt1'),
                            'title': i18n.translate('i9ea8f7u_2.structure.custom.template.items.2.title'),
                            'wxRchTxt_sTxt2': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ea8f7u_2.structure.custom.template.items.2.wxRchTxt_sTxt2')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i74q7yix': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i74q7yix': i18n.translate('i9ea8f7u_2.structure.custom.template.items.2.i74q7yix'),
                            'i74qitov': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'bef2ef0f0a3343aba2fa7e746baa781c.jpg',
                                'width': 4434,
                                'height': 3729,
                                'alt': ''
                            },
                            'i74qklqs': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i986xt6n': i18n.translate('i9ea8f7u_2.structure.custom.template.items.2.i986xt6n'),
                            'i98723ix': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'}
                        }], 'displayName': i18n.translate('i9ea8f7u_2.structure.custom.template.displayName')
                    }
                },
                'id': 'i9ea8f7u_2'
            },
            'preset': {
                'rect': {
                    'width': 162, 'height': 177, 'x': 162, 'y': 0
                },
                'label': 'i9ea8f7u_2.structure.custom.template.displayName', 'tags': null
            }
        },
        {
            'id': 'Lists_List_Events_3', 'structure': {
                'type': 'Component', 'skin': 'wysiwyg.viewer.skins.AppPartSkin', 'layout': {
                    'width': 734,
                    'height': 388,
                    'x': 694,
                    'y': 759,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                }, 'componentType': 'wixapps.integration.components.AppPart2', 'data': {
                    'type': 'AppBuilderComponent', 'metaData': {
                        'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false
                    },
                    'appInnerID': '2', 'appPartName': 'appparti9ea8f6p15'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {
                        'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false
                    },
                    'style': {
                        'groups': {},
                        'properties': {
                            'rd': '7px'
                        },
                        'propertiesSource': {
                            'rd': 'value'
                        }
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'displayName': i18n.translate('i9ea8f7v.structure.custom.template.type.displayName'),
                            'name': 'NewsPosts_i6kgo8hc79',
                            'version': 0,
                            'baseTypes': [],
                            'fields': [{
                                'displayName': i18n.translate('i9ea8f7v.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9ea8f7v.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'Strng_sTxt1',
                                'searchable': false,
                                'displayName': i18n.translate('i9ea8f7v.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i9ea8f7v.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'name': 'i988299v',
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i9ea8f7v.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i9ea8f7v.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i9ea8f7v.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'name': 'wxRchTxt_sTxt0',
                                'computed': false
                            }, {
                                'name': 'i988l4hj',
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i9ea8f7v.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9ea8f7v.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i9ea8f7v.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i9ea8f7v.structure.custom.template.type.fields.6.displayName'),
                                'name': 'i6m0pxog',
                                'computed': false
                            }, {
                                'name': 'Strng_sBttn0-v1c',
                                'defaultValue': i18n.translate('i9ea8f7v.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i9ea8f7v.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }]
                        },
                        'views': [{
                            'name': 'NewsPostsView_i6kgo8hd80_dup_i6kigfzs229_dup_i6qm3oyj323_dup_i6ruoykd73_dup_i721db34304_dup_i7ekcvww6_dup_i9ea8f64120',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6kgo8hd80_dup_i6kigfzs229_dup_i6qm3oyj323_dup_i6ruoykd73_dup_i721db34304_dup_i7ekcvww6_dup_i9ea8f64120'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6kgo8hd80_dup_i6kigfzs229_dup_i6qm3oyj323_dup_i6ruoykd73_dup_i721db34304_dup_i7ekcvww6_dup_i9ea8f64120'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9ea8f64',
                            'format': 'Mobile'
                        }, {
                            'name': 'NewsPostsView_i6kgo8hd80_dup_i6kigfzs229_dup_i6qm3oyj323_dup_i6ruoykd73_dup_i721db34304_dup_i7ekcvww6_dup_i9ea8f64120',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6kgo8hd80_dup_i6kigfzs229_dup_i6qm3oyj323_dup_i6ruoykd73_dup_i721db34304_dup_i7ekcvww6_dup_i9ea8f64120'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6kgo8hd80_dup_i6kigfzs229_dup_i6qm3oyj323_dup_i6ruoykd73_dup_i721db34304_dup_i7ekcvww6_dup_i9ea8f64120'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9ea8f64'
                        }, {
                            'name': 'NewsPostsView_i6kgo8hd80_dup_i6kigfzs229_dup_i6qm3oyj323_dup_i6ruoykd73_dup_i721db34304_dup_i7ekcvww6_dup_i9ea8f64120',
                            'forType': 'NewsPosts_i6kgo8hc79',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i721hau8373',
                                            'data': 'i6m0pxog',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 733,
                                                'height': 440,
                                                'items': [{
                                                    'id': 'fld_i721hau8373_proxy',
                                                    'data': 'i6m0pxog',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 15,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left',
                                                'hidden': true
                                            }
                                        }, {
                                            'id': 'fld_i721eeox325',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 733,
                                                'height': 320,
                                                'items': [{
                                                    'id': 'fld_i721eeox325_proxy',
                                                    'data': 'image',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 15,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {'id': 'topSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'fld_i98829ay67',
                                                    'data': 'i988299v',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i98829ay67_proxy',
                                                            'data': 'i988299v',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mmm dd, yyyy',
                                                                'style': 'Heading S',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 20
                                                        },
                                                        'hidden': false
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hfnxgxqo',
                                                    'data': 'Strng_sTxt1',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfnxgxqo_proxy',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'italic': false,
                                                                'underline': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 20
                                                        }
                                                    }
                                                }, {
                                                    'experiment': 'NewListFields',
                                                    'id': 'fld_i988l4iw51',
                                                    'data': 'i988l4hj',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i988l4iw51_proxy',
                                                            'data': 'i988l4hj',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 12
                                                        },
                                                        'hidden': true
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 20
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': 180,
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'bold': false,
                                                                'italic': false,
                                                                'underline': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'labelPosition': 'none',
                                                        'hidden': false,
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_hfxs75de',
                                                    'data': 'wxRchTxt_sTxt0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfxs75de_proxy',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body S',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 10,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'labelPosition': 'none',
                                                        'hidden': false
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'pack': 'start'
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '3'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'fld_hhq9fct831',
                                                    'data': 'Strng_sBttn0-v1c',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'items': [{
                                                            'id': 'fld_hhq9fct831_proxy',
                                                            'data': 'Strng_sBttn0-v1c',
                                                            'comp': {'name': 'Button2', 'style': 'b1'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'hidden': false,
                                                        'height': 40,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 25,
                                                            'after': 3,
                                                            'xax-before': 34,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'end'
                                                    }
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        }
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }, {
                            'name': 'NewsPostsView_i6kgo8hd80_dup_i6kigfzs229_dup_i6qm3oyj323_dup_i6ruoykd73_dup_i721db34304_dup_i7ekcvww6_dup_i9ea8f64120',
                            'forType': 'NewsPosts_i6kgo8hc79',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i721hau8373',
                                            'data': 'i6m0pxog',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 220,
                                                'items': [{
                                                    'id': 'fld_i721hau8373_proxy',
                                                    'data': 'i6m0pxog',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 15,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left',
                                                'hidden': true
                                            }
                                        }, {
                                            'id': 'fld_i721eeox325',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 240,
                                                'items': [{
                                                    'id': 'fld_i721eeox325_proxy',
                                                    'data': 'image',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 15,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {'id': 'topSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'fld_i98829ay67',
                                                            'data': 'i988299v',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i98829ay67_proxy',
                                                                    'data': 'i988299v',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'mmm dd, yyyy',
                                                                        'style': 'Heading S',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': false
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hfnxgxqo',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hfnxgxqo_proxy',
                                                                    'data': 'Strng_sTxt1',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'underline': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'experiment': 'NewListFields',
                                                            'id': 'fld_i988l4iw51',
                                                            'data': 'i988l4hj',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i988l4iw51_proxy',
                                                                    'data': 'i988l4hj',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }, {
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'color': 'color_14',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'underline': false
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'labelPosition': 'none',
                                                                'hidden': false,
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_hfxs75de',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hfxs75de_proxy',
                                                                    'data': 'wxRchTxt_sTxt0',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body S',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 10,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'labelPosition': 'none',
                                                                'hidden': false
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 3}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_3',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_3',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': [{
                                                            'id': 'fld_hhq9fct831',
                                                            'data': 'Strng_sBttn0-v1c',
                                                            'comp': {
                                                                'name': 'Field',
                                                                'width': 280,
                                                                'items': [{
                                                                    'id': 'fld_hhq9fct831_proxy',
                                                                    'data': 'Strng_sBttn0-v1c',
                                                                    'comp': {'name': 'Button2', 'style': 'b1'},
                                                                    'layout': {'position': 'relative'}
                                                                }],
                                                                'hidden': false,
                                                                'height': 44,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 2,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'box-align': 'center'
                                                            }
                                                        }]
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        }
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true, 'originalWidth': 601},
                            'format': 'Mobile'
                        }], 'items': [{
                            'title': i18n.translate('i9ea8f7v.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'c7ad2b64020342d0ae1a1feace158fe9.jpg',
                                'width': 1920,
                                'height': 1280,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i9ea8f7v.structure.custom.template.items.0.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ea8f7v.structure.custom.template.items.0.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i9ea8f7v.structure.custom.template.items.0.Strng_sBttn0-v1c'),
                            'i6m0pxog': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i988299v': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i988l4hj': i18n.translate('i9ea8f7v.structure.custom.template.items.0.i988l4hj')
                        }, {
                            'title': i18n.translate('i9ea8f7v.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'd967ba93f0314c78924edc8a8c8cfa15.jpg',
                                'width': 1920,
                                'height': 1275,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i9ea8f7v.structure.custom.template.items.1.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ea8f7v.structure.custom.template.items.1.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i9ea8f7v.structure.custom.template.items.1.Strng_sBttn0-v1c'),
                            'i6m0pxog': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i988299v': {'_type': 'wix:Date', 'iso': '2023-02-28T00:00:00.000Z'},
                            'i988l4hj': i18n.translate('i9ea8f7v.structure.custom.template.items.1.i988l4hj')
                        }, {
                            'title': i18n.translate('i9ea8f7v.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'bef2ef0f0a3343aba2fa7e746baa781c.jpg',
                                'width': 4434,
                                'height': 3729,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i9ea8f7v.structure.custom.template.items.2.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ea8f7v.structure.custom.template.items.2.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i9ea8f7v.structure.custom.template.items.2.Strng_sBttn0-v1c'),
                            'i6m0pxog': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i988299v': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i988l4hj': i18n.translate('i9ea8f7v.structure.custom.template.items.2.i988l4hj')
                        }], 'displayName': i18n.translate('i9ea8f7v.structure.custom.template.displayName')
                    }
                },
                'id': 'i9ea8f7v'
            },
            'preset': {
                'rect': {
                    'width': 162, 'height': 120, 'x': 162, 'y': 177
                },
                'label': 'i9ea8f7v.structure.custom.template.displayName', 'tags': null
            }
        }
        ],
        'compTypes': ['wixapps.integration.components.AppPart2']
    }, 'help': {
        'hide': false, 'text': 'add_section_info_text_events'
    }
}
