import { cx, fixedStage } from '@/util';
import React from 'react';
import { UpperDeck } from './decs';

function previewModeLayout(this: AnyFixMe) {
  const isFluidPreview = fixedStage.isFixedStageEnabled();

  return (
    <>
      <UpperDeck>
        <div
          className={cx({
            'top-bar-left-section': true,
            'with-separators': !isFluidPreview,
          })}
        >
          {this.renderLogo()}
          {this.renderLanguageSelect()}
          {isFluidPreview
            ? this.renderNotification()
            : this.renderDeviceSwitch()}
        </div>
        <div
          className={cx('top-bar-center-section', {
            'with-separators': isFluidPreview,
          })}
        >
          {isFluidPreview
            ? this.renderDeviceSwitch()
            : this.renderNotification()}
        </div>
        <div className="top-bar-right-section">
          {this.renderPreviewModeActionsButtons()}
        </div>
      </UpperDeck>
    </>
  );
}

export default previewModeLayout;
