// @ts-nocheck
import React from 'react';
import { OldMessagePanelFrame } from '../frames';

export default class extends React.Component {
  static displayName = 'exampleMessage';

  onConfirm = () => {
    console.info('onConfirm called');
  };

  render() {
    return (
      <OldMessagePanelFrame
        panelName={this.props.panelName}
        width="500"
        height="500"
        onConfirm={this.onConfirm}
        confirmLabel="OK"
        title="Hello"
      >
        <div>content here</div>
      </OldMessagePanelFrame>
    );
  }
}
