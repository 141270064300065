import React from 'react';
import { useSelectedApp } from '../selectedAppContext/selectedAppContext';
import { GreyLabel, BoldText } from '../helperComponents/helperComponents';
import styles from './appReleaseNotes.scss';
import { translate } from '@/i18n';

export const AppReleaseNotes: React.FC = () => {
  const { listNotes, codePackage } = useSelectedApp().selectedApp;
  const [userName] = codePackage?.name?.split('/') || [];

  return (
    <div className={styles.container} data-hook="pac-release-notes">
      <div className={styles.title}>
        <BoldText>
          blocks-private-apps.AppDiscovery_App_Details_Release_Notes_Title
        </BoldText>
        {userName && (
          <GreyLabel size={'medium'}>
            &nbsp;|{' '}
            {translate(
              'blocks-private-apps.AppDiscovery_App_Details_Created_By_User',
              { userName },
            )}
          </GreyLabel>
        )}
      </div>
      {listNotes &&
        listNotes.map((note) => (
          <div data-hook="pac-release-notes-line" className={styles.content}>
            {note}
          </div>
        ))}
    </div>
  );
};
