import * as util from '@/util';
import constants from '@/constants';
import { translate as t } from '@/i18n';
import { Button, Divider } from '@wix/wix-base-ui';
import React, { useEffect, useState } from 'react';
import styles from './siteCreationNavigationEndScreen.scss';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import SiteCreationFPDThumbnail from './siteCreationFPDThumbnail';
import HorizontalLoader from './horizontalLoader';
import SiteCreationBottomSection from './siteCreationBottomSection';
import {
  BF_ACTIONS,
  END_SCREEN_LOADER_TEXT_1,
  END_SCREEN_LOADER_TEXT_2,
  END_SCREEN_LOADER_TEXT_3,
} from './siteCreationConstants';

import type { SiteCreationProps } from './siteCreationPagesView';
import type { PageAlternative } from '@/presetApi';
import { getScale } from './siteCreationUtils';

export interface SiteCreationNavigationEndScreenProps
  extends SiteCreationProps {
  headerPresetNode: any;
  footerPresetNode: any;
  endScreenVisible: boolean;
  pagePreset: PageAlternative;
  creationFinishPromise: Promise<void>;
  setIsWizardVisible: (isVisible: boolean) => void;
  setIsSiteCreationUiVisible: () => void;
}

const { SITE_CREATION: SITE_CREATION_CONSTS } = constants;

const SiteCreationNavigationEndScreen: React.FC<
  SiteCreationNavigationEndScreenProps
> = ({
  pagePreset,
  publishSite,
  windowWidth,
  windowHeight,
  openLiveSite,
  handleBackToBo,
  endScreenVisible,
  headerPresetNode,
  footerPresetNode,
  setIsWizardVisible,
  fireContentScrolled,
  fireViewStartLoading,
  fireViewContentLoaded,
  creationFinishPromise,
  fireBusinessFirstAction,
  sendWizardFinishedBi,
  setLastStepSiteCreationFinished,
  setIsSiteCreationUiVisible,
}) => {
  const [isScreenVisible, setScreenVisible] = useState<boolean>(true);
  const [loaderText, setLoaderText] = useState<string>(
    END_SCREEN_LOADER_TEXT_1,
  );
  const [isCreationFinished, setIsCreationFinished] = useState<boolean>(false);

  useEffect(() => {
    if (creationFinishPromise) {
      creationFinishPromise.then(async () => {
        setLoaderText(END_SCREEN_LOADER_TEXT_3);
        await publishSite();
        pagePreset.presetPreviews.unshift(headerPresetNode);
        pagePreset.presetPreviews.push(footerPresetNode);
        setIsCreationFinished(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creationFinishPromise, publishSite]);

  useEffect(() => {
    if (isCreationFinished) {
      fireViewContentLoaded(SITE_CREATION_CONSTS.SCREENS.BUSINESS_CARD);
      sendWizardFinishedBi();
      return () => {
        setIsSiteCreationUiVisible();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreationFinished, fireViewContentLoaded]);

  useEffect(() => {
    if (endScreenVisible) {
      fireViewStartLoading(SITE_CREATION_CONSTS.SCREENS.BUSINESS_CARD);
      window.setTimeout(() => {
        setLoaderText(END_SCREEN_LOADER_TEXT_2);
      }, 3000);
    }
  }, [endScreenVisible, fireViewStartLoading]);

  const handleEditSite = () => {
    setScreenVisible(false);
    setIsWizardVisible(false);
    setTimeout(() => {
      setLastStepSiteCreationFinished();
    }, 300);
    fireBusinessFirstAction(BF_ACTIONS.GO_TO_EDITOR);
  };

  const handleGoToLive = () => {
    fireBusinessFirstAction(BF_ACTIONS.VIEW_LIVE_SITE);
    openLiveSite();
  };

  const handleBackToDashboard = () => {
    fireBusinessFirstAction(BF_ACTIONS.BACK_TO_DASHBOARD);
    handleBackToBo();
  };

  const getBackToDashboardButton = (isInverted: boolean) => (
    <Button
      className={
        isInverted ? 'btn-inverted' : styles.siteCreationBackToBoAction
      }
      onClick={handleBackToDashboard}
    >
      <span>{t('site_creation_business_first_success_dashboard_cta')}</span>
    </Button>
  );

  const getGoToEditorButton = (isInverted: boolean) => (
    <Button
      className={
        isInverted ? 'btn-inverted' : styles.siteCreationBackToBoAction
      }
      onClick={handleEditSite}
    >
      <span>{t('site_creation_business_first_success_editor_cta')}</span>
    </Button>
  );

  return (
    <div
      className={util.cx(styles.siteCreationNavigationEndScreen, {
        hidden: !isScreenVisible,
      })}
      data-hook="navigation-end-screen"
    >
      {isCreationFinished ? (
        <div className={styles.siteCreationNavigationEndContentWrapper}>
          <div className={styles.siteCreationNavigationEndActions}>
            <div className={styles.siteCreationNavigationEndMainActionsWrapper}>
              <div>
                <span className={styles.siteCreationNavigationEndTitle}>
                  {t('site_creation_business_first_success_first_title')}
                </span>
              </div>
              <div>
                <span className={styles.siteCreationNavigationEndSubTitle}>
                  {t('site_creation_business_first_success_second_title')}
                </span>
              </div>
              {getBackToDashboardButton(false)}
              {getGoToEditorButton(true)}
            </div>
            <Divider long={true} />
            <div
              className={styles.siteCreationGoToLive}
              onClick={handleGoToLive}
            >
              <span>
                {t('site_creation_business_first_success_view_live_cta')}
              </span>
              <Symbol name="arrowRightThin" />
            </div>
          </div>
          <div className={styles.siteCreationNavigationEndThumbnailWrapper}>
            <SiteCreationFPDThumbnail
              scale={getScale(windowWidth, 0.4)}
              presetIndex={0}
              pagePreset={pagePreset}
              thumbnailHeight={windowHeight / 2}
              fireContentScrolled={fireContentScrolled}
              isSelected={false}
              nonInteractive={true}
            />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.siteCreationNavigationEndLoadContentWrapper}>
            <span className={styles.siteCreationNavigationEndTitle}>
              {t(loaderText)}
            </span>
          </div>
          <HorizontalLoader shouldShow={endScreenVisible} />
          <SiteCreationBottomSection />
        </>
      )}
    </div>
  );
};

export default SiteCreationNavigationEndScreen;
