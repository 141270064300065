function isList(data) {
  return Boolean(data?.propertyValList?.value);
}

function isMap(data) {
  return Boolean(data?.propertyValMap?.mapVal);
}

function parseList(data) {
  const list = data.propertyValList.value;

  return list.map(parseValue);
}

function parseMap(data) {
  const map = data.propertyValMap.mapVal;
  const result: Record<string, any> = {};

  for (const key of Object.keys(map)) {
    result[key] = parseValue(map[key]);
  }

  return result;
}

function parsePrimitive(data) {
  const [value] = Object.values(data);
  return value;
}

function parseValue(data) {
  let result;

  if (isMap(data)) {
    result = parseMap(data);
  } else if (isList(data)) {
    result = parseList(data);
  } else {
    result = parsePrimitive(data);
  }

  return result;
}

export function parseProtoOutput(data) {
  return parseValue(data);
}
