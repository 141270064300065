:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._animationPanel_uiz6h_7 {
  background: linear-gradient(90deg, #206aff 12px, transparent 1%) center, linear-gradient(#206aff 12px, transparent 1%) center, #5c92fd;
  background-size: 14px 14px;
  position: relative;
  border-radius: 0 4px 4px 0;
  width: 50vw;
  min-width: 50%;
  overflow: hidden; }
  ._animationPanel_uiz6h_7 ._bgWrapper_uiz6h_15::after {
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background: linear-gradient(195deg, transparent 46.42%, #1357fa 65.41%, #0535d2 86.05%);
    opacity: 0.4; }
  ._animationPanel_uiz6h_7 svg {
    transform: translate3d(0px, -45px, 0px) !important;
    aspect-ratio: 1/1; }
  ._animationPanel_uiz6h_7 ._card_uiz6h_27 {
    position: absolute;
    top: -2px;
    right: -2px;
    aspect-ratio: 1 /1;
    width: 40%;
    background: rgba(255, 255, 255, 0.14);
    border: 1px solid rgba(255, 255, 255, 0.26);
    box-shadow: -1px 1px 1px rgba(255, 255, 255, 0.27);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    border-radius: 0 0 0 1.5vw; }
    ._animationPanel_uiz6h_7 ._card_1_uiz6h_38, ._animationPanel_uiz6h_7 ._card_2_uiz6h_38 {
      position: absolute;
      top: -69px;
      right: -4px;
      aspect-ratio: 1 /1;
      width: 40%;
      background: rgba(255, 255, 255, 0.14);
      border: 1px solid rgba(255, 255, 255, 0.26);
      box-shadow: -1px 1px 1px rgba(255, 255, 255, 0.27);
      -webkit-backdrop-filter: blur(4px);
              backdrop-filter: blur(4px);
      border-radius: 0 0 0 1.5vw; }
    ._animationPanel_uiz6h_7 ._card_1_uiz6h_38::after {
      content: '';
      background: #5ff2aeb8;
      filter: blur(80px);
      width: 70%;
      aspect-ratio: 1 /1;
      position: absolute;
      border-radius: 0 0 0 50%;
      top: -40px;
      right: -90px; }
    ._animationPanel_uiz6h_7 ._card_2_uiz6h_38 {
      left: -1px;
      bottom: -1px;
      aspect-ratio: 1 / 0.5;
      top: unset;
      border-radius: 0 60px 0 0;
      width: 20%; }
