import sendBIAndActionUtil from './sendBIAndActionUtil';
import * as coreBi from '@/coreBi';
import * as util from '@/util';
import constants from '@/constants';
import { fedopsLogger, editorWixRecorder, publishUtil } from '@/util';
import * as stateManagement from '@/stateManagement';
const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;
import type { EditorAPI } from '@/editorAPI';
import type { PublishInteractionStartedSource } from 'types/fedops/publishInteraction';

function undoFromKeyboard(editorAPI: EditorAPI): void {
  reportUndoRedoBI(editorAPI, 'undo');

  editorAPI.history.undo();
}

function redoFromKeyboard(editorAPI: EditorAPI): void {
  reportUndoRedoBI(editorAPI, 'redo');

  editorAPI.history.redo();
}

function reportUndoRedoBI(editorAPI: EditorAPI, type: AnyFixMe): void {
  const biParams =
    type === 'undo'
      ? editorAPI.documentServices.history.getUndoLastSnapshotParams()
      : editorAPI.documentServices.history.getRedoLastSnapshotParams();
  sendBIAndActionUtil(
    editorAPI,
    coreBi.events.editor.undo_redo_component_change,
    {
      type,
      origin: 'keyboard',
      pageId: biParams?.currentPage,
      date_of_action: biParams?.date_of_action,
      actionName: biParams?.label,
      component_id: biParams?.component_id,
      component_type: biParams?.component_type,
    },
  );
}

function saveSiteFromKeyboard(editorAPI: EditorAPI): void {
  editorAPI.saveManager.save({
    origin: 'keyboard',
    sourceOfStart: 'keyboard_saveSiteShortcut',
  });
}

function saveSiteAsTemplateFromKeyboard(editorAPI: EditorAPI): void {
  editorAPI.saveManager.saveAsTemplate(function () {
    console.info('Saved successfully as Template.');
  });
}

function publishSiteFromKeyboard(
  editorAPI: EditorAPI,
  event: KeyboardEvent,
): void {
  if (!editorAPI.savePublish.canUserPublish()) {
    return;
  }

  event.preventDefault();
  editorWixRecorder.addLabel('publish click');
  const currentViewMode = publishUtil.getPublishViewMode(
    editorAPI.isMobileEditor(),
    editorAPI.preview.isInPreviewMode(),
  );

  const numberOfManualPublish = getSiteUserPreferences('numberOfManualPublish')(
    editorAPI.store.getState(),
  ) as number;
  editorAPI.bi.event(coreBi.events.publish.PUBLISH_CLICK, {
    origin: 'keyboard',
    viewModeSource: currentViewMode,
    numberOfManualPublish: numberOfManualPublish
      ? numberOfManualPublish + 1
      : 1,
  });

  const sourceOfStart: PublishInteractionStartedSource =
    'keyboard_publishShortcut';

  // NOTE: if "shouldSaveBeforePublish", instead of publish, there will be save and save success panel. Then user should click "publish" click on the panel.
  if (!editorAPI.publishManager.shouldSaveBeforePublish()) {
    // NOTE: https://github.com/wix-private/santa-editor/issues/37029
    fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.PUBLISH, {
      paramsOverrides: {
        sourceOfStart,
      },
    });
  }

  editorAPI.publishManager.saveAndPublish({
    origin: 'keyboard_publish',
    sourceOfStart,
  });
}

function togglePreviewFromKeyboard(editorAPI: EditorAPI, event: KeyboardEvent) {
  event.preventDefault();
  util.editorWixRecorder.addLabel('preview click');
  editorAPI.bi.event(coreBi.events.preview.PREVIEW_CLICK, {
    origin: constants.BI.PREVIEW.ORIGIN.KEYBOARD,
  });
  editorAPI.preview.togglePreview(undefined, {
    biParams: { origin: constants.BI.PREVIEW.ORIGIN.KEYBOARD },
  });
}
function publishRCFromKeyboard(editorAPI: EditorAPI, event: KeyboardEvent) {
  event.preventDefault();
  util.editorWixRecorder.addLabel('test site click');

  editorAPI.bi.event(coreBi.events.topbar.top_bar_click, {
    origin: 'keyboard',
    src: 38,
    category: 'test_site',
    state: util.fixedStage.getTopBarStateBiParamValue(editorAPI),
  });
  editorAPI.publishManager.publishRC();
}

export {
  undoFromKeyboard,
  redoFromKeyboard,
  saveSiteFromKeyboard,
  saveSiteAsTemplateFromKeyboard,
  publishSiteFromKeyboard,
  togglePreviewFromKeyboard,
  publishRCFromKeyboard,
};
