import type { Scope } from '@/sections';
import { fixSingleSectionOverheight } from './fixSingleSectionOverheight';
import { ErrorReporter } from '@wix/editor-error-reporter';

import experiment from 'experiment';

type PageNavigationHook = (pageId: string) => Promise<void>;

export const createAfterPageNavigateEnforcement = (
  scope: Scope,
): PageNavigationHook => {
  return async (pageId: string) => {
    const { editorAPI } = scope;
    const pageRef = editorAPI.pages.getReference(pageId);

    if (experiment.isOpen('se_fixSectionNegativeYPosition')) {
      try {
        await fixSingleSectionOverheight(scope, pageRef, false);
      } catch (error: MaybeError) {
        error.name = `[SectionsAfterNavigateEnforcement]: ${error.name}`;
        ErrorReporter.captureException(error);
      }
    }
  };
};
