// @ts-nocheck
import _ from 'lodash';
import savePublishErrorPanelData from '../constants/savePublishErrorPanelData';
import { translate } from '@/i18n';
import constants from '@/constants';
import type { SaveInteractionStartedSource } from 'types/fedops/saveInteraction';
import type { SiteWasSavedPanelProps } from '../panels/save/siteWasSavedPanel';

const FREE_DOMAIN_PREFIX = 'http://www.wix.com/';

const PanelNames = {
  CHOOSE_DOMAIN: 'savePublish.panels.save.chooseDomainPanel',
  SITE_WAS_SAVED: 'savePublish.panels.save.siteWasSavedPanel',
  SAVE_FAILED: 'savePublish.panels.common.failPanel',
  CONCURRENT_SAVE: 'savePublish.panels.save.concurrentSavePanel',
};

let editorAPI = null;
let mockSiteState = null;

function resetSiteState(configuration) {
  mockSiteState = _.clone(configuration);
}

function updateSiteState(state) {
  mockSiteState = _.merge(mockSiteState || {}, state);
}

async function generateUniqueSiteName() {
  return new Promise((resolve, reject) => {
    editorAPI.siteName.generate(
      translate('Save_Publish_Site_Default_Name'),
      resolve,
      reject,
    );
  });
}

async function openChooseDomainPanel() {
  const panelProps = {
    defaultSiteName: await generateUniqueSiteName(),
    freeDomainPrefix: FREE_DOMAIN_PREFIX,
    connectDomainHandler: openConnectDomainWindow,
    freeDomainHandler: connectFreeDomain,
  };
  editorAPI.panelManager.openPanel(PanelNames.CHOOSE_DOMAIN, panelProps, true);
}

function openConnectDomainPanel() {
  editorAPI.panelManager.openPanel(
    'savePublish.panels.connectDomain',
    {
      title: 'SAVE_SITE_SAVED_TITLE_TITLE',
      subtitle: 'PREMIUM_TITLE_SUBTITLE',
      onConnectDomainFlowEnded: () =>
        openSiteWasSavedPanel({
          overrideTitle: 'SAVE_SITE_SAVED_WELCOME_BACK_TITLE',
          overrideSubtitle: 'SAVE_SITE_SAVED_WELCOME_BACK_SUBTITLE',
        }),
      isFirstSave: true,
      isFirstPublish: true,
      isPremium: mockSiteState.isSitePremium,
      flowId: constants.CONNECT_DOMAIN.FLOWS.SAVE,
    },
    true,
  );
}

function openFailPanel() {
  editorAPI.panelManager.openPanel(
    PanelNames.SAVE_FAILED,
    savePublishErrorPanelData.SAVE_ERROR_DEFAULT_PANEL_DATA,
    true,
  );
}

function openSiteWasSavedPanel({
  overridingSiteState,
  overrideTitle,
  overrideSubtitle,
  sourceOfStart,
}: {
  overridingSiteState?: SitePremiumState;
  overrideTitle?: string;
  overrideSubtitle?: string;
  sourceOfStart?: SaveInteractionStartedSource;
} = {}) {
  const panelProps: SiteWasSavedPanelProps = {
    ..._.clone(mockSiteState),
    ...overridingSiteState,
    isMockSave: true,
    overrideTitle,
    overrideSubtitle,
    sourceOfStart,
  };
  editorAPI.panelManager.openPanel(PanelNames.SITE_WAS_SAVED, panelProps, true);
}

function connectFreeDomain(chosenSiteName) {
  if (mockSiteState.shouldSaveSucceed) {
    performFirstSave();
    updateSiteState({ domain: FREE_DOMAIN_PREFIX + chosenSiteName });
    openSiteWasSavedPanel();
  } else {
    openFailPanel();
  }
}

function openConnectDomainWindow() {
  if (mockSiteState.shouldSaveSucceed) {
    performFirstSave();
    openConnectDomainPanel();
  } else {
    openFailPanel();
  }
}

function performFirstSave() {
  console.info('First Save Was Performed!');
}

function performSave() {
  console.info('Save Was Performed!');
}

function save(_editorAPI) {
  editorAPI = _editorAPI;

  async function startSave(configuration) {
    if (!configuration.isDomainConnected) {
      configuration.domain =
        FREE_DOMAIN_PREFIX + (await generateUniqueSiteName());
    }

    resetSiteState(configuration);

    if (configuration.isFirstSave) {
      openChooseDomainPanel();
    } else if (configuration.shouldSaveSucceed) {
      performSave();
      openSiteWasSavedPanel({
        overrideTitle: 'SAVE_SITE_SAVED_SMALL_TITLE_TITLE',
        overrideSubtitle: 'SAVE_SITE_SAVED_SMALL_TITLE_SUBTITLE',
      });
    } else {
      openFailPanel();
    }
  }

  editorAPI.panelManager.openPanel(
    'savePublish.panels.save.saveConfigurationPanel',
    { onConfirm: startSave },
    true,
  );
}

export { save };
