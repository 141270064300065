import type constants from '@/constants';
import { http } from '@/util';

const { fetchRetry } = http;
const API_URL = 'https://editor.wix.com/_api/editor-my-elements/v1/elements';

interface ResponseCollectionElement {
  createdAt: string;
  elementUrl: string;
  id: string;
  name: string;
  preview: {
    templateDataUrl: string;
    viewport: {
      height: number;
      width: number;
    };
  };
  ttlSeconds: number;
  updatedAt: string;
}

interface SavedComponent {
  id: string;
  name: string;
  previewUrl: string;
  sourceUrl: string;
  previewDimension: {
    height: number;
    width: number;
  };
}

type CollectionType = typeof constants.COMP_TYPES.SECTION | 'my-design';

const fetchCollection = (
  metaSiteInstance: string,
  type: CollectionType = 'my-design',
): Promise<SavedComponent[]> =>
  http
    .fetchJson(`${API_URL}/query`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: new Headers({
        Authorization: metaSiteInstance,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        paging: { limit: 100 },
        filter: { type },
      }),
    })
    .then(({ elements }: { elements: ResponseCollectionElement[] }) => {
      if (!Array.isArray(elements)) {
        return [];
      }

      return elements.map(
        ({ id, name, preview: { viewport, templateDataUrl }, elementUrl }) => ({
          id,
          name,
          previewUrl: templateDataUrl,
          previewDimension: viewport,
          sourceUrl: elementUrl,
        }),
      );
    });

const fetchItemPreview = (url: string) =>
  http.fetchJson(url).then((data: AnyFixMe) => data.body);

const fetchItemSource = (url: string) => http.fetchJson(url);

const deleteItem = (metaSiteInstance: AnyFixMe, id: AnyFixMe) =>
  fetchRetry(`${API_URL}/${id}`, {
    method: 'DELETE',
    contentType: 'application/json',
    credentials: 'same-origin',
    headers: new Headers({ Authorization: metaSiteInstance }),
  });

const renameItem = (metaSiteInstance: AnyFixMe, id: AnyFixMe, name: AnyFixMe) =>
  fetchRetry(`${API_URL}/${id}`, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: new Headers({
      Authorization: metaSiteInstance,
      'content-type': 'application/json; charset=utf-8',
    }),
    body: JSON.stringify({ id, name }),
  });

const postItem = (
  metaSiteInstance: string,
  name: string,
  { source, previewDimension, previewData, previewTemplate, type }: AnyFixMe,
) =>
  fetchRetry(API_URL, {
    method: 'POST',
    credentials: 'same-origin',
    headers: new Headers({
      Authorization: metaSiteInstance,
      'content-type': 'application/json; charset=utf-8',
    }),
    body: JSON.stringify({
      name,
      elementJson: JSON.stringify(source),
      preview: {
        viewport: previewDimension,
        templateDataJson: JSON.stringify(previewData),
        templateHtml: previewTemplate,
      },
      type,
    }),
  })
    .then((response: AnyFixMe) => response.json())
    .then(({ id }: AnyFixMe) => id);

export {
  fetchCollection,
  fetchItemPreview,
  fetchItemSource,
  renameItem,
  postItem,
  deleteItem,
};
