import React from 'react';
import { Text, TextButton, Checkbox } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import * as panels from '@/panels';
import styles from './publishRCPreparePanel.scss';

interface PublishRCPreparePanelProps {
  onCancel: Function;
  onConfirm: Function;
  onLearnMore: React.MouseEventHandler;
  panelName: string;
  dontShowAgainKey: string;
  dontShowAgain?: boolean;
  toggleDontShowAgain?: () => void;
  userPrefType: string;
}

interface PublishRCPreparePanelState {
  dontShowAgain: boolean;
}

class PublishRCPreparePanel extends React.Component<
  PublishRCPreparePanelProps,
  PublishRCPreparePanelState
> {
  constructor(props: PublishRCPreparePanelProps) {
    super(props);
    this.state = {
      dontShowAgain: props.dontShowAgain,
    };
  }

  onCancel = (origin: any) => {
    this.props.onCancel(origin);
  };
  onConfirm = () => {
    this.props.onConfirm();
  };
  getBulletsList = (bulletsList: string[]) => {
    return (
      <ul className="bullets-list">
        {bulletsList.map((bulletText, index) => (
          <li className={styles.bulletItem} key={index}>
            <Text shouldTranslate={false}>{'• '}</Text>
            <div className={styles.bulletItemText}>
              <Text
                size="medium"
                weight="normal"
                skin="secondary"
                enableEllipsis={false}
                shouldTranslate={false}
              >
                {translate(bulletText)}
              </Text>
            </div>
          </li>
        ))}
      </ul>
    );
  };
  render() {
    const bulletsList = [
      'WixCode_TestSite_FirstTimeNotes_Modal_Description1',
      'WixCode_TestSite_FirstTimeNotes_Modal_Description2',
      'WixCode_TestSite_FirstTimeNotes_Modal_Description3',
    ];
    return (
      <panels.frames.MessagePanelFrame
        dataHook={'publish-rc-prepare-panel'}
        panelName={this.props.panelName}
        className={'prepare-panel'}
        title={translate('WixCode_TestSite_FirstTimeNotes_Modal_Title')}
        cancelLabel={translate(
          'WixCode_TestSite_FirstTimeNotes_Modal_SecondaryCTA',
        )}
        confirmLabel={translate(
          'WixCode_TestSite_FirstTimeNotes_Modal_PrimaryCTA',
        )}
        onConfirm={this.onConfirm}
        onCancel={this.onCancel}
        dontShowAgainKey={this.props.dontShowAgainKey}
        sideActions={
          <Checkbox
            labelAfterSymbol={true}
            label={translate('Notification_Box_Dont_Show_Again')}
            shouldTranslate={false}
            value={this.state.dontShowAgain}
            onChange={() => {
              this.props.toggleDontShowAgain();
              this.setState((prevState) => ({
                dontShowAgain: !prevState.dontShowAgain,
              }));
            }}
            dataHook="MessagePanelFrame-dont-show-again"
          />
        }
        userPrefType={this.props.userPrefType}
        illustration={null}
      >
        {this.getBulletsList(bulletsList)}
        <div className="learn-more">
          <TextButton
            size="medium"
            weight="thin"
            shouldTranslate={false}
            onClick={this.props.onLearnMore}
          >
            {translate('WixCode_TestSite_FirstTimeNotes_Modal_LearnMore_Text')}
          </TextButton>
        </div>
      </panels.frames.MessagePanelFrame>
    );
  }
}
export default PublishRCPreparePanel;
