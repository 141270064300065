export const HEAVY_SITES = {
  NOTIFICATION_SKIPPED_THIRD:
    'FTE_tour_ed_to_ed2_heavy_sites_no_offline_skip_notifications_text',
  NOTIFICATION_TECH_ERROR:
    'FTE_tour_ed_to_ed2_heavy_sites_tech_error_notifications_text',
  NOTIFICATION_TIME_OUT:
    'FTE_tour_ed_to_ed2_heavy_sites_time_out_notifications_text',
  NOTIFICATION_SUCCESS_MIGRATION:
    'FTE_tour_ed_to_ed2_heavy_sites_success_notifications_text',
  NOTIFICATION_SUCCESS_LINK_TEXT:
    'FTE_tour_ed_to_ed2_heavy_sites_success_notifications_link_text',
  NOTIFICATION_LEARN_MORE_LINK_TEXT: 'Notifications_Learn_More_Link',
};
