import _ from 'lodash';
import * as editor from './editor/editor';
import * as preview from './editor/preview/preview';
import * as vfs from './document/vfs/vfs';
import pages from './document/pages/pages';
import components from './document/components/components';
import permissions from './document/permissions/permissions';
import language from './document/language/language';
import * as routers from './document/routers/routers';
import * as history from './document/history/history';
import * as ide from './editor/ide/ide';
import extraNamespaces from './extraNamespaces/extraNamespaces';
import * as info from './editor/info/info';
import * as environment from './editor/environment/environment';
import * as userPreferences from './editor/userPreferences/userPreferences';
import * as accessibility from './editor/accessibility/accessibility';
import * as tpa from './document/tpa/tpa';
import * as application from './document/application/application';
import * as internalAPIs from './internalAPIs';
import * as selection from './editor/selection/selection';
import * as deeplink from './editor/deeplink/deeplink';
import * as developerMode from './editor/developerMode/developerMode';
import * as editorRouters from './editor/routers/routers';
import * as editorComponents from './editor/components/components';

const documentNamespace = Object.assign(
  {},
  {
    application,
    components,
    permissions,
    language,
    history,
    pages,
    routers,
    tpa,
    vfs,
  },
);

const editorNamespace = Object.assign({}, editor, {
  environment,
  ide,
  info,
  __internal__: internalAPIs,
  preview,
  selection,
  userPreferences,
  accessibility,
  deeplink,
  developerMode,
  routers: editorRouters,
  components: editorComponents,
});

const namespaces = {
  document: documentNamespace,
  editor: editorNamespace,
};

export default _.merge(
  namespaces,
  namespaces.document,
  namespaces.editor,
  extraNamespaces,
);
