import React from 'react';
import { hoc, media as mediaUtil } from '@/util';
import styles from './logoSection.scss';
import { ActionItem } from '@wix/wix-base-ui';
import { mapDispatchToProps } from './logoSectionMapper';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import type { LogoSectionDispatchProps } from './logoSectionMapper';
import type { LogoSection as ILogoSection } from '@wix/add-panel-common';

export interface LogoSectionOwnProps {
  section: ILogoSection;
}

export interface LogoSectionProps
  extends LogoSectionOwnProps,
    LogoSectionDispatchProps {}

const LogoSectionPure: React.FC<LogoSectionProps> = ({
  section,
  handleActionClick,
}) => (
  <ActionItem
    title={section.props.title}
    description={section.props.subTitle}
    illustration={
      <img
        className={styles.illustration}
        alt=""
        src={mediaUtil.getMediaUrl(
          'addPanelData/sections/logo/logoDefault.png',
        )}
      />
    }
    actionIcon={<Symbol name="plus" />}
    onClick={handleActionClick}
    dataHook="logo-section-component"
  />
);

export const LogoSection = hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(LogoSectionPure);
