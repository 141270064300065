'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_Vlines',
    "subNavigationTitle": "add_section_label_Vlines",
    "presetTitle": "add_section_label_Vlines",
    "tooltipTitle": "add_section_label_Vlines",
    "automationId": "add-panel-section-verticalLinesSection",
    "subNavigationHide": false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/verticalLinesSection_en/verticalLinesSection_en.png',
        'imageHover': null,
        'items': [{
            'id': 'Shape_Vertical_Lines_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.VerticalSolidLine',
                'layout': {
                    'width': 5,
                    'height': 304,
                    'x': 18,
                    'y': 30,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.VerticalLine',
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#424242', 'lnw': '1'},
                        'propertiesSource': {'brd': 'value', 'lnw': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.VerticalLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.VerticalSolidLine'
                },
                'activeModes': {},
                'id': 'comp-jgp80bx9'
            },
            'preset': {'rect': {'width': 37, 'height': 250, 'x': 0, 'y': 0}, 'tags': null}
        }, {
            'id': 'Shape_Vertical_Lines_2',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.VerticalSolidLine',
                'layout': {
                    'width': 5,
                    'height': 304,
                    'x': 49,
                    'y': 30,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.VerticalLine',
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#424242', 'lnw': '3', 'mrg': '3'},
                        'propertiesSource': {'brd': 'value', 'lnw': 'value', 'mrg': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.VerticalLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.VerticalSolidLine'
                },
                'activeModes': {},
                'id': 'comp-jgp80bwy'
            },
            'preset': {'rect': {'width': 25, 'height': 250, 'x': 36, 'y': 0}, 'tags': null}
        }, {
            'id': 'Shape_Vertical_Lines_3',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.VerticalSolidLine',
                'layout': {
                    'width': 5,
                    'height': 304,
                    'x': 80,
                    'y': 30,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.VerticalLine',
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#424242', 'lnw': '6', 'mrg': '6'},
                        'propertiesSource': {'brd': 'value', 'lnw': 'value', 'mrg': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.VerticalLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.VerticalSolidLine'
                },
                'activeModes': {},
                'id': 'comp-jgp80bwl'
            },
            'preset': {'rect': {'width': 30, 'height': 250, 'x': 62, 'y': 0}, 'tags': null}
        }, {
            'id': 'Shape_Vertical_Lines_4',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.VerticalSolidLine',
                'layout': {
                    'width': 5,
                    'height': 304,
                    'x': 112,
                    'y': 30,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.VerticalLine',
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#424242', 'lnw': '10', 'mrg': '11'},
                        'propertiesSource': {'alpha-brd': 'value', 'brd': 'value', 'lnw': 'value', 'mrg': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.VerticalLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.VerticalSolidLine'
                },
                'activeModes': {},
                'id': 'comp-jgp80bwc'
            },
            'preset': {'rect': {'width': 37, 'height': 250, 'x': 93, 'y': 0}, 'tags': null}
        }, {
            'id': 'Shape_Vertical_Lines_5',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.VerticalDottedLine',
                'layout': {
                    'width': 5,
                    'height': 304,
                    'x': 142,
                    'y': 30,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.VerticalLine',
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#1D2CF3', 'lnw': '5', 'mrg': '3px'},
                        'propertiesSource': {'alpha-brd': 'value', 'brd': 'value', 'lnw': 'value', 'mrg': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.VerticalLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.VerticalDottedLine'
                },
                'activeModes': {},
                'id': 'comp-jgp80bvc'
            },
            'preset': {'rect': {'width': 32, 'height': 250, 'x': 129, 'y': 0}, 'tags': null}
        }, {
            'id': 'Shape_Vertical_Lines_6',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.VerticalDashedLine',
                'layout': {
                    'width': 5,
                    'height': 304,
                    'x': 174,
                    'y': 30,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.VerticalLine',
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#B45AD3', 'lnw': '5', 'mrg': '3px'},
                        'propertiesSource': {'alpha-brd': 'value', 'brd': 'value', 'lnw': 'value', 'mrg': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.VerticalLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.VerticalDashedLine'
                },
                'activeModes': {},
                'id': 'comp-jgp80bvj'
            },
            'preset': {'rect': {'width': 33, 'height': 250, 'x': 161, 'y': 0}, 'tags': null}
        }, {
            'id': 'Shape_Vertical_Lines_7',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.VerticalDashedLine',
                'layout': {
                    'width': 5,
                    'height': 304,
                    'x': 207,
                    'y': 30,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.VerticalLine',
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#64D2B5', 'lnw': '3', 'mrg': '3px'},
                        'propertiesSource': {'alpha-brd': 'value', 'brd': 'value', 'lnw': 'value', 'mrg': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.VerticalLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.VerticalDashedLine'
                },
                'activeModes': {},
                'id': 'comp-jgp80bvt'
            },
            'preset': {'rect': {'width': 25, 'height': 250, 'x': 194, 'y': 0}, 'tags': null}
        }, {
            'id': 'Shape_Vertical_Lines_8',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.VerticalDashedLine',
                'layout': {
                    'width': 5,
                    'height': 304,
                    'x': 237,
                    'y': 30,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.VerticalLine',
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#FF4F4F', 'lnw': '1', 'mrg': '1'},
                        'propertiesSource': {'alpha-brd': 'value', 'brd': 'value', 'lnw': 'value', 'mrg': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.VerticalLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.VerticalDashedLine'
                },
                'activeModes': {},
                'id': 'comp-jgp80bw1'
            },
            'preset': {'rect': {'width': 30, 'height': 250, 'x': 219, 'y': 0}, 'tags': null}
        }, {
            'id': 'Shape_Vertical_Lines_9',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.VerticalDoubleLine',
                'layout': {
                    'width': 5,
                    'height': 304,
                    'x': 270,
                    'y': 30,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.VerticalLine',
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-brd': '1',
                            'brd': '#FFDE5F',
                            'dst': '5px',
                            'lnw1': '6',
                            'lnw2': '1px'
                        },
                        'propertiesSource': {
                            'alpha-brd': 'value',
                            'brd': 'value',
                            'dst': 'value',
                            'lnw1': 'value',
                            'lnw2': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.VerticalLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.VerticalDoubleLine'
                },
                'activeModes': {},
                'id': 'comp-jgp80bv5'
            },
            'preset': {'rect': {'width': 37, 'height': 250, 'x': 249, 'y': 0}, 'tags': null}
        }, {
            'id': 'Shape_Vertical_Lines_10',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.VerticalArrowLineTop',
                'layout': {
                    'width': 5,
                    'height': 304,
                    'x': 301,
                    'y': 30,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.VerticalLine',
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#424242'},
                        'propertiesSource': {'alpha-brd': 'value', 'brd': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.VerticalLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.VerticalArrowLineTop'
                },
                'activeModes': {},
                'id': 'comp-jgp80buy'
            },
            'preset': {'rect': {'width': 38, 'height': 250, 'x': 286, 'y': 0}, 'tags': null}
        }],
        'compTypes': ['wysiwyg.viewer.components.VerticalLine']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_Vlines'}
}
