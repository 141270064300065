import _ from 'lodash';
import React from 'react';
import {
  Composites,
  DropDown,
  DropDownOption,
  DropDownThemeOption,
  InfoIcon,
  TextLabel,
  Tooltip,
} from '@wix/wix-base-ui';

import * as util from '@/util';
import { translate } from '@/i18n';
import experiment from 'experiment';

import constants from '../../utils/constants';
import * as fontUtils from '../../utils/fontUtils';
import { textThemesUtils } from '@/textControls';

interface TextStyleProps {
  nullLabel?: string;
  stylesMap: typeof constants.TEXT_THEMES_STYLES_MAP;
  normalizedFontsSize?: Record<string, string>;
  themeColors: AnyFixMe;
  themeFonts: AnyFixMe;
  isDeveloperMode?: boolean;
  className?: string;
  value?:
    | {
        class: string;
      }
    | string;
  tooltipValue?: React.ReactNode;
  label: string;
  infoText: string;
  dropdownFooter?: React.ReactNode;
  optionsClass?: string;
  onChange: FunctionFixMe;
  onToggle: (isOpen: boolean) => void;
  onArrowKeyDown?: (e: React.ReactElement) => void;
  onOptionMouseEnter?: (e: React.ReactElement) => void;
}

class TextStyle extends React.Component<TextStyleProps> {
  static displayName = 'textStyle';

  static defaultProps: AnyFixMe = {
    dropdownFooter: null,
    stylesMap: constants.TEXT_THEMES_STYLES_MAP,
    toolbarMode: false,
    optionsWidth: 264,
    onToggle: _.noop,
  };

  getNullLabel() {
    return (
      this.props.nullLabel ||
      translate(
        'list_design_panel_Customize_TextField_CustomTheme_DropdownItem_Label',
      )
    );
  }

  getStyleOptions() {
    const { themeColors, themeFonts, isDeveloperMode } = this.props;

    // TODO remove this check when we move all to textThemes api
    const isTextThemeObj = typeof Object.values(themeFonts)[0] === 'object';

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(this.props.stylesMap, (style) => {
      const styleObject = isTextThemeObj
        ? textThemesUtils.parseTextThemeStyleMap(
            themeFonts[style.cssClass],
            themeColors,
          )
        : fontUtils.getStyleFont(style.cssClass, themeColors, themeFonts);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      const retVal = _.assign(
        {},
        styleObject,
        _.pick(style, ['seoTag', 'cssClass', 'displayName']),
      );
      if (
        experiment.isOpen('text_themes_sdk') &&
        isDeveloperMode &&
        /^font_[7-9]$/.test(retVal.cssClass)
      ) {
        const sdkSuffix = Number(retVal.cssClass.replace(/.*(\d)/, '$1')) - 6;
        retVal.seoTag = `p class="P${sdkSuffix}"`;
      }
      return retVal;
    });
  }

  getThemeOptions() {
    return this.getStyleOptions().map((style) => {
      const fontSize =
        this.props.normalizedFontsSize?.[style.cssClass] ?? style.size;
      const isSpx = style.size.includes('spx');
      return {
        key: style.cssClass,
        value: style.cssClass,
        label: translate(style.displayName),
        size: parseInt(fontSize, 10),
        color: style.cssColor,
        htmlTag: style.seoTag,
        theme: this.getDisplayStyle(style),
        selectedValueSuffix: this.props.className === 'custom-style' ? '*' : '',
        unit: isSpx ? 'px*' : 'px',
      };
    });
  }

  getDisplayStyle(style: AnyFixMe) {
    return {
      fontWeight: style.weight,
      fontFamily: `${style.family},${style.fontWithFallbacks}`,
      fontSize: parseInt(style.size, 10) <= 28 ? style.size : '28px',
      fontStyle: style.style,
    };
  }

  getValue() {
    const { value } = this.props;
    return (value as AnyFixMe)?.class || value;
  }

  isCustom() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    return !_.some(this.props.stylesMap, { cssClass: this.getValue() });
  }

  render() {
    return (
      <Tooltip
        content={this.props.tooltipValue}
        disabled={!this.props.tooltipValue}
      >
        <Composites.DropDownLabeled>
          {this.props.infoText ? (
            <InfoIcon shouldTranslate={true} text={this.props.infoText} />
          ) : null}
          <TextLabel value={this.props.label} shouldTranslate={true} />
          <DropDown
            automationId="themes-dropdown"
            value={this.getValue()}
            shouldTranslate={false}
            optionsContainerClassName={util.cx('themes-dropdown-options', {
              [this.props.optionsClass]: Boolean(this.props.optionsClass),
            })}
            // @ts-expect-error
            onChange={this.props.onChange}
            onToggle={this.props.onToggle}
            onArrowKeyDown={this.props.onArrowKeyDown}
            onOptionMouseEnter={this.props.onOptionMouseEnter}
          >
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
            {_.map(this.getThemeOptions(), (opt) => (
              <DropDownThemeOption {...opt} />
            ))}
            {this.isCustom() ? (
              <DropDownOption
                key="textStyleCustom"
                // @ts-expect-error
                value={this.props.value}
                label={this.getNullLabel()}
                optionWrapperClassName="custom-option"
              />
            ) : null}
            {this.props.dropdownFooter}
          </DropDown>
        </Composites.DropDownLabeled>
      </Tooltip>
    );
  }
}

export default TextStyle;
