import { wixIllustrationsSection } from './wixIllustrationsSection';
import { wixImagesSection } from './wixImagesSection';
import { uploadsAndImportsSection } from './uploadsAndImportsSection';
import { siteImagesSection } from './siteImagesSection';

export default {
  getCategorySections() {
    return {
      uploadsAndImportsSection,
      siteImagesSection,
      wixImagesSection,
      wixIllustrationsSection,
    };
  },
};
