import type { CompVariantPointer, CompRef } from 'types/documentServices';
import * as stateManagement from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';

const { setDefaultTransition } = stateManagement.interactions.actions;
const { isInInteractionMode, isShownOnlyOnVariant, getCompVariantPointer } =
  stateManagement.interactions.selectors;

function getSelectedCompVariantPointerIfExists(editorAPI: any) {
  const state = editorAPI.store.getState();
  return getCompVariantPointer(state);
}

export function removeRefIfEmptyStyle(
  editorAPI: EditorAPI,
  compRef: CompRef,
  variantPointer?: CompVariantPointer,
) {
  const state = editorAPI.store.getState();
  if (isInInteractionMode(state) && !isShownOnlyOnVariant(editorAPI, compRef)) {
    const pointerWithVariant =
      variantPointer || getSelectedCompVariantPointerIfExists(editorAPI);
    editorAPI.dsActions.components.stylable.removeRefIfEmptyStyle(
      pointerWithVariant,
    );
  }
}

export function createEmptyStyleStylable(
  editorAPI: EditorAPI,
  compRef: CompRef,
): void {
  let res;
  const state = editorAPI.store.getState();
  if (isInInteractionMode(state) && !isShownOnlyOnVariant(editorAPI, compRef)) {
    const styleData = editorAPI.dsRead.components.style.get(compRef);
    const pointerWithVariant = getCompVariantPointer(state);
    const { id, ...styleValueWithoutId } = styleData;
    res = editorAPI.dsActions.components.stylable.createEmptyStyle(
      pointerWithVariant,
      styleValueWithoutId,
    );
    setDefaultTransition(editorAPI, compRef);
  }
  return res;
}
