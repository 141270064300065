import emailMarketingPanel from './emailMarketingPanel/emailMarketingPanel';
import seoPanel from './seoPanel/seoPanel';
import googleAdsPanel from './googleAdsPanel/googleAdsPanel';
import facebookAdsPanel from './facebookAdsPanel/facebookAdsPanel';
import socialMediaPanel from './socialMediaPanel/socialMediaPanel';
import marketingIntegrationsPanel from './marketingIntegrationsPanel/marketingIntegrationsPanel';

export {
  emailMarketingPanel,
  seoPanel,
  googleAdsPanel,
  facebookAdsPanel,
  socialMediaPanel,
  marketingIntegrationsPanel,
};
