import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@wix/wix-base-ui';
import { EmptyState } from '../../components/EmptyState/EmptyState';
import { AUTOMATION_IDS } from '../../automationIds';

import styles from './PendingApp.scss';

export interface PendingAppProps {
  onAddApp: () => void;
}

export const PendingApp: React.FC<PendingAppProps> = ({ onAddApp }) => {
  const [translate] = useTranslation();

  return (
    <EmptyState
      symbolName="appManager_installationFlowsAddToEditor"
      title={translate('App_Manager_Finalize_Installation_Header')}
      text={translate('App_Manager_Finalize_Installation_Text')}
      action={
        <Button
          className={`btn-md ${styles.actionBtn}`}
          onClick={onAddApp}
          dataHook={AUTOMATION_IDS.MY_BUSINESS.PENDING_APP_INSTALL_ACTION}
        >
          {translate('App_Manager_Finalize_Installation_Link')}
        </Button>
      }
      dataHook={AUTOMATION_IDS.MY_BUSINESS.PENDING_APP_CONTAINER}
    />
  );
};
