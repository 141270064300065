'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const i18n = require('@/i18n')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_preset_list_other',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': `addPanelData/sections/listOtherSection_${util.languages.getLanguageCode()}/listOtherSection_${util.languages.getLanguageCode()}.png`,
        'imageHover': null,
        'items': [{
            'id': 'Lists_List_Other_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 756,
                    'height': 772,
                    'x': 20,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7g7pq083'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'name': 'VideoList_i6fev9dl429',
                            'displayName': i18n.translate('i7g7pq0e.structure.custom.template.type.displayName'),
                            'version': 0,
                            'baseTypes': [],
                            'fields': [{
                                'displayName': i18n.translate('i7g7pq0e.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g7pq0e.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'searchable': false,
                                'displayName': i18n.translate('i7g7pq0e.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'name': 'details1',
                                'computed': false,
                                'defaultValue': i18n.translate('i7g7pq0e.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'name': 'i98f50sg',
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7g7pq0e.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7g7pq0e.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7g7pq0e.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'name': 'description',
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7g7pq0e.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g7pq0e.structure.custom.template.type.fields.4.defaultValue'),
                                'name': 'i98f87hv',
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7g7pq0e.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'i9dyflk2',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7g7pq0e.structure.custom.template.type.fields.6.displayName'),
                                'name': 'video',
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i7g7pq0e.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'name': 'i71tvnw8',
                                'type': 'String',
                                'displayName': i18n.translate('i7g7pq0e.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }]
                        },
                        'views': [{
                            'name': 'VideoListView_i6fev9dm430_dup_i6kj31gi360_dup_i6qm3p0x325_dup_i6vwu8ki73_dup_i70h8y2v200_dup_i7g7ppxq25',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'VideoListView_i6fev9dm430_dup_i6kj31gi360_dup_i6qm3p0x325_dup_i6vwu8ki73_dup_i70h8y2v200_dup_i7g7ppxq25'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'VideoListView_i6fev9dm430_dup_i6kj31gi360_dup_i6qm3p0x325_dup_i6vwu8ki73_dup_i70h8y2v200_dup_i7g7ppxq25'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g7ppxq',
                            'format': 'Mobile'
                        }, {
                            'name': 'VideoListView_i6fev9dm430_dup_i6kj31gi360_dup_i6qm3p0x325_dup_i6vwu8ki73_dup_i70h8y2v200_dup_i7g7ppxq25',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'VideoListView_i6fev9dm430_dup_i6kj31gi360_dup_i6qm3p0x325_dup_i6vwu8ki73_dup_i70h8y2v200_dup_i7g7ppxq25'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'VideoListView_i6fev9dm430_dup_i6kj31gi360_dup_i6qm3p0x325_dup_i6vwu8ki73_dup_i70h8y2v200_dup_i7g7ppxq25'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g7ppxq'
                        }, {
                            'name': 'VideoListView_i6fev9dm430_dup_i6kj31gi360_dup_i6qm3p0x325_dup_i6vwu8ki73_dup_i70h8y2v200_dup_i7g7ppxq25',
                            'forType': 'VideoList_i6fev9dl429',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_hhq2ovcg144',
                                            'data': 'video',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 220,
                                                'items': [{
                                                    'id': 'fld_hhq2ovcg144_proxy',
                                                    'data': 'video',
                                                    'comp': {'name': 'Video', 'showControls': 'always_hide'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left'
                                            }
                                        }, {
                                            'id': 'fld_i9dzf85f26',
                                            'data': 'i9dyflk2',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i9dzf85f26_proxy',
                                                    'data': 'i9dyflk2',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true,
                                                'height': 200,
                                                'box-align': 'left'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'Left_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'items': [],
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                }
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Left_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': []
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'fld_hhq2q4pp150',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hhq2q4pp150_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_hhq2rdvs162',
                                                            'data': 'details1',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hhq2rdvs162_proxy',
                                                                    'data': 'details1',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body M',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_i9dvrgs895',
                                                            'data': 'i98f50sg',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9dvrgs895_proxy',
                                                                    'data': 'i98f50sg',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'dddd, mmmm dd, yyyy',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hhq2srcn192',
                                                            'data': 'description',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_hhq2srcn192_proxy',
                                                                    'data': 'description',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 5,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': false,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_i9dvrj5a104',
                                                            'data': 'i98f87hv',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'items': [{
                                                                    'id': 'fld_i9dvrj5a104_proxy',
                                                                    'data': 'i98f87hv',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Heading S'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 6,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 200}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 3}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i71tvnyx371',
                                            'data': 'i71tvnw8',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 44,
                                                'items': [{
                                                    'id': 'fld_i71tvnyx371_proxy',
                                                    'data': 'i71tvnw8',
                                                    'comp': {'name': 'Button2', 'align': 'center', 'style': 'b1'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 16,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'originalWidth': 700, 'wasChanged': true},
                            'format': 'Mobile'
                        }, {
                            'name': 'VideoListView_i6fev9dm430_dup_i6kj31gi360_dup_i6qm3p0x325_dup_i6vwu8ki73_dup_i70h8y2v200_dup_i7g7ppxq25',
                            'forType': 'VideoList_i6fev9dl429',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'items': [{
                                                    'id': 'fld_hhq2ovcg144',
                                                    'data': 'video',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 390,
                                                        'height': 220,
                                                        'items': [{
                                                            'id': 'fld_hhq2ovcg144_proxy',
                                                            'data': 'video',
                                                            'comp': {
                                                                'name': 'Video',
                                                                'showControls': 'always_hide'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 25,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left',
                                                        'hidden': false
                                                    }
                                                }, {
                                                    'id': 'fld_i9dyflld127',
                                                    'data': 'i9dyflk2',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 390,
                                                        'height': 220,
                                                        'items': [{
                                                            'id': 'fld_i9dyflld127_proxy',
                                                            'data': 'i9dyflk2',
                                                            'comp': {'name': 'Image', 'style': 'wp2'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 22,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left',
                                                        'hidden': true
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 20
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'fld_hhq2q4pp150',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hhq2q4pp150_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'id': 'fld_hhq2rdvs162',
                                                    'data': 'details1',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hhq2rdvs162_proxy',
                                                            'data': 'details1',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body M',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'id': 'fld_i98f50tm62',
                                                    'data': 'i98f50sg',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i98f50tm62_proxy',
                                                            'data': 'i98f50sg',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'dddd, mmmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 3,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hhq2srcn192',
                                                    'data': 'description',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hhq2srcn192_proxy',
                                                            'data': 'description',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 8,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i98f87ji41',
                                                    'data': 'i98f87hv',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i98f87ji41_proxy',
                                                            'data': 'i98f87hv',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 7,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_i71tvnyx371',
                                                    'data': 'i71tvnw8',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'items': [{
                                                            'id': 'fld_i71tvnyx371_proxy',
                                                            'data': 'i71tvnw8',
                                                            'comp': {
                                                                'name': 'Button2',
                                                                'align': 'center',
                                                                'style': 'b1'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 20,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'height': '40',
                                                        'box-align': 'left'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'pack': 'start'
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 200}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '3'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': []
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        }
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }],
                        'items': [{
                            'links': {
                                '_type': 'wix:Map',
                                'i71tvnw8': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'video': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'title': i18n.translate('i7g7pq0e.structure.custom.template.items.0.title'),
                            'details1': i18n.translate('i7g7pq0e.structure.custom.template.items.0.details1'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g7pq0e.structure.custom.template.items.0.description')
                            },
                            'i71tvnw8': i18n.translate('i7g7pq0e.structure.custom.template.items.0.i71tvnw8'),
                            'i98f50sg': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i98f87hv': i18n.translate('i7g7pq0e.structure.custom.template.items.0.i98f87hv'),
                            'i9dyflk2': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'c7ad2b64020342d0ae1a1feace158fe9.jpg',
                                'width': 1920,
                                'height': 1280,
                                'alt': ''
                            }
                        }, {
                            'links': {
                                '_type': 'wix:Map',
                                'i71tvnw8': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'video': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'title': i18n.translate('i7g7pq0e.structure.custom.template.items.1.title'),
                            'details1': i18n.translate('i7g7pq0e.structure.custom.template.items.1.details1'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g7pq0e.structure.custom.template.items.1.description')
                            },
                            'i71tvnw8': i18n.translate('i7g7pq0e.structure.custom.template.items.1.i71tvnw8'),
                            'i98f50sg': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i98f87hv': i18n.translate('i7g7pq0e.structure.custom.template.items.1.i98f87hv'),
                            'i9dyflk2': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'd967ba93f0314c78924edc8a8c8cfa15.jpg',
                                'width': 1920,
                                'height': 1275,
                                'alt': ''
                            }
                        }, {
                            'links': {
                                '_type': 'wix:Map',
                                'i71tvnw8': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'video': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'title': i18n.translate('i7g7pq0e.structure.custom.template.items.2.title'),
                            'details1': i18n.translate('i7g7pq0e.structure.custom.template.items.2.details1'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g7pq0e.structure.custom.template.items.2.description')
                            },
                            'i71tvnw8': i18n.translate('i7g7pq0e.structure.custom.template.items.2.i71tvnw8'),
                            'i98f50sg': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i98f87hv': i18n.translate('i7g7pq0e.structure.custom.template.items.2.i98f87hv'),
                            'i9dyflk2': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'bef2ef0f0a3343aba2fa7e746baa781c.jpg',
                                'width': 4434,
                                'height': 3729,
                                'alt': ''
                            }
                        }],
                        'displayName': i18n.translate('i7g7pq0e.structure.custom.template.displayName')
                    }
                },
                'id': 'i7g7pq0e'
            },
            'preset': {
                'rect': {'width': 162, 'height': 185, 'x': 0, 'y': 0},
                'label': 'i7g7pq0e.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Other_2',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 394,
                    'height': 943,
                    'x': 842,
                    'y': 264,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7g8kgkh9'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'name': 'VideoList_i6jb7jfo465',
                            'version': 0,
                            'baseTypes': [],
                            'fields': [{
                                'displayName': i18n.translate('i7g8kgko.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g8kgko.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'details',
                                'searchable': false,
                                'displayName': i18n.translate('i7g8kgko.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i7g8kgko.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'name': 'i98dqrye',
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7g8kgko.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7g8kgko.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7g8kgko.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'name': 'description',
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7g8kgko.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g8kgko.structure.custom.template.type.fields.4.defaultValue'),
                                'name': 'i98dsuye',
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7g8kgko.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'i6lyu881',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7g8kgko.structure.custom.template.type.fields.6.displayName'),
                                'name': 'video',
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i7g8kgko.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'name': 'i71ptb51',
                                'type': 'String',
                                'displayName': i18n.translate('i7g8kgko.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'displayName': i18n.translate('i7g8kgko.structure.custom.template.type.displayName')
                        },
                        'views': [{
                            'name': 'VideoListView_i6jb7jfp466_dup_i6kj48qb378_dup_i6qm3p28326_dup_i6vwu8j872_dup_i70h8y21199_dup_i7g8kghh259',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 45, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'VideoListView_i6jb7jfp466_dup_i6kj48qb378_dup_i6qm3p28326_dup_i6vwu8j872_dup_i70h8y21199_dup_i7g8kghh259'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'VideoListView_i6jb7jfp466_dup_i6kj48qb378_dup_i6qm3p28326_dup_i6vwu8j872_dup_i70h8y21199_dup_i7g8kghh259'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g8kghh',
                            'format': 'Mobile'
                        }, {
                            'name': 'VideoListView_i6jb7jfp466_dup_i6kj48qb378_dup_i6qm3p28326_dup_i6vwu8j872_dup_i70h8y21199_dup_i7g8kghh259',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'VideoListView_i6jb7jfp466_dup_i6kj48qb378_dup_i6qm3p28326_dup_i6vwu8j872_dup_i70h8y21199_dup_i7g8kghh259'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'VideoListView_i6jb7jfp466_dup_i6kj48qb378_dup_i6qm3p28326_dup_i6vwu8j872_dup_i70h8y21199_dup_i7g8kghh259'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g8kghh'
                        }, {
                            'name': 'VideoListView_i6jb7jfp466_dup_i6kj48qb378_dup_i6qm3p28326_dup_i6vwu8j872_dup_i70h8y21199_dup_i7g8kghh259',
                            'forType': 'VideoList_i6jb7jfo465',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_hhq2ovcg144',
                                            'data': 'video',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 390,
                                                'items': [{
                                                    'id': 'fld_hhq2ovcg144_proxy',
                                                    'data': 'video',
                                                    'comp': {'name': 'Video', 'showControls': 'always_hide'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': false,
                                                'height': 220,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'start'
                                            }
                                        }, {
                                            'id': 'fld_i71pv3tc231',
                                            'data': 'i6lyu881',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 390,
                                                'height': 220,
                                                'items': [{
                                                    'id': 'fld_i71pv3tc231_proxy',
                                                    'data': 'i6lyu881',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'start',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 20
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'fld_hhq2q4pp150',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hhq2q4pp150_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_hhq2qndl156',
                                                    'data': 'details',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hhq2qndl156_proxy',
                                                            'data': 'details',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body M',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 4,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'id': 'fld_i98dqrzr313',
                                                    'data': 'i98dqrye',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i98dqrzr313_proxy',
                                                            'data': 'i98dqrye',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mmmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hhq2srcn192',
                                                    'data': 'description',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hhq2srcn192_proxy',
                                                            'data': 'description',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 4,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true
                                                    }
                                                }, {
                                                    'experiment': 'NewListFields',
                                                    'id': 'fld_i98dsuzi41',
                                                    'data': 'i98dsuye',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i98dsuzi41_proxy',
                                                            'data': 'i98dsuye',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 6,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'pack': 'start'
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 200}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '3'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': []
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i71ptb67217',
                                            'data': 'i71ptb51',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 160,
                                                'height': '40',
                                                'items': [{
                                                    'id': 'fld_i71ptb67217_proxy',
                                                    'data': 'i71ptb51',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 18,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }, {
                            'name': 'VideoListView_i6jb7jfp466_dup_i6kj48qb378_dup_i6qm3p28326_dup_i6vwu8j872_dup_i70h8y21199_dup_i7g8kghh259',
                            'forType': 'VideoList_i6jb7jfo465',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_hhq2ovcg144',
                                            'data': 'video',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_hhq2ovcg144_proxy',
                                                    'data': 'video',
                                                    'comp': {'name': 'Video', 'showControls': 'always_hide'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': false,
                                                'height': 220,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center'
                                            }
                                        }, {
                                            'id': 'fld_i71pv3tc231',
                                            'data': 'i6lyu881',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 220,
                                                'items': [{
                                                    'id': 'fld_i71pv3tc231_proxy',
                                                    'data': 'i6lyu881',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }, {
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'fld_hhq2q4pp150',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hhq2q4pp150_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_hhq2qndl156',
                                                            'data': 'details',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hhq2qndl156_proxy',
                                                                    'data': 'details',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body M',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_i9dv6amn132',
                                                            'data': 'i98dqrye',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9dv6amn132_proxy',
                                                                    'data': 'i98dqrye',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'color': 'color_14',
                                                                        'format': 'mmmm dd, yyyy'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 4,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hhq2srcn192',
                                                            'data': 'description',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hhq2srcn192_proxy',
                                                                    'data': 'description',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 1,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'experiment': 'NewListFields',
                                                            'id': 'fld_i9dv6e2m141',
                                                            'data': 'i98dsuye',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9dv6e2m141_proxy',
                                                                    'data': 'i98dsuye',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Body L'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 4,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 200}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 3}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i71ptb67217',
                                            'data': 'i71ptb51',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 44,
                                                'items': [{
                                                    'id': 'fld_i71ptb67217_proxy',
                                                    'data': 'i71ptb51',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 10,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true, 'originalWidth': 700},
                            'format': 'Mobile'
                        }],
                        'items': [{
                            'links': {
                                '_type': 'wix:Map',
                                'i71ptb51': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'video': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'title': i18n.translate('i7g8kgko.structure.custom.template.items.0.title'),
                            'details': i18n.translate('i7g8kgko.structure.custom.template.items.0.details'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g8kgko.structure.custom.template.items.0.description')
                            },
                            'i6lyu881': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'c7ad2b64020342d0ae1a1feace158fe9.jpg',
                                'width': 1920,
                                'height': 1280,
                                'alt': ''
                            },
                            'i71ptb51': i18n.translate('i7g8kgko.structure.custom.template.items.0.i71ptb51'),
                            'i98dqrye': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i98dsuye': i18n.translate('i7g8kgko.structure.custom.template.items.0.i98dsuye')
                        }, {
                            'links': {
                                '_type': 'wix:Map',
                                'i71ptb51': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'video': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'title': i18n.translate('i7g8kgko.structure.custom.template.items.1.title'),
                            'details': i18n.translate('i7g8kgko.structure.custom.template.items.1.details'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g8kgko.structure.custom.template.items.1.description')
                            },
                            'i6lyu881': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'd967ba93f0314c78924edc8a8c8cfa15.jpg',
                                'width': 1920,
                                'height': 1275,
                                'alt': ''
                            },
                            'i71ptb51': i18n.translate('i7g8kgko.structure.custom.template.items.1.i71ptb51'),
                            'i98dqrye': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i98dsuye': i18n.translate('i7g8kgko.structure.custom.template.items.1.i98dsuye')
                        }, {
                            'links': {
                                '_type': 'wix:Map',
                                'i71ptb51': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'video': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'title': i18n.translate('i7g8kgko.structure.custom.template.items.2.title'),
                            'details': i18n.translate('i7g8kgko.structure.custom.template.items.2.details'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g8kgko.structure.custom.template.items.2.description')
                            },
                            'i6lyu881': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'bef2ef0f0a3343aba2fa7e746baa781c.jpg',
                                'width': 4434,
                                'height': 3729,
                                'alt': ''
                            },
                            'i71ptb51': i18n.translate('i7g8kgko.structure.custom.template.items.2.i71ptb51'),
                            'i98dqrye': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i98dsuye': i18n.translate('i7g8kgko.structure.custom.template.items.2.i98dsuye')
                        }],
                        'displayName': i18n.translate('i7g8kgko.structure.custom.template.displayName')
                    }
                },
                'id': 'i7g8kgko'
            },
            'preset': {
                'rect': {'width': 162, 'height': 369, 'x': 162, 'y': 0},
                'label': 'i7g8kgko.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Other_3',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 795,
                    'height': 783,
                    'x': -172,
                    'y': 767,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7g88i746'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'displayName': i18n.translate('i7g88i7b.structure.custom.template.type.displayName'),
                            'version': 0,
                            'baseTypes': [],
                            'name': 'VideoList_i6vymelz52',
                            'fields': [{
                                'displayName': i18n.translate('i7g88i7b.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g88i7b.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'details',
                                'searchable': false,
                                'displayName': i18n.translate('i7g88i7b.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i7g88i7b.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'type': 'wix:Date',
                                'searchable': false,
                                'name': 'i98focyh',
                                'displayName': i18n.translate('i7g88i7b.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7g88i7b.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7g88i7b.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'name': 'description',
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7g88i7b.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g88i7b.structure.custom.template.type.fields.4.defaultValue'),
                                'name': 'i98fly47',
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7g88i7b.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'i71u61d8',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7g88i7b.structure.custom.template.type.fields.6.displayName'),
                                'name': 'video',
                                'computed': false
                            }, {
                                'name': 'i71u3t8k',
                                'defaultValue': i18n.translate('i7g88i7b.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7g88i7b.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }]
                        },
                        'views': [{
                            'name': 'VideoListView_i6vymem153_dup_i6vz9to2158_dup_i70h8y3p201_dup_i7g88i4r151',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 55, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'VideoListView_i6vymem153_dup_i6vz9to2158_dup_i70h8y3p201_dup_i7g88i4r151'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'VideoListView_i6vymem153_dup_i6vz9to2158_dup_i70h8y3p201_dup_i7g88i4r151'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g88i4r'
                        }, {
                            'name': 'VideoListView_i6vymem153_dup_i6vz9to2158_dup_i70h8y3p201_dup_i7g88i4r151',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'VideoListView_i6vymem153_dup_i6vz9to2158_dup_i70h8y3p201_dup_i7g88i4r151'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'VideoListView_i6vymem153_dup_i6vz9to2158_dup_i70h8y3p201_dup_i7g88i4r151'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g88i4r',
                            'format': 'Mobile'
                        }, {
                            'name': 'VideoListView_i6vymem153_dup_i6vz9to2158_dup_i70h8y3p201_dup_i7g88i4r151',
                            'forType': 'VideoList_i6vymelz52',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 20
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'fld_hhq2q4pp150',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': 370,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hhq2q4pp150_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'id': 'fld_hhq2qndl156',
                                                    'data': 'details',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': 0,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hhq2qndl156_proxy',
                                                            'data': 'details',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'id': 'fld_i98foczz70',
                                                    'data': 'i98focyh',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i98foczz70_proxy',
                                                            'data': 'i98focyh',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mmmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 6,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hhq2srcn192',
                                                    'data': 'description',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': 370,
                                                        'items': [{
                                                            'id': 'fld_hhq2srcn192_proxy',
                                                            'data': 'description',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 8,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i98fly5c34',
                                                    'data': 'i98fly47',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i98fly5c34_proxy',
                                                            'data': 'i98fly47',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 10,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_i71u3ta3493',
                                                    'data': 'i71u3t8k',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'items': [{
                                                            'id': 'fld_i71u3ta3493_proxy',
                                                            'data': 'i71u3t8k',
                                                            'comp': {
                                                                'name': 'Button2',
                                                                'style': 'b1',
                                                                'align': 'center'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 16,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'height': '40',
                                                        'box-align': 'center'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'pack': 'center'
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '3'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'fld_i71u61ed526',
                                                    'data': 'i71u61d8',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 384,
                                                        'items': [{
                                                            'id': 'fld_i71u61ed526_proxy',
                                                            'data': 'i71u61d8',
                                                            'comp': {'name': 'Image', 'style': 'wp2'}
                                                        }],
                                                        'hidden': true,
                                                        'height': 220,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 6,
                                                            'xax-before': 24,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }, {
                                                    'id': 'fld_hhq2ovcg144',
                                                    'data': 'video',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 384,
                                                        'items': [{
                                                            'id': 'fld_hhq2ovcg144_proxy',
                                                            'data': 'video',
                                                            'comp': {
                                                                'name': 'Video',
                                                                'showControls': 'always_hide'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'hidden': false,
                                                        'height': '220',
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 24,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }, {
                            'name': 'VideoListView_i6vymem153_dup_i6vz9to2158_dup_i70h8y3p201_dup_i7g88i4r151',
                            'forType': 'VideoList_i6vymelz52',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i71u61ed526',
                                            'data': 'i71u61d8',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i71u61ed526_proxy',
                                                    'data': 'i71u61d8',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'hidden': true,
                                                'height': 220,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left'
                                            }
                                        }, {
                                            'id': 'fld_hhq2ovcg144',
                                            'data': 'video',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_hhq2ovcg144_proxy',
                                                    'data': 'video',
                                                    'comp': {'name': 'Video', 'showControls': 'always_hide'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': false,
                                                'height': '220',
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }, {
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'fld_hhq2q4pp150',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hhq2q4pp150_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_hhq2qndl156',
                                                            'data': 'details',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 0,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hhq2qndl156_proxy',
                                                                    'data': 'details',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 6,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_i9e03r09112',
                                                            'data': 'i98focyh',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9e03r09112_proxy',
                                                                    'data': 'i98focyh',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'mmmm dd, yyyy',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 4,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hhq2srcn192',
                                                            'data': 'description',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hhq2srcn192_proxy',
                                                                    'data': 'description',
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 10,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'experiment': 'NewListFields',
                                                            'id': 'fld_i9e03s8a121',
                                                            'data': 'i98fly47',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9e03s8a121_proxy',
                                                                    'data': 'i98fly47',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Heading S'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 6,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'center'
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 3}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': [{
                                                            'id': 'Right_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Right_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': []
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Right_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Right_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': []
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }]
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i71u3ta3493',
                                            'data': 'i71u3t8k',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i71u3ta3493_proxy',
                                                    'data': 'i71u3t8k',
                                                    'comp': {'name': 'Button2', 'align': 'center', 'style': 'b1'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'height': 44,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 10,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true, 'originalWidth': 968},
                            'format': 'Mobile'
                        }],
                        'items': [{
                            'links': {
                                '_type': 'wix:Map',
                                'i71u3t8k': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'video': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'title': i18n.translate('i7g88i7b.structure.custom.template.items.0.title'),
                            'details': i18n.translate('i7g88i7b.structure.custom.template.items.0.details'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g88i7b.structure.custom.template.items.0.description')
                            },
                            'i71u3t8k': i18n.translate('i7g88i7b.structure.custom.template.items.0.i71u3t8k'),
                            'i71u61d8': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'c7ad2b64020342d0ae1a1feace158fe9.jpg',
                                'width': 1920,
                                'height': 1280,
                                'alt': ''
                            },
                            'i98fly47': i18n.translate('i7g88i7b.structure.custom.template.items.0.i98fly47'),
                            'i98focyh': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'}
                        }, {
                            'links': {
                                '_type': 'wix:Map',
                                'i71u3t8k': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'video': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'title': i18n.translate('i7g88i7b.structure.custom.template.items.1.title'),
                            'details': i18n.translate('i7g88i7b.structure.custom.template.items.1.details'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g88i7b.structure.custom.template.items.1.description')
                            },
                            'i71u3t8k': i18n.translate('i7g88i7b.structure.custom.template.items.1.i71u3t8k'),
                            'i71u61d8': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'd967ba93f0314c78924edc8a8c8cfa15.jpg',
                                'width': 1920,
                                'height': 1275,
                                'alt': ''
                            },
                            'i98fly47': i18n.translate('i7g88i7b.structure.custom.template.items.1.i98fly47'),
                            'i98focyh': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'}
                        }, {
                            'links': {
                                '_type': 'wix:Map',
                                'i71u3t8k': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'video': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'title': i18n.translate('i7g88i7b.structure.custom.template.items.2.title'),
                            'details': i18n.translate('i7g88i7b.structure.custom.template.items.2.details'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g88i7b.structure.custom.template.items.2.description')
                            },
                            'i71u3t8k': i18n.translate('i7g88i7b.structure.custom.template.items.2.i71u3t8k'),
                            'i71u61d8': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'bef2ef0f0a3343aba2fa7e746baa781c.jpg',
                                'width': 4434,
                                'height': 3729,
                                'alt': ''
                            },
                            'i98fly47': i18n.translate('i7g88i7b.structure.custom.template.items.2.i98fly47'),
                            'i98focyh': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'}
                        }],
                        'displayName': i18n.translate('i7g88i7b.structure.custom.template.displayName')
                    }
                },
                'id': 'i7g88i7b'
            },
            'preset': {
                'rect': {'width': 162, 'height': 184, 'x': 0, 'y': 185.25},
                'label': 'i7g88i7b.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Other_4',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 753,
                    'height': 731,
                    'x': -111,
                    'y': 1467,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7g7dwj018'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'fields': [{
                                'displayName': i18n.translate('i7g7dwj7.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g7dwj7.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'i6mb02vh',
                                'searchable': false,
                                'displayName': i18n.translate('i7g7dwj7.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i7g7dwj7.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'name': 'i98dxwym',
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7g7dwj7.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7g7dwj7.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7g7dwj7.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'name': 'description',
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7g7dwj7.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g7dwj7.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false,
                                'name': 'i98e7xb2'
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7g7dwj7.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'name': 'i71onr3y',
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7g7dwj7.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i7g7dwj7.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7g7dwj7.structure.custom.template.type.fields.7.displayName'),
                                'name': 'button',
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'version': 0,
                            'baseTypes': [],
                            'name': 'BasicPosts_i6fey5qh444',
                            'displayName': i18n.translate('i7g7dwj7.structure.custom.template.type.displayName')
                        },
                        'views': [{
                            'name': 'BasicPostsView_i6fey5qh445_dup_i6kjyhh3391_dup_i6qm3p3i327_dup_i708d4e41_dup_i70h8y9r207_dup_i7g7dwie319',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'BasicPostsView_i6fey5qh445_dup_i6kjyhh3391_dup_i6qm3p3i327_dup_i708d4e41_dup_i70h8y9r207_dup_i7g7dwie319'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'BasicPostsView_i6fey5qh445_dup_i6kjyhh3391_dup_i6qm3p3i327_dup_i708d4e41_dup_i70h8y9r207_dup_i7g7dwie319'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g7dwif',
                            'format': 'Mobile'
                        }, {
                            'name': 'BasicPostsView_i6fey5qh445_dup_i6kjyhh3391_dup_i6qm3p3i327_dup_i708d4e41_dup_i70h8y9r207_dup_i7g7dwie319',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'BasicPostsView_i6fey5qh445_dup_i6kjyhh3391_dup_i6qm3p3i327_dup_i708d4e41_dup_i70h8y9r207_dup_i7g7dwie319'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'BasicPostsView_i6fey5qh445_dup_i6kjyhh3391_dup_i6qm3p3i327_dup_i708d4e41_dup_i70h8y9r207_dup_i7g7dwie319'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g7dwif'
                        }, {
                            'name': 'BasicPostsView_i6fey5qh445_dup_i6kjyhh3391_dup_i6qm3p3i327_dup_i708d4e41_dup_i70h8y9r207_dup_i7g7dwie319',
                            'forType': 'BasicPosts_i6fey5qh444',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'Left_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'items': [{
                                                                    'id': 'fld_hhrsgeey34',
                                                                    'data': 'image',
                                                                    'comp': {
                                                                        'name': 'Field',
                                                                        'width': 280,
                                                                        'height': 191,
                                                                        'items': [{
                                                                            'id': 'fld_hhrsgeey34_proxy',
                                                                            'data': 'image',
                                                                            'comp': {
                                                                                'name': 'Image',
                                                                                'style': 'wp2'
                                                                            }
                                                                        }],
                                                                        'labelPosition': 'none',
                                                                        'spacers': {
                                                                            'before': 0,
                                                                            'after': 0,
                                                                            'xax-before': 0,
                                                                            'xax-after': 10
                                                                        },
                                                                        'box-align': 'left',
                                                                        'hidden': false
                                                                    }
                                                                }],
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                }
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Left_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': [{
                                                                    'id': 'fld_i71onr53659',
                                                                    'data': 'i71onr3y',
                                                                    'comp': {
                                                                        'name': 'Field',
                                                                        'width': 280,
                                                                        'height': '220',
                                                                        'items': [{
                                                                            'id': 'fld_i71onr53659_proxy',
                                                                            'data': 'i71onr3y',
                                                                            'comp': {'name': 'Video'},
                                                                            'layout': {'position': 'relative'}
                                                                        }],
                                                                        'labelPosition': 'none',
                                                                        'spacers': {
                                                                            'before': 0,
                                                                            'after': 0,
                                                                            'xax-before': 0,
                                                                            'xax-after': 10
                                                                        },
                                                                        'box-align': 'left',
                                                                        'hidden': true
                                                                    }
                                                                }]
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'fld_hhrsh1qx43',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 260,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hhrsh1qx43_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'bold': false,
                                                                        'underline': false,
                                                                        'italic': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_i71oll0z639',
                                                            'data': 'i6mb02vh',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 260,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i71oll0z639_proxy',
                                                                    'data': 'i6mb02vh',
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_i9e24w8k156',
                                                            'data': 'i98dxwym',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'items': [{
                                                                    'id': 'fld_i9e24w8k156_proxy',
                                                                    'data': 'i98dxwym',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'dddd, mmmm dd, yyyy',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hhrsips469',
                                                            'data': 'description',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 260,
                                                                'items': [{
                                                                    'id': 'fld_hhrsips469_proxy',
                                                                    'data': 'description',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 4,
                                                                    'after': 8,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_i9e24x3r165',
                                                            'data': 'i98e7xb2',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'items': [{
                                                                    'id': 'fld_i9e24x3r165_proxy',
                                                                    'data': 'i98e7xb2',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 6,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 3}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_hhrsj5x182',
                                            'data': 'button',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_hhrsj5x182_proxy',
                                                    'data': 'button',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': false,
                                                'height': 44,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 10,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'originalWidth': 643, 'wasChanged': true},
                            'format': 'Mobile'
                        }, {
                            'name': 'BasicPostsView_i6fey5qh445_dup_i6kjyhh3391_dup_i6qm3p3i327_dup_i708d4e41_dup_i70h8y9r207_dup_i7g7dwie319',
                            'forType': 'BasicPosts_i6fey5qh444',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'fld_hhrsgeey34',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 330,
                                                        'items': [{
                                                            'id': 'fld_hhrsgeey34_proxy',
                                                            'data': 'image',
                                                            'comp': {'name': 'Image', 'style': 'wp2'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 6,
                                                            'xax-before': 0,
                                                            'xax-after': 33
                                                        },
                                                        'hidden': false,
                                                        'height': 200,
                                                        'box-align': 'left'
                                                    }
                                                }, {
                                                    'id': 'fld_i71onr53659',
                                                    'data': 'i71onr3y',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 330,
                                                        'height': '220',
                                                        'items': [{
                                                            'id': 'fld_i71onr53659_proxy',
                                                            'data': 'i71onr3y',
                                                            'comp': {'name': 'Video'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 33
                                                        },
                                                        'box-align': 'end',
                                                        'hidden': true
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 20
                                                    }
                                                },
                                                'pack': 'start'
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'fld_hhrsh1qx43',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hhrsh1qx43_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'underline': false,
                                                                'italic': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i71oll0z639',
                                                    'data': 'i6mb02vh',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i71oll0z639_proxy',
                                                            'data': 'i6mb02vh',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i98dxwzf93',
                                                    'data': 'i98dxwym',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i98dxwzf93_proxy',
                                                            'data': 'i98dxwym',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'dddd, mmmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hhrsips469',
                                                    'data': 'description',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hhrsips469_proxy',
                                                            'data': 'description',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 12,
                                                            'after': 10,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i98e7xc241',
                                                    'data': 'i98e7xb2',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i98e7xc241_proxy',
                                                            'data': 'i98e7xb2',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hhrsj5x182',
                                                    'data': 'button',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'items': [{
                                                            'id': 'fld_hhrsj5x182_proxy',
                                                            'data': 'button',
                                                            'comp': {
                                                                'name': 'Button2',
                                                                'style': 'b1',
                                                                'align': 'center'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 31,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false,
                                                        'height': 40,
                                                        'box-align': 'start'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'pack': 'start'
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '3'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': []
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }],
                        'items': [{
                            'links': {'_type': 'wix:Map'},
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'c7ad2b64020342d0ae1a1feace158fe9.jpg',
                                'width': 1920,
                                'height': 1280,
                                'alt': ''
                            },
                            'title': i18n.translate('i7g7dwj7.structure.custom.template.items.0.title'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g7dwj7.structure.custom.template.items.0.description')
                            },
                            'button': i18n.translate('i7g7dwj7.structure.custom.template.items.0.button'),
                            'i6mb02vh': i18n.translate('i7g7dwj7.structure.custom.template.items.0.i6mb02vh'),
                            'i71onr3y': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i98dxwym': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i98e7xb2': i18n.translate('i7g7dwj7.structure.custom.template.items.0.i98e7xb2')
                        }, {
                            'links': {'_type': 'wix:Map'},
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'd967ba93f0314c78924edc8a8c8cfa15.jpg',
                                'width': 1920,
                                'height': 1275,
                                'alt': ''
                            },
                            'title': i18n.translate('i7g7dwj7.structure.custom.template.items.1.title'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g7dwj7.structure.custom.template.items.1.description')
                            },
                            'button': i18n.translate('i7g7dwj7.structure.custom.template.items.1.button'),
                            'i6mb02vh': i18n.translate('i7g7dwj7.structure.custom.template.items.1.i6mb02vh'),
                            'i71onr3y': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i98dxwym': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i98e7xb2': i18n.translate('i7g7dwj7.structure.custom.template.items.1.i98e7xb2')
                        }, {
                            'links': {'_type': 'wix:Map'},
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'bef2ef0f0a3343aba2fa7e746baa781c.jpg',
                                'width': 4434,
                                'height': 3729,
                                'alt': ''
                            },
                            'title': i18n.translate('i7g7dwj7.structure.custom.template.items.2.title'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g7dwj7.structure.custom.template.items.2.description')
                            },
                            'button': i18n.translate('i7g7dwj7.structure.custom.template.items.2.button'),
                            'i6mb02vh': i18n.translate('i7g7dwj7.structure.custom.template.items.2.i6mb02vh'),
                            'i71onr3y': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i98dxwym': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i98e7xb2': i18n.translate('i7g7dwj7.structure.custom.template.items.2.i98e7xb2')
                        }],
                        'displayName': i18n.translate('i7g7dwj7.structure.custom.template.displayName')
                    }
                },
                'id': 'i7g7dwj7'
            },
            'preset': {
                'rect': {'width': 162, 'height': 182, 'x': 0, 'y': 369.25},
                'label': 'i7g7dwj7.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Other_5',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 596,
                    'height': 631,
                    'x': 689,
                    'y': 1561,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7g7h8za21'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'name': 'NewsPosts_i6j6n14i329',
                            'fields': [{
                                'displayName': i18n.translate('i7g7h8zh.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g7h8zh.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'Strng_sTxt1',
                                'searchable': false,
                                'displayName': i18n.translate('i7g7h8zh.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i7g7h8zh.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'type': 'wix:Date',
                                'searchable': false,
                                'name': 'i98g4xxk',
                                'displayName': i18n.translate('i7g7h8zh.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7g7h8zh.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7g7h8zh.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'name': 'wxRchTxt_sTxt0',
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7g7h8zh.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g7h8zh.structure.custom.template.type.fields.4.defaultValue'),
                                'name': 'i98fzfv7',
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7g7h8zh.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'name': 'i71p3bx3',
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7g7h8zh.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'name': 'Strng_sBttn0-v1c',
                                'defaultValue': i18n.translate('i7g7h8zh.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7g7h8zh.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'version': 0,
                            'displayName': i18n.translate('i7g7h8zh.structure.custom.template.type.displayName'),
                            'baseTypes': []
                        },
                        'views': [{
                            'name': 'NewsPostsView_i6j6n14k330_dup_i6mavjiv355_dup_i6qm3phb340_dup_i708d4fn2_dup_i70h8yan208_dup_i7g7h8yo354',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 45, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6j6n14k330_dup_i6mavjiv355_dup_i6qm3phb340_dup_i708d4fn2_dup_i70h8yan208_dup_i7g7h8yo354'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6j6n14k330_dup_i6mavjiv355_dup_i6qm3phb340_dup_i708d4fn2_dup_i70h8yan208_dup_i7g7h8yo354'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g7h8yo',
                            'format': 'Mobile'
                        }, {
                            'name': 'NewsPostsView_i6j6n14k330_dup_i6mavjiv355_dup_i6qm3phb340_dup_i708d4fn2_dup_i70h8yan208_dup_i7g7h8yo354',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6j6n14k330_dup_i6mavjiv355_dup_i6qm3phb340_dup_i708d4fn2_dup_i70h8yan208_dup_i7g7h8yo354'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6j6n14k330_dup_i6mavjiv355_dup_i6qm3phb340_dup_i708d4fn2_dup_i70h8yan208_dup_i7g7h8yo354'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g7h8yo'
                        }, {
                            'name': 'NewsPostsView_i6j6n14k330_dup_i6mavjiv355_dup_i6qm3phb340_dup_i708d4fn2_dup_i70h8yan208_dup_i7g7h8yo354',
                            'forType': 'NewsPosts_i6j6n14i329',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 173,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left',
                                                'hidden': false
                                            }
                                        }, {
                                            'id': 'fld_i71p3bxy820',
                                            'data': 'i71p3bx3',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': '220',
                                                'items': [{
                                                    'id': 'fld_i71p3bxy820_proxy',
                                                    'data': 'i71p3bx3',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'Left_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'items': [],
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                }
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Left_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': []
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'box-align': 'start',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0
                                                                },
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_i71p1yew779',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i71p1yew779_proxy',
                                                                    'data': 'Strng_sTxt1',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'labelPosition': 'none',
                                                                'hidden': true
                                                            }
                                                        }, {
                                                            'id': 'fld_i9e1sa3e288',
                                                            'data': 'i98g4xxk',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'items': [{
                                                                    'id': 'fld_i9e1sa3e288_proxy',
                                                                    'data': 'i98g4xxk',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'mmmm dd, yyyy',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_i9e1sb47297',
                                                            'data': 'i98fzfv7',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'items': [{
                                                                    'id': 'fld_i9e1sb47297_proxy',
                                                                    'data': 'i98fzfv7',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Body L'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hfxs75de',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_hfxs75de_proxy',
                                                                    'data': 'wxRchTxt_sTxt0',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 4,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_hhq9fct831',
                                                            'data': 'Strng_sBttn0-v1c',
                                                            'comp': {
                                                                'name': 'Field',
                                                                'width': 280,
                                                                'items': [{
                                                                    'id': 'fld_hhq9fct831_proxy',
                                                                    'data': 'Strng_sBttn0-v1c',
                                                                    'comp': {
                                                                        'name': 'Button2',
                                                                        'style': 'b1',
                                                                        'align': 'center'
                                                                    },
                                                                    'layout': {'position': 'relative'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 25,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'height': 44,
                                                                'box-align': 'center'
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 3}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        }
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'originalWidth': 706, 'wasChanged': true},
                            'format': 'Mobile'
                        }, {
                            'name': 'NewsPostsView_i6j6n14k330_dup_i6mavjiv355_dup_i6qm3phb340_dup_i708d4fn2_dup_i70h8yan208_dup_i7g7h8yo354',
                            'forType': 'NewsPosts_i6j6n14i329',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'items': [{
                                                    'id': 'image_1',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'box-align': 'start',
                                                        'width': 173,
                                                        'height': 173,
                                                        'items': [{
                                                            'id': 'image_1_proxy',
                                                            'data': 'image',
                                                            'comp': {'name': 'Image', 'style': 'wp2'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 24,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'id': 'fld_i71p3bxy820',
                                                    'data': 'i71p3bx3',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'box-align': 'left',
                                                        'width': 330,
                                                        'height': '220',
                                                        'items': [{
                                                            'id': 'fld_i71p3bxy820_proxy',
                                                            'data': 'i71p3bx3',
                                                            'comp': {'name': 'Video'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 24,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'labelPosition': 'none'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 20
                                                    }
                                                },
                                                'pack': 'center'
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'box-align': 'start',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'italic': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {'before': 0, 'after': 0, 'xax-before': 0},
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_i71p1yew779',
                                                    'data': 'Strng_sTxt1',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i71p1yew779_proxy',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'labelPosition': 'none',
                                                        'hidden': true
                                                    }
                                                }, {
                                                    'id': 'fld_i98g4zjc175',
                                                    'data': 'i98g4xxk',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i98g4zjc175_proxy',
                                                            'data': 'i98g4xxk',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mmmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_i98fzfx393',
                                                    'data': 'i98fzfv7',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i98fzfx393_proxy',
                                                            'data': 'i98fzfv7',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hfxs75de',
                                                    'data': 'wxRchTxt_sTxt0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hfxs75de_proxy',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 10,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_hhq9fct831',
                                                    'data': 'Strng_sBttn0-v1c',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'items': [{
                                                            'id': 'fld_hhq9fct831_proxy',
                                                            'data': 'Strng_sBttn0-v1c',
                                                            'comp': {
                                                                'name': 'Button2',
                                                                'style': 'b1',
                                                                'align': 'center'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 25,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false,
                                                        'height': 40,
                                                        'box-align': 'end'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'pack': 'start'
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '3'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': []
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        }
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }],
                        'items': [{
                            'title': i18n.translate('i7g7h8zh.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'c7ad2b64020342d0ae1a1feace158fe9.jpg',
                                'width': 1920,
                                'height': 1280,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7g7h8zh.structure.custom.template.items.0.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g7h8zh.structure.custom.template.items.0.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7g7h8zh.structure.custom.template.items.0.Strng_sBttn0-v1c'),
                            'i71p3bx3': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i98fzfv7': i18n.translate('i7g7h8zh.structure.custom.template.items.0.i98fzfv7'),
                            'i98g4xxk': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'}
                        }, {
                            'title': i18n.translate('i7g7h8zh.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'd967ba93f0314c78924edc8a8c8cfa15.jpg',
                                'width': 1920,
                                'height': 1275,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7g7h8zh.structure.custom.template.items.1.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g7h8zh.structure.custom.template.items.1.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7g7h8zh.structure.custom.template.items.1.Strng_sBttn0-v1c'),
                            'i71p3bx3': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i98fzfv7': i18n.translate('i7g7h8zh.structure.custom.template.items.1.i98fzfv7'),
                            'i98g4xxk': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'}
                        }, {
                            'title': i18n.translate('i7g7h8zh.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'bef2ef0f0a3343aba2fa7e746baa781c.jpg',
                                'width': 4434,
                                'height': 3729,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7g7h8zh.structure.custom.template.items.2.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g7h8zh.structure.custom.template.items.2.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7g7h8zh.structure.custom.template.items.2.Strng_sBttn0-v1c'),
                            'i71p3bx3': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i98fzfv7': i18n.translate('i7g7h8zh.structure.custom.template.items.2.i98fzfv7'),
                            'i98g4xxk': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'}
                        }],
                        'displayName': i18n.translate('i7g7h8zh.structure.custom.template.displayName')
                    }
                },
                'id': 'i7g7h8zh'
            },
            'preset': {
                'rect': {'width': 162, 'height': 181, 'x': 162, 'y': 369.25},
                'label': 'i7g7h8zh.structure.custom.template.displayName',
                'tags': null
            }
        }],
        'compTypes': ['wixapps.integration.components.AppPart2']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_list_other'}
}
