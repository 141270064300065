// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import CustomTooltip from './customTooltipWithContextMenu';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';

const getDsActions = (dispatch, getState, { dsActions }) => dsActions;
const BROWSER_THEME = 'browser theme';

// eslint-disable-next-line react/prefer-es6-class
const MobileBrowserThemeColorTooltip = createReactClass({
  displayName: 'mobileBrowserThemeColorTooltip',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    openPanel: PropTypes.func.isRequired,
    isBrowserThemeColorSet: PropTypes.bool.isRequired,
    removeBrowserThemeColor: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      isBrowserThemeColorSet: this.props.isBrowserThemeColorSet,
    };
  },

  editBrowserThemeColor() {
    this.props.dispatchBIEvent(
      coreBi.events.mobileBrowserThemeColorPanel
        .EDIT_BROWSER_THEME_COLOR_MOBILE_TOOLS,
      {
        mobile_tool: BROWSER_THEME,
      },
    );
    this.props.openPanel('panels.focusPanels.browserThemeColorPanel');
  },

  removeBrowserThemeColor() {
    this.props.dispatchBIEvent(
      coreBi.events.mobileBrowserThemeColorPanel
        .REMOVE_BROWSER_THEME_COLOR_MOBILE_TOOLS,
      {
        mobile_tool: BROWSER_THEME,
      },
    );
    this.props.removeBrowserThemeColor();
    this.setState({ isBrowserThemeColorSet: false });
  },

  render() {
    return React.createElement(CustomTooltip, {
      isTooltipContentOnAddVisible: !this.state.isBrowserThemeColorSet,
      isEditRemoveSectionVisible: this.state.isBrowserThemeColorSet,
      onEditClick: this.editBrowserThemeColor,
      onRemoveClick: this.removeBrowserThemeColor,
    });
  },
});

const mapStateToProps = ({ dsRead }) => ({
  isBrowserThemeColorSet: dsRead.browserThemeColor.isSet(),
});

const mapDispatchToProps = (dispatch) => ({
  openPanel(panelName, panelProps, leavePanelsOpen) {
    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        panelName,
        panelProps,
        leavePanelsOpen,
      ),
    );
  },
  removeBrowserThemeColor: () => {
    dispatch(getDsActions).browserThemeColor.reset();
  },
  dispatchBIEvent: (...args) => {
    dispatch(stateManagement.bi.actions.event(...args));
  },
});

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(MobileBrowserThemeColorTooltip);
