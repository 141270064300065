//eslint-disable-next-line @wix/santa-editor/scoped-imports
import {
  mapDispatchToMembersWelcomeSectionProps,
  type MembersWelcomeDispatchProps,
} from '@/addPanelData/mappers';
import { wrapWithPropsTransformer } from '../utils';

export interface MembersWelcomeProps {
  membersMainAction: () => void;
}

export const membersWelcomeMapper = wrapWithPropsTransformer<
  MembersWelcomeDispatchProps,
  MembersWelcomeProps
>(mapDispatchToMembersWelcomeSectionProps, (props) => ({
  membersMainAction: props.installMembersApp,
}));
