import type {
  CompRef,
  ScrubAnimationDef,
  TriggerVariant,
} from 'types/documentServices';

import type { ComponentEditorMetaDataDefinition } from '@/componentModel';
import { componentsStore, selection } from '@/stateManagement';
import constants from '@/constants';
import { array } from '@/util';

import type { AnimationsScope } from '../../scope';
import type { EffectData, ScrollEffectData } from '../types';

interface PreviewScrubAnimationData {
  animations: Record<string, ScrubAnimationDef>;
  triggers: Record<string, TriggerVariant>;
}

export const createPreviewApi = ({ editorAPI, helpers }: AnimationsScope) => {
  const previewIds = new Map<string, number | null>();
  const defaultCompRestrictions = new Map<
    string,
    ComponentEditorMetaDataDefinition
  >();

  function start(compRef: CompRef, effectObj: EffectData) {
    const previewId = editorAPI.components.behaviors.previewAnimation(
      compRef,
      {
        ...effectObj.value,
        params: effectObj.value.namedEffect,
      },
      () => {
        previewIds.delete(compRef.id);
      },
    );

    previewIds.set(compRef.id, previewId);
  }

  function stop(compRef: CompRef) {
    editorAPI.components.behaviors.stopPreviewAnimation(
      previewIds.get(compRef.id),
      1,
    );
  }

  function getScrollPreviewData(
    compRefs: CompRef[],
    effectObj: ScrollEffectData,
  ) {
    return compRefs.reduce(
      (acc, compRef) => {
        const effectRef = helpers.findReaction(compRef, 'scroll')?.effect;
        const effectData = helpers.getEffectData(compRef, effectRef);

        acc.animations[effectRef.id] = {
          ...effectData,
          ...effectObj.value,
          type: 'ScrubAnimationOptions',
          targetId: compRef.id,
          variants: [],
        };
        acc.triggers[effectRef.id] = {
          ...helpers.findTriggerRef(compRef, 'view-progress'),
          type: 'Trigger',
          componentId: compRef.id,
          trigger: 'view-progress' as const,
        };

        return acc;
      },
      { animations: {}, triggers: {} } as PreviewScrubAnimationData,
    );
  }

  function startScrollPreview(
    compRef: CompRef | CompRef[],
    effectObj: ScrollEffectData,
  ) {
    const compRefs = array.asArray(compRef);

    compRefs.forEach((compRef) => {
      defaultCompRestrictions.set(
        compRef.id,
        editorAPI.getCompRestrictions(compRef),
      );

      editorAPI.components.layout.stage.freeze(compRef);

      const newRestrictions = {
        ...defaultCompRestrictions.get(compRef.id),
        // it would be better to not use `horizontally-` and `vertically-` keys,
        // but it seems like selection box only takes them into account
        movable: false,
        horizontallyMovable: false,
        verticallyMovable: false,
        resizable: false,
        verticallyResizable: false,
        horizontallyResizable: false,
        rotatable: false,
        verticallyRotatable: false,
        horizontallyRotatable: false,
      };

      editorAPI.store.dispatch(
        componentsStore.actions.updateComponentStore(
          compRef,
          constants.COMPONENTS_STORE_KEYS.META_DATA_OVERRIDES,
          newRestrictions,
        ),
      );

      editorAPI.store.dispatch(
        selection.actions.setSelectedCompsRestrictions(newRestrictions),
      );
    });

    const { animations, triggers } = getScrollPreviewData(compRefs, effectObj);
    editorAPI.components.behaviors.previewScrubAnimations(animations, triggers);
  }

  async function stopScrollPreview(compRef: CompRef | CompRef[]) {
    const compRefs = array.asArray(compRef);

    editorAPI.components.behaviors.stopPreviewScrubAnimations(() => {
      compRefs.forEach((compRef) => {
        editorAPI.components.layout.stage.unfreeze(compRef);

        editorAPI.store.dispatch(
          componentsStore.actions.updateComponentStore(
            compRef,
            constants.COMPONENTS_STORE_KEYS.META_DATA_OVERRIDES,
            defaultCompRestrictions.get(compRef.id),
          ),
        );

        editorAPI.store.dispatch(
          selection.actions.setSelectedCompsRestrictions(
            defaultCompRestrictions.get(compRef.id),
          ),
        );

        defaultCompRestrictions.delete(compRef.id);
      });
    });
  }

  function updateScrollPreviewData(
    compRef: CompRef | CompRef[],
    effectObj: ScrollEffectData,
  ) {
    const { animations, triggers } = getScrollPreviewData(
      array.asArray(compRef),
      effectObj,
    );
    editorAPI.components.behaviors.updateScrubAnimationsPreview(
      animations,
      triggers,
    );
  }

  return {
    start: (compRefs: CompRef | CompRef[], effectData: EffectData) =>
      array.applyForAll((compRef) => start(compRef, effectData), compRefs),
    stop: (compRefs: CompRef | CompRef[]) =>
      array.applyForAll((compRef) => stop(compRef), compRefs),
    scroll: {
      start: startScrollPreview,
      stop: stopScrollPreview,
      update: updateScrollPreviewData,
    },
  };
};
