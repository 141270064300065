import constants from '@/constants';
import { sections as sectionsUtils } from '@/util';
import { waitForAddedCompRef } from '@/componentsAddUtils';
import * as stateManagement from '@/stateManagement';
import { addBlankSection } from '../../api';

import type { Scope } from '@/sections';
import type { CompRef } from 'types/documentServices';
import type { ViewerChangesData } from 'types/core';

const { isSiteCreationUiVisible } = stateManagement.siteCreation.selectors;

const CHANGES_TO_REACT = ['pages.navigateTo', 'Undo'];

export const addBlankSectionToEmptyPageIfNeeded = async (
  scope: Scope,
  pageRef?: CompRef,
) => {
  const { editorAPI, selection } = scope;

  if (isSiteCreationUiVisible(editorAPI.store.getState())) return;

  await editorAPI.waitForChangesAppliedAsync();

  const page = pageRef || editorAPI.pages.getCurrentPage();

  const rootComponents = editorAPI.components
    .getChildren(page)
    .filter((childRef: CompRef) =>
      sectionsUtils.isSectionableComponent(
        editorAPI.documentServices,
        childRef,
      ),
    );

  if (rootComponents.length) return;

  const defaultPageHeight = editorAPI.isMobileEditor()
    ? constants.CONSTRAINS.PAGE_MIN_HEIGHT.MOBILE
    : constants.CONSTRAINS.PAGE_MIN_HEIGHT.DESKTOP;

  const { height: pageHeight } = editorAPI.components.layout.get_size(page);

  const blankSection = await waitForAddedCompRef(
    addBlankSection(scope, page, {
      height: Math.max(defaultPageHeight, pageHeight),
      y: 0,
    }),
  );

  await editorAPI.waitForChangesAppliedAsync();

  selection.selectComponentByCompRef(blankSection, {
    origin: 'after_action',
  });
};

export const createAddBlankSectionOnViewerChanges = (scope: Scope) => {
  let isAddingSection = true;

  addBlankSectionToEmptyPageIfNeeded(scope).then(
    () => (isAddingSection = false),
  );

  return async (changes: ViewerChangesData) => {
    if (isAddingSection) return;
    if (!changes?.some((change) => CHANGES_TO_REACT.includes(change))) return;

    isAddingSection = true;
    await addBlankSectionToEmptyPageIfNeeded(scope);
    isAddingSection = false;
  };
};
