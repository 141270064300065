import type { BiEventDefinition, BiEventFields } from 'types/bi';

type Origin = 'help_menu' | 'help_icon';

export enum HelpBlock {
  ARTICLES = 'ARTICLES',
  HIRE_PRO = 'HIRE_PRO',
  WELCOME_TOUR = 'WELCOME_TOUR',
  MEDIA_CAROUSEL = 'MEDIA_CAROUSEL',
  EDITOR_SEARCH = 'EDITOR_SEARCH',
}

interface HelpArticle {
  title: string;
  helpId: string;
}

export interface VideoItem {
  url: string;
  title: string;
  duration: string;
}

export interface StateProps {
  blocks: HelpBlock[];
  popularHelpArticles: HelpArticle[];
  hireProLink: string;
  videoItems: VideoItem[];
}

export interface DispatchProps {
  close: () => void;
  openHelpCenter: (helpId?: string) => void;
  startWelcomeTour: () => void;
  openSearch: () => void;
  openHowToVideo: (videoId: string) => void;
  onHireProClick: () => void;
  onVideoScrollClick: () => void;
  sendBi: (eventType: BiEventDefinition, biParams: BiEventFields) => void;
  onOpen: (blocks: HelpBlock[], articles: HelpArticle[]) => void;
}

export interface OwnProps {
  origin?: Origin;
}

// TODO: make own type for every block
export type Props = OwnProps & StateProps & DispatchProps;
