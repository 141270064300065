// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: [],
      sections: [
        addPanel.sections.getSections().other.fileDownloadButtonsSection,
      ],
    };
  },
};
