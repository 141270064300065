// @ts-nocheck
import React from 'react';
import { DATA_BINDING } from '@wix/app-definition-ids';
import { translate } from '@/i18n';
import PropTypes from 'prop-types';
import * as platform from '@/platform';
import _ from 'lodash';
import constants from '@/constants';
import dynamicPageSettingsNoDevModeTab from './tabs/dynamicPageSettingsNoDevMode';
import tabLayout from './tabs/tabLayout';
import tabPermissions from './tabs/tabPermissions';
import tabPageInfo from './tabs/tabPageInfoMainMenu';
import * as applicationSettingsPanelTabsMapper from './applicationSettingsPanelTabsMapper';
import applicationIframe from './tabs/applicationIframe/applicationIframe';
import tabSEO from './tabs/tabSEO';
import * as WixPromoteSEOPanel from './tabs/SEO/WixPromoteSEOPanel';
import SEOPanelHOC from './tabs/SEO/SeoPanelHOC';

import TabsHolder from './tabs/pageSettingsPanelTabsHolder';
import { isCustomOrAdvancedMenuEnabled, hoc, url, sections } from '@/util';
import PageSettingsLayoutTab from './tabs/PageSettingsLayoutTab/PageSettingsLayoutTab';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

class ApplicationSettingsPanelTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iframeLoaded: false,
      advancedSeoPanel: null,
    };

    this.getPostMessageHandler = (token) => {
      const initialData = {
        routerRef: this.props.routerRef,
        publicUrl: this.props.publicUrl,
        pageRef: this.props.pageRef,
        origin: 'dynamic_page_settings_panel_tab',
      };
      return platform.platformPostMessageService.createPostMessageListener(
        {
          token,
          initialData,
        },
        token,
      );
    };

    this.getAppData = () => {
      const managingAppDefId = this.props.pageData?.managingAppDefId;
      const appDefinitionId = managingAppDefId
        ? managingAppDefId
        : this.props.routerData?.appDefinitionId;
      if (appDefinitionId) {
        return {
          appDefId: appDefinitionId,
          applicationId:
            this.props.getAppDataByAppDefId(appDefinitionId)?.applicationId,
        };
      }
    };

    this.getIframeFullSrc = (url, appDefId, applicationId) => {
      const appData = this.props.getAppDataByAppDefId(appDefId);
      return platform.utils.getFullApplicationUrl(
        appData,
        url,
        appDefId,
        applicationId,
      );
    };

    this.iframeLoadedHandler = () => {
      this.setState({ iframeLoaded: true });
    };

    this.getTabContent = (tab, tabIndex) => {
      const consts = platform.constants;
      const className = this.state.iframeLoaded
        ? 'content-iframe loaded'
        : 'content-iframe';

      if (_.isObject(tab) && tab.url) {
        const appData = this.getAppData();
        let shouldShowNoDevModeTab;

        if (appData.appDefId === 'wix-code') {
          shouldShowNoDevModeTab = !this.props.developerModeEnabled;
        }
        if (appData.appDefId === DATA_BINDING) {
          shouldShowNoDevModeTab =
            !this.props.developerModeEnabled && !this.props.dataModeIsOn;
        }

        if (shouldShowNoDevModeTab) {
          return {
            panelClass: dynamicPageSettingsNoDevModeTab,
            tag: `dynamic-page-default-tab${this.props.menuItem.pageData.id}${tabIndex}${appData.appDefId}`,
            props: {},
          };
        }

        let src = this.getIframeFullSrc(
          tab.url,
          appData.appDefId,
          appData.applicationId,
        );

        if (isCustomOrAdvancedMenuEnabled()) {
          src = url.setUrlParams(src, {
            customMenusEnabled: true,
          });
        }

        return {
          panelClass: applicationIframe,
          tag: tab.type,
          name: tab.title,
          props: {
            key: `dynamic-page-iframe${this.props.menuItem.pageData.id}${tabIndex}${appData.appDefId}`,
            ref: `dynamic-page-iframe${this.props.menuItem.pageData.id}${appData.appDefId}`,
            className,
            appData,
            name: this.props.token,
            src,
            onPostMessage: this.getPostMessageHandler(this.props.token),
            onLoad: this.iframeLoadedHandler,
            iframeLoaded: this.state.iframeLoaded,
          },
        };
      }
      switch (tab.type) {
        case consts.pages.page_settings.layout.type:
          return {
            tag: constants.PAGE_SETTINGS_TABS.LAYOUT,
            name: translate('Pages_Layouts_Tab_Title'),
            panelClass: sections.isSectionsEnabled()
              ? PageSettingsLayoutTab
              : tabLayout,
            props: {
              menuItem: this.props.menuItem,
            },
          };
        case consts.pages.page_settings.permissions.type:
          return {
            tag: constants.PAGE_SETTINGS_TABS.PERMISSIONS,
            name: translate('Pages_Permissions_Tab_Title'),
            panelClass: tabPermissions,
            props: {
              pageData: this.props.menuItem.pageData,
            },
          };
        case consts.pages.page_settings.pageInfo.type:
          return {
            tag: constants.PAGE_SETTINGS_TABS.PAGE_INFO,
            name: translate('Pages_Info_Tab_Title'),
            panelClass: tabPageInfo,
            props: {
              menuItem: this.props.menuItem,
            },
          };
        case consts.pages.page_settings.seo.type:
          return this.getSEOTabs();
      }
    };

    this.getSEOPanel = () => {
      if (this.state.advancedSeoPanel) {
        return this.state.advancedSeoPanel;
      }
      WixPromoteSEOPanel.getSEOPanels().then((advancedSeoPanel) => {
        //eslint-disable-line new-cap
        this.setState({ advancedSeoPanel });
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
        const helpIds = _.reduce(
          advancedSeoPanel.tabs,
          (acc, tab) => {
            acc[tab.name] = tab.helpArticleId;
            return acc;
          },
          {},
        );
        this.props.setHelpIds(helpIds);
      });
      return null;
    };

    this.getSEOTabs = () => {
      const seoPanel = this.getSEOPanel();

      if (!seoPanel) {
        return [
          {
            tag: constants.PAGE_SETTINGS_TABS.SEO,
            name: translate('Pages_SEO_Social_Tab_Title'),
            panelClass: tabSEO,
            props: {
              pageData: this.props.menuItem.pageData,
            },
          },
        ];
      }

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      return _.map(seoPanel.tabs, (tab) => ({
        tag: tab.name,
        name: translate(tab.label_key),
        panelClass: SEOPanelHOC(tab.PanelClass), //eslint-disable-line new-cap
        props: {
          pageData: this.props.menuItem.pageData,
          tabName: tab.name,
          setActiveTab: this.props.setActiveTabType,
        },
      }));
    };

    this.getTabsPanel = () => {
      const consts = platform.constants;
      let tabs = [];
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(this.props.applicationTabs, (tab, index) => {
        if (
          this.props.hideLayoutTab &&
          tab.type === consts.pages.page_settings.layout.type
        ) {
          return;
        }
        const tabResult = this.getTabContent(tab, index);
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
        if (_.isArray(tabResult)) {
          tabs = tabs.concat(tabResult);
        } else {
          tabs.push(tabResult);
        }
      });

      return this.enhanceTabs(tabs);
    };
  }

  isTabDisabled(tab) {
    return this.props.disabledTabs?.includes(tab.tag);
  }

  enhanceTabs(tabs) {
    return tabs.map((tab) => ({
      ...tab,
      disabled: this.isTabDisabled(tab),
    }));
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.iframeLoaded !== nextState.iframeLoaded) {
      return true;
    }
    if (this.state.advancedSeoPanel !== nextState.advancedSeoPanel) {
      return true;
    }
    if (this.props.routerData !== nextProps.routerData) {
      return true;
    }
    if (this.props.developerModeEnabled !== nextProps.developerModeEnabled) {
      return true;
    }
    if (
      this.props.menuItem.pageData.id !== nextProps.menuItem.pageData.id ||
      (nextProps.selectedTabType &&
        this.props.selectedTabType !== nextProps.selectedTabType)
    ) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const pageChanged =
      this.props.menuItem.pageData.id !== nextProps.menuItem.pageData.id;
    const tabChanged =
      nextProps.selectedTabType &&
      this.props.selectedTabType !== nextProps.selectedTabType;
    if (pageChanged || tabChanged) {
      this.setState({ iframeLoaded: false });
    }
  }

  render() {
    return <TabsHolder tabs={this.getTabsPanel()} {...this.props} />;
  }
}
ApplicationSettingsPanelTabs.displayName = 'applicationSettingsPanelTabs';
ApplicationSettingsPanelTabs.propTypes = {
  pageRef: PropTypes.object.isRequired,
  routerRef: PropTypes.object,
  routerData: PropTypes.object,
  getAppDataByAppDefId: PropTypes.func.isRequired,
  pageData: PropTypes.object.isRequired,
  publicUrl: PropTypes.string,
  menuItem: PropTypes.object.isRequired,
  selectedTabType: PropTypes.string,
  setActiveTabType: PropTypes.func.isRequired,
  applicationTabs: PropTypes.array.isRequired,
  setHelpIds: PropTypes.func.isRequired,
  disabledTabs: PropTypes.arrayOf(PropTypes.string),
};

const Connected = connect(
  EDITOR_API,
  applicationSettingsPanelTabsMapper.mapStateToProps,
  applicationSettingsPanelTabsMapper.mapDispatchToProps,
)(ApplicationSettingsPanelTabs);
Connected.pure = ApplicationSettingsPanelTabs;
export default Connected;
