import React from 'react';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import { translate } from '@/i18n';
import { Composites, TextInput, TextLabel } from '@wix/wix-base-ui';
import type { MapDispatchToProps } from 'types/redux';
import { OldMessagePanelFrame } from '../../frames';

const defaultTranslations = {
  panelTitle: 'MyDesigns_RenameDesign_Popup_Header',
  confirmLabel: 'MyDesigns_RenameDesign_Popup_Button_Save',
  cancelLabel: 'MyDesigns_RenameDesign_Popup_Button_Cancel',
  inputLabel: 'MyDesigns_RenameDesign_Popup_Edit_Name_Label',
  inputPlaceholder: 'MyDesigns_SaveDesign_Popup_Name_Placeholder',
  inputInvalidMessage: 'MyDesigns_RenameDesign_Popup_Rename_Error_Empty',
};

type Translations = typeof defaultTranslations;
type TranslationKey = keyof Translations;

interface RenameSavedComponentsPanelOwnProps {
  name: string;
  onConfirm: (newName: string) => void;
  panelName: string;

  translations?: Translations;
}

interface RenameSavedComponentsPanelDispatchProps {
  close: () => void;
}
interface RenameSavedComponentsPanelProps
  extends RenameSavedComponentsPanelOwnProps,
    RenameSavedComponentsPanelDispatchProps {}

interface RenameSavedComponentsPanelState {
  value: string;
  isValid: boolean;
  isPristine: boolean;
}

class RenameSavedComponentsPanel extends React.Component<
  RenameSavedComponentsPanelProps,
  RenameSavedComponentsPanelState
> {
  constructor(props: RenameSavedComponentsPanelProps) {
    super(props);

    this.state = this.initialState;

    this.validator = this.validator.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.submit = this.submit.bind(this);
  }

  get initialState(): RenameSavedComponentsPanelState {
    return {
      value: this.props.name,
      isValid: false,
      isPristine: true,
    };
  }

  static displayName = 'renameSavedComponentsPanel';

  getTranslationKey = (key: TranslationKey): string => {
    return this.props.translations?.[key] || defaultTranslations[key];
  };

  validator(value: string) {
    const isValid = Boolean(value);

    this.setState({ isValid });

    return isValid;
  }

  handleChange(value: string) {
    this.setState({ value, isPristine: false });
  }

  handleKeyDown(e: React.KeyboardEvent) {
    const ENTER_KEY = 13;

    if (e.keyCode === ENTER_KEY) {
      this.submit();
    }
  }

  submit() {
    if (this.state.isValid) {
      this.props.onConfirm(this.state.value);
      this.props.close();
    }
  }

  isConfirmDisabled() {
    return this.state.isPristine || !this.state.isValid;
  }

  render() {
    return (
      <OldMessagePanelFrame
        panelName={this.props.panelName}
        panelTitle={translate(this.getTranslationKey('panelTitle'))}
        confirmLabel={this.getTranslationKey('confirmLabel')}
        cancelLabel={this.getTranslationKey('cancelLabel')}
        confirmDisabled={this.isConfirmDisabled()}
        onConfirm={this.submit}
        className="rename-saved-components-panel"
      >
        <Composites.TextInputLabeled>
          <TextLabel value={this.getTranslationKey('inputLabel')} />
          <TextInput
            value={this.state.value}
            automationId="saved-elements-rename-input"
            // @ts-expect-error
            validateOnBlurOnly={true}
            validateInitialValue={false}
            validator={this.validator}
            maxLength={255}
            autoSelect={true}
            onKeyDown={this.handleKeyDown}
            placeholder={this.getTranslationKey('inputPlaceholder')}
            invalidMessage={this.getTranslationKey('inputInvalidMessage')}
            onChange={this.handleChange}
          />
        </Composites.TextInputLabeled>
      </OldMessagePanelFrame>
    );
  }
}

const mapDispatchToProps: MapDispatchToProps<
  RenameSavedComponentsPanelDispatchProps,
  RenameSavedComponentsPanelOwnProps
> = (dispatch, props) => ({
  close: () =>
    dispatch(
      stateManagement.panels.actions.closePanelByNameAction(props.panelName),
    ),
});

export default util.hoc.connect(
  util.hoc.STORES.STATE_ONLY,
  undefined,
  mapDispatchToProps,
)(RenameSavedComponentsPanel);
