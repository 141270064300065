import React from 'react';
import ReactDOM from 'react-dom';
import type { ExtensionMountedModel } from '@wix/extensions-registry';
import type { EditorStore } from '@/stateManagement';
import { ExtensionDataProvider } from '@wix/extensions-slots-host';
import {
  EditorSlotsStateProvider,
  EditorOpenedPanelsStateProvider,
} from '@/extensionSlots';
import { Provider as StoreProvider } from 'react-redux';
import { registryHost } from './registry-host';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { isDebugMode } from '@/util';

const reportExtensionRenderError = (extensionId: string, error: Error) => {
  if (isDebugMode()) {
    console.error(
      `[editor-extensions]: "${extensionId}" extension has been removed from render tree - `,
      error,
    );
  }

  ErrorReporter.captureException(error, {
    tags: {
      component: 'editor-extensions-error-boundary',
      extensionId,
    },
  });
};

function mountExtensionsRootNode(): HTMLElement {
  const root = window.document.getElementById('root');
  const extensionsRootEl = window.document.createElement('div');

  extensionsRootEl.style.display = 'none';
  extensionsRootEl.setAttribute('id', 'extensions-root');

  root.appendChild(extensionsRootEl);

  return extensionsRootEl;
}

// TODO: move this component to https://github.com/wix-private/editor-platform
class ErrorBoundary extends React.Component<
  {
    extensionId: string;
    onError: (extensionId: string, error: Error) => void;
  },
  { hasError: boolean }
> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state = { hasError: false };

  componentDidCatch(error: Error): void {
    this.props.onError(this.props.extensionId, error);
  }

  render() {
    return !this.state.hasError ? this.props.children : null;
  }
}

export function render(
  componentModels: ExtensionMountedModel[],
  editorStore: EditorStore,
  onRendered: () => void,
) {
  const store = {
    ...editorStore,
    // NOTE: this workaround is needed to use editor's connect for extensions
    getState() {
      return {
        state: editorStore.getState(),
      };
    },
  };

  ReactDOM.render(
    <StoreProvider store={store}>
      <EditorSlotsStateProvider>
        <EditorOpenedPanelsStateProvider>
          {componentModels.map(({ id, component: ExtensionRoot }) => (
            <ExtensionDataProvider
              key={id}
              extensionId={id}
              {...registryHost.getBoundedParams(id)}
            >
              <ErrorBoundary
                extensionId={id}
                onError={reportExtensionRenderError}
              >
                <ExtensionRoot />
              </ErrorBoundary>
            </ExtensionDataProvider>
          ))}
        </EditorOpenedPanelsStateProvider>
      </EditorSlotsStateProvider>
    </StoreProvider>,
    mountExtensionsRootNode(),
    onRendered,
  );
}
