import React from 'react';

import { Divider, Text } from '@wix/wix-base-ui';
import CollectionItem from '../collectionItem';
import styles from './collectionsSection.scss';

import { isSharedCollection } from '../utils/sharedCollection';
import type { Section } from '../utils/collection';
import type { CollectionsListDispatchProps } from '../collectionsList/collectionsList.mapper';

interface CollectionsSectionProps {
  section: Section;
  sendBi: CollectionsListDispatchProps['sendBi'];
}

export const CollectionsSection: React.FC<CollectionsSectionProps> = ({
  section,
  sendBi,
}) => {
  return (
    <div key={section.namespace} data-hook={`${section.namespace}-section`}>
      <div className={styles.sectionHeader}>
        <Text size="small" shouldTranslate={false} dataHook="section-heading">
          {section.namespace}
        </Text>
      </div>
      <Divider direction="horizontal" long />
      {section.collections.map((collection) => (
        <CollectionItem
          key={collection.id}
          collectionId={collection.id}
          collectionName={collection.displayName}
          collectionPermissions={collection.permissions}
          iconName={
            collection?.plugins?.singleItem
              ? 'singleItemCollectionItemIcon'
              : 'userCollectionItemIcon'
          }
          isEditable={section.isEditable}
          isSharedCollection={isSharedCollection(collection)}
          aid="collection-item"
          sendBi={sendBi}
        />
      ))}
    </div>
  );
};
