// @ts-nocheck
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import PMS from 'pms';
import * as core from '@/core';
import * as helpIds from '@/helpIds';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';

import React from 'react';
import * as baseUI from '@/baseUI';
import { FocusPanelFrame } from '../frames';
import { cx } from '@/util';

const mediaSources = {
  ICON_SOCIAL: 'social_icons',
  IMAGE: 'gallery_image',
};
const SOCIAL_GALLERY_TYPE = 'socialIcons';
const orgImagesEvents = coreBi.events.organizeImages;
const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

function setDialog() {
  //LOG.reportEvent(wixEvents.ORGIMAGES_DIALOG_LOADED, {c1: 'new Organize Images'});
  const eAPI = this.getEditorAPI();

  eAPI.bi.event(orgImagesEvents.ORGIMAGES_DIALOG_LOADED, {
    c1: 'new Organize Images',
  });
  this._isReady = true;
  const dialogData = getDialogData.call(this);
  this._pms.send('set', dialogData);
}

function getDialogData() {
  return {
    images: this.props.imageDatas,
    translation: util.imageOrganizerUtils.getTranslation(
      this.props.galleryType,
    ),
    topology: util.serviceTopology,
    isPreset: this.props.isPreset,
    userSets: this.userSets,
  };
}

function openHelpCenter(options) {
  const helpId = options.data?.helpId;

  if (helpId) {
    const biHelpParams = {
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
      component: this.getEditorAPI().selection.getSelectedComponentType(),
    };
    this.getEditorAPI().panelManager.openHelpCenter(
      helpId,
      null,
      null,
      biHelpParams,
    );
  }
}

function showDialog() {
  const frame = getIFrameElem.call(this);

  let title, helpId;
  if (this.props.galleryType === SOCIAL_GALLERY_TYPE) {
    helpId = '6377d68a-a81f-4fd7-a675-f5a79f7e7ea9';
    title = translate('ORGANIZE_ICONS_DIALOG_TITLE');
  } else {
    helpId = helpIds.EXTERNAL.ORGANIZE_IMAGES;
    title = translate('OrganizeImages_IMAGES_DIALOG_TITLE');
  }

  this.setState({
    helpId,
    title,
    show: true,
  });
  frame.contentWindow.focus();
}

function getIFrameElem() {
  return ReactDOM.findDOMNode(this.refs.iframe);
}

function createNewImageData(data) {
  const newData = this.props.createImageDataItem();
  _.merge(newData, data);
  return newData;
}

function mergeIntoExistingData(images, newData) {
  let res;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const imageFromProps = _.find(images, { id: newData.id });
  if (imageFromProps) {
    res = _.merge(imageFromProps.data, newData.data);
  }
  return res;
}

function validateImageData(imageData) {
  if ((imageData?.title?.length ?? 0) > 100) {
    //100 is the max length for image title in IMAGE data schema
    imageData.title = imageData.title.substring(0, 100);
  }
}

function save(e) {
  const images = e.data;
  const originalImageDatas = this.props.imageDatas;
  let imageData;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const modifiedImages = _.map(
    images,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/bind
    _.bind(function (image) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      if (_.includes(image.id, 'new_')) {
        imageData = createNewImageData.call(this, image.data);
      } else {
        imageData = mergeIntoExistingData(originalImageDatas, image);
      }
      validateImageData(imageData);
      return imageData;
    }, this),
  );

  this.props.imagesChanged(modifiedImages);
}

function hideDialog() {
  this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
}

function triggerBiOnChanges(e) {
  const eAPI = this.getEditorAPI();
  const eData = e.data;
  const reportEvent = eAPI.bi.event;

  console.info('bi events', e);
  _.forOwn(eData, function (value, key) {
    reportEvent(orgImagesEvents[key], eData[key]);
  });
}

function triggerBiEvent(e) {
  const eAPI = this.getEditorAPI();
  const eData = e.data;
  const params = eData.params || {};

  console.info('bi events', e);
  eAPI.bi.event(orgImagesEvents[eData.name], params);
}

function openLinkDialog(data) {
  data = data.data;
  const eAPI = this.getEditorAPI();

  eAPI.openLinkPanel({
    defaultLinkType: 'ExternalLink',
    link: data.data.link,

    callback: function (newLinkData) {
      if (!newLinkData || !newLinkData.type) {
        newLinkData = null;
      }
      this._pms.send('setLink', {
        link: newLinkData,
        linkText: newLinkData
          ? util.link.getLinkValueAsString(eAPI, newLinkData, true)
          : '',
        imgData: data,
        id: data.id,
      });
    }.bind(this),
  });
}

function openMediaGalleryInternal(isMultiSelect, callback, isReplace) {
  let mediaServicesGalleryType;
  const translations = {
    title: 'ORGANIZE_IMAGES_MEDIA_MNGR_TITLE',
    submit: 'ORGANIZE_IMAGES_MEDIA_MNGR_SUBMIT',
  };
  const { mediaServices } = this.getEditorAPI();
  const { mediaManager } = mediaServices;

  switch (this.props.galleryType) {
    case SOCIAL_GALLERY_TYPE:
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(translations, function (item, key) {
        translations[key] = item.replace('IMAGES', 'ICONS');
      });
      mediaServicesGalleryType = mediaManager.categories.ICON_SOCIAL;
      break;
    default:
      mediaServicesGalleryType = mediaManager.categories.IMAGE;
      break;
  }

  const quantitySuffix = isMultiSelect ? '_MANY' : '_ONE';

  const openSource = mediaSources[mediaServicesGalleryType];

  mediaManager.open(mediaServicesGalleryType, openSource, {
    multiSelect: isMultiSelect,
    translation: {
      title: translate(translations.title + quantitySuffix),
      submitButton: isReplace
        ? translate('MMGR_submitbutton_gallery_uploads_choose')
        : translate('MMGR_submitbutton_gallery_uploads_add'),
    },
    callback: (items, info) => {
      callback.call(this, items, info);
      mediaServices.setFocusToEditor();
    },
  });
}

let idCounter = 0;

function getNewID() {
  return `new_${idCounter++}`;
}

// eslint-disable-next-line react/prefer-es6-class
const OrganizeImagesPanel = createReactClass({
  displayName: 'organizeImagesPanel',
  mixins: [util.postMessageMixin, core.mixins.editorAPIMixin],
  propTypes: {
    imageDatas: PropTypes.arrayOf(PropTypes.object).isRequired,
    isPreset: PropTypes.bool.isRequired,
    galleryType: PropTypes.oneOf(['images', SOCIAL_GALLERY_TYPE]).isRequired,
    imagesChanged: PropTypes.func.isRequired,
    createImageDataItem: PropTypes.func.isRequired,
  },

  getInitialState() {
    this.userSets =
      stateManagement.userPreferences.selectors.getGlobalUserPreferences(
        'OrganizeImages',
      )(this.getEditorAPI().store.getState());

    this._pms = new PMS({
      connectionID: 'OrganizeImages',
    });

    this._pms.on('ready', setDialog, this);
    this._pms.on('setup', showDialog, this);
    this._pms.on('closed', hideDialog, this);
    this._pms.on('openLinkDialog', openLinkDialog, this);
    this._pms.on('save', save, this);
    this._pms.on('bi', triggerBiEvent, this);
    this._pms.on('biChanges', triggerBiOnChanges, this);
    this._pms.on('openHelpCenter', openHelpCenter, this);
    this._pms.on('openMediaGallery', this.openMediaGallery, this);
    this._pms.on(
      'openMediaGalleryForReplace',
      this.openMediaGalleryForReplace,
      this,
    );

    return {
      show: false,
    };
  },

  openMediaGallery() {
    openMediaGalleryInternal.call(
      this,
      true,
      function (newImagesListFromMediaGallery) {
        if (!newImagesListFromMediaGallery) {
          return;
        }

        const newImagesList = [];

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(
          newImagesListFromMediaGallery,
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/bind
          _.bind(function (data) {
            const imageData = createNewImageData.call(this, {
              uri: data.fileName,
              height: data.height,
              width: data.width,
              title: data.title || data.fileName,
              hasAnimation: !!data.fileInput?.animated,
            });
            newImagesList.push({
              id: getNewID(),
              data: imageData,
              linkText: '',
            });
          }, this),
        );

        this._pms.send('add', newImagesList);
      },
      false,
    );
  },

  openMediaGalleryForReplace(replaceData) {
    const { position } = replaceData.data;
    openMediaGalleryInternal.call(
      this,
      false,
      function (newImagesListFromMediaGallery) {
        if (!newImagesListFromMediaGallery) {
          return;
        }
        const newImagesList = [];

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(
          newImagesListFromMediaGallery,
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/bind
          _.bind(function (data) {
            const imageData = createNewImageData.call(this, {
              uri: data.fileName,
              height: data.height,
              width: data.width,
              title: data.title || data.fileName,
              hasAnimation: !!data.fileInput?.animated,
            });
            newImagesList.push({
              id: getNewID(),
              data: imageData,
              linkText: '',
            });
          }, this),
        );

        if (newImagesList.length <= 0) {
          return;
        }

        this._pms.send('replace', { position, data: newImagesList[0] });
      },
      true,
    );
  },

  getFrameUrl() {
    return util.imageOrganizerUtils.getFrameUrl(this.props.galleryType);
  },

  componentDidMount() {
    this._pms.setTargetWindow(getIFrameElem.call(this).contentWindow);
    getIFrameElem.call(this).addEventListener('load', this.onFrameLoad);
  },

  onFrameLoad() {
    this._pms.on('setUserSets', this.setUserSettings, this);
  },

  setUserSettings(e) {
    const userSets = (this.userSets = e.data);
    this.getEditorAPI().store.dispatch(
      stateManagement.userPreferences.actions.setGlobalUserPreferences(
        'OrganizeImages',
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign(userSets, { saveNow: true }),
      ),
    );
  },

  onPanelClose() {
    const eAPI = this.getEditorAPI();
    if (!this._isReady) {
      return true;
    }

    this._pms.send('saveAndClose');
    eAPI.bi.event(orgImagesEvents.ORGIMAGES_CLICK_CLOSE);
    return false;
  },

  render() {
    return (
      <FocusPanelFrame
        panelName={this.props.panelName}
        helpId={this.state.helpId}
        shouldNotCloseOnBlur={true}
        shouldHideHeader={true}
        onClose={this.onPanelClose}
        width={'80%'}
        height={Math.round(0.8 * window.innerHeight).toString()}
        className="organize-images-panel"
      >
        <iframe
          id="organizeImages"
          ref="iframe"
          onLoad={this.onLoad}
          width="100%"
          height="100%"
          src={this.getFrameUrl()}
          frameBorder="0"
          // TODO: cleanup later
          // eslint-disable-next-line react/no-unknown-property
          allowTransparency
          className={cx({ hidden: !this.state.show, transition: true })}
        />

        <baseUI.preloader
          ref="preloader"
          className={cx({ hidden: this.state.show, transition: true })}
        />
      </FocusPanelFrame>
    );
  },
});

function getImageDataList(rootData, editorAPI) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map((rootData || {}).items, function (imageData) {
    const linkData = imageData.link;

    return {
      id: getNewID(),
      data: imageData,
      linkText: linkData
        ? util.link.getLinkValueAsString(editorAPI, linkData, true)
        : '',
    };
  });
}

const mapEditorAPIToProps = ({ editorAPI }) => {
  const comps = editorAPI.selection.getSelectedComponents();
  const data = editorAPI.components.data.get(_.head(comps));

  return {
    imageDatas: getImageDataList(data, editorAPI),
  };
};

const ConnectedPanel = connect(
  EDITOR_API,
  mapEditorAPIToProps,
)(OrganizeImagesPanel);
ConnectedPanel.pure = OrganizeImagesPanel;

export default ConnectedPanel;
