export default {
  getDesignSections() {
    return {
      titles: ['design_section_label_explore_checkbox'],
      sections: [
        {
          type: 'SECTIONS_TYPES_PRESET',
          hide: false,
          title: 'add_section_label_Checkbox',
          showSectionHeader: true,
          additionalBehaviours: {
            labelMode: 'none',
            infoIcon: 'none',
            hoverImageAction: 'none',
            iconEnabledForComps: {},
          },
          props: {
            image: 'addPanelData/sections/developer_en/checkboxSection_en.png',
            imageHover: null as AnyFixMe,
            items: [
              {
                id: 'User_Input_Checkbox_1',
                structure: {
                  layout: {
                    width: 18,
                    height: 18,
                    x: 0,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  componentType: 'wysiwyg.viewer.components.inputs.Checkbox',
                  type: 'Component',
                  skin: 'wysiwyg.viewer.skins.input.CheckboxBasicSkin',
                  data: {
                    type: 'CheckboxInput',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                    },
                    value: '',
                    checked: true,
                  },
                  props: {
                    type: 'CheckboxProperties',
                    metaData: {
                      schemaVersion: '1.0',
                    },
                    required: false,
                    autoFocus: false,
                  },
                  style: {
                    type: 'TopLevelStyle',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                    },
                    style: {
                      properties: {
                        'alpha-bg': '1',
                        'alpha-bge': '1',
                        'alpha-bgh': '1',
                        'alpha-brd': '1',
                        'alpha-brdd': '1',
                        'alpha-brde': '0',
                        'alpha-brdh': '1',
                        bg: '#FFFFFF',
                        bgc: '#737373',
                        bgcd: '#FFFFFF',
                        bgcf: '#FFFFFF',
                        bgch: '#525252',
                        bgd: '#DBDBDB',
                        bge: '#FF4040',
                        bgf: '#FFFFFF',
                        bgh: '#FFFFFF',
                        'boxShadowToggleOn-shd': 'false',
                        brd: '#737373',
                        brdd: '#DBDBDB',
                        brde: '#FF4040',
                        brdf: '#919191',
                        brdh: '#525252',
                        brfh: '#3D9BE9',
                        brw: '2',
                        brwe: '2',
                        brwf: '2',
                        brwh: '2',
                        rd: '0px',
                        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                        txt: '#000000',
                        fnt: 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
                      },
                      propertiesSource: {
                        'alpha-bg': 'value',
                        'alpha-bge': 'value',
                        'alpha-bgh': 'value',
                        'alpha-brdd': 'value',
                        'alpha-brde': 'value',
                        bg: 'value',
                        bgc: 'value',
                        bgcd: 'value',
                        bgcf: 'value',
                        bgch: 'value',
                        bgd: 'value',
                        bge: 'value',
                        bgf: 'value',
                        bgh: 'value',
                        brd: 'value',
                        brdd: 'value',
                        brde: 'value',
                        brdf: 'value',
                        brdh: 'value',
                        brw: 'value',
                        brwe: 'value',
                        brwf: 'value',
                        brwh: 'value',
                        rd: 'value',
                        shd: 'value',
                        txt: 'value',
                        fnt: 'value',
                      },
                    },
                    componentClassName:
                      'wysiwyg.viewer.components.inputs.Checkbox',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.input.CheckboxBasicSkin',
                  },
                  connections: {
                    type: 'ConnectionList',
                    items: [
                      {
                        type: 'WixCodeConnectionItem',
                        role: 'checkbox4',
                      },
                    ],
                  },
                },
                preset: {
                  rect: {
                    width: 324,
                    height: 54,
                    x: 0,
                    y: 0,
                  },
                },
              },
              {
                id: 'User_Input_Checkbox_2',
                structure: {
                  layout: {
                    width: 17,
                    height: 17,
                    x: 0,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  componentType: 'wysiwyg.viewer.components.inputs.Checkbox',
                  type: 'Component',
                  skin: 'wysiwyg.viewer.skins.input.CheckboxBasicSkin',
                  data: {
                    type: 'CheckboxInput',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                    },
                    value: '',
                    checked: true,
                  },
                  props: {
                    type: 'CheckboxProperties',
                    metaData: {
                      schemaVersion: '1.0',
                    },
                    required: false,
                    autoFocus: false,
                  },
                  style: {
                    type: 'TopLevelStyle',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                    },
                    style: {
                      properties: {
                        'alpha-bg': '1',
                        'alpha-bgh': '1',
                        'alpha-brd': '1',
                        'alpha-brdd': '0',
                        'alpha-brdh': '1',
                        bg: '#49D6D6',
                        bgcd: '#FFFFFF',
                        bgcf: '#FFFFFF',
                        bgch: '#49D6D6',
                        bgd: '#E8E8E8',
                        bge: '#FF4040',
                        bgf: '#49D6D6',
                        bgh: '#FFFFFF',
                        'boxShadowToggleOn-shd': 'true',
                        brd: '#FFFFFF',
                        brdf: '#49D6D6',
                        brdh: '#49D6D6',
                        brfh: '#3D9BE9',
                        brw: '1',
                        brwf: '0',
                        brwh: '1',
                        rd: '0px',
                        shd: '0.00px 0.00px 0px 1px rgba(73,214,214,1)',
                        txt: '#000000',
                        fnt: 'italic normal normal 16px/1.4em georgia',
                      },
                      propertiesSource: {
                        'alpha-bg': 'value',
                        'alpha-bgh': 'value',
                        'alpha-brdd': 'value',
                        bg: 'value',
                        bgcd: 'value',
                        bgcf: 'value',
                        bgch: 'value',
                        bgd: 'value',
                        bge: 'value',
                        bgf: 'value',
                        bgh: 'value',
                        brd: 'value',
                        brdf: 'value',
                        brdh: 'value',
                        brw: 'value',
                        brwf: 'value',
                        brwh: 'value',
                        rd: 'value',
                        shd: 'value',
                        txt: 'value',
                        fnt: 'value',
                      },
                    },
                    componentClassName:
                      'wysiwyg.viewer.components.inputs.Checkbox',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.input.CheckboxBasicSkin',
                  },
                  connections: {
                    type: 'ConnectionList',
                    items: [
                      {
                        type: 'WixCodeConnectionItem',
                        role: 'checkbox7',
                      },
                    ],
                  },
                },
                preset: {
                  rect: {
                    width: 324,
                    height: 48,
                    x: 0,
                    y: 54,
                  },
                },
              },
              {
                id: 'User_Input_Checkbox_3',
                structure: {
                  layout: {
                    width: 19,
                    height: 19,
                    x: 0,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  componentType: 'wysiwyg.viewer.components.inputs.Checkbox',
                  type: 'Component',
                  skin: 'wysiwyg.viewer.skins.input.CheckboxBasicSkin',
                  data: {
                    type: 'CheckboxInput',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                    },
                    value: '',
                    checked: true,
                  },
                  props: {
                    type: 'CheckboxProperties',
                    metaData: {
                      schemaVersion: '1.0',
                    },
                    required: false,
                    autoFocus: false,
                  },
                  style: {
                    type: 'TopLevelStyle',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                    },
                    style: {
                      properties: {
                        'alpha-bg': '1',
                        'alpha-bgd': '1',
                        'alpha-bgf': '1',
                        'alpha-bgh': '1',
                        'alpha-brd': '1',
                        'alpha-brdd': '0',
                        'alpha-brdh': '1',
                        bg: '#4BDB7B',
                        bgc: '#FFFFFF',
                        bgcd: '#FFFFFF',
                        bgcf: '#FFFFFF',
                        bgch: '#4BDB7B',
                        bgd: '#E8E8E8',
                        bge: '#FF4040',
                        bgf: '#4BDB7B',
                        bgh: '#FFFFFF',
                        'boxShadowToggleOn-shd': 'false',
                        brd: '#4BDB7B',
                        brdf: '#4BDB7B',
                        brdh: '#4BDB7B',
                        brfh: '#3D9BE9',
                        brw: '1',
                        brwf: '1',
                        brwh: '1',
                        rd: '4px',
                        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                        txt: '#4BDB7B',
                        fnt: 'normal normal normal 15px/1.4em bree-w01-thin-oblique',
                      },
                      propertiesSource: {
                        'alpha-bg': 'value',
                        'alpha-bgd': 'value',
                        'alpha-bgf': 'value',
                        'alpha-bgh': 'value',
                        'alpha-brd': 'value',
                        'alpha-brdd': 'value',
                        'alpha-brdh': 'value',
                        bg: 'value',
                        bgc: 'value',
                        bgcd: 'value',
                        bgcf: 'value',
                        bgch: 'value',
                        bgd: 'value',
                        bge: 'value',
                        bgf: 'value',
                        bgh: 'value',
                        brd: 'value',
                        brdf: 'value',
                        brdh: 'value',
                        brw: 'value',
                        brwf: 'value',
                        brwh: 'value',
                        rd: 'value',
                        shd: 'value',
                        txt: 'value',
                        fnt: 'value',
                      },
                    },
                    componentClassName:
                      'wysiwyg.viewer.components.inputs.Checkbox',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.input.CheckboxBasicSkin',
                  },
                  connections: {
                    type: 'ConnectionList',
                    items: [
                      {
                        type: 'WixCodeConnectionItem',
                        role: 'checkbox9',
                      },
                    ],
                  },
                },
                preset: {
                  rect: {
                    width: 324,
                    height: 49,
                    x: 0,
                    y: 102,
                  },
                },
              },
              {
                id: 'User_Input_Checkbox_4',
                structure: {
                  layout: {
                    width: 18,
                    height: 18,
                    x: 0,
                    y: 1,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  componentType: 'wysiwyg.viewer.components.inputs.Checkbox',
                  type: 'Component',
                  skin: 'wysiwyg.viewer.skins.input.CheckboxBasicSkin',
                  data: {
                    type: 'CheckboxInput',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                    },
                    value: '',
                    checked: true,
                  },
                  props: {
                    type: 'CheckboxProperties',
                    metaData: {
                      schemaVersion: '1.0',
                    },
                    required: false,
                    autoFocus: false,
                  },
                  style: {
                    type: 'TopLevelStyle',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                    },
                    style: {
                      properties: {
                        'alpha-bg': '0',
                        'alpha-bge': '1',
                        'alpha-bgf': '1',
                        'alpha-bgh': '1',
                        'alpha-brd': '1',
                        'alpha-brdd': '0',
                        'alpha-brdh': '1',
                        bg: '#919191',
                        bgc: '#8C84FA',
                        bgcd: '#FFFFFF',
                        bgcf: '#FFFFFF',
                        bgd: '#D9D9D9',
                        bge: '#FF4040',
                        bgf: '#8C84FA',
                        bgh: '#8C84FA',
                        'boxShadowToggleOn-shd': 'false',
                        brd: '#8C84FA',
                        brde: '#FF4040',
                        brdf: '#8C84FA',
                        brdh: '#8C84FA',
                        brfh: '#3D9BE9',
                        brw: '1',
                        brwe: '0',
                        brwf: '1',
                        brwh: '1',
                        rd: '0px',
                        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                        txt: '#000000',
                        fnt: 'normal normal normal 15px/1.4em futura-lt-w01-light',
                      },
                      propertiesSource: {
                        'alpha-bg': 'value',
                        'alpha-bge': 'value',
                        'alpha-bgf': 'value',
                        'alpha-bgh': 'value',
                        'alpha-brdd': 'value',
                        bg: 'value',
                        bgc: 'value',
                        bgcd: 'value',
                        bgcf: 'value',
                        bgd: 'value',
                        bge: 'value',
                        bgf: 'value',
                        bgh: 'value',
                        brd: 'value',
                        brde: 'value',
                        brdf: 'value',
                        brdh: 'value',
                        brw: 'value',
                        brwe: 'value',
                        brwf: 'value',
                        brwh: 'value',
                        rd: 'value',
                        shd: 'value',
                        txt: 'value',
                        fnt: 'value',
                      },
                    },
                    componentClassName:
                      'wysiwyg.viewer.components.inputs.Checkbox',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.input.CheckboxBasicSkin',
                  },
                  connections: {
                    type: 'ConnectionList',
                    items: [
                      {
                        type: 'WixCodeConnectionItem',
                        role: 'checkbox8',
                      },
                    ],
                  },
                },
                preset: {
                  rect: {
                    width: 324,
                    height: 50,
                    x: 0,
                    y: 151,
                  },
                },
              },
              {
                id: 'User_Input_Checkbox_5',
                structure: {
                  layout: {
                    width: 19,
                    height: 19,
                    x: 0,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  componentType: 'wysiwyg.viewer.components.inputs.Checkbox',
                  type: 'Component',
                  skin: 'wysiwyg.viewer.skins.input.CheckboxBasicSkin',
                  data: {
                    type: 'CheckboxInput',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                    },
                    value: '',
                    checked: true,
                  },
                  props: {
                    type: 'CheckboxProperties',
                    metaData: {
                      schemaVersion: '1.0',
                    },
                    required: false,
                    autoFocus: false,
                  },
                  style: {
                    type: 'TopLevelStyle',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                    },
                    style: {
                      properties: {
                        'alpha-bg': '1',
                        'alpha-bgd': '1',
                        'alpha-bgf': '1',
                        'alpha-bgh': '1',
                        'alpha-brd': '1',
                        'alpha-brdd': '0',
                        'alpha-brdh': '1',
                        bg: '#FFFFFF',
                        bgc: '#000000',
                        bgcd: '#FFFFFF',
                        bgcf: '#000000',
                        bgch: '#000000',
                        bgd: '#E8E8E8',
                        bge: '#FF4040',
                        bgf: '#FFFFFF',
                        bgh: '#FFFFFF',
                        'boxShadowToggleOn-shd': 'true',
                        brd: '#F5F5F5',
                        brdf: '#E8E8E8',
                        brdh: '#F5F5F5',
                        brfh: '#3D9BE9',
                        brw: '1',
                        brwf: '1',
                        brwh: '1',
                        rd: '4px',
                        shd: '0.00px 3.00px 9px 1px rgba(0,0,0,0.08)',
                        txt: '#000000',
                        fnt: 'normal normal normal 12px/1.4em lulo-clean-w01-one-bold',
                      },
                      propertiesSource: {
                        'alpha-bg': 'value',
                        'alpha-bgd': 'value',
                        'alpha-bgf': 'value',
                        'alpha-bgh': 'value',
                        'alpha-brd': 'value',
                        'alpha-brdd': 'value',
                        'alpha-brdh': 'value',
                        bg: 'value',
                        bgc: 'value',
                        bgcd: 'value',
                        bgcf: 'value',
                        bgch: 'value',
                        bgd: 'value',
                        bge: 'value',
                        bgf: 'value',
                        bgh: 'value',
                        brd: 'value',
                        brdf: 'value',
                        brdh: 'value',
                        brw: 'value',
                        brwf: 'value',
                        brwh: 'value',
                        rd: 'value',
                        shd: 'value',
                        txt: 'value',
                        fnt: 'value',
                      },
                    },
                    componentClassName:
                      'wysiwyg.viewer.components.inputs.Checkbox',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.input.CheckboxBasicSkin',
                  },
                  connections: {
                    type: 'ConnectionList',
                    items: [
                      {
                        type: 'WixCodeConnectionItem',
                        role: 'checkbox1',
                      },
                    ],
                  },
                },
                preset: {
                  rect: {
                    width: 324,
                    height: 49,
                    x: 0,
                    y: 201,
                  },
                },
              },
              {
                id: 'User_Input_Checkbox_6',
                structure: {
                  layout: {
                    width: 18,
                    height: 18,
                    x: 0,
                    y: 1,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  componentType: 'wysiwyg.viewer.components.inputs.Checkbox',
                  type: 'Component',
                  skin: 'wysiwyg.viewer.skins.input.CheckboxBasicSkin',
                  data: {
                    type: 'CheckboxInput',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                    },
                    value: '',
                    checked: true,
                  },
                  props: {
                    type: 'CheckboxProperties',
                    metaData: {
                      schemaVersion: '1.0',
                    },
                    required: false,
                    autoFocus: false,
                  },
                  style: {
                    type: 'TopLevelStyle',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                    },
                    style: {
                      properties: {
                        'alpha-bg': '1',
                        'alpha-bgh': '1',
                        'alpha-brd': '1',
                        'alpha-brdd': '0',
                        'alpha-brdh': '1',
                        bg: '#FFFFFF',
                        bgc: '#00A6FF',
                        bgcd: '#FFFFFF',
                        bgcf: '#FFFFFF',
                        bgch: '#00A6FF',
                        bgd: '#D9D9D9',
                        bge: '#FF4040',
                        bgf: '#00A6FF',
                        bgh: '#E6F5FE',
                        'boxShadowToggleOn-shd': 'false',
                        brd: '#6EB7FD',
                        brde: '#FF4040',
                        brdf: '#00A6FF',
                        brdh: '#6EB7FD',
                        brfh: '#3D9BE9',
                        brw: '1px',
                        brwf: '0',
                        brwh: '1px',
                        rd: '4px',
                        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                        txt: '#2B5672',
                        fnt: 'normal normal normal 16px/1.4em helvetica-w01-light',
                      },
                      propertiesSource: {
                        'alpha-bg': 'value',
                        'alpha-bgh': 'value',
                        'alpha-brdd': 'value',
                        bg: 'value',
                        bgc: 'value',
                        bgcd: 'value',
                        bgcf: 'value',
                        bgch: 'value',
                        bgd: 'value',
                        bge: 'value',
                        bgf: 'value',
                        bgh: 'value',
                        brd: 'value',
                        brde: 'value',
                        brdf: 'value',
                        brdh: 'value',
                        brw: 'value',
                        brwf: 'value',
                        brwh: 'value',
                        rd: 'value',
                        shd: 'value',
                        txt: 'value',
                        fnt: 'value',
                      },
                    },
                    componentClassName:
                      'wysiwyg.viewer.components.inputs.Checkbox',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.input.CheckboxBasicSkin',
                  },
                  connections: {
                    type: 'ConnectionList',
                    items: [
                      {
                        type: 'WixCodeConnectionItem',
                        role: 'checkbox10',
                      },
                    ],
                  },
                },
                preset: {
                  rect: {
                    width: 324,
                    height: 56,
                    x: 0,
                    y: 250,
                  },
                },
              },
            ],
          },
          help: {
            hide: true,
            text: '',
          },
        },
      ],
    };
  },
};
