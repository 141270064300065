import React from 'react';
import { Composites, PromotionalList } from '@wix/wix-base-ui';
import DescriptionLayout from './descriptionLayout';

interface PromoDescriptionProps {
  mainActionLabel: string;
  thirdActionLabel?: string;
  onMainActionClick: () => void;
  onSecondaryActionClick: () => void;
  onThirdActionClick?: () => void;
  showIconsOnButtons?: boolean;
}

const PromoDescription: React.FC<PromoDescriptionProps> = ({
  mainActionLabel,
  thirdActionLabel,
  onMainActionClick,
  onSecondaryActionClick,
  onThirdActionClick,
  showIconsOnButtons,
}) => (
  <DescriptionLayout
    title="NewPages_Panel_Add_Dynamic_Page_TitleCM"
    mainActionLabel={mainActionLabel}
    secondaryActionLabel="NewPages_Panel_Add_Dynamic_Page_LearnMoreCM"
    thirdActionLabel={thirdActionLabel}
    onMainActionClick={onMainActionClick}
    onSecondaryActionClick={onSecondaryActionClick}
    onThirdActionClick={onThirdActionClick}
    showIconsOnButtons={showIconsOnButtons}
  >
    <Composites.PromotionalList>
      <PromotionalList
        items={[
          'NewPages_Panel_Add_Dynamic_Page_Bullet1CM',
          'NewPages_Panel_Add_Dynamic_Page_Bullet2CM',
          'NewPages_Panel_Add_Dynamic_Page_Bullet3CM',
        ]}
      />
    </Composites.PromotionalList>
  </DescriptionLayout>
);

export default PromoDescription;
