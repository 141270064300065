// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['design_section_label_explore_quick_action_bar'], //here
      sections: [
        addPanel.sections.getSections().designPanelOnly.quickActionBarSection,
      ],
    };
  },
};
