import createReactClass from 'create-react-class';
import * as core from '@/core';
import * as util from '@/util';
import React from 'react';
import * as BaseUI from '@/baseUI';
import { cx } from '@/util';
import { BaseUiTextWrapper } from './baseUiTextWrapper';

interface RcmItemWithShortcutProps {
  automationId?: string;
  item: { enabled: boolean; icon?: { svgName?: string } };
  label: string;
  value?: string;
  shortcut?: string;
  onClick?: () => void;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<RcmItemWithShortcutProps>({
  displayName: 'rcmItemWithShortcut',
  mixins: [util.translationMixin as AnyFixMe, core.mixins.editorAPIMixin],

  getTooltipText() {
    const editorAPI = this.getEditorAPI();
    const tooltipText =
      editorAPI.tooltipTextService.getSelectedComponentTooltipText(
        this.props.item.operationKey,
      );

    return tooltipText;
  },

  render() {
    const label: string = this.translateIfNeeded(this.props.label);
    const shortcut: string = this.props.shortcut;
    const disabled = !this.props.item.enabled;

    return (
      <BaseUI.tooltip
        value={this.getTooltipText()}
        alignment="right"
        disabled={!this.getTooltipText()}
      >
        <li
          onClick={
            this.props.item.enabled ? this.props.onClick : function () {}
          }
          onContextMenu={
            this.props.item.enabled ? this.props.onClick : function () {}
          }
          data-aid={this.props.automationId || ''}
          className={cx({
            'item-shortcut': true,
            'item-shortcut-flex': true,
            disabled,
            hoverable: this.props.item.enabled,
          })}
        >
          {this.props.item.icon ? (
            <span key="icon" className="icon">
              {this.props.item.icon.svgName ? (
                <BaseUI.symbol name={this.props.item.icon.svgName} key="svg" />
              ) : null}
            </span>
          ) : null}
          <span className="label label-shortcut-small">
            <BaseUiTextWrapper text={label} disabled={disabled} />
          </span>
          <span className="action action-shortcut margin-left-auto action-relative">
            <BaseUiTextWrapper text={shortcut} disabled={disabled} />
          </span>
        </li>
      </BaseUI.tooltip>
    );
  },
});
