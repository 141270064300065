._section_180ae_1 {
  padding: 18px 24px; }

._heading_180ae_4 {
  display: flex; }

._link_180ae_7 {
  margin-left: auto; }

._content_180ae_10 {
  margin-top: 12px;
  border: 1px solid #e1e3e6;
  border-radius: 4px;
  overflow: hidden; }
