import type { Scope } from './scope';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import type { SnapshotParams } from './types';
import type { CompRef } from 'types/documentServices';
import {
  getInnerRouteFromCurrentNavInfo,
  getOpenedInlinePopup,
  updateSaveState,
} from './common';

interface HistoryAddParams extends Partial<SnapshotParams> {
  shouldSave?: boolean;
}

export function add(
  scope: Scope,
  label: string,
  params?: HistoryAddParams,
  amendToSnapshotWithId?: string,
) {
  const { editorAPI } = scope;
  editorAPI.store.dispatch(stateManagement.rulers.actions.resetHistory());
  const isMobileEditor = editorAPI.isMobileEditor();
  const selectedCompIds = params?.component_id
    ? [params.component_id]
    : editorAPI.selection.getSelectedComponents().map(({ id }: CompRef) => id);
  const selectedCompTypes = params?.component_type
    ? [params?.component_type]
    : selectedCompIds.map((compId: string) =>
        editorAPI.components.getType(editorAPI.components.get.byId(compId)),
      );

  const snapshotParams: SnapshotParams = {
    label,
    currentPage: editorAPI.dsRead.pages.getFocusedPageId(),
    currentInnerRoute: getInnerRouteFromCurrentNavInfo(scope),
    openedInlinePopup: getOpenedInlinePopup(scope),
    isMobileEditor,
    component_type: selectedCompTypes.join(','),
    component_id: selectedCompIds.join(','),
    date_of_action: util.bi.getDateInStringFormat(),
    ...(params || {}),
  };
  editorAPI.dsActions.history.add(label, snapshotParams, amendToSnapshotWithId);
  updateSaveState(scope);
}

export const amend = (scope: Scope) => {
  const { editorAPI } = scope;
  add(
    scope,
    editorAPI.dsActions.history.getUndoLastSnapshotLabel(),
    editorAPI.dsActions.history.getUndoLastSnapshotParams(),
    editorAPI.dsActions.history.getUndoLastSnapshotId(),
  );
};
