import constants from '@/constants';
import * as util from '@/util';

import type { PublishOptions, SaveAndPublishOptions } from '@/savePublish';
import type { SaveInteractionStartedSource } from 'types/fedops/saveInteraction';

const { SITE_MENU_ITEMS } = constants.ROOT_COMPS.TOPBAR;
const SAVE_YOUR_SITE_PANEL_NAME = 'savePublish.panels.save.saveYourSitePanel';
const SAVE_YOUR_SITE_PANEL_BODY_KEY = util.workspace.isNewWorkspaceEnabled()
  ? 'TOPBAR_SITE_DASHBOARD_FIRST_SAVE_SITE_TEXT'
  : 'TOPBAR_SITE_DASHBOARD_SAVE_FIRST_MSG_BODY';

interface SaveOptions {
  preventSiteSavedPanel: boolean;
  callback: () => void;
}

export interface SiteMenuAPIDispatchProps {
  togglePreview: (
    callback?: Function,
    params?: {
      biParams?: { origin?: string };
    },
  ) => void;
  getInvalidLoggedUser: () => Promise<string | null>;
  handleInvalidLoggedUser: (invalidStatus: string) => void;
  sendBiForLoginRelatedError: () => void;
  openPanel: (
    panelName: string,
    panelProps?: Record<string, any>,
    leavePanelsOpen?: boolean,
  ) => void;
  goToDashboard: () => void;
  myAccountAction: (action: string) => void;
  save: (origin?: string, sourceOfStart?: SaveInteractionStartedSource) => void;
  saveSite: (options: SaveOptions) => void;
  publishRC: (origin?: string) => void;
  publish: (origin?: string) => void;
  siteSaveAndPublish: (options: SaveAndPublishOptions) => void;
  sitePublish: (options: PublishOptions) => void;
  openReleaseManager: () => void;
  openSiteBranchesManager: () => void;
  openRevisions: () => void;
  setTopBarSiteHistoryUsed: () => void;
  exitEditor: () => void;
  openAccountSettings: (path: string) => void;
}

export interface SiteMenuAPIStateProps {
  metaSiteId: string;
  isFirstSave: boolean;
  isDraftMode: boolean;
  canUserPublish: boolean;
  shouldSaveBeforePublish: boolean;
  isSitePublished: boolean;
  sitePublicUrl: string;
}

export interface SiteMenuAPIOwnProps {}

export interface SiteMenuAPIProps
  extends SiteMenuAPIOwnProps,
    SiteMenuAPIStateProps,
    SiteMenuAPIDispatchProps {}

export const getSiteMenuAPI = (props: SiteMenuAPIProps) => ({
  [SITE_MENU_ITEMS.SAVE]() {
    props.save('top_bar_site_menu', 'topBar_siteMenu_saveItem');
  },
  [SITE_MENU_ITEMS.PREVIEW]() {
    props.togglePreview(undefined, {
      biParams: { origin: constants.BI.PREVIEW.ORIGIN.MENU_BAR },
    });
  },
  [SITE_MENU_ITEMS.FEEDBACK]: async () => {
    const invalidStatus = await props.getInvalidLoggedUser();
    if (invalidStatus) {
      props.handleInvalidLoggedUser(invalidStatus);
      props.sendBiForLoginRelatedError();
      return;
    }
    if (props.isFirstSave || props.isDraftMode) {
      props.openPanel(
        SAVE_YOUR_SITE_PANEL_NAME,
        {
          title: 'TOPBAR_SITE_FEEDBACK_SAVE_FIRST_MSG_TITLE',
          messageBody: util.workspace.isNewWorkspaceEnabled()
            ? 'TOPBAR_SITE_FEEDBACK_FIRST_SAVE_SITE_TEXT'
            : 'TOPBAR_SITE_FEEDBACK_SAVE_FIRST_MSG_BODY',
        },
        true,
      );
    } else {
      props.openPanel('panels.focusPanels.feedbackPanel');
    }
  },
  [SITE_MENU_ITEMS.PUBLISH](options: PublishOptions & SaveAndPublishOptions) {
    if (props.canUserPublish) {
      if (!props.shouldSaveBeforePublish) {
        props.sitePublish(options);
      } else {
        props.siteSaveAndPublish(options);
      }
    }
  },
  [SITE_MENU_ITEMS.VIEW_SITE]() {
    if (props.isSitePublished) {
      window.open(props.sitePublicUrl);
    }
  },
  [SITE_MENU_ITEMS.VIEW_SITE_MOBILE]() {
    if (props.isSitePublished) {
      props.openPanel(constants.PANELS.PROMOTIONAL.QR_CODE_MAIN_PANEL);
    }
  },
  [SITE_MENU_ITEMS.CREATE_RELEASE_CANDIDATE]() {
    props.publishRC('top_bar');
  },
  [SITE_MENU_ITEMS.RELEASE_MANAGER]: async () => {
    const invalidStatus = await props.getInvalidLoggedUser();
    if (invalidStatus) {
      props.handleInvalidLoggedUser(invalidStatus);
      props.sendBiForLoginRelatedError();
      return;
    }
    props.openReleaseManager();
  },
  [SITE_MENU_ITEMS.SITE_BRANCHES]: async () => {
    const invalidStatus = await props.getInvalidLoggedUser();
    if (invalidStatus) {
      props.handleInvalidLoggedUser(invalidStatus);
      props.sendBiForLoginRelatedError();
      return;
    }
    props.openSiteBranchesManager();
  },
  [SITE_MENU_ITEMS.REVISIONS]() {
    if (props.isFirstSave || props.isDraftMode) {
      props.openPanel(
        SAVE_YOUR_SITE_PANEL_NAME,
        {
          title: 'TOPBAR_SITE_HISTORY_SAVE_FIRST_MSG_TITLE',
          messageBody: 'TOPBAR_SITE_HISTORY_SAVE_FIRST_MSG_BODY',
        },
        true,
      );
    } else {
      props.openRevisions();
    }

    props.setTopBarSiteHistoryUsed();
  },
  [SITE_MENU_ITEMS.CREATE_NEW_SITE]() {
    window.open(
      `https://manage.wix.com/create-site?forcePlatform=wix`,
      '_blank',
    );
  },
  [SITE_MENU_ITEMS.DUPLICATE]() {
    if (props.isFirstSave || props.isDraftMode) {
      props.openPanel(
        SAVE_YOUR_SITE_PANEL_NAME,
        {
          title: 'TOPBAR_SITE_SETTINGS_SAVE_FIRST_MSG_TITLE',
          messageBody: SAVE_YOUR_SITE_PANEL_BODY_KEY,
        },
        true,
      );
    } else {
      props.myAccountAction('duplicate');
    }
  },
  [SITE_MENU_ITEMS.UNPUBLISH]() {
    const path = 'settings/website-settings';

    props.openAccountSettings(path);
  },
  [SITE_MENU_ITEMS.TRANSFER]() {
    if (props.isFirstSave || props.isDraftMode) {
      props.openPanel(
        SAVE_YOUR_SITE_PANEL_NAME,
        {
          title: 'TOPBAR_SITE_SETTINGS_SAVE_FIRST_MSG_TITLE',
          messageBody: SAVE_YOUR_SITE_PANEL_BODY_KEY,
        },
        true,
      );
    } else {
      props.myAccountAction('transfer');
    }
  },
  [SITE_MENU_ITEMS.MOVE_TO_TRASH]: async () => {
    const invalidStatus = await props.getInvalidLoggedUser();
    if (invalidStatus) {
      props.handleInvalidLoggedUser(invalidStatus);
      props.sendBiForLoginRelatedError();
      return;
    }
    props.saveSite({
      preventSiteSavedPanel: true,
      callback: () => {
        window.location.href = `${util.serviceTopology.businessManagerUrl}/${props.metaSiteId}/home?action=move-to-trash`;
      },
    });
  },
  [SITE_MENU_ITEMS.BACK_TO_ADI]() {
    props.openPanel('panels.focusPanels.backToADIPanel');
  },
  [SITE_MENU_ITEMS.UPDATE_EDITOR]() {
    props.openPanel('panels.promotionalPanels.optionalSectionsMigrationPanel');
  },
  [SITE_MENU_ITEMS.SITE_DASHBOARD]: () => {
    if (props.isFirstSave || props.isDraftMode) {
      props.openPanel(
        SAVE_YOUR_SITE_PANEL_NAME,
        {
          title: 'TOPBAR_SITE_SETTINGS_SAVE_FIRST_MSG_TITLE',
          messageBody: SAVE_YOUR_SITE_PANEL_BODY_KEY,
        },
        true,
      );
    } else {
      props.goToDashboard();
    }
  },
  [SITE_MENU_ITEMS.EXIT]() {
    props.exitEditor();
  },
});
