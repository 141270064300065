import _ from 'lodash';

import MenuBarItem from '../menuBar/item';

import withDropDown from '../hocs/withDropDownOnHover';
import withCustomDropPanel from '../hocs/withCustomDropPanel';

export default _.flow(
  withDropDown,
  withCustomDropPanel(({ handlerKey }) => handlerKey),
)(MenuBarItem);
