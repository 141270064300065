'use strict'

const socialBarSection = require('../sections/social/socialBarSection')
const facebookSection = require('../sections/social/facebookSection')
const twitterSection = require('../sections/social/twitterSection')
const pinterestSection = require('../sections/social/pinterestSection')
const youtubeSocialSection = require('../sections/social/youtubeSocialSection')
const vkSection = require('../sections/social/vkSection')
const lineSection = require('../sections/social/lineSection')

module.exports = {
    socialBarSection,
    facebookSection,
    twitterSection,
    pinterestSection,
    youtubeSocialSection,
    vkSection,
    lineSection
}
