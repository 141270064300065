export const AUTOMATION_IDS = {
  PANEL: 'manage-menu-panel',
  BUTTONS: {
    ADD_PAGE: 'manage-menu-add-page',
    ADD_LINK: 'manage-menu-add-link',
    ADD_FOLDER: 'manage-menu-add-folder',
  },
  ITEM: {
    SELF: 'manage-menu-item',
    VIEW: 'manage-menu-item-view',
    INPUT: 'manage-menu-item-edit-input',
    ACTIONS: {
      CHANGE_LINK: 'manage-menu-item-action-change-link',
      RENAME: 'manage-menu-item-action-rename',
      REMOVE: 'manage-menu-item-action-remove',
      LINK: 'manage-menu-item-action-link',
      UNLINK: 'manage-menu-item-action-unlink',
      MOVE_UP: 'manage-menu-item-action-move-up',
      MOVE_DOWN: 'manage-menu-item-action-move-down',
      SEO_SETTINGS: 'manage-menu-item-action-move-seo-settings',
    },
  },
  FOOTER: {
    CONTAINER: 'manage-menu-footer',
    ADD_PAGE_BUTTON: 'add-menu-page-button',
    ADD_LINK_BUTTON: 'add-menu-link-button',
    ADD_DROPDOWN_BUTTON: 'add-menu-folder-button',
    ADD_PAGE_CONTEXT_MENU: 'add-page-context-menu',
    ACTIONS: {
      SITE_PAGES: 'add-menu-page-action',
      DYNAMIC_PAGES: 'add-menu-dynamic-page-action',
      LINK: 'add-menu-link-action',
      ANCHOR: 'add-menu-anchor-action',
      SECTION: 'add-menu-section-action',
      SUBMENU: 'add-menu-submenu-action',
      CONTAINER: 'add-menu-container-action',
      MEMBER_PAGES: 'add-menu-member-page-action',
    },
  },
  HEADER: {
    CHANGE_MENU_BUTTON: 'manage-menu-panel-change-menu-button',
    MENU_LABEL: 'manage-menu-panel-current-menu-label',
    INFO_ICON: 'menu-manage-panel-info-icon',
    DROPDOWN: 'manage-menu-header-dropdown',
  },
};
