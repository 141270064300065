// @ts-nocheck
import constants from '@/constants';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import { recompose } from '@wix/santa-editor-utils';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'reactDOM';
import $ from 'zepto';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const { getPreviewMode } = stateManagement.preview.selectors;
const { getPreviewPosition } = stateManagement.domMeasurements.selectors;
const { DELAY } = constants.OVARLAPPING_COMP;
const { HIDE_DELAY } = constants.OVARLAPPING_COMP;
const { MIN_OVERLAP } = constants.OVARLAPPING_COMP;

const overlappedCompTooltip = (template) => (Component) => {
  // eslint-disable-next-line react/prefer-es6-class
  const Overlapped = createReactClass({
    displayName: recompose.wrapDisplayName(Component, 'Overlapped'),
    propTypes: {
      registeredHideBubbleOnPreview: PropTypes.bool,
      setRegisteredHideBubbleOnPreview: PropTypes.func,
      previewMode: PropTypes.bool,
      previewTop: PropTypes.number,
      getComponentsUnderClick: PropTypes.func,

      hideToolsQuickTipShownSitePreference: PropTypes.number,
      setHideToolsQuickTipShownSitePreference: PropTypes.func,
      hideToolsQuickTipShownSessionPreference: PropTypes.number,
      setHideToolsQuickTipShownSessionPreference: PropTypes.func,

      reportBI: PropTypes.func,
      toggleHalfOpacityTools: PropTypes.func,
      toggleHideTools: PropTypes.func,
      showFloatingBubble: PropTypes.func,
      hideFloatingBubble: PropTypes.func,
      registerToGoToPreview: PropTypes.func,
      getRelativeToScreenConsideringScroll: PropTypes.func,
      toggleHideToolsBtnBlink: PropTypes.func,
      showUserActionNotification: PropTypes.func,
      isMobileEditor: PropTypes.bool,
      topBarHideControlsUsed: PropTypes.bool,
    },
    getInitialState() {
      this.intersectionComp = null;
      this.hideQuickTipTimer = null;
      return {
        quickTipAlreadyShown: false,
      };
    },
    componentDidMount() {
      if (!this.props.registeredHideBubbleOnPreview) {
        this.props.registerToGoToPreview(this.hideQuickTip);
        this.props.setRegisteredHideBubbleOnPreview(true);
      }

      this.checkOverlap = _.throttle(this.checkOverlap, DELAY, {
        leading: false,
      });
    },
    getComponentsLayoutByMouseEvent(e) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      return _.map(
        this.props.getComponentsUnderClick(e),
        (compRef) =>
          this.props.getRelativeToScreenConsideringScroll(compRef).bounding,
      );
    },
    calcOverlapPercentage(nodeMeasurements, compLayout) {
      const intersection = util.math.getSquaresIntersection(
        compLayout,
        nodeMeasurements,
      );

      if (!intersection) {
        return 0;
      }

      this.intersectionComp = compLayout;

      return (
        ((intersection.width * intersection.height) /
          (compLayout.height * compLayout.width)) *
        100
      );
    },
    isOverlapping(mouseEvent) {
      const compsLayout = this.getComponentsLayoutByMouseEvent(mouseEvent);
      let overlapPercentage = 0;
      if (!this.wrapped) {
        return false;
      }
      const node = ReactDOM.findDOMNode(this.wrapped);
      const nodeMeasurements = {
        x: node.offsetLeft,
        y: node.offsetTop - this.props.previewTop,
        width: node.offsetWidth,
        height: node.offsetHeight,
      };

      for (const i in compsLayout) {
        if (compsLayout.hasOwnProperty(i)) {
          overlapPercentage = this.calcOverlapPercentage(
            nodeMeasurements,
            compsLayout[i],
          );

          if (overlapPercentage > MIN_OVERLAP) {
            return true;
          }
        }
      }

      return false;
    },
    hideQuickTip() {
      window.clearTimeout(this.hideQuickTipTimer);
      this.props.toggleHalfOpacityTools(false);
      this.props.hideFloatingBubble();
    },
    showQuickTip(e) {
      const windowMiddle = $(window).width() / 2;
      const leftOffsetFix = e.pageX < windowMiddle ? 2 : -2;
      const innerTemplate = React.createElement(template, {
        text: 'toolbars_hide_tooltip_text',
        linkActionText: 'toolbars_hide_tooltip_link',
        linkAction: this.quickTipLinkAction,
        linkMouseEnter: this.quickTipLinkMouseEnter,
        linkMouseLeave: this.quickTipLinkMouseLeave,
        symbol: 'idea-bulb',
        closeAction: this.quickTipCloseAction,
      });
      const targetLayout = {
        height: 100,
        width: this.intersectionComp.width,
        top: e.clientY,
        left: this.intersectionComp.x + leftOffsetFix,
      };
      const propsForBubble = {
        noArrow: true,
        shake: true,
        behindPopUps: true,
        hideMethod: this.props.toggleHalfOpacityTools.bind(this, false),
        alignment: constants.UI.TOOLTIP.ALIGNMENT.RIGHT,
        timeoutForMouseOverBubble: HIDE_DELAY,
      };
      const shouldNotHideOnMouseLeaveTarget = true;

      this.props.showFloatingBubble(
        innerTemplate,
        targetLayout,
        propsForBubble,
        shouldNotHideOnMouseLeaveTarget,
      );
      this.props.reportBI(
        coreBi.events.editor.hide_tools.show_controls_tooltip_appearance,
      );
    },
    moveQuickTip() {
      const windowMiddle = $(window).width() / 2;
      const bubbleWidth = 284;
      const innerTemplate = React.createElement(template, {
        text: 'toolbars_show_tooltip_text',
        symbol: 'idea-bulb',
        closeAction: this.quickTipCloseAction,
      });
      const targetLayout = {
        height: this.props.previewTop + 30,
        width: bubbleWidth,
        top: 0,
        left: windowMiddle - bubbleWidth / 2,
      };
      const propsForBubble = {
        noArrow: true,
        behindPopUps: true,
        shouldHaveTransition: true,
      };
      const shouldNotHideOnMouseLeaveTarget = true;

      this.props.showFloatingBubble(
        innerTemplate,
        targetLayout,
        propsForBubble,
        shouldNotHideOnMouseLeaveTarget,
      );
      this.props.toggleHideToolsBtnBlink(true);
    },
    quickTipCloseAction() {
      this.hideQuickTip();
      this.props.reportBI(
        coreBi.events.editor.hide_tools.show_controls_tooltip_close,
      );

      const sessionQuickTipShown =
        this.props.hideToolsQuickTipShownSessionPreference || 0;
      this.props.setHideToolsQuickTipShownSessionPreference(
        sessionQuickTipShown + 1,
      );
    },
    quickTipLinkAction() {
      this.props.toggleHalfOpacityTools(false);
      this.props.toggleHideTools(true);

      this.props.reportBI(coreBi.events.editor.hide_tools.hide_controls, {
        origin: 'tooltip',
      });

      this.moveQuickTip();

      this.props.setHideToolsQuickTipShownSitePreference(true);
    },
    quickTipLinkMouseEnter() {
      this.props.toggleHalfOpacityTools(true);
    },
    quickTipLinkMouseLeave() {
      this.props.toggleHalfOpacityTools(false);
    },
    resetQuickTip() {
      this.setState({ quickTipAlreadyShown: false });
    },
    checkOverlap(e) {
      if (this.props.previewMode) {
        return;
      }

      const sessionNotToShowTipAgain =
        this.props.hideToolsQuickTipShownSitePreference ||
        this.props.hideToolsQuickTipShownSessionPreference > 4;

      if (
        !this.props.isMobileEditor &&
        !sessionNotToShowTipAgain &&
        !this.state.quickTipAlreadyShown &&
        this.isOverlapping(e)
      ) {
        if (!this.props.topBarHideControlsUsed) {
          this.props.showUserActionNotification({
            message: 'Notifications_Hide_Controls_Text',
            title: 'Notifications_Hide_Controls_Text',
            type: 'info',
            link: {
              caption: 'Notifications_Hide_Controls_Link',
              onNotificationLinkClick: this.quickTipLinkAction,
            },
          });
        } else {
          this.showQuickTip(e);
          this.setState({ quickTipAlreadyShown: true });
        }
      }
    },
    wrappedMounted(wrapped) {
      this.wrapped = wrapped;
    },
    onMouseMove(e) {
      // must use persist for throttled listener - React synthetic events die when the thread is released. https://facebook.github.io/react/docs/events.html#event-pooling
      e.persist();
      this.checkOverlap(e);
    },
    render() {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      const wrappedCompProps = _.assign({}, this.props, {
        onMouseMove: this.onMouseMove,
        onMouseEnter: this.resetQuickTip,
        ref: this.wrappedMounted,
      });
      return React.createElement(Component, wrappedCompProps);
    },
  });

  return Overlapped;
};

const mapStateToProps = ({ editorAPI, state }) => ({
  registeredHideBubbleOnPreview: editorAPI.registeredHideBubbleOnPreview,
  previewTop: getPreviewPosition(state).top,
  previewMode: getPreviewMode(state),
  hideToolsQuickTipShownSitePreference:
    stateManagement.userPreferences.selectors.getSiteUserPreferences(
      'hideToolsQuickTipShown',
    )(state),
  hideToolsQuickTipShownSessionPreference:
    stateManagement.userPreferences.selectors.getSessionUserPreferences(
      'hideToolsQuickTipShown',
    )(state),
  topBarHideControlsUsed:
    stateManagement.userPreferences.selectors.getSessionUserPreferences(
      'topBarHideControlsUsed',
    )(state),
  isMobileEditor: editorAPI.isMobileEditor(),
});

const setRegisteredHideBubbleOnPreview =
  (value) =>
  (dispatch, getState, { editorAPI }) => {
    editorAPI.registeredHideBubbleOnPreview = value;
  };

const registerToGoToPreview =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.preview.registerToGoToPreview(...args);

const getRelativeToScreenConsideringScroll =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.components.layout.getRelativeToScreenConsideringScroll(...args);

const getComponentsUnderClick =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.selection.getComponentsUnderClick(...args);

const toggleHalfOpacityTools =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.toolsControl.toggleHalfOpacityTools(...args);
const toggleHideTools =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.toolsControl.toggleHideTools(...args);
const showFloatingBubble =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.showFloatingBubble(...args);
const hideFloatingBubble =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.hideFloatingBubble(...args);
const toggleHideToolsBtnBlink =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.toolsControl.toggleHideToolsBtnBlink(...args);

const mapDispatchToProps = (dispatch) => ({
  setRegisteredHideBubbleOnPreview(value) {
    return dispatch(setRegisteredHideBubbleOnPreview(value));
  },
  registerToGoToPreview(...args) {
    return dispatch(registerToGoToPreview(...args));
  },
  getRelativeToScreenConsideringScroll(...args) {
    return dispatch(getRelativeToScreenConsideringScroll(...args));
  },
  getComponentsUnderClick(...args) {
    return dispatch(getComponentsUnderClick(...args));
  },
  setHideToolsQuickTipShownSitePreference: (count) =>
    dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        'hideToolsQuickTipShown',
        count,
      ),
    ),
  setHideToolsQuickTipShownSessionPreference: (count) =>
    dispatch(
      stateManagement.userPreferences.actions.setSessionUserPreferences(
        'hideToolsQuickTipShown',
        count,
      ),
    ),
  reportBI(eventType, params) {
    dispatch(stateManagement.bi.actions.event(eventType, params));
  },
  toggleHalfOpacityTools(...args) {
    return dispatch(toggleHalfOpacityTools(...args));
  },
  toggleHideTools(...args) {
    return dispatch(toggleHideTools(...args));
  },
  showFloatingBubble(...args) {
    return dispatch(showFloatingBubble(...args));
  },
  hideFloatingBubble(...args) {
    return dispatch(hideFloatingBubble(...args));
  },
  toggleHideToolsBtnBlink(...args) {
    return dispatch(toggleHideToolsBtnBlink(...args));
  },
  showUserActionNotification: (...args) =>
    dispatch(
      stateManagement.notifications.actions.showUserActionNotification(...args),
    ),
});

export default (template) =>
  <T>(Component: T): T =>
    connect(
      EDITOR_API,
      mapStateToProps,
      mapDispatchToProps,
    )(overlappedCompTooltip(template)(Component));
