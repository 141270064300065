import experiment from 'experiment';
import * as util from '@/util';
import {
  getRefComponentRootChild,
  getRefComponentStageData,
} from '@/documentServices';
import type {
  ComponentEditorMetaDataDefinition,
  ComponentEditorMetaDataValueResolver,
} from '../types';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef, ComponentStageBehavior } from 'types/documentServices';

const focusable: ComponentEditorMetaDataDefinition['focusable'] = (
  editorAPI,
  compRef,
) => {
  const mainChildRef = getRefComponentRootChild(editorAPI.dsRead, compRef);

  return !!mainChildRef && editorAPI.components.is.focusable(mainChildRef);
};

const forbiddenResizeDueToStageData: ComponentEditorMetaDataValueResolver<
  boolean
> = (editorAPI, compRef) => {
  const refComponentStageData = getRefComponentStageData(
    editorAPI.dsRead,
    compRef,
  );
  return refComponentStageData?.behavior?.resizable === false;
};

function duplicatable(editorAPI: EditorAPI, compRef: CompRef) {
  return !editorAPI.platform.widgetPlugins.isWidgetPluginComponent(compRef);
}

function horizontallyMovable(editorAPI: EditorAPI, compRef: CompRef) {
  return !editorAPI.platform.widgetPlugins.isWidgetPluginComponent(compRef);
}

function verticallyMovable(editorAPI: EditorAPI, compRef: CompRef) {
  return !editorAPI.platform.widgetPlugins.isWidgetPluginComponent(compRef);
}

const resizable: ComponentEditorMetaDataDefinition['resizable'] = (
  editorAPI,
  compRef,
) => {
  const { dsRead } = editorAPI;
  return (
    duplicatable(editorAPI, compRef) &&
    util.appStudioUtils.isResponsiveBlocksWidget({ dsRead }, compRef) &&
    !forbiddenResizeDueToStageData(editorAPI, compRef)
  );
};

const containable: ComponentEditorMetaDataValueResolver<boolean> = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  potentialParent?: CompRef,
) => {
  if (!potentialParent) {
    return;
  }

  const isRepeaterContainer =
    editorAPI.components.is.repeatedComponent(potentialParent);

  if (isRepeaterContainer) {
    // TODO: use isRepeatable meta data here when it is production ready
    return experiment.isOpen('se_widgetInRepeater');
  }

  return true;
};

const canContainByStructure: ComponentEditorMetaDataValueResolver<boolean> = (
  editorAPI: EditorAPI,
  potentialParent: CompRef,
) => containable(editorAPI, null, potentialParent);

function getDelegatedRefComponentBehaviorMetadataHandlers(): Partial<ComponentEditorMetaDataDefinition> {
  const behaviourMetadata: Partial<ComponentStageBehavior> = {
    pinnable: true,
    removable: true,
    duplicatable: true,
  };
  const behaviourMetadataKeys = Object.keys(
    behaviourMetadata,
  ) as (keyof ComponentStageBehavior)[];

  const behaviourMetadataHandlers = {} as {
    [key in keyof ComponentStageBehavior]: ComponentEditorMetaDataValueResolver<
      ComponentStageBehavior[key]
    >;
  };

  for (const handlerName of behaviourMetadataKeys) {
    behaviourMetadataHandlers[handlerName] = (editorAPI, compRef) => {
      const controllerStageData = getRefComponentStageData(
        editorAPI.dsRead,
        compRef,
      );

      return (controllerStageData?.behavior?.[handlerName] ??
        behaviourMetadata[handlerName]) as any;
    };
  }

  return behaviourMetadataHandlers as Partial<ComponentEditorMetaDataDefinition>;
}

const refComponentMetaData: ComponentEditorMetaDataDefinition = {
  isDirectChildrenSelectable: false,
  selectedBeforeDescendants: true,
  delegateControlsToFirstChild: {
    applyToAll: true,
  },
  applyMetaDataToFirstChild: {
    skipInLayersPanel: true,
    isAllowedAddToSavedComponent: false,
  },
  selectable: true,
  isAllowedAddToSavedComponent: false,
  horizontallyMovable,
  verticallyMovable,
  focusable,
  resizable,
  duplicatable,
  horizontallyResizable: resizable,
  verticallyResizable: resizable,
  stretchable: resizable,
  containable,
  canContainByStructure,
  ...getDelegatedRefComponentBehaviorMetadataHandlers(),
};

export default refComponentMetaData;
