'use strict'

const anchorsSection = require('../sections/other/anchorsSection')
const corvidDevModeSection = require('../sections/other/corvidDevModeSection')
const marketingIntegrationsSection = require('../sections/other/marketingIntegrationsSection')
const fileDownloadButtonsSection = require('../sections/other/fileDownloadButtonsSection')
const customEmbedsSection = require('../sections/other/customEmbedsSection')
const htmlFlashSection = require('../sections/other/htmlFlashSection')
const htmlFlashWithoutCustomElementSection = require('../sections/other/htmlFlashWithoutCustomElementSection')
const payButtonSection = require('../sections/other/payButtonSection')
const paypalButtonsSection = require('../sections/other/paypalButtonsSection')
const wixStaffSection = require('../sections/other/wixStaffSection')
const verticalAnchorsMenuSection = require('../sections/menu/verticalAnchorsMenuSection')
const googleMapsSquareSection = require('../sections/contact/googleMapsSquareSection')
const googleMapsGenericSection = require('../sections/contact/googleMapsGenericSection')
const googleMapsStripSection = require('../sections/contact/googleMapsStripSection')

module.exports = {
    anchorsSection,
    corvidDevModeSection,
    marketingIntegrationsSection,
    fileDownloadButtonsSection,
    customEmbedsSection,
    htmlFlashSection,
    htmlFlashWithoutCustomElementSection,
    payButtonSection,
    paypalButtonsSection,
    wixStaffSection,
    verticalAnchorsMenuSection,
    googleMapsSquareSection,
    googleMapsGenericSection,
    googleMapsStripSection
}
