import React from 'react';
import { Text } from '@wix/wix-base-ui';
import styles from './addPageBulletsContent.scss';
import * as Symbols from '@wix/santa-editor-symbols';

import type { PresetInfoIconData } from '../../types';

const AddPageBulletsContent = ({ title, steps }: PresetInfoIconData) => (
  <div className={styles.addPageBulletsContent}>
    <Text size="large">{title}</Text>
    {steps?.map(({ text, symbolName }) => (
      <div className={styles.addPageBulletsInfo}>
        <div className={styles.addPageBulletSvgContainer}>
          <Symbols.symbol name={symbolName} />
        </div>
        <Text enableEllipsis={false} size="small">
          {text}
        </Text>
      </div>
    ))}
  </div>
);

export default AddPageBulletsContent;
