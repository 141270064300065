import { bi } from '@/stateManagement';
import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import type { BiEventDefinition, BiEventFields } from 'types/bi';

import { getHasDynamicPages } from '../dynamicPages';
import {
  selectCollections,
  selectCollectionsLoaded,
  selectExternalCollections,
  selectWixAppsCollections,
  selectFormsCollections,
} from '../collections';
import { selectIsPremiumEnabled, selectHasPremium } from '../premium';
import { isDataModeOn } from '../dataMode';

export interface WixDataPanelPropsOwnProps {
  selectedTabName?: string;
}
export interface WixDataPanelStateProps {
  showUpgradeToPremiumTab: boolean;
  hasDynamicPages: boolean;
  hasCollections: boolean;
  hasWixAppsCollections: boolean;
  hasFormsCollections: boolean;
  hasExternalCollections: boolean;
  isLoading: boolean;
  hasEditStructurePermission: boolean;
  isDataModeOn: boolean;
  collectionsCount: number;
  isPremium: boolean;
  userId: string;
}

export interface WixDataPanelDispatchProps {
  sendBi: (event: BiEventDefinition, params: BiEventFields) => void;
}

export const mapStateToProps: MapStateToProps<
  WixDataPanelStateProps,
  WixDataPanelPropsOwnProps
> = ({ editorAPI, state }) => ({
  showUpgradeToPremiumTab:
    selectIsPremiumEnabled(state) && !selectHasPremium(state),
  hasDynamicPages: getHasDynamicPages(editorAPI),
  hasCollections: selectCollections(state).length > 0,
  hasWixAppsCollections: selectWixAppsCollections(state).length > 0,
  hasFormsCollections: selectFormsCollections(state).length > 0,
  hasExternalCollections: selectExternalCollections(state).length > 0,
  isLoading: !selectCollectionsLoaded(state) || !isDataModeOn(editorAPI),
  hasEditStructurePermission:
    editorAPI.platform.permissions.isCustomPermissionsGranted([
      'DOCUMENT.EDIT_STRUCTURE',
    ]),
  isDataModeOn: isDataModeOn(editorAPI),
  collectionsCount: selectCollections(state).length,
  isPremium: selectHasPremium(state),
  userId: editorAPI.dsRead.generalInfo.getUserInfo()?.userId,
});

export const mapDispatchToProps: MapDispatchToProps<
  WixDataPanelDispatchProps,
  WixDataPanelPropsOwnProps
> = (dispatch) => ({
  sendBi: (event: BiEventDefinition, params: BiEventFields) =>
    dispatch(bi.actions.event(event, params)),
});
