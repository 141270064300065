import { stateMapperArgsSelectors } from '@/stateManagement';

export const {
  dsReadSel,
  editorAPIMouseSel,
  editorAPISel,
  editorStateSel,
  editorStateMouseOpsSel,
  getStateMapperArgsFromEditorAPI,
} = stateMapperArgsSelectors.rootSels;
