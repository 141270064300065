// @ts-nocheck
import * as coreBi from '@/coreBi';
import _ from 'lodash';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';

const POPUP_CONTAINER_SETTING_PANEL_NAME =
  constants.COMP_PANELS.POPUP.CONTAINER.SETTINGS_PANEL.NAME;

function openFloatingBubble(editorAPI) {
  const onNotificationLinkClick = () => {
    const popupContainer = editorAPI.pages.popupPages.getPopupContainer();
    editorAPI.selection.selectComponentByCompRef(popupContainer);
    editorAPI.openComponentPanel('settingsPanel');
  };
  editorAPI.store.dispatch(
    stateManagement.notifications.actions.showUserActionNotification({
      message: 'Notifications_Lightbox_Close_Text',
      title: 'Notifications_Lightbox_Close_Text',
      type: 'warning',
      link: {
        caption: 'Notifications_Lightbox_Close_Link',
        onNotificationLinkClick,
      },
    }),
  );
}

function isSettingsPanelOpen(editorAPI) {
  const openPanelsList = editorAPI.panelManager.getOpenPanels();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const settingsPanel = _.find(openPanelsList, {
    name: POPUP_CONTAINER_SETTING_PANEL_NAME,
  });

  return Boolean(settingsPanel);
}

export default function (editorAPI, compRef, showMessage, originalRemove) {
  const removePromise = originalRemove();

  if (!isSettingsPanelOpen(editorAPI)) {
    openFloatingBubble(editorAPI);
    editorAPI.bi.event(
      coreBi.events.popups.pop_up_tooltip_appearance_on_delete_x,
    );
  }

  return removePromise;
}
