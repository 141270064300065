import { translate } from '@/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 226,
        height: 103,
        x: 224,
        y: 241,
        scale: 1,
        rotationInDegrees: 110.50247541236939,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'uywfn',
      },
      parent: 'comp-kguvzxjc',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
        svgId: 'c876ec4f79734344b5cfb15c41e30352.svg',
        title: 'media-84-22',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'uywfn',
        },
        displayMode: 'fit',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
        shapeStyle: {
          opacity: 1,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
      },
      scopedTransformations: {
        'variants-kguvzxjp': {
          type: 'TransformData',
          rotate: -12,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'uywfn',
          },
          translate: {
            x: {
              type: 'px',
              value: 9,
            },
            y: {
              type: 'px',
              value: -4,
            },
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 144,
          height: 66,
          x: 127,
          y: 139,
          scale: 1,
          rotationInDegrees: 110.50247541236939,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kguvzxjr',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 296,
        height: 296,
        x: 52,
        y: 51,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'uywfn',
      },
      parent: 'comp-kguvzxjc',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
        svgId: '8463f60718194af748c49dddbe45b668_svgshape.v1.HollowCircle.svg',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'uywfn',
        },
        displayMode: 'stretch',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
        overrideColors: {
          color1: '#FFFAF5',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 1,
          stroke: '#423737',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
      },
      scopedTransformations: {
        'variants-kguvzxjp': {
          type: 'TransformData',
          scale: {
            x: 0.9,
            y: 0.9,
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'uywfn',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        pageId: '',
        compId: 'i8yjldkb_0',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 190,
          height: 190,
          x: 24,
          y: 31,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kguvzxk25',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      layout: {
        width: 234,
        height: 234,
        x: 84,
        y: 83,
        scale: 1,
        rotationInDegrees: 339.7554165910976,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'uywfn',
      },
      parent: 'comp-kguvzxjc',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'uywfn',
        },
        title: '',
        uri: '74f558_87529dd70eec4de2ae5cb19c5dcc55b8~mv2.png',
        description: '',
        width: 718,
        height: 720,
        alt: 'bowtie.png',
        name: 'bowtie.png',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'uywfn',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
      },
      scopedTransformations: {
        'variants-kguvzxjp': {
          type: 'TransformData',
          scale: {
            x: 1.1,
            y: 1.1,
          },
          rotate: 19,
          skew: {
            x: 0,
            y: 0,
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'uywfn',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WPhoto',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      },
      mobileStructure: {
        layout: {
          width: 146,
          height: 145,
          x: 44,
          y: 50,
          scale: 1,
          rotationInDegrees: 339.7554165910976,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kguvzxk45',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 312,
        height: 48,
        x: 478,
        y: 236,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'uywfn',
      },
      parent: 'comp-kguvzxjc',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
        text: `<p class="font_7" style="font-size: 14px; text-align: center; line-height: 1.6em;"><span style="font-size: 17px;"><span style="font-family: proxima-n-w01-reg;"><span style="font-weight: normal;"><span style="font-style: normal;"><span style="font-variant: normal;"><span style="color: rgb(66, 55, 55);"><span style="color:#707070;"><span style="font-size:14px;"><span style="font-weight:bold;"><span style="font-family:worksans-extralight,work sans,sans-serif;">${translate(
          'interactions_preset_bows_paragraph',
        )}</span></span></span></span></span></span></span></span></span></span></p>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'uywfn',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
      },
      scopedTransformations: {
        'variants-kguvzxjp': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'uywfn',
          },
          rotate: 0,
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 260,
          height: 50,
          x: 10,
          y: 319,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'uywfn',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kguvzxk64',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 432,
        height: 106,
        x: 420,
        y: 136,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'uywfn',
      },
      parent: 'comp-kguvzxjc',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
        text: `<h2 class="font_2" style="font-size:40px; text-align:center"><span style="font-size:40px"><span style="font-weight:bold"><span style="font-family:playfair display,serif"><span style="color:#292929">${translate(
          'interactions_preset_bows_main_text_1',
        )}</span></span></span></span></h2>\n\n<h2 class="font_2" style="font-size:40px; text-align:center"><span style="font-size:40px"><span style="font-weight:bold"><span style="font-family:playfair display,serif"><span style="color:#292929">${translate(
          'interactions_preset_bows_main_text_2',
        )}</span></span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'uywfn',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
      },
      scopedTransformations: {
        'variants-kguvzxjp': {
          type: 'TransformData',
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: -30,
            },
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'uywfn',
          },
          rotate: 0,
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 260,
          height: 64,
          x: 10,
          y: 327,
          scale: 0.8,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'uywfn',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kguvzxk82',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 288,
        height: 15,
        x: 492,
        y: 99,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'uywfn',
      },
      parent: 'comp-kguvzxjc',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
        text: `<h2 class="font_2" style="font-size:14px; line-height:1em; text-align:center"><span style="font-family:proxima-n-w01-reg,sans-serif"><span style="color:#8C5C23"><span style="letter-spacing:0.15em"><span style="font-size:14px">${translate(
          'interactions_preset_bows_tagline',
        )}</span></span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'uywfn',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
      },
      scopedTransformations: {
        'variants-kguvzxjp': {
          type: 'TransformData',
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: -16,
            },
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'uywfn',
          },
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 260,
          height: 15,
          x: 10,
          y: 295,
          scale: 0.9333333333333333,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'uywfn',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kguvzxka',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wixui.skins.Skinless',
      layout: {
        width: 140,
        height: 40,
        x: 566,
        y: 307,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wixui.StylableButton',
      metaData: {
        pageId: 'uywfn',
      },
      parent: 'comp-kguvzxjc',
      data: {
        type: 'StylableButton',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
        label: translate('interactions_preset_bows_CTA'),
        svgId: '3b1d298064004630ad27b628a67cfe24.svg',
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.6,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'uywfn',
        },
        style: {
          properties: {
            '$st-css':
              ':import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 50px;background: #5C916B}.root:hover{\n    background: #FFFFFF}.root:hover::label {\n    color: #262626;\n}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit;flex-direction: row-reverse}.root::label{transition: inherit;margin: 0px 0px 0px 1.5px;letter-spacing: 0em;color: #FFFFFF;font-size: 13px;font-family: worksans-extralight,"work sans",sans-serif}.root::icon{transition: inherit;width: 14px;height: 14px;margin: 0px 1.5px 0px 0px;fill: #000000;display: none}',
          },
          propertiesSource: {
            '$st-css': 'value',
          },
        },
        componentClassName: 'wixui.StylableButton',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wixui.skins.Skinless',
      },
      mobileStructure: {
        layout: {
          width: 200,
          height: 42,
          x: 40,
          y: 426,
          scale: 0.9333333333333333,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kguvzxkb3',
          author: 'studio',
        },
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 894,
    height: 417,
    x: 43,
    y: 41,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'uywfn',
  },
  parent: 'uywfn',
  variants: {
    'variants-kguvzxjp': {
      componentId: 'comp-kguvzxjc',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'uywfn',
      },
    },
  },
  scopedTransformations: {
    'variants-kguvzxjp': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'uywfn',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'uywfn',
    },
    style: {
      properties: {
        'alpha-bg': '1',
        'alpha-brd': '1',
        bg: '#F5EAE1',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  mobileStructure: {
    layout: {
      width: 280,
      height: 513,
      x: 20,
      y: 8,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kguvzxjc',
      author: 'studio',
    },
  },
  activeModes: {},
};
