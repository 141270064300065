import React, { type FC, type MouseEvent } from 'react';
import * as baseUI from '@/baseUI';
import * as util from '@/util';

import { AUTOMATION_IDS } from './constants';

import styles from './Background.scss';

interface BackgroundTopActionsProps {
  onChangeColorClick(evt: MouseEvent): void;
  onChangeImageClick(evt: MouseEvent): void;
  onChangeVideoClick(evt: MouseEvent): void;
}

const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();

export const BackgroundTopActions: FC<BackgroundTopActionsProps> = (props) => {
  const colorBtnSymbol = isNewWorkspace ? 'color-btn-v2' : 'color-btn';
  const imageBtnSymbol = isNewWorkspace ? 'image-btn-v2' : 'image-btn';
  const videoBtnSymbol = isNewWorkspace ? 'video-btn-v2' : 'video-btn';

  const btnClassName = util.cx({
    [styles['bg-panel-action-btn']]: true,
    default: true,
    centered: isNewWorkspace,
  });

  return (
    <div className={styles['bg-panel-top-actions']}>
      <div className={styles['bg-panel-action']}>
        <baseUI.button2
          onClick={props.onChangeColorClick}
          symbolName={colorBtnSymbol}
          label="BGPP_PANEL_COLOR_BUTTON"
          data-hook={AUTOMATION_IDS.BACKGROUND_VIEW_COLOR_PICKER}
          className={btnClassName}
        />
      </div>
      <div className={styles['bg-panel-action']}>
        <baseUI.button2
          onClick={props.onChangeImageClick}
          symbolName={imageBtnSymbol}
          label="BGPP_PANEL_IMAGE_BUTTON"
          data-hook={AUTOMATION_IDS.BACKGROUND_VIEW_IMAGE_PICKER}
          className={btnClassName}
        />
      </div>
      <div className={styles['bg-panel-action']}>
        <baseUI.button2
          onClick={props.onChangeVideoClick}
          symbolName={videoBtnSymbol}
          label="BGPP_PANEL_VIDEO_BUTTON"
          data-hook={AUTOMATION_IDS.BACKGROUND_VIEW_VIDEO_PICKER}
          className={btnClassName}
        />
      </div>
    </div>
  );
};
