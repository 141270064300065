// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as core from '@/core';
import * as util from '@/util';
import { utils as themeUtils } from '@/theme';
import * as baseUI from '@/baseUI';
import * as higherOrderComponents from '@/higherOrderComponents';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import componentConstants from './constants';
import React from 'react';
import { CustomScroll } from '@wix/wix-base-ui';
import { cx } from '@/util';
import SiteCustomColorsAddBtn from './siteCustomColorAddBtn';
import CustomColorRadio from './customColorRadio';

const ALWAYS_SHOWN_COLORS = ['#000000', '#FFFFFF'];

// eslint-disable-next-line react/prefer-es6-class
const SiteCustomColors = createReactClass({
  displayName: 'siteCustomColors',
  mixins: [core.mixins.editorAPIMixin, baseUI.radioButtonsMixin],
  propTypes: {
    customColors: PropTypes.arrayOf(PropTypes.string).isRequired,
    userAddedColors: PropTypes.array,
    isEditable: PropTypes.bool,
    onPreview: PropTypes.func,
    openAddColorPanel: PropTypes.func,
    origin: PropTypes.string,
  },
  NUM_OF_EMPTY_SLOTS: 36,
  AUTOMATION_IDS: componentConstants.AUTOMATION_IDS,
  getInitialState() {
    return {
      selectedIndex: this.getSelectedColorIndex(
        util.valueLink.getValueFromProps(this.props),
        this.props.userAddedColors || [],
      ), //needed since we allow different color options with the same color value
    };
  },
  getFilteredColors(userAddedColors?: string[]) {
    userAddedColors = userAddedColors || this.props.userAddedColors || [];
    const filteredColors = _.difference(
      this.props.customColors,
      ALWAYS_SHOWN_COLORS,
      userAddedColors,
    );
    return _.uniq(ALWAYS_SHOWN_COLORS.concat(userAddedColors, filteredColors));
  },
  sendBI(event, parameters) {
    const compRef = this.getEditorAPI().selection.getSelectedComponents()[0];
    const defaultBIParams = {
      component_id: compRef?.id,
    };

    this.getEditorAPI().bi.event(event, {
      ...defaultBIParams,
      ...parameters,
    });
  },
  selectColor(color, colorIndex) {
    this.sendBI(coreBi.events.colorPicker.CUSTOM_COLOR_SELECT, {
      panelName: this.props.origin,
      value: color,
    });
    this.setState(
      { selectedIndex: colorIndex },
      util.valueLink.callOnChangeIfExists(this.props, color),
    );
  },
  getSelectedColorIndex(selected, optionalColorSet) {
    return this.getFilteredColors(
      optionalColorSet || this.props.userAddedColors,
    ).indexOf(selected);
  },
  getColorBrightnessClass(color) {
    if (!color) {
      return 'dark';
    }
    const colorRgb = themeUtils.hexToRgb(color);
    const colorHsb = themeUtils.rgbToHsb(colorRgb);
    if (colorHsb.brightness >= 80 && colorHsb.saturation < 50) {
      return 'bright';
    }
    return 'dark';
  },
  getColorOptionClasses(color) {
    const classes = {
      'color-displayer': true,
      'white-option': themeUtils.getDistanceToWhite(color) < 3,
    };
    classes[`${this.getColorBrightnessClass(color)}-color`] = true;
    return classes;
  },
  UNSAFE_componentWillReceiveProps(newProps) {
    const currentColor = util.valueLink.getValueFromProps(this.props);
    const newColor = util.valueLink.getValueFromProps(newProps);

    if (currentColor !== newColor) {
      this.setState({ selectedIndex: this.getSelectedColorIndex(newColor) });
    }
  },
  addCustomColor() {
    this.sendBI(coreBi.events.colorPicker.ADD_COLOR_BUTTON_CLICKED, {});
    this.props.openAddColorPanel(componentConstants.ORIGIN.ADD_BUTTON);
  },
  renderAddBtn() {
    return this.props.isEditable ? (
      <SiteCustomColorsAddBtn
        key="addColorButton"
        onAddCustomColor={this.addCustomColor}
      />
    ) : null;
  },
  renderCustomColors() {
    const selected = util.valueLink.getValueFromProps(this.props);
    const filteredColors = this.getFilteredColors();
    return filteredColors.map((color, colorIndex) => {
      return (
        <CustomColorRadio
          key={`colorOption-${colorIndex}`}
          automationId={this.AUTOMATION_IDS.BTN.CUSTOM_COLORS}
          index={colorIndex}
          color={color}
          radioGroupId={this.getRadioGroupId()}
          onPreview={this.props.onPreview}
          onSelectColor={this.selectColor}
          selectedIndex={this.state.selectedIndex}
          selected={selected}
          colorOptionClasses={this.getColorOptionClasses(color)}
        />
      );
    });
  },
  render() {
    const filteredColors = this.getFilteredColors();
    return (
      <div
        className={cx('site-custom-colors', this.filteredProps()?.className)}
      >
        <CustomScroll heightRelativeToParent="100%" keepAtBottom={true}>
          {this.renderAddBtn()}
          {this.renderCustomColors()}
          {_.isEmpty(filteredColors)
            ? // eslint-disable-next-line lodash/prefer-times, you-dont-need-lodash-underscore/map
              _.map(_.range(this.NUM_OF_EMPTY_SLOTS), () => (
                <div key="emptyColorOptions" className="empty-color-option" />
              ))
            : null}
        </CustomScroll>
      </div>
    );
  },
});

const userPreferencesDefs = [
  {
    type: 'session',
    key: constants.USER_PREFS.THEME.COLORS.CUSTOM.USER_ADDED,
    propName: 'userAddedColors',
  },
];

export default higherOrderComponents.withUserPreferences(userPreferencesDefs)(
  SiteCustomColors,
);
