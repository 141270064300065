import React, { Fragment } from 'react';
import { ResultThumbnail } from './resultThumbnail';
import { ResultPreview } from './resultPreview';
import type { LayoutWithMetadata } from '../switchLayoutStore';
import styles from './switchLayoutPanel.scss';

export interface ResultsContainerProps {
  layoutOptions: LayoutWithMetadata[];
  selectedId: string;
  isSelecting: boolean;
  sectionCorrelationId: string;
  onPresetPreviewBeforeRender: (preset: any) => void;
  onLayoutSelected: (layoutOption: LayoutWithMetadata, paasPreset: any) => void;
}

export const ResultsContainer: React.FC<ResultsContainerProps> = (props) => {
  const {
    layoutOptions,
    selectedId,
    isSelecting,
    onLayoutSelected,
    onPresetPreviewBeforeRender,
    sectionCorrelationId,
  } = props;
  const listItems = layoutOptions.map(
    (layoutOption: LayoutWithMetadata, index: number) => {
      const isSelected = selectedId == layoutOption.id;

      return (
        <ResultThumbnail
          key={`${sectionCorrelationId}--${layoutOption.id}-${index}`}
          resultItem={layoutOption}
          isSelected={isSelected}
          isSelecting={isSelecting}
          onClick={() => onLayoutSelected(layoutOption, layoutOption.preset)}
          render={(resultItem) => (
            <div className={styles['result-preview']}>
              <ResultPreview
                resultItem={resultItem}
                onPresetPreviewBeforeRender={onPresetPreviewBeforeRender}
              />
            </div>
          )}
        />
      );
    },
  );

  return <Fragment>{listItems}</Fragment>;
};
