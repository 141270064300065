import _ from 'lodash';
import { DATA_BINDING, MEMBERS_AREA_V2 } from '@wix/app-definition-ids';
import pageCategories from '../utils/pageCategories';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import { EVENTS, isDataModeOn } from '@/wixData';
import pagesAPI from '../api/api';
import * as util from '@/util';
import { hasCustomSignInLightbox, hasCustomSignupLightbox } from '../utils';
import type { Dispatch } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import { selectUserCollections } from '@/wixData';

const PANEL_NAME = 'pages_panel';
const customSignUpAppDefId = '5f4fa4f1-5afd-4ac0-8a85-e5ac1d2b9b7d';
const customSignInAppDefId = 'bbe1406a-31f5-4f3f-9e0a-b39dfd25274f';

const getSelectedTabId = (
  openedPopup: AnyFixMe,
  customSignupPopupId: AnyFixMe,
  customSignInPopupId: AnyFixMe,
  signupCategory: AnyFixMe,
  tabsAppDefIdMap: AnyFixMe,
) => {
  const { managingAppDefId } = openedPopup;
  if (
    openedPopup.id === customSignupPopupId ||
    openedPopup.id === customSignInPopupId
  ) {
    return signupCategory;
  } else if (managingAppDefId && tabsAppDefIdMap[managingAppDefId]) {
    return managingAppDefId;
  }
  return pageCategories.POPUPS.id;
};

const getSelected = (
  editorAPI: AnyFixMe,
  menus: AnyFixMe,
  routers: AnyFixMe,
  appPages: AnyFixMe,
  isDesktop: AnyFixMe,
  forceSelectedTabId: AnyFixMe,
  tabsAppDefIdMap: AnyFixMe,
): {
  selectedPageId: string;
  selectedPageIsDynamic?: boolean;
  selectedMenuItemId?: string;
  selectedTabId: string;
  selectedPageIsManagedByApp?: boolean;
} => {
  const openedPopup = editorAPI.pages.popupPages.getCurrentPopupData();
  if (openedPopup) {
    const popups = editorAPI.pages.popupPages.getDataList();
    const customSignupPopupId = popups.find(
      (popup: AnyFixMe) => popup.managingAppDefId === customSignUpAppDefId,
    )?.id;
    const customSignInPopupId = popups.find(
      (popup: AnyFixMe) => popup.managingAppDefId === customSignInAppDefId,
    )?.id;
    const signupCategory = isDesktop
      ? pageCategories.SIGNUP.id
      : pageCategories.MAIN_MENU.id;

    return {
      selectedPageId: openedPopup.id,
      selectedTabId: getSelectedTabId(
        openedPopup,
        customSignupPopupId,
        customSignInPopupId,
        signupCategory,
        tabsAppDefIdMap,
      ),
    };
  }

  const selectedPageId = editorAPI.pages.getCurrentUrlPageId();
  if (!selectedPageId) {
    return getSelectedHomepage(editorAPI, menus);
  }

  const selectedAppPageData = getSelectedAppPageData(
    editorAPI,
    selectedPageId,
    appPages,
    forceSelectedTabId,
  );
  if (selectedAppPageData?.selectedTabId) {
    return selectedAppPageData;
  }

  const selectedRouterData = getSelectedRouterData(
    editorAPI,
    selectedPageId,
    routers,
  );
  if (selectedRouterData?.selectedTabId) {
    return selectedRouterData;
  }

  const selectedMenuItem = editorAPI.menus.getSelectedMenuItemByPageId(
    menus,
    selectedPageId,
  );

  const selectedTabId = forceSelectedTabId || selectedMenuItem.menuId;

  if (selectedTabId) {
    return {
      selectedPageId,
      selectedMenuItemId: selectedMenuItem?.id,
      selectedTabId,
    };
  }

  return getSelectedHomepage(editorAPI, menus);
};

const getInitialTab = (
  editorAPI: AnyFixMe,
  menuIdPanelWasOpenedFrom: AnyFixMe,
  routers: AnyFixMe,
) => {
  if (menuIdPanelWasOpenedFrom) {
    if (menuIdPanelWasOpenedFrom === 'CUSTOM_MAIN_MENU') {
      return 'CUSTOM_MAIN_MENU';
    }

    //for menus that still do not appear in pages panel but do have a router
    const menuAppId = editorAPI.dsRead.menu.getById(
      menuIdPanelWasOpenedFrom,
    ).appId;
    const routerToShow = routers.find(
      ({ applicationId }: AnyFixMe) => applicationId === menuAppId,
    );
    return routerToShow?.id;
  }

  return null;
};

const getSelectedHomepage = (editorAPI: AnyFixMe, menus: AnyFixMe) => {
  let homePageId = editorAPI.dsRead.homePage.get();
  let selectedMenuItem = editorAPI.menus.getSelectedMenuItemByPageId(
    menus,
    homePageId,
  );

  // set home page if empty https://jira.wixpress.com/browse/WEED-18962
  if (!selectedMenuItem?.id) {
    const firstNonHomePageMenuItem =
      editorAPI.mainMenu.getFirstNonHomePageMenuItem();
    homePageId = firstNonHomePageMenuItem.pageData.id;
    selectedMenuItem = editorAPI.menus.getSelectedMenuItemByPageId(
      menus,
      homePageId,
    );

    editorAPI.homePage.set(homePageId);
  }

  return {
    selectedPageId: homePageId,
    selectedMenuItemId: selectedMenuItem.id,
    selectedTabId: selectedMenuItem.menuId,
  };
};

const getSelectedRouterData = (
  editorAPI: AnyFixMe,
  selectedPageId: AnyFixMe,
  routers: AnyFixMe,
) => {
  const initialTab = routers.find(({ pagesData }: AnyFixMe) =>
    pagesData.some(({ id }: AnyFixMe) => id === selectedPageId),
  );
  const selectedTabId = initialTab?.id;

  if (selectedTabId) {
    return { selectedPageId, selectedTabId, selectedPageIsDynamic: true };
  }

  return null;
};

const getSelectedAppPageData = (
  editorAPI: AnyFixMe,
  selectedPageId: AnyFixMe,
  appPages: AnyFixMe,
  forceSelectedTabId: string,
) => {
  const initialTab = appPages.find(
    ({ pagesData }: AnyFixMe) =>
      pagesData && pagesData.some(({ id }: AnyFixMe) => id === selectedPageId),
  );
  const selectedTabId = forceSelectedTabId ?? initialTab?.id;

  if (selectedTabId) {
    return { selectedPageId, selectedTabId, selectedPageIsManagedByApp: true };
  }

  return null;
};

const getSelectedTransition = (editorAPI: AnyFixMe) => {
  const selectedTransitionId = editorAPI.pages.transitions.get();
  return _.get(
    {
      outIn: 'outIn',
      crossfade: 'crossfade',
      shrinkfade: 'crossfade',
      swipeHorizontal: 'swipeHorizontalFullScreen',
      swipeHorizontalFullScreen: 'swipeHorizontalFullScreen',
      swipeVertical: 'swipeVerticalFullScreen',
      swipeVerticalFullScreen: 'swipeVerticalFullScreen',
      none: 'none',
    },
    selectedTransitionId,
    'none',
  );
};

const getRenameItemId = (
  renameEnabled: AnyFixMe,
  menus: AnyFixMe,
  selectedMenuItemId: AnyFixMe,
  selectedPageId: AnyFixMe,
  initialTabId: AnyFixMe,
) => {
  if (!renameEnabled) {
    return null;
  }

  if (menus.some((menu: AnyFixMe) => menu.id === initialTabId)) {
    return selectedMenuItemId;
  }

  return selectedPageId;
};

const shouldShowDynamicPagesIntroTab = (editorAPI: AnyFixMe) =>
  Boolean(editorAPI.developerMode.isEnabled()) || isDataModeOn(editorAPI);

const setInPrefIscollapsedMenuItems = (
  editorAPI: EditorAPI,
  collapsedMenuItems: Record<string, boolean>,
  menuItemId: string,
  isCollapsed: boolean,
) => {
  if (!collapsedMenuItems) {
    // NOTE: https://github.com/wix-private/santa-editor/pull/20942#discussion_r331974822
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setSessionUserPreferences(
        'collapsedMenuItems',
        {},
      ),
    );
    collapsedMenuItems = {};
  }
  collapsedMenuItems = Object.assign({}, collapsedMenuItems, {
    [menuItemId]: Boolean(isCollapsed),
  });

  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSessionUserPreferences(
      'collapsedMenuItems',
      collapsedMenuItems,
    ),
  );
};

const mapStateToProps = ({ editorAPI, dsRead }: AnyFixMe, props: AnyFixMe) => {
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.PAGES_PANEL.NEW_MAP_STATE_TO_PROPS,
  );
  const state = editorAPI.store.getState();
  const openPanels = editorAPI.panelManager.getOpenPanels();
  const pagesData = dsRead.pages.getPagesData();
  const isDesktop = editorAPI.isDesktopEditor();

  const pagesStructure = pagesAPI.getFullPagesStructure(editorAPI);
  const { menus, routers, appPages, popups } = pagesStructure;

  const pagesAppDefId = appPages.reduce(
    (acc, { id }) => ({ ...acc, [id]: true }),
    {},
  );

  const specialRouters = routers.filter((router) => router.isSpecial);
  const userRouters = routers.filter((router) => !router.isSpecial);

  const hasRestrictedPage = pagesData.some(
    (data: AnyFixMe) => data.pageSecurity?.requireLogin,
  );

  const forceSelectedTabId =
    props.selectedTabId ??
    openPanels.find(
      (panel: AnyFixMe) =>
        panel.name === constants.ROOT_COMPS.LEFTBAR.MENUS_AND_PAGES_PANEL_NAME,
    )?.props?.selectedTabId;

  const collapsionMap =
    stateManagement.userPreferences.selectors.getSessionUserPreferences(
      'collapsedMenuItems',
    )(editorAPI.store.getState()) as Record<string, boolean>;

  const {
    selectedPageId,
    selectedPageIsDynamic,
    selectedMenuItemId,
    selectedTabId,
    selectedPageIsManagedByApp,
  } = getSelected(
    editorAPI,
    menus,
    routers,
    appPages,
    isDesktop,
    forceSelectedTabId,
    pagesAppDefId,
  );

  const globalEditingId =
    stateManagement.pages.selectors.getEditingMenuItemId(state);

  const renamedItemId =
    globalEditingId ||
    getRenameItemId(
      props.renameEnabled,
      menus,
      selectedMenuItemId,
      selectedPageId,
      selectedTabId,
    );
  util.fedopsLogger.interactionEnded(
    util.fedopsLogger.INTERACTIONS.PAGES_PANEL.NEW_MAP_STATE_TO_PROPS,
  );

  const hasUserCollections = selectUserCollections(state).length > 0;

  return {
    selectedPageId,
    selectedMenuItemId: renamedItemId || selectedMenuItemId,
    selectedPageIsDynamic,
    selectedTabId,
    isForceSelectedTabId: Boolean(forceSelectedTabId),
    selectedPageIsManagedByApp,
    initialTabId:
      getInitialTab(editorAPI, props.menuId, routers) || selectedTabId,
    pagesData,
    menus,
    specialRouters,
    userRouters,
    appPages,
    popups,
    isDesktop,
    renamedItemId,
    biPanelName: PANEL_NAME,
    shouldShowDynamicPagesIntroTab: shouldShowDynamicPagesIntroTab(editorAPI),
    hasRestrictedPage,
    isMembersAreaV2Installed: editorAPI.tpa.app.isInstalled(MEMBERS_AREA_V2),
    hasCustomSignupLightbox: hasCustomSignupLightbox(editorAPI),
    hasCustomSignInLightbox: hasCustomSignInLightbox(editorAPI),
    selectedTransition: getSelectedTransition(editorAPI),
    forceOpenPagesPanelCounter: editorAPI.getForceOpenPagesPanelEventCounter(),
    setInPrefIscollapsedMenuItems(menuItemId: string, isCollapsed: boolean) {
      setInPrefIscollapsedMenuItems(
        editorAPI,
        collapsionMap,
        menuItemId,
        isCollapsed,
      );
    },
    collapsionMap,
    hasUserCollections,
  };
};

const openHelpCenter =
  (panelName: AnyFixMe, helpId: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const biHelpParams = {
      panel_name: panelName,
      origin: constants.BI.HELP.ORIGIN.PAGES_PANEL,
      learn_more: true,
    };
    editorAPI.panelManager.openHelpCenter(helpId, null, biHelpParams);
  };

const onPanelOpen = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: { editorAPI: EditorAPI },
) => {
  editorAPI.temporaryAdvancedMenuAPI.openMigrationPanelIfNeeded('pages-panel');
};

const closePanel =
  (panelName: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    editorAPI.panelManager.closePanelByName(panelName);
  };

const getPageData =
  (pageId: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { dsRead }: AnyFixMe) =>
    dsRead.pages.data.get(pageId);

const getAddDynamicPage = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => {
  const { applicationId } =
    editorAPI.dsRead.platform.getAppDataByAppDefId(DATA_BINDING);

  editorAPI.dsActions.platform.notifyApplication(applicationId, {
    eventType: EVENTS.addDynamicPageClicked,
    eventPayload: {
      origin: 'pagesPanel',
    },
  });
};

const getOpenNewDynamicPagePanel = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => {
  const { applicationId } =
    editorAPI.dsRead.platform.getAppDataByAppDefId(DATA_BINDING);

  editorAPI.dsActions.platform.notifyApplication(applicationId, {
    eventType: EVENTS.openNewDynamicPagePanel,
    eventPayload: {
      origin: 'pagesPanel',
    },
  });
};

const getOnClickTransition =
  (newTransition: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const getPageId = (item: AnyFixMe) => item.link.pageId.replace('#', '');

    editorAPI.pages.transitions.set(newTransition);
    editorAPI.bi.event(
      coreBi.events.pages.pagesPanel.page_transition_selected_click,
      { category: newTransition },
    );

    const currentPageId = editorAPI.dsRead.pages.getPrimaryPageId();
    const pageIds = _(editorAPI.dsRead.mainMenu.getMenu(true))
      .flatMap((item) => [item, ...item.items])
      .filter(
        (item) =>
          item?.link?.type === 'PageLink' &&
          !editorAPI.dsRead.pages.popupPages.isPopup(getPageId(item)),
      )
      .map(getPageId)
      .value();

    const currentPageIndex = pageIds.indexOf(currentPageId);
    const nextPageIndex = (currentPageIndex + 1) % pageIds.length;
    if (nextPageIndex !== currentPageIndex) {
      editorAPI.pages.navigateTo(pageIds[nextPageIndex]);
    }
    editorAPI.waitForChangesApplied(function () {
      editorAPI.pages.navigateTo(pageIds[currentPageIndex]);
    });
  };

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapDispatchToProps = (dispatch: Dispatch, { panelName }: AnyFixMe) => ({
  notifyDataBindingAppTooManyPages:
    dispatch(getEditorAPI).pages.notifyDataBindingAppTooManyPages,

  onHelp(helpId: AnyFixMe) {
    return dispatch(openHelpCenter(panelName, helpId));
  },

  onOpen() {
    dispatch(onPanelOpen);
  },

  onClose() {
    return dispatch(closePanel(panelName));
  },

  getPageData(pageId: AnyFixMe) {
    return dispatch(getPageData(pageId));
  },

  biEvent(eventType: AnyFixMe, params: AnyFixMe) {
    return dispatch(stateManagement.bi.actions.event(eventType, params));
  },

  addDynamicPage() {
    return dispatch(getAddDynamicPage);
  },

  openNewDynamicPagePanel() {
    return dispatch(getOpenNewDynamicPagePanel);
  },

  onClickTransition(newTransition: AnyFixMe) {
    dispatch(getOnClickTransition(newTransition));
  },

  clearForceSelectedTabId() {
    dispatch(getEditorAPI).panelManager.updatePanelProps(
      constants.ROOT_COMPS.LEFTBAR.MENUS_AND_PAGES_PANEL_NAME,
      {
        selectedTabId: null,
      },
    );
  },

  resetEditingId() {
    dispatch(stateManagement.pages.actions.setEditItemId(null));
  },
});

export { mapStateToProps, mapDispatchToProps };
