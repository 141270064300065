import React from 'react';
import { RichText } from '@wix/wix-base-ui';

import { translate } from '@/i18n';
import { workspace } from '@/util';
import * as BaseUI from '@/baseUI';
import { frames } from '@/panels';

const { MessagePanelFrame, OldMessagePanelFrame } = frames;

interface CancelFontUploadPanelProps {
  panelName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const CancelFontUploadPanel: React.FC<CancelFontUploadPanelProps> = (props) => {
  return (
    <OldMessagePanelFrame
      panelName={props.panelName}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      confirmLabel="Upload_Fonts_Close_Panel_Pop_Up_Button_1"
      cancelLabel="Upload_Fonts_Close_Panel_Pop_Up_Button_2"
      type="danger"
      panelTitle={translate('Upload_Fonts_Close_Panel_Pop_Up_Header')}
      className="close-font-message"
    >
      <RichText type="T01">
        {translate('Upload_Fonts_Close_Panel_Pop_Up_Text')}
      </RichText>
    </OldMessagePanelFrame>
  );
};

const NewWorkspaceCancelFontUploadPanel: React.FC<
  CancelFontUploadPanelProps
> = (props) => {
  return (
    <MessagePanelFrame
      theme="destructive"
      panelName={props.panelName}
      title={translate('Upload_Fonts_Close_Panel_Pop_Up_Header')}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      confirmLabel={translate('Upload_Fonts_Close_Panel_Pop_Up_Cancel_Button')}
      cancelLabel={translate('Upload_Fonts_Close_Panel_Pop_Up_Continue_Button')}
      illustration={<BaseUI.symbol name="cancelUploads_NewWorkspace" />}
    >
      {translate('Upload_Fonts_Close_Panel_Pop_Up_Text')}
    </MessagePanelFrame>
  );
};

export default workspace.isNewWorkspaceEnabled()
  ? NewWorkspaceCancelFontUploadPanel
  : CancelFontUploadPanel;
