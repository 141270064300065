// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  label: string;
}

export default class extends React.Component<Props> {
  static displayName = 'thumbnailExtensionLabel';

  static propTypes = {
    label: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className="media-manager-panel-preset-extension-label">
        {this.props.label}
      </div>
    );
  }
}
