import type { TokenUsage } from '../aiWriterPanelTypes';

export const createQueryOutputBiData = (queryOutput: AnyFixMe) => {
  return {
    pageComponents: queryOutput.idMap,
    tokens: queryOutput.completionMetadata?.tokenUsage[0],
    query_output: queryOutput.outline,
    prompt: queryOutput.completionMetadata?.promptsVersion,
    gptParams: queryOutput.completionMetadata?.gptParamsVersion,
  };
};
export const getTokensFromQueryOutput = (queryOutputs: any): TokenUsage[] => {
  const query = queryOutputs?.completionMetadata
    ? queryOutputs?.completionMetadata?.tokenUsage
    : queryOutputs?.tokenUsage;
  return query.flat() || [];
};
