:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._input_mm0b8_7 {
  text-align: left; }
  ._input_mm0b8_7 .control-search-input > .input-container > .input {
    border-radius: 4px;
    height: 36px;
    padding: 0 11px 0;
    font-size: 16px; }
  ._input_mm0b8_7 .control-search-input__prefix {
    display: none; }
