// @ts-nocheck
import _ from 'lodash';
import advancedStyleDataUtil from './advancedStyleDataUtil';
import * as util from '@/util';

const getSkinParamsForAutomation = function (skinName) {
  const isQA = util.url.isTrue('isqa') || util.url.isTrue('isQA'); // don't use isQA anymore
  if (isQA) {
    const { editorAPI } = window.testApi;
    const rawSkinDefinition =
      editorAPI.dsRead.theme.skins.getSkinDefinition(skinName);
    const isDeveloperModeEnabled = editorAPI.developerMode.isEnabled();

    const selectedComponent = _.head(
      _.castArray(editorAPI.selection.getSelectedComponents()),
    );
    const skinParamsToIgnore =
      editorAPI.components.is.skinParamsToIgnore(selectedComponent);

    const panelData = advancedStyleDataUtil.getPanelData(
      skinName,
      rawSkinDefinition,
      skinParamsToIgnore,
      isDeveloperModeEnabled,
    );

    const skinParams = [];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(panelData.regular, function (categoryData) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(categoryData, function (section) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(section.params, function (param) {
          skinParams.push({ paramKey: param.paramKey, paramType: param.type });
        });
      });
    });

    return skinParams;
  }
};

const getSkinListBySkinParamType = function (compType, paramType) {
  const isQA = util.url.isTrue('isqa') || util.url.isTrue('isQA'); // don't use isQA anymore
  if (isQA) {
    const { editorAPI } = window.testApi;
    const getSkinDefinitionFunction =
      editorAPI.dsRead.theme.skins.getSkinDefinition;

    const skins = editorAPI.dsRead.theme.skins.getComponentSkins(compType);
    const compSkins = advancedStyleDataUtil.filterSkins(skins);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _.filter(compSkins, function (skinName) {
      const paramTypes = advancedStyleDataUtil.getSkinParamsTypes(
        getSkinDefinitionFunction,
        skinName,
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      return _.includes(paramTypes, paramType);
    });
  }
};

export { getSkinParamsForAutomation, getSkinListBySkinParamType };
