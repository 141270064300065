import React, { type FC } from 'react';
import _ from 'lodash';
import { cx, hoc } from '@/util';
import { useTranslation } from 'react-i18next';
import withOpenDropPanel, {
  type WithOpenDropPanelProps,
} from '../hocs/withOpenDropPanel';
import {
  mapStateToProps,
  mapDispatchToProps,
  type ToolsButtonStateProps,
  type ToolsButtonDispatchProps,
} from './toolsMappers';
import TopBarButton from '../topBarButton/topBarButtonWithTooltip';
import TooltipContent from '../tooltipContent';
import DropDown from '../dropDown/dropDown';
import ToolsPanel from './toolsPanel';
import { withRestrictions } from '@/editorRestrictions';
import { Custom } from '@wix/wix-ui-icons-common/classic-editor';

export interface ToolsButtonOwnProps {
  className?: string;
  delayedFadeOut?: boolean;
}

interface ToolsButtonProps
  extends ToolsButtonOwnProps,
    ToolsButtonStateProps,
    ToolsButtonDispatchProps,
    WithOpenDropPanelProps {}

const ToolsButton: FC<ToolsButtonProps> = ({
  className,
  closeDropPanel,
  delayedFadeOut,
  isPanelOpen,
  openDropPanel,
  panelName,
  isZoomMode,
  isLabelShown,
  reportToolsClicked,
}) => {
  const { t } = useTranslation();

  const enabledTooltip = isPanelOpen ? null : (
    <TooltipContent
      tooltipData={{
        title: 'TOPBAR_TOOLS_TOOLTIP_TITLE',
        text: 'TOPBAR_TOOLS_TOOLTIP_TEXT',
      }}
    />
  );
  const disabledTooltip = (
    <TooltipContent
      tooltipData={{
        text: 'TOPBAR_ZoomOutSection_Tools_Disabled_Tooltip',
      }}
    />
  );

  const shouldShowDisabledTooltip = isZoomMode;

  const tooltip = shouldShowDisabledTooltip ? disabledTooltip : enabledTooltip;

  const handleClick = () => {
    openDropPanel(panelName);
    reportToolsClicked();
  };

  return (
    <DropDown
      isOpen={isPanelOpen}
      delayedFadeOut={delayedFadeOut}
      panelContent={<ToolsPanel closePanel={closeDropPanel} />}
      onPanelOuterClick={closeDropPanel}
      alignPanelLeft
    >
      <TopBarButton
        tooltip={tooltip}
        toggled={isPanelOpen}
        automationId="top-bar-tools-button"
        className={cx('top-bar-tools-btn', className, {
          'lower-position': true,
          minimized: !isLabelShown,
        })}
        customSymbol={<Custom className={'btn-symbol '} />}
        label={isLabelShown && t('Topbar_Tools_Label')}
        onClick={handleClick}
        disabled={shouldShowDisabledTooltip}
        tooltipMaxWidth={230}
      />
    </DropDown>
  );
};

const ConnectedToolsButton = _.flow(
  withRestrictions('top-bar_tools'),
  hoc.connect(hoc.STORES.EDITOR_API, mapStateToProps, mapDispatchToProps),
  withOpenDropPanel,
)(ToolsButton) as React.ComponentType;

(ConnectedToolsButton as AnyFixMe).pure = ToolsButton;

export default ConnectedToolsButton;
