import React from 'react';
import DescriptionLayout from './descriptionLayout';

interface GenericDescriptionProps {
  onMainActionClick: () => void;
}

const GenericDescription: React.FC<GenericDescriptionProps> = ({
  onMainActionClick,
}) => (
  <DescriptionLayout
    title={'Platform_Pages_Menu_Add_Dynamic_Page_Member_Title'}
    subtitle={'Platform_Pages_Menu_Add_Dynamic_Page_Member_Text'}
    mainActionLabel={'Platform_Pages_Menu_Add_Dynamic_Page_Add_Button'}
    onMainActionClick={onMainActionClick}
  />
);

export default GenericDescription;
