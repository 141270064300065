@charset "UTF-8";
:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._panel-container_7n2pz_8 {
  display: flex;
  flex-direction: column;
  height: 100%; }

._panel-content_7n2pz_13 {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box; }

._bg-panel_7n2pz_19 {
  height: 100%;
  background-color: #fff; }
  ._bg-panel_7n2pz_19._designPanel_7n2pz_22 {
    flex: 1;
    display: flex; }
  ._bg-panel_7n2pz_19 .panel-container,
  ._bg-panel_7n2pz_19 .custom-scroll,
  ._bg-panel_7n2pz_19 .outer-container {
    display: flex;
    flex-direction: column;
    flex: 1; }
  ._bg-panel_7n2pz_19 ._bg-panel-top_7n2pz_31 {
    position: relative; }
  ._bg-panel_7n2pz_19 ._bg-panel-preview_7n2pz_33 {
    height: 174px;
    width: 100%; }
  ._bg-panel_7n2pz_19 ._bg-panel-preview-media_7n2pz_36 {
    position: absolute;
    width: 100%;
    height: 174px; }
  ._bg-panel_7n2pz_19 ._bg-panel-preview-color_7n2pz_40 {
    width: 44px;
    height: 44px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0; }
    ._bg-panel_7n2pz_19 ._bg-panel-preview-color_7n2pz_40 ._symbol-fold-clipart_7n2pz_48 {
      width: 50px;
      height: 50px;
      position: relative;
      top: 0;
      left: 0; }
  ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 {
    width: 100%;
    height: 120px;
    text-align: center;
    position: absolute; }
    ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 {
      display: inline-block;
      text-align: center;
      vertical-align: top;
      margin-top: 52px;
      padding: 0 4px;
      position: relative; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54._adjust-image-control_7n2pz_66 {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: #fff 2px solid;
        box-sizing: border-box;
        background-color: rgba(22, 45, 61, 0.5);
        fill: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54._adjust-image-control_7n2pz_66:hover {
        border-color: #5999ff;
        fill: #5999ff; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54._adjust-image-control_7n2pz_66:active {
        border: #fff 2px solid;
        fill: #fff; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .symbol-play-video,
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video .symbol-play-video {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: #fff 2px solid;
        box-sizing: border-box;
        background-color: rgba(22, 45, 61, 0.5); }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video:hover .symbol-play-video {
        border-color: #5999ff; }
        ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video:hover .symbol-play-video .st0 {
          fill: #5999ff; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video {
        border-color: #5999ff; }
        ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video .st0 {
          fill: transparent; }
        ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video .st1 {
          fill: #5999ff; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video:active .input-play-video:checked ~ .symbol-play-video {
        border-color: #fff !important; }
        ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video:active .input-play-video:checked ~ .symbol-play-video .st0 {
          fill: transparent !important; }
        ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video:active .input-play-video:checked ~ .symbol-play-video .st1 {
          fill: #fff !important; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video:active .symbol-play-video {
        border: #fff 2px solid; }
        ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video:active .symbol-play-video .st0 {
          fill: #fff; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video .input-play-video:disabled ~ .symbol-play-video {
        pointer-events: none;
        border-color: #bcbcbc; }
        ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video .input-play-video:disabled ~ .symbol-play-video .st0 {
          fill: #bcbcbc; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video {
        pointer-events: none;
        border-color: #bcbcbc; }
        ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video .st1 {
          fill: #bcbcbc; }
        ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video .st0 {
          fill: transparent; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .symbol-settings-icon,
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-settings-icon .symbol-settings-icon {
        position: absolute;
        left: 5px;
        top: 1px; }
        ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .symbol-settings-icon .st0,
        ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-settings-icon .symbol-settings-icon .st0 {
          fill: #fff; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-settings-icon:hover .symbol-settings-icon .st0 {
        fill: #5999ff; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .label-settings-icon,
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-settings-icon .label-settings-icon {
        border-radius: 18px;
        border: #fff 2px solid;
        box-sizing: border-box;
        background-color: rgba(22, 45, 61, 0.5);
        height: 36px;
        line-height: 30px;
        padding: 0 12px 0 34px;
        color: #fff; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-settings-icon:hover .label-settings-icon {
        border-color: #5999ff;
        color: #5999ff; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-settings-icon:active .symbol-settings-icon .st0 {
        fill: #fff; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-settings-icon:active .input-settings-icon:checked ~ .symbol-settings-icon .st0 {
        fill: #fff; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-settings-icon:active .label-settings-icon {
        border: #fff 2px solid;
        color: #fff; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .control-settings-icon:active .input-settings-icon:checked ~ .label-settings-icon {
        border: #fff 2px solid;
        color: #fff; }
      ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .circle-preloader {
        width: 18px;
        height: 36px;
        margin: 0;
        top: 0;
        left: 4px;
        animation-duration: 2s; }
        ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .circle-preloader::before, ._bg-panel_7n2pz_19 ._bg-panel-preview-buttons_7n2pz_54 ._bg-panel-preview-button_7n2pz_54 .circle-preloader::after {
          color: #fff;
          border-width: 2px;
          animation-duration: 1s; }
  ._bg-panel_7n2pz_19 ._bg-panel-top-actions_7n2pz_168 {
    position: absolute;
    bottom: 0;
    height: 54px;
    width: 100%;
    background: rgba(177, 221, 248, 0.4);
    text-align: center; }
  ._bg-panel_7n2pz_19 ._bg-panel-action_7n2pz_175 {
    padding: 11px 5px 0 5px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative; }
    ._bg-panel_7n2pz_19 ._bg-panel-action_7n2pz_175 .control-button2._bg-panel-action-btn_7n2pz_184 {
      background-color: #fff;
      height: 32px;
      line-height: 32px;
      width: 82px;
      text-align: center;
      max-width: 123456px; }
    ._bg-panel_7n2pz_19 ._bg-panel-action_7n2pz_175 .control-button2._bg-panel-action-btn_7n2pz_184 .symbol {
      margin-left: -1px; }
      ._bg-panel_7n2pz_19 ._bg-panel-action_7n2pz_175 .control-button2._bg-panel-action-btn_7n2pz_184 .symbol .st0 {
        fill: #116dff; }
    ._bg-panel_7n2pz_19 ._bg-panel-action_7n2pz_175 .control-button2._bg-panel-action-btn_7n2pz_184 .label {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      color: #116dff;
      padding: 0 8px 0 0;
      margin-left: -2px; }
    ._bg-panel_7n2pz_19 ._bg-panel-action_7n2pz_175 .control-button2._bg-panel-action-btn_7n2pz_184:active {
      background-color: #e7f0ff; }
    ._bg-panel_7n2pz_19 ._bg-panel-action_7n2pz_175 .control-button2._bg-panel-action-btn_7n2pz_184
input:disabled
~ .label {
      color: #bcbcbc; }
    ._bg-panel_7n2pz_19 ._bg-panel-action_7n2pz_175 .control-button2._bg-panel-action-btn_7n2pz_184[disabled] {
      background-color: #fff; }
    ._bg-panel_7n2pz_19 ._bg-panel-action_7n2pz_175 .control-button2._bg-panel-action-btn_7n2pz_184
input:disabled
~ .symbol .st0 {
      fill: #bcbcbc; }
    ._bg-panel_7n2pz_19 ._bg-panel-action_7n2pz_175 :first-child {
      padding-left: 0; }
    ._bg-panel_7n2pz_19 ._bg-panel-action_7n2pz_175 :last-child {
      padding-right: 0; }
    ._bg-panel_7n2pz_19 ._bg-panel-action_7n2pz_175 ._bg-panel-action-btn_7n2pz_184.centered {
      display: flex;
      align-items: center;
      justify-content: center; }
      ._bg-panel_7n2pz_19 ._bg-panel-action_7n2pz_175 ._bg-panel-action-btn_7n2pz_184.centered .symbol {
        margin-right: 6px; }
      ._bg-panel_7n2pz_19 ._bg-panel-action_7n2pz_175 ._bg-panel-action-btn_7n2pz_184.centered .label {
        padding: 0; }
  ._bg-panel_7n2pz_19 ._bg-panel-bottom_7n2pz_228 {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 0, white 70%);
    height: 73px;
    padding-top: 19px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    box-sizing: border-box; }
  ._bg-panel_7n2pz_19 ._bg-panel-section-list_7n2pz_238 {
    margin-top: 21px; }
  ._bg-panel_7n2pz_19 ._bg-panel-preset-list_7n2pz_240 {
    width: 100%;
    box-sizing: border-box; }
    ._bg-panel_7n2pz_19 ._bg-panel-preset-list_7n2pz_240 ._bg-panel-preset-list-sections_7n2pz_243 {
      padding: 20px 25px 73px; }
      ._bg-panel_7n2pz_19 ._bg-panel-preset-list_7n2pz_240 ._bg-panel-preset-list-sections_7n2pz_243 .preset-list-item {
        display: inline-block;
        font-size: 0;
        width: 33.33%;
        margin-bottom: 18px;
        text-align: center; }
        ._bg-panel_7n2pz_19 ._bg-panel-preset-list_7n2pz_240 ._bg-panel-preset-list-sections_7n2pz_243 .preset-list-item:nth-child(1), ._bg-panel_7n2pz_19 ._bg-panel-preset-list_7n2pz_240 ._bg-panel-preset-list-sections_7n2pz_243 .preset-list-item:nth-child(3n + 1) {
          text-align: left; }
        ._bg-panel_7n2pz_19 ._bg-panel-preset-list_7n2pz_240 ._bg-panel-preset-list-sections_7n2pz_243 .preset-list-item:nth-child(3n + 3) {
          text-align: right; }
      ._bg-panel_7n2pz_19 ._bg-panel-preset-list_7n2pz_240 ._bg-panel-preset-list-sections_7n2pz_243 .preset-list-preset {
        display: inline-block; }

._tpa-settings-panel-frame_7n2pz_258 {
  position: absolute;
  top: 32px;
  left: 78px; }

._classic-facelift-skin_7n2pz_263
._bg-panel_7n2pz_19
._bg-panel-action_7n2pz_175
.control-button2._bg-panel-action-btn_7n2pz_184
.label {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
