'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const i18n = require('@/i18n')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_preset_list_team_members_tall',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': `addPanelData/sections/listTeamMembersSection_${util.languages.getLanguageCode()}/listTeamMembersSection_${util.languages.getLanguageCode()}.png`,
        'imageHover': null,
        'items': [{
            'id': 'Lists_List_Team_Members_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 364,
                    'height': 1458,
                    'x': 98,
                    'y': 23,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti9ec8hnu72'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'fields': [{
                                'displayName': i18n.translate('i9ec8hqz_2.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9ec8hqz_2.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'searchable': false,
                                'displayName': i18n.translate('i9ec8hqz_2.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'name': 'Strng_sTxt0',
                                'computed': false,
                                'defaultValue': i18n.translate('i9ec8hqz_2.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i9ec8hqz_2.structure.custom.template.type.fields.2.displayName'),
                                'name': 'i98cfnvz',
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i9ec8hqz_2.structure.custom.template.type.fields.3.displayName'),
                                'name': 'wxRchTxt_sTxt4',
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i9ec8hqz_2.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false
                            }, {
                                'name': 'i98ch45k',
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i9ec8hqz_2.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9ec8hqz_2.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i9ec8hqz_2.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'name': 'i6lyi5j8',
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i9ec8hqz_2.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i9ec8hqz_2.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i9ec8hqz_2.structure.custom.template.type.fields.7.displayName'),
                                'computed': false,
                                'name': 'i71pp1vp'
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'name': 'TeamList_i6fex53s439',
                            'version': 0,
                            'baseTypes': [],
                            'displayName': i18n.translate('i9ec8hqz_2.structure.custom.template.type.displayName')
                        },
                        'views': [{
                            'name': 'TeamListView_i6fex53s440_dup_i6krlro2619_dup_i6qm3p5p329_dup_i6uu2ne8143_dup_i70h8y01197_dup_i7gal7zy256_dup_i9ec8hna613',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TeamListView_i6fex53s440_dup_i6krlro2619_dup_i6qm3p5p329_dup_i6uu2ne8143_dup_i70h8y01197_dup_i7gal7zy256_dup_i9ec8hna613'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TeamListView_i6fex53s440_dup_i6krlro2619_dup_i6qm3p5p329_dup_i6uu2ne8143_dup_i70h8y01197_dup_i7gal7zy256_dup_i9ec8hna613'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9ec8hna'
                        }, {
                            'name': 'TeamListView_i6fex53s440_dup_i6krlro2619_dup_i6qm3p5p329_dup_i6uu2ne8143_dup_i70h8y01197_dup_i7gal7zy256_dup_i9ec8hna613',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TeamListView_i6fex53s440_dup_i6krlro2619_dup_i6qm3p5p329_dup_i6uu2ne8143_dup_i70h8y01197_dup_i7gal7zy256_dup_i9ec8hna613'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TeamListView_i6fex53s440_dup_i6krlro2619_dup_i6qm3p5p329_dup_i6uu2ne8143_dup_i70h8y01197_dup_i7gal7zy256_dup_i9ec8hna613'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9ec8hna',
                            'format': 'Mobile'
                        }, {
                            'name': 'TeamListView_i6fex53s440_dup_i6krlro2619_dup_i6qm3p5p329_dup_i6uu2ne8143_dup_i70h8y01197_dup_i7gal7zy256_dup_i9ec8hna613',
                            'forType': 'TeamList_i6fex53s439',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i71pqmwu159',
                                            'data': 'i6lyi5j8',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 358,
                                                'height': '220',
                                                'items': [{
                                                    'id': 'fld_i71pqmwu159_proxy',
                                                    'data': 'i6lyi5j8',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 15,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 10
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'items': [{
                                                    'id': 'image_1',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 358,
                                                        'items': [{
                                                            'id': 'image_1_proxy',
                                                            'data': 'image',
                                                            'comp': {'name': 'Image', 'style': 'wp2'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 15,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'height': 280,
                                                        'box-align': 'left'
                                                    }
                                                }, {
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'italic': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_hfwpt6aj',
                                                    'data': 'Strng_sTxt0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfwpt6aj_proxy',
                                                            'data': 'Strng_sTxt0',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'experiment': 'NewListFields',
                                                    'id': 'fld_i98cfnxm76',
                                                    'data': 'i98cfnvz',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i98cfnxm76_proxy',
                                                            'data': 'i98cfnvz',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mmmm dd, yyyy',
                                                                'bold': false,
                                                                'underline': false,
                                                                'italic': true,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 3,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true
                                                    }
                                                }, {
                                                    'id': 'fld_hfxsv1z6',
                                                    'data': 'wxRchTxt_sTxt4',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfxsv1z6_proxy',
                                                            'data': 'wxRchTxt_sTxt4',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 3,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false
                                                    }
                                                }, {
                                                    'experiment': 'NewListFields',
                                                    'id': 'fld_i98ch46l117',
                                                    'data': 'i98ch45k',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i98ch46l117_proxy',
                                                            'data': 'i98ch45k',
                                                            'comp': {'name': 'Label', 'style': 'Heading S'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 6,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 200}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 10,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i71pp1x0145',
                                            'data': 'i71pp1vp',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 160,
                                                'items': [{
                                                    'id': 'fld_i71pp1x0145_proxy',
                                                    'data': 'i71pp1vp',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 18,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': '40',
                                                'box-align': 'left'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }, {
                            'name': 'TeamListView_i6fex53s440_dup_i6krlro2619_dup_i6qm3p5p329_dup_i6uu2ne8143_dup_i70h8y01197_dup_i7gal7zy256_dup_i9ec8hna613',
                            'forType': 'TeamList_i6fex53s439',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i71pqmwu159',
                                            'data': 'i6lyi5j8',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 260,
                                                'height': '220',
                                                'items': [{
                                                    'id': 'fld_i71pqmwu159_proxy',
                                                    'data': 'i6lyi5j8',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 15,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }, {
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'items': [{
                                                            'id': 'image_1',
                                                            'data': 'image',
                                                            'comp': {
                                                                'name': 'Field',
                                                                'width': 280,
                                                                'items': [{
                                                                    'id': 'image_1_proxy',
                                                                    'data': 'image',
                                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 15,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'height': 280,
                                                                'box-align': 'left'
                                                            }
                                                        }, {
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 260,
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'color': 'color_14',
                                                                        'bold': false,
                                                                        'italic': false
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_hfwpt6aj',
                                                            'data': 'Strng_sTxt0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 260,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hfwpt6aj_proxy',
                                                                    'data': 'Strng_sTxt0',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Body L'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_i98cfnxm76',
                                                            'data': 'i98cfnvz',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 260,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i98cfnxm76_proxy',
                                                                    'data': 'i98cfnvz',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'color': 'color_14',
                                                                        'format': 'mmmm dd, yyyy',
                                                                        'bold': false,
                                                                        'underline': false,
                                                                        'italic': true
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 3,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hfxsv1z6',
                                                            'data': 'wxRchTxt_sTxt4',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 260,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hfxsv1z6_proxy',
                                                                    'data': 'wxRchTxt_sTxt4',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 3,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'experiment': 'NewListFields',
                                                            'id': 'fld_i98ch46l117',
                                                            'data': 'i98ch45k',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 260,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i98ch46l117_proxy',
                                                                    'data': 'i98ch45k',
                                                                    'comp': {'name': 'Label', 'style': 'Heading S'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 6,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 200}
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'def_3_0',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i71pp1x0145',
                                            'data': 'i71pp1vp',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i71pp1x0145_proxy',
                                                    'data': 'i71pp1vp',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 18,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': 44,
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'originalWidth': 280, 'wasChanged': true},
                            'format': 'Mobile'
                        }],
                        'items': [{
                            'title': i18n.translate('i9ec8hqz_2.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_5a6f018840b44388b91b4b5706a5e4e6.jpg',
                                'width': 949,
                                'height': 989,
                                'alt': ''
                            },
                            'Strng_sTxt0': i18n.translate('i9ec8hqz_2.structure.custom.template.items.0.Strng_sTxt0'),
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ec8hqz_2.structure.custom.template.items.0.wxRchTxt_sTxt4')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6lyih4p': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                },
                                'i71pp1vp': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6lyi5j8': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i71pp1vp': i18n.translate('i9ec8hqz_2.structure.custom.template.items.0.i71pp1vp'),
                            'i98cfnvz': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i98ch45k': i18n.translate('i9ec8hqz_2.structure.custom.template.items.0.i98ch45k')
                        }, {
                            'title': i18n.translate('i9ec8hqz_2.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': 'shutterstock_255223495_size_crop.jpg',
                                'src': '8d13be_58e182dbf80f4125bc5873921b74753c.jpg',
                                'width': 949,
                                'height': 989,
                                'alt': ''
                            },
                            'Strng_sTxt0': i18n.translate('i9ec8hqz_2.structure.custom.template.items.1.Strng_sTxt0'),
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ec8hqz_2.structure.custom.template.items.1.wxRchTxt_sTxt4')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6lyih4p': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                },
                                'i71pp1vp': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6lyi5j8': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i71pp1vp': i18n.translate('i9ec8hqz_2.structure.custom.template.items.1.i71pp1vp'),
                            'i98cfnvz': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i98ch45k': i18n.translate('i9ec8hqz_2.structure.custom.template.items.1.i98ch45k')
                        }, {
                            'title': i18n.translate('i9ec8hqz_2.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_40f316547dc047898fe868ecef9bf054.jpg',
                                'width': 876,
                                'height': 887,
                                'alt': ''
                            },
                            'Strng_sTxt0': i18n.translate('i9ec8hqz_2.structure.custom.template.items.2.Strng_sTxt0'),
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ec8hqz_2.structure.custom.template.items.2.wxRchTxt_sTxt4')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6lyih4p': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                },
                                'i71pp1vp': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6lyi5j8': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i71pp1vp': i18n.translate('i9ec8hqz_2.structure.custom.template.items.2.i71pp1vp'),
                            'i98cfnvz': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i98ch45k': i18n.translate('i9ec8hqz_2.structure.custom.template.items.2.i98ch45k')
                        }],
                        'displayName': i18n.translate('i9ec8hqz_2.structure.custom.template.displayName')
                    }
                },
                'id': 'i9ec8hqz_2'
            },
            'preset': {
                'rect': {'width': 162, 'height': 553, 'x': 0, 'y': 0},
                'label': 'i9ec8hqz_2.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Team_Members_2',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 522,
                    'height': 518,
                    'x': 693,
                    'y': 60,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti9ec8hn869'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'name': 'NewsPosts_i6mbwbwz526',
                            'displayName': i18n.translate('i9ec8hqz_1.structure.custom.template.type.displayName'),
                            'fields': [{
                                'displayName': i18n.translate('i9ec8hqz_1.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9ec8hqz_1.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'Strng_sTxt1',
                                'searchable': false,
                                'displayName': i18n.translate('i9ec8hqz_1.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i9ec8hqz_1.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'name': 'i98c2l28',
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i9ec8hqz_1.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i9ec8hqz_1.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i9ec8hqz_1.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'name': 'wxRchTxt_sTxt0',
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i9ec8hqz_1.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'name': 'i984p612',
                                'type': 'String',
                                'defaultValue': i18n.translate('i9ec8hqz_1.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i9ec8hqz_1.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i9ec8hqz_1.structure.custom.template.type.fields.6.displayName'),
                                'name': 'i6mbxh6z',
                                'computed': false
                            }, {
                                'name': 'Strng_sBttn0-v1c',
                                'defaultValue': i18n.translate('i9ec8hqz_1.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i9ec8hqz_1.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'baseTypes': [],
                            'version': 0
                        },
                        'views': [{
                            'name': 'NewsPostsView_i6mbwbx0527_dup_i6qm3piz341_dup_i6uu2nda142_dup_i70h8xyx196_dup_i7gagrr7164_dup_i9ec8hmb612',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6mbwbx0527_dup_i6qm3piz341_dup_i6uu2nda142_dup_i70h8xyx196_dup_i7gagrr7164_dup_i9ec8hmb612'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6mbwbx0527_dup_i6qm3piz341_dup_i6uu2nda142_dup_i70h8xyx196_dup_i7gagrr7164_dup_i9ec8hmb612'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9ec8hmb'
                        }, {
                            'name': 'NewsPostsView_i6mbwbx0527_dup_i6qm3piz341_dup_i6uu2nda142_dup_i70h8xyx196_dup_i7gagrr7164_dup_i9ec8hmb612',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6mbwbx0527_dup_i6qm3piz341_dup_i6uu2nda142_dup_i70h8xyx196_dup_i7gagrr7164_dup_i9ec8hmb612'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6mbwbx0527_dup_i6qm3piz341_dup_i6uu2nda142_dup_i70h8xyx196_dup_i7gagrr7164_dup_i9ec8hmb612'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9ec8hmb',
                            'format': 'Mobile'
                        }, {
                            'name': 'NewsPostsView_i6mbwbx0527_dup_i6qm3piz341_dup_i6uu2nda142_dup_i70h8xyx196_dup_i7gagrr7164_dup_i9ec8hmb612',
                            'forType': 'NewsPosts_i6mbwbwz526',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'Left_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'items': [{
                                                                    'id': 'image_1',
                                                                    'data': 'image',
                                                                    'comp': {
                                                                        'name': 'Field',
                                                                        'width': 142,
                                                                        'items': [{
                                                                            'id': 'image_1_proxy',
                                                                            'data': 'image',
                                                                            'comp': {
                                                                                'name': 'Image',
                                                                                'style': 'wp2'
                                                                            }
                                                                        }],
                                                                        'spacers': {
                                                                            'before': 0,
                                                                            'after': 0,
                                                                            'xax-before': 0,
                                                                            'xax-after': 12
                                                                        },
                                                                        'height': 142,
                                                                        'box-align': 'start'
                                                                    }
                                                                }],
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                }
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Left_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': [{
                                                                    'id': 'fld_i71p9lea915',
                                                                    'data': 'i6mbxh6z',
                                                                    'comp': {
                                                                        'name': 'Field',
                                                                        'width': 280,
                                                                        'height': '220',
                                                                        'items': [{
                                                                            'id': 'fld_i71p9lea915_proxy',
                                                                            'data': 'i6mbxh6z',
                                                                            'comp': {'name': 'Video'},
                                                                            'layout': {'position': 'relative'}
                                                                        }],
                                                                        'spacers': {
                                                                            'before': 0,
                                                                            'after': 0,
                                                                            'xax-before': 0,
                                                                            'xax-after': 12
                                                                        },
                                                                        'box-align': 'left',
                                                                        'hidden': true,
                                                                        'labelPosition': 'none'
                                                                    }
                                                                }]
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Heading S',
                                                                        'bold': false,
                                                                        'italic': false
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0
                                                                },
                                                                'box-align': 'start',
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_i98c2l3a39',
                                                            'data': 'i98c2l28',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i98c2l3a39_proxy',
                                                                    'data': 'i98c2l28',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'color': 'color_14',
                                                                        'format': 'mmmm dd, yyyy'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true,
                                                                'labelPosition': 'none'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hfxs75de',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hfxs75de_proxy',
                                                                    'data': 'wxRchTxt_sTxt0',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 6,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_i984p62d91',
                                                            'data': 'i984p612',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_i984p62d91_proxy',
                                                                    'data': 'i984p612',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 6,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hfnxgxqo',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_hfnxgxqo_proxy',
                                                                    'data': 'Strng_sTxt1',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body S',
                                                                        'bold': false,
                                                                        'italic': true,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 10,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_i71p7qxe885',
                                                            'data': 'Strng_sBttn0-v1c',
                                                            'comp': {
                                                                'name': 'Field',
                                                                'width': 280,
                                                                'height': 44,
                                                                'items': [{
                                                                    'id': 'fld_i71p7qxe885_proxy',
                                                                    'data': 'Strng_sBttn0-v1c',
                                                                    'comp': {
                                                                        'name': 'Button2',
                                                                        'style': 'b1',
                                                                        'align': 'center'
                                                                    },
                                                                    'layout': {'position': 'relative'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 20,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'box-align': 'center'
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 200}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 3}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': [],
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'originalWidth': 601, 'wasChanged': true},
                            'format': 'Mobile'
                        }, {
                            'name': 'NewsPostsView_i6mbwbx0527_dup_i6qm3piz341_dup_i6uu2nda142_dup_i70h8xyx196_dup_i7gagrr7164_dup_i9ec8hmb612',
                            'forType': 'NewsPosts_i6mbwbwz526',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'items': [{
                                                    'id': 'image_1',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 142,
                                                        'items': [{
                                                            'id': 'image_1_proxy',
                                                            'data': 'image',
                                                            'comp': {'name': 'Image', 'style': 'wp2'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 20,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'height': 142,
                                                        'box-align': 'start'
                                                    }
                                                }, {
                                                    'id': 'fld_i71p9lea915',
                                                    'data': 'i6mbxh6z',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 330,
                                                        'height': '220',
                                                        'items': [{
                                                            'id': 'fld_i71p9lea915_proxy',
                                                            'data': 'i6mbxh6z',
                                                            'comp': {'name': 'Video'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 20,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left',
                                                        'hidden': true,
                                                        'labelPosition': 'none'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 20
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'box-align': 'start',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'italic': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {'before': 0, 'after': 0, 'xax-before': 0},
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_i98c2l3a39',
                                                    'data': 'i98c2l28',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i98c2l3a39_proxy',
                                                            'data': 'i98c2l28',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mmmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'labelPosition': 'none',
                                                        'hidden': true,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hfxs75de',
                                                    'data': 'wxRchTxt_sTxt0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfxs75de_proxy',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 6,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'id': 'fld_i984p62d91',
                                                    'data': 'i984p612',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i984p62d91_proxy',
                                                            'data': 'i984p612',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 6,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hfnxgxqo',
                                                    'data': 'Strng_sTxt1',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hfnxgxqo_proxy',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body S',
                                                                'bold': false,
                                                                'italic': true,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 10,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i71p7qxe885',
                                                    'data': 'Strng_sBttn0-v1c',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'items': [{
                                                            'id': 'fld_i71p7qxe885_proxy',
                                                            'data': 'Strng_sBttn0-v1c',
                                                            'comp': {
                                                                'name': 'Button2',
                                                                'style': 'b1',
                                                                'align': 'center'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 20,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'height': '40',
                                                        'box-align': 'start'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'pack': 'start'
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 200}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '3'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [],
                                                'pack': 'start'
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }],
                        'items': [{
                            'title': i18n.translate('i9ec8hqz_1.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_5a6f018840b44388b91b4b5706a5e4e6.jpg',
                                'width': 949,
                                'height': 989,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i9ec8hqz_1.structure.custom.template.items.0.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ec8hqz_1.structure.custom.template.items.0.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i9ec8hqz_1.structure.custom.template.items.0.Strng_sBttn0-v1c'),
                            'i6mbxh6z': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i984p612': i18n.translate('i9ec8hqz_1.structure.custom.template.items.0.i984p612'),
                            'i98c2l28': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'}
                        }, {
                            'title': i18n.translate('i9ec8hqz_1.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_58e182dbf80f4125bc5873921b74753c.jpg',
                                'width': 949,
                                'height': 989,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i9ec8hqz_1.structure.custom.template.items.1.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ec8hqz_1.structure.custom.template.items.1.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i9ec8hqz_1.structure.custom.template.items.1.Strng_sBttn0-v1c'),
                            'i6mbxh6z': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i984p612': i18n.translate('i9ec8hqz_1.structure.custom.template.items.1.i984p612'),
                            'i98c2l28': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'}
                        }, {
                            'title': i18n.translate('i9ec8hqz_1.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_40f316547dc047898fe868ecef9bf054.jpg',
                                'width': 876,
                                'height': 887,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i9ec8hqz_1.structure.custom.template.items.2.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ec8hqz_1.structure.custom.template.items.2.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i9ec8hqz_1.structure.custom.template.items.2.Strng_sBttn0-v1c'),
                            'i6mbxh6z': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i984p612': i18n.translate('i9ec8hqz_1.structure.custom.template.items.2.i984p612'),
                            'i98c2l28': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'}
                        }],
                        'displayName': i18n.translate('i9ec8hqz_1.structure.custom.template.displayName')
                    }
                },
                'id': 'i9ec8hqz_1'
            },
            'preset': {
                'rect': {'width': 162, 'height': 173, 'x': 162, 'y': 0},
                'label': 'i9ec8hqz_1.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Team_Members_3',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 384,
                    'height': 1162,
                    'x': 688,
                    'y': 703,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti9ec8hq381'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'fields': [{
                                'displayName': i18n.translate('i9ec8hqz_5.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9ec8hqz_5.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'searchable': false,
                                'displayName': i18n.translate('i9ec8hqz_5.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'name': 'Strng_sTxt0',
                                'computed': false,
                                'defaultValue': i18n.translate('i9ec8hqz_5.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'name': 'i98d2eu0',
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i9ec8hqz_5.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i9ec8hqz_5.structure.custom.template.type.fields.3.displayName'),
                                'name': 'wxRchTxt_sTxt4',
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i9ec8hqz_5.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false
                            }, {
                                'name': 'i98cnr17',
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i9ec8hqz_5.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9ec8hqz_5.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i9ec8hqz_5.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'name': 'i6uv4iab',
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i9ec8hqz_5.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'name': 'i71pjchy',
                                'defaultValue': i18n.translate('i9ec8hqz_5.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i9ec8hqz_5.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'version': 0,
                            'baseTypes': [],
                            'name': 'TeamList_i6uuomp4161',
                            'displayName': i18n.translate('i9ec8hqz_5.structure.custom.template.type.displayName')
                        },
                        'views': [{
                            'name': 'TeamListView_i6uuomp4162_dup_i70h8y0w198_dup_i7gapdih350_dup_i9ec8hpj616',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TeamListView_i6uuomp4162_dup_i70h8y0w198_dup_i7gapdih350_dup_i9ec8hpj616'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TeamListView_i6uuomp4162_dup_i70h8y0w198_dup_i7gapdih350_dup_i9ec8hpj616'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9ec8hpj'
                        }, {
                            'name': 'TeamListView_i6uuomp4162_dup_i70h8y0w198_dup_i7gapdih350_dup_i9ec8hpj616',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TeamListView_i6uuomp4162_dup_i70h8y0w198_dup_i7gapdih350_dup_i9ec8hpj616'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TeamListView_i6uuomp4162_dup_i70h8y0w198_dup_i7gapdih350_dup_i9ec8hpj616'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9ec8hpj',
                            'format': 'Mobile'
                        }, {
                            'name': 'TeamListView_i6uuomp4162_dup_i70h8y0w198_dup_i7gapdih350_dup_i9ec8hpj616',
                            'forType': 'TeamList_i6uuomp4161',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 240,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'hidden': false,
                                                'height': 190,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 11,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center'
                                            }
                                        }, {
                                            'id': 'fld_i71pn0p187',
                                            'data': 'i6uv4iab',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': '220',
                                                'items': [{
                                                    'id': 'fld_i71pn0p187_proxy',
                                                    'data': 'i6uv4iab',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 11,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }, {
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'items': [{
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': false,
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_hfwpt6aj',
                                                            'data': 'Strng_sTxt0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_hfwpt6aj_proxy',
                                                                    'data': 'Strng_sTxt0',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': false,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_i98d2evx240',
                                                            'data': 'i98d2eu0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i98d2evx240_proxy',
                                                                    'data': 'i98d2eu0',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'color': 'color_14',
                                                                        'format': 'mmmm dd, yyyy'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_i6uv50lp96',
                                                            'data': 'wxRchTxt_sTxt4',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i6uv50lp96_proxy',
                                                                    'data': 'wxRchTxt_sTxt4',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'experiment': 'NewListFields',
                                                            'id': 'fld_i98cnr25195',
                                                            'data': 'i98cnr17',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i98cnr25195_proxy',
                                                                    'data': 'i98cnr17',
                                                                    'comp': {'name': 'Label', 'style': 'Body L'},
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 200}
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'def_3_0',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i71pjcjj73',
                                            'data': 'i71pjchy',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i71pjcjj73_proxy',
                                                    'data': 'i71pjchy',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 15,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': 44,
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'originalWidth': 280, 'wasChanged': true},
                            'format': 'Mobile'
                        }, {
                            'name': 'TeamListView_i6uuomp4162_dup_i70h8y0w198_dup_i7gapdih350_dup_i9ec8hpj616',
                            'forType': 'TeamList_i6uuomp4161',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 190,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'hidden': false,
                                                'height': 190,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 11,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center'
                                            }
                                        }, {
                                            'id': 'fld_i71pn0p187',
                                            'data': 'i6uv4iab',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 330,
                                                'height': '220',
                                                'items': [{
                                                    'id': 'fld_i71pn0p187_proxy',
                                                    'data': 'i6uv4iab',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 11,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 10
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'items': [{
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'italic': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': false,
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_hfwpt6aj',
                                                    'data': 'Strng_sTxt0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hfwpt6aj_proxy',
                                                            'data': 'Strng_sTxt0',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': false,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'experiment': 'NewListFields',
                                                    'id': 'fld_i98d2evx240',
                                                    'data': 'i98d2eu0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i98d2evx240_proxy',
                                                            'data': 'i98d2eu0',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mmmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true
                                                    }
                                                }, {
                                                    'id': 'fld_i6uv50lp96',
                                                    'data': 'wxRchTxt_sTxt4',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i6uv50lp96_proxy',
                                                            'data': 'wxRchTxt_sTxt4',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': false,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'id': 'fld_i98cnr25195',
                                                    'data': 'i98cnr17',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i98cnr25195_proxy',
                                                            'data': 'i98cnr17',
                                                            'comp': {'name': 'Label', 'style': 'Body L'},
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': true,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    },
                                                    'experiment': 'NewListFields'
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 200}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 10,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i71pjcjj73',
                                            'data': 'i71pjchy',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 160,
                                                'items': [{
                                                    'id': 'fld_i71pjcjj73_proxy',
                                                    'data': 'i71pjchy',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 15,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': '40',
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }],
                        'items': [{
                            'title': i18n.translate('i9ec8hqz_5.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_5a6f018840b44388b91b4b5706a5e4e6.jpg',
                                'width': 949,
                                'height': 989,
                                'alt': ''
                            },
                            'Strng_sTxt0': i18n.translate('i9ec8hqz_5.structure.custom.template.items.0.Strng_sTxt0'),
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ec8hqz_5.structure.custom.template.items.0.wxRchTxt_sTxt4')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i71pjchy': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6uv4iab': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i71pjchy': i18n.translate('i9ec8hqz_5.structure.custom.template.items.0.i71pjchy'),
                            'i98cnr17': i18n.translate('i9ec8hqz_5.structure.custom.template.items.0.i98cnr17'),
                            'i98d2eu0': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'}
                        }, {
                            'title': i18n.translate('i9ec8hqz_5.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_58e182dbf80f4125bc5873921b74753c.jpg',
                                'width': 949,
                                'height': 989,
                                'alt': ''
                            },
                            'Strng_sTxt0': i18n.translate('i9ec8hqz_5.structure.custom.template.items.1.Strng_sTxt0'),
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ec8hqz_5.structure.custom.template.items.1.wxRchTxt_sTxt4')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i71pjchy': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6uv4iab': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i71pjchy': i18n.translate('i9ec8hqz_5.structure.custom.template.items.1.i71pjchy'),
                            'i98cnr17': i18n.translate('i9ec8hqz_5.structure.custom.template.items.1.i98cnr17'),
                            'i98d2eu0': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'}
                        }, {
                            'title': i18n.translate('i9ec8hqz_5.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_40f316547dc047898fe868ecef9bf054.jpg',
                                'width': 876,
                                'height': 887,
                                'alt': ''
                            },
                            'Strng_sTxt0': i18n.translate('i9ec8hqz_5.structure.custom.template.items.2.Strng_sTxt0'),
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9ec8hqz_5.structure.custom.template.items.2.wxRchTxt_sTxt4')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i71pjchy': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6uv4iab': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i71pjchy': i18n.translate('i9ec8hqz_5.structure.custom.template.items.2.i71pjchy'),
                            'i98cnr17': i18n.translate('i9ec8hqz_5.structure.custom.template.items.2.i98cnr17'),
                            'i98d2eu0': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'}
                        }],
                        'displayName': i18n.translate('i9ec8hqz_5.structure.custom.template.displayName')
                    }
                },
                'id': 'i9ec8hqz_5'
            },
            'preset': {
                'rect': {'width': 162, 'height': 380, 'x': 162, 'y': 173},
                'label': 'i9ec8hqz_5.structure.custom.template.displayName',
                'tags': null
            }
        }],
        'compTypes': ['wixapps.integration.components.AppPart2']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_team'}
}
