// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import mobileWizardData from './mobileWizardData.json';
import React from 'react';
import MobileWizardSingleStep from './mobileWizardSingleStep';

const QAB_POINTER = { id: 'QUICK_ACTION_BAR', type: 'MOBILE' };

const selectors = stateManagement.mobile.selectors.mobileWizard;
const actions = stateManagement.mobile.actions.mobileWizard;

//TYPE WAS GENERATED, remove this line when reviewed
interface MobileWizardSelectQabProps {
  availablePresets: Array<AnyFixMe>;
  selectedQab?: string;
  setQabPreset: FunctionFixMe;
  init?: FunctionFixMe;
  destroy?: FunctionFixMe;
}

class MobileWizardSelectQab extends React.Component<MobileWizardSelectQabProps> {
  static displayName = 'mobileWizardSelectQab';

  static propTypes = {
    availablePresets: PropTypes.array.isRequired,
    selectedQab: PropTypes.string,
    setQabPreset: PropTypes.func.isRequired,
    init: PropTypes.func,
    destroy: PropTypes.func,
  };

  componentDidMount() {
    this.props.init();
  }

  componentWillUnmount() {
    this.props.destroy();
  }

  getStepOptions = () => {
    const mobileWizardQabData = mobileWizardData[QAB_POINTER.id];
    const availablePresets = _.sortBy(
      this.props.availablePresets,
      (preset) => mobileWizardQabData[preset.id].order,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(availablePresets, (preset) => {
      const { label, imageSrc, automationId } =
        mobileWizardData[QAB_POINTER.id]?.[preset.id];

      return {
        value: preset,
        isSelected: this.props.selectedQab === preset.id,
        label,
        imageSrc,
        automationId,
      };
    });
  };

  render() {
    return (
      <MobileWizardSingleStep
        headerLabel="Mobile_Wizard_Welcome_QAB_Title"
        actionToTakeLabel="Mobile_Wizard_Welcome_QAB_Line"
        automationId="mobile-wizard-qab-step"
        options={this.getStepOptions()}
        onOptionSelected={(option) => this.props.setQabPreset(option.value)}
      />
    );
  }
}

const stateToProps = ({ state }) => ({
  availablePresets: selectors.qab.getAvailablePresets(state),
  selectedQab:
    selectors.qab.getSelectedQab(state) || selectors.getLastSaved(state, 'qab'),
});

const dispatchToProps = (dispatch) => ({
  init() {
    dispatch(actions.initQabStep());
  },
  destroy() {
    dispatch(actions.setPreviewState(QAB_POINTER.id, undefined));
  },
  setQabPreset(preset) {
    // 'designPanelUtils' is injected here, because it cannot be required inside 'mobileWizardactions'
    // because of circular dependency caused by 'core' -> 'stateManagement'
    // after removing 'core' -> 'stateManagement', remove 'designPanelUtils' from here to 'mobileWizardactions'
    dispatch(actions.setSelectedQabPreset(preset));
  },
});

const connected = util.hoc.connect(
  util.hoc.STORES.STATE_ONLY,
  stateToProps,
  dispatchToProps,
)(util.hoc.renderWhenMutated(MobileWizardSelectQab));
connected.pure = MobileWizardSelectQab;
export default connected;
