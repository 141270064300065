// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as core from '@/core';
import * as util from '@/util';
import React from 'react';
import { FocusPanelFrame } from '../frames';
import * as helpIds from '@/helpIds';
import { translate } from '@/i18n';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'spotifySearch',

  mixins: [core.mixins.editorAPIMixin, util.postMessageMixin],
  render() {
    return (
      <FocusPanelFrame
        ref="focusPanelFrame"
        title={translate('Spotify_Player_Settings_Search')}
        panelName={this.props.panelName}
        selectedComponentType={this.props.selectedComponentType}
        helpId={helpIds.EXTERNAL.SEARCH_SPOTIFY}
        className="spotify-search-panel"
      >
        <iframe src={this.props.iframeSrc} className="content-iframe" />
      </FocusPanelFrame>
    );
  },

  componentDidMount() {
    this.setPostMessageHandlerNamed(
      'spotifySearch',
      this.props.id,
      this.onMessageFromDialog,
    );
  },
  onMessageFromDialog(messageData) {
    if (_.isFunction(this.props.callback)) {
      this.props.callback(messageData);
    }

    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },
});
