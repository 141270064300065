import _ from 'lodash';

import * as helpIds from '@/helpIds';
import constants from '@/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';
import columnsGfppUtils from '../../utils/columnsGfppUtils';
import { animations as animationsUtils } from '@/util';
import { designData } from '@/util';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { GFPPActionType } from '@wix/editor-component-types';

const COLUMNS_BG_PANEL_NAME =
  'compPanels.panels.StripColumnsContainer.backgroundPanel';
const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const hasVideoBackground = (editorAPI: EditorAPI, column: CompRef) => {
  const designData = editorAPI.components.design.get(column);
  return designData?.background?.mediaRef?.type === constants.MEDIA_TYPES.VIDEO;
};
const hasPadding = (editorAPI: EditorAPI, column: CompRef) => {
  const properties = editorAPI.components.properties.get(column) as {
    mediaBackgroundPadding?: Record<string, number>;
  };
  const mediaBackgroundPaddingProp = properties?.mediaBackgroundPadding;
  return (
    mediaBackgroundPaddingProp &&
    Object.values(mediaBackgroundPaddingProp)[0] > 0
  );
};

const hasChildrenWithBackgroundVideoAndPadding = (
  editorAPI: EditorAPI,
  columnsContainer: CompRef,
) =>
  _(columnsContainer)
    .thru(editorAPI.components.getChildren)
    .filter((column) => hasVideoBackground(editorAPI, column))
    .filter((column) => hasPadding(editorAPI, column))
    .value().length > 0;

const shouldShowMobileBackgroundSettingsAction = (
  editorAPI: EditorAPI,
  columnsContainer: CompRef,
) =>
  columnsGfppUtils.hasMargins(editorAPI, columnsContainer) ||
  columnsGfppUtils.hasChildrenWithOpacity(editorAPI, columnsContainer) ||
  hasChildrenWithBackgroundVideoAndPadding(editorAPI, columnsContainer);

function getChangeBackgroundActionIfApplicable(
  editorAPI: EditorAPI,
  columnsContainerPtr: CompRef,
) {
  const isSingleColumn =
    editorAPI.columns.isSingleColumnStrip(columnsContainerPtr);
  const stripColumns = editorAPI.components.getChildren(columnsContainerPtr);
  const componentForBgPanel =
    isSingleColumn &&
    !designData.hasDividersDesign(editorAPI, columnsContainerPtr)
      ? stripColumns
      : columnsContainerPtr;
  const isBackgroundVisible = columnsGfppUtils.shouldShowChangeBackgroundAction(
    editorAPI,
    columnsContainerPtr,
    isSingleColumn,
  );

  const { label, icon, tooltip, backgroundMediaType } =
    gfppDataUtils.getDataGfppWithBackgroundIcon(
      editorAPI,
      Array.isArray(componentForBgPanel)
        ? componentForBgPanel[0]
        : componentForBgPanel,
      {
        label: 'gfpp_mainaction_stripContainer',
        tooltip: !isBackgroundVisible
          ? 'gfpp_tooltip_mainaction_stripContainer_bg_scroll_effects_unsupported'
          : '',
        isDisabled: !isBackgroundVisible,
      },
    );

  return {
    label,
    isSelected: gfppDataUtils.getPanelStateFn(COLUMNS_BG_PANEL_NAME),
    onClick: gfppDataUtils.getToggleCompPanelFn(COLUMNS_BG_PANEL_NAME, {
      selectedComponent: componentForBgPanel,
      focusedCompType: editorAPI.components.getType(componentForBgPanel),
      prependNoFillPreset: !isSingleColumn,
      biParams: { bg_media_type: backgroundMediaType },
    }),
    automationId: 'gfpp-button-background',
    isDisabled: !isBackgroundVisible,
    tooltip,
    icon,
  };
}

function getMainActions(editorAPI: EditorAPI, compRef: CompRef) {
  return [
    columnsGfppUtils.getManageColumnsActionIfApplicable(editorAPI, compRef),
    editorAPI.isDesktopEditor() &&
      getChangeBackgroundActionIfApplicable(editorAPI, compRef),
  ].filter(Boolean);
}

export default {
  untranslatable: true,
  mainActions: getMainActions,
  enabledActions: (_editorAPI: EditorAPI) => [
    ACTIONS.EFFECTS,
    ACTIONS.LAYOUT,
    ACTIONS.STRETCH_FOR_COLUMNS,
    ACTIONS.HELP,
    ACTIONS.DIVIDERS,
  ],
  mobileMainActions: getMainActions,
  mobileEnabledActions(editorAPI: EditorAPI, compRef: CompRef) {
    const isSingleColumn = editorAPI.columns.isSingleColumnStrip(compRef);
    const shouldShowMobileBackgroundSettings =
      isSingleColumn ||
      shouldShowMobileBackgroundSettingsAction(editorAPI, compRef);
    const shouldDisplayDividers = designData.hasDividersDesign(
      editorAPI,
      compRef,
    );
    const oldAnimationsActions = [
      isSingleColumn && ACTIONS.MOTION,
      !isSingleColumn && ACTIONS.LAYOUT,
      ACTIONS.HIDE,
      ACTIONS.HELP,
      shouldShowMobileBackgroundSettings && ACTIONS.MOBILE_BACKGROUND_SETTINGS,
    ] as GFPPActionType[];

    const newAnimationsActions = [
      isSingleColumn && ACTIONS.ANIMATION,
      isSingleColumn && ACTIONS.EFFECTS,
      !isSingleColumn && ACTIONS.LAYOUT,
      ACTIONS.HIDE,
      ACTIONS.HELP,
      shouldShowMobileBackgroundSettings && ACTIONS.MOBILE_BACKGROUND_SETTINGS,
    ] as GFPPActionType[];

    return (
      animationsUtils.isNewAnimationsEnabled(editorAPI)
        ? newAnimationsActions
        : oldAnimationsActions
    ).concat(shouldDisplayDividers ? [ACTIONS.DIVIDERS] : []);
  },
  presetActions(editorAPI: EditorAPI, compRef: CompRef) {
    const isSingleColumn =
      editorAPI.components.getChildren(compRef).length === 1;
    const helpId = isSingleColumn
      ? helpIds.GFPP.STRIP_COLUMNS_CONTAINER_SINGLE
      : helpIds.GFPP.STRIP_COLUMNS_CONTAINER;
    const mobileHelpId = isSingleColumn
      ? helpIds.GFPP.STRIP_COLUMNS_CONTAINER_MOBILE_SINGLE
      : helpIds.GFPP.STRIP_COLUMNS_CONTAINER_MOBILE;

    return {
      help: {
        helpId,
        mobileHelpId,
      },
    };
  },
};
