'use strict'

/**
 * @param {Object} sections
 * @param {Object} commonSections
 * @returns {Object}
 */
function mergeWithCommonSections(sections, commonSections) {
    return Object.assign(commonSections, sections)
}

module.exports = mergeWithCommonSections
