import { menuItemTree, type IItem } from '@/baseUI';
import { link } from '@/util';
import * as core from '@/core';

import { createMenuApi } from '../API/menuAPI';
import { MENU_ITEM_TYPE, PAGES_MENU_ID } from '../constants';
import { cleanId } from '../utils/utils';

import type {
  PagesData,
  MenuItem as IMenuItem,
  PageLink,
} from '@wix/document-services-types';
import type { EditorAPI } from '@/editorAPI';

const {
  linkTypeValidators: { isPageLink },
} = link;
const { menuLogic } = core.utils;

/**
 * se_support_fixPagesMenu - workaround in order to fix https://jira.wixpress.com/browse/WEED-22089
 * add &debug=1&experiments=se_support_fixPagesMenu
 * run in console window.__addMissingPagesForMenu___(editorAPI,true)
 * */
export const __addMissingPagesForMenu = (
  editorAPI: EditorAPI,
  applyChanges = false,
) => {
  const menuAPI = createMenuApi(editorAPI);

  const pages = editorAPI.dsRead.pages.getPagesData();
  const menuPagesSet: Set<string> = new Set();
  const diff: Set<PagesData> = new Set();

  const pagesMenu = menuAPI.getMenu(PAGES_MENU_ID);
  const mainTree = menuItemTree.flattenTree(pagesMenu.items as IItem[]);

  mainTree.forEach((record) => {
    if (isPageLink((record.item as IMenuItem).link)) {
      menuPagesSet.add(
        cleanId(((record.item as IMenuItem).link as PageLink).pageId),
      );
    }

    return null;
  });
  const dynamicPagesIdMap = editorAPI.menus._buildDynamicPagesIdsMap();

  pages.forEach((pageData) => {
    const { id } = pageData;
    if (
      !menuPagesSet.has(id) &&
      !menuLogic.isSpecialPage(editorAPI, pageData, dynamicPagesIdMap)
    ) {
      diff.add(pageData);
    }
  });

  if (!applyChanges) {
    // eslint-disable-next-line no-console
    console.log('pages will be added to the menu:', [...diff]);
    return;
  }

  const itemsToAdd = [...diff].map((page: PagesData) => ({
    isVisible: false,
    isVisibleMobile: false,
    type: MENU_ITEM_TYPE,
    items: [],
    label: page.title,
    link: {
      type: 'PageLink',
      target: '_self',
      pageId: `#${page.id}`,
    },
  }));

  menuAPI.replaceMenuItems(PAGES_MENU_ID, [
    ...pagesMenu.items,
    ...itemsToAdd,
  ] as IMenuItem[]);
};

export default {
  __addMissingPagesForMenu,
};
