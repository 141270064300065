'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

const moreBehaviorHelper = require("./moreBehaviorHelper")
module.exports =
    {
        "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
        "hide": false,
        "title": "add_section_vectorart_animals_label",
        "subNavigationTitle": "add_section_vectorart_animals_label",
        "presetTitle": "add_section_vectorart_animals_label",
        "tooltipTitle": "add_section_vectorart_animals_label",
        "automationId": "add-panel-section-animalNatureSection",
        "subNavigationHide": false,
        "showSectionHeader": true,
        "additionalBehaviours": {
            "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
            "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
            "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
            "iconEnabledForComps": {}
        },
        "props": {
            "image": "addPanelData/sections/vectorArt/animalsSection.v1.png",
            "retinaImage": {
                "src": "addPanelData/sections/vectorArt/animalsSection-@2x.v1.png",
                "dimensions": {
                    "width": 648,
                    "height": 1938
                }
            },
            "imageHover": null,
            "items": [{
                "id": "VectorArt_Animal_&_Nature_1",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 226,
                        "height": 176,
                        "x": 11,
                        "y": 18,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "7d7896e26f194f3d8c2741a55fc526f1.svg",
                        "title": "Plant ",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vta",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73cyl"
                },
                "preset": {
                    "rect": {"width": 114, "height": 89, "x": 11, "y": 20},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "18, 35, 42, 14, 119, 19, 133, 46, 117, 86, 90, 112, 43, 113, 6, 65, 7, 37"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_2",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 132,
                        "height": 110,
                        "x": 141,
                        "y": 29,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "95b5fdfb319943ca80b44c51929a45ca.svg",
                        "title": "media-88-15",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vtg",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73cyn"
                },
                "preset": {
                    "rect": {"width": 67, "height": 56, "x": 141, "y": 30},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "144, 37, 179, 16, 217, 40, 215, 62, 188, 93, 133, 75"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_3",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 105,
                        "height": 105,
                        "x": 242,
                        "y": 30,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "252af31adaae4443bb9e074caa91c2b5.svg",
                        "title": "media-1-35",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vtr",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73d0f"
                },
                "preset": {
                    "rect": {"width": 36, "height": 36, "x": 242, "y": 30},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "237, 30, 268, 18, 292, 45, 273, 68, 243, 68, 232, 30"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_4",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 208,
                        "height": 110,
                        "x": 214,
                        "y": 68,
                        "scale": 1,
                        "rotationInDegrees": 349.9206875082524,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "2592569a5e8e41f5a0bea7a67e0dc751.svg",
                        "title": "media-26-07",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4ks0",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73d0c"
                },
                "preset": {
                    "rect": {"width": 118, "height": 82, "x": 205, "y": 58},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "205, 87, 228, 65, 244, 82, 289, 70, 309, 48, 321, 62, 310, 102, 228, 143, 214, 134"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_5",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 126,
                        "height": 127.19999999999999,
                        "x": 14,
                        "y": 99,
                        "scale": 1,
                        "rotationInDegrees": 12.961325089542015,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "fc8027afd58e4ec3a941e5b7aab14615.svg",
                        "title": "media-104-05",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vtx",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73cyj"
                },
                "preset": {
                    "rect": {"width": 136, "height": 140, "x": 0, "y": 82},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "8, 139, 22, 191, 40, 209, 77, 202, 125, 152, 139, 100, 109, 102, 87, 128, 45, 121"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_6",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 158,
                        "height": 160,
                        "x": 123,
                        "y": 113,
                        "scale": 1,
                        "rotationInDegrees": 66.7061502562986,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "0049b0d977f04428ab092175ee76524b.svg",
                        "title": "Branch",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vtt",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73cyh"
                },
                "preset": {
                    "rect": {"width": 116, "height": 118, "x": 105, "y": 94},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "153, 104, 200, 103, 214, 164, 198, 181, 188, 191, 128, 212, 121, 199, 137, 168, 136, 135"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_7",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 100,
                        "height": 182,
                        "x": 245,
                        "y": 141,
                        "scale": 1,
                        "rotationInDegrees": 327.62623120331364,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "822f6bc3a5514112bcfe1c96d82b750d.svg",
                        "title": "media-88-03",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vu6",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73d0a"
                },
                "preset": {
                    "rect": {"width": 100, "height": 118, "x": 222, "y": 128},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "223, 155, 229, 180, 273, 221, 322, 220, 320, 197, 285, 159, 237, 147"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_8",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 226.79999999999998,
                        "height": 103.2,
                        "x": 120,
                        "y": 201,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "c876ec4f79734344b5cfb15c41e30352.svg",
                        "title": "media-84-22",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vu7",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73cz9"
                },
                "preset": {
                    "rect": {"width": 191, "height": 88, "x": 120, "y": 202},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "199, 192, 220, 202, 216, 231, 250, 214, 265, 234, 253, 248, 317, 255, 307, 286, 170, 295, 112, 260, 122, 227, 178, 220, 182, 200"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_9",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 105.6,
                        "height": 145.2,
                        "x": 9,
                        "y": 212,
                        "scale": 1,
                        "rotationInDegrees": 355.2359018127419,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "c96ee0ec7d15434ca58803f734d26558.svg",
                        "title": "Grass",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vsz",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73czb"
                },
                "preset": {
                    "rect": {"width": 104, "height": 130, "x": 0, "y": 207},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "61, 219, 33, 230, 21, 261, 31, 277, 10, 302, 7, 344, 78, 330, 114, 293, 97, 261, 112, 224, 86, 205"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_10",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 184,
                        "height": 170,
                        "x": 86,
                        "y": 314,
                        "scale": 1,
                        "rotationInDegrees": 234.17227291348343,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "aea836545a824833bc3cd40e3f12d7b5.svg",
                        "title": "Leaves ",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vua",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73cyw"
                },
                "preset": {
                    "rect": {"width": 126, "height": 128, "x": 69, "y": 295},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "113, 313, 93, 332, 116, 389, 120, 425, 138, 431, 172, 382, 178, 343, 148, 304"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_11",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 158.4,
                        "height": 115.19999999999999,
                        "x": 184,
                        "y": 293,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "c9bf35fd8544476c891ccbe38a0ac18d.svg",
                        "title": "media-82-18",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vue",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73cyy"
                },
                "preset": {
                    "rect": {"width": 133, "height": 97, "x": 184, "y": 295},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "244, 316, 233, 338, 209, 302, 181, 308, 183, 363, 210, 375, 283, 397, 311, 380, 319, 317, 305, 291, 265, 291"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_12",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 176,
                        "height": 212,
                        "x": 14,
                        "y": 354,
                        "scale": 1,
                        "rotationInDegrees": 17.92670989229964,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "82dfb82344de4fdd9051232952644375.svg",
                        "title": "media-82-03",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vsu",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73cze"
                },
                "preset": {
                    "rect": {"width": 118, "height": 132, "x": 0, "y": 340},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "53, 348, 12, 377, 26, 442, 49, 467, 81, 477, 79, 440, 111, 413, 106, 374, 72, 341"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_13",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 134.4,
                        "height": 220.79999999999998,
                        "x": 178,
                        "y": 390,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "2fc6253d53dc4925aab74c224256d7f8.svg",
                        "title": "media-84-08",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vuh",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73cyu"
                },
                "preset": {
                    "rect": {"width": 114, "height": 185, "x": 178, "y": 390},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "197, 381, 164, 434, 180, 486, 168, 507, 188, 531, 225, 577, 265, 570, 299, 496, 292, 451, 309, 422, 234, 391"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_14",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 177,
                        "height": 132,
                        "x": 98,
                        "y": 442,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "f682bcb3dba1438c9f919b0aa7788a48.svg",
                        "title": "media-1-57",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vuo",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73d07"
                },
                "preset": {
                    "rect": {"width": 60, "height": 45, "x": 98, "y": 444},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "94, 440, 106, 430, 167, 469, 166, 495, 115, 499, 89, 467"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_15",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 226.79999999999998,
                        "height": 177.6,
                        "x": 10,
                        "y": 479,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "0363706dca554893a2e6d9278dc3801a.svg",
                        "title": "animals-tiger",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vus",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73cyr"
                },
                "preset": {
                    "rect": {"width": 190, "height": 150, "x": 10, "y": 481},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "24, 477, 9, 467, 3, 497, 4, 632, 89, 637, 77, 596, 124, 591, 135, 632, 170, 632, 173, 593, 217, 590, 196, 554, 155, 505, 90, 508, 64, 474"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_16",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 192,
                        "height": 172,
                        "x": 224,
                        "y": 561,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "0034aa99262b4b54ace56b390c351c89.svg",
                        "title": "Grass 2",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vsp",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73cyp"
                },
                "preset": {
                    "rect": {"width": 99, "height": 88, "x": 224, "y": 561},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "306, 579, 321, 637, 283, 658, 238, 650, 208, 625, 230, 581, 276, 570"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_17",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 66,
                        "height": 192,
                        "x": 102,
                        "y": 617,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "bfb77c3d5e95407e92f7720592a83ad4.svg",
                        "title": "media-88-13",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vv4",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73czu"
                },
                "preset": {
                    "rect": {"width": 34, "height": 98, "x": 102, "y": 617},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "94, 617, 119, 606, 141, 657, 128, 725, 110, 724, 96, 658"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_18",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 164.4,
                        "height": 226.79999999999998,
                        "x": 137,
                        "y": 631,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "a8043344ae4945c09e8c23a536b4ac92.svg",
                        "title": "Fox",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vv0",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73czq"
                },
                "preset": {
                    "rect": {"width": 139, "height": 190, "x": 137, "y": 631},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "169, 638, 155, 647, 148, 660, 129, 743, 160, 829, 227, 828, 287, 764, 277, 746, 216, 681, 225, 645, 187, 619"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_19",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 130,
                        "height": 96,
                        "x": 16,
                        "y": 647,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "8a61fdae8e9c4cdf9233a8f77f026ef8.svg",
                        "title": "media-1-41",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vv8",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73czx"
                },
                "preset": {
                    "rect": {"width": 67, "height": 50, "x": 16, "y": 648},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "14, 648, 13, 679, 33, 702, 75, 687, 86, 645, 29, 639"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_20",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 72,
                        "height": 176,
                        "x": 259,
                        "y": 671,
                        "scale": 1,
                        "rotationInDegrees": 330.5607011791876,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "9964bcf8392b491cb496f2b58955edf4.svg",
                        "title": "animals-plant",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vuw",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73czl"
                },
                "preset": {
                    "rect": {"width": 84, "height": 98, "x": 238, "y": 666},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "251, 669, 238, 690, 277, 740, 311, 759, 321, 742, 289, 673, 262, 661"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_21",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 121.19999999999999,
                        "height": 171.6,
                        "x": 6,
                        "y": 702,
                        "scale": 1,
                        "rotationInDegrees": 356.14664948484346,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "b5b48190cbd548b483c31d0309035476.svg",
                        "title": "media-84-18",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vvc",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73t23"
                },
                "preset": {
                    "rect": {"width": 114, "height": 154, "x": 0, "y": 697},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "20, 721, 10, 765, 32, 796, 8, 793, 7, 859, 34, 861, 119, 809, 106, 770, 115, 734, 67, 697, 28, 708"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_22",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 144,
                        "height": 122.39999999999999,
                        "x": 81,
                        "y": 838,
                        "scale": 1,
                        "rotationInDegrees": 340.4700503683182,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "a0dd2177e03348f8aec833eefc8854ad.svg",
                        "title": "media-88-16",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vvf",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73czo"
                },
                "preset": {
                    "rect": {"width": 148, "height": 144, "x": 68, "y": 820},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "123, 832, 67, 868, 78, 909, 135, 959, 154, 953, 195, 896, 203, 865, 192, 853"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_23",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 130.79999999999998,
                        "height": 138,
                        "x": 204,
                        "y": 832,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "d241edc2a0ff44e9802672d6d7767d43.svg",
                        "title": "media-84-28",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vsd",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a73d04"
                },
                "preset": {
                    "rect": {"width": 110, "height": 116, "x": 204, "y": 832},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "244, 822, 210, 857, 195, 927, 256, 958, 319, 932, 320, 863, 286, 824"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }, {
                "id": "VectorArt_Animal_&_Nature_24",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 126,
                        "height": 134,
                        "x": 13,
                        "y": 875,
                        "scale": 1,
                        "rotationInDegrees": 4.876633916280753,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "e825ef7a999e4db8919d8c6fa7e60274.svg",
                        "title": "media-1-61",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vsg",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8bs399w"
                },
                "preset": {
                    "rect": {"width": 72, "height": 78, "x": 10, "y": 870},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "49, 865, 24, 871, 2, 912, 17, 932, 66, 959, 97, 948, 86, 931, 59, 900"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"}
            }],
            "compTypes": ["wysiwyg.viewer.components.VectorImage"]
        },
        "sectionFooter": {
            "label": "add_section_vectorart_animals_more_tooltip_title",
            "action": moreBehaviorHelper,
            "category": "VECTOR_ART",
            "openSource": "add_panel_vector_art_animals",
            "path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"
        },
        "help": {"hide": false, "text": "add_section_vectorart_animals_tooltip_description"}
    }
