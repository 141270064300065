import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { ParentsNegativeScaleAndSkewWrapper } from './parentsNegativeScaleAndSkewWrapper';

interface DragHandleProps {
  negativeScaleAndSkewString?: string;
  parentsNegativeScaleAndSkewString?: string[];
  registerDragMouseMoveAction: (
    event: React.MouseEvent,
    isDraggingWithHandle?: boolean,
  ) => void;
}

export const DragHandle = ({
  negativeScaleAndSkewString = '',
  parentsNegativeScaleAndSkewString = [],
  registerDragMouseMoveAction,
}: DragHandleProps) => {
  const cssPosition: React.CSSProperties = {
    position: 'absolute',
  };

  return (
    <ParentsNegativeScaleAndSkewWrapper
      parentsNegativeScaleAndSkewString={parentsNegativeScaleAndSkewString}
      cssPosition={cssPosition}
    >
      <div
        className="drag-handle"
        style={{
          ...cssPosition,
          transform: `${negativeScaleAndSkewString}`,
        }}
        onMouseDown={(e) => {
          registerDragMouseMoveAction(e, true);
        }}
      >
        <symbols.symbol name="interactionDragHandle" />
      </div>
    </ParentsNegativeScaleAndSkewWrapper>
  );
};
