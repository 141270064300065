// @ts-nocheck
import _ from 'lodash';

const TINY_MENU_MEMBERS_SKIN =
  'wysiwyg.viewer.skins.mobile.TinyMenuMemberSectionSkin';

export default {
  pinnable: false,
  showLegacyFixedPosition: true,
  skinParamsToIgnore(editorAPI, compRef) {
    const hasLoginSocialBar = !_.chain(compRef)
      .thru(editorAPI.components.data.get)
      .get('loginSocialBarRef')
      .isEmpty()
      .value();

    return hasLoginSocialBar
      ? []
      : // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/keys
        _.keys(editorAPI.theme.skins.getSkinDefinition(TINY_MENU_MEMBERS_SKIN));
  },
  overrideProportionalResize: true,
};
