// @ts-nocheck
import _ from 'lodash';
import sectionTypesMap from './sectionTypesMap';

const isNonEmptyString = (val) => _.isString(val) && !_.isEmpty(val);

const isValidSectionType = (sectionType) => _.has(sectionTypesMap, sectionType);

const isNotEmptyArray = (items) => Array.isArray(items) && items.length > 0;

const validateCategoryDefinition = ({ title, header, type, items } = {}) =>
  isNonEmptyString(title) &&
  isNonEmptyString(header) &&
  isValidSectionType(type) &&
  isNotEmptyArray(items);

export { validateCategoryDefinition as validate };
