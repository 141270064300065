._container_156g8_1 {
  --indicator-height: 22px;
  --horizontal-position: calc(
    (var(--indicator-width) + var(--indicator-margin)) * -1
  );
  position: absolute;
  top: calc(var(--component-height) / 2 - var(--indicator-height) / 2);
  right: var(--horizontal-position);
  width: var(--indicator-width);
  height: var(--indicator-height);
  display: flex;
  box-sizing: border-box;
  padding: 4px, 6px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #116dff; }
  ._container_156g8_1._outside_156g8_18 {
    right: var(--indicator-margin); }
  ._container_156g8_1._left_156g8_20 {
    right: auto;
    left: var(--horizontal-position); }
    ._container_156g8_1._left_156g8_20._outside_156g8_18 {
      left: var(--indicator-margin); }
  ._container_156g8_1 ._text_156g8_25 {
    color: #fff; }
