import type { Scope } from './scope';

export const createDeferred = (_scope: Scope) => {
  const state: {
    resolve: Function;
    promise: Promise<void>;
  } = {
    resolve: null,
    promise: null,
  };

  return {
    getPromise: () => state.promise,
    createPromise: () => {
      const promise = new Promise<void>((resolve) => {
        state.resolve = resolve;
      });
      state.promise = promise;

      return promise;
    },

    resolve: () => {
      if (typeof state.resolve === 'function') {
        state.resolve();
        state.resolve = null;
        state.promise = null;
      }
    },
  };
};
