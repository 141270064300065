import constants from '@/constants';
import { bi, platform } from '@/stateManagement';
import { services } from '@/tpa';
import { WorkspaceModesApiKey } from '@/apis';
import { WorkspaceModes } from '@/workspaceModes';
import type { AppData } from 'types/documentServices';
import { appMarketUtils } from '@/tpa';

import { APP_MANAGER_APPS_LIST } from '../constants';
import {
  getAppHelpId as getApplicationId,
  isDescriptorExists,
} from '../services/platform/platform';
import {
  loadSegmentApps,
  transformSegmentApps,
} from '../services/dealer/dealer.segmentApps';
import {
  loadDealerAppsData,
  transformDealerAppsData,
} from '../services/dealer/dealer.manageApp';

import type { StateMapperArgs, ThunkAction } from 'types/redux';
import type { BiEventFields } from 'types/bi';
import type { EditorAPI } from '@/editorAPI';
import { getAllAvailableToUpdateApps } from '@/privateAppsPanel';

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapStateToProps = ({
  editorAPI,
  dsRead,
  state,
}: StateMapperArgs) => {
  const installedBlocksApps = dsRead.platform
    .getInstalledEditorApps()
    .reduce((acc, app) => {
      if (
        appMarketUtils.isBuilderType(app as AppData) &&
        !APP_MANAGER_APPS_LIST.includes(app.appDefinitionId)
      ) {
        acc.push(app.appDefinitionId);
      }
      return acc;
    }, []);
  const supportedApps = APP_MANAGER_APPS_LIST.concat(installedBlocksApps);
  const availableAppsToUpdate = getAllAvailableToUpdateApps(editorAPI).reduce(
    (acc, { appDefinitionId }) => {
      if (installedBlocksApps.includes(appDefinitionId)) {
        acc.push(appDefinitionId);
      }
      return acc;
    },
    [],
  );
  return {
    metaSiteInstance: dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    ).instance,
    // need to trigger rerender when manifests count is changed
    loadedManifestsCount: platform.selectors.getLoadedAppManifestsCount(state),
    supportedApps,
    availableAppsToUpdate,
    hasInstalledApps: dsRead.platform
      .getInstalledEditorApps()
      .some((app) => APP_MANAGER_APPS_LIST.includes(app.appDefinitionId)),
    isLiteMode: editorAPI.host
      .getAPI(WorkspaceModesApiKey)
      .isMode(WorkspaceModes.LITE),
  };
};

export const mapDispatchToProps = (dispatch: FunctionFixMe) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const pendingAppDefIds =
    services.pendingAppsService.getAllPendingAppDefIds(editorAPI);
  const installedEditorAppsIds = editorAPI.dsRead.platform
    .getInstalledEditorApps()
    .map((app) => app.appDefinitionId);

  const checkIsDescriptorExists = (appDefId: string) =>
    isDescriptorExists(editorAPI, appDefId);
  const getAppHelpId = (appDefId: string) =>
    getApplicationId(editorAPI, appDefId);
  const sendBi = (evid: number, biParams: BiEventFields) =>
    dispatch(bi.actions.event({ evid }, biParams));
  const isPendingApp = (appDefId: string) =>
    !!pendingAppDefIds.includes(appDefId);
  const isAppInstalled = (appDefId: string) =>
    installedEditorAppsIds.includes(appDefId);
  const loadAndTransformSegmentApps = async (metaSiteInstance: string) => {
    const apps = await loadSegmentApps(metaSiteInstance);

    return transformSegmentApps(editorAPI, apps);
  };
  const loadAndTransformAppPageOverrides = async (metaSiteInstance: string) => {
    const assets = await loadDealerAppsData(metaSiteInstance);
    return transformDealerAppsData(editorAPI, assets);
  };

  return {
    checkIsDescriptorExists,
    getAppHelpId,
    sendBi,
    isAppInstalled,
    isPendingApp,
    loadAndTransformSegmentApps,
    loadAndTransformAppPageOverrides,
  };
};
