// @ts-nocheck
import experiment from 'experiment';

const isEcomAddAppExperimentOn = function () {
  return experiment.isOpen('se_ecomAddApp');
};

const isNotifyControllerGFPPDoubleClickDisabled = function () {
  return experiment.isOpen('se_disableNotifyControllerGFPPDoubleClick');
};

const isStorePagesUsability1bExperimentOn = function () {
  return experiment.isOpen('StorePagesUsability1b');
};

const isShouldShowPhotoAlbumsAboutExperimentOn = function () {
  return experiment.isOpen('se_shouldShowPhotoAlbumsAbout');
};

const isWixFaqGfppExperimentOn = function () {
  return (
    experiment.isOpen('spec.wix-labs-faq.EnableNewVersion') ||
    experiment.isOpen('specs.wixlabs.FAQMigrationNewUsers')
  );
};

const isWixFaqGfppExperimentOff = function () {
  return !isWixFaqGfppExperimentOn();
};

export {
  isEcomAddAppExperimentOn,
  isNotifyControllerGFPPDoubleClickDisabled,
  isStorePagesUsability1bExperimentOn,
  isShouldShowPhotoAlbumsAboutExperimentOn,
  isWixFaqGfppExperimentOn,
  isWixFaqGfppExperimentOff,
};
