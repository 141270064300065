import { createThemedAction } from '../utils/themedAction';
import type { ThemedActionCreationData } from '../utils/textAction';

const letterSpacingActionCreationData: Readonly<
  ThemedActionCreationData<string>
> = {
  execCommand: (textManager, letterSpacing) => {
    if (letterSpacing === undefined) {
      textManager.commands.letterSpacing('', { execWithoutFocus: true });
    } else {
      textManager.commands.letterSpacing(letterSpacing);
    }
  },
  getWixRichTextCommandState: (textManager) => {
    const letterSpacing = textManager.getCommandsStates().letterSpacing;
    if (letterSpacing !== '') {
      return letterSpacing;
    }
  },
  execRevertCommand: (textManager) =>
    textManager.commands.letterSpacing('', { execWithoutFocus: true }),
  createUpdatedStyleProperties: (letterSpacing) => ({ letterSpacing }),
  getPropertyValue: (styleProperties) => styleProperties.letterSpacing,
  getThemeValue: (theme) => theme?.letterSpacing ?? '0em',
};

export const letterSpacingAction = createThemedAction(
  letterSpacingActionCreationData,
);
