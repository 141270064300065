import type { CompStructure } from 'types/documentServices';

export default {
  componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
  layout: {
    x: 0,
    y: 47.33333333333334,
    fixedPosition: false,
    width: 980,
    height: 100,
    scale: 1,
    rotationInDegrees: 0,
  },
  type: 'Container',
  components: [
    {
      componentType: 'wysiwyg.viewer.components.Column',
      layout: {
        x: 0,
        y: 0,
        fixedPosition: false,
        width: 980,
        height: 100,
        scale: 1,
        rotationInDegrees: 0,
      },
      type: 'Container',
      components: [
        {
          componentType: 'wysiwyg.viewer.components.WRichText',
          layout: {
            x: 334.99999999999994,
            y: 17.50000000000003,
            fixedPosition: false,
            width: 310,
            height: 65,
            scale: 1,
            rotationInDegrees: 0,
          },
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          metaData: {
            pageId: 'c1dmp',
            sig: '0wi-312',
            // @ts-expect-error
            basedOnSignature: '0wi-293',
          },
          parent: 'comp-kq4zfl7c',
          data: {
            linkList: [],
            text: `<h1 class=\"font_0 divider\" style=\"font-size: 36px; text-align: center;\"><span style=\"font-size:30px;\"><span style=\"color:#000000\"><span style=\"font-family:oswald-medium,oswald,sans-serif\">DIVIDER</span></span></span></h1>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'c1dmp',
              sig: '0wi-207',
              basedOnSignature: '0wi-206',
            },
          },
          props: {
            type: 'WRichTextProperties',
            packed: true,
            brightness: 1,
            verticalText: false,
            metaData: {
              schemaVersion: '1.0',
              autoGenerated: false,
              pageId: 'c1dmp',
              sig: '0wi-197',
            },
          },
          style: 'txtNew',
        },
      ],
      metaData: {
        pageId: 'c1dmp',
        sig: '0wi-335',
        basedOnSignature: '0wi-333',
      },
      parent: 'comp-kq4zfl54',
      props: {
        type: 'ColumnProperties',
        alignment: 50,
        metaData: {
          schemaVersion: '1.0',
          pageId: 'c1dmp',
          sig: '0wi-26',
        },
      },
      mobileHints: {
        type: 'MobileHints',
        recommendedWidth: 320,
        recommendedScale: 1,
        author: 'studio',
        offsetX: 0,
        offsetY: 0,
        offsetOrigin: 'leftTop',
        orderIndex: 0,
        props: {
          type: 'ColumnProperties',
          alignment: 50,
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c1dmp',
          sig: '0wi-191',
          basedOnSignature: '0wi-32',
        },
      },
      design: {
        type: 'MediaContainerDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c1dmp',
          sig: '0wi-189',
          basedOnSignature: '0wi-187',
        },
        background: {
          fittingType: 'fill',
          alignType: 'center',
          colorOverlay: '',
          colorOverlayOpacity: 0,
          color: '#BADA55',
          colorOpacity: 1,
          type: 'BackgroundMedia',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c1dmp',
            sig: '0wi-190',
            basedOnSignature: '0wi-188',
          },
          scrollType: 'none',
          showOverlayForMediaType: 'WixVideo',
        },
        dataChangeBehaviors: [],
        charas: 'design-kq4zgs3o',
      },
      style: 'mc1',
    },
  ],
  metaData: {
    pageId: 'c1dmp',
    sig: '0wi-339',
    basedOnSignature: '0wi-336',
  },
  parent: 'c1dmp',
  props: {
    frameMargin: 0,
    fullWidth: true,
    columnsMargin: 0,
    siteMargin: 0,
    rowMargin: 0,
    type: 'StripColumnsContainerProperties',
    metaData: {
      schemaVersion: '1.0',
      pageId: 'c1dmp',
      sig: '0wi-20',
    },
  },
  mobileHints: {
    type: 'MobileHints',
    recommendedWidth: 320,
    recommendedScale: 1,
    author: 'studio',
    props: {
      frameMargin: 0,
      fullWidth: true,
      columnsMargin: 0,
      siteMargin: 0,
      rowMargin: 0,
      type: 'StripColumnsContainerProperties',
    },
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'c1dmp',
      sig: '0wi-192',
      basedOnSignature: '0wi-34',
    },
  },
  design: {
    type: 'MediaContainerDesignData',
    background: {
      fittingType: 'fill',
      alignType: 'center',
      colorOverlay: '',
      colorOverlayOpacity: 1,
      color: '#FFFFFF',
      colorOpacity: 0,
      type: 'BackgroundMedia',
      imageOverlay: '',
      scrollType: 'none',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'c1dmp',
        sig: '0wi-22',
      },
    },
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'c1dmp',
      sig: '0wi-21',
    },
  },
  style: 'strc1',
  activeModes: {},
} as CompStructure;
