import _ from 'lodash';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { EditorAPIKey, SectionizerApiKey } from '@/apis';
import * as util from '@/util';
import saveManager, {
  type SaveInBackgroundOptions,
  type SaveResult,
} from './saveManager';
import { SavePublishApiKey } from '../publicApiKey';
import type { Logger } from 'types/core';
import type { Shell } from '@/apilib';
import type { SaveInteractionStartedSource } from 'types/fedops/saveInteraction';

type SaveCallback = (result?: SaveResult) => void;
export interface SaveOptions {
  origin?: string;
  isPublish?: boolean;
  overrideTitle?: string;
  overrideSubtitle?: string;
  callback?: SaveCallback;
  onError?: (error: unknown) => void;
  preventSiteSavedPanel?: boolean;
  overrideFreeDomainRadioButtonLabel?: string;
  overrideFreeDomainPrefix?: string;
  shouldConnectDomain?: boolean;
  sourceOfStart?: SaveInteractionStartedSource;
}

export function createSaveManagerApi(shell: Shell, log: Logger) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const savePublishApi = shell.getAPI(SavePublishApiKey);
  const sectionizerApi = shell.getAPI(SectionizerApiKey);
  const saveAPI = saveManager(editorAPI, sectionizerApi, log);

  function save(options?: SaveOptions) {
    options = options || {};
    options.origin = options.origin || '';
    const callback: SaveCallback = options.callback || _.noop;
    const onError = options.onError || _.noop;

    saveAPI
      .save(options)
      .then((result) => {
        // TODO: resolve void vs undefined issue and cleanup `|| undefined`
        callback(result || undefined);
      })
      .catch(function (e) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/values
        const error = _.isError(e) ? e : _.values(e)[0];
        if (_.isError(error)) {
          ErrorReporter.captureException(error, {
            tags: { user_save: true },
          }); // TODO: Refactor error throwing in santa
        }
        onError(e);
      });
  }

  function saveInBackground(
    onSuccess: (argument?: AnyFixMe) => void | undefined,
    onError: (error: Error) => void,
    origin?: string,
    options?: SaveInBackgroundOptions,
  ) {
    options = options || {};

    if (!savePublishApi.canSaveOrPublish()) {
      //onError({ error: 'Site is being saved or published!' });
      savePublishApi.setWaitForSaveDoneCallback(async (saveSuccess, e) => {
        if (options.shouldConnectDomainAfterSilentFirstSave) {
          const siteState = editorAPI.savePublish.getSiteState();
          const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
          await saveAPI.openChooseDomainPanel(
            options,
            siteState.isFirstSave,
            isDraftMode,
            true,
          );
        }
        if (saveSuccess) {
          onSuccess({});
          return;
        }
        onError(e);
      });
      return;
    }

    if (!options.isPublish) {
      ErrorReporter.breadcrumb('application triggered save', {
        origin,
        options,
      });
      util.fedopsLogger.interactionStarted('bg_save', {
        paramsOverrides: {
          sourceOfStart: options.sourceOfStart,
        },
        customParams: {
          origin: options.origin,
        },
      });
      options.isSilent = true;
    }

    saveAPI
      .saveInBackground(origin, options)
      .then(function () {
        onSuccess({});
        if (!options.isPublish) {
          util.fedopsLogger.interactionEnded('bg_save', {
            paramsOverrides: {
              sourceOfStart: options.sourceOfStart,
              sourceOfEnd: 'saveManager',
            },
            customParams: {
              origin: options.origin,
            },
          });
        }
      })
      .catch(function (e) {
        const tags = options.isPublish
          ? { publish_save: true }
          : { background_save: true };
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/values
        const error = _.isError(e) ? e : _.values(e)[0];
        if (_.isError(error)) {
          ErrorReporter.captureException(error, {
            tags,
          });
        }
        onError(e);
      });
  }

  return {
    save,
    saveInBackground,
    saveAsTemplate: saveAPI.saveAsTemplate,
    setTimerForSaveReminder: _.once(saveAPI.setTimerForSaveReminder),
    displayFailurePanelMatchingAnErrorType:
      saveAPI.displayFailurePanelMatchingAnErrorType,
    openSaveReminderPanel: saveAPI.openSaveReminderPanel,
    getSavingStatus: saveAPI.getSavingStatus,
    handleSaveError: saveAPI.handleSaveError,
    openChooseDomainPanel: saveAPI.openChooseDomainPanel,
  };
}
