@charset "UTF-8";
:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._button_1xtmk_8.add-panel {
  box-shadow: 0 2px 7px rgba(23, 25, 28, 0.17); }
  ._button_1xtmk_8.add-panel:hover, ._button_1xtmk_8.add-panel:active, ._button_1xtmk_8.add-panel._hovered_1xtmk_10, ._button_1xtmk_8.add-panel._selected_1xtmk_10, ._button_1xtmk_8.add-panel._highlighted_1xtmk_10, ._button_1xtmk_8.add-panel.tour-maker-highlight-element {
    background-color: #fff; }
    ._button_1xtmk_8.add-panel:hover svg, ._button_1xtmk_8.add-panel:active svg, ._button_1xtmk_8.add-panel._hovered_1xtmk_10 svg, ._button_1xtmk_8.add-panel._selected_1xtmk_10 svg, ._button_1xtmk_8.add-panel._highlighted_1xtmk_10 svg, ._button_1xtmk_8.add-panel.tour-maker-highlight-element svg {
      color: #116dff; }
  ._button_1xtmk_8.add-panel.add-panel-sections {
    box-shadow: none; }
    ._button_1xtmk_8.add-panel.add-panel-sections .symbol-leftBarButtonAddSections .add-plus {
      transform-origin: center;
      opacity: 1;
      color: #fff; }
  .sections-experience ._button_1xtmk_8.add-panel {
    box-shadow: none; }
    .sections-experience ._button_1xtmk_8.add-panel:hover, .sections-experience ._button_1xtmk_8.add-panel:active, .sections-experience ._button_1xtmk_8.add-panel._hovered_1xtmk_10, .sections-experience ._button_1xtmk_8.add-panel._selected_1xtmk_10, .sections-experience ._button_1xtmk_8.add-panel._highlighted_1xtmk_10 {
      background-color: #ebf2ff; }
      .sections-experience ._button_1xtmk_8.add-panel:hover .elm-transparent, .sections-experience ._button_1xtmk_8.add-panel:active .elm-transparent, .sections-experience ._button_1xtmk_8.add-panel._hovered_1xtmk_10 .elm-transparent, .sections-experience ._button_1xtmk_8.add-panel._selected_1xtmk_10 .elm-transparent, .sections-experience ._button_1xtmk_8.add-panel._highlighted_1xtmk_10 .elm-transparent {
        color: #ebf2ff; }
      .sections-experience ._button_1xtmk_8.add-panel:hover .circle-back, .sections-experience ._button_1xtmk_8.add-panel:active .circle-back, .sections-experience ._button_1xtmk_8.add-panel._hovered_1xtmk_10 .circle-back, .sections-experience ._button_1xtmk_8.add-panel._selected_1xtmk_10 .circle-back, .sections-experience ._button_1xtmk_8.add-panel._highlighted_1xtmk_10 .circle-back {
        fill: #116dff; }
    .sections-experience ._button_1xtmk_8.add-panel .add-plus {
      transform-origin: center;
      opacity: 1;
      color: #fff; }
    .sections-experience ._button_1xtmk_8.add-panel .plus-horizontal-line {
      transform: translate(0px, 5px) rotate(-90deg);
      height: 0px;
      transform-origin: center;
      transform-box: fill-box; }
    .sections-experience ._button_1xtmk_8.add-panel .plus-vertical-line {
      transform: translate(0px, 5px);
      height: 0px;
      transform-origin: center;
      transform-box: fill-box; }
    .sections-experience ._button_1xtmk_8.add-panel:not(._selected_1xtmk_10):hover .add-plus {
      animation: _hide-plus-animation_1xtmk_1 0.333s; }
    .sections-experience ._button_1xtmk_8.add-panel:not(._selected_1xtmk_10):hover .plus-horizontal-line {
      animation: _show-horizontal-line-animation_1xtmk_1 0.283s cubic-bezier(0.25, 1, 0.5, 1) 0.05s; }
    .sections-experience ._button_1xtmk_8.add-panel:not(._selected_1xtmk_10):hover .plus-vertical-line {
      animation: _show-vertical-line-animation_1xtmk_1 0.283s cubic-bezier(0.25, 1, 0.5, 1) 0.05s; }

@keyframes _hide-plus-animation_1xtmk_1 {
  0% {
    opacity: 1; }
  1% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes _show-horizontal-line-animation_1xtmk_1 {
  0% {
    transform: translate(0px, 5px) rotate(-90deg);
    height: 2px; }
  100% {
    transform: translate(0px, 0px) rotate(-90deg);
    height: 12px; } }

@keyframes _show-vertical-line-animation_1xtmk_1 {
  0% {
    transform: translate(0px, 5px);
    height: 2px; }
  100% {
    transform: translate(0px, 0px);
    height: 12px; } }

._button_1xtmk_8.add-section-panel:hover, ._button_1xtmk_8.add-section-panel:active, ._button_1xtmk_8.add-section-panel._hovered_1xtmk_10, ._button_1xtmk_8.add-section-panel._selected_1xtmk_10, ._button_1xtmk_8.add-section-panel._highlighted_1xtmk_10 {
  background: #d9d2ff; }
  ._button_1xtmk_8.add-section-panel:hover .icon-transparent, ._button_1xtmk_8.add-section-panel:active .icon-transparent, ._button_1xtmk_8.add-section-panel._hovered_1xtmk_10 .icon-transparent, ._button_1xtmk_8.add-section-panel._selected_1xtmk_10 .icon-transparent, ._button_1xtmk_8.add-section-panel._highlighted_1xtmk_10 .icon-transparent {
    color: #d9d2ff; }
  ._button_1xtmk_8.add-section-panel:hover svg, ._button_1xtmk_8.add-section-panel:active svg, ._button_1xtmk_8.add-section-panel._hovered_1xtmk_10 svg, ._button_1xtmk_8.add-section-panel._selected_1xtmk_10 svg, ._button_1xtmk_8.add-section-panel._highlighted_1xtmk_10 svg {
    color: #6f56f9; }

._button_1xtmk_8.add-section-panel:not(._selected_1xtmk_10):hover .upper-part {
  animation: _up-upper-part-animation_1xtmk_1 0.275s cubic-bezier(0.5, 1, 0.89, 1), _down-upper-part-animation_1xtmk_1 0.058s linear 0.275s; }

._button_1xtmk_8.add-section-panel:not(._selected_1xtmk_10):hover .bottom-part {
  animation: _up-bottom-part-animation_1xtmk_1 0.275s cubic-bezier(0.5, 1, 0.89, 1), _down-bottom-part-animation_1xtmk_1 0.058s linear 0.275s; }

._button_1xtmk_8.add-section-panel:not(._selected_1xtmk_10):hover .middle-part {
  animation: _move-middle-animation_1xtmk_1 0.275s cubic-bezier(0.5, 1, 0.89, 1); }

@keyframes _up-upper-part-animation_1xtmk_1 {
  0% {
    transform: translate(0px, 3px); }
  100% {
    transform: translate(0px, -1px); } }

@keyframes _down-upper-part-animation_1xtmk_1 {
  0% {
    transform: translate(0px, -1px); }
  100% {
    transform: translate(0px, 0px); } }

@keyframes _up-bottom-part-animation_1xtmk_1 {
  0% {
    transform: translate(0px, -3px); }
  100% {
    transform: translate(0px, 1px); } }

@keyframes _down-bottom-part-animation_1xtmk_1 {
  0% {
    transform: translate(0px, 1px); }
  100% {
    transform: translate(0px, 0px); } }

@keyframes _move-middle-animation_1xtmk_1 {
  0% {
    height: 0px;
    transform: translate(0px, 4px); }
  100% {
    height: 8px;
    transform: translate(0px, 0px); } }

._button_1xtmk_8.app-manager-panel .icon-transparent {
  color: #fff; }

._button_1xtmk_8.app-manager-panel:hover, ._button_1xtmk_8.app-manager-panel:active, ._button_1xtmk_8.app-manager-panel._hovered_1xtmk_10, ._button_1xtmk_8.app-manager-panel._selected_1xtmk_10, ._button_1xtmk_8.app-manager-panel._highlighted_1xtmk_10 {
  background: #eeecfe; }
  ._button_1xtmk_8.app-manager-panel:hover .icon-transparent, ._button_1xtmk_8.app-manager-panel:active .icon-transparent, ._button_1xtmk_8.app-manager-panel._hovered_1xtmk_10 .icon-transparent, ._button_1xtmk_8.app-manager-panel._selected_1xtmk_10 .icon-transparent, ._button_1xtmk_8.app-manager-panel._highlighted_1xtmk_10 .icon-transparent {
    color: #eeecfe; }
  ._button_1xtmk_8.app-manager-panel:hover svg, ._button_1xtmk_8.app-manager-panel:active svg, ._button_1xtmk_8.app-manager-panel._hovered_1xtmk_10 svg, ._button_1xtmk_8.app-manager-panel._selected_1xtmk_10 svg, ._button_1xtmk_8.app-manager-panel._highlighted_1xtmk_10 svg {
    color: #5a48f5; }

._button_1xtmk_8.app-manager-panel .icon-animation-3 {
  transform: translateY(0); }

._button_1xtmk_8.app-manager-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.app-manager-panel:not(._selected_1xtmk_10):hover .icon-animation-1 {
  transform-origin: center;
  transform-box: fill-box;
  animation: _app-manager-gear-animation_1xtmk_1 0.25s cubic-bezier(0.33, 1, 0.68, 1); }

._button_1xtmk_8.app-manager-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-2,
._button_1xtmk_8.app-manager-panel:not(._selected_1xtmk_10):hover .icon-animation-2 {
  animation: _app-manager-up-square-animation_1xtmk_1 0.25s cubic-bezier(0.33, 1, 0.68, 1), _hide-app-manager-up-square-animation_1xtmk_1 0.185s linear 0.065s; }

._button_1xtmk_8.app-manager-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-3,
._button_1xtmk_8.app-manager-panel:not(._selected_1xtmk_10):hover .icon-animation-3 {
  animation: _app-manager-down-square-animation_1xtmk_1 0.25s cubic-bezier(0.33, 1, 0.68, 1); }

._button_1xtmk_8.app-manager-panel:not(._selected_1xtmk_10) .icon-animation-4 {
  opacity: 0; }

._button_1xtmk_8.app-manager-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-4,
._button_1xtmk_8.app-manager-panel:not(._selected_1xtmk_10):hover .icon-animation-4 {
  animation: _app-manager-down-square-2-animation_1xtmk_1 0.25s cubic-bezier(0.33, 1, 0.68, 1), _show-app-manager-down-square-2-animation_1xtmk_1 0.195s linear 0.055s; }

._button_1xtmk_8.app-manager-panel-new .icon-transparent {
  color: #fff; }

._button_1xtmk_8.app-manager-panel-new:hover, ._button_1xtmk_8.app-manager-panel-new:active, ._button_1xtmk_8.app-manager-panel-new._hovered_1xtmk_10, ._button_1xtmk_8.app-manager-panel-new._selected_1xtmk_10, ._button_1xtmk_8.app-manager-panel-new._highlighted_1xtmk_10 {
  background: #e8ebf4; }
  ._button_1xtmk_8.app-manager-panel-new:hover .icon-transparent, ._button_1xtmk_8.app-manager-panel-new:active .icon-transparent, ._button_1xtmk_8.app-manager-panel-new._hovered_1xtmk_10 .icon-transparent, ._button_1xtmk_8.app-manager-panel-new._selected_1xtmk_10 .icon-transparent, ._button_1xtmk_8.app-manager-panel-new._highlighted_1xtmk_10 .icon-transparent {
    color: #e8ebf4; }
  ._button_1xtmk_8.app-manager-panel-new:hover svg, ._button_1xtmk_8.app-manager-panel-new:active svg, ._button_1xtmk_8.app-manager-panel-new._hovered_1xtmk_10 svg, ._button_1xtmk_8.app-manager-panel-new._selected_1xtmk_10 svg, ._button_1xtmk_8.app-manager-panel-new._highlighted_1xtmk_10 svg {
    color: #1b3689; }

._button_1xtmk_8.app-manager-panel-new .icon-animation-3 {
  transform: translateY(0); }

._button_1xtmk_8.app-manager-panel-new:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.app-manager-panel-new:not(._selected_1xtmk_10):hover .icon-animation-1 {
  transform-origin: center;
  transform-box: fill-box;
  animation: _app-manager-gear-animation_1xtmk_1 0.25s cubic-bezier(0.33, 1, 0.68, 1); }

._button_1xtmk_8.app-manager-panel-new:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-2,
._button_1xtmk_8.app-manager-panel-new:not(._selected_1xtmk_10):hover .icon-animation-2 {
  animation: _app-manager-up-square-animation_1xtmk_1 0.25s cubic-bezier(0.33, 1, 0.68, 1), _hide-app-manager-up-square-animation_1xtmk_1 0.185s linear 0.065s; }

._button_1xtmk_8.app-manager-panel-new:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-3,
._button_1xtmk_8.app-manager-panel-new:not(._selected_1xtmk_10):hover .icon-animation-3 {
  animation: _app-manager-down-square-animation_1xtmk_1 0.25s cubic-bezier(0.33, 1, 0.68, 1); }

._button_1xtmk_8.app-manager-panel-new:not(._selected_1xtmk_10) .icon-animation-4 {
  opacity: 0; }

._button_1xtmk_8.app-manager-panel-new:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-4,
._button_1xtmk_8.app-manager-panel-new:not(._selected_1xtmk_10):hover .icon-animation-4 {
  animation: _app-manager-down-square-2-animation_1xtmk_1 0.25s cubic-bezier(0.33, 1, 0.68, 1), _show-app-manager-down-square-2-animation_1xtmk_1 0.195s linear 0.055s; }

@keyframes _app-manager-gear-animation_1xtmk_1 {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(45deg); } }

@keyframes _app-manager-up-square-animation_1xtmk_1 {
  0% {
    height: 7px; }
  100% {
    height: 2px;
    transform: translate(0, 0); } }

@keyframes _hide-app-manager-up-square-animation_1xtmk_1 {
  0% {
    opacity: 1; }
  1% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes _app-manager-down-square-animation_1xtmk_1 {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-11px); } }

@keyframes _app-manager-down-square-2-animation_1xtmk_1 {
  0% {
    height: 2px;
    transform: rotate(180deg) translate(-15px, -37px); }
  100% {
    height: 7px;
    transform: rotate(180deg) translate(-15px, -37px); } }

@keyframes _show-app-manager-down-square-2-animation_1xtmk_1 {
  0% {
    opacity: 0; }
  1% {
    opacity: 1; }
  100% {
    opacity: 1; } }

._button_1xtmk_8.am-leftBarButtonAppManagerFitness:hover, ._button_1xtmk_8.am-leftBarButtonAppManagerFitness:active, ._button_1xtmk_8.am-leftBarButtonAppManagerFitness._hovered_1xtmk_10, ._button_1xtmk_8.am-leftBarButtonAppManagerFitness._selected_1xtmk_10, ._button_1xtmk_8.am-leftBarButtonAppManagerFitness._highlighted_1xtmk_10 {
  background: #eeecfe; }
  ._button_1xtmk_8.am-leftBarButtonAppManagerFitness:hover svg, ._button_1xtmk_8.am-leftBarButtonAppManagerFitness:active svg, ._button_1xtmk_8.am-leftBarButtonAppManagerFitness._hovered_1xtmk_10 svg, ._button_1xtmk_8.am-leftBarButtonAppManagerFitness._selected_1xtmk_10 svg, ._button_1xtmk_8.am-leftBarButtonAppManagerFitness._highlighted_1xtmk_10 svg {
    color: #5a48f5;
    overflow: visible; }

._button_1xtmk_8.am-leftBarButtonAppManagerFitness .icon-animation-1 {
  transform-origin: bottom; }

._button_1xtmk_8.am-leftBarButtonAppManagerFitness:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.am-leftBarButtonAppManagerFitness:not(._selected_1xtmk_10):hover .icon-animation-1 {
  animation: _fit-weight-animation_1xtmk_1 0.1s cubic-bezier(0.65, 0, 0.35, 1), _fit-weight-animation-2_1xtmk_1 0.217s cubic-bezier(0.65, 0, 0.35, 1) 0.1s, _fit-weight-animation-3_1xtmk_1 0.2s cubic-bezier(0.65, 0, 0.35, 1) 0.317s; }

@keyframes _fit-weight-animation_1xtmk_1 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.12); } }

@keyframes _fit-weight-animation-2_1xtmk_1 {
  0% {
    transform: scale(1.12); }
  100% {
    transform: scale(0.93); } }

@keyframes _fit-weight-animation-3_1xtmk_1 {
  0% {
    transform: scale(0.93); }
  100% {
    transform: scale(1); } }

._button_1xtmk_8.app-market-panel:hover, ._button_1xtmk_8.app-market-panel:active, ._button_1xtmk_8.app-market-panel._hovered_1xtmk_10, ._button_1xtmk_8.app-market-panel._selected_1xtmk_10, ._button_1xtmk_8.app-market-panel._highlighted_1xtmk_10 {
  background: #fde9f3; }
  ._button_1xtmk_8.app-market-panel:hover svg, ._button_1xtmk_8.app-market-panel:active svg, ._button_1xtmk_8.app-market-panel._hovered_1xtmk_10 svg, ._button_1xtmk_8.app-market-panel._selected_1xtmk_10 svg, ._button_1xtmk_8.app-market-panel._highlighted_1xtmk_10 svg {
    color: #e3169a; }

._button_1xtmk_8.app-market-panel .icon-animation-1,
._button_1xtmk_8.app-market-panel .icon-animation-3 {
  transform: translate(0, 0); }

._button_1xtmk_8.app-market-panel .icon-animation-1-back,
._button_1xtmk_8.app-market-panel .icon-animation-3-back {
  opacity: 0; }

._button_1xtmk_8.app-market-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.app-market-panel:not(._selected_1xtmk_10):hover .icon-animation-1 {
  animation: _app-square-1-animation_1xtmk_1 0.283s cubic-bezier(0.33, 1, 0.68, 1); }

._button_1xtmk_8.app-market-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-3,
._button_1xtmk_8.app-market-panel:not(._selected_1xtmk_10):hover .icon-animation-3 {
  animation: _app-square-3-animation_1xtmk_1 0.283s cubic-bezier(0.33, 1, 0.68, 1) 0.05s; }

._button_1xtmk_8.app-market-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-2,
._button_1xtmk_8.app-market-panel:not(._selected_1xtmk_10):hover .icon-animation-2 {
  animation: _app-square-2-animation_1xtmk_1 0.283s linear; }

._button_1xtmk_8.app-market-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-4,
._button_1xtmk_8.app-market-panel:not(._selected_1xtmk_10):hover .icon-animation-4 {
  animation: _app-square-4-animation_1xtmk_1 0.283s linear 0.05s; }

._button_1xtmk_8.app-market-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1-back,
._button_1xtmk_8.app-market-panel:not(._selected_1xtmk_10):hover .icon-animation-1-back {
  animation: _app-square-1-back-animation_1xtmk_1 0.283s linear 0.083s; }

._button_1xtmk_8.app-market-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-3-back,
._button_1xtmk_8.app-market-panel:not(._selected_1xtmk_10):hover .icon-animation-3-back {
  animation: _app-square-3-back-animation_1xtmk_1 0.283s linear 0.133s; }

@keyframes _app-square-1-animation_1xtmk_1 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(11px, 0); } }

@keyframes _app-square-3-animation_1xtmk_1 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(11px, 0); } }

@keyframes _app-square-2-animation_1xtmk_1 {
  0% {
    opacity: 1; }
  24% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes _app-square-4-animation_1xtmk_1 {
  0% {
    opacity: 1; }
  24% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes _app-square-1-back-animation_1xtmk_1 {
  0% {
    opacity: 0; }
  17% {
    opacity: 1; }
  99% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes _app-square-3-back-animation_1xtmk_1 {
  0% {
    opacity: 0; }
  17% {
    opacity: 1; }
  99% {
    opacity: 1; }
  100% {
    opacity: 0; } }

._button_1xtmk_8.ascend-panel:hover, ._button_1xtmk_8.ascend-panel:active, ._button_1xtmk_8.ascend-panel._hovered_1xtmk_10, ._button_1xtmk_8.ascend-panel._selected_1xtmk_10, ._button_1xtmk_8.ascend-panel._highlighted_1xtmk_10 {
  background: #e7effb; }
  ._button_1xtmk_8.ascend-panel:hover .icon-transparent, ._button_1xtmk_8.ascend-panel:active .icon-transparent, ._button_1xtmk_8.ascend-panel._hovered_1xtmk_10 .icon-transparent, ._button_1xtmk_8.ascend-panel._selected_1xtmk_10 .icon-transparent, ._button_1xtmk_8.ascend-panel._highlighted_1xtmk_10 .icon-transparent {
    color: #e7effb; }
  ._button_1xtmk_8.ascend-panel:hover svg, ._button_1xtmk_8.ascend-panel:active svg, ._button_1xtmk_8.ascend-panel._hovered_1xtmk_10 svg, ._button_1xtmk_8.ascend-panel._selected_1xtmk_10 svg, ._button_1xtmk_8.ascend-panel._highlighted_1xtmk_10 svg {
    color: #1863da; }

._button_1xtmk_8.ascend-panel .icon-transparent {
  color: #fff; }

._button_1xtmk_8.ascend-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.ascend-panel:not(._selected_1xtmk_10):hover .icon-animation-1 {
  animation: _business-letter-a-fade-out-animation_1xtmk_1 0.033s cubic-bezier(0.33, 1, 0.68, 1), _business-letter-a-animation_1xtmk_1 0.333s cubic-bezier(0.33, 1, 0.68, 1) 0.028s; }

@keyframes _business-letter-a-fade-out-animation_1xtmk_1 {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes _business-letter-a-animation_1xtmk_1 {
  0% {
    transform: translateY(9px); }
  100% {
    transform: translateY(0); } }

._button_1xtmk_8.bg-panel:hover, ._button_1xtmk_8.bg-panel:active, ._button_1xtmk_8.bg-panel._hovered_1xtmk_10, ._button_1xtmk_8.bg-panel._selected_1xtmk_10, ._button_1xtmk_8.bg-panel._highlighted_1xtmk_10 {
  background: #fef3e6; }
  ._button_1xtmk_8.bg-panel:hover .icon-transparent, ._button_1xtmk_8.bg-panel:active .icon-transparent, ._button_1xtmk_8.bg-panel._hovered_1xtmk_10 .icon-transparent, ._button_1xtmk_8.bg-panel._selected_1xtmk_10 .icon-transparent, ._button_1xtmk_8.bg-panel._highlighted_1xtmk_10 .icon-transparent {
    color: #fef3e6; }
  ._button_1xtmk_8.bg-panel:hover svg, ._button_1xtmk_8.bg-panel:active svg, ._button_1xtmk_8.bg-panel._hovered_1xtmk_10 svg, ._button_1xtmk_8.bg-panel._selected_1xtmk_10 svg, ._button_1xtmk_8.bg-panel._highlighted_1xtmk_10 svg {
    color: #faad4d; }

._button_1xtmk_8.bg-panel .icon-transparent {
  color: #fff; }

._button_1xtmk_8.bg-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.bg-panel:not(._selected_1xtmk_10):hover .icon-animation-1 {
  animation: _background-down-animation_1xtmk_1 0.33s cubic-bezier(0.33, 1, 0.68, 1); }

._button_1xtmk_8.bg-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-2,
._button_1xtmk_8.bg-panel:not(._selected_1xtmk_10):hover .icon-animation-2 {
  animation: _background-up-animation_1xtmk_1 0.33s cubic-bezier(0.33, 1, 0.68, 1); }

@keyframes _background-down-animation_1xtmk_1 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(12px, 12px); } }

@keyframes _background-up-animation_1xtmk_1 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(12px, 12px); } }

._button_1xtmk_8.blog-panel:hover, ._button_1xtmk_8.blog-panel:active, ._button_1xtmk_8.blog-panel._hovered_1xtmk_10, ._button_1xtmk_8.blog-panel._selected_1xtmk_10, ._button_1xtmk_8.blog-panel._highlighted_1xtmk_10,
._button_1xtmk_8.new-blog-panel:hover,
._button_1xtmk_8.new-blog-panel:active,
._button_1xtmk_8.new-blog-panel._hovered_1xtmk_10,
._button_1xtmk_8.new-blog-panel._selected_1xtmk_10,
._button_1xtmk_8.new-blog-panel._highlighted_1xtmk_10 {
  background: #fef3e6; }
  ._button_1xtmk_8.blog-panel:hover svg, ._button_1xtmk_8.blog-panel:active svg, ._button_1xtmk_8.blog-panel._hovered_1xtmk_10 svg, ._button_1xtmk_8.blog-panel._selected_1xtmk_10 svg, ._button_1xtmk_8.blog-panel._highlighted_1xtmk_10 svg,
  ._button_1xtmk_8.new-blog-panel:hover svg,
  ._button_1xtmk_8.new-blog-panel:active svg,
  ._button_1xtmk_8.new-blog-panel._hovered_1xtmk_10 svg,
  ._button_1xtmk_8.new-blog-panel._selected_1xtmk_10 svg,
  ._button_1xtmk_8.new-blog-panel._highlighted_1xtmk_10 svg {
    color: #faad4d; }

._button_1xtmk_8.blog-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.blog-panel:not(._selected_1xtmk_10):hover .icon-animation-1,
._button_1xtmk_8.new-blog-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.new-blog-panel:not(._selected_1xtmk_10):hover .icon-animation-1 {
  transform-origin: center;
  transform-box: fill-box;
  animation: _blog-pen-animation_1xtmk_1 0.333s cubic-bezier(0.65, 0, 0.35, 1); }

@keyframes _blog-pen-animation_1xtmk_1 {
  0% {
    transform: rotate(0); }
  50% {
    transform: rotate(-90deg); }
  100% {
    transform: rotate(0); } }

._button_1xtmk_8.wix-bookings-panel:hover, ._button_1xtmk_8.wix-bookings-panel:active, ._button_1xtmk_8.wix-bookings-panel._hovered_1xtmk_10, ._button_1xtmk_8.wix-bookings-panel._selected_1xtmk_10, ._button_1xtmk_8.wix-bookings-panel._highlighted_1xtmk_10 {
  background: #eeecfe; }
  ._button_1xtmk_8.wix-bookings-panel:hover .icon-transparent, ._button_1xtmk_8.wix-bookings-panel:active .icon-transparent, ._button_1xtmk_8.wix-bookings-panel._hovered_1xtmk_10 .icon-transparent, ._button_1xtmk_8.wix-bookings-panel._selected_1xtmk_10 .icon-transparent, ._button_1xtmk_8.wix-bookings-panel._highlighted_1xtmk_10 .icon-transparent {
    color: #eeecfe; }
  ._button_1xtmk_8.wix-bookings-panel:hover svg, ._button_1xtmk_8.wix-bookings-panel:active svg, ._button_1xtmk_8.wix-bookings-panel._hovered_1xtmk_10 svg, ._button_1xtmk_8.wix-bookings-panel._selected_1xtmk_10 svg, ._button_1xtmk_8.wix-bookings-panel._highlighted_1xtmk_10 svg {
    color: #5a48f5; }

._button_1xtmk_8.wix-bookings-panel .icon-transparent {
  color: #fff; }

._button_1xtmk_8.wix-bookings-panel .icon-animation-2 {
  transform: translateY(0); }

._button_1xtmk_8.wix-bookings-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.wix-bookings-panel:not(._selected_1xtmk_10):hover .icon-animation-1 {
  animation: _bookings-main-square-animation_1xtmk_1 0.333s cubic-bezier(0.65, 0, 0.35, 1); }

._button_1xtmk_8.wix-bookings-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-2,
._button_1xtmk_8.wix-bookings-panel:not(._selected_1xtmk_10):hover .icon-animation-2 {
  animation: _bookings-square-left-animation_1xtmk_1 0.183s cubic-bezier(0.65, 0, 0.35, 1); }

@keyframes _bookings-main-square-animation_1xtmk_1 {
  0% {
    transform: translateY(0); }
  20% {
    transform: translateY(-4px); }
  21% {
    transform: translateY(8px); }
  100% {
    transform: translateY(0); } }

@keyframes _bookings-square-left-animation_1xtmk_1 {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-14px); } }

._button_1xtmk_8.wix-data-panel:hover, ._button_1xtmk_8.wix-data-panel:active, ._button_1xtmk_8.wix-data-panel._hovered_1xtmk_10, ._button_1xtmk_8.wix-data-panel._selected_1xtmk_10, ._button_1xtmk_8.wix-data-panel._highlighted_1xtmk_10,
._button_1xtmk_8.wix-data-panel-icon-b:hover,
._button_1xtmk_8.wix-data-panel-icon-b:active,
._button_1xtmk_8.wix-data-panel-icon-b._hovered_1xtmk_10,
._button_1xtmk_8.wix-data-panel-icon-b._selected_1xtmk_10,
._button_1xtmk_8.wix-data-panel-icon-b._highlighted_1xtmk_10 {
  background: #e0f4eb; }
  ._button_1xtmk_8.wix-data-panel:hover .icon-transparent, ._button_1xtmk_8.wix-data-panel:active .icon-transparent, ._button_1xtmk_8.wix-data-panel._hovered_1xtmk_10 .icon-transparent, ._button_1xtmk_8.wix-data-panel._selected_1xtmk_10 .icon-transparent, ._button_1xtmk_8.wix-data-panel._highlighted_1xtmk_10 .icon-transparent,
  ._button_1xtmk_8.wix-data-panel-icon-b:hover .icon-transparent,
  ._button_1xtmk_8.wix-data-panel-icon-b:active .icon-transparent,
  ._button_1xtmk_8.wix-data-panel-icon-b._hovered_1xtmk_10 .icon-transparent,
  ._button_1xtmk_8.wix-data-panel-icon-b._selected_1xtmk_10 .icon-transparent,
  ._button_1xtmk_8.wix-data-panel-icon-b._highlighted_1xtmk_10 .icon-transparent {
    color: #e0f4eb; }
  ._button_1xtmk_8.wix-data-panel:hover svg, ._button_1xtmk_8.wix-data-panel:active svg, ._button_1xtmk_8.wix-data-panel._hovered_1xtmk_10 svg, ._button_1xtmk_8.wix-data-panel._selected_1xtmk_10 svg, ._button_1xtmk_8.wix-data-panel._highlighted_1xtmk_10 svg,
  ._button_1xtmk_8.wix-data-panel-icon-b:hover svg,
  ._button_1xtmk_8.wix-data-panel-icon-b:active svg,
  ._button_1xtmk_8.wix-data-panel-icon-b._hovered_1xtmk_10 svg,
  ._button_1xtmk_8.wix-data-panel-icon-b._selected_1xtmk_10 svg,
  ._button_1xtmk_8.wix-data-panel-icon-b._highlighted_1xtmk_10 svg {
    color: #1c7962; }

._button_1xtmk_8.wix-data-panel .icon-transparent,
._button_1xtmk_8.wix-data-panel-icon-b .icon-transparent {
  color: #fff; }

._button_1xtmk_8.wix-data-panel .icon-down-elm .icon-up-elm,
._button_1xtmk_8.wix-data-panel-icon-b .icon-down-elm .icon-up-elm {
  transform: translateY(0); }

._button_1xtmk_8.wix-data-panel .icon-down-hide-elm,
._button_1xtmk_8.wix-data-panel-icon-b .icon-down-hide-elm {
  transform: translateY(4px);
  opacity: 0; }

._button_1xtmk_8.wix-data-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-up-elm,
._button_1xtmk_8.wix-data-panel:not(._selected_1xtmk_10):hover .icon-up-elm,
._button_1xtmk_8.wix-data-panel-icon-b:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-up-elm,
._button_1xtmk_8.wix-data-panel-icon-b:not(._selected_1xtmk_10):hover .icon-up-elm {
  animation: _content-up_1xtmk_1 0.333s cubic-bezier(0.33, 1, 0.68, 1); }

._button_1xtmk_8.wix-data-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-down-elm,
._button_1xtmk_8.wix-data-panel:not(._selected_1xtmk_10):hover .icon-down-elm,
._button_1xtmk_8.wix-data-panel-icon-b:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-down-elm,
._button_1xtmk_8.wix-data-panel-icon-b:not(._selected_1xtmk_10):hover .icon-down-elm {
  animation: _content-down_1xtmk_1 0.333s cubic-bezier(0.33, 1, 0.68, 1); }

._button_1xtmk_8.wix-data-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-down-hide-elm,
._button_1xtmk_8.wix-data-panel:not(._selected_1xtmk_10):hover .icon-down-hide-elm,
._button_1xtmk_8.wix-data-panel-icon-b:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-down-hide-elm,
._button_1xtmk_8.wix-data-panel-icon-b:not(._selected_1xtmk_10):hover .icon-down-hide-elm {
  animation: _content-down-hide_1xtmk_1 0.221s cubic-bezier(0.33, 1, 0.68, 1) 0.12s; }

@keyframes _content-up_1xtmk_1 {
  0% {
    transform: translateY(0); }
  9% {
    transition: linear;
    opacity: 1; }
  60% {
    transition: linear;
    opacity: 0; }
  100% {
    transform: translateY(-5px);
    opacity: 0; } }

@keyframes _content-down_1xtmk_1 {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-6px); } }

@keyframes _content-down-hide_1xtmk_1 {
  0% {
    transform: translateY(4px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

._button_1xtmk_8.hidden-items-panel:hover, ._button_1xtmk_8.hidden-items-panel:active, ._button_1xtmk_8.hidden-items-panel._hovered_1xtmk_10, ._button_1xtmk_8.hidden-items-panel._selected_1xtmk_10, ._button_1xtmk_8.hidden-items-panel._highlighted_1xtmk_10,
._button_1xtmk_8.interactions-hidden-components:hover,
._button_1xtmk_8.interactions-hidden-components:active,
._button_1xtmk_8.interactions-hidden-components._hovered_1xtmk_10,
._button_1xtmk_8.interactions-hidden-components._selected_1xtmk_10,
._button_1xtmk_8.interactions-hidden-components._highlighted_1xtmk_10 {
  background: #fde9f3; }
  ._button_1xtmk_8.hidden-items-panel:hover svg, ._button_1xtmk_8.hidden-items-panel:active svg, ._button_1xtmk_8.hidden-items-panel._hovered_1xtmk_10 svg, ._button_1xtmk_8.hidden-items-panel._selected_1xtmk_10 svg, ._button_1xtmk_8.hidden-items-panel._highlighted_1xtmk_10 svg,
  ._button_1xtmk_8.interactions-hidden-components:hover svg,
  ._button_1xtmk_8.interactions-hidden-components:active svg,
  ._button_1xtmk_8.interactions-hidden-components._hovered_1xtmk_10 svg,
  ._button_1xtmk_8.interactions-hidden-components._selected_1xtmk_10 svg,
  ._button_1xtmk_8.interactions-hidden-components._highlighted_1xtmk_10 svg {
    color: #e3169a; }

._button_1xtmk_8.hidden-items-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.hidden-items-panel:not(._selected_1xtmk_10):hover .icon-animation-1,
._button_1xtmk_8.interactions-hidden-components:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.interactions-hidden-components:not(._selected_1xtmk_10):hover .icon-animation-1 {
  animation: _fade-out-hide-line-animation_1xtmk_1, _show-hide-line-animation_1xtmk_1 0.3s cubic-bezier(0.33, 1, 0.68, 1); }

._button_1xtmk_8.hidden-items-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-2,
._button_1xtmk_8.hidden-items-panel:not(._selected_1xtmk_10):hover .icon-animation-2,
._button_1xtmk_8.interactions-hidden-components:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-2,
._button_1xtmk_8.interactions-hidden-components:not(._selected_1xtmk_10):hover .icon-animation-2 {
  transform-origin: center;
  transform-box: fill-box;
  animation: _hide-eye-cricle-animation_1xtmk_1 0.167s 0.05s, _show-eye-cricle-animation_1xtmk_1 0.25s cubic-bezier(0.33, 1, 0.68, 1) 0.1s; }

@keyframes _fade-out-hide-line-animation_1xtmk_1 {
  0% {
    opacity: 1; }
  1% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes _show-hide-line-animation_1xtmk_1 {
  0% {
    height: 0; }
  100% {
    height: 25px; } }

@keyframes _hide-eye-cricle-animation_1xtmk_1 {
  0% {
    transform: scale(1); }
  1% {
    transform: scale(0.2); }
  100% {
    transform: scale(0.2); } }

@keyframes _show-eye-cricle-animation_1xtmk_1 {
  0% {
    transform: scale(0.2); }
  100% {
    transform: scale(1); } }

._button_1xtmk_8.layout-optimizer-panel:hover, ._button_1xtmk_8.layout-optimizer-panel:active, ._button_1xtmk_8.layout-optimizer-panel._hovered_1xtmk_10, ._button_1xtmk_8.layout-optimizer-panel._selected_1xtmk_10, ._button_1xtmk_8.layout-optimizer-panel._highlighted_1xtmk_10 {
  background: #eeecfe; }
  ._button_1xtmk_8.layout-optimizer-panel:hover svg, ._button_1xtmk_8.layout-optimizer-panel:active svg, ._button_1xtmk_8.layout-optimizer-panel._hovered_1xtmk_10 svg, ._button_1xtmk_8.layout-optimizer-panel._selected_1xtmk_10 svg, ._button_1xtmk_8.layout-optimizer-panel._highlighted_1xtmk_10 svg {
    color: #5a48f5; }

._button_1xtmk_8.layout-optimizer-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.layout-optimizer-panel:not(._selected_1xtmk_10):hover .icon-animation-1 {
  animation: _layout-hide-up-right-line-animation_1xtmk_1 0.277s cubic-bezier(0.33, 1, 0.68, 1), _layout-show-up-right-line-animation_1xtmk_1 0.278s cubic-bezier(0.33, 1, 0.68, 1) 0.105s; }

._button_1xtmk_8.layout-optimizer-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-2,
._button_1xtmk_8.layout-optimizer-panel:not(._selected_1xtmk_10):hover .icon-animation-2 {
  animation: _layout-hide-down-right-line-animation_1xtmk_1 0.341s cubic-bezier(0.33, 1, 0.68, 1) 0.042s, _layout-show-down-right-line-animation_1xtmk_1 0.197s cubic-bezier(0.33, 1, 0.68, 1) 0.186s; }

._button_1xtmk_8.layout-optimizer-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-3,
._button_1xtmk_8.layout-optimizer-panel:not(._selected_1xtmk_10):hover .icon-animation-3 {
  animation: _layout-hide-left-square-animation_1xtmk_1 0.075s cubic-bezier(0.33, 1, 0.68, 1), _layout-show-left-square-animation_1xtmk_1 0.308s cubic-bezier(0.33, 1, 0.68, 1) 0.075s; }

@keyframes _layout-hide-up-right-line-animation_1xtmk_1 {
  0% {
    transform: rotate(180deg) translate(-37px, -12px);
    width: 7px; }
  100% {
    transform: rotate(180deg) translate(-37px, -12px);
    width: 0; } }

@keyframes _layout-show-up-right-line-animation_1xtmk_1 {
  0% {
    transform: rotate(0) translate(0, 0);
    width: 0; }
  100% {
    transform: rotate(0) translate(0, 0);
    width: 7px; } }

@keyframes _layout-hide-down-right-line-animation_1xtmk_1 {
  0% {
    transform: rotate(180deg) translate(-37px, -20px);
    width: 7px; }
  100% {
    transform: rotate(180deg) translate(-37px, -20px);
    width: 0; } }

@keyframes _layout-show-down-right-line-animation_1xtmk_1 {
  0% {
    transform: rotate(0) translate(0, 0);
    width: 0; }
  100% {
    transform: rotate(0) translate(0, 0);
    width: 7px; } }

@keyframes _layout-hide-left-square-animation_1xtmk_1 {
  0% {
    transform: rotate(0) translate(0, 0);
    height: 8px; }
  100% {
    transform: rotate(0) translate(0, 0);
    height: 2px; } }

@keyframes _layout-show-left-square-animation_1xtmk_1 {
  0% {
    transform: rotate(180deg) translate(-16px, -16px);
    height: 3px; }
  100% {
    transform: rotate(180deg) translate(-16px, -16px);
    height: 8px; } }

._button_1xtmk_8.media-manager-panel:hover, ._button_1xtmk_8.media-manager-panel:active, ._button_1xtmk_8.media-manager-panel._hovered_1xtmk_10, ._button_1xtmk_8.media-manager-panel._selected_1xtmk_10, ._button_1xtmk_8.media-manager-panel._highlighted_1xtmk_10,
._button_1xtmk_8.media-manager-panel-icon-b:hover,
._button_1xtmk_8.media-manager-panel-icon-b:active,
._button_1xtmk_8.media-manager-panel-icon-b._hovered_1xtmk_10,
._button_1xtmk_8.media-manager-panel-icon-b._selected_1xtmk_10,
._button_1xtmk_8.media-manager-panel-icon-b._highlighted_1xtmk_10,
._button_1xtmk_8.media-manager-panel-icon-c:hover,
._button_1xtmk_8.media-manager-panel-icon-c:active,
._button_1xtmk_8.media-manager-panel-icon-c._hovered_1xtmk_10,
._button_1xtmk_8.media-manager-panel-icon-c._selected_1xtmk_10,
._button_1xtmk_8.media-manager-panel-icon-c._highlighted_1xtmk_10 {
  background: #fff0ea; }
  ._button_1xtmk_8.media-manager-panel:hover .icon-transparent, ._button_1xtmk_8.media-manager-panel:active .icon-transparent, ._button_1xtmk_8.media-manager-panel._hovered_1xtmk_10 .icon-transparent, ._button_1xtmk_8.media-manager-panel._selected_1xtmk_10 .icon-transparent, ._button_1xtmk_8.media-manager-panel._highlighted_1xtmk_10 .icon-transparent,
  ._button_1xtmk_8.media-manager-panel-icon-b:hover .icon-transparent,
  ._button_1xtmk_8.media-manager-panel-icon-b:active .icon-transparent,
  ._button_1xtmk_8.media-manager-panel-icon-b._hovered_1xtmk_10 .icon-transparent,
  ._button_1xtmk_8.media-manager-panel-icon-b._selected_1xtmk_10 .icon-transparent,
  ._button_1xtmk_8.media-manager-panel-icon-b._highlighted_1xtmk_10 .icon-transparent,
  ._button_1xtmk_8.media-manager-panel-icon-c:hover .icon-transparent,
  ._button_1xtmk_8.media-manager-panel-icon-c:active .icon-transparent,
  ._button_1xtmk_8.media-manager-panel-icon-c._hovered_1xtmk_10 .icon-transparent,
  ._button_1xtmk_8.media-manager-panel-icon-c._selected_1xtmk_10 .icon-transparent,
  ._button_1xtmk_8.media-manager-panel-icon-c._highlighted_1xtmk_10 .icon-transparent {
    color: #fff0ea; }
  ._button_1xtmk_8.media-manager-panel:hover svg, ._button_1xtmk_8.media-manager-panel:active svg, ._button_1xtmk_8.media-manager-panel._hovered_1xtmk_10 svg, ._button_1xtmk_8.media-manager-panel._selected_1xtmk_10 svg, ._button_1xtmk_8.media-manager-panel._highlighted_1xtmk_10 svg,
  ._button_1xtmk_8.media-manager-panel-icon-b:hover svg,
  ._button_1xtmk_8.media-manager-panel-icon-b:active svg,
  ._button_1xtmk_8.media-manager-panel-icon-b._hovered_1xtmk_10 svg,
  ._button_1xtmk_8.media-manager-panel-icon-b._selected_1xtmk_10 svg,
  ._button_1xtmk_8.media-manager-panel-icon-b._highlighted_1xtmk_10 svg,
  ._button_1xtmk_8.media-manager-panel-icon-c:hover svg,
  ._button_1xtmk_8.media-manager-panel-icon-c:active svg,
  ._button_1xtmk_8.media-manager-panel-icon-c._hovered_1xtmk_10 svg,
  ._button_1xtmk_8.media-manager-panel-icon-c._selected_1xtmk_10 svg,
  ._button_1xtmk_8.media-manager-panel-icon-c._highlighted_1xtmk_10 svg {
    color: #fb6413; }

._button_1xtmk_8.media-manager-panel .icon-transparent,
._button_1xtmk_8.media-manager-panel-icon-b .icon-transparent,
._button_1xtmk_8.media-manager-panel-icon-c .icon-transparent {
  color: #fff; }

._button_1xtmk_8.media-manager-panel .icon-animation-2,
._button_1xtmk_8.media-manager-panel-icon-b .icon-animation-2,
._button_1xtmk_8.media-manager-panel-icon-c .icon-animation-2 {
  transform-origin: center;
  transform-box: fill-box;
  transform: rotate(-135deg); }

._button_1xtmk_8.media-manager-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.media-manager-panel:not(._selected_1xtmk_10):hover .icon-animation-1,
._button_1xtmk_8.media-manager-panel-icon-b:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.media-manager-panel-icon-b:not(._selected_1xtmk_10):hover .icon-animation-1,
._button_1xtmk_8.media-manager-panel-icon-c:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.media-manager-panel-icon-c:not(._selected_1xtmk_10):hover .icon-animation-1 {
  animation: _media-moon-animation_1xtmk_1 0.38s cubic-bezier(0.33, 1, 0.68, 1); }

._button_1xtmk_8.media-manager-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-2,
._button_1xtmk_8.media-manager-panel:not(._selected_1xtmk_10):hover .icon-animation-2,
._button_1xtmk_8.media-manager-panel-icon-b:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-2,
._button_1xtmk_8.media-manager-panel-icon-b:not(._selected_1xtmk_10):hover .icon-animation-2,
._button_1xtmk_8.media-manager-panel-icon-c:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-2,
._button_1xtmk_8.media-manager-panel-icon-c:not(._selected_1xtmk_10):hover .icon-animation-2 {
  animation: _media-mountain-animation_1xtmk_1 0.38s cubic-bezier(0.33, 1, 0.68, 1); }

@keyframes _media-moon-animation_1xtmk_1 {
  0% {
    transform: translateY(0); }
  33% {
    transform: translateY(-2px);
    opacity: 0; }
  36% {
    transform: translateY(6px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes _media-mountain-animation_1xtmk_1 {
  0% {
    transform: rotate(-135deg); }
  100% {
    transform: rotate(-45deg); } }

._button_1xtmk_8.mobile-elements-panel:hover, ._button_1xtmk_8.mobile-elements-panel:active, ._button_1xtmk_8.mobile-elements-panel._hovered_1xtmk_10, ._button_1xtmk_8.mobile-elements-panel._selected_1xtmk_10, ._button_1xtmk_8.mobile-elements-panel._highlighted_1xtmk_10 {
  background: #eff5ff; }
  ._button_1xtmk_8.mobile-elements-panel:hover svg, ._button_1xtmk_8.mobile-elements-panel:active svg, ._button_1xtmk_8.mobile-elements-panel._hovered_1xtmk_10 svg, ._button_1xtmk_8.mobile-elements-panel._selected_1xtmk_10 svg, ._button_1xtmk_8.mobile-elements-panel._highlighted_1xtmk_10 svg {
    color: #569eff; }

._button_1xtmk_8.mobile-elements-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.mobile-elements-panel:not(._selected_1xtmk_10):hover .icon-animation-1 {
  transform-origin: center;
  transform-box: fill-box;
  animation: _tools-key-animation_1xtmk_1 0.333s cubic-bezier(0.65, 0, 0.35, 1); }

@keyframes _tools-key-animation_1xtmk_1 {
  0% {
    transform: rotate(0); }
  50% {
    transform: rotate(47deg); }
  100% {
    transform: rotate(0); } }

._button_1xtmk_8.pages-panel-pp .icon-transparent {
  color: #fff; }

._button_1xtmk_8.pages-panel-pp:hover, ._button_1xtmk_8.pages-panel-pp:active, ._button_1xtmk_8.pages-panel-pp._selected_1xtmk_10, ._button_1xtmk_8.pages-panel-pp._highlighted_1xtmk_10, ._button_1xtmk_8.pages-panel-pp._hovered_1xtmk_10, ._button_1xtmk_8.pages-panel-pp.tour-maker-highlight-element {
  background: #e0f4eb; }
  ._button_1xtmk_8.pages-panel-pp:hover .icon-transparent, ._button_1xtmk_8.pages-panel-pp:active .icon-transparent, ._button_1xtmk_8.pages-panel-pp._selected_1xtmk_10 .icon-transparent, ._button_1xtmk_8.pages-panel-pp._highlighted_1xtmk_10 .icon-transparent, ._button_1xtmk_8.pages-panel-pp._hovered_1xtmk_10 .icon-transparent, ._button_1xtmk_8.pages-panel-pp.tour-maker-highlight-element .icon-transparent {
    color: #e0f4eb; }
  ._button_1xtmk_8.pages-panel-pp:hover svg, ._button_1xtmk_8.pages-panel-pp:active svg, ._button_1xtmk_8.pages-panel-pp._selected_1xtmk_10 svg, ._button_1xtmk_8.pages-panel-pp._highlighted_1xtmk_10 svg, ._button_1xtmk_8.pages-panel-pp._hovered_1xtmk_10 svg, ._button_1xtmk_8.pages-panel-pp.tour-maker-highlight-element svg {
    color: #00A87E; }

._button_1xtmk_8.pages-panel-pp:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.pages-panel-pp:not(._selected_1xtmk_10):hover .icon-animation-1 {
  animation: _pages-line-1-animation_1xtmk_1 0.253s cubic-bezier(0.33, 1, 0.68, 1); }

._button_1xtmk_8.pages-panel-pp:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-2,
._button_1xtmk_8.pages-panel-pp:not(._selected_1xtmk_10):hover .icon-animation-2 {
  animation: _pages-line-2-animation_1xtmk_1 0.353s cubic-bezier(0.33, 1, 0.68, 1) 0.05s; }

@keyframes _pages-line-1-animation_1xtmk_1 {
  0% {
    transform: rotate(180deg) translate(-26px, -22px);
    width: 8px; }
  50% {
    transform: rotate(180deg) translate(-26px, -22px);
    width: 0; }
  51% {
    transform: rotate(0) translate(0, 0); }
  100% {
    transform: rotate(0) translate(0, 0);
    width: 8px; } }

@keyframes _pages-line-2-animation_1xtmk_1 {
  0% {
    transform: rotate(180deg) translate(-26px, -32px);
    width: 8px; }
  40% {
    transform: rotate(180deg) translate(-26px, -32px);
    width: 0; }
  41% {
    transform: rotate(0) translate(0, 0); }
  100% {
    transform: rotate(0) translate(0, 0);
    width: 8px; } }

._button_1xtmk_8.wix-store-panel:hover, ._button_1xtmk_8.wix-store-panel:active, ._button_1xtmk_8.wix-store-panel._hovered_1xtmk_10, ._button_1xtmk_8.wix-store-panel._selected_1xtmk_10, ._button_1xtmk_8.wix-store-panel._highlighted_1xtmk_10 {
  background: #eff5ff; }
  ._button_1xtmk_8.wix-store-panel:hover .icon-transparent, ._button_1xtmk_8.wix-store-panel:active .icon-transparent, ._button_1xtmk_8.wix-store-panel._hovered_1xtmk_10 .icon-transparent, ._button_1xtmk_8.wix-store-panel._selected_1xtmk_10 .icon-transparent, ._button_1xtmk_8.wix-store-panel._highlighted_1xtmk_10 .icon-transparent {
    color: #eff5ff; }
  ._button_1xtmk_8.wix-store-panel:hover svg, ._button_1xtmk_8.wix-store-panel:active svg, ._button_1xtmk_8.wix-store-panel._hovered_1xtmk_10 svg, ._button_1xtmk_8.wix-store-panel._selected_1xtmk_10 svg, ._button_1xtmk_8.wix-store-panel._highlighted_1xtmk_10 svg {
    color: #569eff;
    overflow: visible; }

._button_1xtmk_8.wix-store-panel .icon-transparent {
  color: #fff; }

._button_1xtmk_8.wix-store-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.wix-store-panel:not(._selected_1xtmk_10):hover .icon-animation-1 {
  animation: _stores-down-line-animation_1xtmk_1 0.334s cubic-bezier(0.76, 0, 0.24, 1); }

._button_1xtmk_8.wix-store-panel:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-2,
._button_1xtmk_8.wix-store-panel:not(._selected_1xtmk_10):hover .icon-animation-2 {
  transform-origin: top;
  transform-box: fill-box;
  animation: _stores-bag-animation_1xtmk_1 0.383s cubic-bezier(0.76, 0, 0.24, 1); }

@keyframes _stores-down-line-animation_1xtmk_1 {
  0% {
    transform: rotate(180deg) translate(-18.7px, -10px);
    width: 6px; }
  50% {
    transform: rotate(180deg) translate(-19px, -10px);
    width: 0;
    transition: transform cubic-bezier(0.32, 0, 0.67, 0); }
  51% {
    transform: rotate(0) translate(0, 0); }
  100% {
    transform: rotate(0) translate(0, 0);
    width: 6px;
    transition: transform cubic-bezier(0.33, 1, 0.68, 1); } }

@keyframes _stores-bag-animation_1xtmk_1 {
  0% {
    transform: rotate(0) translate(0.66px, 5px);
    transition: transform cubic-bezier(0.32, 0, 0.67, 0); }
  20% {
    transform: rotate(-10deg) translate(0.66px, 5px);
    transition: transform cubic-bezier(0.32, 0, 0.67, 0); }
  75% {
    transform: rotate(3deg) translate(0.66px, 5px);
    transition: transform cubic-bezier(0.65, 0, 0.35, 1); }
  100% {
    transform: rotate(0) translate(0.66px, 5px);
    transition: transform cubic-bezier(0.65, 0, 0.35, 1); } }

._button_1xtmk_8.design-panel-button .icon-transparent {
  color: #fff;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1); }

._button_1xtmk_8.design-panel-button:hover, ._button_1xtmk_8.design-panel-button:active, ._button_1xtmk_8.design-panel-button._hovered_1xtmk_10, ._button_1xtmk_8.design-panel-button._selected_1xtmk_10, ._button_1xtmk_8.design-panel-button._highlighted_1xtmk_10, ._button_1xtmk_8.design-panel-button.tour-maker-highlight-element {
  background: #ffebcc; }
  ._button_1xtmk_8.design-panel-button:hover .icon-transparent, ._button_1xtmk_8.design-panel-button:active .icon-transparent, ._button_1xtmk_8.design-panel-button._hovered_1xtmk_10 .icon-transparent, ._button_1xtmk_8.design-panel-button._selected_1xtmk_10 .icon-transparent, ._button_1xtmk_8.design-panel-button._highlighted_1xtmk_10 .icon-transparent, ._button_1xtmk_8.design-panel-button.tour-maker-highlight-element .icon-transparent {
    color: #ffebcc; }
  ._button_1xtmk_8.design-panel-button:hover svg, ._button_1xtmk_8.design-panel-button:active svg, ._button_1xtmk_8.design-panel-button._hovered_1xtmk_10 svg, ._button_1xtmk_8.design-panel-button._selected_1xtmk_10 svg, ._button_1xtmk_8.design-panel-button._highlighted_1xtmk_10 svg, ._button_1xtmk_8.design-panel-button.tour-maker-highlight-element svg {
    color: #ff9900; }

._button_1xtmk_8.design-panel-button:not(._selected_1xtmk_10)._hovered_1xtmk_10 .icon-animation-1,
._button_1xtmk_8.design-panel-button:not(._selected_1xtmk_10):hover .icon-animation-1 {
  animation: _fade-out-drop-animation_1xtmk_1 0.085s linear, _theme-drop-animation_1xtmk_1 0.27s cubic-bezier(0.33, 1, 0.68, 1) 0.085s; }

@keyframes _fade-out-drop-animation_1xtmk_1 {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes _theme-drop-animation_1xtmk_1 {
  0% {
    transform: translate(17px, 5px) scale(0.0001); }
  100% {
    transform: translate(0, 0) scale(1); } }

._button_1xtmk_8.more-options {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center; }

._button_1xtmk_8.more-options:hover, ._button_1xtmk_8.more-options:active, ._button_1xtmk_8.more-options._hovered_1xtmk_10, ._button_1xtmk_8.more-options._selected_1xtmk_10, ._button_1xtmk_8.more-options._highlighted_1xtmk_10, ._button_1xtmk_8.more-options.tour-maker-highlight-element {
  background-color: #d6e6ff; }
  ._button_1xtmk_8.more-options:hover svg, ._button_1xtmk_8.more-options:active svg, ._button_1xtmk_8.more-options._hovered_1xtmk_10 svg, ._button_1xtmk_8.more-options._selected_1xtmk_10 svg, ._button_1xtmk_8.more-options._highlighted_1xtmk_10 svg, ._button_1xtmk_8.more-options.tour-maker-highlight-element svg {
    color: #146dff;
    overflow: unset; }

._button_1xtmk_8.more-options:not(._selected_1xtmk_10) ellipse {
  transform-origin: center;
  transform-box: fill-box;
  transform: translate(0px, 0px); }

._button_1xtmk_8.more-options:not(._selected_1xtmk_10) ._dot-4_1xtmk_923 {
  opacity: 0;
  transform: translate(-3px, 0px);
  -ms-transform: translate(-3px, 0px);
  -webkit-transform: translate(-3px, 0px); }

._button_1xtmk_8.more-options :not(._selected_1xtmk_10):hover .dot-1, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10):active .dot-1, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10)._hovered_1xtmk_10 .dot-1, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10)._highlighted_1xtmk_10 .dot-1, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10).tour-maker-highlight-element .dot-1 {
  animation: _more-dot-animation_1xtmk_1 0.283s cubic-bezier(0.33, 1, 0.68, 1); }

._button_1xtmk_8.more-options :not(._selected_1xtmk_10):hover .dot-2, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10):active .dot-2, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10)._hovered_1xtmk_10 .dot-2, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10)._highlighted_1xtmk_10 .dot-2, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10).tour-maker-highlight-element .dot-2 {
  animation: _more-dot-animation_1xtmk_1 0.283s cubic-bezier(0.33, 1, 0.68, 1); }

._button_1xtmk_8.more-options :not(._selected_1xtmk_10):hover .dot-3, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10):active .dot-3, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10)._hovered_1xtmk_10 .dot-3, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10)._highlighted_1xtmk_10 .dot-3, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10).tour-maker-highlight-element .dot-3 {
  animation: _more-dot-3-animation_1xtmk_1 0.283s cubic-bezier(0.33, 1, 0.68, 1); }

._button_1xtmk_8.more-options :not(._selected_1xtmk_10):hover .dot-4, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10):active .dot-4, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10)._hovered_1xtmk_10 .dot-4, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10)._highlighted_1xtmk_10 .dot-4, ._button_1xtmk_8.more-options :not(._selected_1xtmk_10).tour-maker-highlight-element .dot-4 {
  animation: _more-dot-4-animation_1xtmk_1 0.233s linear 0.05s; }

@keyframes _more-dot-animation_1xtmk_1 {
  0% {
    transform: translate(0px, 0px); }
  100% {
    transform: translate(8.5px, 0px); } }

@keyframes _more-dot-3-animation_1xtmk_1 {
  0% {
    transform: translate(0px, 0px);
    opacity: 1; }
  15% {
    transform: translate(3px, 0px);
    opacity: 0; }
  100% {
    transform: translate(3px, 0px);
    opacity: 0; } }

@keyframes _more-dot-4-animation_1xtmk_1 {
  0% {
    transform: translate(0px, 0px);
    opacity: 0; }
  60% {
    transform: translate(0px, 0px);
    opacity: 1; }
  100% {
    transform: translate(0px, 0px);
    opacity: 1; } }

._wrapper_1xtmk_969 {
  position: relative; }

._button_1xtmk_8 {
  position: relative;
  display: flex;
  line-height: 42px;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  ._button_1xtmk_8._hovered_1xtmk_10, ._button_1xtmk_8._selected_1xtmk_10, ._button_1xtmk_8:active, ._button_1xtmk_8:hover {
    background-color: #dfe5eb; }
  ._button_1xtmk_8:disabled > * {
    color: #bec2ca; }
  ._button_1xtmk_8:not(._selected_1xtmk_10):hover + ._label-wrapper_1xtmk_986,
  ._button_1xtmk_8:not(._selected_1xtmk_10):hover + ._label_1xtmk_986 {
    opacity: 1; }
  ._button_1xtmk_8._highlighted_1xtmk_10:not(._selected_1xtmk_10) + ._label-wrapper_1xtmk_986,
  ._button_1xtmk_8._highlighted_1xtmk_10:not(._selected_1xtmk_10) + ._label_1xtmk_986 {
    opacity: 1;
    pointer-events: auto; }
  ._button_1xtmk_8._highlighted_1xtmk_10:not(._selected_1xtmk_10) + ._label-wrapper_1xtmk_986._interactive_1xtmk_993,
  ._button_1xtmk_8._highlighted_1xtmk_10:not(._selected_1xtmk_10) + ._label_1xtmk_986._interactive_1xtmk_993 {
    transition: transform 0.2s; }
    ._button_1xtmk_8._highlighted_1xtmk_10:not(._selected_1xtmk_10) + ._label-wrapper_1xtmk_986._interactive_1xtmk_993,
    ._button_1xtmk_8._highlighted_1xtmk_10:not(._selected_1xtmk_10) + ._label-wrapper_1xtmk_986._interactive_1xtmk_993 > *,
    ._button_1xtmk_8._highlighted_1xtmk_10:not(._selected_1xtmk_10) + ._label_1xtmk_986._interactive_1xtmk_993,
    ._button_1xtmk_8._highlighted_1xtmk_10:not(._selected_1xtmk_10) + ._label_1xtmk_986._interactive_1xtmk_993 > * {
      cursor: pointer; }
  ._button_1xtmk_8._highlighted_1xtmk_10:not(._selected_1xtmk_10):hover + ._label-wrapper_1xtmk_986._interactive_1xtmk_993,
  ._button_1xtmk_8._highlighted_1xtmk_10:not(._selected_1xtmk_10):hover + ._label_1xtmk_986._interactive_1xtmk_993,
  ._button_1xtmk_8._highlighted_1xtmk_10:not(._selected_1xtmk_10)._hovered_1xtmk_10 + ._label-wrapper_1xtmk_986._interactive_1xtmk_993,
  ._button_1xtmk_8._highlighted_1xtmk_10:not(._selected_1xtmk_10)._hovered_1xtmk_10 + ._label_1xtmk_986._interactive_1xtmk_993 {
    transform: translateX(6px); }
  ._button_1xtmk_8 svg {
    color: #2d3034; }

._notifications_1xtmk_1009 {
  position: absolute;
  top: -4px;
  right: -4px;
  background: #e62214;
  color: #fff;
  font-size: 11px;
  line-height: 16px;
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  display: block;
  text-align: center;
  width: 16px;
  height: 16px;
  border-radius: 50%; }

._label-wrapper_1xtmk_986 {
  position: absolute;
  top: 50%;
  left: 100%;
  margin: -25px 0 0 21px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: block;
  max-width: none;
  white-space: nowrap; }

._label_1xtmk_986 {
  position: absolute;
  top: 50%;
  left: 100%;
  border-radius: 4px;
  letter-spacing: 0.4px;
  color: #000624;
  margin: -19px 0 0 21px;
  padding: 10px 15px;
  background-color: #fff;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: block;
  max-width: none;
  white-space: nowrap; }
