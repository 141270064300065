import React from 'react';
import { InfoIcon } from '@wix/wix-base-ui';
import { useTranslation } from 'react-i18next';
import constants from '@/constants';
import { StageSideAreaBorder } from '@/sections';
import { hoc } from '@/util';
import { withRestrictions } from '@/editorRestrictions';

import type {
  SiteSegmentIndicationDispatchProps,
  SiteSegmentIndicationStateProps,
} from './SiteSegmentIndicationMapper';
import {
  mapDispatchToProps,
  mapStateToProps,
} from './SiteSegmentIndicationMapper';
import { shouldShowSegmentIndication } from '../selectors';
import {
  AUTOMATION_IDS,
  SegmentIndicationAreaKeys,
  SegmentType,
} from './SiteSegmentIndication.constants';

import styles from './SiteSegmentIndication.scss';

export interface SiteSegmentIndicationOwnProps {
  type: SegmentType;
}

interface SiteSegmentIndicationProps
  extends SiteSegmentIndicationOwnProps,
    SiteSegmentIndicationStateProps,
    SiteSegmentIndicationDispatchProps {}

const SiteSegmentComp: React.FC<SiteSegmentIndicationProps> = ({
  type,
  areas,
  segmentRef,
  isHovered,
  isMobile,
  isStageZoomMode,
  selectSiteSegment,
  setHoveredSegment,
  clearHoveredSegment,
  shouldRenderStageSideAreaBorder,
}) => {
  const [translation] = useTranslation();

  const isHeader = type === SegmentType.Header;
  const tooltipContent = translation(
    isHeader ? 'ZoomOut_Header_Tooltip' : 'ZoomOut_Footer_Tooltip',
  );

  return (
    <>
      {areas.map((area) => {
        const isRightArea = area.key === SegmentIndicationAreaKeys.Right;

        return (
          <div
            key={area.key}
            className={styles.area}
            style={{
              top: area.top,
              left: area.left,
              width: area.width,
              height: area.height,
            }}
            onMouseEnter={() => setHoveredSegment(segmentRef)}
            onMouseLeave={() => clearHoveredSegment()}
            onClick={() => selectSiteSegment(segmentRef)}
            data-hook={AUTOMATION_IDS.getAreaHook(area.key)}
          >
            {isHovered && (
              <>
                {isRightArea && (
                  <div className={styles.content}>
                    <InfoIcon
                      text={tooltipContent}
                      tooltipMarginRight={6}
                      shouldTranslate={false}
                      className={styles.infoIcon}
                      // @ts-expect-error
                      alignment={constants.UI.TOOLTIP.ALIGNMENT.RIGHT.toUpperCase()}
                    />
                  </div>
                )}

                {shouldRenderStageSideAreaBorder && (
                  <StageSideAreaBorder
                    position={isRightArea ? 'right' : 'left'}
                    isMobileEditor={isMobile}
                    isZoomOut={isStageZoomMode}
                    style={{
                      position: 'absolute',
                      [isHeader ? 'bottom' : 'top']: '-1px',
                      [isRightArea ? 'left' : 'right']: 0,
                    }}
                  />
                )}
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

const ConnectedSiteSegmentIndication = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(SiteSegmentComp);

const SiteSegmentIndication = withRestrictions(
  'zoom-mode_site-segment-indication',
)(
  hoc.withConditionalRender(
    hoc.STORES.EDITOR_API,
    shouldShowSegmentIndication,
  )(ConnectedSiteSegmentIndication),
);

export const SiteSegmentIndicationHeader = () => (
  <SiteSegmentIndication type={SegmentType.Header} />
);

export const SiteSegmentIndicationFooter = () => (
  <SiteSegmentIndication type={SegmentType.Footer} />
);
