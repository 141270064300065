import {
  sections as sectionsUtils,
  fedopsLogger,
  biLogger,
  animations as animationsUtils,
} from '@/util';
import constants from '@/constants';
import { AnimationsApiKey } from '@/apis';
import { sectionRenameSectionClick } from '@wix/bi-logger-editor/v2';
import { isMeshLayoutEnabled } from '@/layoutOneDockMigration';
import { isRefComponent } from '@/documentServices';

import { scrollToComponentIfHigherThanViewport } from './scrollToComponent';
import { getClosestSectionLike, isSection, isSectionLike } from '../api';

import type { Scope } from '../scope';
import type { EditorAPI } from '@/editorAPI';
import type {
  CompRef,
  CompLayout,
  RelativePosition,
} from 'types/documentServices';

const compWithLayoutMapper = (
  editorAPI: EditorAPI,
  ref: CompRef,
  useRelativeToStructureLayout = false,
) => ({
  ref,
  layout: useRelativeToStructureLayout
    ? editorAPI.components.layout.getRelativeToStructure(ref)
    : editorAPI.components.layout.get(ref),
});

const openRenamePanelInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.RENAME_PANEL.OPEN,
);

const openSectionNamePanel = (
  editorAPI: EditorAPI,
  sectionRef: CompRef,
  biParams: { origin: string },
  clickPosition?: RelativePosition,
) => {
  const isSectionSelected =
    editorAPI.selection.getSelectedComponentId() === sectionRef.id;

  if (!isSectionSelected) {
    editorAPI.selection.selectComponentByCompRef(sectionRef, {
      origin: 'after_action',
    });
  }

  const panelPosition = sectionsUtils.getCompPanelOpenPosition(editorAPI, {
    clickPosition,
  });

  biLogger.report(
    sectionRenameSectionClick({
      origin: biParams.origin,
      section_name: editorAPI.sections.getName(sectionRef),
      component_id: sectionRef.id,
    }),
  );

  editorAPI.panelManager.openComponentPanel(
    'compPanels.panels.ClassicSection.settingsPanel',
    {
      selectedComponents: [sectionRef],
      style: panelPosition,
      origin: biParams.origin,
      openPanelInteraction: openRenamePanelInteraction,
    },
  );
};

const openSiteSegmentSettingsPanel = (
  editorAPI: EditorAPI,
  siteSegmentRef: CompRef,
  biParams: { origin: string },
  clickPosition?: RelativePosition,
) => {
  if (
    !editorAPI.sections.isSectionLike(siteSegmentRef) ||
    editorAPI.sections.isSection(siteSegmentRef)
  )
    return;

  const isSiteSegmentSelected =
    editorAPI.selection.getSelectedComponentId() === siteSegmentRef.id;

  if (!isSiteSegmentSelected) {
    editorAPI.selection.selectComponentByCompRef(siteSegmentRef, {
      origin: 'after_action',
    });
  }

  const panelPosition = sectionsUtils.getCompPanelOpenPosition(editorAPI, {
    clickPosition,
  });

  const panelNamesMap = {
    [constants.COMP_TYPES.HEADER]:
      'compPanels.panels.HeaderContainer.settingsPanel',
    [constants.COMP_TYPES.FOOTER]:
      'compPanels.panels.FooterContainer.settingsPanel',
  };

  const panelName =
    panelNamesMap[
      editorAPI.components.getType(siteSegmentRef) as keyof typeof panelNamesMap
    ];

  editorAPI.panelManager.openComponentPanel(panelName, {
    selectedComponents: [siteSegmentRef],
    style: panelPosition,
    origin: biParams.origin,
    // openPanelInteraction: openRenamePanelInteraction,
  });
};

const openSiteSegmentAnimationPanel = (
  editorAPI: EditorAPI,
  siteSegmentRef: CompRef,
  biParams: { origin: string },
) => {
  if (
    !editorAPI.sections.isSectionLike(siteSegmentRef) ||
    editorAPI.sections.isSection(siteSegmentRef)
  )
    return;

  const isSiteSegmentSelected =
    editorAPI.selection.getSelectedComponentId() === siteSegmentRef.id;

  if (!isSiteSegmentSelected) {
    editorAPI.selection.selectComponentByCompRef(siteSegmentRef, {
      origin: 'after_action',
    });
  }

  if (animationsUtils.isNewAnimationsEnabled(editorAPI)) {
    editorAPI.host.getAPI(AnimationsApiKey).toggleAnimationPanel({
      origin: biParams.origin,
    });
    return;
  }

  editorAPI.panelManager.openComponentPanel(
    'compPanels.dynamicPanels.animationPanel',
    {
      selectedComponents: [siteSegmentRef],
      origin: biParams.origin,
      dataOverridesKey: 'Containers',
    },
  );
};

const BIG_ELEMENT_Y_MARGIN = 10;

function getRelativeToParentFromDOM(
  componentsAPI: Scope['components'],
  compRef: CompRef,
): CompLayout {
  const containerRef = componentsAPI.getContainer(compRef);

  const parentDOMLayout =
    componentsAPI.layout.getRelativeToScreen(containerRef);
  const compDOMLayout = componentsAPI.layout.getRelativeToScreen(compRef);

  compDOMLayout.x -= parentDOMLayout.x;
  compDOMLayout.y -= parentDOMLayout.y;

  return compDOMLayout;
}

const applyBigElementsLogicIfNeeded = async (
  scope: Scope,
  compRef: CompRef,
  shouldApplyMargins: boolean,
) => {
  const { components, editorAPI } = scope;
  const containerRef = components.getContainer(compRef);

  const isSectionLikeChild = isSectionLike(scope, containerRef);

  if (!isSectionLikeChild) return;

  const shoulReadLayoutFromDOM =
    // NOTE: under `isMeshLayoutEnabled` rect is already reading from DOM
    !isMeshLayoutEnabled() && isRefComponent(editorAPI.dsRead, compRef);

  let compLayoutRelativeToParent;
  if (shoulReadLayoutFromDOM) {
    await editorAPI.waitForChangesAppliedAsync();
    compLayoutRelativeToParent = getRelativeToParentFromDOM(
      components,
      compRef,
    );
  } else {
    compLayoutRelativeToParent = editorAPI.components.layout.get_rect(compRef);
  }

  const { height: sectionHeight } = components.layout.get_size(containerRef);

  const isBigElement = compLayoutRelativeToParent.height >= sectionHeight;
  if (!isBigElement) return;

  const bigElementMargin = shouldApplyMargins ? BIG_ELEMENT_Y_MARGIN : 0;

  const compY = Math.max(bigElementMargin, compLayoutRelativeToParent.y);

  components.layout.resizeToAndPush(
    containerRef,
    {
      height: compY + compLayoutRelativeToParent.height + bigElementMargin,
    },
    {
      dontAddToUndoRedoStack: true,
    },
  );

  await editorAPI.waitForChangesAppliedAsync();

  components.layout.update(
    compRef,
    {
      y: compY,
    },
    true,
  );

  await editorAPI.waitForChangesAppliedAsync();

  scrollToComponentIfHigherThanViewport(editorAPI, compRef);
};

const getClosestSectionLikeForFWE = (scope: Scope, compRefs: CompRef[]) => {
  if (compRefs.length > 1) return;

  const { editorAPI } = scope;
  const [compRef] = compRefs;

  if (isSection(scope, compRef) || !editorAPI.components.is.fullWidth(compRef))
    return;

  return getClosestSectionLike(scope, compRef);
};

export {
  compWithLayoutMapper,
  openSectionNamePanel,
  openSiteSegmentSettingsPanel,
  openSiteSegmentAnimationPanel,
  applyBigElementsLogicIfNeeded,
  getClosestSectionLikeForFWE,
};
