import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

function openDeprecationPanel(editorAPI: EditorAPI, compRef: CompRef): void {
  const compType = editorAPI.components.getType(compRef);
  if (editorAPI.panelManager.hasDeprecationPanel(editorAPI, compType)) {
    if (editorAPI.panelManager.shouldOpenDeprecationPanel(editorAPI, compRef)) {
      editorAPI.panelManager.openDeprecationPanel(compType);
      return;
    }
    editorAPI.panelManager.closePanelByName(
      'compPanels.dynamicPanels.deprecationPanel',
    );
    return;
  }
}

export { openDeprecationPanel };
