import { BEFORE_SECTIONS_MIGRATION_HISTORY_LABEL } from '../constants';

import type { SectionsMigrationScope as Scope } from '../scope';

export const revertMigrationTransaction = async (
  scope: Scope,
): Promise<{
  undoLabelBeforeRevert: string;
  undoLabelAfterRevert: string;
}> => {
  const { documentServices, history: historyAPI } = scope.editorAPI;

  const undoLabelBeforeRevert = historyAPI.getUndoLastSnapshotLabel();

  // revert all document changes to initial snapshot
  await historyAPI.performUndoUntilLabel(
    BEFORE_SECTIONS_MIGRATION_HISTORY_LABEL,
    true,
  );

  await documentServices.waitForChangesAppliedAsync();

  const undoLabelAfterRevert = historyAPI.getUndoLastSnapshotLabel();

  historyAPI.clear();

  return {
    undoLabelBeforeRevert,
    undoLabelAfterRevert,
  };
};
