// @ts-nocheck
import _ from 'lodash';
import * as addPanel from '@/oldAddPanel';
import section1 from './live/section1';
import section2 from './live/section2';
import wixFormsSections from './wixForms/wixFormsSections';
function getContactFormLiveSections(isDynamicContactForm) {
  let contactFormToRemove = 'wysiwyg.viewer.components.DynamicContactForm';
  if (isDynamicContactForm) {
    contactFormToRemove = 'wysiwyg.viewer.components.ContactForm';
  }
  const removeContactFormType = function (value) {
    return value === contactFormToRemove;
  };
  const sections = { section_1: section1, section_2: section2 };
  _.remove(sections.section_1.props.compTypes, removeContactFormType);
  _.remove(sections.section_2.props.compTypes, removeContactFormType);
  return sections;
}

function getAllContactSections(isDynamicContactForm) {
  const allContactFormSections = addPanel.sections.getSections().contact;
  if (isDynamicContactForm) {
    allContactFormSections.contactFormsSection = _.merge(
      {},
      allContactFormSections.dynamicContactFormsSection,
    );
  }
  return _.omit(allContactFormSections, 'dynamicContactFormsSection');
}

export default {
  getCategorySections(editorAPI) {
    const isDynamicContactForm =
      editorAPI.dsRead.documentMode.isContactFormMigrationMode();
    const contactFormSections = _.merge(
      {},
      getAllContactSections(isDynamicContactForm),
      wixFormsSections(editorAPI),
    );
    const liveSections = getContactFormLiveSections(isDynamicContactForm);
    return addPanel.dataUtil.mergeWithCommonSections(
      contactFormSections,
      liveSections,
    );
  },
};
