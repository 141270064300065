import { collectComponents } from './collectComponents';
import { extractSiteData } from './extractSiteData';
import { extractDomData } from './extractDomData';

import type {
  DocumentServicesObject,
  CompRef,
} from '@wix/document-services-types';

async function getPageData(
  documentServices: DocumentServicesObject,
  pageId: string,
  shouldCollectOnlyRoot: boolean = false,
): Promise<any> {
  const componentsList = collectComponents(
    documentServices,
    pageId,
    shouldCollectOnlyRoot,
  );
  const siteData = extractSiteData(documentServices, pageId, componentsList);
  const domData = await extractDomData(documentServices, componentsList);

  return {
    siteData,
    domData,
  };
}

async function getInputDataForTextClassificationPrediction(
  documentServices: DocumentServicesObject,
  pageId: string,
  compRef: CompRef,
): Promise<any> {
  const componentsList = documentServices.components.getChildren(compRef, true);
  const siteData = extractSiteData(documentServices, pageId, componentsList);
  const domData = await extractDomData(documentServices, componentsList);

  return {
    siteData,
    domData,
  };
}

function getCurrentPageData(
  documentServices: DocumentServicesObject,
  shouldCollectOnlyRoot: boolean = false,
) {
  return getPageData(
    documentServices,
    documentServices.pages.getFocusedPageId(),
    shouldCollectOnlyRoot,
  );
}

export {
  getPageData,
  getCurrentPageData,
  getInputDataForTextClassificationPrediction,
};
