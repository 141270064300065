import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import constants from '../constants';

const getBiBaseParams = ({ state, editorAPI }: AnyFixMe) => {
  const isPremium = !!stateManagement.ascend.selectors.getPremiumPlan(state);
  const ascendProductId =
    stateManagement.ascend.selectors.getAscendProductId(state);

  const isPublished = _.invoke(editorAPI, 'dsRead.generalInfo.isSitePublished');
  const msid = editorAPI.dsRead.generalInfo.getMetaSiteId();

  return {
    msid,
    ascend_package:
      constants.MAP_PRODUCT_ID_TO_ASCEND_PLAN[ascendProductId] ||
      'no-ascend-premium',
    is_premium: isPremium,
    is_publish: isPublished,
  };
};

export default {
  getBiBaseParams,
};
