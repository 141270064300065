import { httpDispatcher } from './httpDispatcher';

const METASITE_APP_ID = -666;

export const fetchAppReadme = httpDispatcher<[{ id: string; version: string }]>(
  ({ editorAPI, baseUrl }, { id, version }) => ({
    buildUrl: () => `${baseUrl}/packages/${id}/readmeV2?semver=${version}`,
    buildSiHeader: () =>
      editorAPI.dsRead.platform.getAppDataByApplicationId(METASITE_APP_ID)
        .instance,
  }),
);
