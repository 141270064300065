import * as stateManagement from '@/stateManagement';
import type { StateMapperArgs, ThunkAction } from 'types/redux';

const { getSingleSelectedComponent } = stateManagement.selection.selectors;
const domainSelectors = stateManagement.domain.selectors;

const mapStateToProps = ({ editorAPI, dsRead }: StateMapperArgs) => {
  const { store } = editorAPI;
  const editorState = store.getState();
  const editedComponent = getSingleSelectedComponent(editorState);
  const siteProtocol = domainSelectors.getSiteProtocol(
    editorState,
    editorAPI.dsRead,
  );
  const isDomainConnected = domainSelectors.isDomainConnected(dsRead);
  const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
  const rcPublicUrl =
    editorAPI.dsRead.generalInfo.getCurrentRevisionPublicUrl();

  return {
    editedComponent,
    metaSiteId,
    siteProtocol,
    bi: editorAPI.bi,
    isDomainConnected,
    panelManager: editorAPI.panelManager,
    rcPublicUrl,
  };
};

const onClose: ThunkAction = (dispatch, getState, { editorAPI }) => {
  editorAPI.developerToolBar.toggle();
};

const mapDispatchToProps = (dispatch: AnyFixMe) => ({
  onClose() {
    dispatch(onClose);
  },

  openHelpCenter(helpId: AnyFixMe, props: AnyFixMe, biHelpParams: AnyFixMe) {
    dispatch(
      stateManagement.panels.actions.openHelpCenter(
        helpId,
        props,
        biHelpParams,
      ),
    );
  },
});

export { mapStateToProps, mapDispatchToProps };
