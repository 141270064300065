import type { Scope } from './workspaceRightPanelEntryPoint';

export const getCurrentDrillInUniqueIdFromContentSlot = (scope: Scope) => {
  const drillInContents = scope.drillInContentSlot.getItems(true);
  return drillInContents[drillInContents.length - 1].uniqueId;
};

export const getDrillInConditionFunction = (
  scope: Scope,
  drillInIndex: number,
) => {
  return () => {
    const uniqueId =
      scope.drillInContentSlot.getItems(true)[drillInIndex].uniqueId;
    return scope.store.getContributedDrillInPanels()[uniqueId]?.isOpen;
  };
};
