export interface PageAction {
  title: string;
  onClick: Function;
  symbolName?: string;
  symbol?: React.FC<any>;
  isRename?: boolean;
  isSettings?: boolean;
  type?: string;
  disabled?: boolean;
  disabledText?: string;
}

export interface PageItem {
  id: string;
  title: string;
  info?: string;
  actions?: PageAction[];
  typeSymbol?: string;
  permissionSymbol?: string;
}

export type DynamicPage = Pick<
  PageItem,
  'id' | 'title' | 'permissionSymbol' | 'typeSymbol'
>;
