import type { EntryPoint } from '@/apilib';
import { CampaignsApiKey, EditorAPIKey } from '@/apis';
import { createCampaignsApi } from './campaignsApi';

export const CampaignsApiEntryPoint: EntryPoint = {
  name: 'CampaignsApi',
  declareAPIs: () => [CampaignsApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(CampaignsApiKey, () => createCampaignsApi(shell));
  },
};
