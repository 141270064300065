import TextItem from './components/textItem';
import HoverBoxItem from './components/hoverBoxItem';
import ButtonDesignEntryPoint from './components/buttonDesignEntryPoint';
import constants from '@/constants';
import { fonts } from '@wix/santa-editor-utils';
import { QuickEditControlActions } from '@wix/editor-elements-types/quickEditControls';
import { constants as textControlsConstants } from '@/textControls';

import type { ComponentValueRecord } from '@/quickEditEngine';
import type { ComponentType } from 'react';
import type { QuickEditControlOwnProps } from '@wix/editor-elements-types/quickEditControls';

export const {
  ALIGNMENT_TYPES,
  CK_DISABLED,
  TEXT_LIST_TYPES,
  TEXT_LIST_TYPES_RTL,
} = textControlsConstants;

export const quickEditPanelOrigin = 'quickEditPanel';
export const QUICK_EDIT_PANEL_WIDTH = 288;

export const NUMBER_OF_FIRST_LEVEL_DRILL_IN_PANELS = 2;

export const TEXT_EDITOR_OVERRIDE_STYLE = `
@import url('${fonts.getMadeForFontUrl(
  window.serviceTopology.scriptsDomainUrl,
)}');
}body {
    overflow: hidden !important;
}
* {
    font-size: 16px !important;
    font-family: Madefor, HelveticaNeue, sans-serif !important;
    text-shadow: none !important;
    color: #000624 !important;
    line-height: 24px !important;
    letter-spacing: normal !important;
}`;

export const QUICK_EDIT_PANEL_HELP_ID = '6e2524c2-74e4-41ef-8390-8f3eb1d657b4';

export const CONTENT_PERMISSION_ZOOM_MODE_SCALE = 0.75;

export const CKEDITOR_SELECTOR = '.cke_contents';
export const BOLD = 'bold';
export const ITALIC = 'italic';
export const UNDERLINE = 'underline';
export const LTR = 'bidiltr';
export const RTL = 'bidirtl';

export const SETTINGS_CTX = 'settings';
export const RENAME_CTX = 'rename';
export const HELP_CTX = 'help';

export const NULL_VALUE_RECORD: ComponentValueRecord = {
  initial: null,
  last: null,
};

export const COMMANDS_WITH_BI_TARGET: string[] = [
  TEXT_LIST_TYPES.textListNone,
  TEXT_LIST_TYPES.textListNumbers,
  TEXT_LIST_TYPES.textListBullets,
  ALIGNMENT_TYPES.textAlignLeft,
  ALIGNMENT_TYPES.textAlignCenter,
  ALIGNMENT_TYPES.textAlignRight,
  ALIGNMENT_TYPES.textAlignBlock,
];

export interface DesignEntryPointOwnProps {
  compId: string;
}

const {
  TEXT,
  FOOTER,
  HEADER,
  SECTION,
  CONTAINER,
  SITE_BUTTON,
  STYLABLE_BUTTON,
  POPUP_CONTAINER,
} = constants.COMP_TYPES;

export const ALLOWED_ROOT_QUICK_EDIT_COMPONENTS = new Set<string>([
  HEADER,
  FOOTER,
  SECTION,
  POPUP_CONTAINER,
]);

export const overrideCompControls: {
  [compType: string]: ComponentType<QuickEditControlOwnProps>;
} = {
  [TEXT]: TextItem,
  [CONTAINER]: HoverBoxItem,
};

export const drillInActionMap: {
  [compType: string]: ComponentType<DesignEntryPointOwnProps>;
} = {
  [SITE_BUTTON]: ButtonDesignEntryPoint,
  [STYLABLE_BUTTON]: ButtonDesignEntryPoint,
};

export const FULL_HEIGHT_INNER_PANELS = new Set<QuickEditControlActions>([
  QuickEditControlActions.BACKGROUND,
]);

export const TEXT_DATA_HOOKS = {
  PLACEHOLDER: 'text-quick-edit-control-placeholder',
  TEXT_EDITOR: 'text-quick-edit-control-text-editor',
  BOLD_BUTTON: 'text-quick-edit-control-bold-button',
  ITALIC_BUTTON: 'text-quick-edit-control-italic-button',
  UNDERLINE_BUTTON: 'text-quick-edit-control-underline-button',
  LINK_BUTTON: 'text-quick-edit-control-link-button',
  TEXT_DIRECTION_BUTTON: 'text-quick-edit-control-direction-button',
};

export const HOVER_BOX_DATA_HOOKS = {
  TOGGLE_DROPDOWN: 'hoverbox-quick-edit-control-toggle-dropdown',
  REGULAR_MODE: 'hoverbox-quick-edit-control-regular-option',
  HOVER_MODE: 'hoverbox-quick-edit-control-hover-option',
};

export const QUICK_EDIT_COMPS_WITH_INTERACTIONS = new Set<string>([CONTAINER]);
