:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_1m08b_7 {
  display: flex;
  align-items: center;
  padding: 18px 24px;
  height: 60px;
  box-sizing: border-box;
  cursor: pointer; }
  ._container_1m08b_7:hover {
    background: #ebf2ff; }

._illustration_1m08b_17 {
  flex: none;
  margin-right: 18px; }

._illustrationIcon_1m08b_21 {
  fill: #3b4057; }
  ._illustrationIcon_1m08b_21 * {
    fill: #3b4057; }

._illustrationImage_1m08b_26 {
  width: 20px;
  height: 20px; }

._description_1m08b_30 {
  min-width: 0; }

._action_1m08b_33 {
  flex: none;
  margin-left: auto; }
