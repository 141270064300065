import { getComponentDefinedScopesAndScopesList } from '@/documentServices';
import { functionUtils } from '@/util';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { SelectionStore } from './createSelectionStore';

export function createSelectionScopeApi({
  selectionStore,
  editorAPI,
}: {
  selectionStore: SelectionStore;
  editorAPI: EditorAPI;
}) {
  function getSelectedComponentsScopes() {
    return selectionStore
      .getSelectedComponents()
      .flatMap((compRef) =>
        getComponentDefinedScopesAndScopesList(editorAPI.dsRead, compRef),
      );
  }

  const isScopeOwnerEqual = (
    scopeOwnerRef1: CompRef,
    scopeOwnerRef2: CompRef,
  ) => editorAPI.dsRead.utils.isSameRef(scopeOwnerRef1, scopeOwnerRef2);

  const isScopeOwnerContains = (
    scopeOwnerRef: CompRef,
    possibleDescendantRef: CompRef,
  ) =>
    !!editorAPI.components.findScopeOwner(possibleDescendantRef, (scopeOwner) =>
      editorAPI.dsRead.utils.isSameRef(scopeOwner, scopeOwnerRef),
    );

  const isComponentScopeSelectedInnerCached = functionUtils.memoizeOnce(
    isComponentScopeSelectedInner,
    {
      getArgsKey: (selectedComponents, compRef: CompRef) =>
        `${compRef.id}-${compRef.type}|${selectedComponents
          .map((compRef) => compRef.id)
          .join(';')}`,
    },
  );

  function isComponentScopeSelectedInner(
    selectedComponents: CompRef[],
    compRef: CompRef,
  ): boolean {
    const compScopeOwnerRef = editorAPI.components.findScopeOwner(
      compRef,
      (scopeOwnerRef) => editorAPI.components.is.selectable(scopeOwnerRef),
    );

    if (!compScopeOwnerRef) {
      // NOTE:
      // if component is not scoped or
      // if component dees not have selectable scope owner,
      // consider component scope is selected
      return true;
    }

    // NOTE:
    // scope is selected if:
    // first selectable scope owner is selected
    // first selectable scope owner contains selected components
    return selectedComponents.some(
      (selectedCompRef) =>
        isScopeOwnerEqual(compScopeOwnerRef, selectedCompRef) ||
        isScopeOwnerContains(compScopeOwnerRef, selectedCompRef),
    );
  }

  function isComponentScopeSelected(compRef: CompRef): boolean {
    return isComponentScopeSelectedInnerCached(
      selectionStore.getSelectedComponents(),
      compRef,
    );
  }

  return {
    getSelectedComponentsScopes,
    isComponentScopeSelected,
  };
}
