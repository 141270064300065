import createReactClass from 'create-react-class';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import * as Symbols from '@wix/santa-editor-symbols';
import { DropDown, DropDownOption, Button } from '@wix/wix-base-ui';

import * as core from '@/core';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import * as BaseUI from '@/baseUI';
import { cx } from '@/util';
import type { EditorState } from '@/stateManagement';
import type {
  TpaInnerRouteData,
  CurrentTpaInnerRoutesInfo,
} from '@/stateManagement';
import type { SitemapEntry } from 'types/documentServices';
import { FixedStageApiKey, EditorUIApiKey } from '@/apis';

const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();

const EXCLUDED_CLASSNAMES_FROM_DRAG = [
  'button-content',
  'tooltip-on-ellipsis-content',
  'combo-box-wrapper',
  'c1',
  'go-to-preview',
  'selected-content',
  'symbol-close',
  'close-area',
  'selected-container',
  'symbol-arrow-down',
];

const { getStageLayout } = stateManagement.domMeasurements.selectors;
const PAGINATION_RANGE = 100;

const { getPreviewMode } = stateManagement.preview.selectors;

function getRangeStartForRouteIndex(newIndex: AnyFixMe) {
  let newRangeStart = 0;
  while (newRangeStart + PAGINATION_RANGE <= newIndex) {
    newRangeStart += PAGINATION_RANGE;
  }
  return newRangeStart;
}

type InnerRoute = SitemapEntry | TpaInnerRouteData;

interface DynamicPagesComboBoxProps {
  previewMode: boolean;
  editorIsInit: boolean;
  devModeContext: unknown;
  stageLayout?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  innerRoutes: InnerRoute[];
  selectedInnerRoute?: string;
  routerId?: string;
  tpaInfo?: CurrentTpaInnerRoutesInfo;
  setSelectedInnerRoute: (innerRoute: string, routerId: string) => void;
  style?: React.CSSProperties;
}

// eslint-disable-next-line react/prefer-es6-class
const dynamicPageComboBoxPure = createReactClass<DynamicPagesComboBoxProps>({
  displayName: 'dynamicPagesComboBox',
  render() {
    return this.shouldShowInnerRoutesDropDown()
      ? (() => {
          const innerRoutes = this.getInnerRoutesToRender();

          return (
            <div
              key="dynamicPageComboBox"
              className={cx('dynamic-pages-combo-box', {
                preview: this.props.previewMode,
              })}
              style={this.props.style}
              onMouseDown={(e) => {
                const { classList } = e.target as HTMLDivElement;
                if (
                  !EXCLUDED_CLASSNAMES_FROM_DRAG.some(
                    (className) =>
                      classList.contains(className) || classList.length === 0,
                  )
                ) {
                  this.startDrag(e, this.getDragLimits());
                }
              }}
              onMouseUp={() => {
                this.endDrag();
              }}
            >
              <div className="dragable-area">
                {isNewWorkspace ? (
                  <Symbols.symbol name="dragHandler" />
                ) : (
                  <BaseUI.symbol
                    name="smalldragHandle"
                    className="symbol-smallDragHandle"
                  />
                )}
              </div>
              <div className={cx('combo-box-wrapper')}>
                <DropDown
                  value={this.props.selectedInnerRoute}
                  shouldTranslate={false}
                  searchBox={true}
                  key="innerRouteDropDown"
                  optionsContainerClassName={cx('dynamic-pages-options', {
                    preview: this.props.previewMode,
                  })}
                  onChange={this.onInnerRouteChange}
                  className="dynamic-pages-combobox"
                >
                  {this.shouldShowPrevButton() ? (
                    <Button
                      key="prevButton"
                      onClick={this.decreaseRange}
                      className="btn-text"
                    >
                      <span>
                        {translate(
                          'dynamic_pages_routes_navigation_button_back',
                        )}
                      </span>
                    </Button>
                  ) : null}
                  {this.getInnerRoutesDropDownOptions(innerRoutes)}
                  {this.shouldShowNextButton() ? (
                    <Button
                      key="nextButton"
                      onClick={this.increaseRange}
                      className="btn-text"
                    >
                      <span>
                        {translate(
                          'dynamic_pages_routes_navigation_button_next',
                        )}
                      </span>
                    </Button>
                  ) : null}
                </DropDown>
              </div>
              {!this.props.previewMode ? (
                <BaseUI.button
                  key="goToPreviewButton"
                  label="INNER_ROUTES_NAVIGATION_BOX_GO_TO_PREVIEW"
                  onClick={this.goToPreview}
                  className={cx('go-to-preview', {
                    'btn-text': true,
                  })}
                />
              ) : null}
            </div>
          );
        })()
      : null;
  },
  mixins: [core.mixins.editorAPIMixin, util.draggableMixin],
  getInitialState() {
    return {
      shouldShowNavigation: true,
      rangeStartIndex: 0,
    };
  },
  propTypes: {
    previewMode: PropTypes.bool.isRequired,
    editorIsInit: PropTypes.bool.isRequired,
    devModeContext: PropTypes.object.isRequired,
    stageLayout: PropTypes.object,
    innerRoutes: PropTypes.array.isRequired,
    selectedInnerRoute: PropTypes.string,
    routerId: PropTypes.string,
    setSelectedInnerRoute: PropTypes.func.isRequired,
    style: PropTypes.object,
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.editorIsInit &&
      nextProps.selectedInnerRoute &&
      !_.isEmpty(nextProps.innerRoutes) &&
      !this.isCollectionPageOrHasOneRoute()
    ) {
      const index = this.findInnerRouteIndex(
        nextProps.selectedInnerRoute,
        nextProps.innerRoutes,
      );
      this.setState({
        rangeStartIndex: index >= 0 ? getRangeStartForRouteIndex(index) : 0,
      });
    }

    if (
      nextProps.routerId !== this.props.routerId ||
      !_.isEqual(this.props.tpaInfo, nextProps.tpaInfo) ||
      !_.isEqual(this.props.innerRoutes, nextProps.innerRoutes)
    ) {
      this.setState({
        shouldShowNavigation: true,
      });
    }
  },
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(this.state, nextState) || !_.isEqual(this.props, nextProps)
    );
  },
  isCollectionPageOrHasOneRoute() {
    const { innerRoutes } = this.props;
    if (!innerRoutes) {
      return false;
    }
    return innerRoutes.length === 1;
  },
  shouldShowInnerRoutesDropDown() {
    const editorAPI = this.getEditorAPI();
    const developerContext = editorAPI.dsRead?.pages.getFocusedPageId
      ? editorAPI.developerMode.getContext()
      : {};
    const isPageContext =
      developerContext.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE;
    const isShowingFixedComponentsEnabled = editorAPI.dsRead?.documentMode
      ? editorAPI.dsRead.documentMode.isShowingFixedComponentsEnabled()
      : true;
    const isThePageFocused = editorAPI.dsRead?.pages.getFocusedPageId
      ? editorAPI.dsRead.pages.getFocusedPageId() ===
        editorAPI.dsRead.pages.getPrimaryPageId()
      : true;
    return (
      isThePageFocused &&
      !this.isCollectionPageOrHasOneRoute() &&
      this.state.shouldShowNavigation &&
      (this.props.routerId || this.props.tpaInfo) &&
      !_.isEmpty(this.props.innerRoutes) &&
      (this.props.previewMode || isPageContext) &&
      isShowingFixedComponentsEnabled
    );
  },
  getInnerRouteUrl(innerRoute: InnerRoute) {
    if (this.props.routerId) {
      return (innerRoute as SitemapEntry).url;
    }
    return (innerRoute as TpaInnerRouteData).path;
  },
  getInnerRoutesDropDownOptions(innerRoutes: InnerRoute[]) {
    return innerRoutes.map((innerRoute) => {
      const innerRouteUrl = this.getInnerRouteUrl(innerRoute);
      const innerRouteName =
        (innerRoute as SitemapEntry).title ??
        (innerRoute as TpaInnerRouteData).name;
      return (
        <DropDownOption
          key={innerRouteUrl}
          value={innerRouteUrl}
          label={innerRouteName}
          shouldTranslate={false}
        />
      );
    });
  },
  sendBI(eventName: AnyFixMe, params: AnyFixMe) {
    const editorAPI = this.getEditorAPI();
    editorAPI.bi.event(eventName, params);
  },
  getInnerRoutesToRender() {
    if (!this.props.innerRoutes) {
      return [];
    }

    return this.props.innerRoutes.slice(
      this.state.rangeStartIndex,
      this.state.rangeStartIndex + PAGINATION_RANGE,
    );
  },
  increaseRange() {
    const newRangeStart = this.state.rangeStartIndex + PAGINATION_RANGE;
    const newSelectedInnerRoute = this.getInnerRouteUrl(
      this.shouldShowPrevButton(newRangeStart)
        ? this.props.innerRoutes[newRangeStart]
        : this.props.innerRoutes[newRangeStart - 1],
    );
    this.sendBI(
      coreBi.events.dynamicPageComboBox.preview_navigate,
      this.getBIParams(newSelectedInnerRoute),
    );
    this.setState({
      rangeStartIndex: newRangeStart,
    });
    this.props.setSelectedInnerRoute(
      newSelectedInnerRoute,
      this.props.routerId,
    );
  },
  decreaseRange() {
    const newRangeStart = this.state.rangeStartIndex - PAGINATION_RANGE;
    const newSelectedInnerRoute = this.getInnerRouteUrl(
      this.props.innerRoutes[newRangeStart],
    );

    this.sendBI(
      coreBi.events.dynamicPageComboBox.preview_navigate,
      this.getBIParams(newSelectedInnerRoute),
    );
    this.setState({
      rangeStartIndex: newRangeStart,
    });
    this.props.setSelectedInnerRoute(
      newSelectedInnerRoute,
      this.props.routerId,
    );
  },
  shouldShowPrevButton(rangeStart: AnyFixMe) {
    rangeStart = rangeStart || this.state.rangeStartIndex;
    const hasLessThenMax = this.props.innerRoutes.length < PAGINATION_RANGE;
    const gotToBottomLimit = rangeStart === 0;

    return !gotToBottomLimit && !hasLessThenMax;
  },
  shouldShowNextButton() {
    const hasLessThenMax = this.props.innerRoutes.length < PAGINATION_RANGE;
    const gotToUpperLimit =
      this.state.rangeStartIndex + PAGINATION_RANGE >=
      this.props.innerRoutes.length;

    return !gotToUpperLimit && !hasLessThenMax;
  },
  goToPreview() {
    const editorAPI = this.getEditorAPI();
    if (!this.props.previewMode) {
      this.sendBI(
        coreBi.events.dynamicPageComboBox.preview_clicked,
        this.getBIParams(this.props.selectedInnerRoute),
      );
      editorAPI.preview.togglePreview(undefined, {
        biParams: { origin: constants.BI.PREVIEW.ORIGIN.DYNAMIC_PAGES },
      });
    }
  },
  getBIParams(innerRouteName: string) {
    const editorAPI = this.getEditorAPI();
    const currentPageId = editorAPI.dsRead.pages.getPrimaryPageId();
    const routerData =
      editorAPI.dsRead.routers.getRouterDataForPageIfExist(currentPageId);
    if (routerData) {
      return {
        item_name: innerRouteName,
        item_path: `${routerData.prefix}/${innerRouteName}`,
        pageId: currentPageId,
        appName: routerData.appDefinitionId,
        isTpa: false,
      };
    }

    const state = editorAPI.store.getState();
    const selectedRoute =
      stateManagement.tpaDynamicPages.selectors.getSelectedRouteInfo(state);
    const currentTpa =
      stateManagement.tpaDynamicPages.selectors.getCurrentRouteInfo(state);
    return {
      item_name: innerRouteName,
      item_path: selectedRoute.path,
      pageId: currentPageId,
      appName: currentTpa.appDefinitionId,
      isTpa: true,
    };
  },
  onInnerRouteChange(newInnerRouteName: string) {
    this.sendBI(
      coreBi.events.dynamicPageComboBox.preview_select_page,
      this.getBIParams(newInnerRouteName),
    );
    this.props.setSelectedInnerRoute(newInnerRouteName, this.props.routerId);
  },
  close() {
    const editorAPI = this.getEditorAPI();
    const currentPageId = editorAPI.dsRead.pages.getPrimaryPageId();
    const routerData =
      editorAPI.dsRead.routers.getRouterDataForPageIfExist(currentPageId);
    this.sendBI(coreBi.events.dynamicPageComboBox.preview_closed, {
      pageId: currentPageId,
      appName: routerData.appDefinitionId,
    });
    this.setState({
      shouldShowNavigation: false,
    });
  },
  openHelpCenter() {
    const editorAPI = this.getEditorAPI();
    const helpId = 'b853c294-8ff1-418f-b4d2-29d7b59a85c3'; // TODO: change helpid
    const biHelpParams = {
      origin: 'display-combo-box',
      panel_name: 'dynamic-pages-display-combo-box',
      component: this.props.id,
    };

    editorAPI.panelManager.openHelpCenter(helpId, null, biHelpParams);
  },
  findInnerRouteIndex(innerRoute: string, innerRoutes: InnerRoute[]) {
    const index = innerRoutes.findIndex(
      (route) => this.getInnerRouteUrl(route) === innerRoute,
    );
    return index === -1 ? 0 : index;
  },
  moveToItem(index: AnyFixMe) {
    const newRoute =
      !_.isEmpty(this.props.innerRoutes) &&
      index < this.props.innerRoutes.length
        ? this.getInnerRouteUrl(this.props.innerRoutes[index])
        : null;
    this.sendBI(
      coreBi.events.dynamicPageComboBox.preview_navigate,
      this.getBIParams(newRoute),
    );
    this.props.setSelectedInnerRoute(newRoute, this.props.routerId);

    this.setState({
      rangeStartIndex: getRangeStartForRouteIndex(index),
    });
  },
  getDragLimits() {
    let { stageLayout } = this.props;

    if (!stageLayout || this.props.isPreview) {
      stageLayout = window.document.body.getBoundingClientRect();
    }

    if (util.fixedStage.isFixedStageEnabled()) {
      const editorAPI = this.getEditorAPI();
      const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);
      const additionalStageWidth =
        fixedStageAPI.isStageHorizontallyScrollable() &&
        editorAPI.host.getAPI(EditorUIApiKey).stage.shouldCutEditingArea()
          ? constants.UI.EDITING_AREA_RIGHT_MARGIN
          : 0;

      return {
        x: [stageLayout.x, stageLayout.right + additionalStageWidth],
        y: [
          stageLayout.top - constants.UI.FIXED_STAGE_MARGIN_TOP,
          stageLayout.bottom,
        ],
      };
    }

    return {
      x: [stageLayout.left, stageLayout.right],
      y: [stageLayout.top, stageLayout.bottom],
    };
  },
});

const getInnerRoutesProps = (state: EditorState) => {
  const routerId = stateManagement.dynamicPages.selectors.getRouterId(state);
  if (routerId) {
    return {
      innerRoutes: stateManagement.dynamicPages.selectors.getInnerRoutes(state),
      selectedInnerRoute:
        stateManagement.dynamicPages.selectors.getSelectedInnerRoute(state),
      routerId,
    };
  }

  const selectedInnerRoute =
    stateManagement.tpaDynamicPages.selectors.getSelectedRouteInfo(state)?.path;
  if (selectedInnerRoute) {
    const tpaInfo =
      stateManagement.tpaDynamicPages.selectors.getCurrentRouteInfo(state);
    return {
      innerRoutes:
        stateManagement.tpaDynamicPages.selectors.getFocusedInnerRoutes(
          state,
          tpaInfo.appDefinitionId,
          tpaInfo.pageId,
          'dynamicPagesNavBar',
        ),
      selectedInnerRoute,
      tpaInfo,
    };
  }

  return {
    innerRoutes: [] as InnerRoute[],
  };
};

const mapDispatchToProps = (dispatch: AnyFixMe) => ({
  setSelectedInnerRoute: (newRoute: string, routerId: string) => {
    if (routerId) {
      dispatch(
        stateManagement.dynamicPages.actions.setSelectedInnerRouteAndNavigate(
          newRoute,
          routerId,
        ),
      );
    } else {
      dispatch(
        stateManagement.tpaDynamicPages.actions.setCurrentUrlToState(
          newRoute,
          true,
        ),
      );
    }
  },
});

const mapStateToProps = ({ state }: { state: EditorState }) => ({
  ...getInnerRoutesProps(state),
  stageLayout: getStageLayout(state),
  isPreview: getPreviewMode(state),
});

const Connected = util.hoc.connect(
  util.hoc.STORES.STATE_ONLY,
  mapStateToProps,
  mapDispatchToProps,
)(dynamicPageComboBoxPure) as any; //TODO fixme
Connected.pure = dynamicPageComboBoxPure;
export default Connected;
