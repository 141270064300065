import type { TextManager } from 'types/data';
import { createThemedAction } from '../utils/themedAction';
import type { ThemedActionCreationData } from '../utils/textAction';

export const fontWeight = {
  Bold: 'bold',
  Normal: 'normal',
} as const;
export type FontWeight = (typeof fontWeight)[keyof typeof fontWeight];

const getWixRichTextCommandState = (textManager: TextManager) => {
  if (textManager.getCommandsStates().bold) {
    return fontWeight.Bold;
  }

  if (textManager.getCommandsStates().unBold) {
    return fontWeight.Normal;
  }

  return;
};

const boldActionCreationData: Readonly<ThemedActionCreationData<FontWeight>> = {
  execCommand: (textManager, actionInput) => {
    switch (actionInput) {
      case fontWeight.Bold:
        textManager.commands.bold();
        break;
      case fontWeight.Normal:
        textManager.commands.unBold();
        break;
    }
  },
  getWixRichTextCommandState,
  execRevertCommand: (textManager) => {
    if (textManager.getCommandsStates().unBold) {
      textManager.commands.unBold();
    } else {
      // we call it twice to make sure we don't have inline font-weight
      textManager.commands.unBold();
      textManager.commands.unBold();
    }
  },
  createUpdatedStyleProperties: (fontWeight) => ({ fontWeight }),
  getPropertyValue: (styleProperties) => styleProperties.fontWeight,
  getThemeValue: (theme) => (theme?.bold ? fontWeight.Bold : fontWeight.Normal),
};

export const boldAction = createThemedAction(boldActionCreationData);
