// @ts-nocheck
import * as util from '@/util';
import { isOnWixStudio } from '@wix/santa-editor-utils';
function convertPageNameToUrl(title) {
  const dash = '-';
  const pageUrlWithoutSpaces = title.replace(/\s/g, dash);
  const validator = util.validate.byInvalidCharacters(
    util.validationExpressions.invalidUrlCharacters,
  );

  return validator(pageUrlWithoutSpaces)
    ? pageUrlWithoutSpaces.toLowerCase()
    : 'blank';
}

function getPublicUrl(editorAPI, pageData) {
  const baseUrl = editorAPI.dsRead.generalInfo.getPublicUrl();
  const defaultSiteNameString = isOnWixStudio() ? '' : 'your-site-name';
  const defaultSiteName =
    editorAPI.dsRead.generalInfo.isFirstSave() ||
    editorAPI.dsRead.generalInfo.isDraft()
      ? `${baseUrl}${defaultSiteNameString}`
      : '';

  return editorAPI.pages.getPageUrl(pageData.id, defaultSiteName);
}

export { getPublicUrl, convertPageNameToUrl };
