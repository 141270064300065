import _ from 'lodash';
import type { EditorAPI } from '@/editorAPI';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import getInfoForActions from '../mappers/getInfoForActions';
import * as queries from '../queries/openBehaviorsQueries';
import behaviorsConstants from '../constants/openBehaviorsConstants';

const componentsSelectors = stateManagement.components.selectors;
const selectionSelectors = stateManagement.selection.selectors;

function getNumOfAvailablePages(actionsInfo: AnyFixMe) {
  return _(actionsInfo.enabledPageIds)
    .union(actionsInfo.selectedPageIds)
    .size();
}

function sendToggleAllBi(
  editorAPI: EditorAPI,
  actionsInfo: ReturnType<typeof getInfoForActions>,
  shouldDisplay: boolean,
) {
  const numOfAvailablePages = getNumOfAvailablePages(actionsInfo);

  sendPageSelectorBi(editorAPI, {
    show_on_pages: shouldDisplay,
    all_pages_selected: shouldDisplay,
    num_selected_pages: shouldDisplay ? numOfAvailablePages : 0,
    num_unselected_pages: shouldDisplay ? 0 : numOfAvailablePages,
  });
}

function sendTogglePageBi(
  editorAPI: EditorAPI,
  actionsInfo: ReturnType<typeof getInfoForActions>,
  shouldDisplay: boolean,
  pageId: string,
) {
  const numOfAvailablePages = getNumOfAvailablePages(actionsInfo);
  const numOfSelectedPages = shouldDisplay
    ? _(actionsInfo.selectedPageIds).push(pageId).size()
    : _(actionsInfo.selectedPageIds).pull(pageId).size();

  sendPageSelectorBi(editorAPI, {
    show_on_pages: shouldDisplay,
    all_pages_selected: numOfAvailablePages === numOfSelectedPages,
    num_selected_pages: numOfSelectedPages,
    num_unselected_pages: numOfAvailablePages - numOfSelectedPages,
  });
}

const getSelectedComponentBiMeta = (editorAPI: EditorAPI) => {
  const [selectedComponent] = selectionSelectors.getSelectedCompsRefs(
    editorAPI.store.getState(),
  );

  if (!selectedComponent) {
    return { compId: undefined, compType: undefined };
  }

  const compType = componentsSelectors.getCompTypeSuffix(
    selectedComponent,
    editorAPI.dsRead,
  );

  return {
    compId: selectedComponent.id,
    compType,
  };
};

function sendPageSelectorBi(editorAPI: EditorAPI, props: object) {
  const { compId } = getSelectedComponentBiMeta(editorAPI);

  editorAPI.bi.event(coreBi.events.popups.POP_UP_SETTINGS_PAGE_SELECTOR_OK, {
    ...props,
    component_id: compId,
  });
}

const sendToggleAutoDisplayBi = (editorAPI: EditorAPI, status: boolean) => {
  const { compId, compType } = getSelectedComponentBiMeta(editorAPI);

  editorAPI.bi.event(coreBi.events.popups.pop_up_settings_show_on_pages, {
    status,
    component_type: compType,
    component_id: compId,
  });
};

function assignOpenBehaviorOnlyToCurrentPage(editorAPI: EditorAPI) {
  const actionsInfo = getInfoForActions(editorAPI);
  const primaryPageId = editorAPI.dsRead.pages.getPrimaryPageId();

  sendTogglePageBi(editorAPI, actionsInfo, true, primaryPageId);

  if (_.has(actionsInfo.disabledPageIds, primaryPageId)) {
    return;
  }

  _removeAllOpenBehaviors(editorAPI, actionsInfo.openBehaviors);

  _assignOpenBehaviorToPage(
    editorAPI,
    primaryPageId,
    actionsInfo.popupPointer.id,
    actionsInfo.behaviorDefaults,
  );
  editorAPI.history.add('assign open behavior only to current page');
}

function removeOpenBehaviorFromAllPages(editorAPI: EditorAPI, options = {}) {
  const actionsInfo = getInfoForActions(editorAPI);
  _removeAllOpenBehaviors(editorAPI, actionsInfo.openBehaviors);
  sendToggleAllBi(editorAPI, actionsInfo, false);
  const disableHistorySave = _.get(options, 'disableHistorySave', false);

  if (!disableHistorySave) {
    editorAPI.history.add('remove open behavior from all pages');
  }
}

function assignOpenBehaviorToAllPages(editorAPI: EditorAPI) {
  const actionsInfo = getInfoForActions(editorAPI);

  const popupId = actionsInfo.popupPointer.id;
  const { openBehaviors } = actionsInfo;

  if (queries.isMasterPageSelected(editorAPI, openBehaviors)) {
    return;
  }

  if (_.isEmpty(actionsInfo.disabledPageIds)) {
    _removeAllOpenBehaviors(editorAPI, openBehaviors);

    _assignOpenBehaviorToPage(
      editorAPI,
      editorAPI.dsRead.siteSegments.getSiteStructureId(),
      popupId,
      actionsInfo.behaviorDefaults,
    );
  } else {
    _(actionsInfo.enabledPageIds)
      .difference(actionsInfo.selectedPageIds)
      .forEach(function (pageId) {
        _assignOpenBehaviorToPage(
          editorAPI,
          pageId,
          popupId,
          actionsInfo.behaviorDefaults,
        );
      });
  }

  sendToggleAllBi(editorAPI, actionsInfo, true);
  editorAPI.history.add('assign open behavior to all pages');
}

function assignOpenBehaviorToPage(editorAPI: EditorAPI, pageId: string) {
  const actionsInfo = getInfoForActions(editorAPI);

  const popupId = actionsInfo.popupPointer.id;
  const { openBehaviors } = actionsInfo;

  if (queries.isMasterPageSelected(editorAPI, openBehaviors)) {
    return;
  }

  if (_.has(actionsInfo.disabledPageIds, pageId)) {
    return;
  }

  const potentialSelectedIds = actionsInfo.selectedPageIds.concat(pageId);

  if (
    potentialSelectedIds.length > 1 &&
    potentialSelectedIds.length === actionsInfo.allPageIds.length
  ) {
    _removeAllOpenBehaviors(editorAPI, openBehaviors);

    _assignOpenBehaviorToPage(
      editorAPI,
      editorAPI.dsRead.siteSegments.getSiteStructureId(),
      popupId,
      actionsInfo.behaviorDefaults,
    );
  } else {
    _assignOpenBehaviorToPage(
      editorAPI,
      pageId,
      popupId,
      actionsInfo.behaviorDefaults,
    );
  }

  sendTogglePageBi(editorAPI, actionsInfo, true, pageId);
  editorAPI.history.add('assign open behavior to page');
}

function removeOpenBehaviorFromPage(editorAPI: EditorAPI, pageId: string) {
  const popupId = editorAPI.dsRead.pages.popupPages.getCurrentPopupId();
  const openBehaviors =
    editorAPI.pages.popupPages.getOpenBehaviorsForPopupWithId(popupId);
  const globalOpenBehavior = queries.findMasterPageBehavior(
    editorAPI,
    openBehaviors,
  );
  const actionsInfo = getInfoForActions(editorAPI);

  if (globalOpenBehavior) {
    _convertGlobalOpenBehaviorToLocal(editorAPI, globalOpenBehavior, pageId);
  } else {
    _removeOpenBehavior(editorAPI, pageId, popupId);
  }

  sendTogglePageBi(editorAPI, actionsInfo, false, pageId);
  editorAPI.history.add('remove open behavior from page');
}

function toggleLoadOnMobile(editorAPI: EditorAPI, openInMobile: boolean) {
  const popupId = editorAPI.dsRead.pages.popupPages.getCurrentPopupId();
  const openBehaviors =
    editorAPI.pages.popupPages.getOpenBehaviorsForPopupWithId(popupId);

  openBehaviors.forEach(function (behavior) {
    let params = {};
    params = _.defaults(
      {
        openInMobile,
        shouldSyncDesktopMobileOpenBehaviors: true,
      },
      behavior.params,
    );
    _assignOpenBehaviorToPage(
      editorAPI,
      behavior.pageId,
      behavior.popupId,
      params,
    );
  });

  editorAPI.history.add('toggle load on mobile');
  sendToggleAutoDisplayBi(editorAPI, openInMobile);
}

function toggleLoadOnDesktop(editorAPI: EditorAPI, openInDesktop: boolean) {
  const popupId = editorAPI.dsRead.pages.popupPages.getCurrentPopupId();
  const openBehaviors =
    editorAPI.pages.popupPages.getOpenBehaviorsForPopupWithId(popupId);

  openBehaviors.forEach(function (behavior) {
    let params;
    if (behavior.params.shouldSyncDesktopMobileOpenBehaviors) {
      params = _.defaults({ openInDesktop }, behavior.params);
    } else {
      params = _.defaults(
        {
          openInDesktop,
          openInMobile: openInDesktop,
        },
        behavior.params,
      );
    }
    _assignOpenBehaviorToPage(
      editorAPI,
      behavior.pageId,
      behavior.popupId,
      params,
    );
  });
  editorAPI.history.add('toggle load on desktop');

  sendToggleAutoDisplayBi(editorAPI, openInDesktop);
}

function _convertGlobalOpenBehaviorToLocal(
  editorAPI: EditorAPI,
  globalOpenBehavior: AnyFixMe,
  excludedPageId: string,
) {
  const pagesIds = _.without(
    editorAPI.dsRead.pages.getPageIdList(),
    excludedPageId,
  );

  _removeOpenBehavior(
    editorAPI,
    editorAPI.dsRead.siteSegments.getSiteStructureId(),
    globalOpenBehavior.popupId,
  );

  pagesIds.forEach((pageId: string) => {
    _assignOpenBehaviorToPage(
      editorAPI,
      pageId,
      globalOpenBehavior.popupId,
      globalOpenBehavior.params,
    );
  });
}

function changeBehaviorParams(
  editorAPI: EditorAPI,
  newParams: Record<string, string | number>,
) {
  const popupId = editorAPI.dsRead.pages.popupPages.getCurrentPopupId();
  const openBehaviors =
    editorAPI.pages.popupPages.getOpenBehaviorsForPopupWithId(popupId);

  openBehaviors.forEach((behavior) => {
    _assignOpenBehaviorToPage(
      editorAPI,
      behavior.pageId,
      behavior.popupId,
      Object.assign({}, behavior.params, newParams),
    );
  });
}

function changeOpenDelay(editorAPI: EditorAPI, delay: number) {
  changeBehaviorParams(editorAPI, { delay });
}

function changeVisibilitySchedule(
  editorAPI: EditorAPI,
  schedule: {
    startISODate: string;
    endISODate: string;
    scheduleTimeZone: string;
  },
) {
  changeBehaviorParams(editorAPI, schedule);
}

function _assignOpenBehaviorToPage(
  editorAPI: AnyFixMe,
  pageId: AnyFixMe,
  popupId: AnyFixMe,
  params: AnyFixMe,
) {
  const pagePointer = editorAPI.components.get.byId(pageId);
  const popupPointer = editorAPI.components.get.byId(popupId);
  const action = editorAPI.actions.getDefinition(
    behaviorsConstants.LOAD_ACTION,
  );
  const behavior = editorAPI.behaviors.getDefinition(
    behaviorsConstants.OPEN_POPUP_BEHAVIOR,
  );
  behavior.params = params;
  editorAPI.behaviors.update(pagePointer, action, popupPointer, behavior);
}

function _removeOpenBehavior(
  editorAPI: AnyFixMe,
  pageId: AnyFixMe,
  popupId: AnyFixMe,
) {
  const pagePointer = editorAPI.components.get.byId(pageId);
  const popupPointer = editorAPI.components.get.byId(popupId);
  const action = editorAPI.actions.getDefinition(
    behaviorsConstants.LOAD_ACTION,
  );
  const behavior = editorAPI.behaviors.getDefinition(
    behaviorsConstants.OPEN_POPUP_BEHAVIOR,
  );

  editorAPI.behaviors.remove(pagePointer, action, popupPointer, behavior);
}

function _removeAllOpenBehaviors(
  editorAPI: EditorAPI,
  openBehaviors: AnyFixMe,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(openBehaviors, (behavior) => {
    _removeOpenBehavior(editorAPI, behavior.pageId, behavior.popupId);
  });
}

export {
  assignOpenBehaviorOnlyToCurrentPage,
  removeOpenBehaviorFromAllPages,
  assignOpenBehaviorToAllPages,
  assignOpenBehaviorToPage,
  removeOpenBehaviorFromPage,
  changeOpenDelay,
  toggleLoadOnMobile,
  toggleLoadOnDesktop,
  changeVisibilitySchedule,
};
