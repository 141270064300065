'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
module.exports =
    {
        "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
        "hide": false,
        "title": "design_section_label_explore_image",
        "showSectionHeader": true,
        "additionalBehaviours": {
            "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
            "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
            "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
            "iconEnabledForComps": {}
        },
        "props": {
            "image": "addPanelData/sections/imageStyleSection_en/imageStyleSection_en.png",
            "imageHover": null,
            "items": [{
                "id": "Image_Image_Style_1",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto",
                    "layout": {
                        "width": 220,
                        "height": 160,
                        "x": 48,
                        "y": 40,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WPhoto",
                    "data": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "",
                        "uri": "a3c153_61d1e624212542dfbd1d61241f449800~mv2_d_3901_2423_s_4_2.jpg",
                        "description": "",
                        "width": 3901,
                        "height": 2423,
                        "alt": "",
                        "crop": {"x": 1020, "y": 521, "width": 2881, "height": 1802}
                    },
                    "props": {
                        "type": "WPhotoProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fill",
                        "onClickBehavior": "goToLink"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                    },
                    "activeModes": {},
                    "id": "comp-jc0p6t5s"
                },
                "preset": {"rect": {"width": 126, "height": 98, "x": 17, "y": 0}, "tags": null}
            }, {
                "id": "Image_Image_Style_2",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.photo.RoundPhoto",
                    "layout": {
                        "width": 220,
                        "height": 160,
                        "x": 308,
                        "y": 40,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WPhoto",
                    "data": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "",
                        "uri": "a3c153_61d1e624212542dfbd1d61241f449800~mv2_d_3901_2423_s_4_2.jpg",
                        "description": "",
                        "width": 3901,
                        "height": 2423,
                        "alt": "",
                        "crop": {"x": 1020, "y": 491, "width": 2881, "height": 1802}
                    },
                    "props": {
                        "type": "WPhotoProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fill",
                        "onClickBehavior": "goToLink"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#000000",
                                "brw": "5",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {"brd": "value", "brw": "value", "rd": "value", "shd": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.photo.RoundPhoto"
                    },
                    "activeModes": {},
                    "id": "comp-jc0p6t5l"
                },
                "preset": {"rect": {"width": 126, "height": 98, "x": 143, "y": 0}, "tags": null}
            }, {
                "id": "Image_Image_Style_3",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.photo.GlowLinePhoto",
                    "layout": {
                        "width": 220,
                        "height": 160,
                        "x": 48,
                        "y": 240,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WPhoto",
                    "data": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "",
                        "uri": "a3c153_61d1e624212542dfbd1d61241f449800~mv2_d_3901_2423_s_4_2.jpg",
                        "description": "",
                        "width": 3901,
                        "height": 2423,
                        "alt": "",
                        "crop": {"x": 1020, "y": 541, "width": 2881, "height": 1802}
                    },
                    "props": {
                        "type": "WPhotoProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fill",
                        "onClickBehavior": "goToLink"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "boxShadowToggleOn-shd": "false",
                                "rd": "10px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {"rd": "value", "shd": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.photo.GlowLinePhoto"
                    },
                    "activeModes": {},
                    "id": "comp-jc0p6t5v"
                },
                "preset": {"rect": {"width": 126, "height": 98, "x": 17, "y": 98}, "tags": null}
            }, {
                "id": "Image_Image_Style_4",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.photo.LiftedTopPhoto",
                    "layout": {
                        "width": 220,
                        "height": 160,
                        "x": 308,
                        "y": 240,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WPhoto",
                    "data": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "",
                        "uri": "a3c153_61d1e624212542dfbd1d61241f449800~mv2_d_3901_2423_s_4_2.jpg",
                        "description": "",
                        "width": 3901,
                        "height": 2423,
                        "alt": "",
                        "crop": {"x": 1020, "y": 531, "width": 2881, "height": 1802}
                    },
                    "props": {
                        "type": "WPhotoProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fill",
                        "onClickBehavior": "goToLink"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "brw": "8px", "rd": "0px"},
                            "propertiesSource": {"brd": "value", "brw": "value", "rd": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.photo.LiftedTopPhoto"
                    },
                    "activeModes": {},
                    "id": "comp-jc0p6t5y"
                },
                "preset": {"rect": {"width": 126, "height": 98, "x": 143, "y": 98}, "tags": null}
            }, {
                "id": "Image_Image_Style_5",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                    "layout": {
                        "width": 215,
                        "height": 215,
                        "x": 53,
                        "y": 444,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WPhoto",
                    "data": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "",
                        "uri": "a3c153_61d1e624212542dfbd1d61241f449800~mv2_d_3901_2423_s_4_2.jpg",
                        "description": "",
                        "width": 3901,
                        "height": 2423,
                        "alt": "",
                        "crop": {"x": 1018, "y": 120, "width": 2245, "height": 2243}
                    },
                    "props": {
                        "type": "WPhotoProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fill",
                        "onClickBehavior": "goToLink"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFFFFF",
                                "brw": "0px",
                                "shd": "0px 1px 3px 0px rgba(0,0,0,0.5)"
                            },
                            "propertiesSource": {"brd": "value", "brw": "value", "shd": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                    },
                    "activeModes": {},
                    "id": "comp-jc0p6t60"
                },
                "preset": {"rect": {"width": 126, "height": 126, "x": 17, "y": 196}, "tags": null}
            }, {
                "id": "Image_Image_Style_6",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                    "layout": {
                        "width": 220,
                        "height": 220,
                        "x": 308,
                        "y": 440,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WPhoto",
                    "data": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "",
                        "uri": "a3c153_61d1e624212542dfbd1d61241f449800~mv2_d_3901_2423_s_4_2.jpg",
                        "description": "",
                        "width": 3901,
                        "height": 2423,
                        "alt": "",
                        "crop": {"x": 1003, "y": 0, "width": 2422, "height": 2423}
                    },
                    "props": {
                        "type": "WPhotoProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fill",
                        "onClickBehavior": "goToLink"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#FFFFFF",
                                "brw": "6",
                                "shd": "0.00px 0.00px 14px 0px rgba(0,0,0,0.2)"
                            },
                            "propertiesSource": {"brd": "value", "brw": "value", "shd": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                    },
                    "activeModes": {},
                    "id": "comp-jc0p6t64"
                },
                "preset": {"rect": {"width": 126, "height": 126, "x": 143, "y": 196}, "tags": null}
            }, {
                "id": "Image_Image_Style_7",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.photo.DoubleBorderPhoto",
                    "layout": {
                        "width": 220,
                        "height": 160,
                        "x": 48,
                        "y": 703,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WPhoto",
                    "data": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "",
                        "uri": "a3c153_61d1e624212542dfbd1d61241f449800~mv2_d_3901_2423_s_4_2.jpg",
                        "description": "",
                        "width": 3901,
                        "height": 2423,
                        "alt": "",
                        "crop": {"x": 1020, "y": 621, "width": 2881, "height": 1802}
                    },
                    "props": {
                        "type": "WPhotoProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fill",
                        "onClickBehavior": "goToLink"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd1": "1",
                                "alpha-brd2": "1",
                                "bg": "#FFFFFF",
                                "boxShadowToggleOn-shd": "false",
                                "brd1": "#000000",
                                "brd2": "#000000",
                                "mrg1": "9px",
                                "mrg2": "5px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "sz1": "3",
                                "sz2": "0px"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "brd1": "value",
                                "brd2": "value",
                                "mrg1": "value",
                                "mrg2": "value",
                                "rd": "value",
                                "shd": "value",
                                "sz1": "value",
                                "sz2": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.photo.DoubleBorderPhoto"
                    },
                    "activeModes": {},
                    "id": "comp-jc0p6t6a"
                },
                "preset": {"rect": {"width": 126, "height": 98, "x": 17, "y": 322}, "tags": null}
            }, {
                "id": "Image_Image_Style_8",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.photo.PolaroidPhoto",
                    "layout": {
                        "width": 220,
                        "height": 160,
                        "x": 308,
                        "y": 703,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WPhoto",
                    "data": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "",
                        "uri": "a3c153_61d1e624212542dfbd1d61241f449800~mv2_d_3901_2423_s_4_2.jpg",
                        "description": "",
                        "width": 3901,
                        "height": 2423,
                        "alt": "",
                        "crop": {"x": 1020, "y": 611, "width": 2881, "height": 1802}
                    },
                    "props": {
                        "type": "WPhotoProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fill",
                        "onClickBehavior": "goToLink"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#FFFFFF",
                                "brw": "6",
                                "rd": "0px",
                                "shd": "0.00px 1.00px 4px 0px rgba(0,0,0,0.3)"
                            },
                            "propertiesSource": {
                                "alpha-brd": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.photo.PolaroidPhoto"
                    },
                    "activeModes": {},
                    "id": "comp-jc0p6t66"
                },
                "preset": {"rect": {"width": 126, "height": 98, "x": 143, "y": 322}, "tags": null}
            }, {
                "id": "Image_Image_Style_9",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.photo.MouseOverPhoto",
                    "layout": {
                        "width": 220,
                        "height": 160,
                        "x": 48,
                        "y": 904,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WPhoto",
                    "data": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "",
                        "uri": "a3c153_61d1e624212542dfbd1d61241f449800~mv2_d_3901_2423_s_4_2.jpg",
                        "description": "",
                        "width": 3901,
                        "height": 2423,
                        "alt": "",
                        "crop": {"x": 1020, "y": 541, "width": 2881, "height": 1802}
                    },
                    "props": {
                        "type": "WPhotoProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fill",
                        "onClickBehavior": "goToLink"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bgh": "0.5",
                                "alpha-brd": "1",
                                "bgh": "#FFFFFF",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#FFFFFF",
                                "brw": "5",
                                "rd": "0px",
                                "shd": "0.00px 1.00px 4px 0px rgba(0,0,0,0.4)"
                            },
                            "propertiesSource": {
                                "bgh": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.photo.MouseOverPhoto"
                    },
                    "activeModes": {},
                    "id": "comp-jc0p6t6h"
                },
                "preset": {"rect": {"width": 126, "height": 98, "x": 17, "y": 420}, "tags": null}
            }, {
                "id": "Image_Image_Style_10",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.photo.DoubleBorderPhoto",
                    "layout": {
                        "width": 220,
                        "height": 160,
                        "x": 308,
                        "y": 904,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WPhoto",
                    "data": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "",
                        "uri": "a3c153_61d1e624212542dfbd1d61241f449800~mv2_d_3901_2423_s_4_2.jpg",
                        "description": "",
                        "width": 3901,
                        "height": 2423,
                        "alt": "",
                        "crop": {"x": 1020, "y": 541, "width": 2881, "height": 1802}
                    },
                    "props": {
                        "type": "WPhotoProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fill",
                        "onClickBehavior": "goToLink"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd1": "1",
                                "alpha-brd2": "1",
                                "bg": "#FFFFFF",
                                "boxShadowToggleOn-shd": "false",
                                "brd1": "#000000",
                                "brd2": "#000000",
                                "mrg1": "11px",
                                "mrg2": "5px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "sz1": "2",
                                "sz2": "1px"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "brd1": "value",
                                "brd2": "value",
                                "mrg1": "value",
                                "mrg2": "value",
                                "rd": "value",
                                "shd": "value",
                                "sz1": "value",
                                "sz2": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.photo.DoubleBorderPhoto"
                    },
                    "activeModes": {},
                    "id": "comp-jc0p6t6e"
                },
                "preset": {"rect": {"width": 126, "height": 98, "x": 143, "y": 420}, "tags": null}
            }],
            "compTypes": ["wysiwyg.viewer.components.WPhoto"]
        },
        "help": {"hide": false, "text": "design_section_label_explore_image"}
    }