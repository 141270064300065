import overlaySettingsPanel from './popup/overlaySettingsPanel/overlaySettingsPanel';
import discoverMobile from './discoverMobile';
import backgroundPanel from './backgroundPanel/backgroundPanel';
import mediaManagerPanel from './mediaManagerPanel/mediaManagerPanel';
import workspaceModesLeftPanel from './workspaceModesLeftPanel/workspaceModesLeftPanel';
import bgImageSettingsPanel from './backgroundSubPanels/BgImageSettingsPanel/BgImageSettingsPanel';
import bgVideoSettingsPanel from './backgroundSubPanels/bgVideoSettingsPanel';
import bgApplyPanel from './backgroundSubPanels/bgApplyPanel';
import siteSettingsPanel from './siteSettings/siteSettingsPanel';
import editorVersionInfoPanel from './editorVersionInfoPanel/editorVersionInfoPanel';
import siteSettingsBranchWarning from './siteSettingsBranchWarning/siteSettingsBranchWarning';
import applyStylePanel from './applyStylePanel/applyStylePanel';
import boxSlideShowManageSlidesPanel from './boxSlideShowPanels/boxSlideShowManageSlidesPanel';
import slideShowBgApplyPanel from './boxSlideShowPanels/slideShowBgApplyPanel';
import slideShowBgApplyPanelItem from './boxSlideShowPanels/slideShowBgApplyPanelItem';
import stateBoxBgApplyPanel from './stateBoxPanels/stateBoxBgApplyPanel';
import stateBoxBgApplyPanelItem from './stateBoxPanels/stateBoxBgApplyPanelItem';
import stateBoxManageStatesPanel from './stateBoxPanels/stateBoxManageStatesPanel';

const popup = {
  overlaySettingsPanel,
};

export {
  discoverMobile,
  backgroundPanel,
  mediaManagerPanel,
  workspaceModesLeftPanel,
  bgImageSettingsPanel,
  bgVideoSettingsPanel,
  bgApplyPanel as backgroundApplyPanel,
  siteSettingsPanel,
  popup,
  editorVersionInfoPanel,
  siteSettingsBranchWarning,
  applyStylePanel,
  boxSlideShowManageSlidesPanel,
  slideShowBgApplyPanel,
  slideShowBgApplyPanelItem,
  stateBoxBgApplyPanel,
  stateBoxBgApplyPanelItem,
  stateBoxManageStatesPanel,
};
