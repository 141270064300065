import chooseDomainPanel from './chooseDomainPanel';
import siteWasSavedPanel from './siteWasSavedPanel';
import concurrentSavePanel from './concurrentSavePanel/concurrentSavePanel';
import saveConfigurationPanel from './saveConfigurationPanel';
import saveReminderPanel from './saveReminderPanel/saveReminderPanel';
import onBoardingMigrationConfirmationPanel from './onBoardingMigrationConfirmationPanel';
import saveYourSitePanel from './saveYourSitePanel';
import feedbackPanel from './feedbackPanel/feedbackPanel';

export {
  chooseDomainPanel,
  siteWasSavedPanel,
  concurrentSavePanel,
  saveConfigurationPanel,
  saveReminderPanel,
  onBoardingMigrationConfirmationPanel,
  saveYourSitePanel,
  feedbackPanel,
};
