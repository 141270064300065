import React from 'react';

import * as util from '@/util';
import * as helpIds from '@/helpIds';
import * as coreBi from '@/coreBi';
import constants from '@/constants';

import connect from './mobileFriendlyPanelConnect';
import {
  NewWorkspaceMobileFriendlyPanelFrame,
  MobileFriendlyPanelFrame,
} from './mobileFriendlyPanelFrame';

import type { SendBiFunction } from 'types/bi';
import type { EditorAPI } from '@/editorAPI';

type OpenHelpCenter = EditorAPI['panelManager']['openHelpCenter'];

interface MobileFriendlyPanelProps {
  panelName: string;
  setMobileOptimizedSite: (val: boolean) => void;
  isMobileOptimizedSite: boolean;
  sendBi: SendBiFunction;
  openHelpCenter: OpenHelpCenter;
  closePanelByName: (panelName: string) => void;
}

class MobileFriendlyPanel extends React.Component<MobileFriendlyPanelProps> {
  protected isNewWorkspace = util.workspace.isNewWorkspaceEnabled();

  toggleMobileOptimized = () => {
    const newIsOptimized = !this.props.isMobileOptimizedSite;
    this.props.setMobileOptimizedSite(newIsOptimized);

    this.props.sendBi(coreBi.events.mobileFriendlyPanel.toggle_click, {
      origin: this.props.panelName,
      status: newIsOptimized,
    });
  };

  close = (origin: string) => {
    this.props.sendBi(coreBi.events.panels.PANEL_CLOSED, {
      panel_name: this.props.panelName,
      origin,
    });

    this.props.closePanelByName(this.props.panelName);
  };

  openHelpCenter = () => {
    const biHelpParams = {
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
    };

    this.props.openHelpCenter(
      helpIds.EXTERNAL.MOBILE_FRIENDLY,
      {},
      biHelpParams,
    );
  };

  render() {
    const PanelFrame = this.isNewWorkspace
      ? NewWorkspaceMobileFriendlyPanelFrame
      : MobileFriendlyPanelFrame;

    return (
      <PanelFrame
        panelName={this.props.panelName}
        onClose={this.close}
        onHelp={this.openHelpCenter}
        toggleMobileOptimized={this.toggleMobileOptimized}
        isMobileOptimizedSite={this.props.isMobileOptimizedSite}
      />
    );
  }
}

const ConnectedMobileFriendlyPanel = connect(MobileFriendlyPanel);

export default ConnectedMobileFriendlyPanel;
