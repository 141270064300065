import {
  getPresets,
  CONFIG,
  type EffectConfig,
  type ScrollEffectConfig,
  type OneOfEntranceEffects,
  type OneOfOngoingEffects,
  type OneOfScrollEffects,
} from '@wix/effects-kit';

export const entranceEffects = getPresets(
  CONFIG.wix.entrance,
) as EffectConfig<OneOfEntranceEffects>[];

export const ongoingEffects = getPresets(
  CONFIG.wix.ongoing,
) as EffectConfig<OneOfOngoingEffects>[];

export const scrollEffects = getPresets(
  CONFIG.wix.scroll,
) as ScrollEffectConfig<OneOfScrollEffects>[];

export const legacyEffects = getPresets(
  CONFIG.wix.entranceLegacy,
) as EffectConfig<OneOfEntranceEffects>[];

export const presets = {
  entrance: entranceEffects.concat(legacyEffects),
  ongoing: ongoingEffects,
  scroll: scrollEffects,
};

export const TEXTS = {
  MAIN: {
    header: 'Animation_panel_header_title_add',
  },
  SETTINGS: {
    header: 'Animation_panel_header_preset_settings_title',
    back_button: 'ANIMATION_SETTINGS_PANEL_BACK_BUTTON',
  },
};
