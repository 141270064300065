import React, { useCallback } from 'react';
import { Text } from '@wix/wix-base-ui';

import { hoc, cx } from '@/util';

import { AUTOMATION_IDS } from './constants';
import styles from './SiteSegmentName.scss';

import {
  type SiteSegmentNameOwnProps,
  type SiteSegmentNameStateProps,
  type SiteSegmentNameDispatchProps,
  mapStateToProps,
  mapDispatchToProps,
} from './SiteSegmentNameMapper';

// TODO: check why exportation didn't work in https://github.com/wix-private/wix-design-systems/pull/9497
import type { TextProps } from '@wix/wix-base-ui';

const stopPropagation = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export interface SiteSegmentNameProps
  extends SiteSegmentNameOwnProps,
    SiteSegmentNameStateProps,
    SiteSegmentNameDispatchProps {}

const SiteSegmentNameComponent: React.FC<SiteSegmentNameProps> = ({
  isSelected,
  isMobile,
  isZoomMode,
  isHeader,
  canSelect,
  selectIfNeeded,
  name,
  labelOffsetRight,
}) => {
  const isSelectedStyle = isSelected || !canSelect;

  const textProps: Omit<TextProps, 'children'> = {
    size: isZoomMode ? 'tiny' : 'small',
    skin: 'standard',
    light: isSelectedStyle,
    shouldTranslate: false,
  };

  const handleContainerClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (!isZoomMode) {
        selectIfNeeded();
      }
    },
    [isZoomMode, selectIfNeeded],
  );

  return (
    <div
      data-hook={AUTOMATION_IDS.NAME_LABEL}
      className={cx(styles.container, {
        [styles.selected]: isSelectedStyle,
        [styles.mobile]: isMobile,
        [styles.zoomMode]: isZoomMode,
        [styles.header]: isHeader,
      })}
      onMouseDown={stopPropagation}
      onMouseUp={stopPropagation}
      onMouseMove={stopPropagation}
      onClick={handleContainerClick}
      style={{ right: labelOffsetRight }}
    >
      <Text {...textProps}>{name}</Text>
    </div>
  );
};

export const SiteSegmentName = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(SiteSegmentNameComponent);
