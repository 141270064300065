// @ts-nocheck
import * as previewHandlers from '../handlers/editorTPAPreviewHandlers';
import * as postMessageCommon from '../common/editorTPAPostMessageCommon';

const handlePreviewMessage = function (editorAPI, event) {
  let msg;
  try {
    if (event.data) {
      if (typeof event.data === 'string') {
        msg = JSON.parse(event.data);
      }
    } else if (event.originalEvent?.data) {
      event = event.originalEvent;
      if (typeof event.data === 'string') {
        msg = JSON.parse(event.data);
      }
    }
  } catch (e) {
    return;
  }

  if (msg && isPreviewMessage(msg) && shouldHandleMessage(msg)) {
    callHandler(editorAPI, msg, generateResponseFunction(event.source, msg));
  }
};

const callHandler = function (editorAPI, msg, response) {
  previewHandlers[msg.type].call(this, editorAPI, msg, response);
};

const generateResponseFunction = function (source, msg) {
  return function (data) {
    const params = {
      intent: 'TPA_PREVIEW_RESPONSE',
      callId: msg.callId,
      type: msg.type,
      res: data,
      status: true,
    };
    postMessageCommon.callPostMessage(source, params);
  };
};

const isPreviewMessage = function (msg) {
  return msg.intent === 'TPA_PREVIEW';
};

function shouldHandleMessage(msg) {
  return Boolean(previewHandlers[msg.type]);
}

const init = function (editorAPI) {
  window.addEventListener(
    'message',
    handlePreviewMessage.bind(this, editorAPI),
    false,
  );
};

export { init, callHandler };
