import _ from 'lodash';
import { isSection } from '../utils';
import { findComponentGroup } from './findComponentGroup';

import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import type { GroupingItem } from '../api/getPageGrouping';

const flattenGroupingChildren = (grouping: GroupingItem[]): Set<string> =>
  new Set(grouping.flatMap((item: GroupingItem) => item.children));

export const validateGrouping = (
  documentServices: DocumentServicesObject,
  grouping: GroupingItem[],
  rootComponents: CompRef[], // sectionable root components
): boolean => {
  const groupingChildren = flattenGroupingChildren(grouping);

  return rootComponents.every((compRef) => groupingChildren.has(compRef.id));
};

// split legacy sections into single group
export function splitSectionChildToSingleGroup(
  documentServices: DocumentServicesObject,
  items: GroupingItem['children'],
) {
  const partitionedItems: GroupingItem['children'][] = [];
  let isLastItemSplit = false;

  items.forEach((compId) => {
    const shouldBeSplit = isSection(
      documentServices,
      documentServices.components.get.byId(compId),
    );

    // create single group for section or new group after section
    const shouldBePushToNewItem =
      shouldBeSplit || isLastItemSplit || partitionedItems.length === 0;

    if (shouldBePushToNewItem) {
      partitionedItems.push([compId]);
    } else {
      partitionedItems[partitionedItems.length - 1].push(compId);
    }

    isLastItemSplit = shouldBeSplit;
  });

  return partitionedItems;
}

export const normalizeAndValidateGrouping = (
  documentServices: DocumentServicesObject,
  grouping: GroupingItem[],
  rootComponents: CompRef[], // sectionable root components
): GroupingItem[] => {
  const rootComponentIds = rootComponents.map(({ id }: CompRef) => id);
  const normalizedGrouping = grouping.reduce((acc, groupItem) => {
    const normalizedChildren = groupItem.children
      .filter((compId) => rootComponentIds.includes(compId))
      .sort(
        (a, b) => rootComponentIds.indexOf(a) - rootComponentIds.indexOf(b),
      );

    const partitionedChildren = splitSectionChildToSingleGroup(
      documentServices,
      normalizedChildren,
    );

    partitionedChildren.forEach((children) => {
      acc.push({
        ...groupItem,
        children,
      });
    });

    return acc;
  }, [] as GroupingItem[]);

  if (normalizedGrouping.length === 0) {
    throw new Error('no valid root components in sections');
  }

  const isValid = validateGrouping(
    documentServices,
    normalizedGrouping,
    rootComponents,
  );

  if (!isValid) {
    throw new Error('missed root components in sections');
  }

  return normalizedGrouping;
};

export function groupMissedComponents(
  documentServices: DocumentServicesObject,
  rootComponents: CompRef[],
  groups: GroupingItem[],
): GroupingItem[] {
  const rootComponentsWithLayout = rootComponents
    .map((compRef) => ({
      compRef,
      layout: documentServices.components.layout.get(compRef),
    }))
    .filter((component) => component.layout);

  const componentsOfGroupsSet = flattenGroupingChildren(groups);
  const [componentsOfGroups, componentsOutOfGroups] = _.partition(
    rootComponentsWithLayout,
    (component) => componentsOfGroupsSet.has(component.compRef.id),
  );

  // create new groups array instead of mutating the original one
  groups = [...groups];
  componentsOutOfGroups.forEach((component) => {
    const componentType = documentServices.components.getType(
      component.compRef,
    );
    const group = findComponentGroup(component, {
      groups,
      componentType,
      componentsOfGroups,
    });

    if (group) {
      groups[groups.indexOf(group)] = {
        ...group,
        children: group.children.concat(component.compRef.id),
      };
    }
  });

  return groups;
}

// collapse group with undefined content role to previous group.
// TPA is installed into following section with undefined content role e.g.
export const collapseRedundantGroups = (
  grouping: GroupingItem[],
): GroupingItem[] =>
  grouping.reduce((acc, item) => {
    const prevIndex = acc.length - 1;
    const prevItem = acc[prevIndex];

    if (!item.contentRole && prevItem?.contentRole) {
      acc[prevIndex] = {
        ...prevItem,
        children: [...prevItem.children, ...item.children],
      };

      return acc;
    }

    acc.push(item);

    return acc;
  }, [] as GroupingItem[]);
