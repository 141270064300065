const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_16",
  preset: { rect: { width: 148, height: 69, x: -1, y: 460 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        groups: {},
        properties: {
          "$st-css":
            ":import{\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: StylableButton\n}\n.root{\n    -st-extends: StylableButton;\n    transition: all 0.2s ease, visibility 0s;\n    border: 0px solid rgb(63, 118, 82);\n    background: rgba(255,255,255,0)\n}\n.root::container{\n    transition: inherit\n}\n.root::label{\n    transition: inherit;\n    font-weight: normal;\n    font-style: italic;\n    font-family: playfairdisplay-bold,\"playfair display\",serif;\n    color: #3F7652;\n    letter-spacing: 0em;\n    font-size: 18px\n}\n.root::icon{\n    transition: inherit;\n    width: 10px;\n    height: 10px;\n    fill: value(site_1_1);\n    display: none\n}\n.root:hover::label {\n    color: #063019;\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "7ud-448",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "7ud-382",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_apply_now"),
      svgId: "b861b040274141de9c08999ec3b76edf.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-kpmmiipp",
    layout: {
      x: 11,
      fixedPosition: false,
      y: 476,
      scale: 1,
      height: 40,
      rotationInDegrees: 0,
      width: 137,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "7ud-358", pageId: "c1dmp" },
    },
    metaData: { sig: "inq-318", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
