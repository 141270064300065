import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import type { GroupingItem } from '../../api/getPageGrouping';
import type { ComponentsCluster } from '@/util';
import { groupComponentsClusters } from '@/util';

export const composeSectionsFromPageChildren = (
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  pageRootComponents: CompRef[],
): GroupingItem[] => {
  const pageTitle = documentServices.pages.getPageTitle(pageRef.id); // check whether we can use compref instead of page ref
  const componentsClustersList = groupComponentsClusters(
    documentServices,
    pageRef,
    pageRootComponents,
  );
  return composeGroupingItems(componentsClustersList, pageTitle);
};

const composeGroupingItems = (
  protoSections: ComponentsCluster[],
  pageTitle: string,
): GroupingItem[] =>
  protoSections.map((section) => ({
    name: pageTitle,
    contentRole: undefined,
    children: section.children.map(({ id }) => id),
  }));
