import React from 'react';
import { translate } from '@/i18n';
import { Button, Composites, RichText, TextLabel } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

interface DescriptionLayoutProps {
  title: string;
  subtitle?: string;
  mainActionLabel: string;
  secondaryActionLabel?: string;
  thirdActionLabel?: string;
  onMainActionClick: () => void;
  onSecondaryActionClick?: () => void;
  onThirdActionClick?: () => void;
  showIconsOnButtons?: boolean;
}

const DescriptionLayout: React.FC<DescriptionLayoutProps> = ({
  title,
  subtitle,
  mainActionLabel,
  secondaryActionLabel,
  thirdActionLabel,
  onMainActionClick,
  onSecondaryActionClick,
  onThirdActionClick,
  children,
  showIconsOnButtons,
}) => (
  <React.Fragment>
    <Composites.RichTextLabeled className="composite-centered">
      <TextLabel
        type="T16"
        value={title}
        automationId="intro-title"
        enableEllipsis={false}
      />

      {subtitle && (
        <RichText type="T11" automationId="intro-subtitle">
          {translate(subtitle)}
        </RichText>
      )}
    </Composites.RichTextLabeled>

    {children}

    <Composites.ActionSetVertical>
      <Button onClick={onMainActionClick} automationId="intro-main-action">
        {showIconsOnButtons && <symbols.symbol name="addPage" />}
        <span>{translate(mainActionLabel)}</span>
      </Button>

      {thirdActionLabel && onThirdActionClick ? (
        <Button
          onClick={onThirdActionClick}
          automationId="intro-third-action"
          className="btn-confirm-secondary"
        >
          {showIconsOnButtons && <symbols.symbol name="addPage" />}
          <span>{translate(thirdActionLabel)}</span>
        </Button>
      ) : null}

      {secondaryActionLabel && onSecondaryActionClick ? (
        <Button
          onClick={onSecondaryActionClick}
          className="btn-text"
          automationId="intro-secondary-action"
        >
          <span>{translate(secondaryActionLabel)}</span>
        </Button>
      ) : null}
    </Composites.ActionSetVertical>
  </React.Fragment>
);

export default DescriptionLayout;
