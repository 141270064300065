export const createMigrationStatus = () => {
  let isMigrationInProgress: boolean | null = null;

  return {
    setMigrationStarted: () => {
      isMigrationInProgress = true;
    },
    setMigrationFinished: () => {
      isMigrationInProgress = false;
    },
    hasProcessed: (): boolean => isMigrationInProgress === false,
    isInProgress: (): boolean => isMigrationInProgress === true,
  };
};
