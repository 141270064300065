// @ts-nocheck
import * as fontUtils from './fontUtils';
import _ from 'lodash';

function insertUploadedFontsToCk(fontNamesArr, textManager) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  _(fontNamesArr)
    .filter(function (name) {
      return fontUtils.isUploadedFont(name);
    })
    .forEach(function (cssName) {
      textManager.execCommand('wixCss', {
        id: cssName,
        value: fontUtils.getUploadedFontFace([cssName]),
      });
    });
}

function expandSelectionToLinks(textManager) {
  textManager.commands.expandCurrentLinkSelection();
}

//todo: text limit utils
function focus(editor) {
  const sel = editor.getSelection();
  sel.ignoreFillingCharRemoveOnSelectionChange();
  editor.focus();
  sel.resumeFillingCharRemoveOnSelectionChange();
}

function toggleSelectionToTransparent(textManager, shouldEnable) {
  textManager.toggleSelectionToTransparent(shouldEnable);
}

function setOverflowCss(textManager) {
  textManager.execCommand('wixCss', {
    id: 'overFlowCss',
    value: 'body {overflow: hidden !important;}',
    isLink: false,
    force: false,
  });
}

function removeOverflowCss(textManager) {
  textManager.execCommand('wixCss', {
    id: 'overFlowCss',
    value: '',
    isLink: false,
    force: false,
  });
}

function registerAfterUndoRedoListener(callback, isUndo, textManager) {
  const snapshot = textManager.getRawData();
  const listener = function () {
    if (_.isEqual(snapshot, textManager.getRawData())) {
      callback();
    }
  };

  if (isUndo) {
    textManager.onAfterUndo(listener);
  } else {
    textManager.onAfterRedo(listener);
  }

  return listener;
}

function setCkReadOnly(textManager, isReadOnly) {
  textManager.setReadOnly(isReadOnly);
}

export {
  focus,
  toggleSelectionToTransparent,
  registerAfterUndoRedoListener,
  setOverflowCss,
  removeOverflowCss,
  setCkReadOnly,
  expandSelectionToLinks,
  insertUploadedFontsToCk,
};
