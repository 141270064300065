import React from 'react';
import ReactDOM from 'reactDOM';
import _ from 'lodash';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import * as UI from '@/baseUI';
import constants from '@/constants';
import { translate } from '@/i18n';
import { Checkbox, CustomScroll } from '@wix/wix-base-ui';

import { CHARACTER_SET_DATA, type CharacterSetItemType } from './constants';
import { ToolPanelFrame } from '../../frames';
import {
  type LanguageSupportProps,
  mapStateToProps,
  mapDispatchToProps,
} from './languageSupport.mapper';

const contentWrapperClass = 'language-support-panel';
const PANEL_DRAG_MARGINS = 30;

interface LanguageSupportState {
  charsItems: CharacterSetItemType[];
  maxPanelContentHeight: number;
  fullPanelHeight: number;
}
class LanguageSupport extends React.Component<
  LanguageSupportProps,
  LanguageSupportState
> {
  constructor(props: LanguageSupportProps) {
    super(props);
    this.state = {
      charsItems: this.getCharacterSetsArray(),
      maxPanelContentHeight: 0,
      fullPanelHeight: 0,
    };
  }

  componentDidMount(): void {
    const panelHeight = document
      .querySelector(`.${contentWrapperClass}`)
      ?.getBoundingClientRect()?.height;

    const contentHeight = ReactDOM.findDOMNode(
      this.refs.contentWrapper,
    )?.getBoundingClientRect()?.height;

    this.setState({
      fullPanelHeight: panelHeight,
      maxPanelContentHeight: contentHeight,
    });
  }

  getBackgroundImage = () => {
    return `url(${util.media.getMediaUrl('textEditor/langs.v1.png')})`;
  };

  setLanguages = () => {
    this.props.sendBI(
      coreBi.events.textControls.text_languages_support_dialog_save_click,
    );
    const languagesToBeSet = this.state.charsItems
      .filter((char) => char.value)
      .map((char) => char.characterSet);

    this.props.updateCharacterSet(languagesToBeSet);
    this.close();
  };

  close = () => {
    this.props.closePanelByName(this.props.panelName);
  };
  update = (charIndex: number, value: boolean) => {
    const charsItems = _.clone(this.state.charsItems);
    const newCharItem = _.clone(charsItems[charIndex]);
    newCharItem.value = value;
    charsItems[charIndex] = newCharItem;
    this.setState({
      charsItems,
    });
  };
  openHelpPanelFrame = () => {
    this.props.sendBI(
      coreBi.events.textControls.text_languages_support_learn_more_click,
    );
    this.props.openHelpCenter(
      '147f770b-8d82-48b9-aea8-2f07d94c0743',
      {},
      {
        panel_name: 'languageSupport',
        origin: 'languageSupport',
      },
    );
  };

  getCharacterSetsArray = () => {
    const possibleCharacterSets = CHARACTER_SET_DATA;

    const backgroundImage = this.getBackgroundImage();

    return possibleCharacterSets.map(
      ({ label, characterSet, tooltip, disabled, position }) => ({
        label,
        characterSet,
        tooltip,
        value: this.props.characterSet.includes(characterSet),
        disabled,
        style: {
          backgroundImage,
          backgroundPosition: position,
          height: '18px',
          width: '72px',
          display: 'inline-block',
        },
      }),
    );
  };
  openCopyright = () => {
    const linkUrl = translate('add_languages_copyright_link');

    if (window) {
      window.open(linkUrl, '_blank');
    }
  };

  getPanelScrollableContentStyle = () => {
    if (!this.state.fullPanelHeight || !this.state.maxPanelContentHeight) {
      return { height: '100%' };
    }

    const diff = this.state.fullPanelHeight - this.props.editorLayout.height;

    const spaceAvailableForContent =
      this.state.maxPanelContentHeight - diff - PANEL_DRAG_MARGINS;

    return {
      height: Math.min(
        spaceAvailableForContent,
        this.state.maxPanelContentHeight,
      ),
    };
  };

  render() {
    const { top, left } = this.props;
    return (
      <ToolPanelFrame
        panelName={this.props.panelName}
        headerTitle={translate('add_languages_header_label')}
        isSingleInstance={true}
        helpId="147f770b-8d82-48b9-aea8-2f07d94c0743"
        ref="frame"
        contentWrapperClass={contentWrapperClass}
        contentStyle={{ top, left }}
        contentClass="language-support-panel-content-wrapper"
      >
        <CustomScroll>
          <div
            className="language-support-panel-body-wrapper"
            ref="contentWrapper"
            style={this.getPanelScrollableContentStyle()}
          >
            <div className="label">{translate('add_languages_text')}</div>

            {this.state.charsItems.map((char, charIndex) => (
              <div
                key={char.label}
                className={util.cx({
                  'language-option': true,
                  disabled: char.disabled,
                })}
              >
                <UI.tooltip
                  value={char.tooltip}
                  disabled={!char.tooltip}
                  styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
                >
                  <Checkbox
                    label={char.label}
                    disabled={char.disabled}
                    value={char.value}
                    onChange={(value) => {
                      this.update(charIndex, value);
                    }}
                    labelAfterSymbol={true}
                  />
                </UI.tooltip>
                {!char.disabled ? (
                  <span key="example-img" className="example-img">
                    <span style={char.style} />
                  </span>
                ) : null}
              </div>
            ))}
          </div>
        </CustomScroll>

        <footer className="language-support-panel_footer" ref="footer">
          <div className="copy-right-text">
            <span>
              {translate('add_languages_copyright_text')}
              <a onClick={this.openCopyright} className="copy-right-link">
                {translate('add_languages_copyright_text_link')}
              </a>
            </span>
          </div>
          <UI.button
            label="add_languages_save_button"
            onClick={this.setLanguages}
            className="btn-confirm-primary"
          />
        </footer>
      </ToolPanelFrame>
    );
  }
}

const Connected = _.flowRight(
  stateManagement.bi.hoc.reportBiEventOnUiChange,
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  ),
)(LanguageSupport);

Connected.pure = LanguageSupport;

export default Connected;
