import type { EditorAPI } from '@/editorAPI';
import type { CompStructure, CompRef } from 'types/documentServices';
import _ from 'lodash';
import addPanelDataConsts from '@/addPanelDataConsts';
import triggerBiEvent from './triggerElementAddedBiEvent';
import * as addPanelAddCompService from '../addPanelAddCompService';
import constants from '../../constants/constants';
import superAppsConstants from '../../superApps/superAppsConstants';
import * as superAppsCustomData from '../../superApps/superAppsCustomData';

const APP_DEF_ID = superAppsConstants.NEW_BLOG_APP_DEF_ID;
const WIDGET_ID = '813eb645-c6bd-4870-906d-694f30869fd9';

const BI_PARAMS = {
  element_type: 'recent_posts_list',
};

const onClick = function (
  compStructure: CompStructure,
  sectionTitle: string,
  tags: string[],
  itemId: string,
  editorAPI: EditorAPI,
  addOptions: AnyFixMe,
) {
  triggerBiEvent(editorAPI, BI_PARAMS);
  return addPanelAddCompService.addCompOnClick(
    editorAPI,
    APP_DEF_ID,
    WIDGET_ID,
    compStructure,
    tags,
    itemId,
    {
      origin: constants.BI.type.ADD_WIDGET,
      sectionTitle: null,
      category: null,
    },
    addOptions,
  );
};

const onDrop = function (
  layoutParams: { x: number; y: number },
  compPreset: CompStructure & { itemId: string },
  parentComponent: CompRef,
  editorAPI: EditorAPI,
) {
  triggerBiEvent(editorAPI, BI_PARAMS);
  return addPanelAddCompService.addCompOnDrop(
    editorAPI,
    APP_DEF_ID,
    WIDGET_ID,
    layoutParams,
    compPreset,
    parentComponent,
    _.noop,
    { origin: constants.BI.type.ADD_WIDGET },
  );
};

function getSection() {
  return {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'add_section_label_newblog_recentposts',
    subNavigationTitle: 'add_section_label_newblog_recentposts',
    presetTitle: 'add_section_label_newblog_recentposts',
    tooltipTitle: 'add_section_label_newblog_recentposts',
    automationId: 'add-panel-section-newBlog-postlisteditorialsmall',
    sectionName: 'recentPostsListSection',
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
      iconEnabledForComps: {},
    },
    props: {
      onClick,
      onDrop,
      image:
        'addPanelData/sections/blogRecentPostsSection_en/blogRecentPostsSection_en.v4.png',
      retinaImage: {
        src: 'addPanelData/sections/blogRecentPostsSection_en/blogRecentPostsSection_en@2x.v4.png ',
        dimensions: {
          width: 648,
          height: 292,
        },
      },
      imageHover: null as AnyFixMe,
      items: [
        {
          id: 'recentPostsList',
          structure: {
            layout: {
              x: 0,
              y: 0,
              fixedPosition: false,
              width: 980,
              height: 140,
              scale: 1,
              rotationInDegrees: 0,
            },
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              widgetId: WIDGET_ID,
              appDefinitionId: APP_DEF_ID,
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              referenceId: '',
            },
            mobileHints: {
              type: 'MobileHints',
              hidden: true,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
            },
            style: {
              type: 'TopLevelStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                'recentPostsList',
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            connections: {
              type: 'ConnectionList',
              items: [] as AnyFixMe,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
            },
            activeModes: {},
          },
          preset: {
            rect: {
              width: 324,
              height: 146,
              x: 0,
              y: 0,
            },
            label: '',
            tags: null as AnyFixMe,
          },
        },
      ],
      compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
    },
    help: { hide: false, text: 'add_section_info_text_customfeed' },
  };
}

export default {
  registerSection(editorAPI: EditorAPI) {
    editorAPI.addPanel.registerSectionAtStart(
      addPanelDataConsts.CATEGORIES_ID.NEW_BLOG,
      _.partial(getSection, editorAPI),
    );
  },
};
