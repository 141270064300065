import _ from 'lodash';
import { calculateSectionsEdge, getLastCompPosition } from './utils';
import { resolveMobileZIndexOverlapping } from './resolveZIndexOverlapping';
import { resolveMobileSmallSections } from './resolveSmallSections';

import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import type { SectionDescription } from '../../types';

function fitTwoSections(
  upperSection: SectionDescription,
  lowerSection: SectionDescription,
): [upperSection: SectionDescription, lowerSection: SectionDescription] {
  let upperSectionBottom = upperSection.mobile.bottom;
  let lowerSectionTop = lowerSection.mobile.top;

  const sectionsIntersectionPoint = calculateSectionsEdge(
    upperSectionBottom,
    upperSection.mobile.isFullWidthBottom,
    lowerSectionTop,
    lowerSection.mobile.isFullWidthTop,
  );

  if (sectionsIntersectionPoint) {
    upperSectionBottom = lowerSectionTop = sectionsIntersectionPoint;
  }

  return [
    {
      ...upperSection,
      mobile: {
        ...upperSection.mobile,
        bottom: upperSectionBottom,
      },
    },
    {
      ...lowerSection,
      mobile: {
        ...lowerSection.mobile,
        top: lowerSectionTop,
      },
    },
  ];
}

export function fitSectionsToEachOther(
  sectionsDescriptions: SectionDescription[],
  yOrder: number[],
  outOfYOrder: number[],
): SectionDescription[] {
  const firstIndex = yOrder[0];
  const fitSections = [];

  fitSections[firstIndex] = sectionsDescriptions[firstIndex];

  for (let yPosition = 0; yPosition < yOrder.length - 1; yPosition++) {
    const [upperSection, lowerSection] = fitTwoSections(
      fitSections[yOrder[yPosition]],
      sectionsDescriptions[yOrder[yPosition + 1]],
    );

    fitSections[yOrder[yPosition]] = upperSection;
    fitSections[yOrder[yPosition + 1]] = lowerSection;
  }

  // keep sections which is hidden on mobile
  outOfYOrder.forEach((index: number) => {
    fitSections[index] = sectionsDescriptions[index];
  });

  return fitSections;
}

function fitSectionsToPage(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  sectionsDescriptions: SectionDescription[],
  yOrder: number[],
): SectionDescription[] {
  const firstIndex = yOrder[0];
  const firstSection = sectionsDescriptions[firstIndex];

  sectionsDescriptions[firstIndex] = {
    ...firstSection,
    mobile: {
      ...firstSection.mobile,
      top: 0,
    },
  };

  const lastIndex = yOrder[yOrder.length - 1];
  const lastSection = sectionsDescriptions[lastIndex];
  const mobilePageRef = documentServices.components.getMobileRef(pageRef);
  const lastCompBottom = getLastCompPosition(documentServices, mobilePageRef);

  sectionsDescriptions[lastIndex] = {
    ...lastSection,
    mobile: {
      ...lastSection.mobile,
      bottom: Math.max(lastSection.mobile.bottom, lastCompBottom),
    },
  };

  return sectionsDescriptions;
}

export function getMobileYOrder(sectionsDescriptions: SectionDescription[]): {
  yOrder: number[];
  outOfYOrder: number[];
} {
  const yOrder: { index: number; top: number }[] = [];
  const outOfYOrder: number[] = [];

  sectionsDescriptions.forEach((section, index) => {
    if (section.mobile) {
      yOrder.push({
        index,
        top: section.mobile.top,
      });
    } else {
      outOfYOrder.push(index); // section is hidden on mobile
    }
  });

  yOrder.sort((a, b) => a.top - b.top);

  return {
    yOrder: yOrder.map((item) => item.index),
    outOfYOrder,
  };
}

export function collocateMobileSections(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  sectionsDescriptions: SectionDescription[],
): SectionDescription[] {
  // mobile and desktop order may be different. by default sections is sorted by desktop order
  // yOrder is order on mobile, arguments are order index on desktop
  // outOfYOrder are sections which is hidden on mobile
  const { yOrder, outOfYOrder } = getMobileYOrder(sectionsDescriptions);

  if (yOrder.length === 0) {
    return sectionsDescriptions;
  }

  return _.flow([
    fitSectionsToEachOther,
    (sectionsDescriptions) =>
      fitSectionsToPage(
        documentServices,
        pageRef,
        sectionsDescriptions,
        yOrder,
      ),
    (sectionsDescriptions) =>
      resolveMobileZIndexOverlapping(
        documentServices,
        pageRef,
        sectionsDescriptions,
        yOrder,
        outOfYOrder,
      ),
    ({ sectionsDescriptions, yOrder, outOfYOrder }) =>
      resolveMobileSmallSections(sectionsDescriptions, yOrder, outOfYOrder),
    ({ sectionsDescriptions }) => sectionsDescriptions,
  ])(sectionsDescriptions, yOrder, outOfYOrder);
}
