'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

const items = [{
    id: 'Other_HTML_&_Flash_2',
    title: 'add_section_heading_custom_embeds_embed_widget_title',
    desc: 'add_section_heading_custom_embeds_embed_widget_description',
    image: 'addPanelData/sections/customEmbeds/embedaWidget.png',
    symbol: 'plusBig',
    helpId: '2c56cce3-4d9f-40b2-b6a5-08138cf3958d',
    learnMoreText: 'add_section_heading_custom_embeds_embed_widget_description_link',
    structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.HtmlComponentSkin',
        layout: {
            width: 230,
            height: 190,
            x: 267,
            y: 41,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'wysiwyg.viewer.components.HtmlComponent',
        componentTypeForBI: 'HtmlCode',
        data: {
            type: 'HtmlComponent',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            url: '',
            sourceType: 'htmlEmbedded',
            freezeFrame: false,
            allowFullScreen: true
        },
        style: {
            type: 'TopLevelStyle',
            id: 'htco1',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            style: {groups: {}, properties: {}, propertiesSource: {}},
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.HtmlComponentSkin'
        },
        id: 'i5rznih2'
    }
}, {
    id: 'Other_HTML_&_Flash_1',
    title: 'add_section_heading_custom_embeds_embed_site_title',
    desc: 'add_section_heading_custom_embeds_embed_site_description',
    image: 'addPanelData/sections/customEmbeds/embedaSite.png',
    symbol: 'plusBig',
    helpId: 'a7c2a674-c8df-4c9d-8868-87745b07f4f9',
    learnMoreText: 'add_section_heading_custom_embeds_embed_site_description_link',
    structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.HtmlComponentSkin',
        layout: {
            width: 230,
            height: 190,
            x: 51,
            y: 41,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'wysiwyg.viewer.components.HtmlComponent',
        componentTypeForBI: 'EmbedSite',
        data: {
            type: 'HtmlComponent',
            metaData: {isPreset: true, schemaVersion: '1.0', isHidden: false},
            url: '',
            sourceType: 'external',
            freezeFrame: false
        },
        style: {
            type: 'TopLevelStyle',
            id: 'htco1',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            style: {groups: {}, properties: {}, propertiesSource: {}},
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.HtmlComponentSkin'
        },
        id: 'i5rznih1'
    }
}, {
    id: 'Other_HTML_&_Flash_4',
    title: 'add_section_heading_custom_embeds_custom_element_title',
    desc: 'add_section_heading_custom_embeds_custom_element_description',
    image: 'addPanelData/sections/customEmbeds/customElement.png',
    symbol: 'plusBig',
    helpId: '06e2726d-4a98-4f9e-b973-60f0bd56d676',
    learnMoreText: 'add_section_heading_custom_embeds_custom_element_description_link',
    structure: {
        layout: {width: 636, height: 384, x: 172, y: 112},
        componentType: 'wixui.CustomElementComponent',
        data: {
            url: 'https://static.parastorage.com/services/wix-ui-santa/1.1581.0/assets/CustomElement/wixDefaultCustomElement.js',
            tagName: 'wix-default-custom-element',
            type: 'CustomElement'
        },
        style: {}
    }
}]

module.exports = {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET_LIST,
    hide: false,
    title: 'add_section_heading_custom_embeds',
    presetTitle: 'add_section_heading_custom_embeds',
    subNavigationTitle: 'add_section_label_custom_embed',
    automationId: 'add-panel-section-customEmbeds',
    subNavigationHide: false,
    showSectionHeader: true,
    props: {
        items
    }
}
