import type { ComponentEditorMetaDataDefinition } from '../types';

import constants from '@/constants';

const metaData: ComponentEditorMetaDataDefinition = {
  nestedBackgroundDesign: true,
  slidesContainer: true,
  focusable: true,
  canContain: false,
  canContainByStructure: false,
  overridesWhenReferred: {
    gfpp: {
      disableMainActionsByLabelKey: [
        constants.BOX_SLIDE_SHOW.GFPP.ACTIONS.LABELS
          .REGULAR_SLIDESHOW_SETTINGS_MANAGE_SLIDES,
      ],
    },
  },
};

export default metaData;
