import type { MediaManagerItem } from '@/mediaServices';
import { hoc } from '@/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import * as mediaPanelConstants from '../mediaManagerPanelConstants/mediaManagerPanelConstants';
import * as mediaPanelPurchaseUtils from '../mediaManagerPanelUtils/mediaManagerPanelPurchaseUtils';
import * as mediaPanelUtils from '../mediaManagerPanelUtils/mediaManagerPanelUtils';
import BaseGenerator from './baseGenerator';
import type {
  DynamicMediaBoxProps,
  DynamicMediaBoxState,
} from './dynamicMediaBox.types';
import { mapDispatchToProps, mapStateToProps } from './dynamicMediaBoxMapper';

class DynamicMediaBox extends React.Component<
  DynamicMediaBoxProps,
  DynamicMediaBoxState
> {
  static propTypes = {
    biOriginBase: PropTypes.string,
    props: PropTypes.shape({
      sectionsIds: PropTypes.arrayOf(
        PropTypes.oneOf(
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/values
          _.values(mediaPanelConstants.MEDIA_MANAGER_PANEL_SECTION_ID),
        ),
      ),
      sections: PropTypes.object,
    }),
  };

  constructor(props: AnyFixMe) {
    super(props);

    this.state = {
      prices: null,
    };

    this.boxItems = new Map();

    _.bindAll(this, [
      'openMediaManager',
      'handleMediaManagerSessionEnd',
      'handleItemClick',
      'buyItem',
      'startItemDrag',
      'registerPayloadResolver',
      'registerItem',
    ]);
  }

  componentDidMount() {
    this.registerPayloadResolver();
  }

  private boxItems: Map<string, MediaManagerItem>;

  get boxProps() {
    return (this.props as AnyFixMe)?.props;
  }

  get sectionsIds() {
    return this.boxProps?.sectionsIds;
  }

  buyItem(item: AnyFixMe, sectionId: AnyFixMe) {
    const { siteMediaToken } = this.props;
    const { biOriginBase } = this.boxProps;

    mediaPanelPurchaseUtils.purchaseItemNewFlow(item, {
      siteToken: siteMediaToken,
      origin: biOriginBase,
      tab: `${biOriginBase}-${sectionId}`,
      locale: this.props.language,
      publicMediaRoot: Object.values(
        mediaPanelConstants.PUBLIC_MEDIA_ROOT,
      ).join(','),
    });
  }

  getCommonSectionProps() {
    return {
      contentStyle: this.props.contentStyle,
      siteMediaToken: this.props.siteMediaToken,
      showSectionHeader: this.props.showSectionHeader,
      disabledContentPaddings: this.props.disabledContentPaddings,
      disabledHeaderMargins: this.props.disabledHeaderMargins,
      language: this.props.language,
      openMediaManager: this.openMediaManager,
      mediaManager: this.props.mediaServices.mediaManager,
      onItemClick: this.handleItemClick,
      buyItem: this.buyItem,
      startItemDrag: this.startItemDrag,
      registerItem: this.registerItem,
    };
  }

  getSectionProps(sectionId: AnyFixMe) {
    return {
      id: sectionId,
      ...this.getCommonSectionProps(),
      ...this.boxProps?.sectionsProps?.[sectionId],
    };
  }

  extendSectionProps(section: AnyFixMe) {
    return {
      ...this.getCommonSectionProps(),
      ...section,
    };
  }

  handleMediaManagerSessionEnd(items: AnyFixMe, info: AnyFixMe) {
    if (this.props.mediaManagerCallbackOverride) {
      this.props.mediaManagerCallbackOverride(items, info);

      return;
    }

    this.handleItemsSelect(items, info);
  }

  handleItemsSelect(items: AnyFixMe, info: AnyFixMe) {
    if (!items) {
      return;
    }

    items.forEach((item: AnyFixMe) => {
      this.handleItemClick({
        item,
        info,
        addingMethod: mediaPanelConstants.ADD_TO_STAGE_METHOD.MEDIA_MANAGER,
      });
    });
  }

  handleItemClick(params: AnyFixMe) {
    const { item, info, addingMethod } = params;
    const compDef = this.generateItemStructure(item);

    this.props.saveLastMediaPath(item.fileName, info?.path);

    this.props.onClick(
      compDef,
      this.props.title,
      undefined,
      item.id,
      undefined,
      {
        addComponentOptions: {
          position: params.index,
          category: this.props.categoryId,
          section: params.sectionTitle,
        },
      },
      addingMethod,
    );
  }

  generateItemStructure(item: AnyFixMe) {
    const { isResponsiveEditor } = this.boxProps;
    const buildDefaultCompStructure =
      this.props.editorBuildDefaultComponentStructure;
    const preparedMediaItem =
      mediaPanelUtils.prepareItemBeforeAddComponent(item);

    if (isResponsiveEditor && mediaPanelUtils.isPicture(preparedMediaItem)) {
      return mediaPanelUtils.addComponentData.imageX(
        preparedMediaItem,
        {},
        buildDefaultCompStructure,
      );
    }

    return mediaPanelUtils.getComponentStructureCreator(preparedMediaItem, {
      isResponsiveEditor,
    })(preparedMediaItem, {}, buildDefaultCompStructure);
  }

  startItemDrag({ event, dragItemInfo }: AnyFixMe) {
    const { item, rect } = dragItemInfo;
    const structure = this.generateItemStructure(item);
    const dragItemData = {
      structure,
      rect,
    };

    _.invoke(this.props, 'onItemDrag', event, dragItemData);
  }

  registerItem(item: AnyFixMe) {
    if (this.boxItems.has(item.id)) {
      return;
    }

    this.boxItems.set(item.id, item);
  }

  getItemNode(mouseEvent: AnyFixMe) {
    const path = mouseEvent.composedPath();

    return path?.find(
      (node: AnyFixMe) =>
        node?.classList?.contains('media-manager-panel-preset'),
    );
  }

  registerPayloadResolver() {
    if (!_.isFunction(this.props.registerPayloadResolver)) {
      return;
    }

    const payloadResolver = (mouseEvent: AnyFixMe) => {
      const itemNode = this.getItemNode(mouseEvent);

      if (!itemNode) {
        return;
      }

      const { x, y, width, height } = itemNode.getBoundingClientRect();
      const itemId = itemNode.getAttribute('data-dynamic-media-item-id');
      const item = this.boxItems.get(itemId);

      if (!item || (item && item.source === 'shutterStock')) {
        return;
      }

      const componentStructure = this.generateItemStructure(item);
      const compHandle = {
        structure: componentStructure,
        position: null as AnyFixMe,
        absoluteDimensions: {
          width: componentStructure.layout.width,
          height: componentStructure.layout.height,
        },
      };
      const responsiveCompStructure =
        this.props.transformStructureToResponsive(compHandle);

      return {
        structure: responsiveCompStructure,
        forceUseActualDimensions: true,
        mouseRelativePosition: {
          x: width / 2,
          y: height / 2,
        },
        placeholderDimensions: {
          width,
          height,
        },
        actualDimensions: {
          width: componentStructure.layout.width,
          height: componentStructure.layout.height,
        },
        placeholderPosition: {
          x,
          y,
        },
        options: {
          appDefinitionId: this.props.appDefinitionId,
        },
      };
    };

    this.props.registerPayloadResolver(payloadResolver);
  }

  openMediaManager(options: AnyFixMe) {
    const {
      mediaServices: { mediaManager },
    } = this.props;
    const { biSource } = options;
    const mediaManagerOpenOptions = { multiSelect: true, path: options.path };

    mediaManager.open(
      mediaManager.categories.ALL_MEDIA,
      `${this.boxProps.biOriginBase || this.props.biOriginBase}_${biSource}`,
      {
        callback: this.handleMediaManagerSessionEnd,
        ...mediaManagerOpenOptions,
      },
    );
  }

  render() {
    const children = this.boxProps.items
      ? this.boxProps.items.map((section: AnyFixMe) => (
          <BaseGenerator
            key={section.id}
            {...this.extendSectionProps(section)}
          />
        ))
      : this.sectionsIds.map((sectionId: AnyFixMe) => (
          <BaseGenerator key={sectionId} {...this.getSectionProps(sectionId)} />
        ));

    return <div>{children}</div>;
  }
}

const ConnectedDynamicMediaBox = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(DynamicMediaBox);

export default ConnectedDynamicMediaBox;
