'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

const moreBehaviorHelper = require("./moreBehaviorHelper")
module.exports =
    {
        "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
        "hide": false,
        "title": "add_section_vectorart_characters_label",
        "subNavigationTitle": "add_section_vectorart_characters_label",
        "presetTitle": "add_section_vectorart_characters_label",
        "tooltipTitle": "add_section_vectorart_characters_label",
        "automationId": "add-panel-section-charactersSection",
        "subNavigationHide": false,
        "showSectionHeader": true,
        "additionalBehaviours": {
            "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
            "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
            "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
            "iconEnabledForComps": {}
        },
        "props": {
            "image": "addPanelData/sections/vectorArt/charactersSection.v1.png",
            "retinaImage": {
                "src": "addPanelData/sections/vectorArt/charactersSection-@2x.v1.png",
                "dimensions": {
                    "width": 648,
                    "height": 1548
                }
            },
            "imageHover": null,
            "items": [{
                "id": "VectorArt_Characters_1",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 140.4,
                        "height": 177.6,
                        "x": 7,
                        "y": 20,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "a2168ad236b147d5ab14d56aab0577d5.svg",
                        "title": "character-Watering-Plants",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w03",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a7w0go"
                },
                "preset": {
                    "rect": {"width": 120, "height": 149, "x": 7, "y": 20},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "15, 20, 34, 11, 56, 18, 136, 69, 131, 156, 122, 163, 45, 180, 22, 166, 2, 43"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"}
            }, {
                "id": "VectorArt_Characters_2",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 156,
                        "height": 157.2,
                        "x": 171,
                        "y": 38,
                        "scale": 1,
                        "rotationInDegrees": 18.460873046937934,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "fbb36649f82946268a053b6a29f627dd.svg",
                        "title": "media-51-01",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w06",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a7w0fz"
                },
                "preset": {
                    "rect": {"width": 171, "height": 171, "x": 152, "y": 20},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "205, 31, 148, 115, 159, 149, 246, 189, 291, 172, 317, 64, 289, 35"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"}
            }, {
                "id": "VectorArt_Characters_3",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 111.6,
                        "height": 183.6,
                        "x": 111,
                        "y": 167,
                        "scale": 1,
                        "rotationInDegrees": 351.17763201028356,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "bbd4e11161ca40dcaaee017923560150.svg",
                        "title": "character-Bear",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w0g",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a7w0gj"
                },
                "preset": {
                    "rect": {"width": 120, "height": 170, "x": 100, "y": 159},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "111, 171, 159, 158, 172, 161, 215, 243, 210, 319, 152, 328, 106, 272"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"}
            }, {
                "id": "VectorArt_Characters_4",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 148,
                        "height": 170,
                        "x": 20,
                        "y": 200,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "549da00763e14a6c92c95204a4e11e59.svg",
                        "title": "media-72-12",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w0c",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a7w0gl"
                },
                "preset": {
                    "rect": {"width": 76, "height": 87, "x": 20, "y": 200},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "19, 200, 67, 188, 102, 214, 101, 263, 74, 293, 32, 294, 11, 265, 6, 217"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"}
            }, {
                "id": "VectorArt_Characters_5",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 97.2,
                        "height": 236.39999999999998,
                        "x": 231,
                        "y": 217,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "4ce608caaa4a4601a04add412bc38402.svg",
                        "title": "character-man",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w0j",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a7w0gs"
                },
                "preset": {
                    "rect": {"width": 82, "height": 201, "x": 231, "y": 217},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "250, 213, 271, 202, 292, 211, 317, 261, 305, 411, 287, 418, 251, 421, 237, 405, 223, 285, 234, 221, 245, 213"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"}
            }, {
                "id": "VectorArt_Characters_6",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 78,
                        "height": 174,
                        "x": 23,
                        "y": 316,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "0223bd71c9a248709677dc55ad6bc8f0.svg",
                        "title": "media-36-02",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w0r",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a7w0gb"
                },
                "preset": {
                    "rect": {"width": 40, "height": 89, "x": 23, "y": 316},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "24, 305, 69, 307, 67, 381, 66, 413, 14, 414, 8, 317"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"}
            }, {
                "id": "VectorArt_Characters_7",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 90,
                        "height": 166,
                        "x": 73,
                        "y": 321,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "996d6f5c214b466ab9a03c8c71c24f9a.svg",
                        "title": "media-36-05",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w0n",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a7w0ge"
                },
                "preset": {
                    "rect": {"width": 45, "height": 84, "x": 73, "y": 321},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "72, 314, 123, 315, 122, 413, 69, 410"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"}
            }, {
                "id": "VectorArt_Characters_8",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 268.8,
                        "height": 249.6,
                        "x": 80,
                        "y": 327,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "ca27cf6ec087418e8d538ed1cdc1e5b6.svg",
                        "title": "Girl with Tiger",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w0v",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a7w0g1"
                },
                "preset": {
                    "rect": {"width": 224, "height": 208, "x": 80, "y": 330},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "170, 328, 139, 418, 112, 423, 102, 466, 75, 485, 78, 512, 176, 546, 209, 545, 284, 487, 313, 438, 304, 420, 269, 426, 263, 438, 229, 429, 219, 346, 200, 323"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"}
            }, {
                "id": "VectorArt_Characters_9",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 93.6,
                        "height": 176.4,
                        "x": 19,
                        "y": 435,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "bc0255ce735b45baaa1c2be1ef49ed41.svg",
                        "title": "media-3-03",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w12",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a7w0g7"
                },
                "preset": {
                    "rect": {"width": 78, "height": 147, "x": 19, "y": 435},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "25, 426, 56, 424, 71, 473, 74, 524, 107, 538, 109, 552, 67, 588, 39, 591, 4, 512"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"}
            }, {
                "id": "VectorArt_Characters_10",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 112,
                        "height": 152,
                        "x": 252,
                        "y": 511,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "8946f60c4ed847dfb9ca8af10287ea7f.svg",
                        "title": "media-79-02",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w1b",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a7w0g5"
                },
                "preset": {
                    "rect": {"width": 56, "height": 76, "x": 252, "y": 511},
                    "tags": null,
                    "shape": {"name": "poly", "coords": "272, 502, 298, 501, 318, 554, 312, 602, 260, 592, 241, 562"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"}
            }, {
                "id": "VectorArt_Characters_11",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 178.79999999999998,
                        "height": 104.39999999999999,
                        "x": 91,
                        "y": 554,
                        "scale": 1,
                        "rotationInDegrees": 27.537584262930295,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "4d768e034e0a48a3b0fb2288d775f491.svg",
                        "title": "media-86-26",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w17",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a7w0g9"
                },
                "preset": {
                    "rect": {"width": 176, "height": 151, "x": 79, "y": 526},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "77, 587, 106, 566, 168, 553, 231, 564, 255, 590, 255, 604, 242, 614, 220, 617, 198, 668, 181, 668, 79, 610"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"}
            }, {
                "id": "VectorArt_Characters_12",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 189.6,
                        "height": 176.4,
                        "x": 12,
                        "y": 596,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "e5405e46d0fc4abfad27ead8620a7a66.svg",
                        "title": "media-28-03",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w1j",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a7w0g3"
                },
                "preset": {
                    "rect": {"width": 159, "height": 147, "x": 12, "y": 599},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "47, 594, 71, 593, 84, 637, 144, 653, 178, 711, 180, 752, 6, 753, 5, 697, 15, 654"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"}
            }, {
                "id": "VectorArt_Characters_13",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 99.6,
                        "height": 163.2,
                        "x": 212,
                        "y": 608,
                        "scale": 1,
                        "rotationInDegrees": 13.409017039809385,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "750ffecd0e1e41709b6520fb1841ba2e.svg",
                        "title": "media-101-08",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "mobileHints": {"type": "MobileHints", "hidden": false},
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w1f",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a7w0gg"
                },
                "preset": {
                    "rect": {"width": 116, "height": 156, "x": 199, "y": 600},
                    "tags": null,
                    "shape": {
                        "name": "poly",
                        "coords": "268, 602, 296, 604, 310, 654, 302, 753, 241, 758, 193, 693, 229, 625"
                    }
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"}
            }],
            "compTypes": ["wysiwyg.viewer.components.VectorImage"]
        },
        "sectionFooter": {
            "styleOverride": {"paddingTop": "25px", "paddingBottom": "30px"},
            "label": "add_section_vectorart_characters_more_tooltip_title",
            "action": moreBehaviorHelper,
            "category": "VECTOR_ART",
            "openSource": "add_panel_vector_art_characters",
            "path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"
        },
        "help": {"hide": false, "text": "add_section_vectorart_characters_tooltip_description"}
    }
