:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._workspace-modes-left-panel_377bb_7 {
  height: calc( 100vh - var(--g-top-bar-height) - 64px);
  width: 320px;
  text-align: center;
  padding: 85px 24px 24px; }
  ._workspace-modes-left-panel_377bb_7 ._icon_377bb_12 {
    width: 120px;
    height: 120px; }
  ._workspace-modes-left-panel_377bb_7 ._title-container_377bb_15 {
    margin: 18px auto 0;
    text-align: center;
    max-width: 280px; }
  ._workspace-modes-left-panel_377bb_7 ._subtitle_377bb_19 {
    margin: 6px auto 18px;
    max-width: 267px;
    text-align: center; }
  ._workspace-modes-left-panel_377bb_7 ._button_377bb_23 {
    max-width: 240px;
    margin: 24px auto 0;
    display: block;
    text-align: center; }
