// @ts-nocheck
function getCustomizationsForFeed() {
  return [
    getCustomizationForView('MasonryPage'),
    getCustomizationForView('MediaBottomPage'),
    getCustomizationForView('MediaLeftPage'),
    getCustomizationForView('MediaRightPage'),
    getCustomizationForView('MediaTopPage'),
    getCustomizationForView('MediaZigzagPage'),
  ];
}

function getCustomizationsForCustomFeed() {
  return [
    getCustomizationForView('Masonry'),
    getCustomizationForView('MediaBottom'),
    getCustomizationForView('MediaLeft'),
    getCustomizationForView('MediaRight'),
    getCustomizationForView('MediaTop'),
    getCustomizationForView('MediaZigzag'),
  ];
}

function getCustomizationForView(name) {
  return {
    type: 'AppPartCustomization',
    forType: 'Array',
    format: '*',
    view: name,
    fieldId: 'vars',
    key: 'pageNavigationType',
    value: 'pagination',
  };
}

export { getCustomizationsForFeed, getCustomizationsForCustomFeed };
