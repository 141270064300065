import React, { PureComponent } from 'react';
import _ from 'lodash';

import * as util from '@/util';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import type { ProgressStatus } from '@/constants';
import type { SaveInteractionStartedSource } from 'types/fedops/saveInteraction';

import withSendBi, { type WithSendBiProps } from '../../hocs/withSendBi';
import withOpenDropPanel from '../../hocs/withOpenDropPanel';
import withDropDown, {
  type ComponentWithDropDownOnHoverProps,
} from '../../hocs/withDropDownOnHover';
import withCustomDropPanel from '../../hocs/withCustomDropPanel';
import withSaveDropPanel from './withDropPanel';

import * as topBarData from '../../topBarComponent/topBarData';
import TopBarButton from '../../topBarButton/topBarButton';
import ActionSuccessSymbol from '../../actionSuccess/actionSuccessSymbol';

import { mapDispatchToProps, mapStateToProps } from './saveMappers';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const { progressButtonClassNames } = topBarData;
const { MODES, BUTTONS } = constants.ROOT_COMPS.TOPBAR;

const isDoneSuccessfully = (status: ProgressStatus) =>
  status === constants.PROGRESS_STATUS.DONE_SUCCESS;

const shouldShowSuccessAnimation = (props: AnyFixMe) =>
  isDoneSuccessfully(props.status);

const getStatusClassName = (status: ProgressStatus): string =>
  progressButtonClassNames[status];

export interface SaveButtonOwnProps {
  className?: string;
  isZoomMode?: boolean;
}

export interface SaveButtonStateProps {
  status: ProgressStatus;
  isManualSave: boolean;
  disabled?: boolean;
  isSitePublished?: boolean;
  topBarStateBIParamValue: string;
}

export interface SaveButtonDispatchProps {
  save: (sourceOfStart: SaveInteractionStartedSource) => void;
}

type SaveButtonProps = SaveButtonOwnProps &
  SaveButtonStateProps &
  SaveButtonDispatchProps &
  WithSendBiProps &
  ComponentWithDropDownOnHoverProps;

class SaveButton extends PureComponent<SaveButtonProps> {
  handleClick = () => {
    this.props.save('topBar_saveButton');
    util.editorWixRecorder.addLabel('save top bar clicked');
    this.props.sendBi(coreBi.events.topbar.top_bar_click, {
      category: 'save',
      origin: this.props.isZoomMode ? MODES.ZOOM_OUT : MODES.EDITOR,
      is_published: this.props.isSitePublished,
      state: this.props.topBarStateBIParamValue,
    });
  };

  render() {
    const label = translate('TOPBAR_Site_Save');
    const className = util.cx(
      'top-bar-save-btn',
      this.props.className,
      getStatusClassName(this.props.status),
    );
    const customSymbol = shouldShowSuccessAnimation(this.props)
      ? React.createElement(ActionSuccessSymbol)
      : null;

    return (
      <TopBarButton
        automationId="top-bar-button-save"
        className={className}
        label={label}
        onClick={this.handleClick}
        disabled={this.props.disabled}
        customSymbol={customSymbol}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      />
    );
  }
}

const ConnectedSaveButton = _.flow(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
  withDropDown,
  withCustomDropPanel(BUTTONS.SAVE),
  withSaveDropPanel,
  withOpenDropPanel,
  withSendBi,
)(SaveButton) as React.ComponentType<SaveButtonOwnProps>;

export default ConnectedSaveButton;
