// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import React from 'react';
import { translate } from '@/i18n';
import * as baseUI from '@/baseUI';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'superAppTooltipUpgrade',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    title: PropTypes.string,
    text: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.string,
    upgrade: PropTypes.func,
  },
  render() {
    return (
      <div
        className={cx({
          superAppTooltipUpgrade: true,
        })}
      >
        <div className="wix-store-upgrade-left">
          {this.props.title ? (
            <h3 key="upgradeTitle" className="upgrade-title">
              {translate(this.props.title)}
            </h3>
          ) : null}
          {this.props.text ? (
            <p key="upgradeContent" className="upgrade-content">
              {translate(this.props.text)}
            </p>
          ) : null}
          <baseUI.button
            label={this.props.label}
            onClick={() => {
              this.props.upgrade();
            }}
            className={cx({
              'upgrade-button': true,
            })}
          />
        </div>
        <symbols.symbol name={this.props.image} />
      </div>
    );
  },
});
