// @ts-nocheck
import _ from 'lodash';
import * as helpIds from '@/helpIds';
import * as stateManagement from '@/stateManagement';
import * as styles from '@/styles';
import consts from '../../utils/gfppConsts';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = consts;
const COMPONENT_OPEN_STATE =
  styles.advancedStyle.advancedStyleDataUtil.STATES.OPEN;
const { getComponentSkinExports } = stateManagement.components.selectors;

function getMobileEnabledActions(editorAPI, selectedComponents) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/concat
  const selectedComponent = _(selectedComponents).concat().head();
  const exports = getComponentSkinExports(selectedComponent, editorAPI.dsRead);
  const mobileEnabledActions = [
    ACTIONS.SETTINGS,
    ACTIONS.DESIGN,
    ACTIONS.CUSTOMIZE_DESIGN,
    ACTIONS.HELP,
  ];

  if (exports['has-alignment']) {
    mobileEnabledActions.push(ACTIONS.LAYOUT);
  }

  return mobileEnabledActions;
}

export default {
  mobileMainActions: [
    {
      label: 'gfpp_mainaction_set_up_quick_actions',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.MANAGE),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.MANAGE),
    },
  ],
  mobileEnabledActions: getMobileEnabledActions,
  presetActions: {
    help: {
      mobileHelpId: helpIds.GFPP.QUICK_ACTION_BAR,
    },
  },
  previewState: COMPONENT_OPEN_STATE,
};
