import type { Account } from '@wix/ambassador-identity-account-v2-account/build/cjs/types.impl';

export const SET_ACCOUNTS = 'SET_ACCOUNTS' as const;
export const SET_IS_STUDIO_ACCOUNT = 'SET_IS_STUDIO_ACCOUNT' as const;

const setAccounts = (payload: { accounts: Account[] }) => ({
  type: SET_ACCOUNTS,
  payload,
});

const setIsStudioAccount = (isStudioAccount: boolean) => ({
  type: SET_IS_STUDIO_ACCOUNT,
  isStudioAccount,
});

export const accountActions = { setAccounts, setIsStudioAccount };

export type AccountActions =
  | ReturnType<typeof setAccounts>
  | ReturnType<typeof setIsStudioAccount>;
