// @ts-nocheck
import _ from 'lodash';

const isDataDefined = (compData, key) =>
  compData &&
  ((!_.isObject(compData[key]) && !!compData[key]) ||
    !_.isEmpty(compData[key]));

export default {
  isDataDefined,
};
