import _ from 'lodash';
import * as util from '@/util';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import {
  bi,
  components,
  type EditorState,
  interactions,
  selection,
} from '@/stateManagement';

import {
  getLayout,
  getComponentUIColor,
  isComponentBoxRotated,
} from './common';
import {
  shouldApplySectionResizeIndication,
  isSectionsOnStageEnabled,
} from '@/sectionsOnStage';
import { sections as sectionsUtils } from '@/util';
import { getStateMapperArgsFromEditorAPI } from '../../selectors/rootSelectors';
import { canShowAddSectionSelector } from '../../addSection/AddSection.selectors';
import { startResizeAndPush } from './knobsBoxResizeActions';

import type {
  Dispatch,
  MapDispatchToProps,
  MapStateToProps,
  StateMapperArgs,
  ThunkAction,
} from 'types/redux';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type {
  KnobsBoxOwnProps,
  KnobsBoxStateProps,
  KnobsBoxDispatchProps,
} from './knobsBox';

const componentsSelectors = components.selectors;
const { getSelectedCompRestrictions, getSelectedCompsRefs } =
  selection.selectors;
const { isInInteractionMode } = interactions.selectors;

const getResizeAndPushOnlyComps = () =>
  isSectionsOnStageEnabled() || sectionsUtils.isSectionsEnabled()
    ? {
        DESKTOP: {},
        MOBILE: {
          'wysiwyg.viewer.components.SiteRegionContainer': true,
        },
      }
    : {
        DESKTOP: {
          'wysiwyg.viewer.components.HeaderContainer': true,
          'wysiwyg.viewer.components.FooterContainer': true,
        },
        MOBILE: {
          'wysiwyg.viewer.components.HeaderContainer': true,
          'wysiwyg.viewer.components.SiteRegionContainer': true,
          'wysiwyg.viewer.components.FooterContainer': true,
        },
      };

function shouldShowResizeAndPushKnobOnly(
  isMobile: AnyFixMe,
  compTypes: AnyFixMe,
) {
  const RESIZE_AND_PUSH_KNOBS_ONLY_COMPS = getResizeAndPushOnlyComps();

  const pushKnobsOnlyComponents = isMobile
    ? RESIZE_AND_PUSH_KNOBS_ONLY_COMPS.MOBILE
    : RESIZE_AND_PUSH_KNOBS_ONLY_COMPS.DESKTOP;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(compTypes, function (compType) {
    return pushKnobsOnlyComponents[
      compType as keyof typeof pushKnobsOnlyComponents
    ];
  });
}

function shouldShowDragAndPushKnob(
  editorAPI: EditorAPI,
  editorState: EditorState,
  selectedComponents: AnyFixMe,
  layout: AnyFixMe,
  restrictions: AnyFixMe,
) {
  if (isInInteractionMode(editorState)) {
    return false;
  }
  const isContainsSiblingsOnly =
    editorAPI.components.layout.containsSiblingsOnly(selectedComponents);
  const isPinned = editorAPI.components.layout.isPinned(selectedComponents);
  const isDescendantOfRepeater =
    editorAPI.components.is.repeaterItem(selectedComponents) ||
    editorAPI.components.is.descendantOfRepeaterItem(selectedComponents);
  const isComponentRotated =
    layout.rotationInDegrees !== 0 && restrictions.rotatable;

  const isSectionLike =
    util.sections.isSectionsEnabled() &&
    editorAPI.sections.isSectionLike(selectedComponents);

  const isFullWidth =
    util.sections.isSectionsEnabled() &&
    editorAPI.components.is.fullWidth(selectedComponents);

  const isMultiselect = selectedComponents.length > 1;

  return (
    // NOTE: Hide drag & push handle for the multiple components in the TB, until it is fixed
    !isMultiselect &&
    isContainsSiblingsOnly &&
    restrictions.verticallyMovable &&
    restrictions.anchorableFrom &&
    !isPinned &&
    !isDescendantOfRepeater &&
    !isComponentRotated &&
    !isFullWidth &&
    !isSectionLike
  );
}

function shouldShowResizeAndPushKnob(
  editorAPI: EditorAPI,
  editorState: EditorState,
  selectedComponents: AnyFixMe,
  layout: AnyFixMe,
  restrictions: AnyFixMe,
) {
  if (isInInteractionMode(editorState)) {
    return false;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  const isPopupContainer = _.some(selectedComponents, function (comp) {
    const type = editorAPI.components.getType(comp);
    return type === constants.COMP_TYPES.POPUP_CONTAINER;
  });

  const isDescendantOfRepeater =
    editorAPI.components.is.repeaterItem(selectedComponents) ||
    editorAPI.components.is.descendantOfRepeaterItem(selectedComponents);
  const isComponentRotated = layout.rotationInDegrees !== 0;
  const isMultiselect = util.array.isMultiselect(selectedComponents);
  const isGroup = editorAPI.components.is.group(selectedComponents);
  const isPinned = editorAPI.components.layout.isPinned(selectedComponents);

  const isSectionLike =
    util.sections.isSectionsEnabled() &&
    editorAPI.sections.isSectionLike(selectedComponents);

  return (
    !isMultiselect &&
    restrictions.verticallyResizable &&
    restrictions.canResizeWithAnchors &&
    !restrictions.resizeOnlyProportionally &&
    !isPinned &&
    !isPopupContainer &&
    !isGroup &&
    !isDescendantOfRepeater &&
    !isComponentRotated &&
    !isSectionLike
  );
}

function getAllowedKnobsMap(
  editorAPI: EditorAPI,
  editorState: EditorState,
  selectedComponents: AnyFixMe,
  layout: AnyFixMe,
  restrictions: AnyFixMe,
  compType: AnyFixMe,
  compTypes: AnyFixMe,
  isMultiselect: AnyFixMe,
) {
  //eslint-disable-line complexity
  const isPage = editorAPI.components.is.page(selectedComponents);

  const resizeSides = editorAPI.dsRead.components.layout.RESIZE_SIDES;
  const enabledKnobs = _.difference(
    restrictions.resizableSides,
    restrictions.disabledKnobs,
  );
  const isPinned = editorAPI.components.layout.isPinned(selectedComponents);
  const dockData: any =
    (!util.array.isMultiselect(selectedComponents) &&
      // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
      editorAPI.components.layout.getDock(selectedComponents)) ||
    {};

  const isHorizontallyStretchedToScreen =
    editorAPI.components.layout.isHorizontallyStretchedToScreen(
      selectedComponents,
    );
  const diagonalResize = restrictions.canResizeDiagonally;
  const isResizeOnlyProportionally = restrictions.resizeOnlyProportionally;
  const isGroup = editorAPI.components.is.group(selectedComponents);

  const shouldShowResizeAndPushOnly = shouldShowResizeAndPushKnobOnly(
    editorAPI.isMobileEditor(),
    compTypes,
  );

  const shouldShowTopKnob =
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(enabledKnobs, resizeSides.TOP) &&
    !shouldShowResizeAndPushOnly &&
    (isPinned || restrictions.verticallyResizable);
  const shouldShowBottomKnob =
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(enabledKnobs, resizeSides.BOTTOM) &&
    !shouldShowResizeAndPushOnly &&
    (isPinned || restrictions.verticallyResizable);
  const shouldShowLeftKnob =
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(enabledKnobs, resizeSides.LEFT) &&
    (isPinned || restrictions.horizontallyResizable);
  const shouldShowRightKnob =
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(enabledKnobs, resizeSides.RIGHT) &&
    (isPinned || restrictions.horizontallyResizable);

  const shouldShowTopLeftKnob =
    !isHorizontallyStretchedToScreen &&
    ((shouldShowTopKnob && shouldShowLeftKnob && diagonalResize) ||
      (isResizeOnlyProportionally && !dockData.top && !dockData.left));
  const shouldShowTopRightKnob =
    !isHorizontallyStretchedToScreen &&
    ((shouldShowTopKnob && shouldShowRightKnob && diagonalResize) ||
      (isResizeOnlyProportionally && !dockData.top && !dockData.right));
  const shouldShowBottomLeftKnob =
    !isHorizontallyStretchedToScreen &&
    ((shouldShowBottomKnob && shouldShowLeftKnob && diagonalResize) ||
      (isResizeOnlyProportionally && !dockData.bottom && !dockData.left));
  const shouldShowBottomRightKnob =
    !isHorizontallyStretchedToScreen &&
    ((shouldShowBottomKnob && shouldShowRightKnob && diagonalResize) ||
      (isResizeOnlyProportionally && !dockData.bottom && !dockData.right));

  // @ts-expect-error
  const gaps = editorAPI.siteSegments.getGaps();
  const isFooter = compType === 'FooterContainer';
  const hasPagesToFooterGap = _.has(gaps, 'pagesToFooterGap');
  const isFooterWithPageGap = isFooter && hasPagesToFooterGap;
  const isPageWithHeaderGap = isPage && _.has(gaps, 'headerToPagesGap');
  const isPopupPage =
    isPage &&
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    _.some(selectedComponents, (comp) =>
      editorAPI.pages.popupPages.isPopup(comp.id),
    );

  return {
    top: !isMultiselect && !isGroup && shouldShowTopKnob,
    bottom: !isMultiselect && !isGroup && shouldShowBottomKnob,
    left: !isMultiselect && !isGroup && shouldShowLeftKnob,
    right: !isMultiselect && !isGroup && shouldShowRightKnob,
    topLeft: shouldShowTopLeftKnob,
    topRight: shouldShowTopRightKnob,
    bottomLeft: shouldShowBottomLeftKnob,
    bottomRight: shouldShowBottomRightKnob,
    closeGaps: isPageWithHeaderGap || isFooterWithPageGap,
    dragAndPush: shouldShowDragAndPushKnob(
      editorAPI,
      editorState,
      selectedComponents,
      layout,
      restrictions,
    ),
    resizeOnlyProportionally: restrictions.resizeOnlyProportionally,
    resizeAndPush: shouldShowResizeAndPushKnob(
      editorAPI,
      editorState,
      selectedComponents,
      layout,
      restrictions,
    ),
    rotate: restrictions.rotatable && !isPinned,
    bottomPage: util.sections.isSectionsEnabled()
      ? false
      : (isPage && !isPopupPage) || (isFooter && !hasPagesToFooterGap),
  };
}

const getDragAndHandlersClass = (layout: AnyFixMe) => {
  const widthLimit = 400;
  if (layout.width >= widthLimit) {
    return `showHover`;
  }
  return undefined;
};

function getParentRotationInDegrees(editorAPI: EditorAPI, comps: CompRef[]) {
  const parentContainer = editorAPI.components.getContainer(comps);
  return (
    parentContainer &&
    editorAPI.components.layout.get_rotationInDegrees(parentContainer)
  );
}

const shouldApplyNewVerticalResizeDesign = (
  editorAPI: EditorAPI,
  {
    isMultiselect,
    selectedComp,
  }: { isMultiselect: boolean; selectedComp: CompRef },
) => {
  if (isMultiselect) {
    return false;
  }

  if (sectionsUtils.isSectionsEnabled()) {
    return util.sections.shouldApplyNewResizeDesign(editorAPI, selectedComp);
  }

  if (isSectionsOnStageEnabled()) {
    return (
      canShowAddSectionSelector(getStateMapperArgsFromEditorAPI(editorAPI)) &&
      shouldApplySectionResizeIndication(editorAPI, selectedComp)
    );
  }

  return false;
};

export const mapStateToProps: MapStateToProps<
  KnobsBoxStateProps,
  KnobsBoxOwnProps
> = ({ editorAPI, state: editorState, dsRead }: StateMapperArgs) => {
  //////common

  const comps: CompRef[] = getSelectedCompsRefs(editorState);
  const layout = getLayout(editorAPI, comps);
  const isMultiselect = util.array.isMultiselect(comps);

  const compType = componentsSelectors.getCompTypeSuffix(comps, dsRead);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const compTypes = _.map(comps, function (comp) {
    return editorAPI.components.getType(comp);
  });

  const isPage = editorAPI.components.is.page(comps);
  const pagesContainer = dsRead.siteSegments.getPagesContainer();

  const compRestrictions = isPage
    ? editorAPI.getCompRestrictions(pagesContainer)
    : getSelectedCompRestrictions(editorState);

  const compsWithoutSelectedAncestors =
    editorAPI.components.getComponentsWhichDontHaveAncestorsInTheArray(comps);
  /////common

  const parentRotationInDegrees = getParentRotationInDegrees(editorAPI, comps);

  const compToDragAndPush = isPage
    ? [pagesContainer]
    : compsWithoutSelectedAncestors;

  const shouldChangeVerticalResizeDesign = shouldApplyNewVerticalResizeDesign(
    editorAPI,
    {
      isMultiselect,
      selectedComp: comps[0],
    },
  );

  const isBoxRotated = isComponentBoxRotated({
    compLayout: layout,
    compRestrictions,
  });

  return {
    compToDragAndPush,
    knobsMap: getAllowedKnobsMap(
      editorAPI,
      editorState,
      comps,
      layout,
      compRestrictions,
      compType,
      compTypes,
      isMultiselect,
    ),
    componentUIColor: getComponentUIColor(editorAPI, comps),
    dragAndHandlersClass: getDragAndHandlersClass(layout),
    rotationInDegrees: layout.rotationInDegrees,
    parentRotationInDegrees,
    //@ts-expect-error
    gaps: editorAPI.siteSegments.getGaps(),
    isBoxRotated,
    isPage,
    comps,
    compType,
    componentType: isMultiselect ? 'multiselect' : compType,
    shouldChangeVerticalResizeDesign,
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  KnobsBoxDispatchProps,
  KnobsBoxOwnProps
> = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  const getSelectedComponentBiFields = () => {
    const selectedComponents = editorAPI.selection.getSelectedComponents();

    return editorAPI.bi.getComponentsBIParams(
      selectedComponents,
    )[0] as any as Record<string, string>;
  };

  return {
    registerMouseMoveAction: editorAPI.mouseActions.registerMouseMoveAction,
    // @ts-expect-error
    updateGaps: editorAPI.siteSegments.updateGaps,
    addToHistory: editorAPI.history.add,
    setRotateKnobRect: editorAPI.setEditBoxRotateKnobRect,
    setIsMouseUpSelectionEnabled:
      editorAPI.selection.setIsMouseUpSelectionEnabled,
    updateLayout: editorAPI.components.layout.update,
    biEvent: (biEventDefinition, biEventFields) =>
      dispatch(bi.actions.event(biEventDefinition, biEventFields)),
    getSelectedComponentBiFields,
    startResizeAndPush: (event, components) =>
      dispatch(startResizeAndPush(event, components)),
    reportComponentResized: (
      componentType: string,
      directionWithRotation: string,
    ) => {
      editorAPI.bi.event(coreBi.events.editBox.RESIZE, {
        ...getSelectedComponentBiFields(),
        origin: 'editBox',
        handle_value: directionWithRotation,
        component_type: componentType,
        appId:
          editorAPI.components.data.get(
            editorAPI.selection.getSelectedComponents()[0],
          )?.appDefinitionId || null,
      });
    },
  };
};
