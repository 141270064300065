import React, { type FC } from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import MessagePanelFrame from '../frames/messagePanelFrame/messagePanelFrame';
import { translate } from '@/i18n';

export interface AiWriterErrorPanelProps {
  closePanel: () => void;
}

const aiWriterErrorPanel: FC<AiWriterErrorPanelProps> = ({ closePanel }) => (
  <MessagePanelFrame
    panelName={translate('ai_text_to_page_high_traffic_error_title')}
    title={translate('ai_text_to_page_high_traffic_error_title')}
    onConfirm={closePanel}
    onCancel={closePanel}
    confirmLabel={translate('ai_text_to_page_high_traffic_error_cta')}
    illustration={<Symbol name="serverError_NewWorkspace" />}
    onSecondaryAction={closePanel}
    cancelOnClickOutside={true}
    cancelOnEscPress={true}
    dataHook="ai-writer-error-panel"
  >
    {translate('ai_text_to_page_high_traffic_error_text')}
  </MessagePanelFrame>
);

export default aiWriterErrorPanel;
