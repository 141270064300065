'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    hide: false,
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    title: 'add_section_label_line',
    presetTitle: 'add_section_label_line',
    tooltipTitle: 'add_section_label_line_tooltip_title',
    subNavigationTitle: 'add_section_label_line',
    automationId: "add-panel-section-lineSection",
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
        labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        iconEnabledForComps: {}
    },
    props: {
        image: 'addPanelData/sections/socialLineSection_en/line_share_with_hit_area.png',
        items: [{
            id: 'Social_Line_LineShareButton_1',
            structure: {
                type: 'Component',
                componentType: 'wixui.LineShareButton',
                skin: 'wixui.skins.LineShareButton',
                layout: {
                    x: 100,
                    y: 100,
                    width: 57,
                    height: 20
                },
                props: {
                    type: 'LineShareButtonProperties',
                    buttonType: 'button'
                },
                style: {
                    type: 'TopLevelStyle',
                    skin: 'wixui.skins.LineShareButton'
                }
            },
            preset: {
                label: 'add_preset_Social_line_share',
                labelClasses: 'align-left',
                rect: {
                    width: 324,
                    height: 64,
                    x: 0,
                    y: 0
                }
            }
        }],
        compTypes: ['wixui.LineShareButton']
    },
    help: {
        hide: false,
        'text': 'add_section_label_line_tooltip_description'
    }
}
