// @ts-nocheck
import addPanelDataConsts from '@/addPanelDataConsts';
const compTypes = [
  'wysiwyg.viewer.components.DynamicContactForm',
  'wysiwyg.viewer.components.ContactForm',
  'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
];

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
  hide: false,
  title: 'add_section_label_my_contact',
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    iconEnabledForComps: {
      'wysiwyg.common.components.subscribeform.viewer.SubscribeForm': {
        title: 'preset_info_title_subscribeform',
        text: 'preset_info_text_subscribeform',
      },
    },
  },
  props: {
    compTypes,
    verticalMargin: 30,
    horizontalMargin: 20,
    maxFontSize: 15,
    scaleProportion: {
      x: 0.5,
      y: 0.5,
    },
  },
  help: {
    hide: false,
    text: 'add_section_info_text_my_general',
  },
};
