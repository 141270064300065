import constants, {
  LEGACY_WIX_APPS_NAMES,
  LEGACY_WIX_APPS_NAMES_TO_HELP_ID_MAP,
} from '@/constants';
import coreUtilsLib from 'coreUtilsLib';
import experiment from 'experiment';
import * as blogManagerFrame from '../blogManager/blogManagerFrame';
import * as blogUtils from '../utils/blogUtils';
import type { EditorAPI } from '@/editorAPI';
import type { GFPPData, GFPPDataRaw } from '@wix/editor-component-types';
import type { CompRef } from 'types/documentServices';

const { blogAppPartNames } = coreUtilsLib;
const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const getDeprecatedComponentActions = () => ({
  mainActions: [] as AnyFixMe[],
  enabledActions: [ACTIONS.SETTINGS, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.SETTINGS, ACTIONS.HELP],
});

function createBlogManagerFrameOpenerOnPage(pageType: string, compId?: string) {
  return (editorAPI: EditorAPI, ignoredComp: AnyFixMe, referral: AnyFixMe) => {
    blogManagerFrame.open(editorAPI, pageType, referral, compId);
  };
}

function getSinglePostDataId(editorAPI: EditorAPI) {
  const singlePostAppPart = editorAPI.dsRead.wixapps.classics
    .getAllAppPartComps()
    // @ts-expect-error
    .find(({ appPartName }) => appPartName === blogAppPartNames.SINGLE_POST);

  return (singlePostAppPart as AnyFixMe)?.id;
}

function getActions(
  editorAPI: EditorAPI,
  compRef: CompRef,
  actionsType: keyof GFPPData,
  defaultActions: AnyFixMe,
) {
  const editedCompData = editorAPI.components.data.get(compRef);

  const availableMainActions = {
    upgrade: {
      label: 'gfpp_mainaction_blog_update_migration',
      isApplied: false,
      onClick: () =>
        blogUtils.showMigrationPopup(editorAPI, {
          origin: 'oldBlogGFPPButton',
        }),
    },
    addNewPost: {
      label: 'gfpp_mainaction_blog_new_post',
      isApplied: false,
      onClick: createBlogManagerFrameOpenerOnPage('newPost'),
    },
    managePosts: {
      label: 'gfpp_mainaction_blog_manager',
      isApplied: false,
      onClick: createBlogManagerFrameOpenerOnPage('allPosts'),
    },
    manageCategories: {
      label: 'gfpp_mainaction_blog_categories',
      isApplied: false,
      onClick: createBlogManagerFrameOpenerOnPage('categories'),
    },
    editPost: {
      label: 'gfpp_mainaction_blog_edit_post',
      isApplied: false,
      onClick: createBlogManagerFrameOpenerOnPage(
        'editPost',
        editedCompData.id,
      ),
    },
    editRelatedPosts: {
      label: 'gfpp_mainaction_blog_edit_related_post',
      isApplied: false,
      onClick: createBlogManagerFrameOpenerOnPage(
        'editRelatedPosts',
        getSinglePostDataId(editorAPI),
      ),
    },
  };

  const APP_PART_TO_ACTIONS: Record<keyof typeof blogAppPartNames, AnyFixMe> =
    {};

  /*********************** Blog *****************************/
  APP_PART_TO_ACTIONS[blogAppPartNames.ARCHIVE] = experiment.isOpen(
    'newBlogMagicMigration',
  )
    ? {
        mainActions: [availableMainActions.upgrade],
        enabledActions: [ACTIONS.HELP],
        mobileEnabledActions: [ACTIONS.HELP],
      }
    : {
        mainActions: [],
        enabledActions:
          editedCompData.viewName === 'ArchiveList'
            ? [ACTIONS.SETTINGS, ACTIONS.LAYOUT, ACTIONS.DESIGN, ACTIONS.HELP]
            : [ACTIONS.LAYOUT, ACTIONS.HELP],
        mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP],
      };

  APP_PART_TO_ACTIONS[blogAppPartNames.CUSTOM_FEED] = experiment.isOpen(
    'newBlogMagicMigration',
  )
    ? {
        mainActions: [availableMainActions.upgrade],
        enabledActions: [ACTIONS.HELP],
        mobileEnabledActions: [ACTIONS.HELP],
      }
    : {
        mainActions: [
          availableMainActions.managePosts,
          availableMainActions.addNewPost,
        ],
        mobileEnabledActions: [
          ACTIONS.LAYOUT,
          ACTIONS.DESIGN,
          ACTIONS.HIDE,
          ACTIONS.HELP,
        ],
      };

  APP_PART_TO_ACTIONS[blogAppPartNames.POSTS_GALLERY] = experiment.isOpen(
    'newBlogMagicMigration',
  )
    ? {
        mainActions: [availableMainActions.upgrade],
        enabledActions: [ACTIONS.HELP],
        mobileEnabledActions: [ACTIONS.HELP],
      }
    : {
        mainActions: [availableMainActions.managePosts],
        mobileEnabledActions: [ACTIONS.DESIGN, ACTIONS.HIDE, ACTIONS.HELP],
      };

  APP_PART_TO_ACTIONS[blogAppPartNames.TICKER] = experiment.isOpen(
    'newBlogMagicMigration',
  )
    ? {
        mainActions: [availableMainActions.upgrade],
        enabledActions: [ACTIONS.HELP],
        mobileEnabledActions: [ACTIONS.HELP],
      }
    : {
        mainActions: [availableMainActions.managePosts],
        mobileEnabledActions: [ACTIONS.DESIGN, ACTIONS.HIDE, ACTIONS.HELP],
      };

  APP_PART_TO_ACTIONS[blogAppPartNames.FEATURED_POSTS] = experiment.isOpen(
    'newBlogMagicMigration',
  )
    ? {
        mainActions: [availableMainActions.upgrade],
        enabledActions: [ACTIONS.HELP],
        mobileEnabledActions: [ACTIONS.HELP],
      }
    : {
        mainActions: [availableMainActions.managePosts],
        mobileEnabledActions: [
          ACTIONS.LAYOUT,
          ACTIONS.DESIGN,
          ACTIONS.HIDE,
          ACTIONS.HELP,
        ],
      };

  APP_PART_TO_ACTIONS[blogAppPartNames.POSTS_LIST] = experiment.isOpen(
    'newBlogMagicMigration',
  )
    ? {
        mainActions: [availableMainActions.upgrade],
        enabledActions: [ACTIONS.HELP],
        mobileEnabledActions: [ACTIONS.HELP],
      }
    : {
        mainActions: [availableMainActions.managePosts],
        mobileEnabledActions: [
          ACTIONS.LAYOUT,
          ACTIONS.DESIGN,
          ACTIONS.HIDE,
          ACTIONS.HELP,
        ],
      };

  APP_PART_TO_ACTIONS[blogAppPartNames.AUTHOR] = experiment.isOpen(
    'newBlogMagicMigration',
  )
    ? {
        mainActions: [availableMainActions.upgrade],
        enabledActions: [ACTIONS.HELP],
        mobileEnabledActions: [ACTIONS.HELP],
      }
    : {
        mainActions: [availableMainActions.managePosts, ACTIONS.HIDE],
      };

  APP_PART_TO_ACTIONS[blogAppPartNames.FEED] = experiment.isOpen(
    'newBlogMagicMigration',
  )
    ? {
        mainActions: [availableMainActions.upgrade],
        enabledActions: [ACTIONS.HELP],
        mobileEnabledActions: [ACTIONS.HELP],
      }
    : {
        mainActions: [
          availableMainActions.managePosts,
          availableMainActions.addNewPost,
        ],
        mobileEnabledActions: [
          ACTIONS.SETTINGS,
          ACTIONS.LAYOUT,
          ACTIONS.DESIGN,
          ACTIONS.HELP,
        ],
      };

  APP_PART_TO_ACTIONS[blogAppPartNames.TAG_CLOUD] = experiment.isOpen(
    'newBlogMagicMigration',
  )
    ? {
        mainActions: [availableMainActions.upgrade],
        enabledActions: [ACTIONS.HELP],
        mobileEnabledActions: [ACTIONS.HELP],
      }
    : {
        mainActions: [],
        mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP],
      };

  APP_PART_TO_ACTIONS[blogAppPartNames.SINGLE_POST] = experiment.isOpen(
    'newBlogMagicMigration',
  )
    ? {
        mainActions: [availableMainActions.upgrade],
        enabledActions: [ACTIONS.HELP],
        mobileEnabledActions: [ACTIONS.HELP],
      }
    : {
        mainActions: [
          availableMainActions.editPost,
          availableMainActions.managePosts,
        ],
        mobileEnabledActions: [
          ACTIONS.SETTINGS,
          ACTIONS.LAYOUT,
          ACTIONS.DESIGN,
          ACTIONS.HELP,
        ],
      };

  APP_PART_TO_ACTIONS[blogAppPartNames.RELATED_POSTS] = experiment.isOpen(
    'newBlogMagicMigration',
  )
    ? {
        mainActions: [availableMainActions.upgrade],
        enabledActions: [ACTIONS.HELP],
        mobileEnabledActions: [ACTIONS.HELP],
      }
    : {
        mainActions: [availableMainActions.editRelatedPosts],
        mobileEnabledActions: [ACTIONS.DESIGN, ACTIONS.HIDE, ACTIONS.HELP],
      };

  APP_PART_TO_ACTIONS[blogAppPartNames.CATEGORIES] = experiment.isOpen(
    'newBlogMagicMigration',
  )
    ? {
        mainActions: [availableMainActions.upgrade],
        enabledActions: [ACTIONS.HELP],
        mobileEnabledActions: [ACTIONS.HELP],
      }
    : {
        mainActions: [availableMainActions.manageCategories],
        enabledActions: [ACTIONS.LAYOUT, ACTIONS.DESIGN, ACTIONS.HELP],
        mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.DESIGN, ACTIONS.HELP],
      };

  /** Deprecated WixApps **/
  Object.values(LEGACY_WIX_APPS_NAMES).forEach((appName) => {
    APP_PART_TO_ACTIONS[appName] = getDeprecatedComponentActions();
  });

  return (
    APP_PART_TO_ACTIONS[editedCompData.appPartName][actionsType] ||
    defaultActions
  );
}

function getMainActions(editorAPI: EditorAPI, compRef: CompRef) {
  return getActions(editorAPI, compRef, 'mainActions', []);
}

function getEnabledActions(editorAPI: EditorAPI, compRef: CompRef) {
  return getActions(editorAPI, compRef, 'enabledActions', [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.HELP,
  ]);
}

function getMobileEnabledActions(editorAPI: EditorAPI, compRef: CompRef) {
  return getActions(editorAPI, compRef, 'mobileEnabledActions', []);
}

const getAppPartName = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): LEGACY_WIX_APPS_NAMES => {
  const editedCompData = editorAPI.components.data.get(compRef);
  return editedCompData?.appPartName;
};

function getHelpId(editorAPI: EditorAPI, compRef: CompRef, helpType: AnyFixMe) {
  const appPartName = getAppPartName(editorAPI, compRef);

  if (LEGACY_WIX_APPS_NAMES_TO_HELP_ID_MAP[appPartName]) {
    return LEGACY_WIX_APPS_NAMES_TO_HELP_ID_MAP[appPartName];
  }

  if (experiment.isOpen('newBlogMagicMigration')) {
    return '6f26af10-0e5b-44e8-a92f-f89d424822fe';
  }

  return blogUtils.getAppPartHelpId(
    appPartName,
    editorAPI.dsRead.viewMode.VIEW_MODES.DESKTOP,
    helpType,
  );
}

function getMobileHelpId(
  editorAPI: EditorAPI,
  compRef: CompRef,
  helpType: AnyFixMe,
) {
  const appPartName = getAppPartName(editorAPI, compRef);

  if (LEGACY_WIX_APPS_NAMES_TO_HELP_ID_MAP[appPartName]) {
    return LEGACY_WIX_APPS_NAMES_TO_HELP_ID_MAP[appPartName];
  }

  if (experiment.isOpen('newBlogMagicMigration')) {
    return '6f26af10-0e5b-44e8-a92f-f89d424822fe';
  }

  return blogUtils.getAppPartHelpId(
    appPartName,
    editorAPI.dsRead.viewMode.VIEW_MODES.MOBILE,
    helpType,
  );
}

const gfppData: GFPPDataRaw = {
  mainActions: getMainActions,
  enabledActions: getEnabledActions,
  mobileEnabledActions: getMobileEnabledActions,
  presetActions: {
    help: {
      helpId: getHelpId,
      mobileHelpId: getMobileHelpId,
    },
  },
};

export default gfppData;
