import { getClosestSectionLikeForFWE } from '../../../utils';

import type { Scope } from '@/sections';
import type { CompRef } from 'types/documentServices';

export const createBeforeComponentDuplicatePlugin = (scope: Scope) => {
  return async (compRefs: CompRef[]) => {
    const { editorAPI, components } = scope;
    const sectionLikeRef = getClosestSectionLikeForFWE(scope, compRefs);

    if (!sectionLikeRef) return;

    const sectionLayout = components.layout.get_size(sectionLikeRef);
    const compLayout = components.layout.get_size(compRefs[0]);

    components.layout.updateAndAdjustLayout(
      sectionLikeRef,
      {
        height: sectionLayout.height + compLayout.height,
      },
      true,
    );

    await editorAPI.waitForChangesAppliedAsync();
  };
};
