import type { Shell } from '@/apilib';
import { HomePageApiKey, EditorAPIKey, PagesApiKey } from '@/apis';

import _ from 'lodash';
import * as pagesMenu from '@/pagesMenu';
import experiment from 'experiment';
import { isCustomMenusEnabled } from '@/util';

function createMainMenuPanelContext() {
  let isOpened = false;
  let context: {
    selectedMenuItem?: { id: string };
  } = {};

  function setOpened({ selectedMenuItem }: AnyFixMe) {
    context.selectedMenuItem = selectedMenuItem;

    isOpened = true;
  }

  function setSelectedMenuItem(menuItem: AnyFixMe) {
    if (!isOpened) {
      return;
    }

    context.selectedMenuItem = menuItem;
  }

  function getSelectedMenuItem() {
    if (!isOpened) {
      return;
    }

    return context.selectedMenuItem;
  }

  function setClosed() {
    context = {};

    isOpened = false;
  }

  return {
    setOpened,
    setSelectedMenuItem,
    getSelectedMenuItem,
    setClosed,
  };
}

export function createMainMenuApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const pagesApi = shell.getAPI(PagesApiKey);
  const homePageApi = shell.getAPI(HomePageApiKey);
  const { menuLogic } = pagesMenu.specialPages;

  const IS_LINK_REGEX = /Link$/;

  function sanitizeHash(str: AnyFixMe) {
    return str.replace('#', '');
  }

  function _buildDynamicPagesIdsMap() {
    const dynamicPagesIdsMap: AnyFixMe = {};
    const routers = editorAPI.dsRead.routers.get.all();
    _.forOwn(routers, (router) => {
      _.forOwn(router.pages, (pageId) => {
        dynamicPagesIdsMap[pageId as any] = true;
      });
    });
    return dynamicPagesIdsMap;
  }

  function findFirstNonHomePage(
    menuItems: AnyFixMe,
    excludedPageIds: AnyFixMe[] = [],
  ) {
    let fitMenuItem: AnyFixMe;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    _.some(menuItems, function (item) {
      const pageId = item.pageData?.id;
      if (
        pageId &&
        item.type.isPage &&
        !pagesApi.isHomePage(pageId) &&
        !pagesApi.isParentPage(pageId) &&
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        !_.includes(excludedPageIds, pageId)
      ) {
        fitMenuItem = item;
        return true;
      }

      if (item.items?.length) {
        fitMenuItem = findFirstNonHomePage(item.items);
        if (fitMenuItem) {
          return true;
        }
      }
    });

    return fitMenuItem;
  }

  function getMenuItemTypeData(menuItem: AnyFixMe) {
    const menuItemType = menuItem.link?.type;
    const linkPageId = menuItem.link?.pageId;
    const isPage = menuItemType === 'PageLink';
    const isPopupLink =
      linkPageId &&
      editorAPI.dsRead.pages.popupPages.isPopup(linkPageId.replace('#', ''));

    return {
      raw: menuItemType,
      isDropdown: !menuItemType,
      isLink: (!isPage && IS_LINK_REGEX.test(menuItemType)) || isPopupLink,
      isPage: isPage && !isPopupLink,
    };
  }

  function createMenuItemWithPageData(
    menuItem: AnyFixMe,
    pageData: AnyFixMe,
    parent?: AnyFixMe,
  ) {
    return {
      id: menuItem.id,
      type: getMenuItemTypeData(menuItem),
      label: menuItem.label,
      parent,
      isVisible: menuItem.isVisible,
      isVisibleMobile: menuItem.isVisibleMobile,
      pageData,
      link: menuItem.link,
    };
  }

  function extendWithPageDataRecursively(
    menuItemsTree: AnyFixMe,
    traverseInfo: AnyFixMe,
    filterFunction: AnyFixMe,
    menuItemProcessedCallback: AnyFixMe,
  ) {
    const res: AnyFixMe = [];

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(menuItemsTree, function (menuItem) {
      let newItem;
      const type = getMenuItemTypeData(menuItem);

      let pageData;
      if (experiment.isOpen('pagesPanelOpenCollapsed')) {
        pageData = type.isPage
          ? traverseInfo.pagesDataMap[sanitizeHash(menuItem.link.pageId)]
          : null;
      } else {
        pageData = type.isPage
          ? // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/find
            _.find(traverseInfo.pagesDataList, [
              'id',
              sanitizeHash(menuItem.link.pageId),
            ])
          : null;
      }

      if (!filterFunction || filterFunction(menuItem, pageData)) {
        newItem = createMenuItemWithPageData(
          menuItem,
          pageData,
          traverseInfo.parent,
        );

        if (menuItem.items.length > 0) {
          (newItem as AnyFixMe).items = extendWithPageDataRecursively(
            menuItem.items,
            _.defaults({ parent: newItem }, traverseInfo),
            filterFunction,
            menuItemProcessedCallback,
          );
        } else {
          (newItem as AnyFixMe).items = [] as AnyFixMe;
        }

        res.push(newItem);
        if (menuItemProcessedCallback) {
          menuItemProcessedCallback(newItem);
        }
      }
    });

    return res;
  }

  const convertPageToMockedMenuItem = (page: AnyFixMe) => ({
    id: `mockedMenuId-pageid-${page.id}`,
    label: page.title,
    pageData: page,

    type: {
      isDropdown: false,
      isLink: false,
      isPage: true,
      isPopupLink: false,
      raw: 'PageLink',
    },

    isVisible: true,
    isVisibleMobile: true,
  });

  function convertPagesDataListToMenuItemsList(
    pagesDataList: AnyFixMe,
    menuTree?: AnyFixMe,
  ) {
    if (isCustomMenusEnabled()) {
      return pagesDataList.map(convertPageToMockedMenuItem);
    }

    function traceTree(items: AnyFixMe) {
      let pageData, type, pageDataIndex;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(items, function (item) {
        type = getMenuItemTypeData(item);
        if (type.isPage) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
          pageDataIndex = _.findIndex(pagesDataList, {
            id: sanitizeHash(item.link.pageId),
          });
          if (pageDataIndex > -1) {
            pageData = pagesDataList[pageDataIndex];
            res[pageDataIndex] = createMenuItemWithPageData(item, pageData);
          }
        }

        if (item.items.length > 0) {
          traceTree(item.items);
        }
      });
    }

    const res: AnyFixMe = [];
    traceTree(menuTree || editorAPI.dsRead.mainMenu.getMenu(true));
    return _.compact(res);
  }

  function extendMenuItemsTreeWithPageData(
    pagesDataList: AnyFixMe,
    filterFunction: AnyFixMe,
    menuItemProcessedCallback?: AnyFixMe,
    rawMenuTree?: AnyFixMe,
  ) {
    rawMenuTree = rawMenuTree || editorAPI.dsRead.mainMenu.getMenu(true);
    if (experiment.isOpen('pagesPanelOpenCollapsed')) {
      const pagesDataMap = _.keyBy(pagesDataList, 'id');
      return extendWithPageDataRecursively(
        rawMenuTree,
        {
          parent: null,
          homePageId: homePageApi.get(),
          pagesDataMap,
        },
        filterFunction,
        menuItemProcessedCallback,
      );
    }

    return extendWithPageDataRecursively(
      rawMenuTree,
      {
        parent: null,
        homePageId: homePageApi.get(),
        pagesDataList,
      },
      filterFunction,
      menuItemProcessedCallback,
    );
  }

  function getFirstNonHomePageMenuItem(excludedPageIds: AnyFixMe) {
    const mainMenuItemsTree = getMenuItemsTreeForMainMenu();

    return findFirstNonHomePage(mainMenuItemsTree, excludedPageIds);
  }

  function getMenuItemsTreeForMainMenu(pagesDataList?: AnyFixMe) {
    if (!editorAPI.dsRead) {
      return [];
    }
    pagesDataList = pagesDataList || editorAPI.dsRead.pages.getPagesData();
    const dynamicPagesIdsMap = _buildDynamicPagesIdsMap();

    const isMainMenuItem = function (menuItem: AnyFixMe, pageData: AnyFixMe) {
      if (!pageData) {
        return true;
      }

      return !menuLogic.isSpecialPage(editorAPI, pageData, dynamicPagesIdsMap);
    };

    return extendMenuItemsTreeWithPageData(pagesDataList, isMainMenuItem);
  }

  function hasOnlyLastPageMenuItem() {
    const dynamicPagesIdsMap = _buildDynamicPagesIdsMap();
    const pagesData = editorAPI.dsRead.pages.getPagesData();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const mainMenuPagesDataList = _.filter(
      pagesData,
      (pageData) =>
        !menuLogic.isSpecialPage(editorAPI, pageData, dynamicPagesIdsMap),
    );

    return mainMenuPagesDataList.length === 1;
  }

  /*
     "Fixed" means with special pages made as subpages of section pages.
     Example: tree with 2 roots [Blog, SinglePost] becomes tree with Blog as a root and SinglePost as
     a subpage [Blog[SinglePost]]
     Only pages are includes, Dropdowns and Links are excluded.
     */
  function getMenuItemsTreeForPagesFixed() {
    const menuTree = editorAPI.dsRead.mainMenu.getMenu(true);
    const pagesDataList = editorAPI.dsRead.pages.getPagesData();
    const sectionPagesIds = menuLogic.getSectionPagesIds(
      editorAPI,
      pagesDataList,
    );
    const sectionPagesMenuItems: AnyFixMe = [];
    const { push } = Array.prototype;
    const dynamicPagesIdsMap = _buildDynamicPagesIdsMap();

    const filterFn = function isRegularPageOrNonPageWithChildren(
      menuItem: AnyFixMe,
      pageData: AnyFixMe,
    ) {
      if (!pageData && menuItem.items.length === 0) {
        return false; // not-pages are excluded. exception: when a not-page has children
      }
      if (
        pageData &&
        menuLogic.isSpecialPage(editorAPI, pageData, dynamicPagesIdsMap)
      ) {
        return false; // special pages are excluded
      }
      return true;
    };

    const rememberSectionPages = function (menuItem: AnyFixMe) {
      if (
        menuItem.pageData &&
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        _.includes(sectionPagesIds, menuItem.pageData.id)
      ) {
        sectionPagesMenuItems.push(menuItem); // remember section pages, later special pages will be added into them
      }
    };

    // 1. Build tree with regular pages only
    const tree = extendMenuItemsTreeWithPageData(
      pagesDataList,
      filterFn,
      rememberSectionPages,
      menuTree,
    );

    // 2. Add missing special pages
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(sectionPagesMenuItems, function (menuItem) {
      const specialPages =
        menuLogic.getSpecialPagesListBySectionPageOrBySibling(
          editorAPI,
          menuItem.pageData,
          pagesDataList,
        );
      const specialPagesMenuItems = convertPagesDataListToMenuItemsList(
        specialPages,
        menuTree,
      );
      push.apply(menuItem.items, specialPagesMenuItems);
    });

    return tree;
  }

  return {
    hasOnlyLastPageMenuItem,
    getMenuItemsTreeForPagesFixed,
    getFirstNonHomePageMenuItem,
    getMenuItemsTreeForMainMenu,
    extendMenuItemsTreeWithPageData,
    convertPagesDataListToMenuItemsList,
    panelContext: createMainMenuPanelContext(),
  };
}
