import React from 'react';
import * as BaseUI from '@/baseUI';
import { Composites, Divider, Slider, TextLabel } from '@wix/wix-base-ui';

import { EFFECT_LIMITS, EffectTypes } from '../effects';
import type { IEffectsPanelProps } from '../effectsPanel';

import {
  ALIGNMENT_MODES_XY,
  getOriginFromValue,
  getSelectedValueFromOrigin,
} from './customizeUtils';
import * as coreBi from '@/coreBi';

type IProps = Pick<
  IEffectsPanelProps,
  'shrink' | 'scaleValue' | 'origin' | 'sendBI' | 'variantId'
>;

interface IState {
  scaleValue: number;
}

export class CustomizeShrinkPanel extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      scaleValue: props.scaleValue,
    };
  }

  handleAlignmentChange = (value: string) => {
    const scale = this.state.scaleValue;
    const origin = getOriginFromValue(value);
    this.props.sendBI(
      coreBi.events.interactions.effects_panel_change_settings,
      {
        interaction_id: this.props.variantId,
        behavior_name: EffectTypes.SHRINK,
        parameter_name: 'effect_origin',
        old_value: getSelectedValueFromOrigin(this.props.origin),
        new_value: value,
      },
    );
    this.props.shrink(scale, origin);
  };

  handleScaleChange = (value: number) => {
    const scale = value / 100;
    const { origin } = this.props;
    this.props.sendBI(
      coreBi.events.interactions.effects_panel_change_settings,
      {
        interaction_id: this.props.variantId,
        behavior_name: EffectTypes.SHRINK,
        parameter_name: 'scale',
        old_value: this.state.scaleValue,
        new_value: scale,
      },
    );
    this.props.shrink(scale, origin);
    this.setState({ scaleValue: scale });
  };

  render() {
    return (
      <div className="customize-effect-panel" data-aid="customize-effect-panel">
        <Divider long={true} />
        <BaseUI.sectionDividerLabeled label="interactions_effects_shrink_section_title" />
        <Divider long={true} />

        <Composites.SliderLabeled>
          <TextLabel value="interactions_effects_shrink_shrink_to_label" />
          <Slider
            value={Math.round(this.state.scaleValue * 100)}
            onChange={this.handleScaleChange}
            unit="percent"
            min={EFFECT_LIMITS.SHRINK.MIN}
            max={EFFECT_LIMITS.SHRINK.MAX}
            step={1}
          />
        </Composites.SliderLabeled>

        <Divider long={false} />

        <BaseUI.alignment
          options={ALIGNMENT_MODES_XY}
          label="interactions_effects_shrink_shrink_towards_label"
          value={getSelectedValueFromOrigin(this.props.origin)}
          onChange={this.handleAlignmentChange}
          infoIconProps={{
            text: 'interactions_effects_shrink_shrink_towards_tooltip',
          }}
        />
      </div>
    );
  }
}
