// @ts-nocheck
import loginButtonGfppData from './loginButtonGfppData';
import backToTopButtonGfppData from './backToTopButtonGfppData';
import clipArtGfppData from './clipArtGfppData';

export default {
  LoginButton: loginButtonGfppData,
  BackToTopButton: backToTopButtonGfppData,
  ClipArt: clipArtGfppData,
};
