// @ts-nocheck
import ReactDOM from 'reactDOM';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import $ from 'zepto';
import * as stateManagement from '@/stateManagement';
import * as core from '@/core';
import * as advancedStylePanel from '@/advancedStylePanel';
import stylablePanel, {
  getStylablePanelPlane,
} from './stylablePanel/stylablePanel';
import { StylePanelPlane } from './stylablePanel/stylablePanelTypes';
import designPanelMixin from '../core/designPanelMixin';
import { mapDispatchToProps } from './changeStylePanelMapper';
import * as util from '@/util';
import experiment from 'experiment';

import React from 'react';
import DesignPanel from '../core/designPanel';
import * as baseUI from '@/baseUI';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const { panelPosition } = stateManagement.domMeasurements.hoc;
const componentsSelectors = stateManagement.components.selectors;
const { getComponentCompVariantPointersCascade } =
  stateManagement.interactions.selectors;

const TRANSPARENT_SKINS = [
  'wysiwyg.viewer.skins.page.TransparentPageSkin',
  'wysiwyg.viewer.skins.screenwidthcontainer.TransparentHalfScreen',
  'wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen',
  'wysiwyg.viewer.skins.area.TransparentArea',
];
const VIDEO_SKIN = ['wysiwyg.viewer.skins.VideoSkin'];
const IMAGE_STYLE_FRAME = ['wysiwyg.viewer.skins.photo.NoSkinPhoto'];

const COMP_TYPES_WITH_CUSTOM_ADVANCE_STYLE = {
  'wysiwyg.viewer.components.svgshape.SvgShape': advancedStylePanel.svgShape,
  'wysiwyg.viewer.components.BoxSlideShowSlide':
    advancedStylePanel.boxSlideShowSlide,
};

function getLayoutToMaintain(editorAPI, compRef) {
  return editorAPI.components.layout.get_rect(compRef);
}

const updateOriginalStyleDefs = (multiComponentsOriginalStyleDefs) => {
  return (previousState) => ({
    ...previousState,
    multiComponentsOriginalStyleDefs,
  });
};

// eslint-disable-next-line react/prefer-es6-class
const ChangeStyleDesignPanel = createReactClass({
  displayName: 'changeStyleDesignPanel',
  mixins: [designPanelMixin, core.mixins.editorAPIMixin],
  render() {
    return (
      <DesignPanel ref="designPanel" {...this.getPanelProps()}>
        {this.shouldLinkToAdvancedStyling() ? (
          <div key="customizeDesignBtn" className="button-wrapper">
            <baseUI.button
              ref="customizeDesign"
              label="design_customize_button"
              onClick={this.openCustomizeDesign}
              icon="customize_design_drop_light"
              className="button"
            />
          </div>
        ) : null}

        {this.state.advancedStyling
          ? (() => {
              const AdvanceStylePanelClass = this.getAdvancedStylePanelClass();

              return (
                <div key="advancedStylePanel">
                  <AdvanceStylePanelClass
                    hideSaveThemeButton={this.props.hideSaveThemeButton}
                    {...this.getAdvancedProps()}
                  />
                </div>
              );
            })()
          : null}
      </DesignPanel>
    );
  },
  advancedPanelWasOpenedFromOutside: false,
  propTypes: {
    panelName: PropTypes.string.isRequired,
    style: PropTypes.object.isRequired,
    selectedComponent: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.array.isRequired,
    ]),
    multiSelectedComponents: PropTypes.array,
    compType: PropTypes.string.isRequired,
    updateStyle: PropTypes.func, // will be called with (newStyleId, isTemporary)
    getStyle: PropTypes.func,
    currentStyleId: PropTypes.string, //Used only for wixapp
    shouldMaintainOriginalLayout: PropTypes.bool,
    shouldChangeProperties: PropTypes.bool,
    shouldForkStyle: PropTypes.bool, //Sent by appPart and other panels will use default value
    startWithAdvanced: PropTypes.bool,
    initialView: PropTypes.shape({
      page: PropTypes.number,
      selector: PropTypes.string,
      section: PropTypes.string,
    }),
    plane: PropTypes.string,
    customTranslations: PropTypes.object,
    dimensionUnits: PropTypes.object,
    getComponentCompVariantPointersCascade: PropTypes.func,
    showUserActionNotification: PropTypes.func,
  },
  getDefaultProps() {
    return {
      backButtonLabel: 'design_panel_back_button_label',
      shouldForkStyle: true,
    };
  },
  getStateFromProps(props) {
    const editorAPI = this.getEditorAPI();
    const currentEditorState = editorAPI.store.getState();
    const selectedComponent = Array.isArray(props.selectedComponent)
      ? props.selectedComponent[0]
      : props.selectedComponent;
    //TODO: 1. Should be removed when 'moveCustomStyleFromMasterPage' experiment will be open
    if (
      props.shouldForkStyle &&
      !stateManagement.interactions.selectors.isInInteractionMode(
        currentEditorState,
      ) &&
      !stateManagement.componentSelectors.isReferredComponent(selectedComponent)
    ) {
      editorAPI.components.style.fork(selectedComponent);
    }

    const shouldOpenAdvancedPanel =
      props.startWithAdvanced && !this.advancedPanelWasOpenedFromOutside;

    return {
      originalStyleDef: props.getStyle(),
      multiComponentsOriginalStyleDefs: props.getMultiSelectedComponentsStyle(),
      layoutToMaintain:
        props.shouldMaintainOriginalLayout &&
        getLayoutToMaintain(editorAPI, props.selectedComponent),
      helpLink: undefined,
      advancedStyling: shouldOpenAdvancedPanel ? true : undefined,
      selectedComponent: props.multiSelectedComponents
        ? _.head(props.multiSelectedComponents)
        : props.selectedComponent,
    };
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.layoutToMaintain) {
      return;
    }

    if (
      _.isEqual(nextProps.selectedComponent, this.state.selectedComponent) ||
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
      _.some(nextProps.multiSelectedComponents, (compRef) =>
        _.isEqual(compRef, this.state.selectedComponent),
      )
    ) {
      const editorAPI = this.getEditorAPI();
      const currLayout = getLayoutToMaintain(
        editorAPI,
        nextProps.selectedComponent,
      );
      const isLayoutChanged = !_.isEqual(
        this.state.layoutToMaintain,
        currLayout,
      );
      const isStyleChanged = !_.isEqual(
        nextProps.getStyle(),
        this.state.originalStyleDef,
      );
      if (isLayoutChanged && !isStyleChanged) {
        this.setState({
          layoutToMaintain: currLayout,
        });
      }
    }
  },
  changePermanentState(compDef) {
    const editorAPI = this.getEditorAPI();
    const styleDef = core.styleManager.getCompDefStyle(compDef, editorAPI);
    const { updateStyle, multiSelectedComponents } = this.props;
    const { originalStyleDef } = this.state;
    const shouldRemoveScopedStyle = originalStyleDef.skin !== styleDef.skin;
    updateStyle(
      styleDef,
      false,
      this.state.layoutToMaintain,
      undefined,
      undefined,
      shouldRemoveScopedStyle,
    );

    const newState = {
      originalStyleDef: styleDef,
      isStyleChanged: true,
    };

    if (multiSelectedComponents) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
      const multiComponentsOriginalStyleDefs = _.reduce(
        multiSelectedComponents,
        (acc, compRef) => _.set(acc, compRef.id, styleDef),
        {},
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(newState, { multiComponentsOriginalStyleDefs });
    }
    if (experiment.isOpen('animateChangeStyleButton')) {
      const customizeDesign = $(
        ReactDOM.findDOMNode(this.refs.customizeDesign),
      );
      customizeDesign.removeClass('animate-button');

      this.setState(newState, function () {
        customizeDesign.addClass('animate-button');
      });
    } else {
      this.setState(newState);
    }
  },
  changeTempState(compDef) {
    const editorAPI = this.getEditorAPI();
    const styleObj = core.styleManager.getCompDefStyle(compDef, editorAPI);
    if (!styleObj) {
      return;
    }

    this.props.updateStyle(
      styleObj,
      true,
      this.state.layoutToMaintain,
      true,
      true,
    );

    if (this.props.shouldChangeProperties) {
      editorAPI.components.properties.update(
        this.state.selectedComponent,
        compDef.props,
      );
    }
  },
  maintainOriginalState(dontAddToUndoRedoStack = false) {
    const { originalStyleDef, multiComponentsOriginalStyleDefs } = this.state;
    if (this.props.multiSelectedComponents) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      return _.forEach(
        multiComponentsOriginalStyleDefs,
        ({ originalDef, compRef }) => {
          this.getEditorAPI().components.style.update(
            compRef,
            originalDef,
            dontAddToUndoRedoStack,
            true,
          );
        },
      );
    }

    if (this.state.originalStyleDef) {
      this.props.updateStyle(
        originalStyleDef,
        false,
        this.state.layoutToMaintain,
        dontAddToUndoRedoStack,
        true,
      );
    }
  },
  isSelectedItem(compDef) {
    const editorAPI = this.getEditorAPI();
    const isMatchingStyle = core.styleManager.isEqualWithoutTypeConsideration(
      core.styleManager.getCompDefStyle(compDef, editorAPI),
      this.state.originalStyleDef,
    );
    const isMatchingProperties = _(this.state.selectedComponent)
      .thru(editorAPI.components.properties.get)
      .omit('id', 'metaData')
      .isEqual(_.omit(compDef.props, 'id', 'metaData'));

    return (
      isMatchingStyle &&
      (!this.props.shouldChangeProperties || isMatchingProperties)
    );
  },
  getStylableProps() {
    if (!this.isStylableComp()) {
      return {};
    }

    const editorAPI = this.getEditorAPI();
    let cssClass;

    if (this.state.advancedStyling) {
      if (editorAPI.isMobileEditor()) {
        cssClass = 'stylable-panel-mobile';
      } else {
        cssClass = 'stylable-panel';
      }
    } else {
      cssClass = 'stylable-panel-height-only';
    }
    const plane = getStylablePanelPlane(this.props.plane);
    if (plane === StylePanelPlane.VerticalCollapse) {
      cssClass += ' stylable-panel-vertical-collapse';
    }
    return {
      class: cssClass,
      helpId: this.state.advancedStyling ? this.state.helpId : undefined,
    };
  },
  getPanelProps() {
    const editorAPI = this.getEditorAPI();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(
      this.getPropsForDesignPanel(),
      {
        getAdditionalElementProps(compDef) {
          const style = core.styleManager.getCompDefStyle(compDef, editorAPI);
          const props = {};
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          if (_.includes(TRANSPARENT_SKINS, style.skin)) {
            props.text = 'DESIGN_LABEL_NO_COLOR';
            props.className = 'design-panel-transparent-skin-item';
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          } else if (_.includes(VIDEO_SKIN, style.skin)) {
            props.text = 'Design_Label_No_Frame';
            props.className = 'design-panel-no-frame-skin-item';
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          } else if (_.includes(IMAGE_STYLE_FRAME, style.skin)) {
            props.text = 'Design_Label_No_Frame';
            const imageCompType = 'wysiwyg.viewer.components.WPhoto';
            if (this.props && _.head(this.props.compTypes) === imageCompType) {
              props.className = 'design-panel-no-frame-skin-item';
            } else {
              props.className = 'design-panel-no-frame-skin-item-discover-more';
            }
          }
          return props;
        },
      },
      this.getStylableProps(),
    );
  },
  isStylableComp() {
    const editorAPI = this.getEditorAPI();
    return componentsSelectors.isStylable(
      this.state.selectedComponent,
      editorAPI.dsRead,
    );
  },
  getAdvancedStylePanelClass() {
    if (COMP_TYPES_WITH_CUSTOM_ADVANCE_STYLE[this.props.compType]) {
      return COMP_TYPES_WITH_CUSTOM_ADVANCE_STYLE[this.props.compType];
    }
    if (this.isStylableComp()) {
      return stylablePanel;
    }

    return advancedStylePanel.panel;
  },
  getAdvancedStylableProps() {
    const {
      breakpointId,
      customTranslations,
      dimensionUnits,
      initialView,
      multiSelectedComponents,
      plane,
      showUserActionNotification,
      startWithAdvanced,
      onSiteColorChange,
    } = this.props;

    const props = {
      breakpointId,
      customTranslations,
      dimensionUnits,
      getComponentCompVariantPointersCascade,
      initialView:
        startWithAdvanced && !this.customizeDesignClicked
          ? initialView
          : undefined,
      plane: getStylablePanelPlane(plane),
      setHelpId: (helpId) => this.setState({ helpId }),
      showUserActionNotification,
      onSiteColorChange,
    };

    if (multiSelectedComponents) {
      props.multiSelectedComponents = multiSelectedComponents;
    }
    return this.isStylableComp() ? props : {};
  },
  getAdvancedProps() {
    return Object.assign(
      {},
      this.getAdvancedStylingProps(),
      this.getAdvancedStylableProps(),
      this.props.multiSelectedComponents
        ? {
            onStyleParamChanged: this.props.onStyleParamChanged,
            isMultiComponentDesign: true,
            maintainOriginalState: this.maintainOriginalState,
            updateOriginalStyleDefs: () =>
              this.setState(
                updateOriginalStyleDefs(
                  this.props.getMultiSelectedComponentsStyle(),
                ),
              ),
            multiSelectedComponents: this.props.multiSelectedComponents,
          }
        : {},
    );
  },
  shouldLinkToAdvancedStyling() {
    return !this.state.advancedStyling;
  },
});

const WrappedPanel = connect(
  EDITOR_API,
  undefined,
  mapDispatchToProps,
)(panelPosition()(ChangeStyleDesignPanel));
WrappedPanel.pure = ChangeStyleDesignPanel;
export default WrappedPanel;
