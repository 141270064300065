// @ts-nocheck
import myUploadsSection from './myUploadsSection';
import imageCollectionsSection from './imageCollectionsSection';
import mySocialImagesSection from './mySocialImagesSection';

export default {
  getCategorySections() {
    return {
      myUploadsSection,
      imageCollectionsSection,
      mySocialImagesSection,
    };
  },
};
