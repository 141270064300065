import { registerComponentGfppData } from '@/gfppData';
import * as appManager from '@/appManager';

import blogMenuPanel from './appMenu/panels/blogMenuPanel';
import newBlogMenuPanel from './newAppMenu/panels/blogMenuPanel';
import newBlogMagicMigrationSlide from './newAppMenu/panels/magicMigration/newBlogMagicMigrationSlide';
import * as blogUtils from './utils/blogUtils';
import blogDeleteConfirmation from './compPanels/blogDeleteConfirmation';
import * as newBlogBi from './newBlogBi/newBlogBi';
import appPartGfppData from './gfppData/appPartGfppData';
import type { CompRef } from 'types/documentServices';
import { BlogUpgradePanel } from './blogUpgradePanel/blogUpgradePanel';
import BlogDeprecationPanel from './oldBlogDeprecation/blogDeprecationPanel';

import type { EditorAPI } from '@/editorAPI';

function shouldAddOldBlogButton(editorAPI: EditorAPI) {
  return (
    blogUtils.siteHasOldBlog(editorAPI) &&
    !blogUtils.isNewBlogMagicMigrationEnabled()
  );
}

async function registerBlog(editorAPI: EditorAPI) {
  blogUtils.registerHooks(editorAPI);

  await appManager.init(editorAPI);

  if (shouldAddOldBlogButton(editorAPI)) {
    blogUtils.registerBlogButton(editorAPI);
  }

  blogUtils.registerNewBlogButton(editorAPI);
  editorAPI.registerRemovePagePlugin('blog', function () {
    blogUtils.removeBlogButton(editorAPI);
    blogUtils.registerNewBlogButton(editorAPI);
  });
}

function registerWixAppsGFPPData(editorAPI: EditorAPI) {
  editorAPI.registerCompNamePlugin(
    'wixapps.integration.components.AppPart',
    (compRef: CompRef) => blogUtils.getCompName(editorAPI, compRef),
  );
  registerComponentGfppData('AppPart', appPartGfppData);
}

function onPreviewReady(editorAPI: EditorAPI) {
  registerWixAppsGFPPData(editorAPI);

  if (blogUtils.shouldFetchMigrationStatus(editorAPI)) {
    blogUtils.setMigrationStatusSlide(editorAPI, newBlogMagicMigrationSlide);
    return registerBlog(editorAPI);
  }

  registerBlog(editorAPI);
}

export const showDeprecationPopup = (
  editorAPI: EditorAPI,
  ...props: AnyFixMe[]
) => {
  editorAPI.panelManager.openPanel('blog.panels.blogDeprecationPanel', {
    ...props,
  });
};

export {
  getBlogMainAppPartCompRef,
  siteHasOldBlog,
  siteHasOldBlogDeprecationComponents,
  getBlogPageId,
  getAppPartHelpId,
  siteHasNewBlog,
  registerBlogButton,
  registerNewBlogButton,
  unregisterNewBlogButton,
  onNewBlogInstalled,
  addNewBlog,
  startQuickMigration,
  startMagicMigration,
  isNewBlogQuickMigrationEnabled,
  isNewBlogMagicMigrationEnabled,
  openNewBlogInAppMarket,
  newBlogAppDefId,
  showMigrationPopup,
  addBlogAndRegisterButton as installApp,
  helpArticles,
} from './utils/blogUtils';

export { open as openBlogManagerFrame } from './blogManager/blogManagerFrame';

export {
  onPreviewReady,
  blogMenuPanel as menuPanel,
  blogDeleteConfirmation,
  newBlogMenuPanel as newMenuPanel,
  newBlogBi,
};

export const panels = {
  blogUpgradePanel: BlogUpgradePanel,
  blogDeprecationPanel: BlogDeprecationPanel,
};
