import React from 'react';
import { TextLabel } from '@wix/wix-base-ui';
import { cx } from '@/util';
import styles from './block.scss';
import { AIDS } from '../constants';
import type { HelpBlock } from '../types';

interface Props {
  title?: string;
  type: HelpBlock;
  raw?: boolean;
  href?: string;
  className?: string;
  onClick?: () => void;
}

const Block: React.FC<Props> = ({
  title,
  children,
  type,
  raw,
  href,
  className,
  onClick,
}) => {
  const containerClassName = cx(
    {
      [styles.block]: !raw,
    },
    className,
  );
  const containerProps = {
    'data-aid': AIDS.BLOCK,
    'data-block-type': type,
    className: containerClassName,
    onClick,
  };

  const blockContent = raw ? (
    children
  ) : (
    <>
      {title && (
        <div className={styles.blockTitle}>
          <TextLabel
            type="T19"
            value={title}
            className={styles.blockTitleLabel}
            enableEllipsis={false}
          />
        </div>
      )}
      <div className={styles.blockContent}>{children}</div>
    </>
  );

  return href ? (
    <a href={href} target="_blank" {...containerProps}>
      {blockContent}
    </a>
  ) : (
    <div {...containerProps}>{blockContent}</div>
  );
};

export default Block;
