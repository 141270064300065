// @ts-nocheck
import _ from 'lodash';
import { CSSProperties } from 'react';
import type { CompRef } from 'types/documentServices';

function findFirstContainerMatchingConditions(
  editorAPI,
  components,
  conditions,
): CompRef | undefined {
  let currentAncestor = _.head(components);
  while (
    currentAncestor &&
    !isAnyConditionFulfilled(conditions, currentAncestor)
  ) {
    currentAncestor =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
        currentAncestor,
      );
  }
  return currentAncestor;
}

function isAnyConditionFulfilled(conditions, component) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(conditions, function (condition) {
    return condition(component);
  });
}

function getPosition(isShowOnFixedPosition): CSSProperties['position'] {
  return isShowOnFixedPosition ? 'fixed' : 'absolute';
}

function getBoundingStyle(
  isShowOnFixedPosition,
  layout,
  boundedComps,
  borderWidth,
) {
  let boxOffset = 0;
  let boxExtraHeightWidth = 0;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/size
  if (_.size(boundedComps) > 1) {
    boxOffset = 2;
    boxExtraHeightWidth = boxOffset * 2;
  }

  const { bounding } = layout;

  return {
    position: getPosition(isShowOnFixedPosition),
    top: layout.bounding.y - borderWidth - boxOffset,
    left: layout.bounding.x - borderWidth - boxOffset,
    width: bounding.width + boxExtraHeightWidth,
    height: bounding.height + boxExtraHeightWidth,
  };
}

export { getPosition, getBoundingStyle, findFirstContainerMatchingConditions };
