export function loadScript(scriptURL: string) {
  return new Promise((resolve, reject) => {
    const headElement = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');

    script.async = true;
    script.src = scriptURL;
    script.onload = resolve;
    script.onerror = reject;

    headElement.appendChild(script);
  });
}

export async function loadScripts(scriptURLs: string[]) {
  for (const scriptURL of scriptURLs) {
    await loadScript(scriptURL);
  }
}
