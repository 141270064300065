// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as pageMenuItemHelpers from '../../utils/pageMenuItemHelpers';
import pageSettingsTabMixin from './pageSettingsTabMixin';
import * as coreBi from '@/coreBi';
import React from 'react';
import * as baseUI from '@/baseUI';
import * as symbols from '@wix/santa-editor-symbols';
import * as util from '@/util';
import { translate } from '@/i18n';
import CONSTANTS from '../../utils/constants';
import { CustomScroll, ToggleSwitch } from '@wix/wix-base-ui';
import { isCustomMenusEnabled } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'tabPageInfoMainMenu',
  mixins: [pageSettingsTabMixin],
  render() {
    const isHome = this.isHomePage();

    return (
      <CustomScroll>
        <div className="page-info tab-inner">
          <section className="section-page-title">
            <baseUI.textInput
              label="Pages_Info_Name_Title"
              maxLength={CONSTANTS.NAME_MAX_LENGTH}
              validator={[
                util.validate.notEmptyString,
                util.validate.noHTMLTags,
              ]}
              invalidMessage="Pages_Info_Name_Error_Tooltip"
              value={this.getValueFromPageData('title')}
              onChange={this.onChange}
              validateOnBlurOnly={true}
              infoText="Pages_Info_Name_Tooltip"
              className="control-text-input"
            />
          </section>

          {!isCustomMenusEnabled() && (
            <section className="section-hide-page">
              <ToggleSwitch
                value={this.linkPageProperty('hidePage').value}
                label="Pages_Info_Hide_Toggle"
                onChange={this.linkPageProperty('hidePage').requestChange}
              />
            </section>
          )}

          {!isHome ? (
            <section
              key="page-settings-tab-page-non-home"
              className="page-settings-control-description"
            >
              <span>{translate('Pages_Info_Home_NotSelected')}</span>
              <baseUI.button
                icon="settingsHome"
                label="Pages_Info_Home_Set"
                onClick={this.setAsHomepage}
              />
            </section>
          ) : null}

          {isHome ? (
            <section
              key="page-settings-tab-page-home"
              className="page-settings-control-description"
            >
              <span>
                <symbols.symbol name="settingsHome" />
                {translate('Pages_Info_Home_IsSelected')}
              </span>

              <baseUI.infoIcon
                uniqueId="changeHomePageTooltip"
                title="Pages_Info_Home_Popup_Title"
                text="Pages_Info_Home_Popup_Text"
                className="page-settings-change-homepage-tooltip"
              />
            </section>
          ) : null}
        </div>
      </CustomScroll>
    );
  },
  propTypes: {
    menuItem: PropTypes.object.isRequired,
  },
  setAsHomepage() {
    const editorAPI = this.getEditorAPI();
    editorAPI.homePage.set(this.getPageId());
    editorAPI.bi.event(
      coreBi.events.topbar.pages
        .top_bar_PAGES_settings_page_info_home_page_change_set_click,
    );
  },

  onChange(newValue) {
    pageMenuItemHelpers.renamePage(
      this.getEditorAPI(),
      this.props.menuItem.pageData,
      newValue,
    );
  },

  isHomePage() {
    if (this.props.menuItem.pageData && this.props.menuItem.pageData.id) {
      return this.getEditorAPI().pages.isHomePage(
        this.props.menuItem.pageData.id,
      );
    }
    return false;
  },
});
