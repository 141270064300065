'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

const moreBehaviorHelper = require("./moreBehaviorHelper")
module.exports =
    {
        "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
        "hide": false,
        "title": "add_section_vectorart_icons_label",
        "subNavigationTitle": "add_section_vectorart_icons_label",
        "presetTitle": "add_section_vectorart_icons_label",
        "tooltipTitle": "add_section_vectorart_icons_label",
        "automationId": "add-panel-section-iconsSection",
        "subNavigationHide": false,
        "showSectionHeader": true,
        "additionalBehaviours": {
            "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
            "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
            "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
            "iconEnabledForComps": {}
        },
        "props": {
            "image": "addPanelData/sections/vectorArt/iconsSection.v1.png",
            "retinaImage": {
                "src": "addPanelData/sections/vectorArt/iconsSection-@2x.v2.png",
                "dimensions": {
                    "width": 648,
                    "height": 1124
                }
            },
            "imageHover": null,
            "items": [{
                "id": "VectorArt_Icons_1",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 91.2,
                        "height": 160.79999999999998,
                        "x": 237,
                        "y": 20,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "1aed812a4810440e950e9f3215d659fe.svg",
                        "title": "media-5-14",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vzh",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyso"
                },
                "preset": {
                    "rect": {"width": 76, "height": 134, "x": 237, "y": 20},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "235, 13, 320, 165"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_2",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 86,
                        "height": 106,
                        "x": 168,
                        "y": 23,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "8490ff4eae454a60b9ded2461ecf5a89.svg",
                        "title": "Alarm Clock",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4w02",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yysu"
                },
                "preset": {
                    "rect": {"width": 44, "height": 53, "x": 168, "y": 23},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "162, 20, 229, 86"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_3",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 110,
                        "height": 86,
                        "x": 89,
                        "y": 27,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "49500f65e1204772b8ff4d00f0a20970.svg",
                        "title": "House",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vzu",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yysy"
                },
                "preset": {
                    "rect": {"width": 55, "height": 44, "x": 89, "y": 28},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "88, 20, 156, 83"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_4",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 116,
                        "height": 86,
                        "x": 6,
                        "y": 29,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "3c119d0c356d4ba5ba6bcd134015b170.svg",
                        "title": "media-6-07",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vzn1",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yysz"
                },
                "preset": {
                    "rect": {"width": 58, "height": 44, "x": 6, "y": 30},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "5, 23, 73, 84"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_5",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 106,
                        "height": 94,
                        "x": 91,
                        "y": 88,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "404c5b4292e24c669111c5956b4a937c.svg",
                        "title": "Ship",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vvz",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyst"
                },
                "preset": {
                    "rect": {"width": 53, "height": 47, "x": 91, "y": 90},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "85, 89, 155, 153"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_6",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 74,
                        "height": 108,
                        "x": 173,
                        "y": 92,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "2ccf8ac59d8442388b841e333a39783b.svg",
                        "title": "Mail",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vvv",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yysw"
                },
                "preset": {
                    "rect": {"width": 38, "height": 54, "x": 173, "y": 92},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "160, 90, 229, 155"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_7",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 128,
                        "height": 106,
                        "x": 5,
                        "y": 103,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "d501fdcdd8f84d7b92c37c2d2e2c4dca.svg",
                        "title": "Laptop",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vzk",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyt1"
                },
                "preset": {
                    "rect": {"width": 64, "height": 55, "x": 5, "y": 104},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "3, 93, 80, 162"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_8",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 68,
                        "height": 54,
                        "x": 10,
                        "y": 167,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "94c1ca7df7c54940ac83fa26b505f0f2.svg",
                        "title": "media-19-37",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vzd",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyt5"
                },
                "preset": {
                    "rect": {"width": 34, "height": 27, "x": 10, "y": 169},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "7, 164, 50, 203"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_9",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 60,
                        "height": 46,
                        "x": 64,
                        "y": 168,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "dab67b7902f5450d8acbf38bdf6deed0.svg",
                        "title": "media-19-31",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vz9",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyt9"
                },
                "preset": {
                    "rect": {"width": 30, "height": 24, "x": 64, "y": 169},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "58, 164, 102, 200"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_10",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 64,
                        "height": 46,
                        "x": 116,
                        "y": 168,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "de8c68b8c26a4567a365682b2010a518.svg",
                        "title": "media-19-30",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vz5",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yytc"
                },
                "preset": {
                    "rect": {"width": 32, "height": 24, "x": 116, "y": 169},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "110, 161, 155, 201"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_11",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 68,
                        "height": 44,
                        "x": 172,
                        "y": 169,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "0655f1acc09d4724859d972c685d6be9.svg",
                        "title": "media-19-26",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vz1",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yytg"
                },
                "preset": {
                    "rect": {"width": 34, "height": 23, "x": 172, "y": 170},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "167, 163, 213, 200"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_12",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 66,
                        "height": 44,
                        "x": 223,
                        "y": 168,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "5a554fb2f1644a90ac2fec7664bf69ca.svg",
                        "title": "media-19-01",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vvp",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyti"
                },
                "preset": {
                    "rect": {"width": 33, "height": 22, "x": 223, "y": 170},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "219, 166, 261, 200"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_13",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 70,
                        "height": 46,
                        "x": 278,
                        "y": 168,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "82b7a4d1ff7f4d84b867e081312e755a.svg",
                        "title": "media-19-36",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vyx",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yytm"
                },
                "preset": {
                    "rect": {"width": 35, "height": 23, "x": 278, "y": 170},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "271, 167, 320, 201"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_14",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 110.39999999999999,
                        "height": 150,
                        "x": 11,
                        "y": 211,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "e398fd03e9154b66b913b19528b6afa9.svg",
                        "title": "all_colored-11",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vyk",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yysc"
                },
                "preset": {
                    "rect": {"width": 93, "height": 125, "x": 11, "y": 211},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "5, 208, 108, 341"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_15",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 118.8,
                        "height": 150,
                        "x": 116,
                        "y": 211,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "ecdfbe77beaf43a8a5e977e71f44a463.svg",
                        "title": "all_colored-09",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vyo",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yysl"
                },
                "preset": {
                    "rect": {"width": 100, "height": 125, "x": 116, "y": 211},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "113, 207, 219, 341"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_16",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 97.2,
                        "height": 151.2,
                        "x": 232,
                        "y": 212,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "2e9f52bfa7ed4317a1dd1ff0ee134e6c.svg",
                        "title": "all_colored-10",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vys",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yysm"
                },
                "preset": {
                    "rect": {"width": 81, "height": 126, "x": 232, "y": 212},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "224, 207, 321, 343"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_17",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 112,
                        "height": 102,
                        "x": 10,
                        "y": 352,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "4e0d69c2787b483c92ceb16c221e417c.svg",
                        "title": "media-30-13",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vyg",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yysh"
                },
                "preset": {
                    "rect": {"width": 56, "height": 52, "x": 10, "y": 353},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "4, 345, 72, 411"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_18",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 150,
                        "height": 102,
                        "x": 77,
                        "y": 352,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "8682e8b233ea4e6f87d21fabd641d3a9.svg",
                        "title": "media-30-07",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vy3",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yysj"
                },
                "preset": {
                    "rect": {"width": 75, "height": 52, "x": 77, "y": 353},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "77, 344, 153, 412"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_19",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 62,
                        "height": 56,
                        "x": 175,
                        "y": 351,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "d4b7854767284af0b8946750c64dc779.svg",
                        "title": "House 2",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vxz",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yytt"
                },
                "preset": {
                    "rect": {"width": 31, "height": 28, "x": 175, "y": 353},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "167, 346, 212, 388"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_20",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 46,
                        "height": 64,
                        "x": 233,
                        "y": 353,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "73532054bfad467ea62bf14f29c02893.svg",
                        "title": "Location Pin",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vxt",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yytv"
                },
                "preset": {
                    "rect": {"width": 23, "height": 32, "x": 233, "y": 353},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "220, 347, 268, 390"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_21",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 58,
                        "height": 52,
                        "x": 283,
                        "y": 353,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "f2cf7395eaae42638598f3237c1ddf64.svg",
                        "title": "media-65-19",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vwq",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyue"
                },
                "preset": {
                    "rect": {"width": 29, "height": 26, "x": 283, "y": 355},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "274, 347, 321, 391"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_22",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 56,
                        "height": 58,
                        "x": 177,
                        "y": 395,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "0345921c668b47ed87c489509b3c146e.svg",
                        "title": "media-65-11",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vxf",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyu3"
                },
                "preset": {
                    "rect": {"width": 29, "height": 29, "x": 177, "y": 395},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "165, 393, 214, 433"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_23",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 58,
                        "height": 54,
                        "x": 230,
                        "y": 398,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "28ba11b32f354afab995adf598678192.svg",
                        "title": "media-65-35",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vxa",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyu5"
                },
                "preset": {
                    "rect": {"width": 29, "height": 28, "x": 230, "y": 399},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "220, 395, 268, 433"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_24",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 58,
                        "height": 54,
                        "x": 283,
                        "y": 398,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "08d33bf98eec4e73849470f9786480a0.svg",
                        "title": "media-65-61",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vxl",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyu1"
                },
                "preset": {
                    "rect": {"width": 29, "height": 28, "x": 283, "y": 399},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "273, 396, 320, 436"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_25",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 110,
                        "height": 112,
                        "x": 12,
                        "y": 415,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "c005539e614743a9ac19078bd6a80c2e.svg",
                        "title": "media-30-17",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vyb",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyse"
                },
                "preset": {
                    "rect": {"width": 55, "height": 56, "x": 12, "y": 415},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "3, 415, 73, 477"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_26",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 138,
                        "height": 102,
                        "x": 80,
                        "y": 419,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "c78bb96c0ca34b929a7f6aa02d64b18c.svg",
                        "title": "media-30-05",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vy6",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yysf"
                },
                "preset": {
                    "rect": {"width": 69, "height": 52, "x": 80, "y": 420},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "78, 415, 154, 481"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_27",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 40,
                        "height": 50,
                        "x": 234,
                        "y": 444,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "6a0bf86be65b4e5c81c9fa78a00264ed.svg",
                        "title": "media-65-60",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vwz",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyua"
                },
                "preset": {
                    "rect": {"width": 21, "height": 26, "x": 234, "y": 444},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "220, 438, 268, 478"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_28",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 56,
                        "height": 50,
                        "x": 177,
                        "y": 444,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "9f67adfae0ad49e4ba357a3f11b71c3b.svg",
                        "title": "Percentage",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vwu",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyuc"
                },
                "preset": {
                    "rect": {"width": 28, "height": 26, "x": 177, "y": 445},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "166, 439, 214, 477"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_29",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 58,
                        "height": 44,
                        "x": 283,
                        "y": 444,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "1529249dc9884983941adc6b06b89607.svg",
                        "title": "Camera",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vx4",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyu8"
                },
                "preset": {
                    "rect": {"width": 29, "height": 23, "x": 283, "y": 445},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "272, 441, 320, 479"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_30",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 92,
                        "height": 90,
                        "x": 206,
                        "y": 493,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "063eacf697bd4202a4b65a8bf4dedc40.svg",
                        "title": "media-66-07",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vw9",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyuv"
                },
                "preset": {
                    "rect": {"width": 46, "height": 46, "x": 206, "y": 494},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "201, 488, 259, 548"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_31",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 86,
                        "height": 92,
                        "x": 11,
                        "y": 495,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "4fcd052d17344562ba803e707ce51dab.svg",
                        "title": "media-66-06",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vwl",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyun"
                },
                "preset": {
                    "rect": {"width": 43, "height": 46, "x": 11, "y": 495},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "3, 486, 61, 549"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_32",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 100,
                        "height": 90,
                        "x": 73,
                        "y": 494,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "5d46d1704f8f4ee19b9efe9aac750848.svg",
                        "title": "Train Station",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vwg",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyuq"
                },
                "preset": {
                    "rect": {"width": 50, "height": 46, "x": 73, "y": 495},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "70, 489, 130, 551"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_33",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 72,
                        "height": 92,
                        "x": 147,
                        "y": 495,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "0ad71dd76ed6462ea572e9baccece6ac.svg",
                        "title": "media-66-10",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vwc",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyuz"
                },
                "preset": {
                    "rect": {"width": 36, "height": 46, "x": 147, "y": 495},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "138, 489, 191, 548"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }, {
                "id": "VectorArt_Icons_34",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 88,
                        "height": 86,
                        "x": 270,
                        "y": 496,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "e04a0e5f88bc48f1a3f079f0e45ad067.svg",
                        "title": "media-66-19",
                        "link": null
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "displayMode": "fit"
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "shapeStyle": {"opacity": 1},
                        "overrideColors": null,
                        "charas": "design-jb2q4vw3",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-j8a6yyuh"
                },
                "preset": {
                    "rect": {"width": 44, "height": 44, "x": 270, "y": 497},
                    "tags": null,
                    "shape": {"name": "rect", "coords": "266, 492, 317, 548"}
                },
                "mediaManager": {"path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"}
            }],
            "compTypes": ["wysiwyg.viewer.components.VectorImage"]
        },
        "sectionFooter": {
            "styleOverride": {"paddingTop": "25px"},
            "label": "add_section_vectorart_icons_more_tooltip_title",
            "action": moreBehaviorHelper,
            "category": "VECTOR_ART",
            "openSource": "add_panel_vector_art_icons",
            "path": "public/98285ba8ab164d17a935742bd019e150/b1f33f6c9d644d0d9a61120d9ca3fa27"
        },
        "help": {"hide": false, "text": "add_section_vectorart_icons_tooltip_description"}
    }
