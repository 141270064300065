import { BasePublicApi } from '@/apilib';
import * as styles from '@/styles';
import { array, sections } from '@/util';
import experiment from 'experiment';
import { DEFAULT_WITH_FILL_LAYERS_SKIN_NAME } from './constants';

import type { Scope } from './scope';
import type { CompRef } from '@wix/document-services-types';

const { styleUtil } = styles.advancedStyle;

const advancedSiteSegmentBackgroundEnabled = (
  scope: Scope,
  selectedComponent: CompRef | CompRef[],
) => {
  const component = array.asSingle(selectedComponent);
  return (
    experiment.isOpen('se_siteSegmentMediaBackground') &&
    sections.isSectionsEnabled() &&
    compDesignExistsAndHasFills(scope, component) &&
    scope.sections.isHeaderOrFooter(component)
  );
};

// TODO - after the finalized UX/UI, remove these two functions if we decide to not use Change Background panel

export const compDesignExistsAndHasFills = (
  scope: Scope,
  compRef: CompRef | CompRef[],
) => {
  const component = array.asSingle(compRef);
  const componentDesign = scope.components.design.get(component);

  if (!componentDesign) {
    return false;
  }
  const colorLayers = componentDesign?.background?.colorLayers;
  // if colorLayers exist, but is an empty array, the panel will not open. !colorLayers is a valid value (bg of type None)
  return !colorLayers || colorLayers.length > 0;
};

// migration to the DefaultWithFillLayers skin
const updateCompStyleIfApplicable = (
  scope: Scope,
  selectedComponent: CompRef | CompRef[],
) => {
  if (!advancedSiteSegmentBackgroundEnabled(scope, selectedComponent)) {
    return;
  }
  const componentStyle = scope.components.style.get([
    array.asSingle(selectedComponent),
  ]);
  if (
    !componentStyle ||
    componentStyle.skin === DEFAULT_WITH_FILL_LAYERS_SKIN_NAME
  ) {
    return;
  }

  const skinDefinitionFunction = scope.editorAPI.theme.skins.getSkinDefinition;

  const skinParams = styleUtil.getStyleForSkin(
    skinDefinitionFunction,
    DEFAULT_WITH_FILL_LAYERS_SKIN_NAME,
    null,
  );

  componentStyle.skin = DEFAULT_WITH_FILL_LAYERS_SKIN_NAME;

  // to transfer the properties from old skin to the default of the new one
  styleUtil.mergeCurrentSkinIntoDefaultWithFillLayers(
    skinParams.properties,
    componentStyle.style.properties,
  );
  Object.assign(componentStyle.style, {
    properties: skinParams.properties,
    propertiesSource: skinParams.propertiesSource,
  });

  scope.components.style.update(selectedComponent, componentStyle, true);
};

export class SiteSegmentMediaBackgroundPublicApi extends BasePublicApi<Scope> {
  advancedSiteSegmentBackgroundEnabled = this.bindScope(
    advancedSiteSegmentBackgroundEnabled,
  );
  compDesignExistsAndHasFills = this.bindScope(compDesignExistsAndHasFills);
  updateCompStyleIfApplicable = this.bindScope(updateCompStyleIfApplicable);
}
