import { hoc } from '@/util';
import { leftBar, bi, components, pages } from '@/stateManagement';

import type { StateMapperArgs } from 'types/redux';

import { subPanels } from './Background';

const mapStateToProps = ({ state, dsRead }: StateMapperArgs) => {
  const device = leftBar.selectors.getDeviceType(state);
  const pageId = dsRead.pages.getPrimaryPageId();
  const emptyBackgroundObject: {
    ref: AnyFixMe;
    mediaRef: AnyFixMe;
  } = {
    ref: {},
    mediaRef: null,
  };

  const bgData =
    dsRead.pages.background.get(pageId, device) || emptyBackgroundObject;
  const mobileBgData =
    dsRead.pages.background.get(pageId, 'mobile') || emptyBackgroundObject;

  const focusedPage = pages.selectors.getFocusedPage(state);

  const openPanels = state.panelData.openPanels || [];
  const shouldSpinSettingsIcon = openPanels.some(
    ({ name }) =>
      name === subPanels.IMAGE_SETTINGS || name === subPanels.VIDEO_SETTINGS,
  );

  if (!bgData.ref) {
    bgData.ref = {};
  }

  if (!mobileBgData.ref) {
    mobileBgData.ref = {};
  }

  return {
    bgData,
    mobileBgData,
    shouldSpinSettingsIcon,
    focusedPageComponentType: components.selectors.getCompType(
      focusedPage,
      dsRead,
    ),
  };
};

const mapDispatchToProps = {
  sendBi: bi.actions.event,
};

export const connectBackground = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
);
