import React, { useEffect, useRef, useState } from 'react';
import * as util from '@/util';
import constants from '@/constants';
import styles from './siteCreationAdditionalPages.scss';
import {
  type AdditionalPage,
  getScale,
  getThumbnailsHeight,
  getThumbnailWidth,
  splitToColumns,
} from './siteCreationUtils';
import {
  ADDITIONAL_PAGES_COLUMN_NUM,
  COLUMN_MARGINS,
  PLACEHOLDER_DIMENSIONS_RATIO,
} from './siteCreationConstants';
import SiteCreationBottomSection from './siteCreationBottomSection';
import SiteCreationAdditionalPagesThumbnail from './siteCreationAdditionalPagesThumbnail';
import { translate as t } from '@/i18n';

import type { CeType, IPageDescription } from '@/presetApi';
import type { UserContactInfo } from '@/editorPaas';
import type { SiteCreationProps } from './siteCreationPagesView';

const { SITE_CREATION: SITE_CREATION_CONSTS } = constants;

interface IPageDescriptionWithID extends IPageDescription {
  id?: string;
}

export interface SiteCreationAdditionalPagesProps extends SiteCreationProps {
  headerPresetNode: any;
  footerPresetNode: any;
  curatedTemplateId: number;
  userContactInfo: UserContactInfo;
  addHomePagePromise: Promise<void>;
  setIsWizardVisible: (isVisible: boolean) => void;
  setEndScreenVisible: (isVisible: boolean) => void;
  setCreationFinishPromise: (creationFinishPromise: Promise<void>) => void;
  setIsSiteCreationUiVisible: () => void;
}

const removeHeaderAndFooter = (selectedPages: AdditionalPage[]) =>
  selectedPages.map((selectedPage) => {
    selectedPage.page.presetPreviews.shift();
    selectedPage.page.presetPreviews.pop();
    return selectedPage;
  });

const SiteCreationAdditionalPages: React.FC<
  SiteCreationAdditionalPagesProps
> = ({
  windowWidth,
  windowHeight,
  addPagePreset,
  getPagePresets,
  userContactInfo,
  headerPresetNode,
  footerPresetNode,
  businessFirstFlow,
  curatedTemplateId,
  addHomePagePromise,
  setIsWizardVisible,
  setEndScreenVisible,
  fireContentScrolled,
  fireNextButtonClick,
  fireViewStartLoading,
  fireViewContentLoaded,
  fireAdditionalPageClick,
  setCreationFinishPromise,
  onSiteCreationAllPagesAdded,
  sendWizardFinishedBi,
  setLastStepSiteCreationFinished,
  setIsSiteCreationUiVisible,
}) => {
  const { siteCreationController } = window;
  const [scale, setScale] = useState<number>(0.25);
  const [columns, setColumns] = useState<AdditionalPage[][]>([]);
  const [isScreenVisible, setIsScreenVisible] = useState<boolean>(true);
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setScale(getScale(windowWidth));
  }, [windowWidth]);

  useEffect(() => {
    if (
      headerPresetNode &&
      footerPresetNode &&
      curatedTemplateId &&
      !columns.length
    ) {
      fireViewStartLoading(SITE_CREATION_CONSTS.SCREENS.PAGES);
      const { caas } = siteCreationController;
      const allPages: IPageDescriptionWithID[] = caas.getPages().slice(1);
      Promise.all(
        allPages.map(async (caasPage) => {
          const [page] = await getPagePresets(
            caas,
            caasPage,
            userContactInfo,
            [curatedTemplateId],
            null,
            false,
          );
          if (!page?.presetPreviews?.length) {
            return null;
          }
          const ceTypes = caasPage.sections.map(
            (section) => section.ceType,
          ) as CeType[];
          page.presetPreviews.unshift(headerPresetNode);
          page.presetPreviews.push(footerPresetNode);
          return {
            id: caasPage.id,
            isSelected: false,
            name: caasPage.name,
            page,
            ceTypes,
          };
        }),
      ).then((pages) => {
        if (!siteCreationController.autoSkip) {
          setColumns(splitToColumns(pages));
        }
        if (pages.length === 0 || siteCreationController.autoSkip) {
          onClickNext([], SITE_CREATION_CONSTS.NAVIGATIONS.AUTO_SKIP);
        }
        fireViewContentLoaded(SITE_CREATION_CONSTS.SCREENS.PAGES, pages.length);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerPresetNode, footerPresetNode, curatedTemplateId]);

  const onPageClick = async (pageId: string) => {
    setColumns(
      columns.map((pages) =>
        pages.map((page) => {
          if (page.id === pageId) {
            page.isSelected = !page.isSelected;
            fireAdditionalPageClick(page.isSelected, page.name);
          }
          return page;
        }),
      ),
    );
  };

  const getSelectedPages = () => {
    return columns.reduce(
      (selectedPages, columnPages) => [
        ...selectedPages,
        ...columnPages.filter(({ isSelected }) => isSelected),
      ],
      [],
    );
  };

  const onClickNext = async (
    selectedPages: AdditionalPage[],
    navButton: string,
  ) => {
    selectedPages = removeHeaderAndFooter(selectedPages);
    fireNextButtonClick(SITE_CREATION_CONSTS.SCREENS.PAGES, navButton);
    setIsLoaderVisible(true);
    await addHomePagePromise;
    siteCreationController.screensLoadingTimes.pages.setFinished();
    if (businessFirstFlow) {
      setIsScreenVisible(false);
      setEndScreenVisible(true);
    } else {
      setIsWizardVisible(false);
      setTimeout(() => {
        sendWizardFinishedBi();
        setLastStepSiteCreationFinished();
        setIsSiteCreationUiVisible();
      }, 300);
    }
    for (let i = 0; i < selectedPages.length; i++) {
      await addPagePreset(
        selectedPages[i].page,
        selectedPages[i].name,
        false,
        false,
        curatedTemplateId,
        selectedPages[i].ceTypes,
      );
    }
    setCreationFinishPromise(onSiteCreationAllPagesAdded());
  };

  const getContentHeight = () => {
    const TOP_PADDING = 10;
    return `calc(100% - ${headerRef.current?.clientHeight}px - ${TOP_PADDING}px)`;
  };

  const getPresetsPlaceholders = () => {
    const thumbnailsHeight = getThumbnailsHeight(
      windowHeight,
      headerRef,
      footerRef,
    );
    const width =
      getThumbnailWidth(scale) * ADDITIONAL_PAGES_COLUMN_NUM + COLUMN_MARGINS;
    const height = width / PLACEHOLDER_DIMENSIONS_RATIO;
    return (
      <div
        className={styles.siteCreationAdditionalPlaceholder}
        style={{
          width,
          height,
          maxHeight: thumbnailsHeight,
          backgroundImage: `url(${util.media.getMediaUrl(
            `siteCreation/additionalSkeleton.gif`,
          )})`,
        }}
      />
    );
  };

  return (
    <div
      className={util.cx(styles.siteCreationAdditionalPages, {
        hidden: !isScreenVisible,
      })}
      data-hook="additional-pages-screen"
    >
      <div className={styles.siteCreationAdditionalPagesContentWrapper}>
        <div ref={headerRef} className={styles.additionalPagesContentSection}>
          <span className="main-additional-pages-title">
            {t('site_creation_pages_title')}
          </span>
          <span className="secondary-additional-pages-title">
            {t('site_creation_pages_subtitle')}
          </span>
        </div>
        <div
          style={{
            height: getContentHeight(),
          }}
          className={styles.additionalPagesColumnsWrapper}
        >
          {columns.length
            ? columns.map((pages, i) => (
                <div className={styles.additionalPagesColumn} key={i}>
                  {pages.map(({ id, name, page, isSelected }) => (
                    <SiteCreationAdditionalPagesThumbnail
                      key={id}
                      id={id}
                      name={name}
                      presetIndex={i}
                      isSelected={isSelected}
                      onPageClick={onPageClick}
                      fireContentScrolled={fireContentScrolled}
                      scale={scale}
                      page={page}
                    />
                  ))}
                </div>
              ))
            : getPresetsPlaceholders()}
        </div>
      </div>
      <SiteCreationBottomSection
        wrapperRef={footerRef}
        mainActionLabel={t(
          businessFirstFlow
            ? 'site_creation_business_first_pages_next_button'
            : 'site_creation_pages_Edit_button',
        )}
        symbol={'arrowRightWhite'}
        onMainActionClick={() =>
          onClickNext(
            getSelectedPages(),
            SITE_CREATION_CONSTS.NAVIGATIONS.EDIT_SITE,
          )
        }
        isLoaderVisible={isLoaderVisible}
        secondaryActionLabel={t('site_creation_pages_skip_button')}
        onSecondaryActionClick={() =>
          onClickNext([], SITE_CREATION_CONSTS.NAVIGATIONS.SKIP)
        }
      />
    </div>
  );
};

export default SiteCreationAdditionalPages;
