// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  superApp: AnyFixMe;
  selectTabByName: (tabName: string) => void;
  isLoading: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'superAppManagePanel';

  static propTypes = {
    superApp: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="super-app-manage-panel">
        <this.props.superApp.panel.manage.template
          selectTabByName={this.props.selectTabByName}
          isLoading={this.props.superApp.isLoading}
        />
      </div>
    );
  }
}
