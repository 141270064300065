import type React from 'react';
import { Edit } from '@wix/wix-ui-icons-common';

import { sectionClickOnAction } from '@wix/bi-logger-editor/v2';
import { translationUtils, biLogger } from '@/util';
import { EditorRestrictionsApiKey, QuickEditApiKey } from '@/apis';
import { gfppModel } from '@/gfppData';

import type { StageActionsOwnProps } from './StageActions';
import type {
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import type { CompRef } from 'types/documentServices';
import type { GFPPAction } from '@wix/editor-component-types';
import type { EditorAPI } from '@/editorAPI';

export interface StageAction {
  id: string;
  Icon?: React.JSXElementConstructor<any>;
  label: string;
  onClick: () => void;
  dataHook: string;
  biName: string;
}

const QUICK_EDIT_ACTION_HOOK = 'section-stage-actions-quick-edit';
const WIDGET_ACTION_HOOK_PREFIX = 'section-stage-actions-widget-action';
const HEIGHT_THRESHOLD = 48;
const CONTAINER_MARGIN_LEFT = 18;

export interface StageActionsStateProps {
  actions: StageAction[];
  shouldShow: boolean;
  horizontallyCentered: boolean;
  containerOffsetLeft: number;
}

export interface StageActionsDispatchProps {
  reportActionClicked: (action: StageAction) => void;
}

const STAGE_ACTIONS_ORIGIN = 'sections_stage_actions';

const getMainAction = (editorAPI: EditorAPI, compRef: CompRef): GFPPAction => {
  const compGfppData = gfppModel.getFullComponentGfppData(
    editorAPI,
    [compRef],
    STAGE_ACTIONS_ORIGIN,
  );

  return compGfppData.mainActions[0];
};

const getWidgetsActions = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): StageAction[] => {
  const widgetRefs = editorAPI.tpa.getUniqueChildrenWidgets(compRef);

  const widgetsActions = widgetRefs.reduce<StageAction[]>(
    (actions, widgetRef) => {
      const mainAction = getMainAction(editorAPI, widgetRef);
      if (!mainAction) return actions;

      actions.push({
        id: widgetRef.id,
        label: translationUtils.translateIfNeeded(mainAction.label),
        onClick: () => {
          editorAPI.selection.selectComponentByCompRef(widgetRef);
          mainAction.onClick(editorAPI, [widgetRef], STAGE_ACTIONS_ORIGIN);
        },
        biName: mainAction.label,
        dataHook: WIDGET_ACTION_HOOK_PREFIX,
      });

      return actions;
    },
    [],
  );

  return widgetsActions;
};

export const mapStateToProps: MapStateToProps<
  StageActionsStateProps,
  StageActionsOwnProps
> = ({ editorAPI }, { compRef }) => {
  const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);
  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);

  const quickEditAction = {
    id: 'quickEdit',
    label: translationUtils.translateIfNeeded(
      'section_action_edit_section_button',
    ),
    Icon: Edit,
    onClick: () => {
      editorAPI.selection.selectComponentByCompRef(compRef);
      quickEditAPI.openPanel({
        rootControlCompRef: compRef,
        origin: STAGE_ACTIONS_ORIGIN,
      });
    },
    dataHook: QUICK_EDIT_ACTION_HOOK,
    biName: 'quickEdit',
  };

  const canShowQuickEdit =
    quickEditAPI.isQuickEditAvailable() &&
    editorRestrictionsApi.allowed('sections_quick-edit-stage-action.visible');
  const canShowWidgetActions =
    editorAPI.isDesktopEditor() &&
    !editorAPI.zoomMode.isInZoomMode() &&
    editorAPI.sections.isSection(compRef) &&
    editorRestrictionsApi.allowed('sections_widget-stage-action.visible');

  const defaultActions: StageAction[] = [canShowQuickEdit && quickEditAction];

  const widgetsActions = canShowWidgetActions
    ? getWidgetsActions(editorAPI, compRef)
    : [];

  const actions = defaultActions.concat(widgetsActions).filter(Boolean);
  const compHeight =
    editorAPI.components.layout.get_size(compRef).height *
    editorAPI.getSiteScale();

  return {
    actions,
    shouldShow: actions.length > 0,
    horizontallyCentered: compHeight <= HEIGHT_THRESHOLD,
    containerOffsetLeft:
      (editorAPI.scroll.get().scrollLeft || 0) + CONTAINER_MARGIN_LEFT,
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  StageActionsDispatchProps,
  StageActionsOwnProps
> = (dispatch, { compRef: sectionLikeRef }) => {
  const editorAPI = dispatch(getEditorAPI);
  const [defaultComponentFields] = editorAPI.bi.getComponentsBIParams([
    sectionLikeRef,
  ]);

  return {
    reportActionClicked: (action) => {
      const appIds = editorAPI.tpa
        .getUniqueChildrenWidgets(sectionLikeRef)
        .map(
          (ref: CompRef) => editorAPI.components.data.get(ref)?.appDefinitionId,
        )
        .filter(Boolean);

      biLogger.report(
        sectionClickOnAction({
          ...defaultComponentFields,
          action_name: action.biName,
          origin: STAGE_ACTIONS_ORIGIN,
          app_id: appIds.join(','),
        }),
      );
    },
  };
};
