import { caasPropNames } from './injectConsts';
import {
  validateJsonStructure,
  reportErrorOnInjection,
  reportOnNullOrEmptyContent,
} from '../contentInjectionUtil';

export function isTextInjection(propName: string): boolean {
  const { TITLE, SUBTITLE, LONG_TEXT } = caasPropNames;
  return [TITLE, SUBTITLE, LONG_TEXT].some(
    (caasPropName) => caasPropName === propName,
  );
}

export function handleTextInjection(
  structureString: string,
  textToReplace: string,
  textToInject: string,
) {
  if (!textToInject) {
    reportOnNullOrEmptyContent('text');
    return structureString;
  }
  try {
    const textToReplaceIncludeEscapeChars =
      handleEscapeCharsInTextToReplace(textToReplace);
    const textToInjectIncludeEscapeChars =
      handleEscapeCharsInTextToInject(textToInject);
    const structureStringWithTextInjected = structureString.replace(
      textToReplaceIncludeEscapeChars,
      textToInjectIncludeEscapeChars,
    );

    validateJsonStructure(structureStringWithTextInjected);
    return structureStringWithTextInjected;
  } catch (e) {
    reportErrorOnInjection(e, textToInject);
    return structureString;
  }
}

export function handleEscapeCharsInTextToReplace(text: string): string {
  return text.replace(/\n/gim, String.raw`\n`).replace(/\"/gim, String.raw`\"`);
}

export function handleEscapeCharsInTextToInject(text: string): string {
  return text
    .replace(/\r/gim, '')
    .replace(/\n/gim, '<br>')
    .replace(/\"/gim, String.raw`\"`);
}
