:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._linksListItem_1sezl_7 {
  cursor: pointer;
  margin-bottom: 9px; }
  ._linksListItem_1sezl_7:last-child {
    margin-bottom: 0; }

._text_1sezl_13 {
  display: inline-block;
  vertical-align: middle; }

._symbolContainer_1sezl_17 {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px; }
  ._symbolContainer_1sezl_17 svg path {
    fill: #116dff; }
