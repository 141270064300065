import constants from '@/constants';
import { domMeasurements, panels, mouseActions } from '@/stateManagement';
import { layoutUtils } from '@/layoutUtils';

import type { EditorAPI } from '@/editorAPI';
import type { SectionWithLayout } from '@/sections';
import type { EditorState } from '@/stateManagement';
import type {
  Dispatch,
  DispatchMapperArgs,
  MapDispatchToPropsFunction,
  StateMapperArgs,
} from 'types/redux';
import type { CompRef } from 'types/documentServices';
import { UNSCALED_DROPZONE_HEIGHT } from './constants';

export interface SectionsDropZoneContainerStateProps {
  isSectionDragToStageInProgress: boolean;
  isDragOverStageInProgress: boolean;
  sectionsWithLayout: SectionWithLayout[];
  previewHeight: number;
  previewTop: number;
  scrollTop: number;
  mousePosition: { x: number; y: number };
  siteScale: number;
  siteFooterRef: CompRef;
  stageEntryIndex?: number;
  shouldDisplayStaticDropZone: boolean;
}

type EditorAPIFieldType<Path> = GetFieldType<EditorAPI, Path>;

export interface SectionsDropZoneContainerDispatchProps {
  setSectionsStageEntryIndex: (index: number) => void;
  updateComponentTransformation: EditorAPIFieldType<'components.transformations.update'>;
  removeComponentTransformation: EditorAPIFieldType<'components.transformations.remove'>;
  setAutosaveEnabled: (enabled: boolean) => void;
  setDropZoneExtraSiteHeight: () => void;
}

export const mapStateToProps = ({
  editorAPI,
  state,
}: StateMapperArgs): SectionsDropZoneContainerStateProps => {
  const { height: previewHeight, top: previewTop } =
    domMeasurements.selectors.getPreviewPosition(state);
  const siteScale = domMeasurements.selectors.getSiteScale(state);
  const sectionsWithLayout = editorAPI.sections
    .getPageSectionsWithLayoutSortedByStageOrder(
      editorAPI.pages.getCurrentPage(),
      true,
    )
    .map((sectionWithLayout) => {
      sectionWithLayout.layout = layoutUtils.getScaledRect(
        sectionWithLayout.layout,
        siteScale,
      );
      return sectionWithLayout;
    });

  const siteFooterRef = editorAPI.siteSegments.getFooter();
  const panelData = panels.selectors.selectOpenLeftPanels(state)?.[0];
  const stageEntryIndex = panelData?.props?.stageEntryIndex;
  const emptyStateSectionReplacement =
    panelData?.props?.emptyStateSectionReplacement;

  const registeredMouseMoveAction =
    editorAPI.mouseActions.getRegisteredMouseMoveAction();

  const isSectionDragToStageInProgress =
    registeredMouseMoveAction?.type ===
    constants.MOUSE_ACTION_TYPES.ADD_SECTION_PANEL_DRAG_TO_STAGE;

  const isMouseOverStage = mouseActions.selectors.isMouseOverStage(state);

  const mousePosition = {
    x: 0,
    y: 0,
    ...editorAPI.cursor.get(),
  };

  return {
    isSectionDragToStageInProgress,
    isDragOverStageInProgress:
      isSectionDragToStageInProgress && isMouseOverStage,
    sectionsWithLayout,
    shouldDisplayStaticDropZone:
      emptyStateSectionReplacement && editorAPI.sections.isEmptyState(),
    scrollTop: editorAPI.scroll.get().scrollTop,
    mousePosition,
    previewHeight,
    previewTop,
    siteScale,
    siteFooterRef,
    stageEntryIndex,
  };
};

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: DispatchMapperArgs,
): EditorAPI => editorAPI;

export const mapDispatchToProps: MapDispatchToPropsFunction<
  SectionsDropZoneContainerDispatchProps,
  {}
> = (dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    setDropZoneExtraSiteHeight: () => {
      editorAPI.documentMode.setExtraSiteHeight(UNSCALED_DROPZONE_HEIGHT);
    },
    setSectionsStageEntryIndex: (index: number) => {
      dispatch(
        panels.actions.updatePanel(
          constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
          {
            stageEntryIndex: index,
          },
        ),
      );
    },

    updateComponentTransformation: (compRef, data) => {
      if (
        editorAPI.components.transformations.get(compRef)?.translate?.y
          .value === data.translate.y
      ) {
        return;
      }

      editorAPI.components.transformations.update(compRef, data);
    },
    removeComponentTransformation: editorAPI.components.transformations.remove,

    setAutosaveEnabled: (enabled: boolean) => {
      editorAPI.autosaveManager.init({ enabled }, true, true);
    },
  };
};
