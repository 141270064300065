function create(compFactory: AnyFixMe) {
  const registeredComponents: AnyFixMe = {};

  const getCompClass = (compType: AnyFixMe) =>
    registeredComponents[compType] || compFactory.getCompReactClass(compType);

  const registerComp = (compType: AnyFixMe, CompClass: AnyFixMe) => {
    registeredComponents[compType] = CompClass;
  };

  return {
    registerComp,
    getCompClass,
  };
}

export { create };
