import React, { type ComponentType } from 'react';
import _ from 'lodash';

import { hoc, media, tpaUtils, workspace, browserUtil } from '@/util';

import type {
  WixStoreAddPanelDispatchProps,
  WixStoreAddPanelOwnProps,
  WixStoreAddPanelStateProps,
} from './types';
import {
  mapDispatchToProps,
  mapStateToProps,
} from './wixStoreAddPanelViewMapper';

const {
  connect,
  renderWhenMutated,
  STORES: { EDITOR_API },
} = hoc;

class WixStoreAddPanelView extends React.Component<
  WixStoreAddPanelOwnProps &
    WixStoreAddPanelStateProps &
    WixStoreAddPanelDispatchProps
> {
  private isNewWorkspace: boolean = workspace.isNewWorkspaceEnabled();

  getMainAction = () => {
    if (_.isFunction(this.props.installAppOverride)) {
      return _.partial(
        this.props.installAppOverride,
        tpaUtils.getStoresAppDefId(),
      );
    }
    return this.props.installApp;
  };

  getBannerImgSrc() {
    let { bannerImage: bannerImgSrc } = this.props;

    if (!bannerImgSrc) {
      bannerImgSrc = 'wixStore/appMenuPanel/gallery/storeGalleryAddPanel.png';

      if (this.isNewWorkspace) {
        bannerImgSrc = browserUtil.isRetina()
          ? 'wixStore/appMenuPanel/gallery/newWorkspace_storeGalleryAddPanel@x2.png'
          : 'wixStore/appMenuPanel/gallery/newWorkspace_storeGalleryAddPanel.png';
      }
    }
    return media.getMediaUrl(bannerImgSrc);
  }

  render() {
    const WelcomeSection = this.props.welcomeSectionTemplate;

    return (
      <div>
        <WelcomeSection
          bannerImgSrc={this.getBannerImgSrc()}
          title="SiteApp_WixStores_AddStoresPanel_Title"
          subtitle="SiteApp_WixStores_AddStoresPanel_Subtitle"
          promotionalListItems={[
            'SiteApp_WixStores_AddStoresPanel_Bullet1',
            'SiteApp_WixStores_AddStoresPanel_Bullet2',
            'SiteApp_WixStores_AddStoresPanel_Bullet3',
          ]}
          mainActionLabel="tpa_add_panel_add_to_site"
          secondaryActionLabel="SiteApp_WixStores_AddStoresPanel_SecondaryLink"
          onMainActionClick={this.getMainAction()}
          onSecondaryActionClick={this.props.openAppMarket}
        />
      </div>
    );
  }
}

export default connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(
  renderWhenMutated(WixStoreAddPanelView),
) as ComponentType<WixStoreAddPanelOwnProps>;
