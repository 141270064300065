// @ts-nocheck
import keyboardShortcutsActionMap from '@/keyboardShortcuts';
import * as util from '@/util';

const shortcuts = {};

function reportPastePageStarted(isCrossSite) {
  const FEDOPS_INTERACTION = isCrossSite
    ? util.fedopsLogger.INTERACTIONS.PASTE_PAGE_CROSS_SITE
    : util.fedopsLogger.INTERACTIONS.PASTE_PAGE_SAME_SITE;
  util.fedopsLogger.interactionStarted(FEDOPS_INTERACTION);
}
function reportPastePageEnded(isCrossSite) {
  const FEDOPS_INTERACTION = isCrossSite
    ? util.fedopsLogger.INTERACTIONS.PASTE_PAGE_CROSS_SITE
    : util.fedopsLogger.INTERACTIONS.PASTE_PAGE_SAME_SITE;
  util.fedopsLogger.interactionEnded(FEDOPS_INTERACTION);
}

function onCopy(editorAPI) {
  const menuItem = editorAPI.mainMenu.panelContext.getSelectedMenuItem();
  if (
    !menuItem ||
    !menuItem.type.isPage ||
    !editorAPI.copyPaste.canCopyPage(menuItem.pageData.id)
  ) {
    return;
  }

  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.COPY_PAGE,
  );
  editorAPI.copyPaste.copyPage(menuItem.pageData.id, { origin: 'keyboard' });
  util.fedopsLogger.interactionEnded(util.fedopsLogger.INTERACTIONS.COPY_PAGE);
}

function onPaste(editorAPI, shouldApplyTargetTheme) {
  if (!editorAPI.copyPaste.hasPageToPaste()) {
    return;
  }

  reportPastePageStarted(editorAPI.clipboard.isCrossSite());
  editorAPI.copyPaste
    .pastePage({
      menuItemToPasteAfter:
        editorAPI.mainMenu.panelContext.getSelectedMenuItem(),
      shouldApplyTargetTheme,
      origin: 'keyboard_pages_menu',
    })
    .then(() => {
      reportPastePageEnded(editorAPI.clipboard.isCrossSite());
    });
}

function init(editorAPI) {
  const keyboardMap = keyboardShortcutsActionMap.editor;

  shortcuts[keyboardMap.COPY.key] = () => onCopy(editorAPI);
  shortcuts[keyboardMap.PASTE.key] = () => onPaste(editorAPI, false);
  shortcuts[keyboardMap.PASTE_AND_APPLY_THEME.key] = () =>
    onPaste(editorAPI, true);
}

export { init, shortcuts };
