import { selectFormsCollections } from '../../collections';
import type { MapStateToProps } from 'types/redux';

import type { Schema } from '@wix/wix-data-schema-types';

export interface WixFormsCollectionsListStateProps {
  collections: Schema[];
}

export const mapStateToProps: MapStateToProps<
  WixFormsCollectionsListStateProps
> = ({ state }) => ({
  collections: selectFormsCollections(state),
});
