import _ from 'lodash';
import experiment from 'experiment';
import * as stateManagement from '@/stateManagement';
import { constants } from '@/tpa';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef, AppData } from 'types/documentServices';

const MAX_SETTINGS_WIDTH = 426;
const NUM_OF_PIXELS_TO_REMOVE_FROM_WIDTH = {
  en: 22,
  da: 18,
  sv: 18,
  es: 18,
  tr: 10,
  pl: 10,
  no: 10,
  it: 10,
  fr: 10,
  de: 10,
  ru: 10,
  ja: 0,
  pt: 0,
  nl: 0,
  ko: 0,
};

function getTranslatedLanguages(widgetData: AnyFixMe) {
  return _.has(widgetData, 'settings.languages')
    ? widgetData.settings.languages
    : [];
}

function isTranslated(language: AnyFixMe, translatedLanguages: AnyFixMe) {
  return translatedLanguages.includes(language);
}

function getMenuWidthByLanguage(language: AnyFixMe) {
  return (
    MAX_SETTINGS_WIDTH - _.get(NUM_OF_PIXELS_TO_REMOVE_FROM_WIDTH, language, 0)
  );
}

function getSettingsMenuWidth(language: AnyFixMe, widgetData: AnyFixMe) {
  const translatedLanguages = getTranslatedLanguages(widgetData);
  return getMenuWidthByLanguage(
    isTranslated(language, translatedLanguages) ? language : 'en',
  );
}

function openSettingsAfterFetchingMarketData(
  appData: AnyFixMe,
  marketData: AnyFixMe,
  editorAPI: AnyFixMe,
  compPointer: AnyFixMe,
  widgetId: AnyFixMe,
  props: AnyFixMe,
) {
  const appMarketWidgetData = marketData.widgets?.find(
    (widget: AnyFixMe) => widget.widgetId === widgetId,
  );
  const isNewVersion = isNewSettingsVersion(appData, widgetId);
  const settingsWidth = isNewVersion
    ? getSettingsMenuWidth(
        editorAPI.dsRead.generalInfo.getLanguage(),
        appMarketWidgetData,
      )
    : appData.settingsWidth;
  openSettingsPanel(editorAPI, compPointer, {
    ...props,
    width: settingsWidth,
    isNewVersion,
  });
}

const shouldShowNewSettingsAccordingToExperiment = function (
  widgetData: AnyFixMe,
  widgetId: AnyFixMe,
) {
  if (widgetData?.abTest) {
    return experiment.isOpen(`se_${widgetId.replace(/-/g, '_')}`);
  }
  return true;
};

const isNewSettingsVersion = function (appData: AnyFixMe, widgetId: AnyFixMe) {
  const widgetData = appData?.widgets?.[widgetId]?.settings;
  const isNewWidgetSettingsVersion = widgetData?.version !== 1;
  const isUrlV2Exist = widgetData?.urlV2 ?? false;

  return (
    isNewWidgetSettingsVersion &&
    isUrlV2Exist &&
    shouldShowNewSettingsAccordingToExperiment(widgetData, widgetId)
  );
};

const notifyAppOnSettingsClose = (editorAPI: EditorAPI, appData: AppData) => {
  editorAPI.dsActions.tpa.change.trigger.editorEvent({
    tpaType: appData.appDefinitionName,
    type: constants.TPA_ON_SETTINGS_CLOSE_EVENT,
  });
};

function open(
  editorAPI: EditorAPI,
  compPointer?: CompRef | CompRef[],
  props: any = {},
) {
  compPointer = compPointer || editorAPI.selection.getSelectedComponents();
  const compData = editorAPI.components.data.get(compPointer);
  const appData = editorAPI.dsRead.tpa.app.getData(compData.applicationId);
  const { appDefinitionId } = appData;
  if (appDefinitionId) {
    const { onClose } = props;
    props = {
      ...props,
      onClose: () => {
        if (onClose) {
          onClose();
        }
        notifyAppOnSettingsClose(editorAPI, appData);
      },
    };
    editorAPI.dsRead.tpa.appMarket
      .getDataAsync(appDefinitionId, {
        verticalsPremium: true,
      })
      .then(function (marketData) {
        openSettingsAfterFetchingMarketData(
          appData,
          marketData,
          editorAPI,
          compPointer,
          compData.widgetId,
          props,
        );
      })
      .catch((e) => {
        console.error(e);
      });
  } else {
    openSettingsPanel(editorAPI, compPointer, props);
  }
}

const openSettingsPanel = function (
  editorAPI: AnyFixMe,
  compPointer: AnyFixMe,
  props: AnyFixMe,
) {
  const state = editorAPI.store.getState();
  const settingsPanelId = 'tpa.compPanels.tpaSettings';
  const openedSettingsPanel = getOpenedPanel(
    settingsPanelId,
    editorAPI.panelManager,
  );
  const settingsPanelExtraProps =
    stateManagement.tpa.selectors.getTpaSettingsPanelExtraProps(state);

  if (!openedSettingsPanel) {
    const selectedComponent = Array.isArray(compPointer)
      ? _.head(compPointer)
      : compPointer;
    const selectedComponentId = selectedComponent?.id;
    const panelProps = _.merge(
      {
        selectedComponent: compPointer,
        origCompId: selectedComponentId,
      },
      props,
      settingsPanelExtraProps,
    );
    editorAPI.panelManager.openPanel(settingsPanelId, panelProps);
  }
};

const getOpenedPanel = function (panelName: AnyFixMe, panelManager: AnyFixMe) {
  const openPanels = panelManager.getOpenPanels();
  return openPanels.find((panel: AnyFixMe) => panel.name === panelName);
};

export { open };
