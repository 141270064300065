// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import * as coreBi from '@/coreBi';
import * as baseUI from '@/baseUI';
import * as stateManagement from '@/stateManagement';
import * as platformEvents from 'platformEvents';
import constants from '@/constants';

const componentsSelectors = stateManagement.components.selectors;
const { findCurrentSlide } = stateManagement.boxSlideShow.selectors;
const ANIMATIONS_NOT_SUITABLE_FOR_OVERFLOW_HIDDEN = [
  'SlideVertical',
  'SlideHorizontal',
];

const notifyStateChange = (
  editorAPI,
  { widgetRef, stateBoxRef, stateIndex },
) => {
  const { applicationId: appDefinitionId } =
    editorAPI.components.data.get(widgetRef);
  const { applicationId } =
    editorAPI.platform.getAppDataByAppDefId(appDefinitionId);
  const selectedStateRef =
    editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(stateBoxRef)[
      stateIndex
    ];

  editorAPI.platform.notifyApplication(
    applicationId,
    platformEvents.factory.stateChanged({
      componentRef: stateBoxRef,
      selectedStateRef,
    }),
  );
};

function getSlideShowParent(editorAPI, compRef) {
  return editorAPI.components.findAncestor(
    compRef,
    editorAPI.components.is.slidesContainer,
    { includeScopeOwner: true },
  );
}

function getSlideShowSlideParent(editorAPI, compRef) {
  return editorAPI.components.findAncestor(
    compRef,
    editorAPI.components.is.slideContainer,
    { includeScopeOwner: true },
  );
}

function moveNextSlide(editorAPI, compRef) {
  preNavigationActions(editorAPI);
  const currentSlide = findCurrentSlide(editorAPI.dsRead, compRef);
  editorAPI.components.behaviors.execute(
    compRef,
    'nextSlide',
    null,
    function () {
      editorAPI.allowMouseDown();
      onSlideChange(editorAPI, compRef, currentSlide);
    },
  );
}

function closePanelIfNeeded(editorAPI) {
  if (
    editorAPI.panelManager.isPanelOpened(
      'compPanels.dynamicPanels.mobileBackgroundSettingsPanel',
    )
  ) {
    editorAPI.closeCompPanelIfExists();
  }
}

function preNavigationActions(editorAPI) {
  hideQuickTip(editorAPI);
  editorAPI.preventMouseDown();
  closePanelIfNeeded(editorAPI);
}

function changeState(editorAPI, compRef, stateIndex, supressSelection = false) {
  const { dsRead } = editorAPI;

  preNavigationActions(editorAPI);
  const currentState = findCurrentSlide(editorAPI.dsRead, compRef);
  const parentCompRef =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
  const parentCompType = componentsSelectors.getCompTypeSuffix(
    parentCompRef,
    dsRead,
  );
  const isChildOfAppWidget = parentCompType === 'AppWidget';
  editorAPI.components.behaviors.execute(
    compRef,
    'changeState',
    { stateIndex },
    function () {
      editorAPI.allowMouseDown();
      if (isChildOfAppWidget) {
        notifyStateChange(editorAPI, {
          widgetRef: parentCompRef,
          stateBoxRef: compRef,
          stateIndex,
        });
      }
      onSlideChange(editorAPI, compRef, currentState, supressSelection);
    },
  );
  const type = componentsSelectors.getCompTypeSuffix(compRef, dsRead);

  if (type === 'StateBox' && isChildOfAppWidget && !supressSelection) {
    editorAPI.selection.selectComponentByCompRef(parentCompRef);
  }
}

function movePrevSlide(editorAPI, compRef) {
  preNavigationActions(editorAPI);
  const currentSlide = findCurrentSlide(editorAPI.dsRead, compRef);
  editorAPI.components.behaviors.execute(
    compRef,
    'prevSlide',
    null,
    function () {
      editorAPI.allowMouseDown();
      onSlideChange(editorAPI, compRef, currentSlide);
    },
  );
}

function onSlideChange(editorAPI, compRef, slide, supressSelection = false) {
  if (!supressSelection) {
    editorAPI.selection.selectComponentByCompRef(compRef);
  }
  const newSlide = findCurrentSlide(editorAPI.dsRead, compRef);
  if (!_.isEqual(slide, newSlide)) {
    editorAPI.bi.event(
      coreBi.events.boxSlideShow.SLIDES_NAVIGATION,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(
        { origin: 'sliderControl' },
        editorAPI.components.getDefaultBiParams(compRef),
      ),
    );
    identifyComponentsOutOfSlideshow(editorAPI, compRef);
  }
}

function identifyComponentsOutOfSlideshow(editorAPI, compRef) {
  const slideProps = editorAPI.components.properties.get(compRef);
  if (slideProps.shouldHideOverflowContent) {
    const slideshowLayout =
      editorAPI.components.layout.getRelativeToScreen(compRef);
    const currentSlide = findCurrentSlide(editorAPI, compRef);
    const childComps =
      editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(
        currentSlide,
        true,
      );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(childComps, function (comp) {
      const compLayout = editorAPI.components.layout.getRelativeToScreen(comp);
      if (
        compLayout.y + compLayout.height < slideshowLayout.y ||
        compLayout.y > slideshowLayout.y + slideshowLayout.height ||
        compLayout.x + compLayout.width < slideshowLayout.x ||
        compLayout.x > slideshowLayout.x + slideshowLayout.width
      ) {
        editorAPI.components.properties.update(compRef, {
          shouldHideOverflowContent: false,
        });
        if (
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          _.includes(
            ANIMATIONS_NOT_SUITABLE_FOR_OVERFLOW_HIDDEN,
            slideProps.transition,
          )
        ) {
          editorAPI.components.properties.update(compRef, {
            transition: 'NoTransition',
          });
        }
        editorAPI.bi.event(
          coreBi.events.boxSlideShow.SLIDESHOW_HIDDEN_ELEMENTS_TOOLTIP_APPEAR,
        );
        showCompsOutOfSlideshowTooltip(editorAPI, compRef);
        return false;
      }
    });
  }
}

function hideQuickTip(editorAPI) {
  editorAPI.toolsControl.toggleHalfOpacityTools(false);
  editorAPI.hideFloatingBubble();
}

function openSlideshowSettings(editorAPI, compRef) {
  editorAPI.selection.selectComponentByCompRef(compRef);
  hideQuickTip(editorAPI);
  const compType = editorAPI.components.getType(compRef);
  const panelProps = {
    selectedComponent: compRef,
    compType,
  };

  editorAPI.panelManager.openComponentPanel(
    'compPanels.panels.BoxSlideShow.settingsPanel',
    panelProps,
  );
}
function showCompsOutOfSlideshowTooltip(editorAPI, compRef) {
  const slideshowLayout =
    editorAPI.components.layout.getRelativeToScreenConsideringScroll(compRef);
  const innerTemplate = React.createElement(baseUI.popoverTemplates.quickTip, {
    text: 'ONSTAGE_SLIDESHOW_RESIZE_TOOLTIP',
    linkActionText: 'ONSTAGE_SLIDESHOW_RESIZE_TOOLTIP_LINK',
    linkAction() {
      editorAPI.bi.event(
        coreBi.events.boxSlideShow
          .SLIDESHOW_HIDDEN_ELEMENTS_TOOLTIP_ACTION_CLICKED,
        { action: 'settings' },
      );
      openSlideshowSettings(editorAPI, compRef);
    },
    symbol: 'idea-bulb',
    closeAction() {
      editorAPI.bi.event(
        coreBi.events.boxSlideShow
          .SLIDESHOW_HIDDEN_ELEMENTS_TOOLTIP_ACTION_CLICKED,
        { action: 'x' },
      );
      hideQuickTip(editorAPI);
    },
    onOuterClick(e) {
      const componentUnderClick = _.head(
        editorAPI.selection.getComponentsUnderClick(e),
      );
      const currentSlide = findCurrentSlide(editorAPI.dsRead, compRef);
      const childComps =
        editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(
          currentSlide,
          true,
        );

      if (
        !componentUnderClick ||
        (!_.isEqual(componentUnderClick, compRef) &&
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/some
          !_.some(childComps, componentUnderClick))
      ) {
        hideQuickTip(editorAPI);
      }
    },
  });
  const targetLayout = {
    height: 1,
    width: 1,
    top:
      slideshowLayout.y < 0
        ? editorAPI.editorConfig.topBarHeight + 10
        : slideshowLayout.y + editorAPI.editorConfig.topBarHeight + 20,
    left: slideshowLayout.x + slideshowLayout.width / 2,
  };
  const propsForBubble = {
    noArrow: true,
    shake: false,
    behindPopUps: true,
    hideMethod: editorAPI.toolsControl.toggleHalfOpacityTools.bind(this, false),
    alignment: 'bottom',
  };
  const shouldNotHideOnMouseLeaveTarget = true;

  editorAPI.showFloatingBubble(
    innerTemplate,
    targetLayout,
    propsForBubble,
    shouldNotHideOnMouseLeaveTarget,
  );
}

function mobileSwitchCallback(editorAPI) {
  const currentPage = editorAPI.pages.getCurrentPage();
  const stateBoxes = editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
    constants.COMP_TYPES.STATE_BOX,
    currentPage,
  );

  const stateBoxesCurrentSlides = stateBoxes
    .map((stateBox) => {
      const currentSlideIndex =
        stateManagement.boxSlideShow.selectors.getCurrentSlideIndex(
          editorAPI.dsRead,
          stateBox,
        );
      return {
        stateBox,
        currentSlideIndex,
      };
    })
    .filter(({ currentSlideIndex }) => currentSlideIndex !== 0);

  editorAPI.dsActions.waitForChangesApplied(() => {
    stateBoxesCurrentSlides.forEach(({ stateBox, currentSlideIndex }) => {
      changeState(editorAPI, stateBox, currentSlideIndex, true);
    });
  });
}

export {
  getSlideShowParent,
  getSlideShowSlideParent,
  moveNextSlide,
  movePrevSlide,
  changeState,
  preNavigationActions,
  identifyComponentsOutOfSlideshow,
  showCompsOutOfSlideshowTooltip,
  hideQuickTip,
  mobileSwitchCallback,
};
