import { CeType } from '@/presetApi';
import type { PossibleViewModes } from 'types/documentServices';

export const PAGE_SECTIONS_EDITOR_DATA_NAMESPACE = 'pageSections'; // TODO: move to sectionsUtils

export const BEFORE_SECTIONS_MIGRATION_HISTORY_LABEL =
  'BEFORE_SECTIONS_MIGRATION';

export const enum DEBUG {
  LOG = 'log',
  WARN = 'warn',
  TIME = 'time',
  TIME_END = 'timeEnd',
}

export const enum MigrationOrigin {
  Init = 'init',
  Shadow = 'shadow',
  Navigation = 'navigation',
  TPAInstall = 'tpa_install',
}

export const enum ADIMigrationTrigger {
  OnLoad = 'onload',
  OnDemand = 'ondemand',
  Reminder = 'reminder',
}

export const VIEW_MODES = ['DESKTOP', 'MOBILE'] as PossibleViewModes[];

export const DEFAULT_CONTENT_ROLE = CeType.About;

export const MAX_PAGES_TO_MIGRATE = 50;

export const MIGRATION_TIME_LIMIT = 120000; // 2 minutes

export const MIGRATION_SKIP_LIMIT = 2;
