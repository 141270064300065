const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_31",
  preset: { rect: { width: 107, height: 72, x: 135, y: 891 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        properties: {
          "$st-css":
            ":import{\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: StylableButton\n}\n.root{\n    -st-extends: StylableButton;\n    transition: all 0.2s ease, visibility 0s;\n    background: #5F5BCD;\n    border-radius: 7px;\n    border: 0px solid rgb(71, 67, 197)\n}\n.root:hover {\n    background: #4743C5;\n}\n.root:disabled {\n    background: #DBDBDB;\n}\n.root[disabled]{\n    background: #E2E2E2\n}\n.root[disabled]::label{\n    color: #8F8F8F\n}\n.root[disabled]::icon{\n    fill: #8F8F8F\n}\n.root::container{\n    transition: inherit\n}\n.root::label{\n    transition: inherit;\n    display: initial;\n    color: #FFFFFF;\n    font-weight: 700;\n    font-family: poppins-semibold,poppins,sans-serif;\n    font-size: 20px;\n    letter-spacing: 0.05em\n}\n.root::icon{\n    transition: inherit;\n    fill: #FFFFFF;\n    width: 23px;\n    height: 23px;\n    display: none\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "4ie-539",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "5vr-2073",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_go"),
      svgId: "11062b_a9f863f00acf4ed6a7113f0eb05f6884.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-kmdhsw83",
    layout: {
      x: 147,
      fixedPosition: false,
      y: 900,
      scale: 1,
      height: 43,
      rotationInDegrees: 0,
      width: 77,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "hmj-1406", pageId: "c1dmp" },
    },
    metaData: { sig: "inq-347", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
