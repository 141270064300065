:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._addSectionButton_rgh23_7 {
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important; }
  ._addSectionButton_rgh23_7.btn-md {
    padding: 3px 18px 3px 12px; }
  ._addSectionButton_rgh23_7._sharpBorderTop_rgh23_12 {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  ._addSectionButton_rgh23_7 ._icon_rgh23_15 * {
    fill: var(--icon-color); }

._legacy_rgh23_18 {
  --bg-color: #6f56f9;
  --text-color: #fff;
  --icon-color: #ffffff; }
  ._legacy_rgh23_18:hover {
    --bg-color: #4429da; }

._primary_rgh23_25 {
  border-radius: 4px !important;
  border: 1px solid #ffffff;
  --bg-color: #6f56f9;
  --text-color: #ffffff;
  --icon-color: #ffffff; }
  ._primary_rgh23_25:hover {
    --bg-color: #4b31dc; }

._secondary_rgh23_34 {
  border-radius: 4px !important;
  border: 1px solid #6f56f9;
  --bg-color: #ffffff;
  --text-color: #6f56f9;
  --icon-color: #6f56f9; }
  ._secondary_rgh23_34:hover {
    --bg-color: #d9d2ff; }
