// @ts-nocheck
import * as util from '@/util';
import * as blogPagesPanelMapper from './blogPagesPanelMapper';
import React from 'react';
import _ from 'lodash';
import { CustomScroll } from '@wix/wix-base-ui';
import * as pagesMenu from '@/pagesMenu';
import * as superAppMenuInfra from '@/superAppMenuInfra';

import { newBlogAppDefId } from '../../utils/blogUtils';

class BlogPagesPanel extends React.Component {
  static displayName = 'BlogPagesPanel';

  handleItemClick = (pageId: string) => {
    this.props.selectPage(pageId);

    const biParams = {
      app_id: newBlogAppDefId,
      tab_name: 'Pages',
      action_id: 'selectPage',
      action_type: superAppMenuInfra.BIAppActionType.General,
    };

    this.props.sendBi(
      superAppMenuInfra.superAppEvents.CLICK_ON_APP_ACTION,
      biParams,
    );
  };

  render() {
    return (
      <CustomScroll heightRelativeToParent="100%">
        <div className="page-list-container">
          <ul className="page-list">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
            {_.map(this.props.pages, (page) => {
              const pageId = page.pageData.id;

              return (
                <li key={pageId}>
                  <pagesMenu.pageItem
                    label={page.label}
                    isVisible={true}
                    onClick={() => this.handleItemClick(pageId)}
                    isSelected={this.props.currentPageId === pageId}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </CustomScroll>
    );
  }
}

const BlogPagesPanelConnected = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  blogPagesPanelMapper.mapStateToProps,
  blogPagesPanelMapper.mapDispatchToProps,
)(BlogPagesPanel);

BlogPagesPanelConnected.pure = BlogPagesPanel;

export default BlogPagesPanelConnected;
