// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as core from '@/core';
import * as coreBi from '@/coreBi';
import createReactClass from 'create-react-class';
import React from 'react';
import { ToolPanelFrame } from '../../frames';
import {
  ActionWithSymbol,
  Composites,
  Divider,
  RichText,
  TextLabel,
  Tooltip,
} from '@wix/wix-base-ui';
import * as baseUI from '@/baseUI';

function getCustomSignupLinkFromId(editorAPI, customSignupPageId) {
  const link = editorAPI.data.createItem('PageLink');
  if (customSignupPageId) {
    link.pageId = `#${customSignupPageId}`;
  }
  return link;
}

function sendBiEvent(editorAPI) {
  const { pages } = coreBi.events.topbar;
  const { event } = editorAPI.bi;

  return {
    clickOnSaveSettings: () => event(pages.custom_signup_panel_save),
    clickOnSaveLinkPanel: () =>
      event(pages.custom_signup_panel_link_panel_save),
    clickOnCancelLinkPanel: () =>
      event(pages.custom_signup_panel_link_panel_cancel),
    clickOnLinkButton: () => event(pages.custom_signup_panel_link_panel_open),
  };
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'customSignupSettingsPanel',

  mixins: [core.mixins.editorAPIMixin, ReactLinkedStateMixin], //eslint-disable-line react/no-deprecated

  getInitialState() {
    const editorAPI = this.getEditorAPI();
    const customSignupPageId = editorAPI.siteMembers.getCustomSignupPageId();

    return {
      customSignupPageId,
      customSignupPageLink: null,
      isCustomSignupEnabled: !!customSignupPageId,
    };
  },

  toggleCustomSignupEnabled() {
    this.setState({ isCustomSignupEnabled: !this.state.isCustomSignupEnabled });
  },

  getCustomSignupPageLabel() {
    const editorAPI = this.getEditorAPI();
    const { customSignupPageId, customSignupPageLink } = this.state;

    if (!customSignupPageId || !editorAPI.data.getById(customSignupPageId)) {
      return translate(
        'Pages_Custom_Signup_Settings_Custom_Signup_Link_Action_Placeholder',
      );
    } else if (customSignupPageLink) {
      return util.link.getLinkValueAsString(editorAPI, customSignupPageLink);
    }

    const pageData = editorAPI.data.getById(customSignupPageId);
    return pageData?.title ?? '';
  },

  openLinkPanel() {
    const editorAPI = this.getEditorAPI();
    const { customSignupPageId } = this.state;

    sendBiEvent(editorAPI).clickOnLinkButton();

    editorAPI.openLinkPanel({
      visibleSections: {
        PopupLink: true,
        PageLink: false,
        AnchorLink: false,
        NoLink: false,
        PhoneLink: false,
        WhatsAppLink: false,
        ExternalLink: false,
        EmailLink: false,
        DocumentLink: false,
        LoginToWixLink: false,
        EdgeAnchorLinks: false,
      },
      defaultLinkType: 'PopupLink',
      initialTabToDisplay: 'PopupLink',
      link: getCustomSignupLinkFromId(editorAPI, customSignupPageId),
      showAllPopups: true,
      hidePageLinks: true,
      pageTabTitle: 'LINK_PANEL_TAB_ANCHOR',
      pageTabPageDropDownTitle: 'PLATFORM_LINK_MENU_PAGE_PAGE_OPTION',
      anchorTabPageDropDownTitle: 'LINK_PANEL_PAGE_DROP_DOWN_PAGELINK_LABEL',
      title: 'Pages_Custom_Signup_Settings_Link_Panel_Header',
      onCancelCallback: () => sendBiEvent(editorAPI).clickOnCancelLinkPanel(),
      callback: this.updateCustomSignupState,
      hideLinkTarget: true,
    });
  },

  updateCustomSignupState(customSignupPageLink) {
    const customSignupPageId = customSignupPageLink
      ? customSignupPageLink?.pageId.replace('#', '')
      : null;
    this.setState({ customSignupPageLink, customSignupPageId });
    sendBiEvent(this.getEditorAPI()).clickOnSaveLinkPanel();
  },

  applySettings() {
    const smAPI = this.getEditorAPI().siteMembers;
    const { customSignupPageId, isCustomSignupEnabled } = this.state;

    if (smAPI.getCustomSignupPageId() !== customSignupPageId) {
      smAPI.setCustomSignupPageId(customSignupPageId);
    }

    if (!isCustomSignupEnabled) {
      smAPI.setCustomSignupPageId(null);
    }

    sendBiEvent(this.getEditorAPI()).clickOnSaveSettings();
    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },

  isSaveEnabled() {
    const smAPI = this.getEditorAPI().siteMembers;
    const { customSignupPageId, isCustomSignupEnabled } = this.state;
    return (
      smAPI.getCustomSignupPageId() !== customSignupPageId ||
      (customSignupPageId && !isCustomSignupEnabled)
    );
  },

  render() {
    return (
      <ToolPanelFrame
        panelName={this.props.panelName}
        helpId={this.props.helpId}
        headerTitle={translate('Pages_Custom_Signup_Settings_Header_Title')}
        contentClass="custom-signup-settings-panel"
        contentWrapperClass="custom-signup-settings-panel-wrapper"
      >
        <Composites.RichText>
          <RichText className="light">
            {translate(
              'pages_Custom_Signup_Settings_Custom_Signup_Description_Label',
            )}
          </RichText>
        </Composites.RichText>

        <Divider />

        <div className="tab-content">
          <div className="settings">
            <div className="options">
              <section className="option">
                <baseUI.toggle
                  name="switch"
                  value={this.state.isCustomSignupEnabled}
                  onChange={this.toggleCustomSignupEnabled}
                  label="Pages_Custom_Signup_Settings_Custom_Signup_Enabled_Label"
                />
              </section>
            </div>

            <Divider />

            {this.state.isCustomSignupEnabled ? (
              <section key="customSignupLink" className="option">
                <Composites.ActionWithSymbolLabeled>
                  <TextLabel
                    value="Pages_Custom_Signup_Settings_Custom_Signup_Link_Action_Label"
                    shouldTranslate={true}
                  />
                  <ActionWithSymbol action={this.openLinkPanel}>
                    <TextLabel
                      value={this.getCustomSignupPageLabel()}
                      shouldTranslate={false}
                    />
                  </ActionWithSymbol>
                </Composites.ActionWithSymbolLabeled>
              </section>
            ) : null}
          </div>
        </div>

        <Divider long={true} />

        <footer className="actions">
          <Tooltip
            disabled={this.isSaveEnabled()}
            content="Pages_Custom_Signup_Settings_Custom_Signup_Disabled_Save_Button_Tooltip"
            shouldTranslate={true}
            arrowAlignment="top"
            key="saveButtonTooltip"
          >
            <baseUI.button
              label="Pages_Custom_Signup_Settings_Custom_Signup_Save_Button_Label"
              onClick={this.applySettings}
              disabled={!this.isSaveEnabled()}
              className="btn-confirm-primary btn-md"
            />
          </Tooltip>
        </footer>
      </ToolPanelFrame>
    );
  },
});
