// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import constants from '@/constants';
import {
  Slider,
  Divider,
  InfoIcon,
  TextLabel,
  Composites,
  TextFormatting,
} from '@wix/wix-base-ui';
import * as core from '@/core';
import { utils as themeUtils } from '@/theme';
import * as panelUtils from '@/panelUtils';
import * as textControls from '@/textControls';
import {
  interactionSettingsHOC,
  SupportedCSSProps,
} from './interactionSettingsHOC';
import ReactDOM from 'react-dom';

// eslint-disable-next-line react/prefer-es6-class
export const PureTextStylingSection = createReactClass({
  displayName: 'textStylingSection',
  mixins: [core.mixins.editorAPIMixin, panelUtils.linkColorPickerMixin],

  componentDidMount() {
    this.originStyleDef = this.props.styleDef;
  },

  componentWillUnmount() {
    if (!_.isEqual(this.originStyleDef, this.props.styleDef)) {
      this.props.sendBeforePanelClosedBi();
    }
  },

  getPosition() {
    const node = ReactDOM.findDOMNode(this);
    return node instanceof Element ? node.getBoundingClientRect() : null;
  },

  getPanelPosition() {
    const panelPosition = this.getPosition();
    return {
      top: `${panelPosition.top + 72}px`,
      left: `${panelPosition.left - 12}px`,
    };
  },

  openColorPickerPanel(
    colorType: SupportedCSSProps.Color | SupportedCSSProps.BackgroundColor,
    callback,
  ) {
    const position = this.getPanelPosition();
    const colorFromStyles = this.props[colorType];
    const hexColor = themeUtils.getColorInHex(colorFromStyles);
    const colorFromTheme = _.findKey(
      this.props.themeColors,
      (colorOption) => colorOption === hexColor,
    );
    const colorValue = colorFromTheme ? colorFromTheme : hexColor;

    this.openColorPicker(
      (newColor) => {
        callback(newColor === 'noColor' ? undefined : newColor);
      },
      colorValue,
      true,
      position,
      false,
      false,
    );
  },

  getColorValue(
    colorType: SupportedCSSProps.Color | SupportedCSSProps.BackgroundColor,
  ) {
    const color = this.props[colorType];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/starts-with
    if (_.startsWith(color, 'color')) {
      return this.props.themeColors[color];
    }
    return color;
  },

  render() {
    const smallDesign = constants.UI.TOOLTIP.STYLE_TYPE.SMALL;

    return (
      <div
        className={`more-styling-options${
          this.props.skinName
            ? ` text-styling-section-${this.props.skinName}`
            : ``
        }`}
      >
        <div className="button-row">
          <TextFormatting.bold
            value={this.props.isBold}
            onChange={this.props.boldChanged}
            tooltipContent={textControls.getTextTooltip('bold', 'B')}
          />
          <TextFormatting.italic
            value={this.props.isItalic}
            onChange={this.props.italicChanged}
            tooltipContent={textControls.getTextTooltip('italic', 'I')}
          />
          <TextFormatting.underline
            value={this.props.isUnderline}
            onChange={this.props.underlineChanged}
            tooltipContent={textControls.getTextTooltip('underline', 'U')}
          />
          <TextFormatting.color
            fill={this.props.colorValue}
            onChange={() => {
              this.openColorPickerPanel(SupportedCSSProps.Color, (newColor) => {
                this.props.colorChanged(newColor);
              });
            }}
            tooltipContent={textControls.getTextTooltip('text_color')}
          />
          <textControls.textBackgroundColor
            color={this.getColorValue(SupportedCSSProps.BackgroundColor)}
            onClick={() => {
              this.openColorPickerPanel(
                SupportedCSSProps.BackgroundColor,
                (newColor) => {
                  this.props.backgroundColorChanged(newColor);
                },
              );
            }}
            handleNoColor={() => this.props.backgroundColorChanged(undefined)}
            tooltipValue={textControls.getTextTooltip('text_highlight')}
            tooltipStyleType={smallDesign}
          />
        </div>

        <Divider long={true} />

        <Composites.SliderLabeled>
          <TextLabel value="text_editor_character_spacing_label" />

          <InfoIcon text="text_editor_character_spacing_tooltip" />

          <Slider
            className="thumbnail-slider"
            value={this.props.letterSpacing}
            onChange={this.props.letterSpacingChanged}
            onSlideEnd={this.focus}
            min={textControls.constants.MIN_VALUE_SLIDER_LETTER_SPACING}
            max={textControls.constants.MAX_VALUE_SLIDER_LETTER_SPACING}
            inputMin={textControls.constants.MIN_VALUE_INPUT_LETTER_SPACING}
            inputMax={textControls.constants.MAX_VALUE_INPUT_LETTER_SPACING}
            step={textControls.constants.DEFAULT_STEP_SLIDER}
            inputStep={textControls.constants.DEFAULT_STEP_INPUT_LETTER_SPACING}
          />
        </Composites.SliderLabeled>

        <Divider long={true} />

        <textControls.lineHeight
          infoText="text_editor_line_spacing_tooltip"
          value={this.props.lineHeight ? this.props.lineHeight : ''}
          onChange={this.props.lineHeightChanged}
          onSlideEnd={this.focus}
          renderButtonsOptions={this.props.renderButtonsOptions}
          max={this.props.lineHeightOptions?.max}
          min={this.props.lineHeightOptions?.min}
          step={this.props.lineHeightOptions?.step}
          inputStep={this.props.lineHeightOptions?.inputStep}
          inputMin={this.props.lineHeightOptions?.inputMin}
          inputMax={this.props.lineHeightOptions?.inputMax}
        />
      </div>
    );
  },
});
export default interactionSettingsHOC(PureTextStylingSection);
