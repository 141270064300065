import React, { useMemo } from 'react';
import styles from './htmlThumbnailDragBox.scss';
import { HtmlPreviewThumbnail } from './htmlPreviewThumbnail';
import HtmlPreviewThumbnailManager from './htmlPreviewThumbnailManager';
import { RawHtmlPreviewThumbnail } from '../addSection/components/RawHtmlPreviewThumbnail';

export interface HtmlThumbnailDragBoxProps {
  previewHtml: string;
  boxSize: {
    width?: number;
    height?: number;
  };
  coords: {
    left?: number;
    top?: number;
  };
  boxOffset: {
    left?: number;
    top?: number;
  };
  originalPresetWidth?: number;
  containerWidth: number;
  displayRawPreview: boolean;
}

const HtmlThumbnailDragBox: React.FC<HtmlThumbnailDragBoxProps> = ({
  coords,
  boxSize,
  boxOffset,
  previewHtml,
  originalPresetWidth,
  displayRawPreview,
  containerWidth,
}: HtmlThumbnailDragBoxProps) => {
  const htmlPreviewThumbnailManager = useMemo<HtmlPreviewThumbnailManager>(
    () => new HtmlPreviewThumbnailManager(previewHtml),
    [previewHtml],
  );
  const transform = `translate(${boxOffset.left * 100}%, ${
    boxOffset.top * 100
  }%)`;
  return (
    <div
      className={styles.htmlThumbnailDragBox}
      style={{
        ...boxSize,
        top: coords.top,
        WebkitTransform: transform,
        transform,
        left: coords.left,
      }}
    >
      {displayRawPreview ? (
        <RawHtmlPreviewThumbnail
          originalPresetWidth={originalPresetWidth}
          displayWidth={containerWidth}
          previewHtmlManager={htmlPreviewThumbnailManager}
        />
      ) : (
        <HtmlPreviewThumbnail
          shouldOptimizeImages={true}
          containerWidth={containerWidth}
          fontsUrls={[]}
          previewHtmlManager={htmlPreviewThumbnailManager}
          originalPresetWidth={originalPresetWidth}
        />
      )}
    </div>
  );
};

export default HtmlThumbnailDragBox;
