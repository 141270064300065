import type { ComponentSwitchTypeItem } from './types';
export enum ComponentCategory {
  Image = 'image',
  Video = 'video',
  Title = 'title',
  Subtitle = 'subtitle',
  Paragraph = 'paragraph',
  Button = 'button',
  Container = 'container',
  Unknown = 'unknown',
  Unsupported = 'unsupported',
}

export class ParsedLayout {
  flatComponents: ComponentSwitchTypeItem[];
  componentsCategories: { [type: string]: number };
  switchItemsByType: {
    [switchType in ComponentCategory]: ComponentSwitchTypeItem[];
  };

  constructor() {
    this.flatComponents = [];
    // init with 0 so it won't be ignored
    this.componentsCategories = {
      [ComponentCategory.Button]: 0,
      [ComponentCategory.Image]: 0,
      [ComponentCategory.Title]: 0,
      [ComponentCategory.Subtitle]: 0,
      [ComponentCategory.Paragraph]: 0,
    };
    this.switchItemsByType = {
      [ComponentCategory.Button]: [],
      [ComponentCategory.Container]: [],
      [ComponentCategory.Image]: [],
      [ComponentCategory.Video]: [],
      [ComponentCategory.Title]: [],
      [ComponentCategory.Subtitle]: [],
      [ComponentCategory.Paragraph]: [],
      [ComponentCategory.Unknown]: [],
      [ComponentCategory.Unsupported]: [],
    };
  }

  AddComponent(
    component: ComponentSwitchTypeItem,
    switchType: ComponentCategory,
  ): void {
    if (!(component.componentCategory in this.componentsCategories)) {
      this.componentsCategories[component.componentCategory] = 0;
    }

    if (!Array.isArray(this.switchItemsByType[switchType])) {
      this.switchItemsByType[switchType] = [];
    }
    this.switchItemsByType[switchType].push(component);
    this.componentsCategories[component.componentCategory]++;
    this.flatComponents.push(component);
  }
}
