:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_yqycd_7 {
  position: absolute;
  pointer-events: none;
  top: 18px;
  z-index: 102;
  display: flex; }
  ._container_yqycd_7._horizontallyCentered_yqycd_13 {
    top: calc(50% - 15px); }
  ._container_yqycd_7 ._lightLarge_yqycd_15 {
    top: 24px;
    left: 24px; }

._button_yqycd_19 {
  margin-right: 12px;
  cursor: pointer;
  pointer-events: all; }
  ._button_yqycd_19:last-child {
    margin-right: 0; }
