import React from 'react';
import {
  Composites,
  InfoIcon,
  DropDown,
  TextLabel,
  CollapseLabel,
  DropDownListItemOption,
} from '@wix/wix-base-ui';

import { translate } from '@/i18n';
import { hoc } from '@/util';
const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

import { mapDispatchToProps } from './RelAttribute.mapper';

interface RelAttributeComponentProps {
  rel: string[];
  onChange: (newValue: string[]) => void;
  category: string;
  origin: string;
}

interface RelAttrDispatchProps {
  openHelpCenter: () => void;
  sendExpandBI: (
    category: string,
    subcategory: string,
    origin: string,
    isOpen: boolean,
  ) => void;
  sendToggleRelValuesBI: (
    category: string,
    prevVal: string[],
    newVal: string[],
    origin: string,
  ) => void;
}

interface RelAttrProps
  extends RelAttributeComponentProps,
    RelAttrDispatchProps {}

interface RelAttributeComponentState {
  rel: string[];
}

const relDropdownOptions = [
  {
    value: 'noopener',
    label: translate('LINK_PANEL_SEO_REL_NOOPENER_TITLE'),
    subtitle: translate('LINK_PANEL_SEO_REL_NOOPENER_SUBTITLE'),
  },
  {
    value: 'noreferrer',
    label: translate('LINK_PANEL_SEO_REL_NOREFERRER_TITLE'),
    subtitle: translate('LINK_PANEL_SEO_REL_NOREFERRER_SUBTITLE'),
  },
  {
    value: 'nofollow',
    label: translate('LINK_PANEL_SEO_REL_NOFOLLOW_TITLE'),
    subtitle: translate('LINK_PANEL_SEO_REL_NOFOLLOW_SUBTITLE'),
  },
  {
    value: 'sponsored',
    label: translate('LINK_PANEL_SEO_REL_SPONSORED_TITLE'),
    subtitle: translate('LINK_PANEL_SEO_REL_SPONSORED_SUBTITLE'),
  },
];

export class RelAttributeComponent extends React.Component<
  RelAttrProps,
  RelAttributeComponentState
> {
  constructor(props: AnyFixMe) {
    super(props);
    const rel = props.rel;
    this.state = {
      rel,
    };
  }

  private onChange = (newValue: string[]) => {
    this.props.sendToggleRelValuesBI(
      this.props.category,
      this.props.rel,
      newValue,
      this.props.origin,
    );
    this.props.onChange(newValue);
  };

  render() {
    return (
      <CollapseLabel
        label={translate('LINK_PANEL_SEO_COLLAPSE_TITLE')}
        shouldTranslate={false}
        isOpen={false}
        onToggle={this.props.sendExpandBI.bind(
          null,
          this.props.category,
          'seo_settings',
          this.props.origin,
        )}
      >
        <Composites.DropDownLabeled>
          <InfoIcon
            text={'LINK_PANEL_SEO_REL_TOOLTIP_TEXT'}
            linkText={'LINK_PANEL_SEO_REL_TOOLTIP_LEARN_MORE_LINK'}
            onLinkClick={this.props.openHelpCenter}
          />
          <TextLabel type="T02" value={'LINK_PANEL_SEO_REL_TITLE'} />
          <DropDown
            value={this.props.rel}
            openOnSelected={true}
            multiSelect={true}
            onChange={this.onChange}
            onToggle={this.props.sendExpandBI.bind(
              null,
              this.props.category,
              'rel_values',
              this.props.origin,
            )}
            placeholder={translate('LINK_PANEL_SEO_REL_PLACEHOLDER')}
            optionsContainerClassName="larger-item-list-dropdown-options"
          >
            {relDropdownOptions.map((option) => (
              <DropDownListItemOption
                key={option.value}
                value={option.value}
                label={option.label}
                subtitle={option.subtitle}
              />
            ))}
          </DropDown>
        </Composites.DropDownLabeled>
      </CollapseLabel>
    );
  }
}

export const RelAttribute = connect(
  EDITOR_API,
  null,
  mapDispatchToProps,
)(RelAttributeComponent);

RelAttribute.pure = RelAttributeComponent;
