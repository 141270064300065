// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { OldMessagePanelFrame } from '../../frames';
import { translate } from '@/i18n';
import { RichText } from '@wix/wix-base-ui';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  panelName: string;
  onConfirm: FunctionFixMe;
  onCancel: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'deleteFontMsg';

  static propTypes = {
    panelName: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  render() {
    return (
      <OldMessagePanelFrame
        panelName={this.props.panelName}
        onConfirm={this.props.onConfirm}
        onCancel={this.props.onCancel}
        confirmLabel="Upload_Fonts_Delete_Pop_Up_Button_1"
        cancelLabel="Upload_Fonts_Delete_Pop_Up_Button_2"
        type="danger"
        panelTitle={translate('Upload_Fonts_Delete_Pop_Up_Header')}
        className="delete-font-message"
      >
        <RichText type="T01" automationId="confirmOpenDescription">
          {translate('Upload_Fonts_Delete_Pop_Up_Text')}
        </RichText>
      </OldMessagePanelFrame>
    );
  }
}
