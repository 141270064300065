import React, { useEffect } from 'react';

import * as panels from '@/panels';
import constants from '@/constants';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';

import {
  mapDispatchToProps,
  type SiteSettingsBranchWarningDispatchProps,
  type SiteSettingsBranchWarningOwnProps,
} from './siteSettingsBranchWarningMapper';
import { extractOriginFromDashboardUrlForBi, mapBiOrigin } from './biUtils';
import type { ProvidedPanelFrameProps } from '@/stateManagement';

import * as symbols from '@wix/santa-editor-symbols';
import { RichText } from '@wix/wix-base-ui';

import _ from 'lodash';
import * as util from '@/util';

type SiteSettingsBranchWarningProps = SiteSettingsBranchWarningOwnProps &
  ProvidedPanelFrameProps &
  SiteSettingsBranchWarningDispatchProps;

const SiteSettingsBranchWarning: React.FC<SiteSettingsBranchWarningProps> = ({
  close: closeOnlyThisPanel,
  openHelpArticle,
  sendBi,
  dashboardUrl,
}) => {
  useEffect(() => {
    sendBi(coreBi.events.siteSettingsBranchWarning.isShown, {
      origin: extractOriginFromDashboardUrlForBi(dashboardUrl),
    });
  }, [dashboardUrl, sendBi]);

  return (
    <panels.frames.MessagePanelFrame
      panelName={constants.PANEL_TYPES.SITE_SETTINGS_BRANCH_WARNING}
      dataHook="site-settings-branch-warning"
      onCancel={(origin) => {
        sendBi(coreBi.events.siteSettingsBranchWarning.popupAction, {
          action: mapBiOrigin(origin),
        });
        closeOnlyThisPanel('site-settings-branch-warning');
      }}
      title={translate('SiteBranches_Editor_TopBar_Modal_Title')}
      illustration={
        <symbols.symbol name="velo-site-branches-changes-cog-branches" />
      }
      confirmLabel={translate('SiteBranches_Editor_TopBar_Modal_Button')}
      onConfirm={() => {
        sendBi(coreBi.events.siteSettingsBranchWarning.popupAction, {
          action: 'continue',
        });
        closeOnlyThisPanel('site-settings-branch-warning');
      }}
      dontShowAgainKey={
        constants.USER_PREFS.BRANCHES.SITE_SETTINGS_WARNING_DONT_SHOW_AGAIN
      }
      userPrefType="site"
      cancelOnClickOutside={false}
      onHelp={openHelpArticle}
      className="site-settings-branch-warning-panel"
    >
      {/* @ts-expect-error */}
      <RichText type="T06" enableEllipsis={false}>
        {translate('SiteBranches_Editor_TopBar_Modal_Text')}
        <br />
        <a
          href={translate('SiteBranches_Editor_TopBar_Modal_Link_URL')}
          target="_blank"
        >
          {translate('SiteBranches_Editor_TopBar_Modal_Link_Text')}
        </a>
      </RichText>
    </panels.frames.MessagePanelFrame>
  );
};

export default _.flow(
  util.hoc.connect(util.hoc.STORES.EDITOR_API, null, mapDispatchToProps),
  stateManagement.panels.hoc.panelFrame(
    constants.PANEL_TYPES.SITE_SETTINGS_BRANCH_WARNING,
  ),
)(SiteSettingsBranchWarning);
