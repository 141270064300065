import { calculateMobileParents } from './calculateMobileParents';
import { collectMobileComponents } from './collectMobileComponents';
import {
  getMobileZIndex,
  calculateSectionBottomEdge,
  calculateSectionTopEdge,
} from './utils';

import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import type {
  SectionDescription,
  SectionDescriptionMobile,
  MobileComponent,
} from '../../types';

export function getMobileSectionsDescriptions(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  sectionsDescription: SectionDescription[],
): SectionDescription[] {
  const {
    mobileRootComponents,
    mobileRootComponentsOrdering,
    sectionsChildrenToKeepMobileParent,
  } = collectMobileComponents(documentServices, pageRef, sectionsDescription);

  const sectionsMobileChildrenWithParents: MobileComponent[] =
    calculateMobileParents(mobileRootComponents, sectionsDescription.length);

  return sectionsDescription.map((section, sectionIndex) => {
    const mobileChildren = sectionsMobileChildrenWithParents.filter(
      (item) => item.mobileParentIndex === sectionIndex,
    );

    if (mobileChildren.length === 0) {
      return {
        ...section,
        mobile: null,
      };
    }

    const mobileChildrenSorted = mobileChildren.sort(
      (itemA, itemB) =>
        getMobileZIndex(
          documentServices,
          pageRef,
          itemA.ref,
          mobileRootComponentsOrdering,
        ) -
        getMobileZIndex(
          documentServices,
          pageRef,
          itemB.ref,
          mobileRootComponentsOrdering,
        ),
    );

    const childrenLayouts = mobileChildren.map((child) => {
      const { layout, isFullWidth } = child;
      return {
        top: layout.y,
        bottom: layout.y + layout.height,
        isFullWidth,
      };
    });

    const { position: top, isFullWidth: isFullWidthTop } =
      calculateSectionTopEdge(childrenLayouts);

    const { position: bottom, isFullWidth: isFullWidthBottom } =
      calculateSectionBottomEdge(childrenLayouts);

    const mobile: SectionDescriptionMobile = {
      top,
      bottom,
      children: mobileChildrenSorted,
      isFullWidthTop,
      isFullWidthBottom,
    };

    const childrenToKeepMobileParent =
      sectionsChildrenToKeepMobileParent[sectionIndex];

    return {
      ...section,
      mobile,
      childrenToKeepMobileParent,
    };
  });
}
