"use strict"
const addPanelDataConsts = require('@/addPanelDataConsts')
const i18n = require('@/i18n')

module.exports = {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    title: "add_section_label_multiStateBox",
    subNavigationTitle: "add_section_label_multiStateBox",
    presetTitle: "add_section_label_multiStateBox",
    tooltipTitle: "add_section_label_multiStateBox_tooltip_title",
    automationId: "add-panel-section-stateBoxSection",
    subNavigationHide: false,
    showSectionHeader: true,
    hide: false,
    additionalBehaviours: {
        labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
        iconEnabledForComps: {}
    },
    props: {
        image:
            `addPanelData/sections/multiStateBoxSection_en/multiStateBoxSection_en.png`,
        imageHover:
            `addPanelData/sections/multiStateBoxSection_en/hover_en/multiStateBoxSection_hover_en.png`,
        items: [
            {
                id: "StateBox_StateBox_1",
                structure: {
                    type: "Container",
                    id: "comp-k0grve7f",
                    components: [{
                        type: "Container",
                        id: "comp-k0grve8p",
                        components: [{
                            type: "Container",
                            id: "comp-k0grve93",
                            components: [{
                                type: "Component",
                                id: "comp-k0grve9g",
                                skin: "skins.viewer.mediaOverlayControlsDefaultSkin",
                                layout: {
                                    width: 69,
                                    height: 69,
                                    x: 85,
                                    y: 85,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.MediaOverlayControls",
                                props: {
                                    type: "MediaOverlayControlsProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    playerId: "comp-k0grve93",
                                    id: "propItem-k0grve9l"
                                },
                                design: {
                                    type: "MediaControlsDesignData",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    iconsDefaultDesign: {
                                        type: "VectorImageDesignData",
                                        metaData: {
                                            isPreset: false,
                                            schemaVersion: "1.0",
                                            isHidden: false
                                        },
                                        overrideColors: {
                                            "color1": "#ffffff"
                                        },
                                        shapeStyle: {
                                            opacity: 1,
                                            strokeWidth: 1,
                                            stroke: "#000000",
                                            strokeOpacity: 1,
                                            enableStroke: false
                                        }
                                    },
                                    icons: [{
                                        type: "ControlIconDesignData",
                                        metaData: {
                                            isPreset: false,
                                            schemaVersion: "1.0",
                                            isHidden: false
                                        },
                                        name: "playButton",
                                        svgId: "0da768_152a8490c7444b949a51e30f2ac4d9a4.svg"
                                    }],
                                    id: "dataItem-k0grve9m"
                                },
                                style: {
                                    type: "ComponentStyle",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    style: {
                                        properties: {},
                                        propertiesSource: {},
                                        groups: {}
                                    },
                                    componentClassName: "",
                                    pageId: "",
                                    compId: "",
                                    styleType: "custom",
                                    skin: "skins.viewer.mediaOverlayControlsDefaultSkin",
                                    id: "style-k0grve9o"
                                },
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "mediaOverlayControls1"
                                    }],
                                    id: "connection-k0grve9o1"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 74,
                                        height: 74,
                                        x: 83,
                                        y: 26,
                                        scale: 1,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0grve9g",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0grveab",
                                skin: "skins.viewer.mediaControlsNoControlsSkin",
                                layout: {
                                    width: 236,
                                    height: 44,
                                    x: 2,
                                    y: 194,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.MediaControls",
                                props: {
                                    type: "MediaControlsProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    showStoryboard: "videoAndTime",
                                    playerId: "comp-k0grve93",
                                    id: "propItem-k0grveah"
                                },
                                design: {
                                    type: "MediaControlsDesignData",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    iconsDefaultDesign: {
                                        type: "VectorImageDesignData",
                                        metaData: {
                                            isPreset: false,
                                            schemaVersion: "1.0",
                                            isHidden: false
                                        },
                                        overrideColors: {
                                            "color1": "#ffffff"
                                        },
                                        shapeStyle: {
                                            opacity: 1,
                                            strokeWidth: 1,
                                            stroke: "#000000",
                                            strokeOpacity: 1,
                                            enableStroke: false
                                        }
                                    },
                                    icons: [{
                                        type: "ControlIconDesignData",
                                        metaData: {
                                            isPreset: false,
                                            schemaVersion: "1.0",
                                            isHidden: false
                                        },
                                        name: "volumeOn",
                                        svgId: "0da768_9c02fee4517e40e1bfe9581b4cfa3750.svg"
                                    }, {
                                        type: "ControlIconDesignData",
                                        metaData: {
                                            isPreset: false,
                                            schemaVersion: "1.0",
                                            isHidden: false
                                        },
                                        name: "volumeOff",
                                        svgId: "0da768_2a8395c630404213aaf6576ea1baaa70.svg"
                                    }, {
                                        type: "ControlIconDesignData",
                                        metaData: {
                                            isPreset: false,
                                            schemaVersion: "1.0",
                                            isHidden: false
                                        },
                                        name: "noAudio",
                                        svgId: "0da768_2a8395c630404213aaf6576ea1baaa70.svg",
                                        iconDesign: {
                                            type: "VectorImageDesignData",
                                            metaData: {
                                                isPreset: false,
                                                schemaVersion: "1.0",
                                                isHidden: false
                                            },
                                            overrideColors: {
                                                "color1": "#ACAEAF"
                                            }
                                        }
                                    }],
                                    id: "dataItem-k0grveai"
                                },
                                style: {
                                    type: "ComponentStyle",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    style: {
                                        properties: {
                                            disabledcolor: "#8d8d8d",
                                            maincolor: "#FFFFFF",
                                            textcolor: "#2d2d2d"
                                        },
                                        propertiesSource: {},
                                        groups: {}
                                    },
                                    componentClassName: "",
                                    pageId: "",
                                    compId: "",
                                    styleType: "custom",
                                    skin: "skins.viewer.mediaControlsNoControlsSkin",
                                    id: "style-k0grveaj"
                                },
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "mediaControls1"
                                    }],
                                    id: "connection-k0grveaj1"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 236,
                                        height: 44,
                                        x: 2,
                                        y: 80,
                                        scale: 1,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0grveab",
                                        author: "duplicate"
                                    }
                                }
                            }],
                            skin: "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
                            layout: {
                                width: 240,
                                height: 240,
                                x: 370,
                                y: 143,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.MediaPlayer",
                            props: {
                                type: "MediaPlayerProperties",
                                metaData: {
                                    schemaVersion: "1.0",
                                    autoGenerated: false
                                },
                                autoplay: true,
                                playerInteraction: {
                                    click: "play",
                                    rollIn: "none",
                                    rollOut: "none",
                                    allowReplay: true
                                },
                                mute: true,
                                loop: true,
                                disableAudio: true,
                                playerAudioInteraction: {
                                    rollIn: "none",
                                    rollOut: "none"
                                },
                                animatePoster: "none",
                                id: "propItem-k0grve99"
                            },
                            design: {
                                type: "MediaPlayerDesignData",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                background: {
                                    type: "BackgroundMedia",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    mediaRef: {
                                        type: "WixVideo",
                                        metaData: {
                                            isPreset: false,
                                            schemaVersion: "1.0",
                                            isHidden: false
                                        },
                                        title: "Ice Cream Looader.mp4",
                                        videoId: "74f558_e544cc7a55c94117846e31b1b9553c33",
                                        qualities: [{
                                            quality: "144p",
                                            width: 240,
                                            height: 240,
                                            url: "video/74f558_e544cc7a55c94117846e31b1b9553c33/144p/mp4/file.mp4"
                                        }, {
                                            quality: "storyboard",
                                            width: 144,
                                            height: 144,
                                            url: "video/74f558_e544cc7a55c94117846e31b1b9553c33/storyboard/144p/mp4/file.mp4"
                                        }],
                                        posterImageRef: {
                                            type: "Image",
                                            metaData: {
                                                isPreset: false,
                                                schemaVersion: "1.0",
                                                isHidden: false
                                            },
                                            title: "",
                                            uri: "74f558_e544cc7a55c94117846e31b1b9553c33f000.jpg",
                                            description: "private",
                                            width: 240,
                                            height: 240,
                                            alt: ""
                                        },
                                        opacity: 1,
                                        duration: 2.92,
                                        preload: "auto",
                                        artist: {
                                            id: "",
                                            name: ""
                                        },
                                        generatedPosters: ["74f558_e544cc7a55c94117846e31b1b9553c33f000.jpg", "74f558_e544cc7a55c94117846e31b1b9553c33f001.jpg", "74f558_e544cc7a55c94117846e31b1b9553c33f002.jpg", "74f558_e544cc7a55c94117846e31b1b9553c33f003.jpg"],
                                        hasAudio: true,
                                        fps: "60/1",
                                        adaptiveVideo: [{
                                            format: "hls",
                                            url: "site/media/video/74f558_e544cc7a55c94117846e31b1b9553c33/6779ea56-4f07-4bf7-84af-7cc4615d2393/repackage/hls"
                                        }]
                                    },
                                    color: "#212121",
                                    colorOpacity: 1,
                                    alignType: "center",
                                    fittingType: "fill",
                                    scrollType: "none",
                                    colorOverlay: "",
                                    colorOverlayOpacity: 0,
                                    showOverlayForMediaType: "WixVideo"
                                },
                                cssStyle: {},
                                themeMappings: {},
                                charas: "design-k0gqejml",
                                id: "dataItem-k0grve9a"
                            },
                            style: "mp1",
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "mediaPlayer1"
                                }],
                                id: "connection-k0grve9e"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 240,
                                    height: 126,
                                    x: 19,
                                    y: 82,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k0grve93",
                                    author: "duplicate"
                                }
                            }
                        }],
                        layout: {
                            width: 980,
                            height: 527,
                            x: 0,
                            y: 0,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: "wysiwyg.viewer.components.StateBoxState",
                        design: {
                            type: "MediaContainerDesignData",
                            metaData: {
                                isPreset: false,
                                schemaVersion: "1.0",
                                isHidden: false
                            },
                            background: {
                                type: "BackgroundMedia",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                color: "#FDF5ED",
                                colorOpacity: 1,
                                alignType: "center",
                                fittingType: "fill",
                                scrollType: "none",
                                colorOverlay: "",
                                colorOverlayOpacity: 0,
                                showOverlayForMediaType: "WixVideo"
                            },
                            charas: "design-k0grtzmy",
                            dataChangeBehaviors: [],
                            id: "dataItem-k0grve90"
                        },
                        style: "stateBoxState1",
                        connections: {
                            type: "ConnectionList",
                            items: [{
                                type: "WixCodeConnectionItem",
                                role: "Preloader"
                            }],
                            id: "connection-k0grve91"
                        },
                        mobileStructure: {
                            layout: {
                                width: 280,
                                height: 290,
                                x: 0,
                                y: 0,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            metaData: {
                                originalCompId: "comp-k0grve8p",
                                author: "duplicate"
                            }
                        }
                    }, {
                        type: "Container",
                        id: "comp-k0grvecf",
                        components: [{
                            type: "Container",
                            id: "comp-k0grvect",
                            components: [{
                                type: "Component",
                                id: "comp-k0grved4",
                                skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                                layout: {
                                    width: 357,
                                    height: 53,
                                    x: 44,
                                    y: 46,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.WRichText",
                                data: {
                                    type: "StyledText",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    text: `<h4 class=\"font_4\" style=\"font-size:35px\"><span style=\"font-size:35px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:poppins-semibold,poppins,sans-serif\"><span style=\"color:#333333\">${i18n.translate('multiStateBox_preset_loader_title')}</span></span></span></span></h4>`,
                                    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                    linkList: [],
                                    id: "dataItem-k0grvedd"
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    brightness: 1,
                                    packed: false,
                                    minHeight: 26,
                                    id: "propItem-k0grvedf"
                                },
                                mobileHints: {
                                    type: "MobileHints",
                                    hidden: false,
                                    id: "mobileHints-k0grvedh"
                                },
                                style: "txtNew",
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "text26"
                                    }],
                                    id: "connection-k0grvedh1"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 220,
                                        height: 35,
                                        x: 15,
                                        y: 11,
                                        scale: 0.8214285714285714,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    props: {
                                        type: "WRichTextProperties",
                                        metaData: {
                                            schemaVersion: "1.0",
                                            autoGenerated: true
                                        },
                                        brightness: 1,
                                        packed: false,
                                        minHeight: null
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0grved4",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0grvee5",
                                skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                                layout: {
                                    width: 204,
                                    height: 19,
                                    x: 47,
                                    y: 104,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.WRichText",
                                data: {
                                    type: "StyledText",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    text: `<p class=\"font_4\" style=\"font-size:16px\"><span style=\"font-size:16px\"><span style=\"color:#FF7575\"><span style=\"font-style:italic\"><span style=\"font-family:georgia,palatino,book antiqua,palatino linotype,serif\"><span style=\"letter-spacing:0em\">${i18n.translate('multiStateBox_preset_loader_subtitle_text')}</span></span></span></span></span></p>`,
                                    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                    linkList: [],
                                    id: "dataItem-k0grveeb"
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    brightness: 1,
                                    packed: false,
                                    minHeight: 14,
                                    id: "propItem-k0grveeb1"
                                },
                                mobileHints: {
                                    type: "MobileHints",
                                    hidden: false,
                                    id: "mobileHints-k0grveec"
                                },
                                style: "txtNew",
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "text25"
                                    }],
                                    id: "connection-k0grveec1"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 220,
                                        height: 19,
                                        x: 15,
                                        y: 46,
                                        scale: 1,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    props: {
                                        type: "WRichTextProperties",
                                        metaData: {
                                            schemaVersion: "1.0",
                                            autoGenerated: true
                                        },
                                        brightness: 1,
                                        packed: false,
                                        minHeight: null
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0grvee5",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0grveey",
                                skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                                layout: {
                                    width: 357,
                                    height: 145,
                                    x: 47,
                                    y: 171,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.WRichText",
                                data: {
                                    type: "StyledText",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    text: `<p class=\"font_4\" style=\"font-size:16px; line-height:1.5em\"><span style=\"font-size:16px\"><span style=\"color:#000000\"><span style=\"font-style:normal\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-weight:bold\">${i18n.translate('multiStateBox_preset_loader_short_description')}</span></span></span></span></span></p>`,
                                    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                    linkList: [],
                                    id: "dataItem-k0grvef8"
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    brightness: 1,
                                    packed: false,
                                    minHeight: 145,
                                    id: "propItem-k0grvef9"
                                },
                                mobileHints: {
                                    type: "MobileHints",
                                    hidden: false,
                                    id: "mobileHints-k0grvef91"
                                },
                                style: "txtNew",
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "text24"
                                    }],
                                    id: "connection-k0grvefa"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 220,
                                        height: 168,
                                        x: 15,
                                        y: 84,
                                        scale: 0.875,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    props: {
                                        type: "WRichTextProperties",
                                        metaData: {
                                            schemaVersion: "1.0",
                                            autoGenerated: true
                                        },
                                        brightness: 1,
                                        packed: false,
                                        minHeight: null
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0grveey",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0grvefx",
                                skin: "wysiwyg.viewer.skins.button.BasicButton",
                                layout: {
                                    width: 122,
                                    height: 34,
                                    x: 44,
                                    y: 339,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.SiteButton",
                                data: {
                                    type: "LinkableButton",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    label: i18n.translate('multiStateBox_preset_loader_short_description_button'),
                                    id: "dataItem-k0grveg3"
                                },
                                props: {
                                    type: "ButtonProperties",
                                    metaData: {
                                        schemaVersion: "1.0"
                                    },
                                    align: "center",
                                    margin: 0,
                                    id: "propItem-k0grveg4"
                                },
                                style: {
                                    type: "ComponentStyle",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    style: {
                                        properties: {
                                            "alpha-bg": "1",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "0",
                                            "alpha-txt": "1",
                                            "alpha-txth": "1",
                                            bg: "#323232",
                                            bgh: "#3A344D",
                                            "boxShadowToggleOn-shd": "false",
                                            brd: "color_14",
                                            brdh: "color_12",
                                            brw: "0",
                                            fnt: "normal normal normal 14px/1.4em poppins-extralight",
                                            rd: "30px 30px 30px 30px",
                                            shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                            txt: "color_8",
                                            txth: "color_11"
                                        },
                                        propertiesSource: {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "alpha-txt": "value",
                                            "alpha-txth": "value",
                                            bg: "value",
                                            bgh: "value",
                                            "boxShadowToggleOn-shd": "value",
                                            brd: "theme",
                                            brdh: "theme",
                                            brw: "value",
                                            fnt: "value",
                                            rd: "value",
                                            shd: "value",
                                            txt: "theme",
                                            txth: "theme"
                                        },
                                        groups: {}
                                    },
                                    componentClassName: "wysiwyg.viewer.components.SiteButton",
                                    pageId: "",
                                    compId: "",
                                    styleType: "custom",
                                    skin: "wysiwyg.viewer.skins.button.BasicButton",
                                    id: "style-k0grveg41"
                                },
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "button1"
                                    }],
                                    id: "connection-k0grveg42"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 220,
                                        height: 36,
                                        x: 15,
                                        y: 268,
                                        scale: 0.8619047619047618,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0grvefx",
                                        author: "duplicate"
                                    }
                                }
                            }],
                            layout: {
                                width: 451,
                                height: 418,
                                x: 55,
                                y: 54,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "mobile.core.components.Container",
                            style: {
                                type: "ComponentStyle",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                style: {
                                    properties: {
                                        "alpha-bg": "1",
                                        bg: "#FFFFFF"
                                    },
                                    propertiesSource: {
                                        bg: "value"
                                    },
                                    groups: {}
                                },
                                componentClassName: "mobile.core.components.Container",
                                pageId: "",
                                compId: "",
                                styleType: "custom",
                                skin: "wysiwyg.viewer.skins.area.RectangleArea",
                                id: "style-k0grved1"
                            },
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "box12"
                                }],
                                id: "connection-k0grved2"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 250,
                                    height: 324,
                                    x: 13,
                                    y: 15,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k0grvect",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Component",
                            id: "comp-k0grvehg",
                            skin: "wysiwyg.viewer.skins.photo.NoSkinPhoto",
                            layout: {
                                width: 418,
                                height: 418,
                                x: 506,
                                y: 54,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.WPhoto",
                            data: {
                                type: "Image",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "2.0",
                                    isHidden: false
                                },
                                title: "",
                                uri: "83426f65331c4d8587618b301c415d6d.jpg",
                                description: "",
                                width: 2667,
                                height: 1500,
                                alt: "Forest Fruit Ice Cream",
                                crop: {
                                    x: 538,
                                    y: 0,
                                    width: 1501,
                                    height: 1500,
                                    svgId: "909695c1e003409ba70b5561666c7c4d.svg"
                                },
                                name: "Forest Fruit Ice Cream",
                                id: "dataItem-k0grvehn"
                            },
                            props: {
                                type: "WPhotoProperties",
                                metaData: {
                                    schemaVersion: "1.0"
                                },
                                displayMode: "fill",
                                onClickBehavior: "goToLink",
                                autoFill: false,
                                id: "propItem-k0grvehn1"
                            },
                            mobileHints: {
                                type: "MobileHints",
                                hidden: false,
                                id: "mobileHints-k0grveho"
                            },
                            style: {
                                type: "ComponentStyle",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                style: {
                                    properties: {},
                                    propertiesSource: {},
                                    groups: {}
                                },
                                componentClassName: "wysiwyg.viewer.components.WPhoto",
                                pageId: "",
                                compId: "",
                                styleType: "custom",
                                skin: "wysiwyg.viewer.skins.photo.NoSkinPhoto",
                                id: "style-k0grveho1"
                            },
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "image14"
                                }],
                                id: "connection-k0grveho2"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 249,
                                    height: 270,
                                    x: 14,
                                    y: 339,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                props: {
                                    type: "WPhotoProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    displayMode: "fill",
                                    onClickBehavior: "goToLink",
                                    overrideCrop: {
                                        x: 429,
                                        y: 0,
                                        width: 1738,
                                        height: 1500,
                                        svgId: "909695c1e003409ba70b5561666c7c4d.svg"
                                    },
                                    autoFill: false
                                },
                                metaData: {
                                    originalCompId: "comp-k0grvehg",
                                    author: "duplicate"
                                }
                            }
                        }],
                        layout: {
                            width: 980,
                            height: 527,
                            x: 0,
                            y: 0,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: "wysiwyg.viewer.components.StateBoxState",
                        design: {
                            type: "MediaContainerDesignData",
                            metaData: {
                                isPreset: false,
                                schemaVersion: "1.0",
                                isHidden: false
                            },
                            background: {
                                type: "BackgroundMedia",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                color: "#FDF5ED",
                                colorOpacity: 1,
                                alignType: "center",
                                fittingType: "fill",
                                scrollType: "none",
                                colorOverlay: "",
                                colorOverlayOpacity: 0,
                                showOverlayForMediaType: "WixVideo"
                            },
                            charas: "design-k0grus5h",
                            dataChangeBehaviors: [],
                            id: "dataItem-k0grvecq"
                        },
                        style: "stateBoxState1",
                        connections: {
                            type: "ConnectionList",
                            items: [{
                                type: "WixCodeConnectionItem",
                                role: "Intro"
                            }],
                            id: "connection-k0grvecr"
                        },
                        mobileStructure: {
                            layout: {
                                width: 280,
                                height: 626,
                                x: 0,
                                y: 0,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            metaData: {
                                originalCompId: "comp-k0grvecf",
                                author: "duplicate"
                            }
                        }
                    }, {
                        type: "Container",
                        id: "comp-k0grveiz",
                        components: [{
                            type: "Component",
                            id: "comp-k0grvejd",
                            layout: {
                                width: 864,
                                height: 377,
                                x: 58,
                                y: 56,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.WPhoto",
                            data: {
                                type: "Image",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "2.0",
                                    isHidden: false
                                },
                                title: "",
                                uri: "83426f65331c4d8587618b301c415d6d.jpg",
                                description: "",
                                width: 2667,
                                height: 1500,
                                alt: "Forest Fruit Ice Cream",
                                crop: {
                                    x: 0,
                                    y: 191,
                                    width: 2667,
                                    height: 1164,
                                    svgId: "909695c1e003409ba70b5561666c7c4d.svg"
                                },
                                name: "Forest Fruit Ice Cream",
                                id: "dataItem-k0grvejj"
                            },
                            props: {
                                type: "WPhotoProperties",
                                metaData: {
                                    schemaVersion: "1.0",
                                    autoGenerated: false
                                },
                                displayMode: "fill",
                                onClickBehavior: "goToLink",
                                autoFill: false,
                                id: "propItem-k0grvejk"
                            },
                            style: "wp2",
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "image5"
                                }],
                                id: "connection-k0grvejk1"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 250,
                                    height: 117,
                                    x: 15,
                                    y: 15,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                props: {
                                    type: "WPhotoProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    displayMode: "fill",
                                    onClickBehavior: "goToLink",
                                    overrideCrop: {
                                        x: 131,
                                        y: 0,
                                        width: 2332,
                                        height: 1500,
                                        svgId: "909695c1e003409ba70b5561666c7c4d.svg"
                                    },
                                    autoFill: false
                                },
                                metaData: {
                                    originalCompId: "comp-k0grvejd",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Container",
                            id: "comp-k0grvek8",
                            components: [{
                                type: "Component",
                                id: "comp-k0grveky",
                                skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                                layout: {
                                    width: 238,
                                    height: 33,
                                    x: 28,
                                    y: 20,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.WRichText",
                                data: {
                                    type: "StyledText",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    text: `<h4 class=\"font_4\" style=\"font-size:22px\"><span style=\"font-size:22px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:poppins-semibold,poppins,sans-serif\"><span style=\"color:#333333\">${i18n.translate('multiStateBox_preset_loader_title')}</span></span></span></span></h4>`,
                                    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                    linkList: [],
                                    id: "dataItem-k0grvel5"
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    brightness: 1,
                                    packed: true,
                                    id: "propItem-k0grvel51"
                                },
                                mobileHints: {
                                    type: "MobileHints",
                                    hidden: false,
                                    id: "mobileHints-k0grvel6"
                                },
                                style: "txtNew",
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "text55"
                                    }],
                                    id: "connection-k0grvel61"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 220,
                                        height: 43,
                                        x: 10,
                                        y: 10,
                                        scale: 1.3181818181818181,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    props: {
                                        type: "WRichTextProperties",
                                        metaData: {
                                            schemaVersion: "1.0",
                                            autoGenerated: true
                                        },
                                        brightness: 1,
                                        packed: false,
                                        minHeight: null
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0grveky",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0grvem4",
                                skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                                layout: {
                                    width: 238,
                                    height: 16,
                                    x: 28,
                                    y: 58,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.WRichText",
                                data: {
                                    type: "StyledText",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    text: `<p class=\"font_4\" style=\"font-size:14px\"><span style=\"color:#FF7575\"><span style=\"font-style:italic\"><span style=\"font-family:georgia,palatino,book antiqua,palatino linotype,serif\"><span style=\"font-size:14px\"><span style=\"letter-spacing:0em\">${i18n.translate('multiStateBox_preset_loader_subtitle_text')}</span></span></span></span></span></p>`,
                                    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                    linkList: [],
                                    id: "dataItem-k0grvemc"
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    brightness: 1,
                                    packed: true,
                                    id: "propItem-k0grvemd"
                                },
                                mobileHints: {
                                    type: "MobileHints",
                                    hidden: false,
                                    id: "mobileHints-k0grvemd1"
                                },
                                style: "txtNew",
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "text56"
                                    }],
                                    id: "connection-k0grvemd2"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 220,
                                        height: 20,
                                        x: 10,
                                        y: 53,
                                        scale: 1.1428571428571428,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    props: {
                                        type: "WRichTextProperties",
                                        metaData: {
                                            schemaVersion: "1.0",
                                            autoGenerated: true
                                        },
                                        brightness: 1,
                                        packed: false,
                                        minHeight: null
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0grvem4",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0grven3",
                                skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                                layout: {
                                    width: 804,
                                    height: 117,
                                    x: 28,
                                    y: 100,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.WRichText",
                                data: {
                                    type: "StyledText",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    text: `<p class=\"font_8\" style=\"font-size: 16px; line-height: 1.3em;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:16px\">${i18n.translate('multiStateBox_preset_loader_long_description_text1')}</span></span></p>\n\n<p class=\"font_8\" style=\"font-size: 16px; line-height: 1.21em;\">&nbsp;</p>\n\n<p class=\"font_8\" style=\"font-size: 16px; line-height: 1.21em;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:16px\">${i18n.translate('multiStateBox_preset_loader_long_description_text2')}</span></span></p>`,
                                    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                    linkList: [],
                                    id: "dataItem-k0grvenb"
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    brightness: 1,
                                    packed: false,
                                    minHeight: 117,
                                    id: "propItem-k0grvenb1"
                                },
                                style: "txtNew",
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "text29"
                                    }],
                                    id: "connection-k0grvenc"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 220,
                                        height: 270,
                                        x: 13,
                                        y: 93,
                                        scale: 0.875,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    props: {
                                        type: "WRichTextProperties",
                                        metaData: {
                                            schemaVersion: "1.0",
                                            autoGenerated: false
                                        },
                                        brightness: 1,
                                        packed: false,
                                        overrideAlignment: "left"
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0grven3",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0grveo5",
                                skin: "wysiwyg.viewer.skins.button.BasicButton",
                                layout: {
                                    width: 122,
                                    height: 34,
                                    x: 28,
                                    y: 245,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.SiteButton",
                                data: {
                                    type: "LinkableButton",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    label: i18n.translate('multiStateBox_preset_loader_long_description_button'),
                                    id: "dataItem-k0grveof"
                                },
                                props: {
                                    type: "ButtonProperties",
                                    metaData: {
                                        schemaVersion: "1.0"
                                    },
                                    align: "center",
                                    margin: 0,
                                    id: "propItem-k0grveof1"
                                },
                                style: {
                                    type: "ComponentStyle",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    style: {
                                        properties: {
                                            "alpha-bg": "1",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "0",
                                            "alpha-txt": "1",
                                            "alpha-txth": "1",
                                            bg: "#323232",
                                            bgh: "#3A344D",
                                            "boxShadowToggleOn-shd": "false",
                                            brd: "color_14",
                                            brdh: "color_12",
                                            brw: "0",
                                            fnt: "normal normal normal 14px/1.4em poppins-extralight",
                                            rd: "30px 30px 30px 30px",
                                            shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                            txt: "color_8",
                                            txth: "color_11"
                                        },
                                        propertiesSource: {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "alpha-txt": "value",
                                            "alpha-txth": "value",
                                            bg: "value",
                                            bgh: "value",
                                            "boxShadowToggleOn-shd": "value",
                                            brd: "theme",
                                            brdh: "theme",
                                            brw: "value",
                                            fnt: "value",
                                            rd: "value",
                                            shd: "value",
                                            txt: "theme",
                                            txth: "theme"
                                        },
                                        groups: {}
                                    },
                                    componentClassName: "wysiwyg.viewer.components.SiteButton",
                                    pageId: "",
                                    compId: "",
                                    styleType: "custom",
                                    skin: "wysiwyg.viewer.skins.button.BasicButton",
                                    id: "style-k0grveof2"
                                },
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "button20"
                                    }],
                                    id: "connection-k0grveog"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 220,
                                        height: 36,
                                        x: 14,
                                        y: 385,
                                        scale: 0.8619047619047618,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0grveo5",
                                        author: "duplicate"
                                    }
                                }
                            }],
                            layout: {
                                width: 864,
                                height: 306,
                                x: 58,
                                y: 433,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "mobile.core.components.Container",
                            style: {
                                type: "ComponentStyle",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                style: {
                                    properties: {
                                        "alpha-bg": "1",
                                        bg: "#FFFFFF"
                                    },
                                    propertiesSource: {
                                        bg: "value"
                                    },
                                    groups: {}
                                },
                                componentClassName: "mobile.core.components.Container",
                                pageId: "",
                                compId: "",
                                styleType: "custom",
                                skin: "wysiwyg.viewer.skins.area.RectangleArea",
                                id: "style-k0grvekw"
                            },
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "box13"
                                }],
                                id: "connection-k0grvekw1"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 250,
                                    height: 443,
                                    x: 15,
                                    y: 132,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k0grvek8",
                                    author: "duplicate"
                                }
                            }
                        }],
                        layout: {
                            width: 980,
                            height: 795,
                            x: 0,
                            y: 0,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: "wysiwyg.viewer.components.StateBoxState",
                        design: {
                            type: "MediaContainerDesignData",
                            metaData: {
                                isPreset: false,
                                schemaVersion: "1.0",
                                isHidden: false
                            },
                            background: {
                                type: "BackgroundMedia",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                color: "#FBF3E8",
                                colorOpacity: 1,
                                alignType: "center",
                                fittingType: "fill",
                                scrollType: "none",
                                colorOverlay: "",
                                colorOverlayOpacity: 0,
                                showOverlayForMediaType: "WixVideo"
                            },
                            charas: "design-jxk1wpvx",
                            dataChangeBehaviors: [],
                            id: "dataItem-k0grvej8"
                        },
                        style: "stateBoxState1",
                        connections: {
                            type: "ConnectionList",
                            items: [{
                                type: "WixCodeConnectionItem",
                                role: "Expanded"
                            }],
                            id: "connection-k0grvej9"
                        },
                        mobileStructure: {
                            layout: {
                                width: 280,
                                height: 585,
                                x: 0,
                                y: 0,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            metaData: {
                                originalCompId: "comp-k0grveiz",
                                author: "duplicate"
                            }
                        }
                    }],
                    skin: "wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin",
                    layout: {
                        width: 980,
                        height: 527,
                        x: 32,
                        y: 64,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wysiwyg.viewer.components.StateBox",
                    props: {
                        type: "StateBoxProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        transition: "CrossFade",
                        transDuration: 0,
                        id: "propItem-k0grve8i"
                    },
                    style: {
                        type: "ComponentStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            properties: {
                                "alpha-brd": "0.11",
                                "boxShadowToggleOn-shd": "false",
                                brd: "color_15",
                                brw: "0",
                                shd: "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            propertiesSource: {
                                "alpha-brd": "value",
                                "boxShadowToggleOn-shd": "value",
                                brd: "theme",
                                brw: "value",
                                shd: "value"
                            },
                            groups: {}
                        },
                        componentClassName: "wysiwyg.viewer.components.StateBox",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin",
                        id: "style-k0grve8j"
                    },
                    connections: {
                        type: "ConnectionList",
                        items: [{
                            type: "WixCodeConnectionItem",
                            role: "PreloaderStateBox"
                        }],
                        id: "connection-k0grve8k"
                    },
                    mobileStructure: {
                        layout: {
                            width: 280,
                            height: 585,
                            x: 14,
                            y: 36,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        metaData: {
                            originalCompId: "comp-k0grve7f",
                            author: "duplicate"
                        }
                    },
                    activeModes: {}
                },
                preset: {
                    rect: {
                        width: 304,
                        height: 205,
                        x: 10,
                        y: 0
                    },
                    label: "add_preset_multiStateBox_1",
                    tags: null
                }
            },
            {
                id: "StateBox_StateBox_2",
                structure: {
                    type: "Container",
                    id: "comp-k0c29veg",
                    components: [{
                        type: "Container",
                        id: "comp-k0c29vfi",
                        components: [{
                            type: "Container",
                            id: "comp-k0c29vfq",
                            components: [{
                                type: "Component",
                                id: "comp-k0c29vg6",
                                skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                                layout: {
                                    width: 254,
                                    height: 32,
                                    x: 18,
                                    y: 12,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.WRichText",
                                data: {
                                    type: "StyledText",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    text: `<h6 class=\"font_6\" style=\"font-size:28px\"><span style=\"font-family:baskervillemtw01-smbdit,serif;\"><span style=\"font-size:28px\"><span style=\"color:#000000\">${i18n.translate('multiStateBox_preset_productBadge_bestSeller_text1')}</span></span></span></h6>`,
                                    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                    linkList: [],
                                    id: "dataItem-k0c29vgb"
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    brightness: 1,
                                    packed: true,
                                    id: "propItem-k0c29vgc"
                                },
                                style: "txtNew",
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "text30"
                                    }],
                                    id: "connection-k0c29vgc1"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 200,
                                        height: 24,
                                        x: 30,
                                        y: 15,
                                        scale: 0.8076923076923077,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    props: {
                                        type: "WRichTextProperties",
                                        metaData: {
                                            schemaVersion: "1.0",
                                            autoGenerated: false
                                        },
                                        brightness: 1,
                                        packed: true,
                                        overrideAlignment: "center"
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0c29vg6",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0c29vgv",
                                skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                                layout: {
                                    width: 315,
                                    height: 20,
                                    x: 291,
                                    y: 18,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.WRichText",
                                data: {
                                    type: "StyledText",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    text: `<p class=\"font_7\" style=\"text-align:center\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">${i18n.translate('multiStateBox_preset_productBadge_bestSeller_text2')}</span></p>`,
                                    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                    linkList: [],
                                    id: "dataItem-k0c29vh0"
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    brightness: 1,
                                    packed: true,
                                    id: "propItem-k0c29vh1"
                                },
                                style: "txtNew",
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "text31"
                                    }],
                                    id: "connection-k0c29vh11"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 200,
                                        height: 19,
                                        x: 30,
                                        y: 47,
                                        scale: 0.9411764705882353,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    props: {
                                        type: "WRichTextProperties",
                                        metaData: {
                                            schemaVersion: "1.0",
                                            autoGenerated: false
                                        },
                                        brightness: 1,
                                        packed: true
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0c29vgv",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0c29vhl",
                                skin: "wysiwyg.viewer.skins.button.BasicButton",
                                layout: {
                                    width: 135,
                                    height: 32,
                                    x: 734,
                                    y: 11,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.SiteButton",
                                data: {
                                    type: "LinkableButton",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    label: i18n.translate('multiStateBox_preset_productBadge_bestSeller_text3'),
                                    id: "dataItem-k0c29vhz"
                                },
                                props: {
                                    type: "ButtonProperties",
                                    metaData: {
                                        schemaVersion: "1.0"
                                    },
                                    align: "center",
                                    margin: 0,
                                    id: "propItem-k0c29vhz1"
                                },
                                style: {
                                    type: "ComponentStyle",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    style: {
                                        properties: {
                                            "alpha-bg": "1",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "0",
                                            "alpha-txt": "1",
                                            "alpha-txth": "1",
                                            bg: "#FFB32D",
                                            bgh: "#FFCC00",
                                            "boxShadowToggleOn-shd": "false",
                                            brd: "color_14",
                                            brdh: "color_12",
                                            brw: "0",
                                            fnt: "normal normal normal 15px/1.4em avenir-lt-w01_35-light1475496",
                                            rd: "30px 30px 30px 30px",
                                            shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                            txt: "color_8",
                                            txth: "color_11"
                                        },
                                        propertiesSource: {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "alpha-txt": "value",
                                            "alpha-txth": "value",
                                            bg: "value",
                                            bgh: "value",
                                            "boxShadowToggleOn-shd": "value",
                                            brd: "theme",
                                            brdh: "theme",
                                            brw: "value",
                                            fnt: "value",
                                            rd: "value",
                                            shd: "value",
                                            txt: "theme",
                                            txth: "theme"
                                        },
                                        groups: {}
                                    },
                                    componentClassName: "wysiwyg.viewer.components.SiteButton",
                                    pageId: "",
                                    compId: "",
                                    styleType: "custom",
                                    skin: "wysiwyg.viewer.skins.button.BasicButton",
                                    id: "style-k0c29vi0"
                                },
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "button21"
                                    }],
                                    id: "connection-k0c29vi01"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 140,
                                        height: 36,
                                        x: 60,
                                        y: 82,
                                        scale: 0.8,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0c29vhl",
                                        author: "duplicate"
                                    }
                                }
                            }],
                            layout: {
                                width: 898,
                                height: 57,
                                x: 41,
                                y: 27,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "mobile.core.components.Container",
                            style: {
                                type: "ComponentStyle",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                style: {
                                    properties: {
                                        "alpha-bg": "1",
                                        bg: "#FFFFFF"
                                    },
                                    propertiesSource: {
                                        bg: "value"
                                    },
                                    groups: {}
                                },
                                componentClassName: "mobile.core.components.Container",
                                pageId: "",
                                compId: "",
                                styleType: "custom",
                                skin: "wysiwyg.viewer.skins.area.RectangleArea",
                                id: "style-k0c29vg4"
                            },
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "box9"
                                }],
                                id: "connection-k0c29vg5"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 260,
                                    height: 135,
                                    x: 10,
                                    y: 10,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k0c29vfq",
                                    author: "duplicate"
                                }
                            }
                        }],
                        layout: {
                            width: 980,
                            height: 112,
                            x: 0,
                            y: 0,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: "wysiwyg.viewer.components.StateBoxState",
                        design: {
                            type: "MediaContainerDesignData",
                            metaData: {
                                isPreset: false,
                                schemaVersion: "1.0",
                                isHidden: false
                            },
                            background: {
                                type: "BackgroundMedia",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                mediaRef: {
                                    type: "Image",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    title: "Leaves",
                                    uri: "79e0a317682945f18a0ef5b281deb7d2.png",
                                    description: "public/0fe8fef2d044427d8acb157eca90e6e8/7431644314724266afbf10b9e771ebb9/all/any/all",
                                    width: 300,
                                    height: 300,
                                    alt: "",
                                    artist: {
                                        id: "",
                                        name: ""
                                    }
                                },
                                color: "#FFC55F",
                                colorOpacity: 1,
                                alignType: "center",
                                fittingType: "tile",
                                scrollType: "none",
                                colorOverlay: "",
                                colorOverlayOpacity: 0,
                                showOverlayForMediaType: "WixVideo"
                            },
                            charas: "design-k06pwrl9",
                            dataChangeBehaviors: [],
                            id: "dataItem-k0c29vfn"
                        },
                        style: "stateBoxState1",
                        connections: {
                            type: "ConnectionList",
                            items: [{
                                type: "WixCodeConnectionItem",
                                role: "BestSeller"
                            }],
                            id: "connection-k0c29vfo2"
                        },
                        mobileStructure: {
                            layout: {
                                width: 280,
                                height: 155,
                                x: 0,
                                y: 0,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            metaData: {
                                originalCompId: "comp-k0c29vfi",
                                author: "duplicate"
                            }
                        }
                    }, {
                        type: "Container",
                        id: "comp-k0c29vju",
                        components: [{
                            type: "Container",
                            id: "comp-k0c29vk4",
                            components: [{
                                type: "Component",
                                id: "comp-k0c29vke",
                                skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                                layout: {
                                    width: 254,
                                    height: 33,
                                    x: 19,
                                    y: 12,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.WRichText",
                                data: {
                                    type: "StyledText",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    text: `<h6 class=\"font_6\" style=\"font-size:25px\"><span style=\"font-size:25px\"><span style=\"font-weight:bold\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"color:#000000\">${i18n.translate('multiStateBox_preset_productBadge_sale_text1')}</span></span></span></span></h6>`,
                                    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                    linkList: [],
                                    id: "dataItem-k0c29vkk"
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    brightness: 1,
                                    packed: true,
                                    id: "propItem-k0c29vkl"
                                },
                                style: "txtNew",
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "text97"
                                    }],
                                    id: "connection-k0c29vkl1"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 237,
                                        height: 27,
                                        x: 11,
                                        y: 14,
                                        scale: 0.8,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    props: {
                                        type: "WRichTextProperties",
                                        metaData: {
                                            schemaVersion: "1.0",
                                            autoGenerated: false
                                        },
                                        brightness: 1,
                                        packed: true,
                                        overrideAlignment: "center"
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0c29vke",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0c29vl8",
                                skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                                layout: {
                                    width: 287,
                                    height: 20,
                                    x: 306,
                                    y: 18,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.WRichText",
                                data: {
                                    type: "StyledText",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    text: `<p class=\"font_7\" style=\"text-align:center\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">${i18n.translate('multiStateBox_preset_productBadge_sale_text2')}</span></p>`,
                                    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                    linkList: [],
                                    id: "dataItem-k0c29vle"
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    brightness: 1,
                                    packed: true,
                                    id: "propItem-k0c29vle1"
                                },
                                style: "txtNew",
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "text98"
                                    }],
                                    id: "connection-k0c29vlf"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 237,
                                        height: 19,
                                        x: 10,
                                        y: 47,
                                        scale: 0.9411764705882353,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    props: {
                                        type: "WRichTextProperties",
                                        metaData: {
                                            schemaVersion: "1.0",
                                            autoGenerated: false
                                        },
                                        brightness: 1,
                                        packed: true
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0c29vl8",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0c29vmc",
                                skin: "wysiwyg.viewer.skins.button.BasicButton",
                                layout: {
                                    width: 135,
                                    height: 32,
                                    x: 734,
                                    y: 11,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.SiteButton",
                                data: {
                                    type: "LinkableButton",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    label: i18n.translate('multiStateBox_preset_productBadge_sale_text3'),
                                    id: "dataItem-k0c29vmk"
                                },
                                props: {
                                    type: "ButtonProperties",
                                    metaData: {
                                        schemaVersion: "1.0"
                                    },
                                    align: "center",
                                    margin: 0,
                                    id: "propItem-k0c29vmk1"
                                },
                                style: {
                                    type: "ComponentStyle",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    style: {
                                        properties: {
                                            "alpha-bg": "1",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "0",
                                            "alpha-txt": "1",
                                            "alpha-txth": "1",
                                            bg: "#78C3AE",
                                            bgh: "#78E1BE",
                                            "boxShadowToggleOn-shd": "false",
                                            brd: "color_14",
                                            brdh: "color_12",
                                            brw: "0",
                                            fnt: "normal normal normal 15px/1.4em avenir-lt-w01_35-light1475496",
                                            rd: "30px 30px 30px 30px",
                                            shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                            txt: "color_8",
                                            txth: "color_11"
                                        },
                                        propertiesSource: {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "alpha-txt": "value",
                                            "alpha-txth": "value",
                                            bg: "value",
                                            bgh: "value",
                                            "boxShadowToggleOn-shd": "value",
                                            brd: "theme",
                                            brdh: "theme",
                                            brw: "value",
                                            fnt: "value",
                                            rd: "value",
                                            shd: "value",
                                            txt: "theme",
                                            txth: "theme"
                                        },
                                        groups: {}
                                    },
                                    componentClassName: "wysiwyg.viewer.components.SiteButton",
                                    pageId: "",
                                    compId: "",
                                    styleType: "custom",
                                    skin: "wysiwyg.viewer.skins.button.BasicButton",
                                    id: "style-k0c29vmk2"
                                },
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "button22"
                                    }],
                                    id: "connection-k0c29vml"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 140,
                                        height: 36,
                                        x: 58,
                                        y: 82,
                                        scale: 0.8,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0c29vmc",
                                        author: "duplicate"
                                    }
                                }
                            }],
                            layout: {
                                width: 898,
                                height: 57,
                                x: 41,
                                y: 27,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "mobile.core.components.Container",
                            style: {
                                type: "ComponentStyle",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                style: {
                                    properties: {
                                        "alpha-bg": "1",
                                        bg: "#FFFFFF"
                                    },
                                    propertiesSource: {
                                        bg: "value"
                                    },
                                    groups: {}
                                },
                                componentClassName: "mobile.core.components.Container",
                                pageId: "",
                                compId: "",
                                styleType: "custom",
                                skin: "wysiwyg.viewer.skins.area.RectangleArea",
                                id: "style-k0c29vkb"
                            },
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "box38"
                                }],
                                id: "connection-k0c29vkc"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 260,
                                    height: 135,
                                    x: 10,
                                    y: 10,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k0c29vk4",
                                    author: "duplicate"
                                }
                            }
                        }],
                        layout: {
                            width: 980,
                            height: 112,
                            x: 0,
                            y: 0,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: "wysiwyg.viewer.components.StateBoxState",
                        design: {
                            type: "MediaContainerDesignData",
                            metaData: {
                                isPreset: false,
                                schemaVersion: "1.0",
                                isHidden: false
                            },
                            background: {
                                type: "BackgroundMedia",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                mediaRef: {
                                    type: "Image",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    title: "Yellow Leaves",
                                    uri: "60cfe1187df7455ba5093c9504679286.png",
                                    description: "public/0fe8fef2d044427d8acb157eca90e6e8/7431644314724266afbf10b9e771ebb9/all/any/all",
                                    width: 500,
                                    height: 500,
                                    alt: "",
                                    artist: {
                                        id: "",
                                        name: ""
                                    },
                                    opacity: 0.5
                                },
                                color: "#78C3AE",
                                colorOpacity: 1,
                                alignType: "center",
                                fittingType: "tile",
                                scrollType: "none",
                                colorOverlay: "",
                                colorOverlayOpacity: 0,
                                showOverlayForMediaType: "WixVideo"
                            },
                            charas: "design-k06qa13r",
                            dataChangeBehaviors: [],
                            id: "dataItem-k0c29vk1"
                        },
                        style: "stateBoxState1",
                        connections: {
                            type: "ConnectionList",
                            items: [{
                                type: "WixCodeConnectionItem",
                                role: "Sale"
                            }],
                            id: "connection-k0c29vk2"
                        },
                        mobileStructure: {
                            layout: {
                                width: 280,
                                height: 153,
                                x: 0,
                                y: 0,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            metaData: {
                                originalCompId: "comp-k0c29vju",
                                author: "duplicate"
                            }
                        }
                    }, {
                        type: "Container",
                        id: "comp-k0c29von",
                        components: [{
                            type: "Container",
                            id: "comp-k0c29vox",
                            components: [{
                                type: "Component",
                                id: "comp-k0c29vp7",
                                skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                                layout: {
                                    width: 254,
                                    height: 29,
                                    x: 17,
                                    y: 14,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.WRichText",
                                data: {
                                    type: "StyledText",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    text: `<h6 class=\"font_6\" style=\"font-size:25px\"><span style=\"font-family:baskervillemtw01-smbdit,serif;\"><span style=\"font-size:25px\"><span style=\"font-weight:bold\"><span style=\"color:#000000\">${i18n.translate('multiStateBox_preset_productBadge_new_text1')}</span></span></span></span></h6>`,
                                    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                    linkList: [],
                                    id: "dataItem-k0c29vpe"
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    brightness: 1,
                                    packed: true,
                                    id: "propItem-k0c29vpf"
                                },
                                style: "txtNew",
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "text99"
                                    }],
                                    id: "connection-k0c29vpf1"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 200,
                                        height: 23,
                                        x: 28,
                                        y: 14,
                                        scale: 0.8,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    props: {
                                        type: "WRichTextProperties",
                                        metaData: {
                                            schemaVersion: "1.0",
                                            autoGenerated: false
                                        },
                                        brightness: 1,
                                        packed: true,
                                        overrideAlignment: "center"
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0c29vp7",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0c29vqd",
                                skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                                layout: {
                                    width: 329,
                                    height: 20,
                                    x: 284,
                                    y: 18,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.WRichText",
                                data: {
                                    type: "StyledText",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    text: `<p class=\"font_7\" style=\"text-align:center\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">${i18n.translate('multiStateBox_preset_productBadge_new_text2')}</span></p>`,
                                    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                    linkList: [],
                                    id: "dataItem-k0c29vqk"
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    brightness: 1,
                                    packed: true,
                                    id: "propItem-k0c29vqk1"
                                },
                                style: "txtNew",
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "text100"
                                    }],
                                    id: "connection-k0c29vql"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 244,
                                        height: 19,
                                        x: 6,
                                        y: 47,
                                        scale: 0.9411764705882353,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    props: {
                                        type: "WRichTextProperties",
                                        metaData: {
                                            schemaVersion: "1.0",
                                            autoGenerated: false
                                        },
                                        brightness: 1,
                                        packed: true
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0c29vqd",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0c29vrb",
                                skin: "wysiwyg.viewer.skins.button.BasicButton",
                                layout: {
                                    width: 135,
                                    height: 32,
                                    x: 734,
                                    y: 11,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.SiteButton",
                                data: {
                                    type: "LinkableButton",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    label: i18n.translate('multiStateBox_preset_productBadge_new_text3'),
                                    id: "dataItem-k0c29vri"
                                },
                                props: {
                                    type: "ButtonProperties",
                                    metaData: {
                                        schemaVersion: "1.0"
                                    },
                                    align: "center",
                                    margin: 0,
                                    id: "propItem-k0c29vri1"
                                },
                                style: {
                                    type: "ComponentStyle",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    style: {
                                        properties: {
                                            "alpha-bg": "1",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "0",
                                            "alpha-txt": "1",
                                            "alpha-txth": "1",
                                            bg: "#EF7373",
                                            bgh: "#FF7575",
                                            "boxShadowToggleOn-shd": "false",
                                            brd: "color_14",
                                            brdh: "color_12",
                                            brw: "0",
                                            fnt: "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                            rd: "30px 30px 30px 30px",
                                            shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                            txt: "#FFFFFF",
                                            txth: "#FFFFFF"
                                        },
                                        propertiesSource: {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "alpha-txt": "value",
                                            "alpha-txth": "value",
                                            bg: "value",
                                            bgh: "value",
                                            "boxShadowToggleOn-shd": "value",
                                            brd: "theme",
                                            brdh: "theme",
                                            brw: "value",
                                            fnt: "value",
                                            rd: "value",
                                            shd: "value",
                                            txt: "value",
                                            txth: "value"
                                        },
                                        groups: {}
                                    },
                                    componentClassName: "wysiwyg.viewer.components.SiteButton",
                                    pageId: "",
                                    compId: "",
                                    styleType: "custom",
                                    skin: "wysiwyg.viewer.skins.button.BasicButton",
                                    id: "style-k0c29vri2"
                                },
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "button23"
                                    }],
                                    id: "connection-k0c29vrj"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 140,
                                        height: 36,
                                        x: 58,
                                        y: 89,
                                        scale: 0.8619047619047618,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0c29vrb",
                                        author: "duplicate"
                                    }
                                }
                            }],
                            layout: {
                                width: 898,
                                height: 57,
                                x: 41,
                                y: 27,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "mobile.core.components.Container",
                            style: {
                                type: "ComponentStyle",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                style: {
                                    properties: {
                                        "alpha-bg": "1",
                                        bg: "#FFFFFF"
                                    },
                                    propertiesSource: {
                                        bg: "value"
                                    },
                                    groups: {}
                                },
                                componentClassName: "mobile.core.components.Container",
                                pageId: "",
                                compId: "",
                                styleType: "custom",
                                skin: "wysiwyg.viewer.skins.area.RectangleArea",
                                id: "style-k0c29vp5"
                            },
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "box39"
                                }],
                                id: "connection-k0c29vp51"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 260,
                                    height: 135,
                                    x: 10,
                                    y: 10,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k0c29vox",
                                    author: "duplicate"
                                }
                            }
                        }],
                        layout: {
                            width: 980,
                            height: 112,
                            x: 0,
                            y: 0,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: "wysiwyg.viewer.components.StateBoxState",
                        design: {
                            type: "MediaContainerDesignData",
                            metaData: {
                                isPreset: false,
                                schemaVersion: "1.0",
                                isHidden: false
                            },
                            background: {
                                type: "BackgroundMedia",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                mediaRef: {
                                    type: "Image",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    title: "Flower Buds",
                                    uri: "f9b9d0b90d514da8b7f6d3cc07218673.png",
                                    description: "public/0fe8fef2d044427d8acb157eca90e6e8/7431644314724266afbf10b9e771ebb9/all/any/all",
                                    width: 300,
                                    height: 300,
                                    alt: "",
                                    artist: {
                                        id: "",
                                        name: ""
                                    }
                                },
                                color: "#EF7373",
                                colorOpacity: 1,
                                alignType: "center",
                                fittingType: "tile",
                                scrollType: "none",
                                colorOverlay: "",
                                colorOverlayOpacity: 0,
                                showOverlayForMediaType: "WixVideo"
                            },
                            charas: "design-k0b28kvs",
                            dataChangeBehaviors: [],
                            id: "dataItem-k0c29vou"
                        },
                        style: "stateBoxState1",
                        connections: {
                            type: "ConnectionList",
                            items: [{
                                type: "WixCodeConnectionItem",
                                role: "New"
                            }],
                            id: "connection-k0c29vou3"
                        },
                        mobileStructure: {
                            layout: {
                                width: 280,
                                height: 155,
                                x: 0,
                                y: 0,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            metaData: {
                                originalCompId: "comp-k0c29von",
                                author: "duplicate"
                            }
                        }
                    }, {
                        type: "Container",
                        id: "comp-k0c29vtw",
                        components: [{
                            type: "Container",
                            id: "comp-k0c29vu8",
                            components: [{
                                type: "Component",
                                id: "comp-k0c29vuh",
                                skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                                layout: {
                                    width: 254,
                                    height: 33,
                                    x: 19,
                                    y: 12,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.WRichText",
                                data: {
                                    type: "StyledText",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    text: `<h6 class=\"font_6\" style=\"font-size:25px\"><span style=\"font-size:25px\"><span style=\"font-weight:bold\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"color:#000000\">${i18n.translate('multiStateBox_preset_productBadge_OutOfStock_text1')}</span></span></span></span></h6>`,
                                    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                    linkList: [],
                                    id: "dataItem-k0c29vuo"
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    brightness: 1,
                                    packed: true,
                                    id: "propItem-k0c29vuo1"
                                },
                                style: "txtNew",
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "text103"
                                    }],
                                    id: "connection-k0c29vup1"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 235,
                                        height: 27,
                                        x: 12,
                                        y: 14,
                                        scale: 0.8,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    props: {
                                        type: "WRichTextProperties",
                                        metaData: {
                                            schemaVersion: "1.0",
                                            autoGenerated: false
                                        },
                                        brightness: 1,
                                        packed: true,
                                        overrideAlignment: "center"
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0c29vuh",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0c29vvv",
                                skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                                layout: {
                                    width: 329,
                                    height: 20,
                                    x: 284,
                                    y: 18,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.WRichText",
                                data: {
                                    type: "StyledText",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    text: `<p class=\"font_7\" style=\"text-align:center\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">${i18n.translate('multiStateBox_preset_productBadge_OutOfStock_text2')}</span></p>`,
                                    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                    linkList: [],
                                    id: "dataItem-k0c29vw2"
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: false
                                    },
                                    brightness: 1,
                                    packed: true,
                                    id: "propItem-k0c29vw3"
                                },
                                style: "txtNew",
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "text104"
                                    }],
                                    id: "connection-k0c29vw32"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 229,
                                        height: 19,
                                        x: 15,
                                        y: 47,
                                        scale: 0.9411764705882353,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    props: {
                                        type: "WRichTextProperties",
                                        metaData: {
                                            schemaVersion: "1.0",
                                            autoGenerated: false
                                        },
                                        brightness: 1,
                                        packed: true
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0c29vvv",
                                        author: "duplicate"
                                    }
                                }
                            }, {
                                type: "Component",
                                id: "comp-k0c29vwu",
                                skin: "wysiwyg.viewer.skins.button.BasicButton",
                                layout: {
                                    width: 135,
                                    height: 32,
                                    x: 734,
                                    y: 11,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: "wysiwyg.viewer.components.SiteButton",
                                data: {
                                    type: "LinkableButton",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    label: i18n.translate('multiStateBox_preset_productBadge_OutOfStock_text3'),
                                    id: "dataItem-k0c29vx1"
                                },
                                props: {
                                    type: "ButtonProperties",
                                    metaData: {
                                        schemaVersion: "1.0"
                                    },
                                    align: "center",
                                    margin: 0,
                                    id: "propItem-k0c29vx11"
                                },
                                style: {
                                    type: "ComponentStyle",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    style: {
                                        properties: {
                                            "alpha-bg": "1",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "0",
                                            "alpha-txt": "1",
                                            "alpha-txth": "1",
                                            bg: "#8653F5",
                                            bgh: "#8653F5",
                                            "boxShadowToggleOn-shd": "false",
                                            brd: "color_14",
                                            brdh: "color_12",
                                            brw: "0",
                                            fnt: "font_8",
                                            rd: "30px 30px 30px 30px",
                                            shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                            txt: "color_8",
                                            txth: "color_11"
                                        },
                                        propertiesSource: {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "alpha-txt": "value",
                                            "alpha-txth": "value",
                                            bg: "value",
                                            bgh: "value",
                                            "boxShadowToggleOn-shd": "value",
                                            brd: "theme",
                                            brdh: "theme",
                                            brw: "value",
                                            fnt: "theme",
                                            rd: "value",
                                            shd: "value",
                                            txt: "theme",
                                            txth: "theme"
                                        },
                                        groups: {}
                                    },
                                    componentClassName: "wysiwyg.viewer.components.SiteButton",
                                    pageId: "",
                                    compId: "",
                                    styleType: "custom",
                                    skin: "wysiwyg.viewer.skins.button.BasicButton",
                                    id: "style-k0c29vx21"
                                },
                                connections: {
                                    type: "ConnectionList",
                                    items: [{
                                        type: "WixCodeConnectionItem",
                                        role: "button26"
                                    }],
                                    id: "connection-k0c29vx22"
                                },
                                mobileStructure: {
                                    layout: {
                                        width: 140,
                                        height: 36,
                                        x: 59,
                                        y: 82,
                                        scale: 0.8,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    metaData: {
                                        originalCompId: "comp-k0c29vwu",
                                        author: "duplicate"
                                    }
                                }
                            }],
                            layout: {
                                width: 898,
                                height: 57,
                                x: 41,
                                y: 27,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "mobile.core.components.Container",
                            style: {
                                type: "ComponentStyle",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                style: {
                                    properties: {
                                        "alpha-bg": "1",
                                        bg: "#FFFFFF"
                                    },
                                    propertiesSource: {
                                        bg: "value"
                                    },
                                    groups: {}
                                },
                                componentClassName: "mobile.core.components.Container",
                                pageId: "",
                                compId: "",
                                styleType: "custom",
                                skin: "wysiwyg.viewer.skins.area.RectangleArea",
                                id: "style-k0c29vue1"
                            },
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "box48"
                                }],
                                id: "connection-k0c29vuf"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 260,
                                    height: 135,
                                    x: 10,
                                    y: 10,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k0c29vu8",
                                    author: "duplicate"
                                }
                            }
                        }],
                        layout: {
                            width: 980,
                            height: 112,
                            x: 0,
                            y: 0,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: "wysiwyg.viewer.components.StateBoxState",
                        design: {
                            type: "MediaContainerDesignData",
                            metaData: {
                                isPreset: false,
                                schemaVersion: "1.0",
                                isHidden: false
                            },
                            background: {
                                type: "BackgroundMedia",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                mediaRef: {
                                    type: "Image",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false
                                    },
                                    title: "Green Branches",
                                    uri: "433673a85ea041169f9822a38dca01d6.png",
                                    description: "public/0fe8fef2d044427d8acb157eca90e6e8/7431644314724266afbf10b9e771ebb9/all/any/all",
                                    width: 500,
                                    height: 500,
                                    alt: "",
                                    artist: {
                                        id: "",
                                        name: ""
                                    }
                                },
                                color: "#8653F5",
                                colorOpacity: 1,
                                alignType: "center",
                                fittingType: "tile",
                                scrollType: "none",
                                colorOverlay: "",
                                colorOverlayOpacity: 0,
                                showOverlayForMediaType: "WixVideo"
                            },
                            charas: "design-k0c211e6",
                            dataChangeBehaviors: [],
                            id: "dataItem-k0c29vu4"
                        },
                        style: "stateBoxState1",
                        connections: {
                            type: "ConnectionList",
                            items: [{
                                type: "WixCodeConnectionItem",
                                role: "OutOfStock"
                            }],
                            id: "connection-k0c29vu51"
                        },
                        mobileStructure: {
                            layout: {
                                width: 280,
                                height: 155,
                                x: 0,
                                y: 0,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            metaData: {
                                originalCompId: "comp-k0c29vtw",
                                author: "duplicate"
                            }
                        }
                    }],
                    skin: "wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin",
                    layout: {
                        width: 980,
                        height: 112,
                        x: 33,
                        y: 748,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wysiwyg.viewer.components.StateBox",
                    props: {
                        type: "StateBoxProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        transition: "OutIn",
                        transDuration: 1,
                        id: "propItem-k0c29vfc"
                    },
                    style: {
                        type: "ComponentStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            properties: {
                                "alpha-brd": "0.44",
                                "boxShadowToggleOn-shd": "false",
                                brd: "color_28",
                                brw: "0",
                                shd: "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            propertiesSource: {
                                "alpha-brd": "value",
                                "boxShadowToggleOn-shd": "value",
                                brd: "theme",
                                brw: "value",
                                shd: "value"
                            },
                            groups: {}
                        },
                        componentClassName: "wysiwyg.viewer.components.StateBox",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin",
                        id: "style-k0c29vfd"
                    },
                    connections: {
                        type: "ConnectionList",
                        items: [{
                            type: "WixCodeConnectionItem",
                            role: "BannerStateBox"
                        }],
                        id: "connection-k0c29vfe"
                    },
                    mobileStructure: {
                        layout: {
                            width: 280,
                            height: 155,
                            x: 14,
                            y: 656,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        metaData: {
                            originalCompId: "comp-k0c29veg",
                            author: "duplicate"
                        }
                    },
                    activeModes: {}
                },
                preset: {
                    rect: {
                        width: 304,
                        height: 94,
                        x: 10,
                        y: 205
                    },
                    label: "add_preset_multiStateBox_2",
                    tags: null
                }
            },
            {
                id: "StateBox_StateBox_3",
                structure: {
                    type: "Container",
                    id: "comp-k06qi0rk",
                    components: [{
                        type: "Container",
                        id: "comp-k06qi0ry",
                        components: [{
                            type: "Component",
                            id: "comp-k06qi0s6",
                            skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                            layout: {
                                width: 378,
                                height: 48,
                                x: 301,
                                y: 32,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.WRichText",
                            data: {
                                type: "StyledText",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                text: `<h2 class=\"font_2\" style=\"font-size: 40px; text-align: center;\"><span style=\"font-size:40px;\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"color:#EF7373\"><span style=\"letter-spacing:0em\">${i18n.translate('multiStateBox_preset_emptyFullState_full_title')}</span></span></span></span></h2>`,
                                stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                linkList: [],
                                id: "dataItem-k06qi0sb"
                            },
                            props: {
                                type: "WRichTextProperties",
                                metaData: {
                                    schemaVersion: "1.0",
                                    autoGenerated: false
                                },
                                brightness: 1,
                                packed: false,
                                minHeight: 48,
                                id: "propItem-k06qi0sb1"
                            },
                            style: "txtNew",
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "text91"
                                }],
                                id: "connection-k06qi0sc"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 220,
                                    height: 28,
                                    x: 28,
                                    y: 19,
                                    scale: 0.8,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: true
                                    },
                                    brightness: 1,
                                    packed: false,
                                    minHeight: null
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi0s6",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Component",
                            id: "comp-k06qi0t3",
                            skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                            layout: {
                                width: 175,
                                height: 31,
                                x: 68,
                                y: 154,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.WRichText",
                            data: {
                                type: "StyledText",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                text: `<p class=\"font_8\" style=\"font-size:22px; line-height:1.4em\"><span style=\"font-size:22px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#3A344D\"><span style=\"letter-spacing:0em\">${i18n.translate('multiStateBox_preset_emptyFullState_full_text1')}</span></span></span></span></p>`,
                                stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                linkList: [],
                                id: "dataItem-k06qi0t8"
                            },
                            props: {
                                type: "WRichTextProperties",
                                metaData: {
                                    schemaVersion: "1.0",
                                    autoGenerated: false
                                },
                                brightness: 1,
                                packed: false,
                                minHeight: 31,
                                id: "propItem-k06qi0t9"
                            },
                            style: "txtNew",
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "text92"
                                }],
                                id: "connection-k06qi0t91"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 120,
                                    height: 28,
                                    x: 13,
                                    y: 84,
                                    scale: 0.9090909090909091,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: true
                                    },
                                    brightness: 1,
                                    packed: false,
                                    minHeight: null
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi0t3",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Component",
                            id: "comp-k06qi0tv",
                            skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                            layout: {
                                width: 175,
                                height: 25,
                                x: 738,
                                y: 157,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.WRichText",
                            data: {
                                type: "StyledText",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                text: `<p class=\"font_8\" style=\"font-size:18px; line-height:1.4em; text-align:right\"><span style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#3A344D\"><span style=\"letter-spacing:0em\">${i18n.translate('multiStateBox_preset_emptyFullState_full_text4')}</span></span></span></span></p>`,
                                stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                linkList: [],
                                id: "dataItem-k06qi0u0"
                            },
                            props: {
                                type: "WRichTextProperties",
                                metaData: {
                                    schemaVersion: "1.0",
                                    autoGenerated: false
                                },
                                brightness: 1,
                                packed: false,
                                minHeight: 25,
                                id: "propItem-k06qi0u01"
                            },
                            style: "txtNew",
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "text94"
                                }],
                                id: "connection-k06qi0u1"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 100,
                                    height: 25,
                                    x: 163,
                                    y: 87,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: true
                                    },
                                    brightness: 1,
                                    packed: false,
                                    minHeight: null
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi0tv",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Component",
                            id: "comp-k06qi0us",
                            layout: {
                                width: 848,
                                height: 5,
                                x: 66,
                                y: 206,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.FiveGridLine",
                            props: {
                                type: "FiveGridLineProperties",
                                metaData: {
                                    schemaVersion: "1.0"
                                },
                                fullScreenModeOn: false,
                                id: "propItem-k06qi0ux"
                            },
                            style: {
                                type: "ComponentStyle",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                style: {
                                    properties: {
                                        "alpha-brd": "1",
                                        brd: "#000000",
                                        lnw: "1"
                                    },
                                    propertiesSource: {
                                        "alpha-brd": "value",
                                        brd: "value",
                                        lnw: "value"
                                    },
                                    groups: {}
                                },
                                componentClassName: "wysiwyg.viewer.components.FiveGridLine",
                                pageId: "",
                                compId: "",
                                styleType: "custom",
                                skin: "wysiwyg.viewer.skins.line.SolidLine",
                                id: "style-k06qi0ux1"
                            },
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "line1"
                                }],
                                id: "connection-k06qi0ux2"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 250,
                                    height: 5,
                                    x: 13,
                                    y: 124,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi0us",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Component",
                            id: "comp-k06qi0vi",
                            skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                            layout: {
                                width: 175,
                                height: 31,
                                x: 66,
                                y: 253,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.WRichText",
                            data: {
                                type: "StyledText",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                text: `<p class=\"font_8\" style=\"font-size: 22px; line-height: 1.4em;\"><span style=\"font-size:22px;\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#3A344D\"><span style=\"letter-spacing:0em\">${i18n.translate('multiStateBox_preset_emptyFullState_full_text2')}</span></span></span></span></p>`,
                                stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                linkList: [],
                                id: "dataItem-k06qi0vo"
                            },
                            props: {
                                type: "WRichTextProperties",
                                metaData: {
                                    schemaVersion: "1.0",
                                    autoGenerated: false
                                },
                                brightness: 1,
                                packed: false,
                                minHeight: 31,
                                id: "propItem-k06qi0vp"
                            },
                            style: "txtNew",
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "text95"
                                }],
                                id: "connection-k06qi0vp1"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 90,
                                    height: 28,
                                    x: 15,
                                    y: 157,
                                    scale: 0.9090909090909091,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: true
                                    },
                                    brightness: 1,
                                    packed: false,
                                    minHeight: null
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi0vi",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Component",
                            id: "comp-k06qi0wf",
                            skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                            layout: {
                                width: 175,
                                height: 25,
                                x: 738,
                                y: 259,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.WRichText",
                            data: {
                                type: "StyledText",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                text: `<p class=\"font_8\" style=\"font-size:18px; line-height:1.4em; text-align:right\"><span style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#3A344D\"><span style=\"letter-spacing:0em\">${i18n.translate('multiStateBox_preset_emptyFullState_full_text5')}</span></span></span></span></p>`,
                                stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                linkList: [],
                                id: "dataItem-k06qi0wt"
                            },
                            props: {
                                type: "WRichTextProperties",
                                metaData: {
                                    schemaVersion: "1.0",
                                    autoGenerated: false
                                },
                                brightness: 1,
                                packed: false,
                                minHeight: 24,
                                id: "propItem-k06qi0wu"
                            },
                            style: "txtNew",
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "text96"
                                }],
                                id: "connection-k06qi0wu1"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 63,
                                    height: 25,
                                    x: 200,
                                    y: 160,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: true
                                    },
                                    brightness: 1,
                                    packed: false,
                                    minHeight: null
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi0wf",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Component",
                            id: "comp-k06qi0xj",
                            layout: {
                                width: 848,
                                height: 5,
                                x: 66,
                                y: 305,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.FiveGridLine",
                            props: {
                                type: "FiveGridLineProperties",
                                metaData: {
                                    schemaVersion: "1.0"
                                },
                                fullScreenModeOn: false,
                                id: "propItem-k06qi0xo"
                            },
                            style: {
                                type: "ComponentStyle",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                style: {
                                    properties: {
                                        "alpha-brd": "1",
                                        brd: "#000000",
                                        lnw: "1"
                                    },
                                    propertiesSource: {
                                        "alpha-brd": "value",
                                        brd: "value",
                                        lnw: "value"
                                    },
                                    groups: {}
                                },
                                componentClassName: "wysiwyg.viewer.components.FiveGridLine",
                                pageId: "",
                                compId: "",
                                styleType: "custom",
                                skin: "wysiwyg.viewer.skins.line.SolidLine",
                                id: "style-k06qi0xp"
                            },
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "line2"
                                }],
                                id: "connection-k06qi0xp1"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 250,
                                    height: 5,
                                    x: 15,
                                    y: 197,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi0xj",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Component",
                            id: "comp-k06qi0y8",
                            skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                            layout: {
                                width: 175,
                                height: 31,
                                x: 66,
                                y: 362,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.WRichText",
                            data: {
                                type: "StyledText",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                text: `<p class=\"font_8\" style=\"font-size: 22px; line-height: 1.4em;\"><span style=\"font-size:22px;\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#3A344D\"><span style=\"letter-spacing:0em\">${i18n.translate('multiStateBox_preset_emptyFullState_full_text3')}</span></span></span></span></p>`,
                                stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                linkList: [],
                                id: "dataItem-k06qi0ye"
                            },
                            props: {
                                type: "WRichTextProperties",
                                metaData: {
                                    schemaVersion: "1.0",
                                    autoGenerated: false
                                },
                                brightness: 1,
                                packed: false,
                                minHeight: 31,
                                id: "propItem-k06qi0ye1"
                            },
                            style: "txtNew",
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "text93"
                                }],
                                id: "connection-k06qi0yf"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 130,
                                    height: 26,
                                    x: 15,
                                    y: 234,
                                    scale: 0.8636363636363636,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: true
                                    },
                                    brightness: 1,
                                    packed: false,
                                    minHeight: null
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi0y8",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Component",
                            id: "comp-k06qi0z3",
                            skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                            layout: {
                                width: 175,
                                height: 25,
                                x: 738,
                                y: 368,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.WRichText",
                            data: {
                                type: "StyledText",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                text: `<p class=\"font_8\" style=\"font-size:18px; line-height:1.4em; text-align:right\"><span style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#3A344D\"><span style=\"letter-spacing:0em\">${i18n.translate('multiStateBox_preset_emptyFullState_full_text6')}</span></span></span></span></p>`,
                                stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                linkList: [],
                                id: "dataItem-k06qi0z8"
                            },
                            props: {
                                type: "WRichTextProperties",
                                metaData: {
                                    schemaVersion: "1.0",
                                    autoGenerated: false
                                },
                                brightness: 1,
                                packed: false,
                                minHeight: 24,
                                id: "propItem-k06qi0z9"
                            },
                            style: "txtNew",
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "text101"
                                }],
                                id: "connection-k06qi0z91"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 49,
                                    height: 25,
                                    x: 214,
                                    y: 235,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: true
                                    },
                                    brightness: 1,
                                    packed: false,
                                    minHeight: null
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi0z3",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Component",
                            id: "comp-k06qi0zt",
                            skin: "wysiwyg.viewer.skins.line.SolidLine",
                            layout: {
                                width: 848,
                                height: 5,
                                x: 66,
                                y: 414,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.FiveGridLine",
                            props: {
                                type: "FiveGridLineProperties",
                                metaData: {
                                    schemaVersion: "1.0"
                                },
                                fullScreenModeOn: false,
                                id: "propItem-k06qi0zy"
                            },
                            style: {
                                type: "ComponentStyle",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                style: {
                                    properties: {
                                        "alpha-brd": "1",
                                        brd: "#000000",
                                        lnw: "1"
                                    },
                                    propertiesSource: {
                                        "alpha-brd": "value",
                                        brd: "value",
                                        lnw: "value"
                                    },
                                    groups: {}
                                },
                                componentClassName: "wysiwyg.viewer.components.FiveGridLine",
                                pageId: "",
                                compId: "",
                                styleType: "custom",
                                skin: "wysiwyg.viewer.skins.line.SolidLine",
                                id: "style-k06qi0zy1"
                            },
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "line3"
                                }],
                                id: "connection-k06qi0zz"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 250,
                                    height: 5,
                                    x: 15,
                                    y: 272,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi0zt",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Component",
                            id: "comp-k06qi10o",
                            skin: "wysiwyg.viewer.skins.button.BasicButton",
                            layout: {
                                width: 215,
                                height: 38,
                                x: 382,
                                y: 476,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.SiteButton",
                            data: {
                                type: "LinkableButton",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                label: i18n.translate('multiStateBox_preset_emptyFullState_full_button'),
                                id: "dataItem-k06qi10t"
                            },
                            props: {
                                type: "ButtonProperties",
                                metaData: {
                                    schemaVersion: "1.0"
                                },
                                align: "center",
                                margin: 0,
                                id: "propItem-k06qi10t1"
                            },
                            style: {
                                type: "ComponentStyle",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                style: {
                                    properties: {
                                        "alpha-bg": "1",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "0",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        bg: "#EF7373",
                                        bgh: "#FF7575",
                                        "boxShadowToggleOn-shd": "false",
                                        brd: "color_14",
                                        brdh: "color_12",
                                        brw: "0",
                                        fnt: "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                        rd: "30px 30px 30px 30px",
                                        shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                        txt: "#FFFFFF",
                                        txth: "#FFFFFF"
                                    },
                                    propertiesSource: {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "alpha-txt": "value",
                                        "alpha-txth": "value",
                                        bg: "value",
                                        bgh: "value",
                                        "boxShadowToggleOn-shd": "value",
                                        brd: "theme",
                                        brdh: "theme",
                                        brw: "value",
                                        fnt: "value",
                                        rd: "value",
                                        shd: "value",
                                        txt: "value",
                                        txth: "value"
                                    },
                                    groups: {}
                                },
                                componentClassName: "wysiwyg.viewer.components.SiteButton",
                                pageId: "",
                                compId: "",
                                styleType: "custom",
                                skin: "wysiwyg.viewer.skins.button.BasicButton",
                                id: "style-k06qi10u"
                            },
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "button24"
                                }],
                                id: "connection-k06qi10u1"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 200,
                                    height: 42,
                                    x: 40,
                                    y: 314,
                                    scale: 0.9333333333333333,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi10o",
                                    author: "duplicate"
                                }
                            }
                        }],
                        layout: {
                            width: 980,
                            height: 566,
                            x: 0,
                            y: 0,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: "wysiwyg.viewer.components.StateBoxState",
                        design: {
                            type: "MediaContainerDesignData",
                            metaData: {
                                isPreset: false,
                                schemaVersion: "1.0",
                                isHidden: false
                            },
                            background: {
                                type: "BackgroundMedia",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                color: "#D4EEEF",
                                colorOpacity: 1,
                                alignType: "center",
                                fittingType: "fill",
                                scrollType: "none",
                                colorOverlay: "",
                                colorOverlayOpacity: 0,
                                showOverlayForMediaType: "WixVideo"
                            },
                            charas: "design-jzgqtjqp",
                            dataChangeBehaviors: [],
                            id: "dataItem-k06qi0s3"
                        },
                        style: "stateBoxState1",
                        connections: {
                            type: "ConnectionList",
                            items: [{
                                type: "WixCodeConnectionItem",
                                role: "Full"
                            }],
                            id: "connection-k06qi0s41"
                        },
                        mobileStructure: {
                            layout: {
                                width: 280,
                                height: 391,
                                x: 0,
                                y: 0,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            metaData: {
                                originalCompId: "comp-k06qi0ry",
                                author: "duplicate"
                            }
                        }
                    }, {
                        type: "Container",
                        id: "comp-k06qi122",
                        components: [{
                            type: "Component",
                            id: "comp-k06qi12c",
                            skin: "skins.viewer.VectorImageSkin",
                            layout: {
                                width: 302,
                                height: 169,
                                x: 340,
                                y: 154,
                                scale: 1,
                                rotationInDegrees: 137.66670029612112,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.VectorImage",
                            data: {
                                type: "VectorImage",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                svgId: "a3097bb41da44826b6354f37334715cc.svg",
                                id: "dataItem-k06qi12h"
                            },
                            props: {
                                type: "VectorImageProperties",
                                metaData: {
                                    schemaVersion: "1.0"
                                },
                                displayMode: "stretch",
                                flip: "none",
                                id: "propItem-k06qi12i"
                            },
                            design: {
                                type: "VectorImageDesignData",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                overrideColors: {
                                    "color1": "#E8FEFF"
                                },
                                shapeStyle: {
                                    opacity: 1,
                                    strokeWidth: 1,
                                    stroke: "color_15",
                                    strokeOpacity: 1,
                                    enableStroke: false
                                },
                                id: "dataItem-k06qi12i1"
                            },
                            style: {
                                type: "ComponentStyle",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                style: {
                                    properties: {},
                                    propertiesSource: {},
                                    groups: {}
                                },
                                componentClassName: "wysiwyg.viewer.components.VectorImage",
                                pageId: "",
                                compId: "i8yjldkk",
                                styleType: "custom",
                                skin: "skins.viewer.VectorImageSkin",
                                id: "style-k06qi12i2"
                            },
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "vectorImage50"
                                }],
                                id: "connection-k06qi12i3"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 220,
                                    height: 123,
                                    x: 11,
                                    y: 52,
                                    scale: 1,
                                    rotationInDegrees: 137.66670029612112,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi12c",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Component",
                            id: "comp-k06qi133",
                            skin: "skins.viewer.VectorImageSkin",
                            layout: {
                                width: 146,
                                height: 286,
                                x: 411,
                                y: 96,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.VectorImage",
                            data: {
                                type: "VectorImage",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                svgId: "daf2ab4ebcf342d7829375e521e18867.svg",
                                id: "dataItem-k06qi138"
                            },
                            props: {
                                type: "VectorImageProperties",
                                metaData: {
                                    schemaVersion: "1.0"
                                },
                                displayMode: "fit",
                                flip: "none",
                                id: "propItem-k06qi1381"
                            },
                            design: {
                                type: "VectorImageDesignData",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                overrideColors: {
                                    "color1": "#FFECD1",
                                    "color2": "#CAE0DE",
                                    "color3": "#CAE0DE",
                                    "color4": "#9DD2D4",
                                    "color5": "#FBF3E8",
                                    "color6": "#A37F94"
                                },
                                shapeStyle: {
                                    opacity: 1,
                                    strokeWidth: 4,
                                    stroke: "#5E97FF",
                                    strokeOpacity: 1,
                                    enableStroke: false
                                },
                                id: "dataItem-k06qi139"
                            },
                            style: {
                                type: "ComponentStyle",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                style: {
                                    properties: {},
                                    propertiesSource: {},
                                    groups: {}
                                },
                                componentClassName: "wysiwyg.viewer.components.VectorImage",
                                styleType: "custom",
                                skin: "skins.viewer.VectorImageSkin",
                                id: "style-k06qi1391"
                            },
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "vectorImage53"
                                }],
                                id: "connection-k06qi1392"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 106,
                                    height: 210,
                                    x: 85,
                                    y: 124,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi133",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Component",
                            id: "comp-k06qi13z",
                            skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
                            layout: {
                                width: 377,
                                height: 24,
                                x: 301,
                                y: 421,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.WRichText",
                            data: {
                                type: "StyledText",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                text: `<p class=\"font_8\" style=\"font-size:16px; line-height:1.4em; text-align:center\"><span style=\"font-size:16px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#3A344D\"><span style=\"letter-spacing:0em\">${i18n.translate('multiStateBox_preset_emptyFullState_empty_description')}</span></span></span></span></p>`,
                                stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
                                linkList: [],
                                id: "dataItem-k06qi144"
                            },
                            props: {
                                type: "WRichTextProperties",
                                metaData: {
                                    schemaVersion: "1.0",
                                    autoGenerated: false
                                },
                                brightness: 1,
                                packed: false,
                                minHeight: 24,
                                id: "propItem-k06qi145"
                            },
                            style: "txtNew",
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "text102"
                                }],
                                id: "connection-k06qi1451"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 240,
                                    height: 44,
                                    x: 21,
                                    y: 344,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                props: {
                                    type: "WRichTextProperties",
                                    metaData: {
                                        schemaVersion: "1.0",
                                        autoGenerated: true
                                    },
                                    brightness: 1,
                                    packed: false,
                                    minHeight: null
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi13z",
                                    author: "duplicate"
                                }
                            }
                        }, {
                            type: "Component",
                            id: "comp-k06qi14q",
                            skin: "wysiwyg.viewer.skins.button.BasicButton",
                            layout: {
                                width: 215,
                                height: 38,
                                x: 382,
                                y: 476,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.SiteButton",
                            data: {
                                type: "LinkableButton",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                label: i18n.translate('multiStateBox_preset_emptyFullState_empty_button'),
                                id: "dataItem-k06qi14w"
                            },
                            props: {
                                type: "ButtonProperties",
                                metaData: {
                                    schemaVersion: "1.0"
                                },
                                align: "center",
                                margin: 0,
                                id: "propItem-k06qi14w1"
                            },
                            style: {
                                type: "ComponentStyle",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                style: {
                                    properties: {
                                        "alpha-bg": "1",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "0",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        bg: "#EF7373",
                                        bgh: "#FF7575",
                                        "boxShadowToggleOn-shd": "false",
                                        brd: "color_14",
                                        brdh: "color_12",
                                        brw: "0",
                                        fnt: "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                        rd: "30px 30px 30px 30px",
                                        shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                        txt: "#FFFFFF",
                                        txth: "#FFFFFF"
                                    },
                                    propertiesSource: {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "alpha-txt": "value",
                                        "alpha-txth": "value",
                                        bg: "value",
                                        bgh: "value",
                                        "boxShadowToggleOn-shd": "value",
                                        brd: "theme",
                                        brdh: "theme",
                                        brw: "value",
                                        fnt: "value",
                                        rd: "value",
                                        shd: "value",
                                        txt: "value",
                                        txth: "value"
                                    },
                                    groups: {}
                                },
                                componentClassName: "wysiwyg.viewer.components.SiteButton",
                                pageId: "",
                                compId: "",
                                styleType: "custom",
                                skin: "wysiwyg.viewer.skins.button.BasicButton",
                                id: "style-k06qi14w2"
                            },
                            connections: {
                                type: "ConnectionList",
                                items: [{
                                    type: "WixCodeConnectionItem",
                                    role: "button25"
                                }],
                                id: "connection-k06qi14x"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 240,
                                    height: 38,
                                    x: 21,
                                    y: 408,
                                    scale: 0.9333333333333333,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k06qi14q",
                                    author: "duplicate"
                                }
                            }
                        }],
                        layout: {
                            width: 980,
                            height: 566,
                            x: 0,
                            y: 0,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: "wysiwyg.viewer.components.StateBoxState",
                        design: {
                            type: "MediaContainerDesignData",
                            metaData: {
                                isPreset: false,
                                schemaVersion: "1.0",
                                isHidden: false
                            },
                            background: {
                                type: "BackgroundMedia",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false
                                },
                                color: "#D4EEEF",
                                colorOpacity: 1,
                                alignType: "center",
                                fittingType: "fill",
                                scrollType: "none",
                                colorOverlay: "",
                                colorOverlayOpacity: 0,
                                showOverlayForMediaType: "WixVideo"
                            },
                            charas: "design-jxn6a4tx",
                            dataChangeBehaviors: [],
                            id: "dataItem-k06qi129"
                        },
                        style: "stateBoxState1",
                        connections: {
                            type: "ConnectionList",
                            items: [{
                                type: "WixCodeConnectionItem",
                                role: "Empty"
                            }],
                            id: "connection-k06qi1292"
                        },
                        mobileStructure: {
                            layout: {
                                width: 280,
                                height: 456,
                                x: 0,
                                y: 0,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            metaData: {
                                originalCompId: "comp-k06qi122",
                                author: "duplicate"
                            }
                        }
                    }],
                    skin: "wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin",
                    layout: {
                        width: 980,
                        height: 566,
                        x: 32,
                        y: 1026,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wysiwyg.viewer.components.StateBox",
                    props: {
                        type: "StateBoxProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        transition: "OutIn",
                        transDuration: 0,
                        id: "propItem-k06qi0rp"
                    },
                    style: {
                        type: "ComponentStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            properties: {
                                "alpha-brd": "0.44",
                                "boxShadowToggleOn-shd": "false",
                                brd: "#78E1BE",
                                brw: "0",
                                shd: "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            propertiesSource: {
                                "alpha-brd": "value",
                                "boxShadowToggleOn-shd": "value",
                                brd: "value",
                                brw: "value",
                                shd: "value"
                            },
                            groups: {}
                        },
                        componentClassName: "wysiwyg.viewer.components.StateBox",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin",
                        id: "style-k06qi0rq"
                    },
                    connections: {
                        type: "ConnectionList",
                        items: [{
                            type: "WixCodeConnectionItem",
                            role: "EmptyFullStateBox"
                        }],
                        id: "connection-k06qi0rq1"
                    },
                    mobileStructure: {
                        layout: {
                            width: 280,
                            height: 391,
                            x: 14,
                            y: 843,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        metaData: {
                            originalCompId: "comp-k06qi0rk",
                            author: "duplicate"
                        }
                    },
                    activeModes: {}
                },
                preset: {
                    rect: {
                        width: 304,
                        height: 216,
                        x: 10,
                        y: 299
                    },
                    label: "add_preset_multiStateBox_3",
                    tags: null
                }
            },
            {
                id: "StateBox_StateBox_4",
                structure: {
                    type: "Container",
                    id: "comp-k2el8vkn",
                    components: [
                        {
                            type: "Container",
                            id: "comp-k2el8vn9",
                            components: [],
                            layout: {
                                width: 980,
                                height: 418,
                                x: 0,
                                y: 0,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: "wysiwyg.viewer.components.StateBoxState",
                            parent: "comp-k2el8vkn",
                            metaData: {
                                pageId: "zs4qw"
                            },
                            design: {
                                type: "MediaContainerDesignData",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "zs4qw"
                                },
                                background: {
                                    type: "BackgroundMedia",
                                    metaData: {
                                        isPreset: false,
                                        schemaVersion: "1.0",
                                        isHidden: false,
                                        pageId: "zs4qw"
                                    },
                                    color: "#FFFFFF",
                                    colorOpacity: 1,
                                    alignType: "center",
                                    fittingType: "fill",
                                    scrollType: "none",
                                    colorOverlay: "",
                                    colorOverlayOpacity: 0,
                                    showOverlayForMediaType: "WixVideo"
                                },
                                charas: "design-k2el6m1r",
                                dataChangeBehaviors: [],
                                id: "dataItem-k2el8vnk"
                            },
                            style: "stateBoxState1",
                            connections: {
                                type: "ConnectionList",
                                items: [
                                    {
                                        type: "WixCodeConnectionItem",
                                        role: "State1"
                                    }
                                ],
                                metaData: {
                                    pageId: "zs4qw"
                                },
                                id: "connection-k2el8vnl"
                            },
                            mobileStructure: {
                                layout: {
                                    width: 280,
                                    height: 111,
                                    x: 0,
                                    y: 0,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                metaData: {
                                    originalCompId: "comp-k2el8vn9",
                                    author: "duplicate"
                                }
                            }
                        }
                    ],
                    skin: "wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin",
                    layout: {
                        width: 980,
                        height: 418,
                        x: 31,
                        y: 1720,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wysiwyg.viewer.components.StateBox",
                    parent: "comp-k06qi0ci",
                    metaData: {
                        pageId: "zs4qw"
                    },
                    props: {
                        type: "StateBoxProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false,
                            pageId: "zs4qw"
                        },
                        transition: "CrossFade",
                        transDuration: 0,
                        id: "propItem-k2el8vmx"
                    },
                    style: {
                        type: "ComponentStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "zs4qw"
                        },
                        style: {
                            properties: {
                                alphaBrd: "0.11",
                                boxShadowToggleOnShd: "false",
                                brd: "color_15",
                                brw: "0",
                                shd: "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            propertiesSource: {
                                alphaBrd: "value",
                                boxShadowToggleOnShd: "value",
                                brd: "theme",
                                brw: "value",
                                shd: "value"
                            },
                            groups: {}
                        },
                        componentClassName: "wysiwyg.viewer.components.StateBox",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin",
                        id: "style-k2el8vmy"
                    },
                    connections: {
                        type: "ConnectionList",
                        items: [
                            {
                                type: "WixCodeConnectionItem",
                                role: "statebox8"
                            }
                        ],
                        metaData: {
                            pageId: "zs4qw"
                        },
                        id: "connection-k2el8vmz"
                    },
                    mobileStructure: {
                        layout: {
                            width: 280,
                            height: 111,
                            x: 14,
                            y: 1266,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        metaData: {
                            originalCompId: "comp-k2el8vkn",
                            author: "duplicate"
                        }
                    },
                    activeModes: {}
                },
                preset: {
                    rect: {
                        width: 304,
                        height: 170,
                        x: 10,
                        y: 515
                    },
                    label: "add_preset_multiStateBox_4",
                    tags: null
                }
            }
        ],
        compTypes: ["wysiwyg.viewer.components.StateBox"]
    },
    help: {
        hide: false,
        text: "add_section_label_multiStateBox_tooltip_description"
    }
}
