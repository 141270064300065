// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['address_input_design_discovermore_label'],
      sections: [addPanel.sections.getSections().developer.addressInputSection],
    };
  },
};
