import type { CompStructure } from 'types/documentServices';
import { ComponentCategory } from './parsedLayout';
import experiment from 'experiment';
import constants from '@/constants';
import type { TextClassificationResponseData } from '@/textClassification';

export class ComponentTypeMapper {
  static GetContainerCategory(component: CompStructure): ComponentCategory {
    if (component.hasOwnProperty('design')) {
      const raw = component as any;
      if (raw.design?.background?.mediaRef?.type === 'Image') {
        console.log('container has image background.');
        return ComponentCategory.Image;
      }
    }
    return ComponentCategory.Container;
  }

  private static getTextCategory(
    textClassifications: TextClassificationResponseData,
    compId: string,
  ): ComponentCategory {
    if (!textClassifications) {
      return ComponentCategory.Unsupported;
    }
    const { classification } = textClassifications[compId];
    return classification === 'small text'
      ? ComponentCategory.Paragraph
      : (textClassifications[compId].classification as ComponentCategory);
  }

  static GetComponentCategory(
    component: CompStructure,
    textClassifications: TextClassificationResponseData,
  ): ComponentCategory {
    if (experiment.isOpen('se_switchLayoutVectorArtBlackList')) {
      if (component.componentType === constants.COMP_TYPES.SHAPE) {
        return ComponentCategory.Unknown;
      }
    }

    switch (component.componentType) {
      case constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER:
      case constants.COMP_TYPES.COLUMN:
      case constants.COMP_TYPES.SECTION:
        return ComponentTypeMapper.GetContainerCategory(component);
      case constants.COMP_TYPES.PHOTO:
        return ComponentCategory.Image;
      case constants.COMP_TYPES.TEXT:
        return this.getTextCategory(textClassifications, component.id);
      case constants.COMP_TYPES.SITE_BUTTON:
      case constants.COMP_TYPES.STYLABLE_BUTTON:
        return ComponentCategory.Button;
      case 'mobile.core.components.Container':
      case 'wysiwyg.viewer.components.Group':
      case 'wysiwyg.viewer.components.FiveGridLine':
        return ComponentCategory.Unknown;
      default:
        return ComponentCategory.Unsupported;
    }
  }
}
