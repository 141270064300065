:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._siteCreationAdditionalPages_3pfgq_7 {
  background-color: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  visibility: visible;
  transition: visibility 0s 300ms, opacity 300ms linear; }
  ._siteCreationAdditionalPages_3pfgq_7.hidden {
    opacity: 0;
    visibility: hidden; }

._siteCreationAdditionalPagesContentWrapper_3pfgq_22 {
  height: calc(100% - 102px); }
  @media only screen and (max-height: 900px) {
    ._siteCreationAdditionalPagesContentWrapper_3pfgq_22 {
      height: calc(100% - 82px); } }

._additionalPagesContentSection_3pfgq_28 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 82px 0 80px;
  position: relative;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 93.75%, rgba(255, 255, 255, 0) 100%);
  z-index: 2;
  margin: 0 27px; }
  ._additionalPagesContentSection_3pfgq_28 span {
    font-family: Madefor; }
  ._additionalPagesContentSection_3pfgq_28 .main-additional-pages-title {
    font-size: 32px;
    margin-bottom: 18px;
    font-weight: 700; }
  ._additionalPagesContentSection_3pfgq_28 .secondary-additional-pages-title {
    font-size: 18px; }
  @media only screen and (min-width: 1440px) {
    ._additionalPagesContentSection_3pfgq_28 .main-additional-pages-title {
      font-size: 42px; } }

._additionalPagesColumnsWrapper_3pfgq_49 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: scroll;
  padding-top: 25px;
  margin-top: -15px;
  z-index: 1; }

._additionalPagesColumn_3pfgq_49 {
  display: flex;
  flex-direction: column;
  margin: 0 27px; }
  ._additionalPagesColumn_3pfgq_49:first-of-type .additional-page-thumbnail {
    max-height: 18vw; }
  ._additionalPagesColumn_3pfgq_49:last-of-type .additional-page-thumbnail {
    max-height: 22vw; }

._siteCreationAdditionalPlaceholder_3pfgq_67 {
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat; }
