import React from 'react';
import * as util from '@/util';
import * as baseUI from '@/baseUI';
import { Text } from '@wix/wix-base-ui';
import constants from '@/constants';
import * as UA from '@/uiAnimations';
import { mobileWizard as MobileWizard } from '@/mobileEditor';
import { LeftBar } from '@/leftBar';
import LazyComponent from '@/lazyComponent';
import { LeftBarWithQuickTip } from '../leftBar/oldLeftBar';
import OpenedPanels from '../panels/openedPanels';
import Portal from '../portal/portal';
import { IDEContainer } from '../ideContainer';
import { MobileFrame } from '../mobileFrame/mobileFrame';
import { StageContainer } from '../stage/stageContainer';
import { PreviewFrame } from '../previewFrame/previewFrame';
import {
  mapDispatchToProps,
  mapStateToProps,
  type SplitterWrapperDispatchProps,
  type SplitterWrapperStateProps,
} from './splitterWrapperMapper';
import { horizontalScroll } from '@wix/bi-logger-editor/v2';
import {
  TABLET_LAPTOP_WIDTH,
  TABLET_WIDTH,
  DESKTOP_WIDTH,
  WIDE_DESKTOP_WIDTH,
  PREVIEW_HORIZONTAL_MARGIN,
} from '@/preview';
import { translate } from '@/i18n';
import experiment from 'experiment';

const MOBILE_WIZARD_OPENING_STATE =
  constants.MOBILE.MOBILE_WIZARD.OPENING_STATE;

const Splitter = baseUI.splitter as any;
const withLeftPanelHiddenElementsRedesignExperiment =
  experiment.getValue('se_leftPanelHiddenMobileElementsRedesign') === 'B' ||
  experiment.getValue('se_leftPanelHiddenMobileElementsRedesign') === 'C';

export interface SplitterWrapperOwnProps {}

interface SplitterWrapperProps
  extends SplitterWrapperOwnProps,
    SplitterWrapperStateProps,
    SplitterWrapperDispatchProps {}

const getWixCodeTreeComponent = (props: SplitterWrapperProps) => {
  if (!props.loadWixCodeWithRepluggableInClassic) {
    return (
      <LazyComponent
        moduleName={
          util.appStudioUtils.isAppStudio()
            ? 'wixCode.components.DeprecatedAppBuilderTree'
            : 'wixCode.components.Tree'
        }
        key="wix-code-tree"
        id="wix-code-tree"
        performingMouseMoveAction={props.performingMouseMoveAction}
        context={props.devModeContextWithData}
      />
    );
  }
  return (
    <props.veloTreeComponent
      performingMouseMoveAction={props.performingMouseMoveAction}
      context={props.devModeContextWithData}
    />
  );
};

const getConsoleComponent = (props: SplitterWrapperProps) => {
  if (!props.loadWixCodeWithRepluggableInClassic) {
    return (
      <LazyComponent
        moduleName="wixCode.components.console"
        key="wix-code-console"
        id="wix-code-console"
      />
    );
  }

  return <props.consoleComponent />;
};

class SplitterWrapperComponent extends React.Component<SplitterWrapperProps> {
  static displayName = 'SplitterWrapper';

  private leftBarAreaRef = React.createRef<HTMLDivElement>();
  private editingAreaRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.updatePositionsInState();
    this.props.initDomMeasurements();
  }

  componentDidUpdate(prevProps: SplitterWrapperProps) {
    this.props.onRenderDone(this.props.renderCounter);
    const newShouldStageBeHorizontallyScrollable =
      this.shouldStageBeHorizontallyScrollable();

    if (!this.props.performingMouseMoveAction) {
      this.updatePositionsInState();
    }

    if (
      this.props.isStageHorizontallyScrollable !==
      newShouldStageBeHorizontallyScrollable
    ) {
      this.props.setIsStageHorizontallyScrollable(
        newShouldStageBeHorizontallyScrollable,
      );
      util.biLogger.report(
        horizontalScroll({
          state: newShouldStageBeHorizontallyScrollable
            ? 'horizontal_scroll_on'
            : 'horizontal_scroll_off',
        }),
      );
    }

    if (prevProps.isMobileEditor !== this.props.isMobileEditor) {
      const switchEditorModeInteraction = this.props.isMobileEditor
        ? util.fedopsLogger.INTERACTIONS.CHANGE_EDITOR_MODE_TO_MOBILE
        : util.fedopsLogger.INTERACTIONS.CHANGE_EDITOR_MODE_TO_DESKTOP;

      util.fedopsLogger.interactionEnded(switchEditorModeInteraction, {
        paramsOverrides: {
          sourceOfEnd: 'splitterWrapper',
          isPreview: `${this.props.previewMode}`,
          isZoomOut: `${this.props.isZoomOut}`,
        },
      });
    }

    this.props.initDomMeasurements();

    // these transitions (to Mobile, to Preview, to ZoomOut) have CSS animation, which should be ended before DOM Measurements
    // TODO probably, these is only cases when DomMeasurements are needed ever
    if (
      util.isPropChanged(prevProps, this.props, [
        'hideTools',
        'previewMode',
        'isMobileEditor',
        'isShrinkedStageZoomOutActive',
      ])
    ) {
      this.props.initDomMeasurementsAfterAnimationEnd();
    }
  }

  getEditingAreaRect() {
    const { top, left, height, width, right, bottom } =
      this.editingAreaRef.current.getBoundingClientRect();

    return { top, left, height, width, right, bottom };
  }

  updatePositionsInState() {
    this.props.refreshEditingAreaPosition(this.getEditingAreaRect());
  }

  getEditingAreaRectOffset() {
    if (this.editingAreaRef.current) {
      const { left, x } = this.editingAreaRef.current.getBoundingClientRect();

      return { left, x };
    }
  }

  getEditingAreaClassName() {
    return util.cx({
      'editing-area': true,

      'mobile-wizard-editing-area': this.props.shouldRenderMobileWizard,
      'mobile-editor': this.props.isMobileEditor,

      'preview-mode': this.props.previewMode,
      'resizable-preview-mode':
        this.props.previewMode && util.fixedStage.isFixedStageEnabled(),
      'preview-resize-animation': this.props.shouldAnimatePreviewWidth,
      'interaction-mode': this.props.isInteractionMode,
      'translation-mode': this.props.isTranslationMode,
      'lightbox-mode': this.props.isLightboxMode,

      'new-translation-mode': this.props.isTranslationMode,
      'new-lightbox-mode':
        this.props.isLightboxMode && util.topBar.isNewTopBarEnabled(),
      'new-mobile-editor-layout': util.workspace.isNewWorkspaceEnabled(),
    });
  }

  getPreviewFrameCoverClasses() {
    const { mobileWizardOpeningState, isStageZoomMode } = this.props;

    return {
      'preview-frame-cover': true,
      'stage-zoom-mode': isStageZoomMode,
      'mobile-wizard-preview-frame':
        mobileWizardOpeningState === MOBILE_WIZARD_OPENING_STATE.IN_PROGRESS,
      'mobile-wizard-preview-frame-out':
        mobileWizardOpeningState === MOBILE_WIZARD_OPENING_STATE.FINISH,
      'fixed-margin-top': this.props.shouldRenderFixedStageTopGap,
    };
  }

  shouldCompletelyHideTools() {
    const isMobileWizardExpanded =
      this.props.mobileWizardOpeningState ===
      MOBILE_WIZARD_OPENING_STATE.IN_PROGRESS;
    return this.props.shouldRenderMobileWizard && isMobileWizardExpanded;
  }

  shouldShowSectionedPanel() {
    if (this.props.isRightPanelHidden) {
      return false;
    }

    if (!this.props.previewMode) {
      return (
        this.props.shouldShowDevModePanels &&
        this.props.hasOpenDockedSections &&
        this.props.shouldShowSectionedPanel
      );
    }
    return this.props.shouldShowSectionedPanelAppStudioPreview;
  }

  shouldShowPlatformSidePanel() {
    if (this.props.isRightPanelHidden) {
      return false;
    }
    return !this.props.previewMode && this.props.hasDockedPanels;
  }

  getPlatformSidePanelClasses() {
    return util.cx({
      'right-pane': true,
      'right-pane-above-modals': this.props.shouldRaiseSidePanelAboveModals,
    });
  }

  getRightAreaConstantWidth() {
    return this.props.previewMode
      ? constants.RIGHT_AREA.WIDTH_PREVIEW
      : constants.RIGHT_AREA.WIDTH;
  }

  leftTreeSplitterResize = (
    firstPanePercent: number,
    relativePosition: number,
    splitterSize: number,
  ) => {
    if (relativePosition < 150) {
      this.props.developerModeUI.treePane.toggle();
    } else {
      this.props.developerModeUI.treePane.setSplitPosition(
        firstPanePercent,
        relativePosition,
        splitterSize,
      );
    }
  };

  handleIdePaneResizeStart = () => {
    if (this.props.previewMode) {
      this.props.previewResizeStart();
    }
  };

  handleIdePaneResizeEnd = (
    firstPanePercent: AnyFixMe,
    relativePosition: AnyFixMe,
    splitterSize: AnyFixMe,
  ) => {
    const paneKey = this.props.previewMode ? 'consolePane' : 'idePane';

    if (this.props.previewMode) {
      this.props.previewResizeEnd();
    }

    this.props.developerModeUI[paneKey].setSplitPosition(
      firstPanePercent,
      relativePosition,
      splitterSize,
    );
  };

  getEditingAreaTopBottom = () => {
    const editingAreaMargins = { top: 0, bottom: 0 };

    if (
      util.sections.isSectionsEnabled() &&
      this.props.isZoomOut &&
      !this.props.isMobileEditor
    ) {
      if (!this.props.isFullEditorMode) {
        editingAreaMargins.top = 30;
        editingAreaMargins.bottom = 30;
      } else {
        editingAreaMargins.top = 18;
        editingAreaMargins.bottom = 18;
      }
    }
    if (this.props.shouldRenderFixedStageTopGap) {
      editingAreaMargins.top = constants.UI.FIXED_STAGE_MARGIN_TOP;
    }
    return editingAreaMargins;
  };

  getEditingAreaStyle = () => {
    const { shouldCutEditingArea, fixedStageWidth } = this.props;

    const stageRightMargin = shouldCutEditingArea
      ? constants.UI.EDITING_AREA_RIGHT_MARGIN
      : 0;

    const scrollbarWidth = util.uiUtils.getScrollbarWidth();

    const { x, width } =
      this.leftBarAreaRef.current?.getBoundingClientRect() || {
        x: 0,
        width: 0,
      };

    const { top, bottom } = this.getEditingAreaTopBottom();

    const propertiesForRegularStage = {
      '--stage-right-margin': `${stageRightMargin}px`,
      '--stage-left': `${x + width}px`,
      '--stage-top': `${top}px`,
      '--stage-bottom': `${bottom}px`,
      '--stage-scrollbar-width': `${util.uiUtils.getScrollbarWidth()}px`,
      '--stage-height': '100%',
    };

    if (
      !this.props.shouldRenderStageWithFixedWidth &&
      !this.props.shouldRenderFixedStageTopGap
    ) {
      return propertiesForRegularStage as React.CSSProperties;
    }

    const fixedStageMarginsSum =
      (this.editingAreaRef.current &&
        (this.props.editingAreaWidth || this.getEditingAreaRect().width)) -
      fixedStageWidth -
      scrollbarWidth;

    const fixedStageMarginWithoutConstrains = fixedStageMarginsSum / 2;

    const rightMarginFixedStage = Math.max(
      fixedStageMarginWithoutConstrains,
      stageRightMargin,
    );
    const leftMarginFixedStage = fixedStageMarginsSum - rightMarginFixedStage;

    const propertiesForFixedStage = {
      '--stage-fixed-width': `${fixedStageWidth}px`,
      '--site-scale': this.props.siteScale,
      '--stage-shift-transform':
        this.props.styleForPushedStageAndPreviewFrame.transform,
      '--stage-shift-transition':
        this.props.styleForPushedStageAndPreviewFrame.transition,
      '--stage-height': this.props.isMobileEditor
        ? '100%'
        : this.props.stageHeight,
      '--stage-right-margin': `${rightMarginFixedStage}px`,
      '--stage-left-margin': `${Math.max(leftMarginFixedStage, 0)}px`,
      '--horizontal-ruler-right': `${
        (rightMarginFixedStage - stageRightMargin) * -1
      }px`,
      '--horizontal-ruler-top': `${top * -1}px`,
      '--preview-horizontal-margin': `${
        this.props.isDesktopPreview ? PREVIEW_HORIZONTAL_MARGIN : 0
      }px`,
      '--preview-width': `${this.props.previewWidth}px`,
    };

    return {
      ...propertiesForRegularStage,
      ...propertiesForFixedStage,
    } as React.CSSProperties;
  };

  getEditorLayoutLeftBarStyle = () => {
    return {
      '--left-bar-width': `${this.props.leftBarWidth}px`,
    } as React.CSSProperties;
  };

  getEditingAreaMinWidth = () => {
    const stageRightMargin = this.props.shouldCutEditingArea
      ? constants.UI.EDITING_AREA_RIGHT_MARGIN
      : 0;

    if (this.props.isStageHorizontallyScrollable) {
      return stageRightMargin
        ? this.props.fixedStageWidth
        : this.props.fixedStageWidth + constants.UI.EDITING_AREA_RIGHT_MARGIN;
    }

    return (
      this.props.fixedStageWidth +
      stageRightMargin +
      util.uiUtils.getScrollbarWidth()
    );
  };

  shouldStageBeHorizontallyScrollable = () =>
    this.props.shouldRenderStageWithFixedWidth &&
    !this.props.isStageZoomMode &&
    this.props.editingAreaWidth < this.getEditingAreaMinWidth() &&
    !this.props.previewMode;

  getScrollBarWidthForSplitterRow = () => {
    if (
      !this.props.shouldRenderFixedStageTopGap ||
      (this.props.editingAreaWidth < this.getEditingAreaMinWidth() &&
        !this.props.shouldCutEditingArea)
    ) {
      return 0;
    }

    return util.uiUtils.getScrollbarWidth();
  };

  shouldHideMobileFrame = () => {
    if (util.fixedStage.isFixedStageEnabled()) {
      return true;
    }

    return (
      !this.props.previewMode &&
      this.props.mobileWizardOpeningState !==
        MOBILE_WIZARD_OPENING_STATE.IN_PROGRESS
    );
  };

  getPreviewWidthInfoText() {
    const widthValueText = translate('preview_width_label_prefix', {
      width: this.props.previewWidth,
    });

    const getWidthDescriptionKey = () => {
      if (this.props.previewWidth === this.props.baselineWidth) {
        return 'preview_width_label_suffix_editing_size';
      }

      if (this.props.previewWidth <= TABLET_WIDTH) {
        return 'preview_width_label_suffix_tablet';
      }

      if (this.props.previewWidth <= TABLET_LAPTOP_WIDTH) {
        return 'preview_width_label_suffix_tablet_laptop';
      }

      if (this.props.previewWidth <= DESKTOP_WIDTH) {
        return 'preview_width_label_suffix_laptop';
      }

      if (this.props.previewWidth < WIDE_DESKTOP_WIDTH) {
        return 'preview_width_label_suffix_desktop';
      }

      return 'preview_width_label_suffix_wide_desktop';
    };

    const widthDescription = translate(getWidthDescriptionKey());

    return [widthValueText, widthDescription].join(' • ');
  }

  render() {
    return (
      <div
        className={util.cx({
          'editor-content-wrapper': true,
          'with-top-bar-banner': this.props.isWithTopBarBanner,
          'hidden-to-top': this.shouldCompletelyHideTools(),
          'tools-hidden':
            this.props.hideTools &&
            !this.props.hideToolsAndMaintainStagePosition,
        })}
      >
        <Splitter
          layout="vertical"
          splitPosition={this.props.treePaneSplitPosition}
          statefulResize
          onResize={this.leftTreeSplitterResize}
          className="file-pane-splitter"
          name="file-pane-splitter"
          handle={
            <Splitter.Handle
              onClick={() => this.props.developerModeUI.treePane.toggle()}
              isOpen={() => this.props.developerModeUI.treePane.isOpen()}
              automationId="corvid-site-structure-resize-handle"
            />
          }
        >
          {this.props.shouldShowLeftPane ? (
            <Splitter.Pane
              onContextMenu={util.browserUtil.preventDefaultRightClick}
              collapsed={!this.props.developerModeUI.treePane.isOpen()}
              fitWhenCollapsed
              key="left-panel-pane"
              minSize="150px"
              maxSize="400px"
              automationId="wix-code-left-pane"
              className="wix-code-left-pane"
            >
              {this.props.wixCodeLoaded && getWixCodeTreeComponent(this.props)}
            </Splitter.Pane>
          ) : null}

          <Splitter.Pane>
            <Splitter
              layout="vertical"
              splitPosition={
                !this.props.isRightPanelHidden &&
                this.shouldShowPlatformSidePanel()
                  ? null
                  : '100%'
              }
            >
              <Splitter.Pane
                minSize={`calc(100% - ${
                  this.shouldShowPlatformSidePanel()
                    ? this.props.sidePanelWidth
                    : this.getRightAreaConstantWidth()
                }px)`}
              >
                <Splitter
                  layout="horizontal"
                  splitPosition={this.props.editorSplitPosition}
                  onDividerClick={
                    this.props.previewMode
                      ? null
                      : this.props.developerModeUI.idePane.toggleMinimize
                  }
                  statefulResize
                  onDragStarted={this.handleIdePaneResizeStart}
                  onResize={this.handleIdePaneResizeEnd}
                  className="ide-splitter"
                >
                  <Splitter.Pane
                    maxSize={
                      this.props.shouldShowIdePane
                        ? `calc(100% - ${this.props.developerModeUI.topbarHeight})`
                        : '100%'
                    }
                  >
                    <Splitter
                      layout="vertical"
                      splitPosition={this.props.secondaryLeftAreaWidth}
                      statefulResize
                      onResize={this.props.setSecondaryLeftAreaWidth}
                      className="left-area-splitter"
                    >
                      {this.props.secondaryLeftAreaContentModule &&
                      !this.props.hideTools ? (
                        <Splitter.Pane
                          onContextMenu={
                            util.browserUtil.preventDefaultRightClick
                          }
                          key="secondaryLeftArea"
                          maxSize="360px"
                          minSize="135px"
                          className="secondary-left-area"
                        >
                          <LazyComponent
                            moduleName={
                              this.props.secondaryLeftAreaContentModule
                            }
                          />
                        </Splitter.Pane>
                      ) : null}

                      <Splitter.Pane>
                        <div
                          className="editor-layout-splitter-row"
                          style={
                            {
                              '--stage-scrollbar-width': `${this.getScrollBarWidthForSplitterRow()}px`,
                            } as React.CSSProperties
                          }
                        >
                          {util.workspace.isNewWorkspaceEnabled() &&
                            this.props.isLeftBarVisible && (
                              <div
                                className={util.cx('editor-layout-left-bar', {
                                  expanded: this.props.hasOpenedLeftPanels,
                                  'left-panel-hidden-elements-redesign-experiment':
                                    withLeftPanelHiddenElementsRedesignExperiment,
                                })}
                                ref={this.leftBarAreaRef}
                                style={this.getEditorLayoutLeftBarStyle()}
                              >
                                {this.props.previewMode ? null : <LeftBar />}
                              </div>
                            )}

                          <div
                            className={util.cx('editor-layout-editing-area', {
                              truncated: this.props.shouldCutEditingArea,
                              fixed:
                                this.props.shouldRenderStageWithFixedWidth &&
                                !this.shouldStageBeHorizontallyScrollable(),
                              horizontallyScrollable:
                                this.shouldStageBeHorizontallyScrollable(),
                            })}
                            style={this.getEditingAreaStyle()}
                          >
                            <div
                              ref={this.editingAreaRef}
                              id={constants.ROOT_COMPS.SELECTOR_ID.EDITING_AREA}
                              className={this.getEditingAreaClassName()}
                            >
                              {this.props.isDesktopPreview ? (
                                <div className="preview-width-info">
                                  <Text
                                    skin="placeholder"
                                    size="tiny"
                                    shouldTranslate={false}
                                  >
                                    {this.getPreviewWidthInfoText()}
                                  </Text>
                                </div>
                              ) : null}
                              <div
                                className={util.cx(
                                  this.getPreviewFrameCoverClasses(),
                                )}
                              >
                                {this.props.isMobileEditor ? (
                                  <MobileFrame
                                    frameless={this.shouldHideMobileFrame()}
                                  />
                                ) : null}

                                <PreviewFrame
                                  styleForPushedStageAndPreviewFrame={
                                    this.props
                                      .styleForPushedStageAndPreviewFrame
                                  }
                                />

                                {!this.props.previewMode &&
                                !this.props.shouldRenderMobileWizard ? (
                                  <StageContainer
                                    styleForPushedStageAndPreviewFrame={
                                      this.props
                                        .styleForPushedStageAndPreviewFrame
                                    }
                                  />
                                ) : null}
                              </div>

                              <OpenedPanels
                                helpPanelProps={this.props.helpPanelProps}
                                selectedComponents={
                                  this.props.selectedComponents
                                }
                                isMobileEditor={this.props.isMobileEditor}
                              />

                              {util.workspace.isNewWorkspaceEnabled() ? null : (
                                <UA.leftBarAnimation>
                                  {!this.props.previewMode &&
                                  !this.props.isPinMode ? (
                                    <LeftBarWithQuickTip
                                      editingAreaRectOffset={this.getEditingAreaRectOffset()}
                                    />
                                  ) : null}
                                </UA.leftBarAnimation>
                              )}

                              <Portal />

                              {this.props.shouldRenderMobileWizard ? (
                                <MobileWizard />
                              ) : null}
                            </div>
                          </div>
                          {this.props.WorkspaceRightPanel.map((slot) => (
                            <slot.contribution key={slot.uniqueId} />
                          ))}
                        </div>
                      </Splitter.Pane>
                    </Splitter>
                  </Splitter.Pane>
                  {this.props.shouldShowIdePane ? (
                    <Splitter.Pane
                      key="wix-code-ide-pane"
                      minSize={this.props.developerModeUI.topbarHeight}
                      onClick={this.props.closeAllPanels}
                      className={util.cx({
                        'wix-code-ide-pane': true,
                        'above-editor':
                          this.props.devModeContext.type !==
                            constants.DEVELOPER_MODE.CONTEXT_TYPES.COLLECTION &&
                          Number(
                            this.props.editorSplitPosition.replace('%', ''),
                          ) < 50,
                      })}
                    >
                      {this.props.previewMode ? (
                        getConsoleComponent(this.props)
                      ) : (
                        <IDEContainer
                          loadWixCodeWithRepluggableInClassic={
                            this.props.loadWixCodeWithRepluggableInClassic
                          }
                          codeEditorComponent={this.props.codeEditorComponent}
                          toggleMeasureMaps={this.props.toggleMeasureMaps}
                          setIsWixCodeIDELoaded={
                            this.props.setIsWixCodeIDELoaded
                          }
                        />
                      )}
                    </Splitter.Pane>
                  ) : null}
                </Splitter>

                <OpenedPanels
                  isFullStagePanel
                  helpPanelProps={this.props.helpPanelProps}
                  isMobileEditor={this.props.isMobileEditor}
                  selectedComponents={this.props.selectedComponents}
                  withHelpPanel={false}
                />

                {this.props.shouldDisplayLayersPanel ? (
                  <LazyComponent
                    id="layers-panel-loader"
                    moduleName="panels.toolPanels.layersPanel"
                    onClose={this.props.hideLayersPanel}
                  />
                ) : null}

                {this.props.shouldDisplayQrCodeExposer ? (
                  <LazyComponent
                    id="mobile-preview-qrcode-exposer"
                    moduleName="mobileEditor.qrCodeExposer"
                  />
                ) : null}

                {this.props.shouldDisplaySectionizerPanel ? (
                  <LazyComponent
                    id="sectionizer-panel-loader"
                    moduleName="sectionizer.panels.sectionizerPanel"
                  />
                ) : null}
              </Splitter.Pane>

              {this.shouldShowSectionedPanel() ||
              this.shouldShowPlatformSidePanel() ? (
                <Splitter.Pane
                  key="right-area"
                  id="right-panel"
                  className={this.getPlatformSidePanelClasses()}
                  size={`${this.props.sidePanelWidth}px`}
                  collapsed={
                    this.props.isRightPanelHidden ||
                    (!this.shouldShowSectionedPanel() &&
                      !this.shouldShowPlatformSidePanel())
                  }
                >
                  {this.shouldShowSectionedPanel() ? (
                    <LazyComponent
                      moduleName={
                        this.props.previewMode
                          ? 'dockableSections.sectionedPanelPreview'
                          : 'dockableSections.sectionedPanel'
                      }
                      previewMode={this.props.previewMode}
                      key="sectioned-panel"
                    />
                  ) : null}

                  {this.shouldShowPlatformSidePanel() ? (
                    <LazyComponent
                      moduleName="platformPanels.sidePanel"
                      previewMode={this.props.previewMode}
                      key="platform-side-panel"
                    />
                  ) : null}
                </Splitter.Pane>
              ) : null}
            </Splitter>
          </Splitter.Pane>
        </Splitter>
      </div>
    );
  }
}

export const SplitterWrapper = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(SplitterWrapperComponent);

SplitterWrapper.pure = SplitterWrapperComponent;
