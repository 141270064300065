:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._mediaAddPosterIndicator_1qu04_7 {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 116px;
  left: 102px;
  cursor: pointer; }
  ._mediaAddPosterIndicator_1qu04_7 ._st1_1qu04_14 {
    fill: #116dff; }
  ._mediaAddPosterIndicator_1qu04_7 ._st2_1qu04_16 {
    fill: #fff; }
  ._mediaAddPosterIndicator_1qu04_7 ._st3_1qu04_18 {
    fill: #fff; }
  ._mediaAddPosterIndicator_1qu04_7:hover ._st1_1qu04_14 {
    fill: #5999ff; }
