import React from 'react';
import { cx, hoc } from '@/util';
import { createStructuredSelector } from '../selectors';
import {
  _relativeToStructureLayoutSel,
  isIndicatorBottomSel,
} from '../selectors';

interface LayoutSizeIndicatorOwnProps {}

interface LayoutSizeIndicatorStateProps {
  width: number;
  height: number;
  isIndicatorBottom: boolean;
}

interface LayoutSizeIndicatorProps
  extends LayoutSizeIndicatorOwnProps,
    LayoutSizeIndicatorStateProps {}

const LayoutSizeIndicator: React.FC<LayoutSizeIndicatorProps> = (props) => {
  return (
    <span
      key="resizeBoundingStyle"
      className={cx('edit-box-indicator', 'resizing', {
        'bottom-indicator': props.isIndicatorBottom,
      })}
    >
      W: {props.width}, H: {props.height}
    </span>
  );
};

const layoutSizeIndicatorPropsSel =
  createStructuredSelector<LayoutSizeIndicatorStateProps>({
    width: _relativeToStructureLayoutSel.width,
    height: _relativeToStructureLayoutSel.height,
    isIndicatorBottom: isIndicatorBottomSel,
  });

export default hoc.connect(
  hoc.STORES.MOUSE_OPS,
  layoutSizeIndicatorPropsSel,
)(LayoutSizeIndicator);
