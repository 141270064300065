import React from 'react';
import { TextLabel } from '@wix/wix-base-ui';

import styles from './Header.scss';
import { translate } from '@/i18n';
import type { InstallerAppsData } from '@wix/editor-platform-host-integration-apis';

export interface HeaderProps {
  appToInstallName: string;
  dependenciesData: InstallerAppsData[];
}

export const Header: React.FC<HeaderProps> = ({
  appToInstallName,
  dependenciesData,
}) => {
  return (
    <section
      className={
        dependenciesData.length < 3
          ? styles.smallHeaderContainer
          : styles.headerContainer
      }
    >
      <div>
        <TextLabel
          dataHook="header-title"
          className={styles.title}
          enableEllipsis={false}
          shouldTranslate={false}
          type="T08"
          value={translate('PLATFORM_AppInstall_Modal_Add_App_Title', {
            AppName: appToInstallName,
          })}
        />
      </div>
      <div className={styles.subtitleContainer}>
        <TextLabel
          dataHook="header-subtitle"
          className={styles.subtitle}
          shouldTranslate={false}
          enableEllipsis={false}
          type="T05"
          value={translate('PLATFORM_AppInstall_Modal_Add_App_Subtitle', {
            AppName: appToInstallName,
          })}
        />
      </div>
    </section>
  );
};
