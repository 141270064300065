// @ts-nocheck
import createReactClass from 'create-react-class';
import linkToMixin from './linkToMixin';
import React from 'react';
import * as UI from '@/baseUI';
import { cx } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'linkToButton',
  mixins: [linkToMixin],
  render() {
    return (
      <UI.button
        icon="link"
        onClick={this.openLinkDialog}
        {...this.filteredProps()}
        className={cx('link-to-button', this.filteredProps()?.className)}
      />
    );
  },
});
