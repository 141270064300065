const CATEGORIES = {
  TEXT: 'TEXT',
  CORNERS: 'CORNERS',
  SHADOW: 'SHADOW',
  FILL: 'FILL',
  BORDER: 'BORDER',
  COVER_IMAGE: 'COVER_IMAGE',
  OTHER: 'OTHER',
};

const SKIN_PARAM_DEFINITIONS = {
  TEXT_COLOR: {
    label: 'CustomDesign_Text_TextColor',
    category: CATEGORIES.TEXT,
    controlType: 'colorPicker',
  },
  TEXT_COLOR_LEGACY_ALPHA: {
    label: 'CustomDesign_Text_TextColor',
    category: CATEGORIES.TEXT,
    controlType: 'colorPicker',
  },
  TEXT_ALIGNMENT: {
    label: 'CustomDesign_Text_Alignment',
    category: CATEGORIES.TEXT,
    controlType: 'textAlignment',
  },
  TEXT_SIZE: {
    label: 'CustomDesign_Text_FontSize',
    category: CATEGORIES.TEXT,
    controlType: 'wixBaseUISlider',
  },
  FONT: {
    label: 'CustomDesign_Text_Font',
    category: CATEGORIES.TEXT,
    controlType: 'fontControl',
  },
  FONT_FAMILY: {
    label: 'CustomDesign_Text_Font',
    category: CATEGORIES.TEXT,
    controlType: 'fontFamily',
  },
  BORDER_RADIUS: {
    label: 'CustomDesign_Corners_CornerRadius',
    category: CATEGORIES.CORNERS,
    controlType: 'cornerRadiusInput',
  },
  BOX_SHADOW: {
    label: 'shadow', //?
    category: CATEGORIES.SHADOW,
    controlType: 'shadowControl',
  },
  BOX_SHADOW_COLOR_ALPHA: {
    label: 'CustomDesign_Shadow_Color&Opacity',
    category: CATEGORIES.SHADOW,
    controlType: 'colorPickerWithOpacity',
  },
  COLOR_ALPHA: {
    label: 'CustomDesign_FillColor_Color&Opacity',
    category: CATEGORIES.FILL,
    controlType: 'colorPickerWithOpacity',
  },
  COLOR: {
    label: 'CustomDesign_FillColor_Color',
    category: CATEGORIES.FILL,
    controlType: 'colorPicker',
  },
  COVER_IMAGE: {
    label: 'Custom.design_video.player_cover.Image_tab_title',
    category: CATEGORIES.COVER_IMAGE,
    controlType: 'coverImage',
  },
  BG_COLOR: {
    label: 'CustomDesign_FillColor_BGColor',
    category: CATEGORIES.FILL,
    controlType: 'colorPicker',
  },
  BG_COLOR_ALPHA: {
    label: 'CustomDesign_FillColor_BGColor&Opacity',
    category: CATEGORIES.FILL,
    controlType: 'colorPickerWithOpacity',
  },
  COLOR_SCHEME: {
    label: 'CustomDesign_COLOR_SCHEME',
    category: CATEGORIES.FILL,
    controlType: 'colorScheme',
  },
  BORDER_COLOR_ALPHA: {
    label: 'CustomDesign_Borders_Color&Opacity',
    category: CATEGORIES.BORDER,
    controlType: 'colorPickerWithOpacity',
  },
  BORDER_COLOR: {
    label: 'CustomDesign_Borders_Color',
    category: CATEGORIES.BORDER,
    controlType: 'colorPicker',
  },
  BORDER_SIZE: {
    label: 'CustomDesign_Borders_Width',
    category: CATEGORIES.BORDER,
    controlType: 'wixBaseUISlider',
  },
  BORDER_SIZES: {
    label: 'CustomDesign_Borders_Width',
    category: CATEGORIES.BORDER,
    controlType: 'borderPositionControl',
  },
  BORDER_TOP_SIZE: {
    label: 'CustomDesign_Borders_BORDER_TOP_SIZE',
    category: CATEGORIES.BORDER,
    controlType: 'wixBaseUISlider',
  },
  BORDER_BOTTOM_SIZE: {
    label: 'CustomDesign_Borders_BORDER_BOTTOM_SIZE',
    category: CATEGORIES.BORDER,
    controlType: 'wixBaseUISlider',
  },
  BORDER_LEFT_SIZE: {
    label: 'CustomDesign_Borders_BORDER_LEFT_SIZE',
    category: CATEGORIES.BORDER,
    controlType: 'wixBaseUISlider',
  },
  BORDER_RIGHT_SIZE: {
    label: 'CustomDesign_Borders_BORDER_RIGHT_SIZE',
    category: CATEGORIES.BORDER,
    controlType: 'wixBaseUISlider',
  },
  SIZE: {
    label: 'CustomDesign_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  PADDING_SIZE: {
    label: 'CustomDesign_PADDING_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  PADDING_TOP_SIZE: {
    label: 'CustomDesign_PADDING_TOP_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  PADDING_BOTTOM_SIZE: {
    label: 'CustomDesign_PADDING_BOTTOM_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  PADDING_LEFT_SIZE: {
    label: 'CustomDesign_PADDING_LEFT_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  PADDING_RIGHT_SIZE: {
    label: 'CustomDesign_PADDING_RIGHT_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  PADDING_SIZE_HORIZONTAL: {
    label: 'editorx.CustomDesign_PADDING_SIZE_Horizontal',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  PADDING_SIZE_VERTICAL: {
    label: 'editorx.CustomDesign_PADDING_SIZE_Vertical',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  MARGIN_SIZE: {
    label: 'CustomDesign_MARGIN_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  MARGIN_TOP_SIZE: {
    label: 'CustomDesign_MARGIN_TOP_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  MARGIN_BOTTOM_SIZE: {
    label: 'CustomDesign_MARGIN_BOTTOM_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  MARGIN_LEFT_SIZE: {
    label: 'CustomDesign_MARGIN_LEFT_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  MARGIN_RIGHT_SIZE: {
    label: 'CustomDesign_MARGIN_RIGHT_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  BG_SIZE: {
    label: 'CustomDesign_BG_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  WIDTH_SIZE: {
    label: 'CustomDesign_WIDTH_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  HEIGHT_SIZE: {
    label: 'CustomDesign_HEIGHT_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  TOP_SIZE: {
    label: 'CustomDesign_TOP_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  BOTTOM_SIZE: {
    label: 'CustomDesign_BOTTOM_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  LEFT_SIZE: {
    label: 'CustomDesign_LEFT_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  RIGHT_SIZE: {
    label: 'CustomDesign_RIGHT_SIZE',
    category: CATEGORIES.OTHER,
    controlType: 'wixBaseUISlider',
  },
  ALPHA: {
    label: 'CustomDesign_FillColor_Opacity',
    category: CATEGORIES.FILL,
    controlType: 'wixBaseUISlider',
  },
  BACKGROUND_FILLS: {
    label: 'CustomDesign_FillColor_Color&Opacity',
    category: CATEGORIES.FILL,
    controlType: 'backgroundFillsControl',
  },
};

export function getDefaultCategory(
  paramType: keyof typeof SKIN_PARAM_DEFINITIONS,
) {
  return (
    SKIN_PARAM_DEFINITIONS[paramType] &&
    SKIN_PARAM_DEFINITIONS[paramType].category.toLowerCase()
  ); //tolowercase till I normalize the rest of the places which use the category. -etai
}

export function getDefaultLabel(
  paramType: keyof typeof SKIN_PARAM_DEFINITIONS,
) {
  return SKIN_PARAM_DEFINITIONS[paramType]?.label;
}

export function getControlType(paramType: keyof typeof SKIN_PARAM_DEFINITIONS) {
  return SKIN_PARAM_DEFINITIONS[paramType]?.controlType;
}
