'use strict'

module.exports = {
    featuredSection: require('../sections/vectorArt/featuredSection'),
    campaign_featuredSection: require('../sections/vectorArt/campaign_featuredSection'),
    iconsSection: require('../sections/vectorArt/iconsSection'),
    animalNatureSection: require('../sections/vectorArt/animalNatureSection'),
    logosBadgesSection: require('../sections/vectorArt/logosBadgesSection'),
    charactersSection: require('../sections/vectorArt/charactersSection')

}
