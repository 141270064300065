// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import experiment from 'experiment';
import * as core from '@/core';
import * as util from '@/util';
import * as designPanelUtils from '@/designPanelUtils';
import * as stateManagement from '@/stateManagement';
import customDesignPanel from './customDesignPanel';
import changeStyleDesignPanel from '../panelTypes/changeStyleDesignPanel';
import changeDataDesignPanel from '../panelTypes/changeDataDesignPanel';
import changeGalleryDesignPanel from '../panelTypes/changeGalleryDesignPanel';
import wixappsDesignPanel from '../wixapps/wixappsDesignPanel';
import cssDesignDataPanel from '../panelTypes/cssDesignDataPanel';
import changeGoogleMapDesignPanel from '../panelTypes/changeGoogleMapDesignPanel';
import changeDataCustomizeDesignPanel from '../panelTypes/changeDataCustomizeDesignPanel';
import TextMaskDesignPanel from '../panelTypes/TextMask/TextMask';

const componentsSelectors = stateManagement.components.selectors;

const LOAD_CUSTOM_PANEL_EXPERIMENT = 'se_customDesignPanelInClassic';

const panelTypes = {
  'wysiwyg.common.components.imagebutton.viewer.ImageButton': {
    desktop: changeDataDesignPanel,
  },
  'wysiwyg.viewer.components.documentmedia.DocumentMedia': {
    desktop: changeDataDesignPanel,
  },
  'wysiwyg.viewer.components.MatrixGallery': {
    desktop: changeGalleryDesignPanel,
  },
  'wysiwyg.viewer.components.PaginatedGridGallery': {
    desktop: changeGalleryDesignPanel,
  },
  'wysiwyg.viewer.components.SliderGallery': {
    desktop: changeGalleryDesignPanel,
  },
  'wysiwyg.viewer.components.SlideShowGallery': {
    desktop: changeGalleryDesignPanel,
  },
  'tpa.viewer.components.Masonry': { desktop: changeGalleryDesignPanel },
  'tpa.viewer.components.Accordion': { desktop: changeGalleryDesignPanel },
  'tpa.viewer.components.Impress': { desktop: changeGalleryDesignPanel },
  'tpa.viewer.components.Freestyle': { desktop: changeGalleryDesignPanel },
  'tpa.viewer.components.Collage': { desktop: changeGalleryDesignPanel },
  'tpa.viewer.components.Honeycomb': { desktop: changeGalleryDesignPanel },
  'tpa.viewer.components.StripShowcase': { desktop: changeGalleryDesignPanel },
  'tpa.viewer.components.StripSlideshow': { desktop: changeGalleryDesignPanel },
  'tpa.viewer.components.Thumbnails': { desktop: changeGalleryDesignPanel },
  'wysiwyg.viewer.components.tpapps.TPA3DGallery': {
    desktop: changeGalleryDesignPanel,
  },
  'wysiwyg.viewer.components.tpapps.TPA3DCarousel': {
    desktop: changeGalleryDesignPanel,
  },
  'wixapps.integration.components.AppPart': { desktop: wixappsDesignPanel },
  'wysiwyg.viewer.components.HoverBox': { desktop: cssDesignDataPanel },
  'wysiwyg.viewer.components.GoogleMap': {
    desktop: changeGoogleMapDesignPanel,
  },
  'wixui.RatingsDisplay': { desktop: changeDataCustomizeDesignPanel },
  'wixui.RatingsInput': { desktop: changeDataCustomizeDesignPanel },
  'wixui.TextMask': { desktop: TextMaskDesignPanel },
};

const componentsToChangeProperties = [
  'wysiwyg.viewer.components.QuickActionBar',
];

function getPanelType(compType) {
  const panelType = panelTypes[compType];
  if (!panelType) {
    return changeStyleDesignPanel;
  }
  return (
    (this.getEditorAPI().isMobileEditor() && panelType.mobile) ||
    panelType.desktop
  );
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'designPanelFactory',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    compType: PropTypes.string,
    shouldMaintainOriginalLayout: PropTypes.bool,
    contentOnly: PropTypes.bool,
  },

  getInitialState() {
    return { customPanel: undefined };
  },

  componentWillUnmount() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  },

  getComponentType() {
    const editorAPI = this.getEditorAPI();
    let { compType, selectedComponent } = this.props;
    if (!selectedComponent || !compType) {
      selectedComponent = editorAPI.selection.getSelectedComponents();
      compType = editorAPI.components.getType(selectedComponent);
    }
    return compType;
  },

  render() {
    const editorAPI = this.getEditorAPI();
    const { selectedComponent } = this.props;
    const compType = this.getComponentType();
    const selectedComponents = util.array.asArray(selectedComponent);

    const areAnyMobileOnlyComps =
      editorAPI.mobile.mobileOnlyComponents.areAnyMobileOnlyNonNativeComponent(
        selectedComponents,
      );

    const isStylableSkin = componentsSelectors.isStylable(
      selectedComponent,
      editorAPI.dsRead,
    );
    const shouldLoadCustomPanel =
      experiment.isOpen(LOAD_CUSTOM_PANEL_EXPERIMENT) &&
      !isStylableSkin &&
      customDesignPanel.hasCustomDesignPanel(compType);

    const panel = shouldLoadCustomPanel
      ? customDesignPanel.panel
      : getPanelType.call(this, compType);

    const childProps = {
      compType,
      selectedComponent,
      areAnyMobileOnlyComps,
      shouldMaintainOriginalLayout: true,
      shouldChangeProperties: componentsToChangeProperties.includes(compType),
    };

    if (
      designPanelUtils.customizeDesignComponents.showAdvancedStylingOnly(
        editorAPI,
        selectedComponent,
        compType,
      )
    ) {
      childProps.onlyAdvancedStyling = true;
    }

    return React.createElement(panel, Object.assign(childProps, this.props));
  },
});
