// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'gfppCustomButton',
  render() {
    return React.createElement(this.props.template, this.props);
  },
});
