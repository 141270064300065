// @ts-nocheck
import {
  environment,
  editorDataFunction,
  componentInstanceData,
} from '@wix/editor-component-data-resolver';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';

const { isTextEditorOpen, isStyleOnly } = stateManagement.text.selectors;
const isMovable = (state) => !(isTextEditorOpen(state) && !isStyleOnly(state));

export default {
  pinnable: true,
  verticallyResizable: editorDataFunction(
    componentInstanceData('componentProperties'),
    environment('isMobileEditor'),
    (compProps, isMobileEditor) => !isMobileEditor || compProps?.verticalText,
  ),
  horizontallyResizable: editorDataFunction(
    componentInstanceData('componentProperties'),
    environment('isMobileEditor'),
    (compProps, isMobileEditor) => !isMobileEditor || !compProps?.verticalText,
  ),
  allowedMobileOnly: true,
  disabledKnobs: editorDataFunction(
    componentInstanceData('componentProperties'),
    environment('isMobileEditor'),
    (compProps, isMobileEditor) => {
      if (!isMobileEditor) {
        return [constants.RESIZE_SIDES.TOP];
      }

      return compProps?.verticalText
        ? [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]
        : [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.BOTTOM];
    },
  ),
  resizableWithAnchors: false,
  resizableDiagonally: false,
  allowConnectToDB: true,
  overrideProportionalResize: false,
  horizontallyMovable: ({ store }) => isMovable(store.getState()),
  verticallyMovable: ({ store }) => isMovable(store.getState()),
  overridesWhenReferred: editorDataFunction(
    environment('isMobileEditor'),
    (isMobileEditor) => ({
      gfpp: {
        disableActions: isMobileEditor
          ? [
              constants.ROOT_COMPS.GFPP.ACTIONS.SCALE_UP,
              constants.ROOT_COMPS.GFPP.ACTIONS.SCALE_DOWN,
            ]
          : [],
      },
    }),
  ),
};
