import React, { type FC } from 'react';
import * as util from '@/util';
import { ToggleSwitch } from '@wix/wix-base-ui';
import type { EditorAPI } from '@/editorAPI';
import type { MapStateToProps } from 'types/redux';
import styles from './devModeToggle.scss';

type IsDevModeEnabled = EditorAPI['developerMode']['isEnabled'];

export interface DevModeToggleStateProps {
  isDevModeEnabled: IsDevModeEnabled;
}
interface DevModeToggleProps {
  toggle: () => void;
  isDevModeEnabled: IsDevModeEnabled;
}

const DevModeToggle: FC<DevModeToggleProps> = ({
  toggle,
  isDevModeEnabled,
}: DevModeToggleProps) => (
  <div
    className={styles.devModeToggleContainer}
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    <ToggleSwitch
      automationId="code-btn-code-on"
      className={styles.devModeToggle}
      size="MEDIUM"
      onChange={() => toggle()}
      value={isDevModeEnabled()}
    />
  </div>
);

export const mapStateToProps: MapStateToProps<DevModeToggleStateProps> = ({
  editorAPI,
}) => ({
  isDevModeEnabled: editorAPI.developerMode.isEnabled,
});

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  null,
)(DevModeToggle);
