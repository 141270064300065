import type { PlatformAppDescription } from 'types/documentServices';

export interface PlatformAppItemDefinition {
  title: string;
  desc: string;
  image: string;
  imageSource: keyof typeof ImageSources;
  id: string;
  presetId?: string;
  appExtraData: any;
  size: {
    width: number;
    height: number;
  };
  widgetId?: string;
}

export interface PlatformAppCategoryDefinition {
  type: 'LIST';
  title: string;
  header: string;
  items: PlatformAppItemDefinition[];
}

export interface PlatformAppWithCategoryDefinition {
  app: PlatformAppDescription;
  categoryDefinition: PlatformAppCategoryDefinition;
}

export const ImageSources = {
  IMAGE_LIST: 'IMAGE_LIST',
  URL: 'URL',
} as const;
