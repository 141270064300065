import { createSelector } from '../../../selectors/selector';
import { isDraggingSel, isResizingSel } from '../../selectors/mouseSelectors';
import { zoomModeSel } from '../../selectors/other';

export const shouldShowParentContainerBox = createSelector(
  [isDraggingSel, isResizingSel, zoomModeSel],
  (isDragging, isResizing, isInZoomMode) => {
    return !(isDragging || isResizing) && !isInZoomMode;
  },
);
