'use strict'

const listEventsSection = require('../sections/list/listEventsSection')
const listProductsAndServicesSection = require('../sections/list/listProductsAndServicesSection')
const listNewsPostsSection = require('../sections/list/listNewsPostsSection')
const listTestimonialsSection = require('../sections/list/listTestimonialsSection')
const listTeamMembersSection = require('../sections/list/listTeamMembersSection')
const listOtherSection = require('../sections/list/listOtherSection')

module.exports = {
    // order matters
    listNewsPostsSection,
    listEventsSection,
    listTestimonialsSection,
    listProductsAndServicesSection,
    listTeamMembersSection,
    listOtherSection
}
