// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import * as coreBi from '@/coreBi';
import PropTypes from 'prop-types';
import React from 'react';
import WelcomeVideoPanel from './welcomeVideoPanel';
import { translate } from '@/i18n';
import * as baseUI from '@/baseUI';
const VIDEO_URL = 'www.youtube.com/embed/CCFWEJ6r3NA';
const VIDEO_URL_PARAMS =
  '?modestbranding=1;rel=0&frameborder=0;allowfullscreen=0;autoplay=1;controls=1;autohide=1;showinfo=0';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'mobileEditorFirstTimeVideoPanel',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    panelName: PropTypes.string.isRequired,
  },
  videoUrl: `//${VIDEO_URL}${VIDEO_URL_PARAMS}`,
  close() {
    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },

  startNow() {
    this.sendBIEvent('startNow');
    this.close();
  },

  sendBIEventWhenClosedByX() {
    this.sendBIEvent('xButton');
    this.close();
  },

  sendBIEventWhenClosedByESC() {
    this.sendBIEvent('ESC');
    this.close();
  },

  getContent() {
    const content = {};
    content.line1 = 'Intro_video_mobile_user_dialog_text1';
    content.line2 = 'Intro_video_mobile_user_dialog_text2';
    return content;
  },

  sendBIEvent(origin) {
    const editorAPI = this.getEditorAPI();

    const BIData = {
      origin,
      site_id: editorAPI.dsRead.generalInfo.getSiteId(),
      duration: Date.now() - this.displayTime,
      video_url: VIDEO_URL,
      panel_name: this.constructor.displayName,
    };

    editorAPI.bi.event(
      coreBi.events.newUserIntroVideoPanel.INTRO_VIDEO_CLOSE,
      BIData,
    );
  },

  componentDidMount() {
    this.displayTime = Date.now();
  },

  render() {
    return (
      <WelcomeVideoPanel
        ref="frame"
        title={translate('Intro_video_mobile_user_dialog_title')}
        subtitle={translate('Intro_video_mobile_user_dialog_subtitle')}
        linkedVideoData={this.videoUrl}
        content={this.getContent()}
        panelName={this.props.panelName}
        shouldNotCloseOnBlur={true}
        onCloseByX={this.sendBIEventWhenClosedByX}
        onCloseByESC={this.sendBIEventWhenClosedByESC}
      >
        <div className="buttons">
          <baseUI.button
            label="Intro_video_mobile_user_dialog_button"
            onClick={() => {
              this.startNow();
            }}
          />
        </div>
      </WelcomeVideoPanel>
    );
  },
});
