import React from 'react';
import * as panels from '@/panels';
import { RichText } from '@wix/wix-base-ui';
import { translate as t } from '@/i18n';
import * as baseUI from '@/baseUI';
import { hoc } from '@/util';
import { WorkspaceModesApiKey } from '@/apis';

import type { EditorAPI } from '@/editorAPI';
import type { InferComponentProps } from '@/apilib';
import type { EditorState } from '@/stateManagement';
import type { StateMapperArgs, Dispatch } from 'types/redux';

// TODO move it to workspaceModes package and contribute with panelsApi when it will be live

interface SwitchModePanelOwnProps {
  panelName: string;
  origin: string;
  actionNameKey: string;
  callback: () => void;
}

type SwitchModePanelProps = InferComponentProps<
  null,
  typeof mapDispatchToProps,
  SwitchModePanelOwnProps
>;

export const SwitchWorkspaceModePanelPure: React.FC<SwitchModePanelProps> = ({
  callback,
  actionNameKey,
  panelName,
  closePanel,
  switchToFullEditor,
}) => {
  return (
    <panels.frames.MessagePanelFrame
      panelName={panelName}
      title={t('lite_editor_app_manager_actions_modal_title', {
        name_of_tool: t(actionNameKey),
      })}
      confirmLabel={t('lite_editor_app_manager_actions_modal_main_cta')}
      cancelLabel={t('lite_editor_app_manager_actions_modal_secondary_cta')}
      onConfirm={() => {
        switchToFullEditor();
        callback();
      }}
      onCancel={closePanel}
      illustration={<baseUI.symbol name="saveYourWork_NewWorkspace" />}
      dataHook={'switch-workspace-mode-panel'}
    >
      <RichText type="T01">
        {t('lite_editor_app_manager_actions_modal_body')}
      </RichText>
    </panels.frames.MessagePanelFrame>
  );
};

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: StateMapperArgs,
) => editorAPI;

const mapDispatchToProps = (
  dispatch: AnyFixMe,
  ownProps: SwitchModePanelOwnProps,
) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const workspaceModesApi = editorAPI.host.getAPI(WorkspaceModesApiKey);
  return {
    switchToFullEditor: () => {
      workspaceModesApi.switchToFullMode(ownProps.origin);
    },
    closePanel: () => {
      editorAPI.panelManager.closePanelByName(
        'panels.messagePanels.switchWorkspaceModePanel',
      );
    },
  };
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(SwitchWorkspaceModePanelPure);
