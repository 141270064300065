import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import {
  getFullWidthValueFromDocking,
  isMeshLayoutEnabled,
} from '@/layoutOneDockMigration';
import * as mediaDialogHandlerUtils from '../utils/mediaDialogHandlerUtils';
import * as packagePicker from '../packagePicker/packagePicker';
import constants from '../constants/constants';
import * as dashboardAppService from '../services/dashboardAppService';
import * as bi from '../bi/bi';
import * as tpaStyleService from '../services/tpaStyleService';
import * as tpaAppIsAliveService from '../services/tpaAppIsAliveService';
import * as appMarketUtils from '../appMarket/utils/appMarketUtils';
import * as mediaDialogService from '../services/mediaDialogService';
import * as tpaAddComponentService from '../services/tpaAddComponentService';
import * as tpaSettingsModalService from '../services/tpaSettingsModalService';
import * as editorSuperAppsHandlers from './editorSuperAppsHandlers';
import * as editorRefreshTPADataAfterUpgrade from './editorRefreshTPADataAfterUpgrade';
import * as platform from '@/platform';
import experiment from 'experiment';
import type { EditorAPI } from '@/editorAPI';
import type {
  AppData,
  CompRef,
  Dock,
  Docking,
  Rect,
} from 'types/documentServices';

type TPAMessageDataAny = Record<string, any>;

export interface TPAMessage<
  TData extends TPAMessageDataAny = TPAMessageDataAny,
> {
  type: string;
  compId: string;
  namespace: string;
  data?: TData;
}
export type TPAMessageCallback = (args?: unknown) => void;
export type TPAHandler<TData extends TPAMessageDataAny = TPAMessageDataAny> = (
  editorAPI: EditorAPI,
  msg: TPAMessage<TData>,
  callback?: TPAMessageCallback,
) => void;

const openBillingPage: TPAHandler = function (editorAPI, msg) {
  const origCompData = editorAPI.selection.getSelectedComponentData();
  if (!origCompData) {
    return;
  }
  const appData = editorAPI.dsRead.tpa.app.getData(origCompData.applicationId);
  if (packagePicker.isFullyUpgraded(editorAPI, appData)) {
    return;
  }

  const isHandlerFlow = true;
  const options: AnyFixMe = { origin: msg?.data?.referrer || 'settings' };
  const premiumIntent = msg?.data?.premiumIntent;
  if (premiumIntent) {
    options.premiumIntent = premiumIntent;
  }
  packagePicker.startUpgradeFlow(
    editorAPI,
    appData.appDefinitionId,
    isHandlerFlow,
    options,
  );
};

const appEngaged: TPAHandler = function (editorAPI, msg) {
  const origCompData = editorAPI.selection.getSelectedComponentData();
  if (!origCompData) {
    return;
  }
  const appData = editorAPI.dsRead.tpa.app.getData(origCompData.applicationId);

  const params = {
    app_id: appData.appDefinitionId,
    instance_id: appData.instanceId,
    premium_intent: msg?.data?.premiumIntent,
  };
  editorAPI.bi.event(bi.events.APP_ENGAGEMENT, params);
};

const triggerSettingsUpdatedEvent: TPAHandler = function (editorAPI, msg) {
  const origCompData = editorAPI.selection.getSelectedComponentData();
  if (!origCompData) {
    return;
  }
  // @ts-expect-error
  editorAPI.dsActions.tpa.change.trigger.settingsUpdated(
    origCompData.applicationId,
    msg.data.compId,
    msg.data.message,
  );
};

const openMediaDialog: TPAHandler = function (editorAPI, msg, callback) {
  if (
    // @ts-expect-error
    editorAPI.dsRead.tpa.app.isSuperAppByCompId(msg.compId) &&
    msg.namespace === 'SuperApps.Settings'
  ) {
    editorSuperAppsHandlers.superAppsOpenMediaDialog(editorAPI, msg, callback);
  } else {
    _.unset(msg, ['data', 'options', 'imageAnalysis']);

    const parseMediaDialogPayload = function (mediaDialogPayload: AnyFixMe) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
      if (!_.isArray(mediaDialogPayload)) {
        mediaDialogPayload = [mediaDialogPayload];
      }

      let mediaItems = mediaDialogPayload.map(
        mediaDialogHandlerUtils.convertMediaManagerResult,
      );
      mediaItems = msg.data.multiSelection ? mediaItems : mediaItems[0];
      return mediaItems;
    };

    mediaDialogService.openMediaDialogByPayloadParsingMethod(
      editorAPI,
      msg,
      parseMediaDialogPayload,
      callback,
    );
  }
};

const openSiteMembersSettingsDialog = function (editorAPI: EditorAPI) {
  editorAPI.panelManager.openPanel(
    'panels.focusPanels.siteMembersSettingsPanel',
    {},
    true,
  );
};

const setStyleParam: TPAHandler = function (editorAPI, msg, callback) {
  tpaStyleService.setStyleParam(editorAPI, msg.compId, msg.data, callback);
  // @ts-expect-error
  editorAPI.dsActions.tpa.style.setParamOldSDK(msg.compId, msg.data);
};

const getDashboardAppUrl: TPAHandler = function (editorAPI, msg, callback) {
  dashboardAppService.getDashboardAppUrlAsync(editorAPI, msg.data, callback);
};

const getCurrentPageId: TPAHandler = function (editorAPI, msg, callback) {
  const pageId = editorAPI.pages.getCurrentPageId();
  callback(pageId);
};

const appIsAlive: TPAHandler = function (editorAPI, msg, callback) {
  tpaAppIsAliveService.appIsAlive(editorAPI, msg, callback);
};

const stylesReady: TPAHandler = function (editorAPI, msg) {
  editorAPI.panelManager.updatePanelProps('tpa.compPanels.tpaSettings', {
    stylesReady: true,
    sdkVersion: msg?.data?.version,
  });
};

const setWindowPlacement: TPAHandler = function (editorAPI, msg) {
  const { placement } = msg.data;
  if (placement === 'CENTER') {
    throw new Error(
      `Invalid position: ${placement}. please use a valid position`,
    );
  }
  const verticalMargin = msg.data.verticalMargin || 0;
  const horizontalMargin = msg.data.horizontalMargin || 0;

  const compRef = editorAPI.components.get.byId(msg.compId, 'masterPage');
  const compProps = editorAPI.components.properties.get(compRef);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  _.assign(compProps, {
    placement,
    verticalMargin,
    horizontalMargin,
  });

  editorAPI.components.properties.update(compRef, compProps);
  //editBox.fitToComp(); //TODO: check if needed
  // @ts-expect-error
  editorAPI.dsActions.tpa.change.trigger.windowPlacement(msg);
};

const getWindowPlacement: TPAHandler = function (editorAPI, msg, callback) {
  const compProps: AnyFixMe = editorAPI.components.properties.get(
    editorAPI.selection.getSelectedComponents(),
  );

  if (compProps) {
    callback({
      placement: compProps.placement,
      verticalMargin: parseFloat(compProps.verticalMargin),
      horizontalMargin: parseFloat(compProps.horizontalMargin),
    });
  } else {
    throw new Error('Selected widget is not Glued Widget');
  }
};

const settingsOpenModal: TPAHandler = function (editorAPI, msg, callback) {
  tpaSettingsModalService.settingsOpenModal(editorAPI, msg, callback);
};

const setExternalId: TPAHandler = function (editorAPI, msg, callback) {
  const compPointer = editorAPI.components.get.byId(msg.compId);
  // @ts-expect-error
  editorAPI.dsActions.tpa.comp.setExternalId(
    compPointer,
    msg.data.externalId,
    callback,
    msg.data.preventRefresh,
  );
};

const getExternalId: TPAHandler = function (editorAPI, msg, callback) {
  const compPointer = editorAPI.components.get.byId(msg.compId);
  // @ts-expect-error
  const externalId = editorAPI.dsRead.tpa.comp.getExternalId(compPointer);
  callback(externalId);
};

const addComponent: TPAHandler = function (editorAPI, msg, callback) {
  if (msg.data.appDefinitionId) {
    const origCompData = editorAPI.selection.getSelectedComponentData();
    const appData = editorAPI.dsRead.tpa.app.getData(
      origCompData?.applicationId,
    );
    if (appData && !appData.isWixTPA) {
      delete msg.data.appDefinitionId;
    }
  }

  tpaAddComponentService.addComponent(
    editorAPI,
    msg.compId,
    msg.data as tpaAddComponentService.AddTpaComponentInitialOptions,
    function (result: { compId?: string }) {
      if (result?.compId) {
        callback(result.compId);
      } else {
        callback(result);
      }
    },
  );
};

const openReviewInfo: TPAHandler = function (editorAPI) {
  const origCompData = editorAPI.selection.getSelectedComponentData();
  const appData = editorAPI.dsRead.tpa.app.getData(origCompData.applicationId);
  if (experiment.isOpen('specs.marketplace.newReviewComponent')) {
    const iframeUrl = `https://www.wix.com/app-market/add-review/${appData.appDefinitionId}?origin=editorSdk`;
    appMarketUtils.openAppMarketModal(
      editorAPI.panelManager.openPanel,
      constants.MODALS.MARKET,
      {
        url: iframeUrl,
        height: 645,
        width: 600,
        shouldHideHeader: false,
        title: 'app_market_title',
      },
    );
  } else {
    if (!appData.appDefinitionId) return;
    editorAPI.dsRead.tpa.appMarket
      .getDataAsync(appData.appDefinitionId)
      .then(function (appMarketData) {
        if (appMarketData) {
          const appMarketParams = {
            origin: appMarketUtils.origin(),
            openMarketOrigin: 'settings_panel',
          };
          // @ts-expect-error
          const appMarketUrl = editorAPI.dsRead.tpa.appMarket.getAppReviewsUrl(
            appMarketParams,
            // @ts-expect-error
            appMarketData.slug,
          );
          appMarketUtils.openAppMarketModal(
            editorAPI.panelManager.openPanel,
            constants.MODALS.APP,
            { url: appMarketUrl },
          );
        }
      });
  }
};

type StretchMarginValue =
  | `${number}`
  | `${number}px`
  | `${number}vw`
  | `${number}%`;

interface StretchMargins {
  left?: StretchMarginValue;
  right?: StretchMarginValue;
}

/**
 * Handler caller https://github.com/wix-private/js-sdk/blob/e2f519f8afe6e8db9ef96b03614bf62b3c5cd732/packages/wix-sdk/js/modules/Settings.js#L276-L310
 */
const setFullWidth: TPAHandler<{
  stretch?: boolean;
  margins?: StretchMargins;
}> = function (editorAPI, msg, callback) {
  const shouldStretch = msg.data.stretch;
  const selectedComponent = editorAPI.selection.getSelectedComponents();
  const isStretched =
    editorAPI.components.layout.isHorizontallyStretchedToScreen(
      selectedComponent,
    );

  if (shouldStretch) {
    const margins = getMarginValuesIfValid(msg?.data?.margins);

    if (margins) {
      stretchCompWithMargins(editorAPI, margins, selectedComponent, callback);
    } else {
      callback({ onError: 'Margin values are not valid.' });
    }
  } else if (isStretched) {
    editorAPI.dsActions.waitForChangesApplied(function () {
      unstretchComp(editorAPI, selectedComponent, callback);
    });
  }
};

function getMarginValuesIfValid(
  margins: StretchMargins,
): Pick<Docking, 'left' | 'right'> {
  if (margins) {
    const leftMargin = getMarginObjIfCan(margins.left);
    const rightMargin = getMarginObjIfCan(margins.right);

    if (leftMargin && rightMargin) {
      return {
        left: leftMargin || {},
        right: rightMargin || {},
      };
    }
    return null;
  }
  return {};
}

function getMarginObjIfCan(margin: StretchMarginValue): Dock {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-nan
  if (!_.isNaN(_.toNumber(margin))) {
    return { px: _.toNumber(margin) };
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/ends-with
  } else if (_.endsWith(margin, 'px')) {
    return { px: parseFloat(margin) };
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/ends-with
  } else if (_.endsWith(margin, 'vw')) {
    return { vw: parseFloat(margin) };
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/ends-with
  } else if (_.endsWith(margin, '%')) {
    return { pct: parseFloat(margin) };
  }

  return null;
}

function stretchCompWithMargins(
  editorAPI: EditorAPI,
  margins: Pick<Docking, 'left' | 'right'>,
  selectedComponent: CompRef[],
  callback: TPAMessageCallback,
) {
  const leftMargins = margins?.left || {};
  const rightMargins = margins?.right || {};
  const dockingValues = {
    left: getMarginDockingValues(leftMargins),
    right: getMarginDockingValues(rightMargins),
  };

  if (isMeshLayoutEnabled() && _.isEqual(leftMargins, rightMargins)) {
    const { siteMargin } = getFullWidthValueFromDocking(dockingValues);

    editorAPI.components.layout
      .stretchComponent(selectedComponent, {
        siteMargin,
      })
      .then(editorAPI.dsActions.waitForChangesAppliedAsync)
      .then(callback);
    return;
  }

  if (isMeshLayoutEnabled() && !_.isEqual(leftMargins, rightMargins)) {
    // NOTE: cases when left and right margins are different are deprecated
    // discussion: https://wix.slack.com/archives/C04EX7N2BCP/p1696938539527859?thread_ts=1696867390.266509&cid=C04EX7N2BCP
    // > Lior Hai
    // > But maybe a product change is also acceptable in this case and we can set same padding for left/right
    // Fall back to deprecated adapter
    console.error(
      'Left and right margins are different. Fallback to deprecated adapter.',
    );
  }

  // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
  editorAPI.components.layout.setDock(selectedComponent, dockingValues, {
    allowOneDockAdapter_DONT_USE: true,
  });
  editorAPI.dsActions.waitForChangesApplied(callback);
}

function getMarginDockingValues(margins: Dock): Dock {
  if (_.isNumber(margins.pct)) {
    return { pct: margins.pct };
  }
  return { vw: margins.vw || 0, px: margins.px || 0 };
}

function unstretchComp(
  editorAPI: EditorAPI,
  selectedComponent: CompRef[],
  callback: TPAMessageCallback,
) {
  const selectedCompData = editorAPI.selection.getSelectedComponentData();
  // source: https://github.com/wix-private/document-management/blob/c9dba0485fb13cda8c785fa73afaa97b933b5ef0/document-services-implementation/src/tpa/services/tpaComponentService.ts#L298
  const positionAndSizeBeforeStretched =
    // @ts-expect-error
    editorAPI.dsRead.tpa.app.getDefaultLayout(
      selectedCompData.applicationId,
      selectedCompData.widgetId,
    ) as Rect;
  positionAndSizeBeforeStretched.height =
    editorAPI.components.layout.get_size(selectedComponent).height;

  if (isMeshLayoutEnabled()) {
    editorAPI.components.layout
      .unStretchComponent(selectedComponent, {
        compRect: positionAndSizeBeforeStretched,
      })
      .then(editorAPI.dsActions.waitForChangesAppliedAsync)
      .then(callback);
    return;
  }

  // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
  editorAPI.components.layout.unDock(selectedComponent);
  editorAPI.dsActions.waitForChangesApplied(function () {
    editorAPI.components.layout.update(
      selectedComponent,
      positionAndSizeBeforeStretched,
    );
    callback();
  });
}

const isOtherAppAvailable = function (
  editorAPI: EditorAPI,
  otherApplicationAppData: AppData,
) {
  return (
    otherApplicationAppData &&
    // @ts-expect-error
    !editorAPI.dsRead.tpa.app.isPermissionsRevoked(otherApplicationAppData)
  );
};

const getProductsForDifferentApp = function (
  editorAPI: EditorAPI,
  appDefinitionId: string,
  currency: string,
  callback: TPAMessageCallback,
) {
  const otherApplicationAppData =
    editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId);
  if (isOtherAppAvailable(editorAPI, otherApplicationAppData)) {
    editorAPI.dsRead.tpa.appMarket
      // @ts-expect-error
      .getPackages(appDefinitionId, otherApplicationAppData?.instanceId, {
        currency,
      })
      .then(callback, callback);
  } else {
    callback({ error: 'app is not installed' });
  }
};

const getProducts: TPAHandler = function (editorAPI, msg, callback) {
  const origCompData = editorAPI.selection.getSelectedComponentData();
  const origCompAppData = editorAPI.dsRead.tpa.app.getData(
    origCompData?.applicationId,
  );
  const appDefinitionId = msg?.data?.appDefinitionId;
  const currency = msg?.data?.currency;
  if (appDefinitionId && origCompAppData.isWixTPA) {
    getProductsForDifferentApp(editorAPI, appDefinitionId, currency, callback);
  } else {
    editorAPI.dsRead.tpa.appMarket
      // @ts-expect-error
      .getPackages(
        origCompAppData?.appDefinitionId,
        origCompAppData?.instanceId,
        {
          currency,
        },
      )
      .then(callback, callback);
  }
};

const isComponentInstalled: TPAHandler = function (editorAPI, msg, callback) {
  const tpaComponentId = msg?.data?.componentId;
  const origCompData = editorAPI.selection.getSelectedComponentData();
  if (origCompData) {
    const appData = editorAPI.dsRead.tpa.app.getData(
      origCompData.applicationId,
    );
    if (appData) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      const widgetData = _.find(appData.widgets, function (widget: AnyFixMe) {
        if (widget.appPage) {
          return widget.appPage.id === tpaComponentId;
        }
        return widget.tpaWidgetId === tpaComponentId;
      });
      if (widgetData) {
        // @ts-expect-error
        callback(editorAPI.dsRead.tpa.comp.isInstalled(widgetData.widgetId));
        return;
      }
    }
  }
  callback(false);
};

const openLinkPanel: TPAHandler = function (editorAPI, msg, callback) {
  editorAPI.openLinkPanel({
    link: msg?.data?.link,
    hideLinkTarget: true,
    callback,
    onCancelCallback() {
      if (callback) {
        callback({
          onCancel: true,
        });
      }
    },
  });
};

const getPanelNamesByTarget = function (target: string) {
  switch (target.toLowerCase()) {
    case 'all':
      return [
        constants.TPA_SETTINGS_PANEL_ID,
        constants.TPA_SETTING_MODAL,
        platform.constants.panelTypes.DASHBOARD,
      ];
    case 'settings':
      return [constants.TPA_SETTINGS_PANEL_ID];
    case 'modal':
    default:
      return [
        constants.TPA_SETTING_MODAL,
        platform.constants.panelTypes.DASHBOARD,
      ];
  }
};

const closeWindow: TPAHandler = function (editorAPI, msg) {
  msg = msg || ({} as typeof msg);
  const openedPanels = editorAPI.panelManager.getOpenPanels();
  const target = msg.data?.message?.target ?? '';

  let panelsToClose = getPanelNamesByTarget(target);
  panelsToClose = _.reject(panelsToClose, function (panelName) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return !_.find(openedPanels, { name: panelName });
  });

  panelsToClose.forEach(function (panelName) {
    editorAPI.panelManager.updatePanelProps(panelName, {
      shouldCloseModal: true,
      closeModalMessage: msg?.data?.message,
    });
  });
};

// Not exposed in TPA SDK, BizMngr postMessages to it directly
const refreshAppsOnDashboardClose: TPAHandler = function (editorAPI, msg) {
  if (msg?.data) {
    let appsDefinitionIdsToRefresh = msg.data.appsToRefresh;
    const isMessageDataValid =
      _.isString(appsDefinitionIdsToRefresh) ||
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
      (_.isArray(appsDefinitionIdsToRefresh) &&
        appsDefinitionIdsToRefresh.every(_.isString));
    if (isMessageDataValid) {
      appsDefinitionIdsToRefresh = [].concat(appsDefinitionIdsToRefresh);
      editorAPI.store.dispatch(
        stateManagement.tpa.actions.addAppDefIdsToRefreshOnDashboardClose(
          appsDefinitionIdsToRefresh,
        ),
      );
    }
  }
};

const trackAppUpgrade: TPAHandler = function (editorAPI) {
  const origCompData = editorAPI.selection.getSelectedComponentData();
  editorRefreshTPADataAfterUpgrade.trackAppUpgrade(
    editorAPI,
    origCompData.applicationId,
  );
};

const getWixUpgradeUrl: TPAHandler = function (editorAPI, msg, callback) {
  const origCompData = editorAPI.selection.getSelectedComponentData() || {};
  const appData = editorAPI.dsRead.tpa.app.getData(origCompData.applicationId);
  if (
    appData &&
    // @ts-expect-error
    editorAPI.dsRead.tpa.app.hasPremiumOffering(appData.applicationId)
  ) {
    packagePicker.getWixUpgradeUrl(
      editorAPI,
      appData.appDefinitionId,
      callback,
    );
  }
};

const migrate: TPAHandler = function (editorAPI, msg, callback) {
  const { appDefinitionId, payload } = msg?.data ?? {};
  editorAPI.dsActions.platform
    // @ts-expect-error
    .migrate(appDefinitionId, payload)
    .then((result: AnyFixMe) => callback(result))
    .catch((e: AnyFixMe) => callback(e));
};

const refreshLivePreview: TPAHandler = function (editorAPI, msg) {
  const { appDefinitionIds, shouldFetchData } = msg?.data ?? {};
  const appsDefinitionIdsToRefresh = [].concat(appDefinitionIds);

  // @ts-expect-error
  editorAPI.dsActions.platform.livePreview.refresh({
    apps: appsDefinitionIdsToRefresh,
    source: 'editorTpaHandler',
    shouldFetchData: !!shouldFetchData,
  });
};

const dummyForBIEvents = function () {};

export {
  addComponent,
  appEngaged,
  appIsAlive,
  closeWindow,
  getCurrentPageId,
  getDashboardAppUrl,
  getExternalId,
  getProducts,
  getWindowPlacement,
  isComponentInstalled,
  openBillingPage,
  openLinkPanel,
  openMediaDialog,
  openReviewInfo,
  openSiteMembersSettingsDialog,
  triggerSettingsUpdatedEvent as postMessage,
  refreshAppsOnDashboardClose,
  setExternalId,
  setFullWidth,
  setStyleParam,
  setStyleParam as setGlobalStyleParam,
  settingsOpenModal,
  setWindowPlacement,
  stylesReady,
  trackAppUpgrade,
  getWixUpgradeUrl,
  migrate,
  refreshLivePreview,
  dummyForBIEvents as getViewModeInternal,
  dummyForBIEvents as toWixDate,
  dummyForBIEvents as getCompId,
  dummyForBIEvents as getOrigCompId,
  dummyForBIEvents as getWidth,
  dummyForBIEvents as getLocale,
  dummyForBIEvents as getCacheKiller,
  dummyForBIEvents as getTarget,
  dummyForBIEvents as getInstanceId,
  dummyForBIEvents as getSignDate,
  dummyForBIEvents as getUid,
  dummyForBIEvents as getPermissions,
  dummyForBIEvents as getIpAndPort,
  dummyForBIEvents as getDemoMode,
  dummyForBIEvents as getDeviceType,
  dummyForBIEvents as getInstanceValue,
  dummyForBIEvents as getSiteOwnerId,
  dummyForBIEvents as getImageUrl,
  dummyForBIEvents as getResizedImageUrl,
  dummyForBIEvents as getAudioUrl,
  dummyForBIEvents as getDocumentUrl,
  dummyForBIEvents as getSwfUrl,
  dummyForBIEvents as getPreviewSecureMusicUrl,
  dummyForBIEvents as getStyleParams,
  dummyForBIEvents as getStyleColorByKey,
  dummyForBIEvents as getColorByreference,
  dummyForBIEvents as getEditorFonts,
  dummyForBIEvents as getSiteTextPresets,
  dummyForBIEvents as getFontsSpriteUrl,
  dummyForBIEvents as getStyleFontByKey,
  dummyForBIEvents as getStyleFontByReference,
  dummyForBIEvents as getSiteColors,
  dummyForBIEvents as setUILIBParamValue,
};
