'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "design_section_label_explore_page",
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/pageStyleSection_en/pageStyleSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "page_0",
            "structure": {
                "type": "Page",
                "id": "c1dmp",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": " 1",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "1",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {"alpha-bg": "1", "bg": "#FFFFFF"},
                        "propertiesSource": {"bg": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "c1dmp",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                    "id": "c1dmp_i6gho7ed"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 0, "y": 15}}
        }, {
            "id": "page_1",
            "structure": {
                "type": "Page",
                "id": "ccs3",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.TransparentPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "2",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "2",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"properties": {}, "propertiesSource": {}, "groups": {}},
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "ccs3",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.TransparentPageSkin",
                    "id": "ccs3_i6ghopfq"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 108, "y": 15}}
        }, {
            "id": "page_2",
            "structure": {
                "type": "Page",
                "id": "c1aqm",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "3",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "3",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {"alpha-bg": "0.6", "bg": "#FFFFFF"},
                        "propertiesSource": {"bg": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "c1aqm",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                    "id": "c1aqm_i6ghu5in"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 216, "y": 15}}
        }, {
            "id": "page_3",
            "structure": {
                "type": "Page",
                "id": "ccv0",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "4",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "4",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {"alpha-bg": "1", "bg": "#232323"},
                        "propertiesSource": {"bg": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "ccv0",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                    "id": "ccv0_i6ghww0z"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 0, "y": 115}}
        }, {
            "id": "page_4",
            "structure": {
                "type": "Page",
                "id": "c1c8u",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "5",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "5",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {"alpha-bg": "0.6", "bg": "#000000"},
                        "propertiesSource": {"bg": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "c1c8u",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                    "id": "c1c8u_i6ghwf0v"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 108, "y": 115}}
        }, {
            "id": "page_5",
            "structure": {
                "type": "Page",
                "id": "c1571",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "6",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "6",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {"alpha-bg": "1", "bg": "#D6D6D6"},
                        "propertiesSource": {"bg": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "c1571",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                    "id": "c1571_i9mhhwlx"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 216, "y": 115}}
        }, {
            "id": "page_6",
            "structure": {
                "type": "Page",
                "id": "che0",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "7",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "7",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {"alpha-bg": "1", "bg": "#002E5D"},
                        "propertiesSource": {"bg": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "che0",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                    "id": "che0_i9mhkhjq"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 0, "y": 215}}
        }, {
            "id": "page_7",
            "structure": {
                "type": "Page",
                "id": "c10si",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "8",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "8",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {"alpha-bg": "1", "bg": "#276BA5"},
                        "propertiesSource": {"bg": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "c10si",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                    "id": "c10si_i6gh63jf"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 108, "y": 215}}
        }, {
            "id": "page_8",
            "structure": {
                "type": "Page",
                "id": "cui3",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "9",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "9",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {"alpha-bg": "1", "bg": "#3699EC"},
                        "propertiesSource": {"bg": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "cui3",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.BasicPageSkin",
                    "id": "cui3_i6ghxrsg"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 216, "y": 215}}
        }, {
            "id": "page_9",
            "structure": {
                "type": "Page",
                "id": "c1ku0",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.LiftedTopPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "10",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "10",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#D8053F",
                            "brd": "#F2668F",
                            "brw": "0px"
                        }, "propertiesSource": {"bg": "value", "brd": "value", "brw": "value"}, "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "c1ku0",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.LiftedTopPageSkin",
                    "id": "c1ku0_i6ghxwmz"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 0, "y": 315}}
        }, {
            "id": "page_10",
            "structure": {
                "type": "Page",
                "id": "c1znz",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.LiftedTopPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "11",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "11",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#F00664",
                            "brd": "#1C8A43",
                            "brw": "0px"
                        }, "propertiesSource": {"bg": "value", "brd": "value", "brw": "value"}, "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "c1znz",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.LiftedTopPageSkin",
                    "id": "c1znz_i6ghy1hd"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 108, "y": 315}}
        }, {
            "id": "page_11",
            "structure": {
                "type": "Page",
                "id": "cued",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.LiftedTopPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "12",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "12",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#F4648E",
                            "brd": "#BDBF37",
                            "brw": "0px"
                        }, "propertiesSource": {"bg": "value", "brd": "value", "brw": "value"}, "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "cued",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.LiftedTopPageSkin",
                    "id": "cued_i6ghy4v1"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 216, "y": 315}}
        }, {
            "id": "page_12",
            "structure": {
                "type": "Page",
                "id": "c5qs",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.LiftedBottomPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "13",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "13",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#BDC125",
                            "brd": "#006C6D",
                            "brw": "0px"
                        }, "propertiesSource": {"bg": "value", "brd": "value", "brw": "value"}, "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "c5qs",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.LiftedBottomPageSkin",
                    "id": "c5qs_i6ghy88v"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 0, "y": 415}}
        }, {
            "id": "page_13",
            "structure": {
                "type": "Page",
                "id": "c23mp",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.LiftedBottomPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "14",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "14",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#66B147",
                            "brd": "#01B1AF",
                            "brw": "0px"
                        }, "propertiesSource": {"bg": "value", "brd": "value", "brw": "value"}, "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "c23mp",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.LiftedBottomPageSkin",
                    "id": "c23mp_i6giadqm"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 108, "y": 415}}
        }, {
            "id": "page_14",
            "structure": {
                "type": "Page",
                "id": "caeb",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.LiftedBottomPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "15",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "15",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#128B40",
                            "brd": "#342216",
                            "brw": "0px"
                        }, "propertiesSource": {"bg": "value", "brd": "value", "brw": "value"}, "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "caeb",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.LiftedBottomPageSkin",
                    "id": "caeb_i6giahrw"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 216, "y": 415}}
        }, {
            "id": "page_15",
            "structure": {
                "type": "Page",
                "id": "c1c5",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.InnerShadowPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "16",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "16",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#00B1B0",
                            "brd": "#163E3A",
                            "brw": "0px",
                            "rd": "0px"
                        },
                        "propertiesSource": {"bg": "value", "brd": "value", "brw": "value", "rd": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "c1c5",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.InnerShadowPageSkin",
                    "id": "c1c5_i6giamgq"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 0, "y": 515}}
        }, {
            "id": "page_16",
            "structure": {
                "type": "Page",
                "components": [],
                "skin": "wysiwyg.viewer.skins.page.InnerShadowPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "17",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "17",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#00928F",
                            "brd": "#B22087",
                            "brw": "0px",
                            "rd": "0px"
                        },
                        "propertiesSource": {"bg": "value", "brd": "value", "brw": "value", "rd": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "cxl8",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.InnerShadowPageSkin"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 108, "y": 515}}
        }, {
            "id": "page_17",
            "structure": {
                "type": "Page",
                "id": "c15th",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.InnerShadowPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "18",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "18",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#006C6D",
                            "brd": "#C65AA5",
                            "brw": "0px",
                            "rd": "0px"
                        },
                        "propertiesSource": {"bg": "value", "brd": "value", "brw": "value", "rd": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "c15th",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.InnerShadowPageSkin",
                    "id": "c15th_i6giauvy"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 216, "y": 515}}
        }, {
            "id": "page_18",
            "structure": {
                "type": "Page",
                "id": "csm9",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.ThreeDeePageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "19",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "19",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#342215",
                            "brd": "#1A110A",
                            "brw": "1px",
                            "rd": "5px"
                        },
                        "propertiesSource": {"bg": "value", "brd": "value", "brw": "value", "rd": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "csm9",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.ThreeDeePageSkin",
                    "id": "csm9_i6giayft"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 0, "y": 615}}
        }, {
            "id": "page_19",
            "structure": {
                "type": "Page",
                "id": "c1wsg",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.ThreeDeePageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "20",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "20",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#5F3C13",
                            "brd": "#2F1E09",
                            "brw": "1px",
                            "rd": "5px"
                        },
                        "propertiesSource": {"bg": "value", "brd": "value", "brw": "value", "rd": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "c1wsg",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.ThreeDeePageSkin",
                    "id": "c1wsg_i6gib2ns"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 108, "y": 615}}
        }, {
            "id": "page_20",
            "structure": {
                "type": "Page",
                "id": "c10rd",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.ThreeDeePageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "21",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "21",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#DBB89A",
                            "brd": "#2B6CA3",
                            "brw": "0px",
                            "rd": "10px"
                        },
                        "propertiesSource": {"bg": "value", "brd": "value", "brw": "value", "rd": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.ThreeDeePageSkin",
                    "id": "style-igan7a9q"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 216, "y": 615}}
        }, {
            "id": "page_21",
            "structure": {
                "type": "Page",
                "id": "cagn",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.LiftedShadowPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "22",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "22",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#B64E97",
                            "brd": "#3D9BE9",
                            "brw": "0px",
                            "rd": "0px"
                        },
                        "propertiesSource": {"bg": "value", "brd": "value", "brw": "value", "rd": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "cagn",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.LiftedShadowPageSkin",
                    "id": "cagn_i6gibdfw"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 0, "y": 715}}
        }, {
            "id": "page_22",
            "structure": {
                "type": "Page",
                "id": "c21mi",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.LiftedShadowPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "23",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "23",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#A4157B",
                            "brd": "#000000",
                            "brw": "0px",
                            "rd": "0px"
                        },
                        "propertiesSource": {"bg": "value", "brd": "value", "brw": "value", "rd": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "c21mi",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.LiftedShadowPageSkin",
                    "id": "c21mi_i6gibgj6"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 108, "y": 715}}
        }, {
            "id": "page_23",
            "structure": {
                "type": "Page",
                "id": "c1a8d",
                "components": [],
                "mobileComponents": [],
                "skin": "wysiwyg.viewer.skins.page.LiftedShadowPageSkin",
                "layout": {
                    "width": 980,
                    "height": 500,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "24",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "22",
                    "hidePage": false,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false},
                    "indexable": true,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": false
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "fixed"
                            },
                            "isPreset": true
                        }
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "bg": "#740065",
                            "brd": "#000000",
                            "brw": "0px",
                            "rd": "0px"
                        },
                        "propertiesSource": {"bg": "value", "brd": "value", "brw": "value", "rd": "value"},
                        "groups": {}
                    },
                    "componentClassName": "mobile.core.components.Page",
                    "pageId": "",
                    "compId": "c1a8d",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.page.LiftedShadowPageSkin",
                    "id": "c1a8d_i6ginjik"
                }
            },
            "preset": {"rect": {"width": 108, "height": 100, "x": 216, "y": 715}}
        }],
        "compTypes": ["mobile.core.components.Page"]
    }
}
