import { fedopsLogger, performance, url } from '@/util';
import { EditorCoreApiKey } from '@/apis';
import { onNewAddPanelReady } from '@/addPanelInfra';
import { onComponentModelReady } from '@/componentModel';

import { sendEditorStageReadyBI } from './monitoring';
import type { EditorAPI } from '@/editorAPI';

let sendEditorStageReady = true;
function reportStageIsReady({ editorAPI }: { editorAPI: EditorAPI }) {
  if (!sendEditorStageReady) {
    return;
  }
  sendEditorStageReady = false;

  fedopsLogger.appLoadingPhaseFinish('editor-init-stage');
  fedopsLogger.appLoadingPhaseFinish('editor-visible');

  performance.mark('editor ready');
  performance.measure('editor ready', 'editor loaded', 'editor ready');

  sendEditorStageReadyBI({
    editorAPI,
  });
}

export async function initStage({ editorAPI }: { editorAPI: EditorAPI }) {
  const editorCoreApi = editorAPI.host.getAPI(EditorCoreApiKey);
  await Promise.all([
    onComponentModelReady(),
    onNewAddPanelReady(),
    editorCoreApi.hooks.workspaceModesInit.promise,
    waitForWixCodeInLocalEditor(),
    editorCoreApi.createInitReadyPromise(),
  ]);

  editorAPI.updateState({
    editorIsInit: true,
  });

  editorAPI.host.getAPI(EditorCoreApiKey).hooks.stageIsReady.resolve();

  reportStageIsReady({ editorAPI });

  async function waitForWixCodeInLocalEditor() {
    const isLocalEditor = !!url.getParameterByName('localPort');
    if (isLocalEditor) {
      await editorCoreApi.hooks.wixCodeLiveEditorReady.promise;
    }
  }
}
