const CALENDAR_WIDGET_IMAGE =
  '/addPanelData/sections/bookingsCalendarSection_en/bookingsCalendarSection_editorX_en.v2.png';
const CALENDAR_WIDGET_IMAGE_WIX_STUDIO =
  '/addPanelData/sections/bookingsCalendarSection_en/bookingsCalendarSection_wix_studio_en.png';
const CALENDAR_PRESET_KEY = 'Wix_Bookings_CALENDAR_Widget';
const CALENDAR_WIDGET_ID = '0eadb76d-b167-4f19-88d1-496a8207e92b';
const CALENDAR_TITLE_KEY = 'add_section_label_bookings_calendar';
const CALENDAR_TOOLTIP_TITLE_KEY =
  'add_section_label_bookings_calendar_tooltip_title';
const CALENDAR_TOOLTIP_DESCRIPTION_KEY =
  'add_section_label_bookings_calendar_tooltip_description';

export default {
  CALENDAR_WIDGET_IMAGE,
  CALENDAR_PRESET_KEY,
  CALENDAR_WIDGET_ID,
  CALENDAR_TITLE_KEY,
  CALENDAR_TOOLTIP_TITLE_KEY,
  CALENDAR_TOOLTIP_DESCRIPTION_KEY,
  CALENDAR_WIDGET_IMAGE_WIX_STUDIO,
};
