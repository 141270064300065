import * as stateManagement from '@/stateManagement';
import type { PublishSuccessPanelDispatchProps } from './publishSuccessPanel';
import type { StateMapperArgs } from 'types/redux';

function mapDispatchToProps(
  dispatch: AnyFixMe,
): PublishSuccessPanelDispatchProps {
  return {
    showUserActionNotification: (options) =>
      dispatch(
        stateManagement.notifications.actions.showUserActionNotification(
          options,
        ),
      ),
    setWasLatestRCPublished: (wasLatestRCPublished: boolean) =>
      dispatch(
        stateManagement.savePublish.actions.setWasLatestRCPublished(
          wasLatestRCPublished,
        ),
      ),
  };
}

function mapStateToProps({ editorAPI }: StateMapperArgs) {
  const { store } = editorAPI;
  const editorState = store.getState();
  return {
    wasLatestRCPublished:
      stateManagement.savePublish.selectors.getWasLatestRCPublished(
        editorState,
      ),
  };
}

export { mapStateToProps, mapDispatchToProps };
