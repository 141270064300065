// @ts-nocheck
export default {
  actionSelectedHandler(value) {
    if (value === 'rename') {
      this.props.editedPageIdLink.requestChange(this.props.dataSource.id);
    } else {
      this.props.onActionSelected(value, this.props.dataSource);
    }
  },
};
