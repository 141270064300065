import { SafeInjectHtml } from '@/util';
import React from 'react';
import { Text } from '@wix/wix-base-ui';

const DESCRIPTION_TAGS = ['p', 'li'] as const;
type ValueOf<T> = T extends ReadonlyArray<any> ? T[number] : T[keyof T];
type PanelDescriptionTagName = ValueOf<typeof DESCRIPTION_TAGS>;

const parseDescription = (
  description: string | PanelTag[],
): JSX.Element | JSX.Element[] => {
  if (typeof description === 'string') {
    return (
      <SafeInjectHtml tag="p" html={removeNonAllowedHTMLTags(description)} />
    );
  }

  if (Array.isArray(description)) {
    return description
      .filter((d) => d && DESCRIPTION_TAGS.includes(d.tag))
      .reduce<(JSX.Element | JSX.Element[])[]>((contentArray, item, index) => {
        const element = (
          <item.tag key={`${item.tag}_${index}`}>{item.content}</item.tag>
        );
        if (item.tag !== 'li') {
          contentArray.push(element);
          return contentArray;
        }
        const lastItem = contentArray[contentArray.length - 1];
        if (Array.isArray(lastItem)) {
          lastItem.push(element);
        } else {
          contentArray.push([element]);
        }
        return contentArray;
      }, [])
      .map((item, index) =>
        Array.isArray(item) ? <ol key={`ol_${index}`}>{item}</ol> : item,
      );
  }
  throw new Error('unexpected content send to list parser');
};

const TAGS_TO_ALLOW = ['b', 'p', 'i', 'br', 'li', 'ul', 'ol'];
const ALLOWED_HTML_TAGS: Record<string, boolean> = TAGS_TO_ALLOW.reduce(
  (accumalatedList: Record<string, boolean>, currentTag: string) => {
    accumalatedList[`<${currentTag}>`] = true;
    accumalatedList[`</${currentTag}>`] = true;
    return accumalatedList;
  },
  {},
);

const removeNonAllowedHTMLTags = (htmlText: string) => {
  return htmlText.replace(/<.*?>/g, (htmlTag: string) =>
    ALLOWED_HTML_TAGS[htmlTag] ? htmlTag : '',
  );
};

class ListParser extends React.Component<ListParserProps> {
  render() {
    return (
      <div className="list-parser">
        <Text enableEllipsis={false} shouldTranslate={false}>
          {parseDescription(this.props.description)}
        </Text>
      </div>
    );
  }
}

interface ListParserProps {
  description: string | PanelTag[];
}

export interface PanelTag {
  tag: PanelDescriptionTagName;
  content: string;
}

export default ListParser;
