export const PANEL_NAME = 'ai.panels.welcomePanel';

export enum PanelTypes {
  InjectionToSite = 'injection to site',
  AutoInjectionToSite = 'auto-injection to site',
}

export enum ImageTypes {
  Image = 'image',
  PageBackground = 'pageBackground',
  StripBackground = 'stripBackground',
  SectionBackground = 'sectionBackground',
}
