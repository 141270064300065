import React from 'react';
import { Text } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

import * as panels from '@/panels';
import * as baseUI from '@/baseUI';
import { translate } from '@/i18n';

const { ConfirmationPanelFrame, MessagePanelFrame } = panels.frames;

interface ConcurrentSavePanelFrameProps {
  panelName: string;
  title: string;
  confirmLabel?: string;
  cancelLabel?: string;
  symbolName: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose: () => void;
  onHelp?: () => void;
  hideCloseButton?: boolean;
  closeOnEscPress?: boolean;
}

export const NewWorkspaceConcurrentSavePanelFrame: React.FC<
  ConcurrentSavePanelFrameProps
> = (props) => {
  return (
    <MessagePanelFrame
      className="new-workspace-concurrent-save-panel"
      panelName={props.panelName}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      cancelLabel={translate(props.cancelLabel)}
      confirmLabel={translate(props.confirmLabel)}
      onHelp={props.onHelp}
      title={translate(props.title)}
      illustration={<symbols.symbol name={props.symbolName} />}
      hideCloseButton={props.hideCloseButton}
      cancelOnClickOutside={false}
      cancelOnEscPress={props.closeOnEscPress}
    >
      <Text
        size="medium"
        weight="normal"
        skin="secondary"
        enableEllipsis={false}
        shouldTranslate={false}
      >
        {props.children}
      </Text>
    </MessagePanelFrame>
  );
};

export const ConcurrentSavePanelFrame: React.FC<
  ConcurrentSavePanelFrameProps
> = (props) => {
  const keyboardShortcuts = {
    esc() {
      if (props.closeOnEscPress) {
        props.onClose();
      }
    },
  };

  return (
    <ConfirmationPanelFrame
      panelName={props.panelName}
      panelTitle={translate(props.title)}
      keyboardShortcuts={keyboardShortcuts}
      confirmLabel={props.confirmLabel}
      cancelLabel={props.cancelLabel}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      onClose={props.onClose}
      shouldHideCancelButton={!props.cancelLabel}
      shouldHideCloseButton={props.hideCloseButton}
      noHelpBtn={!props.onHelp}
      onHelp={props.onHelp}
      className="concurrent-save-panel save-publish-panel"
    >
      <div className="content-wrapper">
        <div className="error-logo">
          <baseUI.symbol name={props.symbolName} />
        </div>
        {props.children}
      </div>
    </ConfirmationPanelFrame>
  );
};
