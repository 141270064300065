const AUTH_VALUES = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
  DEV: 'dev',
};

const SIGNUP_OPTIONS_LABELS = {
  [AUTH_VALUES.DEFAULT]: 'NewPages_Panel_Signup_Dropdown_Default_label',
  [AUTH_VALUES.CUSTOM]: 'NewPages_Panel_Signup_Dropdown_Custom_label',
  [AUTH_VALUES.DEV]: 'NewPages_Panel_Signup_Dropdown_Developer_label',
};

const PRIVACY_NOTE_TYPE = {
  CHECKBOX: 'CHECKBOX' as const,
  NOTE: 'NOTE' as const,
};

const PRIVACY_NOTE_TYPE_OPTIONS = [
  {
    label: 'NewPages_Panel_Signup_Privacy_Note_Type_Note_label',
    value: PRIVACY_NOTE_TYPE.NOTE,
  },
  {
    label: 'NewPages_Panel_Signup_Privacy_Note_Type_Checkbox_label',
    value: PRIVACY_NOTE_TYPE.CHECKBOX,
  },
];

const SIGNUP_POLICIES = {
  TERMS_OF_USE: 'termsOfUse',
  PRIVACY: 'privacyPolicy',
  CODE_OF_CONDUCT: 'codeOfConduct',
};

const AUTO_APPROVAL = [
  {
    label: 'Pages_Member_Signup_Permission_Settings_Dropdown_Automatic',
    value: true,
  },
  {
    label: 'Pages_Member_Signup_Permission_Settings_Dropdown_Manual',
    value: false,
  },
];

const LOGIN_FIRST_OPTIONS = [
  {
    label: 'Pages_Member_Signup_Display_Settings_Dropdown_Signup',
    value: 'false',
  },
  {
    label: 'Pages_Member_Signup_Display_Settings_Dropdown_Login',
    value: 'true',
  },
] as const;
// these do not exist on '@wix/app-definition-ids'
const CUSTOM_REGISTRATION_APP_DEF_ID = '5f4fa4f1-5afd-4ac0-8a85-e5ac1d2b9b7d';
const CUSTOM_LOGIN_APP_DEF_ID = 'bbe1406a-31f5-4f3f-9e0a-b39dfd25274f';

export {
  SIGNUP_POLICIES,
  AUTO_APPROVAL,
  LOGIN_FIRST_OPTIONS,
  AUTH_VALUES,
  SIGNUP_OPTIONS_LABELS,
  CUSTOM_REGISTRATION_APP_DEF_ID,
  CUSTOM_LOGIN_APP_DEF_ID,
  PRIVACY_NOTE_TYPE,
  PRIVACY_NOTE_TYPE_OPTIONS,
};
