import _ from 'lodash';
import constants from './constants/constants';

const PERMANENT_HANDLERS = 'permanent_handlers';
const SINGLE_HANDLERS = 'single_handlers';

export function createEditorEventRegistryApi() {
  const registry: Record<string, AnyFixMe> = {};

  function dispatch(this: unknown, eventName: AnyFixMe, _editorAPI?: AnyFixMe) {
    const args = _.toArray(arguments).slice(1);
    const permanentEventListeners =
      registry?.[eventName]?.[PERMANENT_HANDLERS] ?? [];
    const singleEventListeners = registry?.[eventName]?.[SINGLE_HANDLERS] ?? [];

    const eventListeners = permanentEventListeners.concat(singleEventListeners);

    eventListeners.forEach(function (this: unknown, func: AnyFixMe) {
      func.apply(this, args);
    });

    _.unset(registry, [eventName, SINGLE_HANDLERS]);

    return this;
  }

  function register(eventName: AnyFixMe, callback: AnyFixMe) {
    registerHandler(eventName, PERMANENT_HANDLERS, callback);
  }

  function registerOnce(eventName: AnyFixMe, callback: AnyFixMe) {
    registerHandler(eventName, SINGLE_HANDLERS, callback);
  }

  function registerHandler(
    eventName: AnyFixMe,
    handlerType: AnyFixMe,
    callback: AnyFixMe,
  ) {
    const newHandlers = _.compact(
      [callback].concat(registry[eventName]?.[handlerType]),
    );

    _.set(registry, [eventName, handlerType], newHandlers);
  }

  function unregister(eventName: AnyFixMe, callback: AnyFixMe) {
    _.pull(registry[eventName]?.[PERMANENT_HANDLERS], callback);
    _.pull(registry[eventName]?.[SINGLE_HANDLERS], callback);
  }

  return {
    register,
    registerOnce,
    unregister,
    dispatch,
    constants,
  };
}
