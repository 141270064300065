._markdown_24faw_1 {
  padding: 10px 10px 10px 0;
  width: 100%; }
  ._markdown_24faw_1 * {
    -webkit-user-select: text;
            user-select: text; }

._markdown_24faw_1 ._octicon_24faw_7 {
  display: inline-block;
  fill: currentColor;
  vertical-align: text-bottom; }

._markdown_24faw_1 ._anchor_24faw_12 {
  float: left;
  line-height: 1;
  margin-left: -20px;
  padding-right: 4px; }

._markdown_24faw_1 ._anchor_24faw_12:focus {
  outline: none; }

._markdown_24faw_1 h1 ._octicon-link_24faw_21,
._markdown_24faw_1 h2 ._octicon-link_24faw_21,
._markdown_24faw_1 h3 ._octicon-link_24faw_21,
._markdown_24faw_1 h4 ._octicon-link_24faw_21,
._markdown_24faw_1 h5 ._octicon-link_24faw_21,
._markdown_24faw_1 h6 ._octicon-link_24faw_21 {
  color: #1b1f23;
  vertical-align: middle;
  visibility: hidden; }

._markdown_24faw_1 h1:hover ._anchor_24faw_12,
._markdown_24faw_1 h2:hover ._anchor_24faw_12,
._markdown_24faw_1 h3:hover ._anchor_24faw_12,
._markdown_24faw_1 h4:hover ._anchor_24faw_12,
._markdown_24faw_1 h5:hover ._anchor_24faw_12,
._markdown_24faw_1 h6:hover ._anchor_24faw_12 {
  text-decoration: none; }

._markdown_24faw_1 h1:hover ._anchor_24faw_12 ._octicon-link_24faw_21,
._markdown_24faw_1 h2:hover ._anchor_24faw_12 ._octicon-link_24faw_21,
._markdown_24faw_1 h3:hover ._anchor_24faw_12 ._octicon-link_24faw_21,
._markdown_24faw_1 h4:hover ._anchor_24faw_12 ._octicon-link_24faw_21,
._markdown_24faw_1 h5:hover ._anchor_24faw_12 ._octicon-link_24faw_21,
._markdown_24faw_1 h6:hover ._anchor_24faw_12 ._octicon-link_24faw_21 {
  visibility: visible; }

._markdown_24faw_1 h1:hover ._anchor_24faw_12 ._octicon-link_24faw_21:before,
._markdown_24faw_1 h2:hover ._anchor_24faw_12 ._octicon-link_24faw_21:before,
._markdown_24faw_1 h3:hover ._anchor_24faw_12 ._octicon-link_24faw_21:before,
._markdown_24faw_1 h4:hover ._anchor_24faw_12 ._octicon-link_24faw_21:before,
._markdown_24faw_1 h5:hover ._anchor_24faw_12 ._octicon-link_24faw_21:before,
._markdown_24faw_1 h6:hover ._anchor_24faw_12 ._octicon-link_24faw_21:before {
  width: 16px;
  height: 16px;
  content: ' ';
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' width='16' height='16' aria-hidden='true'%3E%3Cpath fill-rule='evenodd' d='M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z'%3E%3C/path%3E%3C/svg%3E"); }

._markdown_24faw_1 {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
  color: #24292e;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word; }

._markdown_24faw_1 details {
  display: block; }

._markdown_24faw_1 summary {
  display: list-item; }

._markdown_24faw_1 a {
  background-color: initial; }

._markdown_24faw_1 a:active,
._markdown_24faw_1 a:hover {
  outline-width: 0; }

._markdown_24faw_1 strong {
  font-weight: inherit;
  font-weight: bolder; }

._markdown_24faw_1 h1 {
  font-size: 2em;
  margin: 0.67em 0; }

._markdown_24faw_1 img {
  border-style: none; }

._markdown_24faw_1 code,
._markdown_24faw_1 kbd,
._markdown_24faw_1 pre {
  font-family: monospace, monospace;
  font-size: 1em; }

._markdown_24faw_1 hr {
  box-sizing: initial;
  height: 0;
  overflow: visible; }

._markdown_24faw_1 input {
  font: inherit;
  margin: 0; }

._markdown_24faw_1 input {
  overflow: visible; }

._markdown_24faw_1 [type='checkbox'] {
  box-sizing: border-box;
  padding: 0; }

._markdown_24faw_1 * {
  box-sizing: border-box; }

._markdown_24faw_1 input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

._markdown_24faw_1 a {
  color: #0366d6;
  text-decoration: none; }

._markdown_24faw_1 a:hover {
  text-decoration: underline; }

._markdown_24faw_1 strong {
  font-weight: 600; }

._markdown_24faw_1 hr {
  height: 0;
  margin: 15px 0;
  overflow: hidden;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #dfe2e5; }

._markdown_24faw_1 hr:after,
._markdown_24faw_1 hr:before {
  display: table;
  content: ''; }

._markdown_24faw_1 hr:after {
  clear: both; }

._markdown_24faw_1 table {
  border-spacing: 0;
  border-collapse: collapse; }

._markdown_24faw_1 td,
._markdown_24faw_1 th {
  padding: 0; }

._markdown_24faw_1 details summary {
  cursor: pointer; }

._markdown_24faw_1 kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  line-height: 10px;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: 1px solid #d1d5da;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #d1d5da; }

._markdown_24faw_1 h1,
._markdown_24faw_1 h2,
._markdown_24faw_1 h3,
._markdown_24faw_1 h4,
._markdown_24faw_1 h5,
._markdown_24faw_1 h6 {
  margin-top: 0;
  margin-bottom: 0; }

._markdown_24faw_1 h1 {
  font-size: 32px; }

._markdown_24faw_1 h1,
._markdown_24faw_1 h2 {
  font-weight: 600; }

._markdown_24faw_1 h2 {
  font-size: 24px; }

._markdown_24faw_1 h3 {
  font-size: 20px; }

._markdown_24faw_1 h3,
._markdown_24faw_1 h4 {
  font-weight: 600; }

._markdown_24faw_1 h4 {
  font-size: 16px; }

._markdown_24faw_1 h5 {
  font-size: 14px; }

._markdown_24faw_1 h5,
._markdown_24faw_1 h6 {
  font-weight: 600; }

._markdown_24faw_1 h6 {
  font-size: 12px; }

._markdown_24faw_1 p {
  margin-top: 0;
  margin-bottom: 10px; }

._markdown_24faw_1 blockquote {
  margin: 0; }

._markdown_24faw_1 ol,
._markdown_24faw_1 ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0; }

._markdown_24faw_1 ol ol,
._markdown_24faw_1 ul ol {
  list-style-type: lower-roman; }

._markdown_24faw_1 ol ol ol,
._markdown_24faw_1 ol ul ol,
._markdown_24faw_1 ul ol ol,
._markdown_24faw_1 ul ul ol {
  list-style-type: lower-alpha; }

._markdown_24faw_1 dd {
  margin-left: 0; }

._markdown_24faw_1 code,
._markdown_24faw_1 pre {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 12px; }

._markdown_24faw_1 pre {
  margin-top: 0;
  margin-bottom: 0; }

._markdown_24faw_1 input::-webkit-inner-spin-button,
._markdown_24faw_1 input::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none; }

._markdown_24faw_1 :checked + ._radio-label_24faw_252 {
  position: relative;
  z-index: 1;
  border-color: #0366d6; }

._markdown_24faw_1 ._border_24faw_257 {
  border: 1px solid #e1e4e8 !important; }

._markdown_24faw_1 ._border-0_24faw_260 {
  border: 0 !important; }

._markdown_24faw_1 ._border-bottom_24faw_263 {
  border-bottom: 1px solid #e1e4e8 !important; }

._markdown_24faw_1 ._rounded-1_24faw_266 {
  border-radius: 3px !important; }

._markdown_24faw_1 ._bg-white_24faw_269 {
  background-color: #fff !important; }

._markdown_24faw_1 ._bg-gray-light_24faw_272 {
  background-color: #fafbfc !important; }

._markdown_24faw_1 ._text-gray-light_24faw_275 {
  color: #6a737d !important; }

._markdown_24faw_1 ._mb-0_24faw_278 {
  margin-bottom: 0 !important; }

._markdown_24faw_1 ._my-2_24faw_281 {
  margin-top: 8px !important;
  margin-bottom: 8px !important; }

._markdown_24faw_1 ._pl-0_24faw_285 {
  padding-left: 0 !important; }

._markdown_24faw_1 ._py-0_24faw_288 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

._markdown_24faw_1 ._pl-1_24faw_292 {
  padding-left: 4px !important; }

._markdown_24faw_1 ._pl-2_24faw_295 {
  padding-left: 8px !important; }

._markdown_24faw_1 ._py-2_24faw_298 {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

._markdown_24faw_1 ._pl-3_24faw_302,
._markdown_24faw_1 ._px-3_24faw_303 {
  padding-left: 16px !important; }

._markdown_24faw_1 ._px-3_24faw_303 {
  padding-right: 16px !important; }

._markdown_24faw_1 ._pl-4_24faw_309 {
  padding-left: 24px !important; }

._markdown_24faw_1 ._pl-5_24faw_312 {
  padding-left: 32px !important; }

._markdown_24faw_1 ._pl-6_24faw_315 {
  padding-left: 40px !important; }

._markdown_24faw_1 ._f6_24faw_318 {
  font-size: 12px !important; }

._markdown_24faw_1 ._lh-condensed_24faw_321 {
  line-height: 1.25 !important; }

._markdown_24faw_1 ._text-bold_24faw_324 {
  font-weight: 600 !important; }

._markdown_24faw_1 ._pl-c_24faw_327 {
  color: #6a737d; }

._markdown_24faw_1 ._pl-c1_24faw_330,
._markdown_24faw_1 ._pl-s_24faw_331 ._pl-v_24faw_331 {
  color: #005cc5; }

._markdown_24faw_1 ._pl-e_24faw_334,
._markdown_24faw_1 ._pl-en_24faw_335 {
  color: #6f42c1; }

._markdown_24faw_1 ._pl-s_24faw_331 ._pl-s1_24faw_338,
._markdown_24faw_1 ._pl-smi_24faw_339 {
  color: #24292e; }

._markdown_24faw_1 ._pl-ent_24faw_342 {
  color: #22863a; }

._markdown_24faw_1 ._pl-k_24faw_345 {
  color: #d73a49; }

._markdown_24faw_1 ._pl-pds_24faw_348,
._markdown_24faw_1 ._pl-s_24faw_331,
._markdown_24faw_1 ._pl-s_24faw_331 ._pl-pse_24faw_350 ._pl-s1_24faw_338,
._markdown_24faw_1 ._pl-sr_24faw_351,
._markdown_24faw_1 ._pl-sr_24faw_351 ._pl-cce_24faw_352,
._markdown_24faw_1 ._pl-sr_24faw_351 ._pl-sra_24faw_353,
._markdown_24faw_1 ._pl-sr_24faw_351 ._pl-sre_24faw_354 {
  color: #032f62; }

._markdown_24faw_1 ._pl-smw_24faw_357,
._markdown_24faw_1 ._pl-v_24faw_331 {
  color: #e36209; }

._markdown_24faw_1 ._pl-bu_24faw_361 {
  color: #b31d28; }

._markdown_24faw_1 ._pl-ii_24faw_364 {
  color: #fafbfc;
  background-color: #b31d28; }

._markdown_24faw_1 ._pl-c2_24faw_368 {
  color: #fafbfc;
  background-color: #d73a49; }

._markdown_24faw_1 ._pl-c2_24faw_368:before {
  content: '^M'; }

._markdown_24faw_1 ._pl-sr_24faw_351 ._pl-cce_24faw_352 {
  font-weight: 700;
  color: #22863a; }

._markdown_24faw_1 ._pl-ml_24faw_379 {
  color: #735c0f; }

._markdown_24faw_1 ._pl-mh_24faw_382,
._markdown_24faw_1 ._pl-mh_24faw_382 ._pl-en_24faw_335,
._markdown_24faw_1 ._pl-ms_24faw_384 {
  font-weight: 700;
  color: #005cc5; }

._markdown_24faw_1 ._pl-mi_24faw_388 {
  font-style: italic;
  color: #24292e; }

._markdown_24faw_1 ._pl-mb_24faw_392 {
  font-weight: 700;
  color: #24292e; }

._markdown_24faw_1 ._pl-md_24faw_396 {
  color: #b31d28;
  background-color: #ffeef0; }

._markdown_24faw_1 ._pl-mi1_24faw_400 {
  color: #22863a;
  background-color: #f0fff4; }

._markdown_24faw_1 ._pl-mc_24faw_404 {
  color: #e36209;
  background-color: #ffebda; }

._markdown_24faw_1 ._pl-mi2_24faw_408 {
  color: #f6f8fa;
  background-color: #005cc5; }

._markdown_24faw_1 ._pl-mdr_24faw_412 {
  font-weight: 700;
  color: #6f42c1; }

._markdown_24faw_1 ._pl-ba_24faw_416 {
  color: #586069; }

._markdown_24faw_1 ._pl-sg_24faw_419 {
  color: #959da5; }

._markdown_24faw_1 ._pl-corl_24faw_422 {
  text-decoration: underline;
  color: #032f62; }

._markdown_24faw_1 ._mb-0_24faw_278 {
  margin-bottom: 0 !important; }

._markdown_24faw_1 ._my-2_24faw_281 {
  margin-bottom: 8px !important; }

._markdown_24faw_1 ._my-2_24faw_281 {
  margin-top: 8px !important; }

._markdown_24faw_1 ._pl-0_24faw_285 {
  padding-left: 0 !important; }

._markdown_24faw_1 ._py-0_24faw_288 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

._markdown_24faw_1 ._pl-1_24faw_292 {
  padding-left: 4px !important; }

._markdown_24faw_1 ._pl-2_24faw_295 {
  padding-left: 8px !important; }

._markdown_24faw_1 ._py-2_24faw_298 {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

._markdown_24faw_1 ._pl-3_24faw_302 {
  padding-left: 16px !important; }

._markdown_24faw_1 ._pl-4_24faw_309 {
  padding-left: 24px !important; }

._markdown_24faw_1 ._pl-5_24faw_312 {
  padding-left: 32px !important; }

._markdown_24faw_1 ._pl-6_24faw_315 {
  padding-left: 40px !important; }

._markdown_24faw_1 ._pl-7_24faw_464 {
  padding-left: 48px !important; }

._markdown_24faw_1 ._pl-8_24faw_467 {
  padding-left: 64px !important; }

._markdown_24faw_1 ._pl-9_24faw_470 {
  padding-left: 80px !important; }

._markdown_24faw_1 ._pl-10_24faw_473 {
  padding-left: 96px !important; }

._markdown_24faw_1 ._pl-11_24faw_476 {
  padding-left: 112px !important; }

._markdown_24faw_1 ._pl-12_24faw_479 {
  padding-left: 128px !important; }

._markdown_24faw_1 hr {
  border-bottom-color: #eee; }

._markdown_24faw_1 kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  line-height: 10px;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: 1px solid #d1d5da;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #d1d5da; }

._markdown_24faw_1:after,
._markdown_24faw_1:before {
  display: table;
  content: ''; }

._markdown_24faw_1:after {
  clear: both; }

._markdown_24faw_1 > :first-child {
  margin-top: 0 !important; }

._markdown_24faw_1 > :last-child {
  margin-bottom: 0 !important; }

._markdown_24faw_1 a:not([href]) {
  color: inherit;
  text-decoration: none; }

._markdown_24faw_1 blockquote,
._markdown_24faw_1 details,
._markdown_24faw_1 dl,
._markdown_24faw_1 ol,
._markdown_24faw_1 p,
._markdown_24faw_1 pre,
._markdown_24faw_1 table,
._markdown_24faw_1 ul {
  margin-top: 0;
  margin-bottom: 16px; }

._markdown_24faw_1 hr {
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: #e1e4e8;
  border: 0; }

._markdown_24faw_1 blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #3885ff; }

._markdown_24faw_1 blockquote > :first-child {
  margin-top: 0; }

._markdown_24faw_1 blockquote > :last-child {
  margin-bottom: 0; }

._markdown_24faw_1 h1,
._markdown_24faw_1 h2,
._markdown_24faw_1 h3,
._markdown_24faw_1 h4,
._markdown_24faw_1 h5,
._markdown_24faw_1 h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25; }

._markdown_24faw_1 h1 {
  font-size: 2em; }

._markdown_24faw_1 h1,
._markdown_24faw_1 h2 {
  padding-bottom: 0.3em;
  border-bottom: 1px solid #eaecef; }

._markdown_24faw_1 h2 {
  font-size: 1.5em; }

._markdown_24faw_1 h3 {
  font-size: 1.25em; }

._markdown_24faw_1 h4 {
  font-size: 1em; }

._markdown_24faw_1 h5 {
  font-size: 0.875em; }

._markdown_24faw_1 h6 {
  font-size: 0.85em;
  color: #6a737d; }

._markdown_24faw_1 ol,
._markdown_24faw_1 ul {
  padding-left: 2em; }

._markdown_24faw_1 ol ol,
._markdown_24faw_1 ol ul,
._markdown_24faw_1 ul ol,
._markdown_24faw_1 ul ul {
  margin-top: 0;
  margin-bottom: 0; }

._markdown_24faw_1 li {
  word-wrap: break-word;
  list-style-type: initial; }

._markdown_24faw_1 li > p {
  margin-top: 16px; }

._markdown_24faw_1 li + li {
  margin-top: 0.25em; }

._markdown_24faw_1 dl {
  padding: 0; }

._markdown_24faw_1 dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600; }

._markdown_24faw_1 dl dd {
  padding: 0 16px;
  margin-bottom: 16px; }

._markdown_24faw_1 table {
  display: block;
  width: 100%;
  overflow: auto; }

._markdown_24faw_1 table th {
  font-weight: 600; }

._markdown_24faw_1 table td,
._markdown_24faw_1 table th {
  padding: 6px 13px;
  border: 1px solid #dfe2e5; }

._markdown_24faw_1 table tr {
  background-color: #fff;
  border-top: 1px solid #c6cbd1; }

._markdown_24faw_1 table tr:nth-child(2n) {
  background-color: #f6f8fa; }

._markdown_24faw_1 img {
  max-width: 100%;
  box-sizing: initial;
  background-color: #fff; }

._markdown_24faw_1 img[align='right'] {
  padding-left: 20px; }

._markdown_24faw_1 img[align='left'] {
  padding-right: 20px; }

._markdown_24faw_1 code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px; }

._markdown_24faw_1 pre {
  word-wrap: normal; }

._markdown_24faw_1 pre > code {
  padding: 0;
  margin: 0;
  font-size: 100%;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0; }

._markdown_24faw_1 ._highlight_24faw_664 {
  margin-bottom: 16px; }

._markdown_24faw_1 ._highlight_24faw_664 pre {
  margin-bottom: 0;
  word-break: normal; }

._markdown_24faw_1 ._highlight_24faw_664 pre,
._markdown_24faw_1 pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 3px; }

._markdown_24faw_1 pre code {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: initial;
  border: 0; }

._markdown_24faw_1 ._commit-tease-sha_24faw_691 {
  display: inline-block;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 90%;
  color: #444d56; }

._markdown_24faw_1 ._full-commit_24faw_697 ._btn-outline_24faw_697:not(:disabled):hover {
  color: #005cc5;
  border-color: #005cc5; }

._markdown_24faw_1 ._blob-wrapper_24faw_701 {
  overflow-x: auto;
  overflow-y: hidden; }

._markdown_24faw_1 ._blob-wrapper-embedded_24faw_705 {
  max-height: 240px;
  overflow-y: auto; }

._markdown_24faw_1 ._blob-num_24faw_709 {
  width: 1%;
  min-width: 50px;
  padding-right: 10px;
  padding-left: 10px;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 12px;
  line-height: 20px;
  color: rgba(27, 31, 35, 0.3);
  text-align: right;
  white-space: nowrap;
  vertical-align: top;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none; }

._markdown_24faw_1 ._blob-num_24faw_709:hover {
  color: rgba(27, 31, 35, 0.6); }

._markdown_24faw_1 ._blob-num_24faw_709:before {
  content: attr(data-line-number); }

._markdown_24faw_1 ._blob-code_24faw_730 {
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  line-height: 20px;
  vertical-align: top; }

._markdown_24faw_1 ._blob-code-inner_24faw_737 {
  overflow: visible;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 12px;
  color: #24292e;
  word-wrap: normal;
  white-space: pre; }

._markdown_24faw_1 ._pl-token_24faw_745._active_24faw_745,
._markdown_24faw_1 ._pl-token_24faw_745:hover {
  cursor: pointer;
  background: #ffea7f; }

._markdown_24faw_1 ._tab-size_24faw_750[data-tab-size='1'] {
  -moz-tab-size: 1;
  tab-size: 1; }

._markdown_24faw_1 ._tab-size_24faw_750[data-tab-size='2'] {
  -moz-tab-size: 2;
  tab-size: 2; }

._markdown_24faw_1 ._tab-size_24faw_750[data-tab-size='3'] {
  -moz-tab-size: 3;
  tab-size: 3; }

._markdown_24faw_1 ._tab-size_24faw_750[data-tab-size='4'] {
  -moz-tab-size: 4;
  tab-size: 4; }

._markdown_24faw_1 ._tab-size_24faw_750[data-tab-size='5'] {
  -moz-tab-size: 5;
  tab-size: 5; }

._markdown_24faw_1 ._tab-size_24faw_750[data-tab-size='6'] {
  -moz-tab-size: 6;
  tab-size: 6; }

._markdown_24faw_1 ._tab-size_24faw_750[data-tab-size='7'] {
  -moz-tab-size: 7;
  tab-size: 7; }

._markdown_24faw_1 ._tab-size_24faw_750[data-tab-size='8'] {
  -moz-tab-size: 8;
  tab-size: 8; }

._markdown_24faw_1 ._tab-size_24faw_750[data-tab-size='9'] {
  -moz-tab-size: 9;
  tab-size: 9; }

._markdown_24faw_1 ._tab-size_24faw_750[data-tab-size='10'] {
  -moz-tab-size: 10;
  tab-size: 10; }

._markdown_24faw_1 ._tab-size_24faw_750[data-tab-size='11'] {
  -moz-tab-size: 11;
  tab-size: 11; }

._markdown_24faw_1 ._tab-size_24faw_750[data-tab-size='12'] {
  -moz-tab-size: 12;
  tab-size: 12; }

._markdown_24faw_1 ._task-list-item_24faw_798 {
  list-style-type: none; }

._markdown_24faw_1 ._task-list-item_24faw_798 + ._task-list-item_24faw_798 {
  margin-top: 3px; }

._markdown_24faw_1 ._task-list-item_24faw_798 input {
  margin: 0 0.2em 0.25em -1.6em;
  vertical-align: middle; }
