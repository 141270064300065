// @ts-nocheck
import _ from 'lodash';
import * as tpaUtils from '../utils/tpaUtils';
import constants from '../constants/constants';
import * as tpaHelpService from './tpaHelpService';
import { getOrigCompIdFromTpaMessage } from '../common/editorTPAPostMessageCommon';

const settingsOpenModal = function (
  editorAPI,
  msg,
  callback,
  selectedComponents?,
) {
  const settingModalName = constants.TPA_SETTING_MODAL;

  let appData, origCompData;
  const appDefId = msg?.data?.appDefinitionId;
  if (appDefId) {
    appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefId);
  } else {
    origCompData = editorAPI.selection.getSelectedComponentData();
    appData =
      origCompData &&
      editorAPI.dsRead.tpa.app.getData(origCompData.applicationId);
  }

  // Possible undefined
  appData = appData || {};

  const selectedComponent = _.head(selectedComponents);

  const urlParams = {
    url: msg.data.url,
    deviceType: msg.deviceType,
    applicationId: appData.applicationId,
    origCompId: getOrigCompIdFromTpaMessage(msg, selectedComponent, editorAPI),
    bi: msg.data.bi,
  };
  const panelParams = _.clone(msg.data);
  panelParams.width = parseModalSizeParams(panelParams.width);
  panelParams.height = parseModalSizeParams(panelParams.height);
  panelParams.onClose = callback;

  if (!msg.data?.options?.leavePanelsOpen) {
    closeOpenModal(editorAPI, settingModalName);
  }

  if (appData.isWixTPA) {
    if (panelParams.isBareMode) {
      delete panelParams.title;
    }

    panelParams.shouldNotCloseOnBlur =
      panelParams.shouldNotCloseOnBlur ||
      (panelParams?.options?.shouldNotCloseOnBlur ?? false);
  } else {
    panelParams.isBareMode = false;
    panelParams.shouldNotCloseOnBlur = false;
  }

  const modalProps =
    editorAPI.dsRead.tpa.getSettingsModalParams(urlParams, panelParams) || {};
  const widgetId = origCompData?.widgetId;

  tpaHelpService
    .getHelpId(
      editorAPI,
      appData.appDefinitionId,
      widgetId,
      tpaHelpService.HELP_END_POINTS.SETTINGS_MODAL,
    )
    .then(function (helpId) {
      modalProps.appDefinitionId = appData.appDefinitionId;
      modalProps.helpId = helpId;
      modalProps.widgetId = widgetId;

      editorAPI.panelManager.openPanel(settingModalName, modalProps, true);
    });
};

const parseModalSizeParams = function (sizeParam) {
  if (typeof sizeParam === 'number') {
    sizeParam = `${sizeParam.toString()}px`;
  }
  return sizeParam;
};

function closeOpenModal(editorAPI, settingModalName) {
  const modal = tpaUtils.getOpenedPanel(
    editorAPI.panelManager,
    settingModalName,
  );
  if (modal) {
    editorAPI.panelManager.closePanelByName(modal.props.panelName);
  }
}

export { settingsOpenModal };
