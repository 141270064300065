'use strict'

module.exports = {
    id: 'add_preset_popups_Sale_3',
    structure: {
        type: 'Page',
        id: 'b83pv',
        componentTypeForBI: 'wysiwyg.viewer.components.PopupContainer',
        components: [
            {
                type: 'Container',
                id: 'comp-ilwdr4ia',
                components: [
                    {
                        type: 'Container',
                        id: 'comp-ilwe41x9',
                        components: [
                            {
                                type: 'Component',
                                id: 'comp-imer9nyg',
                                skin: 'wysiwyg.viewer.skins.button.TextOnlyButtonSkin',
                                layout: {
                                    width: 395,
                                    height: 40,
                                    x: 121,
                                    y: 382,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: 'wysiwyg.viewer.components.PopupCloseTextButton',
                                data: {
                                    type: 'LinkableButton',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    label: 'No Thanks​',
                                    id: 'dataItem-imer9nyl'
                                },
                                props: {
                                    type: 'ButtonProperties',
                                    metaData: {schemaVersion: '1.0'},
                                    align: 'center',
                                    margin: 0,
                                    id: 'propItem-imer9nym'
                                },
                                style: {
                                    type: 'TopLevelStyle',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    style: {
                                        groups: {},
                                        properties: {
                                            'alpha-txt': '1',
                                            'alpha-txth': '1',
                                            fnt: 'normal normal normal 16px/1.4em raleway',
                                            txt: '#000000',
                                            txth: '#996231'
                                        },
                                        propertiesSource: {fnt: 'value', txt: 'value', txth: 'value'}
                                    },
                                    componentClassName: 'wysiwyg.viewer.components.PopupCloseTextButton',
                                    pageId: '',
                                    compId: '',
                                    styleType: 'custom',
                                    skin: 'wysiwyg.viewer.skins.button.TextOnlyButtonSkin',
                                    id: 'style-imfxlwza'
                                }
                            },
                            {
                                type: 'Component',
                                id: 'comp-im0azk1k',
                                layout: {
                                    width: 491,
                                    height: 29,
                                    x: 73,
                                    y: 101,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: 'wysiwyg.viewer.components.WRichText',
                                data: {
                                    type: 'StyledText',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    text: '<p class="font_8" style="font-size:22px; text-align:center"><span style="color:#4D4D4D;"><span style="font-size:22px"><span style="font-family:raleway,sans-serif">Enter your email and get</span></span></span></p>\n',
                                    stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                    linkList: [],
                                    id: 'dataItem-im0azk1n'
                                },
                                props: {
                                    type: 'WRichTextProperties',
                                    metaData: {schemaVersion: '1.0'},
                                    brightness: 1,
                                    packed: true,
                                    id: 'propItem-im0azk1o'
                                },
                                style: 'txtNew'
                            },
                            {
                                type: 'Component',
                                id: 'comp-im0avzyo',
                                layout: {
                                    width: 544,
                                    height: 99,
                                    x: 46,
                                    y: 160,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: 'wysiwyg.viewer.components.WRichText',
                                data: {
                                    type: 'StyledText',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    text: '<h2 class="font_2" style="font-size: 84px; line-height: 1em; text-align: center;"><span style="font-size:84px"><span style="letter-spacing:-0.1em"><span style="color:#06B0AE"><span style="font-family:lulo-clean-w01-one-bold,sans-serif">10% </span></span><span class="color_2"><span style="font-family:lulo-clean-w01-one-bold,sans-serif">off</span></span></span></span></h2>\n',
                                    stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                    linkList: [],
                                    id: 'dataItem-im0avzyu'
                                },
                                props: {
                                    type: 'WRichTextProperties',
                                    metaData: {schemaVersion: '1.0'},
                                    brightness: 1,
                                    packed: true,
                                    id: 'propItem-im0avzyw'
                                },
                                style: 'txtNew'
                            }
                        ],
                        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                        layout: {
                            width: 637,
                            height: 493,
                            x: 15,
                            y: 14,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'mobile.core.components.Container',
                        style: {
                            type: 'TopLevelStyle',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            style: {
                                groups: {},
                                properties: {
                                    'alpha-bg': '1',
                                    'alpha-brd': '1',
                                    bg: '#FFFFFF',
                                    'boxShadowToggleOn-shd': 'false',
                                    brd: '#660658',
                                    brw: '0',
                                    rd: '0px',
                                    shd: '0px 1px 4px 0px rgba(0,0,0,0.6)'
                                },
                                propertiesSource: {
                                    bg: 'value',
                                    brd: 'value',
                                    brw: 'value',
                                    rd: 'value',
                                    shd: 'value'
                                }
                            },
                            componentClassName: 'mobile.core.components.Container',
                            pageId: '',
                            compId: '',
                            styleType: 'custom',
                            skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                            id: 'style-it8eh6hu'
                        }
                    },
                    {
                        'type': 'Component',
                        'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744',
                        'layout': {
                            'x': 600,
                            'y': 38,
                            'fixedPosition': false,
                            'width': 30,
                            'height': 30,
                            'scale': 1,
                            'rotationInDegrees': 0
                        },
                        'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'parent': 'comp-jv6oynja',
                        'metaData': {
                            'pageId': 'yusqn'
                        },
                        'data': {
                            'type': 'SvgShape',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'yusqn'
                            }
                        },
                        'props': {
                            'type': 'SvgShapeProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'pageId': 'yusqn'
                            },
                            'maintainAspectRatio': true
                        },
                        'mobileHints': {
                            'type': 'MobileHints',
                            'author': 'duplicate',
                            'originalCompId': 'comp-israwtmt',
                            'recommendedScale': 1,
                            'recommendedWidth': 13,
                            'recommendedHeight': 13,
                            'metaData': {
                                'pageId': 'yusqn'
                            }
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'yusqn'
                            },
                            'style': {
                                'properties': {
                                    'alpha-fillcolor': '1',
                                    'alpha-stroke': '1',
                                    'fillcolor': '#424242',
                                    'stroke': '#FFFFFF',
                                    'strokewidth': '1'
                                },
                                'propertiesSource': {
                                    'alpha-fillcolor': 'value',
                                    'alpha-stroke': 'value',
                                    'fillcolor': 'value',
                                    'stroke': 'value',
                                    'strokewidth': 'value'
                                },
                                'groups': {}
                            },
                            'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744'
                        },
                        'connections': {
                            'type': 'ConnectionList',
                            'items': [
                                {
                                    'type': 'WixCodeConnectionItem',
                                    'role': 'button1'
                                }
                            ],
                            'metaData': {
                                'pageId': 'yusqn'
                            }
                        },
                        'activeModes': {}
                    }
                ],
                skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                layout: {
                    width: 667,
                    height: 524,
                    x: 156,
                    y: 127,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.PopupContainer',
                props: {
                    type: 'PopupContainerProperties',
                    metaData: {schemaVersion: '1.0'},
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center',
                    alignmentType: 'nineGrid',
                    horizontalOffset: 0,
                    verticalOffset: 0,
                    id: 'propItem-ilwdr4ic'
                },
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        mediaRef: {
                            type: 'Image',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            title: 'Paint Print Floor',
                            uri: '4b638eda91074488962f2ed310eba030.jpg',
                            description: 'public/a0c10733-b2a6-4330-a431-537a9f05c353/820351a1-729d-4bd1-856d-d578ca44f29e',
                            width: 4288,
                            height: 2848,
                            alt: '',
                            artist: {id: '', name: ''}
                        },
                        color: '#FFFFFE',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    id: 'dataItem-ilwdr4id'
                },
                style: {
                    type: 'TopLevelStyle',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    style: {
                        groups: {},
                        properties: {'alpha-bg': '1', bg: 'rgba(255,255,255,1)'},
                        propertiesSource: {'alpha-bg': 'value', bg: 'value'}
                    },
                    componentClassName: 'wysiwyg.viewer.components.PopupContainer',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                    id: 'style-ilwdr4id2'
                },
                behaviors: {
                    type: 'ObsoleteBehaviorsList',
                    items: '[{"name":"FadeIn","delay":0.1,"duration":0.75,"action":"screenIn","targetId":"","params":{}},{"name":"FadeOut","delay":0.1,"duration":0.75,"action":"exit","targetId":"","params":{}}]',
                    id: 'behavior-iomlwee7'
                }
            }
        ],

        skin: 'skins.core.InlineSkin',
        layout: {
            width: 980,
            height: 651,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'mobile.core.components.Page',
        data: {
            type: 'Page',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            title: 'add_preset_popups_Sale_3',
            hideTitle: true,
            icon: '',
            descriptionSEO: '',
            metaKeywordsSEO: '',
            pageTitleSEO: '',
            pageUriSEO: 'blank-b83pv',
            hidePage: true,
            underConstruction: false,
            pageSecurity: {requireLogin: false, passwordDigest: '', dialogLanguage: ''},
            isPopup: true,
            indexable: false,
            isLandingPage: false,
            pageBackgrounds: {
                desktop: {
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#000000',
                        colorOpacity: 0.7,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                mobile: {
                    custom: true,
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#000000',
                        colorOpacity: 0.7,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'scroll',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    isPreset: true
                }
            },
            ignoreBottomBottomAnchors: true
        },
        props: {
            type: 'PageProperties',
            metaData: {schemaVersion: '1.0'},
            desktop: {popup: {closeOnOverlayClick: true}},
            mobile: {popup: {closeOnOverlayClick: true}},
            id: 'b83pv'
        },
        style: 'p1'
    },
    preset: {
        rect: {width: 305, height: 202, x: 10, y: 464},
        label: 'add_preset_popups_Sale_3',
        tags: null
    }
}

