import type { CompRef } from '@wix/document-services-types';

import { BaseAnimationAPI } from './baseAnimationsApi';
import type { EntranceEffectData } from '../types';

export class EntranceAnimationAPI extends BaseAnimationAPI {
  previewAnimation: (compRef: CompRef, effectData: EntranceEffectData) => void;

  protected getTriggerType() {
    return 'viewport-enter';
  }

  protected getTriggerParams() {
    return {
      trigger: 'viewport-enter',
      params: { threshold: 0.15 },
    };
  }

  get reactionParams() {
    return {
      type: 'Play',
      once: true,
    };
  }
}
