import _ from 'lodash';
import { createPagesApi } from '@/pages-wip';
import { translate } from '@/i18n';
import { link, isAdvancedMenuOpen, sections } from '@/util';
import { nanoid } from 'nanoid';
import experiment from 'experiment';

import {
  cleanId,
  getCustomMenus as filterCustomMenus,
  getAdvancedMenus,
  getItemName,
  isDropdown,
  isMainMenu,
  isMembersMenuId,
} from '../utils/utils';
import {
  MENU_ITEM_TYPE,
  menuComponentTypes,
  MENU_COMPONENTS_DONT_SUPPORT_SUBNESTING_EXCUSES,
  NON_CUSTOM_MENUS_IDS,
  PAGES_MENU_ID,
} from '../constants';
import { menuItemTree } from '@/baseUI';
import { setItemCollapsed } from './userPreferences';

import type { EditorAPI } from '@/editorAPI';
import type {
  MenuItem,
  CompRef,
  Link,
  MenuData,
  PageLink,
  PagesData,
  RoutersDefinition,
  DynamicPageLink,
  SitemapEntry,
} from 'types/documentServices';
import { TRANSLATIONS_MAP } from '../panels/MenuManagePanel/utils/translations';
import type { IMenuConfig } from '../panels/MenuManagePanel/utils/config.types';
import * as stateManagement from '@/stateManagement';

const {
  LinkTypes,
  linkTypeValidators: {
    isPageLink,
    isDynamicPageLink,
    isAnchorLink,
    isPhoneLink,
    isSectionLink,
  },
} = link;

const {
  findItem,
  findParent,
  insertAfter,
  removeFromTree,
  moveToParent,
  flattenTree,
  getTreeHeight,
} = menuItemTree;

type IMenuId = string;
type IMenuItemId = string;
type IRouterId = string;

export type CreateMenuData =
  | (Omit<MenuData, 'id' | 'items' | 'type'> & {
      items: Omit<MenuItem, 'id'>[];
    })
  | MenuData;

export interface IMenuPages {
  [pageId: string]: PagesData;
}

export interface IMenuRoutes {
  [routeId: string]: RoutersDefinition;
}

export type IMenuAPI = ReturnType<typeof createMenuApi>;

interface SubnestingSupportExcuse {
  content?: string;
  linkText?: string;
  helpId?: string;
}

// pageId comes from DS with '#' prepended
const getLinkPageId = (link: PageLink) => cleanId(link.pageId);

export const createMenuApi = (editorAPI: EditorAPI, config?: IMenuConfig) => {
  const menuDataHooks = config?.hooks;

  const getAll = (): MenuData[] =>
    // TODO: not only PAGES_MENU_ID?
    editorAPI.dsRead.menu.getAll().filter((menu) => menu.id !== PAGES_MENU_ID);

  const getMenu = (menuId: IMenuId) =>
    editorAPI.dsRead.menu.getById(menuId, false);
  const getCustomMenus = () => filterCustomMenus(getAll());
  const getPagesMenu = () => getMenu(PAGES_MENU_ID);
  const getMainMenu = () => getCustomMenus().find(isMainMenu);

  const createMenu = (menuData: CreateMenuData) =>
    editorAPI.dsActions.menu.create({ ...menuData, type: 'CustomMenu' });

  const createMenuWithHomePage = (
    name: string,
    additionalMenuData: Partial<CreateMenuData> = {},
  ) => {
    const pagesAPI = createPagesApi(editorAPI);
    const homePageId = pagesAPI.getHomePageId();
    const { title: homePageTitle } = pagesAPI.getPageById(homePageId);

    const homePageLink: PageLink = {
      type: LinkTypes.PAGE_LINK as 'PageLink',
      target: '_self',
      pageId: `#${homePageId}`,
    };

    const homePageMenuItem: Omit<MenuItem, 'id'> = {
      isVisible: true,
      isVisibleMobile: true,
      type: MENU_ITEM_TYPE,
      items: [],
      label: homePageTitle,
      link: homePageLink,
    };

    const menusNames = getAll().map((m) => m.name);
    const menuName = getItemName(menusNames, name);

    const menu = {
      ...additionalMenuData,
      name: menuName,
      items: [homePageMenuItem],
    };

    const menuId = createMenu(menu);

    return menuId;
  };

  const convertExtendedItemToItem = ({ label, metaData, link }: MenuItem) => ({
    type: MENU_ITEM_TYPE,
    isVisible: true,
    isVisibleMobile: true,
    items: [] as AnyFixMe,
    label,
    metaData,
    link,
  });

  interface IExtendedMenuData {
    items: MenuItem[];
    pagesCount: number;
    multiplePagesInMenu: boolean;
  }

  const createCustomMenu = (
    additionalMenuData: Partial<CreateMenuData> = {},
    items?: MenuItem[],
  ) => {
    if (!items) {
      const menuData = editorAPI.dsRead.menu.getById('CUSTOM_MAIN_MENU', false);

      const extendedMenuData = editorAPI.menus.getExtendedMenuItemsTree(
        menuData,
      ) as IExtendedMenuData;

      const extendedItems = extendedMenuData.items;

      items =
        isAdvancedMenuOpen() && editorAPI.isMobileEditor()
          ? menuData.items
          : // @ts-expect-error
            (flattenTree(extendedItems)
              // @ts-expect-error
              .filter(({ item }) => isPageLink(item.link))
              .slice(0, 4)
              // @ts-expect-error
              .map((node): MenuItem => node.item)
              .map(convertExtendedItemToItem) as MenuItem[]);
    }

    const defaultName = 'Custom Menu';

    if (items.length === 0) {
      return createMenuWithHomePage(defaultName, additionalMenuData);
    }

    const menusNames = getAll().map((m) => m.name);
    const name = getItemName(menusNames, defaultName);

    return createMenu({
      ...additionalMenuData,
      name,
      items,
    });
  };

  const isInMultiLingualFlow = () =>
    editorAPI.language.multilingual.isEnabled() &&
    !stateManagement.multilingual.services.utils.currentIsOriginal(editorAPI);

  const duplicateMenu = ({
    menuId,
    name,
    itemsOnly,
  }: {
    menuId: IMenuId;
    name?: string;
    itemsOnly?: boolean;
  }): string => {
    const menu = getMenu(menuId);
    const duplicatedMenuName = menu.name;
    const names = getAll().map((m) => m.name);
    const menuName = getItemName(names, name || duplicatedMenuName);

    const newMenu = itemsOnly
      ? { items: menu.items, name: menuName }
      : { ...menu, name: menuName };

    const createdMenuId = editorAPI.dsActions.menu.create(newMenu as MenuData);

    return createdMenuId;
  };

  const updateMenu = (menuId: IMenuId, updatedData: Partial<MenuData>) => {
    const isMultilingual = isInMultiLingualFlow();

    if (isMultilingual) {
      const languageCode = isMultilingual
        ? editorAPI.dsRead.language.current.get()
        : undefined;

      editorAPI.multilingual.menu.update(languageCode, menuId, updatedData);

      return;
    }

    const currentData = getMenu(menuId);

    editorAPI.dsActions.menu.update(menuId, {
      ...currentData,
      ...updatedData,
    });
  };

  const renameMenu = (menuId: IMenuId, newName: string) => {
    updateMenu(menuId, { name: newName });
  };

  const replaceMenuItems = (menuId: IMenuId, items: MenuItem[]) => {
    updateMenu(menuId, { items });
  };

  const removeMenu = (menuId: IMenuId) =>
    editorAPI.dsActions.menu.remove(menuId);

  const hasConnectedComponents = (menuId: IMenuId) =>
    editorAPI.dsRead.menu.hasConnectedComps(menuId);

  const removeMenusWithoutConnectedComponents = () => {
    getAll().forEach((menu) => {
      if (!hasConnectedComponents(menu.id) && !isMembersMenuId(menu.id)) {
        removeMenu(menu.id);
      }
    });
    return editorAPI.waitForChangesAppliedAsync();
  };

  const getItem = (menuId: IMenuId, itemId: IMenuItemId) => {
    const menu = getMenu(menuId);
    // @ts-expect-error
    return findItem(menu.items, itemId);
  };

  const addItem = async (
    menuId: IMenuId,
    menuItem: Partial<MenuItem>,
  ): Promise<IMenuItemId> => {
    const newItem = {
      type: 'BasicMenuItem' as const,
      ...menuItem,
    };

    if (isInMultiLingualFlow()) {
      const languageCode = editorAPI.language.current.get();
      const { items } = editorAPI.multilingual.menu.get(languageCode, menuId);
      editorAPI.multilingual.menu.update(languageCode, menuId, {
        items: [...items, newItem],
      });

      await editorAPI.dsActions.waitForChangesAppliedAsync();

      const newItems = editorAPI.multilingual.menu.get(
        languageCode,
        menuId,
      ).items;

      return newItems[newItems.length - 1].id;
    }

    const id = editorAPI.dsActions.menu.addItem(menuId, newItem);
    await editorAPI.dsActions.waitForChangesAppliedAsync();

    menuDataHooks?.onItemAdded?.(menuId, newItem as MenuItem);

    return id;
  };

  const createPageAfter = async (
    menuId: IMenuId,
    afterItemId?: IMenuItemId,
    name?: string,
  ): Promise<IMenuItemId> => {
    /**
     * Add page, wait for sync with menu, change the position of it in the items list and return new id
     * */
    const pagesAPI = createPagesApi(editorAPI);
    const pageRef = pagesAPI.add(name);

    //  DS adds a page to CUSTOM_MAIN_MENU automatically. So we need to wait for it and then get its id.
    await editorAPI.dsActions.waitForChangesAppliedAsync();

    const meuItemId = getItemByPageId(menuId, cleanId(pageRef.pageId));
    const { items } = getMenu(menuId);
    const item = getItem(menuId, meuItemId);

    if (afterItemId) {
      const newItemsList = insertAfter(
        // @ts-expect-error
        removeFromTree(items, item.id),
        afterItemId,
        item,
      );
      // @ts-expect-error
      replaceMenuItems(menuId, newItemsList);
    }

    return meuItemId;
  };

  const removeItem = async (
    menuId: IMenuId,
    itemId: IMenuItemId,
    keepSubitems: boolean = true,
  ) => {
    const menuItem = getItem(menuId, itemId);

    const isConfirmed =
      !menuDataHooks?.requestRemovalConfirmation ||
      // @ts-expect-error
      (await menuDataHooks?.requestRemovalConfirmation(menuId, menuItem, {
        translate,
      }));

    if (!isConfirmed) {
      return;
    }

    editorAPI.dsActions.menu.removeItem(menuId, itemId, keepSubitems);

    await editorAPI.dsActions.waitForChangesAppliedAsync();
    // @ts-expect-error
    menuDataHooks?.onItemRemoved?.(menuId, menuItem);
  };

  const updateItem = (
    menuId: IMenuId,
    itemId: IMenuItemId,
    item: Partial<MenuItem>,
  ) => {
    editorAPI.dsActions.menu.updateItem(menuId, itemId, item);
  };

  /**
   * API for manipulate/interact with pages and it's structures.
   * @description Updates all items that match predicate in all custom menus
   */
  const bulkItemUpdate = (
    predicate: (item: MenuItem) => boolean,
    updater: (currentItem: MenuItem) => Partial<MenuItem>,
  ) => {
    const updateItems = (items: MenuItem[]): MenuItem[] =>
      items.map((item) => {
        const updatedData = predicate(item) ? updater(item) : {};

        return {
          ...item,
          items: updateItems(item.items),
          ...updatedData,
        };
      });

    const menus = isAdvancedMenuOpen()
      ? getAdvancedMenus(getAll())
      : getCustomMenus();

    menus.forEach((menu) => {
      const initialItems = menu.items;
      const updatedItems = updateItems(initialItems);

      if (!_.isEqual(initialItems, updatedItems)) {
        replaceMenuItems(menu.id, updatedItems);
      }
    });
  };

  const renameItem = (
    menuId: IMenuId,
    itemId: IMenuItemId,
    newName: string,
  ) => {
    const item = getItem(menuId, itemId);
    // @ts-expect-error
    const oldName = item.label;

    // @ts-expect-error
    const { link } = item;

    if (isPageLink(link)) {
      const pageId = cleanId(link.pageId);

      editorAPI.menus.renamePage(pageId, oldName, newName);
    } else {
      updateItem(menuId, itemId, { label: newName });
    }
  };

  const renamePageInCustomMenus = (pageId: string, title: string) =>
    bulkItemUpdate(
      (menuItem) =>
        isPageLink(menuItem.link) && cleanId(menuItem.link.pageId) === pageId,
      () => ({
        label: title,
      }),
    );

  const unlinkItem = (menuId: IMenuId, itemId: IMenuItemId) => {
    const names = getMenu(menuId).items.map((x) => x.label);

    const label = getItemName(
      names,
      translate(TRANSLATIONS_MAP.ITEM.DROPDOWN.newItemText),
    );

    updateItem(menuId, itemId, { label, link: undefined });
  };

  const canDuplicateItem = (menuItem: MenuItem) => {
    const pagesAPI = createPagesApi(editorAPI);
    const { link } = menuItem;

    if (isPageLink(link)) {
      return pagesAPI.canDuplicatePage(cleanId(link.pageId));
    }

    return isDropdown(menuItem);
  };

  const linkItem = (menuId: IMenuId, itemId: IMenuItemId, link: Link) => {
    const item = getItem(menuId, itemId);
    const pagesAPI = createPagesApi(editorAPI);
    // @ts-expect-error
    let { label } = item;

    if (isPageLink(link)) {
      const page = pagesAPI.getPageById(cleanId(link.pageId));
      label = page.title;
    }

    updateItem(menuId, itemId, { link, label });
  };

  const connect = (menuId: IMenuId, compRef: CompRef): void =>
    editorAPI.dsActions.menu.connect(compRef, menuId);

  const getDynamicPageLinkLabel = async (link: DynamicPageLink) => {
    /* Anchor on dynamic page */
    if (link.anchorDataId) {
      const compId = cleanId(link.anchorDataId);
      const compData = editorAPI.data.getById(compId);
      return compData.name;
    }

    if (!link.innerRoute) {
      const pageData = editorAPI.pages.data.get(cleanId(link.pageId));
      return pageData.title;
    }

    const innerRoutes: SitemapEntry[] = await new Promise((resolve) => {
      editorAPI.routers.getRouterInnerRoutes(
        link.routerId,
        cleanId(link.pageId),
        resolve,
      );
    });

    const innerRouteInfo = innerRoutes.find(
      ({ url }) => url === link.innerRoute,
    );

    return innerRouteInfo?.title;
  };

  const getNewLinkLabel = async (link: Link) => {
    if (isAnchorLink(link)) {
      return link.anchorName;
    }

    if (isPhoneLink(link)) {
      return link.phoneNumber;
    }

    if (isPageLink(link)) {
      const pageId = getLinkPageId(link);
      const page = editorAPI.pages.data.get(pageId);

      return page.title;
    }

    if (experiment.isOpen('se_menuLinksLabels')) {
      if (sections.isSectionsEnabled() && isSectionLink(link)) {
        return link.anchorName;
      }

      if (isDynamicPageLink(link)) {
        return getDynamicPageLinkLabel(link);
      }
    }

    return null;
  };

  const addDropdown = async (
    menuId: IMenuId,
    afterItemId?: IMenuItemId,
    name: string = translate(TRANSLATIONS_MAP.ITEM.DROPDOWN.newItemText),
  ) => {
    const menuItems = getMenu(menuId).items;
    const names = menuItems.map((x) => x.label);

    const label = getItemName(names, name);

    const newItemId = await addItem(menuId, { label });

    if (afterItemId) {
      const newItem = getItem(menuId, newItemId);
      // @ts-expect-error
      const newItemsList = insertAfter(menuItems, afterItemId, newItem);
      // @ts-expect-error
      replaceMenuItems(menuId, newItemsList);
    }

    return newItemId;
  };

  const addContainer = async (menuId: IMenuId) => {
    const name = translate(TRANSLATIONS_MAP.ITEM.CONTAINER.newItemText);
    const itemsNames = getMenu(menuId).items.map((x) => x.label);
    const label = getItemName(itemsNames, name);
    const slot = nanoid();

    const newItemId = await addItem(menuId, {
      label,
      slot,
    });

    return newItemId;
  };

  const moveToDropdown = (
    menuId: IMenuId,
    itemId: IMenuItemId,
    parentId: IMenuItemId,
  ) => {
    const menu = getMenu(menuId);
    const item = getItem(menuId, itemId);
    // @ts-expect-error
    const newItems = moveToParent(menu.items, parentId, item);

    // @ts-expect-error
    replaceMenuItems(menuId, newItems);

    const options = {
      menuId,
      itemId: parentId,
      isCollapsed: false,
    };

    setItemCollapsed(editorAPI, options);
  };

  const moveFromDropdown = (menuId: IMenuId, itemId: IMenuItemId) => {
    const menu = getMenu(menuId);
    const { items } = menu;
    // @ts-expect-error
    const item = findItem(items, itemId);

    // @ts-expect-error
    const parent = findParent(items, itemId);

    const newItemsList = insertAfter(
      // @ts-expect-error
      removeFromTree(items, itemId),
      parent.id,
      item,
    );

    // @ts-expect-error
    replaceMenuItems(menuId, newItemsList);
  };

  // this could be probably moved to DS
  const getMenuComponents = (rootRef?: CompRef | string) =>
    _.flatMap(Object.values(menuComponentTypes), (type) =>
      editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
        type,
        rootRef as CompRef,
      ),
    );

  const getMenuComponentsWithCustomMenus = (rootRef?: CompRef | string) =>
    getMenuComponents(rootRef).filter((menuCompRef) => {
      const menuData = editorAPI.components.data.get(menuCompRef);
      // there could be a menuData with menuRef as undefined
      const menuId = menuData?.menuRef ? cleanId(menuData.menuRef) : undefined;

      return !!menuId && !NON_CUSTOM_MENUS_IDS.includes(menuId);
    });

  const getDesktopMenuComponentsByMenuId = (menuIdToFindBy: IMenuId) =>
    getMenuComponents().filter((menuCompRef) => {
      const menuData = editorAPI.components.data.get(menuCompRef);
      const menuId = cleanId(menuData.menuRef);

      return menuId === menuIdToFindBy;
    });

  const getMenuByCompRef = (compRef: CompRef) => {
    const { menuRef } = editorAPI.documentServices.components.data.get(compRef);
    const menuId = cleanId(menuRef);
    return getMenu(menuId);
  };

  /** This function returns content to show as warnings in case component doesn't support sub-nested menus.
   * Can be used to get content or to know whether menu conneced to unsupported component or not (cast result to boolean)
   * Returns warning contents or null in case menu is supported by connected components
   */
  const getSubnestingSupportExcuse = (): SubnestingSupportExcuse | null => {
    const selectedComponent = editorAPI.selection.getSelectedComponents()[0];
    const componentType = editorAPI.components.getType(selectedComponent);

    return (
      MENU_COMPONENTS_DONT_SUPPORT_SUBNESTING_EXCUSES[componentType] || null
    );
  };

  const getMobileMenuComponents = (): CompRef[] => {
    const mobileMenuComponents = [
      'TINY_MENU',
      'MENU_AS_CONTAINER_EXPANDABLE_MENU',
    ]
      .map((id) => {
        const ref = { id, type: 'MOBILE' } as const;
        const data = editorAPI.dsRead.components.data.get(ref);

        if (data) {
          return ref;
        }

        return undefined;
      })
      .filter(Boolean);

    return mobileMenuComponents;
  };

  const getMenuPagesList = (menuId: IMenuId) => {
    const pagesAPI = createPagesApi(editorAPI);
    const pages = pagesAPI.getAllPages();
    const menu = getMenu(menuId);

    // @ts-expect-error
    const pagesIds = flattenTree(menu.items)
      // @ts-expect-error
      .filter((i) => isPageLink(i.item.link))
      // @ts-expect-error
      .map((i) => cleanId((i.item.link as PageLink).pageId));

    return pages.filter((p) => pagesIds.includes(p.id));
  };

  const getMenuPagesIds = (menuId: IMenuId) => {
    const pagesList = getMenuPagesList(menuId);
    return pagesList.map((p) => p.id);
  };

  const getMenuPagesInfo = (menuId: IMenuId) => {
    const pagesList = getMenuPagesList(menuId);
    return _.keyBy(pagesList, 'id');
  };

  const getMenuRoutersInfo = (menuId: IMenuId): IMenuRoutes => {
    const menu = getMenu(menuId);

    // @ts-expect-error
    const routerIds: IRouterId[] = flattenTree(menu.items)
      .map((treeItem) => {
        // @ts-expect-error
        if (isDynamicPageLink(treeItem.item.link)) {
          // @ts-expect-error
          return treeItem.item.link.routerId;
        }
        return null;
      })
      .filter(Boolean);
    const uniqueRouterIds = Array.from(new Set(routerIds));

    const routersData: [IRouterId, RoutersDefinition][] = uniqueRouterIds.map(
      (id) => [id, editorAPI.dsRead.routers.get.byId(id)],
    );

    return _.fromPairs(routersData);
  };

  const getItemByPageId = (menuId: IMenuId, pageId: string) => {
    const menu = getMenu(menuId);

    // @ts-expect-error
    const item = findItem(menu.items, (i) => {
      // @ts-expect-error
      const { link } = i.item;

      if (!isPageLink(link)) {
        return false;
      }

      return cleanId(link.pageId) === pageId;
    });

    return item?.id;
  };

  const getMenuDepth = (menuId: IMenuId) => {
    const menu = getMenu(menuId);

    // @ts-expect-error
    const itemHeights = menu.items.map((item) => getTreeHeight(item));

    return Math.max(...itemHeights);
  };

  const getSelectedMenuId = () => {
    const compRef = editorAPI.selection.getSelectedComponents()[0];
    const { menuRef } = editorAPI.components.data.get(compRef);

    return menuRef.replace('#', '');
  };

  return {
    getAll,
    getCustomMenus,
    getPagesMenu,
    getMenu,
    getMainMenu,
    createMenuWithHomePage,
    createCustomMenu,
    createMenu,
    duplicateMenu,
    renameMenu,
    removeMenu,
    replaceMenuItems,
    updateMenu,
    removeMenusWithoutConnectedComponents,

    getItem,
    updateItem,
    bulkItemUpdate,
    renameItem,
    renamePageInCustomMenus,
    addItem,
    createPageAfter,
    addDropdown,
    addContainer,
    removeItem,
    unlinkItem,
    linkItem,
    getItemByPageId,

    canDuplicateItem,

    moveToDropdown,
    moveFromDropdown,

    connect,
    getNewLinkLabel,

    hasConnectedComponents,
    getMenuComponents,
    getMenuComponentsWithCustomMenus,
    getDesktopMenuComponentsByMenuId,
    getMenuByCompRef,
    getMobileMenuComponents,

    getMenuDepth,
    getSubnestingSupportExcuse,

    getMenuPagesIds,
    getMenuPagesInfo,
    getMenuRoutersInfo,
    getSelectedMenuId,
  };
};

export type MenuAPI = ReturnType<typeof createMenuApi>;
