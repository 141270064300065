import { show } from '@wix/deeplink-editor';
import type { DeprecatedComponents } from '../utils/componentDeprecationUtils';

export const contactFormDeprecationConfiguration: ValueOf<DeprecatedComponents> =
  {
    helpId: '3f079ae2-312b-4196-99f8-73e2c142cb39',
    defaultDeprecationModalOverrides: {
      onContinue: () => {
        show({
          type: 'addPanel',
          named: {
            appDefinitionId: '14ce1214-b278-a7e4-1373-00cebd1bef7c',
          },
        });
      },
      title: 'Component_Deprecation_ContactForm_GFPP_Popup_Title',
      description: {
        firstSentenceText:
          'Component_Deprecation_ContactForm_GFPP_Popup_Text_1',
        secondSentenceText:
          'Component_Deprecation_ContactForm_GFPP_Popup_Text_2',
        thirdSentenceText:
          'Component_Deprecation_ContactForm_GFPP_Popup_Text_3',
      },
      mainCtaText: 'Component_Deprecation_ContactForm_GFPP_Popup_CTA',
    },
  };
