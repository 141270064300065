import {
  adaptMenuComponentStructure,
  connectMainMenuToComponent,
} from '@/menu';

import type { EditorAPI } from '@/editorAPI';
import type { CompStructure } from 'types/documentServices';

const mapComponentTypeToAdapter: Record<
  string,
  (editorAPI: EditorAPI, compStructure: CompStructure) => CompStructure
> = {
  'wysiwyg.viewer.components.menus.DropDownMenu': adaptMenuComponentStructure,
  'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu':
    adaptMenuComponentStructure,
  'wysiwyg.viewer.components.mobile.TinyMenu': adaptMenuComponentStructure,
  'wysiwyg.viewer.components.ExpandableMenu': adaptMenuComponentStructure,
  'wixui.StylableHorizontalMenu': adaptMenuComponentStructure,
  'wixui.Breadcrumbs': connectMainMenuToComponent,
};

export const adaptAddingComponentStructure = (
  editorAPI: EditorAPI,
  componentStructure: CompStructure,
) => {
  const adapter = mapComponentTypeToAdapter[componentStructure.componentType];

  return adapter ? adapter(editorAPI, componentStructure) : componentStructure;
};
