import type { EditorAPI } from '@/editorAPI';
import type { CompLayout, CompRef } from 'types/documentServices';
import type { MousePosition, ViewerMouseCoordinates } from '../types';

export type PluginFactoryScope = Readonly<{
  editorAPI: EditorAPI;
  selectedComp: CompRef[];
  initMousePosition: MousePosition;
  shouldDragAndCopy: boolean;
}>;

abstract class BaseHook<A, RTap, RFire> {
  protected subs: Array<(arg: A) => RTap> = [];
  tap(cb: (arg: A) => RTap) {
    this.subs.push(cb);
  }
  abstract fire(v: A): RFire;
}

class VoidHook<A> extends BaseHook<A, void, void> {
  fire(v: A) {
    this.subs.forEach((cb) => cb(v));
  }
}

class PromiseHook<A> extends BaseHook<A, Promise<void>, Promise<void>> {
  async fire(v: A): Promise<void> {
    await Promise.all(this.subs.map((cb) => cb(v)));
  }
}

interface OverrideLayoutCtx {
  isFastDrag: boolean;
  isAltKeyDown: boolean;
}

class OverrideLayoutHook {
  protected subs: Array<Function> = [];
  tap(cb: (layout: CompLayout, ctx: OverrideLayoutCtx) => CompLayout) {
    this.subs.push(cb);
  }

  fire(layout: CompLayout, ctx: OverrideLayoutCtx): CompLayout {
    return this.subs.reduce((acc, cb) => cb(acc, ctx), layout);
  }
}

export function createHooks() {
  return {
    onDragStart: new VoidHook<void>(),
    overrideLayout: new OverrideLayoutHook(),
    beforeLayoutUpdate: new VoidHook<{
      mouseCoordinates: ViewerMouseCoordinates;
      isFastDrag: boolean;
      layout: CompLayout;
      isAltKeyDown: boolean;
    }>(),
    updateLayout: new VoidHook<UpdateLayoutArgs>(),
    beforeEndDragLayout: new VoidHook<{
      mouseCoordinates: ViewerMouseCoordinates;
    }>(),
    endDragLayout: new PromiseHook<void>(),
    endDrag: new VoidHook<{
      mouseCoordinates: ViewerMouseCoordinates;
    }>(),
  };
}
type Hooks = ReturnType<typeof createHooks>;

export type EmitPluginHooks = {
  [K in keyof Hooks]: { fire: Hooks[K]['fire'] };
};

export type TapPluginHooks = { [K in keyof Hooks]: { tap: Hooks[K]['tap'] } };

export interface PluginContext {
  pluginFactoryScope: PluginFactoryScope;
  hooks: TapPluginHooks;
}

export interface BaseDragPlugin {
  init(ctx: PluginContext): void;
}

export interface UpdateLayoutArgs {
  mouseCoordinates: ViewerMouseCoordinates;
  currentMousePosition: MousePosition & { isShiftPressed: boolean };
  isAltKeyDown: boolean;
  fireOverrideLayoutHook: (layout: CompLayout) => CompLayout;
  fireBeforeLayoutUpdateHook: (layout: CompLayout) => void;
  isShiftPressed: boolean;
}
