// @ts-nocheck
import _ from 'lodash';
import postMainActionsEnhancers from './enhancers/postMainActions/enhancers';
import postPresetActionsEnhancers from './enhancers/postPresetActions/enhancers';

function applyOverrides(enhancers, editorAPI, compRefs, gfppData) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(enhancers, function (enhancer) {
    enhancer(editorAPI, compRefs, gfppData);
  });
}

export default {
  applyPostMainActionsOverrides: _.partial(
    applyOverrides,
    postMainActionsEnhancers,
  ),
  applyPostPresetActionsOverrides: _.partial(
    applyOverrides,
    postPresetActionsEnhancers,
  ),
  applyGfppGlobalEnhancers: (editorAPI, compRefs, gfppData) => {
    applyOverrides(
      Object.values(
        editorAPI.pluginService.getAllPluginsOfType('gfppGlobalEnhancers') ||
          {},
      ),
      editorAPI,
      compRefs,
      gfppData,
    );
  },
};
