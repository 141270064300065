// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as core from '@/core';
import * as stateManagement from '@/stateManagement';
import * as designPanelMixin from '@/designPanelUtils';
import addPanelDataConsts from '@/addPanelDataConsts';
import React from 'react';
import DesignPanel from '../core/designPanel';
import * as customizeDesignDataPanel from '@/customizeDesignDataPanel';
import * as baseUI from '@/baseUI';

const { panelPosition } = stateManagement.domMeasurements.hoc;

let selectedComponentDesign = null;
let permanentSelectedDesign = null;

// eslint-disable-next-line react/prefer-es6-class
const CssDesignDataPanel = createReactClass({
  displayName: 'change-css-design-data-panel',
  mixins: [designPanelMixin, core.mixins.editorAPIMixin],

  getDefaultProps() {
    return {
      backButtonLabel: 'design_panel_back_button_label',
    };
  },

  UNSAFE_componentWillMount() {
    selectedComponentDesign = this.getEditorAPI().components.design.get(
      this.props.selectedComponent,
    );
  },

  componentWillUnmount() {
    permanentSelectedDesign = null;
  },

  changePermanentState(compDef) {
    if (compDef.design) {
      permanentSelectedDesign = compDef.design;
      this.getEditorAPI().components.design.update(
        this.props.selectedComponent,
        compDef.design,
        true,
      );
    }
  },

  changeTempState(compDef) {
    if (compDef.design) {
      this.getEditorAPI().components.design.update(
        this.props.selectedComponent,
        compDef.design,
        true,
      );
    }
  },

  maintainOriginalState() {
    const originalDesign = permanentSelectedDesign || selectedComponentDesign;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-null
    if (!_.isNull(originalDesign)) {
      this.getEditorAPI().components.design.update(
        this.props.selectedComponent,
        originalDesign,
        true,
      );
    }
  },

  isSelectedItem(item, sectionType) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-null
    if (!_.isNull(permanentSelectedDesign)) {
      return item.design.charas === permanentSelectedDesign.charas;
    } else if (item.design.charas === selectedComponentDesign.charas) {
      const SectionTypes = addPanelDataConsts.SECTIONS_TYPES;
      return (
        sectionType === SectionTypes.PERSONALIZED_DESIGN ||
        sectionType === SectionTypes.DESIGN_DATA_THEME_PRESET
      );
    }
  },

  shouldLinkToAdvancedStyling() {
    return !this.state.advancedStyling;
  },

  render() {
    return (
      <DesignPanel ref="designPanel" {...this.getPropsForDesignPanel()}>
        {this.shouldLinkToAdvancedStyling() ? (
          <div key="customizeDesignBtn" className="button-wrapper">
            <baseUI.button
              ref="customizeDesign"
              label="design_customize_button"
              onClick={this.openCustomizeDesign}
              icon="customize_design_drop_light"
              className="button"
            />
          </div>
        ) : null}
        {this.state.advancedStyling
          ? (() => {
              const CustomizePanel = customizeDesignDataPanel.panel;

              return (
                <div key="advancedStylePanel">
                  <CustomizePanel
                    selectedComponent={this.props.selectedComponent}
                    selectedCategory={this.props.selectedCategory}
                  />
                </div>
              );
            })()
          : null}
      </DesignPanel>
    );
  },
});

export default panelPosition()(CssDesignDataPanel);
