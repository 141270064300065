import React from 'react';
import * as panels from '@/panels';
import {
  Button,
  Composites,
  Illustration,
  PanelHeader,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';
import * as baseUI from '@/baseUI';
import { translate } from '@/i18n';

function template() {
  return (
    <div className="update-error-panel">
      <panels.frames.FocusPanelFrame
        panelName="updateError"
        shouldHideHeader={true}
        width="560px"
      >
        <Composites.PopupSmallSize className="composite-full-height">
          <PanelHeader
            // @ts-expect-error
            titleType="T16"
            noCloseBtn={true}
            noHelpBtn={true}
          >
            <span data-aid="header-title">
              {translate('AppStudio_Update_Error_Title')}
            </span>
          </PanelHeader>
          <Composites.PanelContent>
            <Composites.RichTextWithIllustration>
              <Illustration>
                <baseUI.symbol name="plaster" />
              </Illustration>
              <RichText>
                <p>{translate('AppStudio_Update_Error_Text')}</p>
              </RichText>
            </Composites.RichTextWithIllustration>
          </Composites.PanelContent>
          <Composites.ActionSetHorizontal>
            <Button
              onClick={() => {
                {
                  window.location.reload();
                }
              }}
              className="primary"
            >
              <TextLabel value="AppStudio_Refresh_Button" />
            </Button>
          </Composites.ActionSetHorizontal>
        </Composites.PopupSmallSize>
      </panels.frames.FocusPanelFrame>
    </div>
  );
}

export default () => template();
