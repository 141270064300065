import React from 'react';
import _ from 'lodash';
import { hoc, array } from '@/util';
import * as stateManagement from '@/stateManagement';
import { useEffect } from 'react';
import { menuBiLogger } from './menuBiLogger';
import type { StateMapperArgs } from 'types/redux';
import { createMenuApi } from '../API/menuAPI';
import { cleanId } from '../utils/utils';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import type { CompRef } from 'types/documentServices';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StoreProps = ReturnType<typeof mapStateToProps>;
interface OwnProps {
  menuId?: string;
  selectedComponent?: CompRef | CompRef[];
}

const mapStateToProps = (
  { state, editorAPI, dsRead }: StateMapperArgs,
  ownProps: OwnProps,
) => {
  const { selection, pages, components } = stateManagement;
  const menuAPI = createMenuApi(editorAPI);
  const customMenus = menuAPI.getCustomMenus();
  const selectedComponent =
    array.asArray(ownProps.selectedComponent)[0] ||
    selection.selectors.getSingleSelectedComponent(state);
  const [selectedComponentData] = components.selectors.getCompsData(
    selectedComponent,
    dsRead,
  );
  const compType = components.selectors.getCompType(selectedComponent, dsRead);
  const menuSetId = cleanId(selectedComponentData?.menuRef || ownProps.menuId);
  const focusedPageId = pages.selectors.getFocusedPageId(state);

  return {
    selectedComponentId: selectedComponent?.id,
    defaultMenuSetId: customMenus[0]?.id,
    menuSetId,
    focusedPageId,
    compType,
  };
};

const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const sendBi = (descriptor: BiEventDefinition, params: BiEventFields) => {
    dispatch(stateManagement.bi.actions.event(descriptor, params));
  };

  return {
    sendBi,
  };
};

const MenuBiHoc = (Target: React.ComponentClass) => {
  const WithMenuBi: React.FC<OwnProps & StoreProps & DispatchProps> = (
    props,
  ) => {
    const {
      menuSetId,
      sendBi,
      selectedComponentId,
      defaultMenuSetId,
      focusedPageId,
      compType,
    } = props;

    useEffect(
      () => {
        menuBiLogger.init(sendBi);
      }, // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    useEffect(() => {
      menuBiLogger.updateContext({
        menuSetId,
        selectedComponentId,
        defaultMenuSetId,
        focusedPageId,
        compType,
      });
    }, [
      menuSetId,
      selectedComponentId,
      defaultMenuSetId,
      focusedPageId,
      compType,
    ]);

    return (
      <Target
        {..._.omit(props, [
          'menuSetId',
          'focusedPageId',
          'defaultMenuSetId',
          'selectedComponentId',
        ])}
      />
    );
  };

  return WithMenuBi;
};

export const withMenuBi = (Target: React.ComponentClass) => {
  return hoc.connect(
    hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  )(MenuBiHoc(Target));
};
