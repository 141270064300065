import _ from 'lodash';
import { debugReporter, isAnchor, isSection } from '../../utils';
import { waitForChangesAppliedAndCheckTimeLimit } from '../migrationUtils';
import {
  getAnchorTarget,
  calcAnchorArrangementIndexInSection,
} from '@/anchors';
import { DEBUG } from '../../constants';
import type { SectionsMigrationScope as Scope } from '../../scope';
import type { DocumentServicesObject, CompRef } from 'types/documentServices';

function movePageRootAnchorsToSections(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
) {
  const pageRootComponents = documentServices.components
    .getChildren(pageRef)
    .map((compRef) => ({
      compRef,
      layout: documentServices.components.layout.get(compRef),
    }))
    .filter((item) => !!item.layout);

  const pageSections = pageRootComponents.filter(({ compRef }) =>
    isSection(documentServices, compRef),
  );
  const pageRootAnchors = pageRootComponents.filter(({ compRef }) =>
    isAnchor(documentServices, compRef),
  );

  const movedAnchors: { anchorRef: CompRef; anchorSectionRef: CompRef }[] = [];
  pageRootAnchors.forEach((anchor) => {
    const anchorLayout = anchor.layout;
    const anchorSection = getAnchorTarget(anchorLayout, pageSections, {
      getLayout: ({ layout }) => layout,
    });

    if (anchorSection) {
      documentServices.components.setContainer(
        anchor.compRef,
        anchorSection.compRef,
      );
      movedAnchors.push({
        anchorRef: anchor.compRef,
        anchorSectionRef: anchorSection.compRef,
      });
    }
  });

  return { movedAnchors };
}

/**
 * Ensure anchor position is within section bounds.
 */
function updateAnchorPosition(
  documentServices: DocumentServicesObject,
  anchorRef: CompRef,
) {
  const anchorLayout = documentServices.components.layout.get(anchorRef);
  const anchorSectionLayout = documentServices.components.layout.get(
    documentServices.components.getContainer(anchorRef),
  );

  if (!anchorLayout || !anchorSectionLayout) {
    return;
  }

  const anchorLayoutY = _.clamp(anchorLayout.y, 0, anchorSectionLayout.height);

  if (anchorLayout.y !== anchorLayoutY) {
    // NOTE:
    // 1. `y < 0` - anchor overlaps header or there are gaps between sections
    // 2. `y > section.height` - anchor overlaps footer
    documentServices.components.layout.update(anchorRef, {
      y: anchorLayoutY,
      ...anchorLayout,
    });
  }
}

/**
 * Ensure anchor arrangement index is before components next to the anchor
 */
function updateAnchorArrangementIndex(
  documentServices: DocumentServicesObject,
  anchorRef: CompRef,
) {
  const anchorArrangementIndex = calcAnchorArrangementIndexInSection(
    documentServices,
    anchorRef,
  );

  if (
    typeof anchorArrangementIndex === 'number' &&
    anchorArrangementIndex >= 0
  ) {
    documentServices.components.arrangement.moveToIndex(
      anchorRef,
      anchorArrangementIndex,
    );
  }
}

export async function moveAnchorsIntoSectionsTransaction(
  scope: Scope,
  {
    pagesRefs,
  }: {
    pagesRefs: CompRef[];
  },
) {
  const startTime = performance.now();
  const { documentServices } = scope.editorAPI;
  const movedAnchors: { anchorRef: CompRef; anchorSectionRef: CompRef }[] = [];

  pagesRefs.forEach((pageRef) => {
    const desktopPageRef = pageRef;
    const mobilePageRef = documentServices.components.getMobileRef(pageRef);

    movedAnchors.push(
      ...movePageRootAnchorsToSections(documentServices, desktopPageRef)
        .movedAnchors,
    );

    movedAnchors.push(
      ...movePageRootAnchorsToSections(documentServices, mobilePageRef)
        .movedAnchors,
    );
  });

  await waitForChangesAppliedAndCheckTimeLimit(documentServices, startTime);

  movedAnchors.forEach(({ anchorRef, anchorSectionRef }) => {
    if (
      !documentServices.utils.isSameRef(
        documentServices.components.getContainer(anchorRef),
        anchorSectionRef,
      )
    ) {
      debugReporter(scope, DEBUG.WARN, 'anchor is not moved to section', {
        anchorRef,
        anchorSectionRef,
      });
      return;
    }

    updateAnchorPosition(documentServices, anchorRef);
    updateAnchorArrangementIndex(documentServices, anchorRef);
  });

  await waitForChangesAppliedAndCheckTimeLimit(documentServices, startTime);
}
