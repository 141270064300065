// @ts-nocheck
import React from 'react';
import ReactDOM from 'reactDOM';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import experiment from 'experiment';
import constants from '@/constants';
import * as util from '@/util';
import * as utils from '@wix/santa-editor-utils';
import {
  Composites,
  CustomScroll,
  InfoIcon,
  PanelHeader,
  SectionDivider,
  TextLabel,
  TooltipOnEllipsis,
} from '@wix/wix-base-ui';
import * as stateManagement from '@/stateManagement';
import * as baseUI from '@/baseUI';
import { cx } from '@/util';
import { EditorParamsApiKey } from '@/apis';

const connectionFirstTimeIndicationUserPrefString =
  'closed_teaser_componentConnectedToDataTeaser';
const { getSelectedComponentType } = stateManagement.selection.selectors;
const { isSelectedCompConnectedToDataBindingController } =
  stateManagement.selection.selectors;
const { measuring } = utils.hoc;
const { panelDragLimits, panelFrame } = stateManagement.panels.hoc;
const { getViewPort } = stateManagement.domMeasurements.selectors;
const positionStyleAttributes = ['position', 'top', 'bottom', 'left', 'right'];

export interface BannerProps {
  text: string;
  tooltipText: string;
  linkText?: string;
  onLinkClick?: () => void;
  shouldTranslateTooltip?: boolean;
  error?: boolean;
}

// eslint-disable-next-line react/prefer-es6-class
const CompPanelFrame = createReactClass({
  displayName: constants.PANEL_TYPES.COMP,
  propTypes: {
    panelName: PropTypes.string.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
    headerBg: PropTypes.string,
    headerTheme: PropTypes.oneOf(['standard', 'ai', 'destructive', 'premium']),
    bgColor: PropTypes.string,
    selectedComponentType: PropTypes.string.isRequired,
    shouldShowConnectionIndication: PropTypes.bool,
    shouldHideContentOverflow: PropTypes.bool,
    style: PropTypes.object,
    onCompPanelLoaded: PropTypes.func,
    setConnectionFirstTimeIndicationUserPrefString: PropTypes.func.isRequired,
    startDrag: PropTypes.func.isRequired,
    isCustomPosition: PropTypes.bool.isRequired,
    helpId: PropTypes.string,
    backButtonAutomationId: PropTypes.string,
    backButtonLabel: PropTypes.string,
    onBackClick: PropTypes.func,
    onClose: PropTypes.func,
    onDragEnd: PropTypes.func,
    noHelpBtn: PropTypes.bool,
    useNativeScroll: PropTypes.bool,
    closePanelByName: PropTypes.func,
    openHelpCenter: PropTypes.func,
    contentClass: PropTypes.string,
    isSingleInstance: PropTypes.bool,
    bannerProps: PropTypes.shape({
      text: PropTypes.string.isRequired,
      tooltipText: PropTypes.string.isRequired,
      linkText: PropTypes.string,
      onLinkClick: PropTypes.func,
      shouldTranslateTooltip: PropTypes.bool,
      error: PropTypes.bool,
    }),
    isConnectedToData: PropTypes.bool.isRequired,
    blockOuterScroll: PropTypes.func,
    close: PropTypes.func,
    scrollTo: PropTypes.number,
    freezePosition: PropTypes.bool,
    automationId: PropTypes.string,
    useContentMaxHeight: PropTypes.bool,
    reportMeasurements: PropTypes.func.isRequired,
    measurements: PropTypes.object.isRequired,
    registerDragCallbacks: PropTypes.func.isRequired,
    id: PropTypes.string,
    viewPortHeight: PropTypes.number,
    getPanelDragLimits: PropTypes.func.isRequired,
    sendBI: PropTypes.func,
    children: PropTypes.node.isRequired,
    extraBanner: PropTypes.node,
    footer: PropTypes.node,
    actionsBar: PropTypes.node,
    enableEventPropagation: PropTypes.bool,
  },
  mixins: [util.translationMixin],
  getDefaultProps() {
    return {
      noHelpBtn: false,
      onDragEnd: _.noop,
    };
  },
  getInitialState() {
    this.props.registerDragCallbacks({
      onDragEnd: this.onDragEnd,
    });

    return {
      showHelp: false,
      showConnectedBanner: this.shouldShowConnectedIndication(),
    };
  },
  componentDidMount() {
    this.measureMaxHeight();

    const compType = `wysiwyg.viewer.components.${this.props.selectedComponentType}`;
    const interactionKey = (util.fedopsLogger.INTERACTIONS.PANELS[compType] ||
      {})[this.props.panelName];
    if (interactionKey) {
      util.fedopsLogger.interactionEnded(interactionKey);
    }
  },
  componentDidUpdate(prevProps) {
    if (
      _.isEmpty(prevProps.measurements) &&
      !_.isEmpty(this.props.measurements) &&
      _.isFunction(this.props.onCompPanelLoaded)
    ) {
      this.props.onCompPanelLoaded();
    }
    this.measureMaxHeight();
    this.updateCustomScrollContentRef();
  },
  componentWillUnmount() {
    if (
      this.props.shouldShowConnectionIndication &&
      this.state.showFirstTimeBox
    ) {
      this.props.setConnectionFirstTimeIndicationUserPrefString();
    }
  },
  getRootProps() {
    const mouseEventHandler = this.props.enableEventPropagation
      ? undefined
      : this.stopEventPropagation;

    return {
      className: `comp-panel-frame ${this.props.className || ''}`,
      id: this.props.id || '',
      style: !this.props.isCustomPosition
        ? this.props.style
        : _.omit(this.props.style, positionStyleAttributes),
      onMouseDown: mouseEventHandler,
      onMouseUp: mouseEventHandler,
      onDoubleClick: mouseEventHandler,
      onContextMenu: mouseEventHandler,
      onMouseMove: mouseEventHandler,
    };
  },
  getHeaderBackButtonProps() {
    return experiment.isOpen('se_panelHeaderBackButton') &&
      this.shouldShowBackButton()
      ? {
          backAutomationId: this.props.backButtonAutomationId,
          backTooltipContent: this.props.backButtonLabel,
          onBack: this.props.onBackClick,
        }
      : {};
  },
  shouldShowBackButton() {
    return !!this.props.onBackClick;
  },
  getContentMaxHeight() {
    return this.props.measurements.allowedContentHeight;
  },
  measureMaxHeight() {
    if (!this.props.viewPortHeight) {
      return null;
    }

    const panelHeaderHeight = this.refs.header
      ? ReactDOM.findDOMNode(this.refs.header)?.getBoundingClientRect().height
      : null;

    const availableContentHeight =
      this.props.viewPortHeight - panelHeaderHeight;

    const contentMaxHeight = parseFloat(
      window.getComputedStyle(this.refs.content).maxHeight,
    );

    let allowedContentHeight;
    this.originalContentMaxHeight = !this.originalContentMaxHeight
      ? contentMaxHeight
      : this.originalContentMaxHeight;

    if (isNaN(this.originalContentMaxHeight)) {
      allowedContentHeight = availableContentHeight;
    } else {
      allowedContentHeight = Math.min(
        this.originalContentMaxHeight,
        availableContentHeight,
      );
    }

    allowedContentHeight = isNaN(allowedContentHeight)
      ? undefined
      : allowedContentHeight;

    this.props.reportMeasurements({
      allowedContentHeight,
    });
  },

  updateCustomScrollContentRef() {
    const { customScroll } = this.refs;

    if (!customScroll) return;

    const customScrollDomNode = ReactDOM.findDOMNode(customScroll);

    Object.assign(customScroll, {
      contentWrapper: customScrollDomNode.querySelector('.content-wrapper'),
      customScrollbarRef:
        customScrollDomNode.querySelector('.custom-scrollbar'),
      scrollHandle: customScrollDomNode.querySelector('.custom-scroll-handle'),
    });
  },

  renderContent() {
    const maxHeight = this.getContentMaxHeight();

    const contentWrapperStyle: React.CSSProperties = {
      maxHeight,
      '--comp-Panel-Frame-Height': maxHeight,
    };
    if (this.props.useContentMaxHeight) {
      contentWrapperStyle.height = maxHeight;
    }

    return (
      <section
        ref="content"
        style={contentWrapperStyle}
        className={cx(this.getContentClass())}
      >
        {!experiment.isOpen('se_panelHeaderBackButton') &&
        this.shouldShowBackButton() ? (
          <div key="buttonBack" className="btn-back-wrapper">
            <baseUI.button
              label={this.props.backButtonLabel}
              icon="arrowLeftSmall"
              onClick={this.props.onBackClick}
              className="btn-back"
            />
          </div>
        ) : null}
        {this.props.children}
      </section>
    );
  },

  renderContentWithScroll() {
    const content = this.renderContent();

    if (this.props.useContentMaxHeight) {
      return content;
    }
    if (this.props.useNativeScroll) {
      return React.createElement('div', {}, content);
    }
    const customScrollProps = {
      scrollTo: this.props.scrollTo,
      freezePosition: this.props.freezePosition,
      ref: 'customScroll',
    };
    return React.createElement(CustomScroll, customScrollProps, content);
  },
  handleScroll(event) {
    const scrollPercent =
      (event.currentTarget.scrollTop /
        (event.currentTarget.scrollHeight - event.currentTarget.clientHeight)) *
      100;
    if (scrollPercent > this.panelMaximumScroll) {
      this.panelMaximumScroll = Math.round(scrollPercent);
    }
  },
  openHelp() {
    const componentType = this.props.selectedComponentType;
    const biHelpParams = {
      component: componentType,
    };
    this.props.openHelpCenter(biHelpParams);
  },

  closeCompPanelFrame() {
    if (this.props.onClose) {
      this.props.onClose();
    }

    this.props.close('x');
  },

  getContentClass() {
    const classAsObject = {
      'comp-panel-content': true,
      hideOverflow: this.props.shouldHideContentOverflow,
    };
    if (this.props.contentClass) {
      classAsObject[this.props.contentClass] = true;
    }

    return classAsObject;
  },
  stopEventPropagation(e) {
    e.stopPropagation();
  },
  toggleHelp() {
    const newShowHelp = !this.state.showHelp;
    this.setState({
      showHelp: newShowHelp,
    });
  },
  getHeaderStyle() {
    const { headerBg } = this.props;
    const { bgColor } = this.props;
    return headerBg
      ? {
          backgroundImage: `url(${headerBg})`,
          backgroundColor: bgColor || '',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: '216px 160px',
        }
      : {};
  },
  onDragEnd(newPosition) {
    this.props.onDragEnd(newPosition);
  },

  shouldShowConnectedIndication(): boolean {
    return (
      this.props.shouldShowConnectionIndication && this.props.isConnectedToData
    );
  },

  shouldDisplayBanner(): boolean {
    return !!this.props.bannerProps || this.shouldShowConnectedIndication();
  },

  getBannerProps(): BannerProps {
    return (
      this.props.bannerProps || {
        text: 'PLATFORM_CONNECTED_ELEMENT_SETTINGS_LABEL',
        tooltipText: 'PLATFORM_CONNECTED_ELEMENT_SETTINGS_TOOLTIP',
      }
    );
  },

  render() {
    const bannerProps = this.shouldDisplayBanner()
      ? this.getBannerProps()
      : null;

    return (
      <div
        data-tooltip-bounds="true"
        data-component-type={this.props.componentType}
        data-component-panel=""
        data-component-panel-source={this.props.componentPanelSource}
        data-component-panel-type={this.props.componentPanelType}
        data-aid={this.props.automationId || this.props.panelName}
        {...this.getRootProps()}
      >
        <PanelHeader
          ref="header"
          style={this.getHeaderStyle()}
          onMouseDown={(e) => {
            this.props.startDrag(e, this.props.getPanelDragLimits());
          }}
          {...this.getHeaderBackButtonProps()}
          onHelp={this.openHelp}
          noHelpBtn={this.props.noHelpBtn}
          onClose={this.closeCompPanelFrame}
          className="dragger dark"
          theme={this.props.headerTheme}
          actionsBar={this.props.actionsBar}
        >
          <TooltipOnEllipsis>
            <span data-hook="panel-header-label">
              {this.translateIfNeeded(this.props.title)}
            </span>
          </TooltipOnEllipsis>
        </PanelHeader>
        {bannerProps ? (
          <Composites.Banner
            key="compPanelStickyBanner"
            automationId="comp-panel-banner"
          >
            <InfoIcon
              automationId="comp-panel-banner-info-icon"
              error={bannerProps.error}
              text={bannerProps.tooltipText}
              shouldTranslate={bannerProps.shouldTranslateTooltip}
              linkText={bannerProps.linkText}
              onLinkClick={bannerProps.onLinkClick}
            />
            <SectionDivider>
              <TextLabel automationId="banner-text" value={bannerProps.text} />
            </SectionDivider>
          </Composites.Banner>
        ) : null}
        {this.props.extraBanner || null}
        {this.renderContentWithScroll()}
        {this.props.footer || null}
      </div>
    );
  },
});

const mapStateToProps = ({ editorAPI, state, dsRead }, ownProps) => {
  ownProps = ownProps || {};
  const isInsideEditorX =
    editorAPI.host.getAPI(EditorParamsApiKey).isInsideEditorX;

  return {
    isConnectedToData: isSelectedCompConnectedToDataBindingController(
      state,
      dsRead,
    ),
    selectedComponentType:
      ownProps.selectedComponentType || getSelectedComponentType(state, dsRead),
    viewPortHeight: getViewPort(state)?.height,
    enableEventPropagation: isInsideEditorX,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setConnectionFirstTimeIndicationUserPrefString: () =>
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      connectionFirstTimeIndicationUserPrefString,
      true,
    ),
  sendBI: (event, parameters) =>
    dispatch(stateManagement.bi.actions.event(event, parameters)),
});

const WrappedCompPanelFrame = _.flow(
  util.hoc.renderWhenMutated,
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  ),
  utils.hoc.draggable,
  panelDragLimits(),
  measuring,
  panelFrame(constants.PANEL_TYPES.COMP),
)(CompPanelFrame) as AnyFixMe;
WrappedCompPanelFrame.pure = CompPanelFrame;

export default WrappedCompPanelFrame;
