import * as stateManagement from '@/stateManagement';
import { isMeshLayoutEnabled } from '@/layout';
import type { MousePosition } from './types';
import type { DragLifecycleScope as Scope } from './initDrag';
import type { CompLayout } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

const { translateToViewerCoordinates } =
  stateManagement.domMeasurements.selectors;

const FAST_DRAG_MIN_LIMIT = 15;

export type DragDataFromEvent = ReturnType<typeof getDragDataFromEvent>;

export function getDragDataFromEvent(
  { editorAPI }: { editorAPI: EditorAPI },
  event: React.MouseEvent,
) {
  const mouseCoordinates = translateToViewerCoordinates(editorAPI, event);

  const isShiftPressed = event.shiftKey;
  const isAltKeyDown = event.altKey;

  const currentMousePosition: MousePosition & { isShiftPressed: boolean } = {
    x: mouseCoordinates.pageX,
    y: mouseCoordinates.pageY,
    isShiftPressed: event.shiftKey,
  };

  return {
    isAltKeyDown,
    isShiftPressed,
    currentMousePosition,
    mouseCoordinates,
  };
}

function getMouseDiff(
  scope: Scope,
  currentMousePosition: MousePosition,
): MousePosition {
  const prevPosition = scope.state.lastMousePosition;

  return {
    x: Math.abs(prevPosition.x - currentMousePosition.x),
    y: Math.abs(prevPosition.y - currentMousePosition.y),
  };
}

function getIsFastDrag(scope: Scope, currentMousePosition: MousePosition) {
  const mouseDiff = getMouseDiff(scope, currentMousePosition);

  return mouseDiff.x > FAST_DRAG_MIN_LIMIT || mouseDiff.y > FAST_DRAG_MIN_LIMIT;
}

function startDrag(scope: Scope) {
  scope.pluginHooks.onDragStart.fire();
}

function onDrag(scope: Scope, dragData: DragDataFromEvent) {
  const { editorAPI } = scope;
  const {
    mouseCoordinates,
    currentMousePosition,
    isAltKeyDown,
    isShiftPressed,
  } = dragData;

  editorAPI.selection.setIsMouseUpSelectionEnabled(false);

  const isFastDrag = getIsFastDrag(scope, currentMousePosition);

  const fireOverrideLayoutHook = (layout: CompLayout) => {
    return scope.pluginHooks.overrideLayout.fire(layout, {
      isAltKeyDown,
      isFastDrag,
    });
  };

  const fireBeforeLayoutUpdateHook = (layout: CompLayout) => {
    scope.pluginHooks.beforeLayoutUpdate.fire({
      isAltKeyDown,
      isFastDrag,
      mouseCoordinates,
      layout,
    });
  };

  scope.pluginHooks.updateLayout.fire({
    currentMousePosition,
    fireBeforeLayoutUpdateHook,
    isAltKeyDown,
    isShiftPressed,
    mouseCoordinates,
    fireOverrideLayoutHook,
  });
  scope.state.lastMousePosition = currentMousePosition;
  scope.globalHooks.drag.fire({
    position: { x: mouseCoordinates.clientX, y: mouseCoordinates.clientY },
  });
}

async function endDrag(scope: Scope, { mouseCoordinates }: DragDataFromEvent) {
  const { editorAPI, pluginHooks, globalHooks } = scope;

  pluginHooks.beforeEndDragLayout.fire({ mouseCoordinates });
  await pluginHooks.endDragLayout.fire();

  globalHooks.beforeDragEnd.fire();
  pluginHooks.endDrag.fire({ mouseCoordinates });

  if (!isMeshLayoutEnabled()) {
    // NOTE: for the mesh history will be added after the layout update (in moveByTransition.end())
    editorAPI.history.add('Dragged component');
  }

  globalHooks.dragEnd.fire({
    position: { x: mouseCoordinates.clientX, y: mouseCoordinates.clientY },
  });
}

// export function cancelDrag(scope: Scope) {
//   //should end drag?
//   const { editorAPI, selectedComp } = scope;

//   editorAPI.mouseActions.turnOffMouseEvents();
//   for (let i = 0; i < selectedComp.length; i++) {
//     editorAPI.components.layout.update(
//       selectedComp[i],
//       initCompLayouts[i],
//       true,
//     );
//   }
// }

export const lifecycle = { endDrag, onDrag, startDrag };
