import React, { type FC, useMemo } from 'react';
import { ExtensionSlotMenuItem } from './extensionSlotMenuItem';
import {
  sortAccordingToOrder,
  HelpMenuItemsSlotPlacement,
  ToolsMenuItemSlotPlacement,
} from '@/extensionSlots';

interface ExtensionSlotMenuItemsPlacementProps {
  menuItemKey: string;
  extensionSlotPlacementComponent:
    | typeof HelpMenuItemsSlotPlacement
    | typeof ToolsMenuItemSlotPlacement;
  orderedExtensionsIds: string[];
}

export const ExtensionSlotMenuItemsPlacement: FC<
  ExtensionSlotMenuItemsPlacementProps
> = (props) => {
  const {
    orderedExtensionsIds,
    extensionSlotPlacementComponent: ExtensionSlotPlacement,
  } = props;

  /*
   * Skip filtering for now and just allow any extension to be placed here
  const filterSlotMenuItems = useMemo(
    () => filterByExtensionIds(orderedExtensionsIds),
    [orderedExtensionsIds],
  );
   */
  const sortSlotMenuItems = useMemo(
    () => sortAccordingToOrder(orderedExtensionsIds),
    [orderedExtensionsIds],
  );

  return (
    <ExtensionSlotPlacement sort={sortSlotMenuItems}>
      {(_, ownProps) => (
        <ExtensionSlotMenuItem
          item={ownProps}
          menuItemKey={props.menuItemKey}
        />
      )}
    </ExtensionSlotPlacement>
  );
};
