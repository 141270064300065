import {
  editorDataFunction,
  componentInstanceData,
} from '@wix/editor-component-data-resolver';

import type { ComponentEditorMetaDataDefinition } from '../types';

const girdVisibleAlignmentMap: Record<string, (props: AnyFixMe) => boolean> = {
  nineGrid(popupContainerProperties) {
    return popupContainerProperties.horizontalAlignment === 'center';
  },
  fullWidth() {
    return true;
  },
  fullHeight() {
    return false;
  },
};

const metaData: ComponentEditorMetaDataDefinition = {
  pinnable: false,
  gridLinesVisible: editorDataFunction(
    componentInstanceData('componentProperties'),
    (popupContainerProperties: AnyFixMe) => {
      const isGridVisibleFn =
        girdVisibleAlignmentMap[popupContainerProperties.alignmentType];

      if (isGridVisibleFn) {
        return isGridVisibleFn(popupContainerProperties);
      }
    },
  ),
  focusable: true,
};

export default metaData;
