'use strict'

module.exports = {
    'type': 'Component',
    'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuSkin',
    'layout': {
        'width': 50,
        'height': 50,
        'x': 252,
        'y': 20,
        'scale': 1,
        'rotationInDegrees': 0,
        'anchors': [{
            'distance': 30,
            'topToTop': 20,
            'originalValue': 100,
            'type': 'BOTTOM_PARENT',
            'locked': true,
            'targetComponent': 'SITE_HEADER'
        }],
        'fixedPosition': false
    },
    'componentType': 'wysiwyg.viewer.components.mobile.TinyMenu',
    'props': {
        'type': 'TinyMenuProperties',
        'metaData': {'schemaVersion': '1.0'},
        'direction': 'left'
    },
    'style': {
        'type': 'TopLevelStyle',
        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
        'style': {
            'properties': {
                'alpha-bg': '1',
                'alpha-bgs': '1',
                'alpha-txt': '1',
                'alpha-txts': '1',
                'bg': '###',
                'bgs': '###',
                'txt': '###',
                'txts': '###'
            }, 'propertiesSource': {'bg': 'value', 'bgs': 'value', 'txt': 'value', 'txts': 'value'}, 'groups': {}
        },
        'componentClassName': 'wysiwyg.viewer.components.mobile.TinyMenu',
        'pageId': '',
        'compId': 'TINY_MENU',
        'styleType': 'custom',
        'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuSkin'
    },
    'dataquery': '#MAIN_MENU'
}