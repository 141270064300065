import experiment from 'experiment';
import type { EditorState, PanelProps } from '@/stateManagement';
import { domMeasurements, leftBar, panels } from '@/stateManagement';
import type {
  DispatchMapperArgs,
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import { WorkspaceRightPanelApiKey, FixedStageApiKey } from '@/apis';
import { fixedStage, zoomMode } from '@/util';

export interface StateProps {
  panelName?: string;
  panelProps?: PanelProps;
  stageBehaviour: PanelProps['stageBehaviour'];
  maxWidth: string;
  isZoomedByUser?: boolean;
}
export interface DispatchProps {
  onShrink: (isStageBehaviourClose?: boolean) => void;
  onStretch: () => void;
  closePanel: (panelName: string) => void;
}

export interface OwnProps {}

export const mapStateToProps: MapStateToProps<StateProps, OwnProps> = ({
  state,
  editorAPI,
}) => {
  const openedPanel = panels.selectors.selectOpenLeftPanels(state)?.[0];
  const stageLeftPosition =
    domMeasurements.selectors.getStageLayout(state)?.left;
  const maxWidth = `calc(100vw - ${stageLeftPosition}px)`; // fix window resize issue

  const stageBehaviour = leftBar.selectors.getPanelStageBehaviour(
    state,
    openedPanel?.name,
    editorAPI.isPopUpMode(),
  );

  const isZoomedByUser = editorAPI.zoomMode?.isZoomedByUser();

  return {
    panelName: openedPanel?.name,
    panelProps: openedPanel?.props,
    stageBehaviour,
    maxWidth,
    isZoomedByUser,
  };
};

interface GetStateAndEditorAPI {
  editorAPI: DispatchMapperArgs['editorAPI'];
  getState: () => EditorState;
}

const getStateAndEditorAPI: ThunkAction<GetStateAndEditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => ({ editorAPI, getState });

export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
) => {
  const { editorAPI, getState }: GetStateAndEditorAPI =
    dispatch(getStateAndEditorAPI);
  const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);

  return {
    onShrink: (isStageBehaviourClose: boolean) => {
      const stageSizeExperimentIsOpen = experiment.isOpen('se_stageSize');
      const shouldZoomBeFixed = stageSizeExperimentIsOpen
        ? !editorAPI.zoomMode.isZoomedByUser()
        : false;
      const shouldEnableFixedStage = fixedStageAPI.isStageFixedInDesktop();
      const [openedPanel] = panels.selectors.selectOpenLeftPanels(getState());
      const newScale = shouldEnableFixedStage
        ? { zoomScale: editorAPI.zoomMode.getSiteScaleForPanelsOpen() }
        : {};

      if (shouldZoomBeFixed && isStageBehaviourClose) {
        editorAPI.zoomMode.exitZoomMode({
          biParams: {
            origin: openedPanel?.name,
            zoom_mode_type: zoomMode.isNewZoomLabelEnabled() ? '75%' : '50%',
          },
          disableViewerDocumentAdjustments: true,
        });
      } else {
        editorAPI.zoomMode.enterZoomMode({
          ...newScale,
          biParams: { origin: openedPanel?.name },
          originalScrollY: openedPanel?.props?.zoomModeParams?.originalScrollY,
          keepComponentsSelection: !openedPanel,
          disableViewerDocumentAdjustments: true,
        });
      }
    },
    onStretch: () => {
      const workspaceRightPanelAPI = editorAPI.host.getAPI(
        WorkspaceRightPanelApiKey,
      );

      if (!workspaceRightPanelAPI.isOpen()) {
        const [openedPanel] = panels.selectors.selectOpenLeftPanels(getState());
        const stageSizeExperimentIsOpen = experiment.isOpen('se_stageSize');
        const shouldZoomBeFixed = stageSizeExperimentIsOpen
          ? editorAPI.zoomMode.isZoomedByUser()
          : false;

        // in fixed stage, scale is >= 0.5 when stage shifting panels are opened, so we need to bring it back to 0.5 when isZoomedByUser
        if (shouldZoomBeFixed && fixedStage.isFixedStageEnabled()) {
          editorAPI.setSiteScale({
            requestedScale: 0.5,
            scrollY: editorAPI.scroll.get().scrollTop || 0,
          });
        }

        editorAPI.zoomMode.exitZoomMode({
          biParams: {
            origin: openedPanel?.name,
            zoom_mode_type: zoomMode.isNewZoomLabelEnabled() ? '75%' : '50%',
          },
          disableViewerDocumentAdjustments: true,
        });
      }
    },
    closePanel: (panelName: string) =>
      dispatch(panels.actions.closePanelByName(panelName)),
  };
};
