import { EditorAPIKey, WixDataApiKey } from '@/apis';
import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { WixDataApi } from './api';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
}

export const WixDataEntryPoint = createEntryPoint({
  name: 'WixDataEntryPoint',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(WixDataApiKey, WixDataApi);
  },
  initialize() {},
});
