import { loadSections } from '../../../../addPresetApi';
import type { SectionPresetDefinition } from '../../../../types';
import * as util from '@/util';
import _ from 'lodash';
import type { AddPresetScope } from '../../../../addPresetApiEntry';
import type { CompStructure } from 'types/documentServices';
import { ErrorReporter } from '@wix/editor-error-reporter';
import type {
  SectionAiCreationQueryOutput,
  SectionCreatorSuccessItem,
  SectionCreatorSuccessList,
} from '../aiSectionCreatorTypes';
import { AI_SECTIONS_COLLECTION_ID } from '../../../../addPresetConsts';
import { SECTIONS_COUNT_PER_REQUEST } from '../aiSectionCreatorConsts';

const chooseRandomSections = (
  sections: SectionPresetDefinition[],
): SectionPresetDefinition[] => {
  return sections.length <= SECTIONS_COUNT_PER_REQUEST
    ? sections
    : _.sampleSize(sections, SECTIONS_COUNT_PER_REQUEST);
};

const injectDefaultContentIntoSections = async (
  sections: SectionPresetDefinition[],
): Promise<SectionPresetDefinition[]> => {
  return await Promise.all(
    sections.map(async (section) => {
      const presetDefinition = await util.http.fetchJson(section.presetJsonUrl);
      return {
        ...section,
        injectedStructure: presetDefinition,
      };
    }),
  );
};

const injectAiContentIntoSection = async (
  scope: AddPresetScope,
  {
    section,
    businessName,
    businessType,
    description,
    categoryName,
  }: {
    section: SectionPresetDefinition;
    businessName?: string;
    businessType?: string;
    description: string;
    categoryName: string;
  },
): Promise<SectionCreatorSuccessItem> => {
  return new Promise((resolve, reject) => {
    // @ts-expect-error
    scope.editorAPI.ai.content.getContentByStructure(
      businessType,
      businessName,
      description,
      section.injectedStructure,
      categoryName,
      (
        result: CompStructure,
        queryOutput: SectionAiCreationQueryOutput,
      ): void => {
        const sectionWithAiContent = { ...section, injectedStructure: result };
        return resolve({
          createdSection: sectionWithAiContent,
          queryOutput,
        });
      },
      (error: Error, queryOutput: SectionAiCreationQueryOutput): void => {
        ErrorReporter.captureException(error, {
          tags: { aiContentInjectionIntoSection: true },
        });

        reject({ error, queryOutput });
      },
    );
  });
};

const groupSectionCreationResults = (
  results: SectionCreatorSuccessItem[],
): SectionCreatorSuccessList => {
  const result: SectionCreatorSuccessList = {
    createdSections: [],
    queryOutputs: [],
  };
  for (const { queryOutput, createdSection } of results) {
    result.createdSections.push(createdSection);
    result.queryOutputs.push(queryOutput);
  }

  return result;
};

const injectAiContentIntoSections = async (
  scope: AddPresetScope,
  sections: SectionPresetDefinition[],
  params: {
    categoryName: string;
    businessName?: string;
    businessType?: string;
    description: string;
  },
): Promise<SectionCreatorSuccessList> => {
  const requestOutputs = await Promise.all(
    sections.map((section) =>
      injectAiContentIntoSection(scope, { ...params, section }),
    ),
  );

  return groupSectionCreationResults(requestOutputs);
};

const injectCassContentIntoSections = async (
  scope: AddPresetScope,
  sections: SectionPresetDefinition[],
): Promise<SectionPresetDefinition[]> => {
  const { contentInjectionAPI } = scope;
  const contentManager = contentInjectionAPI.getContentManager();
  if (!contentManager) await contentInjectionAPI.initiateContentManager();

  return sections.map((section) => ({
    ...section,
    injectedStructure: contentInjectionAPI.getInjectedPreset(
      section.injectedStructure,
      section.contentInjectionMap,
      section._id,
    ),
  }));
};

export const getSectionsWithAIContent = async (
  scope: AddPresetScope,
  {
    categoryId,
    categoryName,
    language,
    businessName,
    businessType,
    description,
  }: {
    categoryId: string;
    categoryName: string;
    language: string;
    businessName: string;
    businessType: string;
    description: string;
  },
): Promise<SectionCreatorSuccessList> => {
  const sectionsInCategory = await loadSections(
    scope,
    categoryId,
    language,
    AI_SECTIONS_COLLECTION_ID,
  );
  const randomSections = chooseRandomSections(sectionsInCategory);
  const sectionsWithStructures =
    await injectDefaultContentIntoSections(randomSections);
  const sectionWithInjectedContent = await injectCassContentIntoSections(
    scope,
    sectionsWithStructures,
  );
  const sectionWithAiContent = await injectAiContentIntoSections(
    scope,
    sectionWithInjectedContent,
    {
      categoryName,
      businessName,
      businessType,
      description,
    },
  );

  return sectionWithAiContent;
};
