import React from 'react';
import { ActionItem } from '@wix/wix-base-ui';
import styles from './actionItem.scss';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import Block from './block';
import { AIDS } from '../constants';
import { HelpBlock } from '../types';
import type { Props } from '../types';

const WelcomeTour: React.FC<Props> = ({ startWelcomeTour }) => (
  <Block type={HelpBlock.WELCOME_TOUR} raw>
    <ActionItem
      title="Editor_Help_Panel_Tour_Title"
      className={styles.helpHomeActionItem}
      illustration={<Symbol name="welcomeTour" />}
      description="Editor_Help_Panel_Tour_Description"
      tooltipContent="Editor_Help_Panel_Tour_Tooltip"
      actionIcon={
        <Symbol
          name="arrowRightSmall"
          customDimensions={{ width: 9, height: 8 }}
        />
      }
      onClick={startWelcomeTour}
      dataHook={AIDS.WELCOME_TOUR}
    />
  </Block>
);

export default WelcomeTour;
