// @ts-nocheck
import React from 'react';

import * as util from '@/util';
import { translate } from '@/i18n';
import * as superAppMenuInfra from '@/superAppMenuInfra';
import constants from '@/constants';
import { constants as tpaConstants } from '@/tpa';

import editorActionsService from '../../../common/editorActionsService';
import dataProvider from '../../../common/dataProvider';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

const {
  connect,
  renderWhenMutated,
  STORES: { EDITOR_API },
} = util.hoc;

class PromotePanelView extends React.Component {
  static displayName = 'PromotePanel';

  componentDidMount() {
    this.props.init(this.props.nextSlide);
  }

  private isNewWorkspace: boolean = util.workspace.isNewWorkspaceEnabled();

  getSliderImages = () => {
    return this.isNewWorkspace
      ? undefined
      : [
          util.media.getMediaUrl(
            'wixBookings/appMenuPanel/gallery/bookingsGallery01.png',
          ),
          util.media.getMediaUrl(
            'wixBookings/appMenuPanel/gallery/bookingsGallery02.png',
          ),
          util.media.getMediaUrl(
            'wixBookings/appMenuPanel/gallery/bookingsGallery03.png',
          ),
        ];
  };

  getBannerImgSrc = () => {
    if (this.isNewWorkspace) {
      const bannerSrc = util.browserUtil.isRetina()
        ? 'wixBookings/appMenuPanel/bookingsWelcomeSection@x2.png'
        : 'wixBookings/appMenuPanel/bookingsWelcomeSection.png';

      return util.media.getMediaUrl(bannerSrc);
    }
  };

  getButtonLabel = () => {
    if (this.props.isBookingsInstalled()) {
      return 'SiteApp_WixBookings_TemplateFTUE_Step1_NextButton_Caption';
    }
    return 'SiteApp_WixBookings_PromotionalFTUE_Step1_NextButton_Caption';
  };

  getTitle = () => {
    return 'SiteApp_WixBookings_NewPromotionalFTUE_Step1_Title';
  };

  getSubTitle = () => {
    return 'SiteApp_WixBookings_NewPromotionalFTUE_Step1_SubTitle';
  };

  getOpenAppMarketLabel = () => {
    return 'SiteApp_Blog_PromotionalFTUE_Step1_SeeInAppMarket_LinkCaption';
  };

  getPros = () => {
    return [
      'SiteApp_WixBookings_NewPromotionalFTUE_Step1_Bullet1',
      'SiteApp_WixBookings_NewPromotionalFTUE_Step1_Bullet2',
      'SiteApp_WixBookings_NewPromotionalFTUE_Step1_Bullet3',
    ];
  };

  nextSlide = () => {
    this.props.nextSlide();
  };

  render() {
    return (
      <superAppMenuInfra.firstTimeTourPanels.installAppSlide
        {...this.props}
        origin="promotePanel"
        superAppPanelName={dataProvider.superAppPanelName}
        superAppButtonLabel={dataProvider.superAppButtonLabel}
        superAppPanelClassName={dataProvider.superAppPanelClassName}
        superAppSymbolName={dataProvider.superAppSymbolName}
        title={this.getTitle()}
        subTitle={this.getSubTitle()}
        pros={this.getPros()}
        nextButtonLabel={this.getButtonLabel()}
        openAppMarketLabel={this.getOpenAppMarketLabel()}
        openAppMarket={this.props.openHelp}
        loadingLabel="SiteApp_WixBookings_PromotionalFTUE_Step2_Loader_Caption"
        sliderImages={this.getSliderImages()}
        bannerImgSrc={this.getBannerImgSrc()}
        installImage="bookingsInstallBtnPromotional"
        installApp={this.props.installApp}
        nextSlide={this.nextSlide}
        isNextStepOpeningBackOffice={true}
      />
    );
  }
}

const PromotePanelMapper = ({ editorAPI }) => {
  const actionsService = editorActionsService({ editorAPI });

  function installApp(done) {
    if (!dataProvider.isWixBookingsInstalled(editorAPI)) {
      actionsService.addAppToSite(done, {
        biOrigin: tpaConstants.BI.type.ADD_APP_APP_BUTTON,
        platformOrigin: {
          type: EditorType.Classic,
          initiator: InstallInitiator.Editor,
          info: {
            type: InstallationOriginType.AddPanel,
          },
        },
      });
    } else {
      done();
    }
  }

  function isInTemplateFlow() {
    return !dataProvider.isAppProvisioned(editorAPI);
  }

  function init(nextSlideCallback) {
    if (
      dataProvider.isWixBookingsInstalled(editorAPI) &&
      !dataProvider.isInPreInstallationFunnel(editorAPI)
    ) {
      if (isInTemplateFlow()) {
        actionsService.goToContainingPage();
      } else {
        nextSlideCallback();
      }
    }
  }

  function openHelp() {
    actionsService.openHelp();
  }

  function isBookingsInstalled() {
    return dataProvider.isWixBookingsInstalled(editorAPI);
  }

  function initStoppableLoader() {
    let timeoutId;

    return {
      start: () => {
        const key = translate('SiteApp_WixBookings_Prepare_Loader');
        editorAPI.panelHelpers.openProgressBar({
          title: key,
          totalSteps: 3,
          currentStep: 1,
        });
        timeoutId = setTimeout(() => {
          editorAPI.panelHelpers.updateProgressBar(2);
          timeoutId = setTimeout(() => {
            editorAPI.panelHelpers.updateProgressBar(3);
          }, 4000);
        }, 4000);
      },
      stop: async () => {
        clearTimeout(timeoutId);
        return editorAPI.panelHelpers.closeProgressBar();
      },
    };
  }

  async function openBackofficeServicesPage() {
    const loader = initStoppableLoader();
    loader.start();
    editorAPI.panelManager.closePanelByName(
      constants.SUPER_APPS.BOOKINGS_PANEL_NAME,
    );
    await actionsService.openDashboard(
      dataProvider.BOOKINGS_BO_PAGES.offeringList,
    );
    await loader.stop();
  }

  return {
    init,
    isBookingsInstalled,
    installApp,
    openHelp,
    openBackofficeServicesPage,
  };
};

export default connect(
  EDITOR_API,
  PromotePanelMapper,
)(renderWhenMutated(PromotePanelView));
