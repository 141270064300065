import React, { Component, type ComponentType } from 'react';
import _ from 'lodash';

interface WithActiveItemBehaviorProps {
  openedDropPanel: string;
  menuItems: any[];
}

interface ComponentWithActiveItemBehaviorProps {
  getActiveItemIndex: () => number;
  getNextActiveItemKey: (dir: number) => string;
}

const withActiveItemBehavior = <P extends WithActiveItemBehaviorProps>(
  WrappedComponent: ComponentType<P & ComponentWithActiveItemBehaviorProps>,
) => {
  class WithActiveItemBehavior extends Component<WithActiveItemBehaviorProps> {
    getNextActiveItemKey = (dir: number): string => {
      const currActiveItemIndex = this.getActiveItemIndex();
      const { length } = this.props.menuItems;
      const newIndex =
        currActiveItemIndex === -1
          ? null
          : (length + currActiveItemIndex + dir) % length;

      return _.isNumber(newIndex) ? this.props.menuItems[newIndex].key : null;
    };

    getActiveItemIndex = (): number =>
      this.props.menuItems.findIndex(
        ({ key }) => key === this.props.openedDropPanel,
      );

    render() {
      const { props } = this;

      return React.createElement(
        WrappedComponent,
        Object.assign({}, props as P, {
          getActiveItemIndex: this.getActiveItemIndex,
          getNextActiveItemKey: this.getNextActiveItemKey,
        }),
      );
    }
  }

  return WithActiveItemBehavior;
};

export default withActiveItemBehavior;
