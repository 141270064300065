:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._htmlThumbnailDragBox_pf8vv_7 {
  position: fixed;
  z-index: 1550;
  pointer-events: none; }
