._app-details-container_1ta79_1 {
  height: 100%; }

._empty-state_1ta79_4 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto !important;
  max-width: 280px;
  height: 70%; }
  ._empty-state_1ta79_4 > div {
    text-align: center;
    margin: 4px; }
    ._empty-state_1ta79_4 > div * > div {
      white-space: initial !important; }

._loader-container_1ta79_18 {
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center; }
