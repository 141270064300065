import { createResizeAndPushHooks } from './resizeAndPush';
import { createHeaderFooterResizeAndPushHooks } from './headerFooterResizeAndPush';
import { createChildResizeAndPushHooks } from './childResizeAndPush';
import { enlargeLastSectionIfNeeded } from '../../enforcement/enlargeLastSection'; //TODO maybe utils

import type { Scope } from '@/sections';

const bootstrapResizeAndPushPlugins = (scope: Scope) => {
  const { baseResizeAndPush } = scope;

  const { start: handleResizeAndPushStart, end: handleResizeAndPushEnd } =
    createResizeAndPushHooks(scope);

  baseResizeAndPush.hooks.resizeAndPushStart.tap(({ compRef }) =>
    handleResizeAndPushStart(compRef),
  );
  baseResizeAndPush.hooks.resizeAndPushEnd.tap(async ({ compRef }) => {
    enlargeLastSectionIfNeeded(scope);
    await handleResizeAndPushEnd(compRef);
  });

  const {
    start: handleHeaderFooterResizeAndPushStart,
    on: handleHeaderFooterResizeAndPushOn,
    end: handleHeaderFooterResizeAndPushEnd,
  } = createHeaderFooterResizeAndPushHooks(scope);

  baseResizeAndPush.hooks.resizeAndPushStart.tap(({ compRef }) => {
    handleHeaderFooterResizeAndPushStart(compRef);
  });
  baseResizeAndPush.hooks.resizeAndPushOn.tap((data) => {
    handleHeaderFooterResizeAndPushOn(data);
  });
  baseResizeAndPush.hooks.resizeAndPushEnd.tap(async ({ compRef, event }) => {
    handleHeaderFooterResizeAndPushEnd(event, compRef);
  });

  const { end: handleChildResizeAndPushEnd } =
    createChildResizeAndPushHooks(scope);

  baseResizeAndPush.hooks.resizeAndPushEnd.tap(async ({ compRef }) => {
    handleChildResizeAndPushEnd(compRef);
  });
};

export { bootstrapResizeAndPushPlugins };
