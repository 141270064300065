import type { EditorAPI } from '@/editorAPI';
import gfppDataUtils from './gfppDataUtils';

export const openManageMenu = (
  editorAPI: EditorAPI,
  optionalPropsOverrides = {},
) =>
  gfppDataUtils.getToggleCompPanelFn('menu.panels.MenuManagePanel', {
    useLastPanelPosition: true,
    ...optionalPropsOverrides,
  })(editorAPI);

export const manageMenuGfppAutomationId = 'gfpp-button-manage-menu';
