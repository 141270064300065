._empty-private-apps-panel_4gemh_1 {
  text-align: center;
  align-items: center;
  align-self: center;
  min-width: 300px;
  margin: auto;
  height: initial !important; }
  ._empty-private-apps-panel_4gemh_1 ._empty-private-apps-panel-title_4gemh_8 {
    max-width: 230px;
    margin: 0 auto; }
  ._empty-private-apps-panel_4gemh_1 ._empty-private-apps-panel-content_4gemh_11 > div {
    margin: 12px; }
  ._empty-private-apps-panel_4gemh_1 ._empty-private-apps-panel-content_4gemh_11 ._empty-private-apps-panel-text_4gemh_13 {
    margin: 12px 0 24px 0; }
    ._empty-private-apps-panel_4gemh_1 ._empty-private-apps-panel-content_4gemh_11 ._empty-private-apps-panel-text_4gemh_13 > span {
      display: inline-block;
      max-width: 320px; }

._empty-private-apps-panel-wrapper_4gemh_19 {
  display: flex;
  height: 100%;
  margin: 24px 24px; }
