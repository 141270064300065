:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._content_j1cfj_7 {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center; }
  ._content_j1cfj_7 ._infoIcon_j1cfj_12 {
    padding-left: 12px; }

._area_j1cfj_15 {
  position: absolute;
  z-index: 4; }
