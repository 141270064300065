import { silentInstallApps } from '@/platform';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';

import type { EditorAPI } from '@/editorAPI';
import type { InstallAppsHookFn } from '@/editorCore';

export const getSilentInstallAppsFn = (
  editorAPI: EditorAPI,
): InstallAppsHookFn => {
  return (appDefIds, appsOptions, platformOrigin, options = {}) => {
    return new Promise<void>((resolve) => {
      silentInstallApps(editorAPI, appDefIds, {
        appsOptions,
        disableHistoryRevert: true,
        installOrigin: platformOrigin,
        ...options,
      })
        .then(resolve)
        .catch((e) => {
          ErrorReporter.captureException(e, {
            tags: { failedOnSilentInstallApps: true },
            extra: {
              appDefIds,
              appsOptions,
            },
          });
          resolve();
        });
    });
  };
};

export const getHeadlessInstallAppsFn = (
  editorAPI: EditorAPI,
): InstallAppsHookFn => {
  return async (appDefIds, appsInstallOptions, platformOrigin) => {
    for (const appDefId of appDefIds) {
      const options = appsInstallOptions[appDefId] || {};
      await new Promise((resolve, reject) => {
        editorAPI.host
          .getAPI(EditorPlatformHostIntegrationAPI)
          .applications.install(
            [appDefId],
            {
              appsOptions: {
                [appDefId]: {
                  ...options,
                  isSilent: true,
                  origin: platformOrigin,
                  headlessInstallation: true,
                  biData: {
                    origin: platformOrigin?.info?.type || 'Editor',
                  },
                },
              },
            },
            {
              singleAppCallback: resolve,
              finishAllCallback: resolve,
              onError: reject,
            },
          );
      });
    }
  };
};
