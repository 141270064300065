import React, { type FC } from 'react';
import { Text } from '@wix/wix-base-ui';
import styles from './ProgressRing.scss';

export interface ProgressRingProps {
  progress: number;
  label: string;
  strokeWidth?: number;
}

export const ProgressRing: FC<ProgressRingProps> = ({
  progress,
  label,
  strokeWidth,
}) => {
  const viewportSize = 42;
  const radius = viewportSize / 2 - strokeWidth;
  const circumference = radius * 2 * Math.PI;

  // 0% should be displayed as 4% for better UX
  const minVisibleProgress = 4;
  const displayedProgress = Math.max(progress, minVisibleProgress);
  const offset = circumference - (displayedProgress / 100) * circumference;

  return (
    <div
      className={styles.container}
      role="progressbar"
      aria-valuenow={progress}
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuetext={label}
    >
      <svg
        className={styles.ring}
        viewBox={`0 0 ${viewportSize} ${viewportSize}`}
      >
        <circle
          className={styles.track}
          r={radius}
          cx={viewportSize / 2}
          cy={viewportSize / 2}
          strokeWidth={strokeWidth}
        />
        <circle
          className={styles.progress}
          r={radius}
          cx={viewportSize / 2}
          cy={viewportSize / 2}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
          strokeWidth={strokeWidth}
        />
      </svg>
      <Text
        skin="standard"
        size="small"
        weight="normal"
        shouldTranslate={false}
      >
        {label}
      </Text>
    </div>
  );
};

ProgressRing.defaultProps = {
  strokeWidth: 3,
};
