import type { EntryPoint } from '@/apilib';
import { EditorAPIKey } from '@/apis';
import { createFloatingBubbleApi } from './floatingBubbleWrapper';
import { FloatingBubbleApiKey } from './publicApiKey';

export const FloatingBubbleEntrypoint: EntryPoint = {
  name: 'FloatingBubbleApi',
  declareAPIs: () => [FloatingBubbleApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(FloatingBubbleApiKey, () =>
      createFloatingBubbleApi(shell),
    );
  },
};
