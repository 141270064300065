// @ts-nocheck

const overrideCategoryGroups = {};
const overrideCategoryItemsByGroup = {};
const overrideCategoryItemsAtEnd = {};
const overrideCategoryItemsAtStart = {};

function registerOverrideGroupsForCategory(categoryId, groupsFunction) {
  if (overrideCategoryGroups[categoryId]) {
    console.error('category', categoryId, 'is already overwritten with groups');
    return;
  }
  overrideCategoryGroups[categoryId] = groupsFunction;
}

function unRegisterOverrideGroupsForCategory(categoryId) {
  if (overrideCategoryGroups[categoryId]) {
    delete overrideCategoryGroups[categoryId];
  }
}

function getRegisteredOverrideGroupsForCategory(categoryId) {
  return overrideCategoryGroups[categoryId];
}

function registerSectionToCategoryByGroup(
  categoryId,
  groupIndex,
  sectionNumber,
  sectionFunction,
) {
  overrideCategoryItemsByGroup[categoryId] =
    overrideCategoryItemsByGroup[categoryId] || {};
  overrideCategoryItemsByGroup[categoryId][groupIndex] =
    overrideCategoryItemsByGroup[categoryId][groupIndex] || {};
  overrideCategoryItemsByGroup[categoryId][groupIndex][sectionNumber] =
    sectionFunction;
}

function getRegisteredSectionsForCategory(categoryId) {
  return overrideCategoryItemsByGroup[categoryId];
}

function registerSectionAtEnd(categoryId, sectionFunction) {
  overrideCategoryItemsAtEnd[categoryId] = [
    ...(overrideCategoryItemsAtEnd[categoryId] || []),
    sectionFunction,
  ];
}

function registerSectionAtStart(categoryId, sectionFunction) {
  overrideCategoryItemsAtStart[categoryId] = [
    ...(overrideCategoryItemsAtStart[categoryId] || []),
    sectionFunction,
  ];
}

function getRegisteredSectionsAtEnd(categoryId) {
  return overrideCategoryItemsAtEnd[categoryId];
}

function getRegisteredSectionsAtStart(categoryId) {
  return overrideCategoryItemsAtStart[categoryId];
}

export default {
  override: {
    registerOverrideGroupsForCategory,
    getRegisteredOverrideGroupsForCategory,
    unRegisterOverrideGroupsForCategory,
  },
  registration: {
    registerSectionToCategoryByGroup,
    getRegisteredSectionsForCategory,
    registerSectionAtStart,
    getRegisteredSectionsAtStart,
    registerSectionAtEnd,
    getRegisteredSectionsAtEnd,
  },
};
