import coreUtilsLib from 'coreUtilsLib';
import * as platformEvents from 'platformEvents';
import {
  dsOneDockAdapter,
  isMeshLayoutEnabled,
} from '@/layoutOneDockMigration';
import { isPopupContainer } from '@/documentServices';
import * as core from '@/core';
import { arrayUtils } from '@/util';
import { componentActions } from '@/stateManagement';
import * as componentsUtils from './componentsUtils';

import type { CompRef, ComponentProperties } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

export function createComponentsPropertiesApi({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  function updateSingleCompProperties(
    component: CompRef,
    propertiesItem: Partial<ComponentProperties>,
    dontAddToUndoRedoStack: boolean,
  ) {
    const { DESKTOP, MOBILE } = editorAPI.dsRead.viewMode.VIEW_MODES;
    if (editorAPI.isMobileEditor() && component.type === DESKTOP) {
      component = editorAPI.components.get.byId(component.id, null, MOBILE);
    }
    if (!component) {
      return;
    }
    const currentProperties: ComponentProperties =
      editorAPI.dsRead.components.properties.get(component);
    const isChanged =
      !currentProperties ||
      !componentsUtils.isContainedObject(currentProperties, propertiesItem);
    if (!isChanged) {
      return;
    }
    const isForked =
      editorAPI.dsRead.components.properties.mobile.isForked(component);
    propertiesItem.type = propertiesItem?.type || currentProperties?.type;
    propertiesItem = core.utils.updatePropertiesUtil.fixProperties(
      propertiesItem,
      component,
      editorAPI.dsRead,
      editorAPI.dsActions,
      editorAPI.isMobileEditor(),
    );

    if (editorAPI.isMobileEditor() && currentProperties && !isForked) {
      editorAPI.dsActions.components.properties.mobile.fork(component);
    }
    editorAPI.dsActions.components.properties.update(component, propertiesItem);
    editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
      platformEvents.factory.componentPropsChanged({ compRef: component }),
    );

    if (!dontAddToUndoRedoStack) {
      editorAPI.history.debouncedAdd('comp properties changed');
    }
    editorAPI.store.dispatch(
      componentActions.onSelectedCompPropertiesChange(
        component,
        propertiesItem,
      ),
    );

    componentsUtils.sendBiIfRefComponent(
      editorAPI,
      component,
      componentsUtils.REF_COMPONENT_CHANGE_TYPE.PROPS,
    );
  }

  function update(
    compRefOrRefs: CompRef | CompRef[],
    propertiesItem: Partial<ComponentProperties> & { type?: string },
    optionsOrDontAddToUndoRedoStack:
      | boolean
      | { dontAddToUndoRedoStack: boolean },
  ) {
    coreUtilsLib.wSpy.log('editorUpdateProperties', [
      'updateProperties',
      ...arguments,
    ]);

    const compRef = arrayUtils.asSingleThrowIfMultiple(compRefOrRefs);

    if (isMeshLayoutEnabled() && isPopupContainer(editorAPI.dsRead, compRef)) {
      throw new Error(
        'updateProperties is not supported for popup containers in mesh layout',
      );
    }

    const options =
      typeof optionsOrDontAddToUndoRedoStack === 'boolean'
        ? { dontAddToUndoRedoStack: optionsOrDontAddToUndoRedoStack }
        : optionsOrDontAddToUndoRedoStack;
    return updateSingleCompProperties(
      compRef,
      propertiesItem,
      options?.dontAddToUndoRedoStack,
    );
  }

  function get(compRefs: CompRef | CompRef[]): Partial<ComponentProperties> {
    const compRef = arrayUtils.asSingle(compRefs);

    if (isMeshLayoutEnabled()) {
      return dsOneDockAdapter(editorAPI.dsRead).properties.get(compRef);
    }

    return editorAPI.dsRead.components.properties.get(compRef);
  }

  return {
    update,
    get,
  };
}
