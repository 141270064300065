// @ts-nocheck
import gfppDataUtils from '../../utils/gfppDataUtils';
import constants from '@/constants';

const helpIds = {
  DESKTOP: {
    VECTOR_IMAGE_SHAPE: '895364d5-46e7-4b80-b40f-d183c5423fed',
    VECTOR_IMAGE_ART: '8bb33bac-1638-4f8c-97b3-0030cdfd6278',
    VECTOR_IMAGE_UGC: '8bb33bac-1638-4f8c-97b3-0030cdfd6278',
  },
  MOBILE: {
    VECTOR_IMAGE_ART: 'a70e6d8b-e5e2-41a1-be05-9532c95f7b05',
    VECTOR_IMAGE_SHAPE: 'cb2f6c8d-d963-413a-95e2-a06003f5a1b8',
    VECTOR_IMAGE_UGC: 'cb2f6c8d-d963-413a-95e2-a06003f5a1b8',
  },
};

function getHelpIdByTypeAndMode(editorAPI, compRef) {
  const data = editorAPI.components.data.get(compRef);
  const svgInfo = editorAPI.media.vectorImage.getSvgInfoFromCache(data.svgId);
  const { svgType } = svgInfo;
  const isMobile = editorAPI.isMobileEditor();

  if (svgType === 'shape') {
    return isMobile
      ? helpIds.MOBILE.VECTOR_IMAGE_SHAPE
      : helpIds.DESKTOP.VECTOR_IMAGE_SHAPE;
  } else if (svgType === 'color') {
    return isMobile
      ? helpIds.MOBILE.VECTOR_IMAGE_ART
      : helpIds.DESKTOP.VECTOR_IMAGE_ART;
  }
  return isMobile
    ? helpIds.MOBILE.VECTOR_IMAGE_UGC
    : helpIds.DESKTOP.VECTOR_IMAGE_UGC;
}

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  translateAction: ACTIONS.SETTINGS,
  mainActions: [
    {
      label(editorAPI, compRef) {
        const data = editorAPI.components.data.get(compRef);
        const svgInfo = editorAPI.media.vectorImage.getSvgInfoFromCache(
          data.svgId,
        );
        return svgInfo && svgInfo.svgType === 'shape'
          ? 'gfpp_mainaction_vectorImageShape'
          : 'gfpp_mainaction_vectorImageArt';
      },
      isApplied: gfppDataUtils.isSkinDefined,
      onClick(editorAPI, compRef) {
        editorAPI.mediaServices.changeVectorImage(compRef);
      },
    },
  ],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.LINK,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId: getHelpIdByTypeAndMode,
      mobileHelpId: getHelpIdByTypeAndMode,
    },
  },
};
