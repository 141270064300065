export const AUTOMATION_IDS = {
  SEARCH_INPUT: {
    CONTAINER: 'es-search-input',
    INPUT: 'es-search-input-input',
    CLEAR_BUTTON: 'es-search-input-clear-button',
  },
  SEARCH_RESULT_ITEM: {
    CTA: 'editor-search-cta',
    CONDITIONAL_TOOLTIP_CTA: 'editor-search-conditional-tooltip-cta',
  },
  SEARCH_RESULT_CATEGORY: {
    LINK: 'editor-search-category-link',
    SHOW_MORE: 'es-show-more-link',
  },
  FOOTER: {
    APP_MARKET_LINK: 'open-app-market-home-link',
    HELP_CENTER_LINK: 'open-help-center-home-link',
  },
};
