import sectionizerPanel from '../sectionizer/panels/sectionizerPanel/sectionizerPanel';
import sectionizerErrorPanel from '../sectionizer/panels/sectionizerPanel/sectionizerErrorPanel';
import sectionizerSectionNamePanel from '../sectionizer/panels/sectionizerPanel/sectionNamePanel';
import sectionizerValidationPromt from '../sectionizer/panels/sectionizerValidationPromt/sectionizerValidationPromt';
import saveSectionizedTemplatePromt from '../sectionizer/panels/saveSectionizedTemplatePrompt/saveSectionizedTemplatePromt';

export { SectionizerEntryPoint } from './scope';
export { SectionizerApiKey } from './publicApiKey';
export type { SectionizerPublicApi } from './api';

export const panels = {
  sectionizerPanel,
  sectionizerErrorPanel,
  sectionizerSectionNamePanel,
  sectionizerValidationPromt,
  saveSectionizedTemplatePromt,
};
