import type { EditorAPI } from '@/editorAPI';
import { getAppState } from './appState';

const getActions = (editorAPI: EditorAPI) => getAppState(editorAPI).actions;

const getActionsBySchema = (editorAPI: EditorAPI) =>
  getAppState(editorAPI).actionsBySchema;

export const getSchemaActions = ({
  editorAPI,
  schemaId,
}: {
  editorAPI: EditorAPI;
  schemaId: string;
}) => {
  const actions = getActions(editorAPI);
  const actionsBySchema = getActionsBySchema(editorAPI);
  const schemaActions = actionsBySchema[schemaId] || [];

  return schemaActions.map((schemaAction) => actions[schemaAction]);
};
