import _ from 'lodash';
import type { RecommendedToolDealerData } from '../dealer/dealer.types';

export interface RecommendedTool extends RecommendedToolDealerData {
  executeAction: () => void;
}

export class RecommendedTools {
  private readonly tools: Record<string, RecommendedTool[]>;

  constructor(
    data: RecommendedToolDealerData[],
    openBusinessManager: (appDefinitionId: string, path: string) => void,
  ) {
    this.tools = _.mapValues(_.groupBy(data, 'appDefId'), (appTools) =>
      appTools.map((appTool) => ({
        ...appTool,
        executeAction: () =>
          openBusinessManager(appTool.appDefId, appTool.dashboardLink),
      })),
    );
  }

  getByApp(appDefId: string) {
    return this.tools[appDefId] || [];
  }

  getAll() {
    return Object.values(this.tools).flat();
  }

  isEmpty() {
    return this.getAll().length === 0;
  }
}
