import PropTypes from 'prop-types';
import { translate } from '@/i18n';

import React from 'react';
import GeneralContent from './generalContent';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  closePanel: FunctionFixMe;
  className?: string;
  actionCallback?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'manageExposure';

  static propTypes = {
    closePanel: PropTypes.func.isRequired,
    className: PropTypes.string,
    actionCallback: PropTypes.func,
  };

  getClassName = () => {
    return `manage-exposure${
      this.props.className ? ` ${this.props.className}` : ''
    }`;
  };

  getGeneralContentProps = () => {
    return {
      symbolName: 'manageExposure',
      mainTitle: translate('Release_Candidate_Screen_Whats_Next_Section_Title'),
      title: translate('Release_Candidate_Screen_Whats_Next_Section_Heading'),
      actionLabel: translate(
        'Release_Candidate_Screen_Whats_Next_Section_Link',
      ),
      shouldTranslate: false,
      actionCallback: this.action,
      subtitle: translate('Release_Candidate_Screen_Whats_Next_Section_Text'),
    };
  };

  action = () => {
    if (this.props.actionCallback) {
      this.props.actionCallback();
    }
    this.props.closePanel();
  };

  render() {
    return (
      <GeneralContent
        className={this.getClassName()}
        {...this.getGeneralContentProps()}
      />
    );
  }
}
