import { fedopsLogger } from '@/util';

import { createMenuApi } from '../../API/menuAPI';
import { FALLBACK_PAGES_MENU_NAME, PAGES_MENU_ID } from '../../constants';

import type { MenuItem } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

const {
  INTERACTIONS: { CUSTOM_MENUS_REVERT },
} = fedopsLogger;

export const revertCustomMenus = async (editorAPI: EditorAPI) => {
  fedopsLogger.interactionStarted(CUSTOM_MENUS_REVERT.E2E_REVERT_FUNCTION);

  const menuAPI = createMenuApi(editorAPI);
  const fallbackMenu = editorAPI.dsRead.menu
    .getAll()
    .find((menu) => menu.name === FALLBACK_PAGES_MENU_NAME);

  if (!fallbackMenu) {
    return;
  }

  fedopsLogger.interactionStarted(CUSTOM_MENUS_REVERT.CONNECT_MENU_COMPONENTS);
  try {
    const desktopMenuComponents = menuAPI.getMenuComponents();
    const mobileMenuComponents = menuAPI.getMobileMenuComponents();

    [...desktopMenuComponents, ...mobileMenuComponents].forEach(
      async (menuComponent) => {
        menuAPI.connect(PAGES_MENU_ID, menuComponent);
        await new Promise<void>((resolve) =>
          editorAPI.waitForChangesApplied(resolve),
        );
      },
    );
  } catch (e) {
    return;
  }
  fedopsLogger.interactionEnded(CUSTOM_MENUS_REVERT.CONNECT_MENU_COMPONENTS);

  fedopsLogger.interactionStarted(CUSTOM_MENUS_REVERT.REVERT_PAGES_MENU);

  const fallbackMenuItems = fallbackMenu.items;

  try {
    menuAPI.updateMenu(PAGES_MENU_ID, {
      items: fallbackMenuItems as MenuItem[],
    });
    await new Promise<void>((resolve) =>
      editorAPI.waitForChangesApplied(resolve),
    );
  } catch (e) {
    return;
  }
  fedopsLogger.interactionEnded(CUSTOM_MENUS_REVERT.REVERT_PAGES_MENU);

  fedopsLogger.interactionStarted(CUSTOM_MENUS_REVERT.REMOVE_CUSTOM_MENUS);
  try {
    const customMenus = menuAPI.getCustomMenus();
    customMenus.forEach((menu) => menuAPI.removeMenu(menu.id));

    // menuAPI.removeMenu(fallbackMenu.id);

    await new Promise<void>((resolve) =>
      editorAPI.waitForChangesApplied(resolve),
    );
  } catch (e) {
    return;
  }
  fedopsLogger.interactionEnded(CUSTOM_MENUS_REVERT.REMOVE_CUSTOM_MENUS);

  (window as any).___custom_menus_open___ = false;

  fedopsLogger.interactionEnded(CUSTOM_MENUS_REVERT.E2E_REVERT_FUNCTION);
};
