import { sections as sectionsUtils } from '@/util';
import { addBlankSectionToEmptyPageIfNeeded } from '../../enforcement/addBlankSection';

import type { CutPlugin } from 'types/editorPlugins';
import type { Scope } from '@/sections';
import { isMeshLayoutEnabled } from '@/layout';

const { shiftComponents, getComponentsBelowPosition } = sectionsUtils;

export const getSectionCutPlugin =
  (scope: Scope): CutPlugin =>
  async (compRef) => {
    const { editorAPI, components } = scope;
    const sectionLayout = components.layout.get_rect(compRef);

    const componentsToShift = getComponentsBelowPosition(
      editorAPI,
      sectionLayout.y + sectionLayout.height,
    );

    editorAPI.dsActions.components.remove(compRef);

    // NOTE: mesh layout anchors will shift next sections automatically
    if (!isMeshLayoutEnabled()) {
      shiftComponents(editorAPI, componentsToShift, -sectionLayout.height);
    }

    addBlankSectionToEmptyPageIfNeeded(scope);
  };
