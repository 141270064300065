import React, { Component, type ComponentType, type ReactElement } from 'react';
import _ from 'lodash';
import constants from '@/constants';
import TestSitePanel from '../../dropPanel/testSitePanel/testSitePanel';

const { BUTTONS } = constants.ROOT_COMPS.TOPBAR;

interface WithTestSiteDropPanelProps {}

interface ComponentWithTestSiteDropPanelProps {
  dropPanelContent?: ReactElement | null;
  dropPanelKey?: string;
}

const withTestSiteDropPanel = <P extends WithTestSiteDropPanelProps>(
  WrappedComponent: ComponentType<P & ComponentWithTestSiteDropPanelProps>,
) => {
  class WithPublishDropPanel extends Component<WithTestSiteDropPanelProps> {
    render() {
      const { props } = this;

      return React.createElement(
        WrappedComponent,
        _.defaults({}, props as P, {
          dropPanelKey: BUTTONS.TEST_SITE,
          dropPanelContent: <TestSitePanel />,
        }),
      );
    }
  }

  return WithPublishDropPanel;
};

export default withTestSiteDropPanel;
