/* eslint-disable @typescript-eslint/no-unused-vars */
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef, Point } from 'types/documentServices';

const { isInInteractionMode } = stateManagement.interactions.selectors;

enum Action {
  Detach = 1,
  Update = 2,
  Reattach = 3,
}
class OrderTracker {
  private prevAction: Action = Action.Reattach;
  private errorReported = false;

  reportError(action: Action) {
    if (!this.errorReported) {
      ErrorReporter.captureMessage('Incorrect ds calls order while drag', {
        extra: {
          prevAction: this.prevAction,
          action,
        },
      });
      if (process.env.NODE_ENV === 'developement') {
        throw new Error('Incorrect ds calls order while drag');
      }
      this.errorReported = true;
    }
  }
  isValidFlow(action: Action, prevAction: Action) {
    if (action === Action.Detach) {
      return prevAction === Action.Reattach;
    }
    if (action === Action.Update) {
      return prevAction === Action.Detach || prevAction === Action.Update;
    }
    if (action === Action.Reattach) {
      return prevAction === Action.Detach || prevAction === Action.Update;
    }
  }
  reportAction(action: Action) {
    if (!this.isValidFlow(action, this.prevAction)) {
      this.reportError(action);
    }
    this.prevAction = action;
  }
}
const orderTracker = new OrderTracker();

const PARENT_TYPES_TO_SKIP_OPTIMISTIC_DRAG = new Set([
  'wysiwyg.viewer.components.Repeater',
  'platform.components.AppWidget',
  //  // "wysiwyg.viewer.components.FormContainer",
]);

export function getCanOptimisticDrag(
  editorAPI: EditorAPI,
  selectedComps: CompRef[],
) {
  const editorState = editorAPI.store.getState();
  const isInteractionMode = isInInteractionMode(editorState);
  if (util.appStudioUtils.isAppStudio() || isInteractionMode) {
    return false;
  }

  if (selectedComps.length !== 1) {
    return false;
  }
  if (editorAPI.selection.getSelectedComponents().length !== 1) {
    // sometimes getCompToBeDragged is collapsing selected components into one parent
    return false; // and layout is got by getSnugLayout([...]). Rear case.
  }
  const compRef = selectedComps[0];
  const hasParentsToSkip = editorAPI.components.someAncestor(
    compRef,
    (ancestorRef) =>
      PARENT_TYPES_TO_SKIP_OPTIMISTIC_DRAG.has(
        editorAPI.components.getType(ancestorRef),
      ),
  );

  return !hasParentsToSkip;
}

export class OptimisticDragHandler {
  _canOptimisticDrag: boolean;
  lastScroll: {
    scrollLeft: number;
    scrollTop: number;
  };
  lastLayoutSet: Partial<Point> | null = null;
  isDetached = false;
  constructor(
    private editorAPI: EditorAPI,
    private selectedComps: CompRef[],
  ) {
    this._canOptimisticDrag = getCanOptimisticDrag(
      this.editorAPI,
      this.selectedComps,
    );
  }
  updateLayout(
    compRef: CompRef,
    layoutUpdated: Point,
    relativeToScreenDiff: Point,
  ) {
    if (!layoutUpdated) {
      // eslint-disable-next-line no-console
      console.error(
        'OptimisticDragHandlerThunderbolt.updateLayout: layout is not deined',
      );
      return;
    }

    const editorAPI = this.editorAPI;

    if (!this.isDetached) {
      orderTracker.reportAction(Action.Detach);

      editorAPI.components.layout.runtime.detachLayout(compRef);

      editorAPI.components.layout.setLayoutReadFromDOM(true);

      this.isDetached = true;
    }

    orderTracker.reportAction(Action.Update);

    const scroll = editorAPI.scroll.get();
    const layoutUpdatedAfterConstrains =
      editorAPI.components.layout.getConstrainedLayout(compRef, layoutUpdated);
    const top = layoutUpdatedAfterConstrains.y + relativeToScreenDiff.y;
    const left = layoutUpdatedAfterConstrains.x + relativeToScreenDiff.x;
    const absoluteTop = top - scroll.scrollTop;
    const absoluteLeft = left - scroll.scrollLeft;

    editorAPI.components.layout.runtime.updateDetachedLayout(compRef, {
      absoluteTop,
      absoluteLeft,
      top,
      left,
    });

    this.lastScroll = scroll;
    this.lastLayoutSet = layoutUpdatedAfterConstrains;

    editorAPI.notifyViewerUpdate(['optimisticDrag']);
  }
  canOptimisticDrag(_layout: Point) {
    return this._canOptimisticDrag;
  }

  finish() {
    if (!this._canOptimisticDrag) {
      return;
    }
    if (this.lastLayoutSet) {
      orderTracker.reportAction(Action.Reattach);

      const scroll = this.editorAPI.scroll.get();
      const deltaY = scroll.scrollTop - this.lastScroll.scrollTop; //TODO refactor, pass layout to finish
      const deltaX = scroll.scrollLeft - this.lastScroll.scrollLeft;

      const compRef = this.selectedComps[0];
      const compLayoutUpdated = {
        ...this.lastLayoutSet,
        x: this.lastLayoutSet.x + deltaX,
        y: this.lastLayoutSet.y + deltaY,
      };
      const compLayoutUpdatedAfterConstrains =
        this.editorAPI.components.layout.getConstrainedLayout(
          compRef,
          compLayoutUpdated,
        );

      this.editorAPI.dsActions.components.layout.runtime.reattachLayoutAndUpdate(
        compRef,
        compLayoutUpdatedAfterConstrains,
        () => {
          this.editorAPI.components.layout.setLayoutReadFromDOM(false);
        },
      );
      this.isDetached = false;
      this.lastLayoutSet = null;
    }
    return;
  }
}

// (() => {
//   async function move(ref, x) {
//     editorAPI.dsActions.components.layout.runtime.detachLayout(ref);
//     // console.log('detached');
//     editorAPI.components.layout.update(
//       ref,
//       {
//         width: 64,
//         height: 68,
//         x,
//         y: 49,
//         scale: 1,
//         rotationInDegrees: 0,
//         fixedPosition: false,
//         bounding: { x, y: 49, width: 64, height: 68 },
//       },
//       true,
//     );

//     return new Promise((resolve) => {
//       editorAPI.waitForChangesApplied(() => {
//         editorAPI.dsActions.components.layout.runtime.reattachLayout(ref);
//         // console.log('reaettached');
//         resolve();
//       });
//     });
//   }

//   async function test() {
//     let x = 110;
//     let ref = editorAPI.selection.getSelectedComponents()[0];
//     for (let i = 0; i < 10; i+=1) {
//       // console.log('drag n=' + i);
//       await move(ref, x);
//       x += 3;
//     }
//   }
//   test().catch(console.error);
// })();
