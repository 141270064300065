// @ts-nocheck
import _ from 'lodash';
import sectionsDefinitionData from './sectionsDefinitionData';
import experiment from 'experiment';

const EXCEPTION_MESSAGE =
  'Returned sectionsDefinition must be in format {categoryName: [ { sections: ["section1"] }, { sections: ["section2"] } ]}';

function areSectionsValid(sections) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/every
  return _.every(sections, _.isString);
}

function validateSections(sections) {
  if (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    !_.isArray(sections) ||
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    !_.every(sections, (sectionGroup) =>
      areSectionsValid(sectionGroup.sections),
    )
  ) {
    throw new Error(EXCEPTION_MESSAGE);
  }
}

export default {
  get(editorAPI) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/to-pairs
    const sectionsPairs = _.toPairs(sectionsDefinitionData);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    return _.reduce(
      sectionsPairs,
      function (resultSectionsMap, pair) {
        const categoryName = pair[0];
        const sectionsDefinition = pair[1];
        const { defaults } = sectionsDefinition;
        const { modifier } = sectionsDefinition;
        const experimentName = sectionsDefinition.experiment;
        let sections;

        if (modifier) {
          sections = modifier(editorAPI, defaults);
        } else if (experimentName) {
          sections = experiment.isOpen(experimentName) ? defaults : [];
        } else {
          sections = defaults;
        }

        validateSections(sections);
        resultSectionsMap[categoryName] = sections;

        return resultSectionsMap;
      },
      {},
    );
  },
};
