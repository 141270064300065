import {
  isDropdown,
  isMegaMenuContainer,
  isMembersLoginMenuId,
  isMembersMenuId,
} from '../utils/utils';
import type { MenuData, MenuItem } from '@wix/document-services-types';
import { DropMode, menuItemTree, type IItem } from '@/baseUI';
import { link } from '@/util';
import type { SendBiFunction } from 'types/bi';

const { linkTypeValidators } = link;

/**
 * @deprecated - remove after se_updatedCustomMenus merge
 * */
enum BiAddCategories {
  AddLink = 'add_link',
  AddDropdown = 'add_dropdown',
  AddPage = 'add_page',
}

enum NewBiAddCategories {
  Main = 'MAIN_BUTTON',
  SitePages = 'site_pages',
  DynamicPages = 'dynamic_pages',
  Link = 'Link',
  Anchor = 'anchor',
  Section = 'section',
  Submenu = 'submenu',
  Container = 'container',
  MembersPages = 'members_pages',
}

enum BiContextMenuActionCategories {
  Rename = 'rename',
  Settings = 'settings',
  Remove = 'remove',
  Duplicate = 'duplicate',
  Link = 'link',
  Unlink = 'unlink',
  ChangeLink = 'changeLink',
  MoveToDropdown = 'move-to-dropdown',
  MoveOutOfDropdown = 'move-out-of-dropdown',
  MoveToDropdownSubSub = 'move-to-sub-sub-menu',
  MoveOutOfDropdownSubSub = 'move-out-of-sub-sub-menu',
  EditContainer = 'edit-container',
}

enum BiSources {
  DefaultMenu = 'default_menu',
  CustomMenu = 'custom_menu',
  MemberMenu = 'member_menu',
  LoginBarMenu = 'login_bar_menu',
}

enum BiRenameSources {
  DoubleClick = 'double_click',
  QuickActions = 'quick_actions_menu',
}

enum BiTargets {
  Page = 'page',
  MenuSet = 'menu_set',
  Link = 'link',
  Dropdown = 'dropdown',
  Section = 'section',
  MegaMenu = 'mega_menu',
}

enum BiDragCategories {
  SubItemDrag = 'subitem_drag',
  SubItemMove = 'subitem_moved',
  MoveToFolder = 'move_to_folder',
  MoveFromFolder = 'move_from_folder',
  SubSubItemDrag = 'sub-subitem_drag',
  SubSubItemMove = 'sub-subitem_moved',
  SubSubItemMoveToFolder = 'sub-subItem_move_to_folder',
  SubSubItemMoveFromFolder = 'sub-subItem_move_from_folder',
  ItemDrag = 'item_drag',
}

const getBiSource = ({
  defaultMenuSetId,
  menuSetId,
}: Pick<UpdateBiContextOptions, 'defaultMenuSetId' | 'menuSetId'>) => {
  if (isMembersLoginMenuId(menuSetId)) {
    return BiSources.LoginBarMenu;
  }

  if (isMembersMenuId(menuSetId)) {
    return BiSources.MemberMenu;
  }

  if (defaultMenuSetId === menuSetId) {
    return BiSources.DefaultMenu;
  }

  return BiSources.CustomMenu;
};

const getBiTarget = (item: MenuItem | MenuData) => {
  if (item.type === 'CustomMenu') {
    return BiTargets.MenuSet;
  }

  if (isMegaMenuContainer(item)) {
    return BiTargets.MegaMenu;
  }

  if (isDropdown(item)) {
    return BiTargets.Dropdown;
  }

  if (
    linkTypeValidators.isPageLink(item.link) ||
    linkTypeValidators.isDynamicPageLink(item.link)
  ) {
    return BiTargets.Page;
  }

  if (linkTypeValidators.isSectionLink(item.link)) {
    return BiTargets.Section;
  }

  return BiTargets.Link;
};

export interface UpdateBiContextOptions {
  menuSetId: string;
  selectedComponentId: string;
  defaultMenuSetId: string;
  focusedPageId: string;
  compType: string;
}

export interface MenuBiContext {
  source: BiSources;
  menuSetId: string;
  componentId: string;
  defaultMenuSetId: string;
  pageId: string;
  compType: string;
}

class MenuBiLogger {
  private context: MenuBiContext;
  private sendBi: SendBiFunction;
  private isContextMenuOpen = false;
  private isContextMenuRenameInProgress = false;
  private pendingEvents: Record<string, object> = {};

  init(sendBi: SendBiFunction) {
    this.sendBi = sendBi;
  }

  private logBiEvent(id: number, params: object = {}) {
    if (!this.context) {
      this.pendingEvents[id] = params;
      return;
    }

    this.sendBi({ evid: id }, { ...this.getContextParams(), ...params });
  }

  updateContext(options: UpdateBiContextOptions) {
    this.context = {
      menuSetId: options.menuSetId,
      defaultMenuSetId: options.defaultMenuSetId,
      componentId: options.selectedComponentId,
      source: getBiSource(options),
      pageId: options.focusedPageId,
      compType: options.compType,
    };

    this.isContextMenuOpen = false;
    this.isContextMenuRenameInProgress = false;

    Object.keys(this.pendingEvents).forEach((evid) => {
      this.logBiEvent(Number(evid), this.pendingEvents[evid]);
    });
    this.pendingEvents = {};
  }

  private getContextParams() {
    const { menuSetId, source, componentId, compType } = this.context;
    return {
      menuSetId,
      source,
      component_id: componentId,
      component_type: compType,
    };
  }

  private logAddMenuItemClick(
    category: BiAddCategories | NewBiAddCategories,
    itemId?: string,
  ) {
    const params: {
      category: BiAddCategories | NewBiAddCategories;
      item_id?: string;
    } = { category };
    if (itemId) {
      params.item_id = itemId;
    }
    this.logBiEvent(1047, params);
  }

  /**
   * @deprecated - remove after se_updatedCustomMenus merge
   * */
  logAddPageClick = () => this.logAddMenuItemClick(BiAddCategories.AddPage);
  /**
   * @deprecated - remove after se_updatedCustomMenus merge
   * */
  logAddLinkClick = () => this.logAddMenuItemClick(BiAddCategories.AddLink);
  /**
   * @deprecated - remove after se_updatedCustomMenus merge
   * */
  logAddDropdownClick = () =>
    this.logAddMenuItemClick(BiAddCategories.AddDropdown);

  logAddMainButtonClick = () =>
    this.logAddMenuItemClick(NewBiAddCategories.Main);
  logAddSitePageButtonClick = () =>
    this.logAddMenuItemClick(NewBiAddCategories.SitePages);
  logAddDynamicPageButtonClick = () =>
    this.logAddMenuItemClick(NewBiAddCategories.DynamicPages);
  logAddLinkButtonClick = () =>
    this.logAddMenuItemClick(NewBiAddCategories.Link);
  logAddAnchorButtonClick = () =>
    this.logAddMenuItemClick(NewBiAddCategories.Anchor);
  logAddSectionButtonClick = () =>
    this.logAddMenuItemClick(NewBiAddCategories.Section);

  logAddSubmenuButtonClick = () =>
    this.logAddMenuItemClick(NewBiAddCategories.Submenu);
  logAddContainerButtonClick = (itemId?: string) =>
    this.logAddMenuItemClick(NewBiAddCategories.Container, itemId);
  logAddMembersButtonClick = () =>
    this.logAddMenuItemClick(NewBiAddCategories.MembersPages);

  logMenuItemContextMenuOpened = (item: MenuItem) => {
    this.logBiEvent(975, {
      target: getBiTarget(item),
      page_id: this.context.pageId,
      item_id: item.id,
    });

    this.isContextMenuOpen = true;
  };

  logMenuSetContextMenuOpened = (item: MenuData) => {
    const { pageId, defaultMenuSetId } = this.context;

    this.logBiEvent(975, {
      target: getBiTarget(item),
      page_id: pageId,
      menuSetId: item.id,
      source: getBiSource({
        defaultMenuSetId,
        menuSetId: item.id,
      }),
    });

    this.isContextMenuOpen = true;
  };

  private createLogContextMenuAction =
    (category: BiContextMenuActionCategories) =>
    (item: MenuItem | MenuData) => {
      const { pageId } = this.context;

      if (this.isContextMenuOpen || isMegaMenuContainer(item)) {
        this.logBiEvent(974, {
          target: getBiTarget(item),
          page_id: pageId,
          item_id: item.id,
          category,
        });

        if (category === BiContextMenuActionCategories.Rename) {
          this.isContextMenuRenameInProgress = true;
        }
      }

      this.isContextMenuOpen = false;
    };

  logContextMenuRenameClick = this.createLogContextMenuAction(
    BiContextMenuActionCategories.Rename,
  );

  logContextMenuSettingsClick = this.createLogContextMenuAction(
    BiContextMenuActionCategories.Settings,
  );
  logContextMenuRemoveClick = this.createLogContextMenuAction(
    BiContextMenuActionCategories.Remove,
  );
  logContextMenuDuplicateClick = this.createLogContextMenuAction(
    BiContextMenuActionCategories.Duplicate,
  );
  logContextMenuLinkClick = this.createLogContextMenuAction(
    BiContextMenuActionCategories.Link,
  );
  logContextMenuUnlinkClick = this.createLogContextMenuAction(
    BiContextMenuActionCategories.Unlink,
  );
  logContextMenuChangeClick = this.createLogContextMenuAction(
    BiContextMenuActionCategories.ChangeLink,
  );
  logContextMenuMoveToDropdownClick = this.createLogContextMenuAction(
    BiContextMenuActionCategories.MoveToDropdown,
  );
  logContextMenuMoveOutOfDropdownClick = this.createLogContextMenuAction(
    BiContextMenuActionCategories.MoveOutOfDropdown,
  );
  logContextMenuMoveToDropdownSubSubClick = this.createLogContextMenuAction(
    BiContextMenuActionCategories.MoveToDropdownSubSub,
  );
  logContextMenuMoveOutOfDropdownSubSubClick = this.createLogContextMenuAction(
    BiContextMenuActionCategories.MoveOutOfDropdownSubSub,
  );
  logEditContainerClick = this.createLogContextMenuAction(
    BiContextMenuActionCategories.EditContainer,
  );
  logInfoPopupShown(message: string) {
    this.logBiEvent(1048, {
      message,
    });
  }

  logChangeMenuSetClicked() {
    this.logBiEvent(1044);
  }

  logCreateMenuSetClicked(menuSetId: string) {
    this.logBiEvent(973, {
      menuSetId,
      source: BiSources.CustomMenu,
    });
  }

  logMenuSetSelected(menuSetId: string) {
    this.logBiEvent(1045, {
      menuSetId,
      source: getBiSource({
        defaultMenuSetId: this.context.defaultMenuSetId,
        menuSetId,
      }),
    });
  }

  logMenuSetDeleted(menuSetId: string, deletedActiveMenuSetCount: number) {
    this.logBiEvent(1046, {
      menuSetId,
      source: getBiSource({
        defaultMenuSetId: this.context.defaultMenuSetId,
        menuSetId,
      }),
      deletedActiveMenuSetCount,
    });
  }

  logMenuItemRename = (
    item: MenuItem | MenuData,
    oldName: string,
    newName: string,
  ) => {
    const { pageId } = this.context;

    this.logBiEvent(976, {
      old_name: oldName,
      new_name: newName,
      page_id: pageId,
      source_type: this.isContextMenuRenameInProgress
        ? BiRenameSources.QuickActions
        : BiRenameSources.DoubleClick,
      target: getBiTarget(item),
      item_id: item.id,
    });

    this.isContextMenuRenameInProgress = false;
  };

  logMenuItemDrop = (
    dropMode: DropMode,
    items: MenuItem[],
    item: MenuItem,
    target?: MenuItem,
  ) => {
    const prevParent = menuItemTree.findParent(items as IItem[], item.id);

    const newParent =
      dropMode === DropMode.on
        ? target
        : menuItemTree.findParent(items as IItem[], target?.id);

    const isSubSubCase =
      dropMode === DropMode.on
        ? isSubSubItem(items, item, newParent as MenuItem)
        : isOutOfSubSubCase(items, item);

    const getSubItemCategory = (): BiDragCategories => {
      if (newParent) {
        if (prevParent) {
          return prevParent.id === newParent.id
            ? BiDragCategories.SubItemDrag
            : BiDragCategories.SubItemMove;
        }

        return BiDragCategories.MoveToFolder;
      }

      if (prevParent) {
        return BiDragCategories.MoveFromFolder;
      }

      return BiDragCategories.ItemDrag;
    };

    const getSubSubItemCategory = (): BiDragCategories => {
      if (newParent) {
        if (prevParent) {
          return prevParent.id === newParent.id
            ? BiDragCategories.SubSubItemDrag
            : BiDragCategories.SubSubItemMove;
        }

        return BiDragCategories.SubSubItemMoveToFolder;
      }

      if (prevParent) {
        return BiDragCategories.SubSubItemMoveFromFolder;
      }

      return BiDragCategories.SubSubItemDrag;
    };

    const getParentItemName = (parent?: MenuItem): BiTargets | void => {
      if (!parent) {
        return;
      }

      if (isDropdown(newParent as MenuItem)) {
        return BiTargets.Dropdown;
      }

      if (linkTypeValidators.isPageLink(parent.link)) {
        return BiTargets.Page;
      }

      if (parent.link) {
        return BiTargets.Link;
      }
    };

    this.logBiEvent(1043, {
      category: isSubSubCase ? getSubSubItemCategory() : getSubItemCategory(),
      parent_item_name: getParentItemName(newParent as MenuItem),
      page_id: this.context.pageId,
    });
  };

  logMultiselectApply = (itemsCount: number) => {
    this.logBiEvent(972, {
      pages_count: itemsCount,
    });
  };

  logMultiselectSelectAll = (show: boolean) => {
    this.logBiEvent(970, {
      target: show ? 'show' : 'hide',
    });
  };

  logMultiselectSelectItem = (
    id: string,
    value: boolean,
    sectionId: string,
  ) => {
    this.logBiEvent(971, {
      target: value ? 'show' : 'hide',
      page_id: id,
      page_type: sectionId,
    });
  };
}

export const isSubSubItem = (
  items: MenuItem[],
  item: MenuItem,
  parent: MenuItem | null,
): boolean => {
  if (parent) {
    const parentOfParent = menuItemTree.findParent(items as IItem[], parent.id);
    const doesItemHaveChildren = item.items.length > 0;
    return doesItemHaveChildren || !!parentOfParent;
  }
  return false;
};

export const isOutOfSubSubCase = (
  items: MenuItem[],
  item: MenuItem,
): boolean => {
  const prevParent = menuItemTree.findParent(items as IItem[], item.id);
  return isSubSubItem(items, item, prevParent as MenuItem);
};

export const menuBiLogger = new MenuBiLogger();

export const ADD_NEW_PAGE_BI_CATEGORY = 'menu_manager';
export const ADD_NEW_PAGE_BI_ORIGIN = 'advanced_menu';
