import type { CompRef } from '@wix/document-services-types';
import type { EditorAPI } from '@/editorAPI';

const GALLERIES = 'galleries';
const FULL_WIDTH_LINE = 'fiveGridLineFullWidth';
const VECTOR_IMAGE_ART = 'VectorImageArt';
const VECTOR_IMAGE_SHAPE = 'VectorImageShape';
const VECTOR_IMAGE_UGC = 'VectorImageUGC';

const vectorImageFunc = (compRef: CompRef, editorAPI: EditorAPI) => {
  const { svgId } = editorAPI.components.data.get(compRef);
  const { svgType } = editorAPI.media.vectorImage.getSvgInfoFromCache(svgId);

  let compType;

  if (svgType === 'shape') {
    compType = VECTOR_IMAGE_SHAPE;
  } else if (svgType === 'color') {
    compType = VECTOR_IMAGE_ART;
  } else {
    compType = VECTOR_IMAGE_UGC;
  }

  return compType;
};

function fiveGridLineFunc(compRef: CompRef, editorAPI: EditorAPI) {
  const compProps = editorAPI.components.properties.get(compRef);
  if (compProps?.fullScreenModeOn) {
    return FULL_WIDTH_LINE;
  }
  return 'wysiwyg.viewer.components.FiveGridLine';
}

const SPECIAL_COMPONENTS_MAP: Record<
  string,
  string | ((compRef: CompRef, editorAPI: EditorAPI) => string)
> = {
  'wysiwyg.viewer.components.MatrixGallery': GALLERIES,
  'wysiwyg.viewer.components.PaginatedGridGallery': GALLERIES,
  'wysiwyg.viewer.components.SliderGallery': GALLERIES,
  'wysiwyg.viewer.components.SlideShowGallery': GALLERIES,
  'tpa.viewer.components.Masonry': GALLERIES,
  'tpa.viewer.components.Accordion': GALLERIES,
  'tpa.viewer.components.Impress': GALLERIES,
  'tpa.viewer.components.Freestyle': GALLERIES,
  'tpa.viewer.components.Collage': GALLERIES,
  'tpa.viewer.components.Honeycomb': GALLERIES,
  'tpa.viewer.components.StripShowcase': GALLERIES,
  'tpa.viewer.components.StripSlideshow': GALLERIES,
  'tpa.viewer.components.Thumbnails': GALLERIES,
  'wysiwyg.viewer.components.tpapps.TPA3DGallery': GALLERIES,
  'wysiwyg.viewer.components.tpapps.TPA3DCarousel': GALLERIES,
  'wixapps.integration.components.AppPage': 'mobile.core.components.Page',
  'wysiwyg.viewer.components.PopupContainer':
    'mobile.core.components.Container',
  'wysiwyg.viewer.components.FiveGridLine': fiveGridLineFunc,
  'wysiwyg.viewer.components.VectorImage': vectorImageFunc,
};

export const castDesignCompType = (
  compType: string,
  compRef: CompRef,
  editorAPI: EditorAPI,
): string => {
  const specialCompType = SPECIAL_COMPONENTS_MAP[compType];
  // @ts-expect-error
  if (window.DESIGN_PANEL_COMP_TYPE_OVERRIDE) {
    // @ts-expect-error // for addpanel e2e tests
    return window.DESIGN_PANEL_COMP_TYPE_OVERRIDE;
  }
  if (specialCompType) {
    if (typeof specialCompType === 'function') {
      return specialCompType(compRef, editorAPI);
    }
    return specialCompType;
  }

  return compType;
};
