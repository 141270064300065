import _ from 'lodash';
import * as i18n from '@/i18n';

const { translate } = i18n;

export default {
  MAIN_MENU: {
    id: 'CUSTOM_MAIN_MENU',
    title: translate('NewPages_Panel_Left_SiteMenu_Label'),
    flatMenuTitle: translate('NewPages_QuickNav_Site_Pages_Label'),
    helpId: '8278a5f6-119c-412e-8b01-4bd0955cb307',
    customMenusHelpId: 'eeb02018-3a66-4495-8dad-4fae32c2c1af',
    mobileHelpId: '1aeed4d9-19a1-44b7-beec-7bc38a9d4d99',
    group: 'menus',
  },
  MEMBERS_MENU: {
    id: 'MEMBERS_SUB_MENU',
    title: translate('NewPages_Panel_Left_MembersMenu_Label'),
    helpId: 'ab4d4af2-0a5a-4ad9-93fe-dbf42f22d2ca',
    mobileHelpId: '8fa70d57-3d09-4f0b-a966-7ef2475e3f1b',
    group: 'menus',
  },
  //things that are common to all menus or fallbacks
  MENUS: {
    id: 'MENUS',
    group: 'menus',
    helpId: '8278a5f6-119c-412e-8b01-4bd0955cb307',
    biCategory: 'custom menu',
    getBiCategory: (id: string): string => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      if (_.includes(['CUSTOM_MAIN_MENU', 'MEMBERS_SUB_MENU'], id)) {
        return id;
      }
      return 'CUSTOM_MENU';
    },
    titleAdd: translate('NewPages_Panel_AddPage_Button'),
    titleAddMenuItem: translate('NewPages_Panel_AddMenuItem_Button'),
    titleNewPage: translate('NewPages_Panel_AddMenuItem_MenuItems_Page_Label'),
    titleDropdownPlaceholder: translate(
      'NewPages_Panel_Menu_Folder_Placeholder',
    ),
  },
  SPECIAL_PAGES: {
    group: 'special_pages',
    getBiCategory: (appDefId: AnyFixMe, membersAppDefId: AnyFixMe) => {
      if (appDefId === membersAppDefId) {
        return 'members';
      }
    },
  },
  APPLICATION_PAGES: {
    group: 'application_pages',
  },
  MEMBERS_PAGES: {
    helpId: '7710f007-cbdb-4b77-bb8b-fd4d65424ab1',
  },
  TPA_PAGES: {
    id: 'TPA_PAGES',
    group: 'tpa_pages',
    titleAdd: translate('NewPages_Panel_AddPage_Button'),
    getBiCategory: (id: AnyFixMe) => `tpa_${id}`,
  },
  DYNAMIC_PAGES_INTRO: {
    id: 'DYNAMIC_PAGES_INTRO',
    helpId: '2b10e89b-5943-46d3-9037-5176b9f1b74c',
    title: 'NewPages_Panel_Left_DynamicPage_Label',
    group: 'dynamic_pages',
    biCategory: 'dynamic_page_intro',
  },
  DYNAMIC_PAGES: {
    id: 'DYNAMIC_PAGES',
    helpId: '1e76f0cc-531a-45b3-bfe0-8907cf391fca',
    mobileHelpId: '4eda8656-f16f-4ddd-9c80-4b80eef09686',
    group: 'dynamic_pages',
    biCategory: 'router',
  },
  ROUTER_PAGES: {
    id: 'ROUTER_PAGES',
    helpId: 'aa6cebfb-a948-4767-bb0e-a60cb8fa9146',
    mobileHelpId: '4b873e86-b218-4589-b84b-8070c775f3c4',
    group: 'dynamic_pages',
    biCategory: 'router',
  },
  SIGNUP: {
    id: 'SIGNUP',
    title: 'NewPages_Panel_Signup_Title',
    biCategory: 'signup',
    helpId: 'e8c26342-9c6b-4f7d-ae71-8e105009724b',
  },
  POPUPS: {
    id: 'POPUPS',
    title: 'POPUPS_SiteStructure_Panel_Popups',
    helpId: '24659a21-11c8-41e1-9752-5822677154a0',
    mobileHelpId: '05438eeb-262a-49e0-a59b-dfda242c5609',
    group: 'popups',
    biCategory: 'lightbox',
    titleAdd: translate('POPUPS_SiteStructure_Panel_Button_Add'),
  },
  TRANSITIONS: {
    id: 'TRANSITIONS',
    title: 'NewPages_Panel_Transitions_Title',
    group: 'transitions',
    biCategory: 'transitions',
    helpId: '000c9232-9321-4738-94c8-885f8633b35b',
  },
};
