import React from 'react';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import { PromotionalList, Text } from '@wix/wix-base-ui';
import * as panels from '@/panels';
import { translate } from '@/i18n';

class PlatformPromotionalPanel extends React.Component<PlatformPromotionalPanelProps> {
  getKeyboardShortcuts = () => ({
    esc: () => this.props.closePanel(ErrorPanelResolveType.CLOSE_ACTION),
  });

  secClick = () => {
    if (this.props.secondaryAction) {
      this.props.secondaryAction();
    } else {
      this.props.openHelpCenter();
    }
  };

  mainClick = () => {
    if (this.props.primaryAction) {
      this.props.primaryAction();
    }
    this.props.closePanel(ErrorPanelResolveType.MAIN_ACTION);
  };

  getSecondaryButtonText = () => {
    if (this.props.secondaryActionText) {
      return this.props.shouldTranslate
        ? translate(this.props.secondaryActionText)
        : this.props.secondaryActionText;
    } else if (this.props.helpId) {
      return this.props.shouldTranslate
        ? translate(this.props.KBLinkText)
        : this.props.KBLinkText;
    }
  };

  getIllustration = () =>
    this.props.shouldShowIllustration || this.props.illustration ? (
      <img
        src={this.props.illustration}
        data-aid="illustration"
        className="promotional-panel-illustration"
      />
    ) : undefined;

  render() {
    const { shouldTranslate, mainActionText, subtitleText, titleText } =
      this.props;
    return (
      <panels.frames.PromotionalPanelFrame
        dataHook="platform-promotional-panel"
        panelName={this.props.panelName}
        onPrimaryButtonClick={this.mainClick}
        primaryButtonText={
          shouldTranslate ? translate(mainActionText) : mainActionText
        }
        title={shouldTranslate ? translate(titleText) : titleText}
        subtitle={shouldTranslate ? translate(subtitleText) : subtitleText}
        illustration={this.getIllustration()}
        footnote={
          this.props.footnote && (
            <Text
              skin="secondary"
              size="small"
              enableEllipsis={false}
              shouldTranslate={false}
              dataHook="footnote"
            >
              {this.props.footnote}
              &nbsp;
              {this.props.footnoteLink && (
                <a
                  href={this.props.footnoteLink}
                  target="_blank"
                  data-id="footnote-link"
                >
                  {this.props.footnoteLinkText}
                </a>
              )}
            </Text>
          )
        }
        onHelpButtonClick={this.props.helpId && this.props.openHelpCenter}
        onSecondaryButtonClick={this.secClick}
        secondaryButtonText={this.getSecondaryButtonText()}
        onCloseButtonClick={() =>
          this.props.closePanel(ErrorPanelResolveType.CLOSE_ACTION)
        }
        theme="standard"
      >
        <PromotionalList
          items={this.props.contentArray}
          shouldTranslate={false}
          automationId="content-array"
        />
      </panels.frames.PromotionalPanelFrame>
    );
  }
}

const mapDispatchToProps = (dispatch: AnyFixMe, ownProps: AnyFixMe) => {
  const closePanel = (origin: AnyFixMe) => {
    dispatch(
      stateManagement.panels.actions.closePanelByNameAction(ownProps.panelName),
    );
    ownProps.onPanelClose(origin);
  };

  const openHelpCenter = () =>
    dispatch(stateManagement.panels.actions.openHelpCenter(ownProps.helpId));

  return {
    closePanel,
    openHelpCenter,
  };
};

enum ErrorPanelResolveType {
  MAIN_ACTION = 'mainActionClicked',
  SECONDARY_ACTION = 'secActionClicked',
  CLOSE_ACTION = 'closeActionClicked',
}

interface PlatformPromotionalPanelProps {
  closePanel(data: ErrorPanelResolveType): void;
  helpId?: string;
  openHelpCenter(): void;
  titleText: string;
  subtitleText?: string;
  illustration?: string;
  footnote?: string;
  footnoteLink?: string;
  footnoteLinkText?: string;
  contentArray: string[];
  KBLinkText?: string;
  mainActionText: string;
  onPanelClose(): void;
  panelName: string;
  shouldShowIllustration?: boolean;
  secondaryActionText?: string;
  secondaryAction?(): void;
  primaryAction?(): void;
  shouldTranslate?: boolean;
}

export default util.hoc.connect(
  util.hoc.STORES.DS_ONLY,
  null,
  mapDispatchToProps,
)(PlatformPromotionalPanel);
