import React from 'react';
import { ExtensionSlotMenuAPIProvider } from './extensionSlotMenuAPIProvider';
import type { MenuItemEntry } from './extensionSlotMenuItemTypes';
import { functionUtils } from '@/util';

interface ExtensionSlotMenuItemsState {
  extensionSlotMenuItems: {
    [menuItemKey: string]: MenuItemEntry[];
  };
}

export const withExtensionSlotMenuItems = <
  T extends { items: MenuItemEntry[] },
>(
  Component: React.ComponentType<T>,
) => {
  return class WithExtensionSlotMenuItems extends React.Component<
    T,
    ExtensionSlotMenuItemsState
  > {
    state: ExtensionSlotMenuItemsState = {
      extensionSlotMenuItems: {},
    };

    registerExtensionSlotMenuItem = (
      item: MenuItemEntry,
      menuItemKey: string,
    ) => {
      this.setState((state) => ({
        ...state,
        extensionSlotMenuItems: {
          ...state.extensionSlotMenuItems,
          [menuItemKey]: [
            ...(state.extensionSlotMenuItems[menuItemKey] || []),
            item,
          ],
        },
      }));
    };

    unregisterExtensionSlotMenuItem = (
      item: MenuItemEntry,
      menuItemKey: string,
    ) => {
      this.setState((state) => ({
        ...state,
        extensionSlotMenuItems: {
          ...state.extensionSlotMenuItems,
          [menuItemKey]: (
            state.extensionSlotMenuItems[menuItemKey] || []
          ).filter((menuItem) => menuItem !== item),
        },
      }));
    };

    getMemoizedItems = functionUtils.memoizeOnce(
      (
        items: MenuItemEntry[],
        extensionSlotItems: ExtensionSlotMenuItemsState['extensionSlotMenuItems'],
      ): MenuItemEntry[] => {
        return items.flatMap((item) => {
          const extensionItems = extensionSlotItems[item.key] || [];

          return [item, ...extensionItems];
        });
      },
    );

    getItems() {
      return this.getMemoizedItems(
        this.props.items,
        this.state.extensionSlotMenuItems,
      );
    }

    render() {
      return (
        <ExtensionSlotMenuAPIProvider
          registerExtensionSlotItem={this.registerExtensionSlotMenuItem}
          unregisterExtensionSlotItem={this.unregisterExtensionSlotMenuItem}
        >
          <Component {...this.props} items={this.getItems()} />
        </ExtensionSlotMenuAPIProvider>
      );
    }
  };
};
