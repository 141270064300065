import constants from './constants';
import experiment from 'experiment';
import { overridable } from '@wix/santa-editor-utils';
import * as util from '@/util';
import type { PlatformOrigin } from '@/platform';
import type { CompStructure } from 'types/documentServices';
import * as tokenService from '../services/tokenService';
import type { EditorAPI } from '@/editorAPI';
import { isPlatformOriginAPP } from '../bi/bi';
import { InstallationOriginType, EditorType } from '@wix/platform-editor-sdk';
import type { AppData, CompRef } from '@wix/document-services-types';
import { translate } from '@/i18n';
import { utils as coreUtils } from '@/core';

function addSDKVersionToUrl(url: string): string {
  const sdkAbsoluteURL = `${window.serviceTopology.scriptsLocationMap['js-platform-editor-sdk']}/lib/editorSDK.min.js`;

  let sdkNumericVersion;

  if (sdkAbsoluteURL.startsWith(util.serviceTopology.scriptsDomainUrl)) {
    /*
      application could already baked semver-ish editorSDK version into the panel's url
      while not being able to handle a url-ish one (e.g. `&sdkVersion=https://localhost:3000/lib/editorSDK`)

      https://wix.slack.com/archives/CQGJP31CM/p1617619878102500

      because of that, we try to use numeric `editorSDK` version when possible,
      so panel will work properly for users and fail for developers when they'll use url-ish `&sdkVersion`

      when it will fail for developer, application needs to be tweaked to use `@wix/platform-editor-sdk/lib/loader`
      (instead of hard-coded `&sdkVersion` handling). contact #editor-platform-dev in case you need help with that
    */

    sdkNumericVersion = sdkAbsoluteURL.match(
      /\/js-platform-editor-sdk\/(?<version>\d+\.\d+\.\d+)\/lib\//i,
    )?.groups.version;
  }

  return util.url.setUrlParam(
    url,
    'sdkVersion',
    sdkNumericVersion || sdkAbsoluteURL,
  );
}

function addOverridesToUrl(url: string): string {
  const searchParams = new URLSearchParams(window.location.search);
  const artifactOverrides: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    if (key.endsWith('-override')) {
      artifactOverrides[key] = value;
    }
  });

  return util.url.setUrlParams(url, artifactOverrides);
}

function addCommonQueryParams(url: string): string {
  let urlWithCommonParams = addOverridesToUrl(url);
  urlWithCommonParams = addSDKVersionToUrl(urlWithCommonParams);
  return urlWithCommonParams;
}

function addQueryParamsToUrl(
  url: string,
  applicationId: number,
  appDefinitionId: string,
): string {
  const queryParams = {
    applicationId,
    appDefinitionId,
    editorType: EditorType.Classic,
  };

  for (const [name, value] of Object.entries(queryParams)) {
    url = util.url.setUrlParam(url, name, value);
  }

  const extraNamespaces = getExtraNamespaces();
  if (extraNamespaces.length) {
    url = util.url.setUrlParam(
      url,
      'extraNamespaces',
      extraNamespaces.join(','),
    );
  }

  url = addCommonQueryParams(url);

  return url;
}

function getExtraNamespaces(): string[] {
  return util.appStudioUtils.isAppStudio()
    ? constants.PLATFORM_EXTRA_NAMESPACES.APP_BUILDER
    : [];
}

const getFullApplicationUrl = overridable(
  (
    appData: AnyFixMe,
    providedUrl: AnyFixMe,
    appDefinitionId: AnyFixMe,
    applicationId: AnyFixMe,
  ): string | undefined => {
    if (providedUrl.startsWith('http')) {
      return addQueryParamsToUrl(providedUrl, applicationId, appDefinitionId);
    }

    const urlParams: {
      editorPlatformAppSources?: string;
    } = util.url.parseUrlParams(window.location.search.substr(1));

    // &editorPlatformAppSources=iframeVersion:1.1847.0,wix-code-editor-app:1.11.0
    const appSource = urlParams.editorPlatformAppSources
      ?.split(',')
      .find((part) => part.startsWith(`${appDefinitionId}:`))
      ?.split(':')[1];

    const baseUrl = getBaseUrl(appSource);

    function getBaseUrl(version: AnyFixMe) {
      const editorScriptUrl = appData?.appFields?.platform?.editorScriptUrl;
      if (editorScriptUrl) {
        return editorScriptUrl;
      }
      const name = appData?.editorArtifact;
      if (name) {
        return version
          ? util.url.joinURL(
              util.serviceTopology.scriptsDomainUrl,
              'services',
              name,
              version,
            )
          : util.serviceTopology.scriptsLocationMap[name];
      }
    }

    // TODO: replace this with a url library or add a URL polyfill
    if (baseUrl) {
      const url = new URL(providedUrl, baseUrl).href;
      return addQueryParamsToUrl(url, applicationId, appDefinitionId);
    }
  },
);

const runInContext = <T extends (...args: []) => any>(
  appDefId: string,
  editorAPI: EditorAPI,
  func: T,
): ReturnType<T> => editorAPI.dsActions.runInContext(appDefId, func);

const RESTRICTED_ORIGIN_TYPES_OPEN_APP_PANEL_ON_INSTALL = new Set([
  InstallationOriginType.ADD_PAGE_PANEL,
]);
function shouldDisableOpenAppPanelOnInstall(
  editorAPI: EditorAPI,
  platformOrigin: PlatformOrigin,
): boolean {
  if (isPlatformOriginAPP(platformOrigin)) {
    return false;
  }
  return (
    editorAPI.platform.applications.isSilentInstallRunning() ||
    RESTRICTED_ORIGIN_TYPES_OPEN_APP_PANEL_ON_INSTALL.has(
      platformOrigin?.info?.type,
    )
  );
}

function collectPageWidgetIdsByAppIdRecursively(
  compStructure: CompStructure,
  appDefId: string,
  widgetIds: string[],
): void {
  if (
    compStructure.data?.appDefinitionId === appDefId ||
    compStructure.data?.applicationId === appDefId
  ) {
    widgetIds.push(
      compStructure?.data.controllerType || compStructure?.data.widgetId,
    );
  }
  (compStructure.components as CompStructure[])?.forEach((childStructure) => {
    collectPageWidgetIdsByAppIdRecursively(childStructure, appDefId, widgetIds);
  });
}

function getStructureWidgetIdsByAppId(
  pageStructure: CompStructure,
  appDefId: string,
): string[] {
  const widgetIds: AnyFixMe = [];
  collectPageWidgetIdsByAppIdRecursively(pageStructure, appDefId, widgetIds);
  return widgetIds;
}

function getPanelProps(
  editorAPI: EditorAPI,
  appData: AppData,
  options: AnyFixMe,
  url: AnyFixMe,
) {
  const fullAppData = editorAPI.dsRead.platform.getAppDataByApplicationId(
    appData.applicationId,
  );
  const props = Object.assign(
    { token: tokenService.generatePanelToken(fullAppData.appDefinitionId) },
    options,
  );
  if (options.url) {
    Object.assign(props, {
      url:
        url ||
        getFullApplicationUrl(
          fullAppData,
          options.url,
          fullAppData.appDefinitionId,
          fullAppData.applicationId,
        ),
    });
  }

  if (experiment.isOpen('specs.responsive-editor.platformPanelOneTimeToken')) {
    props.appDefinitionId = fullAppData.appDefinitionId;
  }

  if (experiment.isOpen('se_multilingualHideOnboardingPanel')) {
    Object.assign(props, {
      appDefinitionId: fullAppData.appDefinitionId,
      instanceId: fullAppData.instanceId,
    });
  }
  return props;
}

const getSectionDeleteMessageProps = function (
  appName: string,
  defaultTitle: string,
  customContent: AnyFixMe,
) {
  return {
    headerText:
      customContent.title ?? translate(defaultTitle, { app_name: appName }),
    shouldShowIllustration: true,
    symbol: 'deleteSectionIcon_NewWorkspace',
    descriptionText: customContent.descriptionText,
    mainActionText: translate('tpa_messages_ok'),
  };
};

const displaySectionDeleteMessage = async function (
  editorAPI: EditorAPI,
  compRef: CompRef,
  appData: AppData,
) {
  const appApi = await editorAPI.platform.getAppEditorApi(
    appData.appDefinitionId,
  );
  const customContent = await appApi?.getCustomCannotDeletePanelContent({
    compId: compRef.id,
  });
  const defaultTitle = 'tpa_messages_oops_title';
  if (customContent && Object.keys(customContent).length > 0) {
    const panelProps = getSectionDeleteMessageProps(
      appData.appDefinitionName,
      defaultTitle,
      customContent,
    );
    editorAPI.panelManager.openPlatformPanel({
      panelName: 'platformPanels.platformConfirmationPanel',
      panelProps,
    });
  } else {
    editorAPI.panelManager.openPanel(
      'tpaPanels.message.cannotDeleteSection',
      {
        title: defaultTitle,
        appName: appData.appDefinitionName,
        appDefId: appData.appDefinitionId,
      },
      true,
    );
  }
};

const dataUpdateHook = function (editorAPI: EditorAPI, compRef: CompRef): void {
  const compType = editorAPI.components.getType(compRef);
  if (
    experiment.isOpen('se_siteCompletionSourceData') &&
    compType === 'wysiwyg.viewer.components.WRichText'
  ) {
    coreUtils.componentSourceFeatureUtils.updateComponentSource(editorAPI, {
      compRef,
      source: 'application',
    });
  }
};

export {
  displaySectionDeleteMessage,
  getExtraNamespaces,
  getFullApplicationUrl,
  runInContext,
  addCommonQueryParams,
  getStructureWidgetIdsByAppId,
  shouldDisableOpenAppPanelOnInstall,
  getPanelProps,
  dataUpdateHook,
};
