import React from 'react';
import { NotificationBanner } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import type { AddPageCategory } from '@/addPreset';

export interface AddPageNotificationBanner {
  isPremiumSite: boolean;
  category: AddPageCategory;
  upgrade: (category: AddPageCategory) => void;
  openHelpCenter: (helpId: string) => void;
  isPreview?: boolean;
  isReplacePage?: boolean;
}

const HELP_ID = '785d01a3-5414-468f-8d6c-fef15033c78e';
const INFO_REPLACE_BUTTON = 'add_page_preset_404_replace_current_banner_link';
const INFO_REPLACE_MESSAGE = 'add_page_preset_404_replace_current_banner_text';
const PREVIEW_INFO_UPGRADE_MESSAGE =
  'add_page_preset_404_preview_upgrade_banner_text_1';
const PREVIEW_INFO_UPGRADE_BUTTON =
  'add_page_preset_404_preview_upgrade_banner_link';
const MODAL_INFO_UPGRADE_MESSAGE = 'add_page_preset_404_upgrade_banner_text';
const MODAL_INFO_UPGRADE_BUTTON = 'add_page_preset_404_upgrade_banner_link';

function getLabel(
  shouldDisplayUpgrade: boolean,
  isReplacePage: boolean,
  isPreview: boolean,
): string {
  if (isReplacePage) {
    return 'add_page_preset_404_replace_current_banner_link';
  }
  const INFO_UPGRADE_BUTTON = isPreview
    ? PREVIEW_INFO_UPGRADE_BUTTON
    : MODAL_INFO_UPGRADE_BUTTON;
  return shouldDisplayUpgrade ? INFO_UPGRADE_BUTTON : INFO_REPLACE_BUTTON;
}

function getText(
  shouldDisplayUpgrade: boolean,
  isReplacePage: boolean,
  isPreview: boolean,
): string {
  if (isReplacePage) {
    return 'add_page_preset_404_preview_replace_current_banner_text';
  }
  const INFO_UPGRADE_MESSAGE = isPreview
    ? PREVIEW_INFO_UPGRADE_MESSAGE
    : MODAL_INFO_UPGRADE_MESSAGE;
  return shouldDisplayUpgrade ? INFO_UPGRADE_MESSAGE : INFO_REPLACE_MESSAGE;
}

export const AddPageNotificationBanner = ({
  isPremiumSite,
  category,
  upgrade,
  openHelpCenter,
  isPreview = false,
  isReplacePage = false,
}: AddPageNotificationBanner) => {
  const isPremiumCategory = category.isPremium;
  const shouldDisplayUpgrade = !isPremiumSite && isPremiumCategory;
  const skin = shouldDisplayUpgrade ? 'premium' : 'standard';

  const text = getText(shouldDisplayUpgrade, isReplacePage, isPreview);
  const buttonLabel = getLabel(shouldDisplayUpgrade, isReplacePage, isPreview);

  const handleClick = () =>
    shouldDisplayUpgrade ? upgrade(category) : openHelpCenter(HELP_ID);

  return (
    <div className="add-page-notification-banner">
      <NotificationBanner
        skin={skin}
        text={translate(text)}
        link={{
          label: translate(buttonLabel),
          onClick: handleClick,
        }}
        shouldTranslate={false}
      />
    </div>
  );
};
