:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._buttonDesignEntryPoint_1juf4_7 {
  position: relative;
  margin: 18px 24px; }
  ._buttonDesignEntryPoint_1juf4_7 ._link_1juf4_10 {
    cursor: pointer;
    color: #116dff; }
    ._buttonDesignEntryPoint_1juf4_7 ._link_1juf4_10:hover {
      text-decoration: underline; }
