// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import React from 'react';
import { translate } from '@/i18n';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'tpaCartPageInfo',
  mixins: [core.mixins.editorAPIMixin],
  render() {
    const api = this.getEditorAPI();

    return (
      <div className="page-info tab-inner">
        <section>
          <p className="title">
            {translate('Pages_Store_Cart_Info_WhatisTitle')}
          </p>

          <p>{translate('Pages_Store_Cart_Info_WhatisText')}</p>
        </section>

        <section>
          <p className="title">{translate('Pages_Store_Cart_Info_HowTitle')}</p>

          {api.isDesktopEditor() ? (
            <p key="desktop">{translate('Pages_Store_Cart_Info_HowText')} </p>
          ) : null}
          {api.isMobileEditor() ? (
            <p key="mobile">
              {translate('Pages_Store_Cart_Info_HowText_Mobile')}{' '}
            </p>
          ) : null}
        </section>
      </div>
    );
  },
});
