import experiment from 'experiment';
import { HttpClient } from '@wix/http-client';
import { getAscendStatus } from '@wix/ambassador-promote-ascend-utils-service-v1-utils/http';

import * as stateManagement from '@/stateManagement';
import { createEntryPoint } from '@/apilib';

import { register as ascendRegister } from '@/ascend';
import * as promoteSuperAppButton from './button/promoteSuperAppButton';
import promoteMenuPanel from './appMenu/panels/menuPanel/menuPanel';
import { PromoteApiKey } from './publicApiKey';
import { createPromoteApi } from './promotePublicApi';
import { fetchPromoteData } from './store/initActions';
import { PromoteScope } from './scope';

const PromoteEntryPoint = createEntryPoint({
  name: 'PromoteApi',
  Scope: PromoteScope,
  publicApi({ contributeApi }) {
    contributeApi(PromoteApiKey, createPromoteApi);
  },
  initialize: async (scope: PromoteScope) => {
    const hideAscend = await fetchIsKillingAscend();

    if (typeof scope.editorAPI.store?.dispatch !== 'function') {
      if (process.env.NODE_ENV === 'test') {
        // ignore in tests for now, to make tests logs more readable
        return;
      }

      // TODO: looks like some circular dependency issue with `scope.editorAPI`
      throw new Error('scope.editorAPI.store.dispatch is not a function');
    }

    scope.editorAPI.store.dispatch(
      stateManagement.ascend.actions.updateKillingAscend(hideAscend),
    );
    const showNewMarketingPanel =
      experiment.isOpen('specs.so.EnableAscendKilledFlowInEditor') &&
      hideAscend;
    scope.editorCoreAPI.hooks.initReady.promise.then(() => {
      if (showNewMarketingPanel) {
        const prefetchUserPackage = !experiment.isOpen(
          'specs.so.FetchUserPackageAfterOpen',
        );
        if (prefetchUserPackage) {
          fetchPromoteData(scope);
        }
        promoteSuperAppButton.register(scope.editorAPI);
      } else {
        ascendRegister(scope.editorAPI);
      }
    });
  },
});

async function fetchIsKillingAscend() {
  const httpClient = new HttpClient();
  const { data } = await httpClient
    .request(getAscendStatus({}))
    .catch(() => ({ data: { killingAscend: false } }));

  return data.killingAscend || false;
}

export type { PromoteApi } from './promotePublicApi';

export { PromoteEntryPoint, promoteMenuPanel as panel };
