'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_columns_about",
    "subNavigationTitle": "add_section_label_columns_about",
    "presetTitle": "add_section_label_columns_about",
    "tooltipTitle": "add_section_label_columns_about",
    "automationId": "add-panel-section-columnsClassicSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/stripContainerClassicSection_en/stripContainerClassicSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "Columns_ColumnsClassic_1",
            "structure": {
                "type": "Container",
                "layout": {"width": 980, "height": 400, "x": 0, "y": 200, "scale": 1, "rotationInDegrees": 0},
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "components": [{
                    "type": "Container",
                    "layout": {
                        "width": 980,
                        "height": 400,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "components": [],
                    "props": {"type": "ColumnProperties", "alignment": 50},
                    "style": "mc1",
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#242323",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 400,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {"type": "ColumnProperties", "alignment": 50},
                        "style": "mc1",
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#242323",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "fullWidth": true,
                    "rowMargin": 0,
                    "siteMargin": 0,
                    "type": "StripColumnsContainerProperties"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "background": {
                        "type": "BackgroundMedia",
                        "mediaRef": null,
                        "alignType": "center",
                        "fittingType": "fill",
                        "colorOpacity": 0,
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1,
                        "imageOverlay": "",
                        "scrollType": "none",
                        "color": "#FFFFFF"
                    }
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 400,
                        "x": 0,
                        "y": 22,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "fullWidth": true,
                        "rowMargin": 0,
                        "siteMargin": 0,
                        "type": "StripColumnsContainerProperties"
                    },
                    "style": "strc1",
                    "design": {
                        "type": "MediaContainerDesignData",
                        "background": {
                            "type": "BackgroundMedia",
                            "mediaRef": null,
                            "alignType": "center",
                            "fittingType": "fill",
                            "colorOpacity": 0,
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1,
                            "imageOverlay": "",
                            "scrollType": "none",
                            "color": "#FFFFFF"
                        }
                    }
                },
                "id": "comp-ie011k7t"
            },
            "preset": {
                "rect": {"width": 324, "height": 120, "x": 0, "y": 0},
                "label": "add_preset_Strip_Container_Classic_1",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsClassic_2",
            "structure": {
                "type": "Container",
                "layout": {"width": 980, "height": 400, "x": 0, "y": 800, "scale": 1, "rotationInDegrees": 0},
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "components": [{
                    "type": "Container",
                    "layout": {
                        "width": 980,
                        "height": 400,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "components": [],
                    "props": {"type": "ColumnProperties", "alignment": 50},
                    "style": "mc1",
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FAFAFA",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 400,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {"type": "ColumnProperties", "alignment": 50},
                        "style": "mc1",
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FAFAFA",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "fullWidth": true,
                    "rowMargin": 0,
                    "siteMargin": 0,
                    "type": "StripColumnsContainerProperties"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "background": {
                        "type": "BackgroundMedia",
                        "mediaRef": null,
                        "alignType": "center",
                        "fittingType": "fill",
                        "colorOpacity": 0,
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1,
                        "imageOverlay": "",
                        "scrollType": "none",
                        "color": "#FFFFFF"
                    }
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 400,
                        "x": 0,
                        "y": 454,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "fullWidth": true,
                        "rowMargin": 0,
                        "siteMargin": 0,
                        "type": "StripColumnsContainerProperties"
                    },
                    "style": "strc1",
                    "design": {
                        "type": "MediaContainerDesignData",
                        "background": {
                            "type": "BackgroundMedia",
                            "mediaRef": null,
                            "alignType": "center",
                            "fittingType": "fill",
                            "colorOpacity": 0,
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1,
                            "imageOverlay": "",
                            "scrollType": "none",
                            "color": "#FFFFFF"
                        }
                    }
                },
                "id": "comp-ie011uwm"
            },
            "preset": {
                "rect": {"width": 324, "height": 120, "x": 0, "y": 120},
                "label": "add_preset_Strip_Container_Classic_2",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsClassic_3",
            "structure": {
                "type": "Container",
                "layout": {"width": 980, "height": 401, "x": 0, "y": 1400, "scale": 1, "rotationInDegrees": 0},
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "components": [{
                    "type": "Container",
                    "layout": {
                        "width": 980,
                        "height": 401,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "components": [],
                    "props": {"type": "ColumnProperties", "alignment": 50},
                    "style": "mc1",
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "WixVideo",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Snowfall",
                                "videoId": "video/11062b_7124ff06fc56498d96722413f5f4846f",
                                "qualities": [{
                                    "quality": "480p",
                                    "width": 854,
                                    "height": 480,
                                    "formats": ["mp4", "webm"]
                                }, {
                                    "quality": "720p",
                                    "width": 1280,
                                    "height": 720,
                                    "formats": ["mp4", "webm"]
                                }, {"quality": "1080p", "width": 1920, "height": 1080, "formats": ["mp4", "webm"]}],
                                "posterImageRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "",
                                    "uri": "11062b_7124ff06fc56498d96722413f5f4846ff000.jpg",
                                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/f219085a-ca93-4f05-bc3f-5146ce3e6c8f",
                                    "width": 1920,
                                    "height": 1080,
                                    "alt": ""
                                },
                                "opacity": 1,
                                "duration": 30,
                                "loop": true,
                                "autoplay": true,
                                "preload": "auto",
                                "mute": true,
                                "artist": {"id": "i2800", "name": "pexels"}
                            },
                            "color": "#FAFAFA",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 401,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {"type": "ColumnProperties", "alignment": 50},
                        "style": "mc1",
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "WixVideo",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Snowfall",
                                    "videoId": "video/11062b_7124ff06fc56498d96722413f5f4846f",
                                    "qualities": [{
                                        "quality": "480p",
                                        "width": 854,
                                        "height": 480,
                                        "formats": ["mp4", "webm"]
                                    }, {
                                        "quality": "720p",
                                        "width": 1280,
                                        "height": 720,
                                        "formats": ["mp4", "webm"]
                                    }, {
                                        "quality": "1080p",
                                        "width": 1920,
                                        "height": 1080,
                                        "formats": ["mp4", "webm"]
                                    }],
                                    "posterImageRef": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                        "title": "",
                                        "uri": "11062b_7124ff06fc56498d96722413f5f4846ff000.jpg",
                                        "description": "public/8e256233-1752-4026-9341-54036e7f875e/f219085a-ca93-4f05-bc3f-5146ce3e6c8f",
                                        "width": 1920,
                                        "height": 1080,
                                        "alt": ""
                                    },
                                    "opacity": 1,
                                    "duration": 30,
                                    "loop": true,
                                    "autoplay": true,
                                    "preload": "auto",
                                    "mute": true,
                                    "artist": {"id": "i2800", "name": "pexels"}
                                },
                                "color": "#FAFAFA",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "fullWidth": true,
                    "rowMargin": 0,
                    "siteMargin": 0,
                    "type": "StripColumnsContainerProperties"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "background": {
                        "type": "BackgroundMedia",
                        "mediaRef": null,
                        "alignType": "center",
                        "fittingType": "fill",
                        "colorOpacity": 0,
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1,
                        "imageOverlay": "",
                        "scrollType": "none",
                        "color": "#FFFFFF"
                    }
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 401,
                        "x": 0,
                        "y": 886,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "fullWidth": true,
                        "rowMargin": 0,
                        "siteMargin": 0,
                        "type": "StripColumnsContainerProperties"
                    },
                    "style": "strc1",
                    "design": {
                        "type": "MediaContainerDesignData",
                        "background": {
                            "type": "BackgroundMedia",
                            "mediaRef": null,
                            "alignType": "center",
                            "fittingType": "fill",
                            "colorOpacity": 0,
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1,
                            "imageOverlay": "",
                            "scrollType": "none",
                            "color": "#FFFFFF"
                        }
                    }
                },
                "id": "comp-ie01f0wt"
            },
            "preset": {
                "rect": {"width": 324, "height": 120, "x": 0, "y": 240},
                "label": "add_preset_Strip_Container_Classic_3",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsClassic_4",
            "structure": {
                "type": "Container",
                "layout": {"width": 980, "height": 402, "x": 0, "y": 2000, "scale": 1, "rotationInDegrees": 0},
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "components": [{
                    "type": "Container",
                    "layout": {
                        "width": 980,
                        "height": 402,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "components": [],
                    "props": {"type": "ColumnProperties", "alignment": 50},
                    "style": "mc1",
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "Misty Slopes",
                                "uri": "99ec98fdb81945c29c25a3ad6c5606b1.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 1920,
                                "height": 1280,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#FAFAFA",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 402,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {"type": "ColumnProperties", "alignment": 50},
                        "style": "mc1",
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "Misty Slopes",
                                    "uri": "99ec98fdb81945c29c25a3ad6c5606b1.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 1920,
                                    "height": 1280,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#FAFAFA",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "fullWidth": true,
                    "rowMargin": 0,
                    "siteMargin": 0,
                    "type": "StripColumnsContainerProperties"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "background": {
                        "type": "BackgroundMedia",
                        "mediaRef": null,
                        "alignType": "center",
                        "fittingType": "fill",
                        "colorOpacity": 0,
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1,
                        "imageOverlay": "",
                        "scrollType": "none",
                        "color": "#FFFFFF"
                    }
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 402,
                        "x": 0,
                        "y": 1319,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "fullWidth": true,
                        "rowMargin": 0,
                        "siteMargin": 0,
                        "type": "StripColumnsContainerProperties"
                    },
                    "style": "strc1",
                    "design": {
                        "type": "MediaContainerDesignData",
                        "background": {
                            "type": "BackgroundMedia",
                            "mediaRef": null,
                            "alignType": "center",
                            "fittingType": "fill",
                            "colorOpacity": 0,
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1,
                            "imageOverlay": "",
                            "scrollType": "none",
                            "color": "#FFFFFF"
                        }
                    }
                },
                "id": "comp-ie01agg4"
            },
            "preset": {
                "rect": {"width": 324, "height": 120, "x": 0, "y": 360},
                "label": "add_preset_Strip_Container_Classic_4",
                "tags": null
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.StripColumnsContainer"]
    },
    "help": {"hide": false, "text": "add_section_info_text_strip_container_classic"}
}
