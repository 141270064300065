import React from 'react';
import LazyComponent from '@/lazyComponent';

interface DynamicPanelProps {
  panelPath: string;
}

function DynamicPanel(props: DynamicPanelProps) {
  return <LazyComponent moduleName={props.panelPath} key={props.panelPath} />;
}

export default DynamicPanel;
