// @ts-nocheck
import _ from 'lodash';
import * as mediaDialogHandlerUtils from '../utils/mediaDialogHandlerUtils';

const galleryMediaSource = {
  IMAGE: 'gallery_image',
  VIDEO: 'gallery_video',
};

const openMediaDialogByPayloadParsingMethod = function (
  editorAPI,
  msg,
  parseMediaDialogPayload,
  callback,
) {
  const { mediaManager } = editorAPI.mediaServices;
  const mediaType = mediaDialogHandlerUtils.convertMediaType(
    msg.data.mediaType,
    mediaManager,
  );
  const { callOnCancel } = msg.data;
  const isMultiSelect = msg.data.multiSelection;

  const handleMediaDialogResult = function (mediaDialogPayload) {
    let callbackParams;
    const isMediaDialogItemsSelected = !_.isEmpty(mediaDialogPayload);
    if (isMediaDialogItemsSelected) {
      callbackParams = parseMediaDialogPayload(mediaDialogPayload);
    } else if (callOnCancel) {
      callbackParams = {
        wasCancelled: true,
      };
    }

    if (callbackParams) {
      callback(callbackParams);
    }
    editorAPI.mediaServices.setFocusToEditor();
  };

  const options = {
    multiSelect: isMultiSelect,
    callback: handleMediaDialogResult,
  };
  const imageAnalysis = msg?.data?.options?.imageAnalysis;
  if (imageAnalysis) {
    options.imageAnalysis = imageAnalysis;
  }
  mediaManager.open(mediaType, galleryMediaSource[mediaType], options);
};

export { openMediaDialogByPayloadParsingMethod };
