import gfppDataUtils from '../../utils/gfppDataUtils';
import constants from '@/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  translateAction: ACTIONS.SETTINGS,
  mainActions: [
    {
      label: 'gfpp_mainaction_shape',
      isApplied: gfppDataUtils.isSkinDefined,
      onClick(editorAPI: AnyFixMe, compRef: AnyFixMe) {
        editorAPI.mediaServices.changeShape(compRef);
      },
    },
  ],
  enabledActions: [
    ACTIONS.LINK,
    ACTIONS.SETTINGS,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId: '1fcfa99e-c4f9-424c-b938-c8fbaf45d791',
    },
  },
};
