import constants from '@/constants';
import { sections as sectionsUtils } from '@/util';

import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import type { CompSnapshot } from './types';

function getCompLayout(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
): CompSnapshot['layout'] {
  if (!compRef) {
    return null;
  }

  let layout;

  try {
    // for some components it is not possible to get layout (it si undefined)
    // DS doesn't cope these edge case in the getRelativeToStructure methods
    // it causes "Cannot read properties of undefined" error
    layout = documentServices.components.layout.getRelativeToStructure(compRef);
  } catch (e) {
    layout = documentServices.components.layout.get(compRef);
  }

  if (!layout) {
    return null;
  }

  const { x, y, width, height } = layout;

  return {
    x,
    y,
    width,
    height,
  };
}

function getComponentSnapshot(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
  pageTitle: string,
  isFullSnapshot: boolean,
): CompSnapshot {
  return {
    id: compRef.id,
    componentType: documentServices.components.getType(compRef),
    layout: isFullSnapshot
      ? getCompLayout(documentServices, compRef)
      : undefined,
    page: pageTitle,
  };
}

export const collectFlattenChildrenSnapshots = (
  documentServices: DocumentServicesObject,
  compRef: CompRef,
  pageTitle: string,
  isFullSnapshot: boolean,
): CompSnapshot[] => {
  const result: CompSnapshot[] = [];
  const compType = documentServices.components.getType(compRef);

  // avoid not sectionable components
  if (!sectionsUtils.isSectionableComponent(documentServices, compRef)) {
    return result;
  }

  // avoid page and section components but keep their children
  if (
    compType !== constants.COMP_TYPES.PAGE &&
    compType !== constants.COMP_TYPES.SECTION
  ) {
    result.push(
      getComponentSnapshot(
        documentServices,
        compRef,
        pageTitle,
        isFullSnapshot,
      ),
    );
  }

  const children = documentServices.components.getChildren(compRef);

  if (children) {
    children.reduce((acc: CompSnapshot[], item: CompRef) => {
      const children = collectFlattenChildrenSnapshots(
        documentServices,
        item,
        pageTitle,
        isFullSnapshot,
      );

      acc.push(...children);

      return acc;
    }, result);
  }

  return result;
};
