import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import * as stateManagement from '@/stateManagement';
import * as slidesProvider from './slidesProvider';
import * as experiments from '../../utils/experiments';
import React from 'react';
import { CustomScroll } from '@wix/wix-base-ui';
import type {
  FirstTimeTourFlowName,
  FirstTimeTourSlide,
} from './slidesProvider';

interface SuperAppFirstTimeTourPanelProps {
  superApp: unknown;
  onClose: () => void;
  onTourEnd: () => void;
  appWasManuallyInstalled?: boolean;
  flowType?: string;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<SuperAppFirstTimeTourPanelProps>({
  displayName: 'superAppFirstTimeTourPanel',
  propTypes: {
    superApp: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onTourEnd: PropTypes.func.isRequired,
    appWasManuallyInstalled: PropTypes.bool,
    flowType: PropTypes.string,
  },
  mixins: [core.mixins.editorAPIMixin],

  _getFlowName() {
    const isAppInstalled = this.props.superApp.isAppInstalled(
      this.getEditorAPI(),
    );

    if (
      !isAppInstalled &&
      experiments.isPromotionalExperimentOn(this.props.superApp.id)
    ) {
      return slidesProvider.FLOW_NAMES.PROMOTIONAL;
    }

    return this._getUsualFlowName();
  },

  _getUsualFlowName(): FirstTimeTourFlowName {
    const userFlow = this._getUserFlowFromUserPreferences();

    if (userFlow && userFlow !== slidesProvider.FLOW_NAMES.PROMOTIONAL) {
      return userFlow;
    } else if (this.props.appWasManuallyInstalled) {
      return slidesProvider.FLOW_NAMES.MANUALLY_INSTALLED;
    }

    return slidesProvider.FLOW_NAMES.TEMPLATE;
  },

  _getUserFlowFromUserPreferences(): FirstTimeTourFlowName {
    const lastSeenSlideKey = `${this.props.superApp.id}/first_time_tour_last_seen_slide`;
    const userFlow =
      stateManagement.userPreferences.selectors.getSiteUserPreferences<FirstTimeTourSlide>(
        lastSeenSlideKey,
      )(this.getEditorAPI().store.getState());
    return userFlow?.flow;
  },
  getInitialState() {
    const currentSlide = slidesProvider.getNextSlide(
      this.getEditorAPI(),
      this.props.superApp,
      this._getFlowName(),
    );
    return {
      currentSlide,
      isFirstSlide: true,
    };
  },
  componentDidMount() {
    if (this.state.currentSlide === undefined) {
      this.props.onTourEnd();
    }
  },
  nextSlide() {
    const nextSlide = slidesProvider.getNextSlide(
      this.getEditorAPI(),
      this.props.superApp,
      this._getFlowName(),
      this.state.currentSlide,
    );
    this.setState({
      currentSlide: nextSlide,
      isFirstSlide: false,
    });
    if (nextSlide === undefined) {
      this.props.onTourEnd();
    }
  },
  render() {
    return (
      <div className="super-app-first-time-tour-panel">
        <CustomScroll heightRelativeToParent="100%">
          {this.state.currentSlide !== undefined ? (
            <this.state.currentSlide
              {...this.props}
              key="currentSlide"
              nextSlide={this.nextSlide}
              isFirstSlide={this.state.isFirstSlide}
            />
          ) : null}
        </CustomScroll>
      </div>
    );
  },
});
