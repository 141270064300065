export const AIDS = {
  PANEL: 'help-home-panel',
  BLOCK: 'help-home-block',
  EDITOR_SEARCH: 'help-home-trigger-editor-search',
  ARTICLE: 'help-home-article',
  HEADER_CLOSE: 'help-home-close',
  HELP_CENTER: 'help-home-open-help-center',
  WELCOME_TOUR: 'help-home-welcome-tour-start',
};

export const PANEL_NAME = 'rEditor.HelpHomePanel';
