import * as stateManagement from '@/stateManagement';
import type { EditorApiAgentTool } from '../types';
import type { ComponentProperties } from 'types/documentServices';

const SUCCESS_RESPONSE = {
  success: true,
  content: '',
};

type OpenPanelTool = EditorApiAgentTool<{
  panel_name: string;
  comp_id: string;
}>;

type OpenPanelToolHandler = OpenPanelTool['func'];

const overridePanelHandlers: {
  [panelName: string]: OpenPanelToolHandler;
} = {
  'panels.toolPanels.linkPanel': (scope, { comp_id: compId }) => {
    const { editorAPI } = scope;
    const compRef = editorAPI.components.get.byId(compId);
    editorAPI.openLinkPanel({
      link: editorAPI.components.data.get(compRef).link,
      callback: (newLinkData: ComponentProperties['link']) =>
        editorAPI.components.data.update(compRef, { link: newLinkData }),
    });

    return SUCCESS_RESPONSE;
  },
};

const defaultPanelHandler: OpenPanelToolHandler = (
  scope,
  { panel_name, comp_id: compId },
) => {
  const { editorAPI } = scope;

  editorAPI.store.dispatch(
    stateManagement.panels.actions.updateOrOpenPanel(panel_name, {
      origin: 'aiAssistant',
      compId,
    }),
  );

  return SUCCESS_RESPONSE;
};

const openEditorPanelTool: OpenPanelTool = {
  name: 'openEditorPanel',
  func: (scope, { panel_name, ...params }) => {
    const panelOpenHandler =
      overridePanelHandlers[panel_name] ?? defaultPanelHandler;

    return panelOpenHandler(scope, { panel_name, ...params });
  },
};

export default openEditorPanelTool;
