import React, { PureComponent, type ReactElement } from 'react';
import _ from 'lodash';
import * as util from '@/util';
import * as coreBi from '@/coreBi';
import BackToEditorButton from '../backToEditor';
import withSendBi, { type WithSendBiProps } from '../../../hocs/withSendBi';
import {
  mapDispatchToProps,
  mapStateToProps,
} from './backToEditorFromPreviewMappers';

import type { ProgressStatus } from '@/constants';
import toggleMobileEditor from '../../../thunks/toggleMobileEditor';

export interface BackToEditorFromPreviewButtonOwnProps {
  tooltip?: ReactElement | string;
  label?: string;
  disabled?: boolean;
}

export interface BackToEditorFromPreviewButtonStateProps {
  hasUserSeenMobileEditor: boolean;
  isMobileEditor?: boolean;
  status?: ProgressStatus;
  hasDesignPermissions: boolean;
}

export interface BackToEditorFromPreviewButtonDispatchProps {
  togglePreview: (
    callback?: Function,
    params?: { biParams?: AnyFixMe },
  ) => void;
  toggleMobileEditor: typeof toggleMobileEditor;
}

type BackToEditorFromPreviewButtonProps = WithSendBiProps &
  BackToEditorFromPreviewButtonOwnProps &
  BackToEditorFromPreviewButtonStateProps &
  BackToEditorFromPreviewButtonDispatchProps;

class BackToEditorFromPreviewButton extends PureComponent<BackToEditorFromPreviewButtonProps> {
  handleClick = () => {
    const {
      sendBi,
      togglePreview,
      isMobileEditor,
      toggleMobileEditor,
      hasDesignPermissions,
      hasUserSeenMobileEditor,
    } = this.props;
    const backToEditorEvent =
      coreBi.events.topbar.top_bar_Preview_Page_Back_to_Editor_click;

    if (!hasDesignPermissions && isMobileEditor) {
      toggleMobileEditor(
        false,
        'topBar_backToEditorButton',
        'editor_view_mode_desktop',
      );
    }

    togglePreview();

    sendBi(backToEditorEvent, {
      origin: 'thumb',
    });

    if (isMobileEditor) {
      sendBi(coreBi.events.mobileEditor.MOBILE_EDITOR_READY, {
        is_first: hasUserSeenMobileEditor,
      });
    }
  };

  render() {
    return (
      <BackToEditorButton
        label={this.props.label}
        tooltip={this.props.tooltip}
        disabled={this.props.disabled}
        primary={true}
        onClick={this.handleClick}
      />
    );
  }
}

const ConnectedBackToEditorFromPreviewButton = _.flow(
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  ),
  withSendBi,
)(
  BackToEditorFromPreviewButton,
) as React.ComponentType<BackToEditorFromPreviewButtonOwnProps>;

(ConnectedBackToEditorFromPreviewButton as AnyFixMe).pure =
  BackToEditorFromPreviewButton;

export default ConnectedBackToEditorFromPreviewButton;
