export { mapDispatchToProps as snapshotSectionMapDispatchToProps } from './categories/savedComponents/savedComponents';
export type { SnapshotSectionDispatchProps } from './categories/savedComponents/types';

export { mapDispatchToProps as mapDispatchToBookingsWelcomeSectionProps } from './categories/bookingsWelcome/bookingsWelcomeSectionMapper';
export type { BookingsWelcomeDispatchProps } from './categories/bookingsWelcome/types';

export { mapDispatchToProps as mapDispatchToBlogWelcomeSectionProps } from './categories/newBlogWelcome/blogWelcomeSectionMapper';
export type { BlogWelcomeDispatchProps } from './categories/newBlogWelcome/types';

export { mapDispatchToProps as mapDispatchToEventsWelcomeSectionProps } from './categories/eventsWelcome/eventsWelcomeSectionMapper';
export type { EventsWelcomeDispatchProps } from './categories/eventsWelcome/types';

export { mapDispatchToProps as mapDispatchToOnlineProgramsWelcomeSectionProps } from './categories/onlineProgramsWelcome/onlineProgramsWelcomeSectionMapper';
export type { OnlineProgramsWelcomeDispatchProps } from './categories/onlineProgramsWelcome/types';

export { mapDispatchToProps as mapDispatchToGroupsWelcomeSectionProps } from './categories/groupsWelcome/groupsWelcomeSectionMapper';
export type { GroupsWelcomeDispatchProps } from './categories/groupsWelcome/types';

export { mapDispatchToProps as mapDispatchToForumWelcomeSectionProps } from './categories/forumWelcome/forumWelcomeSectionMapper';
export type { ForumWelcomeDispatchProps } from './categories/forumWelcome/types';

export { mapDispatchToProps as mapDispatchToMembersWelcomeSectionProps } from './categories/membersWelcome/membersWelcomeSectionMapper';
export type { MembersWelcomeDispatchProps } from './categories/membersWelcome/types';

export { mapDispatchToProps as mapDispatchToDatabaseWelcomeSectionProps } from './categories/database/databaseWelcome/databaseWelcomeSectionMapper';
export type { DatabaseWelcomeDispatchProps } from './categories/database/databaseWelcome/types';
