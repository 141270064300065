import React from 'react';
import { Button } from '@wix/wix-base-ui';
import { Add } from '@wix/wix-ui-icons-common/classic-editor';

import { cx } from '@/util';
import { translate } from '@/i18n';

import { AUTOMATION_IDS } from '../SectionBox/constants';

import type { UISkin } from '../skins/skins.types';

import styles from './AddSectionButton.scss';

interface AddSectionButtonProps {
  onClick: (e: React.MouseEvent) => void;
  skin: UISkin;
  sharpBorderTop?: boolean;
}

export const AddSectionButton: React.FC<AddSectionButtonProps> = ({
  sharpBorderTop,
  onClick,
  skin,
}) => {
  return (
    <Button
      className={cx(styles.addSectionButton, 'btn-md', {
        [styles.sharpBorderTop]: sharpBorderTop,
        [styles.legacy]: skin === 'legacy',
        [styles.primary]: skin === 'primary',
        [styles.secondary]: skin === 'secondary',
      })}
      shouldTranslate={false}
      onClick={onClick}
      automationId={AUTOMATION_IDS.BUTTON}
    >
      <>
        <Add className={styles.icon} />
        <span>{translate('section_onstage_add_section_button_label')}</span>
      </>
    </Button>
  );
};
