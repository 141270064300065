import type { IOrigin } from '../../../interactionsTransformFacade';

const ALIGNMENT_MODES_XY = [
  {
    value: '0 0',
    symbolName: 'alignTypeCenter',
    disabled: false,
  },
  {
    value: '50 0',
    symbolName: 'alignTypeCenter',
    disabled: false,
  },
  {
    value: '100 0',
    symbolName: 'alignTypeCenter',
    disabled: false,
  },
  {
    value: '0 50',
    symbolName: 'alignTypeCenter',
    disabled: false,
  },
  {
    value: '50 50',
    symbolName: 'alignTypeCenter',
    disabled: false,
  },
  {
    value: '100 50',
    symbolName: 'alignTypeCenter',
    disabled: false,
  },
  {
    value: '0 100',
    symbolName: 'alignTypeCenter',
    disabled: false,
  },
  {
    value: '50 100',
    symbolName: 'alignTypeCenter',
    disabled: false,
  },
  {
    value: '100 100',
    symbolName: 'alignTypeCenter',
    disabled: false,
  },
];

const DEFAULT_ORIGIN_VALUE = '50 50';

function getOriginFromValue(value: string): IOrigin {
  const [originX, originY] = value.split(' ');
  return {
    x: { value: +originX, type: 'percentage' },
    y: { value: +originY, type: 'percentage' },
  };
}

function getSelectedValueFromOrigin(origin: IOrigin): string {
  return `${origin?.x.value} ${origin?.y.value}`;
}

function getDefaultOrigin() {
  return getOriginFromValue(DEFAULT_ORIGIN_VALUE);
}

export {
  ALIGNMENT_MODES_XY,
  getOriginFromValue,
  getSelectedValueFromOrigin,
  getDefaultOrigin,
};
