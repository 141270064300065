// @ts-nocheck
import _ from 'lodash';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import { getAppPartHelpId } from '@/blog/utils/blogUtils';
import appLogicCustomizationMixin from './appLogicCustomizationMixin';

export default {
  mixins: [appLogicCustomizationMixin],

  evaluateHiddenWhenCondition(hiddenWhen) {
    if (!hiddenWhen) {
      return false;
    }

    const value =
      this.getEditorAPI().dsRead.wixapps.classics.getAppPartViewFieldKeyValue(
        this.props.selectedComponent[0],
        hiddenWhen.forType,
        this._getFormat(),
        hiddenWhen.fieldId,
        hiddenWhen.key,
      );

    return value === hiddenWhen.value;
  },

  linkedCustomization(rule) {
    const self = this;
    const customization = this._getCustomization(rule);
    const valueLink = {
      value: customization.value,
      requestChange(value) {
        valueLink.value = value; // TODO: should be removed
        return self.setCustomizationValue(rule, value);
      },
    };
    return valueLink;
  },

  setCustomizationValue(rule, value, skipUndoRedoStack) {
    const customization = this._getCustomization(rule);
    customization.value = value;
    this._normalizeCustomizationFormat(customization);
    if (value !== null) {
      this.setAppLogicCustomization(customization, skipUndoRedoStack);
    } else {
      this.removeAppLogicCustomization(customization, skipUndoRedoStack);
    }
  },

  getAvailableCustomizations(panelType, options) {
    const format = _.has(options, 'format')
      ? options.format
      : this._getFormat();
    const viewName = _.has(options, 'viewName')
      ? options.viewName
      : this._getSelectedViewName();
    return this.getEditorAPI().dsRead.wixapps.classics.getAvailableCustomizations(
      this.getSelectedComponentPackageName(),
      viewName,
      format,
      panelType,
    );
  },
  getStyleCustomizations(fieldId) {
    return this.getEditorAPI().dsRead.wixapps.classics.getStyleCustomizations(
      this.getSelectedComponentPackageName(),
      fieldId,
    );
  },
  getAvailableViewNames() {
    return this.getEditorAPI().dsRead.wixapps.classics.getAvailableViewNames(
      this.getSelectedComponentPackageName(),
      this._getSelectedAppPartName(),
    );
  },
  isRTLAllowed() {
    return this.getEditorAPI().dsRead.wixapps.classics.isRTLAllowed(
      this.getSelectedComponentPackageName(),
      this._getSelectedAppPartName(),
    );
  },
  getInnerComps() {
    return (
      this.getEditorAPI().dsRead.wixapps.classics.getInnerComps(
        this.getSelectedComponentPackageName(),
        this._getSelectedViewName(),
      ) || []
    );
  },
  getSelectedAppPartDisplayName() {
    return this.getEditorAPI().dsRead.wixapps.classics.getAppPartRole(
      this.getSelectedComponentPackageName(),
      this._getSelectedAppPartName(),
    );
  },
  getPanelTeaserTitle() {
    return `title_${this.getSelectedAppPartDisplayName()}_Teaser_Text`;
  },
  getPanelTeaserText() {
    return `${this.getSelectedAppPartDisplayName()}_Teaser_Text`;
  },
  getSelectedComponentPackageName() {
    const editorAPI = this.getEditorAPI();
    return editorAPI.dsRead.wixapps.classics.getPackageName(
      this.getData('appInnerID'),
    );
  },

  /**
   * @param {string} type possible values: design|help|layout|settings
   * @returns {string} id of help item from santa-editor/packages/blog/utils/blogAppPartsHelpIdsMap.js
   */
  getComponentHelpIdByType(type) {
    return getAppPartHelpId(
      this._getSelectedAppPartName(),
      this.getEditorAPI().viewMode.get(),
      type,
    );
  },

  isMobileViewMode() {
    const { viewMode } = this.getEditorAPI();
    return viewMode.get() === viewMode.VIEW_MODES.MOBILE;
  },

  isSinglePost() {
    return this.getSelectedAppPartDisplayName() === 'SINGLE_POST';
  },

  _getFormat() {
    return {
      MOBILE: 'Mobile',
      DESKTOP: '',
    }[this.getEditorAPI().viewMode.get()];
  },
  _getSelectedViewName() {
    return this.getData('viewName');
  },
  _getSelectedAppPartName() {
    return this.getData('appPartName');
  },

  _getTextCustomizationKeys() {
    return [
      'comp.fontFamily',
      'comp.fontSize',
      'comp.bold',
      'comp.italic',
      'comp.underline',
      'comp.color',
      'comp.backgroundColor',
    ];
  },

  _getTextAndStyleCustomizationKeys() {
    return this._getTextCustomizationKeys().concat('comp.style');
  },

  _getDesignCustomizationKeys() {
    return this._getTextAndStyleCustomizationKeys().concat('comp.hidden');
  },

  _isDesignCustomizationKey(key) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return _.includes(this._getDesignCustomizationKeys(), key);
  },

  _isDesktopCustomizationFormat(format) {
    return format === '';
  },

  _shouldFallbackToCommonCustomization(rule) {
    return (
      this._isDesignCustomizationKey(rule.key) &&
      this._isDesktopCustomizationFormat(rule.format)
    );
  },

  _findCustomization(rule, formatOverride) {
    const commonRule = _.merge({}, rule, {
      format: formatOverride || rule.format,
    });
    return this.getEditorAPI().dsRead.wixapps.classics.findCustomizationOverride(
      this.getAppLogicCustomizations(),
      commonRule,
    );
  },

  _getCommonCustomizationValue(rule) {
    if (rule.format !== '*') {
      const customization = this._findCustomization(rule, '*');
      return customization?.value;
    }
  },

  _getDefaultCustomizationValue(rule) {
    return this.getEditorAPI().dsRead.wixapps.classics.findCustomizationDefaultValueFromDescriptor(
      this.getSelectedComponentPackageName(),
      rule,
    );
  },

  _createCustomization(rule, format) {
    const value = this._getCommonCustomizationValue(rule);
    return {
      fieldId: rule.fieldId,
      forType: rule.forType,
      format,
      key: rule.key,
      type: 'AppPartCustomization',
      view: rule.view,
      value:
        typeof value !== 'undefined'
          ? value
          : this._getDefaultCustomizationValue(rule),
    };
  },

  _findCompRuleByFieldIdAndViewName(compRules, fieldId, viewName) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return _.find(compRules, {
      fieldId,
      view: viewName,
    });
  },

  _isCompFieldId(comp, fieldId) {
    return comp.compId === fieldId || comp.id === fieldId;
  },

  _findInnerCompByRule(rule) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return _.find(
      this.getInnerComps(),
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (comp) {
        return comp.rules
          ? this._findCompRuleByFieldIdAndViewName(
              comp.rules,
              rule.fieldId,
              rule.view,
            )
          : this._isCompFieldId(comp, rule.fieldId);
      }, this),
    );
  },

  _isTextFieldCompType(compType) {
    const componentType =
      this.getEditorAPI().dsRead.wixapps.classics.getComponentTypeByProxyName(
        compType,
      );
    return componentType === 'wysiwyg.viewer.components.WRichText';
  },

  _isCustomizationForTextField(rule) {
    const innerComp = this._findInnerCompByRule(rule);
    return innerComp ? this._isTextFieldCompType(innerComp.type) : false;
  },

  _isTextOrStyleCustomizationKey(key) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return _.includes(this._getTextAndStyleCustomizationKeys(), key);
  },

  _applyCommonTextCustomizationsOnlyToDesktop(rule) {
    this._getTextAndStyleCustomizationKeys().forEach(function (key) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      const commonCustomizationRule = _.assign({}, rule, { format: '*', key });
      const commonCustomization = this._findCustomization(
        commonCustomizationRule,
      );
      if (commonCustomization) {
        this.removeAppLogicCustomization(commonCustomizationRule);
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        const desktopCustomization = _.assign({}, commonCustomization, {
          format: '',
        });
        this.setAppLogicCustomization(desktopCustomization);
      }
    }, this);
  },

  _createMobileStyleRule(rule) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign({}, rule, { format: 'Mobile', key: 'comp.style' });
  },

  _hasMobileStyleCustomization(rule) {
    const mobileStyleRule = this._createMobileStyleRule(rule);
    return this._isExistingCustomization(mobileStyleRule);
  },

  _isExistingCustomization(rule) {
    return Boolean(this.getAppLogicCustomization(rule));
  },

  _getCustomization(rule) {
    let { format } = rule;
    let customization = this._findCustomization(rule);
    if (!customization && this._shouldFallbackToCommonCustomization(rule)) {
      format = '*';
      customization = this._findCustomization(rule, format);
    }
    if (!customization) {
      customization = this._createCustomization(rule, format);
    }
    return customization;
  },

  _isNewTextCustomization(rule) {
    return (
      !this._isExistingCustomization(rule) &&
      this._isTextOrStyleCustomizationKey(rule.key) &&
      this._isCustomizationForTextField(rule)
    );
  },

  _normalizeNewTextCustomizationFormat(rule) {
    if (rule.format === 'Mobile') {
      if (rule.key === 'comp.style') {
        this._applyCommonTextCustomizationsOnlyToDesktop(rule);
      }
    } else if (rule.format === '*' && this._hasMobileStyleCustomization(rule)) {
      rule.format = '';
    }
  },

  _normalizeCustomizationFormat(rule) {
    if (this._isNewTextCustomization(rule)) {
      this._normalizeNewTextCustomizationFormat(rule);
    }
  },
};
