export default {
  verticallyMovable: false,
  controlledByParent: true,
  multiselectable: false,
  selectable: true,
  rotatable: false,
  rotatableByType: false,
  skipInQuickEditPanel: true,
  overrideProportionalResize: true,
};
