const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_21",
  preset: { rect: { width: 162, height: 70, x: 0, y: 600 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        properties: {
          "$st-css":
            ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: value(site_1_1);border-radius: 50px;border: 2px solid rgb(139, 103, 85)}.root:hover{border-radius: 20px}.root:hover::icon{width: 26px;height: 26px}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;display: initial;font-family: helvetica-w01-light,helvetica-w02-light,sans-serif;color: #8B6755;margin: 0px 5.5px 0px 0px}.root::icon{transition: inherit;display: initial;fill: #8B6755;width: 20px;height: 20px;margin: 0px 0px 0px 5.5px}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "15r-120",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "hmj-440",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_download_icon"),
      svgId: "11062b_a67643df07304d9bb39d3653dac8bb91.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-km981jjb",
    layout: {
      x: 11,
      fixedPosition: false,
      y: 615,
      scale: 1,
      height: 42,
      rotationInDegrees: 0,
      width: 142,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "hmj-358", pageId: "c1dmp" },
    },
    metaData: { sig: "inq-328", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
