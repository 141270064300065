import React, { useCallback } from 'react';
import { StageActionButton } from './StageActionButton';
import { hoc, cx } from '@/util';

import {
  mapStateToProps,
  mapDispatchToProps,
  type StageAction,
  type StageActionsDispatchProps,
  type StageActionsStateProps,
} from './StageActions.mapper';

import type { CompRef } from 'types/documentServices';
import type { UISkin } from '../skins/skins.types';

import styles from './StageActions.scss';

export interface StageActionsOwnProps {
  compRef: CompRef;
  skin: UISkin;
}

interface StageActionsProps
  extends StageActionsOwnProps,
    StageActionsStateProps,
    StageActionsDispatchProps {}

export const AUTOMATION_ID = 'section-stage-actions-container';

const StageActionsComponent: React.FC<StageActionsProps> = ({
  compRef,
  shouldShow,
  actions,
  skin,
  horizontallyCentered,
  reportActionClicked,
  containerOffsetLeft,
}) => {
  const handleActionClicked = useCallback(
    (action: StageAction) => {
      action.onClick();
      reportActionClicked(action);
    },
    [reportActionClicked],
  );

  if (!shouldShow) return null;

  return (
    <div
      className={cx(styles.container, {
        [styles.horizontallyCentered]: horizontallyCentered,
        [styles.lightLarge]:
          skin === 'lightLarge' || skin === 'lightLarge-soap',
      })}
      data-hook={AUTOMATION_ID}
      data-skin={skin}
      data-comp-id={compRef.id}
      style={{ left: containerOffsetLeft }}
    >
      {actions.map((action) => {
        const { id, label, Icon, dataHook } = action;

        return (
          <StageActionButton
            key={id}
            label={label}
            onClick={() => handleActionClicked(action)}
            Icon={Icon}
            className={styles.button}
            skin={skin}
            dataHook={dataHook}
          />
        );
      })}
    </div>
  );
};

export const StageActions = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(StageActionsComponent);
