import _ from 'lodash';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import { EditorAPIKey } from '@/apis';
import { Hooks } from '@/apilib';

import type { Shell } from '@/apilib';
import type constants from '@/constants';
import type { MouseEvent } from 'react';
import type { EditorAPI } from '@/editorAPI';

const {
  setUsingWindowMouseEvents,
  setPerformingMouseAction,
  setDragInProgress,
} = stateManagement.mouseActions.actions;
const { isPerformingMouseMoveAction, isUsingWindowMouseEvents } =
  stateManagement.mouseActions.selectors;
const { setLastSelectionClickPos } = stateManagement.selection.actions;
const { getSelectedCompsRefs } = stateManagement.selection.selectors;
const { isInInteractionMode } = stateManagement.interactions.selectors;

type MouseMoveActionType = ValueOf<typeof constants.MOUSE_ACTION_TYPES>;

interface MouseMoveActionStart<P = unknown> {
  (editorAPI: EditorAPI, params: P): void;
}
interface MouseMoveActionOn {
  (event: MouseEvent): void;
}
interface MouseMoveActionEnd {
  (event: MouseEvent): void;
}

export interface MouseMoveAction<P = unknown> {
  type: MouseMoveActionType;
  start: MouseMoveActionStart<P>;
  on: MouseMoveActionOn;
  end: MouseMoveActionEnd;
  setContext?: () => void;
  unsetContext?: () => void;
  params?: P;
}

export type RegisterMouseMoveAction = <P = unknown>(
  actionObj: MouseMoveAction<P>,
  params: P,
  shouldUseWindowMouseEvents?: boolean,
) => void;

export function createMouseActionsApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  let registeredMouseMoveAction: MouseMoveAction<any> = null;
  const { dispatch, getState } = editorAPI.store;

  const hooks = {
    start: Hooks.createHook<{ type: MouseMoveActionType; params: unknown }>(),
    end: Hooks.createHook<{
      type: MouseMoveActionType;
      event: MouseEvent;
    }>(),
  };

  function onMouseMove(evt: AnyFixMe) {
    // evt.preventDefault();
    const editorState = getState();
    if (
      registeredMouseMoveAction &&
      _.isFunction(registeredMouseMoveAction.on)
    ) {
      const isFirstMouseMove = !isPerformingMouseMoveAction(editorState);
      if (isFirstMouseMove) {
        if (_.isFunction(registeredMouseMoveAction.setContext)) {
          registeredMouseMoveAction.setContext();
        } else {
          util.keyboardShortcuts.disable();
        }
        dispatch(setPerformingMouseAction(true));
        dispatch(setLastSelectionClickPos(null));
      }

      if (isFirstMouseMove) {
        editorAPI.store.flush();
      } else if (!editorState.mouseActions.dragInProgress) {
        dispatch(setDragInProgress(true));
      }

      registeredMouseMoveAction.on(evt);
    }
  }

  function onMouseUp(evt: AnyFixMe) {
    // evt.preventDefault();
    if (
      registeredMouseMoveAction &&
      _.isFunction(registeredMouseMoveAction.end)
    ) {
      registeredMouseMoveAction.end(evt);
    }
    if (
      registeredMouseMoveAction &&
      _.isFunction(registeredMouseMoveAction.unsetContext)
    ) {
      registeredMouseMoveAction.unsetContext();
    } else {
      util.keyboardShortcuts.enable();
    }
    hooks.end.fire({ type: registeredMouseMoveAction?.type, event: evt });

    turnOffMouseEvents();
  }

  function turnOffMouseEvents() {
    const editorState = getState();
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
    const performingMouseMoveAction = isPerformingMouseMoveAction(editorState);
    const usingWindowEvents = isUsingWindowMouseEvents(editorState);

    if (editorState.mouseActions.dragInProgress) {
      dispatch(setDragInProgress(false));
    }

    if (performingMouseMoveAction || usingWindowEvents) {
      dispatch(setPerformingMouseAction(false));
      dispatch(setUsingWindowMouseEvents(false));
    }
    registeredMouseMoveAction = null;
  }

  const registerMouseMoveAction: RegisterMouseMoveAction = (
    actionObj,
    params,
    shouldUseWindowMouseEvents,
  ) => {
    if (_.isFunction(actionObj.start)) {
      actionObj.start(editorAPI, params);
    }

    if (shouldUseWindowMouseEvents) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    }

    registeredMouseMoveAction = actionObj;
    registeredMouseMoveAction.params = params;
    hooks.start.fire({ type: registeredMouseMoveAction?.type, params });

    dispatch(setUsingWindowMouseEvents(!!shouldUseWindowMouseEvents));
  };

  function shouldInitLassoToolOnMouseDown(mouseDownEvent: AnyFixMe) {
    const currentEditorState = getState();
    const selectedComps = getSelectedCompsRefs(currentEditorState);

    if (
      editorAPI.selection.isSelectedCompUnderCursorAndBeneathSiteSegment(
        selectedComps,
        mouseDownEvent,
      )
    ) {
      return false;
    }

    const componentToBeSelected =
      editorAPI.selection.getComponentUnderClickToBeSelected(mouseDownEvent);

    if (isInInteractionMode(currentEditorState)) {
      return false;
    }

    if (editorAPI.selection.isMultiSelectKeyPressed(mouseDownEvent)) {
      return true;
    }

    if (editorAPI.selection.isHiddenComponentDraggedNext()) {
      return false;
    }

    if (
      editorAPI.components.is.siteSegment(componentToBeSelected) ||
      editorAPI.components.is.page(componentToBeSelected) ||
      (util.sections.isSectionsEnabled() &&
        editorAPI.sections.isSectionLike(componentToBeSelected))
    ) {
      return true;
    }

    const isContainer =
      editorAPI.components.is.container(componentToBeSelected) ||
      // @ts-expect-error
      editorAPI.components.is.containableByStructure(componentToBeSelected);
    const shouldForceEnableLasso = util.fixedStage.isFixedStageEnabled();
    const isAllowLassoOnContainer = componentToBeSelected
      ? editorAPI.components.is.allowLassoOnContainer(componentToBeSelected)
      : shouldForceEnableLasso;

    if (isContainer && isAllowLassoOnContainer) {
      if (
        editorAPI.components.isDescendantOfComp(
          selectedComps,
          componentToBeSelected,
        )
      ) {
        return true;
      }

      if (editorAPI.columns.isColumn(componentToBeSelected)) {
        return false;
      }

      if (!editorAPI.components.is.draggable(componentToBeSelected)) {
        return true;
      }

      return !(
        componentToBeSelected &&
        editorAPI.selection.isComponentSelected(componentToBeSelected)
      );
    }

    return false;
  }

  function onEditorViewMouseMove(e: AnyFixMe) {
    const editorState = getState();
    if (registeredMouseMoveAction && !isUsingWindowMouseEvents(editorState)) {
      onMouseMove(e);
    }
  }

  function onEditorViewMouseUp(e: AnyFixMe) {
    const editorState = getState();
    if (registeredMouseMoveAction && !isUsingWindowMouseEvents(editorState)) {
      onMouseUp(e);
    }
  }

  return {
    hooks,
    onMouseMove,
    onMouseUp,
    onEditorViewMouseMove,
    onEditorViewMouseUp,
    turnOffMouseEvents,
    registerMouseMoveAction,
    shouldInitLassoToolOnMouseDown,
    getRegisteredMouseMoveAction() {
      return registeredMouseMoveAction;
    },
    setRegisteredMouseMoveAction(action: AnyFixMe) {
      registeredMouseMoveAction = action;
    },
  };
}
