import React from 'react';
import * as mediaPanelConsts from '../../mediaManagerPanelConstants/mediaManagerPanelConstants';
import WixMediaBaseSection from './wixMediaSectionBase';

class WixMediaImagesSection extends React.Component {
  getProps() {
    return Object.assign(
      {
        title: 'Media_Panel_Wix_Images_Section_Label',
        mediaRoot: mediaPanelConsts.PUBLIC_MEDIA_ROOT.IMAGES,
      },
      this.props,
    );
  }

  render() {
    return <WixMediaBaseSection {...this.getProps()} />;
  }
}

export default WixMediaImagesSection;
