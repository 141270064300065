import React, { Component, type ComponentType } from 'react';

import connectWithEditor from '../../util/connectWithEditor';
import mapPropsToMenuBarItems from '../../menuBar/menuBarListItems/menuStructure';
import { withRestrictions } from '@/editorRestrictions';
import { mapStateToProps, mapDispatchToProps } from './withMenuItemsMapper';

import type { ITopBarMenuItemOrButton } from '../../menuBar/types';
import type {
  MenuBarItemsOwnProps,
  MenuBarItemsStateProps,
  MenuBarItemsDispatchProps,
} from '../../menuBar/menuBarListItems/menuStructure';

export interface ComponentWithMenuItemsProps {
  menuItems: ITopBarMenuItemOrButton[];
}

export interface WithMenuItemsOwnProps extends MenuBarItemsOwnProps {}
export interface WithMenuItemsStateProps extends MenuBarItemsStateProps {}
export interface WithMenuItemsDispatchProps extends MenuBarItemsDispatchProps {
  pickOwnProps: () => object;
}

interface WithMenuItemsProps
  extends WithMenuItemsOwnProps,
    WithMenuItemsStateProps,
    WithMenuItemsDispatchProps {
  type?: 'left' | 'right';
}

const withMenuItems = <P extends WithMenuItemsProps>(
  WrappedComponent: ComponentType<P & ComponentWithMenuItemsProps>,
) => {
  class WithMenuItems extends Component<WithMenuItemsProps> {
    render() {
      const menuItems = mapPropsToMenuBarItems(
        this.props as WithMenuItemsProps,
        this.props.type,
      );

      return React.createElement(
        WrappedComponent,
        Object.assign({}, this.props.pickOwnProps() as P, {
          menuItems,
        }),
      );
    }
  }

  return withRestrictions('top-bar_menu-bar')(
    connectWithEditor(mapStateToProps, mapDispatchToProps)(WithMenuItems),
  );
};

export default withMenuItems;
