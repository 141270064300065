import type { VideoItem } from './types';

const itemsForUsers: VideoItem[] = [
  {
    title: 'Editor_Help_Panel_Video_Header_Footer',
    url: '7PMSPVq2deQ',
    duration: '3:32',
  },
  {
    title: 'Editor_Help_Panel_Video_Page_Structure',
    url: 'TcPU0BFFlmM',
    duration: '1:26',
  },
  {
    title: 'Editor_Help_Panel_Video_Domains',
    url: '2F5QVBqM-Qs',
    duration: '3:17',
  },
  {
    title: 'Editor_Help_Panel_Video_Text',
    url: '9-YgOp6xghg',
    duration: '3:31',
  },
  {
    title: 'Editor_Help_Panel_Video_Buttons',
    url: '1p-fRkLWyMs',
    duration: '3:41',
  },
  {
    title: 'Editor_Help_Panel_Video_Social_bar',
    url: 'nAtlFIGpevc',
    duration: '3:57',
  },
  {
    title: 'Editor_Help_Panel_Video_Elements',
    url: '5xpCn8Xarfc',
    duration: '2:26',
  },
  {
    title: 'Editor_Help_Panel_Video_Images',
    url: 'jNSVFa59mwA',
    duration: '3:00',
  },
  {
    title: 'Editor_Help_Panel_Video_Mobile_Editor',
    url: 'CCFWEJ6r3NA',
    duration: '3:49',
  },
];

const itemsForPartners: VideoItem[] = [
  {
    title: 'Editor_Help_Panel_Video_Restore_Versions',
    url: 'U1v7LXFXbY0',
    duration: '1:39',
  },
  {
    title: 'Editor_Help_Panel_Video_Sticky_Header',
    url: '9_jtI06mqwk',
    duration: '3:09',
  },
  {
    title: 'Editor_Help_Panel_Video_Content_Manager',
    url: 'R0PQg-0pEHM',
    duration: '1:42',
  },
  {
    title: 'Editor_Help_Panel_Video_Dynamic_Repeaters',
    url: 'PbBVugdCG_4',
    duration: '1:23',
  },
];

export const getVideoItems = ({
  isPartner,
}: {
  isPartner: boolean;
}): VideoItem[] => {
  return isPartner ? itemsForPartners : itemsForUsers;
};
