import React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import { imageTransform } from '@wix/santa-editor-utils';

const { fittingTypes } = imageTransform;

const BGPP_SETTINGS_IMAGE_FITTING_SCALE_TO_FILL = {
  label: 'BGPP_SETTINGS_IMAGE_FITTING_SCALE_TO_FILL',
  value: fittingTypes.SCALE_TO_FILL,
};

const BGPP_SETTINGS_IMAGE_FITTING_SCALE_TO_FIT = {
  label: 'BGPP_SETTINGS_IMAGE_FITTING_SCALE_TO_FIT',
  value: fittingTypes.SCALE_TO_FIT,
};

const BGPP_SETTINGS_IMAGE_FITTING_ORIGINAL_SIZE = {
  label: 'BGPP_SETTINGS_IMAGE_FITTING_ORIGINAL_SIZE',
  value: fittingTypes.LEGACY_BG_NORMAL,
};

const BGPP_SETTINGS_IMAGE_FITTING_TILE = {
  label: 'BGPP_SETTINGS_IMAGE_FITTING_TILE',
  value: fittingTypes.LEGACY_BG_FIT_AND_TILE,
};

const BGPP_SETTINGS_IMAGE_FITTING_TILE_HORIZONTALLY = {
  label: 'BGPP_SETTINGS_IMAGE_FITTING_TILE_HORIZONTALLY',
  value: fittingTypes.LEGACY_BG_FIT_AND_TILE_HORIZONTAL,
};

const BGPP_SETTINGS_IMAGE_FITTING_TILE_VERTICALLY = {
  label: 'BGPP_SETTINGS_IMAGE_FITTING_TILE_VERTICALLY',
  value: fittingTypes.LEGACY_BG_FIT_AND_TILE_VERTICAL,
};

export const normalFittingOptions = [
  BGPP_SETTINGS_IMAGE_FITTING_SCALE_TO_FILL,
  BGPP_SETTINGS_IMAGE_FITTING_SCALE_TO_FIT,
  BGPP_SETTINGS_IMAGE_FITTING_ORIGINAL_SIZE,
  BGPP_SETTINGS_IMAGE_FITTING_TILE,
  BGPP_SETTINGS_IMAGE_FITTING_TILE_HORIZONTALLY,
  BGPP_SETTINGS_IMAGE_FITTING_TILE_VERTICALLY,
];

export const scrollMobileFittingOptions = [
  BGPP_SETTINGS_IMAGE_FITTING_SCALE_TO_FILL,
  { label: 'BGPP_SETTINGS_IMAGE_FITTING_STRETCH', value: fittingTypes.STRETCH },
  BGPP_SETTINGS_IMAGE_FITTING_SCALE_TO_FIT,
  BGPP_SETTINGS_IMAGE_FITTING_TILE,
];

export const fixedMobileFittingOptions = [
  BGPP_SETTINGS_IMAGE_FITTING_SCALE_TO_FILL,
  BGPP_SETTINGS_IMAGE_FITTING_SCALE_TO_FIT,
  BGPP_SETTINGS_IMAGE_FITTING_TILE,
];

export const excludedMobileFittingOptionsMap = [
  BGPP_SETTINGS_IMAGE_FITTING_ORIGINAL_SIZE,
  BGPP_SETTINGS_IMAGE_FITTING_TILE_HORIZONTALLY,
  BGPP_SETTINGS_IMAGE_FITTING_TILE_VERTICALLY,
].reduce((a, b) => {
  return {
    ...a,
    [b.value]: b,
  };
}, {});

const BGPP_SETTINGS_IMAGE_SCROLL_NONE = {
  value: 'scroll',
  label: 'BGPP_SETTINGS_IMAGE_SCROLL_NONE',
  illustration: <Symbol name="thumb-none" />,
};

const BGPP_SETTINGS_IMAGE_SCROLL_PARALLAX = {
  value: 'parallax',
  label: 'BGPP_SETTINGS_IMAGE_SCROLL_PARALLAX',
  illustration: <Symbol name="scroll-fx-site-bg-parallax" />,
};

const BGPP_SETTINGS_IMAGE_SCROLL_FREEZE = {
  value: 'fixed',
  label: 'BGPP_SETTINGS_IMAGE_SCROLL_FREEZE',
  illustration: <Symbol name="scroll-fx-site-bg-freeze" />,
};

export const effectsThumbnailsOptions = [
  BGPP_SETTINGS_IMAGE_SCROLL_NONE,
  BGPP_SETTINGS_IMAGE_SCROLL_PARALLAX,
  BGPP_SETTINGS_IMAGE_SCROLL_FREEZE,
];

export const effectsThumbnailsMobileOptions = [
  BGPP_SETTINGS_IMAGE_SCROLL_NONE,
  BGPP_SETTINGS_IMAGE_SCROLL_FREEZE,
];

export const parallaxFittingOptions = [
  BGPP_SETTINGS_IMAGE_FITTING_SCALE_TO_FILL,
  BGPP_SETTINGS_IMAGE_FITTING_TILE,
];

export const tileFittingOptions = [
  fittingTypes.LEGACY_BG_FIT_AND_TILE,
  fittingTypes.LEGACY_BG_FIT_AND_TILE_HORIZONTAL,
  fittingTypes.LEGACY_BG_FIT_AND_TILE_VERTICAL,
];
