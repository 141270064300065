// @ts-nocheck
import * as util from '@/util';
import { translate } from '@/i18n';
import dataProvider from '../common/dataProvider';
import manageBookingsPanel from './panels/manageBookings/manageBookingsPanel';
import wixBookingsAddPanelSections from '../addPanel/wixBookingsAddPanelSections';
import promotePanel from './panels/firstTimeTour/promotePanel';

const getSuperAppBase = () => {
  const learnMore = getLearnMoreWithHireWixDesignPartner();
  return {
    id: 'wixBookings',
    appDefId: dataProvider.WIX_BOOKINGS_APP_DEF_ID,
    appName: 'WixBookings',
    firstTimeTour: {
      templateFlow: [promotePanel],
      manuallyInstalledFlow: [promotePanel],
      promotionalFlow: [promotePanel],
    },
    panel: {
      name: dataProvider.superAppPanelName,
      label: util.workspace.isNewWorkspaceEnabled()
        ? translate('SiteApp_WixBookings_Panel_Title')
        : undefined,
      pages: {
        isHidden: true,
      },
      manage: {
        title: 'SiteApp_WixBookings_Menu_ManageBookings',
        helpId: 'bf44fdcd-7f35-41a2-a04a-93a0a821e55d',
        template: manageBookingsPanel,
      },
      learnMore,
    },
  };
};

const getLearnMoreWithHireWixDesignPartner = () => ({
  title: 'SiteApp_WixBookings_Learn_Title',
  section2HeaderViewA: 'SiteApp_WixBookings_Learn_Forum',
  section3HeaderViewA: 'SiteApp_WixBookings_Learn_Blog',
  section4HeaderViewA: 'SiteApp_WixBookings_Learn_More',
  data: {
    viewA: {
      helpCenterPopUp: {
        isHidden: true,
      },
      supportForumsPopUp: {
        link: 'https://support.wix.com/en/wix-bookings/setting-up-wix-bookings/?locale={{lang}}',
        image: util.media.getMediaUrl(
          'superAppMenuInfra/ViewTheBlogHelpCenter.png',
        ),
      },
      wixTipsBlog: {
        link: 'https://www.wix.com/blog/category/small-business-tips/',
        image: util.media.getMediaUrl(
          'superAppMenuInfra/OpenTheBlogSupportForum.png',
        ),
      },
      wixHireDesignPartner: {
        link: 'https://www.wix.com/marketplace/dynamic-brief?entry=booking&serviceType=2&innerType=1&origin=booking-editor',
        image: util.media.getMediaUrl(
          'superAppMenuInfra/HireWixDesignPartner.png',
        ),
      },
    },
  },
});

const getSuperAppWithLocalizedSupportLink = (superApp) => {
  const lang = util.languages.getLanguageCode();
  const supportLink =
    superApp.panel.learnMore.data.viewA.supportForumsPopUp.link;
  superApp.panel.learnMore.data.viewA.supportForumsPopUp.link =
    supportLink.replace('{{lang}}', lang);
  return superApp;
};

function getWixBookingsSuperApp({
  editorAPI,
  isUserOwner,
  openAppPremiumPackagePicker,
  isAppInstalled,
  isPremium,
}) {
  let superApp = getSuperAppBase();
  superApp.isPremium = isPremium || dataProvider.hasPremium;
  superApp.isAppInstalled = isAppInstalled;
  superApp.openAppPremiumPackagePicker = openAppPremiumPackagePicker;
  superApp.hasPremiumPlan = isUserOwner;
  superApp.upgradeLinkText = 'SiteApp_WixBooking_Upgrade_link';
  superApp.tooltip = {
    upgrade: {
      title: 'SiteApp_WixBookings_UpgradeTooltip_Title',
      text: 'SiteApp_WixBookings_UpgradeTooltip_Paragraph1',
      label: 'SiteApp_WixBookings_UpgradeTooltip_Button_Label',
      image: 'menubarUpgradePromotionDark',
    },
  };

  superApp.panel = superApp.panel || {};

  superApp.panel.addElements = {
    title: 'SiteApp_WixBookings_AddElement_Title',
    helpId: 'ec2615c5-4f08-4c7e-8ee5-2c9fde4ab6cb',
    sections: wixBookingsAddPanelSections({ editorAPI }),
    categoryId: dataProvider.getAddElementCategoryId(),
  };

  superApp = getSuperAppWithLocalizedSupportLink(superApp);

  return superApp;
}

export { getWixBookingsSuperApp };
