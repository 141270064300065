'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const appDefinitionId = '949cfcc9-2a3f-4a96-bd6c-11d9d82763a4';
const widgetId = 'aa86b56d-3c49-44fd-9976-963695e7815d';

module.exports = {
  type: addPanelDataConsts.SECTIONS_TYPES.DATA,
  hide: false,
  title: 'ADD_SECTION_RICHCONTENT_TITLE',
  showSectionHeader: true,
  props: {
    items: [
      {
        title: 'ADD_SECTION_RICHCONTENT_COMP1_TITLE',
        image: 'addPanelData/sections/dataBinding/addRichContent.png',
        action: addPanelDataConsts.CLICK_ACTION.DATA.ADD_RICHCONTENT,
        tooltip: 'ADD_SECTION_RICHCONTENT_COMP1_DESCRIPTION',
        desc: 'ADD_SECTION_RICHCONTENT_COMP1_DESCRIPTION',
        symbol: 'plusBig',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          layout: {
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
            canBeStretched: true,
          },
          componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          data: {
            type: 'TPAWidget',
            appDefinitionId,
            widgetId,
          },
          style: 'tpaw0',
        },
      }
    ]
  }
}
