import _ from 'lodash';
import * as util from '@/util';
import * as helpIds from '@/helpIds';
import keyboardShortcutsActionMap from '@/keyboardShortcuts';
import selectComponentShortcutsActions from '../actions/selectComponentShortcutsActions';
import editorDebugShortcutsUtil from '../util/editorDebugShortcutsUtil';
import sendBIAndActionUtil from '../util/sendBIAndActionUtil';
import { gfppModel } from '@/gfppData';
import * as coreBi from '@/coreBi';
import experiment from 'experiment';
const { isMultiselect } = util.array;
const EDITOR_SHORTCUTS_CONFIG = {};
const SHORTCUTS_ORIGIN = 'keyboardShortcuts';
const HELP_PANEL_SHORTCURS = 'kb_shortcuts';

import type { EditorAPI } from '@/editorAPI';

const keyboardMap = keyboardShortcutsActionMap.editor;

function addEditorShortcuts(editorAPI: EditorAPI) {
  const keysToMerge: Record<string, Function> = {};
  keysToMerge[keyboardMap.GROUP_COMPONENTS.key] = groupComponents.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.UNGROUP_COMPONENTS.key] = ungroupComponents.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.COPY.key] = copyComponentsFromKeyboard.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.CUT.key] = cutComponentFromKeyboard.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.DUPLICATE.key] = duplicateComponentFromKeyboard.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.MOVE_RIGHT.key] = moveComponentToDirection.bind(
    null,
    editorAPI,
    'x',
    1,
  );
  keysToMerge[keyboardMap.MOVE_LEFT.key] = moveComponentToDirection.bind(
    null,
    editorAPI,
    'x',
    -1,
  );
  keysToMerge[keyboardMap.MOVE_UP.key] = moveComponentToDirection.bind(
    null,
    editorAPI,
    'y',
    -1,
  );
  keysToMerge[keyboardMap.MOVE_DOWN.key] = moveComponentToDirection.bind(
    null,
    editorAPI,
    'y',
    1,
  );
  keysToMerge[keyboardMap.SHIFT_RIGHT.key] = moveComponentToDirection.bind(
    null,
    editorAPI,
    'x',
    10,
  );
  keysToMerge[keyboardMap.SHIFT_LEFT.key] = moveComponentToDirection.bind(
    null,
    editorAPI,
    'x',
    -10,
  );
  keysToMerge[keyboardMap.SHIFT_UP.key] = moveComponentToDirection.bind(
    null,
    editorAPI,
    'y',
    -10,
  );
  keysToMerge[keyboardMap.SHIFT_DOWN.key] = moveComponentToDirection.bind(
    null,
    editorAPI,
    'y',
    10,
  );

  keysToMerge[keyboardMap.ADD_APPLICATIVE_MODE.key] = function () {
    if (experiment.isOpen('applicativeModes')) {
      const selectedComponent = _.head(
        editorAPI.selection.getSelectedComponents(),
      );
      if (selectedComponent) {
        const currentModes =
          editorAPI.components.modes.getModes(selectedComponent);
        const label = `mode-${currentModes ? currentModes.length : '0'}`;
        // @ts-expect-error
        editorAPI.components.modes.addModeDefinition(
          selectedComponent,
          'APPLICATIVE',
          null,
          label,
        );
      }
    }
  };
  keysToMerge[keyboardMap.OPEN_QUICK_NAVIGATION.key] = openQuickNavigation.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.TOGGLE_TOOLBAR.key] = toggleToolbar.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.OPEN_HELP_CENTER.key] = openHelpCenter.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.NEW_PAGE.key] = addNewPage.bind(null, editorAPI);
  keysToMerge[keyboardMap.OPEN_SELECTED_COMP_SETTINGS_PANEL.key] =
    openSelectedCompPanel.bind(null, editorAPI, 'settings');
  keysToMerge[keyboardMap.OPEN_SELECTED_COMP_LAYOUT_PANEL.key] =
    openSelectedCompPanel.bind(null, editorAPI, 'layout');
  keysToMerge[keyboardMap.OPEN_SELECTED_COMP_DESIGN_PANEL.key] =
    openSelectedCompPanel.bind(null, editorAPI, 'design');
  keysToMerge[keyboardMap.TRIGGER_SELECTED_COP_MAIN_ACTION.key] =
    triggerSelectedCompMainAction.bind(null, editorAPI);
  keysToMerge[keyboardMap.TOGGLE_DEVELOPER_TOOLBAR.key] = function () {
    if (
      editorAPI.developerMode.isEnabled() &&
      editorAPI.developerToolBar.isInCodePanel()
    ) {
      editorAPI.bi.event(coreBi.events.PROPERTIES_PANEL_TOGGLE, {
        site_id: editorAPI.dsRead.generalInfo.getSiteId(),
        state: editorAPI.developerToolBar.isEnabled() ? 'close' : 'open',
        origin: 'keyboard_shortcut',
      });
      editorAPI.developerToolBar.toggle();
    }
  };

  const debugShortcuts = editorDebugShortcutsUtil(editorAPI);
  _.merge(keysToMerge, debugShortcuts);
  _.merge(EDITOR_SHORTCUTS_CONFIG, keysToMerge);

  document.body.oncopy = (evt: ClipboardEvent) => {
    // it cleans the copy event after copy/paste of file
    if (_.isEmpty(util.browserUtil.getBrowserSelectedText())) {
      evt.clipboardData.setData('text/plain', '');
      evt.preventDefault();
    }
  };
}

export function copyComponentsFromKeyboard(editorAPI: EditorAPI) {
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.COPY_COMPONENTS,
  );

  const editorBIEvents = coreBi.events.rightClickMenu;
  const selectedComponents = editorAPI.selection.getSelectedComponents();
  const isCompPinned = editorAPI.components.layout.isPinned(selectedComponents)
    ? 1
    : 0;

  editorAPI.selectedComponent.copy();
  util.fedopsLogger.interactionEnded(
    util.fedopsLogger.INTERACTIONS.COPY_COMPONENTS,
  );

  if (selectedComponents) {
    const compType = editorAPI.components.getType(selectedComponents);

    sendBIAndActionUtil(editorAPI, editorBIEvents.COPY, {
      origin: 'keyboard',
      is_component_pinned: isCompPinned,
      component_type: compType,
    });
  }
}

function groupComponents(editorAPI: EditorAPI, e: KeyboardEvent) {
  const selectedComps = editorAPI.selection.getSelectedComponents();
  if (
    editorAPI.components.is.groupable(selectedComps) &&
    isMultiselect(selectedComps)
  ) {
    editorAPI.components.groupComponents(selectedComps);
  }
  e.preventDefault();
}

function ungroupComponents(editorAPI: EditorAPI, e: KeyboardEvent) {
  const selectedComps = editorAPI.selection.getSelectedComponents();
  if (editorAPI.components.is.group(selectedComps)) {
    const groupChildren = editorAPI.components.getChildren(selectedComps);
    editorAPI.selection.selectComponentByCompRef(groupChildren);
    editorAPI.components.ungroup(selectedComps);
  }
  e.preventDefault();
}

export async function duplicateComponentFromKeyboard(editorAPI: EditorAPI) {
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.DUPLICATE_COMPONENT_OR_TPA,
  );
  const editorBIEvents = coreBi.events.rightClickMenu;

  const selectedComps = editorAPI.selection.getSelectedComponents();
  const isCompPinned = editorAPI.components.layout.isPinned(selectedComps)
    ? 1
    : 0;

  sendBIAndActionUtil(editorAPI, editorBIEvents.DUPLICATE, {
    origin: 'keyboard',
    is_component_pinned: isCompPinned,
  });

  await editorAPI.selectedComponent.duplicate();
  if (editorAPI.selection.getSelectedComponentType() !== 'Column') {
    editorAPI.history.add('component - duplicate', { isAddingComponent: true });
  }
}

export function cutComponentFromKeyboard(editorAPI: EditorAPI) {
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.CUT_COMPONENT,
  );
  const editorBIEvents = coreBi.events.rightClickMenu;

  const selectedComps = editorAPI.selection.getSelectedComponents();
  const isCompPinned = editorAPI.components.layout.isPinned(selectedComps)
    ? 1
    : 0;

  sendBIAndActionUtil(editorAPI, editorBIEvents.CUT, {
    origin: 'keyboard',
    is_component_pinned: isCompPinned,
  });

  editorAPI.selectedComponent.cut();

  const historyParams = {
    component_id: selectedComps.map(({ id }) => id).join(','),
    component_type: selectedComps
      .map((compRef) => editorAPI.components.getType(compRef))
      .join(','),
  };

  editorAPI.history.add('component - cut', historyParams);
}

function moveComponentToDirection(
  editorAPI: EditorAPI,
  axis: 'x' | 'y',
  amountToMove: number,
  event: KeyboardEvent,
) {
  if (event) {
    event.preventDefault();
  }

  selectComponentShortcutsActions.moveComponent(editorAPI, axis, amountToMove);
}

function openQuickNavigation(editorAPI: EditorAPI) {
  editorAPI.openQuickNavigation(editorAPI, 'keyboard_shortcut');
}

function toggleToolbar(editorAPI: EditorAPI) {
  editorAPI.toolbar.toggleToolbar();
  editorAPI.bi.event(coreBi.events.topbar.top_bar_VIEW_menu_click, {
    category: 'toolbar',
    status: editorAPI.toolbar.isToolbarEnabled(),
    origin: SHORTCUTS_ORIGIN,
  });
}

function openHelpCenter(editorAPI: EditorAPI) {
  const biParams = {
    origin: SHORTCUTS_ORIGIN,
    panel_name: HELP_PANEL_SHORTCURS,
  };
  editorAPI.panelManager.openHelpCenter(
    helpIds.HELP_CENTER.KEYBOARD_SHORTCUTS,
    null,
    biParams,
  );
}

function addNewPage(editorAPI: EditorAPI) {
  const newPage = editorAPI.pages.add('Blank');
  if (newPage) {
    editorAPI.navigateAndOpenPagesPanel(
      newPage.id,
      {
        origin: SHORTCUTS_ORIGIN,
      },
      _.noop,
      true,
    );
  } else {
    editorAPI.panelHelpers.openPagesPanel({ origin: SHORTCUTS_ORIGIN });
  }
}

function openSelectedCompPanel(editorAPI: EditorAPI, panelName: string) {
  const selectedComponents = editorAPI.selection.getSelectedComponents();
  const gfppData = gfppModel.getFullComponentGfppData(
    editorAPI,
    selectedComponents,
  );

  const presetActions = gfppData?.presetActions;
  if (!presetActions) {
    return;
  }
  const action = presetActions[panelName as keyof typeof presetActions];
  if (!action) {
    return;
  }
  action.onClick(editorAPI, selectedComponents);
  editorAPI.bi.event(coreBi.events.shortcuts.comp_main_panels_trigger, {
    panel_name: panelName,
  });
}

function triggerSelectedCompMainAction(editorAPI: EditorAPI) {
  const selectedComponents = editorAPI.selection.getSelectedComponents();
  const gfppData = gfppModel.getFullComponentGfppData(
    editorAPI,
    selectedComponents,
  );

  const mainActions = gfppData?.mainActions;
  if (!mainActions || _.isEmpty(mainActions)) {
    return;
  }
  mainActions[0].onClick(editorAPI, selectedComponents);
  editorAPI.bi.event(coreBi.events.shortcuts.comp_main_action, {
    componentId: selectedComponents[0].id,
  });
}

export default {
  init(editorAPI: EditorAPI) {
    addEditorShortcuts(editorAPI);
  },
  shortcuts: EDITOR_SHORTCUTS_CONFIG,
};
