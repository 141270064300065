import React from 'react';
import addMediaSection from './sections/addMediaSection';
import siteFilesSection from './sections/siteFilesSection';
import wixMediaIllustrationsSection from './sections/wixMediaIllustrationsSection';
import wixMediaImagesSection from './sections/wixMediaImagesSection';
import wixMediaVideosSection from './sections/wixMediaVideosSection';
import shutterstockImagesSection from './sections/shutterstock/shutterstockImagesSection';
import shutterstockVideosSection from './sections/shutterstock/shutterstockVideosSection';
import * as mediaPanelConsts from '../mediaManagerPanelConstants/mediaManagerPanelConstants';

const { MEDIA_MANAGER_PANEL_SECTION_ID } = mediaPanelConsts;

const modules = initModules();

function initModules() {
  const tmp: Record<string, AnyFixMe> = {};

  tmp[MEDIA_MANAGER_PANEL_SECTION_ID.ADD_MEDIA] = addMediaSection;
  tmp[MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES] = siteFilesSection;
  tmp[MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES] = wixMediaImagesSection;
  tmp[MEDIA_MANAGER_PANEL_SECTION_ID.WIX_ILLUSTRATIONS] =
    wixMediaIllustrationsSection;
  tmp[MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS] = wixMediaVideosSection;
  tmp[MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_IMAGES] =
    shutterstockImagesSection;
  tmp[MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_VIDEOS] =
    shutterstockVideosSection;

  return tmp;
}

interface DynamicMediaSectionBaseGeneratorProps {
  id: string;
}

class DynamicMediaSectionBaseGenerator extends React.Component<
  DynamicMediaSectionBaseGeneratorProps & AnyFixMe
> {
  render() {
    return React.createElement(
      modules[this.props.id as keyof typeof modules],
      this.props,
      null,
    );
  }
}

export default DynamicMediaSectionBaseGenerator;
