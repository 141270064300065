import React, { useCallback } from 'react';

import * as panels from '@/panels';

import { hoc, media } from '@/util';

import { GalleryCard, Text, InfoIcon } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import {
  type InstallerAppsPanelDispatchProps,
  mapDispatchToProps,
} from './installerAppsPanelMapper';
import styles from './installerAppsPanel.scss';
import { Header } from './Header/Header';
import type { InstallerAppsData } from '@wix/editor-platform-host-integration-apis';

export interface PanelResolveType {
  status: 'mainActionClicked' | 'secActionClicked' | 'closeActionClicked';
}

export interface InstallerAppsPanelOwnProps {
  onPanelClose(arg0: PanelResolveType): void;
  panelName: string;
  dependenciesData: InstallerAppsData[];
  appToInstallName: string;
}

interface InstallerAppsPanelProps
  extends InstallerAppsPanelOwnProps,
    InstallerAppsPanelDispatchProps {}

const defaultIcon = media.getMediaUrl(
  'platform/widgetSection/widget-color-3.square.png',
);

const InstallerAppsPanelComponent: React.FC<InstallerAppsPanelProps> = ({
  panelName,
  dependenciesData,
  appToInstallName,
  openHelpCenter,
  closePanel,
}) => {
  const getKeyboardShortcuts = useCallback(
    () => ({
      esc: () => closePanel({ status: 'closeActionClicked' }),
    }),
    [closePanel],
  );

  const closeClick = useCallback(
    () => closePanel({ status: 'closeActionClicked' }),
    [closePanel],
  );

  const mainClick = useCallback(() => {
    closePanel({
      status: 'mainActionClicked',
    });
  }, [closePanel]);

  const renderContent = () => (
    <span>
      <Header
        appToInstallName={appToInstallName}
        dependenciesData={dependenciesData}
      />
      <div className={styles.galleryWrapper}>
        {dependenciesData.map((app) => {
          return (
            <GalleryCard
              dataHook="card-info"
              key={`card-${app.appDefId}`}
              width="268px"
              title={
                <Text size="small" shouldTranslate={false}>
                  {app?.label}
                </Text>
              }
              titleSuffix={
                app?.info && (
                  <InfoIcon
                    dataHook="info"
                    shouldTranslate={false}
                    text={app.info}
                  />
                )
              }
            >
              <img
                src={app?.appIconUrl || defaultIcon}
                className={styles.img}
              />
            </GalleryCard>
          );
        })}
      </div>
    </span>
  );

  return (
    <panels.frames.CustomPanelFrame
      dataHook="platform-installer-apps-panel"
      className={styles.platformInstallerAppsPanel}
      panelName={panelName}
      onHelpButtonClick={openHelpCenter}
      onCloseButtonClick={closeClick}
      keyboardShortcuts={getKeyboardShortcuts()}
      onPrimaryButtonClick={mainClick}
      primaryButtonText={translate(
        'PLATFORM_AppInstall_Modal_Button_Add_to_Site',
      )}
      theme="standard"
    >
      {renderContent()}
    </panels.frames.CustomPanelFrame>
  );
};

const connect = hoc.connect(hoc.STORES.EDITOR_API, null, mapDispatchToProps);

export default connect(InstallerAppsPanelComponent);
