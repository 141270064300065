import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import type { MouseEvent } from 'react';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

export function createSelectionKeyboardAndMouseApi({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  const { translateToViewerCoordinates } =
    stateManagement.domMeasurements.selectors;
  const { browserUtil } = util;
  let isMouseUpSelectionEnabled: boolean = false;

  function setIsMouseUpSelectionEnabled(isMouseUpEnabled: boolean): void {
    isMouseUpSelectionEnabled = isMouseUpEnabled;
  }

  function getIsMouseUpSelectionEnabled(): boolean {
    return isMouseUpSelectionEnabled;
  }

  function getViewerMouseCoordinates(event: MouseEvent) {
    return translateToViewerCoordinates(editorAPI, event);
  }

  function isMultiSelectKeyPressed(event: MouseEvent): boolean {
    return event.shiftKey;
  }

  function isDirectSelection(event: MouseEvent) {
    return (
      browserUtil.isLeftClick(event) && browserUtil.isSpecialKeyPressed(event)
    );
  }

  function onSelectComponentsByRef(compsToBeSelected: CompRef[]) {
    if (editorAPI.components.layout.isPinned(compsToBeSelected)) {
      util.keyboardShortcuts.setContext(
        util.keyboardShortcuts.CONTEXTS.PIN_MODE,
      );
    } else if (
      util.keyboardShortcuts.getContext() ===
      util.keyboardShortcuts.CONTEXTS.PIN_MODE
    ) {
      util.keyboardShortcuts.setContext(util.keyboardShortcuts.CONTEXTS.EDITOR);
    }
  }

  function onDeselectComponents(_compRef?: CompRef | CompRef[]) {
    if (
      util.keyboardShortcuts.getContext() ===
      util.keyboardShortcuts.CONTEXTS.PIN_MODE
    ) {
      util.keyboardShortcuts.setContext(util.keyboardShortcuts.CONTEXTS.EDITOR);
    }
  }

  return {
    setIsMouseUpSelectionEnabled,
    getIsMouseUpSelectionEnabled,
    getViewerMouseCoordinates,
    isMultiSelectKeyPressed,
    isDirectSelection,
    isLeftClick: browserUtil.isLeftClick,
    isRightClick: browserUtil.isRightClick,
    onSelectComponentsByRef,
    onDeselectComponents,
  };
}

export type SelectionKeyboardAndMouseApi = ReturnType<
  typeof createSelectionKeyboardAndMouseApi
>;
