import React from 'react';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import * as panels from '@/panels';
import * as symbols from '@wix/santa-editor-symbols';
import ListParser, { type PanelTag } from '../ListParser/listParser';
import { Text } from '@wix/wix-base-ui';

class PlatformConfirmationPanel extends React.Component<PlatformConfirmationPanelProps> {
  getKeyboardShortcuts = () => ({
    esc: () => this.props.closePanel(PanelResolveType.CLOSE_ACTION),
  });

  secClick = (origin?: string) => {
    if (origin === panels.PanelCloseOrigin.HEADER_CLOSE_BUTTON) {
      return this.props.closePanel(PanelResolveType.CLOSE_ACTION);
    }
    this.props.closePanel(PanelResolveType.SECONDARY_ACTION);
  };

  mainClick = () => {
    this.props.closePanel(PanelResolveType.MAIN_ACTION);
  };

  getIllustration = () =>
    this.props.illustration ? (
      <img
        src={this.props.illustration}
        data-aid="illustration"
        className="confirmation-panel-illustration"
      />
    ) : (
      <symbols.symbol
        name={this.props.symbol ?? 'default-confirmation'}
        className="svg-symbol"
      />
    );

  getFootnote = () =>
    this.props.footnote?.footnoteText ? (
      <Text
        size="small"
        skin="secondary"
        shouldTranslate={false}
        enableEllipsis={false}
      >
        {this.props.footnote.footnoteText}
        {this.props.footnote.urlText && this.props.footnote.urlLink && (
          <>
            {' '}
            {
              <a
                data-hook="footnote-link"
                href={this.props.footnote.urlLink}
                target="_blank"
              >
                {this.props.footnote.urlText}
              </a>
            }
          </>
        )}
      </Text>
    ) : undefined;

  render() {
    return (
      <panels.frames.MessagePanelFrame
        dataHook="platform-confirmation-panel"
        panelName={this.props.panelName}
        onConfirm={this.mainClick}
        confirmLabel={this.props.mainActionText}
        title={this.props.headerText}
        illustration={
          this.props.shouldShowIllustration ? this.getIllustration() : undefined
        }
        onHelp={this.props.helpId && this.props.openHelpCenter}
        onCancel={this.secClick}
        cancelLabel={this.props.secondaryActionText}
        sideActions={this.props.sideActions}
        confirmBtnProps={this.props.mainActionProps}
        dontShowAgainKey={this.props.dontShowAgainKey}
        userPrefType={this.props.userPrefType}
        enableOnApproveAction={this.props.enableOnApproveAction}
        footnote={this.getFootnote()}
      >
        <ListParser description={this.props.descriptionText} />
      </panels.frames.MessagePanelFrame>
    );
  }
}

const mapDispatchToProps = (dispatch: AnyFixMe, ownProps: AnyFixMe) => {
  const closePanel = (origin: AnyFixMe) => {
    dispatch(
      stateManagement.panels.actions.closePanelByNameAction(ownProps.panelName),
    );
    ownProps.onPanelClose(origin);
  };

  const openHelpCenter = () =>
    dispatch(stateManagement.panels.actions.openHelpCenter(ownProps.helpId));

  return {
    closePanel,
    openHelpCenter,
  };
};

enum PanelResolveType {
  MAIN_ACTION = 'mainActionClicked',
  SECONDARY_ACTION = 'secActionClicked',
  CLOSE_ACTION = 'closeActionClicked',
}

interface PlatformConfirmationPanelProps {
  closePanel(panelResolveType: PanelResolveType): void;
  helpId?: string;
  openHelpCenter(): void;
  headerText: string;
  illustration?: string;
  symbol?: string;
  descriptionText: string | PanelTag[];
  secondaryActionText?: string;
  mainActionText: string;
  onPanelClose(): void;
  panelName: string;
  shouldShowIllustration: boolean;
  sideActions?: React.ReactNode;
  mainActionProps?: object;
  dontShowAgainKey?: string;
  userPrefType?: string;
  enableOnApproveAction?: boolean;
  footnote?: ConfirmationPanelFootnote;
}

export interface ConfirmationPanelFootnote {
  footnoteText: string;
  urlText?: string;
  urlLink?: string;
}

export default util.hoc.connect(
  util.hoc.STORES.DS_ONLY,
  null,
  mapDispatchToProps,
)(PlatformConfirmationPanel);
