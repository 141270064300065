._app-details-header_7phty_1 {
  display: flex;
  width: 100%;
  align-items: center; }

._left-container_7phty_6 {
  flex: 1;
  margin: 24px; }

._right-container_7phty_10 {
  display: flex;
  align-items: center;
  margin-right: 20px; }

._version-container_7phty_15 {
  margin-right: 18px; }

._actions-container_7phty_18 {
  display: flex;
  align-items: center; }
  ._actions-container_7phty_18 > div {
    margin: 6px; }
