import { BaseEntryScope, createEntryPoint } from '@/apilib';
import {
  EditorAPIKey,
  EditorCoreApiKey,
  PreviewApiKey,
  SelectionApiKey,
  WorkspaceModesApiKey,
} from '@/apis';
import {
  waitForDSReady,
  registerExitPreview,
  registerDeviceChange,
  registerToGoToPreview,
  registerSelectByCompRefInterceptor,
} from './utils';
import {
  WorkspaceModesApi,
  isWorkspaceModesAvailable,
  initWorkspaceMode,
} from './workspaceModesApi';
import { WorkspaceModesStore } from './workspaceModesStore';

export class WorkspaceModesScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
  previewAPI = this.useDependency(PreviewApiKey);
  selectionAPI = this.useDependency(SelectionApiKey);
  store = this.declareStore(WorkspaceModesStore);
}

export const WorkspaceModesEntryPoint = createEntryPoint({
  name: 'WorkspaceModes',
  Scope: WorkspaceModesScope,
  publicApi({ contributeApi }) {
    contributeApi(WorkspaceModesApiKey, WorkspaceModesApi);
  },
  async initialize(scope: WorkspaceModesScope) {
    await waitForDSReady(scope);
    if (!isWorkspaceModesAvailable(scope)) {
      scope.editorCoreAPI.hooks.workspaceModesInit.resolve();
      return;
    }
    initWorkspaceMode(scope);
    registerToGoToPreview(scope);
    registerExitPreview(scope);
    registerDeviceChange(scope);
    registerSelectByCompRefInterceptor(scope);
  },
});
