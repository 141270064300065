import experiment from 'experiment';

function isPromotionalExperimentOn(superAppId: string) {
  return Boolean(
    {
      wixBookings: true,
      blog: true,
      newBlog: true,
      wixStore: false,
    }[superAppId],
  );
}

function isWaitForClickExperimentOn(superAppId: string) {
  return Boolean(
    {
      blog: true,
      wixStore: true,
    }[superAppId],
  );
}

function isLearnMoreExperimentOn() {
  return experiment.isOpen('SuperAppLearnMorePanelView');
}

export {
  isPromotionalExperimentOn,
  isLearnMoreExperimentOn,
  isWaitForClickExperimentOn,
};
