import _ from 'lodash';
import React from 'react';
import * as mediaPanelConsts from '../../mediaManagerPanelConstants/mediaManagerPanelConstants';
import WixMediaBaseSection from './wixMediaSectionBase';

interface Props {}

export const WixMediaIllustrationsSection: React.FC<Props> = (passedProps) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  const props = _.assign(
    {
      title: 'add_section_heading_wix_illustrations',
      mediaRoot: mediaPanelConsts.PUBLIC_MEDIA_ROOT.ILLUSTRATIONS,
    },
    passedProps,
  );
  return <WixMediaBaseSection {...props} />;
};

export default WixMediaIllustrationsSection;
