import type { EditorAPI } from '@/editorAPI';
import type { ComponentsAPI } from './scanTypes';
import type { CompRef } from 'types/documentServices';
import {
  SECTION_REGULAR,
  SECTION_LIST,
  SECTION_REPEATER,
  SECTION_SLIDESHOW,
  regularComponentsContentTags,
  LIST_ITEM,
  LIST,
  SLIDESHOW,
  REPEATER,
  REGULAR,
  SLIDESHOW_SLIDE,
  SLIDESHOW_ITEM,
  IMAGE,
  TITLE,
  SUBTITLE,
  LONGTEXT,
  InjectionPropNameMap,
  CaasPropNameMap,
} from './scanConsts';
import type { ComponentDataForContentInjection } from './scanTypes';
import type {
  ContentInjectionImage,
  RegularContentInjectionValues,
} from './scanTypes';

const baseContentTagging = [
  SECTION_REGULAR,
  SECTION_LIST,
  SECTION_REPEATER,
  SECTION_SLIDESHOW,
];

export function isTaggedComponent(contentTag: string) {
  if (!contentTag) {
    return false;
  }
  const tagWithoutNums = removeNumsFromString(contentTag);
  return baseContentTagging.some((startStr: string) =>
    tagWithoutNums.includes(startStr),
  );
}

export function isListItemTag(contentTag: string) {
  return contentTag.includes(LIST_ITEM);
}

export function removeRulesFromTag(tag: string) {
  return tag.split('Rule')[0];
}

export function getRulesListFromTag(tag: string) {
  return tag.split('Rule').filter((_, idx) => idx !== 0);
}

export function isRuleIncluded(
  componentsAPI: ComponentsAPI,
  compRef: CompRef,
  includedRule: string,
) {
  const contentTag = componentsAPI.code.getNickname(compRef);
  const RuleListFromContentTag = getRulesListFromTag(contentTag);
  return RuleListFromContentTag.some((rule) => rule === includedRule);
}

export function getContentTagLastPart(tag: string) {
  const tagWithNum = splitComponentTags(tag);
  return removeNumsFromString(tagWithNum[tagWithNum.length - 1]);
}

export function splitComponentTags(tag: string) {
  return tag
    .replace(/([0-9]?[0-9])/g, '$1 ')
    .trim()
    .split(' ');
}

export function getComponentTagNumber(tag: string): number {
  return +tag.replace(/([^0-9])/g, '');
}

export function getListItemNumber(contentTags: string[]) {
  const listItem = contentTags.find(
    (tag) => tag.includes(LIST_ITEM) || tag.includes(SLIDESHOW_ITEM),
  );
  return listItem ? getComponentTagNumber(listItem) - 1 : undefined;
}

export function removeNumsFromString(str: string) {
  return str.replace(/\d+/g, '');
}

export function getCeType(
  componentsAPI: ComponentsAPI,
  compRef: CompRef,
): string {
  return componentsAPI.features.get(compRef, 'contentRole')?.contentRole;
}

export function getComponentData(editorApi: EditorAPI, compRef: CompRef) {
  return editorApi.components.data.get(compRef);
}

export function getCompRefsIncludingParent(
  componentsAPI: ComponentsAPI,
  compRef: CompRef,
): CompRef[] {
  return [
    compRef,
    ...componentsAPI.getChildren_DEPRECATED_BAD_PERFORMANCE(compRef, true),
  ];
}

export function isEmptyObject(obj: AnyFixMe) {
  return Object.keys(obj).length === 0;
}

export function isTextContentType(contentTag: string) {
  const textTagList = [TITLE, SUBTITLE, LONGTEXT];
  for (const textTag of textTagList) {
    if (contentTag.includes(textTag)) {
      return true;
    }
  }
  return false;
}

export function isImageContentType(contentTag: string) {
  return contentTag.includes(IMAGE);
}

export function isButtonContentType(contentTag: string) {
  return contentTag.includes(regularComponentsContentTags.BUTTON);
}

export function getComponentDataFromSerialize(
  componentsAPI: ComponentsAPI,
  compRef: CompRef,
) {
  return {
    contentTag: componentsAPI.code.getNickname(compRef),
    compRef,
    data: componentsAPI.data.get(compRef),
    type: componentsAPI.getType(compRef),
    design: componentsAPI.design.get(compRef),
  };
}

export function isSlideShowSlide(component: any) {
  return component.type.includes(SLIDESHOW_SLIDE) && !!component.design;
}

export function addComponentDataToCompRef(
  componentsAPI: ComponentsAPI,
  components: CompRef[],
) {
  return components.map((compRef) =>
    getComponentDataFromSerialize(componentsAPI, compRef),
  );
}

export function filterContentTaggedComponents(
  components: ComponentDataForContentInjection[],
) {
  return components.filter(
    (item) =>
      isTaggedComponent(item.contentTag) || item.type.includes(SLIDESHOW_SLIDE),
  );
}

export function getSectionContentType(contentTag: string) {
  const contentTagCategoryList = [REGULAR, LIST, REPEATER, SLIDESHOW];
  for (const contentTagCategory of contentTagCategoryList) {
    if (contentTag.includes(contentTagCategory)) {
      return contentTagCategory;
    }
  }
}

export function convertContentTagPropKeyToCaasPropKey(
  contentTypePropKey: string,
) {
  if (contentTypePropKey.includes(InjectionPropNameMap.TITLE)) {
    return CaasPropNameMap.TITLE;
  } else if (contentTypePropKey.includes(InjectionPropNameMap.SUBTITLE)) {
    return CaasPropNameMap.SUBTITLE;
  } else if (contentTypePropKey.includes(InjectionPropNameMap.LONGTEXT)) {
    return CaasPropNameMap.LONGTEXT;
  } else if (contentTypePropKey.includes(InjectionPropNameMap.MEDIA_IMAGE)) {
    return CaasPropNameMap.MEDIA;
  } else if (contentTypePropKey.includes(InjectionPropNameMap.BUTTON)) {
    return CaasPropNameMap.BUTTON;
  }
  return contentTypePropKey;
}

export function addInjectionByTypeToInjectableMap(
  injectionPerComponents: RegularContentInjectionValues,
  injectionPropName: keyof RegularContentInjectionValues,
  injectionMapByTagType: RegularContentInjectionValues,
) {
  if (injectionPropName === CaasPropNameMap.MEDIA) {
    injectionPerComponents[injectionPropName] =
      injectionPerComponents[injectionPropName] || [];
    return [
      ...injectionPerComponents[injectionPropName],
      injectionMapByTagType,
    ] as ContentInjectionImage[];
  }
  return injectionMapByTagType as string;
}
