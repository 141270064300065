import React from 'react';
import { Preloader } from '@wix/wix-base-ui';
import styles from './privateAppsPanel.scss';
import { AppDetails } from './components/appDetails/appDetails';
import EmptyPrivateAppsPanel from './components/EmptyState/EmptyState';
import AppVersionsList from './components/AppVersionsList/AppVersionsList';
import {
  ListView,
  usePrivateApps,
} from './components/privateAppsProvider/privateAppsProvider';
import { PrivateAppsLists } from './components/privateAppsLists/privateAppsLists';

const PrivateAppsPanel: React.FC = () => {
  const { installedApps, availableApps, isLoading, selectedListView } =
    usePrivateApps();

  const LeftContainer = React.useMemo(
    // eslint-disable-next-line react/display-name
    () => () =>
      selectedListView === ListView.AppVersions ? (
        <AppVersionsList />
      ) : (
        <PrivateAppsLists />
      ),
    [selectedListView],
  );

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Preloader className={'medium'} />
      </div>
    );
  }

  if (availableApps.length === 0 && installedApps.length === 0) {
    return <EmptyPrivateAppsPanel />;
  }

  return (
    <div className={styles.privateAppsContainer} data-hook="private-apps-panel">
      <div className={styles.leftContainer}>
        <LeftContainer />
      </div>
      <div className={styles.rightContainer}>
        <AppDetails />
      </div>
    </div>
  );
};

export default PrivateAppsPanel;
