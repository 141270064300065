import React from 'react';
import ReactDOM from 'react-dom';
import experiment from 'experiment';
import { WixBaseUiEnvironmentProvider } from '@wix/wix-base-ui';

const portalRoot = window.document.body;

interface DropDownPortalProps {}

const DropDownPortal: React.FC<DropDownPortalProps> = (props) => {
  const component = (
    <WixBaseUiEnvironmentProvider
      madefor
      theme="facelift"
      inputBorders={experiment.isOpen(
        'se_components_editorClassic_inputBorders',
      )}
    >
      <div className="top-bar-drop-down-portal-wrapper">{props.children}</div>
    </WixBaseUiEnvironmentProvider>
  );
  return ReactDOM.createPortal(component, portalRoot);
};

export default DropDownPortal;
