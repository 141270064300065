import { createReduxStore } from '@/apilib';

export enum ZoomDirection {
  IN = 'in',
  OUT = 'out',
}

interface State {
  enableCompPanels: boolean;
  isTransitioning: boolean;
  enterOrigin: string;
  enableRightClickMenu: boolean;
  transitionPromise: Promise<void>;
  zoomTransitionDirection: ZoomDirection;
  isZoomedByUser: boolean;
}

const getInitialState = (): State => ({
  enableCompPanels: false,
  isTransitioning: false,
  enterOrigin: null,
  enableRightClickMenu: true,
  transitionPromise: null,
  zoomTransitionDirection: null,
  isZoomedByUser: false,
});

export const Store = createReduxStore({
  getInitialState,
  selectors: {
    isZoomModeTransitionActive: (state) => state.isTransitioning,
    getZoomModeEnterOrigin: (state) => state.enterOrigin,
    getTransitionPromise: (state) => state.transitionPromise,
    getZoomModeEnabledCompPanels: (state) => state.enableCompPanels,
    getZoomTransitionDirection: (state) => state.zoomTransitionDirection,
    getZoomModeEnabledRightClickMenu: (state) => state.enableRightClickMenu,
    isZoomedByUser: (state) => state.isZoomedByUser,
  },
  actions: {
    zoomTransitionStart: (state) => ({ ...state, isTransitioning: true }),
    zoomTransitionEnd: (state) => ({ ...state, isTransitioning: false }),
    setTransitionPromise: (state, transitionPromise) => ({
      ...state,
      transitionPromise,
    }),
    setZoomModeEnabledCompPanels: (state, enableCompPanels: boolean) => ({
      ...state,
      enableCompPanels,
    }),
    setZoomTransitionDirection: (
      state,
      zoomTransitionDirection: ZoomDirection,
    ) => ({
      ...state,
      zoomTransitionDirection,
    }),
    setZoomModeEnabledRightClickMenu: (
      state,
      enableRightClickMenu: boolean,
    ) => ({
      ...state,
      enableRightClickMenu,
    }),
    setZoomModeEnterOrigin: (state, enterOrigin: string) => ({
      ...state,
      enterOrigin,
    }),
    setIsZoomedByUser: (state, isZoomedByUser: boolean) => ({
      ...state,
      isZoomedByUser,
    }),
  },
});
