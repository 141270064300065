import React, { memo, type FC } from 'react';
import { TextInput, Composites, TextLabel } from '@wix/wix-base-ui';

export interface RegularTextInputProps {
  label: string;
  placeholder?: string;
  value: string;
  invalidMessage?: string;
  onChange: (value: string) => void;
  onBlur?(e: React.FocusEvent<HTMLInputElement>, isCanceled: boolean): void;
}

const RegularTextInput: FC<RegularTextInputProps> = memo(
  function RegularTextInput({
    label,
    placeholder,
    value,
    invalidMessage,
    onChange,
    onBlur,
  }) {
    return (
      <Composites.TextInputLabeled>
        <TextLabel type="T02" value={label} />
        <TextInput
          automationId="ai-injection-into-template-business-name"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          isValid={!invalidMessage}
          invalidMessage={invalidMessage}
          placeholder={placeholder}
          hideSuccessIndication
        />
      </Composites.TextInputLabeled>
    );
  },
);

export { RegularTextInput };
