// @ts-nocheck
import _ from 'lodash';
import * as core from '@/core';
import constants from '@/constants';
import * as addPanelUtils from '../addPanelUtils';
import { sections } from '@/util';
import AddPanelSectionDragHandler from './AddPanelSectionDragHandler';
import AddPanelDragHandler from './AddPanelDragHandler';
import { AddSectionDragHandler } from './AddSectionDragHandler';

let dragHandler;

export default {
  type: constants.MOUSE_ACTION_TYPES.ADD_PANEL_DRAG_TO_STAGE,
  start: function startDrag(editorAPI, params) {
    const shouldApplySectionDrag =
      !sections.isSectionsEnabled() &&
      (addPanelUtils.isStripStructure(editorAPI, params.item.structure) ||
        params?.item?.tags?.isFullWidth) &&
      !_.isEmpty(core.utils.sectionsReorganizeUtil.getPageSections(editorAPI));

    const shouldApplyAddSectionDrag = sections.isSectionsEnabled();

    let DragHandlerClass = AddPanelDragHandler;

    if (shouldApplySectionDrag) {
      DragHandlerClass = AddPanelSectionDragHandler;
    } else if (shouldApplyAddSectionDrag) {
      DragHandlerClass = AddSectionDragHandler;
    }

    dragHandler = new DragHandlerClass(
      editorAPI,
      params.evt,
      params.item,
      params.panelElm,
      params.dragBoxClass,
      params.onItemDragStart,
      params.onItemDragEnd,
    );
  },
  on: function onDrag(e) {
    dragHandler.onDrag(e);
  },
  end: function endDrag(e) {
    if (!dragHandler) {
      return;
    }
    dragHandler.endDrag(e);
    dragHandler = null;
  },
};
