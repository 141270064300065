:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._addPageBulletsContent_4eex5_7 {
  padding: 4px; }
  ._addPageBulletsContent_4eex5_7 div {
    color: #3b4057; }

._addPageBulletsInfo_4eex5_12 {
  color: #3b4057;
  margin: 9px 0;
  display: flex;
  align-items: center; }

._addPageBulletSvgContainer_4eex5_18 {
  width: 22px;
  display: flex;
  justify-content: center; }
  ._addPageBulletSvgContainer_4eex5_18 svg {
    margin-right: 6px; }
