import React from 'react';
import GeneralContent from './generalContent';
import type { GeneralContentProps } from './generalContent';
import { fedopsLogger, workspace } from '@/util';

import type { InnerPanelProps } from './types';
import { SiteWasSavedPanelCloseSource } from '../save/siteWasSavedPanel';
import type { PublishInteractionStartedSource } from 'types/fedops/publishInteraction';

export default class PublishContent extends React.Component<InnerPanelProps> {
  getClassName() {
    return `publish-site-content${
      this.props.className ? ` ${this.props.className}` : ''
    }`;
  }

  getGeneralContentProps(): GeneralContentProps {
    return {
      symbolName: workspace.isNewWorkspaceEnabled()
        ? 'publishSite_NewWorkspace'
        : 'publishSite',
      mainTitle: 'SAVE_SITE_SAVED_WHATS_NEXT_LABEL',
      title: 'SAVE_SITE_SAVED_WHATS_NEXT_PUBLISH_LINE',
      actionLabel: 'SAVE_SITE_SAVED_WHATS_NEXT_PUBLISH_LINK',
      actionCallback: this.onPublishSiteClick,
    };
  }

  onPublishSiteClick = () => {
    const sourceOfStart: PublishInteractionStartedSource =
      'saveSuccessPanel_innerPanel_publish_publishLink';

    // NOTE: https://github.com/wix-private/santa-editor/issues/37029
    fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.PUBLISH, {
      paramsOverrides: {
        sourceOfStart,
      },
    });

    this.props.closePanel({
      closeSource: SiteWasSavedPanelCloseSource.InnerPanelPublishPublishLink,
    });
    void this.props.publishMethod({
      sourceOfStart,
    });
    if (this.props.actionCallback) {
      this.props.actionCallback();
    }
  };

  render() {
    return (
      <GeneralContent
        className={this.getClassName()}
        {...this.getGeneralContentProps()}
      />
    );
  }
}
