import { ErrorReporter } from '@wix/editor-error-reporter';

export default class HtmlPreviewThumbnailManager {
  private htmlString: string;
  private readonly htmlStringPromise: Promise<string>;

  constructor(
    previewHtml?: string,
    previewHtmlUrl?: string,
    previewPath: string = 'previewHtml',
  ) {
    if (previewHtml) {
      this.htmlString = previewHtml;
      this.htmlStringPromise = Promise.resolve(previewHtml);
    } else if (previewHtmlUrl) {
      this.htmlStringPromise = fetch(previewHtmlUrl)
        .then((res) => res.blob())
        .then((blob: Blob) => {
          return new Promise((resolve) => {
            const fr = new FileReader();
            fr.onload = (event: any) => {
              if (event.target.readyState === 2) {
                this.htmlString = JSON.parse(event.target.result)[previewPath];
                resolve(this.htmlString);
              }
            };
            fr.readAsText(blob);
          });
        });
    } else {
      ErrorReporter.captureException(
        new Error('HtmlPreviewThumbnailManager without any params'),
        {
          tags: { htmlPreviewThumbnailParams: true },
        },
      );
    }
  }

  get previewHtml(): string {
    return this.htmlString;
  }

  get previewHtmlPromise(): Promise<string> {
    return this.htmlStringPromise;
  }
}
