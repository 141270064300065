'use strict'

module.exports = {
    id: 'add_preset_popups_Contact_3',
    structure: {
        type: 'Page',
        id: 'a3ki2',
        componentTypeForBI: 'wysiwyg.viewer.components.PopupContainer',
        components: [
            {
                type: 'Container',
                id: 'comp-im22i2b5',
                components: [
                    {
                        type: 'Component',
                        id: 'comp-im22i2bn',
                        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                        layout: {
                            width: 887,
                            height: 73,
                            x: 0,
                            y: 126,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<h2 class="font_2" style="font-size:56px; text-align:center"><span style="color:#4CD1A0;"><span style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif"><span style="letter-spacing:5.6px">HOLLER AT US</span></span></span></h2>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: [],
                            id: 'dataItem-im22i2bn1'
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true,
                            id: 'propItem-ipf5io5h'
                        },
                        style: 'txtNew'
                    },
                    {
                        type: 'Component',
                        id: 'comp-im22t203',
                        skin: 'svgshape.v2.Svg_ac57ac960ceb4f22bb96208bcfa86bad',
                        layout: {
                            width: 90,
                            height: 79,
                            x: 399,
                            y: 35,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.svgshape.SvgShape',
                        data: {
                            type: 'SvgShape',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            id: 'dataItem-im22t208'
                        },
                        props: {
                            type: 'SvgShapeProperties',
                            metaData: {schemaVersion: '1.0'},
                            maintainAspectRatio: true,
                            id: 'propItem-im22t209'
                        },
                        style: {
                            type: 'TopLevelStyle',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            style: {
                                groups: {},
                                properties: {
                                    'alpha-fillcolor': '1',
                                    'alpha-stroke': '0',
                                    fillcolor: '#4CD1A0',
                                    stroke: '#163E3A',
                                    strokewidth: '0'
                                },
                                propertiesSource: {
                                    'alpha-fillcolor': 'value',
                                    'alpha-stroke': 'value',
                                    fillcolor: 'value',
                                    stroke: 'value',
                                    strokewidth: 'value'
                                }
                            },
                            componentClassName: 'wysiwyg.viewer.components.svgshape.SvgShape',
                            pageId: '',
                            compId: '',
                            styleType: 'custom',
                            skin: 'svgshape.v2.Svg_ac57ac960ceb4f22bb96208bcfa86bad',
                            id: 'style-iqkqb37j'
                        }
                    },
                    {
                        type: 'Component',
                        id: 'comp-im22y86b',
                        layout: {
                            width: 421,
                            height: 15,
                            x: 18,
                            y: 448,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<p class="font_8" style="font-size:12px"><span style="color:#000000;"><span style="font-size:12px"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">500 Terry Francois Street, San Francisco, CA 94158</span></span></span></p>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: [],
                            id: 'dataItem-im22y86f'
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true,
                            id: 'propItem-im22y86g'
                        },
                        style: 'txtNew'
                    },
                    {
                        type: 'Component',
                        id: 'comp-im22i2bq',
                        skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
                        layout: {
                            width: 74,
                            height: 22,
                            x: 187,
                            y: 206,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.LinkBar',
                        data: {
                            type: 'ImageList',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            items: [
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'Black Facebook Icon',
                                    uri: '0fdef751204647a3bbd7eaa2827ed4f9.png',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'https://www.facebook.com/wix',
                                        target: '_blank'
                                    }
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'Black Twitter Icon',
                                    uri: 'c7d035ba85f6486680c2facedecdcf4d.png',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'https://twitter.com/wix',
                                        target: '_blank'
                                    }
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    title: 'Black Instagram Icon',
                                    uri: '01c3aff52f2a4dffa526d7a9843d46ea.png',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'https://www.instagram.com/wix/',
                                        target: '_blank'
                                    }
                                }
                            ],
                            id: 'dataItem-im22i2br'
                        },
                        props: {
                            type: 'LinkBarProperties',
                            metaData: {schemaVersion: '1.0'},
                            gallery: 'social_icons',
                            iconSize: 22,
                            spacing: 4,
                            bgScale: 1,
                            orientation: 'HORIZ',
                            id: 'propItem-im22i2by'
                        },
                        style: 'lb1'
                    },
                    {
                        type: 'Component',
                        id: 'comp-im232ifz',
                        layout: {
                            width: 435,
                            height: 21,
                            x: 280,
                            y: 208,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<p class="font_8" style="font-size:16px"><span style="color:#000000"><span style="font-size:16px"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Tel: 123-456-7890 &nbsp;| &nbsp;Fax: 123-456-7890 &nbsp;| &nbsp;Info@mysite.com &nbsp;</span></span></span></p>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: [],
                            id: 'dataItem-im232ig3'
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true,
                            id: 'propItem-im232ig4'
                        },
                        style: 'txtNew'
                    },
                    {
                        'type': 'Component',
                        'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744',
                        'layout': {
                            'width': 30,
                            'height': 30,
                            'x': 838,
                            'y': 25,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'fixedPosition': false
                        },
                        'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'parent': 'comp-im22i2b5',
                        'metaData': {
                            'pageId': 'a3ki2'
                        },
                        'data': {
                            'type': 'SvgShape',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'a3ki2'
                            }
                        },
                        'props': {
                            'type': 'SvgShapeProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'pageId': 'a3ki2'
                            },
                            'maintainAspectRatio': true
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'a3ki2'
                            },
                            'style': {
                                'properties': {
                                    'alpha-fillcolor': '1',
                                    'alpha-stroke': '1',
                                    'fillcolor': '#000000',
                                    'stroke': '#FFFFFF',
                                    'strokewidth': '1'
                                },
                                'propertiesSource': {
                                    'alpha-fillcolor': 'value',
                                    'alpha-stroke': 'value',
                                    'fillcolor': 'value',
                                    'stroke': 'value',
                                    'strokewidth': 'value'
                                },
                                'groups': {}
                            },
                            'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744'
                        },
                        'connections': {
                            'type': 'ConnectionList',
                            'items': [
                                {
                                    'type': 'WixCodeConnectionItem',
                                    'role': 'button2'
                                }
                            ],
                            'metaData': {
                                'pageId': 'a3ki2'
                            }
                        },
                        'mobileStructure': {
                            'layout': {
                                'width': 24,
                                'height': 24,
                                'x': 258,
                                'y': 23,
                                'scale': 1,
                                'rotationInDegrees': 0,
                                'fixedPosition': false
                            },
                            'metaData': {
                                'originalCompId': 'comp-isrei7g6',
                                'author': 'duplicate'
                            }
                        },
                        'activeModes': {}
                    }
                ],
                skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                layout: {
                    width: 888,
                    height: 476,
                    x: 46,
                    y: 151,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.PopupContainer',
                props: {
                    type: 'PopupContainerProperties',
                    metaData: {schemaVersion: '1.0'},
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center',
                    alignmentType: 'nineGrid',
                    horizontalOffset: 0,
                    verticalOffset: 0,
                    id: 'propItem-im22i2bb'
                },
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#FFFFFF',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    id: 'dataItem-im22i2bc'
                },
                style: {
                    type: 'TopLevelStyle',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    style: {
                        groups: {},
                        properties: {'alpha-bg': '1', bg: 'rgba(255,255,255,1)'},
                        propertiesSource: {'alpha-bg': 'value', bg: 'value'}
                    },
                    componentClassName: 'wysiwyg.viewer.components.PopupContainer',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                    id: 'style-im22i2bd'
                },
                behaviors: {
                    type: 'ObsoleteBehaviorsList',
                    items: '[{"name":"FadeIn","delay":0.1,"duration":0.75,"action":"screenIn","targetId":"","params":{}},{"name":"FadeOut","delay":0.1,"duration":0.75,"action":"exit","targetId":"","params":{}}]',
                    id: 'behavior-iomlweee'
                }
            }
        ],

        skin: 'skins.core.InlineSkin',
        layout: {
            width: 980,
            height: 627,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'mobile.core.components.Page',
        data: {
            type: 'Page',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            title: 'add_preset_popups_Contact_3',
            hideTitle: true,
            icon: '',
            descriptionSEO: '',
            metaKeywordsSEO: '',
            pageTitleSEO: '',
            pageUriSEO: 'copy-of-contact-a3ki2',
            hidePage: true,
            underConstruction: false,
            tpaApplicationId: 0,
            pageSecurity: {requireLogin: false, passwordDigest: '', dialogLanguage: ''},
            isPopup: true,
            indexable: false,
            isLandingPage: false,
            pageBackgrounds: {
                desktop: {
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#000000',
                        colorOpacity: 0.8,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                mobile: {
                    custom: true,
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#000000',
                        colorOpacity: 0.8,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'scroll',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    isPreset: true
                }
            },
            ignoreBottomBottomAnchors: true
        },
        props: {
            type: 'PageProperties',
            metaData: {schemaVersion: '1.0'},
            desktop: {popup: {closeOnOverlayClick: true}},
            mobile: {popup: {closeOnOverlayClick: true}},
            id: 'a3ki2'
        },
        style: 'p1',
        tpaComps: []
    },
    preset: {
        rect: {width: 305, height: 202, x: 10, y: 20},
        label: 'add_preset_popups_Contact_3',
        tags: null
    }
}
