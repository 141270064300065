import type { EditorAPI } from '@/editorAPI';
import { layoutUtils } from '@/layoutUtils';
import { ButtonAlignment } from './constants';
import { FixedStageApiKey } from '@/apis';
import type { CompRef } from 'types/documentServices';

export const getCustomSelectedComponents = (editorAPI: EditorAPI) => {
  const selectedComps = editorAPI.selection.getSelectedComponents();
  if (selectedComps.length > 1) {
    return selectedComps;
  }

  const [firstSelectedComp] = selectedComps;

  if (!firstSelectedComp || editorAPI.components.is.page(firstSelectedComp)) {
    return [];
  }

  return selectedComps;
};

export const getIsAddSectionButtonOverlapsComponent = (
  editorAPI: EditorAPI,
  buttonAlignment: ButtonAlignment,
  sectionRef: CompRef,
) => {
  const selectedComponents = editorAPI.selection.getSelectedComponents();
  const sectionLayout =
    editorAPI.components.layout.getRelativeToScreen(sectionRef);
  const isSelectedComponentSectionLike = editorAPI.sections.isSectionLike(
    selectedComponents[0],
  );

  if (selectedComponents.length !== 1 || isSelectedComponentSectionLike) {
    return false;
  }

  const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);
  const previewWidth = fixedStageAPI.isStageHorizontallyScrollable()
    ? editorAPI.getEditingAreaPosition().width
    : sectionLayout.width;

  const nonOverlappableRect = { width: 260, height: 90, x: 0, y: 0 };
  const { scrollLeft = 0 } = editorAPI.scroll.get();

  const nonOverlappableRectMedialPosition = {
    y:
      buttonAlignment === ButtonAlignment.TOP
        ? sectionLayout.y
        : sectionLayout.y + sectionLayout.height,
    x: previewWidth / 2 + scrollLeft,
  };
  nonOverlappableRect.x =
    nonOverlappableRectMedialPosition.x - nonOverlappableRect.width / 2;
  nonOverlappableRect.y =
    nonOverlappableRectMedialPosition.y - nonOverlappableRect.height / 2;

  const selectedComponentLayoutRelativeToScreen =
    editorAPI.components.layout.getRelativeToScreen(selectedComponents[0]);

  return layoutUtils.doBoxesOverlap(
    nonOverlappableRect,
    selectedComponentLayoutRelativeToScreen,
  );
};
