import React from 'react';
import * as baseUI from '@/baseUI';
import { BasePublicApi } from '@/apilib';
import { keyboardShortcuts } from '@/util';
import constants from '@/constants';
import type { CompRef } from 'types/documentServices';
import type { ComponentFocusModeScope } from './componentFocusModeScope';
import type {
  EnterPayload,
  ExitPayload,
  FloatBarOptions,
  AddPanelOverriding,
  MenuType,
} from './types';
import { overrideLeftBar } from './helpers/overrideLeftBar';
import { ADD_PANEL_SECTION_NAMES } from './helpers/addPanelSectionNames';
import { getAllowedChildrenTypes } from './helpers/getAllowedChildrenTypes';

const blockingLayerConfig = {
  backgroundColor: '#fff',
  opacity: 0.6,
};

const ENTER_MODE_HISTORY_LABEL = 'ENTER_COMPONENT_FOCUS_MODE';

export class ComponentFocusModeApi extends BasePublicApi<ComponentFocusModeScope> {
  private onExitCallback: (payload: ExitPayload) => void;
  private onOverlayClickOptions?: {
    shouldDisplayTooltip: boolean;
    tooltipText?: string;
    tooltipSymbol?: string;
  };

  enter = async (payload: EnterPayload) => {
    const { editorAPI, store } = this.scope;

    editorAPI.panelManager.closeAllPanels();
    const allowedSections = payload.overrideAddPanel?.(ADD_PANEL_SECTION_NAMES);
    const addPanelOverriding = allowedSections?.map(
      (section) => section.sectionName,
    );
    const allowedChildrenTypes =
      allowedSections && (await getAllowedChildrenTypes(allowedSections));
    store.enter(payload, { allowedChildrenTypes, addPanelOverriding });
    editorAPI.selection.selectComponentByCompRef(payload.compRef);

    overrideLeftBar(
      editorAPI,
      payload.overrideLeftBar?.(constants.ROOT_COMPS.LEFTBAR),
    );

    keyboardShortcuts.setContext(
      keyboardShortcuts.CONTEXTS.COMPONENT_FOCUS_MODE,
    );

    this.showCompOnTopIfNeeded();

    editorAPI.waitForChangesApplied(() => {
      editorAPI.history.add(ENTER_MODE_HISTORY_LABEL);
    });

    this.onExitCallback = payload.onExit;
    this.onOverlayClickOptions = payload.onOverlayClickOptions;
  };

  exit = (exitPayload: ExitPayload = {}) => {
    const { editorAPI, store } = this.scope;

    editorAPI.panelManager.closeAllPanels();

    editorAPI.renderPlugins.setCompsToShowOnTop(null);
    editorAPI.renderPlugins.setBlockingLayer(null);
    editorAPI.hideFloatingBubble();

    store.exit();
    overrideLeftBar(editorAPI, {});

    keyboardShortcuts.setContext(keyboardShortcuts.CONTEXTS.EDITOR);

    this.onExitCallback?.(exitPayload);
  };

  showCompOnTopIfNeeded: () => void = this.bindScope(
    ({ store, editorAPI }: ComponentFocusModeScope) => {
      if (store.getIsEnabled()) {
        editorAPI.renderPlugins.setBlockingLayer(blockingLayerConfig);
        editorAPI.renderPlugins.setCompsToShowOnTop([
          store.getCompRefToShowOnTop().id,
        ]);
      }
    },
  );

  isEnabled: () => boolean = this.bindScope(
    ({ store }: ComponentFocusModeScope) => store.getIsEnabled(),
  );

  getCompRef: () => CompRef | null = this.bindScope(
    ({ store }: ComponentFocusModeScope) => store.getCompRef(),
  );

  getMenuType: () => MenuType = this.bindScope(
    ({ store }: ComponentFocusModeScope) => store.getMenuType(),
  );

  getCompRefToAttachChildren: () => CompRef | null = this.bindScope(
    ({ store }: ComponentFocusModeScope) => store.getCompRefToAttachChildren(),
  );

  getFloatBarOptions: () => FloatBarOptions | null = this.bindScope(
    ({ store }: ComponentFocusModeScope) => store.getFloatBarOptions(),
  );

  getAddPanelOverriding: () => AddPanelOverriding | null = this.bindScope(
    ({ store }: ComponentFocusModeScope) => store.getAddPanelOverriding(),
  );
  canBePasted: (compTypes: string[]) => boolean = this.bindScope(
    ({ store }, compTypes) => {
      const allowedChildrenTypes = store.getAllowedChildrenTypes();
      if (!allowedChildrenTypes) {
        return true;
      }
      return compTypes.every((compType) =>
        allowedChildrenTypes.includes(compType),
      );
    },
  );

  handleBlockingLayerClick: () => void = this.bindScope(({ editorAPI }) => {
    if (
      !this.onOverlayClickOptions ||
      !this.onOverlayClickOptions.shouldDisplayTooltip
    ) {
      return;
    }
    const innerTemplate = React.createElement(
      baseUI.popoverTemplates.quickTip,
      {
        symbol: this.onOverlayClickOptions.tooltipSymbol,
        text: this.onOverlayClickOptions.tooltipText,
        closeAction: editorAPI.floatingBubble.hide,
      },
    );
    const coords = editorAPI.cursor.get();

    const targetLayout = {
      height: 0,
      width: 0,
      top: coords.y + 80 || 0,
      left: coords.x + 80 || 0,
    };

    const propsForBubble = {
      noArrow: true,
      shake: false,
      hideMethod: editorAPI.hideFloatingBubble,
      behindPopUps: true,
      alignment: constants.UI.TOOLTIP.ALIGNMENT.RIGHT,
    };
    editorAPI.floatingBubble.show(innerTemplate, targetLayout, propsForBubble, {
      shouldNotHideOnMouseLeaveTarget: false,
      delay: 300,
    });
  });

  wasLastActionEnterMode: () => boolean = this.bindScope(
    ({ editorAPI }: ComponentFocusModeScope) =>
      editorAPI.documentServices.history.getUndoLastSnapshotLabel() ===
      ENTER_MODE_HISTORY_LABEL,
  );
}
