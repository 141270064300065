export default {
  QUALAROO_DUMMY_EVENT: {
    evid: 9,
    fields: {
      testField: 'testValue',
    },
  },
  UPGRADE_EVENT: {
    evid: 519,
    fields: {
      origin: 'string',
    },
  },
  AUTOSAVE_TEST: {
    src: 42,
    endpoint: 'ugc-viewer',
    evid: 362,
    fields: {
      type: 'string',
      uuid: 'guid',
    },
  },
  EDITOR_READY: {
    endpoint: 'editor',
    src: 38,
    evid: 951,
    fields: {
      is_premium: 'boolean',
      is_template: 'boolean',
      window_width: 'number',
      window_height: 'number',
      devmod_type: 'string',
      open_method: 'string',
      is_section_migration: 'string',
    },
  },

  BG_IMAGE_SETTINGS_PANEL_SCROLL_EFFECT: {
    endpoint: 'editor',
    src: 38,
    evid: 685,
    fields: {
      scroll_type: 'string',
    },
  },
} as const;
