import * as util from '@/util';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import * as newBlogBi from '../../../../newBlogBi/newBlogBi';
import * as blogUtils from '../../../../utils/blogUtils';
import React from 'react';
import * as baseUI from '@/baseUI';
import * as symbols from '@wix/santa-editor-symbols';
import type { MapDispatchToProps, MapStateToProps } from 'types/redux';
import type { SendBiFunction } from 'types/bi';

interface MagicMigrationSuccessSlideOwnProps {
  displayName: string;
  siteHasNewBlog: boolean;
  onClose: FunctionFixMe;
  superApp: {
    id: string;
  };
}

interface MagicMigrationSuccessSlideDispatchProps {
  openParentPanel: () => void;
  sendBi: SendBiFunction;
  openHelpCenter: (article: string) => void;
  markMagicMigrationAsSeen: () => void;
  saveAndPublish: FunctionFixMe;
}

interface MagicMigrationSuccessSlideStateProps {
  siteHasNewBlog: boolean;
}

type MagicMigrationSuccessSlideProps = MagicMigrationSuccessSlideOwnProps &
  MagicMigrationSuccessSlideDispatchProps &
  MagicMigrationSuccessSlideStateProps;

class MagicMigrationSuccessSlide extends React.Component<MagicMigrationSuccessSlideProps> {
  static displayName = 'MagicMigrationSuccessSlide';

  componentDidMount() {
    this.props.sendBi(newBlogBi.events.BLOG_MAGIC_MIGRATION_MODAL_SHOWN, {
      modal_name: 'Migration success panel',
      new_blog_valid: this.props.siteHasNewBlog,
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
    });
  }

  onClose = () => {
    this.props.markMagicMigrationAsSeen();
    this.props.onClose();
    this.props.openParentPanel();

    this.props.sendBi(newBlogBi.events.BLOG_MAGIC_MIGRATION_MODAL_ACTION, {
      modal_name: 'Migration success panel',
      action: 'close',
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
    });
  };

  onPublish = () => {
    this.props.markMagicMigrationAsSeen();
    this.props.saveAndPublish();
    this.props.sendBi(newBlogBi.events.BLOG_MAGIC_MIGRATION_MODAL_ACTION, {
      modal_name: 'Migration success panel',
      action: 'publish',
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
    });
    this.props.onClose();
  };

  handleHelpClick = (e: React.MouseEvent) => {
    e.preventDefault();
    this.props.openHelpCenter(blogUtils.helpArticles.aboutNewBlog);
  };

  render() {
    return (
      <div className="blog-magic-migration-success-slide">
        <div onClick={() => this.onClose()} className="btn-close">
          <symbols.symbol name="headerCloseButton" />
        </div>

        <div className="illustration">
          <symbols.symbol name="illustrationSuccess" />
        </div>

        <div className="content">
          <h1 className="title">
            {translate('SiteApp_NewBlog_Migration_Success_Panel_Title')}
          </h1>

          <p className="subtitle">
            {translate('SiteApp_NewBlog_Migration_Success_Panel_Subtitle')}
          </p>

          <baseUI.button
            label="SiteApp_NewBlog_Migration_Success_Panel_CTA"
            onClick={() => this.onPublish()}
            className="btn-confirm-primary btn-switch"
          />

          <p>
            <a href="#" onClick={() => this.onClose()}>
              {translate('SiteApp_NewBlog_Migration_Success_Panel_Link')}
            </a>
          </p>

          <div className="footer">
            {translate('SiteApp_NewBlog_Migration_Success_Panel_Footer')}
            <a
              href="#"
              onClick={(e: React.MouseEvent) => this.handleHelpClick(e)}
              className="footer-link"
            >
              {translate('SiteApp_NewBlog_Migration_Success_Panel_Footer_Link')}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<
  MagicMigrationSuccessSlideStateProps,
  MagicMigrationSuccessSlideOwnProps
> = ({ editorAPI }) => ({
  siteHasNewBlog: blogUtils.siteHasNewBlog(editorAPI),
});

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;
const mapDispatchToProps: MapDispatchToProps<
  MagicMigrationSuccessSlideDispatchProps,
  MagicMigrationSuccessSlideOwnProps
> = (dispatch, { superApp }) => {
  const editorAPI = dispatch(getEditorAPI);
  const isNewBlogPanel = superApp.id === blogUtils.newSuperAppId;
  const parentPanel = isNewBlogPanel
    ? blogUtils.newSuperAppPanelName
    : blogUtils.superAppPanelName;

  return {
    sendBi: (event, parameters) =>
      dispatch(stateManagement.bi.actions.event(event, parameters)),
    openHelpCenter: (article) =>
      dispatch(stateManagement.panels.actions.openHelpCenter(article)),
    openParentPanel: () => editorAPI.panelManager.openPanel(parentPanel),
    markMagicMigrationAsSeen: () =>
      blogUtils.markMagicMigrationAsSeen(editorAPI),
    saveAndPublish: editorAPI.publishManager.saveAndPublish,
  };
};

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(MagicMigrationSuccessSlide);
ConnectedComponent.pure = MagicMigrationSuccessSlide;

export default ConnectedComponent;
