// @ts-nocheck
import React from 'react';
import GeneralSectionContent from './generalSectionContent';
import SectionWrapper from '../sectionWrapper';

export default class extends React.Component {
  static displayName = 'generalSection';

  render() {
    return (
      <SectionWrapper className={this.props.props.class || ''} {...this.props}>
        <GeneralSectionContent {...this.props} />
      </SectionWrapper>
    );
  }
}
