// @ts-nocheck
import constants from '@/constants';
import _ from 'lodash';
import * as mediaPanelConstants from '../mediaManagerPanelConstants/mediaManagerPanelConstants';
import * as mediaManagerPanelUtils from './mediaManagerPanelUtils';
import experiment from 'experiment';

const mediaManagerMediaTypes = constants.MEDIA_MANAGER_MEDIA_TYPES;
const {
  CHECKOUT_MEDIA_TYPE,
  CHECKOUT_MEDIA_PRODUCER,
  MEDIA_MANAGER_MEDIA_TYPES_SHUTTER_STOCK_MEDIA_TYPES_MAP,
} = mediaPanelConstants;

const purchaseItemParamsOrder = [
  'fileId',
  'checkoutMediaType',
  'mediaProducer',
  'publicMediaRoot',
  'origin',
  'tab',
  'locale',
  'siteToken',
];

const isPriceWithTaxExperimentEnabled = experiment.isOpen('se_PriceWithTax');

const extractPriceForShutterstockItem = (item, shutterstockPrices) => {
  const { mediaType } = item;
  const { taxSettings } = shutterstockPrices;

  const priceInfoByItemType =
    shutterstockPrices[
      MEDIA_MANAGER_MEDIA_TYPES_SHUTTER_STOCK_MEDIA_TYPES_MAP[mediaType]
    ];

  const { currencySymbol, showPricesWithTax, priceWithTax, priceValue } =
    priceInfoByItemType;

  return {
    value:
      isPriceWithTaxExperimentEnabled && showPricesWithTax
        ? priceWithTax
        : priceValue,
    symbol: currencySymbol ? currencySymbol.replace('US', '') : null,
    taxSettings,
  };
};

const isItemPurchasable = (item) => {
  return mediaManagerPanelUtils.isShutterStockItem(item);
};

const purchaseItemNewFlow = (item, options) =>
  new Promise((resolve, reject) => {
    const checkoutMediaType = mediaManagerPanelUtils.isVideoItem(item)
      ? CHECKOUT_MEDIA_TYPE.VIDEO
      : CHECKOUT_MEDIA_TYPE.IMAGE;
    const mediaProducer = mediaManagerPanelUtils.isWixMediaItem(item)
      ? CHECKOUT_MEDIA_PRODUCER.WIXSTOCK
      : CHECKOUT_MEDIA_PRODUCER.SHUTTERSTOCK;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    const isEditorX = _.includes(window.location.hostname, 'editorx.com');
    const checkoutUrl = `https://${
      isEditorX ? 'manage.editorx.com' : 'editor.wix.com'
    }/media-manager/checkout`;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const params = _.assign({}, options, {
      fileId: item.id,
      checkoutMediaType,
      mediaProducer,
    });
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const paramsStr = _(purchaseItemParamsOrder)
      .map((param) => `${param}=${params[param]}`)
      .join('&');

    const popup = window.open(
      `${checkoutUrl}/?${paramsStr}`,
      'purchase',
      'width=1150,height=900,scrollbars=1',
    );

    const onWindowMessage = (event) => {
      if (/editor.wix.com/i.test(event.origin)) {
        let response;

        try {
          response = JSON.parse(event.data);
        } catch (e) {
          response = {};
        }

        const { action, itemId, success, isNew } = response;

        if (action === '__mediaPurchase' && itemId === item.id) {
          window.removeEventListener('message', onWindowMessage, false);

          if (success) {
            resolve({ item, isNew });
          } else {
            reject({ item, isNew });
          }
        }
      }
    };

    window.addEventListener('message', onWindowMessage, false);

    popup.blur();
    window.focus();
  });

const buyItem = (purchaseUrl, item) =>
  new Promise((resolve, reject) => {
    const popup = window.open(
      purchaseUrl,
      'purchase',
      'width=1000,height=550,scrollbars=1',
    );

    const onWindowMessage = (event) => {
      if (/premium\./i.test(event.origin)) {
        const response = JSON.parse(event.data);
        const [itemId] = response.packageUniqueId.split('_');
        if (itemId === item.id) {
          window.removeEventListener('message', onWindowMessage, false);

          if (response.success) {
            resolve({ item });
          } else {
            reject({ item });
          }
        }
      }
    };

    window.addEventListener('message', onWindowMessage, false);

    popup.blur();
    window.focus();
  });

const buyShutterStockItem = (item, settings) => {
  const imagePurchaseUrl = `${settings.shutterStockPaymentAPI}/services/shutterstock/start_purchase?site_token=${settings.siteMediaToken}&image_id=${item.id}&altsku=1`;
  const videoPurchaseUrl = `${settings.shutterStockPaymentAPI}/services/shutterstock/video/start_purchase?site_token=${settings.siteMediaToken}&video_id=${item.id}&altsku=1`;
  const purchaseUrl =
    item.mediaType === mediaManagerMediaTypes.PICTURE
      ? imagePurchaseUrl
      : videoPurchaseUrl;

  return buyItem(purchaseUrl, item);
};

const buyWixMediaItem = (item, settings) => {
  const purchaseUrl = `https://pix.wix.com/services/wixstock/start_purchase?site_token=${settings.siteMediaToken}&image_id=${item.id}`;

  return buyItem(purchaseUrl, item);
};

export {
  isItemPurchasable,
  extractPriceForShutterstockItem,
  purchaseItemNewFlow,
  buyShutterStockItem,
  buyWixMediaItem,
};
