const AUTOMATION_IDS = {
  INPUT: 'save-to-my-elements-input',
  BUTTON_CONFIRM: 'message-panel-frame-confirm-btn',
  BUTTON_CANCEL: 'message-panel-frame-cancel-btn',
};
const DISPLAY_NAME = 'SaveToMyElementsPanel';
const NOTIFICATION_PANEL_TIMEOUT = 3000;
const NOTIFICATION_DEFAULT_KEYS = {
  title: 'Notifications_MyDesignSaved_Text',
  message: 'Notifications_MyDesignSaved_Text',
  linkCaption: 'Notifications_MyDesignSaved_Link',
};
const NOTIFICATION_SAVED_SECTION_KEYS = {
  title: 'add_section_saved_sections_saved_notification_text',
  message: 'add_section_saved_sections_saved_notification_text',
  linkCaption: 'add_section_saved_sections_saved_notification_cta',
};

export {
  AUTOMATION_IDS,
  DISPLAY_NAME,
  NOTIFICATION_SAVED_SECTION_KEYS,
  NOTIFICATION_DEFAULT_KEYS,
  NOTIFICATION_PANEL_TIMEOUT,
};
