import constants from '@/constants';
import type { PanelSectionDefinition } from '@/stateManagement';

const { PANEL_NAMES, PANEL_SECTIONS } = constants;

const VIDEO_PLAYER_SETTINGS_DEFAULT_SECTIONS =
  PANEL_SECTIONS[PANEL_NAMES.VIDEO_PLAYER_SETTINGS];

export const quickEditPanelsSectionsDefinitions: {
  [panelName: string]: PanelSectionDefinition;
} = {
  [PANEL_NAMES.VIDEO_PLAYER_SETTINGS]: {
    [VIDEO_PLAYER_SETTINGS_DEFAULT_SECTIONS.CHANGE_VIDEO]: 'enabled',
    [VIDEO_PLAYER_SETTINGS_DEFAULT_SECTIONS.VIDEO_DESCRIPTION]: 'enabled',
    [VIDEO_PLAYER_SETTINGS_DEFAULT_SECTIONS.PLAYBACK]: 'enabled',
  },
};
