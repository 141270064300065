import _ from 'lodash';

import type { FetchListResult, NewsItem, SectionItem } from '../types';
import {
  getLinkHref,
  getRichTextContent,
  getWixImageDescriptor,
} from '../utils';
import { WixAppsAppNames } from '../constants';

export const convertNewsItems = (
  fetchResults: FetchListResult<SectionItem, NewsItem>[],
) => {
  const payloads = fetchResults.map((req) => req.payload);

  const withBasicFields = (item: SectionItem | NewsItem) => {
    return {
      title: item.title,
      id: item._iid,
      _createdAt: item._createdAt,
      _updatedAt: item._updatedAt,
    };
  };

  const sections = payloads.map(({ item }) => withBasicFields(item));

  const news = payloads.map((payload) => {
    const { item, referencedItems } = payload;

    return item.items
      .map(
        (newsItemRef) =>
          referencedItems[`${newsItemRef.collectionId}/${newsItemRef.itemId}`],
      )
      .filter(Boolean)
      .map((item) => {
        return {
          ...withBasicFields(item),
          image: getWixImageDescriptor(item.image, WixAppsAppNames.News),
          createdDate: item.date.iso,
          createdBy: item.newsSource,
          createdByLink: getLinkHref(item.newsSourceLink),
          description: getRichTextContent(
            item.content.text,
            item.content.links,
          ),
          sections: item.parentRefs.map((ref) => ref.itemId),
        };
      });
  });

  return {
    sections: _.flatten(sections),
    news: _.flatten(news),
  };
};
