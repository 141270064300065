import type { CompRef, ScopePointer } from 'types/documentServices';

const CACHE_MAX = {
  TIME_DELTA: 400,
  DIST_SQR: 5,
} as const;

interface SelectionContext {
  scopes: ScopePointer[];
}

interface CacheKey {
  x: number;
  y: number;
  context: SelectionContext;
  timeStamp: number;
}

export function createSelectionCompsCache() {
  let cacheKey: CacheKey | null = null;
  let cacheComponents: CompRef[] = null;

  function areSelectionContextsEqual(
    context1: SelectionContext,
    context2: SelectionContext,
  ): boolean {
    if (context1.scopes.length !== context2.scopes.length) {
      return false;
    }

    return context1.scopes.every(
      (context1Scope, index) => context1Scope.id === context2.scopes[index].id,
    );
  }

  function shouldUseCache(
    x: number,
    y: number,
    context: SelectionContext,
  ): boolean {
    if (
      !cacheKey ||
      performance.now() - cacheKey.timeStamp > CACHE_MAX.TIME_DELTA
    ) {
      return false;
    }

    const dx = x - cacheKey.x;
    const dy = y - cacheKey.y;

    if (dx * dx + dy * dy >= CACHE_MAX.DIST_SQR) {
      return false;
    }

    return areSelectionContextsEqual(context, cacheKey.context);
  }

  return {
    get(x: number, y: number, context: SelectionContext): CompRef[] {
      return shouldUseCache(x, y, context) ? cacheComponents : null;
    },
    set(
      x: number,
      y: number,
      context: SelectionContext,
      components: CompRef[],
    ) {
      cacheKey = {
        x,
        y,
        context,
        timeStamp: performance.now(),
      };
      cacheComponents = components;
    },
  };
}
