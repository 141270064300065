import type { SectionDescription } from '../../types';

const SECTION_HEIGHT_LIMIT = 40;

export const mergeSections = (
  sectionA: SectionDescription,
  sectionB: SectionDescription,
): SectionDescription => ({
  ...sectionA,
  children: [...sectionA.children, ...sectionB.children],
  top: Math.min(sectionA.top, sectionB.top),
  bottom: Math.max(sectionA.bottom, sectionB.bottom),
  isFullWidthTop:
    sectionB.top > sectionA.top
      ? sectionA.isFullWidthTop
      : sectionB.isFullWidthTop,
  isFullWidthBottom:
    sectionB.bottom > sectionA.bottom
      ? sectionB.isFullWidthBottom
      : sectionA.isFullWidthBottom,
});

export const mergeMobileSections = (
  sectionA: SectionDescription,
  sectionB: SectionDescription,
): SectionDescription => ({
  ...sectionA,
  mobile: {
    ...sectionA.mobile,
    children: [...sectionA.mobile.children, ...sectionB.mobile.children],
    top: Math.min(sectionA.mobile.top, sectionB.mobile.top),
    bottom: Math.max(sectionA.mobile.bottom, sectionB.mobile.bottom),
    isFullWidthTop:
      sectionB.mobile.top > sectionA.mobile.top
        ? sectionA.mobile.isFullWidthTop
        : sectionB.mobile.isFullWidthTop,
    isFullWidthBottom:
      sectionB.mobile.bottom > sectionA.mobile.bottom
        ? sectionB.mobile.isFullWidthBottom
        : sectionA.mobile.isFullWidthBottom,
  },
});

export function resolveSmallSections(
  sectionsDescriptions: SectionDescription[],
): SectionDescription[] {
  return sectionsDescriptions.reduce(
    (resolvedSections, currentSection, index) => {
      if (resolvedSections.length === 0) {
        resolvedSections.push(currentSection);

        return resolvedSections;
      }

      const prevIndex = resolvedSections.length - 1;
      const prevSection = resolvedSections[prevIndex];
      const prevHeight = prevSection.bottom - prevSection.top;
      const currentHeight = currentSection.bottom - currentSection.top;
      const shouldMergeSections =
        currentHeight < SECTION_HEIGHT_LIMIT ||
        (index === 1 && prevHeight < SECTION_HEIGHT_LIMIT);

      if (shouldMergeSections) {
        resolvedSections[prevIndex] = mergeSections(
          prevSection,
          currentSection,
        );
      } else {
        resolvedSections.push(currentSection);
      }

      return resolvedSections;
    },
    <SectionDescription[]>[],
  );
}

export function resolveMobileSmallSections(
  sectionsDescriptions: SectionDescription[],
  yOrder: number[],
  outOfYOrder: number[],
): {
  sectionsDescriptions: SectionDescription[];
  yOrder: number[];
  outOfYOrder: number[];
} {
  const resolvedSections: SectionDescription[] = [];
  const resolvedYOrder = [];
  const resolvedOutOfYOrder = [...outOfYOrder];

  resolvedSections[yOrder[0]] = sectionsDescriptions[yOrder[0]];
  resolvedYOrder.push(yOrder[0]);

  for (let yPosition = 1; yPosition < yOrder.length; yPosition++) {
    const prevIndex = resolvedYOrder[resolvedYOrder.length - 1];
    const prevSection = resolvedSections[prevIndex];
    const currentIndex = yOrder[yPosition];
    const currentSection = sectionsDescriptions[currentIndex];

    const prevHeight = prevSection.mobile.bottom - prevSection.mobile.top;
    const currentHeight =
      currentSection.mobile.bottom - currentSection.mobile.top;

    const shouldMergeSections =
      currentHeight < SECTION_HEIGHT_LIMIT ||
      (yPosition === 1 && prevHeight < SECTION_HEIGHT_LIMIT);

    if (shouldMergeSections) {
      resolvedSections[prevIndex] = mergeMobileSections(
        prevSection,
        currentSection,
      );
      resolvedSections[currentIndex] = {
        ...currentSection,
        mobile: null,
      };
      resolvedOutOfYOrder.push(currentIndex);
    } else {
      resolvedSections[currentIndex] = currentSection;
      resolvedYOrder.push(currentIndex);
    }
  }

  // keep sections which is hidden on mobile
  outOfYOrder.forEach((index: number) => {
    resolvedSections[index] = sectionsDescriptions[index];
  });

  return {
    sectionsDescriptions: resolvedSections,
    yOrder: resolvedYOrder,
    outOfYOrder: resolvedOutOfYOrder,
  };
}
