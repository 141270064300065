import React from 'react';

interface FacebookAdsPanelProps {
  logo: React.ReactElement;
  title: React.ReactElement;
  body: React.ReactElement;
  actions: React.ReactElement;
}

export const MarketingPanel = React.memo<FacebookAdsPanelProps>((props) => {
  return (
    <div className="super-app-extra-panel">
      <div className="em-marketing-panel">
        <div className="em-body">
          {props.logo}
          <div className="em-title">{props.title}</div>
          {props.body}
          <div className="em-marketing-actions">{props.actions}</div>
        </div>
      </div>
    </div>
  );
});

MarketingPanel.displayName = 'MarketingPanel';
