import { translate } from '@/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 151,
        height: 97,
        x: 4,
        y: 29,
        scale: 1,
        rotationInDegrees: 341.5594735266043,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'xq224',
      },
      parent: 'comp-kgt5onmj',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
        alt: '',
        svgId: '800c2d532145403996febf2d8ab21396.svg',
        title: 'Speech Bubble ',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'xq224',
        },
        displayMode: 'stretch',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
        overrideColors: {
          color1: '#BADA55',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 1,
          stroke: 'color_15',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
      },
      transformations: {
        type: 'TransformData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
      },
      scopedTransformations: {
        'variants-kgt5onmt': {
          type: 'TransformData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'xq224',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        pageId: '',
        compId: 'i8yjldkb_0',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 150,
          height: 106,
          x: 17,
          y: 24,
          scale: 1,
          rotationInDegrees: 341.5594735266043,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5onmv',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 26,
        height: 32,
        x: 24,
        y: 60,
        scale: 1,
        rotationInDegrees: 32,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'xq224',
      },
      parent: 'comp-kgt5onmj',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
        text: `<h2 class="font_2" style="font-size:24px; text-align:center"><span style="color:#5C916B"><span style="font-size:24px"><span style="font-family:suez one,serif"><span style="letter-spacing:0.1em">${translate(
          'interactions_preset_hello_text_1',
        )}</span></span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'xq224',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
      },
      scopedTransformations: {
        'variants-kgt5onmt': {
          type: 'TransformData',
          translate: {
            x: {
              type: 'px',
              value: 6,
            },
            y: {
              type: 'px',
              value: 14,
            },
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'xq224',
          },
          rotate: -55,
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 20,
          height: 32,
          x: 44,
          y: 60,
          scale: 1,
          rotationInDegrees: 32,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'xq224',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5onmx',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 34,
        height: 32,
        x: 90,
        y: 72,
        scale: 1,
        rotationInDegrees: 303,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'xq224',
      },
      parent: 'comp-kgt5onmj',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
        text: `<h2 class="font_2" style="font-size:24px; text-align:center"><span style="color:#5C916B"><span style="font-size:24px"><span style="font-family:suez one,serif"><span style="letter-spacing:0.1em">${translate(
          'interactions_preset_hello_text_2',
        )}</span></span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'xq224',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
      },
      scopedTransformations: {
        'variants-kgt5onmt': {
          type: 'TransformData',
          translate: {
            x: {
              type: 'px',
              value: -44,
            },
            y: {
              type: 'px',
              value: -6,
            },
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'xq224',
          },
          rotate: 34,
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 17,
          height: 32,
          x: 113,
          y: 71,
          scale: 1,
          rotationInDegrees: 303,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'xq224',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5onmy5',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 33,
        height: 32,
        x: 45,
        y: 86,
        scale: 1,
        rotationInDegrees: 32,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'xq224',
      },
      parent: 'comp-kgt5onmj',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
        text: `<h2 class="font_2" style="font-size:24px; text-align:center"><span style="color:#5C916B"><span style="font-size:24px"><span style="font-family:suez one,serif"><span style="letter-spacing:0.1em">${translate(
          'interactions_preset_hello_text_3',
        )}</span></span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'xq224',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
      },
      scopedTransformations: {
        'variants-kgt5onmt': {
          type: 'TransformData',
          translate: {
            x: {
              type: 'px',
              value: 20,
            },
            y: {
              type: 'px',
              value: -28,
            },
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'xq224',
          },
          rotate: -56,
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 16,
          height: 32,
          x: 73,
          y: 89,
          scale: 1,
          rotationInDegrees: 32,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'xq224',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5onn01',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 28,
        height: 32,
        x: 62,
        y: 33,
        scale: 1,
        rotationInDegrees: 316,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'xq224',
      },
      parent: 'comp-kgt5onmj',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
        text: `<h2 class="font_2" style="font-size:24px; text-align:center"><span style="color:#5C916B"><span style="font-size:24px"><span style="font-family:suez one,serif"><span style="letter-spacing:0.1em">${translate(
          'interactions_preset_hello_text_4',
        )}</span></span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'xq224',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
      },
      scopedTransformations: {
        'variants-kgt5onmt': {
          type: 'TransformData',
          translate: {
            x: {
              type: 'px',
              value: 24,
            },
            y: {
              type: 'px',
              value: 18,
            },
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'xq224',
          },
          rotate: 20,
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 16,
          height: 32,
          x: 83,
          y: 33,
          scale: 1,
          rotationInDegrees: 316,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'xq224',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5onn15',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 28,
        height: 32,
        x: 102,
        y: 43,
        scale: 1,
        rotationInDegrees: 32,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'xq224',
      },
      parent: 'comp-kgt5onmj',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
        text: `<h2 class="font_2" style="font-size:24px; text-align:center"><span style="color:#5C916B"><span style="font-size:24px"><span style="font-family:suez one,serif"><span style="letter-spacing:0.1em">${translate(
          'interactions_preset_hello_text_5',
        )}</span></span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'xq224',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xq224',
        },
      },
      scopedTransformations: {
        'variants-kgt5onmt': {
          type: 'TransformData',
          translate: {
            x: {
              type: 'px',
              value: 2,
            },
            y: {
              type: 'px',
              value: 0,
            },
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'xq224',
          },
          rotate: -57,
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 20,
          height: 32,
          x: 121,
          y: 43,
          scale: 1,
          rotationInDegrees: 32,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'xq224',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5onn3',
          author: 'studio',
        },
      },
    },
  ],
  layout: {
    width: 171,
    height: 158,
    x: 404,
    y: 171,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'xq224',
  },
  parent: 'xq224',
  variants: {
    'variants-kgt5onmt': {
      componentId: 'comp-kgt5onmj',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'xq224',
      },
    },
  },
  transitions: {
    type: 'TransitionData',
    'timing-function': 'ease-in-out',
    duration: 0.4,
    delay: 0,
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'xq224',
    },
  },
  transformations: {
    type: 'TransformData',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'xq224',
    },
  },
  scopedTransformations: {
    'variants-kgt5onmt': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'xq224',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'xq224',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: 'color_15',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'theme',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  mobileStructure: {
    layout: {
      width: 187,
      height: 141,
      x: 58,
      y: 10,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kgt5onmj',
      author: 'studio',
    },
  },
  activeModes: {},
};
