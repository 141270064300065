import React, { useState, type FC, useEffect } from 'react';
import {
  LiveChat,
  MessageRoles,
  type ChatCompletionRequestMessage,
  type SystemMessage,
  type LegalChecklQuery,
  type LegalCheckResponse,
} from '@/baseUI';
import { type Channel } from '@wix/duplexer-js';
import * as stateManagement from '@/stateManagement';
import type { MapStateToProps } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import constants from '@/constants';
import { hoc, http, serviceTopology } from '@/util';
import experiment from 'experiment';

const { ChatContext } = LiveChat;

const base = `${serviceTopology.editorRootUrl}_serverless/${
  experiment.isOpen('se_useTestServerlessForAiTextCreator')
    ? 'gpt-3-test'
    : 'gpt-3'
}`;

export async function _checkLegal(
  query: LegalChecklQuery,
  metaSiteInstance: string,
): Promise<LegalCheckResponse> {
  return await http.fetchJson(`${base}/completion`, {
    method: 'POST',
    headers: new Headers({
      'content-type': 'application/json; charset=utf-8',
      Authorization: metaSiteInstance,
    }),
    body: JSON.stringify(query),
  });
}

let messagesCache: ChatCompletionRequestMessage[];

const INITIAL_MESSAGES_STATE = [
  {
    content:
      'Let AI Assistant help you with your work. Type a few words and press enter.',
    role: MessageRoles.AUTO_MESSAGE,
  },
];

export interface AIAssistantProviderOwnProps {}

export interface AIAssistantProviderStoreProps {
  metaSiteInstance: string;
  msid: string;
  toneOfVoice: string;
}

interface AIAssistantProviderTypes
  extends AIAssistantProviderOwnProps,
    AIAssistantProviderStoreProps {}

const AIAssistantProvider: FC<AIAssistantProviderTypes> = ({
  children,
  metaSiteInstance,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_chunks, setChunks] = useState<
    { content: string; timestamp: number }[]
  >([]);
  const [systemMessage, setSystemMessage] = useState<SystemMessage>({
    content: '',
    type: null,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [chatChannel, _setChatChannel] = useState<Channel<any>>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isConnected, _setIsConnected] = useState(false);
  const [isStreamingNow, setIsStreamingNow] = useState(false);
  const [showStreamingPlaceholder, setShowStreamingPlaceholder] =
    useState(false);
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState<ChatCompletionRequestMessage[]>(
    messagesCache ?? INITIAL_MESSAGES_STATE,
  );
  const [isImproveTextMode, setIsImproveTextMode] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [usagePromptTokens, _setUsagePromptTokens] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [usageCompletionTokens, _setUsageCompletionTokens] = useState(0);

  useEffect(() => {
    messagesCache = messages;
  }, [messages]);

  const streamMessage = (messages: ChatCompletionRequestMessage[]) => {
    if (messages.length === 0) return;

    setShowStreamingPlaceholder(true);

    setIsStreamingNow(true);
  };

  const clearMessage = () => {
    setSystemMessage({
      content: '',
      type: null,
    });
    setChunks([]);
  };

  const checkLegal = async (query: LegalChecklQuery) => {
    return _checkLegal(query, metaSiteInstance);
  };

  return (
    <ChatContext.Provider
      value={{
        systemMessage,
        chatChannel,
        isConnected,
        isStreamingNow,
        showStreamingPlaceholder,
        inputValue,
        messages,
        isImproveTextMode,
        toneOfVoice: 'funny',
        usage: {
          promptTokens: usagePromptTokens,
          completionTokens: usageCompletionTokens,
        },
        setSystemMessage,
        streamMessage,
        clearMessage,
        setShowStreamingPlaceholder,
        setInputValue,
        setMessages,
        setIsImproveTextMode,
        checkLegal,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const mapStateToProps: MapStateToProps<
  AIAssistantProviderStoreProps,
  AIAssistantProviderOwnProps
> = ({ editorAPI }: { editorAPI: EditorAPI }) => {
  const metaSiteInstance = editorAPI.dsRead.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance;

  return {
    metaSiteInstance,
    msid: editorAPI.site.getMetaSiteId(),
    toneOfVoice:
      (stateManagement.userPreferences.selectors.getSiteUserPreferences(
        'toneOfVoice',
      )(editorAPI.store.getState()) as string) || 'Default',
  };
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  null,
)(AIAssistantProvider);
