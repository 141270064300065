// @ts-nocheck
import React from 'react';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import coreUtilsLib from 'coreUtilsLib';
import mobileOnlyHiddenItemsData from './mobileOnlyHiddenItemsData';
import type { CompLayout } from 'types/documentServices';

const MAX_TEXT_SIZE = 50;
const ICON_CONTAINER_SIZES = { width: 50, height: 50 };
const { isReferredComponent } = stateManagement.components.selectors;

const compsThatShouldAddPropFromDataToLabel = {
  'wysiwyg.viewer.components.SiteButton': 'label',
  'wysiwyg.viewer.components.WRichText': 'text',
};

function htmlDecode(text) {
  const e = window.document.createElement('div');
  e.innerHTML = text;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}

function parseTextForPreview(text) {
  let parsedText = text || '';
  parsedText = util.stringUtils.removeHtmlTagsFromString(parsedText); //Clear all HTML tags
  parsedText = util.stringUtils.removeBreakLinesFromString(parsedText); //Remove /n and /r
  parsedText = parsedText.substring(0, MAX_TEXT_SIZE); //Cut to reasonable length
  parsedText = htmlDecode(parsedText);
  return parsedText;
}

function getDisplayText(compRef, compType, editorAPI) {
  let labelText = editorAPI.components.getDisplayName(compRef, compType);
  if (compsThatShouldAddPropFromDataToLabel[compType]) {
    const compData = editorAPI.components.data.get(compRef);
    if (compData) {
      labelText += `: ${
        compData[compsThatShouldAddPropFromDataToLabel[compType]]
      }`;
    }
  }
  return parseTextForPreview(labelText);
}

function getMobileOnlyItemData(compId) {
  const mobileData = mobileOnlyHiddenItemsData.getMobileItemData(compId);
  const iconProps = util.compIcon.getCompIconInfo(
    null,
    mobileData.className,
    ICON_CONTAINER_SIZES,
  );
  return {
    compId: mobileData.id,
    compType: mobileData.id,
    desktopLayout: null,
    label: translate(mobileData.label),
    iconUrl: iconProps.src,
    svgName: iconProps.svgName,
    css: iconProps.css,
  };
}

export interface HiddenItemData {
  compId: string;
  compType: string;
  desktopLayout: CompLayout;
  label: string;
  iconUrl: string;
  svgName: string;
  css: React.CSSProperties;
  isEssential?: boolean;
  essentialText?: string;
}

function getNonMobileItemData(compId, pageId, editorAPI): HiddenItemData {
  const desktopCompRef = editorAPI.components.get.byId(
    compId,
    pageId,
    editorAPI.dsRead.viewMode.VIEW_MODES.DESKTOP,
  );
  const compType = editorAPI.components.getType(desktopCompRef);
  const desktopLayout = editorAPI.components.layout.get(desktopCompRef);
  const iconProps = editorAPI.components.getIconInfo(
    desktopCompRef,
    ICON_CONTAINER_SIZES,
  );
  const { isEssential, text: essentialText } =
    (desktopCompRef &&
      stateManagement.platform.selectors.getEssentialData(
        editorAPI,
        desktopCompRef,
      )) ||
    {};
  return {
    compId: desktopCompRef.id,
    compType,
    desktopLayout,
    label: getDisplayText(desktopCompRef, compType, editorAPI),
    iconUrl: iconProps.src,
    svgName: iconProps.svgName,
    css: iconProps.css,
    isEssential,
    essentialText,
  };
}

const getDirectRefHostCompPointer = (compPtr) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  _.assign({}, compPtr, {
    id: compPtr.id.substring(0, compPtr.id.lastIndexOf('_r_')),
  });
const getTopRefHostCompPointer = (compPtr) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  _.assign({}, compPtr, { id: compPtr.id.split('_r_')[0] });

function getOriginalComponent(editorAPI, compPointer) {
  let refCompPointer = getDirectRefHostCompPointer(compPointer);
  let ghostsMap =
    editorAPI.dsRead.components.refComponents.getGhostRefComponents(
      refCompPointer,
    );
  if (!_.has(ghostsMap, compPointer.id)) {
    refCompPointer = getTopRefHostCompPointer(compPointer);
    ghostsMap =
      editorAPI.dsRead.components.refComponents.getGhostRefComponents(
        refCompPointer,
      );
  }

  return ghostsMap[compPointer.id];
}

function getGhostMobileItemData(compPtr, pageId, editorAPI) {
  const id = coreUtilsLib.displayedOnlyStructureUtil.isRepeatedComponent(
    compPtr.id,
  )
    ? coreUtilsLib.displayedOnlyStructureUtil.getRepeaterTemplateId(compPtr.id)
    : compPtr.id;
  const compPointer = _.set(compPtr, 'id', id);
  const originalComponent = getOriginalComponent(editorAPI, compPointer);
  const compType = originalComponent.componentType;
  const desktopLayout = editorAPI.components.layout.get(compPointer);
  const iconProps = util.compIcon.getCompIconInfo(
    compPointer,
    compType,
    ICON_CONTAINER_SIZES,
    editorAPI,
  );
  return {
    compId: compPointer.id,
    compType,
    desktopLayout,
    label: getDisplayText(compPointer, compType, editorAPI),
    iconUrl: iconProps.src,
    svgName: iconProps.svgName,
    css: iconProps.css,
  };
}

function getItemData(editorAPI, compId, pageId, isMobileOnly) {
  const compPointer = editorAPI.components.get.byId(
    compId,
    pageId,
    editorAPI.dsRead.viewMode.VIEW_MODES.MOBILE,
  );
  if (compPointer && isReferredComponent(compPointer)) {
    return getGhostMobileItemData(compPointer, pageId, editorAPI);
  }
  return isMobileOnly
    ? getMobileOnlyItemData(compId)
    : getNonMobileItemData(compId, pageId, editorAPI);
}

export { getItemData };
