export const AUTOMATION_IDS = {
  MENUS_LIST: {
    CONTAINER: 'menu-sets-panel-content',
    ITEM: 'menu-sets-item',
    CREATE_MENU_BUTTON: 'create-menu-button',
  },
  ITEM: {
    SELF: 'menu-sets-item',
    INFO_ICON: 'menu-sets-item-info-icon',
    ACTIONS: {
      RENAME: 'menu-sets-item-action-rename',
      DUPLICATE: 'menu-sets-item-action-duplicate',
      REMOVE: 'menu-sets-item-action-remove',
    },
  },
  PANEL: {
    ACTIONS: {
      CLOSE: 'panel-header-close-button',
    },
  },
};
