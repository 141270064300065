// @ts-nocheck
import connectedComponentGfppEnhancer from './connectedComponentGfppEnhancer';
import repeaterGfppEnhancer from './repeaterGfppEnhancer';
import toggleMenuGfppEnhancer from './toggleMenuGfppEnhancer';
import interactionsGfppEnhancer from './interactionsGfppEnhancer';
import tpaGfppEnhancer from './tpaGfppEnhancer';
import oldBlogDeprecationEnhancer from './oldBlogDeprecationEnhancer';
import unsupportedComponentEnhancer from './unsupportedComponentEnhancer';
export default [
  repeaterGfppEnhancer,
  toggleMenuGfppEnhancer,
  interactionsGfppEnhancer,
  connectedComponentGfppEnhancer,
  tpaGfppEnhancer,
  oldBlogDeprecationEnhancer,
  unsupportedComponentEnhancer,
];
