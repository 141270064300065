'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const hoverBoxPresets = require('./hoverBoxPresets')

const items = [
    hoverBoxPresets.hbPreset1,
    hoverBoxPresets.hbPreset2,
    hoverBoxPresets.hbPreset3,
    hoverBoxPresets.hbPreset4,
    hoverBoxPresets.hbPreset5,
    hoverBoxPresets.hbPreset6,
    hoverBoxPresets.hbPreset7,
    hoverBoxPresets.hbPreset9,
    hoverBoxPresets.hbPreset10,
    hoverBoxPresets.hbPreset11,
    hoverBoxPresets.hbPreset12,
    hoverBoxPresets.hbPreset13,
    hoverBoxPresets.hbPreset14,
    hoverBoxPresets.hbPreset15,
    hoverBoxPresets.hbPreset16,
    hoverBoxPresets.hbPreset17,
    hoverBoxPresets.hbPreset18,
    hoverBoxPresets.hbPreset19,
    hoverBoxPresets.hbPreset20,
    hoverBoxPresets.hbPreset22,
    hoverBoxPresets.hbPreset23,
    hoverBoxPresets.hbPreset24,
    hoverBoxPresets.hbPreset25,
    hoverBoxPresets.hbPreset26,
    hoverBoxPresets.hbPreset27,
    hoverBoxPresets.hbPreset28,
    hoverBoxPresets.hbPreset29,
    hoverBoxPresets.hbPreset30,
    hoverBoxPresets.hbPreset31,
    hoverBoxPresets.hbPreset32,
    hoverBoxPresets.hbPreset33,
    hoverBoxPresets.hbPreset34
].filter(p => p)

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_hover_box",
    "subNavigationTitle": "add_section_label_hover_box",
    "presetTitle": "add_section_label_hover_box",
    "tooltipTitle": "add_section_label_hover_box",
    "automationId": "add-panel-section-hoverBoxSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/hoverBoxesSection_en/hoverBoxesSection_en.jpg",
        "imageHover": "addPanelData/sections/hoverBoxesSection_en/hover_en/hoverBoxesSection_hover_en.jpg",
        items,
        "compTypes": ["wysiwyg.viewer.components.HoverBox"]
    },
    "help": {
        "hide": false,
        "text": "add_section_info_text_hoverbox"
    }
}
