import React, { useCallback, useState } from 'react';
import { RichText, ColorPickerInput, Button, Divider } from '@wix/wix-base-ui';
import { useTranslation } from 'react-i18next';
import { ToolPanelFrame } from '../../../frames';
import styles from './replaceColorPanel.scss';
import { REPLACE_COLOR_PANEL_TRANSLATION } from './translations';
import type { ColorName, ColorPalette } from '@/theme';

import { biLogger } from '@/util';
import { colorPaletteRemoveColorPanel } from '@wix/bi-logger-editor/v2';

export interface IReplaceRemovePanelProps {
  top: string | number;
  left: string | number;
  onClose: (linkedColorName?: ColorName) => void;
  replacementColors: ColorName[];
  palette: ColorPalette;
  openColorHelp: () => void;
}

const AUTOMATION_IDS = {
  PANEL: 'replace-color-panel',
};

const PANEL_NAME = 'replace-theme-color-panel';

const ReplaceColorPanel: React.FC<IReplaceRemovePanelProps> = ({
  top,
  left,
  replacementColors,
  palette,
  onClose,
  openColorHelp,
}: IReplaceRemovePanelProps) => {
  const [translate] = useTranslation();

  const [currentColorName, setCurrentColorName] = useState(null);

  const handlePanelClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handlePanelSubmit = () => onClose(currentColorName);

  const handlePanelClick = (colorName: ColorName) => {
    setCurrentColorName(colorName);

    biLogger.report(
      colorPaletteRemoveColorPanel({
        target: colorName || 'none',
      }),
    );
  };

  return (
    <ToolPanelFrame
      panelName={PANEL_NAME}
      headerTitle={translate(REPLACE_COLOR_PANEL_TRANSLATION.HEADER)}
      lightHeader
      contentStyle={{ top, left }}
      isBlockingLayerFullScreen={false}
      shouldBlockOuterScroll={false}
      automationId={AUTOMATION_IDS.PANEL}
      contentWrapperClass={styles.root}
      onClose={handlePanelClose}
      helpId="8cc9f826-68a9-4b87-8e66-aec778d5d04f"
      openHelpCenter={openColorHelp}
    >
      <section className={styles.content}>
        <RichText
          children={translate(REPLACE_COLOR_PANEL_TRANSLATION.PANEL_CONTENT)}
        />
      </section>
      <Divider />
      <section className={styles.colors}>
        <RichText
          children={translate(
            REPLACE_COLOR_PANEL_TRANSLATION.REPLACEMENT_LABEL,
          )}
        />
        <section className={styles.colorsPreview}>
          {replacementColors.map((colorName) => (
            <ColorPickerInput
              key={colorName}
              value={palette[colorName]}
              onClick={() => handlePanelClick(colorName)}
              selected={colorName === currentColorName}
            />
          ))}
        </section>
      </section>
      <Divider long />
      <section className={styles.footer}>
        <Button
          onClick={handlePanelSubmit}
          disabled={!currentColorName}
          dataHook="replace-color-submit-button"
        >
          {translate(REPLACE_COLOR_PANEL_TRANSLATION.REPLACEMENT_BUTTON)}
        </Button>
      </section>
    </ToolPanelFrame>
  );
};

export default ReplaceColorPanel;
