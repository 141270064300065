import React from 'react';
import * as util from '@/util';
import _ from 'lodash';
import {
  mapStateToProps,
  mapDispatchToProps,
  type PublishPanelDispatchProps,
  type PublishPanelStateProps,
} from './publishPanelMappers';

import TextAndLinkPanel from '../textAndLinkPanel';
import {
  withPanelData,
  type PublishPanelWithPanelDataProps,
} from './publishPanelStructure';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

export interface PublishPanelOwnProps {}

export interface PublishPanelProps
  extends PublishPanelDispatchProps,
    PublishPanelWithPanelDataProps,
    PublishPanelStateProps,
    PublishPanelOwnProps {}

const PublishPanel = (props: PublishPanelProps) => (
  <TextAndLinkPanel panelData={props.panelData} />
);

const ConnectedPublishPanel = _.flow(
  withPanelData,
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
)(PublishPanel);

export default ConnectedPublishPanel;
