/*eslint max-lines: [2, { "max": 1161, "skipComments": true, "skipBlankLines": true}]*/
import type { CompRef, CompData, CompStructure } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { LayoutWithMetadata } from './switchLayoutStore';
import type { PreferredMedia } from '@/presetApi';
import type { SwitchLayoutState } from './switchLayoutStore';
import type { Dispatch, StateMapperArgs } from 'types/redux';
import * as util from '@/util';
import { EditorPaasApiKey } from '@/apis';
import { CeType } from '@/presetApi';
import { focalPoint } from '@/stateManagement';
import constants from '@/constants';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { getListsContentItems, sendListInfo } from './switchLayoutListsUtil';
import type {
  TextClassificationApi,
  TextClassificationResponseData,
} from '@/textClassification';
import {
  collectListsMapFromClassificationOutput,
  validateSectionGrouping,
  collectListComponentIds,
} from './switchLayoutListsUtil';
import type { BiData } from './switchLayoutStore';
import type { ListClassificationApi } from '@/listClassification';
import {
  SupportedCompGeneralTypes,
  SupportedTextClassificationTypes,
  SUPPORTED_COMPS,
  SupportedVideoMediaTypes,
  SupportedImageMediaTypes,
  SupportedVideoTypes,
  SUPPORTED_VIDEOTYPES_LINKS,
  supportedVideoComponentTypes,
  SUPPORTED_VIDEO_DATATYPES,
  type MediaImageContentItem,
  type MediaVideoContentItem,
  type MediaGalleryContentItem,
  type ContentItem,
  type ExternalVideoContentItem,
  type InternalVideoContentItem,
  type OriginalCompMappingData,
  type ListContentItem,
  type PaaSContent,
  type TypeMappingComponentData,
  containersValidBackgroundMediaTypes,
  containersValidAsPaasComponent,
  MAX_INSTACES_OF_SUPPORTED_TYPES_PAAS,
  unsupportedForSnapifyComponentTypes,
  IS_COMP_UNSUPPORTED_BY_DATA,
  CONTAINERS_TO_IGNORE_FOR_PAAS,
  LIST_PRECESS_INFO_OPTIONS,
} from './consts';
import { isOriginalCompMappingDataEmpty } from './switchLayoutApi';
import * as textControls from '@/textControls';
import { fedopsLogger } from '@/util';
import { getText, decodeHtml } from './switchLayoutUtil';

const { COMP_TYPES } = constants;
const ceTypes = new Set(Object.values(CeType));

export const handleTextStylings = (
  newComponentText: string,
  origCompText: string,
) => {
  const origHtmlTempParent = document.createElement('div');
  origHtmlTempParent.innerHTML = origCompText;
  let textWithInnerStylingHtml = '';
  while (origHtmlTempParent.childNodes.length > 0) {
    const child = origHtmlTempParent.childNodes[0];
    let nodeHTML = (child as AnyFixMe).outerHTML;
    if (child.nodeType === Node.TEXT_NODE) {
      nodeHTML = (child as AnyFixMe).wholeText;
      origHtmlTempParent.removeChild(child);
    } else {
      nodeHTML = reparentHtmlElementForStyling(child);
    }
    textWithInnerStylingHtml += nodeHTML;
  }

  const targetHtmlTempParent = document.createElement('div');
  targetHtmlTempParent.innerHTML = newComponentText;
  const tagsToCopy = getTargetHtmlTagForReparenting(targetHtmlTempParent);
  tagsToCopy.innerHTML = textWithInnerStylingHtml;
  return targetHtmlTempParent.innerHTML;
};

const reparentHtmlElementForStyling = (node: ChildNode) => {
  const tempParent = document.createElement('div');
  tempParent.appendChild(node);

  // we want to remove all styling elements that affect the entire text - and return the inner text with stylings
  // so we want to continue removing the parent elements until styling is branched and no longer for all inner text
  while (node?.childNodes.length === 1) {
    const parent = node.parentNode;
    const nextNode = node.childNodes[0];

    // we want to ignore refs and underlines when removing stylings
    if (
      (node as AnyFixMe).tagName.toLowerCase() !== 'a' &&
      (node as AnyFixMe).style.textDecoration.toLowerCase() !== 'underline'
    ) {
      parent.removeChild(node);
      parent.appendChild(nextNode);
    }
    node = nextNode;
  }

  removeColorClassesFromElements(node);

  const doesTextHaveBranchedInnerElements = node?.childNodes.length > 1;
  if (doesTextHaveBranchedInnerElements) {
    // handle the last element before the element branches -
    // reparent the branches so that we dont leave a styling on all text
    removeNodeFromTree(node);
  }

  return tempParent.innerHTML;
};

const removeColorClassesFromElements = (node: ChildNode) => {
  for (const child of node?.childNodes) {
    if (child.nodeType !== Node.TEXT_NODE) {
      // color can be set as a class
      const colorClass = (child as AnyFixMe).classList.value
        .split(' ')
        .find((className: string) =>
          className.toLowerCase().startsWith('color'),
        );
      if (colorClass) {
        (child as AnyFixMe).classList.toggle(colorClass);
        if ((child as AnyFixMe).classList.length == 0) {
          (child as AnyFixMe).removeAttribute('class');
        }
      }

      // color can be set in a style attribute
      if ((child as AnyFixMe).style.color) {
        (child as AnyFixMe).style.removeProperty('color');
        if ((child as AnyFixMe).style.length == 0) {
          (child as AnyFixMe).removeAttribute('style');
        }
      }
      removeColorClassesFromElements(child);
    }
  }

  // after removing all the coloring - remove elements with no attributes left,
  // so we dont leave empty elements behind us
  for (let index = 0; index < node?.childNodes.length; index++) {
    const nodeToCheck = node.childNodes[index];
    if (
      nodeToCheck.nodeType !== Node.TEXT_NODE &&
      (nodeToCheck as AnyFixMe).attributes.length == 0
    ) {
      removeNodeFromTree(nodeToCheck);
      --index;
    }
  }
};

const removeNodeFromTree = (nodeToRemove: ChildNode) => {
  // if not found, index will be -1,
  const indexOfNodeToRemoveInParent = [
    ...nodeToRemove.parentNode.childNodes,
  ].indexOf(nodeToRemove);

  // if no node in index it will be undefined
  const nodeToInsertReparentedElementsBefore =
    nodeToRemove.parentNode.childNodes[indexOfNodeToRemoveInParent + 1];

  while (nodeToRemove.childNodes.length > 0) {
    nodeToRemove.parentNode.insertBefore(
      nodeToRemove.childNodes[0],
      nodeToInsertReparentedElementsBefore,
    );
  }
  nodeToRemove.parentNode.removeChild(nodeToRemove);
};

const getTargetHtmlTagForReparenting = (htmlNode: ChildNode) => {
  let currNode: AnyFixMe = htmlNode;

  // we want to leave styling elements while we only have one line of elements -
  // until styling is branched or we get to the inner text
  while (
    currNode?.childNodes.length === 1 &&
    currNode.childNodes[0].nodeType !== Node.TEXT_NODE
  ) {
    currNode = currNode.childNodes[0];
  }

  // remove the inner stylings and text- so we only have the styling "frame"
  currNode.innerHTML = '';
  return currNode;
};

const handleTextLinks = async (editorAPI: EditorAPI, origObj: any) => {
  const linksHelper = new (textControls.LinksHelper as AnyFixMe)(
    origObj.data.linkList,
    [],
  );
  const linkIds: any = {};
  const copyData = { ...origObj.data };
  // recreate the links
  for (const linkId of Object.keys(copyData.linkListForSwitch)) {
    linkIds[linkId] = linksHelper.saveLink(
      origObj.data.linkList.find(
        (link: any) =>
          link.metaData.sig === copyData.linkListForSwitch[linkId].metaData.sig,
      ),
    );
  }
  await editorAPI.waitForChangesAppliedAsync();
  delete copyData.linkListForSwitch;

  for (const key of Object.keys(linkIds)) {
    copyData.text = copyData.text.replace(key, linkIds[key]);
  }
  return copyData;
};

const handleTextComponentsReWire = async (
  editorAPI: EditorAPI,
  originalComponents: any,
  textComps: CompRef[],
) => {
  // go over the original components to find the component that replaced it (compare text)
  for (const originalComponent of originalComponents) {
    const value = originalComponent.serialized.data?.text;
    if (value && textComps.length > 0) {
      const originalText = value
        ? decodeHtml(util.stringUtils.removeHtmlTagsFromString(value))
        : null;
      const textComponentIndex = textComps.findIndex((x: CompRef) => {
        return (
          getText(editorAPI, x).replaceAll('\n', '').toLowerCase().trim() ===
          originalText.replaceAll('\n', '').toLowerCase().trim()
        );
      });

      if (textComponentIndex !== -1) {
        const newComp = textComps[textComponentIndex];
        let copyData = { ...originalComponent.serialized.data };
        if (originalComponent.serialized.data?.linkList.length > 0) {
          copyData = await handleTextLinks(
            editorAPI,
            originalComponent.serialized,
          );
        }

        const componentTextData = editorAPI.components.data.get(newComp).text;
        const componentTextHeadingTag =
          getHeadingTag(componentTextData).toLowerCase();
        const copyDataHeadingTag = getHeadingTag(copyData.text).toLowerCase();

        copyData.text = handleTextStylings(componentTextData, copyData.text);
        copyData.text = keepHeadingTagAfterSwitch(
          componentTextHeadingTag,
          copyDataHeadingTag,
          copyData.text,
        );

        editorAPI.components.data.update(newComp, copyData, true);
        textComps.splice(textComponentIndex, 1);
      }
    }
  }
};

const keepHeadingTagAfterSwitch = (
  componentTextHeadingTag: string,
  copyDataHeadingTag: string,
  textData: string,
) => {
  if (componentTextHeadingTag !== copyDataHeadingTag) {
    let textDataReplaced = textData.replace(
      `<${componentTextHeadingTag}`,
      `<${copyDataHeadingTag}`,
    );
    textDataReplaced = textDataReplaced.replace(
      `</${componentTextHeadingTag}`,
      `</${copyDataHeadingTag}`,
    );
    return textDataReplaced;
  }
  return textData;
};

const getHeadingTag = (headingHtml: string) => {
  const headingParent = document.createElement('div');
  headingParent.innerHTML = headingHtml;
  return headingParent.firstChild.nodeName;
};

export const postPaasSwitchDataFixer = async (
  editorAPI: EditorAPI,
  originalLayoutTypeMapping: AnyFixMe,
  sectionCompRef: CompRef,
) => {
  const generalTypes = [
    SupportedCompGeneralTypes.BUTTON,
    SupportedCompGeneralTypes.TEXT,
    SupportedCompGeneralTypes.MEDIA_IMAGES,
    SupportedCompGeneralTypes.MEDIA_VIDEOS,
  ];

  for (const generalType of generalTypes) {
    const originalComponents = originalLayoutTypeMapping[generalType] || [];
    if (originalComponents.length === 0) {
      continue;
    }

    // get all the specific types for the general type
    const supportedTypes = Object.keys(SUPPORTED_COMPS).filter(
      (x) => SUPPORTED_COMPS[x] === generalType,
    );
    let typeComps: CompRef[] = [];

    // get all the components of the specific types
    for (const supportedType of supportedTypes) {
      const compRefs =
        editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
          supportedType,
          sectionCompRef,
        );
      typeComps = [...typeComps, ...compRefs];
    }

    if (generalType === SupportedCompGeneralTypes.TEXT) {
      await handleTextComponentsReWire(
        editorAPI,
        originalComponents,
        typeComps,
      );
    } else if (typeComps.length > 0) {
      const newData = {
        ...editorAPI.components.data.get(typeComps[0]),
      };
      if (originalComponents[0].serialized.data?.link) {
        newData.link = originalComponents[0].serialized.data?.link;
      }
      editorAPI.components.data.update(typeComps[0], newData, true);
    }
  }
};

const areComponentTypesMatch = (
  valueElements: any,
  argsMatchingPolicy: any,
  originalCompMapping: OriginalCompMappingData,
) => {
  const mapTypes: any = {
    text: 0,
    button: 0,
    image: 0,
    video: 0,
    gallery: 0,
  };
  valueElements.forEach((element: any) => {
    const elementKey: any = Object.keys(element)?.[0];
    const valueElement: any = Object.values(element)?.[0];
    if (valueElement.value) {
      const isValidButton =
        valueElement.type === 'button' &&
        valueElement.value.callToAction !== null;
      const isValidText =
        valueElement.type === 'text' && valueElement.value.length > 0;
      const isValidMediaImages =
        valueElement.type === 'media' &&
        Array.isArray(valueElement.value) &&
        valueElement.value[0]?.mediaType === SupportedImageMediaTypes.IMAGE &&
        valueElement.value.length === 1;
      const isValidMediaVideos =
        valueElement.type === 'media' &&
        Array.isArray(valueElement.value) &&
        valueElement.value.length > 0 &&
        Object.values(SupportedVideoMediaTypes).includes(
          valueElement.value[0]?.mediaType,
        );
      const isValidMediaGalleries =
        valueElement.type === 'media' &&
        Array.isArray(valueElement.value) &&
        valueElement.value[0]?.mediaType === SupportedImageMediaTypes.IMAGE &&
        valueElement.value.length > 1;

      if (isValidMediaImages) {
        if (argsMatchingPolicy[elementKey]) {
          mapTypes[valueElement.value[0]?.mediaType]++;
        }
      }
      if (isValidMediaVideos) {
        if (argsMatchingPolicy[elementKey]) {
          mapTypes.video++;
        }
      }
      if (isValidMediaGalleries) {
        if (argsMatchingPolicy[elementKey]) {
          mapTypes.gallery++;
        }
      }
      if (isValidButton || isValidText) {
        if (argsMatchingPolicy[elementKey]) {
          mapTypes[valueElement.type]++;
        }
      }
    }
  });

  const isTextCountMatch =
    (originalCompMapping?.data?.[SupportedCompGeneralTypes.TEXT] || [])
      .length === mapTypes.text;

  const isMediaImagesCountMatch =
    (originalCompMapping?.data?.[SupportedCompGeneralTypes.MEDIA_IMAGES] || [])
      .length === (mapTypes.image || 0);

  const isMediaVideosCountMatch =
    (originalCompMapping?.data?.[SupportedCompGeneralTypes.MEDIA_VIDEOS] || [])
      .length === (mapTypes.video || 0);

  const isMediaGalleriesCountMatch =
    (
      originalCompMapping?.data?.[SupportedCompGeneralTypes.MEDIA_GALLERIES] ||
      []
    ).length === (mapTypes.gallery || 0);

  const isButtonCountMatch =
    (originalCompMapping?.data?.[SupportedCompGeneralTypes.BUTTON] || [])
      .length === mapTypes.button;

  return (
    isTextCountMatch &&
    isMediaImagesCountMatch &&
    isMediaVideosCountMatch &&
    isMediaGalleriesCountMatch &&
    isButtonCountMatch
  );
};

const filterPresetDuplications = (presets: any[]) => {
  const presetsByPresetId: any = {};

  presets.forEach((preset: any) => {
    if (!presetsByPresetId[preset.preset.id]) {
      presetsByPresetId[preset.preset.id] = preset;
    }
  });

  // assume the order is not meaningful
  return Object.values(presetsByPresetId);
};

// TODO create preset interface
const filterPAASPresetsByExactMatching = (
  originalCompMapping: OriginalCompMappingData,
  presets: any[],
) => {
  const filteredPresets = presets.filter((preset) => {
    let contentElementValues: any[] = [];
    const argsMatchingPolicy: any = preset.previewNode?.argsMatchingPolicy;
    if (originalCompMapping?.listData?.isList) {
      contentElementValues = preset.previewNode?.children?.reduce(
        (contentElements: any, currentChild: any) => {
          if (!contentElements) {
            contentElements = [];
          }
          if (currentChild.argsMatchingPolicy) {
            Object.entries(currentChild.argsMatchingPolicy).forEach(
              ([key, value]) => {
                if (!argsMatchingPolicy[key] && value) {
                  argsMatchingPolicy[key] = true;
                }
              },
            );
          }
          return contentElements.concat(
            Object.entries(currentChild?.contentElement?.values).map(
              (contentElement) => {
                return { [contentElement[0]]: contentElement[1] };
              },
            ),
          );
        },
        [],
      );
    } else {
      contentElementValues = Object.entries(
        preset.previewNode?.contentElement?.values,
      ).map((contentElement) => {
        return { [contentElement[0]]: contentElement[1] };
      });
    }
    return areComponentTypesMatch(
      contentElementValues,
      argsMatchingPolicy,
      originalCompMapping,
    );
  });
  return filteredPresets;
};

export const filterPaaSPresets = (
  originalCompMapping: OriginalCompMappingData,
  presets: any[],
) => {
  const uniquePresets = filterPresetDuplications(presets);

  const filteredPresets = filterPAASPresetsByExactMatching(
    originalCompMapping,
    uniquePresets,
  );
  return filteredPresets;
};

const getMediaImageContentItem = (
  imageDate: CompData,
): MediaImageContentItem => {
  return {
    uri: imageDate.uri,
    width: imageDate.width,
    Title: imageDate.title,
    height: imageDate.height,
    relativeUri: imageDate.uri,
    mediaType: imageDate.mediaType || imageDate.type.toLowerCase(),
    fileUrl: `media/${imageDate.uri}`,
    geo: ['all'],
    locales: ['all'],
    fileInput: { face: [] },
  };
};

const getMediaImageItems = (imageRefs: any[] | undefined) => {
  if (imageRefs && imageRefs.length > 0) {
    return (
      imageRefs?.map((imageRef) => {
        const imageRefData = imageRef.serialized.data;
        return getMediaImageContentItem(
          imageRefData
            ? imageRefData
            : imageRef.serialized.design?.background?.mediaRef,
        );
      }) || []
    );
  }
  return [];
};

export const getImageItems = async (imageRefs: any) => {
  const mediaImageItemsBeforeFaces = getMediaImageItems(imageRefs);
  //eslint-disable-next-line array-callback-return
  return Promise.all(
    mediaImageItemsBeforeFaces.map(async (media) => {
      const mediaInfo = await focalPoint.getImageInfo(media.uri);
      if (mediaInfo?.file_input?.face) {
        media.fileInput.face = mediaInfo.file_input.face;
      }
      return media;
    }),
  );
};

const getVideoPlaymodeContent = (videoProps: any) => {
  return {
    autoplay: videoProps.autoplay,
    loop: videoProps.loop,
  };
};

const getVideofileOutputContent = (videoRef: any): any => {
  const fileOutputImagesDimensions = {
    width: videoRef.posterImageRef.width,
    height: videoRef.posterImageRef.height,
  };
  return {
    image: videoRef.generatedPosters.map((fileOutputImageContentItem: any) => ({
      url: `media/${fileOutputImageContentItem}`,
      ...fileOutputImagesDimensions,
    })),
    video: videoRef.qualities.map((fileOutputVideoContentItem: any) => ({
      ...fileOutputVideoContentItem,
      format: 'mp4',
    })),
  };
};

const getInternalVideoContentItem = (
  serializedVideoComp: CompData,
): InternalVideoContentItem => {
  let currentVideoRef;
  if (serializedVideoComp.componentType === COMP_TYPES.VIDEO_PLAYER) {
    currentVideoRef = serializedVideoComp.data.videoRef;
  } else if (serializedVideoComp.componentType === COMP_TYPES.MEDIA_PLAYER) {
    currentVideoRef = serializedVideoComp.design.background.mediaRef;
  }

  if (!currentVideoRef) {
    return;
  }

  const videoProps = serializedVideoComp.props;
  return {
    Title: currentVideoRef.title,
    mediaType: SupportedVideoMediaTypes.VIDEO,
    fileBaseUrl: `video/${currentVideoRef.videoId}`,
    relativeUri: currentVideoRef.videoId,
    playmode: getVideoPlaymodeContent(videoProps),
    fileOutput: getVideofileOutputContent(currentVideoRef),
    fileInput: {
      duration: currentVideoRef.duration * 1000,
    },
  };
};

const getExternalVideoContentItem = (
  serializedVideoComp: CompData,
): ExternalVideoContentItem => {
  const videoData = serializedVideoComp.data;
  const contentItem: ExternalVideoContentItem = {
    mediaType: SupportedVideoMediaTypes.EXT_VIDEO,
    playmode: getVideoPlaymodeContent(serializedVideoComp.props),
    videoUrl: '',
  };
  if (serializedVideoComp.componentType === COMP_TYPES.VIDEO_PLAYER) {
    return {
      ...contentItem,
      videoUrl: videoData.videoUrl,
    };
  } else if (serializedVideoComp.componentType === COMP_TYPES.VIDEO) {
    return {
      ...contentItem,
      videoUrl: `${
        SUPPORTED_VIDEOTYPES_LINKS[videoData.videoType.toLowerCase()]
      }${videoData.videoId}`,
      videoId: videoData.videoId,
    };
  }
};

const getMediaVideoContentItem = (
  serializedVideoComp: CompData,
): MediaVideoContentItem | void => {
  if (!supportedVideoComponentTypes.has(serializedVideoComp.componentType)) {
    return;
  }

  const videoData = serializedVideoComp.data;

  if (
    videoData &&
    videoData?.type === SUPPORTED_VIDEO_DATATYPES.VIDEO_PLAYER &&
    Object.values(SupportedVideoTypes).includes(
      videoData.videoType.toLowerCase(),
    )
  ) {
    if (
      videoData.videoType === SupportedVideoTypes.FILE &&
      videoData.videoRef
    ) {
      return getInternalVideoContentItem(serializedVideoComp);
    }
    return getExternalVideoContentItem(serializedVideoComp);
  }

  if (
    videoData?.type === SUPPORTED_VIDEO_DATATYPES.VIDEO &&
    Object.values(SupportedVideoTypes).includes(
      videoData.videoType.toLowerCase(),
    )
  ) {
    return getExternalVideoContentItem(serializedVideoComp);
  }

  if (
    !videoData &&
    serializedVideoComp?.design?.background?.mediaRef?.type ===
      SUPPORTED_VIDEO_DATATYPES.WIX_VIDEO
  ) {
    return getInternalVideoContentItem(serializedVideoComp);
  }
};

const getMediaVideoItems = (
  originalCompMapping: OriginalCompMappingData,
): MediaVideoContentItem[] | any[] => {
  const videoItems: any[] | undefined =
    originalCompMapping.data[SupportedCompGeneralTypes.MEDIA_VIDEOS];
  const videoContentItems = (videoItems || []).map((videoItem) => {
    return getMediaVideoContentItem(videoItem.serialized);
  });
  const areAllItemsValid = videoContentItems.every(
    (videoContentItem) => !!videoContentItem,
  );
  if (!areAllItemsValid) {
    return null;
  }
  return videoContentItems;
};

const getMediaGalleryContentItem = (
  serializedGalleryComp: CompData,
): MediaImageContentItem[] | void => {
  return serializedGalleryComp.data.items.map(
    (imageContentItem: any): MediaImageContentItem => {
      return {
        uri: imageContentItem.uri,
        width: imageContentItem.width,
        Title: imageContentItem.title,
        height: imageContentItem.height,
        relativeUri: imageContentItem.uri,
        mediaType: 'image',
        fileUrl: `media/${imageContentItem.uri}`,
        geo: ['all'],
        locales: ['all'],
        fileInput: { face: [] },
      };
    },
  );
};

const getMediaGalleryItems = (
  originalCompMapping: OriginalCompMappingData,
): MediaGalleryContentItem[] | any[] => {
  const GalleryItems: any[] | undefined =
    originalCompMapping.data[SupportedCompGeneralTypes.MEDIA_GALLERIES];
  return (GalleryItems || []).map((galleryItem) => {
    return getMediaGalleryContentItem(galleryItem.serialized);
  });
};

export const getStageContentItems = async (
  editorAPI: EditorAPI,
  originalCompMapping: OriginalCompMappingData,
): Promise<ContentItem> => {
  let titleRef: CompRef;
  let subtitleRef: CompRef;
  let longTextRef: CompRef;
  let isContentValid = true;

  if (isOriginalCompMappingDataEmpty(originalCompMapping)) {
    return {
      button: null,
      title: null,
      subTitle: null,
      longText: null,
      media: [],
    };
  }

  fedopsLogger.interactionStarted(
    fedopsLogger.SWITCH_LAYOUT.SWITCH_LAYOUT_TEXT_CLASSIFFICATION,
  );
  if (originalCompMapping.data[SupportedCompGeneralTypes.TEXT]) {
    if (
      originalCompMapping.textClassificationData?.textClassificationPredictions
    ) {
      fedopsLogger.interactionEnded(
        fedopsLogger.SWITCH_LAYOUT.SWITCH_LAYOUT_TEXT_CLASSIFFICATION,
      );
      const smallTextIds: CompRef[] = [];
      Object.entries(
        originalCompMapping?.textClassificationData
          .textClassificationPredictions,
      ).forEach(([compId, classification]) => {
        if (
          originalCompMapping.data[SupportedCompGeneralTypes.TEXT].find(
            (textComp: TypeMappingComponentData) =>
              textComp.compRef.id === compId,
          )
        ) {
          const classificationValue = classification.classification;
          if (classificationValue === SupportedTextClassificationTypes.TITLE) {
            titleRef = editorAPI.components.get.byId(compId);
          } else if (
            classificationValue === SupportedTextClassificationTypes.SUBTITLE
          ) {
            subtitleRef = editorAPI.components.get.byId(compId);
          } else if (
            classificationValue === SupportedTextClassificationTypes.PARAGRAPH
          ) {
            longTextRef = editorAPI.components.get.byId(compId);
          } else {
            smallTextIds.push(editorAPI.components.get.byId(compId));
          }
        }
      });
      smallTextIds.forEach((compRef) => {
        if (!titleRef) {
          titleRef = compRef;
        } else if (!subtitleRef) {
          subtitleRef = compRef;
        } else if (!longTextRef) {
          longTextRef = compRef;
        }
      });
    } else {
      originalCompMapping.data[SupportedCompGeneralTypes.TEXT]?.forEach(
        (textComponentData: AnyFixMe, index: number) => {
          switch (index) {
            case 0:
              titleRef = textComponentData.compRef;
              break;
            case 1:
              subtitleRef = textComponentData.compRef;
              break;
            case 2:
              longTextRef = textComponentData.compRef;
          }
        },
      );
    }
  }

  const buttonIds =
    originalCompMapping.data[SupportedCompGeneralTypes.BUTTON] || [];
  const buttonRef = buttonIds[0]?.compRef;

  const imageRefs: any[] | undefined =
    originalCompMapping.data[SupportedCompGeneralTypes.MEDIA_IMAGES];
  const mediaImageItems = await getImageItems(imageRefs);
  let mediaItems: ContentItem['media'] = mediaImageItems;

  const mediaVideoItems = getMediaVideoItems(originalCompMapping);
  if (!mediaVideoItems) {
    isContentValid = false;
  } else if (mediaVideoItems.length > 0) {
    mediaItems = mediaVideoItems;
  }

  const mediaGalleryItems = getMediaGalleryItems(originalCompMapping);
  if (!mediaGalleryItems) {
    isContentValid = false;
  } else if (mediaGalleryItems[0]?.length) {
    mediaItems = mediaGalleryItems[0];
  }

  if (!isContentValid) {
    return null;
  }

  return {
    button: buttonRef ? editorAPI.components.data.get(buttonRef).label : null,
    title: titleRef ? getText(editorAPI, titleRef) : null,
    subTitle: subtitleRef ? getText(editorAPI, subtitleRef) : null,
    longText: longTextRef ? getText(editorAPI, longTextRef) : null,
    media: mediaItems,
  };
};

export const getSectionTopicIfExists = (
  sectionRef: CompRef,
  editorAPI: EditorAPI,
): CeType | null => {
  const contentRole = editorAPI.components.features.get(
    sectionRef,
    'contentRole',
  )?.contentRole;
  return ceTypes.has(contentRole) ? contentRole : null;
};

const getContentItems = async (
  editorAPI: EditorAPI,
  originalCompMapping: OriginalCompMappingData,
  topicDisplayName: CeType,
): Promise<PaaSContent> => {
  let items: ContentItem | ListContentItem;
  if (originalCompMapping.listData.isList) {
    items = await getListsContentItems(editorAPI, originalCompMapping);
  } else {
    items = await getStageContentItems(editorAPI, originalCompMapping);
  }

  return {
    contentItems: [items],
    isList: !!originalCompMapping.listData.isList,
    ceType: topicDisplayName,
    sitePart: 'body',
  };
};

export const getPaaSPresets = async (
  editorAPI: EditorAPI,
  originalCompMapping: OriginalCompMappingData,
  topicDisplayName: CeType,
  switchLayoutExperiments: any,
) => {
  let ceType = topicDisplayName;
  const editorPAASApi = editorAPI.host.getAPI(EditorPaasApiKey);

  const contentItems = await getContentItems(
    editorAPI,
    originalCompMapping,
    topicDisplayName,
  );
  if (!contentItems.contentItems[0]) {
    return null;
  }
  try {
    let preferredMedia: PreferredMedia;
    let maxPresetsAmount = 10000;
    if (switchLayoutExperiments.isSwitchLayoutMaxPaasPresetsOpen) {
      maxPresetsAmount = 50;
    }
    if (originalCompMapping.data[SupportedCompGeneralTypes.MEDIA_IMAGES]) {
      preferredMedia = 'image';
    }
    if (originalCompMapping.data[SupportedCompGeneralTypes.MEDIA_VIDEOS]) {
      preferredMedia = undefined;
    }
    if (originalCompMapping.data[SupportedCompGeneralTypes.MEDIA_GALLERIES]) {
      preferredMedia = 'gallery';
    }
    if ((originalCompMapping.listData?.resultListMap?.children || []).length) {
      preferredMedia = undefined;
      ceType = CeType.Product;
      contentItems.ceType = CeType.Product;
      maxPresetsAmount = 20;
    }
    const sectionPresets = await editorPAASApi.getSectionPresets(
      null,
      ceType,
      true,
      contentItems,
      preferredMedia,
      maxPresetsAmount,
    );
    return sectionPresets;
  } catch (err) {
    ErrorReporter.captureException(err, {
      tags: { switchLayoutFlow: true, getPaaSPresetsFlow: true },
    });
    return null;
  }
};

export const getLayoutsWithMetadata = (
  presets: Array<any>,
): Array<LayoutWithMetadata> => {
  return presets.map((preset) => {
    return {
      preset,
      isPaasPreset: true,
      id: preset.preset.id,
    };
  });
};

export const isContainerValidAsComponentForPaas = (
  compType: string,
  compDesign: CompStructure['design'],
): boolean => {
  return (
    containersValidAsPaasComponent.has(compType) &&
    containersValidBackgroundMediaTypes.has(
      compDesign?.background?.mediaRef?.type?.toLowerCase(),
    )
  );
};

export const getEmptyTypeMapping = () => {
  return {
    bi: {},
    data: {
      unsupported: {},
    },
    count: 0,
  };
};

const addToBiMap = (map: any, comp: string, compId: string) => {
  if (typeof map[comp]?.count !== 'number') {
    map[comp] = { count: 0, compIds: [], component_type: comp };
  }
  ++map[comp].count;
  map[comp].compIds.push(compId);
};

const addToDataMap = (
  map: any,
  comp: string,
  compRef: CompRef,
  serialized: CompStructure,
) => {
  if (!Array.isArray(map[comp])) {
    map[comp] = [];
  }
  map[comp].push({
    compRef,
    serialized,
  });
};

export const mapComponentTypes = (
  editorAPI: EditorAPI,
  serializedComp: any,
  typeMapping: any,
) => {
  if (!serializedComp?.componentType) {
    return typeMapping;
  }

  let isSupportedComponent =
    !!SUPPORTED_COMPS[serializedComp.componentType] &&
    !unsupportedForSnapifyComponentTypes.has(serializedComp?.componentType);

  if (
    serializedComp.componentType === COMP_TYPES.VIDEO_PLAYER &&
    !Object.values(SupportedVideoTypes).includes(
      serializedComp?.data?.videoType.toLowerCase(),
    )
  ) {
    isSupportedComponent = false;
  }

  if (CONTAINERS_TO_IGNORE_FOR_PAAS.has(serializedComp.componentType)) {
    if (serializedComp?.design?.background?.mediaRef) {
      if (
        serializedComp.design.background.mediaRef.type.toLowerCase() !==
        SupportedImageMediaTypes.IMAGE
      ) {
        addToDataMap(
          typeMapping.data.unsupported,
          SupportedCompGeneralTypes.MEDIA_VIDEOS,
          editorAPI.components.get.byId(serializedComp.id),
          serializedComp,
        );
      } else {
        addToDataMap(
          typeMapping.data,
          SupportedCompGeneralTypes.MEDIA_IMAGES,
          editorAPI.components.get.byId(serializedComp.id),
          serializedComp,
        );
      }
      addToBiMap(
        typeMapping.bi,
        SupportedCompGeneralTypes.MEDIA_IMAGES,
        serializedComp.id,
      );
      ++typeMapping.count;
    }
    serializedComp.components?.forEach((component: CompStructure) => {
      mapComponentTypes(editorAPI, component, typeMapping);
    });
  } else if (isSupportedComponent) {
    if (
      IS_COMP_UNSUPPORTED_BY_DATA[serializedComp.componentType]?.(
        serializedComp,
      )
    ) {
      addToDataMap(
        typeMapping.data.unsupported,
        SupportedCompGeneralTypes.MEDIA_VIDEOS,
        editorAPI.components.get.byId(serializedComp.id),
        serializedComp,
      );
    }

    if (
      SUPPORTED_COMPS[serializedComp.componentType] ===
      SupportedCompGeneralTypes.TEXT
    ) {
      if (serializedComp.data?.linkList?.length > 0) {
        serializedComp.data.linkListForSwitch = {};
        serializedComp.data?.text
          .match(/dataquery="#([a-zA-Z0-9])+([_-])([a-zA-Z0-9])+/g)
          .map((dataquery: string) => dataquery.replace('dataquery="#', ''))
          .forEach((linkId: any) => {
            const link = editorAPI.dsRead.data.getById(linkId);
            if (link) {
              delete link.id;
              serializedComp.data.linkListForSwitch[linkId] = link;
            } else {
              serializedComp.data.linkListForSwitch[linkId] = {};
            }
          });
      }
    }

    addToBiMap(typeMapping.bi, serializedComp.componentType, serializedComp.id);
    addToDataMap(
      typeMapping.data,
      SUPPORTED_COMPS[serializedComp.componentType],
      editorAPI.components.get.byId(serializedComp.id),
      serializedComp,
    );

    ++typeMapping.count;
  } else {
    addToBiMap(typeMapping.bi, serializedComp.componentType, serializedComp.id);
    addToDataMap(
      typeMapping.data.unsupported,
      serializedComp.componentType,
      editorAPI.components.get.byId(serializedComp.id),
      serializedComp,
    );

    ++typeMapping.count;
  }

  return typeMapping;
};

export const fetchSuggestionsPaas =
  (
    originalCompMapping: OriginalCompMappingData,
    switchLayoutExperiments: any,
    topicDisplayName: CeType,
  ) =>
  async (
    dispatch: Dispatch,
    getState: () => SwitchLayoutState,
    { editorAPI }: StateMapperArgs,
  ) => {
    fedopsLogger.interactionStarted(
      fedopsLogger.SWITCH_LAYOUT.GET_SUGGESTIONS_PAAS,
    );

    const presets = await getPaaSPresets(
      editorAPI,
      originalCompMapping,
      topicDisplayName,
      switchLayoutExperiments,
    );

    fedopsLogger.interactionEnded(
      fedopsLogger.SWITCH_LAYOUT.GET_SUGGESTIONS_PAAS,
    );

    if (presets) {
      if (
        originalCompMapping.listData.isList &&
        !switchLayoutExperiments.isFilterListPresetsOpen
      ) {
        return getLayoutsWithMetadata(presets);
      }
      const filteredPaasPresets = await filterPaaSPresets(
        originalCompMapping,
        presets,
      );
      return getLayoutsWithMetadata(filteredPaasPresets);
    }
    return [];
  };

// TODO: Refactor to pure function
export const isLayoutSupportedForPaaS = async (
  originalCompMappingData: OriginalCompMappingData,
  textClassificationApi: TextClassificationApi,
  listClassificationApi: ListClassificationApi,
  pageId: string,
  originalCompRef: CompRef,
  editorAPI: EditorAPI,
  switchLayoutExperiments: any,
  biData: BiData,
) => {
  if (Object.keys(originalCompMappingData.data.unsupported).length !== 0) {
    originalCompMappingData.listData.isList = false;
    return false;
  }

  const textClassificationPredictions =
    (await textClassificationApi.getTextClassification(
      editorAPI.pages.getCurrentPageId(),
      originalCompRef,
    )) as TextClassificationResponseData;

  if (textClassificationPredictions) {
    originalCompMappingData.textClassificationData.textClassificationPredictions =
      textClassificationPredictions;
  }

  const isCompCountValidForNonListSection =
    isComponentCountValidForNonListSection(originalCompMappingData.data);

  // In case of list we max instances is not relevant
  if (!isCompCountValidForNonListSection) {
    fedopsLogger.interactionStarted(
      fedopsLogger.SWITCH_LAYOUT.SWITCH_LAYOUT_LIST_CLASSIFICATION_PROCESS,
    );
    const listClassificationPredictions =
      (await listClassificationApi.getListClassification(
        pageId,
        originalCompRef,
      )) as any;
    originalCompMappingData.listData.listClassificationOutput =
      listClassificationPredictions;

    const availableLists = {};
    collectListsMapFromClassificationOutput(
      listClassificationPredictions,
      availableLists,
    );
    originalCompMappingData.listData.allIdentifiedLists = availableLists;
    // @ts-expect-error
    originalCompMappingData.listData.resultListMap =
      Object.values(availableLists)[0];
    originalCompMappingData.listData.originalExtractedList =
      Object.values(availableLists)[0];
    originalCompMappingData.listData.updatedListAfterRestructure = {};
    originalCompMappingData.listData.flattenListComponents = {};

    originalCompMappingData.listData.listIds = new Set<string>([]);
    collectListComponentIds(
      originalCompMappingData.listData.resultListMap,
      originalCompMappingData.listData.listIds,
      originalCompMappingData.listData.flattenListComponents,
    );

    const filteredOriginalCompMapping = {
      media_images: originalCompMappingData.data?.media_images?.filter(
        ({ compRef }) =>
          !originalCompMappingData.listData?.listIds.has(compRef.id),
      ),
      media_videos: originalCompMappingData.data?.media_videos?.filter(
        ({ compRef }) =>
          !originalCompMappingData.listData?.listIds.has(compRef.id),
      ),
      media_galleries: originalCompMappingData.data?.media_galleries?.filter(
        ({ compRef }) =>
          !originalCompMappingData.listData?.listIds.has(compRef.id),
      ),
      text: originalCompMappingData.data?.text?.filter(
        ({ compRef }) =>
          !originalCompMappingData.listData?.listIds.has(compRef.id),
      ),
      button: originalCompMappingData.data?.button?.filter(
        ({ compRef }) =>
          !originalCompMappingData.listData?.listIds.has(compRef.id),
      ),
    };

    const isCompCountValidForNonListSection =
      isComponentCountValidForNonListSection(filteredOriginalCompMapping);

    const isListValid = validateSectionGrouping(
      originalCompMappingData,
      editorAPI,
      biData,
    );
    const isSingleList = Object.entries(availableLists).length === 1;
    if (!isSingleList) {
      sendListInfo(
        editorAPI,
        biData,
        originalCompMappingData,
        LIST_PRECESS_INFO_OPTIONS.MORE_THAN_ONE_LIST_FOUND,
      );
    }
    if (!isListValid || !isSingleList || !isCompCountValidForNonListSection) {
      return false;
    }
    fedopsLogger.interactionEnded(
      fedopsLogger.SWITCH_LAYOUT.SWITCH_LAYOUT_LIST_CLASSIFICATION_PROCESS,
    );
    originalCompMappingData.listData.isList = true;
    originalCompMappingData.listData.nonListComponents = getEmptyTypeMapping();
    originalCompMappingData.listData.nonListComponents.data =
      filteredOriginalCompMapping;
    return true;
  }

  originalCompMappingData.listData.isList = false;
  return isCompCountValidForNonListSection;
};

export const isComponentCountValidForNonListSection = (
  dataMapping: any,
): boolean => {
  return !Object.keys(MAX_INSTACES_OF_SUPPORTED_TYPES_PAAS).some(
    (type) =>
      MAX_INSTACES_OF_SUPPORTED_TYPES_PAAS[type] < dataMapping[type]?.length,
  );
};
