'use strict'

/**
 * @param {Object} section
 * @param {Array} items
 * @returns {Object}
 */
function mergePropsItems(section, items) {
    // equivalent to `_.merge({}, section, {props: {items}})`, but faster

    return {
        ...section,
        props: {
            ...section.props,
            items
        }
    }
}

module.exports = mergePropsItems
