import type { Schema } from '@wix/wix-data-schema-types';
import React from 'react';

export function useRedirectToUserCollectionsTab({
  collections,
  selectTabByName,
  redirectEnabled = true,
}: {
  collections: Schema[];
  selectTabByName: (name: string) => void;
  redirectEnabled?: boolean;
}) {
  React.useEffect(() => {
    if (collections.length === 0 && redirectEnabled) {
      selectTabByName('ManageContent');
    }
  }, [collections.length, selectTabByName, redirectEnabled]);
}
