import { getDefaultOrigin } from './customize/customizeUtils';

import type { ITransformationData } from '../../interactionsTransformFacade';

export enum EffectTypes {
  NONE = 'none',
  GROW = 'grow',
  SHRINK = 'shrink',
  SKEW = 'skew',
  ROTATE = 'rotate',
  CUSTOM = 'custom',
}

export interface IEffect {
  value: EffectTypes;
  label: string;
  automationId: string;
  symbolName?: string;
}

export const EFFECT_LIMITS = {
  GROW: {
    MIN: 101,
    MAX: 200,
    DEFAULT: 110 / 100,
    INPUT_MAX: 500,
  },
  SHRINK: {
    MIN: 10,
    MAX: 99,
    DEFAULT: 90 / 100,
  },
  SKEW: {
    MIN: -50,
    MAX: 50,
    DEFAULT: -5,
  },
  ROTATE: {
    CUSTOM_MIN: -360,
    MIN: 1,
    MAX: 360,
    DEFAULT: 10,
    INPUT_MAX: 720,
  },
};

export const CUSTOMIZE_ALL_DEFAULTS: ITransformationData = {
  scale: { x: 1, y: 1 },
  rotate: 0,
  skew: { x: 0, y: 0 },
  origin: getDefaultOrigin(),
};

export const EFFECTS: IEffect[] = [
  {
    value: EffectTypes.NONE,
    label: 'interactions_effects_tab_animations_none',
    symbolName: 'thumb-none',
    automationId: EffectTypes.NONE,
  },
  {
    value: EffectTypes.GROW,
    label: 'interactions_effects_tab_animations_grow',
    symbolName: 'interactionsEffectsGrow',
    automationId: EffectTypes.GROW,
  },
  {
    value: EffectTypes.SHRINK,
    label: 'interactions_effects_tab_animations_shrink',
    symbolName: 'interactionsEffectsShrink',
    automationId: EffectTypes.SHRINK,
  },
  {
    value: EffectTypes.SKEW,
    label: 'interactions_effects_tab_animations_skew',
    symbolName: 'interactionsEffectsSkew',
    automationId: EffectTypes.SKEW,
  },
  {
    value: EffectTypes.ROTATE,
    label: 'interactions_effects_tab_animations_rotate',
    symbolName: 'interactionsEffectsRotate',
    automationId: EffectTypes.ROTATE,
  },
  {
    value: EffectTypes.CUSTOM,
    label: 'interactions_effects_tab_animations_custom',
    symbolName: 'interactionsEffectsCustomize',
    automationId: EffectTypes.CUSTOM,
  },
];

type ITransformationDataKey = keyof ITransformationData;
const EFFECT_KEYS: ITransformationDataKey[] = ['skew', 'rotate', 'scale'];

const isEffectActive = (effectName: any, effectData: any): boolean => {
  if (['skew', 'scale'].includes(effectName)) {
    return effectData && (effectData.x || effectData.y);
  }
  return EFFECT_KEYS.includes(effectName) && !!effectData;
};

export function getSelectedEffectFromTransformation(
  transformationData?: ITransformationData,
): EffectTypes {
  if (!transformationData) {
    return EffectTypes.NONE;
  }
  const activeEffects: ITransformationDataKey[] = (
    Object.keys(transformationData) as ITransformationDataKey[]
  ).filter((effectName) =>
    isEffectActive(effectName, transformationData[effectName]),
  );

  if (
    activeEffects.length > 1 ||
    (transformationData.scale &&
      transformationData.scale.x !== transformationData.scale.y)
  ) {
    return EffectTypes.CUSTOM;
  }
  const [activeEffect] = activeEffects;

  switch (activeEffect) {
    case 'scale':
      return transformationData.scale.x > 1 && transformationData.scale.y > 1
        ? EffectTypes.GROW
        : EffectTypes.SHRINK;
    case 'skew':
      return EffectTypes.SKEW;
    case 'rotate':
      return EffectTypes.ROTATE;
    default:
      return EffectTypes.NONE;
  }
}
