import React from 'react';

export const ConnectingLine = ({
  layoutBoundingBox,
  originalLayoutBoundingBox,
}: AnyFixMe) => {
  const lineProps = {
    x1: originalLayoutBoundingBox.x + originalLayoutBoundingBox.width / 2,
    y1: originalLayoutBoundingBox.y + originalLayoutBoundingBox.height / 2,
    x2: layoutBoundingBox.x + layoutBoundingBox.width / 2,
    y2: layoutBoundingBox.y + layoutBoundingBox.height / 2,
  };

  return (
    <svg className="center-to-original-center-connecting-line">
      <line {...lineProps} />
    </svg>
  );
};
