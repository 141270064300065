export enum Conditions {
  MobileEditor = 'MobileEditor',
  DesktopEditor = 'DesktopEditor',
  Multilingual = 'Multilingual',
  InstalledApp = 'InstalledApp',
  DataMode = 'DataMode',
  DevMode = 'DevMode',
  SitePublish = 'SitePublish',
}

export const ConditionsPriority: Conditions[] = [
  Conditions.MobileEditor,
  Conditions.DesktopEditor,
  Conditions.InstalledApp,
  Conditions.Multilingual,
  Conditions.DataMode,
  Conditions.DevMode,
  Conditions.SitePublish,
];
