// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['Stylable_Horizontal_Menu_Design_DiscoverMore_Label'], // TODO - replace with real title for Breadcrumbs
      sections: [addPanel.sections.getSections().menu.breadcrumbsSection],
    };
  },
};
