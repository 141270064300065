import React, {
  type MouseEvent,
  type ReactElement,
  type ReactNode,
} from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { cx } from '@/util';
import * as symbols from '@wix/santa-editor-symbols';

interface FakeLayoutItem {
  type?: 'text' | 'symbol';
  width?: number;
  content?: string;
  className?: string;
}

export interface TopBarButtonProps {
  label?: ReactNode;
  automationId?: string;
  dataHook?: string;
  className?: string;
  symbolName?: string;
  customSymbol?: ReactElement;
  symbolClassName?: string;
  labelClassName?: string;
  toggled?: boolean;
  disabled?: boolean;
  primary?: boolean;
  onClick?: (e: Event) => void;
  onMouseEnter?: (event: MouseEvent) => void;
  onMouseLeave?: (event: MouseEvent) => void;
  flagIconUrl?: string;
  fakeLayoutItems?: FakeLayoutItem[];
}

class TopBarButton extends React.Component<TopBarButtonProps> {
  static defaultProps = {
    disabled: false,
    primary: false,
    className: '',
    symbolClassName: '',
    onClick: _.noop,
  };

  getCssClasses = (): string => {
    const { disabled, label, fakeLayoutItems, toggled, primary } = this.props;

    return cx('top-bar-btn', this.props.className, {
      disabled,
      toggled,
      'top-bar-btn_primary': primary,
      'top-bar-symbol-btn': !label,
      'top-bar-fake-layout': !!fakeLayoutItems,
    });
  };

  getFakeLayoutItemCssClasses = (item: FakeLayoutItem): string => {
    return cx(
      'fake-layout-item',
      `fake-layout-type-${item.type}`,
      item.className,
    );
  };

  handleClick = (e: AnyFixMe) => {
    if (!this.props.disabled) {
      this.props.onClick(e);
      // @ts-expect-error
      ReactDOM.findDOMNode(this).blur();
    }
  };

  renderFakeLayout() {
    if (!this.props.fakeLayoutItems) {
      return null;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(this.props.fakeLayoutItems, (item, index) => (
      <div
        key={index}
        style={{ width: item.width }}
        className={this.getFakeLayoutItemCssClasses(item)}
      >
        {item.content}
      </div>
    ));
  }

  renderSymbol() {
    if (this.props.customSymbol) {
      return (
        <div key="customSymbol" className={this.props.symbolClassName}>
          {this.props.customSymbol}
        </div>
      );
    }

    if (this.props.symbolName) {
      return (
        <symbols.symbol
          key={`symbol-${this.props.symbolName}`}
          name={this.props.symbolName}
          className={cx('btn-symbol', this.props.symbolClassName)}
        />
      );
    }

    return null;
  }

  renderLabel() {
    if (!this.props.label) {
      return null;
    }

    return (
      <span key="label" className={cx('label', this.props.labelClassName)}>
        {this.props.label}
      </span>
    );
  }

  render() {
    return (
      <button
        data-aid={this.props.automationId}
        data-hook={this.props.dataHook}
        onClick={this.handleClick}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        className={this.getCssClasses()}
      >
        <span className="top-bar-btn-content-wrapper">
          {this.renderFakeLayout()}
          {this.renderSymbol()}
          {this.props.children}
          {this.renderLabel()}
        </span>
      </button>
    );
  }
}

export default TopBarButton;
