import _ from 'lodash';
import React from 'react';
import type { CompStructure } from '@wix/document-services-types';
import type { AnySection, PresetSectionItem } from '@wix/add-panel-common';
import * as core from '@/core';
import * as coreBi from '@/coreBi';
import type { EditorAPI } from '@/editorAPI';
import * as addPanelInfra from '@/addPanelInfra';
import { cx, array as arrayUtils, addPanel } from '@/util';
import * as stateManagement from '@/stateManagement';
import addPanelDataConsts from '@/addPanelDataConsts';

const { getStyleId } = stateManagement.components.selectors;

interface DesignPanelProps {
  panelName: string;
  style: AnyFixMe;
  selectedComponent: AnyFixMe[];
  compType: string;
  changePermanentState: FunctionFixMe;
  changeTempState?: FunctionFixMe;
  maintainOriginalState: FunctionFixMe;
  hideComponentSections?: boolean;
  getSections?: FunctionFixMe;
  getAdditionalElementProps?: FunctionFixMe;
  shouldHideContentOverflow?: boolean;
  withAdditionalBehaviors?: boolean;
  multiSelectedComponents?: AnyFixMe[];
  editorAPI: EditorAPI;
  enter: FunctionFixMe;
  leave: FunctionFixMe;
  children: AnyFixMe;
  designPanelHeader: string;
  designPanelTitle: string;
  skinName?: string;
  isSelectedItem: (item: string | PresetSectionItem['structure']) => boolean;
}

const unreachable = addPanel.deprecateOldAddPanel(() => {}, 'unreachable');

interface DesignPanelState {
  selectedItemKey: string;
}

const SELECTED_ITEM_INDICATOR_SYMBOL_NAME = 'selectedItemDesignPanel';

function areTherePresetSectionsOnly(sections: Array<AnySection>) {
  const types = sections.map(({ type }) => type);

  return _.isEqual(arrayUtils.uniq(types), [
    addPanelDataConsts.SECTIONS_TYPES.PRESET,
  ]);
}

export class DesignPanelContent extends React.Component<
  DesignPanelProps,
  DesignPanelState
> {
  constructor(props: DesignPanelProps) {
    super(props);
    this.state = {
      selectedItemKey: null,
    };
  }

  styleId: string;

  UNSAFE_componentWillReceiveProps(nextProps: DesignPanelProps) {
    this.styleId = getStyleId(
      this.props.selectedComponent,
      this.props.editorAPI.dsRead,
    );

    if (nextProps.hideComponentSections !== this.props.hideComponentSections) {
      this.setState({
        selectedItemKey: null,
      });
    }
  }

  shouldHideSection = (section: AnySection, sectionIndex: number): boolean => {
    const overriddenSections =
      stateManagement.designPanel.selectors.getOverriddenSections(
        this.props.editorAPI.store.getState(),
      );
    const id = this.getSectionRef(section, sectionIndex);
    return overriddenSections?.[id]?.isHidden;
  };

  getSections = (): AnySection[] => {
    if (this.props.getSections) {
      return this.props.getSections();
    }
    return (
      this.props.editorAPI.addPanel?.getSectionsByComponentType(
        this.props.compType,
        this.props.selectedComponent,
        this.props.withAdditionalBehaviors,
      ) ?? []
    );
  };

  onClick = (
    compDef: CompStructure,
    sectionTitle: string,
    sectionTags: PresetSectionItem['preset']['tags'],
    itemKey: string,
  ) => {
    this.props.changePermanentState(compDef, itemKey);
    this.setState({ selectedItemKey: itemKey });
    this.reportToBI(compDef, sectionTitle, sectionTags, itemKey);
  };

  reportToBI = (
    compDef: CompStructure,
    sectionTitle: string,
    sectionTags: PresetSectionItem['preset']['tags'],
    itemKey: string,
  ) => {
    const styleDef = core.styleManager.getCompDefStyle(
      compDef,
      this.props.editorAPI,
    );

    this.props.editorAPI.bi.event(
      coreBi.events.designPanel.DESIGN_PANEL_SELECT_PRESET_CLICK,
      {
        style_id: styleDef.id,
        preset_id: itemKey,
        component_type: this.props.compType,
        component_id: _.head(this.props.selectedComponent).id,
        preset_data_skin: styleDef?.skin,
        preset_data_tags: sectionTags,
        section: sectionTitle,
      },
    );
  };

  onMouseEnter = (compDef: CompStructure, itemKey: string) => {
    this.props.enter(null, compDef, itemKey, this.props.changeTempState);
  };

  onItemMouseLeave = (itemKey: string) => {
    this.props.leave(null, itemKey);
  };

  onPanelMouseLeave = () => {
    if (!this.props.hideComponentSections) {
      this.props.maintainOriginalState(true);
    }
  };

  getSectionRef = (section: AnySection, sectionIndex: number) => {
    return section.type + sectionIndex;
  };

  getSectionsClasses = () => {
    return {
      sections: true,
      'panel-has-children':
        !!this.props.children && !_.isEmpty(_.compact(this.props.children)),
    };
  };

  getAdditionalScaling = () => {
    return this.props.skinName === 'narrow'
      ? addPanelDataConsts.DESIGN_PANEL_SCALE_NARROW
      : addPanelDataConsts.DESIGN_PANEL_SCALE;
  };

  getSectionProps = (
    section: AnySection,
    sectionIndex: number,
    sections: AnySection[],
  ) => {
    const forcedCurrentStyleSelection =
      _.isFunction(this.props.isSelectedItem) &&
      areTherePresetSectionsOnly(sections);

    const imageCompType = 'wysiwyg.viewer.components.WPhoto';

    return {
      ...section,
      id: this.getSectionRef(section, sectionIndex),
      title: section.title || section?.translations?.presetTitle,
      additionalScaling: this.getAdditionalScaling(),
      onClick: this.onClick,
      isImageSection: this.props.compType === imageCompType,
      disableInfoIcon: true,
      onMouseEnter: this.onMouseEnter,
      onMouseLeave: this.onItemMouseLeave,
      isSelectedItem: this.props.isSelectedItem,
      selectedItemKey: this.state.selectedItemKey
        ? [this.state.selectedItemKey]
        : null,
      parentType: addPanelDataConsts.PANEL_TYPES.DESIGN_PANEL,
      nextSibling: sections[sectionIndex + 1],
      sectionIndex,
      getAdditionalElementProps: this.props.getAdditionalElementProps,
      multiSelectedComponents: this.props.multiSelectedComponents,
      getAdditionalItemProps: (item: AnyFixMe) => {
        let elemProps =
          (this.props.getAdditionalElementProps &&
            this.props.getAdditionalElementProps(
              item.structure,
              this.props.editorAPI,
            )) ||
          {};
        const isCurrentPresetItemSelected =
          this.state.selectedItemKey && this.state.selectedItemKey === item.id;
        const isCurrentStyleSelected =
          forcedCurrentStyleSelection &&
          this.props.isSelectedItem(item.structure);

        if (isCurrentPresetItemSelected || isCurrentStyleSelected) {
          const className = elemProps.className
            ? `${elemProps.className} `
            : '';
          elemProps = {
            ...elemProps,
            className: `${className}design-panel-selected-item`,
            symbolName: SELECTED_ITEM_INDICATOR_SYMBOL_NAME,
          };
        }
        return elemProps;
      },
    };
  };

  renderSections() {
    if (this.props.hideComponentSections) {
      return null;
    }

    const sections = this.getSections().filter(
      (section, index) => !this.shouldHideSection(section, index),
    );

    const hasAddPanelDesign = this.props.editorAPI.addPanel.hasAddPanelDesign(
      this.props.compType,
      this.props.selectedComponent,
    );

    if (!hasAddPanelDesign) {
      unreachable();
    }

    const renderedSections = hasAddPanelDesign
      ? this.renderNewAddPanelSections(sections)
      : this.renderAddPanelSections(sections);

    return (
      <div
        onMouseLeave={this.onPanelMouseLeave}
        key="designPanelSections"
        className={cx(this.getSectionsClasses())}
      >
        {renderedSections}
      </div>
    );
  }

  getNewAddPanelSectionProps = (section: AnySection) => {
    return {
      ...section,
      disableInfoIcon: true,
      additionalScaling: this.getAdditionalScaling(),
    };
  };

  renderNewAddPanelSections(sections: AnySection[]) {
    return sections.map((section, sectionIndex) => (
      <addPanelInfra.newAddPanelSectionView
        index={sectionIndex}
        key={section.systemId}
        viewType="design-panel"
        onClickHandler={this.onClick}
        onMouseEnterHandler={this.onMouseEnter}
        isSelectedItem={this.props.isSelectedItem}
        onMouseLeaveHandler={this.onItemMouseLeave}
        selectedItemKey={this.state.selectedItemKey}
        section={this.getNewAddPanelSectionProps(section)}
      />
    ));
  }

  renderAddPanelSections(sections: AnySection[]) {
    return sections.map((section, sectionIndex) => (
      <addPanelInfra.baseGenerator
        ref={this.getSectionRef(section, sectionIndex)}
        key={this.getSectionRef(section, sectionIndex)}
        {...this.getSectionProps(section, sectionIndex, sections)}
      />
    ));
  }

  render() {
    return (
      <>
        <div>{this.props.designPanelHeader}</div>
        {this.props.designPanelTitle ? (
          <div key="designPanelTitle" className="design-panel-title">
            {this.props.designPanelTitle}
          </div>
        ) : null}

        {this.renderSections()}

        <div>{this.props.children}</div>
      </>
    );
  }
}
