import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';
import {
  getCollectionType,
  CollectionType,
} from '@wix/wix-code-collections-api';
import { WIX_FORMS } from '@wix/app-definition-ids';
import { selectAppState } from './appState';

import type { Schema } from '@wix/wix-data-schema-types';

export const selectCollectionsLoaded = (state: EditorState) =>
  selectAppState(state).schemas !== undefined;

export const selectCollections = (state: EditorState) =>
  selectAppState(state).schemas || [];

export const selectCollectionsItemCounts = (state: EditorState) =>
  selectAppState(state).collectionItemCounts;

export const selectLiveCollectionItemCounts = (state: EditorState) =>
  selectCollectionsItemCounts(state)?.liveItems || [];

export const selectSandboxCollectionItemCounts = (state: EditorState) =>
  selectCollectionsItemCounts(state)?.sandboxItems || [];

export const selectTotalCollectionItemCount = (state: EditorState) => {
  const collections = selectCollections(state);
  return selectLiveCollectionItemCounts(state)
    .concat(selectSandboxCollectionItemCounts(state))
    .filter((collectionItemCount) => {
      const collection = collections.find(
        ({ id }) => id === collectionItemCount.collectionId,
      );
      return collection && !isFormsCollection(collection);
    })
    .reduce((total, collection) => total + collection.itemCount, 0);
};

const isUserCollection = (collection: Schema) => {
  return [CollectionType.USER, CollectionType.STATIC_APP_SCHEMA].includes(
    getCollectionType(collection),
  );
};

const isWixAppsCollection = (collection: Schema) =>
  getCollectionType(collection) === CollectionType.DB_DRIVER;

const isFormsCollection = (collection: Schema) =>
  collection.ownerAppId === WIX_FORMS;

export const selectUserCollections = (state: EditorState) => {
  const collections = selectCollections(state);
  return collections.filter(
    (collection) =>
      isUserCollection(collection) && !isFormsCollection(collection),
  );
};

export const selectFormsCollections = (state: EditorState) => {
  const collections = selectCollections(state);
  return collections.filter(isFormsCollection);
};

export const selectWixAppsCollections = (state: EditorState): Schema[] =>
  selectCollections(state).filter(
    (collection) =>
      isWixAppsCollection(collection) && !isFormsCollection(collection),
  );

export const selectExternalCollections = (state: EditorState): Schema[] => {
  const collections = selectCollections(state);
  return collections.filter(
    (collection) =>
      getCollectionType(collection) === CollectionType.EXTERNAL_DB_DRIVER,
  );
};

export const getCollections = (editorAPI: EditorAPI) =>
  selectCollections(editorAPI.store.getState());

export const getIsPermittedToAddCollection = (editorAPI: EditorAPI) =>
  editorAPI.platform.permissions.isCustomPermissionsGranted(['wix-code.admin']);

export const selectEnterpriseBrandName = (state: EditorState) =>
  selectAppState(state).enterpriseBrandName;
