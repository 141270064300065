import React from 'react';
import {
  Button,
  Composites,
  CustomScroll,
  Divider,
  DropDown,
  DropDownIllustrationOption,
  DropDownOption,
  DropDownStickyFooter,
  Text,
  TextButton,
  TextLabel,
} from '@wix/wix-base-ui';
import { hoc, panelUtils, fedopsLogger, sections } from '@/util';
import { translate } from '@/i18n';
import constants from '@/constants';
import * as baseUI from '@/baseUI';
import * as helpIds from '@/helpIds';
import { events } from '@/coreBi';
import {
  Anchor as AnchorIcon,
  Section,
} from '@wix/wix-ui-icons-common/classic-editor';

import {
  convertLinkPageIdToPageId,
  convertPageIdToLinkPageId,
} from '../../utils';
import {
  PageSelection,
  type PageSelectionComponentProps,
} from '../PageSelection/PageSelection';
import { LINK_TYPES } from '../../constants';
import { TRANSLATIONS } from './translations';
import { AUTOMATION_IDS } from './constants';
import {
  mapDispatchToProps,
  mapStateToProps,
  AnchorDataType,
} from './AnchorLink.mapper';

import type {
  AnchorLinkComponentStateProps,
  AnchorLinkComponentDispatchProps,
  AnchorData,
} from './AnchorLink.mapper';
import type { PagesData, CompRef } from 'types/documentServices';
import type { Anchor } from '@/util';
import type { LinkPanelBiCategories } from '../../LinkPanel';

interface Option {
  type?: AnchorDataType;
  label: string;
  value: string;
}

export interface TAnchorLink {
  id?: string;
  type: string;
  routerId?: string;
  innerRoute?: string;
  pageId?: string;
  anchorName?: string;
  anchorDataId?: string;
  isTpaRoute?: boolean;
  ref?: CompRef;
  selection?: string;
}

export interface AnchorLinkComponentOwnProps {
  panelName: string;
  biOrigin?: string;
  pageTabPageDropDownTitle?: string;
  link: TAnchorLink;
  pages: PagesData[];
  anchors: (AnchorData | Anchor)[];
  origin?: string;
  onValidationUpdate(isValid: boolean): void;
  onLinkChange(link: TAnchorLink): void;
  onCreateButtonClick(category: LinkPanelBiCategories): void;
  saveLink: (link: TAnchorLink) => string;
  sendBIAddCompFlow: (
    link: TAnchorLink,
    targetCompId: string,
    targetCompType: string,
  ) => void;
}

interface AnchorLinkComponentProps
  extends AnchorLinkComponentOwnProps,
    AnchorLinkComponentStateProps,
    AnchorLinkComponentDispatchProps {}

interface AnchorLinkComponentState {
  link: TAnchorLink;
  prevLink: TAnchorLink;
  isRouteLoading: boolean;
  isFetchingPage: boolean;
}

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

const NO_ANCHOR_PLACEHOLDER = 'NO_ANCHOR_PLACEHOLDER';

export class AnchorLinkComponent extends React.Component<
  AnchorLinkComponentProps,
  AnchorLinkComponentState
> {
  private isNewLinkPanelAnchorsFlowEnabled =
    panelUtils.isNewLinkPanelAnchorsFlowEnabled();
  private isLinkToSectionEnabled = sections.isSectionsEnabled();

  constructor(props: AnchorLinkComponentProps) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount() {
    this.props.onLinkChange(this.state.link);
    this.props.onValidationUpdate(
      !this.state.isRouteLoading &&
        !this.state.isFetchingPage &&
        Boolean(this.state.link.anchorDataId),
    );
    if (this.state.link.routerId) {
      this.getPageFromInnerRoute(this.state.link);
    }
  }

  componentDidUpdate(
    _prevProps: AnchorLinkComponentProps,
    prevState: AnchorLinkComponentState,
  ) {
    const { onValidationUpdate, onLinkChange } = this.props;
    if (prevState.link !== this.state.link) {
      onLinkChange(this.state.link);
    }
    if (
      prevState.isRouteLoading !== this.state.isRouteLoading ||
      prevState.isFetchingPage !== this.state.isFetchingPage ||
      prevState.link !== this.state.link
    ) {
      onValidationUpdate(
        !this.state.isRouteLoading &&
          !this.state.isFetchingPage &&
          Boolean(this.state.link.anchorDataId),
      );
    }
  }

  private getInitialState() {
    const { currentPageId } = this.props;

    const link = this.props.link || this.createDefaultAnchorLink();

    if (link.routerId) {
      if (link.innerRoute === 'CURRENT_INNER_ROUTE') {
        link.pageId = convertPageIdToLinkPageId(currentPageId);

        return {
          link,
          prevLink: link,
          isRouteLoading: true,
          isFetchingPage: false,
        };
      }

      return {
        link,
        prevLink: link,
        isRouteLoading: true,
        isFetchingPage: true,
      };
    }
    return {
      link,
      prevLink: link,
      isRouteLoading: false,
      isFetchingPage: false,
    };
  }

  static getDerivedStateFromProps(
    props: AnchorLinkComponentProps,
    state: AnchorLinkComponentState,
  ) {
    const nextLink =
      state.prevLink?.id !== props.link?.id ? props.link : state.link;

    return {
      prevLink: props.link,
      link: nextLink,
    };
  }

  private handleInitialInnerRoutingFetch = () => {
    this.setState({
      isRouteLoading: false,
    });
  };

  private createDefaultAnchorLink() {
    return {
      type: LINK_TYPES.AnchorLink,
      pageId: undefined,
      anchorName: undefined,
      anchorDataId: undefined,
    } as TAnchorLink;
  }

  private getPageFromInnerRoute(link: TAnchorLink) {
    const { routerId, innerRoute } = link;
    const { getPageFromInnerRoute } = this.props;

    getPageFromInnerRoute(routerId, innerRoute, (pageId: string) => {
      if (pageId) {
        this.setState({
          link: {
            ...link,
            pageId: convertPageIdToLinkPageId(pageId),
          },
          isFetchingPage: false,
        });
      } else {
        this.setState({
          link: this.createDefaultAnchorLink(),
          isFetchingPage: false,
        });
      }
    });
  }

  private setAnchor = (newAnchorId: string) => {
    if (newAnchorId === NO_ANCHOR_PLACEHOLDER) {
      return;
    }

    const link = this.getAnchorLink({ newAnchorId });

    this.setState({
      link,
    });
  };

  private hasAnchors(pageIdToCheck: string) {
    if (!pageIdToCheck) {
      return false;
    }

    return this.getAnchorOptions(pageIdToCheck).length !== 0;
  }

  private getAnchorOptions(pageId: string) {
    return this.props.anchors
      .filter(
        (anchor) =>
          convertLinkPageIdToPageId(anchor.pageId) ===
            convertLinkPageIdToPageId(pageId) ||
          convertLinkPageIdToPageId(anchor.pageId) === 'masterPage',
      )
      .sort((curr, next) => {
        return curr.y - next.y;
      });
  }

  private getDropDownOptions(): Option[] {
    const anchors = this.getAnchorOptions(this.state.link.pageId);
    return anchors.map((anchor) => {
      if (this.isLinkToSectionEnabled) {
        return {
          value: anchor.anchorDataId,
          label:
            (anchor as AnchorData).type === AnchorDataType.Section
              ? (anchor as AnchorData).displayName
              : anchor.anchorName,
          type: (anchor as AnchorData).type,
        };
      }
      return {
        value: anchor.anchorDataId,
        label: anchor.anchorName,
      };
    });
  }

  private getAnchorName(newAnchorId?: string) {
    const { anchors } = this.props;
    return newAnchorId !== undefined
      ? anchors.find((anchor) => anchor.anchorDataId === newAnchorId)
          ?.anchorName
      : undefined;
  }

  private static getAnchorIcon(type: AnchorDataType) {
    switch (type) {
      case AnchorDataType.Section:
        return <Section />;
      case AnchorDataType.Anchor:
        return <AnchorIcon />;
      default:
        return null;
    }
  }

  private getAnchorLink({
    newAnchorId,
    anchorType,
  }: {
    newAnchorId?: string;
    anchorType?: string;
  }): TAnchorLink {
    if (this.state.link.routerId) {
      return {
        type: LINK_TYPES.DynamicPageLink,
        anchorDataId: newAnchorId,
        routerId: this.state.link.routerId,
        innerRoute: this.state.link.innerRoute,
        pageId: this.state.link.pageId,
        anchorName: this.state.link.anchorName,
        selection: anchorType,
      };
    }

    const anchor = this.props.anchors.find(
      ({ anchorDataId }) => newAnchorId === anchorDataId,
    ) as AnchorData;

    return {
      type: LINK_TYPES.AnchorLink,
      anchorDataId: newAnchorId,
      pageId: this.state.link.pageId,
      anchorName: this.getAnchorName(newAnchorId),
      selection: anchor?.type || anchorType,
    };
  }

  private getDefaultDataForPage(pageId: string) {
    const anchors = this.getAnchorOptions(pageId);
    if (anchors.length > 0 || this.isNewLinkPanelAnchorsFlowEnabled) {
      return {
        type: LINK_TYPES.AnchorLink,
        pageId,
        anchorDataId: anchors[0]?.anchorDataId,
        anchorName: anchors[0]?.anchorName,
      };
    }
  }

  private getDefaultDataForDynamicPage(pageId: string, routerId: string) {
    const anchors = this.getAnchorOptions(pageId);
    if (anchors.length > 0 || this.isNewLinkPanelAnchorsFlowEnabled) {
      return {
        type: LINK_TYPES.DynamicPageLink,
        routerId,
        pageId,
        anchorName: anchors[0]?.anchorName,
        anchorDataId: anchors[0]?.anchorDataId,
      };
    }
  }

  private setPage = (page: PagesData) => {
    const pageId = convertPageIdToLinkPageId(page?.id);

    this.setState({
      isRouteLoading: false,
      link: {
        ...this.getDefaultDataForPage(pageId),
      },
    });
  };

  private setDynamicPage = async (page: PagesData, routerId: string) => {
    const pageId = convertPageIdToLinkPageId(page.id);

    this.setState({
      isRouteLoading: true,

      link: {
        ...this.getDefaultDataForDynamicPage(pageId, routerId),
      },
    });
  };

  private setInnerRoute = (innerRoute: string) => {
    const { link } = this.state;

    this.setState({
      isRouteLoading: false,

      link: {
        ...link,
        innerRoute,
      },
    });
  };

  private getPagesWithAnchors() {
    if (!this.props.anchors.length) {
      return [];
    }

    return this.props.pages.filter((page) => this.hasAnchors(page.id));
  }

  private openLearnMoreOnAnchor = () => {
    const biHelpParams = {
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
      learn_more: true,
    };
    let helpItem;

    if (this.props.isMobileEditor) {
      helpItem = helpIds.MOBILE_ONLY.LINK_PANEL.ANCHOR_LINK;
    } else if (this.isLinkToSectionEnabled) {
      helpItem = helpIds.LEARN_MORE.LINK_PANEL.LINK_TO_SECTION_LINK;
    } else {
      helpItem = '4932c49c-7282-48f0-8fa2-42d929981c40';
    }
    this.props.openHelpCenter(helpItem, null, biHelpParams);
  };

  renderPagesSelection = (
    pages: PagesData[],
    props: Partial<PageSelectionComponentProps> = {},
  ) => {
    let pageDropDownTitle =
      this.props.pageTabPageDropDownTitle || 'LINK_PANEL_PAGE_DROP_DOWN_LABEL';

    if (this.isLinkToSectionEnabled) {
      pageDropDownTitle =
        TRANSLATIONS.SECTIONS.LINK_PANEL_SECTION_PAGE_DROP_DOWN_LABEL;
    }

    return (
      <PageSelection
        pages={pages}
        origin={this.props.origin}
        onPageSelect={this.setPage}
        onDynamicPageSelect={this.setDynamicPage}
        onInnerRouteSelect={this.setInnerRoute}
        onInitialInnerRoutingFetch={this.handleInitialInnerRoutingFetch}
        shouldAddAnyItemOption
        addAnyItemTranslation={translate(
          'Platform_Link_Menu_Anchor_Item_Option_Any_Item',
        )}
        pageDropDownTitle={translate(pageDropDownTitle)}
        link={this.state.link}
        {...props}
      />
    );
  };

  renderDescriptionContainer = () => {
    return (
      <div className="anchor-link-description-container">
        <Text
          size="large"
          skin="secondary"
          weight="bold"
          shouldTranslate={false}
        >
          {translate(
            this.isLinkToSectionEnabled
              ? TRANSLATIONS.SECTIONS.LINK_PANEL_LINK_TO_SECTION_TITLE
              : 'LINK_PANEL_LINK_TO_ANCHOR_TITLE',
          )}
        </Text>

        <Text
          size="small"
          skin="secondary"
          weight="normal"
          enableEllipsis={false}
          shouldTranslate={false}
        >
          <span className="anchor-link-description">
            {translate(
              this.isLinkToSectionEnabled
                ? TRANSLATIONS.SECTIONS.LINK_PANEL_LINK_TO_SECTION_TEXT
                : 'LINK_PANEL_LINK_TO_ANCHOR_TEXT',
            )}{' '}
            <Button
              onClick={this.openLearnMoreOnAnchor}
              className="btn-text no-margin"
            >
              {translate(
                this.isLinkToSectionEnabled
                  ? TRANSLATIONS.SECTIONS.LINK_PANEL_LINK_TO_SECTION_LINK
                  : 'LINK_PANEL_LINK_TO_ANCHOR_LINK',
              )}
            </Button>
          </span>
        </Text>
      </div>
    );
  };

  getEmptyStateTitleKey = () =>
    this.props.isMobileEditor
      ? 'MOBILE_LINK_PANEL_NO_ANCHOR_TEXT_ADDPANEL_MENUS_TITLE'
      : 'LINK_PANEL_NO_ANCHOR_TITLE';

  private renderEmptyState() {
    return (
      <div key="noAnchorsWrapper" className="no-anchors">
        <h3>{translate(this.getEmptyStateTitleKey())}</h3>
        <p>
          {translate(
            this.props.isMobileEditor
              ? 'LINK_PANEL_ANCHOR_MOBILE_EMPTYSTATE_TEXT'
              : 'LINK_PANEL_NO_ANCHOR_TEXT_ADDPANEL_MENUS',
          )}{' '}
          <baseUI.button
            label={
              this.props.isMobileEditor
                ? 'MOBILE_LINK_PANEL_NO_ANCHOR_TEXT_ADDPANEL_MENUS_LINK'
                : 'Anchor_Settings_How_Link_Text'
            }
            onClick={this.openLearnMoreOnAnchor}
            className="btn-text"
          />
        </p>
      </div>
    );
  }

  private handleDropDownToggle = (isOpen: boolean) => {
    if (isOpen) {
      this.props.sendBI(events.linkPanel.link_panel_drop_down_open, {
        category: LINK_TYPES.AnchorLink,
        origin: this.props.origin,
      });
    }
  };

  private handleAddAnchorClick = () => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.LINK_PANEL.ADD_ANCHOR,
    );
    this.props.onCreateButtonClick('anchor');
    this.props.addAndLinkComp(
      this.getAnchorLink({ anchorType: AnchorDataType.Anchor }),
    );
  };

  private handleAddSectionClick = () => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.ADD_AND_LINK,
    );
    this.props.onCreateButtonClick('section');
    this.props.addAndLinkComp(
      this.getAnchorLink({ anchorType: AnchorDataType.Section }),
    );
  };

  render() {
    const { isFetchingPage } = this.state;
    const { isMobileEditor } = this.props;
    const pagesWithAnchors = this.getPagesWithAnchors();
    const anchorOptions = this.getDropDownOptions();

    if (isFetchingPage) {
      return null;
    }

    if (this.isLinkToSectionEnabled) {
      return (
        <section
          data-hook={AUTOMATION_IDS.ANCHOR_TAB}
          className="scroll-container scroll-container-anchor-link"
        >
          <CustomScroll heightRelativeToParent="100%">
            {this.renderDescriptionContainer()}
            <Divider />
            {this.renderPagesSelection(this.props.pages, {
              defaultPageId: this.props.currentPageId,
            })}

            <Composites.DropDownLabeled>
              <TextLabel
                shouldTranslate={false}
                value={translate(
                  TRANSLATIONS.SECTIONS
                    .LINK_PANEL_SECTION_SECTION_DROP_DOWN_LABEL,
                )}
              />
              <DropDown
                automationId="anchor-link-anchor-selection-dropdown"
                dataHook="anchor-selection-dropdown"
                shouldTranslate={false}
                value={this.state.link.anchorDataId}
                searchBox={true}
                onChange={this.setAnchor}
                onToggle={this.handleDropDownToggle}
                optionsContainerClassName="link-panel-options-container"
                optionsMaxHeight={300}
                disabled={this.state.isRouteLoading}
                placeholder={translate(
                  'LINK_PANEL_ANCHOR_DROP_DOWN_NO_ANCHOR_LABEL',
                )}
              >
                {this.getDropDownOptions().map((option) => (
                  <DropDownIllustrationOption
                    shouldTranslate={false}
                    key={option.value}
                    value={option.value}
                    label={option.label}
                  >
                    {AnchorLinkComponent.getAnchorIcon(option.type)}
                  </DropDownIllustrationOption>
                ))}

                {!isMobileEditor && (
                  <DropDownStickyFooter closeOnClick={true}>
                    <div className="footer-link-container">
                      <TextButton
                        dataHook={AUTOMATION_IDS.ADD_SECTION_BTN}
                        shouldTranslate={false}
                        onClick={this.handleAddSectionClick}
                        size="tiny"
                        prefixIcon={<Section />}
                      >
                        {translate(
                          TRANSLATIONS.SECTIONS
                            .LINK_PANEL_SECTION_ADD_SECTION_BUTTON,
                        )}
                      </TextButton>
                      <TextButton
                        dataHook={AUTOMATION_IDS.ADD_ANCHOR_BTN}
                        onClick={this.handleAddAnchorClick}
                        shouldTranslate={false}
                        size="tiny"
                        prefixIcon={<AnchorIcon />}
                      >
                        {translate(
                          TRANSLATIONS.SECTIONS
                            .LINK_PANEL_SECTION_ADD_ANCHOR_BUTTON,
                        )}
                      </TextButton>
                    </div>
                  </DropDownStickyFooter>
                )}
              </DropDown>
            </Composites.DropDownLabeled>
          </CustomScroll>
        </section>
      );
    }

    if (this.isNewLinkPanelAnchorsFlowEnabled && !isMobileEditor) {
      return (
        <section
          data-hook={AUTOMATION_IDS.ANCHOR_TAB}
          className="scroll-container scroll-container-anchor-link"
        >
          <CustomScroll heightRelativeToParent="100%">
            {this.renderDescriptionContainer()}
            <Divider />
            {this.renderPagesSelection(this.props.pages, {
              defaultPageId: this.props.currentPageId,
            })}

            <Composites.DropDownLabeled>
              <TextLabel
                shouldTranslate={false}
                value={translate('LINK_PANEL_PAGE_ANCHOR_DROP_DOWN_LABEL')}
              />
              <DropDown
                automationId="anchor-link-anchor-selection-dropdown"
                dataHook="anchor-selection-dropdown"
                shouldTranslate={false}
                value={this.state.link.anchorDataId}
                searchBox={true}
                onChange={this.setAnchor}
                onToggle={this.handleDropDownToggle}
                optionsContainerClassName="link-panel-options-container"
                optionsMaxHeight={300}
                disabled={this.state.isRouteLoading}
                placeholder={translate(
                  'LINK_PANEL_ANCHOR_DROP_DOWN_NO_ANCHOR_LABEL',
                )}
              >
                {(anchorOptions.length > 0
                  ? anchorOptions
                  : [
                      {
                        label: translate(
                          'LINK_PANEL_ANCHOR_DROP_DOWN_NO_ANCHOR_LABEL',
                        ),
                        value: NO_ANCHOR_PLACEHOLDER,
                      },
                    ]
                ).map((option) => (
                  <DropDownOption
                    shouldTranslate={false}
                    key={option.value}
                    value={option.value}
                    label={option.label}
                  />
                ))}
                <DropDownStickyFooter closeOnClick={true}>
                  <Button
                    automationId="add-anchor-button"
                    onClick={this.handleAddAnchorClick}
                    className="btn-text"
                  >
                    <span>
                      {translate(
                        anchorOptions.length === 0
                          ? 'LINK_PANEL_ANCHOR_DROP_DOWN_NO_ANCHOR_ADD_BUTTON'
                          : 'LINK_PANEL_ANCHOR_DROP_DOWN_ADD_NEW_ANCHOR_BUTTON',
                      )}
                    </span>
                  </Button>
                </DropDownStickyFooter>
              </DropDown>
            </Composites.DropDownLabeled>
          </CustomScroll>
        </section>
      );
    }

    return pagesWithAnchors.length ? (
      <section data-hook={AUTOMATION_IDS.ANCHOR_TAB}>
        {this.isNewLinkPanelAnchorsFlowEnabled &&
          this.renderDescriptionContainer()}
        {this.renderPagesSelection(pagesWithAnchors)}
        {this.state.link.pageId && (
          <Composites.DropDownLabeled>
            <TextLabel value="LINK_PANEL_PAGE_ANCHOR_DROP_DOWN_LABEL" />
            <DropDown
              automationId="anchor-link-anchor-selection-dropdown"
              dataHook="anchor-selection-dropdown"
              shouldTranslate={false}
              value={this.state.link.anchorDataId}
              searchBox={true}
              onChange={this.setAnchor}
              onToggle={this.handleDropDownToggle}
              optionsContainerClassName="link-panel-options-container"
              disabled={
                !this.hasAnchors(this.state.link.pageId) ||
                this.state.isRouteLoading
              }
            >
              {this.getDropDownOptions().map((option) => (
                <DropDownOption
                  shouldTranslate={false}
                  key={option.value}
                  value={option.value}
                  label={option.label}
                />
              ))}
            </DropDown>
          </Composites.DropDownLabeled>
        )}
      </section>
    ) : (
      this.renderEmptyState()
    );
  }
}

export const AnchorLink = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(AnchorLinkComponent);

AnchorLink.pure = AnchorLinkComponent;
