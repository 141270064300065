import { $layoutY } from '@/layout';
import constants from '@/constants';
import { getAnchorTarget } from '@/anchors';
import { GET_LAYOUT_OPTIONS } from './constants';

import type { CompLayout } from 'types/documentServices';
import type { GroupingItem } from '../../api/getPageGrouping';
import type { ComponentWithLayout } from './types';

type FindGroupFunction = (
  compLayout: CompLayout,
  args: {
    groups: GroupingItem[];
    componentsOfGroups: ComponentWithLayout[];
  },
) => GroupingItem;

const findClosestComponentGroupByMiddleY: FindGroupFunction = (
  compLayout,
  { groups, componentsOfGroups },
) => {
  const closest = $layoutY(compLayout).getClosest(componentsOfGroups, {
    ...GET_LAYOUT_OPTIONS,
    byMiddleY: true,
  });

  return (
    closest &&
    groups.find((group) => group.children.includes(closest.compRef.id))
  );
};

const findAnchorGroup: FindGroupFunction = (
  anchorLayout,
  { groups, componentsOfGroups },
) => {
  const anchorTarget = getAnchorTarget(
    anchorLayout,
    componentsOfGroups,
    GET_LAYOUT_OPTIONS,
  );

  return anchorTarget
    ? groups.find((group) => group.children.includes(anchorTarget.compRef.id))
    : // fallback to the closest group by component middleY
      findClosestComponentGroupByMiddleY(anchorLayout, {
        groups,
        componentsOfGroups,
      });
};

const FindGroupByTypeMap = new Map<string, FindGroupFunction>([
  [constants.COMP_TYPES.ANCHOR, findAnchorGroup],
]);

export const findComponentGroup = (
  component: ComponentWithLayout,
  {
    groups,
    componentType,
    componentsOfGroups,
  }: {
    groups: GroupingItem[];
    componentType: string;
    componentsOfGroups: ComponentWithLayout[];
  },
) => {
  if (groups.length === 1) {
    return groups[0];
  }

  const findGroup =
    FindGroupByTypeMap.get(componentType) ?? findClosestComponentGroupByMiddleY;

  return findGroup(component.layout, {
    groups,
    componentsOfGroups,
  });
};
