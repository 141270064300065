import advancedStylePanel from './templates/advancedStylePanelComponent';
import multiComponentAdvancedStylePanel from './templates/multiComponentAdvancedStylePanel/multiComponentAdvancedStylePanel';
import tpaGalleryAdvancedStylePanel from './tpaGalleries/tpaGalleryAdvancedStylePanel';
import svgShapeAdvancedStylePanel from './templates/svgShapeAdvancedStylePanel';
import boxSlideShowSlideAdvancedStylePanel from './templates/boxSlideShowSlideAdvancedStylePanel';
import advancedStylePanelStandalone from './templates/advancedStylePanelStandalone';
import widgetDesignAdvancedStylePanel from './templates/multiComponentAdvancedStylePanel/widgetDesignAdvancedStylePanel';
import {
  calculateCommonStyle,
  getSupportedTabs,
} from './templates/multiComponentAdvancedStylePanel/widgetDesignHelpers';
import type { WRichTextSettingsPanelProps } from './templates/multiComponentAdvancedStylePanel/types';
import * as widgetDesignPanelMapper from './templates/multiComponentAdvancedStylePanel/widgetDesignPanelMapper';
import WidgetDesignPanelAdvancedStyleSection from './templates/multiComponentAdvancedStylePanel/widgetDesignPanelAdvancedStyleSection';
import WidgetDesignPanelElementsSection from './templates/multiComponentAdvancedStylePanel/widgetDesignPanelElementsSection';
import WidgetDesignPanelPresetSection from './templates/multiComponentAdvancedStylePanel/WidgetDesignPanelPresetSection';
import WidgetDesignPanelStylableSection from './templates/multiComponentAdvancedStylePanel/WidgetDesignPanelStylableSection';

export {
  calculateCommonStyle,
  getSupportedTabs,
  advancedStylePanel as panel,
  tpaGalleryAdvancedStylePanel as tpaGallery,
  svgShapeAdvancedStylePanel as svgShape,
  boxSlideShowSlideAdvancedStylePanel as boxSlideShowSlide,
  /**
   * To use:
   * editorAPI.panelManager.openPanel('advancedStylePanel.advancedStylePanel', {selectedComponents})
   */
  advancedStylePanelStandalone as advancedStylePanel,
  multiComponentAdvancedStylePanel,
  widgetDesignAdvancedStylePanel,
  type WRichTextSettingsPanelProps,
  widgetDesignPanelMapper,
  WidgetDesignPanelAdvancedStyleSection,
  WidgetDesignPanelElementsSection,
  WidgetDesignPanelPresetSection,
  WidgetDesignPanelStylableSection,
};
