import type { ComponentType } from 'react';
import { useQuery } from 'react-query';
import { loadComponentByPath } from '@/lazyComponent';
import type { Props } from './openedLeftPanels';

const KEY = 'LazyLeftPanels';

interface UseLazyLeftPanelParams {
  panelName: Props['panelName'];
}

export const useLazyLeftPanel = ({
  panelName,
}: UseLazyLeftPanelParams): ComponentType<any> | null => {
  const { data } = useQuery([KEY, panelName], async () => {
    if (!panelName) {
      return null;
    }

    return await loadComponentByPath(panelName)
      .then((Component) => {
        // can't just export Component, need to wrap it into object.
        // It looks like react-query transforms FCs somehow, and it becomes an element instead
        return { Component };
      })
      .catch((error) => {
        // eslint-disable-next-line no-console

        return Promise.reject(error);
      });
  });

  return data?.Component;
};
