import * as coreBi from '@/coreBi';
import constants from '@/constants';
import { biLogger, fixedStage, siteCreation } from '@/util';
import * as stateManagement from '@/stateManagement';
import experiment from 'experiment';

import type { EditorAPI } from '@/editorAPI';
import type {
  Dispatch,
  StateMapperArgs,
  MapStateToProps,
  MapDispatchToPropsFunction,
} from 'types/redux';
import { topBarClick } from '@wix/bi-logger-editor/v2';

const { event } = stateManagement.bi.actions;
const { updateOrOpenPanel } = stateManagement.panels.actions;
const { hideDomainSuggestion } = stateManagement.domainSuggestions.actions;
const { getDomainSuggestions, isDomainSuggestionsTurnedOff } =
  stateManagement.domainSuggestions.selectors;
const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;
const domainSelectors = stateManagement.domain.selectors;
const { USER_MADE_MANUAL_SAVE } = constants.USER_PREFS.SAVE;
const { getUserToggledPreviewInCurrentSession } =
  stateManagement.preview.selectors;
const { getIsSessionInitializedWithWizard } =
  stateManagement.siteCreation.selectors;
const GENERIC_MY_SITE_URL = 'https://www.wix.com/mysite';

export interface UrlBarStateProps {
  publicUrl: string;
  isFirstSave: boolean;
  isSitePremium: boolean;
  isSitePublished: boolean;
  domainSuggestion: string;
  isDomainConnected: boolean;
  nonInteractivePublicUrl: boolean;
  shouldRenderOnlyPublicUrl: boolean;
  shouldRenderDomainSuggestion: boolean;
}

export interface UrlBarDispatchProps {
  publicUrlHandler: () => void;
  onCloseDomainSuggestion: () => void;
  sendBiDomainSuggestionShow: () => void;
  connectDomainUrlHandler: (domainSuggestion: string) => void;
  connectDomainCTAHandler: (domainSuggestion: string) => void;
}

function getSanitizedPublicUrl(publicUrl: string = '') {
  if (publicUrl.endsWith('/')) {
    publicUrl = publicUrl.slice(0, -1);
  }
  return publicUrl.replace(/https?:\/\//, '');
}

const shouldShowDomainSuggestion = (
  isSiteCreationTemplate: boolean,
  userHadManualSave: boolean,
  userToggledPreview: boolean,
  isSitePublished: boolean,
  canUserUpgrade: boolean,
  domainSuggestion: string,
  isDomainConnected: boolean,
  isSchoolMode: boolean,
  domainSuggestionsTurnedOff: boolean,
  isSessionInitializedWithWizard: boolean,
): boolean => {
  let shouldShowByFlowsCondition =
    userHadManualSave || userToggledPreview || isSitePublished;
  if (isSiteCreationTemplate) {
    shouldShowByFlowsCondition =
      !isSessionInitializedWithWizard || shouldShowByFlowsCondition;
  }
  return (
    canUserUpgrade &&
    domainSuggestion &&
    !isDomainConnected &&
    !isSchoolMode &&
    !domainSuggestionsTurnedOff &&
    shouldShowByFlowsCondition
  );
};

const shouldShowGenericSiteUrl = (
  userHadManualSave: boolean,
  userToggledPreview: boolean,
  isSitePublished: boolean,
  canUserUpgrade: boolean,
  isDomainConnected: boolean,
): boolean => {
  return (
    canUserUpgrade &&
    !isDomainConnected &&
    !isSitePublished &&
    !userHadManualSave
  );
};

export const mapStateToProps: MapStateToProps<UrlBarStateProps, {}> = ({
  editorAPI,
  dsRead,
  state,
}: StateMapperArgs) => {
  const isSiteCreationTemplate = siteCreation.isSiteCreationSectionsTemplate();
  const domainSuggestion = getDomainSuggestions(state)[0];
  const userToggledPreview = getUserToggledPreviewInCurrentSession(state);
  const isSessionInitializedWithWizard =
    getIsSessionInitializedWithWizard(state);
  const domainSuggestionsTurnedOff = isDomainSuggestionsTurnedOff(state);
  const userHadManualSave = !!getSiteUserPreferences(USER_MADE_MANUAL_SAVE)(
    state,
  );
  const isSchoolMode = stateManagement.schoolMode.selectors.isEnabled(state);
  const canUserUpgrade = editorAPI.dsRead?.account.canUserUpgrade();
  const isSitePremium = editorAPI.site.isPremium();
  const isDomainConnected = domainSelectors.isDomainConnected(dsRead);
  const isSitePublished = editorAPI.dsRead?.generalInfo.isSitePublished();
  const shouldShowGenericMySiteUrl = shouldShowGenericSiteUrl(
    userHadManualSave,
    userToggledPreview,
    isSitePublished,
    canUserUpgrade,
    isDomainConnected,
  );
  const publicUrl = shouldShowGenericMySiteUrl
    ? GENERIC_MY_SITE_URL
    : editorAPI.dsRead?.generalInfo.getPublicUrl();
  const canUserPublish = editorAPI.savePublish.canUserPublish();
  const isFirstSave =
    editorAPI.dsRead?.generalInfo.isFirstSave() ||
    editorAPI.dsRead?.generalInfo.isDraft();
  const nonInteractivePublicUrl =
    !isDomainConnected ||
    isFirstSave ||
    !isSitePublished ||
    (!isSitePublished && !canUserPublish) ||
    (!domainSuggestion && !isDomainConnected && !isSitePublished);

  const shouldRenderDomainSuggestion = shouldShowDomainSuggestion(
    isSiteCreationTemplate,
    userHadManualSave,
    userToggledPreview,
    isSitePublished,
    canUserUpgrade,
    domainSuggestion,
    isDomainConnected,
    isSchoolMode,
    domainSuggestionsTurnedOff,
    isSessionInitializedWithWizard,
  );
  return {
    nonInteractivePublicUrl,
    isFirstSave,
    isSitePremium,
    isSitePublished,
    domainSuggestion,
    isDomainConnected,
    shouldRenderDomainSuggestion,
    publicUrl: isDomainConnected ? getSanitizedPublicUrl(publicUrl) : publicUrl,
    shouldRenderOnlyPublicUrl:
      !canUserUpgrade || isDomainConnected || isSchoolMode,
  };
};

const connectDomainHandler = (
  editorAPI: EditorAPI,
  domainSuggestion: string,
) => {
  if (experiment.isOpen('se_newAddDomainEntrypoint')) {
    const queryParam = domainSuggestion
      ? `?domainName=${domainSuggestion}`
      : '';
    const path = `/add-domain${queryParam}`;
    editorAPI.account.openSettings({
      path,
    });
  } else if (editorAPI.site.isPremium()) {
    editorAPI.account.openSettings({ tab: 'domains' });
  } else {
    editorAPI.store.dispatch(
      updateOrOpenPanel(constants.PANELS.SAVE_PUBLISH.CONNECT_DOMAIN, {
        domainSuggestion,
        title: 'Topbar_Connect_Domain_Modal_Title',
        subtitle: 'Topbar_Connect_Domain_Modal_Text',
        isFirstSave:
          editorAPI.dsRead?.generalInfo.isFirstSave() ||
          editorAPI.dsRead?.generalInfo.isDraft(),
        isPremiumSite: editorAPI.site.isPremium(),
        isFirstPublish: !editorAPI.dsRead?.generalInfo.isSitePublished(),
        flowId: constants.CONNECT_DOMAIN.FLOWS.URL_BAR,
        origin: constants.CONNECT_DOMAIN.FLOWS.URL_BAR,
      }),
    );
  }
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export const mapDispatchToProps: MapDispatchToPropsFunction<
  UrlBarDispatchProps,
  {}
> = (dispatch: Dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  const reportConnectDomainClick = () => {
    biLogger.report(
      topBarClick({
        category: 'fake_browser',
        is_published: editorAPI.dsRead?.generalInfo.isSitePublished(),
        state: fixedStage.getTopBarStateBiParamValue(editorAPI),
      }),
    );
  };

  return {
    connectDomainUrlHandler: (domainSuggestion: string) => {
      connectDomainHandler(editorAPI, domainSuggestion);
      dispatch(
        event(coreBi.events.topbar.urlBar.connect_domain_click, {
          domain_type: 'suggested',
          action: 'suggestion',
        }),
      );

      reportConnectDomainClick();
    },
    connectDomainCTAHandler: (domainSuggestion: string) => {
      dispatch(
        event(coreBi.events.topbar.urlBar.connect_domain_click, {
          domain_type: domainSuggestion ? 'suggested' : 'general',
          action: 'connect_your_domain',
        }),
      );
      connectDomainHandler(editorAPI, domainSuggestion);
      reportConnectDomainClick();
    },
    sendBiDomainSuggestionShow: () => {
      editorAPI.bi.event(coreBi.events.topbar.urlBar.suggested_domain_show);
    },
    publicUrlHandler: () => {
      if (editorAPI.dsRead?.generalInfo.isSitePublished()) {
        const sitePublicUrl = editorAPI.dsRead?.generalInfo.getPublicUrl();
        window.open(sitePublicUrl, '_blank');
      }
    },
    onCloseDomainSuggestion: () => {
      dispatch(hideDomainSuggestion());
      dispatch(
        event(coreBi.events.panels.PANEL_CLOSED, {
          panel_name: 'domain_suggestion',
        }),
      );
    },
  };
};
