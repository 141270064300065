import constants from '@/constants';
import * as coreBi from '@/coreBi';
import type { EditorAPI } from '@/editorAPI';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import React, { type FC } from 'react';
import type {
  MapDispatchToPropsFunction,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type {
  SnapshotSectionDispatchProps,
  SnapshotSectionOwnProps,
  SnapshotSectionStateProps,
} from './types';

const SavedComponentsSection: FC<
  SnapshotSectionOwnProps &
    SnapshotSectionStateProps &
    SnapshotSectionDispatchProps
> = (props) => {
  const { snapshotSectionTemplate: SnapshotSection, ...otherProps } = props;

  return (
    <div data-automation-id="add-panel-section-savedComponentsSection">
      <SnapshotSection {...otherProps} />
    </div>
  );
};

const { getSavedComponents } = stateManagement.savedComponents.selectors;
const { actions } = stateManagement.savedComponents;

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

const mapStateToProps: MapStateToProps<
  SnapshotSectionStateProps,
  SnapshotSectionOwnProps
> = ({ editorAPI, state }) => ({
  items: getSavedComponents(state),
  snapshotSectionTemplate: editorAPI.addPanelInfra.snapshotSection,
});

export const mapDispatchToProps: MapDispatchToPropsFunction<
  SnapshotSectionDispatchProps,
  SnapshotSectionOwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    fetchCollection() {
      dispatch(actions.fetchCollection());
    },
    fetchItemPreview(id) {
      dispatch(actions.fetchItemPreview(id));
    },
    pasteItem(
      id,
      shouldApplyTargetTheme,
      pastePosition,
      addingMethod,
      pasteContainer,
    ) {
      dispatch(
        actions.pasteItem(id, {
          shouldApplyTargetTheme,
          pastePosition,
          addingMethod,
          elementId: id,
          pasteContainer,
        }),
      );
    },
    renameItem(id, name) {
      editorAPI.panelManager.openPanel(
        constants.PANELS.SAVED_COMPONENTS.RENAME_COMPONENT_PANEL_NAME,
        {
          name,
          onConfirm: (newName: string) =>
            dispatch(actions.renameItem(id, newName)),
        },
        true,
      );
    },
    deleteItem(id, name) {
      dispatch(
        stateManagement.bi.actions.event(
          coreBi.events.savedComponents.delete_from_library,
          { element_id: id },
        ),
      );
      editorAPI.panelManager.openPanel(
        constants.PANELS.SAVED_COMPONENTS.DELETE_COMPONENT_PANEL_NAME,
        {
          name,
          onConfirm: () => {
            dispatch(actions.deleteItem(id));
            dispatch(
              stateManagement.bi.actions.event(
                coreBi.events.savedComponents.confirm_deletion_from_library,
                {
                  element_id: id,
                  success: true,
                },
              ),
            );
          },
        },
        true,
      );
    },
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(SavedComponentsSection);
