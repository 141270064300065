// @ts-nocheck
import PropTypes from 'prop-types';
import * as util from '@/util';
import { translate } from '@/i18n';
import React from 'react';
import * as UI from '@/baseUI';
import * as symbols from '@wix/santa-editor-symbols';
import constants from '@/constants';
import { cx } from '@/util';

const helveticaFallback =
  'HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  fontFamily?: string;
  name?: string;
  status?: string;
  onDownloadClick?: FunctionFixMe;
  onRemoveClick?: FunctionFixMe;
  onCancelClick?: FunctionFixMe;
  downloadable?: boolean;
  removeable?: boolean;
  cancelable?: boolean;
  isStillUploading?: boolean;
  errorCode?: string;
}

export default class extends React.Component<Props> {
  static displayName = 'fontItem';

  static propTypes = {
    fontFamily: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    onDownloadClick: PropTypes.func,
    onRemoveClick: PropTypes.func,
    onCancelClick: PropTypes.func,
    downloadable: PropTypes.bool,
    removeable: PropTypes.bool,
    cancelable: PropTypes.bool,
    isStillUploading: PropTypes.bool,
    errorCode: PropTypes.string,
  };

  getStyle = () => {
    if (this.props.fontFamily) {
      const fontFamily = `${this.props.fontFamily},${helveticaFallback}`;
      return { fontFamily };
    }
    return {};
  };

  shouldShowActions = () => {
    return this.props.downloadable || this.props.removeable;
  };

  shouldShowCancel = () => {
    return this.props.cancelable;
  };

  getStatusSymbol = () => {
    const fontStatus = {
      uploading: 'font-uploading-loader',
      waiting: 'font-uploading-loader',
      processing: 'font-uploading-loader',
      succeeded: 'inputValidationSuccess',
      failed: 'inputValidationError',
    };
    return fontStatus[this.props.status];
  };

  getDownloadProps = () => {
    const props = {};
    if (util.browserUtil.isChrome()) {
      props.download = this.props.fileName;
    } else {
      props.target = '_blank';
    }
    return props;
  };

  getStatusMessage = () => {
    let msg = '';
    if (this.props.errorCode) {
      msg = util.fontsManagerUtils.getErrorMessage(
        this.props.errorCode,
      ).translate_msg;
    } else if (this.props.isStillUploading) {
      msg = 'Upload_Fonts_Status_Still_Uploading';
    } else {
      msg =
        util.fontsManagerUtils.constants.FONT_STATUS_MSG[this.props.status] ||
        '';
    }
    return msg;
  };

  render() {
    return (
      <div
        className={`font-item ${
          this.shouldShowActions() ? 'with-actions ' : ''
        } ${this.shouldShowCancel() ? 'cancelable' : ''} ${
          this.props.status && !this.props.fontFamily ? 'queue' : 'list'
        } ${this.props.errorCode ? 'error' : ''} ${
          this.props.finished ? 'animated' : ''
        }`}
      >
        <div
          className={`font-description ${
            this.props.fontFamily ? 'uploaded' : ''
          }`}
        >
          <span
            style={this.getStyle()}
            className={`font-title display-name ${
              this.props.fontFamily ? 'uploaded' : ''
            }`}
          >
            {this.props.name}
          </span>
          <span className="font-title file-name">{this.props.fileName}</span>
          <span className="font-status-msg">
            {translate(this.getStatusMessage())}
          </span>
        </div>
        <div className="font-indicator">
          {this.getStatusSymbol() ? (
            <symbols.symbol
              name={this.getStatusSymbol()}
              key="status-symbol"
              className={this.props.status || ''}
            />
          ) : null}
        </div>
        {this.props.finished ? (
          <div key="finished-symbol" className="font-indicator finished">
            <symbols.symbol name="inputValidationSuccess" />
          </div>
        ) : null}
        <div className="cancel-icon">
          <symbols.symbol
            name="headerCloseButton"
            onClick={this.props.onCancelClick}
            className="cancel-btn"
          />
        </div>
        <div className="actions">
          <UI.tooltip
            value="Upload_Fonts_Download_Tooltip"
            styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
          >
            <div className="action-btn-container">
              {this.props.downloadable ? (
                <a
                  href={this.props.downloadUrl}
                  onClick={this.props.onDownloadClick}
                  key="download-btn"
                  {...this.getDownloadProps()}
                  className={cx(
                    'action-btn download',
                    this.getDownloadProps()?.className,
                  )}
                >
                  <symbols.symbol name="font-download" />
                </a>
              ) : null}
            </div>
          </UI.tooltip>
          <UI.tooltip
            value="Upload_Fonts_Delete_Tooltip"
            styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
          >
            <div className="action-btn-container">
              {this.props.removeable ? (
                <a
                  onClick={this.props.onRemoveClick}
                  key="remove-btn"
                  className="action-btn"
                >
                  <symbols.symbol name="font-delete" />
                </a>
              ) : null}
            </div>
          </UI.tooltip>
        </div>
      </div>
    );
  }
}
