import React from 'react';
import { Text, Tooltip } from '@wix/wix-base-ui';

import * as symbols from '@wix/santa-editor-symbols';

import { translate } from '@/i18n';

import styles from './newWorkspaceContentHeader.scss';

interface AddPageEntryButtonProps {
  tooltipContent: string;
  onClick?: () => void;
}

const AddPageEntryButton: React.FC<AddPageEntryButtonProps> = ({
  tooltipContent,
  onClick,
}) => (
  <Tooltip content={tooltipContent} shouldTranslate={false}>
    <span onClick={onClick} className={styles.addPage}>
      <symbols.symbol name="addPage" />
      {translate('Pages_Menu_Add_Button_Label')}
    </span>
  </Tooltip>
);

interface NewWorkspaceContentHeaderProps {
  showAddOptions: boolean;
  title: string;
  tooltipContent?: string;
  onAddPage?: () => void;
}

/*
 * Custom header for pages panel content for the new workspace
 */
export const NewWorkspaceContentHeader: React.FunctionComponent<
  NewWorkspaceContentHeaderProps
> = ({ title, showAddOptions, tooltipContent, onAddPage }) => {
  return (
    <div className={styles.root}>
      <Text size="small" shouldTranslate={false}>
        {title}
      </Text>
      {showAddOptions && (
        <AddPageEntryButton
          tooltipContent={tooltipContent}
          onClick={onAddPage}
        />
      )}
    </div>
  );
};
