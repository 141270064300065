// @ts-nocheck
import _ from 'lodash';
import * as utils from '@/util';

let LATEST_COPIED_LINKS = {};

function createUrlLink(url) {
  return {
    type: 'ExternalLink',
    target: '_blank',
    url,
  };
}

function tryCreateMailLink(href) {
  if (!/^mailto:/i.test(href)) {
    return null;
  }
  const hrefArr = href.replace(/mailto:/i, '').split('?');
  if (!utils.validationExpressions.validEmailReg.test(hrefArr[0])) {
    return null;
  }
  const emailAddress = hrefArr[0];
  let subject = hrefArr[1];
  if (/^subject=/i.test(subject)) {
    subject = subject.replace(/^subject=/i, '');
  } else {
    subject = '';
  }
  return {
    type: 'EmailLink',
    recipient: emailAddress,
    subject,
  };
}

export interface LinksHelperType {
  getLink(linkId: string): string;
  setNonPasteableLinkTypes(nonPasteableLinkTypes: string[]): void;
  getAllLinks(): string[];
  setCurrentLinks(links: string[]): void;
}

function LinksHelper(initialLinks, nonPasteableLinkTypes?) {
  this.setCurrentLinks(initialLinks);
  this.setNonPasteableLinkTypes(nonPasteableLinkTypes);
}

LinksHelper.prototype.getLink = function (linkId) {
  return this._links[linkId];
};

LinksHelper.prototype.setNonPasteableLinkTypes = function (
  nonPasteableLinkTypes,
) {
  this.nonPasteableLinkTypes = nonPasteableLinkTypes;
};

LinksHelper.prototype.getAllLinks = function () {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(this._links);
};

LinksHelper.prototype.setCurrentLinks = function (links) {
  this._links = {};

  const self = this;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(links, function (link) {
    self._links[link.id] = link;
  });
};

LinksHelper.prototype.cacheCopiedLinks = function () {
  LATEST_COPIED_LINKS = _.clone(this._links);
};

LinksHelper.prototype.createLinkFromHref = function (href) {
  let linkData = {};

  const isValidUrl = utils.validationExpressions.validUrlReg.test(href);
  if (isValidUrl) {
    linkData = createUrlLink(href);
  } else {
    linkData = tryCreateMailLink(href);
  }

  if (linkData) {
    return this.saveLink(linkData);
  }

  return null;
};

LinksHelper.prototype.saveLink = function (linkObj) {
  linkObj.id = utils.guidUtils.getUniqueId('textLink', '_');

  this._links[linkObj.id] = linkObj;

  return linkObj.id;
};

LinksHelper.prototype.getLatestCopiedLink = function (linkId) {
  const currLink = LATEST_COPIED_LINKS[linkId];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  if (currLink && !_.includes(this.nonPasteableLinkTypes, currLink.type)) {
    return currLink;
  }
  return null;
};

LinksHelper.prototype.duplicateLink = function (linkId) {
  const currLink = this.getLink(linkId) || this.getLatestCopiedLink(linkId);

  if (currLink) {
    return this.saveLink(_.clone(currLink));
  }

  return null;
};

export default LinksHelper;
