'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_anchor',
    'subNavigationTitle': 'add_section_label_anchor',
    'presetTitle': 'add_section_label_anchor',
    'tooltipTitle': 'add_section_label_anchor',
    "automationId": "add-panel-section-anchorsSection",
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.ALWAYS,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.HOVER,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {
            'wysiwyg.common.components.anchor.viewer.Anchor': {
                'title': 'preset_info_title_anchor',
                'text': 'preset_info_text_anchor'
            }
        }
    },
    'props': {
        'image': 'addPanelData/sections/anchorsSection_en/anchorsSection_en.v4.png',
        'imageHover': null,
        'items': [
            {
                'id': 'Other_Anchors_1',
                'structure': {
                    'type': 'Component',
                    'skin': 'wysiwyg.common.components.anchor.viewer.skins.AnchorSkin',
                    'layout': {
                        'width': 1918,
                        'height': 21,
                        'x': 0,
                        'y': 115,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.common.components.anchor.viewer.Anchor',
                    'data': {
                        'type': 'Anchor',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'name': 'Anchor 1',
                        'compId': 'i5rzonck_3'
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'id': 'Anchor_1',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
                        'componentClassName': '',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'system',
                        'skin': 'wysiwyg.common.components.anchor.viewer.skins.AnchorSkin'
                    },
                    'id': 'i5rzonck_3'
                },
                'preset': {
                    'rect': {'width': 162, 'height': 125, 'x': 0, 'y': 0},
                    'label': 'add_preset_Other_Anchors_1'
                }
            }
        ],
        'compTypes': ['wysiwyg.common.components.anchor.viewer.Anchor']
    },
    'teaser': {
        'id': 'anchors_section_teaser',
        'hide': false,
        'title': 'add_section_teaser_title_anchor',
        'text': 'add_section_teaser_text_anchor',
        'linkText': 'anchors_section_teaser_link',
        'helpCompType': 'Anchor',
        'helpId': '0a1de6d7-3f69-4d30-8ddc-7aa99bd12edc'
    },
    'help': {'hide': false, 'text': 'add_section_info_text_anchor'}
}
