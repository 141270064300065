// @ts-nocheck
import * as stateManagement from '@/stateManagement';
import { DATA_BINDING } from '@wix/app-definition-ids';
import { ErrorReporter } from '@wix/editor-error-reporter';

export default {
  create:
    (editorAPI, { considerControllerTypeDisplayName } = {}) =>
    (compRef) => {
      const widgetName =
        stateManagement.applicationStudio.selectors.getWidgetDisplayName(
          editorAPI.store.getState(),
          editorAPI.dsRead,
          compRef,
        );
      if (widgetName) {
        return widgetName;
      }
      const stageData =
        editorAPI.dsRead.platform.controllers.getStageData(compRef);

      if (stageData) {
        if (stageData.displayName) {
          return stageData.displayName;
        }

        if (
          considerControllerTypeDisplayName &&
          stageData.controllerTypeDisplayName
        ) {
          return stageData.controllerTypeDisplayName;
        }
      }

      const { applicationId: appDefinitionId } =
        editorAPI.dsRead.components.data.get(compRef);
      if (appDefinitionId === DATA_BINDING) {
        return '';
      }
      if (!appDefinitionId) {
        ErrorReporter.captureException(
          new Error('app market call without appDefId'),
          {
            tags: {
              appMarketCallNoAppDefId: true,
            },
            extra: {
              flow: 'platformCompNamePlugin',
            },
          },
        );
        return '';
      }

      const appData =
        editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId);
      if (appData) {
        const appMarketData =
          editorAPI.dsRead.tpa.appMarket.getData(appDefinitionId);
        if (appMarketData?.name) {
          return appMarketData.name;
        }
        return appData.appDefinitionName || '';
      }

      return '';
    },
};
