'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_iconbutton",
    "subNavigationTitle": "add_section_label_iconbutton",
    "presetTitle": "add_section_label_iconbutton",
    "tooltipTitle": "add_section_label_iconbutton",
    "automationId": "add-panel-section-iconButtonsSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
        'scaleProportion': addPanelDataConsts.DEFAULT_SCALE_FOR_PRESET,
        'iconEnabledForComps': []
    },
    "props": {
        "image": "addPanelData/sections/iconButtonsSection_en/iconButtonsSection_en.png",
        "imageHover": 'addPanelData/sections/iconButtonsSection_en/hover_en/iconButtonsSection_hover_en.png',
        "items": [{
            "id": "Button_Icon_Buttons_1",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 13,
                    "y": 32,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-90.png",
                        "uri": "035244_fc17cf03f0ee4cd59a471731cbb739c1~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-59.png",
                        "uri": "035244_d650b3a954f34679b3c51cd694bd48f8~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-90.png",
                        "uri": "035244_fc17cf03f0ee4cd59a471731cbb739c1~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jahzou08"
            },
            "preset": {"rect": {"width": 47, "height": 64, "x": 0, "y": 1}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_2",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 58,
                    "y": 32,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-74.png",
                        "uri": "035244_9d81c5b6621246a98012cf31f3edb2f8~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-47.png",
                        "uri": "035244_9618d9e4f47a4218b9f3741f05f1d63c~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-74.png",
                        "uri": "035244_9d81c5b6621246a98012cf31f3edb2f8~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai0jtpw"
            },
            "preset": {"rect": {"width": 46, "height": 64, "x": 47, "y": 1}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_3",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 103,
                    "y": 32,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-80.png",
                        "uri": "035244_0b4c16d074404c6b8b7a2adda962e53d~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-53.png",
                        "uri": "035244_e1dcc0e24d81456982df6647e4a0cfb8~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-80.png",
                        "uri": "035244_0b4c16d074404c6b8b7a2adda962e53d~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai0k48y"
            },
            "preset": {"rect": {"width": 46, "height": 64, "x": 93, "y": 1}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_4",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 149,
                    "y": 32,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-08.png",
                        "uri": "035244_3211ee50d5fc4a0598bc545d68d8af44~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-08.png",
                        "uri": "035244_c3fa0f77136944d4866f57d5a8ed715f~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-08.png",
                        "uri": "035244_3211ee50d5fc4a0598bc545d68d8af44~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai0keh2"
            },
            "preset": {"rect": {"width": 46, "height": 64, "x": 139, "y": 1}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_5",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 194,
                    "y": 32,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-93.png",
                        "uri": "035244_652d12a4071147d4a2fff8f8d2ff5db1~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-62.png",
                        "uri": "035244_00464e7939644b71b41d864548ca468f~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-93.png",
                        "uri": "035244_652d12a4071147d4a2fff8f8d2ff5db1~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai0kngu"
            },
            "preset": {"rect": {"width": 46, "height": 64, "x": 185, "y": 1}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_6",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 239,
                    "y": 32,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-91.png",
                        "uri": "035244_5605b924f6404fd99d292b38f576ee6b~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-60.png",
                        "uri": "035244_06a98e42dc8b447e9c6d6975be392368~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-91.png",
                        "uri": "035244_5605b924f6404fd99d292b38f576ee6b~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai0kzcy"
            },
            "preset": {"rect": {"width": 46, "height": 64, "x": 231, "y": 1}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_7",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 285,
                    "y": 32,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-94.png",
                        "uri": "035244_f79a38685afa43bcaab97548140846fc~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-63.png",
                        "uri": "035244_6678addb7fe749ca8286466861401dad~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-94.png",
                        "uri": "035244_f79a38685afa43bcaab97548140846fc~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {"type": "ImageButtonProperties", "metaData": {"schemaVersion": "1.0"}, "transition": "fade"},
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai0l90h"
            },
            "preset": {"rect": {"width": 47, "height": 64, "x": 277, "y": 1}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_8",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 13,
                    "y": 71,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-13.png",
                        "uri": "035244_ca183d1f593a4c41b9d034b9099ac2b5~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-13.png",
                        "uri": "035244_2e634687e921441dadcf64f964bfa81f~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-13.png",
                        "uri": "035244_ca183d1f593a4c41b9d034b9099ac2b5~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai0ny92"
            },
            "preset": {"rect": {"width": 47, "height": 39, "x": 0, "y": 65}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_9",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 58,
                    "y": 71,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-04.png",
                        "uri": "035244_6af9c4421a85482ca71b02dc782a276a~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-04.png",
                        "uri": "035244_ad00e9a4df534dccbc20a627b0861168~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-04.png",
                        "uri": "035244_6af9c4421a85482ca71b02dc782a276a~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai0ny8x"
            },
            "preset": {"rect": {"width": 46, "height": 39, "x": 47, "y": 65}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_10",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 103,
                    "y": 71,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-05.png",
                        "uri": "035244_c27b8aea2cff4db586a908e7a9bff268~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-05.png",
                        "uri": "035244_b77cb55b7fea432eac140cf631d1d346~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-05.png",
                        "uri": "035244_c27b8aea2cff4db586a908e7a9bff268~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai0ny9v"
            },
            "preset": {"rect": {"width": 46, "height": 39, "x": 93, "y": 65}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_11",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 149,
                    "y": 71,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-07.png",
                        "uri": "035244_3511e868a21748338c395d7843494e00~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-07.png",
                        "uri": "035244_ddf2a7558b514f8e9d42ea99a5835359~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-07.png",
                        "uri": "035244_3511e868a21748338c395d7843494e00~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai0ny94"
            },
            "preset": {"rect": {"width": 46, "height": 39, "x": 139, "y": 65}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_12",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 194,
                    "y": 71,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-06.png",
                        "uri": "035244_a4606c1e60ce43bf9ba50c4c35500812~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-06.png",
                        "uri": "035244_a5cde717b52f415cab396755e4e66d4b~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-06.png",
                        "uri": "035244_a4606c1e60ce43bf9ba50c4c35500812~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai0ny9p"
            },
            "preset": {"rect": {"width": 46, "height": 39, "x": 185, "y": 65}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_13",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 239,
                    "y": 71,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-03.png",
                        "uri": "035244_19984e4b06dc4b148048731e3dcf1011~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-03.png",
                        "uri": "035244_ddce7e7d314a49299f147857fadbc40a~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-03.png",
                        "uri": "035244_19984e4b06dc4b148048731e3dcf1011~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai0ny9s"
            },
            "preset": {"rect": {"width": 46, "height": 39, "x": 231, "y": 65}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_14",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 285,
                    "y": 71,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-79.png",
                        "uri": "035244_5b099a1dbd02423ab7bd90fc5c9df0dd~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-52.png",
                        "uri": "035244_9313afeda597446b8f8d9d173dddb7db~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-79.png",
                        "uri": "035244_5b099a1dbd02423ab7bd90fc5c9df0dd~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {"type": "ImageButtonProperties", "metaData": {"schemaVersion": "1.0"}, "transition": "fade"},
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai0ny90"
            },
            "preset": {"rect": {"width": 47, "height": 39, "x": 277, "y": 65}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_15",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 13,
                    "y": 111,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-76.png",
                        "uri": "035244_e6b753ae08974e7785a153ccc10763b4~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-49.png",
                        "uri": "035244_58b0242f5c0e428a9b79f5781188243a~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-76.png",
                        "uri": "035244_e6b753ae08974e7785a153ccc10763b4~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai146rw"
            },
            "preset": {"rect": {"width": 47, "height": 39, "x": -1, "y": 104}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_16",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 58,
                    "y": 111,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-10.png",
                        "uri": "035244_999cd1e539224dc48f564938c766b96c~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-10.png",
                        "uri": "035244_6d475fa0c5a34125a10cc1f74312de98~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-10.png",
                        "uri": "035244_999cd1e539224dc48f564938c766b96c~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai146rh"
            },
            "preset": {"rect": {"width": 46, "height": 39, "x": 46, "y": 104}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_17",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 104,
                    "y": 111,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-75.png",
                        "uri": "035244_280769d312984cb89f8bc0664c0274cd~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-48.png",
                        "uri": "035244_ec093644b17e4152ad443bd015616fc0~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-75.png",
                        "uri": "035244_280769d312984cb89f8bc0664c0274cd~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai146ru"
            },
            "preset": {"rect": {"width": 46, "height": 39, "x": 92, "y": 104}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_18",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 149,
                    "y": 111,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-83.png",
                        "uri": "035244_4e8624c8d90f44689d47ba6c14eeb42b~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-54.png",
                        "uri": "035244_69d9cbc2624b4006841e210755771978~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-83.png",
                        "uri": "035244_4e8624c8d90f44689d47ba6c14eeb42b~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai146rm"
            },
            "preset": {"rect": {"width": 46, "height": 39, "x": 138, "y": 104}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_19",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 195,
                    "y": 111,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-78.png",
                        "uri": "035244_683f3ffcab8844a288bd3ac0bf9258e5~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-51.png",
                        "uri": "035244_5a79f4ffe4ea40d9bb7cc5958c5a3612~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-78.png",
                        "uri": "035244_683f3ffcab8844a288bd3ac0bf9258e5~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai146ro"
            },
            "preset": {"rect": {"width": 46, "height": 39, "x": 184, "y": 104}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_20",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 240,
                    "y": 111,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-02.png",
                        "uri": "035244_b1c50b0fad9b4f459f5a4ab5e6fdc470~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-02.png",
                        "uri": "035244_b308fc60fab34fbc8a6522150ee3dfae~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-02.png",
                        "uri": "035244_b1c50b0fad9b4f459f5a4ab5e6fdc470~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai146rq"
            },
            "preset": {"rect": {"width": 47, "height": 39, "x": 230, "y": 104}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_21",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 286,
                    "y": 111,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-84.png",
                        "uri": "035244_ec2b493cd81d4b95834844d703446554~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-55.png",
                        "uri": "035244_8976710cff914865ae05fd61113e5f37~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-84.png",
                        "uri": "035244_ec2b493cd81d4b95834844d703446554~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai146rk"
            },
            "preset": {"rect": {"width": 47, "height": 39, "x": 277, "y": 104}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_22",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 13,
                    "y": 150,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-09.png",
                        "uri": "035244_dd24ef2039b2445898494772793733a4~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-09.png",
                        "uri": "035244_139911c139a34082880d9336a9ce56b3~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-09.png",
                        "uri": "035244_dd24ef2039b2445898494772793733a4~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jarzgdvn"
            },
            "preset": {"rect": {"width": 47, "height": 51, "x": 0, "y": 143}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_23",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 58,
                    "y": 150,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-95.png",
                        "uri": "035244_515471d549f34f878c302629cae9b5a3~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-64.png",
                        "uri": "035244_8951285488524f63a89bb18034d094ab~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-95.png",
                        "uri": "035244_515471d549f34f878c302629cae9b5a3~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jarzgdv6"
            },
            "preset": {"rect": {"width": 46, "height": 51, "x": 47, "y": 143}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_24",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 103,
                    "y": 150,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-92.png",
                        "uri": "035244_24b9c41c9a044bacbba44758e22b2be4~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-61.png",
                        "uri": "035244_10ff4dab3a69441a9ff20f72c3f70460~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-92.png",
                        "uri": "035244_24b9c41c9a044bacbba44758e22b2be4~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jarzgdvj"
            },
            "preset": {"rect": {"width": 46, "height": 51, "x": 93, "y": 143}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_25",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 149,
                    "y": 150,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-11.png",
                        "uri": "035244_8d1ad732e7bc42f3bc738e4751da3f34~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-11.png",
                        "uri": "035244_612bbdd5a26a444693eaa1f0065d6fe2~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-11.png",
                        "uri": "035244_23d9eacd81aa4c28a803e29e888525c2~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jarzgdv9"
            },
            "preset": {"rect": {"width": 46, "height": 51, "x": 139, "y": 143}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_26",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 194,
                    "y": 150,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-95.png",
                        "uri": "035244_0d2eccd1c6604ea985cc2a624748c9d2~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-56.png",
                        "uri": "035244_ab0e5e9fa24b47e9b02f65c2c8c03a42~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-85.png",
                        "uri": "035244_f65d91d2c6784c938c63ff40b5eb3b14~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jarzgdvd"
            },
            "preset": {"rect": {"width": 46, "height": 51, "x": 185, "y": 143}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_27",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 239,
                    "y": 150,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-77.png",
                        "uri": "035244_bd3df0558ea141f5b3aa36996265b6ee~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-50.png",
                        "uri": "035244_d97e79c08d77431fbd3ca97a4627ba6d~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-77.png",
                        "uri": "035244_bd3df0558ea141f5b3aa36996265b6ee~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jarzgdvg"
            },
            "preset": {"rect": {"width": 46, "height": 51, "x": 231, "y": 143}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_28",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 26,
                    "height": 26,
                    "x": 285,
                    "y": 150,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-14.png",
                        "uri": "035244_e88342cc4ab64079a94f6e8bd7c5db07~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-14.png",
                        "uri": "035244_24d7ed9c9d404ed2a18e4c45787e176a~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-14.png",
                        "uri": "035244_e88342cc4ab64079a94f6e8bd7c5db07~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jarzgdup"
            },
            "preset": {"rect": {"width": 47, "height": 51, "x": 277, "y": 143}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_29",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 10,
                    "y": 208,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-23.png",
                        "uri": "035244_b7e97a2966464f9db45c120a0286b43a~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-23.png",
                        "uri": "035244_63d0234c3b7f446bbae5fe9418d99161~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-23.png",
                        "uri": "035244_b7e97a2966464f9db45c120a0286b43a~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai1p40q"
            },
            "preset": {"rect": {"width": 80, "height": 82, "x": 1, "y": 194}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_30",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 90,
                    "y": 208,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-25.png",
                        "uri": "035244_0b3967ce744d4abf9724f3904d24cbec~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-25.png",
                        "uri": "035244_049223eb98464ed086bfa3aa59c06c07~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-25.png",
                        "uri": "035244_0b3967ce744d4abf9724f3904d24cbec~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai1p40t"
            },
            "preset": {"rect": {"width": 80, "height": 82, "x": 81, "y": 194}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_31",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 171,
                    "y": 208,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-27.png",
                        "uri": "035244_f7f4e10885124ca385104edd97aeeabe~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-27.png",
                        "uri": "035244_0e1bb186604048af82e5dce8c833d568~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-27.png",
                        "uri": "035244_f7f4e10885124ca385104edd97aeeabe~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai1p40w"
            },
            "preset": {"rect": {"width": 80, "height": 82, "x": 161, "y": 194}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_32",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 251,
                    "y": 208,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-26.png",
                        "uri": "035244_0ffd26364b1841ca8b891a68587a8769~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-26.png",
                        "uri": "035244_ffe13ba923894ba2acac501db7cec6f1~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-26.png",
                        "uri": "035244_0ffd26364b1841ca8b891a68587a8769~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai1p40y"
            },
            "preset": {"rect": {"width": 82, "height": 82, "x": 241, "y": 194}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_33",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 10,
                    "y": 283,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-22.png",
                        "uri": "035244_19bb096c6c3a4128a135a5a73f5cb8bb~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-22.png",
                        "uri": "035244_6a9172fbed2a4f40ba476ae37b04e7be~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-22.png",
                        "uri": "035244_19bb096c6c3a4128a135a5a73f5cb8bb~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasbrwbo"
            },
            "preset": {"rect": {"width": 80, "height": 78, "x": 1, "y": 276}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_34",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 90,
                    "y": 283,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-24.png",
                        "uri": "035244_8ef3e4854b074ed797d6f7c65ba7b16e~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-24.png",
                        "uri": "035244_c34e99aa6cb548ba8d8935d0cfc5a12e~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-24.png",
                        "uri": "035244_8ef3e4854b074ed797d6f7c65ba7b16e~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasbrwbr"
            },
            "preset": {"rect": {"width": 80, "height": 78, "x": 81, "y": 276}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_35",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 171,
                    "y": 283,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-07.png",
                        "uri": "035244_44897ac122df4052aba9f6b8b3cf09f3~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-07.png",
                        "uri": "035244_a4d8f73015d844a097ccccd07216a97c~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-07.png",
                        "uri": "035244_44897ac122df4052aba9f6b8b3cf09f3~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasbrwbt"
            },
            "preset": {"rect": {"width": 80, "height": 78, "x": 161, "y": 276}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_36",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 251,
                    "y": 283,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-06.png",
                        "uri": "035244_f6ec541c74484f0397ade5551118b193~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-06.png",
                        "uri": "035244_ced313c870d847fa904b88abdc9775be~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-06.png",
                        "uri": "035244_f6ec541c74484f0397ade5551118b193~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasbrwbv"
            },
            "preset": {"rect": {"width": 82, "height": 78, "x": 241, "y": 276}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_37",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 9,
                    "y": 359,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-05.png",
                        "uri": "035244_7ab29c72994f4040bafcacadad3a166c~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-05.png",
                        "uri": "035244_ee632884fe314cc28bdd10946aab3ac8~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-05.png",
                        "uri": "035244_7ab29c72994f4040bafcacadad3a166c~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasbt6ih"
            },
            "preset": {"rect": {"width": 80, "height": 82, "x": 1, "y": 354}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_38",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 90,
                    "y": 359,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-04.png",
                        "uri": "035244_80490afb73064192b2f4ebc4d6382811~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-04.png",
                        "uri": "035244_c97632e4298344aab4a009590abc7d4e~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-04.png",
                        "uri": "035244_80490afb73064192b2f4ebc4d6382811~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasbt6ij"
            },
            "preset": {"rect": {"width": 80, "height": 82, "x": 81, "y": 354}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_39",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 170,
                    "y": 359,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-03.png",
                        "uri": "035244_7af1b4eeba30431ba6fde5a7439eeec1~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-03.png",
                        "uri": "035244_6c2c7e7cc1d04fe08d819118383d5cf0~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-03.png",
                        "uri": "035244_7af1b4eeba30431ba6fde5a7439eeec1~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasbt6im"
            },
            "preset": {"rect": {"width": 80, "height": 82, "x": 161, "y": 354}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_40",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 251,
                    "y": 359,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-02.png",
                        "uri": "035244_d6378551bd0d48a89960ba67dbdeb952~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-02.png",
                        "uri": "035244_a3969858cd094c3c822c34fbc117e17f~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-02.png",
                        "uri": "035244_d6378551bd0d48a89960ba67dbdeb952~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasbt6in"
            },
            "preset": {"rect": {"width": 82, "height": 82, "x": 241, "y": 354}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_41",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 40,
                    "height": 40,
                    "x": 16,
                    "y": 455,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-31.png",
                        "uri": "035244_d689ceddf4ed40e1b2122bab3a5e38fc~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-31.png",
                        "uri": "035244_d8e2397534c24db1a7e7a10991d73ccc~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-31.png",
                        "uri": "035244_d689ceddf4ed40e1b2122bab3a5e38fc~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai1t71y"
            },
            "preset": {"rect": {"width": 65, "height": 66, "x": 0, "y": 436}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_42",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 40,
                    "height": 40,
                    "x": 78,
                    "y": 455,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-32.png",
                        "uri": "035244_e6828d11d0ef465a8c1b263e4c31d602~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-32.png",
                        "uri": "035244_6d553d072d074906921789d726bc57ca~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-32.png",
                        "uri": "035244_e6828d11d0ef465a8c1b263e4c31d602~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai1wkn9"
            },
            "preset": {"rect": {"width": 65, "height": 66, "x": 65, "y": 436}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_43",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 40,
                    "height": 40,
                    "x": 142,
                    "y": 455,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-33.png",
                        "uri": "035244_24e9f520a3344c68a9683b701f60b1cd~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-33.png",
                        "uri": "035244_ed658880dd7b483e874c99d6f9ebb317~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-33.png",
                        "uri": "035244_24e9f520a3344c68a9683b701f60b1cd~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai1x1bv"
            },
            "preset": {"rect": {"width": 65, "height": 66, "x": 130, "y": 436}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_44",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 40,
                    "height": 40,
                    "x": 205,
                    "y": 455,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-34.png",
                        "uri": "035244_00366f402f0741ccb7282fd0f8ab76b5~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-34.png",
                        "uri": "035244_da6f98d310bd4fcf85ea276ea15a5401~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-34.png",
                        "uri": "035244_00366f402f0741ccb7282fd0f8ab76b5~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai1y5ir"
            },
            "preset": {"rect": {"width": 65, "height": 66, "x": 195, "y": 436}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_45",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 40,
                    "height": 40,
                    "x": 268,
                    "y": 455,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-35.png",
                        "uri": "035244_6f75c90bfd8b4f9eac84808fe7afcc92~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-35.png",
                        "uri": "035244_7a6c06171f9a414baf5c990ce9f840ad~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-35.png",
                        "uri": "035244_6f75c90bfd8b4f9eac84808fe7afcc92~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai1yq15"
            },
            "preset": {"rect": {"width": 64, "height": 66, "x": 260, "y": 436}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_46",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 40,
                    "height": 40,
                    "x": 16,
                    "y": 506,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-36.png",
                        "uri": "035244_e10c84d1d90a491b824ac56232e42f35~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-36.png",
                        "uri": "035244_e0fe42148ac841659ec848add1a5b11c~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-36.png",
                        "uri": "035244_e10c84d1d90a491b824ac56232e42f35~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai20bk1"
            },
            "preset": {"rect": {"width": 65, "height": 63, "x": 0, "y": 502}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_47",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 40,
                    "height": 40,
                    "x": 78,
                    "y": 508,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-37.png",
                        "uri": "035244_254e9b3101e54cf494e95aa1ea422d25~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-37.png",
                        "uri": "035244_7a8fa61177b542fdbbcb02a08e0e8b5c~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-37.png",
                        "uri": "035244_254e9b3101e54cf494e95aa1ea422d25~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai20bk3"
            },
            "preset": {"rect": {"width": 65, "height": 63, "x": 65, "y": 502}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_48",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 40,
                    "height": 40,
                    "x": 142,
                    "y": 506,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-28.png",
                        "uri": "035244_2d2cf2c0bfb3410eb97edf52f29043fd~mv2.png",
                        "description": "",
                        "width": 101,
                        "height": 101,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-28.png",
                        "uri": "035244_a442fb14572d41c495ac4e83c0f6f47d~mv2.png",
                        "description": "",
                        "width": 101,
                        "height": 101,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-28.png",
                        "uri": "035244_2d2cf2c0bfb3410eb97edf52f29043fd~mv2.png",
                        "description": "",
                        "width": 101,
                        "height": 101,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai20bk5"
            },
            "preset": {"rect": {"width": 65, "height": 63, "x": 130, "y": 502}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_49",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 40,
                    "height": 40,
                    "x": 205,
                    "y": 506,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-29.png",
                        "uri": "035244_c1a09899975644db8ae6ce8bf0bcb5bf~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-29.png",
                        "uri": "035244_616676fb8aff43619a9170b80edd9654~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-29.png",
                        "uri": "035244_c1a09899975644db8ae6ce8bf0bcb5bf~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai20bk7"
            },
            "preset": {"rect": {"width": 65, "height": 63, "x": 195, "y": 502}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_50",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 40,
                    "height": 40,
                    "x": 268,
                    "y": 506,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-30.png",
                        "uri": "035244_bb9dbbd5e7b84997824cba88813ecd1c~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-30.png",
                        "uri": "035244_5282c9e96b3c40718d26f003a02ad2c5~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-30.png",
                        "uri": "035244_bb9dbbd5e7b84997824cba88813ecd1c~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai20bk9"
            },
            "preset": {"rect": {"width": 64, "height": 63, "x": 260, "y": 502}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_51",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 65,
                    "height": 65,
                    "x": 2,
                    "y": 579,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-11.png",
                        "uri": "035244_0f75358b3da04ff5b70113cc7994af51~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-11.png",
                        "uri": "035244_08d608d6ab734fddb979d779cbd136ed~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-11.png",
                        "uri": "035244_0f75358b3da04ff5b70113cc7994af51~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaid7vd7"
            },
            "preset": {"rect": {"width": 82, "height": 82, "x": 0, "y": 565}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_52",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 66,
                    "height": 65,
                    "x": 84,
                    "y": 579,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-10.png",
                        "uri": "035244_daf103d6eddb4328bb84a9dc7cd3a0fb~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-10.png",
                        "uri": "035244_af94f47ae8df4aa2bc69c99760146fce~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-10.png",
                        "uri": "035244_daf103d6eddb4328bb84a9dc7cd3a0fb~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaid7vdb"
            },
            "preset": {"rect": {"width": 80, "height": 82, "x": 82, "y": 565}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_53",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 65,
                    "height": 66,
                    "x": 167,
                    "y": 577,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-15.png",
                        "uri": "035244_f26f0950ea7d4168bb0913ee3adbb6e1~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-15.png",
                        "uri": "035244_f0266dfcf72d49d69a93b0f2a503465a~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-15.png",
                        "uri": "035244_f26f0950ea7d4168bb0913ee3adbb6e1~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaid7vdd"
            },
            "preset": {"rect": {"width": 80, "height": 82, "x": 162, "y": 565}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_54",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 65,
                    "height": 66,
                    "x": 250,
                    "y": 577,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-09.png",
                        "uri": "035244_e1592bd6fe1347838a70f601a06a091d~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-09.png",
                        "uri": "035244_4143b00b44664f03b68ac11dd9b7d227~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-09.png",
                        "uri": "035244_e1592bd6fe1347838a70f601a06a091d~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaid7vdg"
            },
            "preset": {"rect": {"width": 82, "height": 82, "x": 242, "y": 565}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_55",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 65,
                    "height": 65,
                    "x": 2,
                    "y": 655,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-14.png",
                        "uri": "035244_b306b0142151451ebd2f5819cbeaa9bd~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-14.png",
                        "uri": "035244_a37f1d05947e422e82f337ad2ab09170~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-14.png",
                        "uri": "035244_b306b0142151451ebd2f5819cbeaa9bd~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasc5p13"
            },
            "preset": {"rect": {"width": 82, "height": 82, "x": 0, "y": 647}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_56",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 66,
                    "height": 65,
                    "x": 84,
                    "y": 655,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-08.png",
                        "uri": "035244_46e2c209e01741c69c8516bbc533e932~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-08.png",
                        "uri": "035244_cc3b4101760141e385fdaa4126e3e61f~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-08.png",
                        "uri": "035244_46e2c209e01741c69c8516bbc533e932~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasc5p16"
            },
            "preset": {"rect": {"width": 80, "height": 82, "x": 82, "y": 647}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_57",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 65,
                    "height": 66,
                    "x": 167,
                    "y": 654,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-12.png",
                        "uri": "035244_e74a6438bbbc48d487660ba8bb675d3f~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-12.png",
                        "uri": "035244_4b82ff08d6454ce5b9b14c89bc15a55f~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-12.png",
                        "uri": "035244_e74a6438bbbc48d487660ba8bb675d3f~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasc5p18"
            },
            "preset": {"rect": {"width": 80, "height": 82, "x": 162, "y": 647}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_58",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 65,
                    "height": 66,
                    "x": 250,
                    "y": 654,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-13.png",
                        "uri": "035244_5be57f16efef4e569405fc377d3f3c64~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-13.png",
                        "uri": "035244_cbebb9036b7945c0a93f1355458b1294~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-13.png",
                        "uri": "035244_5be57f16efef4e569405fc377d3f3c64~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasc5p1b"
            },
            "preset": {"rect": {"width": 82, "height": 82, "x": 242, "y": 647}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_59",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 60,
                    "height": 60,
                    "x": 11,
                    "y": 751,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-44.png",
                        "uri": "035244_c847f95d0196411baa289a27e83620ab~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-44.png",
                        "uri": "035244_68079ec8e6ac46058fe04f7cf578035d~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-44.png",
                        "uri": "035244_c847f95d0196411baa289a27e83620ab~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaibp1p1"
            },
            "preset": {"rect": {"width": 82, "height": 86, "x": 0, "y": 729}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_60",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 60,
                    "height": 60,
                    "x": 91,
                    "y": 751,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-39.png",
                        "uri": "035244_7fbc565e4f0a42de9dab2a546b17fe32~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-39.png",
                        "uri": "035244_6df0624581f84fa3b3cb2892d222cd97~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-39.png",
                        "uri": "035244_7fbc565e4f0a42de9dab2a546b17fe32~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jascjout"
            },
            "preset": {"rect": {"width": 80, "height": 86, "x": 82, "y": 729}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_61",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 60,
                    "height": 60,
                    "x": 172,
                    "y": 751,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-45.png",
                        "uri": "035244_e817ff5bb9a54654af726d4dc71cb656~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-45.png",
                        "uri": "035244_d1ca385bc8e84565898adc72eb0422e7~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-45.png",
                        "uri": "035244_e817ff5bb9a54654af726d4dc71cb656~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasck1vk"
            },
            "preset": {"rect": {"width": 80, "height": 86, "x": 162, "y": 729}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_62",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 60,
                    "height": 60,
                    "x": 253,
                    "y": 751,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-46.png",
                        "uri": "035244_b10d50ee4c2c486b8df3c09e64ab56f0~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-46.png",
                        "uri": "035244_8bfb95a0ecc846b196056bd145a9701c~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-46.png",
                        "uri": "035244_b10d50ee4c2c486b8df3c09e64ab56f0~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasck93g"
            },
            "preset": {"rect": {"width": 82, "height": 86, "x": 242, "y": 729}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_63",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 60,
                    "height": 60,
                    "x": 11,
                    "y": 820,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-38.png",
                        "uri": "035244_571e2017bd214fb2903d3ee3d37e3f59~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-38.png",
                        "uri": "035244_a4c995c18d054b1ab300b690df1c253d~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-38.png",
                        "uri": "035244_571e2017bd214fb2903d3ee3d37e3f59~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasczflu"
            },
            "preset": {"rect": {"width": 82, "height": 78, "x": 0, "y": 815}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_64",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 60,
                    "height": 60,
                    "x": 91,
                    "y": 820,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-40.png",
                        "uri": "035244_8160c084cb1c41148a7f073fd76504c1~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-40.png",
                        "uri": "035244_69722a463478478da76ac0e4480e3a9d~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-40.png",
                        "uri": "035244_8160c084cb1c41148a7f073fd76504c1~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasczfly"
            },
            "preset": {"rect": {"width": 80, "height": 78, "x": 82, "y": 815}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_65",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 60,
                    "height": 60,
                    "x": 172,
                    "y": 820,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-41.png",
                        "uri": "035244_fc4c5e0d0e7243c3b1d2770b01b1faff~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-41.png",
                        "uri": "035244_20928b4a31f5456fa4f27a5f9ff1a59f~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-41.png",
                        "uri": "035244_fc4c5e0d0e7243c3b1d2770b01b1faff~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasczfm2"
            },
            "preset": {"rect": {"width": 80, "height": 78, "x": 162, "y": 815}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_66",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 60,
                    "height": 60,
                    "x": 253,
                    "y": 820,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-42.png",
                        "uri": "035244_fcfb3b91e3e24dea99e5bc72e62718fe~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-42.png",
                        "uri": "035244_4702f02e657d41b7a54d16facf2054d8~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-42.png",
                        "uri": "035244_fcfb3b91e3e24dea99e5bc72e62718fe~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasczfm5"
            },
            "preset": {"rect": {"width": 82, "height": 78, "x": 242, "y": 815}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_67",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 69,
                    "height": 69,
                    "x": 11,
                    "y": 912,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-51.png",
                        "uri": "035244_1e19af640aea4d6bb502d7fe8e18f0cf~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-51.png",
                        "uri": "035244_203c11395e894bf89fb46c963dce4417~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-51.png",
                        "uri": "035244_1e19af640aea4d6bb502d7fe8e18f0cf~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai2n73z"
            },
            "preset": {"rect": {"width": 82, "height": 90, "x": 0, "y": 893}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_68",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 70,
                    "height": 69,
                    "x": 89,
                    "y": 912,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-50.png",
                        "uri": "035244_4ebbc03ec21c4df29b940f5b1e34783e~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-50.png",
                        "uri": "035244_362237325f56451484eada73facd5b6d~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-50.png",
                        "uri": "035244_4ebbc03ec21c4df29b940f5b1e34783e~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai2qa33"
            },
            "preset": {"rect": {"width": 80, "height": 90, "x": 82, "y": 893}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_69",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 69,
                    "height": 70,
                    "x": 166,
                    "y": 911,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-53.png",
                        "uri": "035244_35711abf513d486bbbe3d6c9b10a8063~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-53.png",
                        "uri": "035244_34aaa0a77f06468a874851b5d91ec334~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-53.png",
                        "uri": "035244_35711abf513d486bbbe3d6c9b10a8063~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai2qrwx"
            },
            "preset": {"rect": {"width": 80, "height": 90, "x": 162, "y": 893}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_70",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 69,
                    "height": 70,
                    "x": 243,
                    "y": 911,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-48.png",
                        "uri": "035244_90fdf199fe4e4786a11c9b77c74091c8~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-48.png",
                        "uri": "035244_d95923aad2404beb9726fb5b7ae61fad~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-48.png",
                        "uri": "035244_90fdf199fe4e4786a11c9b77c74091c8~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai2r5yk"
            },
            "preset": {"rect": {"width": 82, "height": 90, "x": 242, "y": 893}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_71",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 69,
                    "height": 69,
                    "x": 11,
                    "y": 992,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-55.png",
                        "uri": "035244_459654b5e750462cb9b69d98c0d050e8~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-55.png",
                        "uri": "035244_46dbde2169dd4465b1df0ddc3c8f8683~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-55.png",
                        "uri": "035244_459654b5e750462cb9b69d98c0d050e8~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasekkqc"
            },
            "preset": {"rect": {"width": 82, "height": 95, "x": 0, "y": 983}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_72",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 69,
                    "height": 69,
                    "x": 90,
                    "y": 992,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-54.png",
                        "uri": "035244_a4b9b50392094c5abcebdedac20ce73a~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-54.png",
                        "uri": "035244_2ede036b14fd499bbe2c00b4b2f8395b~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-54.png",
                        "uri": "035244_a4b9b50392094c5abcebdedac20ce73a~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasekkqf"
            },
            "preset": {"rect": {"width": 80, "height": 95, "x": 82, "y": 983}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_73",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 69,
                    "height": 70,
                    "x": 166,
                    "y": 991,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-49.png",
                        "uri": "035244_f0ee3d60a20e4e79aa097b3aad1dd6af~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-49.png",
                        "uri": "035244_0b48856ac1064a4eaa05686764c4f8b4~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-49.png",
                        "uri": "035244_f0ee3d60a20e4e79aa097b3aad1dd6af~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasekkqh"
            },
            "preset": {"rect": {"width": 80, "height": 95, "x": 162, "y": 983}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_74",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 69,
                    "height": 70,
                    "x": 243,
                    "y": 991,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-52.png",
                        "uri": "035244_4cf32cc2d6274bb785d493abd04432e8~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-52.png",
                        "uri": "035244_5d6a94cb904d4558b908839fd595719c~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-52.png",
                        "uri": "035244_4cf32cc2d6274bb785d493abd04432e8~mv2.png",
                        "description": "",
                        "width": 100,
                        "height": 100,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasekkqi"
            },
            "preset": {"rect": {"width": 82, "height": 95, "x": 242, "y": 983}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_75",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 72,
                    "height": 72,
                    "x": 10,
                    "y": 1093,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-55.png",
                        "uri": "035244_d7b32c4b46e048dbb19fba81094a13e5~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-28.png",
                        "uri": "035244_f3e6bce73c014051842c18b03967c4b4~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-55.png",
                        "uri": "035244_d7b32c4b46e048dbb19fba81094a13e5~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaielvqg"
            },
            "preset": {"rect": {"width": 82, "height": 92, "x": 0, "y": 1078}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_76",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 72,
                    "height": 72,
                    "x": 87,
                    "y": 1093,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-52.png",
                        "uri": "035244_d84a53c8c757490ea8c5eb3ac755d8dd~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-25.png",
                        "uri": "035244_62a51fd41d7c4169aeee6b72b7064b6d~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-52.png",
                        "uri": "035244_d84a53c8c757490ea8c5eb3ac755d8dd~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaiet2x1"
            },
            "preset": {"rect": {"width": 80, "height": 92, "x": 81, "y": 1078}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_77",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 72,
                    "height": 72,
                    "x": 163,
                    "y": 1093,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-51.png",
                        "uri": "035244_b67fb1c0ca9142b9a3e889004b467984~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-24.png",
                        "uri": "035244_5ef2c11740724195af10c71c6eda3ffa~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-51.png",
                        "uri": "035244_b67fb1c0ca9142b9a3e889004b467984~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaietog0"
            },
            "preset": {"rect": {"width": 80, "height": 92, "x": 161, "y": 1078}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_78",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 72,
                    "height": 72,
                    "x": 240,
                    "y": 1093,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-50.png",
                        "uri": "035244_84c55d24083b44bc975af2bd75633d9f~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-23.png",
                        "uri": "035244_ced97f095bfc44b8873ec5597d1f926c~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-50.png",
                        "uri": "035244_84c55d24083b44bc975af2bd75633d9f~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaietvx2"
            },
            "preset": {"rect": {"width": 82, "height": 92, "x": 241, "y": 1078}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_79",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 72,
                    "height": 72,
                    "x": 10,
                    "y": 1178,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-57.png",
                        "uri": "035244_42d2c3e1d23a4802b2f590fb31a2fb47~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-30.png",
                        "uri": "035244_41b52d13bf344b739924040d4ac6b270~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-57.png",
                        "uri": "035244_42d2c3e1d23a4802b2f590fb31a2fb47~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaiexlp8"
            },
            "preset": {"rect": {"width": 82, "height": 92, "x": -1, "y": 1170}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_80",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 72,
                    "height": 72,
                    "x": 86,
                    "y": 1179,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-53.png",
                        "uri": "035244_87dc298b5c4b4584838e3c9353330c6f~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-26.png",
                        "uri": "035244_6b732f5d22a74e3084ebd2c4f3447581~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-53.png",
                        "uri": "035244_87dc298b5c4b4584838e3c9353330c6f~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaiexlp9"
            },
            "preset": {"rect": {"width": 80, "height": 92, "x": 81, "y": 1170}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_81",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 72,
                    "height": 72,
                    "x": 163,
                    "y": 1179,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-56.png",
                        "uri": "035244_6d8748a01b794527bd352bbd841737f4~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-29.png",
                        "uri": "035244_a65babab1aa5483ab91c5597cdd7e0ba~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-56.png",
                        "uri": "035244_6d8748a01b794527bd352bbd841737f4~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaiexlp91"
            },
            "preset": {"rect": {"width": 80, "height": 92, "x": 161, "y": 1170}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_82",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 72,
                    "height": 72,
                    "x": 239,
                    "y": 1179,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-54.png",
                        "uri": "035244_eb7fe156e1c04135bae4272712beb056~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-27.png",
                        "uri": "035244_ccf756246f254303bfa1359f84796fde~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-54.png",
                        "uri": "035244_eb7fe156e1c04135bae4272712beb056~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaiexlpa"
            },
            "preset": {"rect": {"width": 82, "height": 92, "x": 241, "y": 1170}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_83",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 74,
                    "height": 74,
                    "x": 9,
                    "y": 1270,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-61.png",
                        "uri": "035244_780593b5e655401eb4682271408c853c~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b [Recovered]-34.png",
                        "uri": "035244_dbca21abcbfd4917b3344bf3fb14c96d~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-61.png",
                        "uri": "035244_780593b5e655401eb4682271408c853c~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaif91nq"
            },
            "preset": {"rect": {"width": 82, "height": 93, "x": 0, "y": 1262}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_84",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 74,
                    "height": 74,
                    "x": 85,
                    "y": 1270,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1b-38.png",
                        "uri": "035244_358947d4979c4120bf560b2f55ce81d5~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-38.png",
                        "uri": "035244_362ee7051cee42a3b94c405f0008ea3f~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1b-38.png",
                        "uri": "035244_358947d4979c4120bf560b2f55ce81d5~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasg8k43"
            },
            "preset": {"rect": {"width": 80, "height": 93, "x": 81, "y": 1262}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_85",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 75,
                    "height": 74,
                    "x": 161,
                    "y": 1270,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-64.png",
                        "uri": "035244_9392c945091a429b8fe6df27f83c63a8~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b [Recovered]-37.png",
                        "uri": "035244_dc7728310d0d4f159cf5dd1ab3fcf92a~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-64.png",
                        "uri": "035244_9392c945091a429b8fe6df27f83c63a8~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasg8ylk"
            },
            "preset": {"rect": {"width": 80, "height": 93, "x": 161, "y": 1262}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_86",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 74,
                    "height": 74,
                    "x": 239,
                    "y": 1270,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-59.png",
                        "uri": "035244_56cc2ac9c686468cb1fd8aeacc7fabd9~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b [Recovered]-32.png",
                        "uri": "035244_4a96023afc7d4621bb1fa71c59ed1618~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-59.png",
                        "uri": "035244_56cc2ac9c686468cb1fd8aeacc7fabd9~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasg96pg"
            },
            "preset": {"rect": {"width": 82, "height": 93, "x": 241, "y": 1262}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_87",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 74,
                    "height": 74,
                    "x": 9,
                    "y": 1350,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-88.png",
                        "uri": "035244_416dccab97ba4b6082f866ac15996cb0~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b [Recovered]-58.png",
                        "uri": "035244_679eb4dafbc149d585144066e7a74ece~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-88.png",
                        "uri": "035244_416dccab97ba4b6082f866ac15996cb0~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasgipqy"
            },
            "preset": {"rect": {"width": 82, "height": 92, "x": 0, "y": 1355}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_88",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 74,
                    "height": 74,
                    "x": 85,
                    "y": 1350,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-62.png",
                        "uri": "035244_0e3a845a2b8b431a92888214e7122cd4~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b [Recovered]-35.png",
                        "uri": "035244_7bfeffbc73d5409bbd86d947a5930dbe~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-62.png",
                        "uri": "035244_0e3a845a2b8b431a92888214e7122cd4~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasgipra"
            },
            "preset": {"rect": {"width": 80, "height": 92, "x": 82, "y": 1355}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_89",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 75,
                    "height": 74,
                    "x": 161,
                    "y": 1350,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-63.png",
                        "uri": "035244_83af2c58ff214b8fba9ba063d541ea71~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b [Recovered]-36.png",
                        "uri": "035244_dde293fb821d493c8df131b8c37b8bf4~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-63.png",
                        "uri": "035244_83af2c58ff214b8fba9ba063d541ea71~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasgipre"
            },
            "preset": {"rect": {"width": 80, "height": 92, "x": 162, "y": 1355}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_90",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 74,
                    "height": 74,
                    "x": 239,
                    "y": 1350,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-86.png",
                        "uri": "035244_b9c51697f74245c0b4d2fc629abc5aa0~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b [Recovered]-57.png",
                        "uri": "035244_3eca22f8c8ef426caddd1b1e94513ec5~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-86.png",
                        "uri": "035244_b9c51697f74245c0b4d2fc629abc5aa0~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasgiprg"
            },
            "preset": {"rect": {"width": 82, "height": 92, "x": 242, "y": 1355}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_91",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 9,
                    "y": 1456,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-69.png",
                        "uri": "035244_7d018d24c7834e9eaf5cf79a20f91fa9~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-42.png",
                        "uri": "035244_9aa01b9f701245e5b7178a9ffb2e3693~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-69.png",
                        "uri": "035244_7d018d24c7834e9eaf5cf79a20f91fa9~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaifwr2j"
            },
            "preset": {"rect": {"width": 82, "height": 76, "x": 0, "y": 1447}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_92",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 63,
                    "height": 62,
                    "x": 89,
                    "y": 1456,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-68.png",
                        "uri": "035244_b5cd480d0fc54b849d5e8baad8396c60~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-41.png",
                        "uri": "035244_d24c52b6c9954eb282edbe401168b902~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-68.png",
                        "uri": "035244_b5cd480d0fc54b849d5e8baad8396c60~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaifwr2n"
            },
            "preset": {"rect": {"width": 80, "height": 76, "x": 82, "y": 1447}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_93",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 170,
                    "y": 1456,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-67.png",
                        "uri": "035244_c46011402f024888a62b221ffcc61644~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-40.png",
                        "uri": "035244_7a8228120ac447088e27d52da2a9f2bd~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-67.png",
                        "uri": "035244_c46011402f024888a62b221ffcc61644~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaifwr2p"
            },
            "preset": {"rect": {"width": 80, "height": 76, "x": 162, "y": 1447}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_94",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 250,
                    "y": 1456,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-66.png",
                        "uri": "035244_f831d25cf8474dcd90c158ee165ad8fb~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-39.png",
                        "uri": "035244_4be7d6e19b104c68b2d35db72019dd0f~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-66.png",
                        "uri": "035244_f831d25cf8474dcd90c158ee165ad8fb~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaifwr4r"
            },
            "preset": {"rect": {"width": 82, "height": 76, "x": 242, "y": 1447}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_95",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 9,
                    "y": 1527,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-73.png",
                        "uri": "035244_b5d7cdf30c5a4f5ba35a2f36bd65b494~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-46.png",
                        "uri": "035244_91a859641876411aaa042d780d1714ab~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-73.png",
                        "uri": "035244_b5d7cdf30c5a4f5ba35a2f36bd65b494~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasgrgp2"
            },
            "preset": {"rect": {"width": 82, "height": 76, "x": 0, "y": 1523}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_96",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 63,
                    "height": 62,
                    "x": 89,
                    "y": 1528,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-72.png",
                        "uri": "035244_5a81b9c935f44fb383f2b56c0e4ad3c4~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-45.png",
                        "uri": "035244_b39e0dd888f24695a6d9a1ef5b54940c~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-72.png",
                        "uri": "035244_5a81b9c935f44fb383f2b56c0e4ad3c4~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasgrgp9"
            },
            "preset": {"rect": {"width": 80, "height": 76, "x": 82, "y": 1523}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_97",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 170,
                    "y": 1526,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-71.png",
                        "uri": "035244_95e6963af89d46f5a94ca983a7d27ada~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-44.png",
                        "uri": "035244_2e6d9181cd0d456e96dca008a8f2f77c~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-71.png",
                        "uri": "035244_95e6963af89d46f5a94ca983a7d27ada~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasgrgpj"
            },
            "preset": {"rect": {"width": 80, "height": 76, "x": 162, "y": 1523}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_98",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 62,
                    "height": 62,
                    "x": 250,
                    "y": 1527,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-70.png",
                        "uri": "035244_ed8a2cf654674a69bfcd4f18d4ceb92d~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b-43.png",
                        "uri": "035244_f9b34fd3489c481695b11e4c35630b20~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-70.png",
                        "uri": "035244_ed8a2cf654674a69bfcd4f18d4ceb92d~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jasgrgpq"
            },
            "preset": {"rect": {"width": 82, "height": 76, "x": 242, "y": 1523}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_99",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 92,
                    "height": 92,
                    "x": 9,
                    "y": 1620,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-17.png",
                        "uri": "035244_e2eaaae633c94f03ab486faaf84917e5~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-17.png",
                        "uri": "035244_c386955cb66a4cfc84c5f11a0436e092~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-17.png",
                        "uri": "035244_e2eaaae633c94f03ab486faaf84917e5~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaig347s"
            },
            "preset": {"rect": {"width": 108, "height": 116, "x": 0, "y": 1599}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_100",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 95,
                    "height": 92,
                    "x": 113,
                    "y": 1620,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-16.png",
                        "uri": "035244_29760023a840434a9ce5bbbc93ec4cdf~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-16.png",
                        "uri": "035244_19a426cb36114002ad5ec12f6ed3b94e~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-16.png",
                        "uri": "035244_29760023a840434a9ce5bbbc93ec4cdf~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaig17n6"
            },
            "preset": {"rect": {"width": 108, "height": 116, "x": 107, "y": 1599}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_101",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 92,
                    "height": 92,
                    "x": 222,
                    "y": 1618,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-20.png",
                        "uri": "035244_ee45ab1ce9e74cf194308e06f989a9af~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-20.png",
                        "uri": "035244_fb3830e4ef5b455887011cd2c6ef3296~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-20.png",
                        "uri": "035244_ee45ab1ce9e74cf194308e06f989a9af~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaig4a70"
            },
            "preset": {"rect": {"width": 109, "height": 116, "x": 214, "y": 1599}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_102",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 92,
                    "height": 92,
                    "x": 9,
                    "y": 1720,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-18.png",
                        "uri": "035244_9476046e579542afa53a1e9c666cb9e8~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-18.png",
                        "uri": "035244_f9086b4d5b70499abb25c633d63005ce~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-18.png",
                        "uri": "035244_9476046e579542afa53a1e9c666cb9e8~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaig9oxl"
            },
            "preset": {"rect": {"width": 108, "height": 110, "x": -1, "y": 1715}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_103",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 92,
                    "height": 92,
                    "x": 110,
                    "y": 1721,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-19.png",
                        "uri": "035244_1bc491e6c1754819a1571200c901eec5~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-19.png",
                        "uri": "035244_f172a2353b904f988b0991b07d3797d1~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-19.png",
                        "uri": "035244_1bc491e6c1754819a1571200c901eec5~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaig9oxr"
            },
            "preset": {"rect": {"width": 109, "height": 110, "x": 107, "y": 1715}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_104",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 92,
                    "height": 92,
                    "x": 221,
                    "y": 1721,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-21.png",
                        "uri": "035244_a437b9994a864694b14c80951e84a5a3~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_2-21.png",
                        "uri": "035244_a44f726cd7af49fb94bdc71ff6f89f14~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_2-21.png",
                        "uri": "035244_a437b9994a864694b14c80951e84a5a3~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jaig9oxp"
            },
            "preset": {"rect": {"width": 108, "height": 110, "x": 215, "y": 1715}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_105",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 56,
                    "height": 56,
                    "x": 11,
                    "y": 1839,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-33.png",
                        "uri": "035244_84bf70a186044233b6d73970eb010512~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b [Recovered]-22.png",
                        "uri": "035244_6baafa34ad22400fb8be751380550043~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-33.png",
                        "uri": "035244_84bf70a186044233b6d73970eb010512~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai3fuv6"
            },
            "preset": {"rect": {"width": 70, "height": 100, "x": 0, "y": 1825}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_106",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 56,
                    "height": 56,
                    "x": 72,
                    "y": 1839,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-32.png",
                        "uri": "035244_bf267eff84f848c29f2231fa52e63885~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b [Recovered]-21.png",
                        "uri": "035244_0e50331439b1443d89559c1a252e012b~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-32.png",
                        "uri": "035244_bf267eff84f848c29f2231fa52e63885~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai3kx7t"
            },
            "preset": {"rect": {"width": 61, "height": 100, "x": 70, "y": 1825}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_107",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 56,
                    "height": 56,
                    "x": 134,
                    "y": 1839,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-31.png",
                        "uri": "035244_fd9047684abb4f6ab91b89a9a967a80a~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b [Recovered]-20.png",
                        "uri": "035244_16378ba516c34d63a70878c4305b2612~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-31.png",
                        "uri": "035244_fd9047684abb4f6ab91b89a9a967a80a~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai3lsud"
            },
            "preset": {"rect": {"width": 61, "height": 100, "x": 131, "y": 1825}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_108",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 56,
                    "height": 56,
                    "x": 196,
                    "y": 1839,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-30.png",
                        "uri": "035244_d78727b15f084484a7837d9324d8bd6d~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b [Recovered]-19.png",
                        "uri": "035244_0d000e23558b40f288250bb6e9146b70~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-30.png",
                        "uri": "035244_d78727b15f084484a7837d9324d8bd6d~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai3mk3d"
            },
            "preset": {"rect": {"width": 62, "height": 100, "x": 192, "y": 1825}, "tags": null}
        }, {
            "id": "Button_Icon_Buttons_109",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                "layout": {
                    "width": 56,
                    "height": 56,
                    "x": 258,
                    "y": 1839,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                "data": {
                    "type": "ImageButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-29.png",
                        "uri": "035244_a1db9ac95d034f12ac9fc962b876ea90~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "hoverImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon over_1b [Recovered]-18.png",
                        "uri": "035244_c79c719de5084204831fd9ad578a5888~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "activeImage": {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "icon final_1-29.png",
                        "uri": "035244_a1db9ac95d034f12ac9fc962b876ea90~mv2.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": ""
                    },
                    "alt": ""
                },
                "props": {
                    "type": "ImageButtonProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "transition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    'id': 'ImageButton_1',
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                },
                "activeModes": {},
                "id": "comp-jai3nhml"
            },
            "preset": {"rect": {"width": 70, "height": 100, "x": 254, "y": 1825}, "tags": null}
        }],
        "compTypes": ["wysiwyg.common.components.imagebutton.viewer.ImageButton"]
    },
    "help": {"hide": false, "text": "add_section_info_text_iconbutton"}
}
