import { EditorAPIKey } from '@/apis';
import type { EntryPoint } from '@/apilib';
import { createMaskCropApi } from './maskCropWrapper';
import { MaskCropApiKey } from './publicApiKey';

export const MaskCropEntrypoint: EntryPoint = {
  name: 'MaskCropApi',
  declareAPIs: () => [MaskCropApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(MaskCropApiKey, () => createMaskCropApi(shell));
  },
};
