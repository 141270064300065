import _ from 'lodash';
import * as editorHandlers from '../handlers/editorTPAHandlers';
import editorSettingsModalHandlersService from '../handlers/editorSettingsModalHandlersService';
import * as editorUILibHandlers from '../handlers/editorUILibHandlers';
import * as editorPublicDataHandlers from '../handlers/editorPublicDataHandlers';
import * as editorSuperAppsHandlers from '../handlers/editorSuperAppsHandlers';
import * as tpaPostMessageCommon from '../common/editorTPAPostMessageCommon';
import * as tpaUtils from '../utils/tpaUtils';
import constants from '../constants/constants';
import type { EditorAPI } from '@/editorAPI';
import type { TPAHandler, TPAMessage } from '../handlers/editorTPAHandlers';

function init(
  editorAPI: EditorAPI,
  listenerMethod: Window['addEventListener'],
) {
  const editorSettingsModalHandlers =
    editorSettingsModalHandlersService(editorAPI);

  type SuperAppHandlerType = keyof typeof editorSuperAppsHandlers;
  type SettingsModalHandlerType = keyof typeof editorSettingsModalHandlers;

  const isInHandlers = function (handlerSet: Record<string, TPAHandler>) {
    return function (_editorAPI: EditorAPI, msg: TPAMessage) {
      return Boolean(handlerSet[msg.type]);
    };
  };

  const shouldCallEditorSuperAppsHandler = function (
    _editorAPI: EditorAPI,
    msg: TPAMessage,
  ) {
    return (
      Boolean(editorSuperAppsHandlers[msg.type as SuperAppHandlerType]) &&
      // @ts-expect-error
      _editorAPI.dsRead.tpa.app.isSuperAppByCompId(msg.compId)
    );
  };

  const handlerGroups: {
    test: (editorAPI: EditorAPI, msg: TPAMessage) => boolean;
    handlers: Record<string, TPAHandler>;
  }[] = [
    {
      test: isInHandlers(editorPublicDataHandlers),
      handlers: editorPublicDataHandlers,
    },
    {
      test: shouldCallEditorSuperAppsHandler,
      handlers: editorSuperAppsHandlers,
    },
    {
      test: isInHandlers(editorHandlers),
      handlers: editorHandlers,
    },
    {
      test: isInHandlers(editorUILibHandlers),
      handlers: editorUILibHandlers,
    },
  ];

  const getMessageHandler = function (_editorAPI: EditorAPI, msg: TPAMessage) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const handlersGroup = _.find(handlerGroups, function (group) {
      return group.test(_editorAPI, msg);
    });
    if (handlersGroup) {
      return handlersGroup.handlers[msg.type];
    }
  };

  const callHandler = function (
    this: AnyFixMe,
    _editorAPI: EditorAPI,
    msg: TPAMessage,
    response: AnyFixMe,
    dontSetCompId: boolean,
  ) {
    if (!dontSetCompId) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(msg, {
        compId: _editorAPI.selection.getSelectedComponentId(),
      });
    }
    const settingModal = tpaUtils.getOpenedPanel(
      editorAPI.panelManager,
      constants.TPA_SETTING_MODAL,
    );
    if (
      settingModal &&
      editorSettingsModalHandlers[msg.type as SettingsModalHandlerType]
    ) {
      editorSettingsModalHandlers[msg.type as SettingsModalHandlerType].call(
        this,
        msg,
        response,
        settingModal,
      );
      // @ts-expect-error
      _editorAPI.dsActions.tpa.bi.sendBIEvent(msg, 'editor');
    } else {
      const handler = getMessageHandler(_editorAPI, msg);
      if (handler) {
        handler.call(this, _editorAPI, msg, response);
        // @ts-expect-error
        _editorAPI.dsActions.tpa.bi.sendBIEvent(msg, 'editor');
      } else {
        // @ts-expect-error
        _editorAPI.dsRead.tpa.handlers(
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign(msg, { originFrame: 'editor' }),
          response,
        );
      }
    }
  };

  function addListenerToTpaMessage(this: AnyFixMe) {
    listenerMethod(
      'message',
      tpaPostMessageCommon.handleTPAMessage.bind(this, editorAPI, callHandler),
      false,
    );
  }

  return {
    callHandler: callHandler.bind(null, editorAPI),
    addListenerToTpaMessage,
  };
}

export default init;
