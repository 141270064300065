import constants from '@/constants';
import * as stateManagement from '@/stateManagement';

import type { EditorAPI } from '@/editorAPI';

import type { MapStateToProps, ThunkAction } from 'types/redux';

const mapStateToProps: MapStateToProps = ({ editorAPI }) => {
  const { generalInfo, platform } = editorAPI.dsRead;

  return {
    mediaServices: editorAPI.mediaServices,
    language: generalInfo.getLanguage(),
    metaSiteInstance: platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    )?.instance,
    siteMediaToken: generalInfo.media.getSiteUploadToken(),
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

const mapDispatchToProps = (dispatch: FunctionFixMe) => {
  const editorAPI = dispatch(getEditorAPI);
  const { userPreferences } = stateManagement;

  return {
    saveLastMediaPath: (fileName: AnyFixMe, path: AnyFixMe) =>
      dispatch(
        userPreferences.actions.setSessionUserPreferences(
          `last_media_path_${fileName}`,
          path,
        ),
      ),
    editorBuildDefaultComponentStructure: (componentName: AnyFixMe) =>
      editorAPI.components.buildDefaultComponentStructure(componentName),
  };
};

export { mapStateToProps, mapDispatchToProps };
