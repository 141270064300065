// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import focusPanelMixin from './focusPanelMixin';
import React from 'react';
import { translate } from '@/i18n';
import * as baseUI from '@/baseUI';
import { Button, Composites, RichText, TextLabel } from '@wix/wix-base-ui';
import { FocusPanelFrame } from '../frames';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'failPanel',
  mixins: [focusPanelMixin],
  propTypes: {
    title: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    closeButtonText: PropTypes.string.isRequired,
    closePanel: PropTypes.func.isRequired,
    illustrationSymbol: PropTypes.string,
  },
  getDefaultProps() {
    return {
      illustrationSymbol: 'plaster',
    };
  },
  getKeyboardShortcuts() {
    return {
      esc: this.onClose,
    };
  },
  onClose() {
    this.props.closePanel(this.props.panelName);
  },
  render() {
    return (
      <FocusPanelFrame
        ref="frame"
        panelName={this.props.panelName}
        shouldHideHeader={false}
        shouldHideHelp={true}
        shouldNotCloseOnBlur={true}
        isBareMode={true}
        title={translate(this.props.title)}
        keyboardShortcuts={this.getKeyboardShortcuts()}
        className="fail-panel-general"
      >
        <div className="content-wrapper">
          <div className="icon">
            <baseUI.symbol name={this.props.illustrationSymbol} />
          </div>
          <Composites.RichText className="body-text">
            <RichText type="T06">
              <span data-aid="error-message">
                {translate(this.props.bodyText)}
              </span>
            </RichText>
          </Composites.RichText>
          <Button onClick={this.onClose} className="primary close-button">
            <TextLabel value={this.props.closeButtonText} />
          </Button>
        </div>
      </FocusPanelFrame>
    );
  },
});
