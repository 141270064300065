export const restoreSession = (runWithAfterFailParam = false): void => {
  const url = new URL(window.location.href);
  if (runWithAfterFailParam) {
    url.searchParams.set('failedMigration', 'true');
  }
  url.searchParams.delete('forceSectionsMigration');
  window.history.replaceState(
    {
      ...window.history.state,
    },
    '',
    url.toString(),
  );
  window.onbeforeunload = null;
  // @ts-expect-error
  window.location.reload(true);

  return;
};
