// @ts-nocheck
import * as stateManagement from '@/stateManagement';

function getNotificationKey(id) {
  return `quick-actions-notification-${id}`;
}

function hasNotification(editorAPI, id) {
  const key = getNotificationKey(id);
  return stateManagement.userPreferences.selectors.getSessionUserPreferences(
    key,
  )(editorAPI.store.getState());
}

function setNotification(editorAPI, id, value) {
  const key = getNotificationKey(id);
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSessionUserPreferences(
      key,
      value,
    ),
  );
}

function turnNotificationOn(editorAPI, id) {
  setNotification(editorAPI, id, true);
}

function turnNotificationOff(editorAPI, id) {
  setNotification(editorAPI, id, false);
}

export { hasNotification, turnNotificationOn, turnNotificationOff };
