:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._show-all-versions-for-private-app_1xodx_7 {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden; }
  ._show-all-versions-for-private-app_1xodx_7 hr {
    flex: none; }

._versions-list_1xodx_16 {
  flex: 1; }

._available-versions_1xodx_19 {
  padding: 8px 24px; }

._loader-container_1xodx_22 {
  display: grid;
  height: 100%;
  align-items: center;
  justify-content: center; }

._version-installed-icon_1xodx_28 {
  margin-left: -2px; }

._versions-list-test-divider_1xodx_31 {
  margin: 4px auto !important; }

._test-version-item_1xodx_34 {
  border-bottom: none !important; }

._version-list-error_1xodx_37 {
  margin: 0 24px; }
