import * as util from '@/util';

import CoverImage from './coverImage';
import { mapStateToProps, mapDispatchToProps } from './coverImageMapper';
import * as stateManagement from '@/stateManagement';
import type { CoverImageProps } from './types';

const CoverImageWithPanelDispatch = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(CoverImage as React.FC<CoverImageProps>);

export default stateManagement.components.hoc.compPanel(
  CoverImageWithPanelDispatch,
);
