// @ts-nocheck
export default {
  invalidateViewCache() {
    const editorAPI = this.getEditorAPI();
    const componentId = editorAPI.selection.getSelectedComponentId();
    editorAPI.dsActions.wixapps.classics.invalidateComponentViewCache(
      componentId,
    );
  },
};
