'use strict';
const addPanelDataConsts = require('@/addPanelDataConsts')
const moreBehaviorHelper = require("./moreBehaviorHelper")

module.exports =
  {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_media_player_transparent_label",
    "automationId": "add-panel-section-videoBoxTransparentSection",
    "showSectionHeader": true,
    "additionalBehaviours": {
      "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
      "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.VIDEO,
      "iconEnabledForComps": {}
    },
    "props": {
      'image': 'addPanelData/sections/videoSection_en/transparent-video_en.v4.png',
      'retinaImage': {
        'src': 'addPanelData/sections/videoSection_en/transparent-video_en-@2.v4.png',
        'dimensions': {
          'width': 648,
          'height': 968
        }
      },
      "imageHover": null,
      "items": [
        {
          "id": "VideoBox_VideoBoxTransparent_1",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "xae-1317",
              "pageId": "q330v"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "dau-331",
                  "pageId": "q330v"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 70,
                  "height": 70,
                  "x": 84,
                  "y": 175,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kpqzbpio",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-5529",
                    "autoGenerated": false,
                    "pageId": "q330v"
                  },
                  "playerId": "comp-kpqzbpio"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "dau-321",
                    "pageId": "q330v"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "dau-328",
                      "pageId": "q330v"
                    },
                    "overrideColors": {
                      "color1": "color_15"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5524",
                        "pageId": "q330v"
                      },
                      "name": "playButton",
                      "svgId": "0da768_661bc0cafffa432db3753ad5d17e4f10.svg"
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1010",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-1267",
                  "pageId": "q330v"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 188,
                  "y": 376,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kpqzbpio",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-5540",
                    "autoGenerated": false,
                    "pageId": "q330v"
                  },
                  "showStoryboard": "none",
                  "playerId": "comp-kpqzbpio"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-5531",
                    "pageId": "q330v"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-5536",
                      "pageId": "q330v"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5535",
                        "pageId": "q330v"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_4026746ebab74627a513aa0df1511dea.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5534",
                        "pageId": "q330v"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5532",
                        "pageId": "q330v"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "yje-5533",
                          "pageId": "q330v"
                        },
                        "overrideColors": {
                          "color1": "#a9a9a9"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1021",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d",
                      "disabledcolor": "#8d8d8d"
                    },
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaPlayerSkin",
            "layout": {
              "width": 239,
              "height": 421,
              "x": 21,
              "y": 19,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kpqzbpie",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "yje-5514",
                "pageId": "q330v"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "none",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "elk-27",
                "pageId": "q330v"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "elk-28",
                  "pageId": "q330v"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "elk-29",
                    "pageId": "q330v"
                  },
                  "title": "Headspin",
                  "videoId": "11062b_b6a38f8370e241c782f0f122073a0d83",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 274,
                      "height": 480,
                      "url": "video/11062b_b6a38f8370e241c782f0f122073a0d83/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 410,
                      "height": 720,
                      "url": "video/11062b_b6a38f8370e241c782f0f122073a0d83/720p/mp4/file.mp4"
                    },
                    {
                      "quality": "1080p",
                      "width": 616,
                      "height": 1080,
                      "url": "video/11062b_b6a38f8370e241c782f0f122073a0d83/1080p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "elk-30",
                      "pageId": "q330v"
                    },
                    "title": "",
                    "uri": "11062b_b6a38f8370e241c782f0f122073a0d83f000.png",
                    "description": "public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/078ecd58-f828-44f6-ac91-8cafbf5dbdae",
                    "width": 1248,
                    "height": 2192,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 6.4,
                  "preload": "auto",
                  "artist": {
                    "id": "",
                    "name": ""
                  },
                  "generatedPosters": [
                    "11062b_b6a38f8370e241c782f0f122073a0d83f000.png",
                    "11062b_b6a38f8370e241c782f0f122073a0d83f001.png",
                    "11062b_b6a38f8370e241c782f0f122073a0d83f002.png",
                    "11062b_b6a38f8370e241c782f0f122073a0d83f003.png"
                  ],
                  "hasAudio": false,
                  "fps": "30",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_b6a38f8370e241c782f0f122073a0d83/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ],
                  "mediaFeatures": [
                    "alpha"
                  ]
                },
                "alignType": "center",
                "fittingType": "fill",
                "colorOverlay": "",
                "colorOverlayOpacity": 0
              },
              "cssStyle": {}
            },
            "style": {
              "type": "TopLevelStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "ua9-1003",
                "pageId": "masterPage"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "system",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kpqzbpio"
          },
          "preset": {
            "rect": {
              "width": 144,
              "height": 238,
              "x": 0,
              "y": 0
            },
            "mediaManager": {
              "path": "public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/a25040d5-1cb2-4d49-9173-ff53909be8f5"
            }
          }
        },
        {
          "id": "VideoBox_VideoBoxTransparent_2",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "dau-313",
              "pageId": "q330v"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "dau-782",
                  "pageId": "q330v"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 72,
                  "height": 72,
                  "x": 108,
                  "y": 93,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kpqzbpiw",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-5557",
                    "autoGenerated": false,
                    "pageId": "q330v"
                  },
                  "playerId": "comp-kpqzbpiw"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "dau-789",
                    "pageId": "q330v"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "dau-370",
                      "pageId": "q330v"
                    },
                    "overrideColors": {
                      "color1": "color_11"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "dau-790",
                        "pageId": "q330v"
                      },
                      "name": "playButton",
                      "svgId": "0da768_70d326d6112c4597bee7759165a86dc2.svg"
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1010",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-1375",
                  "pageId": "q330v"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 237,
                  "y": 214,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kpqzbpiw",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-5568",
                    "autoGenerated": false,
                    "pageId": "q330v"
                  },
                  "showStoryboard": "none",
                  "playerId": "comp-kpqzbpiw"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-5559",
                    "pageId": "q330v"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-5564",
                      "pageId": "q330v"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5563",
                        "pageId": "q330v"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_4026746ebab74627a513aa0df1511dea.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5562",
                        "pageId": "q330v"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5560",
                        "pageId": "q330v"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "yje-5561",
                          "pageId": "q330v"
                        },
                        "overrideColors": {
                          "color1": "#a9a9a9"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1021",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d",
                      "disabledcolor": "#8d8d8d"
                    },
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaPlayerSkin",
            "layout": {
              "width": 288,
              "height": 258,
              "x": 379,
              "y": 18,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kpqzbpie",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "yje-5542",
                "pageId": "q330v"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "none",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "yje-5543",
                "pageId": "q330v"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "yje-5544",
                  "pageId": "q330v"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-5545",
                    "pageId": "q330v"
                  },
                  "title": "Wood white Butterfly",
                  "videoId": "11062b_b95278b89b434f06bf3b8a9860eadc68",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 832,
                      "height": 744,
                      "url": "video/11062b_b95278b89b434f06bf3b8a9860eadc68/480p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-5546",
                      "pageId": "q330v"
                    },
                    "title": "",
                    "uri": "11062b_b95278b89b434f06bf3b8a9860eadc68f000.png",
                    "description": "public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/078ecd58-f828-44f6-ac91-8cafbf5dbdae",
                    "width": 832,
                    "height": 744,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 7.61,
                  "preload": "auto",
                  "artist": {
                    "id": "",
                    "name": ""
                  },
                  "generatedPosters": [
                    "11062b_b95278b89b434f06bf3b8a9860eadc68f000.png",
                    "11062b_b95278b89b434f06bf3b8a9860eadc68f001.png",
                    "11062b_b95278b89b434f06bf3b8a9860eadc68f002.png",
                    "11062b_b95278b89b434f06bf3b8a9860eadc68f003.png"
                  ],
                  "hasAudio": false,
                  "fps": "30",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_b95278b89b434f06bf3b8a9860eadc68/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ],
                  "mediaFeatures": [
                    "alpha"
                  ]
                },
                "alignType": "center",
                "fittingType": "fill",
                "colorOverlay": "",
                "colorOverlayOpacity": 0
              },
              "cssStyle": {}
            },
            "style": {
              "type": "TopLevelStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "ua9-1003",
                "pageId": "masterPage"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "system",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kpqzbpiw"
          },
          "preset": {
            "rect": {
              "width": 180,
              "height": 164,
              "x": 144,
              "y": 0
            },
            "mediaManager": {
              "path": "public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/b5ef3160-148f-48b2-b95d-a7056191db75"
            }
          }
        },
        {
          "id": "VideoBox_VideoBoxTransparent_3",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "dau-411",
              "pageId": "q330v"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "dau-777",
                  "pageId": "q330v"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 78,
                  "height": 78,
                  "x": 164,
                  "y": 89,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kpqzbpj2",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-5584",
                    "autoGenerated": false,
                    "pageId": "q330v"
                  },
                  "playerId": "comp-kpqzbpj2"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "dau-386",
                    "pageId": "q330v"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "dau-385",
                      "pageId": "q330v"
                    },
                    "overrideColors": {
                      "color1": "color_11"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "dau-387",
                        "pageId": "q330v"
                      },
                      "name": "playButton",
                      "svgId": "0da768_b94b0c8d9ad140b397386b0796eab836.svg"
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1010",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "yje-6032",
                  "pageId": "q330v"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 356,
                  "y": 212,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kpqzbpj2",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-5594",
                    "autoGenerated": false,
                    "pageId": "q330v"
                  },
                  "showStoryboard": "none",
                  "playerId": "comp-kpqzbpj2"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-5586",
                    "pageId": "q330v"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-5591",
                      "pageId": "q330v"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5590",
                        "pageId": "q330v"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_4026746ebab74627a513aa0df1511dea.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5589",
                        "pageId": "q330v"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5587",
                        "pageId": "q330v"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "yje-5588",
                          "pageId": "q330v"
                        },
                        "overrideColors": {
                          "color1": "#a9a9a9"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1021",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d",
                      "disabledcolor": "#8d8d8d"
                    },
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaPlayerSkin",
            "layout": {
              "width": 407,
              "height": 256,
              "x": 31,
              "y": 544,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kpqzbpie",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "yje-5571",
                "pageId": "q330v"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "none",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "yje-5572",
                "pageId": "q330v"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "yje-5573",
                  "pageId": "q330v"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-5574",
                    "pageId": "q330v"
                  },
                  "title": "Brown Horse",
                  "videoId": "11062b_a93e53578522406f96b7821bd5aac77c",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 764,
                      "height": 480,
                      "url": "video/11062b_a93e53578522406f96b7821bd5aac77c/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 1146,
                      "height": 720,
                      "url": "video/11062b_a93e53578522406f96b7821bd5aac77c/720p/mp4/file.mp4"
                    },
                    {
                      "quality": "1080p",
                      "width": 1680,
                      "height": 1056,
                      "url": "video/11062b_a93e53578522406f96b7821bd5aac77c/1080p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-5575",
                      "pageId": "q330v"
                    },
                    "title": "",
                    "uri": "11062b_a93e53578522406f96b7821bd5aac77cf000.png",
                    "description": "public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/078ecd58-f828-44f6-ac91-8cafbf5dbdae",
                    "width": 1680,
                    "height": 1056,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 4.2,
                  "preload": "auto",
                  "artist": {
                    "id": "",
                    "name": ""
                  },
                  "generatedPosters": [
                    "11062b_a93e53578522406f96b7821bd5aac77cf000.png",
                    "11062b_a93e53578522406f96b7821bd5aac77cf001.png",
                    "11062b_a93e53578522406f96b7821bd5aac77cf002.png",
                    "11062b_a93e53578522406f96b7821bd5aac77cf003.png"
                  ],
                  "hasAudio": false,
                  "fps": "30/1",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_a93e53578522406f96b7821bd5aac77c/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ],
                  "mediaFeatures": [
                    "alpha"
                  ]
                },
                "alignType": "center",
                "fittingType": "fill",
                "colorOverlay": "",
                "colorOverlayOpacity": 0
              },
              "cssStyle": {}
            },
            "style": {
              "type": "TopLevelStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "ua9-1003",
                "pageId": "masterPage"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "system",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kpqzbpj2"
          },
          "preset": {
            "rect": {
              "width": 156,
              "height": 108,
              "x": 0,
              "y": 238
            },
            "mediaManager": {
              "path": "public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/841033e4-5c33-4c6c-949c-3fbd1dc0e8ae"
            }
          }
        },
        {
          "id": "VideoBox_VideoBoxTransparent_4",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "dau-439",
              "pageId": "q330v"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "dau-497",
                  "pageId": "q330v"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 96,
                  "height": 96,
                  "x": 121,
                  "y": 140,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kpqzbpy1",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-5476",
                    "autoGenerated": false,
                    "pageId": "q330v"
                  },
                  "playerId": "comp-kpqzbpy1"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "dau-498",
                    "pageId": "q330v"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "dau-451",
                      "pageId": "q330v"
                    },
                    "overrideColors": {
                      "color1": "color_11"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "dau-499",
                        "pageId": "q330v"
                      },
                      "name": "playButton",
                      "svgId": "0da768_70d326d6112c4597bee7759165a86dc2.svg"
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1010",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "dau-415",
                  "pageId": "q330v"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 287,
                  "y": 333,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kpqzbpy1",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-5486",
                    "autoGenerated": false,
                    "pageId": "q330v"
                  },
                  "showStoryboard": "none",
                  "playerId": "comp-kpqzbpy1"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-5478",
                    "pageId": "q330v"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-5483",
                      "pageId": "q330v"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5482",
                        "pageId": "q330v"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_4026746ebab74627a513aa0df1511dea.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5481",
                        "pageId": "q330v"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5479",
                        "pageId": "q330v"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "yje-5480",
                          "pageId": "q330v"
                        },
                        "overrideColors": {
                          "color1": "#a9a9a9"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1021",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d",
                      "disabledcolor": "#8d8d8d"
                    },
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaPlayerSkin",
            "layout": {
              "width": 338,
              "height": 377,
              "x": 542,
              "y": 599,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kpqzbpie",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "yje-5463",
                "pageId": "q330v"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "none",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "yje-5464",
                "pageId": "q330v"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "yje-5465",
                  "pageId": "q330v"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-5466",
                    "pageId": "q330v"
                  },
                  "title": "Spinning rod",
                  "videoId": "11062b_c7f00e349e8947cd9db0c3d5da2ac533",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 432,
                      "height": 480,
                      "url": "video/11062b_c7f00e349e8947cd9db0c3d5da2ac533/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 848,
                      "height": 944,
                      "url": "video/11062b_c7f00e349e8947cd9db0c3d5da2ac533/720p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-5467",
                      "pageId": "q330v"
                    },
                    "title": "",
                    "uri": "11062b_c7f00e349e8947cd9db0c3d5da2ac533f000.png",
                    "description": "public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/078ecd58-f828-44f6-ac91-8cafbf5dbdae",
                    "width": 848,
                    "height": 944,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 10.02,
                  "preload": "auto",
                  "artist": {
                    "id": "",
                    "name": ""
                  },
                  "generatedPosters": [
                    "11062b_c7f00e349e8947cd9db0c3d5da2ac533f000.png",
                    "11062b_c7f00e349e8947cd9db0c3d5da2ac533f001.png",
                    "11062b_c7f00e349e8947cd9db0c3d5da2ac533f002.png",
                    "11062b_c7f00e349e8947cd9db0c3d5da2ac533f003.png"
                  ],
                  "hasAudio": false,
                  "fps": "60/1",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_c7f00e349e8947cd9db0c3d5da2ac533/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ],
                  "mediaFeatures": [
                    "alpha"
                  ]
                },
                "alignType": "center",
                "fittingType": "fill",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "filterEffect": {
                  "effectType": "tvMonotoneDark",
                  "brightness": 1.2,
                  "duotoneDark": "#000000",
                  "duotoneLight": "#407C51",
                  "isCustom": true
                }
              },
              "cssStyle": {},
              "charas": "design-ko9umt9b"
            },
            "style": {
              "type": "TopLevelStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "ua9-1003",
                "pageId": "masterPage"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "system",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kpqzbpy1"
          },
          "preset": {
            "rect": {
              "width": 168,
              "height": 182,
              "x": 156,
              "y": 164
            },
            "mediaManager": {
              "path": "public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/b5ef3160-148f-48b2-b95d-a7056191db75"
            }
          }
        },
        {
          "id": "VideoBox_VideoBoxTransparent_5",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "xae-1565",
              "pageId": "q330v"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "dau-591",
                  "pageId": "q330v"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 87,
                  "height": 87,
                  "x": 127,
                  "y": 82,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kpqzbpj8",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-5622",
                    "autoGenerated": false,
                    "pageId": "q330v"
                  },
                  "playerId": "comp-kpqzbpj8"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "dau-545",
                    "pageId": "q330v"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "dau-530",
                      "pageId": "q330v"
                    },
                    "overrideColors": {
                      "color1": "color_11"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "dau-546",
                        "pageId": "q330v"
                      },
                      "name": "playButton",
                      "svgId": "0da768_88bec97e49f946ddae2d0684fb5f830b.svg"
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1010",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-1554",
                  "pageId": "q330v"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 290,
                  "y": 207,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kpqzbpj8",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-5633",
                    "autoGenerated": false,
                    "pageId": "q330v"
                  },
                  "showStoryboard": "none",
                  "playerId": "comp-kpqzbpj8"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-5624",
                    "pageId": "q330v"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-5629",
                      "pageId": "q330v"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5628",
                        "pageId": "q330v"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_4026746ebab74627a513aa0df1511dea.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5627",
                        "pageId": "q330v"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5625",
                        "pageId": "q330v"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "yje-5626",
                          "pageId": "q330v"
                        },
                        "overrideColors": {
                          "color1": "#a9a9a9"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1021",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d",
                      "disabledcolor": "#8d8d8d"
                    },
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaPlayerSkin",
            "layout": {
              "width": 341,
              "height": 251,
              "x": 21,
              "y": 1102,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kpqzbpie",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "yje-5607",
                "pageId": "q330v"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "none",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "yje-5608",
                "pageId": "q330v"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "yje-5609",
                  "pageId": "q330v"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-5610",
                    "pageId": "q330v"
                  },
                  "title": "Rotating Rock",
                  "videoId": "11062b_72f4a516964a4df085cd8380a93bb48b",
                  "qualities": [
                    {
                      "quality": "360p",
                      "width": 640,
                      "height": 470,
                      "url": "video/11062b_72f4a516964a4df085cd8380a93bb48b/360p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-5611",
                      "pageId": "q330v"
                    },
                    "title": "",
                    "uri": "11062b_72f4a516964a4df085cd8380a93bb48bf000.png",
                    "description": "public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/078ecd58-f828-44f6-ac91-8cafbf5dbdae",
                    "width": 640,
                    "height": 470,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 22.92,
                  "preload": "auto",
                  "artist": {
                    "id": "",
                    "name": ""
                  },
                  "generatedPosters": [
                    "11062b_72f4a516964a4df085cd8380a93bb48bf000.png",
                    "11062b_72f4a516964a4df085cd8380a93bb48bf001.png",
                    "11062b_72f4a516964a4df085cd8380a93bb48bf002.png",
                    "11062b_72f4a516964a4df085cd8380a93bb48bf003.png"
                  ],
                  "hasAudio": false,
                  "fps": "30000/1001",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_72f4a516964a4df085cd8380a93bb48b/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ],
                  "mediaFeatures": [
                    "alpha"
                  ]
                },
                "alignType": "center",
                "fittingType": "fill",
                "colorOverlay": "",
                "colorOverlayOpacity": 0
              },
              "cssStyle": {},
              "charas": "design-ko9ujrjj"
            },
            "style": {
              "type": "TopLevelStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "ua9-1003",
                "pageId": "masterPage"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "system",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kpqzbpj8"
          },
          "preset": {
            "rect": {
              "width": 180,
              "height": 138,
              "x": 0,
              "y": 346
            },
            "mediaManager": {
              "path": "public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/b5ef3160-148f-48b2-b95d-a7056191db75"
            }
          }
        },
        {
          "id": "VideoBox_VideoBoxTransparent_6",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "dau-516",
              "pageId": "q330v"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "dau-578",
                  "pageId": "q330v"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 73,
                  "height": 73,
                  "x": 89,
                  "y": 89,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kpqzbpyl",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-5500",
                    "autoGenerated": false,
                    "pageId": "q330v"
                  },
                  "playerId": "comp-kpqzbpyl"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "dau-564",
                    "pageId": "q330v"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "dau-563",
                      "pageId": "q330v"
                    },
                    "overrideColors": {
                      "color1": "color_11"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "dau-565",
                        "pageId": "q330v"
                      },
                      "name": "playButton",
                      "svgId": "0da768_152a8490c7444b949a51e30f2ac4d9a4.svg"
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1010",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "dau-504",
                  "pageId": "q330v"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 200,
                  "y": 207,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kpqzbpyl",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-5510",
                    "autoGenerated": false,
                    "pageId": "q330v"
                  },
                  "showStoryboard": "none",
                  "playerId": "comp-kpqzbpyl"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-5502",
                    "pageId": "q330v"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-5507",
                      "pageId": "q330v"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5506",
                        "pageId": "q330v"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_4026746ebab74627a513aa0df1511dea.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5505",
                        "pageId": "q330v"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-5503",
                        "pageId": "q330v"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "yje-5504",
                          "pageId": "q330v"
                        },
                        "overrideColors": {
                          "color1": "#a9a9a9"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1021",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d",
                      "disabledcolor": "#8d8d8d"
                    },
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaPlayerSkin",
            "layout": {
              "width": 251,
              "height": 251,
              "x": 519,
              "y": 1102,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kpqzbpie",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "yje-5487",
                "pageId": "q330v"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "none",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "yje-5488",
                "pageId": "q330v"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "yje-5489",
                  "pageId": "q330v"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-5490",
                    "pageId": "q330v"
                  },
                  "title": "Ripple cube",
                  "videoId": "11062b_f4aee2e9528b40d7bac10a1d306e367d",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 480,
                      "height": 480,
                      "url": "video/11062b_f4aee2e9528b40d7bac10a1d306e367d/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 720,
                      "height": 720,
                      "url": "video/11062b_f4aee2e9528b40d7bac10a1d306e367d/720p/mp4/file.mp4"
                    },
                    {
                      "quality": "1080p",
                      "width": 1350,
                      "height": 1350,
                      "url": "video/11062b_f4aee2e9528b40d7bac10a1d306e367d/1080p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-5491",
                      "pageId": "q330v"
                    },
                    "title": "",
                    "uri": "11062b_f4aee2e9528b40d7bac10a1d306e367df000.png",
                    "description": "public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/078ecd58-f828-44f6-ac91-8cafbf5dbdae",
                    "width": 1350,
                    "height": 1350,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 6.67,
                  "preload": "auto",
                  "artist": {
                    "id": "",
                    "name": ""
                  },
                  "generatedPosters": [
                    "11062b_f4aee2e9528b40d7bac10a1d306e367df000.png",
                    "11062b_f4aee2e9528b40d7bac10a1d306e367df001.png",
                    "11062b_f4aee2e9528b40d7bac10a1d306e367df002.png",
                    "11062b_f4aee2e9528b40d7bac10a1d306e367df003.png"
                  ],
                  "hasAudio": false,
                  "fps": "60/1",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_f4aee2e9528b40d7bac10a1d306e367d/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ],
                  "mediaFeatures": [
                    "alpha"
                  ]
                },
                "alignType": "center",
                "fittingType": "fill",
                "colorOverlay": "",
                "colorOverlayOpacity": 0
              },
              "cssStyle": {}
            },
            "style": {
              "type": "TopLevelStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "ua9-1003",
                "pageId": "masterPage"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "system",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kpqzbpyl"
          },
          "preset": {
            "rect": {
              "width": 144,
              "height": 138,
              "x": 180,
              "y": 346
            },
            "mediaManager": {
              "path": "public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/841033e4-5c33-4c6c-949c-3fbd1dc0e8ae"
            }
          }
        }
      ],
      "compTypes": [
        "wysiwyg.viewer.components.MediaPlayer"
      ]
    },
    "subNavigationTitle": "add_section_media_player_transparent_label",
    "presetTitle": "add_section_media_player_transparent_label",
    "tooltipTitle": "add_section_media_player_transparent_label",
    "subNavigationHide": false,
    "sectionFooter": {
      "label": "add_section_transparentvideo_more_tooltip_title",
      "styleOverride": {"paddingTop": "25px"},
      "action": moreBehaviorHelper,
      "category": "VIDEO_BOX",
      "openSource": "add_panel_videobox_transparent_all",
      "path": "public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/078ecd58-f828-44f6-ac91-8cafbf5dbdae"
    },
    "help": {
      "hide": false,
      "text": "add_section_media_player_transparent_label_tooltip_title"
    }
  };
