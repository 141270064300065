import type { EditorAPI } from '@/editorAPI';
import type { StateMapperArgs, Dispatch, ThunkAction } from 'types/redux';

const getEditorAPI: ThunkAction<EditorAPI> = (
  _dispatch,
  _getState,
  { editorAPI },
) => editorAPI;

export const mapStateToProps = ({ editorAPI }: StateMapperArgs) => ({
  siteStructureId: editorAPI.dsRead.siteSegments.getSiteStructureId(),
});

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  return {
    createDefaultData(id: string) {
      return editorAPI.dsRead.data.createItem(id);
    },
  };
};
