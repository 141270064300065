import { interactions, hoverBox, selection } from '@/stateManagement';
import { sections } from '@/util';
import {
  createStructuredSelector,
  createSelector,
} from '../../../selectors/selector';
import {
  editorStateSel,
  editorAPISel,
  editorAPIMouseSel,
  editorStateMouseOpsSel,
} from '../../selectors/rootSelectors';
import { getIsLabelBottom } from '../../selectors/isLabelBottom';
import { getLayout, isComponentBoxRotated } from '../editBox/common';
import { siteScaleSel, isPinModeSel } from '../../selectors/other';

import type { CompRef } from 'types/documentServices';
import type { CSSProperties } from 'react';
import type { MapStateToProps } from 'types/redux';

export interface HoverBoxStateProps {
  hoverBoxStyles: CSSProperties;
  hoverClasses: { [key: string]: boolean };
  isLabelBottom: boolean;
  hoveredComp: CompRef;
}

export const hoveredCompSel = createSelector(
  [editorStateSel],
  hoverBox.selectors.getHoveredComp,
);

const hoveredCompLayoutSel = createSelector(
  [editorAPIMouseSel, hoveredCompSel],
  getLayout,
);

const hoveredCompRestrictions = createSelector(
  [editorAPISel, hoveredCompSel],
  (editorAPI, hoveredComp) => editorAPI.getCompRestrictions(hoveredComp),
);

const isFullWidthCompSel = createSelector(
  [editorAPISel, hoveredCompSel],
  (editorAPI, hoveredComp) => editorAPI.components.is.fullWidth(hoveredComp),
);

const hoverBoxStylesSel = createSelector(
  [hoveredCompLayoutSel, hoveredCompRestrictions, isFullWidthCompSel],
  (layout, compRestrictions, isFullWidthComp) => {
    let stylesForHoverBox = {
      top: layout.y - 1,
      left: layout.x - 1,
      width: layout.width,
      height: layout.height,
    };

    const isBoxRotated = isComponentBoxRotated({
      compLayout: layout,
      compRestrictions,
    });

    if (isBoxRotated) {
      const transformValue = `rotate(${layout.rotationInDegrees}deg)`;
      stylesForHoverBox = {
        ...stylesForHoverBox,
        ...{ transform: transformValue, WebkitTransform: transformValue },
      };
    }

    if (isFullWidthComp) {
      stylesForHoverBox = {
        ...stylesForHoverBox,
        ...{ borderLeft: 0, borderRight: 0 },
      };
    }
    return stylesForHoverBox;
  },
);

const hoverBoxOverlaySel = createSelector(
  [editorStateSel],
  hoverBox.selectors.getHoverBoxOverlay,
);

const isDragInProgressSel = createSelector(
  editorStateMouseOpsSel,
  (state) => state.mouseActions.dragInProgress,
);

const hoverClassesSel = createSelector(
  [editorAPISel, hoveredCompSel, hoverBoxOverlaySel],
  (editorAPI, compPointer, overlay) => {
    const isShowOnAllPages = editorAPI.components.isShowOnAllPages(compPointer);
    const isShowOnSomePages =
      editorAPI.components.isShowOnSomePages(compPointer);
    const isPage = editorAPI.components.is.page(compPointer);

    const classes = {
      'hover-box': true,
      'components-background-color': !isPage,
      'components-ui-color-orange': isShowOnAllPages || isShowOnSomePages,
      'fixed-position':
        editorAPI.components.layout.isShowOnFixedPosition(compPointer),
      overlay,
    };

    return classes;
  },
);

const isHoverLabelBottomSel = createSelector(
  [editorAPIMouseSel, hoveredCompSel],
  getIsLabelBottom,
);

export const mapStateToProps: MapStateToProps<HoverBoxStateProps> =
  createStructuredSelector({
    hoverClasses: hoverClassesSel,
    hoverBoxStyles: hoverBoxStylesSel,
    isLabelBottom: isHoverLabelBottomSel,
    hoveredComp: hoveredCompSel,
  });

export const shouldShowHoverboxSel = createSelector(
  [
    editorAPISel,
    hoveredCompSel,
    siteScaleSel,
    isPinModeSel,
    editorStateSel,
    isDragInProgressSel,
  ],
  (
    editorAPI,
    hoveredComp,
    siteScale,
    isPinMode,
    editorState,
    isDragInProgress,
  ) => {
    const isHoveredCompExists = editorAPI.components.is.exist(hoveredComp);
    const isInteractionMode =
      interactions.selectors.isInInteractionMode(editorState);
    const focusedContainer =
      selection.selectors.getFocusedContainer(editorState);
    const isHoveredComponentFocused = editorAPI.utils.isSameRef(
      focusedContainer,
      hoveredComp,
    );
    const isHoveredSectionLike =
      sections.isSectionsEnabled() &&
      editorAPI.sections.isSectionLike(hoveredComp);

    return (
      !isDragInProgress &&
      siteScale === 1 &&
      hoveredComp &&
      isHoveredCompExists &&
      !isPinMode &&
      !isInteractionMode &&
      !isHoveredComponentFocused &&
      !isHoveredSectionLike
    );
  },
);
