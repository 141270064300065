import constants from '@/constants';
import { StageAnchorConstants } from './constants';
import { getStageAnchorLineYConsideringScroll } from './getStageAnchorLineY';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

/**
 * Get the anchor line right position
 *
 * DESTOP:
 * [-site-x-][---site:980---][-site-x-]
 * [-----------stageUIWidth-----------]
 * [-------------------------◀︎◼️◼️◼️-----]👈
 * MOBILE:
 * [-site-x-][---site:320---]
 * [        ][------◀︎◼️◼️◼️----]👈
 */
function getStageAnchorLineRight(editorAPI: EditorAPI, anchorRef: CompRef) {
  const stageUIWidth = editorAPI.ui.stage.getEditingAreaLayout().width;

  const anchorLineRight =
    anchorRef.type === 'MOBILE'
      ? editorAPI.site.getSiteX() + editorAPI.site.getWidth()
      : stageUIWidth;

  return anchorLineRight;
}

/**
 * Get the anchor handle rect
 * [--------line-------][◀︎◼️◼️◼️handle][----]
 */
export function getStageAnchorHandleRectConsideringScroll(
  editorAPI: EditorAPI,
  anchorRef: CompRef,
) {
  const { anchorHandleWidth, anchorHandleHeight, anchorHandleMarginRight } =
    StageAnchorConstants;

  const anchorLineY = getStageAnchorLineYConsideringScroll(
    editorAPI,
    anchorRef,
  );
  const anchorLineRight = getStageAnchorLineRight(editorAPI, anchorRef);

  const anchorHandleRect = {
    left: anchorLineRight - anchorHandleMarginRight - anchorHandleWidth,
    right: anchorLineRight - anchorHandleMarginRight,
    top: anchorLineY - anchorHandleHeight / 2,
    bottom: anchorLineY - anchorHandleHeight / 2 + anchorHandleHeight,
  };

  return anchorHandleRect;
}

/**
 * @deprecated use getStageAnchorHandleRectConsideringScroll instead
 * Original code is taken from the https://github.com/wix-private/santa-editor/blob/683a3556f6b5d4a3de4aa264ed5d9159533dcece/packages/rEditor/src/main/app/APISections/selection.js#L235-L247
 *
 * Problems:
 * Document Service `getRelativeToScreenConsideringScroll` implementation for anchor[1] contains information, which it should not contain,
 * AnchorStageHandle UI constants belong to this repo, and should not be in the document service
 * (ANCHOR_WIDTH, MARGINS_FROM_RIGHT, anhcor line right, anchor x calulation, etc. [1])
 *
 * [1]: https://github.com/wix-private/document-management/blob/35a0051a875b195287de5593d3cd4a042ff7a136/document-services-implementation/src/structure/relativeToScreenPlugins/anchorPlugin.ts#L7-L20
 */
export function getStageAnchorHandleRectConsideringScroll_deprecated(
  editorAPI: EditorAPI,
  anchorRef: CompRef,
) {
  const { dsRead } = editorAPI;
  const anchorLayout =
    dsRead.components.layout.getRelativeToScreenConsideringScroll(anchorRef);

  const { UI } = constants;
  return {
    left: anchorLayout.x,
    right: anchorLayout.x + UI.ANCHOR_WIDTH,
    top: anchorLayout.y + UI.ANCHOR_OFFSET_TOP,
    bottom: anchorLayout.y + UI.ANCHOR_OFFSET_TOP + UI.ANCHOR_HEIGHT,
  };
}
