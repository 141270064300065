import * as util from '@/util';

export const SITE_BUSINESS_DATA_URL = `${util.serviceTopology.editorRootUrl}_serverless/editor-client-service/site-business-data`;
export const SITE_BUSINESS_NAME_URL =
  '/_api/site-properties-service/properties?fields.paths=businessName';
export const DEFAULT_BUSINESS_NAME = 'site_creation_site_business_name_default';

// Default business type is set to "Other"
export const DEFAULT_BUSINESS_TYPE = {
  industryId: 'e79e5765052b89b579c9a4c2',
  structureId: '40dc538a8a6c3e2dae342922',
  displayName: 'Other',
};
