// @ts-nocheck
import * as core from '@/core';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import * as styles from '@/styles';
import * as util from '@/util';
import { recompose } from '@wix/santa-editor-utils';
import { EditorParamsApiKey } from '@/apis';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const { isInInteractionMode } = stateManagement.interactions.selectors;

const { styleUtil } = styles.advancedStyle;
const { advancedStyleDataUtil } = styles.advancedStyle; //getSkinDefaultParams

const DISABLED_UNDO_STACK_PARAM_TYPES = [
  'TEXT_COLOR',
  'COLOR',
  'BG_COLOR',
  'BORDER_COLOR',
]; // alpha has special handling in linkStyleParam
/*, 'COLOR_ALPHA', 'BOX_SHADOW_COLOR_ALPHA', 'BG_COLOR_ALPHA', 'BORDER_COLOR_ALPHA'*/ function isDisabledUndoStackParamType(
  paramType,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  return _.includes(DISABLED_UNDO_STACK_PARAM_TYPES, paramType);
}

const updateStyleDataItem = (styleDataItem, forcedUpdates = {}) => {
  return (previousState) => ({
    ...previousState,
    styleDataItem,
    forcedUpdates,
  });
};

const linkStyle = (Component) =>
  // eslint-disable-next-line react/prefer-es6-class
  createReactClass({
    displayName: recompose.wrapDisplayName(Component, 'LinkStyle'),
    propTypes: {
      selectedComponent: PropTypes.oneOfType([
        PropTypes.object.isRequired,
        PropTypes.array.isRequired,
      ]),
      getStyle: PropTypes.func.isRequired,
      getSkinDefinition: PropTypes.func.isRequired,
      updateStyle: PropTypes.func.isRequired,
      onStyleParamChanged: PropTypes.func,
      maintainOriginalState: PropTypes.func,
      isMultiComponentDesign: PropTypes.bool,
      isInsideEditorX: PropTypes.bool,
      updateOriginalStyleDefs: PropTypes.func,
      multiSelectedComponents: PropTypes.array,
      sendBI: PropTypes.func,
    },
    getInitialState() {
      this.cachedStylePropertiesForSkins = {};
      const styleDataItem = this.props.getStyle() || {};
      if (!styleDataItem.style) {
        styleDataItem.style = {};
      }
      if (!styleDataItem.style.propertiesOverride) {
        styleDataItem.style.propertiesOverride = {};
      }
      return {
        styleDataItem,
      };
    },
    componentDidMount() {
      this.mounted = true;
    },
    UNSAFE_componentWillReceiveProps(nextProps) {
      const nextStyle = nextProps.getStyle();
      if (!nextStyle) {
        return;
      }
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(this.state.forcedUpdates, (v, k) => {
        _.set(nextStyle, `style.properties.${k}`, v);
      });

      if (
        this.props.isMultiComponentDesign &&
        this.props.multiSelectedComponents.some((compRef) =>
          core.styleManager.isEqual(this.props.getStyle(compRef), nextStyle),
        )
      ) {
        if (!nextStyle.style.propertiesOverride) {
          nextStyle.style.propertiesOverride = {};
        }
        this.setState(updateStyleDataItem(nextStyle));
        return;
      }

      const currentStyle = this.props.getStyle();
      if (!currentStyle || core.styleManager.isEqual(currentStyle, nextStyle)) {
        if (!nextStyle.style.propertiesOverride) {
          nextStyle.style.propertiesOverride = {};
        }
        this.setState(updateStyleDataItem(nextStyle));
      }
    },
    componentWillUnmount() {
      this.mounted = false;
    },
    forceUpdateParentStyle(styleDataItem, styleParam, newVal) {
      if (this.props.isMultiComponentDesign) {
        this.props.onStyleParamChanged(
          styleParam,
          newVal,
          styleUtil.getPropertySource(newVal),
        );
      } else {
        this.props.updateStyle(styleDataItem);
      }

      const forcedUpdates = Array.isArray(styleParam)
        ? newVal
        : { [styleParam]: newVal };

      this.setState(updateStyleDataItem(styleDataItem, forcedUpdates));
    },
    sendBIDebounced: _.debounce((sendBi, ...args) => {
      sendBi(...args);
    }, 700),
    onStyleParamChanged(
      originalStyleDataItem,
      styleParam,
      newVal,
      paramDisplayMode,
      dontAddToUndoRedoStack,
      { isMouseOut, isHover },
      styleParamCategory,
    ) {
      const { isMultiComponentDesign, maintainOriginalState } = this.props;
      const styleDataItem = _.cloneDeep(originalStyleDataItem);
      const { style } = styleDataItem;
      const value = typeof newVal === 'object' ? newVal.value : newVal;
      const source =
        typeof newVal === 'object'
          ? newVal.source
          : styleUtil.getPropertySource(value);
      const hasStyleParamChanged =
        originalStyleDataItem.style.properties[styleParam] !== value;

      if (style.propertiesOverride) {
        if (
          typeof newVal !== 'object' &&
          source === 'theme' &&
          style.propertiesOverride[styleParam]
        ) {
          delete style.propertiesOverride[styleParam];
        } else if (typeof newVal === 'object' && newVal.overrideValues) {
          style.propertiesOverride[styleParam] = newVal.overrideValues;
        }
      }

      style.properties[styleParam] = value;
      if (style.propertiesSource) {
        style.propertiesSource[styleParam] = source;
      }
      if (isMouseOut && isMultiComponentDesign && maintainOriginalState) {
        this.setState(updateStyleDataItem(styleDataItem));
        return maintainOriginalState(dontAddToUndoRedoStack);
      }

      const hasStyleDataChanged = !_.isEqual(
        styleDataItem,
        originalStyleDataItem,
      );

      if (this.props.onStyleParamChanged && hasStyleParamChanged) {
        this.props.onStyleParamChanged(
          styleParam,
          value,
          source,
          this.state.forcedUpdates,
        );
      } else if (hasStyleDataChanged) {
        this.props.updateStyle(
          styleDataItem,
          dontAddToUndoRedoStack,
          undefined,
          undefined,
          undefined,
          undefined,
          isHover,
        );
      }

      if (this.mounted && hasStyleDataChanged) {
        this.setState(updateStyleDataItem(styleDataItem));
      }

      if (isHover === false && this.props.updateOriginalStyleDefs) {
        this.props.updateOriginalStyleDefs();
      }

      // we want to limit this BI event to WixStudio only
      if (this.props.isInsideEditorX) {
        this.sendBIDebounced(
          this.props.sendBI,
          coreBi.events.designPanel.BOX_DESIGN_SETTINGS,
          {
            field_name: styleParam,
            field_value: value,
            panel_name: styleParamCategory,
          },
        );
      }
    },
    getStyleDataItemForNewSkin(skinName, oldStyleDataItem) {
      const oldStyle = oldStyleDataItem.style;
      const getSkinDefinitionFunction = this.props.getSkinDefinition;

      const newStyle = styleUtil.getStyleForSkin(
        getSkinDefinitionFunction,
        skinName,
        this.cachedStylePropertiesForSkins[skinName],
      );
      styleUtil.mergeSharedProperties(
        getSkinDefinitionFunction,
        newStyle.properties,
        oldStyle.properties,
        skinName,
        oldStyleDataItem.skin,
      );
      const newStyleDataItem = _.cloneDeep(oldStyleDataItem);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(newStyleDataItem.style, newStyle);
      newStyleDataItem.skin = skinName;

      return newStyleDataItem;
    },

    linkStyleParam(
      styleParam,
      paramType,
      paramDisplayMode,
      styleParamCategory,
    ) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      if (_.includes(paramType, 'COLOR_ALPHA')) {
        return this.linkColorAlphaWithMutator(
          styleParam,
          paramDisplayMode,
          styleParamCategory,
        );
      }
      const { styleDataItem } = this.state;
      const linkParamValue = this.getParamValue(styleDataItem, styleParam);
      let overrideValues = {};

      const { propertiesOverride } = styleDataItem.style;
      if (propertiesOverride && propertiesOverride[styleParam]) {
        overrideValues = propertiesOverride[styleParam];
      }

      return {
        value: linkParamValue,
        overrideValues,
        requestChange: (newVal, { isHover, isMouseOut } = {}) => {
          this.onStyleParamChanged(
            this.state.styleDataItem,
            styleParam,
            newVal,
            paramDisplayMode || 'active',
            isDisabledUndoStackParamType(paramType),
            { isMouseOut, isHover },
            styleParamCategory,
          );
        },
      };
    },
    linkColorAlphaWithMutator(
      colorParam,
      paramDisplayMode,
      styleParamCategory,
    ) {
      const alphaParam = `alpha-${colorParam}`;
      const { styleDataItem } = this.state;
      const alphaVal = this.getParamValue(styleDataItem, alphaParam);
      const colorVal = this.getParamValue(styleDataItem, colorParam);

      return {
        value: {
          color: colorVal,
          alpha: alphaVal,
        },
        requestChange: (colorWithAlpha, { isHover, isMouseOut } = {}) => {
          const { alpha, color } = colorWithAlpha;
          let hasChanges = false;
          if (
            this.getParamValue(this.state.styleDataItem, alphaParam) !== alpha
          ) {
            const { style } = styleDataItem;
            this.onStyleParamChanged(
              this.state.styleDataItem,
              alphaParam,
              alpha,
              paramDisplayMode,
              isDisabledUndoStackParamType(alphaParam),
              { isMouseOut, isHover },
              styleParamCategory,
            );
            style.properties[alphaParam] = alpha;
            hasChanges = true;
          }

          if (
            this.getParamValue(this.state.styleDataItem, colorParam) !== color
          ) {
            this.onStyleParamChanged(
              this.state.styleDataItem,
              colorParam,
              color,
              paramDisplayMode,
              isDisabledUndoStackParamType(colorParam),
              { isMouseOut, isHover },
              styleParamCategory,
            );
            hasChanges = true;
          }

          if (!hasChanges && !isHover && this.props.updateOriginalStyleDefs) {
            this.props.updateOriginalStyleDefs();
          }
        },
      };
    },
    cacheStylePropertiesForSkin(skinName, styleProperties) {
      this.cachedStylePropertiesForSkins[skinName] = styleProperties;
    },
    changeSkin(skinName) {
      const oldStyleDataItem = this.state.styleDataItem;

      if (skinName === oldStyleDataItem.skin) {
        return;
      }

      const styleDataItem = this.getStyleDataItemForNewSkin(
        skinName,
        oldStyleDataItem,
      );

      this.cacheStylePropertiesForSkin(
        oldStyleDataItem.skin,
        oldStyleDataItem.style.properties,
      );
      this.props.updateStyle(styleDataItem);
      this.setState({ styleDataItem });
    },
    getParamValue(styleDataItem, paramKey) {
      const getSkinDefinitionFunction = this.props.getSkinDefinition;
      const paramValue = styleDataItem.style.properties[paramKey];
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
      return _.isUndefined(paramValue)
        ? advancedStyleDataUtil.getSkinParamDefaultValue(
            getSkinDefinitionFunction,
            styleDataItem.skin,
            paramKey,
          )
        : paramValue;
    },
    render() {
      const props = _.defaults(
        {
          linkStyleParam: this.linkStyleParam,
          changeSkin: this.changeSkin,
          forceUpdateParentStyle: this.forceUpdateParentStyle,
          styleDataItem: this.state.styleDataItem,
        },
        this.props,
      );

      return React.createElement(Component, props);
    },
  });

const getSkinDefinition =
  (skinName) =>
  (dispatch, getState, { dsRead }) =>
    dsRead.theme.skins.getSkinDefinition(skinName);
const updateStyle =
  (selectedComponent, styleDef) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.components.style.update(selectedComponent, styleDef);
const getStyle =
  (selectedComponent) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.components.style.get(selectedComponent);

const mapStateToProps = ({ editorAPI }) => {
  const editorParamsApi = editorAPI.host.getAPI(EditorParamsApiKey);
  const shouldResetToggledOffShadow = !isInInteractionMode(
    editorAPI.store.getState(),
  );
  const isInsideEditorX = editorParamsApi.isInsideEditorX;

  return {
    shouldResetToggledOffShadow,
    isInsideEditorX,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getSkinDefinition: (skinName) => dispatch(getSkinDefinition(skinName)),
  sendBI: (event, params) =>
    dispatch(
      stateManagement.bi.actions.event(
        event,
        Object.assign(
          {
            component_type: ownProps.compType,
            component_id: ownProps.selectedComponents?.[0]?.id,
          },
          params,
        ),
      ),
    ),
  updateStyle:
    ownProps.updateStyle ||
    ((styleDef) => dispatch(updateStyle(ownProps.selectedComponent, styleDef))),
  getStyle:
    ownProps.getStyle || (() => dispatch(getStyle(ownProps.selectedComponent))),
});

export default _.flow(
  linkStyle,
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
);
