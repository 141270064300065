import { type AnySection, SectionType } from '@wix/add-panel-component';
import { styleManager } from '@/core';
import type { EditorAPI } from '@/editorAPI';
import { PANEL_TYPES } from '@/addPanelDataConsts';
import addPanelStyleManager from '../../addPanel/addPanelStyleManager';
import componentsDefaultData from '../../addPanel/componentsDefaultData';
import type { StyleForSection } from './types';

const DISPLAYED_STYLES_LIMIT = 24;

export const getStylesForPersonalizedSection = ({
  panelLayoutType = PANEL_TYPES.ADD_PANEL,
  compTypes,
  editorAPITheme,
  editorAPIComponents,
}: AnyFixMe): StyleForSection[] => {
  const stylesForSection: AnyFixMe = [];

  compTypes.forEach(function (compType: AnyFixMe) {
    const compDef = componentsDefaultData.getComponentDefault(
      compType,
      panelLayoutType,
    );

    if (!compDef) {
      return;
    }

    const componentType =
      compDef.componentTypeSkinOverride || compDef.componentType || compType;

    const styles = styleManager.getUniqueCustomStyleList(
      editorAPITheme,
      editorAPIComponents,
      componentType,
    );

    if (!styles || styles.length === 0) {
      return;
    }

    styles.slice(0, DISPLAYED_STYLES_LIMIT).forEach(function (style) {
      stylesForSection.push({
        styleId: style.id,
        styleDef: style,
        compType,
      });
    });
  });

  return stylesForSection;
};

export const getStylesForThemedSection = ({
  panelLayoutType = PANEL_TYPES.ADD_PANEL,
  compTypes,
  editorAPITheme,
  editorAPIComponents,
}: AnyFixMe): StyleForSection[] => {
  const stylesForSection: AnyFixMe = [];

  compTypes.forEach(function (compType: AnyFixMe) {
    const compDef = componentsDefaultData.getComponentDefault(
      compType,
      panelLayoutType,
    );
    if (!compDef) {
      return;
    }

    const componentType =
      compDef.componentTypeSkinOverride || compDef.componentType || compType;

    const styles = addPanelStyleManager.getComponentThemeStyles(
      componentType,
      editorAPITheme,
      editorAPIComponents,
    );

    if (!styles || styles.length === 0) {
      return;
    }

    styles.forEach(function (style) {
      stylesForSection.push({ styleId: style.id, styleDef: style, compType });
    });
  });

  return stylesForSection;
};

const getStylesForSection = ({
  section,
  editorAPITheme,
  editorAPIComponents,
}: AnyFixMe): StyleForSection[] => {
  const { props } = section;
  const { compTypes } = props;

  return section.type === SectionType.Theme
    ? getStylesForThemedSection({
        compTypes,
        editorAPITheme,
        editorAPIComponents,
      })
    : getStylesForPersonalizedSection({
        compTypes,
        editorAPITheme,
        editorAPIComponents,
      });
};

export const hasStylesForSection = (
  editorAPI: EditorAPI,
  section: AnySection,
) => {
  const stylesForSection = getStylesForSection({
    section,
    editorAPITheme: editorAPI.theme,
    editorAPIComponents: editorAPI.components,
  });
  return stylesForSection.length > 0;
};
