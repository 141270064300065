// @ts-nocheck
import * as addPanel from '@/oldAddPanel';
import section1 from './live/section1';
import section2 from './live/section2';
import interactionsHoverSection from './interactionsHoverSection/interactionsHoverSection';

export default {
  getCategorySections() {
    const sections = addPanel.sections.getSections();
    const res = addPanel.dataUtil.mergeWithCommonSections(sections.box, {
      section_1: section1,
      section_2: section2,
      interactionsHoverSection,
    });
    res.hoverBoxSection = sections.hoverBox.hoverBoxSection;
    return res;
  },
};
