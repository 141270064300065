import React from 'react';
import { CenterDot } from './centerDot';
import { DragHandle } from './dragHandle';
import { TriggerLabel } from './triggerLabel';
import { SelectedNonTriggerLayout } from './selectedNonTriggerLayout';
import type {
  CompRefNode,
  LayoutAndTranformationsMap,
} from '../interactionsEditBoxMapper';
import {
  getTransformString,
  getTransformOriginString,
  getNegativeScaleAndSkewString,
} from '../interactionsEditBoxMapper';
import { isEditBoxRefs } from '../interactionsEditBox';

const triggerBorderWidth = 3;

interface TriggerLayoutProps {
  dragging: boolean;
  triggerTree: CompRefNode;
  layoutAndTranformationsMap: LayoutAndTranformationsMap;
  selectedOffsetX: number;
  selectedOffsetY: number;
  rotateDisabled: boolean;
  skewDisabled: boolean;
  navOffsetLeft?: number | string;
  selectTrigger: () => void;
  renderRegularEditBox: () => React.Component;
  addHoverClass: (element: HTMLElement) => void;
  registerDragMouseMoveAction: (
    event: React.MouseEvent,
    isDraggingWithHandle?: boolean,
  ) => void;
  exitMode: () => void;
}

// eslint-disable-next-line react/display-name
export const TriggerLayout = React.forwardRef<
  HTMLDivElement,
  TriggerLayoutProps
>(
  (
    {
      dragging,
      triggerTree,
      layoutAndTranformationsMap,
      navOffsetLeft,
      selectedOffsetX,
      selectedOffsetY,
      exitMode,
      rotateDisabled,
      skewDisabled,
      selectTrigger,
      renderRegularEditBox,
      addHoverClass,
      registerDragMouseMoveAction,
    },
    ref,
  ) => {
    if (isEditBoxRefs(ref)) {
      const triggerData = layoutAndTranformationsMap[triggerTree.compRef.id];
      const {
        calculatedLayout,
        transformationsObject,
        compTransformations,
        canCompBeDragged,
      } = triggerData;
      const triggerLayoutStyle: React.CSSProperties = {
        top: calculatedLayout.y - triggerBorderWidth,
        left: calculatedLayout.x - triggerBorderWidth,
        width: calculatedLayout.width,
        height: calculatedLayout.height,
        transform: triggerData.isSelected
          ? getTransformString(
              transformationsObject,
              calculatedLayout.rotationInDegrees,
              rotateDisabled,
              skewDisabled,
              selectedOffsetX,
              selectedOffsetY,
            )
          : getTransformString(
              transformationsObject,
              calculatedLayout.rotationInDegrees,
              rotateDisabled,
              skewDisabled,
            ),
        transformOrigin: getTransformOriginString(compTransformations),
      };
      const { layoutRef } = ref;

      const negativeScaleAndSkewString = getNegativeScaleAndSkewString(
        transformationsObject,
        skewDisabled,
      );
      // @ts-expect-error
      const { scale } = transformationsObject || {};

      return (
        <div
          ref={triggerData.isSelected ? layoutRef : null}
          className="trigger-layout"
          style={triggerLayoutStyle}
        >
          {triggerTree.children.map((child) => {
            const nextChildData = layoutAndTranformationsMap[child.compRef.id];
            if (
              nextChildData.isShownOnlyInVariant &&
              nextChildData.isSelected
            ) {
              return renderRegularEditBox();
            }
            return (
              <SelectedNonTriggerLayout
                key={child.compRef.id}
                ref={ref}
                childTree={child}
                layoutAndTranformationsMap={layoutAndTranformationsMap}
                selectedOffsetX={selectedOffsetX}
                selectedOffsetY={selectedOffsetY}
                renderRegularEditBox={renderRegularEditBox}
                registerDragMouseMoveAction={registerDragMouseMoveAction}
                rotateDisabled={rotateDisabled}
                skewDisabled={skewDisabled}
                parentsNegativeScaleAndSkewString={[negativeScaleAndSkewString]}
                addHoverClass={addHoverClass}
              />
            );
          })}

          {triggerData.isSelected && (
            <>
              <CenterDot
                negativeScaleAndSkewString={negativeScaleAndSkewString}
              />

              {canCompBeDragged && (
                <DragHandle
                  negativeScaleAndSkewString={negativeScaleAndSkewString}
                  registerDragMouseMoveAction={registerDragMouseMoveAction}
                />
              )}
            </>
          )}

          <TriggerLabel
            selectTrigger={selectTrigger}
            scaleY={scale?.y || 1}
            navOffsetLeft={navOffsetLeft}
            dragging={dragging}
            exitMode={exitMode}
            handleMouseDown={registerDragMouseMoveAction}
            negativeScaleAndSkewString={negativeScaleAndSkewString}
          />
        </div>
      );
    }
  },
);
