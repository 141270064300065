import React, { type ReactElement, Component, type ComponentType } from 'react';
import _ from 'lodash';
import type { SendBiFunction } from 'types/bi';
import * as coreBi from '@/coreBi';

const TIMEOUT = 2000; // 2s

interface WithTestSiteDropPanelBiBehaviorProps {
  dropPanelContent?: ReactElement | null;
  sendBi: SendBiFunction;
}

interface ComponentWithTestSiteDropPanelBiBehaviorProps {
  dropPanelContent?: ReactElement | null;
}

const withTestSiteDropPanelBiBehavior = <
  P extends WithTestSiteDropPanelBiBehaviorProps,
>(
  WrappedComponent: ComponentType<
    P & ComponentWithTestSiteDropPanelBiBehaviorProps
  >,
) => {
  class WithTestSiteDropPanelBiBehavior extends Component<WithTestSiteDropPanelBiBehaviorProps> {
    state = {
      hovered: false,
    };

    handlePanelPresentEnoughLong = () => {
      this.props.sendBi(coreBi.events.topbar.top_bar_tooltip_open, {
        item: 'Test Site',
      });
      this.props.sendBi(coreBi.events.topbar.TOP_BAR_PANEL_OPENED, {
        menu_name: 'TEST_SITE',
      });
    };

    handleMouseEnter = () => this.setState({ hovered: true });

    handleMouseLeave = () => this.setState({ hovered: false });

    getDropPanelContent() {
      const { dropPanelContent } = this.props;
      const { hovered } = this.state;

      return hovered && dropPanelContent ? (
        <MountHandle wait={TIMEOUT} onMount={this.handlePanelPresentEnoughLong}>
          {dropPanelContent}
        </MountHandle>
      ) : (
        dropPanelContent
      );
    }

    render() {
      const { props } = this;
      const dropPanelContent = this.getDropPanelContent();
      const wrappedComponent = React.createElement(
        WrappedComponent,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign({}, props as P, {
          dropPanelContent,
        }),
      );

      return (
        <span
          id="top-bar-test-site-hover-bi-handler"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {wrappedComponent}
        </span>
      );
    }
  }

  return WithTestSiteDropPanelBiBehavior;
};

export default withTestSiteDropPanelBiBehavior;

interface MountHandleProps {
  wait: number;
  onMount: () => void;
}

class MountHandle extends Component<MountHandleProps> {
  componentDidMount() {
    const { onMount, wait } = this.props;

    this.timeout = setTimeout(() => onMount(), wait);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  private timeout: NodeJS.Timeout;

  render() {
    return this.props.children;
  }
}
