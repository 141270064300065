:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._platform-installer-apps-panel_7rb5c_7 .control-base-modal {
  max-height: 642px;
  max-width: 1038px; }

._platform-installer-apps-panel_7rb5c_7 ._galleryWrapper_7rb5c_11 {
  display: flex;
  grid-gap: 24px 54px;
  flex-wrap: wrap;
  padding: 24px 30px 24px 30px; }
  ._platform-installer-apps-panel_7rb5c_7 ._galleryWrapper_7rb5c_11 ._img_7rb5c_16 {
    max-width: 268px;
    max-height: 650px;
    border-radius: 4px; }
