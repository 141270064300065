// @ts-nocheck
import _ from 'lodash';

const blockElementTags = [
  'audio',
  'dd',
  'dt',
  'li',
  'video',
  'address',
  'article',
  'aside',
  'blockquote',
  'details',
  'div',
  'dl',
  'fieldset',
  'figure',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'header',
  'hgroup',
  'hr',
  'menu',
  'nav',
  'ol',
  'p',
  'pre',
  'section',
  'table',
  'ul',
  'center',
  'dir',
  'noframes',
];

function mootoolsTypeOf(item) {
  if (item === null) {
    return 'null';
  }
  if (item.$family) {
    return item.$family();
  }

  if (item.nodeName) {
    if (item.nodeType === 1) {
      return 'element';
    }
    if (item.nodeType === 3) {
      return /\S/.test(item.nodeValue) ? 'textnode' : 'whitespace';
    }
  } else if (typeof item.length === 'number') {
    if (item.callee) {
      return 'arguments';
    }
    if ('item' in item) {
      return 'collection';
    }
  }

  return typeof item;
}

/**
 *  IE8 - mootools bug, when a mootools element there are round 130 attributes, and the copy method makes functions to be string - no good..
 * this is a very partial list of attributes, should suffice for the current use of the function
 * @param element
 */
function getAllAttributes(element) {
  const attributeCollection = element.attributes;
  let attributeArray = [];
  if (attributeCollection.length < 20) {
    attributeArray = collectionToArray(attributeCollection);
  } else {
    const attrMap = element.getProperties(
      'class',
      'style',
      'dir',
      'href',
      'background',
      'action',
      'src',
      'data',
      'align',
      'alt',
      'color',
      'face',
      'label',
      'for',
      'link',
      'id',
      'name',
      'value',
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(attrMap, function (value, key) {
      if (key && value) {
        attributeArray.push({
          name: key,
          value,
        });
      }
    });
  }
  return attributeArray;
}

function moveChildren(from, target) {
  if (from === target) {
    return;
  }

  let child;
  while ((child = from.firstChild)) {
    //eslint-disable-line no-cond-assign
    target.appendChild(from.removeChild(child));
  }
}

function collectionToArray(col) {
  //copied from http://stackoverflow.com/questions/3199588/fastest-way-to-convert-javascript-nodelist-to-array
  function convertNodeListToArray(nl) {
    const arr = [];
    for (let i = nl.length; i--; ) {
      arr.unshift(nl[i]);
    }

    return arr;
  }

  return convertNodeListToArray(col);
}

function renameNode(node, newTag) {
  // If it's already correct exit here.
  if (node.tagName.toLowerCase() === newTag.toLowerCase()) {
    return node;
  }

  // Create the new node.
  const newNode = window.document.createElement(newTag);

  // Copy all attributes.
  const attributeArray = getAllAttributes(node);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(attributeArray, function (attr) {
    newNode.setAttribute(attr.name, attr.value);
  });

  // Move children to the new node.
  moveChildren(node, newNode);

  // Replace the node.
  if (node.parentNode) {
    node.parentNode.replaceChild(newNode, node);
  }
  return newNode;
}

function isBlockElement(element) {
  const elementTag = element.tagName && element.tagName.toLowerCase();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  return _.includes(blockElementTags, elementTag);
}

function isOnlyWhiteSpace(elements) {
  return elements.every((el) => mootoolsTypeOf(el) === 'whitespace');
}

function isDomElement(element) {
  return mootoolsTypeOf(element) === 'element';
}

/**
 *
 * @param element
 * @return {Boolean} true only if it's a real text node, not only whitespace
 * @private
 */
function isTextNode(element) {
  return mootoolsTypeOf(element) === 'textnode';
}

function hasBlockElementChildren(element) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(element.children, isBlockElement);
}

function replaceElementWithItsChildren(element) {
  const parent = element.parentNode;
  const children = collectionToArray(element.childNodes);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(children, function (childToAppend) {
    parent.insertBefore(childToAppend, element);
  });
  parent.removeChild(element);
}

function isElementHasAttributes(element) {
  const attributes = getAllAttributes(element);
  if (!attributes) {
    return false;
  }
  for (let i = 0; i < attributes.length; i++) {
    const attrVal = attributes[i].value;
    //if attribute has value
    if (attrVal) {
      return true;
    }
  }
  return false;
}

function removeClass(element, cssClass) {
  element.classList.remove(cssClass);
  if (element.getAttribute('class').length === 0) {
    element.removeAttribute('class');
  }
}

/**
 * @param node - the node to update its style
 * @param styleProperties - object of key-value pairs of properties to update in the style
 */
function setStyle(node, styleProperties) {
  Object.assign(node.style, styleProperties);
}

export {
  moveChildren,
  collectionToArray,
  isBlockElement,
  hasBlockElementChildren,
  isDomElement,
  isTextNode,
  isElementHasAttributes,
  replaceElementWithItsChildren,
  renameNode,
  isOnlyWhiteSpace,
  removeClass,
  setStyle,
};
