// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import * as platformEvents from 'platformEvents';
import {
  Button,
  SortByDragList,
  ContextMenu,
  ContextMenuContent,
  ContextMenuAction,
  TextLabel,
} from '@wix/wix-base-ui';

import * as util from '@/util';
import { waitForAddedCompRef } from '@/componentsAddUtils';
import * as utils from '@wix/santa-editor-utils';
import * as compPanelInfra from '@/compPanelInfra';
import * as boxSlideShow from '@/boxSlideshow';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import * as BaseUI from '@/baseUI';
import * as CompPanelInfra from '@/compPanelInfra';
import * as helpIds from '@/helpIds';
import { translate } from '@/i18n';
import * as initialStateStructureUtil from './initialStateStructureUtil';
import type { EditorAPI } from '@/editorAPI';

const ID_PREFIX = 'ID: ';
const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;
const { getAllSlides, findCurrentSlide } =
  stateManagement.boxSlideShow.selectors;
const { getStateTitle, hasTitle, isRepeaterStateAllowed } =
  stateManagement.stateBox.selectors;
const slideShowUtils = boxSlideShow.utils.boxSlideShowUtils;
const { multiStateBoxUtils } = utils;

function getStateItem(
  utils,
  stateBoxPointer,
  statePointer,
  selectedState,
  dsRead,
) {
  const stateHasTitle = hasTitle(dsRead, statePointer);
  const stateLabel = utils.getStateTitle(statePointer);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  const stateData = _.assign(
    {
      id: statePointer.id,
      label: stateHasTitle ? stateLabel : `${ID_PREFIX}${stateLabel}`,
      cleanLabel: stateLabel,
      pointer: statePointer,
    },
    stateHasTitle && { value: utils.getNickname(statePointer) },
  );

  if (stateData.id === selectedState.id) {
    stateData.selected = true;
  }

  return stateData;
}

function getStates(utils, allStatesPointers, stateBox, selectedState, dsRead) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(allStatesPointers, function (statePointer) {
    return getStateItem(utils, stateBox, statePointer, selectedState, dsRead);
  });
}

// eslint-disable-next-line react/prefer-es6-class
const BoxStateBoxManageStatePanel = createReactClass({
  displayName: 'StateBoxManageStatesPanel',
  mixins: [compPanelInfra.compPanelMixin],
  propTypes: {
    stateBox: PropTypes.object,
    allStatesPointers: PropTypes.array,
    biParams: PropTypes.object,
    sendBi: PropTypes.func,
    preventMouseDown: PropTypes.func,
    allowMouseDown: PropTypes.func,
    hideQuickTip: PropTypes.func,
    utils: PropTypes.object,
  },
  getInitialState() {
    const currentState = findCurrentSlide(
      this.getEditorAPI().dsRead,
      this.props.stateBox,
    );
    this.isCurrentlyInOperation = false;

    return {
      selectedState: currentState,
      editIndex: -1,
    };
  },
  UNSAFE_componentWillMount() {
    this.states = this.getStates();
    this.operations = [];
  },
  componentWillUpdate(nextProps, nextState) {
    this.states = getStates(
      this.props.utils,
      this.props.allStatesPointers,
      this.props.stateBox,
      nextState.selectedState,
      this.getEditorAPI().dsRead,
    );
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.states = getStates(
      this.props.utils,
      this.props.allStatesPointers,
      nextProps.stateBox,
      this.state.selectedState,
      this.getEditorAPI().dsRead,
    );
    const nextSelectedState = findCurrentSlide(
      this.getEditorAPI().dsRead,
      nextProps.stateBox,
    );

    if (!_.isEqual(nextSelectedState, this.state.selectedState)) {
      //current state was changed from outside
      this.setState({ selectedState: nextSelectedState });
    }
  },
  getStates() {
    return getStates(
      this.props.utils,
      this.props.allStatesPointers,
      this.props.stateBox,
      this.state.selectedState,
      this.getEditorAPI().dsRead,
    );
  },
  getSelectedStateItem() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return _.find(this.states, { id: this.state.selectedState.id });
  },
  startOperation() {
    this.getEditorAPI().preventMouseDown();
    let endOperation;
    const operation = new Promise((resolve) => {
      endOperation = resolve;
    });
    this.operations.push(operation);
    return endOperation;
  },
  endOperation(endOperation) {
    this.getEditorAPI().allowMouseDown();
    endOperation();
  },
  async waitForOperations() {
    await Promise.all(this.operations);
    this.operations = [];
  },
  isInOperation() {
    return this.isCurrentlyInOperation;
  },
  async selectState(stateValue, stateIndex) {
    await this.waitForOperations();

    const editorAPI = this.getEditorAPI();
    const endOperation = this.startOperation();

    await new Promise((resolve) => {
      this.setState({ selectedState: stateValue.pointer }, resolve);
    });

    await new Promise((resolve) => {
      editorAPI.components.behaviors.execute(
        this.props.stateBox,
        'changeState',
        { stateIndex },
        resolve,
      );
    });

    this.endOperation(endOperation);

    this.props.hideQuickTip();
  },
  async moveState(startIdx, dropIdx) {
    if (startIdx === dropIdx) {
      return;
    }

    await this.waitForOperations();

    const editorAPI = this.getEditorAPI();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const selectedIndex = _.findIndex(this.getStates(), { selected: true });
    const stateToMove = this.states[startIdx].pointer;
    editorAPI.components.arrangement.moveToIndex(stateToMove, dropIdx, {
      dontAddToUndoRedoStack: false,
    });
    this.props.sendBi(
      coreBi.events.boxSlideShow.MANAGE_SLIDES_SLIDE_REORDERED,
      this.props.biParams,
    );

    editorAPI.dsActions.waitForChangesApplied(() => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
      const newSelectedIndex = _.findIndex(this.getStates(), {
        selected: true,
      });
      if (selectedIndex === newSelectedIndex) {
        return;
      }

      const endOperation = this.startOperation();
      editorAPI.components.behaviors.execute(
        this.props.stateBox,
        'changeState',
        { stateIndex: dropIdx },
        () => this.endOperation(endOperation),
      );
    });
  },
  notifyApplicationIfNeeded(editorAPI, newStateRef, isDuplicatedState) {
    const stateBoxRef = this.props.stateBox;
    const primaryConnection =
      editorAPI.dsRead.platform.controllers.connections.getPrimaryConnection(
        stateBoxRef,
      );
    if (!primaryConnection) {
      return;
    }

    const { controllerRef } = primaryConnection;
    const { applicationId: appDefinitionId } =
      editorAPI.components.data.get(controllerRef) || {};
    const { applicationId } =
      editorAPI.platform.getAppDataByAppDefId(appDefinitionId);

    if (isDuplicatedState) {
      editorAPI.platform.notifyApplication(
        applicationId,
        platformEvents.factory.stateDuplicated({
          componentRef: stateBoxRef,
          newDuplicatedStateRef: newStateRef,
        }),
      );
    } else {
      editorAPI.platform.notifyApplication(
        applicationId,
        platformEvents.factory.stateAdded({
          componentRef: stateBoxRef,
          addedStateRef: newStateRef,
        }),
      );
    }
  },
  async addState(
    stateDef,
    index,
    isDuplicatedState = false,
    stateNickname = '',
  ) {
    const editorAPI: EditorAPI = this.getEditorAPI();

    const addedState = await waitForAddedCompRef(
      editorAPI.components.add(this.props.stateBox, stateDef),
    );

    editorAPI.components.arrangement.moveToIndex(addedState, index, {
      dontAddToUndoRedoStack: true,
    });
    this.getEditorAPI().history.add('added a state to state show', {
      isAddingComponent: true,
    });

    await editorAPI.dsActions.waitForChangesAppliedAsync();

    this.notifyApplicationIfNeeded(editorAPI, addedState, isDuplicatedState);

    if (stateNickname) {
      editorAPI.components.code.setNickname(addedState, stateNickname);
    }

    const addedStateData = getStateItem(
      this.props.utils,
      this.props.stateBox,
      addedState,
      this.state.selectedState,
      this.getEditorAPI().dsRead,
    );

    this.selectState(addedStateData, index);
  },
  async duplicateState(state, stateIndex) {
    const stateNickname = this.calculateDuplicatedStepNickname(state.pointer);
    const stateDef = this.getEditorAPI().components.serialize(state.pointer);
    await this.addState(stateDef, stateIndex + 1, true, stateNickname);
  },
  async duplicateCurrentState() {
    const currState = this.getSelectedStateItem();
    const totalStates = this.states.length;
    await this.duplicateState(currState, totalStates - 1);
    this.props.sendBi(
      coreBi.events.boxSlideShow.MANAGE_SLIDES_DUPLICATE_CURRENT_SLIDE,
      this.props.biParams,
    );
  },
  calculateDuplicatedStepNickname(duplicatedStatePointer) {
    return multiStateBoxUtils.getDuplicatedStateNickname(
      this.getEditorAPI(),
      duplicatedStatePointer,
      this.states,
    );
  },
  async deleteState(state, stateIndex, allStates) {
    if (allStates.length <= 1) {
      return;
    }

    const editorAPI = this.getEditorAPI();
    const [firstState] = allStates;

    await this.selectState(firstState, 0);

    await editorAPI.components.remove(state.pointer);

    editorAPI.history.add('removed a state from state show');

    const selectedState = findCurrentSlide(
      editorAPI.dsRead,
      this.props.stateBox,
    );

    this.setState({ selectedState });
  },
  changeStateName(state) {
    const newTitle = state.value || state.cleanLabel;
    const editorAPI = this.getEditorAPI();
    editorAPI.components.code.setNickname(state.pointer, newTitle);
    editorAPI.dsActions.waitForChangesApplied(() => {
      editorAPI.selection.selectComponentByCompRef(this.props.stateBox);
    });
  },
  isRepeaterState(compRef) {
    const compType = this.props.getType(compRef);
    return compType === 'wysiwyg.viewer.components.Repeater';
  },
  getNewContainerStateStructure() {
    const editorAPI = this.getEditorAPI();
    const currState = this.getSelectedStateItem();
    if (this.isRepeaterState(currState.pointer)) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      const containerState = _.find(
        this.states,
        (state) => !this.isRepeaterState(state.pointer),
      );
      if (containerState) {
        // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
        return editorAPI.components.serialize(containerState.pointer);
      }
      return initialStateStructureUtil.CONTAINER_STATE;
    }
    // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
    return editorAPI.components.serialize(currState.pointer);
  },
  async addNewContainerState() {
    const stateDef = this.getNewContainerStateStructure();
    stateDef.components = [];
    const totalStates = this.states.length;
    await this.addState(stateDef, totalStates);
    this.props.sendBi(
      coreBi.events.boxSlideShow.MANAGE_SLIDES_ADD_NEW_SLIDE,
      this.props.biParams,
    );
  },
  async addNewRepeaterState() {
    const repeaterStructure = initialStateStructureUtil.REPEATER_STATE;
    const totalStates = this.states.length;
    await this.addState(repeaterStructure, totalStates);
    this.props.sendBi(
      coreBi.events.boxSlideShow.MANAGE_SLIDES_ADD_NEW_SLIDE,
      this.props.biParams,
    );
  },
  getEditIdValidators() {
    return [
      {
        validator: util.validate.notEmptyString,
        invalidMessage: 'multiStateBox_ManageStates_State_Invalid_Name',
      },
      {
        validator: this.isEditingValueUnique,
        invalidMessage: 'multiStateBox_ManageStates_State_Invalid_Name_Unique',
      },
      {
        validator: util.validate.eventName,
        invalidMessage:
          'multiStateBox_ManageStates_State_Invalid_Name_Characters',
      },
    ];
  },
  valueEditStart(index) {
    this.setState({ editIndex: index });
  },
  valueEditEnd() {
    this.setState({ editIndex: -1 });
  },
  isEditingValueUnique(value) {
    return (
      this.state.editIndex === -1 ||
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      !_.includes(this.getEditExistingValues(), value)
    );
  },
  getEditExistingValues() {
    const existingValues = this.states.map(
      (state) => state.value || state.cleanLabel,
    );
    existingValues.splice(this.state.editIndex, 1);
    return existingValues;
  },
  getMenuActionsOverrides() {
    return {
      toggleDefault: {
        enable: false,
      },
      toggleEditValue: {
        priority: 3,
        label: translate('multiStateBox_ManageStates_State_Rename'),
        enable: true,
      },
      toggleEditLabel: {
        enable: false,
      },
      duplicate: {
        priority: 2,
        label: translate('multiStateBox_ManageStates_State_Duplicate'),
      },
      delete: {
        priority: 1,
        label: translate('multiStateBox_ManageStates_State_Delete'),
        enable: this.states.length > 1,
      },
    };
  },
  getAddNewStateButton() {
    return React.createElement(
      Button,
      {
        label: 'multiStateBox_ManageStates_State_AddState_Link',
        className: 'add-new-state',
        automationId: 'add-state-btn',
      },
      [translate('multiStateBox_ManageStates_State_AddState_Link')],
    );
  },
  getNewStatesItems() {
    return [
      {
        id: 'add-container-state',
        icon: 'container',
        label: 'multiStateBox_ManageStates_Add_State_Container',
        onClick: this.addNewContainerState,
      },
      {
        id: 'add-repeater-state',
        icon: 'repeater',
        label: 'multiStateBox_ManageStates_Add_State_Repeater',
        onClick: this.addNewRepeaterState,
      },
    ];
  },
  render() {
    return (
      <CompPanelInfra.compPanelFrame
        contentClass="box-slide-show-manage-slides-panel"
        title="multiStateBox_ManageStates_Header_Label"
        helpId={helpIds.EXTERNAL.STATE_BOX_MANAGE_STATES}
        {...this.getFrameProps()}
      >
        <SortByDragList
          selectable={true}
          value={this.getStates()}
          selectItem={this.selectState}
          menuActionsOverrides={this.getMenuActionsOverrides()}
          enableSetAsDefault={false}
          labelValidator={this.getEditIdValidators()}
          valueValidator={this.getEditIdValidators()}
          itemMoved={this.moveState}
          duplicateItem={this.duplicateState}
          deleteItem={this.deleteState}
          itemChanged={this.changeStateName}
          itemValuePrefix="ID"
          onValueEditStart={this.valueEditStart}
          onLabelEditStart={this.valueEditStart}
          onValueEditEnd={this.valueEditEnd}
          onLabelEditEnd={this.valueEditEnd}
          labelPlaceholder="multiStateBox_ManageStates_State_Invalid_Name_Placeholder"
          valuePlaceholder="multiStateBox_ManageStates_State_Invalid_Name_Placeholder"
          submitEditLabelButtonText={translate(
            'multiStateBox_ManageStates_State_Rename_Done',
          )}
          submitEditValueButtonText={translate(
            'multiStateBox_ManageStates_State_Rename_Done',
          )}
        />
        {this.props.isRepeaterStateAllowed ? (
          <div className="button-wrapper">
            <ContextMenu
              direction="MIDDLE"
              customButton={this.getAddNewStateButton()}
              automationId="add-state-menu"
            >
              <ContextMenuContent>
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                {_.map(this.getNewStatesItems(), (item) => (
                  <ContextMenuAction
                    automationId={item.id}
                    key={item.id}
                    className="add-state-action"
                    onClick={item.onClick}
                  >
                    <BaseUI.symbol name={item.icon} />
                    <TextLabel value={item.label} />
                  </ContextMenuAction>
                ))}
              </ContextMenuContent>
            </ContextMenu>
            <Button
              automationId="duplicate-state-btn"
              onClick={this.duplicateCurrentState}
              className="duplicate-state btn-text"
            >
              {translate('multiStateBox_ManageStates_State_Duplicate_Button')}
            </Button>
          </div>
        ) : (
          <div className="button-wrapper">
            <BaseUI.button
              label="multiStateBox_ManageStates_State_Duplicate_Button"
              onClick={this.duplicateCurrentState}
              className="mainButton"
            />
            <BaseUI.button
              label="multiStateBox_ManageStates_State_AddState_Link"
              onClick={this.addNewContainerState}
              className="secondaryButton btn-text"
            />
          </div>
        )}
      </CompPanelInfra.compPanelFrame>
    );
  },
});

const mapStateToProps = ({ editorAPI, state, dsRead }) => {
  const [stateBox] = editorAPI.selection.getSelectedComponents();
  const allStatesPointers = getAllSlides(dsRead, stateBox);
  return {
    stateBox,
    allStatesPointers,
    biParams: editorAPI.components.getDefaultBiParams(stateBox),
    preventMouseDown: () => editorAPI.preventMouseDown(),
    allowMouseDown: () => editorAPI.allowMouseDown(),
    hideQuickTip: () => slideShowUtils.hideQuickTip(editorAPI),
    isRepeaterStateAllowed: isRepeaterStateAllowed(state),
    getType: (compRef) => editorAPI.components.getType(compRef),
    utils: {
      getStateTitle: (statePointer) =>
        getStateTitle(dsRead, stateBox, statePointer),
      getNickname: (statePointer) =>
        stateManagement.components.selectors.getNickname(statePointer, dsRead),
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendBi: (event, parameters) =>
    dispatch(stateManagement.bi.actions.event(event, parameters)),
});

export default connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(BoxStateBoxManageStatePanel);
