import { ErrorReporter } from '@wix/editor-error-reporter';
import { createDefaultDesignWithOverrides } from './utils';
import type { CompRef } from '@wix/document-services-types';
import type { Scope } from './scope';

const getOverridesByCompType = (scope: Scope, compRef: CompRef) => {
  if (!compRef) {
    return;
  }

  const compDesign = scope.components.design.get(compRef);
  if (compDesign) {
    return;
  }

  const compStyle = scope.components.style.get([compRef]);

  if (!compStyle) {
    return {};
  }

  const newAlphaValue = compStyle.style?.properties?.['alpha-bg'] ?? 1;

  return {
    colorLayers: [
      {
        type: 'SolidColorLayer',
        opacity: +newAlphaValue,
        fill: {
          type: 'SolidColor',
          color: compStyle.style?.properties?.bg || 'color_11',
        },
      },
    ],
  };
};

const handleDesignUpdate = async (scope: Scope, compRef: CompRef) => {
  const styleOverrides = getOverridesByCompType(scope, compRef);
  if (!styleOverrides) {
    return;
  }
  try {
    await scope.editorAPI.documentServices.transactions.runAndWaitForApproval(
      async () => {
        scope.components.design.update(
          compRef,
          createDefaultDesignWithOverrides(styleOverrides),
          false,
          true,
        );
      },
    );
  } catch (error) {
    ErrorReporter.captureException(error, {
      tags: {
        siteSegmentInitDesignItem: true,
      },
    });
  }
};

export const initCompsDesign = async (scope: Scope, compsList: CompRef[]) => {
  await Promise.allSettled(
    compsList.map(async (compRef) => {
      await handleDesignUpdate(scope, compRef);
    }),
  );
};
