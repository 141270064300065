'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const i18n = require('@/i18n')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_listsandgrids_layouts",
    "subNavigationTitle": "add_section_label_listsandgrids_layouts",
    "presetTitle": "add_section_label_listsandgrids_layouts",
    "tooltipTitle": "add_section_label_listsandgrids_layouts",
    "automationId": "add-panel-section-repeaterSection",
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": `addPanelData/sections/localizationRepeaters/repeaters_${util.languages.getLanguageCode()}.png`,
        "imageHover": null,
        "items": [
            {
                "id": "Repeater_Repeaters_1",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto",
                                    "layout": {
                                        "width": 306,
                                        "height": 226,
                                        "x": 0,
                                        "y": 0,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "2.0",
                                                "isHidden": false
                                            },
                                            "title": "",
                                            "uri": "af9daef5b5684a679caf003614294ccd.jpg",
                                            "description": "",
                                            "width": 6000,
                                            "height": 4000,
                                            "alt": "",
                                            "crop": {
                                                "x": 584,
                                                "y": 0,
                                                "width": 5416,
                                                "height": 4000
                                            }
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "af9daef5b5684a679caf003614294ccd.jpg",
                                                "description": "",
                                                "width": 6000,
                                                "height": 4000,
                                                "alt": "",
                                                "crop": {
                                                    "x": 584,
                                                    "y": 0,
                                                    "width": 5416,
                                                    "height": 4000
                                                }
                                            },
                                            "item2": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_0d9c0ddff0e54fb99dbea4f37fe36111~mv2_d_8576_5696_s_4_2.jpg",
                                                "description": "",
                                                "width": 8576,
                                                "height": 5696,
                                                "alt": ""
                                            },
                                            "item3": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "3273c6e2911e44059093e6011be353a5.jpg",
                                                "description": "",
                                                "width": 3959,
                                                "height": 2644,
                                                "alt": ""
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {},
                                            "propertiesSource": {}
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WPhoto",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 297,
                                            "height": 231,
                                            "x": 12,
                                            "y": 10,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Container",
                                    "components": [
                                        {
                                            "type": "Component",
                                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                            "layout": {
                                                "width": 159,
                                                "height": 27,
                                                "x": 17,
                                                "y": 8,
                                                "scale": 1,
                                                "rotationInDegrees": 0,
                                                "fixedPosition": false
                                            },
                                            "componentType": "wysiwyg.viewer.components.WRichText",
                                            "data": {
                                                "type": "RepeatedData",
                                                "original": {
                                                    "type": "StyledText",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "1.0",
                                                        "isHidden": false
                                                    },
                                                    "text": `<h4 class=\"font_2\" style=\"font-size:20px\"><span style=\"font-size:20px\"><span style=\"letter-spacing:0.08em\"><span style=\"font-weight:bold\"><span style=\"color:#577083\"><span style=\"font-family:open sans,sans-serif\">${i18n.translate('comp-j9o86nhz.components[0].components[1].components[0].data.overrides.item1.text')}</span></span></span></span></span></h4>`,
                                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                    "linkList": []
                                                },
                                                "overrides": {
                                                    "item1": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<h4 class=\"font_2\" style=\"font-size:20px\"><span style=\"font-size:20px\"><span style=\"letter-spacing:0.08em\"><span style=\"font-weight:bold\"><span style=\"color:#577083\"><span style=\"font-family:open sans,sans-serif\">${i18n.translate('comp-j9o86nhz.components[0].components[1].components[0].data.overrides.item1.text')}</span></span></span></span></span></h4>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    },
                                                    "item2": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<h4 class=\"font_2\" style=\"font-size: 20px;\"><span style=\"font-size:20px\"><span style=\"letter-spacing:0.08em\"><span style=\"font-weight:bold\"><span style=\"color:#577083\"><span style=\"font-family:open sans,sans-serif\">${i18n.translate('comp-j9o86nhz.components[0].components[1].components[0].data.overrides.item2.text')}</span></span></span></span></span></h4>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    },
                                                    "item3": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<h4 class=\"font_2\" style=\"font-size:20px\"><span style=\"font-size:20px\"><span style=\"letter-spacing:0.08em\"><span style=\"font-weight:bold\"><span style=\"color:#577083\"><span style=\"font-family:open sans,sans-serif\">${i18n.translate('comp-j9o86nhz.components[0].components[1].components[0].data.overrides.item3.text')}</span></span></span></span></span></h4>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    }
                                                }
                                            },
                                            "props": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0"
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            },
                                            "style": {
                                                "type": "TopLevelStyle",
                                                "metaData": {
                                                    "isPreset": true,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "style": {
                                                    "groups": {},
                                                    "properties": {
                                                        "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                        "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                        "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                        "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                        "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                        "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                        "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                        "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                        "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                        "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                        "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                                    },
                                                    "propertiesSource": {
                                                        "f0": "value",
                                                        "f1": "value",
                                                        "f10": "value",
                                                        "f2": "value",
                                                        "f3": "value",
                                                        "f4": "value",
                                                        "f5": "value",
                                                        "f6": "value",
                                                        "f7": "value",
                                                        "f8": "value",
                                                        "f9": "value"
                                                    }
                                                },
                                                "componentClassName": "",
                                                "pageId": "",
                                                "compId": "",
                                                "styleType": "custom",
                                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                            },
                                            "mobileStructure": {
                                                "componentType": "wysiwyg.viewer.components.WRichText",
                                                "type": "Component",
                                                "conversionData": {
                                                    "isCustomPreset": true,
                                                    "mobileProps": {
                                                        "type": "WRichTextProperties",
                                                        "metaData": {
                                                            "schemaVersion": "1.0",
                                                            "autoGenerated": false
                                                        },
                                                        "brightness": 1,
                                                        "packed": true
                                                    }
                                                },
                                                "layout": {
                                                    "width": 149,
                                                    "height": 23,
                                                    "x": 7,
                                                    "y": 7,
                                                    "scale": 0.8727272727272727,
                                                    "rotationInDegrees": 0,
                                                    "fixedPosition": false
                                                }
                                            }
                                        }
                                    ],
                                    "layout": {
                                        "width": 180,
                                        "height": 40,
                                        "x": -1,
                                        "y": 196,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "mobile.core.components.Container",
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-bg": "1",
                                                "alpha-brd": "1",
                                                "bg": "#FFFFFF",
                                                "boxShadowToggleOn-shd": "false",
                                                "brd": "#858585",
                                                "brw": "0",
                                                "rd": "0px",
                                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                            },
                                            "propertiesSource": {
                                                "bg": "value",
                                                "brd": "value",
                                                "brw": "value",
                                                "rd": "value",
                                                "shd": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "mobile.core.components.Container",
                                        "type": "Container",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 164,
                                            "height": 55,
                                            "x": 12,
                                            "y": 209,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 191,
                                        "height": 17,
                                        "x": 16,
                                        "y": 238,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size: 14px;\"><span style=\"font-size:14px;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span style=\"color:#36B5CD\">01/19 - 01/23</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size: 14px;\"><span style=\"font-size:14px;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span style=\"color:#36B5CD\">01/19 - 01/23</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item2": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#36B5CD\">01/19 - 01/23</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item3": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size: 14px;\"><span style=\"font-size:14px;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span style=\"color:#36B5CD\">01/19 - 01/23</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 149,
                                            "height": 17,
                                            "x": 22,
                                            "y": 244,
                                            "scale": 0.9333333333333333,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 268,
                                        "height": 68,
                                        "x": 19,
                                        "y": 272,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_8\" style=\"line-height:1.5em\"><span style=\"color:#577083\">${i18n.translate('comp-j9o86nhz.components[0].components[3].data.overrides.item1.text')}</span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"line-height:1.5em\"><span style=\"color:#577083\">${i18n.translate('comp-j9o86nhz.components[0].components[3].data.overrides.item1.text')}</span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item2": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"line-height:1.5em\"><span style=\"color:#577083\">${i18n.translate('comp-j9o86nhz.components[0].components[3].data.overrides.item1.text')}</span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item3": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"line-height:1.5em\"><span style=\"color:#577083\">${i18n.translate('comp-j9o86nhz.components[0].components[3].data.overrides.item1.text')}</span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 68
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 44,
                                            "x": 20,
                                            "y": 280,
                                            "scale": 0.9974999999999999,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 115,
                                        "height": 22,
                                        "x": 19,
                                        "y": 354,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:15px; line-height:1.5em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#577083\">${i18n.translate('comp-j9o86nhz.components[0].components[4].data.overrides.item1.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:15px; line-height:1.5em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#577083\">${i18n.translate('comp-j9o86nhz.components[0].components[4].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item2": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:15px; line-height:1.5em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#577083\">${i18n.translate('comp-j9o86nhz.components[0].components[4].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item3": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:15px; line-height:1.5em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#577083\">${i18n.translate('comp-j9o86nhz.components[0].components[4].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 98,
                                            "height": 22,
                                            "x": 20,
                                            "y": 351,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 136,
                                        "height": 39,
                                        "x": 19,
                                        "y": 374,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size: 26px; line-height: 1.5em;\"><span style=\"font-size:26px;\"><span style=\"color:#36B5CD\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\">$550</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size: 26px; line-height: 1.5em;\"><span style=\"font-size:26px;\"><span style=\"color:#36B5CD\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\">$550</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item2": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:26px; line-height:1.5em\"><span style=\"font-size:26px\"><span style=\"color:#36B5CD\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">$399</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item3": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:26px; line-height:1.5em\"><span style=\"font-size:26px\"><span style=\"color:#36B5CD\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">$499</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 147,
                                            "height": 35,
                                            "x": 20,
                                            "y": 372,
                                            "scale": 0.8846153846153846,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                    "layout": {
                                        "width": 120,
                                        "height": 40,
                                        "x": 167,
                                        "y": 367,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "LinkableButton",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "label": i18n.translate('comp-j9o86nhz.components[0].components[6].data.overrides.item1.label')
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9o86nhz.components[0].components[6].data.overrides.item1.label')
                                            },
                                            "item2": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9o86nhz.components[0].components[6].data.overrides.item1.label')
                                            },
                                            "item3": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9o86nhz.components[0].components[6].data.overrides.item1.label')
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "align": "center",
                                        "margin": 0
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },

                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-bg": "1",
                                                "alpha-bgh": "1",
                                                "alpha-brd": "1",
                                                "alpha-brdh": "0",
                                                "alpha-txt": "1",
                                                "alpha-txth": "1",
                                                "bg": "#36B5CD",
                                                "bgh": "#5BC9DE",
                                                "boxShadowToggleOn-shd": "false",
                                                "brd": "#545454",
                                                "brdh": "#F9F9F9",
                                                "brw": "0",
                                                "fnt": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "rd": "0px",
                                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                                "txt": "#FFFFFF",
                                                "txth": "#FFFFFF"
                                            },
                                            "propertiesSource": {
                                                "alpha-brdh": "value",
                                                "bg": "value",
                                                "bgh": "value",
                                                "brd": "value",
                                                "brdh": "value",
                                                "brw": "value",
                                                "fnt": "value",
                                                "rd": "value",
                                                "shd": "value",
                                                "txt": "value",
                                                "txth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.SiteButton",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 120,
                                            "height": 40,
                                            "x": 185,
                                            "y": 362,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 306,
                                "height": 427,
                                "x": 336,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#FFFFFF",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo",
                                        "mediaTransforms": {
                                            "scale": 1
                                        }
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        },
                                        "cssBoxShadow": [
                                            {
                                                "inset": false,
                                                "offsetX": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "offsetY": {
                                                    "value": 4,
                                                    "unit": "px"
                                                },
                                                "blurRadius": {
                                                    "value": 10,
                                                    "unit": "px"
                                                },
                                                "spreadRadius": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "color": {
                                                    "red": 0,
                                                    "green": 0,
                                                    "blue": 0,
                                                    "alpha": 0.1
                                                }
                                            }
                                        ]
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9o8atta",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "cssBoxShadow": [
                                                {
                                                    "inset": false,
                                                    "offsetX": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "offsetY": {
                                                        "value": 4,
                                                        "unit": "px"
                                                    },
                                                    "blurRadius": {
                                                        "value": 10,
                                                        "unit": "px"
                                                    },
                                                    "spreadRadius": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "color": {
                                                        "red": 0,
                                                        "green": 0,
                                                        "blue": 0,
                                                        "alpha": 0.1
                                                    }
                                                }
                                            ]
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9o8atta",
                                        "dataChangeBehaviors": []
                                    },
                                    "item2": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "cssBoxShadow": [
                                                {
                                                    "inset": false,
                                                    "offsetX": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "offsetY": {
                                                        "value": 4,
                                                        "unit": "px"
                                                    },
                                                    "blurRadius": {
                                                        "value": 10,
                                                        "unit": "px"
                                                    },
                                                    "spreadRadius": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "color": {
                                                        "red": 0,
                                                        "green": 0,
                                                        "blue": 0,
                                                        "alpha": 0.1
                                                    }
                                                }
                                            ]
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9pcqmvo",
                                        "dataChangeBehaviors": []
                                    },
                                    "item3": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "cssBoxShadow": [
                                                {
                                                    "inset": false,
                                                    "offsetX": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "offsetY": {
                                                        "value": 4,
                                                        "unit": "px"
                                                    },
                                                    "blurRadius": {
                                                        "value": 10,
                                                        "unit": "px"
                                                    },
                                                    "spreadRadius": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "color": {
                                                        "red": 0,
                                                        "green": 0,
                                                        "blue": 0,
                                                        "alpha": 0.1
                                                    }
                                                }
                                            ]
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9pcqsl2",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 436,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 980,
                        "height": 427,
                        "x": 0,
                        "y": 86,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item2",
                            "item3"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 30,
                            "horizontal": 30
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true,
                            "mobileProps": {
                                "type": "CardsLayoutProperties",
                                "metaData": {
                                    "schemaVersion": "1.0",
                                    "autoGenerated": false
                                },
                                "alignment": "left",
                                "gap": {
                                    "vertical": 20,
                                    "horizontal": 20
                                }
                            }
                        },
                        "layout": {
                            "width": 320,
                            "height": 1348,
                            "x": 0,
                            "y": 43,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9o86nhz"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 184,
                        "x": 0,
                        "y": 0
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_2",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                    "layout": {
                                        "width": 200,
                                        "height": 101,
                                        "x": 121,
                                        "y": 33,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_8\" style=\"line-height:1.8em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9pe6wlf.components[0].components[0].data.overrides.item1.text')}</span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"line-height:1.8em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9pe6wlf.components[0].components[0].data.overrides.item1.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item2": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"line-height:1.8em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9pe6wlf.components[0].components[0].data.overrides.item2.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item3": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"line-height:1.8em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9pe6wlf.components[0].components[0].data.overrides.item3.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pejq8o": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"line-height:1.8em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9pe6wlf.components[0].components[0].data.overrides.item-j9pejq8o.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pejooz": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"line-height:1.8em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9pe6wlf.components[0].components[0].data.overrides.item-j9pejooz.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pejndu": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"line-height:1.8em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9pe6wlf.components[0].components[0].data.overrides.item-j9pejndu.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pejllk": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"line-height:1.8em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9pe6wlf.components[0].components[0].data.overrides.item-j9pejllk.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 101
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 184,
                                            "height": 81,
                                            "x": 83,
                                            "y": 44,
                                            "scale": 0.9974999999999999,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                    "layout": {
                                        "width": 49,
                                        "height": 47,
                                        "x": 35,
                                        "y": 31,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_2\" style=\"font-size: 30px;\"><span style=\"font-size:30px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">1.</span></span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 30px;\"><span style=\"font-size:30px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">1.</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item2": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 30px;\"><span style=\"font-size:30px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">2.</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item3": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 30px;\"><span style=\"font-size:30px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">3.</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pejq8o": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 30px;\"><span style=\"font-size:30px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">4.</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pejooz": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 30px;\"><span style=\"font-size:30px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">5.</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pejndu": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 30px;\"><span style=\"font-size:30px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">6.</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pejllk": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 30px;\"><span style=\"font-size:30px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">7.</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 47
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 48,
                                            "height": 41,
                                            "x": 26,
                                            "y": 28,
                                            "scale": 1.125,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 369,
                                "height": 158,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#35C68B",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo"
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9ps462s",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#35C68B",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9ps462s",
                                        "dataChangeBehaviors": []
                                    },
                                    "item2": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#35C68B",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9ps462w",
                                        "dataChangeBehaviors": []
                                    },
                                    "item3": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#35C68B",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9ps462y",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pejq8o": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#35C68B",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9ps4630",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pejooz": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#35C68B",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9ps4632",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pejndu": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#35C68B",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9ps4634",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pejllk": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#35C68B",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9ps4636",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 158,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 369,
                        "height": 1106,
                        "x": 6,
                        "y": 562,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item2",
                            "item3",
                            "item-j9pejq8o",
                            "item-j9pejooz",
                            "item-j9pejndu",
                            "item-j9pejllk"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 0,
                            "horizontal": 0
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 1106,
                            "x": 0,
                            "y": 1434,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9pe6wlf"
                },
                "preset": {
                    "rect": {
                        "width": 141,
                        "height": 315,
                        "x": 0,
                        "y": 184
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_3",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 319,
                                        "height": 349,
                                        "x": 20,
                                        "y": 217,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "2.0",
                                                "isHidden": false
                                            },
                                            "title": "",
                                            "uri": "0ad29cb9d94741debe3c0c516a8ee222.jpg",
                                            "description": "",
                                            "width": 1342,
                                            "height": 894,
                                            "alt": "",
                                            "crop": {
                                                "x": 183,
                                                "y": 0,
                                                "width": 766,
                                                "height": 894
                                            }
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "0ad29cb9d94741debe3c0c516a8ee222.jpg",
                                                "description": "",
                                                "width": 1342,
                                                "height": 894,
                                                "alt": "",
                                                "crop": {
                                                    "x": 183,
                                                    "y": 0,
                                                    "width": 766,
                                                    "height": 894
                                                }
                                            },
                                            "item3": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_19f97958a972415a8a70f3ce42e57548~mv2_d_4000_6016_s_4_2.jpg",
                                                "description": "",
                                                "width": 4000,
                                                "height": 6016,
                                                "alt": ""
                                            },
                                            "item2": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "1225c146e4c544c3ab8b4fc772b41373.jpg",
                                                "description": "",
                                                "width": 1382,
                                                "height": 922,
                                                "alt": "",
                                                "crop": {
                                                    "x": 251,
                                                    "y": 0,
                                                    "width": 843,
                                                    "height": 922
                                                }
                                            },
                                            "item-j9pghds4": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "eb7b30e78b58498781b44a63c4543c39.jpg",
                                                "description": "",
                                                "width": 5760,
                                                "height": 3840,
                                                "alt": "",
                                                "crop": {
                                                    "x": 2749,
                                                    "y": 877,
                                                    "width": 2255,
                                                    "height": 2468
                                                }
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {},
                                            "propertiesSource": {}
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WPhoto",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 306,
                                            "x": 20,
                                            "y": 195,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 311,
                                        "height": 96,
                                        "x": 20,
                                        "y": 87,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:16px; line-height:1.5em\"><span style=\"color:#333333\"><span style=\"font-size:16px\">${i18n.translate('comp-j9peaki1.components[0].components[1].data.overrides.item1.text')}</span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:16px; line-height:1.5em\"><span style=\"color:#333333\"><span style=\"font-size:16px\">${i18n.translate('comp-j9peaki1.components[0].components[1].data.overrides.item1.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item3": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:16px; line-height:1.5em\"><span style=\"color:#333333\"><span style=\"font-size:16px\">${i18n.translate('comp-j9peaki1.components[0].components[1].data.overrides.item3.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item2": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:16px; line-height:1.5em\"><span style=\"color:#333333\"><span style=\"font-size:16px\">${i18n.translate('comp-j9peaki1.components[0].components[1].data.overrides.item2.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pghds4": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:16px; line-height:1.5em\"><span style=\"color:#333333\"><span style=\"font-size:16px\">${i18n.translate('comp-j9peaki1.components[0].components[1].data.overrides.item1.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 96,
                                            "x": 20,
                                            "y": 76,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 306,
                                        "height": 44,
                                        "x": 20,
                                        "y": 20,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h4 class=\"font_4\" style=\"font-size:30px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.08em\"><span style=\"font-family:anton,sans-serif\"><span style=\"font-size:30px\">${i18n.translate('comp-j9peaki1.components[0].components[2].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:30px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.08em\"><span style=\"font-family:anton,sans-serif\"><span style=\"font-size:30px\">${i18n.translate('comp-j9peaki1.components[0].components[2].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item3": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:30px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.08em\"><span style=\"font-family:anton,sans-serif\"><span style=\"font-size:30px\">${i18n.translate('comp-j9peaki1.components[0].components[2].data.overrides.item3.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item2": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:30px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.08em\"><span style=\"font-family:anton,sans-serif\"><span style=\"font-size:30px\">${i18n.translate('comp-j9peaki1.components[0].components[2].data.overrides.item2.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pghds4": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:30px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.08em\"><span style=\"font-family:anton,sans-serif\"><span style=\"font-size:30px\">${i18n.translate('comp-j9peaki1.components[0].components[2].data.overrides.item-j9pghds4.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 34,
                                            "x": 20,
                                            "y": 23,
                                            "scale": 0.8518518518518519,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                    "layout": {
                                        "width": 319,
                                        "height": 5,
                                        "x": 20,
                                        "y": 70,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                    "props": {
                                        "type": "FiveGridLineProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "fullScreenModeOn": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-brd": "1",
                                                "brd": "#F2BF5E",
                                                "lnw": "3"
                                            },
                                            "propertiesSource": {
                                                "brd": "value",
                                                "lnw": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 5,
                                            "x": 20,
                                            "y": 64,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 361,
                                "height": 589,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#FFFFFF",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo",
                                        "mediaTransforms": {
                                            "scale": 1
                                        }
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        },
                                        "cssBoxShadow": [
                                            {
                                                "inset": false,
                                                "offsetX": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "offsetY": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "blurRadius": {
                                                    "value": 13,
                                                    "unit": "px"
                                                },
                                                "spreadRadius": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "color": {
                                                    "red": 0,
                                                    "green": 0,
                                                    "blue": 0,
                                                    "alpha": 0.1
                                                }
                                            }
                                        ]
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9psyr4o",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "cssBoxShadow": [
                                                {
                                                    "inset": false,
                                                    "offsetX": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "offsetY": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "blurRadius": {
                                                        "value": 13,
                                                        "unit": "px"
                                                    },
                                                    "spreadRadius": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "color": {
                                                        "red": 0,
                                                        "green": 0,
                                                        "blue": 0,
                                                        "alpha": 0.1
                                                    }
                                                }
                                            ]
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9psyr4o",
                                        "dataChangeBehaviors": []
                                    },
                                    "item3": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "cssBoxShadow": [
                                                {
                                                    "inset": false,
                                                    "offsetX": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "offsetY": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "blurRadius": {
                                                        "value": 13,
                                                        "unit": "px"
                                                    },
                                                    "spreadRadius": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "color": {
                                                        "red": 0,
                                                        "green": 0,
                                                        "blue": 0,
                                                        "alpha": 0.1
                                                    }
                                                }
                                            ]
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9psyr4s",
                                        "dataChangeBehaviors": []
                                    },
                                    "item2": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "cssBoxShadow": [
                                                {
                                                    "inset": false,
                                                    "offsetX": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "offsetY": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "blurRadius": {
                                                        "value": 13,
                                                        "unit": "px"
                                                    },
                                                    "spreadRadius": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "color": {
                                                        "red": 0,
                                                        "green": 0,
                                                        "blue": 0,
                                                        "alpha": 0.1
                                                    }
                                                }
                                            ]
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9psyr4r",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pghds4": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "cssBoxShadow": [
                                                {
                                                    "inset": false,
                                                    "offsetX": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "offsetY": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "blurRadius": {
                                                        "value": 13,
                                                        "unit": "px"
                                                    },
                                                    "spreadRadius": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "color": {
                                                        "red": 0,
                                                        "green": 0,
                                                        "blue": 0,
                                                        "alpha": 0.1
                                                    }
                                                }
                                            ]
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9psyr4t",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 511,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 758,
                        "height": 1251,
                        "x": 2,
                        "y": 1735,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item3",
                            "item2",
                            "item-j9pghds4"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 25,
                            "horizontal": 25
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 2119,
                            "x": 0,
                            "y": 2597,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9peaki1"
                },
                "preset": {
                    "rect": {
                        "width": 183,
                        "height": 315,
                        "x": 141,
                        "y": 184
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_4",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                    "layout": {
                                        "width": 231,
                                        "height": 35,
                                        "x": 6,
                                        "y": 48,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h4 class=\"font_2\" style=\"font-size: 26px; text-align: center;\"><span style=\"font-size:26px\"><span style=\"letter-spacing:0.08em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9phsrjg.components[0].components[0].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_2\" style=\"font-size: 26px; text-align: center;\"><span style=\"font-size:26px\"><span style=\"letter-spacing:0.08em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9phsrjg.components[0].components[0].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9ples3e": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_2\" style=\"font-size: 26px; text-align: center;\"><span style=\"font-size:26px\"><span style=\"letter-spacing:0.08em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9phsrjg.components[0].components[0].data.overrides.item-j9ples3e.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9plerjk": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_2\" style=\"font-size: 26px; text-align: center;\"><span style=\"font-size:26px\"><span style=\"letter-spacing:0.08em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9phsrjg.components[0].components[0].data.overrides.item-j9plerjk.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pleqw5": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_2\" style=\"font-size: 26px; text-align: center;\"><span style=\"font-size:26px\"><span style=\"letter-spacing:0.08em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9phsrjg.components[0].components[0].data.overrides.item-j9pleqw5.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 29,
                                            "x": 20,
                                            "y": 40,
                                            "scale": 0.8461538461538461,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                    "layout": {
                                        "width": 201,
                                        "height": 63,
                                        "x": 20,
                                        "y": 113,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_2\" style=\"font-size: 14px; line-height: 1.5em; text-align: center;\"><span style=\"font-size:14px;\"><span style=\"font-family:georgia,palatino,book antiqua,palatino linotype,serif\"><span style=\"letter-spacing:0em\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9phsrjg.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 14px; line-height: 1.5em; text-align: center;\"><span style=\"font-size:14px;\"><span style=\"font-family:georgia,palatino,book antiqua,palatino linotype,serif\"><span style=\"letter-spacing:0em\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9phsrjg.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9ples3e": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 14px; line-height: 1.5em; text-align: center;\"><span style=\"font-size:14px;\"><span style=\"font-family:georgia,palatino,book antiqua,palatino linotype,serif\"><span style=\"letter-spacing:0em\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9phsrjg.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9plerjk": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 14px; line-height: 1.5em; text-align: center;\"><span style=\"font-size:14px;\"><span style=\"font-family:georgia,palatino,book antiqua,palatino linotype,serif\"><span style=\"letter-spacing:0em\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9phsrjg.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pleqw5": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 14px; line-height: 1.5em; text-align: center;\"><span style=\"font-size:14px;\"><span style=\"font-family:georgia,palatino,book antiqua,palatino linotype,serif\"><span style=\"letter-spacing:0em\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9phsrjg.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 233,
                                            "height": 66,
                                            "x": 43,
                                            "y": 101,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                                    "layout": {
                                        "width": 48,
                                        "height": 9,
                                        "x": 96,
                                        "y": 210,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "ImageButton",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "defaultImage": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "Arrow.png",
                                                "uri": "5bfb6f_c2ae26a3c5004bca9ea2b860a535f4ab~mv2.png",
                                                "description": "",
                                                "width": 36,
                                                "height": 10,
                                                "alt": ""
                                            },
                                            "hoverImage": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "Arrow_Hover.png",
                                                "uri": "5bfb6f_ff73ca63250240b2a3c975834afa9356~mv2.png",
                                                "description": "",
                                                "width": 36,
                                                "height": 10,
                                                "alt": ""
                                            },
                                            "activeImage": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "Arrow.png",
                                                "uri": "5bfb6f_c2ae26a3c5004bca9ea2b860a535f4ab~mv2.png",
                                                "description": "",
                                                "width": 36,
                                                "height": 10,
                                                "alt": ""
                                            },
                                            "alt": ""
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "ImageButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "defaultImage": {
                                                    "type": "Image",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "2.0",
                                                        "isHidden": false
                                                    },
                                                    "title": "Arrow.png",
                                                    "uri": "5bfb6f_c2ae26a3c5004bca9ea2b860a535f4ab~mv2.png",
                                                    "description": "",
                                                    "width": 36,
                                                    "height": 10,
                                                    "alt": ""
                                                },
                                                "hoverImage": {
                                                    "type": "Image",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "2.0",
                                                        "isHidden": false
                                                    },
                                                    "title": "Arrow_Hover.png",
                                                    "uri": "5bfb6f_ff73ca63250240b2a3c975834afa9356~mv2.png",
                                                    "description": "",
                                                    "width": 36,
                                                    "height": 10,
                                                    "alt": ""
                                                },
                                                "activeImage": {
                                                    "type": "Image",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "2.0",
                                                        "isHidden": false
                                                    },
                                                    "title": "Arrow.png",
                                                    "uri": "5bfb6f_c2ae26a3c5004bca9ea2b860a535f4ab~mv2.png",
                                                    "description": "",
                                                    "width": 36,
                                                    "height": 10,
                                                    "alt": ""
                                                },
                                                "alt": ""
                                            },
                                            "item-j9ples3e": {
                                                "type": "ImageButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "defaultImage": {
                                                    "type": "Image",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "2.0",
                                                        "isHidden": false
                                                    },
                                                    "title": "Arrow.png",
                                                    "uri": "5bfb6f_c2ae26a3c5004bca9ea2b860a535f4ab~mv2.png",
                                                    "description": "",
                                                    "width": 36,
                                                    "height": 10,
                                                    "alt": ""
                                                },
                                                "hoverImage": {
                                                    "type": "Image",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "2.0",
                                                        "isHidden": false
                                                    },
                                                    "title": "Arrow_Hover.png",
                                                    "uri": "5bfb6f_ff73ca63250240b2a3c975834afa9356~mv2.png",
                                                    "description": "",
                                                    "width": 36,
                                                    "height": 10,
                                                    "alt": ""
                                                },
                                                "activeImage": {
                                                    "type": "Image",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "2.0",
                                                        "isHidden": false
                                                    },
                                                    "title": "Arrow.png",
                                                    "uri": "5bfb6f_c2ae26a3c5004bca9ea2b860a535f4ab~mv2.png",
                                                    "description": "",
                                                    "width": 36,
                                                    "height": 10,
                                                    "alt": ""
                                                },
                                                "alt": ""
                                            },
                                            "item-j9plerjk": {
                                                "type": "ImageButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "defaultImage": {
                                                    "type": "Image",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "2.0",
                                                        "isHidden": false
                                                    },
                                                    "title": "Arrow.png",
                                                    "uri": "5bfb6f_c2ae26a3c5004bca9ea2b860a535f4ab~mv2.png",
                                                    "description": "",
                                                    "width": 36,
                                                    "height": 10,
                                                    "alt": ""
                                                },
                                                "hoverImage": {
                                                    "type": "Image",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "2.0",
                                                        "isHidden": false
                                                    },
                                                    "title": "Arrow_Hover.png",
                                                    "uri": "5bfb6f_ff73ca63250240b2a3c975834afa9356~mv2.png",
                                                    "description": "",
                                                    "width": 36,
                                                    "height": 10,
                                                    "alt": ""
                                                },
                                                "activeImage": {
                                                    "type": "Image",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "2.0",
                                                        "isHidden": false
                                                    },
                                                    "title": "Arrow.png",
                                                    "uri": "5bfb6f_c2ae26a3c5004bca9ea2b860a535f4ab~mv2.png",
                                                    "description": "",
                                                    "width": 36,
                                                    "height": 10,
                                                    "alt": ""
                                                },
                                                "alt": ""
                                            },
                                            "item-j9pleqw5": {
                                                "type": "ImageButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "defaultImage": {
                                                    "type": "Image",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "2.0",
                                                        "isHidden": false
                                                    },
                                                    "title": "Arrow.png",
                                                    "uri": "5bfb6f_c2ae26a3c5004bca9ea2b860a535f4ab~mv2.png",
                                                    "description": "",
                                                    "width": 36,
                                                    "height": 10,
                                                    "alt": ""
                                                },
                                                "hoverImage": {
                                                    "type": "Image",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "2.0",
                                                        "isHidden": false
                                                    },
                                                    "title": "Arrow_Hover.png",
                                                    "uri": "5bfb6f_ff73ca63250240b2a3c975834afa9356~mv2.png",
                                                    "description": "",
                                                    "width": 36,
                                                    "height": 10,
                                                    "alt": ""
                                                },
                                                "activeImage": {
                                                    "type": "Image",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "2.0",
                                                        "isHidden": false
                                                    },
                                                    "title": "Arrow.png",
                                                    "uri": "5bfb6f_c2ae26a3c5004bca9ea2b860a535f4ab~mv2.png",
                                                    "description": "",
                                                    "width": 36,
                                                    "height": 10,
                                                    "alt": ""
                                                },
                                                "alt": ""
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "ImageButtonProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "transition": "fade"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {},
                                            "propertiesSource": {}
                                        },
                                        "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 60,
                                            "height": 11,
                                            "x": 130,
                                            "y": 193,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 241,
                                "height": 250,
                                "x": 738,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#F2BF5E",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo",
                                        "mediaTransforms": {
                                            "scale": 1
                                        }
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9pkr07o",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#F2BF5E",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9pkr07o",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9ples3e": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FF6161",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9plfiju",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9plerjk": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#536EB7",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9plfm0b",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pleqw5": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#75CBA8",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9plfr4e",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 324,
                                    "height": 244,
                                    "x": 0,
                                    "y": 244,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 980,
                        "height": 250,
                        "x": -1,
                        "y": 3049,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9ples3e",
                            "item-j9plerjk",
                            "item-j9pleqw5"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 5,
                            "horizontal": 5
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true,
                            "mobileProps": {
                                "type": "CardsLayoutProperties",
                                "metaData": {
                                    "schemaVersion": "1.0",
                                    "autoGenerated": false
                                },
                                "alignment": "left",
                                "gap": {
                                    "vertical": 0,
                                    "horizontal": 0
                                }
                            }
                        },
                        "layout": {
                            "width": 324,
                            "height": 976,
                            "x": -4,
                            "y": 4777,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9phsrjg"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 118,
                        "x": 0,
                        "y": 499
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_5",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                                    "layout": {
                                        "width": 160,
                                        "height": 160,
                                        "x": 19,
                                        "y": 22,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "title": "",
                                            "uri": "ecb6bd6e8d334b0b8edfc734f2f1dca6.jpg",
                                            "description": "",
                                            "width": 6000,
                                            "height": 4000,
                                            "alt": "",
                                            "crop": {
                                                "x": 1750,
                                                "y": 0,
                                                "width": 4000,
                                                "height": 4000
                                            },
                                            "link": {
                                                "type": "ExternalLink",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "url": "http://www.wix.com",
                                                "target": "_blank"
                                            }
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "ecb6bd6e8d334b0b8edfc734f2f1dca6.jpg",
                                                "description": "",
                                                "width": 6000,
                                                "height": 4000,
                                                "alt": "",
                                                "crop": {
                                                    "x": 1750,
                                                    "y": 0,
                                                    "width": 4000,
                                                    "height": 4000
                                                },
                                                "link": {
                                                    "type": "ExternalLink",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "1.0",
                                                        "isHidden": false
                                                    },
                                                    "url": "http://www.wix.com",
                                                    "target": "_blank"
                                                }
                                            },
                                            "item-j9pnv9cm": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "9fb022ebe1404971ab3255c2a8713279.jpg",
                                                "description": "",
                                                "width": 3896,
                                                "height": 2600,
                                                "alt": "",
                                                "crop": {
                                                    "x": 1432,
                                                    "y": 250,
                                                    "width": 1602,
                                                    "height": 1600
                                                },
                                                "link": {
                                                    "type": "ExternalLink",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "1.0",
                                                        "isHidden": false
                                                    },
                                                    "url": "http://www.wix.com",
                                                    "target": "_blank"
                                                }
                                            },
                                            "item-j9pnv7xn": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "8e2268d23654421eab38df998c71dcc6.jpg",
                                                "description": "",
                                                "width": 822,
                                                "height": 1033,
                                                "alt": "",
                                                "link": {
                                                    "type": "ExternalLink",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "1.0",
                                                        "isHidden": false
                                                    },
                                                    "url": "http://www.wix.com",
                                                    "target": "_blank"
                                                }
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-brd": "0",
                                                "boxShadowToggleOn-shd": "false",
                                                "brd": "#FFFFFF",
                                                "brw": "0px",
                                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                            },
                                            "propertiesSource": {
                                                "alpha-brd": "value",
                                                "brd": "value",
                                                "brw": "value",
                                                "shd": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WPhoto",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 272,
                                            "height": 272,
                                            "x": 23,
                                            "y": 26,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 238,
                                        "height": 79,
                                        "x": 208,
                                        "y": 103,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_4\" style=\"font-size: 16px;\"><span style=\"color:#858585\"><span style=\"font-size:16px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:eb garamond,serif\">${i18n.translate('comp-j9pn2gew.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_4\" style=\"font-size: 16px;\"><span style=\"color:#858585\"><span style=\"font-size:16px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:eb garamond,serif\">${i18n.translate('comp-j9pn2gew.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pnv9cm": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_4\" style=\"font-size: 16px;\"><span style=\"color:#858585\"><span style=\"font-size:16px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:eb garamond,serif\">${i18n.translate('comp-j9pn2gew.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pnv7xn": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_4\" style=\"font-size: 16px;\"><span style=\"color:#858585\"><span style=\"font-size:16px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:eb garamond,serif\">${i18n.translate('comp-j9pn2gew.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 79
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true,
                                                "overrideAlignment": "center"
                                            }
                                        },
                                        "layout": {
                                            "width": 242,
                                            "height": 63,
                                            "x": 39,
                                            "y": 373,
                                            "scale": 0.9974999999999999,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 238,
                                        "height": 23,
                                        "x": 208,
                                        "y": 68,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_4\" style=\"font-size: 18px;\"><span style=\"font-size:18px\"><span style=\"color:#A2230A\"><span style=\"letter-spacing:0em\"><span style=\"font-family:eb garamond,serif\">${i18n.translate('comp-j9pn2gew.components[0].components[2].data.overrides.item1.text')}</span></span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_4\" style=\"font-size: 18px;\"><span style=\"font-size:18px\"><span style=\"color:#A2230A\"><span style=\"letter-spacing:0em\"><span style=\"font-family:eb garamond,serif\">${i18n.translate('comp-j9pn2gew.components[0].components[2].data.overrides.item1.text')}</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pnv9cm": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_4\" style=\"font-size: 18px;\"><span style=\"font-size:18px\"><span style=\"color:#A2230A\"><span style=\"letter-spacing:0em\"><span style=\"font-family:eb garamond,serif\">${i18n.translate('comp-j9pn2gew.components[0].components[2].data.overrides.item-j9pnv9cm.text')}</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pnv7xn": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_4\" style=\"font-size: 18px;\"><span style=\"font-size:18px\"><span style=\"color:#A2230A\"><span style=\"letter-spacing:0em\"><span style=\"font-family:eb garamond,serif\">${i18n.translate('comp-j9pn2gew.components[0].components[2].data.overrides.item-j9pnv7xn.text')}</span></span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true,
                                                "overrideAlignment": "center"
                                            }
                                        },
                                        "layout": {
                                            "width": 272,
                                            "height": 22,
                                            "x": 23,
                                            "y": 342,
                                            "scale": 0.9444444444444444,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 238,
                                        "height": 27,
                                        "x": 208,
                                        "y": 38,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h4 class=\"font_4\" style=\"font-size:24px\"><span style=\"font-size:24px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.08em\"><span style=\"font-family:helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif\">${i18n.translate('comp-j9pn2gew.components[0].components[3].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:24px\"><span style=\"font-size:24px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.08em\"><span style=\"font-family:helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif\">${i18n.translate('comp-j9pn2gew.components[0].components[3].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pnv9cm": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:24px\"><span style=\"font-size:24px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.08em\"><span style=\"font-family:helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif\">${i18n.translate('comp-j9pn2gew.components[0].components[3].data.overrides.item-j9pnv9cm.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pnv7xn": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:24px\"><span style=\"font-size:24px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.08em\"><span style=\"font-family:helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif\">${i18n.translate('comp-j9pn2gew.components[0].components[3].data.overrides.item-j9pnv7xn.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true,
                                                "overrideAlignment": "center"
                                            }
                                        },
                                        "layout": {
                                            "width": 272,
                                            "height": 24,
                                            "x": 23,
                                            "y": 317,
                                            "scale": 0.8333333333333334,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 479,
                                "height": 209,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#F9F9F9",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo",
                                        "mediaTransforms": {
                                            "scale": 1
                                        }
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9pn991b",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#F9F9F9",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9pn991b",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pnv9cm": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#F9F9F9",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9pn991b",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pnv7xn": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#F9F9F9",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9pn991b",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 462,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 479,
                        "height": 667,
                        "x": 7,
                        "y": 3353,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9pnv9cm",
                            "item-j9pnv7xn"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 20,
                            "horizontal": 20
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 1426,
                            "x": 0,
                            "y": 6736,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9pn2gew"
                },
                "preset": {
                    "rect": {
                        "width": 183,
                        "height": 275,
                        "x": 0,
                        "y": 617
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_6",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 125,
                                        "height": 120,
                                        "x": 22,
                                        "y": 24,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "2.0",
                                                "isHidden": false
                                            },
                                            "title": "",
                                            "uri": "fd5752f4ee5b4580b359d3ea16c8b715.jpeg",
                                            "description": "",
                                            "width": 2600,
                                            "height": 1698,
                                            "alt": ""
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "fd5752f4ee5b4580b359d3ea16c8b715.jpeg",
                                                "description": "",
                                                "width": 2600,
                                                "height": 1698,
                                                "alt": ""
                                            },
                                            "item-j9pmjd4r": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "da1a8132cff64a40a18d539a5abdb1cf.jpg",
                                                "description": "",
                                                "width": 5472,
                                                "height": 3648,
                                                "alt": "",
                                                "crop": {
                                                    "x": 644,
                                                    "y": 376,
                                                    "width": 3353,
                                                    "height": 3272
                                                }
                                            },
                                            "item-j9pmjbx7": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_888aeec6e18d4d09b87688c1d2530cec~mv2_d_2048_1365_s_2.jpg",
                                                "description": "",
                                                "width": 2048,
                                                "height": 1365,
                                                "alt": "",
                                                "crop": {
                                                    "x": 480,
                                                    "y": 0,
                                                    "width": 1249,
                                                    "height": 1245
                                                }
                                            },
                                            "item-j9pmjbip": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_79fd07aacb80489297ad377e7aff806d~mv2_d_5400_3841_s_4_2.jpg",
                                                "description": "",
                                                "width": 5400,
                                                "height": 3841,
                                                "alt": "",
                                                "crop": {
                                                    "x": 1565,
                                                    "y": 0,
                                                    "width": 3835,
                                                    "height": 3841
                                                }
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {},
                                            "propertiesSource": {}
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WPhoto",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 125,
                                            "height": 120,
                                            "x": 23,
                                            "y": 34,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 148,
                                        "height": 52,
                                        "x": 167,
                                        "y": 105,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:15px\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9pl04ef.components[0].components[1].data.overrides.item-j9pmjbip.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:15px\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9pl04ef.components[0].components[1].data.overrides.item-j9pmjbip.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pmjd4r": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:15px\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9pl04ef.components[0].components[1].data.overrides.item-j9pmjbip.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pmjbx7": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:15px\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9pl04ef.components[0].components[1].data.overrides.item-j9pmjbip.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pmjbip": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:15px\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9pl04ef.components[0].components[1].data.overrides.item-j9pmjbip.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 52
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 125,
                                            "height": 18,
                                            "x": 170,
                                            "y": 114,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                    "layout": {
                                        "width": 20,
                                        "height": 5,
                                        "x": 168,
                                        "y": 88,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                    "props": {
                                        "type": "FiveGridLineProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "fullScreenModeOn": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-brd": "1",
                                                "brd": "#FFFFFF",
                                                "lnw": "3"
                                            },
                                            "propertiesSource": {
                                                "brd": "value",
                                                "lnw": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 20,
                                            "height": 5,
                                            "x": 170,
                                            "y": 99,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 153,
                                        "height": 24,
                                        "x": 167,
                                        "y": 52,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h6 class=\"font_7\" style=\"font-size: 20px;\"><span style=\"font-size:20px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9pl04ef.components[0].components[3].data.overrides.item1.text')}</span></span></span></h6>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h6 class=\"font_7\" style=\"font-size: 20px;\"><span style=\"font-size:20px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9pl04ef.components[0].components[3].data.overrides.item1.text')}</span></span></span></h6>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pmjd4r": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h6 class=\"font_7\" style=\"font-size: 20px;\"><span style=\"font-size:20px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9pl04ef.components[0].components[3].data.overrides.item-j9pmjd4r.text')}</span></span></span></h6>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pmjbx7": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h6 class=\"font_7\" style=\"font-size: 20px;\"><span style=\"font-size:20px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9pl04ef.components[0].components[3].data.overrides.item-j9pmjbx7.text')}</span></span></span></h6>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pmjbip": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h6 class=\"font_7\" style=\"font-size: 20px;\"><span style=\"font-size:20px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-j9pl04ef.components[0].components[3].data.overrides.item-j9pmjbip.text')}</span></span></span></h6>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 125,
                                            "height": 23,
                                            "x": 170,
                                            "y": 59,
                                            "scale": 0.95,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 323,
                                "height": 180,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#333333",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo"
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9pta0wk",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#333333",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9pta0wk",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pmjd4r": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#333333",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9pta0wo",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pmjbx7": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#333333",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9pta0wp",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pmjbip": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#333333",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9pta0wr",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 189,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 323,
                        "height": 722,
                        "x": 38,
                        "y": 4094,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9pmjd4r",
                            "item-j9pmjbx7",
                            "item-j9pmjbip"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 0,
                            "horizontal": 0
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 792,
                            "x": 0,
                            "y": 5855,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9pl04ef"
                },
                "preset": {
                    "rect": {
                        "width": 141,
                        "height": 275,
                        "x": 183,
                        "y": 617
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_7",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3",
                                    "layout": {
                                        "width": 12,
                                        "height": 12,
                                        "x": 457,
                                        "y": 217,
                                        "scale": 1,
                                        "rotationInDegrees": 270,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "SvgShape",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            }
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "SvgShape",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                }
                                            },
                                            "item2": {
                                                "type": "SvgShape",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                }
                                            },
                                            "item-j9pujymd": {
                                                "type": "SvgShape",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                }
                                            },
                                            "item-j9pujxu7": {
                                                "type": "SvgShape",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                }
                                            },
                                            "item-j9pujwrr": {
                                                "type": "SvgShape",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                }
                                            },
                                            "item-j9pujvmm": {
                                                "type": "SvgShape",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                }
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "SvgShapeProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "maintainAspectRatio": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-fillcolor": "1",
                                                "alpha-stroke": "0",
                                                "fillcolor": "#FFFFFF",
                                                "strokewidth": "0"
                                            },
                                            "propertiesSource": {
                                                "alpha-stroke": "value",
                                                "fillcolor": "value",
                                                "stroke": "value",
                                                "strokewidth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 12,
                                            "height": 12,
                                            "x": 126,
                                            "y": 237,
                                            "scale": 1,
                                            "rotationInDegrees": 270,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 265,
                                        "height": 75,
                                        "x": 27,
                                        "y": 162,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h4 class=\"font_8\" style=\"font-size: 35px; line-height: 1em;\"><span style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">${i18n.translate('comp-j9pp53f2.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_8\" style=\"font-size: 35px; line-height: 1em;\"><span style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">${i18n.translate('comp-j9pp53f2.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item2": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_8\" style=\"font-size: 35px; line-height: 1em;\"><span style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">${i18n.translate('comp-j9pp53f2.components[0].components[1].data.overrides.item2.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pujymd": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_8\" style=\"font-size: 35px; line-height: 1em;\"><span style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">${i18n.translate('comp-j9pp53f2.components[0].components[1].data.overrides.item-j9pujymd.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pujxu7": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_8\" style=\"font-size: 35px; line-height: 1em;\"><span style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">${i18n.translate('comp-j9pp53f2.components[0].components[1].data.overrides.item-j9pujxu7.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pujwrr": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_8\" style=\"font-size: 35px; line-height: 1em;\"><span style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">${i18n.translate('comp-j9pp53f2.components[0].components[1].data.overrides.item-j9pujwrr.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9pujvmm": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_8\" style=\"font-size: 35px; line-height: 1em;\"><span style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">${i18n.translate('comp-j9pp53f2.components[0].components[1].data.overrides.item-j9pujvmm.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 75
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 62,
                                            "x": 19,
                                            "y": 38,
                                            "scale": 1.0628344735991382,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                                    "layout": {
                                        "width": 108,
                                        "height": 26,
                                        "x": 350,
                                        "y": 210,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "LinkableButton",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "label": i18n.translate('comp-j9pp53f2.components[0].components[2].data.original.label')
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9pp53f2.components[0].components[2].data.original.label')
                                            },
                                            "item2": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9pp53f2.components[0].components[2].data.original.label')
                                            },
                                            "item-j9pujymd": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9pp53f2.components[0].components[2].data.original.label')
                                            },
                                            "item-j9pujxu7": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9pp53f2.components[0].components[2].data.original.label')
                                            },
                                            "item-j9pujwrr": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9pp53f2.components[0].components[2].data.original.label')
                                            },
                                            "item-j9pujvmm": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9pp53f2.components[0].components[2].data.original.label')
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "align": "left",
                                        "margin": 10
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-txt": "1",
                                                "alpha-txth": "1",
                                                "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496",
                                                "txt": "#FFFFFF",
                                                "txth": "#DEDEDE"
                                            },
                                            "propertiesSource": {
                                                "fnt": "value",
                                                "txt": "value",
                                                "txth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.SiteButton",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 108,
                                            "height": 26,
                                            "x": 19,
                                            "y": 230,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 490,
                                "height": 252,
                                "x": 0,
                                "y": 252,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "mediaRef": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "title": "1.jpg",
                                            "uri": "5bfb6f_62b060a47ea543c5ae96065f5ee408d3~mv2.jpg",
                                            "description": "private/",
                                            "width": 720,
                                            "height": 719,
                                            "alt": "",
                                            "artist": {
                                                "id": "",
                                                "name": ""
                                            },
                                            "opacity": 0.74
                                        },
                                        "color": "#333333",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo",
                                        "mediaTransforms": {
                                            "scale": 1
                                        }
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9pugynf",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "mediaRef": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "1.jpg",
                                                "uri": "5bfb6f_62b060a47ea543c5ae96065f5ee408d3~mv2.jpg",
                                                "description": "private/",
                                                "width": 720,
                                                "height": 719,
                                                "alt": "",
                                                "artist": {
                                                    "id": "",
                                                    "name": ""
                                                },
                                                "opacity": 0.74
                                            },
                                            "color": "#333333",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9pugynf",
                                        "dataChangeBehaviors": []
                                    },
                                    "item2": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "mediaRef": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "2.jpg",
                                                "uri": "5bfb6f_02c96b59d32f478eb58f9e50e85c98cb~mv2.jpg",
                                                "description": "private/allMedia_picture",
                                                "width": 971,
                                                "height": 971,
                                                "alt": "",
                                                "artist": {
                                                    "id": "",
                                                    "name": ""
                                                },
                                                "opacity": 0.9
                                            },
                                            "color": "#000000",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-ja9fi9x1",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pujymd": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "mediaRef": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "5.jpg",
                                                "uri": "5bfb6f_1d6659fb81e7458a9f023de8ca8f0582~mv2.jpg",
                                                "description": "private/allMedia_picture",
                                                "width": 983,
                                                "height": 983,
                                                "alt": "",
                                                "artist": {
                                                    "id": "",
                                                    "name": ""
                                                },
                                                "opacity": 0.8
                                            },
                                            "color": "#333333",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9pun643",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pujxu7": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "mediaRef": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "4.jpg",
                                                "uri": "5bfb6f_92eb6689eec944ab9adc68db203d9c89~mv2_d_1675_1675_s_2.jpg",
                                                "description": "private/allMedia_picture",
                                                "width": 1675,
                                                "height": 1675,
                                                "alt": "",
                                                "artist": {
                                                    "id": "",
                                                    "name": ""
                                                },
                                                "opacity": 0.8
                                            },
                                            "color": "#333333",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9qqynxf",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pujwrr": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "mediaRef": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "10.jpg",
                                                "uri": "5bfb6f_74f9b842e9284da5aaf4bb634916b838~mv2.jpg",
                                                "description": "private/allMedia_picture",
                                                "width": 1005,
                                                "height": 1004,
                                                "alt": "",
                                                "artist": {
                                                    "id": "",
                                                    "name": ""
                                                },
                                                "opacity": 0.85
                                            },
                                            "color": "#333333",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9pumueh",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9pujvmm": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "mediaRef": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "3.jpg",
                                                "uri": "5bfb6f_9cbcc5472de4429c8d2db9608c22579a~mv2.jpg",
                                                "description": "private/allMedia_picture",
                                                "width": 1156,
                                                "height": 1156,
                                                "alt": "",
                                                "artist": {
                                                    "id": "",
                                                    "name": ""
                                                },
                                                "opacity": 0.85
                                            },
                                            "color": "#333333",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9pummro",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 283,
                                    "x": 0,
                                    "y": 566,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 980,
                        "height": 756,
                        "x": -2,
                        "y": 4846,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item2",
                            "item-j9pujymd",
                            "item-j9pujxu7",
                            "item-j9pujwrr",
                            "item-j9pujvmm"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "alignment": "center",
                        "gap": {
                            "vertical": 0,
                            "horizontal": 0
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 1698,
                            "x": 0,
                            "y": 8255,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9pp53f2"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 216,
                        "x": 0,
                        "y": 892
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_8",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                    "layout": {
                                        "width": 309,
                                        "height": 33,
                                        "x": 26,
                                        "y": 47,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_2\" style=\"font-size: 25px;\"><span style=\"color:#36B5CD\"><span style=\"font-size:25px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">01/</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 25px;\"><span style=\"color:#36B5CD\"><span style=\"font-size:25px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">01/</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9qtctce": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 25px;\"><span style=\"color:#36B5CD\"><span style=\"font-size:25px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">02/</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9qtcsjc": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 25px;\"><span style=\"color:#36B5CD\"><span style=\"font-size:25px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">03/</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9qtcrql": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 25px;\"><span style=\"color:#36B5CD\"><span style=\"font-size:25px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">04/</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 157,
                                            "height": 31,
                                            "x": 27,
                                            "y": 44,
                                            "scale": 0.92,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                    "layout": {
                                        "width": 289,
                                        "height": 76,
                                        "x": 26,
                                        "y": 88,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h4 class=\"font_2\" style=\"font-size: 25px; line-height: 1.5em;\"><span style=\"color:#FFFFFF\"><span style=\"font-size:25px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9qrom3r.components[0].components[1].data.overrides.item1.text')}</span></span></span></h4>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_2\" style=\"font-size: 25px; line-height: 1.5em;\"><span style=\"color:#FFFFFF\"><span style=\"font-size:25px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9qrom3r.components[0].components[1].data.overrides.item1.text')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9qtctce": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_2\" style=\"font-size: 25px; line-height: 1.5em;\"><span style=\"color:#FFFFFF\"><span style=\"font-size:25px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9qrom3r.components[0].components[1].data.overrides.item-j9qtctce.text')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9qtcsjc": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_2\" style=\"font-size: 25px; line-height: 1.5em;\"><span style=\"color:#FFFFFF\"><span style=\"font-size:25px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9qrom3r.components[0].components[1].data.overrides.item-j9qtcsjc.text')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9qtcrql": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_2\" style=\"font-size: 25px; line-height: 1.5em;\"><span style=\"color:#FFFFFF\"><span style=\"font-size:25px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9qrom3r.components[0].components[1].data.overrides.item-j9qtcrql.text')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 47
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 216,
                                            "height": 58,
                                            "x": 27,
                                            "y": 80,
                                            "scale": 0.76,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                    "layout": {
                                        "width": 268,
                                        "height": 124,
                                        "x": 27,
                                        "y": 173,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_2\" style=\"font-size: 14px; line-height: 1.7em;\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9qrom3r.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 14px; line-height: 1.7em;\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9qrom3r.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9qtctce": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 14px; line-height: 1.7em;\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9qrom3r.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9qtcsjc": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size: 14px; line-height: 1.7em;\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9qrom3r.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9qtcrql": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_2\" style=\"font-size:14px; line-height:1.7em\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">${i18n.translate('comp-j9qrom3r.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 124
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 246,
                                            "height": 96,
                                            "x": 27,
                                            "y": 149,
                                            "scale": 0.9333333333333333,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 400,
                                "height": 323,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#173981",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo",
                                        "mediaTransforms": {
                                            "scale": 1
                                        }
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9qruv6y",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#173981",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9qruv6y",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9qtctce": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#173981",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9qruv6y",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9qtcsjc": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#173981",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9qruv6y",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9qtcrql": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#173981",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9qruv6y",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 288,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 400,
                        "height": 1292,
                        "x": -2,
                        "y": 5729,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9qtctce",
                            "item-j9qtcsjc",
                            "item-j9qtcrql"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 0,
                            "horizontal": 0
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 1181,
                            "x": 0,
                            "y": 10048,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9qrom3r"
                },
                "preset": {
                    "rect": {
                        "width": 141,
                        "height": 362,
                        "x": 0,
                        "y": 1108
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_9",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 280,
                                        "height": 352,
                                        "x": 0,
                                        "y": 0,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "2.0",
                                                "isHidden": false
                                            },
                                            "title": "",
                                            "uri": "5bfb6f_60da908c04d84c888c704e939d95d1e5~mv2_d_2600_2600_s_4_2.jpg",
                                            "description": "",
                                            "width": 2600,
                                            "height": 2600,
                                            "alt": "",
                                            "crop": {
                                                "x": 422,
                                                "y": 0,
                                                "width": 2178,
                                                "height": 2600
                                            }
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_60da908c04d84c888c704e939d95d1e5~mv2_d_2600_2600_s_4_2.jpg",
                                                "description": "",
                                                "width": 2600,
                                                "height": 2600,
                                                "alt": "",
                                                "crop": {
                                                    "x": 422,
                                                    "y": 0,
                                                    "width": 2178,
                                                    "height": 2600
                                                }
                                            },
                                            "item-j9r5bvyk": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_38adb24765e54b69b31d9aeedee6c531~mv2_d_2600_2600_s_4_2.jpg",
                                                "description": "",
                                                "width": 2600,
                                                "height": 2600,
                                                "alt": "",
                                                "crop": {
                                                    "x": 370,
                                                    "y": 580,
                                                    "width": 1400,
                                                    "height": 1760
                                                }
                                            },
                                            "item-j9r5bv3y": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_23f1633dda4843898cb135d2cd225230~mv2_d_2600_2600_s_4_2.jpg",
                                                "description": "",
                                                "width": 2600,
                                                "height": 2600,
                                                "alt": ""
                                            },
                                            "item-j9r5bv33": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_b2bae2142f8b49bd936056f77a571e3e~mv2_d_2600_2600_s_4_2.jpg",
                                                "description": "",
                                                "width": 2600,
                                                "height": 2600,
                                                "alt": "",
                                                "crop": {
                                                    "x": 433,
                                                    "y": 272,
                                                    "width": 1618,
                                                    "height": 2034
                                                }
                                            },
                                            "item-j9r5bulg": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_c8c2eb69d0ca401784b52945725744ea~mv2_d_2600_2600_s_4_2.jpg",
                                                "description": "",
                                                "width": 2600,
                                                "height": 2600,
                                                "alt": ""
                                            },
                                            "item-j9r5dcsm": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_54f9e483e3ed450a84faa05d7a95ce95~mv2_d_2600_2600_s_4_2.jpg",
                                                "description": "",
                                                "width": 2600,
                                                "height": 2600,
                                                "alt": "",
                                                "crop": {
                                                    "x": 0,
                                                    "y": 0,
                                                    "width": 1802,
                                                    "height": 2487
                                                }
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {},
                                            "propertiesSource": {}
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WPhoto",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 299,
                                            "height": 352,
                                            "x": 10,
                                            "y": 10,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Container",
                                    "components": [
                                        {
                                            "type": "Component",
                                            "layout": {
                                                "width": 224,
                                                "height": 29,
                                                "x": 10,
                                                "y": 23,
                                                "scale": 1,
                                                "rotationInDegrees": 0,
                                                "fixedPosition": false
                                            },
                                            "componentType": "wysiwyg.viewer.components.WRichText",
                                            "data": {
                                                "type": "RepeatedData",
                                                "original": {
                                                    "type": "StyledText",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "1.0",
                                                        "isHidden": false
                                                    },
                                                    "text": `<h4 class=\"font_0\" style=\"font-size: 24px; text-align: center;\"><span style=\"font-size:24px\"><span style=\"color:#465E52\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">${i18n.translate('comp-j9quo3ml.components[0].components[1].components[0].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                    "linkList": []
                                                },
                                                "overrides": {
                                                    "item1": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<h4 class=\"font_0\" style=\"font-size: 24px; text-align: center;\"><span style=\"font-size:24px\"><span style=\"color:#465E52\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">${i18n.translate('comp-j9quo3ml.components[0].components[1].components[0].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    },
                                                    "item-j9r5bvyk": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<h4 class=\"font_0\" style=\"font-size: 24px; text-align: center;\"><span style=\"font-size:24px\"><span style=\"color:#465E52\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">${i18n.translate('comp-j9quo3ml.components[0].components[1].components[0].data.overrides.item-j9r5bvyk.text')}</span></span></span></span></h4>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    },
                                                    "item-j9r5bv3y": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<h4 class=\"font_0\" style=\"font-size: 24px; text-align: center;\"><span style=\"font-size:24px\"><span style=\"color:#465E52\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">${i18n.translate('comp-j9quo3ml.components[0].components[1].components[0].data.overrides.item-j9r5bv3y.text')}</span></span></span></span></h4>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    },
                                                    "item-j9r5bv33": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<h4 class=\"font_0\" style=\"font-size: 24px; text-align: center;\"><span style=\"font-size:24px\"><span style=\"color:#465E52\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">${i18n.translate('comp-j9quo3ml.components[0].components[1].components[0].data.overrides.item-j9r5bv33.text')}</span></span></span></span></h4>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    },
                                                    "item-j9r5bulg": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<h4 class=\"font_0\" style=\"font-size: 24px; text-align: center;\"><span style=\"font-size:24px\"><span style=\"color:#465E52\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">${i18n.translate('comp-j9quo3ml.components[0].components[1].components[0].data.overrides.item-j9r5bulg.text')}</span></span></span></span></h4>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    },
                                                    "item-j9r5dcsm": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<h4 class=\"font_0\" style=\"font-size: 24px; text-align: center;\"><span style=\"font-size:24px\"><span style=\"color:#465E52\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">${i18n.translate('comp-j9quo3ml.components[0].components[1].components[0].data.overrides.item-j9r5dcsm.text')}</span></span></span></span></h4>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    }
                                                }
                                            },
                                            "props": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            },
                                            "style": {
                                                "type": "TopLevelStyle",
                                                "metaData": {
                                                    "isPreset": true,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "style": {
                                                    "groups": {},
                                                    "properties": {
                                                        "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                        "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                        "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                        "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                        "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                        "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                        "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                        "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                        "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                        "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                        "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                                    },
                                                    "propertiesSource": {
                                                        "f0": "value",
                                                        "f1": "value",
                                                        "f10": "value",
                                                        "f2": "value",
                                                        "f3": "value",
                                                        "f4": "value",
                                                        "f5": "value",
                                                        "f6": "value",
                                                        "f7": "value",
                                                        "f8": "value",
                                                        "f9": "value"
                                                    }
                                                },
                                                "componentClassName": "",
                                                "pageId": "",
                                                "compId": "",
                                                "styleType": "custom",
                                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                            },
                                            "mobileStructure": {
                                                "componentType": "wysiwyg.viewer.components.WRichText",
                                                "type": "Component",
                                                "conversionData": {
                                                    "isCustomPreset": true,
                                                    "mobileProps": {
                                                        "type": "WRichTextProperties",
                                                        "metaData": {
                                                            "schemaVersion": "1.0",
                                                            "autoGenerated": false
                                                        },
                                                        "brightness": 1,
                                                        "packed": true
                                                    }
                                                },
                                                "layout": {
                                                    "width": 205,
                                                    "height": 32,
                                                    "x": 27,
                                                    "y": 19,
                                                    "scale": 1.0838709677419356,
                                                    "rotationInDegrees": 0,
                                                    "fixedPosition": false
                                                }
                                            }
                                        },
                                        {
                                            "type": "Component",
                                            "layout": {
                                                "width": 224,
                                                "height": 48,
                                                "x": 11,
                                                "y": 59,
                                                "scale": 1,
                                                "rotationInDegrees": 0,
                                                "fixedPosition": false
                                            },
                                            "componentType": "wysiwyg.viewer.components.WRichText",
                                            "data": {
                                                "type": "RepeatedData",
                                                "original": {
                                                    "type": "StyledText",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "1.0",
                                                        "isHidden": false
                                                    },
                                                    "text": `<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('repeatersfix_preset_text_line1')}</span></span></p>\n\n<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('repeatersfix_preset_text_line2')}</span></span></p>`,
                                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                    "linkList": []
                                                },
                                                "overrides": {
                                                    "item1": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('repeatersfix_preset_text_line1')}</span></span></p>\n\n<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('repeatersfix_preset_text_line2')}</span></span></p>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    },
                                                    "item-j9r5bvyk": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('repeatersfix_preset_text_line1')}</span></span></p>\n\n<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('repeatersfix_preset_text_line2')}</span></span></p>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    },
                                                    "item-j9r5bv3y": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('repeatersfix_preset_text_line1')}</span></span></p>\n\n<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('repeatersfix_preset_text_line2')}</span></span></p>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    },
                                                    "item-j9r5bv33": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('repeatersfix_preset_text_line1')}</span></span></p>\n\n<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('repeatersfix_preset_text_line2')}</span></span></p>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    },
                                                    "item-j9r5bulg": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('repeatersfix_preset_text_line1')}</span></span></p>\n\n<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('repeatersfix_preset_text_line2')}</span></span></p>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    },
                                                    "item-j9r5dcsm": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('repeatersfix_preset_text_line1')}</span></span></p>\n\n<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('repeatersfix_preset_text_line2')}</span></span></p>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    }
                                                }
                                            },
                                            "props": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": false,
                                                "minHeight": 48
                                            },
                                            "style": {
                                                "type": "TopLevelStyle",
                                                "metaData": {
                                                    "isPreset": true,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "style": {
                                                    "groups": {},
                                                    "properties": {
                                                        "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                        "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                        "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                        "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                        "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                        "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                        "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                        "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                        "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                        "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                        "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                                    },
                                                    "propertiesSource": {
                                                        "f0": "value",
                                                        "f1": "value",
                                                        "f10": "value",
                                                        "f2": "value",
                                                        "f3": "value",
                                                        "f4": "value",
                                                        "f5": "value",
                                                        "f6": "value",
                                                        "f7": "value",
                                                        "f8": "value",
                                                        "f9": "value"
                                                    }
                                                },
                                                "componentClassName": "",
                                                "pageId": "",
                                                "compId": "",
                                                "styleType": "custom",
                                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                            },
                                            "mobileStructure": {
                                                "componentType": "wysiwyg.viewer.components.WRichText",
                                                "type": "Component",
                                                "conversionData": {
                                                    "isCustomPreset": true,
                                                    "mobileProps": {
                                                        "type": "WRichTextProperties",
                                                        "metaData": {
                                                            "schemaVersion": "1.0",
                                                            "autoGenerated": false
                                                        },
                                                        "brightness": 1,
                                                        "packed": true
                                                    }
                                                },
                                                "layout": {
                                                    "width": 205,
                                                    "height": 38,
                                                    "x": 27,
                                                    "y": 59,
                                                    "scale": 1.0838709677419354,
                                                    "rotationInDegrees": 0,
                                                    "fixedPosition": false
                                                }
                                            }
                                        }
                                    ],
                                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                                    "layout": {
                                        "width": 248,
                                        "height": 117,
                                        "x": 17,
                                        "y": 280,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "mobile.core.components.Container",
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-bg": "1",
                                                "alpha-brd": "1",
                                                "bg": "#FFFFFF",
                                                "boxShadowToggleOn-shd": "false",
                                                "brd": "#8BAA95",
                                                "brw": "1",
                                                "rd": "0px",
                                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                            },
                                            "propertiesSource": {
                                                "bg": "value",
                                                "brd": "value",
                                                "brw": "value",
                                                "rd": "value",
                                                "shd": "value"
                                            }
                                        },
                                        "componentClassName": "mobile.core.components.Container",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "mobile.core.components.Container",
                                        "type": "Container",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 259,
                                            "height": 115,
                                            "x": 30,
                                            "y": 314,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 280,
                                "height": 397,
                                "x": 310,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#FFFFFF",
                                        "colorOpacity": 0,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 1,
                                        "showOverlayForMediaType": "WixVideo"
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9r56st2",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 0,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 1,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9r56st2",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9r5bvyk": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 0,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 1,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9r56st2",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9r5bv3y": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 0,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 1,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9r56st2",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9r5bv33": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 0,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 1,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9r56st2",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9r5bulg": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 0,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 1,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9r56st2",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9r5dcsm": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 0,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 1,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9r56st2",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 456,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 590,
                        "height": 1251,
                        "x": 8,
                        "y": 7066,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9r5bvyk",
                            "item-j9r5bv3y",
                            "item-j9r5bv33",
                            "item-j9r5bulg",
                            "item-j9r5dcsm"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "alignment": "center",
                        "gap": {
                            "vertical": 30,
                            "horizontal": 30
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 2886,
                            "x": 0,
                            "y": 11282,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9quo3ml"
                },
                "preset": {
                    "rect": {
                        "width": 183,
                        "height": 362,
                        "x": 141,
                        "y": 1108
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_10",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 661,
                                        "height": 473,
                                        "x": 320,
                                        "y": 0,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "2.0",
                                                "isHidden": false
                                            },
                                            "title": "",
                                            "uri": "5bfb6f_ea027c006780413d9535b7d12c43bf87~mv2_d_2448_2448_s_4_2.jpg",
                                            "description": "",
                                            "width": 2448,
                                            "height": 2448,
                                            "alt": "",
                                            "crop": {
                                                "x": 0,
                                                "y": 648,
                                                "width": 2448,
                                                "height": 1746
                                            }
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_ea027c006780413d9535b7d12c43bf87~mv2_d_2448_2448_s_4_2.jpg",
                                                "description": "",
                                                "width": 2448,
                                                "height": 2448,
                                                "alt": "",
                                                "crop": {
                                                    "x": 0,
                                                    "y": 648,
                                                    "width": 2448,
                                                    "height": 1746
                                                }
                                            },
                                            "item-j9r6lf43": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_33ab1f49b4b342bf831200b069faf17f~mv2_d_1848_1848_s_2.jpg",
                                                "description": "",
                                                "width": 1848,
                                                "height": 1848,
                                                "alt": ""
                                            },
                                            "item-j9r6ldtk": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_a70f262018f64aaba6d7820695923550~mv2_d_1600_1600_s_2.jpg",
                                                "description": "",
                                                "width": 1600,
                                                "height": 1600,
                                                "alt": "",
                                                "crop": {
                                                    "x": 0,
                                                    "y": 15,
                                                    "width": 1600,
                                                    "height": 1145
                                                }
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {},
                                            "propertiesSource": {}
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WPhoto",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 325,
                                            "height": 274,
                                            "x": -2,
                                            "y": 398,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                                    "layout": {
                                        "width": 256,
                                        "height": 58,
                                        "x": -74,
                                        "y": 158,
                                        "scale": 1,
                                        "rotationInDegrees": 270,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "LinkableButton",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "label": i18n.translate('comp-j9r5kw5w.components[0].components[1].data.overrides.item1.label')
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9r5kw5w.components[0].components[1].data.overrides.item1.label')
                                            },
                                            "item-j9r6lf43": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9r5kw5w.components[0].components[1].data.overrides.item-j9r6lf43.label')
                                            },
                                            "item-j9r6ldtk": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9r5kw5w.components[0].components[1].data.overrides.item-j9r6ldtk.label')
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "align": "left",
                                        "margin": 0
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-txt": "1",
                                                "alpha-txth": "1",
                                                "fnt": "normal normal normal 33px/1.4em futura-lt-w01-book",
                                                "txt": "#333333",
                                                "txth": "#858585"
                                            },
                                            "propertiesSource": {
                                                "fnt": "value",
                                                "txt": "value",
                                                "txth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.SiteButton",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 207,
                                            "height": 41,
                                            "x": -47,
                                            "y": 107,
                                            "scale": 1.05,
                                            "rotationInDegrees": 270,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle",
                                    "layout": {
                                        "width": 10,
                                        "height": 10,
                                        "x": 55,
                                        "y": 330,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "SvgShape",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            }
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "SvgShape",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                }
                                            },
                                            "item-j9r6lf43": {
                                                "type": "SvgShape",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                }
                                            },
                                            "item-j9r6ldtk": {
                                                "type": "SvgShape",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                }
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "SvgShapeProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "maintainAspectRatio": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-fillcolor": "1",
                                                "alpha-stroke": "1",
                                                "fillcolor": "#333333",
                                                "stroke": "#ED1566",
                                                "strokewidth": "0px"
                                            },
                                            "propertiesSource": {
                                                "fillcolor": "value",
                                                "stroke": "value",
                                                "strokewidth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 9,
                                            "height": 9,
                                            "x": 59,
                                            "y": 245,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 190,
                                        "height": 137,
                                        "x": 110,
                                        "y": 317,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:18px; line-height:1.5em\"><span style=\"font-size:18px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"color:#333333\">${i18n.translate('comp-j9phsrjg.components[0].components[1].data.overrides.item1.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px; line-height:1.5em\"><span style=\"font-size:18px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"color:#333333\">${i18n.translate('comp-j9phsrjg.components[0].components[1].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9r6lf43": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size: 18px; line-height: 1.5em;\"><span style=\"font-size:18px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"color:#333333\">${i18n.translate('comp-j9phsrjg.components[0].components[1].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9r6ldtk": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size: 18px; line-height: 1.5em;\"><span style=\"font-size:18px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"color:#333333\">${i18n.translate('comp-j9phsrjg.components[0].components[1].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 137
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 161,
                                            "height": 92,
                                            "x": 109,
                                            "y": 267,
                                            "scale": 0.8426332288401254,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 59,
                                        "height": 5,
                                        "x": 31,
                                        "y": 382,
                                        "scale": 1,
                                        "rotationInDegrees": 90,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                    "props": {
                                        "type": "FiveGridLineProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "fullScreenModeOn": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-brd": "1",
                                                "brd": "#000000",
                                                "lnw": "1px"
                                            },
                                            "propertiesSource": {
                                                "brd": "value",
                                                "lnw": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 59,
                                            "height": 5,
                                            "x": 35,
                                            "y": 303,
                                            "scale": 1,
                                            "rotationInDegrees": 90,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 980,
                                "height": 474,
                                "x": 0,
                                "y": 504,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#B5F3DA",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo"
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-ja9fakta",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#B5F3DA",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-ja9fakta",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9r6lf43": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#B5F3DA",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-ja9faktd",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9r6ldtk": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#B5F3DA",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-ja9faktf",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 325,
                                    "height": 672,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 980,
                        "height": 1482,
                        "x": 0,
                        "y": 8405,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9r6lf43",
                            "item-j9r6ldtk"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 30,
                            "horizontal": 30
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true,
                            "mobileProps": {
                                "type": "CardsLayoutProperties",
                                "metaData": {
                                    "schemaVersion": "1.0",
                                    "autoGenerated": false
                                },
                                "alignment": "left",
                                "gap": {
                                    "vertical": 10,
                                    "horizontal": 10
                                }
                            }
                        },
                        "layout": {
                            "width": 325,
                            "height": 2036,
                            "x": 0,
                            "y": 14299,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9r5kw5w"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 404,
                        "x": 0,
                        "y": 1470
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_11",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 311,
                                        "height": 388,
                                        "x": 0,
                                        "y": 0,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "2.0",
                                                "isHidden": false
                                            },
                                            "title": "",
                                            "uri": "265db2f99b3143548289b5d053da3837.jpg",
                                            "description": "",
                                            "width": 2890,
                                            "height": 3648,
                                            "alt": "",
                                            "crop": {
                                                "x": 578,
                                                "y": 635,
                                                "width": 1821,
                                                "height": 2406
                                            }
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "265db2f99b3143548289b5d053da3837.jpg",
                                                "description": "",
                                                "width": 2890,
                                                "height": 3648,
                                                "alt": "",
                                                "crop": {
                                                    "x": 578,
                                                    "y": 635,
                                                    "width": 1821,
                                                    "height": 2406
                                                }
                                            },
                                            "item-j9r9uz7e": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "c2836e732dcb4198b34b72cf2d834162.jpg",
                                                "description": "",
                                                "width": 4272,
                                                "height": 2848,
                                                "alt": "",
                                                "crop": {
                                                    "x": 521,
                                                    "y": 0,
                                                    "width": 2283,
                                                    "height": 2848
                                                }
                                            },
                                            "item-j9r9uxns": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "134ea37dd1ed4dd282e0610808ba928a.jpg",
                                                "description": "",
                                                "width": 3000,
                                                "height": 1995,
                                                "alt": "",
                                                "crop": {
                                                    "x": 1039,
                                                    "y": 0,
                                                    "width": 1600,
                                                    "height": 1995
                                                }
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {},
                                            "propertiesSource": {}
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WPhoto",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 320,
                                            "height": 369,
                                            "x": 0,
                                            "y": 0,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                    "layout": {
                                        "width": 135,
                                        "height": 34,
                                        "x": 88,
                                        "y": 369,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "LinkableButton",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "label": i18n.translate('comp-j9r85mb7.components[0].components[1].data.overrides.item1.label')
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9r85mb7.components[0].components[1].data.overrides.item1.label')
                                            },
                                            "item-j9r9uz7e": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9r85mb7.components[0].components[1].data.overrides.item-j9r9uz7e.label')
                                            },
                                            "item-j9r9uxns": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9r85mb7.components[0].components[1].data.overrides.item-j9r9uxns.label')
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "align": "center",
                                        "margin": 0
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-bg": "1",
                                                "alpha-bgh": "1",
                                                "alpha-brd": "1",
                                                "alpha-brdh": "0",
                                                "alpha-txt": "1",
                                                "alpha-txth": "1",
                                                "bg": "#7ECCC5",
                                                "bgh": "#F2BF5E",
                                                "boxShadowToggleOn-shd": "false",
                                                "brd": "#545454",
                                                "brdh": "#F9F9F9",
                                                "brw": "0",
                                                "fnt": "normal normal normal 17px/1.4em times+new+roman",
                                                "rd": "0px",
                                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                                "txt": "#FFFFFF",
                                                "txth": "#FFFFFF"
                                            },
                                            "propertiesSource": {
                                                "alpha-brdh": "value",
                                                "bg": "value",
                                                "bgh": "value",
                                                "brd": "value",
                                                "brdh": "value",
                                                "brw": "value",
                                                "fnt": "value",
                                                "rd": "value",
                                                "shd": "value",
                                                "txt": "value",
                                                "txth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.SiteButton",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 140,
                                            "height": 34,
                                            "x": 90,
                                            "y": 351,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "svgshape.v2.Svg_65682bba59d04820a48bd42480e76fe5",
                                    "layout": {
                                        "width": 15,
                                        "height": 15,
                                        "x": 202,
                                        "y": 594,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "SvgShape",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            }
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "SvgShape",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                }
                                            },
                                            "item-j9r9uz7e": {
                                                "type": "SvgShape",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                }
                                            },
                                            "item-j9r9uxns": {
                                                "type": "SvgShape",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                }
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "SvgShapeProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "maintainAspectRatio": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-fillcolor": "1",
                                                "alpha-stroke": "1",
                                                "fillcolor": "#000000",
                                                "strokewidth": "0"
                                            },
                                            "propertiesSource": {
                                                "fillcolor": "value",
                                                "stroke": "value",
                                                "strokewidth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "svgshape.v2.Svg_65682bba59d04820a48bd42480e76fe5"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 15,
                                            "height": 15,
                                            "x": 197,
                                            "y": 579,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 219,
                                        "height": 91,
                                        "x": 48,
                                        "y": 493,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_4\" style=\"font-size: 15px; line-height: 1.6em; text-align: center;\"><span style=\"color:#545454\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('comp-j9phsrjg.components[0].components[1].data.overrides.item1.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_4\" style=\"font-size: 15px; line-height: 1.6em; text-align: center;\"><span style=\"color:#545454\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('comp-j9phsrjg.components[0].components[1].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9r9uz7e": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_4\" style=\"font-size: 15px; line-height: 1.6em; text-align: center;\"><span style=\"color:#545454\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('comp-j9phsrjg.components[0].components[1].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9r9uxns": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_4\" style=\"font-size: 15px; line-height: 1.6em; text-align: center;\"><span style=\"color:#545454\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">${i18n.translate('comp-j9phsrjg.components[0].components[1].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 91
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 220,
                                            "height": 72,
                                            "x": 50,
                                            "y": 473,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 304,
                                        "height": 36,
                                        "x": 3,
                                        "y": 444,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h4 class=\"font_4\" style=\"font-size:30px; text-align:center\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#000000\"><span style=\"font-size:30px\">${i18n.translate('comp-j9r85mb7.components[0].components[4].data.overrides.item1.text')}</span></span></span></h4>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:30px; text-align:center\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#000000\"><span style=\"font-size:30px\">${i18n.translate('comp-j9r85mb7.components[0].components[4].data.overrides.item1.text')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9r9uz7e": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:30px; text-align:center\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#000000\"><span style=\"font-size:30px\">${i18n.translate('comp-j9r85mb7.components[0].components[4].data.overrides.item-j9r9uz7e.text')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9r9uxns": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:30px; text-align:center\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#000000\"><span style=\"font-size:30px\">${i18n.translate('comp-j9r85mb7.components[0].components[4].data.overrides.item-j9r9uxns.text')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 300,
                                            "height": 36,
                                            "x": 10,
                                            "y": 425,
                                            "scale": 1.0944114398437503,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                                    "layout": {
                                        "width": 98,
                                        "height": 24,
                                        "x": 101,
                                        "y": 588,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "LinkableButton",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "label": i18n.translate('comp-j9r85mb7.components[0].components[5].data.original.label')
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9r85mb7.components[0].components[5].data.original.label')
                                            },
                                            "item-j9r9uz7e": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9r85mb7.components[0].components[5].data.original.label')
                                            },
                                            "item-j9r9uxns": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9r85mb7.components[0].components[5].data.original.label')
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "align": "center",
                                        "margin": 0
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-txt": "1",
                                                "alpha-txth": "1",
                                                "fnt": "normal normal normal 17px/1.4em times+new+roman",
                                                "txt": "#000000",
                                                "txth": "#787878"
                                            },
                                            "propertiesSource": {
                                                "fnt": "value",
                                                "txt": "value",
                                                "txth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.SiteButton",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 98,
                                            "height": 24,
                                            "x": 100,
                                            "y": 573,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 311,
                                "height": 650,
                                "x": 334,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#FFFFFF",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo"
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-jab3cj9d",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-jab3cj9d",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9r9uz7e": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-jab3cj9h",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9r9uxns": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-jab3cj9i",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 628,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 980,
                        "height": 650,
                        "x": 0,
                        "y": 9983,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9r9uz7e",
                            "item-j9r9uxns"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 23,
                            "horizontal": 23
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 1930,
                            "x": 0,
                            "y": 16409,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9r85mb7"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 197,
                        "x": 0,
                        "y": 1874
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_12",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 498,
                                        "height": 43,
                                        "x": 61,
                                        "y": 53,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h4 class=\"font_6\" style=\"font-size: 35px;\"><span style=\"font-size:35px\"><span style=\"font-weight:bold\"><span style=\"color:#FFFFFF\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">${i18n.translate('comp-j9s7doxv.components[0].components[0].data.overrides.item1.text')}</span></span></span></span></span></h4>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_6\" style=\"font-size: 35px;\"><span style=\"font-size:35px\"><span style=\"font-weight:bold\"><span style=\"color:#FFFFFF\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">${i18n.translate('comp-j9s7doxv.components[0].components[0].data.overrides.item1.text')}</span></span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sa1km3": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_6\" style=\"font-size: 35px;\"><span style=\"font-size:35px\"><span style=\"font-weight:bold\"><span style=\"color:#FFFFFF\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">${i18n.translate('comp-j9s7doxv.components[0].components[0].data.overrides.item-j9sa1km3.text')}</span></span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sa1jjv": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_6\" style=\"font-size: 35px;\"><span style=\"font-size:35px\"><span style=\"font-weight:bold\"><span style=\"color:#FFFFFF\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">${i18n.translate('comp-j9s7doxv.components[0].components[0].data.overrides.item-j9sa1jjv.text')}</span></span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 28,
                                            "x": 21,
                                            "y": 42,
                                            "scale": 0.7857142857142857,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 139,
                                        "height": 94,
                                        "x": 61,
                                        "y": 124,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"font-size:16px\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\"><span style=\"color:#FFFFFF\">${i18n.translate('repeaters_fix_Mondays')}</span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"font-size:16px\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\"><span style=\"color:#FFFFFF\">${i18n.translate('repeaters_fix_Mondays_time')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"font-size:16px\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\"><span style=\"color:#FFFFFF\">${i18n.translate('repeaters_fix_Mondays')}</span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"font-size:16px\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\"><span style=\"color:#FFFFFF\">${i18n.translate('repeaters_fix_Mondays_time')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sa1km3": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"font-size:16px\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\"><span style=\"color:#FFFFFF\">${i18n.translate('repeaters_fix_Tuesdays')}</span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"font-size:16px\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\"><span style=\"color:#FFFFFF\">${i18n.translate('repeaters_fix_Tuesdays_time')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sa1jjv": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"font-size:16px\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\"><span style=\"color:#FFFFFF\">${i18n.translate('repeaters_fix_Saturdays')}</span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"font-size:16px\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\"><span style=\"color:#FFFFFF\">${i18n.translate('repeaters_fix_Saturdays_time')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 94
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 52,
                                            "x": 21,
                                            "y": 80,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine",
                                    "layout": {
                                        "width": 5,
                                        "height": 180,
                                        "x": 212,
                                        "y": 128,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.VerticalLine",
                                    "mobileHints": {
                                        "type": "MobileHints",
                                        "hidden": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-brd": "0.5",
                                                "brd": "#FFFFFF",
                                                "lnw": "1px"
                                            },
                                            "propertiesSource": {
                                                "alpha-brd": "value",
                                                "brd": "value",
                                                "lnw": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 640,
                                        "height": 215,
                                        "x": 245,
                                        "y": 121,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_8\" style=\"font-size:14px; line-height:1.7em\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\">${i18n.translate('comp-j9s7doxv.components[0].components[3].data.overrides.item1.text')}</span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size:14px; line-height:1.7em\">&nbsp;</p>\n\n<p class=\"font_8\" style=\"font-size:14px; line-height:1.7em\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\">${i18n.translate('comp-j9s7doxv.components[0].components[3].data.overrides.item1.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:14px; line-height:1.7em\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\">${i18n.translate('comp-j9s7doxv.components[0].components[3].data.overrides.item1.text')}</span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size:14px; line-height:1.7em\">&nbsp;</p>\n\n<p class=\"font_8\" style=\"font-size:14px; line-height:1.7em\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\">${i18n.translate('comp-j9s7doxv.components[0].components[3].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sa1km3": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:14px; line-height:1.7em\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\">${i18n.translate('comp-j9s7doxv.components[0].components[3].data.overrides.item1.text')}</span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size:14px; line-height:1.7em\">&nbsp;</p>\n\n<p class=\"font_8\" style=\"font-size:14px; line-height:1.7em\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\">${i18n.translate('comp-j9s7doxv.components[0].components[3].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sa1jjv": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:14px; line-height:1.7em\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\">${i18n.translate('comp-j9s7doxv.components[0].components[3].data.overrides.item1.text')}</span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size:14px; line-height:1.7em\">&nbsp;</p>\n\n<p class=\"font_8\" style=\"font-size:14px; line-height:1.7em\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:americantypwrteritcw01--731025,americantypwrteritcw02--737091,serif\">${i18n.translate('comp-j9s7doxv.components[0].components[3].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 215
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 268,
                                            "height": 407,
                                            "x": 21,
                                            "y": 145,
                                            "scale": 0.9333333333333333,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 980,
                                "height": 367,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#173981",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo",
                                        "mediaTransforms": {
                                            "scale": 1
                                        }
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9s7eaxw",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#173981",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9s7eaxw",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9sa1km3": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#173981",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9s7eaxw",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9sa1jjv": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#173981",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9s7eaxw",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 600,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 980,
                        "height": 1101,
                        "x": 0,
                        "y": 10739,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9sa1km3",
                            "item-j9sa1jjv"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 0,
                            "horizontal": 0
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 1800,
                            "x": -1,
                            "y": 18420,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9s7doxv"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 354,
                        "x": 0,
                        "y": 2071
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_13",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 150,
                                        "height": 150,
                                        "x": 17,
                                        "y": 28,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "2.0",
                                                "isHidden": false
                                            },
                                            "title": "",
                                            "uri": "24b04690d5dc436b91637be29f8e047d.jpg",
                                            "description": "",
                                            "width": 3456,
                                            "height": 2304,
                                            "alt": "",
                                            "crop": {
                                                "x": 649,
                                                "y": 190,
                                                "width": 1908,
                                                "height": 1913
                                            }
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "24b04690d5dc436b91637be29f8e047d.jpg",
                                                "description": "",
                                                "width": 3456,
                                                "height": 2304,
                                                "alt": "",
                                                "crop": {
                                                    "x": 649,
                                                    "y": 190,
                                                    "width": 1908,
                                                    "height": 1913
                                                }
                                            },
                                            "item-j9sbjmlt": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "a89e294f3653f56517e4eb5e5eddb666.png",
                                                "description": "",
                                                "width": 687,
                                                "height": 458,
                                                "alt": "",
                                                "crop": {
                                                    "x": 0,
                                                    "y": 0,
                                                    "width": 458,
                                                    "height": 458
                                                }
                                            },
                                            "item-j9sbjizh": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "b7768e84bd144332a193310b0bbdbb2d.jpg",
                                                "description": "",
                                                "width": 1280,
                                                "height": 720,
                                                "alt": "",
                                                "crop": {
                                                    "x": 182,
                                                    "y": 0,
                                                    "width": 719,
                                                    "height": 720
                                                }
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {},
                                            "propertiesSource": {}
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WPhoto",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 280,
                                            "x": 20,
                                            "y": 84,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 304,
                                        "height": 37,
                                        "x": 202,
                                        "y": 38,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h4 class=\"font_4\" style=\"font-size:30px\"><span style=\"font-size:30px\"><span style=\"color:#000000\"><span style=\"font-family:museo-w01-700,serif\">${i18n.translate('comp-j9sa4qqq.components[0].components[1].data.overrides.item1.text')}</span></span></span></h4>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:30px\"><span style=\"font-size:30px\"><span style=\"color:#000000\"><span style=\"font-family:museo-w01-700,serif\">${i18n.translate('comp-j9sa4qqq.components[0].components[1].data.overrides.item1.text')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjmlt": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:30px\"><span style=\"font-size:30px\"><span style=\"color:#000000\"><span style=\"font-family:museo-w01-700,serif\">${i18n.translate('listsandgrids_preset_fluffy_label')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjizh": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:30px\"><span style=\"font-size:30px\"><span style=\"color:#000000\"><span style=\"font-family:museo-w01-700,serif\">${i18n.translate('listsandgrids_preset_ginger_label')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 26,
                                            "x": 20,
                                            "y": 20,
                                            "scale": 0.7758333333333333,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 632,
                                        "height": 17,
                                        "x": 202,
                                        "y": 81,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:14px\"><span style=\"color:#545454\">${i18n.translate('comp-j9sa4qqq.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:14px\"><span style=\"color:#545454\">${i18n.translate('comp-j9sa4qqq.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjmlt": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:14px\"><span style=\"color:#545454\">${i18n.translate('comp-j9sa4qqq.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjizh": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:14px\"><span style=\"color:#545454\">${i18n.translate('comp-j9sa4qqq.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 17,
                                            "x": 20,
                                            "y": 51,
                                            "scale": 0.9333333333333333,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 103,
                                        "height": 22,
                                        "x": 202,
                                        "y": 118,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[3].data.overrides.item1.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[3].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjmlt": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[3].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjizh": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[3].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 18
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 130,
                                            "height": 22,
                                            "x": 20,
                                            "y": 388,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 103,
                                        "height": 22,
                                        "x": 321,
                                        "y": 118,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[4].data.overrides.item1.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[4].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjmlt": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[4].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjizh": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[4].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 18
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 71,
                                            "height": 22,
                                            "x": 129,
                                            "y": 388,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 103,
                                        "height": 22,
                                        "x": 434,
                                        "y": 118,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[5].data.original.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[5].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjmlt": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[5].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjizh": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[5].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 18
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 66,
                                            "height": 22,
                                            "x": 239,
                                            "y": 388,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 103,
                                        "height": 22,
                                        "x": 558,
                                        "y": 118,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[6].data.original.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[6].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjmlt": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[6].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjizh": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[6].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 18
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 109,
                                            "height": 22,
                                            "x": 133,
                                            "y": 467,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 103,
                                        "height": 22,
                                        "x": 697,
                                        "y": 118,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[7].data.original.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[7].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjmlt": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[7].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjizh": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"color:#000000\"><span style=\"font-size:18px\">${i18n.translate('comp-j9sa4qqq.components[0].components[7].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 18
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 120,
                                            "height": 22,
                                            "x": 20,
                                            "y": 465,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 103,
                                        "height": 18,
                                        "x": 698,
                                        "y": 150,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-j9sa4qqq.components[0].components[8].data.original.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-j9sa4qqq.components[0].components[8].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjmlt": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-j9sa4qqq.components[0].components[8].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjizh": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-j9sa4qqq.components[0].components[8].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 18
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 101,
                                            "height": 17,
                                            "x": 20,
                                            "y": 497,
                                            "scale": 0.9333333333333333,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 103,
                                        "height": 18,
                                        "x": 558,
                                        "y": 150,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-j9sa4qqq.components[0].components[9].data.original.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-j9sa4qqq.components[0].components[9].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjmlt": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-j9sa4qqq.components[0].components[9].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjizh": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('repeaters_fix_male')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 18
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 120,
                                            "height": 17,
                                            "x": 133,
                                            "y": 499,
                                            "scale": 0.9333333333333333,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 103,
                                        "height": 18,
                                        "x": 434,
                                        "y": 150,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-j9sa4qqq.components[0].components[10].data.original.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-j9sa4qqq.components[0].components[10].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjmlt": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-j9sa4qqq.components[0].components[10].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjizh": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-j9sa4qqq.components[0].components[10].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 18
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 66,
                                            "height": 17,
                                            "x": 239,
                                            "y": 420,
                                            "scale": 0.9333333333333333,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 103,
                                        "height": 18,
                                        "x": 321,
                                        "y": 150,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-j9sa4qqq.components[0].components[11].data.original.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-j9sa4qqq.components[0].components[11].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjmlt": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('comp-j9sa4qqq.components[0].components[11].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjizh": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('repeaters_fix_Orange')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 18
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 71,
                                            "height": 17,
                                            "x": 129,
                                            "y": 420,
                                            "scale": 0.9333333333333333,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 103,
                                        "height": 18,
                                        "x": 202,
                                        "y": 150,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('repeaters_fix_mixed')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('repeaters_fix_mixed')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjmlt": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('repeaters_fix_mixed')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sbjizh": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:14px\"><span style=\"font-size:14px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#000000\">${i18n.translate('repeaters_fix_mixed')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 18
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 71,
                                            "height": 17,
                                            "x": 20,
                                            "y": 420,
                                            "scale": 0.9333333333333333,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                    "layout": {
                                        "width": 140,
                                        "height": 45,
                                        "x": 810,
                                        "y": 120,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "LinkableButton",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "label": i18n.translate('comp-j9sa4qqq.components[0].components[13].data.original.label')
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9sa4qqq.components[0].components[13].data.original.label')
                                            },
                                            "item-j9sbjmlt": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9sa4qqq.components[0].components[13].data.original.label')
                                            },
                                            "item-j9sbjizh": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9sa4qqq.components[0].components[13].data.original.label')
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "align": "center",
                                        "margin": 0
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-bg": "1",
                                                "alpha-bgh": "1",
                                                "alpha-brd": "1",
                                                "alpha-brdh": "0",
                                                "alpha-txt": "1",
                                                "alpha-txth": "1",
                                                "bg": "#FF6161",
                                                "bgh": "#333333",
                                                "boxShadowToggleOn-shd": "false",
                                                "brd": "#545454",
                                                "brdh": "#F9F9F9",
                                                "brw": "0",
                                                "fnt": "normal normal normal 14px/1.4em museo-w01-700",
                                                "rd": "0px",
                                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                                "txt": "#FFFFFF",
                                                "txth": "#FFFFFF"
                                            },
                                            "propertiesSource": {
                                                "alpha-brdh": "value",
                                                "bg": "value",
                                                "bgh": "value",
                                                "brd": "value",
                                                "brdh": "value",
                                                "brw": "value",
                                                "fnt": "value",
                                                "rd": "value",
                                                "shd": "value",
                                                "txt": "value",
                                                "txth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.SiteButton",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 140,
                                            "height": 45,
                                            "x": 20,
                                            "y": 542,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 939,
                                        "height": 5,
                                        "x": 16,
                                        "y": 202,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                    "props": {
                                        "type": "FiveGridLineProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "fullScreenModeOn": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-brd": "1",
                                                "brd": "#333333",
                                                "lnw": "1px"
                                            },
                                            "propertiesSource": {
                                                "brd": "value",
                                                "lnw": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 5,
                                            "x": 19,
                                            "y": 619,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 980,
                                "height": 222,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#FFFFFF",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo",
                                        "mediaTransforms": {
                                            "scale": 1
                                        }
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9sa8l0h",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sa8l0h",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9sbjmlt": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sa8l0h",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9sbjizh": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sa8l0h",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 641,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 980,
                        "height": 666,
                        "x": 0,
                        "y": 11925,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9sbjmlt",
                            "item-j9sbjizh"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 0,
                            "horizontal": 0
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 1923,
                            "x": 1,
                            "y": 20301,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9sa4qqq"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 255,
                        "x": 0,
                        "y": 2425
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_14",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                    "layout": {
                                        "width": 492,
                                        "height": 129,
                                        "x": 244,
                                        "y": 42,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h4 class=\"font_2\" style=\"font-size:80px; text-align:center\"><span style=\"color:#545454\"><span style=\"letter-spacing:0.05em\"><span style=\"font-size:80px\"><span style=\"font-family:belinda-w00-regular,script\">${i18n.translate('comp-j9o8vzqi.components[0].components[0].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_2\" style=\"font-size:80px; text-align:center\"><span style=\"color:#545454\"><span style=\"letter-spacing:0.05em\"><span style=\"font-size:80px\"><span style=\"font-family:belinda-w00-regular,script\">${i18n.translate('comp-j9o8vzqi.components[0].components[0].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item2": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_2\" style=\"font-size: 80px; text-align: center;\"><span style=\"color:#545454\"><span style=\"letter-spacing:0.05em\"><span style=\"font-size:80px\"><span style=\"font-family:belinda-w00-regular,script\">${i18n.translate('comp-j9o8vzqi.components[0].components[0].data.overrides.item2.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item3": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_2\" style=\"font-size: 80px; text-align: center;\"><span style=\"color:#545454\"><span style=\"letter-spacing:0.05em\"><span style=\"font-size:80px\"><span style=\"font-family:belinda-w00-regular,script\">${i18n.translate('comp-j9o8vzqi.components[0].components[0].data.overrides.item3.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9oa3rji": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_2\" style=\"font-size: 80px; text-align: center;\"><span style=\"color:#545454\"><span style=\"letter-spacing:0.05em\"><span style=\"font-size:80px\"><span style=\"font-family:belinda-w00-regular,script\">${i18n.translate('comp-j9o8vzqi.components[0].components[0].data.overrides.item-j9oa3rji.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 76,
                                            "x": 20,
                                            "y": 50,
                                            "scale": 1.0745130500284095,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                                    "layout": {
                                        "width": 142,
                                        "height": 22,
                                        "x": 419,
                                        "y": 168,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "LinkableButton",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "label": i18n.translate('comp-j9o8vzqi.components[0].components[1].data.overrides.item1.label')
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9o8vzqi.components[0].components[1].data.overrides.item1.label')
                                            },
                                            "item2": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9o8vzqi.components[0].components[1].data.overrides.item1.label')
                                            },
                                            "item3": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9o8vzqi.components[0].components[1].data.overrides.item1.label')
                                            },
                                            "item-j9oa3rji": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9o8vzqi.components[0].components[1].data.overrides.item1.label')
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "align": "center",
                                        "margin": 0
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-txt": "1",
                                                "alpha-txth": "1",
                                                "fnt": "normal normal normal 16px/1.4em open+sans",
                                                "txt": "#333333",
                                                "txth": "#858585"
                                            },
                                            "propertiesSource": {
                                                "fnt": "value",
                                                "txt": "value",
                                                "txth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.SiteButton",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 135,
                                            "height": 27,
                                            "x": 92,
                                            "y": 125,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                    "layout": {
                                        "width": 87,
                                        "height": 5,
                                        "x": 448,
                                        "y": 191,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                    "props": {
                                        "type": "FiveGridLineProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "fullScreenModeOn": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-brd": "1",
                                                "brd": "#545454",
                                                "lnw": "3"
                                            },
                                            "propertiesSource": {
                                                "brd": "value",
                                                "lnw": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 87,
                                            "height": 5,
                                            "x": 116,
                                            "y": 153,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 980,
                                "height": 265,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "mediaRef": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "title": "3.png",
                                            "uri": "5bfb6f_5db79f27b2e84c52bd612986d555986d~mv2_d_7367_1884_s_2.png",
                                            "description": "private/",
                                            "width": 7367,
                                            "height": 1884,
                                            "alt": "",
                                            "artist": {
                                                "id": "",
                                                "name": ""
                                            }
                                        },
                                        "color": "#DBADCA",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo",
                                        "mediaTransforms": {
                                            "scale": 1
                                        }
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9o8zrqk",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "mediaRef": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "3.png",
                                                "uri": "5bfb6f_5db79f27b2e84c52bd612986d555986d~mv2_d_7367_1884_s_2.png",
                                                "description": "private/",
                                                "width": 7367,
                                                "height": 1884,
                                                "alt": "",
                                                "artist": {
                                                    "id": "",
                                                    "name": ""
                                                }
                                            },
                                            "color": "#DBADCA",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9o8zrqk",
                                        "dataChangeBehaviors": []
                                    },
                                    "item2": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "mediaRef": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "2.png",
                                                "uri": "5bfb6f_ddd2da64f4bd4460baa33867d9327f21~mv2_d_6720_1720_s_2.png",
                                                "description": "private/allMedia_picture",
                                                "width": 6720,
                                                "height": 1720,
                                                "alt": "",
                                                "artist": {
                                                    "id": "",
                                                    "name": ""
                                                }
                                            },
                                            "color": "#EAE8E4",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-ja9fgjpd",
                                        "dataChangeBehaviors": []
                                    },
                                    "item3": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "mediaRef": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "1.png",
                                                "uri": "5bfb6f_cd915db152694139bf3e37a959076df0~mv2.png",
                                                "description": "private/allMedia_picture",
                                                "width": 4000,
                                                "height": 1024,
                                                "alt": "",
                                                "artist": {
                                                    "id": "",
                                                    "name": ""
                                                }
                                            },
                                            "color": "#D4ECEA",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-ja9ffnac",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9oa3rji": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "mediaRef": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "4.png",
                                                "uri": "5bfb6f_04416c36839443df8552ac8c0cac7a92~mv2_d_7668_1959_s_2.png",
                                                "description": "private/",
                                                "width": 7668,
                                                "height": 1959,
                                                "alt": "",
                                                "artist": {
                                                    "id": "",
                                                    "name": ""
                                                }
                                            },
                                            "color": "#EAE8E4",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-ja9fhfdv",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 219,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 980,
                        "height": 1060,
                        "x": 0,
                        "y": 12695,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item2",
                            "item3",
                            "item-j9oa3rji"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 0,
                            "horizontal": 0
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 876,
                            "x": 0,
                            "y": 22304,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9o8vzqi"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 352,
                        "x": 0,
                        "y": 2680
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_15",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto",
                                    "layout": {
                                        "width": 86,
                                        "height": 86,
                                        "x": 102,
                                        "y": 40,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "2.0",
                                                "isHidden": false
                                            },
                                            "title": "",
                                            "uri": "5bfb6f_9cc6931fc07c4c46b50694d9062d80d8~mv2.png",
                                            "description": "",
                                            "width": 86,
                                            "height": 86,
                                            "alt": ""
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_9cc6931fc07c4c46b50694d9062d80d8~mv2.png",
                                                "description": "",
                                                "width": 86,
                                                "height": 86,
                                                "alt": ""
                                            },
                                            "item-j9sd2so8": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_e3a01c682ec544bd844cbf99b78e54e3~mv2.png",
                                                "description": "",
                                                "width": 86,
                                                "height": 86,
                                                "alt": ""
                                            },
                                            "item-j9sd2rk2": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_3150d8a22c9a4b7b9f0839d437482bf1~mv2.png",
                                                "description": "",
                                                "width": 86,
                                                "height": 86,
                                                "alt": ""
                                            },
                                            "item-j9sd2pxt": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_bf381356a8434e9eab4bb214437f6b84~mv2.png",
                                                "description": "",
                                                "width": 86,
                                                "height": 86,
                                                "alt": ""
                                            },
                                            "item-j9sd2p9n": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_5b3bff9a8dc149e8ada5f665bc60da44~mv2.png",
                                                "description": "",
                                                "width": 86,
                                                "height": 86,
                                                "alt": ""
                                            },
                                            "item-j9sd2nog": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "5bfb6f_70f3754589f541afaea8cc6c951280da~mv2.png",
                                                "description": "",
                                                "width": 86,
                                                "height": 86,
                                                "alt": ""
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {},
                                            "propertiesSource": {}
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WPhoto",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 86,
                                            "height": 86,
                                            "x": 117,
                                            "y": 48,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 226,
                                        "height": 28,
                                        "x": 32,
                                        "y": 154,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h4 style=\"font-size: 24px; text-align: center;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:24px\"><span style=\"color:#000000\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\">${i18n.translate('comp-j9sc10tl.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 style=\"font-size: 24px; text-align: center;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:24px\"><span style=\"color:#000000\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\">${i18n.translate('comp-j9sc10tl.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sd2so8": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 style=\"font-size: 24px; text-align: center;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:24px\"><span style=\"color:#000000\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\">${i18n.translate('comp-j9sc10tl.components[0].components[1].data.overrides.item-j9sd2so8.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sd2rk2": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 style=\"font-size: 24px; text-align: center;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:24px\"><span style=\"color:#000000\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\">${i18n.translate('comp-j9sc10tl.components[0].components[1].data.overrides.item-j9sd2rk2.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sd2pxt": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 style=\"font-size: 24px; text-align: center;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:24px\"><span style=\"color:#000000\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\">${i18n.translate('comp-j9sc10tl.components[0].components[1].data.overrides.item-j9sd2pxt.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sd2p9n": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 style=\"font-size: 24px; text-align: center;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:24px\"><span style=\"color:#000000\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\">${i18n.translate('comp-j9sc10tl.components[0].components[1].data.overrides.item-j9sd2p9n.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sd2nog": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 style=\"font-size: 24px; text-align: center;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:24px\"><span style=\"color:#000000\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\">${i18n.translate('comp-j9sc10tl.components[0].components[1].data.overrides.item-j9sd2nog.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 24,
                                            "x": 20,
                                            "y": 164,
                                            "scale": 0.8333333333333334,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 222,
                                        "height": 62,
                                        "x": 34,
                                        "y": 199,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_8\" style=\"font-size:15px; line-height:1.5em; text-align:center\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#545454\">${i18n.translate('comp-j9sc10tl.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:15px; line-height:1.5em; text-align:center\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#545454\">${i18n.translate('comp-j9sc10tl.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sd2so8": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:15px; line-height:1.5em; text-align:center\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#545454\">${i18n.translate('comp-j9sc10tl.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sd2rk2": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:15px; line-height:1.5em; text-align:center\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#545454\">${i18n.translate('comp-j9sc10tl.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sd2pxt": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:15px; line-height:1.5em; text-align:center\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#545454\">${i18n.translate('comp-j9sc10tl.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sd2p9n": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:15px; line-height:1.5em; text-align:center\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#545454\">${i18n.translate('comp-j9sc10tl.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sd2nog": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:15px; line-height:1.5em; text-align:center\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#545454\">${i18n.translate('comp-j9sc10tl.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 62
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 44,
                                            "x": 20,
                                            "y": 195,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 290,
                                "height": 272,
                                "x": 290,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#FFFFFF",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo",
                                        "mediaTransforms": {
                                            "scale": 1
                                        }
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9sc7tb1",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sc7tb1",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9sd2so8": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sc7tb1",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9sd2rk2": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sc7tb1",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9sd2pxt": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sc7tb1",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9sd2p9n": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sc7tb1",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9sd2nog": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sc7tb1",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 267,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 580,
                        "height": 816,
                        "x": 0,
                        "y": 13835,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9sd2so8",
                            "item-j9sd2rk2",
                            "item-j9sd2pxt",
                            "item-j9sd2p9n",
                            "item-j9sd2nog"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 0,
                            "horizontal": 0
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 1602,
                            "x": 0,
                            "y": 23275,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9sc10tl"
                },
                "preset": {
                    "rect": {
                        "width": 140,
                        "height": 217,
                        "x": 0,
                        "y": 3032
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_16",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 838,
                                        "height": 42,
                                        "x": 42,
                                        "y": 31,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h5 class=\"font_8\" style=\"font-size: 35px;\"><span style=\"color:#333333\"><span style=\"font-size:35px\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('comp-j9sdezf7.components[0].components[0].data.overrides.item1.text')}</span></span></span></h5>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h5 class=\"font_8\" style=\"font-size: 35px;\"><span style=\"color:#333333\"><span style=\"font-size:35px\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('comp-j9sdezf7.components[0].components[0].data.overrides.item1.text')}</span></span></span></h5>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sduzlb": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h5 class=\"font_8\" style=\"font-size: 35px;\"><span style=\"color:#333333\"><span style=\"font-size:35px\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('comp-j9sdezf7.components[0].components[0].data.overrides.item-j9sduzlb.text')}</span></span></span></h5>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sduyxf": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h5 class=\"font_8\" style=\"font-size: 35px;\"><span style=\"color:#333333\"><span style=\"font-size:35px\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('comp-j9sdezf7.components[0].components[0].data.overrides.item-j9sduyxf.text')}</span></span></span></h5>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sduy4y": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h5 class=\"font_8\" style=\"font-size: 35px;\"><span style=\"color:#333333\"><span style=\"font-size:35px\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('comp-j9sdezf7.components[0].components[0].data.overrides.item-j9sduy4y.text')}</span></span></span></h5>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sduwt6": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h5 class=\"font_8\" style=\"font-size: 35px;\"><span style=\"color:#333333\"><span style=\"font-size:35px\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('comp-j9sdezf7.components[0].components[0].data.overrides.item-j9sduwt6.text')}</span></span></span></h5>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sdw9xa": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h5 class=\"font_8\" style=\"font-size: 35px;\"><span style=\"color:#333333\"><span style=\"font-size:35px\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('comp-j9sdezf7.components[0].components[0].data.overrides.item-j9sdw9xa.text')}</span></span></span></h5>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 52,
                                            "x": 19,
                                            "y": 21,
                                            "scale": 0.7586206896551724,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                    "layout": {
                                        "width": 838,
                                        "height": 5,
                                        "x": 42,
                                        "y": 134,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                    "props": {
                                        "type": "FiveGridLineProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "fullScreenModeOn": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-brd": "1",
                                                "brd": "#000000",
                                                "lnw": "1px"
                                            },
                                            "propertiesSource": {
                                                "brd": "value",
                                                "lnw": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 5,
                                            "x": 19,
                                            "y": 126,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 573,
                                        "height": 17,
                                        "x": 42,
                                        "y": 82,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_7\" style=\"font-size:16px\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdezf7.components[0].components[2].data.overrides.item1.text')}</span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:16px\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdezf7.components[0].components[2].data.overrides.item1.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sduzlb": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:16px\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdezf7.components[0].components[2].data.overrides.item1.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sduyxf": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:16px\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdezf7.components[0].components[2].data.overrides.item1.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sduy4y": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:16px\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdezf7.components[0].components[2].data.overrides.item1.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sduwt6": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:16px\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdezf7.components[0].components[2].data.overrides.item1.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9sdw9xa": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_7\" style=\"font-size:16px\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdezf7.components[0].components[2].data.overrides.item1.text')}</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 17,
                                            "x": 19,
                                            "y": 83,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 920,
                                "height": 163,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#FFEF98",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo",
                                        "mediaTransforms": {
                                            "scale": 1
                                        }
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9sdii5u",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFEF98",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sdii5u",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9sduzlb": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFEF98",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sdii5u",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9sduyxf": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFEF98",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sdii5u",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9sduy4y": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFEF98",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sdii5u",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9sduwt6": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFEF98",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sdii5u",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9sdw9xa": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFEF98",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sdii5u",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 145,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 920,
                        "height": 978,
                        "x": 34,
                        "y": 14717,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9sduzlb",
                            "item-j9sduyxf",
                            "item-j9sduy4y",
                            "item-j9sduwt6",
                            "item-j9sdw9xa"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 0,
                            "horizontal": 0
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 818,
                            "x": 0,
                            "y": 24974,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9sdezf7"
                },
                "preset": {
                    "rect": {
                        "width": 184,
                        "height": 217,
                        "x": 140,
                        "y": 3032
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_17",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto",
                                    "layout": {
                                        "width": 278,
                                        "height": 262,
                                        "x": 18,
                                        "y": 22,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "title": "",
                                            "uri": "7c13964b8ac7449284e04b0efc560680.jpg",
                                            "description": "",
                                            "width": 7360,
                                            "height": 4912,
                                            "alt": "",
                                            "link": {
                                                "type": "ExternalLink",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "url": "http://www.wix.com",
                                                "target": "_blank"
                                            }
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "7c13964b8ac7449284e04b0efc560680.jpg",
                                                "description": "",
                                                "width": 7360,
                                                "height": 4912,
                                                "alt": "",
                                                "link": {
                                                    "type": "ExternalLink",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "1.0",
                                                        "isHidden": false
                                                    },
                                                    "url": "http://www.wix.com",
                                                    "target": "_blank"
                                                }
                                            },
                                            "item-j9wkr1bk": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "250bd4f590284f84a1015fccdb53119d.jpg",
                                                "description": "",
                                                "width": 7360,
                                                "height": 4912,
                                                "alt": "",
                                                "link": {
                                                    "type": "ExternalLink",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "1.0",
                                                        "isHidden": false
                                                    },
                                                    "url": "http://www.wix.com",
                                                    "target": "_blank"
                                                }
                                            },
                                            "item-j9wkqyib": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "9b38f381ec89469ab2bff4021892f229.jpg",
                                                "description": "",
                                                "width": 5500,
                                                "height": 3671,
                                                "alt": "",
                                                "link": {
                                                    "type": "ExternalLink",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "1.0",
                                                        "isHidden": false
                                                    },
                                                    "url": "http://www.wix.com",
                                                    "target": "_blank"
                                                }
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink",
                                        "filterEffect": {
                                            "effectType": "whistler"
                                        }
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {},
                                            "propertiesSource": {}
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WPhoto",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 281,
                                            "height": 264,
                                            "x": 19,
                                            "y": 20,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 222,
                                        "height": 25,
                                        "x": 81,
                                        "y": 601,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdzmre.components[0].components[1].data.overrides.item1.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdzmre.components[0].components[1].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wkr1bk": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdzmre.components[0].components[1].data.overrides.item-j9wkr1bk.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wkqyib": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdzmre.components[0].components[1].data.overrides.item-j9wkqyib.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 18
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 204,
                                            "height": 25,
                                            "x": 86,
                                            "y": 541,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 222,
                                        "height": 25,
                                        "x": 81,
                                        "y": 580,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"color:#545454\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif;\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdzmre.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"color:#545454\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif;\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdzmre.components[0].components[2].data.overrides.item1.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wkr1bk": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"color:#545454\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"font-size:16px\">${i18n.translate('repeaters_fix_jasmine_aziz')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wkqyib": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"color:#545454\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"font-size:16px\">${i18n.translate('repeaters_fix_iron_mike')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 18
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 166,
                                            "height": 25,
                                            "x": 86,
                                            "y": 518,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                                    "layout": {
                                        "width": 50,
                                        "height": 50,
                                        "x": 18,
                                        "y": 577,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "2.0",
                                                "isHidden": false
                                            },
                                            "title": "",
                                            "uri": "727644b338ab465cad167dcaf9e69f84.jpg",
                                            "description": "",
                                            "width": 1846,
                                            "height": 2379,
                                            "alt": "",
                                            "crop": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 1846,
                                                "height": 1830
                                            }
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "727644b338ab465cad167dcaf9e69f84.jpg",
                                                "description": "",
                                                "width": 1846,
                                                "height": 2379,
                                                "alt": "",
                                                "crop": {
                                                    "x": 0,
                                                    "y": 0,
                                                    "width": 1846,
                                                    "height": 1830
                                                }
                                            },
                                            "item-j9wkr1bk": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "12c903750c8d46ccb81ce6562a1923d9.jpg",
                                                "description": "",
                                                "width": 1902,
                                                "height": 2452,
                                                "alt": "",
                                                "crop": {
                                                    "x": 0,
                                                    "y": 0,
                                                    "width": 1902,
                                                    "height": 1916
                                                }
                                            },
                                            "item-j9wkqyib": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "8a49d97617ec470289d12539d3365e26.jpg",
                                                "description": "",
                                                "width": 1521,
                                                "height": 1960,
                                                "alt": "",
                                                "crop": {
                                                    "x": 0,
                                                    "y": 0,
                                                    "width": 1521,
                                                    "height": 1531
                                                }
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-brd": "0",
                                                "boxShadowToggleOn-shd": "false",
                                                "brd": "#FFFFFF",
                                                "brw": "0px",
                                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                            },
                                            "propertiesSource": {
                                                "alpha-brd": "value",
                                                "brd": "value",
                                                "brw": "value",
                                                "shd": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.WPhoto",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WPhoto",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 50,
                                            "height": 50,
                                            "x": 21,
                                            "y": 518,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                    "layout": {
                                        "width": 285,
                                        "height": 5,
                                        "x": 18,
                                        "y": 561,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                    "props": {
                                        "type": "FiveGridLineProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "fullScreenModeOn": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-brd": "1",
                                                "brd": "#858585",
                                                "lnw": "1"
                                            },
                                            "propertiesSource": {
                                                "brd": "value",
                                                "lnw": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 5,
                                            "x": 21,
                                            "y": 503,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 285,
                                        "height": 60,
                                        "x": 18,
                                        "y": 343,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h4 class=\"font_8\" style=\"font-size: 22px; line-height: 1.4em;\"><span style=\"font-size:22px\"><span style=\"color:#EB8F72\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('repeaters_fix_cardio')}</span></span></span></h4>\n\n<h4 class=\"font_8\" style=\"font-size: 22px; line-height: 1.4em;\"><span style=\"font-size:22px\"><span style=\"color:#EB8F72\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('repeaters_fix_cardio_time')}</span></span></span></h4>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_8\" style=\"font-size: 22px; line-height: 1.4em;\"><span style=\"font-size:22px\"><span style=\"color:#EB8F72\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('repeaters_fix_cardio')}</span></span></span></h4>\n\n<h4 class=\"font_8\" style=\"font-size: 22px; line-height: 1.4em;\"><span style=\"font-size:22px\"><span style=\"color:#EB8F72\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('repeaters_fix_cardio_time')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wkr1bk": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_8\" style=\"font-size: 22px; line-height: 1.4em;\"><span style=\"font-size:22px\"><span style=\"color:#EB8F72\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('repeaters_fix_boxing')}</span></span></span></h4>\n\n<h4 class=\"font_8\" style=\"font-size: 22px; line-height: 1.4em;\"><span style=\"font-size:22px\"><span style=\"color:#EB8F72\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('repeaters_fix_boxing_time')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wkqyib": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_8\" style=\"font-size: 22px; line-height: 1.4em;\"><span style=\"font-size:22px\"><span style=\"color:#EB8F72\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('repeaters_fix_weight')}</span></span></span></h4>\n\n<h4 class=\"font_8\" style=\"font-size: 22px; line-height: 1.4em;\"><span style=\"font-size:22px\"><span style=\"color:#EB8F72\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">${i18n.translate('repeaters_fix_Tuesdays_time')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 52,
                                            "x": 20,
                                            "y": 324,
                                            "scale": 0.8181818181818182,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 235,
                                        "height": 20,
                                        "x": 18,
                                        "y": 310,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_8\" style=\"font-size:16px\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdzmre.components[0].components[6].data.original.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:16px\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdzmre.components[0].components[6].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wkr1bk": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:16px\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdzmre.components[0].components[6].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wkqyib": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size:16px\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdzmre.components[0].components[6].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 20,
                                            "x": 20,
                                            "y": 299,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 277,
                                        "height": 100,
                                        "x": 18,
                                        "y": 420,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_8\" style=\"font-size: 16px; line-height: 1.6em;\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdzmre.components[0].components[7].data.original.text')}</span></span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size: 16px; line-height: 1.6em;\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdzmre.components[0].components[7].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wkr1bk": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size: 16px; line-height: 1.6em;\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdzmre.components[0].components[7].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wkqyib": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"font-size: 16px; line-height: 1.6em;\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">${i18n.translate('comp-j9sdzmre.components[0].components[7].data.original.text')}</span></span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 100,
                                            "x": 21,
                                            "y": 383,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 315,
                                "height": 648,
                                "x": 664,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#FFFFFF",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo",
                                        "mediaTransforms": {
                                            "scale": 1
                                        }
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        },
                                        "cssBoxShadow": [
                                            {
                                                "inset": false,
                                                "offsetX": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "offsetY": {
                                                    "value": 4,
                                                    "unit": "px"
                                                },
                                                "blurRadius": {
                                                    "value": 20,
                                                    "unit": "px"
                                                },
                                                "spreadRadius": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "color": {
                                                    "red": 0,
                                                    "green": 0,
                                                    "blue": 0,
                                                    "alpha": 0.1
                                                }
                                            }
                                        ]
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9sed6lg",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "cssBoxShadow": [
                                                {
                                                    "inset": false,
                                                    "offsetX": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "offsetY": {
                                                        "value": 4,
                                                        "unit": "px"
                                                    },
                                                    "blurRadius": {
                                                        "value": 20,
                                                        "unit": "px"
                                                    },
                                                    "spreadRadius": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "color": {
                                                        "red": 0,
                                                        "green": 0,
                                                        "blue": 0,
                                                        "alpha": 0.1
                                                    }
                                                }
                                            ]
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sed6lg",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9wkr1bk": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "cssBoxShadow": [
                                                {
                                                    "inset": false,
                                                    "offsetX": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "offsetY": {
                                                        "value": 4,
                                                        "unit": "px"
                                                    },
                                                    "blurRadius": {
                                                        "value": 20,
                                                        "unit": "px"
                                                    },
                                                    "spreadRadius": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "color": {
                                                        "red": 0,
                                                        "green": 0,
                                                        "blue": 0,
                                                        "alpha": 0.1
                                                    }
                                                }
                                            ]
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sed6lg",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9wkqyib": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "cssBoxShadow": [
                                                {
                                                    "inset": false,
                                                    "offsetX": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "offsetY": {
                                                        "value": 4,
                                                        "unit": "px"
                                                    },
                                                    "blurRadius": {
                                                        "value": 20,
                                                        "unit": "px"
                                                    },
                                                    "spreadRadius": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "color": {
                                                        "red": 0,
                                                        "green": 0,
                                                        "blue": 0,
                                                        "alpha": 0.1
                                                    }
                                                }
                                            ]
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9sed6lg",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 596,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 980,
                        "height": 648,
                        "x": 0,
                        "y": 15804,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9wkr1bk",
                            "item-j9wkqyib"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 17,
                            "horizontal": 17
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true,
                            "mobileProps": {
                                "type": "CardsLayoutProperties",
                                "metaData": {
                                    "schemaVersion": "1.0",
                                    "autoGenerated": false
                                },
                                "alignment": "left",
                                "gap": {
                                    "vertical": 20,
                                    "horizontal": 20
                                }
                            }
                        },
                        "layout": {
                            "width": 320,
                            "height": 1828,
                            "x": 0,
                            "y": 25875,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9sdzmre"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 231,
                        "x": 0,
                        "y": 3249
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_18",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Container",
                                    "components": [
                                        {
                                            "type": "Component",
                                            "layout": {
                                                "width": 195,
                                                "height": 60,
                                                "x": 62,
                                                "y": 130,
                                                "scale": 1,
                                                "rotationInDegrees": 0,
                                                "fixedPosition": false
                                            },
                                            "componentType": "wysiwyg.viewer.components.WRichText",
                                            "data": {
                                                "type": "RepeatedData",
                                                "original": {
                                                    "type": "StyledText",
                                                    "metaData": {
                                                        "isPreset": false,
                                                        "schemaVersion": "1.0",
                                                        "isHidden": false
                                                    },
                                                    "text": `<p class=\"font_4\" style=\"font-size: 40px; text-align: center;\"><span style=\"font-size:40px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">01</span></span></span></p>`,
                                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                    "linkList": []
                                                },
                                                "overrides": {
                                                    "item1": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<p class=\"font_4\" style=\"font-size: 40px; text-align: center;\"><span style=\"font-size:40px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">01</span></span></span></p>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    },
                                                    "item-j9wmdaoh": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<p class=\"font_4\" style=\"font-size: 40px; text-align: center;\"><span style=\"font-size:40px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">02</span></span></span></p>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    },
                                                    "item-j9wmd9q7": {
                                                        "type": "StyledText",
                                                        "metaData": {
                                                            "isPreset": false,
                                                            "schemaVersion": "1.0",
                                                            "isHidden": false
                                                        },
                                                        "text": `<p class=\"font_4\" style=\"font-size: 40px; text-align: center;\"><span style=\"font-size:40px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">03</span></span></span></p>`,
                                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                        "linkList": []
                                                    }
                                                }
                                            },
                                            "props": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            },
                                            "style": {
                                                "type": "TopLevelStyle",
                                                "metaData": {
                                                    "isPreset": true,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "style": {
                                                    "groups": {},
                                                    "properties": {
                                                        "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                        "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                        "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                        "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                        "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                        "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                        "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                        "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                        "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                        "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                        "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                                    },
                                                    "propertiesSource": {
                                                        "f0": "value",
                                                        "f1": "value",
                                                        "f10": "value",
                                                        "f2": "value",
                                                        "f3": "value",
                                                        "f4": "value",
                                                        "f5": "value",
                                                        "f6": "value",
                                                        "f7": "value",
                                                        "f8": "value",
                                                        "f9": "value"
                                                    }
                                                },
                                                "componentClassName": "",
                                                "pageId": "",
                                                "compId": "",
                                                "styleType": "custom",
                                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                            },
                                            "mobileStructure": {
                                                "componentType": "wysiwyg.viewer.components.WRichText",
                                                "type": "Component",
                                                "conversionData": {
                                                    "isCustomPreset": true,
                                                    "mobileProps": {
                                                        "type": "WRichTextProperties",
                                                        "metaData": {
                                                            "schemaVersion": "1.0",
                                                            "autoGenerated": false
                                                        },
                                                        "brightness": 1,
                                                        "packed": true
                                                    }
                                                },
                                                "layout": {
                                                    "width": 139,
                                                    "height": 51,
                                                    "x": 90,
                                                    "y": 65,
                                                    "scale": 1.12,
                                                    "rotationInDegrees": 0,
                                                    "fixedPosition": false
                                                }
                                            }
                                        }
                                    ],
                                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                                    "layout": {
                                        "width": 320,
                                        "height": 320,
                                        "x": 0,
                                        "y": 0,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "mobile.core.components.Container",
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-bg": "0.2",
                                                "alpha-brd": "0",
                                                "bg": "rgba(255,255,255,1)",
                                                "boxShadowToggleOn-shd": "false",
                                                "brd": "#324158",
                                                "brw": "0px",
                                                "rd": "0px",
                                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                            },
                                            "propertiesSource": {
                                                "alpha-bg": "value",
                                                "alpha-brd": "value",
                                                "bg": "value",
                                                "brd": "value",
                                                "brw": "value",
                                                "rd": "value",
                                                "shd": "value"
                                            }
                                        },
                                        "componentClassName": "mobile.core.components.Container",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "mobile.core.components.Container",
                                        "type": "Container",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 320,
                                            "height": 181,
                                            "x": 0,
                                            "y": 0,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 389,
                                        "height": 36,
                                        "x": 452,
                                        "y": 80,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h4 class=\"font_4\" style=\"font-size:24px; text-align:center\"><span style=\"font-weight:bold\"><span style=\"font-size:24px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">${i18n.translate('comp-jagwukrn.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:24px; text-align:center\"><span style=\"font-weight:bold\"><span style=\"font-size:24px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">${i18n.translate('comp-jagwukrn.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wmdaoh": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:24px; text-align:center\"><span style=\"font-size:24px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">${i18n.translate('comp-jagwukrn.components[0].components[1].data.overrides.item-j9wmdaoh.text')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wmd9q7": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:24px; text-align:center\"><span style=\"font-size:24px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">${i18n.translate('comp-jagwukrn.components[0].components[1].data.overrides.item-j9wmd9q7.text')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 29,
                                            "x": 20,
                                            "y": 251,
                                            "scale": 0.8333333333333334,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 334,
                                        "height": 44,
                                        "x": 484,
                                        "y": 129,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_8\" style=\"line-height:1.5em; text-align:center\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-jagwukrn.components[0].components[2].data.overrides.item1.text')}</span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"line-height:1.5em; text-align:center\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-jagwukrn.components[0].components[2].data.overrides.item1.text')}</span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wmdaoh": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"line-height:1.5em; text-align:center\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-jagwukrn.components[0].components[2].data.overrides.item1.text')}</span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wmd9q7": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_8\" style=\"line-height:1.5em; text-align:center\"><span style=\"color:#FFFFFF\">${i18n.translate('comp-jagwukrn.components[0].components[2].data.overrides.item1.text')}</span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "mobileHints": {
                                        "type": "MobileHints",
                                        "hidden": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": false
                                                },
                                                "brightness": 1,
                                                "packed": true
                                            }
                                        },
                                        "layout": {
                                            "width": 237,
                                            "height": 66,
                                            "x": 41,
                                            "y": 303,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                                    "layout": {
                                        "width": 142,
                                        "height": 40,
                                        "x": 576,
                                        "y": 197,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "LinkableButton",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "label": i18n.translate('comp-j9sc10tl.components[0].components[1].data.overrides.item-j9sd2p9n.text')
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9sc10tl.components[0].components[1].data.overrides.item-j9sd2p9n.text')
                                            },
                                            "item-j9wmdaoh": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9sc10tl.components[0].components[1].data.overrides.item-j9sd2p9n.text')
                                            },
                                            "item-j9wmd9q7": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9sc10tl.components[0].components[1].data.overrides.item-j9sd2p9n.text')
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "align": "center",
                                        "margin": 0
                                    },
                                    "mobileHints": {
                                        "type": "MobileHints",
                                        "hidden": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-bg": "0",
                                                "alpha-bgd": "1",
                                                "alpha-bgh": "1",
                                                "alpha-brd": "1",
                                                "alpha-brdd": "1",
                                                "alpha-brdh": "1",
                                                "bg": "#F2BF5E",
                                                "bgd": "#CCCCCC",
                                                "bgh": "#36B5CD",
                                                "boxShadowToggleOn-shd": "false",
                                                "brd": "#FFFFFF",
                                                "brdd": "#CCCCCC",
                                                "brdh": "#858585",
                                                "brw": "1",
                                                "fnt": "normal normal normal 16px/1.4em open+sans",
                                                "rd": "100px",
                                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                                "txt": "#FFFFFF",
                                                "txtd": "#FFFFFF",
                                                "txth": "#858585"
                                            },
                                            "propertiesSource": {
                                                "alpha-bg": "value",
                                                "alpha-bgd": "value",
                                                "alpha-bgh": "value",
                                                "alpha-brd": "value",
                                                "alpha-brdd": "value",
                                                "alpha-brdh": "value",
                                                "bg": "value",
                                                "bgd": "value",
                                                "bgh": "value",
                                                "boxShadowToggleOn-shd": "value",
                                                "brd": "value",
                                                "brdd": "value",
                                                "brdh": "value",
                                                "brw": "value",
                                                "fnt": "value",
                                                "rd": "value",
                                                "shd": "value",
                                                "txt": "value",
                                                "txtd": "value",
                                                "txth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.SiteButton",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 140,
                                            "height": 40,
                                            "x": 90,
                                            "y": 414,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 980,
                                "height": 320,
                                "x": 0,
                                "y": 320,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#35C68B",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo",
                                        "mediaTransforms": {
                                            "scale": 1
                                        }
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-j9wld94y",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#35C68B",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9wld94y",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9wmdaoh": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FF6161",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9wmdlkc",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9wmd9q7": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#21406A",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-j9wme4vo",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 514,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 980,
                        "height": 960,
                        "x": -1,
                        "y": 16546,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9wmdaoh",
                            "item-j9wmd9q7"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 0,
                            "horizontal": 0
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 1542,
                            "x": 0,
                            "y": 27777,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-jagwukrn"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 290,
                        "x": 0,
                        "y": 3480
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_19",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 620,
                                        "height": 375,
                                        "x": 20,
                                        "y": 23,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WPhoto",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "2.0",
                                                "isHidden": false
                                            },
                                            "title": "",
                                            "uri": "e071d6ecfcfbcf2b0eba57543959d12b.jpg",
                                            "description": "",
                                            "width": 1500,
                                            "height": 1000,
                                            "alt": ""
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "e071d6ecfcfbcf2b0eba57543959d12b.jpg",
                                                "description": "",
                                                "width": 1500,
                                                "height": 1000,
                                                "alt": ""
                                            },
                                            "item-j9wn309i": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "582ed97cb9b9442b86b99e1acd742ff1.jpg",
                                                "description": "",
                                                "width": 6016,
                                                "height": 4016,
                                                "alt": ""
                                            },
                                            "item-j9wn2zim": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "e478cb5f2d1f481ba15448f9240c9977.jpg",
                                                "description": "",
                                                "width": 5763,
                                                "height": 3847,
                                                "alt": ""
                                            },
                                            "item-j9wn2yvi": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "2.0",
                                                    "isHidden": false
                                                },
                                                "title": "",
                                                "uri": "b2f7ef303bfa46ffa607186d757eb73f.jpg",
                                                "description": "",
                                                "width": 5472,
                                                "height": 3648,
                                                "alt": "",
                                                "crop": {
                                                    "x": 516,
                                                    "y": 679,
                                                    "width": 4383,
                                                    "height": 2651
                                                }
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WPhotoProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "displayMode": "fill",
                                        "onClickBehavior": "goToLink"
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {},
                                            "propertiesSource": {}
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WPhoto",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 218,
                                            "x": 20,
                                            "y": 20,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 304,
                                        "height": 42,
                                        "x": 670,
                                        "y": 26,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h4 class=\"font_4\" style=\"font-size:35px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:35px\"><span style=\"font-family:impact,impact-w01-2010,impact-w02-2010,impact-w10-2010,sans-serif\"><span style=\"background-color:#F6FD33\">${i18n.translate('comp-j9wmimhj.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></span></h4>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:35px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:35px\"><span style=\"font-family:impact,impact-w01-2010,impact-w02-2010,impact-w10-2010,sans-serif\"><span style=\"background-color:#F6FD33\">${i18n.translate('comp-j9wmimhj.components[0].components[1].data.overrides.item1.text')}</span></span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wn309i": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:35px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:35px\"><span style=\"font-family:impact,impact-w01-2010,impact-w02-2010,impact-w10-2010,sans-serif\"><span style=\"background-color:#F6FD33\">${i18n.translate('comp-j9wmimhj.components[0].components[1].data.overrides.item-j9wn309i.text')}</span></span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wn2zim": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:35px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:35px\"><span style=\"font-family:impact,impact-w01-2010,impact-w02-2010,impact-w10-2010,sans-serif\"><span style=\"background-color:#F6FD33\">${i18n.translate('comp-j9wmimhj.components[0].components[1].data.overrides.item-j9wn2zim.text')}</span></span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wn2yvi": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_4\" style=\"font-size:35px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:35px\"><span style=\"font-family:impact,impact-w01-2010,impact-w02-2010,impact-w10-2010,sans-serif\"><span style=\"text-transform: uppercase\"><span style="background-color:#F6FD33">${i18n.translate('comp-j9phsrjg.components[0].components[0].data.overrides.item-j9pleqw5.text')}</span></span></span></span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": true
                                                },
                                                "brightness": 1,
                                                "packed": false,
                                                "minHeight": null,
                                                "id": "mobile_propItem-j9wmlnfa1"
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 29,
                                            "x": 23,
                                            "y": 264,
                                            "scale": 0.8214285714285714,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                    "layout": {
                                        "width": 77,
                                        "height": 5,
                                        "x": 671,
                                        "y": 391,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                    "props": {
                                        "type": "FiveGridLineProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "fullScreenModeOn": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-brd": "1",
                                                "brd": "#F6FD33",
                                                "lnw": "5"
                                            },
                                            "propertiesSource": {
                                                "brd": "value",
                                                "lnw": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 75,
                                            "height": 5,
                                            "x": 23,
                                            "y": 469,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 238,
                                        "height": 260,
                                        "x": 672,
                                        "y": 94,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<p class=\"font_4\" style=\"font-size: 16px; line-height: 1.5em;\"><span style=\"color:#545454\"><span style=\"font-size:16px\"><span style=\"font-family:proxima-n-w01-reg,sans-serif\">${i18n.translate('comp-j9wmimhj.components[0].components[3].data.overrides.item1.text')}</span></span><span style=\"display:none\">&nbsp;</span></span></p>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_4\" style=\"font-size: 16px; line-height: 1.5em;\"><span style=\"color:#545454\"><span style=\"font-size:16px\"><span style=\"font-family:proxima-n-w01-reg,sans-serif\">${i18n.translate('comp-j9wmimhj.components[0].components[3].data.overrides.item1.text')}</span></span><span style=\"display:none\">&nbsp;</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wn309i": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_4\" style=\"font-size: 16px; line-height: 1.5em;\"><span style=\"color:#545454\"><span style=\"font-size:16px\"><span style=\"font-family:proxima-n-w01-reg,sans-serif\">${i18n.translate('comp-j9wmimhj.components[0].components[3].data.overrides.item1.text')}</span></span><span style=\"display:none\">&nbsp;</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wn2zim": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_4\" style=\"font-size: 16px; line-height: 1.5em;\"><span style=\"color:#545454\"><span style=\"font-size:16px\"><span style=\"font-family:proxima-n-w01-reg,sans-serif\">${i18n.translate('comp-j9wmimhj.components[0].components[3].data.overrides.item1.text')}</span></span><span style=\"display:none\">&nbsp;</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wn2yvi": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<p class=\"font_4\" style=\"font-size: 16px; line-height: 1.5em;\"><span style=\"color:#545454\"><span style=\"font-size:16px\"><span style=\"font-family:proxima-n-w01-reg,sans-serif\">${i18n.translate('comp-j9wmimhj.components[0].components[3].data.overrides.item1.text')}</span></span><span style=\"display:none\">&nbsp;</span></span></p>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": false,
                                        "minHeight": 260
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": true
                                                },
                                                "brightness": 1,
                                                "packed": false,
                                                "minHeight": null,
                                                "id": "mobile_propItem-j9wmmjth1"
                                            }
                                        },
                                        "layout": {
                                            "width": 235,
                                            "height": 120,
                                            "x": 23,
                                            "y": 302,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                                    "layout": {
                                        "width": 132,
                                        "height": 27,
                                        "x": 674,
                                        "y": 364,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "LinkableButton",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "label": i18n.translate('comp-j9wmimhj.components[0].components[4].data.original.label')
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9wmimhj.components[0].components[4].data.original.label')
                                            },
                                            "item-j9wn309i": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9wmimhj.components[0].components[4].data.original.label')
                                            },
                                            "item-j9wn2zim": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9wmimhj.components[0].components[4].data.original.label')
                                            },
                                            "item-j9wn2yvi": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9wmimhj.components[0].components[4].data.original.label')
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "align": "left",
                                        "margin": 0
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-txt": "1",
                                                "alpha-txth": "1",
                                                "fnt": "normal normal normal 16px/1.4em impact",
                                                "txt": "#000000",
                                                "txth": "#545454"
                                            },
                                            "propertiesSource": {
                                                "fnt": "value",
                                                "txt": "value",
                                                "txth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.SiteButton",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 132,
                                            "height": 27,
                                            "x": 23,
                                            "y": 442,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 980,
                                "height": 424,
                                "x": 0,
                                "y": 1314,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "color": "#FFFFFF",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo"
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-jab3gc43",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-jab3gc43",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9wn309i": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-jab3gc46",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9wn2zim": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-jab3gc48",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9wn2yvi": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "color": "#FFFFFF",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo"
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-jab3gc49",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 512,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 980,
                        "height": 1738,
                        "x": 0,
                        "y": 101,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9wn309i",
                            "item-j9wn2zim",
                            "item-j9wn2yvi"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 0,
                            "horizontal": 0
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 2077,
                            "x": 0,
                            "y": 43,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9wmimhj"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 495,
                        "x": 0,
                        "y": 3770
                    }
                }
            },
            {
                "id": "Repeater_Repeaters_20",
                "structure": {
                    "type": "RepeaterContainer",
                    "components": [
                        {
                            "type": "Container",
                            "components": [
                                {
                                    "type": "Component",
                                    "layout": {
                                        "width": 515,
                                        "height": 62,
                                        "x": 232,
                                        "y": 119,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "StyledText",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "text": `<h4 class=\"font_6\" style=\"font-size: 50px; text-align: center;\"><span style=\"color:#FFFFFF\"><span style=\"font-size:50px\"><span style=\"font-family:didot-w01-italic,serif\">${i18n.translate('comp-j9wnmggo.components[0].components[0].data.overrides.item1.text')}</span></span></span></h4>`,
                                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                            "linkList": []
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_6\" style=\"font-size: 50px; text-align: center;\"><span style=\"color:#FFFFFF\"><span style=\"font-size:50px\"><span style=\"font-family:didot-w01-italic,serif\">${i18n.translate('comp-j9wnmggo.components[0].components[0].data.overrides.item1.text')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wo8nd1": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_6\" style=\"font-size: 50px; text-align: center;\"><span style=\"color:#FFFFFF\"><span style=\"font-size:50px\"><span style=\"font-family:didot-w01-italic,serif\">${i18n.translate('comp-j9wnmggo.components[0].components[0].data.overrides.item-j9wo8nd1.text')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            },
                                            "item-j9wo8mwf": {
                                                "type": "StyledText",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "text": `<h4 class=\"font_6\" style=\"font-size: 50px; text-align: center;\"><span style=\"color:#FFFFFF\"><span style=\"font-size:50px\"><span style=\"font-family:didot-w01-italic,serif\">${i18n.translate('comp-j9wnmggo.components[0].components[0].data.overrides.item-j9wo8mwf.text')}</span></span></span></h4>`,
                                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                                "linkList": []
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0",
                                            "autoGenerated": false
                                        },
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": true,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                                "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                                "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                                "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                                "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                                "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                                "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                                "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                                "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                                "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                            },
                                            "propertiesSource": {
                                                "f0": "value",
                                                "f1": "value",
                                                "f10": "value",
                                                "f2": "value",
                                                "f3": "value",
                                                "f4": "value",
                                                "f5": "value",
                                                "f6": "value",
                                                "f7": "value",
                                                "f8": "value",
                                                "f9": "value"
                                            }
                                        },
                                        "componentClassName": "",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.WRichText",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true,
                                            "mobileProps": {
                                                "type": "WRichTextProperties",
                                                "metaData": {
                                                    "schemaVersion": "1.0",
                                                    "autoGenerated": true
                                                },
                                                "brightness": 1,
                                                "packed": false,
                                                "minHeight": null,
                                                "id": "mobile_propItem-j9wnumyh1"
                                            }
                                        },
                                        "layout": {
                                            "width": 280,
                                            "height": 50,
                                            "x": 20,
                                            "y": 97,
                                            "scale": 1.1776598053977274,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                },
                                {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                    "layout": {
                                        "width": 142,
                                        "height": 40,
                                        "x": 419,
                                        "y": 196,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "data": {
                                        "type": "RepeatedData",
                                        "original": {
                                            "type": "LinkableButton",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "label": i18n.translate('comp-j9wnmggo.components[0].components[1].data.overrides.item1.label')
                                        },
                                        "overrides": {
                                            "item1": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9wnmggo.components[0].components[1].data.overrides.item1.label')
                                            },
                                            "item-j9wo8nd1": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9wnmggo.components[0].components[1].data.overrides.item1.label')
                                            },
                                            "item-j9wo8mwf": {
                                                "type": "LinkableButton",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "label": i18n.translate('comp-j9wnmggo.components[0].components[1].data.overrides.item1.label')
                                            }
                                        }
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "align": "center",
                                        "margin": 0
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "alpha-bg": "0",
                                                "alpha-bgd": "1",
                                                "alpha-bgh": "1",
                                                "alpha-brd": "1",
                                                "alpha-brdd": "1",
                                                "alpha-brdh": "0",
                                                "bg": "#333333",
                                                "bgd": "#CCCCCC",
                                                "bgh": "#000000",
                                                "boxShadowToggleOn-shd": "false",
                                                "brd": "#FFFFFF",
                                                "brdd": "#CCCCCC",
                                                "brdh": "#FFFFFF",
                                                "brw": "1",
                                                "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                                "rd": "0",
                                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                                "txt": "#FFFFFF",
                                                "txtd": "#FFFFFF",
                                                "txth": "#FFFFFF"
                                            },
                                            "propertiesSource": {
                                                "alpha-bg": "value",
                                                "alpha-bgd": "value",
                                                "alpha-bgh": "value",
                                                "alpha-brd": "value",
                                                "alpha-brdd": "value",
                                                "alpha-brdh": "value",
                                                "bg": "value",
                                                "bgd": "value",
                                                "bgh": "value",
                                                "boxShadowToggleOn-shd": "value",
                                                "brd": "value",
                                                "brdd": "value",
                                                "brdh": "value",
                                                "brw": "value",
                                                "fnt": "value",
                                                "rd": "value",
                                                "shd": "value",
                                                "txt": "value",
                                                "txtd": "value",
                                                "txth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                    },
                                    "mobileStructure": {
                                        "componentType": "wysiwyg.viewer.components.SiteButton",
                                        "type": "Component",
                                        "conversionData": {
                                            "isCustomPreset": true
                                        },
                                        "layout": {
                                            "width": 135,
                                            "height": 38,
                                            "x": 92,
                                            "y": 166,
                                            "scale": 1,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        }
                                    }
                                }
                            ],
                            "layout": {
                                "width": 980,
                                "height": 340,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.MediaContainer",
                            "design": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "mediaRef": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "title": "tea1.png",
                                            "uri": "5bfb6f_a599570e47114652978034f8e1cd4c9e~mv2.png",
                                            "description": "private/allMedia_picture",
                                            "width": 910,
                                            "height": 252,
                                            "alt": "",
                                            "artist": {
                                                "id": "",
                                                "name": ""
                                            }
                                        },
                                        "color": "#333333",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0,
                                        "showOverlayForMediaType": "WixVideo",
                                        "mediaTransforms": {
                                            "scale": 1
                                        }
                                    },
                                    "cssStyle": {
                                        "cssBorder": {
                                            "width": {
                                                "top": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "right": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottom": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "left": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            },
                                            "style": {
                                                "top": "solid",
                                                "right": "solid",
                                                "left": "solid",
                                                "bottom": "solid"
                                            },
                                            "color": {
                                                "top": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "right": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "left": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                },
                                                "bottom": {
                                                    "red": 176,
                                                    "green": 169,
                                                    "blue": 134,
                                                    "alpha": 1
                                                }
                                            }
                                        },
                                        "cssBorderRadius": {
                                            "topLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "topRight": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomLeft": {
                                                "value": 0,
                                                "unit": "px"
                                            },
                                            "bottomRight": {
                                                "value": 0,
                                                "unit": "px"
                                            }
                                        }
                                    },
                                    "themeMappings": {},
                                    "charas": "design-ja9fjgz7",
                                    "dataChangeBehaviors": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "mediaRef": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "tea1.png",
                                                "uri": "5bfb6f_a599570e47114652978034f8e1cd4c9e~mv2.png",
                                                "description": "private/allMedia_picture",
                                                "width": 910,
                                                "height": 252,
                                                "alt": "",
                                                "artist": {
                                                    "id": "",
                                                    "name": ""
                                                }
                                            },
                                            "color": "#333333",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-ja9fjgz7",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9wo8nd1": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "mediaRef": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "tea2.png",
                                                "uri": "5bfb6f_62bc6ebc921242eeb7a8764caabd7a54~mv2.png",
                                                "description": "private/",
                                                "width": 910,
                                                "height": 252,
                                                "alt": "",
                                                "artist": {
                                                    "id": "",
                                                    "name": ""
                                                }
                                            },
                                            "color": "#333333",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-ja9fjnwg",
                                        "dataChangeBehaviors": []
                                    },
                                    "item-j9wo8mwf": {
                                        "type": "MediaContainerDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "background": {
                                            "type": "BackgroundMedia",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "mediaRef": {
                                                "type": "Image",
                                                "metaData": {
                                                    "isPreset": false,
                                                    "schemaVersion": "1.0",
                                                    "isHidden": false
                                                },
                                                "title": "tea3.png",
                                                "uri": "5bfb6f_689812ec6aad4c058904f6ffbab2964a~mv2.png",
                                                "description": "private/allMedia_picture",
                                                "width": 910,
                                                "height": 252,
                                                "alt": "",
                                                "artist": {
                                                    "id": "",
                                                    "name": ""
                                                }
                                            },
                                            "color": "#333333",
                                            "colorOpacity": 1,
                                            "alignType": "center",
                                            "fittingType": "fill",
                                            "scrollType": "none",
                                            "colorOverlay": "",
                                            "colorOverlayOpacity": 0,
                                            "showOverlayForMediaType": "WixVideo",
                                            "mediaTransforms": {
                                                "scale": 1
                                            }
                                        },
                                        "cssStyle": {
                                            "cssBorder": {
                                                "width": {
                                                    "top": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "right": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "bottom": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    },
                                                    "left": {
                                                        "value": 0,
                                                        "unit": "px"
                                                    }
                                                },
                                                "style": {
                                                    "top": "solid",
                                                    "right": "solid",
                                                    "left": "solid",
                                                    "bottom": "solid"
                                                },
                                                "color": {
                                                    "top": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "right": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "left": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    },
                                                    "bottom": {
                                                        "red": 176,
                                                        "green": 169,
                                                        "blue": 134,
                                                        "alpha": 1
                                                    }
                                                }
                                            },
                                            "cssBorderRadius": {
                                                "topLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "topRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomLeft": {
                                                    "value": 0,
                                                    "unit": "px"
                                                },
                                                "bottomRight": {
                                                    "value": 0,
                                                    "unit": "px"
                                                }
                                            }
                                        },
                                        "themeMappings": {},
                                        "charas": "design-ja9fjt54",
                                        "dataChangeBehaviors": []
                                    }
                                }
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.MediaContainer",
                                "type": "Container",
                                "conversionData": {
                                    "isCustomPreset": true
                                },
                                "layout": {
                                    "width": 320,
                                    "height": 301,
                                    "x": 0,
                                    "y": 602,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }
                    ],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 980,
                        "height": 1020,
                        "x": 0,
                        "y": 1996,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "data": {
                        "type": "Repeater",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            "item1",
                            "item-j9wo8nd1",
                            "item-j9wo8mwf"
                        ]
                    },
                    "props": {
                        "type": "CardsLayoutProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "alignment": "left",
                        "gap": {
                            "vertical": 0,
                            "horizontal": 0
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "0",
                                "bg": "61,155,233,1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.Repeater",
                        "type": "RepeaterContainer",
                        "conversionData": {
                            "isCustomPreset": true
                        },
                        "layout": {
                            "width": 320,
                            "height": 903,
                            "x": 0,
                            "y": 2163,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    },
                    "activeModes": {},
                    "id": "comp-j9wnmggo"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 292,
                        "x": 0,
                        "y": 4265
                    }
                }
            }
        ],
        "compTypes": [
            "wysiwyg.viewer.components.Repeater"
        ]
    },
    "help": {
        "hide": false,
        "text": "add_section_label_listsandgrid_layouts_tooltip_body"
    }
}
