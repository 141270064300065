import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { type ReactNode, type ReactChild } from 'react';
import * as BaseUI from '@/baseUI';
import { cx } from '@/util';

interface Props {
  symbol: string;
  columns?: number;
  onShow?: () => void;
  tooltipClass?: string;
  disabled?: boolean;
  className?: string;
  tooltip?: ReactChild;
  children: ReactNode[];
}

interface State {
  isMenuDisplayed: boolean;
}

class ToolSelector extends React.Component<Props, State> {
  static displayName = 'toolSelector';
  _isMounted: boolean;

  static propTypes = {
    symbol: PropTypes.string.isRequired,
    columns: PropTypes.number,
    onShow: PropTypes.func,
    tooltipClass: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    columns: 3,
    disable: false,
  };

  state: State = {
    isMenuDisplayed: false,
  };

  displayMenu = () => {
    if (!this.props.disabled) {
      this.setState({ isMenuDisplayed: true });
      if (_.isFunction(this.props.onShow)) {
        this.props.onShow();
      }
    }
  };

  hideMenu = () => {
    const self = this;
    window.setTimeout(function () {
      self.safelySetState({ isMenuDisplayed: false });
    }, 300);
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safelySetState: this['setState'] = (newState) => {
    if (this._isMounted) {
      this.setState(newState);
    }
  };

  getDropDownClasses = () => {
    const classes: Record<string, boolean> = {
      'drop-down': true,
      disabled: this.props.disabled,
    };
    classes[`grid-${this.props.columns}-col`] = true;
    return classes;
  };

  getClasses = () => {
    const classes: { [key: string]: boolean } = { 'tool-selector': true };

    if (this.props.className) {
      const classNames = this.props.className.split(' ');
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(classNames, function (name) {
        classes[name] = true;
      });
    }

    if (this.props.disabled) {
      classes.disabled = true;
    }

    return classes;
  };

  render() {
    return (
      <div
        style={{
          position: 'relative',
        }}
        className={cx(this.getClasses())}
      >
        {this.state.isMenuDisplayed ? (
          <div
            key="menuDropDown"
            onMouseLeave={this.hideMenu}
            className={cx(this.getDropDownClasses())}
          >
            {this.props.children}
          </div>
        ) : null}
        <BaseUI.tooltip
          disabled={!this.props.tooltip}
          value={this.props.tooltip}
          delay="600"
          className={this.props.tooltipClass}
        >
          <span
            onClick={this.displayMenu}
            className={cx({ button: true, disabled: this.props.disabled })}
          >
            <BaseUI.symbol name={this.props.symbol} />
          </span>
        </BaseUI.tooltip>
        {this.props.children && this.props.children.length > 1 ? (
          <div key="smallIndicator" className="small-indicator" />
        ) : null}
      </div>
    );
  }
}

export default ToolSelector;
