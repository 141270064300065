import React from 'react';

import keyboardShortcutsActionMap from '@/keyboardShortcuts';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as menu from '@/menu';
import * as stateManagement from '@/stateManagement';

import ItemWithShortcut from '../menuItems/rcmItemWithShortcut';

import type { EditorAPI } from '@/editorAPI';
import {
  isMainMenuFlowEnabled,
  isCustomMenusEnabled,
  isNewPagesPanelEnabled,
} from '@/util';
import * as coreBi from '@/coreBi';
import { pageCategories } from '@/pages';

const {
  api: { createMenuApi },
} = menu;

function PagesMenuContextMenuTemplate(props: AnyFixMe) {
  return (
    <ul>
      {props.items.map((item: AnyFixMe) => (
        <ItemWithShortcut
          key={item.key}
          automationId={item.automationId}
          item={item.item}
          label={item.label}
          shortcut={item.shortcut}
          onClick={item.onClick}
        />
      ))}
    </ul>
  );
}

const shortcuts = keyboardShortcutsActionMap.editor;

function reportPasteStarted(isCrossSite: AnyFixMe) {
  const FEDOPS_INTERACTION = isCrossSite
    ? util.fedopsLogger.INTERACTIONS.PASTE_PAGE_CROSS_SITE
    : util.fedopsLogger.INTERACTIONS.PASTE_PAGE_SAME_SITE;
  util.fedopsLogger.interactionStarted(FEDOPS_INTERACTION);
}
function reportPasteEnded(isCrossSite: AnyFixMe) {
  const FEDOPS_INTERACTION = isCrossSite
    ? util.fedopsLogger.INTERACTIONS.PASTE_PAGE_CROSS_SITE
    : util.fedopsLogger.INTERACTIONS.PASTE_PAGE_SAME_SITE;
  util.fedopsLogger.interactionEnded(FEDOPS_INTERACTION);
}

function onPastePage(editorAPI: AnyFixMe) {
  reportPasteStarted(editorAPI.clipboard.isCrossSite());

  editorAPI.copyPaste
    .pastePage({
      menuItemToPasteAfter:
        editorAPI.mainMenu.panelContext.getSelectedMenuItem(),
      origin: 'rcm_pages_menu',
    })
    .then(() => {
      editorAPI.closeRightClickMenu();
      reportPasteEnded(editorAPI.clipboard.isCrossSite());
    });
}

function onPastePageAndApplyTheme(editorAPI: AnyFixMe) {
  reportPasteStarted(editorAPI.clipboard.isCrossSite());

  editorAPI.copyPaste
    .pastePage({
      menuItemToPasteAfter:
        editorAPI.mainMenu.panelContext.getSelectedMenuItem(),
      shouldApplyTargetTheme: true,
      origin: 'rcm_pages_menu',
    })
    .then(() => {
      editorAPI.closeRightClickMenu();
      reportPasteEnded(editorAPI.clipboard.isCrossSite());
    });
}

const createFolder = async (editorAPI: EditorAPI): Promise<void> => {
  const menuAPI = createMenuApi(editorAPI);

  const placeAfterItemId: AnyFixMe = undefined;

  const dropdownId = await menuAPI.addDropdown(
    menu.constants.PAGES_MENU_ID,
    placeAfterItemId,
    translate('PagesPanel_Actions_Folder_New_Default_Text'),
  );

  editorAPI.store.dispatch(
    stateManagement.pages.actions.setEditItemId(dropdownId),
  );

  // hack. Pages panel calculates the number of opens and only then applies a new state.
  editorAPI.openPagesPanel({});

  const currentPage = editorAPI.pages.getCurrentPage();

  editorAPI.bi.event(coreBi.events.pages.pagesPanel.quick_actions_menu_select, {
    page_id: currentPage.id,
    source: pageCategories.MENUS.getBiCategory(dropdownId),
    category: 'create-folder',
    source_type: 'right-click-context-menu',
    type: 'group',
  });

  editorAPI.closeRightClickMenu();
};

function mapStateToProps({ editorAPI }: AnyFixMe) {
  const isPagePasteAllowed = editorAPI.copyPaste.hasPageToPaste();
  const isPagePageCrossSiteAllowed =
    isPagePasteAllowed && editorAPI.clipboard.isCrossSite();

  const items = [
    {
      key: 'paste',
      automationId: 'rcm-paste-item',
      label: 'SiteMenu_RightClick_Paste_Page_Label',
      item: { enabled: isPagePasteAllowed },
      shortcut: shortcuts.PASTE.label,
      onClick: () => onPastePage(editorAPI),
    },
  ];

  if (
    (isCustomMenusEnabled() && !isMainMenuFlowEnabled()) ||
    isNewPagesPanelEnabled()
  ) {
    items.push({
      key: 'createFolder',
      automationId: 'rcm-create-folder-item',
      label: 'SiteMenu_RightClick_Create_Folder_Label',
      item: { enabled: true },
      shortcut: keyboardShortcutsActionMap.pagesPanel.NEW_FOLDER.label,
      onClick: () => createFolder(editorAPI),
    });
  }

  // NOTE: we should not show cross-site options if they are not applicable
  if (isPagePageCrossSiteAllowed) {
    items.push({
      key: 'pasteAndApplyTheme',
      automationId: 'rcm-paste-and-apply-theme-item',
      label: 'SiteMenu_RightClick_PasteandApplyTheme_Page_Label',
      item: { enabled: isPagePageCrossSiteAllowed },
      shortcut: shortcuts.PASTE_AND_APPLY_THEME.label,
      onClick: () => onPastePageAndApplyTheme(editorAPI),
    });
  }

  return {
    items,
  };
}

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
)(PagesMenuContextMenuTemplate);
