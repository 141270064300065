import React from 'react';
import type { EditorAPI } from '@/editorAPI';

export interface TreeNodesContext {
  isNodeExpanded: (nodeId: string) => boolean | undefined;
  setIsNodeExpanded: (nodeId: string, isExpanded: boolean) => void;
  reset: () => void;
}

export function initTreeNodesContext(
  editorAPI: EditorAPI,
  treeNodesContext: TreeNodesContext,
) {
  editorAPI.selection.hooks.selectionChanged.tap(treeNodesContext.reset);
}

export function destroyTreeNodesContext(
  editorAPI: EditorAPI,
  treeNodesContext: TreeNodesContext,
) {
  editorAPI.selection.hooks.selectionChanged.untap(treeNodesContext.reset);
}

export const useTreeNodesContext = () => {
  const [expandedNodes, setState] = React.useState<Record<string, boolean>>({});

  const setIsNodeExpanded = React.useCallback(
    (nodeId: string, nextIsExpanded: boolean) => {
      setState((expandedNodes) => ({
        ...expandedNodes,
        [nodeId]: nextIsExpanded,
      }));
    },
    [],
  );

  // NOTE: it's important to keep the same reference to reset function (see initTreeNodesContext and destroyTreeNodesContext)
  const reset = React.useCallback(() => {
    setState({});
  }, []);

  return React.useMemo<TreeNodesContext>(
    () => ({
      isNodeExpanded(nodeId) {
        return expandedNodes[nodeId] ?? undefined;
      },
      setIsNodeExpanded,
      reset,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expandedNodes],
  );
};
