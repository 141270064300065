import type { EditorAPI } from '@/editorAPI';
import * as stateManagement from '@/stateManagement';
import type { EditorState } from '@/stateManagement';
import type {
  Dispatch,
  MapDispatchToProps,
  MapStateToProps,
} from 'types/redux';
import { AiApiKey } from '@/apis';

export interface AiAssistantOwnProps {}

export interface AiAssistantStateProps {}

export interface AiAssisntantDispatchProps {
  closePanel: () => void;
  sendMessage: (message: string) => Promise<string>;
  initAiAssistant: () => void;
}

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
): EditorAPI => editorAPI;

const mapStateToProps: MapStateToProps<
  AiAssistantStateProps,
  AiAssistantOwnProps
> = () => ({});

const mapDispatchToProps: MapDispatchToProps<
  AiAssisntantDispatchProps,
  AiAssistantOwnProps
> = (dispatch, _ownProps: AiAssistantOwnProps) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    closePanel: () => {
      editorAPI.store.dispatch(
        stateManagement.panels.actions.closePanelByNameAction(
          'panels.focusPanels.AIAssistantPanel',
        ),
      );
    },
    sendMessage: (message: string) => {
      return editorAPI.documentServices.ai.conversation.sendMessage(message);
    },
    initAiAssistant: () => {
      const aiScopeApi = editorAPI.host.getAPI(AiApiKey);
      aiScopeApi.registerEditorTools();
    },
  };
};

export { mapStateToProps, mapDispatchToProps };
