import type { OnDropCallback } from '@/addPanelInfra';
import type { EditorAPI } from '@/editorAPI';
import { chatButtonSection, liveChatSection } from '@/tpa';
import type { CompStructure } from 'types/documentServices';

interface AddLiveChatOnClickParams {
  structure: CompStructure;
  sectionTitle: string;
  tags: string;
  itemId: string;
  position: { x: number; y: number } | void;
}

export const addLiveChatOnClick = (
  editorAPI: EditorAPI,
  { structure, sectionTitle, tags, itemId, position }: AddLiveChatOnClickParams,
) =>
  liveChatSection.addApp.call(
    { editorAPI },
    structure,
    sectionTitle,
    tags,
    itemId,
    editorAPI,
    undefined,
    { position },
  );

export const addLiveChatOnDrop = (editorAPI: EditorAPI) =>
  liveChatSection.onDrop.bind({ editorAPI });

interface AddChatButtonOnClickParams {
  structure: CompStructure;
}

export const addChatButtonOnClick = (
  editorAPI: EditorAPI,
  { structure }: AddChatButtonOnClickParams,
) =>
  chatButtonSection.addButton(editorAPI, {
    structure,
    coords: null,
    dropContainer: null,
  });

export const addChatButtonOnDrop =
  (editorAPI: EditorAPI): OnDropCallback =>
  async (coords, { structure }, dropContainer) => {
    chatButtonSection.addButton(editorAPI, {
      structure,
      coords,
      dropContainer,
    });
  };
