import Button from '../topBarButton/topBarButtonWithTooltip';
import TooltipContent from '../tooltipContent';
import React from 'react';
import { TopBarSecondaryActionsSlotPlacement } from '@/extensionSlots';

import styles from './secondaryActionsExtensionSlot.scss';

export const SecondaryActionsExtensionSlot = () => (
  <TopBarSecondaryActionsSlotPlacement>
    {(_, ownProps) => (
      <Button
        className={styles.secondaryActionBtn}
        automationId={ownProps.automationId}
        symbolName={ownProps.symbolName}
        tooltip={
          <TooltipContent
            tooltipData={{ ...ownProps.tooltip, disableTranslate: true }}
          />
        }
        toggled={ownProps.isActive}
        onClick={ownProps.onClick}
      />
    )}
  </TopBarSecondaryActionsSlotPlacement>
);
