import type { IAutoCompleteComponentModel } from '@wix/editor-elements-registry/2.0/autoComplete';
import type { IServiceTopology } from '@wix/editor-elements-registry/2.0/toolbox';
import experiment from 'experiment';

async function loadAutoCompleteData({
  serviceTopology,
}: {
  serviceTopology: IServiceTopology;
}) {
  const [{ registry }, { getComponentsLibraries }] = await Promise.all([
    import('@wix/editor-elements-registry/2.0/autoComplete'),
    import('@wix/editor-elements-registry/2.0/toolbox'),
  ]);

  const registryAPI = await registry({
    mode: 'eager',
    options: {
      //eslint-disable-next-line
      useEsmLoader: SANTA_EDITOR_BUILD_TYPE === 'esbuild',
    },
    libraries: getComponentsLibraries({
      serviceTopology,
      url: window.location.href,
      useNewStatics: experiment.isOpen('specs.editor-elements.useNewStatics'),
      experimentalMobileLibrary: experiment.isOpen(
        'specs.mobile-elements.useRegistry',
      ),
    }),
  });

  const autoCompleteDataLoadersMap = registryAPI.getComponentsLoaders();
  const autoCompleteData = await Promise.all(
    Object.entries(autoCompleteDataLoadersMap).map(
      async ([componentName, loader]) =>
        [componentName, await loader()] as [
          string,
          IAutoCompleteComponentModel,
        ],
    ),
  );

  const autoCompleteDataMap = autoCompleteData.reduce(
    (acc, [componentName, data]) => {
      acc[componentName] = data;
      return acc;
    },
    {} as Record<string, IAutoCompleteComponentModel>,
  );

  return autoCompleteDataMap;
}

export { loadAutoCompleteData };
