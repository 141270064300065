import { type Effect } from '@wix/motion-effects-panels';
import { type ScrollEffectParams } from '@wix/effects-kit';
import experiment from 'experiment';

import type {
  AnimationType,
  EffectData,
  EntranceEffectData,
  OngoingEffectData,
  ScrollEffectData,
} from '../api/types';

import { presets } from './constants';

const buildEntranceEffectObject = (effect: Effect): EntranceEffectData => {
  const { duration, delay, allowReplay, easing, ...namedEffectOptions } =
    effect.params;

  return {
    name: '',
    type: 'TimeAnimation',
    value: {
      type: 'TimeAnimationOptions',
      fill: 'backwards',
      duration,
      delay,
      allowReplay,
      easing,
      namedEffect: {
        type: effect.name,
        ...namedEffectOptions,
      },
    },
  };
};

const buildOngoingEffectObject = (effect: Effect): OngoingEffectData => {
  const { duration, delay, allowReplay, easing, ...namedEffectOptions } =
    effect.params;

  return {
    name: '',
    type: 'TimeAnimation',
    value: {
      type: 'TimeAnimationOptions',
      iterations: 0,
      duration,
      delay,
      allowReplay,
      easing,
      namedEffect: {
        type: effect.name,
        ...namedEffectOptions,
      },
    },
  };
};

const buildScrollEffectObject = (effect: Effect): ScrollEffectData => {
  const { start, end, ...namedEffectOptions } =
    effect.params as ScrollEffectParams;

  return {
    name: '',
    type: 'ScrubAnimation',
    value: {
      type: 'ScrubAnimationOptions',
      startOffset: {
        name: 'cover',
        offset: { value: start, type: 'percentage' },
      },
      endOffset: {
        name: 'cover',
        offset: { value: end, type: 'percentage' },
      },
      fill: 'both',
      namedEffect: {
        type: effect.name,
        ...namedEffectOptions,
      },
    },
  };
};

export const effectBuilders = {
  entrance: buildEntranceEffectObject,
  ongoing: buildOngoingEffectObject,
  scroll: buildScrollEffectObject,
} as const;

export const getEffectObject = (effect: EffectData | null): Effect => {
  if (!effect?.value?.namedEffect) {
    return { name: 'None' };
  }

  const { startOffset, endOffset } = (effect as ScrollEffectData).value ?? {};
  const startEndParams =
    startOffset && endOffset
      ? { start: startOffset.offset.value, end: endOffset.offset.value }
      : {};

  return {
    name: effect.value.namedEffect.type,
    params: {
      ...effect.value,
      ...effect.value.namedEffect,
      ...startEndParams,
    },
  } as Effect;
};

export const getConfig = (type: AnimationType, name: string) =>
  presets[type].find((animation) => animation?.name === name);

export const isLoopEnabled = () => {
  return experiment.isOpen('se_newAnimationPanelLoop');
};

export const isScrollEnabled = () => {
  return experiment.isOpen('se_newAnimationPanelScroll');
};
