import { serviceTopology } from '@/util';
import { translate } from '@/i18n';
import type { EditorSDKModule } from '@wix/platform-editor-sdk';
import type { EditorAPI } from '@/editorAPI';
import { fedopsLogger } from '@/util';
import experiment from 'experiment';

import { registryHost } from './registry-host';
import { render as renderComponents } from './renderer';

interface MountParams {
  editorSDKModule: EditorSDKModule;
  editorAPI: EditorAPI;
}

let __extensionIds: string[] = [];

export async function mount({
  editorSDKModule,
  editorAPI,
}: MountParams): Promise<void> {
  fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.EXTENSIONS.MOUNT);
  const componentModels = await registryHost.mount({
    editorSDKModule,
    // FIXME: update types
    documentServices: editorAPI.documentServices,
    serviceTopology,
    translate,
    biLogger: {
      logBi: editorAPI.bi.event,
    },
    editorExperiments: experiment,
  });
  fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.EXTENSIONS.MOUNT);

  fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.EXTENSIONS.RENDER);
  renderComponents(componentModels, editorAPI.store, () =>
    fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.EXTENSIONS.RENDER),
  );

  __extensionIds = componentModels.map(({ id }) => id);
}

export function startLoading() {
  return registryHost.startLoading();
}

export function __dispatchHackyAppInstalledEvent(
  appDefinitionId: string,
): void {
  /*
  this function is here to make AppManager panel implementable as an Editor Extension until:
  - `editorSDK` in main frame has proper support for editor events (right now,
      events are dispatched only to TPAs, via Editor -> DS -> platform-worker)
  - `editorSDK` has a proper `appInstalled` _event_ instead of/in addition to an action

  (see also https://github.com/wix-private/santa-editor/pull/38280)

  Don't even think about adding another `__dispatchSomeEvent` function next to this one.
  */

  for (const id of __extensionIds) {
    const { editorSDK } = registryHost.getBoundedParams(id);

    if (!editorSDK.__dispatchEvent) {
      continue;
    }

    editorSDK.__dispatchEvent(
      new CustomEvent('__hackyAppInstalled', {
        detail: {
          appDefinitionId,
        },
      }),
    );
  }
}
