import _ from 'lodash';
import animationGfppUtils from '../../utils/animationGfppUtils';
import multiselectGfppUtils from '../../utils/multiselectGfppUtils';
import constants from '@/constants';
import type { GFPPActionType } from '@wix/editor-component-types';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import experiment from 'experiment';

const {
  isMultiDesignEnabled,
  getRichTextMainAction,
  getRichTextPresetActions,
  multipleRichTextCompsAreSelected,
} = multiselectGfppUtils;

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

function canBeGrouped(editorAPI: EditorAPI, compRefs: CompRef[]) {
  return (
    editorAPI.components.is.groupable(compRefs) &&
    !editorAPI.components.is.groupedComponent(compRefs) &&
    editorAPI.components.layout.containsSiblingsOnly(compRefs)
  );
}

/**
 * For mobile animations multiselect
 * @param editorAPI
 * @param compRefs
 * @returns {boolean}
 */
function isSomeContainers(editorAPI: EditorAPI, compRefs: CompRef[]) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(compRefs, (compRef) =>
    animationGfppUtils.isContainerType(editorAPI, compRef),
  );
}

/**
 * For desktop animations multiselect
 * @param editorAPI
 * @param compRefs
 * @returns {boolean}
 */
function isSomeFullWidthContainers(editorAPI: EditorAPI, compRefs: CompRef[]) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(compRefs, (compRef) =>
    animationGfppUtils.isFullWidthContainerType(editorAPI, compRef),
  );
}

export default {
  mainActions(editorAPI: EditorAPI, compRefs: CompRef[]) {
    const isTextSettingsInDesignGfppExperimentOpen = experiment.isOpen(
      'se_textSettingsInDesignGfpp',
    );
    const mainActions = [];
    const groupElementsAction = {
      label: 'gfpp_mainaction_multiselect',
      isApplied: true,
      className: 'gfpp_button_group_elements',
      async onClick(editorAPIArg: EditorAPI, compRefsArg: CompRef[]) {
        await editorAPIArg.components.groupComponents(compRefsArg);
      },
    };

    if (canBeGrouped(editorAPI, compRefs)) {
      mainActions.push(groupElementsAction);
    }

    const richTextMainAction = isTextSettingsInDesignGfppExperimentOpen
      ? null
      : getRichTextMainAction(editorAPI, compRefs);

    if (richTextMainAction) {
      mainActions.push(richTextMainAction);
    }

    return mainActions;
  },
  enabledActions(editorAPI: EditorAPI, compRefs: CompRef[]) {
    const actions: GFPPActionType[] = [ACTIONS.HELP, ACTIONS.LAYOUT];
    if (!isSomeFullWidthContainers(editorAPI, compRefs)) {
      actions.push(ACTIONS.ANIMATION);
    }
    if (isMultiDesignEnabled(editorAPI, compRefs)) {
      actions.push(ACTIONS.DESIGN);
    }
    return actions;
  },
  mobileEnabledActions(editorAPI: EditorAPI, compRefs: CompRef[]) {
    const actions: GFPPActionType[] = [ACTIONS.HIDE, ACTIONS.HELP];
    if (experiment.isOpen('se_enableLayoutGfppForMultiselectOnMobile')) {
      actions.push(ACTIONS.LAYOUT);
    }
    if (!isSomeContainers(editorAPI, compRefs)) {
      actions.push(ACTIONS.ANIMATION);
    }
    if (multipleRichTextCompsAreSelected(editorAPI, compRefs)) {
      actions.push(ACTIONS.SCALE_UP, ACTIONS.SCALE_DOWN);
    }
    return actions;
  },
  presetActions(editorAPI: EditorAPI, compRefs: CompRef[]) {
    return (
      getRichTextPresetActions(editorAPI, compRefs) || {
        help: {
          helpId: 'ebc0c5e0-5b02-4de5-8168-608cab2cf752',
        },
      }
    );
  },
};
