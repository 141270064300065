import React from 'react';

export const XYIndicator = ({
  layoutBoundingBox,
  offsetX,
  offsetY,
  dragging,
}: AnyFixMe) => {
  const xyIndicatorStyle: React.CSSProperties = {
    top: layoutBoundingBox.y,
    left: layoutBoundingBox.x,
  };

  return (
    <div
      className={`x-y-indicator ${dragging ? 'visible' : ''}`}
      style={xyIndicatorStyle}
    >
      x: {Math.floor(offsetX)}, y: {Math.floor(offsetY)}
    </div>
  );
};
