import { Button } from '@wix/wix-base-ui';
import React, { type MouseEvent } from 'react';
import * as util from '@/util';
import styles from './addSectionThumbnailOverlay.scss';

interface AddSectionThumbnailOverlayProps {
  isHovered: boolean;
  buttonContentTranslated: string;
  handleSectionClick: (e: MouseEvent<HTMLDivElement>) => void;
}

export const AddSectionThumbnailOverlay = ({
  isHovered,
  buttonContentTranslated,
  handleSectionClick,
}: AddSectionThumbnailOverlayProps) => {
  return (
    <div
      className={util.cx(styles.addSectionThumbnailOverlay, {
        [styles.addSectionThumbnailOverlayHover]: isHovered,
      })}
    >
      <Button onClick={handleSectionClick} className="btn-md">
        {buttonContentTranslated}
      </Button>
    </div>
  );
};
