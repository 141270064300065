import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as core from '@/core';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import React from 'react';
import { FocusPanelFrame } from '../frames';
import * as symbols from '@wix/santa-editor-symbols';

const bi = coreBi;

interface WelcomeVideoPanelProps {
  title?: string;
  subtitle?: string;
  linkedVideoData?: string;
  content?: object;
  contentClass?: string;
  className?: string;
  panelName?: string;
  shouldNotCloseOnBlur?: boolean;
  onCloseByX?: () => void;
  onCloseByESC?: () => void;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<WelcomeVideoPanelProps>({
  displayName: 'welcomeScreenPanel',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    linkedVideoData: PropTypes.string,
    content: PropTypes.object,
    contentClass: PropTypes.string,
    className: PropTypes.string,
    panelName: PropTypes.string,
    onCloseByX: PropTypes.func,
    onCloseByESC: PropTypes.func,
  },

  close() {
    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },

  closeByX() {
    if (_.isFunction(this.props.onCloseByX)) {
      this.props.onCloseByX();
    }
    this.close();
  },

  getKeyboardShortcuts() {
    return {
      esc: this.closeByESC,
    };
  },

  closeByESC() {
    if (_.isFunction(this.props.onCloseByESC)) {
      this.props.onCloseByESC();
    } else {
      this.getEditorAPI().bi.event(
        bi.events.welcomeVideoPanel.WELCOME_SCREEN_CLOSED,
        {
          panel_name: this.props.panelName,
          origin: 'ESC',
        },
      );
    }

    this.close();
  },

  handleShouldCloseOnBlur() {
    this.getEditorAPI().bi.event(
      bi.events.welcomeVideoPanel.WELCOME_SCREEN_CLOSED,
      {
        panel_name: this.props.panelName,
        origin: 'outerClick',
      },
    );
    return true;
  },

  getContent(contentPart: AnyFixMe) {
    const { content } = this.props;
    const line = content ? translate(content[contentPart]) : '';
    return line;
  },

  getContentClass() {
    return this.props.contentClass || '';
  },

  render() {
    return (
      <FocusPanelFrame
        ref="frame"
        panelName={this.props.panelName}
        shouldHideHeader={true}
        shouldHideHelp={true}
        isBareMode={true}
        onClose={this.handleShouldCloseOnBlur}
        keyboardShortcuts={this.getKeyboardShortcuts()}
        shouldNotCloseOnBlur={this.props.shouldNotCloseOnBlur}
        className={`welcome-screen-panel ${this.props.className || ''}`}
      >
        <header key="header" className="panel-header dark">
          <button
            onClick={() => {
              this.closeByX();
            }}
            className="close"
          >
            <symbols.symbol name="headerCloseButton" />
          </button>
          <div className="welcome-panel-header-title">{this.props.title}</div>
          <div className="welcome-panel-header-subtitle">
            {this.props.subtitle}
          </div>
          <div className="videoInput-wrapper">
            <iframe
              src={this.props.linkedVideoData}
              data-aid="welcome-video-panel-iframe"
              frameBorder="0"
              allowFullScreen
            />
            <div className="video-pixel-cover" />
          </div>
        </header>

        <div className={`content ${this.getContentClass()}`}>
          <p>
            {this.getContent('line1')} {this.getContent('link')}
          </p>
          <p>{this.getContent('line2')}</p>
        </div>
        {'\n'}
        {/* css bug */}
        {this.props.children}
      </FocusPanelFrame>
    );
  },
});
