// @ts-nocheck
import ReactDOM from 'reactDOM';
import * as util from '@/util';
import addPanelDataConsts from '@/addPanelDataConsts';

function getVideoSrcPrefix() {
  return `${getAssetsBaseUrl()}video/`;
}
function buildVideoSrcUrl(videoName) {
  const devicePixelRatio = util.browserUtil.getDevicePixelRatio();
  const retinaSuffix = devicePixelRatio >= 2 ? '-@2x' : '';
  // marked out because all editor supported browsers do support mp4 video. use only if complaints (if so then define vidContainer only once)
  //var vidContainer = videoNode.canPlayType('video/mp4; codecs="avc1.4D401E, mp4a.40.2"') !== 'probably' ? '.webm' : '.mp4';
  const vidContainer = '.mp4';
  return videoName + retinaSuffix + vidContainer;
}

function getAssetsBaseUrl() {
  return util.serviceTopology.editorAssetsBaseUrl;
}

function shouldShowVideo(evt) {
  const videoNode = evt.target;
  return !videoNode.paused && videoNode.currentTime > 0;
}

function addVideoListeners(videoNode, listener) {
  videoNode.addEventListener('timeupdate', listener);
}

function removeVideoListeners(videoNode, listener) {
  videoNode.removeEventListener('timeupdate', listener);
}

export default {
  getInitialState() {
    return {
      videoPlaying: false,
    };
  },
  getVideoPosterSrc() {
    return `${getAssetsBaseUrl()}image/${this.props.preset.video.poster}`;
  },
  hasVideo() {
    return (
      this.props.hoverImageAction ===
        addPanelDataConsts.HOVER_IMAGE_ACTIONS.VIDEO &&
      !!this.props.preset.video
    );
  },
  playVideo() {
    const videoNode = ReactDOM.findDOMNode(this.refs.video);
    const videoPrefixUri = this.props.preset.video.source || '';

    const videoName = ['texteffects', 'videobox'].some((val) =>
      videoPrefixUri.toLowerCase().includes(val),
    )
      ? util.media.getMediaUrl(videoPrefixUri)
      : getVideoSrcPrefix() + videoPrefixUri;

    addVideoListeners(videoNode, this.onStartedPlaying);

    if (videoNode.readyState === videoNode.HAVE_NOTHING) {
      videoNode.src = buildVideoSrcUrl(videoName);
      videoNode.load();
    }

    videoNode.play();
  },
  pauseVideo() {
    const videoNode = ReactDOM.findDOMNode(this.refs.video);

    removeVideoListeners(videoNode, this.onStartedPlaying);
    this.setState({ videoPlaying: false });
  },
  isVideoReady(node) {
    return node.readyState !== node.HAVE_NOTHING;
  },
  componentDidMount() {
    this.mounted = true;
  },
  componentDidUpdate(prevProps, prevState) {
    if (prevState.videoPlaying && !this.state.videoPlaying) {
      const videoNode = ReactDOM.findDOMNode(this.refs.video);

      window.requestAnimationFrame(function () {
        videoNode.pause();
        if (videoNode.readyState >= videoNode.HAVE_CURRENT_DATA) {
          videoNode.currentTime = 0;
        }
      });
    }
  },
  componentWillUnmount() {
    this.mounted = false;
  },
  onStartedPlaying(evt) {
    if (this.state.videoPlaying) {
      return;
    }
    if (shouldShowVideo(evt)) {
      window.requestAnimationFrame(
        function () {
          if (this.mounted) {
            const videoNode = evt.target;
            videoNode.currentTime = 0;
            this.setState({ videoPlaying: true });
          }
        }.bind(this),
      );
    }
  },
};
