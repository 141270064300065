import { biLogger } from '@/util';
import { translate } from '@/i18n';
import { connectWithScope } from '@/apilib';
import styles from './styles/genericComponentControl.scss';
import { GENERIC_ITEM_THUMBNAIL_WIDTH } from '../consts';
import { SafeInjectHtml } from '@/util';
import React, { useEffect, useRef, useState } from 'react';
import { QuickEditEngineScope } from '../quickEditEngineEntryPoint';
import { ErrorReporter } from '@wix/editor-error-reporter';
import {
  Button,
  ControlHeader,
  Composites,
  SecondaryMediaImage,
  Tooltip,
} from '@wix/wix-base-ui';
import { quickEditPanelReportings } from '@wix/bi-logger-editor/v2';
import { DataSmall } from '@wix/wix-ui-icons-common/classic-editor';
import { QuickEditControlActions } from '@wix/editor-elements-types/quickEditControls';

import type { QuickEditControlOwnProps } from '@wix/editor-elements-types/quickEditControls';

interface GenericComponentControlDispatchProps {
  getComponentSnapshot: () => string;
}

interface GenericComponentControlProps
  extends QuickEditControlOwnProps,
    GenericComponentControlDispatchProps {}
const GenericComponentControl: React.FC<GenericComponentControlProps> =
  React.memo(
    ({
      onHelp,
      children,
      displayName,
      onMainActionClick,
      onActionClick,
      hasPrimaryConnection,
      isConnectedToDataBinding,
      getComponentSnapshot,
    }) => {
      const snapshotRef = useRef<HTMLDivElement>(null);
      const [scale, setScale] = useState<number>(0);
      useEffect(() => {
        setScale(
          GENERIC_ITEM_THUMBNAIL_WIDTH / snapshotRef.current.offsetWidth,
        );
      }, []);
      return (
        <>
          <Composites.SecondaryMediaSmallLabeled>
            <ControlHeader
              labelProps={{
                text: displayName,
              }}
              infoIconProps={
                isConnectedToDataBinding
                  ? undefined
                  : {
                      text: 'edit_section_generic_element_tooltip',
                      linkText: 'edit_section_generic_element_tooltip_cta',
                      onLinkClick: onHelp,
                    }
              }
              suffix={
                isConnectedToDataBinding
                  ? [
                      <Tooltip content={'edit_section_data_connected_label'}>
                        <DataSmall />
                      </Tooltip>,
                    ]
                  : undefined
              }
            />
            <SecondaryMediaImage size="small">
              <SafeInjectHtml
                tag="div"
                style={{ transform: `scale(${scale})` }}
                ref={snapshotRef}
                className={styles.quickEditGenericThumbnail}
                html={getComponentSnapshot()}
              />
            </SecondaryMediaImage>
            <Button
              className="btn-sm"
              onClick={
                hasPrimaryConnection
                  ? () => onMainActionClick()
                  : () => onActionClick(QuickEditControlActions.SETTINGS)
              }
              disabled={isConnectedToDataBinding}
            >
              <span>{translate('edit_section_settings_tooltip')}</span>
            </Button>
          </Composites.SecondaryMediaSmallLabeled>
          {children}
        </>
      );
    },
    () => true,
  );

GenericComponentControl.displayName = 'GenericComponentControl';

const mapDispatchToProps = (
  { editorAPI }: QuickEditEngineScope,
  { compRef }: QuickEditControlOwnProps,
): GenericComponentControlDispatchProps => {
  return {
    getComponentSnapshot: () => {
      try {
        return editorAPI.snapshot.getSnapshot(compRef);
      } catch (e) {
        const componentType = editorAPI.components.getType(compRef);
        biLogger.report(
          quickEditPanelReportings({
            component_id: compRef.id,
            component_type: componentType,
            error_type: 'genericControlSnapshot',
          }),
        );
        ErrorReporter.captureException(e, {
          tags: {
            quickEditPanel: true,
            getComponentSnapshot: true,
          },
          extra: {
            componentType,
          },
        });
        return '';
      }
    },
  };
};

export default connectWithScope(
  () => QuickEditEngineScope,
  GenericComponentControl,
  null,
  mapDispatchToProps,
) as React.FC<QuickEditControlOwnProps>;
