// @ts-nocheck
import _ from 'lodash';

import React from 'react';
import { stylablePanel as StylablePanel } from '@/designPanel';

import type { WidgetDesignProps } from './types';

class WidgetDesignPanelStylableSection extends React.Component<WidgetDesignProps> {
  getStylablePanelProps = () => {
    const { activeTab, componentAdapter } = this.props;
    const selectedComponents =
      componentAdapter.getSelectedComponents(activeTab);
    const selectedComponent = _.head(selectedComponents);
    return Object.assign({}, this.props, {
      selectedComponent: [selectedComponent],
      selectedComponents: [selectedComponent],
      multiSelectedComponents: selectedComponents,
      compType: this.props.getCompType(selectedComponent),
    });
  };

  render() {
    return (
      <div key="advancedStylePanelClass">
        <StylablePanel {...this.getStylablePanelProps()} />
      </div>
    );
  }
}

export default WidgetDesignPanelStylableSection;
