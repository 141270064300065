import {
  DEFAULT_MOBILE_IMAGE_WIDTH,
  getProportionalImageHeight,
} from '../../../sections/listSection/imageSectionUtils';
import { userPreferences } from '@/stateManagement';
import { attemptToAddComponent } from '../../../addPanel/addPanelUtils';
import { constants as mediaManagerPanelConsts } from '@/mediaManagerPanelInfra';
import { BI_ORIGIN } from '../../constants';
import { biLogger } from '@/util';
import { addMenuImageSectionButtonClick } from '@wix/bi-logger-editor/v2';

import type { MouseEvent } from 'react';
import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';
import type { CompRef, CompStructure, Point } from 'types/documentServices';
import type { Dispatch, StateMapperArgs } from 'types/redux';
import type { DraggableItem } from '../../../addPanel/dragToStage/AddPanelDragHandler';
import type { SearchSectionOwnProps } from './dynamicMediaBoxWrapper';

const {
  ADD_TO_STAGE_METHOD,
  MEDIA_MANAGER_OPEN_OPTIONS,
  MEDIA_MANAGER_UPLOAD_SOURCES,
} = mediaManagerPanelConsts;

const adjustMobileImageWidthIfNeeded = (compStructure: CompStructure): void => {
  if (compStructure.layout.width > DEFAULT_MOBILE_IMAGE_WIDTH) {
    compStructure.layout.width = DEFAULT_MOBILE_IMAGE_WIDTH;
    compStructure.layout.height = getProportionalImageHeight(
      compStructure.layout.width,
      compStructure.data.width,
      compStructure.data.height,
    );
  }
};

export const mapStateToProps = ({ editorAPI }: StateMapperArgs) => {
  return {
    siteMediaToken: editorAPI.dsRead.generalInfo.media.getSiteUploadToken(),
  };
};

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: StateMapperArgs,
): EditorAPI => editorAPI;

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { section, startItemDrag, query }: SearchSectionOwnProps,
) => {
  const editorAPI = dispatch(getEditorAPI);

  const setMediaManagerPath = (itemStructure: Partial<CompStructure>) => {
    const sectionId = section.props?.items?.[0]?.id;
    if (sectionId) {
      const info = MEDIA_MANAGER_OPEN_OPTIONS[sectionId]();
      dispatch(
        userPreferences.actions.setSessionUserPreferences(
          `last_media_path_${itemStructure.data.uri}`,
          info.path || '',
        ),
      );
    }
  };

  const getItemStructure = (itemStructure: Partial<CompStructure>) => {
    const compStructure: CompStructure = Object.assign(
      editorAPI.components.buildDefaultComponentStructure(
        itemStructure.componentType,
      ),
      itemStructure,
    );
    if (editorAPI.isMobileEditor()) {
      adjustMobileImageWidthIfNeeded(compStructure);
    }
    return compStructure;
  };

  const onItemDragStart = (
    event: MouseEvent,
    { rect, structure }: Partial<DraggableItem>,
  ) => {
    if (editorAPI.isMobileEditor()) {
      adjustMobileImageWidthIfNeeded(structure as CompStructure);
    }
    return startItemDrag(event, {
      rect,
      structure,
      tags: '',
      section: '',
      itemId: section.sectionName,
      dragOrigin: BI_ORIGIN,
      sectionTitle: section.sectionName,
      categoryId: section.mediaType,
      onDrop: async (
        position: Point,
        dragItem: DraggableItem,
        dropContainer: CompRef,
      ) => {
        const itemStructure = getItemStructure(dragItem.structure);
        const compRef = await attemptToAddComponent(
          editorAPI,
          itemStructure,
          'image',
          `exploreImages_${section.sectionName}${query ? '_search' : ''}`,
          '',
          section.sectionName,
          BI_ORIGIN,
          null,
          { dropContainer, position },
          ADD_TO_STAGE_METHOD.DRAG,
        );
        setMediaManagerPath(itemStructure);

        editorAPI.components.hooks.componentAddedToStage.fire({
          compRef,
          type: 'drag',
          origin: 'addPanel',
        });
      },
    });
  };

  const openMediaManager = (
    callback: () => void,
    sectionName: string = null,
    subSectionName: string = null,
  ): void => {
    const { mediaManager } = editorAPI.mediaServices;

    biLogger.report(
      addMenuImageSectionButtonClick({
        section: sectionName,
        sub_section: `${subSectionName}_search`,
      }),
    );

    const customSource = `${BI_ORIGIN}_upload_media_button_search`;

    mediaManager.open(mediaManager.categories.ALL_MEDIA, customSource, {
      multiSelect: true,
      path: `external/${MEDIA_MANAGER_UPLOAD_SOURCES.COMPUTER}`,
      callback,
    });
  };

  const handleItemClick = async (
    itemStructure: Partial<CompStructure>,
    sectionTitle: string,
    sectionTags: string[],
    itemId: string,
    editor: any,
    additionalData: {
      appDefinitionId?: string;
      addComponentOptions?: {
        position?: number;
        category?: string;
        section?: string;
      };
    },
    addingMethod: string = ADD_TO_STAGE_METHOD.CLICK,
  ) => {
    const compRef = await attemptToAddComponent(
      editorAPI,
      getItemStructure(itemStructure),
      'image',
      `exploreImages_${section.sectionName}${query ? '_search' : ''}`,
      '',
      section.sectionName,
      BI_ORIGIN,
      null,
      {},
      addingMethod,
    );
    setMediaManagerPath(itemStructure);

    editorAPI.components.hooks.componentAddedToStage.fire({
      compRef,
      type: addingMethod as any,
      origin: 'addPanel',
    });
  };

  return {
    handleItemClick,
    onItemDragStart,
    openMediaManager,
  };
};
