import type { GFPPActionType } from '@wix/editor-component-types';

export default {
  ACTIONS: {
    SETTINGS: 'settings',
    LAYOUT: 'layout',
    DESIGN: 'design',
    UPGRADE: 'upgrade',
    FILTERS: 'filters',
    CROP: 'crop',
    MOTION: 'motion',
    ANIMATION: 'animation',
    LINK: 'link',
    HIDE: 'hide',
    HELP: 'help',
    STRETCH: 'stretch',
    STRETCH_FOR_COLUMNS: 'stretchForColumns',
    PIN_MODE: 'pinMode',
    CONNECT: 'connect',
    MANAGE: 'manage',
    CUSTOMIZE_DESIGN: 'customizeDesign',
    APPLY_TO_OTHER_VIEW: 'applyToOtherView',
    ADD: 'add',
    CUSTOM_CONNECT: 'customConnect',
  } as Record<string, GFPPActionType>,
  PANELS: {
    SETTINGS: 'settings',
    LAYOUT: 'layout',
    DESIGN: 'design',
    BG_EFFECTS: 'effects',
    FILTERS: 'filters',
  },
  ANIMATION_TYPES: ['screenIn'],
} as const;
