import React, { useEffect } from 'react';
import {
  Composites,
  Divider,
  InfoIcon,
  TextLabel,
  Thumbnails,
  ToggleSwitch,
  Tooltip,
} from '@wix/wix-base-ui';
import * as Symbols from '@wix/santa-editor-symbols';
import _ from 'lodash';
import * as imageKit from '@wix/image-kit';
import { imageTransform, backgroundUtils, biLogger } from '@/util';
import type { CoverImageProps } from './types';
import type { MediaManagerImage, MediaManagerInfo } from '@/mediaServices';
import {
  singleVideoPlayerCoverImageChanged,
  singleVideoPlayerCoverImageFadeOutChanged,
  singleVideoPlayerCoverImageAddClick,
} from '@wix/bi-logger-editor/v2';
import styles from './coverImage.scss';

const POSTER_THUMB_SIZE = { width: 90, height: 90 };

export default function NewCoverImage(props: CoverImageProps) {
  const uri = props.compData?.videoRef?.posterImageRef?.uri;
  const isVideoUpload = props.compData?.videoType === 'file';

  const { mediaPlayerVideoCover, setMediaPlayerVideoCover } = props;
  useEffect(() => {
    if (uri && !mediaPlayerVideoCover) {
      setMediaPlayerVideoCover(props.compData?.videoRef?.posterImageRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri, mediaPlayerVideoCover]);

  const urlFromFileId = (uri: string) =>
    imageTransform.getFromImageData(
      {
        uri,
        width: 0,
        height: 0,
      },
      POSTER_THUMB_SIZE.width,
      POSTER_THUMB_SIZE.height,
      imageKit.fittingTypes.SCALE_TO_FILL,
      undefined,
      undefined,
    )?.uri;

  const onPosterThumbnailClick = (uri: string) => {
    const mediaData = props.compData.videoRef;
    const generatedPosters = _.take(mediaData.generatedPosters, 3);

    biLogger.report(
      singleVideoPlayerCoverImageChanged({
        actionType: 'select',
        videoId: props.compData?.videoRef?.videoId,
        coverUrl: urlFromFileId(uri),
        cover_index: generatedPosters.indexOf(uri) + 1, //  1-3, 0 is custom
      }),
    );
    props.updatePartialData('videoRef.posterImageRef.uri', uri);
  };

  const applyOrRemovePosterAnimation = (value: boolean) => {
    biLogger.report(
      singleVideoPlayerCoverImageFadeOutChanged({
        isEnabled: value,
        videoId: props.compData?.videoRef?.videoId,
      }),
    );
    props.updatePartialProperties('animatePoster', value ? 'fade' : 'none');
  };

  const onMediaManagerPosterCallback = (
    payload: MediaManagerImage[],
    info: MediaManagerInfo,
  ) => {
    if (!payload) {
      return;
    }

    const mediaObj = backgroundUtils.getImageBackgroundObject(
      _.head(payload),
      info,
    );

    props.updatePartialData('videoRef.posterImageRef', _.clone(mediaObj));
    props.setPosterMMGRPath(mediaObj.uri, info.path);
    props.setMediaPlayerCompCover(mediaObj);
    props.setMediaPlayerVideoCover(mediaObj);
  };

  const changeCover = () => {
    const { mediaManager, compType } = props;
    const hasSelectedOwnCover = getExtraCoverThumb();

    biLogger.report(
      singleVideoPlayerCoverImageAddClick({
        videoId: props.compData?.videoRef?.videoId,
        component_type: compType,
        action_type: hasSelectedOwnCover ? 'change' : 'add',
      }),
    );

    const category = mediaManager.categories.IMAGE;
    mediaManager.open(category, {
      path: props.getPosterMMGRPath,
      callback: (items: MediaManagerImage[], info: MediaManagerInfo) => {
        biLogger.report(
          singleVideoPlayerCoverImageChanged({
            videoId: props.compData?.videoRef?.videoId,
            coverUrl: urlFromFileId(items[0]?.fileName),
            actionType: hasSelectedOwnCover ? 'change' : 'add',
            cover_index: 0,
          }),
        );
        onMediaManagerPosterCallback(items, info);
        props.setFocusToEditorFunc();
      },
    });
  };

  const getExtraCoverThumb = () => {
    if (!props.compData.videoRef) {
      return null;
    }
    const mediaData = props.compData.videoRef;
    const mediaObj =
      props.mediaPlayerCompCover ||
      props.mediaPlayerVideoCover ||
      mediaData.posterImageRef;

    const isCustomCover = !mediaData.generatedPosters?.includes(mediaObj.uri);

    if (isCustomCover) {
      const transformedData = imageTransform.getFromImageData(
        { width: 0, height: 0, ...mediaObj },
        POSTER_THUMB_SIZE.width,
        POSTER_THUMB_SIZE.height,
        imageKit.fittingTypes.SCALE_TO_FILL,
        undefined,
        undefined,
      );
      return {
        value: mediaObj.uri,
        illustration: (
          <img
            src={transformedData.uri}
            width={transformedData.css.img.width}
            height={transformedData.css.img.height}
          />
        ),
      };
    }
  };

  const getAddThumbProps = () => {
    if (getExtraCoverThumb()) {
      return null;
    }
    return {
      isFirst: true,
      onAddThumbnailClick: changeCover,
      height: POSTER_THUMB_SIZE.height,
      label: '',
    };
  };

  const getPosterOptions = (props: CoverImageProps) => {
    //get original image size from video qualities
    const mediaData = props.compData.videoRef;
    const { width, height } =
      mediaData.qualities[mediaData.qualities.length - 1];
    const fittingType = props.hasAlpha
      ? imageKit.fittingTypes.SCALE_TO_FIT
      : imageKit.fittingTypes.SCALE_TO_FILL;

    // panel displays the first 3
    const generatedPosters = _.take(mediaData.generatedPosters, 3);

    const thumbOptions = generatedPosters.map((uri) => {
      const transformedData = imageTransform.getFromImageData(
        { uri, width, height },
        POSTER_THUMB_SIZE.width,
        POSTER_THUMB_SIZE.height,
        fittingType,
        undefined,
        undefined,
      );
      return {
        value: uri,
        illustration: React.createElement('img', {
          src: transformedData.uri,
          width: transformedData.css.img.width,
          height: transformedData.css.img.height,
          style: transformedData.css.img,
        }),
      };
    });

    const extraCoverThumb = getExtraCoverThumb();

    if (extraCoverThumb) {
      return [extraCoverThumb].concat(thumbOptions);
    }

    return thumbOptions;
  };

  return (
    <div className={props.className}>
      <Composites.Thumbnails>
        {props.tabsUiMode === 'tabs' ? (
          <>
            <InfoIcon
              shouldTranslate
              text="Custom.design_video.player_cover.Image_tab_tooltip"
            />
            <TextLabel
              value="Custom.design_video.player_cover.Image_tab_subtitle"
              shouldTranslate={true}
              enableEllipsis={false}
            />
          </>
        ) : null}
        <Thumbnails
          value={uri}
          onChange={(thumbUri: string) => onPosterThumbnailClick(thumbUri)}
          maxThumbsPerRow={2}
          borderType="thumbnail"
          fixedRatio={true}
          addThumbnailProps={getAddThumbProps()}
          options={isVideoUpload ? getPosterOptions(props) : null}
          shouldTranslate={true}
        />
      </Composites.Thumbnails>
      {/* START: Thumbnails add poster indicator, should be in Base UI Lib */}
      {!getAddThumbProps() ? (
        <div
          key="changebutton"
          onClick={changeCover}
          className={styles.mediaAddPosterIndicator}
        >
          <Tooltip
            shouldTranslate={true}
            closeOnMouseClick={true}
            content="Custom.design_video.player_cover.Image_tab_change_tooltip"
          >
            <Symbols.symbol name="video-player-change-poster" />
          </Tooltip>
        </div>
      ) : null}
      {/* END: Thumbnails add poster indicator, should be in Base UI Lib */}
      <Divider long={false} />
      <Composites.ToggleSwitch>
        <ToggleSwitch
          value={props.compProperties.animatePoster === 'fade'}
          label="Custom.design_video.player_cover.Image_tab_fade_toggle"
          shouldTranslate={true}
          onChange={(value: boolean) => applyOrRemovePosterAnimation(value)}
          dataHook="animate-poster-toggle"
        />
      </Composites.ToggleSwitch>
    </div>
  );
}
