import type { Scope } from './textClassificationEntryPoint';
import { BasePublicApi } from '@/apilib';
import type { CompRef } from '@wix/document-services-types';
import type { TextClassificationResponseData } from './prediction/types';
import { getPrediction } from '../textClassification/prediction/prediction';

export interface Config {
  getTextClassification: (scope: Scope, sectionRef: CompRef) => void;
}

export async function getTextClassification(
  scope: Scope,
  pageId: string,
  compRef: CompRef,
): Promise<TextClassificationResponseData> {
  return getPrediction(scope.editorAPI.documentServices, pageId, compRef);
}

export class TextClassificationApi extends BasePublicApi<Scope> {
  getTextClassification = this.bindScope(getTextClassification);
}
