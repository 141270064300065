import addSectionPanel from './addSection/addSectionPanel';
import addPagePanel from './addPage/addPagePanel/addPagePanel';
import replacePageModal from './addPage/addPagePanel/components/replacePageModal';
import SingleThumbnailPreviewModal from './addPage/pagePreview/singleThumbnailPreviewModal';
import { PageAlreadyAddedModal } from './addPage/addPagePanel/components/pageAlreadyAddedModal';
import { HtmlPreviewThumbnail } from './htmlPreviewThumbnail/htmlPreviewThumbnail';
import MultiplePresetIndication from './addPage/pagePreview/multiplePresetIndication';
import HtmlPreviewThumbnailManager from './htmlPreviewThumbnail/htmlPreviewThumbnailManager';

export {
  HtmlPreviewThumbnail,
  MultiplePresetIndication,
  HtmlPreviewThumbnailManager,
};
export const panels = {
  addPagePanel,
  addSectionPanel,
  singlePreviewModal: SingleThumbnailPreviewModal,
  pageAlreadyAddedModal: PageAlreadyAddedModal,
  replacePageModal,
};

export * as utils from './addPresetUtil';
export { AddPresetApiKey } from './publicApiKey';
export { AddPresetApiEntryPoint } from './addPresetApiEntry';
export {
  ADD_PRESET_SERVERLESS_BASE_URL,
  ADD_PRESET_MP_APP_DOMAIN,
  ADD_PRESET_MP_BASE_URL,
  SCANNING_WIDTH,
  TPA_CLONE_DATA_APPS,
} from './addPresetConsts';

export type {
  PagePresetDefinition,
  AddPageCategory,
  AddPageCategoryApplication,
  SectionPresetDefinition,
  HeaderPresetDefinition,
  FooterPresetDefinition,
  ExternalPresetOptions,
  PresetDefinition,
} from './types';
