import { translate } from '@/i18n';
import type { MenuData } from 'types/documentServices';
import { menuBiLogger } from '../../../bi/menuBiLogger';
import { TRANSLATIONS_MAP } from '../utils/translations';
import { AUTOMATION_IDS } from '../utils/automationIds';

interface IMenuActionsConfig {
  allowDelete: boolean;
  menu: MenuData;
  onEdit(menuId: string): void;
  onDuplicate(menuId: string, expectedTitle: string): void;
  onDelete(menuId: string): void;
}

interface IMenuAction {
  label: string;
  action: () => void;
}

type IMenuActions = Record<string, IMenuAction>;

const MAX_ITEM_LENGTH = 40;

export const getMenuActions = ({
  menu,
  onEdit,
  onDuplicate,
  onDelete,
  allowDelete,
}: IMenuActionsConfig): Record<string, IMenuAction> => {
  const editAction = {
    action: () => {
      menuBiLogger.logContextMenuRenameClick(menu);
      onEdit(menu.id);
    },
    label: translate(TRANSLATIONS_MAP.ITEM.ACTIONS.rename),
    priority: 2,
    labelMaxLength: MAX_ITEM_LENGTH,
    automationId: AUTOMATION_IDS.ITEM.ACTIONS.RENAME,
  };

  const duplicateAction = {
    action: () => {
      const name = translate(TRANSLATIONS_MAP.ITEM.duplicatedTitle, {
        menu_name: menu.name,
      });

      menuBiLogger.logContextMenuDuplicateClick(menu);
      onDuplicate(menu.id, name);
    },
    label: translate(TRANSLATIONS_MAP.ITEM.ACTIONS.duplicate),
    priority: 1,
    automationId: AUTOMATION_IDS.ITEM.ACTIONS.DUPLICATE,
  };

  const deleteAction = {
    action: () => {
      menuBiLogger.logContextMenuRemoveClick(menu);
      onDelete(menu.id);
    },
    label: translate(TRANSLATIONS_MAP.ITEM.ACTIONS.delete),
    priority: 0,
    automationId: AUTOMATION_IDS.ITEM.ACTIONS.REMOVE,
  };

  const actions: IMenuActions = {
    duplicate: duplicateAction,
  };

  actions.toggleEditLabel = editAction;

  if (allowDelete) {
    actions.delete = deleteAction;
  }

  return actions;
};
