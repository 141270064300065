// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import PropTypes from 'prop-types';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import panelCloseOrigin from '../../frames/panelCloseOrigin';
import React from 'react';
import {
  Button,
  Composites,
  Divider,
  InfoIcon,
  TextInput,
  TextInputMultiline,
  TextLabel,
} from '@wix/wix-base-ui';
import { ToolPanelFrame } from '../../frames';

//TYPE WAS GENERATED, remove this line when reviewed
interface APIDefinitionEventProps {
  event: AnyFixMe;
  title: string;
  mainActionLabel: string;
  onChange: FunctionFixMe;
  close: FunctionFixMe;
  illegalEventNames?: Array<string>;
  contentStyle?: {
    top?: number;
    right?: number;
    width?: number;
  };
  callBi: FunctionFixMe;
}

class APIDefinitionEvent extends React.Component<APIDefinitionEventProps> {
  static displayName = 'apiDefinitionEvent';

  static propTypes = {
    event: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    mainActionLabel: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    illegalEventNames: PropTypes.arrayOf(PropTypes.string),
    contentStyle: PropTypes.shape({
      top: PropTypes.number,
      right: PropTypes.number,
      width: PropTypes.number,
    }),
    callBi: PropTypes.func.isRequired,
  };

  static defaultProps = {
    event: {
      name: '',
      description: '',
    },
  };

  state = {
    name: this.props.event.name,
    description: this.props.event.description,
  };

  handleClickAction = () => {
    this.props.onChange({
      name: this.state.name,
      description: this.state.description,
    });
    this.props.callBi();
  };

  closePanel = (origin) => {
    if (origin === panelCloseOrigin.CLICK_OUTSIDE && this.isFormValid()) {
      this.handleClickAction();
      return;
    }
    this.props.close();
  };

  isEventNameValid = (propName) => {
    return (
      util.validate.eventName(propName) &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      !_.includes(this.props.illegalEventNames, propName)
    );
  };

  isFormValid = () => {
    return this.isEventNameValid(this.state.name);
  };

  render() {
    return (
      <ToolPanelFrame
        panelName="panels.toolPanels.apiDefinitionEvent"
        onClose={(origin) => this.closePanel(origin)}
        headerTitle={translate(this.props.title)}
        contentStyle={this.props.contentStyle}
        automationId="api-definition-event"
        className="api-definition-event"
      >
        <Composites.TextInputLabeled>
          <InfoIcon text="AppStudio_WidgetAPI_NewEvent_Name_Tooltip" />
          <TextLabel value="AppStudio_WidgetAPI_NewEvent_Name_label" />
          <TextInput
            value={this.state.name}
            placeholder="AppStudio_WidgetAPI_NewEvent_Name_Placeholder"
            validator={this.isEventNameValid}
            allowInvalidChange={true}
            validateInitialValue={false}
            invalidMessage="AppStudio_WidgetAPI_NewEvent_Name_InvalidName_Error"
            onChange={(name) => {
              this.setState({ name });
            }}
            className="add-event-name"
          />
        </Composites.TextInputLabeled>

        <Divider long={true} />

        <Composites.TextInputMultilineLabeled>
          <InfoIcon text="AppStudio_WidgetAPI_NewEvent_Description_Tooltip" />
          <TextLabel value="AppStudio_WidgetAPI_NewEvent_Description_Label" />
          <TextInputMultiline
            value={this.state.description}
            automationId="add-event-description"
            placeholder="AppStudio_WidgetAPI_NewEvent_Description_Placeholder"
            onChange={(description) => {
              this.setState({ description });
            }}
            className="widget-event-description"
          />
        </Composites.TextInputMultilineLabeled>

        <Composites.ButtonMedium>
          <Button
            disabled={!this.isFormValid()}
            automationId="add-event-main-button"
            onClick={this.handleClickAction}
            className="btn-md"
          >
            <span>{translate(this.props.mainActionLabel)}</span>
          </Button>
        </Composites.ButtonMedium>
      </ToolPanelFrame>
    );
  }
}

const mapStateToProps = ({ dsRead }, ownProps) => {
  const rootCompId = dsRead.pages.getFocusedPageId();
  const selectedWidgetPointer =
    dsRead.appStudio.widgets.getByRootCompId(rootCompId);
  const allEvents = dsRead.appStudio.widgets.events.get(selectedWidgetPointer);
  const illegalEventNames = _(allEvents)
    .reject({ name: ownProps?.event?.name })
    .map('name')
    .value();

  return {
    illegalEventNames,
  };
};

const Connected = _.flow(
  util.hoc.renderWhenMutated,
  util.hoc.connect(util.hoc.STORES.DS_ONLY, mapStateToProps),
  stateManagement.panels.hoc.panelFrame(constants.PANEL_TYPES.TOOL),
)(APIDefinitionEvent);
export default Connected;
