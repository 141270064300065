import addPanelDataConsts from '@/addPanelDataConsts';
import onlineProgramsWelcome from './onlineProgramsWelcome';

const onlineProgramsWelcomeSection = {
  type: addPanelDataConsts.SECTIONS_TYPES.GENERAL,
  hide: false,
  title: 'add_section_label_online_programs',
  showSectionHeader: true,
  props: {
    class: 'no-space-before-section invisible-header',
    template: onlineProgramsWelcome,
  },
  help: {
    hide: false,
    text: 'add_section_label_membersarea_tooltip_description',
  },
};

export default {
  getCategorySections() {
    return {
      onlineProgramsWelcomeSection,
    };
  },
};
