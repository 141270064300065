import { BaseEntryScope } from '@/apilib';
import { EditorAPIKey, WorkspaceApiKey, EditorCoreApiKey } from '@/apis';
import { ComponentFocusModeStore } from './componentFocusModeStore';

export class ComponentFocusModeScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  workspace = this.useDependency(WorkspaceApiKey);
  store = this.declareStore(ComponentFocusModeStore);
  editorCoreApi = this.useDependency(EditorCoreApiKey);
}
