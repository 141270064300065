import _ from 'lodash';
import * as util from '@/util';
import { arrayUtils } from '@/util';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import { gfppModel } from '@/gfppData';
import * as rcmMoveTo from '../rcmDataItemBuilders/rcmMoveToBuilder';
import * as stateManagement from '@/stateManagement';
import experiment from 'experiment';
import * as platform from '@/platform';
import { EditorRestrictionsApiKey, ImageToBackgroundApiKey } from '@/apis';
import { isRefComponent, getRefComponentRootChild } from '@/documentServices';
import { clickOnHideHeaderAndFooter } from '@wix/bi-logger-editor/v2';

import type { CopyMetaData } from '../../../app/APISections/copyPasteWrapper/createCopyPasteUtils';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { GFPPAction, GFPPActionType } from '@wix/editor-component-types';
import type { UIRestrictionRCM } from '../uiRestriction';
import type { RCMItemDataType } from '../types';

let editorAPI: EditorAPI;

const { isAppWidget, getFirstAppWidget } = stateManagement.components.selectors;
export const DEFAULT_BI_ORIGIN = 'rcm';
const { getFocusedContainer } = stateManagement.selection.selectors;
const {
  isInInteractionMode,
  isShownOnlyOnVariant,
  getCompVariantPointer,
  getInteractionTriggerRef,
} = stateManagement.interactions.selectors;
const { resetInteractionChanges: resetInteractionChangesAction } =
  stateManagement.interactions.actions;
const { someDescendantIsPrimaryConnectedToAppWidget } =
  stateManagement.components.selectors;
const userPreferencesSelectors = stateManagement.userPreferences.selectors;
const isAdvancedA11yEnabled = userPreferencesSelectors.getSiteUserPreferences(
  constants.USER_PREFS.ACCESSIBILITY.ADVANCED_SETTINGS,
);
const { GFPP_BUTTON_CLICK, ACCESSIBILITY } = constants.COMP_META_DATA.CONTROLS;

const a11yConditionByTypeMap: AnyFixMe = {};

const containerSizeForWBUIcons = experiment.isOpen('se_newIconsForLayers') && {
  width: 24,
  height: 24,
};

function createUndoItem() {
  return {
    enabled: true,
  };
}

function isDuplicatable(comp: AnyFixMe, container?: AnyFixMe) {
  return (
    editorAPI.components.is.duplicatable(comp, container) &&
    !editorAPI.components.layout.isPinned(comp)
  );
}

function isFlippable(comp: AnyFixMe) {
  return editorAPI.components.is.flippable(comp);
}

function isAllowedAddToSavedComponent(comp: AnyFixMe, container: AnyFixMe) {
  return editorAPI.components.is.allowedAddToSavedComponent(comp, container);
}

function createCutItem(comp: AnyFixMe) {
  if (!isDuplicatable(comp)) {
    return null;
  }

  return {
    enabled: true,
  };
}

function createCopyItem(comp: AnyFixMe) {
  if (!isDuplicatable(comp)) {
    return null;
  }

  return {
    enabled: true,
  };
}

function createPasteItem(
  isRegularMobileComp: boolean,
  rightClickedComponents: CompRef[],
) {
  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const isPasteSectionChildrenInteractive =
    editorRestrictionsApi.allowed<UIRestrictionRCM>(
      'rcm_paste-children.interactive',
    );
  function shouldEnablePaste() {
    const clipboardData: AnyFixMe = editorAPI.clipboard.getItem();
    const clipboardItems: { componentType?: string }[] =
      clipboardData?.value?.components ?? [];
    const clipboardMetaData: CopyMetaData = clipboardData.metaData;
    const isMobileEditor = editorAPI.isMobileEditor();
    const isStageZoomMode = editorAPI.zoomMode.isStageZoomMode();

    if (
      (isMobileEditor && !clipboardMetaData?.copiedOnMobile) ||
      (clipboardMetaData?.copiedOnMobile && !isMobileEditor) ||
      (isMobileEditor &&
        clipboardItems?.some(
          (compDef: AnyFixMe) =>
            !editorAPI.components.is.allowedMobileOnlyByType(
              compDef.componentType,
            ),
        )) ||
      ((isStageZoomMode || !isPasteSectionChildrenInteractive) &&
        !clipboardItems.every(
          (item) => item.componentType === constants.COMP_TYPES.SECTION,
        ))
    ) {
      return false;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const copiedComponentsAppWidgetAncestors = _(clipboardItems)
      .map('appWidgetAncestor')
      .uniqBy('id')
      .compact()
      .value();

    if (_.isEmpty(copiedComponentsAppWidgetAncestors)) {
      return !_.isEmpty(clipboardData);
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const appWidgetsAncestors = _(rightClickedComponents)
      .map((comp) => {
        if (isAppWidget(comp, editorAPI.dsRead)) {
          return comp;
        }

        return getFirstAppWidget(comp, editorAPI.dsRead);
      })
      .uniqBy('id')
      .value();

    const multipleWidgetsOperation =
      appWidgetsAncestors.length > 1 ||
      copiedComponentsAppWidgetAncestors.length > 1;

    return multipleWidgetsOperation
      ? false
      : _.isEqual(
          _.head(appWidgetsAncestors),
          _.head(copiedComponentsAppWidgetAncestors),
        );
  }

  const [firstComponent] = rightClickedComponents;

  if (
    (editorAPI.zoomMode.isStageZoomMode() ||
      !isPasteSectionChildrenInteractive) &&
    editorAPI.sections.isHeaderOrFooter(firstComponent)
  ) {
    return null;
  }

  return {
    enabled: shouldEnablePaste(),
  };
}

function createAddToSavedComponentsItem(comp: AnyFixMe) {
  const container =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(comp);
  if (
    editorAPI.isMobileEditor() ||
    !isAllowedAddToSavedComponent(comp, container)
  ) {
    return null;
  }

  return {
    enabled: true,
  };
}

function createHideInteractionItem(editorAPI: AnyFixMe, compRef: AnyFixMe) {
  if (!isInteractionHideAvailable(editorAPI, compRef)) {
    return null;
  }
  return {
    enabled: true,
  };
}

function createShowInteractionItem() {
  return {
    enabled: true,
  };
}

function createPasteAndApplyThemeItem(isRegularMobileComp: AnyFixMe) {
  if (isRegularMobileComp || !editorAPI.clipboard.isCrossSite()) {
    return null;
  }

  return {
    enabled: true,
  };
}

function createFlipItem(comp: AnyFixMe) {
  if (!isFlippable(comp)) {
    return null;
  }

  return {
    enabled: true,
  };
}

function createApplyModeFromClipboard(isRegularMobileComp: AnyFixMe) {
  if (!isRegularMobileComp && editorAPI.copyPaste.canApplyModeFromClipboard()) {
    return {
      enabled: true,
    };
  }

  return null;
}

function createDuplicateItem(comp: AnyFixMe) {
  const container =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(comp);
  if (!isDuplicatable(comp, container)) {
    return null;
  }

  return {
    enabled: true,
  };
}

function createApplyStyleItem(comp: AnyFixMe) {
  const canStyleBeApplied = editorAPI.components.is.styleCanBeApplied(comp);

  if (!canStyleBeApplied) {
    return null;
  }

  return {
    enabled: true,
  };
}

function createCopyDesignItem(comp: AnyFixMe) {
  const hasExternalDesign = editorAPI.hasExternalDesign(comp);

  if (hasExternalDesign) {
    return null;
  }

  return {
    enabled: true,
  };
}

const TPA_FIXED_COMPS = ['wysiwyg.viewer.components.tpapps.TPAGluedWidget'];

function createArrangeItem(comp: AnyFixMe) {
  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const isArrangeItemsVisible = editorRestrictionsApi.allowed<UIRestrictionRCM>(
    'rcm_arrange-items.visible',
  );
  if (
    editorAPI.components.is.controlledByParent(comp) ||
    !isArrangeItemsVisible
  ) {
    return null;
  }

  const compRestrictions = editorAPI.getCompMoveRestrictions(comp);
  const arrangeable =
    compRestrictions.canMoveBackward || compRestrictions.canMoveForward;

  const compType = editorAPI.components.getType(comp);

  const isFixedTPAWidget =
    editorAPI.components.is.fixed(comp) && TPA_FIXED_COMPS.includes(compType); //TODO: talk to Lior, see if we can have same behavior for TPA fixed as non-tpa fixed comps

  if (!arrangeable || isFixedTPAWidget) {
    return null;
  }

  return {
    type: 'subItems',
    enabled: true,
    subItems: getArrangeSubItems(comp),
  };
}

function getArrangeSubItems(comp: AnyFixMe) {
  const compRestrictions = editorAPI.getCompMoveRestrictions(comp);

  return {
    moveToFront: {
      icon: { svgName: 'bringToFront' },
      enabled: compRestrictions.canMoveForward,
    },
    moveToBack: {
      icon: { svgName: 'sendToBack' },
      enabled: compRestrictions.canMoveBackward,
    },
    moveForward: {
      icon: { svgName: 'moveForward' },
      enabled: compRestrictions.canMoveForward,
    },
    moveBackward: {
      icon: { svgName: 'moveBackward' },
      enabled: compRestrictions.canMoveBackward,
    },
  };
}

function createOverlappingItemsItem(
  rightClickedComponents: CompRef[],
  overlappingComps: CompRef[],
) {
  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const isOverlappingItemsVisible =
    editorRestrictionsApi.allowed<UIRestrictionRCM>(
      'rcm_overlapping-items.visible',
    );
  if (editorAPI.zoomMode.isStageZoomMode() || !isOverlappingItemsVisible) {
    return null;
  }

  let subItems = getOverlappingSubItems(overlappingComps);
  const state = editorAPI.store.getState();
  if (isInInteractionMode(state)) {
    const triggerRef = getInteractionTriggerRef(state);
    subItems = subItems.filter(
      (subItem) =>
        editorAPI.components.isDescendantOfComp(
          subItem.component,
          triggerRef,
        ) || subItem.component.id === triggerRef.id,
    );
  }
  if (
    subItems.length > 1 ||
    (subItems.length === 1 && editorAPI.utils.isPage(rightClickedComponents))
  ) {
    return {
      type: 'subItems',
      shouldShowCount: true,
      enabled: true,
      subItems,
    };
  }
  return null;
}

function isDataSet(compType: AnyFixMe) {
  return compType === 'platform.components.AppController';
}

function getOverlappingSubitemsIcon(comp: CompRef) {
  const iconInfo = editorAPI.components.getIconInfo(
    comp,
    containerSizeForWBUIcons,
    'rcm',
  );
  if (!iconInfo.css) {
    iconInfo.css = {};
  }
  _.merge(iconInfo.css, { containerSize: containerSizeForWBUIcons?.width });

  return iconInfo;
}

function getOverlappingSubItems(overlappingComps: CompRef[]) {
  const overlappedComps = _(overlappingComps)
    .reject(function (comp) {
      return (
        ['SITE_PAGES', 'PAGES_CONTAINER'].includes(comp.id) ||
        isDataSet(editorAPI.components.getType(comp))
      );
    })
    .map(function (comp) {
      return {
        component: comp,
        key: `overlappingSubItem_${comp.id}`,
        icon: getOverlappingSubitemsIcon(comp),
        label:
          util.componentLabel.getComponentLabel(editorAPI, comp) ||
          editorAPI.components.getDisplayName(comp),
        enabled: true,
      };
    })
    .value();
  return overlappedComps.length === 1 ? [] : overlappedComps;
}

function createImageToBackgroundItem(
  editorAPI: EditorAPI,
  rightClickedComponents: CompRef[],
): RCMItemDataType {
  const imageToBackgroundAPI = editorAPI.host.getAPI(ImageToBackgroundApiKey);
  if (
    !imageToBackgroundAPI.isImageToBackgroundEnabled(rightClickedComponents)
  ) {
    return null;
  }
  const allBackgroundCandidates =
    imageToBackgroundAPI.getAllBackgroundCandidates(rightClickedComponents[0]);

  if (!allBackgroundCandidates.length) {
    return null;
  }

  return {
    type: 'subItems',
    enabled: true,
    shouldShowCount: false,
    subItems: allBackgroundCandidates.map((item) => ({
      component: item,
      key: item.id,
      icon: imageToBackgroundAPI.getIconForRCMSubItem(
        item,
        18,
        containerSizeForWBUIcons,
      ),
      label:
        util.componentLabel.getComponentLabel(editorAPI, item) ||
        editorAPI.components.getDisplayName(item),
      enabled: true,
    })),
  };
}

function createDetachImageFromBackgroundItem(
  editorAPI: EditorAPI,
  selectedComponents: CompRef[],
) {
  const imageToBackgroundAPI = editorAPI.host.getAPI(ImageToBackgroundApiKey);
  if (
    !imageToBackgroundAPI.isDetachImageFromBackgroundEnabled(selectedComponents)
  ) {
    return null;
  }

  return {
    enabled: true,
  };
}

function createRemoveItem(comps: AnyFixMe) {
  const comp = Array.isArray(comps) ? comps[0] : comps;
  const isTPA = editorAPI.dsRead.tpa.isTpaByCompType(
    editorAPI.components.getType(comp),
  );
  const isRemovable =
    editorAPI.components.is.removable(comp) ||
    editorAPI.selection.isPopupPageSelected(comp);

  // // TODO: Remove when mobile hide is supported in app studio
  if (util.appStudioUtils.isAppStudio() && editorAPI.isMobileEditor()) {
    return null;
  }
  if (isTPA || isRemovable) {
    return {
      enabled: isRemovable,
      operationKey: 'DELETE',
    };
  }
  return null;
}

function refComponentSOAPDisabledByStageData(
  compRef: CompRef | CompRef[],
): boolean {
  const [refComponentCompRef] = arrayUtils.asArray(compRef);

  if (!isRefComponent(editorAPI.dsRead, refComponentCompRef)) {
    return false;
  }

  const appWidgetCompRef = getRefComponentRootChild(
    editorAPI.dsRead,
    refComponentCompRef,
  );
  const stageData =
    editorAPI.platform.controllers.getStageData(appWidgetCompRef);

  return stageData?.behavior?.toggleShowOnAllPagesEnabled === false;
}

export function shouldForceSOAPForNonContainable(
  editorAPI: EditorAPI,
  compRef: CompRef,
) {
  // QAB could be contained in the master page(=== seen on all pages) by metadata. However, it was possible to attach it to a page before.
  // So now we need to allow users to make QAB to be seen on all pages if it's attached to a page now.
  // This logic is needed because SOAP toggle checks both canAttachToPage && canAttachToMaster page, but this condition is false to QAB.
  if (
    editorAPI.components.getType(compRef) ===
    constants.COMP_TYPES.QUICK_ACTION_BAR
  ) {
    return !editorAPI.components.isShowOnAllPages(compRef);
  }

  return false;
}

function createShowOnAllPagesItem(comp: AnyFixMe) {
  const isFixedPosition = editorAPI.components.is.fixed(comp);
  const isTPA = editorAPI.dsRead.tpa.isTpaByCompType(
    editorAPI.components.getType(comp),
  );
  const isFixedTPA = isTPA && isFixedPosition;
  const shouldShowSOAPDisabled = someDescendantIsPrimaryConnectedToAppWidget(
    comp,
    editorAPI.dsRead,
  );

  if (
    isInInteractionMode(editorAPI.store.getState()) ||
    refComponentSOAPDisabledByStageData(comp) ||
    editorAPI.components.shouldHideShowOnAllPages(comp) ||
    (!editorAPI.components.canToggleShowOnAllPages(comp) &&
      !isFixedTPA &&
      !shouldShowSOAPDisabled &&
      !shouldForceSOAPForNonContainable(editorAPI, comp)) ||
    editorAPI.mobile.mobileOnlyComponents.areAnyMobileOnlyNonNativeComponent(
      comp,
    )
  ) {
    return null;
  }

  const isEnabled = !isFixedTPA && !shouldShowSOAPDisabled;

  return {
    value: isFixedTPA || editorAPI.components.isShowOnAllPages(comp),
    enabled: isEnabled,
    operationKey: 'SHOW_ON_ALL_PAGES',
  };
}

function createFixedPositionItem(comps: CompRef[]) {
  const shouldShowFixedPositionItem =
    editorAPI.components.is.showLegacyFixedPosition(comps);

  return shouldShowFixedPositionItem
    ? {
        value: editorAPI.components.is.fixed(comps),
        enabled: editorAPI.components.layout.canBeFixedPosition(comps),
      }
    : null;
}

function createPinnedItem(comp: AnyFixMe, isRegularMobileComp: AnyFixMe) {
  if (
    isInInteractionMode(editorAPI.store.getState()) ||
    isRegularMobileComp ||
    !editorAPI.components.is.pinnable(comp)
  ) {
    return null;
  }
  const isPinned = editorAPI.components.is.fixed(comp);
  return {
    value: isPinned,
    label: isPinned ? 'RightClick_Menu_Unpin' : 'RightClick_Menu_PinToScreen',
    enabled: true,
  };
}

function createA11yItem(comp: AnyFixMe) {
  const state = editorAPI.store.getState();
  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const isAccessibilityVisible =
    editorRestrictionsApi.allowed<UIRestrictionRCM>('rcm_advancedA11y.visible');
  if (
    isInInteractionMode(editorAPI.store.getState()) ||
    !isAdvancedA11yEnabled(state) ||
    !isAccessibilityVisible
  ) {
    return null;
  }

  const selectedComponent = [
    util.controlsUtils.getFirstEditableCompRef(
      editorAPI,
      _.head(comp),
      ACCESSIBILITY,
    ),
  ];

  const isConfigurable =
    editorAPI.components.is.a11yConfigurable(selectedComponent);

  const compType = editorAPI.components.getType(selectedComponent);
  if (a11yConditionByTypeMap[compType] && !a11yConditionByTypeMap[compType]()) {
    return;
  }

  if (isConfigurable) {
    return {
      enabled: true,
      selectedComponent,
    };
  }

  return null;
}

function createApplyToAllModesItem(isRegularMobileComp: AnyFixMe) {
  if (isInInteractionMode(editorAPI.store.getState()) || isRegularMobileComp) {
    return null;
  }
  const state = editorAPI.store.getState();
  const focusedContainer = getFocusedContainer(state);
  if (!focusedContainer) {
    return null;
  }
  const focusedContainerModes =
    editorAPI.components.modes.getModes(focusedContainer);
  if (!focusedContainerModes || _.isEmpty(focusedContainerModes)) {
    return null;
  }

  const label = editorAPI.components.modes.isCompDefaultModeActive(
    focusedContainer,
  )
    ? 'RightClick_Menu_Apply_Regular_ToHover_Label'
    : 'RightClick_Menu_Apply_Hover_ToRegular_Label';

  return {
    enabled: true,
    label,
  };
}

function getGfppItems(
  selectedComponents: CompRef[],
  biOrigin: string,
  origin: string,
) {
  const compRefs = arrayUtils.asArray(selectedComponents);
  const gfppData = gfppModel.getFullComponentGfppData(
    editorAPI,
    compRefs,
    origin,
  );

  const { ACTIONS } = constants.ROOT_COMPS.GFPP;

  const defaultActions: GFPPActionType[] = [
    editorAPI.zoomMode.isStageZoomMode() ? null : ACTIONS.SETTINGS,
    editorAPI.zoomMode.isStageZoomMode() ? null : ACTIONS.DESIGN,
    ACTIONS.CUSTOMIZE_DESIGN,
    ACTIONS.EFFECTS,
    ACTIONS.LAYOUT,
    ACTIONS.DIVIDERS,
    ACTIONS.BEHAVIORS,
    ACTIONS.UPGRADE,
    ACTIONS.CROP,
    ACTIONS.MASK,
    ACTIONS.FILTERS,
    ACTIONS.ANIMATION,
    ACTIONS.INTERACTIONS,
    ACTIONS.LINK,
    ACTIONS.STRETCH,
    ACTIONS.STRETCH_FOR_COLUMNS,
    editorAPI.zoomMode.isStageZoomMode() ? null : ACTIONS.CONNECT,
    ACTIONS.STYLABLE_LAYOUT,
    ACTIONS.STYLABLE,
  ].filter(Boolean);

  defaultActions.splice(
    defaultActions.indexOf(ACTIONS.STRETCH_FOR_COLUMNS) + 1,
    0,
    ACTIONS.HELP,
  );

  interface RcmGfppItem extends GFPPAction {
    action: string;
  }

  const items = (gfppData.mainActions || []).concat(
    _(gfppData.presetActions)
      .pick(defaultActions)
      .map((value, action) => ({ ...value, action }))
      .value(),
  ) as RcmGfppItem[];
  const firstControllableComponents = arrayUtils
    .asArray(selectedComponents)
    .map((selectedComponent: AnyFixMe) =>
      util.controlsUtils.getFirstControllableComponent(
        editorAPI,
        selectedComponent,
        GFPP_BUTTON_CLICK,
      ),
    );

  const helpItem = items.find((item) => item.helpId);
  const componentType = editorAPI.components.getType(
    firstControllableComponents,
  );
  const biParams = {
    component: componentType,
    origin: biOrigin,
  };
  if (helpItem) {
    helpItem.onClick = () => {
      editorAPI.panelManager.openHelpCenter(helpItem.helpId, null, biParams);
    };
  }

  const designItem = items.find((item) => item.icon === 'design');

  if (designItem) {
    const widgetDesignSubItems =
      platform.widgetDesignUtils.getWidgetDesignRcmItems(
        editorAPI,
        designItem,
        firstControllableComponents,
        biOrigin,
      );

    if (widgetDesignSubItems) {
      Object.assign(designItem, {
        subItems: widgetDesignSubItems,
      });
    }
  }

  const actions = _.transform(
    items,
    function (acc, action) {
      if (action.onClick && !action.actionType) {
        //disabling items that have a dynamic template without an onClick function or duplicate
        acc.push({
          automationId: action?.automationId,
          action: action.action,
          shouldTranslate: _.isBoolean(action.shouldTranslate)
            ? action.shouldTranslate
            : true,
          item: {
            enabled: !action.isDisabled,
            label: action.rightClickMenuLabel || action.label || action.tooltip,
            subItems: action.subItems,
          },
          onClick: () => {
            action.onClick(editorAPI, firstControllableComponents, biOrigin);
            const biParams = {
              ...editorAPI.bi.getComponentsBIParams(
                firstControllableComponents,
              )[0],
              action_name:
                action.label || action.rightClickMenuLabel || action.tooltip,
              component_id: firstControllableComponents[0]?.id,
              app_Id:
                editorAPI.components.data.get(firstControllableComponents[0])
                  ?.appDefinitionId || null,
              origin: biOrigin,
            };

            editorAPI.bi.event(coreBi.events.actions.CLICK, biParams);
            editorAPI.closeRightClickMenu();
          },
        });
      }
    },
    [],
  );

  if (experiment.isOpen('se_improveRCMContent')) {
    const cropAction = actions.find(
      (action) =>
        action.shouldTranslate &&
        !action.item?.enabled &&
        action.item?.label === 'gfpp_tooltip_crop_gif_unsupported',
    );

    if (cropAction) {
      cropAction.item.label = 'gfpp_tooltip_crop';
    }

    const animationAction =
      actions.find(
        (action) =>
          action.shouldTranslate &&
          !action.item?.enabled &&
          action.item?.label ===
            'gfpp_tooltip_hover_animation_disabled_single_element_in_regular',
      ) ||
      actions.find(
        (action) =>
          action.shouldTranslate &&
          !action.item?.enabled &&
          action.item?.label ===
            'gfpp_tooltip_hover_animation_disabled_linked_element_in_regular',
      );

    if (animationAction) {
      animationAction.item.label = 'gfpp_tooltip_animation';
    }
  }

  const currentLanguageCode = editorAPI.language.current.get();
  const originalLanguage = editorAPI.language.original.get();
  const originalLanguageCode = originalLanguage?.languageCode;

  if (
    originalLanguageCode &&
    currentLanguageCode &&
    originalLanguageCode !== currentLanguageCode &&
    !editorAPI.components.is.customizeTranslate(selectedComponents)
  ) {
    if (!gfppData.untranslatable) {
      const [firstAction, ...rest] = actions;
      let translateFn;
      if (gfppData.translateAction) {
        translateFn = () => {
          gfppData.presetActions[gfppData.translateAction].onClick(
            editorAPI,
            selectedComponents,
          );
          editorAPI.closeRightClickMenu();
        };
      } else {
        translateFn = firstAction.onClick;
      }

      const translateAction = Object.assign({}, firstAction, {
        item: Object.assign({}, firstAction.item, {
          label: 'gfpp_mainaction_edit_translation',
        }),
        onClick: translateFn,
      });

      const googleTranslateFn =
        util.multilingual.googleTranslateFactory.getTranslateMethodForComponent(
          editorAPI,
          stateManagement,
          _.head(selectedComponents),
        );
      if (googleTranslateFn) {
        const googleTranslateItem = {
          item: {
            label: 'gfpp_mainaction_google_translate',
            enabled: true,
          },
          onClick: () => {
            googleTranslateFn();
            editorAPI.closeRightClickMenu();
          },
          shouldTranslate: true,
        };
        return [translateAction, googleTranslateItem, ...rest];
      }

      return [translateAction, ...rest];
    }
  }

  return actions;
}

function getThemeStyleIds(comp: AnyFixMe) {
  if (experiment.isOpen('themeStyle')) {
    const componentType = editorAPI.components.getType(comp);
    const compDefinition = editorAPI.components.getDefinition(componentType);
    const styleIds =
      compDefinition?.styles && Object.keys(compDefinition.styles);

    if (styleIds) {
      return {
        enabled: true,
        styleIds,
      };
    }
  }
  return null;
}

function createLayersItem() {
  return {
    enabled: true,
    key: 'layers',
    automationId: 'rcmItemLayers',
    label: 'RightClick_Menu_Overlapping_Items_Layers_Label',
  };
}

function resetInteractionChanges(editorAPI: EditorAPI, compRef: CompRef) {
  resetInteractionChangesAction(editorAPI, compRef);
  editorAPI.closeRightClickMenu();
  editorAPI.bi.event(
    coreBi.events.rightClickMenu.RIGHT_CLICK_MENU_RESET_TO_REGULAR_CLICK,
    {
      component_type: editorAPI.components.getType(compRef),
    },
  );
}

function createResetInteractionChangesItem(
  editorAPI: EditorAPI,
  compRef: CompRef,
) {
  if (
    isInInteractionMode(editorAPI.store.getState()) &&
    !isShownOnlyOnVariant(editorAPI, compRef)
  ) {
    return {
      label: 'RightClick_Interactions_Remove_Changes',
      onClick: () => resetInteractionChanges(editorAPI, compRef),
      enabled: true,
      automationId: 'rcmRemoveInteractions',
    };
  }
  return null;
}

function isInteractionHideAvailable(editorAPI: AnyFixMe, compRef: AnyFixMe) {
  const state = editorAPI.store.getState();
  if (!isInInteractionMode(state) || isShownOnlyOnVariant(editorAPI, compRef)) {
    return false;
  }
  const compVariantPointer = getCompVariantPointer(state);
  const compTransformations =
    editorAPI.documentServices.components.transformations.get(
      compVariantPointer,
    );
  return !compTransformations || !compTransformations.hidden;
}

type GetRegularModeRightClickMenuItemsParams = Parameters<
  typeof getRegularModeRightClickMenuItems
>;

export type RightClickMenuItems = Partial<
  ReturnType<typeof getRegularModeRightClickMenuItems>
>;

function getRegularModeRightClickMenuItems(
  rightClickedComponents: CompRef[],
  overlappingComponents: CompRef[],
  isRegularMobileComp: boolean,
  biOrigin: string,
  origin: string,
) {
  return {
    undo: createUndoItem(),
    copy: createCopyItem(rightClickedComponents),
    paste: createPasteItem(isRegularMobileComp, rightClickedComponents),
    pasteAndApplyTheme: createPasteAndApplyThemeItem(isRegularMobileComp),
    applyModeFromClipboard: createApplyModeFromClipboard(isRegularMobileComp),
    cut: createCutItem(rightClickedComponents),
    duplicate: createDuplicateItem(rightClickedComponents),
    flip: createFlipItem(rightClickedComponents),
    copyDesign: createCopyDesignItem(rightClickedComponents),
    arrange: createArrangeItem(rightClickedComponents),
    moveTo: rcmMoveTo.build(editorAPI, rightClickedComponents),
    imageToBackground: createImageToBackgroundItem(
      editorAPI,
      rightClickedComponents,
    ),
    detachImageFromBackground: createDetachImageFromBackgroundItem(
      editorAPI,
      rightClickedComponents,
    ),
    overlappingItems: createOverlappingItemsItem(
      rightClickedComponents,
      overlappingComponents,
    ),
    interactionsHide: createHideInteractionItem(
      editorAPI,
      rightClickedComponents[0],
    ),
    interactionsShow: createShowInteractionItem(),
    layers: createLayersItem(),
    remove: createRemoveItem(rightClickedComponents),
    showOnAllPages: createShowOnAllPagesItem(rightClickedComponents),
    fixedPosition: createFixedPositionItem(rightClickedComponents),
    gfppItems: getGfppItems(rightClickedComponents, biOrigin, origin),
    styleCanBeApplied: createApplyStyleItem(rightClickedComponents),
    pin: createPinnedItem(rightClickedComponents, isRegularMobileComp),
    setA11y: createA11yItem(rightClickedComponents),
    applyToAllModes: createApplyToAllModesItem(isRegularMobileComp),
    saveToTheme: getThemeStyleIds(rightClickedComponents),
    addToSavedComponents: createAddToSavedComponentsItem(
      rightClickedComponents,
    ),
    resetInteraction: createResetInteractionChangesItem(
      editorAPI,
      rightClickedComponents[0],
    ),
    hideHeaderFooter: createHideHeaderFooter(
      editorAPI,
      rightClickedComponents,
      biOrigin,
    ),
  };
}

const createHideHeaderFooter = (
  editorAPI: EditorAPI,
  rightClickedComponents: CompRef[],
  biOrigin: string,
) => {
  const currentPageId = editorAPI.pages.getCurrentPageId();

  const enabled = util.sections.isSectionsEnabled()
    ? editorAPI.sections.isHeaderOrFooter(rightClickedComponents[0]) &&
      editorAPI.isDesktopEditor() &&
      editorAPI.pages.settings.hasLayoutSettingsTab(currentPageId)
    : false;

  if (!enabled) return;

  return {
    label: 'section_headerandfooter_hide_tooltip_title',
    onClick: () => {
      editorAPI.closeRightClickMenu();
      editorAPI.panelHelpers.openPagesPanel({
        initialSettingsTabType: constants.PAGE_SETTINGS_TABS.LAYOUT,
      });
      const [params] = editorAPI.bi.getComponentsBIParams(
        rightClickedComponents,
      );

      util.biLogger.report(
        clickOnHideHeaderAndFooter({
          ...params,
          origin: biOrigin,
        }),
      );
    },
    enabled,
    automationId: 'rcmHideHeaderFooter',
  };
};

const RCM_ALLOWED_INTERACTION = [
  'undo',
  'redo',
  'interactionsHide',
  'design',
  'help',
  'resetInteraction',
  'overlappingItems',
  'arrange',
];

function getInteractionsMenuItems(
  ...params: GetRegularModeRightClickMenuItemsParams
) {
  const regularModeRightClickMenuItems = getRegularModeRightClickMenuItems(
    ...params,
  );
  return Object.keys(regularModeRightClickMenuItems).reduce((acc, key) => {
    if (RCM_ALLOWED_INTERACTION.includes(key)) {
      acc[key] =
        regularModeRightClickMenuItems[
          key as keyof typeof regularModeRightClickMenuItems
        ];
    }
    return acc;
  }, {} as AnyFixMe);
}

function getMenuMap(
  rightClickedComponents: CompRef[],
  {
    isRegularMobileComp,
    biOrigin,
    origin,
    overlappingComponents,
  }: {
    isRegularMobileComp: boolean;
    origin: string;
    biOrigin: string;
    overlappingComponents: CompRef[];
  },
): RightClickMenuItems {
  if (!rightClickedComponents || _.isEmpty(rightClickedComponents)) {
    return {
      paste: createPasteItem(isRegularMobileComp, rightClickedComponents),
      undo: createUndoItem(),
    };
  }

  const state = editorAPI.store.getState();
  return isInInteractionMode(state) &&
    !isShownOnlyOnVariant(editorAPI, rightClickedComponents[0])
    ? getInteractionsMenuItems(
        rightClickedComponents,
        overlappingComponents,
        isRegularMobileComp,
        biOrigin,
        origin,
      )
    : getRegularModeRightClickMenuItems(
        rightClickedComponents,
        overlappingComponents,
        isRegularMobileComp,
        biOrigin,
        origin,
      );
}

const parser = {
  buildMenuItems(
    _editorApi: EditorAPI,
    overlappingComponents: CompRef[],
    selectedComponents: CompRef | CompRef[],
    biOrigin: string,
    origin: string,
  ) {
    const compRefs = arrayUtils.asArray(selectedComponents);
    editorAPI = _editorApi;
    const isRegularMobileComp =
      editorAPI.isMobileEditor() &&
      !editorAPI.mobile.mobileOnlyComponents.areAnyMobileOnlyNonNativeComponent(
        compRefs,
      );
    return getMenuMap(compRefs, {
      isRegularMobileComp,
      biOrigin,
      origin,
      overlappingComponents,
    });
  },
};

export default parser;
