// @ts-nocheck
import _ from 'lodash';

const getMenuItemsWithInitialCollaptionData = (menuItems, collaptionMap) => {
  //TODO: once more then 2 levels of menu will be opened make this function recursive. For now keep it simple and efficient
  if (collaptionMap) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(menuItems, (menuItem) => {
      menuItem.isCollapsed = collaptionMap[menuItem.id];
    });
  }

  return menuItems;
};

const getMenuItemsWithsymbolIfApplicable = (
  menuItems,
  menuAppDefId,
  membersAppDefId,
) => {
  if (menuAppDefId !== membersAppDefId) {
    return menuItems;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(menuItems, (menuItem) =>
    _.defaults(
      {
        symbol: 'membersAction',
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/map
        items: _.map(menuItem.items, (item) =>
          _.defaults({ symbol: 'membersAction' }, item),
        ),
      },
      menuItem,
    ),
  );
};

export {
  getMenuItemsWithInitialCollaptionData,
  getMenuItemsWithsymbolIfApplicable,
};
