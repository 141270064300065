import { createSelector } from '../../selectors/selector';
import { editorAPISel, editorAPIMouseSel } from '../selectors/rootSelectors';
import {
  selectedCompsSel,
  attachCandidateSel,
} from '../selectors/selectedCompsSelectors';
import { isResizingSel, isDraggingSel } from '../selectors/mouseSelectors';
import { attachCandidate } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';

const { selectAttachCandidateComponent } = attachCandidate.selectors;
const getPageAttachCandidate = (editorAPI: EditorAPI) => {
  const attachCandidateComp = selectAttachCandidateComponent(
    editorAPI.store.getState(),
  );
  const isPage = editorAPI.utils.isPage(attachCandidateComp);

  return isPage && attachCandidateComp;
};
const shouldShowPageMarginsIndicatorSel = createSelector(
  [
    editorAPIMouseSel,
    selectedCompsSel,
    attachCandidateSel,
    isResizingSel,
    isDraggingSel,
  ],
  (editorAPI, selectedComps, attachCandidate, isResizing, isDragging) => {
    return (
      (isDragging || isResizing) &&
      selectedComps.some((comp) => {
        const attachCandidate = getPageAttachCandidate(editorAPI);
        const container =
          attachCandidate || editorAPI.components.getContainer(comp);
        const shouldShowPageMarginIndicators =
          editorAPI.components.layout.shouldShowOutOfPageGridlinesWarning(
            comp,
            container,
            0,
          );

        return shouldShowPageMarginIndicators;
      })
    );
  },
);

export const pageMarginsIndicatorStylesSel = createSelector(
  [editorAPISel, shouldShowPageMarginsIndicatorSel],
  (editorAPI, shouldShowPageMarginsIndicator) => {
    if (!shouldShowPageMarginsIndicator) {
      return undefined;
    }
    const masterPageComp = editorAPI.siteSegments.getSiteStructure();
    const relativeToScreen =
      editorAPI.components.layout.getRelativeToScreen(masterPageComp);
    const pagesContainerLayout =
      editorAPI.siteSegments.getPagesContainerAbsLayout();
    const marginLeft = pagesContainerLayout.x - relativeToScreen.x;
    const marginRight =
      relativeToScreen.x +
      relativeToScreen.width -
      (pagesContainerLayout.x + pagesContainerLayout.width);

    return {
      indicator: {
        top: relativeToScreen.y,
        left: relativeToScreen.x,
        width: relativeToScreen.width,
        height: '100%',
      },
      marginLeft,
      marginRight,
      inlineWidth: relativeToScreen.width - (marginLeft + marginRight),
    };
  },
);

export type PageMarginsIndicatorStyles = ReturnType<
  typeof pageMarginsIndicatorStylesSel
>;
