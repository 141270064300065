// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import React from 'react';
import experiment from 'experiment';
import * as Symbols from '@wix/santa-editor-symbols';
import { CustomScroll } from '@wix/wix-base-ui';

import * as core from '@/core';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import * as Panels from '@/panels';
import { translate } from '@/i18n';

const VERTICAL_MARGIN = 30;
const HORIZONTAL_MARGIN = 20;
const DEFAULT_SCALE = 1;
const TRANSPARENT_SKINS = [
  'wysiwyg.viewer.skins.page.TransparentPageSkin',
  'wysiwyg.viewer.skins.screenwidthcontainer.TransparentHalfScreen',
  'wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen',
  'wysiwyg.viewer.skins.area.TransparentArea',
];
const NO_FRAME_SKINS = [
  'wysiwyg.viewer.skins.photo.NoSkinPhoto',
  'wysiwyg.viewer.skins.VideoSkin',
];

const COMPS_TO_REMOVE_THEME_REGULAR_USERS = [
  'wysiwyg.viewer.components.MatrixGallery',
  'wysiwyg.viewer.components.SlideShowGallery',
  'wysiwyg.viewer.components.SliderGallery',
  'wysiwyg.viewer.components.PaginatedGridGallery',
];
const compData = {
  'wysiwyg.viewer.components.HeaderContainer': {
    scale: 0.5,
  },
  'wysiwyg.viewer.components.FooterContainer': {
    scale: 0.5,
  },
  'wysiwyg.viewer.components.ScreenWidthContainer': {
    scale: 0.5,
  },
  'mobile.core.components.Page': {
    compOverrideType: 'pageContainer',
  },
  'wysiwyg.viewer.components.Video': {
    scale: 0.5,
  },
  'wysiwyg.viewer.components.VerticalLine': {
    maxFontSize: 10,
  },
  'wysiwyg.viewer.components.FiveGridLine': {
    maxFontSize: 10,
  },
  fiveGridLineFullWidth: {
    maxFontSize: 10,
  },
  'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer': {
    maxFontSize: 10,
  },
  'wysiwyg.viewer.components.AudioPlayer': {
    maxFontSize: 10,
  },
  'wysiwyg.viewer.components.menus.DropDownMenu': {
    maxFontSize: 15,
  },
  'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu': {
    maxFontSize: 15,
  },
  'wysiwyg.viewer.components.SiteButton': {
    maxFontSize: 22,
  },
  'mobile.core.components.Container': {
    scale: 0.5,
  },
  'core.components.Container': {
    scale: 0.5,
  },
  'wysiwyg.viewer.components.BgImageStrip': {
    scale: 0.5,
  },
  'wysiwyg.viewer.components.ContactForm': {
    scale: 0.5,
  },
  'wysiwyg.viewer.components.DynamicContactForm': {
    scale: 0.5,
  },
  'wysiwyg.common.components.subscribeform.viewer.SubscribeForm': {
    scale: 0.5,
  },
  'wysiwyg.viewer.components.GoogleMap': {
    scale: 0.5,
  },
  'wysiwyg.viewer.components.WPhoto': {
    scale: 0.5,
  },
  'wysiwyg.viewer.components.MatrixGallery': {
    scale: 0.25,
    maxFontSize: 12,
  },
  'wysiwyg.viewer.components.SlideShowGallery': {
    scale: 0.25,
    maxFontSize: 12,
  },
  'wysiwyg.viewer.components.SliderGallery': {
    scale: 0.25,
    maxFontSize: 12,
  },
  'wysiwyg.viewer.components.PaginatedGridGallery': {
    scale: 0.25,
    maxFontSize: 12,
  },
};

const compsWithSeveralCompTypes = {
  'mobile.core.components.Container': ['core.components.Container'],
  'core.components.Container': ['mobile.core.components.Container'],
};

function getThemeStylesAndAllowDuplication(editorAPI, compType) {
  const compTypes = _.union([compType], compsWithSeveralCompTypes[compType]);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  return _.reduce(
    compTypes,
    (acc, currCompType) => {
      const styleItems = _(
        core.styleManager.getThemedStyleForComp(
          editorAPI.theme,
          editorAPI.components,
          currCompType,
        ),
      )
        .union()
        .flatten()
        .value();
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(styleItems, (styleItem) => {
        acc.push({
          styleItem,
          compPtr: null,
        });
      });
      return acc;
    },
    [],
  );
}

function getCustomStyleWithNoDuplication(editorAPI, compType) {
  let allStyles = core.styleManager.getUniqueCustomStylesAndCompPtr(
    editorAPI.theme,
    editorAPI.components,
    compType,
  );
  if (compsWithSeveralCompTypes[compType]) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(compsWithSeveralCompTypes[compType], function (currCompType) {
      const customStyles = core.styleManager.getUniqueCustomStylesAndCompPtr(
        editorAPI.theme,
        editorAPI.components,
        currCompType,
      );
      allStyles = allStyles.concat(customStyles);
    });
  }
  return allStyles;
}

function getCompTypeToPassToLiveSection(compType) {
  return compData[compType]?.compOverrideType
    ? compData[compType].compOverrideType
    : compType;
}

function getPropsToRenderLiveSection(
  editorAPI,
  id,
  compType,
  stylesToRender,
  selectedItems,
  onClickCallback,
) {
  const liveSectionProps = {
    additionalBehaviours: {
      iconEnabledForComps: [],
      infoIcon: 'none',
      labelMode: 'none',
    },
    help: {
      hide: true,
    },
    categoryId: compType.substring(compType.lastIndexOf('.') + 1),
    hide: false,
    id,
    onClick: onClickCallback,
    onItemDrag() {},
    parentType: 'ADD_PANEL',
    props: {
      compTypes: getCompTypeToPassToLiveSection(compType),
      horizontalMargin: HORIZONTAL_MARGIN,
      verticalMargin: VERTICAL_MARGIN,
      scaleProportion: {
        x: compData[compType]?.scale ? compData[compType].scale : DEFAULT_SCALE,
        y: compData[compType]?.scale ? compData[compType].scale : DEFAULT_SCALE,
      },
    },
    showSectionHeader: false,
    stylesForSection: stylesToRender,

    additionalScaling: 1,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    selectedItemKey: _.map(selectedItems, 'itemKey'),
    getAdditionalElementProps(compDef) {
      const style = core.styleManager.getCompDefStyle(compDef, editorAPI);
      const props = {};

      if (!style) {
        return props;
      }

      if (TRANSPARENT_SKINS.includes(style.skin)) {
        props.text = 'DESIGN_LABEL_NO_COLOR';
        props.className = 'design-panel-transparent-skin-item';
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      } else if (NO_FRAME_SKINS.includes(style.skin)) {
        props.text = 'Design_Label_No_Frame';
        props.className = 'design-panel-no-frame-skin-item';
      }
      return props;
    },
  };
  if (compData[compType]?.maxFontSize) {
    liveSectionProps.props.maxFontSize = compData[compType].maxFontSize;
  }
  return liveSectionProps;
}

function filterNoFrameStyles(allStyles) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  return _.filter(allStyles, function (currStyle) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return !_.includes(NO_FRAME_SKINS, currStyle.styleItem.skin);
  });
}

//HACK FOR MOBILE MENU COPY DESIGN - needed in order to make it work with mobile menu skins format
function checkUniquenessForMenuToggle(compType, styleType) {
  return !isMobileMenu(compType) || styleType !== 'system';
}

function isMobileMenu(compType) {
  return compType === 'wysiwyg.viewer.components.MenuToggle';
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'applyStylePanel',
  mixins: [core.mixins.editorAPIMixin],

  getInitialState() {
    return {
      selectedItems: [],
    };
  },
  close() {
    const editorAPI = this.getEditorAPI();
    editorAPI.panelManager.closePanelByName(this.props.panelName);
    const { selectedComp } = this.props;
    editorAPI.bi.event(coreBi.events.applyStyle.COPY_STYLE_CANCEL, {
      component_id: selectedComp.id,
      component_type: editorAPI.components.getType(selectedComp),
    });
  },
  onCancel() {
    const editorAPI = this.getEditorAPI();
    const { selectedComp } = this.props;
    editorAPI.bi.event(coreBi.events.applyStyle.COPY_STYLE_CANCEL, {
      component_id: selectedComp.id,
      component_type: editorAPI.components.getType(selectedComp),
    });
  },
  onConfirm() {
    const editorAPI = this.getEditorAPI();
    const selectedCompType = editorAPI.components.getType(
      this.props.selectedComp,
    );
    const currentStyle = editorAPI.components.style.get(
      this.props.selectedComp,
    );
    const { selectedComp } = this.props;
    editorAPI.bi.event(coreBi.events.applyStyle.COPY_STYLE_APPLY, {
      num_of_styles_chosen: this.state.selectedItems.length,
      component_id: selectedComp.id,
      component_type: editorAPI.components.getType(selectedComp),
    });
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(this.state.selectedItems, function (item) {
      let styleItem;
      if (!item.compPtr) {
        const itemNameAsArr = item.itemKey.split('.');
        const lastPart = itemNameAsArr[itemNameAsArr.length - 1];
        const itemStyleId = lastPart.substring(lastPart.indexOf('_') + 1);
        styleItem = core.styleManager.getStyleById(
          editorAPI.theme,
          editorAPI.components,
          itemStyleId,
          editorAPI.components.getType(selectedComp),
        );
      } else {
        styleItem = editorAPI.components.style.get(item.compPtr);
      }

      let stylesToUpdate = [{ compPtr: item.compPtr, styleItem }];
      let duplicateStyleToSelectedStyles =
        core.styleManager.getDuplicateToStyleList(
          editorAPI.theme,
          editorAPI.components,
          styleItem,
          selectedCompType,
        );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(
        compsWithSeveralCompTypes[selectedCompType],
        function (currCompType) {
          const duplicateStyles = core.styleManager.getDuplicateToStyleList(
            editorAPI.theme,
            editorAPI.components,
            styleItem,
            currCompType,
            compsWithSeveralCompTypes[selectedCompType],
          );
          duplicateStyleToSelectedStyles =
            duplicateStyleToSelectedStyles.concat(duplicateStyles);
        },
      );
      if (experiment.isOpen('se_studioCopyDesign')) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/filter
        duplicateStyleToSelectedStyles = _.filter(
          duplicateStyleToSelectedStyles,
          { styleItem: { styleType: 'custom' } },
        );
      }
      stylesToUpdate = stylesToUpdate.concat(duplicateStyleToSelectedStyles);

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(stylesToUpdate, function (styleToUpdate) {
        const updatedStyle = {
          skin: currentStyle.skin,
          style: currentStyle.style,
          type: styleToUpdate.styleItem.type,
          styleType: styleToUpdate.styleItem.styleType,
        };
        if (
          styleToUpdate.styleItem.styleType === 'system' ||
          isMobileMenu(selectedCompType)
        ) {
          editorAPI.theme.styles.update(
            styleToUpdate.styleItem.id,
            updatedStyle,
          );
        } else {
          editorAPI.components.style.update(
            styleToUpdate.compPtr,
            updatedStyle,
          );
        }
      });
    });
  },

  getSelectedCompProps() {
    const editorAPI = this.getEditorAPI();
    const { selectedComp } = this.props;
    const compType = editorAPI.components.getType(selectedComp);
    const id = `selectedComp${selectedComp.id}`;
    const styleDef = editorAPI.components.style.get(selectedComp);
    const stylesToRender = [
      {
        styleId: styleDef.id,
        styleDef,
        compType: getCompTypeToPassToLiveSection(compType),
      },
    ];
    return getPropsToRenderLiveSection(
      editorAPI,
      id,
      compType,
      stylesToRender,
      this.state.selectedItems,
      _.noop,
    );
  },
  getThemeAndPersonalizeStylesProps() {
    const editorAPI = this.getEditorAPI();
    const { selectedComp } = this.props;
    const id = `themeAndMyForComp${selectedComp.id}`;
    const compType = editorAPI.components.getType(selectedComp);
    const stylesToRender = this.themeAndPersonalizedStylesToRender
      ? this.themeAndPersonalizedStylesToRender
      : this.getThemeAndPersonalizeStylesToRender();
    return getPropsToRenderLiveSection(
      editorAPI,
      id,
      compType,
      stylesToRender,
      this.state.selectedItems,
      this.updateListOfStyleToUpdate,
    );
  },

  getThemeAndPersonalizeStylesToRender() {
    if (this.themeAndPersonalizedStylesToRender) {
      return this.themeAndPersonalizedStylesToRender;
    }
    const editorAPI = this.getEditorAPI();
    const { selectedComp } = this.props;
    const compType = editorAPI.components.getType(selectedComp);
    const currentCompStyle = editorAPI.components.style.get(selectedComp);
    let allStyles = [];

    if (experiment.isOpen('se_studioCopyDesign')) {
      allStyles = getThemeStylesAndAllowDuplication(editorAPI, compType);
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    } else if (_.includes(COMPS_TO_REMOVE_THEME_REGULAR_USERS, compType)) {
      allStyles = getCustomStyleWithNoDuplication(editorAPI, compType);
    } else {
      const themeStyles = getThemeStylesAndAllowDuplication(
        editorAPI,
        compType,
      );
      allStyles = themeStyles.concat(
        getCustomStyleWithNoDuplication(editorAPI, compType),
      );
    }

    allStyles = filterNoFrameStyles(allStyles);
    const stylesToRender = [];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(allStyles, function (item) {
      const styleDef = item.styleItem;
      const isStyleUnique =
        !core.styleManager.isEqualWithoutTypeConsideration(
          styleDef,
          currentCompStyle,
        ) && checkUniquenessForMenuToggle(compType, styleDef.styleType);
      if (isStyleUnique) {
        stylesToRender.push({
          compType: getCompTypeToPassToLiveSection(compType),
          styleId: styleDef.id,
          styleDef,
          compPtr: item.compPtr,
        });
      }
    });
    this.themeAndPersonalizedStylesToRender = stylesToRender;
    return stylesToRender;
  },

  updateListOfStyleToUpdate(compDef, sectionTitle, sectionTags, itemKey) {
    const selectedItemsArr = _.clone(this.state.selectedItems);
    const itemNameAsArr = itemKey.split('.');
    const lastPart = itemNameAsArr[itemNameAsArr.length - 1];
    const itemStyleId = lastPart.substring(lastPart.indexOf('_') + 1);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    const isSelected = _.includes(
      _.mapValues(selectedItemsArr, 'itemKey'),
      itemKey,
    );
    if (isSelected) {
      _.remove(selectedItemsArr, { itemKey });
    } else {
      selectedItemsArr.push({ itemKey, compPtr: compDef.compPtr });
    }
    const { selectedComp } = this.props;
    const editorAPI = this.getEditorAPI();
    editorAPI.bi.event(
      coreBi.events.applyStyle.COPY_STYLE_CHOOSE_STYLES_TO_REPLACE_CLICK,
      {
        style_id: itemStyleId,
        status: !isSelected,
        component_id: selectedComp.id,
        component_type: editorAPI.components.getType(selectedComp),
      },
    );
    this.setState({ selectedItems: selectedItemsArr });
  },

  openHelpCenter() {
    const editorAPI = this.getEditorAPI();
    const helpId = 'b853c294-8ff1-418f-b4d2-29d7b59a85c3';
    const biHelpParams = {
      panel_name: this.props.panelName,
      component: editorAPI.selection.getSelectedComponentType(),
      origin: constants.BI.HELP.ORIGIN.PANEL,
      learn_more: false,
    };
    editorAPI.panelManager.openHelpCenter(helpId, null, biHelpParams);
  },

  render() {
    const DesignPanelLiveComponentSection =
      this.getEditorAPI().addPanelInfra.designPanelLiveComponentSection;

    return (
      <Panels.frames.ConfirmationPanelFrame
        panelName={this.props.panelName}
        onConfirm={this.onConfirm}
        onCancel={this.onCancel}
        shouldHideHeader={true}
        confirmLabel="COPY_DESIGN_APPLY"
        ref="frame"
        cancelLabel="COPY_DESIGN_APPLY_CANCEL"
        className="apply-style-panel"
      >
        <header className="save-publish-panel-header">
          <button onClick={this.close} className="close">
            <Symbols.symbol name="headerCloseButton" />
          </button>
          <button onClick={this.openHelpCenter} key="help" className="help">
            ?
          </button>
          <span className="title">{translate('COPY_DESIGN_TITLE')}</span>
          <span className="subtitle">
            {translate('COPY_DESIGN_DESCRIPTION_LINE1')}
          </span>
        </header>
        <div className="panel-content">
          <div className="current-style-container container-wrapper">
            <div className="current-style-header header">
              <span className="text">
                {translate('COPY_DESIGN_LABEL_LEFT')}
              </span>
            </div>
            <div className="current-style comp-container">
              <DesignPanelLiveComponentSection
                {...this.getSelectedCompProps()}
              />
            </div>
          </div>
          <div className="applied-styles-container container-wrapper">
            {this.getThemeAndPersonalizeStylesToRender().length > 0 ? (
              <div
                key="applyStyleHeader"
                className="applied-styles-header header"
              >
                <span className="text">
                  {' '}
                  {translate('COPY_DESIGN_LABEL_RIGHT')}
                </span>
              </div>
            ) : null}
            <CustomScroll>
              <div className="comp-themed-styles comp-container">
                {this.getThemeAndPersonalizeStylesToRender().length > 0 ? (
                  <DesignPanelLiveComponentSection
                    key="appliesStylesLiveSections"
                    {...this.getThemeAndPersonalizeStylesProps()}
                  />
                ) : null}
                {this.getThemeAndPersonalizeStylesToRender().length === 0 ? (
                  <div key="emptyStylesKey" className="empty-styles">
                    <Symbols.symbol name="nothingToEditASP" />
                    <div className="empty-style-text text">
                      {translate('COPY_DESIGN_EMPTY_LABEL')}
                    </div>
                  </div>
                ) : null}
              </div>
            </CustomScroll>
          </div>
        </div>
      </Panels.frames.ConfirmationPanelFrame>
    );
  },
});
