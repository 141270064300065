export const AUTOMATION_IDS = {
  CTA: 'blog-upgrade-panel-cta',
  LEARN_MORE: 'blog-upgrade-panel-learn-more',
};

export const HELP_ARTICLE_IDS = {
  LEARN_MORE: '6f26af10-0e5b-44e8-a92f-f89d424822fe',
};

export const TRANSLATIONS = {
  SIMPLE_MIGRATION: {
    TITLE: 'SiteApp_NewBlog_Migration_No_Support_Panel_Title',
    INFO: 'SiteApp_NewBlog_Migration_No_Support_Panel_Subtitle',
    BULLET_LIST_ITEMS: [
      'SiteApp_NewBlog_Migration_No_Support_Panel_Bullet_1',
      'SiteApp_NewBlog_Migration_No_Support_Panel_Bullet_2',
      'SiteApp_NewBlog_Migration_No_Support_Panel_Bullet_3',
    ],
    CTA: 'SiteApp_NewBlog_Migration_No_Support_Panel_CTA',
    LEARN_MORE: 'SiteApp_NewBlog_Migration_No_Support_Panel_Link',
  },
  TWO_BLOGS_MIGRATION: {
    TITLE: 'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Panel_Title',
    BASIC_INFO: 'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Panel_Subtitle',
    MIGRATION_INFO:
      'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Elements_Panel_Subtitle',
    BULLET_LIST_ITEMS: [
      'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Panel_Bullet_1',
      'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Panel_Bullet_2',
      'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Panel_Bullet_3',
    ],
    MIGRATIONS_LABEL:
      'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Elements_Panel_Radio_Label',
    MIGRATIONS_TOOLTIP:
      'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Elements_Panel_Tooltip',
    MIGRATION_TOOLTIP: {
      PART_1: {
        LABEL:
          'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Elements_Panel_Tooltip',
        INFO: 'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Elements_Panel_Tooltip2',
      },
      PART_2: {
        LABEL:
          'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Elements_Panel_Tooltip3',
        INFO: 'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Elements_Panel_Tooltip4',
      },
    },
    MIGRATION_CONVERT:
      'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Elements_Panel_Radio_Convert',
    MIGRATION_DELETE:
      'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Elements_Panel_Radio_Delete',
    NEXT_STEP_CTA: 'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Panel_CTA',
    MIGRATION_CTA:
      'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Elements_Panel_CTA',
    LEARN_MORE: 'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Panel_Link',
  },
};
