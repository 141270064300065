import _ from 'lodash';
import * as React from 'react';
import { Composites, Divider, ToggleSwitch } from '@wix/wix-base-ui';

import type { StyleRef } from '@wix/document-services-types';

const ALPHA_PREFIX = 'alpha-';
const DEFAULT_COLOR_VALUE = 'color_11';
const DEFAULT_ALPHA_VALUE = 0;
const DEFAULT_PROPERTY_SOURCE = 'value';
const keys = {
  fill: 'Fill',
  corners: 'Corners',
  border: 'Borders',
  shadow: 'Shadow!',
};

const toRegularProperty = (scrolledPropertyName: string) =>
  scrolledPropertyName.replace('-scrl', '');

interface ObjectType {
  [key: string]: any;
}

interface ToggleParamsType {
  [key: string]: string;
}

interface ScrolledStateToggleType {
  styleDataItem: StyleRef;
  toggleParams: ToggleParamsType;
  forceUpdateParentStyle: (
    styleDataItem: StyleRef,
    styleParam: string | string[],
    newVal: ObjectType,
  ) => void;
  category: keyof typeof keys;
  children?: React.ReactNode;
}

const assignValueToProperty = (
  styleItem: StyleRef['style'],
  paramsData: ToggleParamsType,
  property: string,
) => {
  const regularProperty = toRegularProperty(property);
  const alphaProperty = `${ALPHA_PREFIX}${property}`;
  const regularAlphaProperty = `${ALPHA_PREFIX}${regularProperty}`;

  let newValue = styleItem.properties[regularProperty];
  let newAlphaValue = styleItem.properties[regularAlphaProperty];

  // unlike other properties, bg-scrl should not match its equivalent in Regular state, but use its default value
  if (property === 'bg-scrl') {
    newValue = DEFAULT_COLOR_VALUE;
    newAlphaValue = DEFAULT_ALPHA_VALUE;
  }

  styleItem.properties[property] = newValue;
  styleItem.propertiesSource[property] =
    styleItem.propertiesSource[regularProperty] || DEFAULT_PROPERTY_SOURCE;

  if (paramsData[property].includes('COLOR_ALPHA')) {
    styleItem.properties[alphaProperty] = newAlphaValue;
    styleItem.propertiesSource[alphaProperty] =
      styleItem.propertiesSource[regularAlphaProperty] ||
      DEFAULT_PROPERTY_SOURCE;
  }
};

const deleteProperty = (style: StyleRef['style'], property: string) => {
  delete style.properties[property];
  delete style.propertiesSource[property];
  delete style.properties[`${ALPHA_PREFIX}${property}`];
  delete style.propertiesSource[`${ALPHA_PREFIX}${property}`];
};

const ScrolledStateToggle: React.FC<ScrolledStateToggleType> = ({
  styleDataItem,
  toggleParams,
  forceUpdateParentStyle,
  category,
}) => {
  const getParamsData = () => {
    const params = _.cloneDeep(toggleParams);
    if (Object.keys(params).includes('shd-scrl')) {
      params['boxShadowToggleOn-shd-scrl'] = 'BOX_SHADOW';
    }
    return params;
  };
  const applyScrolledStyle = (scrollEnabled: boolean) => {
    if (!styleDataItem?.style?.properties) {
      return;
    }

    const currentStyleData = styleDataItem;
    const paramsData = getParamsData();
    const updatedStyleData = _.cloneDeep(currentStyleData);
    const paramsDataKeys = Object.keys(paramsData);

    for (const property in paramsData) {
      if (scrollEnabled) {
        assignValueToProperty(updatedStyleData.style, paramsData, property);
      } else {
        deleteProperty(updatedStyleData.style, property);
      }
    }

    if (paramsDataKeys.includes('bg-scrl')) {
      // fill layers opacity is based on alpha-bg CSS property
      if (scrollEnabled) {
        updatedStyleData.style.properties['alpha-bg'] = 0;
      } else {
        updatedStyleData.style.properties['alpha-bg'] = 1;
      }
      paramsDataKeys.push('alpha-bg');
    }

    forceUpdateParentStyle(
      updatedStyleData,
      paramsDataKeys,
      _.pick(updatedStyleData.style.properties, paramsDataKeys),
    );
  };

  const getIsScrolledStyleApplied = () => {
    if (!styleDataItem?.style?.properties) {
      return false;
    }

    const paramsData = getParamsData();
    return Object.keys(paramsData).every((param) =>
      Object.hasOwn(styleDataItem.style.properties, param),
    );
  };

  return (
    <>
      <Composites.ToggleSwitch>
        <ToggleSwitch
          // TODO -replace with the actual key
          label={`Apply ${keys[category]}`}
          value={getIsScrolledStyleApplied()}
          onChange={applyScrolledStyle}
        />
      </Composites.ToggleSwitch>
      <Divider long={false} key="divider" />
    </>
  );
};

ScrolledStateToggle.displayName = 'scrolledStateToggle';

export default ScrolledStateToggle;
