import React from 'react';
import { Text, TextLabel, RichText } from '@wix/wix-base-ui';
import { CategoryLabel } from './categoryLabel';
import { translate } from '@/i18n';

import type { AddPageCategory, PageLabel } from '../../../types';

export interface CategoryHeader {
  labels: PageLabel[];
  selectedCategory: AddPageCategory;
  openHelpCenter(helpId: string): void;
  isPremiumSite: boolean;
}

export const CategoryHeader = ({
  selectedCategory,
  labels,
  openHelpCenter,
  isPremiumSite,
}: CategoryHeader) => {
  return (
    <section className="thumbnails-header">
      <div className="thumbnails-header-title">
        <Text weight="bold" size="large">
          {selectedCategory.longTitle}
        </Text>

        <CategoryLabel
          labels={labels}
          selectedCategory={selectedCategory}
          isPremiumSite={isPremiumSite}
        />
      </div>
      <div className="thumbnails-header-description">
        <RichText>
          <TextLabel type="T05" value={selectedCategory.description} />
          {selectedCategory.descriptionHelpId &&
          selectedCategory.descriptionLearnMore ? (
            <a
              className="thumbnails-header-learn-more"
              onClick={() => openHelpCenter(selectedCategory.descriptionHelpId)}
            >
              {translate(selectedCategory.descriptionLearnMore)}
            </a>
          ) : null}
        </RichText>
      </div>
    </section>
  );
};
