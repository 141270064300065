// @ts-nocheck
const BOOKABLE_DAILY_TIMETABLE_WIDGET_IMAGE_V4 =
  'wixBookings/addPanel/preset_editor_timetable.v4.jpg';
const PRESET_KEY = 'Wix_Bookings_Timetable_Widget';
const DAILY_TIMETABLE_WIDGET_ID = '89c4023a-027e-4d2a-b6b7-0b9d345b508d';
const DAILY_TIMETABLE_TITLE_KEY = 'add_section_label_timetable_input';
const dailyTimetableCompIds = {
  BOOK_BUTTON: 'comp-ka0psh0k',
};

const DAILY_TIMETABLE_OVERRIDES = [
  {
    itemType: 'props',
    dataItem: {
      ghost: undefined,
      type: 'ButtonProperties',
    },
    isMobile: false,
    compId: dailyTimetableCompIds.BOOK_BUTTON,
  },
  {
    itemType: 'props',
    dataItem: {
      ghost: undefined,
      type: 'ButtonProperties',
    },
    isMobile: true,
    compId: dailyTimetableCompIds.BOOK_BUTTON,
  },
];

export {
  BOOKABLE_DAILY_TIMETABLE_WIDGET_IMAGE_V4,
  PRESET_KEY as DAILY_TIMETABLE_PRESET_KEY,
  DAILY_TIMETABLE_WIDGET_ID,
  DAILY_TIMETABLE_TITLE_KEY,
  DAILY_TIMETABLE_OVERRIDES,
};
