import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import constants from '@/constants';
import * as util from '@/util';
import type { WithDoubleClickPrevention } from '@/util';
import * as mediaManagerPanelUtils from '../../mediaManagerPanelUtils/mediaManagerPanelUtils';
import * as mediaManagerPanelConstants from '../../mediaManagerPanelConstants/mediaManagerPanelConstants';
import mediaPreview, { type TaxSettings } from './mediaPreview';
import * as baseUI from '@/baseUI';
import { Button, Composites, Preloader, TextLabel } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import ItemThumbnail from './thumbnails/presetThumbnail';
import { cx, SafeInjectHtml } from '@/util';
import type { MediaManagerItem } from '@/mediaServices';

const {
  hoc: { withDoubleClickPrevention },
} = util;
const mediaManagerMediaTypes = constants.MEDIA_MANAGER_MEDIA_TYPES;

interface PresetProps {
  item: MediaManagerItem;
  index: number;
  priceInfo: {
    value: number;
    symbol: string;
    taxSettings: TaxSettings;
  };
  width: number;
  dataCompType: string;

  shouldDisplayName?: boolean;
  isBackgroundBlue?: boolean;

  onBuyItem: (item: MediaManagerItem) => void;
  onClick: (item: MediaManagerItem, index: number) => void;
  onItemDrag: (event: MouseEvent, dragItemInfo: AnyFixMe) => void;
  registerItem: (item: MediaManagerItem) => void;
}

interface PresetState {
  hasPictureLoadingError: boolean;
}

class MediaManagerPanelPreset extends React.Component<
  PresetProps & WithDoubleClickPrevention,
  PresetState
> {
  static defaultProps = {
    width: 88,
    shouldDisplayName: false,
  };

  constructor(props: AnyFixMe) {
    super(props);

    this.state = {
      hasPictureLoadingError: false,
    };

    _.bindAll(this, ['handlePresetClick', 'handlePictureError']);
  }

  componentDidMount() {
    this.registerItem();
  }

  get item() {
    return this.props.item;
  }

  get hasPictureLoadingError() {
    return this.state.hasPictureLoadingError;
  }

  get canBePurchased() {
    return this.hasPriceInfo() && _.isFunction(this.props.onBuyItem);
  }

  registerItem() {
    _.invoke(this.props, 'registerItem', this.props.item);
  }

  getPresetInstance(): Element {
    return ReactDOM.findDOMNode(this.refs.presetInstance) as Element;
  }

  shouldDisplayName() {
    return this.props.shouldDisplayName;
  }

  isBackgroundBlue() {
    return this.props.isBackgroundBlue;
  }

  isVideo() {
    return mediaManagerPanelUtils.isItemOfType(
      this.item,
      mediaManagerMediaTypes.VIDEO,
    );
  }

  canPresetBeUsed() {
    const isVideo = this.isVideo();

    return (
      !this.hasPictureLoadingError &&
      (!isVideo || (isVideo && !this.isTranscodingFailed()))
    );
  }

  isTranscodingInProgress() {
    return mediaManagerPanelUtils.isVideoFileTranscodingInProgress(this.item);
  }

  isTranscodingFailed() {
    return mediaManagerPanelUtils.isVideoFileTranscodingFailed(this.item);
  }

  isTranscodedSuccessfully() {
    return mediaManagerPanelUtils.isVideoFileTranscodedSuccessfully(this.item);
  }

  shouldRenderTranscodingOverlay() {
    return (
      this.hasPictureLoadingError ||
      (this.isVideo() &&
        (this.isTranscodingInProgress() || this.isTranscodingFailed()))
    );
  }

  getFailedTranscodingTooltipValue() {
    return mediaManagerPanelConstants.VIDEO_FILE_TRANSCODING_FAILED_TOOLTIP_MESSAGE;
  }

  getItemPreviewElement(item: AnyFixMe) {
    return React.createElement(mediaPreview, {
      item,
      isBackgroundBlue: this.isBackgroundBlue(),
      priceInfo: this.getPriceInfo(),
    });
  }

  shouldDisablePreview() {
    return (
      this.hasPictureLoadingError ||
      !mediaManagerPanelUtils.canShowPreviewForItem(this.item) ||
      (this.isVideo() && !this.isTranscodedSuccessfully())
    );
  }

  getPriceInfo() {
    return this.props.priceInfo;
  }

  hasPriceInfo() {
    return Boolean(this.getPriceInfo());
  }

  handlePictureError() {
    this.setState({ hasPictureLoadingError: true });
  }

  buyItem() {
    _.invoke(this.props, 'onBuyItem', this.item);
  }

  handlePresetClick(event: AnyFixMe) {
    const isAnotherMouseActionAllowed =
      this.props.isAnotherMouseActionAllowed(event);

    if (
      isAnotherMouseActionAllowed &&
      this.canPresetBeUsed() &&
      !this.canBePurchased
    ) {
      _.invoke(this.props, 'onClick', this.item, this.props.index);

      this.props.setClickTimeStamp(event);
    }

    if (isAnotherMouseActionAllowed && this.canBePurchased) {
      this.buyItem();

      this.props.setClickTimeStamp(event);
    }
  }

  handlePresetMouseDown(event: AnyFixMe) {
    event.preventDefault();
    event.persist();

    if (
      !this.props.isAnotherMouseActionAllowed(event) ||
      !this.canPresetBeUsed() ||
      this.canBePurchased
    ) {
      return;
    }

    const presetInstance = this.getPresetInstance();

    _.invoke(this.props, 'onItemDrag', event, {
      item: this.item,
      rect: _.pick(presetInstance.getBoundingClientRect(), [
        'left',
        'top',
        'width',
        'height',
      ]),
    });
  }

  render() {
    const { item } = this.props;

    return (
      <div
        data-dynamic-media-item-id={this.props.item.id}
        onClick={this.handlePresetClick}
        onMouseDown={(event) => {
          this.handlePresetMouseDown(event);
        }}
        ref="presetInstance"
        className="media-manager-panel-preset"
        data-comp={this.props.dataCompType}
      >
        <baseUI.tooltip
          value={this.getItemPreviewElement(item)}
          disabled={this.shouldDisablePreview()}
          styleType={constants.UI.TOOLTIP.STYLE_TYPE.CONTENT_ONLY}
          noArrow={true}
          alignment="right"
          delay="300"
          width="252px"
        >
          <div>
            {this.hasPriceInfo()
              ? (() => {
                  const priceInfo = this.getPriceInfo();

                  return (
                    <div
                      style={{ width: this.props.width }}
                      key="presetOverlay"
                      data-hook="media-manager-panel-buy_item_button_wrapper"
                      className="media-manager-panel-preset__purchase-overlay"
                    >
                      <Button className="btn-sm media-manager-panel-preset__buy-item-button">
                        <SafeInjectHtml tag="span" html={priceInfo.symbol} />
                        <span>{priceInfo.value}</span>
                      </Button>
                    </div>
                  );
                })()
              : null}

            {this.shouldRenderTranscodingOverlay() ? (
              <div
                key="videoTranscodingOverlay"
                className="media-manager-panel-preset__transcoding-overlay"
              >
                {this.isTranscodingInProgress() ||
                this.hasPictureLoadingError ? (
                  <div
                    style={{ width: this.props.width }}
                    key="transcodingPreloader"
                    className="media-manager-panel-preset__transcoding-indicator"
                  >
                    <Composites.Preloader>
                      <Preloader />
                    </Composites.Preloader>
                  </div>
                ) : null}

                {this.isTranscodingFailed() ? (
                  <baseUI.tooltip
                    value={this.getFailedTranscodingTooltipValue()}
                    width="171px"
                    key="transcodingFailedMessage"
                  >
                    <div className="media-manager-panel-preset__transcoding-failed-icon">
                      <symbols.symbol name="media-manager-panel-failed-transcoding-icon" />
                    </div>
                  </baseUI.tooltip>
                ) : null}
              </div>
            ) : null}

            <div
              style={{ width: this.props.width }}
              className={cx('media-manager-panel-preset__thumbnail', {
                'with-error': this.hasPictureLoadingError,
              })}
            >
              <ItemThumbnail
                item={item}
                isBackgroundBlue={this.isBackgroundBlue()}
                width={this.props.width}
                onPictureError={this.handlePictureError}
              />
            </div>
          </div>
        </baseUI.tooltip>

        {this.shouldDisplayName() ? (
          <div key="title" className="media-manager-panel-preset__title">
            <TextLabel value={item.name} type="T12" shouldTranslate={false} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withDoubleClickPrevention<PresetProps>(MediaManagerPanelPreset);
