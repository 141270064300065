import React from 'react';
import { Text } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import styles from './installedAppIndicator.scss';

export const InstalledAppIndicator = () => (
  <div className={styles.installedAppIndicator}>
    <symbols.symbol name="appInstalledIcon" />
    <Text weight="bold">
      blocks-private-apps.AppDiscovery_App_Details_Installed_Status
    </Text>
  </div>
);
