import { BaseEntryScope, createEntryPoint, Hooks } from '@/apilib';
import { WorkspaceApi } from './api';
import { WorkspaceApiKey } from './publicApiKey';

export class Scope extends BaseEntryScope {
  slots = {
    stageComponents: this.declareComponentSlot(),
    mouseCatcherComponents: this.declareComponentSlot(),
    workspaceComponents: this.declareComponentSlot(),
    workspaceRightPanel: this.declareComponentSlot(),
    topBarContributions: {
      main: {
        logo: this.declareComponentSlot(),
        rightSide: this.declareComponentSlot(),
      },
      preview: {
        logo: this.declareComponentSlot(),
        rightSide: this.declareComponentSlot(),
      },
    },
    editBoxComponents: { imageToBackground: this.declareComponentSlot() },
  };

  hooks = this.declareApi(() => ({
    stageMouseLeave: Hooks.createHook(),
  }));
}

export const WorkspaceEntrypoint = createEntryPoint({
  name: 'WorkspaceEntrypoint',
  publicApi({ contributeApi }) {
    contributeApi(WorkspaceApiKey, WorkspaceApi);
  },
  Scope,
  initialize() {},
});
