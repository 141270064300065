import * as textControls from '@/textControls';
import type { EditorAPI } from '@/editorAPI';
import _ from 'lodash';
import type { CompRef } from 'types/documentServices';
import { text } from '@/stateManagement';
import { fontUtils } from '@/util';

const stylesCssSelector =
  '.cke_editable p, .cke_editable h1, .cke_editable h2, .cke_editable h3, .cke_editable h4, .cke_editable h5, .cke_editable h6, .cke_editable ul, .cke_editable ol';

const shouldConsiderStyleProperties = (editorAPI: EditorAPI): boolean => {
  const [selectedCompRef] = editorAPI.selection.getSelectedComponents();

  const getResponsiveLayout =
    editorAPI.documentServices?.components?.responsiveLayout.get;

  const widgetDesignSelectedComponents =
    text.selectors.getWidgetDesignSelectedComponents(
      editorAPI.store.getState(),
    );

  const hasResponsiveLayout =
    getResponsiveLayout &&
    !!getResponsiveLayout(
      _.get(widgetDesignSelectedComponents, 0, selectedCompRef),
    );

  return selectedCompRef && hasResponsiveLayout;
};

const getStylePropertiesCss = (editorAPI: EditorAPI, compRef: CompRef) =>
  _(editorAPI.components.style.get(compRef)?.style?.properties)
    .mapValues((stylePropertyValue, stylePropertyName) => {
      let resolvedStylePropertyValue = stylePropertyValue;

      if (
        (stylePropertyName === 'color' ||
          stylePropertyName === 'backgroundColor') &&
        stylePropertyValue !== undefined
      ) {
        resolvedStylePropertyValue =
          editorAPI.documentServices.theme.colors.render(stylePropertyValue);
      }

      return resolvedStylePropertyValue;
    })
    .map(
      (stylPropertyValue, stylePropertyName) =>
        `${_.kebabCase(stylePropertyName)}:${stylPropertyValue}`,
    )
    .join('; ');

export default (editorAPI: EditorAPI) => ({
  getThemes: () => {
    if (shouldConsiderStyleProperties(editorAPI)) {
      return {};
    }
    if (
      editorAPI.selection.getSelectedComponents()[0] &&
      editorAPI.documentServices.components.responsiveLayout &&
      !!editorAPI.documentServices.components.responsiveLayout.get(
        editorAPI.selection.getSelectedComponents()[0],
      )
    ) {
      return {};
    }

    const getFontsMap = editorAPI.documentServices?.theme.textThemes.getAll;
    const themeColorsGet = editorAPI.documentServices?.theme.colors.getAll;
    let themes = getFontsMap && getFontsMap();
    const themeColors = themeColorsGet && themeColorsGet();
    if (!_.isEmpty(themes)) {
      themes = _.mapValues(themes, (themeValues, themeKey) => {
        themeValues.fontFamily = themeValues.fontFamily
          .replace(/[+]/g, ' ')
          .toLowerCase();

        const fontStyle = fontUtils.parseStyleTextTheme(
          themeKey,
          themeValues,
          themeColors,
        );

        // In this particular case we don't want the real fontWithFallbacks, since it sends
        // wrong fontFamily to the editor, needs to be fixed in the editor to support fontWithFallbacks
        return Object.assign({}, fontStyle, themeValues, {
          fontWithFallbacks: themeValues.fontFamily,
        });
      });
    }

    return themes || {};
  },
  getCssData: () => {
    const res: any = {};

    res.viewerCss = {
      id: 'viewerCss',
      value:
        'https://static.parastorage.com/services/santa/1.2195.0/static/css/viewer.css',
      isLink: true,
    };

    if (editorAPI.documentServices && editorAPI.documentServices.theme) {
      const getThemeStyles =
        editorAPI.documentServices?.theme.textThemes.getStyles;

      if (getThemeStyles) {
        res.theme_fonts = {
          id: 'theme_fonts',
          value: getThemeStyles(),
          isLink: false,
          force: true,
        };
      }

      const getCss = editorAPI.documentServices?.theme.colors.getCss;

      if (getCss) {
        res.theme_colors = {
          id: 'theme_colors',
          value: getCss(),
          isLink: false,
          force: true,
        };
      }
    }

    res.style_Properties = {
      id: 'style_Properties',
      value: `${stylesCssSelector} { ${
        shouldConsiderStyleProperties(editorAPI)
          ? getStylePropertiesCss(
              editorAPI,
              editorAPI.selection.getSelectedComponents()[0],
            )
          : ''
      } }`,
      isLink: false,
      force: false,
    };

    return res;
  },

  getFonts: _.partial(
    textControls.fontUtils.getCurrentSelectableFontsWithParams,
    editorAPI,
    false,
    true,
  ),
});
