'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_custom',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {

        }
    },
    'props': {
        'image': 'addPanelData/sections/popUpsCloseButtonsSection_en/popUpsCloseButtonsSection.v2.png',
        'imageHover': null,
        'items': [
            {
                'id': 'PopUps_PopUps_Close_Buttons_1',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_9a8686831e874878a55a90925c0feb6c',
                    'layout': {
                        'width': 35,
                        'height': 35,
                        'x': 8,
                        'y': 15,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_9a8686831e874878a55a90925c0feb6c'
                    },
                    'id': 'comp-ikauccvz'
                },
                'preset': {
                    'rect': {
                        'width': 53,
                        'height': 64,
                        'x': 0,
                        'y': 0
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_2',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v1.svg_7d72932f870a315ce1b030fcc29ba4e5.XMark',
                    'layout': {
                        'width': 35,
                        'height': 35,
                        'x': 61,
                        'y': 15,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#808080',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v1.svg_7d72932f870a315ce1b030fcc29ba4e5.XMark'
                    },
                    'id': 'comp-ikaue9m0'
                },
                'preset': {
                    'rect': {
                        'width': 57,
                        'height': 64,
                        'x': 53,
                        'y': 0
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_3',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744',
                    'layout': {
                        'width': 35,
                        'height': 35,
                        'x': 117,
                        'y': 15,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#424242',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744'
                    },
                    'id': 'comp-ik8gqa6c'
                },
                'preset': {
                    'rect': {
                        'width': 49,
                        'height': 64,
                        'x': 110,
                        'y': 0
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_4',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_d9aa4ba7d4cf439eae572bd0a2037a9a',
                    'layout': {
                        'width': 35,
                        'height': 35,
                        'x': 171,
                        'y': 15,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#D4D4D4',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_d9aa4ba7d4cf439eae572bd0a2037a9a'
                    },
                    'id': 'comp-ikaue9m1'
                },
                'preset': {
                    'rect': {
                        'width': 56,
                        'height': 64,
                        'x': 159,
                        'y': 0
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_5',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_ca10d45302c9474086840aaaebc64d54',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 225,
                        'y': 14,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_ca10d45302c9474086840aaaebc64d54'
                    },
                    'id': 'comp-ik8g7urr'
                },
                'preset': {
                    'rect': {
                        'width': 55,
                        'height': 64,
                        'x': 215,
                        'y': 0
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_6',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_adbbdf0555154fd9ac0b5c6317557cac',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 281,
                        'y': 14,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#808080',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_adbbdf0555154fd9ac0b5c6317557cac'
                    },
                    'id': 'comp-ikauccvw'
                },
                'preset': {
                    'rect': {
                        'width': 53,
                        'height': 64,
                        'x': 270,
                        'y': 0
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_7',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_8a9e7bf39dc04297a9c481b08e06171e',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 8,
                        'y': 75,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_8a9e7bf39dc04297a9c481b08e06171e'
                    },
                    'id': 'comp-ikauagk6'
                },
                'preset': {
                    'rect': {
                        'width': 53,
                        'height': 65,
                        'x': 0,
                        'y': 64
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_8',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_767bce2c0ab24a998dde9802e3acf5b5',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 63,
                        'y': 75,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#808080',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_767bce2c0ab24a998dde9802e3acf5b5'
                    },
                    'id': 'comp-ikauagk81'
                },
                'preset': {
                    'rect': {
                        'width': 57,
                        'height': 65,
                        'x': 53,
                        'y': 64
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_9',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_f9e491d57f3d47c9940b4d59603abd67',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 117,
                        'y': 75,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#424242',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_f9e491d57f3d47c9940b4d59603abd67'
                    },
                    'id': 'comp-ikauagk8'
                },
                'preset': {
                    'rect': {
                        'width': 49,
                        'height': 65,
                        'x': 110,
                        'y': 64
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_10',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_027545a3ebce40bd9fcec9cbf57e9936',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 171,
                        'y': 75,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_027545a3ebce40bd9fcec9cbf57e9936'
                    },
                    'id': 'comp-ik8gaakr1'
                },
                'preset': {
                    'rect': {
                        'width': 56,
                        'height': 65,
                        'x': 159,
                        'y': 64
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_11',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_8ec13301249443a7849a44730d65d4d6',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 225,
                        'y': 75,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#808080',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_8ec13301249443a7849a44730d65d4d6'
                    },
                    'id': 'comp-ik8g9lb21'
                },
                'preset': {
                    'rect': {
                        'width': 55,
                        'height': 65,
                        'x': 215,
                        'y': 64
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_12',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_ebf6e4f4378b4ab28b54747875671028',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 279,
                        'y': 75,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#D4D4D4',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_ebf6e4f4378b4ab28b54747875671028'
                    },
                    'id': 'comp-ikauagk71'
                },
                'preset': {
                    'rect': {
                        'width': 54,
                        'height': 65,
                        'x': 269,
                        'y': 64
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_13',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_e608002829f8420c8a0653fc41a3c6df',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 117,
                        'y': 136,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_e608002829f8420c8a0653fc41a3c6df'
                    },
                    'id': 'comp-ikauagk7'
                },
                'preset': {
                    'rect': {
                        'width': 49,
                        'height': 64,
                        'x': 110,
                        'y': 128
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_14',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_9d6d3e891351401195d6acfb3433a367',
                    'layout': {
                        'width': 38,
                        'height': 38,
                        'x': 225,
                        'y': 135,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#424242',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_9d6d3e891351401195d6acfb3433a367'
                    },
                    'id': 'comp-ik8g7t7l'
                },
                'preset': {
                    'rect': {
                        'width': 55,
                        'height': 64,
                        'x': 215,
                        'y': 128
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_15',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_92d916765e78418d8e311fe779ca8c70',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 8,
                        'y': 136,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#424242',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_92d916765e78418d8e311fe779ca8c70'
                    },
                    'id': 'comp-ikauccvz1'
                },
                'preset': {
                    'rect': {
                        'width': 53,
                        'height': 64,
                        'x': 0,
                        'y': 129
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_16',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_26785a916a73466e9be44b1541982cd3',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 63,
                        'y': 136,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#808080',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_26785a916a73466e9be44b1541982cd3'
                    },
                    'id': 'comp-ik8gqa6d1'
                },
                'preset': {
                    'rect': {
                        'width': 58,
                        'height': 64,
                        'x': 52,
                        'y': 129
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_17',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_47fb7962d06948c1a7e184543d5f3164',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 170,
                        'y': 136,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#D4D4D4',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_47fb7962d06948c1a7e184543d5f3164'
                    },
                    'id': 'comp-ikauccvx1'
                },
                'preset': {
                    'rect': {
                        'width': 56,
                        'height': 64,
                        'x': 159,
                        'y': 129
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_18',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_fae88497feb64ed69f761a34d1c61370',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 280,
                        'y': 136,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_fae88497feb64ed69f761a34d1c61370'
                    },
                    'id': 'comp-ikauccvy1'
                },
                'preset': {
                    'rect': {
                        'width': 54,
                        'height': 64,
                        'x': 270,
                        'y': 129
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_19',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_7d9252ff9470436caedd54550b1005e0',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 8,
                        'y': 197,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#2F2E2E',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_7d9252ff9470436caedd54550b1005e0'
                    },
                    'id': 'comp-ik8gaaku'
                },
                'preset': {
                    'rect': {
                        'width': 53,
                        'height': 56,
                        'x': 0,
                        'y': 192
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_20',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_96673a05dc714bee8cbcf596a10a76a3',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 63,
                        'y': 197,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#D4D4D4',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_96673a05dc714bee8cbcf596a10a76a3'
                    },
                    'id': 'comp-ik8gaaks1'
                },
                'preset': {
                    'rect': {
                        'width': 59,
                        'height': 56,
                        'x': 52,
                        'y': 192
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_21',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_d9aa4ba7d4cf439eae572bd0a2037a9a',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 117,
                        'y': 198,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#808080',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_d9aa4ba7d4cf439eae572bd0a2037a9a'
                    },
                    'id': 'comp-ikauisya'
                },
                'preset': {
                    'rect': {
                        'width': 48,
                        'height': 56,
                        'x': 111,
                        'y': 192
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_22',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_5b26e57a1eed4912bec60f9c6918ea50',
                    'layout': {
                        'width': 35,
                        'height': 35,
                        'x': 171,
                        'y': 198,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#2F2E2E',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_5b26e57a1eed4912bec60f9c6918ea50'
                    },
                    'id': 'comp-ik8gqa6d'
                },
                'preset': {
                    'rect': {
                        'width': 55,
                        'height': 56,
                        'x': 159,
                        'y': 192
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_23',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_0d0bd19c9cc84a9a974c1442be06b9ad',
                    'layout': {
                        'width': 35,
                        'height': 35,
                        'x': 224,
                        'y': 198,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#808080',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_0d0bd19c9cc84a9a974c1442be06b9ad'
                    },
                    'id': 'comp-ik8gaakr'
                },
                'preset': {
                    'rect': {
                        'width': 55,
                        'height': 56,
                        'x': 214,
                        'y': 192
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_24',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_cbb8e8ef852a43309c88fe1c54fbadba',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 279,
                        'y': 197,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_cbb8e8ef852a43309c88fe1c54fbadba'
                    },
                    'id': 'comp-ik8gcifm'
                },
                'preset': {
                    'rect': {
                        'width': 54,
                        'height': 56,
                        'x': 270,
                        'y': 192
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_25',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_872e30e6a30f4385a6b2dd0f0efc8d5e',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 8,
                        'y': 258,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_872e30e6a30f4385a6b2dd0f0efc8d5e'
                    },
                    'id': 'comp-ik8g7rj0'
                },
                'preset': {
                    'rect': {
                        'width': 53,
                        'height': 64,
                        'x': 1,
                        'y': 248
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_26',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_2db104fc42db4ecf8c7d739079914d5f',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 61,
                        'y': 259,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#808080',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_2db104fc42db4ecf8c7d739079914d5f'
                    },
                    'id': 'comp-ik8g7pe6'
                },
                'preset': {
                    'rect': {
                        'width': 58,
                        'height': 64,
                        'x': 53,
                        'y': 248
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_27',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_cf6205952d3f48b184492163e8d0bed2',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 117,
                        'y': 259,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_cf6205952d3f48b184492163e8d0bed2'
                    },
                    'id': 'comp-ik8gcifl'
                },
                'preset': {
                    'rect': {
                        'width': 49,
                        'height': 64,
                        'x': 111,
                        'y': 248
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_28',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_9eba6dc91ad040cfaef2b35e9932732a',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 170,
                        'y': 259,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_9eba6dc91ad040cfaef2b35e9932732a'
                    },
                    'id': 'comp-ik8gaaks'
                },
                'preset': {
                    'rect': {
                        'width': 55,
                        'height': 64,
                        'x': 160,
                        'y': 248
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_29',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_860e53e2887048ccbd6762621564a4c3',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 226,
                        'y': 259,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#D4D4D4',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_860e53e2887048ccbd6762621564a4c3'
                    },
                    'id': 'comp-ik8gcifk'
                },
                'preset': {
                    'rect': {
                        'width': 55,
                        'height': 64,
                        'x': 215,
                        'y': 248
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_30',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_094cedeeb8014533b78585030e1eb72f',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 279,
                        'y': 259,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#424242',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_094cedeeb8014533b78585030e1eb72f'
                    },
                    'id': 'comp-ik8g7nk7'
                },
                'preset': {
                    'rect': {
                        'width': 54,
                        'height': 64,
                        'x': 270,
                        'y': 248
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_31',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_6af2996bd7e64914840e00a49b1e0c33',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 8,
                        'y': 320,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#808080',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_6af2996bd7e64914840e00a49b1e0c33'
                    },
                    'id': 'comp-ik8gaaku1'
                },
                'preset': {
                    'rect': {
                        'width': 53,
                        'height': 56,
                        'x': 0,
                        'y': 312
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_32',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_96df067539b14870a44bda98cafb3b35',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 63,
                        'y': 320,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#D4D4D4',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_96df067539b14870a44bda98cafb3b35'
                    },
                    'id': 'comp-ik8gcifk1'
                },
                'preset': {
                    'rect': {
                        'width': 58,
                        'height': 56,
                        'x': 53,
                        'y': 312
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_33',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_44ff76f568924052a4da155815deaa99',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 117,
                        'y': 320,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_44ff76f568924052a4da155815deaa99'
                    },
                    'id': 'comp-ik8gaakq'
                },
                'preset': {
                    'rect': {
                        'width': 49,
                        'height': 56,
                        'x': 111,
                        'y': 312
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_34',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_4f79d60b7aab47b8871afaae9546dc9c',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 171,
                        'y': 320,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#808080',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_4f79d60b7aab47b8871afaae9546dc9c'
                    },
                    'id': 'comp-ik8gcifm1'
                },
                'preset': {
                    'rect': {
                        'width': 56,
                        'height': 56,
                        'x': 160,
                        'y': 312
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_35',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_5331f0b5d02a41838624903c1208a53d',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 223,
                        'y': 320,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_5331f0b5d02a41838624903c1208a53d'
                    },
                    'id': 'comp-ik8gcifn1'
                },
                'preset': {
                    'rect': {
                        'width': 55,
                        'height': 56,
                        'x': 216,
                        'y': 312
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_36',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_69f997e6186742c1a796e9add201b3fc',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 279,
                        'y': 320,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#424242',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_69f997e6186742c1a796e9add201b3fc'
                    },
                    'id': 'comp-ik8gaakq1'
                },
                'preset': {
                    'rect': {
                        'width': 54,
                        'height': 56,
                        'x': 271,
                        'y': 312
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_37',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_585b1155cc5e49ef9c373cc3f95596e6',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 8,
                        'y': 381,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_585b1155cc5e49ef9c373cc3f95596e6'
                    },
                    'id': 'comp-ik8g3wwx'
                },
                'preset': {
                    'rect': {
                        'width': 53,
                        'height': 76,
                        'x': 2,
                        'y': 368
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_38',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_b5c76ffc72274f5dac98ba2c3c96e4bf',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 63,
                        'y': 381,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#808080',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_b5c76ffc72274f5dac98ba2c3c96e4bf'
                    },
                    'id': 'comp-ikau8i9f'
                },
                'preset': {
                    'rect': {
                        'width': 58,
                        'height': 76,
                        'x': 54,
                        'y': 368
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_39',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_ebcb0b009a42443fbd139fbfba48c6c9',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 116,
                        'y': 381,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_ebcb0b009a42443fbd139fbfba48c6c9'
                    },
                    'id': 'comp-ik8gqa6e'
                },
                'preset': {
                    'rect': {
                        'width': 48,
                        'height': 76,
                        'x': 112,
                        'y': 368
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_40',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_ecace728e9ef4d78aa0bb03c8e0a47b7',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 171,
                        'y': 381,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#424242',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_ecace728e9ef4d78aa0bb03c8e0a47b7'
                    },
                    'id': 'comp-ik8gqa6d2'
                },
                'preset': {
                    'rect': {
                        'width': 56,
                        'height': 76,
                        'x': 160,
                        'y': 368
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_41',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_4e5fa323e33745a19af01dcab89eea04',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 225,
                        'y': 381,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#D4D4D4',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_4e5fa323e33745a19af01dcab89eea04'
                    },
                    'id': 'comp-ik8gcifl1'
                },
                'preset': {
                    'rect': {
                        'width': 56,
                        'height': 76,
                        'x': 216,
                        'y': 368
                    },
                    'tags': null
                }
            },
            {
                'id': 'PopUps_PopUps_Close_Buttons_42',
                'structure': {
                    'type': 'Component',
                    'skin': 'svgshape.v2.Svg_1497b4098ed7492a83b4a275d8f9f276',
                    'layout': {
                        'width': 36,
                        'height': 36,
                        'x': 279,
                        'y': 381,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                    'data': {
                        'type': 'SvgShape',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        }
                    },
                    'props': {
                        'type': 'SvgShapeProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'maintainAspectRatio': true
                    },
                    'style': {
                        'type': 'TopLevelStyle',
                        'metaData': {
                            'isPreset': false,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'style': {
                            'groups': {

                            },
                            'properties': {
                                'alpha-fillcolor': '1',
                                'alpha-stroke': '1',
                                'fillcolor': '#000000',
                                'stroke': '#ED1566',
                                'strokewidth': '0px'
                            },
                            'propertiesSource': {
                                'fillcolor': 'value',
                                'stroke': 'value',
                                'strokewidth': 'value'
                            }
                        },
                        'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'pageId': '',
                        'compId': '',
                        'styleType': 'custom',
                        'skin': 'svgshape.v2.Svg_1497b4098ed7492a83b4a275d8f9f276'
                    },
                    'id': 'comp-ik8g9lb2'
                },
                'preset': {
                    'rect': {
                        'width': 54,
                        'height': 76,
                        'x': 272,
                        'y': 368
                    },
                    'tags': null
                }
            }
        ],
        'compTypes': [
            'wysiwyg.viewer.components.PopupCloseIconButton'
        ]
    },
    'help': {
        'hide': false,
        'text': 'add_section_info_text_popup_closebutton'
    }
}
