import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import * as panels from '@/panels';
import * as leftBar from '@/leftBar';
import {
  hoc,
  errorBoundary,
  workspace,
  editorModel,
  fedopsLogger,
  hooks,
  sections,
} from '@/util';

import { ErrorState } from '../components/ErrorState/ErrorState';

import { loadSegmentContent } from '../services/dealer/dealer.segmentContent';
import { mapDispatchToProps, mapStateToProps } from './PanelFrame.mapper';

import { HELP_ARTICLE_IDS, APP_MANAGER_ORIGIN } from '../constants';
import { BI_EVENTS_IDS } from '../biEvents';
import { AUTOMATION_IDS } from '../automationIds';

import type { AppManagerPanelProps } from '../AppManagerPanel/AppManagerPanel';

import styles from './PanelFrame.scss';
import { TRANSLATIONS } from '../translations';

const { ErrorBoundary } = errorBoundary;
const { FETCH_DEALER_SEGMENT_CONTENT } = fedopsLogger.INTERACTIONS.APP_MANAGER;

export interface PanelFrameOwnProps {
  panelName: string;
  panelIndex: number;
}

type PanelFrameProps = AppManagerPanelProps &
  PanelFrameOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const withPanelFrame = (
  Target: React.ComponentType<AppManagerPanelProps>,
) => {
  const PanelFrameComponent: React.FC<PanelFrameProps> = (props) => {
    const { openHelpCenter, panelName, panelIndex, sendBi, metaSiteInstance } =
      props;
    const [translate] = useTranslation();
    const [helpParams, setHelpParams] = useState({
      helpId: HELP_ARTICLE_IDS.APP_LIST,
      origin: '',
    });

    // TODO: this is already loaded in init.ts, so it can be put into editor state
    const [
      segmentContent,
      { isLoading: isLoadingSegmentContent, error: errorFromSegmentContent },
    ] = hooks.useRequest(() => loadSegmentContent(metaSiteInstance), {
      fedopsInteractionName: FETCH_DEALER_SEGMENT_CONTENT,
      initialData: {},
    });

    const handleHelpClick = useCallback(
      () => openHelpCenter(helpParams.helpId, { origin: helpParams.origin }),
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [helpParams],
    );
    const handleError = useCallback(
      (error) => {
        sendBi(BI_EVENTS_IDS.PANEL_ERROR, {
          esi: editorModel.editorSessionId,
          message: error?.message || 'not provided',
        });
      }, // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const panelFrameTitle = useMemo(() => {
      if (isLoadingSegmentContent) {
        return undefined;
      }

      return segmentContent?.panelTitle
        ? translate(segmentContent.panelTitle)
        : translate(TRANSLATIONS.PANEL_TITLE);
    }, [isLoadingSegmentContent, segmentContent, translate]);

    const LeftPanelFrame = workspace.isNewWorkspaceEnabled()
      ? leftBar.LeftPanelFrame
      : panels.frames.LeftPanelFrame;

    return (
      <LeftPanelFrame
        panelName={panelName}
        panelIndex={panelIndex}
        panelClass={
          sections.isSectionsEnabled()
            ? 'app-manager-panel-new'
            : 'app-manager-panel'
        }
        className={styles.container}
        label={panelFrameTitle}
        onHelpClicked={handleHelpClick}
        noHelpBtn={!helpParams.helpId}
        dynamicWidth
        fullHeight
      >
        <div
          className={styles.contentContainer}
          data-aid={AUTOMATION_IDS.PANEL}
        >
          <ErrorBoundary
            ravenConfig={{
              tags: {
                [APP_MANAGER_ORIGIN]: true,
              },
            }}
            renderError={(error) => (
              <ErrorState
                segmentContent={segmentContent}
                error={error}
                onHelpClick={handleHelpClick}
              />
            )}
            onError={handleError}
          >
            <Target
              {...props}
              setHelpParams={setHelpParams}
              openHelpCenter={openHelpCenter}
              segmentContent={segmentContent}
              isLoadingSegmentContent={isLoadingSegmentContent}
              errorFromSegmentContent={errorFromSegmentContent}
            />
          </ErrorBoundary>
        </div>
      </LeftPanelFrame>
    );
  };

  const connect = hoc.connect(
    hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  );

  return connect(PanelFrameComponent);
};
