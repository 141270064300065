import { translate } from '@/i18n';
import { mapStateToProps, mapDispatchToProps } from './oldLeftBarMapper';
import React from 'react';
import LeftBarButton from './oldLeftBarButton/leftBarButton';
import { cx, hoc, preventContextMenu } from '@/util';
import * as higherOrderComponents from '@/higherOrderComponents';
import * as baseUI from '@/baseUI';
import { PrimaryButtonsSlotPlacement } from '@/extensionSlots';

const OFFSET_FIX_FOR_MOUNTING = 1000;
const OFFSET_FIX_FOR_ANIMATION = 12; // Should also hide red notification dot of left bar button, when buttons are open

type LeftBarProps = {
  editingAreaRectOffset?: {
    left: number;
    x: number;
  };
  onMouseMove?: (event: React.MouseEvent) => void;
  onMouseEnter?: (event: React.MouseEvent) => void;
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class OldLeftBar extends React.Component<LeftBarProps> {
  static displayName = 'leftBar';

  static defaultProps = {
    editingAreaRectOffset: {
      left: 0,
      x: 0,
    },
  };

  containerRef = React.createRef<HTMLDivElement>();
  buttonsRef = React.createRef<HTMLUListElement>();

  componentDidMount() {
    this.updateButtonsBoundingRect();

    if (this.props.isHidden) {
      if (this.containerRef && this.containerRef.current) {
        this.containerRef.current.style.left = this.getLeftOffset();
      }
    }
  }

  componentDidUpdate(prevProps: AnyFixMe) {
    this.updateButtonsBoundingRect();
    this.sendBIButtonsCountIfNeeded(prevProps);
  }

  getNotificationCount = (panelName: AnyFixMe) => {
    return this.props.leftBarNotifications[panelName] || 0;
  };

  sendBIButtonsCountIfNeeded = (prevProps: AnyFixMe) => {
    if (this.props.superAppsDesktopCount !== prevProps.superAppsDesktopCount) {
      this.props.sendBIButtonsCount();
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps: AnyFixMe) {
    if (nextProps.selectedPanelName) {
      if (!this.props.isCollapsed) {
        this.props.collapseMenu();
      }
    }
  }

  handleOnClick = (panelName: string, panelProps: AnyFixMe) => {
    if (this.props.isEnabled && panelName !== this.props.selectedPanelName) {
      panelProps.openPanelInteraction?.start();
      panelProps.firstPresetSectionLoadInteraction?.start();

      this.props.collapseMenu();
      this.props.openPanel(panelName, panelProps);

      this.props.sendBIButtonClick(panelName);

      if (panelProps.openPanelBiEvent) {
        this.props.reportBI(
          panelProps.openPanelBiEvent,
          panelProps.openPanelBiParams,
        );
      }
    }

    this.props.collapseButton(panelName);
    this.props.hideFloatingBubble();
  };

  onHover = (element_name: string) => {
    this.props.sendBIButtonHover(element_name);
  };

  isMenuOpened = () => {
    return Boolean(this.props.selectedPanelName);
  };

  updateButtonsBoundingRect = () => {
    let boundingRect;

    const hasButtonsToCollapseOnClick = this.props.leftBarMenuItems.some(
      (item: AnyFixMe) => item.collapseOnClick,
    );

    if (this.props.isCollapsed && !hasButtonsToCollapseOnClick) {
      boundingRect = null;
    } else {
      const { editingAreaRectOffset } = this.props;
      const buttonsRef = this.buttonsRef && this.buttonsRef.current;

      if (buttonsRef) {
        const absoluteBoundingRect = buttonsRef.getBoundingClientRect();

        boundingRect = {
          // inherited (absolute)
          bottom: absoluteBoundingRect.bottom,
          height: absoluteBoundingRect.height,
          top: absoluteBoundingRect.top,
          width: absoluteBoundingRect.width,
          y: absoluteBoundingRect.y,

          // recalculated (relative to editingArea)
          left: absoluteBoundingRect.left - editingAreaRectOffset.left,
          right: absoluteBoundingRect.right - editingAreaRectOffset.left,
          x: absoluteBoundingRect.x - editingAreaRectOffset.x,
        };
      }
    }
    this.props.updateLeftBarButtonsBoundingRect(boundingRect);
  };

  getLeftOffset = () => {
    if (!this.props.isHidden) {
      return '';
    }

    const isMounted = this.containerRef && this.containerRef.current;

    let leftOffset;

    if (!isMounted) {
      leftOffset = -OFFSET_FIX_FOR_MOUNTING;
    } else {
      const { offsetWidth } = this.containerRef.current;
      leftOffset = -(offsetWidth + OFFSET_FIX_FOR_ANIMATION);
    }

    return `${leftOffset || ''}px`;
  };

  isSelected = (panelName: AnyFixMe) => {
    const { selectedPanelName } = this.props;
    return !!(selectedPanelName && selectedPanelName === panelName);
  };

  render() {
    return (
      <div
        ref={this.containerRef}
        style={{
          left: this.getLeftOffset(),
        }}
        onContextMenu={preventContextMenu}
        onMouseMove={this.props.onMouseMove}
        onMouseEnter={this.props.onMouseEnter}
        className={cx({
          'left-bar': true,
          'is-hidden': this.props.isHidden,
          'half-opacity': this.props.halfOpacityTools,
          'menu-collapsed-wrapper': this.props.isCollapsed,
        })}
      >
        <ul
          ref={this.buttonsRef}
          className={cx({
            'left-bar-items': true,
            'menu-collapsed': this.props.isCollapsed,
            'menu-opened': this.isMenuOpened(),
          })}
        >
          {this.props.leftBarMenuItems.map(
            (item: AnyFixMe, itemIndex: AnyFixMe) => (
              <LeftBarButton
                tooltips={item.tooltips}
                key={item.panelName}
                label={translate(item.label)}
                panelName={item.panelName}
                automationId={`leftBarButton-${item.panelName}`}
                isMenuCollapsed={this.props.isCollapsed}
                symbolName={item.symbolName}
                isMobileEditor={this.props.isMobileEditor}
                isSelected={this.isSelected(item.panelName)}
                onClick={() => {
                  this.handleOnClick(item.panelName, item.panelProps);
                }}
                onHover={this.onHover}
                isFirstPanel={itemIndex === 0}
                collapseOnClick={item.collapseOnClick}
                isButtonOpen={item.isButtonOpen}
                notificationCount={this.getNotificationCount(item.panelName)}
                hintProps={item.hintProps}
                className={item.className}
              />
            ),
          )}

          <PrimaryButtonsSlotPlacement>
            {(_integrationProps, item) => (
              <LeftBarButton
                tooltips={item.tooltips}
                label={item.label}
                panelName="extensions-slot-primary-panel"
                automationId={`leftBarButton-${item.automationId}`}
                isMenuCollapsed={this.props.isCollapsed}
                symbolName={item.symbolName}
                isMobileEditor={this.props.isMobileEditor}
                isSelected={item.isSelected}
                onClick={item.onClick}
                onHover={this.onHover}
                isFirstPanel={false}
                collapseOnClick={item.collapseOnClick}
                isButtonOpen={item.isButtonOpen}
                notificationCount={0}
                hintProps={item.hintProps}
                className={item.className}
              />
            )}
          </PrimaryButtonsSlotPlacement>
        </ul>
      </div>
    );
  }
}

const ConnectedLeftBar = hoc.connect(
  hoc.STORES.MOUSE_OPS,
  mapStateToProps,
  mapDispatchToProps,
)(OldLeftBar);

ConnectedLeftBar.pure = OldLeftBar;

export const LeftBarWithQuickTip = higherOrderComponents.overlappedCompTooltip(
  baseUI.popoverTemplates.quickTip,
)(ConnectedLeftBar);

export default ConnectedLeftBar;
