const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_6",
  preset: { rect: { width: 192, height: 76, x: 0, y: 220 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        groups: {},
        properties: {
          "$st-css":
            ":import{\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: StylableButton\n}\n.root{\n    -st-extends: StylableButton;\n    transition: all 0.2s ease, visibility 0s;\n    background: #E5EBFA;\n    border-radius: 60px;\n    border: 0px solid rgb(29, 103, 205)\n}\n.root:hover{\n    background: #689ADE;\n    border: 0px solid rgb(29, 103, 205)\n}\n.root:hover::label{\n    color: #FFFFFF\n}\n.root:disabled{\n    background: #E2E2E2\n}\n.root:disabled::label{\n    color: #8F8F8F\n}\n.root:disabled::icon{\n    fill: #8F8F8F\n}\n.root::container{\n    transition: inherit\n}\n.root::label{\n    transition: inherit;\n    font-style: normal;\n    color: #0069FF;\n    font-family: \"playfair display\",serif;\n    font-weight: 400;\n    letter-spacing: 0.05em;\n    font-size: 16px\n}\n.root::icon{\n    transition: inherit;\n    width: 10px;\n    height: 10px;\n    fill: value(site_1_1);\n    display: none\n}\n.root:hover::icon{\n    fill: value(site_1_5)\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "4ie-63",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "5vr-408",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_join_us"),
      svgId: "b861b040274141de9c08999ec3b76edf.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-km8wre2k",
    layout: {
      x: 11,
      fixedPosition: false,
      y: 236,
      scale: 1,
      height: 45,
      rotationInDegrees: 0,
      width: 169,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "b80-137", pageId: "c1dmp" },
    },
    metaData: { sig: "inq-14", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
