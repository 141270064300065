// @ts-nocheck
import _ from 'lodash';

let themeAPI;
const borderSides = ['top', 'right', 'bottom', 'left'];
const corners = ['topLeft', 'topRight', 'bottomRight', 'bottomLeft'];

const categoryControlsMap = Object.freeze({
  cssBoxShadow: [
    {
      controlType: 'angle',
      key: 'angle',
      props: {
        helpText: null,
        label: 'CustomDesign_Shadow_Angle',
        shouldTranslate: true,
        step: 1,
      },
    },
    {
      controlType: 'slider',
      key: 'distance',
      props: {
        helpText: null,
        label: 'CustomDesign_Shadow_Distance',
        shouldTranslate: true,
        min: 0,
        max: 50,
      },
    },
    {
      controlType: 'slider',
      key: 'size',
      props: {
        helpText: null,
        label: 'CustomDesign_Shadow_Size',
        shouldTranslate: true,
        min: 0,
        max: 50,
      },
    },
    {
      controlType: 'slider',
      key: 'blur',
      props: {
        helpText: null,
        label: 'CustomDesign_Shadow_Blur',
        shouldTranslate: true,
        min: 0,
        max: 50,
      },
    },
    {
      controlType: 'colorPickerInputWithOpacity',
      key: 'color',
      props: {
        helpText: null,
        label: 'CustomDesign_Shadow_Color&Opacity',
        shouldTranslate: true,
        min: 0,
        max: 50,
      },
    },
  ],
  cssBorderRadius: [
    {
      controlType: 'cornerRadiusInput',
      key: 'radius',
      props: {
        helpText: null,
        label: 'CustomDesign_Corners_CornerRadius',
      },
    },
  ],
  cssBorder: [
    {
      controlType: 'colorPickerInputWithOpacity',
      key: 'color',
      props: {
        helpText: null,
        label: 'CustomDesign_Borders_Color&Opacity',
      },
    },
    {
      controlType: 'slider',
      key: 'width',
      props: {
        helpText: null,
        label: 'CustomDesign_Borders_Width',
        max: 15,
        min: 0,
      },
    },
  ],
});

const dataManipulatorsMap = Object.freeze({
  cssBoxShadow: {
    angle: {
      getLinkableValue: getCSSBoxShadowAngleLinkableValue,
      linkedValueToData: getCSSBoxShadowAngleLinkedValueToData,
    },
    distance: {
      getLinkableValue: getCSSBoxShadowDistanceLinkableValue,
      linkedValueToData: getCSSBoxShadowDistanceLinkedValueToData,
    },
    size: {
      getLinkableValue: getCSSBoxShadowSizeLinkableValue,
      linkedValueToData: getCSSBoxShadowSizeLinkedValueToData,
    },
    blur: {
      getLinkableValue: getCSSBoxShadowBlurLinkableValue,
      linkedValueToData: getCSSBoxShadowBlurLinkedValueToData,
    },
    color: {
      getLinkableValue: getCSSBoxShadowColorLinkableValue,
      linkedValueToData: getCSSBoxShadowColorLinkedValueToData,
    },
  },
  cssBorderRadius: {
    radius: {
      getLinkableValue: getCSSBorderRadiusLinkableValue,
      linkedValueToData: getCSSBorderRadiusLinkedValueToData,
    },
  },
  cssBorder: {
    color: {
      getLinkableValue: getCSSBorderColorLinkableValue,
      linkedValueToData: getCSSBorderColorLinkedValueToData,
    },
    width: {
      getLinkableValue: getCSSBorderWidthinkableValue,
      linkedValueToData: getCSSBorderWidthLinkedValueToData,
    },
  },
});

function getDataManipulators(data, category, property) {
  const manipulatorGetters = dataManipulatorsMap[category][property];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  return _.reduce(
    manipulatorGetters,
    function (accumulator, getterFunc, funcName) {
      accumulator[funcName] = getterFunc(data, [category, property]);
      return accumulator;
    },
    {},
  );
}

function getCSSBoxShadowColorLinkableValue(data, path) {
  const dataItem = _.get(data.cssStyle, path[0])[0][path[1]];
  return function CSSBoxShadowColorLinkableValue() {
    return {
      color: rgbaObjToRgbString(dataItem),
      alpha: dataItem.alpha.toFixed(4),
    };
  };
}

function getCSSBoxShadowColorLinkedValueToData(data, path) {
  return function CSSBoxShadowColorLinkedValueToData(value) {
    const newData = _.cloneDeep(data);
    const updatePath = _.clone(path);
    updatePath.splice(1, 0, '0');
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/bind
    const themeColorsHandlerFunc = _.bind(
      handleThemeColors,
      {},
      newData.themeMappings,
    );
    updateColorValue(
      themeColorsHandlerFunc,
      newData.cssStyle,
      value,
      updatePath,
    );
    return newData;
  };
}

function getCSSBoxShadowBlurLinkableValue(data, path) {
  const dataItem = _.get(data.cssStyle, path[0])[0];
  return function CSSBoxShadowBlurLinkableValue() {
    return dataItem.blurRadius.value;
  };
}

function getCSSBoxShadowBlurLinkedValueToData(data) {
  return function CSSBoxShadowBlurLinkedValueToData(value) {
    const newData = _.cloneDeep(data);
    newData.cssStyle.cssBoxShadow[0].blurRadius = pxLengthObj(value);
    return newData;
  };
}

function getCSSBoxShadowSizeLinkableValue(data, path) {
  const dataItem = _.get(data.cssStyle, path[0])[0];
  return function CSSBoxShadowSizeLinkableValue() {
    return dataItem.spreadRadius.value;
  };
}

function getCSSBoxShadowSizeLinkedValueToData(data) {
  return function CSSBoxShadowSizeLinkedValueToData(value) {
    const newData = _.cloneDeep(data);
    newData.cssStyle.cssBoxShadow[0].spreadRadius = pxLengthObj(value);
    return newData;
  };
}

function getCSSBoxShadowDistanceLinkableValue(data, path) {
  const dataItem = _.get(data.cssStyle, path[0])[0];
  return function CSSBoxShadowDistanceLinkableValue() {
    return shadowDistanceFromOffsets(
      dataItem.offsetX.value,
      dataItem.offsetY.value,
    );
  };
}

function getCSSBoxShadowDistanceLinkedValueToData(data) {
  return function CSSBoxShadowDistanceLinkedValueToData(value) {
    const currentShadow = data.cssStyle.cssBoxShadow[0];
    const newData = _.cloneDeep(data);
    const fallbackAngle = getFallbackAngle(data);
    const angle = shadowAngleFromOffsets(
      currentShadow.offsetX.value,
      currentShadow.offsetY.value,
      fallbackAngle,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(
      newData.cssStyle.cssBoxShadow[0],
      angleAndDistanceToOffsets(angle, value),
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(newData.panelExtensions.cssBoxShadow[0], { angle });
    return newData;
  };
}

function getCSSBoxShadowAngleLinkableValue(data, path) {
  const dataItem = _.get(data.cssStyle, path[0])[0];
  return function CSSBoxShadowAngleLinkableValue() {
    const fallbackAngle = getFallbackAngle(data);
    return shadowAngleFromOffsets(
      dataItem.offsetX.value,
      dataItem.offsetY.value,
      fallbackAngle,
    );
  };
}

function getCSSBoxShadowAngleLinkedValueToData(data) {
  return function CSSBoxShadowAngleLinkedValueToData(value) {
    const currentShadow = data.cssStyle.cssBoxShadow[0];
    const distance = shadowDistanceFromOffsets(
      currentShadow.offsetX.value,
      currentShadow.offsetY.value,
    );
    const newData = _.cloneDeep(data);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(
      newData.cssStyle.cssBoxShadow[0],
      angleAndDistanceToOffsets(value, distance),
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(newData.panelExtensions.cssBoxShadow[0], { angle: value });
    return newData;
  };
}

function getCSSBorderRadiusLinkableValue(data, path) {
  const dataItem = _.get(data.cssStyle, path[0]);
  return function CSSBorderRadiusLinkableValue() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    return _.reduce(
      corners,
      function (borderString, corner, i) {
        return (
          borderString +
          dataItem[corner].value +
          (i !== corners.length - 1 ? ' ' : '')
        );
      },
      '',
    );
  };
}

function getCSSBorderRadiusLinkedValueToData(data, path) {
  return function CSSBorderRadiusLinkedValueToData(value) {
    const newData = _.cloneDeep(data);
    let values = value.split(' ');
    if (values.length === 1) {
      const v0 = values[0];
      values = [v0, v0, v0, v0];
    }
    const dataItem = (newData.cssStyle[path[0]] = {});
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(corners, function (corner, i) {
      dataItem[corner] = pxLengthObj(parseInt(values[i], 10));
    });
    return newData;
  };
}

function getCSSBorderColorLinkableValue(data, path: string[]) {
  const sideColorPath = [...path, 'top']; // since all sides have same styles
  const color = _.get(data.cssStyle, sideColorPath);
  const themeColor = data.themeMappings?.[sideColorPath.join('.')];

  return function CSSBorderColorLinkableValue() {
    return {
      color: themeColor || rgbaObjToRgbString(color),
      alpha: color.alpha.toFixed(4),
    };
  };
}

function getCSSBorderColorLinkedValueToData(data, path) {
  return function CSSBorderColorLinkedValueToData(value) {
    const newData = _.cloneDeep(data);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/bind
    const themeColorsHandlerFunc = _.bind(
      handleThemeColors,
      {},
      newData.themeMappings,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(borderSides, function (side) {
      updateColorValue(
        themeColorsHandlerFunc,
        newData.cssStyle,
        value,
        path.concat(side),
      );
    });
    return newData;
  };
}

function getCSSBorderWidthinkableValue(data, path) {
  const dataItem = _.get(data.cssStyle, path);
  return function CSSBorderWidthLinkableValue() {
    return dataItem.top.value;
  };
}

function getCSSBorderWidthLinkedValueToData(data, path) {
  return function CSSBorderWidthLinkedValueToData(value) {
    const newData = _.cloneDeep(data);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(borderSides, function (side) {
      _.set(newData.cssStyle, path.concat(side), pxLengthObj(value));
    });
    return newData;
  };
}

function setThemeAPI(instance) {
  themeAPI = instance;
}

function toFixedNum(x) {
  return Math.round(100 * x) / 100;
}

function deg2rad(degrees) {
  return (degrees * Math.PI) / 180;
}

function rad2deg(radians) {
  return (radians * 180) / Math.PI;
}

function shadowDistanceFromOffsets(x, y) {
  if (x === 0 || y === 0) {
    return x + y;
  }
  const angle = Math.atan2(x, y);
  return toFixedNum(x / Math.sin(angle));
}

function getFallbackAngle(data) {
  return data.panelExtensions.cssBoxShadow[0].angle;
}

function shadowAngleFromOffsets(x, y, fallback) {
  if (x === 0 && y === 0) {
    return fallback;
  }
  return (270 + rad2deg(Math.atan2(y, x))) % 360;
}

function angleAndDistanceToOffsets(angle, distance) {
  const angleRad = deg2rad(360 - angle);
  return {
    offsetX: pxLengthObj(toFixedNum(Math.sin(angleRad) * distance)),
    offsetY: pxLengthObj(toFixedNum(Math.cos(angleRad) * distance)),
  };
}

function rgbaObjToRgbString(colorObj) {
  return `#${toHex(colorObj.red)}${toHex(colorObj.green)}${toHex(
    colorObj.blue,
  )}`;
}

function toHex(num) {
  let hex = num.toString(16).toUpperCase();
  if (hex.length < 2) {
    hex = `0${hex}`;
  }
  return hex;
}

function hexStringToRGB(str) {
  return {
    red: parseInt(str.substr(1, 2), 16),
    green: parseInt(str.substr(3, 2), 16),
    blue: parseInt(str.substr(5, 2), 16),
  };
}

function handleThemeColors(themeMappings, value, path) {
  const isThemeName = /color_/.test(value);

  if (themeMappings) {
    if (isThemeName) {
      themeMappings[path.join('.')] = value;
    } else {
      delete themeMappings[path.join('.')];
    }
  }

  return isThemeName ? themeAPI.colors.get(value) : value;
}

function updateColorValue(
  themeColorsHandlerFunc,
  data,
  colorPickerValue,
  path,
) {
  const { alpha } = colorPickerValue;
  const color = themeColorsHandlerFunc(colorPickerValue.color, path);
  const colorObj = hexStringToRGB(color);
  colorObj.alpha = parseFloat(alpha);
  _.set(data, path, colorObj);
}

function pxLengthObj(value) {
  return {
    unit: 'px',
    value,
  };
}

export { setThemeAPI, categoryControlsMap, getDataManipulators };
