import addPanelDataConsts from '@/addPanelDataConsts';
import { translate } from '@/i18n';

export const uploadsAndImportsSection = {
  type: addPanelDataConsts.SECTIONS_TYPES.IMAGE,
  hide: false,
  title: 'add_section_heading_upload_import_images',
  subNavigationTitle: 'add_section_label_upload_import_images',
  presetTitle: 'add_section_heading_upload_import_images',
  subNavigationHide: false,
  showSectionHeader: true,
  automationId: 'addPanel_imageDynamic_uploadImportSection',
  props: {
    items: [
      {
        title: 'add_section_heading_upload_import_images_upload_title',
        desc: 'add_section_heading_upload_import_images_upload_text',
        openSource: 'add_panel_my_image_uploads',
        biItemName: 'myUploads',
        image: 'addPanelData/sections/imageDynamicSection/uploadImages.v1.png',
        path: 'external/computer',
        translation: {
          submitButton: translate('MMGR_submitbutton_addpanel_uploads_add'),
        },
      },
      {
        title: 'add_section_heading_upload_import_images_import_title',
        desc: 'add_section_heading_upload_import_images_import_text',
        openSource: 'add_panel_import_images',
        biItemName: 'importImages',
        image: 'addPanelData/sections/imageDynamicSection/importImages.v1.png',
        path: 'external/drive',
        translation: {
          submitButton: translate('MMGR_submitbutton_addpanel_uploads_add'),
        },
      },
    ],
  },
  help: {
    hide: false,
    text: 'image_section_image_my_uploads_info_text',
  },
};
