import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import pageCategories from '../utils/pageCategories';
import * as menuDataGetterUtil from '../panel/menuView/utils/menuDataGetterUtil';
import type { EditorAPI } from '@/editorAPI';
import type { MenuItem, PagesData } from 'types/documentServices';
import { isCustomMenusEnabled } from '@/util';
import * as actions from './actions/actions';
import { getActionsForMenuItem } from '../panel/menuView/editorAPIConnectors/menuViewActions';
import * as menuViewUtils from '../panel/menuView/editorAPIConnectors/menuViewUtils';
import type {
  BasicAction,
  OnItemAddedHandler,
  OnRenameHandler,
  OnSettingsChangeHandler,
} from '../panel/types';
import type { IPageMenuItem } from '@/menu';

interface NestedDivider {
  isDivider: boolean;
  key: string;
}

export interface MenuItemListAction extends BasicAction {
  isSettings?: boolean;
  isRename?: boolean;
}

interface MenuItemDisabledAction extends Omit<MenuItemListAction, 'onClick'> {
  onClick?: BasicAction['onClick'];
  disabled: true;
}

interface MenuItemActionFolder extends Omit<MenuItemListAction, 'onClick'> {
  isFolder: true;
  actions?: (BasicAction | NestedDivider)[];
}

export interface MenuItemCmsAction extends BasicAction {
  isCmsAction?: boolean;
}

export type MenuItemAction =
  | MenuItemListAction
  | MenuItemDisabledAction
  | MenuItemActionFolder
  | MenuItemCmsAction;

export interface IExtendedMenuData {
  items: MenuItem[];
  pagesCount: number;
  multiplePagesInMenu: boolean;
}

export interface IPagesPanelMenu {
  id: string;
  appDefId: string | undefined;
  menuItems: IExtendedMenuData['items'];
  multiPageMenu: boolean;
  title: string;
  actions?: BasicAction[];
}

const getMenus = (
  editorAPI: EditorAPI,
  pagesData: PagesData[],
  isFlatMenusView?: boolean | undefined,
): IPagesPanelMenu[] => {
  const MEMBERS_APP_DEF_ID =
    editorAPI.dsRead.platform.editorApps.SANTA_MEMBERS.appDefId;
  const menuData = editorAPI.dsRead.menu.getById('CUSTOM_MAIN_MENU', false);
  const appDefId = editorAPI.dsRead.platform.getAppDataByApplicationId(
    menuData.appId,
  ).appDefinitionId;
  const extendedMenuData = editorAPI.menus.getExtendedMenuItemsTree(
    menuData,
    pagesData,
  ) as IExtendedMenuData;

  const title = isCustomMenusEnabled()
    ? translate('NewPages_Panel_Left_MainPages_Label')
    : menuDataGetterUtil.getMenuTabNameIfApplicable(
        MEMBERS_APP_DEF_ID,
        menuData.id,
        appDefId,
        menuData.name,
        isFlatMenusView,
      );
  const viewActions = actions.menuTab(
    editorAPI,
    appDefId,
    editorAPI.isDesktopEditor(),
  );

  return [
    {
      id: menuData.id,
      appDefId,
      actions: viewActions,
      menuItems: extendedMenuData.items,
      multiPageMenu: extendedMenuData.multiplePagesInMenu,
      title,
    },
  ];
};

const getMenuItemsCollapsionGlobalState = (editorAPI: EditorAPI) =>
  stateManagement.userPreferences.selectors.getSessionUserPreferences(
    'collapsedMenuItems',
  )(editorAPI.store.getState());

const getMenuItemActions = (
  editorAPI: EditorAPI,
  isDesktop: boolean,
  isTranslating: boolean,
  onSettingsChangeHandler: OnSettingsChangeHandler,
  onItemsAddedHandler: OnItemAddedHandler,
  onRenameHandler: OnRenameHandler,
  menuItem: IPageMenuItem,
  menu: IPagesPanelMenu,
): MenuItemAction[] => {
  const biCategory = pageCategories.MENUS.getBiCategory(menu.id);
  const renderedMenuItems = menuViewUtils.getMenuItemsWithInitialCollaptionData(
    menu.menuItems,
    getMenuItemsCollapsionGlobalState(editorAPI),
  );

  return getActionsForMenuItem({
    editorAPI,
    menu,
    menuItem,
    isDesktopViewMode: isDesktop,
    isTranslating,
    onShowSettingsCallback: onSettingsChangeHandler,
    onItemAdded: onItemsAddedHandler,
    onRename: onRenameHandler,
    renderedMenuItems,
    multiPageMenu: menu.multiPageMenu,
    biCategory,
  });
};

export default {
  getMenuItemActions,
  getMenus,
};
