import addPanelDataConsts from '@/addPanelDataConsts';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.THEME,
  hide: false,
  title: 'add_section_label_theme_boxes_only',
  subNavigationTitle: 'add_section_label_theme_boxes_only',
  presetTitle: 'add_section_label_theme_boxes_only',
  tooltipTitle: 'add_section_label_theme_boxes_only',
  automationId: 'add-panel-section-boxThemeSection',
  subNavigationHide: false,
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    iconEnabledForComps: [],
  },
  props: {
    compTypes: [
      'mobile.core.components.Container',
      'core.components.Container',
    ],
    verticalMargin: 30,
    horizontalMargin: 20,
    scaleProportion: {
      x: 0.5,
      y: 0.5,
    },
  },
  help: {
    hide: false,
    text: 'add_section_info_text_theme_general',
  },
};
