// @ts-nocheck
import _ from 'lodash';
import * as SEOPanelMapper from './SEOPanelMapper';
import * as util from '@/util';

function connectSeoPanel(panel) {
  const props = function (mapperArgs, ownProps) {
    return SEOPanelMapper.mapStateToProps(mapperArgs, ownProps);
  };
  const Connected = util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    props,
    SEOPanelMapper.mapDispatchToProps,
  )(panel);

  Connected.pure = panel;
  return Connected;
}

export default _.memoize(connectSeoPanel);
