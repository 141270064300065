import React from 'react';
import {
  Button,
  Composites,
  Illustration,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';

import { compPanelFrame as CompPanelFrame } from '@/compPanelInfra';
import { translate } from '@/i18n';
import { LEGACY_WIX_APPS_NAMES } from '@/constants';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import DeprecatedWixAppsSettingsPanel from '../wixappsSettingsPanel';
import type { CompPanelHocProps } from './wixAppsDeprecationPanel.types';
import {
  type ConnectedProps,
  connectWixAppsSettingsPanel,
} from './wixAppsDeprecationPanelMapper';
import { AUTOMATION_IDS, LEGACY_WIX_APPS_IDS } from './constants';
import { isNewsAppPartName } from './appDataExport/utils';

class WixAppsDeprecationPanel extends React.Component<
  CompPanelHocProps & ConnectedProps
> {
  static displayName = 'WixAppsDeprecationPanel';
  static defaultProps = {
    handleCTAButtonClick: () => {},
  };

  shouldDisplayCTA() {
    const { appPartName, isNewsMigrated, isMenusMigrated } = this.props;

    if (isNewsAppPartName(appPartName)) {
      return !isNewsMigrated;
    }

    if (appPartName === LEGACY_WIX_APPS_NAMES.RESTAURANTS_MENUS) {
      return !isMenusMigrated;
    }

    return true;
  }

  handleHelpClick = () => {
    const { openHelpCenter, helpId } = this.props;
    openHelpCenter(helpId);
  };

  render() {
    const {
      frameProps,
      helpId,
      componentType,
      translations,
      appPartName,
      handleCTAButtonClick,
    } = this.props;

    if (LEGACY_WIX_APPS_IDS.includes(appPartName)) {
      return (
        <CompPanelFrame
          shouldTranslate={false}
          title={translate(translations.PANEL_TITLE)}
          automationId={AUTOMATION_IDS.PANEL_FRAME}
          helpId={helpId}
          selectedComponentType={componentType}
          {...frameProps}
        >
          <Composites.RichTextWithIllustrationVertical>
            <Illustration>
              <Symbol name="emptyStateRestrictedAreaRoadblock" />
            </Illustration>
            <Composites.RichTextLabeled>
              <TextLabel
                type="T09"
                shouldTranslate={false}
                value={translate(translations.TITLE)}
              />
              <RichText className="light" type="T07">
                <p>{translate(translations.TEXT)}</p>
                {translations.TEXT_2 && <p>{translate(translations.TEXT_2)}</p>}
                <a
                  onClick={this.handleHelpClick}
                  data-aid={AUTOMATION_IDS.LEARN_MORE}
                >
                  {translate(translations.LEARN_MORE)}
                </a>
                {this.shouldDisplayCTA() && (
                  <Composites.ButtonLarge>
                    <Button
                      onClick={handleCTAButtonClick}
                      automationId={AUTOMATION_IDS.CTA}
                      ellipsis
                    >
                      {translate(translations.CTA)}
                    </Button>
                  </Composites.ButtonLarge>
                )}
              </RichText>
            </Composites.RichTextLabeled>
          </Composites.RichTextWithIllustrationVertical>
        </CompPanelFrame>
      );
    }

    return React.createElement(DeprecatedWixAppsSettingsPanel, this.props);
  }
}

export default connectWixAppsSettingsPanel(WixAppsDeprecationPanel);
