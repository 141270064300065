export const AUTOMATION_IDS = {
  PANEL: 'am-panel',
  PANEL_CONTENT: 'am-panel-content',

  APP_CARD: {
    CONTAINER_PREFIX: 'am-app-card-container-',
    CTA: 'am-app-card-cta',
  },

  APP_PAGE: {
    CONTAINER: 'am-app-page-container',
    PRIMARY_ACTION: 'am-app-page-primary-action',
    SECONDARY_ACTION: 'am-app-page-secondary-action',
    LEARN_MORE_ACTION: 'am-app-page-learn-more-action',
    UPDATE_ACTION: 'am-app-page-update-action',
    REMOVE_APP_ACTION: 'am-app-page-remove-app-action',
  },

  TABS: {
    APP_TAB: 'am-app-tab',
    EXPLORE_MORE_APPS: 'am-explore-more-apps-tab',
  },

  ERROR_STATE_CONTAINER: 'am-error-container',

  MY_BUSINESS: {
    CONTAINER: 'am-my-business-container',
    SIDEBAR_APP_TAB_PREFIX: 'am-my-business-sidebar-app-tab-',
    SIDEBAR_RECOMMENDED_TAB: 'am-my-business-sidebar-tab-recommended-apps',
    SIDEBAR_MORE_APPS_TAB: 'am-my-business-sidebar-tab-more-apps',
    APP_CONTAINER_PREFIX: `am-my-business-app-container-`,
    APP_MANAGE_ACTION: 'am-my-business-app-manage-action',
    APP_HEADER: 'am-my-buisness-app-header',
    APP_SETUP: {
      HEADER: 'am-my-business-app-setup-header',
      STEP_PREFIX: 'am-my-business-app-setup-step-',
      STEP_MAIN_ACTION: 'am-my-business-app-setup-step-main-action',
      STEP_SKIP: 'am-my-business-app-setup-step-skip',
      STEP_UNSKIP: 'am-my-business-app-setup-step-unskip',
      STEP_COMPLETED_ICON: 'am-my-business-app-setup-step-completed-icon',
      SUCCESS_ANIMATION: 'am-my-business-app-setup-success',
    },
    RECOMMENDED_TOOL_PREFIX: 'am-my-business-recommended-tool',
    PENDING_APP_CONTAINER: 'am-my-business-pending-app-container',
    PENDING_APP_INSTALL_ACTION: 'am-my-business-pending-app-install-action',
    UNINSTALLED_APPS_CONTAINER: 'am-my-business-uninstalled-apps-container',
  },
};

export const getAppCardAid = (appDefId: string) =>
  AUTOMATION_IDS.APP_CARD.CONTAINER_PREFIX + appDefId;

export const getUninstalledAppCardDataHook = (appDefId: string) =>
  AUTOMATION_IDS.APP_CARD.CONTAINER_PREFIX + appDefId;

export const getBusinessAppDataHook = (appDefId: string) =>
  AUTOMATION_IDS.MY_BUSINESS.APP_CONTAINER_PREFIX + appDefId;

export const getBusinessAppSidebarTabDataHook = (appDefId: string) =>
  AUTOMATION_IDS.MY_BUSINESS.SIDEBAR_APP_TAB_PREFIX + appDefId;

export const getBusinessAppSetupStepDataHook = (stepId: string) =>
  AUTOMATION_IDS.MY_BUSINESS.APP_SETUP.STEP_PREFIX + stepId;

export const getRecommendedToolDataHook = (toolId: string) =>
  AUTOMATION_IDS.MY_BUSINESS.RECOMMENDED_TOOL_PREFIX + toolId;
