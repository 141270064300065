'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const i18n = require('@/i18n')

module.exports =
    {
        "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
        "hide": false,
        "title": "add_section_label_Hmenu",
        "subNavigationTitle": "add_section_label_Hmenu",
        "presetTitle": "add_section_label_Hmenu",
        "tooltipTitle": "add_section_label_Hmenu",
        "automationId": "add-panel-section-horizontalMenusSection",
        "subNavigationHide": false,
        "showSectionHeader": true,
        "additionalBehaviours": {
            "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
            "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
            "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
            "iconEnabledForComps": {}
        },
        "props": {
            "image": `addPanelData/sections/localizationHorizontalMenus/horizontalMenusSection_${util.languages.getLanguageCode()}.png`,
            "imageHover": null,
            "items": [{
                "id": "Menu_Horizontal_Menus_#_1",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin",
                    "layout": {
                        "width": 609,
                        "height": 45,
                        "x": 20,
                        "y": 78,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.menus.DropDownMenu",
                    "props": {
                        "type": "HorizontalMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "alignButtons": "center",
                        "alignText": "center",
                        "sameWidthButtons": true,
                        "stretchButtonsToMenuWidth": true,
                        "moreButtonLabel": i18n.translate("Horizontal_Menu_Layout_HiddenTabs_InputDefault"),
                        "moreItemHeight": 15,
                        "rtl": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-bgDrop": "1",
                                "alpha-bgh": "0.6",
                                "alpha-bgs": "1",
                                "alpha-brd": "0",
                                "alpha-sep": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "alpha-txts": "1",
                                "bg": "#FFFFFF",
                                "bgDrop": "#FFFFFF",
                                "bgh": "#EDE9E1",
                                "bgs": "#EDE9E1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFFFFF",
                                "brw": "0px",
                                "fnt": "normal normal normal 15px/1.4em helvetica-w01-light",
                                "pad": "5px",
                                "rd": "0px",
                                "rdDrop": "0px",
                                "sep": "#414141",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#444444",
                                "txth": "#444444",
                                "txts": "#444444"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-bgh": "value",
                                "alpha-brd": "value",
                                "alpha-sep": "value",
                                "bg": "value",
                                "bgDrop": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "pad": "value",
                                "rd": "value",
                                "rdDrop": "value",
                                "sep": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.menus.DropDownMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbouh43w",
                    "dataQuery": "#MAIN_MENU"
                },
                "preset": {"rect": {"width": 324, "height": 62, "x": 0, "y": 20}, "tags": null}
            }, {
                "id": "Menu_Horizontal_Menus_#_2",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.TextOnlyMenuButtonSkin",
                    "layout": {
                        "width": 609,
                        "height": 45,
                        "x": 19,
                        "y": 192,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.menus.DropDownMenu",
                    "props": {
                        "type": "HorizontalMenuProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "alignButtons": "center",
                        "alignText": "center",
                        "sameWidthButtons": false,
                        "stretchButtonsToMenuWidth": true,
                        "moreButtonLabel": i18n.translate("Horizontal_Menu_Layout_HiddenTabs_InputDefault"),
                        "moreItemHeight": 15,
                        "rtl": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bgDrop": "1",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "alpha-txts": "1",
                                "bgDrop": "#FFFFFF",
                                "boxShadowToggleOn-shd": "false",
                                "fnt": "normal normal normal 20px/1.4em anton",
                                "menuTotalBordersY": "0",
                                "pad": "5px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#000000",
                                "txth": "#20CE88",
                                "txts": "#20CE88"
                            },
                            "propertiesSource": {
                                "bgDrop": "value",
                                "fnt": "value",
                                "menuTotalBordersY": "value",
                                "pad": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.menus.DropDownMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.TextOnlyMenuButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbouh449",
                    "dataQuery": "#MAIN_MENU"
                },
                "preset": {"rect": {"width": 324, "height": 50, "x": 0, "y": 86}, "tags": null}
            }, {
                "id": "Menu_Horizontal_Menus_#_3",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateBasicMenuButtonSkin",
                    "layout": {
                        "width": 609,
                        "height": 45,
                        "x": 20,
                        "y": 309,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.menus.DropDownMenu",
                    "props": {
                        "type": "HorizontalMenuProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "alignButtons": "center",
                        "alignText": "center",
                        "sameWidthButtons": false,
                        "stretchButtonsToMenuWidth": true,
                        "moreButtonLabel": i18n.translate("Horizontal_Menu_Layout_HiddenTabs_InputDefault"),
                        "moreItemHeight": 15,
                        "rtl": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgDrop": "1",
                                "alpha-bgh": "1",
                                "alpha-bgs": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-brds": "0",
                                "bg": "#FFFFFF",
                                "bgDrop": "#FFFFFF",
                                "bgh": "#566FB8",
                                "bgs": "#566FB8",
                                "boxShadowToggleOn-noshd": "true",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#566FB8",
                                "brdh": "#566FB8",
                                "brds": "#566FB8",
                                "brw": "2",
                                "fnt": "normal normal normal 17px/1.4em eb+garamond",
                                "menuTotalBordersX": "0",
                                "menuTotalBordersY": "0",
                                "nord": "0",
                                "noshd": "none",
                                "pad": "10",
                                "rd": "0px",
                                "rdDrop": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#566FB8",
                                "txth": "#FFFFFF",
                                "txts": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-bgDrop": "value",
                                "alpha-bgh": "value",
                                "alpha-bgs": "value",
                                "alpha-brd": "value",
                                "alpha-brdh": "value",
                                "alpha-brds": "value",
                                "bg": "value",
                                "bgDrop": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "boxShadowToggleOn-noshd": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brds": "value",
                                "brw": "value",
                                "fnt": "value",
                                "menuTotalBordersX": "value",
                                "menuTotalBordersY": "value",
                                "nord": "value",
                                "noshd": "value",
                                "pad": "value",
                                "rd": "value",
                                "rdDrop": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.menus.DropDownMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateBasicMenuButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbouh44k",
                    "dataQuery": "#MAIN_MENU"
                },
                "preset": {"rect": {"width": 324, "height": 67, "x": 0, "y": 146}, "tags": null}
            }, {
                "id": "Menu_Horizontal_Menus_#_4",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonSkin",
                    "layout": {
                        "width": 609,
                        "height": 40,
                        "x": 20,
                        "y": 447,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.menus.DropDownMenu",
                    "props": {
                        "type": "HorizontalMenuProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "alignButtons": "center",
                        "alignText": "center",
                        "sameWidthButtons": false,
                        "stretchButtonsToMenuWidth": true,
                        "moreButtonLabel": i18n.translate("Horizontal_Menu_Layout_HiddenTabs_InputDefault"),
                        "moreItemHeight": 15,
                        "rtl": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bgDrop": "1",
                                "alpha-brd": "0",
                                "alpha-brdh": "1",
                                "alpha-brds": "1",
                                "bgDrop": "#FFFFFF",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#414141",
                                "brdh": "#000000",
                                "brds": "#000000",
                                "fnt": "normal normal normal 16px/1.4em playfair+display",
                                "menuTotalBordersX": "0",
                                "pad": "10",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#858585",
                                "txth": "#000000",
                                "txts": "#000000"
                            },
                            "propertiesSource": {
                                "alpha-bgDrop": "value",
                                "alpha-brd": "value",
                                "alpha-brdh": "value",
                                "alpha-brds": "value",
                                "bgDrop": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brds": "value",
                                "fnt": "value",
                                "menuTotalBordersX": "value",
                                "pad": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.menus.DropDownMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbouh44t",
                    "dataQuery": "#MAIN_MENU"
                },
                "preset": {"rect": {"width": 324, "height": 68, "x": 0, "y": 220}, "tags": null}
            }, {
                "id": "Menu_Horizontal_Menus_#_5",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateBasicMenuButtonSkin",
                    "layout": {
                        "width": 609,
                        "height": 50,
                        "x": 20,
                        "y": 587,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.menus.DropDownMenu",
                    "props": {
                        "type": "HorizontalMenuProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "alignButtons": "center",
                        "alignText": "center",
                        "sameWidthButtons": false,
                        "stretchButtonsToMenuWidth": true,
                        "moreButtonLabel": i18n.translate("Horizontal_Menu_Layout_HiddenTabs_InputDefault"),
                        "moreItemHeight": 15,
                        "rtl": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgDrop": "1",
                                "alpha-bgh": "0",
                                "alpha-bgs": "0",
                                "alpha-brd": "0",
                                "alpha-sep": "0",
                                "bg": "#EEE8D8",
                                "bgDrop": "#EEE8D8",
                                "bgh": "#FFFFFF",
                                "bgs": "#FFFFFF",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#858585",
                                "brw": "0",
                                "fnt": "normal normal normal 15px/1.4em futura-lt-w01-book",
                                "pad": "5px",
                                "rd": "80px",
                                "rdDrop": "0px",
                                "sep": "#858585",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#696969",
                                "txth": "#000000",
                                "txts": "#000000"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-bgDrop": "value",
                                "alpha-bgh": "value",
                                "alpha-bgs": "value",
                                "alpha-brd": "value",
                                "alpha-sep": "value",
                                "bg": "value",
                                "bgDrop": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "pad": "value",
                                "rd": "value",
                                "rdDrop": "value",
                                "sep": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.menus.DropDownMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbouh454",
                    "dataQuery": "#MAIN_MENU"
                },
                "preset": {"rect": {"width": 324, "height": 70, "x": 0, "y": 296}, "tags": null}
            }, {
                "id": "Menu_Horizontal_Menus_#_6",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin",
                    "layout": {
                        "width": 609,
                        "height": 55,
                        "x": 20,
                        "y": 749,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.menus.DropDownMenu",
                    "props": {
                        "type": "HorizontalMenuProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "alignButtons": "center",
                        "alignText": "center",
                        "sameWidthButtons": false,
                        "stretchButtonsToMenuWidth": true,
                        "moreButtonLabel": i18n.translate("Horizontal_Menu_Layout_HiddenTabs_InputDefault"),
                        "moreItemHeight": 15,
                        "rtl": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bgDrop": "1",
                                "alpha-bgh": "0",
                                "alpha-bgs": "0",
                                "alpha-brd": "1",
                                "alpha-brdh": "1",
                                "alpha-brds": "1",
                                "bgDrop": "#CCCCCC",
                                "brd": "#CCCCCC",
                                "brdh": "#414141",
                                "brds": "#414141",
                                "brw": "2",
                                "fnt": "italic normal normal 16px/1.4em georgia",
                                "menuTotalBordersX": "0",
                                "menuTotalBordersY": "0",
                                "pad": "15",
                                "rd": "0",
                                "txt": "#9E9E9E"
                            },
                            "propertiesSource": {
                                "alpha-bgDrop": "value",
                                "alpha-bgh": "value",
                                "alpha-bgs": "value",
                                "alpha-brd": "value",
                                "alpha-brdh": "value",
                                "alpha-brds": "value",
                                "bgDrop": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brds": "value",
                                "brw": "value",
                                "fnt": "value",
                                "menuTotalBordersX": "value",
                                "menuTotalBordersY": "value",
                                "pad": "value",
                                "rd": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.menus.DropDownMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateLinesMenuButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbouh45d",
                    "dataQuery": "#MAIN_MENU"
                },
                "preset": {"rect": {"width": 324, "height": 80, "x": 0, "y": 378}, "tags": null}
            }, {
                "id": "Menu_Horizontal_Menus_#_7",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin",
                    "layout": {
                        "width": 609,
                        "height": 27,
                        "x": 20,
                        "y": 913,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.menus.DropDownMenu",
                    "props": {
                        "type": "HorizontalMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "alignButtons": "center",
                        "alignText": "center",
                        "sameWidthButtons": true,
                        "stretchButtonsToMenuWidth": true,
                        "moreButtonLabel": i18n.translate("Horizontal_Menu_Layout_HiddenTabs_InputDefault"),
                        "moreItemHeight": 15,
                        "rtl": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bgDrop": "1",
                                "alpha-sep": "1",
                                "bgDrop": "#FFFFFF",
                                "boxShadowToggleOn-shd": "false",
                                "fnt": "normal normal 700 14px/1.4em raleway",
                                "menuTotalBordersX": "0",
                                "menuTotalBordersY": "0",
                                "pad": "5px",
                                "rd": "0",
                                "sep": "#D8BDE2",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#B45AD3",
                                "txth": "#532563",
                                "txts": "#532563"
                            },
                            "propertiesSource": {
                                "alpha-bgDrop": "value",
                                "alpha-sep": "value",
                                "bgDrop": "value",
                                "boxShadowToggleOn-shd": "value",
                                "fnt": "value",
                                "menuTotalBordersX": "value",
                                "menuTotalBordersY": "value",
                                "pad": "value",
                                "rd": "value",
                                "sep": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.menus.DropDownMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.TextSeparatorsMenuButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbouh45n",
                    "dataQuery": "#MAIN_MENU"
                },
                "preset": {"rect": {"width": 324, "height": 56, "x": 0, "y": 464}, "tags": null}
            }, {
                "id": "Menu_Horizontal_Menus_#_8",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin",
                    "layout": {
                        "width": 609,
                        "height": 50,
                        "x": 20,
                        "y": 1009,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.menus.DropDownMenu",
                    "props": {
                        "type": "HorizontalMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "alignButtons": "center",
                        "alignText": "center",
                        "sameWidthButtons": true,
                        "stretchButtonsToMenuWidth": true,
                        "moreButtonLabel": i18n.translate("Horizontal_Menu_Layout_HiddenTabs_InputDefault"),
                        "moreItemHeight": 15,
                        "rtl": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-bgDrop": "1",
                                "alpha-bgh": "1",
                                "alpha-bgs": "1",
                                "alpha-brd": "0",
                                "alpha-sep": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "alpha-txts": "1",
                                "bg": "#FFFFFF",
                                "bgDrop": "#FFFFFF",
                                "bgh": "#FFD9E4",
                                "bgs": "#FFD9E4",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#FFFFFF",
                                "brw": "0px",
                                "fnt": "normal normal normal 16px/1.4em baskervillemtw01-smbdit",
                                "pad": "5px",
                                "rd": "0px",
                                "rdDrop": "0px",
                                "sep": "#414141",
                                "shd": "0.00px 4.00px 0px 0px rgba(0,0,0,1)",
                                "txt": "#444444",
                                "txth": "#E33292",
                                "txts": "#E33292"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-bgh": "value",
                                "alpha-brd": "value",
                                "alpha-sep": "value",
                                "bg": "value",
                                "bgDrop": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "pad": "value",
                                "rd": "value",
                                "rdDrop": "value",
                                "sep": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.menus.DropDownMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbouh45z",
                    "dataQuery": "#MAIN_MENU"
                },
                "preset": {"rect": {"width": 324, "height": 64, "x": 0, "y": 532}, "tags": null}
            }, {
                "id": "Menu_Horizontal_Menus_#_9",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateBasicMenuButtonSkin",
                    "layout": {
                        "width": 609,
                        "height": 50,
                        "x": 20,
                        "y": 1167,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.menus.DropDownMenu",
                    "props": {
                        "type": "HorizontalMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "alignButtons": "center",
                        "alignText": "center",
                        "sameWidthButtons": true,
                        "stretchButtonsToMenuWidth": true,
                        "moreButtonLabel": i18n.translate("Horizontal_Menu_Layout_HiddenTabs_InputDefault"),
                        "moreItemHeight": 15,
                        "rtl": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgDrop": "1",
                                "alpha-bgh": "1",
                                "alpha-bgs": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-brds": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "alpha-txts": "1",
                                "bg": "#FFFFFF",
                                "bgDrop": "#FFFFFF",
                                "bgh": "#FFDE5F",
                                "bgs": "#FFDE5F",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#383838",
                                "brdh": "#000000",
                                "brds": "#000000",
                                "brw": "1",
                                "fnt": "normal normal normal 15px/1.4em avenir-lt-w01_35-light1475496",
                                "pad": "10",
                                "rd": "80px",
                                "rdDrop": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#383838",
                                "txth": "#000000",
                                "txts": "#000000"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brdh": "value",
                                "alpha-brds": "value",
                                "bg": "value",
                                "bgDrop": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brds": "value",
                                "brw": "value",
                                "fnt": "value",
                                "pad": "value",
                                "rd": "value",
                                "rdDrop": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.menus.DropDownMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SeparateBasicMenuButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbouh46b",
                    "dataQuery": "#MAIN_MENU"
                },
                "preset": {"rect": {"width": 324, "height": 70, "x": 0, "y": 608}, "tags": null}
            }, {
                "id": "Menu_Horizontal_Menus_#_10",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.LinesMenuButtonSkin",
                    "layout": {
                        "width": 609,
                        "height": 60,
                        "x": 20,
                        "y": 1315,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.menus.DropDownMenu",
                    "props": {
                        "type": "HorizontalMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "alignButtons": "center",
                        "alignText": "center",
                        "sameWidthButtons": true,
                        "stretchButtonsToMenuWidth": false,
                        "moreButtonLabel": i18n.translate("Horizontal_Menu_Layout_HiddenTabs_InputDefault"),
                        "moreItemHeight": 15,
                        "rtl": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bgDrop": "1",
                                "alpha-bgh": "0",
                                "alpha-bgs": "0",
                                "alpha-brd": "1",
                                "bgh": "#CACACA",
                                "bgs": "#CACACA",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#CACACA",
                                "brw": "1px",
                                "fnt": "normal normal normal 18px/1.4em kepler-w03-light-scd-cp",
                                "menuTotalBordersX": "0",
                                "pad": "5px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#9E9E9E"
                            },
                            "propertiesSource": {
                                "alpha-bgDrop": "value",
                                "alpha-bgh": "value",
                                "alpha-bgs": "value",
                                "alpha-brd": "value",
                                "bgDrop": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "menuTotalBordersX": "value",
                                "pad": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.menus.DropDownMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.LinesMenuButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbouh46n",
                    "dataQuery": "#MAIN_MENU"
                },
                "preset": {"rect": {"width": 324, "height": 76, "x": 0, "y": 690}, "tags": null}
            }, {
                "id": "Menu_Horizontal_Menus_#_11",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin",
                    "layout": {
                        "width": 609,
                        "height": 45,
                        "x": 20,
                        "y": 1470,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.menus.DropDownMenu",
                    "props": {
                        "type": "HorizontalMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "alignButtons": "center",
                        "alignText": "center",
                        "sameWidthButtons": true,
                        "stretchButtonsToMenuWidth": true,
                        "moreButtonLabel": i18n.translate("Horizontal_Menu_Layout_HiddenTabs_InputDefault"),
                        "moreItemHeight": 15,
                        "rtl": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgDrop": "1",
                                "alpha-bgh": "1",
                                "alpha-bgs": "1",
                                "alpha-brd": "0",
                                "alpha-sep": "1",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "alpha-txts": "1",
                                "bg": "#F1F1F1",
                                "bgDrop": "#FFFFFF",
                                "bgh": "#323232",
                                "bgs": "#323232",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFFFFF",
                                "brw": "0",
                                "fnt": "normal normal normal 17px/1.4em dinneuzeitgroteskltw01-_812426",
                                "pad": "5px",
                                "rd": "0px",
                                "rdDrop": "0px",
                                "sep": "#FFFFFF",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#000000",
                                "txth": "#FFFFFF",
                                "txts": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-bgh": "value",
                                "alpha-brd": "value",
                                "alpha-sep": "value",
                                "bg": "value",
                                "bgDrop": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "pad": "value",
                                "rd": "value",
                                "rdDrop": "value",
                                "sep": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.menus.DropDownMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbouh474",
                    "dataQuery": "#MAIN_MENU"
                },
                "preset": {"rect": {"width": 324, "height": 66, "x": 0, "y": 774}, "tags": null}
            }],
            "compTypes": ["wysiwyg.viewer.components.menus.DropDownMenu"]
        }
    }
