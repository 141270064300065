import type { BiEventDefinition } from 'types/bi';

// TODO: For some reason, there is no endpoint in BiEventDefinition.
interface IEventsMap {
  [key: string]: BiEventDefinition & { endpoint?: string };
}

export const events: IEventsMap = {
  FIRST_TIME_STORES_EXPOSURE: {
    src: 10,
    evid: 45,
    endpoint: 'ec-sf',
    fields: {
      testName: 'string',
      is_eligible: 'string',
      type: 'string',
    },
  },
  VIEW_STORES_PANEL_IN_EDITOR: {
    src: 10,
    evid: 810,
    endpoint: 'ec-sf',
    fields: {
      app_id: 'string',
      app_name: 'string',
      type: 'string',
      state: 'string',
    },
  },
  CLICK_ON_QUICK_ACTION_STORES_PANEL_IN_EDITOR: {
    src: 10,
    evid: 850,
    endpoint: 'ec-sf',
    fields: {
      app_id: 'string',
      appName: 'string',
      buttonName: 'string',
      origin: 'string',
    },
  },

  MANAGE_PANEL_BUTTON_CLICK: {
    src: 38,
    evid: 205,
    endpoint: 'editor',
    fields: {
      button_name: 'string',
      app_id: 'string',
      app_name: 'string',
    },
  },
  PAGE_PANEL_ADD_STORE_PAGE_CLICK: {
    src: 38,
    evid: 208,
    endpoint: 'editor',
    fields: {
      app_id: 'string',
      app_name: 'string',
    },
  },
};
