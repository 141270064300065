const getEjsTemplate = () => `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
      <% cssLinks.forEach(function(link){ %>
        <link rel="stylesheet" type="text/css" href="<%= link %>">
      <% }) %>
     <style><%= style %></style>
  </head>
  <body>
    <%= body %>
  </body>
</html>
`;

const getBodyHTML = (snapshots: AnyFixMe, width: AnyFixMe, height: AnyFixMe) =>
  `<div style="position:relative;width:${width}px;height:${height}px;">
${snapshots
  .map(
    (item: AnyFixMe) =>
      `<div style="position:absolute;left:${item.left}px;top:${item.top}px">${item.innerHTML}</div>`,
  )
  .join('')}
</div>`;

export { getEjsTemplate, getBodyHTML };
