'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

const singleTrackPlayerSection2 = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_singletrackplayer',
    "automationId": "add-panel-section-singleTrackPlayerSectionSection2",
    'subNavigationHide': true,
    'showSectionHeader': false,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/singleTrackPlayerSection2_en/singleTrackPlayerSection2_en.png',
        'imageHover': null,
        'items': [{
            'id': 'Music_Single_Track_Player_#2_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerRoundPlay',
                'layout': {
                    'width': 304,
                    'height': 52,
                    'x': 10,
                    'y': 15,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
                'data': {
                    'type': 'SingleAudioPlayer',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'originalFileName': 'Maid with the Flaxen Hair.mp3',
                    'artist': 'Wix',
                    'track': 'Stunning Sites'
                },
                'props': {
                    'type': 'SingleAudioPlayerProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'volume': 50,
                    'autoplay': false,
                    'loop': false,
                    'showArtist': true,
                    'showTrack': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-audioIcons': '1',
                            'alpha-audioText': '1',
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'alpha-prog': '1',
                            'alpha-vol': '1',
                            'audioIcons': '#FFFFFF',
                            'audioText': '#FFFFFF',
                            'bg': '#ED1566',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#F2668F',
                            'brw': '0',
                            'prog': '#FFFFFF',
                            'rd': '0',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'vol': '#FFFFFF'
                        },
                        'propertiesSource': {
                            'audioIcons': 'value',
                            'audioText': 'value',
                            'bg': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'prog': 'value',
                            'rd': 'value',
                            'shd': 'value',
                            'vol': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
                    'pageId': '',
                    'compId': 'i6rnv5dy',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerRoundPlay'
                },
                'id': 'i6rnv5dy'
            },
            'preset': {'rect': {'width': 324, 'height': 82, 'x': 0, 'y': 0}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_#2_2',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin',
                'layout': {
                    'width': 304,
                    'height': 60,
                    'x': 10,
                    'y': 97,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
                'data': {
                    'type': 'SingleAudioPlayer',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'originalFileName': 'Maid with the Flaxen Hair.mp3',
                    'artist': 'Wix',
                    'track': 'Stunning Sites'
                },
                'props': {
                    'type': 'SingleAudioPlayerProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'volume': 42,
                    'autoplay': false,
                    'loop': false,
                    'showArtist': true,
                    'showTrack': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-audioIcons': '1',
                            'alpha-audioText': '1',
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'alpha-prog': '1',
                            'alpha-vol': '1',
                            'audioIcons': '#FFFFFF',
                            'audioText': '#FFFFFF',
                            'bg': '#D61043',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#F2668F',
                            'brw': '0px',
                            'prog': '#FFFFFF',
                            'rd': '0',
                            'shd': '0px 1px 1px 0px rgba(0,0,0,0.6)',
                            'vol': '#FFFFFF'
                        },
                        'propertiesSource': {
                            'audioIcons': 'value',
                            'audioText': 'value',
                            'bg': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'dividers': 'value',
                            'prog': 'value',
                            'rd': 'value',
                            'shd': 'value',
                            'vol': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
                    'pageId': '',
                    'compId': 'i6rnv5dx',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin'
                },
                'id': 'i6rnv5dx'
            },
            'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 82}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_#2_3',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin',
                'layout': {
                    'width': 302,
                    'height': 60,
                    'x': 10,
                    'y': 187,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
                'data': {
                    'type': 'SingleAudioPlayer',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'originalFileName': 'Maid with the Flaxen Hair.mp3',
                    'artist': 'Wix',
                    'track': 'Stunning Sites'
                },
                'props': {
                    'type': 'SingleAudioPlayerProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'volume': 50,
                    'autoplay': false,
                    'loop': false,
                    'showArtist': true,
                    'showTrack': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-audioIcons': '1',
                            'alpha-audioText': '1',
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'alpha-prog': '1',
                            'alpha-vol': '1',
                            'audioIcons': '#1C8A43',
                            'audioText': '#1C8A43',
                            'bg': '#FFFFFF',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#1C8A43',
                            'brw': '1px',
                            'prog': '#68B04D',
                            'rd': '6px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'vol': '#68B04D'
                        },
                        'propertiesSource': {
                            'audioIcons': 'value',
                            'audioText': 'value',
                            'bg': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'prog': 'value',
                            'rd': 'value',
                            'shd': 'value',
                            'vol': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
                    'pageId': '',
                    'compId': 'i6rnv5dv',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin'
                },
                'id': 'i6rnv5dv'
            },
            'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 172}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_#2_4',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin',
                'layout': {
                    'width': 302,
                    'height': 60,
                    'x': 10,
                    'y': 275,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
                'data': {
                    'type': 'SingleAudioPlayer',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'originalFileName': 'Maid with the Flaxen Hair.mp3',
                    'artist': 'Wix',
                    'track': 'Stunning Sites'
                },
                'props': {
                    'type': 'SingleAudioPlayerProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'volume': 50,
                    'autoplay': false,
                    'loop': false,
                    'showArtist': true,
                    'showTrack': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-audioIcons': '1',
                            'alpha-audioText': '1',
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'alpha-prog': '1',
                            'alpha-vol': '1',
                            'audioIcons': '#FFFFFF',
                            'audioText': '#FFFFFF',
                            'bg': '#01B1AF',
                            'boxShadowToggleOn-shd': 'true',
                            'brd': '#006C6D',
                            'brw': '0',
                            'prog': '#FFFFFF',
                            'rd': '0',
                            'shd': '0 1px 4px rgba(0, 0, 0, 0.6);',
                            'vol': '#006C6D'
                        },
                        'propertiesSource': {
                            'audioIcons': 'value',
                            'audioText': 'value',
                            'bg': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'prog': 'value',
                            'rd': 'value',
                            'shd': 'value',
                            'vol': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
                    'pageId': '',
                    'compId': 'i6rnv5du',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin'
                },
                'id': 'i6rnv5du'
            },
            'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 262}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_#2_5',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerFramedPlay',
                'layout': {
                    'width': 298,
                    'height': 68,
                    'x': 10,
                    'y': 365,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
                'data': {
                    'type': 'SingleAudioPlayer',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'originalFileName': 'Maid with the Flaxen Hair.mp3',
                    'artist': 'Wix',
                    'track': 'Stunning Sites'
                },
                'props': {
                    'type': 'SingleAudioPlayerProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'volume': 50,
                    'autoplay': false,
                    'loop': false,
                    'showArtist': true,
                    'showTrack': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-audioIcons': '0.8',
                            'alpha-audioText': '1',
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'alpha-dividers': '1',
                            'alpha-prog': '1',
                            'alpha-vol': '1',
                            'audioIcons': '#FFFFFF',
                            'audioText': '#FFFFFF',
                            'bg': '#006C6D',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#01B1AF',
                            'brw': '2px',
                            'dividers': '#01B1AF',
                            'prog': '#FFFFFF',
                            'rd': '0px',
                            'shd': '0px 0px 1px 1px rgba(0,0,0,0.3)',
                            'vol': '#FFFFFF'
                        },
                        'propertiesSource': {
                            'audioIcons': 'value',
                            'audioText': 'value',
                            'bg': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'dividers': 'value',
                            'prog': 'value',
                            'rd': 'value',
                            'shd': 'value',
                            'vol': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
                    'pageId': '',
                    'compId': 'i6rnv5ds',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerFramedPlay'
                },
                'id': 'i6rnv5ds'
            },
            'preset': {'rect': {'width': 324, 'height': 102, 'x': 0, 'y': 352}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_#2_6',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerFramedPlay',
                'layout': {
                    'width': 303,
                    'height': 68,
                    'x': 10,
                    'y': 463,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
                'data': {
                    'type': 'SingleAudioPlayer',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'originalFileName': 'Maid with the Flaxen Hair.mp3',
                    'artist': 'Wix',
                    'track': 'Stunning Sites'
                },
                'props': {
                    'type': 'SingleAudioPlayerProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'volume': 50,
                    'autoplay': false,
                    'loop': false,
                    'showArtist': true,
                    'showTrack': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-audioIcons': '1',
                            'alpha-audioText': '1',
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'alpha-dividers': '1',
                            'alpha-prog': '1',
                            'alpha-vol': '1',
                            'audioIcons': '#FFFFFF',
                            'audioText': '#FFFFFF',
                            'bg': '#B22087',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#C65AA5',
                            'brw': '0',
                            'dividers': '#C65AA5',
                            'prog': '#FFFFFF',
                            'rd': '6px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'vol': '#FFFFFF'
                        },
                        'propertiesSource': {
                            'audioIcons': 'value',
                            'audioText': 'value',
                            'bg': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'dividers': 'value',
                            'prog': 'value',
                            'rd': 'value',
                            'shd': 'value',
                            'vol': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
                    'pageId': '',
                    'compId': 'i6rnv5dq',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerFramedPlay'
                },
                'id': 'i6rnv5dq'
            },
            'preset': {'rect': {'width': 324, 'height': 98, 'x': 0, 'y': 454}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_#2_7',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerLargePlay',
                'layout': {
                    'width': 304,
                    'height': 75,
                    'x': 10,
                    'y': 561,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
                'data': {
                    'type': 'SingleAudioPlayer',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'originalFileName': 'Maid with the Flaxen Hair.mp3',
                    'artist': 'Wix',
                    'track': 'Stunning Sites'
                },
                'props': {
                    'type': 'SingleAudioPlayerProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'volume': 50,
                    'autoplay': false,
                    'loop': false,
                    'showArtist': true,
                    'showTrack': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-audioIcons': '1',
                            'alpha-audioText': '1',
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'alpha-dividers': '1',
                            'alpha-prog': '1',
                            'alpha-vol': '1',
                            'audioIcons': '#FFFFFF',
                            'audioText': '#FFFFFF',
                            'bg': '#7E0C6E',
                            'boxShadowToggleOn-shd': 'true',
                            'brd': '#01B1AF',
                            'brw': '0',
                            'dividers': '#FFFFFF',
                            'prog': '#C65AA5',
                            'shd': '0px 1px 1px 0px rgba(0,0,0,0.6)',
                            'vol': '#FFFFFF'
                        },
                        'propertiesSource': {
                            'audioIcons': 'value',
                            'audioText': 'value',
                            'bg': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'dividers': 'value',
                            'prog': 'value',
                            'shd': 'value',
                            'vol': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
                    'pageId': '',
                    'compId': 'i6rnv5dm',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.singleaudioplayer.viewer.skins.EPlayerLargePlay'
                },
                'id': 'i6rnv5dm'
            },
            'preset': {'rect': {'width': 324, 'height': 105, 'x': 0, 'y': 552}, 'label': '', 'tags': null}
        }],
        'compTypes': ['wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer']
    }
}

module.exports = {} || singleTrackPlayerSection2
