'use strict'

const _ = require('lodash')

function getCompDef(item) {
    return {
        "layout": {
            "width": 200,
            "height": 200,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "props": {
            "type": "VectorImageProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "displayMode": "fit"
        },
        "skin": "skins.viewer.VectorImageSkin",
        "componentType": "wysiwyg.viewer.components.VectorImage",
        "data": {
            "type": "VectorImage",
            "svgId": item.uri,

            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
        },
        "design": {
            "type": "VectorImageDesignData",
            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
            "shapeStyle": {
                "opacity": 1
            },
            "overrideColors": null
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
            "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
            },
            "componentClassName": "wysiwyg.viewer.components.VectorImage",
            "styleType": "custom",
            "skin": "skins.viewer.VectorImageSkin"
        }
    }
}

/**
 * Save media manager path for the given id to site prefs
 * Expects a single comp
 * @param editorAPI
 * @param id
 * @param path
 */

function onItemsSelected(props, editorAPI, items, info) {
    _.forEach(items, function (item) {
        props.onClick(getCompDef(item), props.sectionTitle, '')
        editorAPI.store.dispatch(props.setSessionUserPreferences(`last_media_path_${item.uri}`, info.path))
    })
}

module.exports = function (editorAPI, props) {
    const category = editorAPI.mediaServices.mediaManager.categories[props.category]
    editorAPI.bi.event(props.addPanelBiEvent.ADD_MENU_MORE_CLICK, {
        category: props.categoryId,
        section: props.sectionName
    })
    editorAPI.mediaServices.mediaManager.open(category, props.openSource, {
        multiSelect: true,
        callback: function (items, info) {
            onItemsSelected(props, editorAPI, items, info)
        },
        path: props.path
    })
}
