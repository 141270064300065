import type { Scope } from '../../switchLayoutEntryPoint';
import {
  SCAN_SWITCH_LAYOUT_PRESET_URL,
  booleanRuleKeys,
  stringRuleKeys,
  falseIncludedNumericRuleKeys,
} from './scanSwitchLayoutPresetConsts';
import type {
  ScanSwitchLayoutPresetCmsData,
  ScanSwitchLayoutPresetData,
  FormData,
  ScanSwitchLayoutPresetCmsRules,
  TextOrImageData,
} from './scanSwitchLayoutPresetTypes';

export async function submitSectionScan(
  scope: Scope,
  sectionScanData: ScanSwitchLayoutPresetData,
) {
  try {
    const res = await fetch(SCAN_SWITCH_LAYOUT_PRESET_URL, {
      method: 'POST',
      body: JSON.stringify(sectionScanData),
    });
    const data = await res.json();
    const id = data.hasOwnProperty('inserted') ? data.inserted._id : '';
    return { message: 'Scan submitted', id };
  } catch (e) {
    console.log('scan failed, try again:', e);
    return { message: e instanceof Error && e.message, isError: true };
  }
}

export async function getSectionScan({ pageId, sectionId }: any) {
  try {
    const res = await fetch(
      `${SCAN_SWITCH_LAYOUT_PRESET_URL}/${pageId}/${sectionId}`,
    );
    const data = await res.json();
    return data.result;
  } catch (e) {
    console.log('getSectionScan:', e);
    return null;
  }
}

function isEmptyObj(obj: any): boolean {
  return Object.keys(obj).length === 0;
}

function cleanEmptyProps(obj: TextOrImageData) {
  const keys = Object.keys(obj);
  return keys.reduce((acc: TextOrImageData, key: string) => {
    if (falseIncludedNumericRuleKeys.includes(key)) {
      (acc[key as keyof TextOrImageData] as string | number) =
        +obj[key as keyof TextOrImageData];
    } else if (booleanRuleKeys.includes(key)) {
      acc[key as keyof TextOrImageData] = obj[key as keyof TextOrImageData];
    } else if (
      obj[key as keyof TextOrImageData] &&
      stringRuleKeys.includes(key)
    ) {
      acc[key as keyof TextOrImageData] = obj[key as keyof TextOrImageData];
    } else if (
      obj[key as keyof TextOrImageData] &&
      +obj[key as keyof TextOrImageData] &&
      !isNaN(+obj[key as keyof TextOrImageData])
    ) {
      (acc[key as keyof TextOrImageData] as string | number) =
        +obj[key as keyof TextOrImageData];
    }
    return acc;
  }, {});
}

function cleanRules(obj: ScanSwitchLayoutPresetCmsRules) {
  const keys = Object.keys(obj);
  return keys.reduce((acc: Record<string, any>, key: string) => {
    const cleanedRule = obj[key as keyof ScanSwitchLayoutPresetCmsRules]
      .map((item: TextOrImageData) => cleanEmptyProps(item))
      .filter((item: TextOrImageData) => !isEmptyObj(item));
    if (cleanedRule.length) {
      acc[key] = cleanedRule;
    }
    return acc;
  }, {});
}

export function createSectionScanCmsRules(
  formData: FormData,
): ScanSwitchLayoutPresetCmsRules {
  const { titleData, subtitleData, paragraphData, imageData } = formData;
  const rules = {
    title: titleData,
    subtitle: subtitleData,
    paragraph: paragraphData,
    image: imageData,
  };

  return cleanRules(rules);
}

export function enrichSectionScanForm(
  sectionScan: ScanSwitchLayoutPresetCmsData,
): FormData {
  const { _id, title, rules } = sectionScan;
  return {
    id: _id || '',
    groupLayout: title || '',
    titleData: rules.title || [],
    subtitleData: rules.subtitle || [],
    paragraphData: rules.paragraph || [],
    imageData: rules.image || [],
  };
}

export function validateNumberInput(value: string): boolean {
  const pattern = /^[0-9]*?\*?[0-9]*?$/;
  return pattern.test(value);
}

export function isLeadingZeros(value: string): boolean {
  const pattern = /\b0+/;
  return pattern.test(value);
}

export const cleanLeadingZeros = (value: string): string =>
  value.replace(/\b0+/, '');

export const isCalcInput = (value: string): boolean => value.includes('*');

export const calcInput = (value: string): string => {
  const [first, second] = value.split('*');
  return (+first * +second).toString();
};
