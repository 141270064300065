import _ from 'lodash';
import constants from '@/constants';
import { gfppModel } from '@/gfppData';
import {
  getCompDisplayNameOverride,
  getControlGfppActionsData,
  hasSettingsPanel,
  shouldSelectAndEditParent,
  isSingleColumnInStripWithoutBackground,
  openQuickEditDrillInInternal,
  openComponentPanelDrillInInternal,
} from '../quickEditEngineUtils';
import {
  QUICK_EDIT_PANEL_HELP_ID,
  quickEditPanelOrigin,
  SectionHeaderType,
  SectionFooterType,
} from '../consts';
import { highlights, platform } from '@/stateManagement';
import { loadComponentPart } from '@/componentModel';
import { QuickEditControlActions } from '@wix/editor-elements-types/quickEditControls';
import { EditorRestrictionsApiKey } from '@/apis';

import type { RefObject } from 'react';
import type { QuickEditEngineScope } from '../quickEditEngineEntryPoint';
import type {
  ComponentValueRecord,
  QuickEditControlWrapperInnerOwnProps,
} from './componentControlWrapper';
import type { UIResourceRestriction } from '@/editorRestrictions';
import type {
  QuickEditControlEntry,
  QuickEditControlOwnProps,
  QuickEditControlActionOptions,
  QuickEditControlWrapperOwnProps,
} from '@wix/editor-elements-types/quickEditControls';
import type { GFPPActionType } from '@wix/editor-component-types';

const { ACTIONS: GFPP_ACTIONS } = constants.ROOT_COMPS.GFPP;

export const mapStateToProps = (
  { store, editorAPI }: QuickEditEngineScope,
  { compRef }: QuickEditControlOwnProps,
) => {
  return {
    isSelected: compRef.id === store.getCurrentEditedCompId(),
    compChildrenLength:
      editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(compRef)
        .length,
  };
};

export const mapDispatchToProps = (
  scope: QuickEditEngineScope,
  {
    compRef,
    onControlHover,
    onControlClick,
    onComponentChanged,
    openComponentPanelDrillIn,
    onDrillInAction,
    onLinkAction,
    rootControlCompRef,
    isComponentFiltered,
    getOverrideCompControls,
    getPermissionsConfig,
    getMainActionLabel,
    onGfppAction,
    mapCompTypeToDrillInPanel,
  }: QuickEditControlOwnProps &
    QuickEditControlWrapperOwnProps &
    QuickEditControlWrapperInnerOwnProps,
) => {
  const { editorAPI, store } = scope;
  const compType = editorAPI.components.getType(compRef);

  const hasPrimaryConnection =
    !!editorAPI.platform.controllers.connections.getPrimaryConnection(compRef);

  const isConnectedToDataBinding =
    platform.selectors.isConnectedToDataBindingController(
      compRef,
      editorAPI.dsRead,
    );

  const setIsSelected = (
    action: QuickEditControlActions | GFPPActionType = null,
  ) => {
    editorAPI.store.dispatch(highlights.actions.clearHighlights());
    let compRefToSelect = compRef;
    let compRefToEdit = compRef;
    const parentRef =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
    const parentType = editorAPI.components.getType(parentRef);
    if (
      editorAPI.components.is.controlledByParent(compRef) &&
      ![SectionHeaderType, SectionFooterType].includes(compType) &&
      ![SectionHeaderType, SectionFooterType].includes(parentType)
    ) {
      compRefToSelect = parentRef;
    }
    if (shouldSelectAndEditParent(compType)) {
      compRefToEdit = parentRef;
      compRefToSelect = parentRef;
    }
    editorAPI.selection.selectComponentByCompRef(compRefToSelect);
    store.setCurrentEditedCompId(compRefToEdit.id);
    onControlClick?.(compRef, action);
    onControlHover?.(compRef, rootControlCompRef);
  };

  const displayName =
    getCompDisplayNameOverride(editorAPI, compRef, compType) ||
    editorAPI.components.getDisplayName(compRef);

  const componentControlWrapperProps = {
    getOverrideCompControls,
    onControlClick,
    onComponentChanged,
    isComponentFiltered,
    getPermissionsConfig,
    openComponentPanelDrillIn: (title?: string) =>
      openComponentPanelDrillInInternal(scope, title),
    onLinkAction,
  };

  return {
    compType,
    setIsSelected,
    getChildren: editorAPI.components.getChildren,
    displayName,
    loadComponentControl: (): Promise<QuickEditControlEntry> =>
      loadComponentPart(compType, 'quickEditControl'),
    ...getControlGfppActionsData(
      editorAPI,
      compRef,
      compType,
      setIsSelected,
      getMainActionLabel,
    ),
    onActionClick: async (
      action: QuickEditControlActions,
      { shouldPerformActionOnStage }: QuickEditControlActionOptions = {},
    ) => {
      setIsSelected(action);
      store.setComponentPanelContent(null);
      switch (action) {
        case QuickEditControlActions.BACKGROUND:
        case QuickEditControlActions.MANAGE:
          store.setComponentPanelType(action);
          openComponentPanelDrillIn(displayName);
          break;
        case QuickEditControlActions.LINK:
          onLinkAction(compRef);
          break;
        case QuickEditControlActions.DRILL_IN:
          if (onDrillInAction) {
            onDrillInAction(compRef);
          } else {
            openQuickEditDrillInInternal(
              scope,
              compRef,
              componentControlWrapperProps,
            );
          }
          break;
        case QuickEditControlActions.CHANGE_MEDIA:
          onGfppAction(compRef, GFPP_ACTIONS.CHANGE_MEDIA);
          break;
        case QuickEditControlActions.CUSTOM:
          if (mapCompTypeToDrillInPanel[compType]) {
            store.setComponentPanelContent(mapCompTypeToDrillInPanel[compType]);
            store.setComponentPanelType(action);
            openComponentPanelDrillIn(displayName);
          }
          break;
        case QuickEditControlActions.SETTINGS:
          if (
            !shouldPerformActionOnStage &&
            (await hasSettingsPanel(compType))
          ) {
            store.setComponentPanelType(action);
            openComponentPanelDrillIn(displayName);
            break;
          } else {
            onGfppAction(compRef, GFPP_ACTIONS.SETTINGS);
          }
      }
    },
    onHelp: () => {
      const shouldUseParentGfppData =
        compType === constants.COMP_TYPES.COLUMN &&
        isSingleColumnInStripWithoutBackground(editorAPI, compRef);
      const compRefForGfpp = shouldUseParentGfppData
        ? editorAPI.components.getContainer(compRef)
        : compRef;
      const compGfppData = gfppModel.getFullComponentGfppData(
        editorAPI,
        [compRefForGfpp],
        quickEditPanelOrigin,
      );
      editorAPI.panelManager.openHelpCenter(
        compGfppData?.presetActions?.help?.helpId || QUICK_EDIT_PANEL_HELP_ID,
        {},
        { origin: `quick_edit/${displayName}` },
      );
    },
    hasPrimaryConnection,
    isConnectedToDataBinding,
    reportComponentChangedIfNeeded: (
      valueRecordRef: RefObject<ComponentValueRecord>,
    ) => {
      const { initial, last } = valueRecordRef.current;
      if (_.isEqual(initial, last)) return;
      onComponentChanged(compRef);
    },
    isAllowed: (uxResource: UIResourceRestriction) =>
      editorAPI.host.getAPI(EditorRestrictionsApiKey).allowed(uxResource),
  };
};
