import constants from '@/constants';
import experiment from 'experiment';
import { components } from '@/stateManagement';
import { isMeshLayoutEnabled } from '@/layoutOneDockMigration';
import {
  getStageAnchorHandleRectConsideringScroll,
  getStageAnchorHandleRectConsideringScroll_deprecated,
} from '@/anchors';
import type { DSRead, CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

function getAnchorsByXY(editorAPI: EditorAPI, x: number, y: number): CompRef[] {
  const { dsRead } = editorAPI;
  //there are no anchors on popup, so it will return an empty array - which is good :)
  const pageRef = dsRead.pages.getReference(dsRead.pages.getFocusedPageId());
  const anchors = components.selectors.getComponentsByType(
    constants.COMP_TYPES.ANCHOR,
    pageRef,
    dsRead,
  );

  return anchors.filter((anchorRef: CompRef) => {
    const anchorUIRect =
      experiment.isOpen('se_anchorRectFromCss') || isMeshLayoutEnabled()
        ? getStageAnchorHandleRectConsideringScroll(editorAPI, anchorRef)
        : getStageAnchorHandleRectConsideringScroll_deprecated(
            editorAPI,
            anchorRef,
          );

    return (
      x >= anchorUIRect.left &&
      x <= anchorUIRect.right &&
      y >= anchorUIRect.top &&
      y <= anchorUIRect.bottom
    );
  });
}

function filterCurrentPageDifferencesIfNeeded(
  dsRead: DSRead,
  componentsUnderXYFromDom: CompRef[],
): CompRef[] {
  const currentPageId = dsRead.pages.getCurrentPageId();
  if (currentPageId !== dsRead.pages.getFocusedPageId()) {
    return componentsUnderXYFromDom.filter(
      (compRef) =>
        compRef.id !== currentPageId &&
        dsRead.components.getPage(compRef).id !== currentPageId,
    );
  }
  return componentsUnderXYFromDom;
}

export function getComponentsByXY(
  editorAPI: EditorAPI,
  x: number,
  y: number,
): CompRef[] {
  const { dsRead } = editorAPI;

  const componentsUnderXYFromDom = getAnchorsByXY(editorAPI, x, y).concat(
    dsRead.components.get.byXYFromDom(x, y),
  );

  return filterCurrentPageDifferencesIfNeeded(dsRead, componentsUnderXYFromDom);
}
