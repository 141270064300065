const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_19",
  preset: { rect: { width: 130, height: 71, x: 64, y: 529 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        properties: {
          "$st-css":
            ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 7px;background: #FFF86B;border: 0px solid rgb(255, 248, 107)}.root:hover{border-radius: 20px}.root:hover::icon{width: 26px;height: 26px}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #000000;display: initial;font-family: poppins-semibold,poppins,sans-serif}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #282626;display: none}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "4ie-163",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "hmj-140",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_send_small"),
      svgId: "b861b040274141de9c08999ec3b76edf.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-km96zi3x",
    layout: {
      x: 72,
      fixedPosition: false,
      y: 546,
      scale: 1,
      height: 39,
      rotationInDegrees: 0,
      width: 110,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "hmj-93", pageId: "c1dmp" },
    },
    metaData: { sig: "inq-324", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
