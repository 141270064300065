import { wrapWithPropsTransformer } from '../utils';
import {
  type AddPagesSectionProps as AddPagesMapDispatchToPropsProps,
  mapDispatchToProps as addPagesMapDispatchToProps,
} from '../../../sections/addPagesSection/addPagesSection';

export interface AddPagesProps {
  addBlankPage: AddPagesMapDispatchToPropsProps['onItemClick'];
}

export const pagesComponentsMapper = wrapWithPropsTransformer<
  AddPagesMapDispatchToPropsProps,
  AddPagesProps
>(addPagesMapDispatchToProps, (props) => ({
  addBlankPage: props.onItemClick,
}));
