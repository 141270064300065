import _ from 'lodash';
import constants from '@/constants';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import coreUtilsLib from 'coreUtilsLib';
import {
  getComponentMetaData,
  referredComponentMetaData,
} from '@/componentModel';
import experiment from 'experiment';
import { isEditorDataFunction } from '@wix/editor-component-data-resolver';
import { isComponentScoped } from '@/documentServices';

import type { CompStructure, CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { ComponentEditorModel } from '@wix/editor-component-types';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import type {
  ComponentEditorMetaData,
  ComponentEditorMetaDataDefinition,
} from '@/componentModel/componentsMetaData/types';

const { blogAppPartNames } = coreUtilsLib;

const { hasOwnProperty } = Object.prototype;
const componentSelectors = stateManagement.components.selectors;
const { isInInteractionMode, getFirstAncestorWithInteraction } =
  stateManagement.interactions.selectors;
const { getCompType, isChildOfControllingParent, getEditorData } =
  componentSelectors;

const componentsStoreSelectors = stateManagement.componentsStore.selectors;
const { getComponentStoreData } = componentsStoreSelectors;

const componentMetaDataSelectors = stateManagement.componentMetaData.selectors;
const { getComponentOverridesByType } = componentMetaDataSelectors;

const externalComponents: Record<string, ComponentEditorModel> = {};

const externalComponentsOverrides: Record<
  string,
  ComponentEditorMetaDataDefinition
> = {};

for (const key in externalComponents) {
  if (hasOwnProperty.call(externalComponents, key)) {
    const externalComponent: AnyFixMe = externalComponents[key];

    const componentNameToOverride =
      externalComponent?.metadata?.metadataOverride?.overrideViewerType;
    if (componentNameToOverride) {
      const experimentKey =
        externalComponent?.metadata?.metadataOverride?.editorExperimentKey;
      if (experiment.isOpen(experimentKey)) {
        externalComponentsOverrides[componentNameToOverride] =
          externalComponent.metadata;
      }
    }
  }
}

const {
  isPlatformShowFocusBox,
  isSelectableDueToManifest,
  isHiddenFromHierarchyDueToManifest,
} = stateManagement.componentMetaData.selectors;

const DEFAULT_META_DATA: ComponentEditorMetaDataDefinition = {
  pinnable: true,
  stretchable: true,
  fixedNonPinned: false,
  showLegacyFixedPosition: false,
  skipInLayersPanel: false,
  skipInQuickEditPanel: false,
  duplicatable: true,
  crossSiteDuplicatable: true,
  controlledByParent: false,
  containable: true,
  canContain: true,
  containableByStructure: true,
  canContainByStructure: true,
  focusable: false,
  movable: true,
  removable: true,
  verticallyMovable: true,
  horizontallyMovable: true,
  resizable: true,
  verticallyResizable: true,
  horizontallyResizable: true,
  rotatable: true,
  rotatableByType: true,
  flippable: false,
  canSnapTo: true,
  selectable: true,
  multiselectable: true,
  slidesContainer: false,
  slideContainer: false,
  showMarginsIndicator: false,
  selectedBeforeDescendants: false,
  disabledKnobs: [],
  resizeOnlyProportionally: false,
  overrideProportionalResize: null,
  resizableWithAnchors: true,
  resizableDiagonally: true,
  shouldBeOverlayed: false,
  allowLassoOnContainer: false,
  skinParamsToIgnore: [],
  allowedToContainMoreChildren: true,
  isDirectChildrenSelectable: true,
  isDescendantsSelectable: true,
  shouldShowChildrenInLayersPanel: true,
  allowConnectToDB: false,
  gfppMargins: _.constant(0),
  nestedBackgroundDesign: false,
  customizeTranslate: false,
  spotlightStageContainer: false,
  selectWhenDeselectingChildren: false,
  inlinePopup: false,
  arrangeable: true,
  a11yConfigurable: true,
  dataEditOptions: constants.META_DATA.DATA_EDIT_OPTIONS.DEFAULT,
  propertiesEditable: true,
  gfppVisible: true,
  isAllowedAddToSavedComponent: true,
  applyMetaDataToFirstChild: {
    isAllowedAddToSavedComponent: true,
  },
};

function getCompOrDefaultMetaData<
  TMetaDataKey extends keyof ComponentEditorMetaDataDefinition,
>(
  compType: string,
  metaDataKey: TMetaDataKey,
  componentMetaDataOverrides?: ComponentEditorMetaDataDefinition,
  typeMetaDataOverrides?: ComponentEditorMetaDataDefinition,
): ComponentEditorMetaDataDefinition[TMetaDataKey] {
  // `metaDataKey` is always a simple identifier (without dots)
  if (compType && externalComponentsOverrides[compType]) {
    if (
      hasOwnProperty.call(externalComponentsOverrides[compType], metaDataKey)
    ) {
      return externalComponentsOverrides[compType][metaDataKey];
    }
    return DEFAULT_META_DATA[metaDataKey];
  }

  if (
    typeMetaDataOverrides &&
    hasOwnProperty.call(typeMetaDataOverrides, metaDataKey)
  ) {
    return typeMetaDataOverrides[metaDataKey];
  }

  const componentMetaData = getComponentMetaData(compType);

  if (
    componentMetaData &&
    hasOwnProperty.call(componentMetaData, metaDataKey)
  ) {
    return componentMetaData[metaDataKey];
  }

  if (
    componentMetaDataOverrides &&
    hasOwnProperty.call(componentMetaDataOverrides, metaDataKey)
  ) {
    return componentMetaDataOverrides[metaDataKey];
  }

  return DEFAULT_META_DATA[metaDataKey];
}

function getMetaData<
  TKey extends keyof ComponentEditorMetaData,
  TValue extends ComponentEditorMetaData[TKey],
>(
  editorAPI: EditorAPI,
  metaDataKey: TKey,
  comp: CompRef | CompRef[],
  ...extraArgs: any[]
): TValue {
  const compType = getCompType(comp, editorAPI.dsRead);
  const storeState = editorAPI.store.getState();
  const componentMetaDataOverrides = getComponentStoreData(storeState, {
    comp,
    key: constants.COMPONENTS_STORE_KEYS.META_DATA_OVERRIDES,
  });

  const typeMetaDataOverrides = getComponentOverridesByType(
    storeState,
    compType,
  );

  const metaData = getCompOrDefaultMetaData(
    compType,
    metaDataKey,
    componentMetaDataOverrides,
    typeMetaDataOverrides,
  );

  if (_.isFunction(metaData)) {
    if (isEditorDataFunction(metaData)) {
      return getEditorData(
        metaData,
        editorAPI,
        comp,
        ...(extraArgs as []),
      ) as TValue;
    }

    // @ts-expect-error
    return metaData(editorAPI, comp, ...extraArgs);
  }

  return metaData as TValue;
}

const getMetaDataOverrideForReferredComponent = (
  editorAPI: EditorAPI,
  compRef: CompRef | null,
  metaDataKey: string,
) => {
  if (!compRef) {
    return;
  }

  const isReferredComponent = componentSelectors.isReferredComponent(compRef);
  // TODO: ask about difference isReferredComponent vs byRef + scopes. Is it same but old and new?
  const isScoped = isComponentScoped(editorAPI.dsRead, compRef);

  if (
    (isReferredComponent || isScoped) &&
    hasOwnProperty.call(referredComponentMetaData, metaDataKey)
  ) {
    return referredComponentMetaData[
      metaDataKey as keyof typeof referredComponentMetaData
    ];
  }
};

const getMetaDataFromParent = (
  editorAPI: EditorAPI,
  compRef: CompRef | null,
  metaDataKey: string,
) => {
  if (!compRef) {
    return;
  }
  const parent = editorAPI.components.getContainerOrScopeOwner(compRef);
  const parentOverride = getMetaData(
    editorAPI,
    'applyMetaDataToFirstChild',
    parent,
  );
  if (parentOverride && hasOwnProperty.call(parentOverride, metaDataKey)) {
    return parentOverride[metaDataKey as keyof typeof parentOverride];
  }
};

const getComponentStore = (editorAPI: EditorAPI, comp: CompRef) =>
  stateManagement.componentsStore.selectors.getComponentStoreData(
    editorAPI.store.getState(),
    {
      comp,
      key: constants.COMPONENTS_STORE_KEYS.META_DATA_OVERRIDES,
    },
  );

const getComponentMetaDataOverrideFromStore = (
  editorAPI: EditorAPI,
  compRef: CompRef | null,
  metaDataKey: string,
) => {
  const componentsStore = compRef && getComponentStore(editorAPI, compRef);
  if (componentsStore && hasOwnProperty.call(componentsStore, metaDataKey)) {
    return componentsStore[metaDataKey];
  }
};

function isContainableInMobile(
  editorAPI: EditorAPI,
  comp: CompRef,
  potentialParent: CompRef,
) {
  const compType = editorAPI.components.getType(comp);

  if (compType === 'wysiwyg.viewer.components.MenuToggle') {
    return editorAPI.components.isShowOnAllPages(potentialParent);
  }

  return true;
}

const getIsSelectableByAncestorMetaData = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  const areDescendantsSelectable = (compRef: CompRef) =>
    getMetaData(editorAPI, 'isDescendantsSelectable', compRef);
  const isNotSelectableByAncestors = editorAPI.components.someAncestor(
    compRef,
    (ancestorRef: CompRef) => !areDescendantsSelectable(ancestorRef),
    { includeScopeOwner: true },
  );

  return !isNotSelectableByAncestors;
};

const isAllowedAddToSavedComponent = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  return (
    !!getMetaData(editorAPI, 'isAllowedAddToSavedComponent', compRef) &&
    !!getMetaDataFromParent(editorAPI, compRef, 'isAllowedAddToSavedComponent')
  );
};

function create(editorAPI: EditorAPI) {
  const singleComp = {
    getRestrictions(comp: CompRef, restrictions: AnyFixMe) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
      return _.reduce(
        restrictions,
        function (result: Record<string, boolean>, restriction) {
          result[restriction] =
            // @ts-expect-error Expected 2 arguments, but got 1.
            singleComp.is[restriction as keyof typeof singleComp.is](comp);
          return result;
        },
        {},
      );
    },

    is: {
      controlledByParent(comp: CompRef): boolean {
        return (
          getMetaData(editorAPI, 'controlledByParent', comp) ||
          isChildOfControllingParent(comp, editorAPI.dsRead)
        );
      },

      fixed(comp: CompRef): boolean {
        return editorAPI.components.layout.get_fixedPosition(comp);
      },

      exist(comp: CompRef): boolean {
        return editorAPI.dsRead.components.is.exist(comp);
      },

      container(comp: CompRef): boolean {
        return editorAPI.dsRead.components.is.container(comp);
      },

      selectable(comp: CompRef): boolean {
        const isReferredComponent =
          comp && componentSelectors.isReferredComponent(comp);

        if (
          isReferredComponent &&
          isInInteractionMode(editorAPI.store.getState())
        ) {
          return false;
        }
        const parentRef = editorAPI.components.getContainerOrScopeOwner(comp);

        const selectableDueToParentMetaData = getMetaData(
          editorAPI,
          'isDirectChildrenSelectable',
          parentRef,
        );
        const selectableDueToAncestorsMetaData =
          selectableDueToParentMetaData &&
          getIsSelectableByAncestorMetaData(editorAPI, comp);
        const selectableDueToMetaData =
          selectableDueToAncestorsMetaData &&
          getMetaData(editorAPI, 'selectable', comp);

        return selectableDueToMetaData && isReferredComponent
          ? isSelectableDueToManifest(comp, editorAPI)
          : selectableDueToMetaData;
      },

      multiselectable(comp: CompRef): boolean {
        return (
          getMetaData(editorAPI, 'multiselectable', comp) &&
          !componentSelectors.isReferredComponent(comp)
        );
      },

      siteSegment(comp: CompRef): boolean {
        const allSiteSegments = editorAPI.siteSegments.getRefs();
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find
        return Boolean(_.find(allSiteSegments, comp));
      },

      removable(comp: CompRef): boolean {
        if (
          util.sections.isSectionsEnabled() &&
          editorAPI.components.is.container(comp)
        ) {
          const hasNonRemovableChild = Boolean(
            editorAPI.components.getNonRemovableTPAChild(comp),
          );

          // Cannot remove a parent if 1 of its child is not removable
          if (hasNonRemovableChild) {
            return false;
          }
        }

        return (
          getMetaData(editorAPI, 'removable', comp) &&
          editorAPI.dsRead.components.is.removable(comp)
        );
      },

      anchorableFrom(comp: CompRef): boolean {
        return editorAPI.dsRead.components.is.anchorableFrom(comp);
      },

      anchorableTo(comp: CompRef): boolean {
        return editorAPI.dsRead.components.is.anchorableTo(comp);
      },

      duplicatable(comp: CompRef, potentialParent: CompRef): boolean {
        if (editorAPI.isMobileEditor()) {
          const maybeComponentWithChildren = [
            comp,
            ...editorAPI.components.getChildren(comp, true),
          ];
          if (
            maybeComponentWithChildren.some(
              (comp) =>
                !editorAPI.components.is.allowedMobileOnlyByType(
                  editorAPI.components.getType(comp),
                ),
            )
          ) {
            return false;
          }
        }

        if (
          util.sections.isSectionsEnabled() &&
          editorAPI.components.is.container(comp)
        ) {
          const hasNonDuplicatableTPAChild = Boolean(
            editorAPI.components.getNonDuplicatableTPAChild(comp),
          );

          // Cannot duplicate a parent if 1 of its child is not duplicatable
          if (hasNonDuplicatableTPAChild) {
            return false;
          }
        }

        return (
          getMetaData(editorAPI, 'duplicatable', comp, potentialParent) &&
          editorAPI.dsRead.components.is.duplicatable(comp, potentialParent)
        );
      },

      crossSiteDuplicatableByStructure(
        componentStructure: CompStructure,
      ): boolean {
        return editorAPI.dsRead.components.is.crossSiteDuplicatableByStructure(
          componentStructure,
        );
      },

      allowedAddToSavedComponent(
        comp: CompRef,
        potentialParent?: CompRef,
      ): boolean {
        return (
          editorAPI.components.is.duplicatable(comp, potentialParent) &&
          editorAPI.dsRead.components.is.crossSiteDuplicatable(comp) &&
          (util.sections.isSectionsEnabled() ||
            !editorAPI.sections.isSection(comp)) &&
          isAllowedAddToSavedComponent(editorAPI, comp)
        );
      },

      allowedMobileOnlyByType(compType: string): boolean {
        const allowedMobileOnly = getCompOrDefaultMetaData(
          compType,
          'allowedMobileOnly',
        );
        return Boolean(
          _.isFunction(allowedMobileOnly)
            ? allowedMobileOnly(compType)
            : allowedMobileOnly,
        );
      },

      containable(comp: CompRef, potentialParent?: CompRef) {
        return (
          getMetaData(editorAPI, 'containable', comp, potentialParent) &&
          getMetaData(editorAPI, 'canContain', potentialParent, comp) &&
          editorAPI.dsRead.components.is.containable(comp, potentialParent) &&
          isContainableInMobile(editorAPI, comp, potentialParent)
        );
      },

      styleCanBeApplied(comp: CompRef, potentialParent?: CompRef) {
        return (
          editorAPI.dsRead.components.is.styleCanBeApplied(
            comp,
            potentialParent,
          ) &&
          !editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
            comp,
          )
        );
      },

      movable(comp: CompRef) {
        return (
          getMetaData(editorAPI, 'movable', comp) &&
          editorAPI.dsRead.components.is.movable(comp)
        );
      },

      verticallyMovable(comp: CompRef) {
        return (
          getMetaData(editorAPI, 'verticallyMovable', comp) &&
          editorAPI.dsRead.components.is.verticallyMovable(comp) &&
          !editorAPI.components.layout.isPinned(comp)
        );
      },

      horizontallyMovable(comp: CompRef) {
        return (
          getMetaData(editorAPI, 'horizontallyMovable', comp) &&
          editorAPI.dsRead.components.is.horizontallyMovable(comp) &&
          !editorAPI.components.layout.isPinned(comp) &&
          !editorAPI.components.layout.isHorizontallyStretchedToScreen(comp)
        );
      },

      focusable(comp: CompRef): boolean {
        return (
          getMetaData(editorAPI, 'focusable', comp) ||
          isPlatformShowFocusBox(comp, editorAPI.dsRead)
        );
      },

      fullWidth(comp: CompRef) {
        return editorAPI.dsRead.components.is.fullWidth(comp);
      },

      canSnapTo(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'canSnapTo', comp);
      },

      rotatable(comp: CompRef): boolean {
        return (
          getMetaData(editorAPI, 'rotatable', comp) &&
          editorAPI.dsRead.components.is.rotatable(comp)
        );
      },

      rotatableByType(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'rotatableByType', comp);
      },

      flippable(comp: CompRef): boolean {
        return editorAPI.dsRead.components.is.flippable(comp);
      },

      slidesContainer(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'slidesContainer', comp);
      },

      exposesSlideAsInnerComponent(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'exposesSlideAsInnerComponent', comp);
      },

      slideContainer(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'slideContainer', comp);
      },

      hoverBox(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'hoverBox', comp);
      },

      resizable(comp: CompRef): boolean {
        return (
          getMetaData(editorAPI, 'resizable', comp) &&
          editorAPI.dsRead.components.is.resizable(comp)
        );
      },

      horizontallyResizable(comp: CompRef): boolean {
        return (
          (editorAPI.dsRead.components.is.horizontallyResizable(comp) || // order matters for performance
            editorAPI.components.is.group(comp)) &&
          getMetaData(editorAPI, 'horizontallyResizable', comp) &&
          !editorAPI.components.layout.isHorizontallyStretchedToScreen(comp)
        );
      },

      verticallyResizable(comp: CompRef): boolean {
        return (
          getMetaData(editorAPI, 'verticallyResizable', comp) &&
          (editorAPI.dsRead.components.is.verticallyResizable(comp) ||
            editorAPI.components.is.group(comp))
        );
      },

      verticallyResizableByContainer(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'verticallyResizableByContainer', comp);
      },

      verticallyResizableByChildren(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'verticallyResizableByChildren', comp);
      },

      proportionallyResizable(comp: CompRef): boolean {
        return (
          !editorAPI.components.is.fullWidth(comp) &&
          !editorAPI.components.layout.isPinned(comp) &&
          !editorAPI.components.is.controlledByParent(comp) &&
          editorAPI.components.is.horizontallyResizable(comp) &&
          editorAPI.components.is.verticallyResizable(comp)
        );
      },

      overrideProportionalResize(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'overrideProportionalResize', comp);
      },

      repeaterItem(comp: CompRef): boolean {
        if (!comp) {
          return false;
        }
        return componentSelectors.isRepeaterItem(comp, editorAPI.dsRead);
      },

      descendantOfRepeaterItem(comp: CompRef): boolean {
        return (editorAPI.components.is as AnyFixMe).repeatedComponent(comp);
      },

      resizeOnlyProportionally(comp: CompRef): boolean {
        return editorAPI.dsRead.components.is.resizeOnlyProportionally(comp);
      },

      group(comp: CompRef): boolean | null {
        return editorAPI.dsRead.components.is.exist(comp)
          ? editorAPI.dsRead.components.is.group(comp)
          : null;
      },

      groupable(comp: CompRef): boolean {
        return (
          editorAPI.dsRead.components.is.groupable(comp) &&
          !getMetaData(editorAPI, 'controlledByParent', comp)
        );
      },

      topMost(comp: CompRef, compRefArray: CompRef[]) {
        return editorAPI.dsRead.components.is.topMost(comp, compRefArray);
      },

      leftMost(comp: CompRef, compRefArray: CompRef[]) {
        return editorAPI.dsRead.components.is.leftMost(comp, compRefArray);
      },

      groupedComponent(comp: CompRef) {
        return editorAPI.dsRead.components.is.groupedComponent(comp);
      },

      animatable(comp: CompRef, actionName: string): boolean {
        return editorAPI.dsRead.components.is.animatable(comp, actionName);
      },

      allowedToContainMoreChildren(comp: CompRef): boolean {
        return (
          getMetaData(editorAPI, 'allowedToContainMoreChildren', comp) &&
          editorAPI.dsRead.components.is.allowedToContainMoreChildren(comp)
        );
      },

      fixedNonPinned(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'fixedNonPinned', comp);
      },

      pinnable(comp: CompRef): boolean {
        return (
          getMetaData(editorAPI, 'pinnable', comp) &&
          editorAPI.components.layout.canBeFixedPosition(comp) &&
          !editorAPI.components.is.fullWidth(comp) &&
          !editorAPI.components.layout.isHorizontallyStretchedToScreen(comp) &&
          !editorAPI.components.is.controlledByParent(comp) &&
          !editorAPI.components.is.descendantOfRepeaterItem(comp) &&
          !editorAPI.components.isShowOnSomePages(
            editorAPI.components.getContainerOrScopeOwner(comp),
          ) &&
          !editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
            comp,
          )
        );
      },

      stretchable(comp: CompRef): boolean {
        const compData = editorAPI.components.data.get(comp);
        if (
          compData &&
          experiment.isOpen('newBlogMagicMigration') &&
          Object.values(blogAppPartNames).find(
            (name) => name === compData.appPartName,
          )
        ) {
          return false;
        }

        return (
          getMetaData(editorAPI, 'stretchable', comp) &&
          editorAPI.dsRead.components.layout.canBeStretched(comp) &&
          !editorAPI.components.layout.isPinned(comp) &&
          !editorAPI.components.isShowOnSomePages(comp)
        );
      },

      showLegacyFixedPosition(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'showLegacyFixedPosition', comp);
      },

      alignable(comp: CompRef): boolean {
        return (
          editorAPI.dsRead.components.is.alignable(comp) &&
          editorAPI.components.is.movable(comp)
        );
      },

      draggable(comp: CompRef): boolean {
        return (
          editorAPI.components.is.movable(comp) &&
          !editorAPI.components.layout.isPinned(comp) &&
          !editorAPI.components.is.page(comp)
        );
      },

      allowLassoOnContainer(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'allowLassoOnContainer', comp);
      },

      showMarginsIndicator(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'showMarginsIndicator', comp);
      },

      skipInQuickEditPanel(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'skipInQuickEditPanel', comp);
      },

      skipInLayersPanel(comp: CompRef): boolean {
        if (
          comp &&
          componentSelectors.isReferredComponent(comp) &&
          !editorAPI.components.is.selectable(comp)
        ) {
          return isHiddenFromHierarchyDueToManifest(comp, editorAPI);
        }

        return getMetaData(editorAPI, 'skipInLayersPanel', comp);
      },

      allowConnectToDB(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'allowConnectToDB', comp);
      },
      shouldShowChildrenInLayersPanel(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'shouldShowChildrenInLayersPanel', comp);
      },

      selectedBeforeDescendants(comp: CompRef, descendant: CompRef): boolean {
        const firstAncestorWithInteraction = getFirstAncestorWithInteraction(
          editorAPI,
          descendant,
        );
        return (
          (getMetaData(
            editorAPI,
            'selectedBeforeDescendants',
            comp,
            descendant,
          ) ||
            editorAPI.utils.isSameRef(firstAncestorWithInteraction, comp)) &&
          editorAPI.components.is.selectable(comp)
        );
      },

      disabledKnobs(comp: CompRef): ComponentEditorMetaData['disabledKnobs'] {
        return getMetaData(editorAPI, 'disabledKnobs', comp);
      },

      getDataEditOptions(comp: CompRef) {
        const maybeConnectedComponentStageData: AnyFixMe =
          editorAPI.platform.controllers.getConnectedComponentStageData(comp);
        return (
          maybeConnectedComponentStageData?.behavior?.dataEditOptions ??
          DEFAULT_META_DATA.dataEditOptions
        );
      },

      shouldBeOverlayed(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'shouldBeOverlayed', comp);
      },

      getOverlay(
        comp: AnyFixMe,
        phase?: AnyFixMe,
      ): ComponentEditorMetaData['getOverlay'] {
        return getMetaData(editorAPI, 'getOverlay', comp, phase);
      },

      getOverlayProperties(
        comp: CompRef,
      ): ComponentEditorMetaData['getOverlayProperties'] {
        return getMetaData(editorAPI, 'getOverlayProperties', comp);
      },
      gridLinesVisible(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'gridLinesVisible', comp);
      },
      resizableWithAnchors(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'resizableWithAnchors', comp);
      },
      resizableDiagonally(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'resizableDiagonally', comp);
      },
      skinParamsToIgnore(comp: CompRef): string[] {
        return getMetaData(editorAPI, 'skinParamsToIgnore', comp);
      },
      gfppMargins(comp: CompRef[]): ComponentEditorMetaData['gfppMargins'] {
        const compProps = editorAPI.components.properties.get(comp);
        const compData = editorAPI.components.data.get(comp);
        const previewState =
          editorAPI.documentMode.getComponentPreviewState()[comp[0].id];
        return getMetaData(editorAPI, 'gfppMargins', comp, {
          compData,
          compProps,
          previewState,
        });
      },
      nestedBackgroundDesign(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'nestedBackgroundDesign', comp);
      },
      customizeTranslate(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'customizeTranslate', comp);
      },
      canReparent(comp: CompRef): boolean {
        return editorAPI.dsRead.components.is.canReparent(comp);
      },
      spotlightStageContainer(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'spotlightStageContainer', comp);
      },
      selectWhenDeselectingChildren(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'selectWhenDeselectingChildren', comp);
      },
      inlinePopup(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'inlinePopup', comp);
      },
      arrangeable(comp: CompRef): boolean {
        return getMetaData(editorAPI, 'arrangeable', comp);
      },
      a11yConfigurable(comp: CompRef[]): boolean {
        return (
          getMetaData(editorAPI, 'a11yConfigurable', comp) &&
          editorAPI.dsRead.components.is.a11yConfigurable(comp[0])
        );
      },
      overridesWhenReferred(
        comp: CompRef,
      ): ComponentEditorMetaData['overridesWhenReferred'] {
        return getMetaData(editorAPI, 'overridesWhenReferred', comp);
      },
      editDirectChildProperties(comp: AnyFixMe, firstChild: AnyFixMe): boolean {
        const propertiesEditable = getMetaData(
          editorAPI,
          'propertiesEditable',
          comp,
        );
        const isDirectChildrenSelectable: boolean = getMetaData(
          editorAPI,
          'isDirectChildrenSelectable',
          firstChild,
        );
        return !propertiesEditable && isDirectChildrenSelectable;
      },
      delegateControlsToFirstChild(compRef: CompRef, source?: string): boolean {
        if (
          !compRef ||
          !editorAPI.components.hasChildrenOrScopedChildren(compRef)
        ) {
          return false;
        }
        const delegateControlsToFirstChild = getMetaData(
          editorAPI,
          'delegateControlsToFirstChild',
          compRef,
        );
        if (!delegateControlsToFirstChild) {
          return false;
        }
        const { applyToAll, override } = delegateControlsToFirstChild;
        return (
          applyToAll || override?.includes(source as ValueOf<typeof override>)
        );
      },
      gfppVisible(comps: CompRef[]): boolean {
        return getMetaData(editorAPI, 'gfppVisible', comps);
      },
    },
  };

  singleComp.is = _.mapValues(
    singleComp.is,
    (func, metaDataKey: string) =>
      (...args: AnyFixMe[]) => {
        const compRef: CompRef | null = _.isPlainObject(args[0])
          ? args[0]
          : null;
        const overrideFromStore = getComponentMetaDataOverrideFromStore(
          editorAPI,
          compRef,
          metaDataKey,
        );
        if (overrideFromStore !== undefined) {
          return overrideFromStore;
        }

        const overrideForReferredComponent =
          getMetaDataOverrideForReferredComponent(
            editorAPI,
            compRef,
            metaDataKey,
          );
        if (overrideForReferredComponent !== undefined) {
          return overrideForReferredComponent;
        }

        const metaDataFromParent = getMetaDataFromParent(
          editorAPI,
          compRef,
          metaDataKey,
        );
        if (metaDataFromParent !== undefined) {
          return metaDataFromParent;
        }

        return (func as AnyFixMe)(...args);
      },
  );

  const isByCompStructure_whichDoesntReceivePointerAndShouldntAssumeItDoesSoDontWrapIt =
    {
      crossSiteDuplicatableByStructure(componentStructure: AnyFixMe) {
        return editorAPI.dsRead.components.is.crossSiteDuplicatableByStructure(
          componentStructure,
        );
      },
      containableByStructure(
        componentStructure: CompStructure,
        potentialParent: CompRef,
      ) {
        const compType = componentStructure.componentType;
        const containableByStructureMetaData = getCompOrDefaultMetaData(
          compType,
          'containableByStructure',
        );
        if (
          editorAPI.isMobileEditor() &&
          !editorAPI.components.is.allowedMobileOnlyByType(compType)
        ) {
          return false;
        }
        const isContainableByStructure = _.isFunction(
          containableByStructureMetaData,
        )
          ? containableByStructureMetaData(componentStructure, potentialParent)
          : containableByStructureMetaData;
        return (
          isContainableByStructure &&
          getMetaData(
            editorAPI,
            'canContainByStructure',
            potentialParent,
            componentStructure,
          ) &&
          editorAPI.dsRead.components.is.containableByStructure(
            componentStructure,
            potentialParent,
          )
        );
      },
    };

  const result = {
    ...singleComp,
    is: {
      ...singleComp.is,
      ...isByCompStructure_whichDoesntReceivePointerAndShouldntAssumeItDoesSoDontWrapIt,
    },
  };

  return result;
}

export { create };
