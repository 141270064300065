// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['design_section_label_grid'],
      sections: [addPanel.sections.getSections().developer.gridSection],
    };
  },
};
