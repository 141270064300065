import _ from 'lodash';
import { selection } from '@/stateManagement';
import { createSelector } from '../../selectors/selector';
import {
  dsReadSel,
  editorAPISel,
  editorStateSel,
} from '../selectors/rootSelectors';
import { selectedCompsSel as _selectedCompsSel } from '../selectors/selectedCompsSelectors';
import * as util from '@/util';
import { headerScrollTypes } from '@/util';
import { relativeToStructureOrigLayoutSel } from '../selectors/layoutSelectors';

const { isMultiselect } = util.array;

export const selectedCompsSel = createSelector(
  [_selectedCompsSel],
  (selectedComponents) => {
    if (_.isEmpty(selectedComponents)) {
      return null;
    }
    return selectedComponents;
  },
);

export const selectedComponentsProportionsSel = createSelector(
  [editorAPISel, selectedCompsSel],
  (editorAPI, selectedComps) => {
    const selectedCompsWithoutAncestorInArray =
      editorAPI.components.getComponentsWhichDontHaveAncestorsInTheArray(
        selectedComps,
      );
    if (
      isMultiselect(selectedCompsWithoutAncestorInArray) ||
      editorAPI.components.is.group(selectedCompsWithoutAncestorInArray)
    ) {
      return editorAPI.components.layout.getProportionStructure(
        selectedCompsWithoutAncestorInArray,
      );
    }

    return null;
  },
);

export const componentLayoutLimitsSel = createSelector(
  [dsReadSel, selectedCompsSel],
  (dsRead, selectedComponents) =>
    !selectedComponents
      ? {}
      : dsRead.components.layout.getLimits(_.head(selectedComponents)),
);

export const areComponentsMultiSelectedOrGroupedSel = createSelector(
  [editorAPISel, selectedCompsSel],
  (editorAPI, selectedComponents) => {
    if (!selectedComponents) {
      return false;
    }
    const selectedComps =
      editorAPI.components.getComponentsWhichDontHaveAncestorsInTheArray(
        selectedComponents,
      );
    return (
      isMultiselect(selectedComps) ||
      editorAPI.components.is.group(selectedComps)
    );
  },
);

export const compRestrictionsSel = createSelector(
  [editorStateSel, selectedCompsSel],
  (editorState, selectedComponents) => {
    if (!selectedComponents) {
      return {};
    }
    return selection.selectors.getSelectedCompRestrictions(editorState);
  },
);

export const componentsWithoutAncestorSel = createSelector(
  [editorAPISel, selectedCompsSel],
  (editorAPI, selectedComponents) =>
    editorAPI.components.getComponentsWhichDontHaveAncestorsInTheArray(
      selectedComponents,
    ),
);

export const selectedCompTypeSel = createSelector([editorAPISel], (editorAPI) =>
  editorAPI.selection.getSelectedComponentType(),
);

export const viewModeSel = createSelector([editorAPISel], (editorAPI) =>
  editorAPI.viewMode.get(),
);

export const isHeaderFrozenSel = createSelector(
  [
    editorAPISel,
    relativeToStructureOrigLayoutSel,
    selectedCompsSel,
    selectedCompTypeSel,
    viewModeSel,
  ],
  (editorAPI, componentLayout, selectedComponents, componentType, viewMode) => {
    return (
      componentType === 'HeaderContainer' &&
      headerScrollTypes.getSelectedHeaderType(
        componentLayout.fixedPosition,
        editorAPI.components.behaviors.get(selectedComponents[0]),
        viewMode,
      ).value.scrollType === 'freezes'
    );
  },
);

export const isFooterFrozenSel = createSelector(
  [selectedCompTypeSel, relativeToStructureOrigLayoutSel],
  (componentType, componentLayout) => {
    return componentType === 'FooterContainer' && componentLayout.fixedPosition;
  },
);

export const isCompControlledByParentSel = createSelector(
  [compRestrictionsSel],
  (compRestrictions) => compRestrictions.isControlledByParent,
);

export const shouldShowToolbar = createSelector(
  [editorAPISel],
  (editorAPI) => !editorAPI.zoomMode.isStageZoomMode(),
);
