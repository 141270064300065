import { translate } from '@/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      layout: {
        width: 224,
        height: 337,
        x: 51,
        y: 45,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'c8asg',
      },
      parent: 'comp-kgujq4cx',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'c8asg',
        },
        title: '',
        uri: '74f558_1e7c658fea794052807309fd52e820f3~mv2.jpg',
        description: '',
        width: 2916,
        height: 3888,
        alt: 'alex-bunday-_MdUz-1Ofsg-unsplash.jpg',
        name: 'alex-bunday-_MdUz-1Ofsg-unsplash.jpg',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'c8asg',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 100,
            type: 'percentage',
          },
          y: {
            value: 100,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
      },
      scopedTransformations: {
        'variants-kgujq4d7': {
          type: 'TransformData',
          rotate: 10,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c8asg',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WPhoto',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      },
      mobileStructure: {
        layout: {
          width: 154,
          height: 231,
          x: 10,
          y: 26,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgujq4d9',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      layout: {
        width: 224,
        height: 337,
        x: 51,
        y: 45,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'c8asg',
      },
      parent: 'comp-kgujq4cx',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'c8asg',
        },
        title: '',
        uri: '74f558_ed4a37b820074c948d9822b7a2078396~mv2.jpg',
        description: '',
        width: 5472,
        height: 3648,
        alt: 'edceee-G35D9jK1Bf0-unsplash.jpg',
        name: 'edceee-G35D9jK1Bf0-unsplash.jpg',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'c8asg',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 100,
            type: 'percentage',
          },
          y: {
            value: 100,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
      },
      scopedTransformations: {
        'variants-kgujq4d7': {
          type: 'TransformData',
          rotate: -6,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c8asg',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WPhoto',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      },
      mobileStructure: {
        layout: {
          width: 154,
          height: 231,
          x: 10,
          y: 26,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgujq4dc3',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      layout: {
        width: 224,
        height: 337,
        x: 51,
        y: 45,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'c8asg',
      },
      parent: 'comp-kgujq4cx',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'c8asg',
        },
        title: '',
        uri: '74f558_3e5d3f9f944447ba9e74adb7acf527d9~mv2.jpg',
        description: '',
        width: 3648,
        height: 5472,
        alt: 'lionel-gustave-c1rOy44wuts-unsplash.jpg',
        name: 'lionel-gustave-c1rOy44wuts-unsplash.jpg',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'c8asg',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 100,
            type: 'percentage',
          },
          y: {
            value: 100,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
      },
      scopedTransformations: {
        'variants-kgujq4d7': {
          type: 'TransformData',
          rotate: -20,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c8asg',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WPhoto',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      },
      mobileStructure: {
        layout: {
          width: 154,
          height: 231,
          x: 10,
          y: 26,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgujq4de2',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 452,
        height: 202,
        x: 189,
        y: 124,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'c8asg',
      },
      parent: 'comp-kgujq4cx',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
        text: `<h3 class="font_3" style="font-size: 83px; line-height: 1em;"><span style="font-size:83px;"><span style="font-family:proxima-n-w01-reg"><span style="font-weight:normal"><span style="font-style:normal"><span style="color:#000000"><span style="font-family:arial black,arial-w01-black,arial-w02-black,arial-w10 black,sans-serif"><span style="letter-spacing:0em"><span style="font-weight:bold">${translate(
          'interactions_preset_banner_text_1',
        )}</span></span></span></span></span></span></span></span></h3>\n\n<h3 class="font_3" style="font-size: 83px; line-height: 1em;"><span style="font-size:83px;"><span style="font-family:proxima-n-w01-reg"><span style="font-weight:normal"><span style="font-style:normal"><span style="color:#000000"><span style="font-family:arial black,arial-w01-black,arial-w02-black,arial-w10 black,sans-serif"><span style="letter-spacing:0em"><span style="font-weight:bold">${translate(
          'interactions_preset_banner_text_2',
        )}</span></span></span></span></span></span></span></span></h3>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'c8asg',
        },
        brightness: 1,
        packed: false,
        minHeight: 202,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
      },
      scopedTransformations: {
        'variants-kgujq4d7': {
          type: 'TransformData',
          rotate: 0,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c8asg',
          },
          translate: {
            x: {
              type: 'px',
              value: 57,
            },
            y: {
              type: 'px',
              value: 0,
            },
          },
        },
      },
      scopedStyles: {
        'variants-kgujq4d7': {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c8asg',
          },
          style: {
            properties: {
              color: '#000000',
              f10: 'font_10',
              f0: 'font_0',
              f1: 'font_1',
              f2: 'font_2',
              f3: 'font_3',
              f4: 'font_4',
              f5: 'font_5',
              f6: 'font_6',
              f7: 'font_7',
              f8: 'font_8',
              f9: 'font_9',
            },
            propertiesSource: {
              color: 'value',
              f10: 'theme',
              f0: 'theme',
              f1: 'theme',
              f2: 'theme',
              f3: 'theme',
              f4: 'theme',
              f5: 'theme',
              f6: 'theme',
              f7: 'theme',
              f8: 'theme',
              f9: 'theme',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.WRichText',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
        style: {
          properties: {
            f10: 'normal normal normal 12px/1.4em din-next-w01-light #999997',
            f0: 'normal normal normal 40px/1.4em proxima-n-w01-reg #423737',
            f1: 'normal normal normal 16px/1.4em din-next-w01-light #999997',
            f2: 'normal normal normal 28px/1.4em proxima-n-w01-reg #423737',
            f3: 'normal normal normal 60px/1.4em proxima-n-w01-reg #423737',
            f4: 'normal normal normal 40px/1.4em proxima-n-w01-reg #423737',
            f5: 'normal normal normal 25px/1.4em proxima-n-w01-reg #423737',
            f6: 'normal normal normal 22px/1.4em proxima-n-w01-reg #423737',
            f7: 'normal normal normal 17px/1.4em proxima-n-w01-reg #423737',
            f8: 'normal normal normal 15px/1.4em proxima-n-w01-reg #423737',
            f9: 'normal normal normal 14px/1.4em proxima-n-w01-reg #423737',
          },
          propertiesSource: {
            f10: 'value',
            f0: 'value',
            f1: 'value',
            f2: 'value',
            f3: 'value',
            f4: 'value',
            f5: 'value',
            f6: 'value',
            f7: 'value',
            f8: 'value',
            f9: 'value',
          },
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WRichText',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      },
      mobileStructure: {
        layout: {
          width: 216,
          height: 86,
          x: 67,
          y: 99,
          scale: 0.9036144578313253,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'c8asg',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgujq4dg',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wixui.skins.Skinless',
      layout: {
        width: 142,
        height: 40,
        x: 257,
        y: 319,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wixui.StylableButton',
      metaData: {
        pageId: 'c8asg',
      },
      parent: 'comp-kgujq4cx',
      data: {
        type: 'StylableButton',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
        label: translate('interactions_preset_banner_cta'),
        svgId: '820eb164c7ca4456bd04e94ef847042e.svg',
      },
      props: {
        type: 'StylableButtonProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'c8asg',
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
      },
      scopedTransformations: {
        'variants-kgujq4d7': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c8asg',
          },
          rotate: 0,
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'c8asg',
        },
        style: {
          properties: {
            '$st-css':
              ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #000000}.root:hover{background: #FFFFFF;border: 1px solid #000000}.root:hover::label{color: #000000}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;color: #FFFFFF;font-family: futura-lt-w01-book,sans-serif;font-size: 12px;letter-spacing: 0em;margin: 0px 4px 0px 0px}.root::icon{transition: inherit;fill: #FFFFFF;width: 10px;height: 10px;margin: 0px 0px 0px 4px;display: none}.root:hover::icon{fill: #000000}",
          },
          propertiesSource: {
            '$st-css': 'value',
          },
        },
        componentClassName: 'wixui.StylableButton',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wixui.skins.Skinless',
      },
      mobileStructure: {
        layout: {
          width: 160,
          height: 42,
          x: 60,
          y: 0,
          scale: 0.9333333333333333,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgujq4dj4',
          author: 'studio',
        },
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 678,
    height: 432,
    x: 151,
    y: 34,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'c8asg',
  },
  parent: 'c8asg',
  variants: {
    'variants-kgujq4d7': {
      componentId: 'comp-kgujq4cx',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'c8asg',
      },
    },
  },
  scopedTransformations: {
    'variants-kgujq4d7': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'c8asg',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'c8asg',
    },
    style: {
      properties: {
        'alpha-bg': '1',
        'alpha-brd': '1',
        bg: '#D2EEEF',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  mobileStructure: {
    layout: {
      width: 280,
      height: 284,
      x: 20,
      y: 20,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kgujq4cx',
      author: 'studio',
    },
  },
  activeModes: {},
};
