const DAILY_AGENDA_WIDGET_IMAGE =
  '/addPanelData/sections/bookingsDailyAgendaSection_en/bookingsDailyAgendaSection_editorX_en.v2.png';
const DAILY_AGENDA_WIDGET_WIX_STUDIO_IMAGE =
  '/addPanelData/sections/bookingsDailyAgendaSection_en/bookingsDailyAgendaSection_wix_studio_en.png';
const DAILY_AGENDA_PRESET_KEY = 'Wix_Bookings_DAILY_AGENDA_Widget';
const DAILY_AGENDA_WIDGET_ID = 'e86ab26e-a14f-46d1-9d74-7243b686923b';
const DAILY_AGENDA_TITLE_KEY = 'add_section_label_timetable_input';
const DAILY_AGENDA_TOOLTIP_TITLE_KEY =
  'add_section_label_timetable_input_tooltip_title';
const DAILY_AGENDA_TOOLTIP_DESCRIPTION_KEY =
  'add_section_label_timetable_input_tooltip_description';

export default {
  DAILY_AGENDA_WIDGET_IMAGE,
  DAILY_AGENDA_PRESET_KEY,
  DAILY_AGENDA_WIDGET_ID,
  DAILY_AGENDA_TITLE_KEY,
  DAILY_AGENDA_TOOLTIP_TITLE_KEY,
  DAILY_AGENDA_TOOLTIP_DESCRIPTION_KEY,
  DAILY_AGENDA_WIDGET_WIX_STUDIO_IMAGE,
};
