import { ComponentsApiKey, EditorAPIKey } from '@/apis';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import type { CompRef } from '@wix/document-services-types';
import type { Shell } from '@/apilib';
import * as coreBi from '@/coreBi';
import type { EditorAPI } from '@/editorAPI';

const { setSiteUserPreferences } = stateManagement.userPreferences.actions;

const { DONT_SHOW_AGAIN } =
  constants.USER_PREFS.MULTILINGUAL.WIDGET_DELETED_WARNING;

export function shouldShowModal(shell: Shell, compRefs: CompRef[]) {
  const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;
  const componentsApi = shell.getAPI(ComponentsApiKey);
  const editorAPI = shell.getAPI(EditorAPIKey);

  const isMenuSelected = compRefs.some(
    (compRef) =>
      componentsApi.getType(compRef) ===
      'wysiwyg.viewer.components.LanguageSelector',
  );

  return (
    isMenuSelected &&
    !getSiteUserPreferences(DONT_SHOW_AGAIN)(editorAPI.store.getState())
  );
}

function languagePickerDeletePlugin(
  editorAPI: EditorAPI,
  originalRemoveAction: AnyFixMe,
) {
  const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;
  const removeActionAndReport = () => {
    editorAPI.bi.event(coreBi.events.multilingual.language_menu_deleted, {
      page_id: editorAPI.pages.getCurrentPageId(),
      page_name: editorAPI.pages.getCurrentUrlPageTitle(),
      primary_lang: editorAPI.language.original.get().code,
      current_secondary_lng: editorAPI.language.isCurrentLanguageSecondary()
        ? editorAPI.language.current.get()
        : null,
    });

    return originalRemoveAction();
  };
  // We want to show a popup (but only once per user per site)
  if (!getSiteUserPreferences(DONT_SHOW_AGAIN)(editorAPI.store.getState())) {
    editorAPI.store.dispatch(setSiteUserPreferences(DONT_SHOW_AGAIN, true));
    editorAPI.store.dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        'panels.focusPanels.languagePickerDeletePanel',
        { deleteCB: removeActionAndReport },
        /* closeExisting */ false,
      ),
    );
  } else {
    return removeActionAndReport();
  }
}

export { languagePickerDeletePlugin as plugin };
