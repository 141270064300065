:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._spinnerWrapper_twrwc_7 {
  width: 400px;
  display: flex;
  justify-content: center;
  padding-top: 180px; }

._headerSearchButtonWrapper_twrwc_13 {
  margin-right: 7px;
  line-height: 0; }

._searchIcon_twrwc_17 {
  margin-right: -4px; }

._headerSearchButton_twrwc_13 {
  width: auto !important;
  display: flex;
  align-items: center; }
  ._headerSearchButton_twrwc_13 .control-text {
    color: #000624 !important; }
  ._headerSearchButton_twrwc_13:hover {
    background: none !important; }
    ._headerSearchButton_twrwc_13:hover path {
      fill: #116dff !important; }
    ._headerSearchButton_twrwc_13:hover .control-text {
      color: #116dff !important;
      text-decoration: none; }

[data-madefor='true'] .info-icon-title {
  font-weight: var(--wbu-font-weight-medium);
  margin-bottom: 6px;
  font-size: 16px; }
