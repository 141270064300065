import type { EditorAPI } from '@/editorAPI';
import * as addPanelInfra from '@/addPanelInfra';
import * as componentModel from '@/componentModel';
import * as stateManagement from '@/stateManagement';
import {
  bi as biUtil,
  fedopsLogger,
  languages,
  serviceTopology,
  url as urlUtil,
} from '@/util';
import {
  type Path,
  AddPanelAPI,
  type PresetSection,
  StaticsFetcher,
  AddPanelAPIEventName,
} from '@wix/add-panel-component';
import type { Category } from '@wix/add-panel-common';
import _ from 'lodash';
import { ErrorReporter } from '@wix/editor-error-reporter';
import type { BiErrorDefinition } from 'types/bi';
import { createNewAddPanelConditionHelpers } from './conditionHelpers';
import experiments from 'experiment';

const MAX_RETRIES = 3;

const fetcher = new StaticsFetcher({
  // caching response
  entitiesStaticsUrl:
    serviceTopology.scriptsLocationMap['add-panel-data-classic-editor'],
  locale: languages.getLanguageCode(),
});

interface InitParams {
  editorAPI: EditorAPI;
  isSectionsEnabled: boolean;
  reload?: boolean;
}

let _api: AddPanelAPI;
let _registrySections: Map<string, PresetSection>;
let _cache:
  | undefined
  | {
      titles: Record<string, string>;
      allCompTypes: Set<string>;
    };
let _biLogger: {
  logError: (error: BiErrorDefinition) => void;
};

const getAPISafely = () => {
  preventUsingUninitializedAPI();

  return _api;
};

const isDebug = () => urlUtil.getParameterByName('debug');

export const loadEntities = async (): Promise<void> => {
  const loadEntitiesInner = async (retryTimes: number = 0): Promise<void> => {
    try {
      await _api.loadEntities();
    } catch (error) {
      if (isDebug()) {
        // eslint-disable-next-line
        console.error(
          'Something went wrong during Add Panel data loading:',
          error,
        );
      }

      if (retryTimes < MAX_RETRIES) {
        if (isDebug()) {
          // eslint-disable-next-line
          console.log('Retry to load entities...');
        }

        return loadEntitiesInner(retryTimes + 1);
      }

      ErrorReporter.captureException(error, {
        tags: { service: 'new-add-panel-api' },
      });
      _biLogger?.logError(biUtil.errors.NEW_ADD_PANEL.DATA_LOADING);
    }
  };

  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.ADD_PANEL.DATA_LOAD,
  );

  await loadEntitiesInner();

  fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.ADD_PANEL.DATA_LOAD);
};

const preventUsingUninitializedAPI = () => {
  if (!_api) {
    throw new Error('You should initialize AddPanelAPI before use!');
  }
};

type RegistryAddPanelEntry =
  | PresetSection
  | ((options: { editorAPI: EditorAPI }) => PresetSection);

export const initAndStartLoadData = async ({
  editorAPI,
  isSectionsEnabled,
  reload = false,
}: InitParams): Promise<AddPanelAPI> => {
  if (_api && !reload) {
    return _api;
  }

  const conditionHelpers = createNewAddPanelConditionHelpers({
    editorAPI,
  });

  _api = new AddPanelAPI({
    fetcher,
    conditionHelpers,
    hasSectionStyle: (section) =>
      addPanelInfra.liveComponentSectionUtils.hasStylesForSection(
        editorAPI,
        section,
      ),
    experiments: {
      ...experiments.getRunningExperiments(),
      se_sections: isSectionsEnabled ? 'new' : 'old',
      'specs.UseTbInPreview': String(window.editorModel.willUseTbInPreview),
    },
  });

  _biLogger = {
    logError: (errorDefinition: BiErrorDefinition) =>
      editorAPI.store.dispatch(
        stateManagement.bi.actions.error(errorDefinition, {}),
      ),
  };

  await loadEntities();

  return _api;
};

export const initRegistryAddPanelData = async ({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}): Promise<void> => {
  // @ts-expect-error
  const addPanelEntries = (await componentModel.loadComponentsPart(
    'addPanel',
  )) as Map<string, RegistryAddPanelEntry>;
  _registrySections = new Map(
    Array.from(addPanelEntries.entries()).map(
      ([componentName, componentAddPanelEntry]) => {
        if (typeof componentAddPanelEntry === 'function') {
          /*
           * Pass editorAPI so developer can use `editorAPI.components.buildDefaultStructure`
           * to generate default structure for component development
           */
          return [componentName, componentAddPanelEntry({ editorAPI })];
        }
        return [componentName, componentAddPanelEntry];
      },
    ),
  );
};

export const initUniversalAddPanelData = async () => {};

export const getRegistrySections = () => _registrySections;

export const getVersion = () => getAPISafely().version;

export const getDataSync = () => getAPISafely().getDataSync();

export const getData = () => getAPISafely().getData();

export const getConditionHelpers = () => getAPISafely().getConditionHelpers();

export const isDataLoaded = () => getAPISafely().isLoaded;

export const getCategoryAndSectionSync = (
  categoryId: string,
  sectionName?: string,
  enhancedCategories?: Category[],
) =>
  categoryId
    ? getAPISafely().getCategoryAndSectionSync(
        categoryId,
        sectionName,
        enhancedCategories,
      )
    : undefined;

export const getCategoryAndSection = (
  categoryId: string,
  sectionName?: string,
) => getAPISafely().getCategoryAndSection(categoryId, sectionName);

function getPanelDesignData() {
  const { isLoading, panelDesigns } = getAPISafely().getPanelDesignDataSync();
  if (isLoading) {
    return {};
  }
  if (!_cache) {
    _cache = {
      titles: _.mapValues(panelDesigns, (v) => v.translations.title),
      allCompTypes: new Set(Object.keys(panelDesigns)),
    };
  }

  return panelDesigns;
}

export const getSectionsByComponentType = (compType: string) =>
  getPanelDesignData()[compType]?.sections; //should be called each time because filtering condtions can change (e.g. my & theme sections hasStylesForSection)

export const getDesignPanelTitleByComp = (compType: string) => {
  if (!_cache) {
    getPanelDesignData();
  }
  if (!_cache) {
    return '';
  }
  return _cache.titles[compType]; //not changed
};

export const hasAddPanelDesign = (compType: string) => {
  if (!_cache) {
    getPanelDesignData();
  }
  if (!_cache) {
    return false;
  }
  return _cache.allCompTypes.has(compType); //not changed
};

const generateDeepLinkFromEntities = (
  deeplinkEntities?: ReturnType<typeof getCategoryAndSectionSync>,
) => {
  if (!deeplinkEntities) {
    return null;
  }

  const { category, section } = deeplinkEntities;

  const deeplinkPath: Path = [category.systemId];

  if (section) {
    deeplinkPath.push(section.systemId);
  }

  return deeplinkPath;
};

export const getDeeplinkByCategoryIdAndSectionNameSync = (
  categoryId: string,
  sectionName?: string,
  enhancedCategories?: Category[],
): Path | null => {
  // note: we need to check it because 'strictNullChecks: false' in tsconfig
  if (!categoryId) {
    return null;
  }

  const deeplinkEntities = getCategoryAndSectionSync(
    categoryId,
    sectionName,
    enhancedCategories,
  );

  return generateDeepLinkFromEntities(deeplinkEntities);
};

export const getDeeplinkByCategoryIdAndSectionName = async (
  categoryId: string,
  sectionName: string,
): Promise<Path | null> => {
  // note: we need to check it because 'strictNullChecks: false' in tsconfig
  if (!categoryId) {
    return null;
  }
  const deeplinkEntities = await getCategoryAndSection(categoryId, sectionName);

  return generateDeepLinkFromEntities(deeplinkEntities);
};

export const onceDataLoad = (listener: () => void) => {
  getAPISafely().once(AddPanelAPIEventName.DataLoad, listener);
};

export const offDataLoad = () => {
  getAPISafely().removeAllListeners(AddPanelAPIEventName.DataLoad);
};

export const canOpenAddPanel = (categoryId: string, sectionName: string) =>
  Boolean(getCategoryAndSectionSync(categoryId, sectionName));
