import { SiteWasSavedPanelCloseSource } from '../panels/save/siteWasSavedPanel';
import type { PublishInteractionStartedSource } from 'types/fedops/publishInteraction';

// NOTE: https://github.com/wix-private/santa-editor/issues/37029
export function getPublishSourceOfStartBySavedPanelCloseSource(
  siteWasSavedPanelCloseSource: SiteWasSavedPanelCloseSource,
): PublishInteractionStartedSource {
  if (!siteWasSavedPanelCloseSource) {
    return 'saveSuccessPanel_unknown';
  }

  const map: Record<
    SiteWasSavedPanelCloseSource,
    PublishInteractionStartedSource
  > = {
    [SiteWasSavedPanelCloseSource.HeaderCloseButton]:
      'saveSuccessPanel_header_closeButton',
    [SiteWasSavedPanelCloseSource.InnerPanelPublishPublishLink]:
      'saveSuccessPanel_innerPanel_publish_publishLink',
    [SiteWasSavedPanelCloseSource.InnerPanelConnectDomainActionLink]:
      'saveSuccessPanel_innerPanel_connectDomain_actionLink',
    [SiteWasSavedPanelCloseSource.FooterPublishButton]:
      'saveSuccessPanel_footer_publishButton',
    [SiteWasSavedPanelCloseSource.FooterContinueButton]:
      'saveSuccessPanel_footer_continueButton',
    [SiteWasSavedPanelCloseSource.KeyboardEscapeKey]:
      'saveSuccessPanel_keyboard_escapeKey',
    [SiteWasSavedPanelCloseSource.OuterOverlay]:
      'saveSuccessPanel_clickOutside',
  };

  return map[siteWasSavedPanelCloseSource] ?? 'saveSuccessPanel_unknown';
}
