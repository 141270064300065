import { WIX_BOOKINGS } from '@wix/app-definition-ids';
import type {
  AppInstallOption,
  AppInstallOrigin,
} from '@wix/editor-platform-host-integration-apis';
import {
  InstallationOriginType,
  InstallInitiator,
  EditorType,
} from '@wix/platform-editor-sdk';

export const silentConstants = {
  timeoutBetweenFakeSteps: 2000,
  BEFORE_SILENT_INSTALL_HISTORY_LABEL: 'BEFORE_SILENT_INSTALL',
  BEFORE_INSTALL_APP_HISTORY_LABEL: 'BEFORE_SILENT_INSTALL_APP_',
  AFTER_INSTALL_APP_HISTORY_LABEL: 'AFTER_SILENT_INSTALL_APP_',
  errNames: {
    ADD_APP_ERR: 'ADD_APP_ERR',
    TEMPLATE_ERR: 'TEMPLATE_ERR',
  },
  multipleAppsTranslation: {
    header: 'PLATFORM_Progress_Bar_Silent_Install_Multi_Apps_FirstStep',
    headerAfterFetch: 'PLATFORM_Progress_Bar_Silent_Install_Multi_Apps_Header',
    steps: [
      'PLATFORM_Progress_Bar_Silent_Install_Multi_Apps_Text1',
      'PLATFORM_Progress_Bar_Silent_Install_Multi_Apps_Text2',
    ],
    lastStep: 'PLATFORM_Progress_Bar_Silent_Install_Multi_Apps_Text3',
  },
  singleAppTranslation: {
    header: 'PLATFORM_Progress_Bar_Silent_Install_Single_Apps_FirstStep',
    headerAfterFetch: 'Installation_Popup_Loader_Header',
    steps: [
      'Installation_Popup_Loader_Text1',
      'Installation_Popup_Loader_Text2',
      'Installation_Popup_Loader_Text3',
      'Installation_Popup_Loader_Text4',
    ],
    lastStep: 'Installation_Popup_Loader_Text5',
  },
  platformOrigin: {
    type: EditorType.Classic,
    initiator: InstallInitiator.Editor,
    info: {
      type: InstallationOriginType.SILENT_INSTALL,
    },
  },
  stepsPerSingleInstall: 4,
  stepsPerMultipleInstall: 2,
  APPS_TO_SEND_ENGAGEMENT: new Set<string>([WIX_BOOKINGS]),
  InstallationSteps: {
    BEGIN: 'BEGIN_FUNCTION',
    AFTER_FETCH_NAMES: 'AFTER_FETCH_NAMES',
    AFTER_USER_PREFS_LOAD: 'AFTER_USER_PREFS_LOAD',
    APPS_AFTER_ACTIVE_FILTER: 'APPS_AFTER_ACTIVE_FILTER',
  },
};

export interface SilentInstallAppsOptions {
  disableHistoryRevert?: boolean;
  appsOptions?: Record<string, Partial<AppInstallOption>>;

  installOrigin?: AppInstallOrigin;
  serialInstallation?: boolean;
  shouldRestrictActions?: boolean;
}
