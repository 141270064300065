import type { EditorAPI } from '@/editorAPI';
import _ from 'lodash';
import * as queries from '../queries/openBehaviorsQueries';

export default function getInfoForActions(editorAPI: EditorAPI) {
  const popupPointer = editorAPI.pages.popupPages.getCurrentPopup();
  const allOpenBehaviors =
    editorAPI.pages.popupPages.getOpenBehaviorsForPopups();

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  const currentOpenBehaviors = _.filter(allOpenBehaviors, {
    popupId: popupPointer.id,
  });

  const disabledPageIds = queries.getDisabledPageIds(
    allOpenBehaviors,
    popupPointer.id,
    editorAPI,
  );

  const allPageIds = editorAPI.dsRead.pages.getPageIdList();

  return {
    allPageIds,
    behaviorDefaults: queries.getSomeBehavior(currentOpenBehaviors, editorAPI)
      .params,
    disabledPageIds: _.zipObject(disabledPageIds),
    enabledPageIds: _.difference(allPageIds, disabledPageIds),
    openBehaviors: currentOpenBehaviors,
    popupPointer,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    selectedPageIds: _.map(currentOpenBehaviors, 'pageId'),
  };
}
