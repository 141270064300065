import _ from 'lodash';

import * as stateManagement from '@/stateManagement';

import type { FrameMeasuringScope } from './measureFrameRateEntrypoint';
import type { PageContextState } from './measureFrameRateStore';
import type { CompRef } from 'types/documentServices';

const { isPerformingMouseMoveAction } = stateManagement.mouseActions.selectors;

const REPORTED_COMPONENT_TYPES = new Set([
  'wysiwyg.viewer.components.tpapps.TPAWidget',
  'wysiwyg.viewer.components.RefComponent',
]);

const PAGE_CONTEXT_REFRESH_IMPORTANT_EVENTS = new Set([
  'pages.navigateTo',
  'components.add',
  'components.remove',
  'appStudioWidgets.addWidget',
  'tpa.widget.add',
  'tpa.widget.delete',
]);

export const subscribeStateToViewerChanges = (scope: FrameMeasuringScope) => {
  const editorAPI = scope.editorAPI;

  const refreshPageContext = () => {
    const tpaComponents: PageContextState['components'] = {};
    const focusedPage = editorAPI.dsRead.pages.getFocusedPage();
    const pageData = editorAPI.pages.data.get(focusedPage.id);

    editorAPI.dsRead.components
      .getAllComponentsFromFull(focusedPage.id)
      .filter((compRef: CompRef) =>
        REPORTED_COMPONENT_TYPES.has(
          editorAPI.dsRead.components.getType(compRef),
        ),
      )
      .forEach((compRef: CompRef) => {
        const compData = editorAPI.dsRead.components.data.get(compRef);

        tpaComponents[compRef.id] = {
          managingAppDefId: compData.appDefinitionId,
          compRef,
        };
      });

    scope.store.setPageContext({
      managingAppDefId: pageData?.managingAppDefId,
      components: tpaComponents,
    });
  };

  const throttledPageContextRefreshing = _.throttle(() => {
    refreshPageContext();
  }, 60000);

  editorAPI.registerToViewerChange((eventTypes: string[] | null) => {
    if (isPerformingMouseMoveAction(editorAPI.store.getState())) {
      return;
    }

    const anyImportantEvent =
      eventTypes &&
      eventTypes.find((eventType: string) =>
        PAGE_CONTEXT_REFRESH_IMPORTANT_EVENTS.has(eventType),
      );

    if (anyImportantEvent) {
      refreshPageContext();
    } else {
      throttledPageContextRefreshing();
    }
  });
};
