export default {
  DESKTOP: 1,
  MOBILE: 2,
  DESKTOP_AND_MOBILE: 3,

  FLAG_DESKTOP: 'openInDesktop',
  FLAG_MOBILE: 'openInMobile',

  OPEN_DELAY_DEFAULT_VALUE: 2,

  SITE_BEHAVIOR_TYPE: 'site',
  LOAD_ACTION: 'load',
  OPEN_POPUP_BEHAVIOR: 'openPopup',
};
