import { panels, bi } from '@/stateManagement';
import constants from '@/constants';

import type { PanelFrameOwnProps } from './PanelFrame';
import type { StateMapperArgs } from 'types/redux';
import type { BiEventFields } from 'types/bi';

export const mapStateToProps = ({ dsRead }: StateMapperArgs) => ({
  metaSiteInstance: dsRead.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  ).instance,
});

export const mapDispatchToProps = (
  dispatch: FunctionFixMe,
  ownProps: PanelFrameOwnProps,
) => {
  const closePanel = () =>
    dispatch(panels.actions.closePanelByName(ownProps.panelName));

  const openHelpCenter = (helpId: string, biHelpParams: BiEventFields) =>
    dispatch(
      panels.actions.openHelpCenter(
        helpId,
        {},
        { panel_name: ownProps.panelName, ...biHelpParams },
      ),
    );

  const sendBi = (evid: number, biParams: BiEventFields) =>
    dispatch(
      bi.actions.event(
        { evid },
        {
          ...biParams,
        },
      ),
    );

  return {
    closePanel,
    openHelpCenter,
    sendBi,
  };
};
