:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._fpdColumnContent_azuky_7 {
  transition: transform 250ms ease-in;
  transform-origin: bottom;
  margin: 0 27px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  ._fpdColumnContent_azuky_7 .fpd-main-action-title {
    opacity: 0;
    visibility: hidden;
    transition: all 100ms ease-in;
    cursor: pointer;
    color: #116dff;
    font-size: 16px; }
  ._fpdColumnContent_azuky_7:hover {
    transform: scale(1.05); }
    ._fpdColumnContent_azuky_7:hover .fpd-main-action-title {
      opacity: 1;
      visibility: visible; }
      ._fpdColumnContent_azuky_7:hover .fpd-main-action-title:hover {
        text-decoration: underline; }
  ._fpdColumnContent_azuky_7._singlePage_azuky_26 {
    margin: 0; }
    ._fpdColumnContent_azuky_7._singlePage_azuky_26:hover {
      transform: none; }

._siteCreationFpdThumbnailWrapper_azuky_31 {
  position: relative; }
  ._siteCreationFpdThumbnailWrapper_azuky_31.selected {
    outline: 2px solid #116dff;
    border-radius: 4px 4px 0 0; }

._fpdSelectedV_azuky_37 {
  position: absolute;
  right: -12px;
  top: -11px;
  z-index: 1; }

._fpdPresetWrapper_azuky_43 {
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  overflow: hidden;
  margin-top: 12px;
  border: 1px solid #dfe5eb;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 4px 7px 0 #162d3d2a;
  transition: box-shadow 250ms ease-in; }
  ._fpdPresetWrapper_azuky_43.nonInteractive {
    cursor: default; }
  ._fpdPresetWrapper_azuky_43:hover {
    box-shadow: 0 12px 20px 0 #162d3d1f; }
  ._fpdPresetWrapper_azuky_43.selected, ._fpdPresetWrapper_azuky_43:hover {
    border-radius: 4px 4px 0 0;
    outline: 2px solid #80b1ff; }
  ._fpdPresetWrapper_azuky_43::-webkit-scrollbar {
    width: 0; }
  ._fpdPresetWrapper_azuky_43 > div {
    transition: top 1.5s ease 0s, transform 1.5s ease 0s; }
  ._fpdPresetWrapper_azuky_43:hover > ._fpdScrollbar_azuky_65 {
    visibility: visible; }
  ._fpdPresetWrapper_azuky_43 * {
    pointer-events: none;
    font-size: inherit;
    line-height: inherit;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    outline: 0 !important;
    box-sizing: border-box;
    border-style: solid;
    border-width: 0; }

._fpdScrollbar_azuky_65 {
  visibility: hidden;
  width: 4px;
  position: absolute;
  right: 3px;
  top: 0;
  background-color: rgba(194, 199, 203, 0.5);
  height: 75%;
  border-radius: 30px; }
