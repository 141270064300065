import WixStoresManagePanelWithDealer from './panels/manager/WixStoresManagePanelWithDealer';
import wixStorePagesPanel from './panels/pages/wixStorePagesPanel';
import * as wixStoreDataProvider from '../common/wixStoreDataProvider';
import * as wixStoreEditorActionsService from '../common/wixStoreEditorActionsService';
import * as wixStoresSectionsProvider from '../common/data/wixStoresSectionsProvider';
import utils from './utils';
import dropshippingTab from './dropshippingTab/dropshippingTab';
import templateStep1 from './panels/firstTimeTour/templateFlow/templateStep1';
import manuallyInstalledStep1 from './panels/firstTimeTour/manuallyInstalledFlow/manuallyInstalledStep1';
import promotionalFlow1 from './panels/firstTimeTour/promotionalFlow/promotionalStep1';
import { tpaUtils, workspace } from '@/util';
import { translate } from '@/i18n';

const superAppTranslationKeys = {
  panels: {
    switchTitle: 'SiteApp_WixStores_SwitchTab_Header',
    manageTitle: 'SiteApp_WixStores_ManageTab_Header',
    pagesTitle: 'SiteApp_WixStores_PagesTab_Header',
    addElements: {
      title: 'SiteApp_WixStores_AddTab_Header',
      disabledInMobileMessageTitle: 'Siteapp_Wixstores_AddPanelMobile_Title',
      disabledInMobileMessageDescription:
        'Siteapp_Wixstores_AddPanelMobile_Description',
      disabledInMobileMessageDescriptionLink:
        'Siteapp_Wixstores_AddPanelMobile_Description_LinkPart',
    },
    learnMore: {
      title: 'SiteApp_WixStores_LearnMoreTab_Header',
      section1Header: 'SiteApp_WixStores_LearnMorePanel_Section1_Header',
      section2Header: 'SiteApp_WixStores_LearnMorePanel_Section2_Header',
      section3Header: 'SiteApp_WixStores_LearnMorePanel_Section3_Header',
    },
  },
};

const getSuperApp = (editorAPI: AnyFixMe) => {
  return {
    id: wixStoreDataProvider.superAppId,
    appDefId: tpaUtils.getStoresAppDefId(),
    appName: 'WixStores',
    firstTimeTour: {
      templateFlow: [templateStep1],
      manuallyInstalledFlow: [manuallyInstalledStep1],
      promotionalFlow: [promotionalFlow1],
    },
    panel: {
      name: 'wixStore.panels.wixStorePanel',
      label: workspace.isNewWorkspaceEnabled()
        ? translate('SiteApp_WixStores_Panel_Title')
        : undefined,
      manage: {
        title: superAppTranslationKeys.panels.manageTitle,
        helpId: '679e2ef4-be7c-4629-86d2-e1cc636a4c15',
        template: WixStoresManagePanelWithDealer,
      },
      pages: {
        title: superAppTranslationKeys.panels.pagesTitle,
        helpId: 'b60c6e7b-2b4f-452e-91e3-6708f520ce44',
        template: wixStorePagesPanel,
      },
      addElements: {
        title: superAppTranslationKeys.panels.addElements.title,
        helpId: '59002635-ffbd-4221-bed3-f3ed7031e282',
        teaser: () => wixStoreDataProvider.getElementsTeaserInfo(editorAPI),
        sections: () => wixStoresSectionsProvider.getAddPanelSection(editorAPI),
        disabledInMobileMessageTitle:
          superAppTranslationKeys.panels.addElements
            .disabledInMobileMessageTitle,
        disabledInMobileMessageDescription:
          superAppTranslationKeys.panels.addElements
            .disabledInMobileMessageDescription,
        disabledInMobileMessageDescriptionLink:
          superAppTranslationKeys.panels.addElements
            .disabledInMobileMessageDescriptionLink,
        mobileImage: 'mobileToEditor',
      },
      learnMore: {
        title: superAppTranslationKeys.panels.learnMore.title,
        data: wixStoreDataProvider.getLearnMoreData(),
        section1HeaderViewA:
          superAppTranslationKeys.panels.learnMore.section1Header,
        section2HeaderViewA:
          superAppTranslationKeys.panels.learnMore.section2Header,
        section3HeaderViewA:
          superAppTranslationKeys.panels.learnMore.section3Header,
      },
    },
    tooltip: {
      upgrade: wixStoreDataProvider.getUpgradeTooltipData(),
    },
    upgradeLinkText: 'SiteApp_WixStores_Upgrade_Header',
    isAppInstalled: () => wixStoreDataProvider.isAppInstalled(editorAPI),
    isPremium(editorApi: AnyFixMe) {
      return wixStoreDataProvider.hasPremium(editorApi);
    },
    openAppPremiumPackagePicker() {
      wixStoreEditorActionsService.handleUpgrade();
    },
  };
};

const getWixStoresSuperApp = function (
  isUserOwner: AnyFixMe,
  editorAPI: AnyFixMe,
) {
  const superApp: any = getSuperApp(editorAPI);
  superApp.hasPremiumPlan = isUserOwner;
  superApp.tooltip.external = utils.shouldShowDropshippingBanner()
    ? Object.assign(wixStoreDataProvider.getDropshippingTooltipData(), {
        template: dropshippingTab,
      })
    : null;

  return superApp;
};

export { getWixStoresSuperApp };
