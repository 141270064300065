// @ts-nocheck
import {
  editorDataFunction,
  componentInstanceData,
} from '@wix/editor-component-data-resolver';
import * as util from '@/util';
import experiment from 'experiment';

const { STRETCH, LEGACY_FULL } = util.imageTransform.fittingTypes;

export default {
  allowConnectToDB: true,
  allowedMobileOnly: true,
  overrideProportionalResize: editorDataFunction(
    componentInstanceData('componentProperties'),
    (compProps) =>
      (!compProps.autoFill || experiment.isOpen('se_defaultImageAutoFill')) &&
      compProps.displayMode !== STRETCH &&
      compProps.displayMode !== LEGACY_FULL,
  ),
};
