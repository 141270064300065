import { selectCollectionsItemCounts } from '../collections';

import type { MapStateToProps } from 'types/redux';
import type { CollectionItemCounts } from '../types';

export interface CollectionItemStateProps {
  itemCounts: CollectionItemCounts;
}

export const mapStateToProps: MapStateToProps<CollectionItemStateProps> = ({
  state,
}) => ({
  itemCounts: selectCollectionsItemCounts(state),
});
