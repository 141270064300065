import _ from 'lodash';
import type { Schema, Storage } from '@wix/wix-data-schema-types';

import { isSharedCollection } from './sharedCollection';
import type { ConnectorData } from '@wix/wix-data-connector-settings-client';

export interface Section {
  namespace: string;
  collections: Schema[];
  isEditable?: boolean;
  isExternalConnector?: boolean;
}

const sortCollections = (collections: Schema[]) =>
  _.sortBy(collections, 'displayName');

const sortSections = (sections: Section[]) => _.sortBy(sections, 'namespace');

const getCollectionsByNamespace = (collections: Schema[]) =>
  collections.reduce<Record<string, Schema[]>>((records, collection) => {
    if (collection.namespace) {
      const namespace = collection.displayNamespace || collection.namespace;
      records[namespace] = records[namespace] || [];
      records[namespace].push(collection);
    }

    return records;
  }, {});

const externalConnectorToSection = (connector: ConnectorData): Section => ({
  namespace: connector.namespace,
  isExternalConnector: true,
  collections: [],
});

const getEmptyExternalConnectorsSections = (
  collections: Schema[],
  externalConnectors: ConnectorData[] = [],
) => {
  const emptyExternalConnectors = externalConnectors.filter(
    (connector) =>
      !collections.some(
        (collection) =>
          collection.namespace === connector.namespace ||
          collection.displayNamespace === connector.namespace,
      ),
  );
  return emptyExternalConnectors.map(externalConnectorToSection);
};

const getCollectionsSections = (
  collections: Schema[],
  externalConnectors?: ConnectorData[],
) => {
  const collectionsByNamespace = getCollectionsByNamespace(collections);

  const sections = Object.keys(collectionsByNamespace).map((namespace) => ({
    namespace,
    collections: sortCollections(collectionsByNamespace[namespace]),
  }));

  const getSortedSectionsByStorage = (filter: (s: Storage) => boolean) =>
    sortSections(
      sections.filter(({ collections: [{ storage }] }) => filter(storage)),
    );

  return [
    //get sections with collections that have external storage and add them to array
    ...sortSections([
      ...getSortedSectionsByStorage((storage) => storage === 'external'),
      ...getEmptyExternalConnectorsSections(collections, externalConnectors),
    ]),
    //filter sections with collections which storage is not external or app and add them to array
    ...getSortedSectionsByStorage(
      (storage) => !['external', 'app'].includes(storage),
    ),
    //filter sections with collections that have app storage and add them to array
    ...getSortedSectionsByStorage((storage) => storage === 'app'),
  ];
};

export const getGroupedCollectionsSections = (
  collections: Schema[],
  externalConnectors?: ConnectorData[],
  ignoreGrouping?: boolean,
) => {
  const { collectionsOutOfSections, collectionsInSections } =
    collections.reduce(
      (groups, collection) => {
        if (!collection.namespace || isSharedCollection(collection)) {
          groups.collectionsOutOfSections.push(collection);
        } else {
          groups.collectionsInSections.push(collection);
        }
        return groups;
      },
      {
        collectionsOutOfSections: <Schema[]>[],
        collectionsInSections: <Schema[]>[],
      },
    );

  if (ignoreGrouping) {
    return {
      collectionsOutOfSections: sortCollections(collections),
      collectionsSections: [],
    };
  }

  return {
    collectionsOutOfSections: sortCollections(collectionsOutOfSections),
    collectionsSections: getCollectionsSections(
      collectionsInSections,
      externalConnectors,
    ),
  };
};
