:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_9kuv5_7 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  --side: calc(((1 - var(--siteScale))) * 100% / 2); }
  ._container_9kuv5_7._mobile_9kuv5_14 {
    --side: calc((50% - 320px / 2 * var(--siteScale))); }
  ._container_9kuv5_7 ._left_9kuv5_16 {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--side);
    height: 100%; }
  ._container_9kuv5_7 ._right_9kuv5_22 {
    position: absolute;
    top: 0;
    right: 0;
    width: var(--side);
    height: 100%; }
  ._container_9kuv5_7 ._bottom_9kuv5_28 {
    position: absolute;
    top: var(--previewHeight);
    right: 0;
    bottom: 0;
    left: 0; }
  ._container_9kuv5_7 ._shadow_9kuv5_34 {
    position: absolute;
    top: 0;
    left: var(--side);
    right: var(--side);
    bottom: calc(100% - var(--previewHeight)); }
  .sections-experience ._container_9kuv5_7 {
    pointer-events: none; }

._shadow_9kuv5_34 {
  box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.2); }
  .classic-facelift-skin ._shadow_9kuv5_34 {
    box-shadow: 0 2px 7px rgba(23, 25, 28, 0.17); }
  .sections-experience ._shadow_9kuv5_34 {
    box-shadow: 0px -1px 8px 2px rgba(0, 0, 0, 0.08), 0px 1px 8px 2px rgba(0, 0, 0, 0.08), 2px 0px 8px 2px rgba(0, 0, 0, 0.08), -2px 0px 8px 2px rgba(0, 0, 0, 0.08); }

._side_9kuv5_50 {
  background-color: var(--bodyBackgroundColor); }

._withFullHeightShadow_9kuv5_53 ._shadow_9kuv5_34 {
  bottom: 0; }
