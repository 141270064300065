'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_columns_services",
    "subNavigationTitle": "add_section_label_columns_services",
    "presetTitle": "add_section_label_columns_services",
    "tooltipTitle": "add_section_label_columns_services",
    "automationId": "add-panel-section-columnsServicesSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/columnsServicesSection_en/columnsServicesSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "Columns_ColumnsServices_1",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 244,
                        "height": 340,
                        "x": -136,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Armchair & Side Table",
                                "uri": "77ccfde700bc44a8a884f2ef31c8d5ba.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/47ec63e8-89c6-4665-b7f6-f5be6da222ff",
                                "width": 4064,
                                "height": 4178,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#F5F5F5",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 200,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Armchair & Side Table",
                                    "uri": "77ccfde700bc44a8a884f2ef31c8d5ba.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/47ec63e8-89c6-4665-b7f6-f5be6da222ff",
                                    "width": 4064,
                                    "height": 4178,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#F5F5F5",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 246,
                            "height": 36,
                            "x": 0,
                            "y": 69,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:26px; text-align:center\"><span style=\"color:#FF6161\"><span style=\"font-family:futura-lt-w01-light,sans-serif; letter-spacing:1.3px\">Title Goes Here</span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 160,
                                "height": 71,
                                "x": 80,
                                "y": 15,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:26px; text-align:center\"><span style=\"color:#FF6161\"><span style=\"font-family:futura-lt-w01-light,sans-serif; letter-spacing:1.3px\">Title Goes Here</span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 243,
                            "height": 77,
                            "x": 1,
                            "y": 125,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"line-height:1.6em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">I&rsquo;m a paragraph. Double click </span></p>\n\n<p class=\"font_8\" style=\"line-height:1.6em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">me or&nbsp;click Edit Text. It&#39;s easy &nbsp;to make&nbsp;it your&nbsp;own.</span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 228,
                                "height": 77,
                                "x": 47,
                                "y": 92,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"line-height:1.6em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">I&rsquo;m a paragraph. Double click </span></p>\n\n<p class=\"font_8\" style=\"line-height:1.6em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">me or&nbsp;click Edit Text. It&#39;s easy &nbsp;to make&nbsp;it your&nbsp;own.</span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 171,
                            "height": 40,
                            "x": 37,
                            "y": 228,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "Learn More"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FF6161",
                                    "bgh": "#F27979",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FF6161",
                                    "brdh": "#F27979",
                                    "brw": "1",
                                    "fnt": "normal normal normal 15px/1.4em futura-lt-w01-light",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FF6161",
                                    "txth": "#FFFFFF"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-brd": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 171,
                                "height": 40,
                                "x": 74,
                                "y": 189,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Learn More"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FF6161",
                                        "bgh": "#F27979",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FF6161",
                                        "brdh": "#F27979",
                                        "brw": "1",
                                        "fnt": "normal normal normal 15px/1.4em futura-lt-w01-light",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FF6161",
                                        "txth": "#FFFFFF"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-brd": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "layout": {
                        "width": 244,
                        "height": 340,
                        "x": 200,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFE",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 260,
                            "x": 0,
                            "y": 200,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFFFFE",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 244,
                        "height": 340,
                        "x": 536,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Blue Sofa",
                                "uri": "20e5671799c242d8b8faf5edc41c7311.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/47ec63e8-89c6-4665-b7f6-f5be6da222ff",
                                "width": 5000,
                                "height": 4125,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "{color_11}",
                            "colorOpacity": 0,
                            "alignType": "right",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 200,
                            "x": 0,
                            "y": 460,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Blue Sofa",
                                    "uri": "20e5671799c242d8b8faf5edc41c7311.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/47ec63e8-89c6-4665-b7f6-f5be6da222ff",
                                    "width": 5000,
                                    "height": 4125,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "{color_11}",
                                "colorOpacity": 0,
                                "alignType": "right",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 246,
                            "height": 77,
                            "x": 0,
                            "y": 123,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"line-height:1.6em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">I&rsquo;m a paragraph. Double click </span></p>\n\n<p class=\"font_8\" style=\"line-height:1.6em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">me or&nbsp;click Edit Text. It&#39;s easy &nbsp;to make&nbsp;it your&nbsp;own.</span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 228,
                                "height": 77,
                                "x": 47,
                                "y": 102,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"line-height:1.6em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">I&rsquo;m a paragraph. Double click </span></p>\n\n<p class=\"font_8\" style=\"line-height:1.6em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">me or&nbsp;click Edit Text. It&#39;s easy &nbsp;to make&nbsp;it your&nbsp;own.</span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 171,
                            "height": 40,
                            "x": 37,
                            "y": 226,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "Learn More"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FF6161",
                                    "bgh": "#F27979",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FF6161",
                                    "brdh": "#F27979",
                                    "brw": "1",
                                    "fnt": "normal normal normal 15px/1.4em futura-lt-w01-light",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FF6161",
                                    "txth": "#FFFFFF"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-brd": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 171,
                                "height": 40,
                                "x": 74,
                                "y": 199,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Learn More"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FF6161",
                                        "bgh": "#F27979",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FF6161",
                                        "brdh": "#F27979",
                                        "brw": "1",
                                        "fnt": "normal normal normal 15px/1.4em futura-lt-w01-light",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FF6161",
                                        "txth": "#FFFFFF"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-brd": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 240,
                            "height": 36,
                            "x": 6,
                            "y": 67,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:26px; text-align:center\"><span style=\"color:rgb(255, 97, 97); font-family:futura-lt-w01-light,sans-serif; letter-spacing:1.3px\">Title Goes Here</span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 164,
                                "height": 71,
                                "x": 78,
                                "y": 21,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:26px; text-align:center\"><span style=\"color:rgb(255, 97, 97); font-family:futura-lt-w01-light,sans-serif; letter-spacing:1.3px\">Title Goes Here</span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "layout": {
                        "width": 244,
                        "height": 340,
                        "x": 871,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFE",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 263,
                            "x": 0,
                            "y": 660,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFFFFE",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 340,
                    "x": 0,
                    "y": 100,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "{color_11}",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 0
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 923,
                        "x": 0,
                        "y": 32,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "{color_11}",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ioy7o89n"
            },
            "preset": {
                "rect": {"width": 324, "height": 108, "x": -0.8, "y": 0},
                "label": "add_preset_columns_services_1",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsServices_2",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 318,
                            "height": 213,
                            "x": 0,
                            "y": 167,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:14px; line-height:1.8em\"><span style=\"font-size:16px\"><span style=\"color:#8F8F8F\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\">I&#39;m a paragraph. Click here to add your own text and edit me. It&rsquo;s easy. Just click &ldquo;Edit Text&rdquo; or&nbsp;double click me to add your own content and make changes to the font. Feel free to drag and drop me anywhere you like on your page. I&rsquo;m a great place for you to tell a story and let your users know a little more about you.</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 260,
                                "x": 20,
                                "y": 79,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:14px; line-height:1.8em\"><span style=\"font-size:16px\"><span style=\"color:#8F8F8F\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\">I&#39;m a paragraph. Click here to add your own text and edit me. It&rsquo;s easy. Just click &ldquo;Edit Text&rdquo; or&nbsp;double click me to add your own content and make changes to the font. Feel free to drag and drop me anywhere you like on your page. I&rsquo;m a great place for you to tell a story and let your users know a little more about you.</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 301,
                            "height": 34,
                            "x": 1,
                            "y": 121,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:14px; line-height:2.5em\"><span style=\"color:#000000\"><span style=\"font-size:14px\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\">OUR SERVICES</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 192,
                                "height": 42,
                                "x": 20,
                                "y": 35,
                                "scale": 1.05,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:14px; line-height:2.5em\"><span style=\"color:#000000\"><span style=\"font-size:14px\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\">OUR SERVICES</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "layout": {
                        "width": 421,
                        "height": 510,
                        "x": -103,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FCFCFC",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 380,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FCFCFC",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 558,
                        "height": 510,
                        "x": 500,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "purple flowers.jpg",
                                "uri": "035244_22a88a4a45cd41c8b1388ee198cd6034~mv2_d_4829_2716_s_4_2.jpg",
                                "description": "private/allMedia_picture",
                                "width": 4829,
                                "height": 2716,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "{color_16}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 200,
                            "x": 0,
                            "y": 380,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "purple flowers.jpg",
                                    "uri": "035244_22a88a4a45cd41c8b1388ee198cd6034~mv2_d_4829_2716_s_4_2.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 4829,
                                    "height": 2716,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "{color_16}",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 510,
                    "x": 0,
                    "y": 640,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "{color_11}",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 0
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 580,
                        "x": 0,
                        "y": 2205,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "{color_11}",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ioyarzzv"
            },
            "preset": {
                "rect": {"width": 324, "height": 142, "x": -0.8, "y": 108},
                "label": "add_preset_columns_services_2",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsServices_3",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 246,
                            "height": 21,
                            "x": 1,
                            "y": 191,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size: 18px; line-height: 1em; text-align: center;\"><span style=\"font-size:18px;\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\"><span style=\"letter-spacing:0em;\"><span class=\"color_11\">Branding</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 170,
                                "height": 21,
                                "x": 76,
                                "y": 124,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size: 18px; line-height: 1em; text-align: center;\"><span style=\"font-size:18px;\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\"><span style=\"letter-spacing:0em;\"><span class=\"color_11\">Branding</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 190,
                            "height": 70,
                            "x": 26,
                            "y": 234,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.4em; text-align:center\"><span style=\"font-size:16px\"><span class=\"color_11\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">I&rsquo;m a paragraph. Double click me or click Edit Text,&nbsp;it&#39;s easy.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 213,
                                "height": 47,
                                "x": 53,
                                "y": 165,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.4em; text-align:center\"><span style=\"font-size:16px\"><span class=\"color_11\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">I&rsquo;m a paragraph. Double click me or click Edit Text,&nbsp;it&#39;s easy.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                        "layout": {
                            "width": 158,
                            "height": 35,
                            "x": 42,
                            "y": 321,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "Read More >"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                    "txt": "#FFFFFF",
                                    "txtd": "#FFFFFF",
                                    "txth": "#4BD1A0"
                                },
                                "propertiesSource": {
                                    "fnt": "value",
                                    "txt": "value",
                                    "txtd": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                            "layout": {
                                "width": 158,
                                "height": 35,
                                "x": 81,
                                "y": 232,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Read More >"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                        "txt": "#FFFFFF",
                                        "txtd": "#FFFFFF",
                                        "txth": "#4BD1A0"
                                    },
                                    "propertiesSource": {
                                        "fnt": "value",
                                        "txt": "value",
                                        "txtd": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_cd2842a5927a4d1ca4577245e6bb271f",
                        "layout": {
                            "width": 75,
                            "height": 84,
                            "x": 85,
                            "y": 65,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#FFFFFF",
                                    "stroke": "#ED1566",
                                    "strokewidth": "0px"
                                },
                                "propertiesSource": {
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_cd2842a5927a4d1ca4577245e6bb271f"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "svgshape.v2.Svg_cd2842a5927a4d1ca4577245e6bb271f",
                            "layout": {
                                "width": 73,
                                "height": 81,
                                "x": 123,
                                "y": 22,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "data": {
                                "type": "SvgShape",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "maintainAspectRatio": true
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#FFFFFF",
                                        "stroke": "#ED1566",
                                        "strokewidth": "0px"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_cd2842a5927a4d1ca4577245e6bb271f"
                            }
                        }
                    }],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": -136,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 298,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 246,
                            "height": 21,
                            "x": 0,
                            "y": 191,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size: 18px; line-height: 1em; text-align: center;\"><span style=\"letter-spacing:0em;\"><span style=\"font-size:18px;\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\"><span style=\"color:#4BD1A0\">Storytelling</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 248,
                                "height": 21,
                                "x": 37,
                                "y": 107,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size: 18px; line-height: 1em; text-align: center;\"><span style=\"letter-spacing:0em;\"><span style=\"font-size:18px;\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\"><span style=\"color:#4BD1A0\">Storytelling</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 182,
                            "height": 70,
                            "x": 32,
                            "y": 234,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.4em; text-align:center\"><span style=\"font-size:16px\"><span class=\"color_11\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">I&rsquo;m a paragraph. Double click me or click Edit Text,&nbsp;it&#39;s easy.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 213,
                                "height": 47,
                                "x": 53,
                                "y": 144,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.4em; text-align:center\"><span style=\"font-size:16px\"><span class=\"color_11\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">I&rsquo;m a paragraph. Double click me or click Edit Text,&nbsp;it&#39;s easy.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                        "layout": {
                            "width": 158,
                            "height": 35,
                            "x": 43,
                            "y": 321,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "Read More >"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                    "txt": "#FFFFFF",
                                    "txtd": "#FFFFFF",
                                    "txth": "#4BD1A0"
                                },
                                "propertiesSource": {
                                    "fnt": "value",
                                    "txt": "value",
                                    "txtd": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                            "layout": {
                                "width": 158,
                                "height": 35,
                                "x": 81,
                                "y": 211,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Read More >"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                        "txt": "#FFFFFF",
                                        "txtd": "#FFFFFF",
                                        "txth": "#4BD1A0"
                                    },
                                    "propertiesSource": {
                                        "fnt": "value",
                                        "txt": "value",
                                        "txtd": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_9c700c92106c4b29aa23b21fc105b82d",
                        "layout": {
                            "width": 73,
                            "height": 87,
                            "x": 86,
                            "y": 71,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#FFFFFF",
                                    "stroke": "#FFFFFF",
                                    "strokewidth": "0"
                                },
                                "propertiesSource": {
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_9c700c92106c4b29aa23b21fc105b82d"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "svgshape.v2.Svg_e6c6b5c06ce6493db877ae6a1d04b0de",
                            "layout": {
                                "width": 68,
                                "height": 81,
                                "x": 126,
                                "y": 19,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "data": {
                                "type": "SvgShape",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "maintainAspectRatio": true
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#FFFFFF",
                                        "stroke": "#FFFFFF",
                                        "strokewidth": "0"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_9c700c92106c4b29aa23b21fc105b82d"
                            }
                        }
                    }],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": 200,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "{color_14}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 298,
                            "x": 0,
                            "y": 298,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_14}",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                        "layout": {
                            "width": 158,
                            "height": 35,
                            "x": 40,
                            "y": 321,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "Read More >"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                    "txt": "#000000",
                                    "txtd": "#FFFFFF",
                                    "txth": "#4BD1A0"
                                },
                                "propertiesSource": {
                                    "fnt": "value",
                                    "txt": "value",
                                    "txtd": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                            "layout": {
                                "width": 158,
                                "height": 35,
                                "x": 81,
                                "y": 231,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Read More >"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                        "txt": "#000000",
                                        "txtd": "#FFFFFF",
                                        "txth": "#4BD1A0"
                                    },
                                    "propertiesSource": {
                                        "fnt": "value",
                                        "txt": "value",
                                        "txtd": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 186,
                            "height": 70,
                            "x": 30,
                            "y": 234,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.4em; text-align:center\"><span style=\"color:#000000\"><span style=\"font-size:16px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">I&rsquo;m a paragraph. Double click me or click Edit Text,&nbsp;it&#39;s easy.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 213,
                                "height": 47,
                                "x": 53,
                                "y": 164,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.4em; text-align:center\"><span style=\"color:#000000\"><span style=\"font-size:16px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">I&rsquo;m a paragraph. Double click me or click Edit Text,&nbsp;it&#39;s easy.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 248,
                            "height": 21,
                            "x": -2,
                            "y": 191,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:18px; line-height:1em; text-align:center\"><span style=\"letter-spacing:0em\"><span style=\"font-size:18px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"color:#4BD1A0\">DESIGN</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 122,
                                "height": 21,
                                "x": 99,
                                "y": 124,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:18px; line-height:1em; text-align:center\"><span style=\"letter-spacing:0em\"><span style=\"font-size:18px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"color:#4BD1A0\">DESIGN</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_818dfa43d89e49a194c5e05568f27f42",
                        "layout": {
                            "width": 83,
                            "height": 94,
                            "x": 81,
                            "y": 65,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#000000",
                                    "stroke": "#000000",
                                    "strokewidth": "0"
                                },
                                "propertiesSource": {
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_818dfa43d89e49a194c5e05568f27f42"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "svgshape.v1.svg_ab83605134f16308de4fc9d1dc6c1b94.PencilAndLine",
                            "layout": {
                                "width": 83,
                                "height": 94,
                                "x": 118,
                                "y": 16,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "data": {
                                "type": "SvgShape",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "maintainAspectRatio": true
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#000000",
                                        "stroke": "#000000",
                                        "strokewidth": "0"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_818dfa43d89e49a194c5e05568f27f42"
                            }
                        }
                    }],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": 536,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#EDEDED",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 298,
                            "x": 0,
                            "y": 596,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#EDEDED",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_d58caf6a671b4eaeba6e06e278cd7cc8",
                        "layout": {
                            "width": 80,
                            "height": 109,
                            "x": 82,
                            "y": 60,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#000000",
                                    "stroke": "#000000",
                                    "strokewidth": "0"
                                },
                                "propertiesSource": {
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_d58caf6a671b4eaeba6e06e278cd7cc8"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "svgshape.v2.Svg_c9a24d76f3494d88b10927bb03d00507",
                            "layout": {
                                "width": 82,
                                "height": 106,
                                "x": 119,
                                "y": 10,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "data": {
                                "type": "SvgShape",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "maintainAspectRatio": true
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#000000",
                                        "stroke": "#000000",
                                        "strokewidth": "0"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_d58caf6a671b4eaeba6e06e278cd7cc8"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 246,
                            "height": 21,
                            "x": 0,
                            "y": 191,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:18px; line-height:1em; text-align:center\"><span class=\"color_11\"><span style=\"letter-spacing:0em\"><span style=\"font-size:18px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">Consulting</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 210,
                                "height": 21,
                                "x": 56,
                                "y": 124,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:18px; line-height:1em; text-align:center\"><span class=\"color_11\"><span style=\"letter-spacing:0em\"><span style=\"font-size:18px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">Consulting</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 203,
                            "height": 70,
                            "x": 21,
                            "y": 234,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.4em; text-align:center\"><span style=\"color:#000000\"><span style=\"font-size:16px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">I&rsquo;m a paragraph. Double click me or click Edit Text,&nbsp;it&#39;s easy.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 213,
                                "height": 47,
                                "x": 53,
                                "y": 164,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.4em; text-align:center\"><span style=\"color:#000000\"><span style=\"font-size:16px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">I&rsquo;m a paragraph. Double click me or click Edit Text,&nbsp;it&#39;s easy.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                        "layout": {
                            "width": 158,
                            "height": 35,
                            "x": 42,
                            "y": 321,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "Read More >"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                    "txt": "#000000",
                                    "txtd": "#FFFFFF",
                                    "txth": "#4BD1A0"
                                },
                                "propertiesSource": {
                                    "fnt": "value",
                                    "txt": "value",
                                    "txtd": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                            "layout": {
                                "width": 158,
                                "height": 35,
                                "x": 81,
                                "y": 231,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Read More >"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                        "txt": "#000000",
                                        "txtd": "#FFFFFF",
                                        "txth": "#4BD1A0"
                                    },
                                    "propertiesSource": {
                                        "fnt": "value",
                                        "txt": "value",
                                        "txtd": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                            }
                        }
                    }],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": 871,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#4BD1A0",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 298,
                            "x": 0,
                            "y": 894,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#4BD1A0",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 450,
                    "x": 0,
                    "y": 1350,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    },
                    "charas": "design-iv0xmkw1"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 1192,
                        "x": 0,
                        "y": 3986,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        },
                        "charas": "design-iv0xmkw0"
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ioihz3kb"
            },
            "preset": {
                "rect": {"width": 324, "height": 130, "x": -0.8, "y": 250},
                "label": "add_preset_columns_services_3",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsServices_4",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Container",
                        "components": [{
                            "type": "Container",
                            "components": [{
                                "type": "Component",
                                "layout": {
                                    "width": 179,
                                    "height": 12,
                                    "x": 3,
                                    "y": 82,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size:20px; line-height:0.5em; text-align:center\"><span style=\"font-size:20px\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"color:#FFFFFE\">STRATEGY</span></span></span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 150,
                                        "height": 12,
                                        "x": 86,
                                        "y": 52,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<p class=\"font_8\" style=\"font-size:20px; line-height:0.5em; text-align:center\"><span style=\"font-size:20px\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"color:#FFFFFE\">STRATEGY</span></span></span></span></p>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                                "layout": {
                                    "width": 184,
                                    "height": 35,
                                    "x": 0,
                                    "y": 102,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "Read More "
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "fnt": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                                            "txt": "#FFFFFE",
                                            "txtd": "#FFFFFF",
                                            "txth": "#FAC3C3"
                                        },
                                        "propertiesSource": {
                                            "fnt": "value",
                                            "txt": "value",
                                            "txtd": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                                },
                                "mobileStructure": {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                                    "layout": {
                                        "width": 184,
                                        "height": 35,
                                        "x": 68,
                                        "y": 72,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "data": {
                                        "type": "LinkableButton",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "label": "Read More "
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {"schemaVersion": "1.0"},
                                        "align": "center",
                                        "margin": 0
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "fnt": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                                                "txt": "#FFFFFE",
                                                "txtd": "#FFFFFF",
                                                "txth": "#FAC3C3"
                                            },
                                            "propertiesSource": {
                                                "fnt": "value",
                                                "txt": "value",
                                                "txtd": "value",
                                                "txth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                                    }
                                }
                            }],
                            "layout": {
                                "width": 186,
                                "height": 209,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FF6161",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0
                                }
                            },
                            "style": "mc1",
                            "mobileStructure": {
                                "type": "Container",
                                "layout": {
                                    "width": 320,
                                    "height": 140,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.Column",
                                "props": {
                                    "type": "ColumnProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "alignment": 50
                                },
                                "design": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "color": "#FF6161",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0
                                    }
                                },
                                "style": "mc1"
                            }
                        }, {
                            "type": "Container",
                            "components": [],
                            "layout": {
                                "width": 186,
                                "height": 209,
                                "x": 199,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "mediaRef": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "title": "Shop Sign",
                                        "uri": "61cfb0e5d4d24657988c01e3ee517cbb.jpg",
                                        "description": "public/491df47b-5958-4dc7-9029-37f428755d45/ce6da6fa-34de-4aa0-b394-6c718f21064c",
                                        "width": 3738,
                                        "height": 2490,
                                        "alt": "",
                                        "artist": {"id": "", "name": ""}
                                    },
                                    "color": "#FAFAFA",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0
                                }
                            },
                            "style": "mc1",
                            "mobileStructure": {
                                "type": "Container",
                                "layout": {
                                    "width": 320,
                                    "height": 200,
                                    "x": 0,
                                    "y": 153,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.Column",
                                "props": {
                                    "type": "ColumnProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "alignment": 50
                                },
                                "design": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "mediaRef": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "title": "Shop Sign",
                                            "uri": "61cfb0e5d4d24657988c01e3ee517cbb.jpg",
                                            "description": "public/491df47b-5958-4dc7-9029-37f428755d45/ce6da6fa-34de-4aa0-b394-6c718f21064c",
                                            "width": 3738,
                                            "height": 2490,
                                            "alt": "",
                                            "artist": {"id": "", "name": ""}
                                        },
                                        "color": "#FAFAFA",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0
                                    }
                                },
                                "style": "mc1"
                            }
                        }, {
                            "type": "Container",
                            "components": [{
                                "type": "Component",
                                "layout": {
                                    "width": 179,
                                    "height": 12,
                                    "x": 3,
                                    "y": 82,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size: 20px; line-height: 0.5em; text-align: center;\"><span style=\"font-size:20px;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"color:#FFFFFE\">BRANDING</span></span></span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 170,
                                        "height": 12,
                                        "x": 74,
                                        "y": 54,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<p class=\"font_8\" style=\"font-size: 20px; line-height: 0.5em; text-align: center;\"><span style=\"font-size:20px;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"color:#FFFFFE\">BRANDING</span></span></span></span></p>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                                "layout": {
                                    "width": 179,
                                    "height": 35,
                                    "x": 2,
                                    "y": 102,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "Read More "
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "fnt": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                                            "txt": "#FFFFFE",
                                            "txtd": "#FFFFFF",
                                            "txth": "#FAC3C3"
                                        },
                                        "propertiesSource": {
                                            "fnt": "value",
                                            "txt": "value",
                                            "txtd": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                                },
                                "mobileStructure": {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                                    "layout": {
                                        "width": 179,
                                        "height": 35,
                                        "x": 70,
                                        "y": 71,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "data": {
                                        "type": "LinkableButton",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "label": "Read More "
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {"schemaVersion": "1.0"},
                                        "align": "center",
                                        "margin": 0
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "fnt": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                                                "txt": "#FFFFFE",
                                                "txtd": "#FFFFFF",
                                                "txth": "#FAC3C3"
                                            },
                                            "propertiesSource": {
                                                "fnt": "value",
                                                "txt": "value",
                                                "txtd": "value",
                                                "txth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                                    }
                                }
                            }],
                            "layout": {
                                "width": 184,
                                "height": 209,
                                "x": 398,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FF6161",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0
                                }
                            },
                            "style": "mc1",
                            "mobileStructure": {
                                "type": "Container",
                                "layout": {
                                    "width": 320,
                                    "height": 140,
                                    "x": 0,
                                    "y": 366,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.Column",
                                "props": {
                                    "type": "ColumnProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "alignment": 50
                                },
                                "design": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "color": "#FF6161",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0
                                    }
                                },
                                "style": "mc1"
                            }
                        }, {
                            "type": "Container",
                            "components": [],
                            "layout": {
                                "width": 186,
                                "height": 209,
                                "x": 596,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "mediaRef": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "title": "Hip-Hop Artist",
                                        "uri": "2144345e48ac4090a6bafc808f15fbb4.jpg",
                                        "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                        "width": 1736,
                                        "height": 1200,
                                        "alt": "",
                                        "artist": {"id": "mobile_", "name": ""}
                                    },
                                    "color": "#E21C21",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0
                                }
                            },
                            "style": "mc1",
                            "mobileStructure": {
                                "type": "Container",
                                "layout": {
                                    "width": 320,
                                    "height": 200,
                                    "x": 0,
                                    "y": 519,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.Column",
                                "props": {
                                    "type": "ColumnProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "alignment": 50
                                },
                                "design": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "mediaRef": {
                                            "type": "Image",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "title": "Hip-Hop Artist",
                                            "uri": "2144345e48ac4090a6bafc808f15fbb4.jpg",
                                            "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                            "width": 1736,
                                            "height": 1200,
                                            "alt": "",
                                            "artist": {"id": "", "name": ""}
                                        },
                                        "color": "#E21C21",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0
                                    }
                                },
                                "style": "mc1"
                            }
                        }, {
                            "type": "Container",
                            "components": [{
                                "type": "Component",
                                "layout": {
                                    "width": 179,
                                    "height": 12,
                                    "x": 1,
                                    "y": 81,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size: 20px; line-height: 0.5em; text-align: center;\"><span style=\"font-size:20px;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"color:#FFFFFE\">DESIGN</span></span></span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 136,
                                        "height": 12,
                                        "x": 92,
                                        "y": 50,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<p class=\"font_8\" style=\"font-size: 20px; line-height: 0.5em; text-align: center;\"><span style=\"font-size:20px;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"color:#FFFFFE\">DESIGN</span></span></span></span></p>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                                "layout": {
                                    "width": 179,
                                    "height": 35,
                                    "x": 2,
                                    "y": 102,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "Read More "
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "fnt": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                                            "txt": "#FFFFFE",
                                            "txtd": "#FFFFFF",
                                            "txth": "#FAC3C3"
                                        },
                                        "propertiesSource": {
                                            "fnt": "value",
                                            "txt": "value",
                                            "txtd": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                                },
                                "mobileStructure": {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                                    "layout": {
                                        "width": 179,
                                        "height": 35,
                                        "x": 70,
                                        "y": 70,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "data": {
                                        "type": "LinkableButton",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "label": "Read More "
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {"schemaVersion": "1.0"},
                                        "align": "center",
                                        "margin": 0
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "style": {
                                            "groups": {},
                                            "properties": {
                                                "fnt": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                                                "txt": "#FFFFFE",
                                                "txtd": "#FFFFFF",
                                                "txth": "#FAC3C3"
                                            },
                                            "propertiesSource": {
                                                "fnt": "value",
                                                "txt": "value",
                                                "txtd": "value",
                                                "txth": "value"
                                            }
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                                    }
                                }
                            }],
                            "layout": {
                                "width": 186,
                                "height": 209,
                                "x": 794,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FF6161",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0
                                }
                            },
                            "style": "mc1",
                            "mobileStructure": {
                                "type": "Container",
                                "layout": {
                                    "width": 320,
                                    "height": 140,
                                    "x": 0,
                                    "y": 732,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.Column",
                                "props": {
                                    "type": "ColumnProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "alignment": 50
                                },
                                "design": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "color": "#FF6161",
                                        "colorOpacity": 1,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 0
                                    }
                                },
                                "style": "mc1"
                            }
                        }],
                        "layout": {
                            "width": 980,
                            "height": 209,
                            "x": 0,
                            "y": 210,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                        "props": {
                            "type": "StripColumnsContainerProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullWidth": false,
                            "columnsMargin": 13,
                            "frameMargin": 0,
                            "rowMargin": 0,
                            "siteMargin": 0
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "colorOpacity": 0,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "strc1",
                        "mobileStructure": {
                            "type": "Container",
                            "layout": {
                                "width": 320,
                                "height": 872,
                                "x": 0,
                                "y": 157,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                            "props": {
                                "type": "StripColumnsContainerProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullWidth": false,
                                "columnsMargin": 13,
                                "frameMargin": 0,
                                "rowMargin": 0,
                                "siteMargin": 0
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "{color_11}",
                                    "colorOpacity": 0,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0
                                }
                            },
                            "style": "strc1"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 680,
                            "height": 60,
                            "x": 150,
                            "y": 81,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:46px; text-align:center\"><span style=\"color:#2B689C\"><span style=\"letter-spacing:0.3em\"><span style=\"font-size:46px\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"font-weight:bold\">OUR SERVICES</span></span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 84,
                                "x": 20,
                                "y": 37,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:46px; text-align:center\"><span style=\"color:#2B689C\"><span style=\"letter-spacing:0.3em\"><span style=\"font-size:46px\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"font-weight:bold\">OUR SERVICES</span></span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "layout": {
                        "width": 980,
                        "height": 520,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#045184",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 1029,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#045184",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 520,
                    "x": 0,
                    "y": 2000,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 1029,
                        "x": 0,
                        "y": 1081,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ioy8iy4u"
            },
            "preset": {
                "rect": {"width": 324, "height": 144, "x": -0.8, "y": 380},
                "label": "add_preset_columns_services_4",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsServices_5",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Container",
                        "components": [{
                            "type": "Container",
                            "components": [{
                                "type": "Component",
                                "layout": {
                                    "width": 233,
                                    "height": 22,
                                    "x": 1,
                                    "y": 212,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h1 class=\"font_0\" style=\"font-size:16px; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"letter-spacing:0.1em\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-style:normal\"><span style=\"font-size:16px\">BRANDING</span></span></span></span></span></span></h1>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 117,
                                        "height": 22,
                                        "x": 102,
                                        "y": 137,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<h1 class=\"font_0\" style=\"font-size:16px; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"letter-spacing:0.1em\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-style:normal\"><span style=\"font-size:16px\">BRANDING</span></span></span></span></span></span></h1>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "layout": {
                                    "width": 233,
                                    "height": 130,
                                    "x": 1,
                                    "y": 46,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h1 class=\"font_0\" style=\"font-size:100px; text-align:center\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-style:normal\"><span style=\"font-size:100px\">01</span></span></span></span></span></span></h1>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 127,
                                        "height": 64,
                                        "x": 98,
                                        "y": 29,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<h1 class=\"font_0\" style=\"font-size:100px; text-align:center\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-style:normal\"><span style=\"font-size:100px\">01</span></span></span></span></span></span></h1>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 44,
                                    "height": 5,
                                    "x": 96,
                                    "y": 174,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "2"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                },
                                "mobileStructure": {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                    "layout": {
                                        "width": 44,
                                        "height": 5,
                                        "x": 138,
                                        "y": 107,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                    "props": {
                                        "type": "FiveGridLineProperties",
                                        "metaData": {"schemaVersion": "1.0"},
                                        "fullScreenModeOn": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "style": {
                                            "groups": {},
                                            "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "2"},
                                            "propertiesSource": {"brd": "value", "lnw": "value"}
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                    }
                                }
                            }],
                            "layout": {
                                "width": 236,
                                "height": 288,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFFFFF",
                                    "colorOpacity": 0.4,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 1
                                }
                            },
                            "style": "mc1",
                            "mobileStructure": {
                                "type": "Container",
                                "layout": {
                                    "width": 320,
                                    "height": 199,
                                    "x": 0,
                                    "y": 0,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.Column",
                                "props": {
                                    "type": "ColumnProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "alignment": 50
                                },
                                "design": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "color": "#FFFFFF",
                                        "colorOpacity": 0.4,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 1
                                    }
                                },
                                "style": "mc1"
                            }
                        }, {
                            "type": "Container",
                            "components": [{
                                "type": "Component",
                                "layout": {
                                    "width": 232,
                                    "height": 130,
                                    "x": 2,
                                    "y": 46,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h1 class=\"font_0\" style=\"font-size:100px; text-align:center\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-style:normal\"><span style=\"font-size:100px\">02</span></span></span></span></span></span></h1>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 150,
                                        "height": 64,
                                        "x": 86,
                                        "y": 26,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<h1 class=\"font_0\" style=\"font-size:100px; text-align:center\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-style:normal\"><span style=\"font-size:100px\">02</span></span></span></span></span></span></h1>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 44,
                                    "height": 5,
                                    "x": 96,
                                    "y": 174,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "2"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                },
                                "mobileStructure": {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                    "layout": {
                                        "width": 44,
                                        "height": 5,
                                        "x": 138,
                                        "y": 107,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                    "props": {
                                        "type": "FiveGridLineProperties",
                                        "metaData": {"schemaVersion": "1.0"},
                                        "fullScreenModeOn": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "style": {
                                            "groups": {},
                                            "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "2"},
                                            "propertiesSource": {"brd": "value", "lnw": "value"}
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                    }
                                }
                            }, {
                                "type": "Component",
                                "layout": {
                                    "width": 231,
                                    "height": 22,
                                    "x": 3,
                                    "y": 210,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h1 class=\"font_0\" style=\"font-size:16px; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"letter-spacing:0.1em\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-style:normal\"><span style=\"font-size:16px\">STORYTELLING</span></span></span></span></span></span></h1>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 150,
                                        "height": 22,
                                        "x": 86,
                                        "y": 137,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<h1 class=\"font_0\" style=\"font-size:16px; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"letter-spacing:0.1em\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-style:normal\"><span style=\"font-size:16px\">STORYTELLING</span></span></span></span></span></span></h1>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": "txtNew"
                                }
                            }],
                            "layout": {
                                "width": 234,
                                "height": 288,
                                "x": 249,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFFFFF",
                                    "colorOpacity": 0.3,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 1
                                }
                            },
                            "style": "mc1",
                            "mobileStructure": {
                                "type": "Container",
                                "layout": {
                                    "width": 320,
                                    "height": 198,
                                    "x": 0,
                                    "y": 212,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.Column",
                                "props": {
                                    "type": "ColumnProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "alignment": 50
                                },
                                "design": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "color": "#FFFFFF",
                                        "colorOpacity": 0.3,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 1
                                    }
                                },
                                "style": "mc1"
                            }
                        }, {
                            "type": "Container",
                            "components": [{
                                "type": "Component",
                                "layout": {
                                    "width": 232,
                                    "height": 130,
                                    "x": 2,
                                    "y": 46,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h1 class=\"font_0\" style=\"font-size:100px; text-align:center\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-style:normal\"><span style=\"font-size:100px\">03</span></span></span></span></span></span></h1>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 150,
                                        "height": 64,
                                        "x": 86,
                                        "y": 23,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<h1 class=\"font_0\" style=\"font-size:100px; text-align:center\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-style:normal\"><span style=\"font-size:100px\">03</span></span></span></span></span></span></h1>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "layout": {
                                    "width": 232,
                                    "height": 22,
                                    "x": 2,
                                    "y": 212,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h1 class=\"font_0\" style=\"font-size:16px; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"letter-spacing:0.1em\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-style:normal\"><span style=\"font-size:16px\">DESIGN</span></span></span></span></span></span></h1>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 83,
                                        "height": 22,
                                        "x": 119,
                                        "y": 135,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<h1 class=\"font_0\" style=\"font-size:16px; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"letter-spacing:0.1em\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-style:normal\"><span style=\"font-size:16px\">DESIGN</span></span></span></span></span></span></h1>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 44,
                                    "height": 5,
                                    "x": 96,
                                    "y": 174,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "2"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                },
                                "mobileStructure": {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                    "layout": {
                                        "width": 44,
                                        "height": 5,
                                        "x": 138,
                                        "y": 105,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                    "props": {
                                        "type": "FiveGridLineProperties",
                                        "metaData": {"schemaVersion": "1.0"},
                                        "fullScreenModeOn": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "style": {
                                            "groups": {},
                                            "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "2"},
                                            "propertiesSource": {"brd": "value", "lnw": "value"}
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                    }
                                }
                            }],
                            "layout": {
                                "width": 234,
                                "height": 288,
                                "x": 497,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFFFFE",
                                    "colorOpacity": 0.3,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 1
                                }
                            },
                            "style": "mc1",
                            "mobileStructure": {
                                "type": "Container",
                                "layout": {
                                    "width": 320,
                                    "height": 199,
                                    "x": 0,
                                    "y": 423,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.Column",
                                "props": {
                                    "type": "ColumnProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "alignment": 50
                                },
                                "design": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "color": "#FFFFFE",
                                        "colorOpacity": 0.3,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 1
                                    }
                                },
                                "style": "mc1"
                            }
                        }, {
                            "type": "Container",
                            "components": [{
                                "type": "Component",
                                "layout": {
                                    "width": 231,
                                    "height": 22,
                                    "x": 3,
                                    "y": 212,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h1 class=\"font_0\" style=\"font-size:16px; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"letter-spacing:0.1em\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-style:normal\"><span style=\"font-size:16px\">CONSULTING</span></span></span></span></span></span></h1>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 142,
                                        "height": 22,
                                        "x": 89,
                                        "y": 128,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<h1 class=\"font_0\" style=\"font-size:16px; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"letter-spacing:0.1em\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-style:normal\"><span style=\"font-size:16px\">CONSULTING</span></span></span></span></span></span></h1>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "layout": {
                                    "width": 231,
                                    "height": 130,
                                    "x": 3,
                                    "y": 46,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h1 class=\"font_0\" style=\"font-size:100px; text-align:center\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-size:100px\">04</span></span></span></span></span></h1>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 150,
                                        "height": 64,
                                        "x": 86,
                                        "y": 20,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<h1 class=\"font_0\" style=\"font-size:100px; text-align:center\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:normal\"><span style=\"font-size:100px\">04</span></span></span></span></span></h1>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 44,
                                    "height": 5,
                                    "x": 96,
                                    "y": 174,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "2"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                },
                                "mobileStructure": {
                                    "type": "Component",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                    "layout": {
                                        "width": 44,
                                        "height": 5,
                                        "x": 138,
                                        "y": 98,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                    "props": {
                                        "type": "FiveGridLineProperties",
                                        "metaData": {"schemaVersion": "1.0"},
                                        "fullScreenModeOn": false
                                    },
                                    "style": {
                                        "type": "TopLevelStyle",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "style": {
                                            "groups": {},
                                            "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "2"},
                                            "propertiesSource": {"brd": "value", "lnw": "value"}
                                        },
                                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                        "pageId": "",
                                        "compId": "",
                                        "styleType": "custom",
                                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                    }
                                }
                            }],
                            "layout": {
                                "width": 236,
                                "height": 288,
                                "x": 744,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFFFFF",
                                    "colorOpacity": 0.3,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 1
                                }
                            },
                            "style": "mc1",
                            "mobileStructure": {
                                "type": "Container",
                                "layout": {
                                    "width": 320,
                                    "height": 199,
                                    "x": 0,
                                    "y": 635,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.Column",
                                "props": {
                                    "type": "ColumnProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "alignment": 50
                                },
                                "design": {
                                    "type": "MediaContainerDesignData",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "background": {
                                        "type": "BackgroundMedia",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "color": "#FFFFFF",
                                        "colorOpacity": 0.3,
                                        "alignType": "center",
                                        "fittingType": "fill",
                                        "scrollType": "none",
                                        "colorOverlay": "",
                                        "colorOverlayOpacity": 1
                                    }
                                },
                                "style": "mc1"
                            }
                        }],
                        "layout": {
                            "width": 980,
                            "height": 288,
                            "x": 0,
                            "y": 207,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                        "props": {
                            "type": "StripColumnsContainerProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullWidth": false,
                            "columnsMargin": 13,
                            "frameMargin": 0,
                            "rowMargin": 0,
                            "siteMargin": 0
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "colorOpacity": 0,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "strc1",
                        "mobileStructure": {
                            "type": "Container",
                            "layout": {
                                "width": 320,
                                "height": 834,
                                "x": 0,
                                "y": 78,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                            "props": {
                                "type": "StripColumnsContainerProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullWidth": false,
                                "columnsMargin": 13,
                                "frameMargin": 0,
                                "rowMargin": 0,
                                "siteMargin": 0
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "{color_11}",
                                    "colorOpacity": 0,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0
                                }
                            },
                            "style": "strc1"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 980,
                            "height": 72,
                            "x": 0,
                            "y": 74,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h1 class=\"font_0\" style=\"font-size: 56px; text-align: center;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-size:56px;\"><span style=\"color:#FFFFFF\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"font-style:normal\"><span style=\"font-weight:normal\">What We Do</span></span></span></span></span></span></h1>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 47,
                                "x": 20,
                                "y": 18,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h1 class=\"font_0\" style=\"font-size: 56px; text-align: center;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-size:56px;\"><span style=\"color:#FFFFFF\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"font-style:normal\"><span style=\"font-weight:normal\">What We Do</span></span></span></span></span></span></h1>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "layout": {
                        "width": 980,
                        "height": 630,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "womens silver shoes.jpg",
                                "uri": "035244_d7e4762d5cae42e6ab3eebd358a44fba~mv2_d_5750_3234_s_4_2.jpg",
                                "description": "private/allMedia_picture",
                                "width": 5750,
                                "height": 3234,
                                "alt": "",
                                "artist": {"id": "mobile_mobile_mobile_mobile_mobile_mobile_", "name": ""},
                                "opacity": 0.86
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 912,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "womens silver shoes.jpg",
                                    "uri": "035244_d7e4762d5cae42e6ab3eebd358a44fba~mv2_d_5750_3234_s_4_2.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 5750,
                                    "height": 3234,
                                    "alt": "",
                                    "artist": {"id": "mobile_mobile_mobile_mobile_mobile_", "name": ""},
                                    "opacity": 0.86
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 630,
                    "x": 0,
                    "y": 2720,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 912,
                        "x": 0,
                        "y": 2981,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ip2fjt5x"
            },
            "preset": {
                "rect": {"width": 324, "height": 166, "x": -0.8, "y": 524},
                "label": "add_preset_columns_services_5",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsServices_6",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": -136,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Canapes",
                                "uri": "5dd3bd79153f4a169dc88741631afbe8.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                "width": 4200,
                                "height": 2800,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 160,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Canapes",
                                    "uri": "5dd3bd79153f4a169dc88741631afbe8.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                    "width": 4200,
                                    "height": 2800,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 223,
                            "height": 51,
                            "x": 9,
                            "y": 99,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:80px; line-height:0.5em; text-align:center\"><span style=\"font-size:80px\"><span style=\"color:#BC9B5D\"><span style=\"font-family:peaches-and-cream-regular-w00,script\">Events</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 150,
                                "height": 28,
                                "x": 86,
                                "y": 34,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:80px; line-height:0.5em; text-align:center\"><span style=\"font-size:80px\"><span style=\"color:#BC9B5D\"><span style=\"font-family:peaches-and-cream-regular-w00,script\">Events</span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 228,
                            "height": 128,
                            "x": 9,
                            "y": 177,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:17px; line-height:1.5em; text-align:center\"><span style=\"font-size:17px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">Describe what you offer here. Add a few choice words and a stunning pic to engage your audience and get them to click.&nbsp;</span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 102,
                                "x": 20,
                                "y": 72,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:17px; line-height:1.5em; text-align:center\"><span style=\"font-size:17px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">Describe what you offer here. Add a few choice words and a stunning pic to engage your audience and get them to click.&nbsp;</span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                        "layout": {
                            "width": 151,
                            "height": 26,
                            "x": 47,
                            "y": 315,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "All events >"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "fnt": "italic normal normal 15px/1.4em libre+baskerville",
                                    "txt": "#BC9B5D",
                                    "txtd": "#FFFFFF",
                                    "txth": "#605E5E"
                                },
                                "propertiesSource": {
                                    "fnt": "value",
                                    "txt": "value",
                                    "txtd": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                            "layout": {
                                "width": 151,
                                "height": 26,
                                "x": 84,
                                "y": 181,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "All events >"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "fnt": "italic normal normal 15px/1.4em libre+baskerville",
                                        "txt": "#BC9B5D",
                                        "txtd": "#FFFFFF",
                                        "txth": "#605E5E"
                                    },
                                    "propertiesSource": {
                                        "fnt": "value",
                                        "txt": "value",
                                        "txtd": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                            }
                        }
                    }],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": 200,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FCF8ED",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 234,
                            "x": 0,
                            "y": 160,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FCF8ED",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": 536,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Preparing Hot Dogs",
                                "uri": "a3ce2f00c39c4cee8ed24ccd909d830b.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                "width": 5500,
                                "height": 3875,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#FCF8ED",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 160,
                            "x": 0,
                            "y": 394,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Preparing Hot Dogs",
                                    "uri": "a3ce2f00c39c4cee8ed24ccd909d830b.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                    "width": 5500,
                                    "height": 3875,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#FCF8ED",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 229,
                            "height": 51,
                            "x": 8,
                            "y": 99,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:80px; line-height:0.5em; text-align:center\"><span style=\"font-size:80px\"><span style=\"color:#BC9B5D\"><span style=\"font-family:peaches-and-cream-regular-w00,script\">Parties</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 181,
                                "height": 28,
                                "x": 70,
                                "y": 34,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:80px; line-height:0.5em; text-align:center\"><span style=\"font-size:80px\"><span style=\"color:#BC9B5D\"><span style=\"font-family:peaches-and-cream-regular-w00,script\">Parties</span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 229,
                            "height": 128,
                            "x": 8,
                            "y": 177,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:17px; line-height:1.5em; text-align:center\"><span style=\"font-size:17px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">Describe what you offer here. Add a few choice words and a stunning pic to engage your audience and get them to click.&nbsp;</span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 102,
                                "x": 20,
                                "y": 72,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:17px; line-height:1.5em; text-align:center\"><span style=\"font-size:17px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">Describe what you offer here. Add a few choice words and a stunning pic to engage your audience and get them to click.&nbsp;</span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                        "layout": {
                            "width": 151,
                            "height": 26,
                            "x": 47,
                            "y": 315,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "All parties >"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "fnt": "italic normal normal 15px/1.4em libre+baskerville",
                                    "txt": "#BC9B5D",
                                    "txtd": "#FFFFFF",
                                    "txth": "#605E5E"
                                },
                                "propertiesSource": {
                                    "fnt": "value",
                                    "txt": "value",
                                    "txtd": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                            "layout": {
                                "width": 151,
                                "height": 26,
                                "x": 84,
                                "y": 181,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "All parties >"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "fnt": "italic normal normal 15px/1.4em libre+baskerville",
                                        "txt": "#BC9B5D",
                                        "txtd": "#FFFFFF",
                                        "txth": "#605E5E"
                                    },
                                    "propertiesSource": {
                                        "fnt": "value",
                                        "txt": "value",
                                        "txtd": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                            }
                        }
                    }],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": 871,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FCF8ED",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 234,
                            "x": 0,
                            "y": 554,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FCF8ED",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 450,
                    "x": 0,
                    "y": 3550,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    },
                    "charas": "design-iv0xnbdz"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 788,
                        "x": 0,
                        "y": 5248,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        },
                        "charas": "design-iv0xnbdy"
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ioin258n"
            },
            "preset": {
                "rect": {"width": 324, "height": 130, "x": -0.8, "y": 690},
                "label": "add_preset_columns_services_6",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsServices_7",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 258,
                            "height": 93,
                            "x": 32,
                            "y": 242,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "BUY NOW"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#4BD1A0",
                                    "bgh": "#000000",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#000000",
                                    "brdh": "#000000",
                                    "brw": "6",
                                    "fnt": "normal normal normal 25px/1.4em lulo-clean-w01-one-bold",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#000000",
                                    "txth": "#FFFFFF"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 200,
                                "height": 60,
                                "x": 60,
                                "y": 91,
                                "scale": 0.863837598531476,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "BUY NOW"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#4BD1A0",
                                        "bgh": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#000000",
                                        "brdh": "#000000",
                                        "brw": "6",
                                        "fnt": "normal normal normal 25px/1.4em lulo-clean-w01-one-bold",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#000000",
                                        "txth": "#FFFFFF"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "layout": {
                        "width": 332,
                        "height": 600,
                        "x": -119,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Skater Girl.jpg",
                                "uri": "035244_8da1e1abfb7249679fe967fe13b0dd9f.jpg",
                                "description": "private/",
                                "width": 600,
                                "height": 1200,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.8
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 220,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Skater Girl.jpg",
                                    "uri": "035244_8da1e1abfb7249679fe967fe13b0dd9f.jpg",
                                    "description": "private/",
                                    "width": 600,
                                    "height": 1200,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.8
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 322,
                            "height": 142,
                            "x": 2,
                            "y": 184,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:60px; line-height:1em; text-align:center\"><span style=\"font-size:60px\"><span style=\"color:#4BD1A0\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">FRESH</span></span></span></span></h2>\n\n<h2 class=\"font_2\" style=\"font-size:60px; line-height:1em; text-align:center\"><span style=\"font-size:60px\"><span class=\"color_11\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">&amp;NEW</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 280,
                                "height": 89,
                                "x": 20,
                                "y": 64,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:60px; line-height:1em; text-align:center\"><span style=\"font-size:60px\"><span style=\"color:#4BD1A0\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">FRESH</span></span></span></span></h2>\n\n<h2 class=\"font_2\" style=\"font-size:60px; line-height:1em; text-align:center\"><span style=\"font-size:60px\"><span class=\"color_11\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">&amp;NEW</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 42,
                            "height": 7.77778,
                            "x": 141,
                            "y": 140,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "8"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 42,
                                "height": 7.77778,
                                "x": 139,
                                "y": 37,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "8"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 42,
                            "height": 7.77778,
                            "x": 141,
                            "y": 362,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "8"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 42,
                                "height": 7.77778,
                                "x": 139,
                                "y": 165,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "8"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                        "layout": {
                            "width": 132,
                            "height": 47,
                            "x": 97,
                            "y": 411,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "SEE ALL >"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496",
                                    "txt": "#FFFFFF",
                                    "txtd": "#FFFFFF",
                                    "txth": "#4BD1A0"
                                },
                                "propertiesSource": {
                                    "fnt": "value",
                                    "txt": "value",
                                    "txtd": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                            "layout": {
                                "width": 132,
                                "height": 47,
                                "x": 94,
                                "y": 186,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "SEE ALL >"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496",
                                        "txt": "#FFFFFF",
                                        "txtd": "#FFFFFF",
                                        "txth": "#4BD1A0"
                                    },
                                    "propertiesSource": {
                                        "fnt": "value",
                                        "txt": "value",
                                        "txtd": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                            }
                        }
                    }],
                    "layout": {
                        "width": 322,
                        "height": 600,
                        "x": 336,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 259,
                            "x": 0,
                            "y": 220,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 258,
                            "height": 93,
                            "x": 41,
                            "y": 242,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "BUY NOW"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#000000",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#000000",
                                    "brdh": "#000000",
                                    "brw": "6",
                                    "fnt": "normal normal normal 25px/1.4em lulo-clean-w01-one-bold",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#000000",
                                    "txth": "#FFFFFF"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 200,
                                "height": 60,
                                "x": 60,
                                "y": 70,
                                "scale": 0.863837598531476,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "BUY NOW"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#000000",
                                        "brdh": "#000000",
                                        "brw": "6",
                                        "fnt": "normal normal normal 25px/1.4em lulo-clean-w01-one-bold",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#000000",
                                        "txth": "#FFFFFF"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "layout": {
                        "width": 323,
                        "height": 600,
                        "x": 778,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Skater Guy .jpg",
                                "uri": "035244_4d1a9f7848364b8fbd015f509366d3f5.jpg",
                                "description": "private/",
                                "width": 600,
                                "height": 1200,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.8
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 200,
                            "x": 0,
                            "y": 479,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Skater Guy .jpg",
                                    "uri": "035244_4d1a9f7848364b8fbd015f509366d3f5.jpg",
                                    "description": "private/",
                                    "width": 600,
                                    "height": 1200,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.8
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 600,
                    "x": 0,
                    "y": 4200,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    },
                    "charas": "design-iv0xnzp9"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 679,
                        "x": 0,
                        "y": 6060,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        },
                        "charas": "design-iv0xnzp8"
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ioin3lnu"
            },
            "preset": {
                "rect": {"width": 324, "height": 160, "x": -0.8, "y": 820},
                "label": "add_preset_columns_services_7",
                "tags": null
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.StripColumnsContainer"]
    },
    "help": {"hide": false, "text": "add_section_info_text_column_services"}
}
