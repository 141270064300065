'use strict'

const gridGalleriesSection = require('../sections/gallery/gridGalleriesSection')
const sliderGalleriesSection = require('../sections/gallery/sliderGalleriesSection')
const threeDGalleriesSection = require('../sections/gallery/3DGalleriesSection')
const fullWidthGalleriesSection = require('../sections/gallery/fullWidthGalleriesSection')
const moreGalleriesSection = require('../sections/gallery/moreGalleriesSection')

const threeDGalleriesSectionVideos = require('../sections/gallery/videos/3DGalleriesSectionVideos')
const sliderGalleriesSectionVideos = require('../sections/gallery/videos/sliderGalleriesSectionVideos')
const fullWidthGalleriesSectionVideos = require('../sections/gallery/videos/fullWidthGalleriesSectionVideos')
const gridGalleriesSectionVideos = require('../sections/gallery/videos/gridGalleriesSectionVideos')
const moreGalleriesSectionVideos = require('../sections/gallery/videos/moreGalleriesSectionVideos')


module.exports = {
    gridGalleriesSection,
    sliderGalleriesSection,
    threeDGalleriesSection,
    fullWidthGalleriesSection,
    moreGalleriesSection,
    threeDGalleriesSectionVideos,
    sliderGalleriesSectionVideos,
    fullWidthGalleriesSectionVideos,
    gridGalleriesSectionVideos,
    moreGalleriesSectionVideos
}
