// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';

const { keyboardShortcuts } = util;
let i = 0;
const contextQueue = [];

function generateDynamicContext() {
  return `${keyboardShortcuts.CONTEXTS.MODAL_PANEL}_${i++}`;
}

export default {
  UNSAFE_componentWillMount() {
    let shortcutsDisable = false;
    if (!keyboardShortcuts.isEnabled()) {
      keyboardShortcuts.enable();
      shortcutsDisable = true;
    }
    contextQueue.push(keyboardShortcuts.getContext());
    this.dynamicKeyboardContext = generateDynamicContext();
    keyboardShortcuts.registerContext(
      this.dynamicKeyboardContext,
      this.getPanelShortcuts(),
    );
    keyboardShortcuts.setContext(this.dynamicKeyboardContext);

    if (shortcutsDisable) {
      keyboardShortcuts.disable();
    }
  },
  componentWillUnmount() {
    let shortcutsDisable = false;
    if (!keyboardShortcuts.isEnabled()) {
      keyboardShortcuts.enable();
      shortcutsDisable = true;
    }

    keyboardShortcuts.unregisterContext(this.dynamicKeyboardContext);

    const originalContext = contextQueue.pop();
    if (originalContext !== this.dynamicKeyboardContext) {
      keyboardShortcuts.setContext(originalContext);
    }

    if (shortcutsDisable) {
      keyboardShortcuts.disable();
    }
  },

  getPanelShortcuts() {
    if (this.props.keyboardShortcuts) {
      if (this.props.shouldExtendDefaultShortcuts) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        return _.assign(
          this.getDefaultShortcuts(),
          this.props.keyboardShortcuts,
        );
      }
      return this.props.keyboardShortcuts;
    }
    return this.getDefaultShortcuts();
  },

  getDefaultShortcuts() {
    let escHandler;
    if (this.props.isBareMode) {
      escHandler = _.noop;
    } else {
      escHandler =
        this.onCancel || this.onEscape || this.close || this.props.close;
    }
    return {
      esc: escHandler,
    };
  },
};
