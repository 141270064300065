import type { Transport } from '@wix/wix-code-collections-api';
import type { Schema } from '@wix/wix-data-schema-types';

import type { FileSystem, FileSystemDescriptor } from 'types/documentServices';

export const getSchemaFilePath = (collectionId: string) =>
  `.schemas/${collectionId}.json`;

export class VFSTransport implements Transport {
  private fileSystem: FileSystem;

  private readAndParseFile = async (descriptor: FileSystemDescriptor) => {
    const str = await this.fileSystem.readFile(descriptor);
    return JSON.parse(str);
  };

  private getSchemaVirtualDescriptor = (collectionId: string) => {
    const path = getSchemaFilePath(collectionId);
    return this.fileSystem.getVirtualDescriptor(path);
  };

  constructor(fileSystem: FileSystem) {
    this.fileSystem = fileSystem;
  }

  loadSchema = async (collectionId: string) => {
    const { getSchemaVirtualDescriptor, readAndParseFile } = this;
    const descriptor = getSchemaVirtualDescriptor(collectionId);
    const schema = await readAndParseFile(descriptor);
    return schema as Schema;
  };

  listSchemas = async () => {
    const { fileSystem, readAndParseFile } = this;
    const root = fileSystem.getRoots().schemas;
    const rootChildren = await fileSystem.getChildren(root);
    const filesDescriptors = rootChildren.filter((child) => !child.directory);
    const schemas = await Promise.all(filesDescriptors.map(readAndParseFile));
    return schemas as Schema[];
  };

  saveSchema = async (collectionId: string, schema: Schema) => {
    const { fileSystem, getSchemaVirtualDescriptor } = this;
    const descriptor = getSchemaVirtualDescriptor(collectionId);
    await fileSystem.writeFile(descriptor, JSON.stringify(schema));
    await fileSystem.flush();
  };

  removeSchema = async (collectionId: string) => {
    const { fileSystem, getSchemaVirtualDescriptor } = this;
    const descriptor = getSchemaVirtualDescriptor(collectionId);
    await fileSystem.deleteFile(descriptor);
    await fileSystem.flush();
  };
}
