'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
module.exports =
    {
        "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
        "hide": false,
        "title": "add_section_label_box",
        "subNavigationTitle": "add_section_label_box",
        "presetTitle": "add_section_label_box",
        "tooltipTitle": "add_section_label_box",
        "automationId": "add-panel-section-containerBoxesSlideShowSection",
        "subNavigationHide": false,
        "rebuildTrigger": "please rebuild",
        "showSectionHeader": true,
        "additionalBehaviours": {
            "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
            "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
            "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
            "iconEnabledForComps": {}
        },
        "props": {
            "image": "addPanelData/sections/containerBoxesSection_en/containerBoxesSection_en.png",
            "imageHover": null,
            "items": [{
                "id": "Box_Container_Boxes_1",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 568,
                        "height": 244,
                        "x": 40,
                        "y": 120,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "rgba(50,50,50,1)",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lh5"
                },
                "preset": {"rect": {"width": 162, "height": 100, "x": 0, "y": 0}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_2",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.CircleArea",
                    "layout": {
                        "width": 244,
                        "height": 244,
                        "x": 688,
                        "y": 120,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#414141",
                                "brw": "6",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.CircleArea"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lh6"
                },
                "preset": {"rect": {"width": 80, "height": 100, "x": 162, "y": 0}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_3",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 244,
                        "height": 244,
                        "x": 1015,
                        "y": 120,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "rgba(50,50,50,1)",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#323232",
                                "brw": "0px",
                                "rd": "10px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lh8"
                },
                "preset": {"rect": {"width": 81, "height": 100, "x": 243, "y": 0}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_4",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 353,
                        "height": 244,
                        "x": 40,
                        "y": 484,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "rgba(29,44,243,1)",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "30px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lha"
                },
                "preset": {"rect": {"width": 100, "height": 90, "x": 0, "y": 100}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_5",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 353,
                        "height": 244,
                        "x": 473,
                        "y": 484,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "#1D2CF3",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#414141",
                                "brw": "0",
                                "rd": "0px 30px 0px 30px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lhr"
                },
                "preset": {"rect": {"width": 117, "height": 90, "x": 100, "y": 100}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_6",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 353,
                        "height": 244,
                        "x": 906,
                        "y": 484,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "#1D2CF3",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#1D2CF3",
                                "brw": "0",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lht"
                },
                "preset": {"rect": {"width": 108, "height": 90, "x": 217, "y": 100}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_7",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 392,
                        "height": 254,
                        "x": 40,
                        "y": 848,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "rgba(162,163,233,1)",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#A2A3E9",
                                "brw": "0",
                                "rd": "0",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.BubbleArea"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lhx"
                },
                "preset": {"rect": {"width": 110, "height": 90, "x": 0, "y": 190}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_8",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 746,
                        "height": 244,
                        "x": 513,
                        "y": 848,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "#A2A3E9",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#A2A3E9",
                                "brw": "0",
                                "rd": "0px 0px 30px 0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lhv"
                },
                "preset": {"rect": {"width": 214, "height": 90, "x": 110, "y": 190}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_9",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 248,
                        "height": 248,
                        "x": 40,
                        "y": 1212,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "rgba(180,90,211,1)",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#B45AD3",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "9.64px 11.49px 0px 0px rgba(213,155,232,0.6)"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lhz"
                },
                "preset": {"rect": {"width": 83, "height": 90, "x": 0, "y": 280}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_10",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 557,
                        "height": 248,
                        "x": 371,
                        "y": 1212,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "1",
                                "bg": "rgba(255,255,255,1)",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#B45AD3",
                                "brw": "8",
                                "rd": "200px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1li2"
                },
                "preset": {"rect": {"width": 158, "height": 90, "x": 83, "y": 280}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_11",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 248,
                        "height": 270,
                        "x": 1011,
                        "y": 1212,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-bg": "1",
                                "bg": "rgba(180,90,211,1)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "bg": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.LeftTriangleArea"
                    },
                    "activeModes": {}
                },
                "preset": {"rect": {"width": 83, "height": 90, "x": 241, "y": 280}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_12",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 244,
                        "height": 244,
                        "x": 40,
                        "y": 1580,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "rgba(255,222,95,1)",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFDE5F",
                                "brw": "0",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.CircleArea"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lic"
                },
                "preset": {"rect": {"width": 83, "height": 90, "x": 0, "y": 370}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_13",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                    "layout": {
                        "width": 334,
                        "height": 366,
                        "x": 0,
                        "y": 1524,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "rgba(255,222,95,1)"},
                            "propertiesSource": {"alpha-bg": "value", "bg": "value"}
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                    },
                    "activeModes": {},
                    "id": "comp-jawnawdq"
                },
                "preset": {"rect": {"width": 83, "height": 90, "x": 83, "y": 370}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_14",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 562,
                        "height": 244,
                        "x": 697,
                        "y": 1580,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "rgba(255,222,95,1)",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#FFDE5F",
                                "brw": "0",
                                "rd": "0px",
                                "shd": "5.66px 5.66px 0px 0px rgba(219,186,59,1)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lka"
                },
                "preset": {"rect": {"width": 158, "height": 90, "x": 166, "y": 370}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_15",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 248,
                        "height": 244,
                        "x": 40,
                        "y": 1944,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "#E43292",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#414141",
                                "brw": "0",
                                "rd": "20px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lii"
                },
                "preset": {"rect": {"width": 83, "height": 90, "x": 0, "y": 460}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_16",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 566,
                        "height": 244,
                        "x": 368,
                        "y": 1944,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-bg": "1", "bg": "rgba(228,50,146,1)"},
                            "propertiesSource": {"alpha-bg": "value", "bg": "value"}
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.SandClockArea"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lif"
                },
                "preset": {"rect": {"width": 158, "height": 90, "x": 83, "y": 460}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_17",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 244,
                        "height": 244,
                        "x": 1015,
                        "y": 1944,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "1",
                                "bg": "rgba(50,50,50,1)",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#E43292",
                                "brw": "10",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1li7"
                },
                "preset": {"rect": {"width": 83, "height": 90, "x": 241, "y": 460}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_18",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 244,
                        "height": 609,
                        "x": 40,
                        "y": 2308,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "rgba(255,203,170,1)",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#324158",
                                "brw": "0px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lio"
                },
                "preset": {"rect": {"width": 83, "height": 180, "x": 0, "y": 550}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_19",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 570,
                        "height": 244,
                        "x": 364,
                        "y": 2308,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "#FFCBAA",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFCBAA",
                                "brw": "0",
                                "rd": "30px 0px 20px 0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lil"
                },
                "preset": {"rect": {"width": 157, "height": 90, "x": 83, "y": 550}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_20",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 244,
                        "height": 244,
                        "x": 1015,
                        "y": 2308,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "#FFCBAA",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFCBAA",
                                "brw": "0",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {}
                },
                "preset": {"rect": {"width": 83, "height": 90, "x": 240, "y": 550}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_21",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 244,
                        "height": 244,
                        "x": 364,
                        "y": 2672,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "#FF8C62",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFCBAA",
                                "brw": "0",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lkn"
                },
                "preset": {"rect": {"width": 80, "height": 90, "x": 83, "y": 640}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_22",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.CircleArea",
                    "layout": {
                        "width": 244,
                        "height": 244,
                        "x": 556,
                        "y": 2672,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "#FF8C62",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#FF8C62",
                                "brw": "0",
                                "shd": "0.00px 6.00px 0px 0px rgba(235,107,61,1)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.CircleArea"
                    },
                    "activeModes": {},
                    "id": "comp-jc4u65k3"
                },
                "preset": {"rect": {"width": 80, "height": 90, "x": 163, "y": 640}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_23",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 244,
                        "height": 244,
                        "x": 1015,
                        "y": 2672,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "#FF8C62",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FF8C62",
                                "brw": "0",
                                "rd": "15px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lke"
                },
                "preset": {"rect": {"width": 84, "height": 90, "x": 243, "y": 640}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_24",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 568,
                        "height": 244,
                        "x": 40,
                        "y": 3036,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "1",
                                "bg": "rgba(255,79,79,1)",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FF4F4F",
                                "brw": "15",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1liv"
                },
                "preset": {"rect": {"width": 182, "height": 90, "x": 0, "y": 730}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_25",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 568,
                        "height": 244,
                        "x": 690,
                        "y": 3036,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "#FF4F4F",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#414141",
                                "brw": "0",
                                "rd": "30px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1liz"
                },
                "preset": {"rect": {"width": 142, "height": 88, "x": 182, "y": 730}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_26",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 244,
                        "height": 244,
                        "x": 40,
                        "y": 3400,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-bg": "1", "bg": "rgba(170,231,192,1)"},
                            "propertiesSource": {"alpha-bg": "value", "bg": "value"}
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.VerticalArrowArea"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lj7"
                },
                "preset": {"rect": {"width": 83, "height": 90, "x": 0, "y": 820}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_27",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 244,
                        "height": 244,
                        "x": 364,
                        "y": 3400,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-bg": "1", "bg": "#AAE7C0"},
                            "propertiesSource": {"alpha-bg": "value", "bg": "value"}
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.VerticalRibbonArea"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lks"
                },
                "preset": {"rect": {"width": 83, "height": 90, "x": 83, "y": 820}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_28",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 570,
                        "height": 244,
                        "x": 688,
                        "y": 3400,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "#AAE7C0",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#AAE7C0",
                                "brw": "0",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lj2"
                },
                "preset": {"rect": {"width": 158, "height": 90, "x": 166, "y": 820}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_29",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 570,
                        "height": 244,
                        "x": 39,
                        "y": 3764,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "#20CE88",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#20CE88",
                                "brw": "0",
                                "rd": "0",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.BubbleAreaRight"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1ll3"
                },
                "preset": {"rect": {"width": 163, "height": 90, "x": 0, "y": 910}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_30",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 244,
                        "height": 244,
                        "x": 692,
                        "y": 3764,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "#20CE88",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#20CE88",
                                "brw": "0",
                                "rd": "0px 0px 40px 40px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1ll7"
                },
                "preset": {"rect": {"width": 83, "height": 90, "x": 163, "y": 910}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_31",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.VerticalRibbonArea",
                    "layout": {
                        "width": 244,
                        "height": 244,
                        "x": 1014,
                        "y": 3764,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-bg": "1", "bg": "#20CE88"},
                            "propertiesSource": {"alpha-bg": "value", "bg": "value"}
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1lkw"
                },
                "preset": {"rect": {"width": 80.5, "height": 90.5, "x": 243.5, "y": 910}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_32",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 465,
                        "height": 244,
                        "x": 39,
                        "y": 4128,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "#E6DECA",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#E6DECA",
                                "brw": "0",
                                "rd": "20px 0px 20px 0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1ljf"
                },
                "preset": {"rect": {"width": 138, "height": 90, "x": 0, "y": 1000}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_33",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 674,
                        "height": 244,
                        "x": 584,
                        "y": 4128,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "bg": "rgba(230,222,202,1)",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#E6DECA",
                                "brw": "0px",
                                "rd": "300px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jc4u7urn"
                },
                "preset": {"rect": {"width": 186, "height": 90, "x": 138, "y": 1000}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_34",
                "structure": {
                    "componentType": "mobile.core.components.Container",
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.RibbonAreaSkin",
                    "layout": {
                        "width": 568,
                        "height": 244,
                        "x": -94,
                        "y": 4492,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "alpha-elm": "1",
                                "alpha-elm2": "0",
                                "bg": "rgba(123,115,111,1)",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#7B736F",
                                "brw": "0",
                                "elm": "#414141",
                                "elm2": "#635A56",
                                "els": "0",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "alpha-elm": "value",
                                "alpha-elm2": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "elm": "value",
                                "elm2": "value",
                                "els": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.RibbonAreaSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jc4uaaxj"
                },
                "preset": {"rect": {"width": 162, "height": 104, "x": 0, "y": 1090}, "tags": null}
            }, {
                "id": "Box_Container_Boxes_35",
                "structure": {
                    "type": "Container",
                    "components": [],
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                    "layout": {
                        "width": 568,
                        "height": 244,
                        "x": 690,
                        "y": 4492,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "mobile.core.components.Container",

                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "1",
                                "bg": "rgba(123,115,111,1)",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#7B736F",
                                "brw": "6",
                                "rd": "0",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "mobile.core.components.Container",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.area.BubbleLeftArea"
                    },
                    "activeModes": {},
                    "id": "comp-jawn1ljj"
                },
                "preset": {"rect": {"width": 162, "height": 104, "x": 162, "y": 1090}, "tags": null}
            }],
            "compTypes": ["mobile.core.components.Container"]
        },
        "teaser": {
            "id": "container_box_section_teaser",
            "hide": false,
            "title": "add_section_teaser_title_box",
            "text": "add_section_teaser_text_box",
            "linkText": "container_box_section_teaser_link",
            "helpId": "9369198c-90a1-4f21-b75d-2089c9759171"
        },
        "help": {"hide": false, "text": "add_section_info_text_box"}
    }
