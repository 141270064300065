:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_wyr3c_7 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none; }
  ._container_wyr3c_7 > * {
    pointer-events: auto; }

._stageSideArea_wyr3c_16 {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column; }
  ._stageSideArea_wyr3c_16._highlighted_wyr3c_23 {
    cursor: pointer; }
  ._stageSideArea_wyr3c_16._left_wyr3c_25 {
    right: 0;
    left: auto; }
