// @ts-nocheck
import * as util from '@/util';
import _ from 'lodash';
import TOP_CONTEXT_SHORTCUTS from './topContextShortcuts';
import EDITOR_CONTEXT_SHORTCUTS from './editorShortcuts';
import PREVIEW_CONTEXT_SHORTCUTS from './previewShortcuts';
import PIN_MODE_SHORTCUTS from './pinModeShortcuts';
import ZOOM_MODE_CONTEXT_SHORTCUTS from './zoomModeShortcuts';
import QUICK_EDIT_CONTEXT_SHORTCUTS from './quickEditShortcuts';
import AI_WRITER_PANEL_CONTEXT_SHORTCUTS from './aiWriterPanelShortcuts';
import IMAGE_CROP_CONTEXT_SHORTCUTS from './imageCropShortcuts';
import * as PAGES_MENU_PANEL_CONTEXT_SHORTCUTS from './pagesMenuPanelShortcuts';
import BASE_EDITOR_SHORTCUTS from './baseEditorShortcuts';
import INTERACTIONS_CONTEXT_SHORTCUTS from './interactionsShortcuts';
import COMPONENT_FOCUS_MODE_CONTEXT_SHORTCUTS from './componentFocusModeShortcuts';
import LIGHTBOX_MODE_CONTEXT_SHORTCUTS from './lightboxModeShortcuts';
import ADD_SECTION_PANEL_CONTEXT_SHORTCUTS from './addSectionPanelShortcuts';
import SWITCH_LAYOUT_PANEL_CONTEXT_SHORTCUTS from './switchLayoutPanelShortcuts';
import MULTILINGUAL_MODE_CONTEXT_SHORTCUTS from './multilingualModeShortcuts';
import MOBILE_EDITOR_CONTEXT_SHORTCUTS from './mobileEditorShortcuts';
import WORKSPACE_MODE_LITE_CONTEXT_SHORTCUTS from './workspaceModeLiteShortcuts';

const { keyboardShortcuts } = util;

function wrapAction(action, beforeShortcutFunc) {
  return function () {
    beforeShortcutFunc();
    return action.apply(null, arguments);
  };
}

function wrapShortcuts(shortcuts, beforeShortcutFunc) {
  return _.mapValues(shortcuts, function (shortcut) {
    return wrapAction(shortcut, beforeShortcutFunc);
  });
}

function extendAndRegisterContexts(editorAPI, beforeShortcutFunc) {
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.TOP_CONTEXT,
    wrapShortcuts(TOP_CONTEXT_SHORTCUTS.shortcuts, beforeShortcutFunc),
    editorAPI,
  );

  const baseEditorCtxt = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.TOP_CONTEXT,
    wrapShortcuts(BASE_EDITOR_SHORTCUTS.shortcuts, beforeShortcutFunc),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.BASE_EDITOR,
    baseEditorCtxt,
    editorAPI,
  );

  const previewCtxt = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.TOP_CONTEXT,
    wrapShortcuts(PREVIEW_CONTEXT_SHORTCUTS.shortcuts, beforeShortcutFunc),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.PREVIEW,
    previewCtxt,
    editorAPI,
  );

  const editorCtxt = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.BASE_EDITOR,
    wrapShortcuts(EDITOR_CONTEXT_SHORTCUTS.shortcuts, beforeShortcutFunc),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.EDITOR,
    editorCtxt,
    editorAPI,
  );

  const pagesCtxt = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.EDITOR,
    wrapShortcuts(
      PAGES_MENU_PANEL_CONTEXT_SHORTCUTS.shortcuts,
      beforeShortcutFunc,
    ),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.PAGES_MENU_PANEL,
    pagesCtxt,
    editorAPI,
  );

  const pinModeCtxt = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.BASE_EDITOR,
    wrapShortcuts(PIN_MODE_SHORTCUTS.shortcuts, beforeShortcutFunc),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.PIN_MODE,
    pinModeCtxt,
    editorAPI,
  );

  const imageCropCtxt = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.TOP_CONTEXT,
    wrapShortcuts(IMAGE_CROP_CONTEXT_SHORTCUTS.shortcuts, beforeShortcutFunc),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.IMAGE_CROP,
    imageCropCtxt,
    editorAPI,
  );

  const zoomModeContext = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.TOP_CONTEXT,
    wrapShortcuts(ZOOM_MODE_CONTEXT_SHORTCUTS.shortcuts, beforeShortcutFunc),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.ZOOM_MODE,
    zoomModeContext,
    editorAPI,
  );

  const quickEditContext = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.TOP_CONTEXT,
    wrapShortcuts(QUICK_EDIT_CONTEXT_SHORTCUTS.shortcuts, beforeShortcutFunc),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.QUICK_EDIT,
    quickEditContext,
    editorAPI,
  );

  const aiWriterContext = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.TOP_CONTEXT,
    wrapShortcuts(
      AI_WRITER_PANEL_CONTEXT_SHORTCUTS.shortcuts,
      beforeShortcutFunc,
    ),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.AI_WRITER_PANEL,
    aiWriterContext,
    editorAPI,
  );

  const interactionsContext = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.EDITOR,
    wrapShortcuts(INTERACTIONS_CONTEXT_SHORTCUTS.shortcuts, beforeShortcutFunc),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.INTERACTIONS,
    interactionsContext,
    editorAPI,
  );

  const componentFocusModeContext = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.EDITOR,
    wrapShortcuts(
      COMPONENT_FOCUS_MODE_CONTEXT_SHORTCUTS.shortcuts,
      beforeShortcutFunc,
    ),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.COMPONENT_FOCUS_MODE,
    componentFocusModeContext,
    editorAPI,
  );

  const lightboxModeContext = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.EDITOR,
    wrapShortcuts(
      LIGHTBOX_MODE_CONTEXT_SHORTCUTS.shortcuts,
      beforeShortcutFunc,
    ),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.LIGHTBOX_MODE,
    lightboxModeContext,
    editorAPI,
  );

  const addSectionPanelContext = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.EDITOR,
    wrapShortcuts(
      ADD_SECTION_PANEL_CONTEXT_SHORTCUTS.shortcuts,
      beforeShortcutFunc,
    ),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.ADD_SECTION_PANEL,
    addSectionPanelContext,
    editorAPI,
  );

  const switchLayoutPanelContext = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.EDITOR,
    wrapShortcuts(
      SWITCH_LAYOUT_PANEL_CONTEXT_SHORTCUTS.shortcuts,
      beforeShortcutFunc,
    ),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.SWITCH_LAYOUT_PANEL,
    switchLayoutPanelContext,
    editorAPI,
  );

  const multilingualModeContext = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.EDITOR,
    wrapShortcuts(
      MULTILINGUAL_MODE_CONTEXT_SHORTCUTS.shortcuts,
      beforeShortcutFunc,
    ),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.MULTILINGUAL_MODE,
    multilingualModeContext,
    editorAPI,
  );

  const mobileEditorContext = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.EDITOR,
    wrapShortcuts(
      MOBILE_EDITOR_CONTEXT_SHORTCUTS.shortcuts,
      beforeShortcutFunc,
    ),
  );

  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.MOBILE_EDITOR,
    mobileEditorContext,
    editorAPI,
  );

  const workspaceModeLiteContext = keyboardShortcuts.extendContext(
    keyboardShortcuts.CONTEXTS.TOP_CONTEXT,
    wrapShortcuts(
      WORKSPACE_MODE_LITE_CONTEXT_SHORTCUTS.shortcuts,
      beforeShortcutFunc,
    ),
  );
  keyboardShortcuts.registerContext(
    keyboardShortcuts.CONTEXTS.WORKSPACE_MODE_LITE,
    workspaceModeLiteContext,
    editorAPI,
  );
}

export default {
  init: _.once(function (editorAPI) {
    TOP_CONTEXT_SHORTCUTS.init(editorAPI);
    PREVIEW_CONTEXT_SHORTCUTS.init(editorAPI);
    BASE_EDITOR_SHORTCUTS.init(editorAPI);
    PIN_MODE_SHORTCUTS.init(editorAPI);
    IMAGE_CROP_CONTEXT_SHORTCUTS.init(editorAPI);
    ZOOM_MODE_CONTEXT_SHORTCUTS.init(editorAPI);
    QUICK_EDIT_CONTEXT_SHORTCUTS.init(editorAPI);
    AI_WRITER_PANEL_CONTEXT_SHORTCUTS.init(editorAPI);
    EDITOR_CONTEXT_SHORTCUTS.init(editorAPI);
    PAGES_MENU_PANEL_CONTEXT_SHORTCUTS.init(editorAPI);
    INTERACTIONS_CONTEXT_SHORTCUTS.init(editorAPI);
    COMPONENT_FOCUS_MODE_CONTEXT_SHORTCUTS.init(editorAPI);
    LIGHTBOX_MODE_CONTEXT_SHORTCUTS.init(editorAPI);
    ADD_SECTION_PANEL_CONTEXT_SHORTCUTS.init(editorAPI);
    SWITCH_LAYOUT_PANEL_CONTEXT_SHORTCUTS.init(editorAPI);
    MULTILINGUAL_MODE_CONTEXT_SHORTCUTS.init(editorAPI);
    MOBILE_EDITOR_CONTEXT_SHORTCUTS.init(editorAPI);
    extendAndRegisterContexts(editorAPI, editorAPI.closeRightClickMenu);
  }),
};
