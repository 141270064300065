import { translate } from '@/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      layout: {
        width: 264,
        height: 402,
        x: 18,
        y: 12,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'ya3gj',
      },
      parent: 'comp-kguvxdnu',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'ya3gj',
        },
        title: '',
        uri: '0005bbdb1cc54537b6ea184c3fae9d1f.jpg',
        description: '',
        width: 1920,
        height: 1280,
        alt: 'Window Plants',
        crop: {
          x: 656,
          y: 0,
          width: 838,
          height: 1280,
          svgId: '909695c1e003409ba70b5561666c7c4d.svg',
        },
        name: 'Window Plants',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'ya3gj',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ya3gj',
        },
      },
      transformations: {
        type: 'TransformData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ya3gj',
        },
      },
      scopedTransformations: {
        'variants-kguvxdo2': {
          type: 'TransformData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'ya3gj',
          },
        },
      },
      style: 'wp2',
    },
    {
      type: 'Container',
      components: [
        {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 130,
            height: 15,
            x: 42,
            y: 35,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          metaData: {
            pageId: 'ya3gj',
          },
          parent: 'comp-kguvxdo6',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'ya3gj',
            },
            text: `<h2 class="font_2" style="font-size:12px; line-height:1.2em; text-align:center"><span style="font-family:futura-lt-w01-light,sans-serif"><span style="color:#FFFFFF"><span style="font-size:12px"><span style="letter-spacing:0.35em">${translate(
              'interactions_preset_home_decor_text_1',
            )}</span></span></span></span></h2>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          props: {
            type: 'WRichTextProperties',
            metaData: {
              schemaVersion: '1.0',
              autoGenerated: false,
              pageId: 'ya3gj',
            },
            brightness: 1,
            packed: true,
            verticalText: false,
          },
          transitions: {
            type: 'TransitionData',
            'timing-function': 'ease-out',
            duration: 0.2,
            delay: 0,
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'ya3gj',
            },
          },
          scopedTransformations: {
            'variants-kguvxdo2': {
              type: 'TransformData',
              hidden: true,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'ya3gj',
              },
            },
          },
          style: 'txtNew',
        },
        {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 186,
            height: 23,
            x: 12,
            y: 57,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          metaData: {
            pageId: 'ya3gj',
          },
          parent: 'comp-kguvxdo6',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'ya3gj',
            },
            text: `<h2 class="font_2" style="font-size:19px; text-align:center"><span style="font-size:19px"><span style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif"><span style="color:#FFFFFF">${translate(
              'interactions_preset_home_decor_text_2',
            )}</span></span></span></h2>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          props: {
            type: 'WRichTextProperties',
            metaData: {
              schemaVersion: '1.0',
              autoGenerated: false,
              pageId: 'ya3gj',
            },
            brightness: 1,
            packed: true,
            verticalText: false,
          },
          transitions: {
            type: 'TransitionData',
            'timing-function': 'ease-out',
            duration: 0.2,
            delay: 0,
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'ya3gj',
            },
          },
          scopedTransformations: {
            'variants-kguvxdo2': {
              type: 'TransformData',
              hidden: true,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'ya3gj',
              },
            },
          },
          style: 'txtNew',
        },
      ],
      skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      layout: {
        width: 210,
        height: 115,
        x: 42,
        y: 350,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'mobile.core.components.Container',
      metaData: {
        pageId: 'ya3gj',
      },
      parent: 'comp-kguvxdnu',
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ya3gj',
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-brd': '1',
            bg: '#000000',
            'boxShadowToggleOn-shd': 'false',
            brd: '#423737',
            brw: '1',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-brd': 'value',
            bg: 'value',
            'boxShadowToggleOn-shd': 'value',
            brd: 'value',
            brw: 'value',
            rd: 'value',
            shd: 'value',
          },
          groups: {},
        },
        componentClassName: 'mobile.core.components.Container',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      },
    },
    {
      type: 'Component',
      skin: 'wixui.skins.Skinless',
      layout: {
        width: 142,
        height: 40,
        x: 78,
        y: 390,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wixui.StylableButton',
      metaData: {
        pageId: 'ya3gj',
      },
      parent: 'comp-kguvxdnu',
      data: {
        type: 'StylableButton',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ya3gj',
        },
        label: translate('interactions_preset_home_decor_link_text'),
        svgId: '820eb164c7ca4456bd04e94ef847042e.svg',
      },
      props: {
        type: 'StylableButtonProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'ya3gj',
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ya3gj',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ya3gj',
        },
      },
      scopedTransformations: {
        'variants-kguvxdo2': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'ya3gj',
          },
          rotate: 0,
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ya3gj',
        },
        style: {
          properties: {
            '$st-css':
              ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #000000}.root:hover{border: 1px solid #000000;background: #000000}.root:hover::label{\n    color: #FFFFFF}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;color: #FFFFFF;font-family: futura-lt-w01-book,sans-serif;letter-spacing: 0em;margin: 0px 4px 0px 0px;display: initial;font-size: 014px}.root::icon{transition: inherit;fill: #FFFFFF;width: 10px;height: 10px;margin: 0px 0px 0px 4px;display: none}.root:hover::icon{fill: #000000}",
          },
          propertiesSource: {
            '$st-css': 'value',
          },
        },
        componentClassName: 'wixui.StylableButton',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wixui.skins.Skinless',
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 297,
    height: 470,
    x: 340,
    y: 15,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'ya3gj',
  },
  parent: 'ya3gj',
  variants: {
    'variants-kguvxdo2': {
      componentId: 'comp-kguvxdnu',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'ya3gj',
      },
    },
  },
  scopedTransformations: {
    'variants-kguvxdo2': {
      type: 'TransformData',
      zLayer: 'BELOW_PINNED',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'ya3gj',
      },
      rotate: 0,
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'ya3gj',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  activeModes: {},
};
