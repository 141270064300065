export default function (
  editorAPI: AnyFixMe,
  compRef: AnyFixMe,
  compGfppData: AnyFixMe,
) {
  const connectAction = compGfppData.presetActions.connect;

  if (!(editorAPI.columns.isStrip(compRef) && connectAction)) {
    return;
  }

  if (editorAPI.columns.isSingleColumnStrip(compRef[0])) {
    const { isApplied } = connectAction;
    const { className } = connectAction;
    connectAction.isApplied = function (
      editorAPI: AnyFixMe,
      compRefs: AnyFixMe,
    ) {
      const columnRef = editorAPI.components.getChildren(compRefs[0]);
      return isApplied(editorAPI, columnRef);
    };
    connectAction.className = function (
      editorAPI: AnyFixMe,
      compRefs: AnyFixMe,
    ) {
      const columnRef = editorAPI.components.getChildren(compRefs[0]);
      return className(editorAPI, columnRef);
    };
  } else {
    delete compGfppData.presetActions.connect;
  }
}
