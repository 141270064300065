import { calculateLevelMap } from './calculateLevelMap';
import { VIEW_MODES } from '../constants';

import type { PageSnapshots, CompSnapshot, InvalidSnapshot } from './types';

const ALLOWABLE_POSITION_DELTA = 1;
const ALLOWABLE_SIZE_DELTA = 1;

const isEqualSnapshot = (
  snapshotA: CompSnapshot,
  snapshotB: CompSnapshot,
): boolean =>
  snapshotA &&
  snapshotB &&
  Math.abs(snapshotA.layout.y - snapshotB.layout.y) <=
    ALLOWABLE_POSITION_DELTA &&
  Math.abs(snapshotA.layout.width - snapshotB.layout.width) <=
    ALLOWABLE_SIZE_DELTA &&
  Math.abs(snapshotA.layout.height - snapshotB.layout.height) <=
    ALLOWABLE_SIZE_DELTA;

function comparePageComponentsExist(
  beforePageSnapshots: PageSnapshots,
  afterPageSnapshots: PageSnapshots,
  removedComponents: Set<string>,
): InvalidSnapshot[] {
  const invalidSnapshot: InvalidSnapshot[] = [];

  VIEW_MODES.forEach((viewMode, viewModeIndex) => {
    const beforeSnapshot = beforePageSnapshots[viewModeIndex].filter(
      ({ id }: CompSnapshot) => !removedComponents.has(id),
    );
    const afterSnapshot = afterPageSnapshots[viewModeIndex];
    const afterSnapshotMap = new Map(afterSnapshot.map((i) => [i.id, i]));

    beforeSnapshot.forEach((compSnapshot: CompSnapshot) => {
      const compId = compSnapshot.id;
      const nextCompSnapshot = afterSnapshotMap.get(compId);

      if (!nextCompSnapshot) {
        invalidSnapshot.push({
          page: compSnapshot.page,
          viewMode,
          id: compId,
          componentType: compSnapshot.componentType,
        });
      }
    });
  });

  return invalidSnapshot;
}

function comparePageComponents(
  beforePageSnapshots: PageSnapshots,
  afterPageSnapshots: PageSnapshots,
  removedComponents: Set<string>,
): InvalidSnapshot[] {
  const invalidSnapshot: InvalidSnapshot[] = [];

  VIEW_MODES.forEach((viewMode, viewModeIndex) => {
    const beforeSnapshot = beforePageSnapshots[viewModeIndex].filter(
      ({ id }: CompSnapshot) => !removedComponents.has(id),
    );
    const afterSnapshot = afterPageSnapshots[viewModeIndex];
    const afterSnapshotMap = new Map(afterSnapshot.map((i) => [i.id, i]));

    const beforeLevelMap = calculateLevelMap(beforeSnapshot);
    const afterLevelMap = calculateLevelMap(afterSnapshot);

    beforeSnapshot.forEach((compSnapshot: CompSnapshot) => {
      const compId = compSnapshot.id;
      const nextCompSnapshot = afterSnapshotMap.get(compId);
      const beforeLevel = beforeLevelMap.get(compId);
      const afterLevel = afterLevelMap.get(compId);

      const isEqualVisibility = nextCompSnapshot;
      const isEqualLayout = isEqualSnapshot(compSnapshot, nextCompSnapshot);
      const isEqualLevel = beforeLevel === afterLevel;

      if (!isEqualVisibility || !isEqualLayout || !isEqualLevel) {
        const issueTypes = {
          visibility: !isEqualVisibility,
          layout: isEqualVisibility && !isEqualLayout,
          level: isEqualVisibility && !isEqualLevel,
        };

        invalidSnapshot.push({
          page: compSnapshot.page,
          viewMode,
          id: compId,
          componentType: compSnapshot.componentType,
          issueTypes,
          before: {
            width: compSnapshot.layout.width,
            height: compSnapshot.layout.height,
            y: compSnapshot.layout.y,
            level: beforeLevel,
          },
          after: nextCompSnapshot
            ? {
                width: nextCompSnapshot.layout.width,
                height: nextCompSnapshot.layout.height,
                y: nextCompSnapshot.layout.y,
                level: afterLevel,
              }
            : null,
        });
      }
    });
  });

  return invalidSnapshot;
}

export function compareSnapshots(
  beforeAllSnapshots: PageSnapshots[],
  afterAllSnapshots: PageSnapshots[],
  removedComponents: Set<string>,
  isFullComparison: boolean,
): InvalidSnapshot[] {
  return beforeAllSnapshots.reduce(
    (acc, beforePageSnapshots, index) => {
      const afterPageSnapshots = afterAllSnapshots[index];

      const pageInvalidSnapshots = isFullComparison
        ? comparePageComponents(
            beforePageSnapshots,
            afterPageSnapshots,
            removedComponents,
          )
        : comparePageComponentsExist(
            beforePageSnapshots,
            afterPageSnapshots,
            removedComponents,
          );

      return [...acc, ...pageInvalidSnapshots];
    },
    <InvalidSnapshot[]>[],
  );
}

export function invalidSnapshotsStatistics(
  invalidSnapshots: InvalidSnapshot[],
) {
  let layout = 0;
  let level = 0;
  let visibility = 0;

  invalidSnapshots.forEach(({ issueTypes }) => {
    if (issueTypes?.layout) {
      layout += 1;
    }

    if (issueTypes?.level) {
      level += 1;
    }

    if (issueTypes?.visibility) {
      visibility += 1;
    }
  });

  return { visibility, layout, level };
}
