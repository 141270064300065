import coreUtilsLib from 'coreUtilsLib';
import { LEGACY_WIX_APPS_NAMES } from '@/constants';

const { blogAppPartNames } = coreUtilsLib;

const blogCompsDisplayNameMap = {
  [blogAppPartNames.CUSTOM_FEED]: 'BLOG_HOVERBOX_CUSTOM_FEED',
  [blogAppPartNames.FEATURED_POSTS]: 'BLOG_HOVERBOX_FEATUREDT_POSTS',
  [blogAppPartNames.POSTS_LIST]: 'BLOG_HOVERBOX_POSTS_GALLERY',
  [blogAppPartNames.POSTS_GALLERY]: 'BLOG_HOVERBOX_POSTS_GALLERY',
  [blogAppPartNames.TICKER]: 'BLOG_HOVERBOX_TICKER',
  [blogAppPartNames.ARCHIVE]: 'BLOG_HOVERBOX_ARCHIVE',
  [blogAppPartNames.TAG_CLOUD]: 'BLOG_HOVERBOX_TAG_CLOUD',
  [blogAppPartNames.SINGLE_POST]: 'BLOG_HOVERBOX_SINGLE_POST',
  [blogAppPartNames.FEED]: 'BLOG_HOVERBOX_FEED',
  [blogAppPartNames.CATEGORIES]: 'BLOG_HOVERBOX_CATEGORIES',
  [blogAppPartNames.RELATED_POSTS]: 'BLOG_HOVER_RELATED_POSTS',
  [blogAppPartNames.HERO_IMAGE]: 'BLOG_HOVERBOX_HERO_IMAGE',
};

const legacyWixAppToDisplayNameMap = {
  [LEGACY_WIX_APPS_NAMES.NEWS_A]: 'component_label_news_old',
  [LEGACY_WIX_APPS_NAMES.NEWS_B]: 'component_label_news_old',
  [LEGACY_WIX_APPS_NAMES.RESTAURANTS_MENUS]: 'component_label_menus_old',
  [LEGACY_WIX_APPS_NAMES.FAQ]: 'component_label_faq_old',
};

export default {
  ...blogCompsDisplayNameMap,
  ...legacyWixAppToDisplayNameMap,
};
