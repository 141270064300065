import React from 'react';
import { cx } from '@/util';
import { CenterDot } from './centerDot';
import { DragHandle } from './dragHandle';
import { SelectedOriginalLayout } from './selectedOriginalLayout';
import type {
  CompRefNode,
  LayoutAndTranformationsMap,
} from '../interactionsEditBoxMapper';
import {
  getTransformString,
  getTransformOriginString,
  getNegativeScaleAndSkewString,
} from '../interactionsEditBoxMapper';
import { isEditBoxRefs } from '../interactionsEditBox';

const selectedBorderWidth = 1;

interface SelectedNonTriggerLayoutProps {
  childTree: CompRefNode;
  layoutAndTranformationsMap: LayoutAndTranformationsMap;
  selectedOffsetX: number;
  selectedOffsetY: number;
  rotateDisabled: boolean;
  skewDisabled: boolean;
  parentsNegativeScaleAndSkewString: string[];
  addHoverClass: (element: HTMLElement) => void;
  renderRegularEditBox: () => React.Component;
  registerDragMouseMoveAction: (
    event: React.MouseEvent,
    isDraggingWithHandle?: boolean,
  ) => void;
}

// eslint-disable-next-line react/display-name
export const SelectedNonTriggerLayout = React.forwardRef<
  HTMLDivElement,
  SelectedNonTriggerLayoutProps
>(
  (
    {
      childTree,
      layoutAndTranformationsMap,
      selectedOffsetX,
      selectedOffsetY,
      rotateDisabled,
      skewDisabled,
      parentsNegativeScaleAndSkewString = [],
      addHoverClass,
      renderRegularEditBox,
      registerDragMouseMoveAction,
    },
    ref,
  ) => {
    if (isEditBoxRefs(ref)) {
      const childData = layoutAndTranformationsMap[childTree.compRef.id];
      const {
        calculatedLayout,
        transformationsObject,
        compTransformations,
        canCompBeDragged,
      } = childData;
      const selectedNonTriggerLayoutStyle: React.CSSProperties = {
        top: calculatedLayout.y - selectedBorderWidth,
        left: calculatedLayout.x - selectedBorderWidth,
        width: calculatedLayout.width,
        height: calculatedLayout.height,
        transform: childData.isSelected
          ? getTransformString(
              transformationsObject,
              calculatedLayout.rotationInDegrees,
              rotateDisabled,
              skewDisabled,
              selectedOffsetX,
              selectedOffsetY,
            )
          : getTransformString(
              transformationsObject,
              calculatedLayout.rotationInDegrees,
              rotateDisabled,
              skewDisabled,
            ),
        transformOrigin: getTransformOriginString(compTransformations),
      };
      const { layoutRef, originalLayoutRef } = ref;

      const negativeScaleAndSkewString = getNegativeScaleAndSkewString(
        transformationsObject,
        skewDisabled,
      );

      return (
        <>
          {childData.isSelected && (
            <SelectedOriginalLayout
              ref={originalLayoutRef}
              selected={childData}
            />
          )}
          <div
            ref={childData.isSelected ? layoutRef : null}
            className={cx('non-trigger-layout', {
              selected: childData.isSelected,
            })}
            style={selectedNonTriggerLayoutStyle}
            onMouseOver={(event) => {
              if (childData.selectable) {
                addHoverClass(event.target as HTMLElement);
              }
              event.stopPropagation();
              event.preventDefault();
            }}
            onMouseLeave={(event) => {
              (event.target as HTMLElement).classList.remove('hovered');
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            {childData.isSelected && (
              <>
                <CenterDot
                  negativeScaleAndSkewString={negativeScaleAndSkewString}
                  parentsNegativeScaleAndSkewString={
                    parentsNegativeScaleAndSkewString
                  }
                />

                {canCompBeDragged && (
                  <DragHandle
                    negativeScaleAndSkewString={negativeScaleAndSkewString}
                    parentsNegativeScaleAndSkewString={
                      parentsNegativeScaleAndSkewString
                    }
                    registerDragMouseMoveAction={registerDragMouseMoveAction}
                  />
                )}
              </>
            )}
            {childTree.children.map((child) => {
              const nextChildData =
                layoutAndTranformationsMap[child.compRef.id];
              if (
                nextChildData.isShownOnlyInVariant &&
                nextChildData.isSelected
              ) {
                return renderRegularEditBox();
              }
              return (
                <SelectedNonTriggerLayout
                  key={child.compRef.id}
                  ref={ref}
                  childTree={child}
                  layoutAndTranformationsMap={layoutAndTranformationsMap}
                  selectedOffsetX={selectedOffsetX}
                  selectedOffsetY={selectedOffsetY}
                  rotateDisabled={rotateDisabled}
                  skewDisabled={skewDisabled}
                  parentsNegativeScaleAndSkewString={[
                    ...parentsNegativeScaleAndSkewString,
                    negativeScaleAndSkewString,
                  ]}
                  renderRegularEditBox={renderRegularEditBox}
                  registerDragMouseMoveAction={registerDragMouseMoveAction}
                  addHoverClass={addHoverClass}
                />
              );
            })}
          </div>
        </>
      );
    }
  },
);
