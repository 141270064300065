import _ from 'lodash';
import React from 'react';
import { translate } from '@/i18n';
import * as helpIds from '@/helpIds';
import * as baseUI from '@/baseUI';
import {
  ActionWithSymbol,
  Composites,
  CustomScroll,
  Divider,
  HorizontalTabs,
  Illustration,
  InfoIcon,
  RadioButtons,
  type RadioButtonsProps,
  RichText,
  TextLabel,
  Tooltip,
  Text,
} from '@wix/wix-base-ui';
import experiment from 'experiment';
import * as symbols from '@wix/santa-editor-symbols';
import { AUTH_METHODS } from './authSettingsDesktopMapper';
import type { Link, SignUpPrivacyNoteType } from 'types/documentServices';
import OpenSignupSettingsDashboardButton from './OpenSignupSettingsDashboardButton';
import {
  LOGIN_FIRST_OPTIONS,
  PRIVACY_NOTE_TYPE,
} from '../../../utils/customSignup';
import type { ConnectionType } from '../../../utils/socialLoginStatusApi';
import SignUpSettingsCommunitySectionOld from './signupSettingsCommunitySectionOld';
import SignUpSettingsCommunitySectionNew from './signupSettingsCommunitySectionNew';

const ActionWithSymbolLabeled = Composites.ActionWithSymbolLabeled;

export const TAB_VALUES = {
  GENERAL: 'general',
  SOCIAL: 'social',
  TERMS_AND_CONDITIONS: 'terms',
};

const SOCIAL_VENDORS = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
} as const;

const SIGNUP_POLICIES = {
  TERMS_OF_USE: 'termsOfUse',
  PRIVACY_POLICY: 'privacyPolicy',
  CODE_OF_CONDUCT: 'codeOfConduct',
} as const;

export type IPolicy = (typeof SIGNUP_POLICIES)[keyof typeof SIGNUP_POLICIES];
export type ISocialVendor =
  (typeof SOCIAL_VENDORS)[keyof typeof SOCIAL_VENDORS];

const sharedTabs = [
  {
    label: 'Member_Login_Settings_General_Tab_Title',
    value: TAB_VALUES.GENERAL,
  },
  { label: 'Member_Login_Settings_Social_Tab_Title', value: TAB_VALUES.SOCIAL },
];
const TABS = {
  [AUTH_METHODS.SIGN_UP]: [
    ...sharedTabs,
    {
      label: 'Member_Login_Settings_Policies_Tab_Title',
      value: TAB_VALUES.TERMS_AND_CONDITIONS,
    },
  ],
  [AUTH_METHODS.SIGN_IN]: sharedTabs,
};

const POLICY_ARTICLES = {
  TERMS_OF_USE: helpIds.SITE_MEMBERS.TERMS_OF_USE_EXAMPLE,
  PRIVACY_POLICY: helpIds.SITE_MEMBERS.PRIVACY_POLICY_EXAMPLE,
};

interface ISignInSettingsDefaultDesktop {
  authMethod: 'signIn' | 'signUp';
  isLoginDialogFirst: () => boolean;
  isAutoApproval: () => boolean;
  isTermsOfUseLinkVisible: () => boolean;
  getSocialLoginStatuses: () => Promise<Record<ConnectionType, boolean>>;
  isSiteSaved: () => boolean;
  isSocialAppInstalled: () => Promise<boolean>;
  hasBrandedApp: () => Promise<boolean>;
  canHavePublicMembers: () => Promise<boolean>;
  getJoinCommunityStatus: () => Promise<{
    joinCommunity: boolean;
    revision?: string;
  }>;
  loginDialogFirst: boolean;
  getLoginFirstOptions: () => RadioButtonsProps['options'];
  privacyNoteType: () => SignUpPrivacyNoteType;
  setPrivacyNoteType: (privacyNoteType: SignUpPrivacyNoteType) => void;
  getPrivacyNoteTypeOptions: () => {
    label: string;
    value: SignUpPrivacyNoteType;
  }[];
  toggleLoginDialogFirst: (toggle: boolean) => void;
  getSiteMembersUrl: () => string;
  translationKeys: { [key: string]: string };
  openDashboard: () => void;
}

interface ISignUpSettingsDefaultDesktop extends ISignInSettingsDefaultDesktop {
  termsOfUseLink: () => Link;
  isPrivacyPolicyLinkVisible: () => boolean;
  privacyPolicyLink: () => Link;
  isCodeOfConductLinkVisible: () => boolean;
  codeOfConductLink: () => Link;
  setSocialLogin: (
    vendor: 'google' | 'facebook',
    val: boolean,
  ) => Promise<Boolean>;
  setJoinCommunityStatus: (
    joinCommunity: boolean,
    revision?: string,
  ) => Promise<{ joinCommunity: boolean; revision?: string }>;
  getAutoApprovalOptions: () => {
    label: string;
    value: boolean;
  }[];
  openHelpPanel: (helpId: string) => void;
  toggleAutoApprove: (toggle: boolean) => void;
  autoApproval: boolean;
  getPolicyLinkData: (policy: string) => Link;
  getLabelForPolicyPageFromLink: (policyLink: Link) => string;
  getLabelForPolicyPageFromId: (policy: string) => string;
  setSignupPoliciesFieldStatus: (policy: IPolicy, value: boolean) => void;
  setSignupPoliciesLink: (policy: IPolicy, link: Link) => void;
  openLinkPanel: (params: any) => void;
}

interface ISignInSettingsDefaultDesktopState {
  loginDialogFirst: boolean;
  autoApproval: boolean;
  termsOfUse: boolean;
  isSocialLoginGoogle: boolean;
  isSocialLoginFacebook: boolean;
  privacyNoteType: SignUpPrivacyNoteType;
  joinCommunityStatus: { joinCommunity: boolean; revision?: string };
  isSocialSite: boolean;
}

interface ISignUpSettingsDefaultDesktopState
  extends ISignInSettingsDefaultDesktopState {
  selectedTab: string;
  termsOfUseLink: Link;
  privacyPolicy: boolean;
  privacyPolicyLink: Link;
  codeOfConduct: boolean;
  codeOfConductLink: Link;
  isSocialLoginGoogle: boolean;
  isSocialLoginFacebook: boolean;
  isSSOMandatory: boolean;
  hasBrandedApp: boolean;
}

class SignupSettingsDefaultDesktop extends React.Component<
  ISignUpSettingsDefaultDesktop,
  ISignUpSettingsDefaultDesktopState
> {
  displayName = 'SignupSettingsDefaultDesktop';
  state = {
    selectedTab: TAB_VALUES.GENERAL,
    loginDialogFirst: this.props.isLoginDialogFirst(),
    autoApproval: this.props.isAutoApproval(),
    termsOfUse: this.props.isTermsOfUseLinkVisible(),
    termsOfUseLink: this.props.termsOfUseLink(),
    privacyPolicy: this.props.isPrivacyPolicyLinkVisible(),
    privacyPolicyLink: this.props.privacyPolicyLink(),
    codeOfConduct: this.props.isCodeOfConductLinkVisible(),
    codeOfConductLink: this.props.codeOfConductLink(),
    isSocialLoginGoogle: true,
    isSocialLoginFacebook: true,
    isSSOMandatory: false,
    privacyNoteType: this.props.privacyNoteType(),
    joinCommunityStatus: { joinCommunity: true, revision: '0' },
    isSocialSite: false,
    hasBrandedApp: false,
  };

  template = {
    [AUTH_METHODS.SIGN_UP]: () => {
      const TAB_VALUES = this.getTabValues();
      const SOCIAL_VENDORS = this.getSocialVendors();
      const SIGNUP_POLICIES = this.getSignupPolicies();

      return (
        <div className="site-members-settings-pages-panel">
          <Composites.RichTextWithIllustration className="top-banner">
            <Illustration className="topbanner-illustration">
              <symbols.symbol name="customSignupThumbnailViewSignupPage" />
            </Illustration>
            <RichText>
              {translate(this.props.translationKeys.defaultTopBanner)}
            </RichText>
          </Composites.RichTextWithIllustration>

          <Divider long={true} />

          <HorizontalTabs
            shouldTranslate={false}
            options={this.getTabs()}
            value={this.state.selectedTab}
            onChange={(selectedTab: string) => this.selectTab(selectedTab)}
          />

          <div className="page-settings-panel-pp-content settings settings-margin">
            {this.state.selectedTab === TAB_VALUES.GENERAL && (
              <CustomScroll
                key="smSettingsTab"
                flex="1"
                heightRelativeToParent="100%"
              >
                <Composites.RadioButtonsLabeled className="settings-section">
                  <InfoIcon
                    shouldTranslate={true}
                    text="NewPages_Panel_Signup_Login_First_Info_Tooltip"
                  />
                  <TextLabel value="Pages_Member_Signup_Display_Settings_Dropdown_Label" />
                  <RadioButtons
                    value={this.props.loginDialogFirst.toString()}
                    options={this.props.getLoginFirstOptions()}
                    onChange={(value) =>
                      this.props.toggleLoginDialogFirst(
                        value === LOGIN_FIRST_OPTIONS[1].value,
                      )
                    }
                  />
                </Composites.RadioButtonsLabeled>
                <Divider long={true} />
                <div className="settings-button-container">
                  <OpenSignupSettingsDashboardButton
                    onClick={this.props.openDashboard}
                  />
                </div>
              </CustomScroll>
            )}

            {this.state.selectedTab === TAB_VALUES.SOCIAL && (
              <CustomScroll
                key="smSocialLoginSettingsSection"
                flex="1"
                heightRelativeToParent="100%"
              >
                <section className="option social-option">
                  <symbols.symbol
                    name="mba_social_panel_google_plus_new"
                    className="social-icon google"
                  />
                  <baseUI.toggle
                    name="switch"
                    value={this.state.isSocialLoginGoogle}
                    onChange={(toggle: boolean) =>
                      this.toggleSocialLogin(SOCIAL_VENDORS.GOOGLE, toggle)
                    }
                    label="Member_Login_Settings_Social_Google"
                  />
                </section>
                <section className="option social-option">
                  <symbols.symbol
                    name="mba_social_panel_facebook"
                    className="social-icon facebook"
                  />
                  <baseUI.toggle
                    name="switch"
                    value={this.state.isSocialLoginFacebook}
                    onChange={(toggle: boolean) =>
                      this.toggleSocialLogin(SOCIAL_VENDORS.FACEBOOK, toggle)
                    }
                    label="Member_Login_Settings_Social_Facebook"
                  />
                </section>
                {this.isSocialDisclaimerEnabled() && (
                  <Composites.RichTextWithIllustration className="disclaimer">
                    <Illustration className="disclaimer-illustration">
                      <symbols.symbol name="symbol-warning" />
                    </Illustration>
                    <RichText type="T02">
                      {this.isBrandedAppsDisclaimerEnabled()
                        ? translate(
                            this.props.translationKeys.brandedAppsDisclaimer,
                          )
                        : translate(this.props.translationKeys.disclaimer)}
                    </RichText>
                  </Composites.RichTextWithIllustration>
                )}
                {this.isSocialSite() &&
                  (this.isPrivacyNoteTypeEnabled() ? (
                    <SignUpSettingsCommunitySectionNew
                      privacyNoteType={this.state.privacyNoteType}
                      privacyNoteTypeOptions={this.props.getPrivacyNoteTypeOptions()}
                      onPrivacyNoteTypeChange={this.setPrivacyNoteType}
                      joinCommunityStatus={
                        this.state.joinCommunityStatus.joinCommunity
                      }
                      shouldShowJoinCommunityCheckbox={this.shouldShowJoinCommunityCheckbox()}
                      onJoinCommunityStatusChange={(joinCommunity) => {
                        this.toggleJoinCommunity(
                          joinCommunity,
                          this.state.joinCommunityStatus.revision,
                        );
                      }}
                    />
                  ) : (
                    <SignUpSettingsCommunitySectionOld
                      joinCommunityStatus={
                        this.state.joinCommunityStatus.joinCommunity
                      }
                      onJoinCommunityStatusChange={(joinCommunity) => {
                        this.toggleJoinCommunity(
                          joinCommunity,
                          this.state.joinCommunityStatus.revision,
                        );
                      }}
                    />
                  ))}
              </CustomScroll>
            )}

            {this.state.selectedTab === TAB_VALUES.TERMS_AND_CONDITIONS &&
              (() => {
                const ARTICLES = this.getArticles();

                return (
                  <CustomScroll
                    key="smSignupPoliciesSection"
                    flex="1"
                    heightRelativeToParent="100%"
                  >
                    <baseUI.toggle
                      name="switch"
                      value={this.state[SIGNUP_POLICIES.TERMS_OF_USE]}
                      onChange={() =>
                        this.toggleEditPoliciesVisibility(
                          SIGNUP_POLICIES.TERMS_OF_USE,
                        )
                      }
                      label="Pages_Member_Signup_Settings_Terms_Of_Use_Toggle_Label"
                      className="option policy-option"
                    />

                    {this.state[SIGNUP_POLICIES.TERMS_OF_USE] && (
                      <div
                        key="ifTermsOfUseVisible"
                        className="policy-link-area"
                      >
                        <Divider long={true} />
                        <ActionWithSymbolLabeled className="policy-link">
                          <InfoIcon
                            shouldTranslate={true}
                            linkText="Pages_Member_Signup_Settings_Policies_Link_Info_Tooltip_Link"
                            onLinkClick={() =>
                              this.props.openHelpPanel(ARTICLES.TERMS_OF_USE)
                            }
                            text="Pages_Member_Signup_Settings_Terms_Of_Use_Info_Tooltip"
                          />
                          <TextLabel
                            value="Pages_Member_Signup_Settings_Policies_Link_Header"
                            shouldTranslate={true}
                          />
                          <ActionWithSymbol
                            action={() =>
                              this.openLinkPanelFor(
                                SIGNUP_POLICIES.TERMS_OF_USE,
                              )
                            }
                          >
                            <TextLabel
                              value={this.getLabelForPolicyPage(
                                SIGNUP_POLICIES.TERMS_OF_USE,
                              )}
                              shouldTranslate={false}
                            />
                          </ActionWithSymbol>
                        </ActionWithSymbolLabeled>
                      </div>
                    )}
                    <Divider long={true} />

                    <baseUI.toggle
                      name="switch"
                      value={this.state[SIGNUP_POLICIES.PRIVACY_POLICY]}
                      onChange={() =>
                        this.toggleEditPoliciesVisibility(
                          SIGNUP_POLICIES.PRIVACY_POLICY,
                        )
                      }
                      label="Pages_Member_Signup_Settings_Privacy_Policy_Toggle_Label"
                      className="option policy-option"
                    />

                    {this.state[SIGNUP_POLICIES.PRIVACY_POLICY] && (
                      <div
                        key="ifPrivacyPolicyVisible"
                        className="policy-link-area"
                      >
                        <Divider long={true} />
                        <ActionWithSymbolLabeled className="policy-link">
                          <InfoIcon
                            shouldTranslate={true}
                            linkText="Pages_Member_Signup_Settings_Policies_Link_Info_Tooltip_Link"
                            onLinkClick={() =>
                              this.props.openHelpPanel(ARTICLES.PRIVACY_POLICY)
                            }
                            text="Pages_Member_Signup_Settings_Policies_Link_Info_Tooltip"
                          />
                          <TextLabel
                            value="Pages_Member_Signup_Settings_Policies_Link_Header"
                            shouldTranslate={true}
                          />
                          <ActionWithSymbol
                            action={() =>
                              this.openLinkPanelFor(
                                SIGNUP_POLICIES.PRIVACY_POLICY,
                              )
                            }
                          >
                            <TextLabel
                              value={this.getLabelForPolicyPage(
                                SIGNUP_POLICIES.PRIVACY_POLICY,
                              )}
                              shouldTranslate={false}
                            />
                          </ActionWithSymbol>
                        </ActionWithSymbolLabeled>
                      </div>
                    )}
                    <Divider long={true} />
                  </CustomScroll>
                );
              })()}

            {this.props.isSiteSaved() && (
              <Composites.RichText
                key="manage-site-members-container"
                className="actions"
              >
                <RichText className="light">
                  {translate(
                    'NewPages_Member_Signup_Default_Top_Members_Area_Notice',
                  )}
                  <a
                    target="_blank"
                    href={this.props.getSiteMembersUrl()}
                  >{` ${translate(
                    'NewPages_Member_Signup_Default_Top_Members_Area_Notice_Link',
                  )}`}</a>
                </RichText>
              </Composites.RichText>
            )}
          </div>
        </div>
      );
    },
    [AUTH_METHODS.SIGN_IN]: () => {
      return (
        <div className="site-members-settings-pages-panel">
          <Composites.RichTextWithIllustration className="top-banner">
            <Illustration>
              <symbols.symbol name="customSignupThumbnailViewSignupPage" />
            </Illustration>
            <RichText>
              {translate(this.props.translationKeys.defaultTopBanner)}
            </RichText>
          </Composites.RichTextWithIllustration>

          <Divider long={true} />

          <HorizontalTabs
            shouldTranslate={false}
            options={this.getTabs()}
            value={this.state.selectedTab}
            onChange={(selectedTab: string) => this.selectTab(selectedTab)}
          />

          <div className="page-settings-panel-pp-content settings settings-margin">
            {this.state.selectedTab === TAB_VALUES.GENERAL && (
              <CustomScroll
                key="smSettingsTab"
                flex="1"
                heightRelativeToParent="100%"
              >
                <Composites.RadioButtonsLabeled className="settings-section">
                  <InfoIcon
                    shouldTranslate={true}
                    text="NewPages_Panel_Signup_Login_First_Info_Tooltip"
                  />
                  <TextLabel value="Pages_Member_Signup_Display_Settings_Dropdown_Label" />
                  <RadioButtons
                    value={this.props.loginDialogFirst.toString()}
                    options={this.props.getLoginFirstOptions()}
                    onChange={(value) =>
                      this.props.toggleLoginDialogFirst(
                        value === LOGIN_FIRST_OPTIONS[1].value,
                      )
                    }
                  />
                </Composites.RadioButtonsLabeled>
                <Divider long={true} />
                <div className="settings-button-container">
                  <OpenSignupSettingsDashboardButton
                    onClick={this.props.openDashboard}
                  />
                </div>
              </CustomScroll>
            )}
            {this.state.selectedTab === TAB_VALUES.SOCIAL && (
              <CustomScroll
                key="smSocialLoginSettingsSection"
                flex="1"
                heightRelativeToParent="100%"
              >
                <section className="option social-option">
                  <symbols.symbol
                    name="mba_social_panel_google_plus_new"
                    className="social-icon google"
                  />
                  <baseUI.toggle
                    name="switch"
                    value={this.state.isSocialLoginGoogle}
                    onChange={(toggle: boolean) =>
                      this.toggleSocialLogin(SOCIAL_VENDORS.GOOGLE, toggle)
                    }
                    label="Member_Login_Settings_Social_Google"
                  />
                </section>
                <section className="option social-option">
                  <symbols.symbol
                    name="mba_social_panel_facebook"
                    className="social-icon facebook"
                  />
                  <baseUI.toggle
                    name="switch"
                    value={this.state.isSocialLoginFacebook}
                    onChange={(toggle: boolean) =>
                      this.toggleSocialLogin(SOCIAL_VENDORS.FACEBOOK, toggle)
                    }
                    label="Member_Login_Settings_Social_Facebook"
                  />
                </section>
              </CustomScroll>
            )}
            {this.props.isSiteSaved() && (
              <Composites.RichText
                key="manage-site-members-container"
                className="actions"
              >
                <RichText className="light">
                  {translate(
                    'NewPages_Member_Signup_Default_Top_Members_Area_Notice',
                  )}
                  <a
                    target="_blank"
                    href={this.props.getSiteMembersUrl()}
                  >{` ${translate(
                    'NewPages_Member_Signup_Default_Top_Members_Area_Notice_Link',
                  )}`}</a>
                </RichText>
              </Composites.RichText>
            )}
          </div>
        </div>
      );
    },
  };

  selectTab(selectedTab: AnyFixMe) {
    this.setState({ selectedTab });
  }

  toggleSocialLogin(vendor: 'google' | 'facebook', toggle: boolean) {
    const stateToToggle =
      vendor === SOCIAL_VENDORS.GOOGLE
        ? 'isSocialLoginGoogle'
        : 'isSocialLoginFacebook';
    // @ts-expect-error it inferred to string for some weird reason.
    this.setState({ [stateToToggle]: toggle }, () =>
      this.props.setSocialLogin(vendor, toggle),
    );
  }

  setPrivacyNoteType = (privacyNoteType: SignUpPrivacyNoteType) => {
    this.setState({ privacyNoteType });
    this.props.setPrivacyNoteType(privacyNoteType);

    if (privacyNoteType === PRIVACY_NOTE_TYPE.NOTE) {
      this.toggleJoinCommunity(true, this.state.joinCommunityStatus.revision);
    }
  };

  toggleJoinCommunity(toggle: AnyFixMe, revision?: string) {
    this.setState({
      joinCommunityStatus: {
        joinCommunity: toggle,
        revision: revision ?? this.state.joinCommunityStatus.revision,
      },
    });

    this.props
      .setJoinCommunityStatus(toggle, revision)
      .then((res) => {
        this.setState({
          joinCommunityStatus: {
            joinCommunity: toggle,
            revision: res.revision,
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  toggleEditPoliciesVisibility(policyType: IPolicy) {
    // @ts-expect-error it inferred wrongly
    this.setState({ [policyType]: !this.state[policyType] }, () => {
      this.props.setSignupPoliciesFieldStatus(
        policyType,
        this.state[policyType],
      );
    });
  }

  getPolicyLink(policy: IPolicy) {
    if ((this.state as AnyFixMe)[`${policy}Page`]) {
      return (this.state as AnyFixMe)[`${policy}Page`];
    }

    if ((this.state as AnyFixMe)[`${policy}Link`]) {
      return this.props.getPolicyLinkData(
        (this.state as AnyFixMe)[`${policy}Link`],
      );
    }

    return null;
  }

  getLabelForPolicyPage(policy: AnyFixMe) {
    if ((this.state as AnyFixMe)[`${policy}Page`]) {
      return this.props.getLabelForPolicyPageFromLink(
        (this.state as AnyFixMe)[`${policy}Page`],
      );
    }
    if ((this.state as AnyFixMe)[`${policy}Link`]) {
      return this.props.getLabelForPolicyPageFromId(
        (this.state as AnyFixMe)[`${policy}Link`],
      );
    }

    return translate('Pages_Member_Signup_Settings_Policies_Link_Label');
  }

  linkPanelCallback(policyType: IPolicy, link: Link) {
    if (_.isObject(link)) {
      // @ts-expect-error it inferred to string for some weird reason.
      this.setState({ [`${policyType}Page`]: link }, () => {
        this.props.setSignupPoliciesLink(policyType, link);
      });
    }
    if (_.isString(link)) {
      // @ts-expect-error it inferred to string for some weird reason.
      this.setState({ [`${policyType}Link`]: link }, () => {
        this.props.setSignupPoliciesLink(
          policyType,
          this.props.getPolicyLinkData(link),
        );
      });
    }
  }

  isSocialSite() {
    return this.state.isSocialSite;
  }

  isPrivacyNoteTypeEnabled() {
    return experiment.isOpen('specs.ident.EnableSignUpPrivacyNoteType');
  }

  isMemberPrivacySettingsEnabled() {
    return experiment.isOpen('specs.ident.EnableMemberPrivacySettings');
  }

  isSocialDisclaimerEnabled() {
    return experiment.isOpen('specs.ident.SiteMembersSocialDisclaimer');
  }

  isBrandedAppsDisclaimerEnabled() {
    return (
      experiment.isOpen('specs.ident.SiteMembersBrandedAppsDisclaimer') &&
      this.state.hasBrandedApp
    );
  }

  shouldShowJoinCommunityCheckbox() {
    return this.state.privacyNoteType === PRIVACY_NOTE_TYPE.CHECKBOX;
  }

  openLinkPanelFor(policy: IPolicy) {
    this.props.openLinkPanel({
      policy,
      visibleSections: {
        PageLink: true,
        AnchorLink: true,
        NoLink: false,
        PhoneLink: false,
        WhatsAppLink: false,
        ExternalLink: false,
        EmailLink: false,
        DocumentLink: false,
        LoginToWixLink: false,
        EdgeAnchorLinks: false,
        PopupLink: false,
      },
      link: this.getPolicyLink(policy),
      pageTabTitle: 'LINK_PANEL_TAB_ANCHOR',
      pageTabPageDropDownTitle: 'PLATFORM_LINK_MENU_PAGE_PAGE_OPTION',
      anchorTabPageDropDownTitle: 'LINK_PANEL_PAGE_DROP_DOWN_PAGELINK_LABEL',
      title: 'Pages_Member_Signup_Settings_Policies_Link_Header',
      callback: (link: AnyFixMe) => this.linkPanelCallback(policy, link),
    });
  }

  getTabs() {
    const tabs = TABS[this.props.authMethod].map((tab) => {
      const disabled =
        this.state.isSSOMandatory &&
        tab.value !== TAB_VALUES.TERMS_AND_CONDITIONS;

      const label = disabled ? (
        <Tooltip
          content="These settings aren't available when SSO is enabled and set as required"
          interactive={true}
        >
          <Text disabled={true}>{tab.label}</Text>
        </Tooltip>
      ) : (
        <Text>{tab.label}</Text>
      );

      return {
        ...tab,
        label,
        disabled,
      };
    });
    return tabs;
  }

  getArticles() {
    return POLICY_ARTICLES;
  }

  getTabValues() {
    return TAB_VALUES;
  }

  getSocialVendors() {
    return SOCIAL_VENDORS;
  }

  getSignupPolicies() {
    return SIGNUP_POLICIES;
  }

  componentDidMount() {
    const isSocialSiteNew = this.props.canHavePublicMembers;
    const isSocialSiteOld = this.props.isSocialAppInstalled;
    const getIsSocialSite = this.isMemberPrivacySettingsEnabled()
      ? isSocialSiteNew
      : isSocialSiteOld;

    getIsSocialSite().then((isSocialSite) => {
      this.props.getJoinCommunityStatus().then((joinCommunityStatus) => {
        this.setState({
          isSocialSite,
          joinCommunityStatus,
        });
      });
    });

    this.props.getSocialLoginStatuses().then((socialLoginStatuses) => {
      const isSSOMandatory =
        socialLoginStatuses.sso &&
        !socialLoginStatuses.google &&
        !socialLoginStatuses.facebook &&
        !socialLoginStatuses.password;

      this.setState({
        isSocialLoginGoogle: socialLoginStatuses.google,
        isSocialLoginFacebook: socialLoginStatuses.facebook,
        isSSOMandatory,
      });

      if (isSSOMandatory) {
        this.setState({
          selectedTab: TAB_VALUES.TERMS_AND_CONDITIONS,
        });
      }
    });

    this.props.hasBrandedApp().then((hasBrandedApp) => {
      this.setState({ hasBrandedApp });
    });
  }

  render() {
    return this.template[this.props.authMethod]();
  }
}

export default SignupSettingsDefaultDesktop;
