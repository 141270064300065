'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_DatePicker",
    "subNavigationTitle": "add_section_label_date_time_category",
    "topTitle": "add_section_title_date_picker",
    "presetTitle": "add_section_heading_date_picker",
    "tooltipTitle": "add_section_label_DatePicker_tooltip_title",
    "automationId": "add-panel-section-datePickerSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/datePickerSection_en/datePickerSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "User_Input_DatePicker_1",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.input.DatePickerDefaultSkin",
                "layout": {
                    "width": 320,
                    "height": 46,
                    "x": 1,
                    "y": 184,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.DatePicker",
                "data": {
                    "type": "DatePicker",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "",
                    "disabledDates": [],
                    "disabledDaysOfWeek": [],
                    "allowPastDates": false,
                    "allowFutureDates": true
                },
                "props": {
                    "type": "DatePickerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "textAlignment": "left",
                    "textPadding": 27,
                    "placeholder": "",
                    "dateFormat": "MM/DD/YYYY",
                    "defaultTextType": "today"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "bg-day-selected": "#8C84FA",
                            "bg-header": "#8C84FA",
                            "bgh": "#FEFAFF",
                            "boxShadowToggleOn-shd": "true",
                            "boxShadowToggleOn-shd-calendar": "false",
                            "brd": "#8C84FA",
                            "brd-calendar": "#8C84FA",
                            "brdd": "#DBDBDB",
                            "brde": "#FF4040",
                            "brdf": "#8C84FA",
                            "brdh": "#8C84FA",
                            "fnt": "normal normal normal 14px/1.4em futura-lt-w01-book",
                            "fntlbl": "normal normal normal 15px/1.4em futura-lt-w01-book",
                            "fnt-family-day": "futura-lt-w01-book",
                            "fnt-family-header": "futura-lt-w01-book",
                            "icn-color": "#8C84FA",
                            "rd-calendar": "0px",
                            "shd": "0.00px 1.00px 4px 0px rgba(230,230,230,0.6)",
                            "shd-calendar": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#5C5C5C",
                            "txtlbl": "#8C84FA",
                            "txtlblrq": "#8C84FA",
                            "txt-day": "#686369",
                            "txt-size-day": "13",
                            "txt-size-header": "17",
                            "txtd": "#CCCCCC"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bg-day-selected": "value",
                            "bg-header": "value",
                            "bgcd": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brd-calendar": "value",
                            "brdd": "value",
                            "brde": "value",
                            "brdf": "value",
                            "brdh": "value",
                            "brw": "value",
                            "brwf": "value",
                            "brwh": "value",
                            "fnt": "value",
                            "fntlbl": "value",
                            "fnt-family-day": "value",
                            "fnt-family-header": "value",
                            "icn-color": "value",
                            "rd": "value",
                            "rd-calendar": "value",
                            "shd": "value",
                            "txt": "value",
                            "txtlbl": "value",
                            "txtlblrq": "value",
                            "txt-day": "value",
                            "txt-size-day": "value",
                            "txt-size-header": "value",
                            "txtd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.DatePicker",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.input.DatePickerDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-iteh0eyp2"
            },
            "preset": {"rect": {"width": 162, "height": 207, "x": 0, "y": 0}}
        }, {
            "id": "User_Input_DatePicker_2",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.input.DatePickerTextBetweenNavSkin",
                "layout": {
                    "width": 320,
                    "height": 46,
                    "x": 326,
                    "y": 184,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.DatePicker",
                "data": {
                    "type": "DatePicker",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "",
                    "disabledDates": [],
                    "disabledDaysOfWeek": [],
                    "allowPastDates": true,
                    "allowFutureDates": true
                },
                "props": {
                    "type": "DatePickerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "required": true,
                    "textAlignment": "left",
                    "textPadding": 14,
                    "placeholder": "",
                    "dateFormat": "MM/DD/YYYY",
                    "defaultTextType": "today"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "bg": "#FFFFFF",
                            "bg-calendar": "#FFFFFF",
                            "bg-day-selected": "#00A6FF",
                            "bg-header": "#FFFFFF",
                            "bgh": "#EAF7FF",
                            "boxShadowToggleOn-shd": "false",
                            "boxShadowToggleOn-shd-calendar": "true",
                            "brd": "#C6E2F7",
                            "brd-calendar": "#FFFFFF",
                            "brdf": "#00A6FF",
                            "brdh": "#C6E2F7",
                            "brw": "1",
                            "brw-calendar": "1",
                            "brwf": "1",
                            "brwh": "1",
                            "fnt": "normal normal normal 16px/1.4em helvetica-w01-light",
                            "fntlbl": "normal normal normal 14px/1.4em helvetica-w01-light",
                            "fnt-family-day": "helvetica-w01-light",
                            "fnt-family-header": "helvetica-w01-light",
                            "icn-color": "#686369",
                            "rd": "6px",
                            "rd-calendar": "6px",
                            "rd-day-selected": "30px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "shd-calendar": "0.00px 0.00px 15px 2px rgba(0,0,0,0.06)",
                            "txt": "#324158",
                            "txtlbl": "#00A6FF",
                            "txtlblrq": "#00A6FF",
                            "txt-day": "#324158",
                            "txt-day-selected": "#FFFFFF",
                            "txt-header": "#00A6FF",
                            "txt-placeholder": "#00A6FF",
                            "txt-size-day": "14",
                            "txt-size-header": "16"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bg-calendar": "value",
                            "bg-day-selected": "value",
                            "bg-header": "value",
                            "bgcd": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brd-calendar": "value",
                            "brdf": "value",
                            "brdh": "value",
                            "brw": "value",
                            "brw-calendar": "value",
                            "brwf": "value",
                            "brwh": "value",
                            "fnt": "value",
                            "fntlbl": "value",
                            "fnt-family-day": "value",
                            "fnt-family-header": "value",
                            "icn-color": "value",
                            "rd": "value",
                            "rd-calendar": "value",
                            "rd-day-selected": "value",
                            "shd": "value",
                            "shd-calendar": "value",
                            "txt": "value",
                            "txtlbl": "value",
                            "txtlblrq": "value",
                            "txt-day": "value",
                            "txt-day-selected": "value",
                            "txt-header": "value",
                            "txt-placeholder": "value",
                            "txt-size-day": "value",
                            "txt-size-header": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.DatePicker",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.input.DatePickerTextBetweenNavSkin"
                },
                "activeModes": {},
                "id": "comp-iteh0eyq3"
            },
            "preset": {"rect": {"width": 162, "height": 207, "x": 162, "y": 0}}
        }, {
            "id": "User_Input_DatePicker_3",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.input.DatePickerTextYearNavSkin",
                "layout": {
                    "width": 320,
                    "height": 43,
                    "x": 2,
                    "y": 599,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.DatePicker",
                "data": {
                    "type": "DatePicker",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "",
                    "disabledDates": [],
                    "disabledDaysOfWeek": [],
                    "allowPastDates": true,
                    "allowFutureDates": true
                },
                "props": {
                    "type": "DatePickerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "textAlignment": "left",
                    "textPadding": 26,
                    "placeholder": "",
                    "dateFormat": "MM/DD/YYYY",
                    "defaultTextType": "today"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bg-header": "0",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "bg": "#4BDB7B",
                            "bg-calendar": "#4BDB7B",
                            "bg-day-selected": "#FFFFFF",
                            "bgd": "#D4D4D4",
                            "bge": "#FF4040",
                            "bgf": "#4BDB7B",
                            "bgh": "#66E891",
                            "boxShadowToggleOn-shd": "false",
                            "boxShadowToggleOn-shd-calendar": "false",
                            "brd": "#66E891",
                            "brdf": "#66E891",
                            "brw": "0",
                            "brw-calendar": "0",
                            "brwd": "0",
                            "brwe": "0",
                            "brwf": "0",
                            "brwh": "0",
                            "fnt": "normal normal normal 15px/1.4em bree-w01-thin-oblique",
                            "fntlbl": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                            "fnt-family-day": "din-next-w01-light",
                            "fnt-family-header": "bree-w01-thin-oblique",
                            "icn-color": "#FFFFFF",
                            "rd": "6px",
                            "rd-day-selected": "6px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "shd-calendar": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#FCFCFC",
                            "txtlbl": "#5EE08A",
                            "txtlblrq": "#5EE08A",
                            "txt-day": "#FFFFFF",
                            "txt-day-selected": "#32BF61",
                            "txt-placeholder": "#FFFFFF",
                            "txt-size-day": "14",
                            "txt-size-header": "18",
                            "txtd": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "alpha-bg-header": "value",
                            "bg": "value",
                            "bg-calendar": "value",
                            "bg-day-selected": "value",
                            "bgcd": "value",
                            "bgd": "value",
                            "bge": "value",
                            "bgf": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdf": "value",
                            "brdh": "value",
                            "brw": "value",
                            "brw-calendar": "value",
                            "brwd": "value",
                            "brwe": "value",
                            "brwf": "value",
                            "brwh": "value",
                            "fnt": "value",
                            "fntlbl": "value",
                            "fnt-family-day": "value",
                            "fnt-family-header": "value",
                            "icn-color": "value",
                            "rd": "value",
                            "rd-day-selected": "value",
                            "shd": "value",
                            "txt": "value",
                            "txtlbl": "value",
                            "txtlblrq": "value",
                            "txt-day": "value",
                            "txt-day-selected": "value",
                            "txt-placeholder": "value",
                            "txt-size-day": "value",
                            "txt-size-header": "value",
                            "txtd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.DatePicker",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.input.DatePickerTextYearNavSkin"
                },
                "activeModes": {},
                "id": "comp-iteh0eyq1"
            },
            "preset": {"rect": {"width": 162, "height": 207, "x": 0, "y": 207}}
        }, {
            "id": "User_Input_DatePicker_4",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.input.DatePickerTextYearNavSkin",
                "layout": {
                    "width": 320,
                    "height": 43,
                    "x": 326,
                    "y": 599,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.DatePicker",
                "data": {
                    "type": "DatePicker",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "",
                    "disabledDates": [],
                    "disabledDaysOfWeek": [],
                    "allowPastDates": true,
                    "allowFutureDates": true
                },
                "props": {
                    "type": "DatePickerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "textAlignment": "center",
                    "textPadding": 52,
                    "placeholder": "",
                    "dateFormat": "MM/DD/YYYY",
                    "defaultTextType": "today"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bg-header": "0",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "bg": "#FFFFFF",
                            "bg-calendar": "#FFFFFF",
                            "bg-day-selected": "#49D6D6",
                            "bgf": "#FFFFFF",
                            "bgh": "#FEFAFF",
                            "boxShadowToggleOn-shd": "true",
                            "boxShadowToggleOn-shd-calendar": "true",
                            "brd": "#67D6D6",
                            "brd-calendar": "#67D6D6",
                            "brdf": "#67D6D6",
                            "brdh": "#49D6D6",
                            "brw": "1",
                            "brw-calendar": "0",
                            "brwf": "1",
                            "brwh": "1",
                            "fnt": "italic normal normal 16px/1.4em georgia",
                            "fntlbl": "italic normal normal 14px/1.4em georgia",
                            "fnt-family-day": "proxima-n-w01-reg",
                            "fnt-family-header": "georgia",
                            "icn-color": "#49D6D6",
                            "rd": "0px",
                            "rd-calendar": "0px",
                            "rd-day-selected": "0px",
                            "shd": "0.00px 2.00px 0px 0px rgba(73,214,214,1)",
                            "shd-calendar": "0.00px 0.00px 0px 1px rgba(103,214,214,1)",
                            "txt": "#49D6D6",
                            "txtlbl": "#49D6D6",
                            "txtlblrq": "#49D6D6",
                            "txt-day": "#000000",
                            "txt-day-selected": "#FCFCFC",
                            "txt-header": "#49D6D6",
                            "txt-placeholder": "#A3E6E6",
                            "txt-size-day": "14",
                            "txt-size-header": "18"
                        },
                        "propertiesSource": {
                            "alpha-bg-header": "value",
                            "bg": "value",
                            "bg-calendar": "value",
                            "bg-day-selected": "value",
                            "bgcd": "value",
                            "bgf": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brd-calendar": "value",
                            "brdf": "value",
                            "brdh": "value",
                            "brw": "value",
                            "brw-calendar": "value",
                            "brwf": "value",
                            "brwh": "value",
                            "fnt": "value",
                            "fntlbl": "value",
                            "fnt-family-day": "value",
                            "fnt-family-header": "value",
                            "icn-color": "value",
                            "rd": "value",
                            "rd-calendar": "value",
                            "rd-day-selected": "value",
                            "shd": "value",
                            "shd-calendar": "value",
                            "txt": "value",
                            "txtlbl": "value",
                            "txtlblrq": "value",
                            "txt-day": "value",
                            "txt-day-selected": "value",
                            "txt-header": "value",
                            "txt-placeholder": "value",
                            "txt-size-day": "value",
                            "txt-size-header": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.DatePicker",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.input.DatePickerTextYearNavSkin"
                },
                "activeModes": {},
                "id": "comp-iteh0eyq"
            },
            "preset": {"rect": {"width": 162, "height": 207, "x": 162, "y": 207}}
        }, {
            "id": "User_Input_DatePicker_5",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.input.DatePickerTextBetweenNavSkin",
                "layout": {
                    "width": 320,
                    "height": 46,
                    "x": 2,
                    "y": 1012,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.DatePicker",
                "data": {
                    "type": "DatePicker",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "Select a Date",
                    "disabledDates": [],
                    "disabledDaysOfWeek": [],
                    "allowPastDates": true,
                    "allowFutureDates": true
                },
                "props": {
                    "type": "DatePickerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "textAlignment": "left",
                    "textPadding": 31,
                    "placeholder": "Select a Date",
                    "dateFormat": "MM/DD/YYYY",
                    "defaultTextType": "placeholder"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "bg-day-selected": "#000000",
                            "bg-header": "#FFFFFF",
                            "boxShadowToggleOn-shd": "true",
                            "boxShadowToggleOn-shd-calendar": "true",
                            "brd": "#000000",
                            "brdf": "#E8E8E8",
                            "brdh": "#FEFAFF",
                            "brw": "0",
                            "brw-calendar": "0",
                            "fnt": "normal normal normal 12px/1.4em lulo-clean-w01-one-bold",
                            "fntlbl": "normal normal normal 10px/1.4em lulo-clean-w01-one-bold",
                            "fnt-family-day": "avenir-lt-w01_35-light1475496",
                            "fnt-family-header": "lulo-clean-w01-one-bold",
                            "icn-color": "#999999",
                            "rd": "6px",
                            "rd-calendar": "6px",
                            "rd-day-selected": "40px",
                            "shd": "0.00px 3.00px 10px 1px rgba(0,0,0,0.08)",
                            "shd-calendar": "0.00px 3.00px 10px 1px rgba(0,0,0,0.08)",
                            "txt": "#000000",
                            "txtlbl": "#000000",
                            "txtlblrq": "#000000",
                            "txt-day-selected": "#FFFFFF",
                            "txt-header": "#000000",
                            "txt-placeholder": "#000000",
                            "txt-size-day": "13",
                            "txt-size-header": "12"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bg-day-selected": "value",
                            "bg-header": "value",
                            "bgcd": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdf": "value",
                            "brdh": "value",
                            "brw": "value",
                            "brw-calendar": "value",
                            "brwf": "value",
                            "brwh": "value",
                            "fnt": "value",
                            "fntlbl": "value",
                            "fnt-family-day": "value",
                            "fnt-family-header": "value",
                            "icn-color": "value",
                            "rd": "value",
                            "rd-calendar": "value",
                            "rd-day-selected": "value",
                            "shd": "value",
                            "shd-calendar": "value",
                            "txt": "value",
                            "txtlbl": "value",
                            "txtlblrq": "value",
                            "txt-day-selected": "value",
                            "txt-header": "value",
                            "txt-placeholder": "value",
                            "txt-size-day": "value",
                            "txt-size-header": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.DatePicker",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.input.DatePickerTextBetweenNavSkin"
                },
                "activeModes": {},
                "id": "comp-iteh0eyq2"
            },
            "preset": {"rect": {"width": 162, "height": 207, "x": 0, "y": 414}}
        }, {
            "id": "User_Input_DatePicker_6",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.input.DatePickerTextBetweenNavSkin",
                "layout": {
                    "width": 320,
                    "height": 46,
                    "x": 326,
                    "y": 1012,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.DatePicker",
                "data": {
                    "type": "DatePicker",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "",
                    "disabledDates": [],
                    "disabledDaysOfWeek": [],
                    "allowPastDates": false,
                    "allowFutureDates": true
                },
                "props": {
                    "type": "DatePickerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "required": true,
                    "textAlignment": "left",
                    "textPadding": 31,
                    "placeholder": "",
                    "dateFormat": "MM/DD/YYYY",
                    "defaultTextType": "today"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "bg-day-selected": "#000000",
                            "bg-header": "#000000",
                            "bgh": "#F2FAF9",
                            "boxShadowToggleOn-shd": "false",
                            "boxShadowToggleOn-shd-calendar": "false",
                            "brd": "#919191",
                            "brd-calendar": "#000000",
                            "brdf": "#000000",
                            "brdh": "#525252",
                            "brw": "2",
                            "brw-calendar": "2",
                            "brwf": "2",
                            "brwh": "2",
                            "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                            "fntlbl": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                            "fnt-family-day": "avenir-lt-w01_35-light1475496",
                            "fnt-family-header": "avenir-lt-w01_35-light1475496",
                            "icn-color": "#CCCCCC",
                            "rd": "0px",
                            "rd-calendar": "0px",
                            "rd-day-selected": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "shd-calendar": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#000000",
                            "txtlbl": "#61615F",
                            "txtlblrq": "#61615F",
                            "txt-day-selected": "#FFFFFF",
                            "txt-header": "#FFFFFF",
                            "txt-placeholder": "#525252",
                            "txt-size-day": "13",
                            "txt-size-header": "16"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bg-day-selected": "value",
                            "bg-header": "value",
                            "bgcd": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brd-calendar": "value",
                            "brdf": "value",
                            "brdh": "value",
                            "brw": "value",
                            "brw-calendar": "value",
                            "brwf": "value",
                            "brwh": "value",
                            "fnt": "value",
                            "fntlbl": "value",
                            "fnt-family-day": "value",
                            "fnt-family-header": "value",
                            "icn-color": "value",
                            "rd": "value",
                            "rd-calendar": "value",
                            "rd-day-selected": "value",
                            "shd": "value",
                            "shd-calendar": "value",
                            "txt": "value",
                            "txtlbl": "value",
                            "txtlblrq": "value",
                            "txt-day-selected": "value",
                            "txt-header": "value",
                            "txt-placeholder": "value",
                            "txt-size-day": "value",
                            "txt-size-header": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.DatePicker",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.input.DatePickerTextBetweenNavSkin"
                },
                "activeModes": {},
                "id": "comp-iteh0eyp"
            },
            "preset": {"rect": {"width": 162, "height": 207, "x": 162, "y": 414}}
        }],
        "compTypes": ["wysiwyg.viewer.components.inputs.DatePicker"]
    },
    "help": {"hide": false, "text": "add_section_info_text_datepicker"}
}
