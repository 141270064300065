import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '@/i18n';
import * as util from '@/util';
import type { LanguageObject } from 'types/documentServices';

// TODO take the type from https://github.com/wix-private/document-management/pull/9858
type LanguageStatus = ReturnType<LanguageObject['original']['get']>['status'];

export interface LanguageRowProps {
  key: string;
  index: number;
  isSelected: boolean;
  flagUrl: string;
  languageName: string;
  languageCode: string;
  isPrimary: boolean;
  published: boolean;
  invisible: boolean;
  onSelect(): void;
  onChange(newStatus: LanguageStatus): void;
}

function template(props: LanguageRowProps) {
  return (
    <div
      onClick={props.onSelect}
      className={`languageRow${props.isSelected ? ' languageRowSelected' : ''}`}
    >
      <div className="languageRowFlag">
        <div
          className={util.cx([
            'flag-icon',
            { 'flag-icon-invisible': props.invisible },
          ])}
        >
          <img src={props.flagUrl} />
          {props.invisible && (
            <symbols.symbol
              name="hiddenPageStatus"
              key="invisiableLanguage-symbol"
            />
          )}
        </div>
      </div>

      <div className="languageRowTextContainer">
        <div>{props.languageName}</div>
        <div key="languageRowLangCode" className="languageRowCode">
          {props.languageCode}
        </div>
      </div>

      {props.isPrimary && (
        <div key="mainLangBadge" className="main-language-indicator">
          {translate('SiteLanguages_Panel_Label_Main')}
        </div>
      )}

      {!props.isPrimary ? (
        <div
          onClick={(e) => e.stopPropagation()}
          key="publishToggle"
          className="languageRowSwitch"
        >
          {!props.published && (
            <Tooltip
              key="hidden-tooltip"
              content="LangMenu_Manager_Panel_Activate_Tooltip"
            >
              <symbols.symbol
                name="quickActionsHide"
                className="hidden-tooltip"
              />
            </Tooltip>
          )}
        </div>
      ) : null}
    </div>
  );
}

const LanguageRow = (props: AnyFixMe) => template(props);

LanguageRow.protoTypes = {
  isPrimary: PropTypes.bool.isRequired,
  published: PropTypes.bool.isRequired,
  allowToggle: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool,
  flagUrl: PropTypes.string.isRequired,
  languageName: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
  invisible: PropTypes.bool,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
};

export default LanguageRow;
