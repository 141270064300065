import constants from '@/constants';

import type {
  CompRef,
  DocumentServicesObject,
  AnchorLink,
  MenuItem,
} from 'types/documentServices';
import type { SectionDescription } from '../types';

type SectionsByPages = Record<string, string[]>;

const createSectionsByPagesList = (
  pagesRefs: CompRef[],
  sections: SectionDescription[][],
): SectionsByPages => {
  const result: SectionsByPages = {};
  pagesRefs.forEach(({ id: pageId }, i) => {
    const sectionIds = sections[i].map((section) => section.ref.id);
    result[pageId] = sectionIds;
  });
  return result;
};

const updateAnchorMenuOnPage = (
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  sectionsByPages: SectionsByPages,
) => {
  const anchorsMenus = documentServices.components.get.byType(
    constants.COMP_TYPES.ANCHORS_MENU,
    pageRef,
  );

  anchorsMenus.forEach((anchorsMenuRef) => {
    const { hiddenAnchorIds } =
      documentServices.components.data.get(anchorsMenuRef);

    Object.keys(sectionsByPages).forEach((pageId) => {
      hiddenAnchorIds[pageId] = [
        ...(hiddenAnchorIds[pageId] || []),
        ...sectionsByPages[pageId],
      ];
    });

    documentServices.components.data.update(anchorsMenuRef, {
      hiddenAnchorIds,
    });
  });
};

export const removeAnchors = (
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
): string[] => {
  const anchors = documentServices.components.get.byType(
    constants.COMP_TYPES.ANCHOR,
    pageRef,
  );

  return anchors.map((anchor) => {
    documentServices.components.remove(anchor);

    return anchor.id;
  });
};

export const clearSectionsFromPageAnchorsMenu = (
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  pageSections: SectionDescription[],
) => {
  const sectionsToHide = createSectionsByPagesList([pageRef], [pageSections]);
  updateAnchorMenuOnPage(documentServices, pageRef, sectionsToHide);
};

export const clearSectionsFromMasterPageAnchorMenu = (
  documentServices: DocumentServicesObject,
  pagesRefs: CompRef[],
  pagesSections: SectionDescription[][],
) => {
  const masterPageId = documentServices.pages.getMasterPageId();
  const masterPageRef = documentServices.components.get.byId(masterPageId);

  const sectionsByPages = createSectionsByPagesList(pagesRefs, pagesSections);
  updateAnchorMenuOnPage(documentServices, masterPageRef, sectionsByPages);
};

export const replaceAnchorsToSectionsOnMenu = (
  documentServices: DocumentServicesObject,
  pagesRefs: CompRef[],
  pagesSections: SectionDescription[][],
) => {
  const getSectionAnchorIdByName = ({
    pageId: pageIdWithHash,
    anchorName,
  }: AnchorLink): string => {
    const pageId = pageIdWithHash.replace('#', '');
    const pageIndex = pagesRefs.findIndex(({ id }) => id === pageId);

    if (pageIndex === -1) {
      return;
    }

    // TODO: fix section name with subset index
    const section = pagesSections[pageIndex].find(
      ({ name }) => name === anchorName,
    );

    if (section === undefined) {
      return;
    }

    let { id: anchorId } = documentServices.components.anchor.get(section.ref);

    if (!anchorId.startsWith('#')) {
      anchorId = `#${anchorId}`;
    }

    return anchorId;
  };

  const replaceMenuItems = (menuId: string, items: MenuItem[]) => {
    items.forEach(({ id: menuItemId, link, items: subItems }) => {
      if (link?.type === 'AnchorLink') {
        documentServices.menu.updateItem(menuId, menuItemId, {
          link: {
            ...link,
            anchorDataId: getSectionAnchorIdByName(link as AnchorLink),
          } as AnchorLink,
        });
      }

      if (subItems?.length) {
        replaceMenuItems(menuId, subItems);
      }
    });
  };

  documentServices.menu.getAll().forEach(({ id: menuId, items }) => {
    replaceMenuItems(menuId, items);
  });
};
