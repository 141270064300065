'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const i18n = require('@/i18n')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_listsandgrids_layouts_listbuilder',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': `addPanelData/sections/listTestimonialsSection_${util.languages.getLanguageCode()}/listTestimonialsSection_${util.languages.getLanguageCode()}.png`,
        'imageHover': null,
        'items': [{
            'id': 'Lists_List_Testimonials_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 544,
                    'height': 488,
                    'x': 64,
                    'y': 41,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti9eax8mq45'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'displayName': i18n.translate('i9eax8rp_6.structure.custom.template.type.displayName'),
                            'version': 0,
                            'baseTypes': [],
                            'fields': [{
                                'displayName': i18n.translate('i9eax8rp_6.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9eax8rp_6.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'Strng_sTxt1',
                                'searchable': false,
                                'displayName': i18n.translate('i9eax8rp_6.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i9eax8rp_6.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'name': 'i942a3t5',
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i9eax8rp_6.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i9eax8rp_6.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i9eax8rp_6.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'name': 'wxRchTxt_sTxt0',
                                'computed': false
                            }, {
                                'name': 'i942e864',
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i9eax8rp_6.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9eax8rp_6.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i9eax8rp_6.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i9eax8rp_6.structure.custom.template.type.fields.6.displayName'),
                                'name': 'i6q9ktqr',
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i9eax8rp_6.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'name': 'i71m0lmz',
                                'type': 'String',
                                'displayName': i18n.translate('i9eax8rp_6.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'name': 'Testimonials_i6q79ixr654'
                        },
                        'views': [{
                            'name': 'TestimonialsView_i6q79ixt655_dup_i6qm3pka342_dup_i6tgyzts51_dup_i70h8xu6191_dup_i7g5xmrd284_dup_i9eax8m0241',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 30, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TestimonialsView_i6q79ixt655_dup_i6qm3pka342_dup_i6tgyzts51_dup_i70h8xu6191_dup_i7g5xmrd284_dup_i9eax8m0241'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TestimonialsView_i6q79ixt655_dup_i6qm3pka342_dup_i6tgyzts51_dup_i70h8xu6191_dup_i7g5xmrd284_dup_i9eax8m0241'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9eax8m0'
                        }, {
                            'name': 'TestimonialsView_i6q79ixt655_dup_i6qm3pka342_dup_i6tgyzts51_dup_i70h8xu6191_dup_i7g5xmrd284_dup_i9eax8m0241',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 30, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TestimonialsView_i6q79ixt655_dup_i6qm3pka342_dup_i6tgyzts51_dup_i70h8xu6191_dup_i7g5xmrd284_dup_i9eax8m0241'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TestimonialsView_i6q79ixt655_dup_i6qm3pka342_dup_i6tgyzts51_dup_i70h8xu6191_dup_i7g5xmrd284_dup_i9eax8m0241'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9eax8m0',
                            'format': 'Mobile'
                        }, {
                            'name': 'TestimonialsView_i6q79ixt655_dup_i6qm3pka342_dup_i6tgyzts51_dup_i70h8xu6191_dup_i7g5xmrd284_dup_i9eax8m0241',
                            'forType': 'Testimonials_i6q79ixr654',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'Left_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'items': [{
                                                                    'id': 'fld_i71m3g0u303',
                                                                    'data': 'i6q9ktqr',
                                                                    'comp': {
                                                                        'name': 'Field',
                                                                        'box-align': 'left',
                                                                        'width': 280,
                                                                        'height': '220',
                                                                        'items': [{
                                                                            'id': 'fld_i71m3g0u303_proxy',
                                                                            'data': 'i6q9ktqr',
                                                                            'comp': {'name': 'Video'},
                                                                            'layout': {'position': 'relative'}
                                                                        }],
                                                                        'spacers': {
                                                                            'before': 0,
                                                                            'after': 0,
                                                                            'xax-before': 0,
                                                                            'xax-after': 0
                                                                        },
                                                                        'hidden': true,
                                                                        'labelPosition': 'none'
                                                                    }
                                                                }],
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                }
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Left_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': [{
                                                                    'id': 'image_1',
                                                                    'data': 'image',
                                                                    'comp': {
                                                                        'name': 'Field',
                                                                        'box-align': 'start',
                                                                        'width': 80,
                                                                        'height': 80,
                                                                        'items': [{
                                                                            'id': 'image_1_proxy',
                                                                            'data': 'image',
                                                                            'comp': {
                                                                                'name': 'Image',
                                                                                'style': 'wp2',
                                                                                'imageMode': 'fitWidth'
                                                                            }
                                                                        }],
                                                                        'spacers': {
                                                                            'before': 0,
                                                                            'after': 10,
                                                                            'xax-before': 0,
                                                                            'xax-after': 0
                                                                        },
                                                                        'heightMode': 'auto',
                                                                        'hidden': false
                                                                    }
                                                                }]
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'fld_i942a3uc103',
                                                            'data': 'i942a3t5',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 260,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i942a3uc103_proxy',
                                                                    'data': 'i942a3t5',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'color': 'color_14',
                                                                        'format': 'mmmm dd, yyyy'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true,
                                                                'labelPosition': 'none'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_i942e87k39',
                                                            'data': 'i942e864',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 260,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i942e87k39_proxy',
                                                                    'data': 'i942e864',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true,
                                                                'labelPosition': 'none'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hfxs75de',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 260,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hfxs75de_proxy',
                                                                    'data': 'wxRchTxt_sTxt0',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 28,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': false,
                                                                'labelPosition': 'none'
                                                            }
                                                        }, {
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 260,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body M',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'underline': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'right'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 10,
                                                                    'after': 0,
                                                                    'xax-before': 0
                                                                },
                                                                'box-align': 'start',
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_hfnxgxqo',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 260,
                                                                'items': [{
                                                                    'id': 'fld_hfnxgxqo_proxy',
                                                                    'data': 'Strng_sTxt1',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body S',
                                                                        'bold': false,
                                                                        'italic': true,
                                                                        'underline': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'right'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_i71m0lo2289',
                                                            'data': 'i71m0lmz',
                                                            'comp': {
                                                                'name': 'Field',
                                                                'width': 280,
                                                                'height': 44,
                                                                'items': [{
                                                                    'id': 'fld_i71m0lo2289_proxy',
                                                                    'data': 'i71m0lmz',
                                                                    'comp': {
                                                                        'name': 'Button2',
                                                                        'style': 'b1',
                                                                        'align': 'center'
                                                                    },
                                                                    'layout': {'position': 'relative'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 36,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'box-align': 'center'
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 200}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 3}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'editorData': {'wasChanged': true, 'originalWidth': 601},
                            'id': 'def_0',
                            'format': 'Mobile'
                        }, {
                            'name': 'TestimonialsView_i6q79ixt655_dup_i6qm3pka342_dup_i6tgyzts51_dup_i70h8xu6191_dup_i7g5xmrd284_dup_i9eax8m0241',
                            'forType': 'Testimonials_i6q79ixr654',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'items': [{
                                                    'id': 'fld_i71m3g0u303',
                                                    'data': 'i6q9ktqr',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'box-align': 'left',
                                                        'width': 330,
                                                        'height': '220',
                                                        'items': [{
                                                            'id': 'fld_i71m3g0u303_proxy',
                                                            'data': 'i6q9ktqr',
                                                            'comp': {'name': 'Video'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 10,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'labelPosition': 'none'
                                                    }
                                                }, {
                                                    'id': 'image_1',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'box-align': 'start',
                                                        'width': 80,
                                                        'height': 80,
                                                        'items': [{
                                                            'id': 'image_1_proxy',
                                                            'data': 'image',
                                                            'comp': {
                                                                'name': 'Image',
                                                                'style': 'wp2',
                                                                'imageMode': 'fitWidth'
                                                            }
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 10,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'heightMode': 'auto',
                                                        'hidden': false
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 20
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'fld_i942a3uc103',
                                                    'data': 'i942a3t5',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i942a3uc103_proxy',
                                                            'data': 'i942a3t5',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mmmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'labelPosition': 'none'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_i942e87k39',
                                                    'data': 'i942e864',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i942e87k39_proxy',
                                                            'data': 'i942e864',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'labelPosition': 'none'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hfxs75de',
                                                    'data': 'wxRchTxt_sTxt0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfxs75de_proxy',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 28,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false,
                                                        'labelPosition': 'none'
                                                    }
                                                }, {
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body M',
                                                                'bold': false,
                                                                'italic': false,
                                                                'underline': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'right'}
                                                        }],
                                                        'spacers': {'before': 10, 'after': 0, 'xax-before': 0},
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'box-align': 'start',
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_hfnxgxqo',
                                                    'data': 'Strng_sTxt1',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hfnxgxqo_proxy',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body S',
                                                                'bold': false,
                                                                'italic': true,
                                                                'underline': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'right'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i71m0lo2289',
                                                    'data': 'i71m0lmz',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'height': '40',
                                                        'items': [{
                                                            'id': 'fld_i71m0lo2289_proxy',
                                                            'data': 'i71m0lmz',
                                                            'comp': {
                                                                'name': 'Button2',
                                                                'style': 'b1',
                                                                'align': 'center'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 36,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'end'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'pack': 'start'
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 200}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '3'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': []
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'editorData': {'wasChanged': true},
                            'id': 'def_0'
                        }],
                        'items': [{
                            'title': i18n.translate('i9eax8rp_6.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_5acb1b3a0f1844f8bea438e1e099d76b.png',
                                'width': 109,
                                'height': 73,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i9eax8rp_6.structure.custom.template.items.0.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9eax8rp_6.structure.custom.template.items.0.wxRchTxt_sTxt0')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i71m0lmz': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6q9ktqr': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i71m0lmz': i18n.translate('i9eax8rp_6.structure.custom.template.items.0.i71m0lmz'),
                            'i942a3t5': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i942e864': i18n.translate('i9eax8rp_6.structure.custom.template.items.0.i942e864')
                        }, {
                            'title': i18n.translate('i9eax8rp_6.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_5acb1b3a0f1844f8bea438e1e099d76b.png',
                                'width': 109,
                                'height': 73,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i9eax8rp_6.structure.custom.template.items.1.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9eax8rp_6.structure.custom.template.items.1.wxRchTxt_sTxt0')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i71m0lmz': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6q9ktqr': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i71m0lmz': i18n.translate('i9eax8rp_6.structure.custom.template.items.1.i71m0lmz'),
                            'i942a3t5': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i942e864': i18n.translate('i9eax8rp_6.structure.custom.template.items.1.i942e864')
                        }, {
                            'title': i18n.translate('i9eax8rp_6.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_5acb1b3a0f1844f8bea438e1e099d76b.png',
                                'width': 109,
                                'height': 73,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i9eax8rp_6.structure.custom.template.items.2.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9eax8rp_6.structure.custom.template.items.2.wxRchTxt_sTxt0')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i71m0lmz': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6q9ktqr': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i71m0lmz': i18n.translate('i9eax8rp_6.structure.custom.template.items.2.i71m0lmz'),
                            'i942a3t5': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i942e864': i18n.translate('i9eax8rp_6.structure.custom.template.items.2.i942e864')
                        }],
                        'displayName': i18n.translate('i9eax8rp_6.structure.custom.template.displayName')
                    }
                },
                'id': 'i9eax8rp_6'
            },
            'preset': {
                'rect': {'width': 162, 'height': 167, 'x': 0, 'y': 0},
                'label': '',
                'tags': null
            }

        }, {
            'id': 'Lists_List_Testimonials_2',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 558,
                    'height': 466,
                    'x': 722,
                    'y': 58,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti9eax8lx42'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'name': 'Testimonials_i6f6hb4u1',
                            'version': 0,
                            'baseTypes': [],
                            'displayName': i18n.translate('i9eax8rp_5.structure.custom.template.type.displayName'),
                            'fields': [{
                                'displayName': i18n.translate('i9eax8rp_5.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9eax8rp_5.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'Strng_sTxt1',
                                'searchable': false,
                                'displayName': i18n.translate('i9eax8rp_5.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i9eax8rp_5.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'name': 'i943k30s',
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i9eax8rp_5.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i9eax8rp_5.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i9eax8rp_5.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'name': 'wxRchTxt_sTxt0',
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i9eax8rp_5.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i9eax8rp_5.structure.custom.template.type.fields.4.defaultValue'),
                                'name': 'i944lfyf',
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i9eax8rp_5.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'name': 'i6f6xlpu',
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i9eax8rp_5.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i9eax8rp_5.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i9eax8rp_5.structure.custom.template.type.fields.7.displayName'),
                                'name': 'i71m6ysl',
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'defaultValue': {
                                    '_type': 'wix:Map',
                                    'i6f6yrp8': {
                                        'protocol': 'http',
                                        '_type': 'wix:ExternalLink',
                                        'target': '_blank',
                                        'address': 'www.wix.com'
                                    }
                                },
                                'displayName': '',
                                'computed': false
                            }]
                        },
                        'views': [{
                            'name': 'TestimonialsView_i6f6hb4w2_dup_i6m5p5li155_dup_i6qm3pc3335_dup_i6tgyzus52_dup_i70h8xv6192_dup_i7g5t86w229_dup_i9eax8l9240',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TestimonialsView_i6f6hb4w2_dup_i6m5p5li155_dup_i6qm3pc3335_dup_i6tgyzus52_dup_i70h8xv6192_dup_i7g5t86w229_dup_i9eax8l9240'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TestimonialsView_i6f6hb4w2_dup_i6m5p5li155_dup_i6qm3pc3335_dup_i6tgyzus52_dup_i70h8xv6192_dup_i7g5t86w229_dup_i9eax8l9240'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9eax8l9'
                        }, {
                            'name': 'TestimonialsView_i6f6hb4w2_dup_i6m5p5li155_dup_i6qm3pc3335_dup_i6tgyzus52_dup_i70h8xv6192_dup_i7g5t86w229_dup_i9eax8l9240',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TestimonialsView_i6f6hb4w2_dup_i6m5p5li155_dup_i6qm3pc3335_dup_i6tgyzus52_dup_i70h8xv6192_dup_i7g5t86w229_dup_i9eax8l9240'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TestimonialsView_i6f6hb4w2_dup_i6m5p5li155_dup_i6qm3pc3335_dup_i6tgyzus52_dup_i70h8xv6192_dup_i7g5t86w229_dup_i9eax8l9240'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i9eax8l9',
                            'format': 'Mobile'
                        }, {
                            'name': 'TestimonialsView_i6f6hb4w2_dup_i6m5p5li155_dup_i6qm3pc3335_dup_i6tgyzus52_dup_i70h8xv6192_dup_i7g5t86w229_dup_i9eax8l9240',
                            'forType': 'Testimonials_i6f6hb4u1',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'Left_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'items': [{
                                                                    'id': 'fld_i71mah27415',
                                                                    'data': 'i6f6xlpu',
                                                                    'comp': {
                                                                        'name': 'Field',
                                                                        'box-align': 'left',
                                                                        'width': 280,
                                                                        'height': '220',
                                                                        'items': [{
                                                                            'id': 'fld_i71mah27415_proxy',
                                                                            'data': 'i6f6xlpu',
                                                                            'comp': {'name': 'Video'},
                                                                            'layout': {'position': 'relative'}
                                                                        }],
                                                                        'spacers': {
                                                                            'before': 0,
                                                                            'after': 27,
                                                                            'xax-before': 0,
                                                                            'xax-after': 12
                                                                        },
                                                                        'hidden': true,
                                                                        'labelPosition': 'none'
                                                                    }
                                                                }],
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                }
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Left_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': [{
                                                                    'id': 'image_1',
                                                                    'data': 'image',
                                                                    'comp': {
                                                                        'name': 'Field',
                                                                        'box-align': 'start',
                                                                        'width': 120,
                                                                        'height': 120,
                                                                        'items': [{
                                                                            'id': 'image_1_proxy',
                                                                            'data': 'image',
                                                                            'comp': {
                                                                                'name': 'Image',
                                                                                'style': 'wp2'
                                                                            }
                                                                        }],
                                                                        'spacers': {
                                                                            'before': 0,
                                                                            'after': 27,
                                                                            'xax-before': 0,
                                                                            'xax-after': 0
                                                                        }
                                                                    }
                                                                }]
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'fld_i943k31v262',
                                                            'data': 'i943k30s',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i943k31v262_proxy',
                                                                    'data': 'i943k30s',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'color': 'color_14',
                                                                        'format': 'mmm dd, yyyy'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true,
                                                                'labelPosition': 'none'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'underline': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0
                                                                },
                                                                'box-align': 'start',
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_hfnxgxqo',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hfnxgxqo_proxy',
                                                                    'data': 'Strng_sTxt1',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Body S',
                                                                        'bold': false,
                                                                        'italic': true,
                                                                        'underline': false
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': false,
                                                                'labelPosition': 'none'
                                                            }
                                                        }, {
                                                            'id': 'fld_hfxs75de',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_hfxs75de_proxy',
                                                                    'data': 'wxRchTxt_sTxt0',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 9,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'height': 109,
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_i944yzga26',
                                                            'data': 'i944lfyf',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_i944yzga26_proxy',
                                                                    'data': 'i944lfyf',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 10,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_i71m6ytz399',
                                                            'data': 'i71m6ysl',
                                                            'comp': {
                                                                'name': 'Field',
                                                                'width': 280,
                                                                'items': [{
                                                                    'id': 'fld_i71m6ytz399_proxy',
                                                                    'data': 'i71m6ysl',
                                                                    'comp': {
                                                                        'name': 'Button2',
                                                                        'style': 'b1',
                                                                        'align': 'center'
                                                                    },
                                                                    'layout': {'position': 'relative'}
                                                                }],
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 20,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true,
                                                                'height': 44,
                                                                'box-align': 'center'
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 200}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 3}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'editorData': {'wasChanged': true, 'originalWidth': 566},
                            'id': 'def_0',
                            'format': 'Mobile'
                        }, {
                            'name': 'TestimonialsView_i6f6hb4w2_dup_i6m5p5li155_dup_i6qm3pc3335_dup_i6tgyzus52_dup_i70h8xv6192_dup_i7g5t86w229_dup_i9eax8l9240',
                            'forType': 'Testimonials_i6f6hb4u1',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'items': [{
                                                    'id': 'fld_i71mah27415',
                                                    'data': 'i6f6xlpu',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'box-align': 'left',
                                                        'width': 330,
                                                        'height': '220',
                                                        'items': [{
                                                            'id': 'fld_i71mah27415_proxy',
                                                            'data': 'i6f6xlpu',
                                                            'comp': {'name': 'Video'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 27,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'labelPosition': 'none'
                                                    }
                                                }, {
                                                    'id': 'image_1',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'box-align': 'start',
                                                        'width': 120,
                                                        'height': 120,
                                                        'items': [{
                                                            'id': 'image_1_proxy',
                                                            'data': 'image',
                                                            'comp': {'name': 'Image', 'style': 'wp2'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 27,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 20
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'fld_i943k31v262',
                                                    'data': 'i943k30s',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i943k31v262_proxy',
                                                            'data': 'i943k30s',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'labelPosition': 'none'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'italic': false,
                                                                'underline': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {'before': 0, 'after': 0, 'xax-before': 0},
                                                        'box-align': 'start',
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_hfnxgxqo',
                                                    'data': 'Strng_sTxt1',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfnxgxqo_proxy',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body S',
                                                                'bold': false,
                                                                'italic': true,
                                                                'underline': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false,
                                                        'labelPosition': 'none'
                                                    }
                                                }, {
                                                    'id': 'fld_hfxs75de',
                                                    'data': 'wxRchTxt_sTxt0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': 164,
                                                        'items': [{
                                                            'id': 'fld_hfxs75de_proxy',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 9,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'height': 109,
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_i944yzga26',
                                                    'data': 'i944lfyf',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i944yzga26_proxy',
                                                            'data': 'i944lfyf',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'color': 'color_14',
                                                                'style': 'Heading S'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 10,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i71m6ytz399',
                                                    'data': 'i71m6ysl',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'items': [{
                                                            'id': 'fld_i71m6ytz399_proxy',
                                                            'data': 'i71m6ysl',
                                                            'comp': {
                                                                'name': 'Button2',
                                                                'style': 'b1',
                                                                'align': 'center'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 20,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'height': '40',
                                                        'box-align': 'left'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'pack': 'start'
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 200}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '3'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': []
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'editorData': {'wasChanged': true},
                            'id': 'def_0'
                        }],
                        'items': [{
                            'title': i18n.translate('i9eax8rp_5.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_5a6f018840b44388b91b4b5706a5e4e6.jpg',
                                'width': 949,
                                'height': 989,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i9eax8rp_5.structure.custom.template.items.0.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9eax8rp_5.structure.custom.template.items.0.wxRchTxt_sTxt0')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6f6yrp8': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                },
                                'i71m6ysl': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6f6xlpu': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i71m6ysl': i18n.translate('i9eax8rp_5.structure.custom.template.items.0.i71m6ysl'),
                            'i943k30s': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i944lfyf': i18n.translate('i9eax8rp_5.structure.custom.template.items.0.i944lfyf')
                        }, {
                            'title': i18n.translate('i9eax8rp_5.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_58e182dbf80f4125bc5873921b74753c.jpg',
                                'width': 949,
                                'height': 989,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i9eax8rp_5.structure.custom.template.items.1.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9eax8rp_5.structure.custom.template.items.1.wxRchTxt_sTxt0')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6f6yrp8': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                },
                                'i71m6ysl': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6f6xlpu': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i71m6ysl': i18n.translate('i9eax8rp_5.structure.custom.template.items.1.i71m6ysl'),
                            'i943k30s': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i944lfyf': i18n.translate('i9eax8rp_5.structure.custom.template.items.1.i944lfyf')
                        }, {
                            'title': i18n.translate('i9eax8rp_5.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_40f316547dc047898fe868ecef9bf054.jpg',
                                'width': 876,
                                'height': 887,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i9eax8rp_5.structure.custom.template.items.2.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i9eax8rp_5.structure.custom.template.items.2.wxRchTxt_sTxt0')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6f6yrp8': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                },
                                'i71m6ysl': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6f6xlpu': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i71m6ysl': i18n.translate('i9eax8rp_5.structure.custom.template.items.2.i71m6ysl'),
                            'i943k30s': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i944lfyf': i18n.translate('i9eax8rp_5.structure.custom.template.items.2.i944lfyf')
                        }],
                        'displayName': i18n.translate('i9eax8rp_5.structure.custom.template.displayName')
                    }
                },
                'id': 'i9eax8rp_5'
            },
            'preset': {
                'rect': {'width': 162, 'height': 167, 'x': 162, 'y': 0},
                'label': '',
                'tags': null
            }
        }],
        'compTypes': ['wixapps.integration.components.AppPart2']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_testimonials'}
}
