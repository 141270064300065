import React from 'react';
import { cx, hoc } from '@/util';
import { createStructuredSelector } from '../selectors';
import {
  _relativeToStructureLayoutSel,
  isIndicatorBottomSel,
} from '../selectors';

interface LayoutPositionIndicatorOwnProps {}

interface LayoutPositionIndicatorStateProps {
  x: number;
  y: number;
  isIndicatorBottom: boolean;
}

interface LayoutPositionIndicatorProps
  extends LayoutPositionIndicatorOwnProps,
    LayoutPositionIndicatorStateProps {}

const LayoutPositionIndicator: React.FC<LayoutPositionIndicatorProps> = (
  props,
) => {
  return (
    <span
      key="dragBoundingStyle"
      data-aid="position-indicator"
      className={cx('edit-box-indicator', 'dragging', {
        'bottom-indicator': props.isIndicatorBottom,
      })}
    >
      x: {props.x}, y: {props.y}
    </span>
  );
};

const layoutPositionIndicatorPropsSel =
  createStructuredSelector<LayoutPositionIndicatorStateProps>({
    x: _relativeToStructureLayoutSel.x,
    y: _relativeToStructureLayoutSel.y,
    isIndicatorBottom: isIndicatorBottomSel,
  });

export default hoc.connect(
  hoc.STORES.MOUSE_OPS,
  layoutPositionIndicatorPropsSel,
)(LayoutPositionIndicator);
