'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.DATA,
    "hide": false,
    "props": {
        "items": [
            {
                "appDefinitionId": "1514b01b-cb08-41b4-8582-0a88551ac769",
                "action": addPanelDataConsts.CLICK_ACTION.DATA.INSTALL_APP_AND_NOTIFY,
                "params": {},
                "biItemName": "admin_pages",
                "title": "adminPages_add_section_adminPages_title",
                "desc": "adminPages_add_section_adminPages_description",
                "tooltip": "adminPages_add_section_adminPages_tooltip_description",
                "image": "addPanelData/sections/addPagesSection/add_admin_page_circle.png",
                "symbol": "plusBig"
            }
        ]
    }
}
