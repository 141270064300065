import type { CompRef } from 'types/documentServices';
import type { EditorApiAgentTool } from '../types';
import type { EditorAPI } from '@/editorAPI';

const createTableLine = (
  ...args: [
    elementType: string,
    sectionId: string,
    sectionName: string,
    elementId: string,
    position: string,
    size: string,
    selectedByUser: boolean | string,
  ]
): string =>
  `${args.map((value) => value.toString().replaceAll(',', '')).join(',')}\n`;

const TABLE_HEADER = createTableLine(
  'Element Type',
  'Section ID',
  'Section Name',
  'Element ID',
  'Position',
  'Size',
  'Selected by User',
);

const createComponentPositionString = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): string => {
  const { x, y } = editorAPI.components.layout.get_position(compRef);
  return `(${x};${y})`;
};

const createComponentSizeString = (editorAPI: EditorAPI, compRef: CompRef) => {
  const { width, height } = editorAPI.components.layout.get_size(compRef);
  return `(${width};${height})`;
};

const createComponentTypeString = (editorAPI: EditorAPI, compRef: CompRef) =>
  editorAPI.components.getType(compRef).split('.').pop();

const pageContextTool: EditorApiAgentTool = {
  name: 'pageContext',
  func: (scope) => {
    const { editorAPI } = scope;
    const selectedComponents: Set<string> = new Set(
      editorAPI.selection.getSelectedComponents().map(({ id }: CompRef) => id),
    );

    const currentPage = editorAPI.pages.getCurrentPage();
    const sectionsOnPage = editorAPI.components.getChildren(currentPage);
    let csvOutput = TABLE_HEADER;
    sectionsOnPage.forEach((section) => {
      const { id: sectionId } = section;
      const sectionName = editorAPI.components.anchor.get(section).name;
      const isSectionSelected = selectedComponents.has(sectionId);
      const componentType = createComponentTypeString(editorAPI, section);

      csvOutput += createTableLine(
        componentType,
        '',
        sectionName,
        sectionId,
        createComponentPositionString(editorAPI, section),
        createComponentSizeString(editorAPI, section),
        isSectionSelected,
      );

      const children = editorAPI.components.getChildren(section, true);
      children.forEach((childRef: CompRef) => {
        const { id: childId } = childRef;
        const componentType = createComponentTypeString(editorAPI, childRef);

        csvOutput += createTableLine(
          componentType,
          sectionId,
          sectionName,
          childId,
          createComponentPositionString(editorAPI, childRef),
          createComponentSizeString(editorAPI, section),
          isSectionSelected || selectedComponents.has(childId),
        );
      });
    });

    return {
      success: true,
      content: `
      \`\`\`csv
      ${csvOutput}
      \`\`\`
      `,
    };
  },
};

export default pageContextTool;
