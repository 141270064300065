// @ts-nocheck
import _ from 'lodash';
import * as textMigrationHandler from './textMigrationHandler';
import * as stateManagement from '@/stateManagement';
import constants from './constants';

import type { TextManager } from 'types/data';

const { isReferredComponent } = stateManagement.components.selectors;

function isOldTextComponent(textComponent, dsRead) {
  const COMPONENT_TYPE = 'wysiwyg.viewer.components.WRichText';
  const compType = dsRead.components.getType(textComponent);
  const compData = dsRead.components.data.get(textComponent);
  return (
    compType === COMPONENT_TYPE &&
    compData &&
    compData.type !== 'StyledText' &&
    compData.type !== 'MediaRichText'
  );
}

function migrateComponentData(
  compData,
  themeFonts,
  themeColors,
  defaultTag,
  defaultClass,
) {
  const migratedText = textMigrationHandler.migrateText(
    compData.text,
    themeFonts,
    themeColors,
    defaultTag,
    defaultClass,
  );

  function isOldDataKey(value, key) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return !_.includes(['id', 'metaData'], key);
  }

  const migratedData = _.omit(compData, isOldDataKey);
  migratedData.type = 'StyledText';
  migratedData.stylesMapId = 'CK_EDITOR_PARAGRAPH_STYLES';
  migratedData.text = migratedText;

  return migratedData;
}

function migrateComponent(textComponent, dsRead, dsActions) {
  let compData = dsRead.components.data.get(textComponent);

  compData = migrateComponentData(
    compData,
    // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
    dsRead.theme.fonts.getAll(),
    dsRead.theme.colors.getAll(),
  );
  //update data item
  dsActions.components.data.update(textComponent, compData, true);
  //update component to the new style
  dsActions.components.style.connectToTheme(textComponent, 'txtNew');
}

function shouldMigrateToTextTheme(editorAPI: EditorAPI, compRef: CompRef) {
  const compStyle = editorAPI.components.style.get(compRef);
  return compStyle && compStyle.skin === constants.WRICH_TEXT_NEW_SKIN;
}

function migrateToTextTheme(
  editorAPI: EditorAPI,
  compRef: CompRef,
  textManager: TextManager,
) {
  if (isReferredComponent(compRef)) return;

  const NEW_THEME_STYLE_ID = 'txtTheme';

  const { text } = editorAPI.components.data.get(compRef);

  if (!text.includes('line-height')) {
    textManager.execCommand('lineHeight', constants.DEFAULT_LINE_HEIGHT, {
      execWithoutFocus: true,
      execWithoutHistory: true,
    });
  }

  if (!text.includes('letter-spacing')) {
    textManager.execCommand('letterSpacing', constants.DEFAULT_LETTER_SPACING, {
      execWithoutFocus: true,
      execWithoutHistory: true,
    });
  }

  // execute style change after line-height change to prevent overlay shift
  const registerOnChange = () => {
    editorAPI.components.style.connectToTheme(compRef, NEW_THEME_STYLE_ID);
    textManager.unRegisterListener(registerOnChange);
  };
  textManager.onDataChange(registerOnChange);
}

export {
  isOldTextComponent,
  migrateComponent,
  shouldMigrateToTextTheme,
  migrateToTextTheme,
};
