// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import * as mediaPanelConsts from '../../mediaManagerPanelConstants/mediaManagerPanelConstants';
import WixMediaBaseSection from './wixMediaSectionBase';

function template() {
  return <WixMediaBaseSection {...this.getProps()} />;
}

class WixMediaVideosSection extends React.Component {
  getProps() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(
      {
        title: 'Media_Panel_Wix_Videos_Section_Label',
        mediaRoot: mediaPanelConsts.PUBLIC_MEDIA_ROOT.VIDEOS,
      },
      this.props,
    );
  }

  render() {
    return template.call(this);
  }
}

export default WixMediaVideosSection;
