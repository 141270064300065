// @ts-nocheck
import React from 'react';
import { translate } from '@/i18n';
import {
  Composites,
  DropDown,
  TextLabel,
  DropDownOption,
} from '@wix/wix-base-ui';

export default class extends React.Component {
  static displayName = 'DropdownCustomization';

  render() {
    return (
      <Composites.DropDownLabeled>
        <TextLabel
          value={translate(this.props.label)}
          shouldTranslate={false}
        />
        <DropDown
          value={this.props.valueLink.value}
          onChange={this.props.valueLink.requestChange}
          shouldTranslate={false}
        >
          {this.props.options.map((option) => (
            <DropDownOption
              key={option}
              value={option.value}
              label={option.label}
              shouldTranslate={this.props.shouldTranslateLabel}
            />
          ))}
        </DropDown>
      </Composites.DropDownLabeled>
    );
  }
}
