import _ from 'lodash';
import constants from '@/constants';
import React from 'react';

import TabsController from './controllers/tabsController/tabsController';
import PaginationController from './controllers/paginationController/paginationController';
import DropDownController, {
  type DropDownControllerOption,
} from './controllers/dropDownController/dropDownController';

import { controls as interactionsControls } from '@/interactions';

import type { InteractionDef } from '@/stateManagement';
import type { CompRef } from 'types/documentServices';
import type { TabDefinition } from './controllers/tabsController/tab/tab';

const { InteractionNavControls } = interactionsControls;

type NavControlsContent =
  | {
      tabs: TabDefinition[];
      type: typeof constants.UI.NAVIGATION_TYPES.TABS;
      translate: boolean;
    }
  | {
      options: DropDownControllerOption[];
      label: string;
      type: typeof constants.UI.NAVIGATION_TYPES.DROPDOWN;
    }
  | {
      label: string;
      type: typeof constants.UI.NAVIGATION_TYPES.PAGINATION;
      translate: boolean;
    }
  | {
      type: typeof constants.UI.NAVIGATION_TYPES.INTERACTION;
    };

interface NavControlsProps {
  content?: NavControlsContent;
  componentUIColor: ValueOf<typeof constants.COMPONENT_UI_COLORS>;
  componentUITheme: ValueOf<typeof constants.COMPONENT_UI_THEMES>;
  style?: React.CSSProperties;
  selectFocusedContainer?: () => void;
  getMousePosition: (event: React.MouseEvent<Element>) => {
    x: number;
    y: number;
    isShiftPressed: boolean;
    isAltKeyPressed: boolean;
    isSpecialKeyPressed: boolean;
  };
  tabIndicationState: AnyFixMe;
  registerMouseMoveAction: Function;
  containerToBeDragged: CompRef;
  canDragContainer: boolean;
  customTabs: string[];
  tabIndicationComponent: React.Component | null;
  onTabClick?: (tab: TabDefinition) => void;
  compInteraction: InteractionDef | null;
  focusedContainer: CompRef;
  onMouseEnterControls?: Function;
  onMouseLeaveControls?: Function;
  onNext: () => void;
  onPrev: () => void;
  getBaseDrag: Function;
  onSelectIndex: (index: number) => void;
}

export default class extends React.Component<NavControlsProps> {
  static displayName = 'navControls';

  onMouseEnter = () => {
    if (_.isFunction(this.props.onMouseEnterControls)) {
      this.props.onMouseEnterControls();
    }
  };

  onMouseLeave = () => {
    if (_.isFunction(this.props.onMouseLeaveControls)) {
      this.props.onMouseLeaveControls();
    }
  };

  onMouseDown = (event: React.MouseEvent<Element>) => {
    if (this.props.canDragContainer) {
      this.props.selectFocusedContainer();
      const initMousePosition = this.props.getMousePosition(event);

      this.props.registerMouseMoveAction(this.props.getBaseDrag(), {
        selectedComp: _.castArray(this.props.containerToBeDragged),
        shouldDragAndCopy: false,
        initMousePosition,
      });
    }
    event.stopPropagation();
  };

  stopEventPropagation = (event: React.MouseEvent<Element>) => {
    event.stopPropagation();
  };

  getPositionClass = () => {
    return this.isPositionTop() ? 'comp-top' : 'comp-bottom';
  };

  isPositionTop = () => {
    return (
      this.props?.style?.top !== constants.UI.NAVIGATION_CONTROLS_POS.BOTTOM
    );
  };

  getCalculatedStyle = () => {
    return {
      left: this.props.style?.left ?? 0,
    };
  };

  render() {
    return (
      <div
        onMouseDown={this.onMouseDown}
        onDoubleClick={this.stopEventPropagation}
        onContextMenu={this.stopEventPropagation}
        onMouseMove={this.stopEventPropagation}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        style={this.getCalculatedStyle()}
        className={`nav-controls ${this.getPositionClass()}`}
      >
        {this.props.content.type === constants.UI.NAVIGATION_TYPES.TABS && (
          <TabsController
            onTabClick={this.props.onTabClick}
            isPositionTop={this.isPositionTop()}
            componentUIColor={this.props.componentUIColor}
            componentUITheme={this.props.componentUITheme}
            tabIndicationState={this.props.tabIndicationState}
            tabIndicationComponent={this.props.tabIndicationComponent}
            {...this.props.content}
            className={this.getPositionClass()}
            customTabs={this.props.customTabs}
          />
        )}
        {this.props.content.type === constants.UI.NAVIGATION_TYPES.DROPDOWN && (
          <DropDownController
            componentUIColor={this.props.componentUIColor}
            componentUITheme={this.props.componentUITheme}
            selectFocusedContainer={this.props.selectFocusedContainer}
            onSelect={this.props.onSelectIndex}
            {...this.props.content}
            className={this.getPositionClass()}
            overwriteWithViewStatesClasses
          />
        )}
        {this.props.content.type ===
          constants.UI.NAVIGATION_TYPES.PAGINATION && (
          <PaginationController
            componentUIColor={this.props.componentUIColor}
            componentUITheme={this.props.componentUITheme}
            selectFocusedContainer={this.props.selectFocusedContainer}
            onNext={this.props.onNext}
            onPrev={this.props.onPrev}
            {...this.props.content}
            className={this.getPositionClass()}
          />
        )}
        {this.props.content.type ===
          constants.UI.NAVIGATION_TYPES.INTERACTION && (
          <InteractionNavControls
            compRef={this.props.focusedContainer}
            componentUIColor={this.props.componentUIColor}
            compInteraction={this.props.compInteraction}
          />
        )}
      </div>
    );
  }
}
