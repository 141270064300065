// @ts-nocheck
import _ from 'lodash';

export default function (styleId, styleValue) {
  if (!styleValue || !styleValue.style || !styleValue.style.properties) {
    return;
  }

  const prop = styleValue.style.properties;
  const propSource = styleValue.style.propertiesSource;

  const previousAlpha = prop['alpha-txt'];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(previousAlpha)) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (_.isUndefined(prop['alpha-iconcolor'])) {
      prop['alpha-iconcolor'] = previousAlpha;
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (_.isUndefined(prop['alpha-bordercolor'])) {
      prop['alpha-bordercolor'] = previousAlpha;
    }
  }

  delete prop['alpha-txt'];
  delete prop['alpha-txts'];

  if (propSource) {
    const previousSource = propSource['alpha-txt'];
    propSource['alpha-iconcolor'] =
      propSource['alpha-iconcolor'] || previousSource;
    propSource['alpha-bordercolor'] =
      propSource['alpha-bordercolor'] || previousSource;
    delete propSource['alpha-txt'];
    delete propSource['alpha-txts'];
  }
}
