'use strict'

module.exports = {
    id: 'Columns_ColumnsContact_4',
    structure: {
        type: 'Container',
        components: [
            {
                type: 'Container',
                components: [
                    {
                        type: 'Component',
                        layout: {
                            width: 489,
                            height: 50,
                            x: 1,
                            y: 104,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<h2 class="font_2" style="font-size: 36px; text-align: center;"><span style="font-size:36px;"><span style="letter-spacing:0.15em"><span style="font-family:futura-lt-w01-book,sans-serif"><span style="color:#FF6161">CONTACT US</span></span></span></span></h2>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            layout: {
                                width: 280,
                                height: 49,
                                x: 20,
                                y: 20,
                                scale: 1.2124674843750003,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<h2 class="font_2" style="font-size: 36px; text-align: center;"><span style="font-size:36px;"><span style="letter-spacing:0.15em"><span style="font-family:futura-lt-w01-book,sans-serif"><span style="color:#FF6161">CONTACT US</span></span></span></span></h2>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    },
                    {
                        type: 'Component',
                        layout: {
                            width: 490,
                            height: 21,
                            x: 0,
                            y: 165,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<p class="font_8" style="font-size: 15px; text-align: center;"><span style="letter-spacing:0.03em;"><span style="font-family:futura-lt-w01-light,sans-serif"><span style="font-size:15px"><span style="color:#000000">500 Terry Francois Street, San Francisco, CA 94158</span></span></span></span></p>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            layout: {
                                width: 321,
                                height: 17,
                                x: 0,
                                y: 73,
                                scale: 0.8,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<p class="font_8" style="font-size: 15px; text-align: center;"><span style="letter-spacing:0.03em;"><span style="font-family:futura-lt-w01-light,sans-serif"><span style="font-size:15px"><span style="color:#000000">500 Terry Francois Street, San Francisco, CA 94158</span></span></span></span></p>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    },
                    {
                        type: 'Component',
                        skin: 'wysiwyg.viewer.skins.line.SolidLine',
                        layout: {
                            width: 40,
                            height: 5,
                            x: 224,
                            y: 210,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.FiveGridLine',
                        props: {
                            type: 'FiveGridLineProperties',
                            metaData: {schemaVersion: '1.0'},
                            fullScreenModeOn: false
                        },
                        style: {
                            type: 'TopLevelStyle',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            style: {
                                groups: {},
                                properties: {'alpha-brd': '1', brd: '#FF6161', lnw: '3'},
                                propertiesSource: {brd: 'value', lnw: 'value'}
                            },
                            componentClassName: 'wysiwyg.viewer.components.FiveGridLine',
                            pageId: '',
                            compId: '',
                            styleType: 'custom',
                            skin: 'wysiwyg.viewer.skins.line.SolidLine'
                        },
                        mobileStructure: {
                            type: 'Component',
                            skin: 'wysiwyg.viewer.skins.line.SolidLine',
                            layout: {
                                width: 42,
                                height: 5,
                                x: 139,
                                y: 105,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.FiveGridLine',
                            props: {
                                type: 'FiveGridLineProperties',
                                metaData: {schemaVersion: '1.0'},
                                fullScreenModeOn: false
                            },
                            style: {
                                type: 'TopLevelStyle',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                style: {
                                    groups: {},
                                    properties: {'alpha-brd': '1', brd: '#FF6161', lnw: '3'},
                                    propertiesSource: {brd: 'value', lnw: 'value'}
                                },
                                componentClassName: 'wysiwyg.viewer.components.FiveGridLine',
                                pageId: '',
                                compId: '',
                                styleType: 'custom',
                                skin: 'wysiwyg.viewer.skins.line.SolidLine'
                            }
                        }
                    }
                ],
                layout: {
                    width: 490,
                    height: 610,
                    x: -90,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.Column',
                props: {type: 'ColumnProperties', metaData: {schemaVersion: '1.0'}, alignment: 50},
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        mediaRef: {
                            type: 'Image',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            title: 'Blue Sofa',
                            uri: '20e5671799c242d8b8faf5edc41c7311.jpg',
                            description: 'public/491df47b-5958-4dc7-9029-37f428755d45/47ec63e8-89c6-4665-b7f6-f5be6da222ff',
                            width: 5000,
                            height: 4125,
                            alt: '',
                            artist: {id: '', name: ''},
                            opacity: 1
                        },
                        color: '#FFFFFE',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                style: 'mc1',
                mobileStructure: {
                    type: 'Container',
                    layout: {
                        width: 320,
                        height: 229,
                        x: 0,
                        y: 0,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: 'wysiwyg.viewer.components.Column',
                    props: {
                        type: 'ColumnProperties',
                        metaData: {schemaVersion: '1.0'},
                        alignment: 50
                    },
                    design: {
                        type: 'MediaContainerDesignData',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        background: {
                            type: 'BackgroundMedia',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            mediaRef: {
                                type: 'Image',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                title: 'Blue Sofa',
                                uri: '20e5671799c242d8b8faf5edc41c7311.jpg',
                                description: 'public/491df47b-5958-4dc7-9029-37f428755d45/47ec63e8-89c6-4665-b7f6-f5be6da222ff',
                                width: 5000,
                                height: 4125,
                                alt: '',
                                artist: {id: '', name: ''},
                                opacity: 1
                            },
                            color: '#FFFFFE',
                            colorOpacity: 1,
                            alignType: 'center',
                            fittingType: 'fill',
                            scrollType: 'none',
                            colorOverlay: '',
                            colorOverlayOpacity: 0
                        }
                    },
                    style: 'mc1'
                }
            },
            {
                type: 'Container',
                components: [
                    {
                        type: 'Component',
                        skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
                        layout: {
                            width: 180,
                            height: 29,
                            x: 154,
                            y: 464,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.LinkBar',
                        data: {
                            type: 'ImageList',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            items: [
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'Black LinkedIn Icon',
                                    uri: '6ea5b4a88f0b4f91945b40499aa0af00.png',
                                    width: 200,
                                    height: 200,
                                    alt: ''
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'Black Facebook Icon',
                                    uri: '0fdef751204647a3bbd7eaa2827ed4f9.png',
                                    description: '',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'http://www.facebook.com/wix',
                                        target: '_blank'
                                    }
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'Black Twitter Icon',
                                    uri: 'c7d035ba85f6486680c2facedecdcf4d.png',
                                    description: '',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'http://www.twitter.com/wix',
                                        target: '_blank'
                                    }
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'Black Google+ Icon',
                                    uri: '05546d0902004bfabba0396e21f33f7d.png',
                                    width: 200,
                                    height: 200,
                                    alt: ''
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    title: 'Black Instagram Icon',
                                    uri: '04b15d1b030049d1b3746f8ef5a72955.png',
                                    description: '',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'https://instagram.com/wix/',
                                        target: '_blank'
                                    }
                                }
                            ]
                        },
                        props: {
                            type: 'LinkBarProperties',
                            metaData: {schemaVersion: '1.0'},
                            gallery: 'social_icons',
                            iconSize: 29,
                            spacing: 9,
                            bgScale: 1,
                            orientation: 'HORIZ'
                        },
                        style: 'lb1',
                        mobileStructure: {
                            type: 'Component',
                            skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
                            layout: {
                                width: 234,
                                height: 36,
                                x: 42,
                                y: 446,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.LinkBar',
                            data: {
                                type: 'ImageList',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                items: [
                                    {
                                        type: 'Image',
                                        metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                        title: 'Black LinkedIn Icon',
                                        uri: '6ea5b4a88f0b4f91945b40499aa0af00.png',
                                        width: 200,
                                        height: 200,
                                        alt: ''
                                    },
                                    {
                                        type: 'Image',
                                        metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                        title: 'Black Facebook Icon',
                                        uri: '0fdef751204647a3bbd7eaa2827ed4f9.png',
                                        description: '',
                                        width: 200,
                                        height: 200,
                                        alt: '',
                                        link: {
                                            type: 'ExternalLink',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            url: 'http://www.facebook.com/wix',
                                            target: '_blank'
                                        }
                                    },
                                    {
                                        type: 'Image',
                                        metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                        title: 'Black Twitter Icon',
                                        uri: 'c7d035ba85f6486680c2facedecdcf4d.png',
                                        description: '',
                                        width: 200,
                                        height: 200,
                                        alt: '',
                                        link: {
                                            type: 'ExternalLink',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            url: 'http://www.twitter.com/wix',
                                            target: '_blank'
                                        }
                                    },
                                    {
                                        type: 'Image',
                                        metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                        title: 'Black Google+ Icon',
                                        uri: '05546d0902004bfabba0396e21f33f7d.png',
                                        width: 200,
                                        height: 200,
                                        alt: ''
                                    },
                                    {
                                        type: 'Image',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        title: 'Black Instagram Icon',
                                        uri: '04b15d1b030049d1b3746f8ef5a72955.png',
                                        description: '',
                                        width: 200,
                                        height: 200,
                                        alt: '',
                                        link: {
                                            type: 'ExternalLink',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            url: 'https://instagram.com/wix/',
                                            target: '_blank'
                                        }
                                    }
                                ]
                            },
                            props: {
                                type: 'LinkBarProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: true},
                                gallery: 'social_icons',
                                iconSize: 35,
                                spacing: 15,
                                bgScale: 1,
                                orientation: 'HORIZ'
                            },
                            style: 'lb1'
                        }
                    }
                ],
                layout: {
                    width: 490,
                    height: 610,
                    x: 580,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.Column',
                props: {type: 'ColumnProperties', metaData: {schemaVersion: '1.0'}, alignment: 50},
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#FF6161',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                style: 'mc1',
                mobileStructure: {
                    type: 'Container',
                    layout: {
                        width: 320,
                        height: 515,
                        x: 0,
                        y: 229,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: 'wysiwyg.viewer.components.Column',
                    props: {
                        type: 'ColumnProperties',
                        metaData: {schemaVersion: '1.0'},
                        alignment: 50
                    },
                    design: {
                        type: 'MediaContainerDesignData',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        background: {
                            type: 'BackgroundMedia',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            color: '#FF6161',
                            colorOpacity: 1,
                            alignType: 'center',
                            fittingType: 'fill',
                            scrollType: 'none',
                            colorOverlay: '',
                            colorOverlayOpacity: 0
                        }
                    },
                    style: 'mc1'
                }
            }
        ],
        layout: {
            width: 980,
            height: 610,
            x: 0,
            y: 2467,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
        props: {
            type: 'StripColumnsContainerProperties',
            metaData: {schemaVersion: '1.0'},
            fullWidth: true,
            columnsMargin: 0,
            frameMargin: 0,
            rowMargin: 0,
            siteMargin: 0
        },
        design: {
            type: 'MediaContainerDesignData',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            background: {
                type: 'BackgroundMedia',
                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                color: '{color_11}',
                colorOpacity: 0,
                alignType: 'center',
                fittingType: 'fill',
                scrollType: 'none',
                colorOverlay: '',
                colorOverlayOpacity: 0
            }
        },
        style: {
            type: 'TopLevelStyle',
            id: 'strc1',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            style: {groups: {}, properties: {}, propertiesSource: {}},
            componentClassName: 'wysiwyg.viewer.components.StripColumnsContainer',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer'
        },
        mobileStructure: {
            type: 'Container',
            layout: {
                width: 320,
                height: 744,
                x: 0,
                y: 3834,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false
            },
            componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
            props: {
                type: 'StripColumnsContainerProperties',
                metaData: {schemaVersion: '1.0'},
                fullWidth: true,
                columnsMargin: 0,
                frameMargin: 0,
                rowMargin: 0,
                siteMargin: 0
            },
            design: {
                type: 'MediaContainerDesignData',
                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                background: {
                    type: 'BackgroundMedia',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    color: '{color_11}',
                    colorOpacity: 0,
                    alignType: 'center',
                    fittingType: 'fill',
                    scrollType: 'none',
                    colorOverlay: '',
                    colorOverlayOpacity: 0
                }
            },
            style: 'strc1'
        },
        activeModes: {},
        id: 'comp-iok163d8'
    },
    preset: {
        rect: {width: 324, height: 162, x: -0.8, y: 473.40000000000003},
        label: 'add_preset_columns_contact_4',
        tags: null
    }
}
