._quickEditCompPanelWrapper_123mk_1 {
  height: 100%;
  background-color: white; }
  ._quickEditCompPanelWrapper_123mk_1 .comp-panel-frame {
    position: relative;
    box-shadow: none;
    top: 0 !important;
    left: 0 !important; }
    ._quickEditCompPanelWrapper_123mk_1 .comp-panel-frame header {
      display: none;
      border-top: none;
      cursor: default; }
      ._quickEditCompPanelWrapper_123mk_1 .comp-panel-frame header .btn-close {
        display: none; }
    ._quickEditCompPanelWrapper_123mk_1 .comp-panel-frame .comp-panel-content {
      height: 100% !important;
      max-height: 100% !important; }
  ._quickEditCompPanelWrapper_123mk_1._fullHeight_123mk_18 .comp-panel-frame {
    height: 100%; }
    ._quickEditCompPanelWrapper_123mk_1._fullHeight_123mk_18 .comp-panel-frame > .custom-scroll {
      height: 100%; }
      ._quickEditCompPanelWrapper_123mk_1._fullHeight_123mk_18 .comp-panel-frame > .custom-scroll .outer-container {
        height: 100%; }
      ._quickEditCompPanelWrapper_123mk_1._fullHeight_123mk_18 .comp-panel-frame > .custom-scroll .inner-container {
        height: 100%; }
        ._quickEditCompPanelWrapper_123mk_1._fullHeight_123mk_18 .comp-panel-frame > .custom-scroll .inner-container .content-wrapper {
          height: 100%; }
