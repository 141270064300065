import { BasePublicApi } from '@/apilib';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { translate } from '@/i18n';

import {
  getSiteBusinessData,
  setSiteBusinessData,
  setSiteBusinessName,
  isBusinessTypeValid,
} from './siteGlobalDataUtil';
import {
  DEFAULT_BUSINESS_TYPE,
  DEFAULT_BUSINESS_NAME,
} from './siteGlobalDataConsts';

import type { Scope } from './siteGlobalDataEntryPoint';
import type { BusinessType } from './siteGlobalDataTypes';

let resolveSiteGlobalDataInitPromise: (value: boolean) => void;
const initSiteGlobalDataPromise = new Promise<boolean>((resolve) => {
  resolveSiteGlobalDataInitPromise = resolve;
});

export async function initializeSiteGlobalData(scope: Scope): Promise<void> {
  const { editorAPI, store } = scope;
  const translatedDefaultBusinessName = translate(DEFAULT_BUSINESS_NAME);

  try {
    const data = await getSiteBusinessData(editorAPI);

    if (data?.businessName) {
      store.setBusinessName(data.businessName);
    } else {
      store.setBusinessName(translatedDefaultBusinessName);
    }

    if (isBusinessTypeValid(data)) {
      const businessType = {
        industryId: data.industryId,
        structureId: data.structureId,
        displayName: data.displayName,
      };
      store.setBusinessType(businessType);
    } else {
      store.setBusinessType(DEFAULT_BUSINESS_TYPE);
    }
  } catch (err) {
    ErrorReporter.captureException(err, {
      tags: { failedToGetSiteBusinessData: true },
    });
    store.setBusinessName(translatedDefaultBusinessName);
    store.setBusinessType(DEFAULT_BUSINESS_TYPE);
  }

  resolveSiteGlobalDataInitPromise(true);
}

async function getIsSiteGlobalDataReadyPromise(): Promise<boolean> {
  return initSiteGlobalDataPromise;
}

function getBusinessType(scope: Scope): BusinessType {
  return scope.store.getBusinessType();
}

function getBusinessName(scope: Scope): string {
  return scope.store.getBusinessName();
}

async function setBusinessType(
  scope: Scope,
  businessType: BusinessType,
): Promise<void> {
  const { editorAPI, store } = scope;

  const newBusinessType = await setSiteBusinessData(editorAPI, businessType);

  if (isBusinessTypeValid(newBusinessType)) {
    store.setBusinessType(newBusinessType);
  }
}

async function setBusinessName(
  scope: Scope,
  businessName: string,
): Promise<void> {
  const { editorAPI, store } = scope;
  await setSiteBusinessName(editorAPI, businessName);

  store.setBusinessName(businessName);
}

export class SiteGlobalDataApi extends BasePublicApi<Scope> {
  getBusinessType = this.bindScope(getBusinessType);
  getBusinessName = this.bindScope(getBusinessName);
  setBusinessType = this.bindScope(setBusinessType);
  setBusinessName = this.bindScope(setBusinessName);
  getIsSiteGlobalDataReadyPromise = this.bindScope(
    getIsSiteGlobalDataReadyPromise,
  );
  isBusinessTypeValid: (value: BusinessType) => boolean = isBusinessTypeValid;
}
