import * as React from 'react';
import { Composites, Button, Text } from '@wix/wix-base-ui';
import { translate } from '@/i18n';

const description = 'Looking to change the header background?';
const buttonLabel = 'Change Background';
interface BackgroundFillControlProps {
  openChangeBackgroundPanel: () => void;
}

const BackgroundFillsControl: React.FC<BackgroundFillControlProps> = ({
  openChangeBackgroundPanel,
}) => {
  return (
    <div className="fills-control-wrapper">
      <Text
        enableEllipsis={false}
        size="small"
        weight="thin"
        skin="secondary"
        shouldTranslate={false}
      >
        {translate(description)}
      </Text>
      <Composites.ButtonLarge>
        <Button
          className="btn-confirm-secondary btn-md"
          onClick={openChangeBackgroundPanel}
          shouldTranslate={false}
        >
          {translate(buttonLabel)}
        </Button>
      </Composites.ButtonLarge>
    </div>
  );
};

BackgroundFillsControl.displayName = 'backgroundFillsControl';

export default BackgroundFillsControl;
