import {
  addInjectionByTypeToInjectableMap,
  convertContentTagPropKeyToCaasPropKey,
  getContentTagLastPart,
  getListItemNumber,
  isButtonContentType,
  isImageContentType,
  isListItemTag,
  isSlideShowSlide,
  isTextContentType,
  removeRulesFromTag,
  splitComponentTags,
} from './scanUtils';
import { FOOTER, HEADER, REPEATER } from './scanConsts';
import {
  createTextInjectionMap,
  createImageInjectionMap,
  createButtonInjectionMap,
} from './scanComponent';

import type {
  ComponentDataForContentInjection,
  ComponentsAPI,
  ContentInjectionImage,
  RegularContentInjectionValues,
} from './scanTypes';
import type { CompRef } from 'types/documentServices';

export function getRegularInjectableMap(
  componentsAPI: ComponentsAPI,
  components: ComponentDataForContentInjection[],
) {
  const injectionPerComponents: RegularContentInjectionValues = {};
  components.forEach((component) => {
    const { compRef, contentTag } = component;
    const tagWithoutRules = removeRulesFromTag(contentTag);
    const componentContentType = getContentTagLastPart(tagWithoutRules);
    const injectionMapByTagType = createContentInjectionMapByType(
      componentsAPI,
      compRef,
      componentContentType,
    );
    if (injectionMapByTagType) {
      const injectionPropName =
        convertContentTagPropKeyToCaasPropKey(componentContentType);
      (injectionPerComponents[
        injectionPropName as keyof RegularContentInjectionValues
      ] as string | ContentInjectionImage[]) =
        addInjectionByTypeToInjectableMap(
          injectionPerComponents,
          injectionPropName as keyof RegularContentInjectionValues,
          injectionMapByTagType,
        );
    }
  });
  return injectionPerComponents;
}

export function getListInjectableMap(
  componentsAPI: ComponentsAPI,
  components: ComponentDataForContentInjection[],
) {
  const headerComponentsInjection = getHeaderComponentsInjectionMap(
    componentsAPI,
    components,
  );
  const footerComponentsInjection = getFooterComponentsInjectionMap(
    componentsAPI,
    components,
  );

  const listItems = components.filter((item) => isListItemTag(item.contentTag));
  const injectionPerComponents: RegularContentInjectionValues[] = [];
  listItems.forEach((component: ComponentDataForContentInjection) => {
    const { compRef, contentTag } = component;
    const tagWithoutRules = removeRulesFromTag(contentTag);
    const splitContentTag = splitComponentTags(tagWithoutRules);
    const componentContentType = getContentTagLastPart(tagWithoutRules);
    const listItemNumber = getListItemNumber(splitContentTag);
    const injectionMapByTagType = createContentInjectionMapByType(
      componentsAPI,
      compRef,
      componentContentType,
    );

    if (injectionMapByTagType && listItemNumber !== undefined) {
      injectionPerComponents[listItemNumber] =
        injectionPerComponents[listItemNumber] || {};

      const injectionPropName =
        convertContentTagPropKeyToCaasPropKey(componentContentType);

      (injectionPerComponents[listItemNumber][
        injectionPropName as keyof RegularContentInjectionValues
      ] as string | ContentInjectionImage[]) =
        addInjectionByTypeToInjectableMap(
          injectionPerComponents[listItemNumber],
          injectionPropName as keyof RegularContentInjectionValues,
          injectionMapByTagType,
        );
    }
  });

  return {
    header: headerComponentsInjection,
    list: injectionPerComponents,
    footer: footerComponentsInjection,
  };
}

export function getHeaderComponentsInjectionMap(
  componentsAPI: ComponentsAPI,
  components: ComponentDataForContentInjection[],
) {
  const headerComponents = components.filter((item) =>
    item.contentTag.includes(HEADER),
  );
  return headerComponents.length
    ? getRegularInjectableMap(componentsAPI, headerComponents)
    : {};
}

export function getFooterComponentsInjectionMap(
  componentsAPI: ComponentsAPI,
  components: ComponentDataForContentInjection[],
) {
  const footerComponents = components.filter((item) =>
    item.contentTag.includes(FOOTER),
  );
  return footerComponents.length
    ? getRegularInjectableMap(componentsAPI, footerComponents)
    : {};
}

export function getRepeaterInjectableMap(
  componentsAPI: ComponentsAPI,
  components: ComponentDataForContentInjection[],
) {
  const headerComponentsInjection = getHeaderComponentsInjectionMap(
    componentsAPI,
    components,
  );
  const footerComponentsInjection = getFooterComponentsInjectionMap(
    componentsAPI,
    components,
  );

  const repeaterComponent = components.find((item) =>
    item.type.includes(REPEATER),
  );
  const repeaterItemIds: string[] = repeaterComponent.data.items;
  const repeaterComponentsInjectableMap: Partial<RegularContentInjectionValues>[] =
    [];
  repeaterItemIds.forEach((itemId: string, idx: number) => {
    const componentIdRelatedItems = components.filter(
      (component) => !!component.data && component.data.id.includes(itemId),
    );
    componentIdRelatedItems.forEach((component) => {
      const { compRef, contentTag } = component;
      const tagWithoutRules = removeRulesFromTag(contentTag);
      const componentContentType = getContentTagLastPart(tagWithoutRules);
      const injectionMapByTagType = createContentInjectionMapByType(
        componentsAPI,
        compRef,
        componentContentType,
      );
      if (injectionMapByTagType) {
        if (!repeaterComponentsInjectableMap[idx]) {
          repeaterComponentsInjectableMap[idx] = {};
        }
        const injectionPropName = convertContentTagPropKeyToCaasPropKey(
          componentContentType,
        ) as keyof RegularContentInjectionValues;

        (repeaterComponentsInjectableMap[idx][injectionPropName] as
          | string
          | ContentInjectionImage[]) = addInjectionByTypeToInjectableMap(
          repeaterComponentsInjectableMap[idx],
          injectionPropName as keyof RegularContentInjectionValues,
          injectionMapByTagType,
        );
      }
    });
  });
  const repeaterInjectableMapConsideringOriginalData = [
    repeaterComponentsInjectableMap[0],
    ...repeaterComponentsInjectableMap,
  ];
  return {
    header: headerComponentsInjection,
    list: repeaterInjectableMapConsideringOriginalData,
    footer: footerComponentsInjection,
  };
}

export function getSlideShowInjectableMap(
  componentsAPI: ComponentsAPI,
  components: ComponentDataForContentInjection[],
) {
  const headerComponentsInjection = getHeaderComponentsInjectionMap(
    componentsAPI,
    components,
  );
  const footerComponentsInjection = getFooterComponentsInjectionMap(
    componentsAPI,
    components,
  );

  const slideShowComponentsInjectableMap: RegularContentInjectionValues[] = [];
  components.forEach((component) => {
    const { compRef, contentTag, data } = component;
    const actualTag = isSlideShowSlide(component) ? data.title : contentTag;
    const tagWithoutRules = removeRulesFromTag(actualTag);
    const splitContentTag = splitComponentTags(tagWithoutRules);
    const componentContentType = getContentTagLastPart(tagWithoutRules);
    const slideShowItemNumber = getListItemNumber(splitContentTag);
    const injectionMapByTagType = createContentInjectionMapByType(
      componentsAPI,
      compRef,
      componentContentType,
    );
    if (injectionMapByTagType && slideShowItemNumber !== undefined) {
      slideShowComponentsInjectableMap[slideShowItemNumber] =
        slideShowComponentsInjectableMap[slideShowItemNumber] || {};

      const injectionPropName =
        convertContentTagPropKeyToCaasPropKey(componentContentType);

      (slideShowComponentsInjectableMap[slideShowItemNumber][
        injectionPropName as keyof RegularContentInjectionValues
      ] as string | ContentInjectionImage[]) =
        addInjectionByTypeToInjectableMap(
          slideShowComponentsInjectableMap[slideShowItemNumber],
          injectionPropName as keyof RegularContentInjectionValues,
          injectionMapByTagType,
        );
    }
  });

  return {
    header: headerComponentsInjection,
    list: slideShowComponentsInjectableMap,
    footer: footerComponentsInjection,
  };
}

export function createContentInjectionMapByType(
  componentsAPI: ComponentsAPI,
  compRef: CompRef,
  componentContentType: string,
) {
  if (isTextContentType(componentContentType)) {
    return createTextInjectionMap(componentsAPI, compRef);
  }
  if (isImageContentType(componentContentType)) {
    return createImageInjectionMap(componentsAPI, compRef);
  }
  if (isButtonContentType(componentContentType)) {
    return createButtonInjectionMap(componentsAPI, compRef);
  }
  return null;
}
