// @ts-nocheck
import _ from 'lodash';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as addPanelAddCompService from '../addPanelAddCompService';
import tpaConstants from '../../constants/constants';
import * as superAppsCustomData from '../../superApps/superAppsCustomData';

const appDefinitionId = '140603ad-af8d-84a5-2c80-a0f60cb47351';
const widgetId = '1440e92d-47d8-69be-ade7-e6de40127106';
const itemIdOnImage = 'onImage';
const itemIdCards = 'cards';
const itemIdSideBySide = 'sideBySide';
const itemIdList = 'list';
const itemIdSingle = 'single';

const getCategory = () => 'other';

const registerSection = (editorAPI) => {
  editorAPI.addPanel.registerSectionAtStart(
    addPanelDataConsts.CATEGORIES_ID.EVENTS,
    _.partial(getSection, editorAPI),
  );
};

const addApp = (
  compStructure,
  sectionTitle,
  tags,
  id,
  editorAPI,
  addOptions,
) => {
  return addPanelAddCompService.addCompOnClick(
    editorAPI,
    appDefinitionId,
    widgetId,
    compStructure,
    tags,
    id,
    { biOrigin: tpaConstants.BI.type.ADD_WIDGET },
    addOptions,
  );
};

const onDrop = (layoutParams, compPreset, parentComponent, editorAPI) =>
  addPanelAddCompService.addCompOnDrop(
    editorAPI,
    appDefinitionId,
    widgetId,
    layoutParams,
    compPreset,
    parentComponent,
    null,
    { biOrigin: tpaConstants.BI.type.ADD_WIDGET },
  );

const getSectionNumber = () =>
  addPanelDataConsts.SECTION_NUMBERS.EVENTS[getCategory()];

const getSection = () => ({
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: 'add_section_label_wixevents',
  subNavigationTitle: 'add_section_label_wixevents',
  presetTitle: 'add_section_label_wixevents',
  tooltipTitle: 'add_section_label_wixevents',
  subNavigationHide: false,
  sectionNumber: getSectionNumber(),
  sectionName: 'wixEventsSection',
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
    iconEnabledForComps: {},
  },
  props: {
    onClick: addApp,
    onDrop,
    image: 'addPanelData/sections/eventsSection_en/eventsSection_en.png',
    imageHover:
      'addPanelData/sections/eventsSection_en/hover_en/eventsSection_hover_en.png',
    items: [
      {
        id: itemIdOnImage,
        structure: {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          layout: {
            width: 859,
            height: 409,
            x: 60,
            y: 204,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          data: {
            type: 'TPAWidget',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            applicationId: '2153',
            widgetId: '1440e92d-47d8-69be-ade7-e6de40127106',
          },
          style: {
            type: 'TopLevelStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: superAppsCustomData.getSuperAppsAddPanelStyles(
              appDefinitionId,
              itemIdOnImage,
            ),
            componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          },
          activeModes: {},
        },
        preset: {
          rect: {
            width: 324,
            height: 176,
            x: 0,
            y: 0,
          },
          label: 'add_preset_wixevents_flashsale',
        },
      },
      {
        id: itemIdCards,
        structure: {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          layout: {
            width: 899,
            height: 582,
            x: 40,
            y: 237,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          data: {
            type: 'TPAWidget',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            applicationId: '2153',
            widgetId: '1440e92d-47d8-69be-ade7-e6de40127106',
          },
          style: {
            type: 'TopLevelStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: superAppsCustomData.getSuperAppsAddPanelStyles(
              appDefinitionId,
              itemIdCards,
            ),
            componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          },
          activeModes: {},
        },
        preset: {
          rect: {
            width: 324,
            height: 219,
            x: 0,
            y: 176,
          },
          label: 'add_preset_wixevents_meetup',
        },
      },
      {
        id: itemIdSideBySide,
        structure: {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          layout: {
            width: 899,
            height: 1072,
            x: 40,
            y: 178,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          data: {
            type: 'TPAWidget',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            applicationId: '2153',
            widgetId: '1440e92d-47d8-69be-ade7-e6de40127106',
          },
          style: {
            type: 'TopLevelStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: superAppsCustomData.getSuperAppsAddPanelStyles(
              appDefinitionId,
              itemIdSideBySide,
            ),
            componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          },
          activeModes: {},
        },
        preset: {
          rect: {
            width: 324,
            height: 320,
            x: 0,
            y: 395,
          },
          label: 'add_preset_wixevents_launch',
        },
      },
      {
        id: itemIdList,
        structure: {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          layout: {
            width: 918,
            height: 422,
            x: 31,
            y: 223,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          data: {
            type: 'TPAWidget',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            applicationId: '2153',
            widgetId: '1440e92d-47d8-69be-ade7-e6de40127106',
          },
          style: {
            type: 'TopLevelStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: superAppsCustomData.getSuperAppsAddPanelStyles(
              appDefinitionId,
              itemIdList,
            ),
            componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          },
          activeModes: {},
        },
        preset: {
          rect: {
            width: 324,
            height: 159,
            x: 0,
            y: 715,
          },
          label: 'add_preset_wixevents_tour',
        },
      },
      {
        id: itemIdSingle,
        structure: {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          layout: {
            width: 860,
            height: 427,
            x: 60,
            y: 172,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          data: {
            type: 'TPAWidget',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            applicationId: '2153',
            widgetId: '1440e92d-47d8-69be-ade7-e6de40127106',
          },
          style: {
            type: 'TopLevelStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: superAppsCustomData.getSuperAppsAddPanelStyles(
              appDefinitionId,
              itemIdSingle,
            ),
            componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          },
          activeModes: {},
        },
        preset: {
          rect: {
            width: 324,
            height: 187,
            x: 0,
            y: 874,
          },
          label: 'add_preset_wixevents_fundraiser',
        },
      },
    ],
    compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
  },
});

export { registerSection };
