// @ts-nocheck
import * as util from '@/util';
import { translate } from '@/i18n';
import addPanelDataConsts from '@/addPanelDataConsts';
import blogManagePanel from './panels/blogManagePanel';
import blogPagesPanel from './panels/blogPagesPanel';
import * as blogUtils from '../utils/blogUtils';

const createSuperApp = ({ promotionalFlow, migrationFlow }) => ({
  id: blogUtils.newSuperAppId,
  appDefId: '14bcded7-0066-7c35-14d7-466cb3f09103',
  appName: 'Blog',
  firstTimeTour: {
    templateFlow: [migrationFlow],
    manuallyInstalledFlow: [migrationFlow],
    promotionalFlow: [promotionalFlow],
  },
  panel: {
    name: blogUtils.newSuperAppPanelName,
    label: util.workspace.isNewWorkspaceEnabled()
      ? translate('SiteApp_Blog_Panel_Title')
      : undefined,
    manage: {
      title: 'SiteApp_NewBlog_Manage_Button',
      helpId: 'e46190d5-305e-440a-9c44-14c05c34b10f',
      template: blogManagePanel,
    },
    pages: {
      title: 'SiteApp_NewBlog_Pages_Button',
      template: blogPagesPanel,
    },
    addElements: {
      title: 'SiteApp_NewBlog_Add_Button',
      helpId: '4a365632-9712-4630-95ee-8b8f9074519f',
      categoryId: addPanelDataConsts.CATEGORIES_ID.NEW_BLOG,
    },
    learnMore: {
      title: 'SiteApp_NewBlog_LearnMore_Button',
      section1HeaderViewA: 'SiteApp_NewBlog_LearnMorePanel_Section1_Header',
      section2HeaderViewA: 'SiteApp_NewBlog_LearnMorePanel_Section2_Header',
      section3HeaderViewA: 'SiteApp_NewBlog_LearnMorePanel_Section3_Header',
      data: {
        viewA: {
          helpCenterPopUp: {
            link: 'a7eeebda-8828-4a49-ba7e-d9b656caa197',
            image: util.media.getMediaUrl(
              'superAppMenuInfra/TipsFromWixsBloogingPros.png',
            ),
          },
          supportForumsPopUp: {
            link: 'https://support.wix.com/en/new-wix-blog/setting-up-the-new-wix-blog',
            image: util.media.getMediaUrl(
              'superAppMenuInfra/ViewTheBlogHelpCenter.png',
            ),
          },
          wixTipsBlog: {
            link: 'https://www.wix.com/blog/tag/blogging-tips/',
            image: util.media.getMediaUrl(
              'superAppMenuInfra/OpenTheBlogSupportForum.png',
            ),
          },
        },
      },
    },
  },
  isAppInstalled: blogUtils.siteHasNewBlog,
});

export default createSuperApp;
