/*eslint max-lines: [2, { "max": 1109, "skipComments": true, "skipBlankLines": true}]*/
// @ts-nocheck
import _ from 'lodash';
import * as addPanel from '@/oldAddPanel';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as addPanelAddCompService from '../addPanelAddCompService';

const appDefinitionId = '14271d6f-ba62-d045-549b-ab972ae1f70e';
const widgetId = '142bb34d-3439-576a-7118-683e690a1e0d';

const registerSection = function (editorAPI) {
  editorAPI.addPanel.registerSectionAtStart('gallery', () =>
    addPanel.dataUtil.mergeSectionVideos(getSection(), getVideoSections()),
  );
};

const addApp = function (
  compStructure,
  sectionTitle,
  tags,
  id,
  editorAPI,
  addOptions,
) {
  return addPanelAddCompService.addCompOnClick(
    editorAPI,
    appDefinitionId,
    widgetId,
    compStructure,
    tags,
    id,
    { origin: 'add_panel', sectionTitle, category: 'gallery' },
    addOptions,
  );
};

const onDrop = function (layoutParams, compPreset, parentComponent, editorAPI) {
  return addPanelAddCompService.addCompOnDrop(
    editorAPI,
    appDefinitionId,
    widgetId,
    layoutParams,
    compPreset,
    parentComponent,
    _.noop,
  );
};

const getVideoSections = () => ({
  videoPosterPrefix:
    'addPanelData/sections/videos_en/gridGalleries/v1/gallery-video-thumb-grid-',
  videoSrcPrefix: 'v1/gallery-video-thumb-grid-',
});

const getSection = function () {
  return {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'add_section_label_gridgalleries',
    subNavigationTitle: 'add_section_label_gridgalleries',
    presetTitle: 'add_section_label_gridgalleries',
    tooltipTitle: 'add_section_label_gridgalleries',
    sectionNumber: 1,
    sectionName: 'newGridGalleriesSection',
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.VIDEO,
      iconEnabledForComps: {},
    },
    props: {
      onClick: addApp,
      onDrop,
      image:
        'addPanelData/sections/gridGalleriesSection_en/gridGalleriesSectionNew_en.png',
      retinaImage: {
        src: 'addPanelData/sections/gridGalleriesSection_en/gridGalleriesSection_en-@2x.png',
        dimensions: {
          width: 648,
          height: 704,
        },
      },
      imageHover: null,
      items: [
        {
          id: 'Gallery_Grid_Galleries_1',
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 749,
              height: 1281,
              x: 86,
              y: 2226,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              applicationId: '1541',
              appDefinitionId: '14271d6f-ba62-d045-549b-ab972ae1f70e',
              widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
            },
            style: {
              type: 'ComponentStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: {
                properties: {
                  'alpha-param_color_actionsColorExpand': '1',
                  'alpha-param_color_arrowsColor': '1',
                  'alpha-param_color_backColor': '1',
                  'alpha-param_color_bgColorExpand': '1',
                  'alpha-param_color_descriptionColorExpand': '1',
                  'alpha-param_color_foreColor': '1',
                  'alpha-param_color_imageLoadingColor': '1',
                  'alpha-param_color_itemBorderColor': '1',
                  'alpha-param_color_itemDescriptionFontColor': '1',
                  'alpha-param_color_itemDescriptionFontColorSlideshow': '1',
                  'alpha-param_color_itemFontColor': '1',
                  'alpha-param_color_itemFontColorSlideshow': '1',
                  'alpha-param_color_itemIconColor': '1',
                  'alpha-param_color_itemIconColorSlideshow': '1',
                  'alpha-param_color_itemOpacity': '0.6',
                  'alpha-param_color_itemShadowOpacityAndColor': '0.23',
                  'alpha-param_color_loadMoreButtonBorderColor': '1',
                  'alpha-param_color_loadMoreButtonColor': '1',
                  'alpha-param_color_loadMoreButtonFontColor': '1',
                  'alpha-param_color_oneColorAnimationColor': '1',
                  'alpha-param_color_te-background-color-picker': '1',
                  'alpha-param_color_textBoxBorderColor': '1',
                  'alpha-param_color_textBoxFillColor': '1',
                  'alpha-param_color_titleColorExpand': '1',
                  param_boolean_addPanel: 'true',
                  param_boolean_allowDescription: 'false',
                  param_boolean_allowDownload: 'false',
                  param_boolean_allowSocial: 'false',
                  param_boolean_allowTitle: 'true',
                  param_boolean_dummyBooly: 'true',
                  param_boolean_itemEnableShadow: 'true',
                  param_boolean_loveButton: 'false',
                  param_boolean_previewHover: 'false',
                  param_boolean_shouldUnDock: 'false',
                  param_color_actionsColorExpand: 'color_15',
                  param_color_arrowsColor: 'color_11',
                  param_color_backColor: 'color_12',
                  param_color_bgColorExpand: 'color_11',
                  param_color_descriptionColorExpand: 'color_15',
                  param_color_foreColor: 'color_12',
                  param_color_imageLoadingColor: 'color_13',
                  param_color_itemBorderColor: 'color_11',
                  param_color_itemDescriptionFontColor: 'color_11',
                  param_color_itemDescriptionFontColorSlideshow: 'color_15',
                  param_color_itemFontColor: 'color_11',
                  param_color_itemFontColorSlideshow: 'color_15',
                  param_color_itemIconColor: 'color_15',
                  param_color_itemIconColorSlideshow: 'color_15',
                  param_color_itemOpacity: 'color_11',
                  param_color_itemShadowOpacityAndColor: 'color_15',
                  param_color_loadMoreButtonBorderColor: 'color_15',
                  param_color_loadMoreButtonColor: 'color_11',
                  param_color_loadMoreButtonFontColor: 'color_15',
                  param_color_oneColorAnimationColor: 'color_11',
                  'param_color_te-background-color-picker': 'color_17',
                  param_color_textBoxBorderColor: 'color_15',
                  param_color_textBoxFillColor: 'color_11',
                  param_color_titleColorExpand: 'color_15',
                  param_font_descriptionFontExpand:
                    '{"style":{"bold":false,"italic":false,"underline":false},"family":"avenir-lt-w01_35-light1475496","preset":"Body-M","editorKey":"font_8","size":"15px","fontStyleParam":true,"displayName":"Paragraph 2"}',
                  param_font_itemDescriptionFont:
                    '{"family":"avenir-lt-w01_35-light1475496","style":{"bold":false,"italic":false,"underline":false},"size":"15px","preset":"Body-M","editorKey":"font_8","fontStyleParam":true,"displayName":"Paragraph 2"}',
                  param_font_itemDescriptionFontSlideshow:
                    '{"family":"helvetica-w01-light","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":15,"preset":"Custom","editorKey":"font_8","fontStyleParam":true}',
                  param_font_itemFont:
                    '{"style":{"bold":false,"italic":false,"underline":false},"family":"avenir-lt-w01_85-heavy1475544","preset":"Heading-S","editorKey":"font_6","size":"20px","fontStyleParam":true,"displayName":"Small Heading"}',
                  param_font_itemFontSlideshow:
                    '{"family":"helvetica-w01-bold","style":{"bold":false,"italic":false,"underline":false},"size":18,"preset":"Custom","editorKey":"font_5","fontStyleParam":true}',
                  param_font_loadMoreButtonFont:
                    '{"family":"helvetica-w01-light","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"13","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
                  param_font_loadMoreButtonText: '"Load More"',
                  'param_font_text-editor-font':
                    '{"family":"avenir-lt-w01_85-heavy1475544","style":{"bold":false,"italic":false,"underline":false},"size":40,"preset":"Custom","editorKey":"font_2","fontStyleParam":true}',
                  'param_font_text-editor-font-1499774301866':
                    '{"family":"avenir-lt-w01_35-light1475496","style":{"bold":false,"italic":false,"underline":false},"size":40,"preset":"Custom","editorKey":"font_2","fontStyleParam":true}',
                  param_font_titleFontExpand:
                    '{"style":{"bold":false,"italic":false,"underline":false},"family":"arial","preset":"Page-title","editorKey":"font_2","size":"28px","fontStyleParam":true,"displayName":"Heading 2"}',
                  param_number_calculateTextBoxHeightMode: '0',
                  param_number_collageDensity: '80',
                  param_number_galleryHorizontalAlign: '1',
                  param_number_galleryLayout: '1',
                  param_number_gallerySize: '48',
                  param_number_galleryVerticalAlign: '0',
                  param_number_gridStyle: '1',
                  param_number_imageInfoType: '1',
                  param_number_imageMargin: '15',
                  param_number_isVertical: '1',
                  param_number_itemBorderRadius: '2',
                  param_number_itemBorderWidth: '5',
                  param_number_itemShadowBlur: '12',
                  param_number_itemShadowDirection: '188',
                  param_number_itemShadowSize: '4',
                  param_number_loveCounter: '1',
                  param_number_newItemsDetails: '1',
                  param_number_newItemsLocation: '0',
                  param_number_numberOfImagesPerRow: '3',
                  param_number_overlayAnimation: '1',
                  param_number_scrollDirection: '0',
                  'param_number_te-padding-slider': '50',
                  param_number_textsVerticalPadding: '12',
                  param_number_titlePlacement: '0',
                },
                propertiesSource: {
                  'alpha-param_color_actionsColorExpand': 'value',
                  'alpha-param_color_arrowsColor': 'value',
                  'alpha-param_color_backColor': 'value',
                  'alpha-param_color_bgColorExpand': 'value',
                  'alpha-param_color_descriptionColorExpand': 'value',
                  'alpha-param_color_foreColor': 'value',
                  'alpha-param_color_imageLoadingColor': 'value',
                  'alpha-param_color_itemBorderColor': 'value',
                  'alpha-param_color_itemDescriptionFontColor': 'value',
                  'alpha-param_color_itemDescriptionFontColorSlideshow':
                    'value',
                  'alpha-param_color_itemFontColor': 'value',
                  'alpha-param_color_itemFontColorSlideshow': 'value',
                  'alpha-param_color_itemIconColor': 'value',
                  'alpha-param_color_itemIconColorSlideshow': 'value',
                  'alpha-param_color_itemOpacity': 'value',
                  'alpha-param_color_itemShadowOpacityAndColor': 'value',
                  'alpha-param_color_loadMoreButtonBorderColor': 'value',
                  'alpha-param_color_loadMoreButtonColor': 'value',
                  'alpha-param_color_loadMoreButtonFontColor': 'value',
                  'alpha-param_color_oneColorAnimationColor': 'value',
                  'alpha-param_color_te-background-color-picker': 'value',
                  'alpha-param_color_textBoxBorderColor': 'value',
                  'alpha-param_color_textBoxFillColor': 'value',
                  'alpha-param_color_titleColorExpand': 'value',
                  param_boolean_addPanel: 'value',
                  param_boolean_allowDescription: 'value',
                  param_boolean_allowDownload: 'value',
                  param_boolean_allowSocial: 'value',
                  param_boolean_allowTitle: 'value',
                  param_boolean_dummyBooly: 'value',
                  param_boolean_itemEnableShadow: 'value',
                  param_boolean_loveButton: 'value',
                  param_boolean_previewHover: 'value',
                  param_boolean_shouldUnDock: 'value',
                  param_color_actionsColorExpand: 'theme',
                  param_color_arrowsColor: 'theme',
                  param_color_backColor: 'theme',
                  param_color_bgColorExpand: 'theme',
                  param_color_descriptionColorExpand: 'theme',
                  param_color_foreColor: 'theme',
                  param_color_imageLoadingColor: 'theme',
                  param_color_itemBorderColor: 'theme',
                  param_color_itemDescriptionFontColor: 'theme',
                  param_color_itemDescriptionFontColorSlideshow: 'theme',
                  param_color_itemFontColor: 'theme',
                  param_color_itemFontColorSlideshow: 'theme',
                  param_color_itemIconColor: 'theme',
                  param_color_itemIconColorSlideshow: 'theme',
                  param_color_itemOpacity: 'theme',
                  param_color_itemShadowOpacityAndColor: 'theme',
                  param_color_loadMoreButtonBorderColor: 'theme',
                  param_color_loadMoreButtonColor: 'theme',
                  param_color_loadMoreButtonFontColor: 'theme',
                  param_color_oneColorAnimationColor: 'theme',
                  'param_color_te-background-color-picker': 'theme',
                  param_color_textBoxBorderColor: 'theme',
                  param_color_textBoxFillColor: 'theme',
                  param_color_titleColorExpand: 'theme',
                  param_font_descriptionFontExpand: 'value',
                  param_font_itemDescriptionFont: 'value',
                  param_font_itemDescriptionFontSlideshow: 'value',
                  param_font_itemFont: 'value',
                  param_font_itemFontSlideshow: 'value',
                  param_font_loadMoreButtonFont: 'value',
                  param_font_loadMoreButtonText: 'value',
                  'param_font_text-editor-font': 'value',
                  'param_font_text-editor-font-1499774301866': 'value',
                  param_font_titleFontExpand: 'value',
                  param_number_calculateTextBoxHeightMode: 'value',
                  param_number_collageDensity: 'value',
                  param_number_galleryHorizontalAlign: 'value',
                  param_number_galleryLayout: 'value',
                  param_number_gallerySize: 'value',
                  param_number_galleryVerticalAlign: 'value',
                  param_number_gridStyle: 'value',
                  param_number_imageInfoType: 'value',
                  param_number_imageMargin: 'value',
                  param_number_isVertical: 'value',
                  param_number_itemBorderRadius: 'value',
                  param_number_itemBorderWidth: 'value',
                  param_number_itemShadowBlur: 'value',
                  param_number_itemShadowDirection: 'value',
                  param_number_itemShadowSize: 'value',
                  param_number_loveCounter: 'value',
                  param_number_newItemsDetails: 'value',
                  param_number_newItemsLocation: 'value',
                  param_number_numberOfImagesPerRow: 'value',
                  param_number_overlayAnimation: 'value',
                  param_number_scrollDirection: 'value',
                  'param_number_te-padding-slider': 'value',
                  param_number_textsVerticalPadding: 'value',
                  param_number_titlePlacement: 'value',
                },
                groups: {},
              },
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            mobileStructure: {
              layout: {
                width: 280,
                height: 462,
                x: 20,
                y: 2378,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false,
              },
              metaData: {
                originalCompId: 'comp-jyn36x2i',
                author: 'duplicate',
              },
            },
            activeModes: {},
          },
          preset: {
            rect: { width: 162, height: 176, x: 0, y: 0 },
            label: 'add_preset_galleries_grid_masonry_label',
            tags: { isFullWidth: false },
          },
        },
        {
          id: 'Gallery_Grid_Galleries_2',
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 718,
              height: 1200,
              x: 100,
              y: 1016,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              applicationId: '1541',
              appDefinitionId: '14271d6f-ba62-d045-549b-ab972ae1f70e',
              referenceId: 'f42b64ca-57ee-4033-b5de-844ba7dc34d1',
              widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
            },
            mobileHints: {
              type: 'MobileHints',
              author: 'duplicate',
              originalCompId: 'comp-jyn36x2i',
              recommendedScale: 1,
              recommendedWidth: 280,
              recommendedHeight: 462,
            },
            style: {
              type: 'ComponentStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: {
                properties: {
                  'alpha-param_color_actionsColorExpand': '1',
                  'alpha-param_color_arrowsColor': '1',
                  'alpha-param_color_backColor': '1',
                  'alpha-param_color_bgColorExpand': '1',
                  'alpha-param_color_descriptionColorExpand': '1',
                  'alpha-param_color_foreColor': '1',
                  'alpha-param_color_imageLoadingColor': '1',
                  'alpha-param_color_itemBorderColor': '1',
                  'alpha-param_color_itemDescriptionFontColor': '1',
                  'alpha-param_color_itemDescriptionFontColorSlideshow': '1',
                  'alpha-param_color_itemFontColor': '1',
                  'alpha-param_color_itemFontColorSlideshow': '1',
                  'alpha-param_color_itemIconColor': '1',
                  'alpha-param_color_itemIconColorSlideshow': '1',
                  'alpha-param_color_itemOpacity': '0.6',
                  'alpha-param_color_itemShadowOpacityAndColor': '0.38',
                  'alpha-param_color_loadMoreButtonBorderColor': '1',
                  'alpha-param_color_loadMoreButtonColor': '1',
                  'alpha-param_color_loadMoreButtonFontColor': '1',
                  'alpha-param_color_oneColorAnimationColor': '1',
                  'alpha-param_color_te-background-color-picker': '1',
                  'alpha-param_color_textBoxBorderColor': '1',
                  'alpha-param_color_textBoxFillColor': '1',
                  'alpha-param_color_titleColorExpand': '1',
                  param_boolean_addPanel: 'true',
                  param_boolean_allowDescription: 'false',
                  param_boolean_allowDownload: 'false',
                  param_boolean_allowSocial: 'false',
                  param_boolean_allowTitle: 'false',
                  param_boolean_dummyBooly: 'true',
                  param_boolean_itemEnableShadow: 'false',
                  param_boolean_loveButton: 'false',
                  param_boolean_previewHover: 'false',
                  param_boolean_shouldUnDock: 'false',
                  param_color_actionsColorExpand: 'color_15',
                  param_color_arrowsColor: 'color_11',
                  param_color_backColor: 'color_12',
                  param_color_bgColorExpand: 'color_11',
                  param_color_descriptionColorExpand: 'color_15',
                  param_color_foreColor: 'color_12',
                  param_color_imageLoadingColor: 'color_13',
                  param_color_itemBorderColor: 'color_11',
                  param_color_itemDescriptionFontColor: 'color_15',
                  param_color_itemDescriptionFontColorSlideshow: 'color_15',
                  param_color_itemFontColor: 'color_15',
                  param_color_itemFontColorSlideshow: 'color_15',
                  param_color_itemIconColor: 'color_15',
                  param_color_itemIconColorSlideshow: 'color_15',
                  param_color_itemOpacity: 'color_11',
                  param_color_itemShadowOpacityAndColor: 'color_15',
                  param_color_loadMoreButtonBorderColor: 'color_15',
                  param_color_loadMoreButtonColor: 'color_11',
                  param_color_loadMoreButtonFontColor: 'color_15',
                  param_color_oneColorAnimationColor: 'color_11',
                  'param_color_te-background-color-picker': 'color_17',
                  param_color_textBoxBorderColor: 'color_15',
                  param_color_textBoxFillColor: 'color_11',
                  param_color_titleColorExpand: 'color_15',
                  param_font_descriptionFontExpand:
                    '{"style":{"bold":false,"italic":false,"underline":false},"family":"avenir-lt-w01_35-light1475496","preset":"Body-M","editorKey":"font_8","size":"15px","fontStyleParam":true,"displayName":"Paragraph 2"}',
                  param_font_itemDescriptionFont:
                    '{"family":"helvetica-w01-light","style":{"bold":false,"italic":false,"underline":false},"size":15,"preset":"Custom","editorKey":"font_8","fontStyleParam":true}',
                  param_font_itemDescriptionFontSlideshow:
                    '{"family":"helvetica-w01-light","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":15,"preset":"Custom","editorKey":"font_8","fontStyleParam":true}',
                  param_font_itemFont:
                    '{"style":{"bold":true,"italic":false,"underline":false},"family":"arial","preset":"Custom","editorKey":"font_5","size":20,"fontStyleParam":true}',
                  param_font_itemFontSlideshow:
                    '{"family":"helvetica-w01-bold","style":{"bold":false,"italic":false,"underline":false},"size":20,"preset":"Custom","editorKey":"font_5","fontStyleParam":true}',
                  param_font_loadMoreButtonFont:
                    '{"family":"helvetica-w01-light","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"13","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
                  param_font_loadMoreButtonText: '"Load More"',
                  'param_font_text-editor-font':
                    '{"family":"avenir-lt-w01_85-heavy1475544","style":{"bold":false,"italic":false,"underline":false},"size":40,"preset":"Custom","editorKey":"font_2","fontStyleParam":true}',
                  'param_font_text-editor-font-1499774301866':
                    '{"family":"avenir-lt-w01_35-light1475496","style":{"bold":false,"italic":false,"underline":false},"size":40,"preset":"Custom","editorKey":"font_2","fontStyleParam":true}',
                  param_font_titleFontExpand:
                    '{"style":{"bold":false,"italic":false,"underline":false},"family":"avenir-lt-w01_35-light1475496","preset":"Page-title","editorKey":"font_2","size":"18px","fontStyleParam":true,"displayName":"Page Title"}',
                  param_number_collageDensity: '80',
                  param_number_galleryHorizontalAlign: '1',
                  param_number_galleryLayout: '2',
                  param_number_gallerySize: '48',
                  param_number_galleryVerticalAlign: '1',
                  param_number_gridStyle: '1',
                  param_number_imageInfoType: '1',
                  param_number_imageMargin: '15',
                  param_number_isVertical: '1',
                  param_number_itemBorderRadius: '2',
                  param_number_itemBorderWidth: '0',
                  param_number_itemShadowBlur: '7',
                  param_number_itemShadowSize: '4',
                  param_number_loveCounter: '1',
                  param_number_newItemsDetails: '1',
                  param_number_newItemsLocation: '0',
                  param_number_numberOfImagesPerRow: '3',
                  param_number_overlayAnimation: '1',
                  param_number_scrollDirection: '0',
                  'param_number_te-padding-slider': '50',
                  param_number_textsVerticalPadding: '18',
                  param_number_titlePlacement: '1',
                },
                propertiesSource: {
                  'alpha-param_color_actionsColorExpand': 'value',
                  'alpha-param_color_arrowsColor': 'value',
                  'alpha-param_color_backColor': 'value',
                  'alpha-param_color_bgColorExpand': 'value',
                  'alpha-param_color_descriptionColorExpand': 'value',
                  'alpha-param_color_foreColor': 'value',
                  'alpha-param_color_imageLoadingColor': 'value',
                  'alpha-param_color_itemBorderColor': 'value',
                  'alpha-param_color_itemDescriptionFontColor': 'value',
                  'alpha-param_color_itemDescriptionFontColorSlideshow':
                    'value',
                  'alpha-param_color_itemFontColor': 'value',
                  'alpha-param_color_itemFontColorSlideshow': 'value',
                  'alpha-param_color_itemIconColor': 'value',
                  'alpha-param_color_itemIconColorSlideshow': 'value',
                  'alpha-param_color_itemOpacity': 'value',
                  'alpha-param_color_itemShadowOpacityAndColor': 'value',
                  'alpha-param_color_loadMoreButtonBorderColor': 'value',
                  'alpha-param_color_loadMoreButtonColor': 'value',
                  'alpha-param_color_loadMoreButtonFontColor': 'value',
                  'alpha-param_color_oneColorAnimationColor': 'value',
                  'alpha-param_color_te-background-color-picker': 'value',
                  'alpha-param_color_textBoxBorderColor': 'value',
                  'alpha-param_color_textBoxFillColor': 'value',
                  'alpha-param_color_titleColorExpand': 'value',
                  param_boolean_addPanel: 'value',
                  param_boolean_allowDescription: 'value',
                  param_boolean_allowDownload: 'value',
                  param_boolean_allowSocial: 'value',
                  param_boolean_allowTitle: 'value',
                  param_boolean_dummyBooly: 'value',
                  param_boolean_itemEnableShadow: 'value',
                  param_boolean_loveButton: 'value',
                  param_boolean_previewHover: 'value',
                  param_boolean_shouldUnDock: 'value',
                  param_color_actionsColorExpand: 'theme',
                  param_color_arrowsColor: 'theme',
                  param_color_backColor: 'theme',
                  param_color_bgColorExpand: 'theme',
                  param_color_descriptionColorExpand: 'theme',
                  param_color_foreColor: 'theme',
                  param_color_imageLoadingColor: 'theme',
                  param_color_itemBorderColor: 'theme',
                  param_color_itemDescriptionFontColor: 'theme',
                  param_color_itemDescriptionFontColorSlideshow: 'theme',
                  param_color_itemFontColor: 'theme',
                  param_color_itemFontColorSlideshow: 'theme',
                  param_color_itemIconColor: 'theme',
                  param_color_itemIconColorSlideshow: 'theme',
                  param_color_itemOpacity: 'theme',
                  param_color_itemShadowOpacityAndColor: 'theme',
                  param_color_loadMoreButtonBorderColor: 'theme',
                  param_color_loadMoreButtonColor: 'theme',
                  param_color_loadMoreButtonFontColor: 'theme',
                  param_color_oneColorAnimationColor: 'theme',
                  'param_color_te-background-color-picker': 'theme',
                  param_color_textBoxBorderColor: 'theme',
                  param_color_textBoxFillColor: 'theme',
                  param_color_titleColorExpand: 'theme',
                  param_font_descriptionFontExpand: 'value',
                  param_font_itemDescriptionFont: 'value',
                  param_font_itemDescriptionFontSlideshow: 'value',
                  param_font_itemFont: 'value',
                  param_font_itemFontSlideshow: 'value',
                  param_font_loadMoreButtonFont: 'value',
                  param_font_loadMoreButtonText: 'value',
                  'param_font_text-editor-font': 'value',
                  'param_font_text-editor-font-1499774301866': 'value',
                  param_font_titleFontExpand: 'value',
                  param_number_collageDensity: 'value',
                  param_number_galleryHorizontalAlign: 'value',
                  param_number_galleryLayout: 'value',
                  param_number_gallerySize: 'value',
                  param_number_galleryVerticalAlign: 'value',
                  param_number_gridStyle: 'value',
                  param_number_imageInfoType: 'value',
                  param_number_imageMargin: 'value',
                  param_number_isVertical: 'value',
                  param_number_itemBorderRadius: 'value',
                  param_number_itemBorderWidth: 'value',
                  param_number_itemShadowBlur: 'value',
                  param_number_itemShadowSize: 'value',
                  param_number_loveCounter: 'value',
                  param_number_newItemsDetails: 'value',
                  param_number_newItemsLocation: 'value',
                  param_number_numberOfImagesPerRow: 'value',
                  param_number_overlayAnimation: 'value',
                  param_number_scrollDirection: 'value',
                  'param_number_te-padding-slider': 'value',
                  param_number_textsVerticalPadding: 'value',
                  param_number_titlePlacement: 'value',
                },
                groups: {},
              },
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            activeModes: {},
          },
          preset: {
            rect: { width: 162, height: 176, x: 162, y: 0 },
            label: 'add_preset_galleries_grid_portrait_label',
            tags: { isFullWidth: false },
          },
        },
        {
          id: 'Gallery_Grid_Galleries_3',
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 718,
              height: 1300,
              x: 100,
              y: 3517,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              applicationId: '1541',
              appDefinitionId: '14271d6f-ba62-d045-549b-ab972ae1f70e',
              widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
            },
            mobileHints: {
              type: 'MobileHints',
              author: 'duplicate',
              originalCompId: 'comp-jyn36x2i',
              recommendedScale: 1,
              recommendedWidth: 280,
              recommendedHeight: 462,
            },
            style: {
              type: 'ComponentStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: {
                properties: {
                  'alpha-param_color_actionsColorExpand': '1',
                  'alpha-param_color_arrowsColor': '1',
                  'alpha-param_color_backColor': '1',
                  'alpha-param_color_bgColorExpand': '1',
                  'alpha-param_color_descriptionColorExpand': '1',
                  'alpha-param_color_foreColor': '1',
                  'alpha-param_color_imageLoadingColor': '1',
                  'alpha-param_color_itemBorderColor': '1',
                  'alpha-param_color_itemDescriptionFontColor': '1',
                  'alpha-param_color_itemDescriptionFontColorSlideshow': '1',
                  'alpha-param_color_itemFontColor': '1',
                  'alpha-param_color_itemFontColorSlideshow': '1',
                  'alpha-param_color_itemIconColor': '1',
                  'alpha-param_color_itemIconColorSlideshow': '1',
                  'alpha-param_color_itemOpacity': '0.6',
                  'alpha-param_color_itemShadowOpacityAndColor': '0.38',
                  'alpha-param_color_loadMoreButtonBorderColor': '1',
                  'alpha-param_color_loadMoreButtonColor': '1',
                  'alpha-param_color_loadMoreButtonFontColor': '1',
                  'alpha-param_color_oneColorAnimationColor': '1',
                  'alpha-param_color_te-background-color-picker': '1',
                  'alpha-param_color_textBoxBorderColor': '1',
                  'alpha-param_color_textBoxFillColor': '1',
                  'alpha-param_color_titleColorExpand': '1',
                  param_boolean_addPanel: 'true',
                  param_boolean_allowDescription: 'false',
                  param_boolean_allowDownload: 'false',
                  param_boolean_allowSocial: 'false',
                  param_boolean_allowTitle: 'true',
                  param_boolean_dummyBooly: 'true',
                  param_boolean_itemEnableShadow: 'false',
                  param_boolean_loveButton: 'false',
                  param_boolean_previewHover: 'false',
                  param_boolean_shouldUnDock: 'false',
                  param_color_actionsColorExpand: 'color_15',
                  param_color_arrowsColor: 'color_11',
                  param_color_backColor: 'color_12',
                  param_color_bgColorExpand: 'color_11',
                  param_color_descriptionColorExpand: 'color_15',
                  param_color_foreColor: 'color_12',
                  param_color_imageLoadingColor: 'color_13',
                  param_color_itemBorderColor: 'color_11',
                  param_color_itemDescriptionFontColor: 'color_11',
                  param_color_itemDescriptionFontColorSlideshow: 'color_15',
                  param_color_itemFontColor: 'color_11',
                  param_color_itemFontColorSlideshow: 'color_15',
                  param_color_itemIconColor: 'color_15',
                  param_color_itemIconColorSlideshow: 'color_15',
                  param_color_itemOpacity: 'color_11',
                  param_color_itemShadowOpacityAndColor: 'color_15',
                  param_color_loadMoreButtonBorderColor: 'color_15',
                  param_color_loadMoreButtonColor: 'color_11',
                  param_color_loadMoreButtonFontColor: 'color_15',
                  param_color_oneColorAnimationColor: 'color_11',
                  'param_color_te-background-color-picker': 'color_17',
                  param_color_textBoxBorderColor: 'color_15',
                  param_color_textBoxFillColor: 'color_12',
                  param_color_titleColorExpand: 'color_15',
                  param_font_descriptionFontExpand:
                    '{"style":{"bold":false,"italic":false,"underline":false},"family":"avenir-lt-w01_35-light1475496","preset":"Body-M","editorKey":"font_8","size":"15px","fontStyleParam":true,"displayName":"Paragraph 2"}',
                  param_font_itemDescriptionFont:
                    '{"family":"avenir-lt-w01_35-light1475496","style":{"bold":false,"italic":false,"underline":false},"size":"15px","preset":"Body-M","editorKey":"font_8","fontStyleParam":true,"displayName":"Paragraph 2"}',
                  param_font_itemDescriptionFontSlideshow:
                    '{"family":"helvetica-w01-light","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":15,"preset":"Custom","editorKey":"font_8","fontStyleParam":true}',
                  param_font_itemFont:
                    '{"style":{"bold":false,"italic":false,"underline":false},"family":"avenir-lt-w01_85-heavy1475544","preset":"Heading-S","editorKey":"font_6","size":"20px","fontStyleParam":true,"displayName":"Small Heading"}',
                  param_font_itemFontSlideshow:
                    '{"family":"helvetica-w01-bold","style":{"bold":false,"italic":false,"underline":false},"size":18,"preset":"Custom","editorKey":"font_5","fontStyleParam":true}',
                  param_font_loadMoreButtonFont:
                    '{"family":"helvetica-w01-light","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"13","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
                  param_font_loadMoreButtonText: '"Load More"',
                  'param_font_text-editor-font':
                    '{"family":"avenir-lt-w01_85-heavy1475544","style":{"bold":false,"italic":false,"underline":false},"size":40,"preset":"Custom","editorKey":"font_2","fontStyleParam":true}',
                  'param_font_text-editor-font-1499774301866':
                    '{"family":"avenir-lt-w01_35-light1475496","style":{"bold":false,"italic":false,"underline":false},"size":40,"preset":"Custom","editorKey":"font_2","fontStyleParam":true}',
                  param_font_titleFontExpand:
                    '{"style":{"bold":false,"italic":false,"underline":false},"family":"avenir-lt-w01_35-light1475496","preset":"Page-title","editorKey":"font_2","size":"18px","fontStyleParam":true,"displayName":"Page Title"}',
                  param_number_collageDensity: '80',
                  param_number_galleryHorizontalAlign: '1',
                  param_number_galleryImageRatio: '1',
                  param_number_galleryLayout: '2',
                  param_number_gallerySize: '48',
                  param_number_galleryVerticalAlign: '0',
                  param_number_gridStyle: '1',
                  param_number_imageInfoType: '1',
                  param_number_imageMargin: '15',
                  param_number_isVertical: '1',
                  param_number_itemBorderRadius: '2',
                  param_number_itemBorderWidth: '0',
                  param_number_itemShadowBlur: '7',
                  param_number_itemShadowSize: '4',
                  param_number_loveCounter: '1',
                  param_number_newItemsDetails: '1',
                  param_number_newItemsLocation: '0',
                  param_number_numberOfImagesPerRow: '3',
                  param_number_overlayAnimation: '1',
                  param_number_scrollDirection: '0',
                  'param_number_te-padding-slider': '50',
                  param_number_textsVerticalPadding: '12',
                  param_number_titlePlacement: '0',
                },
                propertiesSource: {
                  'alpha-param_color_actionsColorExpand': 'value',
                  'alpha-param_color_arrowsColor': 'value',
                  'alpha-param_color_backColor': 'value',
                  'alpha-param_color_bgColorExpand': 'value',
                  'alpha-param_color_descriptionColorExpand': 'value',
                  'alpha-param_color_foreColor': 'value',
                  'alpha-param_color_imageLoadingColor': 'value',
                  'alpha-param_color_itemBorderColor': 'value',
                  'alpha-param_color_itemDescriptionFontColor': 'value',
                  'alpha-param_color_itemDescriptionFontColorSlideshow':
                    'value',
                  'alpha-param_color_itemFontColor': 'value',
                  'alpha-param_color_itemFontColorSlideshow': 'value',
                  'alpha-param_color_itemIconColor': 'value',
                  'alpha-param_color_itemIconColorSlideshow': 'value',
                  'alpha-param_color_itemOpacity': 'value',
                  'alpha-param_color_itemShadowOpacityAndColor': 'value',
                  'alpha-param_color_loadMoreButtonBorderColor': 'value',
                  'alpha-param_color_loadMoreButtonColor': 'value',
                  'alpha-param_color_loadMoreButtonFontColor': 'value',
                  'alpha-param_color_oneColorAnimationColor': 'value',
                  'alpha-param_color_te-background-color-picker': 'value',
                  'alpha-param_color_textBoxBorderColor': 'value',
                  'alpha-param_color_textBoxFillColor': 'value',
                  'alpha-param_color_titleColorExpand': 'value',
                  param_boolean_addPanel: 'value',
                  param_boolean_allowDescription: 'value',
                  param_boolean_allowDownload: 'value',
                  param_boolean_allowSocial: 'value',
                  param_boolean_allowTitle: 'value',
                  param_boolean_dummyBooly: 'value',
                  param_boolean_itemEnableShadow: 'value',
                  param_boolean_loveButton: 'value',
                  param_boolean_previewHover: 'value',
                  param_boolean_shouldUnDock: 'value',
                  param_color_actionsColorExpand: 'theme',
                  param_color_arrowsColor: 'theme',
                  param_color_backColor: 'theme',
                  param_color_bgColorExpand: 'theme',
                  param_color_descriptionColorExpand: 'theme',
                  param_color_foreColor: 'theme',
                  param_color_imageLoadingColor: 'theme',
                  param_color_itemBorderColor: 'theme',
                  param_color_itemDescriptionFontColor: 'theme',
                  param_color_itemDescriptionFontColorSlideshow: 'theme',
                  param_color_itemFontColor: 'theme',
                  param_color_itemFontColorSlideshow: 'theme',
                  param_color_itemIconColor: 'theme',
                  param_color_itemIconColorSlideshow: 'theme',
                  param_color_itemOpacity: 'theme',
                  param_color_itemShadowOpacityAndColor: 'theme',
                  param_color_loadMoreButtonBorderColor: 'theme',
                  param_color_loadMoreButtonColor: 'theme',
                  param_color_loadMoreButtonFontColor: 'theme',
                  param_color_oneColorAnimationColor: 'theme',
                  'param_color_te-background-color-picker': 'theme',
                  param_color_textBoxBorderColor: 'theme',
                  param_color_textBoxFillColor: 'theme',
                  param_color_titleColorExpand: 'theme',
                  param_font_descriptionFontExpand: 'value',
                  param_font_itemDescriptionFont: 'value',
                  param_font_itemDescriptionFontSlideshow: 'value',
                  param_font_itemFont: 'value',
                  param_font_itemFontSlideshow: 'value',
                  param_font_loadMoreButtonFont: 'value',
                  param_font_loadMoreButtonText: 'value',
                  'param_font_text-editor-font': 'value',
                  'param_font_text-editor-font-1499774301866': 'value',
                  param_font_titleFontExpand: 'value',
                  param_number_collageDensity: 'value',
                  param_number_galleryHorizontalAlign: 'value',
                  param_number_galleryImageRatio: 'value',
                  param_number_galleryLayout: 'value',
                  param_number_gallerySize: 'value',
                  param_number_galleryVerticalAlign: 'value',
                  param_number_gridStyle: 'value',
                  param_number_imageInfoType: 'value',
                  param_number_imageMargin: 'value',
                  param_number_isVertical: 'value',
                  param_number_itemBorderRadius: 'value',
                  param_number_itemBorderWidth: 'value',
                  param_number_itemShadowBlur: 'value',
                  param_number_itemShadowSize: 'value',
                  param_number_loveCounter: 'value',
                  param_number_newItemsDetails: 'value',
                  param_number_newItemsLocation: 'value',
                  param_number_numberOfImagesPerRow: 'value',
                  param_number_overlayAnimation: 'value',
                  param_number_scrollDirection: 'value',
                  'param_number_te-padding-slider': 'value',
                  param_number_textsVerticalPadding: 'value',
                  param_number_titlePlacement: 'value',
                },
                groups: {},
              },
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            activeModes: {},
          },
          preset: {
            rect: { width: 162, height: 176, x: 0, y: 176 },
            label: 'add_preset_galleries_grid_polaroid_label',
            tags: { isFullWidth: false },
          },
        },
        {
          id: 'Gallery_Grid_Galleries_4',
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 718,
              height: 1200,
              x: 100,
              y: 4837,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              applicationId: '1541',
              appDefinitionId: '14271d6f-ba62-d045-549b-ab972ae1f70e',
              widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
            },
            mobileHints: {
              type: 'MobileHints',
              author: 'duplicate',
              originalCompId: 'comp-jyn36x2i',
              recommendedScale: 1,
              recommendedWidth: 280,
              recommendedHeight: 462,
            },
            style: {
              type: 'ComponentStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: {
                properties: {
                  'alpha-param_color_actionsColorExpand': '1',
                  'alpha-param_color_arrowsColor': '1',
                  'alpha-param_color_backColor': '1',
                  'alpha-param_color_bgColorExpand': '1',
                  'alpha-param_color_descriptionColorExpand': '1',
                  'alpha-param_color_foreColor': '1',
                  'alpha-param_color_imageLoadingColor': '1',
                  'alpha-param_color_itemBorderColor': '1',
                  'alpha-param_color_itemDescriptionFontColor': '1',
                  'alpha-param_color_itemDescriptionFontColorSlideshow': '1',
                  'alpha-param_color_itemFontColor': '1',
                  'alpha-param_color_itemFontColorSlideshow': '1',
                  'alpha-param_color_itemIconColor': '1',
                  'alpha-param_color_itemIconColorSlideshow': '1',
                  'alpha-param_color_itemOpacity': '1',
                  'alpha-param_color_itemShadowOpacityAndColor': '0.38',
                  'alpha-param_color_loadMoreButtonBorderColor': '1',
                  'alpha-param_color_loadMoreButtonColor': '1',
                  'alpha-param_color_loadMoreButtonFontColor': '1',
                  'alpha-param_color_oneColorAnimationColor': '1',
                  'alpha-param_color_te-background-color-picker': '1',
                  'alpha-param_color_textBoxBorderColor': '1',
                  'alpha-param_color_textBoxFillColor': '1',
                  'alpha-param_color_titleColorExpand': '1',
                  param_boolean_addPanel: 'true',
                  param_boolean_allowDescription: 'true',
                  param_boolean_allowDownload: 'false',
                  param_boolean_allowSocial: 'false',
                  param_boolean_allowTitle: 'true',
                  param_boolean_dummyBooly: 'true',
                  param_boolean_itemEnableShadow: 'false',
                  param_boolean_loveButton: 'false',
                  param_boolean_previewHover: 'false',
                  param_boolean_shouldUnDock: 'false',
                  param_color_actionsColorExpand: 'color_15',
                  param_color_arrowsColor: 'color_11',
                  param_color_backColor: 'color_12',
                  param_color_bgColorExpand: 'color_11',
                  param_color_descriptionColorExpand: 'color_15',
                  param_color_foreColor: 'color_12',
                  param_color_imageLoadingColor: 'color_13',
                  param_color_itemBorderColor: 'color_15',
                  param_color_itemDescriptionFontColor: 'color_15',
                  param_color_itemDescriptionFontColorSlideshow: 'color_15',
                  param_color_itemFontColor: 'color_15',
                  param_color_itemFontColorSlideshow: 'color_15',
                  param_color_itemIconColor: 'color_15',
                  param_color_itemIconColorSlideshow: 'color_15',
                  param_color_itemOpacity: 'color_11',
                  param_color_itemShadowOpacityAndColor: 'color_15',
                  param_color_loadMoreButtonBorderColor: 'color_15',
                  param_color_loadMoreButtonColor: 'color_11',
                  param_color_loadMoreButtonFontColor: 'color_15',
                  param_color_oneColorAnimationColor: 'color_11',
                  'param_color_te-background-color-picker': 'color_17',
                  param_color_textBoxBorderColor: 'color_15',
                  param_color_textBoxFillColor: 'color_11',
                  param_color_titleColorExpand: 'color_15',
                  param_font_descriptionFontExpand:
                    '{"style":{"bold":false,"italic":false,"underline":false},"family":"avenir-lt-w01_35-light1475496","preset":"Body-M","editorKey":"font_8","size":"15px","fontStyleParam":true,"displayName":"Paragraph 2"}',
                  param_font_itemDescriptionFont:
                    '{"family":"helvetica-w01-light","style":{"bold":false,"italic":false,"underline":false},"size":15,"preset":"Custom","editorKey":"font_8","fontStyleParam":true}',
                  param_font_itemDescriptionFontSlideshow:
                    '{"family":"helvetica-w01-light","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":15,"preset":"Custom","editorKey":"font_8","fontStyleParam":true}',
                  param_font_itemFont:
                    '{"style":{"bold":true,"italic":false,"underline":false},"family":"arial","preset":"Custom","editorKey":"font_6","size":18,"fontStyleParam":true}',
                  param_font_itemFontSlideshow:
                    '{"family":"helvetica-w01-bold","style":{"bold":false,"italic":false,"underline":false},"size":20,"preset":"Custom","editorKey":"font_5","fontStyleParam":true}',
                  param_font_loadMoreButtonFont:
                    '{"family":"helvetica-w01-light","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"13","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
                  param_font_loadMoreButtonText: '"Load More"',
                  'param_font_text-editor-font':
                    '{"family":"avenir-lt-w01_85-heavy1475544","style":{"bold":false,"italic":false,"underline":false},"size":40,"preset":"Custom","editorKey":"font_2","fontStyleParam":true}',
                  'param_font_text-editor-font-1499774301866':
                    '{"family":"avenir-lt-w01_35-light1475496","style":{"bold":false,"italic":false,"underline":false},"size":40,"preset":"Custom","editorKey":"font_2","fontStyleParam":true}',
                  param_font_titleFontExpand:
                    '{"style":{"bold":false,"italic":false,"underline":false},"family":"avenir-lt-w01_35-light1475496","preset":"Page-title","editorKey":"font_2","size":"18px","fontStyleParam":true,"displayName":"Page Title"}',
                  param_number_collageDensity: '80',
                  param_number_galleryHorizontalAlign: '1',
                  param_number_galleryLayout: '2',
                  param_number_gallerySize: '48',
                  param_number_galleryVerticalAlign: '1',
                  param_number_gridStyle: '1',
                  param_number_hoveringBehaviour: '1',
                  param_number_imageInfoType: '1',
                  param_number_imageMargin: '15',
                  param_number_isVertical: '1',
                  param_number_itemBorderRadius: '2',
                  param_number_itemBorderWidth: '4',
                  param_number_itemShadowBlur: '7',
                  param_number_itemShadowSize: '4',
                  param_number_loveCounter: '1',
                  param_number_newItemsDetails: '1',
                  param_number_newItemsLocation: '0',
                  param_number_numberOfImagesPerRow: '3',
                  param_number_overlayAnimation: '1',
                  param_number_scrollDirection: '0',
                  'param_number_te-padding-slider': '50',
                  param_number_textsVerticalPadding: '18',
                  param_number_titlePlacement: '1',
                },
                propertiesSource: {
                  'alpha-param_color_actionsColorExpand': 'value',
                  'alpha-param_color_arrowsColor': 'value',
                  'alpha-param_color_backColor': 'value',
                  'alpha-param_color_bgColorExpand': 'value',
                  'alpha-param_color_descriptionColorExpand': 'value',
                  'alpha-param_color_foreColor': 'value',
                  'alpha-param_color_imageLoadingColor': 'value',
                  'alpha-param_color_itemBorderColor': 'value',
                  'alpha-param_color_itemDescriptionFontColor': 'value',
                  'alpha-param_color_itemDescriptionFontColorSlideshow':
                    'value',
                  'alpha-param_color_itemFontColor': 'value',
                  'alpha-param_color_itemFontColorSlideshow': 'value',
                  'alpha-param_color_itemIconColor': 'value',
                  'alpha-param_color_itemIconColorSlideshow': 'value',
                  'alpha-param_color_itemOpacity': 'value',
                  'alpha-param_color_itemShadowOpacityAndColor': 'value',
                  'alpha-param_color_loadMoreButtonBorderColor': 'value',
                  'alpha-param_color_loadMoreButtonColor': 'value',
                  'alpha-param_color_loadMoreButtonFontColor': 'value',
                  'alpha-param_color_oneColorAnimationColor': 'value',
                  'alpha-param_color_te-background-color-picker': 'value',
                  'alpha-param_color_textBoxBorderColor': 'value',
                  'alpha-param_color_textBoxFillColor': 'value',
                  'alpha-param_color_titleColorExpand': 'value',
                  param_boolean_addPanel: 'value',
                  param_boolean_allowDescription: 'value',
                  param_boolean_allowDownload: 'value',
                  param_boolean_allowSocial: 'value',
                  param_boolean_allowTitle: 'value',
                  param_boolean_dummyBooly: 'value',
                  param_boolean_itemEnableShadow: 'value',
                  param_boolean_loveButton: 'value',
                  param_boolean_previewHover: 'value',
                  param_boolean_shouldUnDock: 'value',
                  param_color_actionsColorExpand: 'theme',
                  param_color_arrowsColor: 'theme',
                  param_color_backColor: 'theme',
                  param_color_bgColorExpand: 'theme',
                  param_color_descriptionColorExpand: 'theme',
                  param_color_foreColor: 'theme',
                  param_color_imageLoadingColor: 'theme',
                  param_color_itemBorderColor: 'theme',
                  param_color_itemDescriptionFontColor: 'theme',
                  param_color_itemDescriptionFontColorSlideshow: 'theme',
                  param_color_itemFontColor: 'theme',
                  param_color_itemFontColorSlideshow: 'theme',
                  param_color_itemIconColor: 'theme',
                  param_color_itemIconColorSlideshow: 'theme',
                  param_color_itemOpacity: 'theme',
                  param_color_itemShadowOpacityAndColor: 'theme',
                  param_color_loadMoreButtonBorderColor: 'theme',
                  param_color_loadMoreButtonColor: 'theme',
                  param_color_loadMoreButtonFontColor: 'theme',
                  param_color_oneColorAnimationColor: 'theme',
                  'param_color_te-background-color-picker': 'theme',
                  param_color_textBoxBorderColor: 'theme',
                  param_color_textBoxFillColor: 'theme',
                  param_color_titleColorExpand: 'theme',
                  param_font_descriptionFontExpand: 'value',
                  param_font_itemDescriptionFont: 'value',
                  param_font_itemDescriptionFontSlideshow: 'value',
                  param_font_itemFont: 'value',
                  param_font_itemFontSlideshow: 'value',
                  param_font_loadMoreButtonFont: 'value',
                  param_font_loadMoreButtonText: 'value',
                  'param_font_text-editor-font': 'value',
                  'param_font_text-editor-font-1499774301866': 'value',
                  param_font_titleFontExpand: 'value',
                  param_number_collageDensity: 'value',
                  param_number_galleryHorizontalAlign: 'value',
                  param_number_galleryLayout: 'value',
                  param_number_gallerySize: 'value',
                  param_number_galleryVerticalAlign: 'value',
                  param_number_gridStyle: 'value',
                  param_number_hoveringBehaviour: 'value',
                  param_number_imageInfoType: 'value',
                  param_number_imageMargin: 'value',
                  param_number_isVertical: 'value',
                  param_number_itemBorderRadius: 'value',
                  param_number_itemBorderWidth: 'value',
                  param_number_itemShadowBlur: 'value',
                  param_number_itemShadowSize: 'value',
                  param_number_loveCounter: 'value',
                  param_number_newItemsDetails: 'value',
                  param_number_newItemsLocation: 'value',
                  param_number_numberOfImagesPerRow: 'value',
                  param_number_overlayAnimation: 'value',
                  param_number_scrollDirection: 'value',
                  'param_number_te-padding-slider': 'value',
                  param_number_textsVerticalPadding: 'value',
                  param_number_titlePlacement: 'value',
                },
                groups: {},
              },
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            activeModes: {},
          },
          preset: {
            rect: { width: 162, height: 176, x: 162, y: 176 },
            label: 'add_preset_galleries_grid_rollover_label',
            tags: { isFullWidth: false },
          },
        },
      ],
      compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
    },
    help: { hide: false, text: 'add_section_info_text_gridgalleries' },
  };
};

export { registerSection, getSection };
