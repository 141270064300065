import { Scope } from '../scope';
import { runMigration } from './runMigration';
import { checkIsNewSite } from '../utils';
import { skipMigrationAndUpdateFixedState } from './skipMigration';
import { shouldRunMigration } from './shouldRunMigration';

export const initAfterSectionMigrationDone = async (scope: Scope) => {
  scope.editorCoreAPI.addToEditorInit(scope.migrationHook.promise); // block the editor UI

  await scope.editorCoreAPI.hooks._dsIsReady.promise;

  const { documentServices } = scope.editorAPI;
  const isNewSite = checkIsNewSite(documentServices);

  await scope.editorCoreAPI.hooks.sectionsMigrationReady.promise;
  if (shouldRunMigration(scope, isNewSite)) {
    await runMigration(scope);
  } else {
    skipMigrationAndUpdateFixedState(scope);
  }
};
