import addPanelDataConsts from '@/addPanelDataConsts';
import * as util from '@/util';
import { createMyWidgetsSectionsAddPanelV1 } from './sectionsGenerator';
import * as addPanelData from '@/addPanelData';
function register() {
  if (util.addPanel.isOldAddPanelRegistryDisabled()) {
    return;
  }
  addPanelData.manager.override.registerOverrideGroupsForCategory(
    addPanelDataConsts.CATEGORIES_ID.INSTALLED_APPS,
    createMyWidgetsSectionsAddPanelV1,
  );
}

function unregister() {
  if (util.addPanel.isOldAddPanelRegistryDisabled()) {
    return;
  }
  addPanelData.manager.override.unRegisterOverrideGroupsForCategory(
    addPanelDataConsts.CATEGORIES_ID.INSTALLED_APPS,
  );
}

export { register, unregister };
