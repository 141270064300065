// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as designPanelUtils from '@/designPanelUtils';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import experiment from 'experiment';

const { getStyleId } = stateManagement.components.selectors;
function isWixAppsDesignPanel(props) {
  return props.switchPanelFunc || props.isWixApp;
}

export default {
  propTypes: {
    compType: PropTypes.string.isRequired,
  },

  getInitialState() {
    return this.getResetState(this.props);
  },
  getResetState(newProps) {
    const customizeDesignOnly =
      stateManagement.designPanel.selectors.getShowCustomizeDesignOnly(
        this.getEditorAPI().store.getState(),
      );
    const state = {
      advancedStyling: customizeDesignOnly,
      isStyleChanged: false,
    };
    if (customizeDesignOnly) {
      state.currentStyleId = newProps.styleId;
    }
    if (this.getStateFromProps) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(state, this.getStateFromProps(newProps));
    }
    return state;
  },
  UNSAFE_componentWillMount() {
    this.customizeDesignClicked = false;
    if (this.props.onlyAdvancedStyling) {
      this.openCustomizeDesign();
    }
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.selectedComponent, this.props.selectedComponent)) {
      this.customizeDesignClicked = false;
      this.setState(this.getResetState(nextProps));
    }
    this.syncStyleIds();
  },

  syncStyleIds() {
    const currentStyleId = this.getComponentStyleId();
    if (
      this.state.currentStyleId &&
      currentStyleId !== this.state.currentStyleId
    ) {
      this.setState({
        currentStyleId,
      });
    }
  },

  getComponentStyleId() {
    if (this.props.currentStyleId) {
      return this.props.currentStyleId;
    }
    return getStyleId(this.props.selectedComponent, this.getEditorAPI().dsRead);
  },

  getPropsForDesignPanel() {
    const props = Object.assign({}, this.props, {
      changePermanentState: this.changePermanentState,
      changeTempState: this.changeTempState,
      maintainOriginalState: this.maintainOriginalState,
      isSelectedItem: this.isSelectedItem,
      hideComponentSections: this.state.advancedStyling,
      onlyAdvancedStyling: this.props.onlyAdvancedStyling,
      shouldHideContentOverflow: this.state.advancedStyling,
      isCustomizeDesign: this.state.advancedStyling,
    });

    const customizeDesignOnly =
      stateManagement.designPanel.selectors.getShowCustomizeDesignOnly(
        this.getEditorAPI().store.getState(),
      );
    if (
      !customizeDesignOnly &&
      this.state.advancedStyling &&
      !this.props.onlyAdvancedStyling
    ) {
      props.onBackClick = this.customizeDesignCallBack;
    }
    return props;
  },

  onBreakPointToggleChange(value) {
    _.invoke(this, 'props.onBreakPointToggleChange', value);
  },

  openCustomizeDesign() {
    const editorAPI = this.getEditorAPI();
    const state = {};
    const styleId =
      this.props.currentStyleId ||
      getStyleId(this.props.selectedComponent, editorAPI.dsRead);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(state, { currentStyleId: styleId });

    editorAPI.dsActions.waitForChangesApplied(
      function () {
        const isMobile =
          !this.props.areAnyMobileOnlyComps &&
          editorAPI.dsRead.viewMode.get() ===
            editorAPI.dsRead.viewMode.VIEW_MODES.MOBILE;
        if (
          (experiment.isOpen('fastStyling') || isMobile) &&
          designPanelUtils.customizeDesignComponents.isFastStylingEnabled(
            this.props.compType,
          ) &&
          !isWixAppsDesignPanel(this.props)
        ) {
          const panelProps = {
            onlyAdvancedStyling: this.props.onlyAdvancedStyling,
            backButtonLabel: 'design_panel_back_button_label',
            onBackClick: _.noop,
            styleId,
          };
          editorAPI.replaceComponentPanel(
            constants.componentPanels.customizeDesign,
            panelProps,
          );
        } else {
          state.advancedStyling = true;
          this.customizeDesignClicked = true;
        }

        this.setState(state, function () {
          this.reportCustomizeDesignClickedToBI();
        });
      }.bind(this),
    );
  },

  reportCustomizeDesignClickedToBI() {
    const editorAPI = this.getEditorAPI();
    const compId = Array.isArray(this.props.selectedComponent)
      ? this.props.selectedComponent[0].id
      : this.props.selectedComponent.id;
    editorAPI.bi.event(
      coreBi.events.designPanel.DESIGN_PANEL_CUSTOMIZE_BUTTON_CLICK,
      {
        was_preset_clicked_current_session: this.state.isStyleChanged,
        component_type: this.props.compType,
        component_id: compId,
        chosen_style_id: this.state.currentStyleId,
      },
    );
  },
  customizeDesignCallBack() {
    this.setState({ advancedStyling: false });
    const editorAPI = this.getEditorAPI();
    const compId = Array.isArray(this.props.selectedComponent)
      ? this.props.selectedComponent[0].id
      : this.props.selectedComponent.id;
    editorAPI.bi.event(coreBi.events.ADVANCED_STYLE_PANEL.BACK_BUTTON_CLICK, {
      component_type: this.props.compType,
      component_id: compId,
    });
    this.setState(this.getResetState(this.props));
    if (_.isFunction(this.props.onCustomizeDesignCallBack)) {
      this.props.onCustomizeDesignCallBack();
    }
  },
  getAdvancedStylingProps() {
    const editorAPI = this.getEditorAPI();

    const props = {
      useMouseEvent: this.props.useMouseEvent,
      stretched: this.props.stretched,
      tabsUiMode: this.props.tabsUiMode,
      tabsUiSkin: this.props.tabsUiSkin,
      skinName: this.props.skinName,
      blockUnmountUpdate: this.props.blockUnmountUpdate,
      selectedSkin: this.props.selectedSkin,
      selectedComponent: this.props.selectedComponent,
      styleId: this.state.currentStyleId,
      compType: this.props.compType,
      configuration: this.props.configuration,
      maxStrokeWidth: this.props.maxStrokeWidth,
      panelPosition: this.actualPanelPosition,
      onlyAdvancedStyling: this.props.onlyAdvancedStyling,
      onPreviewStateChange: this.props.onPreviewStateChange,
      getDefaultState: this.props.getDefaultState,
      updateStyle:
        this.props.updateStyle ||
        ((styleDef) =>
          editorAPI.components.style.update(
            this.props.selectedComponent,
            styleDef,
          )),
      getStyle:
        this.props.getStyle ||
        (() => editorAPI.components.style.get(this.props.selectedComponent)),
      getFontThemes: this.props.getFontThemes,
    };

    if (_.has(this.props, 'shouldStartClosed')) {
      props.shouldStartClosed = this.props.shouldStartClosed;
    }

    return props;
  },
};
