import React from 'react';
import { ActionItem } from '@wix/wix-base-ui';
import styles from './actionItem.scss';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import Block from './block';
import { HelpBlock } from '../types';
import type { Props } from '../types';

const HirePro: React.FC<Props> = ({ hireProLink, onHireProClick }) => (
  <Block href={hireProLink} type={HelpBlock.HIRE_PRO} onClick={onHireProClick}>
    <ActionItem
      title="Editor_Help_Panel_Partners_Title"
      className={styles.helpHomeActionItem}
      illustration={<Symbol name="hireProfessional" />}
      description="Editor_Help_Panel_Partners_Description"
      tooltipContent="Editor_Help_Panel_Partners_Tooltip"
      actionIcon={
        <Symbol
          name="arrowRightSmall"
          customDimensions={{ width: 9, height: 8 }}
        />
      }
    />
  </Block>
);

export default HirePro;
