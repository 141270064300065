import React, { type FC } from 'react';
import Lottie from 'lottie-react';
import styles from './animationPanel.scss';
import animation from './animation.json';

const AnimationPanel: FC = () => {
  return (
    <div className={styles.animationPanel}>
      <div className={styles.bgWrapper}>
        <div className={styles.card_1} />
        <div className={styles.card_2} />
      </div>
      <Lottie animationData={animation} />
    </div>
  );
};

export default AnimationPanel;
