'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_Hlines',
    "subNavigationTitle": "add_section_label_Hlines",
    "presetTitle": "add_section_label_Hlines",
    "tooltipTitle": "add_section_label_Hlines",
    "automationId": "add-panel-section-horizontalLinesSection",
    "subNavigationHide": false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/horizontalLinesSection_en/horizontalLinesSection_en.png',
        'imageHover': null,
        'items': [{
            'id': 'Shape_Horizontal_Lines_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.SolidLine',
                'layout': {
                    'width': 304,
                    'height': 5,
                    'x': 10,
                    'y': 29,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.FiveGridLine',
                'props': {
                    'type': 'FiveGridLineProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fullScreenModeOn': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#424242', 'lnw': '1'},
                        'propertiesSource': {'brd': 'value', 'lnw': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.FiveGridLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.SolidLine'
                },
                'activeModes': {},
                'id': 'comp-je2tp4jd'
            },
            'preset': {'rect': {'width': 324, 'height': 43, 'x': 0, 'y': -1}, 'tags': null}
        }, {
            'id': 'Shape_Horizontal_Lines_2',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.SolidLine',
                'layout': {
                    'width': 304,
                    'height': 5,
                    'x': 10,
                    'y': 62,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.FiveGridLine',
                'props': {
                    'type': 'FiveGridLineProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fullScreenModeOn': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#424242', 'lnw': '3'},
                        'propertiesSource': {'brd': 'value', 'lnw': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.FiveGridLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.SolidLine'
                },
                'activeModes': {},
                'id': 'comp-je2tqs6f'
            },
            'preset': {'rect': {'width': 324, 'height': 43, 'x': 0, 'y': 42}, 'tags': null}
        }, {
            'id': 'Shape_Horizontal_Lines_3',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.SolidLine',
                'layout': {
                    'width': 304,
                    'height': 6,
                    'x': 10,
                    'y': 97,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.FiveGridLine',
                'props': {
                    'type': 'FiveGridLineProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fullScreenModeOn': false
                },
                'mobileHints': {
                    'type': 'MobileHints',
                    'author': 'duplicate',
                    'originalCompId': 'comp-je2tqs6f',
                    'recommendedScale': 1,
                    'recommendedWidth': 280,
                    'recommendedHeight': 5
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#424242', 'lnw': '6'},
                        'propertiesSource': {'brd': 'value', 'lnw': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.FiveGridLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.SolidLine'
                },
                'activeModes': {},
                'id': 'comp-je790qwx'
            },
            'preset': {'rect': {'width': 324, 'height': 36, 'x': 0, 'y': 85}, 'tags': null}
        }, {
            'id': 'Shape_Horizontal_Lines_4',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.SolidLine',
                'layout': {
                    'width': 304,
                    'height': 10,
                    'x': 10,
                    'y': 133,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.FiveGridLine',
                'props': {
                    'type': 'FiveGridLineProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fullScreenModeOn': false
                },
                'mobileHints': {
                    'type': 'MobileHints',
                    'author': 'duplicate',
                    'originalCompId': 'comp-je2tqs6f',
                    'recommendedScale': 1,
                    'recommendedWidth': 280,
                    'recommendedHeight': 5
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#424242', 'lnw': '10'},
                        'propertiesSource': {'brd': 'value', 'lnw': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.FiveGridLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.SolidLine'
                },
                'activeModes': {},
                'id': 'comp-je79f488'
            },
            'preset': {'rect': {'width': 324, 'height': 42, 'x': 0, 'y': 121}, 'tags': null}
        }, {
            'id': 'Shape_Horizontal_Lines_5',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.DottedLine',
                'layout': {
                    'width': 304,
                    'height': 5,
                    'x': 10,
                    'y': 175,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.FiveGridLine',
                'props': {
                    'type': 'FiveGridLineProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fullScreenModeOn': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#1D2CF3', 'lnw': '5'},
                        'propertiesSource': {'alpha-brd': 'value', 'brd': 'value', 'lnw': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.FiveGridLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.DottedLine'
                },
                'activeModes': {},
                'id': 'comp-je2tu9px'
            },
            'preset': {'rect': {'width': 324, 'height': 36, 'x': 0, 'y': 163}, 'tags': null}
        }, {
            'id': 'Shape_Horizontal_Lines_6',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.DashedLine',
                'layout': {
                    'width': 304,
                    'height': 6,
                    'x': 10,
                    'y': 211,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.FiveGridLine',
                'props': {
                    'type': 'FiveGridLineProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fullScreenModeOn': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#B45AD3', 'lnw': '6'},
                        'propertiesSource': {'alpha-brd': 'value', 'brd': 'value', 'lnw': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.FiveGridLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.DashedLine'
                },
                'activeModes': {},
                'id': 'comp-je2tsrk8'
            },
            'preset': {'rect': {'width': 324, 'height': 36, 'x': 0, 'y': 198}, 'tags': null}
        }, {
            'id': 'Shape_Horizontal_Lines_7',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.DashedLine',
                'layout': {
                    'width': 304,
                    'height': 5,
                    'x': 10,
                    'y': 248,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.FiveGridLine',
                'props': {
                    'type': 'FiveGridLineProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fullScreenModeOn': false
                },
                'mobileHints': {
                    'type': 'MobileHints',
                    'author': 'duplicate',
                    'originalCompId': 'comp-je2trqjq',
                    'recommendedScale': 1,
                    'recommendedWidth': 280,
                    'recommendedHeight': 5
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#64D2B5', 'lnw': '4'},
                        'propertiesSource': {'alpha-brd': 'value', 'brd': 'value', 'lnw': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.FiveGridLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.DashedLine'
                },
                'activeModes': {},
                'id': 'comp-jecqvtf3'
            },
            'preset': {'rect': {'width': 324, 'height': 35, 'x': 0, 'y': 234}, 'tags': null}
        }, {
            'id': 'Shape_Horizontal_Lines_8',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.DashedLine',
                'layout': {
                    'width': 304,
                    'height': 5,
                    'x': 10,
                    'y': 283,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.FiveGridLine',
                'props': {
                    'type': 'FiveGridLineProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fullScreenModeOn': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#FF4F4F', 'lnw': '1'},
                        'propertiesSource': {'alpha-brd': 'value', 'brd': 'value', 'lnw': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.FiveGridLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.DashedLine'
                },
                'activeModes': {},
                'id': 'comp-je2trqjq'
            },
            'preset': {'rect': {'width': 324, 'height': 32, 'x': 0, 'y': 269}, 'tags': null}
        }, {
            'id': 'Shape_Horizontal_Lines_9',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.DoubleLine',
                'layout': {
                    'width': 304,
                    'height': 13,
                    'x': 10,
                    'y': 315,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.FiveGridLine',
                'props': {
                    'type': 'FiveGridLineProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fullScreenModeOn': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#FFD45A', 'dst': '6', 'lnw1': '6', 'lnw2': '1px'},
                        'propertiesSource': {
                            'alpha-brd': 'value',
                            'brd': 'value',
                            'dst': 'value',
                            'lnw1': 'value',
                            'lnw2': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.FiveGridLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.DoubleLine'
                },
                'activeModes': {},
                'id': 'comp-je2twqz4'
            },
            'preset': {'rect': {'width': 324, 'height': 39, 'x': 0, 'y': 301}, 'tags': null}
        }, {
            'id': 'Shape_Horizontal_Lines_10',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.ZigzagLineSkin',
                'layout': {
                    'width': 304,
                    'height': 10,
                    'x': 10,
                    'y': 353,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.FiveGridLine',
                'props': {
                    'type': 'FiveGridLineProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fullScreenModeOn': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#FFCBAA', 'zigzagsize': '10px'},
                        'propertiesSource': {'alpha-brd': 'value', 'brd': 'value', 'zigzagsize': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.FiveGridLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.ZigzagLineSkin'
                },
                'activeModes': {},
                'id': 'comp-je2txfs8'
            },
            'preset': {'rect': {'width': 324, 'height': 39, 'x': 0, 'y': 340}, 'tags': null}
        }, {
            'id': 'Shape_Horizontal_Lines_11',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.line.ArrowRightLine',
                'layout': {
                    'width': 304,
                    'height': 5,
                    'x': 10,
                    'y': 394,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.FiveGridLine',
                'props': {
                    'type': 'FiveGridLineProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fullScreenModeOn': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-brd': '1', 'brd': '#424242'},
                        'propertiesSource': {'alpha-brd': 'value', 'brd': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.FiveGridLine',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.line.ArrowRightLine'
                },
                'activeModes': {},
                'id': 'comp-je2twxtn'
            },
            'preset': {'rect': {'width': 324, 'height': 51, 'x': 0, 'y': 379}, 'tags': null}
        }],
        'compTypes': ['wysiwyg.viewer.components.FiveGridLine']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_Hlines'}
}
