import addPanelDataConsts from '@/addPanelDataConsts';
import * as i18n from '@/i18n';
import * as util from '@/util';
import tpaConstants from '../../constants/constants';
import { addApp } from '../../services/tpaAddAppService';
import * as superApps from '../../superApps/superApps';
import superAppsConstants from '../../superApps/superAppsConstants';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';
import * as addPanelInfra from '@/addPanelInfra';

const lang = util.languages.getLanguageCode();

const chatAppDefinitionId = superAppsConstants.WIX_CHAT.APP_DEF_ID;
const chatButtonDefinitionId = 'bcf2aea9-53d2-4139-9a63-3ad7ecc93d28';

const BUTTON_GROUP_SECTION_INDEX = 9;

export const registerSection = (editorAPI: AnyFixMe) => {
  editorAPI.addPanel.registerSectionToCategoryByGroup(
    addPanelDataConsts.CATEGORIES_ID.BUTTON,
    0,
    BUTTON_GROUP_SECTION_INDEX,
    () => getButtonSection(editorAPI),
  );
};

const getButtonSection = (editorAPI: AnyFixMe) => {
  if (superApps.isInstalled(chatAppDefinitionId)) {
    return getChatButtonSection(editorAPI);
  }
  return null;
};

const installApp = (
  editorAPI: AnyFixMe,
  { structure, coords, dropContainer }: AnyFixMe,
) => {
  const addAppCb = (resolve: AnyFixMe, reject: AnyFixMe) => {
    const type = tpaConstants.APP.TYPE.PLATFORM_ONLY;
    const biInitiator = tpaConstants.BI.initiator.EDITOR;
    const options = {
      originType: tpaConstants.BI.type.ADD_APP_ADD_PANEL,
      disableAddCompleteSave: true,
    };
    const callback = (data: AnyFixMe) =>
      data?.success ? resolve(data) : reject(data);
    const platformOrigin = {
      type: EditorType.Classic,
      initiator: InstallInitiator.Editor,
      info: {
        type: InstallationOriginType.AddPanel,
        coords,
      },
    };
    addApp(
      editorAPI,
      chatButtonDefinitionId,
      null,
      null,
      type,
      biInitiator,
      false,
      {},
      options,
      callback,
      platformOrigin,
    );
  };

  return new Promise(function (resolve, reject) {
    return addPanelInfra.addPanelUtils
      .attemptToAddComponent(
        editorAPI,
        structure,
        'contact',
        'add_section_label_wix_chat_button',
        [],
        structure.id,
        'add_panel',
        null,
        {
          position: coords,
          skipHistorySnapshot: true,
          dropContainer,
        },
      )
      .then(() => {
        return addAppCb(resolve, reject);
      });
  });
};

export const addButton = (
  editorAPI: AnyFixMe,
  { structure, coords, dropContainer }: AnyFixMe,
) =>
  Promise.resolve(
    installApp(editorAPI, {
      structure,
      coords,
      dropContainer,
    }),
  );

const onDrop =
  (editorAPI: AnyFixMe) =>
  (coords: AnyFixMe, compDef: AnyFixMe, dropContainer: AnyFixMe) =>
    addButton(editorAPI, {
      structure: compDef.structure,
      coords,
      dropContainer,
    });

const onClick = (editorAPI: AnyFixMe) => (structure: AnyFixMe) =>
  addButton(editorAPI, { structure, coords: null, dropContainer: null });

export const getChatButtonSection = (editorAPI: AnyFixMe) => ({
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  subNavigationTitle: 'add_section_label_wix_chat_button',
  title: 'add_section_heading_wix_chat_button',
  presetTitle: 'add_section_heading_wix_chat_button_tooltip_title',
  tooltipTitle: 'add_section_heading_wix_chat_button_tooltip_description',
  automationId: 'add-panel-section-chatButtonSection',
  subNavigationHide: false,
  showSectionHeader: true,
  appDefinitionId: 'bcf2aea9-53d2-4139-9a63-3ad7ecc93d28',

  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
    iconEnabledForComps: {},
  },

  props: {
    onClick: onClick(editorAPI),
    onDrop: onDrop(editorAPI),
    image: `addPanelData/sections/chatButton_${lang}/chat_button_${lang}.v4.png`,
    imageHover: `addPanelData/sections/chatButton_${lang}/hover_${lang}/chat_button__hover_${lang}.v4.png`,
    items: [
      {
        appDefinitionId: 'bcf2aea9-53d2-4139-9a63-3ad7ecc93d28',
        id: 'chatButton1',
        structure: {
          type: 'Container',
          id: 'comp-chatButton1',
          components: [
            {
              layout: {
                width: 142,
                height: 48,
                x: 0,
                y: 0,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false,
              },
              componentType: 'wixui.StylableButton',
              type: 'Component',
              id: 'comp-jyfm7b6q',
              skin: 'wixui.skins.Skinless',
              data: {
                svgId: 'a2d129_8434efeb618c4282bde0d0a38703b377.svg',
                type: 'StylableButton',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                },
                label: i18n.translate('chatbutton_preset_button_lets_chat'),
                id: 'dataItem-jyfm7b6r',
              },
              props: {
                type: 'StylableButtonProperties',
              },
              style: {
                type: 'ComponentStyle',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  pageId: 'c1dmp',
                },
                skin: 'wixui.skins.Skinless',
                style: {
                  properties: {
                    '$st-css':
                      ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease;background: value(site_2_3);padding: 8px;padding-right: 16px;padding-left: 16px}.root:hover{border: 1px solid #000000;background: rgba(13,94,186,0.7)}.root:hover::label{color: rgba(255,255,255,0.7)}.root:disabled{background: value(site_1_3)}.root:disabled::label{color: rgba(255,255,255,0.6)}.root:disabled::icon{fill: rgba(255,255,255,0.6)}.root::container{transition: inherit;flex-direction: row-reverse}.root::label{transition: inherit;letter-spacing: 0em;font-family: avenir-lt-w01_35-light1475496,sans-serif,HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;font-size: 16px;color: value(site_1_1);margin: 0px 0px 0px 4px}.root::icon{transition: inherit;fill: value(site_1_1);width: 22px;height: 22px;margin: 0px 4px 0px 0px}.root:hover::icon{fill: rgba(255,255,255,0.7)}",
                  },
                  propertiesSource: {
                    '$st-css': 'value',
                  },
                },
              },
              connections: {
                type: 'ConnectionList',
                items: [
                  {
                    type: 'ConnectionItem',
                    role: 'ChatButton',
                    controllerId: 'dataItem-jyfm7b67',
                    isPrimary: true,
                  },
                ],
                id: 'connection-jyfm7b77',
              },
              mobileStructure: {
                layout: {
                  width: 160,
                  height: 44,
                  x: 0,
                  y: 0,
                  scale: 0.9333333333333333,
                  rotationInDegrees: 0,
                  fixedPosition: false,
                },
                metaData: {
                  originalCompId: 'comp-jyfm7b6q',
                  author: 'duplicate',
                },
              },
            },
          ],
          layout: {
            width: 142,
            height: 48,
            x: 10,
            y: 20,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'platform.components.AppWidget',
          data: {
            type: 'AppController',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            applicationId: 'bcf2aea9-53d2-4139-9a63-3ad7ecc93d28',
            name: 'WixChat',
            controllerType: 'WixChat',
            id: 'dataItem-jyfm7b67',
          },
          style: 'appWidget1',
          connections: {
            type: 'ConnectionList',
            items: [
              {
                type: 'WixCodeConnectionItem',
                role: 'wixChatButton1',
              },
            ],
            id: 'connection-jyfm7b7b',
          },
          mobileStructure: {
            layout: {
              width: 160,
              height: 44,
              x: 80,
              y: 95,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            metaData: {
              originalCompId: 'comp-jyfm7b5f',
              author: 'duplicate',
            },
          },
          activeModes: {},
        },
        preset: {
          rect: {
            width: 160,
            height: 78,
            x: 0,
            y: 0,
          },
          tags: null as AnyFixMe,
        },
      },
      {
        appDefinitionId: 'bcf2aea9-53d2-4139-9a63-3ad7ecc93d28',
        id: 'chatButton2',
        structure: {
          type: 'Container',
          id: 'comp-chatButton2',
          components: [
            {
              layout: {
                width: 142,
                height: 48,
                x: 0,
                y: 0,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false,
              },
              componentType: 'wixui.StylableButton',
              type: 'Component',
              id: 'comp-jyfm7b6q',
              skin: 'wixui.skins.Skinless',
              data: {
                type: 'StylableButton',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                },
                label: i18n.translate('chatbutton_preset_button_message_us'),
                id: 'dataItem-jyfm7b6r',
              },
              props: {
                type: 'StylableButtonProperties',
              },
              style: {
                type: 'ComponentStyle',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  pageId: 'c1dmp',
                },
                skin: 'wixui.skins.Skinless',
                style: {
                  properties: {
                    '$st-css':
                      ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease;background: value(site_2_3);padding: 8px;padding-right: 16px;padding-left: 16px}.root:hover{border: 1px solid #000000;background: rgba(13,94,186,0.7)}.root:hover::label{color: rgba(255,255,255,0.7)}.root:disabled{background: value(site_1_3)}.root:disabled::label{color: rgba(255,255,255,0.6)}.root:disabled::icon{fill: rgba(255,255,255,0.6)}.root::container{transition: inherit;flex-direction: row}.root::label{transition: inherit;letter-spacing: 0em;font-family: avenir-lt-w01_35-light1475496,sans-serif,HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;font-size: 16px;color: value(site_1_1);margin: 0px 4px 0px 0px}.root::icon{transition: inherit;fill: value(site_1_1);width: 13px;height: 13px;margin: 0px 0px 0px 4px;display: none}.root:hover::icon{fill: rgba(255,255,255,0.7)}",
                  },
                  propertiesSource: {
                    '$st-css': 'value',
                  },
                },
              },
              connections: {
                type: 'ConnectionList',
                items: [
                  {
                    type: 'WixCodeConnectionItem',
                    role: 'button13',
                  },
                  {
                    type: 'ConnectionItem',
                    role: 'ChatButton',
                    controllerId: 'dataItem-jyfm7b67',
                    isPrimary: true,
                  },
                ],
                id: 'connection-jyfm7b77',
              },
              mobileStructure: {
                layout: {
                  width: 160,
                  height: 44,
                  x: 0,
                  y: 0,
                  scale: 0.9333333333333333,
                  rotationInDegrees: 0,
                  fixedPosition: false,
                },
                metaData: {
                  originalCompId: 'comp-jyfm7b6q',
                  author: 'duplicate',
                },
              },
            },
          ],
          layout: {
            width: 142,
            height: 48,
            x: 10,
            y: 20,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'platform.components.AppWidget',
          data: {
            type: 'AppController',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            applicationId: 'bcf2aea9-53d2-4139-9a63-3ad7ecc93d28',
            name: 'WixChat',
            controllerType: 'WixChat',
            id: 'dataItem-jyfm7b67',
          },
          style: 'appWidget1',
          connections: {
            type: 'ConnectionList',
            items: [
              {
                type: 'WixCodeConnectionItem',
                role: 'wixChatButton1',
              },
            ],
            id: 'connection-jyfm7b7b',
          },
          mobileStructure: {
            layout: {
              width: 160,
              height: 44,
              x: 80,
              y: 95,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            metaData: {
              originalCompId: 'comp-jyfm7b5f',
              author: 'duplicate',
            },
          },
          activeModes: {},
        },
        preset: {
          rect: {
            width: 160,
            height: 78,
            x: 160,
            y: 0,
          },
          tags: null as AnyFixMe,
        },
      },
    ],
    compTypes: ['platform.components.AppWidget'],
  },

  help: {
    hide: false,
    text: 'add_section_label_pay_button_tooltip_description',
  },
});
