/* eslint-disable @typescript-eslint/no-unused-vars */
import type { Shell } from '@/apilib';
import type { CompRef } from 'types/documentServices';
import { ComponentStageMode, type StageMode } from './componentStageMode';

export const createComponentsStageModesApi = (_shell: Shell) => {
  function enter(compRef: CompRef, stageMode: StageMode): void {}
  function exit(compRef: CompRef, stageMode: StageMode): void {}
  function isIn(compRef: CompRef, stageMode: StageMode): boolean {
    return false;
  }

  return {
    StageMode: ComponentStageMode,
    enter,
    exit,
    isIn,
  };
};
