:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._block_1b041_7 {
  padding-bottom: 14px; }

._carouselWrapper_1b041_10 {
  margin: 0 24px; }

._carouselItemsList_1b041_13 {
  display: flex;
  overflow-x: scroll;
  margin: 0 -24px;
  --itemWidth: 288px;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  padding-right: 24px;
  margin-bottom: 14px; }

._carouselItemsList_1b041_13::-webkit-scrollbar {
  width: 0;
  height: 0; }

._carouselItemsList_1b041_13::after {
  content: '';
  flex: 1px 0 0; }

._carouselItem_1b041_13 {
  scroll-snap-align: center;
  height: 156px;
  flex: var(--itemWidth) 0 0;
  border-radius: 3px;
  margin-left: 20px;
  cursor: pointer;
  background-position: center;
  transition: all 0.6s ease-in-out;
  position: relative; }

._carouselItem_1b041_13:hover ._playWrapper_1b041_42 {
  visibility: visible; }

._carouselItem_1b041_13:nth-child(1) {
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/editorAssistant/mediaCarousel/cover-1.svg");
  background-size: 100%; }
  ._carouselItem_1b041_13:nth-child(1):hover {
    background-size: 110%; }

._carouselItem_1b041_13:nth-child(2) {
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/editorAssistant/mediaCarousel/cover-2.svg");
  background-size: 100%; }
  ._carouselItem_1b041_13:nth-child(2):hover {
    background-size: 110%; }

._carouselItem_1b041_13:nth-child(3) {
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/editorAssistant/mediaCarousel/cover-3.svg");
  background-size: 100%; }
  ._carouselItem_1b041_13:nth-child(3):hover {
    background-size: 110%; }

._carouselItem_1b041_13:nth-child(4) {
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/editorAssistant/mediaCarousel/cover-4.svg");
  background-size: 100%; }
  ._carouselItem_1b041_13:nth-child(4):hover {
    background-size: 110%; }

._carouselItem_1b041_13:nth-child(5) {
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/editorAssistant/mediaCarousel/cover-5.svg");
  background-size: 100%; }
  ._carouselItem_1b041_13:nth-child(5):hover {
    background-size: 110%; }

._carouselItem_1b041_13:nth-child(6) {
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/editorAssistant/mediaCarousel/cover-6.svg");
  background-size: 100%; }
  ._carouselItem_1b041_13:nth-child(6):hover {
    background-size: 110%; }

._carouselItem_1b041_13:nth-child(7) {
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/editorAssistant/mediaCarousel/cover-7.svg");
  background-size: 100%; }
  ._carouselItem_1b041_13:nth-child(7):hover {
    background-size: 110%; }

._carouselItem_1b041_13:nth-child(8) {
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/editorAssistant/mediaCarousel/cover-8.svg");
  background-size: 100%; }
  ._carouselItem_1b041_13:nth-child(8):hover {
    background-size: 110%; }

._carouselItem_1b041_13:nth-child(9) {
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/editorAssistant/mediaCarousel/cover-9.svg");
  background-size: 100%; }
  ._carouselItem_1b041_13:nth-child(9):hover {
    background-size: 110%; }

._carouselItem_1b041_13:nth-child(10) {
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/editorAssistant/mediaCarousel/cover-10.svg");
  background-size: 100%; }
  ._carouselItem_1b041_13:nth-child(10):hover {
    background-size: 110%; }

._carouselItem_1b041_13:first-child {
  margin-left: 24px; }

._carouselItem_1b041_13:last-child {
  margin-right: 24px; }

._carouselItemHeader_1b041_111 {
  width: 220px;
  margin: 16px;
  font-size: 16px !important;
  color: #fff; }

._carouselItemTiming_1b041_117 {
  position: absolute;
  bottom: 16px;
  left: 16px;
  color: #fff; }

._playWrapper_1b041_42 {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(43, 86, 114, 0.7); }

._playButton_1b041_134 path {
  fill: #fff; }

._playButton_1b041_134:hover path {
  fill: #116dff; }

._controls_1b041_140 {
  display: flex;
  align-items: center;
  justify-content: center; }
  ._controls_1b041_140 ._control_1b041_140:first-child .symbol svg {
    margin-right: 2px; }
  ._controls_1b041_140 ._control_1b041_140:last-child .symbol svg {
    margin-left: 1px; }

._control_1b041_140 {
  background: none;
  width: 24px;
  height: 24px; }
  ._control_1b041_140:last-child {
    margin-left: 8px; }
  ._control_1b041_140[disabled] {
    background: none; }
    ._control_1b041_140[disabled]:active, ._control_1b041_140[disabled]:hover {
      background: none; }
    ._control_1b041_140[disabled].control-icon-button .symbol path,
    ._control_1b041_140[disabled].control-icon-button .symbol:active path {
      fill: #c8c8c8; }
  ._control_1b041_140:hover {
    background: #e7f0ff; }
  ._control_1b041_140:active {
    background: #116dff; }
  ._control_1b041_140.control-icon-button .symbol path {
    fill: #116dff; }
  ._control_1b041_140.control-icon-button .symbol:active path {
    fill: #fff; }
