:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._scanSwitchLayoutPresetPanel_1lm1s_7 {
  height: 100%;
  overflow-y: scroll;
  background-color: #f7f8f8; }

._scanSwitchLayoutPresetForm_1lm1s_12 {
  padding: 20px 20px 100px 20px; }

._scanSwitchLayoutPresetTextForm_1lm1s_15,
._scanSwitchLayoutPresetImageForm_1lm1s_16 {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  margin-bottom: 10px; }
  ._scanSwitchLayoutPresetTextForm_1lm1s_15 p,
  ._scanSwitchLayoutPresetImageForm_1lm1s_16 p {
    margin-bottom: 10px; }

._scanSwitchLayoutPresetTitle_1lm1s_25 {
  margin: 40px 0 20px 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between; }

._scanSwitchLayoutPresetTitleButton_1lm1s_32 {
  margin: 0 5px; }

._scanSwitchLayoutPresetInputContainer_1lm1s_35 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start; }

._scanSwitchLayoutPresetInput_1lm1s_35 {
  width: 100%;
  max-width: 125px;
  outline: #17191c;
  padding: 0 10px;
  height: 36px;
  border: none;
  margin-bottom: 10px; }
  ._scanSwitchLayoutPresetInput_1lm1s_35:hover {
    cursor: pointer;
    background-color: #d6e6ff;
    color: #424851; }
  ._scanSwitchLayoutPresetInput_1lm1s_35:focus {
    cursor: text;
    background-color: #fff;
    border-color: #80b1ff; }

._scanSwitchLayoutPresetCheckbox_1lm1s_58 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px; }
  ._scanSwitchLayoutPresetCheckbox_1lm1s_58 input {
    margin-right: 10px; }

._scanSwitchLayoutPresetLoaderContainer_1lm1s_66 {
  background-color: #f7f8f8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0; }

._scanSwitchLayoutPresetLoader_1lm1s_66 {
  background-color: #f7f8f8;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%); }

._scanSwitchLayoutPresetMessage_1lm1s_81 {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 50px;
  font-size: 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff; }
  ._scanSwitchLayoutPresetMessage_1lm1s_81 span {
    cursor: pointer;
    border: 2px solid;
    border-radius: 50%;
    padding: 2px 6px; }
