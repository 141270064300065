// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';

export default function (WrappedComp) {
  // eslint-disable-next-line react/prefer-es6-class
  const Wrapper = createReactClass({
    mixins: [core.mixins.editorAPIMixin],
    displayName: `closeMessageWrapperFor-${WrappedComp.displayName}`,
    propTypes: {
      onClose: PropTypes.func,
      shouldCloseModal: PropTypes.bool,
      closeModalMessage: PropTypes.any,
      panelName: PropTypes.string.isRequired,
    },
    componentWillUnmount() {
      if (this.props.onClose) {
        this.props.onClose(this.props.closeModalMessage);
      }
    },
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.shouldCloseModal) {
        this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
      }
    },
    render() {
      return React.createElement(WrappedComp, this.props);
    },
  });
  Wrapper._BaseClass = WrappedComp;
  return Wrapper;
}
