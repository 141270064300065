import { EditorAPIKey } from '@/apis';
import { ImageUploadToStageApiKey } from './publicApiKey';
import { bootstrap, ImageUploadToStageApi } from './imageUploadToStageApi';
import { BaseEntryScope, createEntryPoint } from '@/apilib';

export class ImageUploadToStageScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
}

export const ImageUploadToStageEntryPoint = createEntryPoint({
  name: 'ImageUploadToStage',
  Scope: ImageUploadToStageScope,
  publicApi({ contributeApi }) {
    contributeApi(ImageUploadToStageApiKey, ImageUploadToStageApi);
  },
  initialize(scope: ImageUploadToStageScope) {
    bootstrap(scope);
  },
});
