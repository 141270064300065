import type { Shell } from '@/apilib';
import { EditorAPIKey } from '@/apis';
import * as stateManagement from '@/stateManagement';
import { constants as platformConstants } from '@/platform';

const second = 1000;
const COMPONENT_PANEL_PRELOADER_TIMEOUT = 15 * second;
const { actions, selectors } = stateManagement.panels;
const { updatePanel } = actions;
const { selectOpenPanels } = selectors;

export const createComponentPanelPreloaderApi = (shell: Shell) => {
  const editorAPI = shell.getAPI(EditorAPIKey);

  const preloaderTimeoutMap: Map<string, number> = new Map<string, number>();

  function getPanelState(token: string) {
    const panels = selectOpenPanels(editorAPI.store.getState());
    return panels.find(
      (x) =>
        x.name === platformConstants.panelTypes.COMPONENT_PANEL &&
        x.props.token === token,
    );
  }

  async function showPreloader(token: string) {
    const platformPanelOptions = getPanelState(token);

    if (
      !platformPanelOptions ||
      platformPanelOptions.props.preloaderHiddenBeforeShown
    ) {
      return;
    }

    editorAPI.store.dispatch(
      updatePanel(platformConstants.panelTypes.COMPONENT_PANEL, {
        showPreloader: true,
      }),
    );

    const preloaderTimeout = window.setTimeout(() => {
      preloaderTimeoutMap.delete(token);

      const platformPanelOptions = getPanelState(token);
      if (!platformPanelOptions) {
        return;
      }

      editorAPI.store.dispatch(
        updatePanel(platformConstants.panelTypes.COMPONENT_PANEL, {
          preloaderReachedTimeout: true,
        }),
      );
    }, COMPONENT_PANEL_PRELOADER_TIMEOUT);

    preloaderTimeoutMap.set(token, preloaderTimeout);
  }

  async function hidePreloader(token: string) {
    const platformPanelOptions = getPanelState(token);

    const preloaderTimeout = preloaderTimeoutMap.get(token);
    preloaderTimeoutMap.delete(token);

    if (!platformPanelOptions) {
      return;
    }

    editorAPI.store.dispatch(
      updatePanel(platformConstants.panelTypes.COMPONENT_PANEL, {
        showPreloader: false,
        preloaderHiddenBeforeShown: !platformPanelOptions.props.showPreloader,
      }),
    );

    if (preloaderTimeout) {
      clearTimeout(preloaderTimeout);
    }
  }

  return {
    showPreloader,
    hidePreloader,
  };
};
