import React from 'react';
import { cx } from '@/util';
import { translate } from '@/i18n';
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import * as symbols from '@wix/santa-editor-symbols';
import { RichText, TextLabel, Tooltip } from '@wix/wix-base-ui';

interface MobileOnlyIndicatorProps {
  componentUIColor?: ValueOf<typeof constants.COMPONENT_UI_COLORS>;
  componentUITheme?: ValueOf<typeof constants.COMPONENT_UI_THEMES>;
  openHelpCenter?: (
    helpId: string,
    props?: object,
    biHelpParams?: object,
  ) => void;
  className?: string;
  dimmed: boolean;
}

export default class extends React.Component<MobileOnlyIndicatorProps> {
  static displayName = 'MobileOnlyIndicator';
  getMobileOnlyCompIndicatorClasses = () => {
    return {
      [this.props.className]: !!this.props.className,
      'mobile-only-comp-indicator': true,
      'components-ui-color-orange':
        this.props.componentUIColor === constants.COMPONENT_UI_COLORS.ORANGE,
      'components-ui-theme-dark':
        this.props.componentUITheme === constants.COMPONENT_UI_THEMES.DARK,
      dimmed: this.props.dimmed,
    };
  };

  getMobileOnlyIndicatorTooltipContent = () => {
    return (
      <div className="mobile-only-indicator-tooltip-content">
        <TextLabel
          value="component_label_mobile_element_tooltip_text"
          enableEllipsis={false}
          type="T07"
        />
        {this.props.openHelpCenter ? (
          <RichText>
            <a
              onClick={() =>
                this.props.openHelpCenter(helpIds.MOBILE_ONLY.INDICATION)
              }
            >
              {translate('component_label_mobile_element_tooltip_link')}
            </a>
          </RichText>
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <span
        key="mobileOnlyCompIndicator"
        className={cx(this.getMobileOnlyCompIndicatorClasses())}
      >
        <Tooltip
          interactive={true}
          marginTop={5}
          content={this.getMobileOnlyIndicatorTooltipContent()}
        >
          <span className="symbol-wrapper">
            <symbols.symbol name="mobileOnlyIndicator" />
          </span>
        </Tooltip>
      </span>
    );
  }
}
