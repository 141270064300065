@charset "UTF-8";
:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._root_9a091_8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 17px 24px 16px 24px;
  padding-bottom: 7px;
  border-bottom: 1px solid #dfe5eb; }

._addPage_9a091_16 {
  display: flex;
  align-items: center;
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  color: #116dff;
  cursor: pointer; }
  ._addPage_9a091_16 svg {
    height: 8px;
    width: 8px;
    margin-right: 6px; }
  ._addPage_9a091_16 path {
    fill: #116dff; }
  ._addPage_9a091_16:hover {
    color: #5999ff; }
    ._addPage_9a091_16:hover path {
      fill: #5999ff; }
