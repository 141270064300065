// @ts-nocheck
import * as stateManagement from '@/stateManagement';

const trackAppUpgrade = function (editorAPI, applicationId) {
  editorAPI.store.dispatch(
    stateManagement.tpa.actions.addAppToTrackForUpgrade(applicationId),
  );
};

export { trackAppUpgrade };
