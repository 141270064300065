const item0 = require("./item0.js");
const item1 = require("./item1.js");
const item2 = require("./item2.js");
const item3 = require("./item3.js");
const item4 = require("./item4.js");
const item5 = require("./item5.js");
const item6 = require("./item6.js");
const item7 = require("./item7.js");
const item8 = require("./item8.js");
const item9 = require("./item9.js");
const item10 = require("./item10.js");
const item12 = require("./item12.js");
const item13 = require("./item13.js");
const item14 = require("./item14.js");
const item15 = require("./item15.js");
const item16 = require("./item16.js");
const item17 = require("./item17.js");
const item18 = require("./item18.js");
const item19 = require("./item19.js");
const item20 = require("./item20.js");
const item21 = require("./item21.js");
const item22 = require("./item22.js");
const item23 = require("./item23.js");
const item24 = require("./item24.js");
const item25 = require("./item25.js");
const item26 = require("./item26.js");
const item27 = require("./item27.js");
const item28 = require("./item28.js");
const item29 = require("./item29.js");
const item30 = require("./item30.js");
const item31 = require("./item31.js");

module.exports = [
  item0,
  item1,
  item2,
  item3,
  item4,
  item5,
  item6,
  item7,
  item8,
  item9,
  item10,
  item12,
  item13,
  item14,
  item15,
  item16,
  item17,
  item18,
  item19,
  item20,
  item21,
  item22,
  item23,
  item24,
  item25,
  item26,
  item27,
  item28,
  item29,
  item30,
  item31,
];
