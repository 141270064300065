import constants from '@/constants';
import { panels } from '@/stateManagement';

import type { EditorAPI } from '@/editorAPI';

const { ROOT_COMPS, BI } = constants;

const isPanelOpen = (editorAPI: EditorAPI, panelName: string): boolean => {
  const openPanels = editorAPI.panelManager.getOpenPanels();
  return openPanels.some((panel) => panel.name === panelName);
};

export const openOrUpdatePanel = (
  editorAPI: EditorAPI,
  panelName: string,
  panelProps?: Record<string, any>,
  shouldLeaveOpen?: boolean,
) => {
  panelProps = {
    ...panelProps,
    origin: BI.HELP.ORIGIN.EDITOR_SEARCH,
  };

  //We cannot use stateManagement.panels.actions.updateOrOpenPanel because it's not updating panel if it not a singleInstance panel
  if (isPanelOpen(editorAPI, panelName)) {
    editorAPI.panelManager.updatePanelProps(panelName, panelProps);
  } else {
    panelProps?.openPanelInteraction?.start();
    editorAPI.panelManager.openPanel(panelName, panelProps, shouldLeaveOpen);
  }
};

export const openAddPanel = (
  editorAPI: EditorAPI,
  category: string,
  sectionId: string,
) => {
  openOrUpdatePanel(
    editorAPI,
    ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
    {
      category,
      sectionId,
    },
    true,
  );
};

export const openAppMarket = (editorAPI: EditorAPI, slug: string) => {
  const appMarketPanelProps = {
    urlParams: {
      openMarketOrigin: 'editorSearch',
      slug,
    },
    origin: BI.HELP.ORIGIN.EDITOR_SEARCH,
  };

  editorAPI.store.dispatch(
    panels.actions.openLeftPanel(
      constants.ROOT_COMPS.LEFTBAR.ADD_APPS_PANEL_NAME,
      appMarketPanelProps,
    ),
  );
};

export const isValidUrl = (fullUrlOrSegment: string) => {
  let isValid = true;

  try {
    // eslint-disable-next-line no-new
    new URL(fullUrlOrSegment);
  } catch {
    isValid = false;
  }

  return isValid;
};
