import keyboardShortcutsActionMap from '@/keyboardShortcuts';
import * as coreBi from '@/coreBi';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import { closePanels } from './baseEditorShortcuts';
import _ from 'lodash';

const MOBILE_EDITOR_SHORTCUTS_CONFIG = {};
const SHORTCUT_ORIGIN = 'keyboard_shortcut';
const keyboardMap = keyboardShortcutsActionMap.editor;

function addMobileEditorShortcuts(editorAPI: EditorAPI) {
  const keysToMerge: Record<string, Function> = {};

  keysToMerge[keyboardMap.CLOSE_PANELS.key] = () => {
    const menuContainerRef = editorAPI.components.get.byId(
      editorAPI.mobile.mobileOnlyComponents.mobileOnlyComps.MENU_AS_CONTAINER,
    );
    const menuIconCompRef = editorAPI.components.get.byId(
      editorAPI.mobile.mobileOnlyComponents.mobileOnlyComps
        .MENU_AS_CONTAINER_TOGGLE,
    );

    const target = util.inlinePopupUtils.getToggleTarget(
      editorAPI,
      menuIconCompRef,
    );

    const isOpen = util.inlinePopupUtils.isOpen(editorAPI, target);
    const selectedComponent = _.head(
      editorAPI.selection.getSelectedComponents(),
    );

    if (isOpen && selectedComponent.id === menuContainerRef.id) {
      editorAPI.store.dispatch(
        stateManagement.mobile.actions.mobileKeyboard.setIsCollapsingMenuFromKeyboard(
          true,
        ),
      );

      editorAPI.bi.event(coreBi.events.panels.PANEL_CLOSED, {
        panel_name: 'mobileMenuCollapse',
        origin: SHORTCUT_ORIGIN,
      });
    }
    closePanels(editorAPI, 'keyboard close');
  };

  Object.assign(MOBILE_EDITOR_SHORTCUTS_CONFIG, keysToMerge);
}

export default {
  init(editorAPI: EditorAPI) {
    addMobileEditorShortcuts(editorAPI);
  },
  shortcuts: MOBILE_EDITOR_SHORTCUTS_CONFIG,
};
