'use strict'
const _ = require('lodash')
const addPanelSectionsEN = require('../../addPanelSectionsEN/addPanelSectionsEN')

function mergeSections(addPanelSectionsEN2, languageSpecificAddPanelSections) {
    if (!languageSpecificAddPanelSections || languageSpecificAddPanelSections === addPanelSectionsEN2) {
        return addPanelSectionsEN2
    }

    return _.transform(languageSpecificAddPanelSections, (addPanelSections, translatedSections, category) => {
        _.assign(addPanelSections[category], translatedSections)
    }, addPanelSectionsEN2)
}

function merge(languageSpecificAddPanelSections) {
    return mergeSections(addPanelSectionsEN, languageSpecificAddPanelSections)
}

module.exports = {
    merge
}
