import _ from 'lodash';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import experiment from 'experiment';
import * as util from '@/util';
import { translate } from '@/i18n';

import { getSettingsMenuAPI } from './settingsMenuAPI';

import type { SendBiFunction } from 'types/bi';
import type { IMenuBarListItem } from '../../types';
import type {
  SettingsMenuAPIOwnProps,
  SettingsMenuAPIStateProps,
  SettingsMenuAPIDispatchProps,
} from './settingsMenuAPI';
import type { MenuBarItemsDispatchProps } from '../menuStructure';

const { SETTINGS_MENU_ITEMS } = constants.ROOT_COMPS.TOPBAR;

export interface SettingsListItemsOwnProps extends SettingsMenuAPIOwnProps {}
export interface SettingsListItemsStateProps extends SettingsMenuAPIStateProps {
  isFirstSave: boolean;
  siteId: string;
  isUserOwner: boolean;
  isMobileEditor: boolean;
  isActivePersonalSaleCampaign: boolean;
  personalSaleCampaignDiscountValue: number;
}
export interface SettingsListItemsDispatchProps
  extends SettingsMenuAPIDispatchProps {
  sendBi: SendBiFunction;
}

export interface SettingsListItemsProps
  extends SettingsListItemsOwnProps,
    SettingsListItemsStateProps,
    SettingsListItemsDispatchProps,
    MenuBarItemsDispatchProps {}

export const getSettingsListItemsByProps = (
  props: SettingsListItemsProps,
): IMenuBarListItem[] => {
  const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();
  const settingsAPI = getSettingsMenuAPI(props);
  const getPersonalSaleCampaignSymbolName = (
    symbolPathPrefix: string,
  ): string => {
    return `${symbolPathPrefix}_${props.personalSaleCampaignDiscountValue}`;
  };
  const getPersonalSaleCampaignImageUrl = (imagePathPrefix: string): string =>
    util.media.getMediaUrl(
      `rEditor/topBar/${getPersonalSaleCampaignSymbolName(
        imagePathPrefix,
      )}.png`,
    );
  const getPersonalSaleTranslatedLabel = (label: string): string =>
    translate(label, {
      discount: props.personalSaleCampaignDiscountValue || 50,
    });
  const getAccessibilitySymbolName = () => {
    if (isNewWorkspace) {
      return 'settingsAccessibility_NewWorkspace';
    }
    return 'settingsAccessibility';
  };

  return [
    {
      key: SETTINGS_MENU_ITEMS.CONNECT_DOMAIN,
      automationId: 'list-item-connect-domain',
      leftTitle: 'TOPBAR_SITE_CONNECT_DOMAIN',
      rightTitle: 'TOPBAR_SITE_CONNECT_PROMOTION_TITLE',
      description: 'TOPBAR_SITE_CONNECT_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'settingsConnectDomain_NewWorkspace'
        : 'menubarConnectDomainPromotion_v2',
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.CONNECT_DOMAIN]();

        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'connect_domain',
        });
      },
      condition() {
        return props.isUserOwner;
      },
    },
    {
      key: SETTINGS_MENU_ITEMS.MAILBOX,
      automationId: 'list-item-mailbox',
      leftTitle: 'TOPBAR_Settings_Mailbox_Label',
      rightTitle: 'TOPBAR_Settings_Mailbox_Promotion_Title',
      description: 'TOPBAR_Settings_Mailbox_Promotion_Text',
      symbolName: isNewWorkspace
        ? 'settingsGetMailbox_NewWorkspace'
        : 'menubarMailboxes',
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.MAILBOX]();

        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'mailboxes',
        });
      },
    },
    // TODO remove all sale related code when wix dealer is integrated in top bar
    _.defaults(
      props.isActivePersonalSaleCampaign
        ? {
            translatedDescription: getPersonalSaleTranslatedLabel(
              'TOPBAR_UPGRADE_POPUP_PERSONAL_SALE_LABEL_DYNAMIC',
            ),
            illustrationClassName:
              'illustration-upgrade personal-sale-campaign',
            image: getPersonalSaleCampaignImageUrl('menubarSitePanel'),
            symbolName: undefined,
          }
        : {
            translatedDescription: null,
            illustrationClassName: 'illustration-upgrade',
            symbolName: isNewWorkspace
              ? 'menubarUpgradePromotion_NewWorkspace'
              : 'menubarUpgradePromotionLight_v2',
            image: null,
          },
      {
        key: SETTINGS_MENU_ITEMS.UPGRADE,
        automationId: 'list-item-upgrade',
        leftTitle: 'TOPBAR_Upgrade_Label',
        rightTitle: 'TOPBAR_SITE_UPGRADE_PROMOTION_TITLE',
        description: 'TOPBAR_SITE_UPGRADE_PROMOTION_TEXT',
        listItemClassName: 'list-item-upgrade',
        onClick() {
          settingsAPI[SETTINGS_MENU_ITEMS.UPGRADE]();

          props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
            category: 'upgrade',
          });
        },
        condition() {
          return props.isUserOwner;
        },
      },
    ),
    {
      isSeparator: true,
    },
    {
      key: SETTINGS_MENU_ITEMS.GO_TO_SEO_WIZ,
      automationId: 'list-item-go-to-seo-wiz',
      leftTitle: 'TOPBAR_GetTraffic_New_Label',
      rightTitle: 'TOPBAR_GetTraffic_New_Promotion_Title',
      description: 'TOPBAR_GetTraffic_New_Promotion_Text',
      symbolName: isNewWorkspace
        ? 'settingsFoundOnGoogle_NewWorkspace'
        : 'menubarGetFoundOnGoogle_v2',
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.GO_TO_SEO_WIZ]();

        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'get_traffic',
        });
      },
    },
    {
      key: SETTINGS_MENU_ITEMS.FAVICON,
      automationId: 'list-item-favicon',
      leftTitle: 'TOPBAR_Settings_Favicon_Label',
      rightTitle: 'TOPBAR_Settings_Favicon_Promotion_Title',
      description: 'TOPBAR_Settings_Favicon_Promotion_Text',
      symbolName: isNewWorkspace
        ? 'settingsAddFavicon_NewWorkspace'
        : 'menubarFavicon',
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.FAVICON]();

        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'favicon',
        });
      },
    },
    {
      key: SETTINGS_MENU_ITEMS.BUSINESS_INFO,
      automationId: 'list-item-business-info',
      leftTitle: 'TOPBAR_Settings_BusinessInfo_Label',
      rightTitle: 'TOPBAR_Settings_BusinessInfo_Promotion_Title',
      description: 'TOPBAR_Settings_BusinessInfo_Promotion_Text',
      symbolName: isNewWorkspace
        ? 'settingsSetInfo_NewWorkspace'
        : 'menubarBusinessInfo',
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.BUSINESS_INFO]();

        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'business',
        });
      },
    },
    {
      key: SETTINGS_MENU_ITEMS.SOCIAL,
      automationId: 'list-item-social',
      leftTitle: 'TOPBAR_Settings_Social_Label',
      rightTitle: 'TOPBAR_Settings_Social_Promotion_Title',
      description: 'TOPBAR_Settings_Social_Promotion_Text',
      symbolName: isNewWorkspace
        ? 'settingsSocialReady_NewWorkspace'
        : 'menubarSocial',
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.SOCIAL]();

        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'social',
        });
      },
    },
    {
      key: SETTINGS_MENU_ITEMS.TRACKING_TOOLS,
      automationId: 'list-item-tracking-tools',
      leftTitle: 'TOPBAR_Settings_TrackingTools_Label',
      rightTitle: 'TOPBAR_Settings_TrackingTools_Promotion_Title',
      description: 'TOPBAR_Settings_TrackingTools_Promotion_Text',
      symbolName: isNewWorkspace
        ? 'settingsMarketing_NewWorkspace'
        : 'menubarTrackingAndAnalytics',
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.TRACKING_TOOLS]();

        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'analytics',
        });
      },
      condition() {
        return !experiment.isOpen('specs.codeEmbed.CustomCodeOnly');
      },
    },
    {
      key: SETTINGS_MENU_ITEMS.MARKETING_INTEGRATIONS,
      automationId: 'list-item-marketing-integrations',
      leftTitle: 'TOPBAR_Settings_MarketingIntegrations_Label',
      rightTitle: 'TOPBAR_Settings_MarketingIntegrations_Title',
      description: 'TOPBAR_Settings_MarketingIntegrations_Text',
      symbolName: isNewWorkspace
        ? 'settingsMarketing_NewWorkspace'
        : 'menubarTrackingAndAnalytics',
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.MARKETING_INTEGRATIONS]();

        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'marketing_integration',
        });
      },
      condition() {
        return experiment.isOpen('specs.codeEmbed.CustomCodeOnly');
      },
    },
    {
      key: SETTINGS_MENU_ITEMS.PERMISSIONS,
      automationId: 'list-item-permissions',
      leftTitle: 'TOPBAR_Settings_Permissions_Label',
      rightTitle: 'TOPBAR_Settings_Permissions_Promotion_Title',
      description: 'TOPBAR_Settings_Permissions_Promotion_Text',
      symbolName: isNewWorkspace
        ? 'settingsRolesPermissions_NewWorkspace'
        : 'menubarRolesAndPermissions',
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.PERMISSIONS]();

        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'permissions',
        });
      },
    },
    {
      key: SETTINGS_MENU_ITEMS.SITE_ACCESS,
      automationId: 'list-item-site-access',
      leftTitle: 'TOPBAR_Settings_Password_Label',
      rightTitle: 'TOPBAR_Settings_Permissions_Password_Promotion_Title',
      description: 'TOPBAR_Settings_Permissions_Password_Promotion_Text',
      symbolName: 'settingsSiteAccess',
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.SITE_ACCESS]();

        util.fedopsLogger.interactionStarted(
          util.fedopsLogger.INTERACTIONS.SITE_ACCESS.OPEN_PANEL,
        );
        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'site_access',
        });
      },
    },
    {
      key: SETTINGS_MENU_ITEMS.ACCESSIBILITY,
      automationId: 'list-item-accessibility',
      leftTitle: 'TOPBAR_AccessibilityWiz_Label',
      rightTitle: 'TOPBAR_AccessibilityWiz_Promotion_Title',
      description: 'TOPBAR_AccessibilityWiz_Promotion_Text',
      symbolName: getAccessibilitySymbolName(),
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.ACCESSIBILITY]();

        props.sendBi(
          coreBi.events.accessibilityPanel.click_accessbility_in_topbar_menu,
          {
            site_id: props.siteId,
          },
        );
        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'accessibility',
        });
      },
      condition() {
        return props.isAllowed('top-bar_menu-settings-accessibility.visible');
      },
    },
    {
      key: SETTINGS_MENU_ITEMS.MOBILE_FRIENDLY,
      automationId: 'list-item-mobile-friendly',
      leftTitle: 'TOPBAR_Settings_Mobile_Friendly_Label',
      rightTitle: 'TOPBAR_Settings_Mobile_Friendly_Promotion_Title',
      description: 'TOPBAR_Settings_Mobile_Friendly_Promotion_Text',
      symbolName: isNewWorkspace
        ? 'settingsMobileFriendly_NewWorkspace'
        : 'mobileFriendlyTopBar',
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.MOBILE_FRIENDLY]();

        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'mobile_friendly',
        });
      },
      condition() {
        if (experiment.isOpen('se_remove_mobile_friendly')) {
          return !props.isMobileOptimizedSite;
        }
        return true;
      },
    },
    {
      key: SETTINGS_MENU_ITEMS.IMAGE_OPTIMIZATION,
      automationId: 'list-item-image-optimization',
      leftTitle: 'TOPBAR_SITE_IMAGE_QUALITY',
      rightTitle: 'TOPBAR_SITE_IMAGE_QUALITY_PROMOTION_TITLE',
      description: 'TOPBAR_SITE_IMAGE_QUALITY_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'settingsImageSharpning_NewWorkspace'
        : 'imageOptimizationPromotion_v2',
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.IMAGE_OPTIMIZATION]();

        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'image_optimization',
        });
      },
    },
    {
      key: SETTINGS_MENU_ITEMS.PRIVACY_GDPR,
      automationId: 'list-item-privacy-gdpr',
      leftTitle: 'TOPBAR_Settings_Privacy_Cookies_Label',
      rightTitle: 'TOPBAR_Settings_Privacy_Cookies_Promotion_Title',
      description: 'TOPBAR_Settings_Privacy_Cookies_Promotion_Text',
      symbolName: isNewWorkspace
        ? 'settingsCookies_NewWorkspace'
        : 'privacyGDPR',
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.PRIVACY_GDPR]();

        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'privacy_gdpr',
        });
      },
      condition() {
        return !props.isFirstSave;
      },
    },
    {
      key: SETTINGS_MENU_ITEMS.MULTILINGUAL,
      automationId: 'list-item-multilingual',
      leftTitle: 'TOPBAR_MULTILINGUAL_LABEL',
      rightTitle: 'TOPBAR_MULTILINGUAL_PROMOTION_TITLE',
      description: 'TOPBAR_MULTILINGUAL_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'settingsMultylingual_NewWorkspace'
        : 'menubarMultilingual',
      onClick() {
        settingsAPI[SETTINGS_MENU_ITEMS.MULTILINGUAL]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'multilingual',
        });
      },
      condition() {
        return !props.isMobileEditor;
      },
    },
    {
      key: SETTINGS_MENU_ITEMS.CUSTOM_ERROR_PAGE,
      automationId: 'list-item-custom-error-page',
      leftTitle: 'TOPBAR_Settings_Custom404_Label',
      rightTitle: 'TOPBAR_Settings_Custom404_Promotion_Title',
      description: 'TOPBAR_Settings_Custom404_Promotion_Text',
      symbolName: isNewWorkspace
        ? 'settings404_NewWorkspace'
        : 'custom404_Top_bar',
      onClick() {
        util.fedopsLogger.interactionStarted(
          util.fedopsLogger.INTERACTIONS.CUSTOM_404_SETTING_MENU_CLICK,
        );
        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.CUSTOM_404_SETTING_MENU_CLICK,
        );
        settingsAPI[SETTINGS_MENU_ITEMS.CUSTOM_ERROR_PAGE]();

        props.sendBi(coreBi.events.topbar.top_bar_SETTINGS_menu_click, {
          category: 'custom_error_page',
        });
      },
    },
  ];
};
