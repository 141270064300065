// @ts-nocheck
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import { recompose } from '@wix/santa-editor-utils';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import react from 'react';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const designPanel = (Component) =>
  // eslint-disable-next-line react/prefer-es6-class
  createReactClass({
    displayName: recompose.wrapDisplayName(Component, 'designPanel'),
    propTypes: {
      selectedComponents: PropTypes.oneOfType([
        PropTypes.object.isRequired,
        PropTypes.array.isRequired,
      ]),
      compType: PropTypes.string.isRequired,
      selectedSkin: PropTypes.string,
      maxStrokeWidth: PropTypes.number,
      styleDataItem: PropTypes.object,
      styleId: PropTypes.string,
      shouldStartClosed: PropTypes.bool,
      createStyleId: PropTypes.func,
      setStyleId: PropTypes.func,
      updateStyle: PropTypes.func,
      waitForChangesApplied: PropTypes.func,
      reportEvent: PropTypes.func,
      onCustomizeDesignCallBack: PropTypes.func,
      onPreviewStateChange: PropTypes.func,
      getDefaultState: PropTypes.func,
      onStyleParamChanged: PropTypes.func,
      multiSelectedComponents: PropTypes.array,
    },
    getInitialState() {
      const isDebug = !!util.url.getParameterByName('debug');
      if (this.props.compType !== 'wixui.RatingsDisplay' && isDebug) {
        //eslint-disable-next-line
        console.error(
          'DEAR WIX DEVELOPER- This HOC implementation is partial, please validate that all the mixin functionality of design panel exists',
        );
      }
      return this.getResetState(this.props);
    },
    UNSAFE_componentWillReceiveProps(nextProps) {
      const selectedComponentHasChanged = !_.isEqual(
        nextProps.selectedComponents,
        this.props.selectedComponents,
      );
      if (selectedComponentHasChanged) {
        this.setState(this.getResetState(nextProps));
      }
    },
    getResetState(newProps) {
      let customizeDesignOnly = !!newProps.advancedStyling;
      if (_.isFunction(this.getEditorAPI)) {
        const editorAPI = this.getEditorAPI();
        customizeDesignOnly =
          stateManagement.designPanel.selectors.getShowCustomizeDesignOnly(
            editorAPI.store.getState(),
          );
      }
      return {
        advancedStyling: customizeDesignOnly,
        currentStyleId: newProps.styleId,
        isStyleChanged: false,
      };
    },

    getComponentStyleId() {
      return this.state.currentStyleId || this.props.styleId;
    },

    getPropsForDesignPanel() {
      const props = _.defaults(
        {
          backButtonLabel: 'design_panel_back_button_label',
          hideComponentSections: this.state.advancedStyling,
          shouldHideContentOverflow: this.state.advancedStyling,
          isCustomizeDesign: this.state.advancedStyling,
        },
        this.props,
      );

      if (this.state.advancedStyling) {
        props.onBackClick = this.customizeDesignCallBack;
      }

      return props;
    },

    openCustomizeDesign() {
      const newState = {};

      newState.currentStyleId = this.props.styleId;

      this.props.waitForChangesApplied(() => {
        newState.advancedStyling = true;

        this.setState(newState, function () {
          this.reportCustomizeDesignClickedToBI();
          if (_.isFunction(this.props.onCustomizeDesignCallBack)) {
            this.props.onCustomizeDesignCallBack();
          }
        });
      });
    },

    reportCustomizeDesignClickedToBI() {
      this.props.reportEvent(
        coreBi.events.designPanel.DESIGN_PANEL_CUSTOMIZE_BUTTON_CLICK,
        {
          was_preset_clicked_current_session: this.state.isStyleChanged,
          component_type: this.props.compType,
          component_id: _.head(this.props.selectedComponents).id,
          chosen_style_id: this.state.currentStyleId,
        },
      );
    },
    customizeDesignCallBack() {
      this.setState({ advancedStyling: false });

      this.props.reportEvent(
        coreBi.events.ADVANCED_STYLE_PANEL.BACK_BUTTON_CLICK,
        {
          component_type: this.props.compType,
          component_id: _.head(this.props.selectedComponents).id,
        },
      );
    },
    getAdvancedStylingProps() {
      const selectedComponents = util.array.asArray(
        this.props.selectedComponents,
      );
      const props = {
        selectedSkin: this.props.selectedSkin,
        selectedComponent: _.head(selectedComponents),
        styleId: this.state.currentStyleId,
        compType: this.props.compType,
        maxStrokeWidth: this.props.maxStrokeWidth,
        styleDataItem: this.props.styleDataItem,
      };

      if (this.props.multiSelectedComponents) {
        props.multiSelectedComponents = this.props.multiSelectedComponents;
        props.onStyleParamChanged = this.props.onStyleParamChanged;
      }

      if (_.has(this.props, 'shouldStartClosed')) {
        props.shouldStartClosed = this.props.shouldStartClosed;
      }

      return props;
    },

    render() {
      const props = _.defaults(
        {
          advancedStylingProps: this.getAdvancedStylingProps(),
          propsForDesignPanel: this.getPropsForDesignPanel(),
          openCustomizeDesign: this.openCustomizeDesign,
          advancedStyling: this.state.advancedStyling,
        },
        this.props,
      );
      return react.createElement(Component, props);
    },
  });

const mapStateToProps = ({ editorAPI, dsRead }) => {
  const { viewMode } = dsRead;
  const selectedComponents = editorAPI.selection.getSelectedComponents();
  const selectedComponent = _.head(selectedComponents);
  const { getStyleId } = stateManagement.components.selectors;
  const styleId = getStyleId(selectedComponent, dsRead);
  const styleDataItem = editorAPI.components.style.get(selectedComponent);

  return {
    styleId,
    styleDataItem,
    selectedComponents,
    compType: editorAPI.components.getType(selectedComponent),
    waitForChangesApplied: editorAPI.dsActions.waitForChangesApplied, // TODO: should not be here
    isMobile: viewMode.get() === viewMode.VIEW_MODES.MOBILE,
    reportEvent: editorAPI.bi.event,
  };
};

const updateMultiComponentStyle =
  (multiSelectedComponents, styleParam, value, source) =>
  (dispatch, getState, { editorAPI }) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    return _.forEach(multiSelectedComponents, (compRef) => {
      const styleDef = _.cloneDeep(editorAPI.components.style.get(compRef));
      styleDef.style.properties[styleParam] = value;
      if (styleDef.style.propertiesSource) {
        styleDef.style.propertiesSource[styleParam] = source;
      }

      editorAPI.components.style.update(compRef, styleDef);
    });
  };

const replaceComponentPanel =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.replaceComponentPanel(...args);

const mapDispatchToProps = (dispatch, { multiSelectedComponents }) => ({
  replaceComponentPanel: (...args) => dispatch(replaceComponentPanel(...args)),
  onStyleParamChanged: (styleParam, value, source) =>
    dispatch(
      updateMultiComponentStyle(
        multiSelectedComponents,
        styleParam,
        value,
        source,
      ),
    ),
});

export default _.flow(
  designPanel,
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
);
