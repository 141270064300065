import { mixins } from '@/core';
import createReactClass from 'create-react-class';
import { getStylesForThemedSection } from './liveComponentSectionUtils';
import liveSectionLoaderMixin from './liveSectionLoaderMixin';

// @ts-expect-error
// eslint-disable-next-line react/prefer-es6-class
export const ThemeSection = createReactClass({
  displayName: 'themeSection',
  mixins: [liveSectionLoaderMixin, mixins.editorAPIMixin],
  getStylesForSection() {
    const panelLayoutType = this.props.parentType;
    const { compTypes } = this.props.props;
    const editorAPI = this.getEditorAPI();

    return getStylesForThemedSection({
      compTypes,
      panelLayoutType,
      editorAPITheme: editorAPI.theme,
      editorAPIComponents: editorAPI.components,
    });
  },
});
