import React from 'react';
import styles from './ScreenRecordingIndicator.scss';

export const ScreenRecordingIndicator: React.FC = () => {
  return (
    <>
      <div className={styles.left} />
      <div className={styles.right} />
      <div className={styles.top} />
      <div className={styles.bottom} />
    </>
  );
};
