// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import React from 'react';
import PopupTemplate from './popupTemplate';
import constants from '@/constants';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'mobileEditorFirstTimePanel',
  propTypes: {
    panelName: PropTypes.string.isRequired,
  },
  mixins: [core.mixins.editorAPIMixin],
  render() {
    return (
      <PopupTemplate
        panelName={this.props.panelName}
        h1text="Notification_Box_Mobile_FirstTime_Title"
        imageClassName="check-your-mobile-site-image"
        bodyText="Notification_Box_Mobile_FirstTime_Text"
        dontShowAgainKey={
          constants.USER_PREFS.MOBILE_EDITOR.FIRST_TIME_PANEL.DONT_SHOW_AGAIN
        }
        shouldNotCloseOnBlur={true}
        userPrefType="site"
        className="mobile-editor-first-time"
      />
    );
  },
});
