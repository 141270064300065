import { isAdvancedMenuOpen, symbols } from '@/util';

import type { IPageMenuItem } from '@/menu';

export const getPagesMenuItemSymbol = (
  pageMenuItem: IPageMenuItem & { symbol?: string },
) => {
  if (pageMenuItem.symbol) {
    return pageMenuItem.symbol;
  }
  if (pageMenuItem.type.isPopupLink) {
    return 'popupPageType';
  }
  if (pageMenuItem.type.isLink) {
    return symbols.getLinkSymbolName(pageMenuItem?.link);
  }
  if (pageMenuItem.type.isDropdown) {
    return isAdvancedMenuOpen() ? 'menuFolder' : 'folderPageType';
  }
  if (pageMenuItem.isCustomErrorPage) {
    return 'pageItemCustom404';
  }
  if (pageMenuItem.isHomePage) {
    return 'homePageType';
  }
  if (pageMenuItem.isOldBlog) {
    return 'oldBlogPageType';
  }
  if (pageMenuItem?.pageData?.tpaPageId === 'members') {
    return 'membersAction';
  }
  if (pageMenuItem?.pageData?.tpaApplicationId > 0) {
    return symbols.getTpaSymbolName(pageMenuItem.appDefinitionId);
  }
  return 'regularPageType';
};
