export enum Integrations {
  ADD_PANEL = 'add-panel',
  MOBILE_ADD_PANEL = 'mobile-add-panel',
  LEFT_PANELS = 'left-panels',
  PAGES_PANEL = 'pages-panel',
  TOP_BAR_MENU = 'top-bar-menu',
  QUICK_ACTIONS = 'top-bar-quick-actions',
  APP_MARKET = 'app-market',
  HELP_CENTER = 'help-center',
  MOBILE_TOOLS = 'mobile-tools',
  BUSINESS_MANAGER = 'business-manager',
}

export enum IconTypes {
  URL = 'URL',
  NAME = 'NAME',
}

export enum SearchCategories {
  TOOLS_AND_SETTINGS = 'TOOLS_AND_SETTINGS',
  ADD_TO_YOUR_SITE = 'ADD_TO_SITE',
  QUICK_ACTIONS = 'QUICK_ACTIONS',
  APP_MARKET = 'APPS_FOR_SITE',
  HELP_CENTER = 'HELP_ARTICLES',
  BUSINESS_MANAGER = 'BUSINESS_MANAGER',
}

export const SEARCH_CATEGORIES_LIST = [
  SearchCategories.ADD_TO_YOUR_SITE,
  SearchCategories.APP_MARKET,
  SearchCategories.QUICK_ACTIONS,
  SearchCategories.TOOLS_AND_SETTINGS,
  SearchCategories.BUSINESS_MANAGER,
  SearchCategories.HELP_CENTER,
];

export const enum ErrorType {
  ABORT = 'abort',
  UNKNOWN = 'unknown',
  NETWORK = 'network',
  REQUEST = 'request',
}
