// @ts-nocheck
import wixFormsConstants from '../constants';
import * as wixFormsUtils from '../utils';
import addPanelDataConsts from '@/addPanelDataConsts';

const title = 'add_section_heading_waiverforms';
const presetTitle = 'add_section_heading_waiverforms_preset';
const tooltipTitle = 'add_section_label_waiverforms_tooltip_title';
const subNavigationTitle = 'add_section_label_waiverforms_sidebar';
const helpText = 'add_section_label_waiverForms_tooltip_description';

const getWaiverSectionSection = (editorAPI) => ({
  waiverSection: {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title,
    topTitle: title,
    tooltipTitle,
    automationId: 'add_section_heading_waiverforms',
    sectionName: 'waiverSection',
    appDefinitionId: wixFormsConstants.FORM_BUILDER_APP_ID,
    showSectionHeader: true,
    subNavigationTitle,
    subNavigationHide: false,
    presetTitle,
    additionalBehaviours: {
      labelMode: 'none',
      infoIcon: 'none',
      hoverImageAction: 'scale',
      iconEnabledForComps: {},
    },
    props: {
      image:
        'addPanelData/sections/formsWaiverSection_en/formsWaiverSection_en.png',
      imageStyle: wixFormsConstants.imageStyle,
      imageHover: null,
      items: [
        {
          id: 'Health_Declaration_Forms',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.HEALTH_DECLARATION02,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.HEALTH_DECLARATION02,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 480,
              height: 947,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: { rect: { width: 162, height: 290, x: 0, y: 0 }, tags: null },
        },
        {
          id: 'Photo_Consent',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.PHOTO_CONSENT,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.PHOTO_CONSENT,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 400,
              height: 749,
              x: 20,
              y: 412,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 290, x: 162, y: 0 },
            tags: null,
          },
        },
        {
          id: 'GYM_Health_Waiver',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.GYM_HEALTH_WAIVER02,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.GYM_HEALTH_WAIVER02,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 538,
              height: 1001,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 316, x: 0, y: 290 },
            tags: null,
          },
        },
        {
          id: 'Emergency_Contact',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.EMERGENCY_CONTACT,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.EMERGENCY_CONTACT,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 510,
              height: 1025,
              x: 20,
              y: 412,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 316, x: 162, y: 290 },
            tags: null,
          },
        },
      ],
      compTypes: [wixFormsConstants.componentType],
    },
    help: {
      hide: false,
      text: helpText,
    },
  },
});

export default (editorAPI) => getWaiverSectionSection(editorAPI);
