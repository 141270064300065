import {
  AssistantAction,
  type IAssistantAction,
  type IAssistantTask,
} from '@wix/tour-maker';

import { EditorAPIKey } from '@/apis';

import * as stateManagement from '@/stateManagement';

import { getStepName } from '../utils/getStepName';
import {
  getTooltipOffsetYForStageAction,
  deleteTextComponentIfNeed,
  removeBackdropClipPath,
  getFirstTextComponent,
  setBackdropClipPath,
  getBackdropClipPath,
} from '../utils/stageUtils';
import { createActions } from './util';
import { HOOKS, MEDIA } from './constants';
import type { CompRef } from 'types/documentServices';
import type { Shell } from '@/apilib';

export const TOUR_NAME = 'newWorkspaceFirstTimeTour';

export const createNewWorkspaceFirstTimeTour = (
  shell: Shell,
): IAssistantTask => {
  const editorAPI = shell.getAPI(EditorAPIKey);

  const leftBarAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction =>
    new AssistantAction('leftBar')
      .thatAttachesTo()
      .hook(HOOKS.leftBar)
      .and.withBackdrop()
      .thatIsOn()
      .and.withTooltip()
      .withTitle('Existing-Tour-LeftBar-Title')
      .withContent('Existing-Tour-LeftBar-Text')
      .withCustomProp('totalSteps', lastStepIndex + 1)
      .withCustomProp('stepName', getStepName(TOUR_NAME, 'leftBar', index))
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.leftBar)
      .withCustomProp('className', 'tour-tooltip-opens-right')
      .thatOpensUp({ offsetY: 464, offsetX: 217 }) // Up but not Right because this fix positioning on small screen
      .withButton('skipButton')
      .completeOnClick()
      .withButton('forwardButton')
      .label('First-Time-Next-Link')
      .goToNextStep()
      .build();

  const pagesPanelAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction =>
    new AssistantAction('pagesPanel')
      .thatAttachesTo()
      .hook(HOOKS.pagesPanel)
      .and.withBackdrop()
      .thatIsOn()
      .and.withTooltip()
      .withTitle('First-Time-Tour-Pages-Title')
      .withContent('First-Time-Tour-Pages-Text')
      .withCustomProp('totalSteps', lastStepIndex + 1)
      .withCustomProp('stepName', getStepName(TOUR_NAME, 'pagesPanel', index))
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.pagesPanel)
      .withCustomProp('className', 'tour-tooltip-opens-right')
      .thatOpensRight({ offsetY: 173 })
      .withButton('skipButton')
      .completeOnClick()
      .withButton('forwardButton')
      .label('First-Time-Next-Link')
      .goToNextStep()
      .withButton('backButton')
      .label('First-Time-Back-Link')
      .onClickGoTo(index - 1)
      .build();

  const mobileAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction =>
    new AssistantAction('mobile')
      .thatAttachesTo()
      .hook(HOOKS.deviceSwitch)
      .and.withBackdrop()
      .thatIsOn()
      .and.withTooltip()
      .withTitle('First-Time-Tour-Mobile-Title')
      .withContent('First-Time-Tour-Mobile-Text')
      .withCustomProp('totalSteps', lastStepIndex + 1)
      .withCustomProp('stepName', getStepName(TOUR_NAME, 'mobile', index))
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.deviceSwitch)
      .withCustomProp('className', 'tour-tooltip-opens-down')
      .thatOpensDown()
      .withButton('skipButton')
      .completeOnClick()
      .withButton('forwardButton')
      .label('First-Time-Next-Link')
      .goToNextStep()
      .withButton('backButton')
      .label('First-Time-Back-Link')
      .onClickGoTo(index - 1)
      .build();

  const smartWriterAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction =>
    new AssistantAction('smartWriter')
      .thatAttachesTo()
      .hook(HOOKS.smartWriter)
      .and.withBehaviors()
      .thatDoesntScrollToElement()
      .and.withCallbacks()
      .onEnter(async () => {
        const textRef: CompRef = await getFirstTextComponent(editorAPI);

        editorAPI.selection.selectComponentByCompRef(textRef);

        setBackdropClipPath(
          HOOKS.tourBackdrop,
          getBackdropClipPath(editorAPI, textRef),
        );
      })
      .onExit(() => {
        deleteTextComponentIfNeed(editorAPI);
        removeBackdropClipPath(HOOKS.tourBackdrop);
        editorAPI.selection.deselectComponents();
      })
      .and.withBackdrop()
      .thatIsOn()
      .and.withTooltip()
      .withTitle('Existing-Tour-TextIdeas-Title')
      .withContent('Existing-Tour-TextIdeas-Text')
      .withCustomProp('totalSteps', lastStepIndex + 1)
      .withCustomProp('stepName', getStepName(TOUR_NAME, 'smartWriter', index))
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.smartWriter)
      .withCustomProp('className', 'tour-tooltip-opens-right')
      .thatOpensRight(getTooltipOffsetYForStageAction(editorAPI))
      .withButton('skipButton')
      .completeOnClick()
      .withButton('forwardButton')
      .label('First-Time-Next-Link')
      .goToNextStep()
      .withButton('backButton')
      .label('First-Time-Back-Link')
      .onClickGoTo(index - 1)
      .build();

  editorAPI.store.dispatch(stateManagement.panels.actions.closeOpenedPanels());

  const actionsList = [
    leftBarAction,
    pagesPanelAction,
    smartWriterAction,
    mobileAction,
  ];

  const actions = createActions(actionsList, actionsList.length - 1);

  return {
    id: TOUR_NAME,
    onEnter: {
      callback: () => {
        editorAPI.autosaveManager.init({ enabled: false });
        editorAPI.panelManager.closeAllPanels();
      },
    },
    onExit: {
      callback: () => {
        editorAPI.autosaveManager.init({ enabled: true }, true);
        deleteTextComponentIfNeed(editorAPI);
      },
    },
    actions,
  };
};
