import React from 'react';
import { isPageUrlWithSessionRecording } from '../sessionRecordingUtils';
import { JiraIssueCollectorButton } from './JiraIssueCollectorButton';
import { ScreenRecordingIndicator } from './ScreenRecordingIndicator';

import type { JiraIssueCollectorParameters } from '../jiraIssueCollector';

interface JiraIssueCollectorProps {
  collectorParameters: JiraIssueCollectorParameters;
}

export const JiraIssueCollector: React.FC<JiraIssueCollectorProps> = ({
  collectorParameters,
}) => {
  return (
    <>
      {isPageUrlWithSessionRecording() ? (
        <>
          <JiraIssueCollectorButton collectorParameters={collectorParameters} />
          <ScreenRecordingIndicator />
        </>
      ) : (
        <JiraIssueCollectorButton collectorParameters={collectorParameters} />
      )}
    </>
  );
};
