import constants from '@/constants';
import { components, domMeasurements, selection } from '@/stateManagement';
import { getBoundingBoxStyles } from './utils';

import type { CSSProperties } from 'react';
import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';
import type { CompRef } from 'types/documentServices';
import type {
  MapStateToProps,
  MapDispatchToProps,
  Dispatch,
  StateMapperArgs,
} from 'types/redux';

interface ChildBoxesDispatchProps {
  getChildBoxes: (sectionLikeId: string) => Promise<ChildBox[]>;
}

interface ChildBoxesStateProps {
  previewWidth: number;
  isSiteSegment: boolean;
  sectionLikeId: string;
  undoLastSnapshotId: string;
  shouldHideChildBoxes: boolean;
  isZoomModeTransitionActive: boolean;
}

export interface ChildBox {
  id: string;
  style: CSSProperties;
}

export interface ChildBoxesProps
  extends ChildBoxesStateProps,
    ChildBoxesDispatchProps {}

const COMPONENTS_WITHOUT_MAP_BOX: string[] = [constants.COMP_TYPES.ANCHOR];

const isComponentWithoutMapBox = (compType: string) =>
  COMPONENTS_WITHOUT_MAP_BOX.includes(compType);

export const mapStateToProps: MapStateToProps<ChildBoxesStateProps> = ({
  state,
  editorAPI,
}) => {
  const selectedComp =
    editorAPI.sections.getSelectedSection() ||
    editorAPI.sections.getSelectedHeaderFooter();

  const isZoomModeTransitionActive =
    editorAPI.zoomMode.isZoomModeTransitionActive();

  return {
    isSiteSegment: editorAPI.sections.isHeaderOrFooter(selectedComp),
    sectionLikeId: selectedComp.id,
    undoLastSnapshotId:
      editorAPI.documentServices.history.getUndoLastSnapshotId(),
    previewWidth: domMeasurements.selectors.getPreviewPosition(state).width,
    shouldHideChildBoxes:
      selection.selectors.shouldHideSelectionWhenStageActionsHovered(editorAPI),
    isZoomModeTransitionActive,
  };
};

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: StateMapperArgs,
): EditorAPI => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  ChildBoxesDispatchProps,
  {}
> = (dispatch: Dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    getChildBoxes: async (sectionLikeId: string) => {
      await editorAPI.waitForChangesAppliedAsync();
      const selectedComp = editorAPI.components.get.byId(sectionLikeId);
      const sectionChildren = editorAPI.components
        .getChildren(selectedComp)
        .map((topLevelChild: CompRef) => {
          const isAppWidget =
            editorAPI.components.getType(topLevelChild) ===
            constants.COMP_TYPES.APP_WIDGET;

          if (isAppWidget) return topLevelChild;

          return editorAPI.components.getChildrenRecursivelyWithResolvers(
            topLevelChild,
            (compRef: CompRef): boolean =>
              constants.COMP_TYPES.REF_COMPONENT !==
              editorAPI.components.getType(compRef),
          );
        })
        .flat(1)
        .filter(
          (child: CompRef) =>
            components.selectors.getIsComponentVisibleInCurrentMode(
              editorAPI.dsRead,
              child,
              editorAPI.store.getState(),
            ) &&
            editorAPI.components.isRenderedOnSite(child) &&
            !isComponentWithoutMapBox(editorAPI.components.getType(child)),
        )
        .filter(editorAPI.components.is.selectable)
        .filter((child: CompRef) => {
          const isContainer = editorAPI.components.is.container(child);
          const parentContainer =
            editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(child);
          const isSectionLike =
            editorAPI.sections.isSectionLike(parentContainer);

          const isNestedContainer =
            isContainer && Boolean(parentContainer) && !isSectionLike;

          return !isNestedContainer;
        });
      return sectionChildren.map((compRef: CompRef) => {
        const { bounding } =
          editorAPI.components.layout.getRelativeToScreen(compRef);
        return {
          id: compRef.id,
          style: getBoundingBoxStyles(bounding),
        };
      });
    },
  };
};
