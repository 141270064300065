import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { DesignItem } from './types';

const REF_COMPONENT_CHANGE_TYPE = {
  PROPS: 'props',
  DATA: 'data',
  STYLE: 'style',
  DESIGN: 'design',
  BEHAVIORS: 'behaviors',
};
const debouncedSendBi = _.debounce((editorAPI, compRef, changeType) => {
  editorAPI.store.dispatch(
    stateManagement.bi.actions.event(coreBi.events.refComponent.changes, {
      changeType,
      component_id: compRef.id,
      component_type: editorAPI.components.getType(compRef),
    }),
  );
}, 1000);

function sendBiIfRefComponent(
  editorAPI: EditorAPI,
  compRef: CompRef,
  changeType: string,
) {
  if (stateManagement.components.selectors.isReferredComponent(compRef)) {
    debouncedSendBi(editorAPI, compRef, changeType);
  }
}

function isContainedObject<TSource extends Record<string, any>>(
  sourceObj: TSource,
  partialObj: Partial<TSource>,
) {
  if (!partialObj) {
    return true;
  }
  return Object.keys(partialObj).every((key) =>
    _.isEqual(sourceObj[key], partialObj[key]),
  );
}

const getHistoryLabelForDesignChange = (
  oldDesign: DesignItem,
  newDesign: DesignItem,
  isBackgroundPanelOpened: boolean,
) => {
  const backgroundAdded = !oldDesign?.background && newDesign.background;

  if (
    isBackgroundPanelOpened &&
    (backgroundAdded ||
      !isContainedObject(oldDesign.background, newDesign.background))
  ) {
    return 'comp background changed';
  }
  return 'comp design changed';
};

function getDiffObject<T>(oldProperties: T, newProperties: T): Partial<T> {
  const uniqKeys = _.uniq([
    ...Object.keys(oldProperties),
    ...Object.keys(newProperties),
  ]) as (keyof T)[];

  const diff = uniqKeys.reduce((diff, key) => {
    const oldProperty = oldProperties[key];
    const newProperty = newProperties[key];

    const shouldGoRecursively =
      _.isObjectLike(oldProperty) && _.isObjectLike(newProperty);

    const newValue = shouldGoRecursively
      ? getDiffObject(oldProperty, newProperty)
      : _.clone(newProperty);

    if (!_.isEqual(oldProperty, newValue)) {
      return { ...diff, [key]: newValue };
    }
    return diff;
  }, {} as T);

  return Object.entries(diff).length ? diff : null;
}

export {
  REF_COMPONENT_CHANGE_TYPE,
  sendBiIfRefComponent,
  isContainedObject,
  getDiffObject,
  getHistoryLabelForDesignChange,
};
