module.exports = {
  id: "Stylable_StylableButton_18",
  preset: { rect: { width: 64, height: 71, x: 0, y: 529 }, tags: null },
  structure: {
    type: "Component",
    metaData: { sig: "inq-323", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
    layout: {
      width: 39,
      height: 39,
      x: 11,
      y: 546,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: "wixui.StylableButton",
    parent: "comp-knzvqsjj",
    data: {
      type: "StylableButton",
      metaData: {
        isPreset: false,
        schemaVersion: "1.0",
        isHidden: false,
        sig: "q4x-290",
        pageId: "c1dmp",
      },
      label: "Next",
      svgId: "b861b040274141de9c08999ec3b76edf.svg",
    },
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "b80-816", pageId: "c1dmp" },
    },
    style: {
      type: "ComponentStyle",
      metaData: {
        isPreset: false,
        schemaVersion: "1.0",
        isHidden: false,
        sig: "4ie-155",
        pageId: "c1dmp",
      },
      style: {
        properties: {
          "$st-css":
            ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #FFFCCC;border-radius: 7px;border: 0px solid rgb(255, 252, 204)}.root:hover{border-radius: 7px;border: 0px solid rgb(255, 252, 204)}.root:disabled {\n    background: #DBDBDB;\n}.root:disabled::icon {\n    fill: #8D8D8D;\n}.root:hover::icon {\n    width: 18px;\n    height: 18px;\n}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #000000;display: none}.root::icon{transition: inherit;fill: #9D6200;width: 14px;height: 14px}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      componentClassName: "wixui.StylableButton",
      pageId: "",
      compId: "",
      styleType: "custom",
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    id: "comp-km95aqax",
    components: [],
  },
};
