import coreUtilsLib from 'coreUtilsLib';

function moveToFooter(
  editorAPI: AnyFixMe,
  appData: AnyFixMe,
  token: AnyFixMe,
  { componentRef, shouldScrollToComponent = true }: AnyFixMe,
) {
  editorAPI.components.moveToFooter(componentRef);
  if (!shouldScrollToComponent) {
    return;
  }
  const footerLayout = editorAPI.components.layout.get_position({
    id: 'SITE_FOOTER',
    type: 'DESKTOP',
  });
  const scrollTop = footerLayout.y - 50;
  const scrollDuration = coreUtilsLib.scrollUtils.calcScrollDuration(
    editorAPI.site.getScroll().y,
    scrollTop,
    editorAPI.isMobileEditor(),
  );
  editorAPI.scroll.animateScrollTo({ y: scrollTop }, scrollDuration);
}

export { moveToFooter };
