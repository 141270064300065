:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._content_1bpb0_7 {
  display: flex;
  flex-direction: column;
  width: 100%; }

._loaderWrapper_1bpb0_12 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }
