import experiment from 'experiment';
import {
  type PanelProps,
  STAGE_BEHAVIOUR_PRESET,
  StageBehaviour,
} from '@/stateManagement';
import { useEffect } from 'react';

interface UseStageShrinkParams {
  stageBehaviour: PanelProps['stageBehaviour'] | null;
  onShrink: (isStageBehaviourClose?: boolean) => void;
  onStretch: () => void;
  isZoomedByUser: boolean;
}

export const useStageShrink = ({
  stageBehaviour = STAGE_BEHAVIOUR_PRESET.DEFAULT,
  onShrink,
  onStretch,
  isZoomedByUser,
}: UseStageShrinkParams) => {
  const { open, close } = stageBehaviour;
  const stageSizeExperimentIsOpen = experiment.isOpen('se_stageSize');

  const needToCloseShrink =
    stageSizeExperimentIsOpen &&
    isZoomedByUser &&
    stageBehaviour === STAGE_BEHAVIOUR_PRESET.TOGGLE;

  useEffect(() => {
    if (open === StageBehaviour.Shrink) {
      onShrink();
    } else if (open === StageBehaviour.Stretch) {
      onStretch();
    }

    return () => {
      if (close === StageBehaviour.Shrink) {
        onShrink();
      } else if (close === StageBehaviour.Stretch && needToCloseShrink) {
        onShrink(true);
      } else if (close === StageBehaviour.Stretch && !needToCloseShrink) {
        onStretch();
      }
    };
  }, [close, onShrink, onStretch, open, needToCloseShrink]);
};
