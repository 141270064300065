import React, { type FC } from 'react';
import { Text } from '@wix/wix-base-ui';
import { cx } from '@/util';
import styles from './addSectionCategoryAdditionalInfo.scss';

export interface AddSectionCategoryAdditionalInfoProps {
  appIcon: string;
  appName: string;
  description: string;
}

export const AddSectionCategoryAdditionalInfo: FC<
  AddSectionCategoryAdditionalInfoProps
> = ({ appIcon, appName, description }) => {
  return (
    <div
      className={cx(
        styles.addSectionPanelCategoryAdditionalInfo,
        styles.addSectionPanelCategoryAdditionalInfo_noBottomIndent,
      )}
    >
      <div className={styles.addSectionPanelCategoryInfoIcon}>
        <img src={appIcon} alt={appName} />
      </div>
      <div>
        <div className={styles.addSectionPanelCategoryInfoText}>
          <Text size="medium" weight="bold" shouldTranslate={false}>
            {appName}
          </Text>
          <Text size="small" weight="thin" enableEllipsis={false}>
            {description}
          </Text>
        </div>
      </div>
    </div>
  );
};
