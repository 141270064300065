:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._root_ecmjm_7 {
  width: 240px; }
  ._root_ecmjm_7 .radio-button-wrapper .radio-button-symbol {
    align-self: flex-start;
    margin-top: 1px; }

._content_ecmjm_13 {
  padding: 24px 24px 6px 24px;
  position: relative; }

._radio_ecmjm_9 {
  margin-bottom: 18px;
  width: 162px; }

._radio__label_ecmjm_21 {
  color: #3b4057;
  margin-bottom: 6px; }

._radio__description_ecmjm_25 {
  color: #868aa5; }
