import _ from 'lodash';

import { getSendBi } from '../../../topBarComponent/topBarMapper';
import {
  mapStateToProps as mapStateToMenuAPIProps,
  mapDispatchToProps as mapDispatchToMenuAPIProps,
} from './settingsMenuAPIMapper';

import type { MapDispatchToPropsFunction, MapStateToProps } from 'types/redux';
import type {
  SettingsListItemsOwnProps,
  SettingsListItemsStateProps,
  SettingsListItemsDispatchProps,
} from './settingsMenuStructure';

const mapStateToProps: MapStateToProps<
  SettingsListItemsStateProps,
  SettingsListItemsOwnProps
> = (...args) => {
  const [{ editorAPI }] = args;

  return {
    ...mapStateToMenuAPIProps(...args),

    isFirstSave: _.invoke(editorAPI, 'dsRead.generalInfo.isFirstSave') || false,
    siteId: _.invoke(editorAPI, 'dsRead.generalInfo.getSiteId'),
    isUserOwner: editorAPI.account.isUserOwner(),
    isMobileEditor: editorAPI.isMobileEditor(),
    isActivePersonalSaleCampaign:
      editorAPI.campaigns.personalSale.isActiveCampaign(),
    personalSaleCampaignDiscountValue:
      editorAPI.campaigns.personalSale.isActiveCampaign()
        ? editorAPI.campaigns.personalSale.getDiscountValue()
        : null,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<
  SettingsListItemsDispatchProps,
  SettingsListItemsOwnProps
> = (dispatch, ownProps) => ({
  ...mapDispatchToMenuAPIProps(dispatch, ownProps),

  sendBi: getSendBi(dispatch),
});

export { mapStateToProps, mapDispatchToProps };
