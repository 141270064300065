import _ from 'lodash';
import CONSTANTS from './constants';

function isEmptyHtmlText(html: string) {
  // \s finds all html whitespace occurrences, e.g, &nbsp; &emsp; &ensp; &thinsp; etc...
  return getTextContentFromHtml(html).replace(/[\u200b\s]/g, '').length < 1;
}

function getTextContentFromHtml(html: string) {
  const tempDiv = window.document.createElement('div');
  tempDiv.innerHTML = html;
  return tempDiv.textContent;
}

function isBlockElement(element: ChildNode) {
  return (
    element.nodeName &&
    CONSTANTS.TEXT_BLOCKS.includes(element.nodeName.toLowerCase())
  );
}

function parseHTML(htmlText: string) {
  const div = window.document.createElement('div');
  div.innerHTML = htmlText;
  return _.compact(div.childNodes);
}

function wrapOrphans(htmlText: string) {
  if (!_.isString(htmlText)) {
    return htmlText;
  }
  const parsedHTML = parseHTML(htmlText.trim());
  const result = document.createElement('div');

  let wrapperBlock: HTMLParagraphElement;

  parsedHTML.forEach((node) => {
    if (!isBlockElement(node)) {
      if (node.textContent.trim().length > 0) {
        //do not add empty orphan text nodes
        if (!wrapperBlock) {
          const p = document.createElement('p');
          p.classList.add(CONSTANTS.DEFAULT_STYLES_MAP.div.cssClass);
          wrapperBlock = p;
        }

        wrapperBlock.appendChild(node);
      }
      return;
    }

    if (wrapperBlock) {
      result.appendChild(wrapperBlock);
      wrapperBlock = undefined;
    }

    result.appendChild(node);
  });

  if (wrapperBlock) {
    result.appendChild(wrapperBlock);
  }

  return result.innerHTML;
}

export { getTextContentFromHtml, isEmptyHtmlText, wrapOrphans };
