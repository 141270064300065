// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as core from '@/core';
import addPanelDataConsts from '@/addPanelDataConsts';
import preventDoubleClickMixin from '../mixins/preventDoubleClickMixin';
import * as imageFallbackMixin from '../mixins/imageFallbackMixin';
import React from 'react';

const { mediaManagerPresetUtil } = core.utils;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'presetAreaItem',
  mixins: [
    core.mixins.editorAPIMixin,
    preventDoubleClickMixin,
    imageFallbackMixin,
  ],
  propTypes: {
    preset: PropTypes.shape({
      rect: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }).isRequired,
      shape: PropTypes.shape({
        name: PropTypes.string.isRequired,
        coords: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    image: PropTypes.string.isRequired,
    imageHover: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func,
    id: PropTypes.string.isRequired,
    structure: PropTypes.object.isRequired,
    sectionTitle: PropTypes.string.isRequired,
    overrideClass: PropTypes.string,
    setDragVectorShapeItem: PropTypes.func,
    getImagePosition: PropTypes.func.isRequired,
  },
  getInitialState() {
    return {
      hover: false,
    };
  },
  onClick(e) {
    e.preventDefault();

    if (this.isAnotherMouseActionAllowed(e)) {
      const editorAPI = this.getEditorAPI();
      mediaManagerPresetUtil.storePath(
        editorAPI,
        this.props.structure,
        this.props.mediaManager || this.props.preset.mediaManager,
      );
      const eventOrigin = _.pick(e, ['clientX', 'clientY']);
      this.props.onClick(
        this.getItemData(),
        this.props.sectionTitle,
        this.props.preset.tags || '',
        this.props.id,
        editorAPI,
        {
          eventOrigin,
          addComponentOptions: {
            position: this.props?.itemHoverArg?.itemIndex,
            section: translate(this.props.sectionTitle),
            category: this.props.categoryId,
          },
        },
      );
    }
    this.setClickTimeStamp(e);
  },
  onMouseEnter(callback) {
    if (this.props.onMouseEnter) {
      this.props.onMouseEnter(this.props.structure, this.props.id);
    } else {
      this.setState({ hover: true }, callback);
    }
    if (this.props.itemHoverCallback) {
      this.props.itemHoverCallback(this.props.itemHoverArg);
    }
  },
  onMouseLeave(callback) {
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave(this.props.id);
    } else {
      this.setState({ hover: false }, callback);
    }
  },
  onMouseDown(e) {
    e.preventDefault();
    if (
      !_.isFunction(this.props.onItemDrag) ||
      !this.isAnotherMouseActionAllowed(e)
    ) {
      return;
    }

    const item = {
      rect: this.getNormalizedRectValues(this.props.preset.rect),
      structure: this.getItemData(),
      mediaManager: this.props.mediaManager || this.props.preset.mediaManager,
      section: 'preset',
      sectionTitle: this.props.sectionTitle,
      categoryId: this.props.categoryId,
      itemId: this.props.id,
      onDrop: this.props.onDrop,
      tags: this.props.preset.tags,
    };

    if (_.isFunction(this.props.setDragVectorShapeItem)) {
      this.props.setDragVectorShapeItem(item);
    }

    this.props.onItemDrag(e, item);
  },

  getNormalizedRectValues(rect) {
    const areaBoundingRect = this.props.getImagePosition();
    return {
      width: rect.width,
      height: rect.height,
      top: rect.y + areaBoundingRect.top,
      left: rect.x + areaBoundingRect.left,
    };
  },
  getItemStyle() {
    const { rect } = this.props.preset;
    let objStyle = { width: rect.width };
    if (!this.props.liveTextToShow) {
      objStyle = {
        left: rect.x,
        top: rect.y,
        width: rect.width,
        height: rect.height,
      };
    }
    if (this.state.hover) {
      objStyle.backgroundPosition = `${-1 * rect.x}px ${-1 * rect.y}px`;
      objStyle.backgroundRepeat = 'no-repeat';
      if (
        this.props.hoverImageAction ===
        addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE
      ) {
        objStyle.backgroundImage = `url('${util.media.getMediaUrl(
          this.props.imageHover,
          this.getFallbackImage(this.props.imageHover),
        )}')`;
      }
    } else {
      objStyle.background = '';
    }
    return objStyle;
  },
  getItemData() {
    const structureProp = _.cloneDeep(this.props.structure);
    if (!structureProp.style || !_.isString(structureProp.style)) {
      structureProp.style =
        structureProp.styleId ||
        (structureProp.style.styleType === 'system'
          ? structureProp.style.id
          : structureProp.style);
    }
    return structureProp;
  },
  render() {
    return (
      <area
        ref={this.props.id}
        draggable="false"
        href="#"
        data-comp={this.props.structure.componentType}
        onClick={this.onClick}
        onMouseDown={this.onMouseDown}
        onMouseEnter={() => {
          this.onMouseEnter();
        }}
        onMouseLeave={() => {
          this.onMouseLeave();
        }}
        shape={this.props.preset.shape.name}
        coords={this.props.preset.shape.coords}
        className="preset-area-item"
      />
    );
  },
});
