// @ts-nocheck
import wixFormsConstants from '../constants';
import * as wixFormsUtils from '../utils';
import addPanelDataConsts from '@/addPanelDataConsts';

const title = 'add_section_label_requestsforms';
const presetTitle = 'add_section_label_requestsforms_preset';
const tooltipTitle = 'add_section_label_requestsforms_tooltip_title';
const subNavigationTitle = 'add_section_requests_sidebar';
const helpText = 'add_section_label_requestsforms_tooltip_description';

const getRequestSection = (editorAPI) => ({
  requestSection: {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title,
    topTitle: title,
    tooltipTitle,
    sectionName: 'requestSection',
    automationId: 'add-panel-section-requestsSection',
    appDefinitionId: wixFormsConstants.FORM_BUILDER_APP_ID,
    showSectionHeader: true,
    subNavigationTitle,
    subNavigationHide: false,
    presetTitle,
    additionalBehaviours: {
      labelMode: 'none',
      infoIcon: 'none',
      hoverImageAction: 'scale',
      iconEnabledForComps: {},
    },
    props: {
      image:
        'addPanelData/sections/formsRequestSection_en/formsRequestSection_en.png',
      imageStyle: wixFormsConstants.imageStyle,
      imageHover: null,
      items: [
        {
          id: 'Price_Quotes_1',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.PRICE_QUOTE03,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.PRICE_QUOTE03,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 420,
              height: 939,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: { rect: { width: 162, height: 298, x: 0, y: 0 }, tags: null },
        },
        {
          id: 'Price_Quotes_2',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.SUPPORT_REQUEST03,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.SUPPORT_REQUEST03,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 420,
              height: 998,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 298, x: 162, y: 0 },
            tags: null,
          },
        },
      ],
      compTypes: [wixFormsConstants.componentType],
    },
    help: {
      hide: false,
      text: helpText,
    },
  },
});

export default (editorAPI) => getRequestSection(editorAPI);
