import * as coreBi from '@/coreBi';
import { translate } from '@/i18n';
import * as util from '@/util';
import { EVENTS } from '@/wixData';
import { DATA_BINDING } from '@wix/app-definition-ids';
import type { MapDispatchToPropsFunction, MapStateToProps } from 'types/redux';
import type {
  DatabaseWelcomeDispatchProps,
  DatabaseWelcomeOwnProps,
  DatabaseWelcomeStateProps,
} from './types';

export const mapStateToProps: MapStateToProps<
  DatabaseWelcomeStateProps,
  DatabaseWelcomeOwnProps
> = ({ editorAPI }) => ({
  welcomeSectionTemplate: editorAPI.addPanelInfra.welcomeSection,
});

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export const mapDispatchToProps: MapDispatchToPropsFunction<
  DatabaseWelcomeDispatchProps,
  DatabaseWelcomeOwnProps
> = (dispatch, ownProps) => {
  const editorAPI = dispatch(getEditorAPI);

  function notifyDataBindingApp(origin: string) {
    const { applicationId } =
      editorAPI.dsRead.platform.getAppDataByAppDefId(DATA_BINDING);
    const eventType = EVENTS.enableDataModeClicked;
    const payload = {
      origin,
    };
    editorAPI.dsActions.platform.notifyApplication(applicationId, {
      eventType,
      eventPayload: payload,
    });
  }
  const showLoaderPanel = () => {
    const appName = translate(
      'SiteApp_WixData_AddCollectionPanel_InstallationLoader_AppName',
    );
    editorAPI.panelManager.openPanel(
      'panels.focusPanels.fakeProgressBarPanel',
      {
        appName,
        isDone: false,
      },
    );
  };
  const showErrorPanel = () => {
    editorAPI.panelManager.openPanel('savePublish.panels.common.failPanel', {
      titleKey: 'WixCode_DeveloperModeFail_Title',
      description: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION',
      stepsTitle: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION_BOLD',
      steps: [
        'SAVE_PUBLISH_ERROR_OPTION_1',
        'WixCode_DeveloperModeFail_Title',
        'WixCode_DeveloperModeFail_Step3_Text',
      ],
      helpMessage: 'WixCode_DeveloperModeFail_Title',
      helpLinkMessage: 'WixCode_DeveloperModeFail_Learn_More_Link',
      helpLink: '8045e8e4-6f47-4913-8d74-6239f21fffec',
      errorCode: '13200',
    });
  };

  const hideLoaderPanel = () => {
    editorAPI.panelManager.closePanelByName(
      'panels.focusPanels.fakeProgressBarPanel',
      '',
    );
  };

  const isNewAddPanel = util.addPanel.isNewPanelEnabled();

  return {
    turnDataModeOn() {
      const interactionName = isNewAddPanel
        ? util.fedopsLogger.INTERACTIONS.CODE_NEW_ADD_PANEL_FIRST_INSTALL
        : util.fedopsLogger.INTERACTIONS.CODE_ADD_PANEL_FIRST_INSTALL;

      util.fedopsLogger.interactionStarted(interactionName);
      editorAPI.bi.event(coreBi.events.addPanel.DATA_MODE_ON_ACTIVATION_CLICK, {
        origin: 'manual',
        status: true,
        entry_point: ownProps.renderedInSuperAppPanel
          ? 'auto_data_panel'
          : 'add_panel',
      });

      showLoaderPanel();
      editorAPI.wixCode.provision({
        onSuccess,
        onError,
      });

      function onSuccess() {
        util.fedopsLogger.interactionEnded(interactionName);
        hideLoaderPanel();

        const dataModeOrigin = ownProps.renderedInSuperAppPanel
          ? '6th_button_data_panel'
          : 'add_panel_teaser';
        notifyDataBindingApp(dataModeOrigin);
      }

      function onError() {
        hideLoaderPanel();
        showErrorPanel();
      }
    },
    openWelcomeHelpSection() {
      editorAPI.panelManager.openHelpCenter(
        '8ccb780b-6dbc-42c2-8b0e-4ac924d6310f',
      );
    },
  };
};
