import experiment from 'experiment';
import { MIGRATION_TIME_LIMIT } from '../constants';
import { CanceledMigrationError, TimeLimitError } from '../utils';
import { MigrationFlow } from '../types';

import type { DocumentServicesObject } from 'types/documentServices';

export const waitForChangesAppliedAndCheckTimeLimit = async (
  documentServices: DocumentServicesObject,
  startTimestamp: number,
  flow?: MigrationFlow,
) => {
  await documentServices.waitForChangesAppliedAsync();

  // check if user canceled migration without time limit
  if (window.__IS_MIGRATION_CANCELED__) {
    throw new CanceledMigrationError('canceled_by_user');
  }

  // check if migration exceeded time limit and user clicked skip migration
  if (window.__IS_MIGRATION_CANCELED_TIME_LIMIT__) {
    throw new TimeLimitError('Migration took to long. Canceled by user.');
  }

  if (
    flow !== MigrationFlow.Template &&
    (experiment.isOpen('se_heavySitesMigrationLoader')
      ? flow !== MigrationFlow.Editor
      : true) &&
    flow !== MigrationFlow.HeavySite &&
    performance.now() - startTimestamp >= MIGRATION_TIME_LIMIT
  ) {
    throw new TimeLimitError(
      'Migration took to long. Should be aborted for now.',
    );
  }
};
