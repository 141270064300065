import type { EntryPoint } from '@/apilib';
import { EditorAPIKey } from '@/apis';
import { createPromotionsApi } from './promotionsWrapper';
import { PromotionsApiKey } from './publicApiKey';

export const PromotionsEntrypoint: EntryPoint = {
  name: 'PromotionsApi',
  declareAPIs: () => [PromotionsApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(PromotionsApiKey, () => createPromotionsApi(shell));
  },
};
