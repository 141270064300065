import type { EditorAPI } from '@/editorAPI';
import _ from 'lodash';
import constants from '../constants/openBehaviorsConstants';

function getSomeBehavior(currentOpenBehaviors: AnyFixMe, editorAPI: EditorAPI) {
  if (_.isEmpty(currentOpenBehaviors)) {
    return editorAPI.behaviors.getDefinition(constants.OPEN_POPUP_BEHAVIOR);
  }

  return currentOpenBehaviors[0];
}

function isOpenInMobile(currentOpenBehaviors: AnyFixMe, editorAPI: EditorAPI) {
  return getSomeBehavior(currentOpenBehaviors, editorAPI).params[
    constants.FLAG_MOBILE
  ];
}

function getDelay(currentOpenBehaviors: AnyFixMe, editorAPI: EditorAPI) {
  return getSomeBehavior(currentOpenBehaviors, editorAPI).params.delay;
}

function getStartISODate(currentOpenBehaviors: AnyFixMe, editorAPI: EditorAPI) {
  return getSomeBehavior(currentOpenBehaviors, editorAPI).params.startISODate;
}

function getEndISODate(currentOpenBehaviors: AnyFixMe, editorAPI: EditorAPI) {
  return getSomeBehavior(currentOpenBehaviors, editorAPI).params.endISODate;
}

function getScheduleTimeZone(
  currentOpenBehaviors: AnyFixMe,
  editorAPI: EditorAPI,
) {
  return getSomeBehavior(currentOpenBehaviors, editorAPI).params
    .scheduleTimeZone;
}

function getCurrentOpenBehaviors(editorAPI: EditorAPI) {
  const popupId = editorAPI.dsRead.pages.popupPages.getCurrentPopupId();
  return editorAPI.pages.popupPages.getOpenBehaviorsForPopupWithId(popupId);
}

function findMasterPageBehavior(editorAPI: EditorAPI, openBehaviors: AnyFixMe) {
  const masterPageId = editorAPI.dsRead.siteSegments.getSiteStructureId();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  return _.find(openBehaviors, { pageId: masterPageId });
}

function isMasterPageSelected(editorAPI: EditorAPI, openBehaviors: AnyFixMe) {
  return !!findMasterPageBehavior(editorAPI, openBehaviors);
}

function getDenormalizedBehaviors(
  editorAPI: EditorAPI,
  openBehaviors: AnyFixMe,
  allPageIds: AnyFixMe,
) {
  const masterPageBehavior = findMasterPageBehavior(editorAPI, openBehaviors);

  if (masterPageBehavior) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(allPageIds, function (pageId) {
      return _.defaults({ pageId }, masterPageBehavior);
    });
  }

  return openBehaviors;
}

function getDisabledPageIds(
  allOpenBehaviors: AnyFixMe,
  popupId: AnyFixMe,
  editorAPI: EditorAPI,
) {
  const allPageIds = editorAPI.dsRead.pages.getPageIdList();
  const denormalizedBehaviors = getDenormalizedBehaviors(
    editorAPI,
    allOpenBehaviors,
    allPageIds,
  );
  return _(denormalizedBehaviors).reject({ popupId }).map('pageId').value();
}

export {
  isOpenInMobile,
  isMasterPageSelected,
  findMasterPageBehavior,
  getCurrentOpenBehaviors,
  getSomeBehavior,
  getDelay,
  getDenormalizedBehaviors,
  getDisabledPageIds,
  getStartISODate,
  getEndISODate,
  getScheduleTimeZone,
};
