'use strict'
// const experiment = require('experiment')
const youTubeStyleSection = require('../sections/video/youTubeStyleSection')
const socialVideoSection = require('../sections/video/socialVideoSection')
const videoBoxSection = require('../sections/video/videoBoxSection')
const videoBoxSectionVideos = require('../sections/video/videos/videoBoxSectionVideos')
const videoBoxTransparentSection = require('../sections/video/videoBoxTransparentSection')
const videoBoxTransparentSectionVideos = require('../sections/video/videos/videoBoxTransparentSectionVideos')
const videoBoxMaskedSection = require('../sections/video/videoBoxMaskedSection')
const videoBoxMaskedSectionVideos = require('../sections/video/videos/videoBoxMaskedSectionVideos')
//legacy
const singleVideoAndAlphaSection = require('../sections/video/singleVideoAndAlphaSection')
const singleVideoAndAlphaSectionVideos = require('../sections/video/videos/singleVideoAndAlphaSectionVideos')

module.exports = {
    socialVideoSection,
    youTubeStyleSection,
    videoBoxSection,
    videoBoxSectionVideos,
    videoBoxTransparentSection,
    videoBoxTransparentSectionVideos,
    videoBoxMaskedSection,
    videoBoxMaskedSectionVideos,
    singleVideoAndAlphaSection,
    singleVideoAndAlphaSectionVideos
}
