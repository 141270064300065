// @ts-nocheck
import _ from 'lodash';

const getWixMediaSubcategory = (
  tree,
  root,
  categoryIndex = 0,
  subcategoryIndex = 0,
) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const mediaRoot = _.find(tree, { id: root });
  const category = _.get(mediaRoot, `subFolders[${categoryIndex}]`);
  const subcategory = _.get(category, `subFolders[${subcategoryIndex}]`);

  return {
    categoryId: category.id,
    id: subcategory.id,
  };
};

export { getWixMediaSubcategory };
