/**
 * Similar configuration that we promote for settings/layout panels
 *
 * After fixing issue with @wix/editor-elements-preview-utils this utility
 * will be imported directly from editor-elements package
 */
interface IPropertyConfiguration {
  hidden?: boolean;
}

interface IConfiguration {
  /**
   * This is used to configure specific state to be hidden from design panel
   */
  states?: Record<string, IPropertyConfiguration>;
  /**
   * This is used to configure specific control to be hidden
   */
  controls?: Record<string, IPropertyConfiguration>;
}

export const applyDesignPanelConfiguration = (
  configuration: IConfiguration,
  rawSkinDefinition: Record<string, AnyFixMe>,
) => {
  const hasStates = Object.keys(configuration.states || {}).length > 0;
  const hasControls = Object.keys(configuration.controls || {}).length > 0;

  const paramsToIgnore: Array<string> = [];

  const rawSkinDefinitionParams: Record<string, AnyFixMe> =
    rawSkinDefinition.params ?? rawSkinDefinition;

  if (hasStates) {
    const ignoredStates = Object.entries(configuration.states)
      .filter(([, state]) => state?.hidden)
      .map(([stateName]) => stateName);

    paramsToIgnore.push(
      ...Object.entries(rawSkinDefinitionParams)
        .filter(([, { state }]) => ignoredStates.includes(state))
        .map(([paramName]) => paramName),
    );
  }

  if (hasControls) {
    const ignoredControls = Object.entries(configuration.controls)
      .filter(([, control]) => control?.hidden)
      .map(([controlName]) => controlName);

    paramsToIgnore.push(
      ...Object.keys(rawSkinDefinitionParams).filter((paramName) =>
        ignoredControls.includes(paramName),
      ),
    );
  }

  return paramsToIgnore;
};
