import React from 'react';
import { Composites, Illustration, RichText } from '@wix/wix-base-ui';

import * as util from '@/util';
import * as baseUI from '@/baseUI';
import * as panels from '@/panels';
import { translate } from '@/i18n';

import type { SaveInteractionStartedSource } from 'types/fedops/saveInteraction';
import connect from './saveYourSitePanelConnect';

const { MessagePanelFrame, OldMessagePanelFrame } = panels.frames;

interface SaveYourSitePanelProps {
  title: string;
  panelName: string;
  messageBody: string;
  saveSite: (sourceOfStart: SaveInteractionStartedSource) => void;
  closePanelByName: (panelName: string) => void;
}

const NewWorkspaceSaveYourSitePanel: React.FC<SaveYourSitePanelProps> = ({
  panelName,
  title,
  messageBody,
  saveSite,
}) => {
  const onConfirm = () => saveSite('saveYourSitePanel_confirmBtn');
  return (
    <MessagePanelFrame
      panelName={panelName}
      title={translate(title)}
      illustration={<baseUI.symbol name="saveYourWork_NewWorkspace" />}
      confirmLabel={translate('TOPBAR_SITE_FEEDBACK_FIRST_SAVE_SITE_Button')}
      cancelLabel={translate(
        'TOPBAR_SITE_FEEDBACK_FIRST_SAVE_SITE_Cancel_Button',
      )}
      onConfirm={onConfirm}
      confirmOnEnterPress
    >
      {translate(messageBody)}
    </MessagePanelFrame>
  );
};

class SaveYourSitePanel extends React.Component<SaveYourSitePanelProps> {
  closePanel = () => this.props.closePanelByName(this.props.panelName);

  handleEnterPress = () => {
    this.closePanel();
    this.props.saveSite('saveYourSitePanel_keyEnter');
  };

  keyboardShortcuts = {
    enter: this.handleEnterPress,
    esc: this.closePanel,
  };

  onConfirm = () => this.props.saveSite('saveYourSitePanel_confirmBtn');

  render() {
    const { panelName, title, messageBody } = this.props;

    return (
      <OldMessagePanelFrame
        ref="frame"
        panelName={panelName}
        panelTitle={translate(title)}
        confirmLabel="TOPBAR_SETTINGS_BUTTON_SAVE"
        onConfirm={this.onConfirm}
        keyboardShortcuts={this.keyboardShortcuts}
        className="save-your-site-panel"
      >
        <Composites.RichTextWithIllustration>
          <Illustration>
            <baseUI.symbol name="saveYourWork" />
          </Illustration>
          <Composites.RichText>
            <RichText type="T01">{translate(messageBody)}</RichText>
          </Composites.RichText>
        </Composites.RichTextWithIllustration>
      </OldMessagePanelFrame>
    );
  }
}

const SaveYourSitePanelComponent = util.workspace.isNewWorkspaceEnabled()
  ? NewWorkspaceSaveYourSitePanel
  : SaveYourSitePanel;

export default connect(SaveYourSitePanelComponent);
