import { Button, Text, TextButton, Heading } from '@wix/wix-base-ui';
import React, { type FC } from 'react';
import { ExternalLink } from '@wix/wix-ui-icons-common';
import styles from './EmptyState.scss';
import { hoc } from '@/util';
import { openBlocksEditor, OpenBlocksEditorOrigins } from '../../utils/utils';
import * as stateManagement from '@/stateManagement';
import type { MapDispatchToPropsFunction } from '@wix/santa-editor-utils';
import { EmptyPrivateAppsLogo } from './EmptyStateLogo';
import { translate } from '@/i18n';

interface EmptyPrivateAppsPanelProps {
  openBlocks?: () => void;
  openHelp?: () => void;
}

const EmptyPrivateAppsPanel: FC<EmptyPrivateAppsPanelProps> = ({
  openBlocks = () => {},
  openHelp = () => {},
}) => (
  <div className={styles.emptyPrivateAppsPanelWrapper}>
    <div className={styles.emptyPrivateAppsPanel}>
      <EmptyPrivateAppsLogo />
      <div className={styles.emptyPrivateAppsPanelTitle}>
        <Heading appearance="h4" multiline={true}>
          blocks-private-apps.AppDiscovery_Empty_State_Title
        </Heading>
      </div>
      <div className={styles.emptyPrivateAppsPanelContent}>
        <div className={styles.emptyPrivateAppsPanelText}>
          <Text size="medium" enableEllipsis={false}>
            blocks-private-apps.AppDiscovery_Empty_State_Text
          </Text>
        </div>
        <div>
          <Button
            className={styles.goToBlocksButton}
            suffixIcon={<ExternalLink />}
            onClick={openBlocks}
          >
            {translate(
              'blocks-private-apps.AppDiscovery_Empty_State_Start_Building_CTA',
            )}
          </Button>
        </div>
        <div>
          <TextButton weight="bold" onClick={openHelp}>
            blocks-private-apps.AppDiscovery_Empty_State_Learn_More
          </TextButton>
        </div>
      </div>
    </div>
  </div>
);

const mapDispatchToProps: MapDispatchToPropsFunction<
  EmptyPrivateAppsPanelProps,
  EmptyPrivateAppsPanelProps
> = (dispatch) => {
  const openHelp = () =>
    dispatch(
      stateManagement.panels.actions.openHelpCenter(
        '8728da39-3b11-40b5-9a6b-6a86c5f36830',
      ),
    );
  const openBlocks = () =>
    openBlocksEditor(OpenBlocksEditorOrigins.EMPTY_STATE);
  return { openBlocks, openHelp };
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  undefined,
  mapDispatchToProps,
)(EmptyPrivateAppsPanel);
