import createReactClass from 'create-react-class';
import firstTimeTourMixin from '../firstTimeTourMixin';
import * as wixStoreEditorActionsService from '../../../../common/wixStoreEditorActionsService';
import * as wixStoreDataProvider from '../../../../common/wixStoreDataProvider';
import * as tpa from '@/tpa';
import * as util from '@/util';
import React from 'react';
import * as superAppMenuInfra from '@/superAppMenuInfra';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'promotionalStep1wixStore',
  mixins: [
    firstTimeTourMixin,
    util.propTypesFilterMixin as React.Mixin<any, any>,
  ],
  componentDidMount() {
    wixStoreEditorActionsService.firstTimePanelOpen(
      'add_wixstores',
      this.props.openType,
    );
  },
  installApp(done: AnyFixMe) {
    wixStoreEditorActionsService.skipFirstTimeTour();
    wixStoreEditorActionsService.addAppToSite(
      tpa.constants.BI.type.ADD_APP_APP_BUTTON,
    );
    done();
  },
  openAppMarket() {
    wixStoreEditorActionsService.closeCurrentWixStorePanel();

    wixStoreEditorActionsService.openStoresAppMarket();
  },
  getSliderImages() {
    return [
      util.media.getMediaUrl(
        'wixStore/appMenuPanel/gallery/storeGallery01.png',
      ),
      util.media.getMediaUrl(
        'wixStore/appMenuPanel/gallery/storeGallery02.png',
      ),
      util.media.getMediaUrl(
        'wixStore/appMenuPanel/gallery/storeGallery03.png',
      ),
      util.media.getMediaUrl(
        'wixStore/appMenuPanel/gallery/storeGallery04.png',
      ),
      util.media.getMediaUrl(
        'wixStore/appMenuPanel/gallery/storeGallery05.png',
      ),
      util.media.getMediaUrl(
        'wixStore/appMenuPanel/gallery/storeGallery06.png',
      ),
    ];
  },
  render() {
    const installImage = util.workspace.isNewWorkspaceEnabled()
      ? 'onlineStoreSpecialBtnPromotional_NewWorkspace'
      : 'onlineStoreSpecialBtnPromotional';

    return (
      <superAppMenuInfra.firstTimeTourPanels.installAppSlide
        installApp={this.installApp}
        origin="promotionalPanel"
        superAppPanelName={
          wixStoreDataProvider.wixStoreLeftBarPanelParams.panelName
        }
        superAppButtonLabel={
          wixStoreDataProvider.wixStoreLeftBarPanelParams.label
        }
        superAppPanelClassName="wixStoreDataProvider.wixStoreLeftBarPanelParams.className"
        superAppSymbolName={
          wixStoreDataProvider.wixStoreLeftBarPanelParams.symbolName
        }
        title="SiteApp_WixStores_AddApp_Title"
        subTitle="SiteApp_WixStores_AddApp_Subtitle"
        pros={[
          'SiteApp_WixStores_AddApp_Bullet1',
          'SiteApp_WixStores_AddApp_Bullet2',
          'SiteApp_WixStores_AddApp_Bullet3',
        ]}
        nextButtonLabel="SiteApp_WixStores_AddApp_Button"
        loadingLabel="SiteApp_WixStores_PromotionalFTUE_Step2_Loader_Caption"
        sliderImages={this.getSliderImages()}
        installImage={installImage}
        openAppMarketLabel="SiteApp_Blog_PromotionalFTUE_Step1_SeeInAppMarket_LinkCaption"
        openAppMarket={this.openAppMarket}
        {...this.filteredProps()}
      />
    );
  },
});
