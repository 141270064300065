import type { ThemedPreviewProps } from '@wix/add-panel-component';
import React from 'react';
import _ from 'lodash';
import { ThemeSection, PersonalizedSection } from '@/addPanelInfra';
import * as core from '@/core';
import type { EditorAPI } from '@/editorAPI';

const TRANSPARENT_SKINS = [
  'wysiwyg.viewer.skins.page.TransparentPageSkin',
  'wysiwyg.viewer.skins.screenwidthcontainer.TransparentHalfScreen',
  'wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen',
  'wysiwyg.viewer.skins.area.TransparentArea',
];
const VIDEO_SKIN = ['wysiwyg.viewer.skins.VideoSkin'];
const IMAGE_STYLE_FRAME = ['wysiwyg.viewer.skins.photo.NoSkinPhoto'];

const wrapLiveSection = (
  ComponentFn: () => React.ComponentType<any>,
): React.ComponentType<ThemedPreviewProps> => {
  // eslint-disable-next-line react/display-name
  return class extends React.Component<ThemedPreviewProps> {
    Component: React.ComponentType<any>;
    constructor(props: ThemedPreviewProps) {
      super(props);
      this.Component = ComponentFn();
    }
    getAdditionalElementProps = (compDef: any, editorAPI: EditorAPI) => {
      //thats needed to render transparent grid and 'No frame' labels
      const style = core.styleManager.getCompDefStyle(compDef, editorAPI);
      const props: any = {};

      if (TRANSPARENT_SKINS.includes(style.skin)) {
        props.text = 'DESIGN_LABEL_NO_COLOR';
        props.className = 'design-panel-transparent-skin-item';
      } else if (VIDEO_SKIN.includes(style.skin)) {
        props.text = 'Design_Label_No_Frame';
        props.className = 'design-panel-no-frame-skin-item';
      } else if (IMAGE_STYLE_FRAME.includes(style.skin)) {
        props.text = 'Design_Label_No_Frame';
        const imageCompType = 'wysiwyg.viewer.components.WPhoto' as any;
        if (
          this.props.props &&
          _.head(this.props.props.compTypes) === imageCompType
        ) {
          props.className = 'design-panel-no-frame-skin-item';
        } else {
          props.className = 'design-panel-no-frame-skin-item-discover-more';
        }
      }
      return props;
    };
    public render() {
      return (
        <this.Component
          {...this.props}
          getAdditionalElementProps={this.getAdditionalElementProps}
        />
      );
    }
  };
};

export const ThemedPreview = wrapLiveSection(() => ThemeSection); //hack for cycled references
export const PersonalizedPreview = wrapLiveSection(() => PersonalizedSection);
