// @ts-nocheck
import constants from '@/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [],
  enabledActions: [ACTIONS.SETTINGS, ACTIONS.DESIGN],
  mobileMainActions: [],
  mobileEnabledActions: [],
};
