import { translate } from '@/i18n';

import { getDesktopRefById, isAppController } from '../../../utils';

import { calculateSectionTopEdge, calculateSectionBottomEdge } from './utils';

import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import type { SectionDescription, GroupingItem } from '../../types';

function getSectionsDescriptionsItem(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  grouping: GroupingItem,
): SectionDescription {
  const children = grouping.children.map((compId: string) =>
    getDesktopRefById(documentServices, compId),
  );

  if (children.length === 0) {
    return {
      children: [],
      childrenToRemove: [],
      childrenToKeepDesktopLayout: [],
      behaviors: [],
      name: translate('section_name_blank'),
      contentRole: grouping.contentRole,
      top: 0,
      bottom: 0,
    };
  }

  const childrenLayouts = children.map((compRef: CompRef) => {
    // exclude app controller from section layout calculation
    if (isAppController(documentServices, compRef)) {
      return {
        isFullWidth: false,
        top: undefined,
        bottom: undefined,
      };
    }

    const layout = documentServices.components.layout.get(compRef);
    const isFullWidth = documentServices.components.is.fullWidth(compRef);

    return {
      isFullWidth,
      top: layout.y,
      bottom: layout.y + layout.height,
    };
  });

  const { position: top, isFullWidth: isFullWidthTop } =
    calculateSectionTopEdge(childrenLayouts);
  const { position: bottom, isFullWidth: isFullWidthBottom } =
    calculateSectionBottomEdge(childrenLayouts);

  return {
    children,
    childrenToRemove: [],
    childrenToKeepDesktopLayout: [],
    behaviors: [],
    name: grouping.name || translate('section_name_blank'),
    contentRole: grouping.contentRole,
    top,
    bottom,
    isFullWidthTop,
    isFullWidthBottom,
  };
}

export function getDesktopSectionsDescriptions(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  grouping: GroupingItem[],
): SectionDescription[] {
  return grouping.map((section: GroupingItem) =>
    getSectionsDescriptionsItem(documentServices, pageRef, section),
  );
}
