import type { ComponentEditorMetaDataDefinition } from '../types';

const metaData: ComponentEditorMetaDataDefinition = {
  overrideProportionalResize: true,
  fixedNonPinned: true,
  arrangeable: false,
  rotatable: false,
};

export default metaData;
