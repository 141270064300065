'use strict'
const addPanelDataConsts = require("@/addPanelDataConsts");

module.exports =
{
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_heading_range_slider",
    "subNavigationTitle": "add_section_label_range_slider",
    "presetTitle": "add_section_heading_range_slider",
    "tooltipTitle": "add_section_label_range_slider",
    "automationId": "add-panel-section-collapsibleText-paragraphsSection",
    "subNavigationHide": true,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
        "iconEnabledForComps": {}
    },
    "props": {
      "image": "addPanelData/sections/rangeSliderSection_en/rangeSliderSection_en.v3.png",
      "imageHover": null,
      "items": [
        {
          "id": "Stylable_RangeSlider_1",
          "structure": {
            "type": "Component",
            "metaData": {
              "pageId": "c1dmp"
            },
            "skin": "wixui.skins.Skinless",
            "layout": {
              "width": 304,
              "height": 66,
              "x": 11,
              "y": 21,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wixui.RangeSlider",
            "data": {
              "type": "wixui.RangeSlider",
              "label": "Color range",
              "sliderType": "Continuous",
              "stepType": "Number",
              "step": 1,
              "direction": "ltr",
              "min": 0,
              "max": 100,
              "prefix": "",
              "suffix": "",
              "minValue": 10,
              "maxValue": 50,
              "thumbShape": "Circle",
              "marksTickShape": "Lines",
              "thumbSize": 24,
              "readOnly": false,
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "pageId": "c1dmp"
              }
            },
            "props": {
              "type": "RangeSliderProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "pageId": "c1dmp"
              }
            },
            "mobileHints": {
              "type": "MobileHints",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "pageId": "c1dmp"
              }
            },
            "style": {
              "type": "ComponentStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "pageId": "c1dmp"
              },
              "style": {
                "properties": {
                  "$st-css": ":import {\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: RangeSlider, --RangeSlider-thumbWidth, --RangeSlider-thumbHeight, --RangeSlider-rail-percentage, --RangeSlider-label-left-padding, --RangeSlider-label-bottom-padding;\n}\n.root{\n    -st-extends: RangeSlider;\n    --RangeSlider-thumbHeight: 25px;\n    --RangeSlider-rail-percentage: 67;\n}\n.root::label {\n    font-family: proxima-n-w01-reg,proxima-n-w05-reg,sans-serif;\n    font-size: 16px;\n}\n.root::slider-rail {\n    background: #414141;\n    border-radius: 20px;\n}\n.root::slider-track {\n    background: 0% 0%/auto auto linear-gradient(109deg, #0265bb 0%, #3899ec 18%, #63e8e8 100%);\n}\n.root::sliderThumb {\n    background: #3899EC;\n    border: 8px solid value(site_1_1);\n    box-shadow: 1px 1.73px 8px -4px rgba(0, 0, 0, 0.73);\n}"
                },
                "propertiesSource": {
                  "$st-css": "value"
                }
              },
              "componentClassName": "wixui.RangeSlider",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "wixui.skins.Skinless"
            },
            "activeModes": {},
            "id": "comp-l0ghd5oi",
            "components": []
          },
          "preset": {
            "rect": {
              "width": 324,
              "height": 101,
              "x": 0,
              "y": 0
            },
            "tags": null
          }
        },
        {
          "id": "Stylable_RangeSlider_2",
          "structure": {
            "type": "Component",
            "metaData": {
              "pageId": "c1dmp"
            },
            "skin": "wixui.skins.Skinless",
            "layout": {
              "width": 304,
              "height": 59,
              "x": 10,
              "y": 123,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wixui.RangeSlider",
            "data": {
              "type": "wixui.RangeSlider",
              "label": "Number of rooms",
              "sliderType": "Continuous",
              "stepType": "Number",
              "step": 1,
              "direction": "ltr",
              "min": 0,
              "max": 100,
              "prefix": "",
              "suffix": "",
              "minValue": 10,
              "maxValue": 50,
              "thumbShape": "Circle",
              "marksTickShape": "Lines",
              "thumbSize": 24,
              "readOnly": false,
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "pageId": "c1dmp"
              }
            },
            "props": {
              "type": "RangeSliderProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "pageId": "c1dmp"
              }
            },
            "mobileHints": {
              "type": "MobileHints",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "pageId": "c1dmp"
              }
            },
            "style": {
              "type": "ComponentStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "pageId": "c1dmp"
              },
              "style": {
                "properties": {
                  "$st-css": ":import {\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: RangeSlider, --RangeSlider-thumbWidth, --RangeSlider-thumbHeight, --RangeSlider-rail-percentage, --RangeSlider-label-left-padding, --RangeSlider-label-bottom-padding;\n}\n.root{\n    -st-extends: RangeSlider;\n    --RangeSlider-thumbHeight: 12px;\n    --RangeSlider-rail-percentage: 43;\n    --RangeSlider-label-bottom-padding: 6px;\n}\n.root::label {\n    font-family: proxima-n-w01-reg,proxima-n-w05-reg,sans-serif;\n    font-size: 16px;\n}\n.root::slider-rail {\n    background: #D7D4FD;\n}\n.root::slider-track {\n    background: #8C84FA;\n}\n.root::sliderThumb {\n    background: #8C84FA;\n    box-shadow: 0 0 0 6px rgba(99,89,238,0.28);\n}"
                },
                "propertiesSource": {
                  "$st-css": "value"
                }
              },
              "componentClassName": "wixui.RangeSlider",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "wixui.skins.Skinless"
            },
            "activeModes": {},
            "id": "comp-l0gib6er",
            "components": []
          },
          "preset": {
            "rect": {
              "width": 324,
              "height": 96,
              "x": 0,
              "y": 101
            },
            "tags": null
          }
        },
        {
          "id": "Stylable_RangeSlider_3",
          "structure": {
            "type": "Component",
            "metaData": {
              "pageId": "c1dmp"
            },
            "skin": "wixui.skins.Skinless",
            "layout": {
              "width": 304,
              "height": 64,
              "x": 11,
              "y": 212,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wixui.RangeSlider",
            "data": {
              "type": "wixui.RangeSlider",
              "label": "Price range",
              "sliderType": "Continuous",
              "stepType": "Number",
              "step": 1,
              "direction": "ltr",
              "min": 0,
              "max": 100,
              "prefix": "",
              "suffix": "",
              "minValue": 10,
              "maxValue": 50,
              "thumbShape": "Circle",
              "marksTickShape": "Lines",
              "thumbSize": 24,
              "readOnly": false,
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "pageId": "c1dmp"
              }
            },
            "props": {
              "type": "RangeSliderProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "pageId": "c1dmp"
              }
            },
            "mobileHints": {
              "type": "MobileHints",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "pageId": "c1dmp"
              }
            },
            "style": {
              "type": "ComponentStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "pageId": "c1dmp"
              },
              "style": {
                "properties": {
                  "$st-css": ".root::label {\n    font-family: proxima-n-w01-reg,proxima-n-w05-reg,sans-serif;\n    font-size: 16px;\n}\n.root::slider-rail {\n    background: #EAEAEA;\n}\n.root::slider-track {\n    background: value(site_1_5);\n}:import {\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: RangeSlider, --RangeSlider-thumbWidth, --RangeSlider-thumbHeight, --RangeSlider-rail-percentage, --RangeSlider-label-left-padding, --RangeSlider-label-bottom-padding;\n}\n.root{\n    -st-extends: RangeSlider;\n    --RangeSlider-thumbWidth: calc(var(--RangeSlider-thumbHeight) * 0.5);\n    --RangeSlider-thumbHeight: 23px;\n    --RangeSlider-rail-percentage: 13;\n}\n.root::sliderThumb {\n    border-radius: 7px;\n    background: value(site_1_1);\n    border: 2px solid value(site_1_5);\n}"
                },
                "propertiesSource": {
                  "$st-css": "value"
                }
              },
              "componentClassName": "wixui.RangeSlider",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "wixui.skins.Skinless"
            },
            "activeModes": {},
            "id": "comp-l0gil55y",
            "components": []
          },
          "preset": {
            "rect": {
              "width": 324,
              "height": 96,
              "x": 0,
              "y": 197
            },
            "tags": null
          }
        },
        {
          "id": "Stylable_RangeSlider_4",
          "structure": {
            "type": "Component",
            "metaData": {
              "pageId": "c1dmp"
            },
            "skin": "wixui.skins.Skinless",
            "layout": {
              "width": 304,
              "height": 68,
              "x": 10,
              "y": 306,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wixui.RangeSlider",
            "data": {
              "type": "wixui.RangeSlider",
              "label": "Brightness",
              "sliderType": "Continuous",
              "stepType": "Number",
              "step": 1,
              "direction": "ltr",
              "min": 0,
              "max": 100,
              "prefix": "",
              "suffix": "",
              "minValue": 10,
              "maxValue": 50,
              "thumbShape": "Circle",
              "marksTickShape": "Lines",
              "thumbSize": 24,
              "readOnly": false,
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "pageId": "c1dmp"
              }
            },
            "props": {
              "type": "RangeSliderProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "pageId": "c1dmp"
              }
            },
            "mobileHints": {
              "type": "MobileHints",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "pageId": "c1dmp"
              }
            },
            "style": {
              "type": "ComponentStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "pageId": "c1dmp"
              },
              "style": {
                "properties": {
                  "$st-css": ".root::label {\n    font-family: proxima-n-w01-reg,proxima-n-w05-reg,sans-serif;\n    font-size: 16px;\n}\n.root::slider-rail {\n    background: #D9F7F7;\n    border-radius: 20px;\n    box-shadow: 0 0 8px -3px rgba(66,204,204,0.27);\n    border: 0px solid rgb(66, 204, 204);\n}\n.root::slider-track {\n    background: #42CCCC;\n}:import {\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: RangeSlider, --RangeSlider-thumbWidth, --RangeSlider-thumbHeight, --RangeSlider-rail-percentage, --RangeSlider-label-left-padding, --RangeSlider-label-bottom-padding;\n}\n.root{\n    -st-extends: RangeSlider;\n    --RangeSlider-thumbWidth: var(--RangeSlider-thumbHeight);\n    --RangeSlider-thumbHeight: 27px;\n    --RangeSlider-rail-percentage: 72;\n}\n.root::sliderThumb {\n    border-radius: 7px;\n    background: #42CCCC;\n    border: 3px solid value(site_1_1);\n    box-shadow: 0 0 0 2px #42CCCC;\n}"
                },
                "propertiesSource": {
                  "$st-css": "value"
                }
              },
              "componentClassName": "wixui.RangeSlider",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "wixui.skins.Skinless"
            },
            "activeModes": {},
            "id": "comp-l0giqf7f",
            "components": []
          },
          "preset": {
            "rect": {
              "width": 324,
              "height": 101,
              "x": 1,
              "y": 293
            },
            "tags": null
          }
        }
      ],
      "compTypes": [
        "wixui.RangeSlider"
      ]
    },
    "help": {
        "hide": true,
        "text": "add_section_label_range_slider"
    }
}

