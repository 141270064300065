import _ from 'lodash';
import constants from '@/constants';
import { biLogger, fedopsLogger, sections as sectionsUtils } from '@/util';
import { domMeasurements, notifications } from '@/stateManagement';
import { translate } from '@/i18n';
import { TRANSLATIONS } from '../../../translations';

import type { CompRef } from 'types/documentServices';
import type { Scope } from '@/sections';
import type { EditorAPI } from '@/editorAPI';
import { clickOnGoToLayoutSettingsForHideHeaderAndFooterFeature } from '@wix/bi-logger-editor/v2';

const { getStageLayout, translateToViewerCoordinates } =
  domMeasurements.selectors;

const resizeInteractions = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.RESIZE,
);

const ANIMATION_DURATION_SEC = 1;
const SCROLL_THROTTLE_DELAY_MS = ANIMATION_DURATION_SEC * 1000;
const EXTRA_HEIGHT = 100;
const BOTTOM_OFFSET_TO_START_SCROLL = 40;

export const showCannotReduceSiteSegmentNotification = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  return editorAPI.store.dispatch(
    notifications.actions.showUserActionNotification({
      message: translate(
        TRANSLATIONS.NOTIFICATIONS.CANNOT_REDUCE_SITE_SEGMENT.TEXT,
      ),
      type: 'info',
      shouldTranslate: false,
      link: {
        caption: translate(
          TRANSLATIONS.NOTIFICATIONS.CANNOT_REDUCE_SITE_SEGMENT.LINK,
        ),
        onNotificationLinkClick: () => {
          editorAPI.panelHelpers.openPagesPanel({
            initialSettingsTabType: constants.PAGE_SETTINGS_TABS.LAYOUT,
          });

          const [params] = editorAPI.bi.getComponentsBIParams([compRef]);
          biLogger.report(
            clickOnGoToLayoutSettingsForHideHeaderAndFooterFeature({
              ...params,
              origin: 'reduce size notification',
            }),
          );
        },
      },
    }),
  );
};

export const createHeaderFooterResizeAndPushHooks = ({ editorAPI }: Scope) => {
  let extraSiteHeightBeforeResize: number;

  const waitForSiteExtraHeightChange = () => {
    let unsubscribe;

    const promise = new Promise<void>((resolve) => {
      unsubscribe = editorAPI.registerToViewerChange(
        (changes: string[] | null) => {
          if (!changes?.includes('documentMode.setExtraSiteHeight')) return;
          resolve();
        },
      );
    });

    return promise.then(unsubscribe);
  };

  let prevMouseY: number | undefined;

  const setExtraSiteHeightIfNeeded = async () => {
    if (extraSiteHeightBeforeResize) return;
    extraSiteHeightBeforeResize = editorAPI.documentMode.getExtraSiteHeight();
    editorAPI.documentMode.setExtraSiteHeight(EXTRA_HEIGHT);
    await waitForSiteExtraHeightChange();
  };

  const start = async (compRef: CompRef) => {
    if (!editorAPI.sections.isFooter(compRef)) return;

    resizeInteractions.start();
  };

  const scroll = () => {
    const stageLayout = getStageLayout(editorAPI.store.getState());
    const scrollTo = editorAPI.site.getHeight() - stageLayout.height + 50; // scroll to the top part of the view + 50px

    editorAPI.scroll.animateScrollTo(
      { y: scrollTo },
      ANIMATION_DURATION_SEC,
      () => {
        editorAPI.store.dispatch(domMeasurements.actions.initDomMeasurements());
      },
    );
  };

  const delayedScroll = _.throttle(scroll, SCROLL_THROTTLE_DELAY_MS);

  const on = async ({
    compRef,
    event,
  }: {
    compRef: CompRef;
    event: React.MouseEvent;
  }) => {
    if (!editorAPI.sections.isFooter(compRef)) return;
    const clientY = event.clientY;

    const isScrollingBottom = prevMouseY && clientY > prevMouseY;

    if (
      isScrollingBottom &&
      clientY >= window.innerHeight - BOTTOM_OFFSET_TO_START_SCROLL
    ) {
      await setExtraSiteHeightIfNeeded();
      delayedScroll();
    }
    prevMouseY = clientY;
  };

  const showNotificationIfNeeded = (
    event: React.MouseEvent,
    compRef: CompRef,
  ) => {
    const minHeightByType = {
      [constants.COMP_TYPES.HEADER]: sectionsUtils.constants.HEADER_MIN_HEIGHT,
      [constants.COMP_TYPES.FOOTER]: sectionsUtils.constants.FOOTER_MIN_HEIGHT,
    };

    const type = editorAPI.components.getType(compRef);

    if (!(type in minHeightByType)) return;

    showSiteSegmentNotificationIfNeeded(
      event,
      compRef,
      minHeightByType[type as keyof typeof minHeightByType],
    );
  };

  const showSiteSegmentNotificationIfNeeded = (
    event: React.MouseEvent,
    compRef: CompRef,
    minHeight: number,
  ) => {
    const pageId = editorAPI.pages.getCurrentPageId();

    if (!editorAPI.pages.settings.hasLayoutSettingsTab(pageId)) return;

    const mouse = translateToViewerCoordinates(editorAPI, event);
    const hasChildren = !!editorAPI.components.getChildren(compRef).length;
    const layout = editorAPI.components.layout.get_position(compRef);

    if (hasChildren || mouse.pageY - layout.y > minHeight) return;

    showCannotReduceSiteSegmentNotification(editorAPI, compRef);
  };

  const end = async (event: React.MouseEvent, compRef: CompRef) => {
    showNotificationIfNeeded(event, compRef);

    if (!editorAPI.sections.isFooter(compRef)) return;

    if (extraSiteHeightBeforeResize) {
      editorAPI.documentMode.setExtraSiteHeight(extraSiteHeightBeforeResize);
      await waitForSiteExtraHeightChange();
    }

    extraSiteHeightBeforeResize = null;
    prevMouseY = null;

    // TODO: update interaction value
    resizeInteractions.end();
  };

  return {
    start,
    on,
    end,
  };
};
