// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';

let contextStack = [];
const { keyboardShortcuts } = util;
const DYNAMIC_CONTEXT = 'DYNAMIC_CONTEXT_';

const getStackWithoutItems = (items) =>
  _.reject(contextStack, (ctx) => _(items).castArray().includes(ctx));

const pushAndSetContext = (contextName) => {
  contextStack.push(keyboardShortcuts.getContext());
  keyboardShortcuts.setContext(contextName);
  return contextName;
};

const pushAndSetCustomContext = (newContext) => {
  contextStack.push(keyboardShortcuts.getContext());
  const newContextName = _.uniqueId(DYNAMIC_CONTEXT);
  keyboardShortcuts.registerContext(newContextName, newContext);
  keyboardShortcuts.setContext(newContextName);
  return newContextName;
};

const popAndRestoreLastContext = (contextToPop, isDynamic) => {
  if (contextToPop === keyboardShortcuts.getContext()) {
    keyboardShortcuts.setContext(contextStack.pop());
  }

  contextStack = getStackWithoutItems(contextToPop);

  if (isDynamic) {
    keyboardShortcuts.unregisterContext(contextToPop);
  }
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/includes
const hasContext = (contextName) => _.includes(contextStack, contextName);

const removeItemsAndRestoreLastContext = (items) => {
  contextStack = getStackWithoutItems(items);
  keyboardShortcuts.setContext(contextStack.pop());
};

export {
  pushAndSetContext,
  pushAndSetCustomContext,
  popAndRestoreLastContext,
  hasContext,
  removeItemsAndRestoreLastContext,
};
