// @ts-nocheck
import * as core from '@/core';
import * as baseUI from '@/baseUI';
import * as util from '@/util';

const SUCCESS_DURATION = 1000;

export default {
  mixins: [baseUI.inputMixin, core.mixins.editorAPIMixin],

  ValidationStatus: {
    NONE: 'none',
    SUCCESS: 'success',
    ERROR: 'error',
  },
  getInitialState() {
    return { validationStatus: this.ValidationStatus.NONE };
  },

  componentDidMount() {
    this.mounted = true;
  },

  componentWillUnmount() {
    this.mounted = false;
  },

  openLinkDialogFromPanel() {
    this.openLinkDialog(true);
  },

  openLinkDialog(shouldLeaveParentPanelOpen) {
    const editorAPI = this.getEditorAPI();

    editorAPI.openLinkPanel(
      {
        link: util.valueLink.getValueFromProps(this.props),
        visibleSections: this.props.visibleSections,
        callback: this.linkUpdated,
      },
      !shouldLeaveParentPanelOpen,
    );
  },

  linkUpdated(newLink) {
    this.setState({ validationStatus: this.ValidationStatus.SUCCESS });
    util.valueLink.callOnChangeIfExists(this.props, newLink);

    window.setTimeout(
      function () {
        if (this.mounted) {
          this.setState({ validationStatus: this.ValidationStatus.NONE });
        }
      }.bind(this),
      SUCCESS_DURATION,
    );
  },
};
