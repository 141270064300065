'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_contactform",
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/contactFormsSection_en/contactFormsSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "Contact_Contact_Forms_1",
            "structure": {
                "type": "Component",
                "skin": "contactform.OverlappingButtonSkin",
                "layout": {
                    "width": 608,
                    "height": 317,
                    "x": 20,
                    "y": 40,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.ContactForm",
                "data": {
                    "type": "ContactForm",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "toEmailAddress": "",
                    "bccEmailAddress": "",
                    "nameFieldLabel": "What’s Your Name?",
                    "emailFieldLabel": "What’s Your Email?",
                    "phoneFieldLabel": "Phone",
                    "addressFieldLabel": "Address",
                    "subjectFieldLabel": "What’s Your Number?",
                    "messageFieldLabel": "How can we help?",
                    "submitButtonLabel": "Submit",
                    "successMessage": "Your details were sent successfully!",
                    "validationErrorMessage": "Please fill in all required fields.",
                    "errorMessage": "Please provide a valid email",
                    "textDirection": "left",
                    "onSubmitBehavior": "message"
                },
                "props": {
                    "type": "ContactFormProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "hidden_emailFieldLabel": true,
                    "hidden_nameFieldLabel": true,
                    "hidden_phoneFieldLabel": false,
                    "hidden_addressFieldLabel": false,
                    "hidden_subjectFieldLabel": true,
                    "hidden_messageFieldLabel": true,
                    "required_emailFieldLabel": true,
                    "required_nameFieldLabel": true,
                    "required_phoneFieldLabel": false,
                    "required_addressFieldLabel": false,
                    "required_subjectFieldLabel": false,
                    "required_messageFieldLabel": false,
                    "useCookie": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg1": "1",
                            "alpha-bg2": "1",
                            "alpha-brd": "1",
                            "bg1": "#34D1B6",
                            "bg2": "#000000",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#34D1B6",
                            "brw": "0",
                            "brw2": "0",
                            "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_85-heavy1475544",
                            "fnt2": "normal normal normal 25px/1.4em cookie",
                            "labelTxt": "#000000",
                            "shd": "0 0px 0px transparent",
                            "txt1": "#000000",
                            "txt2": "#FFFFFF",
                            "txtError": "color_34",
                            "txtSuccess": "color_23"
                        },
                        "propertiesSource": {
                            "alpha-bg1": "value",
                            "alpha-bg2": "value",
                            "alpha-brd": "value",
                            "bg1": "value",
                            "bg2": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "brw2": "value",
                            "fnt": "value",
                            "fnt2": "value",
                            "labelTxt": "value",
                            "shd": "value",
                            "txt1": "value",
                            "txt2": "value",
                            "txtError": "value",
                            "txtSuccess": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.ContactForm",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "contactform.OverlappingButtonSkin"
                },
                "activeModes": {},
                "id": "comp-ix7ca89g"
            },
            "preset": {"rect": {"width": 324, "height": 185.5, "x": 0, "y": 0}, "tags": null}
        }, {
            "id": "Contact_Contact_Forms_2",
            "structure": {
                "type": "Component",
                "skin": "contactform.FullWidthButtonSkin",
                "layout": {
                    "width": 608,
                    "height": 336,
                    "x": 20,
                    "y": 412,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.ContactForm",
                "data": {
                    "type": "ContactForm",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "toEmailAddress": "",
                    "bccEmailAddress": "",
                    "nameFieldLabel": "Your First Name",
                    "emailFieldLabel": "Your Surname",
                    "phoneFieldLabel": "Phone",
                    "addressFieldLabel": "Address",
                    "subjectFieldLabel": "Your Email",
                    "messageFieldLabel": "Ask us anything...",
                    "submitButtonLabel": "Get In Touch",
                    "successMessage": "Your details were sent successfully!",
                    "validationErrorMessage": "Please fill in all required fields.",
                    "errorMessage": "Please provide a valid email",
                    "textDirection": "left",
                    "onSubmitBehavior": "message"
                },
                "props": {
                    "type": "ContactFormProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "hidden_emailFieldLabel": true,
                    "hidden_nameFieldLabel": true,
                    "hidden_phoneFieldLabel": false,
                    "hidden_addressFieldLabel": false,
                    "hidden_subjectFieldLabel": true,
                    "hidden_messageFieldLabel": true,
                    "required_emailFieldLabel": true,
                    "required_nameFieldLabel": true,
                    "required_phoneFieldLabel": false,
                    "required_addressFieldLabel": false,
                    "required_subjectFieldLabel": false,
                    "required_messageFieldLabel": false,
                    "useCookie": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg1": "1",
                            "alpha-bg2": "1",
                            "alpha-brd": "1",
                            "bg1": "#FFC65C",
                            "bg2": "#112A4B",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#FFC65C",
                            "brw": "0",
                            "fnt": "normal normal normal 20px/1.4em belinda-w00-regular",
                            "fnt2": "normal normal normal 17px/1.4em avenir-lt-w01_35-light1475496",
                            "shd": "0 0px 0px transparent",
                            "txt1": "#112A4B",
                            "txt2": "#FFFFFF",
                            "txt3": "#112A4B",
                            "txtError": "color_34",
                            "txtSuccess": "color_23"
                        },
                        "propertiesSource": {
                            "alpha-bg1": "value",
                            "alpha-bg2": "value",
                            "alpha-brd": "value",
                            "bg1": "value",
                            "bg2": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "fnt": "value",
                            "fnt2": "value",
                            "shd": "value",
                            "txt1": "value",
                            "txt2": "value",
                            "txt3": "value",
                            "txtError": "value",
                            "txtSuccess": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.ContactForm",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "contactform.FullWidthButtonSkin"
                },
                "activeModes": {},
                "id": "comp-ix7ca89k"
            },
            "preset": {"rect": {"width": 324, "height": 194.5, "x": 0, "y": 185.5}, "tags": null}
        }, {
            "id": "Contact_Contact_Forms_3",
            "structure": {
                "type": "Component",
                "skin": "contactform.LineOnlySkin",
                "layout": {
                    "width": 608,
                    "height": 295,
                    "x": 20,
                    "y": 788,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.ContactForm",
                "data": {
                    "type": "ContactForm",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "toEmailAddress": "",
                    "bccEmailAddress": "",
                    "nameFieldLabel": "Full Name",
                    "emailFieldLabel": "Email Address",
                    "phoneFieldLabel": "Phone",
                    "addressFieldLabel": "Address",
                    "subjectFieldLabel": "Phone Number",
                    "messageFieldLabel": "Write Your Request",
                    "submitButtonLabel": "Submit Now",
                    "successMessage": "Success! Message received.",
                    "validationErrorMessage": "Please add required info.",
                    "errorMessage": "Please add a valid email.",
                    "textDirection": "left",
                    "onSubmitBehavior": "message"
                },
                "props": {
                    "type": "ContactFormProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "hidden_emailFieldLabel": true,
                    "hidden_nameFieldLabel": true,
                    "hidden_phoneFieldLabel": false,
                    "hidden_addressFieldLabel": false,
                    "hidden_subjectFieldLabel": true,
                    "hidden_messageFieldLabel": true,
                    "required_emailFieldLabel": true,
                    "required_nameFieldLabel": true,
                    "required_phoneFieldLabel": false,
                    "required_addressFieldLabel": false,
                    "required_subjectFieldLabel": false,
                    "required_messageFieldLabel": false,
                    "useCookie": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-brd": "1",
                            "alpha-brd2": "1",
                            "brd": "#000000",
                            "brd2": "#000000",
                            "fnt": "normal normal normal 18px/1.4em baskervillemtw01-smbdit",
                            "fnt2": "normal normal normal 18px/1.4em baskervillemtw01-smbdit",
                            "txt1": "#000000",
                            "txt3": "#000000",
                            "txt4": "#000000",
                            "txtError": "#E21C21",
                            "txtSuccess": "#68B04D"
                        },
                        "propertiesSource": {
                            "alpha-brd": "value",
                            "alpha-brd2": "value",
                            "brd": "value",
                            "brd2": "value",
                            "fnt": "value",
                            "fnt2": "value",
                            "txt1": "value",
                            "txt3": "value",
                            "txt4": "value",
                            "txtError": "value",
                            "txtSuccess": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.ContactForm",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "contactform.LineOnlySkin"
                },
                "activeModes": {},
                "id": "comp-ix7is093"
            },
            "preset": {"rect": {"width": 324, "height": 161.5, "x": 0, "y": 380}, "tags": null}
        }, {
            "id": "Contact_Contact_Forms_4",
            "structure": {
                "type": "Component",
                "skin": "contactform.FieldAnimationSkin",
                "layout": {
                    "width": 608,
                    "height": 323,
                    "x": 20,
                    "y": 1123,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.ContactForm",
                "data": {
                    "type": "ContactForm",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "toEmailAddress": "",
                    "bccEmailAddress": "",
                    "nameFieldLabel": "Your name",
                    "emailFieldLabel": "Your email",
                    "phoneFieldLabel": "Phone",
                    "addressFieldLabel": "Address",
                    "subjectFieldLabel": "Can you come?",
                    "messageFieldLabel": "Special requests?",
                    "submitButtonLabel": "RSVP",
                    "successMessage": "Success! Message received.",
                    "validationErrorMessage": "Please add required info.",
                    "errorMessage": "Please add a valid email.",
                    "textDirection": "left",
                    "onSubmitBehavior": "message"
                },
                "props": {
                    "type": "ContactFormProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "hidden_emailFieldLabel": true,
                    "hidden_nameFieldLabel": true,
                    "hidden_phoneFieldLabel": false,
                    "hidden_addressFieldLabel": false,
                    "hidden_subjectFieldLabel": true,
                    "hidden_messageFieldLabel": true,
                    "required_emailFieldLabel": true,
                    "required_nameFieldLabel": true,
                    "required_phoneFieldLabel": false,
                    "required_addressFieldLabel": false,
                    "required_subjectFieldLabel": false,
                    "required_messageFieldLabel": false,
                    "useCookie": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg1": "1",
                            "alpha-bg2": "1",
                            "alpha-bg3": "1",
                            "bg1": "#354A81",
                            "bg2": "#354A81",
                            "bg3": "#F1BCAA",
                            "fnt": "italic normal normal 15px/1.4em libre+baskerville",
                            "fnt2": "normal normal normal 15px/1.4em libre+baskerville",
                            "txt1": "#FFFFFF",
                            "txt2": "#FFFFFF",
                            "txt4": "#354A81",
                            "txtError": "color_34",
                            "txtSuccess": "color_23"
                        },
                        "propertiesSource": {
                            "alpha-bg1": "value",
                            "alpha-bg2": "value",
                            "alpha-bg3": "value",
                            "bg1": "value",
                            "bg2": "value",
                            "bg3": "value",
                            "fnt": "value",
                            "fnt2": "value",
                            "txt1": "value",
                            "txt2": "value",
                            "txt4": "value",
                            "txtError": "value",
                            "txtSuccess": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.ContactForm",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "contactform.FieldAnimationSkin"
                },
                "activeModes": {},
                "id": "comp-ix7dfrd2"
            },
            "preset": {"rect": {"width": 324, "height": 195, "x": 0, "y": 541.5}, "tags": null}
        }, {
            "id": "Contact_Contact_Forms_5",
            "structure": {
                "type": "Component",
                "skin": "contactform.FieldAnimationSkin",
                "layout": {
                    "width": 276,
                    "height": 371,
                    "x": 20,
                    "y": 1513,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.ContactForm",
                "data": {
                    "type": "ContactForm",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "toEmailAddress": "",
                    "bccEmailAddress": "",
                    "nameFieldLabel": "Name",
                    "emailFieldLabel": "Add your name...",
                    "phoneFieldLabel": "Phone",
                    "addressFieldLabel": "Did you enjoy?...",
                    "subjectFieldLabel": "Add your email...",
                    "messageFieldLabel": "We’d love to know more...",
                    "submitButtonLabel": "Reply",
                    "successMessage": "Success! Message received.",
                    "validationErrorMessage": "Please add required info.",
                    "errorMessage": "Please add a valid email.",
                    "textDirection": "left",
                    "onSubmitBehavior": "message"
                },
                "props": {
                    "type": "ContactFormProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "hidden_emailFieldLabel": true,
                    "hidden_nameFieldLabel": false,
                    "hidden_phoneFieldLabel": false,
                    "hidden_addressFieldLabel": true,
                    "hidden_subjectFieldLabel": true,
                    "hidden_messageFieldLabel": true,
                    "required_emailFieldLabel": true,
                    "required_nameFieldLabel": true,
                    "required_phoneFieldLabel": false,
                    "required_addressFieldLabel": false,
                    "required_subjectFieldLabel": false,
                    "required_messageFieldLabel": false,
                    "useCookie": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg1": "1",
                            "alpha-bg2": "1",
                            "alpha-bg3": "1",
                            "bg1": "#FFFFFF",
                            "bg2": "#000000",
                            "bg3": "#4BD1A0",
                            "fnt": "normal normal normal 15px/1.4em times+new+roman",
                            "fnt2": "italic normal normal 15px/1.4em times+new+roman",
                            "txt1": "#000000",
                            "txt2": "#FFFFFF",
                            "txt4": "#000000",
                            "txtError": "color_34",
                            "txtSuccess": "color_23"
                        },
                        "propertiesSource": {
                            "alpha-bg1": "value",
                            "alpha-bg2": "value",
                            "alpha-bg3": "value",
                            "bg1": "value",
                            "bg2": "value",
                            "bg3": "value",
                            "fnt": "value",
                            "fnt2": "value",
                            "txt1": "value",
                            "txt2": "value",
                            "txt4": "value",
                            "txtError": "value",
                            "txtSuccess": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.ContactForm",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "contactform.FieldAnimationSkin"
                },
                "activeModes": {},
                "id": "comp-ixogp4o5"
            },
            "preset": {"rect": {"width": 162, "height": 212.5, "x": 0, "y": 736.5}, "tags": null}
        }, {
            "id": "Contact_Contact_Forms_6",
            "structure": {
                "type": "Component",
                "skin": "contactform.OverlappingButtonSkin",
                "layout": {
                    "width": 272,
                    "height": 370,
                    "x": 356,
                    "y": 1513,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.ContactForm",
                "data": {
                    "type": "ContactForm",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "toEmailAddress": "",
                    "bccEmailAddress": "",
                    "nameFieldLabel": "Name",
                    "emailFieldLabel": "Email",
                    "phoneFieldLabel": "City",
                    "addressFieldLabel": "Address",
                    "subjectFieldLabel": "Street Address",
                    "messageFieldLabel": "Add a message",
                    "submitButtonLabel": "Apply",
                    "successMessage": "Success! Message received.",
                    "validationErrorMessage": "Please add required info.",
                    "errorMessage": "Please add a valid email.",
                    "textDirection": "left",
                    "onSubmitBehavior": "message"
                },
                "props": {
                    "type": "ContactFormProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "hidden_emailFieldLabel": true,
                    "hidden_nameFieldLabel": true,
                    "hidden_phoneFieldLabel": true,
                    "hidden_addressFieldLabel": false,
                    "hidden_subjectFieldLabel": true,
                    "hidden_messageFieldLabel": true,
                    "required_emailFieldLabel": true,
                    "required_nameFieldLabel": true,
                    "required_phoneFieldLabel": false,
                    "required_addressFieldLabel": false,
                    "required_subjectFieldLabel": false,
                    "required_messageFieldLabel": false,
                    "useCookie": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg1": "1",
                            "alpha-bg2": "1",
                            "alpha-brd": "1",
                            "bg1": "#FFFFFF",
                            "bg2": "#FFFFFF",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#FF6168",
                            "brw": "2",
                            "brw2": "2",
                            "fnt": "normal normal normal 14px/1.4em futura-lt-w01-book",
                            "fnt2": "italic normal normal 18px/1.4em futura-lt-w01-book",
                            "labelTxt": "#0C4266",
                            "shd": "0 0px 0px transparent",
                            "txt1": "#0C4266",
                            "txt2": "#0C4266",
                            "txtError": "color_34",
                            "txtSuccess": "color_23"
                        },
                        "propertiesSource": {
                            "alpha-bg1": "value",
                            "alpha-bg2": "value",
                            "alpha-brd": "value",
                            "bg1": "value",
                            "bg2": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "brw2": "value",
                            "fnt": "value",
                            "fnt2": "value",
                            "labelTxt": "value",
                            "shd": "value",
                            "txt1": "value",
                            "txt2": "value",
                            "txtError": "value",
                            "txtSuccess": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.ContactForm",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "contactform.OverlappingButtonSkin"
                },
                "activeModes": {},
                "id": "comp-ixoidxel"
            },
            "preset": {"rect": {"width": 162, "height": 212.5, "x": 162, "y": 736.5}, "tags": null}
        }, {
            "id": "Contact_Contact_Forms_7",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.contactform.VerticalForm",
                "layout": {
                    "width": 608,
                    "height": 387,
                    "x": 20,
                    "y": 1941,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.ContactForm",
                "data": {
                    "type": "ContactForm",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "toEmailAddress": "",
                    "bccEmailAddress": "",
                    "nameFieldLabel": "What’s your name?",
                    "emailFieldLabel": "What’s your email?",
                    "phoneFieldLabel": "Phone",
                    "addressFieldLabel": "Address",
                    "subjectFieldLabel": "Subject",
                    "messageFieldLabel": "What’s on your mind? Let us know",
                    "submitButtonLabel": "Send",
                    "successMessage": "Success! Message received.",
                    "validationErrorMessage": "Please add required info.",
                    "errorMessage": "Please add a valid email.",
                    "textDirection": "left",
                    "onSubmitBehavior": "message"
                },
                "props": {
                    "type": "ContactFormProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "hidden_emailFieldLabel": true,
                    "hidden_nameFieldLabel": true,
                    "hidden_phoneFieldLabel": false,
                    "hidden_addressFieldLabel": false,
                    "hidden_subjectFieldLabel": false,
                    "hidden_messageFieldLabel": true,
                    "required_emailFieldLabel": true,
                    "required_nameFieldLabel": true,
                    "required_phoneFieldLabel": false,
                    "required_addressFieldLabel": false,
                    "required_subjectFieldLabel": false,
                    "required_messageFieldLabel": false,
                    "useCookie": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg1": "1",
                            "alpha-bg2": "1",
                            "alpha-brd": "1",
                            "bg1": "#FF856D",
                            "bg2": "#FF856D",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#2A2A2A",
                            "brw": "0",
                            "fnt": "normal normal normal 20px/1.4em nimbus-sans-tw01con",
                            "fnt2": "normal normal normal 20px/1.4em nimbus-sans-tw01con",
                            "rd": "0px 0px 10px 0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt1": "#FFFFFF",
                            "txt2": "#FFFFFF",
                            "txt3": "#FF856D",
                            "txtError": "color_34",
                            "txtSuccess": "color_23"
                        },
                        "propertiesSource": {
                            "alpha-bg1": "value",
                            "alpha-bg2": "value",
                            "alpha-brd": "value",
                            "bg1": "value",
                            "bg2": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "fnt": "value",
                            "fnt2": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt1": "value",
                            "txt2": "value",
                            "txt3": "value",
                            "txtError": "value",
                            "txtSuccess": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.ContactForm",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.contactform.VerticalForm"
                },
                "activeModes": {},
                "id": "comp-ix7e95k2"
            },
            "preset": {"rect": {"width": 324, "height": 233.5, "x": 0, "y": 949}, "tags": null}
        }, {
            "id": "Contact_Contact_Forms_8",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft",
                "layout": {
                    "width": 617,
                    "height": 297,
                    "x": 11,
                    "y": 2404,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.ContactForm",
                "data": {
                    "type": "ContactForm",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "toEmailAddress": "",
                    "bccEmailAddress": "",
                    "nameFieldLabel": "name",
                    "emailFieldLabel": "email",
                    "phoneFieldLabel": "Phone",
                    "addressFieldLabel": "Address",
                    "subjectFieldLabel": "phone",
                    "messageFieldLabel": "message",
                    "submitButtonLabel": "get in touch",
                    "successMessage": "Success! Message received.",
                    "validationErrorMessage": "Please add required info.",
                    "errorMessage": "Please add a valid email.",
                    "textDirection": "left",
                    "onSubmitBehavior": "message"
                },
                "props": {
                    "type": "ContactFormProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "hidden_emailFieldLabel": true,
                    "hidden_nameFieldLabel": true,
                    "hidden_phoneFieldLabel": false,
                    "hidden_addressFieldLabel": false,
                    "hidden_subjectFieldLabel": true,
                    "hidden_messageFieldLabel": true,
                    "required_emailFieldLabel": true,
                    "required_nameFieldLabel": true,
                    "required_phoneFieldLabel": false,
                    "required_addressFieldLabel": false,
                    "required_subjectFieldLabel": false,
                    "required_messageFieldLabel": false,
                    "useCookie": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg1": "1",
                            "alpha-bg2": "1",
                            "alpha-brd": "1",
                            "bg1": "#E6E6E6",
                            "bg2": "#112A4B",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#2A2A2A",
                            "brw": "0",
                            "fnt": "normal normal normal 15px/1.4em noticia+text",
                            "fnt2": "normal normal normal 15px/1.4em noticia+text",
                            "rd": "5px 5px 5px 5px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt1": "#112A4B",
                            "txt2": "#FFFFFF",
                            "txt3": "#112A4B",
                            "txtError": "color_34",
                            "txtSuccess": "color_23"
                        },
                        "propertiesSource": {
                            "alpha-bg1": "value",
                            "alpha-bg2": "value",
                            "alpha-brd": "value",
                            "bg1": "value",
                            "bg2": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "fnt": "value",
                            "fnt2": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt1": "value",
                            "txt2": "value",
                            "txt3": "value",
                            "txtError": "value",
                            "txtSuccess": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.ContactForm",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft"
                },
                "activeModes": {},
                "id": "comp-ix7gx8wp"
            },
            "preset": {"rect": {"width": 324, "height": 181.5, "x": 0, "y": 1182.5}, "tags": null}
        }, {
            "id": "Contact_Contact_Forms_9",
            "structure": {
                "type": "Component",
                "skin": "contactform.LineOnlySkin",
                "layout": {
                    "width": 274,
                    "height": 321,
                    "x": 20,
                    "y": 2765,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.ContactForm",
                "data": {
                    "type": "ContactForm",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "toEmailAddress": "",
                    "bccEmailAddress": "",
                    "nameFieldLabel": "Your Name",
                    "emailFieldLabel": "Your Address",
                    "phoneFieldLabel": "Which Pizza?",
                    "addressFieldLabel": "Address",
                    "subjectFieldLabel": "Your Phone",
                    "messageFieldLabel": "Any Special Requests?",
                    "submitButtonLabel": "Order Now",
                    "successMessage": "Success! Message received.",
                    "validationErrorMessage": "Please add required info.",
                    "errorMessage": "Please add a valid email.",
                    "textDirection": "left",
                    "onSubmitBehavior": "message"
                },
                "props": {
                    "type": "ContactFormProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "hidden_emailFieldLabel": true,
                    "hidden_nameFieldLabel": true,
                    "hidden_phoneFieldLabel": true,
                    "hidden_addressFieldLabel": false,
                    "hidden_subjectFieldLabel": true,
                    "hidden_messageFieldLabel": true,
                    "required_emailFieldLabel": true,
                    "required_nameFieldLabel": true,
                    "required_phoneFieldLabel": false,
                    "required_addressFieldLabel": false,
                    "required_subjectFieldLabel": false,
                    "required_messageFieldLabel": false,
                    "useCookie": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-brd": "1",
                            "alpha-brd2": "1",
                            "brd": "#000000",
                            "brd2": "#000000",
                            "fnt": "normal normal normal 14px/1.4em helvetica-w01-light",
                            "fnt2": "italic normal normal 15px/1.4em helvetica-w01-light",
                            "txt1": "#000000",
                            "txt3": "#000000",
                            "txt4": "#000000",
                            "txtError": "#E21C21",
                            "txtSuccess": "#68B04D"
                        },
                        "propertiesSource": {
                            "alpha-brd": "value",
                            "alpha-brd2": "value",
                            "brd": "value",
                            "brd2": "value",
                            "fnt": "value",
                            "fnt2": "value",
                            "txt1": "value",
                            "txt3": "value",
                            "txt4": "value",
                            "txtError": "value",
                            "txtSuccess": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.ContactForm",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "contactform.LineOnlySkin"
                },
                "activeModes": {},
                "id": "comp-ixolj82m"
            },
            "preset": {"rect": {"width": 162, "height": 197.5, "x": 0, "y": 1364}, "tags": null}
        }, {
            "id": "Contact_Contact_Forms_10",
            "structure": {
                "type": "Component",
                "skin": "contactform.FieldAnimationSkin",
                "layout": {
                    "width": 274,
                    "height": 323,
                    "x": 353,
                    "y": 2773,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.ContactForm",
                "data": {
                    "type": "ContactForm",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "toEmailAddress": "",
                    "bccEmailAddress": "",
                    "nameFieldLabel": "Name",
                    "emailFieldLabel": "Age",
                    "phoneFieldLabel": "Phone",
                    "addressFieldLabel": "Address",
                    "subjectFieldLabel": "Email",
                    "messageFieldLabel": "Write your answer here",
                    "submitButtonLabel": "Send",
                    "successMessage": "Success! Message received.",
                    "validationErrorMessage": "Please add required info.",
                    "errorMessage": "Please add a valid email.",
                    "textDirection": "left",
                    "onSubmitBehavior": "message"
                },
                "props": {
                    "type": "ContactFormProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "hidden_emailFieldLabel": true,
                    "hidden_nameFieldLabel": true,
                    "hidden_phoneFieldLabel": false,
                    "hidden_addressFieldLabel": false,
                    "hidden_subjectFieldLabel": true,
                    "hidden_messageFieldLabel": true,
                    "required_emailFieldLabel": true,
                    "required_nameFieldLabel": true,
                    "required_phoneFieldLabel": false,
                    "required_addressFieldLabel": false,
                    "required_subjectFieldLabel": false,
                    "required_messageFieldLabel": false,
                    "useCookie": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg1": "1",
                            "alpha-bg2": "1",
                            "alpha-bg3": "1",
                            "bg1": "#354A81",
                            "bg2": "#B0E8E3",
                            "bg3": "#B0E8E3",
                            "fnt": "normal normal normal 14px/1.4em proxima-n-w01-reg",
                            "fnt2": "italic normal normal 15px/1.4em proxima-n-w01-reg",
                            "txt1": "#B0E8E3",
                            "txt2": "#354A81",
                            "txt4": "#354A81",
                            "txtError": "color_34",
                            "txtSuccess": "color_23"
                        },
                        "propertiesSource": {
                            "alpha-bg1": "value",
                            "alpha-bg2": "value",
                            "alpha-bg3": "value",
                            "bg1": "value",
                            "bg2": "value",
                            "bg3": "value",
                            "fnt": "value",
                            "fnt2": "value",
                            "txt1": "value",
                            "txt2": "value",
                            "txt4": "value",
                            "txtError": "value",
                            "txtSuccess": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.ContactForm",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "contactform.FieldAnimationSkin"
                },
                "activeModes": {},
                "id": "comp-ixolm84k"
            },
            "preset": {"rect": {"width": 162, "height": 197.5, "x": 162, "y": 1364}, "tags": null}
        }, {
            "id": "Contact_Contact_Forms_11",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.contactform.VerticalForm",
                "layout": {
                    "width": 608,
                    "height": 333,
                    "x": 20,
                    "y": 3155,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.ContactForm",
                "data": {
                    "type": "ContactForm",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "toEmailAddress": "",
                    "bccEmailAddress": "",
                    "nameFieldLabel": "YOUR NAME:",
                    "emailFieldLabel": "YOUR EMAIL:",
                    "phoneFieldLabel": "Phone",
                    "addressFieldLabel": "Address",
                    "subjectFieldLabel": "Subject",
                    "messageFieldLabel": "TELL US HOW WE’RE DOING:",
                    "submitButtonLabel": "Send It",
                    "successMessage": "Success! Message received.",
                    "validationErrorMessage": "Please add required info.",
                    "errorMessage": "Please add a valid email.",
                    "textDirection": "left",
                    "onSubmitBehavior": "message"
                },
                "props": {
                    "type": "ContactFormProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "hidden_emailFieldLabel": true,
                    "hidden_nameFieldLabel": true,
                    "hidden_phoneFieldLabel": false,
                    "hidden_addressFieldLabel": false,
                    "hidden_subjectFieldLabel": false,
                    "hidden_messageFieldLabel": true,
                    "required_emailFieldLabel": true,
                    "required_nameFieldLabel": true,
                    "required_phoneFieldLabel": false,
                    "required_addressFieldLabel": false,
                    "required_subjectFieldLabel": false,
                    "required_messageFieldLabel": false,
                    "useCookie": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg1": "1",
                            "alpha-bg2": "1",
                            "alpha-brd": "1",
                            "bg1": "#FF6168",
                            "bg2": "#000000",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#FF6168",
                            "brw": "0",
                            "fnt": "normal normal 700 14px/1.4em raleway",
                            "fnt2": "normal normal normal 14px/1.4em raleway",
                            "rd": "0px 0px 0px 0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt1": "#000000",
                            "txt2": "#FFFFFF",
                            "txt3": "#000000",
                            "txtError": "color_34",
                            "txtSuccess": "color_23"
                        },
                        "propertiesSource": {
                            "alpha-bg1": "value",
                            "alpha-bg2": "value",
                            "alpha-brd": "value",
                            "bg1": "value",
                            "bg2": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "fnt": "value",
                            "fnt2": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt1": "value",
                            "txt2": "value",
                            "txt3": "value",
                            "txtError": "value",
                            "txtSuccess": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.ContactForm",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.contactform.VerticalForm"
                },
                "activeModes": {},
                "id": "comp-ix7nbi8e"
            },
            "preset": {"rect": {"width": 324, "height": 202.5, "x": 0, "y": 1561.5}, "tags": null}
        }, {
            "id": "Contact_Contact_Forms_12",
            "structure": {
                "type": "Component",
                "skin": "contactform.OverlappingButtonSkin",
                "layout": {
                    "width": 608,
                    "height": 329,
                    "x": 20,
                    "y": 3568,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.ContactForm",
                "data": {
                    "type": "ContactForm",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "toEmailAddress": "",
                    "bccEmailAddress": "",
                    "nameFieldLabel": "Name",
                    "emailFieldLabel": "Email",
                    "phoneFieldLabel": "Phone",
                    "addressFieldLabel": "Address",
                    "subjectFieldLabel": "Businesst",
                    "messageFieldLabel": "Tell us more about your project",
                    "submitButtonLabel": "Get a Quote",
                    "successMessage": "Your details were sent successfully!",
                    "validationErrorMessage": "Please fill in all required fields.",
                    "errorMessage": "Please provide a valid email",
                    "textDirection": "left",
                    "onSubmitBehavior": "message"
                },
                "props": {
                    "type": "ContactFormProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "hidden_emailFieldLabel": true,
                    "hidden_nameFieldLabel": true,
                    "hidden_phoneFieldLabel": false,
                    "hidden_addressFieldLabel": false,
                    "hidden_subjectFieldLabel": true,
                    "hidden_messageFieldLabel": true,
                    "required_emailFieldLabel": true,
                    "required_nameFieldLabel": true,
                    "required_phoneFieldLabel": false,
                    "required_addressFieldLabel": false,
                    "required_subjectFieldLabel": false,
                    "required_messageFieldLabel": false,
                    "useCookie": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg1": "0",
                            "alpha-bg2": "1",
                            "alpha-brd": "1",
                            "bg1": "#AE9A64",
                            "bg2": "#AE9A64",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#AE9A64",
                            "brw": "2",
                            "brw2": "0",
                            "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_85-heavy1475544",
                            "fnt2": "normal normal normal 25px/1.4em cookie",
                            "labelTxt": "#AE9A64",
                            "shd": "0 0px 0px transparent",
                            "txt1": "#AE9A64",
                            "txt2": "#FFFFFF",
                            "txtError": "color_34",
                            "txtSuccess": "color_23"
                        },
                        "propertiesSource": {
                            "alpha-bg1": "value",
                            "alpha-bg2": "value",
                            "alpha-brd": "value",
                            "bg1": "value",
                            "bg2": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "brw2": "value",
                            "fnt": "value",
                            "fnt2": "value",
                            "labelTxt": "value",
                            "shd": "value",
                            "txt1": "value",
                            "txt2": "value",
                            "txtError": "value",
                            "txtSuccess": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.ContactForm",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "contactform.OverlappingButtonSkin"
                },
                "activeModes": {},
                "id": "comp-ix7nbi86"
            },
            "preset": {"rect": {"width": 324, "height": 192, "x": 0, "y": 1764}, "tags": null}
        }, {
            "id": "Contact_Contact_Forms_13",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.contactform.DefaultContactForm",
                "layout": {
                    "width": 283,
                    "height": 182,
                    "x": 21,
                    "y": 3951,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.ContactForm",
                "data": {
                    "type": "ContactForm",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "toEmailAddress": "",
                    "bccEmailAddress": "",
                    "nameFieldLabel": "Your Name",
                    "emailFieldLabel": "Company",
                    "phoneFieldLabel": "Phone",
                    "addressFieldLabel": "Email",
                    "subjectFieldLabel": "Phone",
                    "messageFieldLabel": "Place your order",
                    "submitButtonLabel": "Order Now",
                    "successMessage": "Success! Message received.",
                    "validationErrorMessage": "Please add required info.",
                    "errorMessage": "Please add a valid email.",
                    "textDirection": "left",
                    "onSubmitBehavior": "message"
                },
                "props": {
                    "type": "ContactFormProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "hidden_emailFieldLabel": true,
                    "hidden_nameFieldLabel": true,
                    "hidden_phoneFieldLabel": false,
                    "hidden_addressFieldLabel": true,
                    "hidden_subjectFieldLabel": true,
                    "hidden_messageFieldLabel": true,
                    "required_emailFieldLabel": true,
                    "required_nameFieldLabel": true,
                    "required_phoneFieldLabel": false,
                    "required_addressFieldLabel": false,
                    "required_subjectFieldLabel": false,
                    "required_messageFieldLabel": false,
                    "useCookie": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg1": "1",
                            "alpha-bg2": "0",
                            "alpha-brd": "1",
                            "alpha-labelTxt": "1",
                            "alpha-txt1": "1",
                            "alpha-txt2": "1",
                            "alpha-txtError": "1",
                            "alpha-txtSuccess": "1",
                            "bg1": "#6A76AA",
                            "bg2": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#6A76AA",
                            "brw": "0",
                            "fnt": "normal normal normal 13px/1.4em futura-lt-w01-book",
                            "fnt2": "normal normal normal 16px/1.4em futura-lt-w01-book",
                            "labelTxt": "#FFFFFF",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt1": "#FFFFFF",
                            "txt2": "#6A76AA",
                            "txtError": "#E21C21",
                            "txtSuccess": "#68B04D"
                        },
                        "propertiesSource": {
                            "alpha-bg1": "value",
                            "alpha-bg2": "value",
                            "alpha-brd": "value",
                            "bg1": "value",
                            "bg2": "value",
                            "brd": "value",
                            "brw": "value",
                            "fnt": "value",
                            "fnt2": "value",
                            "labelTxt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt1": "value",
                            "txt2": "value",
                            "txtError": "value",
                            "txtSuccess": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.ContactForm",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.contactform.DefaultContactForm"
                },
                "activeModes": {},
                "id": "comp-ix7nbi8h"
            },
            "preset": {"rect": {"width": 162, "height": 175, "x": 0, "y": 1956}, "tags": null}
        }, {
            "id": "Contact_Contact_Forms_14",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.contactform.BasicContactFormSkin",
                "layout": {
                    "width": 283,
                    "height": 270,
                    "x": 344,
                    "y": 3951,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.ContactForm",
                "data": {
                    "type": "ContactForm",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "toEmailAddress": "",
                    "bccEmailAddress": "",
                    "nameFieldLabel": "Contact Email...",
                    "emailFieldLabel": "1st Guest...",
                    "phoneFieldLabel": "Phone",
                    "addressFieldLabel": "Address",
                    "subjectFieldLabel": "2nd Guest..",
                    "messageFieldLabel": "Add a Note...",
                    "submitButtonLabel": "Reserve",
                    "successMessage": "Success! Message received.",
                    "validationErrorMessage": "Please add required info.",
                    "errorMessage": "Please add a valid email.",
                    "textDirection": "left",
                    "onSubmitBehavior": "message"
                },
                "props": {
                    "type": "ContactFormProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "hidden_emailFieldLabel": true,
                    "hidden_nameFieldLabel": true,
                    "hidden_phoneFieldLabel": false,
                    "hidden_addressFieldLabel": false,
                    "hidden_subjectFieldLabel": true,
                    "hidden_messageFieldLabel": true,
                    "required_emailFieldLabel": true,
                    "required_nameFieldLabel": true,
                    "required_phoneFieldLabel": false,
                    "required_addressFieldLabel": false,
                    "required_subjectFieldLabel": false,
                    "required_messageFieldLabel": false,
                    "useCookie": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg1": "1",
                            "alpha-bg2": "1",
                            "alpha-brd": "1",
                            "alpha-labelTxt": "1",
                            "alpha-txt1": "1",
                            "alpha-txt2": "1",
                            "alpha-txtError": "1",
                            "alpha-txtSuccess": "1",
                            "bg1": "#F4C0AF",
                            "bg2": "#F4C0AF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#F4C0AF",
                            "brw": "0",
                            "fnt": "normal normal normal 14px/1.4em tahoma",
                            "fnt2": "normal normal normal 15px/1.4em tahoma",
                            "labelTxt": "#FFFFFF",
                            "rd": "0px 0px 0px 0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt1": "#FFFFFF",
                            "txt2": "#FFFFFF",
                            "txtError": "#E21C21",
                            "txtSuccess": "#68B04D"
                        },
                        "propertiesSource": {
                            "alpha-bg1": "value",
                            "alpha-bg2": "value",
                            "bg1": "value",
                            "bg2": "value",
                            "brd": "value",
                            "brw": "value",
                            "fnt": "value",
                            "fnt2": "value",
                            "labelTxt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt1": "value",
                            "txt2": "value",
                            "txtError": "value",
                            "txtSuccess": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.ContactForm",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.contactform.BasicContactFormSkin"
                },
                "activeModes": {},
                "id": "comp-ix7nbi8j"
            },
            "preset": {"rect": {"width": 162, "height": 175, "x": 162, "y": 1956}, "tags": null}
        }],
        "compTypes": ["wysiwyg.viewer.components.ContactForm"]
    },
    "teaser": {
        "id": "contact_forms_section_teaser",
        "hide": false,
        "title": "add_section_teaser_title_contactform",
        "text": "add_section_teaser_text_contactform",
        "linkText": "contact_forms_section_teaser_link",
        "helpId": "3bf1c6fd-b3e5-47f3-9ab4-22beb2a1ce4c"
    },
    "help": {"hide": false, "text": "add_section_info_text_contactform"}
}
