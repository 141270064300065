import _ from 'lodash';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import { fedopsLogger, isDebugMode } from '@/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import type { BiData } from './switchLayoutStore';
import { getText } from './switchLayoutUtil';
import {
  getImageItems,
  getStageContentItems,
  isContainerValidAsComponentForPaas,
} from './switchLayoutPAASUtil';
import constants from '@/constants';
import type {
  ListClassificationNode,
  ListGroups,
  ListClassificationResponseGroup,
} from '@/listClassification';
import {
  SupportedCompGeneralTypes,
  SupportedTextClassificationTypes,
  type ContentItem,
  type OriginalCompMappingData,
  type ListContentItem,
  CONTAINERS_TO_IGNORE_FOR_PAAS,
  type ListBiInfo,
  LIST_PRECESS_INFO_OPTIONS,
  SUPPORTED_BUTTONS_FOR_SWITCH_LAYOUT,
  type ComponentIdAndType,
  type ComponentsGroupedByType,
} from './consts';
import type { TextClassificationResponseData } from '@/textClassification';
import * as coreBi from '@/coreBi';

const { COMP_TYPES } = constants;

export const getListItems = async (
  editorAPI: EditorAPI,
  originalCompMapping: OriginalCompMappingData,
): Promise<ContentItem[]> => {
  const items: ContentItem[] = await Promise.all(
    originalCompMapping.listData.resultListMap.children?.map(
      async (group: ListClassificationNode) => {
        if (!isListGroupHasOnlyContainers(group)) {
          let buttonRef: CompRef;
          let mediaItems: ContentItem['media'];
          let titleRef: CompRef;
          let subtitleRef: CompRef;
          let longTextRef: CompRef;

          // TODO: handle case where group is component (maybe use filter)
          if (!group.children) {
            return {
              button: null,
              title: null,
              subTitle: null,
              longText: null,
              media: null,
            };
          }
          for (const child of group.children) {
            const componentType = child.component?.componentType;

            if (SUPPORTED_BUTTONS_FOR_SWITCH_LAYOUT.has(componentType)) {
              buttonRef = editorAPI.components.get.byId(child.id);
            }

            if (componentType === COMP_TYPES.TEXT) {
              const classificationValue =
                originalCompMapping.textClassificationData
                  .textClassificationPredictions?.[child.id]?.classification;
              if (classificationValue) {
                fedopsLogger.interactionEnded(
                  fedopsLogger.SWITCH_LAYOUT.SWITCH_LAYOUT_TEXT_CLASSIFFICATION,
                );
                if (
                  classificationValue ===
                    SupportedTextClassificationTypes.TITLE &&
                  !titleRef
                ) {
                  titleRef = editorAPI.components.get.byId(child.id);
                } else if (
                  classificationValue ===
                    SupportedTextClassificationTypes.SUBTITLE &&
                  !subtitleRef
                ) {
                  subtitleRef = editorAPI.components.get.byId(child.id);
                } else if (
                  (classificationValue ===
                    SupportedTextClassificationTypes.PARAGRAPH ||
                    classificationValue ===
                      SupportedTextClassificationTypes.SMALL_TEXT) &&
                  !longTextRef
                ) {
                  longTextRef = editorAPI.components.get.byId(child.id);
                } else if (!titleRef) {
                  titleRef = editorAPI.components.get.byId(child.id);
                } else if (!subtitleRef) {
                  subtitleRef = editorAPI.components.get.byId(child.id);
                } else {
                  longTextRef = editorAPI.components.get.byId(child.id);
                }
              } else if (!titleRef) {
                titleRef = editorAPI.components.get.byId(child.id);
              } else if (!subtitleRef) {
                subtitleRef = editorAPI.components.get.byId(child.id);
              } else {
                longTextRef = editorAPI.components.get.byId(child.id);
              }
            }

            if (
              componentType === COMP_TYPES.PHOTO ||
              isContainerValidAsComponentForPaas(
                componentType,
                editorAPI.components.design.get(
                  editorAPI.components.get.byId(child.id),
                ),
              )
            ) {
              const imageRefs = originalCompMapping.data[
                SupportedCompGeneralTypes.MEDIA_IMAGES
              ].filter((image) => image.compRef.id === child.id);
              mediaItems = await getImageItems(imageRefs);
            }
          }

          return {
            button: buttonRef
              ? editorAPI.components.data.get(buttonRef).label
              : null,
            title: titleRef ? getText(editorAPI, titleRef) : null,
            subTitle: subtitleRef ? getText(editorAPI, subtitleRef) : null,
            longText: longTextRef ? getText(editorAPI, longTextRef) : null,
            media: mediaItems,
          };
        }
      },
    ),
  );
  return items.filter((item: ContentItem | undefined) => item);
};

export const getListAdditionalContent = async (
  editorAPI: EditorAPI,
  originalCompMapping: OriginalCompMappingData,
): Promise<{ header: ContentItem; footer: ContentItem }> => {
  const emptyContentItem: ContentItem = {
    button: null,
    title: null,
    subTitle: null,
    longText: null,
    media: [],
  };

  const header = await getStageContentItems(
    editorAPI,
    originalCompMapping.listData.nonListComponents,
  );
  const footer = emptyContentItem;

  return { header, footer };
};

export const getListsContentItems = async (
  editorAPI: EditorAPI,
  originalCompMapping: OriginalCompMappingData,
): Promise<ListContentItem> => {
  const items = await getListItems(editorAPI, originalCompMapping);
  const { header, footer } = await getListAdditionalContent(
    editorAPI,
    originalCompMapping,
  );

  return {
    items,
    header,
    footer,
  };
};

export const collectListsMapFromClassificationOutput = (
  listClassificationOutput: ListClassificationNode,
  currentLists: ListGroups,
): void => {
  if (listClassificationOutput?.groupType === 'List') {
    currentLists[listClassificationOutput.id] = listClassificationOutput;
  }
  listClassificationOutput?.children?.forEach(
    (listClassification: ListClassificationNode) => {
      collectListsMapFromClassificationOutput(listClassification, currentLists);
    },
  );
};

export const collectListComponentIds = (
  resultListMap: ListClassificationNode,
  ids: Set<string>,
  flattenListComponents: any,
): void => {
  if (resultListMap) {
    if (resultListMap.component) {
      flattenListComponents[resultListMap.id] = resultListMap;
    }
    ids.add(resultListMap.id);
    if (resultListMap.children?.length) {
      resultListMap.children.forEach((child: ListClassificationNode) => {
        collectListComponentIds(child, ids, flattenListComponents);
      });
    }
  }
};

const isListGroupHasOnlyContainers = (
  currentGroup: ListClassificationNode,
): boolean => {
  return currentGroup.children.every((child: ListClassificationNode) =>
    CONTAINERS_TO_IGNORE_FOR_PAAS.has(child?.component?.componentType),
  );
};

const getCompTypeWithTextClassifications = (
  compId: string,
  compType: string,
  textClassificationPredictions: TextClassificationResponseData,
): string => {
  if (
    compType === COMP_TYPES.TEXT &&
    textClassificationPredictions?.[compId]?.classification
  ) {
    compType = textClassificationPredictions?.[compId]?.classification;
  }
  return compType;
};

const getComponentsGroupedByType = (
  componentsIdsWithTypes: ComponentIdAndType[],
): ComponentsGroupedByType => {
  return componentsIdsWithTypes.reduce((acc: any, comp: any) => {
    if (comp.compType) {
      if (!acc[comp.compType]) {
        acc[comp.compType] = [];
      }
      acc[comp.compType] = [...acc[comp.compType], comp];
    }
    return acc;
  }, {});
};

const allComponentsInGroupHasSameParent = (
  editorAPI: EditorAPI,
  componentsGroupedByType: ComponentsGroupedByType,
): boolean => {
  let parentId: string = '';
  return Object.values(componentsGroupedByType).every((componentGroup: any) => {
    return componentGroup.every((component: any) => {
      if (parentId) {
        return (
          parentId ===
          editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
            editorAPI.components.get.byId(component.id),
          ).id
        );
      }
      parentId = editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
        editorAPI.components.get.byId(component.id),
      ).id;
      return true;
    });
  });
};

export const sortComponentsByCoordinates = (
  editorAPI: EditorAPI,
  group: ComponentIdAndType[],
): ComponentIdAndType[] => {
  const groupWithLayout = group.map(({ id, compType }) => ({
    id,
    compType,
    layout: editorAPI.components.layout.getRelativeToScreen(
      editorAPI.components.get.byId(id),
    ),
  }));
  groupWithLayout.sort((a, b) => {
    if (a.layout.y - b.layout.y === 0) {
      return a.layout.x - b.layout.x;
    }
    return a.layout.y - b.layout.y;
  });
  return groupWithLayout.map(({ id, compType }) => ({ id, compType }));
};

const getSortedComponents = (
  editorAPI: EditorAPI,
  componentsGroupedByType: ComponentsGroupedByType,
  originalCompMappingData: OriginalCompMappingData,
): ComponentIdAndType[][] => {
  // if all components has same fater (same container)
  if (
    Object.values(componentsGroupedByType).length === 1 ||
    allComponentsInGroupHasSameParent(editorAPI, componentsGroupedByType)
  ) {
    return Object.values(componentsGroupedByType).map(
      (group: ComponentIdAndType[]) =>
        sortComponentsByCoordinates(editorAPI, group),
    );
  }
  return Object.values(componentsGroupedByType).map(
    (group: ComponentIdAndType[]) => {
      const sortedComps: any[] = [];
      group.forEach((component: any, index: number) => {
        const compRef = editorAPI.components.get.byId(component.id);
        if (group.length === 1) {
          sortedComps[0] = component;
          return sortedComps;
        }
        const prevOrNextCompRef =
          index > 0 && group?.[index - 1]
            ? editorAPI.components.get.byId(group[index - 1].id)
            : editorAPI.components.get.byId(group[index + 1].id);
        let groupRef: any;
        let groupsParentRef: any;
        let prevOrNextGroupCompRef: any;
        if (component.compType === COMP_TYPES.COLUMN) {
          groupRef = compRef;
          prevOrNextGroupCompRef = prevOrNextCompRef;
        } else {
          groupRef =
            editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
              compRef,
            );
          prevOrNextGroupCompRef =
            editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
              prevOrNextCompRef,
            );
        }
        groupsParentRef =
          editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
            groupRef,
          );
        const prevOrNextGroupParentCompRef =
          editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
            prevOrNextGroupCompRef,
          );
        if (groupsParentRef.id !== prevOrNextGroupParentCompRef.id) {
          groupRef = groupsParentRef;
          groupsParentRef =
            editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
              groupRef,
            );
        }
        const componentUpdatedIndex = editorAPI.components
          .getChildren_DEPRECATED_BAD_PERFORMANCE(groupsParentRef)
          .filter((groupRef: any) =>
            originalCompMappingData.listData.listIds.has(groupRef.id),
          )
          .findIndex((containerRef: any) => _.isEqual(containerRef, groupRef));
        if (sortedComps[componentUpdatedIndex]) {
          sortedComps.push(component);
        } else {
          sortedComps[componentUpdatedIndex] = component;
        }
      });
      return sortedComps;
    },
  );
};

const areGroupsWithSameLength = (
  sortedComponentsGroupedByType: ComponentIdAndType[][],
) => {
  const [firstGroup, ...restOfGroups] = sortedComponentsGroupedByType;
  return restOfGroups.every(
    (group: ComponentIdAndType[]) => group.length === firstGroup.length,
  );
};

const getListComponentsWithTypesMapping = (
  editorAPI: EditorAPI,
  typeMappingData: OriginalCompMappingData,
): ComponentIdAndType[] => {
  return Array.from(typeMappingData.listData.listIds)
    .map((compId: any) => {
      const compType = editorAPI.components.getType(
        editorAPI.components.get.byId(compId),
      );

      if (CONTAINERS_TO_IGNORE_FOR_PAAS.has(compType)) {
        const compDesign = editorAPI.components.design.get(
          editorAPI.components.get.byId(compId),
        );
        if (!isContainerValidAsComponentForPaas(compType, compDesign)) {
          return null;
        }
      }
      return {
        id: compId,
        compType: getCompTypeWithTextClassifications(
          compId,
          compType,
          typeMappingData.textClassificationData.textClassificationPredictions,
        ),
      };
    })
    .filter((group: any) => group);
};

const getRestructuredList = (
  originalCompMappingData: OriginalCompMappingData,
  editorAPI: EditorAPI,
  biData: BiData,
): ListClassificationResponseGroup | null => {
  const componentsIdsWithTypes = getListComponentsWithTypesMapping(
    editorAPI,
    originalCompMappingData,
  );
  const componentsGroupedByType = getComponentsGroupedByType(
    componentsIdsWithTypes,
  );

  const sortedComponentsGroupedByType = getSortedComponents(
    editorAPI,
    componentsGroupedByType,
    originalCompMappingData,
  );

  const areGroupsLengthValid = areGroupsWithSameLength(
    sortedComponentsGroupedByType,
  );
  if (!areGroupsLengthValid) {
    sendListInfo(
      editorAPI,
      biData,
      originalCompMappingData,
      LIST_PRECESS_INFO_OPTIONS.RESTRUCTURE_FAILED,
    );
    if (isDebugMode()) {
      console.log(
        'List classification issue - Invalid group members number - groups with unequal number of items',
      );
    }
    return null;
  }

  const fixedGroups: ListClassificationResponseGroup[] = [];
  sortedComponentsGroupedByType?.[0]?.forEach(
    //@ts-expect-error
    (group: ComponentIdAndType[], index: number) => {
      fixedGroups[index] = {
        isSection: false,
        groupType: 'Related',
        tag: 'Group',
        id: `Group_${index}`,
        children: sortedComponentsGroupedByType.map(
          (group: any) =>
            originalCompMappingData.listData.flattenListComponents?.[
              group[index]?.id
            ],
        ),
      };
    },
  );

  const restructuredList = {
    ...originalCompMappingData.listData.resultListMap,
    children: fixedGroups,
  };

  return restructuredList;
};

export const validateSectionGrouping = (
  originalDataMapping: OriginalCompMappingData,
  editorAPI: EditorAPI,
  biData: BiData,
): boolean => {
  if (
    !originalDataMapping?.listData?.resultListMap ||
    !Object.values(originalDataMapping?.listData?.resultListMap)?.[0]
  ) {
    sendListInfo(
      editorAPI,
      biData,
      originalDataMapping,
      LIST_PRECESS_INFO_OPTIONS.NO_LISTS_FOUND,
    );
    return false;
  }

  const restructuredResultListMap = getRestructuredList(
    originalDataMapping,
    editorAPI,
    biData,
  );

  if (restructuredResultListMap) {
    originalDataMapping.listData.resultListMap = restructuredResultListMap;
    originalDataMapping.listData.updatedListAfterRestructure =
      restructuredResultListMap;
    sendListInfo(
      editorAPI,
      biData,
      originalDataMapping,
      LIST_PRECESS_INFO_OPTIONS.RESTRUCTURE_SUCCEEDED,
    );
    return true;
  }

  return false;
};

export const sendListInfo = (
  editorAPI: EditorAPI,
  biData: BiData,
  originalCompMapping: OriginalCompMappingData,
  listInfo: ListBiInfo,
) => {
  editorAPI.bi.event(
    coreBi.events.switchLayout.switchLayoutWithListSessionInfo,
    {
      ...biData,
      error_info: listInfo.errorInfo || undefined,
      is_list: listInfo.isList,
      list_type: listInfo.listType,
      is_success: listInfo.isSuccess,
    },
  );
  if (listInfo.errorInfo) {
    ErrorReporter.captureException(listInfo.errorInfo, {
      tags: {
        switchLayoutFlow: true,
        switchLayoutListClassificationFlow: true,
      },
    });
  }
  if (isDebugMode()) {
    console.log(
      listInfo.generalMessage,
      `${listInfo.errorInfo || 'No errors found'}`,
    );
    console.log('Extracted list data: ', originalCompMapping.listData);
  }
};
