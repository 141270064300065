// @ts-nocheck
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as baseUI from '@/baseUI';
import objectPropertyEditor from './objectPropertyEditor/objectPropertyEditor';
import ListPropertyEditor from './listPropertyEditor/listPropertyEditor';
import { Button, Composites, Divider, TextLabel } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import { FocusPanelFrame } from '../../frames';

//TYPE WAS GENERATED, remove this line when reviewed
interface CustomTypeValueEditPanelProps {
  initialValue: AnyFixMe | Array<AnyFixMe>;
  property: AnyFixMe;
  resolveDefinition: FunctionFixMe;
  onChange: FunctionFixMe;
  openMediaManager: FunctionFixMe;
  translations?: AnyFixMe;
}

class CustomTypeValueEditPanel extends React.Component<CustomTypeValueEditPanelProps> {
  static displayName = 'CustomTypeValueEditPanel';

  static propTypes = {
    initialValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
      .isRequired,
    property: PropTypes.object.isRequired,
    resolveDefinition: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    openMediaManager: PropTypes.func.isRequired,
    translations: PropTypes.object,
  };

  state = {
    value: this.props.initialValue,
  };

  getPropertyName = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    return _.head(_.keys(this.props.property));
  };

  getPropertyData = () => {
    const name = this.getPropertyName();
    return this.props.property[name];
  };

  getEditorElementByType = (type) => {
    switch (type) {
      //TODO: use constants
      case 'array':
        return ListPropertyEditor;
      //TODO: use constants
      case 'object':
        return objectPropertyEditor;
      default:
        throw new Error(`Type ${type} is not supported by this panel`);
    }
  };

  getEditorAutomationIdByType = (type) => {
    switch (this.getPropertyData().type) {
      case 'array':
        return 'list-editor';
      case 'object':
        return 'object-editor';
      default:
        throw new Error(`Type ${type} is not supported by this panel`);
    }
  };

  getValueEditor = () => {
    const { type, title } = this.getPropertyData();
    const element = this.getEditorElementByType(type);
    return React.createElement(element, {
      propertyTitle: title || this.getPropertyName(),
      value: this.state.value,
      propertyData: this.getPropertyData(),
      resolveDefinition: this.props.resolveDefinition,
      openMediaManager: this.props.openMediaManager,
      onChange: this.handleChange,
      translations: this.props.translations,
      automationId: this.getEditorAutomationIdByType(type),
    });
  };

  hasSave = () => {
    return !this.isInEmptyState() || this.getPropertyData().type === 'array';
  };

  handleSave = () => {
    this.props.onChange(this.state.value);
  };

  handleChange = (newValue) => {
    this.setState({ value: newValue });
  };

  handleCancel = () => {
    this.props.onChange(this.props.initialValue);
  };

  isInEmptyState = () => {
    //TODO: constant
    if (this.getPropertyData().type === 'array') {
      return this.state.value.length === 0;
    }

    return false;
  };

  isValid = () => {
    return baseUI.utils.propertySchemaValidationUtils.validateByProperty(
      this.getPropertyData(),
      this.state.value,
      this.props.resolveDefinition,
    );
  };

  render() {
    const title =
      this.getPropertyData().title ||
      this.getPropertyName() ||
      translate('AppStudio_WidgetAPI_NewProperty_Name_label');

    return (
      <FocusPanelFrame
        panelName="panels.focusPanels.customTypeValueEditPanel"
        automationId="property-value-panel"
        title={translate(
          this.props.translations?.panelTitle ??
            'AppStudio_CustomType_DefaultValues_Header',
        )}
        width="474px"
        className="custom-type-value-edit-panel"
      >
        <div className={`property-value-panel ${this.props.className}`}>
          <div key="prop-name-container" className="prop-name-container">
            <TextLabel
              automationId="prop-name-label"
              value={title}
              type="T18"
              shouldTranslate={false}
              className="prop-name-label"
            />
            <Divider long={true} />
          </div>
          {this.getValueEditor()}
          <Divider long={true} />
          <Composites.ActionSetHorizontal>
            <Button
              automationId="cancel-btn"
              onClick={this.handleCancel}
              className="btn-confirm-secondary btn-md"
            >
              <TextLabel value="AppStudio_Cancel_Button" />
            </Button>
            {this.hasSave() ? (
              <Button
                automationId="save-btn"
                onClick={this.handleSave}
                disabled={!this.isValid()}
                className="btn-md"
              >
                <TextLabel value="AppStudio_Apply_Button" />
              </Button>
            ) : null}
          </Composites.ActionSetHorizontal>
        </div>
      </FocusPanelFrame>
    );
  }
}

export default CustomTypeValueEditPanel;
