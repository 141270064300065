// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import PageItem from './pageItem';
import * as quickActionsUtils from '../utils/quickActionsUtils';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  editorAPI: AnyFixMe;
  dataSource: AnyFixMe;
  onActionSelected?: FunctionFixMe;
  editedPageIdLink?: AnyFixMe;
  setSelected?: FunctionFixMe;
  isVisible?: boolean;
  isSelected?: boolean;
  nodePath?: Array<AnyFixMe>;
}

export default class extends React.Component<Props> {
  static displayName = 'nodeContentCommon';

  static propTypes = {
    editorAPI: PropTypes.object.isRequired,
    dataSource: PropTypes.object.isRequired,
    onActionSelected: PropTypes.func,
    editedPageIdLink: PropTypes.object,
    setSelected: PropTypes.func,
    isVisible: PropTypes.bool,
    isSelected: PropTypes.bool,
    nodePath: PropTypes.array, // TODO: should be moved out of here because this is specific to a tree view
  };

  onChange = (newPageName) => {
    if (this.props.onActionSelected) {
      this.props.onActionSelected('rename', this.props.dataSource, newPageName);
    }
    if (this.props.editedPageIdLink) {
      this.props.editedPageIdLink.requestChange(null);
    }
  };

  editModeLink = () => {
    const data = this.props.dataSource;
    const { editedPageIdLink } = this.props;

    return {
      value: editedPageIdLink && editedPageIdLink.value === data.id,
      requestChange(newVal) {
        if (editedPageIdLink) {
          editedPageIdLink.requestChange(newVal ? data.id : null);
        }
      },
    };
  };

  onClick = (e) => {
    this.props.setSelected(e);
    if (_.isFunction(this.props.onClick)) {
      this.props.onClick(e);
    }
  };

  render() {
    const data = this.props.dataSource;

    return (
      <PageItem
        ref="pageItem"
        value={data.label}
        actions={this.props.actions}
        hasNotification={
          data.type.isPage &&
          quickActionsUtils.hasNotification(
            this.props.editorAPI,
            data.pageData.id,
          )
        }
        onChange={this.onChange}
        onClick={this.onClick}
        isMobile={this.props.editorAPI.dsRead.viewMode.get() === 'MOBILE'}
        onMouseLeave={this.props.onMouseLeave}
        onContextMenu={this.props.setSelected}
        {...{
          label: data.label,
          isVisible: this.props.isVisible,
          isSelected: this.props.isSelected,
          isHome:
            data.pageData &&
            this.props.editorAPI.pages.isHomePage(data.pageData.id),
          isPage: data.type.isPage,
          isLink: data.type.isLink,
          isDropdown: data.type.isDropdown,
          isDynamicPage: data.type.isDynamicPage,
          routerType: data.type.routerType,
          isRouter: data.type.isRouter,
          depth: this.props.nodePath ? this.props.nodePath.length - 1 : 0,
          editModeLink: this.editModeLink(),
          additionalClasses: _.has(data, 'additionalClasses')
            ? data.additionalClasses
            : [],
        }}
      >
        {this.props.children}
      </PageItem>
    );
  }
}
