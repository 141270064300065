import type { CompRef } from '@wix/document-services-types';
import type { EditorAPI } from '@/editorAPI';
import { isGhostRefComponent as isGhostRefComponentByType } from '@/documentServices';
import type { DSRead } from 'types/documentServices';
import experiment from 'experiment';

function resolveCompRefWithVariant(dsRead: DSRead, compRef: CompRef) {
  return compRef.type === 'MOBILE'
    ? dsRead.components.variants.getPointer(compRef, [
        dsRead.variants.mobile.get(),
      ])
    : compRef;
}

export const isGhostCollapsed = (editorAPI: EditorAPI, compRef: CompRef) => {
  const properties = editorAPI.dsRead.components.properties.get(compRef);
  return properties?.ghost === 'COLLAPSED';
};

const isComponentHidden = (editorAPI: EditorAPI, compRef: CompRef) => {
  const compRefWithVariant = resolveCompRefWithVariant(
    editorAPI.dsRead,
    compRef,
  );
  const layouts =
    editorAPI.dsRead.components.responsiveLayout.get(compRefWithVariant);
  if (!layouts) {
    return false;
  }
  return layouts.componentLayout?.hidden;
};

export const isRendered = (editorAPI: EditorAPI, compRef: CompRef) => {
  return editorAPI.dsRead.components.is.rendered(compRef);
};

export const hasVolume = (editorAPI: EditorAPI, compRef: CompRef) => {
  const { width, height } =
    editorAPI.dsRead.components.layout.getRelativeToScreen(compRef);
  return width > 0 && height > 0;
};

export const isComponentVisibleInLayersPanel = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): boolean =>
  experiment.isOpen('se_hideHiddenComponentsFromLayersPanel')
    ? !isComponentHidden(editorAPI, compRef) &&
      !isGhostCollapsed(editorAPI, compRef) &&
      !isGhostRefComponentByType(editorAPI.dsRead, compRef)
    : !isGhostCollapsed(editorAPI, compRef) &&
      !isGhostRefComponentByType(editorAPI.dsRead, compRef);
