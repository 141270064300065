// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import * as util from '@/util';
import $ from 'zepto';
import React from 'react';
import * as BaseUI from '@/baseUI';
import * as Panels from '@/panels';

function getSnapshotFromUrl(url) {
  return url.split('/').pop() || 'unknown';
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'editorVersionInfoPanel',
  panelName: 'editorVersionInfoPanel',
  mixins: [core.mixins.editorAPIMixin],
  getEditorVersion() {
    return getSnapshotFromUrl(util?.editorModel?.editorBase ?? '');
  },
  getThunderboltViewerVersion() {
    return `${getSnapshotFromUrl(
      $('#preview')[0]?.contentWindow?.window.viewerSource,
    )} (thunderbolt)`;
  },
  getDmVersion() {
    return getSnapshotFromUrl(
      $('#preview')[0]?.contentWindow?.dmBase ||
        $('#preview')[0]?.contentWindow?.dsBoltBase,
    );
  },
  getEditorElementsVersion() {
    return getSnapshotFromUrl(
      $('#preview')[0]
        ?.contentWindow?.serviceTopology.scriptsLocationMap[
          'editor-elements'
        ].split('/')
        .pop(),
    );
  },
  close() {
    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },
  render() {
    return (
      <Panels.frames.FocusPanelFrame
        panelName={this.props.panelName}
        title="Editor Version Info"
        frameClassName="editorVersionInfoPanel"
      >
        <div className="content">
          <div className="row">
            <label>
              Editor Version: <span>{this.getEditorVersion()}</span>
            </label>
          </div>
          <div className="row">
            <label>
              DM Version: <span>{this.getDmVersion()}</span>
            </label>
          </div>
          <div className="row">
            <label>
              Viewer Version: <span>{this.getThunderboltViewerVersion()}</span>
            </label>
          </div>
          <div className="row">
            <label>
              Editor Elements Version:{' '}
              <span>{this.getEditorElementsVersion()}</span>
            </label>
          </div>
        </div>

        <div className="panel-footer">
          <BaseUI.button
            type="button"
            shouldTranslate={false}
            label="OK"
            onClick={this.close}
            className="done-button"
          />
        </div>
      </Panels.frames.FocusPanelFrame>
    );
  },
});
