import type { EditorAPI } from '@/editorAPI';
import { leftBarActions } from '@/stateManagement';

export const overrideLeftBar = (
  editorAPI: EditorAPI,
  leftBarOverriding: {
    desktop?: Array<{ key: string }>;
    mobile?: Array<{ key: string }>;
  },
) => {
  const action = leftBarActions.overrideItems(leftBarOverriding);
  editorAPI.store.dispatch(action);
};
