import React from 'react';
import { cx } from '@/util';

import { Action, type ActionProps } from '../Action/Action';
import styles from './ActionsBar.scss';

import type { ActionIds } from '../Actions.constants';
import type { ActionsGroup } from '../Actions.types';
import type { UISkin } from '../../../skins/skins.types';

interface ActionsBarProps {
  primaryActions: ActionsGroup;
  secondaryActions: ActionsGroup;
  collapsibleActions: ActionsGroup;
  notCollapsibleAction: ActionsGroup;
  skin: UISkin;
  maxHeight?: number;
  dataHook?: string;
  isMobile: boolean;

  onActionClick: (actionId: ActionIds, e: React.MouseEvent) => void;
  openHelpCenter: (helpId: string) => void;
}

export const ActionsBar = React.forwardRef<HTMLDivElement, ActionsBarProps>(
  (
    {
      maxHeight,
      primaryActions,
      secondaryActions,
      collapsibleActions,
      notCollapsibleAction,
      skin,
      isMobile,
      dataHook,
      onActionClick,
      openHelpCenter,
    },
    ref,
  ) => {
    const commonActionProps: Pick<
      ActionProps,
      'isMobile' | 'onClick' | 'onLearnMoreClick' | 'skin' | 'className'
    > = {
      isMobile,
      onClick: onActionClick,
      onLearnMoreClick: openHelpCenter,
      skin,
      className: styles.action,
    };

    return (
      <div
        ref={ref}
        className={cx(styles.actionBar, {
          [styles.legacy]: skin === 'legacy',
          [styles.primary]: skin === 'primary',
          [styles.secondary]: skin === 'secondary',
          [styles.legacySoap]: skin === 'legacy-soap',
          [styles.primarySoap]: skin === 'primary-soap',
          [styles.secondarySoap]: skin === 'secondary-soap',
        })}
        style={{ maxHeight }}
        data-hook={dataHook}
        data-skin={skin}
      >
        <div className={styles.notRequired}>
          {[primaryActions, secondaryActions].map((groupActions, index) => (
            <div data-collapsible={true} key={index} className={styles.group}>
              {groupActions.map((action) => (
                <Action {...action} {...commonActionProps} key={action.id} />
              ))}
            </div>
          ))}

          {collapsibleActions.map((action) => (
            <Action
              {...action}
              {...commonActionProps}
              key={action.id}
              collapsible={true}
            />
          ))}
        </div>
        <div className={styles.notCollapsibleGroup}>
          {notCollapsibleAction.map((action) => (
            <Action
              {...action}
              {...commonActionProps}
              key={action.id}
              collapsible={false}
            />
          ))}
        </div>
      </div>
    );
  },
);

ActionsBar.displayName = 'ActionsBar';
