import React, {
  type FC,
  type FormEvent,
  useState,
  useCallback,
  useEffect,
} from 'react';
import experiment from 'experiment';
import {
  Composites,
  Button,
  Heading,
  Text,
  Illustration,
} from '@wix/wix-base-ui';
import { hoc, cx } from '@/util';
import { translate } from '@/i18n';
import { frames } from '@/panels';
import { AiTermsAndConditions } from '@/baseUI';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { SparklesBold } from '@wix/wix-ui-icons-common/classic-editor';
import {
  FreeTextInput,
  BusinessTypeInput,
  RegularTextInput,
} from '../../../../ui';

import { validateByKey } from './validation';
import { mapStateToProps } from './questionnairePanelMapper';
import { createBIeventsCallbacks } from '../../welcomePanelBI';

import type { BusinessType } from '@/siteGlobalData';
import type {
  QuestionnaireParams,
  QuestionnaireMessages,
  QuestionnairePanelOwnProps,
  QuestionnairePanelStateProps,
} from '../../types';

import styles from './questionnairePanel.scss';
import { PANEL_NAME, PanelTypes } from '../../constants';
import AnimationPanel from './animationPanel/animationPanel';

export interface Props
  extends QuestionnairePanelOwnProps,
    QuestionnairePanelStateProps {}

const QuestionnairePanel: FC<Props> = ({
  initialBusinessType,
  initialBusinessName,
  onSubmit,
  onClose,
  sendBI,
}) => {
  const {
    sendBIAdditionalAction,
    sendBINotificationShown,
    sendBISettingsChanged,
    sendBICreateTextClick,
    sendBIPanelOpen,
  } = createBIeventsCallbacks(sendBI);

  const [inputValues, setInputValues] = useState({
    businessType: initialBusinessType,
    businessName: initialBusinessName,
    businessDescription: '',
  } as QuestionnaireParams);
  const [validationMessages, setValidationMessages] = useState({
    businessType: '',
    businessName: '',
    businessDescription: '',
  } as QuestionnaireMessages);

  const welcomePanelOptExperimentIsOpen = experiment.isOpen(
    'se_welcomePanelOptimization',
  );

  const renameButtonExperimentIsOpen = experiment.isOpen(
    'se_aiInjectionIntoTemplateRenameButton',
  );

  const handleClose = () => {
    onClose('header_close_button');
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const updateValidationMessages = {} as QuestionnaireMessages;

    Object.keys(validationMessages).forEach((k) => {
      const key = k as keyof QuestionnaireMessages;
      const inputValue = inputValues[key];
      // @ts-expect-error
      updateValidationMessages[key] = validateByKey[key](inputValue);
    });

    const isFormValid = Object.values(updateValidationMessages).every(
      (val: string) => val === '',
    );

    if (isFormValid) {
      onSubmit(inputValues);
      sendBICreateTextClick({
        success: true,
        query: inputValues,
        panelType: PanelTypes.InjectionToSite,
      });
    } else {
      setValidationMessages(updateValidationMessages);
      sendBICreateTextClick({
        success: false,
        query: inputValues,
        panelType: PanelTypes.InjectionToSite,
      });
    }
  };

  const handleBusinessDescriptionChange = useCallback((value: string) => {
    setInputValues((values) => ({ ...values, businessDescription: value }));
    setValidationMessages((messages) => ({
      ...messages,
      businessDescription: validateByKey.businessDescription(value),
    }));
  }, []);

  const handleBusinessDescriptionBlur = useCallback(() => {
    sendBISettingsChanged({
      fieldName: 'freeText',
      mandatoryField: 'yes',
      newValue: inputValues.businessDescription,
      panelType: PanelTypes.InjectionToSite,
    });
  }, [inputValues.businessDescription, sendBISettingsChanged]);

  const handleBusinessTypeChange = useCallback((value: BusinessType) => {
    setInputValues((values) => ({ ...values, businessType: value }));
  }, []);

  const handleBusinessTypeBlur = useCallback(() => {
    sendBISettingsChanged({
      fieldName: 'businessType',
      mandatoryField: 'yes',
      newValue: inputValues.businessType?.displayName,
      panelType: PanelTypes.InjectionToSite,
    });
  }, [inputValues.businessType, sendBISettingsChanged]);

  const handleBusinessNameChange = useCallback((value: string) => {
    setInputValues((values) => ({ ...values, businessName: value }));
    setValidationMessages((messages) => ({
      ...messages,
      businessName: validateByKey.businessName(value),
    }));
  }, []);

  const handleBusinessNameBlur = useCallback(() => {
    sendBISettingsChanged({
      fieldName: 'businessName',
      mandatoryField: 'yes',
      newValue: inputValues.businessName,
      panelType: PanelTypes.InjectionToSite,
    });
  }, [inputValues.businessName, sendBISettingsChanged]);

  const onBusinessDescriptionTooltipOpen = useCallback(() => {
    sendBIAdditionalAction({
      actionName: 'tell about your site -> info button -> hover',
      panelType: PanelTypes.InjectionToSite,
    });
  }, [sendBIAdditionalAction]);

  const onBusinessTypeTooltipOpen = useCallback(() => {
    sendBIAdditionalAction({
      actionName: 'type of site -> info button -> hover',
      panelType: PanelTypes.InjectionToSite,
    });
  }, [sendBIAdditionalAction]);

  useEffect(() => {
    if (validationMessages.businessDescription) {
      sendBINotificationShown({
        fieldName: 'freeText',
        type: 'error',
        message: translate(validationMessages.businessDescription),
        panelType: PanelTypes.InjectionToSite,
      });
    }
  }, [validationMessages.businessDescription, sendBINotificationShown]);

  const [hasPanelOpen, setPanelOpened] = useState(false);
  useEffect(() => {
    if (!hasPanelOpen && sendBIPanelOpen) {
      sendBIPanelOpen({
        query: inputValues,
        panelType: PanelTypes.InjectionToSite,
      });
      setPanelOpened(true);
    }
  }, [hasPanelOpen, sendBIPanelOpen, inputValues]);

  return (
    <frames.CustomPanelFrame
      panelName={PANEL_NAME}
      className={cx(
        styles.panel,
        'ai-injection-into-template-panel',
        welcomePanelOptExperimentIsOpen && styles.panel_opt,
      )}
      dataHook="ai-welcome-panel_questionnaire"
      onCloseButtonClick={handleClose}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <div>
          <div className={styles.title}>
            <Heading appearance="h2" multiline shouldTranslate={false}>
              {translate('ai_template_injection_panel_title')}
            </Heading>

            {!welcomePanelOptExperimentIsOpen && (
              <Illustration
                className={styles.icon}
                children={<Symbol name="aiIcon" />}
              />
            )}
          </div>

          <div className={styles.subtitle}>
            <Text
              size="medium"
              skin="secondary"
              enableEllipsis={false}
              shouldTranslate={false}
            >
              {translate('ai_template_injection_panel_subtitle')}
            </Text>
          </div>

          <div className={styles.row}>
            <FreeTextInput
              label="ai_template_injection_panel_free_text_label"
              placeholder="ai_template_injection_panel_free_text_placeholder"
              value={inputValues.businessDescription}
              invalidMessage={validationMessages.businessDescription}
              onChange={handleBusinessDescriptionChange}
              onBlur={handleBusinessDescriptionBlur}
              onTooltipOpen={onBusinessDescriptionTooltipOpen}
            />
          </div>

          <div className={styles.row}>
            <BusinessTypeInput
              label="ai_template_injection_panel_site_type_label"
              placeholder="ai_template_injection_panel_site_type_placeholder"
              value={inputValues.businessType}
              onChange={handleBusinessTypeChange}
              onBlur={handleBusinessTypeBlur}
              onTooltipOpen={onBusinessTypeTooltipOpen}
            />
          </div>

          <div className={styles.row}>
            <RegularTextInput
              label="ai_template_injection_panel_site_name_label"
              placeholder="ai_template_injection_panel_site_name_placeholder"
              value={inputValues.businessName}
              invalidMessage={validationMessages.businessName}
              onChange={handleBusinessNameChange}
              onBlur={handleBusinessNameBlur}
            />
          </div>
        </div>

        <div className={styles.footer}>
          <Composites.ButtonLargeLabeled
            className={
              welcomePanelOptExperimentIsOpen
                ? styles.submit_opt
                : styles.submit
            }
          >
            <Button
              automationId="ai-injection-into-template-submit-button"
              className={
                welcomePanelOptExperimentIsOpen
                  ? 'btn-ai-primary btn-big'
                  : 'btn-big'
              }
              type="submit"
              prefixIcon={
                !welcomePanelOptExperimentIsOpen && <Symbol name="aiCTAIcon" />
              }
              suffixIcon={welcomePanelOptExperimentIsOpen && <SparklesBold />}
            >
              {translate(
                renameButtonExperimentIsOpen
                  ? 'ai_template_injection_panel_generate_CTA'
                  : 'ai_template_injection_panel_CTA',
              )}
            </Button>
          </Composites.ButtonLargeLabeled>

          <div>
            <AiTermsAndConditions
              translationKey={
                welcomePanelOptExperimentIsOpen
                  ? 'ai_template_injection_panel_legal'
                  : 'ai_text_creator_panel_legal_conditions_text'
              }
              className={styles.terms}
              textWeight="normal"
              textSkin="standard"
              textSize="small"
              withoutDividerAbove={false}
              panelType="injection to site"
              alwaysShow
            />
          </div>
        </div>
      </form>
      {welcomePanelOptExperimentIsOpen && <AnimationPanel />}
    </frames.CustomPanelFrame>
  );
};

const QuestionnairePanelConnect = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
)(QuestionnairePanel);

export default QuestionnairePanelConnect;
