import React, { useContext, type FC } from 'react';
import { LiveChat, MessageRoles } from '@/baseUI';

import styles from './chat.scss';
import { AutoScroller } from './autoscroller';

export interface ChatProps {
  onSendMessage: (message: string) => Promise<string>;
}

const Chat: FC<ChatProps> = ({ onSendMessage }) => {
  const chatContext = useContext(LiveChat.ChatContext);

  const handleSendMessage = (value: string) => {
    chatContext.setShowStreamingPlaceholder(true);
    chatContext.setMessages([
      ...chatContext.messages,
      {
        role: MessageRoles.USER,
        content: value,
      },
    ]);

    onSendMessage(value).then((response) => {
      chatContext.clearMessage();
      chatContext.setShowStreamingPlaceholder(false);
      chatContext.setMessages((currentState) => [
        ...currentState,
        {
          role: MessageRoles.ASSISTANT,
          content: response,
        },
      ]);
    });
  };

  return (
    <div className={styles.container}>
      <AutoScroller
        className={styles.messages}
        messages={chatContext.messages}
        showStreamingPlaceholder={chatContext.showStreamingPlaceholder}
      >
        <LiveChat.Messages
          messages={chatContext.messages}
          lastSystemMessage={null}
          showStreamingPlaceholder={chatContext.showStreamingPlaceholder}
          isStreamingNow={false}
        />
      </AutoScroller>
      <div className={styles.input}>
        <LiveChat.ChatInput
          onSendMessage={handleSendMessage}
          disabled={chatContext.showStreamingPlaceholder}
        />
      </div>
    </div>
  );
};

export default Chat;
