import { DealerOffersServingService } from '@wix/ambassador-dealer-offers-serving-service/http';
import {
  DealerOfferEventsService,
  EventType,
  type OfferEvent,
} from '@wix/ambassador-dealer-offer-events-service/http';

const DEALER_OFFERS_SERVIING_SERVICE_URL =
  '/_api/dealer-offers-serving-service';
const DEALER_OFFER_EVENTS_SERVICE_URL = '/_api/dealer-offer-events-service';

interface GetDealerOffersParams {
  realEstateId: string;
  instance: string;
}

type ReportDealerEventParams = OfferEvent & { instance: string };

async function getOffers(params: GetDealerOffersParams) {
  const { realEstateId, instance } = params;

  const dealerService = DealerOffersServingService(
    DEALER_OFFERS_SERVIING_SERVICE_URL,
  ).DealerOffersServing();

  const { offers } = await dealerService({
    Authorization: instance,
  }).listOffers({ realEstateId });

  return offers;
}

async function reportEvent(params: ReportDealerEventParams) {
  const { instance, ...event } = params;

  const dealerService = DealerOfferEventsService(
    DEALER_OFFER_EVENTS_SERVICE_URL,
  ).DealerOfferEventsService();

  await dealerService({ Authorization: instance }).reportEvent({ event });
}

export { EventType };

export default {
  getOffers,
  reportEvent,
};
