import productGridWidget from './components/productGridWidget';
import shoppingBagWidget from './components/shoppingBagWidget';
import sliderProductGalleryWidget from './components/sliderProductGalleryWidget';
import relatedProductGalleryWidget from './components/relatedProductGalleryWidget';
import multipleProductWidgets from './components/multipleProductWidgets';
import addToCartButton from './components/addToCartButton';
import currencyConverter from './components/currencyConverter';
import wixStoreAddPanelView from '../../addPanel/wixStoreAddPanelView';
import addPanelDataConsts from '@/addPanelDataConsts';
import { superApps } from '@/tpa';

const getRelatedAppsPost = function () {
  return superApps.relatedApps.getRelatedAppsForPostInstall(
    superApps.relatedApps.constants.store.category,
    superApps.relatedApps.constants.store.categoryNum.post,
  );
};

const getRelatedAppsPre = function () {
  return superApps.relatedApps.getRelatedAppsForPreInstall(
    superApps.relatedApps.constants.store.category,
    superApps.relatedApps.constants.store.categoryNum.pre,
  );
};

const appSectionComponent = function () {
  return {
    type: addPanelDataConsts.SECTIONS_TYPES.GENERAL,
    hide: false,
    title: 'add_section_label_wixstores',
    showSectionHeader: true,
    sectionName: 'wixStoreSections',
    props: {
      class: 'no-space-before-section invisible-header',
      template: wixStoreAddPanelView,
    },
    help: {
      hide: false,
      text: 'add_section_label_wixstores_tooltip_description',
    },
    id: 'Wix_Stores_Base_Gallery',
  };
};

let sectionsWhenWixStoresInstalled: AnyFixMe = null;
let sectionsWhenWixStoresNotInstalled: AnyFixMe = null;

export function getWixStoreSections(storeAppIsInstalled: boolean) {
  if (storeAppIsInstalled) {
    if (!sectionsWhenWixStoresInstalled) {
      sectionsWhenWixStoresInstalled = [
        productGridWidget,
        sliderProductGalleryWidget,
        relatedProductGalleryWidget,
        multipleProductWidgets,
        shoppingBagWidget,
        addToCartButton,
        currencyConverter,
        getRelatedAppsPost(),
      ];
    }

    return sectionsWhenWixStoresInstalled;
  }

  if (!sectionsWhenWixStoresNotInstalled) {
    sectionsWhenWixStoresNotInstalled = [
      appSectionComponent(),
      getRelatedAppsPre(),
    ];
  }

  return sectionsWhenWixStoresNotInstalled;
}
