._addSectionThumbnailOverlay_nm9ta_1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.88);
  opacity: 0;
  z-index: 1;
  transition: opacity 0.3s ease-in-out; }
  ._addSectionThumbnailOverlay_nm9ta_1._addSectionThumbnailOverlayHover_nm9ta_14 {
    opacity: 1; }
