import keyboardShortcutsActionMap from '@/keyboardShortcuts';
import experiment from 'experiment';
import * as coreBi from '@/coreBi';
import { bi } from '@/stateManagement';
import { sections } from '@/util';
import { isCompClipboardItem } from '@/clipboard';

import * as commonKeyboardActions from '../util/commonKeyboardActions';
import {
  pasteFromKeyboard,
  removeComponentFromKeyboard,
} from './baseEditorShortcuts';
import {
  copyComponentsFromKeyboard,
  duplicateComponentFromKeyboard,
  cutComponentFromKeyboard,
} from './editorShortcuts';

import type { EditorAPI } from '@/editorAPI';

const ZOOM_MODE_SHORTCUTS_CONFIG = {};

const { editor: editorKeyboardMap, zoomMode: zoomModeKeyboardMap } =
  keyboardShortcutsActionMap;

function addZoomModeShortcuts(editorAPI: EditorAPI) {
  const keysToMerge: Record<string, Function> = {};
  const stageSizeExperimentIsOpen = experiment.isOpen('se_stageSize');

  keysToMerge[editorKeyboardMap.UNDO.key] = () =>
    commonKeyboardActions.undoFromKeyboard(editorAPI);

  keysToMerge[editorKeyboardMap.REDO.key] = () =>
    commonKeyboardActions.redoFromKeyboard(editorAPI);

  keysToMerge[editorKeyboardMap.SAVE.key] = () =>
    commonKeyboardActions.saveSiteFromKeyboard(editorAPI);

  keysToMerge[editorKeyboardMap.SAVE_AS_TEMPLATE.key] = () =>
    commonKeyboardActions.saveSiteAsTemplateFromKeyboard(editorAPI);

  keysToMerge[editorKeyboardMap.PUBLISH_SITE.key] = (event: KeyboardEvent) =>
    commonKeyboardActions.publishSiteFromKeyboard(editorAPI, event);

  keysToMerge[zoomModeKeyboardMap.EXIT_ZOOM_MODE.key] = () => {
    const shouldZoomBeFixed = stageSizeExperimentIsOpen
      ? editorAPI.zoomMode.isZoomedByUser()
      : false;

    if (shouldZoomBeFixed) {
      editorAPI.setSiteScale({
        requestedScale: 0.5,
        scrollY: editorAPI.scroll.get().scrollTop || 0,
      });
    } else {
      editorAPI.zoomMode.exitZoomMode({ biParams: { origin: 'keyboard' } });
    }

    editorAPI.store.dispatch(
      bi.actions.event(coreBi.events.editor.ESCAPE_BUTTON_CLICKED, {
        mode: 'zoom_mode',
      }),
    );
  };

  if (sections.isSectionsEnabled()) {
    const canPaste = () => {
      const clipboardItem = editorAPI.clipboard.getItem();

      if (!isCompClipboardItem(clipboardItem)) return false;

      return clipboardItem.value.components.every((structure) =>
        editorAPI.sections.isSectionByCompType(structure.componentType),
      );
    };

    keysToMerge[editorKeyboardMap.COPY.key] = () =>
      copyComponentsFromKeyboard(editorAPI);

    keysToMerge[editorKeyboardMap.PASTE.key] = () => {
      if (!canPaste()) return;
      pasteFromKeyboard(editorAPI, false);
    };

    keysToMerge[editorKeyboardMap.PASTE_AND_APPLY_THEME.key] = () => {
      if (!canPaste()) return;
      pasteFromKeyboard(editorAPI, true);
    };

    keysToMerge[editorKeyboardMap.DUPLICATE.key] = () =>
      duplicateComponentFromKeyboard(editorAPI);

    keysToMerge[editorKeyboardMap.CUT.key] = () =>
      cutComponentFromKeyboard(editorAPI);

    keysToMerge[editorKeyboardMap.DELETE.key] = () =>
      removeComponentFromKeyboard(editorAPI);
  }

  Object.assign(ZOOM_MODE_SHORTCUTS_CONFIG, keysToMerge);
}

export default {
  init(editorAPI: EditorAPI) {
    addZoomModeShortcuts(editorAPI);
  },
  shortcuts: ZOOM_MODE_SHORTCUTS_CONFIG,
};
