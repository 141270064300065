import React, { PureComponent } from 'react';
import _ from 'lodash';
import * as util from '@/util';
import { editorWixRecorder } from '@/util';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import type { ProgressStatus } from '@/constants';
import withSendBi, { type WithSendBiProps } from '../../hocs/withSendBi';
import withOpenDropPanel from '../../hocs/withOpenDropPanel';
import withDropDown, {
  type WithDropDownOnHoverDropDownProps,
  type ComponentWithDropDownOnHoverProps,
} from '../../hocs/withDropDownOnHover';
import withCustomDropPanel from '../../hocs/withCustomDropPanel';
import withDropPanelBiBehavior from './withDropPanelBiBehavior';
import withTestSiteDropPanel from './withDropPanel';
import { mapStateToProps, mapDispatchToProps } from './testSiteMappers';
import TopBarButton from '../../topBarButton/topBarButtonWithTooltip';

const { BUTTONS } = constants.ROOT_COMPS.TOPBAR;

const isInProgress = (status: ProgressStatus) =>
  status === constants.PROGRESS_STATUS.IN_PROGRESS;
export interface TestSiteButtonOwnProps {
  className?: string;
}

export interface TestSiteButtonStateProps {
  status: ProgressStatus;
  disabled: boolean;
  isSitePublished?: boolean;
  isDevModeOn: boolean;
  publishRC: (origin?: string) => void;
  buttonLabel: string;
  actionChoice: string;
  topBarStateBIParamValue: string;
}

export interface TestSiteButtonDispatchProps {
  togglePreview: (
    callback?: Function,
    params?: { biParams?: AnyFixMe },
  ) => void;
}

type TestSiteButtonProps = TestSiteButtonOwnProps &
  TestSiteButtonStateProps &
  TestSiteButtonDispatchProps &
  WithSendBiProps &
  ComponentWithDropDownOnHoverProps;

export enum Action {
  TEST_SITE = 'Test Site',
  PREVIEW = 'Preview',
}
class TestSiteButton extends PureComponent<
  TestSiteButtonProps & WithSendBiProps
> {
  handleClick = (action: string) => {
    if (action === Action.TEST_SITE) {
      this.props.publishRC('test_site_topbar');

      editorWixRecorder.addLabel('test site top bar clicked');
      this.props.sendBi(coreBi.events.topbar.top_bar_click, {
        src: 38,
        category: 'test_site',
        state: this.props.topBarStateBIParamValue,
      });
      this.props.sendBi(coreBi.events.topbar.start_creating_rc_click, {
        origin: 'top_bar',
      });
    } else {
      this.props.togglePreview();

      util.editorWixRecorder.addLabel('preview top bar clicked');

      util.editorWixRecorder.addLabel('preview click');
      this.props.sendBi(coreBi.events.topbar.top_bar_click, {
        src: 38,
        category: 'preview',
        state: this.props.topBarStateBIParamValue,
      });
      this.props.sendBi(coreBi.events.preview.PREVIEW_CLICK, {
        origin: constants.BI.PREVIEW.ORIGIN.TOP_BAR,
        is_dev_mode: this.props.isDevModeOn,
        category: 'preview',
      });
    }
  };

  render() {
    const className = util.cx({
      'top-bar-test-site-btn': true,

      'top-bar-btn-in-progress-indicator': isInProgress(this.props.status),
      [this.props.className]: true,
    });

    const label: string = this.props.buttonLabel;
    return (
      <TopBarButton
        automationId="top-bar-button-test-site"
        disabled={this.props.disabled}
        label={label}
        onClick={() => this.handleClick(this.props.actionChoice)}
        className={className}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      />
    );
  }
}

const TestSiteButtonWithDropDown = _.flow(
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  ),
  withDropDown,
  withDropPanelBiBehavior,
  withCustomDropPanel(BUTTONS.TEST_SITE),
  withTestSiteDropPanel,
  withOpenDropPanel,
  withSendBi,
)(TestSiteButton) as React.ComponentType<
  TestSiteButtonOwnProps & WithDropDownOnHoverDropDownProps
>;

TestSiteButtonWithDropDown.defaultProps = {
  panelTopMargin: 11,
};

(TestSiteButtonWithDropDown as AnyFixMe).pure = TestSiteButton;

export default TestSiteButtonWithDropDown;
