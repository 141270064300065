import { BasePublicApi } from '@/apilib';
import type { Scope } from './scope';
import type React from 'react';

type TopBarType = 'main' | 'preview';
function contributeWorkspaceComponent(
  scope: Scope,
  item: React.ComponentType<{}>,
) {
  scope.slots.workspaceComponents.contribute(item);
}

function contributeStageComponent(scope: Scope, item: React.ComponentType<{}>) {
  scope.slots.stageComponents.contribute(item);
}

function contributeMouseComponent(scope: Scope, item: React.ComponentType<{}>) {
  scope.slots.mouseCatcherComponents.contribute(item);
}

function contributeWorkspaceRightPanel(
  scope: Scope,
  item: React.ComponentType<{}>,
) {
  scope.slots.workspaceRightPanel.contribute(item);
}

function getStageComponents(scope: Scope) {
  return scope.slots.stageComponents.getItems();
}

function getMouseCatcherComponents(scope: Scope) {
  return scope.slots.mouseCatcherComponents.getItems();
}

function getWorkspaceComponents(scope: Scope) {
  return scope.slots.workspaceComponents.getItems();
}

function getWorkspaceRightPanel(scope: Scope) {
  return scope.slots.workspaceRightPanel.getItems();
}

function getTopBarRightSideComponents(scope: Scope, type: TopBarType) {
  return scope.slots.topBarContributions[type].rightSide
    .getItems()
    .map(({ contribution }) => contribution);
}

function getTopBarLogoComponent(scope: Scope, type: TopBarType) {
  const items = scope.slots.topBarContributions[type].logo.getItems();
  return items?.[0];
}

function contributeTopBarLogo(
  scope: Scope,
  item: React.ComponentType<{}>,
  type: TopBarType,
  condition?: () => boolean,
) {
  scope.slots.topBarContributions[type].logo.contribute(item, condition);
}

function contributeTopBarRightSideComponent(
  scope: Scope,
  item: React.ComponentType<{}>,
  type: TopBarType,
  condition?: () => boolean,
) {
  scope.slots.topBarContributions[type].rightSide.contribute(item, condition);
}

function contributeEditBoxComponent(
  scope: Scope,
  item: React.ComponentType<{}>,
  condition?: () => boolean,
) {
  scope.slots.editBoxComponents.imageToBackground.contribute(item, condition);
}

function getEditBoxComponents(scope: Scope) {
  return scope.slots.editBoxComponents.imageToBackground.getItems();
}

export class WorkspaceApi extends BasePublicApi<Scope> {
  contributeStageComponent = this.bindScope(contributeStageComponent);
  contributeMouseCatcherComponent = this.bindScope(contributeMouseComponent);
  contributeWorkspaceComponent = this.bindScope(contributeWorkspaceComponent);
  contributeWorkspaceRightPanel = this.bindScope(contributeWorkspaceRightPanel);
  contributeTopBarLogo = this.bindScope(contributeTopBarLogo);
  contributeTopBarRightSideComponent = this.bindScope(
    contributeTopBarRightSideComponent,
  );
  contributeEditBoxComponent = this.bindScope(contributeEditBoxComponent);

  getStageComponents = this.bindScope(getStageComponents);
  getMouseCatcherComponents = this.bindScope(getMouseCatcherComponents);
  getWorkspaceComponents = this.bindScope(getWorkspaceComponents);
  getWorkspaceRightPanel = this.bindScope(getWorkspaceRightPanel);
  getTopBarRightSideComponents = this.bindScope(getTopBarRightSideComponents);
  getTopBarLogoComponent = this.bindScope(getTopBarLogoComponent);
  getEditBoxComponents = this.bindScope(getEditBoxComponents);

  hooks = this.scope.hooks;
}
