import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';

function template(_props: AnyFixMe, _context: AnyFixMe) {
  return (
    <div className="action-success-symbol">
      <symbols.symbol name="topBarActionSuccess" />
      <div className="symbol-mask" />
      <div className="circle" />
    </div>
  );
}

const ActionSuccessSymbol = template;

//@ts-expect-error
ActionSuccessSymbol.displayName = 'ActionSuccessSymbol';

export default ActionSuccessSymbol;
