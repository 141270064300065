// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import mobileSettingsMixin from './mobileSettingsMixin';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import React from 'react';
import { ToggleSwitch } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import MobileElementsSettingsHeader from './mobileElementsSettingsHeader/mobileElementsSettingsHeader';
import { cx } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'mobileSettingsBackToTop',
  mixins: [mobileSettingsMixin, core.mixins.editorAPIMixin],
  propTypes: {
    id: PropTypes.string,
    helpId: PropTypes.string,
  },
  goToPreview() {
    const editorAPI = this.getEditorAPI();
    editorAPI.preview.togglePreview();
    editorAPI.bi.event(
      coreBi.events.mobileEditor.mobileTools.BACK_TO_TOP_PREVIEW_CLICK,
      {},
    );
  },
  openHelpCenter() {
    const biHelpParams = {
      learn_more: true,
      panel_name: this.props.id,
      origin: constants.BI.HELP.ORIGIN.LEFTBAR,
    };
    this.getEditorAPI().panelManager.openHelpCenter(
      this.props.helpId,
      null,
      biHelpParams,
    );
  },
  render() {
    return (
      <div
        className={cx({
          'mobile-element-settings': true,
          backToTopEnabled: this.state.toggleValue,
        })}
      >
        <MobileElementsSettingsHeader
          title="mobile_backtotop_title"
          helpId={this.props.helpId}
          minimize={this.props.onClick}
        />
        <div className="content">
          <div className="category-desc">
            <p className="text">
              {translate('mobile_backtotop_text')}{' '}
              <a onClick={this.openHelpCenter} className="learn-more">
                {translate('mobile_backtotop_link')}
              </a>
            </p>
          </div>
          <ToggleSwitch
            value={this.getValueLink().value}
            label="mobile_backtotop_show_label"
            onChange={this.getValueLink().requestChange}
          />
          <br />

          <div className="imageWrapper">
            <div className="img" />
          </div>
          <p className="text">
            {translate('mobile_backtotop_bottom_text')}{' '}
            <a onClick={this.goToPreview} className="go-to-preview">
              {translate('mobile_backtotop_preview_text')}
            </a>
            {translate('mobile_backtotop_bottom_text2')}
          </p>
        </div>
      </div>
    );
  },
});
