const STAFF_LIST_WIDGET_IMAGE_V3 =
  'wixBookings/addPanel/preset_editor_staff_list_widget.v3.png';
const STAFF_LIST_PRESET_KEY = 'Wix_Bookings_Staff_List_Widget';
const STAFF_LIST_WIDGET_ID = '3dc66bc5-5354-4ce6-a436-bd8394c09b0e';
const STAFF_LIST_TITLE_KEY = 'add_section_label_staff_list_input';
const STAFF_LIST_DEFAULT_VARIATION_ID = 'edar7';

export default {
  STAFF_LIST_WIDGET_IMAGE_V3,
  STAFF_LIST_PRESET_KEY,
  STAFF_LIST_WIDGET_ID,
  STAFF_LIST_TITLE_KEY,
  STAFF_LIST_DEFAULT_VARIATION_ID,
};
