import type { EditorAPI } from '@/editorAPI';
import type { CompStructure, CompRef } from 'types/documentServices';
import _ from 'lodash';
import addPanelDataConsts from '@/addPanelDataConsts';
import triggerBiEvent from './triggerElementAddedBiEvent';
import * as addPanelAddCompService from '../addPanelAddCompService';
import constants from '../../constants/constants';
import superAppsConstants from '../../superApps/superAppsConstants';
import * as superAppsCustomData from '../../superApps/superAppsCustomData';

const APP_DEF_ID = superAppsConstants.NEW_BLOG_APP_DEF_ID;
const WIDGET_ID = '813eb645-c6bd-4870-906d-694f30869fd9';
const ITEM_IDS = {
  DETAILED: 'postListSidebarDetailed',
  SLIDER: 'postListSidebarSlider',
  POSTCARD: 'postListSidebarPostcard',
  MINIMAL: 'postListSidebarMinimal',
};

const layoutNames = {
  [ITEM_IDS.DETAILED]: 'detailed',
  [ITEM_IDS.SLIDER]: 'slider',
  [ITEM_IDS.POSTCARD]: 'postcard',
  [ITEM_IDS.MINIMAL]: 'minimal',
};

const getBiParams = function (itemId: AnyFixMe) {
  return {
    element_type: 'post_list_sidebar',
    layout: layoutNames[itemId],
  };
};

const onClick = function (
  compStructure: CompStructure,
  sectionTitle: string,
  tags: string[],
  itemId: string,
  editorAPI: EditorAPI,
  addOptions: AnyFixMe,
) {
  triggerBiEvent(editorAPI, getBiParams(itemId));
  return addPanelAddCompService.addCompOnClick(
    editorAPI,
    APP_DEF_ID,
    WIDGET_ID,
    compStructure,
    tags,
    itemId,
    {
      origin: constants.BI.type.ADD_WIDGET,
      sectionTitle: null,
      category: null,
    },
    addOptions,
  );
};

const onDrop = function (
  layoutParams: { x: number; y: number },
  compPreset: CompStructure & { itemId: string },
  parentComponent: CompRef,
  editorAPI: EditorAPI,
) {
  triggerBiEvent(editorAPI, getBiParams(compPreset.itemId));
  return addPanelAddCompService.addCompOnDrop(
    editorAPI,
    APP_DEF_ID,
    WIDGET_ID,
    layoutParams,
    compPreset,
    parentComponent,
    _.noop,
    { origin: constants.BI.type.ADD_WIDGET },
  );
};

function getSection() {
  return {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'add_section_label_newblog_postlist_sidebar',
    subNavigationTitle: 'add_section_label_newblog_postlist_sidebar',
    presetTitle: 'add_section_label_newblog_postlist_sidebar',
    tooltipTitle: 'add_section_label_newblog_postlist_sidebar_tooltip_title',
    automationId: 'add-panel-section-newBlog-postlist-sidebar',
    sectionName: 'postListSidebarSection',
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
      iconEnabledForComps: {},
    },
    props: {
      onClick,
      onDrop,
      image:
        'addPanelData/sections/blogPostListSidebarSection_en/blogPostListSidebarSection_en.png',
      retinaImage: {
        src: 'addPanelData/sections/blogPostListSidebarSection_en/blogPostListSidebarSection_en@2x.png',
        dimensions: {
          width: 648,
          height: 648,
        },
      },
      imageHover: null as AnyFixMe,
      items: [
        {
          id: ITEM_IDS.DETAILED,
          structure: {
            layout: {
              x: 0,
              y: 0,
              fixedPosition: false,
              width: 254,
              height: 332,
              scale: 1,
              rotationInDegrees: 0,
            },
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              widgetId: WIDGET_ID,
              appDefinitionId: APP_DEF_ID,
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              referenceId: '',
            },
            mobileHints: {
              type: 'MobileHints',
              hidden: true,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
            },
            style: {
              type: 'TopLevelStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                'postListListSmallDetailed',
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            connections: {
              type: 'ConnectionList',
              items: [] as AnyFixMe,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
            },
            activeModes: {},
          },
          preset: {
            rect: {
              width: 162,
              height: 162,
              x: 0,
              y: 0,
            },
            label: '',
            tags: null as AnyFixMe,
          },
        },
        {
          id: ITEM_IDS.SLIDER,
          structure: {
            layout: {
              x: 0,
              y: 0,
              fixedPosition: false,
              width: 369,
              height: 409,
              scale: 1,
              rotationInDegrees: 0,
            },
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              widgetId: WIDGET_ID,
              appDefinitionId: APP_DEF_ID,
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              referenceId: '',
            },
            mobileHints: {
              type: 'MobileHints',
              hidden: true,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
            },
            style: {
              type: 'TopLevelStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                'postListSliderSmall',
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            connections: {
              type: 'ConnectionList',
              items: [],
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
            },
            activeModes: {},
          },
          preset: {
            rect: {
              width: 162,
              height: 162,
              x: 162,
              y: 0,
            },
            label: '',
            tags: null,
          },
        },
        {
          id: ITEM_IDS.POSTCARD,
          structure: {
            layout: {
              x: 0,
              y: 0,
              fixedPosition: false,
              width: 254,
              height: 700,
              scale: 1,
              rotationInDegrees: 0,
            },
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              widgetId: WIDGET_ID,
              appDefinitionId: APP_DEF_ID,
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              referenceId: '',
            },
            mobileHints: {
              type: 'MobileHints',
              hidden: true,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
            },
            style: {
              type: 'TopLevelStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                'postListEditorialSmall',
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            connections: {
              type: 'ConnectionList',
              items: [],
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
            },
            activeModes: {},
          },
          preset: {
            rect: {
              width: 162,
              height: 162,
              x: 0,
              y: 162,
            },
            label: '',
            tags: null,
          },
        },
        {
          id: ITEM_IDS.MINIMAL,
          structure: {
            layout: {
              x: 0,
              y: 0,
              fixedPosition: false,
              width: 254,
              height: 332,
              scale: 1,
              rotationInDegrees: 0,
            },
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              widgetId: WIDGET_ID,
              appDefinitionId: APP_DEF_ID,
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              referenceId: '',
            },
            mobileHints: {
              type: 'MobileHints',
              hidden: true,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
            },
            style: {
              type: 'TopLevelStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                'postListListSmall',
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            connections: {
              type: 'ConnectionList',
              items: [],
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
            },
            activeModes: {},
          },
          preset: {
            rect: {
              width: 162,
              height: 162,
              x: 162,
              y: 162,
            },
            label: '',
            tags: null,
          },
        },
      ],
      compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
    },
    help: {
      hide: false,
      text: 'add_section_label_newblog_postlist_sidebar_tooltip_description	',
    },
  };
}

export default {
  registerSection(editorAPI: EditorAPI) {
    editorAPI.addPanel.registerSectionAtStart(
      addPanelDataConsts.CATEGORIES_ID.NEW_BLOG,
      _.partial(getSection, editorAPI),
    );
  },
};
