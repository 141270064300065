._slotSelectionPanelFrame_2r8m7_1 {
  padding-bottom: 24px; }
  ._slotSelectionPanelFrame_2r8m7_1 .tree-list-item.disabled .tree-list-item-text {
    width: 100%;
    cursor: pointer; }

._slotSelectionPanelDescription_2r8m7_7 {
  padding: 18px 24px; }

._slotSelectionPanelItemTooltip_2r8m7_10 {
  display: inline; }
  ._slotSelectionPanelItemTooltip_2r8m7_10 .control-text.skin-link {
    cursor: pointer; }
    ._slotSelectionPanelItemTooltip_2r8m7_10 .control-text.skin-link:hover {
      text-decoration: underline; }
