import { ErrorReporter } from '@wix/editor-error-reporter';

import { NEW_SITE_WIDTH, BEFORE_MIGRATION_START_LABEL } from '../constants';
import { updateFixedStageState } from './updateFixedStageState';
import { migrationFlowsFedops } from '../utils/fedops';

import type { Scope } from '../scope';

async function beforeMigration(scope: Scope) {
  const { autosaveManager, savePublish, history } = scope.editorAPI;
  const flow = scope.migrationService.getFlow();
  const migrationFedopsLogger = migrationFlowsFedops[flow];

  scope.biService.setStartTime(performance.now());
  history.add(BEFORE_MIGRATION_START_LABEL);
  autosaveManager.init({ enabled: false });
  savePublish.lockSavePublish();
  migrationFedopsLogger.start();
  await scope.biService.beforeMigration(flow);
}

async function afterSuccessMigration(scope: Scope) {
  const { autosaveManager, savePublish, history } = scope.editorAPI;
  const flow = scope.migrationService.getFlow();
  const migrationFedopsLogger = migrationFlowsFedops[flow];
  const migrationEnd = performance.now();

  history.amend();
  autosaveManager.init({ enabled: true }, true);
  savePublish.unlockSavePublish();
  updateFixedStageState(scope);
  migrationFedopsLogger.end();
  scope.migrationHook.resolve();
  scope.biService.afterSuccessMigration(migrationEnd);
}

function afterFailedMigration(scope: Scope, { err }: { err: any }) {
  const { autosaveManager, savePublish, history } = scope.editorAPI;

  history.amend();
  autosaveManager.init({ enabled: true }, true);
  savePublish.unlockSavePublish();
  updateFixedStageState(scope);
  scope.biService.afterFailedMigration(err);
  scope.migrationHook.resolve();
}

async function run(scope: Scope, to: number) {
  const { documentServices } = scope.editorAPI;
  documentServices.site.setWidth(to);
  await documentServices.waitForChangesAppliedAsync();
}

export const runMigration = async (scope: Scope) => {
  const { documentServices } = scope.editorAPI;

  await beforeMigration(scope);

  try {
    await documentServices.transactions.runAndWaitForApproval(async () => {
      await run(scope, NEW_SITE_WIDTH);
      afterSuccessMigration(scope);
    });
  } catch (err: any) {
    afterFailedMigration(scope, { err });
    ErrorReporter.captureException(err, { tags: { siteWidthMigration: true } });
  }
};
