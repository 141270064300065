// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import { Tooltip } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  onClick?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'settingsButton';

  static propTypes = {
    onClick: PropTypes.func,
  };

  handleClick = () => {
    _.invoke(this.props, 'onClick');
  };

  render() {
    return (
      <Tooltip content="Media_Panel_Recommended_Section_Tooltip" maxWidth={195}>
        <button
          onClick={() => {
            this.handleClick();
          }}
          className="media-manager-panel-settings-button"
        >
          <symbols.symbol name="mediaPanelSettingsAction" />
        </button>
      </Tooltip>
    );
  }
}
