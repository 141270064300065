// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Bubble } from '@wix/wix-base-ui';

import PanelContent from './textAndLinkPanel';

const BUBBLE_ARROW_STYLE = {
  position: 'absolute',
  right: 'calc(50% - 8px)',
  top: '53px',
};

const BUBBLE_CONTENT_STYLE = {
  position: 'absolute',
  display: 'block',
  right: 'calc(50% - 120px)',
  top: '60px',
};

class TextAndLinkDropPanel extends React.Component {
  constructor(props) {
    super(props);

    this.bubbleContentStyle = Object.assign(
      {},
      BUBBLE_CONTENT_STYLE,
      props?.bubbleStyleOverrides?.content ?? {},
    );
    this.bubbleArrowStyle = BUBBLE_ARROW_STYLE;
  }

  componentWillUnmount() {
    if (this.props.dropPanelData.onUnmounted) {
      this.props.dropPanelData.onUnmounted();
    }
  }
  render() {
    return (
      <Bubble
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        arrowAlignment="top"
        contentStyle={this.bubbleContentStyle}
        arrowStyle={this.bubbleArrowStyle}
        maxWidth={750}
        className="drop-panel-top-bar"
      >
        <PanelContent panelData={this.props.dropPanelData} />
      </Bubble>
    );
  }
}

TextAndLinkDropPanel.displayName = 'TextAndLinkDropPanel';

TextAndLinkDropPanel.propTypes = {
  dropPanelData: PropTypes.shape({
    body: PropTypes.string.isRequired,
    title: PropTypes.string,
    keyText: PropTypes.string,
    onUnmounted: PropTypes.func,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        titleKey: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      }),
    ),
  }).isRequired,
  bubbleStyleOverrides: PropTypes.shape({
    content: PropTypes.object,
    arrow: PropTypes.object,
  }),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default TextAndLinkDropPanel;
