:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._innerDrillInPanel_18m2f_7 {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  transition: transform ease 300ms, opacity ease 300ms;
  background-color: #fff; }

._innerDrillInContent_18m2f_15 {
  overflow-y: auto;
  height: calc(100% - 56px);
  position: relative; }
