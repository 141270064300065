'use strict'

const videoBoxSection = require('../sections/video/videoBoxSection')
const videoBoxTransparentSection = require('../sections/video/videoBoxTransparentSection')
const videoBoxMaskedSection = require('../sections/video/videoBoxMaskedSection')

const basicShapesSection = require('../sections/vectorShape/basicShapesSection')
const arrowsSection = require('../sections/vectorShape/arrowsSection')
const horizontalLinesSection = require('../sections/vectorShape/horizontalLinesSection')
const verticalLinesSection = require('../sections/vectorShape/verticalLinesSection')

const featuredSection = require('../sections/vectorArt/featuredSection')
const campaign_featuredSection = require('../sections/vectorArt/campaign_featuredSection')
const iconsSection = require('../sections/vectorArt/iconsSection')
const animalNatureSection = require('../sections/vectorArt/animalNatureSection')
const logosBadgesSection = require('../sections/vectorArt/logosBadgesSection')
const charactersSection = require('../sections/vectorArt/charactersSection')

const textMaskSection = require('../sections/textEffects/textMaskSection')

module.exports = {
    featuredSection,
    campaign_featuredSection,
    iconsSection,
    animalNatureSection,
    logosBadgesSection,
    charactersSection,

    basicShapesSection,
    arrowsSection,
    horizontalLinesSection,
    verticalLinesSection,

    videoBoxSection,
    videoBoxMaskedSection,
    videoBoxTransparentSection,

    textMaskSection,
}
