:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._branch-indicator-wrapper_1bexe_7 {
  margin: 0 15px;
  display: inline-flex;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
  max-width: 120px;
  min-width: 40px;
  height: 24px;
  border-radius: 2px;
  background-color: #ffedc1;
  color: #3b4057;
  font-size: 12px;
  text-align: center; }
  ._branch-indicator-wrapper_1bexe_7 ._version-name_1bexe_21 {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
