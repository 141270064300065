import * as stateManagement from '@/stateManagement';
import { replaceAppPageWithReplacerPage } from '../../../services/pageReplaceService';
import _ from 'lodash';
import type { AppData, PageRef, SitemapEntry } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import experiment from 'experiment';

function refreshCurrentPageRoutes(
  editorAPI: EditorAPI,
  appData: AppData,
  options: { selectedRoute?: string },
) {
  const appDefinitionId = appData?.appDefinitionId;

  const currentPageId = editorAPI.dsRead.pages.getFocusedPageId();
  const appRouters = _.omitBy(
    editorAPI.routers.get.all(),
    (value) => value.appDefinitionId !== appDefinitionId,
  );

  const currentPageBelongsToRouter = Object.values(appRouters).find(
    ({ pages }) =>
      !!pages &&
      Object.values(pages).find((pageId) => pageId === currentPageId),
  );
  const selectedRoute = options?.selectedRoute;
  if (currentPageBelongsToRouter) {
    const currentRouteInfo = editorAPI.dsRead.routers.getCurrentInnerRoute();
    return editorAPI.store
      .dispatch(
        stateManagement.dynamicPages.actions.preFetchAndCacheInnerRoutes(
          appRouters,
        ),
      )
      .then(() =>
        editorAPI.store.dispatch(
          stateManagement.dynamicPages.actions.fetchInnerRoutes(
            currentPageId,
            null,
            selectedRoute,
          ),
        ),
      )
      .then((innerRoutes: SitemapEntry[]) => {
        const isCurrentRouteExist = !!innerRoutes.find(
          (route) =>
            route.url === currentRouteInfo?.innerRoute ||
            route.url === `${currentRouteInfo?.innerRoute}/`,
        );
        if (!selectedRoute && isCurrentRouteExist) {
          editorAPI.dsActions.routers.reloadRouterData();
        }
      });
  }
}

function refreshDynamicPageCounts(editorAPI: EditorAPI) {
  editorAPI.store.dispatch(
    stateManagement.dynamicPages.actions.clearInnerRoutesCounts(),
  );
}

function refreshTpaDynamicPageInnerRoute(
  editorAPI: EditorAPI,
  appData: AppData,
) {
  if (experiment.isOpen('se_fetchAndCacheTPAInnerRoutes')) {
    const appDefinitionId = appData?.appDefinitionId;
    const currentPageId = editorAPI.dsRead.pages.getFocusedPageId();

    return editorAPI.store.dispatch(
      stateManagement.tpaDynamicPages.actions.clearInnerRoutesFromCache(
        appDefinitionId,
        currentPageId,
      ),
    );
  }
}

function refresh(
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  options: { selectedRoute?: string },
) {
  return Promise.all([
    refreshCurrentPageRoutes(editorAPI, appData, options),
    refreshDynamicPageCounts(editorAPI),
    refreshTpaDynamicPageInnerRoute(editorAPI, appData),
  ]);
}

function setReplacerPage(
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  options: { pageRef: PageRef; replacerPageRef: PageRef },
) {
  return replaceAppPageWithReplacerPage(
    editorAPI,
    options.pageRef,
    options.replacerPageRef,
  );
}

export { refresh, setReplacerPage };
