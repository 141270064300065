import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import type { ComponentPanelDefinition } from '@wix/editor-component-types';
import { EditorPanelWidth } from '@wix/editor-elements-types/editor';
import { recompose } from '@wix/santa-editor-utils';
import experiment from 'experiment';
import _ from 'lodash';
import React from 'react';
import type { MapStateToProps } from 'types/redux';
import CompPanelFrame from './compPanelFrame';
import * as helpIds from '@/helpIds';
import type { EditorAPI } from '@/editorAPI';

const { compPanel } = stateManagement.components.hoc;
const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const getContentClass = (componentPanelType: string) =>
  componentPanelType === constants.componentPanels.settings
    ? 'settings-panel'
    : '';

/**
 * @deprecated use `wrapExternalComponentPanel`
 */
const externalCompPanel =
  (componentPanelType: string) =>
  (componentPanelDefinition: ComponentPanelDefinition) =>
    wrapExternalComponentPanel({
      componentPanelType,
      componentPanelDefinition,
    });

export type ComponentPanelSource =
  | 'editor-element-registry'
  | 'santa-editor-comp-panels';

export interface WrapExternalComponentPanelProps {
  componentType?: string;
  componentPanelSource?: ComponentPanelSource | string;
  componentPanelType: string;
  componentPanelDefinition: ComponentPanelDefinition;
}

function wrapExternalComponentPanel({
  componentType,
  componentPanelSource,
  componentPanelType,
  componentPanelDefinition,
}: WrapExternalComponentPanelProps) {
  const PanelComponent =
    componentPanelDefinition.PanelClass || componentPanelDefinition.component;
  const WrappedPanel = compPanel(PanelComponent);
  const experiments = (componentPanelDefinition.experiments || []).reduce(
    (res, expName) =>
      _.defaults(
        {
          [expName]: experiment.isOpen(expName),
        },
        res,
      ),
    {},
  );

  interface ExternalCompPanelStateProps {
    editorAPI: EditorAPI;
    isEditorForMobile: boolean;
    componentType: string;
  }

  interface ExternalCompPanelOwnProps {
    helpId: string;
    title: string;
    panelWidth?: EditorPanelWidth;
  }

  type ExternalCompPanelProps = ExternalCompPanelStateProps &
    ExternalCompPanelOwnProps;

  class ExternalCompPanel extends React.Component<ExternalCompPanelProps> {
    static displayName = recompose.wrapDisplayName(
      PanelComponent,
      'externalCompPanel',
    );

    render() {
      const getHelpId = (componentPanelType: string) => {
        const { mobileHelpId, helpId } = componentPanelDefinition;
        const { isEditorForMobile, componentType } = this.props;

        const translatedHelpId = helpIds.UTILS.getComponentHelpId({
          panelType: componentPanelType,
          componentType,
          isMobileEditor: isEditorForMobile,
        });

        if (translatedHelpId) {
          return translatedHelpId;
        }

        if (this.props.helpId) {
          return this.props.helpId;
        }

        return isEditorForMobile && mobileHelpId ? mobileHelpId : helpId;
      };

      const getTitle = () => {
        if (this.props.title) {
          return this.props.title;
        }
        const { mobileTitle, title } = componentPanelDefinition;
        const { isEditorForMobile } = this.props;

        return isEditorForMobile && mobileTitle ? mobileTitle : title;
      };

      const getClassName = () =>
        componentPanelDefinition.panelWidth === EditorPanelWidth.Wide
          ? 'widePanel'
          : '';

      const framePropsFromDefinition =
        typeof componentPanelDefinition.frameProps === 'function'
          ? componentPanelDefinition.frameProps(
              this.props.editorAPI,
              this.props.editorAPI.selection.getSelectedComponents()[0],
            )
          : componentPanelDefinition.frameProps;

      const frameProps = {
        ...framePropsFromDefinition,
        ...util.panelUtils.getFrameProps(this.props),
        componentType,
        componentPanelType,
        componentPanelSource,
        title: getTitle(),
        helpId: getHelpId(componentPanelType),
        contentClass: getContentClass(componentPanelType),
        experiments,
        className: getClassName(),
      };

      return React.createElement(
        CompPanelFrame,
        frameProps,
        React.createElement(WrappedPanel, this.props as any),
      );
    }
  }

  const mapStateToProps: MapStateToProps<ExternalCompPanelStateProps> = ({
    state,
    editorAPI,
  }) => ({
    editorAPI,
    isEditorForMobile: state.isMobileEditor,
    ...(editorAPI.selection.getSelectedComponents().length > 0 && {
      componentType: editorAPI.components.getType(
        editorAPI.selection.getSelectedComponents()[0],
      ),
    }),
  });

  return connect(EDITOR_API, mapStateToProps)(ExternalCompPanel);
}

export { wrapExternalComponentPanel };

export default externalCompPanel;
