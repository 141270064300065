import { events } from '@/coreBi';

import type { SendBiFunction } from 'types/bi';
import type { BusinessType } from '@/siteGlobalData';
import type { QuestionnaireParams, TokenUsage } from './types';
import type { PanelTypes } from './constants';

export const createBIeventsCallbacks = (sendBI: SendBiFunction) => {
  const sendBIAdditionalAction = ({
    actionName,
    panelType,
  }: {
    actionName: string;
    panelType: PanelTypes;
  }) => {
    const {
      aiTextGenerator: { additionalAction },
    } = events;

    sendBI(additionalAction, {
      actionName,
      panelType,
    });
  };

  const sendBINotificationShown = ({
    fieldName,
    type,
    message,
    panelType,
  }: {
    type: string;
    message: string;
    fieldName: string;
    panelType: PanelTypes;
  }) => {
    const {
      aiTextGenerator: { notificationShown },
    } = events;

    sendBI(notificationShown, {
      fieldName,
      type,
      message,
      panelType,
    });
  };

  const sendBISettingsChanged = ({
    fieldName,
    mandatoryField,
    newValue,
    panelType,
  }: {
    fieldName: string;
    mandatoryField: string;
    newValue: string | BusinessType;
    panelType: PanelTypes;
  }) => {
    const {
      aiTextGenerator: { settingsChanged },
    } = events;

    sendBI(settingsChanged, {
      fieldName,
      mandatoryField,
      newValue,
      panelType,
    });
  };

  const sendBICreateTextClick = ({
    success,
    query,
    panelType,
  }: {
    success: boolean;
    query: QuestionnaireParams;
    panelType: PanelTypes;
  }) => {
    const {
      smartWriter: { createTextClick },
    } = events;

    sendBI(createTextClick, {
      isAiTextCreator: true,
      panelType,
      success,
      query,
    });
  };

  const sendBIRequestOutput = ({
    duration,
    query,
    queryOutput,
    pageComponents,
    pageTokens,
    prompt,
    gptParams,
    panelType,
  }: {
    duration: number;
    query: QuestionnaireParams;
    queryOutput: object;
    pageComponents: { pageId: string; componentIds: string[] }[];
    pageTokens: { pageId: string; tokens: TokenUsage[] }[];
    prompt: string;
    gptParams: string;
    panelType: PanelTypes;
  }) => {
    const {
      aiTextGenerator: { gpt3Response },
    } = events;

    sendBI(gpt3Response, {
      duration,
      panelType,
      query: JSON.stringify(query),
      query_output: JSON.stringify(queryOutput),
      pageComponents: JSON.stringify(pageComponents),
      pageTokens: JSON.stringify(pageTokens),
      prompt,
      gptParams,
    });
  };

  const sendBIUseTextBI = ({
    componentIds,
    duration,
    panelType,
  }: {
    componentIds: string[];
    duration: number;
    panelType: PanelTypes;
  }) => {
    const {
      smartWriter: { useTextClick },
    } = events;
    sendBI(useTextClick, {
      isAiTextCreator: true,
      clickOrigin: 'autofill',
      panelType,
      component_id: JSON.stringify(componentIds),
      duration,
    });
  };

  const sendBIError = ({
    query,
    errorMessage,
    gptParams,
    prompt,
    tokens,
    panelType,
  }: {
    query: QuestionnaireParams;
    errorMessage: string;
    gptParams: string;
    prompt: string;
    tokens: TokenUsage[];
    panelType: PanelTypes;
  }): void => {
    const {
      aiTextGenerator: { apiError },
    } = events;

    sendBI(apiError, {
      query: JSON.stringify(query),
      errorMessage,
      panelType,
      gptParams,
      prompt,
      tokens: JSON.stringify(tokens),
    });
  };

  const sendBITextChanged = ({
    text,
    textOrigin,
    componentId,
    parentComponentId,
    componentType,
    pageId,
    parentComponentType,
    sectionType,
  }: {
    text: string;
    textOrigin: string;
    componentId: string;
    parentComponentId: string;
    componentType: string;
    pageId: string;
    parentComponentType: string;
    sectionType: string;
  }): void => {
    const {
      text: { Text_text_changed },
    } = events;

    sendBI(Text_text_changed, {
      text,
      textOrigin,
      component_id: componentId,
      component_type: componentType,
      parentComponentId,
      parentComponentType,
      origin: 'autofill (injection to site)',
      pageId,
      sectionType,
    });
  };

  const sendBIPanelOpen = ({
    query,
    panelType,
  }: {
    query: QuestionnaireParams;
    panelType: PanelTypes;
  }) => {
    const {
      aiTextGenerator: { panelOpen },
    } = events;
    sendBI(panelOpen, {
      query: JSON.stringify(query),
      panelType,
    });
  };

  return {
    sendBIAdditionalAction,
    sendBINotificationShown,
    sendBISettingsChanged,
    sendBICreateTextClick,
    sendBIRequestOutput,
    sendBIUseTextBI,
    sendBIError,
    sendBITextChanged,
    sendBIPanelOpen,
  };
};
