import experiment from 'experiment';

const DEFAULT_AUTOSAVE_ACTION_COUNT = 7;
const DEFAULT_SAVE_AFTER_AUTOSAVE_COUNT = 10;
const DEFAULT_AUTOSAVE_INTERVAL = 30;

let autosaveActionCountConfig = experiment.getValue('se_autosaveActionCount');
let saveAfterAutosaveCountConfig = experiment.getValue(
  'se_saveAfterAutosaveCount',
);
let autosaveIntervalConfig = experiment.getValue('se_autosaveInterval');

autosaveActionCountConfig = getNumberFromExpConfig(
  autosaveActionCountConfig,
  DEFAULT_AUTOSAVE_ACTION_COUNT,
);
saveAfterAutosaveCountConfig = getNumberFromExpConfig(
  saveAfterAutosaveCountConfig,
  DEFAULT_SAVE_AFTER_AUTOSAVE_COUNT,
);
autosaveIntervalConfig = getNumberFromExpConfig(
  autosaveIntervalConfig,
  DEFAULT_AUTOSAVE_INTERVAL,
);

function getNumberFromExpConfig(groupValue: AnyFixMe, defaultValue: AnyFixMe) {
  const configTestGroupRegEx = /^config\d+$/;

  if (configTestGroupRegEx.test(groupValue)) {
    return Number(groupValue.replace('config', ''));
  }

  return defaultValue;
}

export default {
  ERROR_CODES: {
    SAVE_PUBLISH_DISABLED_ON_SERVER: -10132,
    SAVE_PUBLISH_RC_FAILED_ON_SERVER: -10154,
  },
  SAVE: {
    SAVE_FAILED_HELP_LINK: '8045e8e4-6f47-4913-8d74-6239f21fffec',
  },
  AUTOSAVE: {
    AUTOSAVE_TIMEOUT: autosaveIntervalConfig,
    AUTOSAVE_ACTION_COUNT: autosaveActionCountConfig,
    SAVE_AFTER_AUTOSAVE_COUNT: saveAfterAutosaveCountConfig,
    DEBOUNCE_WAIT: 2,
  },
  RENAME: {
    ERRORS: {
      SITE_NAME_TAKEN: -40003,
    },
  },
} as const;
