import React, { type MouseEventHandler } from 'react';
import _ from 'lodash';
import {
  Button,
  Text,
  Divider,
  Composites,
  Preloader,
  AccordionSection,
  RichText,
  CustomScroll,
} from '@wix/wix-base-ui';

import * as panels from '@/panels';
import { hoc } from '@/util';

import {
  SECTIONS_NAMES_WITH_ROLES,
  DEFAULT_CONTENT_ROLE,
  ELEMENTS_BEHIND_ERROR,
  SECTION_NO_NAME_ERROR,
  SAVE_SUCCESS_MSG,
} from './constants';
import SectionsTree from './sectionsTree';
import UnsectionedTree from './unsectionedTree';
import CopyPasteBar from './copyPasteBar';

import withInitData, { type ComponentWithInitDataProps } from './withInitData';
import { mapStateToProps, mapDispatchToProps } from './sectionizerPanelMapper';
import styles from './sectionizerPanel.scss';

import type { CompRef } from 'types/documentServices';
import type { GroupingItem } from '@/sectionsMigration';

import type {
  SectionExtendedMetaData,
  SectionChildren,
  SectionizerPanelStateProps,
  SectionizerPanelDispatchProps,
} from './sectionizerPanel.types';

const TpaSettingsPanelFrame: AnyFixMe = panels.frames.TpaSettingsPanelFrame;

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

interface SectionizerPanelProps
  extends SectionizerPanelStateProps,
    SectionizerPanelDispatchProps,
    ComponentWithInitDataProps {}

interface SectionizerPanelState {
  highlighted: number[];
  data: SectionExtendedMetaData[];
  unsectionedComponents: SectionChildren[];
}

const initialState: SectionizerPanelState = {
  highlighted: [],
  data: [],
  unsectionedComponents: [],
};

const SECTION_NAME_TO_CONTENT_ROLE_MAP = SECTIONS_NAMES_WITH_ROLES.reduce(
  (
    acc: Record<string, string>,
    { name, role }: { name: string; role?: string },
  ) => {
    acc[name] = role || null;
    return acc;
  },
  {},
);

const sectionPlaceholder: SectionExtendedMetaData = {
  name: null,
  contentRole: DEFAULT_CONTENT_ROLE,
  children: [],
};

// TODO: reload when focused page changed
class SectionizerPanel extends React.Component<
  SectionizerPanelProps,
  SectionizerPanelState
> {
  state: SectionizerPanelState = initialState;

  public static getDerivedStateFromProps(
    nextProps: SectionizerPanelProps,
    prevState: SectionizerPanelState,
  ): SectionizerPanelState {
    if (nextProps.isInitialDataFetching) {
      return initialState;
    }

    const isDataExist = Boolean(prevState.data?.length);

    if (isDataExist) {
      return null;
    }

    const initialData = nextProps.initialData || [];

    const data = initialData.length
      ? initialData.map((section: GroupingItem) => ({
          contentRole: DEFAULT_CONTENT_ROLE,
          ...section,
          children: section.children.map((compId: string) => ({
            compId,
            displayName: nextProps.getComponentDisplayName(compId),
            icon: nextProps.getComponentIconInfo(compId),
          })),
        }))
      : [sectionPlaceholder];

    const unsectionedComponents = nextProps.components
      .filter(({ id: compId }: CompRef) =>
        initialData.every(
          (section: GroupingItem) => !section.children.includes(compId),
        ),
      )
      .map(({ id: compId }: CompRef) => ({
        compId,
        displayName: nextProps.getComponentDisplayName(compId),
        icon: nextProps.getComponentIconInfo(compId),
      }));

    return {
      highlighted: [],
      data,
      unsectionedComponents,
    };
  }

  collectDataToSave() {
    return this.state.data
      .map((section: SectionExtendedMetaData) => ({
        ...section,
        contentRole: SECTION_NAME_TO_CONTENT_ROLE_MAP[section.name] || '',
        children: section.children.map(({ compId }) => compId),
      }))
      .filter(({ children }: GroupingItem) => children.length);
  }

  validateDataBeforeSave() {
    if (this.state.unsectionedComponents.length) {
      throw ELEMENTS_BEHIND_ERROR;
    }

    const sectionWithoutName = this.state.data.reduce((acc, section, index) => {
      if (!section.name) {
        acc.push(index);
      }
      return acc;
    }, []);

    if (sectionWithoutName.length) {
      this.setState({ highlighted: sectionWithoutName });
      throw SECTION_NO_NAME_ERROR;
    }
  }

  onSave = () => {
    try {
      this.validateDataBeforeSave();

      const sections = this.collectDataToSave();

      this.props.save(sections);

      this.props.showSuccessMessage(SAVE_SUCCESS_MSG);
    } catch (e) {
      this.props.showErrorMessage(e);
    }
  };

  onSectionNameChange = (sectionIndex: number, sectionName: string) => {
    this.setState(({ data }) => {
      const nextData = data.map((section: SectionExtendedMetaData, index) =>
        index === sectionIndex
          ? {
              ...section,
              name: sectionName,
            }
          : section,
      );

      return {
        highlighted: [],
        data: nextData,
      };
    });
  };

  createAddSectionHandler =
    (index: number): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      e.stopPropagation();

      const nextData = [...this.state.data];

      nextData.splice(index, 0, sectionPlaceholder);

      this.setState({
        highlighted: [],
        data: nextData,
      });
    };

  createChangeSectionNameHandler =
    (index: number): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      e.stopPropagation();

      this.props.openSectionNamePanel({
        value: this.state.data[index]?.name,
        onSubmit: (name: string) => {
          this.onSectionNameChange(index, name);
          this.props.closeSectionNamePanel();
        },
      });
    };

  createRemoveSectionHandler =
    (index: number): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      e.stopPropagation();
      this.setState(({ data, unsectionedComponents }) => {
        const nextData = [...data];
        const nextUnsectionedComponents = [...unsectionedComponents];
        const removedSection = nextData[index];

        nextData.splice(index, 1);

        if (removedSection.children.length) {
          nextUnsectionedComponents.push(...removedSection.children);
        }

        return {
          data: nextData,
          unsectionedComponents: nextUnsectionedComponents,
        };
      });
    };

  createMoveComponentHandler =
    (
      fromSectionIndex: number,
      toSectionIndex: number,
      componentIndex: number,
    ): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      e.stopPropagation();

      const nextData = [...this.state.data];
      const fromSection = nextData[fromSectionIndex];
      const toSection = nextData[toSectionIndex];
      const component = fromSection.children[componentIndex];

      nextData.splice(fromSectionIndex, 1, {
        ...fromSection,
        children: fromSection.children.filter(
          ({ compId }) => compId !== component.compId,
        ),
      });

      nextData.splice(toSectionIndex, 1, {
        ...toSection,
        children:
          fromSectionIndex > toSectionIndex
            ? toSection.children.concat([component])
            : [component].concat(toSection.children),
      });

      this.setState({
        data: nextData,
      });
    };

  createAddComponentHandler =
    (component: SectionChildren): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      e.stopPropagation();

      const nextData = [...this.state.data];
      const toSectionIndex = nextData.length - 1;
      const toSection = nextData[toSectionIndex];

      nextData.splice(toSectionIndex, 1, {
        ...toSection,
        children: toSection.children.concat([component]),
      });

      this.setState({
        data: nextData,
        unsectionedComponents: this.state.unsectionedComponents.filter(
          ({ compId }) => compId !== component.compId,
        ),
      });
    };

  handleRefetch = () => {
    this.props.refetch();
  };

  render() {
    const { isInitialDataFetching, unlabeledPageTitles } = this.props;
    const { data, unsectionedComponents, highlighted } = this.state;

    return (
      <TpaSettingsPanelFrame
        title={'Sectionizer Panel'}
        width={288}
        height={520}
        ref="tpaSettingsFrame"
        top="30px"
        left="250px"
        onClose={this.props.close}
        panelName="sectionizer.panels.sectionizerPanel"
        className={styles.panel}
        shouldAddScroll={false}
      >
        <div className={styles.panelContent}>
          {isInitialDataFetching ? (
            <div className={styles.spinnerWrapper}>
              <Preloader />
            </div>
          ) : (
            <CustomScroll flex="1">
              <SectionsTree
                sections={data}
                sectionsRemoveAllowed={data.length > 1}
                createAddSectionHandler={this.createAddSectionHandler}
                createMoveComponentHandler={this.createMoveComponentHandler}
                createChangeSectionNameHandler={
                  this.createChangeSectionNameHandler
                }
                createRemoveSectionHandler={this.createRemoveSectionHandler}
                highlighted={highlighted}
              />
            </CustomScroll>
          )}

          {unsectionedComponents.length ? (
            <>
              <Divider className={styles.divider} long />
              <CustomScroll flex="1">
                <UnsectionedTree
                  components={unsectionedComponents}
                  createAddComponentHandler={this.createAddComponentHandler}
                />
              </CustomScroll>
            </>
          ) : null}

          <Divider className={styles.divider} long />

          <Composites.ActionSetVertical className={styles.actionButtonSet}>
            <Button onClick={this.onSave}>Done</Button>
          </Composites.ActionSetVertical>

          {unlabeledPageTitles.length > 0 ? (
            <AccordionSection
              // @ts-expect-error
              label={
                <Text skin="alert" shouldTranslate={false} size="small">
                  pages without labelling
                </Text>
              }
              shouldTranslate={false}
            >
              <RichText type="T04">
                <ul>
                  {unlabeledPageTitles.map((title) => (
                    <li>{title}</li>
                  ))}
                </ul>
              </RichText>
            </AccordionSection>
          ) : null}

          <CopyPasteBar
            copyEnabled={unlabeledPageTitles.length === 0}
            onAfterPaste={this.handleRefetch}
          />
        </div>
      </TpaSettingsPanelFrame>
    );
  }
}

export default _.flow(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
  withInitData,
)(SectionizerPanel);
