import React, { useState, useEffect, useRef } from 'react';
import {
  dimensionsCutRegex,
  endingWebpDoubleQuoteRegex,
  endingWebpSingleQuoteRegex,
  SCANNING_WIDTH,
} from '../addPresetConsts';
import experiment from 'experiment';
import {
  enrichHtmlWithThemeFontUrls,
  dimensionsReplacer,
  applyColorThemeInHtml,
  applyFontThemeInHtml,
} from '../addPresetUtil';
import * as util from '@/util';

import type { ThemeMap } from '../types';
import type HtmlPreviewThumbnailManager from './htmlPreviewThumbnailManager';

interface HTMLPreviewThumbnailProps {
  enableScrolling?: boolean;
  shouldOptimizeImages: boolean;
  containerWidth: number;
  shouldScaleOverIframe?: boolean;
  containerHeight?: React.CSSProperties['height'];
  onIframeReady?: () => void;
  themeMap?: ThemeMap;
  currentSiteThemeMap?: ThemeMap;
  fontsUrls: string[];
  thumbnailBorderRadius?: number;
  setHtmlString?: (htmlString: string) => void;
  previewHtmlManager: HtmlPreviewThumbnailManager;
  originalPresetWidth?: number;
}

const waitForImages = (
  frameDocument: Document,
  setComplete: (complete: boolean) => void,
  abortController: AbortController,
) => {
  if (!abortController?.signal.aborted) {
    const onImagesCompleted = () => {
      if (abortController?.signal.aborted) {
        return;
      }
      setComplete(true);
    };

    const checkImages = () => {
      const images: HTMLImageElement[] = Array.from(
        frameDocument.getElementsByTagName('img'),
      );
      const notLoadedImages = images.filter((img) => !img.complete);

      if (notLoadedImages.length === 0) {
        onImagesCompleted();
      } else {
        setTimeout(checkImages, 50);
      }
    };

    checkImages();
  }
};

function getIframeStyle(
  containerWidth: number,
  shouldScaleOverIframe: boolean,
): React.CSSProperties | undefined {
  if (shouldScaleOverIframe) {
    return {
      transformOrigin: '0 0',
      transform: `scale(${containerWidth / SCANNING_WIDTH})`,
    };
  }
}

const getBodyStyleReplaceStr = (
  containerWidth: number,
  enableScrolling: boolean,
  shouldScaleOverIframe: boolean,
  originalPreviewWidth: number,
) => {
  const scaleTransformStr = `
    transform-origin: 0 0;
    transform: scale(${containerWidth / originalPreviewWidth});
  `;
  return `<body style="margin: 0; ${
    shouldScaleOverIframe ? '' : scaleTransformStr
  } ${enableScrolling ? ' overflow-y: scroll;' : ''}"`;
};

interface EnrichPreviewHTMLParams {
  fullPreviewHtml: string;
  shouldOptimizeImages: boolean;
  containerWidth: number;
  enableScrolling: boolean;
  shouldScaleOverIframe: boolean;
  fontsUrls: string[];

  originalPresetWidth?: number;
}

function enrichPreviewHtmlString({
  fullPreviewHtml,
  shouldOptimizeImages,
  containerWidth,
  enableScrolling,
  shouldScaleOverIframe,
  fontsUrls,
  originalPresetWidth,
}: EnrichPreviewHTMLParams): string {
  let previewHtml = shouldOptimizeImages
    ? fullPreviewHtml.replace(dimensionsCutRegex, dimensionsReplacer)
    : fullPreviewHtml;

  previewHtml = previewHtml.replace(
    '<body',
    getBodyStyleReplaceStr(
      containerWidth,
      enableScrolling,
      shouldScaleOverIframe,
      originalPresetWidth || SCANNING_WIDTH,
    ),
  );
  previewHtml = enrichHtmlWithThemeFontUrls(previewHtml, fontsUrls);

  if (!util.browserUtil.isChrome()) {
    previewHtml = previewHtml
      .replace(endingWebpDoubleQuoteRegex, '.png"')
      .replace(endingWebpSingleQuoteRegex, ".png'");
  }
  return previewHtml;
}

const insertPrefiewHtmlToPreviewFrame = (
  previewHtml: string,
  frameDocument: Document,
  setComplete: (complete: boolean) => void,
  frameRef: HTMLIFrameElement,
  abortController?: AbortController,
) => {
  if (!util.browserUtil.isChrome()) {
    if (!util.browserUtil.isSafari()) {
      frameRef.srcdoc = previewHtml;
      setComplete(true);
      return;
    }
  }

  frameDocument.open();
  frameDocument.addEventListener('readystatechange', () => {
    if (frameDocument.readyState === 'complete') {
      waitForImages(frameDocument, setComplete, abortController);
    }
  });

  frameDocument.write(previewHtml);
  frameDocument.close();
};

export const HtmlPreviewThumbnail = ({
  fontsUrls,
  shouldOptimizeImages,
  enableScrolling,
  previewHtmlManager,
  containerWidth,
  shouldScaleOverIframe,
  containerHeight,
  themeMap,
  currentSiteThemeMap,
  onIframeReady,
  thumbnailBorderRadius,
  originalPresetWidth = SCANNING_WIDTH,
}: HTMLPreviewThumbnailProps) => {
  const [complete, setComplete] = useState(false);

  const frameRef = useRef<HTMLIFrameElement>(null);

  useEffect(
    () => {
      if (complete && onIframeReady) {
        onIframeReady();
      }
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [complete],
  );

  useEffect(
    () => {
      const frameDocument: Document = (frameRef.current as any).contentDocument;
      const abortController = new AbortController();
      if (previewHtmlManager.previewHtml) {
        const enrichedHtml = enrichPreviewHtmlString({
          fullPreviewHtml: previewHtmlManager.previewHtml,
          shouldOptimizeImages,
          containerWidth,
          enableScrolling,
          shouldScaleOverIframe,
          fontsUrls,
          originalPresetWidth,
        });
        insertPrefiewHtmlToPreviewFrame(
          enrichedHtml,
          frameDocument,
          setComplete,
          frameRef.current,
        );
      } else {
        previewHtmlManager.previewHtmlPromise.then((htmlFromFile: string) => {
          if (currentSiteThemeMap && themeMap) {
            htmlFromFile = applyColorThemeInHtml(
              htmlFromFile,
              themeMap.colors,
              currentSiteThemeMap.colors,
            );
            if (experiment.isOpen('se_presetsThemeFonts')) {
              htmlFromFile = applyFontThemeInHtml(
                htmlFromFile,
                themeMap.fonts,
                currentSiteThemeMap.fonts,
              );
            }
          }
          const enrichedHtml = enrichPreviewHtmlString({
            fullPreviewHtml: htmlFromFile,
            shouldOptimizeImages,
            containerWidth,
            enableScrolling,
            shouldScaleOverIframe,
            fontsUrls,
            originalPresetWidth,
          });
          insertPrefiewHtmlToPreviewFrame(
            enrichedHtml,
            frameDocument,
            setComplete,
            frameRef.current,
            abortController,
          );
        });
      }

      return () => {
        abortController?.abort();
      };
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [containerWidth],
  );

  return (
    <div
      style={{ borderRadius: thumbnailBorderRadius }}
      data-aid="html-preview-thumbnail"
      className={`html-preview-thumbnail${complete ? '' : ' loading'}`}
    >
      <iframe
        ref={frameRef as any}
        src="about:blank"
        width={originalPresetWidth}
        height={containerHeight || '100%'}
        scrolling={enableScrolling ? 'yes' : 'no'}
        style={getIframeStyle(containerWidth, shouldScaleOverIframe)}
      />
    </div>
  );
};
