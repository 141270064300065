import {
  WorkspaceModesApiKey,
  EditorRestrictionsApiKey,
  EditorAPIKey,
  EditorPermissionsApiKey,
} from '@/apis';
import * as actions from './api/actions/actions';
import * as symbols from './api/actions/symbols';
import pagesAPI from './api/api';
import pagesPanel from './panel/pagesPanel';
import quickNavigation from './quickNavigation/quickNavigation';
import pageCategories from './utils/pageCategories';
import { sendNavigateToPageBiEvent } from './utils';
import { getPagesMenuItemSymbol } from './utils/getPagesMenuItemSymbol';
import { createPagesApi } from './pagesWrapper';
import { PagesApiKey } from './publicApiKey';
import { DuplicatePageCodeConditionApiKey } from './duplicatePageCodeConditionApiKey';
import type { EntryPoint, Shell } from '@/apilib';
import type { IAppPage, IAppPageAction } from './api/appsPages';
import { createDuplicatePageCodeConditionApi } from './createDuplicatePageCodeConditionApi';
import { pagesReducer, type PagesState, TriggerSources } from './state';

export const PagesEntrypoint: EntryPoint = {
  name: 'PagesApi',
  declareAPIs: () => [PagesApiKey, DuplicatePageCodeConditionApiKey],
  getDependencyAPIs: () => [
    EditorAPIKey,
    EditorRestrictionsApiKey,
    EditorPermissionsApiKey,
    WorkspaceModesApiKey,
  ],
  attach(shell) {
    shell.contributeState<{ pages: PagesState }>(() => ({
      pages: pagesReducer,
    }));
    shell.contributeAPI(DuplicatePageCodeConditionApiKey, () =>
      createDuplicatePageCodeConditionApi(shell),
    );
    shell.contributeAPI(PagesApiKey, () => createPagesApi(shell));
  },
  async extend(shell: Shell) {
    const editorPermissionsApi = shell.getAPI(EditorPermissionsApiKey);
    const editorRestrictionsApi = shell.getAPI(EditorRestrictionsApiKey);

    editorRestrictionsApi.set(['pages_manage-pages-button.visible'], () =>
      editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN'),
    );
  },
};

export {
  actions,
  symbols,
  pagesAPI,
  pagesPanel,
  quickNavigation,
  pageCategories,
  getPagesMenuItemSymbol,
  sendNavigateToPageBiEvent,
  TriggerSources,
};

export type { IAppPage, IAppPageAction };
