// @ts-nocheck
import constants from '@/constants';
import * as helpIds from '@/helpIds';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  enabledActions: [ACTIONS.SETTINGS, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.SETTINGS, ACTIONS.HIDE, ACTIONS.HELP],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.FACEBOOK_COMMENTS,
    },
  },
};
