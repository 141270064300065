'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'design_section_label_explore_video',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/youTubeStyleSection_en/youTubeStyleSection_en.png',
        'imageHover': null,
        'items': [{
            'id': 'Video_YouTubeStyle_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.VideoSkin',
                'layout': {
                    'width': 480,
                    'height': 277,
                    'x': 30,
                    'y': 45,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.Video',
                'data': {
                    'type': 'Video',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'videoId': 'PMWmYf2043g',
                    'videoType': 'YOUTUBE'
                },
                'props': {
                    'type': 'VideoProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'autoplay': false,
                    'loop': false,
                    'showinfo': false,
                    'lightTheme': false,
                    'showControls': 'always_show'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'v1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.VideoSkin'
                },
                'id': 'i6thme9r_0'
            },
            'preset': {'rect': {'width': 162, 'height': 113, 'x': 0, 'y': 0}, 'label': ''}
        }, {
            'id': 'Video_YouTubeStyle_2',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.video.VideoDefault',
                'layout': {
                    'width': 480,
                    'height': 277,
                    'x': 719,
                    'y': 88,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.Video',
                'data': {
                    'type': 'Video',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'videoId': 'PMWmYf2043g',
                    'videoType': 'YOUTUBE'
                },
                'props': {
                    'type': 'VideoProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'autoplay': false,
                    'loop': false,
                    'showinfo': false,
                    'lightTheme': false,
                    'showControls': 'always_show'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-brd': '1',
                            'boxShadowToggleOn-shd': 'true',
                            'brd': '#000000',
                            'brw': '0px',
                            'rd': '0px',
                            'shd': '0px 2px 4px 0px rgba(0,0,0,0.65)'
                        },
                        'propertiesSource': {'brd': 'value', 'brw': 'value', 'rd': 'value', 'shd': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.Video',
                    'pageId': '',
                    'compId': 'i6thme9s',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.video.VideoDefault'
                },
                'id': 'i6thme9s'
            },
            'preset': {'rect': {'width': 162, 'height': 113, 'x': 162, 'y': 0}, 'label': ''}
        }, {
            'id': 'Video_YouTubeStyle_3',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.video.VideoDefault',
                'layout': {
                    'width': 480,
                    'height': 277,
                    'x': 75,
                    'y': 522,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.Video',
                'data': {
                    'type': 'Video',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'videoId': 'PMWmYf2043g',
                    'videoType': 'YOUTUBE'
                },
                'props': {
                    'type': 'VideoProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'autoplay': false,
                    'loop': false,
                    'showinfo': false,
                    'lightTheme': false,
                    'showControls': 'always_show'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-brd': '1',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#000000',
                            'brw': '4px',
                            'rd': '0px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {'brd': 'value', 'brw': 'value', 'rd': 'value', 'shd': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.Video',
                    'pageId': '',
                    'compId': 'i6thme9u',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.video.VideoDefault'
                },
                'id': 'i6thme9u'
            },
            'preset': {'rect': {'width': 162, 'height': 113, 'x': 0, 'y': 113}, 'label': ''}
        }, {
            'id': 'Video_YouTubeStyle_4',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.video.VideoLiftedShadow',
                'layout': {
                    'width': 480,
                    'height': 277,
                    'x': 726,
                    'y': 533,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.Video',
                'data': {
                    'type': 'Video',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'videoId': 'PMWmYf2043g',
                    'videoType': 'YOUTUBE'
                },
                'props': {
                    'type': 'VideoProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'autoplay': false,
                    'loop': false,
                    'showinfo': false,
                    'lightTheme': false,
                    'showControls': 'always_show'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-brd': '1',
                            'boxShadowToggleOn-shd': 'true',
                            'brd': '#000000',
                            'brw': '0px',
                            'rd': '0px',
                            'shd': '0 1px 4px rgba(0, 0, 0, 0.6);'
                        },
                        'propertiesSource': {'brd': 'value', 'brw': 'value', 'rd': 'value', 'shd': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.Video',
                    'pageId': '',
                    'compId': 'i6thme9t',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.video.VideoLiftedShadow'
                },
                'id': 'i6thme9t'
            },
            'preset': {'rect': {'width': 162, 'height': 113, 'x': 162, 'y': 113}, 'label': ''}
        }, {
            'id': 'Video_YouTubeStyle_5',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.video.VideoDefault',
                'layout': {
                    'width': 480,
                    'height': 277,
                    'x': 80,
                    'y': 946,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.Video',
                'data': {
                    'type': 'Video',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'videoId': 'PMWmYf2043g',
                    'videoType': 'YOUTUBE'
                },
                'props': {
                    'type': 'VideoProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'autoplay': false,
                    'loop': false,
                    'showinfo': false,
                    'lightTheme': false,
                    'showControls': 'always_show'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-brd': '1',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#3D9BE9',
                            'brw': '4px',
                            'rd': '0px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {'brd': 'value', 'brw': 'value', 'rd': 'value', 'shd': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.Video',
                    'pageId': '',
                    'compId': 'i6thme9u_0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.video.VideoDefault'
                },
                'id': 'i6thme9u_0'
            },
            'preset': {'rect': {'width': 162, 'height': 113, 'x': 0, 'y': 226}, 'label': ''}
        }, {
            'id': 'Video_YouTubeStyle_6',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.video.VideoDefault',
                'layout': {
                    'width': 480,
                    'height': 277,
                    'x': 736,
                    'y': 951,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.Video',
                'data': {
                    'type': 'Video',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'videoId': 'PMWmYf2043g',
                    'videoType': 'YOUTUBE'
                },
                'props': {
                    'type': 'VideoProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'autoplay': false,
                    'loop': false,
                    'showinfo': false,
                    'lightTheme': false,
                    'showControls': 'always_show'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-brd': '1',
                            'boxShadowToggleOn-shd': 'true',
                            'brd': '#3D9BE9',
                            'brw': '6px',
                            'rd': '0px',
                            'shd': '0px 2px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {'brd': 'value', 'brw': 'value', 'rd': 'value', 'shd': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.Video',
                    'pageId': '',
                    'compId': 'i6thme9u_1',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.video.VideoDefault'
                },
                'id': 'i6thme9u_1'
            },
            'preset': {'rect': {'width': 162, 'height': 113, 'x': 162, 'y': 226}, 'label': ''}
        }],
        'compTypes': ['wysiwyg.viewer.components.Video']
    }
}
