// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import { utils as themeUtils } from '@/theme';
import { translate } from '@/i18n';
import constants from './constants';
import React from 'react';
import * as baseUI from '@/baseUI';
import { CustomScroll } from '@wix/wix-base-ui';
import SiteCustomColors from './siteCustomColors';
import * as coreBi from '@/coreBi';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  valueLink: {
    value?: string;
    requestChange?: FunctionFixMe;
  };
  paletteColors: AnyFixMe;
  closeHandler: FunctionFixMe;
  onPreview: FunctionFixMe;
  onCancel: FunctionFixMe;
  siteCustomColors?: Array<string>;
  sendBI: (event: coreBi.BiEventDefinition, parameters: Object) => void;
}

class AddColorContent extends React.Component<Props> {
  static displayName = 'addColorContent';

  static propTypes = {
    valueLink: PropTypes.shape({
      value: PropTypes.string,
      requestChange: PropTypes.func,
    }).isRequired,
    paletteColors: PropTypes.object,
    closeHandler: PropTypes.func,
    onPreview: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    siteCustomColors: PropTypes.arrayOf(PropTypes.string),
    origin: PropTypes.string,
    sendBI: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.initialColor = util.valueLink.getValueFromProps(props);
    const hexValueFromProps = this.resolveColor(this.initialColor);
    const hsbValueFromProps = themeUtils.hexToHsbExact(hexValueFromProps);

    this.state = {
      selectedColor: hsbValueFromProps,
      isValid: true,
    };

    this.AUTOMATION_IDS = constants.AUTOMATION_IDS;
  }

  debouncedSendBI = _.debounce(
    (event: coreBi.BiEventDefinition, parameters: Object) => {
      this.props.sendBI(event, parameters);
    },
    200,
  );

  resolveColor = (color) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (_.includes(color, 'color')) {
      return this.props.paletteColors[color];
    } else if (!color || color === constants.NO_COLOR) {
      return '#ffffff'; //default color is white, if non/noColor-option provided
    }
    return color;
  };

  onHsbColorChanged = (hsbColor) => {
    this.debouncedSendBI(
      coreBi.events.colorPicker.COLOR_SPACE_ADD_NEW_COLOR_TINTS,
      {
        panelName: this.props.origin,
        value: themeUtils.hsbToHex(hsbColor),
      },
    );

    this.setState(
      {
        selectedColor: hsbColor,
      },
      function () {
        const hexColor = themeUtils.hsbToHex(hsbColor);
        this.props.onPreview(hexColor);
      },
    );
  };

  onCustomColorSelected = (hexColor) => {
    this.setState(
      {
        selectedColor: themeUtils.hexToHsbExact(hexColor),
      },
      function () {
        this.props.onPreview(hexColor);
      },
    );
  };

  resetToInitialColor = () => {
    const selectedColorHex = this.resolveColor(this.initialColor);
    this.setState(
      {
        selectedColor: themeUtils.hexToHsbExact(selectedColorHex),
      },
      function () {
        this.props.onPreview(this.initialColor);
      },
    );
  };

  previewColor = (hexColor) => {
    this.props.onPreview(hexColor);
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    if (!this.wasConfirmClicked) {
      this.props.onCancel();
    }
  }

  callOnChangeAndClose = () => {
    const isSameColors = themeUtils.isSameColors(
      this.resolveColor(this.initialColor),
      this.state.selectedColor,
    );

    this.props.sendBI(coreBi.events.colorPicker.COLOR_SPACE_ADD_NEW_COLOR_OK, {
      panelName: this.props.origin,
      isChanged: !isSameColors,
    });

    util.valueLink.callOnChangeIfExists(
      this.props,
      themeUtils.hsbToHex(this.state.selectedColor),
    );
    this.wasConfirmClicked = true;
    this.close();
  };

  onChangeInValidationStatus = (isValid) => {
    if (this.mounted) {
      this.setState({ isValid });
    }
  };

  handleCancel = () => {
    this.props.sendBI(
      coreBi.events.colorPicker.COLOR_SPACE_ADD_NEW_COLOR_CANCEL,
      {
        panelName: this.props.origin,
      },
    );

    this.props.onCancel();
    this.close();
  };

  close = () => {
    this.props.closeHandler?.();
  };

  render() {
    return (
      <div className="add-color-content">
        <div>
          <baseUI.colorSpace
            value={this.state.selectedColor}
            onChange={this.onHsbColorChanged}
          />
        </div>

        <div className="color-indicator">
          <span
            data-aid={this.AUTOMATION_IDS.COLOR_INDICATORS.INITIAL}
            onMouseDown={this.resetToInitialColor}
            style={{
              backgroundColor: this.resolveColor(this.initialColor),
            }}
            className="color-sample initial-color"
          />
          <span
            data-aid={this.AUTOMATION_IDS.COLOR_INDICATORS.CURRENT}
            style={{
              backgroundColor: themeUtils.hsbToHex(this.state.selectedColor),
            }}
            className="color-sample current-color"
          />
        </div>

        <div className="color-format">
          <baseUI.colorFormat
            value={this.state.selectedColor}
            onChange={this.onHsbColorChanged}
            onChangeInValidationStatus={this.onChangeInValidationStatus}
          />
        </div>

        {this.props.siteCustomColors ? (
          <div
            key="addColorCustomColorsSeperator"
            className="custom-colors-separator"
          />
        ) : null}

        {this.props.siteCustomColors ? (
          <div
            key="customColorsSectionTitle"
            className="custom-colors-section-title"
          >
            {translate('ColorPicker_MainPanel_MoreColors')}
          </div>
        ) : null}

        <CustomScroll>
          {this.props.siteCustomColors ? (
            <div key="customColorsSection" className="custom-colors-section">
              <SiteCustomColors
                onPreview={this.previewColor}
                customColors={this.props.siteCustomColors}
                paletteColors={this.props.paletteColors}
                value={themeUtils.hsbToHex(this.state.selectedColor)}
                onChange={this.onCustomColorSelected}
                origin={this.props.origin}
                className="custom-colors"
              />
            </div>
          ) : null}
        </CustomScroll>

        <div className="footer">
          <baseUI.button
            automationId={this.AUTOMATION_IDS.BTN.CLOSE}
            label="ColorPicker_ColorSpace_CancelButton"
            onClick={this.handleCancel}
            className="cancel-button btn-md btn-confirm-secondary"
          />
          <baseUI.button
            automationId={this.AUTOMATION_IDS.BTN.ADD}
            label="ColorPicker_ColorSpace_AddButton"
            onClick={this.callOnChangeAndClose}
            disabled={!this.state.isValid}
            className="confirm-button btn-md"
          />
        </div>
      </div>
    );
  }
}

export default AddColorContent;
