'use strict'

const pagesSection = require('../sections/page/pagesSection')
const pageStyleSection = require('../sections/page/pageStyleSection')
const addPagesSection = require('../sections/page/addPagesSection')
const adminPagesSection = require('../sections/page/adminPagesSection')

module.exports = {
    pagesSection,
    adminPagesSection,
    pageStyleSection,
    addPagesSection
}
