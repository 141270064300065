// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  items: Array<AnyFixMe>;
  showBullets?: boolean;
  autoPlay?: boolean;
  slideInterval?: number;
}

export default class extends React.Component<Props> {
  static displayName = 'innerImageGallery';

  static propTypes = {
    items: PropTypes.array.isRequired,
    showBullets: PropTypes.bool,
    autoPlay: PropTypes.bool,
    slideInterval: PropTypes.number,
  };

  static defaultProps = {
    showBullets: false,
    autoPlay: false,
    slideInterval: 4000,
  };

  state = {
    currentIndex: 0,
    containerWidth: 0,
  };

  componentDidMount() {
    if (this.props.autoPlay) {
      this.play();
    }
  }

  componentWillUnmount() {
    this.pause();
  }

  slideToIndex = (index, event) => {
    const slideCount = this.props.items.length - 1;

    if (index < 0) {
      this.setState({ currentIndex: slideCount });
    } else if (index > slideCount) {
      this.setState({ currentIndex: 0 });
    } else {
      this.setState({ currentIndex: index });
    }
    if (event) {
      if (this._intervalId) {
        this.pause();
        this.play();
      }
      event.preventDefault();
    }
  };

  play = () => {
    if (this._intervalId) {
      return;
    }
    this._intervalId = window.setInterval(
      function () {
        this.slideToIndex(this.state.currentIndex + 1);
      }.bind(this),
      this.props.slideInterval,
    );
  };

  pause = () => {
    if (this._intervalId) {
      window.clearInterval(this._intervalId);
      this._intervalId = null;
    }
  };

  _getAlignment = (index) => {
    const { currentIndex } = this.state;
    let alignment = '';
    switch (index) {
      case currentIndex - 1:
        alignment = 'left';
        break;
      case currentIndex:
        alignment = 'center';
        if (this.props.items.length <= 3) {
          alignment += ' relative';
        }
        break;
      case currentIndex + 1:
        alignment = 'right';
        break;
    }

    if (this.props.items.length >= 3) {
      if (index === 0 && currentIndex === this.props.items.length - 1) {
        alignment = 'right';
      } else if (index === this.props.items.length - 1 && currentIndex === 0) {
        alignment = 'left';
      }
    }

    return alignment;
  };

  render() {
    return (
      <div className="inner-image-gallery">
        <div className="gallery-viewport">
          <ul className="gallery-images">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
            {_.map(this.props.items, (image, imageIndex) => (
              <li className={`slide ${this._getAlignment(imageIndex)}`}>
                <img src={image} alt={``} />
              </li>
            ))}
          </ul>
        </div>

        {this.props.showBullets ? (
          <nav key="bullets" className="gallery-navigation-wrapper">
            <ul className="navigation-bullets">
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
              {_.map(this.props.items, (bullet, bulletIndex) => (
                <li
                  onClick={(event) => {
                    this.slideToIndex(bulletIndex, event);
                  }}
                  className={`bullet ${
                    this.state.currentIndex === bulletIndex ? 'active' : ''
                  }`}
                />
              ))}
            </ul>
          </nav>
        ) : null}
      </div>
    );
  }
}
