import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import _ from 'lodash';

type SelectionPredicateFunc = (
  component: CompRef,
  editorAPI: EditorAPI,
) => void;

export function createSelectionChangePlugin() {
  const callbacksByCompType: AnyFixMe = {};
  const callbacks: AnyFixMe = [];

  function register(
    compTypeOrPredicate: string | SelectionPredicateFunc,
    onSelect: (editorAPI: EditorAPI) => void,
    onDeselect?: (editorAPI: EditorAPI) => void,
  ) {
    if (_.isString(compTypeOrPredicate)) {
      callbacksByCompType[compTypeOrPredicate] = { onSelect, onDeselect };
    } else if (_.isFunction(compTypeOrPredicate)) {
      callbacks.push({
        predicate: compTypeOrPredicate,
        onSelect,
        onDeselect,
      });
    }
  }

  function unregister(compTypeOrPredicate: AnyFixMe) {
    if (_.isString(compTypeOrPredicate)) {
      delete callbacksByCompType[compTypeOrPredicate];
    } else {
      _.remove(callbacks, compTypeOrPredicate);
    }
  }

  function executePluginIfNeeded(
    editorAPI: EditorAPI,
    compRefs: CompRef[],
    cbName: string,
  ) {
    const compType = editorAPI.components.getType(compRefs);
    const compTypeCallbacks = callbacksByCompType[compType];
    if (compTypeCallbacks) {
      _.invoke(compTypeCallbacks, cbName, editorAPI);
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(callbacks, (item) => {
      if (item.predicate(compRefs, editorAPI)) {
        _.invoke(item, cbName, editorAPI);
      }
    });
  }

  function execute(
    editorAPI: EditorAPI,
    previousSelectedComps: CompRef[],
    currentSelectedComps: CompRef[],
  ) {
    if (!_.isEmpty(previousSelectedComps)) {
      executePluginIfNeeded(editorAPI, previousSelectedComps, 'onDeselect');
    }

    if (!_.isEmpty(currentSelectedComps)) {
      executePluginIfNeeded(editorAPI, currentSelectedComps, 'onSelect');
    }
  }

  return { register, unregister, execute };
}
