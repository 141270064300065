import * as React from 'react';
import { Text, TextButton } from '@wix/wix-base-ui';
import { AUTOMATION_IDS } from './constants';

export interface EmptyStateData {
  titleLabel: string;
  ctaLabel: string;
  onClick: () => void;
}

const NodeEmptyState: React.FC<EmptyStateData> = ({
  titleLabel,
  ctaLabel,
  onClick,
}) => {
  return (
    <div
      className="item-empty-state"
      data-hook={AUTOMATION_IDS.EMPTY_STATE.CONTAINER}
    >
      <Text size="small" skin="secondary" weight="thin" enableEllipsis={false}>
        {titleLabel}
      </Text>
      <TextButton
        size="small"
        skin="standard"
        weight="thin"
        onClick={onClick}
        dataHook={AUTOMATION_IDS.EMPTY_STATE.ADD_ELEMENT}
      >
        {ctaLabel}
      </TextButton>
    </div>
  );
};

export default NodeEmptyState;
