import _ from 'lodash';

import * as higherOrderComponents from '@/higherOrderComponents';

import connectWithEditor from '../util/connectWithEditor';
import codeDropPanelCorvidBanner from '../dropPanel/codeDropPanelCorvidBanner';

const topBarConnect =
  (mapStateToProps: AnyFixMe, mapDispatchToProps: AnyFixMe) =>
  (TopBarComponent: AnyFixMe) => {
    const ConnectedTopBar = _.flow(
      connectWithEditor(mapStateToProps, mapDispatchToProps),
      higherOrderComponents.noUpdateOnMouseMove,
    )(TopBarComponent);

    ConnectedTopBar.pure = TopBarComponent;
    ConnectedTopBar.responsiveEditorHooks = {
      codeDropPanelCorvidBanner,
      topBarMapper: { mapStateToProps, mapDispatchToProps },
    };

    return ConnectedTopBar;
  };

export default topBarConnect;
