export enum CAASMediaType {
  Image = 'image',
  Video = 'video',
  Clipart = 'clipart',
  Shape = 'shape',
  Icon = 'icon',
  Audio = 'audio',
  ExtVideo = 'extVideo',
  Document = 'document',
  Pattern = 'pattern',
}

export const caasPropNames = {
  TITLE: 'title',
  SUBTITLE: 'subTitle',
  LONG_TEXT: 'longText',
  MEDIA: 'media',
  BUTTON: 'button',
  HEADER: 'header',
  FOOTER: 'footer',
  LIST: 'list',
};

export const DEFAULT_NUMBER_OF_CONTENT_ITEMS = 9;
export const MAX_NUMBER_OF_LIST_CONTENT_ITEMS = 10;
export const DEFAULT_NUMBER_OF_MEDIA_ITEMS_PER_CATEGORY = 10;
