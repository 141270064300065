function buildList(data) {
  const result = data.map(buildValue);

  return {
    property_val_list: {
      value: result,
    },
  };
}

function buildMap(data, errorReporter) {
  const result: Record<string, any> = {};

  for (const key of Object.keys(data)) {
    result[key] = buildValue(data[key], errorReporter);
  }

  return {
    property_val_map: {
      map_val: result,
    },
  };
}

function buildPrimitive(data, errorReporter) {
  let key;
  if (typeof data === 'boolean') {
    key = 'bool_val';
  } else if (typeof data === 'string') {
    key = 'str_val';
  } else if (typeof data === 'number') {
    if (Number.isNaN(data)) {
      key = 'long_val';
    } else if (!Number.isNaN(data)) {
      key = 'double_val';
    }
    // eslint-disable-next-line lodash/prefer-is-nil
  } else if (data === null || data === undefined) {
    key = null;
  } else {
    const errorMessage = 'Invalid primitive value';
    if (errorReporter) {
      errorReporter.captureException(errorMessage, {
        tags: {
          composeProtoInputFlow: true,
        },
      });
    } else {
      window.console.error(errorMessage, data);
    }
  }

  return key
    ? {
        [key]: data,
      }
    : {};
}

function buildValue(data, errorReporter) {
  let value;

  if (Array.isArray(data)) {
    value = buildList(data);
  } else if (typeof data === 'object' && data !== null) {
    value = buildMap(data, errorReporter);
  } else {
    value = buildPrimitive(data, errorReporter);
  }

  return value;
}

export function composeProtoInput(data, errorReporter = null) {
  return buildValue(data, errorReporter);
}
