'use strict'

module.exports = {
    id: 'Columns_ColumnsContact_3',
    structure: {
        type: 'Container',
        components: [
            {
                type: 'Container',
                components: [
                    {
                        type: 'Component',
                        layout: {
                            width: 293,
                            height: 141,
                            x: 1,
                            y: 161,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<p class="font_8" style="font-size:15px; line-height:2.5em; text-align:center"><span style="display:none">&nbsp;</span><span style="font-size:14px"><span style="color:#FFFFFF"><span style="font-family:brandon-grot-w01-light,sans-serif">OPENING HOURS</span></span></span></p>\n\n<p class="font_8" style="font-size:14px; line-height:2.5em; text-align:center"><span style="font-size:14px"><span style="color:#BFBFBF"><span style="letter-spacing:0.1em"><span style="font-family:brandon-grot-w01-light,sans-serif">MONDAY - FRIDAY 11:00 - 18:30 SATURDAY 11:00 - 17:00 </span></span></span></span></p>\n\n<p class="font_8" style="font-size:14px; line-height:2.5em; text-align:center"><span style="font-size:14px"><span style="color:#BFBFBF"><span style="letter-spacing:0.1em"><span style="font-family:brandon-grot-w01-light,sans-serif">SUNDAY 12:30 - 16:30&nbsp;</span></span></span></span></p>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            layout: {
                                width: 253,
                                height: 276,
                                x: 33,
                                y: 58,
                                scale: 0.95,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<p class="font_8" style="font-size:15px; line-height:2.5em; text-align:center"><span style="display:none">&nbsp;</span><span style="font-size:14px"><span style="color:#FFFFFF"><span style="font-family:brandon-grot-w01-light,sans-serif">OPENING HOURS</span></span></span></p>\n\n<p class="font_8" style="font-size:14px; line-height:2.5em; text-align:center"><span style="font-size:14px"><span style="color:#BFBFBF"><span style="letter-spacing:0.1em"><span style="font-family:brandon-grot-w01-light,sans-serif">MONDAY - FRIDAY 11:00 - 18:30 SATURDAY 11:00 - 17:00 </span></span></span></span></p>\n\n<p class="font_8" style="font-size:14px; line-height:2.5em; text-align:center"><span style="font-size:14px"><span style="color:#BFBFBF"><span style="letter-spacing:0.1em"><span style="font-family:brandon-grot-w01-light,sans-serif">SUNDAY 12:30 - 16:30&nbsp;</span></span></span></span></p>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    }
                ],
                layout: {
                    width: 294,
                    height: 460,
                    x: -127,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.Column',
                props: {type: 'ColumnProperties', metaData: {schemaVersion: '1.0'}, alignment: 50},
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#000000',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 1
                    }
                },
                style: 'mc1',
                mobileStructure: {
                    type: 'Container',
                    layout: {
                        width: 320,
                        height: 392,
                        x: 0,
                        y: 323,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: 'wysiwyg.viewer.components.Column',
                    props: {
                        type: 'ColumnProperties',
                        metaData: {schemaVersion: '1.0'},
                        alignment: 50
                    },
                    design: {
                        type: 'MediaContainerDesignData',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        background: {
                            type: 'BackgroundMedia',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            color: '#000000',
                            colorOpacity: 1,
                            alignType: 'center',
                            fittingType: 'fill',
                            scrollType: 'none',
                            colorOverlay: '',
                            colorOverlayOpacity: 1
                        }
                    },
                    style: 'mc1'
                }
            },
            {
                type: 'Container',
                components: [
                    {
                        type: 'Component',
                        layout: {
                            width: 293,
                            height: 138,
                            x: 1,
                            y: 161,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<p class="font_8" style="font-size:14px; line-height:2.5em; text-align:center"><span style="font-weight:bold"><span style="color:#000000"><span style="font-size:14px"><span style="letter-spacing:0.05em"><span style="font-family:brandon-grot-w01-light,sans-serif">ADDRESS</span></span></span></span></span></p>\n\n<p class="font_8" style="font-size:14px; line-height:2.5em; text-align:center"><span style="color:#ABABAB"><span style="font-size:14px"><span style="letter-spacing:0.05em"><span style="font-family:brandon-grot-w01-light,sans-serif">500 TERRY FRANCOIS STREET</span></span></span></span></p>\n\n<p class="font_8" style="font-size:14px; line-height:2.5em; text-align:center"><span style="color:#ABABAB"><span style="font-size:14px"><span style="letter-spacing:0.05em"><span style="font-family:brandon-grot-w01-light,sans-serif">SAN FRANCISCO, CA 94158</span></span></span></span></p>\n\n<p class="font_8" style="font-size:14px; line-height:2.5em; text-align:center"><span style="color:#ABABAB"><span style="font-size:14px"><span style="letter-spacing:0.05em"><span style="font-family:brandon-grot-w01-light,sans-serif">INFO@MYSITE.COM | TEL.123-456-7890</span></span></span></span></p>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            layout: {
                                width: 280,
                                height: 207,
                                x: 20,
                                y: 58,
                                scale: 0.95,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<p class="font_8" style="font-size:14px; line-height:2.5em; text-align:center"><span style="font-weight:bold"><span style="color:#000000"><span style="font-size:14px"><span style="letter-spacing:0.05em"><span style="font-family:brandon-grot-w01-light,sans-serif">ADDRESS</span></span></span></span></span></p>\n\n<p class="font_8" style="font-size:14px; line-height:2.5em; text-align:center"><span style="color:#ABABAB"><span style="font-size:14px"><span style="letter-spacing:0.05em"><span style="font-family:brandon-grot-w01-light,sans-serif">500 TERRY FRANCOIS STREET</span></span></span></span></p>\n\n<p class="font_8" style="font-size:14px; line-height:2.5em; text-align:center"><span style="color:#ABABAB"><span style="font-size:14px"><span style="letter-spacing:0.05em"><span style="font-family:brandon-grot-w01-light,sans-serif">SAN FRANCISCO, CA 94158</span></span></span></span></p>\n\n<p class="font_8" style="font-size:14px; line-height:2.5em; text-align:center"><span style="color:#ABABAB"><span style="font-size:14px"><span style="letter-spacing:0.05em"><span style="font-family:brandon-grot-w01-light,sans-serif">INFO@MYSITE.COM | TEL.123-456-7890</span></span></span></span></p>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    }
                ],
                layout: {
                    width: 294,
                    height: 460,
                    x: 276,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.Column',
                props: {type: 'ColumnProperties', metaData: {schemaVersion: '1.0'}, alignment: 50},
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#FCFCFC',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                style: 'mc1',
                mobileStructure: {
                    type: 'Container',
                    layout: {
                        width: 320,
                        height: 323,
                        x: 0,
                        y: 0,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: 'wysiwyg.viewer.components.Column',
                    props: {
                        type: 'ColumnProperties',
                        metaData: {schemaVersion: '1.0'},
                        alignment: 50
                    },
                    design: {
                        type: 'MediaContainerDesignData',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        background: {
                            type: 'BackgroundMedia',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            color: '#FCFCFC',
                            colorOpacity: 1,
                            alignType: 'center',
                            fittingType: 'fill',
                            scrollType: 'none',
                            colorOverlay: '',
                            colorOverlayOpacity: 0
                        }
                    },
                    style: 'mc1'
                }
            },
            {
                type: 'Container',
                components: [],
                layout: {
                    width: 392,
                    height: 460,
                    x: 697,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.Column',
                props: {type: 'ColumnProperties', metaData: {schemaVersion: '1.0'}, alignment: 50},
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        mediaRef: {
                            type: 'Image',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            title: 'pexels-photo-62649.jpeg',
                            uri: '035244_0d3ca1294100489f8b93ad2783efc47f~mv2_d_5010_3340_s_4_2.jpeg',
                            description: 'private/allMedia_picture',
                            width: 5010,
                            height: 3340,
                            alt: '',
                            artist: {id: 'mobile_', name: ''}
                        },
                        color: '{color_16}',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                style: 'mc1',
                mobileStructure: {
                    type: 'Container',
                    layout: {
                        width: 320,
                        height: 200,
                        x: 0,
                        y: 715,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: 'wysiwyg.viewer.components.Column',
                    props: {
                        type: 'ColumnProperties',
                        metaData: {schemaVersion: '1.0'},
                        alignment: 50
                    },
                    design: {
                        type: 'MediaContainerDesignData',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        background: {
                            type: 'BackgroundMedia',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            mediaRef: {
                                type: 'Image',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                title: 'pexels-photo-62649.jpeg',
                                uri: '035244_0d3ca1294100489f8b93ad2783efc47f~mv2_d_5010_3340_s_4_2.jpeg',
                                description: 'private/allMedia_picture',
                                width: 5010,
                                height: 3340,
                                alt: '',
                                artist: {id: '', name: ''}
                            },
                            color: '{color_16}',
                            colorOpacity: 1,
                            alignType: 'center',
                            fittingType: 'fill',
                            scrollType: 'none',
                            colorOverlay: '',
                            colorOverlayOpacity: 0
                        }
                    },
                    style: 'mc1'
                }
            }
        ],
        layout: {
            width: 980,
            height: 460,
            x: 0,
            y: 1807,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
        props: {
            type: 'StripColumnsContainerProperties',
            metaData: {schemaVersion: '1.0'},
            fullWidth: true,
            columnsMargin: 0,
            frameMargin: 0,
            rowMargin: 0,
            siteMargin: 0
        },
        design: {
            type: 'MediaContainerDesignData',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            background: {
                type: 'BackgroundMedia',
                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                color: '{color_11}',
                colorOpacity: 0,
                alignType: 'center',
                fittingType: 'fill',
                scrollType: 'none',
                colorOverlay: '',
                colorOverlayOpacity: 0
            }
        },
        style: {
            type: 'TopLevelStyle',
            id: 'strc1',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            style: {groups: {}, properties: {}, propertiesSource: {}},
            componentClassName: 'wysiwyg.viewer.components.StripColumnsContainer',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer'
        },
        mobileStructure: {
            type: 'Container',
            layout: {
                width: 320,
                height: 915,
                x: 0,
                y: 1395,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false
            },
            componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
            props: {
                type: 'StripColumnsContainerProperties',
                metaData: {schemaVersion: '1.0'},
                fullWidth: true,
                columnsMargin: 0,
                frameMargin: 0,
                rowMargin: 0,
                siteMargin: 0
            },
            design: {
                type: 'MediaContainerDesignData',
                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                background: {
                    type: 'BackgroundMedia',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    color: '{color_11}',
                    colorOpacity: 0,
                    alignType: 'center',
                    fittingType: 'fill',
                    scrollType: 'none',
                    colorOverlay: '',
                    colorOverlayOpacity: 0
                }
            },
            style: 'strc1'
        },
        activeModes: {},
        id: 'comp-iowrn84m'
    },
    preset: {
        rect: {width: 324, height: 132, x: -0.8, y: 341.40000000000003},
        label: 'add_preset_columns_contact_3',
        tags: null
    }
}
