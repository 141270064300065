import React, { PureComponent } from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { Button, PromotionalList, TextLabel } from '@wix/wix-base-ui';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import type { SendBiFunction } from 'types/bi';

import PromotionalPanel from './promotionalPanel/promotionalPanel';
import type { SaveInteractionStartedSource } from 'types/fedops/saveInteraction';

const LIST_ITEMS = [
  translate('TOPBAR_UPGRADE_NEW_LINE_1'),
  translate('TOPBAR_UPGRADE_NEW_LINE_2'),
  translate('TOPBAR_UPGRADE_NEW_LINE_3'),
];

const CHECK_SYMBOL = React.createElement(symbols.symbol, { name: 'check' });

function template(this: AnyFixMe) {
  return (
    <div
      data-aid="drop-panel-upgrade"
      className="drop-panel-content default-upgrade-panel-content"
    >
      <div className="left-section-container">
        <div className="text-container">
          <TextLabel
            value={this.getTitle()}
            shouldTranslate={false}
            enableEllipsis={false}
            type="T09"
          />
          <PromotionalList
            items={LIST_ITEMS}
            symbol={CHECK_SYMBOL}
            shouldTranslate={false}
          />
        </div>
        <div className="symbol-container">
          {this.props.isActivePersonalSaleCampaign && (
            <img
              key="dynamicPromotionalImage"
              src={this.getPersonalSaleCampaignImageSrc()}
            />
          )}
          {!this.props.isActivePersonalSaleCampaign ? (
            <symbols.symbol
              key="defaultPromotionalImage"
              name="menubarUpgradePromotionDark"
            />
          ) : null}
        </div>
      </div>

      <div className="footer-container">
        {this.props.isFirstSave || this.props.isDraftMode ? (
          <div key="firstSaveFooter" className="first-save-footer">
            <TextLabel
              value="TOPBAR_UPGRADE_SAVE_FIRST_TEXT"
              type="T07"
              className="comment"
            />
            <Button
              automationId="upgrade-btn-first-save"
              onClick={this.handleSaveButtonClick}
              className="btn-md light-purple"
            >
              <span>{translate('TOPBAR_UPGRADE_BUTTON_SAVE_SITE')}</span>
            </Button>
          </div>
        ) : null}
        {!this.props.isFirstSave && !this.props.isDraftMode ? (
          <div key="regularFooter" className="regular-footer">
            <Button
              automationId="upgrade-btn-compare-plans"
              onClick={this.onComparePlansButtonClick}
              className="btn-md light-purple"
            >
              <span>{translate('TOPBAR_UPGRADE_FREE_ROW_BUTTON_1')}</span>
            </Button>
            <Button
              automationId="upgrade-btn-get-yearly-unlimited"
              onClick={this.handleGetYearlyButtonClick}
              className="btn-md dark-purple"
            >
              <span>{this.getOfferButtonText()}</span>
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

interface DefaultUpgradePanelProps {
  isFirstSave: boolean;
  isDraftMode: boolean;
  isActivePersonalSaleCampaign: boolean;
  personalSaleCampaignDiscountValue: number;
  getPersonalSaleTranslatedLabel: (key: string) => string;
  getPersonalSaleCampaignSymbolName: (key: string) => string;
  save: (options: {
    origin?: string;
    sourceOfStart: SaveInteractionStartedSource;
  }) => void;
  sendBi: SendBiFunction;
  accountUpgrade: (referralCode: string) => void;
  closePanel: () => void;
}

class DefaultUpgradePanel extends PureComponent<DefaultUpgradePanelProps> {
  state = {
    contentHeight: 0,
  };

  componentDidMount() {
    const offering_val = this.props.isActivePersonalSaleCampaign
      ? 'TOP_BAR_UPGRADE_MENU_EDITOR_DEFAULT_SALE_OFFERING'
      : 'TOP_BAR_UPGRADE_MENU_EDITOR_DEFAULT';
    const offering_type =
      this.props.isFirstSave || this.props.isDraftMode
        ? 'TOP_BAR_UPGRADE_MENU_EDITOR_SAVE_SITE'
        : offering_val;
    this.props.sendBi(coreBi.events.topbar.upgrade_topbar_open, {
      type: 'editor',
      offering_type,
    });
  }

  myRef = React.createRef<HTMLDivElement>();

  getTitle = () => {
    if (this.props.isActivePersonalSaleCampaign) {
      return this.props.getPersonalSaleTranslatedLabel(
        'TOPBAR_UPGRADE_POPUP_PERSONAL_SALE_LABEL_DYNAMIC',
      );
    }

    return translate('TOPBAR_UPGRADE_NEW_TITLE');
  };

  getSymbol = () => {
    return this.props.isActivePersonalSaleCampaign ? (
      <img src={this.getPersonalSaleCampaignImageSrc()} />
    ) : (
      <symbols.symbol name="menubarUpgradePromotion_NewWorkspace" />
    );
  };

  getOfferButtonText = () => {
    if (this.props.isActivePersonalSaleCampaign) {
      return this.props.getPersonalSaleTranslatedLabel(
        'TOPBAR_UPGRADE_POPUP_PERSONAL_SALE_BUTTON_DYNAMIC',
      );
    }

    return translate('TOPBAR_UPGRADE_NEW_BUTTON_2');
  };

  getPersonalSaleCampaignImageSrc = () => {
    const filename = this.props.getPersonalSaleCampaignSymbolName(
      'menubarUpgradePanel',
    );

    return util.media.getMediaUrl(`rEditor/topBar/${filename}.png`);
  };

  handleSaveButtonClick = () => {
    this.props.save({ sourceOfStart: 'defaultUpgradeButton_confirmBtn' });
    this.props.sendBi(coreBi.events.topbar.click_save_upgrade_tooltip_topbar);
    this.props.closePanel();
  };

  handleGetYearlyButtonClick = () => {
    const upgradeReferralCode = this.props.isActivePersonalSaleCampaign
      ? 'edhtml_TOP_BAR_UPGRADE_MENU_PS'
      : 'TOP_BAR_UPGRADE_MENU_UPGRADE_NOW';

    this.props.sendBi(coreBi.events.topbar.top_bar_get_yearly_unlimited_click);
    this.props.accountUpgrade(upgradeReferralCode);
  };

  onComparePlansButtonClick = () => {
    this.props.sendBi(coreBi.events.topbar.top_bar_compare_plans_click);
    this.props.accountUpgrade('edhtml_TOP_BAR_UPGRADE_MENU_COMPARE_PLANS');
  };

  renderFooter = () => {
    if (this.props.isFirstSave || this.props.isDraftMode) {
      return (
        <>
          <TextLabel
            value={translate('TOPBAR_UPGRADE_SAVE_FIRST_TEXT')}
            shouldTranslate={false}
            type="T07"
            className="footer-comment"
          />
          <Button
            automationId="upgrade-btn-first-save"
            onClick={this.handleSaveButtonClick}
            className="btn-confirm-secondary"
          >
            <span>{translate('TOPBAR_UPGRADE_BUTTON_SAVE_SITE')}</span>
          </Button>
        </>
      );
    }

    return (
      <>
        <Button
          automationId="upgrade-btn-compare-plans"
          onClick={this.onComparePlansButtonClick}
          className="btn-confirm-secondary"
        >
          <span>{translate('TOPBAR_UPGRADE_FREE_ROW_BUTTON_1')}</span>
        </Button>
        <Button
          automationId="upgrade-btn-get-yearly-unlimited"
          onClick={this.handleGetYearlyButtonClick}
        >
          <span>{this.getOfferButtonText()}</span>
        </Button>
      </>
    );
  };

  setListHeight = (height: number) => {
    this.setState({
      contentHeight: height,
    });
  };

  render() {
    if (util.workspace.isNewWorkspaceEnabled()) {
      return (
        <div ref={this.myRef}>
          <PromotionalPanel
            setListHeight={this.setListHeight}
            automationId="drop-panel-upgrade"
            className={util.cx('upgrade-promotional-panel', {
              'new-upgrade-content':
                !this.props.isActivePersonalSaleCampaign &&
                this.state.contentHeight < 90,
            })}
            themeName="premium"
            title={this.getTitle()}
            listItems={LIST_ITEMS}
            symbol={this.getSymbol()}
            renderFooter={this.renderFooter}
          />
        </div>
      );
    }

    return template.call(this);
  }
}

export default DefaultUpgradePanel;
