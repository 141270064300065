// @ts-nocheck
import ReactDOM from 'reactDOM';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as core from '@/core';
import addPanelDataConsts from '@/addPanelDataConsts';
import presetItemVideoMixin from './presetItemVideoMixin';
import preventDoubleClickMixin from '../mixins/preventDoubleClickMixin';
import * as imageFallbackMixin from '../mixins/imageFallbackMixin';
import React from 'react';
import * as baseUI from '@/baseUI';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '@/util';

const { mediaManagerPresetUtil } = core.utils;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'presetItem',
  mixins: [
    core.mixins.editorAPIMixin,
    presetItemVideoMixin,
    preventDoubleClickMixin,
    imageFallbackMixin,
  ],
  propTypes: {
    preset: PropTypes.shape({
      rect: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
      }).isRequired,
      label: PropTypes.string,
      video: PropTypes.shape({
        poster: PropTypes.string,
        source: PropTypes.string.isRequired,
      }),
    }).isRequired,
    labelMode: PropTypes.oneOf([
      addPanelDataConsts.LABEL_BEHAVIOUR.ALWAYS,
      addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
      addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    ]).isRequired,
    infoIcon: PropTypes.oneOfType([
      PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
      }),
      PropTypes.bool,
    ]),
    symbolName: PropTypes.string,
    image: PropTypes.string,
    imageHover: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func,
    id: PropTypes.string.isRequired,
    structure: PropTypes.object.isRequired,
    ignoreStyleMutations: PropTypes.bool,
    sectionTitle: PropTypes.string.isRequired,
    appDefinitionId: PropTypes.string,
    overrideClass: PropTypes.string,
  },
  getInitialState() {
    return {
      hover: false,
    };
  },
  onClick(e) {
    if (this.isAnotherMouseActionAllowed(e)) {
      const editorAPI = this.getEditorAPI();
      mediaManagerPresetUtil.storePath(
        editorAPI,
        this.props.structure,
        this.props.mediaManager || this.props.preset.mediaManager,
      );
      const eventOrigin = _.pick(e, ['clientX', 'clientY']);
      this.props
        .onClick(
          this.getItemData(),
          this.props.sectionTitle,
          this.props.preset.tags || '',
          this.props.id,
          editorAPI,
          {
            appDefinitionId: this.props.appDefinitionId,
            eventOrigin,
            addComponentOptions: {
              position: this.props.itemHoverArg.itemIndex,
              section: translate(this.props.sectionTitle),
              category: this.props.categoryId,
            },
            position: this.props.getPastePosition
              ? this.props.getPastePosition(this.props.structure)
              : undefined,
          },
        )
        ?.then((compRef) => {
          if (this.props.onComponentAddedToStage) {
            this.props.onComponentAddedToStage(compRef, this.props.structure);
          }
        });
    }
    this.setClickTimeStamp(e);
  },
  onMouseEnter(callback) {
    if (this.hasVideo()) {
      this.playVideo();
    } else if (this.props.onMouseEnter) {
      this.props.onMouseEnter(this.props.structure, this.props.id);
    } else {
      this.setState({ hover: true }, callback);
    }
    if (this.props.itemHoverCallback) {
      this.props.itemHoverCallback(this.props.itemHoverArg);
    }
  },
  onMouseLeave(callback) {
    if (this.hasVideo()) {
      this.pauseVideo();
    } else if (this.props.onMouseLeave) {
      this.props.onMouseLeave(this.props.id);
    } else {
      this.setState({ hover: false }, callback);
    }
  },
  onMouseDown(e) {
    e.preventDefault();
    if (
      !_.isFunction(this.props.onItemDrag) ||
      !this.isAnotherMouseActionAllowed(e)
    ) {
      return;
    }

    this.props.onItemDrag(e, {
      rect: this.getDOMDimensions(),
      structure: this.getItemData(),
      mediaManager: this.props.mediaManager || this.props.preset.mediaManager,
      section: 'preset',
      appDefinitionId: this.props.appDefinitionId,
      sectionTitle: this.props.sectionTitle,
      categoryId: this.props.categoryId,
      itemId: this.props.id,
      onDrop: this.props.onDrop,
      tags: this.props.preset.tags,
    });
  },
  getDOMDimensions() {
    return _.pick(ReactDOM.findDOMNode(this).getBoundingClientRect(), [
      'left',
      'top',
      'width',
      'height',
    ]);
  },
  getItemStyle() {
    const { rect } = this.props.preset;
    let objStyle = { width: rect.width };
    if (!this.props.liveTextToShow) {
      objStyle = {
        left: rect.x,
        top: rect.y,
        width: rect.width,
        height: rect.height,
      };
    }
    if (this.state.hover) {
      objStyle.backgroundPosition = `${-1 * rect.x}px ${-1 * rect.y}px`;
      objStyle.backgroundRepeat = 'no-repeat';
      if (
        this.props.hoverImageAction ===
        addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE
      ) {
        objStyle.backgroundImage = `url('${util.media.getMediaUrl(
          this.props.imageHover,
          this.getFallbackImage(this.props.imageHover),
        )}')`;
      }
    } else {
      objStyle.background = '';
    }
    return objStyle;
  },
  getItemData() {
    const structureProp = _.cloneDeep(this.props.structure);
    if (
      !this.props.ignoreStyleMutations &&
      (!structureProp.style || !_.isString(structureProp.style))
    ) {
      structureProp.style =
        structureProp.styleId ||
        (structureProp.style.styleType === 'system'
          ? structureProp.style.id
          : structureProp.style);
    }
    return structureProp;
  },
  getClassSet() {
    const classSet = {
      'preset-item': true /*,
             'shouldScaleOnHover': this.props.hoverImageAction === addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE || this.props.hoverImageAction === addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE*/,
    };

    if (this.props.className) {
      classSet[this.props.className] = true;
    }

    if (this.props.overrideClass) {
      classSet[this.props.overrideClass] = true;
    }

    return classSet;
  },
  hasInfoIcon() {
    return !!this.props.infoIcon;
  },
  getHelp() {
    if (this.getEditorAPI()) {
      return this.getEditorAPI().panelManager.openHelpCenter;
    }
    return '';
  },
  getInfoIcon() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(
      {
        width: addPanelDataConsts.CATEGORY_VIEW.TOTAL_WIDTH,
        openHelp: this.getHelp(),
      },
      this.props.infoIcon,
    );
  },
  showLabel() {
    return this.props.labelMode !== addPanelDataConsts.LABEL_BEHAVIOUR.NONE;
  },
  getLiveTextStyle() {
    const editorAPI = this.getEditorAPI();
    const { themeName } = this.props;
    const themeMap = editorAPI.dsRead.theme.fonts.getMap();
    const fontSizes = _(themeMap)
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      .reject((value, key) => _.includes(['font_1', 'font_10'], key))
      .map((theme) => _.parseInt(theme.size))
      .sort()
      .value();

    function normalizeFontSize(currentFontSize) {
      const minX = _.min(fontSizes);
      const maxX = _.max(fontSizes);
      const minFontSize = 14;
      const maxFontSize = 36;
      const diff = maxX - minX || 1;
      return _.ceil(
        minFontSize +
          ((currentFontSize - minX) * (maxFontSize - minFontSize)) / diff,
      );
    }

    const style = _.get(themeMap, themeName);
    const nameStyle = {
      fontWeight: style.weight,
      fontFamily: `${style.family},${style.fontWithFallbacks}`,
      fontSize: normalizeFontSize(parseInt(style.size, 10)),
      fontStyle: style.style,
    };
    return nameStyle;
  },
  render() {
    return (
      <div
        ref={this.props.id}
        draggable="false"
        id={this.props.id}
        data-comp={this.props.structure.componentType}
        style={this.getItemStyle()}
        onClick={this.onClick}
        onMouseDown={this.onMouseDown}
        onMouseEnter={() => {
          this.onMouseEnter();
        }}
        onMouseLeave={() => {
          this.onMouseLeave();
        }}
        data-hook="preset-item"
        className={cx(this.getClassSet())}
      >
        {this.hasVideo() ? (
          <video
            id={this.props.id}
            ref="video"
            muted
            key="video"
            width={this.props.preset.rect.width}
            height={this.props.preset.rect.height}
            loop
            className={`video ${!this.state.videoPlaying ? 'videoPaused' : ''}`}
          />
        ) : null}
        {this.props.liveTextToShow ? (
          <div key="live-text">
            <span style={this.getLiveTextStyle()}>
              {translate(this.props.liveTextToShow)}
            </span>
          </div>
        ) : null}
        {this.showLabel() ? (
          <label
            key="label"
            className={`preset-item-label ${this.props.labelMode} ${
              this.props.preset.labelClasses || ''
            }`}
          >
            {translate(this.props.preset.label)}
          </label>
        ) : null}

        {this.hasInfoIcon() ? (
          <div
            key="infoIcon"
            className={cx({
              'info-wrapper': true,
              'has-label': this.props.labelMode !== 'none',
            })}
          >
            <baseUI.infoIcon {...this.getInfoIcon()} />
          </div>
        ) : null}
        {this.props.symbolName ? (
          <symbols.symbol key="symbolKey" name={this.props.symbolName} />
        ) : null}
        {this.props.text ? (
          <div
            key="itemText"
            style={{ lineHeight: `${this.props.preset.rect.height}px` }}
          >
            <span>{translate(this.props.text)}</span>
          </div>
        ) : null}
      </div>
    );
  },
});
