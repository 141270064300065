import { selection } from '@/stateManagement';
import { sections } from '@/util';
import constants from '@/constants';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { ComponentEditorMetaDataDefinition } from '../types';

const headerMetadata: ComponentEditorMetaDataDefinition = {
  pinnable: false,
  showLegacyFixedPosition: false,
  skipInQuickEditPanel: true,
  showMarginsIndicator: () => sections.isSectionsEnabled(),
  focusable: () => sections.isSectionsEnabled(),
  selectedBeforeDescendants: (editorAPI: EditorAPI, headerRef: CompRef) => {
    if (!sections.isSectionsEnabled()) {
      return false;
    }
    const isRightClick = selection.selectors.isLastClickTypeRight(
      editorAPI.store.getState(),
    );
    const isStageZoomMode = editorAPI.zoomMode.isStageZoomMode();
    const [selectedComp] = editorAPI.selection.getSelectedComponents();
    const isHeaderSelected = editorAPI.utils.isSameRef(selectedComp, headerRef);

    if (isRightClick) {
      return isStageZoomMode || !isHeaderSelected;
    }

    return sections.isSectionsEnabled() && !isHeaderSelected;
  },
  disabledKnobs: () =>
    sections.isSectionsEnabled()
      ? Object.values(constants.RESIZE_SIDES)
      : undefined,
  gfppVisible: (editorAPI: EditorAPI, headerRef: CompRef) => {
    if (!sections.isSectionsEnabled()) return true;

    if (editorAPI.sections.isFirstTimeParentSectionLikeFocused(headerRef))
      return false;

    const lastClickPos = selection.selectors.getLastSelectionClickPos(
      editorAPI.store.getState(),
    );
    const topComp = sections.getTopComponentByXY(editorAPI, lastClickPos);
    return editorAPI.utils.isSameRef(headerRef, topComp);
  },
};

export default headerMetadata;
