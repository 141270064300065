// @ts-nocheck
import React from 'react';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import * as helpIds from '@/helpIds';
import { translate } from '@/i18n';
import * as panels from '@/panels';
import * as leftBar from '@/leftBar';
import * as appMarketUtils from '../appMarket/utils/appMarketUtils';
import * as appMarketHandlers from '../appMarket/appMarketHandlers';
import * as bi from '../bi/bi';
import { AppsPanelSectionsApiKey } from '@/appsPanelSections';
import { VerticalTabs } from '@wix/wix-base-ui';

const APP_MARKET_ORIGIN_PARAM = '&origin=editor';
const { getPreviewPosition } = stateManagement.domMeasurements.selectors;

function getAppMarketUrl(editorAPI, urlParamsProps) {
  let urlParams = {};
  if (urlParamsProps.openMarketOrigin === 'market_button') {
    urlParams = appMarketUtils.getAppMarketUrlParams();
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  _.assign(urlParams, urlParamsProps);
  urlParams.origin = appMarketUtils.origin();
  urlParams.newUrl = true;
  urlParams.state =
    appMarketUtils.getAppMarketSessionUserPrefs(editorAPI)?.state ?? '';
  const appMarketURL = editorAPI.dsRead.tpa.appMarket.getUrl(urlParams);
  return appMarketURL + APP_MARKET_ORIGIN_PARAM;
}

const getEditorAPI = (dispatch, getState, { editorAPI }) => editorAPI;

function mapDispatchToProps(dispatch) {
  const editorAPI = dispatch(getEditorAPI);

  return {
    appMarketHandlers: {
      closeAppMarket: () => appMarketHandlers.closeAppMarket(editorAPI),
      openAppMarketHelp: () => appMarketUtils.openAppMarketHelp(editorAPI),
      openBMInEditor: (path) =>
        appMarketHandlers.openBMInEditor(editorAPI, path),
      getUserDetails: (callback) =>
        appMarketHandlers.getUserDetails(editorAPI, {}, callback),
      getPendingAppsList: (callback) =>
        appMarketHandlers.getPendingAppsList(editorAPI, {}, callback),
      addApp: (options) => appMarketHandlers.addApp(editorAPI, options),
      appIsInstalled: (options, callback) =>
        appMarketHandlers.appIsInstalled(editorAPI, options, callback),
      removePendingApp: (options) =>
        appMarketHandlers.removePendingApp(editorAPI, options),
      uninstallPlatformApp: (appDefinitionId) =>
        appMarketHandlers.uninstallPlatformApp(editorAPI, appDefinitionId),
      addPendingApp: (options) =>
        appMarketHandlers.addPendingApp(editorAPI, options),
      save: (options, callback) =>
        appMarketHandlers.save(editorAPI, options, callback),
      deleteApp: (options, callback) =>
        appMarketHandlers.deleteApp(editorAPI, options, callback),
      openApp: (options) => appMarketHandlers.openApp(editorAPI, options),
      updateApp: (options, callback) =>
        appMarketHandlers.updateApp(editorAPI, options, callback),
      setAppMarketState: (options) =>
        appMarketHandlers.setAppMarketState(editorAPI, options),
      isDevMode: (options, callback) =>
        appMarketHandlers.isDevMode(editorAPI, options, callback),
      getDataByAppDefId: (appDefinitionId) =>
        editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId),
      getUserGeo: () => editorAPI.dsRead.generalInfo.getGeo(),
      showUserActionNotification: (options) =>
        editorAPI.showUserActionNotification(options),
    },
  };
}

// eslint-disable-next-line react/prefer-es6-class
const AppMarketPanel = createReactClass({
  displayName: 'AppMarketPanel',
  propTypes: {
    urlParams: PropTypes.shape({
      openMarketOrigin: PropTypes.string.isRequired, //BI - will be sent as referralInfo
      referralInfoCategory: PropTypes.string, //BI - will be sent as referralInfoCategory
      section: PropTypes.string, //BI - at the moment can only be related apps
      openAppDefId: PropTypes.string, //used to open app market with specific app
      appDefinitionId: PropTypes.string, //used to open app market with developer apps section on the specified app
      categorySlug: PropTypes.string, //from editor url - used to open app market on specific category
      query: PropTypes.string, //from editor url - used to open app market with search term
      tags: PropTypes.string,
      tests: PropTypes.string,
      appMarketParams: PropTypes.string,
      slug: PropTypes.string,
      marketRoute: PropTypes.string,
    }).isRequired,
    helpId: PropTypes.string.isRequired,
  },
  mixins: [core.mixins.editorAPIMixin],
  getInitialState() {
    const appsPanelSections = [
      {
        sectionId: 'app-market-section',
        label: translate('AddApps__LeftPanel__AppMarket'),
        headerText: translate('app_market_title'),
        component: () => {
          const Marketplace = this.marketplaceClass;
          return <Marketplace {...this.marketPlaceProps} />;
        },
      },
      ...this.props.contributedSections,
    ];
    return {
      appsPanelSections,
      appMarketUrl: getAppMarketUrl(this.getEditorAPI(), this.props.urlParams),
      selectedSection: appsPanelSections[0],
      loading: true,
    };
  },
  connectMarketplacePanel(panel) {
    const Connected = util.hoc.connect(
      util.hoc.STORES.EDITOR_API,
      {},
      mapDispatchToProps,
    )(panel);
    Connected.pure = panel;
    return Connected;
  },
  getNewAppMarket() {
    if (!this.marketplaceClass) {
      import('marketplace').then((marketplace) => {
        if (marketplace) {
          const marketplaceStaticsUrl =
            util.serviceTopology.scriptsLocationMap['marketplace-component'];
          const manageInstalledAppsUrl =
            util.serviceTopology.scriptsLocationMap['manage-installed-apps'];
          this.marketPlaceProps = {
            marketplaceStaticsUrl,
            metaSiteId: this.getEditorAPI().dsRead.generalInfo.getMetaSiteId(),
            locale: util.languages.getLanguageCode(),
            config: {
              topology: {
                marketplaceStaticsUrl,
                manageInstalledAppsUrl,
              },
            },
            isAppManagerOpen: true,
            routeBaseName: '',
            appMarketParams: this.props.urlParams.appMarketParams,
            openAppDefId: this.props.urlParams.openAppDefId,
            slug: this.props.urlParams.slug,
            openMarketOrigin: this.props.urlParams.openMarketOrigin,
            marketRoute: this.props.urlParams.marketRoute,
          };
          this.marketplaceClass = this.connectMarketplacePanel(
            marketplace.Marketplace.marketplace,
          );
          this.setState({ loading: false });
        }
      });
    }
  },
  getDefaultProps() {
    return {
      panelName: 'tpa.compPanels.appMarketPanel',
      helpId: helpIds.EXTERNAL.APP_MARKET,
    };
  },
  timeMarketOpened: undefined,
  openMarketOrigin: undefined,
  getTime() {
    return Date.now();
  },
  componentDidMount() {
    this.timeMarketOpened = this.getTime();
    this.openMarketOrigin =
      this.props.urlParams && this.props.urlParams.openMarketOrigin;
    this.getNewAppMarket();

    if (this.props.selectedSection?.id) {
      this.onSectionChanged(this.props.selectedSection?.id);
    }
  },
  componentWillUnmount() {
    const params = {
      open_origin: this.openMarketOrigin,
      seconds_opened: parseInt(
        (this.getTime() - this.timeMarketOpened) / 1000,
        10,
      ),
    };
    this.getEditorAPI().bi.event(bi.events.MARKET_CLOSED, params);
  },
  getPanelProps() {
    return {
      urlParams: this.props.urlParams,
      onHelpClicked: this.onHelpClicked,
      helpId: this.state?.selectedSection?.helpId || this.props.helpId,
    };
  },
  getResponsiveWidth() {
    const state = this.getEditorAPI().store.getState();
    const previewWidth = getPreviewPosition(state).width;
    let containerWidth;

    if (util.workspace.isNewWorkspaceEnabled()) {
      const MIN_WIDTH = 1200;
      const MAX_WIDTH =
        1600 + (this.props.contributedSections?.length > 0 ? 150 : 0);
      const width = previewWidth * 0.96;

      containerWidth = Math.min(Math.max(width, MIN_WIDTH), MAX_WIDTH);
    } else {
      const MARGIN_WIDTH = 32;
      const LEFT_PANEL_MARGIN = 65;

      containerWidth = previewWidth - MARGIN_WIDTH - LEFT_PANEL_MARGIN;
    }

    return {
      width: `${containerWidth}px`,
    };
  },
  onHelpClicked() {
    const editorAPI = this.getEditorAPI();
    editorAPI.bi.event(bi.events.MARKET_HELP_CLICK);
  },
  onSectionChanged(sectionId: string) {
    const nextSection = this.state.appsPanelSections.find(
      (section) => section.sectionId === sectionId,
    );
    this.setState({ selectedSection: nextSection });
    nextSection?.onClick?.();
  },
  getSectionTitle() {
    return this.state.selectedSection.headerText;
  },
  render() {
    const LeftPanelFrame = util.workspace.isNewWorkspaceEnabled()
      ? leftBar.LeftPanelFrame
      : panels.frames.LeftPanelFrame;

    return (
      <LeftPanelFrame
        panelName={this.props.panelName}
        label={this.getSectionTitle()}
        panelClass="app-market-panel-new"
        disableBlockingScroll={true}
        panelIndex={this.props.panelIndex}
        styleOverride={this.getResponsiveWidth()}
        className="app-market-content-new"
        fullHeight
        {...this.getPanelProps()}
      >
        {!this.state.loading
          ? (() => {
              const CurrentPanel = this.state.selectedSection.component;
              return (
                <div className="app-market-frame-new">
                  {this.state.appsPanelSections.length > 1 ? (
                    <VerticalTabs
                      shouldTranslate={false}
                      value={this.state.selectedSection.sectionId}
                      onChange={this.onSectionChanged}
                      options={this.state.appsPanelSections.map((section) => ({
                        value: section.sectionId,
                        label: section.label,
                        dataHook: `apps-tab-${section.sectionId}`,
                      }))}
                    />
                  ) : null}
                  <CurrentPanel {...this.props.selectedSection?.props} />
                </div>
              );
            })()
          : null}
      </LeftPanelFrame>
    );
  },
});

function mapStateToProps({ editorAPI }) {
  const appPanelSectionsApi = editorAPI.host.getAPI(AppsPanelSectionsApiKey);
  return {
    contributedSections: appPanelSectionsApi.getContributedSections(),
  };
}

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  {},
)(AppMarketPanel);
