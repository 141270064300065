import React, { Component, type ComponentType } from 'react';

import { integrateMenus } from '../../editorSearchIntegrations/menusIntegration';
import connectWithEditor from '../../util/connectWithEditor';
import {
  mapStateToProps as mapStateToMenusIntegrationProps,
  mapDispatchToProps as mapDispatchToMenusIntegrationProps,
} from '../../editorSearchIntegrations/menusIntegrationMapper';

import type { MapDispatchToPropsFunction } from 'types/redux';
import type {
  MenusSearchIntegrationDispatchProps,
  MenusSearchIntegrationStateProps,
  MenusSearchIntegrationOwnProps,
} from '../../editorSearchIntegrations/menusIntegration';

interface WithSearchIntegrationDispatchProps
  extends MenusSearchIntegrationDispatchProps {
  pickOwnProps: () => object;
}

interface WithSearchIntegrationProps
  extends WithSearchIntegrationDispatchProps,
    MenusSearchIntegrationStateProps,
    MenusSearchIntegrationOwnProps {}

const mapStateToProps = mapStateToMenusIntegrationProps;
const mapDispatchToProps: MapDispatchToPropsFunction<
  WithSearchIntegrationDispatchProps,
  MenusSearchIntegrationOwnProps
> = (dispatch, ownProps) => ({
  ...mapDispatchToMenusIntegrationProps(dispatch, ownProps),
  pickOwnProps: () => ownProps,
});

const withSearchIntegration = <P extends {}>(
  WrappedComponent: ComponentType<P>,
) => {
  class WithSearchIntegration extends Component<WithSearchIntegrationProps> {
    componentDidMount() {
      this.menusSearchIntegration = integrateMenus(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps: WithSearchIntegrationProps) {
      this.menusSearchIntegration.update(nextProps);
    }

    private menusSearchIntegration: ReturnType<typeof integrateMenus>;

    render() {
      const { props } = this;

      return React.createElement(WrappedComponent, props.pickOwnProps() as P);
    }
  }

  return connectWithEditor(
    mapStateToProps,
    mapDispatchToProps,
  )(WithSearchIntegration) as unknown as ComponentType<P>;
};

export default withSearchIntegration;
