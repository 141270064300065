import { translate } from '@/i18n';
import { notifications, panels } from '@/stateManagement';
import { TRANSLATIONS } from '../../../translations';
import { isSection, getNonRemovableData } from '../../../api';

import type { Scope } from '@/sections';
import type { CannotRemovePlugin } from 'types/editorPlugins';

const { showUserActionNotification } = notifications.actions;

const { NOTIFICATIONS } = TRANSLATIONS;

export const createCannotRemovePlugin =
  (scope: Scope): CannotRemovePlugin =>
  (compRef) => {
    const { editorAPI, components } = scope;

    if (!isSection(scope, compRef)) return;

    const nonRemovableData = getNonRemovableData(scope, compRef);
    if (!nonRemovableData) return;

    let notificationMessage: string;
    let notificationHelpId: string | undefined;
    const isDesktop = editorAPI.isDesktopEditor();

    if (nonRemovableData.type === 'lastSection') {
      const key = isDesktop
        ? NOTIFICATIONS.CANNOT_REMOVE_LAST_SECTION
        : NOTIFICATIONS.CANNOT_REMOVE_LAST_SECTION_MOBILE;

      notificationMessage = translate(key);
    } else if (nonRemovableData.type === 'containsNonRemovableTPA') {
      const key = isDesktop
        ? NOTIFICATIONS.CANNOT_REMOVE_SECTION_WITH_WIDGET
        : NOTIFICATIONS.CANNOT_REMOVE_SECTION_WITH_WIDGET_MOBILE;

      notificationMessage = translate(key, {
        widget_name: components.getDisplayName(
          nonRemovableData.nonRemovableTPARef,
        ),
      });
      notificationHelpId = isDesktop
        ? 'ba3940d3-b9d8-4437-abd8-3f77f93085ce'
        : 'dfe9342e-3642-46a1-a29c-ce9fe94c32ae';
    }

    if (!notificationMessage) return;

    editorAPI.store.dispatch(
      showUserActionNotification({
        message: notificationMessage,
        title: notificationMessage,
        type: 'warning',
        shouldTranslate: false,
        link: notificationHelpId
          ? {
              caption: translate('Notifications_Learn_More_Link'),
              onNotificationLinkClick: () => {
                editorAPI.store.dispatch(
                  panels.actions.openHelpCenter(notificationHelpId),
                );
              },
            }
          : undefined,
      }),
    );
  };
