'use strict'

module.exports = {
    'header': {
        'id': 'site_header_14',
        'structure': {
            'type': 'Container',
            'components': [],
            'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.InnerShadowScreen',
            'layout': {
                'width': 980,
                'height': 180,
                'x': 0,
                'y': 0,
                'scale': 1,
                'rotationInDegrees': 0,
                'anchors': [{
                    'distance': 0,
                    'topToTop': 180,
                    'originalValue': 180,
                    'type': 'BOTTOM_TOP',
                    'locked': true,
                    'targetComponent': 'PAGES_CONTAINER'
                }],
                'fixedPosition': false
            },
            'componentType': 'wysiwyg.viewer.components.HeaderContainer',
            'style': {
                'type': 'TopLevelStyle',
                'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                'style': {
                    'properties': {
                        'alpha-bg': '1',
                        'alpha-brd': '1',
                        'bg': '#006C6D',
                        'brd': '#01B1AF',
                        'brw': '0px',
                        'rd': '0px'
                    },
                    'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value', 'rd': 'value'},
                    'groups': {}
                },
                'componentClassName': 'wysiwyg.viewer.components.HeaderContainer',
                'pageId': '',
                'compId': 'SITE_HEADER',
                'styleType': 'custom',
                'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.InnerShadowScreen'
            }
        },
        'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 1170}}
    },
    'footer': {
        'id': 'site_footer_14',
        'structure': {
            'type': 'Container',
            'components': [],
            'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.InnerShadowScreen',
            'layout': {
                'width': 980,
                'height': 180,
                'x': 0,
                'y': 681,
                'scale': 1,
                'rotationInDegrees': 0,
                'anchors': [{
                    'distance': 0,
                    'topToTop': 681,
                    'originalValue': 0,
                    'type': 'BOTTOM_PARENT',
                    'locked': true,
                    'targetComponent': 'masterPage'
                }],
                'fixedPosition': false
            },
            'componentType': 'wysiwyg.viewer.components.FooterContainer',
            'style': {
                'type': 'TopLevelStyle',
                'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                'style': {
                    'properties': {
                        'alpha-bg': '1',
                        'alpha-brd': '1',
                        'bg': '#006C6D',
                        'brd': '#01B1AF',
                        'brw': '0px',
                        'rd': '0px'
                    },
                    'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value', 'rd': 'value'},
                    'groups': {}
                },
                'componentClassName': 'wysiwyg.viewer.components.FooterContainer',
                'pageId': '',
                'compId': 'SITE_FOOTER',
                'styleType': 'custom',
                'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.InnerShadowScreen'
            }
        },
        'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 1170}}
    },
    'index': 14
}
