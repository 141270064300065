// @ts-nocheck
const ADD_PANEL_SECTION_IMAGE =
  'addPanelData/sections/proGalleriesSection_en/add-panel-pro-gallery-section-new.png';
const ADD_PANEL_SECTION_RETINA_IMAGE = {
  src: 'addPanelData/sections/proGalleriesSection_en/add-panel-pro-gallery-section-new-@2x.png',
  dimensions: {
    width: 648,
    height: 2588,
  },
};
const EDITOR_X_ADD_PANEL_SECTION_IMAGE =
  'addPanelData/sections/proGalleriesSection_en/editor-x-add-panel-pro-gallery-section.v2.png';
const EDITOR_X_ADD_PANEL_SECTION_RETINA_IMAGE = {
  src: 'addPanelData/sections/proGalleriesSection_en/editor-x-add-panel-pro-gallery-section-@2x.png',
  dimensions: {
    width: 648,
    height: 2534,
  },
};

const EDITOR_X_ITEMS_PRESETS = {
  slideDeckProGallery: {
    rect: { width: 324, height: 136, x: 0, y: 970 },
    label: 'add_preset_pro_galleries_slide_deck_label',
    tags: { isFullWidth: false },
  },
  sliderProGallery: {
    rect: { width: 324, height: 158, x: 0, y: 1106 },
    label: 'add_preset_pro_galleries_slider_label',
    tags: { isFullWidth: false },
  },
};

export {
  ADD_PANEL_SECTION_IMAGE,
  ADD_PANEL_SECTION_RETINA_IMAGE,
  EDITOR_X_ADD_PANEL_SECTION_IMAGE,
  EDITOR_X_ADD_PANEL_SECTION_RETINA_IMAGE,
  EDITOR_X_ITEMS_PRESETS,
};
