import React from 'react';
import {
  Composites,
  Divider,
  InfoIcon,
  TextInput,
  TextLabel,
} from '@wix/wix-base-ui';
import experiment from 'experiment';
import * as baseUI from '@/baseUI';
import { translate } from '@/i18n';
import { hoc, validate } from '@/util';
import constants from '@/constants';
import { mapDispatchToProps, mapStateToProps } from './PhoneLink.mapper';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

export interface TPhoneLink {
  id?: string;
  type: string;
  phoneNumber?: string;
}

type PhoneLinkComponentProps = {
  panelName: string;
  link: TPhoneLink;
  onValidationUpdate(isValid: boolean): void;
  onLinkChange(link: TPhoneLink): void;
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

interface PhoneLinkComponentState {
  prevLink: TPhoneLink;
  link: TPhoneLink;
  isPhoneNumberValid: boolean;
}

const isValidPhone = (phoneNumber: string) => {
  return validate.phone(phoneNumber);
};

const withBusinessNumberExperiment = () =>
  experiment.isOpen('se_PhoneLinkPanelVirtualNumbers');

export class PhoneLinkComponent extends React.Component<
  PhoneLinkComponentProps,
  PhoneLinkComponentState
> {
  constructor(props: PhoneLinkComponentProps) {
    super(props);
    const link = props.link || props.createDefaultData('PhoneLink');
    this.state = {
      prevLink: link,
      link,
      isPhoneNumberValid: !!link.phoneNumber,
    };
  }

  static getDerivedStateFromProps(
    props: PhoneLinkComponentProps,
    state: PhoneLinkComponentState,
  ) {
    const nextLink =
      state.prevLink?.id !== props.link?.id ? props.link : state.link;

    return {
      prevLink: props.link,
      link: nextLink,
    };
  }

  componentDidMount() {
    const { link, isPhoneNumberValid } = this.state;
    const { onLinkChange, onValidationUpdate, isVNInstalled } = this.props;

    onLinkChange(link);
    onValidationUpdate(isPhoneNumberValid);

    if (withBusinessNumberExperiment() && isVNInstalled) {
      this.updateOrFetchBusinessNumber();
    }
  }

  private updateOrFetchBusinessNumber() {
    const {
      fetchBusinessNumberSucceed,
      isFetchingBusinessNumber,
      getBusinessNumbersData,
    } = this.props;

    if (isFetchingBusinessNumber) {
      return;
    }

    if (fetchBusinessNumberSucceed) {
      return this.onBusinessNumberUpdate();
    }

    return getBusinessNumbersData();
  }

  private onLinkStateUpdate(prevLink: TPhoneLink) {
    const { link } = this.state;

    if (prevLink !== link) {
      this.props.onLinkChange(link);
    }
  }

  private onValidationStateUpdate(prevIsPhoneNumberValid: boolean) {
    const { isPhoneNumberValid } = this.state;

    if (isPhoneNumberValid !== prevIsPhoneNumberValid) {
      this.props.onValidationUpdate(isPhoneNumberValid);
    }
  }

  private onBusinessNumberUpdate() {
    const { businessNumber } = this.props;
    const {
      link: { phoneNumber },
    } = this.state;

    if (businessNumber && !phoneNumber) {
      this.setPhoneNumber(businessNumber);
    }
  }

  componentDidUpdate(
    prevProps: PhoneLinkComponentProps,
    prevState: PhoneLinkComponentState,
  ) {
    this.onLinkStateUpdate(prevState.link);
    this.onValidationStateUpdate(prevState.isPhoneNumberValid);
    this.onBusinessNumberUpdate();
  }

  private setPhoneNumber = (phoneNumber: AnyFixMe) => {
    const { link } = this.state;

    this.setState({
      link: {
        ...link,
        phoneNumber,
      },
    });
  };

  private handlePhoneNumberValidation = (isValid: AnyFixMe) => {
    this.setState({
      isPhoneNumberValid: isValid,
    });
  };

  private openLearnMore = () => {
    const biHelpParams = {
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
      learn_more: true,
    };
    const helpItem = '269ee4ca-ac26-40a4-b982-b120e84c75d4';
    this.props.openHelpCenter(helpItem, null, biHelpParams);
  };

  private renderInfoIcon = () => {
    const infoIconProps = withBusinessNumberExperiment()
      ? {
          onLinkClick: this.openLearnMore,
          linkText: 'LINK_PANEL_PHONE_MOBILE_DESCRIPTION_TOOLTIP_HELP',
          text: 'LINK_PANEL_PHONE_MOBILE_DESCRIPTION_TOOLTIP',
        }
      : {
          text: 'LINK_PANEL_PHONE_DESCRIPTION_TOOLTIP',
        };

    return (
      <InfoIcon
        {...infoIconProps}
        automationId="link-panel-phone-number-description-tooltip"
      />
    );
  };

  private onBusinessNumbersLinkClick = () => {
    const { hasBusinessNumber, openBusinessNumbers } = this.props;
    const biParams = { type: hasBusinessNumber ? 'use' : 'get' };

    openBusinessNumbers({ biParams });
  };

  private renderBusinessNumberExtraInfo = () => {
    const { hasBusinessNumber, isVNInstalled, isFetchingBusinessNumber } =
      this.props;

    if (!isVNInstalled || isFetchingBusinessNumber) {
      return null;
    }

    return (
      <p className="extra-info with-business-number">
        {hasBusinessNumber
          ? translate('LINK_PANEL_VNPHONE_DESCRIPTION_AFTER')
          : translate('LINK_PANEL_VNPHONE_DESCRIPTION_BEFORE')}

        <br />
        <baseUI.button
          label={
            hasBusinessNumber
              ? 'LINK_PANEL_VNPHONE_LINK_BEFORE_AFTER'
              : 'LINK_PANEL_VNPHONE_LINK_BEFORE'
          }
          onClick={this.onBusinessNumbersLinkClick}
          className="btn-text inline-link-button"
          automationId="link-panel-phone-number-open-business-numbers-button"
        />
      </p>
    );
  };

  private renderExtraInfo = () => {
    if (withBusinessNumberExperiment()) {
      return this.renderBusinessNumberExtraInfo();
    }

    return (
      <p className="extra-info">
        {translate('LINK_PANEL_PHONE_DESCRIPTION')}&nbsp;
        <baseUI.button
          label="LINK_PANEL_PHONE_DESCRIPTION_LINK"
          onClick={this.openLearnMore}
          className="btn-text inline-link-button"
          automationId="link-panel-phone-number-learn-more-button"
        />
      </p>
    );
  };

  render() {
    return (
      <div>
        <Composites.TextInputLabeled>
          {this.renderInfoIcon()}
          <TextLabel
            value="LINK_PANEL_PHONE_INPUT_LABEL"
            automationId="link-panel-phone-number-label"
          />
          <TextInput
            key="phoneNumber"
            validator={isValidPhone}
            invalidMessage="Validation_V1_Phone"
            automationId="link-panel-phone-number-input"
            placeholder="LINK_PANEL_PHONE_INPUT_DEFAULT"
            validateInitialValue={false}
            onChange={this.setPhoneNumber}
            value={this.state.link.phoneNumber || ''}
            onValidationStatus={this.handlePhoneNumberValidation}
            focus
          />
        </Composites.TextInputLabeled>

        <Divider />

        {this.renderExtraInfo()}
      </div>
    );
  }
}

export const PhoneLink = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(PhoneLinkComponent);

(PhoneLink as any).pure = PhoneLinkComponent;
