:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._upload-button-container_iywce_7 {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  height: 84px;
  width: 354px;
  background: #fff;
  border-bottom-right-radius: 4px;
  box-sizing: border-box; }

._upload-button_iywce_7 {
  background-color: #116dff;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15); }
  ._upload-button_iywce_7:hover {
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.3); }
