:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._workspace-modes-button_16pph_7.control-button {
  border-radius: 4px;
  height: 30px;
  padding: 0 12px; }

._workspace-modes-button_16pph_7._lite-mode_16pph_12.control-button {
  background-color: #e1f4eb; }
  ._workspace-modes-button_16pph_7._lite-mode_16pph_12.control-button svg * {
    fill: #25a55a; }
  ._workspace-modes-button_16pph_7._lite-mode_16pph_12.control-button .control-text {
    color: #25a55a; }

._workspace-modes-button_16pph_7._lite-mode_16pph_12.control-button:hover {
  background-color: #c8e8d6; }

._workspace-modes-button-text_16pph_22 {
  padding-right: 9px; }

._workspace-modes-drop-panel-content_16pph_25 {
  width: 467px;
  padding: 4px 6px 6px; }

._toggle-title-row_16pph_29 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px; }

._text-button_16pph_34 :hover {
  color: #5999ff; }

._symbol-wrapper_16pph_37 {
  height: 66px;
  width: 66px;
  padding: 17px 30px 15px 14px; }

._symbol_16pph_37 {
  height: 8px;
  width: 8px; }

._radioButtons_16pph_46 {
  border: 1px solid #e7f0ff;
  border-radius: 5px; }
