'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "title": "add_section_label_fullWidth_multiStateBox",
    "subNavigationTitle": "add_section_label_fullWidth_multiStateBox",
    "presetTitle": "add_section_label_fullWidth_multiStateBox",
    "tooltipTitle": "add_section_label_multiStateBox_tooltip_title",
    "automationId": "add-panel-section-stateStripSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "hide": false,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/boxSlideShowSection_en/boxSlideShowSection_en.png",
        "imageHover": null,
        "items": [
            {
                "id": "StateStrip_StateStrip_1",
                "structure": {
                    "layout": {
                        "width": 625,
                        "height": 306,
                        "x": 49,
                        "y": 15.5,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "components": [
                        {
                            "layout": {
                                "width": 625,
                                "height": 306,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "components": [
                                {
                                    "layout": {
                                        "width": 142,
                                        "height": 40,
                                        "x": 241.5,
                                        "y": 133,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "type": "Component",
                                    "data": {
                                        "type": "LinkableButton",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "label": "Go to State 2"
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {
                                            "schemaVersion": "1.0"
                                        },
                                        "align": "center",
                                        "margin": 0
                                    },
                                    "style": "b4",
                                    "behaviors": {
                                        "type": "ObsoleteBehaviorsList",
                                        "items": "[{\"action\":{\"sourceId\":\"comp-jwg0he47\",\"type\":\"comp\",\"name\":\"click\"},\"behavior\":{\"targetId\":\"c1dmp\",\"type\":\"widget\",\"name\":\"runCode\",\"params\":{\"callbackId\":\"button1_click\",\"compId\":\"comp-jwg0he47\"}}}]"
                                    },
                                    "connections": {
                                        "type": "ConnectionList",
                                        "items": [
                                            {
                                                "type": "WixCodeConnectionItem",
                                                "role": "button1"
                                            }
                                        ]
                                    },
                                    "mobileStructure": {
                                        "layout": {
                                            "width": 160,
                                            "height": 42,
                                            "x": 10,
                                            "y": 20,
                                            "scale": 0.9333333333333333,
                                            "rotationInDegrees": 0,
                                            "fixedPosition": false
                                        },
                                        "metaData": {
                                            "originalCompId": "comp-jwg0he47",
                                            "author": "duplicate"
                                        }
                                    }
                                }
                            ],
                            "componentType": "wysiwyg.viewer.components.StateStripState",
                            "type": "Container",
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "background": {
                                    "color": "#F7F5E1",
                                    "showOverlayForMediaType": "WixVideo",
                                    "colorOpacity": 1,
                                    "colorOverlay": "",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "fittingType": "fill",
                                    "type": "BackgroundMedia",
                                    "colorOverlayOpacity": 0,
                                    "alignType": "center"
                                },
                                "charas": "design-jwgc41b3",
                                "dataChangeBehaviors": []
                            },
                            "style": "stateStripState1",
                            "connections": {
                                "type": "ConnectionList",
                                "items": [
                                    {
                                        "type": "WixCodeConnectionItem",
                                        "role": "state1"
                                    }
                                ]
                            },
                            "mobileStructure": {
                                "layout": {
                                    "width": 260,
                                    "height": 72,
                                    "x": 0,
                                    "y": 18,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "metaData": {
                                    "originalCompId": "comp-jwf3tkwk",
                                    "author": "duplicate"
                                }
                            }
                        },
                        {
                            "layout": {
                                "width": 625,
                                "height": 306,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "components": [
                                {
                                    "layout": {
                                        "x": 215.5,
                                        "y": 119,
                                        "fixedPosition": false,
                                        "width": 142,
                                        "height": 40,
                                        "scale": 1,
                                        "rotationInDegrees": 0
                                    },
                                    "previousStyleId": "b4",
                                    "componentType": "wysiwyg.viewer.components.SiteButton",
                                    "type": "Component",
                                    "data": {
                                        "type": "LinkableButton",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "label": "Go to State 1",
                                        "link": null
                                    },
                                    "props": {
                                        "type": "ButtonProperties",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "align": "center",
                                        "margin": 0
                                    },
                                    "mobileHints": {
                                        "type": "MobileHints",
                                        "recommendedWidth": 160,
                                        "recommendedHeight": 42,
                                        "recommendedScale": 0.9333333333333333,
                                        "author": "duplicate",
                                        "originalCompId": "comp-jwg0he47",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        }
                                    },
                                    "style": "b4",
                                    "behaviors": {
                                        "type": "ObsoleteBehaviorsList",
                                        "items": "[{\"action\":{\"sourceId\":\"comp-jwke6736\",\"type\":\"comp\",\"name\":\"click\"},\"behavior\":{\"targetId\":\"c1dmp\",\"type\":\"widget\",\"name\":\"runCode\",\"params\":{\"callbackId\":\"button2_click\",\"compId\":\"comp-jwke6736\"}}}]",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        }
                                    },
                                    "connections": {
                                        "type": "ConnectionList",
                                        "items": [
                                            {
                                                "type": "WixCodeConnectionItem",
                                                "role": "button2"
                                            }
                                        ],
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        }
                                    }
                                }
                            ],
                            "componentType": "wysiwyg.viewer.components.StateStripState",
                            "type": "Container",
                            "mobileHints": {
                                "type": "MobileHints",
                                "author": "duplicate",
                                "originalCompId": "comp-jwf3tkwk",
                                "recommendedScale": 1,
                                "recommendedWidth": 260,
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                }
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "background": {
                                    "color": "{color_13}",
                                    "showOverlayForMediaType": "WixVideo",
                                    "colorOpacity": 1,
                                    "colorOverlay": "",
                                    "mediaRef": null,
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "fittingType": "fill",
                                    "type": "BackgroundMedia",
                                    "colorOverlayOpacity": 0,
                                    "alignType": "center",
                                    "imageOverlay": null,
                                    "filterEffect": null,
                                    "scrollType": "none"
                                },
                                "charas": "design-jwke5rye",
                                "dataChangeBehaviors": []
                            },
                            "style": "stateBoxState1",
                            "connections": {
                                "type": "ConnectionList",
                                "items": [
                                    {
                                        "type": "WixCodeConnectionItem",
                                        "role": "state2"
                                    }
                                ]
                            }
                        }
                    ],
                    "componentType": "wysiwyg.viewer.components.StateStrip",
                    "type": "Container",
                    "skin": "wysiwyg.common.components.statebox.viewer.skins.StateStripSkin",
                    "props": {
                        "type": "StateBoxProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "transition": "OutIn",
                        "transDuration": 1
                    },
                    "style": {
                        "componentClassName": "wysiwyg.viewer.components.StateStrip",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "0.44",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "color_28",
                                "brw": "13",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "alpha-brd": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "theme",
                                "brw": "value",
                                "shd": "value"
                            },
                            "groups": {}
                        },
                        "type": "ComponentStyle",
                        "skin": "wysiwyg.common.components.statebox.viewer.skins.StateStripSkin"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"SpinIn\",\"delay\":0,\"duration\":1.2,\"params\":{\"cycles\":2,\"power\":\"hard\",\"direction\":\"cw\",\"doubleDelayFixed\":true},\"action\":\"screenIn\",\"targetId\":\"\",\"viewMode\":\"DESKTOP\"}]"
                    },
                    "connections": {
                        "type": "ConnectionList",
                        "items": [
                            {
                                "type": "WixCodeConnectionItem",
                                "role": "statebox1"
                            }
                        ]
                    },
                    "mobileStructure": {
                        "layout": {
                            "width": 260,
                            "height": 205,
                            "x": 10,
                            "y": 10,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "metaData": {
                            "originalCompId": "comp-jwf3tkuu",
                            "author": "duplicate"
                        }
                    },
                    "activeModes": {}
                },
                "preset": {
                    "rect": {"width": 162, "height": 134.5, "x": 0, "y": 135},
                    "label": "add_preset_StateStrip_1",
                    "tags": null
                }
            }],
        "compTypes": ["wysiwyg.viewer.components.StateBox"]
    },
    "help": {"hide": false, "text": "add_section_label_multiStateBox_tooltip_description"}
}
