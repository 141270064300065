:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._helpHomeActionItem_1rfqo_7 {
  margin: 0 0 12px; }
  ._helpHomeActionItem_1rfqo_7.wbu-action-item .control-label {
    cursor: pointer;
    color: #000624; }
  ._helpHomeActionItem_1rfqo_7.wbu-action-item .description-wrapper .control-label.description {
    color: #3b4057; }
