import { ErrorReporter } from '@wix/editor-error-reporter';
import { wixBookingsEvents } from './wixBookingsEvents';

function report(
  editorAPI: AnyFixMe,
  evid: AnyFixMe,
  payload: AnyFixMe,
  captureError = false,
) {
  try {
    editorAPI.bi.event(evid, payload);
  } catch (e) {
    if (captureError) {
      ErrorReporter.captureException(e, {
        tags: { bookingsPanelOpenBI: true },
      });
    }
  }
}

export { report, wixBookingsEvents as events };
