'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
module.exports =
{
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_progressBar",
    "topTitle": "add_section_title_progressBar",
    "subNavigationTitle": "add_section_label_progressBar",
    "presetTitle": "add_section_heading_progressBar",
    "tooltipTitle": "add_section_label_progressBar_tooltip_title",
    "automationId": "add-panel-section-progressBarSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/progressBarSection_en/add-panel-progressbar-sction_en.png",
        "imageHover": null,
        "items": [
            {
                "id": "User_Input_ProgressBar_1",
                "structure": {
                    "type": "Component",
                    "skin": "wixui.skins.ProgressBar",
                    "layout": {
                        "width": 305,
                        "height": 10,
                        "x": 10,
                        "y": 19,
                        "scale": 0.66,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wixui.ProgressBar",
                    "data": {
                        "type": "ProgressBar",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "value": 0,
                        "targetValue": 100
                    },
                    "props": {
                        "type": "ProgressBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "direction": "ltr"
                    },
                    "style": {
                        "type": "ComponentStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-borderColor": "0",
                                "barBackgroundColor": "#CDF7F1",
                                "barForegroundColor": "#13D6D6",
                                "borderColor": "#FFFFFF",
                                "borderRadius": "5px 5px 5px 5px",
                                "borderSize": "0",
                                "boxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "boxShadowToggleOn-boxShadow": "false"
                            },
                            "propertiesSource": {
                                "alpha-borderColor": "value",
                                "barBackgroundColor": "value",
                                "barForegroundColor": "value",
                                "borderColor": "value",
                                "borderRadius": "value",
                                "borderSize": "value",
                                "boxShadow": "value",
                                "boxShadowToggleOn-boxShadow": "value"
                            }
                        },
                        "componentClassName": "wixui.ProgressBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wixui.skins.ProgressBar"
                    },
                    "activeModes": {},
                    "id": "comp-jxop3q69"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 45,
                        "x": 0,
                        "y": 0
                    }
                }
            },
            {
                "id": "User_Input_ProgressBar_2",
                "structure": {
                    "type": "Component",
                    "skin": "wixui.skins.ProgressBar",
                    "layout": {
                        "width": 305,
                        "height": 10,
                        "x": 10,
                        "y": 59,
                        "scale": 0.66,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wixui.ProgressBar",
                    "data": {
                        "type": "ProgressBar",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "value": 0,
                        "targetValue": 100
                    },
                    "props": {
                        "type": "ProgressBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "direction": "ltr"
                    },
                    "style": {
                        "type": "ComponentStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-borderColor": "0",
                                "barBackgroundColor": "#EFEFF1",
                                "barForegroundColor": "#FFA78C",
                                "borderColor": "#FFFFFF",
                                "borderRadius": "0px 0px 0px 0px",
                                "borderSize": "0",
                                "boxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "boxShadowToggleOn-boxShadow": "false"
                            },
                            "propertiesSource": {
                                "alpha-borderColor": "value",
                                "barBackgroundColor": "value",
                                "barForegroundColor": "value",
                                "borderColor": "value",
                                "borderRadius": "value",
                                "borderSize": "value",
                                "boxShadow": "value",
                                "boxShadowToggleOn-boxShadow": "value"
                            }
                        },
                        "componentClassName": "wixui.ProgressBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wixui.skins.ProgressBar"
                    },
                    "activeModes": {},
                    "id": "comp-jxop3q6r"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 40,
                        "x": 0,
                        "y": 45
                    }
                }
            },
            {
                "id": "User_Input_ProgressBar_3",
                "structure": {
                    "type": "Component",
                    "skin": "wixui.skins.ProgressBar",
                    "layout": {
                        "width": 305,
                        "height": 12,
                        "x": 10,
                        "y": 99,
                        "scale": 0.66,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wixui.ProgressBar",
                    "data": {
                        "type": "ProgressBar",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "value": 0,
                        "targetValue": 100
                    },
                    "props": {
                        "type": "ProgressBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "direction": "ltr"
                    },
                    "style": {
                        "type": "ComponentStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-barBackgroundColor": "0",
                                "alpha-borderColor": "1",
                                "barBackgroundColor": "#FFFFFF",
                                "barForegroundColor": "#8C84FA",
                                "borderColor": "#8C84FA",
                                "borderRadius": "1px 1px 1px 1px",
                                "borderSize": "2",
                                "boxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "boxShadowToggleOn-boxShadow": "false"
                            },
                            "propertiesSource": {
                                "alpha-barBackgroundColor": "value",
                                "alpha-borderColor": "value",
                                "barBackgroundColor": "value",
                                "barForegroundColor": "value",
                                "borderColor": "value",
                                "borderRadius": "value",
                                "borderSize": "value",
                                "boxShadow": "value",
                                "boxShadowToggleOn-boxShadow": "value"
                            }
                        },
                        "componentClassName": "wixui.ProgressBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wixui.skins.ProgressBar"
                    },
                    "activeModes": {},
                    "id": "comp-jxop3q70"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 42,
                        "x": 0,
                        "y": 85
                    }
                }
            },
            {
                "id": "User_Input_ProgressBar_4",
                "structure": {
                    "type": "Component",
                    "skin": "wixui.skins.ProgressBar",
                    "layout": {
                        "width": 305,
                        "height": 14,
                        "x": 10,
                        "y": 141,
                        "scale": 0.66,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wixui.ProgressBar",
                    "data": {
                        "type": "ProgressBar",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "value": 0,
                        "targetValue": 100
                    },
                    "props": {
                        "type": "ProgressBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "direction": "ltr"
                    },
                    "style": {
                        "type": "ComponentStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-borderColor": "1",
                                "barBackgroundColor": "#D2F6DE",
                                "barForegroundColor": "#06D17E",
                                "borderColor": "#D2F6DE",
                                "borderRadius": "10px 10px 10px 10px",
                                "borderSize": "3",
                                "boxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "boxShadowToggleOn-boxShadow": "false"
                            },
                            "propertiesSource": {
                                "alpha-borderColor": "value",
                                "barBackgroundColor": "value",
                                "barForegroundColor": "value",
                                "borderColor": "value",
                                "borderRadius": "value",
                                "borderSize": "value",
                                "boxShadow": "value",
                                "boxShadowToggleOn-boxShadow": "value"
                            }
                        },
                        "componentClassName": "wixui.ProgressBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wixui.skins.ProgressBar"
                    },
                    "activeModes": {},
                    "id": "comp-jxop3q7a"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 44,
                        "x": 0,
                        "y": 127
                    }
                }
            },
            {
                "id": "User_Input_ProgressBar_5",
                "structure": {
                    "type": "Component",
                    "skin": "wixui.skins.ProgressBar",
                    "layout": {
                        "width": 305,
                        "height": 8,
                        "x": 10,
                        "y": 186,
                        "scale": 0.66,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wixui.ProgressBar",
                    "data": {
                        "type": "ProgressBar",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "value": 0,
                        "targetValue": 100
                    },
                    "props": {
                        "type": "ProgressBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "direction": "ltr"
                    },
                    "style": {
                        "type": "ComponentStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-borderColor": "0",
                                "barBackgroundColor": "#CFE9FF",
                                "barForegroundColor": "#3899EC",
                                "borderColor": "#FFFFFF",
                                "borderRadius": "10px 0px 10px 0px",
                                "borderSize": "0",
                                "boxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "boxShadowToggleOn-boxShadow": "false"
                            },
                            "propertiesSource": {
                                "alpha-borderColor": "value",
                                "barBackgroundColor": "value",
                                "barForegroundColor": "value",
                                "borderColor": "value",
                                "borderRadius": "value",
                                "borderSize": "value",
                                "boxShadow": "value",
                                "boxShadowToggleOn-boxShadow": "value"
                            }
                        },
                        "componentClassName": "wixui.ProgressBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wixui.skins.ProgressBar"
                    },
                    "activeModes": {},
                    "id": "comp-jxop3q7k"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 38,
                        "x": 0,
                        "y": 171
                    }
                }
            },
            {
                "id": "User_Input_ProgressBar_6",
                "structure": {
                    "type": "Component",
                    "skin": "wixui.skins.ProgressBar",
                    "layout": {
                        "width": 305,
                        "height": 20,
                        "x": 10,
                        "y": 224,
                        "scale": 0.66,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wixui.ProgressBar",
                    "data": {
                        "type": "ProgressBar",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "value": 0,
                        "targetValue": 100
                    },
                    "props": {
                        "type": "ProgressBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "direction": "ltr"
                    },
                    "style": {
                        "type": "ComponentStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-barBackgroundColor": "0",
                                "alpha-borderColor": "1",
                                "barBackgroundColor": "#FFFFFF",
                                "barForegroundColor": "#000000",
                                "borderColor": "#000000",
                                "borderRadius": "0px 0px 0px 0px",
                                "borderSize": "1",
                                "boxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "boxShadowToggleOn-boxShadow": "false"
                            },
                            "propertiesSource": {
                                "alpha-barBackgroundColor": "value",
                                "alpha-borderColor": "value",
                                "barBackgroundColor": "value",
                                "barForegroundColor": "value",
                                "borderColor": "value",
                                "borderRadius": "value",
                                "borderSize": "value",
                                "boxShadow": "value",
                                "boxShadowToggleOn-boxShadow": "value"
                            }
                        },
                        "componentClassName": "wixui.ProgressBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wixui.skins.ProgressBar"
                    },
                    "activeModes": {},
                    "id": "comp-jxop3q7u"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 55,
                        "x": 0,
                        "y": 209
                    }
                }
            }
        ],
        "compTypes": [
            "wixui.ProgressBar"
        ]
    },
    "help": {
        "hide": false,
        "text": "add_section_label_progressBar_tooltip_description"
    }
}
