import React from 'react';
import { PrimaryPanelsSlotPlacement } from '@/extensionSlots';
import { LeftPanelFrame } from '@/leftBar';

import styles from './platformExtensionsPrimaryPanel.scss';

export interface ExtensionsPrimaryPanelOwnProps {
  panelName: string;
}

const ExtensionsPrimaryPanel: React.FC<ExtensionsPrimaryPanelOwnProps> = ({
  panelName,
}) => {
  return (
    <PrimaryPanelsSlotPlacement>
      {(integrationProps, ownProps) => (
        <LeftPanelFrame
          panelName={panelName}
          panelClass={ownProps.panelClass}
          label={ownProps.label}
          styleOverride={{ width: ownProps.width }}
          automationId={`leftPanelFrame-${ownProps.automationId}`}
        >
          <div {...integrationProps} className={styles.root} />
        </LeftPanelFrame>
      )}
    </PrimaryPanelsSlotPlacement>
  );
};

export default ExtensionsPrimaryPanel;
