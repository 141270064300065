import type { CompData } from '@wix/document-services-types';

type ExtractComponentData = (data: CompData) => CompData | null;

const getLinkData = (data: any): any => ({
  type: data.type,
  isPreset: data.metaData?.isPreset,
  url: data.url ?? null,
  pageId: data.metaData?.pageId ?? null,
  anchorName: data.anchorName ?? null,
  target: data.target ?? null,
});

const getTextComponentData: ExtractComponentData = (compData) => ({
  type: 'Text',
  isPreset: false,
  text: compData.text,
  linkList: compData.linkList?.map(getLinkData) ?? [],
});

const getImageComponentData: ExtractComponentData = (compData) => ({
  type: 'Image',
  isPreset: compData.metaData?.isPreset,
  title: compData.title,
  uri: compData.uri,
  description: compData.description,
  width: compData.width,
  height: compData.height,
  alt: compData.alt,
  link: compData.link ? getLinkData(compData.link) : null,
});

const getWidgetComponentData: ExtractComponentData = (compData) => ({
  type: 'Widget',
  name: compData.name ?? null,
  widgetId: compData.widgetId,
  applicationId: compData.applicationId,
  appDefinitionId: compData.name,
});

const getPageComponentData: ExtractComponentData = (compData) => ({
  type: 'Page',
  title: compData.title,
  descriptionSEO: compData.descriptionSEO,
  metaKeywordsSEO: compData.metaKeywordsSEO,
  pageTitleSEO: compData.pageTitleSEO,
  pageUriSEO: compData.pageUriSEO,
});

const getButtonComponentData: ExtractComponentData = (compData) => ({
  type: 'Button',
  label: compData.label,
  link: compData.link ? getLinkData(compData.link) : null,
});

const getAnchorComponentData: ExtractComponentData = (compData) => ({
  type: 'Anchor',
  name: compData.name,
});

export const getComponentData: ExtractComponentData = (data: CompData) => {
  if (!data) return null;

  const { type } = data;

  switch (type) {
    case 'StyledText':
    case 'RichText':
      return getTextComponentData(data);
    case 'Image':
      return getImageComponentData(data);
    case 'TPA':
    case 'TPAWidget':
      return getWidgetComponentData(data);
    case 'Page':
      return getPageComponentData(data);
    case 'LinkableButton':
      return getButtonComponentData(data);
    case 'Anchor':
      return getAnchorComponentData(data);
    default:
      return null;
  }
};
