import constants from '@/constants';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const ungroupElementsAction = {
  label: 'gfpp_mainaction_group',
  className: 'gfpp-btn-ungroup',
  isApplied: true,
  async onClick(editorAPI: EditorAPI, compRef: CompRef) {
    const groupChildren =
      editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(compRef);
    editorAPI.selection.selectComponentByCompRef(groupChildren);
    await editorAPI.components.ungroup(compRef);
  },
};

export default {
  mainActions: [ungroupElementsAction],
  mobileMainActions: [ungroupElementsAction],
  enabledActions: [ACTIONS.ANIMATION, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId: 'ebc0c5e0-5b02-4de5-8168-608cab2cf752',
    },
  },
};
