import React from 'react';
import _ from 'lodash';
import {
  TextButton,
  Button,
  Composites,
  Divider,
  DropDown,
  DropDownOption,
  DropDownStickyFooter,
  TextLabel,
  SectionDivider,
} from '@wix/wix-base-ui';

import { hoc, panelUtils, cx } from '@/util';
import { translate } from '@/i18n';
import { events } from '@/coreBi';
import type {
  PagesData,
  SitemapEntry,
  TpaInnerRouteObject,
} from 'types/documentServices';

import { mapDispatchToProps, mapStateToProps } from './PageSelection.mapper';
import {
  convertLinkPageIdToPageId,
  getPageById,
  getRangeStartForRouteIndex,
} from '../../../utils';
import { CURRENT_INNER_ROUTE, PAGINATION_RANGE } from '../../../constants';
import type { TPageLink } from '../../../types';
import { getTpaRoutesDropdownLabel } from './translations';
import type { TpaInnerRouteData } from '@/stateManagement';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

export interface PageSelectionComponentOwnProps {
  pageDropDownTitle?: string;
  defaultPageId?: string;
  link: Partial<TPageLink> | null;
  shouldAddAnyItemOption?: boolean;
  addAnyItemTranslation?: string;
  currentPageId?: string;
  pages: PagesData[];
  addNewItemTitle?: string;
  onAddNewItemClick?(): void;
  onPageSelect(page: PagesData): void;
  onDynamicPageSelect?(page: PagesData, routerId: string): Promise<void>;
  onTpaPageSelect?(page: PagesData, innerRoute?: TpaInnerRouteObject): void;
  onTpaInnerRouteSelect?(innerRoute: TpaInnerRouteObject): void;
  onInitialInnerRoutingFetch?(): void;
  onInnerRouteSelect?(innerRoute: string): void;
  origin: string;
}

export type PageSelectionComponentProps = PageSelectionComponentOwnProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

interface PageSelectionComponentState {
  selectedInnerRoute: string;
  routerId: string;
  innerRoutes: any[];
  innerRoutesRangeStartIndex: number;
  page: PagesData;
  tpaSubPages: { key: string }[];
  selectedTpaSubPage: string;
  tpaInnerRoutes: TpaInnerRouteObject[];
  selectedTpaRoute: string;
}

export class PageSelectionComponent extends React.Component<
  PageSelectionComponentProps,
  PageSelectionComponentState
> {
  private isNewLinkPanelAnchorsFlowEnabled =
    panelUtils.isNewLinkPanelAnchorsFlowEnabled();
  private isLinkingOfNewLightboxEnabled =
    panelUtils.isLinkingOfNewLightboxEnabled();

  constructor(props: PageSelectionComponentProps) {
    super(props);
    const { link, pages } = props;
    const page = getPageById(convertLinkPageIdToPageId(link?.pageId), pages);

    this.state = {
      selectedInnerRoute: link?.innerRoute,
      routerId: link?.routerId,
      innerRoutes: [],
      innerRoutesRangeStartIndex: 0,
      page,
      tpaSubPages: [],
      selectedTpaSubPage: link?.itemTypeIdentifier,
      tpaInnerRoutes: [],
      selectedTpaRoute: link?.itemId,
    };
  }

  componentDidMount() {
    const { routerId, page } = this.state;

    if (!page) {
      this.setPage(this.getDefaultPageId());
    } else if (routerId) {
      this.fetchInitialInnerRoutesFromRouterId();
    } else if (this.props.link.appDefinitionId) {
      this.fetchInitialTpaRoutes();
    }
  }

  private getDefaultPageId() {
    const { defaultPageId } = this.props;

    const prefilledValue = '';

    return this.isNewLinkPanelAnchorsFlowEnabled && defaultPageId
      ? defaultPageId
      : prefilledValue;
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<PageSelectionComponentProps>,
    _nextContext: any,
  ): void {
    if (this.props.isFetchInProgress && !nextProps.isFetchInProgress) {
      const { page, selectedTpaSubPage } = this.state;
      this.setState({
        tpaInnerRoutes: this.props.getTpaInnerRoutes(
          page?.appDefinitionId ?? page?.managingAppDefId,
          page?.id,
          selectedTpaSubPage,
        ),
      });
    }
  }

  private fetchInitialTpaRoutes() {
    const pageId = this.state.page.id;
    this.props.fetchTpaInnerRoutes(
      pageId,
      (tpaInnerRoutes: TpaInnerRouteData[]) =>
        this.setState({
          tpaInnerRoutes,
        }),
    );
    const tpaSubPages = this.props.getSubPages(pageId);

    this.setState({
      tpaSubPages,
      tpaInnerRoutes: this.props.getTpaInnerRoutes(
        this.state.page.appDefinitionId ?? this.state.page.managingAppDefId,
        pageId,
        tpaSubPages[0].key,
      ),
    });
  }

  private async fetchInitialInnerRoutesFromRouterId() {
    const { routerId, page, selectedInnerRoute } = this.state;
    const { getRouterInnerRoutes, onInitialInnerRoutingFetch } = this.props;

    const routersRoutes = await getRouterInnerRoutes(routerId, page.id);

    const innerRoutes = routersRoutes || [];
    const innerRouteIndex =
      selectedInnerRoute === 'CURRENT_INNER_ROUTE'
        ? 0
        : innerRoutes.findIndex((route) => route.url === selectedInnerRoute);

    if (innerRouteIndex === -1) {
      this.setState({
        innerRoutes,
        innerRoutesRangeStartIndex: 0,
        selectedInnerRoute: innerRoutes.length ? innerRoutes[0].url : null,
      });
    } else {
      this.setState({
        innerRoutes,
        innerRoutesRangeStartIndex: getRangeStartForRouteIndex(innerRouteIndex),
      });
    }

    onInitialInnerRoutingFetch();
  }

  private isDynamicPage(page: PagesData) {
    if (!page) {
      return false;
    }

    return !!this.props.getRouterDataForPageIfExist(page.id);
  }

  private isInnerRouteDropDownDisabled() {
    const innerRoutesToShow = this.getInnerRoutesDropDown();

    return innerRoutesToShow.length < 2;
  }

  private increaseRange = () => {
    const newRangeStart =
      this.state.innerRoutesRangeStartIndex + PAGINATION_RANGE;
    const newSelectedInnerRoute = this.shouldShowPrevButton(newRangeStart)
      ? this.state.innerRoutes[newRangeStart].url
      : this.state.innerRoutes[newRangeStart - 1].url;
    this.setState({
      innerRoutesRangeStartIndex: newRangeStart,
      selectedInnerRoute: newSelectedInnerRoute,
    });
    this.setInnerRoute(newSelectedInnerRoute);
  };

  private decreaseRange = () => {
    const newRangeStart =
      this.state.innerRoutesRangeStartIndex - PAGINATION_RANGE;
    const newSelectedInnerRoute = this.state.innerRoutes[newRangeStart].url;
    this.setState({
      innerRoutesRangeStartIndex: newRangeStart,
      selectedInnerRoute: newSelectedInnerRoute,
    });
    this.setInnerRoute(newSelectedInnerRoute);
  };

  private shouldShowPrevButton(
    rangeStart = this.state.innerRoutesRangeStartIndex,
  ) {
    if (_.isEmpty(this.state.innerRoutes)) {
      return false;
    }

    const hasLessThenMax = this.state.innerRoutes.length < PAGINATION_RANGE;
    const gotToBottomLimit = rangeStart === 0;

    return !gotToBottomLimit && !hasLessThenMax;
  }

  private shouldShowNextButton() {
    if (_.isEmpty(this.state.innerRoutes)) {
      return false;
    }

    const hasLessThenMax = this.state.innerRoutes.length < PAGINATION_RANGE;
    const gotToUpperLimit =
      this.state.innerRoutesRangeStartIndex + PAGINATION_RANGE >=
      this.state.innerRoutes.length;

    return !gotToUpperLimit && !hasLessThenMax;
  }

  handleSearch = _.once(() => {
    this.props.sendBI(events.linkPanel.LINK_PANEL_PAGE_NAME_TYPE_START);
  });

  private setInnerRoute = (newInnerRoute: string) => {
    const { onInnerRouteSelect } = this.props;

    this.setState({
      selectedInnerRoute: newInnerRoute,
    });

    onInnerRouteSelect(newInnerRoute);
  };

  private getPageOptionLabel(page: PagesData) {
    const currentPageThisTranslate =
      page.id === this.props.currentPageId
        ? ` ${translate('LINK_PANEL_PAGE_THIS')}`
        : '';

    const routerType = this.props.getRouterPageType(page.id);
    const routerTypeForPageId = routerType ? ` (${routerType})` : '';

    return `${page.title}${currentPageThisTranslate}${routerTypeForPageId}`;
  }

  private async fetchInnerRoutes(pageId: string, routerId: string) {
    const { getRouterInnerRoutes, onInnerRouteSelect } = this.props;

    const routersRoutes = await getRouterInnerRoutes(routerId, pageId);

    // if the page was reselected before this point,
    // don't update innerRoutes with old state
    if (pageId !== this.state.page.id) return;

    const innerRoutes = routersRoutes || [];
    const selectedInnerRoute = innerRoutes.length ? innerRoutes[0].url : null;

    this.setState({
      selectedInnerRoute,
      innerRoutes,
      innerRoutesRangeStartIndex: 0,
    });

    onInnerRouteSelect?.(selectedInnerRoute);
  }

  private setPage = async (newPageId: string) => {
    this.props.fetchTpaInnerRoutes(newPageId);
    const {
      pages,
      getRouterDataForPageIfExist,
      onDynamicPageSelect,
      onPageSelect,
    } = this.props;

    const page = getPageById(newPageId, pages);
    // @ts-expect-error
    const routerId = getRouterDataForPageIfExist(newPageId)?.routerId ?? null;
    const innerRouteState = {
      routerId,
      tpaSubPages: [] as { key: string }[],
      selectedTpaSubPage: undefined as string,
    };
    if (!routerId) {
      innerRouteState.tpaSubPages =
        // if no onTpaPageSelect passes - handle it as regular link
        page?.managingAppDefId && this.props.onTpaPageSelect
          ? this.props.getSubPages(newPageId)
          : [];
      innerRouteState.selectedTpaSubPage = innerRouteState.tpaSubPages[0]?.key;
    }

    this.setState({
      innerRoutes: [],
      innerRoutesRangeStartIndex: 0,
      selectedInnerRoute: null,
      page,
      ...innerRouteState,
    });

    if (routerId) {
      await onDynamicPageSelect?.(page, routerId);
      this.fetchInnerRoutes(page.id, routerId);
    } else if (
      !this.props.isFetchInProgress &&
      innerRouteState.tpaSubPages.length > 0
    ) {
      this.handleSelectTpaSubPage(innerRouteState.selectedTpaSubPage, page);
    } else {
      onPageSelect(page);
    }
  };

  private getInnerRoutesDropDown() {
    const { shouldAddAnyItemOption, addAnyItemTranslation, currentPageId } =
      this.props;

    const options = this.state.innerRoutes.slice(
      this.state.innerRoutesRangeStartIndex,
      this.state.innerRoutesRangeStartIndex + PAGINATION_RANGE,
    );

    if (this.state.page.id === currentPageId && shouldAddAnyItemOption) {
      options.unshift({
        url: CURRENT_INNER_ROUTE,
        title: addAnyItemTranslation,
      });
    }

    return options;
  }

  private handleDropDownToggle = (isOpen: boolean) => {
    if (isOpen) {
      this.props.sendBI(events.linkPanel.link_panel_drop_down_open, {
        category: this.props.link.type,
        origin: this.props.origin,
      });
    }
  };

  private handleSelectTpaSubPage = (subPage: string, page?: PagesData) => {
    this.setState({ selectedTpaSubPage: subPage });

    const selectedPage = page ?? this.state.page;

    const tpaInnerRoutes = this.props.getTpaInnerRoutes(
      selectedPage.appDefinitionId ?? selectedPage.managingAppDefId,
      selectedPage.id,
      subPage,
    );

    const firstRoute = tpaInnerRoutes?.[0];
    this.setState({
      tpaInnerRoutes,
      selectedInnerRoute: firstRoute?.itemId,
    });

    this.props.onTpaPageSelect(selectedPage, firstRoute);
    if (firstRoute) this.props.onTpaInnerRouteSelect(firstRoute);
  };

  private handleSelectTpaRoute = (
    routeId: string,
    routes?: TpaInnerRouteObject[],
  ) => {
    this.setState({
      selectedTpaRoute: routeId,
    });

    const allRoutes = routes ?? this.state.tpaInnerRoutes;
    const selectedRouteObject = allRoutes.find(
      (route) => route.itemId === routeId,
    );
    this.props.onTpaInnerRouteSelect(selectedRouteObject);
  };

  private formatTpaSubpageName(originalName: string) {
    const lastDotIndex = originalName.lastIndexOf('.');

    return originalName.substring(lastDotIndex + 1) || originalName;
  }

  private renderTpaBlock() {
    const noTpaInnerRoutes = this.state.tpaInnerRoutes.length === 0;

    return (
      <>
        {this.state.tpaSubPages.length >= 2 && (
          <>
            <Composites.DropDownLabeled>
              <TextLabel
                className="dropdown-title"
                value="LINK_PANEL_PAGE_ITEM_DEFAULT"
              />
              <DropDown
                automationId="link-panel-tpa-subpages-dropdown"
                dataHook="tpa-subpages-dropdown"
                shouldTranslate={false}
                searchBox={true}
                value={this.state.selectedTpaSubPage}
                onChange={this.handleSelectTpaSubPage}
                optionsContainerClassName="link-panel-options-container"
              >
                {this.state.tpaSubPages.map(({ key }) => (
                  <DropDownOption
                    shouldTranslate={false}
                    key={key}
                    label={this.formatTpaSubpageName(key)}
                    value={key}
                  />
                ))}
              </DropDown>
            </Composites.DropDownLabeled>
            <Divider className="page-link-drop-divider" />
          </>
        )}
        <Composites.DropDownLabeled>
          <TextLabel
            className="dropdown-title"
            value={getTpaRoutesDropdownLabel(this.state.selectedTpaSubPage)}
          />
          <DropDown
            automationId="link-panel-tpa-routes-dropdown"
            dataHook="tpa-routes-dropdown"
            shouldTranslate={false}
            searchBox={true}
            value={this.state.selectedTpaRoute}
            onChange={this.handleSelectTpaRoute}
            optionsContainerClassName="link-panel-options-container"
            disabled={noTpaInnerRoutes}
            placeholder={
              noTpaInnerRoutes
                ? translate(
                    'LINK_PANEL_PAGE_INNER_ROUTES_DROP_DOWN_EMPTY_PLACEHOLDER',
                  )
                : undefined
            }
          >
            {this.state.tpaInnerRoutes.map((innerRoute) => (
              <DropDownOption
                shouldTranslate={false}
                key={innerRoute.itemId}
                label={innerRoute.name}
                value={innerRoute.itemId}
              />
            ))}
          </DropDown>
        </Composites.DropDownLabeled>
        <Divider className="page-link-drop-divider" />
      </>
    );
  }

  private renderBlockForDynamicPage() {
    const innerRoutesToShow = this.getInnerRoutesDropDown();

    return (
      !this.isInnerRouteDropDownDisabled() && (
        <>
          <Composites.DropDownLabeled>
            <TextLabel
              className="dropdown-title"
              value="LINK_PANEL_PAGE_INNER_ROUTES_DROP_DOWN_LABEL"
            />
            <DropDown
              automationId="link-panel-inner-route-selection-dropdown"
              dataHook="inner-route-selection-dropdown"
              shouldTranslate={false}
              searchBox={true}
              value={this.state.selectedInnerRoute}
              onChange={this.setInnerRoute}
              onToggle={this.handleDropDownToggle}
              optionsContainerClassName="link-panel-options-container"
            >
              {this.shouldShowPrevButton() && (
                <Button
                  onClick={this.decreaseRange}
                  className="btn-text"
                  dataHook="link-panel-dynamic-page-selection-prev-list-button"
                >
                  <span>
                    {translate('dynamic_pages_routes_navigation_button_back')}
                  </span>
                </Button>
              )}
              {innerRoutesToShow.map((innerRoute: SitemapEntry) => (
                <DropDownOption
                  shouldTranslate={false}
                  key={innerRoute.url}
                  value={innerRoute.url}
                  label={innerRoute.title}
                />
              ))}
              {this.shouldShowNextButton() && (
                <Button
                  onClick={this.increaseRange}
                  className="btn-text"
                  dataHook="link-panel-dynamic-page-selection-next-list-button"
                >
                  <span>
                    {translate('dynamic_pages_routes_navigation_button_next')}
                  </span>
                </Button>
              )}
            </DropDown>
          </Composites.DropDownLabeled>
          <Divider className="page-link-drop-divider" />
        </>
      )
    );
  }

  render() {
    const {
      pageDropDownTitle,
      addNewItemTitle,
      onAddNewItemClick,
      appPagesSections,
      isFetchInProgress,
    } = this.props;
    const { page } = this.state;

    return (
      <section data-hook="page-selection">
        <Composites.DropDownLabeled>
          <TextLabel value={pageDropDownTitle} shouldTranslate={false} />
          <DropDown
            automationId="link-panel-page-selection-dropdown"
            dataHook="page-selection-dropdown"
            shouldTranslate={false}
            value={page?.id}
            searchBox={true}
            onSearchKeyDown={this.handleSearch}
            onChange={this.setPage}
            onToggle={this.handleDropDownToggle}
            placeholder={translate(
              'LINK_PANEL_PAGE_DROP_DOWN_PLACEHOLDER_DEFAULT',
            )}
            optionsContainerClassName="link-panel-options-container"
            optionsMaxHeight={300}
          >
            {appPagesSections.flatMap(({ id, title, pages }) => [
              React.createElement(SectionDivider, { key: id }, title),
              ...pages.map((page) => (
                <DropDownOption
                  key={page.id}
                  value={page.id}
                  label={this.getPageOptionLabel(page)}
                  shouldTranslate={false}
                />
              )),
            ])}
            {addNewItemTitle && onAddNewItemClick ? (
              <DropDownStickyFooter
                closeOnClick={true}
                className={cx({
                  'link-panel-options-sticky-footer':
                    !this.isLinkingOfNewLightboxEnabled,
                })}
              >
                {this.isLinkingOfNewLightboxEnabled ? (
                  <Button
                    className="btn-text"
                    onClick={onAddNewItemClick}
                    dataHook="link-panel-page-selection-dropdown-add-button"
                  >
                    <span>{translate(addNewItemTitle)}</span>
                  </Button>
                ) : (
                  <TextButton
                    onClick={onAddNewItemClick}
                    dataHook="link-panel-page-selection-dropdown-add-button"
                  >
                    {addNewItemTitle}
                  </TextButton>
                )}
              </DropDownStickyFooter>
            ) : null}
          </DropDown>
        </Composites.DropDownLabeled>
        <Divider className="page-link-drop-divider" />
        {this.isDynamicPage(page) && this.renderBlockForDynamicPage()}
        {this.state.tpaSubPages.length > 0 &&
          !isFetchInProgress &&
          this.renderTpaBlock()}
      </section>
    );
  }
}

export const PageSelection = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(PageSelectionComponent);

PageSelection.pure = PageSelectionComponent;
