// @ts-nocheck
import _ from 'lodash';
import keyboardShortcutsActionMap from '@/keyboardShortcuts';
import constants from '@/constants';
import { toggleMobileFromKeyboard } from './baseEditorShortcuts';

const keyboardMap = keyboardShortcutsActionMap.preview;
const PREVIEW_CONTEXT_SHORTCUTS = {};

function preventDefaultEvent(event) {
  event.preventDefault();
}

function togglePreviewFromKeyboard(editorAPI, event) {
  event.preventDefault();
  editorAPI.preview.togglePreview(undefined, {
    biParams: { origin: constants.BI.PREVIEW.ORIGIN.KEYBOARD },
  });
}

export default {
  init: _.once(function (editorAPI) {
    PREVIEW_CONTEXT_SHORTCUTS[keyboardMap.EXIT_PREVIEW.key] =
      editorAPI.preview.togglePreview;
    PREVIEW_CONTEXT_SHORTCUTS[keyboardMap.TOGGLE_PREVIEW.key] =
      togglePreviewFromKeyboard.bind(this, editorAPI);
    PREVIEW_CONTEXT_SHORTCUTS[keyboardMap.TOGGLE_MOBILE.key] =
      toggleMobileFromKeyboard.bind(null, editorAPI);
    PREVIEW_CONTEXT_SHORTCUTS[keyboardMap.PUBLISH_SITE.key] =
      preventDefaultEvent;
  }),
  shortcuts: PREVIEW_CONTEXT_SHORTCUTS,
};
