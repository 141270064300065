:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._border_9cc9d_7 {
  height: 1px; }
  ._border_9cc9d_7 line {
    stroke: #868aa5;
    stroke-width: 1px;
    stroke-dasharray: 4 4;
    stroke-dashoffset: 4; }
  ._border_9cc9d_7._mobileEditor_9cc9d_14._left_9cc9d_14 {
    transform: rotate(180deg); }
