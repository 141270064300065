import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['Stylable_Horizontal_Menu_Design_DiscoverMore_Label'],
      sections: [
        addPanel.sections.getSections().menu.stylableHorizontalMenuSection,
      ],
    };
  },
};
