import {
  BANDSINTOWN,
  INSTAGRAM_FEED,
  WIX_BLOG,
  WIX_BOOKINGS,
  WIX_EVENTS,
  WIX_FORMS,
  WIX_GROUPS,
  WIX_MUSIC,
  WIX_NEW_STORES,
  WIX_PRO_GALLERY,
  WIX_RESERVATIONS,
  WIX_STORES,
  WIX_VIDEO,
} from '@wix/app-definition-ids';

export const DEFAULT_INDUSTRY_ID = 'e79e5765052b89b579c9a4c2';
export const DEFAULT_STRUCTURE_ID = '40dc538a8a6c3e2dae342922';
export const REGEX_ENDS_HYPHEN = /-+$/;
export const SITE_NAME_INVALID_CHARS_REGEX = /[^a-zA-Z0-9\s\-]/g;
export const MIN_SITE_NAME_LENGTH = 4;
export const MAX_SITE_NAME_LENGTH = 20;
export const PAAS_PREVIEW_BASE_SCALE = 0.25;
export const PAAS_PREVIEW_BASE_FRAME_WIDTH = 1200;
export const PLACEHOLDER_DIMENSIONS_RATIO = 1.9;
export const MIN_HEADER_HEIGHT = 260;
export const MIN_FOOTER_HEIGHT = 80;
export const COLUMN_MARGINS = 108;
export const ADDITIONAL_PAGES_COLUMN_NUM = 3;
export const FPD_COUNT = 3;
export const TRANSITION_DURATION_MULTIPLIER = 1.7;
export const SITE_EXTRA_MARGIN = 35;
export const EDITOR_CONTAINERS_BACKGROUND_THEME_COLOR = 'color_11';
export const SITE_CREATION_PROVISION_ORIGIN = 'SITE_CREATION';
export const STORES_WIDGET_STRETCH_RATIO = 3.41;
export const BOOKINGS_WIDGET_STRETCH_RATIO = 1.51;
export enum POSITION {
  AFTER = 'after',
  BEFORE = 'before',
  LAST = 'last',
}
export const TEMPLATES_IDS_WITHOUT_FORMS = [
  1, 5, 10, 11, 14, 16, 20, 22, 26, 31, 32, 35, 37, 41, 43, 47, 52, 53, 56, 58,
  62, 64, 68, 73, 74, 77, 79, 83, 85, 89, 94, 95, 98, 100,
];
export const ALLOWED_CURATED_TEMPLATED_IDS = [
  1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18, 19, 20, 21, 22, 23,
  24, 25, 27, 28, 29, 30, 31, 32, 33, 34, 36, 37, 38, 39, 40, 42, 43, 44, 45,
  46, 48, 49, 50, 51, 52, 53, 54, 55, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
  67, 69, 70, 71, 72, 73, 74, 75, 76, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87,
  88, 90, 91, 92, 93, 94, 95, 96, 97, 99, 100, 101,
];

export const PRO_GALLERY_STRUCTURE_IDS = [
  '72ffeb33127e2da8c0bb763f',
  '5603bb67000870d80c53d49d',
  '9cb832ab093c3f328f6be318',
  'a28c5e5ea1945e5fa4ca1728',
  'a8c41ae5ef3d8694abd189b1',
  '61c4de3716d3210017c996ac',
  '61bb263a79789b001659f0b0',
  '613dc14cc48b550016cba889',
  '6139bcacddb315001629b6ff',
  '60ed97a31b85ad00150f12d5',
  '6065650afbbe520016463a5f',
  '60587061f8b7ca0015556976',
  '604f0ed3630fc000153966b2',
  '5f5f47b5141fca00171104df',
  '5e8b0b06b997ac001740fa21',
  '5e0c6f7e1802fc0017fdfa39',
  '5e033f571802fc0017fdd74f',
  '5dfa059d67e9270017d1fafd',
  '5dec9bcdb2553b00178182ef',
  '5d1a27de432e5d001622653e',
  '5c8281fc0de30f001c1fe7f5',
  '5c405af1aebef4001ca104ab',
  '503eb1485f6d9be61cd7440e',
  'bf61b613f48c3e7af17cfc6c',
  '53a307985ec6701f3b73c928',
  'eb1243457ba9d8838db2d09f',
  'a4def3d162781f1e96ee1956',
  '3118118a1e2e5ef4539a51f3',
  'f7c409d2f51080ca1c82c9bc',
  'ecd6a21fcbc019a9494ee6e0',
  '1a807ebfa9792fc45bfe845e',
  'ff9d59c82e78e4910bf642d7',
  'cd56860bcbe70d98fcfb09a0',
  'ee1f9dbf15f228ad170819ae',
  '7e5b50a94082f1476c1683ee',
  '3a6327d9c3946eefcfe6d513',
  'f19b3125657d6a267d2c47d8',
  '190a5ffad4f67ad6e22ad08d',
  'fa28a6f03dbe530a8a8c6fcc',
  'c280f2565940817f4edc48fe',
  '70d8036f281d1ce612272b6a',
  'cba925039ed9d3d360590d31',
  'f1b75b7b719f5ed5b5bdc41f',
  'fc57b1629c4ff302fb03e79c',
  '228113fd238f42db1cf7160a',
  'c5c1f3c63915119ed5c7feff',
  '4c41f36c745880e6e42c5838',
  'b5ab898f0afbfec00845808e',
  '54004a861a39c4a3eb57d114',
  '6faf46a50ee4aa0fff4e1135',
  '02b40f8270007a792c300561',
  '481a36b3eeb98dbfc2f904a6',
  '94d4b55ce16bd162b833e148',
  '88337e3560e889b475d492a7',
  '0f04c68cffed335f82caabfa',
  '3278ba04c3340b40d058ed5f',
  'f764b4a56aca6b9f42c3ec3a',
  '0fdf8f7c1fd0fb710ecb8826',
  '320bc14e388b60c6ab499242',
  '9b888a944e35417b0916e075',
  'be6f1d8dc988d56f886ed440',
  '494592c679c0d50b9d48a03e',
  '21c70c386045175cb342045a',
  '6796cebe34d21cca1400d748',
  '0d9c96444715ddf86b7dae9c',
  'fa6f57e9df0b2c3dfa39cce9',
  'e5aec35bbc5fc88b438724cf',
  '7bd6f744207891da991c722b',
  '2722ab4c0feb9fa6fdcc1c6b',
  'ede0e37e9de8c82c420ad0b5',
  '85ce4978795e86d267591938',
  '137221e480f86fbd3c80436a',
  'afee35922f1083c84a62b114',
  '87de0335f09ebc252139974c',
  '7d9f2f2d93d10adfb06b97f8',
  '1265240190ad56db08acdf09',
  '882ad867cfb08a76289aed96',
  '8428dfcd16f94a265be269f1',
  '607d1b36a378f4c38206a56b',
  '493a5210864ae549a0f92832',
  'd67cb2d5db577d59438e2f4b',
  '2bca566fc4bdbe2a402e258c',
  '89fa91123688531f0a4ba0ce',
  'd3cad23d161c321b0c1c29a2',
  '2703cbfc864352670063ea62',
  '155249e1f27d95ef5057e337',
  'e672ff2a2a0edaacfab8021d',
  '71caa1d0683e61e706491aee',
  'c9e7b6e466395dae61b94d1c',
  'b3036b2bf042290dc47ed92a',
  '482d4178d8f6458f17da0add',
  'b1908deccf1194b3164084ae',
  '06a674427f1cecccc05833a1',
];

export const BLOG_AS_HOMEPAGE_STRUCTURE_IDS = [
  'b0a520919b99728bae200018',
  '60ebe220e0aacf0015cecf8c',
  '613efcf07b3e9c0016571b2a',
  '611507cbcb41440015253611',
  '6107f122eec48300158889ec',
  '6140a65e7d886100167a7fb5',
  '61a884000d569e0016fd1b6b',
  '61712509276d2b00161abefd',
  '6190c09ea9d7560016809730',
  '61c464e016d3210017c9950b',
];

export const END_SCREEN_LOADER_TEXT_1 =
  'site_creation_business_first_loader_first_step';

export const END_SCREEN_LOADER_TEXT_2 =
  'site_creation_business_first_loader_second_step';

export const END_SCREEN_LOADER_TEXT_3 =
  'site_creation_business_first_loader_third_step';

export const BF_ACTIONS = {
  GO_TO_EDITOR: 'Go to Editor',
  BACK_TO_DASHBOARD: 'Back to Dashboard',
  VIEW_LIVE_SITE: 'View Live Site',
};

const APPS_PRIORITY: string[] = [
  WIX_NEW_STORES,
  WIX_STORES,
  WIX_BOOKINGS,
  WIX_VIDEO,
  WIX_BLOG,
  WIX_FORMS,
  WIX_RESERVATIONS,
  INSTAGRAM_FEED,
  BANDSINTOWN,
  WIX_GROUPS,
  WIX_EVENTS,
  WIX_MUSIC,
  WIX_PRO_GALLERY,
];
interface AppsPriorityMap {
  [appDefId: string]: number;
}
export const APPS_PRIORITY_MAP: AppsPriorityMap = APPS_PRIORITY.reduce(
  (acc, val, index) => {
    acc[val] = index + 1;
    return acc;
  },
  {} as AppsPriorityMap,
);
