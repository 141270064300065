import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import toggleMobileEditor from '../thunks/toggleMobileEditor';
import constants from '@/constants';
import {
  previewWidth as getPreviewWidthBiEvent,
  sliderValueOnPreviewChange as getSliderValueOnPreviewChangeBiEvent,
  topBarClick as getTopBarClickBiEvent,
} from '@wix/bi-logger-editor/v2';
import type {
  previewWidthParams,
  sliderValueOnPreviewChangeParams,
  topBarClickParams,
} from '@wix/bi-logger-editor/v2/types';
import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import type { PreviewType } from '@/stateManagement';
import { TABLET_LAPTOP_WIDTH, TABLET_MIN_WIDTH } from '@/preview';

const { MOBILE_FRAME_WIDTH } = constants.ROOT_COMPS.MOBILE_EDITOR;

interface OwnProps {}
export interface DeviceSwitchWithResizeStateProps {
  isMobileEditor: boolean;
  previewWidth: number;
  baselineWidth: number;
  windowWidth: number;
  previewType: PreviewType;
}
export interface DeviceSwitchWithResizeDispatchProps {
  setPreviewWidth: typeof stateManagement.preview.actions.setPreviewWidth;
  initPreview: (windowWidth: number) => void;
  switchToMobile: (windowWidth: number) => void;
  switchToTablet: (previewWidth: number, windowWidth: number) => void;
  switchToDesktop: (
    previewWidth: number,
    newPreviewWidth: number,
    windowWidth: number,
  ) => void;
  switchToFullScreen: (windowWidth: number) => void;
  disablePreviewResizeAnimation: typeof stateManagement.preview.actions.disablePreviewResizeAnimation;
}

export const mapPreviewTypeToBiPreviewMode = (
  previewType: PreviewType,
  previewWidth: number,
) => {
  if (previewType === 'fullScreen') {
    return 'full_screen';
  }

  if (previewType === 'desktop') {
    return previewWidth >= TABLET_LAPTOP_WIDTH ? 'desktop' : 'tablet';
  }

  return previewType;
};

const mapStateToProps: MapStateToProps<
  DeviceSwitchWithResizeStateProps,
  OwnProps
> = ({ editorAPI, state }) => {
  const isMobileEditor = editorAPI.isMobileEditor();
  const previewWidth = isMobileEditor
    ? MOBILE_FRAME_WIDTH
    : stateManagement.preview.selectors.getPreviewWidth(state);
  return {
    isMobileEditor,
    previewWidth,
    baselineWidth: editorAPI.site.getWidth && editorAPI.site.getWidth(),
    windowWidth:
      stateManagement.domMeasurements.selectors.getEditorContentLayout(state)
        .width,
    previewType: stateManagement.preview.selectors.getPreviewType(state),
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapDispatchToProps: MapDispatchToProps<
  DeviceSwitchWithResizeDispatchProps,
  OwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  const sendPreviewWidthBi = (params: previewWidthParams) => {
    util.biLogger.report(getPreviewWidthBiEvent(params));
  };

  const sendSliderValueOnPreviewChangeBi = (
    params: sliderValueOnPreviewChangeParams,
  ) => {
    util.biLogger.report(getSliderValueOnPreviewChangeBiEvent(params));
  };

  const sendTopBarBi = (params: topBarClickParams) => {
    util.biLogger.report(getTopBarClickBiEvent(params));
  };

  return {
    setPreviewWidth: (previewWidth: number) =>
      dispatch(stateManagement.preview.actions.setPreviewWidth(previewWidth)),
    setPreviewType: (previewType: PreviewType) =>
      dispatch(stateManagement.preview.actions.setPreviewType(previewType)),
    initPreview: (windowWidth: number) => {
      const initialPreviewType = editorAPI.isMobileEditor()
        ? 'mobile'
        : 'desktop';
      const baselineWidth =
        editorAPI.site.getWidth && editorAPI.site.getWidth();
      dispatch(stateManagement.preview.actions.setPreviewWidth(baselineWidth));
      dispatch(
        stateManagement.preview.actions.setPreviewType(initialPreviewType),
      );

      sendPreviewWidthBi({
        preview_mode: mapPreviewTypeToBiPreviewMode(
          initialPreviewType,
          baselineWidth,
        ),
        preview_width: baselineWidth,
        origin: 'open',
        browser_width: windowWidth,
      });
    },
    switchToMobile: (windowWidth: number) => {
      dispatch(stateManagement.preview.actions.disablePreviewResizeAnimation());
      dispatch(stateManagement.preview.actions.setPreviewType('mobile'));
      dispatch(
        toggleMobileEditor(
          true,
          'topBar_switchToDesktop',
          'editor_view_mode_mobile',
        ),
      );

      sendPreviewWidthBi({
        preview_mode: 'mobile',
        preview_width: MOBILE_FRAME_WIDTH,
        origin: 'mobile',
        browser_width: windowWidth,
      });
    },
    switchToTablet: (previewWidth: number, windowWidth: number) => {
      dispatch(stateManagement.preview.actions.setPreviewType('desktop'));
      dispatch(
        stateManagement.preview.actions.setPreviewWidth(TABLET_MIN_WIDTH),
      );

      if (editorAPI.isMobileEditor()) {
        dispatch(
          toggleMobileEditor(
            false,
            'topBar_switchToDesktop',
            'editor_view_mode_tablet',
          ),
        );
      } else {
        sendTopBarBi({
          isPreview: true,
          category: 'editor_view_mode_tablet',
          origin: 'preview',
        });
        dispatch(
          stateManagement.preview.actions.enablePreviewResizeAnimation(),
        );
      }

      sendPreviewWidthBi({
        preview_mode: 'tablet',
        preview_width: TABLET_MIN_WIDTH,
        origin: 'tablet',
        browser_width: windowWidth,
      });
      sendSliderValueOnPreviewChangeBi({
        selected_value: String(TABLET_MIN_WIDTH),
        previous_value: String(previewWidth),
        value_change_origin: 'tablet_button',
        preview_mode: 'tablet',
        is_preview_mode_changed: previewWidth >= TABLET_LAPTOP_WIDTH,
      });
    },
    switchToDesktop: (
      previewWidth: number,
      newPreviewWidth: number,
      windowWidth: number,
    ) => {
      dispatch(stateManagement.preview.actions.setPreviewType('desktop'));
      dispatch(
        stateManagement.preview.actions.setPreviewWidth(newPreviewWidth),
      );

      if (editorAPI.isMobileEditor()) {
        dispatch(
          toggleMobileEditor(
            false,
            'topBar_switchToDesktop',
            'editor_view_mode_desktop',
          ),
        );
      } else {
        sendTopBarBi({
          isPreview: true,
          category: 'editor_view_mode_desktop',
          origin: 'preview',
        });
        dispatch(
          stateManagement.preview.actions.enablePreviewResizeAnimation(),
        );
      }

      sendPreviewWidthBi({
        preview_mode: 'desktop',
        preview_width: newPreviewWidth,
        origin: 'desktop',
        browser_width: windowWidth,
      });
      sendSliderValueOnPreviewChangeBi({
        selected_value: String(newPreviewWidth),
        previous_value: String(previewWidth),
        value_change_origin: 'desktop_button',
        preview_mode: 'desktop',
        is_preview_mode_changed: previewWidth < TABLET_LAPTOP_WIDTH,
      });
    },
    switchToFullScreen: (windowWidth: number) => {
      dispatch(stateManagement.preview.actions.setPreviewType('fullScreen'));

      if (editorAPI.isMobileEditor()) {
        dispatch(
          toggleMobileEditor(false, 'topBar_switchToDesktop', 'full_screen'),
        );
      } else {
        sendTopBarBi({
          isPreview: true,
          category: 'full_screen',
          origin: 'preview',
        });
        dispatch(
          stateManagement.preview.actions.enablePreviewResizeAnimation(),
        );
      }

      sendPreviewWidthBi({
        preview_mode: 'full_screen',
        preview_width: windowWidth,
        origin: 'full_screen',
        browser_width: windowWidth,
      });
    },
    disablePreviewResizeAnimation:
      stateManagement.preview.actions.disablePreviewResizeAnimation,
  };
};

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

export default connect(EDITOR_API, mapStateToProps, mapDispatchToProps);
