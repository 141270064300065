import _ from 'lodash';
import pageCategories from '../utils/pageCategories';
import type { EditorAPI } from '@/editorAPI';
import * as actions from './actions/actions';
import type { PagesData } from 'types/documentServices';
import type {
  OnSettingsChangeHandler,
  RouterAction,
  RouterViewActions,
} from '../panel/types';

export interface ActionsPerRouter {
  [pageId: string]: ReturnType<typeof actions.dynamicPage>;
}

export interface RouterPageData {
  id: string;
  isVisible: boolean;
  isVisibleMobile: boolean;
  label?: string;
  title?: string;
  pageData: PagesData;
  permissionSymbol?: string;
  type?: {
    isDynamicPage: boolean;
    isPage: boolean;
    routerType: string;
  };
}

export interface IRouter {
  id: string;
  title: string;
  applicationId?: number;
  pagesData: RouterPageData[];
  prefix: string;
  biCategory: string;
  isSpecial?: boolean;
  routerType: string;
  actions?: RouterAction[];
  viewActions?: RouterViewActions;
}

const getPagePermissionSymbol = (pageData: AnyFixMe) => {
  const isMembersPermission = pageData?.pageSecurity?.requireLogin;
  if (isMembersPermission) {
    return 'membersPagePermission';
  }
  const isPasswordPermission = pageData?.pageSecurity?.dialogLanguage;
  if (isPasswordPermission) {
    return 'passwordPagePermission';
  }
  return null;
};

export const getRouters = (
  editorAPI: EditorAPI,
  pagesAppDefId: Record<string, boolean>,
  pageIdsToIgnore: string[] = [],
): IRouter[] =>
  _(editorAPI.pages.dynamicPages.getMenuItems())
    .reject(
      ({ items }) =>
        _.isEmpty(items) ||
        _.isEmpty(
          items.filter(
            (item) =>
              !pagesAppDefId[item.pageData.managingAppDefId] &&
              !pageIdsToIgnore.includes(item.id),
          ),
        ),
    )
    .map(({ id, label, items, prefix, routerType }) => {
      const isDesktop = editorAPI.isDesktopEditor();
      const pageRef = editorAPI.dsRead.pages.getReference(items[0].id);
      const routerRef = editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);
      const { appDefinitionId } = editorAPI.dsRead.routers.get.byRef(routerRef);
      const membersAppDefId =
        editorAPI.dsRead.platform.editorApps.SANTA_MEMBERS.appDefId;

      const isSpecial = appDefinitionId === membersAppDefId; //another special pages can be here in the future
      const biCategory = isSpecial
        ? pageCategories.SPECIAL_PAGES.getBiCategory(
            appDefinitionId,
            membersAppDefId,
          )
        : pageCategories.DYNAMIC_PAGES.biCategory;

      const applicationId =
        editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId)
          ?.applicationId;

      const viewActions = actions.routerView(
        editorAPI,
        isDesktop,
        applicationId,
        prefix,
        biCategory,
        isSpecial,
        routerType,
      );
      return {
        id,
        title: label,
        pagesData: items.map((item) => ({
          ...item,
          permissionSymbol: getPagePermissionSymbol(item.pageData),
        })),
        prefix,
        applicationId,
        isSpecial,
        biCategory,
        routerType,
        actions: actions.router(
          editorAPI,
          isDesktop,
          applicationId,
          prefix,
          biCategory,
          isSpecial,
          routerType,
        ),
        viewActions,
      };
    })
    .compact()
    .value();

export const getRouterPagesWithActions = (
  editorAPI: EditorAPI,
  pagesData: RouterPageData[],
  onSettingsChangeHandler: OnSettingsChangeHandler,
  isDesktop: boolean,
  biCategory: AnyFixMe,
): ActionsPerRouter | {} =>
  pagesData.reduce(
    (actionsPerPage: Record<string, RouterAction[]>, pageData) => {
      const pageId = pageData.id;
      const pageRef = editorAPI.dsRead.pages.getReference(pageId);
      const routerRef = editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);

      const { appDefinitionId } = editorAPI.dsRead.routers.get.byRef(routerRef);
      const { applicationId } =
        editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId) || {};

      actionsPerPage[pageId] = actions.dynamicPage(
        editorAPI,
        pageId,
        applicationId,
        routerRef,
        onSettingsChangeHandler,
        isDesktop,
        biCategory,
      );
      return actionsPerPage;
    },
    {},
  );

export default {
  getRouters,
  getRouterPagesWithActions,
};
