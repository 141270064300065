import PropTypes from 'prop-types';
import { translate } from '@/i18n';
import * as mediaManagerConstants from '../../mediaManagerPanelConstants/mediaManagerPanelConstants';
import * as mediaManagerPanelUtils from '../../mediaManagerPanelUtils/mediaManagerPanelUtils';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import * as baseUI from '@/baseUI';
import PanelSection from '../../mediaManagerPanelPresets/components/section';
import { cx } from '@/util';
import uploadButtonStyles from '../../mediaManagerPanelPresets/components/uploadButton.scss';

const {
  MEDIA_MANAGER_UPLOAD_SOURCES,
  SECTIONS_TITLES_MAP,
  MEDIA_MANAGER_PANEL_SECTION_ID,
} = mediaManagerConstants;

const ICONS_NAMES = {
  [MEDIA_MANAGER_UPLOAD_SOURCES.COMPUTER]: 'upload-btn',
  [MEDIA_MANAGER_UPLOAD_SOURCES.FACEBOOK]: 'media-manager-panel-facebook-icon',
  [MEDIA_MANAGER_UPLOAD_SOURCES.INSTAGRAM]:
    'media-manager-panel-instagram-icon',
  [MEDIA_MANAGER_UPLOAD_SOURCES.GOOGLE_DRIVE]:
    'media-manager-panel-googledrive-icon',
  [MEDIA_MANAGER_UPLOAD_SOURCES.GOOGLE_PHOTOS]:
    'media-manager-panel-googlephotos-icon',
};
const TRANSLATION_KEYS = {
  [MEDIA_MANAGER_UPLOAD_SOURCES.COMPUTER]:
    'Media_Panel_Upload_Upload_Media_Tooltip',
  [MEDIA_MANAGER_UPLOAD_SOURCES.FACEBOOK]:
    'Media_Panel_Upload_Facebook_Tooltip',
  [MEDIA_MANAGER_UPLOAD_SOURCES.INSTAGRAM]:
    'Media_Panel_Upload_Instagram_Tooltip',
  [MEDIA_MANAGER_UPLOAD_SOURCES.GOOGLE_DRIVE]:
    'Media_Panel_Upload_Google_Drive_Tooltip',
  [MEDIA_MANAGER_UPLOAD_SOURCES.GOOGLE_PHOTOS]:
    'Media_Panel_Upload_Google_Photos_Tooltip',
};
const SOURCES_ORDER = [
  MEDIA_MANAGER_UPLOAD_SOURCES.COMPUTER,
  MEDIA_MANAGER_UPLOAD_SOURCES.FACEBOOK,
  MEDIA_MANAGER_UPLOAD_SOURCES.INSTAGRAM,
  MEDIA_MANAGER_UPLOAD_SOURCES.GOOGLE_DRIVE,
  MEDIA_MANAGER_UPLOAD_SOURCES.GOOGLE_PHOTOS,
];

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  openMediaManager: FunctionFixMe;

  helpTitle?: string;
  helpDescription?: string;
}

export default class extends React.Component<Props> {
  static displayName = 'MediaManagerPanelAddMediaSection';

  static propTypes = {
    openMediaManager: PropTypes.func.isRequired,
  };

  isLocalSource = (source: AnyFixMe) => {
    return mediaManagerPanelUtils.isUploadAddMediaSource(source);
  };

  getTitle = () => {
    return translate(
      SECTIONS_TITLES_MAP[MEDIA_MANAGER_PANEL_SECTION_ID.ADD_MEDIA],
    );
  };

  getSources = () => {
    return SOURCES_ORDER;
  };

  getIconName = (source: AnyFixMe) => {
    return ICONS_NAMES[source];
  };

  getSourceTooltipText = (source: AnyFixMe) => {
    return TRANSLATION_KEYS[source];
  };

  generateOpenPath = (source: AnyFixMe) => {
    return `external/${source}`;
  };

  handleAddMediaButtonClick = (source: AnyFixMe) => {
    this.props.openMediaManager({
      source,
      biSource: `upload-media-section_${source}`,
      path: this.generateOpenPath(source),
    });
  };

  render() {
    return (
      <PanelSection
        title={this.getTitle()}
        className="media-manager-panel-add-media-section"
        helpTitle={this.props.helpTitle}
        helpDescription={this.props.helpDescription}
      >
        <ul className="media-manager-panel-add-media-section__sources">
          {this.getSources().map((source) => (
            <li
              key={source}
              className="media-manager-panel-add-media-section__source"
            >
              <baseUI.tooltip
                value={this.getSourceTooltipText(source)}
                delay="300"
              >
                <button
                  onClick={() => this.handleAddMediaButtonClick(source)}
                  className={cx(
                    'media-manager-panel-add-media-section__source-button',
                    {
                      [uploadButtonStyles.uploadButton]:
                        this.isLocalSource(source),
                    },
                  )}
                >
                  <symbols.symbol
                    name={this.getIconName(source)}
                    key="externalSource"
                  />
                </button>
              </baseUI.tooltip>
            </li>
          ))}
        </ul>
      </PanelSection>
    );
  }
}
