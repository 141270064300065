import { selectUserCollections } from '../../collections';
import type { MapStateToProps } from 'types/redux';

import type { Schema } from '@wix/wix-data-schema-types';

export interface UserCollectionsListStateProps {
  collections: Schema[];
}

export const mapStateToProps: MapStateToProps<
  UserCollectionsListStateProps
> = ({ state }) => ({
  collections: selectUserCollections(state),
});
