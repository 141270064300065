import React from 'react';
import * as mediaPanelConsts from '../../../mediaManagerPanelConstants/mediaManagerPanelConstants';
import ShutterstockMediaBaseSection from './shutterstockMediaBaseSection';

class ShutterstockVideosSection extends React.Component {
  getProps() {
    return {
      title: 'Media_Panel_Shutterstock_Videos_Section_Label',
      mediaType: mediaPanelConsts.SHUTTERSTOCK_MEDIA_TYPE.VIDEO,
      ...this.props,
    };
  }

  render() {
    return <ShutterstockMediaBaseSection {...this.getProps()} />;
  }
}

export default ShutterstockVideosSection;
