// @ts-nocheck
import * as util from '@/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { runInContext } from '../../../common/utils';
import { convertDimensionsByDevice } from './utils';
import * as stateManagement from '@/stateManagement';

const { isRefComponent } = stateManagement.components.selectors;
import type { EditorAPI } from '@/editorAPI';
import type {
  VariableValue,
  CompRef,
  UnitSize,
  Alignment,
  JustifyContent,
  VariableValueType,
} from 'types/documentServices';
import type {
  AppData,
  addWidgetOptions,
  PlatformUnitSize,
  DesignVariables,
  DesignVariableValue,
  DesignVariableValueValue,
} from '@wix/platform-editor-sdk';
import type { CompStructure } from 'types/documentServices';
import { EditorType, InstallInitiator } from '@wix/platform-editor-sdk';

const componentsSelectors = stateManagement.components.selectors;

const EXTENSIONS_CONTEXT = 'extension';
const isExtensionContext = (appData: AppData) =>
  appData.context === EXTENSIONS_CONTEXT;

function install(
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  options,
) {
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.INSTALL_APP,
  );
  return new Promise(function (resolve, reject) {
    const appDefinitionId = options?.appDefinitionId;
    if (!appDefinitionId) {
      reject(new Error('options must contain appDefinitionId'));
      return;
    }
    if (appDefinitionId === editorAPI.platform.editorApps.WIX_CODE.appDefId) {
      editorAPI.wixCode.provision({
        onSuccess: resolve,
        onError: reject,
      });
      return;
    }
    const type = editorAPI.tpa.bi.consts.APP.TYPE.PLATFORM_ONLY;
    const biInitiator = editorAPI.tpa.bi.consts.BI.initiator.PLATFORM;
    const callback = (data) => {
      if (data?.success) {
        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.INSTALL_APP,
        );
        resolve(data);
      } else {
        ErrorReporter.captureException(data, {
          tags: { appInstallFailed: appDefinitionId },
          extra: { appData, options },
        });
        reject(data);
      }
    };
    const platformOrigin = {
      type: EditorType.Classic,
      initiator: InstallInitiator.App,
      info: {
        appDefinitionId: appData?.appDefinitionId,
        ...options?.originInfo,
      },
    };
    editorAPI.tpa.services.tpaAddAppService.addApp(
      editorAPI,
      options.appDefinitionId,
      null,
      null,
      type,
      biInitiator,
      false,
      options.originInfo?.biData || {},
      {},
      callback,
      platformOrigin,
    );
  });
}

function uninstall(
  editorAPI: EditorAPI,
  _appData: AppData,
  token: string,
  options = {},
) {
  const { openConfirmation } = options;
  const appData = isExtensionContext(_appData)
    ? {
        applicationId: options.applicationId,
        appDefinitionId: options.appDefinitionId,
      }
    : _appData;

  return editorAPI.store.dispatch(
    stateManagement.platform.actions.uninstallApp(appData, openConfirmation),
  );
}

function changeVariation(
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  { componentRef, variationId, keepOverrides },
) {
  const shouldDeselectComponents = !isRefComponent(
    componentRef,
    editorAPI.dsRead,
  );

  if (shouldDeselectComponents) {
    editorAPI.selection.deselectComponents();
  }

  return new Promise((resolve, reject) => {
    const onSuccess = (newCompRef) => {
      editorAPI.selection.selectComponentByCompRef(newCompRef);
      resolve(newCompRef);
    };
    runInContext(appData.appDefinitionId, editorAPI, () =>
      editorAPI.dsActions.appStudioWidgets.changeVariation(
        componentRef,
        variationId,
        onSuccess,
        reject,
        { keepOverrides },
      ),
    );
  });
}

function convertScopedPresets(editorAPI: EditorAPI, presets) {
  if (presets.mobile) {
    const pageMobileVariant = editorAPI.mobile.getMobileVariant();
    return {
      [pageMobileVariant.id]: presets.mobile,
    };
  }
}

function addWidget(
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  options: addWidgetOptions,
) {
  const { widgetId, scopedPresets, presetIds, layouts, ...moreOptions } =
    options;
  const containerRef =
    options.containerRef ||
    editorAPI.addPanelInfra.addPanelUtils.getContainerToAddTo(editorAPI, {
      layout: options.layout,
    } as CompStructure);
  return new Promise((resolve, reject) => {
    runInContext(appData.appDefinitionId, editorAPI, () =>
      editorAPI.dsActions.appStudioWidgets.addWidget(
        appData.appDefinitionId,
        widgetId,
        {
          ...moreOptions,
          containerRef,
          presets: scopedPresets?.desktop ?? presetIds,
          scopedPresets: convertScopedPresets(editorAPI, scopedPresets ?? {}),
          ...(options.dimensionsByDevice
            ? convertDimensionsByDevice(
                options.dimensionsByDevice,
                options.layout,
              )
            : {}),
          onSuccess: async (newWidgetRef) => {
            const isCompOnCurrentPage = editorAPI.utils.isSameRef(
              editorAPI.components.getPage(newWidgetRef),
              editorAPI.pages.getCurrentPage(),
            );
            if (isCompOnCurrentPage) {
              editorAPI.selection.selectComponentByCompRef(newWidgetRef);
            }

            await editorAPI.components.hooks.componentAddedToStage.fire({
              type: 'editorSdk',
              origin: 'editorSdk',
              compRef: newWidgetRef,
            });

            resolve(newWidgetRef);
            return;
          },
          onError: reject,
        },
      ),
    );
  });
}

const changePreset = (
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  { componentRef, stylePresetId, layoutPresetId, context },
) => {
  if (!componentsSelectors.isRefComponent(componentRef, editorAPI.dsRead)) {
    throw new Error('Change preset is only available for RefComponents');
  }

  const { viewport } = context ?? {};
  if (viewport && !(viewport in editorAPI.dsRead.viewMode.VIEW_MODES)) {
    throw new Error(`The ${viewport} viewport is not supported in this editor`);
  }

  editorAPI.store.dispatch(
    stateManagement.platform.actions.changePresetByViewMode(
      componentRef,
      {
        layoutPresetId,
        stylePresetId,
      },
      viewport ?? editorAPI.dsRead.viewMode.get(),
    ),
  );

  return editorAPI.waitForChangesAppliedAsync();
};

const getScopedCompRef = (editorAPI: EditorAPI, componentRef: CompRef) => {
  if (
    editorAPI.dsRead.viewMode.get() !==
    editorAPI.dsRead.viewMode.VIEW_MODES.MOBILE
  ) {
    return componentRef;
  }

  const mobileVariant = editorAPI.mobile.getMobileVariant();
  if (!mobileVariant) {
    return;
  }
  const compVariantRef = editorAPI.components.variants.getPointer(
    componentRef,
    mobileVariant,
  );

  return compVariantRef;
};

const getPreset = (
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  { componentRef }: { componentRef: CompRef },
) => {
  const scopedCompRef = getScopedCompRef(editorAPI, componentRef);
  const scopedPreset =
    scopedCompRef &&
    editorAPI.dsRead.appStudioWidgets.presets.get(scopedCompRef);
  return (
    scopedPreset ?? editorAPI.dsRead.appStudioWidgets.presets.get(componentRef) //fallback to desktop preset
  );
};

const convertUnitSizeToPlatformUnitSize = (
  variableValue: VariableValue,
): DesignVariableValue => {
  if (variableValue.type === 'UnitSizeValue') {
    const variableValueValue: UnitSize = variableValue.value as UnitSize;
    return {
      type: variableValue.type,
      value: {
        value: variableValueValue.value,
        unit: variableValueValue.type,
      } as PlatformUnitSize,
    } as DesignVariableValue;
  }
  return variableValue as DesignVariableValue;
};

const getDesignVariables = async (
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  { widgetRef }: { widgetRef: CompRef },
): Promise<DesignVariables> => {
  const scopedWidgetRef = getScopedCompRef(editorAPI, widgetRef) ?? widgetRef;

  const variablesPointers = editorAPI.components.variables.list(widgetRef);
  const variablesData = variablesPointers.reduce(
    (acc: DesignVariables, variablePointer) => {
      const variableData = editorAPI.components.variables.get(
        widgetRef,
        variablePointer,
      );
      const variableValue =
        editorAPI.components.variables.value.get(
          scopedWidgetRef,
          variablePointer,
        ) ??
        editorAPI.components.variables.value.get(widgetRef, variablePointer);
      acc[variableData.name ?? ''] =
        convertUnitSizeToPlatformUnitSize(variableValue).value;
      return acc;
    },
    {},
  );

  return variablesData;
};

const getVariablesMap = (editorAPI: EditorAPI, widgetRef: CompRef) => {
  const variablesPointers = editorAPI.components.variables.list(widgetRef);
  const variablesMap = variablesPointers.reduce(
    (acc: DesignVariables, variablePointer) => {
      const variableData = editorAPI.components.variables.get(
        widgetRef,
        variablePointer,
      );
      acc[variableData.name ?? ''] = {
        pointer: variablePointer,
        data: variableData,
      };
      return acc;
    },
    {},
  );

  return variablesMap;
};

const convertPlatformUnitSizeToUnitSize = (
  designVariableValue: DesignVariableValue,
): VariableValue => {
  if (designVariableValue.type === 'UnitSizeValue') {
    const designVariableValueValue: PlatformUnitSize =
      designVariableValue.value as PlatformUnitSize;
    return {
      type: designVariableValue.type,
      value: {
        value: designVariableValueValue.value,
        type: designVariableValueValue.unit,
      },
    };
  }
  return designVariableValue as VariableValue;
};
const allowedUnitSizeVarUnits = ['px', 'percentage', 'vh', 'vw', 'fr'];
const allowedItemsAlignmentVarUnits = ['start', 'end', 'center', 'stretch'];
const allowedJustifyContentVarUnits = [
  'start',
  'end',
  'center',
  'spaceBetween',
  'spaceAround',
];

const isAlignment = (
  variableValue: DesignVariableValueValue,
): variableValue is Alignment =>
  typeof variableValue === 'string' &&
  allowedItemsAlignmentVarUnits.includes(variableValue);

const isJustifyContent = (
  variableValue: DesignVariableValueValue,
): variableValue is JustifyContent =>
  typeof variableValue === 'string' &&
  allowedJustifyContentVarUnits.includes(variableValue);

const isPlatformUnitSize = (
  variableValue: DesignVariableValueValue,
): variableValue is PlatformUnitSize =>
  typeof variableValue === 'object' &&
  allowedUnitSizeVarUnits.includes(variableValue.unit) &&
  typeof variableValue.value === 'number';

const validateItemsAlignmentInputValue = (
  variableName: string,
  variableValue: DesignVariableValueValue,
) => {
  if (!isAlignment(variableValue)) {
    throw new Error(
      `Type error: Expected string with a valid item alignment value for design variable '${variableName}'. Learn more at: https://support.wix.com/en/article/use-design-variables-in-panel-code#item-alignment-variables`,
    );
  }
};

const validateJustifyContentInputValue = (
  variableName: string,
  variableValue: DesignVariableValueValue,
) => {
  if (!isJustifyContent(variableValue)) {
    throw new Error(
      `Type error: Expected string with a valid content justification value for design variable '${variableName}'. Learn more at: https://support.wix.com/en/article/use-design-variables-in-panel-code#content-justification-variables`,
    );
  }
};

const validateNumberInputValue = (
  variableName: string,
  variableValue: DesignVariableValueValue,
) => {
  if (typeof variableValue !== 'number') {
    throw new Error(
      `Type error: Expected number for design variable '${variableName}'. Learn more at: https://support.wix.com/en/article/use-design-variables-in-panel-code#numeric-variables`,
    );
  }
};

const validateUnitSizeInputValue = (
  variableName: string,
  variableValue: DesignVariableValueValue,
) => {
  if (!isPlatformUnitSize(variableValue)) {
    throw new Error(
      `Type error: Expected object with valid size properties for design variable '${variableName}'. Learn more at: https://support.wix.com/en/article/use-design-variables-in-panel-code#size-variables`,
    );
  }
};

const validateVariableValueType = (
  variableName: string,
  variableType: VariableValueType,
  newVariableValue: DesignVariableValueValue,
) => {
  switch (variableType) {
    case 'ItemsAlignmentValue':
      validateItemsAlignmentInputValue(variableName, newVariableValue);
      break;
    case 'JustifyContentValue':
      validateJustifyContentInputValue(variableName, newVariableValue);
      break;
    case 'NumberValue':
      validateNumberInputValue(variableName, newVariableValue);
      break;
    case 'UnitSizeValue':
      validateUnitSizeInputValue(variableName, newVariableValue);
  }
};

const setDesignVariables = async (
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  { widgetRef, newValues }: { widgetRef: CompRef; newValues: DesignVariables },
) => {
  const scopedWidgetRef = getScopedCompRef(editorAPI, widgetRef);
  const variablesMap = getVariablesMap(editorAPI, widgetRef);

  Object.entries(newValues).forEach(([name, newValue]) => {
    const variableData = variablesMap[name];
    if (!variableData) {
      throw new Error(`design variable ${name} does not exist`);
    }
    const type = variableData.data.value.type;
    validateVariableValueType(name, type, newValue);

    editorAPI.components.variables.value.update(
      scopedWidgetRef,
      variableData.pointer,
      convertPlatformUnitSizeToUnitSize({ type, value: newValue }),
    );
  });

  editorAPI.history.add('set designVariables');
};

const appStudioWidgets = {
  changeVariation,
  addWidget,
  changePreset,
  getPreset,
  designVariables: {
    get: getDesignVariables,
    set: setDesignVariables,
  },
};

export { install, appStudioWidgets, uninstall };
