import React from 'react';
import { Button, TextLabel } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import ascendBi from '../common/bi/ascendBi';
import * as utils from '../common/utils';

interface VirtualPhoneNumberPanelProps {
  navigateToVirtualPhoneNumber(): void;
  sendBi(event: object, parameters: object): void;
  biBaseParams: any;
}

class VirtualPhoneNumberPanel extends React.Component<VirtualPhoneNumberPanelProps> {
  componentDidMount() {
    const { sendBi, biBaseParams } = this.props;

    sendBi(ascendBi.events.TAB_VIEW, {
      ...biBaseParams,
      tab_name: 'BusinessPhoneNumber',
    });
  }

  render() {
    const { navigateToVirtualPhoneNumber, sendBi, biBaseParams } = this.props;
    const symbolName = util.workspace.isNewWorkspaceEnabled()
      ? 'ascendVirtualPhoneNumberPanelLogo_NewWorkspace'
      : 'ascendVirtualPhoneNumberPanelLogo';

    return (
      <div className="super-app-extra-panel">
        <div className="virtual-phone-number-panel">
          <div className="body">
            <symbols.symbol name={symbolName} className="logo" />
            <div className="title">
              <TextLabel
                value="AscendButton_BusinessPhoneNumber_Title"
                type="T09"
              />
            </div>
            <div className="subtitle">
              <TextLabel
                value="AscendButton_BusinessPhoneNumber_Text"
                type="T07"
                enableEllipsis={false}
              />
            </div>
            <Button
              onClick={() => {
                sendBi(ascendBi.events.PRODUCT_CLICK, {
                  ...biBaseParams,
                  tab_name: 'BusinessPhoneNumber',
                  product_name: 'BusinessPhoneNumber',
                });
                navigateToVirtualPhoneNumber();
              }}
              className="virtual-phone-number-button btn-md"
            >
              {translate('AscendButton_GetBusinessNumber_CTA')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapStateToProps = ({ editorAPI, state }: AnyFixMe) => {
  return {
    biBaseParams: ascendBi.biUtils.getBiBaseParams({ editorAPI, state }),
  };
};

const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    navigateToVirtualPhoneNumber: () =>
      utils.navigateToAscend(editorAPI, 'virtualPhoneNumber'),
    sendBi: (event: AnyFixMe, parameters: AnyFixMe) =>
      dispatch(stateManagement.bi.actions.event(event, parameters)),
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(VirtualPhoneNumberPanel);
