import ReactDOM from 'reactDOM';
import createReactClass from 'create-react-class';
import {
  backgroundUtils,
  cx,
  imageTransform,
  serviceTopology,
  url,
} from '@/util';
import constants, { type BackgroundData } from '@/constants';
import * as core from '@/core';
import React from 'react';
import {
  generate as generateGradient,
  type GradientLinear,
  type GradientCircle,
  type GradientEllipse,
  type GradientConic,
  type GradientMesh,
} from '@wix/css-gradient-generator';

interface Quality {
  quality: string;
}

const PANEL_WIDTH = 270;
const PANEL_HEIGHT = 152;
const PREFERRED_VIDEO_QUALITY = '480p';
const mediaTypes = constants.MEDIA_TYPES;
const { getColorValue, getOverlayStyle } = backgroundUtils;
function getPreferredQuality(qualities: Quality[]) {
  return qualities?.find(({ quality }) => quality === PREFERRED_VIDEO_QUALITY);
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'mediaPreview',
  mixins: [core.mixins.editorAPIMixin],

  getInitialState() {
    return { isPreviewReady: false };
  },
  componentDidMount() {
    if (this.getMediaType() === mediaTypes.VIDEO) {
      this.getInstance().addEventListener('timeupdate', this.onPlaying);
    }
  },
  componentWillUnmount() {
    if (this.getMediaType() === mediaTypes.VIDEO) {
      this.getInstance().removeEventListener('timeupdate', this.onPlaying);
      this.removeVideoSecurely();
    }
  },

  onPlaying() {
    if (!this.state.isPreviewReady) {
      this.setState({ isPreviewReady: true });
    }
  },

  getInstance(): HTMLVideoElement {
    return ReactDOM.findDOMNode(this.refs.videoInstance);
  },

  removeVideoSecurely() {
    const video = this.getInstance();
    video.pause();
    [...video.children].forEach((child: Element) => {
      if (child.nodeName.toLowerCase() === 'source') {
        child.setAttribute('src', '');
      }
    });
    video.load();
  },

  getMediaType() {
    return backgroundUtils.getMediaType(this.props.data);
  },

  isMediaVideo() {
    return this.getMediaType() === mediaTypes.VIDEO;
  },

  isMediaImage() {
    return this.getMediaType() === mediaTypes.IMAGE;
  },

  isGradient() {
    return (
      this.getMediaType() === mediaTypes.COLOR &&
      this.props.data.colorLayers &&
      this.props.data.colorLayers[0].fill.type !== 'SolidColor'
    );
  },

  getOverlayStyle() {
    return getOverlayStyle(this.props.data, this.getEditorAPI());
  },

  getColorValue(
    data: BackgroundData,
  ):
    | string
    | GradientLinear
    | GradientCircle
    | GradientEllipse
    | GradientConic
    | GradientMesh {
    const editorAPI = this.getEditorAPI();
    const fill =
      data.colorLayers?.[0].fill || getColorValue(editorAPI, data.color);
    return fill?.type === 'SolidColor'
      ? getColorValue(editorAPI, fill.color)
      : fill;
  },

  getBackgroundColor() {
    const editorAPI = this.getEditorAPI();
    const value = this.getColorValue(this.props.data);
    if (typeof value === 'string') {
      return { backgroundColor: getColorValue(editorAPI, value) };
    }
    return {
      backgroundImage: generateGradient(value, {
        resolveColor: editorAPI.theme.colors.get,
      }),
    };
  },

  getBackgroundStyle(mediaType: AnyFixMe) {
    const bgData = this.props.data;
    const imageData =
      mediaType === mediaTypes.VIDEO
        ? bgData.mediaRef.posterImageRef
        : bgData.mediaRef;
    let fittingType;
    if (
      bgData.fittingType ===
        imageTransform.fittingTypes.LEGACY_BG_FIT_AND_TILE ||
      bgData.fittingType === imageTransform.fittingTypes.TILE
    ) {
      const isSmaller = backgroundUtils.isSmallerFromContainer(
        imageData.width,
        imageData.height,
        PANEL_WIDTH,
        PANEL_HEIGHT,
      );
      fittingType = isSmaller
        ? imageTransform.fittingTypes.TILE
        : imageTransform.fittingTypes.FIT_AND_TILE;
    } else {
      fittingType = imageTransform.fittingTypes.SCALE_TO_FILL;
    }
    const previewDisplayData = backgroundUtils.getImageDisplayData(
      fittingType,
      bgData.alignType,
      imageData.uri,
      imageData.width,
      imageData.height,
      PANEL_WIDTH,
      PANEL_HEIGHT,
      85,
    );
    const bgStyle = previewDisplayData.css.container as React.CSSProperties;
    bgStyle.backgroundImage = `url(${url.joinURL(
      serviceTopology.staticMediaUrl,
      previewDisplayData.uri,
    )})`;
    bgStyle.opacity = imageData.opacity;
    return Object.assign(bgStyle, previewDisplayData.css.container);
  },
  getVideoSource() {
    const videoData = this.props.data.mediaRef;
    const preferredQuality =
      getPreferredQuality(videoData.qualities) || videoData.qualities[0];

    return url.joinURL(serviceTopology.staticVideoUrl, preferredQuality.url);
  },
  getVideoStyle() {
    //todo: should be computed if aspect ratio of video is different from panel
    return {
      width: PANEL_WIDTH,
      height: PANEL_HEIGHT,
      opacity: this.props.data.mediaRef.opacity,
    };
  },

  getSecInMin() {
    const seconds = Math.round(this.props.data.mediaRef.duration);
    const sec = seconds % 60;
    const min = Math.floor(seconds / 60);
    return `${min}:${sec < 10 ? `0${sec}` : sec}`;
  },

  getMediaCaption() {
    return this.getMediaType() !== mediaTypes.COLOR
      ? this.props.data.mediaRef.title || ''
      : '';
  },
  render() {
    return (
      <div className="media-preview-panel">
        <div
          className={cx({
            'media-preview-wrapper': true,
            hide: this.isMediaVideo() && !this.state.isPreviewReady,
            show: this.isMediaVideo() && this.state.isPreviewReady,
          })}
        >
          <div
            style={this.getBackgroundColor()}
            className="media-preview-color"
          />
          {this.isMediaImage() ? (
            <div
              key="media-preview-image"
              style={this.getBackgroundStyle(this.getMediaType())}
              className="media-preview-image"
            />
          ) : null}

          {this.isMediaVideo() ? (
            <div
              key="media-preview-video-wrapper"
              style={this.getVideoStyle()}
              className="media-preview-video-wrapper"
            >
              <video
                autoPlay
                loop
                muted
                preload="none"
                ref="videoInstance"
                className="media-preview-video"
              >
                <source src={this.getVideoSource('mp4')} type="video/mp4" />
              </video>
            </div>
          ) : null}
          <div
            style={this.getOverlayStyle()}
            className="media-preview-overlay"
          />
        </div>
        <div className="media-preview-caption">
          <span className="media-title">{this.getMediaCaption()}</span>
          {this.isMediaVideo() ? (
            <span key="video-duration" className="video-duration">
              {this.getSecInMin()}
            </span>
          ) : null}
        </div>
      </div>
    );
  },
});
