import React from 'react';
import * as panels from '@/panels';
import * as baseUI from '@/baseUI';
import { translate } from '@/i18n';
import * as util from '@/util';
interface AppControllerDeleteConfirmationProps {
  controllerDisplayName: string;
  isSingleController: boolean;
  onConfirm: () => void;
}
class AppControllerDeleteConfirmationPanel extends React.Component<AppControllerDeleteConfirmationProps> {
  getContentText() {
    const key = this.props.isSingleController
      ? 'PLATFORM_messages_delete_dataset_text'
      : 'PLATFORM_messages_multidelete_dataset_text';
    return translate(key);
  }

  getTitle() {
    const key = this.props.isSingleController
      ? 'PLATFORM_messages_delete_dataset_title'
      : 'PLATFORM_messages_multidelete_dataset_title';
    return translate(key);
  }

  render() {
    const isNewWorkspaceEnabled = util.workspace.isNewWorkspaceEnabled();
    return isNewWorkspaceEnabled ? (
      <panels.frames.MessagePanelFrame
        panelName="platform.panels.appControllerDeleteConfirmationPanel"
        onConfirm={this.props.onConfirm}
        confirmLabel={translate('PLATFORM_messages_delete')}
        title={this.getTitle()}
        illustration={<baseUI.symbol name="deleteGeneralIcon_NewWorkspace" />}
        cancelLabel={translate('PLATFORM_messages_cancel_delete')}
        theme="destructive"
      >
        {this.getContentText()}
      </panels.frames.MessagePanelFrame>
    ) : (
      <panels.frames.ConfirmationPanelFrame
        type="important"
        panelName="platform.panels.appControllerDeleteConfirmationPanel"
        onConfirm={this.props.onConfirm}
        confirmLabel="PLATFORM_messages_delete"
        cancelLabel="PLATFORM_messages_cancel_delete"
        noHelpBtn={true}
        panelTitle={this.getTitle()}
      >
        <div className="icon">
          <baseUI.symbol name="deleteBlogConfirmationIcon" />
        </div>
        <div className="content">
          <p>{this.getContentText()}</p>
        </div>
      </panels.frames.ConfirmationPanelFrame>
    );
  }
}

export default AppControllerDeleteConfirmationPanel;
