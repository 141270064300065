import type { ThunkAction } from 'types/redux';

const getEditorAPI: ThunkAction = (dispatch, getState, { editorAPI }) => ({
  editorAPI,
  state: getState(),
});

export const mapStateToProps = ({ editorAPI }: AnyFixMe) => ({
  themeColors: editorAPI.theme.colors.getAll(),
});

export const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const { editorAPI } = dispatch(getEditorAPI);

  return {
    openColorPicker: () => {
      editorAPI.openColorPicker();
    },
  };
};
