._dividerContent_1e1yd_1 {
  margin-left: 0;
  margin-right: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  ._dividerContent_1e1yd_1 span {
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    text-transform: uppercase; }

._dividerWrapper_1e1yd_12 {
  margin: 6px 0px; }

._contextMenuActionWrapper_1e1yd_15 {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px; }
