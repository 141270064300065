import experiment from 'experiment';
import { TourNames, StartOrigin } from '@/editorTours';
import { MigrationFlow } from '../types';
import { isSkippedFTE, HEAVY_SITES } from '../utils';
import { showNotification } from './showNotification';

import type { SectionsMigrationScope as Scope } from '../scope';

export function startTourOrShowNotification(scope: Scope, flow: MigrationFlow) {
  if (flow === MigrationFlow.ADI) {
    if (
      scope.tourApi.shouldShowTour(TourNames.ADI_TO_EDITOR) &&
      !isSkippedFTE()
    ) {
      scope.tourApi.startOnLoad(TourNames.ADI_TO_EDITOR);
    } else {
      showNotification(scope, {
        messageType: 'success',
        message: 'adi_to_ed2_migration_success_notifications_text',
        link: HEAVY_SITES.NOTIFICATION_SUCCESS_LINK_TEXT,
        onClick: () =>
          scope.tourApi.startTourByName(
            TourNames.EDITOR_TO_EDITOR,
            StartOrigin.Notification,
          ),
      });
    }
  }

  if (flow === MigrationFlow.Editor || flow === MigrationFlow.HeavySite) {
    if (
      scope.tourApi.shouldShowTour(TourNames.EDITOR_TO_EDITOR) &&
      !isSkippedFTE()
    ) {
      scope.tourApi.startOnLoad(TourNames.EDITOR_TO_EDITOR);
    } else if (experiment.isOpen('se_heavySitesMigrationLoader')) {
      showNotification(scope, {
        messageType: 'success',
        message: 'adi_to_ed2_migration_success_notifications_text',
        link: HEAVY_SITES.NOTIFICATION_SUCCESS_LINK_TEXT,
        onClick: () =>
          scope.tourApi.startTourByName(
            TourNames.EDITOR_TO_EDITOR,
            StartOrigin.Notification,
          ),
      });
    }
  }
}
