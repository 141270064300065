import React from 'react';
import { EmptyResults, Link, Icons } from '@wix/editor-search-ui';
import { useTranslation } from 'react-i18next';

import { hoc } from '@/util';

import { ErrorType } from '../../services/searchModule/constants';
import { TRANSLATIONS } from '../../translations';
import { AUTOMATION_IDS } from '../../automationIds';

import {
  editorSearchBiLogger,
  BILinkButtonNames,
} from '../../services/biLogger/biLogger';
import { mapDispatchToProps } from './ErrorState.mapper';

import styles from './ErrorState.scss';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

export type ErrorStateProps = {
  error: {
    type: ErrorType;
    message: string;
    status?: number;
  };
} & ReturnType<typeof mapDispatchToProps>;

const getTextsFromError = (
  translate: AnyFixMe,
  type: ErrorType,
  message: string,
  status: number,
) => {
  if (type === ErrorType.UNKNOWN) {
    return {
      title: translate(TRANSLATIONS.ERROR_STATE.ERRORS.SERVER.TITLE),
      description: translate(TRANSLATIONS.ERROR_STATE.ERRORS.SERVER.TEXT, {
        status_code: `"${message}"`,
      }),
    };
  }

  if (type === ErrorType.NETWORK) {
    return {
      title: translate(TRANSLATIONS.ERROR_STATE.ERRORS.NETWORK.TITLE),
      description: translate(TRANSLATIONS.ERROR_STATE.ERRORS.NETWORK.TEXT),
    };
  }

  if (status === 401) {
    return {
      title: translate(TRANSLATIONS.ERROR_STATE.ERRORS.UNAUTHORIZED.TITLE),
      description: translate(TRANSLATIONS.ERROR_STATE.ERRORS.UNAUTHORIZED.TEXT),
    };
  }

  return {
    title: translate(TRANSLATIONS.ERROR_STATE.ERRORS.SERVER.TITLE),
    description: translate(TRANSLATIONS.ERROR_STATE.ERRORS.SERVER.TEXT, {
      status_code: status,
    }),
  };
};

export const ErrorStateComponent: React.FC<ErrorStateProps> = ({
  error,
  openAppMarket,
  openHelpCenterHome,
}) => {
  const [translate] = useTranslation();
  const { title, description } = getTextsFromError(
    translate,
    error.type,
    error.message,
    error.status,
  );

  const handleAppMarketClick = () => {
    openAppMarket();
    editorSearchBiLogger.logLinkClicked(BILinkButtonNames.AppMarketHome);
  };

  const handleHelpCenterClick = () => {
    openHelpCenterHome();
    editorSearchBiLogger.logLinkClicked(BILinkButtonNames.HelpCenterHome);
  };

  return (
    <EmptyResults
      title={title}
      subtitle={description}
      footer={
        <div className={styles.footer}>
          <Link
            key="explore-app-market"
            className={styles.footerLink}
            dataHook={AUTOMATION_IDS.FOOTER.APP_MARKET_LINK}
            icon={<Icons.AppMarket />}
            onClick={handleAppMarketClick}
          >
            {translate(TRANSLATIONS.FOOTER.APP_MARKET_LINK)}
          </Link>
          <Link
            key="go-to-help-center"
            className={styles.footerLink}
            dataHook={AUTOMATION_IDS.FOOTER.HELP_CENTER_LINK}
            icon={<Icons.Article />}
            onClick={handleHelpCenterClick}
          >
            {translate(TRANSLATIONS.FOOTER.HELP_CENTER_LINK)}
          </Link>
        </div>
      }
    />
  );
};

export const ErrorState = connect(
  EDITOR_API,
  null,
  mapDispatchToProps,
)(ErrorStateComponent);
