import { languages } from '@/util';
import React, { type FC, useEffect } from 'react';
import styles from './AppVersionsList.scss';
import {
  BackButton,
  Composites,
  Divider,
  Preloader,
  SortByDragList,
  SortByDragListCustomItemRow,
  Text,
  Tooltip,
} from '@wix/wix-base-ui';
import type { VersionsResponse } from '@wix/ambassador-app-service-webapp/types';
import installedVersionIndication from '../installedVersionIndication';
import { useAppVersionsListContext } from './AppVersionsListContext';
import { useSelectedApp } from '../selectedAppContext/selectedAppContext';
import {
  InstallStatus,
  usePrivateApps,
} from '../privateAppsProvider/privateAppsProvider';
import * as util from '@/util';
import { TEST_VERSION } from '../../utils/appDetailsUtils';
import { translate } from '@/i18n';
import { isAutoUpdatePending } from '../../utils/utils';
import UpdateInProgressIndication from '../common/updateInProgressIndication/updateInProgressIndication';

const locale = languages.getLanguageCode();

const AppVersionsList: FC<{}> = () => {
  const { selectedVersionListApp, setSelectedVersionListApp } =
    useAppVersionsListContext();
  const { setSelectedApp, selectedApp } = useSelectedApp();
  const { installedApps, availableApps } = usePrivateApps();

  const { versions, app } = selectedVersionListApp;
  const installedVersion = app?.appFields?.installedVersion;

  const onVersionClicked = (versionId: string) => {
    const installStatus =
      installedVersion === versionId || app.version === versionId
        ? InstallStatus.Installed
        : InstallStatus.Versioned;
    setSelectedApp(
      {
        ...app,
        installStatus,
      },
      versionId,
    );
  };

  useEffect(() => {
    onVersionClicked(app.version || app.latestVersion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versions]);

  const SortByDragListProps = {
    stopPropagationOnContextMenuToggle: true,
    menuActionsOverrides: {
      toggleEditLabel: {
        enable: false,
      },
    },
    removeContextMenu: true,
    draggable: false,
    selectable: true,
    selectItem: (item: { id: string }) => onVersionClicked(item.id),
  };

  const getLocaleDateString = (date: Date) =>
    date instanceof Date
      ? date.toLocaleDateString(locale, {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })
      : undefined;

  const renderVersionContentAfter = (version: string) => {
    if (version === app.version && installedVersion !== TEST_VERSION) {
      return renderInstalledVersionIndication();
    }
    if (
      version === app.latestVersion &&
      isAutoUpdatePending(app.version, app.latestVersion, installedVersion)
    ) {
      return <UpdateInProgressIndication version={app.latestVersion} />;
    }
    return null;
  };

  const renderVersion = ({ version, dateCreated }: VersionsResponse) => {
    return (
      <SortByDragListCustomItemRow
        dataHook={'app-version-item'}
        id={version}
        key={version}
        label={util.appStudioUtils.formatVersion(version)}
        value={getLocaleDateString(dateCreated)}
        isSelected={version === selectedApp.selectedVersion}
        contentAfter={renderVersionContentAfter(version)}
      />
    );
  };

  const renderTestVersionButton = () => (
    <SortByDragList {...SortByDragListProps}>
      {[
        <SortByDragListCustomItemRow
          className={styles.testVersionItem}
          id={TEST_VERSION}
          key={TEST_VERSION}
          label={translate(
            'blocks-private-apps.AppDiscovery_App_Versions_Test_Version_Title',
          )}
          value={getLocaleDateString(versions?.[0]?.dateCreated)}
          isSelected={TEST_VERSION === selectedApp.selectedVersion}
          contentAfter={
            TEST_VERSION === installedVersion
              ? renderInstalledVersionIndication()
              : null
          }
        />,
      ]}
    </SortByDragList>
  );

  const renderVersionsList = () => (
    <>
      {renderTestVersionButton()}
      <Divider className={styles.versionsListTestDivider} />
      <SortByDragList
        dataHook={'app-versions-list'}
        {...SortByDragListProps}
        noBorderTop={true}
      >
        {versions.map((version) => renderVersion(version))}
      </SortByDragList>
    </>
  );

  const renderInstalledVersionIndication = () => (
    <Tooltip
      content="blocks-private-apps.AppDiscovery_App_Versions_Installed_Version_Tooltip"
      className={styles.versionInstalledIcon}
    >
      {installedVersionIndication()}
    </Tooltip>
  );

  return (
    <div className={styles.showAllVersionsForPrivateApp}>
      <Composites.ButtonLeft>
        <BackButton
          label={app.appDefinitionName}
          onClick={() => {
            setSelectedVersionListApp(null);
            setSelectedApp(
              [...installedApps, ...availableApps].find(
                ({ appDefinitionId }) =>
                  appDefinitionId === app.appDefinitionId,
              ),
              app.version,
            );
          }}
          shouldTranslate={false}
        />
      </Composites.ButtonLeft>
      <div className={styles.availableVersions}>
        <Text weight="bold" size="small">
          blocks-private-apps.AppDiscovery_App_Versions_Title
        </Text>
      </div>
      <div className={styles.versionsList}>
        {versions ? (
          renderVersionsList()
        ) : (
          <div className={styles.loaderContainer}>
            <Preloader className={'medium'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  null,
)(AppVersionsList);
