import constants from '@/constants';
import React, { type MouseEvent } from 'react';
import Anchor from './StageAnchorHandle';
import { cx, hoc } from '@/util';
import { withRestrictions } from '@/editorRestrictions';
import type { StateMapperArgs } from 'types/redux';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

interface StageAnchorsContainerProps {
  anchors: CompRef[];
  isMobile: boolean;
  onContextMenu: (ev: MouseEvent) => void;
}

const StageAnchorsContainer: React.FC<StageAnchorsContainerProps> = ({
  anchors,
  isMobile,
  onContextMenu,
}) => {
  return (
    <div
      className={cx('anchors-container', {
        'mobile-editor': isMobile,
      })}
    >
      {anchors.map((anchorRef, anchorRefIndex) => (
        <Anchor
          key={`anchor${anchorRefIndex}`}
          compRef={anchorRef}
          onContextMenu={onContextMenu}
        />
      ))}
    </div>
  );
};

StageAnchorsContainer.displayName = 'stageComps';

const getAnchors = (editorAPI: EditorAPI) => {
  const pageRef = editorAPI.dsRead.pages.getReference(
    editorAPI.dsRead.pages.getFocusedPageId(),
  );
  return editorAPI.components.get.byType(constants.COMP_TYPES.ANCHOR, pageRef);
};

const mapStateToProps = ({ editorAPI }: StateMapperArgs) => {
  const isMobile = editorAPI.isMobileEditor();

  const anchors = getAnchors(editorAPI);

  return { isMobile, anchors };
};

export default withRestrictions('rEditor_anchors-container')(
  hoc.connect(hoc.STORES.EDITOR_API, mapStateToProps)(StageAnchorsContainer),
);
