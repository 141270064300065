export const PANEL_HEADER_HEIGHT = 55;
export const TOP_PANEL_WIDTH = 352;
export const TOP_PANEL_HEIGHT = 184;
export const MAX_TILE_WIDTH = 600;
export const MAX_TILE_HEIGHT = 600;

export const AUTOMATION_IDS = {
  BACKGROUND_VIEW: 'background-view',
  BACKGROUND_VIEW_SETTINGS: 'background-view-settings',
  BACKGROUND_VIEW_COLOR_PICKER: 'background-view-color-picker',
  BACKGROUND_VIEW_IMAGE_PICKER: 'background-view-image-picker',
  BACKGROUND_VIEW_VIDEO_PICKER: 'background-view-video-picker',
};

export const FEDOPS = {
  DESIGN_PANEL_BG_OPEN: 'design_panel_background_open',
};
