:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._blocks_1kel4_7 {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #f7f8f8;
  width: 400px; }

._list_1kel4_15 {
  flex: 1 1 auto;
  padding: 0;
  margin: 0;
  overflow-y: scroll; }
