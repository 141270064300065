import { Hooks } from '@/apilib';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

export interface InterceptorPromiseValue {
  compRefUpdatedMap?: Map<string, CompRef>;
}

const context: {
  isInterceptorHookEnabled: boolean;
  data?: {
    compContainerToByCompId: Map<string, CompRef> | null;
  };
  hooks?: {
    transitionIntercepted: Hooks.PromiseHook<void>;
    transitionEnded: Hooks.PromiseHook<InterceptorPromiseValue>;
  };
} = {
  isInterceptorHookEnabled: false,
  data: null,
  hooks: null,
};

/**
 * @param params.compContainerToByCompId - map of target container id to the compRef that is being moved
 */
export function tapMoveByTransitionEndInterceptorContext(data: {
  compContainerToByCompId: Map<string, CompRef>;
}) {
  if (!context.isInterceptorHookEnabled) {
    throw new Error('ensure `moveByTransitionEndInterceptor` is enabled');
  }

  context.data = data;
  context.hooks = {
    transitionIntercepted: Hooks.createPromiseHook(),
    transitionEnded: Hooks.createPromiseHook(),
  };
}

export function waitMoveByTransitionEndResult() {
  return context.hooks.transitionIntercepted.promise.then(
    () => context.hooks.transitionEnded.promise,
  );
}

export function initMoveByTransitionEndInterceptor({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  context.data = null;
  context.hooks = null;

  if (context.isInterceptorHookEnabled) {
    return;
  }

  const { moveByTransitionEndInterceptor, moveByTransitionEndAfter } =
    editorAPI.components.layout.__mesh.hooks;

  moveByTransitionEndInterceptor.tap((data, { update }) => {
    update({
      ...data,
      ...context.data,
    });

    context.hooks?.transitionIntercepted.resolve();
  });

  moveByTransitionEndAfter.tap((result) => {
    context.hooks?.transitionEnded.resolve(result);
  });

  context.isInterceptorHookEnabled = true;
}
