import {
  type MediaManagerImage,
  type MediaManagerItem,
  type MediaManagerShape,
  type MediaManagerVideo,
  MediaType,
} from './mediaManager.types';

export const isMediaManagerImage = (
  item: MediaManagerItem,
): item is MediaManagerImage => item.mediaType === MediaType.Picture;

export const isMediaManagerVideo = (
  item: MediaManagerItem,
): item is MediaManagerVideo => item.mediaType === MediaType.Video;

export const isMediaManagerShape = (
  item: MediaManagerItem,
): item is MediaManagerShape => item.mediaType === MediaType.Shape;
