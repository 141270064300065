import { panels } from '@/stateManagement';
import { EditorRestrictionsApiKey } from '@/apis';
import type { StateMapperArgs } from 'types/redux';

export const canSelectSectionLike = ({ editorAPI, state }: StateMapperArgs) => {
  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const isSelectSectionLikeInteractive = editorRestrictionsApi.allowed(
    'sections_select-sections-like.interactive',
  );
  if (editorAPI.zoomMode.isStageZoomMode() || isSelectSectionLikeInteractive) {
    return !panels.selectors.hasOpenedLeftPanels(state);
  }
  return true;
};
