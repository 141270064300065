import React from 'react';
import _ from 'lodash';
import { CustomModal, type CustomModalProps } from '@wix/wix-base-ui';

import PanelFrameContainer, {
  type PanelFrameContainerProps,
} from '../panelFrameContainer/panelFrameContainer';

export interface CustomPanelFrameProps
  extends Omit<PanelFrameContainerProps, 'overlayDataHook'>,
    Omit<CustomModalProps, 'primaryButtonOnClick' | 'secondaryButtonOnClick'> {
  panelName: string;
  onPrimaryButtonClick?: CustomModalProps['primaryButtonOnClick'];
  onSecondaryButtonClick?: CustomModalProps['secondaryButtonOnClick'];
}

export const CustomPanelFrame: React.FC<CustomPanelFrameProps> = (props) => {
  const customModalProps = _.omit(props, [
    'className',
    'keyboardShortcuts',
    'onEscKeyPress',
    'onOuterClick',
  ]);

  return (
    <PanelFrameContainer
      className={props.className}
      keyboardShortcuts={props.keyboardShortcuts}
      onEscKeyPress={props.onEscKeyPress}
      onOuterClick={props.onOuterClick}
      overlayDataHook="CustomPanelFrame-overlay"
    >
      <CustomModal
        {...customModalProps}
        primaryButtonOnClick={customModalProps.onPrimaryButtonClick}
        secondaryButtonOnClick={customModalProps.onSecondaryButtonClick}
      >
        {props.children}
      </CustomModal>
    </PanelFrameContainer>
  );
};

CustomPanelFrame.defaultProps = {
  dataHook: 'CustomPanelFrame',
};

export default CustomPanelFrame;
