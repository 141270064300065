._contentContainer_8hur5_1 {
  display: grid;
  grid-column-gap: 6px;
  grid-template-columns: 24px 1fr;
  width: 210px;
  margin-left: -6px; }

._textsContainer_8hur5_8 {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2px;
  margin-top: 4px; }

._tooltipIcon_8hur5_14 {
  width: 24px; }
