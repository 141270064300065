import type { EditorAPI } from '@/editorAPI';
interface LogoUpdateData {
  logoLayoutOverrides: LogoLayoutOverrides;
  logoData: LogoData;
}

interface LogoLayoutOverrides {
  width?: number;
  height?: number;
}

interface LogoData {
  logoId?: string;
  brandId?: string;
  isLoadingData: boolean;
  logoUrl?: string;
  backgroundColor?: string;
}
export const initialLength = 148;

export async function fetchDataForLogoComponent(
  editorAPI: EditorAPI,
): Promise<LogoUpdateData> {
  const metaSiteId = editorAPI.site.getMetaSiteId();
  try {
    const response = await fetch(
      `https://editor.wix.com/logoapi/logo-builder-server-webapp/v1/brands/site?metasiteId=${metaSiteId}`,
    );

    const data = await response.json();
    if (data?.brand) {
      const { brand } = data;
      const { id, primaryLogoId } = brand;
      let url, backgroundColor, layout: LogoLayoutOverrides;
      await fetch(
        `https://editor.wix.com/_serverless/brand-maker/api/v1/brands/${id}/design/${primaryLogoId}/all-previews?defaultPreview=true`,
      ).then(async (res) => {
        const data = await res.json();
        if (data?.previews) {
          const { previews } = data;
          url = previews.previewUrl;
          backgroundColor = previews.backgroundColor;
          layout = calculateLogoLayout(
            previews.width,
            previews.height,
            initialLength,
          );
        }
      });
      return {
        logoData: {
          logoId: primaryLogoId,
          brandId: id,
          isLoadingData: false,
          logoUrl: url,
          backgroundColor,
        },
        logoLayoutOverrides: {
          width: layout.width,
          height: layout.height,
        },
      };
    }
    return {
      logoData: { isLoadingData: false },
      logoLayoutOverrides: {},
    };
  } catch (e) {
    return {
      logoData: { isLoadingData: false },
      logoLayoutOverrides: {},
    };
  }
}

function calculateLogoLayout(width: number, height: number, maxLength: number) {
  const layout: LogoLayoutOverrides = { width: maxLength, height: maxLength };
  if (width && height) {
    const ratio = width / height;
    layout.width = ratio >= 1 ? maxLength : maxLength * ratio;
    layout.height = ratio >= 1 ? maxLength / ratio : maxLength;
  }
  return layout;
}
