import type React from 'react';
import * as stateManagement from '@/stateManagement';
import type { CompRef } from 'types/documentServices';

export interface HiddenInteractionItem {
  label: string;
  compRef: CompRef;
  svgName?: string;
  iconUrl?: string;
  css?: React.CSSProperties;
}

export interface InteractionsHiddenComponentsStateProps {
  panelName?: string;
  panelIndex?: number;
  hiddenComponents: HiddenInteractionItem[];
}

export interface InteractionsHiddenComponentsDispatchProps {
  showComponent(compRef: CompRef, numOfHiddenItems: number): void;
  onLearnMore?(): void;
}

export interface HiddenInteractionItemProps
  extends HiddenInteractionItem,
    InteractionsHiddenComponentsDispatchProps {
  numOfHiddenItems: number;
}

const { getVariantPointers } = stateManagement.interactions.selectors;

function getHiddenComponents(
  editorAPI: AnyFixMe,
  state: AnyFixMe,
): HiddenInteractionItemProps[] {
  const variantPointers = getVariantPointers(state);
  if (!variantPointers) {
    return [];
  }
  const compRefs =
    editorAPI.documentServices.components.transformations.getAllHiddenComponents(
      variantPointers,
    );
  return compRefs.map((compRef: AnyFixMe) => {
    const iconProps = editorAPI.components.getIconInfo(compRef);
    return {
      compRef,
      label: editorAPI.components.getDisplayName(compRef),
      iconUrl: iconProps.src,
      svgName: iconProps.svgName,
      css: iconProps.css,
    };
  });
}

export const mapStateToProps = ({
  editorAPI,
  state,
}: AnyFixMe): InteractionsHiddenComponentsStateProps => ({
  hiddenComponents: getHiddenComponents(editorAPI, state),
});

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export const mapDispatchToProps = (
  dispatch: AnyFixMe,
): InteractionsHiddenComponentsDispatchProps => ({
  showComponent: (compRef, numOfHiddenItems) => {
    if (numOfHiddenItems === 1) {
      const editorAPI = dispatch(getEditorAPI);
      editorAPI.panelManager.closePanelByName(
        'interactions.panels.interactionHiddenComponents',
      );
    }
    dispatch(
      stateManagement.interactions.actions.showComponent(compRef, {
        origin: 'panel',
      }),
    );
  },

  onLearnMore: () => {
    dispatch(
      stateManagement.panels.actions.openHelpCenter(
        '8a2a0c28-656c-4d8e-82df-7f50c1e9f0af',
      ),
    );
  },
});
