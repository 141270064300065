import { useTranslation } from 'react-i18next';
import type { AddPanelProps as AddPanelComponentProps } from '@wix/add-panel-component';
import { sections } from '@/util';
import { createUtils, type NewAddPanelUtils } from '@/newAddPanelAPI';

export const useAddPanelUtils = ({
  theme,
  getSuperAppData,
  hasSectionStyle,
  getLiveChatSectionColors,
}: {
  theme: NewAddPanelUtils['theme'];
  hasSectionStyle: NewAddPanelUtils['hasSectionStyle'];
  getSuperAppData: NewAddPanelUtils['getSuperAppData'];
  getLiveChatSectionColors: NewAddPanelUtils['getLiveChatSectionColors'];
}): NewAddPanelUtils => {
  const [translate] = useTranslation();

  const addPanelUtils = createUtils({
    theme,
    translate,
    getBlankSectionStructure:
      sections.getBlankSectionStructure as AddPanelComponentProps['utils']['getBlankSectionStructure'],
    shouldUseTextThemes: () => true, // TODO remove this prop
    shouldShowMyDesignsFooter: () => sections.isSectionsEnabled(),
    hasSectionStyle,
    getSuperAppData,
    getLiveChatSectionColors,
  });

  return addPanelUtils;
};
