import {
  mapStateToProps as mapStateToHelpProps,
  mapDispatchToProps as mapDispatchToHelpProps,
} from '../menuBar/menuBarListItems/helpMenu/helpMenuAPIMapper';
import {
  mapStateToProps as mapStateToSettingsProps,
  mapDispatchToProps as mapDispatchToSettingsProps,
} from '../menuBar/menuBarListItems/settingsMenu/settingsMenuAPIMapper';
import {
  mapStateToProps as mapStateToSiteProps,
  mapDispatchToProps as mapDispatchToSiteProps,
} from '../menuBar/menuBarListItems/siteMenu/siteMenuAPIMapper';
import {
  mapStateToProps as mapStateToToolsProps,
  mapDispatchToProps as mapDispatchToToolsProps,
} from '../menuBar/menuBarListItems/toolsMenu/toolsMenuAPIMapper';
import {
  mapStateToProps as mapStateToCodeProps,
  mapDispatchToProps as mapDispatchToCodeProps,
} from '../menuBar/menuBarListItems/codeMenu/codeMenuAPIMapper';

import type { MapDispatchToPropsFunction, MapStateToProps } from 'types/redux';
import type {
  MenusSearchIntegrationDispatchProps,
  MenusSearchIntegrationStateProps,
  MenusSearchIntegrationOwnProps,
} from './menusIntegration';

export const mapStateToProps: MapStateToProps<
  MenusSearchIntegrationStateProps,
  MenusSearchIntegrationOwnProps
> = (...args) => ({
  ...mapStateToHelpProps(...args),
  ...mapStateToSettingsProps(...args),
  ...mapStateToSiteProps(...args),
  ...mapStateToToolsProps(...args),
  ...mapStateToCodeProps(...args),
});

export const mapDispatchToProps: MapDispatchToPropsFunction<
  MenusSearchIntegrationDispatchProps,
  MenusSearchIntegrationOwnProps
> = (dispatch, ownProps) => ({
  ...mapDispatchToHelpProps(dispatch, ownProps),
  ...mapDispatchToSettingsProps(dispatch, ownProps),
  ...mapDispatchToSiteProps(dispatch, ownProps),
  ...mapDispatchToToolsProps(dispatch, ownProps),
  ...mapDispatchToCodeProps(dispatch, ownProps),
});
