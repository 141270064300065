import * as util from '@/util';
import { storePagesData, getPagesData } from './api';
import type { Scope } from './scope';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import { EditorParamsApiKey } from '@/apis';
import experiments from 'experiment';

import type { MultilingualObject } from '@wix/document-services-types';

const { showUserActionNotification } = stateManagement.notifications.actions;

interface MultilingualWithSpec extends MultilingualObject {
  isMLSlugExperimentOpen: () => boolean;
}

export async function init(scope: Scope) {
  const enableAutoRedirectsForWixStudio = experiments.isOpen(
    'specs.promote.enableAutoRedirectsForWixStudio',
  );

  const { publishManagerApi, editorApi } = scope;

  const getAutoRedirectApi = () =>
    import('advancedSeoClient').then(
      ({ createAutoRedirect, getBeforePublishedPages }) => ({
        createAutoRedirect,
        getBeforePublishedPages,
      }),
    );

  publishManagerApi.hooks.beforePublish.tap(async () => {
    if (
      (
        editorApi?.dsRead?.multilingual as MultilingualWithSpec
      )?.isMLSlugExperimentOpen()
    ) {
      return;
    }
    const autoRedirectApi = await getAutoRedirectApi();
    const beforePublishPages = await autoRedirectApi.getBeforePublishedPages({
      scope,
      metaSiteAppId: constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    });
    storePagesData(scope, beforePublishPages);
  });

  publishManagerApi.hooks.afterPublish.tap(async () => {
    if (
      (
        editorApi?.dsRead?.multilingual as MultilingualWithSpec
      )?.isMLSlugExperimentOpen()
    ) {
      return;
    }
    const beforePublishedPages = getPagesData(scope);
    const autoRedirectApi = await getAutoRedirectApi();
    await autoRedirectApi.createAutoRedirect({
      scope,
      beforePublishedPages,
      metaSiteAppId: constants.APPLICATIONS.META_SITE_APPLICATION_ID,
      locale: util.languages.getLanguageCode(),
      showUserActionNotification,
      isEditorX:
        !enableAutoRedirectsForWixStudio &&
        editorApi.host.getAPI(EditorParamsApiKey).isInsideEditorX,
    });
  });
}
