import constants, { type ProgressStatus } from '@/constants';

type StageType = 'regular' | 'fluid';

type TooltipDataType = {
  [key in StageType]: {
    title: string;
    text: string;
  };
};

type PreviewDataType = {
  [key in ProgressStatus]: {
    tooltipData: Partial<TooltipDataType>;
  };
};

const progressButtonClassNames: AnyFixMe = {
  [constants.PROGRESS_STATUS.IDLE_SAVED]: 'top-bar-btn-idle-saved',
  [constants.PROGRESS_STATUS.IN_PROGRESS]: 'top-bar-btn-in-progress-indicator',
  [constants.PROGRESS_STATUS.AUTO_SAVE_IN_PROGRESS]:
    'top-bar-btn-in-progress-indicator',
  [constants.PROGRESS_STATUS.DONE_SUCCESS]: 'top-bar-btn-done-successfully',
};

const TEXT_AND_LINK_DROP_PANEL_KEYS = [
  constants.ROOT_COMPS.TOPBAR.DROP_PANELS.SAVE,
  constants.ROOT_COMPS.TOPBAR.DROP_PANELS.PUBLISH,
  constants.ROOT_COMPS.TOPBAR.DROP_PANELS.PUBLISH_RC,
  constants.ROOT_COMPS.TOPBAR.DROP_PANELS.LOCAL_PUSH,
  constants.ROOT_COMPS.TOPBAR.DROP_PANELS.WIXSTORES_MIGRATION_UPGRADE_SITE,
  constants.ROOT_COMPS.TOPBAR.DROP_PANELS.SITE,
];

const KEYBOARD_ACCESSIBLE_DROP_PANELS = [
  constants.ROOT_COMPS.TOPBAR.DROP_PANELS.SITE,
  constants.ROOT_COMPS.TOPBAR.DROP_PANELS.SETTINGS,
  constants.ROOT_COMPS.TOPBAR.DROP_PANELS.TOOLS,
  constants.ROOT_COMPS.TOPBAR.DROP_PANELS.HELP,
  constants.ROOT_COMPS.TOPBAR.DROP_PANELS.UPGRADE,
];

const PREVIEW_DATA: Partial<PreviewDataType> = {
  [constants.PROGRESS_STATUS.IDLE]: {
    tooltipData: {
      fluid: {
        title: 'TOPBAR_PREVIEW_TOOLTIP_TITLE_FLUID',
        text: 'TOPBAR_PREVIEW_TOOLTIP_BODY_FLUID',
      },
      regular: {
        title: 'TOPBAR_PREVIEW_TOOLTIP_TITLE',
        text: 'TOPBAR_PREVIEW_TOOLTIP_BODY',
      },
    },
  },
  [constants.PROGRESS_STATUS.IN_PROGRESS]: {
    tooltipData: {
      regular: {
        title: null as AnyFixMe,
        text: 'TOPBAR_PREVIEW_OPENING_TOOLTIP_BODY',
      },
    },
  },
};

const EDIT_MODE_LAST_BUTTON_BUBBLE_STYLE_OVERRIDES = {
  content: {
    right: '6px',
  },
};

const BACK_TO_ADI_BUBBLE_STYLE_OVERRIDE = {
  content: {
    position: 'absolute',
    display: 'block',
    top: '60px',
  },
};

const EN = 'en';
const SMALL = 'small';
const MEDIUM = 'medium';
// Others fall back to large

const RESPONSIVENESS_CLASS_SUFFIXES = {
  en: EN,
  ko: SMALL,
  fr: SMALL,
  tr: SMALL,
  de: MEDIUM,
  pl: MEDIUM,
  pt: MEDIUM,
  es: MEDIUM,
  hi: MEDIUM,
  it: MEDIUM,
  no: MEDIUM,
  nl: MEDIUM,
};

export {
  progressButtonClassNames,
  TEXT_AND_LINK_DROP_PANEL_KEYS,
  PREVIEW_DATA,
  EDIT_MODE_LAST_BUTTON_BUBBLE_STYLE_OVERRIDES,
  KEYBOARD_ACCESSIBLE_DROP_PANELS,
  RESPONSIVENESS_CLASS_SUFFIXES,
  BACK_TO_ADI_BUBBLE_STYLE_OVERRIDE,
};
