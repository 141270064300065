import React, { type FC, useCallback } from 'react';
import { Text, Button } from '@wix/wix-base-ui';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { cx } from '@/util';

import styles from './SetupSuccessAnimation.scss';

export interface SetupSuccessAnimationProps {
  appName: string;
  className?: string;
  dataHook: string;
  onDismiss: () => void;
}

export const SetupSuccessAnimation: FC<SetupSuccessAnimationProps> = ({
  appName,
  className,
  dataHook,
  onDismiss,
}) => {
  const [t] = useTranslation();
  const addEndListener = useCallback((node, done) => {
    node.addEventListener('transitionend', done, false);
  }, []);

  return (
    <div className={cx(styles.container, className)} data-hook={dataHook}>
      <Symbol name="success" className={styles.businessAppSuccessAnimation} />
      <div className={styles.message}>
        <CSSTransition
          in
          appear
          timeout={500}
          addEndListener={addEndListener}
          classNames={{
            appear: styles.slideFadingIn,
            appearActive: styles.slideFadingInActive,
          }}
        >
          <Text
            skin="standard"
            size="medium"
            weight="bold"
            shouldTranslate={false}
            className={styles.title}
          >
            {t(
              'App_Manager_Ecomm_Segment_Main_Panel_Setup_Complete_Success_Message_Header',
            )}
          </Text>
        </CSSTransition>
        <CSSTransition
          in
          appear
          timeout={500}
          addEndListener={addEndListener}
          classNames={{
            appear: styles.slideFadingIn,
            appearActive: cx(
              styles.slideFadingInActive,
              styles.delayedTransition,
            ),
          }}
        >
          <Text
            skin="secondary"
            size="small"
            weight="thin"
            shouldTranslate={false}
          >
            {t(
              'App_Manager_Ecomm_Segment_Main_Panel_Setup_Complete_Success_Message_Text',
              { appName },
            )}
          </Text>
        </CSSTransition>
        <CSSTransition
          in
          appear
          timeout={2000}
          addEndListener={addEndListener}
          classNames={{
            appear: styles.slideFadingIn,
            appearActive: cx(
              styles.slideFadingInActive,
              styles.longDelayedTransition,
            ),
          }}
        >
          <Button
            className={`btn-text btn-sm ${styles.dismiss}`}
            shouldTranslate={false}
            onClick={onDismiss}
          >
            {t(
              'App_Manager_Ecomm_Segment_Main_Panel_Setup_Complete_Success_Message_CTA',
            )}
          </Button>
        </CSSTransition>
      </div>
    </div>
  );
};
