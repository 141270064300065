// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { SortByDragList } from '@wix/wix-base-ui';

import * as util from '@/util';
import { waitForAddedCompRef } from '@/componentsAddUtils';
import * as compPanelInfra from '@/compPanelInfra';
import * as boxSlideshow from '@/boxSlideshow';
import * as coreBi from '@/coreBi';
import { boxSlideShow, selection } from '@/stateManagement';
import * as BaseUI from '@/baseUI';
import * as CompPanelInfra from '@/compPanelInfra';
import * as helpIds from '@/helpIds';
import { translate } from '@/i18n';
import type { EditorAPI } from '@/editorAPI';
import type { CompStructure } from 'types/documentServices';

const { getAllSlides, findCurrentSlide } = boxSlideShow.selectors;
const { getFocusedContainer } = selection.selectors;
const { boxSlideShowUtils } = boxSlideshow.utils;
const DUPLICATE_SLIDE_PREFIX = 'Regular_Slideshow_Slides_Copy_Slide_Label';
const DEFAULT_NEW_SLIDE_TITLE = 'Regular_Slideshow_Slides_New_Slide_Label'; //TODO - this should also be a part of the preset data
const SLIDE_NAME_DATA_FIELD = 'title';

function getSlideTitle(editorAPI, slide) {
  return editorAPI.components.data.get(slide)[SLIDE_NAME_DATA_FIELD];
}

function getBiParams() {
  return this.getEditorAPI().components.getDefaultBiParams(
    this.props.boxSlideShow,
  );
}

function getSlideItem(
  editorAPI: EditorAPI,
  slidePointer: CompRef,
  selectedSlide: CompRef,
) {
  const slideData = {
    id: slidePointer.id,
    label: getSlideTitle(editorAPI, slidePointer),
    pointer: slidePointer,
  };

  if (slideData.id === selectedSlide.id) {
    slideData.selected = true;
  }

  return slideData;
}

function getSlides(editorAPI, boxSlideShow, selectedSlide) {
  const slidesPointers = getAllSlides(editorAPI.dsRead, boxSlideShow);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(slidesPointers, function (slidePointer) {
    return getSlideItem(editorAPI, slidePointer, selectedSlide);
  });
}

// eslint-disable-next-line react/prefer-es6-class
const BoxSlideShowManageSlidePanel = createReactClass({
  displayName: 'boxSlideShowManageSlidesPanel',
  mixins: [compPanelInfra.compPanelMixin],
  propTypes: {
    boxSlideShow: PropTypes.object,
  },
  getInitialState() {
    const currentSlide = findCurrentSlide(
      this.getEditorAPI().dsRead,
      this.props.boxSlideShow,
    );

    return {
      selectedSlide: currentSlide,
    };
  },
  UNSAFE_componentWillMount() {
    this.slides = this.getSlides();
    this.operations = [];
  },
  componentWillUpdate(nextProps, nextState) {
    this.slides = getSlides(
      this.getEditorAPI(),
      this.props.boxSlideShow,
      nextState.selectedSlide,
    );
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.slides = getSlides(
      this.getEditorAPI(),
      nextProps.boxSlideShow,
      this.state.selectedSlide,
    );
    const nextSelectedSlide = findCurrentSlide(
      this.getEditorAPI().dsRead,
      nextProps.boxSlideShow,
    );

    if (!_.isEqual(nextSelectedSlide, this.state.selectedSlide)) {
      //current slide was changed from outside
      this.setState({ selectedSlide: nextSelectedSlide });
    }
  },
  getSlides() {
    return getSlides(
      this.getEditorAPI(),
      this.props.boxSlideShow,
      this.state.selectedSlide,
    );
  },
  getSelectedSlideItem() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return _.find(this.slides, { id: this.state.selectedSlide.id });
  },
  startOperation() {
    this.getEditorAPI().preventMouseDown();
    let endOperation;
    const operation = new Promise((resolve) => {
      endOperation = resolve;
    });
    this.operations.push(operation);
    return endOperation;
  },
  endOperation(endOperation) {
    this.getEditorAPI().allowMouseDown();
    endOperation();
  },
  async waitForOperations() {
    await Promise.all(this.operations);
    this.operations = [];
  },
  async selectSlide(slideValue, slideIndex) {
    await this.waitForOperations();

    const editorAPI = this.getEditorAPI();
    const endOperation = this.startOperation();

    await new Promise((resolve) => {
      this.setState({ selectedSlide: slideValue.pointer }, resolve);
    });

    await new Promise((resolve) => {
      editorAPI.components.behaviors.execute(
        this.props.boxSlideShow,
        'moveToSlide',
        { slide: slideIndex },
        resolve,
      );
    });

    this.endOperation(endOperation);

    boxSlideShowUtils.identifyComponentsOutOfSlideshow(
      editorAPI,
      this.props.boxSlideShow,
    );

    boxSlideShowUtils.hideQuickTip(editorAPI);
    editorAPI.bi.event(
      coreBi.events.boxSlideShow.SLIDES_NAVIGATION,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign({ origin: 'slideManager' }, getBiParams.call(this)),
    );
  },
  async moveSlide(startIdx, dropIdx) {
    if (startIdx === dropIdx) {
      return;
    }

    await this.waitForOperations();

    const editorAPI = this.getEditorAPI();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const selectedIndex = _.findIndex(this.getSlides(), { selected: true });
    const slideToMove = this.slides[startIdx].pointer;
    editorAPI.components.arrangement.moveToIndex(slideToMove, dropIdx, {
      dontAddToUndoRedoStack: false,
    });
    editorAPI.bi.event(
      coreBi.events.boxSlideShow.MANAGE_SLIDES_SLIDE_REORDERED,
      getBiParams.call(this),
    );

    editorAPI.dsActions.waitForChangesApplied(
      function () {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
        const newSelectedIndex = _.findIndex(this.getSlides(), {
          selected: true,
        });
        if (selectedIndex === newSelectedIndex) {
          return;
        }

        const endOperation = this.startOperation();

        editorAPI.components.behaviors.execute(
          this.props.boxSlideShow,
          'moveToSlide',
          { slide: newSelectedIndex },
          () => this.endOperation(endOperation),
        );
      }.bind(this),
    );
  },
  async addSlide(slideDef: CompStructure, index: number) {
    const editorAPI: EditorAPI = this.getEditorAPI();

    const addedSlideRef = await waitForAddedCompRef(
      editorAPI.components.add(this.props.boxSlideShow, slideDef),
    );

    editorAPI.components.arrangement.moveToIndex(addedSlideRef, index, {
      dontAddToUndoRedoStack: true,
    });
    editorAPI.history.add('added a slide to slide show', {
      isAddingComponent: true,
    });

    editorAPI.dsActions.waitForChangesApplied(
      function () {
        const addedSlideData = getSlideItem(
          editorAPI,
          addedSlideRef,
          this.state.selectedSlide,
        );
        this.selectSlide(addedSlideData, index);
      }.bind(this),
    );
  },
  async duplicateSlide(slide, slideIndex) {
    const slideDef = this.getEditorAPI().components.serialize(slide.pointer);
    const translationParam = {
      curr_slide_title: slideDef.data[SLIDE_NAME_DATA_FIELD],
    };
    slideDef.data[SLIDE_NAME_DATA_FIELD] = translate(
      DUPLICATE_SLIDE_PREFIX,
      translationParam,
    );
    await this.addSlide(slideDef, slideIndex + 1);
  },
  async duplicateCurrentSlide() {
    const currSlide = this.getSelectedSlideItem();
    const totalSlides = this.slides.length;
    await this.duplicateSlide(currSlide, totalSlides - 1);
    this.getEditorAPI().bi.event(
      coreBi.events.boxSlideShow.MANAGE_SLIDES_DUPLICATE_CURRENT_SLIDE,
      getBiParams.call(this),
    );
  },
  async deleteSlide(slide, slideIndex, allSlides) {
    if (allSlides.length <= 1) {
      return;
    }

    const editorAPI = this.getEditorAPI();
    const [firstSlide] = allSlides;

    await this.selectSlide(firstSlide, 0);

    await editorAPI.components.remove(slide.pointer);

    editorAPI.history.add('removed a slide from slide show');

    const selectedSlide = findCurrentSlide(
      editorAPI.dsRead,
      this.props.boxSlideShow,
    );

    this.setState({ selectedSlide });
  },
  changeSlideName(slide) {
    const newTitle = slide.label;
    const editorAPI = this.getEditorAPI();

    const updatedData = {};
    updatedData[SLIDE_NAME_DATA_FIELD] = newTitle;
    editorAPI.components.data.update(slide.pointer, updatedData);
  },
  async addNewSlide() {
    const editorAPI = this.getEditorAPI();
    const currSlide = this.getSelectedSlideItem();
    // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
    const slideDef = editorAPI.components.serialize(currSlide.pointer);
    slideDef.components = [];
    const totalSlides = this.slides.length;
    const translationParam = { curr_slide: totalSlides + 1 };
    slideDef.data[SLIDE_NAME_DATA_FIELD] = translate(
      DEFAULT_NEW_SLIDE_TITLE,
      translationParam,
    );
    await this.addSlide(slideDef, totalSlides);
    editorAPI.bi.event(
      coreBi.events.boxSlideShow.MANAGE_SLIDES_ADD_NEW_SLIDE,
      getBiParams.call(this),
    );
  },
  getMenuActionsOverrides() {
    return {
      toggleDefault: {
        enable: false,
      },
      toggleEditValue: {
        enable: false,
      },
      toggleEditLabel: {
        priority: 3,
        label: translate('Regular_Slideshow_Slides_Rename'),
      },
      duplicate: {
        priority: 2,
        label: translate('Regular_Slideshow_Slides_Duplicate'),
      },
      delete: {
        priority: 1,
        label: translate('Regular_Slideshow_Slides_Delete'),
        enable: this.slides.length > 1,
      },
    };
  },
  render() {
    return (
      <CompPanelInfra.compPanelFrame
        contentClass="box-slide-show-manage-slides-panel"
        title="Regular_Slideshow_Slides_Title"
        helpId={helpIds.EXTERNAL.BOX_SLIDE_SHOW_MANAGE_SLIDES}
        {...this.getFrameProps()}
      >
        <SortByDragList
          selectable={true}
          value={this.getSlides()}
          selectItem={this.selectSlide}
          menuActionsOverrides={this.getMenuActionsOverrides()}
          enableEditValue={false}
          enableSetAsDefault={false}
          labelValidator={util.validate.notEmptyString}
          labelInvalidMessage="Box_slide_show_slides_invalid_slide_name"
          itemMoved={this.moveSlide}
          duplicateItem={this.duplicateSlide}
          deleteItem={this.deleteSlide}
          itemChanged={this.changeSlideName}
          labelPlaceholder="Regular_Slideshow_Slides_Rename_Placeholder"
          submitEditLabelButtonText={translate(
            'Regular_Slideshow_Slides_Rename_Done',
          )}
          submitEditValueButtonText={translate(
            'Regular_Slideshow_Slides_Rename_Done',
          )}
        />

        <div className="button-wrapper">
          <BaseUI.button
            label="Regular_Slideshow_Slides_Add_Duplicate_Slide"
            onClick={this.duplicateCurrentSlide}
            className="mainButton"
          />
          <BaseUI.button
            label="Regular_Slideshow_Slides_Add_New_Slide"
            onClick={this.addNewSlide}
            className="secondaryButton btn-text"
          />
        </div>
      </CompPanelInfra.compPanelFrame>
    );
  },
});

const mapStateToProps = ({ state }) => ({
  boxSlideShow: getFocusedContainer(state),
});

export default util.hoc.connect(
  util.hoc.STORES.STATE_AND_DS,
  mapStateToProps,
  null,
  null,
  false,
)(BoxSlideShowManageSlidePanel);
