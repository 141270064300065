// @ts-nocheck
export default function linksArrayManager(linksHelper) {
  this.convertExternalLinkToLinkRef = function (href) {
    return linksHelper.createLinkFromHref(href);
  };

  this.convertLinkRefToLinkRef = function (linkRef) {
    return linksHelper.duplicateLink(linkRef);
  };

  this.convertLinkRefToAttributes = function (linkRef) {
    const link = linksHelper.getLink(linkRef);

    if (link) {
      return { dataquery: `#${link.id}` };
    }

    return null;
  };

  this.convertAttributesToLinkRef = function (attributesObj) {
    return attributesObj.dataquery && attributesObj.dataquery.slice(1); //remove the leading #
  };
}
