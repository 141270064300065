// @ts-nocheck
import _ from 'lodash';
import constants from '../constants/closeButtonsConstants';

const mod = {};

function getIconButtonData(editorAPI) {
  const sections = editorAPI.addPanel.getSectionsByComponentType(
    constants.ICON_TYPE,
    null,
  );

  const structure = sections?.[0]?.props?.items?.[0]?.structure;
  structure.layout = _.omit(structure.layout, ['x', 'y']);

  return structure;
}

function getThemesForCloseTextButton(editorAPI) {
  return editorAPI.addPanelInfra.addPanelStyleManager.getComponentThemeStyles(
    constants.TEXT_TYPE,
    editorAPI.theme,
    editorAPI.components,
  );
}

function getTextButtonData(editorAPI) {
  const structure =
    editorAPI.addPanelInfra.componentsDefaultData.getComponentDefault(
      constants.TEXT_TYPE,
    );
  const style = _.head(getThemesForCloseTextButton(editorAPI));

  structure.layout = structure.panelLayout;
  structure.style = style;

  delete structure.panelLayout;
  return structure;
}

mod[constants.ICON_TYPE] = getIconButtonData;
mod[constants.TEXT_TYPE] = getTextButtonData;

export default mod;
