import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { Divider, RichText, Text, IconButton, Tooltip } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import * as util from '@/util';
import {
  mapStateToProps,
  mapDispatchToProps,
  type QrCodeExposerStateProps,
  type QrCodeExposerDispatchProps,
} from './qrCodeExposerMapper';

export interface QrCodeExposerProps
  extends QrCodeExposerStateProps,
    QrCodeExposerDispatchProps {}

export interface QrCodeExposerState {
  shouldQrCodeExposerPanelOpen: boolean;
}

class QrCodeExposer extends React.Component<
  QrCodeExposerProps,
  QrCodeExposerState
> {
  constructor(props: QrCodeExposerProps) {
    super(props);
    this.state = {
      shouldQrCodeExposerPanelOpen:
        this.props.shouldQrCodeExposerPanelInitiallyOpen,
    };
  }

  componentDidMount() {
    this.props.onComponentMount();
    if (this.props.shouldQrCodeExposerPanelInitiallyOpen) {
      this.props.sendBi(coreBi.events.panels.PANEL_OPENED, {
        panel_name: 'qrCodeExposer',
      });
    }
  }

  toggleQrCodePanelVisibility = () => {
    if (this.state.shouldQrCodeExposerPanelOpen) {
      this.props.sendBi(coreBi.events.commonControls.Panel_Header_Close, {
        panel_name: 'qrCodeExposer',
      });
    } else {
      this.props.sendBi(coreBi.events.panels.PANEL_OPENED, {
        panel_name: 'qrCodeExposer',
      });
    }
    this.setState({
      shouldQrCodeExposerPanelOpen: !this.state.shouldQrCodeExposerPanelOpen,
    });
  };

  handleQrExportClick = () => {
    if (!this.state.shouldQrCodeExposerPanelOpen) {
      this.props.sendBi(
        coreBi.events.mobileEditor.MOBILE_OPEN_QRCODE_PANEL_CLICK,
        {
          target: 'view_published_version_on_mobile',
        },
      );
    }
    this.toggleQrCodePanelVisibility();
  };

  handleHelpClick = () => {
    this.props.openHelpCenter('4bb45b5d-5269-403e-9980-012851ad6fc8');
    this.props.sendBi(coreBi.events.help.HELP_CLICK, {
      panel_name: 'qrCodeExposer',
      origin: 'panel',
      helpId: '4bb45b5d-5269-403e-9980-012851ad6fc8',
    });
  };

  render() {
    return (
      <div
        className="qrcode-exposer-wrapper"
        style={{
          bottom: this.props.qrCodeExposerBottomPosition,
          left: this.props.qrCodeExposerLeftPosition,
        }}
      >
        <div
          className={util.cx('qrcode-exposer-panel', {
            hidden: !this.state.shouldQrCodeExposerPanelOpen,
          })}
        >
          <header className="qrcode-exposer-panel-header">
            <IconButton skin="light-dark">
              <symbols.symbol
                className="qrcode-exposer-panel-header-icon"
                onClick={this.toggleQrCodePanelVisibility}
                name="close-tab"
              />
            </IconButton>
          </header>
          <div className="qrcode-exposer-panel-content">
            <Text
              skin="standard"
              size="medium"
              weight="bold"
              enableEllipsis={false}
            >
              {'QR_View_Mobile_Site_Preview_Popup_Title'}
            </Text>
            <img className="qr-code-image" src={this.props.qrCodeUrl} />
          </div>
          <Divider long />
          <footer className="qrcode-exposer-panel-footer">
            <RichText>
              <Text
                skin="standard"
                size="small"
                weight="normal"
                enableEllipsis={false}
              >
                {'QR_View_Mobile_Site_Preview_Popup_Text'}
              </Text>
              <a
                className="qrcode-exposer-learn-more"
                onClick={this.handleHelpClick}
              >
                {` ${translate('QR_View_Mobile_Site_Preview_Popup_Link')}`}
              </a>
            </RichText>
          </footer>
        </div>
        <div
          className="qrcode-exposer-button-wrapper"
          onClick={this.handleQrExportClick}
        >
          <Tooltip
            content="QR_View_Mobile_Site_Preview_Tooltip"
            closeOnMouseClick={true}
            disabled={this.state.shouldQrCodeExposerPanelOpen}
            marginTop={5}
          >
            <div className="qrcode-exposer-button">
              <symbols.symbol
                name={
                  this.state.shouldQrCodeExposerPanelOpen
                    ? 'qrcode-exposer-pressed'
                    : 'qrcode-exposer'
                }
              />
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(QrCodeExposer);
