import React from 'react';
import PropTypes from 'prop-types';
import * as util from '@/util';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import * as symbols from '@wix/santa-editor-symbols';
import type { SendBiFunction } from 'types/bi';

const { MODES } = constants.ROOT_COMPS.TOPBAR;

interface WixLogoProps {
  sendBi: SendBiFunction;
  isPreviewMode: boolean;
  isZoomMode: boolean;
  isSitePublished: boolean;
  topBarStateBIParamValue: string;
}

const WixLogo: React.FunctionComponent<WixLogoProps> = (props) =>
  React.createElement(
    'div',
    {
      className: 'top-bar-left-wix-logo',
      onClick: () => {
        util.editorWixRecorder.addLabel('wix_logo top bar clicked');
        const stageMode = props.isZoomMode ? MODES.ZOOM_OUT : MODES.EDITOR;
        props.sendBi(coreBi.events.topbar.top_bar_click, {
          category: 'wix_logo',
          origin: props.isPreviewMode ? MODES.PREVIEW : stageMode,
          is_published: props.isSitePublished,
          state: props.topBarStateBIParamValue,
        });
      },
      onMouseEnter: () => {
        const biEventName = coreBi.events.topbar.top_bar_menu_item_hover;
        props.sendBi(biEventName, {
          menu_item_name: 'VIEW_WIX_LOGO',
        });
      },
    },
    React.createElement(symbols.symbol, { name: 'wixLogo' }),
  );

WixLogo.displayName = 'WixLogo';
WixLogo.propTypes = {
  sendBi: PropTypes.func.isRequired,
  isPreviewMode: PropTypes.bool.isRequired,
  isZoomMode: PropTypes.bool.isRequired,
  isSitePublished: PropTypes.bool.isRequired,
  topBarStateBIParamValue: PropTypes.string.isRequired,
};

export default util.hoc.renderWhenMutated(WixLogo);
