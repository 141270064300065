export { SectionsApiEntryPoint } from './scope';
export { SectionsApiKey } from './publicApiKey';
export { StageSideAreaBorder } from './components/StageSideAreaBorder/StageSideAreaBorder';
export {
  MobileStageSideArea,
  DesktopStageSideArea,
} from './components/StageSideArea/StageSideArea';
export * as selectors from './components/selectors';
export * as utils from './utils';
export { ClassicSectionResizeAndPushEntryPoint } from './classicSectionResizeAndPush/apiEntry';

export type { SectionWithLayout } from './api';
export type { Scope } from './scope';
