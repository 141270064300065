import * as util from '@/util';

type SiteVideoQuota = Record<'quota' | 'usage', number>;

function getSiteVideoQuota(siteMediaToken: string): Promise<SiteVideoQuota> {
  return util.http
    .fetch(`//files.wix.com/media/quota/info?site_token=${siteMediaToken}`, {
      method: 'GET',
      credentials: 'include',
    })
    .then((res: AnyFixMe) => res.json())
    .then(
      (res: AnyFixMe) =>
        (res?.quota?.video_duration || {
          quota: 0,
          usage: 0,
        }) as SiteVideoQuota,
    );
}

export { getSiteVideoQuota };
