// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { Composites, ProgressBar, TextLabel } from '@wix/wix-base-ui';

function template(props) {
  return (
    <div className="tasks-section">
      <Composites.ProgressBar>
        <TextLabel type="T03" value={props.title} />
        <ProgressBar
          automationId="progress-bar-percentage"
          progress={props.tasksDone}
          progressMax={props.totalNumOfTasks}
        />
        <TextLabel
          automationId="task-name"
          type="T02"
          value={props.taskDisplayName}
        />
      </Composites.ProgressBar>
    </div>
  );
}

const PackageTasksSection = (props) => template(props);

PackageTasksSection.displayName = 'PackageTasksSection';
PackageTasksSection.propTypes = {
  tasksDone: PropTypes.number.isRequired,
  taskDisplayName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  totalNumOfTasks: PropTypes.number.isRequired,
};
export default PackageTasksSection;
