import * as stateManagement from '@/stateManagement';
import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import type { SendBiFunction } from 'types/bi';

export interface StateProps {
  selectedComponentType: string;
}

export interface DispatchProps {
  sendBI: SendBiFunction;
  closePanelByName: (panelName: string) => void;
  openHelpCenter: (
    helpId: string,
    props?: AnyFixMe,
    biHelpParams?: AnyFixMe,
  ) => void;
  openPanel: (panelName: string, panelProps?: AnyFixMe) => void;
}

export const mapStateToProps: MapStateToProps<StateProps, {}> = ({
  editorAPI,
}) => ({
  selectedComponentType: editorAPI.selection.getSelectedComponentType(),
});

export const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  sendBI: stateManagement.bi.actions.event,
  closePanelByName: stateManagement.panels.actions.closePanelByName,
  openHelpCenter: stateManagement.panels.actions.openHelpCenter,
  openPanel: stateManagement.panels.actions.updateOrOpenPanel,
};
