import * as stateManagement from '@/stateManagement';
import { fedopsLogger } from '@/util';

import { createMenuApi } from '../../API/menuAPI';
import { cleanId } from '../../utils/utils';
import { PAGES_MENU_ID } from '../../constants';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

const DEDUPLICATION_COMPLETED_KEY = 'customMenusDeduplicationCompleted';

const {
  INTERACTIONS: { CUSTOM_MENUS_SETUP },
} = fedopsLogger;

export const deduplicateCustomMenus = (editorAPI: EditorAPI) => {
  const siteNeedsDeduplication =
    !stateManagement.userPreferences.selectors.getSiteUserPreferences(
      DEDUPLICATION_COMPLETED_KEY,
    )(editorAPI.store.getState());

  if (!siteNeedsDeduplication) {
    return;
  }

  const menuAPI = createMenuApi(editorAPI);
  const menuComponents = menuAPI.getMenuComponentsWithCustomMenus();

  const mainMenu = menuAPI.getMainMenu();

  if (!mainMenu) {
    return;
  }

  const mainMenuId = cleanId(mainMenu.id);

  const getMenuIdFromComponent = (menuCompRef: CompRef) =>
    cleanId(editorAPI.components.data.get(menuCompRef).menuRef);

  const componentsWithCustomMenus = menuComponents
    .map((menuCompRef: CompRef) => {
      const menuId = getMenuIdFromComponent(menuCompRef);
      return { menuCompRef, menuId };
    })
    .filter(({ menuId }) => menuId !== PAGES_MENU_ID && menuId !== mainMenuId);

  let numberOfDeduplicatedMenus = 0;

  if (componentsWithCustomMenus.length === 0) {
    return;
  }

  fedopsLogger.interactionStarted(CUSTOM_MENUS_SETUP.DEDUPLICATE_CUSTOM_MENUS);

  componentsWithCustomMenus.forEach(({ menuCompRef, menuId }, index, arr) => {
    const arrayOfPrevious = arr.slice(0, index);

    if (
      arrayOfPrevious.some(({ menuId: prevMenuId }) => prevMenuId === menuId)
    ) {
      numberOfDeduplicatedMenus += 1;
      const newMenuId = menuAPI.duplicateMenu({ menuId });

      editorAPI.waitForChangesApplied(() => {
        menuAPI.connect(newMenuId, menuCompRef);
      });
    }
  });

  fedopsLogger.interactionEnded(CUSTOM_MENUS_SETUP.DEDUPLICATE_CUSTOM_MENUS);

  /**
   * in case of something goes wrong, we are setting this pref only on after a dry run
   * */
  if (numberOfDeduplicatedMenus === 0) {
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      DEDUPLICATION_COMPLETED_KEY,
      true,
    );
  }
};
