'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const mapDragging = true
const util = require('@/util')
const languageCode = util.languages.getLanguageCode()

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_googlemap",
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/googleMapsDesignPanelSection_en/googleMapsDesignPanelSection_en.png",
        "imageHover": null,
        "items": [
            {
                "id": "Contact_Google_Maps_Design_Panel_1",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 40,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "administrative.province",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "administrative.neighborhood",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "administrative.land_parcel",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape.man_made",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape.natural.landcover",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape.natural.terrain",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#e10404"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.attraction",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.business",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    },
                                    {
                                        "hue": "#ff0070"
                                    },
                                    {
                                        "saturation": 0
                                    },
                                    {
                                        "lightness": -57
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.government",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.medical",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#eb65a0"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#eb65a0"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.school",
                                "stylers": [
                                    {
                                        "hue": "#ff0000"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.sports_complex",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#bccf02"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.highway.controlled_access",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    },
                                    {
                                        "color": "#9b539c"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    },
                                    {
                                        "hue": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "hue": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit.line",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#9b539c"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit.station.bus",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#46bcec"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#73c5e1"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "hue": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyihily"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 0
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_2",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 40,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#e7fff4"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "color": "#c69665"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "weight": 0.01
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#46bcec"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#5cddff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#c69665"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "weight": 0.01
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyicf0o"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 0
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_3",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 404,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "lightness": -22
                                    },
                                    {
                                        "gamma": 4.97
                                    },
                                    {
                                        "saturation": -57
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative.land_parcel",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi.attraction",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.attraction",
                                "stylers": [
                                    {
                                        "color": "#ffcece"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "poi.attraction",
                                "stylers": [
                                    {
                                        "color": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi.business",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "poi.business",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "invert_lightness": true
                                    },
                                    {
                                        "gamma": 10
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#9dd99b"
                                    },
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "gamma": 2.79
                                    },
                                    {
                                        "lightness": 27
                                    },
                                    {
                                        "saturation": 4
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#54734e"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    },
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "color": "#ff8800"
                                    },
                                    {
                                        "gamma": 1.5
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "saturation": -92
                                    },
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "saturation": -93
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "gamma": 1
                                    },
                                    {
                                        "hue": "#ff0000"
                                    },
                                    {
                                        "saturation": 100
                                    },
                                    {
                                        "lightness": 0
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit.line",
                                "stylers": [
                                    {
                                        "saturation": -59
                                    },
                                    {
                                        "gamma": 1
                                    },
                                    {
                                        "lightness": -29
                                    },
                                    {
                                        "weight": 0.13
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "transit.line",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "gamma": 4.71
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "transit.line",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "transit.line",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#47b6bb"
                                    },
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "lightness": 58
                                    },
                                    {
                                        "saturation": 45
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyicf1j1"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 182
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_4",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 404,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.attraction",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fea0a0"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.business",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fea0a0"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.government",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fea0a0"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.medical",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fea0a0"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fae7e7"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.place_of_worship",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fea0a0"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.school",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fea0a0"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.sports_complex",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fea0a0"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway.controlled_access",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#ffb4b4"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fbd5d5"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#ffdfdf"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "transit.line",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fec0c0"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#d24a43"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fba4a4"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyicf1j2"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 182
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_5",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 768,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "saturation": 15
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#fed500"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inym9i10"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 364.5
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_6",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 768,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#ff6865"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inym9i04"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 364.5
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_7",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 1132,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#a93787"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "hue": "#FFBB00"
                                    },
                                    {
                                        "saturation": 43
                                    },
                                    {
                                        "lightness": 37
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "hue": "#00FF6A"
                                    },
                                    {
                                        "saturation": -1
                                    },
                                    {
                                        "lightness": 11
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "poi.attraction",
                                "stylers": [
                                    {
                                        "color": "#f52121"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "hue": "#FFC200"
                                    },
                                    {
                                        "saturation": -61
                                    },
                                    {
                                        "lightness": 45
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "hue": "#FF0300"
                                    },
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 51
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "hue": "#FF0300"
                                    },
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 52
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "hue": "#0078FF"
                                    },
                                    {
                                        "saturation": -13
                                    },
                                    {
                                        "lightness": 2
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#83ccce"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfoct1"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 546
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_8",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 1132,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f0f5f7"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape.man_made",
                                "stylers": [
                                    {
                                        "color": "#ff0000"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    },
                                    {
                                        "color": "#ed1165"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#ed1165"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ed1165"
                                    },
                                    {
                                        "weight": 0.85
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#93aaba"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#3a3c41"
                                    },
                                    {
                                        "weight": 0.01
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocsz"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 546
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_9",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 1496,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative.province",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "saturation": -7
                                    },
                                    {
                                        "lightness": -20
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfoct5"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 728
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_10",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 1496,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#438865"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfoct3"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 728
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_11",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 1860,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#f3f4f4"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape.man_made",
                                "stylers": [
                                    {
                                        "weight": 0.9
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#83cead"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fee379"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fee379"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#7fc8ed"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocta"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 910
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_12",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 1860,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "landscape.man_made",
                                "stylers": [
                                    {
                                        "color": "#f9f8f8"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape.natural",
                                "stylers": [
                                    {
                                        "color": "#f0f0f0"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi.business",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.medical",
                                "stylers": [
                                    {
                                        "color": "#fbd3da"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#e5e5e5"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#fad740"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#f9c830"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "black"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "transit.station.airport",
                                "stylers": [
                                    {
                                        "color": "#c7bace"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#b3eaee"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfoct7"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 910
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_13",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 2224,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "hue": "#008aff"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#0ab9c8"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#5ef141"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#0ab9c8"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfoctg"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 1092
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_14",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 2224,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": 61
                                    },
                                    {
                                        "lightness": 25
                                    },
                                    {
                                        "hue": "#ff003c"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#46e9ec"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocte"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 1092
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_15",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 2588,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "administrative.neighborhood",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "saturation": 100
                                    },
                                    {
                                        "color": "#95c1e8"
                                    },
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "weight": 2.19
                                    },
                                    {
                                        "lightness": 0
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "weight": 1
                                    },
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#95c1e8"
                                    },
                                    {
                                        "lightness": -12
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#95c1e8"
                                    },
                                    {
                                        "weight": 1.79
                                    },
                                    {
                                        "gamma": 1
                                    },
                                    {
                                        "lightness": 0
                                    },
                                    {
                                        "saturation": 100
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#3287d4"
                                    },
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "weight": 10
                                    },
                                    {
                                        "saturation": 81
                                    },
                                    {
                                        "lightness": -16
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    },
                                    {
                                        "saturation": 48
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#d3e5f6"
                                    },
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "weight": 1.51
                                    },
                                    {
                                        "saturation": 100
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "weight": 1.35
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#c1d7eb"
                                    },
                                    {
                                        "weight": 3.03
                                    },
                                    {
                                        "lightness": -21
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "weight": 2.33
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#46bcec"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#9ae1e1"
                                    },
                                    {
                                        "lightness": 7
                                    },
                                    {
                                        "saturation": -5
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#666666"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfoctl"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 1274
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_16",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 2588,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "landscape.man_made",
                                "stylers": [
                                    {
                                        "color": "#f7f7f7"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape.natural",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.attraction",
                                "stylers": [
                                    {
                                        "color": "#42d18c"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#42d18c"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.sports_complex",
                                "stylers": [
                                    {
                                        "color": "#42d18c"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#f765b8"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#f98dc9"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#f98dc9"
                                    },
                                    {
                                        "lightness": 50
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "transit.line",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "##000000"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocti"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 1274
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_17",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 2952,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "color": "#ff0000"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "weight": 0.88
                                    },
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#fc8f6b"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    },
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#eaa68f"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#b1d7db"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfoctr"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 1456
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_18",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 2952,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "color": "#eec8df"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "gamma": 0.01
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": -31
                                    },
                                    {
                                        "lightness": -33
                                    },
                                    {
                                        "weight": 2
                                    },
                                    {
                                        "gamma": 0.8
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "administrative.province",
                                "stylers": [
                                    {
                                        "saturation": -98
                                    },
                                    {
                                        "lightness": 87
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "lightness": -84
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative.neighborhood",
                                "stylers": [
                                    {
                                        "lightness": 100
                                    },
                                    {
                                        "saturation": -1
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "administrative.neighborhood",
                                "stylers": [
                                    {
                                        "saturation": 66
                                    },
                                    {
                                        "lightness": 0
                                    },
                                    {
                                        "weight": 2.6
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "lightness": 30
                                    },
                                    {
                                        "saturation": 30
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "saturation": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "lightness": 20
                                    },
                                    {
                                        "saturation": -20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "lightness": 68
                                    },
                                    {
                                        "saturation": -14
                                    },
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": 25
                                    },
                                    {
                                        "lightness": 25
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": -32
                                    },
                                    {
                                        "gamma": 1.21
                                    },
                                    {
                                        "weight": 0.01
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "lightness": -20
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocto"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 1456
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_19",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 3316,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "color": "#eedd09"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "gamma": 0.01
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": -31
                                    },
                                    {
                                        "lightness": -33
                                    },
                                    {
                                        "weight": 2
                                    },
                                    {
                                        "gamma": 0.8
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "lightness": 30
                                    },
                                    {
                                        "saturation": 30
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "saturation": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "lightness": 20
                                    },
                                    {
                                        "saturation": -20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "lightness": 10
                                    },
                                    {
                                        "saturation": -30
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": 25
                                    },
                                    {
                                        "lightness": 25
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "lightness": -20
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfoctv"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 1638
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_20",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 3316,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "administrative.province",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 30
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "administrative.neighborhood",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "administrative.land_parcel",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "gamma": 0
                                    },
                                    {
                                        "lightness": 74
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#ff0000"
                                    },
                                    {
                                        "gamma": 2
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "color": "#ff0000"
                                    },
                                    {
                                        "lightness": 75
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit.line",
                                "stylers": [
                                    {
                                        "color": "#ff0000"
                                    },
                                    {
                                        "lightness": 65
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit.station",
                                "stylers": [
                                    {
                                        "color": "#ff0000"
                                    },
                                    {
                                        "lightness": 70
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#ff0000"
                                    },
                                    {
                                        "lightness": 37
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfoctt"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 1638
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_21",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 21,
                        "y": 3680,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#faaf3b"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#18181a"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#faef3b"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#faaf3b"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#f9a523"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#faaf3b"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#faaf3b"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#faef3b"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#18aeeb"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocu0"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 1820
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_22",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 3680,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "hue": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#e6007e"
                                    },
                                    {
                                        "weight": 0.3
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#e6007e"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#fff131"
                                    },
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "weight": 6
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#e6007e"
                                    },
                                    {
                                        "weight": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#fff131"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "color": "#e6007e"
                                    },
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fff131"
                                    },
                                    {
                                        "weight": 8
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#e6007e"
                                    },
                                    {
                                        "weight": 8
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "color": "#e6007e"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#e6007e"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "color": "#fff131"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocty"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 1820
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_23",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 4044,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#333739"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#2ecc71"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#2ecc71"
                                    },
                                    {
                                        "lightness": -7
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#2ecc71"
                                    },
                                    {
                                        "lightness": -28
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#2ecc71"
                                    },
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "lightness": -15
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#2ecc71"
                                    },
                                    {
                                        "lightness": -18
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#2ecc71"
                                    },
                                    {
                                        "lightness": -34
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#333739"
                                    },
                                    {
                                        "weight": 0.8
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#2ecc71"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "color": "#333739"
                                    },
                                    {
                                        "weight": 0.3
                                    },
                                    {
                                        "lightness": 10
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocu5"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 2002
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_24",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 4044,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#ef4323"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#ef4323"
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "lightness": -1
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative.neighborhood",
                                "stylers": [
                                    {
                                        "lightness": 0
                                    },
                                    {
                                        "saturation": 0
                                    },
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "administrative.neighborhood",
                                "stylers": [
                                    {
                                        "weight": 0.01
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "administrative.land_parcel",
                                "stylers": [
                                    {
                                        "weight": 0.01
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#ef4323"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#b02308"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "color": "#f9b5a8"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#b02308"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "color": "#f9b5a8"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway.controlled_access",
                                "stylers": [
                                    {
                                        "color": "#b02308"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road.highway.controlled_access",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#b02308"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#b02308"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#b02308"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#b02308"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocu2"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 2002
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_25",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 4408,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#95c11f"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape.man_made",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#7d4e24"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "lightness": -40
                                    },
                                    {
                                        "weight": 2.44
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "color": "#95c11f"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocua"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 2184
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_26",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 4408,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#333333"
                                    },
                                    {
                                        "lightness": 20
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "gamma": 0.75
                                    },
                                    {
                                        "weight": 0.01
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "landscape.man_made",
                                "stylers": [
                                    {
                                        "color": "#404040"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocu7"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 2184
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_27",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 4772,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "all",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "invert_lightness": true
                                    },
                                    {
                                        "saturation": 0
                                    },
                                    {
                                        "lightness": 33
                                    },
                                    {
                                        "gamma": 0.5
                                    },
                                    {
                                        "hue": "#ffcc00"
                                    },
                                    {
                                        "weight": 1.51
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.province",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.neighborhood",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.land_parcel",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "landscape.man_made",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "landscape.natural",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "landscape.natural",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "landscape.natural.landcover",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "landscape.natural.terrain",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "poi.attraction",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "poi.government",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    },
                                    {
                                        "hue": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "transit.station",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "transit.station.rail",
                                "stylers": [
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "transit.station.rail",
                                "stylers": [
                                    {
                                        "hue": "#ff0000"
                                    },
                                    {
                                        "lightness": 42
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "transit.station.rail",
                                "stylers": [
                                    {
                                        "hue": "#ff0000"
                                    },
                                    {
                                        "invert_lightness": true
                                    },
                                    {
                                        "lightness": -15
                                    },
                                    {
                                        "saturation": 31
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocvl"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 2366
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_28",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 4772,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.highway.controlled_access",
                                "stylers": [
                                    {
                                        "color": "#99c018"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocuc"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 2366
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_29",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 5136,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "color": "#c4c4c4"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative.neighborhood",
                                "stylers": [
                                    {
                                        "color": "#db2222"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 21
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.business",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ff0000"
                                    },
                                    {
                                        "lightness": 0
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#575757"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#2c2c2c"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#999999"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#111111"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#aeaeae"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocvp"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 2548
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_30",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 5136,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#0e171d"
                                    }
                                ]
                            },
                            {
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#1e303d"
                                    }
                                ]
                            },
                            {
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "color": "#1e303d"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#1e303d"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#182731"
                                    },
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#f0c514"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#1e303d"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#e77e24"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "color": "#94a5a6"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "color": "#e84c3c"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#e84c3c"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocug"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 2548
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_31",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 5500,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#3e606f"
                                    },
                                    {
                                        "weight": 2
                                    },
                                    {
                                        "gamma": 0.84
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "weight": 0.6
                                    },
                                    {
                                        "color": "#1a3541"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#2c5a71"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#406d80"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#2c5a71"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "color": "#29768a"
                                    },
                                    {
                                        "lightness": -37
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#406d80"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#193341"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocut"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 2730
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_32",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 5500,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "color": "#15e0c5"
                                    },
                                    {
                                        "lightness": 0
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 13
                                    },
                                    {
                                        "weight": 0.01
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#144b53"
                                    },
                                    {
                                        "lightness": 14
                                    },
                                    {
                                        "weight": 1.4
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#0f3944"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#0c4152"
                                    },
                                    {
                                        "lightness": 5
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#0b434f"
                                    },
                                    {
                                        "lightness": 25
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#0b3d51"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#146474"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#0f3944"
                                    },
                                    {
                                        "lightness": -38
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocuj"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 2730
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_33",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 5864,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#b1b1b3"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#3c3d41"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#525459"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#f14e4e"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#1c262d"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#f14e4e"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#3c3d41"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "color": "#3c3d41"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "color": "#eeeeee"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#3c3d41"
                                    },
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#f14e4e"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#3c3d41"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocv4"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 2912
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_34",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 5864,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "color": "#021747"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "lightness": -9
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "lightness": 100
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "gamma": 0.01
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": -31
                                    },
                                    {
                                        "lightness": -33
                                    },
                                    {
                                        "weight": 2
                                    },
                                    {
                                        "gamma": 0.8
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "lightness": 30
                                    },
                                    {
                                        "saturation": 30
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "saturation": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "lightness": 20
                                    },
                                    {
                                        "saturation": -20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "lightness": 10
                                    },
                                    {
                                        "saturation": -30
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": 25
                                    },
                                    {
                                        "lightness": 25
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "lightness": -20
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocuw"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 2912
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_35",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 6228,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "all",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "lightness": 0
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "lightness": -20
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "weight": 0.38
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#b2a77f"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#383838"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#383838"
                                    },
                                    {
                                        "lightness": 0
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape.man_made",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit.line",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "color": "#5f5b4b"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocva"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 3094
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_36",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 6228,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "color": "#f06a21"
                                    },
                                    {
                                        "weight": 0.75
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.province",
                                "stylers": [
                                    {
                                        "color": "#f06a21"
                                    },
                                    {
                                        "weight": 0.5
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "color": "#f06a21"
                                    },
                                    {
                                        "weight": 0.3
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.neighborhood",
                                "stylers": [
                                    {
                                        "weight": 0.2
                                    },
                                    {
                                        "color": "#f06a21"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.land_parcel",
                                "stylers": [
                                    {
                                        "color": "#f06a21"
                                    },
                                    {
                                        "weight": 0.1
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#3d3a38"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocv7"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 3094
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_37",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 6592,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -43
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -70
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -66
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "lightness": -63
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "lightness": 43
                                    },
                                    {
                                        "color": "#419d8c"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -69
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -51
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -54
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -23
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -66
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -62
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -70
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -60
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -54
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -59
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -61
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -45
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#419d8c"
                                    },
                                    {
                                        "lightness": -62
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocvf"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 3276
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_38",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 6592,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "color": "#ff549b"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "weight": 0.01
                                    },
                                    {
                                        "color": "#ff549b"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#00aadc"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyfocvd"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 3276
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_39",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 19,
                        "y": 6956,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#333333"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#fdfeff"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#fefefe"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#fdfeff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#345f8a"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "landscape.natural",
                                "stylers": [
                                    {
                                        "color": "#fdfeff"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#dce0e2"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#dedede"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#fdfeff"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#e9e9e9"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#dce0e2"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "TERRAIN",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyg73ev1"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 3458
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_40",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 343,
                        "y": 6956,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#e9e9e9"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#dedede"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#333333"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#fefefe"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#fefefe"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "TERRAIN",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyiqe61"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 3458
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_41",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 7320,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyg2aht"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 3640
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_42",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 7320,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "TERRAIN",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyg2ahl"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 3640
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_43",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 7684,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "SATELLITE",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyg2aht2"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 0,
                        "y": 3822
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Design_Panel_44",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 344,
                        "y": 7684,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "HYBRID",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "activeModes": {},
                    "id": "comp-inyg2aht1"
                },
                "preset": {
                    "rect": {
                        "width": 162,
                        "height": 182,
                        "x": 162,
                        "y": 3822
                    },
                    "tags": null
                }
            }
        ],
        "compTypes": ["wysiwyg.viewer.components.GoogleMap"]
    },
    "help": {"hide": false, "text": "add_section_info_text_googlemap"}
}
