import { RCM_ORIGIN } from '@/constants';
import * as stateManagement from '@/stateManagement';

import type { BiEventDefinition } from 'types/bi';
import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import { FixedStageApiKey } from '@/apis';
import { fixedStage } from '@/util';

const { event: sendBi } = stateManagement.bi.actions;
const {
  changeGuidePosition,
  changeGuidePositionByPixels,
  updateEditorRulersState,
  sendBIEvent,
  removeGuideViaDelBtn,
  selectGuide,
  deselectGuide,
  setVerticalGuide,
  setHorizontalGuide,
  removeGuideById,
  removeAllGuidesInRuler,
  updateStateOnDragEnd,
} = stateManagement.rulers.actions;

const {
  getSelectedGuideData,
  getSelectedGuideId,
  getHorizontalGuides,
  getVerticalGuides,
} = stateManagement.rulers.selectors;

const { getSelectedCompsRefs } = stateManagement.selection.selectors;

const { getViewPort, getPreviewPosition, getStageLayout } =
  stateManagement.domMeasurements.selectors;

const getGuidePositionLimits = (
  isMobileEditor: boolean,
  isHorizontal: boolean,
  previewHeight: number,
  previewWidth: number,
  pageWidth: number,
  fixedStageEnabled: boolean,
  scrollLeft: number,
) => {
  if (isHorizontal) return { min: 0, max: previewHeight };

  if (fixedStageEnabled) {
    return {
      min: 0,
      max: previewWidth + scrollLeft,
    };
  }

  const pageWidthOffset = (previewWidth - pageWidth) / 2;
  return {
    min: isMobileEditor ? 0 : -pageWidthOffset,
    max: isMobileEditor ? previewWidth : previewWidth - pageWidthOffset,
  };
};

export type RulersDirection = 'vertical' | 'horizontal';
export interface RulersStateProps {
  guidesInRuler: Array<AnyFixMe>;
  selectedGuideId?: string;
  isHovered?: boolean;
  previewHeight?: number;
  toolsHidden?: boolean;
  selectedComponents?: Array<AnyFixMe>;
  isDragging?: boolean;
  isMobileEditor: boolean;
  previewPosition: AnyFixMe;
  siteScroll: AnyFixMe;
  pagesContainerAbsLayout: AnyFixMe;
  stageLayout: any;
  viewPort?: AnyFixMe;
  selectedGuideData?: AnyFixMe;
  isFixedStageEnabled: boolean;
  editingAreaWidth: number;
  isFixedStageTopGapEnabled: boolean;
  isStageHorizontallyScrollable: boolean;
}

export interface RulersDispatchProps {
  removeGuideViaDelBtn: () => void;
  updateStateOnDragEnd: (isMouseOverRulers: boolean) => void;
  removeGuideById: (guideId: string) => void;
  changeGuidePosition: (
    rulersDirection: RulersDirection,
    guideId: string,
    newPosition: number,
  ) => void;
  changeGuidePositionByPixels: (
    direction: RulersDirection,
    pixels: number,
  ) => void;
  selectGuide: (newSelectedGuide: AnyFixMe, origin: string) => void;
  deselectGuide: () => void;
  setVerticalGuide: (xPosition: number) => void;
  setHorizontalGuide: (yPosition: number) => void;
  updateIsHovered: (val: boolean) => void;
  startGuideDrag: () => void;
  openRightClickMenu?: (
    e: React.MouseEvent,
    ruler: AnyFixMe,
    orig: AnyFixMe,
    guideId?: string,
  ) => void;
  sendBi: (biEvent: BiEventDefinition, fields: any) => void;
  sendBIEvent: (biEvent: string, fields: any) => void;
}

export interface RulersOwnProps {
  rulerDirection: RulersDirection;
}

export const mapStateToProps: MapStateToProps<
  RulersStateProps,
  RulersOwnProps
> = ({ state, dsRead, editorAPI }, props) => {
  const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);
  const {
    rulers: { isHovered, isDragging },
    hideTools: toolsHidden,
    siteScale,
    config: { previewHeight },
  } = state;
  const isHorizontal = props.rulerDirection === 'horizontal';
  const isMobileEditor =
    stateManagement.leftBar.selectors.isMobileEditor(state);
  const previewPosition = getPreviewPosition(state);
  const pagesContainerAbsLayout =
    editorAPI.siteSegments.getPagesContainerAbsLayout();

  const getAllGuidesByDirection = isHorizontal
    ? getHorizontalGuides(state)
    : getVerticalGuides(state);

  const isFixedStageTopGapEnabled = fixedStage.isFixedStageEnabled();
  const isFixedStageEnabled = fixedStageAPI.isStageFixedInDesktop();
  const siteScroll = dsRead.site.getScroll();

  const { min, max } = getGuidePositionLimits(
    isMobileEditor,
    isHorizontal,
    previewHeight,
    previewPosition.width,
    pagesContainerAbsLayout.width,
    isFixedStageEnabled,
    siteScroll.x,
  );

  const guidesInRuler = getAllGuidesByDirection.filter(
    (guide) => guide.guidePosition >= min && guide.guidePosition <= max,
  );

  const editingAreaWidth = editorAPI.getEditingAreaPosition().width;

  return {
    isHovered,
    isDragging,
    toolsHidden,
    isMobileEditor,
    pagesContainerAbsLayout,
    previewPosition,
    guidesInRuler,
    previewHeight: previewHeight * siteScale,
    viewPort: getViewPort(state),
    siteScroll,
    stageLayout: getStageLayout(state),
    selectedGuideData: getSelectedGuideData(state),
    selectedComponents: getSelectedCompsRefs(state),
    selectedGuideId: getSelectedGuideId(state),
    isFixedStageEnabled,
    editingAreaWidth,
    isFixedStageTopGapEnabled,
    isStageHorizontallyScrollable:
      fixedStageAPI.isStageHorizontallyScrollable(),
  };
};

const contextMenuActions = stateManagement.contextMenu.actions;

const getOrigin = (origin: 'rulers' | 'guide', direction: RulersDirection) => {
  const isHorizontal = direction === 'horizontal';
  const isRulers = origin === 'rulers';

  if (isHorizontal) {
    return isRulers
      ? RCM_ORIGIN.RULERS_HORIZONTAL
      : RCM_ORIGIN.GUIDE_HORIZONTAL;
  }

  return isRulers ? RCM_ORIGIN.RULERS_VERTICAL : RCM_ORIGIN.GUIDE_VERTICAL;
};

export const mapDispatchToProps: MapDispatchToProps<
  RulersDispatchProps,
  RulersOwnProps
> = (dispatch) => ({
  sendBi: (biEvent: BiEventDefinition, fields: any) =>
    dispatch(sendBi(biEvent, fields)),
  sendBIEvent: (BIEventName, fields) =>
    dispatch(sendBIEvent(BIEventName, fields)),
  removeGuideViaDelBtn: () => dispatch(removeGuideViaDelBtn()),
  changeGuidePosition: (rulersDirection, guideId, newPosition) =>
    dispatch(changeGuidePosition(rulersDirection, guideId, newPosition)),
  changeGuidePositionByPixels: (direction, pixels) =>
    dispatch(changeGuidePositionByPixels(direction, pixels)),
  selectGuide: (newSelectedGuide, origin) =>
    dispatch(selectGuide(newSelectedGuide, origin)),
  deselectGuide: () => dispatch(deselectGuide()),
  setVerticalGuide: (xPosition) => dispatch(setVerticalGuide(xPosition)),
  setHorizontalGuide: (yPosition) => dispatch(setHorizontalGuide(yPosition)),
  updateIsHovered: (val) => dispatch(updateEditorRulersState('isHovered', val)),
  startGuideDrag: () => dispatch(updateEditorRulersState('isDragging', true)),
  updateStateOnDragEnd: (isMouseOverRulers) =>
    dispatch(updateStateOnDragEnd(isMouseOverRulers)),
  removeGuideById: (guideId) => dispatch(removeGuideById(guideId)),
  openRightClickMenu: (e, ruler, orig, guideId?) =>
    dispatch(
      contextMenuActions.openContextMenu({
        editorPositionX: e.clientX,
        editorPositionY: e.clientY,
        origin: getOrigin(orig, ruler.props.rulerDirection),
        removeSelectedGuide: () =>
          dispatch(stateManagement.rulers.actions.removeGuideById(guideId)),
        removeAllGuidesInRuler: () =>
          dispatch(
            removeAllGuidesInRuler(
              ruler.props.isMobileEditor,
              ruler.props.rulerDirection === 'vertical',
            ),
          ),
      }),
    ),
});
