import { create as createEditorApi } from './EditorAPI';
import {
  EditorAPIKey,
  EditorCoreApiKey,
  EditorParamsApiKey,
  InitDocumentServicesApiKey,
} from '@/apis';
import {
  createEditorStore as createEditorStoreBase,
  siteCreation,
} from '@/stateManagement';
import {
  DragInProgressPath,
  type EntryPoint,
  type Shell,
  type AppHost,
} from '@/apilib';
import type { EditorState, EditorStore } from '@/stateManagement';
import type { EditorParams } from '@/editorParams';

function contributeEditorState(
  shell: Shell,
  {
    shouldFreezeState,
    globalServices,
  }: {
    shouldFreezeState: boolean;
    globalServices: any;
  },
): EditorStore {
  const editorStoreBase = createEditorStoreBase(shouldFreezeState);

  shell.contributeState<{ editor: EditorState }>(() => ({
    [DragInProgressPath.EntrypointReducer]: editorStoreBase.reducer,
  }));

  const shellStore = shell.getStore<{ editor: EditorState }>();

  const getRootStore = (() => {
    let rootStore: ReturnType<AppHost['getStore']> | null = null;
    return () => {
      if (rootStore) {
        return rootStore;
      }
      const editorAPI = shell.getAPI(EditorAPIKey);
      if ('host' in editorAPI) {
        rootStore = editorAPI.host.getStore();
      }
      return rootStore;
    };
  })();

  const getMemoizedState = (() => {
    let prevEditorState: EditorState,
      prevRootState: unknown,
      memoizedState: EditorState;

    return (editorState: EditorState, rootState: unknown) => {
      if (editorState !== prevEditorState || rootState !== prevRootState) {
        // invoke mappers if rootState changed too
        prevEditorState = editorState;
        prevRootState = rootState;
        memoizedState = { ...editorState }; //making copy so connect will call mapStateToProps, passing areStoresEq
      }
      return memoizedState || editorState;
    };
  })();

  const getState = () => {
    const editorState =
      shellStore.getState()?.editor || editorStoreBase.initialState;

    const rootState = getRootStore()?.getState();

    return getMemoizedState(editorState, rootState);
  };

  // reduxThunk.withExtraArgument
  const dispatch = (action: any) => {
    if (typeof action === 'function') {
      return action(dispatch, getState, globalServices);
    }

    return shellStore.dispatch(action);
  };

  return {
    ...shellStore,
    getState,
    dispatch,
  } as any;
}

const debugNotExistedPropProxy = (object: any, ignore: string[]) => {
  object.__debugNotExistedPropProxyEnabled = true;
  return new Proxy(object, {
    get: (target, propName) => {
      if (object.__debugNotExistedPropProxyEnabled !== true) {
        return target[propName];
      }
      if (typeof propName !== 'string') {
        return target[propName];
      }
      if (ignore.includes(propName)) {
        return target[propName];
      }
      const propValue = target[propName];
      if (propValue === undefined) {
        throw new Error(`getting not existed prop from editorAPI: ${propName}`);
      }
      return propValue;
    },
  });
};

export function createEditorApiEntryPoint({
  editorParams,
  globalServices,
}: {
  editorParams: EditorParams;
  globalServices: any;
}): EntryPoint {
  return {
    name: DragInProgressPath.EntrypointName,
    declareAPIs: () => [EditorAPIKey],
    getDependencyAPIs: () => [
      EditorCoreApiKey,
      EditorParamsApiKey,
      InitDocumentServicesApiKey,
    ],
    attach(shell) {
      const editorStore = contributeEditorState(shell, {
        shouldFreezeState:
          editorParams.isTestRunner ||
          editorParams.isDebug ||
          editorParams.isQA,
        globalServices,
      });

      shell.contributeAPI(EditorAPIKey, () => {
        const editorAPI = createEditorApi(shell, editorStore);
        if (editorParams.isDebug) {
          return debugNotExistedPropProxy(editorAPI, [
            'dsRead',
            'wixDealerClientApi',
            '@@toStringTag',
          ]);
        }
        return editorAPI;
      });
    },
    extend(shell) {
      const editorAPI = shell.getAPI(EditorAPIKey);
      if (editorParams.siteCreationWizard) {
        editorAPI.store.dispatch(
          siteCreation.actions.setSessionInitializedWithWizard(),
        );
      }
    },
  };
}
