import type { CSSProperties } from 'react';
import constants from '@/constants';
import * as util from '@/util';
import { fedopsLogger } from '@/util';
import { timeoutAfterViewerStartsLoading } from '@wix/bi-logger-editor/v2';
import { ErrorReporter } from '@wix/editor-error-reporter';
import _ from 'lodash';
import React from 'react';
import type { PreviewIframeType } from 'types/core';
import {
  mapDispatchToProps,
  mapStateToProps,
  type PreviewFrameDispatchProps,
  type PreviewFrameStateProps,
} from './previewFrameMapper';
import { tracer } from './tracer';

import type { PreviewPositions } from './types';

function initViewerTimeoutBi(previewNode: AnyFixMe) {
  const viewerStartTimeStamp = new Date().toISOString();
  const isAutoSaveOn = function () {
    const dsModel = window?.[previewNode]?.documentServicesModel;
    return !!dsModel?.autoSaveInfo?.shouldRestoreDiffs;
  };

  const onViewerTimeoutFn = function (timeInSeconds: AnyFixMe) {
    util.biLogger.report(
      timeoutAfterViewerStartsLoading({
        autosave_on: isAutoSaveOn() as any,
        viewer_start_loading_timestamp: viewerStartTimeStamp as any,
        time_seconds: timeInSeconds,
      }),
    );
  };

  const allTimeouts = [
    window.setTimeout(_.partial(onViewerTimeoutFn, 40), 40000),
    window.setTimeout(_.partial(onViewerTimeoutFn, 45), 45000),
    window.setTimeout(_.partial(onViewerTimeoutFn, 50), 50000),
    window.setTimeout(_.partial(onViewerTimeoutFn, 55), 55000),
    window.setTimeout(_.partial(onViewerTimeoutFn, 60), 60000),
  ];

  return () => {
    allTimeouts.forEach((timer) => window.clearTimeout(timer));
  };
}

export interface PreviewFrameOwnProps {
  styleForPushedStageAndPreviewFrame: CSSProperties;
}

interface PreviewFrameProps
  extends PreviewFrameOwnProps,
    PreviewFrameStateProps,
    PreviewFrameDispatchProps {}

interface PreviewManager {
  onMount(): void;
  getPreviewNode(): PreviewIframeType;
  getNodeForPreviewStyles(): HTMLElement;
  renderIframe(): JSX.Element;
  syncPreviewSizes?(
    positions: PreviewPositions,
    styleForPushedStageAndPreviewFrame: CSSProperties,
  ): void;
  subscribeToUpdatePreviewSizes?(cb: () => void): () => void;
  previewModeChanged?(isPreviewMode: boolean): void;
  previewPointerEventsChanged?(pointerEventsOn: boolean): void;
}

class PrefetchedIframe implements PreviewManager {
  private previewPlaceholderRef: React.RefObject<HTMLDivElement>;

  constructor() {
    this.previewPlaceholderRef = React.createRef();
  }
  onMount() {
    window.__previewFrameData.mountAndGetFrame();
  }
  getPreviewNode() {
    return window.__previewFrameData.mountAndGetFrame();
  }
  getNodeForPreviewStyles() {
    return this.previewPlaceholderRef.current;
  }
  previewModeChanged(isPreviewMode: boolean) {
    this.getPreviewNode().style.zIndex = isPreviewMode ? '1' : '0';
  }
  previewPointerEventsChanged(pointerEventsOn: boolean) {
    this.getPreviewNode().style.pointerEvents = pointerEventsOn
      ? 'initial'
      : 'none';
  }
  subscribeToUpdatePreviewSizes(cb: AnyFixMe) {
    const observer = new ResizeObserver(() => {
      cb();
    });
    observer.observe(
      document.getElementById(constants.ROOT_COMPS.SELECTOR_ID.EDITING_AREA),
    );
    observer.observe(this.getNodeForPreviewStyles());

    return () => {
      observer.disconnect();
    };
  }
  renderIframe() {
    return (
      <div
        ref={this.previewPlaceholderRef}
        id={constants.ROOT_COMPS.SELECTOR_ID.PREVIEW_PLACEHOLDER}
      />
    );
  }

  syncPreviewSizes(
    positions: PreviewPositions,
    styleForPushedStageAndPreviewFrame: CSSProperties,
  ) {
    const previewEl = this.getPreviewNode();

    previewEl.style.top = `${positions.top}px`;
    previewEl.style.left = `${positions.left}px`;
    previewEl.style.width = `${positions.width}px`;
    previewEl.style.height = `${positions.height}px`;
    previewEl.style.transform = styleForPushedStageAndPreviewFrame.transform;
    previewEl.style.transition = styleForPushedStageAndPreviewFrame.transition;
  }
}

function wrapWithPreviewFrameErrorHandler<A extends any[], T>(
  cb: (...args: A) => T,
): (...args: A) => T {
  return (...args) => {
    try {
      return cb(...args);
    } catch (err: MaybeError) {
      err.name = `PreviewFrameError: ${err.name}`;
      ErrorReporter.captureException(err, {
        tags: { failedToLoadEditor: true },
        extra: { traces: tracer.getTraces() },
      });
      throw err;
    }
  };
}

class PreviewFrameComponent extends React.Component<PreviewFrameProps> {
  static displayName = 'PreviewFrame';
  previewManager: PreviewManager;
  unsubscribePreviewSizesListener?: () => void;
  constructor(props: AnyFixMe) {
    super(props);
    this.previewManager = new PrefetchedIframe();
  }

  initializeEditor = wrapWithPreviewFrameErrorHandler(async () => {
    this.previewManager.onMount();
    fedopsLogger.appLoadingPhaseFinish('preview-frame-mounted');
    fedopsLogger.appLoadingPhaseStart('preview-handler-initialized');

    tracer.trace('start');
    this.updatePositions();
    tracer.trace('updatedPositions');
    this.unsubscribePreviewSizesListener =
      this.previewManager.subscribeToUpdatePreviewSizes?.(this.updatePositions);
    tracer.trace('subscribedToUpdatePreview');
    this.previewManager.previewModeChanged?.(this.props.isPreviewMode);
    tracer.trace('previewModeChanged');
    this.previewManager.previewPointerEventsChanged?.(
      this.props.pointerEventsOn,
    );
    tracer.trace('previewPointerEventsChanged');

    // preview frame
    fedopsLogger.appLoadingPhaseFinish('preview-handler-initialized');
    fedopsLogger.appLoadingPhaseStart('preview-ds-callback');

    const cleanBiTimeouts = initViewerTimeoutBi(
      this.previewManager.getPreviewNode(),
    );
    const previewNode = await window.__previewFrameData.loadedPromise;
    fedopsLogger.appLoadingPhaseFinish('preview-ds-callback');
    cleanBiTimeouts();

    this.props.notifyPreviewReady(
      previewNode.documentServices,
      previewNode.createSantaPreview,
      previewNode.compFactory,
    );
  });
  componentDidMount() {
    this.initializeEditor();
    window.addEventListener('scroll', this.updatePositions, true);
  }

  componentWillUnmount() {
    this.unsubscribePreviewSizesListener?.();
    window.removeEventListener('scroll', this.updatePositions, true);
  }

  componentDidUpdate(prevProps: PreviewFrameProps) {
    if (!this.props.performingMouseMoveAction) {
      this.updatePositions();
    }
    if (this.props.isPreviewMode !== prevProps.isPreviewMode) {
      this.previewManager.previewModeChanged?.(this.props.isPreviewMode);
    }
    if (this.props.pointerEventsOn !== prevProps.pointerEventsOn) {
      this.previewManager.previewPointerEventsChanged?.(
        this.props.pointerEventsOn,
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: AnyFixMe) {
    // TODO: investigate move to componentDidUpdate
    this.receiveClearTextSelectionEvent(
      nextProps.clearTextSelectionEventCounter,
    );
  }

  receiveClearTextSelectionEvent(clearTextSelectionEventCounter: AnyFixMe) {
    if (
      clearTextSelectionEventCounter > this.props.clearTextSelectionEventCounter
    ) {
      const previewDOMNode = this.previewManager.getPreviewNode();
      const previewContents = previewDOMNode?.contentWindow ?? ({} as any);

      // IE Compatibility
      if (previewContents.selection) {
        previewContents.selection.empty();
      } else if (previewContents.getSelection) {
        previewContents.getSelection().removeAllRanges();
      }
    }
  }

  updatePositions = () => {
    const positions = this.getPreviewRect();
    this.props.updateStageLayout();
    this.props.updatePreviewPos(positions);
    this.previewManager.syncPreviewSizes?.(
      positions,
      this.props.styleForPushedStageAndPreviewFrame,
    );
  };

  getPreviewRect() {
    return _.pick(
      this.previewManager.getNodeForPreviewStyles().getBoundingClientRect(),
      ['top', 'left', 'height', 'width'],
    );
  }

  render() {
    return (
      <div className="preview-container">
        {this.previewManager.renderIframe()}
        {this.props.shouldHidePreviewOverflow ? (
          <div
            style={{
              width: this.props.siteX,
            }}
            key="mobile-hide-preview-left"
            className="mobile-hide-preview-left"
            data-aid="mobile-hide-preview-left"
          />
        ) : null}
        {this.props.shouldHidePreviewOverflow ? (
          <div
            style={{
              width: this.props.siteX,
              left:
                this.props.siteX +
                constants.ROOT_COMPS.MOBILE_EDITOR.MOBILE_FRAME_WIDTH,
            }}
            key="mobile-hide-preview-right"
            className="mobile-hide-preview-right"
            data-aid="mobile-hide-preview-right"
          />
        ) : null}
      </div>
    );
  }
}

export const PreviewFrame = _.flow(
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PreviewFrameComponent);

PreviewFrame.pure = PreviewFrameComponent;
