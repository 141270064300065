// @ts-nocheck
import _ from 'lodash';
import { EditorAPIKey } from '@/apis';
import * as util from '@/util';
import { arrayUtils } from '@/util';
import { layoutUtils } from '@/layoutUtils';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import * as platformEvents from 'platformEvents';
import type { Shell } from '@/apilib';

import type { EditorState } from '@/stateManagement';

type ImageCropState = EditorState['imageCrop'];

const IMAGE_LOADER_DELAY = 150;
const DEFAULT_MASK_ID = '909695c1e003409ba70b5561666c7c4d.svg';

/**
 *  Set .innerHTML or polyfill for IE11
 *
 * @param {SVGElement} element element to set its .innerHTML
 * @param {string} svg string to set
 */
function setSVGInnerHTML(element, svg) {
  if ('innerHTML' in element) {
    element.innerHTML = svg;
  } else {
    const range = window.document.createRange();
    const svgFragment = range.createContextualFragment(svg);
    element.appendChild(svgFragment);
  }
  return element.firstElementChild;
}

/**
 * Get .outerHTML or polyfill for IE11
 *
 * @param element
 * @return {*}
 */
function getSVGOuterHTML(element) {
  if ('outerHTML' in element) {
    return element.outerHTML;
  }

  const div = window.document.createElement('div');
  div.appendChild(element);

  return div.innerHTML;
}

export function createMaskCropApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);

  const getSelectedCompsRefs = () =>
    arrayUtils.asArray(
      stateManagement.selection.selectors.getSelectedCompsRefs(
        editorAPI.store.getState(),
      ),
    );
  const rectUtil = util.math.rect;
  const imageCropUtil = util.imageCrop;
  const { imageTransform } = util;

  function getCropAreaCenterRatio(cropArea, cropOriginArea) {
    const resizeReferencePoint = rectUtil.getMiddlePoint(cropArea);
    return rectUtil.getPointRatioFromRect(resizeReferencePoint, cropOriginArea);
  }

  function getCropAreaCenterAfterResize(
    originalCropArea,
    originalCropOriginArea,
    newCropOriginArea,
  ) {
    const cropAreaCenterRatio = getCropAreaCenterRatio(
      originalCropArea,
      originalCropOriginArea,
    );

    return {
      x: newCropOriginArea.width * cropAreaCenterRatio.x,
      y: newCropOriginArea.height * cropAreaCenterRatio.y,
    };
  }

  function moveCropAreaAndOriginToKeepRatioToCropAreaCenter(
    originalCropOriginArea,
    suggestedResizedNewCropOriginArea,
    originalCropArea,
  ) {
    const newCropOriginArea = _.clone(suggestedResizedNewCropOriginArea);
    const newCropArea = _.clone(originalCropArea);

    const cropAreaCenter = rectUtil.getMiddlePoint(originalCropArea);
    const newCropAreaCenter = getCropAreaCenterAfterResize(
      originalCropArea,
      originalCropOriginArea,
      newCropOriginArea,
    );

    const diff = {
      x: newCropAreaCenter.x - cropAreaCenter.x,
      y: newCropAreaCenter.y - cropAreaCenter.y,
    };

    newCropArea.x += diff.x;
    newCropArea.y += diff.y;

    newCropOriginArea.x -= diff.x;
    newCropOriginArea.y -= diff.y;

    return {
      originArea: newCropOriginArea,
      area: newCropArea,
    };
  }

  function getResizedCropOriginToNewValue(value, cropOriginArea, imageData) {
    const resizeRatio =
      (Math.pow(value / 100, 2) * 100) /
      rectUtil.getAreaRatioPercentage(cropOriginArea, imageData);
    return rectUtil.getResizedByRatio(cropOriginArea, resizeRatio);
  }

  function getCropOnAllScaledImageArea(compLayout) {
    return {
      cropOriginArea: compLayout,
      cropArea: _(compLayout)
        .pick(['height', 'width'])
        .assign({ x: 0, y: 0 })
        .value(),
    };
  }

  /**
   * Sets a new mask and returns the result of the new mask rect
   *
   * @param {string} svgStr
   * @param {Object} cropArea
   * @param {boolean} [isCropModeEnter]
   * @returns {null|{x: number, y: number, width: number, height: number}} mask rect object or null if isCropModeEnter=true
   */
  function setInitMaskValues(svgStr, cropArea, isCropModeEnter) {
    const container = window.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg',
    );
    const editorState = editorAPI.store.getState();
    const selectedComponent = getSelectedCompsRefs(editorState)[0];
    const { width, height, x, y } = cropArea;

    container.setAttribute('viewBox', `0 0 ${width} ${height}`);
    container.setAttribute('width', width);
    container.setAttribute('height', height);
    container.style.visibility = 'hidden';
    container.style.position = 'absolute';
    const svg = setSVGInnerHTML(container, svgStr);
    const id = `mask-svg_${selectedComponent.id}`;
    svg.setAttribute('id', id);

    window.document.body.appendChild(container);

    svg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
    const svgBox = svg.getBBox();
    svg.setAttribute(
      'viewBox',
      `${svgBox.x} ${svgBox.y} ${svgBox.width} ${svgBox.height}`,
    );

    const maskId = `mask_${selectedComponent.id}`;
    const defs = window.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'defs',
    );

    let maskRect = null;
    svg.setAttribute('preserveAspectRatio', 'none');

    // if entering crop we need to preserve the
    if (!isCropModeEnter) {
      const shapeRatio = svgBox.width / svgBox.height;
      const cropRatio = width / height;
      maskRect = {};

      if (cropRatio > shapeRatio) {
        maskRect.height = height;
        maskRect.width = shapeRatio * height;
        maskRect.y = y;
        maskRect.x = x + (width - maskRect.width) / 2;
      } else {
        maskRect.height = width / shapeRatio;
        maskRect.width = width;
        maskRect.x = x;
        maskRect.y = y + (height - maskRect.height) / 2;
      }
    }

    const maskStr = util.imageEffects.getMask(
      maskId,
      id,
      '',
      maskRect || cropArea,
      true,
    );

    setSVGInnerHTML(defs, maskStr);
    container.appendChild(defs);

    container.parentElement.removeChild(container);

    // assign mask BBox to cropArea state
    cropArea.svgStr = getSVGOuterHTML(svg);
    cropArea.svgDomId = id;
    cropArea.svgAspect = svgBox.width / svgBox.height;

    Object.assign(cropArea, maskRect);

    return maskRect;
  }

  function getDefaultMask() {
    return {
      id: DEFAULT_MASK_ID,
      svgAspect: 1,
    };
  }

  function reverseAllCrops(compLayout, compData, crop) {
    const layoutWithoutAutoCrop = imageCropUtil.reverseAutoCrop(
      compLayout,
      crop,
    );
    const layoutWithoutManualCrop = imageCropUtil.reverseManualCrop(
      layoutWithoutAutoCrop,
      crop,
      compData,
    );

    const cropOriginArea = imageCropUtil.reverseAutoCrop(
      layoutWithoutManualCrop,
      compData,
    );

    let cropArea = imageCropUtil.manualCrop(cropOriginArea, crop, compData);
    cropArea = imageCropUtil.autoCrop(cropArea, compLayout);

    return {
      cropOriginArea,
      cropArea,
    };
  }

  function reverseManualCropOnly(compLayout, compData, crop) {
    const cropOriginArea = imageCropUtil.reverseManualCrop(
      compLayout,
      crop,
      compData,
    );
    const cropArea = imageCropUtil.manualCrop(cropOriginArea, crop, compData);

    return {
      cropOriginArea,
      cropArea,
    };
  }

  function reverseAutoCropOnly(compLayout, compData) {
    const cropOriginArea = imageCropUtil.reverseAutoCrop(compLayout, compData);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    let cropArea = _.assign({}, cropOriginArea, { x: 0, y: 0 });
    cropArea = imageCropUtil.autoCrop(
      cropArea,
      _.pick(compLayout, ['height', 'width']),
    );

    return {
      cropOriginArea,
      cropArea,
    };
  }

  function getViewport(isMobile) {
    const stageView = editorAPI.ui.stage.getEditingAreaLayout();
    if (isMobile) {
      const mobileFrame = editorAPI.ui.stage.getMobileFrameLayout();
      return _({
        left: mobileFrame.left - stageView.left,
        top: mobileFrame.top - stageView.top,
      })
        .defaults(mobileFrame)
        .thru(rectUtil.getRectByBoundingRect)
        .value();
    }

    return {
      x: 0,
      y: 0,
      width: editorAPI.site.getSiteWidth(),
      height: editorAPI.site.getHeight(),
    };
  }

  function trimCropAreaToViewPortIfNeeded(cropState) {
    const viewport = getViewport(editorAPI.isMobileEditor());
    return {
      cropArea: imageCropUtil.ensure.areaWithinViewport(viewport, cropState),
      cropOriginArea: cropState.cropOriginArea,
    };
  }

  function generateCropStateForComponent(selectedComponents: CompRef[]) {
    const compData = editorAPI.components.data.get(selectedComponents);
    const compProperties =
      editorAPI.components.properties.get(selectedComponents);
    const fittingType = compProperties.displayMode;
    const rotationInDegrees =
      editorAPI.components.layout.get_rotationInDegrees(selectedComponents);
    const autoCropModeIsOn =
      fittingType === imageTransform.fittingTypes.SCALE_TO_FILL;
    let compLayout = _.pick(
      editorAPI.components.layout.getRelativeToScreen(selectedComponents),
      ['height', 'width', 'x', 'y'],
    );

    let cropState = {};
    const crop = compProperties.overrideCrop || compData.crop;

    if (isUnsupportedDisplayModeForCrop(fittingType)) {
      const imageAspectRatio = layoutUtils.getImageOrCropAspectRatio(compData);

      // notice we're centering the new layout
      compLayout = layoutUtils.getLayoutWithAspectRatio(
        compLayout,
        imageAspectRatio,
        null,
        true,
      );
    }

    if (crop) {
      if (autoCropModeIsOn) {
        cropState = reverseAllCrops(compLayout, compData, crop);
      } else {
        cropState = reverseManualCropOnly(compLayout, compData, crop);
      }

      if (crop.svgStr) {
        cropState.cropArea.svgStr = crop.svgStr;
        cropState.cropArea.svgId = crop.svgId;
      } else if (crop.svgId) {
        cropState.cropArea.svgId = crop.svgId;
      }

      if (crop.flip) {
        cropState.cropArea.flip = crop.flip;
      }
    } else {
      if (autoCropModeIsOn) {
        cropState = reverseAutoCropOnly(compLayout, compData);
      } else {
        cropState = getCropOnAllScaledImageArea(compLayout);
      }

      const defaultMask = getDefaultMask();
      cropState.cropArea.svgId = defaultMask.id;
      cropState.cropArea.svgAspect = defaultMask.svgAspect;
      cropState.cropArea.svgStr = undefined;
      cropState.cropArea.svgDomId = undefined;
    }

    const cropOriginAreaPosAfterRotation = getCropOriginAreaPosAfterRotation(
      cropState,
      rotationInDegrees,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(cropState.cropOriginArea, cropOriginAreaPosAfterRotation);

    cropState = trimCropAreaToViewPortIfNeeded(cropState);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    cropState.originalCropState = _.assign(
      _.pick(cropState.cropArea, ['svgId', 'flip']),
      roundRectDimensions(cropState.cropArea),
    );
    return cropState;
  }

  function getCropOriginAreaPosAfterRotation(cropState, rotationInDegrees) {
    const { cropArea, cropOriginArea } = cropState;
    const cropAreaRelativeToScreen = _.clone(cropArea);
    cropAreaRelativeToScreen.x += cropOriginArea.x;
    cropAreaRelativeToScreen.y += cropOriginArea.y;

    const cropOriginAreaCenter = util.math.getPointAsIfRectWasNotRotated(
      cropAreaRelativeToScreen,
      rectUtil.getMiddlePoint(cropOriginArea),
      -rotationInDegrees,
    );

    return {
      x: cropOriginAreaCenter.x - cropOriginArea.width / 2,
      y: cropOriginAreaCenter.y - cropOriginArea.height / 2,
    };
  }

  function isCropMode() {
    return editorAPI.store.getState().imageCrop.isOn;
  }

  function isEnteringCropMode() {
    return editorAPI.store.getState().imageCrop.isEntering;
  }

  function isUnsupportedDisplayModeForCrop(displayMode) {
    const { fittingTypes } = util.imageTransform;
    const fill = fittingTypes.SCALE_TO_FILL;
    const stretch = fittingTypes.STRETCH;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return !_.includes([fill, stretch], displayMode);
  }

  function enterCropMode(compRef: CompRef[], imageCropState, callback) {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.CROP_MODE,
    );
    editorAPI.autosaveManager.init({ enabled: false });
    util.keyboardShortcuts.setContext(
      util.keyboardShortcuts.CONTEXTS.IMAGE_CROP,
    );

    const currentDisplayMode =
      editorAPI.components.properties.get(compRef).displayMode;

    if (isUnsupportedDisplayModeForCrop(currentDisplayMode)) {
      imageCropState.originalDisplayMode = currentDisplayMode;
      // side effect: fix displayMode to `fill`
      editorAPI.components.properties.update(compRef, {
        displayMode: util.imageTransform.fittingTypes.SCALE_TO_FILL,
      });
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(imageCropState, generateCropStateForComponent(compRef));

    imageCropState.cachedCropArea = Object.assign({}, imageCropState.cropArea);

    editorAPI.bi.event(coreBi.events.imageCrop.Open_crop_mode, {
      fixed_layout: false,
    });
    setCropData(imageCropState, callback, true);
  }

  function exitCropMode(imageCropState, callback) {
    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.CROP_MODE,
    );
    editorAPI.autosaveManager.init({ enabled: true }, true);
    util.keyboardShortcuts.setContext(util.keyboardShortcuts.CONTEXTS.EDITOR);

    // delete the cached original values
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(imageCropState, {
      cropArea: null,
      cropOriginArea: null,
      compOriginalValues: null,
      originalDisplayMode: null,
    });

    editorAPI.updateState({ imageCrop: imageCropState }, callback);
  }

  async function toggleCropMode(shouldCropModeBeOn, callback) {
    const editorState = editorAPI.store.getState();
    const imageCropState = _.cloneDeep(editorState.imageCrop);
    const selectedCompsRefs = getSelectedCompsRefs(editorState);
    const { getSessionUserPreferences } =
      stateManagement.userPreferences.selectors;
    const userHideToolsPreferences =
      getSessionUserPreferences('hideTools')(editorState);

    if (
      _.isEmpty(selectedCompsRefs) ||
      imageCropState.isOn === shouldCropModeBeOn
    ) {
      return;
    }

    imageCropState.isOn =
      typeof shouldCropModeBeOn === 'undefined'
        ? !imageCropState.isOn
        : shouldCropModeBeOn;

    function toggle() {
      if (imageCropState.isOn) {
        enterCropMode(selectedCompsRefs, imageCropState);
      } else {
        exitCropMode(imageCropState, callback);
      }
    }

    if (!userHideToolsPreferences) {
      editorAPI.toolsControl.toggleHideTools(imageCropState.isOn);
    }

    if (imageCropState.isOn) {
      const currentState = editorAPI.store.getState().imageCrop;
      editorAPI.updateState({
        imageCrop: { ...currentState, isEntering: true },
      });

      // If there are any changes to the stage we need to wait for preview frame size transition end (preview resize)
      // because it will mess next layout calculations
      await stateManagement.domMeasurements.helpers.createWaitForStageLayoutChangeEndPromise();
    }

    toggle();
  }

  function getCropArea(shouldTryCache) {
    const state = editorAPI.store.getState().imageCrop;
    return _.clone(
      shouldTryCache ? state.cachedCropArea || state.cropArea : state.cropArea,
    );
  }

  function getCropOriginArea() {
    return _.clone(editorAPI.store.getState().imageCrop.cropOriginArea);
  }

  function getCompOriginalValues() {
    return _.clone(editorAPI.store.getState().imageCrop.compOriginalValues);
  }

  function getCompParentLayoutRelativeToScreen(compRef) {
    const parentRef =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
    const parentLayoutRelativeToScreen =
      editorAPI.components.layout.getRelativeToScreen(parentRef);

    if (editorAPI.isMobileEditor()) {
      return parentLayoutRelativeToScreen;
    }

    const pagesContainer = editorAPI.components.get.byId('PAGES_CONTAINER');
    const pagesContainerLayoutRelativeToScreen =
      editorAPI.components.layout.getRelativeToScreen(pagesContainer);

    if (editorAPI.columns.isColumn(parentRef)) {
      // if column, we need to compensate for column margin

      const containerMargin =
        editorAPI.components.layout.getCompMargin(parentRef);
      parentLayoutRelativeToScreen.x += containerMargin.left;
    } else if (
      editorAPI.components.is.fullWidth(parentRef) ||
      editorAPI.components.isShowOnAllPages(compRef)
    ) {
      parentLayoutRelativeToScreen.x += pagesContainerLayoutRelativeToScreen.x;
    }

    return parentLayoutRelativeToScreen;
  }

  function getCropAreaRelativePosAfterRotation(cropArea, cropOriginArea, deg) {
    const cropAreaCenterRotatedBackwards = rectUtil.getMiddlePoint(cropArea);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const normalizedCropOriginArea = _.assign({}, cropOriginArea, {
      x: 0,
      y: 0,
    });

    const actualCropAreaCenter = util.math.getPointAsIfRectWasNotRotated(
      normalizedCropOriginArea,
      cropAreaCenterRotatedBackwards,
      -deg,
    );

    return {
      x: actualCropAreaCenter.x - cropArea.width / 2,
      y: actualCropAreaCenter.y - cropArea.height / 2,
    };
  }

  function getCropAreaFinalPosition(isRelativeToScreen, ignoreRotation) {
    const editorState = editorAPI.store.getState();
    const selectedCompsRefs = getSelectedCompsRefs(editorState);
    const imageCropState = editorState.imageCrop;
    const { cropOriginArea } = imageCropState;

    if (
      !imageCropState ||
      !imageCropState.cropArea ||
      cropOriginArea.x === undefined ||
      cropOriginArea.y === undefined
    ) {
      return null;
    }

    const { cropArea } = imageCropState;
    const deg =
      editorAPI.components.layout.get_rotationInDegrees(selectedCompsRefs);

    const parentLayout = isRelativeToScreen
      ? {
          x: 0,
          y: 0,
        }
      : getCompParentLayoutRelativeToScreen(selectedCompsRefs);

    const cropAreaRelativePos = ignoreRotation
      ? cropArea
      : getCropAreaRelativePosAfterRotation(cropArea, cropOriginArea, deg);

    return _(cropArea)
      .pick(['height', 'width'])
      .assign({
        x: cropAreaRelativePos.x + cropOriginArea.x - parentLayout.x,
        y: cropAreaRelativePos.y + cropOriginArea.y - parentLayout.y,
      })
      .value();
  }

  function roundRectDimensions(dims) {
    return _(dims)
      .pick(['x', 'y', 'width', 'height'])
      .mapValues(Math.round)
      .value();
  }

  function toggleFlip(direction) {
    const state = editorAPI.store.getState().imageCrop;
    const currentFlip = state.cropArea?.flip || '';
    let flip;

    switch (currentFlip) {
      case '':
        flip = direction;
        break;
      case direction:
        flip = '';
        break;
      case 'xy':
        flip = direction === 'x' ? 'y' : 'x';
        break;
      default:
        flip = 'xy';
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const cropArea = _.assign({}, state.cropArea, { flip });

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    setCropState(_.assign({}, state, { cropArea }));
  }

  const fetchSvgAsync = (svgId: string) =>
    new Promise((resolve) => {
      editorAPI.documentServices.media.vectorImage.fetchSvg(svgId, resolve);
    });

  async function setCropState(
    cropState: ImageCropState,
    callback?: () => void,
    isCropModeEnter: boolean,
  ) {
    const newState = _.cloneDeep(cropState);
    newState.cropOriginArea = roundRectDimensions(newState.cropOriginArea);
    if (isCropModeEnter) {
      newState.isEntering = false;
    }

    if (newState.cropArea.svgId && !newState.cropArea.svgStr) {
      const svgString = await fetchSvgAsync(newState.cropArea.svgId);

      Object.assign(
        newState.cropArea,
        roundRectDimensions(cropState.cachedCropArea || cropState.cropArea),
      );
      setInitMaskValues(svgString, newState.cropArea, isCropModeEnter);
    }

    editorAPI.updateState({ imageCrop: newState }, callback);
  }

  function setCropOriginArea(newCropOriginArea, callback) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const imageCropState = _.assign({}, editorAPI.store.getState().imageCrop, {
      cropOriginArea: newCropOriginArea,
    });
    setCropState(imageCropState, callback);
  }

  function setCropArea(newCropArea, callback) {
    const oldCropState = editorAPI.store.getState().imageCrop;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const imageCropState = _.assign({}, oldCropState, {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      cropArea: _.assign({}, oldCropState.cropArea, newCropArea),
    });
    setCropState(imageCropState, callback);
  }

  function cacheCropArea(callback) {
    const cropState = editorAPI.store.getState().imageCrop;
    const clone = Object.assign({}, cropState, {
      cachedCropArea: Object.assign({}, cropState.cropArea),
    });
    setCropState(clone, callback);
  }

  function clearCachedCropArea(callback) {
    const cropState = editorAPI.store.getState().imageCrop;
    const clone = Object.assign({}, cropState, { cachedCropArea: null });
    setCropState(clone, callback);
  }

  function setCropData(newCropData, callback, isCropModeEnter) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const imageCropState = _.assign(
      {},
      editorAPI.store.getState().imageCrop,
      newCropData,
    );
    setCropState(imageCropState, callback, isCropModeEnter);
  }

  function setCompOriginalValues(compOriginalValues, callback) {
    const oldCropState = editorAPI.store.getState().imageCrop;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const imageCropState = _.assign({}, oldCropState, { compOriginalValues });
    setCropState(imageCropState, callback);
  }

  function getNewCropValues(compRef) {
    const imageData = editorAPI.components.data.get(compRef);
    const fixedPosition =
      editorAPI.components.layout.get_fixedPosition(compRef);
    const cropArea = editorAPI.imageCrop.getCropArea();
    const cropOriginArea = editorAPI.imageCrop.getCropOriginArea();
    const cropAreaLayout =
      editorAPI.imageCrop.getCropAreaFinalPosition(fixedPosition);

    const resizeRatio = {
      width: cropOriginArea.width / imageData.width,
      height: cropOriginArea.height / imageData.height,
    };

    if (cropArea.x === undefined || cropArea.y === undefined) return null;

    let crop = _.mapValues(
      {
        x: cropArea.x / resizeRatio.width,
        y: cropArea.y / resizeRatio.height,
        height: cropArea.height / resizeRatio.height,
        width: cropArea.width / resizeRatio.width,
      },
      Math.round,
    );

    // things we don't want to round
    if (cropArea.svgId) {
      crop.svgId = cropArea.svgId;
      if (cropArea.flip) {
        crop.flip = cropArea.flip;
      }
    }

    const isCropOnFullImage =
      rectUtil.areRectsTheSameSize(imageData, crop) &&
      crop.x === 0 &&
      crop.y === 0 &&
      (!crop.svgId || crop.svgId === getDefaultMask().id);

    if (isCropOnFullImage && !editorAPI.isMobileEditor()) {
      crop = null;
    }

    return {
      crop,
      cropAreaLayout,
    };
  }

  function setCropDataAndLayout(compRef, { crop, cropAreaLayout }, skipUndo) {
    if (editorAPI.isMobileEditor()) {
      editorAPI.components.properties.update(
        compRef,
        { overrideCrop: crop },
        skipUndo,
      );
    } else {
      editorAPI.components.data.update(compRef, { crop }, skipUndo);
    }
    editorAPI.components.layout.update(compRef, cropAreaLayout, skipUndo);
  }

  function hasCropChanged() {
    const cropState = editorAPI.store.getState().imageCrop;
    const changedCropState = _.pick(cropState.cropArea, [
      'x',
      'y',
      'width',
      'height',
      'svgId',
      'flip',
    ]);
    return !_.isEqual(cropState.originalCropState, changedCropState);
  }

  /**
   * Update and Close Crop State, if no data changed, call cancelCrop function
   * @param {{}} compRef
   * @param {string} biOrigin bi event origin field
   */
  function updateCrop(compRef, biOrigin) {
    const isChanged = hasCropChanged();
    if (isChanged) {
      setCropDataAndLayout(compRef, getNewCropValues(compRef));
      editorAPI.documentServices.waitForChangesApplied(() => {
        editorAPI.documentServices.renderPlugins.showComp(compRef);
        toggleCropMode(false);

        editorAPI.bi.event(coreBi.events.imageCrop.apply_crop, {
          is_changed: isChanged,
          origin: biOrigin,
        });
      });
    } else {
      cancelCrop(compRef);
    }
    editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
      platformEvents.factory.componentCropSaved({ compRef }),
    );
  }

  function previewCrop(compRef) {
    if (_.isEmpty(getCompOriginalValues())) {
      const crop = editorAPI.isMobileEditor()
        ? editorAPI.components.properties.get(compRef).overrideCrop
        : editorAPI.components.data.get(compRef).crop;
      const cropAreaLayout = editorAPI.components.layout.get(compRef);

      setCompOriginalValues({ crop, cropAreaLayout });
    }

    setCropDataAndLayout(compRef, getNewCropValues(compRef), true);
  }

  function revertDisplayMode(selectedComponent) {
    const imageCropState = editorAPI.store.getState().imageCrop;

    if (imageCropState.originalDisplayMode) {
      const { originalDisplayMode } = imageCropState;

      // revert displayMode of component
      editorAPI.components.properties.update(selectedComponent, {
        displayMode: originalDisplayMode,
      });
    }
  }

  function cancelCrop(compRef) {
    revertDisplayMode(compRef);
    const originalValues = getCompOriginalValues();

    if (!_.isEmpty(originalValues)) {
      setCropDataAndLayout(compRef, originalValues);

      editorAPI.documentServices.waitForChangesApplied(function () {
        window.setTimeout(() => {
          editorAPI.documentServices.renderPlugins.showComp(compRef);
          toggleCropMode(false);
        }, IMAGE_LOADER_DELAY);
      });
    } else {
      editorAPI.documentServices.renderPlugins.showComp(compRef);
      toggleCropMode(false);
    }
  }

  function getCropOriginDiffPositionAfterRotate(
    cropOriginArea,
    newCropOriginArea,
    rotationInDegrees,
  ) {
    // TODO: Extract calculation and make this code readable
    const cropOriginCenter = rectUtil.getMiddlePoint(cropOriginArea);
    const cropOriginCenterAfterMove =
      rectUtil.getMiddlePoint(newCropOriginArea);

    const vector = {
      x: cropOriginCenterAfterMove.x - cropOriginCenter.x,
      y: cropOriginCenterAfterMove.y - cropOriginCenter.y,
    };

    const rad = util.math.degToRad(rotationInDegrees);
    const rotatedVector = util.math.rotateVectorAround(vector, rad);

    return {
      x: util.math.roundToNearestHalf(rotatedVector.x - vector.x),
      y: util.math.roundToNearestHalf(rotatedVector.y - vector.y),
    };
  }

  function getNewCropStateByPercentageFromOriginalImage(compRef, value) {
    const originalImageData = editorAPI.components.data.get(compRef);
    const rotationInDegrees =
      editorAPI.components.layout.get_rotationInDegrees(compRef);
    const { width, height } = editorAPI.components.layout.get_size(compRef);
    const fittingType =
      editorAPI.components.properties.get(compRef).displayMode;
    const cropOriginArea = editorAPI.imageCrop.getCropOriginArea();
    const cropArea = editorAPI.imageCrop.getCropArea();
    const compAspectRatio = rectUtil.getAspectRatio({ width, height });
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const stretchedImageSize = _.assign({}, originalImageData, {
      height: originalImageData.width * compAspectRatio,
    });
    const imageSize =
      fittingType === imageTransform.fittingTypes.STRETCH
        ? stretchedImageSize
        : originalImageData;
    const cropOriginMinDimensions =
      rectUtil.getRectMinDimensionsByContainedInnerRect(imageSize, cropArea);

    let scaledCropOriginArea = getResizedCropOriginToNewValue(
      value,
      cropOriginArea,
      originalImageData,
    );
    scaledCropOriginArea = imageCropUtil.ensure.scaleWithinDimensions(
      scaledCropOriginArea,
      cropArea,
      cropOriginMinDimensions,
    );
    const newCrop = moveCropAreaAndOriginToKeepRatioToCropAreaCenter(
      cropOriginArea,
      scaledCropOriginArea,
      cropArea,
    );

    const newCropWithinLimits = imageCropUtil.ensure.areaIsFullyContained(
      newCrop.originArea,
      newCrop.area,
    );

    const cropOriginPositionDiff = getCropOriginDiffPositionAfterRotate(
      cropOriginArea,
      newCropWithinLimits.originArea,
      rotationInDegrees,
    );

    newCropWithinLimits.originArea.x += cropOriginPositionDiff.x;
    newCropWithinLimits.originArea.y += cropOriginPositionDiff.y;

    return newCropWithinLimits;
  }

  function setResizing(isResizing, callback) {
    const oldCropState = editorAPI.store.getState().imageCrop;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const imageCropState = _.assign({}, oldCropState, { isResizing });
    setCropState(imageCropState, callback);
  }

  /**
   * Return whether the current image format is supported for crop
   * @param {string} uri
   * @returns {boolean}
   */
  function isCropAllowed(uri) {
    if (!uri) {
      return false;
    }

    const supportedImageExtensions = [
      'png',
      'jpeg',
      'jpg',
      'jpe',
      'wix_ico_mp',
      'wix_mp',
    ];
    const rgx = `(${supportedImageExtensions.join('|')})$`;
    return uri.match(rgx);
  }

  function getCropAreaWithShapeAspectRatio() {
    const cropArea = getCropArea();
    const cropOriginArea = getCropOriginArea();
    const width = Math.min(
      cropArea.height * cropArea.svgAspect,
      cropOriginArea.width,
    );
    const height = width / cropArea.svgAspect;
    const x = Math.max(
      Math.min(
        cropArea.x + (cropArea.width - width) / 2,
        cropOriginArea.width - width,
      ),
      0,
    );

    return {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      cropArea: _.assign({}, cropArea, { width, height, x }),
    };
  }

  return {
    DEFAULT_MASK_ID,
    isCropAllowed,
    isCropMode,
    isEnteringCropMode,
    toggleCropMode,
    getCropArea,
    getCropOriginArea,
    getCropAreaFinalPosition,
    setCropOriginArea,
    setCropArea,
    setCropData,
    toggleFlip,
    updateCrop,
    previewCrop,
    cancelCrop,
    setResizing,
    getNewCropStateByPercentageFromOriginalImage,
    getCropAreaWithShapeAspectRatio,
    cacheCropArea,
    clearCachedCropArea,
  };
}
