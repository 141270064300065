import constants from '@/constants';

const {
  COLUMN,
  STRIP_COLUMNS_CONTAINER,
  CONTAINER,
  REPEATER,
  APP_WIDGET,
  SECTION,
} = constants.COMP_TYPES;

export const videoTypes = {
  VIDEO_BOX: 'videoBox',
  VIDEO_MASK: 'videoMask',
  TRANSPARENT_VIDEO: 'transparentVideo',
};

export const COMPS_WITH_TRANSPARENT_BACKGROUND = [CONTAINER, COLUMN];

export const COMPS_WITH_BG_SCROLL = [SECTION, STRIP_COLUMNS_CONTAINER, COLUMN];

export const BLACK_LISTED_ITERATABLE_COMPONENTS = [REPEATER, APP_WIDGET];

export const PARALLAX_FITTING_EFFECTS = [
  'BgParallax',
  'BgReveal',
  'BgZoomIn',
] as const;

export const MAX_TILE_WIDTH = 600;
export const MAX_TILE_HEIGHT = 600;

export const ANIMATION_KIT_DATA_NAMESPACE = {
  NAME: 'animationKit',
  TYPE: 'AnimationKit',
};
