import React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { withTranslation, type WithTranslation } from 'react-i18next';

import { frames } from '@/panels';
import {
  Button,
  Composites,
  PanelHeaderCenteredText,
  PanelHeaderIllustration,
  PromotionalList,
  RadioButtons,
  RichText,
  Tooltip,
} from '@wix/wix-base-ui';
import { hoc } from '@/util';
import { AUTOMATION_IDS, TRANSLATIONS } from './blogUpgradePanel.constants';
import { mapDispatchToProps, mapStateToProps } from './blogUpgradePanel.mapper';

import type { OwnProps } from './blogUpgradePanel.types';

const MODAL_NAME_BI = 'Upgrade to the new blog';
const MODAL_START_NAME_BI = 'Get Started Both Blogs';

interface BlogUpgradePanelState {
  isSelectingMigrationType: boolean;
  migrationType: MigrationTypes;
}

enum MigrationTypes {
  CONVERT = 'convert',
  DELETE = 'delete',
}

type BlogUpgradePanelProps = OwnProps &
  WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const { FocusPanelFrame } = frames;

class BlogUpgradePanelComponent extends React.Component<
  BlogUpgradePanelProps,
  BlogUpgradePanelState
> {
  static displayName = 'BlogUpgradePanel';
  static defaultProps = {
    onConfirm: () => {},
    onClose: () => {},
  };

  constructor(props: BlogUpgradePanelProps) {
    super(props);

    this.state = {
      isSelectingMigrationType: props.skipInfo,
      migrationType: MigrationTypes.CONVERT,
    };
  }

  componentDidMount() {
    const { logBi, origin, hasNewBlog } = this.props;

    logBi(405, {
      modal_name: MODAL_NAME_BI,
      origin,
      new_blog_valid: hasNewBlog,
    });
  }

  logModalAction = ({
    action,
    choice,
    modal_name = MODAL_NAME_BI,
  }: {
    action:
      | 'close'
      | 'learn_more'
      | 'upgrade_old_blog'
      | 'maybe_later'
      | 'get_started';
    choice?: string;
    modal_name?: string;
  }) => {
    const { logBi, origin, hasNewBlog } = this.props;

    logBi(406, {
      modal_name,
      action,
      choice,
      origin,
      new_blog_valid: hasNewBlog,
    });
  };

  getKeyboardShortcuts = () => ({
    esc: this.handleClose,
  });

  handleClose = () => {
    this.props.closePanel();
    this.props.onClose();
    this.logModalAction({ action: 'close' });
  };

  handleMaybeLater = () => {
    this.props.closePanel();
    this.props.onClose();
    this.logModalAction({ action: 'maybe_later' });
  };

  handleSimpleMigrationCTAClick = () => {
    const { closePanel, onConfirm, startMagicMigration } = this.props;
    startMagicMigration();
    closePanel();
    onConfirm();
    this.logModalAction({ action: 'upgrade_old_blog' });
  };

  handleTwoBlogsMigrationCTAClick = () => {
    const { migrationType } = this.state;
    const {
      closePanel,
      onConfirm,
      startMagicMigrationAndDeleteOldBlog,
      deleteAndStartMagicMigration,
    } = this.props;
    if (migrationType === MigrationTypes.DELETE) {
      startMagicMigrationAndDeleteOldBlog();
    } else {
      deleteAndStartMagicMigration();
    }
    closePanel();
    onConfirm();
    this.logModalAction({ action: 'upgrade_old_blog', choice: migrationType });
  };

  handleMigrationSelection = (migrationType: AnyFixMe) => {
    this.setState({ migrationType });
  };

  setSelectMigrationTypeView = () => {
    this.logModalAction({
      action: 'get_started',
      modal_name: MODAL_START_NAME_BI,
    });
    this.setState({
      isSelectingMigrationType: true,
    });
  };

  handleLearnMoreClick = () => {
    this.props.openHelpCenter();
    this.logModalAction({ action: 'learn_more' });
  };

  renderMigrationInfo() {
    const { t } = this.props;
    return (
      <Composites.PanelContent>
        <Composites.RichText className="blog-upgrade-panel-info">
          <RichText type="T06">
            {t(TRANSLATIONS.TWO_BLOGS_MIGRATION.BASIC_INFO)}
          </RichText>
        </Composites.RichText>
        <Composites.PromotionalList>
          <PromotionalList
            symbol={<Symbol name="check" />}
            shouldTranslate={false}
            items={
              TRANSLATIONS.TWO_BLOGS_MIGRATION.BULLET_LIST_ITEMS.map(
                t,
              ) as string[]
            }
          />
        </Composites.PromotionalList>
        <Composites.ActionSetVertical>
          <Button
            automationId={AUTOMATION_IDS.CTA}
            onClick={this.setSelectMigrationTypeView}
          >
            {t(TRANSLATIONS.TWO_BLOGS_MIGRATION.NEXT_STEP_CTA)}
          </Button>
          <Button
            className="btn-text"
            automationId={AUTOMATION_IDS.LEARN_MORE}
            onClick={this.handleLearnMoreClick}
          >
            {t(TRANSLATIONS.TWO_BLOGS_MIGRATION.LEARN_MORE)}
          </Button>
        </Composites.ActionSetVertical>
      </Composites.PanelContent>
    );
  }

  renderMigrationSelectionTooltip() {
    const { t } = this.props;

    return (
      <div>
        <div className="blog-upgrade-migration-tooltip-first-section">
          <RichText type="T07">
            <strong>
              {t(
                TRANSLATIONS.TWO_BLOGS_MIGRATION.MIGRATION_TOOLTIP.PART_1.LABEL,
              )}
            </strong>
          </RichText>
          <RichText type="T07">
            {t(TRANSLATIONS.TWO_BLOGS_MIGRATION.MIGRATION_TOOLTIP.PART_1.INFO)}
          </RichText>
        </div>
        <RichText type="T07">
          <strong>
            {t(TRANSLATIONS.TWO_BLOGS_MIGRATION.MIGRATION_TOOLTIP.PART_2.LABEL)}
          </strong>
        </RichText>
        <RichText type="T07">
          {t(TRANSLATIONS.TWO_BLOGS_MIGRATION.MIGRATION_TOOLTIP.PART_2.INFO)}
        </RichText>
      </div>
    );
  }
  renderMigrationSelection() {
    const { migrationType } = this.state;
    const { t } = this.props;

    return (
      <Composites.PanelContent>
        <Composites.RichText className="blog-upgrade-panel-info">
          <RichText type="T06">
            {t(TRANSLATIONS.TWO_BLOGS_MIGRATION.MIGRATION_INFO)}
          </RichText>
        </Composites.RichText>
        <Composites.RichText className="blog-upgrade-migration-label">
          <RichText type="T05">
            <span>{t(TRANSLATIONS.TWO_BLOGS_MIGRATION.MIGRATIONS_LABEL)}</span>
            <Tooltip content={this.renderMigrationSelectionTooltip()}>
              <Symbol name="infoIcon" className="blog-upgrade-info-icon" />
            </Tooltip>
          </RichText>
        </Composites.RichText>

        <Composites.RadioButtons className="blog-upgrade-migration-selection">
          <RadioButtons
            value={migrationType}
            onChange={this.handleMigrationSelection}
            labelTemplate={({ label }: AnyFixMe) => (
              <RichText type="T06">{t(label)}</RichText>
            )}
            options={[
              {
                value: MigrationTypes.CONVERT,
                label: TRANSLATIONS.TWO_BLOGS_MIGRATION.MIGRATION_CONVERT,
              },
              {
                value: MigrationTypes.DELETE,
                label: TRANSLATIONS.TWO_BLOGS_MIGRATION.MIGRATION_DELETE,
              },
            ]}
          />
        </Composites.RadioButtons>
        <Composites.ActionSetVertical className="blog-upgrade-migrations-cta">
          <Button
            automationId={AUTOMATION_IDS.CTA}
            onClick={this.handleTwoBlogsMigrationCTAClick}
          >
            {t(TRANSLATIONS.TWO_BLOGS_MIGRATION.MIGRATION_CTA)}
          </Button>
          <Button
            className="btn-text"
            automationId={AUTOMATION_IDS.LEARN_MORE}
            onClick={this.handleLearnMoreClick}
          >
            {t(TRANSLATIONS.TWO_BLOGS_MIGRATION.LEARN_MORE)}
          </Button>
        </Composites.ActionSetVertical>
      </Composites.PanelContent>
    );
  }

  renderTwoBlogsMigrationContent() {
    const { isSelectingMigrationType } = this.state;
    const { t } = this.props;

    return (
      <Composites.PopupLargeSize>
        <Composites.BigPanelHeaderWithIllustration>
          <PanelHeaderCenteredText
            // @ts-expect-error
            shouldTranslate={false}
            title={t(TRANSLATIONS.TWO_BLOGS_MIGRATION.TITLE)}
            onHelp={this.handleLearnMoreClick}
            onClose={this.handleClose}
          />
          <PanelHeaderIllustration>
            <Symbol name="blogUpgradeIllustration" />
          </PanelHeaderIllustration>
        </Composites.BigPanelHeaderWithIllustration>
        {isSelectingMigrationType
          ? this.renderMigrationSelection()
          : this.renderMigrationInfo()}
      </Composites.PopupLargeSize>
    );
  }

  renderSimpleMigrationContent() {
    const { t } = this.props;

    return (
      <Composites.PopupLargeSize>
        <Composites.BigPanelHeaderWithIllustration>
          <PanelHeaderCenteredText
            // @ts-expect-error
            shouldTranslate={false}
            title={t(TRANSLATIONS.SIMPLE_MIGRATION.TITLE)}
            onHelp={this.handleLearnMoreClick}
            onClose={this.handleClose}
          />
          <PanelHeaderIllustration>
            <Symbol name="blogUpgradeIllustration" />
          </PanelHeaderIllustration>
        </Composites.BigPanelHeaderWithIllustration>
        <Composites.PanelContent>
          <Composites.RichText className="blog-upgrade-panel-info">
            <RichText type="T06">
              {t(TRANSLATIONS.SIMPLE_MIGRATION.INFO)}
            </RichText>
          </Composites.RichText>
          <Composites.PromotionalList>
            <PromotionalList
              symbol={<Symbol name="check" />}
              shouldTranslate={false}
              items={
                TRANSLATIONS.SIMPLE_MIGRATION.BULLET_LIST_ITEMS.map(
                  t,
                ) as string[]
              }
            />
          </Composites.PromotionalList>
          <Composites.ActionSetVertical>
            <Button
              automationId={AUTOMATION_IDS.CTA}
              onClick={this.handleSimpleMigrationCTAClick}
            >
              {t(TRANSLATIONS.SIMPLE_MIGRATION.CTA)}
            </Button>
            <Button
              className="btn-text"
              automationId={AUTOMATION_IDS.LEARN_MORE}
              onClick={this.handleLearnMoreClick}
            >
              {t(TRANSLATIONS.SIMPLE_MIGRATION.LEARN_MORE)}
            </Button>
          </Composites.ActionSetVertical>
        </Composites.PanelContent>
      </Composites.PopupLargeSize>
    );
  }

  render() {
    const { hasNewBlog } = this.props;

    return (
      <FocusPanelFrame
        ref="panelFrame"
        shouldHideHeader={true}
        keyboardShortcuts={this.getKeyboardShortcuts()}
        panelName={this.props.panelName}
        className="popup-large blog-upgrade-panel"
      >
        {hasNewBlog
          ? this.renderTwoBlogsMigrationContent()
          : this.renderSimpleMigrationContent()}
      </FocusPanelFrame>
    );
  }
}

export const BlogUpgradePanel = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(hoc.renderWhenMutated(BlogUpgradePanelComponent)));
