:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._wrapper_179sa_7 {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  height: 100%;
  z-index: 1455;
  margin-left: 0;
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) margin; }
  ._wrapper_179sa_7._hidden_179sa_16 {
    margin-left: calc(-1 * var(--left-bar-width));
    transition-duration: 0.4s; }

._highlightOverlay_179sa_20 {
  position: absolute;
  height: 100%;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  z-index: 102; }

._highlightOverlayWithoutShadow_179sa_27 {
  background: transparent; }

._bar_179sa_30 {
  width: var(--left-bar-width);
  background-color: #fff;
  border-top: 1px solid #dfe5eb;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  flex: 0 0 var(--left-bar-width);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  animation: 0.8s :local(rollout);
  -webkit-clip-path: inset(0 -240px 0 0);
          clip-path: inset(0 -240px 0 0); }
  ._bar_179sa_30:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    -webkit-clip-path: inset(0 -20px 0 0);
            clip-path: inset(0 -20px 0 0);
    width: 100%;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08);
    pointer-events: none; }

@keyframes _rollout_179sa_1 {
  0% {
    transform: translateX(-40px); }
  100% {
    transform: translateX(0); } }

hr._divider_179sa_60 {
  flex: 0 0 1px;
  background-color: #dfe5eb; }

.left-bar-toggle {
  width: var(--left-bar-width);
  height: var(--left-bar-width);
  background-color: #fff;
  text-align: center;
  padding: 10px 0;
  box-sizing: border-box; }
