// @ts-nocheck
import _ from 'lodash';

const blockEvent = function (event) {
  event.preventDefault();
};

const TOP_CONTEXT_SHORTCUTS = {
  esc: blockEvent,
  'ctrl + t, command + t': blockEvent,
  'ctrl + y, command + y': blockEvent,
  'ctrl + d, command + d': blockEvent,
  'ctrl + s, command + s': blockEvent,
  'ctrl + ], command + ]': blockEvent,
  'ctrl + [, command + [': blockEvent,
  'ctrl + shift + ], command + shift + ]': blockEvent,
  'ctrl + shift + [, command + shift + [': blockEvent,
  backspace: blockEvent,
  'ctrl + left, command + left': blockEvent,
  'ctrl + right, command + right': blockEvent,
  'ctrl + shift + left, command + shift + left': blockEvent,
  'ctrl + shift + right, command + shift + right': blockEvent,
  'ctrl + m, command + m': blockEvent,
  'ctrl + shift + m, command + shift + m': blockEvent,
};

export default {
  init: _.once(_.noop),
  shortcuts: TOP_CONTEXT_SHORTCUTS,
};
