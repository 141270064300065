import React, { type FC } from 'react';
import { Text, Divider } from '@wix/wix-base-ui';
import { ChevronRight } from '@wix/wix-ui-icons-common/classic-editor';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { hoc } from '@/util';
import { translate } from '@/i18n';
import { LeftPanelFrame } from '@/leftBar';
import { mapStateToProps } from './aiPageToolsPanelMapper';
import styles from './aiPageToolsPanel.scss';

import type {
  AiPageToolsPanelStateProps,
  AiPageToolsPanelDispatchProps,
} from './aiPageToolsPanelMapper';
import type {
  AiPageToolsCategory,
  AiPageToolsItem,
} from './aiPageToolsConfiguration';

const PANEL_NAME = 'ai.panels.aiPageToolsPanel';

interface AiPageToolsPanelProps
  extends AiPageToolsPanelStateProps,
    AiPageToolsPanelDispatchProps {}

const AiPageToolsPanel: FC<AiPageToolsPanelProps> = ({ categories }) => (
  <LeftPanelFrame
    panelName={PANEL_NAME}
    panelIndex={3}
    panelClass={styles.panel}
    label={translate('ai_tools_panel_title')}
    headerTheme="ai"
    helpId="dc37236c-126a-4d85-bbec-1af436cdb617"
    automationId={PANEL_NAME}
  >
    <div className={styles.titleWrapper}>
      <Symbol className={styles.titleSymbol} name="aiIllustration" />
      <Text size="large" weight="bold" enableEllipsis={false}>
        {'ai_tools_main_header'}
      </Text>
    </div>
    <Divider long={true} />

    {categories.map((category: AiPageToolsCategory) =>
      category.items.some((item) => item.condition()) ? (
        <>
          <div className={styles.subtitleWrapper}>
            <Text size="medium" weight="bold">
              {category.title}
            </Text>
          </div>

          <ul className={styles.list}>
            {category.items.map((item: AiPageToolsItem) =>
              item.condition() ? (
                <li>
                  <button
                    className={styles.item}
                    onClick={item.onClick}
                    data-aid={item.automationId}
                  >
                    <span className={styles.itemSymbol}>
                      <Symbol name={item.symbolName} />
                    </span>
                    <span className={styles.itemBody}>
                      <Text size="small" weight="bold" skin="standard">
                        {item.title}
                      </Text>
                      <Text
                        size="small"
                        weight="thin"
                        skin="secondary"
                        enableEllipsis={false}
                      >
                        {item.description}
                      </Text>
                    </span>
                    <span className={styles.itemAction}>
                      <ChevronRight />
                    </span>
                  </button>
                </li>
              ) : null,
            )}
          </ul>
        </>
      ) : null,
    )}
  </LeftPanelFrame>
);

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  null,
)(AiPageToolsPanel);
