// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['add_section_label_slider'],
      sections: [addPanel.sections.getSections().developer.sliderSection],
    };
  },
};
