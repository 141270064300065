/**
 * Please ctrl/cmd + click on biEvents to see the schema :)
 * @type {Object.<String, biEvent>}
 */
const events = {
  TABS_CLICK: {
    evid: 204,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_id: 'string',
      panel: 'string',
    },
  },
  MANAGE_PANEL_BUTTON_CLICK: {
    evid: 205,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_id: 'string',
      button_name: 'string',
      app_name: 'string',
    },
  },
  LEARN_MORE_CLICK: {
    evid: 209,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_id: 'string',
      category: 'string',
    },
  },
  UPGRADE_POPUP_CLICK: {
    evid: 156,
    src: 16,
    endpoint: 'wixboost-users',
    fields: {
      biToken: 'string',
      businessId: 'string',
      referral_info: 'string',
    },
  },
  BOOKINGS_PANEL_OPENED: {
    evid: 181,
    src: 16,
    endpoint: 'wixboost-users',
    fields: {
      state: 'string',
    },
  },
  BOOKINGS_GET_STARTED: {
    evid: 182,
    src: 16,
    endpoint: 'wixboost-users',
    fields: {
      is_bookings_provisioned: 'boolean',
    },
  },
  BOOKINGS_INTERNAL_OPEN_BO: {
    evid: 299,
    src: 16,
    endpoint: 'wixboost-users',
    fields: {
      biToken: 'string',
      businessId: 'string',
      button_name: 'string',
    },
  },
  BOOKINGS_CREATE_NEW_SERVICE: {
    evid: 310,
    src: 16,
    endpoint: 'wixboost-users',
    fields: {
      biToken: 'string',
      businessId: 'string',
      is_new: 'boolean',
    },
  },
  BOOKINGS_TESTS_EXPOSURE: {
    evid: 333,
    src: 16,
    endpoint: 'wixboost-users',
    fields: {
      testName: 'string',
      triggerName: 'string',
      is_eligible: 'boolean',
    },
  },
} as const;

export { events as wixBookingsEvents };
