import { constants as mediaPanelInfraConstants } from '@/mediaManagerPanelInfra';
import { SECTIONS_TYPES } from '@/addPanelDataConsts';
import constants from '@/constants';

const { MEDIA_MANAGER_MEDIA_TYPES } = constants;

const { MEDIA_MANAGER_PANEL_SECTION_ID } = mediaPanelInfraConstants;

export const siteImagesSection = {
  type: SECTIONS_TYPES.DYNAMIC_MEDIA_SECTIONS_BOX,
  title: 'add_section_heading_site_images',
  subNavigationTitle: 'add_section_label_site_images',
  hide: false,
  automationId: 'addPanel_imageDynamic_siteImageSection',
  props: {
    biOriginBase: 'santa-editor',
    items: [
      {
        id: MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES,
        mediaType: MEDIA_MANAGER_MEDIA_TYPES.PICTURE,
        title: 'add_section_heading_site_images',
        automationId: 'addPanel_imageDynamic_siteImageSection',
      },
    ],
  },
};
