// @ts-nocheck
import _ from 'lodash';
import * as core from '@/core';

const utils = core.utils.blogTypeUtils;

export default {
  getSectionPagesDataList(editorAPI, pageDataList) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _.filter(
      pageDataList,
      _.partial(utils.isBlogSectionPage, editorAPI),
    );
  },

  getSpecialPagesDataList(editorAPI, pageDataList) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _.filter(pageDataList, utils.isBlogSpecialPage);
  },
};
