:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_zpaax_7 {
  position: relative; }

._frame_zpaax_10 {
  border: 3px solid #d6e6fe;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

._label_zpaax_19 {
  position: absolute;
  margin-left: 3px;
  bottom: 100%;
  left: 0;
  margin-bottom: -3px;
  border-radius: 2px 2px 2px 0;
  line-height: 30px;
  color: #116dff;
  background: linear-gradient(to bottom, #e3f3ff, #d6e6fe);
  height: 30px;
  padding: 0 12px;
  cursor: default; }
