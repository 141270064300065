// @ts-nocheck
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_disqus',
      isApplied: false,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
    },
  ],
  enabledActions: [ACTIONS.SETTINGS, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.DISQUS_COMMENTS,
      mobileHelpId: '555897ae-6066-4c8e-8736-e3a330a6db1c',
    },
  },
};
