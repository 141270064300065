// @ts-nocheck
import ReactDOM from 'reactDOM';
import createReactClass from 'create-react-class';
import * as core from '@/core';
import * as panelUtils from '@/panelUtils';
import * as higherOrderComponents from '@/higherOrderComponents';
import * as coreBi from '@/coreBi';
import React from 'react';
import { Divider } from '@wix/wix-base-ui';
import * as baseUI from '@/baseUI';

// eslint-disable-next-line react/prefer-es6-class
const BoxSlideShowSlideAdvancedStylePanel = createReactClass({
  displayName: 'boxSlideShowSlideAdvancedStylePanel',
  render() {
    return (
      <div className="advanced-style-panel only-advanced-styling">
        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_Shape_FillColor&Opacity"
          valueLink={this.props.linkStyleParam('bg', 'BG_COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <div className="fixed-button-wrapper">
          <baseUI.button
            label="Regular_Slideshow_Apply_Panel_Header_Title"
            onClick={this.openApplyAllPanel}
            className="button"
          />
        </div>
      </div>
    );
  },
  mixins: [core.mixins.editorAPIMixin, panelUtils.linkColorPickerMixin],
  panelBoundingRect: {},

  getColorPickerProps() {
    return {
      colorResolver: this.resolveColor,
      openColorPicker: this.openColorPicker,
    };
  },

  componentDidUpdate() {
    this.panelBoundingRect = ReactDOM.findDOMNode(this).getBoundingClientRect();
  },

  openApplyAllPanel() {
    const editorAPI = this.getEditorAPI();
    const panelProps = {
      top: this.panelBoundingRect.top + 72,
      left: this.panelBoundingRect.left - 12,
      width: 290,
      origin: 'box',
    };
    editorAPI.panelManager.openPanel(
      'rEditor.panels.slideShowBgApplyPanel',
      panelProps,
      true,
    );
    editorAPI.bi.event(
      coreBi.events.boxSlideShow.APPLY_ON_OTHER_SLIDES_CLICKED,
      editorAPI.components.getDefaultBiParams(
        editorAPI.selection.getSelectedComponents(),
      ),
    );
  },
});

export default higherOrderComponents.linkStyle(
  BoxSlideShowSlideAdvancedStylePanel,
);
