import _ from 'lodash';
import { translate } from '@/i18n';
import * as fontPickerStyleService from '../settings/uiLib/fontPickerStyleService';
import * as colorPickerStyleService from '../settings/uiLib/colorPickerStyleService';

const getOffset = (node: Element) => {
  // https://github.com/madrobby/zepto/blob/master/src/zepto.js#L716
  const { left, top, width, height } = node.getBoundingClientRect();

  return {
    left: left + window.pageXOffset,
    top: top + window.pageYOffset,
    width: Math.round(width),
    height: Math.round(height),
  };
};

const tpaSettingsClickPosRelativeToEditor = function (
  positionInSettingsIframe: AnyFixMe,
) {
  const iframeNode = document.querySelector('#tpaSettingsFrame');
  const iframePos = iframeNode ? getOffset(iframeNode) : null;

  if (iframePos) {
    return {
      left: (positionInSettingsIframe.left || 0) + (iframePos.left || 0),
      top: (positionInSettingsIframe.top || 0) + (iframePos.top || 0),
    };
  }

  return {
    left: positionInSettingsIframe.left,
    top: positionInSettingsIframe.top,
  };
};

const getCompStyle = function (
  editorAPI: AnyFixMe,
  compId: AnyFixMe,
  wixParam: AnyFixMe,
) {
  const compStyle = editorAPI.dsRead.tpa.style.get(compId);
  return compStyle?.style?.fonts?.[wixParam];
};

const openColorPicker = function (
  editorAPI: AnyFixMe,
  msg: AnyFixMe,
  callback: AnyFixMe,
) {
  const clickPos = tpaSettingsClickPosRelativeToEditor({
    top: msg.data.top,
    left: msg.data.left,
  });
  const { wixParam } = msg.data;
  const { compId } = msg;
  const color = msg.data.color || '#ffffff';
  let onChange;

  if (wixParam) {
    onChange = colorPickerStyleService.onStyleChange.bind(
      null,
      editorAPI,
      compId,
      wixParam,
      callback,
    );
  } else {
    onChange = _.partial(
      colorPickerStyleService.onStyleChangeWithoutWixParam,
      callback,
    );
  }

  editorAPI.openColorPicker(color, clickPos, {
    onChange,
    onClose: onPanelClose.bind(null, callback),
    allowPaletteEditing: true,
    enableHistory: true,
    previewOnHover: false,
    overridePosition: true,
    panelMode: 'solid',
  });
};

const openFontPicker = function (
  editorAPI: AnyFixMe,
  msg: AnyFixMe,
  callback: AnyFixMe,
) {
  const { wixParam } = msg.data;
  const { compId } = msg;
  const clickPos = tpaSettingsClickPosRelativeToEditor({
    top: msg.data.top,
    left: msg.data.left,
  });
  let onStyleChange, compStyle;

  if (wixParam) {
    compStyle = getCompStyle(editorAPI, compId, wixParam);
    compStyle.htmlTag = compStyle.htmlTag ?? msg?.data?.htmlTag;
    onStyleChange = fontPickerStyleService.onStyleChange.bind(
      null,
      editorAPI,
      compId,
      wixParam,
      callback,
    );
  } else {
    compStyle = _.isPlainObject(msg.data.value) ? msg.data.value : {};
    onStyleChange = _.partial(
      fontPickerStyleService.onStyleChangeWithoutWixParam,
      editorAPI,
      compStyle,
      callback,
    );
  }

  editorAPI.panelManager.openPanel(
    'tpaPanels.uiLib.uiLibTextParamDesignPanel',
    {
      title: msg.data.title || null,
      hideTheme: msg.data.hideTheme || false,
      hideFont: msg.data.hideFont || false,
      hideSize: msg.data.hideSize || false,
      hideStyle: msg.data.hideStyle || false,
      showHTMLTag: msg.data.showHTMLTag || false,
      top: clickPos.top,
      left: clickPos.left,
      fontMaxSize: msg.data.fontMaxSize,
      fontMinSize: msg.data.fontMinSize,
      compStyle,
      onStyleChange,
    },
    true,
  );
};

const openBackgroundPicker = function (
  editorAPI: AnyFixMe,
  msg: AnyFixMe,
  callback: AnyFixMe,
) {
  const clickPos = tpaSettingsClickPosRelativeToEditor({
    top: msg.data.top,
    left: msg.data.left,
  });
  const color = msg.data.color || '#ffffff';

  editorAPI.panelManager.openPanel(
    'panels.toolPanels.stylableEditor.fillPickerPanel',
    {
      title: translate('StylablePanel_FillPicker_Main_Header'),
      value: color,
      tab: color.includes('gradient') ? 1 : 0,
      onChange: callback,
      panelHost: {
        translate,
        getPalletes: () => editorAPI.theme.colors.getColorPresets(),
      },
      onClose: onPanelClose.bind(null, callback),
      top: clickPos.top,
      left: clickPos.left,
      border: true,
      pickerPropOverrides: { color: { minimalMode: true } },
    },
    true,
  );
};

const onPanelClose = function (callback: AnyFixMe) {
  callback({
    panelClose: true,
  });
};

export { openColorPicker, openFontPicker, openBackgroundPicker };
