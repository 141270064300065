"use strict"
const addPanelDataConsts = require('@/addPanelDataConsts')
const i18n = require('@/i18n')

module.exports = {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: "add_section_label_pagination_bar",
    subNavigationTitle: "add_section_label_pagination_bar",
    topTitle: "add_section_title_pagination_bar",
    presetTitle: "add_section_heading_pagination_bar",
    tooltipTitle: "add_section_label_pagination_bar_tooltip_title",
    automationId: "add-panel-section-paginationSection",
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
        labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        iconEnabledForComps: {}
    },
    props: {
        image: "addPanelData/sections/developer_en/paginationSection_en.png",
        imageHover: null,
        items: [
            {
                id: "User_Input_Pagination_1_Pages",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.Pagination",
                    layout: {
                        width: 324,
                        height: 40,
                        x: 0,
                        y: 4,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.Pagination",
                    data: {
                        type: "Pagination",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        totalPages: 100,
                        currentPage: 3,
                        nextText: i18n.translate("Pagination_Bar_Settings_NextButton_Preset_Text"),
                        previousText: i18n.translate("Pagination_Bar_Settings_PreviousButton_Preset_Text"),
                        firstText: i18n.translate("Pagination_Bar_Settings_FirstButton_Preset_Text"),
                        lastText: i18n.translate("Pagination_Bar_Settings_LastButton_Preset_Text")
                    },
                    props: {
                        type: "PaginationProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        showFirstLastNavButtons: false,
                        showFirstPage: false,
                        showLastPage: false,
                        showInputModeTotalPages: true,
                        paginationMode: "pages",
                        navigationType: "arrows",
                        paginationDirection: "ltr"
                    },
                    mobileHints: {
                        type: "MobileHints",
                        author: "duplicate",
                        originalCompId: "comp-jesq8dqu",
                        recommendedScale: 1,
                        recommendedWidth: 260,
                        recommendedHeight: 32
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                "alpha-navButtonBG": "0",
                                "alpha-navButtonDisabledBG": "0",
                                "alpha-navButtonDisabledBorderColor": "1",
                                "alpha-pageInputBG": "0",
                                "alpha-selectedPageBG": "0",
                                arrowsColor: "#42CCCC",
                                arrowsDisabledColor: "#E8E6E6",
                                bRadius: "10px 10px 10px 10px",
                                "boxShadowToggleOn-shadow": "false",
                                fnt: "normal normal normal 16px/1.4em georgia",
                                navButtonBG: "#858585",
                                navButtonBorderColor: "#414141",
                                navButtonBorderWidth: "0",
                                navButtonDisabledBG: "#E8E6E6",
                                navButtonDisabledBorderColor: "#C7C7C7",
                                navButtonDisabledBorderWidth: "0",
                                navButtonDisabledTextColor: "#E8E6E6",
                                navButtonTextColor: "#42CCCC",
                                navButtonTextSize: "15px",
                                pageInputBG: "#FFFFFF",
                                pageInputBorderColor: "#42CCCC",
                                pageInputBorderWidth: "1",
                                pageInputErrorBG: "#E8E6E6",
                                pageInputErrorBorderColor: "#FF4040",
                                pageInputErrorBorderWidth: "1",
                                pageInputFocusBG: "color_8",
                                pageInputFocusBorderColor: "#42CCCC",
                                pageInputFocusBorderWidth: "1",
                                pageInputTextColor: "#999997",
                                pageNumbersTextColor: "#727272",
                                selectedPageBG: "#414141",
                                selectedPageBorderColor: "#414141",
                                selectedPageBorderWidth: "0",
                                selectedPageTextColor: "#42CCCC",
                                shadow: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                totalPagesTextColor: "#858585"
                            },
                            propertiesSource: {
                                "alpha-navButtonBG": "value",
                                "alpha-navButtonDisabledBG": "value",
                                "alpha-navButtonDisabledBorderColor": "value",
                                "alpha-pageInputBG": "value",
                                "alpha-selectedPageBG": "value",
                                arrowsColor: "value",
                                arrowsDisabledColor: "value",
                                bRadius: "value",
                                fnt: "value",
                                navButtonBG: "value",
                                navButtonBorderColor: "value",
                                navButtonBorderWidth: "value",
                                navButtonDisabledBG: "value",
                                navButtonDisabledBorderColor: "value",
                                navButtonDisabledBorderWidth: "value",
                                navButtonDisabledTextColor: "value",
                                navButtonTextColor: "value",
                                pageInputBG: "value",
                                pageInputBorderColor: "value",
                                pageInputBorderWidth: "value",
                                pageInputErrorBG: "value",
                                pageInputErrorBorderColor: "value",
                                pageInputErrorBorderWidth: "value",
                                pageInputFocusBorderColor: "value",
                                pageInputFocusBorderWidth: "value",
                                pageInputTextColor: "value",
                                pageNumbersTextColor: "value",
                                selectedPageBG: "value",
                                selectedPageBorderColor: "value",
                                selectedPageBorderWidth: "value",
                                selectedPageTextColor: "value",
                                totalPagesTextColor: "value"
                            }
                        },
                        componentClassName: "wixui.Pagination",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.Pagination"
                    },
                    activeModes: {},
                    id: "comp-jfc9vesn"
                },
                preset: {
                    rect: {width: 324, height: 49, x: 0, y: 0},
                    tags: null
                }
            },
            {
                id: "User_Input_Pagination_2_Pages",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.Pagination",
                    layout: {
                        width: 324,
                        height: 40,
                        x: 0,
                        y: 59,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.Pagination",
                    data: {
                        type: "Pagination",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        totalPages: 100,
                        currentPage: 3,
                        nextText: i18n.translate("Pagination_Bar_Settings_NextButton_Preset_Text"),
                        previousText: i18n.translate("Pagination_Bar_Settings_PreviousButton_Preset_Text"),
                        firstText: i18n.translate("Pagination_Bar_Settings_FirstButton_Preset_Text"),
                        lastText: i18n.translate("Pagination_Bar_Settings_LastButton_Preset_Text")
                    },
                    props: {
                        type: "PaginationProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        showFirstLastNavButtons: false,
                        showFirstPage: false,
                        showLastPage: false,
                        showInputModeTotalPages: true,
                        paginationMode: "pages",
                        navigationType: "arrows",
                        paginationDirection: "ltr"
                    },
                    mobileHints: {
                        type: "MobileHints",
                        author: "duplicate",
                        originalCompId: "comp-jesq8uh4",
                        recommendedScale: 1,
                        recommendedWidth: 260,
                        recommendedHeight: 32
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                "alpha-navButtonBG": "0",
                                "alpha-navButtonDisabledBG": "0",
                                "alpha-navButtonDisabledBorderColor": "1",
                                arrowsColor: "#4BDB7B",
                                arrowsDisabledColor: "#C7C7C7",
                                bRadius: "5px 5px 5px 5px",
                                "boxShadowToggleOn-shadow": "false",
                                fnt:
                                    "normal normal normal 16px/1.4em bree-w01-thin-oblique",
                                navButtonBG: "#E5FAD1",
                                navButtonBorderColor: "#4BDB7B",
                                navButtonBorderWidth: "0",
                                navButtonDisabledBG: "#E8E6E6",
                                navButtonDisabledBorderColor: "#E8E6E6",
                                navButtonDisabledBorderWidth: "0",
                                navButtonDisabledTextColor: "#E8E6E6",
                                navButtonTextColor: "#4BDB7B",
                                navButtonTextSize: "15px",
                                pageInputBG: "#4BDB7B",
                                pageInputBorderColor: "color_27",
                                pageInputBorderWidth: "0",
                                pageInputErrorBG: "#4BDB7B",
                                pageInputErrorBorderColor: "#FF4040",
                                pageInputErrorBorderWidth: "2",
                                pageInputFocusBG: "#4BDB7B",
                                pageInputFocusBorderColor: "#4BDB7B",
                                pageInputFocusBorderWidth: "0",
                                pageInputTextColor: "#FFFFFF",
                                pageNumbersTextColor: "#4BDB7B",
                                selectedPageBG: "#4BDB7B",
                                selectedPageBorderColor: "#CAE5AF",
                                selectedPageBorderWidth: "0",
                                selectedPageTextColor: "#FFFFFF",
                                shadow: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                totalPagesTextColor: "#858585"
                            },
                            propertiesSource: {
                                "alpha-navButtonBG": "value",
                                "alpha-navButtonDisabledBG": "value",
                                "alpha-navButtonDisabledBorderColor": "value",
                                arrowsColor: "value",
                                arrowsDisabledColor: "value",
                                bRadius: "value",
                                fnt: "value",
                                navButtonBG: "value",
                                navButtonBorderColor: "value",
                                navButtonBorderWidth: "value",
                                navButtonDisabledBG: "value",
                                navButtonDisabledBorderColor: "value",
                                navButtonDisabledBorderWidth: "value",
                                navButtonDisabledTextColor: "value",
                                navButtonTextColor: "value",
                                pageInputBG: "value",
                                pageInputErrorBG: "value",
                                pageInputErrorBorderColor: "value",
                                pageInputErrorBorderWidth: "value",
                                pageInputFocusBG: "value",
                                pageInputFocusBorderColor: "value",
                                pageInputFocusBorderWidth: "value",
                                pageInputTextColor: "value",
                                pageNumbersTextColor: "value",
                                selectedPageBG: "value",
                                selectedPageBorderColor: "value",
                                selectedPageBorderWidth: "value",
                                selectedPageTextColor: "value",
                                totalPagesTextColor: "value"
                            }
                        },
                        componentClassName: "wixui.Pagination",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.Pagination"
                    },
                    activeModes: {},
                    id: "comp-jfc9vesz"
                },
                preset: {
                    rect: {width: 324, height: 59, x: 0, y: 50},
                    tags: null
                }
            },
            {
                id: "User_Input_Pagination_3_Pages",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.Pagination",
                    layout: {
                        width: 324,
                        height: 40,
                        x: 0,
                        y: 119,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.Pagination",
                    data: {
                        type: "Pagination",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        totalPages: 100,
                        currentPage: 3,
                        nextText: i18n.translate("Pagination_Bar_Settings_NextButton_Preset_Text"),
                        previousText: i18n.translate("Pagination_Bar_Settings_PreviousButton_Preset_Text"),
                        firstText: i18n.translate("Pagination_Bar_Settings_FirstButton_Preset_Text"),
                        lastText: i18n.translate("Pagination_Bar_Settings_LastButton_Preset_Text")
                    },
                    props: {
                        type: "PaginationProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        showFirstLastNavButtons: false,
                        showFirstPage: false,
                        showLastPage: false,
                        showInputModeTotalPages: true,
                        paginationMode: "pages",
                        navigationType: "arrows",
                        paginationDirection: "ltr"
                    },
                    mobileHints: {
                        type: "MobileHints",
                        author: "duplicate",
                        originalCompId: "comp-jesq97ya",
                        recommendedScale: 1,
                        recommendedWidth: 260,
                        recommendedHeight: 32
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                "alpha-navButtonBG": "0",
                                "alpha-navButtonDisabledBG": "0",
                                "alpha-pageInputBG": "0",
                                "alpha-pageInputFocusBG": "0",
                                arrowsColor: "#8C84FA",
                                arrowsDisabledColor: "#C7C7C7",
                                bRadius: "30px 30px 30px 30px",
                                "boxShadowToggleOn-shadow": "false",
                                fnt:
                                    "normal normal normal 16px/1.4em futura-lt-w01-book",
                                navButtonBG: "#8C84FA",
                                navButtonBorderColor: "#8C84FA",
                                navButtonBorderWidth: "0",
                                navButtonDisabledBG: "#C4AEDD",
                                navButtonDisabledBorderColor: "#8C84FA",
                                navButtonDisabledBorderWidth: "0",
                                navButtonDisabledTextColor: "#E8E6E6",
                                navButtonTextColor: "#8C84FA",
                                navButtonTextSize: "15px",
                                pageInputBG: "#8C84FA",
                                pageInputBorderColor: "color_27",
                                pageInputBorderWidth: "1",
                                pageInputErrorBG: "#E8E6E6",
                                pageInputErrorBorderColor: "#FF4040",
                                pageInputErrorBorderWidth: "2",
                                pageInputFocusBG: "color_8",
                                pageInputFocusBorderColor: "#8C84FA",
                                pageInputFocusBorderWidth: "2",
                                pageInputTextColor: "#858585",
                                pageNumbersTextColor: "#858585",
                                selectedPageBG: "#8C84FA",
                                selectedPageBorderColor: "color_26",
                                selectedPageBorderWidth: "0",
                                selectedPageTextColor: "#FFFFFF",
                                shadow: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                totalPagesTextColor: "#858585"
                            },
                            propertiesSource: {
                                "alpha-navButtonBG": "value",
                                "alpha-navButtonDisabledBG": "value",
                                "alpha-pageInputBG": "value",
                                "alpha-pageInputFocusBG": "value",
                                arrowsColor: "value",
                                arrowsDisabledColor: "value",
                                bRadius: "value",
                                fnt: "value",
                                navButtonBG: "value",
                                navButtonBorderColor: "value",
                                navButtonBorderWidth: "value",
                                navButtonDisabledBG: "value",
                                navButtonDisabledBorderColor: "value",
                                navButtonDisabledBorderWidth: "value",
                                navButtonDisabledTextColor: "value",
                                navButtonTextColor: "value",
                                pageInputBG: "value",
                                pageInputBorderWidth: "value",
                                pageInputErrorBG: "value",
                                pageInputErrorBorderColor: "value",
                                pageInputErrorBorderWidth: "value",
                                pageInputFocusBorderColor: "value",
                                pageInputFocusBorderWidth: "value",
                                pageInputTextColor: "value",
                                pageNumbersTextColor: "value",
                                selectedPageBG: "value",
                                selectedPageBorderWidth: "value",
                                selectedPageTextColor: "value",
                                totalPagesTextColor: "value"
                            }
                        },
                        componentClassName: "wixui.Pagination",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.Pagination"
                    },
                    activeModes: {},
                    id: "comp-jfc9vet6"
                },
                preset: {
                    rect: {width: 324, height: 65, x: 0, y: 109},
                    tags: null
                }
            },
            {
                id: "User_Input_Pagination_4_Pages",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.Pagination",
                    layout: {
                        width: 324,
                        height: 40,
                        x: 0,
                        y: 183,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.Pagination",
                    data: {
                        type: "Pagination",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        totalPages: 100,
                        currentPage: 3,
                        nextText: i18n.translate("Pagination_Bar_Settings_NextButton_Preset_Text"),
                        previousText: i18n.translate("Pagination_Bar_Settings_PreviousButton_Preset_Text"),
                        firstText: i18n.translate("Pagination_Bar_Settings_FirstButton_Preset_Text"),
                        lastText: i18n.translate("Pagination_Bar_Settings_LastButton_Preset_Text")
                    },
                    props: {
                        type: "PaginationProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        showFirstLastNavButtons: false,
                        showFirstPage: false,
                        showLastPage: false,
                        showInputModeTotalPages: true,
                        paginationMode: "pages",
                        navigationType: "arrows",
                        paginationDirection: "ltr"
                    },
                    mobileHints: {
                        type: "MobileHints",
                        author: "duplicate",
                        originalCompId: "comp-jesqai3i",
                        recommendedScale: 1,
                        recommendedWidth: 260,
                        recommendedHeight: 32
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                "alpha-navButtonBG": "0",
                                "alpha-navButtonDisabledBG": "0",
                                "alpha-pageInputBG": "1",
                                "alpha-pageInputFocusBG": "0",
                                "alpha-selectedPageBG": "0",
                                "alpha-selectedPageBorderColor": "1",
                                arrowsColor: "#919191",
                                arrowsDisabledColor: "#E8E6E6",
                                bRadius: "30px 30px 30px 30px",
                                "boxShadowToggleOn-shadow": "false",
                                fnt:
                                    "normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496",
                                navButtonBG: "#323232",
                                navButtonBorderColor: "#737373",
                                navButtonBorderWidth: "0",
                                navButtonDisabledBG: "#C7C7C7",
                                navButtonDisabledBorderColor: "#E8E6E6",
                                navButtonDisabledBorderWidth: "0",
                                navButtonDisabledTextColor: "#E8E6E6",
                                navButtonTextColor: "#858585",
                                navButtonTextSize: "15px",
                                pageInputBG: "#FFFFFF",
                                pageInputBorderColor: "#858585",
                                pageInputBorderWidth: "1",
                                pageInputErrorBG: "#E8E6E6",
                                pageInputErrorBorderColor: "#FF4040",
                                pageInputErrorBorderWidth: "2",
                                pageInputFocusBG: "color_8",
                                pageInputFocusBorderColor: "#858585",
                                pageInputFocusBorderWidth: "1",
                                pageInputTextColor: "#000000",
                                pageNumbersTextColor: "#858585",
                                selectedPageBG: "#919191",
                                selectedPageBorderColor: "#919191",
                                selectedPageBorderWidth: "1",
                                selectedPageTextColor: "#858585",
                                shadow: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                totalPagesTextColor: "#000000"
                            },
                            propertiesSource: {
                                "alpha-navButtonBG": "value",
                                "alpha-navButtonDisabledBG": "value",
                                "alpha-pageInputBG": "value",
                                "alpha-pageInputFocusBG": "value",
                                "alpha-selectedPageBG": "value",
                                "alpha-selectedPageBorderColor": "value",
                                arrowsColor: "value",
                                arrowsDisabledColor: "value",
                                bRadius: "value",
                                fnt: "value",
                                navButtonBG: "value",
                                navButtonBorderColor: "value",
                                navButtonBorderWidth: "value",
                                navButtonDisabledBG: "value",
                                navButtonDisabledBorderColor: "value",
                                navButtonDisabledBorderWidth: "value",
                                navButtonDisabledTextColor: "value",
                                navButtonTextColor: "value",
                                pageInputBG: "value",
                                pageInputBorderColor: "value",
                                pageInputBorderWidth: "value",
                                pageInputErrorBG: "value",
                                pageInputErrorBorderColor: "value",
                                pageInputErrorBorderWidth: "value",
                                pageInputFocusBorderColor: "value",
                                pageInputFocusBorderWidth: "value",
                                pageInputTextColor: "value",
                                pageNumbersTextColor: "value",
                                selectedPageBG: "value",
                                selectedPageBorderColor: "value",
                                selectedPageBorderWidth: "value",
                                selectedPageTextColor: "value",
                                totalPagesTextColor: "value"
                            }
                        },
                        componentClassName: "wixui.Pagination",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.Pagination"
                    },
                    activeModes: {},
                    id: "comp-jfc9vetg"
                },
                preset: {
                    rect: {width: 324, height: 65, x: 0, y: 174},
                    tags: null
                }
            },
            {
                id: "User_Input_Pagination_5_Input",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.Pagination",
                    layout: {
                        width: 324,
                        height: 40,
                        x: 0,
                        y: 250,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.Pagination",
                    data: {
                        type: "Pagination",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        totalPages: 1000,
                        currentPage: 3,
                        nextText: i18n.translate("Pagination_Bar_Settings_NextButton_Preset_Text"),
                        previousText: i18n.translate("Pagination_Bar_Settings_PreviousButton_Preset_Text"),
                        firstText: i18n.translate("Pagination_Bar_Settings_FirstButton_Preset_Text"),
                        lastText: i18n.translate("Pagination_Bar_Settings_LastButton_Preset_Text")
                    },
                    props: {
                        type: "PaginationProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        showFirstLastNavButtons: false,
                        showFirstPage: false,
                        showLastPage: false,
                        showInputModeTotalPages: true,
                        paginationMode: "input",
                        navigationType: "arrows",
                        paginationDirection: "ltr"
                    },
                    mobileHints: {
                        type: "MobileHints",
                        author: "duplicate",
                        originalCompId: "comp-jesqbj8k",
                        recommendedScale: 1,
                        recommendedWidth: 260,
                        recommendedHeight: 30
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                "alpha-navButtonBG": "0",
                                "alpha-navButtonBorderColor": "1",
                                "alpha-navButtonDisabledBG": "0",
                                "alpha-pageInputBG": "1",
                                "alpha-pageInputErrorBG": "0",
                                "alpha-pageInputFocusBG": "0",
                                "alpha-selectedPageBG": "1",
                                arrowsColor: "#3899EC",
                                arrowsDisabledColor: "#999997",
                                bRadius: "5px 5px 5px 5px",
                                "boxShadowToggleOn-shadow": "false",
                                fnt:
                                    "normal normal normal 16px/1.4em helvetica-w01-light",
                                navButtonBG: "#000000",
                                navButtonBorderColor: "#3899EC",
                                navButtonBorderWidth: "0",
                                navButtonDisabledBG: "#E8E6E6",
                                navButtonDisabledBorderColor: "#858585",
                                navButtonDisabledBorderWidth: "0",
                                navButtonDisabledTextColor: "#E8E6E6",
                                navButtonTextColor: "#3D9BE9",
                                navButtonTextSize: "15px",
                                pageInputBG: "#FFFFFF",
                                pageInputBorderColor: "#3899EC",
                                pageInputBorderWidth: "1",
                                pageInputErrorBG: "#E8E6E6",
                                pageInputErrorBorderColor: "#FF4040",
                                pageInputErrorBorderWidth: "1",
                                pageInputFocusBG: "color_8",
                                pageInputFocusBorderColor: "#3D9BE9",
                                pageInputFocusBorderWidth: "1",
                                pageInputTextColor: "#888888",
                                pageNumbersTextColor: "#000000",
                                selectedPageBG: "#FFFFFF",
                                selectedPageBorderColor: "#3D9BE9",
                                selectedPageBorderWidth: "0",
                                selectedPageTextColor: "#3D9BE9",
                                shadow: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                totalPagesTextColor: "#000000"
                            },
                            propertiesSource: {
                                "alpha-navButtonBG": "value",
                                "alpha-navButtonBorderColor": "value",
                                "alpha-navButtonDisabledBG": "value",
                                "alpha-pageInputBG": "value",
                                "alpha-pageInputErrorBG": "value",
                                "alpha-pageInputFocusBG": "value",
                                "alpha-selectedPageBG": "value",
                                arrowsColor: "value",
                                arrowsDisabledColor: "value",
                                bRadius: "value",
                                fnt: "value",
                                navButtonBG: "value",
                                navButtonBorderColor: "value",
                                navButtonBorderWidth: "value",
                                navButtonDisabledBG: "value",
                                navButtonDisabledBorderColor: "value",
                                navButtonDisabledBorderWidth: "value",
                                navButtonDisabledTextColor: "value",
                                navButtonTextColor: "value",
                                pageInputBG: "value",
                                pageInputBorderColor: "value",
                                pageInputBorderWidth: "value",
                                pageInputErrorBG: "value",
                                pageInputErrorBorderColor: "value",
                                pageInputErrorBorderWidth: "value",
                                pageInputFocusBorderColor: "value",
                                pageInputFocusBorderWidth: "value",
                                pageInputTextColor: "value",
                                pageNumbersTextColor: "value",
                                selectedPageBG: "value",
                                selectedPageBorderColor: "value",
                                selectedPageBorderWidth: "value",
                                selectedPageTextColor: "value",
                                totalPagesTextColor: "value"
                            }
                        },
                        componentClassName: "wixui.Pagination",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.Pagination"
                    },
                    activeModes: {},
                    id: "comp-jfc9vetp"
                },
                preset: {
                    rect: {width: 324, height: 71, x: 0, y: 239},
                    tags: null
                }
            }
        ],
        compTypes: ["wixui.Pagination"]
    },
    help: {
        hide: false,
        text: "add_section_label_pagination_bar_tooltip_description"
    }
}
