:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._left-container_13r24_7 {
  width: 288px;
  border-right: 1px solid #dfe5eb;
  display: flex;
  flex-direction: column; }

._right-container_13r24_13 {
  flex: 1;
  overflow-y: auto; }

._private-apps-container_13r24_17 {
  height: 100%;
  display: flex; }

._loader-container_13r24_21 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
