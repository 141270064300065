import PropTypes from 'prop-types';

const defaultProps = {
  siteVarsDriver: PropTypes.object.isRequired,
  panelHost: PropTypes.object,
  title: PropTypes.string,
  isNewPaletteOpen: PropTypes.bool,
  visibleThemeColors: PropTypes.arrayOf(PropTypes.string),
};

export default {
  FillPickerProps: {
    ...defaultProps,
    value: PropTypes.string,
    tab: PropTypes.number,
    border: PropTypes.bool,
    onChange: PropTypes.func,
    pickerPropOverrides: PropTypes.object,
  },
  ColorPickerProps: {
    ...defaultProps,
    currentColor: PropTypes.string,
    onHover: PropTypes.func,
    onChange: PropTypes.func,
    onReset: PropTypes.func,
  },
  BoxShadowPickerProps: {
    ...defaultProps,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  },
  StateListDialogProps: {
    ...defaultProps,
    closeStateListDialog: PropTypes.func,
    fixedStates: PropTypes.array,
    usedStates: PropTypes.array,
    applyLabel: PropTypes.string,
    selectorConfiguration: PropTypes.any,
    selector: PropTypes.string,
    onSubmit: PropTypes.func,
  },
};
