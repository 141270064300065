// @ts-nocheck
import wixFormsConstants from '../../contact/wixForms/constants';
import * as wixFormsUtils from '../../contact/wixForms/utils';
import { array } from '@/util';

const addForm = (structure) => {
  const form = wixFormsUtils.getFormWidget({
    layout: {
      width: 619,
      height: 347,
      x: -71.5,
      y: 117,
      scale: 1,
      rotationInDegrees: 0,
      anchors: [
        {
          distance: 15,
          originalValue: 464,
          type: 'BOTTOM_TOP',
          locked: true,
          targetComponent: 'comp-iok1jao7',
        },
      ],
      fixedPosition: false,
    },
  });

  // add form to
  // components: [
  //     component_0,
  //     component_1: {
  //         components: [
  //             form,
  //             component_10
  //         ]
  //     },
  // ]
  return {
    ...structure,
    components: array.immutableSplice(structure.components, 1, 1, {
      ...structure.components[1],
      components: array.immutableSplice(
        structure.components[1].components,
        0,
        0,
        form,
      ),
    }),
  };
};

export default (onDrop, onClick) => (item) => {
  onDrop = onDrop(wixFormsConstants.FormPreset.STRIP02);
  onClick = onClick(wixFormsConstants.FormPreset.STRIP02);

  return {
    ...item,
    onDrop,
    onClick,
    structure: addForm(item.structure),
  };
};
