import type { StylableConfigGetter } from '@wix/editor-component-types';
import { stylableButtonStylableConfigOverrides } from './stylableButton';
import { stylableHorizontalMenuStylableConfigOverride } from './stylableHorizontalMenu';

export const stylablePanelOverrides: Record<string, StylableConfigGetter> = {
  StylableButton: stylableButtonStylableConfigOverrides,
  StylableHorizontalMenu: stylableHorizontalMenuStylableConfigOverride,
  /**
   * This is a new approach which will be supported later after @wix/wix-ui-santa cleanup
   */
  'wixui.StylableButton': stylableButtonStylableConfigOverrides,
  'wixui.StylableHorizontalMenu': stylableHorizontalMenuStylableConfigOverride,
};
