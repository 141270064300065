import coreUtilsLib from 'coreUtilsLib';

const { blogAppPartNames } = coreUtilsLib;

const blogAppPartsHelpIdsMap: Record<
  keyof typeof blogAppPartNames,
  /* GFPPData*/ AnyFixMe
> = {};

blogAppPartsHelpIdsMap[blogAppPartNames.SINGLE_POST] = {
  DESKTOP: {
    help: 'bc3c1b91-e9f4-441e-b89e-bb7801fe0b2c',
  },
};

blogAppPartsHelpIdsMap[blogAppPartNames.FEED] = {
  DESKTOP: {
    help: 'e686b5e3-c726-40cd-aec4-b8fdbae19b2d',
    settings: '6419ebe4-34b1-4db4-8e78-68da440c9679',
    design: '0414e8eb-2cf5-42d5-a106-697849d4422f',
    layout: 'b9ee6e7a-9be8-4bfd-8745-1c4b8a96ed00',
  },
  MOBILE: {
    help: '37fdef43-c752-4e36-9b2e-dfef211d7cce',
    design: '0646ccf2-c643-428b-86e9-df0c7c64dd65',
    layout: 'c27f014e-c8b4-43dc-b3f7-f8ce2944a0c8',
  },
};

blogAppPartsHelpIdsMap[blogAppPartNames.POSTS_LIST] = {
  DESKTOP: {
    help: '2a368dbf-7f6d-4937-a2e2-e41b1496707d',
    settings: 'f818d560-557f-4967-b14f-52143f783b99',
    design: '0414e8eb-2cf5-42d5-a106-697849d4422f',
    layout: '8b7574ce-0851-4407-add1-96f026ad0606',
  },
  MOBILE: {
    help: 'c0ff3e27-3395-466c-8858-ac8b2f41af35',
    design: '0646ccf2-c643-428b-86e9-df0c7c64dd65',
    layout: 'c27f014e-c8b4-43dc-b3f7-f8ce2944a0c8',
  },
};

blogAppPartsHelpIdsMap[blogAppPartNames.FEATURED_POSTS] = {
  DESKTOP: {
    help: 'ebfd2fb1-c241-4330-8d6d-a4b213005147',
    settings: '1a34d9a8-b1ce-4b39-a94c-d218f90680d4',
    design: '0414e8eb-2cf5-42d5-a106-697849d4422f',
    layout: 'e952f357-c333-4e6e-b021-6df7533de4b9',
  },
  MOBILE: {
    help: '6e7865f1-4715-4dff-b6f3-2c1ccdc81094',
    design: '0646ccf2-c643-428b-86e9-df0c7c64dd65',
    layout: 'c27f014e-c8b4-43dc-b3f7-f8ce2944a0c8',
  },
};

blogAppPartsHelpIdsMap[blogAppPartNames.ARCHIVE] = {
  DESKTOP: {
    help: 'c862ebd1-2cb2-4176-9ff6-dd2d9ffb94bb',
    settings: '1cb855b5-c45d-4dd6-8015-5b2ce0d71a9d',
    design: '0414e8eb-2cf5-42d5-a106-697849d4422f',
    layout: 'a87f35c2-297f-4054-a671-7858396ac86f',
  },
  MOBILE: {
    help: '7200ef4f-7e82-43a9-8100-ee181e392f29',
    design: '0646ccf2-c643-428b-86e9-df0c7c64dd65',
  },
};

blogAppPartsHelpIdsMap[blogAppPartNames.TAG_CLOUD] = {
  DESKTOP: {
    help: 'fb85357d-7c22-45d9-9459-0ac1f11ae1c8',
    settings: '77c35aee-2c6b-4545-9646-1a4c70e170c6',
    layout: 'b4ba737e-dad6-4d37-b86b-447a9f114433',
    design: '2b33976c-53a1-43d3-8a7a-682f2c8a8ed6',
  },
  MOBILE: {
    help: '03dce31a-1c55-4c9d-8cb2-d3cd5e482471',
  },
};

blogAppPartsHelpIdsMap[blogAppPartNames.POSTS_GALLERY] = {
  DESKTOP: {
    help: '8e8113e0-c10e-4b65-8e67-97cc889c7b06',
    settings: '1a25cb08-0372-4406-90b7-6cd54e8d5185',
    design: '0414e8eb-2cf5-42d5-a106-697849d4422f',
    layout: '47981efe-e502-4ca7-a558-af62883cf404',
  },
  MOBILE: {
    help: '5cd91e9c-ad02-4cbb-b554-0dad975ab5e9',
    design: '0646ccf2-c643-428b-86e9-df0c7c64dd65',
  },
};

blogAppPartsHelpIdsMap[blogAppPartNames.CUSTOM_FEED] = {
  DESKTOP: {
    help: '68ca884d-9390-4266-a277-2848d5eb6ea9',
    settings: 'd82d0135-8b91-4802-9231-a30744ca0631',
    design: '0414e8eb-2cf5-42d5-a106-697849d4422f',
    layout: '7db44583-dede-404d-91a9-47dfaf4fbf6c',
  },
  MOBILE: {
    help: '621c9618-fe78-4b30-9948-a92b68a3b320',
    design: '0646ccf2-c643-428b-86e9-df0c7c64dd65',
    layout: 'c27f014e-c8b4-43dc-b3f7-f8ce2944a0c8',
  },
};

blogAppPartsHelpIdsMap[blogAppPartNames.TICKER] = {
  DESKTOP: {
    help: '1e85876d-df6e-4709-8f94-e628bc4b7fc0',
    settings: '448cd1e4-cb67-4d58-ac86-c74e49765c14',
    design: '0414e8eb-2cf5-42d5-a106-697849d4422f',
    layout: '9a6d044c-99e5-4a12-800b-9ce02c1dfeb1',
  },
  MOBILE: {
    help: 'd86467cb-6963-4faa-b615-c4aa0ae48f47',
    design: '0646ccf2-c643-428b-86e9-df0c7c64dd65',
  },
};

blogAppPartsHelpIdsMap[blogAppPartNames.CATEGORIES] = {
  DESKTOP: {
    design: '0414e8eb-2cf5-42d5-a106-697849d4422f',
    help: 'a160d02b-3252-43fc-bed0-97651cbeb2cf',
    layout: '613bb13a-b956-4dce-83e8-a3226a3f1ad9',
  },
  MOBILE: {
    design: 'ab5aa4e4-d740-4ae1-b3a8-1fbe0db40c0e',
    help: '5200618c-6745-46da-aa75-dd497bb287c9',
  },
};

blogAppPartsHelpIdsMap[blogAppPartNames.RELATED_POSTS] = {
  DESKTOP: {
    settings: '339f38a0-1620-4c50-a745-1548d9f86140',
    design: 'b84c0754-8cd0-4f1b-988b-81005eff4517',
    help: '7f72438e-d522-4726-bfbc-a363e7e2af16',
    layout: '6abf3a40-a9ae-433e-b51b-1d37fb5d83d9',
  },
  MOBILE: {
    design: 'e83e66d1-ec41-4953-82c9-9d7f50fcbd7c',
    help: 'b1765cea-411b-4bc0-90cc-3c6aae45560d',
  },
};

export default blogAppPartsHelpIdsMap;
