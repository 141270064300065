// @ts-nocheck
import _ from 'lodash';

const collapsedMenuItems = {};

function setMenuItemCollapsed(menuItemId, isCollapsed) {
  collapsedMenuItems[menuItemId] = Boolean(isCollapsed);
}

function isMenuItemCollapsed(menuItemId, defaultCollapse) {
  if (collapsedMenuItems.hasOwnProperty(menuItemId)) {
    return collapsedMenuItems[menuItemId];
  }

  return defaultCollapse || false;
}

function reflectStateToMenuTree(menuTree) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(menuTree, function (item) {
    item.isCollapsed = isMenuItemCollapsed(item.id);
    if (item.items.length > 0) {
      reflectStateToMenuTree(item.items);
    } else if (item.isCollapsed) {
      item.isCollapsed = false;
      setMenuItemCollapsed(item.id, false);
    }
  });
}

function reflectStateToMenuItem(item, defaultCollapse) {
  item.isCollapsed = isMenuItemCollapsed(item.id, defaultCollapse);
  const numOfChildren = item?.items?.length ?? 0;
  if (numOfChildren <= 0 && item.isCollapsed) {
    item.isCollapsed = false;
    setMenuItemCollapsed(item.id, false);
  }

  return item;
}

export { setMenuItemCollapsed, reflectStateToMenuTree, reflectStateToMenuItem };
