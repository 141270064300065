import {
  mapStateToProps as mapStateToMenuItemsProps,
  mapDispatchToProps as mapDispatchToMenuItemsProps,
} from '../../menuBar/menuBarListItems/menuStructureMapper';

import type { MapDispatchToPropsFunction } from 'types/redux';
import type {
  WithMenuItemsOwnProps,
  WithMenuItemsDispatchProps,
} from './withMenuItems';

export const mapStateToProps = mapStateToMenuItemsProps;

export const mapDispatchToProps: MapDispatchToPropsFunction<
  WithMenuItemsDispatchProps,
  WithMenuItemsOwnProps
> = (dispatch, ownProps) => ({
  ...mapDispatchToMenuItemsProps(dispatch, ownProps),
  pickOwnProps: () => ownProps,
});
