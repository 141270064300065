// @ts-nocheck
// Deprecated component
// Please use DropDown instead
import React from 'react';
import PropTypes from 'prop-types';

const DropPanelAnchor = (props) =>
  React.createElement(
    'div',
    {
      style: { position: 'relative', height: '100%' },
    },
    props.children,
  );

DropPanelAnchor.displayName = 'DropPanelAnchor';
DropPanelAnchor.propTypes = {
  children: PropTypes.any,
};

export default DropPanelAnchor;
