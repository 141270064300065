:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_oc15w_7 {
  display: flex;
  width: 100%;
  height: 24px;
  justify-content: center;
  --bg-color: transparent;
  background: var(--bg-color);
  cursor: pointer; }
  ._container_oc15w_7 ._icon_oc15w_15 {
    fill: var(--elements-color); }
  ._container_oc15w_7._disabled_oc15w_17 {
    cursor: default; }
    ._container_oc15w_7._disabled_oc15w_17 ._icon_oc15w_15 {
      fill: #bcbcbc; }

._legacy_oc15w_22 {
  --elements-color: #000624; }
  ._legacy_oc15w_22:hover {
    --elements-color: #116dff; }

._primary_oc15w_27 {
  --elements-color: #ffffff; }
  ._primary_oc15w_27:hover:not(._disabled_oc15w_17) {
    --bg-color: #4b31dc; }

._secondary_oc15w_32 {
  --elements-color: #6f56f9; }
  ._secondary_oc15w_32:hover:not(._disabled_oc15w_17) {
    --bg-color: #d9d2ff;
    --elements-color: #4b31dc; }

._legacySoap_oc15w_38 {
  --elements-color: #000624; }
  ._legacySoap_oc15w_38:hover {
    --elements-color: #116dff; }

._primarySoap_oc15w_43 {
  --elements-color: #ffffff; }
  ._primarySoap_oc15w_43:hover:not(._disabled_oc15w_17) {
    --bg-color: #dd560e; }

._secondarySoap_oc15w_48 {
  --elements-color: #fe620f; }
  ._secondarySoap_oc15w_48:hover:not(._disabled_oc15w_17) {
    --bg-color: #fee8dc; }

._tooltipCustomLink_oc15w_53 {
  padding-top: 5px; }
