const addPanelDataConsts = require("@/addPanelDataConsts");
const util = require("@/util");
const languageCode = util.languages.getLanguageCode();
const buttonImageUrl = `addPanelData/sections/stylableButtonSections/stylableButtonSection_${languageCode}/stylableButtonSection_${languageCode}.v2.png`;
const buttonHoverImageUrl = `addPanelData/sections/stylableButtonSections/stylableButtonSection_${languageCode}/hover/stylableButtonSection_hover_${languageCode}.v2.png`;
const items = require("./items");

module.exports = {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: "add_section_label_stylableButton",
  subNavigationHide: false,
  subNavigationTitle: "add_section_label_stylableButton",
  presetTitle: "add_section_heading_stylableButton",
  automationId: "add-panel-section-stylableSection",
  tooltipTitle: "add_section_label_stylableButton_tooltip_title",
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
    scaleProportion: addPanelDataConsts.DEFAULT_SCALE_FOR_PRESET,
    iconEnabledForComps: {},
  },
  props: {
    image: buttonImageUrl,
    imageHover: buttonHoverImageUrl,
    compTypes: ["wixui.StylableButton"],
    items,
  },
  help: {
    hide: false,
    text: "add_section_info_custom",
  },
};
