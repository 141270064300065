import * as stateManagement from '@/stateManagement';

import type { EditorAPI } from '@/editorAPI';

type MenuId = string;
type ItemId = string;

interface Menu {
  items: {
    [itemId in ItemId]: {
      isCollapsed?: boolean;
    };
  };
}

type IMenuPreferences = {
  [menuId in MenuId]: Menu;
};

const updatePreferences = (
  editorAPI: EditorAPI,
  newPreferences: IMenuPreferences = {},
) =>
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSessionUserPreferences<IMenuPreferences>(
      'menus',
      newPreferences,
    ),
  );

export const getMenusPreferences = (editorAPI: EditorAPI): IMenuPreferences => {
  const menuPreferences =
    stateManagement.userPreferences.selectors.getSessionUserPreferences<IMenuPreferences>(
      'menus',
    )(editorAPI.store.getState());

  return menuPreferences || {};
};

export const setItemCollapsed = (
  editorAPI: EditorAPI,
  {
    menuId,
    itemId,
    isCollapsed,
  }: {
    menuId: MenuId;
    itemId: ItemId;
    isCollapsed: boolean;
  },
) => {
  const menuPreferences: IMenuPreferences = getMenusPreferences(editorAPI);
  const menu: Menu = menuPreferences[menuId] || ({} as Menu);
  const items = menu?.items || {};

  const newMenuPreferences = {
    ...menuPreferences,
    [menuId]: {
      ...menu,
      items: {
        ...items,
        [itemId]: {
          isCollapsed,
        },
      },
    },
  };

  updatePreferences(editorAPI, newMenuPreferences);
};

export const getCollapsedItems = (
  editorAPI: EditorAPI,
  menuId: MenuId,
): ItemId[] => {
  const preferences = getMenusPreferences(editorAPI);
  const items = preferences?.[menuId]?.items || {};

  return Object.entries(items)
    .filter(([, { isCollapsed }]) => isCollapsed)
    .map(([id]) => id);
};
