import { biLogger } from '@/util';
import constants from '@/constants';
import { MediaType } from '@/mediaServices';
import { attemptToAddComponent } from '../../../addPanel/addPanelUtils';
import { addMenuImageSectionButtonClick } from '@wix/bi-logger-editor/v2';
import { getImageCompDef } from '../../../sections/listSection/imageSectionUtils';

import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';
import type { PhotoStudioOptions } from '@wix/add-panel-common';
import type { ActionSectionItem } from '@wix/add-panel-component';
import type {
  Dispatch,
  StateMapperArgs,
  MapDispatchToProps,
} from 'types/redux';

const { PHOTO } = constants.COMP_TYPES;

export interface ImageMapperProps {
  openPhotoStudio: (
    item: ActionSectionItem,
    options: PhotoStudioOptions,
  ) => void;
}

// TODO: take real type from media-studio when exported
// https://github.com/wix-private/media-studio/blob/d430bfc5c988f53677cd3b62824eb44215e7066a/media-image-studio/src/types/media-manager/media-manager.d.ts#L50
interface MediaStudioFileInfo {
  width: number;
  height: number;
  id: string;
  mediaType: MediaType;
  name: string;
}

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: StateMapperArgs,
): EditorAPI => editorAPI;

export const imageMapper: MapDispatchToProps<ImageMapperProps, any> = (
  dispatch: Dispatch,
) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    openPhotoStudio: (
      { biItemName }: ActionSectionItem,
      { mode }: PhotoStudioOptions,
    ) => {
      biLogger.report(
        addMenuImageSectionButtonClick({
          section: biItemName,
        }),
      );
      editorAPI.mediaServices.openMediaStudio(
        {
          mode,
          initiator: 'media-manager-g6',
        },
        async ({ id, height, width, name }: MediaStudioFileInfo) => {
          const compDef = getImageCompDef(
            PHOTO,
            {
              id,
              source: '',
              name,
              mediaType: MediaType.Picture,
              height,
              width,
              title: name,
              fileName: id,
            },
            editorAPI.isMobileEditor(),
          );
          const compRef = await attemptToAddComponent(
            editorAPI,
            compDef,
            'image',
            '',
            [],
            biItemName,
            'addPanel',
          );
          editorAPI.components.hooks.componentAddedToStage.fire({
            compRef,
            type: 'click',
            origin: 'addPanel',
          });
        },
      );
    },
  };
};
