'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_listsandgrids_layouts",
    "subNavigationTitle": "add_section_label_listsandgrids_layouts",
    "presetTitle": "add_section_label_listsandgrids_layouts",
    "tooltipTitle": "add_section_label_listsandgrids_layouts",
    "showSectionHeader": true,
    "automationId": "add-panel-section-repeaterSection",
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/oneItemRepeatersSection_en/oneItemRepeatersSection_en.jpg",
        "imageHover": null,
        "items": [{
            "id": "Repeater_Repeaters_1",
            "structure": {
                "type": "RepeaterContainer",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto",
                        "layout": {
                            "width": 306,
                            "height": 226,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "",
                                "uri": "af9daef5b5684a679caf003614294ccd.jpg",
                                "description": "",
                                "width": 6000,
                                "height": 4000,
                                "alt": "",
                                "crop": {"x": 584, "y": 0, "width": 5416, "height": 4000}
                            },
                            "overrides": {
                                "item1": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "",
                                    "uri": "af9daef5b5684a679caf003614294ccd.jpg",
                                    "description": "",
                                    "width": 6000,
                                    "height": 4000,
                                    "alt": "",
                                    "crop": {"x": 584, "y": 0, "width": 5416, "height": 4000}
                                }
                            }
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 297,
                                "height": 231,
                                "x": 12,
                                "y": 10,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Container",
                        "components": [{
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 159,
                                "height": 27,
                                "x": 17,
                                "y": 8,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h4 class=\"font_2\" style=\"font-size:20px\"><span style=\"font-size:20px\"><span style=\"letter-spacing:0.08em\"><span style=\"font-weight:bold\"><span style=\"color:#577083\"><span style=\"font-family:open sans,sans-serif\">HYDRA</span></span></span></span></span></h4>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<h4 class=\"font_2\" style=\"font-size:20px\"><span style=\"font-size:20px\"><span style=\"letter-spacing:0.08em\"><span style=\"font-weight:bold\"><span style=\"color:#577083\"><span style=\"font-family:open sans,sans-serif\">HYDRA</span></span></span></span></span></h4>",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    }
                                }
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                        "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                        "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                        "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                        "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                        "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                        "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                        "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                        "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                        "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                        "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                    },
                                    "propertiesSource": {
                                        "f0": "value",
                                        "f1": "value",
                                        "f10": "value",
                                        "f2": "value",
                                        "f3": "value",
                                        "f4": "value",
                                        "f5": "value",
                                        "f6": "value",
                                        "f7": "value",
                                        "f8": "value",
                                        "f9": "value"
                                    }
                                },
                                "componentClassName": "",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "type": "Component",
                                "conversionData": {
                                    "isCustomPreset": true,
                                    "mobileProps": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    }
                                },
                                "layout": {
                                    "width": 149,
                                    "height": 23,
                                    "x": 7,
                                    "y": 7,
                                    "scale": 0.8727272727272727,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }],
                        "layout": {
                            "width": 180,
                            "height": 40,
                            "x": -1,
                            "y": 196,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#858585",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {"bg": "value", "brd": "value", "brw": "value", "rd": "value", "shd": "value"}
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "componentType": "mobile.core.components.Container",
                            "type": "Container",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 164,
                                "height": 55,
                                "x": 12,
                                "y": 209,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 191,
                            "height": 17,
                            "x": 16,
                            "y": 238,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_7\" style=\"font-size: 14px;\"><span style=\"font-size:14px;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span style=\"color:#36B5CD\">01/19 - 01/23</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_7\" style=\"font-size: 14px;\"><span style=\"font-size:14px;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span style=\"color:#36B5CD\">01/19 - 01/23</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 149,
                                "height": 17,
                                "x": 22,
                                "y": 244,
                                "scale": 0.9333333333333333,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 268,
                            "height": 68,
                            "x": 19,
                            "y": 272,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"line-height:1.5em\"><span style=\"color:#577083\">Click here to add your own content, or connect to data from your collections.</span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"line-height:1.5em\"><span style=\"color:#577083\">Click here to add your own content, or connect to data from your collections.</span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": false,
                            "minHeight": 68
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 280,
                                "height": 44,
                                "x": 20,
                                "y": 280,
                                "scale": 0.9974999999999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 115,
                            "height": 22,
                            "x": 19,
                            "y": 354,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_7\" style=\"font-size:15px; line-height:1.5em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#577083\">From:</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_7\" style=\"font-size:15px; line-height:1.5em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#577083\">From:</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 98,
                                "height": 22,
                                "x": 20,
                                "y": 351,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 136,
                            "height": 39,
                            "x": 19,
                            "y": 374,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_7\" style=\"font-size: 26px; line-height: 1.5em;\"><span style=\"font-size:26px;\"><span style=\"color:#36B5CD\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\">$550</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_7\" style=\"font-size: 26px; line-height: 1.5em;\"><span style=\"font-size:26px;\"><span style=\"color:#36B5CD\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\">$550</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 147,
                                "height": 35,
                                "x": 20,
                                "y": 372,
                                "scale": 0.8846153846153846,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 120,
                            "height": 40,
                            "x": 167,
                            "y": 367,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Book Now"
                            },
                            "overrides": {
                                "item1": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "Book Now"
                                }
                            }
                        },
                        "props": {"type": "ButtonProperties", "metaData": {"schemaVersion": "1.0"}, "align": "center", "margin": 0},
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "0",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#36B5CD",
                                    "bgh": "#5BC9DE",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#545454",
                                    "brdh": "#F9F9F9",
                                    "brw": "0",
                                    "fnt": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                    "txt": "#FFFFFF",
                                    "txth": "#FFFFFF"
                                },
                                "propertiesSource": {
                                    "alpha-brdh": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 120,
                                "height": 40,
                                "x": 185,
                                "y": 362,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }],
                    "layout": {
                        "width": 306,
                        "height": 427,
                        "x": 336,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.MediaContainer",
                    "design": {
                        "type": "RepeatedData",
                        "original": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFFFFF",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0,
                                "showOverlayForMediaType": "WixVideo",
                                "mediaTransforms": {"scale": 1}
                            },
                            "cssStyle": {
                                "cssBorder": {
                                    "width": {
                                        "top": {"value": 0, "unit": "px"},
                                        "right": {"value": 0, "unit": "px"},
                                        "bottom": {"value": 0, "unit": "px"},
                                        "left": {"value": 0, "unit": "px"}
                                    },
                                    "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                    "color": {
                                        "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                    }
                                },
                                "cssBorderRadius": {
                                    "topLeft": {"value": 0, "unit": "px"},
                                    "topRight": {"value": 0, "unit": "px"},
                                    "bottomLeft": {"value": 0, "unit": "px"},
                                    "bottomRight": {"value": 0, "unit": "px"}
                                },
                                "cssBoxShadow": [{
                                    "inset": false,
                                    "offsetX": {"value": 0, "unit": "px"},
                                    "offsetY": {"value": 4, "unit": "px"},
                                    "blurRadius": {"value": 10, "unit": "px"},
                                    "spreadRadius": {"value": 0, "unit": "px"},
                                    "color": {"red": 0, "green": 0, "blue": 0, "alpha": 0.1}
                                }]
                            },
                            "themeMappings": {},
                            "charas": "design-j9o8atta",
                            "dataChangeBehaviors": []
                        },
                        "overrides": {
                            "item1": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFFFFF",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0,
                                    "showOverlayForMediaType": "WixVideo",
                                    "mediaTransforms": {"scale": 1}
                                },
                                "cssStyle": {
                                    "cssBorder": {
                                        "width": {
                                            "top": {"value": 0, "unit": "px"},
                                            "right": {"value": 0, "unit": "px"},
                                            "bottom": {"value": 0, "unit": "px"},
                                            "left": {"value": 0, "unit": "px"}
                                        },
                                        "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                        "color": {
                                            "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                        }
                                    },
                                    "cssBorderRadius": {
                                        "topLeft": {"value": 0, "unit": "px"},
                                        "topRight": {"value": 0, "unit": "px"},
                                        "bottomLeft": {"value": 0, "unit": "px"},
                                        "bottomRight": {"value": 0, "unit": "px"}
                                    },
                                    "cssBoxShadow": [{
                                        "inset": false,
                                        "offsetX": {"value": 0, "unit": "px"},
                                        "offsetY": {"value": 4, "unit": "px"},
                                        "blurRadius": {"value": 10, "unit": "px"},
                                        "spreadRadius": {"value": 0, "unit": "px"},
                                        "color": {"red": 0, "green": 0, "blue": 0, "alpha": 0.1}
                                    }]
                                },
                                "themeMappings": {},
                                "charas": "design-j9o8atta",
                                "dataChangeBehaviors": []
                            }
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.MediaContainer",
                        "type": "Container",
                        "conversionData": {"isCustomPreset": true},
                        "layout": {
                            "width": 320,
                            "height": 436,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    }
                }],
                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                "layout": {
                    "width": 316,
                    "height": 427,
                    "x": 0,
                    "y": 86,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Repeater",
                "data": {
                    "type": "Repeater",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": ["item1"]
                },
                "props": {
                    "type": "CardsLayoutProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "alignment": "left",
                    "gap": {"vertical": 30, "horizontal": 30}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-brd": "0",
                            "bg": "61,155,233,1",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#324158",
                            "brw": "0px",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "bg": "value",
                            "brd": "value",
                            "brw": "value",
                            "rd": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "mobile.core.components.Container",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                },
                "mobileStructure": {
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "type": "RepeaterContainer",
                    "conversionData": {
                        "isCustomPreset": true,
                        "mobileProps": {
                            "type": "CardsLayoutProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "alignment": "left",
                            "gap": {"vertical": 20, "horizontal": 20}
                        }
                    },
                    "layout": {
                        "width": 320,
                        "height": 1348,
                        "x": 0,
                        "y": 43,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    }
                },
                "activeModes": {},
                "id": "comp-j9o86nhz"
            },
            "preset": {"rect": {"width": 324, "height": 184, "x": 0, "y": 0}}
        }, {
            "id": "Repeater_Repeaters_2",
            "structure": {
                "type": "RepeaterContainer",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 200,
                            "height": 101,
                            "x": 121,
                            "y": 33,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"line-height:1.8em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">First list item. Add your own content here or connect to data from your collection.</span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"line-height:1.8em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">First list item. Add your own content here or connect to data from your collection.</span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": false,
                            "minHeight": 101
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 184,
                                "height": 81,
                                "x": 83,
                                "y": 44,
                                "scale": 0.9974999999999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 49,
                            "height": 47,
                            "x": 35,
                            "y": 31,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_2\" style=\"font-size: 30px;\"><span style=\"font-size:30px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">1.</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_2\" style=\"font-size: 30px;\"><span style=\"font-size:30px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">1.</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": false,
                            "minHeight": 47
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 48,
                                "height": 41,
                                "x": 26,
                                "y": 28,
                                "scale": 1.125,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }],
                    "layout": {
                        "width": 369,
                        "height": 158,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.MediaContainer",
                    "design": {
                        "type": "RepeatedData",
                        "original": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#35C68B",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0,
                                "showOverlayForMediaType": "WixVideo"
                            },
                            "cssStyle": {
                                "cssBorder": {
                                    "width": {
                                        "top": {"value": 0, "unit": "px"},
                                        "right": {"value": 0, "unit": "px"},
                                        "bottom": {"value": 0, "unit": "px"},
                                        "left": {"value": 0, "unit": "px"}
                                    },
                                    "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                    "color": {
                                        "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                    }
                                },
                                "cssBorderRadius": {
                                    "topLeft": {"value": 0, "unit": "px"},
                                    "topRight": {"value": 0, "unit": "px"},
                                    "bottomLeft": {"value": 0, "unit": "px"},
                                    "bottomRight": {"value": 0, "unit": "px"}
                                }
                            },
                            "themeMappings": {},
                            "charas": "design-j9ps462s",
                            "dataChangeBehaviors": []
                        },
                        "overrides": {
                            "item1": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#35C68B",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0,
                                    "showOverlayForMediaType": "WixVideo"
                                },
                                "cssStyle": {
                                    "cssBorder": {
                                        "width": {
                                            "top": {"value": 0, "unit": "px"},
                                            "right": {"value": 0, "unit": "px"},
                                            "bottom": {"value": 0, "unit": "px"},
                                            "left": {"value": 0, "unit": "px"}
                                        },
                                        "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                        "color": {
                                            "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                        }
                                    },
                                    "cssBorderRadius": {
                                        "topLeft": {"value": 0, "unit": "px"},
                                        "topRight": {"value": 0, "unit": "px"},
                                        "bottomLeft": {"value": 0, "unit": "px"},
                                        "bottomRight": {"value": 0, "unit": "px"}
                                    }
                                },
                                "themeMappings": {},
                                "charas": "design-j9ps462s",
                                "dataChangeBehaviors": []
                            }
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.MediaContainer",
                        "type": "Container",
                        "conversionData": {"isCustomPreset": true},
                        "layout": {
                            "width": 320,
                            "height": 158,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    }
                }],
                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                "layout": {
                    "width": 379,
                    "height": 158,
                    "x": 6,
                    "y": 562,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Repeater",
                "data": {
                    "type": "Repeater",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": ["item1"]
                },
                "props": {
                    "type": "CardsLayoutProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "alignment": "left",
                    "gap": {"vertical": 0, "horizontal": 0}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-brd": "0",
                            "bg": "61,155,233,1",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#324158",
                            "brw": "0px",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "bg": "value",
                            "brd": "value",
                            "brw": "value",
                            "rd": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "mobile.core.components.Container",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                },
                "mobileStructure": {
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "type": "RepeaterContainer",
                    "conversionData": {"isCustomPreset": true},
                    "layout": {
                        "width": 320,
                        "height": 1106,
                        "x": 0,
                        "y": 1434,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    }
                },
                "activeModes": {},
                "id": "comp-j9pe6wlf"
            },
            "preset": {"rect": {"width": 141, "height": 301, "x": 0, "y": 184}}
        }, {
            "id": "Repeater_Repeaters_3",
            "structure": {
                "type": "RepeaterContainer",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 319,
                            "height": 349,
                            "x": 20,
                            "y": 217,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "",
                                "uri": "0ad29cb9d94741debe3c0c516a8ee222.jpg",
                                "description": "",
                                "width": 1342,
                                "height": 894,
                                "alt": "",
                                "crop": {"x": 183, "y": 0, "width": 766, "height": 894}
                            },
                            "overrides": {
                                "item1": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "",
                                    "uri": "0ad29cb9d94741debe3c0c516a8ee222.jpg",
                                    "description": "",
                                    "width": 1342,
                                    "height": 894,
                                    "alt": "",
                                    "crop": {"x": 183, "y": 0, "width": 766, "height": 894}
                                }
                            }
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 280,
                                "height": 306,
                                "x": 20,
                                "y": 195,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 311,
                            "height": 96,
                            "x": 20,
                            "y": 87,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_7\" style=\"font-size:16px; line-height:1.5em\"><span style=\"color:#333333\"><span style=\"font-size:16px\">This is a great place to tell people more about your business, and the services you offer. Want to make this content your own? It&rsquo;s easy.</span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_7\" style=\"font-size:16px; line-height:1.5em\"><span style=\"color:#333333\"><span style=\"font-size:16px\">This is a great place to tell people more about your business, and the services you offer. Want to make this content your own? It&rsquo;s easy.</span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 280,
                                "height": 96,
                                "x": 20,
                                "y": 76,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 306,
                            "height": 44,
                            "x": 20,
                            "y": 20,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h4 class=\"font_4\" style=\"font-size:30px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.08em\"><span style=\"font-family:anton,sans-serif\"><span style=\"font-size:30px\">WORK SPACES</span></span></span></span></h4>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h4 class=\"font_4\" style=\"font-size:30px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.08em\"><span style=\"font-family:anton,sans-serif\"><span style=\"font-size:30px\">WORK SPACES</span></span></span></span></h4>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 280,
                                "height": 34,
                                "x": 20,
                                "y": 23,
                                "scale": 0.8518518518518519,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 319,
                            "height": 5,
                            "x": 20,
                            "y": 70,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {"type": "FiveGridLineProperties", "metaData": {"schemaVersion": "1.0"}, "fullScreenModeOn": false},
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#F2BF5E", "lnw": "3"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 280,
                                "height": 5,
                                "x": 20,
                                "y": 64,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }],
                    "layout": {
                        "width": 361,
                        "height": 589,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.MediaContainer",
                    "design": {
                        "type": "RepeatedData",
                        "original": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFFFFF",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0,
                                "showOverlayForMediaType": "WixVideo",
                                "mediaTransforms": {"scale": 1}
                            },
                            "cssStyle": {
                                "cssBorder": {
                                    "width": {
                                        "top": {"value": 0, "unit": "px"},
                                        "right": {"value": 0, "unit": "px"},
                                        "bottom": {"value": 0, "unit": "px"},
                                        "left": {"value": 0, "unit": "px"}
                                    },
                                    "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                    "color": {
                                        "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                    }
                                },
                                "cssBorderRadius": {
                                    "topLeft": {"value": 0, "unit": "px"},
                                    "topRight": {"value": 0, "unit": "px"},
                                    "bottomLeft": {"value": 0, "unit": "px"},
                                    "bottomRight": {"value": 0, "unit": "px"}
                                },
                                "cssBoxShadow": [{
                                    "inset": false,
                                    "offsetX": {"value": 0, "unit": "px"},
                                    "offsetY": {"value": 0, "unit": "px"},
                                    "blurRadius": {"value": 13, "unit": "px"},
                                    "spreadRadius": {"value": 0, "unit": "px"},
                                    "color": {"red": 0, "green": 0, "blue": 0, "alpha": 0.1}
                                }]
                            },
                            "themeMappings": {},
                            "charas": "design-j9psyr4o",
                            "dataChangeBehaviors": []
                        },
                        "overrides": {
                            "item1": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFFFFF",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0,
                                    "showOverlayForMediaType": "WixVideo",
                                    "mediaTransforms": {"scale": 1}
                                },
                                "cssStyle": {
                                    "cssBorder": {
                                        "width": {
                                            "top": {"value": 0, "unit": "px"},
                                            "right": {"value": 0, "unit": "px"},
                                            "bottom": {"value": 0, "unit": "px"},
                                            "left": {"value": 0, "unit": "px"}
                                        },
                                        "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                        "color": {
                                            "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                        }
                                    },
                                    "cssBorderRadius": {
                                        "topLeft": {"value": 0, "unit": "px"},
                                        "topRight": {"value": 0, "unit": "px"},
                                        "bottomLeft": {"value": 0, "unit": "px"},
                                        "bottomRight": {"value": 0, "unit": "px"}
                                    },
                                    "cssBoxShadow": [{
                                        "inset": false,
                                        "offsetX": {"value": 0, "unit": "px"},
                                        "offsetY": {"value": 0, "unit": "px"},
                                        "blurRadius": {"value": 13, "unit": "px"},
                                        "spreadRadius": {"value": 0, "unit": "px"},
                                        "color": {"red": 0, "green": 0, "blue": 0, "alpha": 0.1}
                                    }]
                                },
                                "themeMappings": {},
                                "charas": "design-j9psyr4o",
                                "dataChangeBehaviors": []
                            }
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.MediaContainer",
                        "type": "Container",
                        "conversionData": {"isCustomPreset": true},
                        "layout": {
                            "width": 320,
                            "height": 511,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    }
                }],
                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                "layout": {
                    "width": 373,
                    "height": 589,
                    "x": 2,
                    "y": 1735,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Repeater",
                "data": {
                    "type": "Repeater",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": ["item1"]
                },
                "props": {
                    "type": "CardsLayoutProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "alignment": "left",
                    "gap": {"vertical": 25, "horizontal": 25}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-brd": "0",
                            "bg": "61,155,233,1",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#324158",
                            "brw": "0px",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "bg": "value",
                            "brd": "value",
                            "brw": "value",
                            "rd": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "mobile.core.components.Container",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                },
                "mobileStructure": {
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "type": "RepeaterContainer",
                    "conversionData": {"isCustomPreset": true},
                    "layout": {
                        "width": 320,
                        "height": 2119,
                        "x": 0,
                        "y": 2597,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    }
                },
                "activeModes": {},
                "id": "comp-j9peaki1"
            },
            "preset": {"rect": {"width": 183, "height": 301, "x": 141, "y": 184}}
        }, {
            "id": "Repeater_Repeaters_4",
            "structure": {
                "type": "RepeaterContainer",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 231,
                            "height": 35,
                            "x": 6,
                            "y": 48,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h4 class=\"font_2\" style=\"font-size: 26px; text-align: center;\"><span style=\"font-size:26px\"><span style=\"letter-spacing:0.08em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">Entertainment</span></span></span></span></h4>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h4 class=\"font_2\" style=\"font-size: 26px; text-align: center;\"><span style=\"font-size:26px\"><span style=\"letter-spacing:0.08em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">Entertainment</span></span></span></span></h4>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {"type": "WRichTextProperties", "metaData": {"schemaVersion": "1.0"}, "brightness": 1, "packed": true},
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 280,
                                "height": 29,
                                "x": 20,
                                "y": 40,
                                "scale": 0.8461538461538461,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 201,
                            "height": 63,
                            "x": 20,
                            "y": 113,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_2\" style=\"font-size: 14px; line-height: 1.5em; text-align: center;\"><span style=\"font-size:14px;\"><span style=\"font-family:georgia,palatino,book antiqua,palatino linotype,serif\"><span style=\"letter-spacing:0em\"><span style=\"color:#FFFFFF\">Make this yours. Add images, text and links, or connect data from your collection.</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_2\" style=\"font-size: 14px; line-height: 1.5em; text-align: center;\"><span style=\"font-size:14px;\"><span style=\"font-family:georgia,palatino,book antiqua,palatino linotype,serif\"><span style=\"letter-spacing:0em\"><span style=\"color:#FFFFFF\">Make this yours. Add images, text and links, or connect data from your collection.</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {"type": "WRichTextProperties", "metaData": {"schemaVersion": "1.0"}, "brightness": 1, "packed": true},
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 233,
                                "height": 66,
                                "x": 43,
                                "y": 101,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin",
                        "layout": {
                            "width": 48,
                            "height": 9,
                            "x": 96,
                            "y": 210,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "ImageButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "defaultImage": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "Arrow.png",
                                    "uri": "5bfb6f_c2ae26a3c5004bca9ea2b860a535f4ab~mv2.png",
                                    "description": "",
                                    "width": 36,
                                    "height": 10,
                                    "alt": ""
                                },
                                "hoverImage": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "Arrow_Hover.png",
                                    "uri": "5bfb6f_ff73ca63250240b2a3c975834afa9356~mv2.png",
                                    "description": "",
                                    "width": 36,
                                    "height": 10,
                                    "alt": ""
                                },
                                "activeImage": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "Arrow.png",
                                    "uri": "5bfb6f_c2ae26a3c5004bca9ea2b860a535f4ab~mv2.png",
                                    "description": "",
                                    "width": 36,
                                    "height": 10,
                                    "alt": ""
                                },
                                "alt": ""
                            },
                            "overrides": {
                                "item1": {
                                    "type": "ImageButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "defaultImage": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                        "title": "Arrow.png",
                                        "uri": "5bfb6f_c2ae26a3c5004bca9ea2b860a535f4ab~mv2.png",
                                        "description": "",
                                        "width": 36,
                                        "height": 10,
                                        "alt": ""
                                    },
                                    "hoverImage": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                        "title": "Arrow_Hover.png",
                                        "uri": "5bfb6f_ff73ca63250240b2a3c975834afa9356~mv2.png",
                                        "description": "",
                                        "width": 36,
                                        "height": 10,
                                        "alt": ""
                                    },
                                    "activeImage": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                        "title": "Arrow.png",
                                        "uri": "5bfb6f_c2ae26a3c5004bca9ea2b860a535f4ab~mv2.png",
                                        "description": "",
                                        "width": 36,
                                        "height": 10,
                                        "alt": ""
                                    },
                                    "alt": ""
                                }
                            }
                        },
                        "props": {"type": "ImageButtonProperties", "metaData": {"schemaVersion": "1.0"}, "transition": "fade"},
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                            "componentClassName": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.common.components.imagebutton.viewer.ImageButton",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 60,
                                "height": 11,
                                "x": 130,
                                "y": 193,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }],
                    "layout": {
                        "width": 241,
                        "height": 250,
                        "x": 738,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.MediaContainer",
                    "design": {
                        "type": "RepeatedData",
                        "original": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#F2BF5E",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0,
                                "showOverlayForMediaType": "WixVideo",
                                "mediaTransforms": {"scale": 1}
                            },
                            "cssStyle": {
                                "cssBorder": {
                                    "width": {
                                        "top": {"value": 0, "unit": "px"},
                                        "right": {"value": 0, "unit": "px"},
                                        "bottom": {"value": 0, "unit": "px"},
                                        "left": {"value": 0, "unit": "px"}
                                    },
                                    "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                    "color": {
                                        "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                    }
                                },
                                "cssBorderRadius": {
                                    "topLeft": {"value": 0, "unit": "px"},
                                    "topRight": {"value": 0, "unit": "px"},
                                    "bottomLeft": {"value": 0, "unit": "px"},
                                    "bottomRight": {"value": 0, "unit": "px"}
                                }
                            },
                            "themeMappings": {},
                            "charas": "design-j9pkr07o",
                            "dataChangeBehaviors": []
                        },
                        "overrides": {
                            "item1": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#F2BF5E",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0,
                                    "showOverlayForMediaType": "WixVideo",
                                    "mediaTransforms": {"scale": 1}
                                },
                                "cssStyle": {
                                    "cssBorder": {
                                        "width": {
                                            "top": {"value": 0, "unit": "px"},
                                            "right": {"value": 0, "unit": "px"},
                                            "bottom": {"value": 0, "unit": "px"},
                                            "left": {"value": 0, "unit": "px"}
                                        },
                                        "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                        "color": {
                                            "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                        }
                                    },
                                    "cssBorderRadius": {
                                        "topLeft": {"value": 0, "unit": "px"},
                                        "topRight": {"value": 0, "unit": "px"},
                                        "bottomLeft": {"value": 0, "unit": "px"},
                                        "bottomRight": {"value": 0, "unit": "px"}
                                    }
                                },
                                "themeMappings": {},
                                "charas": "design-j9pkr07o",
                                "dataChangeBehaviors": []
                            }
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.MediaContainer",
                        "type": "Container",
                        "conversionData": {"isCustomPreset": true},
                        "layout": {
                            "width": 324,
                            "height": 244,
                            "x": 0,
                            "y": 244,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    }
                }],
                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                "layout": {
                    "width": 251,
                    "height": 250,
                    "x": -1,
                    "y": 3049,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Repeater",
                "data": {
                    "type": "Repeater",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": ["item1"]
                },
                "props": {
                    "type": "CardsLayoutProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "alignment": "left",
                    "gap": {"vertical": 5, "horizontal": 5}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-brd": "0",
                            "bg": "61,155,233,1",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#324158",
                            "brw": "0px",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "bg": "value",
                            "brd": "value",
                            "brw": "value",
                            "rd": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "mobile.core.components.Container",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                },
                "mobileStructure": {
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "type": "RepeaterContainer",
                    "conversionData": {
                        "isCustomPreset": true,
                        "mobileProps": {
                            "type": "CardsLayoutProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "alignment": "left",
                            "gap": {"vertical": 0, "horizontal": 0}
                        }
                    },
                    "layout": {
                        "width": 324,
                        "height": 976,
                        "x": -4,
                        "y": 4777,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    }
                },
                "activeModes": {},
                "id": "comp-j9phsrjg"
            },
            "preset": {"rect": {"width": 324, "height": 103, "x": 0, "y": 485}}
        }, {
            "id": "Repeater_Repeaters_5",
            "structure": {
                "type": "RepeaterContainer",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                        "layout": {
                            "width": 160,
                            "height": 160,
                            "x": 19,
                            "y": 22,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "",
                                "uri": "ecb6bd6e8d334b0b8edfc734f2f1dca6.jpg",
                                "description": "",
                                "width": 6000,
                                "height": 4000,
                                "alt": "",
                                "crop": {"x": 1750, "y": 0, "width": 4000, "height": 4000},
                                "link": {
                                    "type": "ExternalLink",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "url": "http://www.wix.com",
                                    "target": "_blank"
                                }
                            },
                            "overrides": {
                                "item1": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "",
                                    "uri": "ecb6bd6e8d334b0b8edfc734f2f1dca6.jpg",
                                    "description": "",
                                    "width": 6000,
                                    "height": 4000,
                                    "alt": "",
                                    "crop": {"x": 1750, "y": 0, "width": 4000, "height": 4000},
                                    "link": {
                                        "type": "ExternalLink",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "url": "http://www.wix.com",
                                        "target": "_blank"
                                    }
                                }
                            }
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "0",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brw": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {"alpha-brd": "value", "brd": "value", "brw": "value", "shd": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.WPhoto",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 272,
                                "height": 272,
                                "x": 23,
                                "y": 26,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 238,
                            "height": 79,
                            "x": 208,
                            "y": 103,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_4\" style=\"font-size: 16px;\"><span style=\"color:#858585\"><span style=\"font-size:16px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:eb garamond,serif\">Introduce your team! Click here to add images, text and links, or connect data from your collection.</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_4\" style=\"font-size: 16px;\"><span style=\"color:#858585\"><span style=\"font-size:16px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:eb garamond,serif\">Introduce your team! Click here to add images, text and links, or connect data from your collection.</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": false,
                            "minHeight": 79
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true,
                                    "overrideAlignment": "center"
                                }
                            },
                            "layout": {
                                "width": 242,
                                "height": 63,
                                "x": 39,
                                "y": 373,
                                "scale": 0.9974999999999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 238,
                            "height": 23,
                            "x": 208,
                            "y": 68,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_4\" style=\"font-size: 18px;\"><span style=\"font-size:18px\"><span style=\"color:#A2230A\"><span style=\"letter-spacing:0em\"><span style=\"font-family:eb garamond,serif\">Art Director</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_4\" style=\"font-size: 18px;\"><span style=\"font-size:18px\"><span style=\"color:#A2230A\"><span style=\"letter-spacing:0em\"><span style=\"font-family:eb garamond,serif\">Art Director</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true,
                                    "overrideAlignment": "center"
                                }
                            },
                            "layout": {
                                "width": 272,
                                "height": 22,
                                "x": 23,
                                "y": 342,
                                "scale": 0.9444444444444444,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 238,
                            "height": 27,
                            "x": 208,
                            "y": 38,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h4 class=\"font_4\" style=\"font-size:24px\"><span style=\"font-size:24px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.08em\"><span style=\"font-family:helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif\">JOSIE LANE</span></span></span></span></h4>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h4 class=\"font_4\" style=\"font-size:24px\"><span style=\"font-size:24px\"><span style=\"color:#333333\"><span style=\"letter-spacing:0.08em\"><span style=\"font-family:helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif\">JOSIE LANE</span></span></span></span></h4>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true,
                                    "overrideAlignment": "center"
                                }
                            },
                            "layout": {
                                "width": 272,
                                "height": 24,
                                "x": 23,
                                "y": 317,
                                "scale": 0.8333333333333334,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }],
                    "layout": {
                        "width": 479,
                        "height": 209,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.MediaContainer",
                    "design": {
                        "type": "RepeatedData",
                        "original": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#F9F9F9",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0,
                                "showOverlayForMediaType": "WixVideo",
                                "mediaTransforms": {"scale": 1}
                            },
                            "cssStyle": {
                                "cssBorder": {
                                    "width": {
                                        "top": {"value": 0, "unit": "px"},
                                        "right": {"value": 0, "unit": "px"},
                                        "bottom": {"value": 0, "unit": "px"},
                                        "left": {"value": 0, "unit": "px"}
                                    },
                                    "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                    "color": {
                                        "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                    }
                                },
                                "cssBorderRadius": {
                                    "topLeft": {"value": 0, "unit": "px"},
                                    "topRight": {"value": 0, "unit": "px"},
                                    "bottomLeft": {"value": 0, "unit": "px"},
                                    "bottomRight": {"value": 0, "unit": "px"}
                                }
                            },
                            "themeMappings": {},
                            "charas": "design-j9pn991b",
                            "dataChangeBehaviors": []
                        },
                        "overrides": {
                            "item1": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#F9F9F9",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0,
                                    "showOverlayForMediaType": "WixVideo",
                                    "mediaTransforms": {"scale": 1}
                                },
                                "cssStyle": {
                                    "cssBorder": {
                                        "width": {
                                            "top": {"value": 0, "unit": "px"},
                                            "right": {"value": 0, "unit": "px"},
                                            "bottom": {"value": 0, "unit": "px"},
                                            "left": {"value": 0, "unit": "px"}
                                        },
                                        "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                        "color": {
                                            "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                        }
                                    },
                                    "cssBorderRadius": {
                                        "topLeft": {"value": 0, "unit": "px"},
                                        "topRight": {"value": 0, "unit": "px"},
                                        "bottomLeft": {"value": 0, "unit": "px"},
                                        "bottomRight": {"value": 0, "unit": "px"}
                                    }
                                },
                                "themeMappings": {},
                                "charas": "design-j9pn991b",
                                "dataChangeBehaviors": []
                            }
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.MediaContainer",
                        "type": "Container",
                        "conversionData": {"isCustomPreset": true},
                        "layout": {
                            "width": 320,
                            "height": 462,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    }
                }],
                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                "layout": {
                    "width": 489,
                    "height": 209,
                    "x": 7,
                    "y": 3353,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Repeater",
                "data": {
                    "type": "Repeater",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": ["item1"]
                },
                "props": {
                    "type": "CardsLayoutProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "alignment": "left",
                    "gap": {"vertical": 20, "horizontal": 20}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-brd": "0",
                            "bg": "61,155,233,1",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#324158",
                            "brw": "0px",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "bg": "value",
                            "brd": "value",
                            "brw": "value",
                            "rd": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "mobile.core.components.Container",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                },
                "mobileStructure": {
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "type": "RepeaterContainer",
                    "conversionData": {"isCustomPreset": true},
                    "layout": {
                        "width": 320,
                        "height": 1426,
                        "x": 0,
                        "y": 6736,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    }
                },
                "activeModes": {},
                "id": "comp-j9pn2gew"
            },
            "preset": {"rect": {"width": 183, "height": 268, "x": 0, "y": 588}}
        }, {
            "id": "Repeater_Repeaters_6",
            "structure": {
                "type": "RepeaterContainer",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 125,
                            "height": 120,
                            "x": 22,
                            "y": 24,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "",
                                "uri": "fd5752f4ee5b4580b359d3ea16c8b715.jpeg",
                                "description": "",
                                "width": 2600,
                                "height": 1698,
                                "alt": ""
                            },
                            "overrides": {
                                "item1": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "",
                                    "uri": "fd5752f4ee5b4580b359d3ea16c8b715.jpeg",
                                    "description": "",
                                    "width": 2600,
                                    "height": 1698,
                                    "alt": ""
                                }
                            }
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 125,
                                "height": 120,
                                "x": 23,
                                "y": 34,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 148,
                            "height": 52,
                            "x": 167,
                            "y": 105,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_7\" style=\"font-size:15px\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">Your Text Here</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_7\" style=\"font-size:15px\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">Your Text Here</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": false,
                            "minHeight": 52
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 125,
                                "height": 18,
                                "x": 170,
                                "y": 114,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 20,
                            "height": 5,
                            "x": 168,
                            "y": 88,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {"type": "FiveGridLineProperties", "metaData": {"schemaVersion": "1.0"}, "fullScreenModeOn": false},
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "3"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 20,
                                "height": 5,
                                "x": 170,
                                "y": 99,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 153,
                            "height": 24,
                            "x": 167,
                            "y": 52,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h6 class=\"font_7\" style=\"font-size: 20px;\"><span style=\"font-size:20px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">Urban Life</span></span></span></h6>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h6 class=\"font_7\" style=\"font-size: 20px;\"><span style=\"font-size:20px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">Urban Life</span></span></span></h6>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 125,
                                "height": 23,
                                "x": 170,
                                "y": 59,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }],
                    "layout": {
                        "width": 323,
                        "height": 180,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.MediaContainer",
                    "design": {
                        "type": "RepeatedData",
                        "original": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#333333",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0,
                                "showOverlayForMediaType": "WixVideo"
                            },
                            "cssStyle": {
                                "cssBorder": {
                                    "width": {
                                        "top": {"value": 0, "unit": "px"},
                                        "right": {"value": 0, "unit": "px"},
                                        "bottom": {"value": 0, "unit": "px"},
                                        "left": {"value": 0, "unit": "px"}
                                    },
                                    "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                    "color": {
                                        "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                    }
                                },
                                "cssBorderRadius": {
                                    "topLeft": {"value": 0, "unit": "px"},
                                    "topRight": {"value": 0, "unit": "px"},
                                    "bottomLeft": {"value": 0, "unit": "px"},
                                    "bottomRight": {"value": 0, "unit": "px"}
                                }
                            },
                            "themeMappings": {},
                            "charas": "design-j9pta0wk",
                            "dataChangeBehaviors": []
                        },
                        "overrides": {
                            "item1": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#333333",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0,
                                    "showOverlayForMediaType": "WixVideo"
                                },
                                "cssStyle": {
                                    "cssBorder": {
                                        "width": {
                                            "top": {"value": 0, "unit": "px"},
                                            "right": {"value": 0, "unit": "px"},
                                            "bottom": {"value": 0, "unit": "px"},
                                            "left": {"value": 0, "unit": "px"}
                                        },
                                        "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                        "color": {
                                            "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                        }
                                    },
                                    "cssBorderRadius": {
                                        "topLeft": {"value": 0, "unit": "px"},
                                        "topRight": {"value": 0, "unit": "px"},
                                        "bottomLeft": {"value": 0, "unit": "px"},
                                        "bottomRight": {"value": 0, "unit": "px"}
                                    }
                                },
                                "themeMappings": {},
                                "charas": "design-j9pta0wk",
                                "dataChangeBehaviors": []
                            }
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.MediaContainer",
                        "type": "Container",
                        "conversionData": {"isCustomPreset": true},
                        "layout": {
                            "width": 320,
                            "height": 189,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    }
                }],
                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                "layout": {
                    "width": 333,
                    "height": 180,
                    "x": 38,
                    "y": 4094,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Repeater",
                "data": {
                    "type": "Repeater",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": ["item1"]
                },
                "props": {
                    "type": "CardsLayoutProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "alignment": "left",
                    "gap": {"vertical": 0, "horizontal": 0}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-brd": "0",
                            "bg": "61,155,233,1",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#324158",
                            "brw": "0px",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "bg": "value",
                            "brd": "value",
                            "brw": "value",
                            "rd": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "mobile.core.components.Container",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                },
                "mobileStructure": {
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "type": "RepeaterContainer",
                    "conversionData": {"isCustomPreset": true},
                    "layout": {
                        "width": 320,
                        "height": 792,
                        "x": 0,
                        "y": 5855,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    }
                },
                "activeModes": {},
                "id": "comp-j9pl04ef"
            },
            "preset": {"rect": {"width": 141, "height": 268, "x": 183, "y": 588}}
        }, {
            "id": "Repeater_Repeaters_7",
            "structure": {
                "type": "RepeaterContainer",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3",
                        "layout": {
                            "width": 12,
                            "height": 12,
                            "x": 457,
                            "y": 217,
                            "scale": 1,
                            "rotationInDegrees": 270,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "data": {
                            "type": "RepeatedData",
                            "original": {"type": "SvgShape", "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}},
                            "overrides": {
                                "item1": {
                                    "type": "SvgShape",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                                }
                            }
                        },
                        "props": {"type": "SvgShapeProperties", "metaData": {"schemaVersion": "1.0"}, "maintainAspectRatio": true},
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-fillcolor": "1", "alpha-stroke": "0", "fillcolor": "#FFFFFF", "strokewidth": "0"},
                                "propertiesSource": {
                                    "alpha-stroke": "value",
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 12,
                                "height": 12,
                                "x": 126,
                                "y": 237,
                                "scale": 1,
                                "rotationInDegrees": 270,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 265,
                            "height": 75,
                            "x": 27,
                            "y": 162,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h4 class=\"font_8\" style=\"font-size: 35px; line-height: 1em;\"><span style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">Shades</span></span></span></span></h4>\n\n<h4 class=\"font_8\" style=\"font-size: 35px; line-height: 1em;\"><span style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">of Gray</span></span></span></span></h4>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h4 class=\"font_8\" style=\"font-size: 35px; line-height: 1em;\"><span style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">Shades</span></span></span></span></h4>\n\n<h4 class=\"font_8\" style=\"font-size: 35px; line-height: 1em;\"><span style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">of Gray</span></span></span></span></h4>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": false,
                            "minHeight": 75
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 280,
                                "height": 62,
                                "x": 19,
                                "y": 38,
                                "scale": 1.0628344735991382,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                        "layout": {
                            "width": 108,
                            "height": 26,
                            "x": 350,
                            "y": 210,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "View Project"
                            },
                            "overrides": {
                                "item1": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "View Project"
                                }
                            }
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "align": "left",
                            "margin": 10
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496",
                                    "txt": "#FFFFFF",
                                    "txth": "#DEDEDE"
                                },
                                "propertiesSource": {"fnt": "value", "txt": "value", "txth": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 108,
                                "height": 26,
                                "x": 19,
                                "y": 230,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }],
                    "layout": {
                        "width": 490,
                        "height": 252,
                        "x": 0,
                        "y": 252,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.MediaContainer",
                    "design": {
                        "type": "RepeatedData",
                        "original": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "1.jpg",
                                    "uri": "5bfb6f_62b060a47ea543c5ae96065f5ee408d3~mv2.jpg",
                                    "description": "private/",
                                    "width": 720,
                                    "height": 719,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.74
                                },
                                "color": "#333333",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0,
                                "showOverlayForMediaType": "WixVideo",
                                "mediaTransforms": {"scale": 1}
                            },
                            "cssStyle": {
                                "cssBorder": {
                                    "width": {
                                        "top": {"value": 0, "unit": "px"},
                                        "right": {"value": 0, "unit": "px"},
                                        "bottom": {"value": 0, "unit": "px"},
                                        "left": {"value": 0, "unit": "px"}
                                    },
                                    "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                    "color": {
                                        "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                    }
                                },
                                "cssBorderRadius": {
                                    "topLeft": {"value": 0, "unit": "px"},
                                    "topRight": {"value": 0, "unit": "px"},
                                    "bottomLeft": {"value": 0, "unit": "px"},
                                    "bottomRight": {"value": 0, "unit": "px"}
                                }
                            },
                            "themeMappings": {},
                            "charas": "design-j9pugynf",
                            "dataChangeBehaviors": []
                        },
                        "overrides": {
                            "item1": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "mediaRef": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "title": "1.jpg",
                                        "uri": "5bfb6f_62b060a47ea543c5ae96065f5ee408d3~mv2.jpg",
                                        "description": "private/",
                                        "width": 720,
                                        "height": 719,
                                        "alt": "",
                                        "artist": {"id": "", "name": ""},
                                        "opacity": 0.74
                                    },
                                    "color": "#333333",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0,
                                    "showOverlayForMediaType": "WixVideo",
                                    "mediaTransforms": {"scale": 1}
                                },
                                "cssStyle": {
                                    "cssBorder": {
                                        "width": {
                                            "top": {"value": 0, "unit": "px"},
                                            "right": {"value": 0, "unit": "px"},
                                            "bottom": {"value": 0, "unit": "px"},
                                            "left": {"value": 0, "unit": "px"}
                                        },
                                        "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                        "color": {
                                            "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                        }
                                    },
                                    "cssBorderRadius": {
                                        "topLeft": {"value": 0, "unit": "px"},
                                        "topRight": {"value": 0, "unit": "px"},
                                        "bottomLeft": {"value": 0, "unit": "px"},
                                        "bottomRight": {"value": 0, "unit": "px"}
                                    }
                                },
                                "themeMappings": {},
                                "charas": "design-j9pugynf",
                                "dataChangeBehaviors": []
                            }
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.MediaContainer",
                        "type": "Container",
                        "conversionData": {"isCustomPreset": true},
                        "layout": {
                            "width": 320,
                            "height": 283,
                            "x": 0,
                            "y": 566,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    }
                }],
                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                "layout": {
                    "width": 500,
                    "height": 252,
                    "x": -2,
                    "y": 4846,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Repeater",
                "data": {
                    "type": "Repeater",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": ["item1"]
                },
                "props": {
                    "type": "CardsLayoutProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "alignment": "center",
                    "gap": {"vertical": 0, "horizontal": 0}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-brd": "0",
                            "bg": "61,155,233,1",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#324158",
                            "brw": "0px",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "bg": "value",
                            "brd": "value",
                            "brw": "value",
                            "rd": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "mobile.core.components.Container",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                },
                "mobileStructure": {
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "type": "RepeaterContainer",
                    "conversionData": {"isCustomPreset": true},
                    "layout": {
                        "width": 320,
                        "height": 1698,
                        "x": 0,
                        "y": 8255,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    }
                },
                "activeModes": {},
                "id": "comp-j9pp53f2"
            },
            "preset": {"rect": {"width": 324, "height": 209, "x": 0, "y": 856}}
        }, {
            "id": "Repeater_Repeaters_8",
            "structure": {
                "type": "RepeaterContainer",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 309,
                            "height": 33,
                            "x": 26,
                            "y": 47,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_2\" style=\"font-size: 25px;\"><span style=\"color:#36B5CD\"><span style=\"font-size:25px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">01/</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_2\" style=\"font-size: 25px;\"><span style=\"color:#36B5CD\"><span style=\"font-size:25px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">01/</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {"type": "WRichTextProperties", "metaData": {"schemaVersion": "1.0"}, "brightness": 1, "packed": true},
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 157,
                                "height": 31,
                                "x": 27,
                                "y": 44,
                                "scale": 0.92,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 289,
                            "height": 76,
                            "x": 26,
                            "y": 88,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h4 class=\"font_2\" style=\"font-size: 25px; line-height: 1.5em;\"><span style=\"color:#FFFFFF\"><span style=\"font-size:25px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">Just In! Display Content In Repeating Layouts</span></span></span></h4>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h4 class=\"font_2\" style=\"font-size: 25px; line-height: 1.5em;\"><span style=\"color:#FFFFFF\"><span style=\"font-size:25px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">Just In! Display Content In Repeating Layouts</span></span></span></h4>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": false,
                            "minHeight": 47
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 216,
                                "height": 58,
                                "x": 27,
                                "y": 80,
                                "scale": 0.76,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 268,
                            "height": 124,
                            "x": 27,
                            "y": 173,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_2\" style=\"font-size: 14px; line-height: 1.7em;\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">Keep your visitors in the know. Want to make this content your own? Just add your images, text and links, or connect to data from your collection.&nbsp;</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_2\" style=\"font-size: 14px; line-height: 1.7em;\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">Keep your visitors in the know. Want to make this content your own? Just add your images, text and links, or connect to data from your collection.&nbsp;</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": false,
                            "minHeight": 124
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 246,
                                "height": 96,
                                "x": 27,
                                "y": 149,
                                "scale": 0.9333333333333333,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }],
                    "layout": {
                        "width": 400,
                        "height": 323,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.MediaContainer",
                    "design": {
                        "type": "RepeatedData",
                        "original": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#173981",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0,
                                "showOverlayForMediaType": "WixVideo",
                                "mediaTransforms": {"scale": 1}
                            },
                            "cssStyle": {
                                "cssBorder": {
                                    "width": {
                                        "top": {"value": 0, "unit": "px"},
                                        "right": {"value": 0, "unit": "px"},
                                        "bottom": {"value": 0, "unit": "px"},
                                        "left": {"value": 0, "unit": "px"}
                                    },
                                    "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                    "color": {
                                        "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                    }
                                },
                                "cssBorderRadius": {
                                    "topLeft": {"value": 0, "unit": "px"},
                                    "topRight": {"value": 0, "unit": "px"},
                                    "bottomLeft": {"value": 0, "unit": "px"},
                                    "bottomRight": {"value": 0, "unit": "px"}
                                }
                            },
                            "themeMappings": {},
                            "charas": "design-j9qruv6y",
                            "dataChangeBehaviors": []
                        },
                        "overrides": {
                            "item1": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#173981",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0,
                                    "showOverlayForMediaType": "WixVideo",
                                    "mediaTransforms": {"scale": 1}
                                },
                                "cssStyle": {
                                    "cssBorder": {
                                        "width": {
                                            "top": {"value": 0, "unit": "px"},
                                            "right": {"value": 0, "unit": "px"},
                                            "bottom": {"value": 0, "unit": "px"},
                                            "left": {"value": 0, "unit": "px"}
                                        },
                                        "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                        "color": {
                                            "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                        }
                                    },
                                    "cssBorderRadius": {
                                        "topLeft": {"value": 0, "unit": "px"},
                                        "topRight": {"value": 0, "unit": "px"},
                                        "bottomLeft": {"value": 0, "unit": "px"},
                                        "bottomRight": {"value": 0, "unit": "px"}
                                    }
                                },
                                "themeMappings": {},
                                "charas": "design-j9qruv6y",
                                "dataChangeBehaviors": []
                            }
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.MediaContainer",
                        "type": "Container",
                        "conversionData": {"isCustomPreset": true},
                        "layout": {
                            "width": 320,
                            "height": 288,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    }
                }],
                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                "layout": {
                    "width": 410,
                    "height": 323,
                    "x": -2,
                    "y": 5729,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Repeater",
                "data": {
                    "type": "Repeater",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": ["item1"]
                },
                "props": {
                    "type": "CardsLayoutProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "alignment": "left",
                    "gap": {"vertical": 0, "horizontal": 0}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-brd": "0",
                            "bg": "61,155,233,1",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#324158",
                            "brw": "0px",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "bg": "value",
                            "brd": "value",
                            "brw": "value",
                            "rd": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "mobile.core.components.Container",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                },
                "mobileStructure": {
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "type": "RepeaterContainer",
                    "conversionData": {"isCustomPreset": true},
                    "layout": {
                        "width": 320,
                        "height": 1181,
                        "x": 0,
                        "y": 10048,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    }
                },
                "activeModes": {},
                "id": "comp-j9qrom3r"
            },
            "preset": {"rect": {"width": 141, "height": 346, "x": 0, "y": 1065}}
        }, {
            "id": "Repeater_Repeaters_9",
            "structure": {
                "type": "RepeaterContainer",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 280,
                            "height": 352,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "",
                                "uri": "5bfb6f_60da908c04d84c888c704e939d95d1e5~mv2_d_2600_2600_s_4_2.jpg",
                                "description": "",
                                "width": 2600,
                                "height": 2600,
                                "alt": "",
                                "crop": {"x": 422, "y": 0, "width": 2178, "height": 2600}
                            },
                            "overrides": {
                                "item1": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "",
                                    "uri": "5bfb6f_60da908c04d84c888c704e939d95d1e5~mv2_d_2600_2600_s_4_2.jpg",
                                    "description": "",
                                    "width": 2600,
                                    "height": 2600,
                                    "alt": "",
                                    "crop": {"x": 422, "y": 0, "width": 2178, "height": 2600}
                                }
                            }
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 299,
                                "height": 352,
                                "x": 10,
                                "y": 10,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 224,
                                "height": 29,
                                "x": 10,
                                "y": 23,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h4 class=\"font_0\" style=\"font-size: 24px; text-align: center;\"><span style=\"font-size:24px\"><span style=\"color:#465E52\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">Accessories</span></span></span></span></h4>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<h4 class=\"font_0\" style=\"font-size: 24px; text-align: center;\"><span style=\"font-size:24px\"><span style=\"color:#465E52\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">Accessories</span></span></span></span></h4>",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    }
                                }
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                        "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                        "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                        "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                        "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                        "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                        "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                        "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                        "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                        "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                        "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                    },
                                    "propertiesSource": {
                                        "f0": "value",
                                        "f1": "value",
                                        "f10": "value",
                                        "f2": "value",
                                        "f3": "value",
                                        "f4": "value",
                                        "f5": "value",
                                        "f6": "value",
                                        "f7": "value",
                                        "f8": "value",
                                        "f9": "value"
                                    }
                                },
                                "componentClassName": "",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "type": "Component",
                                "conversionData": {
                                    "isCustomPreset": true,
                                    "mobileProps": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    }
                                },
                                "layout": {
                                    "width": 205,
                                    "height": 32,
                                    "x": 27,
                                    "y": 19,
                                    "scale": 1.0838709677419356,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 224,
                                "height": 48,
                                "x": 11,
                                "y": 59,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "RepeatedData",
                                "original": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">Make this your own. &nbsp;</span></span></p>\n\n<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">Add the content you want.&nbsp;</span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "overrides": {
                                    "item1": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">Make this your own. &nbsp;</span></span></p>\n\n<p class=\"font_8\" style=\"text-align:center\"><span style=\"color:#465E52\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">Add the content you want.&nbsp;</span></span></p>",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    }
                                }
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": false,
                                "minHeight": 48
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                        "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                        "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                        "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                        "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                        "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                        "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                        "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                        "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                        "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                        "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                    },
                                    "propertiesSource": {
                                        "f0": "value",
                                        "f1": "value",
                                        "f10": "value",
                                        "f2": "value",
                                        "f3": "value",
                                        "f4": "value",
                                        "f5": "value",
                                        "f6": "value",
                                        "f7": "value",
                                        "f8": "value",
                                        "f9": "value"
                                    }
                                },
                                "componentClassName": "",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                            },
                            "mobileStructure": {
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "type": "Component",
                                "conversionData": {
                                    "isCustomPreset": true,
                                    "mobileProps": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    }
                                },
                                "layout": {
                                    "width": 205,
                                    "height": 38,
                                    "x": 27,
                                    "y": 59,
                                    "scale": 1.0838709677419354,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        }],
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 248,
                            "height": 117,
                            "x": 17,
                            "y": 280,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#8BAA95",
                                    "brw": "1",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {"bg": "value", "brd": "value", "brw": "value", "rd": "value", "shd": "value"}
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "componentType": "mobile.core.components.Container",
                            "type": "Container",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 259,
                                "height": 115,
                                "x": 30,
                                "y": 314,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }],
                    "layout": {
                        "width": 280,
                        "height": 397,
                        "x": 310,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.MediaContainer",
                    "design": {
                        "type": "RepeatedData",
                        "original": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFFFFF",
                                "colorOpacity": 0,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 1,
                                "showOverlayForMediaType": "WixVideo"
                            },
                            "cssStyle": {
                                "cssBorder": {
                                    "width": {
                                        "top": {"value": 0, "unit": "px"},
                                        "right": {"value": 0, "unit": "px"},
                                        "bottom": {"value": 0, "unit": "px"},
                                        "left": {"value": 0, "unit": "px"}
                                    },
                                    "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                    "color": {
                                        "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                    }
                                },
                                "cssBorderRadius": {
                                    "topLeft": {"value": 0, "unit": "px"},
                                    "topRight": {"value": 0, "unit": "px"},
                                    "bottomLeft": {"value": 0, "unit": "px"},
                                    "bottomRight": {"value": 0, "unit": "px"}
                                }
                            },
                            "themeMappings": {},
                            "charas": "design-j9r56st2",
                            "dataChangeBehaviors": []
                        },
                        "overrides": {
                            "item1": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFFFFF",
                                    "colorOpacity": 0,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 1,
                                    "showOverlayForMediaType": "WixVideo"
                                },
                                "cssStyle": {
                                    "cssBorder": {
                                        "width": {
                                            "top": {"value": 0, "unit": "px"},
                                            "right": {"value": 0, "unit": "px"},
                                            "bottom": {"value": 0, "unit": "px"},
                                            "left": {"value": 0, "unit": "px"}
                                        },
                                        "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                        "color": {
                                            "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                        }
                                    },
                                    "cssBorderRadius": {
                                        "topLeft": {"value": 0, "unit": "px"},
                                        "topRight": {"value": 0, "unit": "px"},
                                        "bottomLeft": {"value": 0, "unit": "px"},
                                        "bottomRight": {"value": 0, "unit": "px"}
                                    }
                                },
                                "themeMappings": {},
                                "charas": "design-j9r56st2",
                                "dataChangeBehaviors": []
                            }
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.MediaContainer",
                        "type": "Container",
                        "conversionData": {"isCustomPreset": true},
                        "layout": {
                            "width": 320,
                            "height": 456,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    }
                }],
                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                "layout": {
                    "width": 290,
                    "height": 397,
                    "x": 8,
                    "y": 7066,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Repeater",
                "data": {
                    "type": "Repeater",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": ["item1"]
                },
                "props": {
                    "type": "CardsLayoutProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "alignment": "center",
                    "gap": {"vertical": 30, "horizontal": 30}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-brd": "0",
                            "bg": "61,155,233,1",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#324158",
                            "brw": "0px",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "bg": "value",
                            "brd": "value",
                            "brw": "value",
                            "rd": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "mobile.core.components.Container",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                },
                "mobileStructure": {
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "type": "RepeaterContainer",
                    "conversionData": {"isCustomPreset": true},
                    "layout": {
                        "width": 320,
                        "height": 2886,
                        "x": 0,
                        "y": 11282,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    }
                },
                "activeModes": {},
                "id": "comp-j9quo3ml"
            },
            "preset": {"rect": {"width": 183, "height": 346, "x": 141, "y": 1065}}
        }, {
            "id": "Repeater_Repeaters_15",
            "structure": {
                "type": "RepeaterContainer",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto",
                        "layout": {
                            "width": 86,
                            "height": 86,
                            "x": 102,
                            "y": 40,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "",
                                "uri": "5bfb6f_9cc6931fc07c4c46b50694d9062d80d8~mv2.png",
                                "description": "",
                                "width": 86,
                                "height": 86,
                                "alt": ""
                            },
                            "overrides": {
                                "item1": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "",
                                    "uri": "5bfb6f_9cc6931fc07c4c46b50694d9062d80d8~mv2.png",
                                    "description": "",
                                    "width": 86,
                                    "height": 86,
                                    "alt": ""
                                }
                            }
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 86,
                                "height": 86,
                                "x": 117,
                                "y": 48,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 226,
                            "height": 28,
                            "x": 32,
                            "y": 154,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h4 style=\"font-size: 24px; text-align: center;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:24px\"><span style=\"color:#000000\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\">ABOUT US</span></span></span></span></h4>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h4 style=\"font-size: 24px; text-align: center;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:24px\"><span style=\"color:#000000\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\">ABOUT US</span></span></span></span></h4>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 280,
                                "height": 24,
                                "x": 20,
                                "y": 164,
                                "scale": 0.8333333333333334,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 222,
                            "height": 62,
                            "x": 34,
                            "y": 199,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.5em; text-align:center\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#545454\">Make this your own. Click here&nbsp;<br />\nto add the content you want.</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.5em; text-align:center\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#545454\">Make this your own. Click here&nbsp;<br />\nto add the content you want.</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": false,
                            "minHeight": 62
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 280,
                                "height": 44,
                                "x": 20,
                                "y": 195,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }],
                    "layout": {
                        "width": 290,
                        "height": 272,
                        "x": 290,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.MediaContainer",
                    "design": {
                        "type": "RepeatedData",
                        "original": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFFFFF",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0,
                                "showOverlayForMediaType": "WixVideo",
                                "mediaTransforms": {"scale": 1}
                            },
                            "cssStyle": {
                                "cssBorder": {
                                    "width": {
                                        "top": {"value": 0, "unit": "px"},
                                        "right": {"value": 0, "unit": "px"},
                                        "bottom": {"value": 0, "unit": "px"},
                                        "left": {"value": 0, "unit": "px"}
                                    },
                                    "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                    "color": {
                                        "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                    }
                                },
                                "cssBorderRadius": {
                                    "topLeft": {"value": 0, "unit": "px"},
                                    "topRight": {"value": 0, "unit": "px"},
                                    "bottomLeft": {"value": 0, "unit": "px"},
                                    "bottomRight": {"value": 0, "unit": "px"}
                                }
                            },
                            "themeMappings": {},
                            "charas": "design-j9sc7tb1",
                            "dataChangeBehaviors": []
                        },
                        "overrides": {
                            "item1": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFFFFF",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0,
                                    "showOverlayForMediaType": "WixVideo",
                                    "mediaTransforms": {"scale": 1}
                                },
                                "cssStyle": {
                                    "cssBorder": {
                                        "width": {
                                            "top": {"value": 0, "unit": "px"},
                                            "right": {"value": 0, "unit": "px"},
                                            "bottom": {"value": 0, "unit": "px"},
                                            "left": {"value": 0, "unit": "px"}
                                        },
                                        "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                        "color": {
                                            "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                        }
                                    },
                                    "cssBorderRadius": {
                                        "topLeft": {"value": 0, "unit": "px"},
                                        "topRight": {"value": 0, "unit": "px"},
                                        "bottomLeft": {"value": 0, "unit": "px"},
                                        "bottomRight": {"value": 0, "unit": "px"}
                                    }
                                },
                                "themeMappings": {},
                                "charas": "design-j9sc7tb1",
                                "dataChangeBehaviors": []
                            }
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.MediaContainer",
                        "type": "Container",
                        "conversionData": {"isCustomPreset": true},
                        "layout": {
                            "width": 320,
                            "height": 267,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    }
                }],
                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                "layout": {
                    "width": 300,
                    "height": 272,
                    "x": 0,
                    "y": 13835,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Repeater",
                "data": {
                    "type": "Repeater",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": ["item1"]
                },
                "props": {
                    "type": "CardsLayoutProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "alignment": "left",
                    "gap": {"vertical": 0, "horizontal": 0}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-brd": "0",
                            "bg": "61,155,233,1",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#324158",
                            "brw": "0px",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "bg": "value",
                            "brd": "value",
                            "brw": "value",
                            "rd": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "mobile.core.components.Container",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                },
                "mobileStructure": {
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "type": "RepeaterContainer",
                    "conversionData": {"isCustomPreset": true},
                    "layout": {
                        "width": 320,
                        "height": 1602,
                        "x": 0,
                        "y": 23275,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    }
                },
                "activeModes": {},
                "id": "comp-j9sc10tl"
            },
            "preset": {"rect": {"width": 140, "height": 217, "x": 0, "y": 1411}}
        }, {
            "id": "Repeater_Repeaters_16",
            "structure": {
                "type": "RepeaterContainer",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 700,
                            "height": 42,
                            "x": 42,
                            "y": 31,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h5 class=\"font_8\" style=\"font-size: 35px;\"><span style=\"color:#333333\"><span style=\"font-size:35px\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">Security on Campus Starts with You</span></span></span></h5>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h5 class=\"font_8\" style=\"font-size: 35px;\"><span style=\"color:#333333\"><span style=\"font-size:35px\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">Security on Campus Starts with You</span></span></span></h5>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 280,
                                "height": 52,
                                "x": 19,
                                "y": 21,
                                "scale": 0.7586206896551724,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 663,
                            "height": 5,
                            "x": 42,
                            "y": 134,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {"type": "FiveGridLineProperties", "metaData": {"schemaVersion": "1.0"}, "fullScreenModeOn": false},
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#000000", "lnw": "1px"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 280,
                                "height": 5,
                                "x": 19,
                                "y": 126,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 573,
                            "height": 17,
                            "x": 42,
                            "y": 82,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_7\" style=\"font-size:16px\"><span style=\"color:#545454\"><span style=\"font-size:16px\">Sept. 23, 2023 at 11am</span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_7\" style=\"font-size:16px\"><span style=\"color:#545454\"><span style=\"font-size:16px\">Sept. 23, 2023 at 11am</span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 280,
                                "height": 17,
                                "x": 19,
                                "y": 83,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }],
                    "layout": {
                        "width": 789,
                        "height": 163,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.MediaContainer",
                    "design": {
                        "type": "RepeatedData",
                        "original": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFEF98",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0,
                                "showOverlayForMediaType": "WixVideo",
                                "mediaTransforms": {"scale": 1}
                            },
                            "cssStyle": {
                                "cssBorder": {
                                    "width": {
                                        "top": {"value": 0, "unit": "px"},
                                        "right": {"value": 0, "unit": "px"},
                                        "bottom": {"value": 0, "unit": "px"},
                                        "left": {"value": 0, "unit": "px"}
                                    },
                                    "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                    "color": {
                                        "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                    }
                                },
                                "cssBorderRadius": {
                                    "topLeft": {"value": 0, "unit": "px"},
                                    "topRight": {"value": 0, "unit": "px"},
                                    "bottomLeft": {"value": 0, "unit": "px"},
                                    "bottomRight": {"value": 0, "unit": "px"}
                                }
                            },
                            "themeMappings": {},
                            "charas": "design-j9sdii5u",
                            "dataChangeBehaviors": []
                        },
                        "overrides": {
                            "item1": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFEF98",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0,
                                    "showOverlayForMediaType": "WixVideo",
                                    "mediaTransforms": {"scale": 1}
                                },
                                "cssStyle": {
                                    "cssBorder": {
                                        "width": {
                                            "top": {"value": 0, "unit": "px"},
                                            "right": {"value": 0, "unit": "px"},
                                            "bottom": {"value": 0, "unit": "px"},
                                            "left": {"value": 0, "unit": "px"}
                                        },
                                        "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                        "color": {
                                            "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                        }
                                    },
                                    "cssBorderRadius": {
                                        "topLeft": {"value": 0, "unit": "px"},
                                        "topRight": {"value": 0, "unit": "px"},
                                        "bottomLeft": {"value": 0, "unit": "px"},
                                        "bottomRight": {"value": 0, "unit": "px"}
                                    }
                                },
                                "themeMappings": {},
                                "charas": "design-j9sdii5u",
                                "dataChangeBehaviors": []
                            }
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.MediaContainer",
                        "type": "Container",
                        "conversionData": {"isCustomPreset": true},
                        "layout": {
                            "width": 320,
                            "height": 145,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    }
                }],
                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                "layout": {
                    "width": 789,
                    "height": 163,
                    "x": 34,
                    "y": 14717,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Repeater",
                "data": {
                    "type": "Repeater",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": ["item1"]
                },
                "props": {
                    "type": "CardsLayoutProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "alignment": "left",
                    "gap": {"vertical": 0, "horizontal": 0}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-brd": "0",
                            "bg": "61,155,233,1",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#324158",
                            "brw": "0px",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "bg": "value",
                            "brd": "value",
                            "brw": "value",
                            "rd": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "mobile.core.components.Container",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                },
                "mobileStructure": {
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "type": "RepeaterContainer",
                    "conversionData": {"isCustomPreset": true},
                    "layout": {
                        "width": 320,
                        "height": 818,
                        "x": 0,
                        "y": 24974,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    }
                },
                "activeModes": {},
                "id": "comp-j9sdezf7"
            },
            "preset": {"rect": {"width": 184, "height": 217, "x": 140, "y": 1411}}
        }, {
            "id": "Repeater_Repeaters_17",
            "structure": {
                "type": "RepeaterContainer",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto",
                        "layout": {
                            "width": 278,
                            "height": 262,
                            "x": 18,
                            "y": 22,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "",
                                "uri": "7c13964b8ac7449284e04b0efc560680.jpg",
                                "description": "",
                                "width": 7360,
                                "height": 4912,
                                "alt": "",
                                "link": {
                                    "type": "ExternalLink",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "url": "http://www.wix.com",
                                    "target": "_blank"
                                }
                            },
                            "overrides": {
                                "item1": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "",
                                    "uri": "7c13964b8ac7449284e04b0efc560680.jpg",
                                    "description": "",
                                    "width": 7360,
                                    "height": 4912,
                                    "alt": "",
                                    "link": {
                                        "type": "ExternalLink",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "url": "http://www.wix.com",
                                        "target": "_blank"
                                    }
                                }
                            }
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "filterEffect": {"effectType": "whistler"}
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                            "componentClassName": "wysiwyg.viewer.components.WPhoto",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 281,
                                "height": 264,
                                "x": 19,
                                "y": 20,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 222,
                            "height": 25,
                            "x": 81,
                            "y": 601,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">chris@mysite.com</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">chris@mysite.com</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": false,
                            "minHeight": 18
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 204,
                                "height": 25,
                                "x": 86,
                                "y": 541,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 222,
                            "height": 25,
                            "x": 81,
                            "y": 580,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"color:#545454\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif;\"><span style=\"font-size:16px\">Chris Fit</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em\"><span style=\"color:#545454\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif;\"><span style=\"font-size:16px\">Chris Fit</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": false,
                            "minHeight": 18
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 166,
                                "height": 25,
                                "x": 86,
                                "y": 518,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                        "layout": {
                            "width": 50,
                            "height": 50,
                            "x": 18,
                            "y": 577,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "",
                                "uri": "727644b338ab465cad167dcaf9e69f84.jpg",
                                "description": "",
                                "width": 1846,
                                "height": 2379,
                                "alt": "",
                                "crop": {"x": 0, "y": 0, "width": 1846, "height": 1830}
                            },
                            "overrides": {
                                "item1": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "",
                                    "uri": "727644b338ab465cad167dcaf9e69f84.jpg",
                                    "description": "",
                                    "width": 1846,
                                    "height": 2379,
                                    "alt": "",
                                    "crop": {"x": 0, "y": 0, "width": 1846, "height": 1830}
                                }
                            }
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "0",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brw": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {"alpha-brd": "value", "brd": "value", "brw": "value", "shd": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.WPhoto",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 50,
                                "height": 50,
                                "x": 21,
                                "y": 518,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 285,
                            "height": 5,
                            "x": 18,
                            "y": 561,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {"type": "FiveGridLineProperties", "metaData": {"schemaVersion": "1.0"}, "fullScreenModeOn": false},
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#858585", "lnw": "1"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 280,
                                "height": 5,
                                "x": 21,
                                "y": 503,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 285,
                            "height": 60,
                            "x": 18,
                            "y": 343,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h4 class=\"font_8\" style=\"font-size: 22px; line-height: 1.4em;\"><span style=\"font-size:22px\"><span style=\"color:#EB8F72\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">Cardio with Christina</span></span></span></h4>\n\n<h4 class=\"font_8\" style=\"font-size: 22px; line-height: 1.4em;\"><span style=\"font-size:22px\"><span style=\"color:#EB8F72\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">9:00 am</span></span></span></h4>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h4 class=\"font_8\" style=\"font-size: 22px; line-height: 1.4em;\"><span style=\"font-size:22px\"><span style=\"color:#EB8F72\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">Cardio with Christina</span></span></span></h4>\n\n<h4 class=\"font_8\" style=\"font-size: 22px; line-height: 1.4em;\"><span style=\"font-size:22px\"><span style=\"color:#EB8F72\"><span style=\"font-family:clarendon-w01-medium-692107,clarendon-w02-medium-693834,serif\">9:00 am</span></span></span></h4>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 280,
                                "height": 52,
                                "x": 20,
                                "y": 324,
                                "scale": 0.8181818181818182,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 235,
                            "height": 20,
                            "x": 18,
                            "y": 310,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:16px\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">January 14, 2017</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size:16px\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">January 14, 2017</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 280,
                                "height": 20,
                                "x": 20,
                                "y": 299,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 277,
                            "height": 100,
                            "x": 18,
                            "y": 420,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size: 16px; line-height: 1.6em;\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">Tell people more about the classes you offer. Add images, text and links,&nbsp;</span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size: 16px; line-height: 1.6em;\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">or connect data from your collection&nbsp;</span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size: 16px; line-height: 1.6em;\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">to display dynamic content.</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size: 16px; line-height: 1.6em;\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">Tell people more about the classes you offer. Add images, text and links,&nbsp;</span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size: 16px; line-height: 1.6em;\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">or connect data from your collection&nbsp;</span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size: 16px; line-height: 1.6em;\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\"><span style=\"color:#545454\"><span style=\"font-size:16px\">to display dynamic content.</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 280,
                                "height": 100,
                                "x": 21,
                                "y": 383,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }],
                    "layout": {
                        "width": 315,
                        "height": 648,
                        "x": 664,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.MediaContainer",
                    "design": {
                        "type": "RepeatedData",
                        "original": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFFFFF",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0,
                                "showOverlayForMediaType": "WixVideo",
                                "mediaTransforms": {"scale": 1}
                            },
                            "cssStyle": {
                                "cssBorder": {
                                    "width": {
                                        "top": {"value": 0, "unit": "px"},
                                        "right": {"value": 0, "unit": "px"},
                                        "bottom": {"value": 0, "unit": "px"},
                                        "left": {"value": 0, "unit": "px"}
                                    },
                                    "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                    "color": {
                                        "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                    }
                                },
                                "cssBorderRadius": {
                                    "topLeft": {"value": 0, "unit": "px"},
                                    "topRight": {"value": 0, "unit": "px"},
                                    "bottomLeft": {"value": 0, "unit": "px"},
                                    "bottomRight": {"value": 0, "unit": "px"}
                                },
                                "cssBoxShadow": [{
                                    "inset": false,
                                    "offsetX": {"value": 0, "unit": "px"},
                                    "offsetY": {"value": 4, "unit": "px"},
                                    "blurRadius": {"value": 20, "unit": "px"},
                                    "spreadRadius": {"value": 0, "unit": "px"},
                                    "color": {"red": 0, "green": 0, "blue": 0, "alpha": 0.1}
                                }]
                            },
                            "themeMappings": {},
                            "charas": "design-j9sed6lg",
                            "dataChangeBehaviors": []
                        },
                        "overrides": {
                            "item1": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFFFFF",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0,
                                    "showOverlayForMediaType": "WixVideo",
                                    "mediaTransforms": {"scale": 1}
                                },
                                "cssStyle": {
                                    "cssBorder": {
                                        "width": {
                                            "top": {"value": 0, "unit": "px"},
                                            "right": {"value": 0, "unit": "px"},
                                            "bottom": {"value": 0, "unit": "px"},
                                            "left": {"value": 0, "unit": "px"}
                                        },
                                        "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                        "color": {
                                            "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                        }
                                    },
                                    "cssBorderRadius": {
                                        "topLeft": {"value": 0, "unit": "px"},
                                        "topRight": {"value": 0, "unit": "px"},
                                        "bottomLeft": {"value": 0, "unit": "px"},
                                        "bottomRight": {"value": 0, "unit": "px"}
                                    },
                                    "cssBoxShadow": [{
                                        "inset": false,
                                        "offsetX": {"value": 0, "unit": "px"},
                                        "offsetY": {"value": 4, "unit": "px"},
                                        "blurRadius": {"value": 20, "unit": "px"},
                                        "spreadRadius": {"value": 0, "unit": "px"},
                                        "color": {"red": 0, "green": 0, "blue": 0, "alpha": 0.1}
                                    }]
                                },
                                "themeMappings": {},
                                "charas": "design-j9sed6lg",
                                "dataChangeBehaviors": []
                            }
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.MediaContainer",
                        "type": "Container",
                        "conversionData": {"isCustomPreset": true},
                        "layout": {
                            "width": 320,
                            "height": 596,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    }
                }],
                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                "layout": {
                    "width": 325,
                    "height": 648,
                    "x": 0,
                    "y": 15804,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Repeater",
                "data": {
                    "type": "Repeater",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": ["item1"]
                },
                "props": {
                    "type": "CardsLayoutProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "alignment": "left",
                    "gap": {"vertical": 17, "horizontal": 17}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-brd": "0",
                            "bg": "61,155,233,1",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#324158",
                            "brw": "0px",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "bg": "value",
                            "brd": "value",
                            "brw": "value",
                            "rd": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "mobile.core.components.Container",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                },
                "mobileStructure": {
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "type": "RepeaterContainer",
                    "conversionData": {
                        "isCustomPreset": true,
                        "mobileProps": {
                            "type": "CardsLayoutProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "alignment": "left",
                            "gap": {"vertical": 20, "horizontal": 20}
                        }
                    },
                    "layout": {
                        "width": 320,
                        "height": 1828,
                        "x": 0,
                        "y": 25875,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    }
                },
                "activeModes": {},
                "id": "comp-j9sdzmre"
            },
            "preset": {"rect": {"width": 324, "height": 212, "x": 0, "y": 1628}}
        }, {
            "id": "Repeater_Repeaters_11",
            "structure": {
                "type": "RepeaterContainer",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 311,
                            "height": 388,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "",
                                "uri": "265db2f99b3143548289b5d053da3837.jpg",
                                "description": "",
                                "width": 2890,
                                "height": 3648,
                                "alt": "",
                                "crop": {"x": 578, "y": 635, "width": 1821, "height": 2406}
                            },
                            "overrides": {
                                "item1": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                    "title": "",
                                    "uri": "265db2f99b3143548289b5d053da3837.jpg",
                                    "description": "",
                                    "width": 2890,
                                    "height": 3648,
                                    "alt": "",
                                    "crop": {"x": 578, "y": 635, "width": 1821, "height": 2406}
                                }
                            }
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 320,
                                "height": 369,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 135,
                            "height": 34,
                            "x": 88,
                            "y": 369,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Fashion"
                            },
                            "overrides": {
                                "item1": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "Fashion"
                                }
                            }
                        },
                        "props": {"type": "ButtonProperties", "metaData": {"schemaVersion": "1.0"}, "align": "center", "margin": 0},
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "0",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#7ECCC5",
                                    "bgh": "#F2BF5E",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#545454",
                                    "brdh": "#F9F9F9",
                                    "brw": "0",
                                    "fnt": "normal normal normal 17px/1.4em times+new+roman",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                    "txt": "#FFFFFF",
                                    "txth": "#FFFFFF"
                                },
                                "propertiesSource": {
                                    "alpha-brdh": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 140,
                                "height": 34,
                                "x": 90,
                                "y": 351,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_65682bba59d04820a48bd42480e76fe5",
                        "layout": {
                            "width": 15,
                            "height": 15,
                            "x": 202,
                            "y": 594,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "data": {
                            "type": "RepeatedData",
                            "original": {"type": "SvgShape", "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}},
                            "overrides": {
                                "item1": {
                                    "type": "SvgShape",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                                }
                            }
                        },
                        "props": {"type": "SvgShapeProperties", "metaData": {"schemaVersion": "1.0"}, "maintainAspectRatio": true},
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-fillcolor": "1", "alpha-stroke": "1", "fillcolor": "#000000", "strokewidth": "0"},
                                "propertiesSource": {"fillcolor": "value", "stroke": "value", "strokewidth": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_65682bba59d04820a48bd42480e76fe5"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 15,
                                "height": 15,
                                "x": 197,
                                "y": 579,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 219,
                            "height": 91,
                            "x": 48,
                            "y": 493,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_4\" style=\"font-size: 15px; line-height: 1.6em; text-align: center;\"><span style=\"color:#545454\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">Make this yours. Add images, text and links, or connect data from your collection.</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_4\" style=\"font-size: 15px; line-height: 1.6em; text-align: center;\"><span style=\"color:#545454\"><span style=\"font-size:15px\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">Make this yours. Add images, text and links, or connect data from your collection.</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": false,
                            "minHeight": 91
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 220,
                                "height": 72,
                                "x": 50,
                                "y": 473,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 304,
                            "height": 36,
                            "x": 3,
                            "y": 444,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h4 class=\"font_4\" style=\"font-size:30px; text-align:center\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#000000\"><span style=\"font-size:30px\">Shoes to Shine</span></span></span></h4>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "overrides": {
                                "item1": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h4 class=\"font_4\" style=\"font-size:30px; text-align:center\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#000000\"><span style=\"font-size:30px\">Shoes to Shine</span></span></span></h4>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                }
                            }
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "f0": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f1": "normal normal normal 16px/1.4em din-next-w01-light #545454",
                                    "f10": "normal normal normal 12px/1.4em din-next-w01-light #545454",
                                    "f2": "normal normal normal 28px/1.4em proxima-n-w01-reg #858585",
                                    "f3": "normal normal normal 60px/1.4em proxima-n-w01-reg #858585",
                                    "f4": "normal normal normal 40px/1.4em proxima-n-w01-reg #858585",
                                    "f5": "normal normal normal 25px/1.4em proxima-n-w01-reg #858585",
                                    "f6": "normal normal normal 22px/1.4em proxima-n-w01-reg #858585",
                                    "f7": "normal normal normal 17px/1.4em proxima-n-w01-reg #858585",
                                    "f8": "normal normal normal 15px/1.4em helvetica-w01-roman #858585",
                                    "f9": "normal normal normal 14px/1.4em proxima-n-w01-reg #858585"
                                },
                                "propertiesSource": {
                                    "f0": "value",
                                    "f1": "value",
                                    "f10": "value",
                                    "f2": "value",
                                    "f3": "value",
                                    "f4": "value",
                                    "f5": "value",
                                    "f6": "value",
                                    "f7": "value",
                                    "f8": "value",
                                    "f9": "value"
                                }
                            },
                            "componentClassName": "",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "type": "Component",
                            "conversionData": {
                                "isCustomPreset": true,
                                "mobileProps": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                }
                            },
                            "layout": {
                                "width": 300,
                                "height": 36,
                                "x": 10,
                                "y": 425,
                                "scale": 1.0944114398437503,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                        "layout": {
                            "width": 98,
                            "height": 24,
                            "x": 101,
                            "y": 588,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "RepeatedData",
                            "original": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Read More"
                            },
                            "overrides": {
                                "item1": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "Read More"
                                }
                            }
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "fnt": "normal normal normal 17px/1.4em times+new+roman",
                                    "txt": "#000000",
                                    "txth": "#787878"
                                },
                                "propertiesSource": {"fnt": "value", "txt": "value", "txth": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                        },
                        "mobileStructure": {
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "type": "Component",
                            "conversionData": {"isCustomPreset": true},
                            "layout": {
                                "width": 98,
                                "height": 24,
                                "x": 100,
                                "y": 573,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    }],
                    "layout": {
                        "width": 311,
                        "height": 650,
                        "x": 334,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.MediaContainer",
                    "design": {
                        "type": "RepeatedData",
                        "original": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFFFFF",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0,
                                "showOverlayForMediaType": "WixVideo"
                            },
                            "cssStyle": {
                                "cssBorder": {
                                    "width": {
                                        "top": {"value": 0, "unit": "px"},
                                        "right": {"value": 0, "unit": "px"},
                                        "bottom": {"value": 0, "unit": "px"},
                                        "left": {"value": 0, "unit": "px"}
                                    },
                                    "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                    "color": {
                                        "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                        "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                    }
                                },
                                "cssBorderRadius": {
                                    "topLeft": {"value": 0, "unit": "px"},
                                    "topRight": {"value": 0, "unit": "px"},
                                    "bottomLeft": {"value": 0, "unit": "px"},
                                    "bottomRight": {"value": 0, "unit": "px"}
                                }
                            },
                            "themeMappings": {},
                            "charas": "design-jab3cj9d",
                            "dataChangeBehaviors": []
                        },
                        "overrides": {
                            "item1": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "color": "#FFFFFF",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0,
                                    "showOverlayForMediaType": "WixVideo"
                                },
                                "cssStyle": {
                                    "cssBorder": {
                                        "width": {
                                            "top": {"value": 0, "unit": "px"},
                                            "right": {"value": 0, "unit": "px"},
                                            "bottom": {"value": 0, "unit": "px"},
                                            "left": {"value": 0, "unit": "px"}
                                        },
                                        "style": {"top": "solid", "right": "solid", "left": "solid", "bottom": "solid"},
                                        "color": {
                                            "top": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "right": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "left": {"red": 176, "green": 169, "blue": 134, "alpha": 1},
                                            "bottom": {"red": 176, "green": 169, "blue": 134, "alpha": 1}
                                        }
                                    },
                                    "cssBorderRadius": {
                                        "topLeft": {"value": 0, "unit": "px"},
                                        "topRight": {"value": 0, "unit": "px"},
                                        "bottomLeft": {"value": 0, "unit": "px"},
                                        "bottomRight": {"value": 0, "unit": "px"}
                                    }
                                },
                                "themeMappings": {},
                                "charas": "design-jab3cj9d",
                                "dataChangeBehaviors": []
                            }
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.MediaContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
                    },
                    "mobileStructure": {
                        "componentType": "wysiwyg.viewer.components.MediaContainer",
                        "type": "Container",
                        "conversionData": {"isCustomPreset": true},
                        "layout": {
                            "width": 320,
                            "height": 628,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        }
                    }
                }],
                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                "layout": {
                    "width": 321,
                    "height": 650,
                    "x": 0,
                    "y": 9983,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Repeater",
                "data": {
                    "type": "Repeater",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": ["item1"]
                },
                "props": {
                    "type": "CardsLayoutProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "alignment": "left",
                    "gap": {"vertical": 23, "horizontal": 23}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-brd": "0",
                            "bg": "61,155,233,1",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#324158",
                            "brw": "0px",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "bg": "value",
                            "brd": "value",
                            "brw": "value",
                            "rd": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "mobile.core.components.Container",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                },
                "mobileStructure": {
                    "componentType": "wysiwyg.viewer.components.Repeater",
                    "type": "RepeaterContainer",
                    "conversionData": {"isCustomPreset": true},
                    "layout": {
                        "width": 320,
                        "height": 1930,
                        "x": 0,
                        "y": 16409,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    }
                },
                "activeModes": {},
                "id": "comp-j9r85mb7"
            },
            "preset": {"rect": {"width": 324, "height": 193, "x": 0, "y": 1840}}
        }],
        "compTypes": [
            "wysiwyg.viewer.components.Repeater"
        ]
    },
    "help": {
        "hide": false,
        "text": "add_section_label_listsandgrid_layouts_tooltip_body"
    }
}
