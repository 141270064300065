//@ts-nocheck
import _ from 'lodash';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import * as superAppMenuInfra from '@/superAppMenuInfra';
import * as tpaUtils from '../utils/tpaUtils';
import * as appMarketTabService from '../appMarket/services/appMarketTabService';
import constants from '../constants/constants';
import section from '../addPanelData/section';
import * as bi from '../bi/bi';
import superAppsConstants from './superAppsConstants';
import * as tpaAddRemoveDSService from '../services/tpaAddRemoveDSService';
import * as packagePicker from '../packagePicker/packagePicker';
import * as tpaHelpService from '../services/tpaHelpService';
import * as tpaStyleService from '../services/tpaStyleService';
import * as tpaAlertsService from '../services/tpaAlertsService';
import * as dashboardAppService from '../services/dashboardAppService';
import * as tpaSettingsModalService from '../services/tpaSettingsModalService';
import * as tpaAppInstallingService from '../services/tpaAppInstallingService';
import { getAppType } from '../appMarket/utils/appMarketUtils';
import { platformBI, PlatformOrigin } from '@/platform';
import {
  reportBiForAddedComponentToStage,
  ReportBiForAddedComponentToStageOptions,
} from '../utils/tpaUtils';

import type { EditorAPI } from '@/editorAPI';
import type {
  PlatformAppDescription,
  CompLayout,
  CompRef,
  StyleRefOrStyleRefs,
  CompStructure,
} from 'types/documentServices';
import tpaConstants from '../../tpa/constants/constants';

let editorAPI: EditorAPI;

const WIDGET_OPTIONS = [
  'dontStretch',
  'styleId',
  'parentContainerRef',
  'containerRef',
  'presetId',
  'platformOrigin',
  'category',
  'section',
  'addingMethod',
  'origin',
  'componentName',
  'componentDefinition',
  'useRelativeToContainerLayout',
];

const relatedAppsConsts = {
  store: {
    category: 'store',
    categoryNum: {
      pre: '1153',
      post: '1039',
    },
  },
  bookings: {
    category: 'bookings',
    categoryNum: {
      pre: '2589',
      post: '2589',
    },
  },
};

const addPanelCategories = ['store'];

const init = function (_editorAPI) {
  editorAPI = _editorAPI;
};

const isPremium = function (appDefinitionId) {
  return editorAPI.dsRead.tpa.app.isPremiumByAppDefinitionId(appDefinitionId);
};

const getExtensions = function (appDefinitionId, callback) {
  if (callback && appDefinitionId) {
    editorAPI.dsRead.tpa.appMarket
      .getDataAsync(appDefinitionId)
      .then(function (data) {
        callback(editorAPI.dsRead.tpa.app.getExtensionsWidgets(data));
      });
  }
};

function sendTpaAddedBiEvent(appData) {
  const compData = editorAPI.selection.getSelectedComponentData();
  const compId = editorAPI.selection.getSelectedComponentId();
  const pageId = editorAPI.dsRead.pages.getFocusedPageId();
  const isPermissionsApp = appData.by !== 'Wix';
  tpaUtils.bi.reportTpaAddedBiEvent(
    editorAPI,
    appData.appDefinitionId,
    compData?.widgetId,
    pageId,
    compId,
    true,
    isPermissionsApp,
    {
      origin: 'template_provision',
      adding_method: 'template',
    },
  );
}

const openDashboardUrl = function (appDefinitionId, options?, biParams?) {
  options = options || {};

  const type = biParams?.type || constants.BI.type.GFPP_MANAGE;
  fireAppIntentBI(type, appDefinitionId);
  const appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId);
  const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
  if (appData) {
    if (appData.notProvisioned || appData.demoMode || isDraftMode) {
      const onSuccess = function () {
        options.bi = tpaUtils.bi.getAppIntentBiParamsToAddToUrl(
          editorAPI,
          appDefinitionId,
          type,
        );
        sendTpaAddedBiEvent(appData);
        openDashboardUrlAfterProvision(appData, options);
      };
      const onError = _.partial(
        tpaAlertsService.openSaveFailedAlertForDashboardUrl,
        editorAPI,
        appData.appDefinitionId,
      );
      editorAPI.saveManager.saveInBackground(
        onSuccess,
        onError,
        'openDashboardUrl',
        {
          sourceOfStart: 'openDashboardUrl_bgSave',
        },
      );
    } else {
      openDashboardUrlAfterProvision(appData, options);
    }
  }
};

const openDashboardUrlAfterProvision = function (appData, params = {}) {
  const settingModalName = constants.TPA_SETTING_MODAL;
  const modal = tpaUtils.getOpenedPanel(
    editorAPI.panelManager,
    settingModalName,
  );
  if (modal) {
    editorAPI.panelManager.closePanelByName(modal.props.panelName);
  }

  const compId = util.guidUtils.getUniqueId();

  const modalProps = {
    title: params.title || '',
    isBareMode: params.isBareMode || false,
    options: params.options,
    compId,
  };

  const dashboardUrl = dashboardAppService.getDashboardUrl(
    editorAPI,
    appData,
    compId,
    params,
  );
  if (dashboardUrl) {
    editorAPI.store.dispatch(
      stateManagement.tpa.actions.addAppDefIdsToRefreshOnDashboardClose([
        appData.appDefinitionId,
      ]),
    );
    modalProps.isBareMode = true;
    modalProps.width = '100%';
    modalProps.height = '100%';
    modalProps.url = dashboardUrl;
    modalProps.onClose = _.partial(refreshVisitedApps, params.onClose);
    modalProps.shouldNotCloseOnBlur = params.shouldNotCloseOnBlur;
    const leavePanelsOpen = params.leavePanelsOpen || false;
    tpaHelpService
      .getHelpId(
        editorAPI,
        appData.appDefinitionId,
        params.widgetId,
        tpaHelpService.HELP_END_POINTS.SETTINGS_MODAL,
      )
      .then(function (helpId) {
        modalProps.appDefinitionId = appData.appDefinitionId;
        modalProps.helpId = helpId;
        modalProps.widgetId = params.widgetId;
        if (params.widgetless) {
          editorAPI.panelManager.openPanel(
            settingModalName,
            modalProps,
            leavePanelsOpen,
          );
        } else {
          tpaUtils.selectComponentAndNavigateIfNeeded(
            editorAPI,
            { ...appData, compId },
            function () {
              editorAPI.panelManager.openPanel(
                settingModalName,
                modalProps,
                leavePanelsOpen,
              );
            },
          );
        }
      });
  }
};

const openTranslationDashboardUrl = function (
  appDefinitionId,
  options = {},
  biParams,
) {
  options.isTranslationDashboard = true;
  return openDashboardUrl(appDefinitionId, options, biParams);
};

const refreshVisitedApps = function (onClose) {
  const editorState = editorAPI.store.getState();
  const appIdsToRefresh =
    stateManagement.tpa.selectors.getAppIdsToRefreshAfterDashboardClose(
      editorState,
    );
  const shouldRefreshGeneralInfo =
    stateManagement.tpa.selectors.getShouldRefreshGeneralInfo(editorState);
  refreshAppComps(appIdsToRefresh);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const appDefinitionIds = _.map(
    appIdsToRefresh,
    (applicationId) =>
      editorAPI.dsRead.platform.getAppDataByApplicationId(applicationId)
        ?.appDefinitionId,
  );
  refreshAppsLivePreview(appDefinitionIds);
  editorAPI.store.dispatch(
    stateManagement.tpa.actions.setAppDefIdsToRefreshOnDashboardClose([]),
  );
  if (shouldRefreshGeneralInfo) {
    editorAPI.documentServices.generalInfo.sitePropertiesInfo.reload();
    editorAPI.store.dispatch(
      stateManagement.tpa.actions.shouldRefreshGeneralInfo(false),
    );
  }
  if (onClose) {
    onClose();
  }
};

const biOriginKeys = {
  [superAppsConstants.BLOG.APP_DEF_ID]: 'blog_button',
  [superAppsConstants.ECOM.APP_DEF_ID]: 'ecom_button',
};

const openAppMarketForAppDef = function (appDefId) {
  const appMarketPanelProps = {
    urlParams: {
      openMarketOrigin: biOriginKeys[appDefId],
      openAppDefId: appDefId,
    },
  };
  appMarketTabService.openAppMarketTab(
    appMarketPanelProps,
    editorAPI.panelManager.openPanel,
  );
};

const refreshAppCompsForAppDefId = function (appDefinitionId) {
  const appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId);
  refreshAppComps([appData.applicationId]);
  refreshAppsLivePreview([appDefinitionId]);
};

const refreshAppComps = function (applicationIds) {
  const appComps =
    editorAPI.dsRead.tpa.app.getRenderedReactCompsByApplicationId(
      applicationIds,
    );
  editorAPI.dsActions.tpa.app.refreshApp(appComps);
};

const refreshAppsLivePreview = function (appDefinitionIds) {
  editorAPI.dsActions.platform.livePreview.refresh({
    apps: appDefinitionIds,
    source: 'editorRefreshAppComps',
  });
};

const registerOnAppInstalled = function (appDefinitionId, callback) {
  editorAPI.dsRead.tpa.app.registerOnInstalled(appDefinitionId, callback);
};

const registerOnAppDeleted = function (appDefinitionId, callback) {
  editorAPI.dsRead.tpa.app.registerOnDeleted(appDefinitionId, callback);
};

//multi multi API
const addSection = async function (
  appDefinitionId: string,
  widgetId,
  origin?: string,
) {
  const appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId);
  const widgetData = appData.widgets[widgetId];
  if (
    widgetData &&
    tpaUtils.isMultiSectionInstanceEnabled(appData, widgetData.widgetId)
  ) {
    return new Promise((resolve) => {
      const callback = function (obj) {
        editorAPI.pages.navigateTo(obj.page.id, () => {
          resolve();
        });
        sendSectionBiEvent(appData, obj, widgetData.widgetId, origin);
      };

      tpaUtils.bi.reportBIOnAppIntent(
        editorAPI,
        bi.events.APP_INTENT,
        appDefinitionId,
        constants.BI.initiator.EDITOR,
        constants.BI.type.ADD_WIDGET,
      );
      tpaAddRemoveDSService.addMultiSection(
        editorAPI,
        appData.appDefinitionId,
        {
          pageId: widgetData.appPage.id,
          callback,
        },
      );
    });
  }
};

const addApp = function (
  appDefId,
  options: {
    callback?: Function;
    biOrigin: string;
    showProgressBar?: boolean;
    platformOrigin: PlatformOrigin;
    isPreInstallation?: boolean;
  } = {
    showProgressBar: true,
    isPreInstallation: false,
  },
) {
  if (!appDefId) return;
  const errorForFailure = new Error('Error while installing -');
  const origin = options.biOrigin || constants.BI.type.ADD_APP;
  let appName;
  if (!options.isPreInstallation) {
    tpaUtils.bi.reportBIOnAppIntent(
      editorAPI,
      bi.events.APP_INTENT,
      appDefId,
      constants.BI.initiator.EDITOR,
      origin,
    );
  }

  if (options.platformOrigin) {
    options.origin = options.platformOrigin;
    delete options.platformOrigin;
  }

  return editorAPI.dsRead.tpa.appMarket
    .getDataAsync(appDefId)
    .then(function (marketData) {
      appName = marketData?.name;
      errorForFailure.message += appName;
      tpaAppInstallingService.prePageAppInstalling(
        editorAPI,
        appName,
        appDefId,
        {
          origin,
          showProgressBar: options.showProgressBar,
          showPageAddedPanel: true,
        },
      );
      platformBI.reportBIOnPlatformTryToInstall(editorAPI, {
        app_id: appDefId,
        app_name: appName,
        origin: options?.origin?.info?.type,
      });

      const callback = options.callback || _.noop;
      options.callback = function (data) {
        editorAPI.tpa.notifyAppInstalled(appDefId, {
          platformOrigin: options.origin,
        });
        platformBI.reportBIOnPlatformAppAdded(editorAPI, {
          app_id: appDefId,
          app_name: appName,
          origin: options?.origin?.info?.type,
        });
        tpaAppInstallingService.onPageAppInstallingCompleted(
          editorAPI,
          appName,
          appDefId,
          data,
          {
            origin,
          },
        );
        callback(data);
      };
      const type = getAppType(marketData);
      switch (type) {
        case tpaConstants.APP.TYPE.PLATFORM_ONLY:
          tpaAddRemoveDSService.addPlatform(editorAPI, appDefId, options);
          break;
        case tpaConstants.APP.TYPE.SECTION:
          tpaAddRemoveDSService.addSection(editorAPI, appDefId, options);
          break;
        case tpaConstants.APP.TYPE.WIDGET:
          tpaAddRemoveDSService.addWidget(editorAPI, appDefId, options);
          break;
      }
    })
    .catch(function (e = {}) {
      if (!e.stack) {
        errorForFailure.message += `${e.message} ${errorForFailure.message}`;
        ErrorReporter.captureException(errorForFailure, {
          tags: { tpaInstallation: appName, origin },
        });
      } else {
        ErrorReporter.captureException(e, {
          tags: { tpaInstallation: appName, origin },
        });
      }
      options.callback(e);
    });
};

const onRefWidgetClick = function (appDefinitionId, panelName, event) {
  addRefWidget(appDefinitionId, panelName, event.data, event.layout);
};

const onRefWidgetDrop = (appDefinitionId, panelName, position, event) => {
  position.width = event.structure.layout.width;
  position.height = event.structure.layout.height;
  addRefWidget(appDefinitionId, panelName, event.structure.data, position);
};

const addRefWidget = function (appDefinitionId, panelName, widgetData, layout) {
  const page = editorAPI.pages.getFocusedPage();
  const addPosition = editorAPI.pasteLogic.addPanelPasteLogic.getPastePosition(
    editorAPI,
    layout,
    page.id,
  );

  const componentDef = {
    componentType: 'wysiwyg.viewer.components.RefComponent',
    layout: _.defaults(addPosition, layout),
    style: 'ref1',
    data: {
      type: 'WidgetRef',
      appDefinitionId,
      widgetId: widgetData.widgetId,
      variationId: widgetData.variationId,
    },
  };

  editorAPI.components.add(
    page,
    componentDef,
    undefined,
    undefined,
    constants.BI.type.ADD_WIDGET,
  );
  editorAPI.panelManager.closePanelByName(panelName);
};

interface AddAppBuilderWidgetToSiteOptions {
  biOptions: Omit<
    ReportBiForAddedComponentToStageOptions,
    'appDefId' | 'origin'
  >;
  containerRef?: CompRef;
}

function addAppBuilderWidgetToSite(
  appDefinitionId,
  widgetData,
  layout,
  overriddenData,
  options: AddAppBuilderWidgetToSiteOptions = {},
): Promise<CompRef> {
  const installationType = widgetData.installationType || 'closed';
  const biOptions = options?.biOptions || {};

  return new Promise((resolve) => {
    editorAPI.dsActions.appStudioWidgets.addWidget(
      appDefinitionId,
      widgetData.widgetId,
      {
        variationPageId: widgetData.variationId,
        layout,
        installationType,
        overriddenData,
        scopedPresets: widgetData.scopedPresets,
        containerRef: options.containerRef,
        onSuccess: (newWidgetRef) => {
          editorAPI.selection.selectComponentByCompRef(newWidgetRef);
          editorAPI.history.add('Add widget', { isAddingComponent: true });
          const appData =
            editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId);
          sendAddWidgetBiEvent(appData, newWidgetRef, widgetData.widgetId);

          if (newWidgetRef && editorAPI.components.is.exist(newWidgetRef)) {
            reportBiForAddedComponentToStage(editorAPI, newWidgetRef, {
              ...biOptions,
              appDefId: appData.appDefinitionId,
              origin: constants.BI.type.ADD_WIDGET,
            });
          }

          resolve(newWidgetRef);
        },
      },
    );
  });
}
export type AddWidgetBIParams = tpaAddRemoveDSService.AddWidgetBIParams;

export type AddWidgetOptions = {
  presetId?: string;
  biOrigin?: string;
  platformOrigin: PlatformOrigin;
  useRelativeToContainerLayout?: boolean;
  dontStretch?: boolean;
  componentName?: string;
  styleId?: string | StyleRefOrStyleRefs;
  parentContainerRef?: CompRef;
  containerRef?: CompRef;
  componentDefinition?: CompStructure;
  cb?: (compRef: CompRef) => void;
} & Partial<AddWidgetBIParams>;

const addWidget = async function (
  appDefinitionId: string,
  widgetId: string,
  position: Partial<CompLayout>,
  options: AddWidgetOptions = {},
) {
  tpaUtils.bi.reportBIOnAppIntent(
    editorAPI,
    bi.events.APP_INTENT,
    appDefinitionId,
    constants.BI.initiator.EDITOR,
    options.biOrigin || constants.BI.type.ADD_APP,
  );

  const widgetOptionsWithLayout = getAddWidgetOptions(
    widgetId,
    position,
    options,
    appDefinitionId,
  );

  return tpaAddRemoveDSService.addWidget(
    editorAPI,
    appDefinitionId,
    widgetOptionsWithLayout,
  );
};

const getAddWidgetOptions = function (
  widgetId: string,
  position: Partial<CompLayout>,
  options: AddWidgetOptions,
  appDefinitionId,
): tpaAddRemoveDSService.AddWidgetOptions {
  const widgetOptionsWithLayout = _.merge(
    { widgetId, layout: position },
    _.pick(options, WIDGET_OPTIONS),
  );

  if (util.sections.isSectionsEnabled()) {
    if (options.addingMethod === 'click') {
      const sectionLikeContainerToPaste =
        util.sections.getSectionLikeContainerToPaste(editorAPI, []);
      widgetOptionsWithLayout.containerRef = sectionLikeContainerToPaste;
      widgetOptionsWithLayout.useRelativeToContainerLayout =
        widgetOptionsWithLayout.useRelativeToContainerLayout ?? true;
    } else if (options.containerRef) {
      widgetOptionsWithLayout.useRelativeToContainerLayout =
        widgetOptionsWithLayout.useRelativeToContainerLayout ?? true;
    }
  }

  widgetOptionsWithLayout.callback = function (obj) {
    editorAPI.bi.tryFlushingConditionedEvents();
    const appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId);
    sendAddWidgetBiEvent(appData, obj.comp, widgetId, options?.presetId);
    if (options?.cb) {
      options.cb(obj.comp);
    }
  };
  return widgetOptionsWithLayout;
};

const setStyleParam = function (appDefId, compId, options) {
  tpaStyleService.setStyleParam(editorAPI, compId, options);
};

const isInstalled = function (appDefinitionId) {
  const installed = editorAPI.dsRead.tpa.app.isInstalled(appDefinitionId);
  if (installed) {
    editorAPI.dsRead.tpa.appMarket.getData(appDefinitionId);
  }
  return installed;
};

const getFirstAppCompPageId = function (appDefinitionId) {
  return editorAPI.dsRead.tpa.app.getFirstAppCompPageId(appDefinitionId);
};

const sendAddWidgetBiEvent = function (appData, compRef, widgetId, presetId) {
  const params = {
    app_id: appData.appDefinitionId,
    app_site_id: appData.instanceId,
    instance_id: compRef.id,
    widget_id: widgetId,
    page_id: compRef.pageId,
    preset_id: presetId,
  };
  editorAPI.bi.event(bi.events.EXTENSION_ADDED, params);
};

const sendSectionBiEvent = function (
  appData,
  sectionObj,
  widgetId,
  origin?: string,
) {
  const params = {
    app_id: appData.appDefinitionId,
    app_site_id: appData.instanceId,
    instance_id: sectionObj.sectionId,
    widget_id: widgetId,
    page_id: sectionObj.page.id,
    origin,
  };
  editorAPI.bi.event(bi.events.EXTENSION_ADDED, params);
};

const getRelatedApps = function (categoryId, categoryNum) {
  return section.getSectionStructure(
    categoryId,
    categoryNum,
    'vertical_button',
  );
};

const getSectionRefByPageId = function (pageId) {
  return editorAPI.dsRead.tpa.section.getSectionRefByPageId(pageId);
};

const upgrade = function (appDefinitionId, external, origin) {
  fireAppIntentBI(constants.BI.type.APP_UPGRADE_BUTTON, appDefinitionId);
  packagePicker.startUpgradeFlow(editorAPI, appDefinitionId, false, {
    origin,
  });
};

const hasPremiumOffering = function (appDefinitionId) {
  const appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId);
  if (appData) {
    return editorAPI.dsRead.tpa.app.hasPremiumOffering(appData.applicationId);
  }
  return false;
};

const openSuperAppPanel = function (
  superAppId,
  isAppInstalled,
  panelName,
  buttonLabel,
  buttonPromotionalLabel,
  panelClassName,
  symbolName,
) {
  superAppMenuInfra.button.registerButton(
    editorAPI,
    superAppId,
    isAppInstalled,
    panelName,
    buttonLabel,
    buttonPromotionalLabel,
    panelClassName,
    symbolName,
    true,
  );
  editorAPI.panelManager.openPanel(panelName, {
    appWasManuallyInstalled: true,
  });
};

const openSettingsOpenModal = function (params, selectedComponents) {
  tpaSettingsModalService.settingsOpenModal(
    editorAPI,
    {
      data: _.omit(params, 'autoProvision'),
    },
    _.noop,
    selectedComponents,
  );
};

const openSuperAppsModal = function (params, _editorAPI, selectedComponents) {
  params = params || {};
  const appDefId =
    params.appDefinitionId || getApplicationDefinitionIdFromSelectedComp();
  fireAppIntentBI(constants.BI.type.APP_MANAGE_BUTTON, appDefId);
  params.bi = tpaUtils.bi.getAppIntentBiParamsToAddToUrl(
    editorAPI,
    appDefId,
    constants.BI.type.APP_MANAGE_BUTTON,
  );

  if (params?.autoProvision) {
    const appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefId);
    const isDraft = editorAPI.dsRead.generalInfo.isDraft();
    if (appData) {
      if (appData.notProvisioned || appData.demoMode || isDraft) {
        editorAPI.saveManager.saveInBackground(
          _.partial(openSettingsOpenModal, params),
          _.partial(
            tpaAlertsService.openSaveFailedAlertForDashboardUrl,
            editorAPI,
            appData.appDefinitionId,
          ),
          'openSuperAppsModal',
          {
            sourceOfStart: 'openSuperAppsModal_bgSave',
          },
        );
      } else {
        openSettingsOpenModal(params, selectedComponents);
      }
    }
  } else {
    openSettingsOpenModal(params, selectedComponents);
  }
};

const fireAppIntentBI = function (type, appDefId) {
  tpaUtils.bi.reportBIOnAppIntent(
    editorAPI,
    bi.events.APP_INTENT,
    appDefId,
    constants.BI.initiator.EDITOR,
    type,
  );
};

const getApplicationDefinitionIdFromSelectedComp = function () {
  const compData = editorAPI.selection.getSelectedComponentData();
  const appData = editorAPI.dsRead.tpa.app.getData(compData?.applicationId);
  return appData?.appDefinitionId;
};

const relatedApps = {
  addPanelCategories,
  constants: relatedAppsConsts,
  getRelatedAppsForPostInstall: getRelatedApps,
  getRelatedAppsForPreInstall: getRelatedApps,
};

const waitForAppCompleteInstallation = (
  { store, dsRead }: EditorAPI,
  appDefId: string,
) => {
  const findInstalledApp = () =>
    dsRead.platform
      .getInstalledEditorApps()
      .find((app) => app.appDefinitionId === appDefId);
  const hasDescriptor = () =>
    Boolean(dsRead.platform.getAppDescriptor(appDefId));
  const hasAppBeenCompletelyInstalled = () =>
    hasDescriptor() && findInstalledApp();

  return new Promise<PlatformAppDescription>((resolve) => {
    if (hasAppBeenCompletelyInstalled()) {
      resolve(findInstalledApp());
    } else {
      const unsubscribe = store.subscribe(() => {
        if (hasAppBeenCompletelyInstalled()) {
          unsubscribe();

          resolve(findInstalledApp());
        }
      });
    }
  });
};

export {
  init,
  isPremium,
  getExtensions,
  openDashboardUrl,
  openTranslationDashboardUrl,
  openAppMarketForAppDef,
  refreshAppCompsForAppDefId,
  registerOnAppInstalled,
  registerOnAppDeleted,
  addApp,
  addWidget,
  addSection,
  superAppsConstants,
  isInstalled,
  getFirstAppCompPageId,
  relatedApps,
  getSectionRefByPageId,
  upgrade,
  hasPremiumOffering,
  openSuperAppPanel,
  openSuperAppsModal,
  setStyleParam,
  onRefWidgetDrop,
  onRefWidgetClick,
  addAppBuilderWidgetToSite,
  waitForAppCompleteInstallation,
};
