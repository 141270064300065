import React from 'react';

const installedVersionIndication = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.967 10.8464L19.662 9.1514C19.878 9.8984 20 10.6844 20 11.5004C20 16.1874 16.186 20.0004 11.5 20.0004C6.813 20.0004 3 16.1874 3 11.5004C3 6.8134 6.813 3.0004 11.5 3.0004C13.16 3.0004 14.706 3.4854 16.016 4.3124L14.559 5.7684C13.646 5.2794 12.605 5.0004 11.5 5.0004C7.916 5.0004 5 7.9164 5 11.5004C5 15.0844 7.916 18.0004 11.5 18.0004C15.084 18.0004 18 15.0844 18 11.5004C18 11.2794 17.988 11.0614 17.967 10.8464ZM7.583 10.9882L8.997 9.5752L11.287 11.8672L18.583 4.5752L19.997 5.9892L11.287 14.6962L7.583 10.9882Z"
        fill="#1F8C4D"
      />
    </svg>
  );
};

export default installedVersionIndication;
