// @ts-nocheck
import constants from '@/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_vkshare',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
    },
  ],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId: '40cee72e-abde-4f5b-af76-0fc566c447d3',
    },
  },
};
