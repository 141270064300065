import colorPickerPanel from './colorPickerPanel/colorPickerPanel';
import colorPickerWithGradientsPanel from './colorPickerPanelWithGradient/colorPickerPanel';
import addColorPanel from './addColorPanel';
import addColorContent from './addColorContent';
import replaceColorPanel from './replaceColorPanel/replaceColorPanel';

const colorPickerWithGradientsPanelInternal = colorPickerWithGradientsPanel;

export {
  colorPickerPanel,
  colorPickerWithGradientsPanel,
  colorPickerWithGradientsPanelInternal,
  addColorPanel,
  addColorContent,
  replaceColorPanel,
};
