import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ToggleSwitch } from '@wix/wix-base-ui';
import * as coreBi from '@/coreBi';
import * as BaseUI from '@/baseUI';
import constants from '@/constants';

import type { CompRef } from 'types/documentServices';

//TYPE WAS GENERATED, remove this line when reviewed
interface ShowOnAllPagesProps {
  selectedComponents?: Array<CompRef>;
  selectedComponentsTypes?: Array<string>;
  isComponentShowOnAllPages?: boolean;
  disabled?: boolean;
  isPage?: boolean;
  isToggleAllowed: boolean;
  toggleShowOnAllPages?: () => void;
  reportBI?: FunctionFixMe;
  origin?: string;
  labelType?: string;
  label?: string;
  className?: string;
  widthByTextLength?: boolean;
}

class ShowOnAllPages extends React.Component<ShowOnAllPagesProps> {
  static displayName = 'showOnAllPages';

  static propTypes = {
    selectedComponents: PropTypes.arrayOf(PropTypes.object),
    selectedComponentsTypes: PropTypes.arrayOf(PropTypes.string),
    isComponentShowOnAllPages: PropTypes.bool,
    disabled: PropTypes.bool,
    isPage: PropTypes.bool,
    componentsRestrictions: PropTypes.object,
    toggleShowOnAllPages: PropTypes.func,
    reportBI: PropTypes.func,
    origin: PropTypes.string,
  };

  shouldHideTooltip = () => {
    return (
      !this.props.selectedComponents ||
      !this.props.disabled ||
      this.props.isPage
    );
  };

  handleShowOnAllPages = () => {
    const { isToggleAllowed } = this.props;
    if (this.props.selectedComponents && isToggleAllowed) {
      this.props.toggleShowOnAllPages();

      this.props.reportBI(coreBi.events.rightClickMenu.SHOW_ON_ALL_PAGES, {
        new_status: !this.props.isComponentShowOnAllPages,
        origin: this.props.origin,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/map
        component_id: _.toString(_.map(this.props.selectedComponents, 'id')),
        component_type: _.toString(this.props.selectedComponentsTypes),
      });
    }
  };

  render() {
    return (
      <label className="row show-on-all-pages-row">
        <BaseUI.tooltip
          disabled={this.shouldHideTooltip()}
          value="RightClick_Menu_Show_on_All_Pages_Disabled_Tooltip"
          styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
          delay="600"
        >
          <ToggleSwitch
            disabled={this.props.disabled}
            value={this.props.isComponentShowOnAllPages}
            onChange={this.handleShowOnAllPages}
            size="MEDIUM"
            // @ts-expect-error
            labelType={this.props.labelType}
            widthByTextLength={this.props.widthByTextLength}
            label={this.props.label}
            className={`show-on-all-pages emphasise ${this.props.className}`}
          />
        </BaseUI.tooltip>
      </label>
    );
  }
}

export default ShowOnAllPages;
