// @ts-nocheck
import _ from 'lodash';
import * as textControls from '@/textControls';
import * as util from '@/util';
import coreUtilsLib from 'coreUtilsLib';
import type { EditorAPI } from '@/editorAPI';

const { blogAppPartNames } = coreUtilsLib;

/**
 * @param editorAPI
 * @param blogManagerAppPathType
 * @returns {string}
 */
function getFrameUrl(editorAPI, blogManagerAppPathType, referralInfo, compId) {
  let baseUrl = util.serviceTopology.htmlEditorRootUrl;
  baseUrl = baseUrl
    ? baseUrl.replace(/html\/editor\/?/, 'wix-blog/')
    : '//editor.wix.com/wix-blog/';
  const urlObj = util.url.parseUrl(window.location.href);

  const blogManagerSpecificQueryParams = {
    // storeId: editorAPI.dsRead.wixapps.classics.account.getBlogInstanceId(), //account was removed from dm
    previewPageId: getPreviewPageId(editorAPI),
    lang: util.languages.getLanguageCode(),
    metaSiteId: editorAPI.dsRead.generalInfo.getMetaSiteId(),
    siteId: editorAPI.dsRead.generalInfo.getSiteId(),
    referralInfo,
    debug: _.has(urlObj, 'query.debug') ? 'true' : undefined,
    isqa: urlObj?.query?.isqa === 'true',
    origin: 'editor',
  };

  const mainQueryParamsWithoutPetriOvr = _.omit(urlObj.query, 'petri_ovr');

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  const queryParamsObj = _({})
    .assign(mainQueryParamsWithoutPetriOvr, blogManagerSpecificQueryParams)
    .pickBy()
    .value();

  const queryStringPart = `?${util.url.toQueryString(queryParamsObj)}`;
  const petri_ovrPart = _.has(urlObj.query, 'petri_ovr')
    ? `&petri_ovr=${urlObj.query.petri_ovr}`
    : '';
  let postId = '';

  if (compId) {
    postId = editorAPI.dsRead.wixapps.classics.getSinglePostId(compId);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array, you-dont-need-lodash-underscore/includes
    if (_.isArray(postId) || _.includes(['1', '2', '3', '-1'], postId)) {
      postId = '';
    }
  }

  const blogManagerAppPathsByType = {
    newPost: '#/posts/edit-post/?postGroup=all',
    allPosts: '#/posts/?postGroup=all',
    categories: '#/categories',
    editPost: `#/posts/edit-post/${postId}?postGroup=all`,
    editRelatedPosts: `#/posts/edit-post/${postId}?postGroup=all&openSiteWidget=relatedPosts`,
  };

  const BlogManagerAppPath =
    blogManagerAppPathsByType[blogManagerAppPathType] || '';

  const cbiParam = `&cbi=${getCBIParam(
    queryParamsObj.storeId,
    queryParamsObj.metaSiteId,
    util.editorModel.permissionsInfo?.ownerId,
    editorAPI.tpa.bi.consts.BI.initiator.EDITOR,
  )}`;

  return (
    baseUrl + queryStringPart + petri_ovrPart + cbiParam + BlogManagerAppPath
  );
}

function getCBIParam(storeId, msid, ownerId, initiator) {
  const cbiJSON = {
    app_id: '61f33d50-3002-4882-ae86-d319c1a249ab',
    evid: 12,
    initiator,
    instance_id: storeId,
    msid,
    ownerId,
    src: 75,
    type: 'GFPP_MANAGE',
  };

  return encodeURIComponent(JSON.stringify(cbiJSON));
}

/**
 * @param editorAPI
 * @returns {Array}
 */
function getPages(editorAPI) {
  const pagesData = editorAPI.dsRead.pages.getPagesData();
  const singlePostpageId = getPreviewPageId(editorAPI);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  return _.reduce(
    pagesData,
    function (result, page) {
      const isDynamicPage =
        !!editorAPI.dsRead.routers.getRouterDataForPageIfExist(page.id);
      if (
        page.tpaApplicationId <= 0 &&
        page.id !== singlePostpageId &&
        !isDynamicPage
      ) {
        result.push({
          value: `#${page.id}`,
          label: page.title,
        });
      }
      return result;
    },
    [],
  );
}

/**
 * @param editorAPI
 * @returns {string}
 */
function getCurrentUrlPageId(editorAPI) {
  return `#${editorAPI.pages.getCurrentUrlPageId()}`;
}

function getPrimaryPageId(editorAPI) {
  return `#${editorAPI.dsRead.pages.getPrimaryPageId()}`;
}

function getPreviewPage(editorAPI) {
  const PREVIEW_APP_PAGE_ID = '7326bfbb-4b10-4a8e-84c1-73f776051e10';
  const pagesData = editorAPI.dsRead.pages.getPagesData();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  return _.find(pagesData, { appPageId: PREVIEW_APP_PAGE_ID });
}

/**
 * @param editorAPI
 * @returns {*}
 */
function getPreviewPageId(editorAPI) {
  const previewPageId = _.result(getPreviewPage(editorAPI), 'id', null);
  return previewPageId;
}

/**
 * @param editorAPI
 * @returns {*}
 */
function getBlogPageTitle(editorAPI) {
  const previewPageId = _.result(getPreviewPage(editorAPI), 'pageUriSEO', null);
  return previewPageId;
}

/**
 * @param editorAPI
 * @param pageId
 * @returns {Array}
 */
function getAnchorsByPage(editorAPI, pageId) {
  const anchors = [];
  const ANCHOR_COMPONENT_TYPE =
    'wysiwyg.common.components.anchor.viewer.Anchor';

  const pageRef = editorAPI.dsRead.pages.getReference(pageId);

  // selecting Anchors components
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(
    editorAPI.components.get.byType(ANCHOR_COMPONENT_TYPE, pageRef),
    function (anchor) {
      const compData = editorAPI.components.data.get(anchor);

      anchors.push({
        anchorId: `#${compData.id}`,
        anchorName: compData.name,
        pageId: `#${pageId}`,
      });
    },
  );

  return anchors;
}

/**
 * Call method for update appPart in the preview
 * @param editorAPI
 */
function reloadAppParts(editorAPI) {
  editorAPI.dsActions.wixapps.classics.reloadApp('blog');
}

/**
 * @param {!Object} editorAPI
 * @returns {!Object}
 */
function getThemeData(editorAPI) {
  const themeData = _.cloneDeep(
    editorAPI.theme.styles.getAll('masterPage').THEME_DATA,
  );

  // Flatten array properties.
  _.forOwn(themeData, function (item, key) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    if (_.isArray(item)) {
      item.forEach(function (subitem, index) {
        themeData[`${key}_${index}`] = subitem;
      });
      delete themeData[key];
    }
  });

  return themeData;
}

/**
 * @param {!Object} editorAPI
 * @returns {!Object}
 */
function getThemeDataSchema(editorAPI) {
  const themeData = iframeApi.getThemeData(editorAPI);
  const themeDataSchema = _.cloneDeep(editorAPI.data.getSchema(themeData.type));

  // Make the new schema look like the old one.
  _.forOwn(themeDataSchema, function (value, key) {
    if (!_.isObject(value)) {
      return;
    }

    if (value.format) {
      // The old schema doesn't have format.
      // That, what is format in the new schema, is type in the old one.
      value.type = value.format;
      delete value.format;
    } else if (/Bg$/.test(key) && value.type === 'string') {
      // The old schema use "background" type instead of "string" for backgrounds.
      value.type = 'background';
    } else if (value.type === 'array') {
      // The old schema doesn't have arrays.
      // Each array item is presented as a single item with index in its name.
      const DEFAULT = 'default';
      value[DEFAULT].forEach(function (defaultValue, index) {
        themeDataSchema[`${key}_${index}`] = {
          type: value.items.format,
          default: defaultValue,
        };
      });
      delete themeDataSchema[key];
    }
  });

  return themeDataSchema;
}

/**
 * @returns {!{
 *      id: string,
 *      items: !Object.<!{
 *          cssClass: string=,
 *          label: string=,
 *          seoTag: string=,
 *          tag: string=
 *      }>,
 *      metaData: !Object,
 *      type: string
 *  }}
 */
function getWixAppsEditorStyles() {
  return textControls.constants.BLOG_EDITOR_STYLES;
}

/**
 * @returns {!{
 *      id: string,
 *      items: !Object.<!{
 *          cssClass: string=,
 *          label: string=,
 *          seoTag: string=,
 *          tag: string=
 *      }>,
 *      metaData: !Object,
 *      type: string
 *  }}
 */
function getCkEditorFontStyles() {
  return textControls.constants.BLOG_CK_EDITOR_FONT_STYLES;
}

/**
 * @param {!Object} editorAPI
 * @returns {!{
 *      missingFontsNames: !Object.<string>,
 *      missingFontsUrl: string
 *  }}
 */
function getCustomUsedFontsUrl(editorAPI) {
  const documentType = editorAPI.dsRead.generalInfo.getDocumentType();
  const characterSets = iframeApi.getCharSet(editorAPI);
  return textControls.fontUtils.getAllCustomFontsUrls(
    documentType,
    characterSets,
  );
}

/**
 * @param {!Object} editorAPI
 * @returns {!Array.<string>}
 */
function getFontsCssUrls(editorAPI) {
  const charSets = iframeApi.getCharSet(editorAPI);
  const documentType = editorAPI.dsRead.generalInfo.getDocumentType();
  return textControls.fontUtils.getWixStoredFontsCss(charSets, documentType);
}

/**
 * @returns {!Object.<!{
 *      cdnName: string,
 *      characterSets: !Array.<string>,
 *      displayName: string,
 *      fallbacks: string,
 *      fontFamily: string,
 *      genericFamily: string,
 *      permissions: string,
 *      provider: string,
 *      spriteIndex: number
 *  }>}
 */
function getFontsData(editorAPI) {
  return iframeApi.getFontsMetadata(editorAPI);
}

/**
 * @returns {!Object.<!{
 *      cdnName: string,
 *      characterSets: !Array.<string>,
 *      displayName: string,
 *      fallbacks: string,
 *      fontFamily: string,
 *      genericFamily: string,
 *      permissions: string,
 *      provider: string,
 *      spriteIndex: number
 *  }>}
 */
function getFontsMetadata(editorAPI) {
  const documentType = editorAPI.dsRead.generalInfo.getDocumentType();
  return textControls.fontUtils.getFontsMetadata(documentType);
}

/**
 * @param {!Object} editorAPI
 * @returns {!Array.<string>}
 */
function getCharSet(editorAPI) {
  return editorAPI.dsRead.theme.fonts.getCharacterSet();
}

/**
 *
 * @param {!Object} editorAPI
 * @returns {string}
 */
function getPublicUrl(editorAPI) {
  return editorAPI.dsRead.generalInfo.getPublicUrl();
}

/**
 *
 * @param {!Object} editorAPI
 * @returns {boolean}
 */
function isSitePublished(editorAPI: EditorAPI) {
  return editorAPI.dsRead.generalInfo.isSitePublished();
}

function getImageOrganizerUrl() {
  return util.imageOrganizerUtils.getFrameUrl('images');
}

function getFontsSpriteUrl() {
  return textControls.fontUtils.getFontsSpriteUrl();
}

function getSinglePostLayout(editorAPI: EditorAPI) {
  // This default value relies on value, hardcoded inside views.xml of santa-viewer for Single Post MediaTop
  const SINGLE_POST_DEFAULT_PADDING = 12;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  return _(editorAPI.dsRead.pages.getPagesData())
    .filter({ appPageId: '7326bfbb-4b10-4a8e-84c1-73f776051e10' })
    .map('id')
    .thru((pageIds) =>
      editorAPI.components.getAllComponents_DEPRECATED_BAD_PERFORMANCE(
        pageIds[0],
      ),
    )
    .filter(function (component) {
      const data = editorAPI.components.data.get(component);
      return data?.appPartName === blogAppPartNames.SINGLE_POST;
    })
    .thru(function (components) {
      const data = editorAPI.components.data.get(components[0]);
      const padding = _.findLast(data?.appLogicCustomizations, function (c) {
        return (
          c.fieldId === 'vars' &&
          c.forType === 'Post' &&
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          _.includes([undefined, '', '*'], c.format) &&
          c.view === data.viewName &&
          c.key === 'padding'
        );
      });

      const layout = editorAPI.components.layout.get_size(components[0]);
      return {
        width: layout?.width,
        padding: padding
          ? parseInt(padding.value, 10)
          : SINGLE_POST_DEFAULT_PADDING,
      };
    })
    .value();
}

function getSiteTitle(editorAPI) {
  return editorAPI.dsRead.seo.title.get() || editorAPI.siteName.get();
}

function isHttpsEnabled(editorAPI) {
  return editorAPI.dsRead.generalInfo.isHttpsEnabled();
}

const iframeApi = {
  getFrameUrl,
  getPages,
  getPrimaryPageId,
  getCurrentUrlPageId,
  getPreviewPageId,
  getBlogPageTitle,
  getThemeData,
  getThemeDataSchema,
  getWixAppsEditorStyles,
  getCkEditorFontStyles,
  getCustomUsedFontsUrl,
  getFontsCssUrls,
  getFontsData,
  getFontsMetadata,
  getCharSet,
  reloadAppParts,
  getPublicUrl,
  isSitePublished,
  getAnchorsByPage,
  getImageOrganizerUrl,
  getFontsSpriteUrl,
  getSinglePostLayout,
  getSiteTitle,
  isHttpsEnabled,
};
export default iframeApi;
