import React from 'react';
import { QuickEditPanel } from '@/quickEditEngine';
import { connectWithScope } from '@/apilib';
import { mapDispatchToProps, mapStateToProps } from './quickEditPanelMapper';
import { QuickEditScope } from '../quickEditEntryPoint';

import type { InferComponentProps } from '@/apilib';
import type { CompRef, CompStructure } from '@wix/document-services-types';
import type { GFPPActionType } from '@wix/editor-component-types';
import type { UIResourceRestriction } from '@/editorRestrictions';
import type {
  QuickEditControlActions,
  QuickEditControlOwnProps,
} from '@wix/editor-elements-types/quickEditControls';

interface ClassicQuickEditPanelOwnProps {
  EmptyState: React.FC<{}>;
  getOverrideCompControls: (
    compRef: CompRef,
  ) => React.ComponentType<QuickEditControlOwnProps>;
  onControlClick: (
    compRef: CompRef,
    action: QuickEditControlActions | GFPPActionType,
  ) => void;
  onComponentChanged: (compRef: CompRef) => void;
  isComponentFiltered: (compRef: CompRef) => boolean;
  getPermissionsConfig: (
    compType: string,
    isAllowed: (uiResourceType: UIResourceRestriction) => boolean,
  ) => any;
  openComponentPanelDrillIn: (title?: string) => void;
  onDrillInAction: (compRef: CompRef) => void;
  onLinkAction: (compRef: CompRef) => void;
  onControlHover: (compRef: CompRef, rootControlCompRef: CompRef) => void;
  onControlLeave: (compRef: CompRef, rootControlCompRef: CompRef) => void;
  onMainActionClick: (compRef: CompRef, hasPrimaryConnection: boolean) => void;
  getThumbnail: (
    compDef: CompStructure,
    thumbnailDimensions: { width: number; height: number },
    onReady?: () => void,
  ) => Promise<React.ReactElement>;
  allowedRootComponents: string[];
  onGfppAction: (compRef: CompRef, actionType: GFPPActionType) => void;
}

export type QuickEditPanelWrapperProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  ClassicQuickEditPanelOwnProps
>;

const QuickEditPanelWrapper: React.FC<QuickEditPanelWrapperProps> = (props) => {
  return <QuickEditPanel {...props} />;
};

export default connectWithScope(
  () => QuickEditScope,
  QuickEditPanelWrapper,
  mapStateToProps,
  mapDispatchToProps,
);
