import * as stateManagement from '@/stateManagement';
import _ from 'lodash';

import type { ITransitionValue } from './transitionPanelTypes';
import type { SendBiFunction } from 'types/bi';
import type { ThunkAction } from 'types/redux';

const { getVariantId } = stateManagement.interactions.selectors;
const getEditorAPI: ThunkAction = (dispatch, getState, { editorAPI }) => ({
  editorAPI,
  state: getState(),
});
const { updateInteractionData } = stateManagement.interactions.actions;

const normalizeCamelCase = (transition: AnyFixMe) => {
  const { 'timing-function': timingFunction, ...restParams } = transition;

  return {
    timingFunction,
    ...restParams,
  };
};

const normalizeKebabCase = (normalizedTransition: ITransitionValue) => {
  const { timingFunction, ...restParams } = normalizedTransition;

  return {
    'timing-function': timingFunction,
    ...restParams,
  };
};

export const mapStateToProps = ({ editorAPI, state }: AnyFixMe) => {
  const { transitions } = editorAPI.documentServices.components;
  const compRef = editorAPI.selection.getSelectedComponents()[0];
  const transitionValue = transitions.get(compRef);

  return {
    variantId: getVariantId(state),
    ...normalizeCamelCase(transitionValue),
  };
};

export const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const { editorAPI } = dispatch(getEditorAPI);
  const { transitions } = editorAPI.documentServices.components;
  const compRef = editorAPI.selection.getSelectedComponents()[0];

  let old_value: AnyFixMe;
  let debouncing = false;
  const sendBIDebounced: SendBiFunction = _.debounce((event, parameters) => {
    const defaultBIParams = {
      component_id: compRef.id,
      component_type: editorAPI.components.getType(compRef),
    };

    dispatch(
      stateManagement.bi.actions.event(event, {
        ...defaultBIParams,
        ...parameters,
        old_value:
          (typeof old_value === 'number' && old_value) || parameters.old_value,
      }),
    );
    debouncing = false;
  }, 1000);

  const sendBI: SendBiFunction = (event, parameters) => {
    if (!debouncing) {
      old_value = parameters.old_value;
      debouncing = true;
    }
    sendBIDebounced(event, parameters);
  };

  return {
    updateTransition: (transitionValue: ITransitionValue) => {
      transitions.update(compRef, normalizeKebabCase(transitionValue));
      dispatch(updateInteractionData(compRef));
    },
    sendBI,
    openHelpCenter: editorAPI.panelManager.openHelpCenter,
  };
};
