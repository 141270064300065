import {
  WIX_CHALLENGES,
  WIX_BOOKINGS,
  WIX_EVENTS,
  WIX_FORMS,
  WIX_GROUPS,
  PRICING_PLANS,
  WIX_BLOG,
  WIX_FORUM,
  WIX_VIDEO,
  MEMBERS_AREA,
  WIX_PHOTO_ALBUMS,
  WIX_RESTAURANTS_ORDERS,
  WIX_CHAT,
  WIX_SITE_SEARCH,
  WIX_FILE_SHARE,
  WIX_PORTFOLIO,
  WIX_FAQ,
  WIX_HOTELS_RUNNER,
  RESTAURANTS,
} from '@wix/app-definition-ids';
import experiment from 'experiment';
import { tpaUtils } from '@/util';

export const HELP_ARTICLE_IDS = {
  APP_LIST: 'adc4b199-9239-475a-ba0d-a5051d4242db',
  MY_BUSINESS: 'adc4b199-9239-475a-ba0d-a5051d4242db',
};
export const APP_MANAGER_ORIGIN = 'app-manager';

export const APP_MANAGER_APPS_LIST = [
  tpaUtils.getStoresAppDefId(),
  WIX_BOOKINGS,
  WIX_BLOG,
  WIX_EVENTS,
  PRICING_PLANS,
  WIX_FORUM,
  WIX_GROUPS,
  WIX_FORMS,
  WIX_CHALLENGES,
  MEMBERS_AREA,
  WIX_PHOTO_ALBUMS,
  WIX_VIDEO,
  WIX_CHAT,
  WIX_SITE_SEARCH,
  WIX_PORTFOLIO,
  WIX_FILE_SHARE,
  WIX_FAQ,
  experiment.isOpen('se_includeRestaurantsOrdersInMyBusinessPanel')
    ? WIX_RESTAURANTS_ORDERS
    : null,
  experiment.isOpen('se_includeWixHotelsRunnerInMyBusinessPanel')
    ? WIX_HOTELS_RUNNER
    : null,
  experiment.isOpen('se_includeRestaurantsInMyBusinessPanel')
    ? RESTAURANTS
    : null,
].filter(Boolean);

export const APPS_EXCLUDED_FROM_INSTALLATION_IN_MY_BUSINESS_PANEL =
  experiment.isOpen('se_excludeInstallationOfWixHotelsRunnerInMyBusinessPanel')
    ? [WIX_HOTELS_RUNNER]
    : [];

export const POPULAR_APPS_IMAGE_PATHS: Record<string, readonly string[]> = {
  [WIX_BOOKINGS]: [
    'appManager/appCardHeader/bookings.png',
    'appManager/appCardHeader/bookings@2x.png',
  ],
  [tpaUtils.getStoresAppDefId()]: [
    'appManager/appCardHeader/stores.png',
    'appManager/appCardHeader/stores@2x.png',
  ],
  [WIX_BLOG]: [
    'appManager/appCardHeader/blogs.png',
    'appManager/appCardHeader/blogs@2x.png',
  ],
} as const;

export const APP_ICON_SIZE = 42;
export const SETUP_SUCCESS_ANIMATION_DURATION = 1000 * 10;
export const DEFAULT_TRANSITION_TIMEOUT = 500;

export const LITE_MODE_FILTERED_APPS = [
  WIX_VIDEO,
  WIX_CHAT,
  WIX_SITE_SEARCH,
  WIX_FAQ,
  WIX_FILE_SHARE,
];
