import { menuItemTree } from '@/baseUI';

import { PAGES_MENU_ID } from '../../constants';
import { createMenuApi } from '../../API/menuAPI';
import { createMenuSetFromPagesPanelData, filterMenus } from './utils';

import type { EditorAPI } from '@/editorAPI';

const getPagesMenuItems = (editorAPI: EditorAPI) => {
  const menuAPI = createMenuApi(editorAPI);
  const menu = menuAPI.getMenu(PAGES_MENU_ID);

  return editorAPI.menus.getExtendedMenuItemsTree(menu).items;
};

/**
 * We should create mobile menu only when there is an item with different visibility setting on desktop and mobile
 * {isVisible: true, isVisibleMobile: false} or vice versa
 * We still should create mobile menu, if there is no desktop menu (any custom menu)
 **/
export const shouldCreateMobileMenu = (editorAPI: EditorAPI): boolean => {
  const menuAPI = createMenuApi(editorAPI);

  const pagesMenuItems = getPagesMenuItems(editorAPI);
  const tree = menuItemTree.flattenTree(pagesMenuItems);

  const hasDifferentVisibilityItems =
    tree.find((node) => node.item.isVisible !== node.item.isVisibleMobile) !==
    undefined;

  const desktopMenu = menuAPI.getCustomMenus()[0];

  return hasDifferentVisibilityItems || !desktopMenu;
};

// This function should be called after creating desktop menu
export const migrateMobileMenu = async (
  editorAPI: EditorAPI,
): Promise<void> => {
  const menuAPI = createMenuApi(editorAPI);
  const mobileMenuComponents = menuAPI.getMobileMenuComponents();

  const shouldReconnect =
    filterMenus(editorAPI, mobileMenuComponents).length > 0;

  if (!shouldReconnect) {
    return Promise.resolve();
  }

  // When we don't need a separated menu set for mobile
  if (!shouldCreateMobileMenu(editorAPI)) {
    const desktopMenu = menuAPI.getCustomMenus()[0];

    mobileMenuComponents.map((component) =>
      menuAPI.connect(desktopMenu.id, component),
    );

    return new Promise((resolve) => editorAPI.waitForChangesApplied(resolve));
  }

  const mobileMenuSetId = createMenuSetFromPagesPanelData(editorAPI, {}, true);

  mobileMenuComponents.map((ref) => menuAPI.connect(mobileMenuSetId, ref));
  return new Promise((resolve) => editorAPI.waitForChangesApplied(resolve));
};
