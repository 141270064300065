import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import { Checkbox, EditableUrl, Heading } from '@wix/wix-base-ui';
import experiment from 'experiment';
import * as symbols from '@wix/santa-editor-symbols';
import * as platformEvents from 'platformEvents';

import * as core from '@/core';
import * as util from '@/util';
import * as panels from '@/panels';
import * as baseUI from '@/baseUI';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';

import type { PublishInnerPanelType } from './publishInnerPanelTypes';
import INNER_PANELS from './publishInnerPanelTypes';
import shoutoutPanel from './content/shoutoutPanel';
import growBusinessPanel from './content/growBusinessPanel';
import connectDomain from '../content/connectDomain';
import * as publishSuccessPanelMapper from './publishSuccessPanelMapper';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import '@wix/santa-editor-utils';

import type { BiEventDefinition } from 'types/bi';
import type {
  PublishInteractionStartedSource,
  PublishRCInteractionStartedSource,
} from 'types/fedops/publishInteraction';
import type { EditorAPI } from '@/editorAPI';
import type { DealerViewerProps } from '@/topBar';
import type { InnerPanelComponent } from '../content/types';
interface DealerOnClickParams {
  additionalLogParams?: {
    clickType?: string;
  };
  offerId: string;
}

const CONNECT_DOMAIN_IN_PROGRESS_PANEL =
  'savePublish.panels.connectDomainInProgress';
const domainSelectors = stateManagement.domain.selectors;

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

export interface PostPublishPanelConfiguration {
  isFakePublish?: boolean;
  isPremiumUser?: boolean;
  sitePremiumDomain?: string;
  isDomainConnected?: boolean;
  isFirstSave?: boolean;
  isFirstPublish?: boolean;
  rcPublicUrl?: string;
}

export interface PublishSuccessPanelOpenProps {
  isFakePanel: boolean;
  contentPanelType: PublishInnerPanelType;
  configuration: PostPublishPanelConfiguration;
  currentDomain: string;
  setDontShowPanelValue: (value: boolean) => void;
  sitePublicUrl: string;
  shouldDisplayInnerPanel: boolean;
  closePanel: () => void;
  dealerViewer?: React.ComponentType<DealerViewerProps>;
  onDealerClick?: (params: DealerOnClickParams) => void;
  sourceOfStart:
    | PublishInteractionStartedSource
    | PublishRCInteractionStartedSource;
}

type PublishSuccessPanelOwnProps = PublishSuccessPanelOpenProps & {
  panelName: string;
};

export interface PublishSuccessPanelDispatchProps {
  showUserActionNotification: (options: AnyFixMe) => {};
  setWasLatestRCPublished: (wasLatestRCPublished: boolean) => {};
}

type PublishSuccessPanelProps = PublishSuccessPanelDispatchProps &
  PublishSuccessPanelOwnProps;

interface PublishSuccessPanelState {
  dealerViewerProps: unknown;
  dontShowAgain: boolean;
  hasDealerFailed: boolean;
  hasDealerSucceeded: boolean;
}

interface BodyProps {
  innerPanel?: React.ReactNode;
  editButtonClassName?: string;
}

interface FooterProps {
  withInnerPanel?: boolean;
  buttonClassName?: string;
}

const PublishSuccessPanel: React.ClassicComponentClass<PublishSuccessPanelProps> =
  createReactClass<
    PublishSuccessPanelProps,
    PublishSuccessPanelState
    // eslint-disable-next-line react/prefer-es6-class
  >({
    displayName: 'publishSuccessPanel',
    mixins: [ReactLinkedStateMixin, core.mixins.editorAPIMixin], //eslint-disable-line react/no-deprecated
    propTypes: {
      isFakePanel: PropTypes.bool,
      contentPanelType(props: AnyFixMe, propName, componentName) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/some
        if (!props[propName] || !_.some(INNER_PANELS, props[propName])) {
          return new Error(
            `Invalid prop \`${propName}\` supplied to ${componentName}. Validation failed.`,
          );
        }
      },
      configuration: PropTypes.object.isRequired,
      currentDomain: PropTypes.string.isRequired,
      setDontShowPanelValue: PropTypes.func.isRequired,
      sitePublicUrl: PropTypes.string.isRequired,
      closePanel: PropTypes.func.isRequired,
      shouldDisplayInnerPanel: PropTypes.bool,
      panelName: PropTypes.string,
      dealerViewer: PropTypes.func,
      onDealerClick: PropTypes.func,
      showUserActionNotification: PropTypes.func.isRequired,
    },

    componentWillUnmount() {
      const editorAPI: EditorAPI = this.getEditorAPI();

      if (this.renderTimeout) {
        util.dealerUtils.clearDealerRenderSuccessTimeout(this.renderTimeout);
      }

      editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
        platformEvents.factory.sitePublishedDialogClosed({}),
      );

      editorAPI.mobile.mobileDiscoverabilityModal.show();
    },

    componentDidMount() {
      // if open panel requested - end interaction on the panel did mount
      // if open panel NOT requested - end interaction in the publishManager
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.PUBLISH,
        {
          paramsOverrides: {
            sourceOfStart: this.props.sourceOfStart,
            sourceOfEnd: 'publishSuccessPanel',
          },
        },
      );

      if (!this.props.dealerViewer) {
        this.sendPanelDisplayedBI(false);
      } else {
        this.renderTimeout = util.dealerUtils.createDealerRenderSuccessTimeout(
          this.onDealerError,
        );
      }
    },

    getInitialState(): PublishSuccessPanelState {
      return {
        dealerViewerProps: this.getDealerViewerProps(),
        dontShowAgain: false,
        hasDealerFailed: false,
        hasDealerSucceeded: false,
      };
    },

    getDealerViewerProps() {
      const metaSiteInstance: string =
        this.getEditorAPI().dsRead.platform.getAppDataByApplicationId(
          constants.APPLICATIONS.META_SITE_APPLICATION_ID,
        )?.instance;

      return {
        ...util.dealerUtils.getDealerViewerBaseProps(),
        metaSiteId: this.getEditorAPI().dsRead.generalInfo.getMetaSiteId(),
        bannerPosition: util.dealerUtils.bannerPositions.POST_PUBLISH,
        signedInstance: metaSiteInstance,
        key: 'dealerViewerPublish',
        onDisplay: this.onDealerDisplay,
        onError: this.onDealerError,
        onClick: this.onDealerClick,
      };
    },

    getShortcuts() {
      return {
        esc: this.onClose,
        enter: this.handlePrimaryButtonClicked,
      };
    },

    onClose() {
      this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
    },

    shouldPresentDealer() {
      return this.props.dealerViewer && !this.state.hasDealerFailed;
    },

    getFrameProps() {
      return {
        panelName: this.props.panelName,
        shouldHideHeader: true,
      };
    },
    getInnerContentClass() {
      if (this.props.contentPanelType === INNER_PANELS.EMPTY) {
        return null;
      }

      const innerPanelTypeName = this.props.contentPanelType.name;
      switch (innerPanelTypeName) {
        case INNER_PANELS.SHOUTOUT.name:
          return 'grow-business-inner-panel';
        case INNER_PANELS.CONNECT_DOMAIN.name:
          return 'connect-domain-inner-panel';
        case INNER_PANELS.GROW_BUSINESS.name:
          return 'grow-business-inner-panel';
        default:
          return '';
      }
    },
    getFrameClass() {
      let frameClass = 'publish-success-panel save-publish-panel';
      const innerPanelClass = this.getInnerContentClass();
      frameClass += innerPanelClass ? ` contains-${innerPanelClass}` : '';
      frameClass +=
        this.shouldPresentDealer() && !this.state.hasDealerSucceeded
          ? ' hide-panel-before-dealer-loaded'
          : '';

      return frameClass;
    },

    getPanelFrameClassName() {
      const additionalClasses =
        this.shouldPresentDealer() && !this.state.hasDealerSucceeded
          ? 'hide-panel-before-dealer-loaded'
          : '';
      return `save-publish-panel-frame ${additionalClasses}`;
    },

    onDoneAndBI(biEventDef: BiEventDefinition) {
      this.sendActionButtonClickedBI(biEventDef);
      this.onDone();
    },

    onDone() {
      if (this.state.dontShowAgain || this.props.isFakePanel) {
        const value = this.state.dontShowAgain;
        this.props.setDontShowPanelValue(value);
      }
      if (this.props.wasLatestRCPublished) {
        this.showReleaseManagerNotification();
        this.props.setWasLatestRCPublished(false);
      }
      this.props.closePanel();
    },

    isDontShowAgainCheckboxVisible() {
      const isFirstSave = this.props.configuration.isFirstPublish;
      return (
        !isFirstSave &&
        !_.isEqual(this.props.contentPanelType, INNER_PANELS.EMPTY)
      );
    },

    isSecondaryButtonDisplayed() {
      return (
        experiment.isOpen('publishUpgradeButtons') &&
        !this.props.configuration.isDomainConnected &&
        this.props.contentPanelType.name === INNER_PANELS.CONNECT_DOMAIN.name
      );
    },

    viewYourSite() {
      this.sendViewSiteBI();

      let url = this.getEditorAPI().site.getSitePublicUrl();
      if (this.props.configuration.isDomainConnected) {
        url = this.props.configuration.sitePremiumDomain;
      }
      window.open(url);
    },

    getSiteUrl(): string {
      const editorAPI: EditorAPI = this.getEditorAPI();
      const state = editorAPI.store.getState();
      const siteProtocol = domainSelectors.getSiteProtocol(
        state,
        editorAPI.dsRead,
      );
      const siteUrl =
        this.props.configuration.sitePremiumDomain || this.props.sitePublicUrl;
      const regex = /(?:^https?:\/\/)?(?:www\.)?(.*)/i;

      return siteUrl.replace(regex, `${siteProtocol}://$1`);
    },

    sendDealerBI(isSuccess: boolean, description: string) {
      this.sendBI(coreBi.events.dealer.content_presented_by_dealer, {
        description,
        content_presented_by_dealer: isSuccess,
        stage: 'render',
        category: 'upgrade',
        panel_name: this.props.panelName,
      });

      this.sendPanelDisplayedBI(isSuccess);
    },

    onDealerDisplay() {
      this.setState({ hasDealerSucceeded: true });
      util.dealerUtils.clearDealerRenderSuccessTimeout(this.renderTimeout);
      this.sendDealerBI(true, '');
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.DEALER_IN_POST_PUBLISH,
      );
    },

    onDealerError(e: AnyFixMe) {
      const description = `dealer after pre fetch ${e?.errorType ?? ''} error`;
      this.sendDealerBI(false, description);
      this.setState({ hasDealerFailed: true });
    },

    onDealerClick(params: DealerOnClickParams) {
      this.props.onDealerClick?.(params);
    },

    getInnerPanel() {
      const innerPanelName = this.props.contentPanelType.name;
      let panel: InnerPanelComponent = null;
      let props = null;

      if (this.shouldPresentDealer()) {
        panel = this.props.dealerViewer;
        props = this.state.dealerViewerProps;
      } else {
        switch (innerPanelName) {
          case INNER_PANELS.SHOUTOUT.name:
            panel = shoutoutPanel;
            props = this.getShoutoutPanelProps();
            break;
          case INNER_PANELS.CONNECT_DOMAIN.name:
            panel = connectDomain;
            props = this.getConnectDomainPanelProps(this.props.configuration);
            break;
          case INNER_PANELS.GROW_BUSINESS.name:
            panel = growBusinessPanel;
            props = {
              metaSiteId:
                this.getEditorAPI().dsRead.generalInfo.getMetaSiteId(),
              userStatus: this.getUserStatus(),
              isFirstPublish: this.props.configuration.isFirstPublish,
            };
            break;
          default:
            break;
        }
      }
      if (panel && this.props.shouldDisplayInnerPanel) {
        return React.createElement(panel, props);
      }
      return null;
    },

    getShoutoutPanelProps() {
      const editorAPI = this.getEditorAPI();
      return {
        isUserPremium: this.props.configuration.isPremiumUser,
        languageCode: util.languages.getLanguageCode(),
        metaSiteId: editorAPI.dsRead.generalInfo.getMetaSiteId(),
        sitePublicUrl: this.props.sitePublicUrl,
        siteNameSEO: editorAPI.dsRead.seo.title.get(),
        siteDescriptionSEO: editorAPI.dsRead.seo.description.get(),
        userStatus: this.getUserStatus(),
        isFirstPublish: this.props.configuration.isFirstPublish,
      };
    },

    getConnectDomainPanelProps(config: AnyFixMe) {
      return {
        isFirstPublish: config.isFirstPublish,
        isDomainConnected: config.isDomainConnected,
        currentDomain: this.props.sitePublicUrl,
        sitePublishedDomain: config.sitePremiumDomain,
        isSitePremium: config.isPremiumUser,
        isFirstSave: config.isFirstSave,
        closePanel: this.onDone,
        flowId: constants.CONNECT_DOMAIN.FLOWS.PUBLISH,
        actionCallback: this.sendConnectDomainClickedBI,
        origin: constants.CONNECT_DOMAIN.FLOWS.PUBLISH,
      };
    },

    getPrimaryButtonText() {
      if (!this.isSecondaryButtonDisplayed()) {
        return 'SAVE_PUBLISH_BUTTON_DONE';
      } else if (this.props.configuration.isPremiumUser) {
        return 'Save_Publish_POST_CONNECT_NOW';
      }

      return experiment.isOpen('spanishUpgradeText')
        ? 'Save_Publish_POST_UPGRADE_NOW'
        : 'Save_Publish_POST_UPGRADE_NOW';
    },

    getSecondaryButtonText() {
      return 'Save_Publish_POST_CONNECT_LATER';
    },

    handlePrimaryButtonClicked() {
      const { isFirstPublish } = this.props.configuration;
      const publishEvents = coreBi.events.publish;
      let biEventDef: BiEventDefinition = isFirstPublish
        ? publishEvents.First_Publish_Done_click
        : publishEvents.Second_Publish_Done_click;

      if (this.isSecondaryButtonDisplayed()) {
        if (this.isConnectDomainInProgress()) {
          this.openConnectDomainInProgressPanel();
          biEventDef =
            publishEvents.publish_flow_connect_domain_now_button_click;
        } else if (this.props.configuration.isPremiumUser) {
          this.getEditorAPI().account.openConnectDomain(
            constants.CONNECT_DOMAIN.FLOWS.PUBLISH_BUTTON,
            isFirstPublish,
          );
          biEventDef =
            publishEvents.publish_flow_connect_domain_now_button_click;
        } else {
          const referralCode = `edhtml_PUBLISH_FLOW_UPGRADE_BUTTON_${
            isFirstPublish ? 'FIRST_PUBLISH' : 'SECOND_PUBLISH'
          }`;
          this.getEditorAPI().account.upgrade(referralCode);
          biEventDef = publishEvents.publish_flow_upgrade_now_button_click;
        }
      }

      this.onDoneAndBI(biEventDef);
    },
    handleSecondaryButtonClicked() {
      const { isFirstPublish } = this.props.configuration;
      const publishEvents = coreBi.events.publish;
      let biEventDef: BiEventDefinition = isFirstPublish
        ? publishEvents.First_Publish_Done_click
        : publishEvents.Second_Publish_Done_click;

      if (this.isSecondaryButtonDisplayed()) {
        if (this.props.configuration.isPremiumUser) {
          biEventDef =
            publishEvents.publish_flow_connect_domain_later_button_click;
        } else {
          biEventDef = publishEvents.publish_flow_upgrade_later_button_click;
        }
      }

      this.onDoneAndBI(biEventDef);
    },
    isConnectDomainInProgress() {
      const { dsRead } = this.getEditorAPI();
      const siteDomain = this.props.configuration.sitePremiumDomain;
      const isDomainLive = this.props.configuration.isDomainConnected;
      const isPremiumSite = this.props.configuration.isPremiumUser;
      const isPremiumDomain = util.domain.isDomainConnected(dsRead, siteDomain);
      return isPremiumSite && isPremiumDomain && !isDomainLive;
    },
    openConnectDomainInProgressPanel() {
      this.getEditorAPI().panelManager.openPanel(
        CONNECT_DOMAIN_IN_PROGRESS_PANEL,
        {
          connectedDomainURL: this.props.configuration.sitePremiumDomain,
          currentPublicURL: this.props.sitePublicUrl,
        },
        true,
      );
    },

    sendPanelDisplayedBI(isUsingDealer: boolean) {
      const event =
        coreBi.events.publish.publish_success_panel_opened_with_content;
      const eventParams = {
        is_first_publish: this.props.configuration.isFirstPublish,
        message: isUsingDealer ? 'DEALER' : this.props.contentPanelType.name,
        type: isUsingDealer ? 'DEALER' : 'EDITOR',
      };

      this.sendBI(event, eventParams);
    },

    sendConnectDomainClickedBI() {
      let event = null;
      let eventParams: AnyFixMe = null;
      if (this.props.configuration.isFirstPublish) {
        event = coreBi.events.publish.First_Publish_connect_domain_click;
      } else {
        event = coreBi.events.publish.Second_Publish_connect_domain_click;
      }

      if (
        this.props.configuration.isSitePremium &&
        !this.props.configuration.isDomainConnected
      ) {
        eventParams = { user_status: 'Not Connected Premium' };
      } else if (!this.props.configuration.isSitePremium) {
        eventParams = { user_status: 'free user' };
      }

      if (event.fields.message) {
        eventParams.message = this.props.contentPanelType.name;
      }

      if (event && eventParams) {
        this.sendBI(event, eventParams);
      }
    },

    sendActionButtonClickedBI(biEventDef: BiEventDefinition) {
      const { isFirstPublish } = this.props.configuration;
      const params: Record<string, unknown> = {
        user_status: this.getUserStatus(),
      };

      if (biEventDef.fields.is_first_publish) {
        params.is_first_publish = isFirstPublish;
      }

      if (biEventDef.fields.message) {
        params.message = this.props.contentPanelType.name;
      }

      this.sendBI(biEventDef, params);
    },

    sendViewSiteBI() {
      this.sendBI(coreBi.events.publish.publish_view_your_site_click, {
        is_first_publish: this.props.configuration.isFirstPublish ? 1 : 0,
        panel_name: 'savePublish.panels.publish.publishSuccessPanel',
        user_status: this.getUserStatus(),
      });
    },

    getUserStatus() {
      const isPremium = this.props.configuration.isPremiumUser;
      const { isDomainConnected } = this.props.configuration;
      let userStatus = null;
      if (!isPremium) {
        userStatus = 'free user';
      } else if (isPremium && isDomainConnected) {
        userStatus = 'Connected Premium';
      } else if (isPremium && !isDomainConnected) {
        userStatus = 'Not Connected Premium';
      }
      return userStatus;
    },

    toggleDontShowAgain() {
      const dontShowAgainValue = !this.state.dontShowAgain;
      this.sendToggleDontShowAgainBI(dontShowAgainValue);
      this.setState({ dontShowAgain: dontShowAgainValue });
    },

    sendToggleDontShowAgainBI(toggleValue: AnyFixMe) {
      toggleValue = toggleValue ? 1 : 0;
      if (this.props.configuration.isFirstPublish) {
        this.sendBI(coreBi.events.publish.First_Publish_dont_show_again_click, {
          toggle: toggleValue,
          user_status: this.getUserStatus(),
        });
      } else {
        this.sendBI(
          coreBi.events.publish.Second_Publish_dont_show_again_click,
          {
            toggle: toggleValue,
          },
        );
      }
    },

    domainInputBiReporter(biEventName: keyof typeof coreBi.events.domainInput) {
      const biProps = {
        user_status: this.getUserStatus(),
        panel_name: this.props.panelName,
        save_or_publish: 'publish',
        is_first_save_or_publish: this.props.configuration.isFirstPublish
          ? 1
          : 0,
      };
      this.sendBI(coreBi.events.domainInput[biEventName], biProps);
    },

    sendBI(
      biEvent: BiEventDefinition,
      biFields: Record<string, string | number | boolean>,
    ) {
      this.getEditorAPI().bi.event(biEvent, biFields);
    },

    showReleaseManagerNotification() {
      const openReleaseManager = () => {
        this.getEditorAPI().account.openReleaseManager();
      };

      this.props.showUserActionNotification({
        message: 'Notifications_PublishAll_WithRC_Text',
        title: 'Notifications_PublishAll_WithRC_Text',
        type: 'info',
        link: {
          caption: 'Notifications_PublishAll_WithRC_Link',
          onNotificationLinkClick: openReleaseManager,
        },
      });
    },

    renderHeader() {
      return (
        <header className="save-publish-panel-header">
          <button onClick={this.props.closePanel} className="close">
            <symbols.symbol name="headerCloseButton" />
          </button>
          <span className="title">
            {translate('PUBLISH_CONGRATULATIONS_TITLE_TITLE')}
          </span>
          <span className="subtitle">
            {translate('PUBLISH_CONGRATULATIONS_TITLE_SUBTITLE')}
          </span>
        </header>
      );
    },

    renderBody(props: BodyProps) {
      const { innerPanel, editButtonClassName } = props;

      return (
        <>
          <div className="address-bar-wrapper">
            <span className="address">
              {/* TODO: Fix ts error, "domain" prop is required but is not passed here */}
              {/* @ts-expect-error */}
              <EditableUrl
                value={this.getSiteUrl()}
                isReadOnly
                onInputClick={() => {
                  this.domainInputBiReporter('DISABLED_DOMAIN_PART_CLICKED');
                  return null;
                }}
              />
            </span>

            <baseUI.button
              label="SAVE_PUBLISH_BUTTON_VIEW"
              onClick={this.viewYourSite}
              className={util.cx('view-site-button', editButtonClassName)}
            />
          </div>

          {innerPanel !== null ? (
            <div key="innerPanel" className="publish-inner-panel">
              {innerPanel}
            </div>
          ) : null}
        </>
      );
    },

    renderFooter(props: FooterProps) {
      const { withInnerPanel, buttonClassName } = props;

      return (
        <div className="publish-success-footer">
          {this.isDontShowAgainCheckboxVisible() && withInnerPanel ? (
            <Checkbox
              key="dontShowAgain"
              label="SAVE_PUBLISH_DO_NOT_SHOW_AGAIN_LABEL"
              value={this.state.dontShowAgain}
              onChange={this.toggleDontShowAgain}
              labelAfterSymbol={true}
              className="dont-show-again"
            />
          ) : null}
          <div className="footer-buttons">
            {this.isSecondaryButtonDisplayed() ? (
              <baseUI.button
                key="secondaryFooterButton"
                label={this.getSecondaryButtonText()}
                onClick={this.handleSecondaryButtonClicked}
                className={util.cx('btn-confirm-secondary', buttonClassName)}
              />
            ) : null}
            <baseUI.button
              automationId="publish-success-panel-proceed-button"
              label={this.getPrimaryButtonText()}
              onClick={this.handlePrimaryButtonClicked}
              className={util.cx('btn-confirm-primary', buttonClassName)}
            />
          </div>
        </div>
      );
    },

    renderNewWorkspacePanelFrame() {
      const InnerPanel = this.getInnerPanel();

      return (
        <panels.frames.CustomPanelFrame
          panelName={this.props.panelName}
          className="new-workspace-publish-success-panel"
          dataHook="publish-success-panel"
          onCloseButtonClick={() => this.props.closePanel()}
          footerContent={this.renderFooter({
            withInnerPanel: InnerPanel !== null,
            buttonClassName: 'btn-md',
          })}
          onOuterClick={this.onClose}
          keyboardShortcuts={this.getShortcuts()}
        >
          <>
            <div className="publish-success-header">
              <Heading shouldTranslate={false} appearance="h1" multiline>
                {translate('PUBLISH_CONGRATULATIONS_TITLE_TITLE')}
              </Heading>
              <Heading shouldTranslate={false} multiline appearance="h4">
                {translate('PUBLISH_CONGRATULATIONS_TITLE_SUBTITLE')}
              </Heading>
            </div>
            <div>
              {this.renderBody({
                innerPanel: InnerPanel,
                editButtonClassName: 'btn-md btn-confirm-secondary',
              })}
            </div>
          </>
        </panels.frames.CustomPanelFrame>
      );
    },

    render() {
      if (util.workspace.isNewWorkspaceEnabled()) {
        return this.renderNewWorkspacePanelFrame();
      }

      const InnerPanel = this.getInnerPanel();

      return (
        <panels.frames.FocusPanelFrame
          frameClassName={this.getPanelFrameClassName()}
          keyboardShortcuts={this.getShortcuts()}
          {...this.getFrameProps()}
          className={util.cx(
            this.getFrameClass(),
            this.getFrameProps()?.className,
          )}
        >
          {this.renderHeader()}
          {this.renderBody({ innerPanel: InnerPanel })}
          {this.renderFooter({ withInnerPanel: InnerPanel !== null })}
        </panels.frames.FocusPanelFrame>
      );
    },
  });

const connectedPublishSuccessPanel = connect(
  EDITOR_API,
  publishSuccessPanelMapper.mapStateToProps,
  publishSuccessPanelMapper.mapDispatchToProps,
)(PublishSuccessPanel);

connectedPublishSuccessPanel.pure = PublishSuccessPanel;

export default connectedPublishSuccessPanel;
