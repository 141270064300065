// @ts-nocheck
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';

const { findCurrentSlide } = stateManagement.boxSlideShow.selectors;
const { ACCESSIBILITY } = constants.COMP_META_DATA.CONTROLS;

function isRepeaterState(editorAPI, comp) {
  const currentSlide = findCurrentSlide(editorAPI.dsRead, comp);
  const slideCompType = editorAPI.components.getType(currentSlide);
  return slideCompType === 'wysiwyg.viewer.components.Repeater';
}

export default {
  selectedBeforeDescendants: (editorAPI, comp) =>
    isRepeaterState(editorAPI, comp),
  nestedBackgroundDesign: true,
  slidesContainer: true,
  focusable: true,
  exposesSlideAsInnerComponent: true,
  canContain: false,
  canContainByStructure: false,
  applyMetaDataToFirstChild: {
    selectable: false,
    focusable: false,
    selectedBeforeDescendants: false,
    slideContainer: true,
    controlledByParent: true,
  },
  delegateControlsToFirstChild: {
    override: [ACCESSIBILITY],
  },
  overridesWhenReferred: {
    gfpp: {
      disableMainActionsByLabelKey: [
        constants.STATE_BOX.GFPP.ACTIONS.LABELS.MANAGE_STATES,
      ],
    },
  },
};
