import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Divider, RichText, Text, Thumbnails } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import * as textControls from '@/textControls';
import { SiteAccessPermission } from '@/constants';
import { fedopsLogger } from '@/util';
import { CustomPanelFrame } from '../../frames';
import connect, {
  type SiteAccessPanelDispatchProps,
  type SiteAccessPanelStateProps,
} from './siteAccessPanelMapper';
import styles from './siteAccessPanel.scss';
import bi, { ClosePanelOrigin, HelpClickOrigin } from './siteAccessPanelBi';
import {
  INVALID_MESSAGE_KEY,
  SITE_ACCESS_HELP_IDS,
  PASSWORD_INITIAL_VALUE,
  PERMISSION_TO_ORIGIN_MAP,
  PERMISSION_TO_HELP_ID_MAP,
} from './siteAccessPanelConstants';

export interface SiteAccessPanelOwnProps {
  panelName: string;
  permission: SiteAccessPermission;
}

interface SiteAccessPanelProps
  extends SiteAccessPanelOwnProps,
    SiteAccessPanelStateProps,
    SiteAccessPanelDispatchProps {}

const SiteAccessPanel: React.FC<SiteAccessPanelProps> = ({
  panelName,
  closePanel,
  openHelpCenter,
  removeSitePassword,
  updateSitePassword,
  permission: initialPermission,
}) => {
  useEffect(() => {
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.SITE_ACCESS.OPEN_PANEL,
    );
  }, []);

  const [translate] = useTranslation();
  const [permission, setPermission] = useState(initialPermission);
  const [password, setPassword] = useState(PASSWORD_INITIAL_VALUE);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isSaveAttempted, setIsSaveAttempted] = useState(false);

  const handlePermissionChange = useCallback(
    (value: SiteAccessPermission) => {
      setPermission(value);
      setIsSaveAttempted(false);
      bi.onPermissionChanged(value);

      if (permission !== SiteAccessPermission.Password) {
        setPassword(PASSWORD_INITIAL_VALUE);
        setIsPasswordValid(false);
      }
    },
    [permission],
  );

  const handleSaveButtonClick = useCallback(() => {
    fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.SITE_ACCESS.SAVE);

    setIsSaveAttempted(true);

    switch (permission) {
      case SiteAccessPermission.Everyone:
        removeSitePassword();
        bi.onSaveSuccess(permission);
        closePanel(ClosePanelOrigin.FooterSaveButton);
        break;
      case SiteAccessPermission.Password:
        if (!isPasswordValid) {
          bi.onSaveFailed(permission);
          break;
        }

        updateSitePassword(password);
        bi.onSaveSuccess(permission);
        closePanel(ClosePanelOrigin.FooterSaveButton);
        break;
      default:
        throw new Error(`Unknown permission: ${permission}`);
    }

    fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.SITE_ACCESS.SAVE);
  }, [
    password,
    permission,
    isPasswordValid,
    closePanel,
    updateSitePassword,
    removeSitePassword,
  ]);

  const handleCloseClick = useCallback(
    (origin: ClosePanelOrigin) => {
      closePanel(origin);
    },
    [closePanel],
  );

  const handleHelpClick = useCallback(() => {
    openHelpCenter(SITE_ACCESS_HELP_IDS.GENERAL, HelpClickOrigin.General);
  }, [openHelpCenter]);

  const handleLearnMoreClick = useCallback(() => {
    const helpId = PERMISSION_TO_HELP_ID_MAP[permission];
    const origin = PERMISSION_TO_ORIGIN_MAP[permission];

    openHelpCenter(helpId, origin, true);
  }, [openHelpCenter, permission]);

  const handlePasswordBlur = useCallback(
    (value: string, isValid: boolean) => {
      setPassword(value);
      setIsPasswordValid(isValid);

      if (value !== password) {
        bi.onPasswordChanged(isValid);
      }
    },
    [password],
  );

  const handlePasswordMarkedInvalid = useCallback(() => {
    bi.onPasswordMarkedInvalid(INVALID_MESSAGE_KEY);
  }, []);

  return (
    <div data-hook="site-access-panel">
      <CustomPanelFrame
        panelName={panelName}
        title={translate('site_password_modal_title')}
        primaryButtonText={translate('site_password_modal_save_button')}
        secondaryButtonText={translate('site_password_modal_cancel_button')}
        onHelpButtonClick={handleHelpClick}
        onPrimaryButtonClick={handleSaveButtonClick}
        onSecondaryButtonClick={() =>
          handleCloseClick(ClosePanelOrigin.FooterCancelButton)
        }
        onCloseButtonClick={() =>
          handleCloseClick(ClosePanelOrigin.HeaderCloseButton)
        }
        onEscKeyPress={() => handleCloseClick(ClosePanelOrigin.Keyboard)}
      >
        <div className={styles.text}>
          <Text size="medium" skin="secondary">
            site_password_modal_text
          </Text>
        </div>

        <div className={styles.thumbnails}>
          <Thumbnails
            dataHook="site-access-thumbnails"
            value={permission}
            maxThumbsPerRow={2}
            options={[
              {
                illustration: React.createElement(symbols.symbol, {
                  name: 'everyonePermissionThumbnailNew',
                }),
                value: SiteAccessPermission.Everyone,
                label: 'site_password_modal_everyone_label',
                dataHook: 'site-access-option-everyone',
              },
              {
                illustration: React.createElement(symbols.symbol, {
                  name: 'passwordPermissionThumbnailNew',
                }),
                value: SiteAccessPermission.Password,
                label: 'site_password_modal_password_holders_label',
                dataHook: 'site-access-option-password',
              },
            ]}
            borderType="thumbnailAndLabel"
            labelsType="T15"
            shoudAddTooltipOnEllipsis={false}
            onChange={handlePermissionChange}
          />
        </div>

        <RichText className={styles.text}>
          <Trans
            i18nKey={
              permission === SiteAccessPermission.Everyone
                ? `site_password_modal_everyone_text`
                : `site_password_modal_password_holders_text`
            }
          >
            <a onClick={handleLearnMoreClick} />
          </Trans>
        </RichText>

        {permission === SiteAccessPermission.Password && (
          <>
            <div className={styles.divider}>
              <Divider long />
            </div>
            <div className={styles.passwordControl}>
              <textControls.password
                label={translate(
                  'site_password_modal_password_holders_enter_password_label',
                )}
                placeholder={translate(
                  'site_password_modal_password_holders_enter_password_placeholder',
                )}
                onBlur={handlePasswordBlur}
                onMarkedInvalid={handlePasswordMarkedInvalid}
                validateInitialValue={isSaveAttempted && !isPasswordValid}
                showStubWhenEmpty={
                  initialPermission === SiteAccessPermission.Password
                }
                dataHook={'site-access-password-control'}
                invalidMessage={translate(INVALID_MESSAGE_KEY)}
              />
            </div>
          </>
        )}
      </CustomPanelFrame>
    </div>
  );
};

export default connect(SiteAccessPanel);
