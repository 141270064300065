._modal_mmb9m_1 .control-base-modal-header-btn-bar {
  margin-top: 0 !important; }

._content_mmb9m_4 {
  padding-top: 12px; }

._rating_mmb9m_7 {
  margin: 6px 0 24px; }

._textarea_mmb9m_10 {
  margin-top: 6px; }
