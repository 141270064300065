import React, {
  type FC,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { hoc } from '@/util';

import QuestionnairePanel from './components/questionnairePanel/questionnairePanel';
import ProgressBarPanel from './components/progressBarPanel/progressBarPanel';
import ErrorMessagePanel from './components/errorMessagePanel/errorMessagePanel';

import {
  mapDispatchToProps,
  mapStateToProps,
  mergeProps,
} from './welcomePanelMapper';
import { createBIeventsCallbacks } from './welcomePanelBI';
import { flattenObject } from './welcomePanelHelpers';
import experiment from 'experiment';

import type {
  QuestionnaireParams,
  ErrorBIData,
  OnInjectionSuccessFnParams,
} from './types';
import { PanelTypes } from './constants';

type Props = ReturnType<typeof mergeProps>;

enum Routes {
  QUESTIONNAIRE = 'form',
  LOADER = 'loader',
  ERROR = 'error',
  EMPTY = 'empty',
}

const WelcomePanel: FC<Props> = ({
  onClose,
  onSubmit,
  isFunnelDataApplicable,
  autoSubmitForm,
  highlightLeftBarMenu,
  unHighlightLeftBarMenu,
  sendBI,
  getParentId,
  getAdditionalBIData,
}) => {
  const { sendBIRequestOutput, sendBIUseTextBI, sendBITextChanged } =
    createBIeventsCallbacks(sendBI);

  const [route, setRoute] = useState(Routes.EMPTY);
  const [panelType, setPanelType] = useState<PanelTypes | null>(
    PanelTypes.InjectionToSite,
  );
  const [errorBIData, setErrorBIData] = useState<ErrorBIData | null>(null);

  const hasBeenLoaded = useRef(false);

  useEffect(() => {
    unHighlightLeftBarMenu();

    return () => {
      highlightLeftBarMenu();
    };
  }, [highlightLeftBarMenu, unHighlightLeftBarMenu]);

  const onSuccess = useCallback(
    ({
      requestParams,
      responseParams,
      requestStartTime,
      isAutosubmitFlow,
    }: OnInjectionSuccessFnParams) => {
      const duration = Date.now() - requestStartTime;

      const pageComponents = responseParams.map(
        ({ pageId, pageSuggestion }) => ({
          pageId,
          componentIds: Object.values(pageSuggestion?.idMap ?? {}),
        }),
      );
      const pageTokens = responseParams.map(({ pageId, pageSuggestion }) => ({
        pageId,
        tokens: pageSuggestion?.completionMetadata?.tokenUsage,
      }));
      const componentIds = pageComponents.reduce((acc, item) => {
        acc.push(...item.componentIds);
        return acc;
      }, []);

      const panelType = isAutosubmitFlow
        ? PanelTypes.AutoInjectionToSite
        : PanelTypes.InjectionToSite;

      sendBIRequestOutput({
        duration,
        query: requestParams,
        queryOutput: responseParams[0].pageSuggestion?.outline,
        pageComponents,
        pageTokens,
        prompt:
          responseParams[0].pageSuggestion?.completionMetadata?.promptsVersion,
        gptParams:
          responseParams[0].pageSuggestion?.completionMetadata
            ?.gptParamsVersion,
        panelType,
      });

      sendBIUseTextBI({
        duration,
        componentIds,
        panelType,
      });

      responseParams.forEach(({ pageSuggestion }) => {
        if (pageSuggestion === undefined) {
          return;
        }

        const flattOutline = flattenObject(pageSuggestion.outline);
        const flattOriginalOutline = flattenObject(
          pageSuggestion.originalOutline,
        );

        Object.keys(flattOutline).forEach((componentKey) => {
          const componentId = pageSuggestion.idMap[componentKey];
          const { componentType, pageId, parentComponentType, sectionType } =
            getAdditionalBIData(componentId);

          sendBITextChanged({
            componentId,
            componentType,
            pageId,
            parentComponentType,
            sectionType,
            parentComponentId: getParentId(componentId),
            text: flattOutline[componentKey as keyof typeof flattOutline],
            textOrigin:
              flattOriginalOutline[
                componentKey as keyof typeof flattOriginalOutline
              ],
          });
        });
      });

      onClose('closed after success result');
    },
    [
      onClose,
      sendBIRequestOutput,
      sendBIUseTextBI,
      sendBITextChanged,
      getParentId,
      getAdditionalBIData,
    ],
  );

  const handleSubmit = useCallback(
    (params: QuestionnaireParams) => {
      setRoute(Routes.LOADER);
      setPanelType(PanelTypes.InjectionToSite);
      onSubmit(params, false, onSuccess, (errorData) => {
        setErrorBIData({
          params,
          currentGptParamsVersion:
            errorData?.contentServerError?.currentGptParamsVersion ??
            errorData?.originalError?.extras?.currentGptParamsVersion,
          currentPromptsVersion:
            errorData?.contentServerError?.currentPromptsVersion ??
            errorData?.originalError?.extras?.currentPromptsVersion,
          tokenUsage: errorData.tokenUsage,
        });
        setRoute(Routes.ERROR);
      });
    },
    [onSuccess, onSubmit],
  );

  useEffect(() => {
    if (hasBeenLoaded.current) return;
    hasBeenLoaded.current = true;

    const isExperimentOpen = experiment.isOpen(
      'se_takeBusinessDescriptionFromFunnel',
    );

    if (!isExperimentOpen) {
      setRoute(Routes.QUESTIONNAIRE);
      return;
    }

    isFunnelDataApplicable((isApplicable) => {
      if (!isApplicable) {
        setRoute(Routes.QUESTIONNAIRE);
        return;
      }

      setRoute(Routes.LOADER);
      setPanelType(PanelTypes.AutoInjectionToSite);

      // eslint-disable-next-line no-console
      autoSubmitForm(onSuccess, (errorData) => {
        setErrorBIData({
          params: {
            businessType: undefined,
            businessName: '',
            businessDescription: '',
          },
          currentGptParamsVersion:
            errorData?.contentServerError?.currentGptParamsVersion ??
            errorData?.originalError?.extras?.currentGptParamsVersion,
          currentPromptsVersion:
            errorData?.contentServerError?.currentPromptsVersion ??
            errorData?.originalError?.extras?.currentPromptsVersion,
          tokenUsage: errorData.tokenUsage,
        });
        setRoute(Routes.ERROR);
      });
    });
  }, [autoSubmitForm, isFunnelDataApplicable, onSuccess]);

  const views = {
    [Routes.QUESTIONNAIRE]: (
      <QuestionnairePanel
        onClose={onClose}
        onSubmit={handleSubmit}
        sendBI={sendBI}
      />
    ),
    [Routes.LOADER]: <ProgressBarPanel />,
    [Routes.ERROR]: (
      <ErrorMessagePanel
        onClose={onClose}
        sendBI={sendBI}
        errorBIData={errorBIData}
        panelType={panelType}
      />
    ),
    [Routes.EMPTY]: <></>,
  };

  return <>{views[route]}</>;
};

const WelcomePanelConnect = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(WelcomePanel);

export default WelcomePanelConnect;
