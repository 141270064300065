import React from 'react';
import { Tooltip, Text, IconButton } from '@wix/wix-base-ui';
import { DismissSmall } from '@wix/wix-ui-icons-common';
import { formCollectionsMovement } from '../../../bi';
import type { BiEventDefinition, BiEventFields } from 'types/bi';

export interface FormsMigrationTooltipProps {
  children: React.ReactNode;
}

export const getFormsMigrationTooltip = ({
  hasFormsCollections,
  isPremium,
  haveSeenNotification,
  setHaveSeenNotification,
  sendBi,
}: {
  hasFormsCollections: boolean;
  isPremium: boolean;
  haveSeenNotification: boolean;
  setHaveSeenNotification: (value: boolean) => void;
  sendBi: (event: BiEventDefinition, params: BiEventFields) => void;
}) => {
  const sendBiEvent = (action: string) =>
    sendBi(formCollectionsMovement, {
      action,
      environment: 'EDITOR',
      origin: 'editor_cm',
      isPremium,
    });

  const dismissTooltip = () => {
    setHaveSeenNotification(true);
    sendBiEvent('dismiss');
  };
  const FormsMigrationTooltip: React.FC<FormsMigrationTooltipProps> = ({
    children,
  }) => {
    return (
      <Tooltip
        content={
          <div className="form-collections-migration-tooltip">
            <Text skin="secondary" size="large" enableEllipsis={false}>
              CONTENT_MANAGER_6_BUTTON_FormCollectionsMigration_Notification
            </Text>
            {
              <div className="close-button">
                <IconButton skin="light-dark" onClick={dismissTooltip}>
                  <DismissSmall />
                </IconButton>
              </div>
            }
          </div>
        }
        alignment="RIGHT"
        marginRight={20}
        maxWidth={260}
        interactive
        closeOnContentMouseClick
        onOpen={() => {
          sendBiEvent('show');
        }}
      >
        <div>{children}</div>
      </Tooltip>
    );
  };

  if (!hasFormsCollections || haveSeenNotification) {
    return null;
  }

  return FormsMigrationTooltip;
};
