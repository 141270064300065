import React, { type ReactNode } from 'react';

import { TreeSectionDivider } from '@wix/wix-base-ui';

import { cx } from '@/util';

import styles from './sectionizerPanel.scss';

interface SectionsItemProps {
  label: string;
  suffix?: ReactNode;
  children: ReactNode;
  highlighted?: boolean;
}

const SectionsItem: React.FC<SectionsItemProps> = (props) => {
  const [collapsed, setCollapsed] = React.useState(false);

  return (
    <div
      className={cx({
        [styles.treeItem]: true,
        [styles.treeItemHighlighted]: props.highlighted,
      })}
    >
      <TreeSectionDivider
        shouldTranslate={false}
        label={props.label}
        collapsed={collapsed}
        onClick={() => setCollapsed(!collapsed)}
        suffix={props.suffix}
        hideTopBorder={true}
        hideBottomBorder={true}
      />
      {collapsed ? null : props.children}
    </div>
  );
};

export default SectionsItem;
