:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._box_1daly_7 {
  position: absolute;
  border: 1px solid #80b1ff;
  margin-left: -1px;
  margin-top: -1px; }
  ._box_1daly_7._siteSegment_1daly_12 {
    border: 1px solid #fe620f; }
