import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import experiment from 'experiment';

import { searchModule } from '../searchModule';
import { Integrations } from '../constants';
import { openOrUpdatePanel } from '../utils/utils';

import type { EditorAPI } from '@/editorAPI';

const {
  ROOT_COMPS: {
    TOPBAR: { BUTTONS },
  },
} = constants;

const marketPlaceURL = experiment.isOpen('specs.SupportMultipleServicesBrief')
  ? 'https://www.wix.com/marketplace/multi/site/briefRoot?origin=editor'
  : 'https://www.wix.com/marketplace/brief-auto-match?entry=editor&origin=editor';

const getButtonsAPI = (editorAPI: EditorAPI) => ({
  [BUTTONS.SAVE]() {
    editorAPI.saveManager.save({
      origin: 'search',
      sourceOfStart: 'editorSearch_saveAction',
    });
  },
  [BUTTONS.PUBLISH]() {
    openOrUpdatePanel(editorAPI, 'panels.focusPanels.confirmPublishPanel');
  },
  [BUTTONS.PREVIEW]() {
    editorAPI.preview.togglePreview();
  },
  [BUTTONS.MOBILE_MODE]() {
    if (!editorAPI.isMobileEditor()) {
      editorAPI.setEditorMode(true, 'editorSearch_mobileModeBtn');
    }
  },
  [BUTTONS.ZOOM]() {
    editorAPI.zoomMode.enterZoomMode({ biParams: { origin: 'editorSearch' } });
  },
  [BUTTONS.UNDO]() {
    editorAPI.store.dispatch(stateManagement.history.actions.undo());
  },
  [BUTTONS.REDO]() {
    editorAPI.store.dispatch(stateManagement.history.actions.redo());
  },
  [BUTTONS.ARENA]() {
    window.open(marketPlaceURL, '_blank');
  },
});

export const integrateQuickActions = (editorAPI: EditorAPI) => {
  const buttonsAPI = getButtonsAPI(editorAPI);

  searchModule.addIntegration({
    type: Integrations.QUICK_ACTIONS,
    transform: (result) => {
      const {
        extras: { buttonID },
      } = result;
      const itemAction = buttonsAPI[buttonID as keyof typeof buttonsAPI];

      return {
        ...result,
        onSubmit: itemAction,
      };
    },
  });
};
