import type { EditorAPI } from '@/editorAPI';
import type { AnimationKitDefinition } from '../api/types';
import type { createGlobalAnimationsApi } from '../api/v3/globalAnimationsApi';

export const getSectionsToAnimate = (editorAPI: EditorAPI) => {
  const pageRef = editorAPI.pages.getFocusedPage();
  const [focusedFirstSection, ...focusedRestSections] =
    editorAPI.sections.getPageSectionsSortedByStageOrder(pageRef);
  const pageIds = editorAPI.pages.getPageIdList();
  const sectionsGroupedByPages = pageIds.map((pageId) =>
    editorAPI.sections.getPageSectionsSortedByStageOrder(
      editorAPI.components.get.byId(pageId),
    ),
  );
  const firstSections = sectionsGroupedByPages.map(
    (pageSections) => pageSections[0],
  );
  const restSections = sectionsGroupedByPages.flatMap((pageSections) =>
    pageSections.slice(1),
  );
  const header = editorAPI.siteSegments.getHeader();
  const footer = editorAPI.siteSegments.getFooter();

  return {
    focusedFirstSection,
    focusedRestSections,
    firstSections,
    restSections,
    header,
    footer,
  };
};

export const applySaveAndPreviewAnimations = async (
  editorAPI: EditorAPI,
  globalAnimationsApi: ReturnType<typeof createGlobalAnimationsApi>,
  animationKit: AnimationKitDefinition,
) => {
  const {
    focusedFirstSection,
    focusedRestSections,
    firstSections,
    restSections,
    header,
    footer,
  } = getSectionsToAnimate(editorAPI);
  const { title, headerFooterKit, firstSectionKit } = animationKit;

  globalAnimationsApi.setSiteAnimationKit(title, true);

  await editorAPI.transactions.run(async () => {
    const applyOnFirstSectionsPromise = firstSections.map((sectionRef) =>
      globalAnimationsApi.applyGlobalAnimations(sectionRef, firstSectionKit),
    );
    const applyOnRestSectionsPromise = restSections.map((sectionRef) =>
      globalAnimationsApi.applyGlobalAnimations(sectionRef, title),
    );
    const applyOnHeaderFooterPromise = [header, footer].map((sectionRef) =>
      globalAnimationsApi.applyGlobalAnimations(sectionRef, headerFooterKit),
    );

    await Promise.all([
      ...applyOnFirstSectionsPromise,
      ...applyOnRestSectionsPromise,
      ...applyOnHeaderFooterPromise,
    ]);
  });

  editorAPI.history.debouncedAdd('global animations applied');

  globalAnimationsApi.startPreview([header, footer], headerFooterKit);
  globalAnimationsApi.startPreview([focusedFirstSection], firstSectionKit);
  globalAnimationsApi.startPreview(focusedRestSections, title);
};
