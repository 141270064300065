import { isAppController } from '../../../utils';
import type { CompRef, DocumentServicesObject } from 'types/documentServices';

export const getMobileZIndex = (
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  compRef: CompRef,
  ordering: string[],
): number => {
  const parentRef: CompRef = documentServices.components.getContainer(compRef);

  // to support redundant nesting
  if (parentRef?.id !== pageRef.id) {
    return ordering.indexOf(parentRef.id);
  }

  return ordering.indexOf(compRef.id);
};

export const findClosest = <ArrayItemType>(
  arr: ArrayItemType[],
  callbackFn: AnyFixMe,
  fromIndex: number,
): ArrayItemType => {
  const rightDelta = arr.slice(fromIndex + 1).findIndex(callbackFn);
  const leftDelta = arr.slice(0, fromIndex).reverse().findIndex(callbackFn);

  let targetIndex;

  if (rightDelta === -1 && leftDelta === -1) {
    targetIndex = -1;
  } else if (rightDelta === -1 && leftDelta !== -1) {
    targetIndex = fromIndex - leftDelta - 1;
  } else if (rightDelta !== -1 && leftDelta === -1) {
    targetIndex = rightDelta + fromIndex + 1;
  } else if (rightDelta < leftDelta) {
    targetIndex = rightDelta + fromIndex + 1;
  } else {
    targetIndex = fromIndex - leftDelta - 1;
  }

  return targetIndex === -1 ? undefined : arr[targetIndex];
};

export interface SectionEdgePosition {
  position: number;
  isFullWidth: boolean;
}

export function calculateSectionTopEdge(
  items: AnyFixMe[],
): SectionEdgePosition {
  const [topItem] = items
    .filter((item: AnyFixMe) => item.top !== undefined)
    .sort((itemA: AnyFixMe, itemB: AnyFixMe) => itemA.top - itemB.top);

  if (!topItem) {
    return {
      position: 0,
      isFullWidth: false,
    };
  }

  return {
    position: Math.max(topItem.top, 0), // resolve negative Y
    isFullWidth: topItem.isFullWidth,
  };
}

export function calculateSectionBottomEdge(
  items: AnyFixMe[],
): SectionEdgePosition {
  const [bottomItem] = items
    .filter((item: AnyFixMe) => item.bottom !== undefined)
    .sort((itemA: AnyFixMe, itemB: AnyFixMe) => itemB.bottom - itemA.bottom);

  if (!bottomItem) {
    return {
      position: 0,
      isFullWidth: false,
    };
  }

  return {
    position: Math.max(bottomItem.bottom, 0), // resolve negative Y
    isFullWidth: bottomItem.isFullWidth,
  };
}

export function calculateSectionsEdge(
  upperSectionBottom: number,
  isUpperSectionFullWidth: boolean,
  lowerSectionTop: number,
  isLowerSectionFullWidth: boolean,
): number {
  if (upperSectionBottom < lowerSectionTop) {
    if (!isUpperSectionFullWidth && !isLowerSectionFullWidth) {
      const delta = Math.floor((lowerSectionTop - upperSectionBottom) / 2);
      return upperSectionBottom + delta;
    } else if (isUpperSectionFullWidth && !isLowerSectionFullWidth) {
      return upperSectionBottom;
    }
    return lowerSectionTop;
  } else if (upperSectionBottom > lowerSectionTop) {
    if (!isLowerSectionFullWidth) {
      return upperSectionBottom;
    }
    return lowerSectionTop;
  }
}

export function getLastCompPosition(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
): number {
  const rootComponentsBottom = documentServices.components
    .getChildren(pageRef)
    .map((compRef: CompRef) => {
      const layout = documentServices.components.layout.get(compRef);

      if (!layout || isAppController(documentServices, compRef)) {
        return 0;
      }

      return layout.y + layout.height;
    })
    .sort((a, b) => b - a);

  return rootComponentsBottom[0] || 0;
}
