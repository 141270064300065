'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_columns_testimonials",
    "subNavigationTitle": "add_section_label_columns_testimonials",
    "presetTitle": "add_section_label_columns_testimonials",
    "tooltipTitle": "add_section_label_columns_testimonials",
    "automationId": "add-panel-section-columnsTestimonialsSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/columnsTestimonialsSection_en/columnsTestimonialsSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "Columns_ColumnsTestimonials_1",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": -136,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "GettyImages-180928679.jpg",
                                "uri": "035244_8a40c3e0d53d41148879ebac9f0b297f.jpg",
                                "description": "private/",
                                "width": 5616,
                                "height": 3744,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "{color_18}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1"
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 246,
                            "height": 207,
                            "x": 0,
                            "y": 90,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size: 22px; line-height: 1.5em; text-align: center;\"><span style=\"color:#BC9B5D\"><span style=\"font-size:22px\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">&ldquo;Testimonials work great. Showing your reviews in quotes&nbsp;has a powerful effect on customers and makes them trust you.&rdquo;</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 244,
                            "height": 21,
                            "x": 0,
                            "y": 339,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">&mdash; Name, Title</span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": 200,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FCF8ED",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1"
                }, {
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": 536,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "GettyImages-185007031.jpg",
                                "uri": "035244_eda4fe580f524ec1ae24aa2ea3c43180.jpg",
                                "description": "private/allMedia_picture",
                                "width": 3872,
                                "height": 2592,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "{color_18}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1"
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 246,
                            "height": 207,
                            "x": 0,
                            "y": 90,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size: 22px; line-height: 1.5em; text-align: center;\"><span style=\"color:#BC9B5D\"><span style=\"font-size:22px\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\">&ldquo;Testimonials work great. Showing your reviews in quotes&nbsp;has a powerful effect on customers and makes them&nbsp;trust you.&rdquo;</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 243,
                            "height": 21,
                            "x": 1,
                            "y": 339,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"text-align:center\"><span class=\"color_14\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">&mdash; Name, Title</span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }],
                    "layout": {
                        "width": 244,
                        "height": 450,
                        "x": 871,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FCF8ED",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1"
                }],
                "layout": {
                    "width": 980,
                    "height": 450,
                    "x": 0,
                    "y": 100,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    },
                    "charas": "design-iv108e3p"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "activeModes": {},
                "id": "comp-ioiomfd3"
            },
            "preset": {
                "rect": {"width": 324, "height": 130, "x": -0.8, "y": 0},
                "label": "add_preset_columns_testimonials_1",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsTestimonials_2",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                        "layout": {
                            "width": 200,
                            "height": 200,
                            "x": 63,
                            "y": 91,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "GettyImages-535587703.jpg",
                            "uri": "035244_429896c0c9db41bdbeee497c2b6a090c~mv2_d_4676_4871_s_4_2.jpg",
                            "description": "",
                            "width": 4676,
                            "height": 4871,
                            "alt": ""
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "kennedy"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brw": "0px",
                                    "shd": "0px 1px 3px 0px rgba(0,0,0,0.5)"
                                },
                                "propertiesSource": {"brd": "value", "brw": "value", "shd": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.WPhoto",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 322,
                            "height": 29,
                            "x": 2,
                            "y": 337,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:26px; line-height:1em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"font-size:26px\"><span style=\"color:#FF6161\">Name, Title</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 273,
                            "height": 80,
                            "x": 26,
                            "y": 384,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"line-height:1.7em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"color:#000000\">Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.</span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a",
                        "layout": {
                            "width": 34,
                            "height": 32,
                            "x": 145,
                            "y": 523,
                            "scale": 1,
                            "rotationInDegrees": 180,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#F27979",
                                    "stroke": "#ED1566",
                                    "strokewidth": "0"
                                },
                                "propertiesSource": {
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a"
                        }
                    }],
                    "layout": {
                        "width": 324,
                        "height": 665,
                        "x": -119,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FAFAFA",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1"
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 271,
                            "height": 80,
                            "x": 23,
                            "y": 384,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"line-height:1.7em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"color:#000000\">Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.</span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 312,
                            "height": 29,
                            "x": 2,
                            "y": 337,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:26px; line-height:1em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"font-size:26px\"><span style=\"color:#FF6161\">Name, Title</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                        "layout": {
                            "width": 200,
                            "height": 200,
                            "x": 58,
                            "y": 91,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "GettyImages-124893619.jpg",
                            "uri": "035244_49fa1767a96f459f8a97bf165f96784b~mv2_d_4676_4871_s_4_2.jpg",
                            "description": "",
                            "width": 4676,
                            "height": 4871,
                            "alt": ""
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "kennedy"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "0",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FF6161",
                                    "brw": "0px",
                                    "shd": "0px 1px 3px 0px rgba(0,0,0,0.5)"
                                },
                                "propertiesSource": {
                                    "alpha-brd": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.WPhoto",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                        }
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a",
                        "layout": {
                            "width": 34,
                            "height": 32,
                            "x": 141,
                            "y": 523,
                            "scale": 1,
                            "rotationInDegrees": 180,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#F27979",
                                    "stroke": "#ED1566",
                                    "strokewidth": "0"
                                },
                                "propertiesSource": {
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a"
                        }
                    }],
                    "layout": {
                        "width": 316,
                        "height": 665,
                        "x": 339,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FAFAFA",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1"
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                        "layout": {
                            "width": 200,
                            "height": 200,
                            "x": 58,
                            "y": 96,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "GettyImages-145680711.jpg",
                            "uri": "035244_8ea2789a36434fa5abddc1502b977886~mv2_d_4676_4871_s_4_2.jpg",
                            "description": "",
                            "width": 4676,
                            "height": 4871,
                            "alt": ""
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "kennedy"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brw": "0",
                                    "shd": "0px 1px 3px 0px rgba(0,0,0,0.5)"
                                },
                                "propertiesSource": {"brd": "value", "brw": "value", "shd": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.WPhoto",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 317,
                            "height": 29,
                            "x": 0,
                            "y": 337,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:26px; line-height:1em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"font-size:26px\"><span style=\"color:#FF6161\">Name, Title</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 263,
                            "height": 80,
                            "x": 27,
                            "y": 384,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"line-height:1.7em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"color:#000000\">Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.</span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a",
                        "layout": {
                            "width": 34,
                            "height": 32,
                            "x": 142,
                            "y": 522,
                            "scale": 1,
                            "rotationInDegrees": 180,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#F27979",
                                    "stroke": "#ED1566",
                                    "strokewidth": "0"
                                },
                                "propertiesSource": {
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a"
                        }
                    }],
                    "layout": {
                        "width": 316,
                        "height": 665,
                        "x": 786,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FAFAFA",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1"
                }],
                "layout": {
                    "width": 980,
                    "height": 665,
                    "x": 0,
                    "y": 750,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 11,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "mediaRef": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": "Modern Living Room",
                            "uri": "83c5b99cb1344ae1b625d133cdb2905c.jpg",
                            "description": "public/491df47b-5958-4dc7-9029-37f428755d45/47ec63e8-89c6-4665-b7f6-f5be6da222ff",
                            "width": 5500,
                            "height": 3301,
                            "alt": "",
                            "artist": {
                                "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_",
                                "name": ""
                            },
                            "opacity": 0.1
                        },
                        "color": "#FFFFFF",
                        "colorOpacity": 1,
                        "alignType": "bottom",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 0
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "activeModes": {},
                "id": "comp-iol3b8j6"
            },
            "preset": {
                "rect": {"width": 324, "height": 173, "x": -0.8, "y": 130},
                "label": "add_preset_columns_testimonials_2",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsTestimonials_3",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Container",
                        "components": [{
                            "type": "Container",
                            "components": [{
                                "type": "Component",
                                "layout": {
                                    "width": 308,
                                    "height": 20,
                                    "x": 6,
                                    "y": 78,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size:18px; line-height:1em; text-align:center\"><span style=\"font-size:18px\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"color:#FF6161\">NAME, TITLE</span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew",
                                "mobileStructure": {
                                    "type": "Component",
                                    "layout": {
                                        "width": 136,
                                        "height": 20,
                                        "x": 92,
                                        "y": 47,
                                        "scale": 1,
                                        "rotationInDegrees": 0,
                                        "fixedPosition": false
                                    },
                                    "componentType": "wysiwyg.viewer.components.WRichText",
                                    "data": {
                                        "type": "StyledText",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "text": "<h2 class=\"font_2\" style=\"font-size:18px; line-height:1em; text-align:center\"><span style=\"font-size:18px\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"color:#FF6161\">NAME, TITLE</span></span></span></h2>\n",
                                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                        "linkList": []
                                    },
                                    "props": {
                                        "type": "WRichTextProperties",
                                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                        "brightness": 1,
                                        "packed": true
                                    },
                                    "style": "txtNew"
                                }
                            }, {
                                "type": "Component",
                                "layout": {
                                    "width": 208,
                                    "height": 102,
                                    "x": 56,
                                    "y": 120,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"line-height:1.7em; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-family:bree-w01-thin-oblique,sans-serif\">Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.</span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }, {
                                "type": "Component",
                                "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a",
                                "layout": {
                                    "width": 34,
                                    "height": 32,
                                    "x": 141,
                                    "y": 281,
                                    "scale": 1,
                                    "rotationInDegrees": 180,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "data": {
                                    "type": "SvgShape",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                                },
                                "props": {
                                    "type": "SvgShapeProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "maintainAspectRatio": true
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-fillcolor": "1",
                                            "alpha-stroke": "1",
                                            "fillcolor": "#FF6161",
                                            "stroke": "#ED1566",
                                            "strokewidth": "0"
                                        },
                                        "propertiesSource": {
                                            "fillcolor": "value",
                                            "stroke": "value",
                                            "strokewidth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a"
                                }
                            }],
                            "layout": {
                                "width": 316,
                                "height": 350,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "mediaRef": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "title": "Fashion Portrait of a Bearded Man",
                                        "uri": "83524445ca90408594501cacb2567307.jpg",
                                        "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                        "width": 4044,
                                        "height": 3639,
                                        "alt": "",
                                        "artist": {
                                            "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_",
                                            "name": ""
                                        },
                                        "opacity": 0.19
                                    },
                                    "color": "#045184",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0
                                }
                            },
                            "style": "mc1"
                        }, {
                            "type": "Container",
                            "components": [{
                                "type": "Component",
                                "layout": {
                                    "width": 306,
                                    "height": 20,
                                    "x": 0,
                                    "y": 78,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size:18px; line-height:1em; text-align:center\"><span style=\"color:#FF6161;\"><span style=\"font-size:18px\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\">NAME, TITLE</span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }, {
                                "type": "Component",
                                "layout": {
                                    "width": 206,
                                    "height": 102,
                                    "x": 49,
                                    "y": 120,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"line-height:1.7em; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-family:bree-w01-thin-oblique,sans-serif\">Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.</span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }, {
                                "type": "Component",
                                "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a",
                                "layout": {
                                    "width": 34,
                                    "height": 32,
                                    "x": 135,
                                    "y": 281,
                                    "scale": 1,
                                    "rotationInDegrees": 180,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "data": {
                                    "type": "SvgShape",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                                },
                                "props": {
                                    "type": "SvgShapeProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "maintainAspectRatio": true
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-fillcolor": "1",
                                            "alpha-stroke": "1",
                                            "fillcolor": "#FF6161",
                                            "stroke": "#ED1566",
                                            "strokewidth": "0"
                                        },
                                        "propertiesSource": {
                                            "fillcolor": "value",
                                            "stroke": "value",
                                            "strokewidth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a"
                                }
                            }],
                            "layout": {
                                "width": 307,
                                "height": 350,
                                "x": 341,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "mediaRef": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "title": "Happy Man",
                                        "uri": "df81d0146a554f6c9a51a21ae7d95d4f.jpg",
                                        "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                        "width": 5760,
                                        "height": 3840,
                                        "alt": "",
                                        "artist": {
                                            "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_",
                                            "name": ""
                                        },
                                        "opacity": 0.15
                                    },
                                    "color": "#045184",
                                    "colorOpacity": 1,
                                    "alignType": "right",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0
                                }
                            },
                            "style": "mc1"
                        }, {
                            "type": "Container",
                            "components": [{
                                "type": "Component",
                                "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a",
                                "layout": {
                                    "width": 34,
                                    "height": 32,
                                    "x": 136,
                                    "y": 281,
                                    "scale": 1,
                                    "rotationInDegrees": 180,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "data": {
                                    "type": "SvgShape",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                                },
                                "props": {
                                    "type": "SvgShapeProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "maintainAspectRatio": true
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-fillcolor": "1",
                                            "alpha-stroke": "1",
                                            "fillcolor": "#FF6161",
                                            "stroke": "#ED1566",
                                            "strokewidth": "0"
                                        },
                                        "propertiesSource": {
                                            "fillcolor": "value",
                                            "stroke": "value",
                                            "strokewidth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a"
                                }
                            }, {
                                "type": "Component",
                                "layout": {
                                    "width": 206,
                                    "height": 102,
                                    "x": 50,
                                    "y": 120,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"line-height:1.7em; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-family:bree-w01-thin-oblique,sans-serif\">Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.</span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }, {
                                "type": "Component",
                                "layout": {
                                    "width": 299,
                                    "height": 20,
                                    "x": 2,
                                    "y": 78,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size:18px; line-height:1em; text-align:center\"><span style=\"font-size:18px\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"color:#FF6161\">NAME, TITLE</span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }],
                            "layout": {
                                "width": 307,
                                "height": 350,
                                "x": 673,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.Column",
                            "props": {
                                "type": "ColumnProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "alignment": 50
                            },
                            "design": {
                                "type": "MediaContainerDesignData",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "background": {
                                    "type": "BackgroundMedia",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "mediaRef": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "title": "man2.jpg",
                                        "uri": "035244_eac0cb69cd9842838188176a1cf524a6~mv2_d_5122_3415_s_4_2.jpg",
                                        "description": "private/",
                                        "width": 5122,
                                        "height": 3415,
                                        "alt": "",
                                        "artist": {"id": "mobile_mobile_", "name": ""},
                                        "opacity": 0.2
                                    },
                                    "color": "#045184",
                                    "colorOpacity": 1,
                                    "alignType": "center",
                                    "fittingType": "fill",
                                    "scrollType": "none",
                                    "colorOverlay": "",
                                    "colorOverlayOpacity": 0
                                }
                            },
                            "style": "mc1"
                        }],
                        "layout": {
                            "width": 980,
                            "height": 350,
                            "x": 0,
                            "y": 181,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                        "props": {
                            "type": "StripColumnsContainerProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullWidth": false,
                            "columnsMargin": 25,
                            "frameMargin": 0,
                            "rowMargin": 0,
                            "siteMargin": 0
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "{color_11}",
                                "colorOpacity": 0,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "strc1"
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 918,
                            "height": 60,
                            "x": 31,
                            "y": 70,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:46px; text-align:center\"><span style=\"letter-spacing:0.2em\"><span style=\"color:#FC8B8B\"><span style=\"font-size:46px\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\"><span style=\"font-weight:bold\">WHAT PEOPLE SAY</span></span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }],
                    "layout": {
                        "width": 980,
                        "height": 630,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FF6161",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1"
                }],
                "layout": {
                    "width": 980,
                    "height": 630,
                    "x": 0,
                    "y": 1615,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "activeModes": {},
                "id": "comp-ioy3tqul"
            },
            "preset": {
                "rect": {"width": 324, "height": 166, "x": -0.8, "y": 303},
                "label": "add_preset_columns_testimonials_3",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsTestimonials_4",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 490,
                        "height": 660,
                        "x": -90,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Young Man with Glasses",
                                "uri": "8fe2b2444c874371a0bad8419bee3e9a.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                "width": 1181,
                                "height": 1523,
                                "alt": "",
                                "artist": {"id": "mobile_", "name": ""}
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "mc1"
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 322,
                            "height": 222,
                            "x": 1,
                            "y": 226,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:28px; line-height:1.5em\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#000000\"><span style=\"font-size:28px\">Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 314,
                            "height": 18,
                            "x": 0,
                            "y": 495,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:16px; line-height:1em\"><span style=\"color:#0F0F0F;\"><span style=\"font-size:16px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">Name, Title</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a",
                        "layout": {
                            "width": 46,
                            "height": 42,
                            "x": 0,
                            "y": 165,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#FFEF98",
                                    "stroke": "#ED1566",
                                    "strokewidth": "0"
                                },
                                "propertiesSource": {
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a"
                        }
                    }],
                    "layout": {
                        "width": 490,
                        "height": 660,
                        "x": 580,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "mc1"
                }],
                "layout": {
                    "width": 980,
                    "height": 660,
                    "x": 0,
                    "y": 2445,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "activeModes": {},
                "id": "comp-ip6p4c7a"
            },
            "preset": {
                "rect": {"width": 324, "height": 172, "x": -0.8, "y": 469},
                "label": "add_preset_columns_testimonials_4",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsTestimonials_5",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 680,
                            "height": 66,
                            "x": 150,
                            "y": 130,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:46px; text-align:center\"><span style=\"color:#636363;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"font-size:46px\"><span style=\"font-weight:bold\"><span style=\"letter-spacing:0.25em\">TESTIMONIALS</span></span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 623,
                            "height": 69,
                            "x": 179,
                            "y": 251,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:14px; line-height:2.5em; text-align:center\"><span style=\"letter-spacing:0.14em\"><span style=\"color:#D4D4D4\"><span style=\"font-size:14px\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\">&ldquo;DOUBLE CLICK HERE OR CLICK EDIT TEXT TO ADD SOME POSITIVE FEEDBACK ABOUT YOUR SERVICES&quot;</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 623,
                            "height": 26,
                            "x": 179,
                            "y": 345,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.6em; text-align:center\"><span style=\"color:#636363;\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif\"><span style=\"font-size:15px\"><span style=\"letter-spacing:0.05em\">NAME / JOB / TITLE</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 623,
                            "height": 69,
                            "x": 179,
                            "y": 414,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:14px; line-height:2.5em; text-align:center\"><span style=\"letter-spacing:0.14em\"><span style=\"color:#D4D4D4\"><span style=\"font-size:14px\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\">&ldquo;DOUBLE CLICK HERE OR CLICK EDIT TEXT TO ADD SOME POSITIVE FEEDBACK ABOUT YOUR SERVICES&quot;</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 623,
                            "height": 26,
                            "x": 179,
                            "y": 508,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.6em; text-align:center\"><span style=\"color:#636363;\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif\"><span style=\"font-size:15px\"><span style=\"letter-spacing:0.05em\">NAME / JOB / TITLE</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew"
                    }],
                    "layout": {
                        "width": 980,
                        "height": 680,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Modern Kitchen",
                                "uri": "ff2c0fa76a5347f3b0f298d9feb685de.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/47ec63e8-89c6-4665-b7f6-f5be6da222ff",
                                "width": 2000,
                                "height": 1334,
                                "alt": "",
                                "artist": {
                                    "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_",
                                    "name": ""
                                },
                                "opacity": 0.03
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1"
                }],
                "layout": {
                    "width": 980,
                    "height": 680,
                    "x": 0,
                    "y": 3304,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "activeModes": {},
                "id": "comp-ioy61ged"
            },
            "preset": {
                "rect": {"width": 324, "height": 175.8, "x": -0.8, "y": 641},
                "label": "add_preset_columns_testimonials_5",
                "tags": null
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.StripColumnsContainer"]
    },
    "help": {"hide": false, "text": "add_section_info_text_column_testimonials"}
}
