import React from 'react';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import * as panels from '@/panels';
import * as symbols from '@wix/santa-editor-symbols';
import ListParser, { type PanelTag } from '../ListParser/listParser';
import constants from '@/constants';
class PlatformErrorPanel extends React.Component<PlatformErrorPanelProps> {
  static defaultProps = {
    secondaryActionText: translate('PLATFORM_messages_cancel'),
  };
  getKeyboardShortcuts = () => ({
    esc: () => this.props.closePanel(constants.PANEL_RESOLVE_TYPE.CLOSE_ACTION),
  });

  secClick = (origin?: string) => {
    if (origin === panels.PanelCloseOrigin.HEADER_CLOSE_BUTTON) {
      return this.props.closePanel(constants.PANEL_RESOLVE_TYPE.CLOSE_ACTION);
    }
    this.props.closePanel(constants.PANEL_RESOLVE_TYPE.SECONDARY_ACTION);
  };

  mainClick = () => {
    this.props.closePanel(constants.PANEL_RESOLVE_TYPE.MAIN_ACTION);
  };

  getIllustration = () =>
    this.props.illustration ? (
      <img
        src={this.props.illustration}
        data-aid="illustration"
        className="error-panel-illustration"
      />
    ) : (
      <symbols.symbol
        name="deleteGeneralIcon_NewWorkspace"
        className="svg-symbol"
      />
    );

  getDescriptions = () => (
    <>
      <ListParser description={this.props.topDescriptionText} />
      {this.props.bottomDescriptionText ? (
        <ListParser description={this.props.bottomDescriptionText} />
      ) : null}
    </>
  );

  render() {
    return (
      <panels.frames.MessagePanelFrame
        dataHook="platform-error-panel"
        panelName={this.props.panelName}
        onConfirm={this.mainClick}
        confirmLabel={this.props.mainActionText}
        title={this.props.headerText}
        illustration={
          this.props.shouldShowIllustration ? this.getIllustration() : undefined
        }
        onHelp={this.props.helpId && this.props.openHelpCenter}
        onCancel={this.secClick}
        cancelLabel={this.props.secondaryActionText}
        theme="destructive"
      >
        {this.getDescriptions()}
      </panels.frames.MessagePanelFrame>
    );
  }
}

const mapDispatchToProps = (dispatch: AnyFixMe, ownProps: AnyFixMe) => {
  const closePanel = (origin: ErrorPanelResolveType) => {
    dispatch(
      stateManagement.panels.actions.closePanelByNameAction(ownProps.panelName),
    );
    ownProps.onPanelClose(origin);
  };

  const openHelpCenter = () =>
    dispatch(stateManagement.panels.actions.openHelpCenter(ownProps.helpId));

  return {
    closePanel,
    openHelpCenter,
  };
};

export type ErrorPanelResolveType =
  (typeof constants.PANEL_RESOLVE_TYPE)[keyof typeof constants.PANEL_RESOLVE_TYPE];

interface PlatformErrorPanelProps {
  closePanel(data: ErrorPanelResolveType): void;
  helpId?: string;
  openHelpCenter(): void;
  headerText: string;
  illustration?: string;
  topDescriptionText: string | PanelTag[];
  bottomDescriptionText?: string | PanelTag[];
  secondaryActionText?: string;
  mainActionText: string;
  onPanelClose(panelResolve: ErrorPanelResolveType): void;
  panelName: string;
  shouldShowIllustration: boolean;
}

export default util.hoc.connect(
  util.hoc.STORES.DS_ONLY,
  null,
  mapDispatchToProps,
)(PlatformErrorPanel);
