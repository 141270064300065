// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import React from 'react';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'overlayWithHole',
  mixins: [core.mixins.editorAPIMixin],
  getHoleLayout() {
    return this.props.hole
      ? this.getEditorAPI().components.layout.getRelativeToScreen(
          this.props.hole,
        )
      : {};
  },
  getStyles() {
    const layout = this.getHoleLayout();
    const styles = {
      width: layout.width,
      height: layout.height,
      top: layout.y,
      left: layout.x,
      position: 'absolute',
      boxShadow: `0 0 0 9999px ${this.props.background}`,
    };

    if (layout.rotationInDegrees) {
      styles.transform = `rotate(${layout.rotationInDegrees}deg)`;
    }

    return styles;
  },
  render() {
    return (
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
        }}
      >
        <div style={this.getStyles()} />
      </div>
    );
  },
});
