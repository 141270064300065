import * as stateManagement from '@/stateManagement';

import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import constants from '@/constants';

export interface TestSitePanelDispatchProps {
  openPanel: (panelName: string) => void;
  sendBi: (event: BiEventDefinition, parameters: BiEventFields) => void;
}

export interface TestSitePanelStateProps {
  actionChoice: string;
  sitePublicUrl: string;
}

const mapStateToProps: MapStateToProps = ({ editorAPI }) => {
  const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;

  const actionChoice = getSiteUserPreferences(
    constants.USER_PREFS.TEST_SITE.TEST_SITE_BUTTON_TITLE,
  )(editorAPI.store.getState());

  return {
    sitePublicUrl: editorAPI.site.getSitePublicUrl() || '',
    actionChoice: actionChoice as string,
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
): EditorAPI => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  TestSitePanelDispatchProps,
  unknown
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    openPanel: (panelName: string) => {
      dispatch(stateManagement.panels.actions.updateOrOpenPanel(panelName));
    },
    sendBi: editorAPI.bi.event,
  };
};

export { mapStateToProps };
