import constants from '@/constants';
import type { GFPPActionType } from '@wix/editor-component-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

interface MainActionsDescriptors {
  [key: string]: {
    label: string;
    actionType: GFPPActionType;
  };
}

const mainActionsDescriptors: MainActionsDescriptors = {
  [ACTIONS.BACKGROUND]: {
    label: 'gfpp_secondaryaction_multiStateBox',
    actionType: ACTIONS.BACKGROUND,
  },
  [ACTIONS.LAYOUT]: {
    label: 'gfpp_action_label_layout',
    actionType: ACTIONS.LAYOUT,
  },
};

const getMainActionDescriptor = (actionType: GFPPActionType) => {
  return mainActionsDescriptors[actionType];
};

export { getMainActionDescriptor };
