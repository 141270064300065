import type { Scope } from '@/sections';
import { fixSingleSectionOverheight } from './fixSingleSectionOverheight';
import { removeSectionsGaps } from './removeSectionsGaps';
import { ErrorReporter } from '@wix/editor-error-reporter';

import experiment from 'experiment';

export const enforceSectionsLayoutOnHomepage = async (scope: Scope) => {
  if (experiment.isOpen('se_fixSectionNegativeYPosition')) {
    const { editorAPI } = scope;
    const pageRef = editorAPI.pages.getCurrentPage();

    try {
      const isLayoutUpdated = await fixSingleSectionOverheight(
        scope,
        pageRef,
        true,
      );
      if (isLayoutUpdated) {
        await removeSectionsGaps(scope, pageRef);
      }
    } catch (error: MaybeError) {
      error.name = `[SectionsOnBootstrapEnforcement]: ${error.name}`;
      ErrorReporter.captureException(error);
    }
  }
};
