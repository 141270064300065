import experiment from 'experiment';

import constants, { SiteAccessPermission } from '@/constants';
import * as util from '@/util';

import type { SaveInteractionStartedSource } from 'types/fedops/saveInteraction';

const { SETTINGS_MENU_ITEMS } = constants.ROOT_COMPS.TOPBAR;
const SAVE_YOUR_SITE_PANEL_NAME = 'savePublish.panels.save.saveYourSitePanel';
const DEFAULT_SAVE_YOUR_SITE_PANEL_BODY_KEY =
  util.workspace.isNewWorkspaceEnabled()
    ? 'TOPBAR_SITE_DASHBOARD_FIRST_SAVE_SITE_TEXT'
    : 'TOPBAR_SITE_DASHBOARD_SAVE_FIRST_MSG_BODY';

export interface SettingsMenuAPIDispatchProps {
  save: (origin?: string, sourceOfStart?: SaveInteractionStartedSource) => void;
  getInvalidLoggedUser: () => Promise<string | null>;
  handleInvalidLoggedUser: (invalidStatus: string) => void;
  sendBiForLoginRelatedError: () => void;
  openSettings: (tabName: string, referrer?: string) => void;
  openPremiumSettings: (tab: string) => void;
  openPanel: (
    panelName: string,
    panelProps?: Record<string, any>,
    leavePanelsOpen?: boolean,
  ) => void;
  upgrade: (referralAdditionalInfo: string) => void;
  openWixSEOWiz: () => void;
  exitInteractionModeIfNeeded: () => void;
  manage: () => void;
  openAccessibilityWizard: () => void;
  notifyMultilingualAppToOpen: () => void;
  openAddPage404ErrorPage: () => void;
}

export interface SettingsMenuAPIStateProps {
  isFirstSave: boolean;
  isDraftMode: boolean;
  isFirstManualSave: boolean;
  isMobileOptimizedSite: boolean;
  isSitePasswordEnabled: boolean;
}

export interface SettingsMenuAPIOwnProps {}

export interface SettingsMenuAPIProps
  extends SettingsMenuAPIOwnProps,
    SettingsMenuAPIStateProps,
    SettingsMenuAPIDispatchProps {}

export const getSettingsMenuAPI = (props: SettingsMenuAPIProps) => {
  const shouldDisplaySaveYourSitePanel = experiment.isOpen(
    'se_forceSavePanelBeforeFirstManualSave',
  )
    ? props.isFirstSave || props.isDraftMode || props.isFirstManualSave
    : props.isFirstSave || props.isDraftMode;
  const openSaveYourSitePanel = (
    titleKey: string = 'TOPBAR_SITE_SETTINGS_SAVE_FIRST_MSG_TITLE',
    messageBodyKey: string = DEFAULT_SAVE_YOUR_SITE_PANEL_BODY_KEY,
  ) => {
    const leavePanelsOpen = true;

    props.openPanel(
      SAVE_YOUR_SITE_PANEL_NAME,
      {
        title: titleKey,
        messageBody: messageBodyKey,
      },
      leavePanelsOpen,
    );
  };

  return {
    [SETTINGS_MENU_ITEMS.CONNECT_DOMAIN]: async () => {
      const invalidStatus = await props.getInvalidLoggedUser();
      if (invalidStatus) {
        props.handleInvalidLoggedUser(invalidStatus);
        props.sendBiForLoginRelatedError();
        return;
      }
      if (props.isFirstSave || props.isDraftMode) {
        props.save();
      } else {
        props.openSettings('domains');
      }
    },
    [SETTINGS_MENU_ITEMS.MAILBOX]: async () => {
      const invalidStatus = await props.getInvalidLoggedUser();
      if (invalidStatus) {
        props.handleInvalidLoggedUser(invalidStatus);
        props.sendBiForLoginRelatedError();
        return;
      }
      if (shouldDisplaySaveYourSitePanel) {
        openSaveYourSitePanel();
      } else {
        props.openSettings('mailboxes');
      }
    },
    [SETTINGS_MENU_ITEMS.UPGRADE]() {
      if (shouldDisplaySaveYourSitePanel) {
        openSaveYourSitePanel(
          'TOPBAR_SITE_UPGRADE_SAVE_FIRST_MSG_TITLE',
          'TOPBAR_SITE_UPGRADE_SAVE_FIRST_MSG_BODY',
        );
      } else if ((props as AnyFixMe).isPremium) {
        props.openPremiumSettings('plans');
      } else {
        props.upgrade('edhtml_TOP_BAR_SITE_MENU_UPGRADE');
      }
    },
    [SETTINGS_MENU_ITEMS.GO_TO_SEO_WIZ]() {
      if (shouldDisplaySaveYourSitePanel) {
        openSaveYourSitePanel();
      } else {
        props.openWixSEOWiz();
      }
    },
    [SETTINGS_MENU_ITEMS.FAVICON]: async () => {
      const invalidStatus = await props.getInvalidLoggedUser();
      if (invalidStatus) {
        props.handleInvalidLoggedUser(invalidStatus);
        props.sendBiForLoginRelatedError();
        return;
      }
      if (shouldDisplaySaveYourSitePanel) {
        openSaveYourSitePanel();
      } else {
        props.openSettings('favicon');
      }
    },
    [SETTINGS_MENU_ITEMS.BUSINESS_INFO]: async () => {
      const invalidStatus = await props.getInvalidLoggedUser();
      if (invalidStatus) {
        props.handleInvalidLoggedUser(invalidStatus);
        props.sendBiForLoginRelatedError();
        return;
      }
      if (shouldDisplaySaveYourSitePanel) {
        openSaveYourSitePanel();
      } else {
        props.openSettings('business');
      }
    },
    [SETTINGS_MENU_ITEMS.SITE_DASHBOARD]() {
      if (shouldDisplaySaveYourSitePanel) {
        openSaveYourSitePanel();
      } else {
        props.manage();
      }
    },
    [SETTINGS_MENU_ITEMS.CUSTOM_ERROR_PAGE]() {
      props.exitInteractionModeIfNeeded();
      if (shouldDisplaySaveYourSitePanel) {
        openSaveYourSitePanel(
          'TOPBAR_SITE_UPGRADE_SAVE_FIRST_MSG_TITLE',
          'TOPBAR_SITE_UPGRADE_SAVE_FIRST_MSG_BODY',
        );

        return;
      }
      props.openAddPage404ErrorPage();
    },
    [SETTINGS_MENU_ITEMS.MULTILINGUAL]: async () => {
      const invalidStatus = await props.getInvalidLoggedUser();
      if (invalidStatus) {
        props.handleInvalidLoggedUser(invalidStatus);
        props.sendBiForLoginRelatedError();
        return;
      }
      props.exitInteractionModeIfNeeded();
      props.notifyMultilingualAppToOpen();
    },
    [SETTINGS_MENU_ITEMS.IMAGE_OPTIMIZATION]: async () => {
      const invalidStatus = await props.getInvalidLoggedUser();
      if (invalidStatus) {
        props.handleInvalidLoggedUser(invalidStatus);
        props.sendBiForLoginRelatedError();
        return;
      }
      props.exitInteractionModeIfNeeded();
      props.openPanel('panels.focusPanels.siteImageOptimizationPanel', {
        panelName: 'panels.focusPanels.siteImageOptimizationPanel',
      });
    },
    [SETTINGS_MENU_ITEMS.PRIVACY_GDPR]() {
      window.open(
        `${util.serviceTopology.basePublicUrl}dashboard/${util.editorModel.metaSiteId}/cookie-consent-settings-app?referralInfo=EDITOR`,
        '_blank',
      );
    },
    [SETTINGS_MENU_ITEMS.MOBILE_FRIENDLY]: async () => {
      const invalidStatus = await props.getInvalidLoggedUser();
      if (invalidStatus) {
        props.handleInvalidLoggedUser(invalidStatus);
        props.sendBiForLoginRelatedError();
        return;
      }
      props.exitInteractionModeIfNeeded();
      props.openPanel('panels.focusPanels.mobileFriendlyPanel');
    },
    [SETTINGS_MENU_ITEMS.ACCESSIBILITY]: async () => {
      const invalidStatus = await props.getInvalidLoggedUser();
      if (invalidStatus) {
        props.handleInvalidLoggedUser(invalidStatus);
        props.sendBiForLoginRelatedError();
        return;
      }
      props.exitInteractionModeIfNeeded();
      props.openAccessibilityWizard();
    },
    [SETTINGS_MENU_ITEMS.PERMISSIONS]: async () => {
      const invalidStatus = await props.getInvalidLoggedUser();
      if (invalidStatus) {
        props.handleInvalidLoggedUser(invalidStatus);
        props.sendBiForLoginRelatedError();
        return;
      }
      if (shouldDisplaySaveYourSitePanel) {
        openSaveYourSitePanel();
      } else {
        props.openSettings('authorization');
      }
    },
    [SETTINGS_MENU_ITEMS.SITE_ACCESS]() {
      props.exitInteractionModeIfNeeded();
      props.openPanel(constants.PANELS.SITE_ACCESS.PANEL_NAME, {
        origin: 'top_bar_settings_menu',
        permission: props.isSitePasswordEnabled
          ? SiteAccessPermission.Password
          : SiteAccessPermission.Everyone,
      });
    },
    [SETTINGS_MENU_ITEMS.TRACKING_TOOLS]() {
      if (shouldDisplaySaveYourSitePanel) {
        openSaveYourSitePanel();
      } else {
        props.openSettings('embeds');
      }
    },
    [SETTINGS_MENU_ITEMS.MARKETING_INTEGRATIONS]: async () => {
      const invalidStatus = await props.getInvalidLoggedUser();
      if (invalidStatus) {
        props.handleInvalidLoggedUser(invalidStatus);
        props.sendBiForLoginRelatedError();
        return;
      }
      if (shouldDisplaySaveYourSitePanel) {
        openSaveYourSitePanel();
      } else {
        props.openSettings('MarketingIntegrations');
      }
    },
    [SETTINGS_MENU_ITEMS.SOCIAL]: async () => {
      const invalidStatus = await props.getInvalidLoggedUser();
      if (invalidStatus) {
        props.handleInvalidLoggedUser(invalidStatus);
        props.sendBiForLoginRelatedError();
        return;
      }
      if (shouldDisplaySaveYourSitePanel) {
        openSaveYourSitePanel();
      } else {
        props.openSettings('social');
      }
    },
  };
};
