// @ts-nocheck
import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import keyboardShortcutsActionMap from '@/keyboardShortcuts';
import selectComponentShortcutsActions from '../actions/selectComponentShortcutsActions';
import editorDebugShortcutsUtil from '../util/editorDebugShortcutsUtil';
import * as commonKeyboardActions from '../util/commonKeyboardActions';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import sendBIAndActionUtil from '../util/sendBIAndActionUtil';
import * as util from '@/util';
import experiment from 'experiment';
import { AddPresetApiKey, ImageUploadToStageApiKey } from '@/apis';
import { browserUtil } from '@/util';
import { isMeshLayoutEnabled } from '@/layoutOneDockMigration';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef, CompStructure } from 'types/documentServices';
import type { ClipboardCompItem } from '@/clipboard';

const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;

const EDITOR_SHORTCUTS_CONFIG = {};

const SHORTCUT_ORIGIN = 'keyboard_shortcut';

const keyboardMap = keyboardShortcutsActionMap.editor;

let deployAppConfigurationAppAmount = 0;

const panelTypesToClose = [
  'leftPanelFrame',
  'compPanelFrame',
  'focusPanelFrame',
  'messagePanelFrame',
  'toolPanelFrame',
  'tpaSettingsPanelFrame',
];

function addEditorShortcuts(editorAPI: EditorAPI) {
  const keysToMerge = {};
  keysToMerge[keyboardMap.AUTOSAVE_ON_OFF.key] = setAutoSaveMode.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.SELECT_ALL.key] = editorAPI.selection.selectAll;
  keysToMerge[keyboardMap.PASTE.key] = pasteFromKeyboard.bind(
    null,
    editorAPI,
    false,
  );

  keysToMerge[keyboardMap.PASTE_AND_APPLY_THEME.key] = pasteFromKeyboard.bind(
    null,
    editorAPI,
    true,
  );

  keysToMerge[keyboardMap.UNDO.key] =
    commonKeyboardActions.undoFromKeyboard.bind(null, editorAPI);
  keysToMerge[keyboardMap.REDO.key] =
    commonKeyboardActions.redoFromKeyboard.bind(null, editorAPI);
  keysToMerge[keyboardMap.DELETE.key] = removeComponentFromKeyboard.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.SAVE.key] =
    commonKeyboardActions.saveSiteFromKeyboard.bind(null, editorAPI);
  keysToMerge[keyboardMap.SAVE_AS_TEMPLATE.key] =
    commonKeyboardActions.saveSiteAsTemplateFromKeyboard.bind(null, editorAPI);
  keysToMerge[keyboardMap.SELECT_NEXT_COMP.key] = selectNextComponent.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.SELECT_PREV_COMP.key] = selectPreviousComponent.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.SELECT_NEXT_COMP_TAB.key] =
    selectNextComponentWithTab.bind(null, editorAPI);
  keysToMerge[keyboardMap.SELECT_PREV_COMP_TAB.key] =
    selectPreviousComponentWithTab.bind(null, editorAPI);

  keysToMerge[keyboardMap.TOGGLE_RULERS.key] = toggleRulers.bind(
    null,
    editorAPI,
  );

  keysToMerge[keyboardMap.CLOSE_PANELS.key] = closePanels.bind(
    null,
    editorAPI,
    'keyboard close',
  );

  keysToMerge[keyboardMap.SEND_TO_FRONT.key] = moveComponent.toFront.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.SEND_TO_BACK.key] = moveComponent.toBack.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.SEND_FORWARD.key] = moveComponent.forward.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.SEND_BACKWARD.key] = moveComponent.backward.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.TOGGLE_PREVIEW.key] =
    commonKeyboardActions.togglePreviewFromKeyboard.bind(null, editorAPI);
  keysToMerge[keyboardMap.TOGGLE_MOBILE.key] = toggleMobileFromKeyboard.bind(
    null,
    editorAPI,
  );

  keysToMerge[keyboardMap.PLATFORM_APP_CONFIGURATION.key] =
    deployAppConfigurationApp.bind(null, editorAPI);

  keysToMerge[keyboardMap.PUBLISH_SITE.key] =
    commonKeyboardActions.publishSiteFromKeyboard.bind(null, editorAPI);

  if (experiment.isOpen('specs.wixCode.TestSiteEntryPoint')) {
    keysToMerge[keyboardMap.PUBLISH_RC.key] =
      commonKeyboardActions.publishRCFromKeyboard.bind(null, editorAPI);
  }

  keysToMerge[keyboardMap.HIDE_TOOLS.key] = toggleHideToolsFromKeyboard.bind(
    null,
    editorAPI,
  );

  keysToMerge[keyboardMap.TOGGLE_SEARCH_PANEL.key] = openSearchPanel.bind(
    null,
    editorAPI,
  );

  keysToMerge[keyboardMap.NEW_BLANK_SECTION.key] = addBlankSection.bind(
    null,
    editorAPI,
  );

  const debugShortcuts = editorDebugShortcutsUtil(editorAPI);
  _.merge(keysToMerge, debugShortcuts);
  _.merge(EDITOR_SHORTCUTS_CONFIG, keysToMerge);
}

const moveComponent = {
  toFront(editorAPI: EditorAPI) {
    const selectedComps = editorAPI.selection.getSelectedComponents();
    if (editorAPI.components.is.controlledByParent(selectedComps)) {
      return;
    }
    editorAPI.components.arrangement.moveToFront(selectedComps);
    sendBIAndActionUtil(
      editorAPI,
      coreBi.events.rightClickMenu.BRING_TO_FRONT,
      {
        origin: 'keyboard',
      },
    );
  },
  toBack(editorAPI: EditorAPI) {
    const selectedComps = editorAPI.selection.getSelectedComponents();
    if (editorAPI.components.is.controlledByParent(selectedComps)) {
      return;
    }
    editorAPI.components.arrangement.moveToBack(selectedComps);
    sendBIAndActionUtil(editorAPI, coreBi.events.rightClickMenu.SEND_TO_BACK, {
      origin: 'keyboard',
    });
  },
  forward(editorAPI: EditorAPI) {
    const selectedComps = editorAPI.selection.getSelectedComponents();
    if (editorAPI.components.is.controlledByParent(selectedComps)) {
      return;
    }
    editorAPI.components.arrangement.moveForward(selectedComps);
    sendBIAndActionUtil(editorAPI, coreBi.events.rightClickMenu.MOVE_FORWARD, {
      origin: 'keyboard',
    });
  },
  backward(editorAPI: EditorAPI) {
    const selectedComps = editorAPI.selection.getSelectedComponents();
    if (editorAPI.components.is.controlledByParent(selectedComps)) {
      return;
    }
    editorAPI.components.arrangement.moveBackward(selectedComps);
    sendBIAndActionUtil(
      editorAPI,
      coreBi.events.rightClickMenu.MOVE_BACKWARDS,
      {
        origin: 'keyboard',
      },
    );
  },
};

function setAutoSaveMode(editorAPI: EditorAPI) {
  const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
  if (editorAPI.generalInfo.isFirstSave() || isDraftMode) {
    return;
  }
  const isAutosaveEnabled = editorAPI.autosaveManager.isAutosaveEnabled();
  const shouldAutosaveBeEnabled = !isAutosaveEnabled;
  editorAPI.bi.event(coreBi.events.save.autosave.ENABLE_DISABLE, {
    type: shouldAutosaveBeEnabled ? 'enable' : 'disable',
  });
  editorAPI.autosaveManager.autosaveStatusTooltip(isAutosaveEnabled);
  editorAPI.autosaveManager.init({ enabled: shouldAutosaveBeEnabled }, true);
}

function pasteComponent(editorAPI: EditorAPI, shouldApplyTheme: boolean) {
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.PAST_COMPONENT_OR_TPA,
  );

  const FEDOPS_INTERACTION = editorAPI.clipboard.isCrossSite()
    ? util.fedopsLogger.INTERACTIONS.PASTE_COMPONENTS_CROSS_SITE
    : util.fedopsLogger.INTERACTIONS.PASTE_COMPONENTS_SAME_SITE;
  util.fedopsLogger.interactionStarted(FEDOPS_INTERACTION);

  const editorBIEvents = coreBi.events.rightClickMenu;
  const clipboardValue = (editorAPI.clipboard.getItem() as ClipboardCompItem)
    ?.value;

  editorAPI.copyPaste
    .paste(undefined, shouldApplyTheme, 'keyboard')
    .then((result) => {
      util.fedopsLogger.interactionEnded(FEDOPS_INTERACTION);

      editorAPI.history.add('component - paste', { isAddingComponent: true });

      const compStructures: CompStructure[] = clipboardValue?.components ?? [];
      const compRefs: CompRef[] = result?.newComponentPointers ?? [];

      const isCompPinned = isMeshLayoutEnabled()
        ? editorAPI.components.layout.isPinned(compRefs)
        : compStructures.every((compStructure) =>
            editorAPI.components.layout.isPinnedByLayout_deprecated(
              compStructure.layout,
            ),
          );

      sendBIAndActionUtil(editorAPI, editorBIEvents.PASTE, {
        origin: 'keyboard',
        is_component_pinned: isCompPinned,
      });
    });
}

function pastePage(editorAPI: EditorAPI, shouldApplyTargetTheme: boolean) {
  const FEDOPS_INTERACTION = editorAPI.clipboard.isCrossSite()
    ? util.fedopsLogger.INTERACTIONS.PASTE_PAGE_CROSS_SITE
    : util.fedopsLogger.INTERACTIONS.PASTE_PAGE_SAME_SITE;
  util.fedopsLogger.interactionStarted(FEDOPS_INTERACTION);

  editorAPI.copyPaste
    .pastePage({
      shouldApplyTargetTheme,
      origin: 'keyboard_stage',
    })
    .then(() => {
      util.fedopsLogger.interactionEnded(FEDOPS_INTERACTION);
    });
}

export function pasteFromKeyboard(
  editorAPI: EditorAPI,
  shouldApplyTheme: boolean,
) {
  if (!browserUtil.isFirefox()) {
    let pasteFnToCall = _.noop;
    if (editorAPI.copyPaste.hasComponentToPaste()) {
      pasteFnToCall = pasteComponent.bind(null, editorAPI, shouldApplyTheme);
    } else if (editorAPI.copyPaste.hasPageToPaste()) {
      pasteFnToCall = pastePage.bind(null, editorAPI, shouldApplyTheme);
    }

    editorAPI.host
      .getAPI(ImageUploadToStageApiKey)
      .setPasteFunctionToCall(pasteFnToCall);
    return;
  }

  if (editorAPI.copyPaste.hasComponentToPaste()) {
    pasteComponent(editorAPI, shouldApplyTheme);
  } else if (editorAPI.copyPaste.hasPageToPaste()) {
    pastePage(editorAPI, shouldApplyTheme);
  } else {
    // do nothing
  }
}

export function toggleMobileFromKeyboard(
  editorAPI: EditorAPI,
  event: KeyboardEvent,
) {
  event.preventDefault();
  editorAPI.bi.event(coreBi.events.topbar.editor_view_mode_switch, {
    origin: 'keyboard',
  });
  const isMobile = editorAPI.isMobileEditor();
  editorAPI.setEditorMode(!isMobile, 'keyboard_switchEditorShortcut');
}

function deployAppConfigurationApp(editorAPI: EditorAPI) {
  if (deployAppConfigurationAppAmount >= 8) {
    const appDefId = 'editorPlatformWixComponents';
    const appData = editorAPI.dsRead.platform.getAppDataByAppDefId(appDefId);
    if (appData) {
      editorAPI.dsActions.platform.notifyApplication(appData.applicationId, {
        eventType: 'openModal',
      });
    } else {
      editorAPI.dsActions.platform.initApp({
        appDefinitionId: 'editorPlatformWixComponents',
        editorUrl:
          'https://static.parastorage.com/services/editor-platform-app-configuration-ui-node/1.33.0/assets/editor.js',
      });
    }
    deployAppConfigurationAppAmount = 0;
  } else {
    deployAppConfigurationAppAmount++;
  }
}

export function removeComponentFromKeyboard(editorAPI: EditorAPI) {
  const selectedComps = editorAPI.selection.getSelectedComponents();
  const isCompPinned = editorAPI.components.layout.isPinned(selectedComps)
    ? 1
    : 0;

  sendBIAndActionUtil(editorAPI, coreBi.events.editor.component_removed, {
    removal_method: 'keyboard',
    is_component_pinned: isCompPinned,
  });

  const hasUndoBeenUsed = !!getSiteUserPreferences(
    constants.USER_PREFS.UNDO.UNDO_PREVIOUSLY_USED,
  )(editorAPI.store.getState());
  const firstTimeDeleteUsed = !!getSiteUserPreferences(
    constants.USER_PREFS.DELETE.FIRST_TIME_DELETE_USED,
  )(editorAPI.store.getState());
  const isRemovable = editorAPI.components.is.removable(selectedComps);
  if (!hasUndoBeenUsed && !firstTimeDeleteUsed && isRemovable) {
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        constants.USER_PREFS.DELETE.FIRST_TIME_DELETE_USED,
        true,
      ),
    );
    const onNotificationLinkClick = () => {
      editorAPI.panelManager.openHelpCenter(helpIds.NOTIFICATIONS.UNDO_REDO);
    };
    editorAPI.store.dispatch(
      stateManagement.notifications.actions.showUserActionNotification({
        message: 'Notifications_Undo_Redo_Text',
        title: 'Undo Redo',
        type: 'info',
        link: {
          caption: 'Notifications_Learn_More_Link',
          onNotificationLinkClick,
        },
      }),
    );
  }

  const selectedPopupComponent = editorAPI.selection.isPopupPageSelected(
    selectedComps,
  )
    ? _.head(selectedComps)
    : editorAPI.selection.getSelectedPopup(selectedComps);
  if (selectedPopupComponent && !editorAPI.isMobileEditor()) {
    editorAPI.components.removePopupWithMessagePanel(
      selectedPopupComponent,
      SHORTCUT_ORIGIN,
    );
    return;
  }
  const historyParams = {
    component_id: selectedComps.map(({ id }) => id).join(','),
    component_type: selectedComps
      .map((compRef) => editorAPI.components.getType(compRef))
      .join(','),
  };
  editorAPI.selectedComponent.remove('keyboard').then((removed) => {
    if (removed) {
      editorAPI.history.add('component - remove', historyParams);
    }
  });
}

function selectNextComponent(editorAPI: EditorAPI) {
  editorAPI.panelManager.closeAllPanels();
  selectComponentShortcutsActions.selectNextComponent(editorAPI);
}

function selectPreviousComponent(editorAPI: EditorAPI) {
  editorAPI.panelManager.closeAllPanels();
  selectComponentShortcutsActions.selectPreviousComponent(editorAPI);
}

function selectNextComponentWithTab(
  editorAPI: EditorAPI,
  event: KeyboardEvent,
) {
  event.preventDefault();
  selectNextComponent(editorAPI);
}

function selectPreviousComponentWithTab(
  editorAPI: EditorAPI,
  event: KeyboardEvent,
) {
  event.preventDefault();
  selectPreviousComponent(editorAPI);
}

function toggleRulers(editorAPI: EditorAPI) {
  editorAPI.store.dispatch(stateManagement.rulers.actions.toggleRulers());
}

export function closePanels(editorAPI: EditorAPI, origin: string) {
  const { panelManager } = editorAPI;
  const openPanels = panelManager.getOpenPanels();
  if (openPanels.length > 0) {
    panelTypesToClose.forEach((panelType) =>
      panelManager.closeAllPanelsOfType(panelType, origin),
    );
  } else if (
    stateManagement.panels.selectors.selectHelpProps(editorAPI.store.getState())
  ) {
    panelManager.closeHelpCenter();
  } else if (editorAPI.selection.isComponentSelected()) {
    editorAPI.selection.deselectComponents();
  } else {
    editorAPI.toolsControl.toggleHideTools(false);
    editorAPI.bi.event(coreBi.events.editor.hide_tools.show_controls, {
      origin: 'shortcut',
    });
  }
}

function toggleHideToolsFromKeyboard(editorAPI: EditorAPI) {
  const areToolsHidden = editorAPI.toolsControl.toggleHideTools();
  const eventName = areToolsHidden ? 'hide_controls' : 'show_controls';

  editorAPI.bi.event(coreBi.events.editor.hide_tools[eventName], {
    origin: 'shortcut',
  });
}

function openSearchPanel(editorAPI: EditorAPI) {
  editorAPI.store.dispatch(
    stateManagement.editorSearch.actions.openEditorSearchPanel({
      origin: 'shortcut',
    }),
  );
  editorAPI.bi.event(coreBi.events.editorSearch.hotKeyOpened);
}

async function addBlankSection(editorAPI: EditorAPI) {
  if (!util.sections.isSectionsEnabled()) {
    return;
  }
  const addPresetAPI = editorAPI.host.getAPI(AddPresetApiKey);
  await addPresetAPI.sections.addBlankSection('keyboard', 'keyboard');
}

export default {
  init(editorAPI: EditorAPI) {
    addEditorShortcuts(editorAPI);
  },
  shortcuts: EDITOR_SHORTCUTS_CONFIG,
};
