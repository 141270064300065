import _ from 'lodash';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import experiment from 'experiment';
import { SwitchLayoutApiKey } from '@/apis';
import type { Scope } from './scope';
import type { SnapshotParams } from './types';
import {
  getInnerRouteFromCurrentNavInfo,
  getOpenedInlinePopup,
  updateSaveState,
} from './common';
import type { CompRef } from 'types/documentServices';
import { hooks } from './hooks';

const { getSessionUserPreferences } = stateManagement.userPreferences.selectors;
const { isInInteractionMode, isLastUndoEnterInteractionMode } =
  stateManagement.interactions.selectors;
const MY_ACCOUNT = `${util.serviceTopology.dashboardUrl.replace(
  /create\//,
  '',
)}/`;

type UndoRedoFn = () => void;

const SECONDARY_PANELS_TO_CLOSE = [
  'panels.toolPanels.colorPicker.colorPickerPanel',
  'panels.toolPanels.colorPicker.colorPickerWithGradientsPanel',
  'rEditor.panels.backgroundApplyPanel',
  'compPanels.dynamicPanels.pinnedDockPanel',
  'rEditor.panels.slideShowBgApplyPanel',
  'rEditor.panels.stateBoxBgApplyPanel',
];

if (experiment.isOpen('se_colorPickerUndo')) {
  SECONDARY_PANELS_TO_CLOSE.splice(
    SECONDARY_PANELS_TO_CLOSE.indexOf(
      'panels.toolPanels.colorPicker.colorPickerWithGradientsPanel',
    ),
    1,
  );
}

function closeSecondaryPanels(scope: Scope) {
  const { editorAPI } = scope;

  for (const panel of SECONDARY_PANELS_TO_CLOSE) {
    editorAPI.panelManager.closePanelByName(panel);
  }
}

type PostAction = (params: SnapshotParams) => Promise<void>;

async function performHistoryAction(
  scope: Scope,
  action: UndoRedoFn,
  params: SnapshotParams,
  postAction: PostAction,
) {
  const { editorAPI } = scope;

  const isSameEditorMode = params.isMobileEditor === editorAPI.isMobileEditor();
  let selectedComps = editorAPI.selection.getSelectedComponents();

  closeSecondaryPanels(scope); // This is necessary while the undo-redo is not capable of retrieving the exact state (and inner state) of the open panel since it causes some really shitty bugs

  editorAPI.setEditorMode(params.isMobileEditor, 'historyApi', true);
  action();
  await editorAPI.dsActions.waitForChangesAppliedAsync();
  const openedInlinePopupInState = getOpenedInlinePopup(scope);
  const nextInlinePopup = params.openedInlinePopup;
  if (openedInlinePopupInState && !nextInlinePopup) {
    editorAPI.store
      .dispatch(
        stateManagement.inlinePopup.actions.close(
          openedInlinePopupInState,
          true,
        ),
      )
      .then(() => {
        selectedComps = [];
        editorAPI.selection.deselectComponents();
      });
  } else if (nextInlinePopup && !openedInlinePopupInState) {
    editorAPI.store
      .dispatch(stateManagement.inlinePopup.actions.open(nextInlinePopup, true))
      .then(() => {
        selectedComps = [nextInlinePopup];
        selectComponents(scope, { isSameEditorMode, selectedComps });
      });
  }
  // originalParams.postAction is a callback function passed from outside
  const originalPostAction = params?.postAction;
  if (originalPostAction) {
    await originalPostAction(params);
  }
  await postAction(params);
  selectComponents(scope, { isSameEditorMode, selectedComps });
}

function selectComponents(
  scope: Scope,
  {
    selectedComps,
    isSameEditorMode,
  }: { selectedComps: CompRef[]; isSameEditorMode: boolean },
) {
  // TODO GuyR 06/02/2017 15:39 - remove bind of is.exist after refactoring componentMetaDataFacade
  const { editorAPI } = scope;
  const componentsToSelect =
    selectedComps?.filter((compRef) =>
      editorAPI.components.is.exist(compRef),
    ) || [];
  if (!componentsToSelect.length) {
    return;
  }
  const focusedRoot: string = editorAPI.dsRead.pages.getFocusedPageId();
  const allowedRoots = [focusedRoot];
  if (!editorAPI.dsRead.pages.popupPages.isPopupOpened()) {
    allowedRoots.push(editorAPI.pages.getMasterPageId());
  }
  const pageIdOfComponentsToSelect =
    editorAPI.components.getPage(componentsToSelect)?.id;
  if (isSameEditorMode && allowedRoots.includes(pageIdOfComponentsToSelect)) {
    editorAPI.selection.selectComponentByCompRef(componentsToSelect);
  }
}

function executePlugins(scope: Scope, params: SnapshotParams, action: string) {
  const { editorAPI } = scope;

  // check historyPlugins.ts for more information about registered plugins
  const plugins = [
    editorAPI.pluginService.getPlugin(action, params.actionType),
    editorAPI.pluginService.getPlugin(action, '*'),
  ]
    .filter(Boolean)
    // I'm not sure do we need to wait for all plugins to be executed before notifying an apps
    .map((plugin) => new Promise((res) => plugin(params, res)));

  return Promise.all(plugins);
}

function getPostAction(scope: Scope, action: string) {
  async function postAction(params: SnapshotParams) {
    await executePlugins(scope, params, action);
  }
  return postAction;
}

function shouldShowNotificationOnUndo(scope: Scope, undoCount: number) {
  const { editorAPI } = scope;

  const topBarSiteHistoryUsed =
    stateManagement.userPreferences.selectors.getSiteUserPreferences(
      'topBarSiteHistoryUsed',
    )(editorAPI.store.getState());
  const siteHistoryNotificationAppeared =
    stateManagement.userPreferences.selectors.getSiteUserPreferences(
      'siteHistoryNotificationAppeared',
    )(editorAPI.store.getState());
  const isFirstSave =
    _.invoke(editorAPI, 'dsRead.generalInfo.isFirstSave') || false;
  const isDraftMode =
    _.invoke(editorAPI, 'dsRead.generalInfo.isDraft') || false;
  return (
    !isFirstSave &&
    !isDraftMode &&
    !siteHistoryNotificationAppeared &&
    !topBarSiteHistoryUsed &&
    undoCount >= constants.USER_PREFS.UNDO.UNDO_THRESHOLD
  );
}

function handleShowNotificationOnUndo(scope: Scope) {
  const { editorAPI } = scope;

  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      constants.USER_PREFS.UNDO.UNDO_PREVIOUSLY_USED,
      true,
    ),
  );
  let undoCount =
    getSessionUserPreferences<number>(constants.USER_PREFS.UNDO.UNDO_COUNT)(
      editorAPI.store.getState(),
    ) || 1;
  if (shouldShowNotificationOnUndo(scope, undoCount)) {
    const metaSiteId = editorAPI.documentServices.generalInfo.getMetaSiteId();
    const url = `${MY_ACCOUNT}history/${metaSiteId}/?referralInfo=EDITOR`;
    const onNotificationLinkClick = () => {
      window.open(url);
    };
    editorAPI.store.dispatch(
      stateManagement.notifications.actions.showUserActionNotification({
        message: 'Notifications_Site_History_Text',
        title: 'Notifications_Site_History_Text',
        type: 'info',
        link: {
          caption: 'Notifications_Site_History_Link',
          onNotificationLinkClick,
        },
      }),
    );
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        'siteHistoryNotificationAppeared',
        true,
      ),
    );
  } else {
    undoCount += 1;
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setSessionUserPreferences(
        constants.USER_PREFS.UNDO.UNDO_COUNT,
        undoCount,
      ),
    );
  }
}

function handleUndoRedo(
  scope: Scope,
  undoRedoFn: UndoRedoFn,
  undoRedoParamsGetter: () => SnapshotParams,
  postAction: PostAction,
) {
  const { editorAPI } = scope;

  const navigateToPageFromSnapshot = (
    params: SnapshotParams,
    cb: () => void,
  ) => {
    const { currentInnerRoute, currentPage } = params;
    if (currentInnerRoute?.routerId && currentInnerRoute.innerRoute) {
      const { innerRoute, routerId } = currentInnerRoute;
      editorAPI.pages.navigateTo(
        { innerRoute, routerId, type: 'DynamicPageLink' },
        cb,
      );
      return;
    }
    editorAPI.pages.navigateTo(currentPage, cb);
  };

  const params = undoRedoParamsGetter();
  const currentInnerRoute = getInnerRouteFromCurrentNavInfo(scope);
  if (
    params.currentPage === editorAPI.dsRead.pages.getFocusedPageId() &&
    (!params.currentInnerRoute ||
      _.isEqual(currentInnerRoute, params.currentInnerRoute))
  ) {
    performHistoryAction(scope, undoRedoFn, params, postAction);
    const pageRef = editorAPI.dsRead.pages.getFocusedPage();
    const routerRefBefore =
      editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);
    editorAPI.dsActions.waitForChangesApplied(function () {
      /*support case of router disconnect where the snapshot is take to the dynamic page*/
      const routerRefAfter =
        editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);
      if (!routerRefBefore && routerRefAfter) {
        editorAPI.dsActions.pages.navigateTo(pageRef.id);
      }
    });
  } else if (!editorAPI.pages.isPageExist(params.currentPage)) {
    // navigate to the not-yet-existing `params.currentPage`
    // only after it is (presumably) created inside `performHistoryAction()`
    performHistoryAction(scope, undoRedoFn, params, postAction);
    editorAPI.dsActions.waitForChangesApplied(function () {
      navigateToPageFromSnapshot(params, _.noop);
    });
  } else {
    navigateToPageFromSnapshot(params, () =>
      performHistoryAction(scope, undoRedoFn, params, postAction),
    );
  }
  if (params.shouldSave) {
    if (!editorAPI.savePublish.canSaveOrPublish()) {
      editorAPI.editorEventRegistry.registerOnce(
        editorAPI.editorEventRegistry.constants.events.SAVE_PUBLISH_UNLOCKED,
        () =>
          editorAPI.saveManager.saveInBackground(
            _.noop,
            _.noop,
            'historyWrapper',
            {
              sourceOfStart: 'historyWrapper_bgSave',
            },
          ),
      );
    } else {
      editorAPI.saveManager.saveInBackground(_.noop, _.noop, 'historyWrapper', {
        sourceOfStart: 'historyWrapper_bgSave',
      });
    }
  }

  updateSaveState(scope);
}

export async function redo(scope: Scope) {
  const { editorAPI } = scope;
  const { history } = editorAPI.dsActions;
  const switchLayoutAPI = editorAPI.host.getAPI(SwitchLayoutApiKey);

  if (stateManagement.rulers.selectors.canRedo(editorAPI.store.getState())) {
    editorAPI.store.dispatch(stateManagement.rulers.actions.redo());
    return;
  }

  await editorAPI.dsActions.waitForChangesAppliedAsync();
  if (!history.canRedo()) {
    return;
  }

  if (switchLayoutAPI.isInSwitchLayoutMode()) {
    switchLayoutAPI.onRedo();
  }
  const originParams = history.getUndoLastSnapshotParams();

  handleUndoRedo(
    scope,
    () => history.redo(),
    () => history.getRedoLastSnapshotParams(),
    getPostAction(scope, constants.HISTORY.REDO),
  );

  const targetParams = history.getUndoLastSnapshotParams();

  hooks.undoWasPerformed.fire({
    originParams,
    targetParams,
  });

  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSessionUserPreferences(
      constants.USER_PREFS.REDO.REDO_PREVIOUSLY_USED,
      true,
    ),
  );
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSessionUserPreferences(
      constants.USER_PREFS.UNDO.UNDO_COUNT,
      0,
    ),
  );
}

export async function undo(scope: Scope) {
  const { editorAPI } = scope;
  const state = editorAPI.store.getState();

  const isInteractionMode = isInInteractionMode(state);
  if (isInteractionMode && isLastUndoEnterInteractionMode(editorAPI)) {
    return;
  }

  const { componentFocusMode } = editorAPI;
  if (
    componentFocusMode.isEnabled() &&
    componentFocusMode.wasLastActionEnterMode()
  ) {
    return;
  }

  const switchLayoutAPI = editorAPI.host.getAPI(SwitchLayoutApiKey);
  if (switchLayoutAPI.preventUndo()) {
    return;
  } else if (switchLayoutAPI.isInSwitchLayoutMode()) {
    switchLayoutAPI.onUndo();
  }

  if (stateManagement.rulers.selectors.canUndo(state)) {
    editorAPI.store.dispatch(stateManagement.rulers.actions.undo());
    return;
  }
  await editorAPI.dsActions.waitForChangesAppliedAsync();

  const { history } = editorAPI.dsActions;
  if (!history.canUndo()) {
    return;
  }
  editorAPI.store.dispatch(stateManagement.rulers.actions.resetHistory());
  const params: SnapshotParams = history.getUndoLastSnapshotParams();
  if (
    params?.undoShouldClosePopup &&
    editorAPI.pages.popupPages.isPopupOpened()
  ) {
    editorAPI.selection.deselectComponents();
    editorAPI.pages.popupPages.close();
  }

  if (params.isAddingComponent) {
    //fast fix to avoid not existed compRef bug.
    editorAPI.selection.deselectComponents();
  }

  handleUndoRedo(
    scope,
    () => history.undo(),
    () => history.getUndoLastSnapshotParams(),
    getPostAction(scope, constants.HISTORY.UNDO),
  );

  handleShowNotificationOnUndo(scope);

  const targetParams = history.getUndoLastSnapshotParams();
  hooks.undoWasPerformed.fire({
    originParams: params,
    targetParams,
  });
}

export function clear(scope: Scope) {
  const { editorAPI } = scope;

  editorAPI.dsActions.history.clear();
}

export function getUndoLastSnapshotLabel(scope: Scope) {
  const { editorAPI } = scope;

  return editorAPI.dsActions.history.getUndoLastSnapshotLabel();
}

export function getRedoLastSnapshotLabel(scope: Scope) {
  const { editorAPI } = scope;

  return editorAPI.dsActions.history.getRedoLastSnapshotLabel();
}

async function undoAndWaitForChanges(scope: Scope) {
  const { editorAPI } = scope;
  undo(scope);
  await editorAPI.dsActions.waitForChangesAppliedAsync();
}

/**
 * Performs undo until requested snapshot label.
 * In case the snapshot label does not exists the undo stack will be cleard similar to history.clear()
 */
export async function performUndoUntilLabel(
  scope: Scope,
  snapshotLabel: string,
  shouldUndoSnapshotLabel: boolean,
) {
  const { editorAPI } = scope;
  if (!editorAPI.documentServices.history.canUndo()) {
    return;
  }
  const lastSnapshotLabel =
    editorAPI.documentServices.history.getUndoLastSnapshotLabel();
  if (lastSnapshotLabel !== snapshotLabel) {
    await undoAndWaitForChanges(scope);
    await performUndoUntilLabel(scope, snapshotLabel, shouldUndoSnapshotLabel);
  } else if (shouldUndoSnapshotLabel) {
    await undoAndWaitForChanges(scope);
  }
}
