const CANVAS_FONT_PROPS = ['font-weight', 'font-size', 'font-family'];
export const getTextComponentArea = (
  document: Document,
  element: HTMLElement,
  text: string,
  scale: number = 1,
) => {
  if (!document || !element) {
    return null;
  }

  // create a hidden div and add it to the text parent element
  const div = createHiddenDiv(document);
  const child = getChildIfExists(element);
  child.appendChild(div);

  // set the div styles and text to match the original element
  const styleArray = getFontStyles(child, document);
  copyElementStylesToDiv(div, styleArray);
  div.innerText = text;

  // calculate the div area
  const height = div.getBoundingClientRect().height * scale;
  const width = div.getBoundingClientRect().width * scale;
  const area = +height * +width;

  // remove the div
  child.removeChild(div);

  return area;
};

const getChildIfExists = (element: any): any => {
  if (element?.hasChildNodes() && element.childNodes[0].hasChildNodes()) {
    return getChildIfExists(element.childNodes[0]);
  }
  return element;
};

const copyElementStylesToDiv = (div: HTMLDivElement, styles: any) => {
  for (let i = 0; i < styles.length; i++) {
    styles[i].value = styles[i].value.split(',')[0];
    (div as any).style[styles[i].key] = styles[i].value;
  }
};

const getFontStyles = (element: any, document: Document) => {
  if (!element) return []; // Element does not exist, empty list.
  let style;
  const win = document.defaultView;
  const styleNode = [];
  if (win.getComputedStyle) {
    style = win.getComputedStyle(element, '');
    for (let i = 0; i < CANVAS_FONT_PROPS.length; i++) {
      const value = style.getPropertyValue(CANVAS_FONT_PROPS[i]);
      if (value !== '') {
        styleNode.push({
          key: CANVAS_FONT_PROPS[i],
          value,
        });
      }
    }
  }
  return styleNode;
};

const createHiddenDiv = (document: Document) => {
  const div = document.createElement('div');
  div.style.position = 'absolute';
  div.style.visibility = 'hidden';
  div.style.opacity = '0';
  div.style.left = '-9999px';
  div.style.zIndex = '100';
  div.style.whiteSpace = 'pre-wrap';
  div.style.maxWidth = '350px';
  return div;
};
