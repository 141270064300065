// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: [
        'design_section_label_explore_gallery_grid',
        'design_section_label_explore_gallery_slider',
        'design_section_label_explore_gallery_more',
      ],
      sections: [
        addPanel.sections.getSections().gallery.gridGalleriesSection,
        addPanel.sections.getSections().gallery.sliderGalleriesSection,
        addPanel.sections.getSections().gallery.moreGalleriesSection,
      ],
    };
  },
};
