import constants from '@/constants';

import { searchModule } from '../searchModule';
import { createPanelIntents, IntentType } from './createPanelIntents';
import { Integrations } from '../constants';

import type { EditorAPI } from '@/editorAPI';

const {
  ROOT_COMPS: { LEFTBAR },
} = constants;

export const integrateMobileToolsToSearch = (editorAPI: EditorAPI) => {
  const mobileToolsPanelIntents = createPanelIntents(
    editorAPI,
    LEFTBAR.MOBILE_TOOLS_PANEL_NAME,
  );

  searchModule.addIntegration({
    type: Integrations.MOBILE_TOOLS,
    transform: (result) => {
      return {
        ...result,
        onEnter: () => {
          mobileToolsPanelIntents.set({
            type: IntentType.OPEN,
          });
        },
        onLeave: () => {
          mobileToolsPanelIntents.set({
            type: IntentType.CLOSE,
          });
        },
      };
    },
  });
};
