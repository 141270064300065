// @ts-nocheck
import _ from 'lodash';
import * as textStylesMigration from './textStylesMigration';
import * as textSimpleFormatMigration from './textSimpleFormatMigration';

/**
 *
 * @param html
 * @param defaultTag
 * @param defaultClass
 */
function migrateText(html, themeFonts, themeColors, defaultTag, defaultClass) {
  defaultTag = defaultTag || 'p';
  defaultClass = defaultClass || 'font_8';
  if (!_.isString(html)) {
    //BI?
    return null;
  }
  if (html === '') {
    return _.template(
      '<<%= defaultTag %> class="<%= defaultClass %>"></<%= defaultTag %>>',
    )({
      defaultTag,
      defaultClass,
    });
  }
  const container = window.document.createElement('div');
  container.innerHTML = html;
  textStylesMigration.migrateElement(
    container,
    defaultTag,
    defaultClass,
    themeFonts,
    themeColors,
  );
  textSimpleFormatMigration.migrateElement(container);
  return container.innerHTML;
}

export { migrateText };
