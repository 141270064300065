//eslint-disable-next-line @wix/santa-editor/scoped-imports
import {
  type EventsWelcomeDispatchProps,
  mapDispatchToEventsWelcomeSectionProps,
} from '@/addPanelData/mappers';
import type * as tpa from '@/tpa';
import { wrapWithPropsTransformer } from '../utils';

export interface EventsWelcomeProps {
  eventsMainAction: ({
    biParams,
  }: {
    biParams?: tpa.superApps.AddWidgetBIParams;
  }) => void;
  eventsSecondaryAction: () => void;
}

export const eventsWelcomeMapper = wrapWithPropsTransformer<
  EventsWelcomeDispatchProps,
  EventsWelcomeProps
>(mapDispatchToEventsWelcomeSectionProps, (props) => ({
  eventsMainAction: props.installEventsApp,
  eventsSecondaryAction: props.openAppMarket,
}));
