import constants from '@/constants';
import * as stateManagement from '@/stateManagement';

import { shouldBeShownFromDealer } from './feedbackPanel.dealer';

import type { EditorAPI } from '@/editorAPI';
import type {
  FeedbackPanelOwnProps,
  FeedbackPanelResult,
} from './feedbackPanel';

const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;
const { setSiteUserPreferences } = stateManagement.userPreferences.actions;

const PANEL_PATH = 'savePublish.panels.save.feedbackPanel';

const moduleInitTime = Date.now();
const getLoadedTime = () => {
  return window.mainLoaded || moduleInitTime;
};
const FIVE_MINUTES_MS = 1000 * 60 * 5;

interface FeedbackPanelFlow {
  check: (
    editorAPI: EditorAPI,
    { manualSavesNumber }: { manualSavesNumber: number },
  ) => Promise<boolean>;
  show: (editorAPI: EditorAPI) => Promise<FeedbackPanelResult>;
}

const thirdManualSaveFlow: FeedbackPanelFlow = {
  check: async (editorAPI, { manualSavesNumber }) => {
    const REQUIRED_SAVES_NUMBER = 3;

    const wasPanelShown = Boolean(
      getSiteUserPreferences<boolean>(
        constants.USER_PREFS.SAVE.FEEDBACK_PANEL_3RD_SAVE_SHOWN,
      )(editorAPI.store.getState()),
    );

    return !wasPanelShown && manualSavesNumber === REQUIRED_SAVES_NUMBER;
  },
  show: (editorAPI) => {
    return new Promise((resolve) => {
      editorAPI.store.dispatch(
        setSiteUserPreferences(
          constants.USER_PREFS.SAVE.FEEDBACK_PANEL_3RD_SAVE_SHOWN,
          true,
        ),
      );
      const panelProps: FeedbackPanelOwnProps = {
        onClose: resolve,
        origin: '3rd manual save',
      };

      editorAPI.panelManager.openPanel(PANEL_PATH, panelProps, true);
    });
  },
};

const siteCompletionFlow: FeedbackPanelFlow = {
  check: async (editorAPI) => {
    const wasPanelShown = Boolean(
      getSiteUserPreferences<boolean>(
        constants.USER_PREFS.SAVE.FEEDBACK_PANEL_SITE_COMPLETION_SHOWN,
      )(editorAPI.store.getState()),
    );
    const isSiteCompleted = await shouldBeShownFromDealer(editorAPI);
    const fiveMinutesSinceEditorLoadedPassed =
      Date.now() - getLoadedTime() > FIVE_MINUTES_MS;

    return (
      !wasPanelShown && isSiteCompleted && fiveMinutesSinceEditorLoadedPassed
    );
  },
  show: (editorAPI) => {
    return new Promise((resolve) => {
      editorAPI.store.dispatch(
        setSiteUserPreferences(
          constants.USER_PREFS.SAVE.FEEDBACK_PANEL_SITE_COMPLETION_SHOWN,
          true,
        ),
      );
      const panelProps: FeedbackPanelOwnProps = {
        onClose: resolve,
        origin: 'siteCompletion',
        translationsKeysOverrides: {
          ratingLabel: 'feedback_modal_complete_rating_label',
        },
      };

      editorAPI.panelManager.openPanel(PANEL_PATH, panelProps, true);
    });
  },
};

export const feedbackPanelFlows: FeedbackPanelFlow[] = [
  thirdManualSaveFlow,
  siteCompletionFlow,
];
