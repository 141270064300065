'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_textarea",
    "subNavigationTitle": "add_section_label_textarea",
    "topTitle": "add_section_title_text_box",
    "presetTitle": "add_section_heading_text_box",
    "tooltipTitle": "add_section_label_textarea_tooltip_title",
    "automationId": "add-panel-section-textAreaSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": `addPanelData/sections/textAreaSection_${util.languages.getLanguageCode()}/textAreaSection_${util.languages.getLanguageCode()}.png`,
        "imageHover": null,
        "items": [{
            "id": "User_Input_TextArea_1",
            "structure": {
                "type": "Component",
                "skin": "TextAreaDefaultSkin",
                "layout": {
                    "width": 304,
                    "height": 70,
                    "x": 10,
                    "y": 20,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.TextAreaInput",
                "data": {
                    "type": "TextAreaInput",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "How can we do better next time?",
                    "value": ""
                },
                "props": {
                    "type": "TextAreaInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "textAlignment": "left",
                    "textPadding": 16,
                    "placeholder": "How can we do better next time?",
                    "defaultTextType": "placeholderText"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "bg": "rgba(255, 255, 255, 1)",
                            "bg2": "#aaaaaa",
                            "bgd": "#FFFFFF",
                            "bgh": "#F2FAF9",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#919191",
                            "brdd": "#DBDBDB",
                            "brde": "#FF4040",
                            "brdf": "#000000",
                            "brdh": "#5C5C5C",
                            "brw": "2px",
                            "brwf": "2",
                            "brwh": "2",
                            "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                            "fntlbl": "normal normal 700 14px/1.4em avenir-lt-w01_35-light1475496",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#000000",
                            "txt2": "#7F808A",
                            "txtlbl": "#61615F",
                            "txtlblrq": "#61615F",
                            "txtd": "#DBDBDB"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.TextAreaInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "TextAreaDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-itegon201"
            },
            "preset": {"rect": {"width": 324, "height": 104, "x": 0, "y": 0}}
        }, {
            "id": "User_Input_TextArea_2",
            "structure": {
                "type": "Component",
                "skin": "TextAreaDefaultSkin",
                "layout": {
                    "width": 304,
                    "height": 70,
                    "x": 10,
                    "y": 121,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.TextAreaInput",
                "data": {
                    "type": "TextAreaInput",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "Describe yourself in 50 words or less\n",
                    "value": ""
                },
                "props": {
                    "type": "TextAreaInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "textAlignment": "left",
                    "textPadding": 16,
                    "placeholder": "Describe yourself in 50 words or less\n",
                    "defaultTextType": "placeholderText"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "bg": "rgba(255, 255, 255, 1)",
                            "bg2": "#aaaaaa",
                            "bgd": "#FFFFFF",
                            "bgf": "#FFFFFF",
                            "bgh": "#FEFAFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#8C84FA",
                            "brdd": "#DBDBDB",
                            "brde": "#FF4040",
                            "brdf": "#6055E6",
                            "brdh": "#8078E3",
                            "brw": "1",
                            "brwf": "1",
                            "brwh": "1",
                            "fnt": "normal normal normal 15px/1.4em futura-lt-w01-light",
                            "fntlbl": "normal normal normal 15px/1.4em futura-lt-w01-book",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#525252",
                            "txt2": "#8C84FA",
                            "txtlbl": "#8C84FA",
                            "txtlblrq": "#8C84FA",
                            "txtd": "#DBDBDB"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.TextAreaInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "TextAreaDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-itegon2g"
            },
            "preset": {"rect": {"width": 324, "height": 101, "x": -1, "y": 104}}
        }, {
            "id": "User_Input_TextArea_3",
            "structure": {
                "type": "Component",
                "skin": "TextAreaDefaultSkin",
                "layout": {
                    "width": 304,
                    "height": 70,
                    "x": 10,
                    "y": 221,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.TextAreaInput",
                "data": {
                    "type": "TextAreaInput",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "Yo! Leave a message, or something...\n",
                    "value": ""
                },
                "props": {
                    "type": "TextAreaInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "textAlignment": "center",
                    "textPadding": 18,
                    "placeholder": "Yo! Leave a message, or something...\n",
                    "defaultTextType": "placeholderText"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "bg": "rgba(255, 255, 255, 1)",
                            "bg2": "#aaaaaa",
                            "bgd": "#FFFFFF",
                            "bge": "#FFFFFF",
                            "bgf": "#FFFFFF",
                            "bgh": "#FAFAFA",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#67D6D6",
                            "brdd": "#DBDBDB",
                            "brde": "#FF4040",
                            "brdf": "#408F8F",
                            "brdh": "#59BABA",
                            "brw": "1",
                            "brwf": "1",
                            "brwh": "1",
                            "fnt": "italic normal normal 16px/1.4em georgia",
                            "fntlbl": "italic normal normal 14px/1.4em georgia",
                            "rd": "0px",
                            "shd": "0.00px 2.00px 0px 0px rgba(103,214,214,1)",
                            "txt": "#49D6D6",
                            "txt2": "#A3E6E6",
                            "txtlbl": "#49D6D6",
                            "txtlblrq": "#49D6D6",
                            "txtd": "#DBDBDB"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.TextAreaInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "TextAreaDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-itegon2p"
            },
            "preset": {"rect": {"width": 324, "height": 102, "x": 0, "y": 205}}
        }, {
            "id": "User_Input_TextArea_4",
            "structure": {
                "type": "Component",
                "skin": "TextAreaDefaultSkin",
                "layout": {
                    "width": 304,
                    "height": 70,
                    "x": 10,
                    "y": 323,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.TextAreaInput",
                "data": {
                    "type": "TextAreaInput",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "Had a great experience? Recommend us\n",
                    "value": ""
                },
                "props": {
                    "type": "TextAreaInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "textAlignment": "left",
                    "textPadding": 16,
                    "placeholder": "Had a great experience? Recommend us\n",
                    "defaultTextType": "placeholderText"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "bg": "rgba(75,219,123,1)",
                            "bg2": "#aaaaaa",
                            "bgd": "#DBDBDB",
                            "bge": "#FF4040",
                            "bgf": "#4BDB7B",
                            "bgh": "#5EE08A",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#FFFFFF",
                            "brdd": "#DBDBDB",
                            "brde": "#FF4040",
                            "brdf": "#FFFFFF",
                            "brdh": "#FFFFFF",
                            "brw": "0",
                            "brwd": "0",
                            "brwf": "0",
                            "brwh": "0",
                            "fnt": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                            "fntlbl": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                            "rd": "6px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#FFFFFF",
                            "txt2": "#FFFFFF",
                            "txtlbl": "#4BDB7B",
                            "txtlblrq": "#4BDB7B",
                            "txtd": "#FFFFFF"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.TextAreaInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "TextAreaDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-itegon34"
            },
            "preset": {"rect": {"width": 324, "height": 101, "x": 0, "y": 307}}
        }, {
            "id": "User_Input_TextArea_5",
            "structure": {
                "type": "Component",
                "skin": "TextAreaDefaultSkin",
                "layout": {
                    "width": 304,
                    "height": 70,
                    "x": 10,
                    "y": 424,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.TextAreaInput",
                "data": {
                    "type": "TextAreaInput",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "LOVE US? TELL US WHY\n",
                    "value": ""
                },
                "props": {
                    "type": "TextAreaInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "textAlignment": "left",
                    "textPadding": 16,
                    "placeholder": "LOVE US? TELL US WHY\n",
                    "defaultTextType": "placeholderText"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "bg": "rgba(255,255,255,1)",
                            "bg2": "#aaaaaa",
                            "bge": "#FFFFFF",
                            "bgf": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#F5F5F5",
                            "brde": "#FF4040",
                            "brdf": "#E8E8E8",
                            "brdh": "#F5F5F5",
                            "brw": "0",
                            "brwf": "1",
                            "brwh": "1",
                            "fnt": "normal normal normal 12px/1.4em lulo-clean-w01-one-bold",
                            "fntlbl": "normal normal normal 10px/1.4em lulo-clean-w01-one-bold",
                            "rd": "6px",
                            "shd": "0.00px 3.00px 19px 1px rgba(0,0,0,0.08)",
                            "txt": "#000000",
                            "txt2": "#919191",
                            "txtlbl": "#000000",
                            "txtlblrq": "#000000"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.TextAreaInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "TextAreaDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-itegon3a"
            },
            "preset": {"rect": {"width": 324, "height": 100, "x": 0, "y": 408}}
        }, {
            "id": "User_Input_TextArea_6",
            "structure": {
                "type": "Component",
                "skin": "TextAreaDefaultSkin",
                "layout": {
                    "width": 304,
                    "height": 70,
                    "x": 10,
                    "y": 523,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.TextAreaInput",
                "data": {
                    "type": "TextAreaInput",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": "Special requests? Add them here\n",
                    "value": ""
                },
                "props": {
                    "type": "TextAreaInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "textAlignment": "left",
                    "textPadding": 16,
                    "placeholder": "Special requests? Add them here\n",
                    "defaultTextType": "placeholderText"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bgf": "0",
                            "alpha-brd": "1",
                            "bg": "rgba(255, 255, 255, 1)",
                            "bg2": "#aaaaaa",
                            "bgd": "#FFFFFF",
                            "bgf": "#00A6FF",
                            "bgh": "#EAF7FF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#C6E2F7",
                            "brdd": "#DBDBDB",
                            "brde": "#FF4040",
                            "brdf": "#00A6FF",
                            "brdh": "#C6E2F7",
                            "brw": "1",
                            "brwd": "0",
                            "brwf": "1",
                            "brwh": "0",
                            "fnt": "normal normal normal 14px/1.4em helvetica-w01-light",
                            "fntlbl": "normal normal normal 14px/1.4em helvetica-w01-light",
                            "rd": "6px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#3D9BE9",
                            "txt2": "#B6C1CD",
                            "txtlbl": "#00A6FF",
                            "txtlblrq": "#00A6FF",
                            "txtd": "#DBDBDB"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.TextAreaInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "TextAreaDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-itegon29"
            },
            "preset": {"rect": {"width": 324, "height": 105, "x": 0, "y": 508}}
        }],
        "compTypes": ["wysiwyg.viewer.components.inputs.TextAreaInput"]
    },
    "help": {"hide": false, "text": "add_section_info_text_textarea"}
}
