import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { cx, hoc, hooks } from '@/util';

import { AUTOMATION_IDS } from './constants';
import { SiteSegmentName } from './components/SiteSegmentName/SiteSegmentName';
import { ResizeHandle } from '../ResizeHandle/ResizeHandle';
import { SiteSegmentEmptyState } from './SiteSegmentEmptyState';
import { SiteSegmentEmptyStateLiteMode } from './SiteSegmentEmptyStateLiteMode';
import { StageActions } from '../StageActions/StageActions';

import { mapDispatchToProps, mapStateToProps } from './SiteSegmentBox.mapper';
import {
  shouldShowHoveredSiteSegmentBoxSel,
  shouldShowSelectedSiteSegmentBoxSel,
} from './selectors';
import type {
  SiteSegmentBoxDispatchProps,
  SiteSegmentBoxStateProps,
} from './SiteSegmentBox.mapper';

import styles from './SiteSegmentBox.scss';
import experiment from 'experiment';

export enum BoxType {
  Hovered = 'Hovered',
  Selected = 'Selected',
}

const {
  connect,
  STORES: { MOUSE_OPS },
  withConditionalRender,
} = hoc;

const { useClickPreventionOnDoubleClick } = hooks;

export interface SiteSegmentBoxOwnProps {
  type: BoxType;
}

interface SiteSegmentBoxProps
  extends SiteSegmentBoxOwnProps,
    SiteSegmentBoxStateProps,
    SiteSegmentBoxDispatchProps {}

const stopEvent = (event: React.MouseEvent) => {
  event.preventDefault();
  event.stopPropagation();
};

const SiteSegmentBoxComponent: React.FC<SiteSegmentBoxProps> = ({
  canSelect,
  bothBoxesForSameComp,
  isMobileEditor,
  type,
  compRef,
  compRect,
  isResizing,
  isDragging,
  isFocused,
  isStageZoomOut,
  shouldShowEmptyState,
  isLiteModeEmptyStateVisible,
  siteScale,
  stageXOffset,
  shouldDisplayResizeHandle,
  shouldHideLabels,
  openAddPanel,
  logButtonBi,
  logEmptyStateShownBi,
  logEmptyStateButtonClick,
  selectComponent,
  isHeader,
  emptyStateAddPanelDefaultSection,
  isOverlayVisible,
  areContainerPointerEventsEnabled,
  stageActionsSkin,
  resizeHandleSkin,
  openQuickEditIfNeeded,
  getCenteredContainerStyle,
}) => {
  const [translate] = useTranslation();
  const isSelected = type === BoxType.Selected;
  const isHovered = !isSelected;

  const handleAddElementsClick = useCallback(
    (event: React.MouseEvent) => {
      stopEvent(event);
      selectComponent(compRef);
      openAddPanel(emptyStateAddPanelDefaultSection);
      logButtonBi('click');
      logEmptyStateButtonClick('add-element');
    },
    [
      emptyStateAddPanelDefaultSection,
      openAddPanel,
      logButtonBi,
      logEmptyStateButtonClick,
      compRef,
      selectComponent,
    ],
  );

  useEffect(() => {
    if (!shouldShowEmptyState || isSelected) return;
    logEmptyStateShownBi();
  }, [shouldShowEmptyState, isSelected, logEmptyStateShownBi]);

  const handleMouseDown = (event: React.MouseEvent) => {
    if (canSelect && !isSelected) {
      stopEvent(event);
      selectComponent(compRef);
    }
  };

  const handleOnClick = () => {
    if (
      isStageZoomOut &&
      experiment.isOpen('se_openQuickEditOnZoomStageClick')
    ) {
      openQuickEditIfNeeded('zoomStageClick');
    }
  };

  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
    handleOnClick,
    () => {},
  );

  // TODO: maybe move it to a separate component
  return (
    <div
      data-hook={`siteSegment-box-${type}`}
      data-comp-id={compRef.id}
      className={cx(styles.container, {
        [styles.selected]: (isSelected && !isFocused) || !canSelect,
        [styles.hovered]: isHovered,
        [styles.mouseOpsInProgress]: isDragging || isResizing,
        [styles.zoomMode]: isStageZoomOut || areContainerPointerEventsEnabled,
        [styles.bothSelectedAndHovered]: bothBoxesForSameComp,
        [styles.header]: isHeader,
        [styles.footer]: !isHeader,
        [styles.overlay]: isOverlayVisible,
      })}
      style={{
        left: stageXOffset,
        width: compRect.width * siteScale,
        top: compRect.top * siteScale,
        height: compRect.height * siteScale,
      }}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    >
      {shouldHideLabels ? null : <SiteSegmentName compRef={compRef} />}

      {shouldShowEmptyState && !isLiteModeEmptyStateVisible && (
        <SiteSegmentEmptyState
          siteSegment={compRef}
          type={isHeader ? 'header' : 'footer'}
          onAddElementsClick={handleAddElementsClick}
        />
      )}

      {shouldShowEmptyState && isLiteModeEmptyStateVisible && (
        <SiteSegmentEmptyStateLiteMode
          type={isHeader ? 'header' : 'footer'}
          onAddElementsClick={handleAddElementsClick}
        />
      )}
      {shouldDisplayResizeHandle && (
        <div
          data-hook={AUTOMATION_IDS.RESIZE_HANDLE_CONTAINER}
          className={cx(styles.resizeHandleContainer, {
            [styles.mobileEditor]: isMobileEditor,
          })}
          style={getCenteredContainerStyle(0.7, '70%')}
        >
          <ResizeHandle
            skin={resizeHandleSkin}
            compRef={compRef}
            tooltipText={translate(
              isHeader
                ? 'section_onstage_adjust_header_height_tooltip'
                : 'section_onstage_adjust_footer_height_tooltip',
            )}
          />
        </div>
      )}
      {stageActionsSkin && (
        <StageActions compRef={compRef} skin={stageActionsSkin} />
      )}
      <div className={styles.frame} />
    </div>
  );
};

const ConnectedSiteSegmentBox = connect(
  MOUSE_OPS,
  mapStateToProps,
  mapDispatchToProps,
)(SiteSegmentBoxComponent);

const ConnectedSelectedBox = withConditionalRender(
  MOUSE_OPS,
  shouldShowSelectedSiteSegmentBoxSel,
)(ConnectedSiteSegmentBox);

const ConnectedHoveredBox = withConditionalRender(
  MOUSE_OPS,
  shouldShowHoveredSiteSegmentBoxSel,
)(ConnectedSiteSegmentBox);

export const HoveredSiteSegmentBox = () => {
  return <ConnectedHoveredBox type={BoxType.Hovered} />;
};

export const SelectedSiteSegmentBox = () => (
  <ConnectedSelectedBox type={BoxType.Selected} />
);

HoveredSiteSegmentBox.displayName = 'HoveredSiteSegmentBox';
SelectedSiteSegmentBox.displayName = 'SelectedSiteSegmentBox';
