:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._actionsArea_1l27j_7 {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  z-index: 4;
  padding: var(--actions-area-vertical-padding) 0;
  transform: translateX(var(--actions-area-offset-x));
  margin-right: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  bottom: 0; }
  ._actionsArea_1l27j_7._mobile_1l27j_18 {
    transform: translateX(12px); }
  ._actionsArea_1l27j_7._fixed_1l27j_20 {
    transform: translateX(var(--actions-area-offset-x)); }

._redesign_1l27j_23._zoomMode_1l27j_23 {
  transform: translateX(12px); }

._invisibleContainer_1l27j_26 {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 0;
  overflow: hidden; }
