import React from 'react';
import { Text, Badge } from '@wix/wix-base-ui';

type BadgeProps = React.ComponentProps<typeof Badge>;

interface PathsPreview {
  path: string;
  skin?: BadgeProps['skin'];
}

interface PageHierarchyUrlPreviewProps {
  label: string;
  sitePublicUrl?: string;
  paths: PathsPreview[];
}
const SITE_URL_MAX_LENGTH = 45;
const PATH_SEPARATOR = '/';

const prefixEllipsis = (
  fullSitePublicUrl: string,
  paths: PathsPreview[],
): string => {
  const sitePublicUrl = fullSitePublicUrl.replace(/\/+$/, '');
  const pathsString = paths.map(({ path }) => path).join(PATH_SEPARATOR);
  const maxDomainLengh = SITE_URL_MAX_LENGTH - pathsString.length;
  if (sitePublicUrl.length <= maxDomainLengh) {
    return sitePublicUrl;
  }
  return `...${sitePublicUrl.slice(-maxDomainLengh)}`;
};
const PageHierarchyUrlPreview: React.FC<PageHierarchyUrlPreviewProps> = ({
  label,
  sitePublicUrl = '...',
  paths,
}) => {
  const firstPart = prefixEllipsis(sitePublicUrl, paths);
  return (
    <div style={{ display: 'flex', gap: '3px', marginTop: '12px' }}>
      <div style={{ marginRight: '9px' }}>
        <Text
          size="tiny"
          weight="bold"
          shouldTranslate={false}
          skin="secondary"
        >
          {label}
        </Text>
      </div>
      <Text size="small" weight="thin" shouldTranslate={false} skin="secondary">
        {firstPart}
      </Text>
      {paths.map(({ path, skin = 'general' }, i) => (
        <div key={i} style={{ display: 'flex', gap: '3px' }}>
          <Text
            size="small"
            weight="thin"
            shouldTranslate={false}
            skin="secondary"
          >
            {PATH_SEPARATOR}
          </Text>
          <Badge skin={skin} value={path} shouldTranslate={false} />
        </div>
      ))}
    </div>
  );
};

export default PageHierarchyUrlPreview;
