._slotPlaceholderItem_au5nj_1.selected {
  cursor: pointer;
  pointer-events: none; }

._slotPlaceholderItemTooltip_au5nj_5 {
  display: inline; }
  ._slotPlaceholderItemTooltip_au5nj_5 .control-text.skin-link {
    cursor: pointer; }
    ._slotPlaceholderItemTooltip_au5nj_5 .control-text.skin-link:hover {
      text-decoration: underline; }
