'use strict'

const titlesSection = require('../sections/text/titlesSection')
const paragraphsSection = require('../sections/text/paragraphsSection')
const collapsibleTextParagraphsSection = require('../sections/text/collapsibleTextParagraphsSection')
const textMaskSection = require('../sections/textEffects/textMaskSection')
const textMaskSectionVideos = require('../sections/textEffects/videos/textMaskSectionVideos')

module.exports = {
    titlesSection,
    paragraphsSection,
    collapsibleTextParagraphsSection,
    textMaskSectionVideos,
    textMaskSection,
}
