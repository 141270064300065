import * as mockSaveManager from './managers/mockSaveManager';
import connectDomainPanel from './panels/connectDomain/connectDomainPanel';
import connectDomainInProgress from './panels/connectDomain/connectDomainInProgress';
import * as commonPanels from './panels/common/commonPanels';
import * as savePanels from './panels/save/savePanels';
import * as publishPanels from './panels/publish/publishPanels';

const panels = {
  connectDomain: connectDomainPanel,
  connectDomainInProgress,
  common: commonPanels,
  publish: publishPanels,
  save: savePanels,
};

const configureSave = mockSaveManager.save;

export type {
  PublishOptions,
  PublishRCOptions,
  SaveAndPublishOptions,
} from './managers/publishManagerApi';

export { SavePublishEntryPoint } from './savePublishEntry';

export { configureSave, panels };
