// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import pageSettingsTabMixin from './pageSettingsTabMixin';
import React from 'react';
import { translate } from '@/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import * as pageMenuItemHelpers from '../../utils/pageMenuItemHelpers';
import { cx } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'googlePreviewExpandable',
  mixins: [pageSettingsTabMixin, ReactLinkedStateMixin], //eslint-disable-line react/no-deprecated
  render() {
    const descriptionSEO = 'Pages_SEO_Description_Placeholder';

    return (
      <div
        className={cx({ 'google-preview': true, expanded: this.isExpanded() })}
      >
        {!this.props.shouldHideExpandingButton ? (
          <div
            key="google-seo-expand-button"
            onClick={() => {
              this.openGooglePreview();
            }}
            className="google-preview-header"
          >
            <div className="google-preview-corner-container left">
              <div className="google-preview-corner" />
            </div>
            <div className="google-preview-corner-container right">
              <div className="google-preview-corner" />
            </div>
            <symbols.symbol name="equals" />
            <symbols.symbol name="arrowDown" />
            <span className="google-preview-header-title">
              {translate('Pages_SEO_Preview_Caption')}
            </span>

            <div className="google-preview-bottom-header-border" />
          </div>
        ) : null}

        <p className="google-preview-title">
          <span className="google-preview-title-site-name">
            {this.getTitle()}
          </span>
        </p>

        <p className="google-preview-url">
          {pageMenuItemHelpers.getPublicUrl(
            this.getEditorAPI(),
            this.props.pageData,
          )}
        </p>

        <p className="google-preview-description">
          {this.props.previewDescriptionText ||
            this.getValueFromPageData('descriptionSEO') ||
            translate(descriptionSEO)}
        </p>
      </div>
    );
  },
  propTypes: {
    pageData: PropTypes.object.isRequired,
    isExpanded: PropTypes.bool,
    shouldHideExpandingButton: PropTypes.bool,
    isExpandedLink: PropTypes.object,
    previewDescriptionText: PropTypes.string,
  },

  isExpanded() {
    return this.props.isExpanded || this.props.isExpandedLink.value;
  },

  openGooglePreview() {
    this.props.isExpandedLink.requestChange(!this.props.isExpandedLink.value);
  },

  getTitle() {
    if (this.props.title) {
      return this.props.title;
    }
    const editorAPI = this.getEditorAPI();
    return this.getValueFromPageData('pageTitleSEO')
      ? this.getValueFromPageData('pageTitleSEO')
      : (editorAPI.dsRead.seo.title.get() || editorAPI.siteName.get()) +
          (editorAPI.pages.isHomePage(this.props.pageData.id)
            ? ''
            : ` | ${this.getValueFromPageData('title')}`);
  },
});
