// @ts-nocheck
import * as util from '@/util';
import * as coreBi from '@/coreBi';

const MENU_TYPE = 'wysiwyg.viewer.components.MenuContainer';

export default function (editorAPI, compRefs, compGfppData) {
  const target = util.inlinePopupUtils.getToggleTarget(editorAPI, compRefs);
  if (target && editorAPI.components.getType(target) === MENU_TYPE) {
    const isOpen = util.inlinePopupUtils.isOpen(editorAPI, target);
    const toggleMenuAction = {
      label: () =>
        isOpen
          ? 'gfpp_mainaction_mobile_menu_close_menu'
          : 'gfpp_mainaction_mobile_menu_open_menu',
      onClick(api: EditorAPI, originComponent?: compRef, origin: string) {
        editorAPI.mobile.toggleMobileMenu(target);

        if (!isOpen) {
          editorAPI.bi.event(coreBi.events.panels.PANEL_OPENED, {
            panel_name: 'mobileMenuExpand',
            origin,
          });
        } else {
          editorAPI.bi.event(coreBi.events.panels.PANEL_CLOSED, {
            panel_name: 'mobileMenuCollapse',
            origin,
          });
        }
      },
      automationId: 'gfpp_menu_toggle_action',
    };
    compGfppData.mainActions = [
      ...(compGfppData.mainActions || []),
      toggleMenuAction,
    ];
  }
}
