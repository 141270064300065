import { sections as sectionsUtils } from '@/util';
import { events } from '@/coreBi';

import { cleanupEditorData } from '../utils';
import { MIGRATION_VERSION } from '../version';
import { MigrationFlow } from '../types';
import { saveInBackground } from './saveMigration';

import type { SectionsMigrationScope as Scope } from '../scope';
import type { MigrationOrigin } from '../constants';

export function skipMigration(scope: Scope) {
  scope.editorCoreAPI.hooks.sectionsMigrationReady.resolve({
    isMigrated: false,
  });

  cleanupEditorData(scope.editorAPI.documentServices);
}

export function resolveMigration(
  scope: Scope,
  {
    flow,
    origin,
  }: {
    flow: MigrationFlow;
    origin: MigrationOrigin;
  },
) {
  sectionsUtils.updateSiteSectionsEditorData(scope.editorAPI, {
    isSectionsEnabled: true,
    isMigratedFromAdi: flow === MigrationFlow.ADI,
    migrationVersion: MIGRATION_VERSION,
  });

  scope.editorAPI.bi.event(events.sectionsMigration.MIGRATION_SUCCESS, {
    flow,
    origin,
    duration: 0,
    pages_list: '',
    is_document_changed: false,
    template_id:
      scope.editorAPI.documentServices.generalInfo.getSiteOriginalTemplateId(),
  });

  if (flow !== MigrationFlow.Template) {
    void saveInBackground(scope);
  }

  scope.editorCoreAPI.hooks.sectionsMigrationReady.resolve({
    isMigrated: true,
  });
}
