import React, { type FC } from 'react';
import ProductItem from './productItem';
import type { Product } from './types';

export interface ProductListProps {
  items: Product[];
  sectionTitle?: JSX.Element;
}

const ProductList: FC<ProductListProps> = ({ items, sectionTitle }) => (
  <div className="ascend-product-list">
    {sectionTitle}
    <div className="items">
      {items.map((item) => (
        <ProductItem key={item.name} {...item} />
      ))}
    </div>
  </div>
);

export default ProductList;
