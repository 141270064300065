// @ts-nocheck
import _ from 'lodash';

const MEDIA_TYPE = {
  photos: 'IMAGE',
  secure_picture: 'IMAGE_PRO',
  backgrounds: 'BG_IMAGE',
  audio: 'MUSIC',
  documents: 'DOCUMENT',
  secure_music: 'MUSIC_PRO',
  swf: 'FLASH',
  video: 'VIDEO',
  shape: 'SHAPE',
  music: 'MUSIC',
  clipart: 'IMAGE',
  bg_video: 'BG_VIDEO',
  icon_document: 'ICON_DOCUMENT',
  bg_social: 'ICON_SOCIAL',
  bg_favicon: 'ICON_FAVICON',
};

const convertMediaType = function (type, mediaManager) {
  const category = MEDIA_TYPE[type];
  return mediaManager.categories[category];
};

const convertMediaManagerResultForSuperApps = function (rawData) {
  const mediaItem = _.cloneDeep(rawData);
  mediaItem.relativeUri = rawData.uri || '';
  return mediaItem;
};

const convertMediaManagerResult = function (rawData) {
  const mediaItem = {};

  mediaItem.fileName = rawData.title || '';

  if (rawData.mediaType === 'secure_music') {
    const audioFilesData = rawData.fileOutput?.audio;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const shortPreviewFileData = _.find(audioFilesData, {
      tag: 'preview-short',
    });
    mediaItem.shortPreviewFileName = shortPreviewFileData?.url || '';
    mediaItem.previewFileName = rawData.previewFileName || '';
    mediaItem.fileUrl = rawData.fileUrl || '';
  } else {
    mediaItem.relativeUri = rawData.fileName || '';
    mediaItem.width = Number(rawData.width) || 0;
    mediaItem.height = Number(rawData.height) || 0;
  }

  return mediaItem;
};

export {
  convertMediaManagerResult,
  convertMediaManagerResultForSuperApps,
  convertMediaType,
};
