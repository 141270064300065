// @ts-nocheck
import constants from '@/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_toggle_switch',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
    },
  ],
  enabledActions: [
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileMainActions: [],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.ANIMATION, ACTIONS.HELP],
  presetActions: {
    help: {
      helpId: '643f64e0-0c41-48dc-97a4-08996301ddaf',
    },
  },
};
