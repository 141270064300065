{
  "TINY_MENU": {
    "wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin": {
      "label": "Mobile_Wizard_Welcome_Centre_Label",
      "imageSrc": "tinyMenuCenter.jpg",
      "automationId": "TinyMenuFullScreenSkin",
      "order": "1"
    },
    "wysiwyg.viewer.skins.mobile.TinyMenuPullFromLeftSkin": {
      "label": "Mobile_Wizard_Welcome_Left_Label",
      "imageSrc": "tinyMenuLeft.jpg",
      "automationId": "TinyMenuPullFromLeftSkin",
      "order": "0"
    },
    "wysiwyg.viewer.skins.mobile.TinyMenuPullFromRightSkin": {
      "label": "Mobile_Wizard_Welcome_Right_Label",
      "imageSrc": "tinyMenuRight.jpg",
      "automationId": "TinyMenuPullFromRightSkin",
      "order": "2"
    }
  },
  "SITE_HEADER": {
    "stays_on_top": {
      "label": "Mobile_Wizard_Welcome_Scroll_Label",
      "imageSrc": "headerEffectsScroll.jpg",
      "automationId": "stays_on_top",
      "order": "1"
    },
    "freezes": {
      "label": "Mobile_Wizard_Welcome_Fixed_Label",
      "imageSrc": "headerEffectsFixed.jpg",
      "automationId": "freezes",
      "order": "4"
    },
    "disappears": {
      "label": "Mobile_Wizard_Welcome_Dissapears_Label",
      "imageSrc": "headerEffectsDisappears.jpg",
      "automationId": "disappears",
      "order": "2"
    },
    "fade_out": {
      "label": "Mobile_Wizard_Welcome_Fades_Label",
      "imageSrc": "headerEffectsFadesOut.jpg",
      "automationId": "fade_out",
      "order": "3"
    }
  },
  "QUICK_ACTION_BAR": {
    "NONE": {
      "label": "Mobile_Wizard_Welcome_None_Label",
      "imageSrc": "qabNone.jpg",
      "automationId": "NONE",
      "order": "2"
    },
    "QuickActionBar_Preset6": {
      "label": "Mobile_Wizard_Welcome_Bottom_Label",
      "imageSrc": "qabMab.jpg",
      "automationId": "QuickActionBar_Bottom",
      "order": "1"
    },
    "QuickActionBar_Preset4": {
      "label": "Mobile_Wizard_Welcome_Floating_Label",
      "imageSrc": "qabFab.jpg",
      "automationId": "QuickActionBar_Floating",
      "order": "0"
    }
  },
  "BACK_TO_TOP_BUTTON": {
    "true": {
      "label": "Mobile_Wizard_Welcome_Yes_Label",
      "imageSrc": "back2TopYes.jpg",
      "automationId": "BACK_TO_TOP_BUTTON_TRUE"
    },
    "false": {
      "label": "Mobile_Wizard_Welcome_No_Label",
      "imageSrc": "back2TopNo.jpg",
      "automationId": "BACK_TO_TOP_BUTTON_FALSE"
    }
  }
}
