import React from 'react';

import * as util from '@/util';
import * as coreBi from '@/coreBi';

import connect from './publishRCConnect';
import { PublishRCFrame, NewWorkspacePublishRCFrame } from './publishRCFrame';

import type { SendBiFunction } from 'types/bi';
import type { PublishOptions } from '@/savePublish';

interface PublishRCDropPanelProps {
  shouldSaveBeforePublish?: boolean;
  publish: (biParams?: PublishOptions) => void;
  saveAndPublish: (biParams?: PublishOptions) => void;
  publishRC: (origin?: string) => void;
  canUserPublish: () => boolean;
  sendBI: SendBiFunction;
  branchName: string;
  shouldShowBranchName: boolean;
}

class PublishRCDropPanel extends React.Component<PublishRCDropPanelProps> {
  private isNewWorkspace: boolean = util.workspace.isNewWorkspaceEnabled();
  publish = () => {
    if (this.props.canUserPublish()) {
      if (!this.props.shouldSaveBeforePublish) {
        this.props.publish({
          origin: 'top_bar_publishRC_dropdown_publish',
          sourceOfStart: 'topBar_publishRCButton_dropPanel',
        });
      } else {
        this.props.saveAndPublish({
          origin: 'top_bar_publishRC_dropdown_publish',
          sourceOfStart: 'topBar_publishRCButton_dropPanel',
        });
      }
      this.props.sendBI(coreBi.events.publish.PUBLISH_ALL, {
        origin: 'publish',
      });
    }
  };
  publishRC = () => {
    this.props.sendBI(coreBi.events.topbar.start_creating_rc_click, {
      origin: 'publish',
    });
    this.props.publishRC('publish');
  };
  render() {
    const PanelFrame = this.isNewWorkspace
      ? NewWorkspacePublishRCFrame
      : PublishRCFrame;
    return (
      <PanelFrame
        publish={this.publish}
        publishRC={this.publishRC}
        branchName={this.props.branchName}
        shouldShowBranchName={this.props.shouldShowBranchName}
      />
    );
  }
}

const ConnectedComponent = connect(PublishRCDropPanel);
ConnectedComponent.pure = PublishRCDropPanel;

export default ConnectedComponent;
