// @ts-nocheck
import addPanelDataConsts from '@/addPanelDataConsts';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: 'add_section_label_bloglayouts',
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
    iconEnabledForComps: {},
  },
  props: {
    image: 'addPanelData/presetData/en/blogLayoutsSection.png',
    imageHover: null,
    items: [
      {
        id: 'Blog_Blog_Layouts_1',
        preset: { rect: { width: 162, height: 251, x: 0, y: 0 }, label: '' },
        viewName: 'MediaLeftPage',
      },
      {
        id: 'Blog_Blog_Layouts_2',
        preset: { rect: { width: 162, height: 440, x: 162, y: 0 }, label: '' },
        viewName: 'MediaTopPage',
      },
      {
        id: 'Blog_Blog_Layouts_3',
        preset: { rect: { width: 162, height: 256, x: 0, y: 251 }, label: '' },
        viewName: 'MasonryPage',
      },
      {
        id: 'Blog_Blog_Layouts_4',
        preset: {
          rect: { width: 162, height: 256, x: 162, y: 440 },
          label: '',
        },
        viewName: 'MediaZigzagPage',
      },
      {
        id: 'Blog_Blog_Layouts_5',
        preset: { rect: { width: 162, height: 440, x: 0, y: 507 }, label: '' },
        viewName: 'MediaBottomPage',
      },
      {
        id: 'Blog_Blog_Layouts_6',
        preset: {
          rect: { width: 162, height: 251, x: 161, y: 696 },
          label: '',
        },
        viewName: 'MediaRightPage',
      },
    ],
    compTypes: ['mobile.core.components.Container'],
  },
  help: { hide: false, text: 'add_section_info_text_bloglayouts' },
};
