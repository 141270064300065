import React from 'react';
import { Text } from '@wix/wix-base-ui';
import { translate } from '@/i18n';

import { frames } from '@/panels';
import PageHierarchyUrlPreview from '../../commonControls/pageHierarchyUrlPreview/pageHierarchyUrlPreview';
import PageHierarchyTitleWithChildPages from '../../commonControls/PageHierarchyTitleWithChildPages/PageHierarchyTitleWithChildPages';
const { MessagePanelFrame } = frames;

interface ParentHomePageMessageProps {
  childPageTitles: string[];
  hasDomain: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  panelName: string;
  sitePublicUrl: string;
}

const ParentHomePageMessage: React.FC<ParentHomePageMessageProps> = (props) => {
  const title = translate('Pages_Settings_Set_Homepage_SiteHierarchy_Title');
  const messageContent = props.hasDomain
    ? translate('Pages_Settings_Set_Homepage_SiteHierarchy_Message_WithDomain')
    : translate('Pages_Settings_Set_Homepage_SiteHierarchy_Message_NoDomain');
  return (
    <MessagePanelFrame
      panelName={props.panelName}
      dataHook="parent-home-page-message"
      theme="standard"
      title={title}
      illustration={null}
      confirmLabel={translate(
        'Pages_Settings_Set_Homepage_SiteHierarchy_Sethomepage_Button',
      )}
      cancelLabel={translate(
        'Pages_Settings_Set_Homepage_SiteHierarchy_Cancel_Button',
      )}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      confirmOnEnterPress
    >
      <PageHierarchyTitleWithChildPages
        translationKey={
          'Pages_Settings_Set_Homepage_SiteHierarchy_NumberofChildrenMessage'
        }
        childPageTitles={props.childPageTitles}
      />
      <Text
        size="medium"
        weight="thin"
        skin="secondary"
        shouldTranslate={false}
        enableEllipsis={false}
      >
        {messageContent}
      </Text>
      <PageHierarchyUrlPreview
        label={translate('Pages_Settings_Set_Homepage_SiteHierarchy_OldURLy')}
        paths={[
          {
            path: translate('Pages_Settings_Set_Homepage_SiteHierarchy_Parent'),
          },
          {
            path: translate('Pages_Settings_Set_Homepage_SiteHierarchy_Child'),
          },
        ]}
      />
      <PageHierarchyUrlPreview
        label={translate('Pages_Settings_Set_Homepage_SiteHierarchy_NewURL')}
        sitePublicUrl={props.sitePublicUrl}
        paths={[
          {
            path: translate('Pages_Settings_Set_Homepage_SiteHierarchy_Child'),
          },
        ]}
      />
    </MessagePanelFrame>
  );
};

export default ParentHomePageMessage;
