const menuMode = "wrap";
const submenuMode = "columnMenu";
const submenuOrder = "vertical";
const css = "";


module.exports = {
    type: 'Component',
    skin: 'wixui.skins.Skinless',
    layout: {
        width: 609,
        height: 59,
        x: 20,
        y: 917,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false
    },
    componentType: 'wixui.StylableHorizontalMenu',
    metaData: {pageId: 'c1dmp'},
    parent: 'c1dmp',
    data: {
        type: 'CustomMenuDataRef',
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c1dmp'
        },
        menuRef: '#CUSTOM_MAIN_MENU'
    },
    props: {
        type: 'StylableHorizontalMenuProperties',
        metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'c1dmp'
        },
        menuMode,
        submenuMode,
        submenuOrder
    },
    style: {
        type: 'ComponentStyle',
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'c1dmp'
        },
        style: {
            properties: {
                '$st-css': css
            },
            propertiesSource: {'$st-css': 'value'}
        },
        componentClassName: 'wixui.StylableHorizontalMenu',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wixui.skins.Skinless'
    },
    activeModes: {},
    id: 'comp-kbhzww6h'
}
