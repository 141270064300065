export const regularComponentsContentTags = {
  TYPE: 'Regular',
  REGULAR_PLUS_IMAGE: 'RegularPlusMediaImage',
  TITLE: 'RegularTitle',
  SUBTITLE: 'RegularSubtitle',
  LONGTEXT: 'RegularLongtext',
  IMAGE: 'RegularMediaImage',
  BUTTON: 'RegularButton',
};

export const listComponentsContentTags = {
  TYPE: 'List',
  LIST_PLUS_IMAGE: 'ListPlusMediaImage',
  HEADER: {
    TITLE: 'ListHeaderTitle',
    SUBTITLE: 'ListHeaderSubtitle',
    LONGTEXT: 'ListHeaderLongtext',
  },
  LIST: {
    ITEM: 'ListItem',
    TITLE: 'Title',
    SUBTITLE: 'Subtitle',
    LONGTEXT: 'Longtext',
    IMAGE: 'MediaImage',
    BUTTON: 'Button',
  },
  REPEATER: {
    ITEM: 'Repeater',
    IMAGE: 'Image',
    TEXT: 'Text',
  },
};

export enum InjectionPropNameMap {
  TITLE = 'Title',
  SUBTITLE = 'Subtitle',
  LONGTEXT = 'Longtext',
  MEDIA_IMAGE = 'MediaImage',
  BUTTON = 'Button',
}

export enum CaasPropNameMap {
  TITLE = 'title',
  SUBTITLE = 'subTitle',
  LONGTEXT = 'longText',
  MEDIA = 'media',
  BUTTON = 'button',
}

export const SECTION = 'Section';
export const REGULAR = 'Regular';
export const IMAGE = 'Image';
export const SECTION_REGULAR = 'SectionRegular';
export const SECTION_LIST = 'SectionList';
export const SECTION_REPEATER = 'SectionRepeater';
export const SECTION_SLIDESHOW = 'SectionSlideShow';
export const LIST = 'List';
export const LIST_ITEM = 'ListItem';
export const SLIDESHOW = 'SlideShow';
export const SLIDESHOW_SLIDE = 'SlideShowSlide';
export const SLIDESHOW_ITEM = 'SlideShowItem';
export const REPEATER = 'Repeater';
export const HEADER = 'Header';
export const FOOTER = 'Footer';
export const TITLE = 'Title';
export const SUBTITLE = 'Subtitle';
export const LONGTEXT = 'Longtext';
