:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._popularArticle_nn7gn_7 {
  cursor: pointer;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 24px; }
  ._popularArticle_nn7gn_7 ._helpTitle_nn7gn_13 {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer; }
    ._popularArticle_nn7gn_7 ._helpTitle_nn7gn_13 .control-label {
      color: #000624; }
  ._popularArticle_nn7gn_7 ._helpIcon_nn7gn_21 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3b4057;
    width: 35px;
    height: 35px;
    margin-right: 18px;
    flex-shrink: 0;
    border: #dfe5eb solid 1px;
    border-radius: 50%; }
  ._popularArticle_nn7gn_7:hover {
    background-color: #e7f0ff; }
    ._popularArticle_nn7gn_7:hover ._helpIcon_nn7gn_21 {
      background: #fff; }

._seeAllButton_nn7gn_37 {
  display: flex;
  margin: 20px auto 0 auto; }
  ._seeAllButton_nn7gn_37.control-button svg path {
    fill: currentColor; }
  ._seeAllButton_nn7gn_37.control-button .control-label {
    color: currentColor; }
