import superAppPanel from './panels/superAppPanel';
import button from './utils/button';
import getStartedSlide from './panels/firstTimeTour/slides/getStartedSlide';
import installAppSlide from './panels/firstTimeTour/slides/installAppSlide';
import welcomeSlide from './panels/firstTimeTour/slides/welcomeSlide';
import welcomeSlideWithNavigation from './panels/firstTimeTour/slides/welcomeSlideWithNavigation';
import superAppTooltip from './panels/superAppTooltip/superAppTooltip';
import { superAppEvents, BIAppActionType } from './bi/superAppEvents';

const firstTimeTourPanels = {
  getStartedSlide,
  installAppSlide,
  welcomeSlide,
  welcomeSlideWithNavigation,
};

export {
  superAppPanel,
  firstTimeTourPanels,
  superAppTooltip,
  button,
  superAppEvents,
  BIAppActionType,
};
