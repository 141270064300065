'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_toggle_switch",
    "subNavigationTitle": "add_section_label_toggle_switch",
    "topTitle": "add_section_title_toggle_switch",
    "presetTitle": "add_section_heading_toggle_switch",
    "tooltipTitle": "add_section_label_toggle_switch_tooltip_title",
    "automationId": "add-panel-section-toggleSwitchSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/developer_en/toggleSwitchSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "User_Input_ToggleSwitch_1",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.ToggleSwitch",
                "layout": {
                    "width": 48,
                    "height": 24,
                    "x": 10,
                    "y": 30,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.ToggleSwitch",
                "data": {
                    "type": "ToggleSwitch",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "value": "",
                    "checked": true
                },
                "props": {
                    "type": "ToggleSwitchProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "isDisabled": false,
                    "displayKnobIcons": false,
                    "trackHeight": 100,
                    "knobSize": 100,
                    "alignment": "left"
                },
                "mobileHints": {
                    "type": "MobileHints",
                    "author": "duplicate",
                    "originalCompId": "comp-jdd36259",
                    "recommendedScale": 1,
                    "recommendedWidth": 48,
                    "recommendedHeight": 24
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-colorDisabled": "1",
                            "alpha-innerLabelBorderColorChecked": "1",
                            "alpha-outerLabelBorderColorHoverChecked": "1",
                            "backgroundColor": "#A8A8A8",
                            "backgroundColorChecked": "#3899EC",
                            "backgroundColorDisabled": "#D9D9D9",
                            "backgroundColorFocus": "#456385",
                            "backgroundColorHover": "#616161",
                            "backgroundColorHoverChecked": "#4EB7F5",
                            "borderRadius": "999px 999px 999px 999px",
                            "boxShadowToggleOn-innerLabelBoxShadow": "false",
                            "boxShadowToggleOn-outerLabelBoxShadow": "false",
                            "color": "#A8A8A8",
                            "colorChecked": "#3899EC",
                            "colorCheckedDisabled": "#D9D9D9",
                            "colorDisabled": "#D9D9D9",
                            "colorHover": "#616161",
                            "colorHoverChecked": "#00A6FF",
                            "innerLabelBackgroundColor": "#FFFFFF",
                            "innerLabelBackgroundColorChecked": "#FFFFFF",
                            "innerLabelBackgroundColorDisabled": "#FFFFFF",
                            "innerLabelBackgroundColorHover": "#FFFFFF",
                            "innerLabelBackgroundColorHoverChecked": "#FFFFFF",
                            "innerLabelBorderColor": "#A8A8A8",
                            "innerLabelBorderColorChecked": "#3899EC",
                            "innerLabelBorderColorDisabled": "#D9D9D9",
                            "innerLabelBorderColorHover": "#616161",
                            "innerLabelBorderColorHoverChecked": "#4EB7F5",
                            "innerLabelBorderSize": "2",
                            "innerLabelBorderSizeChecked": "2",
                            "innerLabelBorderSizeDisabled": "2",
                            "innerLabelBorderSizeHover": "2",
                            "innerLabelBorderSizeHoverChecked": "2",
                            "innerLabelBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "outerLabelBorderColor": "#A8A8A8",
                            "outerLabelBorderColorChecked": "#4EB7F5",
                            "outerLabelBorderColorDisabled": "#D9D9D9",
                            "outerLabelBorderColorFocus": "#456385",
                            "outerLabelBorderColorHover": "#616161",
                            "outerLabelBorderColorHoverChecked": "#3899EC",
                            "outerLabelBorderSizeChecked": "0",
                            "outerLabelBorderSizeDisabled": "2",
                            "outerLabelBorderSizeFocus": "0",
                            "outerLabelBorderSizeHover": "2",
                            "outerLabelBorderSizeHoverChecked": "0",
                            "outerLabelBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-colorDisabled": "value",
                            "alpha-innerLabelBorderColorChecked": "value",
                            "alpha-outerLabelBorderColorHoverChecked": "value",
                            "backgroundColor": "value",
                            "backgroundColorChecked": "value",
                            "backgroundColorDisabled": "value",
                            "backgroundColorFocus": "value",
                            "backgroundColorHover": "value",
                            "backgroundColorHoverChecked": "value",
                            "borderRadius": "value",
                            "color": "value",
                            "colorChecked": "value",
                            "colorCheckedDisabled": "value",
                            "colorDisabled": "value",
                            "colorHover": "value",
                            "colorHoverChecked": "value",
                            "innerLabelBackgroundColor": "value",
                            "innerLabelBackgroundColorChecked": "value",
                            "innerLabelBackgroundColorDisabled": "value",
                            "innerLabelBackgroundColorHover": "value",
                            "innerLabelBackgroundColorHoverChecked": "value",
                            "innerLabelBorderColor": "value",
                            "innerLabelBorderColorChecked": "value",
                            "innerLabelBorderColorDisabled": "value",
                            "innerLabelBorderColorHover": "value",
                            "innerLabelBorderColorHoverChecked": "value",
                            "innerLabelBorderSize": "value",
                            "innerLabelBorderSizeChecked": "value",
                            "innerLabelBorderSizeDisabled": "value",
                            "innerLabelBorderSizeHover": "value",
                            "innerLabelBorderSizeHoverChecked": "value",
                            "outerLabelBorderColor": "value",
                            "outerLabelBorderColorChecked": "value",
                            "outerLabelBorderColorDisabled": "value",
                            "outerLabelBorderColorFocus": "value",
                            "outerLabelBorderColorHover": "value",
                            "outerLabelBorderColorHoverChecked": "value",
                            "outerLabelBorderSizeChecked": "value",
                            "outerLabelBorderSizeDisabled": "value",
                            "outerLabelBorderSizeFocus": "value",
                            "outerLabelBorderSizeHover": "value",
                            "outerLabelBorderSizeHoverChecked": "value"
                        }
                    },
                    "componentClassName": "wixui.ToggleSwitch",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.ToggleSwitch"
                },
                "activeModes": {},
                "id": "comp-jeqv4283"
            },
            "preset": {"rect": {"width": 81, "height": 69, "x": 0, "y": 5}, "tags": null}
        }, {
            "id": "User_Input_ToggleSwitch_2",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.ToggleSwitch",
                "layout": {
                    "width": 48,
                    "height": 24,
                    "x": 95,
                    "y": 30,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.ToggleSwitch",
                "data": {
                    "type": "ToggleSwitch",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "value": "",
                    "checked": true
                },
                "props": {
                    "type": "ToggleSwitchProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "isDisabled": false,
                    "displayKnobIcons": false,
                    "trackHeight": 33,
                    "knobSize": 100,
                    "alignment": "left"
                },
                "mobileHints": {
                    "type": "MobileHints",
                    "author": "duplicate",
                    "originalCompId": "comp-jdn5uhow",
                    "recommendedScale": 1,
                    "recommendedWidth": 48,
                    "recommendedHeight": 24
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "backgroundColor": "#000000",
                            "backgroundColorChecked": "#000000",
                            "backgroundColorDisabled": "#E0E0E0",
                            "backgroundColorFocus": "#000000",
                            "backgroundColorHover": "#616161",
                            "backgroundColorHoverChecked": "#616161",
                            "borderRadius": "999px 999px 999px 999px",
                            "boxShadowToggleOn-innerLabelBoxShadow": "true",
                            "boxShadowToggleOn-outerLabelBoxShadow": "false",
                            "color": "#FFFFFF",
                            "colorChecked": "#FFFFFF",
                            "colorCheckedDisabled": "#49D6D6",
                            "colorDisabled": "#FFFFFF",
                            "colorHover": "#616161",
                            "colorHoverChecked": "#FFFFFF",
                            "innerLabelBackgroundColor": "#FFFFFF",
                            "innerLabelBackgroundColorChecked": "#000000",
                            "innerLabelBackgroundColorDisabled": "#E0E0E0",
                            "innerLabelBackgroundColorHover": "#FFFFFF",
                            "innerLabelBackgroundColorHoverChecked": "#616161",
                            "innerLabelBorderColor": "#000000",
                            "innerLabelBorderColorChecked": "#000000",
                            "innerLabelBorderColorDisabled": "#E0E0E0",
                            "innerLabelBorderColorHover": "#616161",
                            "innerLabelBorderColorHoverChecked": "#616161",
                            "innerLabelBorderSize": "3",
                            "innerLabelBorderSizeChecked": "3",
                            "innerLabelBorderSizeDisabled": "3",
                            "innerLabelBorderSizeHover": "3",
                            "innerLabelBorderSizeHoverChecked": "3",
                            "innerLabelBoxShadow": "0.00px 0.00px 0px 3px rgba(255,255,255,1)",
                            "outerLabelBorderColor": "#FFFFFF",
                            "outerLabelBorderColorChecked": "#FFFFFF",
                            "outerLabelBorderColorDisabled": "#FFFFFF",
                            "outerLabelBorderColorFocus": "#FFFFFF",
                            "outerLabelBorderColorHover": "#FFFFFF",
                            "outerLabelBorderColorHoverChecked": "#FFFFFF",
                            "outerLabelBorderSize": "2",
                            "outerLabelBorderSizeChecked": "2",
                            "outerLabelBorderSizeDisabled": "2",
                            "outerLabelBorderSizeFocus": "1",
                            "outerLabelBorderSizeHover": "2",
                            "outerLabelBorderSizeHoverChecked": "2",
                            "outerLabelBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "backgroundColor": "value",
                            "backgroundColorChecked": "value",
                            "backgroundColorDisabled": "value",
                            "backgroundColorFocus": "value",
                            "backgroundColorHover": "value",
                            "backgroundColorHoverChecked": "value",
                            "borderRadius": "value",
                            "color": "value",
                            "colorChecked": "value",
                            "colorCheckedDisabled": "value",
                            "colorDisabled": "value",
                            "colorHover": "value",
                            "colorHoverChecked": "value",
                            "innerLabelBackgroundColor": "value",
                            "innerLabelBackgroundColorChecked": "value",
                            "innerLabelBackgroundColorDisabled": "value",
                            "innerLabelBackgroundColorHover": "value",
                            "innerLabelBackgroundColorHoverChecked": "value",
                            "innerLabelBorderColor": "value",
                            "innerLabelBorderColorChecked": "value",
                            "innerLabelBorderColorDisabled": "value",
                            "innerLabelBorderColorHover": "value",
                            "innerLabelBorderColorHoverChecked": "value",
                            "innerLabelBorderSize": "value",
                            "innerLabelBorderSizeChecked": "value",
                            "innerLabelBorderSizeDisabled": "value",
                            "innerLabelBorderSizeHover": "value",
                            "innerLabelBorderSizeHoverChecked": "value",
                            "innerLabelBoxShadow": "value",
                            "outerLabelBorderColor": "value",
                            "outerLabelBorderColorChecked": "value",
                            "outerLabelBorderColorDisabled": "value",
                            "outerLabelBorderColorFocus": "value",
                            "outerLabelBorderColorHover": "value",
                            "outerLabelBorderColorHoverChecked": "value",
                            "outerLabelBorderSize": "value",
                            "outerLabelBorderSizeChecked": "value",
                            "outerLabelBorderSizeDisabled": "value",
                            "outerLabelBorderSizeFocus": "value",
                            "outerLabelBorderSizeHover": "value",
                            "outerLabelBorderSizeHoverChecked": "value"
                        }
                    },
                    "componentClassName": "wixui.ToggleSwitch",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.ToggleSwitch"
                },
                "activeModes": {},
                "id": "comp-jeqv4297"
            },
            "preset": {"rect": {"width": 81, "height": 69, "x": 81, "y": 5}, "tags": null}
        }, {
            "id": "User_Input_ToggleSwitch_3",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.ToggleSwitch",
                "layout": {
                    "width": 48,
                    "height": 24,
                    "x": 180,
                    "y": 30,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.ToggleSwitch",
                "data": {
                    "type": "ToggleSwitch",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "value": "",
                    "checked": true
                },
                "props": {
                    "type": "ToggleSwitchProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "isDisabled": false,
                    "displayKnobIcons": false,
                    "trackHeight": 100,
                    "knobSize": 66,
                    "alignment": "left"
                },
                "mobileHints": {
                    "type": "MobileHints",
                    "author": "duplicate",
                    "originalCompId": "comp-jdd3625t",
                    "recommendedScale": 1,
                    "recommendedWidth": 48,
                    "recommendedHeight": 24
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-backgroundColorFocus": "0",
                            "alpha-colorCheckedDisabled": "1",
                            "backgroundColor": "#FFFFFF",
                            "backgroundColorChecked": "#FFFFFF",
                            "backgroundColorDisabled": "#D9D9D9",
                            "backgroundColorFocus": "#CCCCCC",
                            "backgroundColorHover": "#FDD6D6",
                            "backgroundColorHoverChecked": "#8C84FA",
                            "borderRadius": "999px 999px 999px 999px",
                            "boxShadowToggleOn-innerLabelBoxShadow": "true",
                            "boxShadowToggleOn-outerLabelBoxShadow": "false",
                            "color": "#FFFFFF",
                            "colorChecked": "#FFFFFF",
                            "colorCheckedDisabled": "#D9D9D9",
                            "colorDisabled": "#D9D9D9",
                            "colorHover": "#FDD6D6",
                            "colorHoverChecked": "#8C84FA",
                            "innerLabelBackgroundColor": "#FDD6D6",
                            "innerLabelBackgroundColorChecked": "#8C84FA",
                            "innerLabelBackgroundColorDisabled": "#FFFFFF",
                            "innerLabelBackgroundColorHover": "#FFFFFF",
                            "innerLabelBackgroundColorHoverChecked": "#FFFFFF",
                            "innerLabelBorderColor": "#FDD6D6",
                            "innerLabelBorderColorChecked": "#FFFFFF",
                            "innerLabelBorderColorDisabled": "#FFFFFF",
                            "innerLabelBorderColorHover": "#FFFFFF",
                            "innerLabelBoxShadow": "0.00px 1.99px 4px 0px rgba(153,153,151,0.2)",
                            "outerLabelBorderColor": "#FDD6D6",
                            "outerLabelBorderColorChecked": "#8C84FA",
                            "outerLabelBorderColorDisabled": "#D9D9D9",
                            "outerLabelBorderColorFocus": "#CCCCCC",
                            "outerLabelBorderColorHover": "#FDD6D6",
                            "outerLabelBorderColorHoverChecked": "#83A8F0",
                            "outerLabelBorderSize": "2",
                            "outerLabelBorderSizeChecked": "2",
                            "outerLabelBorderSizeFocus": "2",
                            "outerLabelBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-backgroundColorFocus": "value",
                            "alpha-colorCheckedDisabled": "value",
                            "backgroundColor": "value",
                            "backgroundColorChecked": "value",
                            "backgroundColorDisabled": "value",
                            "backgroundColorFocus": "value",
                            "backgroundColorHover": "value",
                            "backgroundColorHoverChecked": "value",
                            "borderRadius": "value",
                            "color": "value",
                            "colorChecked": "value",
                            "colorCheckedDisabled": "value",
                            "colorDisabled": "value",
                            "colorHover": "value",
                            "colorHoverChecked": "value",
                            "innerLabelBackgroundColor": "value",
                            "innerLabelBackgroundColorChecked": "value",
                            "innerLabelBackgroundColorDisabled": "value",
                            "innerLabelBackgroundColorHover": "value",
                            "innerLabelBackgroundColorHoverChecked": "value",
                            "innerLabelBorderColor": "value",
                            "innerLabelBorderColorChecked": "value",
                            "innerLabelBorderColorDisabled": "value",
                            "innerLabelBorderColorHover": "value",
                            "innerLabelBoxShadow": "value",
                            "outerLabelBorderColor": "value",
                            "outerLabelBorderColorChecked": "value",
                            "outerLabelBorderColorDisabled": "value",
                            "outerLabelBorderColorFocus": "value",
                            "outerLabelBorderColorHover": "value",
                            "outerLabelBorderColorHoverChecked": "value",
                            "outerLabelBorderSize": "value",
                            "outerLabelBorderSizeChecked": "value",
                            "outerLabelBorderSizeFocus": "value",
                            "outerLabelBoxShadow": "value"
                        }
                    },
                    "componentClassName": "wixui.ToggleSwitch",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.ToggleSwitch"
                },
                "activeModes": {},
                "id": "comp-jeqv428a"
            },
            "preset": {"rect": {"width": 81, "height": 69, "x": 162, "y": 5}, "tags": null}
        }, {
            "id": "User_Input_ToggleSwitch_4",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.ToggleSwitch",
                "layout": {
                    "width": 48,
                    "height": 24,
                    "x": 267,
                    "y": 30,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.ToggleSwitch",
                "data": {
                    "type": "ToggleSwitch",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "value": "",
                    "checked": true
                },
                "props": {
                    "type": "ToggleSwitchProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "isDisabled": false,
                    "displayKnobIcons": true,
                    "trackHeight": 100,
                    "knobSize": 100,
                    "alignment": "left"
                },
                "mobileHints": {
                    "type": "MobileHints",
                    "author": "duplicate",
                    "originalCompId": "comp-jdd3626j",
                    "recommendedScale": 1,
                    "recommendedWidth": 48,
                    "recommendedHeight": 24
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-backgroundColorFocus": "0",
                            "backgroundColor": "#FFFFFF",
                            "backgroundColorChecked": "#FFFFFF",
                            "backgroundColorDisabled": "#FFFFFF",
                            "backgroundColorFocus": "#E6FFEE",
                            "backgroundColorHover": "#FFDFD1",
                            "backgroundColorHoverChecked": "#E6FFEE",
                            "borderRadius": "2px 2px 2px 2px",
                            "boxShadowToggleOn-innerLabelBoxShadow": "false",
                            "boxShadowToggleOn-outerLabelBoxShadow": "false",
                            "color": "#FFFFFF",
                            "colorChecked": "#FFFFFF",
                            "colorCheckedDisabled": "#FFFFFF",
                            "colorHover": "#FFDFD1",
                            "colorHoverChecked": "#E6FFEE",
                            "innerLabelBackgroundColor": "#FF5454",
                            "innerLabelBackgroundColorChecked": "#4BDB7B",
                            "innerLabelBackgroundColorDisabled": "#E8E8E8",
                            "innerLabelBackgroundColorHover": "#FF5454",
                            "innerLabelBackgroundColorHoverChecked": "#4BDB7B",
                            "innerLabelBorderColor": "#FFFFFF",
                            "innerLabelBorderColorChecked": "#FFFFFF",
                            "innerLabelBorderColorDisabled": "#FFFFFF",
                            "innerLabelBorderColorHover": "#FF5454",
                            "innerLabelBorderColorHoverChecked": "#4BDB7B",
                            "innerLabelBorderSizeHover": "0",
                            "innerLabelBorderSizeHoverChecked": "0",
                            "innerLabelBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "outerLabelBorderColor": "#FF5454",
                            "outerLabelBorderColorChecked": "#4BDB7B",
                            "outerLabelBorderColorDisabled": "#E8E8E8",
                            "outerLabelBorderColorFocus": "#A8A8A8",
                            "outerLabelBorderColorHover": "#FF5454",
                            "outerLabelBorderColorHoverChecked": "#4BDB7B",
                            "outerLabelBorderSize": "2",
                            "outerLabelBorderSizeChecked": "2",
                            "outerLabelBorderSizeDisabled": "2",
                            "outerLabelBorderSizeFocus": "2",
                            "outerLabelBorderSizeHover": "2",
                            "outerLabelBorderSizeHoverChecked": "2",
                            "outerLabelBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-backgroundColorFocus": "value",
                            "backgroundColor": "value",
                            "backgroundColorChecked": "value",
                            "backgroundColorDisabled": "value",
                            "backgroundColorFocus": "value",
                            "backgroundColorHover": "value",
                            "backgroundColorHoverChecked": "value",
                            "borderRadius": "value",
                            "color": "value",
                            "colorChecked": "value",
                            "colorCheckedDisabled": "value",
                            "colorHover": "value",
                            "colorHoverChecked": "value",
                            "innerLabelBackgroundColor": "value",
                            "innerLabelBackgroundColorChecked": "value",
                            "innerLabelBackgroundColorDisabled": "value",
                            "innerLabelBackgroundColorHover": "value",
                            "innerLabelBackgroundColorHoverChecked": "value",
                            "innerLabelBorderColor": "value",
                            "innerLabelBorderColorChecked": "value",
                            "innerLabelBorderColorDisabled": "value",
                            "innerLabelBorderColorHover": "value",
                            "innerLabelBorderColorHoverChecked": "value",
                            "innerLabelBorderSizeHover": "value",
                            "innerLabelBorderSizeHoverChecked": "value",
                            "outerLabelBorderColor": "value",
                            "outerLabelBorderColorChecked": "value",
                            "outerLabelBorderColorDisabled": "value",
                            "outerLabelBorderColorFocus": "value",
                            "outerLabelBorderColorHover": "value",
                            "outerLabelBorderColorHoverChecked": "value",
                            "outerLabelBorderSize": "value",
                            "outerLabelBorderSizeChecked": "value",
                            "outerLabelBorderSizeDisabled": "value",
                            "outerLabelBorderSizeFocus": "value",
                            "outerLabelBorderSizeHover": "value",
                            "outerLabelBorderSizeHoverChecked": "value"
                        }
                    },
                    "componentClassName": "wixui.ToggleSwitch",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.ToggleSwitch"
                },
                "activeModes": {},
                "id": "comp-jeqv429j"
            },
            "preset": {"rect": {"width": 81, "height": 69, "x": 243, "y": 5}, "tags": null}
        }, {
            "id": "User_Input_ToggleSwitch_5",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.ToggleSwitch",
                "layout": {
                    "width": 48,
                    "height": 24,
                    "x": 10,
                    "y": 94,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.ToggleSwitch",
                "data": {
                    "type": "ToggleSwitch",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "value": "",
                    "checked": true
                },
                "props": {
                    "type": "ToggleSwitchProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "isDisabled": false,
                    "displayKnobIcons": false,
                    "trackHeight": 100,
                    "knobSize": 66,
                    "alignment": "left"
                },
                "mobileHints": {
                    "type": "MobileHints",
                    "author": "duplicate",
                    "originalCompId": "comp-jdd3626t",
                    "recommendedScale": 1,
                    "recommendedWidth": 48,
                    "recommendedHeight": 24
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "backgroundColor": "#FFFFFF",
                            "backgroundColorChecked": "#FFFFFF",
                            "backgroundColorDisabled": "#FFFFFF",
                            "backgroundColorFocus": "#FFFFFF",
                            "backgroundColorHover": "#FFFFFF",
                            "backgroundColorHoverChecked": "#FFFFFF",
                            "borderRadius": "999px 999px 999px 999px",
                            "boxShadowToggleOn-innerLabelBoxShadow": "true",
                            "boxShadowToggleOn-outerLabelBoxShadow": "false",
                            "color": "#545454",
                            "colorChecked": "#545454",
                            "colorCheckedDisabled": "#DBDBDB",
                            "colorDisabled": "#DBDBDB",
                            "colorHover": "#000000",
                            "colorHoverChecked": "#000000",
                            "innerLabelBackgroundColor": "#FFFFFF",
                            "innerLabelBackgroundColorChecked": "#FFFFFF",
                            "innerLabelBackgroundColorDisabled": "#FFFFFF",
                            "innerLabelBackgroundColorHover": "#FFFFFF",
                            "innerLabelBackgroundColorHoverChecked": "#FFFFFF",
                            "innerLabelBorderColor": "#FFFFFF",
                            "innerLabelBorderColorChecked": "#FFFFFF",
                            "innerLabelBorderColorDisabled": "#FFFFFF",
                            "innerLabelBorderColorHover": "#FFFFFF",
                            "innerLabelBorderColorHoverChecked": "#FFFFFF",
                            "innerLabelBorderSizeHover": "2",
                            "innerLabelBorderSizeHoverChecked": "2",
                            "innerLabelBoxShadow": "0.00px 3.00px 8px 2px rgba(0,0,0,0.2)",
                            "outerLabelBorderColor": "#545454",
                            "outerLabelBorderColorChecked": "#545454",
                            "outerLabelBorderColorDisabled": "#DBDBDB",
                            "outerLabelBorderColorFocus": "#000000",
                            "outerLabelBorderColorHover": "#000000",
                            "outerLabelBorderColorHoverChecked": "#000000",
                            "outerLabelBorderSize": "9",
                            "outerLabelBorderSizeChecked": "9",
                            "outerLabelBorderSizeDisabled": "9",
                            "outerLabelBorderSizeFocus": "9",
                            "outerLabelBorderSizeHover": "9",
                            "outerLabelBorderSizeHoverChecked": "9",
                            "outerLabelBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "backgroundColor": "value",
                            "backgroundColorChecked": "value",
                            "backgroundColorDisabled": "value",
                            "backgroundColorFocus": "value",
                            "backgroundColorHover": "value",
                            "backgroundColorHoverChecked": "value",
                            "borderRadius": "value",
                            "color": "value",
                            "colorChecked": "value",
                            "colorCheckedDisabled": "value",
                            "colorDisabled": "value",
                            "colorHover": "value",
                            "colorHoverChecked": "value",
                            "innerLabelBackgroundColor": "value",
                            "innerLabelBackgroundColorChecked": "value",
                            "innerLabelBackgroundColorDisabled": "value",
                            "innerLabelBackgroundColorHover": "value",
                            "innerLabelBackgroundColorHoverChecked": "value",
                            "innerLabelBorderColor": "value",
                            "innerLabelBorderColorChecked": "value",
                            "innerLabelBorderColorDisabled": "value",
                            "innerLabelBorderColorHover": "value",
                            "innerLabelBorderColorHoverChecked": "value",
                            "innerLabelBorderSizeHover": "value",
                            "innerLabelBorderSizeHoverChecked": "value",
                            "innerLabelBoxShadow": "value",
                            "outerLabelBorderColor": "value",
                            "outerLabelBorderColorChecked": "value",
                            "outerLabelBorderColorDisabled": "value",
                            "outerLabelBorderColorFocus": "value",
                            "outerLabelBorderColorHover": "value",
                            "outerLabelBorderColorHoverChecked": "value",
                            "outerLabelBorderSize": "value",
                            "outerLabelBorderSizeChecked": "value",
                            "outerLabelBorderSizeDisabled": "value",
                            "outerLabelBorderSizeFocus": "value",
                            "outerLabelBorderSizeHover": "value",
                            "outerLabelBorderSizeHoverChecked": "value"
                        }
                    },
                    "componentClassName": "wixui.ToggleSwitch",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.ToggleSwitch"
                },
                "activeModes": {},
                "id": "comp-jeqv428o"
            },
            "preset": {"rect": {"width": 81, "height": 79, "x": 0, "y": 74}, "tags": null}
        }, {
            "id": "User_Input_ToggleSwitch_6",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.ToggleSwitch",
                "layout": {
                    "width": 48,
                    "height": 24,
                    "x": 95,
                    "y": 94,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.ToggleSwitch",
                "data": {
                    "type": "ToggleSwitch",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "value": "",
                    "checked": true
                },
                "props": {
                    "type": "ToggleSwitchProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "isDisabled": false,
                    "displayKnobIcons": false,
                    "trackHeight": 100,
                    "knobSize": 64,
                    "alignment": "left"
                },
                "mobileHints": {
                    "type": "MobileHints",
                    "author": "duplicate",
                    "originalCompId": "comp-jdn6qegy",
                    "recommendedScale": 1,
                    "recommendedWidth": 48,
                    "recommendedHeight": 24
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-backgroundColorChecked": "1",
                            "alpha-backgroundColorFocus": "1",
                            "alpha-colorDisabled": "1",
                            "alpha-innerLabelBackgroundColorChecked": "1",
                            "backgroundColor": "#F2746B",
                            "backgroundColorChecked": "#FFA78C",
                            "backgroundColorDisabled": "#D9D9D9",
                            "backgroundColorFocus": "#FFA78C",
                            "backgroundColorHover": "#E64C4C",
                            "backgroundColorHoverChecked": "#FFD0A6",
                            "borderRadius": "999px 999px 999px 999px",
                            "boxShadowToggleOn-innerLabelBoxShadow": "false",
                            "boxShadowToggleOn-outerLabelBoxShadow": "false",
                            "color": "#FFFFFF",
                            "colorChecked": "#FFFFFF",
                            "colorCheckedDisabled": "#D9D9D9",
                            "colorDisabled": "#D9D9D9",
                            "colorHover": "#FFFFFF",
                            "colorHoverChecked": "#FFFFFF",
                            "innerLabelBackgroundColor": "#FFFFFF",
                            "innerLabelBackgroundColorChecked": "#FFFFFF",
                            "innerLabelBackgroundColorDisabled": "#FFFFFF",
                            "innerLabelBackgroundColorHover": "#FFFFFF",
                            "innerLabelBackgroundColorHoverChecked": "#FFFFFF",
                            "innerLabelBorderColor": "#FFFFFF",
                            "innerLabelBorderColorChecked": "#FFFFFF",
                            "innerLabelBorderColorDisabled": "#D9D9D9",
                            "innerLabelBorderColorHover": "#FFFFFF",
                            "innerLabelBorderColorHoverChecked": "#FFFFFF",
                            "innerLabelBorderSize": "2",
                            "innerLabelBorderSizeChecked": "2",
                            "innerLabelBorderSizeDisabled": "2",
                            "innerLabelBorderSizeHover": "2",
                            "innerLabelBorderSizeHoverChecked": "2",
                            "innerLabelBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "outerLabelBorderColor": "#FFFFFF",
                            "outerLabelBorderColorChecked": "#FFFFFF",
                            "outerLabelBorderColorDisabled": "#D9D9D9",
                            "outerLabelBorderColorFocus": "#828282",
                            "outerLabelBorderColorHover": "#414141",
                            "outerLabelBorderColorHoverChecked": "#FFD0A6",
                            "outerLabelBorderSize": "0",
                            "outerLabelBorderSizeChecked": "0",
                            "outerLabelBorderSizeDisabled": "2",
                            "outerLabelBorderSizeFocus": "1",
                            "outerLabelBorderSizeHover": "0",
                            "outerLabelBorderSizeHoverChecked": "0",
                            "outerLabelBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-backgroundColorChecked": "value",
                            "alpha-backgroundColorFocus": "value",
                            "alpha-colorDisabled": "value",
                            "alpha-innerLabelBackgroundColorChecked": "value",
                            "backgroundColor": "value",
                            "backgroundColorChecked": "value",
                            "backgroundColorDisabled": "value",
                            "backgroundColorFocus": "value",
                            "backgroundColorHover": "value",
                            "backgroundColorHoverChecked": "value",
                            "borderRadius": "value",
                            "color": "value",
                            "colorChecked": "value",
                            "colorCheckedDisabled": "value",
                            "colorDisabled": "value",
                            "colorHover": "value",
                            "colorHoverChecked": "value",
                            "innerLabelBackgroundColor": "value",
                            "innerLabelBackgroundColorChecked": "value",
                            "innerLabelBackgroundColorDisabled": "value",
                            "innerLabelBackgroundColorHover": "value",
                            "innerLabelBackgroundColorHoverChecked": "value",
                            "innerLabelBorderColor": "value",
                            "innerLabelBorderColorChecked": "value",
                            "innerLabelBorderColorDisabled": "value",
                            "innerLabelBorderColorHover": "value",
                            "innerLabelBorderColorHoverChecked": "value",
                            "innerLabelBorderSize": "value",
                            "innerLabelBorderSizeChecked": "value",
                            "innerLabelBorderSizeDisabled": "value",
                            "innerLabelBorderSizeHover": "value",
                            "innerLabelBorderSizeHoverChecked": "value",
                            "innerLabelBoxShadow": "value",
                            "outerLabelBorderColor": "value",
                            "outerLabelBorderColorChecked": "value",
                            "outerLabelBorderColorDisabled": "value",
                            "outerLabelBorderColorFocus": "value",
                            "outerLabelBorderColorHover": "value",
                            "outerLabelBorderColorHoverChecked": "value",
                            "outerLabelBorderSize": "value",
                            "outerLabelBorderSizeChecked": "value",
                            "outerLabelBorderSizeDisabled": "value",
                            "outerLabelBorderSizeFocus": "value",
                            "outerLabelBorderSizeHover": "value",
                            "outerLabelBorderSizeHoverChecked": "value",
                            "outerLabelBoxShadow": "value"
                        }
                    },
                    "componentClassName": "wixui.ToggleSwitch",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.ToggleSwitch"
                },
                "activeModes": {},
                "id": "comp-jeqv428u"
            },
            "preset": {"rect": {"width": 81, "height": 79, "x": 81, "y": 74}, "tags": null}
        }, {
            "id": "User_Input_ToggleSwitch_7",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.ToggleSwitch",
                "layout": {
                    "width": 48,
                    "height": 24,
                    "x": 180,
                    "y": 94,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.ToggleSwitch",
                "data": {
                    "type": "ToggleSwitch",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "value": "",
                    "checked": true
                },
                "props": {
                    "type": "ToggleSwitchProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "isDisabled": false,
                    "displayKnobIcons": false,
                    "trackHeight": 100,
                    "knobSize": 75,
                    "alignment": "left"
                },
                "mobileHints": {
                    "type": "MobileHints",
                    "author": "duplicate",
                    "originalCompId": "comp-jdd3625j",
                    "recommendedScale": 1,
                    "recommendedWidth": 48,
                    "recommendedHeight": 24
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "backgroundColor": "#FFFFFF",
                            "backgroundColorChecked": "#FFFFFF",
                            "backgroundColorDisabled": "#FFFFFF",
                            "backgroundColorFocus": "#FFFFFF",
                            "backgroundColorHover": "#FFFFFF",
                            "backgroundColorHoverChecked": "#FFFFFF",
                            "borderRadius": "4px 4px 4px 4px",
                            "boxShadowToggleOn-innerLabelBoxShadow": "false",
                            "boxShadowToggleOn-outerLabelBoxShadow": "true",
                            "color": "#FFFFFF",
                            "colorChecked": "#FFFFFF",
                            "colorCheckedDisabled": "#FFFFFF",
                            "colorHover": "#FFFFFF",
                            "colorHoverChecked": "#FFFFFF",
                            "innerLabelBackgroundColor": "#9F9F9F",
                            "innerLabelBackgroundColorChecked": "#000000",
                            "innerLabelBackgroundColorDisabled": "#E8E8E8",
                            "innerLabelBackgroundColorHover": "#616161",
                            "innerLabelBackgroundColorHoverChecked": "#545454",
                            "innerLabelBorderColor": "#9F9F9F",
                            "innerLabelBorderColorChecked": "#000000",
                            "innerLabelBorderColorDisabled": "#F5F5F5",
                            "innerLabelBorderColorHover": "#9F9F9F",
                            "innerLabelBorderColorHoverChecked": "#000000",
                            "innerLabelBorderSizeHoverChecked": "0",
                            "innerLabelBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "outerLabelBorderColor": "#F5F5F5",
                            "outerLabelBorderColorChecked": "#F5F5F5",
                            "outerLabelBorderColorDisabled": "#F5F5F5",
                            "outerLabelBorderColorFocus": "#CCCCCC",
                            "outerLabelBorderColorHover": "#F5F5F5",
                            "outerLabelBorderColorHoverChecked": "#F5F5F5",
                            "outerLabelBorderSize": "1",
                            "outerLabelBorderSizeChecked": "1",
                            "outerLabelBorderSizeDisabled": "1",
                            "outerLabelBorderSizeFocus": "1",
                            "outerLabelBorderSizeHover": "1",
                            "outerLabelBorderSizeHoverChecked": "1",
                            "outerLabelBoxShadow": "2.12px 2.12px 9px 1px rgba(0,0,0,0.1)"
                        },
                        "propertiesSource": {
                            "backgroundColor": "value",
                            "backgroundColorChecked": "value",
                            "backgroundColorDisabled": "value",
                            "backgroundColorFocus": "value",
                            "backgroundColorHover": "value",
                            "backgroundColorHoverChecked": "value",
                            "borderRadius": "value",
                            "color": "value",
                            "colorChecked": "value",
                            "colorCheckedDisabled": "value",
                            "colorHover": "value",
                            "colorHoverChecked": "value",
                            "innerLabelBackgroundColor": "value",
                            "innerLabelBackgroundColorChecked": "value",
                            "innerLabelBackgroundColorDisabled": "value",
                            "innerLabelBackgroundColorHover": "value",
                            "innerLabelBackgroundColorHoverChecked": "value",
                            "innerLabelBorderColor": "value",
                            "innerLabelBorderColorChecked": "value",
                            "innerLabelBorderColorDisabled": "value",
                            "innerLabelBorderColorHover": "value",
                            "innerLabelBorderColorHoverChecked": "value",
                            "innerLabelBorderSizeHoverChecked": "value",
                            "outerLabelBorderColor": "value",
                            "outerLabelBorderColorChecked": "value",
                            "outerLabelBorderColorDisabled": "value",
                            "outerLabelBorderColorFocus": "value",
                            "outerLabelBorderColorHover": "value",
                            "outerLabelBorderColorHoverChecked": "value",
                            "outerLabelBorderSize": "value",
                            "outerLabelBorderSizeChecked": "value",
                            "outerLabelBorderSizeDisabled": "value",
                            "outerLabelBorderSizeFocus": "value",
                            "outerLabelBorderSizeHover": "value",
                            "outerLabelBorderSizeHoverChecked": "value",
                            "outerLabelBoxShadow": "value"
                        }
                    },
                    "componentClassName": "wixui.ToggleSwitch",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.ToggleSwitch"
                },
                "activeModes": {},
                "id": "comp-jeqv429c"
            },
            "preset": {"rect": {"width": 81, "height": 79, "x": 162, "y": 74}, "tags": null}
        }, {
            "id": "User_Input_ToggleSwitch_8",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.ToggleSwitch",
                "layout": {
                    "width": 48,
                    "height": 24,
                    "x": 266,
                    "y": 94,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.ToggleSwitch",
                "data": {
                    "type": "ToggleSwitch",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "value": "",
                    "checked": true
                },
                "props": {
                    "type": "ToggleSwitchProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "isDisabled": false,
                    "displayKnobIcons": true,
                    "trackHeight": 33,
                    "knobSize": 100,
                    "alignment": "left"
                },
                "mobileHints": {
                    "type": "MobileHints",
                    "author": "duplicate",
                    "originalCompId": "comp-jdd3626a",
                    "recommendedScale": 1,
                    "recommendedWidth": 48,
                    "recommendedHeight": 24
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "backgroundColor": "#49D6D6",
                            "backgroundColorChecked": "#49D6D6",
                            "backgroundColorDisabled": "#E0E0E0",
                            "backgroundColorFocus": "#49D6D6",
                            "backgroundColorHover": "#49D6D6",
                            "backgroundColorHoverChecked": "#49D6D6",
                            "borderRadius": "999px 999px 999px 999px",
                            "boxShadowToggleOn-innerLabelBoxShadow": "true",
                            "boxShadowToggleOn-outerLabelBoxShadow": "false",
                            "color": "#FFFFFF",
                            "colorChecked": "#FFFFFF",
                            "colorCheckedDisabled": "#49D6D6",
                            "colorDisabled": "#FFFFFF",
                            "colorHover": "#FFFFFF",
                            "colorHoverChecked": "#FFFFFF",
                            "innerLabelBackgroundColor": "#49D6D6",
                            "innerLabelBackgroundColorChecked": "#49D6D6",
                            "innerLabelBackgroundColorDisabled": "#E0E0E0",
                            "innerLabelBackgroundColorHover": "#49D6D6",
                            "innerLabelBackgroundColorHoverChecked": "#49D6D6",
                            "innerLabelBorderColor": "#FFFFFF",
                            "innerLabelBorderColorChecked": "#FFFFFF",
                            "innerLabelBorderColorDisabled": "#E0E0E0",
                            "innerLabelBorderColorHover": "#49D6D6",
                            "innerLabelBorderColorHoverChecked": "#49D6D6",
                            "innerLabelBorderSize": "3",
                            "innerLabelBorderSizeChecked": "3",
                            "innerLabelBorderSizeDisabled": "3",
                            "innerLabelBorderSizeHover": "3",
                            "innerLabelBorderSizeHoverChecked": "3",
                            "innerLabelBoxShadow": "0.00px 0.00px 0px 2px rgba(73,214,214,1)",
                            "outerLabelBorderColor": "#FFFFFF",
                            "outerLabelBorderColorChecked": "#FFFFFF",
                            "outerLabelBorderColorDisabled": "#FFFFFF",
                            "outerLabelBorderColorFocus": "#FFFFFF",
                            "outerLabelBorderColorHover": "#FFFFFF",
                            "outerLabelBorderColorHoverChecked": "#FFFFFF",
                            "outerLabelBorderSize": "2",
                            "outerLabelBorderSizeChecked": "2",
                            "outerLabelBorderSizeDisabled": "2",
                            "outerLabelBorderSizeFocus": "1",
                            "outerLabelBorderSizeHover": "2",
                            "outerLabelBorderSizeHoverChecked": "2",
                            "outerLabelBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "backgroundColor": "value",
                            "backgroundColorChecked": "value",
                            "backgroundColorDisabled": "value",
                            "backgroundColorFocus": "value",
                            "backgroundColorHover": "value",
                            "backgroundColorHoverChecked": "value",
                            "borderRadius": "value",
                            "color": "value",
                            "colorChecked": "value",
                            "colorCheckedDisabled": "value",
                            "colorDisabled": "value",
                            "colorHover": "value",
                            "colorHoverChecked": "value",
                            "innerLabelBackgroundColor": "value",
                            "innerLabelBackgroundColorChecked": "value",
                            "innerLabelBackgroundColorDisabled": "value",
                            "innerLabelBackgroundColorHover": "value",
                            "innerLabelBackgroundColorHoverChecked": "value",
                            "innerLabelBorderColor": "value",
                            "innerLabelBorderColorChecked": "value",
                            "innerLabelBorderColorDisabled": "value",
                            "innerLabelBorderColorHover": "value",
                            "innerLabelBorderColorHoverChecked": "value",
                            "innerLabelBorderSize": "value",
                            "innerLabelBorderSizeChecked": "value",
                            "innerLabelBorderSizeDisabled": "value",
                            "innerLabelBorderSizeHover": "value",
                            "innerLabelBorderSizeHoverChecked": "value",
                            "innerLabelBoxShadow": "value",
                            "outerLabelBorderColor": "value",
                            "outerLabelBorderColorChecked": "value",
                            "outerLabelBorderColorDisabled": "value",
                            "outerLabelBorderColorFocus": "value",
                            "outerLabelBorderColorHover": "value",
                            "outerLabelBorderColorHoverChecked": "value",
                            "outerLabelBorderSize": "value",
                            "outerLabelBorderSizeChecked": "value",
                            "outerLabelBorderSizeDisabled": "value",
                            "outerLabelBorderSizeFocus": "value",
                            "outerLabelBorderSizeHover": "value",
                            "outerLabelBorderSizeHoverChecked": "value"
                        }
                    },
                    "componentClassName": "wixui.ToggleSwitch",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.ToggleSwitch"
                },
                "activeModes": {},
                "id": "comp-jeqv428g"
            },
            "preset": {"rect": {"width": 81, "height": 79, "x": 243, "y": 74}, "tags": null}
        }],
        "compTypes": ["wixui.ToggleSwitch"]
    },
    "help": {"hide": false, "text": "add_section_label_toggle_switch_tooltip_description"}
}
