import type { GroupPermissions } from '@wix/platform-editor-sdk';
import type { SDKFunction } from '../../../index';

const isGroupsPermissionsGranted: SDKFunction<
  { permissions: GroupPermissions[] },
  boolean
> = (editorAPI, appData, token, options) =>
  editorAPI.platform.permissions.isGroupsPermissionsGranted(
    options.permissions,
  );

const isCustomPermissionsGranted: SDKFunction<
  { permissions: string[] },
  boolean
> = (editorAPI, appData, token, options) =>
  editorAPI.platform.permissions.isCustomPermissionsGranted(
    options.permissions,
  );

export default {
  isGroupsPermissionsGranted,
  isCustomPermissionsGranted,
};
