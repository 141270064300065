import dataProvider from '../dataProvider';
import type { EditorAPI } from '@/editorAPI';
import { getCreateServiceRequest } from '../dynamic-content/create-service-request.builder';
import type {
  BookingsServiceSkeleton,
  BookingsServiceCategory,
} from './bookings-types';
import * as util from '@/util';

async function postRequest(
  editorAPI: EditorAPI,
  url: string,
  requestBody: any,
) {
  const instance = dataProvider.getInstance(editorAPI);
  const response = await util.http.fetch(url, {
    method: 'POST',
    headers: {
      'x-wix-scheduler-instance': instance,
      Authorization: instance,
    },
    body: JSON.stringify(requestBody),
  });
  return response.json();
}

async function getRequest(editorAPI: EditorAPI, url: string) {
  const instance = dataProvider.getInstance(editorAPI);
  const response = await util.http.fetch(url, {
    headers: {
      'x-wix-scheduler-instance': instance,
      Authorization: instance,
    },
  });
  return response.json();
}

export async function createServices(
  editorAPI: EditorAPI,
  services: BookingsServiceSkeleton[],
) {
  const requests = services.map((service) => getCreateServiceRequest(service));
  return postRequest(editorAPI, '/_api/bookings/v1/services/batch', {
    requests,
  });
}

export async function createCategory(
  editorAPI: EditorAPI,
  categoryName: string,
): Promise<BookingsServiceCategory> {
  const createCategoryRequest = {
    category: {
      name: categoryName,
    },
  };
  const createCategoryResponse = await postRequest(
    editorAPI,
    '/_api/bookings/v1/categories',
    createCategoryRequest,
  );
  return createCategoryResponse.category;
}

async function wait(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

// when fetching server data on provisioning, sometimes bits of it are missing.
// this function helps retrying the request until the server initialized for the new site
export async function insist(fetcher: () => Promise<any>) {
  const numOfTries = 5;
  for (let i = 0; i < numOfTries; i++) {
    const response = await fetcher();
    if (response) {
      return response;
    }
    await wait(1000);
  }
}

export async function getDefaultStaff(editorAPI: EditorAPI) {
  const getResourcesResponse = await getRequest(
    editorAPI,
    '/_api/bookings/v1/resources',
  );
  const { resources } = getResourcesResponse;
  return resources.find((resource: AnyFixMe) =>
    resource.tags.includes('defaultStaff'),
  );
}
