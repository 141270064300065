// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import _ from 'lodash';
import quickActionRenameMixin from '../quickActionRenameMixin';
import React from 'react';
import NodeContentCommon from '../nodeContentCommon';
import * as pageMenuItemHelpers from '../../utils/pageMenuItemHelpers';
import * as quickActionsUtils from '../../utils/quickActionsUtils';
import { translate } from '@/i18n';
import * as baseUI from '@/baseUI';
import * as symbols from '@wix/santa-editor-symbols';

function nodeContentWithQuickAction() {
  const item = this.props.dataSource;
  const itemId = item.pageData ? item.pageData.id : item.id;
  const editorAPI = this.getEditorAPI();

  return (
    <NodeContentCommon {...this.getNodeContentProps()}>
      <baseUI.dropDown.contextMenu
        toggleIcon={false}
        shouldTranslate={false}
        onChange={this.actionSelectedHandler}
        onNotificationClick={
          quickActionsUtils.hasNotification(editorAPI, itemId)
            ? this.props.onNotificationClick
            : undefined
        }
        notificationCount={1}
        icon="moreRegular"
        className="quick-actions action-button"
      >
        <baseUI.dropDown.option value="settings">
          <div className="svg-container">
            <symbols.symbol name="quickActionsSettings" />
          </div>
          <span className="qa-label">
            {translate('Pages_Actions_Page_Settings')}
          </span>
        </baseUI.dropDown.option>

        <baseUI.dropDown.option value="rename">
          <div className="svg-container">
            <symbols.symbol name="quickActionsRename" />
          </div>
          <span className="qa-label">
            {translate('Pages_Actions_Page_Rename')}
          </span>
        </baseUI.dropDown.option>

        {pageMenuItemHelpers.canUnhide(this.getEditorAPI(), item) ? (
          <baseUI.dropDown.option key="unhideOption" value="unhide">
            <div className="svg-container">
              <symbols.symbol name="quickActionsShow" />
            </div>
            <span className="qa-label">
              {translate('Pages_Actions_Page_Unhide')}
            </span>
          </baseUI.dropDown.option>
        ) : null}

        {pageMenuItemHelpers.canHide(this.getEditorAPI(), item) ? (
          <baseUI.dropDown.option key="hideOption" value="hide">
            <div className="svg-container">
              <symbols.symbol name="quickActionsHide" />
            </div>
            <span className="qa-label">
              {translate('Pages_Actions_Page_Hide')}
            </span>
          </baseUI.dropDown.option>
        ) : null}

        {pageMenuItemHelpers.canRemoveMenuItem(this.getEditorAPI(), item) ? (
          <baseUI.dropDown.option key="removeOption" value="remove">
            <div className="svg-container">
              <symbols.symbol name="qaDelete" />
            </div>
            <span className="qa-label">
              {translate('Pages_Actions_Page_Delete')}
            </span>
          </baseUI.dropDown.option>
        ) : null}
      </baseUI.dropDown.contextMenu>
    </NodeContentCommon>
  );
}

function nodeContentWithSettingsLink() {
  return (
    <NodeContentCommon {...this.getNodeContentProps()}>
      <a
        href="javascript://settings" //eslint-disable-line no-script-url
        onClick={() => {
          this.props.onActionSelected('settings', this.props.dataSource);
        }}
        className="action-button action-settings"
      >
        <symbols.symbol name="quickActionsSettings" />
      </a>
    </NodeContentCommon>
  );
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'nodeContentUniversalRenderer',

  mixins: [core.mixins.editorAPIMixin, quickActionRenameMixin],

  propTypes: {
    isBelongToSubmenu: PropTypes.bool.isRequired,
    dataSource: PropTypes.object.isRequired,
    onActionSelected: PropTypes.func.isRequired,
    editedPageIdLink: PropTypes.object,
    setSelected: PropTypes.func,
    isSelected: PropTypes.bool,
  },

  getRenderFn() {
    if (this.getEditorAPI().isMobileEditor()) {
      return nodeContentWithSettingsLink;
    }

    if (this.props.isBelongToSubmenu) {
      return nodeContentWithSettingsLink;
    }

    return nodeContentWithQuickAction;
  },

  getNodeContentProps() {
    const editorAPI = this.getEditorAPI();
    let isVisible;

    if (this.props.isBelongToSubmenu) {
      isVisible = true;
    } else {
      isVisible = editorAPI.isMobileEditor()
        ? this.props.dataSource.isVisibleMobile
        : this.props.dataSource.isVisible;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign({}, this.props, {
      isVisible,
      editorAPI,
    });
  },

  render() {
    return this.getRenderFn().call(this);
  },
});
