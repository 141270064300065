import { BasePublicApi } from '@/apilib';
import { WorkspaceRightPanelApiKey } from '@/apis';
import { tooltipManager } from '@/baseUI';
import constants from '@/constants';
import { events } from '@/coreBi';
import experiment from 'experiment';
import {
  array,
  fedopsLogger,
  fixedStage,
  keyboardShortcuts,
  sections,
  uiUtils,
  zoomMode,
} from '@/util';
import {
  domMeasurements,
  interactions,
  leftBar,
  panels,
} from '@/stateManagement';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { DEFAULT_SITE_SCALE, NO_ZOOM_SCALE } from './constants';
import { setShouldOpenInZoomFromLocalStorage } from './utils';
import { ZoomDirection } from './store';

import type { Scope } from './scope';
import type { CSSProperties } from 'react';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

const { exitInteractionModeIfNeeded } = interactions.actions;
const { shouldPanelShrinkStage } = leftBar.selectors;
const { closePanelByName } = panels.actions;
const { selectOpenLeftPanels } = panels.selectors;

const {
  ADD_SECTION_PANEL_NAME,
  ADD_SECTION_PANEL_WIDTH,
  DESIGN_PANEL_NAME,
  DESIGN_PANEL_WIDTH,
  MENUS_AND_PAGES_PANEL_NAME,
  MENU_AND_PAGES_PANEL_WIDTH,
} = constants.ROOT_COMPS.LEFTBAR;

const mapPanelToWidth = {
  [ADD_SECTION_PANEL_NAME]: ADD_SECTION_PANEL_WIDTH,
  [DESIGN_PANEL_NAME]: DESIGN_PANEL_WIDTH,
  [MENUS_AND_PAGES_PANEL_NAME]: MENU_AND_PAGES_PANEL_WIDTH,
};

const getPanelsPartitionedByStageShrink = (editorAPI: Scope['editorAPI']) => {
  const { store, isPopUpMode } = editorAPI;
  const openedPanels = selectOpenLeftPanels(store.getState());
  return array.partition(openedPanels, (panel) =>
    shouldPanelShrinkStage(store.getState(), panel.name, isPopUpMode()),
  );
};

const enterZoomModeInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.ZOOM_MODE.ENTER,
);

const exitZoomModeInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.ZOOM_MODE.EXIT,
);

const calculateOriginalScrollY = (
  { editorAPI }: Scope,
  section: CompRef,
  siteScale: number,
): number => {
  const state = editorAPI.store.getState();
  const stageHeight = domMeasurements.selectors.getStageLayout(state).height;
  const siteHeight = editorAPI.site.getHeight();
  const sectionLayout =
    editorAPI.components.layout.getRelativeToStructure(section);
  const scaledSiteHeight = siteHeight * siteScale;
  const scaledSectionY = sectionLayout.y * siteScale;
  const scaledSectionHeight = sectionLayout.height * siteScale;
  const centerYSectionRelativeToStage =
    (stageHeight - scaledSectionHeight) * siteScale;

  const scrollYToCenterTheSection =
    scaledSectionY - centerYSectionRelativeToStage;
  if (scrollYToCenterTheSection > scaledSectionY) {
    return scaledSectionY;
  }

  const heightFormSectionYToSiteBottom = scaledSiteHeight - scaledSectionY;
  const doesNotHaveEnoughSpaceFromBottom =
    stageHeight - centerYSectionRelativeToStage >
    heightFormSectionYToSiteBottom;
  if (doesNotHaveEnoughSpaceFromBottom) {
    return scaledSiteHeight - stageHeight;
  }

  return scrollYToCenterTheSection;
};

interface ZoomModeBiParams {
  origin: string;
  is_section?: boolean;
  is_site_creation?: boolean;
  zoom_mode_type?: '75%' | '50%';
}

export interface ZoomModeConfig {
  biParams?: ZoomModeBiParams;
  originalScrollY?: number;
  clickedByUser?: boolean;
  animationDuration?: number;
  shouldCenterComponentSelection?: boolean;
  keepComponentsSelection?: boolean;
  enableCompPanels?: boolean;
  enableRightClickMenu?: boolean;
  disableViewerDocumentAdjustments?: boolean;
  zoomScale?: number;
  origin?: string;
  keyboardContext?: string;
}

const isInSameScale = ({ editorAPI }: Scope, zoomScale: number) => {
  return editorAPI.getSiteScale() === zoomScale;
};

const shouldToggleMeasureMapsOnEnterExit = () => {
  return (
    experiment.isOpen('se_disableMeasureMapsInZoom') &&
    sections.isSectionsEnabled()
  );
};

export async function enterZoomMode(
  scope: Scope,
  {
    biParams,
    clickedByUser,
    shouldCenterComponentSelection = true,
    keepComponentsSelection = true,
    enableCompPanels = false,
    enableRightClickMenu = true,
    disableViewerDocumentAdjustments = false,
    originalScrollY = 0,
    animationDuration,
    zoomScale = DEFAULT_SITE_SCALE,
    keyboardContext,
  }: ZoomModeConfig,
) {
  if (isZoomModeTransitionActive(scope)) {
    if (scope.store.getZoomTransitionDirection() === ZoomDirection.IN) {
      return;
    }
    await scope.store.getTransitionPromise();
  }
  if (isInZoomMode(scope) && isInSameScale(scope, zoomScale)) {
    return;
  }

  const { editorAPI, store } = scope;
  const { dispatch } = editorAPI.store;

  enterZoomModeInteraction.start();
  store.zoomTransitionStart();
  store.setZoomTransitionDirection(ZoomDirection.IN);
  ErrorReporter.breadcrumb('enter zoom mode', biParams);

  exitInteractionModeIfNeeded(editorAPI);

  if (clickedByUser) {
    store.setIsZoomedByUser(true);
  }

  if (sections.isSectionsEnabled()) {
    const [, panelsThatDontShrinkStage] =
      getPanelsPartitionedByStageShrink(editorAPI);

    panelsThatDontShrinkStage.forEach((panel) => {
      dispatch(closePanelByName(panel.name, biParams.origin));
    });
  } else {
    editorAPI.panelManager.closeAllPanels();
    editorAPI.toolsControl.toggleHideTools(true, false);
  }

  if (sections.isSectionsEnabled()) {
    if (!keepComponentsSelection) {
      editorAPI.selection.deselectComponents();
    }
  }

  const selectedSection = sections.isSectionsEnabled()
    ? editorAPI.sections.getSelectedSection()
    : undefined;

  const calculatedScrollY =
    shouldCenterComponentSelection && selectedSection
      ? calculateOriginalScrollY(scope, selectedSection, zoomScale)
      : originalScrollY * zoomScale;

  if (
    shouldToggleMeasureMapsOnEnterExit() ||
    !disableViewerDocumentAdjustments
  ) {
    editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(false);
  }
  const transitionPromise = createZoomTransitionPromise(scope);
  editorAPI.setSiteScale({
    requestedScale: zoomScale,
    scrollY: Math.max(calculatedScrollY, 0),
    animationDuration,
    onComplete: async () => {
      if (
        !shouldToggleMeasureMapsOnEnterExit() &&
        !disableViewerDocumentAdjustments
      ) {
        editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(true);
      }
      store.zoomTransitionEnd();
      store.setTransitionPromise(null);
      scope.zoomTransitionDeferred.resolve();
    },
  });

  editorAPI.dsActions.documentMode.allowShowingFixedComponents(false);
  tooltipManager.hide(constants.ROOT_COMPS.TOOLTIPS.FIRST_TIME_EXIT_POPUP_MODE);

  keyboardContext = keyboardContext ?? keyboardShortcuts.CONTEXTS.ZOOM_MODE;
  keyboardShortcuts.setContext(keyboardContext);

  store.setZoomModeEnterOrigin(biParams?.origin);

  store.setZoomModeEnabledCompPanels(enableCompPanels);
  store.setZoomModeEnabledRightClickMenu(enableRightClickMenu);

  ErrorReporter.setTags({
    isZoomMode: true,
  });

  const isNewZoomLabelEnabled =
    isShrinkedStageZoomOutActive(scope) && zoomMode.isNewZoomLabelEnabled();

  editorAPI.bi.event(events.editor.ZOOM_MODE_OPEN, {
    ...biParams,
    isZoomOut: false,
    zoom_mode_type: isNewZoomLabelEnabled ? '75%' : '50%',
  });

  return transitionPromise.then(() => {
    enterZoomModeInteraction.end();
  });
}

const isInCurrentModeScale = ({
  editorAPI,
  workspaceModesApi,
}: Scope): boolean => {
  return (
    editorAPI.getSiteScale() ===
    workspaceModesApi.getWorkspaceModeDefaultScale()
  );
};

export async function exitZoomMode(
  scope: Scope,
  {
    biParams,
    originalScrollY = 0,
    animationDuration,
    disableViewerDocumentAdjustments = false,
    shouldCenterComponentSelection = true,
    zoomScale,
    clickedByUser = false,
    keyboardContext,
  }: ZoomModeConfig,
) {
  if (isZoomModeTransitionActive(scope)) {
    if (scope.store.getZoomTransitionDirection() === ZoomDirection.OUT) {
      return;
    }
    await scope.store.getTransitionPromise();
  }
  keyboardContext = keyboardContext ?? keyboardShortcuts.CONTEXTS.EDITOR;
  if (!isInZoomMode(scope) || isInCurrentModeScale(scope)) {
    keyboardShortcuts.setContext(keyboardContext);
    return;
  }
  const prevZoomScale = scope.editorAPI.getSiteScale() || DEFAULT_SITE_SCALE;

  const { editorAPI, store, workspaceModesApi } = scope;
  const { dispatch } = editorAPI.store;

  exitZoomModeInteraction.start();
  store.setZoomTransitionDirection(ZoomDirection.OUT);
  store.zoomTransitionStart();
  ErrorReporter.breadcrumb('exit zoom mode', biParams);

  store.setIsZoomedByUser(clickedByUser);

  const workspaceRightPanelAPI = editorAPI.host.getAPI(
    WorkspaceRightPanelApiKey,
  );

  if (workspaceRightPanelAPI.isOpen()) {
    workspaceRightPanelAPI.close(biParams.origin);
  }

  if (
    !shouldToggleMeasureMapsOnEnterExit() ||
    !disableViewerDocumentAdjustments
  ) {
    editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(false);
  }
  const transitionPromise = createZoomTransitionPromise(scope);

  editorAPI.bi.event(events.editor.ZOOM_MODE_CLOSE, {
    origin: store.getZoomModeEnterOrigin(),
    ...biParams,
    isZoomOut: true,
    zoom_mode_type: biParams.zoom_mode_type || '50%',
  });

  const selectedSection = sections.isSectionsEnabled()
    ? editorAPI.sections.getFocusedSection()
    : undefined;

  zoomScale = zoomScale ?? workspaceModesApi.getWorkspaceModeDefaultScale();

  const calculatedScrollY =
    shouldCenterComponentSelection && selectedSection
      ? calculateOriginalScrollY(scope, selectedSection, zoomScale)
      : originalScrollY / prevZoomScale;

  editorAPI.setSiteScale({
    requestedScale: zoomScale,
    scrollY: Math.max(calculatedScrollY, 0),
    animationDuration,
    onComplete: async () => {
      editorAPI.dsActions.documentMode.allowShowingFixedComponents(true);
      if (shouldToggleMeasureMapsOnEnterExit()) {
        editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(true);
      }
      await editorAPI.waitForChangesAppliedAsync();
      editorAPI.documentMode.setExtraSiteHeight(
        editorAPI.editorConfig.extraSiteHeight,
      );
      await editorAPI.waitForChangesAppliedAsync();
      if (
        !shouldToggleMeasureMapsOnEnterExit() &&
        !disableViewerDocumentAdjustments
      ) {
        editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(true);
      }
      store.setTransitionPromise(null);
      store.zoomTransitionEnd();
      scope.zoomTransitionDeferred.resolve();
    },
  });

  editorAPI.toolsControl.toggleHideTools(false, true);
  keyboardShortcuts.setContext(keyboardContext);

  store.setZoomModeEnabledCompPanels(false);
  store.setZoomModeEnabledRightClickMenu(true);

  const [panelsThatShouldShrinkStage] =
    getPanelsPartitionedByStageShrink(editorAPI);
  panelsThatShouldShrinkStage.forEach((panel) => {
    dispatch(closePanelByName(panel.name, biParams.origin));
  });

  ErrorReporter.setTags({
    isZoomMode: false,
  });

  return transitionPromise.then(() => {
    exitZoomModeInteraction.end();
  });
}

async function exitZoomModeAndClearTransformationsIfNeeded(scope: Scope) {
  const { sectionsAPI } = scope;

  const hasTransformations = sectionsAPI.hasTransformationsOnCurrentPage();

  if (hasTransformations) {
    await sectionsAPI.clearSectionTransformationsOnCurrentPage();
    exitZoomMode(scope, { biParams: { origin: 'autoExitWhenSave' } });
  }
}

export function isInZoomMode(scope: Scope) {
  return scope.editorAPI.getSiteScale() !== NO_ZOOM_SCALE;
}

function isStageZoomMode(scope: Scope) {
  return isInZoomMode(scope) && sections.isSectionsEnabled();
}

export function isZoomModeTransitionActive(scope: Scope) {
  return scope.store.isZoomModeTransitionActive();
}

export function isZoomedByUser(scope: Scope) {
  return scope.store.isZoomedByUser();
}

function createZoomTransitionPromise(scope: Scope) {
  const promise = scope.zoomTransitionDeferred.createPromise();
  scope.store.setTransitionPromise(promise);
  return promise;
}

function getStageXOffset(scope: Scope) {
  const { editorAPI } = scope;
  const previewPosition = domMeasurements.selectors.getPreviewPosition(
    editorAPI.store.getState(),
  );
  const siteScale = editorAPI.getSiteScale();
  const isMobileEditor = editorAPI.isMobileEditor();
  const siteX = isMobileEditor ? editorAPI.site.getSiteX() : 0;
  const contentWidth = isMobileEditor
    ? editorAPI.site.getWidth()
    : previewPosition.width;
  return siteX + (contentWidth * (NO_ZOOM_SCALE - siteScale)) / 2;
}

const getDeltaXForStageInZoomMode = (editorAPI: EditorAPI) => {
  if (!fixedStage.isFixedStageEnabled()) {
    return 0;
  }
  const previewPositionWidth = editorAPI.site.getWidth();
  const { width: stageWidth } = domMeasurements.selectors.getStageLayout(
    editorAPI.store.getState(),
  );

  return Math.max(
    0,
    (previewPositionWidth - stageWidth + uiUtils.getScrollbarWidth()) / 2,
  );
};

const getFullPanelSizeShiftX = (scope: Scope) => {
  const { editorAPI } = scope;
  const workspaceRightPanelAPI = editorAPI.host.getAPI(
    WorkspaceRightPanelApiKey,
  );
  const [panelsThatShouldShrinkStage] =
    getPanelsPartitionedByStageShrink(editorAPI);

  if (workspaceRightPanelAPI.isOpen()) {
    return workspaceRightPanelAPI.getPanelWidth() * -1;
  }

  if (panelsThatShouldShrinkStage.length === 1) {
    const openedPanelName = panelsThatShouldShrinkStage[0].name;
    if (openedPanelName in mapPanelToWidth) {
      return mapPanelToWidth[openedPanelName as keyof typeof mapPanelToWidth];
    }
  }

  return 0;
};

function getXShiftForPushedStage(scope: Scope) {
  const xShift: number = 0;

  if (!isInZoomMode(scope)) {
    return xShift;
  }

  const { editorAPI } = scope;

  const openedPanelWidth = getFullPanelSizeShiftX(scope);

  if (!openedPanelWidth && !fixedStage.isFixedStageEnabled()) {
    return xShift;
  }

  const translateX = openedPanelWidth / 2;
  const deltaX = getDeltaXForStageInZoomMode(editorAPI);

  return translateX - deltaX;
}

function getStyleForPushedStageAndPreviewFrame(scope: Scope) {
  const xShift = getXShiftForPushedStage(scope);

  const style: CSSProperties = {
    transform: xShift ? `translate(${xShift}px, 0)` : '',
    // TODO: sync transition duration value with panels opening/closing
    transition: 'transform linear 400ms',
  };

  return style;
}

function isZoomModeEnabledCompPanels({ store }: Scope): boolean {
  return store.getZoomModeEnabledCompPanels();
}

function isZoomModeEnabledCompControls(scope: Scope): boolean {
  // TODO: will be added also isZoomModeEnabledSelection, and more if needed
  return isZoomModeEnabledCompPanels(scope);
}

function isZoomModeEnabledRightClickMenu({ store }: Scope): boolean {
  return store.getZoomModeEnabledRightClickMenu();
}

function isLeftShrinkedStageZoomOutActive(scope: Scope): boolean {
  const { editorAPI } = scope;
  const state = editorAPI.store.getState();

  const [leftPanel] = panels.selectors.selectOpenLeftPanels(state);
  return (
    isStageZoomMode(scope) &&
    leftBar.selectors.shouldPanelShrinkStage(
      state,
      leftPanel?.name,
      editorAPI.isPopUpMode(),
    )
  );
}

function isRightShrinkedStageZoomOutActive(scope: Scope): boolean {
  const { editorAPI } = scope;

  const workspaceRightPanelAPI = editorAPI.host.getAPI(
    WorkspaceRightPanelApiKey,
  );

  return isStageZoomMode(scope) && workspaceRightPanelAPI.isOpen();
}

function isShrinkedStageZoomOutActive(scope: Scope): boolean {
  return (
    isLeftShrinkedStageZoomOutActive(scope) ||
    isRightShrinkedStageZoomOutActive(scope)
  );
}

function getSiteScaleForPanelsOpen(scope: Scope) {
  const { editorAPI } = scope;
  const fixedEditingAreaWidth = editorAPI.dsRead.site.getWidth();
  const leftPanelWidth =
    constants.ROOT_COMPS.LEFTBAR.LEFT_BAR_WIDTH_FIXED_STAGE;
  const stageActionsWidth = constants.UI.EDITING_AREA_RIGHT_MARGIN;
  const maxSiteScaleForFixedStage = 0.9;
  const previewScaleXRelativeToStage = 0.5;
  const newScale =
    ((window.innerWidth - leftPanelWidth - stageActionsWidth) *
      previewScaleXRelativeToStage) /
    fixedEditingAreaWidth;
  return Math.min(maxSiteScaleForFixedStage, Number(newScale.toFixed(1)));
}

export class ZoomModeApi extends BasePublicApi<Scope> {
  enterZoomMode = this.bindScope(enterZoomMode);
  exitZoomMode = this.bindScope(exitZoomMode);
  exitZoomModeAndClearTransformationsIfNeeded = this.bindScope(
    exitZoomModeAndClearTransformationsIfNeeded,
  );
  isInZoomMode = this.bindScope(isInZoomMode);
  isStageZoomMode = this.bindScope(isStageZoomMode);
  isZoomModeTransitionActive = this.bindScope(isZoomModeTransitionActive);
  getZoomModeTransitionPromise = this.scope.zoomTransitionDeferred.getPromise;
  getStageXOffset = this.bindScope(getStageXOffset);
  getStyleForPushedStageAndPreviewFrame = this.bindScope(
    getStyleForPushedStageAndPreviewFrame,
  );
  enabledCompPanels = this.bindScope(isZoomModeEnabledCompPanels);
  enabledCompControls = this.bindScope(isZoomModeEnabledCompControls);
  enabledRightClickMenu = this.bindScope(isZoomModeEnabledRightClickMenu);
  isShrinkedStageZoomOutActive = this.bindScope(isShrinkedStageZoomOutActive);
  isLeftShrinkedStageZoomOutActive = this.bindScope(
    isLeftShrinkedStageZoomOutActive,
  );
  isRightShrinkedStageZoomOutActive = this.bindScope(
    isRightShrinkedStageZoomOutActive,
  );
  setShouldOpenInZoomFromLocalStorage = setShouldOpenInZoomFromLocalStorage;
  getSiteScaleForPanelsOpen = this.bindScope(getSiteScaleForPanelsOpen);
  isZoomedByUser = this.bindScope(isZoomedByUser);
  getFullPanelSizeShiftX = this.bindScope(getFullPanelSizeShiftX);
}
