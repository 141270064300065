'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')

module.exports =
    {
        "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
        "hide": false,
        "title": "add_section_label_Vmenu",
        "subNavigationTitle": "add_section_label_Vmenu",
        "presetTitle": "add_section_label_Vmenu",
        "tooltipTitle": "add_section_label_Vmenu",
        "automationId": "add-panel-section-verticalMenusSection",
        "subNavigationHide": false,
        "showSectionHeader": true,
        "additionalBehaviours": {
            "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
            "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
            "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
            "iconEnabledForComps": {}
        },
        "props": {
            "image": `addPanelData/sections/verticalMenusSection_${util.languages.getLanguageCode()}/verticalMenusSection_${util.languages.getLanguageCode()}.png`,
            "imageHover": null,
            "items": [{
                "id": "Menu_Vertical_Menus_1",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextSkin",
                    "layout": {
                        "width": 87,
                        "height": 261,
                        "x": 119,
                        "y": 62,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                    "data": {
                        "type": "CustomMenuDataRef",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "menuRef": "#CUSTOM_MAIN_MENU"
                    },
                    "props": {
                        "type": "VerticalMenuProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "itemsAlignment": "left",
                        "subMenuOpenSide": "right",
                        "originalHeight": 225
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "SKINS_bgSubmenu": "#FFFFFF",
                                "SKINS_fntSubmenu": "normal normal normal 15px/1.4em helvetica-w01-light",
                                "SKINS_submenuMargin": "0px",
                                "alpha-SKINS_bgSubmenu": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "alpha-txts": "1",
                                "boxShadowToggleOn-shd": "false",
                                "fnt": "normal normal normal 16px/1.4em futura-lt-w01-book",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "textSpacing": "0px",
                                "txt": "#555252",
                                "txth": "#555252",
                                "txts": "#000000"
                            },
                            "propertiesSource": {
                                "SKINS_bgSubmenu": "value",
                                "SKINS_fntSubmenu": "value",
                                "SKINS_submenuMargin": "value",
                                "alpha-SKINS_bgSubmenu": "value",
                                "fnt": "value",
                                "shd": "value",
                                "textSpacing": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextSkin"
                    },
                    "activeModes": {},
                    "id": "comp-javaxq7i"
                },
                "preset": {"rect": {"width": 150, "height": 170, "x": 1, "y": 16}, "tags": null}
            }, {
                "id": "Menu_Vertical_Menus_2",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextWithSeparatorsSkin",
                    "layout": {
                        "width": 212,
                        "height": 242,
                        "x": 381,
                        "y": 72,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                    "data": {
                        "type": "CustomMenuDataRef",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "menuRef": "#CUSTOM_MAIN_MENU"
                    },
                    "props": {
                        "type": "VerticalMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "center",
                        "subMenuOpenSide": "right",
                        "originalHeight": 225
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "SKINS_fntSubmenu": "normal normal normal 16px/1.4em din-next-w01-light {color_14}",
                                "SKINS_submenuMargin": "0",
                                "alpha-SKINS_bgSubmenu": "0",
                                "alpha-bgh": "1",
                                "alpha-bgs": "1",
                                "alpha-brd": "1",
                                "bgh": "#E6EAF5",
                                "bgs": "#536EB7",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#C7C7C7",
                                "fnt": "normal normal normal 15px/1.4em libre+baskerville",
                                "sepw": "1px",
                                "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                "textSpacing": "10px",
                                "txt": "#373737",
                                "txts": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "SKINS_bgSubmenu": "value",
                                "SKINS_fntSubmenu": "value",
                                "SKINS_submenuMargin": "value",
                                "alpha-SKINS_bgSubmenu": "value",
                                "alpha-bgh": "value",
                                "alpha-bgs": "value",
                                "alpha-brd": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "fnt": "value",
                                "sepw": "value",
                                "shd": "value",
                                "textSpacing": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuTextWithSeparatorsSkin"
                    },
                    "activeModes": {},
                    "id": "comp-javb41ve"
                },
                "preset": {"rect": {"width": 164, "height": 170, "x": 152, "y": 16}, "tags": null}
            }, {
                "id": "Menu_Vertical_Menus_3",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonFixedWidthSkin",
                    "layout": {
                        "width": 178,
                        "height": 258,
                        "x": 72,
                        "y": 457,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                    "data": {
                        "type": "CustomMenuDataRef",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "menuRef": "#CUSTOM_MAIN_MENU"
                    },
                    "props": {
                        "type": "VerticalMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "center",
                        "subMenuOpenSide": "right",
                        "originalHeight": 225
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "SKINS_fntSubmenu": "normal normal normal 16px/1.4em din-next-w01-light {color_14}",
                                "SKINS_submenuBR": "0",
                                "SKINS_submenuMargin": "8",
                                "alpha-SKINS_bgSubmenu": "0",
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-bgs": "1",
                                "alpha-brd": "1",
                                "bg": "#B5F3E8",
                                "bgh": "#9BE8DA",
                                "bgs": "#79B6BB",
                                "boxShadowToggleOn-shd": "false",
                                "brw": "0",
                                "fnt": "normal normal normal 14px/1.4em droid-serif-w01-regular",
                                "rd": "0",
                                "separatorHeight": "10",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "textSpacing": "6",
                                "txts": "#E6EAF5"
                            },
                            "propertiesSource": {
                                "SKINS_bgSubmenu": "value",
                                "SKINS_fntSubmenu": "value",
                                "SKINS_submenuBR": "value",
                                "SKINS_submenuMargin": "value",
                                "alpha-SKINS_bgSubmenu": "value",
                                "alpha-bg": "value",
                                "alpha-bgh": "value",
                                "alpha-bgs": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "separatorHeight": "value",
                                "shd": "value",
                                "textSpacing": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonFixedWidthSkin"
                    },
                    "activeModes": {},
                    "id": "comp-javbh1r8"
                },
                "preset": {"rect": {"width": 154, "height": 186, "x": 1, "y": 210}, "tags": null}
            }, {
                "id": "Menu_Vertical_Menus_4",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonFixedWidthSkin",
                    "layout": {
                        "width": 168,
                        "height": 263,
                        "x": 401,
                        "y": 454,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                    "data": {
                        "type": "CustomMenuDataRef",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "menuRef": "#CUSTOM_MAIN_MENU"
                    },
                    "props": {
                        "type": "VerticalMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "center",
                        "subMenuOpenSide": "right",
                        "originalHeight": 225
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "SKINS_fntSubmenu": "normal normal normal 16px/1.4em din-next-w01-light {color_14}",
                                "SKINS_submenuBR": "90px",
                                "SKINS_submenuMargin": "0",
                                "alpha-SKINS_bgSubmenu": "0",
                                "alpha-bg": "0",
                                "alpha-bgh": "1",
                                "alpha-bgs": "1",
                                "alpha-brd": "1",
                                "bg": "#FFFFFF",
                                "bgh": "#E6EAF5",
                                "bgs": "#FFD45A",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFD45A",
                                "brw": "0",
                                "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_85-heavy1475544",
                                "rd": "90px",
                                "separatorHeight": "15",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "textSpacing": "0",
                                "txt": "#373737",
                                "txts": "#373737"
                            },
                            "propertiesSource": {
                                "SKINS_bgSubmenu": "value",
                                "SKINS_fntSubmenu": "value",
                                "SKINS_submenuBR": "value",
                                "SKINS_submenuMargin": "value",
                                "alpha-SKINS_bgSubmenu": "value",
                                "alpha-bg": "value",
                                "alpha-bgh": "value",
                                "alpha-bgs": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "separatorHeight": "value",
                                "shd": "value",
                                "textSpacing": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonFixedWidthSkin"
                    },
                    "activeModes": {},
                    "id": "comp-javbto8c"
                },
                "preset": {"rect": {"width": 150, "height": 186, "x": 162, "y": 210}, "tags": null}
            }, {
                "id": "Menu_Vertical_Menus_5",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSolidColorSkin",
                    "layout": {
                        "width": 171,
                        "height": 236,
                        "x": 79,
                        "y": 882,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                    "data": {
                        "type": "CustomMenuDataRef",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "menuRef": "#CUSTOM_MAIN_MENU"
                    },
                    "props": {
                        "type": "VerticalMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "center",
                        "subMenuOpenSide": "right",
                        "originalHeight": 225
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "SKINS_fntSubmenu": "normal normal normal 16px/1.4em din-next-w01-light {color_14}",
                                "SKINS_submenuBR": "0",
                                "SKINS_submenuMargin": "0",
                                "alpha-SKINS_bgSubmenu": "0",
                                "alpha-bg": "0",
                                "alpha-bgh": "1",
                                "alpha-bgs": "1",
                                "alpha-brd": "1",
                                "alpha-sep": "0",
                                "bgh": "#FF9494",
                                "bgs": "#FF4F4F",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FF4F4F",
                                "brw": "1px",
                                "fnt": "normal normal normal 15px/1.4em helvetica-w01-light",
                                "rd": "0",
                                "sep": "#414141",
                                "sepw": "0",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "textSpacing": "10px",
                                "txth": "#FFFFFF",
                                "txts": "#E6EAF5"
                            },
                            "propertiesSource": {
                                "SKINS_bgSubmenu": "value",
                                "SKINS_fntSubmenu": "value",
                                "SKINS_submenuBR": "value",
                                "SKINS_submenuMargin": "value",
                                "alpha-SKINS_bgSubmenu": "value",
                                "alpha-bg": "value",
                                "alpha-bgh": "value",
                                "alpha-bgs": "value",
                                "alpha-brd": "value",
                                "alpha-sep": "value",
                                "bg": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "sep": "value",
                                "sepw": "value",
                                "shd": "value",
                                "textSpacing": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSolidColorSkin"
                    },
                    "activeModes": {},
                    "id": "comp-javc7try"
                },
                "preset": {"rect": {"width": 154, "height": 186, "x": 1, "y": 412}, "tags": null}
            }, {
                "id": "Menu_Vertical_Menus_6",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonFixedWidthSkin",
                    "layout": {
                        "width": 179,
                        "height": 251,
                        "x": 399,
                        "y": 874,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                    "data": {
                        "type": "CustomMenuDataRef",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "menuRef": "#CUSTOM_MAIN_MENU"
                    },
                    "props": {
                        "type": "VerticalMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "center",
                        "subMenuOpenSide": "right",
                        "originalHeight": 225
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "SKINS_fntSubmenu": "normal normal normal 16px/1.4em din-next-w01-light {color_14}",
                                "SKINS_submenuBR": "0",
                                "SKINS_submenuMargin": "8",
                                "alpha-SKINS_bgSubmenu": "0",
                                "alpha-bg": "0",
                                "alpha-bgh": "1",
                                "alpha-bgs": "1",
                                "alpha-brd": "1",
                                "bg": "#B5F3E8",
                                "bgh": "#E6EAF5",
                                "bgs": "#323232",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#323232",
                                "brw": "1",
                                "fnt": "normal normal normal 15px/1.4em didot-w01-italic",
                                "rd": "0",
                                "separatorHeight": "10",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "textSpacing": "6",
                                "txth": "#323232",
                                "txts": "#E6EAF5"
                            },
                            "propertiesSource": {
                                "SKINS_bgSubmenu": "value",
                                "SKINS_fntSubmenu": "value",
                                "SKINS_submenuBR": "value",
                                "SKINS_submenuMargin": "value",
                                "alpha-SKINS_bgSubmenu": "value",
                                "alpha-bg": "value",
                                "alpha-bgh": "value",
                                "alpha-bgs": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "separatorHeight": "value",
                                "shd": "value",
                                "textSpacing": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonFixedWidthSkin"
                    },
                    "activeModes": {},
                    "id": "comp-javc1kg4"
                },
                "preset": {"rect": {"width": 150, "height": 186, "x": 166, "y": 412}, "tags": null}
            }, {
                "id": "Menu_Vertical_Menus_7",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSolidColorSkin",
                    "layout": {
                        "width": 150,
                        "height": 226,
                        "x": 87,
                        "y": 1305,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                    "data": {
                        "type": "CustomMenuDataRef",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "menuRef": "#CUSTOM_MAIN_MENU"
                    },
                    "props": {
                        "type": "VerticalMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "left",
                        "subMenuOpenSide": "right",
                        "originalHeight": 225
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "SKINS_fntSubmenu": "normal normal normal 16px/1.4em din-next-w01-light {color_14}",
                                "SKINS_submenuBR": "0",
                                "SKINS_submenuMargin": "0",
                                "alpha-SKINS_bgSubmenu": "0",
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-bgs": "1",
                                "alpha-brd": "1",
                                "alpha-sep": "0",
                                "bgh": "#FFD9EA",
                                "bgs": "#FFD9E4",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#EDE2DA",
                                "brw": "0",
                                "fnt": "normal normal normal 16px/1.4em din-next-w01-light",
                                "rd": "0",
                                "sep": "#414141",
                                "sepw": "0",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "textSpacing": "21",
                                "txth": "#FF4F4F",
                                "txts": "#FF4F4F"
                            },
                            "propertiesSource": {
                                "SKINS_bgSubmenu": "value",
                                "SKINS_fntSubmenu": "value",
                                "SKINS_submenuBR": "value",
                                "SKINS_submenuMargin": "value",
                                "alpha-SKINS_bgSubmenu": "value",
                                "alpha-bg": "value",
                                "alpha-bgh": "value",
                                "alpha-bgs": "value",
                                "alpha-brd": "value",
                                "alpha-sep": "value",
                                "bg": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "sep": "value",
                                "sepw": "value",
                                "shd": "value",
                                "textSpacing": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSolidColorSkin"
                    },
                    "activeModes": {},
                    "id": "comp-javcpu2m"
                },
                "preset": {"rect": {"width": 150, "height": 180, "x": 1, "y": 620}, "tags": null}
            }, {
                "id": "Menu_Vertical_Menus_8",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonFixedWidthSkin",
                    "layout": {
                        "width": 152,
                        "height": 232,
                        "x": 410,
                        "y": 1302,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                    "data": {
                        "type": "CustomMenuDataRef",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "menuRef": "#CUSTOM_MAIN_MENU"
                    },
                    "props": {
                        "type": "VerticalMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "left",
                        "subMenuOpenSide": "right",
                        "originalHeight": 225
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "SKINS_fntSubmenu": "normal normal normal 16px/1.4em raleway",
                                "SKINS_submenuBR": "0",
                                "SKINS_submenuMargin": "0",
                                "alpha-SKINS_bgSubmenu": "0",
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-bgs": "1",
                                "alpha-brd": "1",
                                "bg": "#F2F2F2",
                                "bgh": "#EDE8DA",
                                "bgs": "#E6DECA",
                                "boxShadowToggleOn-shd": "false",
                                "brw": "0",
                                "fnt": "normal normal normal 15px/1.4em raleway",
                                "rd": "0",
                                "separatorHeight": "0",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "textSpacing": "22",
                                "txth": "#323232",
                                "txts": "#323232"
                            },
                            "propertiesSource": {
                                "SKINS_bgSubmenu": "value",
                                "SKINS_fntSubmenu": "value",
                                "SKINS_submenuBR": "value",
                                "SKINS_submenuMargin": "value",
                                "alpha-SKINS_bgSubmenu": "value",
                                "alpha-bg": "value",
                                "alpha-bgh": "value",
                                "alpha-bgs": "value",
                                "alpha-brd": "value",
                                "bg": "value",
                                "bgh": "value",
                                "bgs": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "separatorHeight": "value",
                                "shd": "value",
                                "textSpacing": "value",
                                "txt": "value",
                                "txth": "value",
                                "txts": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalmenu.viewer.VerticalMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSeparatedButtonFixedWidthSkin"
                    },
                    "activeModes": {},
                    "id": "comp-javcga1b"
                },
                "preset": {"rect": {"width": 150, "height": 180, "x": 166, "y": 620}, "tags": null}
            }],
            "compTypes": ["wysiwyg.common.components.verticalmenu.viewer.VerticalMenu"]
        },
        "help": {"hide": false, "text": "add_section_info_text_Vmenu"}
    }
