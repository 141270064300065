// @ts-nocheck
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import React, { type FC } from 'react';
import PlatformPanelApplicationFrame from './PlatformPanelApplicationFrame';

//TYPE WAS GENERATED, remove this line when reviewed
interface PlatformSidePanelProps {
  token?: string;
  url?: string;
  initialData?: AnyFixMe;
  showOverlay?: boolean;
}

const PlatformSidePanel: FC<PlatformSidePanelProps> = (props) =>
  props.url ? (
    <div key="scroll-container" className="scroll-container">
      <div data-aid="platform-side-panel" className="platform-side-panel">
        <PlatformPanelApplicationFrame
          scrolling="yes"
          key={props.token}
          token={props.token}
          url={props.url}
          initialData={props.initialData}
          height="100%"
          width="100%"
        />
      </div>
      {props.showOverlay ? (
        <div key="side-panel-overlay" className="dark-frame-overlay" />
      ) : null}
    </div>
  ) : null;

const mapStateToProps = ({ state }) => {
  const panel =
    stateManagement.panels.selectors.selectOpenDockedPlatformPanels(state)[0];
  if (panel) {
    const { token, url, initialData, showOverlay } = panel.props;
    return { token, url, initialData, showOverlay };
  }
  return null;
};

const Connected = util.hoc.connect(
  util.hoc.STORES.STATE_ONLY,
  mapStateToProps,
)(PlatformSidePanel);

Connected.pure = PlatformSidePanel;

export default Connected;
