import React from 'react';
import { Trans } from 'react-i18next';
import { Tooltip, Text, Badge } from '@wix/wix-base-ui';

import { i18n } from '@/i18n';
import * as util from '@/util';

import {
  mapStateToProps,
  type SharedCollectionLabelStateProps,
} from './sharedCollectionLabel.mapper';

interface SharedCollectionLabelOwnProps {}

interface SharedCollectionLabelProps
  extends SharedCollectionLabelOwnProps,
    SharedCollectionLabelStateProps {}

const SharedCollectionLabel: React.FC<SharedCollectionLabelProps> = ({
  enterpriseBrandName,
}) => {
  return (
    <Tooltip
      dataHook="shared-collection-tooltip"
      content={
        <Text enableEllipsis={false} shouldTranslate={false} size="small">
          <Trans
            i18n={i18n}
            i18nKey={
              enterpriseBrandName
                ? 'SiteApp_WixData_DataManager_SharedCollection_Tooltip_WithShareSource'
                : 'SiteApp_WixData_DataManager_SharedCollection_Tooltip_No_ShareSource'
            }
            values={{ shareSource: enterpriseBrandName }}
          >
            <Text
              enableEllipsis={false}
              shouldTranslate={false}
              weight="bold"
              size="small"
            >
              <></>
            </Text>
          </Trans>
        </Text>
      }
    >
      <Badge
        dataHook="shared-collection-badge"
        className="shared-collection-badge"
        skin="standard"
        uppercase
        value="SiteApp_WixData_DataManager_Tab_SharedCollection_Badge"
      />
    </Tooltip>
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
)(SharedCollectionLabel);
