import type { MediaManagerInfo, MediaManagerVideo } from '@/mediaServices';
import type { EditorAPI } from '@/editorAPI';
import { backgroundUtils } from '@/util';
import type { CompStructure, StyleRef } from 'types/documentServices';
import _ from 'lodash';

// CompDef generation was copied from https://github.com/wix-private/santa-editor/blob/master/editor-add-panel/addPanelSectionsEN/sections/video/moreBehaviorHelper.js

const PREFERRED_SIZE = 640;
const MIN_SIZE = 240;

interface VideoSize {
  width: number;
  height: number;
}

interface VideoData {
  qualities: { height: number; quality: string; url: string; width: number }[];
  mediaFeatures: string[];
}

const getSizeByWidth = (width: number, aspect: number): VideoSize => ({
  width,
  height: width / aspect,
});

const getSizeByHeight = (height: number, aspect: number): VideoSize => ({
  width: height * aspect,
  height,
});

const getMediaProportionSize = (aspectRatio: number): VideoSize => {
  let layout;
  const landscape = aspectRatio > 1;
  const isAspectInRange =
    aspectRatio < PREFERRED_SIZE / MIN_SIZE &&
    aspectRatio > MIN_SIZE / PREFERRED_SIZE;
  if (isAspectInRange) {
    //eslint-disable-line wix-editor/prefer-ternary
    layout = landscape
      ? getSizeByWidth(PREFERRED_SIZE, aspectRatio)
      : getSizeByHeight(PREFERRED_SIZE, aspectRatio);
  } else {
    layout = landscape
      ? getSizeByHeight(MIN_SIZE, aspectRatio)
      : getSizeByWidth(MIN_SIZE, aspectRatio);
  }
  return layout;
};

function getAspectRatio(videoData: VideoData): number {
  const [quality] = videoData.qualities;
  return quality.width / quality.height;
}

function getMediaPlayerContainerStructure(
  editorAPI: EditorAPI,
  videoData: VideoData,
  videoSize: VideoSize,
): CompStructure {
  const structure = _.omit(
    editorAPI.components.buildDefaultComponentStructure(
      'wysiwyg.viewer.components.MediaPlayer',
    ),
    'data',
  );
  const hasAlpha = videoData.mediaFeatures?.includes('alpha');

  structure.design.background = {
    type: 'BackgroundMedia',
    mediaRef: videoData,
    alignType: 'center',
    fittingType: 'fill',
    imageOverlay: null,
    colorOverlay: '',
    colorOverlayOpacity: 0,
  };
  const layout = structure.layout;
  if (layout) {
    Object.assign(layout, {
      width: videoSize.width,
      height: videoSize.height,
    });
  }
  structure.skin = 'wysiwyg.viewer.skins.mediaPlayerSkin';
  structure.style = 'mp1';
  structure.type = 'Container';
  structure.props = {
    ...structure.props,
    autoplay: true,
    loop: true,
    mute: true,
    disableAudio: false,
    playerInteraction: {
      click: hasAlpha ? 'none' : 'toggle',
      rollIn: 'none',
      rollOut: 'none',
    },
  };

  return structure;
}

function getMediaPlayerOverlayControlsStructure(
  editorAPI: EditorAPI,
  overlaySize: number,
  videoSize: VideoSize,
): CompStructure {
  const overlay = _.omit(
    editorAPI.components.buildDefaultComponentStructure(
      'wysiwyg.viewer.components.MediaOverlayControls',
    ),
    'data',
  );
  overlay.layout = {
    width: overlaySize,
    height: overlaySize,
    x: (videoSize.width - overlaySize) / 2,
    y: (videoSize.height - overlaySize) / 2,
  };
  overlay.design = {
    type: 'MediaControlsDesignData',
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
    iconsDefaultDesign: {
      type: 'VectorImageDesignData',
      metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
      shapeStyle: {
        opacity: 0.8,
        strokeOpacity: 1,
        stroke: '#000000',
        enableStroke: false,
        strokeWidth: 1,
      },
      overrideColors: {
        color1: '#000000',
      },
    },
    icons: [
      {
        type: 'ControlIconDesignData',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        name: 'playButton',
        svgId: '0da768_661bc0cafffa432db3753ad5d17e4f10.svg', //icon #05, 1 color play in circle
      },
    ],
  };
  overlay.skin = 'skins.viewer.mediaOverlayControlsDefaultSkin';
  overlay.style = {
    type: 'TopLevelStyle',
    id: 'mocntDefault',
    style: {
      groups: {},
      properties: {},
      propertiesSource: {},
    },
    styleType: 'custom',
    skin: 'skins.viewer.mediaOverlayControlsDefaultSkin',
  } as StyleRef;

  return overlay;
}

function getMediaPlayerControlsStructure(
  editorAPI: EditorAPI,
  videoData: VideoData,
  videoSize: VideoSize,
  controlsHeight: number,
) {
  const controls = _.omit(
    editorAPI.components.buildDefaultComponentStructure(
      'wysiwyg.viewer.components.MediaControls',
    ),
    'data',
  );
  controls.props.showStoryboard = 'none';
  controls.layout = {
    width: videoSize.width,
    height: controlsHeight,
    x: 0,
    y: videoSize.height - controlsHeight,
  };
  controls.design = {
    type: 'MediaControlsDesignData',
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
    iconsDefaultDesign: {
      type: 'VectorImageDesignData',
      metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
      shapeStyle: {
        opacity: 1,
        strokeOpacity: 1,
        stroke: '#000000',
        enableStroke: false,
        strokeWidth: 1,
      },
      overrideColors: {
        color1: '#ffffff',
      },
    },
    icons: [
      {
        type: 'ControlIconDesignData',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        name: 'volumeOn',
        svgId: '0da768_4026746ebab74627a513aa0df1511dea.svg',
      },
      {
        type: 'ControlIconDesignData',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        name: 'volumeOff',
        svgId: '0da768_c6d2d796452644a6a4a243c3afb7b781.svg',
      },
      {
        type: 'ControlIconDesignData',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        name: 'noAudio',
        svgId: '0da768_c6d2d796452644a6a4a243c3afb7b781.svg',
        iconDesign: {
          type: 'VectorImageDesignData',
          metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
          overrideColors: {
            color1: '#a9a9a9',
          },
        },
      },
    ],
  };
  controls.skin = 'skins.viewer.mediaControlsNoControlsSkin';
  controls.style = {
    type: 'TopLevelStyle',
    id: 'mcntLight',
    style: {
      groups: {},
      properties: {},
      propertiesSource: {},
    },
    styleType: 'custom',
    skin: 'skins.viewer.mediaControlsNoControlsSkin',
  } as StyleRef;

  return controls;
}

function getMediaPlayerStructure(
  editorAPI: EditorAPI,
  videoData: AnyFixMe,
): CompStructure {
  const aspectRatio = getAspectRatio(videoData);
  const videoSize = getMediaProportionSize(aspectRatio);

  const overlaySize = 86;
  const controlsHeight = 44;

  const structure = getMediaPlayerContainerStructure(
    editorAPI,
    videoData,
    videoSize,
  );
  const overlay = getMediaPlayerOverlayControlsStructure(
    editorAPI,
    overlaySize,
    videoSize,
  );
  const controls = getMediaPlayerControlsStructure(
    editorAPI,
    videoData,
    videoSize,
    controlsHeight,
  );

  structure.components = [overlay, controls];
  return structure;
}

export const getVideoBoxCompDef = (
  editorAPI: EditorAPI,
  item: MediaManagerVideo,
  info: MediaManagerInfo,
): CompStructure => {
  const videoData = backgroundUtils.getMediaPlayerVideoObject(item, info);
  return getMediaPlayerStructure(editorAPI, videoData);
};
