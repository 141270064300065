import {
  getModalToOpenByComponentType,
  getHelpIdByComponentType,
} from '@/componentDeprecation';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from '@wix/document-services-types';
import type { GFPPData } from '@wix/editor-component-types';

export default function (
  editorAPI: EditorAPI,
  compRefs: CompRef[],
  compGfppData: GFPPData,
) {
  if (
    compRefs.length &&
    editorAPI.components.getType(compRefs[0]) === 'wixui.UnsupportedComponent'
  ) {
    const isSelected = (editorAPI: EditorAPI, compRefs: CompRef[]) => {
      const comp = editorAPI.documentServices.components.data.get(compRefs[0]);
      if (comp) {
        const origCompName = comp.migratedFrom;
        const panelName = getModalToOpenByComponentType(origCompName);
        return editorAPI.panelManager.isPanelOpened(panelName);
      }
    };

    const getHelpID = (editorAPI: EditorAPI, compRefs: CompRef[]) => {
      const comp = editorAPI.documentServices.components.data.get(compRefs[0]);
      return getHelpIdByComponentType(comp.migratedFrom);
    };

    compGfppData.mainActions[0].onClick = (
      editorAPI: EditorAPI,
      compRefs: CompRef[],
    ) => {
      const comp = editorAPI.documentServices.components.data.get(compRefs[0]);
      editorAPI.panelManager.openPanel(
        getModalToOpenByComponentType(comp.migratedFrom),
        { origin: 'gfpp', migratedFrom: comp.migratedFrom },
      );
    };

    compGfppData.mainActions[0].isSelected = isSelected(editorAPI, compRefs);

    compGfppData.presetActions = {
      help: {
        helpId: getHelpID(editorAPI, compRefs),
      },
    };
  }
}
