import { snapToUtils } from '@/util';
import { registerOnSelectionChange } from './selectionChange';
import type { Scope } from '../scope';

const bootstrapStageGuides = (scope: Scope) => {
  if (!snapToUtils.isNewStageGuidesEnabled()) return;

  registerOnSelectionChange(scope);
};

export { bootstrapStageGuides };
