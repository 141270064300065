import * as util from '@/util';
import promoteEvents from './promoteEvents';
import utils from './utils';

export default {
  report: (
    biEvent: ValueOf<typeof promoteEvents.promoteEvents>,
    biEventParams: object,
  ) => util.bi.biLoggerLegacy.event(biEvent, biEventParams),
  events: promoteEvents.promoteEvents,
  registerEvents: promoteEvents.registerEvents,
  biUtils: utils,
};
