import { ErrorReporter } from '@wix/editor-error-reporter';
import type { Scope } from '../scope';

function handleInvalidFooterDataIfNeeded(scope: Scope): void {
  const footerRef = scope.editorAPI.siteSegments.getFooter();
  const footerData = scope.components.data.get(footerRef);
  if (footerData) {
    ErrorReporter.captureException(new Error('Invalid footer data'), {
      tags: {
        footerHasInvalidData: true,
      },
      extra: {
        footerData,
      },
    });
    scope.components.data.remove([footerRef], true);
  }
}

const bootstrapFooterData = (scope: Scope) => {
  handleInvalidFooterDataIfNeeded(scope);
};

export { bootstrapFooterData };
