import type { CompData } from 'types/documentServices';

const DEFAULT_DETACHED_IMAGE_WIDTH = 600;

export const DEFAULT_BACKGROUND_STRUCTURE = {
  type: 'BackgroundMedia',
  color: '#FAFAFA',
  colorOpacity: 1,
  alignType: 'center',
  fittingType: 'fill',
  scrollType: 'none',
  colorOverlay: '',
  colorOverlayOpacity: 0,
  showOverlayForMediaType: 'WixVideo',
  colorLayers: [
    {
      type: 'SolidColorLayer',
      opacity: 1,
      fill: { type: 'SolidColor', color: '#FFFFFF' },
    },
  ],
};

export const mapImageToBackgroundData = ({
  type,
  alt,
  title,
  uri,
  height,
  width,
  artist,
}: CompData) => {
  return {
    mediaRef: {
      type,
      uri,
      title,
      alt,
      height,
      width,
      artist,
    },
  };
};

export const getImageDimensions = (
  containerWidth: number,
  containerHeight: number,
  marginsSum: number,
  heightToWidthProportions: number,
) => {
  const availableSpaceWithinContainer = Math.min(
    containerWidth - marginsSum,
    DEFAULT_DETACHED_IMAGE_WIDTH,
  );

  let imageWidth = availableSpaceWithinContainer;
  const proportionalImageHeight = imageWidth * heightToWidthProportions;
  let imageHeight = Math.min(containerHeight, proportionalImageHeight);

  if (containerHeight < proportionalImageHeight) {
    imageWidth = imageHeight / heightToWidthProportions;
  }
  if (availableSpaceWithinContainer < imageWidth) {
    imageHeight = imageWidth * heightToWidthProportions;
  }

  return { width: imageWidth, height: imageHeight };
};
