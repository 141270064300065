import React, { Component, type ComponentType } from 'react';
import * as util from '@/util';
import type { MapStateToProps, ThunkAction } from 'types/redux';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

export interface WithOpenDropPanelProps {
  openedDropPanel: string;
  openDropPanel: (panelName: string) => void;
  closeDropPanel: (panelName?: string) => void;
}

interface ComponentWithOpenDropPanelProps {
  openedDropPanel: string;
  openDropPanel: (panelName: string) => void;
  closeDropPanel: (panelName?: string) => void;
}

const mapStateToProps: MapStateToProps = ({ editorAPI }) => ({
  openedDropPanel: editorAPI.topBarMenuBar.getOpenedDropDown(),
});

const mapDispatchToProps = {
  openDropPanel:
    (panelName: string): ThunkAction =>
    (dispatch, getState, { editorAPI }) =>
      editorAPI.topBarMenuBar.openDropDown(panelName),
  closeDropPanel:
    (): ThunkAction =>
    (dispatch, getState, { editorAPI }) =>
      editorAPI.topBarMenuBar.closeDropDown(),
};

const withOpenDropPanel = <P extends WithOpenDropPanelProps>(
  WrappedComponent: ComponentType<P & ComponentWithOpenDropPanelProps>,
) => {
  class WithOpenDropPanel extends Component<WithOpenDropPanelProps> {
    render() {
      const { props } = this;

      return React.createElement(WrappedComponent, props as P);
    }
  }

  return connect(
    EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  )(WithOpenDropPanel);
};

export default withOpenDropPanel;
