export default {
  xOffset: 40,
  yOffset: 30,
  newStackOffset: {
    x: 60,
    y: 40,
  },
  bottomThreshold: 80,
  rightThreshold: 80,
};
