'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const experiment = require('experiment')

const isNoTextExperimentEnabled = experiment.isOpen('se_UploadButtonTextBelowButtonOffByDefault')
const getPropsFromExperiment = () => ({
    "showPlaceholder": !isNoTextExperimentEnabled
})
const getDataFromExperiment = () => ({
    "placeholderLabel": isNoTextExperimentEnabled ? "" : "Max File Size 15MB"
})

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_upoad_button",
    "subNavigationTitle": "add_section_label_upoad_button",
    "topTitle": "add_section_title_upload_button",
    "presetTitle": "add_section_heading_upload_button",
    "tooltipTitle": "add_section_label_upoad_button_tooltip_title",
    "automationId": "add-panel-section-fileUploaderSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": `addPanelData/sections/fileUploaderSection_${util.languages.getLanguageCode()}/fileUploaderSection_${util.languages.getLanguageCode()}.v2.png`,
        "imageHover": null,
        "items": [{
            "id": "User_Input_FileUploader_1",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.FileUploaderDefaultSkin",
                "layout": {
                    "width": 180,
                    "height": 76,
                    "x": 31,
                    "y": 29,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.FileUploader",
                "data": {
                    "type": "FileUploader",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "buttonLabel": "Choose File",
                    ...getDataFromExperiment()
                },
                "props": {
                    "type": "FileUploaderProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "isHidden": false,
                    "buttonAlignment": "ltr",
                    "filesAlignment": "center",
                    "filesType": "Image",
                    "filesSizeLimit": 15,
                    "showFileName": true,
                    ...getPropsFromExperiment()
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-btn_brd": "1",
                            "alpha-file_bg_h": "0",
                            "bg_d": "#DBDBDB",
                            "bg_e": "#FF4040",
                            "bg_f": "#67D6D6",
                            "boxShadowToggleOn-btn_shd": "false",
                            "brd_d": "#FFFFFF",
                            "brw_d": "0",
                            "brw_e": "0",
                            "btn_bg": "#49D6D6",
                            "btn_bg_h": "#80E0E0",
                            "btn_brd": "#FFFFFF",
                            "btn_brd_h": "#FFFFFF",
                            "btn_brw": "0",
                            "btn_brw_h": "0",
                            "btn_fnt": "italic normal normal 15px/1.4em georgia",
                            "btn_shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "btn_txt": "#FFFFFF",
                            "btn_txt_h": "#FFFFFF",
                            "file_bg_h": "#FFFFFF",
                            "file_fnt": "normal normal normal 12px/1.4em helvetica-w01-light",
                            "txt_d": "#FFFFFF",
                            "txt_e": "#FFFFFF",
                            "txt_f": "#FFFFFF",
                            "x_file_txt": "#737373",
                            "x_file_txt_e": "#FF4040",
                            "x_file_txt_d": "color_6",
                            "txtlbl": "#49D6D6",
                            "txtlblrq": "#49D6D6",
                            "fntlbl": "italic normal normal 14px/1.4em georgia"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.FileUploader",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.FileUploaderDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-ixptdqnd"
            },
            "preset": {"rect": {"width": 159.72, "height": 82.5, "x": 0, "y": 0}}
        }, {
            "id": "User_Input_FileUploader_2",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.FileUploaderDefaultSkin",
                "layout": {
                    "width": 180,
                    "height": 76,
                    "x": 273,
                    "y": 29,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.FileUploader",
                "data": {
                    "type": "FileUploader",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "buttonLabel": "Upload",
                    ...getDataFromExperiment()
                },
                "props": {
                    "type": "FileUploaderProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "isHidden": false,
                    "buttonAlignment": "ltr",
                    "filesAlignment": "center",
                    "filesType": "Image",
                    "filesSizeLimit": 15,
                    "showFileName": true,
                    ...getPropsFromExperiment()
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-btn_brd": "1",
                            "alpha-file_bg_h": "0",
                            "bg_d": "#DBDBDB",
                            "bg_e": "#FF4040",
                            "bg_f": "#FFFFFF",
                            "boxShadowToggleOn-btn_shd": "true",
                            "brd_d": "#FFFFFF",
                            "brd_f": "#000000",
                            "brw_d": "0",
                            "brw_e": "0",
                            "brw_f": "0",
                            "btn_bg": "#FFFFFF",
                            "btn_bg_h": "#FFFFFF",
                            "btn_brd": "#FFFFFF",
                            "btn_brd_h": "#F0F0F0",
                            "btn_brw": "0",
                            "btn_brw_h": "1",
                            "btn_fnt": "normal normal normal 12px/1.4em lulo-clean-w01-one-bold",
                            "btn_rd": "6px",
                            "btn_shd": "0.00px 3.00px 10px 1px rgba(0,0,0,0.08)",
                            "btn_txt": "#000000",
                            "btn_txt_h": "#000000",
                            "file_bg_h": "#FFFFFF",
                            "file_fnt": "normal normal normal 12px/1.4em helvetica-w01-light",
                            "txt_d": "#FFFFFF",
                            "txt_e": "#FFFFFF",
                            "txt_f": "#000000",
                            "x_file_txt": "#737373",
                            "x_file_txt_e": "#FF4040",
                            "txtlbl": "#000000",
                            "txtlblrq": "#000000",
                            "fntlbl": "normal normal normal 10px/1.4em lulo-clean-w01-one-bold"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.FileUploader",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.FileUploaderDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-ixpty9mv"
            },
            "preset": {"rect": {"width": 161.04000000000002, "height": 82.5, "x": 159.06, "y": 0}}
        }, {
            "id": "User_Input_FileUploader_3",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.FileUploaderDefaultSkin",
                "layout": {
                    "width": 180,
                    "height": 76,
                    "x": 31,
                    "y": 150,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.FileUploader",
                "data": {
                    "type": "FileUploader",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "buttonLabel": "Add a File",
                    ...getDataFromExperiment()
                },
                "props": {
                    "type": "FileUploaderProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "isHidden": false,
                    "buttonAlignment": "rtl",
                    "filesAlignment": "center",
                    "filesType": "Image",
                    "filesSizeLimit": 15,
                    "showFileName": true,
                    ...getPropsFromExperiment()
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-btn_brd": "1",
                            "alpha-file_bg_h": "0",
                            "bg_d": "#DBDBDB",
                            "bg_e": "#FF4040",
                            "bg_f": "#8C84FA",
                            "boxShadowToggleOn-btn_shd": "false",
                            "brd_d": "#FFFFFF",
                            "brw_d": "0",
                            "brw_e": "0",
                            "btn_bg": "#8C84FA",
                            "btn_bg_h": "#7D74F2",
                            "btn_brd": "#FFFFFF",
                            "btn_brd_h": "#FFFFFF",
                            "btn_brw": "0",
                            "btn_brw_h": "0",
                            "btn_fnt": "normal normal normal 15px/1.4em futura-lt-w01-book",
                            "btn_rd": "0px",
                            "btn_shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "btn_txt": "#FFFFFF",
                            "btn_txt_h": "#FFFFFF",
                            "file_bg_h": "#FFFFFF",
                            "file_fnt": "normal normal normal 12px/1.4em helvetica-w01-light",
                            "txt_d": "#FFFFFF",
                            "txt_e": "#FFFFFF",
                            "txt_f": "#FFFFFF",
                            "x_file_txt": "#737373",
                            "x_file_txt_e": "#FF4040",
                            "txtlbl": "#8C84FA",
                            "txtlblrq": "#8C84FA",
                            "fntlbl": "normal normal normal 15px/1.4em futura-lt-w01-book"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.FileUploader",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.FileUploaderDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-ixpufond"
            },
            "preset": {"rect": {"width": 159.72, "height": 79.2, "x": 0, "y": 82.5}}
        }, {
            "id": "User_Input_FileUploader_4",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.FileUploaderDefaultSkin",
                "layout": {
                    "width": 180,
                    "height": 76,
                    "x": 273,
                    "y": 149,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.FileUploader",
                "data": {
                    "type": "FileUploader",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "buttonLabel": "Upload It",
                    ...getDataFromExperiment()
                },
                "props": {
                    "type": "FileUploaderProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "isHidden": false,
                    "buttonAlignment": "ltr",
                    "filesAlignment": "center",
                    "filesType": "Image",
                    "filesSizeLimit": 15,
                    "showFileName": true,
                    ...getPropsFromExperiment()
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-btn_brd": "1",
                            "alpha-file_bg_h": "0",
                            "bg_d": "#F5F5F5",
                            "bg_e": "#FF4040",
                            "bg_f": "#5EE08A",
                            "boxShadowToggleOn-btn_shd": "false",
                            "brd_d": "#FFFFFF",
                            "brw_d": "0",
                            "brw_e": "0",
                            "btn_bg": "#4BDB7B",
                            "btn_bg_h": "#66E891",
                            "btn_brd": "#FFFFFF",
                            "btn_brd_h": "#FFFFFF",
                            "btn_brw": "0",
                            "btn_brw_h": "0",
                            "btn_fnt": "normal normal normal 16px/1.4em bree-w01-thin-oblique",
                            "btn_rd": "10px",
                            "btn_shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "btn_txt": "#FFFFFF",
                            "btn_txt_h": "#FFFFFF",
                            "file_bg_h": "#FFFFFF",
                            "file_fnt": "normal normal normal 12px/1.4em helvetica-w01-light",
                            "txt_d": "#D4D4D4",
                            "txt_e": "#FFFFFF",
                            "txt_f": "#FFFFFF",
                            "x_file_txt": "#737373",
                            "x_file_txt_e": "#FF4040",
                            "txtlbl": "#5EE08A",
                            "txtlblrq": "#5EE08A",
                            "fntlbl": "normal normal normal 14px/1.4em bree-w01-thin-oblique"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.FileUploader",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.FileUploaderDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-ixpuoa26"
            },
            "preset": {"rect": {"width": 161.04000000000002, "height": 79.2, "x": 159.06, "y": 82.5}}
        }, {
            "id": "User_Input_FileUploader_5",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.FileUploaderDefaultSkin",
                "layout": {
                    "width": 180,
                    "height": 76,
                    "x": 31,
                    "y": 269,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.FileUploader",
                "data": {
                    "type": "FileUploader",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "buttonLabel": "Select File",
                    ...getDataFromExperiment()
                },
                "props": {
                    "type": "FileUploaderProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "isHidden": false,
                    "buttonAlignment": "rtl",
                    "filesAlignment": "center",
                    "filesType": "Image",
                    "filesSizeLimit": 15,
                    "showFileName": true,
                    ...getPropsFromExperiment()
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-btn_brd": "1",
                            "alpha-file_bg_h": "0",
                            "bg_d": "#DBDBDB",
                            "bg_e": "#FF4040",
                            "bg_f": "#525252",
                            "boxShadowToggleOn-btn_shd": "false",
                            "brd_d": "#FFFFFF",
                            "brw_d": "0",
                            "brw_e": "0",
                            "btn_bg": "#FFFFFF",
                            "btn_bg_h": "#808080",
                            "btn_brd": "#919191",
                            "btn_brd_h": "#919191",
                            "btn_brw": "2",
                            "btn_brw_h": "0",
                            "btn_fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                            "fntlbl": "normal normal 700 14px/1.4em avenir-lt-w01_35-light1475496",
                            "btn_rd": "0px",
                            "btn_shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "btn_txt": "#919191",
                            "btn_txt_h": "#FFFFFF",
                            "file_bg_h": "#FFFFFF",
                            "file_fnt": "normal normal normal 12px/1.4em helvetica-w01-light",
                            "txt_d": "#FFFFFF",
                            "txt_e": "#FFFFFF",
                            "txt_f": "#FFFFFF",
                            "x_file_txt": "#737373",
                            "x_file_txt_e": "#FF4040",
                            "txtlbl": "#61615F",
                            "txtlblrq": "#61615F"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.FileUploader",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.FileUploaderDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-ixpuxewc"
            },
            "preset": {"rect": {"width": 159.72, "height": 83.16000000000001, "x": 0, "y": 161.70000000000002}}
        }, {
            "id": "User_Input_FileUploader_6",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.FileUploaderDefaultSkin",
                "layout": {
                    "width": 170,
                    "height": 76,
                    "x": 278,
                    "y": 268,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.FileUploader",
                "data": {
                    "type": "FileUploader",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "buttonLabel": "Change File",
                    ...getDataFromExperiment()
                },
                "props": {
                    "type": "FileUploaderProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "isHidden": false,
                    "buttonAlignment": "rtl",
                    "filesAlignment": "center",
                    "filesType": "Image",
                    "filesSizeLimit": 15,
                    "showFileName": true,
                    ...getPropsFromExperiment()
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-btn_brd": "1",
                            "alpha-file_bg_h": "0",
                            "bg_d": "#DBDBDB",
                            "bg_e": "#FF4040",
                            "bg_f": "#3899EC",
                            "boxShadowToggleOn-btn_shd": "false",
                            "brd_d": "#FFFFFF",
                            "brw_d": "0",
                            "brw_e": "0",
                            "btn_bg": "#3899EC",
                            "btn_bg_h": "#4EB7F5",
                            "btn_brd": "#FFFFFF",
                            "btn_brd_h": "#919191",
                            "btn_brw": "0",
                            "btn_brw_h": "0",
                            "btn_fnt": "normal normal normal 15px/1.4em helvetica-w01-light",
                            "fntlbl": "normal normal normal 14px/1.4em helvetica-w01-light",
                            "btn_rd": "40px",
                            "btn_shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "btn_txt": "#FFFFFF",
                            "btn_txt_h": "#FFFFFF",
                            "file_bg_h": "#FFFFFF",
                            "file_fnt": "normal normal normal 12px/1.4em helvetica-w01-light",
                            "txt_d": "#FFFFFF",
                            "txt_e": "#FFFFFF",
                            "txt_f": "#FFFFFF",
                            "x_file_txt": "#737373",
                            "x_file_txt_e": "#FF4040",
                            "txtlbl": "#00A6FF",
                            "txtlblrq": "#00A6FF"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.FileUploader",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.FileUploaderDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-ixpvfqsm"
            },
            "preset": {"rect": {"width": 160.38, "height": 83.16000000000001, "x": 159.06, "y": 161.70000000000002}}
        }],
        "compTypes": ["wysiwyg.viewer.components.inputs.FileUploader"]
    },
    "help": {"hide": false, "text": "add_section_info_text_fileuploader"}
}
