import $ from 'zepto';
import ReactDOM from 'react-dom';
import { cx, hoc } from '@/util';
import { translate } from '@/i18n';
import {
  type LayersPanelDispatchProps,
  type LayersPanelStateProps,
  mapDispatchToProps,
  mapStateToProps,
} from './layersPanelMapper';
import React from 'react';
import Node from './node';
import { TpaSettingsPanelFrame } from '../../frames';
import { shouldShowLayersPanel } from './selectors';
import {
  useTreeNodesContext,
  type TreeNodesContext,
} from './createNodeProps/nodesContext';
import experiment from 'experiment';

export interface LayersPanelOwnProps {
  treeNodesContext: TreeNodesContext;
}

interface LayersPanelProps
  extends LayersPanelOwnProps,
    LayersPanelStateProps,
    LayersPanelDispatchProps {}

class LayersPanel extends React.Component<LayersPanelProps> {
  componentDidMount() {
    this.props.init({
      treeNodesContext: this.props.treeNodesContext,
    });

    const panelDOMNode = $(ReactDOM.findDOMNode(this));
    const layersPanelInnerScrollDOMNode = panelDOMNode.find(
      '.layers-panel .custom-scroll .inner-container',
    );
    const selectedItemDOMNode = panelDOMNode.find('.selected');
    if (
      selectedItemDOMNode.length > 0 &&
      selectedItemDOMNode.offset().top > 519
    ) {
      layersPanelInnerScrollDOMNode.scrollTop(
        selectedItemDOMNode.offset().top -
          layersPanelInnerScrollDOMNode.offset().top -
          55,
      );
    }
  }

  componentWillUnmount() {
    this.props.destroy({
      treeNodesContext: this.props.treeNodesContext,
    });
  }

  render() {
    return (
      <TpaSettingsPanelFrame
        title={translate('Layers_Title')}
        width={288}
        height={579}
        right="300px"
        left="auto"
        top="50px"
        shouldAddScroll={true}
        onHelpClicked={this.props.openHelpCenter}
        onClose={this.props.close}
        panelName="panels.toolPanels.layersPanel"
        className="layers-panel"
      >
        <div
          className={cx('layers-panel', {
            'with-wbu-icons': experiment.isOpen('se_newIconsForLayers'),
          })}
        >
          {(this.props.treeProps.header?.hasChildNodes ||
            this.props.treeProps.header?.emptyStateData) && (
            <>
              <Node key="header" {...this.props.treeProps.header} />
              <div key="spacer1" className="spacer" />
            </>
          )}

          {this.props.treeProps.page.hasChildNodes && (
            <>
              <Node key="page" {...this.props.treeProps.page} />
              <div key="spacer2" className="spacer" />
            </>
          )}

          {(this.props.treeProps.footer?.hasChildNodes ||
            this.props.treeProps.footer?.emptyStateData) && (
            <Node key="footer" {...this.props.treeProps.footer} />
          )}
        </div>
      </TpaSettingsPanelFrame>
    );
  }
}

const Connected = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
  undefined,
  false,
)(LayersPanel);

const LayersPanelOuter: React.FC = () => {
  const nodesContext = useTreeNodesContext();

  return <Connected treeNodesContext={nodesContext} />;
};

const ConnectedConditional = hoc.withConditionalRender(
  hoc.STORES.EDITOR_API,
  shouldShowLayersPanel,
)(LayersPanelOuter);

ConnectedConditional.pure = LayersPanel;

export default ConnectedConditional;
