'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

//TODO get rid of it when AddPanel Test Driver will be able to find not only component-items
const structure = {
    componentType: 'wysiwyg.viewer.components.HtmlComponent'
}

const items = [{
    id: 'Other_Marketing_Integrations_Verification_Code',
    title: 'add_section_heading_marketing_tools_verification_code_title',
    desc: 'add_section_heading_marketing_tools_verification_code_description',
    image: 'addPanelData/sections/marketingIntegrationsSection/verificationCode.png',
    symbol: 'arrowWithStates',
    helpId: '',
    learnMoreText: 'add_section_heading_marketing_tools_verification_code_description_link',
    accountSettingsPath: 'seo-tools/verification',
    structure
}, {
    id: 'Other_Marketing_Integrations_Custom_Code',
    title: 'add_section_heading_marketing_tools_custom_code_title',
    desc: 'add_section_heading_marketing_tools_custom_code_description',
    image: 'addPanelData/sections/marketingIntegrationsSection/customCode.png',
    symbol: 'arrowWithStates',
    helpId: '',
    learnMoreText: 'add_section_heading_marketing_tools_custom_code_description_link',
    accountSettingsPath: 'manage-website/embeds',
    structure
}, {
    id: 'Other_Marketing_Integrations_Google_Analytics',
    title: 'add_section_heading_marketing_tools_google_analytics_title',
    desc: 'add_section_heading_marketing_tools_google_analytics_description',
    image: 'addPanelData/sections/marketingIntegrationsSection/googleAnalytics.png',
    symbol: 'arrowWithStates',
    accountSettingsPath: 'marketing-integration/widget/googleAnalytics',
    structure
}, {
    id: 'Other_Marketing_Integrations_Google_Ads',
    title: 'add_section_heading_marketing_tools_google_ads',
    desc: 'add_section_heading_marketing_tools_google_ads_description',
    image: 'addPanelData/sections/marketingIntegrationsSection/googleAds.png',
    symbol: 'arrowWithStates',
    helpId: 'f90e0b4c-a27c-4055-8d78-40740b58f56f',
    learnMoreText: 'add_section_heading_marketing_tools_google_ads_description_link',
    accountSettingsPath: 'marketing-integration/widget/gtag',
    structure
}, {
    id: 'Other_Marketing_Integrations_Google_Tag_Manager',
    title: 'add_section_heading_marketing_tools_google_tag_manager_title',
    desc: 'add_section_heading_marketing_tools_google_tag_manager_description',
    image: 'addPanelData/sections/marketingIntegrationsSection/googleTagManager.png',
    symbol: 'arrowWithStates',
    helpId: '4e1084f1-e898-4515-b80e-95ec4f00c739',
    learnMoreText: 'add_section_heading_marketing_tools_google_tag_manager_description_link',
    accountSettingsPath: 'marketing-integration/widget/googleTagManager',
    structure
}, {
    id: 'Other_Marketing_Integrations_Facebook_Pixel',
    title: 'add_section_heading_marketing_tools_facebook_pixel_title',
    desc: 'add_section_heading_marketing_tools_facebook_pixel_description',
    image: 'addPanelData/sections/marketingIntegrationsSection/facebookPixel.png',
    symbol: 'arrowWithStates',
    helpId: 'b15fd3ce-4380-418e-933b-311ea41d5d77',
    learnMoreText: 'add_section_heading_marketing_tools_facebook_pixel_description_link',
    accountSettingsPath: 'marketing-integration/widget/facebookPixel',
    structure
}, {
    id: 'Other_Marketing_Integrations_Yandex_Metrica',
    title: 'add_section_heading_marketing_tools_yandex_metrica_title',
    desc: 'add_section_heading_marketing_tools_yandex_metrica_description',
    image: 'addPanelData/sections/marketingIntegrationsSection/yandex.png',
    symbol: 'arrowWithStates',
    helpId: '2b0b0ef5-0f5a-4481-8046-e715fd4b222b',
    learnMoreText: 'add_section_heading_marketing_tools_yandex_metrica_description_link',
    accountSettingsPath: 'marketing-integration/widget/yandexMetrica',
    structure
}]

module.exports = {
    type: addPanelDataConsts.SECTIONS_TYPES.ACCOUNT_SETTINGS_LIST,
    hide: false,
    title: 'add_section_heading_marketing_tools',
    presetTitle: 'add_section_heading_marketing_tools',
    subNavigationTitle: 'add_section_subnav_label_marketing_tools',
    automationId: 'add-panel-section-marketingIntegrationsSection',
    subNavigationHide: false,
    showSectionHeader: true,
    props: {
        items
    }
}
