// @ts-nocheck
import _ from 'lodash';
import closeButtonsConstants from '../constants/closeButtonsConstants';

const constants = closeButtonsConstants;

function hasButton(componentType, popupPointer, editorAPI) {
  const pointers = editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
    componentType,
    popupPointer,
  );

  return !_.isEmpty(pointers);
}

function getCloseButtonsInfo(popupPointer, editorAPI) {
  const has = {};

  has[constants.ICON] = hasButton(constants.ICON_TYPE, popupPointer, editorAPI);
  has[constants.TEXT] = hasButton(constants.TEXT_TYPE, popupPointer, editorAPI);

  return { has };
}

export { getCloseButtonsInfo };
