._text_1gaok_1 {
  text-align: center;
  margin: 18px 0; }

._thumbnails_1gaok_5 {
  margin: auto;
  max-width: 264px; }

._divider_1gaok_9 {
  margin-bottom: 18px; }

._passwordControl_1gaok_12 {
  margin-bottom: 12px; }
