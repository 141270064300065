import { bi, virtualNumbers } from '@/stateManagement';
import * as coreBi from '@/coreBi';

import type { EditorAPI } from '@/editorAPI';
import type { ThunkAction, Dispatch, StateMapperArgs } from 'types/redux';
import type { DSRead } from 'types/documentServices';

const getEditorAPI: ThunkAction<EditorAPI> = (
  _dispatch,
  _getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    openHelpCenter(helpItem: string, props: AnyFixMe, biHelpParams: AnyFixMe) {
      editorAPI.panelManager.openHelpCenter(helpItem, props, biHelpParams);
    },

    openBusinessNumbers({ biParams }: { biParams: { type: string } }) {
      dispatch(
        bi.actions.event(
          coreBi.events.linkPanel.LINK_PANEL_CLICK_BUSINESS_NUMBERS_LINK,
          biParams,
        ),
      );

      editorAPI.tpa.superApps.openDashboardUrl(
        editorAPI.tpa.superApps.consts.VIRTUAL_NUMBERS.APP_DEF_ID,
        {
          widgetless: true,
          shouldNotCloseOnBlur: true,
          leavePanelsOpen: true,
          origin: 'phone_link_panel',
          onClose: () => {
            dispatch(virtualNumbers.actions.fetchVirtualNumbersData());
          },
        },
      );
    },

    getBusinessNumbersData() {
      dispatch(virtualNumbers.actions.fetchVirtualNumbersData());
    },

    createDefaultData(id: string) {
      return editorAPI.dsRead.data.createItem(id);
    },
  };
};

function isVnInstalled(dsRead: DSRead, editorAPI: EditorAPI) {
  return (
    !dsRead.generalInfo.isFirstSave() &&
    editorAPI.tpa.app.isInstalled(
      editorAPI.tpa.superApps.consts.VIRTUAL_NUMBERS.APP_DEF_ID,
    )
  );
}

export const mapStateToProps = ({
  state,
  dsRead,
  editorAPI,
}: StateMapperArgs) => {
  return {
    virtualNumbersAccountId:
      virtualNumbers.selectors.getVirtualNumbersAccountId(state),
    businessNumber: virtualNumbers.selectors.getBusinessNumber(state),
    hasBusinessNumber: virtualNumbers.selectors.isAccountActive(state),
    isFetchingBusinessNumber: virtualNumbers.selectors.isFetching(state),
    fetchBusinessNumberSucceed: virtualNumbers.selectors.fetchSucceed(state),
    isVNInstalled: isVnInstalled(dsRead, editorAPI),
  };
};
