// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'ResponsiveSection',
  propTypes: {
    registerPayloadResolver: PropTypes.func,
    component: PropTypes.func.isRequired,
  },
  onClickItem(structure, addComponentOptions) {
    this.props.onClick(structure, undefined, undefined, undefined, undefined, {
      responsiveSection: true,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      addComponentOptions: _.assign({}, addComponentOptions, {
        section: this.props.sectionId,
        category: this.props.categoryId,
      }),
    });
  },
  render() {
    return React.createElement(this.props.component, {
      addComponentByClick: this.onClickItem,
      registerPayloadResolver: this.props.registerPayloadResolver,
    });
  },
});
