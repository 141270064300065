import React from 'react';
import { createPagesApi } from '@/pages-wip';
import _ from 'lodash';

import {
  type IItem,
  DropMode,
  type IMenuItem,
  type IOnDrop,
  type ITreeItem,
  MenuList as BaseUIMenuList,
  type IMenuId,
  type IMenuItemId,
  type IAction,
  type ICheckCanDrop,
  type IMenuItemProps,
  menuItemTree,
  type IMenuItemSuffix,
  type IGetMenuItemLocalizations,
} from '@/baseUI';
import {
  fedopsLogger,
  hoc,
  link,
  functionUtils,
  isAdvancedMenuOpen,
} from '@/util';

import {
  createMenuApi,
  type IMenuPages,
  type IMenuRoutes,
} from '../../../API/menuAPI';
import { cleanId, filterVisibleMenuItems } from '../../../utils/utils';
import {
  getCollapsedItems,
  setItemCollapsed,
} from '../../../API/userPreferences';
import { getSymbolName } from '../menuItemSymbols';

import type { EditorAPI } from '@/editorAPI';
import type {
  PagesData,
  MenuItem as DSMenuItem,
} from '@wix/document-services-types';
import type { StateMapperArgs } from 'types/redux';
import { menuBiLogger } from '../../../bi/menuBiLogger';
import { AUTOMATION_IDS } from '../utils/automationIds';
import { createSlotItem, removeSlotItem } from '../utils/slotUtils';

const { createWeakMemo } = functionUtils;

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

const {
  removeFromTree,
  insertAfter,
  insertBefore,
  findItem,
  flattenTree,
  getNestingLevel,
  hasSubnestedItems,
} = menuItemTree;

const {
  linkTypeValidators: { isPageLink, isDynamicPageLink },
} = link;

interface IOwnProps {
  menuId: IMenuId;
  checkCanDrop: ICheckCanDrop<IMenuItemProps, IMenuItem>;
  getActions(item: ITreeItem<IMenuItem>): IAction[];
  getSuffixes?(item: ITreeItem<IMenuItem>): IMenuItemSuffix[];
  setEditingId: (id: IMenuItemId) => void;
  editingId?: IMenuItemId;
  selectedId?: IMenuItemId;
  onItemClick?(id: IMenuItemId): void;
  onItemDoubleClick?(id: IMenuItemId): void;
  onRename(id: IMenuItemId, newName: string): void;
  getItemLocalizations: IGetMenuItemLocalizations;
  maxItemNameLength?: number;
  blockDragAndDrop?: boolean;
  editContainer?(item: DSMenuItem): void;
}

interface IStoreProps {
  menuItems: ITreeItem<IMenuItem>[];
  collapsedIds: IMenuItemId[];
  getSuffixes?(item: ITreeItem<IMenuItem>): IMenuItemSuffix[];
}

interface IDispatchProps {
  onDrop: IOnDrop;
  toggleCollapse(id: IMenuItemId): void;
}

type IConnectedMenuListProps = IOwnProps & IStoreProps & IDispatchProps;

export const MenuList = (props: IConnectedMenuListProps) => {
  const {
    menuItems,
    onDrop,
    toggleCollapse,
    collapsedIds,
    getActions,
    getSuffixes,
    checkCanDrop,
    onRename,
    editingId,
    setEditingId,
    selectedId,
    onItemClick,
    onItemDoubleClick,
    getItemLocalizations,
    maxItemNameLength,
    blockDragAndDrop,
  } = props;

  const handleRenameCancel = () => {
    setEditingId(null);
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.RENAME_ITEM,
    );
  };

  const handleGetActions = (item: ITreeItem<IMenuItem>) => getActions(item);

  const handleRename = (id: IMenuItemId, newName: string) => {
    onRename(id, newName);
    setEditingId(null);
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.RENAME_ITEM,
    );
  };

  return (
    <BaseUIMenuList
      blockDragAndDrop={blockDragAndDrop}
      items={menuItems}
      collapsedIds={collapsedIds}
      editingId={editingId}
      onDrop={onDrop}
      getActions={handleGetActions}
      getSuffixes={getSuffixes}
      toggleCollapse={toggleCollapse}
      onRename={handleRename}
      onRenameCancel={handleRenameCancel}
      onContextMenuOpen={menuBiLogger.logMenuItemContextMenuOpened}
      checkCanDrop={checkCanDrop}
      onItemDoubleClick={onItemDoubleClick}
      onItemClick={onItemClick}
      selectedMenuItemId={selectedId}
      getItemLocalizations={getItemLocalizations}
      maxItemNameLength={maxItemNameLength}
      itemAutomationId={AUTOMATION_IDS.ITEM.SELF}
    />
  );
};

const createMenuItemsConverter = (
  homePageId: string,
  pagesData: IMenuPages,
  routersData: IMenuRoutes,
  editContainer?: (item: DSMenuItem) => void,
) => {
  const convertMenuItems = (menuItems: DSMenuItem[]): IMenuItem[] =>
    menuItems.map((item) => {
      const page: PagesData = isPageLink(item.link)
        ? pagesData[cleanId(item.link.pageId)]
        : undefined;
      const router = isDynamicPageLink(item.link)
        ? routersData[item.link.routerId]
        : undefined;

      return {
        ...item,
        symbol: getSymbolName(item, homePageId, page, router),
        items: item.slot
          ? [createSlotItem(item, editContainer)]
          : convertMenuItems(item.items),
      };
    });

  return convertMenuItems;
};

const suffixesCache = new WeakMap();

const mapStateToProps = (
  { editorAPI }: StateMapperArgs,
  ownProps: IOwnProps,
): IStoreProps => {
  const menuAPI = createMenuApi(editorAPI);
  const pagesAPI = createPagesApi(editorAPI);

  const { menuId } = ownProps;

  const currentMenu = menuAPI.getMenu(menuId);
  const menuItems = currentMenu?.items || [];
  const homepageId = pagesAPI.getHomePageId();

  const pagesData = menuAPI.getMenuPagesInfo(menuId);
  const routersData = menuAPI.getMenuRoutersInfo(menuId);

  const convertedMenuItems = _.flow(
    filterVisibleMenuItems,
    createMenuItemsConverter(
      homepageId,
      pagesData,
      routersData,
      ownProps.editContainer,
    ),
  )(menuItems, editorAPI.isMobileEditor());

  const convertedItemsTree = flattenTree(convertedMenuItems as IItem[]);

  const collapsedIds = getCollapsedItems(editorAPI, menuId);

  const subnestingWarningContents = menuAPI.getSubnestingSupportExcuse();
  const isSubnestingSupportedByComponent = !subnestingWarningContents;

  const warningSuffix = {
    tooltipText: subnestingWarningContents?.content,
    symbolName: 'warning',
    helpText: subnestingWarningContents?.linkText,
    openHelpCenter: () =>
      editorAPI.panelManager.openHelpCenter(subnestingWarningContents.helpId),
  };

  const tinyWarningSuffix = { symbolName: 'warning_tiny' };

  const getSuffixes = (item: ITreeItem<IMenuItem>): IMenuItemSuffix[] => {
    let suffixes = ownProps.getSuffixes?.(item) || [];

    if (isSubnestingSupportedByComponent || !isAdvancedMenuOpen()) {
      return suffixes;
    }

    const nestingLevel = getNestingLevel(
      currentMenu.items as IItem[],
      item as ITreeItem<IItem>,
    );
    if (nestingLevel === 2) {
      suffixes = [...suffixes, warningSuffix];
    }

    if (
      nestingLevel < 2 &&
      hasSubnestedItems(currentMenu.items as IItem[], item as ITreeItem<IItem>)
    ) {
      suffixes = [...suffixes, tinyWarningSuffix];
    }

    return suffixes;
  };

  // use cache outside mapStateToProps
  const cashedGetSuffixes = createWeakMemo(getSuffixes, suffixesCache);

  return {
    menuItems: convertedItemsTree as ITreeItem<IMenuItem>[],
    getSuffixes: cashedGetSuffixes,
    collapsedIds,
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapDispatchToProps = (
  dispatch: AnyFixMe,
  ownProps: IOwnProps,
): IDispatchProps => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const menuAPI = createMenuApi(editorAPI);
  const { replaceMenuItems, moveToDropdown } = menuAPI;
  const { menuId } = ownProps;

  const onDrop: IOnDrop = (
    droppedItem,
    itemDropOnId,
    dropMode = DropMode.on,
  ) => {
    const item = removeSlotItem(droppedItem);

    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.DND_MOVE_ITEM,
    );

    const { items } = menuAPI.getMenu(menuId);
    const dropTarget = itemDropOnId && findItem(items as IItem[], itemDropOnId);

    menuBiLogger.logMenuItemDrop(
      dropMode,
      items,
      item,
      dropTarget as DSMenuItem,
    );

    if (!dropTarget) {
      // @ts-expect-error
      replaceMenuItems(menuId, [...removeFromTree(items, item.id), item]);
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.MANAGE_MENU.DND_MOVE_ITEM,
      );
      editorAPI.history.add('menu items reorder');
      return;
    }

    let newTree: IItem[];

    switch (dropMode) {
      case DropMode.on:
        moveToDropdown(menuId, item.id, itemDropOnId);
        break;
      case DropMode.after:
        // @ts-expect-error
        newTree = removeFromTree(items, item.id);
        // @ts-expect-error
        newTree = insertAfter(newTree, itemDropOnId, item);
        // @ts-expect-error
        replaceMenuItems(menuId, newTree);
        break;
      case DropMode.before:
        // @ts-expect-error
        newTree = removeFromTree(items, item.id);
        // @ts-expect-error
        newTree = insertBefore(newTree, itemDropOnId, item);
        // @ts-expect-error
        replaceMenuItems(menuId, newTree);
        break;
    }

    editorAPI.history.add('menu items reorder');

    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.DND_MOVE_ITEM,
    );
  };

  const toggleCollapse = (id: IMenuItemId) => {
    const isCollapsed = getCollapsedItems(editorAPI, menuId).includes(id);

    const options = {
      menuId,
      itemId: id,
      isCollapsed: !isCollapsed,
    };

    setItemCollapsed(editorAPI, options);
  };

  return {
    onDrop,
    toggleCollapse,
  };
};

export const ConnectedMenuList = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(MenuList);
