import React from 'react';
import { translate } from '@/i18n';
import {
  Composites,
  CustomScroll,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';
import { cx } from '@/util';

export default class extends React.Component {
  static displayName = 'dynamicPageSettingsNoDevModeNew';

  render() {
    return (
      <div className="tab tab-dynamic-page-no-dev-node">
        <CustomScroll>
          <div className={cx({ 'tab-inner': true })}>
            <Composites.RichTextLabeled>
              <TextLabel
                type="T06"
                value={translate(
                  'PLATFORM_PAGE_SETTINGS_DEVTOOLS_DISABLED_TITLE',
                )}
                shouldTranslate={false}
              />
              <RichText>
                <p>
                  {translate(
                    'Platform_Page_Settings_DevTools_Disabled_Paragraph1',
                  )}
                </p>
                <p>
                  {translate(
                    'Platform_Page_Settings_DevTools_Disabled_Paragraph2',
                  )}
                </p>
              </RichText>
            </Composites.RichTextLabeled>
          </div>
        </CustomScroll>
      </div>
    );
  }
}
