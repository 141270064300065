import { initialDesignItem } from './constants';

export const createDefaultDesignWithOverrides = (
  backgroundOverrides: object,
) => ({
  ...initialDesignItem,
  background: {
    ...initialDesignItem.background,
    ...backgroundOverrides,
  },
});
