import type {
  DocumentServiceError,
  DocumentServiceErrorType,
} from 'types/documentServices';

function extractDocumentErrorCode(
  error: unknown | DocumentServiceError,
): number {
  const anyError: AnyFixMe = error;
  return anyError?.document?.errorCode || anyError?.documentServices?.errorCode;
}

function extractDocumentErrorType(
  error: unknown | DocumentServiceError,
): DocumentServiceErrorType {
  const anyError: AnyFixMe = error;
  return anyError?.document?.errorType || anyError?.documentServices?.errorType;
}

export { extractDocumentErrorCode, extractDocumentErrorType };
