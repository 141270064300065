'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const i18n = require('@/i18n')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_FWgalleries',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.VIDEO,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/fullWidthGalleriesSection_en/fullWidthGalleriesSection_en.png',
        'imageHover': null,
        'items': [{
            'id': 'Gallery_Full_Width_Galleries_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.TPAStripSlideshowSkin',
                'layout': {
                    'width': 1905,
                    'height': 500,
                    'x': -463,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'tpa.viewer.components.StripSlideshow',
                'data': {
                    'type': 'ImageList',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'items': [{
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image1'),
                        'uri': 'a9ff3b_38d2c8f4a16e498ab7df390b9f5486db.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 884,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image2'),
                        'uri': 'a9ff3b_967e4680ca98416a99f835f6623e080a.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image3'),
                        'uri': 'a9ff3b_25e87a563520490bb7f843006e11da6f.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 914,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image4'),
                        'uri': 'a9ff3b_375fa2de632e4474a1ed3d964d6d0525.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1016,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image5'),
                        'uri': 'a9ff3b_f69457ef952c4aa4ba805827a02331c4.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }]
                },
                'props': {
                    'type': 'StripSlideshowProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'font': 'arial',
                    'textMode': 'titleAndDescription',
                    'alignText': 'left',
                    'autoplayInterval': 5,
                    'autoplay': false,
                    'transDuration': 1,
                    'transition': 'scroll',
                    'titleColor': '#2F2E2E',
                    'alphaTitleColor': '1',
                    'descriptionColor': '#2F2E2E',
                    'alphaDescriptionColor': '1',
                    'backgroundColor': '#FFFFFF',
                    'alphaBackgroundColor': '0.6'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-color1': '1',
                            'alpha-color2': '1',
                            'alpha-color3': '0.6',
                            'alpha-color4': '1',
                            'alpha-color5': '1',
                            'color1': '#2F2E2E',
                            'color2': '#2F2E2E',
                            'color3': '#FFFFFF',
                            'color4': '#2F2E2E',
                            'color5': '#2F2E2E',
                            'version': '1'
                        },
                        'propertiesSource': {
                            'color1': 'value',
                            'color2': 'value',
                            'color3': 'value',
                            'color4': 'value',
                            'color5': 'value',
                            'version': 'value'
                        }
                    },
                    'componentClassName': 'tpa.viewer.components.StripSlideshow',
                    'pageId': '',
                    'compId': 'i5gkcqjt',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.TPAStripSlideshowSkin'
                },
                'id': 'i5gkcqjt'
            },
            'preset': {
                'rect': {'width': 324, 'height': 130, 'x': 0, 'y': 0},
                'label': 'add_preset_Galleries_Full_Width_Galleries_1',
                'tags': null
            }
        }, {
            'id': 'Gallery_Full_Width_Galleries_2',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.TPAStripShowcaseSkin',
                'layout': {
                    'width': 1905,
                    'height': 500,
                    'x': -463,
                    'y': 1040,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'tpa.viewer.components.StripShowcase',
                'data': {
                    'type': 'ImageList',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'items': [{
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image1'),
                        'uri': 'a9ff3b_38d2c8f4a16e498ab7df390b9f5486db.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 884,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image2'),
                        'uri': 'a9ff3b_967e4680ca98416a99f835f6623e080a.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image3'),
                        'uri': 'a9ff3b_25e87a563520490bb7f843006e11da6f.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 914,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image4'),
                        'uri': 'a9ff3b_375fa2de632e4474a1ed3d964d6d0525.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1016,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image5'),
                        'uri': 'a9ff3b_f69457ef952c4aa4ba805827a02331c4.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }]
                },
                'props': {
                    'type': 'StripShowcaseProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'galleryImageOnClickAction': 'disabled',
                    'cropAndFitStage': 'crop',
                    'imageScale': '4:3',
                    'imageMargin': 5,
                    'showThumbnails': false,
                    'thumbsScale': '4:3',
                    'thumbsMargin': 5,
                    'autoplay': false,
                    'autoplayInterval': 5,
                    'arrowMode': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-color1': '0.5',
                            'alpha-color2': '1',
                            'alpha-color3': '1',
                            'alpha-color4': '1',
                            'alpha-color5': '1',
                            'color1': '#FFFFFF',
                            'color2': '#2F2E2E',
                            'color3': '#2F2E2E',
                            'color4': '#2F2E2E',
                            'color5': '#2F2E2E',
                            'version': '1'
                        },
                        'propertiesSource': {
                            'color1': 'value',
                            'color2': 'value',
                            'color3': 'value',
                            'color4': 'value',
                            'color5': 'value',
                            'version': 'value'
                        }
                    },
                    'componentClassName': 'tpa.viewer.components.StripShowcase',
                    'pageId': '',
                    'compId': 'i5gkcqju',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.TPAStripShowcaseSkin'
                },
                'id': 'i5gkcqju'
            },
            'preset': {
                'rect': {'width': 324, 'height': 130, 'x': 0, 'y': 130},
                'label': 'add_preset_Galleries_Full_Width_Galleries_2',
                'tags': null
            }
        }, {
            'id': 'Gallery_Full_Width_Galleries_3',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.TPACollageSkin',
                'layout': {
                    'width': 1905,
                    'height': 714,
                    'x': -463,
                    'y': 2080,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'tpa.viewer.components.Collage',
                'data': {
                    'type': 'ImageList',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'items': [{
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image1'),
                        'uri': 'a9ff3b_61fe61f3eb4c4adeb5835a166de10689.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image2'),
                        'uri': 'a9ff3b_5f378900670848919283755eb67a5949.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1922,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image3'),
                        'uri': 'a9ff3b_7125cf82b18841c2961ce0a241b78826.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image4'),
                        'uri': 'a9ff3b_1d0b83b8723c4dc39192405fd083142a.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image5'),
                        'uri': 'a9ff3b_749f221316484aef843adff182ce0974.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1923,
                        'height': 1923,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image6'),
                        'uri': 'a9ff3b_f2f5a5f6dac648e8a23cd07144c67530.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image7'),
                        'uri': 'a9ff3b_fccfaf1c059846daa1991638481b27a9.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image8'),
                        'uri': 'a9ff3b_23f64a0cd30b4990bff3d216c8849534.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1920,
                        'alt': ''
                    }]
                },
                'props': {
                    'type': 'CollageProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'font': 'arial',
                    'alignText': 'center',
                    'textMode': 'noText',
                    'galleryImageOnClickAction': 'zoomMode',
                    'minImageSize': 1,
                    'maxImageSize': 1,
                    'margin': 5,
                    'fitToScreenWidth': true,
                    'showNavButtons': true,
                    'orientation': 'vertical',
                    'layoutSeed': 50,
                    'numOfCells': 4,
                    'textColor': '#2F2E2E',
                    'alphaTextColor': '1',
                    'descriptionColor': '#2F2E2E',
                    'alphaDescriptionColor': '1',
                    'backgroundMouseoverColor': '#FFFFFF',
                    'alphaBackgroundMouseoverColor': '0.8'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-color1': '1',
                            'alpha-color2': '1',
                            'alpha-color3': '0.8',
                            'alpha-color4': '1',
                            'alpha-color5': '1',
                            'color1': '#2F2E2E',
                            'color2': '#2F2E2E',
                            'color3': '#FFFFFF',
                            'color4': '#2F2E2E',
                            'color5': '#2F2E2E',
                            'version': '1'
                        },
                        'propertiesSource': {
                            'color1': 'value',
                            'color2': 'value',
                            'color3': 'value',
                            'color4': 'value',
                            'color5': 'value',
                            'version': 'value'
                        }
                    },
                    'componentClassName': 'tpa.viewer.components.Collage',
                    'pageId': '',
                    'compId': 'i5i2u4fn',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.TPACollageSkin'
                },
                'id': 'i5i2u4fn'
            },
            'preset': {
                'rect': {'width': 324, 'height': 130, 'x': 0, 'y': 260},
                'label': 'add_preset_Galleries_Full_Width_Galleries_3',
                'tags': null
            }
        }, {
            'id': 'Gallery_Full_Width_Galleries_4',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.TPACollageSkin',
                'layout': {
                    'width': 1905,
                    'height': 712,
                    'x': -463,
                    'y': 3120,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'tpa.viewer.components.Collage',
                'data': {
                    'type': 'ImageList',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'items': [{
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image1'),
                        'uri': 'a9ff3b_61fe61f3eb4c4adeb5835a166de10689.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image2'),
                        'uri': 'a9ff3b_5f378900670848919283755eb67a5949.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1922,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image3'),
                        'uri': 'a9ff3b_7125cf82b18841c2961ce0a241b78826.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image4'),
                        'uri': 'a9ff3b_1d0b83b8723c4dc39192405fd083142a.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image5'),
                        'uri': 'a9ff3b_749f221316484aef843adff182ce0974.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1923,
                        'height': 1923,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image6'),
                        'uri': 'a9ff3b_f2f5a5f6dac648e8a23cd07144c67530.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image7'),
                        'uri': '8d13be_5a949eb2ad8540b58846be7bc115cdbd.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280
                    }]
                },
                'props': {
                    'type': 'CollageProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'font': 'arial',
                    'alignText': 'center',
                    'textMode': 'noText',
                    'galleryImageOnClickAction': 'zoomMode',
                    'minImageSize': 1,
                    'maxImageSize': 4,
                    'margin': 5,
                    'fitToScreenWidth': true,
                    'showNavButtons': true,
                    'orientation': 'vertical',
                    'layoutSeed': 50,
                    'numOfCells': 8,
                    'textColor': '#2F2E2E',
                    'alphaTextColor': '1',
                    'descriptionColor': '#2F2E2E',
                    'alphaDescriptionColor': '1',
                    'backgroundMouseoverColor': '#FFFFFF',
                    'alphaBackgroundMouseoverColor': '0.8'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-color1': '1',
                            'alpha-color2': '1',
                            'alpha-color3': '0.8',
                            'alpha-color4': '1',
                            'alpha-color5': '1',
                            'color1': '#2F2E2E',
                            'color2': '#2F2E2E',
                            'color3': '#FFFFFF',
                            'color4': '#2F2E2E',
                            'color5': '#2F2E2E',
                            'version': '1'
                        },
                        'propertiesSource': {
                            'color1': 'value',
                            'color2': 'value',
                            'color3': 'value',
                            'color4': 'value',
                            'color5': 'value',
                            'version': 'value'
                        }
                    },
                    'componentClassName': 'tpa.viewer.components.Collage',
                    'pageId': '',
                    'compId': 'i5i2u4fj',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.TPACollageSkin'
                },
                'id': 'i5i2u4fj'
            },
            'preset': {
                'rect': {'width': 324, 'height': 130, 'x': 0, 'y': 390},
                'label': 'add_preset_Galleries_Full_Width_Galleries_4',
                'tags': null
            }
        }],
        'compTypes': ['tpa.viewer.components.StripSlideshow', 'tpa.viewer.components.StripShowcase', 'tpa.viewer.components.Collage']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_FWgalleries'}
}
