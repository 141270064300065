"use strict"
const addPanelDataConsts = require('@/addPanelDataConsts')
module.exports = {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: "add_section_label_rich_text_box",
    subNavigationTitle: "add_section_label_rich_text_box",
    topTitle: "add_section_title_rich_text_box",
    presetTitle: "add_section_heading_rich_text_box",
    tooltipTitle: "add_section_label_rich_text_box_tooltip_title",
    automationId: "add-panel-section-richTextBoxSection",
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
        labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        iconEnabledForComps: {}
    },
    props: {
        image: "addPanelData/sections/developer_en/richTextBoxSection_en.png",
        imageHover: null,
        items: [
            {
                id: "User_Input_RichTextBox_1",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.RichTextBox",
                    layout: {
                        width: 426,
                        height: 288,
                        x: 54,
                        y: 58,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.RichTextBox",
                    data: {
                        type: "RichTextBox",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        value: ""
                    },
                    props: {
                        type: "RichTextBoxProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        allowLinks: true,
                        textAlignment: "left",
                        textPadding: 16,
                        placeholder: "How can we do better next time?",
                        defaultTextType: "placeholderText",
                        toolbarPosition: "top"
                    },
                    mobileHints: {
                        type: "MobileHints",
                        author: "duplicate",
                        originalCompId: "comp-jjcmbqe6",
                        recommendedScale: 1,
                        recommendedWidth: 260,
                        recommendedHeight: 176
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                bgd: "#FFFFFF",
                                bgf: "#FFFFFF",
                                bgh: "#F2FAF9",
                                "boxShadowToggleOn-shd": "false",
                                brd: "#919191",
                                brdd: "#DBDBDB",
                                brde: "#FF4040",
                                brdf: "#000000",
                                brdh: "#525252",
                                brw: "2",
                                brwf: "2",
                                fnt:
                                    "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                iconsColor: "#3B3C40",
                                iconsActiveColor: "#B6F3E8",
                                linkColor: "#B6F3E8",
                                placeHolderTextColor: "#7F808A",
                                rd: "0px 0px 0px 0px",
                                shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                toolbarBgd: "#FFFFFF",
                                toolbarBgf: "#FFFFFF",
                                toolbarBgh: "#F2FAF9",
                                txt: "#7F808A",
                                txtd: "#DBDBDB"
                            },
                            propertiesSource: {
                                bgd: "value",
                                bgf: "value",
                                bgh: "value",
                                brd: "value",
                                brdd: "value",
                                brde: "value",
                                brdf: "value",
                                brdh: "value",
                                brw: "value",
                                brwf: "value",
                                fnt: "value",
                                iconsColor: "value",
                                iconsActiveColor: "value",
                                linkColor: "value",
                                placeHolderTextColor: "value",
                                rd: "value",
                                toolbarBgd: "value",
                                toolbarBgf: "value",
                                toolbarBgh: "value",
                                txt: "value",
                                txtd: "value"
                            }
                        },
                        componentClassName: "wixui.RichTextBox",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.RichTextBox"
                    },
                    activeModes: {},
                    id: "comp-jjcrbghq"
                },
                preset: {
                    rect: {
                        width: 161.898,
                        height: 117.249,
                        x: 15.972,
                        y: 13.794
                    }
                }
            },
            {
                id: "User_Input_RichTextBox_2",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.RichTextBox",
                    layout: {
                        width: 426,
                        height: 288,
                        x: 500,
                        y: 58,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.RichTextBox",
                    data: {
                        type: "RichTextBox",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        value: ""
                    },
                    props: {
                        type: "RichTextBoxProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        allowLinks: true,
                        textAlignment: "left",
                        textPadding: 16,
                        placeholder: "How can we do it better next time?",
                        defaultTextType: "placeholderText",
                        toolbarPosition: "top"
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                "alpha-bgh": "1",
                                bgd: "#FFFFFF",
                                bgf: "#FFFFFF",
                                bgh: "#FFFFFF",
                                "boxShadowToggleOn-shd": "false",
                                brd: "#8C84FA",
                                brdd: "#BCB8FF",
                                brde: "#FF4040",
                                brdf: "#8C84FA",
                                brdh: "#8C84FA",
                                brw: "2",
                                brwd: "2",
                                brwe: "1",
                                brwf: "2",
                                fnt:
                                    "normal normal 700 15px/1.4em futura-lt-w01-book",
                                iconsColor: "#8C84FA",
                                iconsActiveColor: "#4F42FF",
                                linkColor: "#4F42FF",
                                placeHolderTextColor: "#8C84FA",
                                rd: "0px 0px 0px 0px",
                                shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                toolbarBgd: "#FFFFFF",
                                toolbarBgf: "#FFFFFF",
                                toolbarBgh: "#FFFFFF",
                                txt: "#525252",
                                txtd: "#DBDBDB"
                            },
                            propertiesSource: {
                                "alpha-bgh": "value",
                                bgd: "value",
                                bgf: "value",
                                bgh: "value",
                                brd: "value",
                                brdd: "value",
                                brde: "value",
                                brdf: "value",
                                brdh: "value",
                                brw: "value",
                                brwd: "value",
                                brwe: "value",
                                brwf: "value",
                                fnt: "value",
                                iconsColor: "value",
                                iconsActiveColor: "value",
                                linkColor: "value",
                                placeHolderTextColor: "value",
                                rd: "value",
                                toolbarBgd: "value",
                                toolbarBgf: "value",
                                toolbarBgh: "value",
                                txt: "value",
                                txtd: "value"
                            }
                        },
                        componentClassName: "wixui.RichTextBox",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.RichTextBox"
                    },
                    activeModes: {},
                    id: "comp-jjcr8dej"
                },
                preset: {
                    rect: {
                        width: 161.898,
                        height: 117.249,
                        x: 177.87,
                        y: 13.794
                    }
                }
            },
            {
                id: "User_Input_RichTextBox_3",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.RichTextBox",
                    layout: {
                        width: 426,
                        height: 288,
                        x: 54,
                        y: 376,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.RichTextBox",
                    data: {
                        type: "RichTextBox",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        value: ""
                    },
                    props: {
                        type: "RichTextBoxProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        allowLinks: true,
                        textAlignment: "left",
                        textPadding: 16,
                        placeholder: "How can we do it better next time?",
                        defaultTextType: "placeholderText",
                        toolbarPosition: "top"
                    },
                    mobileHints: {
                        type: "MobileHints",
                        author: "duplicate",
                        originalCompId: "comp-jjcqocuq",
                        recommendedScale: 1,
                        recommendedWidth: 260,
                        recommendedHeight: 176
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                "alpha-bgh": "1",
                                bgd: "#FFFFFF",
                                bgf: "#FFFFFF",
                                bgh: "#FEFAFF",
                                "boxShadowToggleOn-shd": "true",
                                brd: "#67D6D6",
                                brdd: "#DBDBDB",
                                brde: "#FF4040",
                                brdf: "#408F8F",
                                brdh: "#59BAB6",
                                brw: "1",
                                brwd: "1",
                                brwe: "1",
                                brwf: "1",
                                brwh: "1",
                                fnt: "italic normal normal 15px/1.4em georgia",
                                iconsColor: "#67D6D6",
                                iconsActiveColor: "#38BABA",
                                linkColor: "#38BABA",
                                placeHolderTextColor: "#67D6D6",
                                rd: "0px 0px 0px 0px",
                                shd:
                                    "0.00px 2.00px 0px 0px rgba(103,214,214,1)",
                                toolbarBgd: "#FFFFFF",
                                toolbarBgf: "#FFFFFF",
                                toolbarBgh: "#FEFAFF",
                                txt: "#A3E6E6",
                                txtd: "#DBDBDB"
                            },
                            propertiesSource: {
                                "alpha-bgh": "value",
                                bgd: "value",
                                bgf: "value",
                                bgh: "value",
                                brd: "value",
                                brdd: "value",
                                brde: "value",
                                brdf: "value",
                                brdh: "value",
                                brw: "value",
                                brwd: "value",
                                brwe: "value",
                                brwf: "value",
                                brwh: "value",
                                fnt: "value",
                                iconsColor: "value",
                                iconsActiveColor: "value",
                                linkColor: "value",
                                placeHolderTextColor: "value",
                                rd: "value",
                                shd: "value",
                                toolbarBgd: "value",
                                toolbarBgf: "value",
                                toolbarBgh: "value",
                                txt: "value",
                                txtd: "value"
                            }
                        },
                        componentClassName: "wixui.RichTextBox",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.RichTextBox"
                    },
                    activeModes: {},
                    id: "comp-jjcrc9p1"
                },
                preset: {
                    rect: {
                        width: 161.898,
                        height: 117.249,
                        x: 15.972,
                        y: 131.043
                    }
                }
            },
            {
                id: "User_Input_RichTextBox_4",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.RichTextBox",
                    layout: {
                        width: 426,
                        height: 288,
                        x: 500,
                        y: 376,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.RichTextBox",
                    data: {
                        type: "RichTextBox",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        value: ""
                    },
                    props: {
                        type: "RichTextBoxProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        allowLinks: true,
                        textAlignment: "left",
                        textPadding: 16,
                        placeholder: "How can we do it better next time?",
                        defaultTextType: "placeholderText",
                        toolbarPosition: "top"
                    },
                    mobileHints: {
                        type: "MobileHints",
                        author: "duplicate",
                        originalCompId: "comp-jjcqxy2v",
                        recommendedScale: 1,
                        recommendedWidth: 280,
                        recommendedHeight: 190
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                "alpha-bgh": "1",
                                bg: "#4BDB7B",
                                bgd: "#E6E6E6",
                                bge: "#FF4040",
                                bgf: "#4BDB7B",
                                bgh: "#5EE08A",
                                "boxShadowToggleOn-shd": "false",
                                brd: "#FFFFFF",
                                brdd: "#FFFFFF",
                                brde: "#FFFFFF",
                                brdf: "#FFFFFF",
                                brdh: "#FFFFFF",
                                brw: "1",
                                brwd: "1",
                                brwe: "1",
                                brwf: "1",
                                brwh: "1",
                                fnt:
                                    "normal normal 700 15px/1.4em bree-w01-thin-oblique",
                                iconsColor: "#FFFFFF",
                                iconsActiveColor: "#009130",
                                linkColor: "#009130",
                                placeHolderTextColor: "#FFFFFF",
                                rd: "6px 6px 6px 6px",
                                shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                toolbarBg: "#4BDB7B",
                                toolbarBgd: "#E6E6E6",
                                toolbarBge: "#FF4040",
                                toolbarBgf: "#4BDB7B",
                                toolbarBgh: "#5EE08A",
                                txt: "#FFFFFF",
                                txtd: "#DBDBDB"
                            },
                            propertiesSource: {
                                "alpha-bgh": "value",
                                bg: "value",
                                bgd: "value",
                                bge: "value",
                                bgf: "value",
                                bgh: "value",
                                brd: "value",
                                brdd: "value",
                                brde: "value",
                                brdf: "value",
                                brdh: "value",
                                brw: "value",
                                brwd: "value",
                                brwe: "value",
                                brwf: "value",
                                brwh: "value",
                                fnt: "value",
                                iconsColor: "value",
                                iconsActiveColor: "value",
                                linkColor: "value",
                                placeHolderTextColor: "value",
                                rd: "value",
                                shd: "value",
                                toolbarBg: "value",
                                toolbarBgd: "value",
                                toolbarBge: "value",
                                toolbarBgf: "value",
                                toolbarBgh: "value",
                                txt: "value",
                                txtd: "value"
                            }
                        },
                        componentClassName: "wixui.RichTextBox",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.RichTextBox"
                    },
                    activeModes: {},
                    id: "comp-jjcr7z5s"
                },
                preset: {
                    rect: {
                        width: 161.898,
                        height: 117.249,
                        x: 177.87,
                        y: 131.043
                    }
                }
            }
        ],
        compTypes: ["wixui.RichTextBox"]
    },
    help: {hide: false, text: "add_section_info_text_rich_text_box"}
}
