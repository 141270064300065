// @ts-nocheck
import _ from 'lodash';
import wixappsViewCacheInvalidationMixin from './wixappsViewCacheInvalidationMixin';

function getAppLogicCustomizationRule(appLogicCustomization) {
  return _.pick(appLogicCustomization, [
    'fieldId',
    'forType',
    'format',
    'key',
    'view',
  ]);
}

export default {
  mixins: [wixappsViewCacheInvalidationMixin],

  getAppLogicCustomizations() {
    return this.getAppLogicCustomizationsLink().value;
  },

  getAppLogicCustomizationsLink() {
    return this.linkedData('appLogicCustomizations');
  },

  getAppLogicCustomization(customization) {
    const customizations = this.getAppLogicCustomizations();
    const customizationRule = getAppLogicCustomizationRule(customization);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return _.find(customizations, customizationRule);
  },

  _updateAppLogicCustomizations(customizations, skipUndoRedoStack) {
    const customizationsLink = this.getAppLogicCustomizationsLink();
    customizationsLink.requestChange(customizations, skipUndoRedoStack);
  },

  setAppLogicCustomization(customization, skipUndoRedoStack) {
    const customizations = this.getAppLogicCustomizations();
    const customizationRule = getAppLogicCustomizationRule(customization);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const customizationIndex = _.findIndex(customizations, customizationRule);
    if (customizationIndex === -1) {
      let precedingCustomizationIndex;
      if (customization.format === '*') {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        const desktopCustomization = _.assign({}, customizationRule, {
          format: '',
        });
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
        let desktopCustomizationIndex = _.findIndex(
          customizations,
          desktopCustomization,
        );
        if (desktopCustomizationIndex === -1) {
          desktopCustomizationIndex = 0;
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        const mobileCustomization = _.assign({}, customizationRule, {
          format: 'Mobile',
        });
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
        let mobileCustomizationIndex = _.findIndex(
          customizations,
          mobileCustomization,
        );
        if (mobileCustomizationIndex === -1) {
          mobileCustomizationIndex = 0;
        }

        // Common customization should be before the format-specific one.
        precedingCustomizationIndex = Math.min(
          desktopCustomizationIndex,
          mobileCustomizationIndex,
        );
      } else {
        // Format-specific customization should be after the common one.
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        const commonRule = _.assign({}, customizationRule, { format: '*' });
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
        precedingCustomizationIndex = _.findIndex(customizations, commonRule);
        if (precedingCustomizationIndex !== -1) {
          precedingCustomizationIndex += 1;
        }
      }

      // If it's a new customization, put it at the right place.
      // If there's no preceding customization, the customization will be put before others.
      // It's the same like the old editor does.
      customizations.splice(precedingCustomizationIndex, 0, customization);
    } else {
      // If it's an existing customization, just replace it.
      customizations[customizationIndex] = customization;
    }

    this.invalidateViewCache();
    this._updateAppLogicCustomizations(customizations, skipUndoRedoStack);
    this.getEditorAPI().dsActions.wixapps.classics.forceUpdate();
  },

  removeAppLogicCustomization(customization, skipUndoRedoStack) {
    const customizations = this.getAppLogicCustomizations();
    const customizationRule = getAppLogicCustomizationRule(customization);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const customizationIndex = _.findIndex(customizations, customizationRule);
    if (customizationIndex !== -1) {
      customizations.splice(customizationIndex, 1);
      this._updateAppLogicCustomizations(customizations, skipUndoRedoStack);
    }
  },
};
