import { sections as sectionsUtils } from '@/util';
import constants from '@/constants';
import { PAGE_SECTIONS_EDITOR_DATA_NAMESPACE } from '../constants';
import { validateGrouping } from '../grouping/groupingUtils';

import {
  getDesktopRefById,
  isAnchor,
  isSection,
  isAppController,
} from './documentServices';

import type {
  CompRef,
  DocumentServicesObject,
  PagesData,
} from 'types/documentServices';

export function hasSectionsInPageRoot(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
) {
  return documentServices.components
    .getChildren(pageRef)
    .some((compRef) => isSection(documentServices, compRef));
}

export function hasAnchorsAsChild(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
) {
  return documentServices.components
    .getChildren(compRef)
    .some((compRef) => isAnchor(documentServices, compRef));
}

export function hasAnchorsInsideSections(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
) {
  return documentServices.components
    .getChildren(pageRef)
    .some((compRef) => hasAnchorsAsChild(documentServices, compRef));
}

function isPageVisibleInMenu(
  documentServices: DocumentServicesObject,
  pageData: PagesData,
) {
  const { tpaPageId, tpaApplicationId, isPopup } = pageData;
  const isPageMarkedAsHideFromMenu =
    documentServices.tpa.isPageMarkedAsHideFromMenu(
      tpaApplicationId,
      tpaPageId,
    );

  return !isPopup && !isPageMarkedAsHideFromMenu;
}

export function getPagesVisibleInMenu(
  documentServices: DocumentServicesObject,
) {
  const pagesDataList = documentServices.pages.getPagesData() ?? [];

  return pagesDataList
    .filter((pageData: PagesData) =>
      isPageVisibleInMenu(documentServices, pageData),
    )
    .map(({ id }) => getDesktopRefById(documentServices, id));
}

/**
 * Get Static, Dynamic or TPA pages which visible in menu and has root components that can be wrapped into sections
 * @param documentServices
 */
export function getPagesToMigrateIntoSections(
  documentServices: DocumentServicesObject,
): CompRef[] {
  return getPagesVisibleInMenu(documentServices).filter((pageRef) => {
    const rootComponents = documentServices.components
      .getChildren(pageRef)
      .filter((compRef: CompRef) =>
        sectionsUtils.isSectionableComponent(documentServices, compRef),
      );
    const isEmptyPage = rootComponents.length === 0;
    const isFullSectionedPage = rootComponents.every(
      (compRef: CompRef) =>
        isSection(documentServices, compRef) ||
        isAnchor(documentServices, compRef),
    );

    if (isEmptyPage || isFullSectionedPage) {
      return false; // ignore empty page and page with sections
    }

    return true; // Static, Dynamic or TPA pages which visible in menu
  });
}

function isPageToMigrateAnchors(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
) {
  const desktopCompRef = pageRef;
  const mobileCompRef = documentServices.components.getMobileRef(pageRef);

  return (
    (hasSectionsInPageRoot(documentServices, desktopCompRef) &&
      hasAnchorsAsChild(documentServices, desktopCompRef)) ||
    (hasSectionsInPageRoot(documentServices, mobileCompRef) &&
      hasAnchorsAsChild(documentServices, mobileCompRef))
  );
}

export function hasPagesWithAnchorsInRoot(
  documentServices: DocumentServicesObject,
  pagesToCheck: CompRef[],
) {
  // TODO: investigate do we need mobile check here
  return pagesToCheck.some((pageRef) => {
    const mobilePageRef = documentServices.components.getMobileRef(pageRef);

    return (
      hasAnchorsAsChild(documentServices, pageRef) ||
      hasAnchorsAsChild(documentServices, mobilePageRef)
    );
  });
}

export function hasPagesWithSectionsInRoot(
  documentServices: DocumentServicesObject,
  pagesToCheck: CompRef[],
) {
  return pagesToCheck.some((pageRef) =>
    documentServices.components
      .getChildren(pageRef)
      .some((compRef) => isSection(documentServices, compRef)),
  );
}

export function hasPagesWithoutSectionsInRoot(
  documentServices: DocumentServicesObject,
  pagesToCheck: CompRef[],
) {
  return pagesToCheck.some((pageRef) =>
    documentServices.components.getChildren(pageRef).some(
      (compRef) =>
        !isSection(documentServices, compRef) &&
        !isAnchor(documentServices, compRef) &&
        !isAppController(documentServices, compRef) && // in order to ignore redundant editor2fix migration
        sectionsUtils.isSectionableComponent(documentServices, compRef),
    ),
  );
}

export function hasPagesWithAnchors(
  documentServices: DocumentServicesObject,
): boolean {
  return Boolean(
    documentServices.components.get.byType(constants.COMP_TYPES.ANCHOR).length,
  );
}

export function getPagesToMigrateAnchors(
  documentServices: DocumentServicesObject,
) {
  return getPagesVisibleInMenu(documentServices).filter((pageRef) =>
    isPageToMigrateAnchors(documentServices, pageRef),
  );
}

export function hasPageLabellingData(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
): boolean {
  return Boolean(
    documentServices.components.features?.get(
      pageRef,
      PAGE_SECTIONS_EDITOR_DATA_NAMESPACE,
    )?.sections,
  );
}

export function getUnlabeledPages(
  documentServices: DocumentServicesObject,
): CompRef[] {
  const pagesRefs = getPagesToMigrateIntoSections(documentServices);

  return pagesRefs.filter((pageRef: CompRef) => {
    const grouping = documentServices.components.features.get(
      pageRef,
      PAGE_SECTIONS_EDITOR_DATA_NAMESPACE,
    )?.sections;

    if (!grouping) {
      return true;
    }

    const rootComponents = documentServices.components
      .getChildren(pageRef)
      .filter(
        (compRef: CompRef) =>
          sectionsUtils.isSectionableComponent(documentServices, compRef) &&
          !isAnchor(documentServices, compRef),
      );

    return !validateGrouping(documentServices, grouping, rootComponents);
  });
}

export function getAllPages(
  documentServices: DocumentServicesObject,
): CompRef[] {
  const pagesDataList = documentServices.pages.getPagesData() ?? [];

  return pagesDataList.map(({ id }) => getDesktopRefById(documentServices, id));
}

export function getIsTPAPage(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
): boolean {
  const { tpaApplicationId, managingAppDefId } =
    documentServices.pages.data.get(pageRef.id) ?? {};

  return Boolean(tpaApplicationId || managingAppDefId);
}
