import { runMigration } from './runMigration';
import { isSiteCreationSiteWidthMigrationEnabled } from '../utils/experiments';
import { skipMigrationAndUpdateFixedState } from './skipMigration';
import { shouldRunMigration } from './shouldRunMigration';

import type { Scope } from '../scope';

export const initAfterSiteCreationDone = async (scope: Scope) => {
  const isNewSite = true;

  await window.siteCreationController.getIsEditorFullyLoadPromise();

  if (
    isSiteCreationSiteWidthMigrationEnabled() &&
    shouldRunMigration(scope, isNewSite)
  ) {
    await runMigration(scope);
  } else {
    skipMigrationAndUpdateFixedState(scope);
  }
};
