const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_30",
  preset: { rect: { width: 82, height: 147, x: 242, y: 816 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        properties: {
          "$st-css":
            ":import{\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: StylableButton\n}\n.root{\n    -st-extends: StylableButton;\n    transition: all 0.2s ease, visibility 0s;\n    background: rgba(255,255,255,0);\n    border-radius: 0px;\n    border: 3px solid rgb(255, 87, 0)\n}\n.root:hover {\n    background: rgba(255,223,201,0.44);\n}\n.root:disabled {\n    background: #DBDBDB;\n}\n.root[disabled]{\n    background: #E2E2E2\n}\n.root[disabled]::label{\n    color: #8F8F8F\n}\n.root[disabled]::icon{\n    fill: #8F8F8F\n}\n.root::container{\n    transition: inherit\n}\n.root::label{\n    transition: inherit;\n    display: initial;\n    color: #FF5700;\n    font-size: 37px;\n    font-family: oswald-extralight,oswald,sans-serif;\n    text-align: center;\n    letter-spacing: 0.2em;\n    font-weight: 700\n}\n.root::icon{\n    transition: inherit;\n    fill: #FFFFFF;\n    width: 23px;\n    height: 23px;\n    display: none\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "15r-144",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "7ud-83",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_sale"),
      svgId: "11062b_a9f863f00acf4ed6a7113f0eb05f6884.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-kmeg3wrn",
    layout: {
      x: 228,
      fixedPosition: false,
      y: 859,
      scale: 1,
      height: 54,
      rotationInDegrees: 90,
      width: 114,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "hmj-1770", pageId: "c1dmp" },
    },
    metaData: { sig: "inq-345", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
