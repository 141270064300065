import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import experiment from 'experiment';

export const moveWidgetPluginAction = {
  label: 'PLATFORM_Widget_Slots_Move_Plugin_RCM_text',
  enabled: (editorAPI: EditorAPI, widgetCompRef: CompRef) => {
    if (experiment.isOpen('se_widgetPlugins_movePluginToAnotherSlot')) {
      return true;
    }

    const slotPlaceholderRef =
      editorAPI.platform.widgetPlugins.getSlotPlaceholderRefByContentRef(
        widgetCompRef,
      );

    return editorAPI.platform.widgetPlugins.isSlotContentMovable(
      slotPlaceholderRef,
    );
  },
  onClick(editorAPI: EditorAPI, widgetCompRef: CompRef) {
    editorAPI.closeRightClickMenu();

    const slotSelectionPanelName = experiment.isOpen(
      'se_widgetPlugins_movePluginToAnotherSlot',
    )
      ? 'panels.widgetPanels.slotSelectionExtendedPanel'
      : 'panels.widgetPanels.slotSelectionPanel';

    editorAPI.panelManager.openPanel(slotSelectionPanelName, {
      slotsPlaceholderCompRef:
        editorAPI.platform.widgetPlugins.getSlotPlaceholderRefByContentRef(
          widgetCompRef,
        ),
    });
  },
  automationId: 'widgetPluginMoveToSlot',
  condition: (editorAPI: EditorAPI, widgetCompRef: CompRef | CompRef[]) => {
    const compRef = Array.isArray(widgetCompRef)
      ? widgetCompRef[0]
      : widgetCompRef;

    const baseCondition =
      experiment.isOpen('se_widgetPlugins') &&
      editorAPI.platform.widgetPlugins.isWidgetPluginComponent(compRef);

    if (
      baseCondition &&
      experiment.isOpen('se_widgetPlugins_movePluginToAnotherSlot')
    ) {
      const slotPlaceholderRef =
        editorAPI.platform.widgetPlugins.getSlotPlaceholderRefByContentRef(
          compRef,
        );

      return editorAPI.platform.widgetPlugins.isSlotContentPotentialMovable(
        slotPlaceholderRef,
      );
    }

    return baseCondition;
  },
};
