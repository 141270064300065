import { sections } from '@/util';
import type { Scope } from '../scope';

export const updateIsSectionsEnabledByEditorData = async ({
  editorCoreAPI,
  editorAPI,
}: Scope) => {
  await editorCoreAPI.hooks._dsIsReady.promise;

  // cache site sections editor data
  void sections.getSiteSectionsEditorData(editorAPI);
};
