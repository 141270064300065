// @ts-nocheck
import PropTypes from 'prop-types';
import ReactDOM from 'reactDOM';
import _ from 'lodash';
import constants from '@/constants';
import * as util from '@/util';
import coreUtilsLib from 'coreUtilsLib';
import * as selectionBoxCommons from '../selectionBoxCommons';
import experiment from 'experiment';
import * as focusBoxMapper from './focusBoxMapper';
import type { TabDef } from './focusBoxMapper';
import React from 'react';
import NavControls from '../navControls/navControls';
import { cx } from '@/util';
import type { InteractionDef } from '@/stateManagement';
import MobileOnlyIndicator from '../../mobileOnly/mobileOnlyIndicator';

interface ViewState {
  id: string;
  label: string;
}

//TYPE WAS GENERATED, remove this line when reviewed
interface FocusBoxProps {
  tabIndicationState?: Object;
  navControlsStyle: Object;
  componentUIColor?: $FlowFixMe;
  getBaseDrag: Function;
  isMobileEditor: boolean;
  compInteraction?: InteractionDef | null;
  isShowOnFixedPosition: boolean;
  focusedContainer: Object;
  selectedCompIsChildOfFocused: boolean;
  afterComponentModeActivation: (tabDef: TabDef) => void;
  containerToBeDragged: Object;
  canDragContainer: boolean;
  isMobileOnlyNonNativeComponent: boolean;
  containerLayout: Object;
  previewPosition: Object;
  modes?: Array<$FlowFixMe>;
  tabsDef?: Object;
  openHelpCenter?: (
    helpId: string,
    props?: object,
    biHelpParams?: object,
  ) => void;
  activeModeIds: Object;
  activateComponentMode: Function;
  closePanelByName: Function;
  selectFocusedContainer: Function;
  registerMouseMoveAction: Function;
  getMousePosition: Function;
  selectComponentByCompRef: Function;
  selectedComponents: Object | Array<Object>;
  biError: Function;
  deactivateComponentMode: Function;
  // refreshNavControlsSize?: Function;
  modesAvailableInView?: Array<Object>;
  onNext: Function;
  onPrev: Function;
  shouldShowShowSmallTabs: boolean;
  viewStates: ViewState[];
  onViewStateChange: (state: string) => void;
}

interface NavControlsContent {
  type?: string;
  tabs?: Object | Array<Object>;
  translate?: boolean;
}

class FocusBox extends React.Component<FocusBoxProps> {
  static displayName = 'focusBox';

  static propTypes = {
    tabIndicationState: PropTypes.object,
    navControlsStyle: PropTypes.object.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    componentUIColor: PropTypes.oneOf(_.values(constants.COMPONENT_UI_COLORS)),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    componentUITheme: PropTypes.oneOf(_.values(constants.COMPONENT_UI_THEMES)),
    isShowOnFixedPosition: PropTypes.bool.isRequired,
    focusedContainer: PropTypes.object.isRequired,
    containerToBeDragged: PropTypes.object.isRequired,
    canDragContainer: PropTypes.bool.isRequired,
    containerLayout: PropTypes.object.isRequired,
    previewPosition: PropTypes.object.isRequired,
    modes: PropTypes.array,
    tabsDef: PropTypes.object,
    activeModeIds: PropTypes.object.isRequired,
    activateComponentMode: PropTypes.func.isRequired,
    closePanelByName: PropTypes.func.isRequired,
    selectFocusedContainer: PropTypes.func.isRequired,
    registerMouseMoveAction: PropTypes.func.isRequired,
    selectComponentByCompRef: PropTypes.func.isRequired,
    selectedComponents: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]).isRequired,
    biError: PropTypes.func.isRequired,
    deactivateComponentMode: PropTypes.func.isRequired,
    modesAvailableInView: PropTypes.arrayOf(PropTypes.object),
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
    viewStates: PropTypes.arrayOf(PropTypes.object).isRequired,
    onViewStateChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      mouseOverNavControls: false,
      parentNavControlsIndent: 0,
    };
    this.navControls = React.createRef();
  }

  // componentDidMount() {
  //   this.props.refreshNavControlsSize?.(this.getNavControlsSize());
  // }

  componentDidUpdate(prevProps, prevState) {
    this.updateParentNavControlsIndent(prevState.parentNavControlsIndent);

    // this.props.refreshNavControlsSize?.(this.getNavControlsSize());
  }

  // componentWillUnmount() {
  //   this.props.refreshNavControlsSize?.(false);
  // }

  updateParentNavControlsIndent(prevParentNavControlsIndent): void {
    if (!this.shuldShowParentNav()) {
      return 0;
    }

    const indent = 5;
    const parentNavControlsIndent = this.getNavControlsSize().width + indent;
    if (parentNavControlsIndent !== prevParentNavControlsIndent) {
      this.setState({
        parentNavControlsIndent,
      });
    }
  }

  getFocusedContainerLayout = (container, layout) => {
    return selectionBoxCommons.getBoundingStyle(
      this.props.isShowOnFixedPosition,
      layout,
      [container],
      0,
    );
  };

  getFocusBoxClasses = () => {
    const { componentUIColor, componentUITheme, selectedCompIsChildOfFocused } =
      this.props;

    return {
      focusbox: true,
      'components-ui-color-orange':
        componentUIColor === constants.COMPONENT_UI_COLORS.ORANGE,
      'components-ui-theme-light':
        componentUITheme === constants.COMPONENT_UI_THEMES.LIGHT,
      'focusbox-secondary': selectedCompIsChildOfFocused,
    };
  };

  getFocusBoxStyle = (container, layout) => {
    const containerLayout = this.getFocusedContainerLayout(container, layout);
    if (containerLayout.position === 'fixed') {
      containerLayout.top += this.props.previewPosition.top;
      containerLayout.left += this.props.previewPosition.left;
    }
    return containerLayout;
  };

  getNavControlsSize = () => {
    if (this.navControls.current) {
      const controlsRect = coreUtilsLib.domMeasurements.getBoundingRect(
        ReactDOM.findDOMNode(this.navControls.current),
      );
      return _.pick(controlsRect, ['width', 'height']);
    }
    return false;
  };

  onMouseEnterControls = () => {
    if (!this.state.mouseOverNavControls) {
      this.setState({
        mouseOverNavControls: true,
      });
    }
  };

  onMouseLeaveControls = () => {
    if (this.state.mouseOverNavControls) {
      this.setState({
        mouseOverNavControls: false,
      });
    }
  };

  shouldRenderInteractionNavs = (): boolean => {
    return (
      !this.props.isMobileEditor &&
      !!this.props.compInteraction &&
      this.props.selectedComponents[0].id === this.props.focusedContainer.id
    );
  };

  shouldRenderNavControls = (): boolean => {
    return !!this.getNavControlsContent();
  };

  getNavControlsContent = (): NavControlsContent | null => {
    if (this.shouldRenderInteractionNavs()) {
      return {
        type: constants.UI.NAVIGATION_TYPES.INTERACTION,
      };
    }
    if (this.componentHasViewStates()) {
      return this.getViewStatesDropdownNav();
    }
    if (this.componentHaveModes()) {
      return this.getModesTabsContent();
    }
    if (this.componentHasTabsDef()) {
      return this.props.tabsDef;
    }
    return null;
  };

  getTabClickHandler = () => {
    if (this.componentHaveModes()) {
      return this.handleModeTabClick;
    }
    return this.props.selectFocusedContainer;
  };

  getModesTabsContent = () => {
    return {
      type: constants.UI.NAVIGATION_TYPES.TABS,
      tabs: this.createModeTabs(),
      translate: true,
    };
  };

  getViewStatesDropdownNav = () => {
    return {
      type: constants.UI.NAVIGATION_TYPES.DROPDOWN,
      label: this.props.containerCompDisplayName,
      options: this.props.viewStates,
      onSelect: this.props.onViewStateChange,
      translate: true,
    };
  };

  createModeTabs = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(
      this.props.modesAvailableInView,
      _.partial(createModeTab, this.props.activeModeIds),
    );
  };

  handleModeTabClick = (tab) => {
    if (this.props.activeModeIds[tab.modeId]) {
      this.props.selectComponentByCompRef(this.props.focusedContainer);
      if (experiment.isOpen('applicativeModes')) {
        this.props.deactivateComponentMode(
          this.props.focusedContainer,
          tab.modeId,
        );
      }
    } else {
      this.props.activateComponentMode(this.props.focusedContainer, tab.modeId);
      if (this.props.afterComponentModeActivation) {
        this.props.afterComponentModeActivation(tab);
      }
      const defaultMode = _.head(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/filter
        _.filter(this.props.modes, { type: 'DEFAULT' }),
      );
      if (defaultMode && defaultMode.modeId === tab.modeId) {
        closePanelsForDefaultMode(this.props.closePanelByName);
      }
    }
  };

  getTabIndicationComponent = (): React.Component | null => {
    if (this.props.isMobileOnlyNonNativeComponent) {
      return (
        <MobileOnlyIndicator
          openHelpCenter={this.props.openHelpCenter}
          componentUIColor={this.props.componentUIColor}
          componentUITheme={this.props.componentUITheme}
          className="tab-indicator"
        />
      );
    }
    return null;
  };

  componentHasViewStates = () => {
    return this.props.viewStates?.length > 0;
  };

  componentHaveModes = () => {
    return !_.isEmpty(this.props.modes);
  };

  componentHasTabsDef = (): boolean => {
    return !!this.props.tabsDef;
  };

  shuldShowParentNav = (): boolean => {
    return this.props.parentNavControlsStyle && this.props.tabsDefParent;
  };

  customChildTabs = (): string[] => {
    return [
      ...(this.props.shouldShowShowSmallTabs ? ['small', 'no-radius'] : []),
    ];
  };

  render() {
    const navControlsProps = {
      style: this.props.navControlsStyle,
      content: this.getNavControlsContent(),
      focusedContainer: this.props.focusedContainer,
      compInteraction: this.props.compInteraction,
      biError: this.props.biError,
      componentUIColor: this.props.componentUIColor,
      componentUITheme: this.props.componentUITheme,
      isShowOnSomePages: this.props.isShowOnSomePages,
      selectFocusedContainer: this.props.selectFocusedContainer,
      containerToBeDragged: this.props.containerToBeDragged,
      canDragContainer: this.props.canDragContainer,
      registerMouseMoveAction: this.props.registerMouseMoveAction,
      getMousePosition: this.props.getMousePosition,
      getBaseDrag: this.props.getBaseDrag,
      onTabClick: this.getTabClickHandler(),
      onNext: this.props.onNext,
      onPrev: this.props.onPrev,
      onSelectIndex: this.props.onSelectIndex,
      onMouseEnterControls: this.onMouseEnterControls,
      onMouseLeaveControls: this.onMouseLeaveControls,
      tabIndicationState: this.props.tabIndicationState,
      tabIndicationComponent: this.getTabIndicationComponent(),
    };

    return (
      <>
        {this.shuldShowParentNav() ? (
          <div
            style={this.getFocusBoxStyle(
              this.props.focusedParent,
              this.props.parentContainerLayout,
            )}
            className={cx(this.getFocusBoxClasses())}
          >
            <NavControls
              {...navControlsProps}
              style={{
                ...this.props.parentNavControlsStyle,
                left: Math.max(
                  _.get(this.props.parentNavControlsStyle, 'left', 0),
                  this.state.parentNavControlsIndent,
                ),
              }}
              content={
                this.props.tabsDefParent ? this.props.tabsDefParent : null
              }
              onTabClick={() => {
                this.props.selectParent();
              }}
              componentUITheme={constants.COMPONENT_UI_THEMES.LIGHT}
              customTabs={['clickable']}
            />
          </div>
        ) : null}
        <div
          style={this.getFocusBoxStyle(
            this.props.focusedContainer,
            this.props.containerLayout,
          )}
          className={cx(this.getFocusBoxClasses())}
        >
          {this.shouldRenderNavControls() && (
            <NavControls
              customTabs={this.customChildTabs()}
              ref={this.navControls}
              {...navControlsProps}
            />
          )}
          <div className="focus-frame" />
        </div>
      </>
    );
  }
}

function createModeTab(activeModeIds, mode) {
  let label;
  switch (mode.type) {
    case 'DEFAULT':
      label = 'HOVER_BOX_STATE_REGULAR';
      break;
    case 'HOVER':
      label = 'HOVER_BOX_STATE_HOVER';
      break;
    case 'SCROLL':
      label = `scroll > ${mode.params.scrollPos}`;
      break;
    case 'WIDTH':
      label = `width > ${mode.params.width}`;
      break;
    default:
      label = mode.label || 'unknown-mode';
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  return _.assign({}, mode, {
    active: !!activeModeIds[mode.modeId],
    label,
  });
}

function closePanelsForDefaultMode(closePanelByName) {
  const panelsToClose = ['compPanels.dynamicPanels.animationPanel'];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(panelsToClose, closePanelByName);
}

const ConnectedFocusBox = util.hoc.connect(
  util.hoc.STORES.MOUSE_OPS,
  focusBoxMapper.mapStateToProps,
  focusBoxMapper.mapDispatchToProps,
)(FocusBox);

ConnectedFocusBox.pure = FocusBox;

export default ConnectedFocusBox;
