import _ from 'lodash';
import { fedopsLogger } from '@/util';
import { BasePublicApi } from '@/apilib';
import { files } from '@wix/santa-editor-utils';
import {
  handleAddImage,
  type ImageOrigin,
} from './addCompsHandlers/addImageHandler';
import {
  HELP_IDS,
  NOT_ALLOWED_EDITOR_PASTE_TARGET_TAGS,
  TRANSLATION_KEYS,
} from './consts';
import { isImageMimeType } from '@wix/santa-editor-utils';
import {
  getBiOrigin,
  reportUnsupportedFileTypeToBi,
  reportUploadStartedToBi,
} from './reportToBi';
import { translate } from '@/i18n';

import type { EditorAPI } from '@/editorAPI';
import type { ImageUploadToStageScope } from './imageUploadToStageEntryPoint';
import type { CompRef } from '@wix/document-services-types';
import { browserUtil } from '@/util';

let pasteFunctionToCall: () => void = () => {};

const setPasteFunctionToCall = (fn: () => void) => {
  pasteFunctionToCall = fn;
};

const reportUploadStarted = (
  file: File,
  origin: ImageOrigin,
  filesInBatch: number,
  sectionId?: string,
) => {
  reportUploadStartedToBi(origin, file, filesInBatch, sectionId);
  fedopsLogger.interactionStarted(
    origin === 'paste_file'
      ? fedopsLogger.INTERACTIONS.PASTE_FILE_FROM_KEYBOARD
      : fedopsLogger.INTERACTIONS.DRAG_DROP_FILE_FROM_OS,
  );
};

const reportUnsupportedFileType = (
  editorAPI: EditorAPI,
  file: File,
  origin: ImageOrigin,
  filesInBatch: number,
) => {
  reportUnsupportedFileTypeToBi(origin, file, filesInBatch);

  editorAPI.showUserActionNotification({
    message: translate(TRANSLATION_KEYS.UNSUPPORTED_FILE, {
      FileType: (file.type.split('/')[1] || file.type).toUpperCase(),
    }),
    type: 'error',
    link: {
      caption: translate(TRANSLATION_KEYS.UNSUPPORTED_FILE_READ_MORE),
      onNotificationLinkClick: () =>
        editorAPI.panelManager.openHelpCenter(HELP_IDS.UNSUPPORTED_FILE),
    },
    shouldTranslate: false,
  });
};

const reportUploadDone = (origin: ImageOrigin) => {
  fedopsLogger.interactionEnded(
    origin === 'paste_file'
      ? fedopsLogger.INTERACTIONS.PASTE_FILE_FROM_KEYBOARD
      : fedopsLogger.INTERACTIONS.DRAG_DROP_FILE_FROM_OS,
  );
};

const isSupportedImage = (type: string) => {
  if (type === 'image/heic' || type === 'image/heif') {
    return false;
  }
  return isImageMimeType(type);
};

const uploadAndAddImage = (
  editorAPI: EditorAPI,
  file: File,
  origin: ImageOrigin,
  filesInBatch: number,
  sectionId?: string,
) => {
  reportUploadStarted(file, origin, filesInBatch, sectionId);
  if (isSupportedImage(file.type)) {
    const mediaAuthToken =
      editorAPI.documentServices.generalInfo.media.getMediaAuthToken();
    const uploadFilePromise = files.uploadSiteFile(mediaAuthToken, file, [
      getBiOrigin(origin),
    ]);
    const reader = new FileReader();
    reader.onload = async () => {
      await handleAddImage(
        editorAPI,
        file,
        uploadFilePromise,
        origin,
        filesInBatch,
      );
    };
    reader.readAsDataURL(file);
  } else {
    reportUnsupportedFileType(editorAPI, file, origin, filesInBatch);
    return;
  }
  reportUploadDone(origin);
};

export const bootstrap = ({ editorAPI }: ImageUploadToStageScope) => {
  document.onpaste = async function (event: ClipboardEvent) {
    if (browserUtil.isFirefox()) {
      return;
    }

    const targetElement = event.target as HTMLElement;
    if (
      targetElement?.tagName &&
      NOT_ALLOWED_EDITOR_PASTE_TARGET_TAGS.has(targetElement.tagName)
    ) {
      return;
    }

    const { items } = (event.clipboardData ||
      // @ts-expect-error
      event.originalEvent.clipboardData) as DataTransfer;

    const filesToPaste = Array.from(items).filter(
      (item) => item.kind === 'file',
    );

    if (_.isEmpty(filesToPaste)) {
      pasteFunctionToCall();
      return;
    }

    const itemsToPaste = Array.from(items).filter(
      (item) => item.kind === 'file' && isSupportedImage(item.type),
    );

    if (_.isEmpty(itemsToPaste)) {
      editorAPI.showUserActionNotification({
        message: translate(TRANSLATION_KEYS.UNSUPPORTED_FILE, {
          FileType: Array.from(items)
            .map((file) => (file.type.split('/')[1] || file.type).toUpperCase())
            .join(', '),
        }),
        type: 'error',
        link: {
          caption: translate(TRANSLATION_KEYS.UNSUPPORTED_FILE_READ_MORE),
          onNotificationLinkClick: () =>
            editorAPI.panelManager.openHelpCenter(HELP_IDS.UNSUPPORTED_FILE),
        },
        shouldTranslate: false,
      });
      return;
    }

    itemsToPaste.forEach((itemToPaste: DataTransferItem) => {
      const fileBlob: File = itemToPaste.getAsFile();
      uploadAndAddImage(editorAPI, fileBlob, 'paste_file', itemsToPaste.length);
    });
  };
};

const handleDraggedFiles = (
  editorAPI: EditorAPI,
  filesToUpload: FileList,
  sectionRef: CompRef,
) => {
  Array.from(filesToUpload).forEach((file) =>
    uploadAndAddImage(
      editorAPI,
      file,
      'desktop_file',
      filesToUpload.length,
      sectionRef?.id,
    ),
  );
};

export class ImageUploadToStageApi extends BasePublicApi<ImageUploadToStageScope> {
  setPasteFunctionToCall = setPasteFunctionToCall;
  handleDraggedFiles = handleDraggedFiles;
}
