export const DEFAULT_WITH_FILL_LAYERS_SKIN_NAME =
  'wysiwyg.viewer.skins.screenwidthcontainer.DefaultWithFillLayers';

export const initialDesignItem = {
  type: 'MediaContainerWithDividers',
  background: {
    type: 'BackgroundMedia',
    colorLayers: [
      {
        type: 'SolidColorLayer',
        opacity: 0,
        fill: {
          type: 'SolidColor',
          color: 'color_11',
        },
      },
    ],
    colorOverlay: '',
  },
};
