import type { EditorAPI } from '@/editorAPI';
import type { CompRef, AppData, CompLayout } from 'types/documentServices';
import { AddPresetApiKey, SectionsApiKey } from '@/apis';
import { InstallationOriginType } from '@wix/platform-editor-sdk';
import { canInstallAppSilently } from './silentInstallService/utils';
import experiment from 'experiment';
import { waitForAddedCompRef } from '@/componentsAddUtils';
import { layoutUtils } from '@/layoutUtils';

let containersToAdjust: CompRef[] = [];
let isAddingUnifiedWidget = false;

function registerToAdjustContainerToChild(containerRef: CompRef) {
  containersToAdjust.push(containerRef);
}

function setIsAddingUnifiedWidget(isAdding: boolean) {
  isAddingUnifiedWidget = isAdding;
}

function getIsAddingUnifiedWidget() {
  return isAddingUnifiedWidget;
}

async function adjustSectionHeightToWidget(
  editorAPI: EditorAPI,
  addedSectionRef: CompRef,
  widgetRef: CompRef,
  shouldUseWidgetHeight: boolean,
) {
  if (!addedSectionRef || !widgetRef) {
    return;
  }

  const widgetLayout = editorAPI.components.layout.get_rect(widgetRef);
  const sectionLayout = editorAPI.components.layout.get_rect(addedSectionRef);

  const newContainerLayout = shouldUseWidgetHeight
    ? { height: widgetLayout.height + widgetLayout.y }
    : layoutUtils.getContainerLayoutToFitContent(sectionLayout, widgetLayout);

  editorAPI.components.layout.update(addedSectionRef, newContainerLayout, true);

  await editorAPI.waitForChangesAppliedAsync();

  return { widgetLayout, sectionLayout };
}

async function centerWidgetInSection(
  editorAPI: EditorAPI,
  widgetRef: CompRef,
  widgetLayout: CompLayout,
  sectionLayout: CompLayout,
) {
  const newLayout = {
    x: Math.max(
      (sectionLayout.width - widgetLayout.width) / 2 + widgetLayout.x,
      0,
    ),
  };

  editorAPI.components.layout.update(widgetRef, newLayout);
  await editorAPI.waitForChangesAppliedAsync();
}

const getNewSectionY = (editorAPI: EditorAPI, pageRef: CompRef) => {
  const allSections =
    editorAPI.sections.getPageSectionsSortedByStageOrder(pageRef);
  if (allSections.length === 0) {
    return 0;
  }
  const lastSectionLayout = editorAPI.components.layout.get_rect(
    allSections.pop(),
  );
  return lastSectionLayout.y + lastSectionLayout.height;
};

const removeSectionsAddedOnNavigation = async (
  editorAPI: EditorAPI,
  addedAppSectionRef: CompRef,
  pageSectionsAfterAddingAppSection: CompRef[],
) => {
  const sectionsToRemove = pageSectionsAfterAddingAppSection.filter(
    (section) => section.id !== addedAppSectionRef.id,
  );
  await editorAPI.components.remove(sectionsToRemove);
  await editorAPI.waitForChangesAppliedAsync();
};

const toggleComponentHidden = (
  editorAPI: EditorAPI,
  sectionRef: CompRef,
  shouldHide: boolean,
) => {
  editorAPI.components.transformations.update(sectionRef, {
    hidden: shouldHide,
  });
};

async function addSectionForWidgetToEndOfPage(
  editorAPI: EditorAPI,
  addWidgetCallback: (sectionRef: CompRef) => Promise<CompRef>,
  options: { sectionName?: string; pageRef?: CompRef },
) {
  const sectionsAPI = editorAPI.host.getAPI(SectionsApiKey);
  setIsAddingUnifiedWidget(true);

  const isNavigationFlow = !options.pageRef; // pageRef is not passed in navigation flow, since handled page would always be the current page
  const handledPage = isNavigationFlow
    ? editorAPI.pages.getCurrentPage()
    : options.pageRef;

  const emptyStateSectionRef = sectionsAPI.getEmptyStateSection(handledPage);
  const pageSectionsBeforeAddingAppSection =
    sectionsAPI.getPageSections(handledPage);

  if (emptyStateSectionRef && options.sectionName) {
    sectionsAPI.rename(emptyStateSectionRef, options.sectionName);
  }

  const sectionRef =
    emptyStateSectionRef ||
    (await waitForAddedCompRef(
      sectionsAPI.addBlankSection(handledPage, {
        height: 500,
        y: getNewSectionY(editorAPI, handledPage),
        name: options.sectionName,
      }),
    ));
  await editorAPI.waitForChangesAppliedAsync();

  toggleComponentHidden(editorAPI, sectionRef, true); // make layout adjustments on a hidden section to avoid jumpy experience

  const widgetRef = await addWidgetCallback(sectionRef);
  editorAPI.selection.deselectComponents(widgetRef); // even though section is hidden, selection box is still visible and it created jumpy experience

  const { widgetLayout, sectionLayout } = await adjustSectionHeightToWidget(
    editorAPI,
    sectionRef,
    widgetRef,
    true,
  );
  await centerWidgetInSection(
    editorAPI,
    widgetRef,
    widgetLayout,
    sectionLayout,
  );

  // remove empty section, added on navigation
  if (!pageSectionsBeforeAddingAppSection.length && isNavigationFlow) {
    const pageSectionsAfterAddingAppSection = sectionsAPI.getPageSections(
      editorAPI.pages.getCurrentPage(),
    );
    if (pageSectionsAfterAddingAppSection.length > 1) {
      await removeSectionsAddedOnNavigation(
        editorAPI,
        sectionRef,
        pageSectionsAfterAddingAppSection,
      );
    }
  }

  setIsAddingUnifiedWidget(false);
  toggleComponentHidden(editorAPI, sectionRef, false);
  editorAPI.selection.selectComponentByCompRef(widgetRef);
  return sectionRef;
}

async function createContainerSectionForWidget(
  editorAPI: EditorAPI,
  appDefId: string,
  index: number,
  serialInstallation: boolean,
) {
  if (serialInstallation) {
    const appData: AppData = editorAPI.platform.getAppDataByAppDefId(appDefId);
    if (
      !canInstallAppSilently(serialInstallation, appData) ||
      !experiment.isOpen('se_installWidgetsInSectionSilently')
    ) {
      return null;
    }
    const addPresetAPI = editorAPI.host.getAPI(AddPresetApiKey);
    const sectionRef = await addPresetAPI.sections.addBlankSection(
      InstallationOriginType.SILENT_INSTALL,
      'silent_install',
      appData?.appDefinitionName,
      !index,
    );
    await editorAPI.waitForChangesAppliedAsync();
    registerToAdjustContainerToChild(sectionRef);
    return { containerRef: sectionRef, useRelativeToContainerLayout: true };
  }
}

async function adjustContainersToChildren(editorAPI: EditorAPI) {
  const removeSections = [];
  for (const containerRef of containersToAdjust) {
    const child = editorAPI.components.getChildren(containerRef)[0];
    if (!child) {
      removeSections.push(containerRef);
      continue;
    }
    await adjustSectionHeightToWidget(editorAPI, containerRef, child, false);
    const sectionsAPI = editorAPI.host.getAPI(SectionsApiKey);
    const childDisplayName = editorAPI.components.getDisplayName(child);
    sectionsAPI.rename(containerRef, childDisplayName);
  }
  for (const section of removeSections) {
    if (editorAPI.components.is.exist(section)) {
      await editorAPI.components.remove(section);
      await editorAPI.waitForChangesAppliedAsync();
    }
  }
  containersToAdjust = [];
}

export {
  adjustContainersToChildren,
  createContainerSectionForWidget,
  addSectionForWidgetToEndOfPage,
  getIsAddingUnifiedWidget,
};
