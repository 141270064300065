._tooltipIcon_dhvvd_1 {
  cursor: pointer; }
  ._tooltipIcon_dhvvd_1 circle {
    fill: #eaf7ff; }
  ._tooltipIcon_dhvvd_1 path {
    fill: #2b5672; }

._tooltipCta_dhvvd_8 {
  display: block;
  margin-top: 6px; }
  ._tooltipCta_dhvvd_8._active_dhvvd_11 {
    text-decoration: underline; }

._installedTooltipIcon_dhvvd_14 {
  width: 7px;
  height: 7px; }
  ._installedTooltipIcon_dhvvd_14 path {
    fill: #ffffff; }
