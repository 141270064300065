import { cx } from '@/util';
import React, { type FunctionComponent } from 'react';

interface Props {
  index: number;
  color: string;
  onSelectColor: (color: string, index: number) => void;
  onPreview: (color: string, isMouseOut: boolean) => void;
  selected: string;
  radioGroupId: string;
  selectedIndex: number;
  colorOptionClasses: { [key: string]: boolean };
  automationId: string;
}

const CustomColorRadio: FunctionComponent<Props> = ({
  index,
  onSelectColor,
  onPreview,
  color,
  selected,
  radioGroupId,
  selectedIndex,
  colorOptionClasses,
  automationId,
}) => {
  return (
    <label
      data-aid={automationId}
      data-color={color}
      onMouseEnter={() => {
        onPreview(color, false);
      }}
      onMouseLeave={() => {
        onPreview(selected, true);
      }}
      className="color-option"
    >
      <input
        type="radio"
        name={radioGroupId}
        value={color}
        checked={index === selectedIndex}
        onChange={() => {
          onSelectColor(color, index);
        }}
      />
      <div
        style={{
          backgroundColor: color,
        }}
        className={cx(colorOptionClasses)}
      />
    </label>
  );
};

export default CustomColorRadio;
