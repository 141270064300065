import { createEntryPoint } from '@/apilib';
import { init } from './init';
import { Scope } from './scope';

export const SeoAutoRedirectEntryPoint = createEntryPoint({
  name: 'SeoAutoRedirect',
  Scope,
  initialize(scope) {
    init(scope);
  },
});
