import type { Scope } from '../switchLayoutEntryPoint';
import type { LayoutWithMetadata } from '../switchLayoutStore';
import constants from '@/constants';
import { fedopsLogger, fixedStage } from '@/util';
import * as coreBi from '@/coreBi';
import { panels } from '@/stateManagement';
import { postPaasSwitchDataFixer } from '../switchLayoutPAASUtil';
import { ENTER_SWITCH_LAYOUT } from '../consts';
import type { EditorInteractionName } from 'types/fedops';

export const mapStateToProps = (scope: Scope) => {
  return {
    layoutOptions: scope.store.getLayoutOptions(),
    isSelecting: scope.store.getIsSelecting(),
    selectedId: scope.store.getSelectedLayoutId(),
    originalLayout: scope.store.getOriginalLayout(),
    isLoading: scope.store.getIsLoading(),
    isSwitchLayoutPanelOpen: scope.store.getIsSwitchLayoutPanelOpen(),
    sectionCorrelationId: scope.store.getBiData()?.section_correlation_id || '',
  };
};

const onBeforeSelectedChanged = (
  { editorAPI, store }: Scope,
  fedopsKey: EditorInteractionName,
  layout_type: string,
  components_amount: number,
  newSelectedId: string,
  newSelectedIndex: number,
) => {
  fedopsLogger.interactionStarted(fedopsKey);

  const biData = store.getBiData();

  editorAPI.bi.event(coreBi.events.switchLayout.switchLayoutSelectedLayout, {
    ...biData,
    layout_type,
    components_amount,
    layout_id: newSelectedId,
    layout_index: newSelectedIndex,
  });
  store.setSelectedLayoutId(newSelectedId);
  store.setIsSelecting(true);
};

const onAfterSelectedChanged = async (
  { editorAPI, store }: Scope,
  newSelectedId: string,
  fedopsKey: EditorInteractionName,
) => {
  await editorAPI.waitForChangesAppliedAsync();

  editorAPI.history.debouncedAdd('Switch Layout');

  await editorAPI.waitForChangesAppliedAsync();
  store.setSelectedLayoutHistory(newSelectedId);
  store.setIsSelecting(false);
  fedopsLogger.interactionEnded(fedopsKey);
};

export const mapDispatchToProps = (scope: Scope) => {
  const { editorAPI, editorPaasApi, store } = scope;

  return {
    onOriginalSelected: async (newSelectedId: string) => {
      const originalLayout = store.getOriginalLayout();

      if (
        !store.getIsSelecting() &&
        newSelectedId !== store.getSelectedLayoutId() &&
        newSelectedId === originalLayout.id
      ) {
        onBeforeSelectedChanged(
          scope,
          fedopsLogger.SWITCH_LAYOUT.APPLY_ORIGINAL_LAYOUT,
          'original_layout',
          originalLayout.typeMapping.count,
          newSelectedId,
          -1,
        );

        await editorAPI.history.performUndoUntilLabel(
          ENTER_SWITCH_LAYOUT,
          false,
        );

        onAfterSelectedChanged(
          scope,
          newSelectedId,
          fedopsLogger.SWITCH_LAYOUT.APPLY_ORIGINAL_LAYOUT,
        );
      }
    },
    onLayoutSelected: async (
      layoutOption: LayoutWithMetadata,
      paasPreset: any,
    ) => {
      const newSelectedId = layoutOption.id;
      if (
        !scope.store.getIsSelecting() &&
        newSelectedId !== scope.store.getSelectedLayoutId()
      ) {
        const originalLayout = scope.store.getOriginalLayout();
        onBeforeSelectedChanged(
          scope,
          fedopsLogger.SWITCH_LAYOUT.APPLY_PAAS_LAYOUT,
          'optional',
          originalLayout.typeMapping.count,
          newSelectedId,
          scope.store.getLayoutOptions().indexOf(layoutOption),
        );

        if (layoutOption.isPaasPreset) {
          await editorAPI.components.remove(
            editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(
              originalLayout.compRef,
            ),
          );

          editorAPI.components.transformations.update(originalLayout.compRef, {
            hidden: true,
          });

          editorAPI.components.design.update(
            originalLayout.compRef,
            constants.COMPONENT_DESIGN.EMPTY_BACKGROUND,
            undefined,
            true,
          );

          await editorAPI.waitForChangesAppliedAsync();

          const compRef = await editorPaasApi.addSectionPreset(
            paasPreset,
            originalLayout.compRef,
            0,
          );
          await editorAPI.waitForChangesAppliedAsync();

          if (fixedStage.isFixedStageEnabled()) {
            const PASS_PRESET_SITE_WIDTH = 980;
            editorAPI.components.layout.migrateToCurrentSiteWidth(
              compRef,
              PASS_PRESET_SITE_WIDTH,
            );
            await editorAPI.waitForChangesAppliedAsync();
          }

          editorAPI.components.transformations.update(originalLayout.compRef, {
            hidden: undefined,
          });

          postPaasSwitchDataFixer(
            scope.editorAPI,
            originalLayout.typeMapping.data,
            originalLayout.compRef,
          );
        } else {
          const originalLayoutY =
            editorAPI.documentServices.components.layout.get(
              originalLayout.compRef,
            ).y;
          layoutOption.preset.layout.y = originalLayoutY;

          editorAPI.documentServices.components.migrate(
            originalLayout.compRef,
            layoutOption.preset,
          );
          await editorAPI.waitForChangesAppliedAsync();

          // call update layout to recalculate the height of the section after the switch
          editorAPI.documentServices.components.layout.update(
            originalLayout.compRef,
            {},
          );
        }
        onAfterSelectedChanged(
          scope,
          newSelectedId,
          fedopsLogger.SWITCH_LAYOUT.APPLY_PAAS_LAYOUT,
        );
      }
    },
    openAddSectionPanel: async () => {
      editorAPI.store.dispatch(
        panels.actions.openLeftPanel(
          constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
          { origin: 'switchLayout' },
        ),
      );
      editorAPI.bi.event(
        coreBi.events.switchLayout.switchLayoutExploreMoreDesigns,
        {
          ...store.getBiData(),
        },
      );
    },
    onPresetPreviewBeforeRender: (preset: any) => {
      editorPaasApi.onPresetPreviewBeforeRender(preset);
    },
    originalSectionTooltipOpen: () => {
      editorAPI.bi.event(coreBi.events.switchLayout.switchLayoutInfoTooltip, {
        ...store.getBiData(),
        action: 'hover',
        layout_type: 'original',
      });
    },
    layoutOptionsTooltipOpen: () => {
      editorAPI.bi.event(coreBi.events.switchLayout.switchLayoutInfoTooltip, {
        ...store.getBiData(),
        action: 'hover',
        layout_type: 'optional',
      });
    },
    sendContentScrolledEvent: (scrollTop: number) => {
      editorAPI.bi.event(coreBi.events.switchLayout.switchLayoutScrollDesigns, {
        ...store.getBiData(),
        scrollTop,
      });
    },
  };
};
