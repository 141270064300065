// @ts-nocheck
import constants from '@/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  untranslatable: true,
  mainActions: [
    {
      label: 'gfpp_mainaction_box',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.DESIGN),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.DESIGN),
    },
  ],
  enabledActions: [ACTIONS.DESIGN, ACTIONS.ANIMATION, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId: '9369198c-90a1-4f21-b75d-2089c9759171',
    },
  },
};
