import * as baseUI from '@/baseUI';
import React, { type FunctionComponent } from 'react';
import constants from './constants';

interface Props {
  onAddCustomColor: () => void;
}

const SiteCustomColorsAddBtn: FunctionComponent<Props> = ({
  onAddCustomColor,
}) => {
  return (
    <label className="color-option first-color-in-row">
      <div
        onClick={onAddCustomColor}
        data-aid={constants.AUTOMATION_IDS.BTN.ADD_CUSTOM_COLOR}
        className="add-color-button"
      >
        <baseUI.symbol name="add-custom-color-plus" />
      </div>
    </label>
  );
};

export default SiteCustomColorsAddBtn;
