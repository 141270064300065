import type { Scope } from './baseDragScope';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { MousePosition } from './types';
import {
  type BaseDragPlugin,
  createHooks,
  type EmitPluginHooks,
  type PluginContext,
} from './plugins/common';
import { AttachPlugin } from './plugins/attach';
import { DragAndCopyPlugin } from './plugins/dragAndCopy';
import { DragAndScrollPlugin } from './plugins/dragAndScroll';
import { DragMoveToPlugin } from './plugins/DragMoveToPlugin';
import { NotifyAndFinalPlugin } from './plugins/notifyAndFinal';
import { SnapPlugin } from './plugins/snapTo';

export interface StartDragParams {
  selectedComp: CompRef[];
  initMousePosition: MousePosition;
  shouldDragAndCopy: boolean;
}

interface State {
  lastMousePosition: MousePosition;
}

export interface DragLifecycleScope {
  editorAPI: EditorAPI;
  state: State;
  pluginHooks: EmitPluginHooks;
  initMousePosition: MousePosition;
  selectedComp: CompRef[];
  globalHooks: Scope['hooks'];
}

export function initDrag(scope: Scope, params: StartDragParams) {
  if (!params.initMousePosition) {
    return;
  }

  const { editorAPI } = scope;

  const { initMousePosition, selectedComp, shouldDragAndCopy } = params;

  const allPlugins: BaseDragPlugin[] = [
    DragAndCopyPlugin,
    AttachPlugin,
    SnapPlugin,
    DragMoveToPlugin,
    NotifyAndFinalPlugin,
    DragAndScrollPlugin,
  ];

  const pluginHooks = createHooks();

  const pluginCtx: PluginContext = {
    hooks: pluginHooks,
    pluginFactoryScope: {
      editorAPI,
      selectedComp,
      initMousePosition,
      shouldDragAndCopy,
    },
  };

  for (const plugin of allPlugins) {
    plugin.init(pluginCtx);
  }

  const dragLifeCycleScope: DragLifecycleScope = {
    state: { lastMousePosition: initMousePosition },
    editorAPI,
    selectedComp,
    initMousePosition,
    pluginHooks,
    globalHooks: scope.hooks,
  };

  scope.hooks.dragStart.fire(); //TODO not correct

  return dragLifeCycleScope;
}
