import type { MapStateToProps } from 'types/redux';

export interface WithPublishDropPanelBiBehaviorStateProps {
  isPublished: boolean;
}

export const mapStateToProps: MapStateToProps<
  WithPublishDropPanelBiBehaviorStateProps,
  {}
> = ({ dsRead }) => {
  const isPublished = dsRead?.generalInfo?.isSitePublished();

  return {
    isPublished,
  };
};
