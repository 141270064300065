import React, { type FunctionComponent } from 'react';
import * as symbols from '@wix/santa-editor-symbols';

interface NotificationProps {
  symbolName?: string;
}

const Notification: FunctionComponent<NotificationProps> = (props) => {
  return (
    <div className="top-bar-center-notification">
      {props.symbolName ? <symbols.symbol name={props.symbolName} /> : null}
      <div className="top-bar-center-notification-content">
        {props.children}
      </div>
    </div>
  );
};

export default Notification;
