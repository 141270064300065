import {
  panels,
  components,
  multilingual,
  selection,
  domMeasurements,
  stateMapperArgsSelectors,
  sections as sectionsState,
} from '@/stateManagement';
import constants from '@/constants';
import { biLogger, sections as sectionsUtils, fedopsLogger } from '@/util';
import {
  addSectionButton,
  sectionEmptyStateClick,
  sectionShowEmptyState,
} from '@wix/bi-logger-editor/v2';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { UNSCALED_DROPZONE_HEIGHT, DEFAULT_SITE_SCALE } from '@/zoomMode';
import { selectors as sectionsSelectors } from '@/sections';
import {
  SwitchLayoutApiKey,
  EditorRestrictionsApiKey,
  QuickEditApiKey,
  FixedStageApiKey,
} from '@/apis';

import { getIsAddSectionButtonOverlapsComponent } from './utils';
import {
  AddSectionBiOrigin,
  SelectSectionBiOrigin,
  ButtonAlignment,
} from './constants';
import { getSectionStageActionsSkin } from '../StageActions/StageActions.skins';
import { getSectionResizeHandleSkin } from '../ResizeHandle/ResizeHandle.skins';
import { getAddSectionButtonSkin } from '../AddSectionButton/AddSectionButton.skins';

import { type SectionBoxOwnProps, SectionBoxType } from './SectionBox';

const {
  mouseSels: { isDraggingSel, isResizingSel },
} = stateMapperArgsSelectors;

import type {
  Dispatch,
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { SectionLikeRect } from '../types';
import type { UISkin } from '../skins/skins.types';

type InteractionName = 'view' | 'hover' | 'click';

type SectionsSelection = ReturnType<typeof getSelection>;

export interface SectionBoxStateProps {
  canSelectSection: boolean;
  isLandingPage: boolean;
  hasSectionAbove: boolean;
  isConnectedToDataBinding: boolean;
  isStageZoomOut: boolean;
  isLeftShrinkedStageZoomOut: boolean;
  isFocused: boolean;
  isResizing: boolean;
  isDragging: boolean;
  isMultilingual: boolean;
  shouldShowEmptyState: boolean;
  isMobileEditor: boolean;
  sectionRef: CompRef;
  sectionType: sectionsUtils.constants.SectionTypes;
  sectionRect: SectionLikeRect;
  siteScale: number;
  stageXOffset: number;
  shouldDisplayResizeHandle: boolean;
  shouldHideLabels: boolean;
  isSectionBothSelectedAndHovered: boolean;
  corvidName: string;
  shouldDisplayPreLoader: boolean;
  isInSwitchLayoutMode: boolean;
  isOverlayVisible: boolean;
  areContainerPointerEventsEnabled: boolean;
  stageActionsSkin: UISkin;
  resizeHandleSkin: UISkin;
  addSectionButtonSkin: UISkin;
  getCenteredContainerStyle: (
    offsetLeft: number,
    defaultLeft: string | number,
  ) => {
    left: string | number;
    transform?: string | undefined;
  };
  labelOffsetRight: string;
}

const addSectionInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.ADD_SECTION,
);

const isMultilingual = (editorAPI: EditorAPI) =>
  editorAPI.language.multilingual.isEnabled() &&
  !multilingual.services.utils.currentIsOriginal(editorAPI);

const EMPTY_STATE_MIN_HEIGHT = 100;

const getSelection = (editorAPI: EditorAPI, ownProps: SectionBoxOwnProps) => {
  const hoveredSection = editorAPI.sections.getHoveredSection();
  const selectedSection = editorAPI.sections.getSelectedSection();
  const focusedSection = editorAPI.sections.getFocusedSection();
  const hoveredHeaderFooter = editorAPI.sections.getHoveredHeaderFooter();
  const selectedHeaderFooter = editorAPI.sections.getSelectedHeaderFooter();
  const focusedHeaderFooter = editorAPI.sections.getFocusedHeaderFooter();
  const isSelectedBox = ownProps.type === SectionBoxType.Selected;
  const isHoveredBox = ownProps.type === SectionBoxType.Hovered;
  const bothSectionBoxesForSameSection = editorAPI.utils.isSameRef(
    selectedSection || focusedSection,
    hoveredSection,
  );
  const isSectionBothSelectedAndHovered = editorAPI.utils.isSameRef(
    selectedSection,
    hoveredSection,
  );
  const sectionRef = isSelectedBox
    ? selectedSection || focusedSection
    : hoveredSection;

  return {
    selectedSection,
    hoveredSection,
    focusedSection,
    hoveredHeaderFooter,
    selectedHeaderFooter,
    focusedHeaderFooter,
    isSelectedBox,
    isHoveredBox,
    sectionRef,
    bothSectionBoxesForSameSection,
    isSectionBothSelectedAndHovered,
  };
};

const shouldShowEmptyState = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  isSelectedBox: boolean,
  bothSelectedAndHovered: boolean,
  isLeftShrinkedStageZoomOut: boolean,
) => {
  const switchLayoutAPI = editorAPI.host.getAPI(SwitchLayoutApiKey);

  const isSmallSection =
    editorAPI.components.layout.getRelativeToScreen(compRef).height <
    EMPTY_STATE_MIN_HEIGHT;

  if (
    isSmallSection ||
    !editorAPI.sections.isBlankSection(compRef) ||
    editorAPI.isMobileEditor() ||
    isMultilingual(editorAPI) ||
    isLeftShrinkedStageZoomOut ||
    switchLayoutAPI.isSwitchLayoutSelecting()
  ) {
    return false;
  }

  const pageSections = editorAPI.sections.getPageSections(
    editorAPI.pages.getFocusedPage(),
  );
  return (
    pageSections.length === 1 && (bothSelectedAndHovered ? isSelectedBox : true)
  );
};

const shouldShowStageActions = (
  editorAPI: EditorAPI,
  {
    sectionRef,
    hoveredHeaderFooter,
    hoveredSection,
    isSelectedBox,
    isSectionBothSelectedAndHovered,
  }: SectionsSelection,
) => {
  if (editorAPI.sections.isBlankSection(sectionRef)) return false;
  if (isSectionBothSelectedAndHovered) {
    return (
      isSelectedBox &&
      editorAPI.sections.isFirstTimeParentSectionLikeFocused(sectionRef)
    );
  }

  if (isSelectedBox) {
    return !hoveredHeaderFooter && !hoveredSection;
  }

  return true;
};

export const mapStateToProps: MapStateToProps<
  SectionBoxStateProps,
  SectionBoxOwnProps
> = (stateMapperArgs, ownProps) => {
  const { editorAPI, state, dsRead } = stateMapperArgs;
  const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);

  const sectionSelection = getSelection(editorAPI, ownProps);

  const {
    selectedSection,
    hoveredSection,
    focusedSection,
    isSelectedBox,
    isHoveredBox,
    sectionRef,
    bothSectionBoxesForSameSection,
    isSectionBothSelectedAndHovered,
  } = sectionSelection;

  const siteScale = editorAPI.getSiteScale();
  const stageXOffset = editorAPI.zoomMode.getStageXOffset();
  const sectionLayout =
    editorAPI.components.layout.getRelativeToScreen(sectionRef);
  const isStageZoomOut = editorAPI.zoomMode.isStageZoomMode();
  const isLeftShrinkedStageZoomOut =
    editorAPI.zoomMode.isLeftShrinkedStageZoomOutActive();
  const isDevMode = editorAPI.developerMode.isEnabled();
  const corvidName = isDevMode
    ? components.selectors.getNickname(sectionRef, dsRead)
    : null;
  const isConnectedToDataBinding =
    selection.selectors.isSelectedCompConnectedToDataBindingController(
      state,
      dsRead,
    );
  const isDragging = isDraggingSel(stateMapperArgs);

  const translateYValue = sectionsUtils.getSectionLikeTranslateY(
    editorAPI,
    sectionRef,
  );

  const sectionRect = {
    width: sectionLayout.width,
    height: sectionLayout.height,
    top: sectionLayout.y + translateYValue,
    bottom: sectionLayout.y + sectionLayout.height + translateYValue,
  };

  const switchLayoutAPI = editorAPI.host.getAPI(SwitchLayoutApiKey);
  const isInSwitchLayoutMode = switchLayoutAPI.isInSwitchLayoutMode();
  const shouldDisplayPreLoader =
    isInSwitchLayoutMode &&
    switchLayoutAPI.isSwitchLayoutSelecting() &&
    switchLayoutAPI.getComponentForSwitchLayout().id ===
      sectionRef.id; /* TODO: check that this is the selected SectionBox (&& isSelectedBox) - now always returns false when zoom out */

  const isLandingPage = editorAPI.pages.isLandingPage(
    editorAPI.pages.getFocusedPageId(),
  );

  const isFocused = isSelectedBox && focusedSection && !selectedSection;

  const getIsOverlayVisible = () => {
    if (!isHoveredBox) return false;

    const isSectionActionsBarHovered =
      sectionsState.selectors.getIsSectionActionsBarHovered(state);

    return !bothSectionBoxesForSameSection || isSectionActionsBarHovered;
  };

  const isOverlayVisible = getIsOverlayVisible();

  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const areContainerPointerEventsEnabled = editorRestrictionsApi.allowed(
    'sections_container-pointer-events.interactive',
  );

  const stageActionsSkin = shouldShowStageActions(editorAPI, sectionSelection)
    ? getSectionStageActionsSkin(editorAPI, sectionRef, {
        selectedSectionLike: selectedSection,
        hoveredSectionLike: hoveredSection,
        focusedSectionLike: focusedSection,
      })
    : undefined;

  const resizeHandleSkin = getSectionResizeHandleSkin(editorAPI, sectionRef, {
    selectedSectionLike: selectedSection,
    hoveredSectionLike: hoveredSection,
    focusedSectionLike: focusedSection,
  });

  const addSectionButtonSkin = getAddSectionButtonSkin(editorAPI, sectionRef, {
    selectedSectionLike: selectedSection,
    hoveredSectionLike: hoveredSection,
    focusedSectionLike: focusedSection,
  });

  return {
    canSelectSection: sectionsSelectors.canSelectSectionLike(stateMapperArgs),
    isLandingPage,
    hasSectionAbove: Boolean(editorAPI.sections.getSectionAbove(sectionRef)),
    isConnectedToDataBinding,
    isStageZoomOut,
    isLeftShrinkedStageZoomOut,
    isFocused,
    sectionRef,
    corvidName,
    sectionType: sectionsUtils.getSectionType(editorAPI, sectionRef),
    sectionRect,
    isDragging,
    isResizing: isResizingSel(stateMapperArgs),
    isMobileEditor: editorAPI.isMobileEditor(),
    isMultilingual:
      editorAPI.language.multilingual.isEnabled() &&
      !multilingual.services.utils.currentIsOriginal(editorAPI),
    shouldShowEmptyState: shouldShowEmptyState(
      editorAPI,
      sectionRef,
      isSelectedBox,
      isSectionBothSelectedAndHovered,
      isLeftShrinkedStageZoomOut,
    ),
    isSectionBothSelectedAndHovered,
    shouldDisplayResizeHandle:
      isHoveredBox &&
      !isDragging &&
      (sectionsUtils.isSectionsControlsRedesignEnabled()
        ? true
        : !isLeftShrinkedStageZoomOut && !isInSwitchLayoutMode) &&
      Boolean(resizeHandleSkin) &&
      editorRestrictionsApi.allowed('sections_resize-handler.visible'),
    siteScale,
    stageXOffset,
    shouldHideLabels:
      isHoveredBox &&
      !isStageZoomOut &&
      !editorRestrictionsApi.allowed('sections_section-box-labels.visible') &&
      editorAPI.utils.isSameRef(selectedSection, hoveredSection),
    shouldDisplayPreLoader,
    isInSwitchLayoutMode,
    isOverlayVisible,
    areContainerPointerEventsEnabled,
    stageActionsSkin,
    resizeHandleSkin,
    addSectionButtonSkin,
    getCenteredContainerStyle: (offsetLeft, defaultLeft) =>
      fixedStageAPI.getCenteredComponentsLeft(offsetLeft, defaultLeft),
    labelOffsetRight: fixedStageAPI.getOffsetRightForComponentsFloatingRight(),
  };
};

export interface SectionBoxDispatchProps {
  logButtonBi: (interactionName: InteractionName) => void;
  openAddPanel: () => void;
  openAddSectionPanel: (
    currentSectionRef: CompRef,
    buttonAlignment: ButtonAlignment,
    origin: 'add-section-button' | 'empty-state-link',
  ) => void;
  selectComponent: (compRef: CompRef) => void;
  logEmptyStateShownBi: () => void;
  logEmptyStateButtonClick: (type: 'add-section' | 'add-element') => void;
  getMouseY: () => number;
  clearHoveredSection: () => void;
  openQuickEditIfNeeded: (origin: string) => void;
  isAddSectionButtonOverlapsComponent: (
    buttonAlignment: ButtonAlignment,
    sectionRef: CompRef,
  ) => boolean;
}

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  SectionBoxDispatchProps,
  SectionBoxOwnProps
> = (dispatch: Dispatch, ownProps) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const switchLayoutAPI = editorAPI.host.getAPI(SwitchLayoutApiKey);

  const getCompDefaultBiFields = () => {
    const { sectionRef } = getSelection(editorAPI, ownProps);

    return editorAPI.bi.getComponentsBIParams([sectionRef])[0];
  };

  return {
    selectComponent: (compRef) => {
      if (
        switchLayoutAPI.isSwitchLayoutLoading() ||
        switchLayoutAPI.isSwitchLayoutSelecting()
      ) {
        return;
      }

      // TODO: use origin from const/enum
      switchLayoutAPI.reloadResults({
        sectionRef: compRef,
        origin: SelectSectionBiOrigin,
      });

      editorAPI.selection.selectComponentByCompRef(compRef);
    },
    logButtonBi: (interactionName: InteractionName) => {
      biLogger.report(
        addSectionButton({
          actionName: interactionName,
        }),
      );
    },
    logEmptyStateShownBi: () => {
      biLogger.report(sectionShowEmptyState(getCompDefaultBiFields()));
    },
    logEmptyStateButtonClick: (type) => {
      biLogger.report(
        sectionEmptyStateClick({
          ...getCompDefaultBiFields(),
          type,
          origin: 'stage',
        }),
      );
    },
    openAddSectionPanel: (currentSectionRef, buttonAlignment, origin) => {
      addSectionInteraction.start();
      const state = editorAPI.store.getState();
      const pageSections = editorAPI.sections.getPageSectionsSortedByStageOrder(
        editorAPI.pages.getCurrentPage(),
      );
      const currentIndex = pageSections.findIndex(
        (sectionRef) => sectionRef.id === currentSectionRef.id,
      );

      const getStageEntryIndex = () => {
        switch (origin) {
          case 'add-section-button':
            const isTopButtonClicked = buttonAlignment === ButtonAlignment.TOP;
            return isTopButtonClicked ? currentIndex : currentIndex + 1;
          case 'empty-state-link':
            return 0;
          default:
            return 0;
        }
      };

      const getBlankSectionEmptyStateIndex = () => {
        if (origin === 'add-section-button') {
          const isTopButtonClicked = buttonAlignment === ButtonAlignment.TOP;
          return isTopButtonClicked ? currentIndex : currentIndex + 1;
        }

        return 1;
      };

      const stageEntryIndex = getStageEntryIndex();
      const emptyStateBlankSectionIndex = getBlankSectionEmptyStateIndex();

      const stageLayout = domMeasurements.selectors.getStageLayout(state);
      const sectionLayout =
        editorAPI.components.layout.get_rect(currentSectionRef);
      const yPositionMap = {
        [ButtonAlignment.TOP]: sectionLayout.y,
        [ButtonAlignment.BOTTOM]: sectionLayout.y + sectionLayout.height,
      };

      editorAPI.documentMode.setExtraSiteHeight(UNSCALED_DROPZONE_HEIGHT);

      const maxScrollY =
        Math.max(0, editorAPI.site.getHeight() - stageLayout.height * 2) +
        UNSCALED_DROPZONE_HEIGHT -
        editorAPI.editorConfig.extraSiteHeight;

      const hasScrollHeightInZoomMode =
        editorAPI.site.getHeight() > stageLayout.height / DEFAULT_SITE_SCALE;

      const desiredScrollY = hasScrollHeightInZoomMode
        ? Math.max(
            0,
            yPositionMap[buttonAlignment] -
              stageLayout.height / 2 -
              stageLayout.top -
              UNSCALED_DROPZONE_HEIGHT,
          )
        : 0;

      const emptyStateSectionReplacement = origin === 'empty-state-link';

      ErrorReporter.breadcrumb('opening addSection panel from SectionBox', {
        stageEntryIndex,
        origin,
        buttonAlignment,
      });

      dispatch(
        panels.actions.openLeftPanel(
          constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
          {
            origin,
            stageEntryIndex,
            emptyStateBlankSectionIndex,
            emptyStateSectionReplacement,
            zoomModeParams: {
              originalScrollY: Math.min(desiredScrollY, maxScrollY),
            },
            onClose: () => addSectionInteraction.end(),
            onSectionAddedToStage: () => addSectionInteraction.end(),
          },
        ),
      );
    },
    openAddPanel: () => {
      editorAPI.panelManager.openPanel(
        constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
        {
          origin: AddSectionBiOrigin,
        },
      );
    },
    getMouseY: () => editorAPI.cursor.get().y,
    clearHoveredSection: () =>
      dispatch(sectionsState.actions.setHoveredSectionLike(null)),
    openQuickEditIfNeeded: (origin: string) => {
      const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);
      const editorRestrictionsApi = editorAPI.host.getAPI(
        EditorRestrictionsApiKey,
      );
      const isQuickEditOnClickInteractive = editorRestrictionsApi.allowed(
        'quick-edit_open-on-click.interactive',
      );
      if (
        quickEditAPI.isQuickEditAvailable() &&
        !quickEditAPI.isQuickEditPanelOpen() &&
        isQuickEditOnClickInteractive
      ) {
        quickEditAPI.openPanel({ origin });
      }
    },
    isAddSectionButtonOverlapsComponent: (buttonAlignment, sectionRef) =>
      getIsAddSectionButtonOverlapsComponent(
        editorAPI,
        buttonAlignment,
        sectionRef,
      ),
  };
};
