import React, { createContext } from 'react';
import type { MenuItemEntry } from './extensionSlotMenuItemTypes';

interface ExtensionSlotMenuAPI {
  registerExtensionSlotItem(item: MenuItemEntry, menuItemKey: string): void;
  unregisterExtensionSlotItem(item: MenuItemEntry, menuItemKey: string): void;
}

export const ExtensionSlotMenuAPIContext = createContext<
  ExtensionSlotMenuAPI | undefined
>(undefined);

export class ExtensionSlotMenuAPIProvider extends React.Component<ExtensionSlotMenuAPI> {
  extensionSlotsMenuItemsAPI: ExtensionSlotMenuAPI = {
    registerExtensionSlotItem: this.props.registerExtensionSlotItem,
    unregisterExtensionSlotItem: this.props.unregisterExtensionSlotItem,
  };

  render() {
    return (
      <ExtensionSlotMenuAPIContext.Provider
        value={this.extensionSlotsMenuItemsAPI}
      >
        {this.props.children}
      </ExtensionSlotMenuAPIContext.Provider>
    );
  }
}
