import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import type {
  ScrollableEditorStageDispatchProps,
  ScrollableEditorStageStateProps,
  ScrollableEditorStageOwnProps,
} from './scrollableEditorStage.types';

export const mapStateToProps: MapStateToProps<
  ScrollableEditorStageStateProps,
  ScrollableEditorStageOwnProps
> = ({ state: editorState, editorAPI }) => {
  const { scrollTo, animateScrollTo } = editorState;

  const isPagesPanelOpen = editorAPI.panelHelpers?.isPagesPanelOpen() ?? false;

  return {
    isScrollingEnabled: editorAPI.scroll.isEnabled(),
    scrollTo,
    animateScrollTo,
    isPagesPanelOpen,
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => ({
  editorAPI,
  state: getState(),
});

export const mapDispatchToProps: MapDispatchToProps<
  ScrollableEditorStageDispatchProps,
  ScrollableEditorStageOwnProps
> = (dispatch) => {
  const { editorAPI } = dispatch(getEditorAPI);

  return {
    clearScrollTo: () => editorAPI.scroll.clearScrollTo(),
    registerNewScrollPosition(newScrollPosition) {
      return editorAPI.scroll.registerNewScrollPosition(newScrollPosition);
    },
    handleStageScroll: (event) => {
      // TODO: refactor it
      if (editorAPI.scroll.handleStageScroll) {
        editorAPI.scroll.handleStageScroll(event);
      }
    },
  };
};
