import * as util from '@/util';
import { EditorAPIKey } from '@/apis';
import type { CompRef } from 'types/documentServices';
import type { Shell } from '@/apilib';

const { checkSingleAndValidate } = util.array;

export function createLayoutersApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  function getNonMasterChildren(
    compRefs: CompRef | CompRef[],
  ): CompRef | false {
    return checkSingleAndValidate(
      editorAPI.dsRead.layouters.getNonMasterChildren,
      compRefs,
    );
  }

  function getParentCompWithOverflowHidden(
    compRefs: CompRef | CompRef[],
  ): CompRef | false {
    return checkSingleAndValidate(
      editorAPI.dsRead.layouters.getParentCompWithOverflowHidden,
      compRefs,
    );
  }

  return {
    getNonMasterChildren,
    getParentCompWithOverflowHidden,
  };
}
