._container_184kg_1 {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 35px;
  justify-content: center;
  min-height: 100%;
  padding: 0 60px; }

._main-title_184kg_11 {
  margin-top: 35px; }

._paragraph-title_184kg_14 {
  margin-bottom: 5px; }

._button-container_184kg_17 {
  margin-bottom: 35px; }
