// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as coreBi from '@/coreBi';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as higherOrderComponents from '@/higherOrderComponents';
import popupTemplateMapper from './popupTemplateMapper';
import constants from '@/constants';
import React from 'react';
import { FocusPanelFrame } from '../frames';
import * as baseUI from '@/baseUI';
import { Button, Checkbox } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

const {
  connect,
  renderWhenMutated,
  STORES: { EDITOR_API },
} = util.hoc;

function validateImageProp(props, propName, componentName) {
  const msg = 'Warning: Failed propType: ';
  if (!(!props.symbolName ^ !props.imageClassName)) {
    return new Error(
      `${
        msg + componentName
      } must have either symbolName or imageClassName but not both.`,
    );
  } else if (props[propName] && !_.isString(props[propName])) {
    return new Error(
      `${msg}Invalid prop \`${propName}\` of type \`${typeof props[
        propName
      ]}\` supplied to \`${componentName}\`, expected \`string\`.`,
    );
  }
}

//TYPE WAS GENERATED, remove this line when reviewed
interface PopupTemplateProps {
  close: FunctionFixMe;
  openHelpCenter: FunctionFixMe;
  sendBi: FunctionFixMe;
  panelName: string;
  h1text?: string;
  h2text?: string;
  symbolName?: AnyFixMe;
  imageClassName?: AnyFixMe;
  bodyText?: string;
  btnText?: string;
  buttons?: Array<AnyFixMe>;
  helpId?: string;
  selectedComponentType?: string;
  dontShowAgain?: boolean;
  toggleDontShowAgain: FunctionFixMe;
  hideDontShowCheckbox?: boolean;
  shouldNotCloseOnBlur?: boolean;
}

class PopupTemplate extends React.Component<PopupTemplateProps> {
  static displayName = 'popupTemplate';

  static propTypes = {
    close: PropTypes.func.isRequired,
    openHelpCenter: PropTypes.func.isRequired,
    sendBi: PropTypes.func.isRequired,
    panelName: PropTypes.string.isRequired,
    h1text: PropTypes.string,
    h2text: PropTypes.string,
    symbolName: validateImageProp,
    imageClassName: validateImageProp,
    bodyText: PropTypes.string,
    btnText: PropTypes.string,
    buttons: PropTypes.arrayOf(PropTypes.object),
    helpId: PropTypes.string,
    selectedComponentType: PropTypes.string,
    dontShowAgain: PropTypes.bool,
    toggleDontShowAgain: PropTypes.func.isRequired,
    hideDontShowCheckbox: PropTypes.bool,
    shouldNotCloseOnBlur: PropTypes.bool,
  };

  openLearnMore = () => {
    const biHelpParams = {
      component: this.props.selectedComponentType,
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
      learn_more: true,
    };
    this.props.openHelpCenter(this.props.helpId, null, biHelpParams);
  };

  handleBtnClick = (btn) => {
    if (_.isFunction(btn.onClick)) {
      btn.onClick();
    }
    this.props.close();
  };

  hasCustomButtons = () => {
    return this.props.buttons && this.props.buttons.length;
  };

  handleDontShowAgainClick = () => {
    this.props.toggleDontShowAgain();
    this.props.sendBi(coreBi.events.commonControls.dont_show_again_click, {
      panel_name: this.props.panelName,
      toggle: this.props.dontShowAgain,
    });
  };

  render() {
    return (
      <FocusPanelFrame
        shouldHideHeader={true}
        isSingleInstance={this.props.isSingleInstance}
        panelName={this.props.panelName}
        shouldNotCloseOnBlur={this.props.shouldNotCloseOnBlur}
      >
        <div className={`popup-template-panel ${this.props.className || ''}`}>
          <div className="panel-header dark">
            <button onClick={this.props.close} className="close">
              <symbols.symbol name="headerCloseButton" />
            </button>
            {this.props.h1text ? (
              <h1 key="h1text">{translate(this.props.h1text)}</h1>
            ) : null}
            {this.props.h2text ? (
              <h2 key="h2text">{translate(this.props.h2text)}</h2>
            ) : null}
            <div className={`image ${this.props.imageClassName || ''}`}>
              {this.props.symbolName ? (
                <baseUI.symbol
                  key="symbol"
                  name={this.props.symbolName}
                  className="popup-symbol"
                />
              ) : null}
            </div>
          </div>

          <div className="content">
            <div className="panel-text">
              <span>{`${translate(this.props.bodyText)} `}</span>
              {this.props.helpId ? (
                <span
                  key="learnMoreLink"
                  onClick={this.openLearnMore}
                  className="learn-more-link"
                >
                  {translate('Notification_Box_Learn_More')}
                </span>
              ) : null}
            </div>
            <div className="bottom-controls">
              {!this.props.hideDontShowCheckbox ? (
                <Checkbox
                  value={this.props.dontShowAgain}
                  onChange={this.handleDontShowAgainClick}
                  key="dont-show-again-checkbox"
                  labelAfterSymbol={true}
                  label="Notification_Box_Dont_Show_Again"
                  className="dont-show-again"
                />
              ) : null}

              {!this.hasCustomButtons() ? (
                <baseUI.button
                  key="hasNoCustomButtons"
                  onClick={this.props.close}
                  label={this.props.btnText || 'Notification_Box_Got_It'}
                  className="btn-ok btn-md"
                />
              ) : null}

              {this.hasCustomButtons() ? (
                <span key="hasCustomButtons">
                  {/* TODO: Fix this the next time the file is edited. */}
                  {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                  {_.map(this.props.buttons, (btn, btnIndex) => (
                    <Button
                      key={btnIndex}
                      onClick={this.handleBtnClick.bind(this, btn)}
                      automationId={btn.automationId}
                      className={btn.className}
                    >
                      {btn.icon ? (
                        <symbols.symbol
                          key={`symbol-${btn.icon}`}
                          name={btn.icon}
                          className={btn.label ? 'symbol-with-label' : ''}
                        />
                      ) : null}
                      <span data-hint-id={btn.hintId}>
                        {translate(btn.label)}
                      </span>
                    </Button>
                  ))}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </FocusPanelFrame>
    );
  }
}

const ConnectedPopupTemplate = connect(
  EDITOR_API,
  popupTemplateMapper,
)(renderWhenMutated(PopupTemplate));

const LinkedPopupTemplate = higherOrderComponents.linkUserPreferences()(
  ConnectedPopupTemplate,
);
LinkedPopupTemplate.pure = PopupTemplate;

export default LinkedPopupTemplate;
