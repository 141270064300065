// @ts-nocheck
import $ from 'zepto';
import { sections } from '@/util';

const RULERS_WIDTH = 22;

function getRulersNumbersLimits(
  isMobileEditor,
  pagesContainerAbsLayout,
  siteHeight,
  previewPosition,
  rulerDirection,
  fixedStageEnabled,
) {
  let firstTickValue = 0;
  const lastTickValue =
    rulerDirection === 'vertical'
      ? Math.floor(pagesContainerAbsLayout.width / 100)
      : Math.floor((siteHeight + previewPosition.top) / 100);

  if (
    !isMobileEditor &&
    rulerDirection === 'horizontal' &&
    !fixedStageEnabled
  ) {
    firstTickValue = Math.floor(previewPosition.top / 100) + 1;
  }

  return [firstTickValue, lastTickValue];
}

function getRulerNumbers(
  isMobileEditor,
  pagesContainerAbsLayout,
  siteHeight,
  previewPosition,
  rulerDirection,
  fixedStageEnabled,
) {
  const [firstTickValue, lastTickValue] = getRulersNumbersLimits(
    isMobileEditor,
    pagesContainerAbsLayout,
    siteHeight,
    previewPosition,
    rulerDirection,
    fixedStageEnabled,
  );
  const numbersInRuler = [];
  for (let i = firstTickValue; i <= lastTickValue; i++) {
    numbersInRuler.push(i * 100);
  }
  return numbersInRuler;
}

function getRulersLimits(
  isMobileEditor,
  pagesContainerAbsLayout,
  viewPort,
  verticalRulersComponentWidth,
  fixedStageEnabled,
) {
  const minVertical = isMobileEditor ? 0 : -pagesContainerAbsLayout.x;
  const maxVertical =
    isMobileEditor || fixedStageEnabled
      ? pagesContainerAbsLayout.width
      : verticalRulersComponentWidth - pagesContainerAbsLayout.x;

  const minHorizontal = isMobileEditor || fixedStageEnabled ? 0 : RULERS_WIDTH;
  const maxHorizontal = viewPort.maxBottomY + viewPort.marginBottom;
  const minHorizontalInView = isMobileEditor
    ? viewPort.scrollTop
    : viewPort.scrollTop + RULERS_WIDTH;

  return {
    vertical: {
      min: minVertical,
      max: maxVertical,
      minInView: minVertical,
    },
    horizontal: {
      min: minHorizontal,
      max: maxHorizontal,
      minInView: minHorizontalInView,
    },
  };
}

function isGuideOutOfLimits(
  isMobileEditor,
  pagesContainerAbsLayout,
  viewPort,
  verticalRulersComponentWidth,
  rulerDirection,
  guideLocation,
  fixedStageEnabled,
) {
  let limits = getRulersLimits(
    isMobileEditor,
    pagesContainerAbsLayout,
    viewPort,
    verticalRulersComponentWidth,
    fixedStageEnabled,
  );
  limits = rulerDirection === 'vertical' ? limits.vertical : limits.horizontal;
  return guideLocation < limits.min || guideLocation > limits.max;
}

function isGuideOutOfViewLimits(
  isMobileEditor,
  pagesContainerAbsLayout,
  viewPort,
  verticalRulersComponentWidth,
  rulerDirection,
  guideLocation,
) {
  let limits = getRulersLimits(
    isMobileEditor,
    pagesContainerAbsLayout,
    viewPort,
    verticalRulersComponentWidth,
  );
  limits = rulerDirection === 'vertical' ? limits.vertical : limits.horizontal;
  return guideLocation < limits.minInView || guideLocation > limits.max; // TODO divide
}

function getMobileHorizontalTicksHeight(viewPort) {
  return viewPort ? viewPort.maxBottomY + viewPort.marginBottom : 0;
}

function getVerticalTicksWidth(isMobileEditor, pagesContainerLayout) {
  if (!isMobileEditor && sections.isSectionsEnabled()) {
    return '100%';
  }
  return isMobileEditor
    ? pagesContainerLayout.width
    : `calc(100% - ${RULERS_WIDTH}px)`;
}

function calcNewGuidePosition(
  previewPosition,
  siteScroll,
  pagesContainerAbsLayout,
  rulerDirection,
  event,
) {
  if (rulerDirection === 'vertical') {
    return (
      event.pageX -
      previewPosition.left -
      pagesContainerAbsLayout.x +
      siteScroll.x
    );
  }
  return event.pageY - previewPosition.top + siteScroll.y;
}

function getVerticalGuidePosition(
  isMobileEditor,
  pagesContainerAbsLayout,
  guidePosition,
  scrollLeft,
) {
  return isMobileEditor
    ? guidePosition
    : guidePosition + pagesContainerAbsLayout.x - scrollLeft;
}

function calcHorizontalDraggingPosition(position, guideDomNode) {
  const diff =
    $(guideDomNode).offset().top - parseInt($(guideDomNode).css('top'), 10);
  return position - diff;
}

export {
  getRulerNumbers,
  isGuideOutOfLimits,
  isGuideOutOfViewLimits,
  getMobileHorizontalTicksHeight,
  calcNewGuidePosition,
  calcHorizontalDraggingPosition,
  getVerticalTicksWidth,
  getVerticalGuidePosition,
};
