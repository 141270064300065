import { AddPresetScope } from '../../../addPresetApiEntry';
import { connectWithScope } from '@/apilib';
import { fedopsLogger, type WithStartItemDrag, guidUtils } from '@/util';
import React, { type FC, useState, useEffect, useRef } from 'react';
import {
  InitialPanelStates,
  InnerRoutes,
  type SectionCreatorCachedData,
  type SectionCreatorPromptParams,
} from './aiSectionCreatorTypes';
import PromptCreator from './components/promptCreator';
import ResultsDisplay from './components/resultsDisplay';
import Loader from './components/loader';
import {
  type AISectionCreatorStateProps,
  type AISectionCreatorDispatchProps,
  mapStateToProps,
  mapDispatchToProps,
} from './aiSectionCreatorMapper';
import type { SectionPresetDefinition } from '../../../types';
import type {
  OnSectionAddFromContextMenuHandler,
  OnSectionClickHandler,
  OnSectionDragEndHandler,
} from '../../addSectionPanelMapper';
import { CREATE_SECTON_WITH_AI_CACHE } from './aiSectionCreatorConsts';
import { getTokensFromQueryOutput, clearSectionsCacheEntry } from './utils';
export interface AISectionCreatorOwnProps extends WithStartItemDrag {
  onSectionDragEnd: OnSectionDragEndHandler;
  onSectionClick: OnSectionClickHandler;
  onSectionAddFromContextMenu: OnSectionAddFromContextMenuHandler;
  stageEntryIndex: number;
  origin?: string;
}

type AISectionCreatorProps = AISectionCreatorOwnProps &
  AISectionCreatorStateProps &
  AISectionCreatorDispatchProps;

const AISectionCreator: FC<AISectionCreatorProps> = ({
  defaultFormState,
  stageEntryIndex,
  onSectionDragEnd,
  onSectionClick,
  onSectionAddFromContextMenu,
  getSectionWithContent,
  startItemDrag,
  sendPanelOpenedBiEvent,
  sendQueryOutputBiEvent,
  showFailStateModal,
  origin,
}) => {
  const sessionId = useRef(guidUtils.getGUID());
  const hasBeenLoaded = useRef(false);
  const [route, setRoute] = useState<InnerRoutes>(InnerRoutes.PromptCreator);
  const [cachedData, setCachedData] = useState({
    categoryId: '',
    description: '',
  });
  const [sectionDefinitions, setSectionDefinitions] = useState<
    SectionPresetDefinition[]
  >([]);

  useEffect(() => {
    if (hasBeenLoaded.current) return;
    hasBeenLoaded.current = true;

    const cachedData = sessionStorage.getItem(CREATE_SECTON_WITH_AI_CACHE);

    const initialData: SectionCreatorCachedData = cachedData
      ? {
          ...defaultFormState,
          ...JSON.parse(cachedData),
        }
      : defaultFormState;

    const { sections, ...query } = initialData;
    const { categoryId, description } = query;

    setCachedData({ categoryId, description });

    const currentPanelState = sections
      ? InitialPanelStates.Results
      : InitialPanelStates.Settings;

    sendPanelOpenedBiEvent(
      sessionId.current,
      query as SectionCreatorPromptParams,
      origin,
      currentPanelState,
    );

    if (sections) {
      setSectionDefinitions(sections);
      setRoute(InnerRoutes.Results);
    }
  }, [defaultFormState, sendPanelOpenedBiEvent, origin]);

  const handleFormSubmit = (params: SectionCreatorPromptParams) => {
    const startTimestampt = Date.now();
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.CREATE_WITH_AI,
    );
    let shouldUpdateState = true;
    setRoute(InnerRoutes.Loader);

    const { categoryId, description } = params;
    setCachedData({ categoryId, description });

    getSectionWithContent(params)
      .then(({ createdSections, queryOutputs }) => {
        if (!shouldUpdateState) return;
        const duration = Date.now() - startTimestampt;
        sendQueryOutputBiEvent(
          sessionId.current,
          params,
          queryOutputs,
          duration,
        );

        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.CREATE_WITH_AI,
        );
        setSectionDefinitions(createdSections);
        sessionStorage.setItem(
          CREATE_SECTON_WITH_AI_CACHE,
          JSON.stringify({
            sections: createdSections,
            categoryId,
            description,
          }),
        );
        setRoute(InnerRoutes.Results);
      })
      .catch(({ error, queryOutput }) => {
        const tokens = JSON.stringify(getTokensFromQueryOutput(queryOutput));
        setCachedData({ categoryId: '', description: '' });
        setSectionDefinitions([]);

        showFailStateModal(
          { categoryId, description, tokens },
          error?.originalError.message,
        );
        setRoute(InnerRoutes.PromptCreator);
      });

    return () => (shouldUpdateState = false);
  };

  const handleBackButtonClick = () => {
    clearSectionsCacheEntry();
    setRoute(InnerRoutes.PromptCreator);
  };

  switch (route) {
    case InnerRoutes.PromptCreator:
      return (
        <PromptCreator
          onSubmit={handleFormSubmit}
          cachedData={cachedData}
          sessionId={sessionId.current}
        />
      );
    case InnerRoutes.Loader:
      return <Loader />;
    case InnerRoutes.Results:
      return (
        <ResultsDisplay
          categoryId={cachedData.categoryId}
          sessionId={sessionId.current}
          sectionDefinitions={sectionDefinitions}
          onBackButtonClick={handleBackButtonClick}
          onSectionDragEnd={onSectionDragEnd}
          onSectionClick={onSectionClick}
          startItemDrag={startItemDrag}
          onSectionAddFromContextMenu={onSectionAddFromContextMenu}
          stageEntryIndex={stageEntryIndex}
        />
      );
    default:
      return null;
  }
};

export default connectWithScope(
  () => AddPresetScope,
  AISectionCreator,
  mapStateToProps,
  mapDispatchToProps,
);
