import _ from 'lodash';
import $ from 'zepto';
import constants from '@/constants';
import * as bi from '@/coreBi';
import * as core from '@/core';
import * as stateManagement from '@/stateManagement';
import { editorWixRecorder, sections, fedopsLogger } from '@/util';
import { layoutUtils } from '@/layoutUtils';
import { ErrorReporter } from '@wix/editor-error-reporter';

import { adaptAddingComponentStructure } from '../adaptAddingComponentStructure';
import {
  isStretchedComp,
  shouldShowAddToEmptyMobilePageWarnNotification,
  showAddToEmptyMobilePageWarnNotification,
} from '../addPanelUtils';

import type {
  CompStructure,
  Point,
  CompRef,
  Rect,
  StyleRefOrStyleRefs,
  CompLayout,
} from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type {
  EditorState,
  StageLayout,
  PreviewPosition,
} from '@/stateManagement';

const { showCompAddedInMobileNotificationIfNeeded } =
  stateManagement.mobile.actions;

const {
  AttachHandler,
  mediaManagerPresetUtil,
  SnapToHandler,
  addPanelBiUtils,
  addUtil: { addComponentUnderInteractionCallback },
} = core.utils;

const { NEW_ADD_PANEL_NAME } = constants.ROOT_COMPS.LEFTBAR;

const CLOSE_ADD_PANEL_DELAY = 200;

const {
  isInInteractionMode,
  getInteractionTriggerRef,
  getInteractionContainerToAddTo,
} = stateManagement.interactions.selectors;
const {
  translateToViewerCoordinates,
  getPreviewPosition,
  getStageLayout,
  getSiteScale,
} = stateManagement.domMeasurements.selectors;
const { showCannotAddComponentNotification } = stateManagement.addPanel.actions;

export interface DraggableItem {
  appDefinitionId?: string;
  overrideDragBoxEl?: (props: any) => JSX.Element;
  onItemDrag?: (e: MouseEvent) => void;
  getBoxSize?: () => { width: number; height: number };
  disableFullWidthPlaceholder?: boolean;
  categoryId?: string;
  itemId: string;
  mediaManager?: undefined;
  onDrop?: OnDropCallback;
  onAfterDrop?: (compRef: CompRef, mousePosition: Point) => void;
  rect: Partial<Rect>;
  section: string;
  sectionTitle?: string;
  structure: Partial<CompStructure>;
  tags?: AnyFixMe;
  dragOrigin?: string;
}

export interface ViewerMouseCoordinates {
  pageX: number;
  pageY: number;
  clientX: number;
  clientY: number;
}

export interface DragBoxStageLayout {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface OffsetObject {
  xOffset: number;
  yOffset: number;
}

interface DropArguments {
  dropContainer: CompRef | null;
  compPosRelativeToContainer: Point | null;
  prerequisiteFunc?: Function;
}

const SNAP_THRESHOLD = 5;
const SNAP_DIRECTIONS_ALL = {
  top: true,
  bottom: true,
  left: true,
  right: true,
  centerX: true,
  centerY: true,
};
const SNAP_DIRECTIONS_VERTICAL_ONLY = {
  top: true,
  bottom: true,
  left: false,
  right: false,
  centerX: false,
  centerY: true,
} as const;

type AllSnapDirections = typeof SNAP_DIRECTIONS_ALL;
type VerticalSnapDirections = typeof SNAP_DIRECTIONS_VERTICAL_ONLY;

type SnapDirections = AllSnapDirections | VerticalSnapDirections;

const POPUP_CATEGORY_ID = 'popup';

const AYELETS_MAGICAL_DRAG_THRESHOLD_NUMBER = 8;

const INTERACTION_OUT_OF_CONTAINER_RESTRICTED: Record<string, boolean> = {
  [constants.COMP_TYPES.COLUMN]: true,
  [constants.COMP_TYPES.REPEATER]: true,
  [constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER]: true,
};

function isCompRepeatedItem(editorAPI: EditorAPI, compRef: CompRef): boolean {
  return !!(
    editorAPI.components.is.repeaterItem(compRef) ||
    editorAPI.components.is.descendantOfRepeaterItem(compRef)
  );
}

function canInteractionAttachOutOfContainer(
  editorAPI: EditorAPI,
  triggerRef: CompRef,
  isRepeatedContainer: boolean,
) {
  if (isRepeatedContainer) {
    return false;
  }
  const triggerCompType = editorAPI.components.getType(triggerRef);
  return !INTERACTION_OUT_OF_CONTAINER_RESTRICTED[triggerCompType];
}

function isDropContainerDescendantOrTrigger(
  editorAPI: EditorAPI,
  dropContainer: CompRef,
  triggerRef: CompRef,
): boolean {
  return (
    editorAPI.components.isDescendantOfComp(dropContainer, triggerRef) ||
    triggerRef.id === dropContainer.id
  );
}

function applyOffsetToPosition(
  position: Point,
  offset: OffsetObject | null,
): Point {
  if (!offset) {
    return position;
  }
  return {
    x: position.x + offset.xOffset,
    y: position.y + offset.yOffset,
  };
}

export interface OnDropCallback {
  (
    coords: Point,
    itemData: DraggableItem,
    parentRef: CompRef,
    editorAPI?: EditorAPI,
  ): Promise<CompRef> | Promise<void> | void;
}

const addToStage: OnDropCallback = (
  coords: Point,
  itemData: DraggableItem,
  parentRef: CompRef,
  editorAPI: EditorAPI,
) => {
  return new Promise<CompRef>(function (resolve) {
    const structureWithUpdatedLayout = {
      ...itemData.structure,
      layout: {
        ...itemData.structure.layout,
        x: Math.round(coords.x),
        y: Math.round(coords.y),
      },
    };
    itemData.structure = adaptAddingComponentStructure(
      editorAPI,
      structureWithUpdatedLayout as any,
    );

    const isFWEAttachToSectionLike =
      sections.isSectionsEnabled() &&
      editorAPI.sections.isSectionLike(parentRef) &&
      isStretchedComp(editorAPI, structureWithUpdatedLayout as any);

    if (isFWEAttachToSectionLike) {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.COMP_ATTACHED_TO_SECTION,
      );
    }

    const addComponentCallback = function (compRef: CompRef) {
      const isModefulContainer = !_.isEmpty(
        editorAPI.components.modes.getModes(parentRef),
      );
      if (isModefulContainer && compRef.id) {
        const activeModeIds =
          editorAPI.components.modes.getComponentActiveModeIds(parentRef);
        editorAPI.components.modes.showComponentOnlyInModesCombination(
          compRef,
          Object.keys(activeModeIds),
        );
        editorAPI.tabIndicationState.activateAddAnimation(true);
        editorAPI.components.modes.addDefaultSingleModeAnimations(compRef);
      }
      if (itemData.structure.type !== 'Page') {
        editorAPI.history.add('component dragged to stage from add panel', {
          isAddingComponent: true,
        });
      }

      const targetType = editorAPI.components.getType(parentRef);
      const isTargetRepeated =
        editorAPI.components.is.repeatedComponent(parentRef);
      const { props: compProps } = itemData.structure;

      editorAPI.selection.selectComponentByCompRef(compRef);
      const state = editorAPI.store.getState();
      if (editorAPI.isMobileEditor()) {
        editorAPI.store.dispatch(showCompAddedInMobileNotificationIfNeeded());
      }

      if (isInInteractionMode(state)) {
        addComponentUnderInteractionCallback(
          editorAPI,
          compRef,
          parentRef,
          state,
        );
      }
      editorAPI.openFirstTimeOrDeprecationPanel(compRef);
      editorWixRecorder.addLabel(
        `${itemData.structure.componentType} added to stage`,
      );
      editorAPI.bi.event(
        bi.events.addPanel.COMPONENT_ADDED_TO_STAGE,
        Object.assign(
          {
            ...editorAPI.bi.getComponentsBIParams([compRef])[0],
            origin: itemData.dragOrigin || 'add_panel',
            category: itemData.categoryId,
            section: itemData.sectionTitle,
            component_type: itemData.structure.componentType,
            component_id: compRef.id,
            preset_id: itemData.itemId || '',
            adding_method: 'drag',
            preset_data_skin:
              (itemData.structure.style as StyleRefOrStyleRefs).skin ||
              itemData.structure.skin ||
              itemData.structure.style,
            preset_data_tags: itemData.tags || '',
            page_id:
              editorAPI.pages.getFocusedPage() &&
              editorAPI.pages.getFocusedPage().id,
            target_component: targetType,
            target_component_id: parentRef?.id,
            is_target_repeated: isTargetRepeated,
            target_component_type:
              editorAPI.componentFocusMode.getMenuType() ?? targetType,
          },
          addPanelBiUtils(compProps),
          itemData.appDefinitionId ? { app_id: itemData.appDefinitionId } : {},
        ),
      );

      editorAPI.components.layout.openCompOutOfGridPanelIfNeeded(compRef);

      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL,
        {
          paramsOverrides: {
            component_type: itemData.structure.componentType,
            method: 'drag',
          },
        },
      );
      ErrorReporter.breadcrumb('added component from add panel', {
        method: 'drag',
        appDefId: itemData.appDefinitionId,
        compRef,
        parentComponent: parentRef,
      });

      resolve(compRef);
    };

    if (itemData.appDefinitionId) {
      editorAPI.tpa
        .installAppIfNeeded(itemData.appDefinitionId, {
          resolveBeforeSave: true,
        })
        .then(() => {
          editorAPI.components.add(
            parentRef,
            itemData.structure as CompStructure,
            undefined,
            addComponentCallback,
            undefined,
            null,
            null,
            'preset',
          );
        });
    } else {
      editorAPI.components.add(
        parentRef,
        itemData.structure as CompStructure,
        undefined,
        addComponentCallback,
        undefined,
        null,
        null,
        'preset',
      );
    }
  });
};

function isInitialDragThresholdMet(
  mouseCoordinates: ViewerMouseCoordinates,
  translatedInitialEvent: ViewerMouseCoordinates,
) {
  return (
    Math.abs(translatedInitialEvent.pageX - mouseCoordinates.pageX) >=
      AYELETS_MAGICAL_DRAG_THRESHOLD_NUMBER ||
    Math.abs(translatedInitialEvent.pageY - mouseCoordinates.pageY) >=
      AYELETS_MAGICAL_DRAG_THRESHOLD_NUMBER
  );
}

function getPagesContainerAbsPosition(editorAPI: EditorAPI): Point {
  const pagesContainerRef = editorAPI.dsRead.siteSegments.getPagesContainer();
  const pageContainerLayout =
    editorAPI.components.layout.getRelativeToScreen(pagesContainerRef);
  return {
    x: pageContainerLayout.x,
    y: 0,
  };
}

function adjustFullWidthDragBoxProps(
  props: any,
  stageWidth: number,
  previewPosition: PreviewPosition,
) {
  props.boxOffset = Object.assign({}, props.boxOffset, { left: 0 });
  props.coords.left = previewPosition.left;

  if (stageWidth) {
    props.boxSize.width = stageWidth;
  }

  return props;
}

function isDescendentOfStretchedRepeater(
  editorAPI: EditorAPI,
  compRef: CompRef,
) {
  const isRepeaterItem = editorAPI.components.is.repeaterItem(compRef);
  const repeaterItem = isRepeaterItem
    ? compRef
    : editorAPI.components.getAncestorRepeaterItem(compRef);

  if (!repeaterItem) {
    return false;
  }

  const repeater = editorAPI.components.getContainer(repeaterItem);
  return editorAPI.components.layout.isHorizontallyStretchedToScreen(repeater);
}

function getPopupContainerDragBox(
  props: any,
  item: any,
  stageLayout: StageLayout,
) {
  const popupContainer = item.structure.components[0];
  const popupContainerLayout = popupContainer.layout;
  const popupContainerAlignmentType: keyof typeof alignmentMap =
    popupContainer.props.alignmentType;
  const { verticalAlignment } = popupContainer.props;
  const { horizontalAlignment } = popupContainer.props;
  const alignmentMap = {
    fullHeight() {
      return {
        left:
          horizontalAlignment === 'left'
            ? stageLayout.left
            : stageLayout.right - popupContainerLayout.width,
        top: stageLayout.top,
        height: stageLayout.height,
        width: popupContainerLayout.width,
      };
    },
    fullWidth() {
      return {
        left: stageLayout.left,
        top:
          verticalAlignment === 'bottom'
            ? stageLayout.bottom - popupContainerLayout.height
            : stageLayout.top,
        height: popupContainerLayout.height,
        width: stageLayout.width,
      };
    },
    nineGrid() {
      return {
        left: (stageLayout.width - popupContainerLayout.width) / 2,
        top: (stageLayout.height - popupContainerLayout.height) / 2,
        height: popupContainerLayout.height,
        width: popupContainerLayout.width,
      };
    },
  };

  const position = alignmentMap[popupContainerAlignmentType]();

  props.coords = _.pick(position, ['left', 'top']);
  props.boxSize = _.pick(position, ['height', 'width']);

  props.boxOffset = { left: 0, top: 0 };

  return props;
}

class AddPanelDragHandler {
  protected lastDragPosition = {
    x: 0,
    y: 0,
  };
  protected isSectionDrag: boolean;
  protected draggedSection: any;

  private attachHandler: InstanceType<typeof AttachHandler>;
  private snapToHandler: InstanceType<typeof SnapToHandler>;

  private isFullWidth: boolean;
  private isFirstDragEvent: boolean;
  private panelOpened: boolean;
  private ignoreTouchingGridLines: boolean;
  private boxOffset?: { left: number; top: number };

  private initialEventTranslatedToViewerCoordinates: ViewerMouseCoordinates;
  private stageLayout: StageLayout;
  private lastMousePosition: Point;
  private originalDragPosition: Point;
  private lastDragFixedPosition: Point;
  private allowedSnapDirections: SnapDirections;
  private panelClientRect?: DOMRect;

  constructor(
    protected editorAPI: EditorAPI,
    private initialEvent: MouseEvent,
    protected item: AnyFixMe,
    private panelElm: Element,
    private dragBoxClass: React.ComponentType,
    private onItemDragStart: Function,
    private onItemDragEnd: Function,
  ) {
    if (!editorAPI) {
      return;
    }
    const state = editorAPI.store.getState();

    this.initialEventTranslatedToViewerCoordinates =
      translateToViewerCoordinates(editorAPI, this.initialEvent);

    this.ignoreTouchingGridLines = editorAPI.dsRead.pages.isWidgetPage(
      editorAPI.pages.getFocusedPageId(),
    );
    this.isFirstDragEvent = true;
    this.panelOpened = true;

    this.originalDragPosition = {
      x: this.initialEvent.pageX,
      y: this.initialEvent.pageY,
    };

    this.stageLayout = getStageLayout(state);

    if (
      !this.item.disableFullWidthPlaceholder &&
      this.editorAPI.components.is.fullWidthByStructure(this.item.structure)
    ) {
      this.isFullWidth = true;
      this.allowedSnapDirections = SNAP_DIRECTIONS_VERTICAL_ONLY;
    } else {
      this.allowedSnapDirections = SNAP_DIRECTIONS_ALL;
      this.isFullWidth = false;
    }

    if (sections.isSectionsEnabled()) {
      this.lastMousePosition = null;
    }

    this.init();
  }

  init() {
    const { editorAPI } = this;
    const scopeRef = editorAPI.spotlightStage.getContainer();

    this.snapToHandler = new SnapToHandler(editorAPI);

    this.attachHandler = new AttachHandler(
      editorAPI,
      {
        comp: this.item.structure,
        layout: _.pick(this.item.structure.layout, ['width', 'height']),
      },
      AttachHandler.TYPES.STRUCTURE,
      {
        scopeRef,
        strictPageBoundary: sections.isSectionsEnabled(),
        isDragHandler: true,
      },
    );
  }
  getFixedDragPosition(e: MouseEvent): Point {
    return {
      x: e.clientX,
      y: e.clientY,
    };
  }
  onDrag(e: MouseEvent) {
    if (this.item.onItemDrag) {
      this.item.onItemDrag(e);
    }
    const state = this.editorAPI.store.getState();
    const mouseCoordinates = translateToViewerCoordinates(this.editorAPI, e);

    if (this.isFirstDragEvent) {
      if (
        !isInitialDragThresholdMet(
          mouseCoordinates,
          this.initialEventTranslatedToViewerCoordinates,
        )
      ) {
        return;
      }

      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL,
        {
          paramsOverrides: {
            component_type: this.item.structure?.componentType,
            method: 'drag',
          },
        },
      );
      this.onInitialDrag();

      this.isFirstDragEvent = false;
    }

    this.lastDragPosition = {
      x: mouseCoordinates.pageX,
      y: mouseCoordinates.pageY,
    };

    this.lastDragFixedPosition = this.getFixedDragPosition(e);

    const isInPanelArea = this.isInPanelArea();
    if (isInPanelArea) {
      this.handleDragInPanelArea();
    } else {
      this.handleDragOutsidePanelArea(e, state, { mouseCoordinates });
    }

    this.setDragBox(isInPanelArea);
  }
  onInitialDrag() {
    if (_.isFunction(this.onItemDragStart)) {
      this.onItemDragStart();
    }
  }
  isInPanelArea() {
    const panelArea = this.getPanelClientRect();
    return (
      this.panelOpened &&
      this.lastDragFixedPosition.x >= panelArea.left &&
      this.lastDragFixedPosition.x <= panelArea.right &&
      this.lastDragFixedPosition.y >= panelArea.top &&
      this.lastDragFixedPosition.y <= panelArea.bottom
    );
  }
  getPanelClientRect() {
    if (!this.panelClientRect) {
      this.panelClientRect = this.panelElm.getBoundingClientRect();
    }

    return this.panelClientRect;
  }
  closeAddPanelOnce = _.once(() => {
    if (this.editorAPI.panelManager.isPanelOpened(NEW_ADD_PANEL_NAME)) {
      setTimeout(() => {
        this.editorAPI.panelManager.closePanelByName(NEW_ADD_PANEL_NAME);
        this.panelOpened = false;
      }, CLOSE_ADD_PANEL_DELAY);
    }
  });
  handleDragOutsidePanelArea(
    e: MouseEvent,
    state: EditorState,
    { mouseCoordinates }: { mouseCoordinates: ViewerMouseCoordinates },
  ) {
    this.closeAddPanelOnce();
    const dragBoxStageLayout = this.getDragBoxStageLayout();
    const dragBoxLayoutRelativeToClient = this.getDragBoxStageLayout(true);

    if (this.item.categoryId !== POPUP_CATEGORY_ID) {
      this.snapDragBoxToCompIfNeeded(dragBoxStageLayout);
      const { candidateRef: attachCandidate, candidateStatus } =
        this.attachHandler.getCurrentAttachCandidate(
          dragBoxLayoutRelativeToClient,
          null,
          { x: mouseCoordinates.clientX, y: mouseCoordinates.clientY },
        );

      if (isInInteractionMode(state)) {
        const triggerRef = getInteractionTriggerRef(state);
        const containerToHighlight = getInteractionContainerToAddTo(
          this.editorAPI,
          triggerRef,
          attachCandidate,
        );
        this.attachHandler.highlightAttachCandidate(containerToHighlight);
        return;
      }

      if (candidateStatus === AttachHandler.ATTACH_CANDIDATE_STATUSES.VALID) {
        this.attachHandler.highlightAttachCandidate(attachCandidate);
      } else if (sections.isSectionsEnabled()) {
        this.clearDrag();
      }
    }
  }
  handleDragInPanelArea() {
    this.editorAPI.snapData.clear(true);
    this.attachHandler.clearHighlightAttachCandidate(true);
  }
  getDragBoxStageLayout(isRelativeToClient?: boolean): DragBoxStageLayout {
    const layout = {
      x:
        this.lastDragPosition.x +
        this.getBoxOffset().left * this.item.structure.layout.width,
      y:
        this.lastDragPosition.y +
        this.getBoxOffset().top * this.item.structure.layout.height,
      width: this.item.structure.layout.width,
      height: this.item.structure.layout.height,
    };

    if (isRelativeToClient) {
      const scroll = this.editorAPI.ui.scroll.getScroll();
      layout.x -= scroll.scrollLeft;
      layout.y -= scroll.scrollTop;
    }

    return layout;
  }
  getBoxOffset() {
    if (!this.boxOffset) {
      const rectSize = this.getRectSize();

      this.boxOffset = {
        left:
          (this.item.rect.left +
            $(window).scrollLeft() -
            this.originalDragPosition.x) /
          rectSize.width,
        top:
          (this.item.rect.top +
            $(window).scrollTop() -
            this.originalDragPosition.y) /
          rectSize.height,
      };
    }

    return this.boxOffset;
  }
  getRectSize() {
    return {
      width: this.item.rect.width,
      height: this.item.rect.height,
    };
  }
  setDragBox(isInPanelArea: boolean) {
    const state = this.editorAPI.store.getState();
    const siteScale = getSiteScale(state);
    let props = {
      key: 'dragToStageDragBox',
      coords: {
        left: this.lastDragFixedPosition.x,
        top: this.lastDragFixedPosition.y,
      },
      boxOffset: this.getBoxOffset(),
    } as any;

    if (isInPanelArea) {
      props.boxSize = this.getRectSize();
    } else if (this.item.categoryId === 'popup') {
      props = getPopupContainerDragBox(props, this.item, this.stageLayout);
      props.boxSize.height *= siteScale;
    } else if (this.item.getBoxSize) {
      props.boxSize = this.item.getBoxSize();
    } else {
      props.boxSize = _.pick(this.item.structure.layout, ['width', 'height']);
      props.boxSize.height *= siteScale;

      if (this.isFullWidth) {
        const previewPosition = getPreviewPosition(state);
        props = adjustFullWidthDragBoxProps(
          props,
          this.stageLayout.width,
          previewPosition,
        );
      }
    }

    const dragBoxElm = {
      props,
      componentType: this.item.structure.componentType,
      reactClass: this.dragBoxClass,
    };

    this.editorAPI.setPortalChildren('addPanel', [dragBoxElm]);
  }
  snapDragBoxToCompIfNeeded(dragBoxLayout: DragBoxStageLayout) {
    const closestCompDistance = this.snapToHandler.getClosestLayoutDistance(
      this.editorAPI,
      dragBoxLayout,
      this.allowedSnapDirections,
    );

    if (Math.abs(closestCompDistance.x) <= SNAP_THRESHOLD) {
      this.lastDragPosition.x += closestCompDistance.x;
      this.lastDragFixedPosition.x += closestCompDistance.x;
      dragBoxLayout.x += closestCompDistance.x;
    }

    if (Math.abs(closestCompDistance.y) <= SNAP_THRESHOLD) {
      this.lastDragPosition.y += closestCompDistance.y;
      this.lastDragFixedPosition.y += closestCompDistance.y;
      dragBoxLayout.y += closestCompDistance.y;
    }

    const snapDrawData = this.snapToHandler.getSnapData(
      dragBoxLayout,
      this.allowedSnapDirections,
      this.ignoreTouchingGridLines,
    );
    this.editorAPI.snapData.set(snapDrawData, true);
  }
  clearDrag() {
    this.editorAPI.snapData.clear(true);
    this.attachHandler.clearHighlightAttachCandidate(true);
  }
  endDrag(e: MouseEvent) {
    this.clearDrag();

    this.editorAPI.setPortalChildren('addPanel', null);

    if (this.isFirstDragEvent) {
      return false;
    }
    const mouseCoordinates = translateToViewerCoordinates(this.editorAPI, e);

    this.lastDragPosition = {
      x: mouseCoordinates.pageX,
      y: mouseCoordinates.pageY,
    };

    if (sections.isSectionsEnabled()) {
      this.lastMousePosition = {
        x: mouseCoordinates.clientX,
        y: mouseCoordinates.clientY,
      };
    }

    if (!this.isInPanelArea()) {
      // @ts-expect-error
      this.onDrop(e);
    } else {
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL,
        {
          paramsOverrides: {
            component_type: this.item.structure?.componentType,
            method: 'drag',
          },
        },
      );
    }

    if (this.onItemDragEnd) {
      this.onItemDragEnd();
    }

    return true;
  }
  getCompPositionRelativeToContainer(
    pagesContainerAbsPosition: Point,
    dropContainerLayout: CompLayout,
  ) {
    return {
      x:
        this.lastDragPosition.x -
        pagesContainerAbsPosition.x -
        dropContainerLayout.x +
        this.getBoxOffset().left * this.item.structure.layout.width,
      y:
        this.lastDragPosition.y -
        pagesContainerAbsPosition.y -
        dropContainerLayout.y +
        this.getBoxOffset().top * this.item.structure.layout.height,
    };
  }
  getCompPositionRelativeToDockedNotFixedContainer(
    widgetRootLayout: CompLayout,
  ) {
    return {
      x:
        this.lastDragPosition.x -
        widgetRootLayout.x +
        this.getBoxOffset().left * this.item.structure.layout.width,
      y:
        this.lastDragPosition.y -
        widgetRootLayout.y +
        this.getBoxOffset().top * this.item.structure.layout.height,
    };
  }
  getDropArguments(): DropArguments {
    const { editorAPI } = this;
    const state = editorAPI.store.getState();

    const addPanelDropPlugins = editorAPI.pluginService.getAllPluginsOfType(
      editorAPI.pluginService.pluginConstants.ADD_PANEL_DROP,
    );

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const pluginThatCanBeApplied = _.find(addPanelDropPlugins, (plugin) =>
      _.invoke(plugin, 'condition', editorAPI),
    );

    if (pluginThatCanBeApplied) {
      return pluginThatCanBeApplied.getOverrides(editorAPI, this);
    }

    const dragBoxStageLayout = this.getDragBoxStageLayout(true);
    const dropContainer = this.getDropContainer(dragBoxStageLayout);

    if (!dropContainer) {
      return {
        dropContainer: null,
        compPosRelativeToContainer: null,
      };
    }

    const dropContainerLayoutRelativeToStructure =
      editorAPI.components.layout.getRelativeToStructure(dropContainer);

    if (isDescendentOfStretchedRepeater(editorAPI, dropContainer)) {
      return {
        dropContainer,
        compPosRelativeToContainer:
          this.getCompPositionRelativeToDockedNotFixedContainer(
            dropContainerLayoutRelativeToStructure,
          ),
      };
    }

    const pagesContainerAbsPosition = getPagesContainerAbsPosition(editorAPI);
    const compPosRelativeToContainer = this.getCompPositionRelativeToContainer(
      pagesContainerAbsPosition,
      dropContainerLayoutRelativeToStructure,
    );

    if (isInInteractionMode(state)) {
      const triggerRef = getInteractionTriggerRef(state);
      const interactionContainerToAddTo = getInteractionContainerToAddTo(
        editorAPI,
        triggerRef,
        dropContainer,
      );
      const offsetDrop =
        editorAPI.pasteLogic.addPanelPasteLogic.getInteractionsOffsetPastePosition(
          editorAPI,
          interactionContainerToAddTo,
        );
      const isRepeatedContainer = isCompRepeatedItem(
        editorAPI,
        interactionContainerToAddTo,
      );
      if (
        !isDropContainerDescendantOrTrigger(
          editorAPI,
          dropContainer,
          triggerRef,
        ) &&
        !canInteractionAttachOutOfContainer(
          editorAPI,
          triggerRef,
          isRepeatedContainer,
        )
      ) {
        const interactionContainerBoundingRect =
          editorAPI.components.layout.measure.getBoundingClientRect(
            interactionContainerToAddTo,
          );
        const interactionContainerMeasure = {
          ...interactionContainerBoundingRect,
          y: interactionContainerBoundingRect.absoluteTop,
          x: interactionContainerBoundingRect.absoluteLeft,
        };
        const { height: containerHeight, width: containerWidth } =
          interactionContainerMeasure;
        const itemLayout = this.item.structure.layout;
        const compPositionRelativeToContainer =
          this.getCompPositionRelativeToContainer(
            { x: 0, y: 0 },
            interactionContainerMeasure,
          );
        const isCompOutsideRepeaterContainer =
          isRepeatedContainer &&
          !layoutUtils.isCompRelativelyInsideRepeatedItemWithInteractionRestrictions(
            {
              ...itemLayout,
              ...compPositionRelativeToContainer,
            },
            interactionContainerMeasure,
          );
        return {
          dropContainer: interactionContainerToAddTo,
          compPosRelativeToContainer: isCompOutsideRepeaterContainer
            ? {
                x: (containerWidth - itemLayout.width) / 2,
                y: (containerHeight - itemLayout.height) / 2,
              }
            : compPositionRelativeToContainer,
        };
      }

      const interactionContainerToAddToLayoutRelativeToStructure =
        editorAPI.components.layout.getRelativeToStructure(
          interactionContainerToAddTo,
        );
      return {
        dropContainer: interactionContainerToAddTo,
        compPosRelativeToContainer: applyOffsetToPosition(
          this.getCompPositionRelativeToContainer(
            pagesContainerAbsPosition,
            interactionContainerToAddToLayoutRelativeToStructure,
          ),
          offsetDrop,
        ),
      };
    }

    const isDropOutsideOfContainer =
      compPosRelativeToContainer.x + dragBoxStageLayout.width < 0 ||
      dropContainerLayoutRelativeToStructure.width <
        compPosRelativeToContainer.x ||
      compPosRelativeToContainer.y + dragBoxStageLayout.height < 0 ||
      dropContainerLayoutRelativeToStructure.height <
        compPosRelativeToContainer.y;

    const currentPage = editorAPI.pages.getFocusedPage();
    if (isDropOutsideOfContainer && editorAPI.isMobileEditor()) {
      const pageLayout = editorAPI.components.layout.get_size(currentPage);
      compPosRelativeToContainer.x =
        pageLayout.width / 2 - this.item.structure.layout.width / 2;
    }

    if (editorAPI.spotlightStage.getContainer()) {
      if (isDropOutsideOfContainer) {
        return {
          dropContainer,
          compPosRelativeToContainer: {
            x:
              (dropContainerLayoutRelativeToStructure.width -
                dragBoxStageLayout.width) /
              2,
            y:
              (dropContainerLayoutRelativeToStructure.height -
                dragBoxStageLayout.height) /
              2,
          },
        };
      }
    }

    if (!sections.isSectionsEnabled()) {
      const droppedToFooter = editorAPI.utils.isSameRef(
        dropContainer,
        editorAPI.siteSegments.getFooter(),
      );
      const droppedToHeader = editorAPI.utils.isSameRef(
        dropContainer,
        editorAPI.siteSegments.getHeader(),
      );

      if (
        droppedToFooter &&
        compPosRelativeToContainer.y + dragBoxStageLayout.height >
          dropContainerLayoutRelativeToStructure.height
      ) {
        compPosRelativeToContainer.y =
          dropContainerLayoutRelativeToStructure.height -
          dragBoxStageLayout.height;
      }

      if (droppedToHeader && compPosRelativeToContainer.y < 0) {
        compPosRelativeToContainer.y = 0;
      }
    }

    return { dropContainer, compPosRelativeToContainer };
  }
  onDrop() {
    const { dropContainer, compPosRelativeToContainer, prerequisiteFunc } =
      this.getDropArguments();

    if (!dropContainer) {
      if (shouldShowAddToEmptyMobilePageWarnNotification(this.editorAPI)) {
        showAddToEmptyMobilePageWarnNotification(this.editorAPI);
      }
      return;
    }

    if (
      !this.editorAPI.components.is.containableByStructure(
        this.item.structure,
        dropContainer,
      )
    ) {
      this.editorAPI.store.dispatch(
        stateManagement.panels.actions.closePanelByName(
          constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
        ),
      );
      this.editorAPI.store.dispatch(
        showCannotAddComponentNotification(this.item.structure, dropContainer),
      );
      return;
    }

    if (_.isFunction(prerequisiteFunc)) {
      prerequisiteFunc();
    }

    _.defer(() => this.onItemDrop(compPosRelativeToContainer, dropContainer));

    mediaManagerPresetUtil.storePath(
      this.editorAPI,
      mediaManagerPresetUtil.getIdByDataType(this.item.structure),
      this.item?.mediaManager?.path,
    );
  }
  async onItemDrop(compPosRelativeToContainer: Point, dropContainer: CompRef) {
    const { editorAPI, item } = this;
    const onItemDrop: OnDropCallback = item.onDrop || addToStage;
    const onAfterItemDrop = item.onAfterDrop || _.noop;

    const compRef = await onItemDrop(
      compPosRelativeToContainer,
      item,
      dropContainer,
      editorAPI,
    );
    onAfterItemDrop(compRef, this.lastMousePosition);
  }
  getDropContainer(dragBoxStageLayout: DragBoxStageLayout) {
    const { x, y } = this.lastMousePosition || dragBoxStageLayout;

    const { candidateRef, candidateStatus } =
      this.attachHandler.getCurrentAttachCandidate(dragBoxStageLayout, null, {
        x,
        y,
      });

    if (candidateStatus === AttachHandler.ATTACH_CANDIDATE_STATUSES.INVALID) {
      return null;
    }
    return candidateRef;
  }
}

export default AddPanelDragHandler;
