'use strict'

module.exports = {
    'header': {
        'id': 'site_header_1',
        'structure': {
            'type': 'Container',
            'components': [],
            'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen',
            'layout': {
                'width': 980,
                'height': 180,
                'x': 0,
                'y': 0,
                'scale': 1,
                'rotationInDegrees': 0,
                'anchors': [{
                    'distance': 0,
                    'topToTop': 180,
                    'originalValue': 180,
                    'type': 'BOTTOM_TOP',
                    'locked': true,
                    'targetComponent': 'PAGES_CONTAINER'
                }],
                'fixedPosition': false
            },
            'componentType': 'wysiwyg.viewer.components.HeaderContainer',
            'style': {
                'type': 'TopLevelStyle',
                'id': 'hc1',
                'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                'style': {'properties': {}, 'propertiesSource': {}, 'groups': {}},
                'componentClassName': '',
                'pageId': '',
                'compId': '',
                'styleType': 'system',
                'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen'
            }
        },
        'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 0}}
    },
    'footer': {
        'id': 'site_footer_1',
        'structure': {
            'type': 'Container',
            'components': [],
            'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen',
            'layout': {
                'width': 980,
                'height': 180,
                'x': 0,
                'y': 681,
                'scale': 1,
                'rotationInDegrees': 0,
                'anchors': [{
                    'distance': 0,
                    'topToTop': 681,
                    'originalValue': 0,
                    'type': 'BOTTOM_PARENT',
                    'locked': true,
                    'targetComponent': 'masterPage'
                }],
                'fixedPosition': false
            },
            'componentType': 'wysiwyg.viewer.components.FooterContainer',
            'style': {
                'type': 'TopLevelStyle',
                'id': 'fc1',
                'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                'style': {'properties': {}, 'propertiesSource': {}, 'groups': {}},
                'componentClassName': '',
                'pageId': '',
                'compId': '',
                'styleType': 'system',
                'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen'
            }
        },
        'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 0}}
    },
    'index': 1
}
