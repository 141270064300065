import React from 'react';

import {
  type PageSelectionComponentOwnProps as NewPageSelectionComponentOwnProps,
  PageSelection as NewPageSelection,
} from './NewPageSelection/PageSelection';

export const PageSelection = (props: NewPageSelectionComponentOwnProps) => {
  return <NewPageSelection {...props} />;
};

export type { PageSelectionComponentProps } from './NewPageSelection/PageSelection';
