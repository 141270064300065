import addPanelDataConsts from '@/addPanelDataConsts';
import * as wixStoreEditorActionsService from '../../wixStoreEditorActionsService';
import * as utils from '@wix/santa-editor-utils';

const setDefaultStyleParams = wixStoreEditorActionsService.setStyleParams([
  {
    key: 'gallery_showTitle',
    param: { value: true },
    type: 'boolean',
  },
]);

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  id: 'wixStoresSliderProductGalleryWidget',
  hide: false,
  title: 'add_preset_Store_Galleries_3',
  subNavigationTitle: 'add_preset_Store_Galleries_3',
  presetTitle: 'add_preset_Store_Galleries_3',
  tooltipTitle: 'add_preset_Store_Galleries_3',
  sectionName: 'relatedProductGalleryWidget',
  subNavigationHide: false,
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.ALWAYS,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
    scaleProportion: addPanelDataConsts.DEFAULT_SCALE_FOR_PRESET,
    iconEnabledForComps: [],
  },
  props: {
    image: utils.isOnWixStudio()
      ? 'wixStore/sections/studio/relatedProducts.png'
      : 'wixStore/sections/relatedProductGalleryRevamp.png',
    imageHover: null,
    onDrop: wixStoreEditorActionsService.onWidgetDrop(setDefaultStyleParams),
    onClick: wixStoreEditorActionsService.onWidgetClick(setDefaultStyleParams),
    items: [
      {
        id: 'Wix_Store_Related_Product_Gallery_1',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          layout: {
            width: 980,
            height: 316,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          data: {
            type: 'TPAWidget',
            metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
            applicationId: '15',
            widgetId: '139a41fd-0b1d-975f-6f67-e8cbdf8ccc82',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'tpaw0',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: { groups: {}, properties: {}, propertiesSource: {} },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          },
          id: 'i6ky9iij',
        },
        preset: {
          rect: { width: 324, height: 160, x: 0, y: 0 },
          label: '',
          tags: null,
          video: {
            poster: 'poster-white-1x1.png',
            source: 'store-video-thumb-slider',
          },
        },
      },
    ],
    compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
  },
};
