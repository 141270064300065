import React, { type FC } from 'react';
import { StickyPanelPlacement } from '@/extensionSlots';
import { frames } from '@/panels';

import styles from './platformExtensionsStickyPanel.scss';

export interface ExtensionsStickyPanelOwnProps {
  panelName: string;
}

const { RightPanelFrame } = frames;

const ExtensionsStickyPanel: FC<ExtensionsStickyPanelOwnProps> = ({
  panelName,
}) => {
  return (
    <div className={styles.wrapper}>
      <StickyPanelPlacement>
        {(integrationProps, ownProps) => (
          <RightPanelFrame
            panelName={panelName}
            title={ownProps.title}
            shouldTranslate={false}
            automationId={ownProps.automationId}
          >
            <div {...integrationProps} className={styles.root} />
          </RightPanelFrame>
        )}
      </StickyPanelPlacement>
    </div>
  );
};

export default ExtensionsStickyPanel;
