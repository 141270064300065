import constants from '@/constants';
import { EditorAPIKey } from '@/apis';
import * as util from '@/util';
import type { Shell } from '@/apilib';

export function createFeedbackApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);

  function updateStateIfNeeded(count: number): void {
    if (editorAPI.store.getState().unreadComments !== count) {
      editorAPI.updateState({
        unreadComments: count,
      });
    }
  }

  async function init(): Promise<void> {
    if (!editorAPI.account.isUserOwner()) {
      return;
    }

    const isSiteSaved =
      !editorAPI.dsRead.generalInfo.isFirstSave() &&
      !editorAPI.dsRead.generalInfo.isDraft();

    if (isSiteSaved) {
      const response = await util.http.fetchJson(
        constants.GET_FEEDBACK.NEW_COUNTER_API,
        {
          method: 'POST',
          body: JSON.stringify({ query: {} }),
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: editorAPI.dsRead.platform.getAppDataByApplicationId(
              constants.APPLICATIONS.META_SITE_APPLICATION_ID,
            )?.instance,
          }),
        },
      );
      const siteFeedbackComments =
        response.commentCounts.length > 0 ? response.commentCounts[0] : {};

      if (siteFeedbackComments.newCount) {
        updateStateIfNeeded(siteFeedbackComments.newCount);
      }
    }
  }

  function getNewCommentsCounter(): number {
    return editorAPI.store.getState().unreadComments;
  }

  return {
    init,
    getNewCommentsCounter,
  };
}
