export enum DealerActionType {
  EditorDeepLink = 'editorDeepLink',
  BMLink = 'dashboardLink',
}

export interface DealerRawAction {
  type: DealerActionType;
  value: string;
}

export interface SetupStepDealerData {
  mainCta: {
    type: DealerActionType;
    value: { key: string; value: string };
  };
  appDefId: string;
  title: string;
  isMandatory: boolean;
  permissions: string;
  metadata: {
    offerId: string;
    offerName: string;
    campaignId: string;
  };
  dynamicParams: {
    completedAt?: string;
  };
}

export interface RecommendedToolDealerData {
  name: string;
  description: string;
  imageSrc: string;
  appDefId: string;
  dashboardLink: string;
  metadata: {
    offerId: string;
    offerName: string;
    campaignId?: string;
  };
}

export interface DealerAppPageAction {
  iconUrl: string;
  onClick: () => void;
}

export interface DealerAppPageData {
  appDefId: string;
  imageUrl?: string;
  title?: string;
  description?: string;

  primaryAction1?: DealerAppPageAction;
  primaryAction2?: DealerAppPageAction;
}
