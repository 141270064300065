// @ts-nocheck
import PropTypes from 'prop-types';
import * as mediaManagerPanelConstants from '../mediaManagerPanelConstants/mediaManagerPanelConstants';

const item = PropTypes.shape({
  name: PropTypes.string.isRequired,
  previewUrl: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  fileUrl: PropTypes.string,
  fileOutput: PropTypes.shape({
    image: PropTypes.arrayOf(PropTypes.object),
  }),
  transcodingStatus: PropTypes.oneOf(
    Object.values(mediaManagerPanelConstants.TRANSCODING_STATUSES),
  ),
});

const presetSection = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(item),
  presetWidth: PropTypes.number,
  shouldDisplayPresetName: PropTypes.bool,
  onItemClick: PropTypes.func,
  getItemPrice: PropTypes.func,
  buyItem: PropTypes.func,
  startItemDrag: PropTypes.func,
  registerItem: PropTypes.func,
  options: PropTypes.shape({
    shouldSetBlueBackgroundToPreset: PropTypes.bool,
    shouldShowLoaderWhenNoItems: PropTypes.bool,
    mockLoadingItemsCount: PropTypes.number,
    folderId: PropTypes.string,
    headerActions: PropTypes.arrayOf(PropTypes.element),
    loaderContainerHeightInRows: PropTypes.number,
  }),
  action: PropTypes.func,
  actionLabel: PropTypes.string,
};

export { item, presetSection };
