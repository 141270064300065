import type { OnDropCallback } from '@/addPanelInfra';
import type { EditorAPI } from '@/editorAPI';
import { triggerNewBlogBiEvent } from '@/tpa';
import type { PresetSectionItem } from '@wix/add-panel-component';
import type { CompStructure } from 'types/documentServices';
import { addCompOnClick, addCompOnDrop } from './addComp';

interface AddBlogOnClickParams {
  item: PresetSectionItem;
  appDefinitionId: string;
  widgetId: string;
  compStructure: CompStructure;
  tags: string;
  itemId: string;
  sectionTitle: string;
  categoryId: string;
  biOrigin: string;
  position: { x: number; y: number } | void;
}

export const addBlogOnClick = (
  editorAPI: EditorAPI,
  {
    item,
    appDefinitionId,
    widgetId,
    compStructure,
    tags,
    itemId,
    sectionTitle,
    categoryId,
    biOrigin,
    position,
  }: AddBlogOnClickParams,
) => {
  triggerNewBlogBiEvent(editorAPI, item.biParams);

  return addCompOnClick(editorAPI, {
    appDefinitionId,
    widgetId,
    compStructure,
    tags,
    itemId,
    sectionTitle,
    categoryId,
    biOrigin,
    position,
  });
};

interface AddBlogOnDropParams {
  item: PresetSectionItem;
  appDefinitionId: string;
  widgetId: string;
  biOrigin: string;
}

export const addBlogOnDrop = (
  editorAPI: EditorAPI,
  { item, appDefinitionId, widgetId, biOrigin }: AddBlogOnDropParams,
): OnDropCallback => {
  triggerNewBlogBiEvent(editorAPI, item.biParams);

  return addCompOnDrop(editorAPI, {
    appDefinitionId,
    widgetId,
    biOrigin,
  });
};
