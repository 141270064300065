:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._panelWrapper_9ph48_7 {
  height: 100%;
  overflow-y: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transition: transform ease 300ms; }

._aiWriterPanel_9ph48_15 {
  width: 288px;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  transition: opacity 250ms ease-in; }
  ._aiWriterPanel_9ph48_15 .composite-text-input-labeled {
    padding: 0 24px 18px; }
  ._aiWriterPanel_9ph48_15 .has-tooltip.info-icon-tooltip:nth-of-type(2) {
    top: -2px; }
  ._aiWriterPanel_9ph48_15 ._wrongPageWrapper_9ph48_29 {
    padding: 0 24px;
    margin-top: 14px; }
  ._aiWriterPanel_9ph48_15 ._wrongPage_9ph48_29 {
    border-radius: 4px;
    background-color: #f7f8f8;
    padding: 9px 18px; }
    ._aiWriterPanel_9ph48_15 ._wrongPage_9ph48_29 .control-text-button {
      vertical-align: top; }
  ._aiWriterPanel_9ph48_15 ._infoBlock_9ph48_38 {
    margin-bottom: -14px;
    padding: 0 24px; }
  ._aiWriterPanel_9ph48_15 ._multiline-input_9ph48_41 {
    height: 108px !important; }
  ._aiWriterPanel_9ph48_15._visible_9ph48_43 {
    opacity: 1;
    visibility: visible; }
  ._aiWriterPanel_9ph48_15 ._tone_9ph48_46 {
    display: flex;
    flex-direction: column;
    gap: 6px; }
    ._aiWriterPanel_9ph48_15 ._tone_9ph48_46 ._toneOfVoice_9ph48_50 {
      gap: 6px; }
    ._aiWriterPanel_9ph48_15 ._tone_9ph48_46 input {
      height: 24px !important;
      font-size: 12px !important;
      font-weight: 400; }
  ._aiWriterPanel_9ph48_15 ._input_9ph48_56 {
    width: 139px; }
  ._aiWriterPanel_9ph48_15 ._button_9ph48_58 {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 14px;
    gap: 10px; }
  ._aiWriterPanel_9ph48_15 ._footer_9ph48_64 {
    display: flex;
    margin-top: auto;
    flex-direction: column;
    justify-content: center; }
