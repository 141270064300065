import { isMeshLayoutEnabled } from '@/layout';
import { sections } from '@/util';
import {
  applyBigElementsLogicIfNeeded,
  scrollToComponent,
} from '../../../utils';
import { isSectionLike, isSection } from '../../../api';

import type { AfterPastePlugin } from 'types/editorPlugins';
import type { Scope } from '@/sections';

const { shiftComponents, getComponentsBelowPosition } = sections;

export const sectionAfterPastePlugin =
  (scope: Scope): AfterPastePlugin =>
  async (_, [compRef]) => {
    const { components, editorAPI } = scope;
    const containerRef = components.getContainer(compRef);
    const isContainerSectionLike = isSectionLike(scope, containerRef);

    if (isContainerSectionLike && components.is.fullWidth(compRef)) {
      scrollToComponent(editorAPI, compRef);
      return;
    }

    if (!isSection(scope, compRef)) {
      scope.enforcement.setEnforcementEnabled(false);
      await applyBigElementsLogicIfNeeded(
        scope,
        compRef,
        isContainerSectionLike,
      ).finally(() => {
        scope.enforcement.setEnforcementEnabled(true);
      });
      return;
    }

    // NOTE: mesh layout anchors will shift next sections automatically
    if (!isMeshLayoutEnabled()) {
      const pastedComponentLayout = components.layout.get_rect(compRef);
      const componentsBelowPosition = getComponentsBelowPosition(
        editorAPI,
        pastedComponentLayout.y,
      );
      const componentsToShift = componentsBelowPosition.filter(
        (ref) => compRef.id !== ref.id,
      );

      shiftComponents(
        editorAPI,
        componentsToShift,
        pastedComponentLayout.height,
      );
    }

    await editorAPI.waitForChangesAppliedAsync();

    scrollToComponent(editorAPI, compRef);
  };
