import type { Trigger } from './pagesReducer';

export const START_LOADING = 'START_LOADING' as const;
export const END_LOADING = 'END_LOADING' as const;
export const SET_TRIGGER = 'SET_TRIGGER' as const;

const startLoading = (pageId: string) => ({
  type: START_LOADING,
  pageId,
});

const endLoading = () => ({
  type: END_LOADING,
});

const setTrigger = (trigger: Trigger) => ({
  type: SET_TRIGGER,
  trigger,
});

export const pagesActions = {
  startLoading,
  endLoading,
  setTrigger,
};

export type PagesActions =
  | ReturnType<typeof startLoading>
  | ReturnType<typeof endLoading>
  | ReturnType<typeof setTrigger>;
