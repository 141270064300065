import constants from '@/constants';
import { sections } from '@/util';
import { selection } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import type { ComponentEditorMetaDataDefinition } from '../types';
import type { CompRef } from 'types/documentServices';

const classicSectionMetadata: ComponentEditorMetaDataDefinition = {
  selectedBeforeDescendants: (editorAPI: EditorAPI, sectionRef: CompRef) => {
    const isRightClick = selection.selectors.isLastClickTypeRight(
      editorAPI.store.getState(),
    );

    const [selectedComp] = editorAPI.selection.getSelectedComponents();
    const isSectionSelected = editorAPI.utils.isSameRef(
      selectedComp,
      sectionRef,
    );

    if (isRightClick) {
      return editorAPI.zoomMode.isStageZoomMode() || !isSectionSelected;
    }

    return sections.isSectionsEnabled() && !isSectionSelected;
  },
  showMarginsIndicator: true,
  focusable: true,
  allowLassoOnContainer: true,
  pinnable: false,
  allowConnectToDB: true,
  multiselectable: false,
  disabledKnobs: Object.values(constants.RESIZE_SIDES),
  verticallyMovable: () => !sections.isSectionsEnabled(),
  removable: (editorAPI: EditorAPI, compRef: CompRef) => {
    const nonRemovableData = editorAPI.sections.getNonRemovableData(compRef);

    return !nonRemovableData;
  },

  gfppVisible: (editorAPI: EditorAPI, sectionRef: CompRef) => {
    if (!sections.isSectionsEnabled()) return true;

    if (editorAPI.sections.isFirstTimeParentSectionLikeFocused(sectionRef))
      return false;

    const lastClickPos = selection.selectors.getLastSelectionClickPos(
      editorAPI.store.getState(),
    );
    const topComp = sections.getTopComponentByXY(editorAPI, lastClickPos);
    return editorAPI.utils.isSameRef(sectionRef, topComp);
  },
};

export default classicSectionMetadata;
