// @ts-nocheck
import React from 'react';
import { translate } from '@/i18n';
import * as baseUI from '@/baseUI';
import { CustomScroll } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { hoc, workspace } from '@/util';

import { mapDispatchToProps } from './blogManagePanelMapper';

import * as blogBi from '../../utils/bi/blogBi';
import { newBlogAppDefId } from '../../utils/blogUtils';

interface BlogManagePanelProps extends ReturnType<typeof mapDispatchToProps> {}

class BlogManagePanel extends React.Component<BlogManagePanelProps> {
  static displayName = 'BlogManagePanel';

  createPost = () => {
    this._openBlogManager('/create-post');

    const params = {
      button_name: 'AddPost',
      app_id: newBlogAppDefId,
      app_name: 'blog',
    };

    this.props.sendBi(blogBi.events.MANAGE_PANEL_BUTTON_CLICK, params);
  };

  manageBlog = () => {
    this._openBlogManager();

    const params = {
      button_name: 'ManageBlog',
      app_id: newBlogAppDefId,
      app_name: 'blog',
    };

    this.props.sendBi(blogBi.events.MANAGE_PANEL_BUTTON_CLICK, params);
  };

  _openBlogManager = (state) => {
    const options = { origin: '6th_button' };
    if (state) {
      options.state = state;
    }
    import('@/tpa').then((tpa) => {
      tpa.superApps.openDashboardUrl(
        '14bcded7-0066-7c35-14d7-466cb3f09103',
        options,
        {
          type: tpa.constants.BI.type.APP_MANAGE_BUTTON,
        },
      );
    });
  };

  render() {
    const symbolName = workspace.isNewWorkspaceEnabled()
      ? 'newBlogManager_NewWorkspace'
      : 'newBlogManager';

    return (
      <div className="blog-manage-panel">
        <CustomScroll heightRelativeToParent="100%">
          <symbols.symbol name={symbolName} className="illustration" />
          <p>{translate('SiteApp_NewBlog_ManageTab_Text')}</p>

          <div className="buttons-container">
            <div>
              <baseUI.button
                label="SiteApp_NewBlog_ManageTab_MainAction"
                onClick={this.createPost}
                className="manage-btn btn-confirm-secondary"
              />
            </div>
            <div>
              <baseUI.button
                label="SiteApp_NewBlog_ManageTab_SecondaryAction"
                onClick={this.manageBlog}
                className="manage-btn btn-confirm-primary"
              />
            </div>
          </div>
        </CustomScroll>
      </div>
    );
  }
}

export default hoc.connect(
  hoc.STORES.STATE_AND_DS,
  null,
  mapDispatchToProps,
)(BlogManagePanel);
