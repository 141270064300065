import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import type { SectionDescription, MobileComponent } from '../../types';

export function detectOverlapping(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
  limit: number,
): boolean {
  const layout = documentServices.components.layout.get(compRef);
  const children = documentServices.components.getChildren(compRef);
  const isOverlapped = layout.y + layout.height > limit;

  if (isOverlapped) {
    return true;
  }

  const areChildrenOverlapped = children.some((childRef: CompRef) =>
    detectOverlapping(documentServices, childRef, limit - layout.y),
  );

  return Boolean(areChildrenOverlapped);
}

export const mergeSections = (
  sectionA: SectionDescription,
  sectionB: SectionDescription,
  componentsOverallOrder: string[],
): SectionDescription => ({
  ...sectionA,
  children: [...sectionA.children, ...sectionB.children].sort(
    (a, b) =>
      componentsOverallOrder.indexOf(a.id) -
      componentsOverallOrder.indexOf(b.id),
  ),
  top: Math.min(sectionA.top, sectionB.top),
  bottom: Math.max(sectionA.bottom, sectionB.bottom),
  isFullWidthTop:
    sectionB.top > sectionA.top
      ? sectionA.isFullWidthTop
      : sectionB.isFullWidthTop,
  isFullWidthBottom:
    sectionB.bottom > sectionA.bottom
      ? sectionB.isFullWidthBottom
      : sectionA.isFullWidthBottom,
});

export const mergeMobileSections = (
  sectionA: SectionDescription,
  sectionB: SectionDescription,
  componentsOverallOrder: string[],
): SectionDescription => ({
  ...sectionA,
  mobile: {
    ...sectionA.mobile,
    children: [...sectionA.mobile?.children, ...sectionB.mobile?.children].sort(
      (a, b) =>
        componentsOverallOrder.indexOf(a.ref.id) -
        componentsOverallOrder.indexOf(b.ref.id),
    ),
    top: Math.min(sectionA.mobile.top, sectionB.mobile.top),
    bottom: Math.max(sectionA.mobile.bottom, sectionB.mobile.bottom),
  },
});

const calculateSectionMinZIndex = (
  items: CompRef[],
  componentsOverallOrder: string[],
): number =>
  Math.min(
    ...items.map(({ id }): number => componentsOverallOrder.indexOf(id)),
  );

export function resolveZIndexOverlapping(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  sectionsDescriptions: SectionDescription[],
): SectionDescription[] {
  const componentsOverallOrder = documentServices.components
    .getChildren(pageRef)
    .map(({ id }: CompRef) => id);

  return sectionsDescriptions.reduce(
    (resolvedSections, currentSection) => {
      if (resolvedSections.length === 0) {
        resolvedSections.push(currentSection);

        return resolvedSections;
      }

      const prevIndex = resolvedSections.length - 1;
      const prevSection = resolvedSections[prevIndex];
      const currentSectionZIndex = calculateSectionMinZIndex(
        currentSection.children,
        componentsOverallOrder,
      );
      const shouldMerge = prevSection.children.some(
        (compRef: CompRef) =>
          detectOverlapping(documentServices, compRef, currentSection.top) &&
          componentsOverallOrder.indexOf(compRef.id) > currentSectionZIndex,
      );

      if (shouldMerge) {
        resolvedSections[prevIndex] = mergeSections(
          prevSection,
          currentSection,
          componentsOverallOrder,
        );
      } else {
        resolvedSections.push(currentSection);
      }

      return resolvedSections;
    },
    <SectionDescription[]>[],
  );
}

export function resolveMobileZIndexOverlapping(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  sectionsDescriptions: SectionDescription[],
  yOrder: number[],
  outOfYOrder: number[],
): {
  sectionsDescriptions: SectionDescription[];
  yOrder: number[];
  outOfYOrder: number[];
} {
  const componentsOverallOrder = documentServices.components
    .getChildren(documentServices.components.getMobileRef(pageRef))
    .map(({ id }: CompRef) => id);

  const resolvedSections: SectionDescription[] = [];
  const resolvedYOrder = [];
  const resolvedOutOfYOrder = [...outOfYOrder];

  resolvedSections[yOrder[0]] = sectionsDescriptions[yOrder[0]];
  resolvedYOrder.push(yOrder[0]);

  for (let yPosition = 1; yPosition < yOrder.length; yPosition++) {
    const prevIndex = yOrder[yPosition - 1];
    const prevSection = resolvedSections[prevIndex];
    const currentIndex = yOrder[yPosition];
    const currentSection = sectionsDescriptions[currentIndex];

    const currentSectionZIndex = calculateSectionMinZIndex(
      currentSection.mobile.children.map(({ ref }) => ref),
      componentsOverallOrder,
    );

    const shouldMergeMobile = prevSection.mobile?.children.some(
      ({ ref: compRef }: MobileComponent) =>
        detectOverlapping(
          documentServices,
          compRef,
          currentSection.mobile.top,
        ) && componentsOverallOrder.indexOf(compRef.id) > currentSectionZIndex,
    );

    if (shouldMergeMobile) {
      resolvedSections[prevIndex] = mergeMobileSections(
        prevSection,
        currentSection,
        componentsOverallOrder,
      );

      resolvedSections[currentIndex] = {
        ...currentSection,
        mobile: null,
      };

      resolvedOutOfYOrder.push(currentIndex);
    } else {
      resolvedSections[currentIndex] = currentSection;
      resolvedYOrder.push(currentIndex);
    }
  }

  // keep sections which is hidden on mobile
  outOfYOrder.forEach((index: number) => {
    resolvedSections[index] = sectionsDescriptions[index];
  });

  return {
    sectionsDescriptions: resolvedSections,
    yOrder: resolvedYOrder,
    outOfYOrder: resolvedOutOfYOrder,
  };
}
