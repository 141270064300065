import { BookingsServiceType } from '../api/bookings-types';
import type { BookingsServiceSkeleton } from '../api/bookings-types';

function getScheduleDefaultTimeRange() {
  const start = new Date();
  const end = new Date();
  start.setDate(start.getDay() + 1);
  start.setHours(0, 0, 0, 0);
  end.setMonth(start.getMonth() + 6);
  end.setHours(0, 0, 0, 0);
  return { start, end };
}

const getScheduleSkeleton = (service: BookingsServiceSkeleton) => {
  const { start } = getScheduleDefaultTimeRange();
  return {
    status: 'CREATED',
    location: {
      locationType: 'OWNER_BUSINESS',
    },
    availability: {
      linkedSchedules: [] as AnyFixMe,
      start: start.toISOString(),
    },
    intervals: [] as AnyFixMe,
    rate: {
      labeledPriceOptions: {
        general: {
          amount: '19.99',
          currency: 'USD',
          downPayAmount: '0',
        },
      },
    },
    title: service.info.name,
  };
};

function getServiceSchedule(service: BookingsServiceSkeleton) {
  const serviceType = service.type;
  const { staffMember } = service;
  const { start, end } = getScheduleDefaultTimeRange();
  if (serviceType === BookingsServiceType.INDIVIDUAL) {
    return {
      ...getScheduleSkeleton(service),
      tags: [serviceType],
      capacity: 1,
      availability: {
        linkedSchedules: [
          {
            scheduleOwnerId: staffMember.id,
            transparency: 'BUSY',
            scheduleId: staffMember.schedules[0].id,
          },
        ],
        constraints: {
          slotDurations: [60],
          timeBetweenSlots: 10,
        },
        start: start.toISOString(),
      },
    };
  }
  if (serviceType === BookingsServiceType.GROUP) {
    return {
      ...getScheduleSkeleton(service),
      tags: [serviceType],
      capacity: 11,
      intervals: [] as AnyFixMe,
    };
  }
  return {
    ...getScheduleSkeleton(service),
    tags: [serviceType],
    capacity: 40,
    intervals: [
      {
        affectedSchedules: [
          {
            scheduleOwnerId: staffMember.id,
            transparency: 'BUSY',
            scheduleId: staffMember.schedules[0].id,
          },
        ],
        start: start.toISOString(),
        end: end.toISOString(),
        frequency: {
          repetition: 1,
        },
        interval: {
          hourOfDay: 9,
          minuteOfHour: 0,
          duration: 540,
          daysOfWeek: 'MON',
        },
      },
      {
        affectedSchedules: [
          {
            scheduleOwnerId: staffMember.id,
            transparency: 'BUSY',
            scheduleId: staffMember.schedules[0].id,
          },
        ],
        start: start.toISOString(),
        end: end.toISOString(),
        frequency: {
          repetition: 1,
        },
        interval: {
          hourOfDay: 9,
          minuteOfHour: 0,
          duration: 540,
          daysOfWeek: 'THU',
        },
      },
    ],
  };
}

export function getCreateServiceRequest(service: BookingsServiceSkeleton) {
  return {
    service: {
      categoryId: service.categoryId,
      info: service.info,
      policy: {
        isBookOnlineAllowed: true,
        bookingsApprovalPolicy: {
          requestsAffectsAvailability: true,
          isBusinessApprovalRequired: false,
        },
        maxParticipantsPerBooking: 1,
      },
      paymentOptions: {
        wixPayOnline: true,
      },
      customProperties: {
        uouHidden: 'false',
      },
    },
    schedules: [getServiceSchedule(service)],
  };
}
