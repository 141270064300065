import React from 'react';
import _ from 'lodash';

import { hoc, cx, workspace } from '@/util';
import * as baseUI from '@/baseUI';
import * as coreBi from '@/coreBi';

import { getMobileElementsData as mobileElementsData } from './mobileElementsData';
import MobileElementSettings from './mobileElementSettings/mobileElementSettings';

import type { ThunkAction } from 'types/redux';

const isNewWorkspace = workspace.isNewWorkspaceEnabled();

interface MobileElementsProps {
  mobileElementId: string;
  sendBI: FunctionFixMe;
  getMobileElementsData: () => any;
  openLeftbarSidePanel?: (comp: React.ReactNode) => void;
}

interface MobileElementsState {
  selectedMobileElement: AnyFixMe;
}

/**
 * This is the main body of the mobileElementsPanels component
 * Due to legacy issues (eg supporting components on the stage that cant even be created) ->
 * -> it was much simpler to just creat a component that is used only in the mobile add panel ->
 * -> than to creat something that supports multiple places to render it in
 */
class MobileElements extends React.Component<
  MobileElementsProps,
  MobileElementsState
> {
  static defaultProps: Partial<MobileElementsProps> = {
    mobileElementId: undefined,
  };

  constructor(props: AnyFixMe) {
    super(props);

    this.state = {
      selectedMobileElement: this.getMobileElementById(
        this.props.mobileElementId,
      ),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: AnyFixMe) {
    if (nextProps.mobileElementId !== this.props.mobileElementId) {
      const mobileElementToSelect = this.getMobileElementById(
        nextProps.mobileElementId,
      );
      this.setState({
        selectedMobileElement: mobileElementToSelect,
      });
    }
  }

  componentDidUpdate(_: AnyFixMe, prevState: AnyFixMe) {
    if (this.state.selectedMobileElement !== prevState.selectedMobileElement)
      this.renderSettingsPanel();
  }

  selectMobileElement = (selectedMobileElement: AnyFixMe) => {
    this.props.sendBI(
      coreBi.events.mobileEditor.mobileTools.MOBILE_ELEMENT_CLICK,
      { element_name: selectedMobileElement.id },
    );

    if (_.isFunction(selectedMobileElement.onClick)) {
      selectedMobileElement.onClick();
    } else {
      this.setMobileElement(selectedMobileElement);
    }
  };

  setMobileElement = (selectedMobileElement: AnyFixMe) => {
    this.setState({ selectedMobileElement });
  };

  getMobileElementById = (mobileElementId: AnyFixMe) => {
    const elementsData = this.props.getMobileElementsData();

    return elementsData.find(function (elementData: AnyFixMe) {
      return elementData.id.toLowerCase() === mobileElementId;
    });
  };

  getMobileElementProps = (mobileElementItem: AnyFixMe) => {
    return Object.assign(mobileElementItem, {
      callback: this.selectMobileElement.bind(this, mobileElementItem),
    });
  };

  isItemSelected = (mobileElementItemId: AnyFixMe) => {
    return (
      this.state.selectedMobileElement &&
      this.state.selectedMobileElement.id === mobileElementItemId
    );
  };

  getClass = (mobileElementItem: AnyFixMe) => {
    return _.defaults(mobileElementItem.classNames, {
      'item-in-list': true,
      selected: this.isItemSelected(mobileElementItem.id),
    });
  };

  getContainerClass = () => {
    return cx({
      'mobile-elements-panel': true,
      'mobile-elements-panel-new-workspace': isNewWorkspace,
    });
  };

  getMobileElementSettingsProps = () => {
    return Object.assign(this.state.selectedMobileElement, {
      onClick: () => this.setState({ selectedMobileElement: undefined }),
    });
  };

  renderSettingsPanel = () => {
    if (this.state.selectedMobileElement) {
      this.props?.openLeftbarSidePanel(
        <MobileElementSettings {...this.getMobileElementSettingsProps()} />,
      );
    }
  };

  render() {
    return (
      <div className={this.getContainerClass()}>
        <div className="items-wrapper">
          {this.props
            .getMobileElementsData()
            .map((mobileElementItem: AnyFixMe) => (
              <div
                key={mobileElementItem.id}
                className={cx(this.getClass(mobileElementItem))}
              >
                <baseUI.listItemWithSymbol
                  key="itemWithSymbol"
                  {...this.getMobileElementProps(mobileElementItem)}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }
}

const sendBI =
  (): ThunkAction =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.bi.event;

const getMobileElementsData =
  (): ThunkAction =>
  (dispatch, getState, { editorAPI }) =>
    mobileElementsData(editorAPI);

const mapDispatchToProps = { sendBI, getMobileElementsData };

const connected = hoc.connect(
  hoc.STORES.EDITOR_API,
  undefined,
  mapDispatchToProps,
)(MobileElements);

export { connected as MobileElements };
