:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._addSectionCategoriesWrapper_1nygj_7 {
  display: flex;
  flex-direction: column;
  background-color: #f7f8f8; }
  ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 {
    overflow-y: auto;
    background-color: #f7f8f8;
    display: inline-block;
    height: 100%;
    padding: 12px 0;
    vertical-align: top;
    max-width: 186px;
    scrollbar-width: thin;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_noScroll_1nygj_21 {
      overflow-y: visible; }
    ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7::-webkit-scrollbar {
      background-color: #f7f8f8;
      width: 6px; }
    ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7::-webkit-scrollbar-track {
      background-color: #f7f8f8; }
    ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7::-webkit-scrollbar-thumb {
      background-color: #dfe5eb;
      border-radius: 16px;
      opacity: 0.3; }
    ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7:hover::-webkit-scrollbar-thumb {
      opacity: 0.5; }
    ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7::-webkit-scrollbar-button {
      display: none; }
    ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._addBlankSection_1nygj_36 {
      margin: 2px 0 7px 12px;
      height: 18px;
      color: #116dff;
      background-color: #f7f8f8;
      line-height: 18px;
      padding: 0 12px;
      font-size: 12px;
      font-weight: 500; }
      ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._addBlankSection_1nygj_36 > ._symbol_1nygj_45 {
        fill: #116dff;
        margin-right: 6px; }
      ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._addBlankSection_1nygj_36:hover {
        color: #5999ff;
        cursor: pointer; }
        ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._addBlankSection_1nygj_36:hover > ._symbol_1nygj_45 {
          fill: #5999ff; }
      ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._addBlankSection_1nygj_36 > ._addBlankSectionLabel_1nygj_53 {
        color: #116dff;
        background-color: #f7f8f8; }
        ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._addBlankSection_1nygj_36 > ._addBlankSectionLabel_1nygj_53:hover {
          color: #5999ff;
          cursor: pointer; }
          ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._addBlankSection_1nygj_36 > ._addBlankSectionLabel_1nygj_53:hover > ._symbol_1nygj_45 {
            fill: #5999ff; }
    ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._categoryDivider_1nygj_61 {
      height: 1px;
      width: 156px;
      cursor: auto;
      padding: 6px 0;
      margin: 1px 0 12px; }
    ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._categoryItem_1nygj_67 {
      cursor: pointer;
      font-size: 18px;
      max-height: 36px;
      min-height: 30px;
      padding: 0 12px;
      position: relative; }
      ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._categoryItem_1nygj_67 > ._categoryNameWrapper_1nygj_74 {
        border-radius: 20px;
        display: inline-block;
        overflow: hidden;
        padding: 0 12px;
        position: relative;
        max-width: 140px;
        height: 30px;
        color: #000624;
        justify-content: center; }
        ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._categoryItem_1nygj_67 > ._categoryNameWrapper_1nygj_74:hover {
          background-color: #e7f0ff;
          color: #116dff; }
          ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._categoryItem_1nygj_67 > ._categoryNameWrapper_1nygj_74:hover > ._categoryName_1nygj_74 {
            color: #116dff; }
        ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._categoryItem_1nygj_67 > ._categoryNameWrapper_1nygj_74 > ._categoryName_1nygj_74 {
          color: #000624;
          display: inline-block;
          position: relative;
          height: 18px;
          top: calc(50% - 18px / 2 - 2px);
          cursor: pointer; }
        ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._categoryItem_1nygj_67 > ._categoryNameWrapper_1nygj_74 > ._categoryIcon_1nygj_96 {
          position: relative;
          top: 7px;
          margin: -1px 0 0 4px; }
      ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._categoryItem_1nygj_67 > ._categoryNameWrapperSelected_1nygj_100 {
        background-color: #d6e6fe;
        color: #0057e1;
        border-radius: 20px;
        display: inline-block;
        overflow: hidden;
        padding: 0 12px;
        position: relative;
        max-width: 140px;
        height: 30px;
        vertical-align: middle; }
        ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._categoryItem_1nygj_67 > ._categoryNameWrapperSelected_1nygj_100 > ._categoryName_1nygj_74 {
          color: #0057e1;
          display: inline-block;
          position: relative;
          height: 18px;
          top: calc(50% - 18px / 2 - 2px);
          cursor: pointer; }
        ._addSectionCategoriesWrapper_1nygj_7 ._addSectionCategories_1nygj_7 > ._categoryItem_1nygj_67 > ._categoryNameWrapperSelected_1nygj_100 > ._categoryIcon_1nygj_96 {
          position: relative;
          top: 7px;
          margin: -1px 0 0 4px; }
  ._addSectionCategoriesWrapper_1nygj_7 ._bottomDrawer_1nygj_122 {
    height: 44px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 18px; }
