"use strict";

const textInputSection = require("../sections/developer/textInputSection");
const radioGroupSection = require("../sections/developer/radioGroupSection");
const checkboxGroupSection = require("../sections/developer/checkboxGroupSection");
const selectionSection = require("../sections/developer/selectionSection");
const checkboxSection = require("../sections/developer/checkboxSection");
const toggleSwitchSection = require("../sections/developer/toggleSwitchSection");
const textAreaSection = require("../sections/developer/textAreaSection");
const datePickerSection = require("../sections/developer/datePickerSection");
const fileUploaderSection = require("../sections/developer/fileUploaderSection");
const gridSection = require("../sections/developer/gridSection");
const paginationSection = require("../sections/developer/paginationSection");
const ratingsDisplaySection = require("../sections/developer/ratingsDisplaySection");
const sliderSection = require("../sections/developer/sliderSection");
const rangeSliderSection = require("../sections/developer/rangeSliderSection");
const captchaSection = require("../sections/developer/captchaSection");
const ratingsInputSection = require("../sections/developer/ratingsInputSection");
const richTextBoxSection = require("../sections/developer/richTextBoxSection");
const timePickerSection = require("../sections/developer/timePickerSection");
const addressInputSection = require("../sections/developer/addressInputSection");
const progressBarSection = require("../sections/developer/progressBarSection");
const signatureInputSection = require("../sections/developer/signatureInputSection");
const searchBoxSection = require("../sections/developer/searchBoxSection");
const selectionTagsListSection = require("../sections/developer/selectionTagsListSection");
const shareButtonsSection = require("../sections/developer/shareButtonsSection");

const sections = {
  textInputSection,
  radioGroupSection,
  checkboxGroupSection,
  selectionSection,
  checkboxSection,
  toggleSwitchSection,
  textAreaSection,
  datePickerSection,
  fileUploaderSection,
  gridSection,
  paginationSection,
  ratingsDisplaySection,
  sliderSection,
  rangeSliderSection,
  captchaSection,
  ratingsInputSection,
  richTextBoxSection,
  timePickerSection,
  addressInputSection,
  progressBarSection,
  signatureInputSection,
  searchBoxSection,
  selectionTagsListSection,
  shareButtonsSection,
};

module.exports = sections;
