import type { ThunkAction } from 'types/redux';

import * as coreBi from '@/coreBi';
import constants from '@/constants';
import { editorWixRecorder, fixedStage } from '@/util';
import * as stateManagement from '@/stateManagement';
import type { SwitchEditorModeInteractionStartedSource } from 'types/fedops/mobileEditorInteraction';

const { MODES } = constants.ROOT_COMPS.TOPBAR;
const biEvent = coreBi.events.topbar.top_bar_click;

const addWixRecorderLabel = (category: string) =>
  editorWixRecorder.addLabel(`${category} top bar clicked`);

const sendBi =
  (category: string, isSitePublished: boolean): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    const isPreviewMode = editorAPI.preview.isInPreviewMode();
    const isZoomOut = editorAPI.zoomMode.isInZoomMode();
    const stageMode = isZoomOut ? MODES.ZOOM_OUT : MODES.EDITOR;

    const biParams = {
      category,
      origin: isPreviewMode ? MODES.PREVIEW : stageMode,
      is_published: isSitePublished,
      isZoomOut,
      isPreview: isPreviewMode,
      state: fixedStage.getTopBarStateBiParamValue(editorAPI),
    };

    dispatch(stateManagement.bi.actions.event(biEvent, biParams));
  };

const toggleMobileEditor =
  (
    toMobile: boolean,
    sourceOfStart: SwitchEditorModeInteractionStartedSource,
    category: string,
  ): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    editorAPI.setEditorMode(toMobile, sourceOfStart);
    const isSitePublished = editorAPI.dsRead?.generalInfo.isSitePublished();

    addWixRecorderLabel(category);
    dispatch(sendBi(category, isSitePublished));
  };

export default toggleMobileEditor;
