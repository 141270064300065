import { type FC, useContext, useEffect } from 'react';
import constants from '@/constants';
import { ExtensionSlotMenuAPIContext } from './extensionSlotMenuAPIProvider';
import type {
  ExtensionSlotMenuItemEntry,
  HelpMenuItemEntry,
  ToolsMenuItemEntry,
} from './extensionSlotMenuItemTypes';

const { TOOLS_MENU_ITEMS, HELP_MENU_ITEMS } = constants.ROOT_COMPS.TOPBAR;

export interface ExtensionSlotMenuItemProps {
  item: ExtensionSlotMenuItemEntry;
  menuItemKey: string;
}

function getMenuItemDefinition(item: ExtensionSlotMenuItemEntry, type: string) {
  switch (type) {
    case HELP_MENU_ITEMS.HELP_EXTENSION_SLOT:
      item = item as HelpMenuItemEntry;
      return {
        translatedLeftTitle: item.leftTitle,
        translatedRightTitle: item.rightTitle,
        translatedDescription: item.description,
        symbolName: item.symbolName,
        image: item.image,
        illustrationClassName: item.illustrationClassName,
        onClick: item.onClick,
        isDisabled: item.isDisabled,
      };
    case TOOLS_MENU_ITEMS.EXTENSIONS_SLOT:
      item = item as ToolsMenuItemEntry;
      return {
        translatedLeftTitle: item.text,
        onClick: item.onClick,
      };

    default:
      // eslint-disable-next-line no-console
      console.warn(`Unknown menu item type: ${type}`);
  }
}

export const ExtensionSlotMenuItem: FC<ExtensionSlotMenuItemProps> = (
  props,
) => {
  const menuAPI = useContext(ExtensionSlotMenuAPIContext);

  const { item, menuItemKey } = props;

  useEffect(() => {
    const menuItem = getMenuItemDefinition(item, menuItemKey);

    menuAPI.registerExtensionSlotItem(menuItem, menuItemKey);

    return () => {
      menuAPI.unregisterExtensionSlotItem(menuItem, menuItemKey);
    };
  }, [item, menuAPI, menuItemKey]);

  return null;
};
