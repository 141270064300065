import type { EditorAPI } from '@/editorAPI';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import type { ComponentRef } from '@wix/platform-editor-sdk';
import type { WidgetPointer } from '@wix/editor-platform-host-integration/ui';
import type { CompRef, AppDataComponent } from '@wix/document-services-types';
import navigateAndOpenAppService from './navigateAndOpenAppService';
import * as appMarketTabService from '../appMarket/services/appMarketTabService';

const addWidgetPlugin = async (
  editorAPI: EditorAPI,
  slotComponentRef: ComponentRef,
  widgetRef: WidgetPointer,
): Promise<CompRef> => {
  const slotInfo =
    await editorAPI.platform.widgetPlugins.getWidgetSlot(slotComponentRef);

  if (slotInfo?.pluginInfo !== undefined) {
    await editorAPI.platform.widgetPlugins.uninstallWidgetPlugin(
      slotComponentRef,
    );
  }

  return await editorAPI.platform.widgetPlugins.installWidgetPlugin(
    slotComponentRef,
    widgetRef,
    { origin: 'appMarket' },
  );
};

const autoInstallAppWidgetPlugin = async (
  editorAPI: EditorAPI,
  appDefinitionId: string,
  components: AppDataComponent[],
  shouldNavigate: boolean,
): Promise<CompRef | null> => {
  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  const pluginInstallationParams =
    await platformAPI.slots.getPluginInstallationParams(
      appDefinitionId,
      components,
    );

  if (!pluginInstallationParams) return null;

  const pluginRef = await addWidgetPlugin(
    editorAPI,
    pluginInstallationParams.slotComponentRef,
    pluginInstallationParams.widgetRef,
  );

  appMarketTabService.closeAppMarketTab(
    editorAPI.panelManager.closePanelByName,
  );

  editorAPI.panelManager.closeAllPanels();

  await editorAPI.waitForChangesAppliedAsync();

  const selectWidgetPlugin = () => {
    editorAPI.selection.selectComponentByCompRef(pluginRef);
  };

  if (shouldNavigate) {
    navigateAndOpenAppService.navigateAndScrollToCompByCompPageData(
      editorAPI,
      {
        pageId: pluginInstallationParams.pageId,
        compId: pluginRef.id,
      },
      selectWidgetPlugin,
    );
  }

  return pluginRef;
};

export { autoInstallAppWidgetPlugin };
