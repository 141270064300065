:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_h38yo_7 {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none; }
  ._container_h38yo_7._zoomMode_h38yo_15 {
    pointer-events: all;
    background-color: transparent; }
    ._container_h38yo_7._zoomMode_h38yo_15._hovered_h38yo_18 {
      z-index: 1; }
  ._container_h38yo_7._bothSelectedAndHovered_h38yo_20:before, ._container_h38yo_7._bothSelectedAndHovered_h38yo_20:after,
  ._container_h38yo_7._bothSelectedAndHovered_h38yo_20 ._frame_h38yo_21, ._container_h38yo_7._selected_h38yo_21:before, ._container_h38yo_7._selected_h38yo_21:after,
  ._container_h38yo_7._selected_h38yo_21 ._frame_h38yo_21 {
    border-color: #fe620f; }
  ._container_h38yo_7._overlay_h38yo_24 {
    background-color: rgba(254, 98, 15, 0.1); }
  ._container_h38yo_7:before, ._container_h38yo_7:after {
    content: '';
    width: 100%;
    position: absolute;
    pointer-events: none; }
  ._container_h38yo_7:before {
    border-top: 3px solid #fdd1ba; }
  ._container_h38yo_7._footer_h38yo_33:before {
    top: 0; }
  ._container_h38yo_7:after {
    border-bottom: 3px solid #fdd1ba;
    bottom: 0; }
  ._container_h38yo_7._mouseOpsInProgress_h38yo_38 {
    background-color: transparent; }

._resizeHandleContainer_h38yo_41 {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 100;
  pointer-events: all;
  cursor: pointer;
  bottom: 0; }
  ._resizeHandleContainer_h38yo_41._mobileEditor_h38yo_49 {
    left: auto; }

._emptyStateContainer_h38yo_52 {
  display: flex;
  height: 100%;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: all;
  z-index: 100; }
  ._emptyStateContainer_h38yo_52._liteMode_h38yo_60 {
    flex-direction: row;
    align-items: center; }
  ._emptyStateContainer_h38yo_52 ._textContainer_h38yo_63 {
    display: inline-block;
    margin-top: 3px;
    margin-right: 5px;
    line-height: 18px; }
  ._emptyStateContainer_h38yo_52 ._linksContainer_h38yo_68,
  ._emptyStateContainer_h38yo_52 > .control-text {
    color: #3b4057; }
  ._emptyStateContainer_h38yo_52._light_h38yo_71 ._linksContainer_h38yo_68,
  ._emptyStateContainer_h38yo_52._light_h38yo_71 > .control-text {
    color: #fff; }
  ._emptyStateContainer_h38yo_52 ._linksContainer_h38yo_68 {
    display: inline-block;
    margin-top: 3px;
    line-height: 18px; }
    ._emptyStateContainer_h38yo_52 ._linksContainer_h38yo_68 > .control-text-button {
      vertical-align: bottom; }

._frame_h38yo_21 {
  border: 3px solid #fdd1ba;
  border-bottom: 0;
  border-top: 0;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
