// @ts-nocheck
import _ from 'lodash';

function mapEditorAPI({ editorAPI }, props) {
  return {
    close: _.partial(editorAPI.panelManager.closePanelByName, props.panelName),
    openHelpCenter: editorAPI.panelManager.openHelpCenter,
    selectedComponentType: editorAPI.selection.getSelectedComponentType(),
    sendBi: editorAPI.bi.event,
  };
}

export default mapEditorAPI;
