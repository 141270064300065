const _ = require("lodash")
const addPanelDataConsts = require("@/addPanelDataConsts")
const structures = require("./inlinePopupToggleStructures.json")

const ITEMS_PER_ROW = 4
const ITEM_WIDTH = 62
const ITEM_HEIGHT = 62
const OFFSET = {
    X: 11,
    Y: 20
}
const ITEM_SPACING = {
    X: 18,
    Y: 18
}

const inlinePopupToggleBaseStructure = {
    type: "Component",
    skin: "wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin1",
    data: {type: "InlinePopupToggle"},
    layout: {
        width: 50,
        height: 50,
        x: 60,
        y: 60,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false
    },
    componentType: "wysiwyg.viewer.components.InlinePopupToggle",
    style: "inlinePopupToggleSkin1",
    activeModes: {}
}

function getItem(structureChanges, index) {
    const col = index % ITEMS_PER_ROW
    const row = Math.floor(index / ITEMS_PER_ROW)
    const x = OFFSET.X + (ITEM_WIDTH + ITEM_SPACING.X) * col
    const y = OFFSET.Y + (ITEM_HEIGHT + ITEM_SPACING.Y) * row

    return {
        id: `Special_Inline_Popup_Toggle_Style_${index}`,
        preset: {
            rect: {width: ITEM_WIDTH, height: ITEM_HEIGHT, x, y},
            label: "",
            video: {
                poster: "poster-white-1x1.png",
                source: `designpanel/tiny-menu/v1/hamburger-thumb-${index + 1}`
            }
        },
        structure: _.merge({}, inlinePopupToggleBaseStructure, structureChanges)
    }
}

module.exports = {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: "Mobile_Menu_Icon_Design_Section_Discover_Icons_Label",
    showSectionHeader: false,
    additionalBehaviours: {
        labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.VIDEO,
        iconEnabledForComps: {}
    },
    props: {
        image: "compPanels/inlinePopupToggle/presets/hamburger-panel.png",
        retinaImage: {
            src: "compPanels/inlinePopupToggle/presets/hamburger-panel-@2x.png",
            dimensions: {
                width: 648,
                height: 844
            }
        },
        items: structures.map(getItem),
        compTypes: ["wysiwyg.viewer.components.InlinePopupToggle"]
    }
}
