:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._empty-state-container_u0qhu_7 {
  text-align: center;
  padding: 24px; }
  ._empty-state-container_u0qhu_7 ._icon_u0qhu_10 {
    width: 120px;
    height: 120px; }
  ._empty-state-container_u0qhu_7 ._title-container_u0qhu_13 {
    margin: 18px auto 0;
    text-align: center;
    max-width: 280px; }
  ._empty-state-container_u0qhu_7 ._subtitle_u0qhu_17 {
    margin: 6px auto 18px;
    max-width: 255px;
    text-align: center; }
  ._empty-state-container_u0qhu_7 ._button_u0qhu_21 {
    max-width: 240px;
    margin: 6px auto 0;
    display: block;
    text-align: center; }
  ._empty-state-container_u0qhu_7 ._link_u0qhu_26 {
    max-width: 240px;
    margin: 6px auto 0;
    display: block;
    font-size: 14px; }
