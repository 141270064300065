const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_17",
  preset: { rect: { width: 176, height: 69, x: 147, y: 460 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        groups: {},
        properties: {
          "$st-css":
            ":import{\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: StylableButton\n}\n.root{\n    -st-extends: StylableButton;\n    transition: all 0.2s ease, visibility 0s;\n    background: value(site_1_1);\n    border: 1px solid #0C4426\n}\n.root:hover{\n    border: 1px solid value(site_1_5);\n    background: #0C4426\n}\n.root:hover::label{\n    color: #FFFFFE\n}\n.root:disabled{\n    background: #E2E2E2\n}\n.root:disabled::label{\n    color: #8F8F8F\n}\n.root:disabled::icon{\n    fill: #8F8F8F\n}\n.root::container{\n    transition: inherit\n}\n.root::label{\n    transition: inherit;\n    font-size: 15px;\n    font-weight: normal;\n    font-style: normal;\n    font-family: \"playfair display\",serif;\n    letter-spacing: 0.05em;\n    color: #0C4426\n}\n.root::icon{\n    transition: inherit;\n    width: 10px;\n    height: 10px;\n    fill: value(site_1_1);\n    display: none\n}\n.root:hover::icon{\n    fill: value(site_1_5)\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "uhr-754",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "mtq-2676",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_featured"),
      svgId: "b861b040274141de9c08999ec3b76edf.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-km38ptmw",
    layout: {
      x: 161,
      fixedPosition: false,
      y: 476,
      scale: 1,
      height: 40,
      rotationInDegrees: 0,
      width: 153,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "mtq-2662", pageId: "c1dmp" },
    },
    metaData: { sig: "uhr-749", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
