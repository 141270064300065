import _ from 'lodash';
import { calculateCommonStyle } from '@/advancedStylePanel';
import * as styles from '@/styles';
import type { Dispatch } from 'types/redux';
const { styleUtil } = styles.advancedStyle;

const getCommonStyleForMultiComponents = (
  editorAPI: AnyFixMe,
  selectedComponents: AnyFixMe,
) => {
  const getCompSkinDef = (compRef: AnyFixMe) =>
    editorAPI.theme.skins.getSkinDefinition(
      editorAPI.components.skin.get(compRef),
    );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  const params = _.reduce(
    selectedComponents,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    (commonSkinDef, comp) => _.assign(commonSkinDef, getCompSkinDef(comp)),
    {},
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/keys
  const paramKeys = _.keys(params);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  const styleByComp = _.reduce(
    selectedComponents,
    (styleByComp, compRef) =>
      _.set(
        styleByComp,
        compRef.id,
        editorAPI.components.style.get(compRef)?.style?.properties ?? {},
      ),
    {},
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  const skinDefByComp = _.reduce(
    selectedComponents,
    (styleByComp, compRef) =>
      _.set(styleByComp, compRef.id, getCompSkinDef(compRef)),
    {},
  );
  const commonStyle = calculateCommonStyle(
    params,
    paramKeys,
    styleByComp,
    skinDefByComp,
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  const propertiesSource = _.reduce(
    commonStyle,
    (source, value, param) =>
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(source, { [param]: styleUtil.getPropertySource(value) }),
    {},
  );
  return {
    properties: commonStyle,
    propertiesSource,
  };
};

const updateMultiComponentStyle =
  (
    multiSelectedComponents: AnyFixMe,
    styleParam: AnyFixMe,
    value: AnyFixMe,
    source: AnyFixMe,
    forcedUpdates: AnyFixMe,
  ) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    return _.forEach(multiSelectedComponents, (compRef) => {
      const styleDef = _.cloneDeep(editorAPI.components.style.get(compRef));
      const stylePropertiesPath = 'style.properties';
      const alphaParam = `alpha-${styleParam}`;
      const alphaParamPath = `${stylePropertiesPath}.${alphaParam}`;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(forcedUpdates, (v, k) => {
        _.set(styleDef, `${stylePropertiesPath}.${k}`, v);
      });

      _.set(styleDef, `${stylePropertiesPath}.${styleParam}`, value);
      if (Number(_.get(styleDef, alphaParamPath)) === 0.0) {
        _.set(styleDef, alphaParamPath, 1);
      }

      if (styleDef.style.propertiesSource) {
        _.set(styleDef, `style.propertiesSource.${styleParam}`, source);
      }

      editorAPI.components.style.update(compRef, styleDef);
    });
  };

const updateComponentStyle =
  (
    selectedComponent: AnyFixMe,
    multiSelectedComponents: AnyFixMe,
    styleDef: AnyFixMe,
    layoutToMaintain: AnyFixMe,
    dontAddToUndoRedoStack: AnyFixMe,
    dontRemoveScopedStyle: boolean,
    shouldRemoveScopedStyle: boolean,
    isHover?: boolean,
  ) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    if (multiSelectedComponents) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(multiSelectedComponents, (compRef) =>
        editorAPI.components.style.update(
          compRef,
          styleDef,
          dontAddToUndoRedoStack,
          dontRemoveScopedStyle,
          isHover,
        ),
      );
      return;
    }
    editorAPI.components.layout.update(
      selectedComponent,
      layoutToMaintain,
      true,
    );
    editorAPI.components.style.update(
      selectedComponent,
      styleDef,
      dontAddToUndoRedoStack,
      dontRemoveScopedStyle,
      shouldRemoveScopedStyle,
      isHover,
    );
  };

const getComponentStyle =
  (selectedComponent: AnyFixMe, multiSelectedComponents: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const compStyle = editorAPI.components.style.get(selectedComponent);
    if (multiSelectedComponents) {
      const commonStyle = getCommonStyleForMultiComponents(
        editorAPI,
        multiSelectedComponents,
      );
      return _.set(compStyle, 'style', commonStyle);
    }
    return editorAPI.components.style.get(selectedComponent);
  };

const getMultiSelectedComponentsStyle =
  (multiSelectedComponents: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    _.reduce(
      multiSelectedComponents,
      (acc, compRef) =>
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/concat
        _.concat(acc, [
          {
            compRef,
            originalDef: editorAPI.components.style.get(compRef),
          },
        ]),
      [],
    );

export const mapDispatchToProps = (
  dispatch: Dispatch,
  {
    updateStyle,
    getStyle,
    selectedComponent,
    multiSelectedComponents,
  }: AnyFixMe,
) => ({
  updateStyle:
    updateStyle ||
    ((
      styleDef: AnyFixMe,
      isTemporary: AnyFixMe,
      layoutToMaintain: AnyFixMe,
      dontAddToUndoRedoStack: AnyFixMe,
      dontRemoveScopedStyle: AnyFixMe,
      shouldRemoveScopedStyle: AnyFixMe,
      isHover: boolean,
    ) =>
      dispatch(
        updateComponentStyle(
          selectedComponent,
          multiSelectedComponents,
          styleDef,
          layoutToMaintain,
          dontAddToUndoRedoStack,
          dontRemoveScopedStyle,
          shouldRemoveScopedStyle,
          isHover,
        ),
      )),

  getStyle:
    getStyle ||
    ((compRef = selectedComponent) =>
      dispatch(getComponentStyle(compRef, multiSelectedComponents))),

  getMultiSelectedComponentsStyle: () =>
    dispatch(getMultiSelectedComponentsStyle(multiSelectedComponents)),

  onStyleParamChanged: (
    styleParam: AnyFixMe,
    value: AnyFixMe,
    source: AnyFixMe,
    forcedUpdates: AnyFixMe,
  ) =>
    dispatch(
      updateMultiComponentStyle(
        multiSelectedComponents,
        styleParam,
        value,
        source,
        forcedUpdates,
      ),
    ),
});
