import React, { Component, type ComponentType } from 'react';

interface WithDelayedOpenDropDownBehaviorProps {
  openedDropPanel: string;
  openDropPanel: (key: string) => string;
  closeDropPanel: (force?: boolean) => string;
}
interface WithDelayedOpenDropDownBehaviorState {
  openedKey: string | null;
}

interface ComponentWithDelayedOpenDropDownBehaviorProps {
  openDropPanel: (key: string) => void;
  closeDropPanel: (force?: boolean) => void;
}

const FIRST_OPEN_DROP_PANEL_DELAY = 300;

const withDelayedOpenDropDownBehavior = <
  P extends WithDelayedOpenDropDownBehaviorProps,
>(
  WrappedComponent: ComponentType<
    P & ComponentWithDelayedOpenDropDownBehaviorProps
  >,
) => {
  class WithDelayedOpenDropDownBehavior extends Component<
    WithDelayedOpenDropDownBehaviorProps,
    WithDelayedOpenDropDownBehaviorState
  > {
    state: WithDelayedOpenDropDownBehaviorState = { openedKey: null };
    private openDropPanelTimer: number;

    openDropPanel = (key: AnyFixMe) => {
      window.clearTimeout(this.openDropPanelTimer);

      this.setState({ openedKey: key });

      if (this.props.openedDropPanel) {
        this.props.openDropPanel(key);
        return;
      }

      this.openDropPanelTimer = window.setTimeout(
        () => this.props.openDropPanel(this.state.openedKey),
        FIRST_OPEN_DROP_PANEL_DELAY,
      );
    };

    closeDropPanel = (force?: boolean) => {
      this.props.closeDropPanel(force);

      this.setState({ openedKey: null });
    };

    render() {
      const { props } = this;

      return React.createElement(
        WrappedComponent,
        Object.assign({}, props as P, {
          openDropPanel: this.openDropPanel,
          closeDropPanel: this.closeDropPanel,
        }),
      );
    }
  }

  return WithDelayedOpenDropDownBehavior;
};

export default withDelayedOpenDropDownBehavior;
