import React from 'react';
import * as mediaPanelConsts from '../../../mediaManagerPanelConstants/mediaManagerPanelConstants';
import ShutterstockMediaBaseSection from './shutterstockMediaBaseSection';

class ShutterstockImagesSection extends React.Component {
  getProps() {
    return {
      title: 'Media_Panel_Shutterstock_Images_Section_Label',
      mediaType: mediaPanelConsts.SHUTTERSTOCK_MEDIA_TYPE.IMAGE,
      ...this.props,
    };
  }

  render() {
    return <ShutterstockMediaBaseSection {...this.getProps()} />;
  }
}

export default ShutterstockImagesSection;
