import React, { useEffect, useRef } from 'react';
import WixRichText from '@wix/wix-richtext';
import { mapDispatchToProps } from './textEditorWrapperMapper';
import { connectWithScope, type InferComponentProps } from '@/apilib';
import { QuickEditScope } from '../quickEditEntryPoint';
import experiment from 'experiment';
import { NULL_VALUE_RECORD } from '../consts';
import { textUtils } from '@/util';

import type { CompRef } from '@wix/document-services-types';
import type { TextManager, CmdState } from 'types/data';
import type { ComponentValueRecord } from '@/quickEditEngine';

export interface TextEditorWrapperOwnProps {
  compRef: CompRef;
  compText: string;
  onTextManagerReady: (manager: TextManager) => void;
  onCommandsStateChange: (cmdState: CmdState) => void;
}

type TextEditorWrapperProps = InferComponentProps<
  any,
  typeof mapDispatchToProps,
  TextEditorWrapperOwnProps
>;

const TextEditorWrapper: React.FC<TextEditorWrapperProps> = ({
  documentServices,
  onDataChange,
  onTextManagerReady,
  compText,
  getLinksManager,
  getLinksHelper,
  reportTextChangedIfNeeded,
  onCommandsStateChange,
}: TextEditorWrapperProps) => {
  const valueTextRef = useRef<ComponentValueRecord>({ ...NULL_VALUE_RECORD });
  const textEditorRef = useRef<HTMLDivElement>(null);

  const monitorTextChanges = (currentValue: any) => {
    if (!valueTextRef.current.initial) {
      valueTextRef.current.initial = currentValue;
    }
    valueTextRef.current.last = currentValue;
  };

  useEffect(() => {
    monitorTextChanges(textUtils.getInnerText(compText));
  }, [compText]);

  useEffect(() => {
    return () => {
      reportTextChangedIfNeeded(valueTextRef);
      valueTextRef.current = { ...NULL_VALUE_RECORD };
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReady = (manager: TextManager) => {
    onTextManagerReady(manager, textEditorRef.current);
  };

  const onCutOrCopy = () => getLinksHelper().cacheCopiedLinks();

  return (
    <div ref={textEditorRef}>
      <WixRichText.TextEditor
        initialData={''}
        onReady={onReady}
        validateHtml={false}
        showToolbar={false}
        documentServicesApi={documentServices}
        isExperimentOpen={experiment.isOpen}
        onCut={onCutOrCopy}
        onCopy={onCutOrCopy}
        onDataChange={onDataChange}
        linksManager={getLinksManager()}
        shouldInjectFontsToDocument={false}
        autoGrowOnStartup={true}
        waitBeforeShowRichText={0}
        onCommandsStateChange={onCommandsStateChange}
      />
    </div>
  );
};

export default connectWithScope(
  () => QuickEditScope,
  TextEditorWrapper,
  null,
  mapDispatchToProps,
) as React.ComponentType<TextEditorWrapperOwnProps>;
