import { createReduxStore } from '@/apilib';

interface State {
  isStageHorizontallyScrollable: boolean;
}

const getInitialState = (): State => ({ isStageHorizontallyScrollable: false });

export const FixedStageStore = createReduxStore({
  getInitialState,
  selectors: {
    isStageHorizontallyScrollable: (state) =>
      state.isStageHorizontallyScrollable,
  },
  actions: {
    setIsStageHorizontallyScrollable: (state, newValue: boolean) => {
      return { ...state, isStageHorizontallyScrollable: newValue };
    },
  },
});
