const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_22",
  preset: { rect: { width: 162, height: 70, x: 162, y: 600 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        properties: {
          "$st-css":
            ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #AE633F;border-radius: 10px;border: 0px solid rgb(174, 99, 63)}.root:hover {\n    background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(180deg, rgba(40, 38, 38, 0.22) 0%, rgba(40, 38, 38, 0.22) 100%), #AE633F;\n}.root:disabled {\n    background: #DBDBDB;\n    border: 0px solid rgb(174, 99, 63);\n}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;display: initial;font-family: helvetica-w01-light,helvetica-w02-light,sans-serif;color: #FFFFFF;margin: 0px 4.5px 0px 0px}.root::icon{transition: inherit;display: initial;fill: #FFFFFF;width: 23px;height: 23px;margin: 0px 0px 0px 4.5px}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "4ie-322",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "hmj-792",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_call_us"),
      svgId: "11062b_a9f863f00acf4ed6a7113f0eb05f6884.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-kmab0832",
    layout: {
      x: 172,
      fixedPosition: false,
      y: 615,
      scale: 1,
      height: 42,
      rotationInDegrees: 0,
      width: 142,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "hmj-648", pageId: "c1dmp" },
    },
    metaData: { sig: "inq-329", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
