import _ from 'lodash';
import { WIX_BLOG, WIX_BOOKINGS } from '@wix/app-definition-ids';
import { tpaUtils } from '@/util';

import * as stateManagement from '@/stateManagement';

import * as experiments from './experiments';

import type { EditorAPI } from '@/editorAPI';

const getUnRegisterButtonCalledKey = (panelName: AnyFixMe) =>
  `${panelName}/calledUnRegisterButton`;

function registerButton(
  editorAPI: EditorAPI,
  superAppId: AnyFixMe,
  isAppInstalled: AnyFixMe,
  panelName: AnyFixMe,
  label: AnyFixMe,
  promotionalLabel: AnyFixMe,
  panelClassName: AnyFixMe,
  symbolName?: AnyFixMe,
  isButtonOpen?: AnyFixMe,
  shouldExpandButton: AnyFixMe = {},
  key?: string,
) {
  editorAPI.registerInitUserPrefsCallback(function registerButtonCallback() {
    let labelToUse;
    const hasCalledUnRegister =
      stateManagement.userPreferences.selectors.getSiteUserPreferences(
        getUnRegisterButtonCalledKey(panelName),
      )(editorAPI.store.getState());
    if (isAppInstalled) {
      labelToUse = label;
      isButtonOpen = true;
    } else if (
      experiments.isPromotionalExperimentOn(superAppId) &&
      !hasCalledUnRegister
    ) {
      labelToUse = promotionalLabel;
    }
    if (labelToUse !== undefined) {
      isButtonOpen = isButtonOpen || false;
      const collapseOnClick =
        shouldExpandButton[superAppId] ||
        (experiments.isWaitForClickExperimentOn(superAppId) && isAppInstalled);
      editorAPI.store.dispatch(
        stateManagement.leftBar.actions.addSuperAppsButton(
          panelName,
          {},
          labelToUse,
          panelClassName,
          symbolName,
          false,
          collapseOnClick,
          isButtonOpen,
          key || null,
        ),
      );
    }
  });
}

function registerCollapsedButton(
  editorAPI: AnyFixMe,
  panelName: AnyFixMe,
  label: AnyFixMe,
  panelClassName: AnyFixMe,
  symbolName?: AnyFixMe,
) {
  editorAPI.store.dispatch(
    stateManagement.leftBar.actions.addSuperAppsButton(
      panelName,
      {},
      label,
      panelClassName,
      symbolName,
      false,
      false,
      false,
    ),
  );
}

function unRegisterButton(editorAPI: AnyFixMe, panelName: AnyFixMe) {
  editorAPI.store.dispatch(
    stateManagement.leftBar.actions.removeSuperAppsButton(panelName),
  );
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      getUnRegisterButtonCalledKey(panelName),
      true,
    ),
  );
}

function onAppInstalled(
  editorAPI: EditorAPI,
  superAppId: AnyFixMe,
  panelName: AnyFixMe,
  label: AnyFixMe,
  panelClassName: AnyFixMe,
  symbolName: AnyFixMe,
  shouldExpandButton?: AnyFixMe,
  shouldDisableOpenAppPanel?: boolean,
  key?: string,
) {
  button.registerButton(
    editorAPI,
    superAppId,
    true,
    panelName,
    label,
    null,
    panelClassName,
    symbolName,
    true,
    shouldExpandButton,
    key || null,
  );
  if (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    _.find(editorAPI.panelManager.getOpenPanels(), { name: panelName }) ===
      undefined &&
    !shouldDisableOpenAppPanel
  ) {
    editorAPI.panelManager.openPanel(
      panelName,
      { appWasManuallyInstalled: true },
      true,
    );
  }
}

const APP_MANAGER_HIDDEN_6TH_BUTTONS = [
  WIX_BLOG,
  WIX_BOOKINGS,
  tpaUtils.getStoresAppDefId(),
];

const canBeRegistered = (editorAPI: EditorAPI, appInfo: { appDefId: string }) =>
  !APP_MANAGER_HIDDEN_6TH_BUTTONS.includes(appInfo.appDefId);

const button = {
  registerButton,
  registerCollapsedButton,
  unRegisterButton,
  canBeRegistered,
  onAppInstalled,
};
export default button;
