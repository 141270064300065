//eslint-disable-next-line @wix/santa-editor/scoped-imports
import {
  type OnlineProgramsWelcomeDispatchProps,
  mapDispatchToOnlineProgramsWelcomeSectionProps,
} from '@/addPanelData/mappers';
import { wrapWithPropsTransformer } from '../utils';

export interface OnlineProgramsWelcomeProps {
  onlineProgramsMainAction: () => void;
}

export const onlineProgramsWelcomeMapper = wrapWithPropsTransformer<
  OnlineProgramsWelcomeDispatchProps,
  OnlineProgramsWelcomeProps
>(mapDispatchToOnlineProgramsWelcomeSectionProps, (props) => ({
  onlineProgramsMainAction: props.installOnlineProgramsApp,
}));
