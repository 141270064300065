import type { EntryPoint } from '@/apilib';
import { PluginServiceApiKey } from '@/apis';
import { createPluginServiceApi } from './pluginService';

export const PluginServiceEntrypoint: EntryPoint = {
  name: 'PluginServiceApi',
  declareAPIs: () => [PluginServiceApiKey],
  getDependencyAPIs: () => [],
  attach(shell) {
    shell.contributeAPI(PluginServiceApiKey, () => createPluginServiceApi());
  },
};
