import _ from 'lodash';
import type {
  FetchListResult,
  ItemRef,
  FAQCategory,
  FAQQuestion,
} from '../types';
import { sanitizeHtmlContent } from '../utils';

const mapCategory = (category: FAQCategory, index: number) => {
  return {
    id: category._iid,
    title: category.title,
    weight: index + 1,
  };
};

const mapQuestion = (question: FAQQuestion) => {
  return question.parentRefs.map((categoryRef) => {
    return {
      category: categoryRef.itemId,
      demo: false,
      id: question._iid,
      title: {
        text: question.question,
      },
      text: {
        imageList: [],
        videoList: [],
        text: sanitizeHtmlContent(question.answer.text),
        links: question.answer.links,
      },
    };
  });
};

export const convertFAQItems = (result: FetchListResult<any, any>) => {
  const { payload } = result;
  const { item, referencedItems } = payload;

  const getReferencedItem = (ref: ItemRef) =>
    referencedItems[`${ref.collectionId}/${ref.itemId}`];

  const categoryItems: FAQCategory[] =
    'categories' in item ? item.categories.map(getReferencedItem) : [item];

  const categories = categoryItems.map(mapCategory);

  const questions = _.flatMap(categoryItems, (category) => {
    return _.flatMap(category.questions, (questionRef) =>
      mapQuestion(getReferencedItem(questionRef)),
    );
  });

  return {
    categories,
    //eslint-disable-next-line lodash/prefer-lodash-chain
    questions: questions.map((question, index) => ({
      ...question,
      weight: index + 1,
    })),
  };
};
