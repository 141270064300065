:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._empty-state-container_1oyrn_7 {
  text-align: center;
  padding: 24px; }
  ._empty-state-container_1oyrn_7 ._icon_1oyrn_10 {
    width: 120px;
    height: 120px; }
  ._empty-state-container_1oyrn_7 ._title-container_1oyrn_13 {
    margin: 18px auto 0;
    text-align: center;
    max-width: 280px; }
  ._empty-state-container_1oyrn_7 ._subtitle_1oyrn_17 {
    margin: 6px auto 0;
    max-width: 255px;
    text-align: center; }
  ._empty-state-container_1oyrn_7 ._link_1oyrn_21 {
    max-width: 240px;
    margin: 6px auto 0;
    display: block;
    font-size: 14px; }
