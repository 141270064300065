// @ts-nocheck
import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import constants from '../constants/constants';
import editorConstants from '@/constants';
import { sections } from '@/util';
import * as tpaHelpService from '../services/tpaHelpService';
import * as tpaAddAppService from '../services/tpaAddAppService';
import * as appMarketUtils from '../appMarket/utils/appMarketUtils';
import * as superApps from '../superApps/superApps';
import type { EditorAPI } from '@/editorAPI';
import { PlatformOrigin } from '@/platform';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

const { getPreviewMode } = stateManagement.preview.selectors;

export type MessageHandlerCallback = (params?: unknown) => void;

export interface TpaMessageBase {
  intent: string;
  namespace: string;
  callId: number;
  compId: string;
  data: unknown;
  deviceType: string;
  type: string;
  version: string;
  widgetId?: string;
  appId?: string;
}

const openHelp = function (editorAPI: EditorAPI, msg: TpaMessageBase) {
  editorAPI.preview.togglePreview(undefined, {
    biParams: { origin: editorConstants.BI.PREVIEW.ORIGIN.TPA },
  });
  const data = editorAPI.dsRead.tpa.app.getFirstAppCompPageId(msg.data);
  const compRef = editorAPI.components.get.byId(data.compId, data?.pageId);
  editorAPI.selection.selectComponentByCompRef(compRef);
  const selectedComponentData = editorAPI.selection.getSelectedComponentData();
  const appData =
    selectedComponentData &&
    editorAPI.dsRead.tpa.app.getData(selectedComponentData.applicationId);

  tpaHelpService
    .getHelpId(
      editorAPI,
      appData?.appDefinitionId,
      selectedComponentData?.widgetId,
      tpaHelpService.HELP_END_POINTS.WIDGET,
    )
    .then(function (helpId) {
      editorAPI.panelManager.openHelpCenter(
        helpId,
        {
          origin: 'preview',
          component: 'TPAWidget',
          learn_more: false,
        },
        {
          panel_name: 'preview',
          origin: 'preview',
        },
      );
    });
};

const getViewMode = function (
  editorAPI: EditorAPI,
  msg: TpaMessageBase,
  callback: MessageHandlerCallback,
) {
  const state = editorAPI.store.getState();
  const viewMode = getPreviewMode(state) ? 'preview' : 'editor';
  return callback(viewMode);
};

const openSettingsDialog = function (
  editorAPI: EditorAPI,
  msg: TpaMessageBase,
) {
  const state = editorAPI.store.getState();
  if (editorAPI.isDesktopEditor() && getPreviewMode(state)) {
    const { pageId } = msg.data;
    const { compId } = msg.data;

    const editedRoots = [editorAPI.dsRead.pages.getFocusedPageId()];
    if (!editorAPI.dsRead.pages.popupPages.isPopupOpened()) {
      editedRoots.push(editorAPI.pages.getMasterPageId());
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (!_.includes(editedRoots, pageId)) {
      editorAPI.pages.navigateTo(
        pageId,
        openSettingsPanel.bind(undefined, editorAPI, compId, pageId),
      );
    } else {
      openSettingsPanel(editorAPI, compId, pageId);
    }
  }
};

const openSettingsPanel = function (
  editorAPI: EditorAPI,
  compId: string,
  pageId: string,
) {
  editorAPI.preview.togglePreview(undefined, {
    biParams: { origin: editorConstants.BI.PREVIEW.ORIGIN.TPA },
  });
  const compRef = editorAPI.components.get.byId(compId, pageId);
  editorAPI.selection.selectComponentByCompRef(compRef);

  const panelProps = {
    selectedComponent: editorAPI.selection.getSelectedComponents(),
    origCompId: compId,
  };
  editorAPI.panelManager.openPanel('tpa.compPanels.tpaSettings', panelProps);
};

const deselectComponent = function (editorAPI: EditorAPI) {
  editorAPI.selection.deselectComponents();
};

const editorAddApplication = function (
  editorAPI: EditorAPI,
  msg: TpaMessageBase,
  callback: MessageHandlerCallback,
) {
  const appDefId = msg?.data?.appDefinitionId;
  if (!appDefId) return;
  const isAppInstalled = tpaAddAppService.isAppInstalled(
    editorAPI,
    appDefId,
    true,
  );
  if (isAppInstalled) {
    callback({
      error: 'Application already installed.',
    });
    return;
  }
  if (editorAPI.isMobileEditor()) {
    callback({
      error: "Can't add application in mobile editor.",
    });
    return;
  }
  editorAPI.dsRead.tpa.appMarket
    .getDataAsync(appDefId)
    .then(function (marketData) {
      if (!marketData || marketData.error) {
        callback({
          error: `Application with ${appDefId} appDefinitionId do not exist.`,
        });
        return;
      }
      const isWixTPA = marketData.by === 'Wix';
      if (isWixTPA) {
        let type = marketData.hasSection
          ? constants.APP.TYPE.SECTION
          : constants.APP.TYPE.WIDGET;
        type =
          appDefId === superApps.superAppsConstants.BLOG.APP_DEF_ID
            ? constants.APP.TYPE.APP_PART
            : type;
        const initiator = constants.BI.initiator.SDK;
        const biData = { adding_method: 'sdk' };
        const options = {
          shouldNavigate: msg?.data?.shouldNavigate ?? false,
          pageId: msg?.data?.pageId,
          showPageAddedPanel: msg?.data?.showPageAddedPanel ?? false,
        };

        if (sections.isSectionsEnabled()) {
          const targetPage = options.pageId
            ? editorAPI.pages.getReference(options.pageId)
            : undefined;

          options.containerRef = targetPage
            ? editorAPI.sections.getPageSectionsSortedByStageOrder(
                targetPage,
              )?.[0]
            : undefined;
        }

        const platformOrigin: PlatformOrigin = {
          initiator: InstallInitiator.Editor,
          type: EditorType.Classic,
          info: {
            type: InstallationOriginType.IFRAME_JS_SDK,
          },
        };

        tpaAddAppService.addApp(
          editorAPI,
          appDefId,
          marketData.name,
          null,
          type,
          initiator,
          !isWixTPA,
          biData,
          options,
          function (data) {
            callback(_.pick(data, ['instanceId']));
          },
          platformOrigin,
        );
      } else {
        const appMarketUrl = editorAPI.dsRead.tpa.appMarket.getPermissionsUrl(
          { appName: marketData?.slug },
          appDefId,
        );
        appMarketUtils.openAppMarketModal(
          editorAPI.panelManager.openPanel,
          constants.MODALS.PERMISSIONS,
          { url: appMarketUrl },
        );
        callback();
      }
    });
};

const editorIsApplicationInstalled = function (
  editorAPI: EditorAPI,
  msg: TpaMessageBase,
  callback: MessageHandlerCallback,
) {
  const appDefinitionId = msg?.data?.appDefinitionId;
  const includingDemoMode = true;
  callback(
    tpaAddAppService.isAppInstalled(
      editorAPI,
      appDefinitionId,
      includingDemoMode,
    ),
  );
};

const editorIsFullWidth = function (
  editorAPI: EditorAPI,
  msg: TpaMessageBase,
  callback: MessageHandlerCallback,
) {
  const selectedComponent = editorAPI.components.get.byId(msg.compId);
  callback(
    editorAPI.components.layout.isHorizontallyStretchedToScreen(
      selectedComponent,
    ),
  );
};

const isCustomApplicationPermissionsGranted = function (
  editorAPI: EditorAPI,
  msg: TpaMessageBase,
  callback: MessageHandlerCallback,
) {
  const customPermission = msg?.data ?? {};
  callback(
    editorAPI.platform.permissions.isCustomPermissionsGranted(customPermission),
  );
};

const isGroupApplicationPermissionsGranted = function (
  editorAPI: EditorAPI,
  msg: TpaMessageBase,
  callback: MessageHandlerCallback,
) {
  const groupPermission = msg?.data ?? {};

  callback(
    editorAPI.platform.permissions.isGroupsPermissionsGranted(groupPermission),
  );
};

const onReady = function (
  editorAPI: EditorAPI,
  msg: TpaMessageBase,
  callback: MessageHandlerCallback,
) {
  editorAPI.registerInitUserPrefsCallback(callback);
};

export {
  openHelp,
  getViewMode,
  openSettingsDialog,
  deselectComponent,
  editorAddApplication,
  editorIsApplicationInstalled,
  editorIsFullWidth,
  onReady,
  isCustomApplicationPermissionsGranted,
  isGroupApplicationPermissionsGranted,
};
