// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['add_section_label_toggle_switch'],
      sections: [addPanel.sections.getSections().developer.toggleSwitchSection],
    };
  },
};
