:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._textComponent_ced9m_7 {
  padding: 12px 24px 18px; }

._textPlaceholder_ced9m_10 {
  color: #000624;
  font-size: 16px;
  padding: 6px 12px;
  display: block;
  min-height: 36px;
  max-height: 110px;
  line-height: 24px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 1px solid #a8caff;
  border-radius: 4px;
  outline: none;
  background-color: #fff; }
  ._textPlaceholder_ced9m_10:hover {
    background-color: #e7f0ff;
    cursor: pointer;
    border-radius: 4px; }

._buttonRow_ced9m_30 {
  display: flex;
  justify-content: space-evenly;
  padding: 6px 4px;
  height: 24px;
  border: 1px solid #116dff;
  border-bottom: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: #fff; }

._buttonsDivider_ced9m_41 {
  width: 1px;
  height: 24px;
  background-color: #dfe5eb; }

._text-editor-wrapper_ced9m_46 {
  max-height: 245px;
  padding: 12px 11px;
  overflow-y: scroll;
  border: 1px solid #116dff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff; }

.cke_contents {
  transition: height 0.5s ease; }

._editText_ced9m_58 {
  margin-top: 6px;
  font-size: 12px; }
  ._editText_ced9m_58 ._link_ced9m_61 {
    cursor: pointer;
    color: #116dff;
    padding-left: 4px; }
    ._editText_ced9m_58 ._link_ced9m_61:hover {
      text-decoration: underline; }

._textEditingContent_ced9m_68 {
  position: relative; }
