import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import React from 'react';
import * as leftBar from '@/leftBar';
import * as panels from '@/panels';
import SuperAppFirstTimeTourPanel from './firstTimeTour/superAppFirstTimeTourPanel';
import SuperAppMenuPanel from './superAppMenuPanel';
import SuperAppFullPanel from './superAppFullPanel';
import { cx, hoc, workspace } from '@/util';
import { translate } from '@/i18n';

import type { EditorAPI } from '@/editorAPI';
import type { MapStateToProps, ThunkAction } from 'types/redux';

interface StateProps {
  inFirstTimeTour: boolean;
}

interface SuperApp {
  id: string;
  panel: {
    name: string;
    label?: string | React.ReactNode;
  };
  isAppInstalled: (editorAPI: EditorAPI) => boolean;
} // Created this one based on the usage below -> dont rely on it

interface SuperAppPanelProps {
  superApp: SuperApp;
  inFirstTimeTour: boolean;
  finishFirstTimeTour: (firstTimeTourKey: string) => void;
  closePanelByName: (panelName: string) => void;
  getEditorAPI(): EditorAPI;
  appWasManuallyInstalled?: boolean;
  selectedTabName?: string;
  panelClass?: string;
  onClickItemOverride?: () => void;
  overrideClosePanel?: () => void;
  fullPanel?: unknown;
  panelIndex?: number;
  tabsWidth?: number;
  contentWidth?: number;
}

interface SuperAppPanelState {
  currentAppHelpId: string;
}

class SuperAppPanel extends React.Component<
  SuperAppPanelProps,
  SuperAppPanelState
> {
  constructor(props: AnyFixMe) {
    super(props);

    this.state = {
      currentAppHelpId: '',
    };
  }

  readonly isNewWorkspace = workspace.isNewWorkspaceEnabled();

  isInFirstTimeTour = () => {
    return this.props.inFirstTimeTour;
  };

  finishFirstTimeTour = () => {
    const firstTimeTourKey = `${this.props.superApp.id}/in_first_time_tour`;
    this.props.finishFirstTimeTour(firstTimeTourKey);
  };

  closePanel = () => {
    if (_.isFunction(this.props.overrideClosePanel)) {
      this.props.overrideClosePanel();
    } else {
      this.props.closePanelByName(this.props.superApp.panel.name);
    }
  };

  shouldDisplayFirstTimeTourPanel = () => {
    return (
      !this.shouldDisplayFullPanel() &&
      (this.isInFirstTimeTour() ||
        !this.props.superApp.isAppInstalled(this.props.getEditorAPI()))
    );
  };

  shouldDisplayMenuPanel = () => {
    return (
      !this.shouldDisplayFullPanel() &&
      !this.isInFirstTimeTour() &&
      this.props.superApp.isAppInstalled(this.props.getEditorAPI())
    );
  };

  shouldDisplayFullPanel = () => {
    return !!this.props.fullPanel;
  };

  shouldRenderPanelWithHeader = () => {
    return this.isNewWorkspace && !this.shouldDisplayFirstTimeTourPanel();
  };

  setHelpId = (helpId: string) => {
    // In the new workspace helpId has to be managed in the LeftPanelFrame -> using this callback to let old panels set it in the new panelFrame
    if (helpId !== this.state.currentAppHelpId) {
      this.setState({ currentAppHelpId: helpId });
    }
  };

  render() {
    const { panel } = this.props.superApp;
    const label = panel.label || translate(panel.name); // TODO: sync about panel headers
    const LeftPanelFrame = this.isNewWorkspace
      ? leftBar.LeftPanelFrame
      : panels.frames.LeftPanelFrame;
    return (
      <LeftPanelFrame
        ref="superAppPanel"
        styleOverride={{ overflow: 'visible' }}
        panelName={this.props.superApp.panel.name}
        panelClass={cx({
          'super-app-panel': true,
          [this.props.panelClass]: true,
          'super-app-panel-new-workspace': this.isNewWorkspace,
        })}
        panelIndex={this.props.panelIndex}
        label={this.shouldRenderPanelWithHeader() ? label : undefined}
        helpId={this.isNewWorkspace ? this.state.currentAppHelpId : undefined}
        dynamicWidth={this.isNewWorkspace ? true : undefined}
        hideHeader={this.shouldDisplayFirstTimeTourPanel()}
      >
        {this.shouldDisplayFirstTimeTourPanel() ? (
          <SuperAppFirstTimeTourPanel
            {...this.props}
            key="firstTimeTour"
            onTourEnd={this.finishFirstTimeTour}
            onClose={this.closePanel}
          />
        ) : null}
        {this.shouldDisplayMenuPanel() ? (
          <SuperAppMenuPanel
            key="AppMenu"
            superApp={this.props.superApp}
            selectedTabName={this.props.selectedTabName}
            onClickItemOverride={this.props.onClickItemOverride}
            onClose={this.closePanel}
            setHelpId={this.isNewWorkspace ? this.setHelpId : undefined}
            tabsWidth={this.props.tabsWidth}
            contentWidth={this.props.contentWidth}
          />
        ) : null}
        {this.shouldDisplayFullPanel() ? (
          <SuperAppFullPanel
            key="AppFull"
            superApp={this.props.superApp}
            fullPanel={this.props.fullPanel}
            onClose={this.closePanel}
          />
        ) : null}
      </LeftPanelFrame>
    );
  }
}

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

const getEditorAPI =
  (): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    return editorAPI; // NOTE: you never want to pass down the entire editorAPI to the component -> its here only for the ease of refactoring
  };
const finishFirstTimeTour = (firstTimeTourKey: string) =>
  stateManagement.userPreferences.actions.setSiteUserPreferences(
    firstTimeTourKey,
    'false',
  );

const closePanelByName =
  (name: string): ThunkAction =>
  (_dispatch, _getState, { editorAPI }) => {
    editorAPI.panelManager.closePanelByName(name);
  };

const mapDispatchToProps = {
  getEditorAPI,
  finishFirstTimeTour,
  closePanelByName,
};

const mapStateToProps: MapStateToProps<StateProps, { superApp: SuperApp }> = (
  { state },
  ownProps,
) => {
  const inFirstTimeTourKey = `${ownProps.superApp.id}/in_first_time_tour`;
  const inFirstTimeTour =
    stateManagement.userPreferences.selectors.getSiteUserPreferences(
      inFirstTimeTourKey,
    )(state);

  return {
    inFirstTimeTour: inFirstTimeTour !== 'false',
  };
};

const conectedComp = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(SuperAppPanel);

export default conectedComp;
