'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_strip_container_classic",
    "subNavigationTitle": "add_section_label_strip_container_classic",
    "presetTitle": "add_section_label_strip_container_classic",
    "tooltipTitle": "add_section_label_strip_container_classic",
    "automationId": "add-panel-section-columnsAboutSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/columnsAboutSection_en/columnsAboutSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "Columns_ColumnsAbout_1",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 490,
                        "height": 650,
                        "x": -90,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Urban Footwear.jpg",
                                "uri": "035244_e73291085fbe44a5be9f6b32af50803f.jpg",
                                "description": "private/allMedia_picture",
                                "width": 1920,
                                "height": 1280,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#000000",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 200,
                            "x": 0,
                            "y": 281,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Urban Footwear.jpg",
                                    "uri": "035244_e73291085fbe44a5be9f6b32af50803f.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 1920,
                                    "height": 1280,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#000000",
                                "colorOpacity": 0,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 158,
                            "height": 47,
                            "x": 166,
                            "y": 431,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "LEARN MORE"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#727272",
                                    "bgh": "#000000",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#000000",
                                    "brdh": "#000000",
                                    "brw": "4",
                                    "fnt": "normal normal normal 15px/1.4em avenir-lt-w01_35-light1475496",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#2F2E2E",
                                    "txth": "#FFFFFF"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 158,
                                "height": 37,
                                "x": 81,
                                "y": 203,
                                "scale": 0.9070294784580498,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "LEARN MORE"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#727272",
                                        "bgh": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#000000",
                                        "brdh": "#000000",
                                        "brw": "4",
                                        "fnt": "normal normal normal 15px/1.4em avenir-lt-w01_35-light1475496",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#2F2E2E",
                                        "txth": "#FFFFFF"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 469,
                            "height": 53,
                            "x": 12,
                            "y": 190,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size: 46px; line-height: 1em; text-align: center;\"><span style=\"letter-spacing:0.15em;\"><span style=\"font-size:46px;\"><span class=\"color_11\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">about</span></span><span style=\"color:#000000\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">us</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 280,
                                "height": 37,
                                "x": 20,
                                "y": 45,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size: 46px; line-height: 1em; text-align: center;\"><span style=\"letter-spacing:0.15em;\"><span style=\"font-size:46px;\"><span class=\"color_11\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">about</span></span><span style=\"color:#000000\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">us</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 314,
                            "height": 98,
                            "x": 88,
                            "y": 282,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"line-height: 1.4em; text-align: center; font-size: 17px;\"><span style=\"font-size:17px;\"><span style=\"color:#000000;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">I&rsquo;m a paragraph. Double click here or&nbsp;click Edit Text to add some text of your&nbsp;own or to change the font. Tell your visitors a bit about your services.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 93,
                                "x": 20,
                                "y": 91,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"line-height: 1.4em; text-align: center; font-size: 17px;\"><span style=\"font-size:17px;\"><span style=\"color:#000000;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">I&rsquo;m a paragraph. Double click here or&nbsp;click Edit Text to add some text of your&nbsp;own or to change the font. Tell your visitors a bit about your services.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "layout": {
                        "width": 490,
                        "height": 650,
                        "x": 580,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#4BD1A0",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 281,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#4BD1A0",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 650,
                    "x": 0,
                    "y": 100,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    },
                    "charas": "design-iv0xf81r"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 481,
                        "x": 0,
                        "y": 860,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        },
                        "charas": "design-iv0xf81q"
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ioij9khe"
            },
            "preset": {
                "rect": {"width": 324, "height": 170, "x": -0.8, "y": 0},
                "label": "add_preset_columns_about_1",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsAbout_2",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 617,
                        "height": 682,
                        "x": -67,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Girl by the Sea",
                                "uri": "45febfcf02f543c1950505340713a476.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                "width": 1920,
                                "height": 1280,
                                "alt": "",
                                "artist": {"id": "mobile_", "name": ""}
                            },
                            "color": "#F7F5E1",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 200,
                            "x": 0,
                            "y": 391,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Girl by the Sea",
                                    "uri": "45febfcf02f543c1950505340713a476.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                    "width": 1920,
                                    "height": 1280,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#F7F5E1",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mcc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 361,
                            "height": 144,
                            "x": 1,
                            "y": 88,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:56px\"><span style=\"color:#0D0D0D\"><span style=\"font-size:56px\"><span style=\"letter-spacing:0.15em\"><span style=\"font-family:didot-w01-italic,serif\">Add Your Title</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 280,
                                "height": 41,
                                "x": 20,
                                "y": 40,
                                "scale": 0.9025,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:56px\"><span style=\"color:#0D0D0D\"><span style=\"font-size:56px\"><span style=\"letter-spacing:0.15em\"><span style=\"font-family:didot-w01-italic,serif\">Add Your Title</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 330,
                            "height": 22,
                            "x": 1,
                            "y": 251,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:16px\"><span style=\"font-size:16px\"><span style=\"color:#110A2B\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">This is a great place to add a tagline.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 22,
                                "x": 20,
                                "y": 91,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:16px\"><span style=\"font-size:16px\"><span style=\"color:#110A2B\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">This is a great place to add a tagline.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 298,
                            "height": 193,
                            "x": 4,
                            "y": 300,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"line-height:1.5em\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"color:#000000\">Tell customers more about you. Add a few words and a stunning pic to grab their attention and get them to click.</span></span></span></p>\n\n<p class=\"font_8\" style=\"line-height:1.5em\"><span class=\"wixGuard\">​</span></p>\n\n<p class=\"font_8\" style=\"line-height:1.5em\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"color:#000000\">This space is ideal for writing a detailed description of your business and&nbsp;the types of services that you provide. Talk about your team and your&nbsp;areas of expertise.&nbsp;</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 218,
                                "x": 20,
                                "y": 129,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"line-height:1.5em\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"color:#000000\">Tell customers more about you. Add a few words and a stunning pic to grab their attention and get them to click.</span></span></span></p>\n\n<p class=\"font_8\" style=\"line-height:1.5em\"><span class=\"wixGuard\">​</span></p>\n\n<p class=\"font_8\" style=\"line-height:1.5em\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"color:#000000\">This space is ideal for writing a detailed description of your business and&nbsp;the types of services that you provide. Talk about your team and your&nbsp;areas of expertise.&nbsp;</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "layout": {
                        "width": 362,
                        "height": 682,
                        "x": 731,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFEF98",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 391,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFEF98",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 682,
                    "x": 0,
                    "y": 950,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "{color_11}",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 0
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 591,
                        "x": 0,
                        "y": 1841,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "{color_11}",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ioilb61k"
            },
            "preset": {
                "rect": {"width": 324, "height": 176.4, "x": -0.8, "y": 170},
                "label": "add_preset_columns_about_2",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsAbout_3",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 274,
                        "height": 610,
                        "x": -130,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Ready to Serve",
                                "uri": "885f815586f74d2493ca39e56aa770dd.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                "width": 5760,
                                "height": 3840,
                                "alt": "",
                                "artist": {"id": "mobile_", "name": ""}
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 200,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Ready to Serve",
                                    "uri": "885f815586f74d2493ca39e56aa770dd.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                    "width": 5760,
                                    "height": 3840,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                        "layout": {
                            "width": 133,
                            "height": 26,
                            "x": 148,
                            "y": 464,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "Book a place >"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "fnt": "italic normal normal 15px/1.4em libre+baskerville",
                                    "txt": "#BC9B5D",
                                    "txtd": "#FFFFFF",
                                    "txth": "#605E5E"
                                },
                                "propertiesSource": {
                                    "fnt": "value",
                                    "txt": "value",
                                    "txtd": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                            "layout": {
                                "width": 151,
                                "height": 26,
                                "x": 84,
                                "y": 325,
                                "scale": 1.1025,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Book a place >"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "fnt": "italic normal normal 15px/1.4em libre+baskerville",
                                        "txt": "#BC9B5D",
                                        "txtd": "#FFFFFF",
                                        "txth": "#605E5E"
                                    },
                                    "propertiesSource": {
                                        "fnt": "value",
                                        "txt": "value",
                                        "txtd": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 294,
                            "height": 179,
                            "x": 69,
                            "y": 246,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:17px; line-height:1.5em; text-align:center\"><span style=\"font-size:17px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">Tell your visitors your story. Add catchy text to describe what you do, and what you have to offer. The right words can inspire and intrigue your audience, so they&rsquo;re ready to take action on your site. To start telling your story, double click or click Edit Text.</span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 179,
                                "x": 20,
                                "y": 132,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:17px; line-height:1.5em; text-align:center\"><span style=\"font-size:17px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">Tell your visitors your story. Add catchy text to describe what you do, and what you have to offer. The right words can inspire and intrigue your audience, so they&rsquo;re ready to take action on your site. To start telling your story, double click or click Edit Text.</span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 431,
                            "height": 21,
                            "x": 0,
                            "y": 191,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:16px; line-height:1.2em; text-align:center\"><span style=\"font-size:16px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"letter-spacing:0.1em\"><span class=\"color_14\">THIS IS A GREAT PLACE FOR YOUR&nbsp;TAGLINE.</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 280,
                                "height": 42,
                                "x": 20,
                                "y": 79,
                                "scale": 0.9925187343749999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:16px; line-height:1.2em; text-align:center\"><span style=\"font-size:16px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"letter-spacing:0.1em\"><span class=\"color_14\">THIS IS A GREAT PLACE FOR YOUR&nbsp;TAGLINE.</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 352,
                            "height": 58,
                            "x": 32,
                            "y": 112,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size: 90px; line-height: 0.5em; text-align: center;\"><span style=\"font-size:90px;\"><span style=\"color:#BC9B5D\"><span style=\"font-family:peaches-and-cream-regular-w00,script\">About Us</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 40,
                                "x": 20,
                                "y": 27,
                                "scale": 1.3400956406250004,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size: 90px; line-height: 0.5em; text-align: center;\"><span style=\"font-size:90px;\"><span style=\"color:#BC9B5D\"><span style=\"font-family:peaches-and-cream-regular-w00,script\">About Us</span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "layout": {
                        "width": 431,
                        "height": 610,
                        "x": 274,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FCF8ED",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 374,
                            "x": 0,
                            "y": 200,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FCF8ED",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 274,
                        "height": 610,
                        "x": 836,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Preparing Food",
                                "uri": "f1dcd76240564bdfbafb7c03457c3dc3.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                "width": 1220,
                                "height": 826,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#FCF8ED",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 200,
                            "x": 0,
                            "y": 574,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Preparing Food",
                                    "uri": "f1dcd76240564bdfbafb7c03457c3dc3.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                    "width": 1220,
                                    "height": 826,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#FCF8ED",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 610,
                    "x": 0,
                    "y": 1833,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    },
                    "charas": "design-iv0xfyjt"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 774,
                        "x": 0,
                        "y": 10,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        },
                        "charas": "design-iv0xfyjr"
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ioij82ny"
            },
            "preset": {
                "rect": {"width": 324, "height": 162.20000000000002, "x": -0.8, "y": 346.40000000000003},
                "label": "add_preset_columns_about_3",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsAbout_4",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 490,
                        "height": 700,
                        "x": -90,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#F7F5E1",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 133,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#F7F5E1",
                                "colorOpacity": 0,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 1
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 377,
                            "height": 140,
                            "x": 78,
                            "y": 169,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:70px; line-height:0.9em\"><span style=\"color:#FFFFFE\"><span style=\"letter-spacing:0.05em\"><span style=\"font-size:70px\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\">ABOUT US</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 40,
                                "x": 10,
                                "y": 27,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:70px; line-height:0.9em\"><span style=\"color:#FFFFFE\"><span style=\"letter-spacing:0.05em\"><span style=\"font-size:70px\"><span style=\"font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif\">ABOUT US</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 279,
                            "height": 116,
                            "x": 80,
                            "y": 330,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.8em\"><span style=\"color:#474747\"><span style=\"font-size:16px\"><span style=\"font-family:bree-w01-thin-oblique,sans-serif\">I&rsquo;m a paragraph. Use this space to tell people more about what you do and the services you offer. Double click here or click Edit Text to get started.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 116,
                                "x": 10,
                                "y": 77,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.8em\"><span style=\"color:#474747\"><span style=\"font-size:16px\"><span style=\"font-family:bree-w01-thin-oblique,sans-serif\">I&rsquo;m a paragraph. Use this space to tell people more about what you do and the services you offer. Double click here or click Edit Text to get started.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 171,
                            "height": 46,
                            "x": 75,
                            "y": 470,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "READ MORE"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FF6161",
                                    "bgh": "#F27979",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFE",
                                    "brdh": "#C65AA5",
                                    "brw": "0",
                                    "fnt": "normal normal normal 15px/1.4em bree-w01-thin-oblique",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FFFFFE",
                                    "txth": "#FFFFFF"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-bgh": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 140,
                                "height": 39,
                                "x": 10,
                                "y": 202,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "READ MORE"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FF6161",
                                        "bgh": "#F27979",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFE",
                                        "brdh": "#C65AA5",
                                        "brw": "0",
                                        "fnt": "normal normal normal 15px/1.4em bree-w01-thin-oblique",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFE",
                                        "txth": "#FFFFFF"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "layout": {
                        "width": 490,
                        "height": 700,
                        "x": 490,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 0},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFE",
                            "colorOpacity": 0.74,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 269,
                            "x": 0,
                            "y": 133,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 0},
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFFFFE",
                                "colorOpacity": 0.74,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 700,
                    "x": 0,
                    "y": 2643,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "mediaRef": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": "pexels-photo-57690.jpeg",
                            "uri": "035244_881cbe4617f449cab844cb6a48b5fbeb~mv2_d_2475_2475_s_4_2.jpeg",
                            "description": "private/allMedia_picture",
                            "width": 2475,
                            "height": 2475,
                            "alt": "",
                            "artist": {
                                "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_",
                                "name": ""
                            },
                            "opacity": 0.68
                        },
                        "color": "#000000",
                        "colorOpacity": 1,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },

                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 402,
                        "x": 0,
                        "y": 1391,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "pexels-photo-57690.jpeg",
                                "uri": "035244_881cbe4617f449cab844cb6a48b5fbeb~mv2_d_2475_2475_s_4_2.jpeg",
                                "description": "private/allMedia_picture",
                                "width": 2475,
                                "height": 2475,
                                "alt": "",
                                "artist": {
                                    "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_",
                                    "name": ""
                                },
                                "opacity": 0.68
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ip2nf1e0"
            },
            "preset": {
                "rect": {"width": 324, "height": 180, "x": -0.8, "y": 508.6},
                "label": "add_preset_columns_about_4",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsAbout_5",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 980,
                            "height": 49,
                            "x": 1,
                            "y": 105,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:36px; text-align:center\"><span style=\"color:#FFFFFF;\"><span style=\"letter-spacing:0.05em\"><span style=\"font-size:36px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">I&#39;M AN ORIGINAL&nbsp;CATCHPHRASE</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 60,
                                "x": 20,
                                "y": 39,
                                "scale": 0.7737809374999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:36px; text-align:center\"><span style=\"color:#FFFFFF;\"><span style=\"letter-spacing:0.05em\"><span style=\"font-size:36px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">I&#39;M AN ORIGINAL&nbsp;CATCHPHRASE</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 549,
                            "height": 83,
                            "x": 216,
                            "y": 202,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"font-size:16px\">I&rsquo;m a paragraph. Double click here or click Edit Text to add some text of&nbsp;your own or to change the font. This is the place for you to tell your site&nbsp;visitors a little bit about you and your services.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 139,
                                "x": 20,
                                "y": 129,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.6em; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"font-size:16px\">I&rsquo;m a paragraph. Double click here or click Edit Text to add some text of&nbsp;your own or to change the font. This is the place for you to tell your site&nbsp;visitors a little bit about you and your services.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 42,
                            "height": 5,
                            "x": 469,
                            "y": 174,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "3"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 42,
                                "height": 5,
                                "x": 139,
                                "y": 113,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "3"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }],
                    "layout": {
                        "width": 980,
                        "height": 410,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FF6161",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 304,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FF6161",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 410,
                    "x": 0,
                    "y": 3543,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 304,
                        "x": 0,
                        "y": 2941,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-iok5bf32"
            },
            "preset": {
                "rect": {"width": 324, "height": 122, "x": -0.8, "y": 688.6},
                "label": "add_preset_columns_about_5",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsAbout_6",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 393,
                            "height": 60,
                            "x": 0,
                            "y": 309,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:60px; line-height:0.9em; text-align:center\"><span style=\"font-size:60px\"><span style=\"font-family:raleway,sans-serif\"><span style=\"color:#FFFFFF\"><span style=\"font-weight: bold;\">OUR&nbsp;</span>FIRM</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 37,
                                "x": 20,
                                "y": 107,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:60px; line-height:0.9em; text-align:center\"><span style=\"font-size:60px\"><span style=\"font-family:raleway,sans-serif\"><span style=\"color:#FFFFFF\"><span style=\"font-weight: bold;\">OUR&nbsp;</span>FIRM</span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 301,
                            "height": 42,
                            "x": 44,
                            "y": 384,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:17px; text-align:center\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:17px\"><span style=\"color:#FFFFFF\">I&#39;m a paragraph. Click here to add your own text and edit me. It&#39;s easy.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 40,
                                "x": 20,
                                "y": 156,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:17px; text-align:center\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:17px\"><span style=\"color:#FFFFFF\">I&#39;m a paragraph. Click here to add your own text and edit me. It&#39;s easy.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Container",
                        "components": [{
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 36,
                                "height": 5.55556,
                                "x": 13,
                                "y": 25,
                                "scale": 1,
                                "rotationInDegrees": 138,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "6"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 27,
                                    "height": 5.55556,
                                    "x": 10,
                                    "y": 21,
                                    "scale": 1,
                                    "rotationInDegrees": 138,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "6"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }],
                        "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                        "layout": {
                            "width": 61,
                            "height": 57,
                            "x": 166,
                            "y": 203,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-brd": "1",
                                    "bg": "#FFFCF5",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brw": "6",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-brd": "value",
                                    "bg": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                            "layout": {
                                "width": 47,
                                "height": 46,
                                "x": 137,
                                "y": 38,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-brd": "1",
                                        "bg": "#FFFCF5",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brw": "6",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-brd": "value",
                                        "bg": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "layout": {
                        "width": 392,
                        "height": 650,
                        "x": -109,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#00305B",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 237,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#00305B",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }, {
                    "type": "Container",
                    "components": [],
                    "layout": {
                        "width": 588,
                        "height": 650,
                        "x": 464,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Modern City",
                                "uri": "813b164e6ecd49b0b09f5f9913d34577.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 3700,
                                "height": 2466,
                                "alt": "",
                                "artist": {"id": "mobile_designItem_iv0xkrdv2", "name": ""}
                            },
                            "color": "{color_11}",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        },
                        "charas": "design-iv0xkret"
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 200,
                            "x": 0,
                            "y": 237,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Modern City",
                                    "uri": "813b164e6ecd49b0b09f5f9913d34577.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 3700,
                                    "height": 2466,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "{color_11}",
                                "colorOpacity": 0,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            },
                            "charas": "design-iv0xkrer"
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 650,
                    "x": 0,
                    "y": 4153,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    },
                    "charas": "design-iv0xlgdd"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 437,
                        "x": 0,
                        "y": 2459,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        },
                        "charas": "design-iv0xlgdc"
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ioijcnth"
            },
            "preset": {
                "rect": {"width": 324, "height": 170, "x": -0.8, "y": 810.6},
                "label": "add_preset_columns_about_6",
                "tags": null
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.StripColumnsContainer"]
    },
    "help": {"hide": false, "text": "add_section_info_text_column_about"}
}
