// @ts-nocheck
import * as addPanel from '@/oldAddPanel';
import section1 from './live/section1';
import section2 from './live/section2';

export default {
  getCategorySections() {
    return addPanel.dataUtil.mergeWithCommonSections(
      addPanel.sections.getSections().shape,
      { section_1: section1, section_2: section2 },
    );
  },
};
