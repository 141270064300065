import React from 'react';
import {
  ALLOWED_ROOT_QUICK_EDIT_COMPONENTS,
  NUMBER_OF_FIRST_LEVEL_DRILL_IN_PANELS,
  overrideCompControls,
  QUICK_EDIT_COMPS_WITH_INTERACTIONS,
  quickEditPanelOrigin,
} from './consts';
import { translate } from '@/i18n';
import { gfppModel } from '@/gfppData';
import constants, {
  WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES,
  WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
  WORKSPACE_RIGHT_PANEL_TAB_GROUP_TITLES,
} from '@/constants';
import { biLogger, fedopsLogger, sections, controlsUtils } from '@/util';
import {
  isNotTransparent,
  canChangeBackground,
  shouldFilterByBackground,
  isComponentInInteractionMode,
  getStageDisplayNameOverride,
  QuickEditDrillInPanelWrapper,
} from '@/quickEditEngine';
import { panelHeaderClose } from '@wix/bi-logger-editor/v2';
import { quickEditPanelReportings } from '@wix/bi-logger-editor/v2';
import { dataUtils } from '@/textControls';
import * as stateManagement from '@/stateManagement';
import {
  getContextMenuConfig,
  getHeaderFooterContextMenuConfig,
} from './quickEditContextMenuConfig';
import {
  reportComponentChanged,
  reportComponentClickEvent,
  reportSecondaryPanelOpen,
} from './quickEditBi';

import type {
  Link,
  CompRef,
  CompStructure,
} from '@wix/document-services-types';
import type { EditorAPI } from '@/editorAPI';
import type { UIResourceRestriction } from '@/editorRestrictions';
import type { QuickEditScope } from './quickEditEntryPoint';
import type { QuickEditControlActions } from '@wix/editor-elements-types/quickEditControls';
import type { GFPPAction, GFPPActionType } from '@wix/editor-component-types';

const {
  APP_WIDGET,
  COLUMN,
  SECTION,
  CONTAINER,
  MEDIA_CONTAINER,
  TPA_SECTION,
  TPA_WIDGET,
  REF_COMPONENT,
  SITE_BUTTON,
  STRIP_COLUMNS_CONTAINER,
  STYLABLE_BUTTON,
  DROPDOWN_MENU,
  STYLABLE_HORIZONTAL_MENU,
  SHAPE,
  SOCIAL_LINK_BAR,
  COLLAPSIBLE_TEXT,
  FORM_TEXT_INPUT,
  VIDEO_PLAYER,
  FORM_TEXT_AREA_INPUT,
  GOOGLE_MAP,
  PHOTO,
} = constants.COMP_TYPES;
const { GFPP_BUTTON_CLICK } = constants.COMP_META_DATA.CONTROLS;
const { highlights } = stateManagement;
const { getPreviewPosition } = stateManagement.domMeasurements.selectors;
const { isShownOnlyOnVariant, componentHasInteraction } =
  stateManagement.interactions.selectors;
const { getIsComponentVisibleInCurrentMode } =
  stateManagement.components.selectors;
const { isReferredComponentHidden } =
  stateManagement.componentMetaData.selectors;

export const getCurrentEditedSection = (editorAPI: EditorAPI): CompRef => {
  const [selectedComponent] = editorAPI.selection.getSelectedComponents();
  const selectedType = editorAPI.components.getType(selectedComponent);
  if (ALLOWED_ROOT_QUICK_EDIT_COMPONENTS.has(selectedType)) {
    return selectedComponent;
  }

  return (
    editorAPI.components.findAncestor(
      selectedComponent,
      (ancestorRef) =>
        ALLOWED_ROOT_QUICK_EDIT_COMPONENTS.has(
          editorAPI.components.getType(ancestorRef),
        ),
      {
        includeScopeOwner: true,
      },
    ) || editorAPI.sections.getHoveredSection()
  );
};

export const getFilteredChildren = (
  editorAPI: EditorAPI,
  parentRef: CompRef,
  isRecursively: boolean,
): CompRef[] =>
  editorAPI.components
    .getChildren_DEPRECATED_BAD_PERFORMANCE(parentRef, isRecursively)
    .map((compRef) => ({
      compRef,
      compType: editorAPI.components.getType(compRef),
    }))
    .filter(
      ({ compRef, compType }) =>
        !editorAPI.components.is.skipInQuickEditPanel(compRef) &&
        isNotTransparent(editorAPI, compRef, compType) &&
        canChangeBackground(editorAPI, compRef, compType),
    )
    .map(({ compRef }) => compRef);

export const updatePanelContextMenu = (
  scope: QuickEditScope,
  compRef: CompRef,
) => {
  const contextMenuConfig = scope.sectionsAPI.isHeaderOrFooter(compRef)
    ? getHeaderFooterContextMenuConfig(scope)
    : getContextMenuConfig(scope);
  scope.store.getQuickEditUpdatePanelConfigFn()({ contextMenuConfig });
};

const getScale = (
  height: number,
  width: number,
  thumbnailDimension: { width: number; height: number },
) => {
  const scaleHeight = thumbnailDimension.height / height;
  const scaleWidth = thumbnailDimension.width / width;
  return Math.min(scaleWidth, scaleHeight);
};

export const getPreviewDimensions = (
  height: number,
  width: number,
  thumbnailDimension: { width: number; height: number },
) => {
  const scale = getScale(height, width, thumbnailDimension);
  return {
    height: Math.min(height * scale, thumbnailDimension.height),
    width: Math.min(width * scale, thumbnailDimension.width),
  };
};

export const reSelectQuickEditRootComponentIfNeeded = (
  scope: QuickEditScope,
): CompRef => {
  const { editorAPI } = scope;
  if (editorAPI.selection.getSelectedComponents().length === 0) {
    let focusedViewportRootComp: CompRef;
    if (editorAPI.pages.popupPages.isPopupOpened()) {
      focusedViewportRootComp = editorAPI.pages.popupPages.getPopupContainer();
    } else {
      const pageRef = editorAPI.pages.getFocusedPage();
      const sectionsStageEntryIndex =
        sections.getStageEntryIndex(editorAPI, pageRef) - 1;
      focusedViewportRootComp =
        editorAPI.sections.getPageSectionsSortedByStageOrder(pageRef)?.[
          sectionsStageEntryIndex
        ];
    }
    if (focusedViewportRootComp) {
      editorAPI.selection.selectComponentByCompRef(focusedViewportRootComp);
      updatePanelContextMenu(scope, focusedViewportRootComp);
      return focusedViewportRootComp;
    }
  }
  return null;
};

export const scrollToComponent = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): Promise<void> => {
  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.SCROLL_TO_COMP_AND_OPEN_PANEL,
  );
  const previewDimensions = getPreviewPosition(editorAPI.store.getState());
  const previewCenterY = previewDimensions.height / 2;

  const compLayoutRelativeToScreen =
    editorAPI.components.layout.getRelativeToScreen(compRef);
  const compCenterYRelativeToScreen =
    compLayoutRelativeToScreen.y + compLayoutRelativeToScreen.height / 2;

  const duration = 0.5;
  return new Promise((resolve) => {
    editorAPI.scroll.animateScrollTo(
      { y: compCenterYRelativeToScreen - previewCenterY },
      duration,
      () => {
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.SCROLL_TO_COMP_AND_OPEN_PANEL,
        );
        setTimeout(resolve, duration * 1000); // animation
      },
    );
  });
};

export const getTextFromHtmlString = (htmlString: string) =>
  dataUtils.getTextContentFromHtml(htmlString).replace(/\s+/g, ' ');

const getDrillInPanelByName = (
  scope: QuickEditScope,
  panelName: WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES,
) => {
  const panels = scope.store
    .getDrillInPanels()
    .filter((drillInPanel) => drillInPanel.panelData.panelName === panelName);
  return panels;
};

export const openComponentPanelDrillIn = (
  scope: QuickEditScope,
  title?: string,
) => {
  const compPanelId = getDrillInPanelByName(
    scope,
    WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES.QUICK_EDIT_COMP_PANEL,
  )[0].panelData.panelId;
  scope.workspaceRightPanelApi.openDrillInPanel(compPanelId, {
    panelTitle: title,
  });
};

const getDrillInPanelTitle = (scope: QuickEditScope, compRef: CompRef) => {
  const compRefForDisplayName = scope.editorAPI.components.getContainer(
    scope.editorAPI.components.getContainer(compRef),
  );
  return scope.editorAPI.components.getDisplayName(compRefForDisplayName);
};

export const openQuickEditDrillIn = (
  scope: QuickEditScope,
  compRef: CompRef,
) => {
  const drillInTitle = getDrillInPanelTitle(scope, compRef);
  const quickEditDrillInPanelId = getDrillInPanelByName(
    scope,
    WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES.QUICK_EDIT_DRILL_IN,
  ).filter((drillInPanel) => !drillInPanel.compRef)[0].panelData.panelId;
  scope.store.updateDrillInPanel(quickEditDrillInPanelId, compRef);
  const quickEditDrillInIndex = scope.store.getDrillInPanels().length;
  const quickEditDrillIn = {
    panelConfig: getQuickEditDrillInPanelConfig(scope, compRef),
    panelComponent: () =>
      React.createElement(QuickEditDrillInPanelWrapper, {
        drillInPanelIndex: quickEditDrillInIndex,
        getDrillInPanelData: (drillInPanelIndex: number) =>
          scope.store.getDrillInPanels()[drillInPanelIndex],
        onDrillInPanelLoaded: (
          compId: string,
          panelName: string,
          compType: string,
        ) => {
          reportSecondaryPanelOpen(scope.store, compId, panelName, compType);
          scope.store.setDrillInPanelBiData({
            openTime: performance.now(),
            component_id: compId,
            panel_name: panelName,
            component_type: compType,
          });
        },
        ...getComponentControlWrapperProps(scope),
      }),
  };
  const backTooltip =
    quickEditDrillInIndex === NUMBER_OF_FIRST_LEVEL_DRILL_IN_PANELS
      ? { backTooltip: translate('edit_section_back_to_content_tooltip') }
      : {
          backTooltip: translate('edit_section_back_to_drillin_tooltip', {
            componentName: drillInTitle,
          }),
        };
  const drillInPanelsData = scope.workspaceRightPanelApi.openDrillInPanel(
    quickEditDrillInPanelId,
    { panelTitle: drillInTitle, ...backTooltip },
    [quickEditDrillIn],
  );
  scope.store.addDrillInPanels(
    drillInPanelsData.map((data) => ({ panelData: data })),
  );
};

export const getQuickEditDrillInPanelConfig = (
  scope: QuickEditScope,
  rootCompRef: CompRef,
) => ({
  panelName: WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES.QUICK_EDIT_DRILL_IN,
  panelTitle: '',
  onClose: () => {
    const { editorAPI, store } = scope;
    const quickEditDrillInPanels = getDrillInPanelByName(
      scope,
      WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES.QUICK_EDIT_DRILL_IN,
    ).filter((drillInPanel) => !!drillInPanel.compRef);
    const uniqueId =
      quickEditDrillInPanels[quickEditDrillInPanels.length - 1].panelData
        .panelId;
    scope.store.removeDrillInPanel();
    scope.store.updateDrillInPanel(uniqueId, null);
    scope.store.setCurrentEditedCompId(null);
    scope.editorAPI.store.dispatch(
      stateManagement.highlights.actions.clearHighlights(),
    );

    const { openTime, panel_name, component_id, component_type } =
      store.getLastOpenedDrillInPanelBiData();
    biLogger.report(
      panelHeaderClose({
        component_id,
        component_type,
        panel_name,
        timeSpent: Math.round(performance.now() - openTime),
      }),
    );
    store.removeLastDrillInBiData();
    editorAPI.selection.selectComponentByCompRef(rootCompRef);
  },
  backTooltip: translate('edit_section_back_to_content_tooltip'),
});

export const getMainActionIndex = (
  compType: string,
  hasRuntimeData: boolean,
) => {
  return compType === COLUMN && hasRuntimeData ? 1 : 0;
};

export const isNotInteractionComponent = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  compType: string,
) => {
  return !(
    QUICK_EDIT_COMPS_WITH_INTERACTIONS.has(compType) &&
    componentHasInteraction(editorAPI, compRef)
  );
};

export const isCompFiltered = (editorAPI: EditorAPI, compRef: CompRef) => {
  const compType = editorAPI.components.getType(compRef);
  if (compType === constants.COMP_TYPES.SECTION) return false;
  const isBlacklisted = editorAPI.components.is.skipInQuickEditPanel(compRef);
  if (isBlacklisted && compType === APP_WIDGET) {
    const [innerAppChild] = editorAPI.components.getChildren(compRef);
    if (innerAppChild) {
      biLogger.report(
        quickEditPanelReportings({
          component_type: editorAPI.components.getType(innerAppChild),
          error_type: `missing_app_widget_comp`,
        }),
      );
    }
  }
  const isNotInteraction = isNotInteractionComponent(
    editorAPI,
    compRef,
    compType,
  );
  if (
    (isBlacklisted && isNotInteraction) ||
    shouldFilterByBackground(editorAPI, compRef, compType)
  ) {
    return true;
  }
  const isNotVisibleInCurrentMode = !getIsComponentVisibleInCurrentMode(
    editorAPI.dsRead,
    compRef,
    editorAPI.store.getState(),
  );
  return (
    isNotVisibleInCurrentMode ||
    (isComponentInInteractionMode(editorAPI, compRef) &&
      !isShownOnlyOnVariant(editorAPI, compRef)) ||
    isReferredComponentHidden(editorAPI, compRef) ||
    !editorAPI.components.isRenderedOnSite(compRef)
  );
};

export const getPermissionsConfig = (
  compType: string,
  isAllowed: (uiResourceType: UIResourceRestriction) => boolean,
) => {
  const permissions = {} as any;

  switch (compType) {
    case SHAPE:
    case COLUMN:
    case SECTION:
    case CONTAINER:
    case STRIP_COLUMNS_CONTAINER:
    case SITE_BUTTON:
    case STYLABLE_BUTTON:
    case SOCIAL_LINK_BAR:
    case COLLAPSIBLE_TEXT:
    case FORM_TEXT_INPUT:
    case FORM_TEXT_AREA_INPUT:
    case MEDIA_CONTAINER:
    case VIDEO_PLAYER:
      permissions.tooltipHidden = !isAllowed(
        'quick-edit_comps-tooltip.visible',
      );
      break;
    case TPA_WIDGET:
    case TPA_SECTION:
    case REF_COMPONENT:
      permissions.buttonHidden = !isAllowed(
        'quick-edit_comps-secondary-action-button.visible',
      );
      break;
    case GOOGLE_MAP:
    case PHOTO:
      permissions.tooltipHidden = !isAllowed(
        'quick-edit_comps-tooltip.visible',
      );
      permissions.buttonHidden = !isAllowed(
        'quick-edit_comps-secondary-action-button.visible',
      );
      break;
    case DROPDOWN_MENU:
    case STYLABLE_HORIZONTAL_MENU:
      permissions.tooltipHidden = !isAllowed(
        'quick-edit_comps-tooltip.visible',
      );
      permissions.buttonDisabled = !isAllowed(
        'quick-edit_comps-action-button.interactive',
      );
      break;
  }

  return {
    permissions,
  };
};

export const getComponentControlWrapperProps = (scope: QuickEditScope) => {
  const { editorAPI, store } = scope;
  const highlightOnStage = (highlightType: string, customCompRef: CompRef) => {
    const stageDisplayNameOverride = getStageDisplayNameOverride(
      editorAPI,
      customCompRef,
    );
    const compBoundingBox =
      editorAPI.components.layout.measure.getBoundingClientRect(customCompRef);
    editorAPI.store.dispatch(
      highlights.actions.addToHighlights([
        {
          compRef: customCompRef,
          displayNameOverride: stageDisplayNameOverride,
          type: highlightType,
          layout: {
            ...compBoundingBox,
            y: compBoundingBox.absoluteTop,
            x: compBoundingBox.absoluteLeft,
          },
        },
      ]),
    );
  };

  const onControlHover = (
    customCompRef: CompRef,
    rootControlCompRef: CompRef,
  ) => {
    if (
      editorAPI.utils.isSameRef(rootControlCompRef, customCompRef) ||
      editorAPI.utils.isSameRef(
        editorAPI.selection.getSelectedComponents()[0],
        customCompRef,
      )
    ) {
      return;
    }
    highlightOnStage(constants.UI.HIGHLIGHTS.TYPES.OVERLAY, customCompRef);
  };

  const onControlLeave = () => {
    editorAPI.store.dispatch(
      highlights.actions.removeFromHighlightsByHighlightType([
        constants.UI.HIGHLIGHTS.TYPES.OVERLAY,
      ]),
    );
  };

  const onMainActionClick = (
    compRef: CompRef,
    hasPrimaryConnection: boolean,
  ) => {
    const { editorAPI } = scope;
    const compGfppData = gfppModel.getFullComponentGfppData(
      editorAPI,
      [compRef],
      quickEditPanelOrigin,
    );
    const mainActions = compGfppData.mainActions;
    const compType = editorAPI.components.getType(compRef);
    const mainAction =
      mainActions[getMainActionIndex(compType, hasPrimaryConnection)];
    const compRefForClick = controlsUtils.getFirstControllableComponent(
      editorAPI,
      compRef,
      GFPP_BUTTON_CLICK,
    );
    mainAction.onClick(editorAPI, [compRefForClick], quickEditPanelOrigin);
  };

  const getActionFromMainActions = (
    actionType: GFPPActionType,
    mainActions: Array<GFPPAction>,
  ) => {
    const actionByType = mainActions.find(
      (action) => action.actionType == actionType,
    );
    return actionByType || mainActions[1];
  };

  const onGfppAction = (compRef: CompRef, actionType: GFPPActionType) => {
    const compGfppData = gfppModel.getFullComponentGfppData(
      editorAPI,
      [compRef],
      quickEditPanelOrigin,
    );
    const compRefForClick = controlsUtils.getFirstControllableComponent(
      editorAPI,
      compRef,
      GFPP_BUTTON_CLICK,
    );
    (
      compGfppData.presetActions[actionType] ||
      getActionFromMainActions(actionType, compGfppData.mainActions)
    ).onClick(editorAPI, [compRefForClick], quickEditPanelOrigin);
  };

  return {
    onGfppAction,
    onControlHover,
    onControlLeave,
    onMainActionClick,
    allowedRootComponents: [...ALLOWED_ROOT_QUICK_EDIT_COMPONENTS],
    getOverrideCompControls: (compRef: CompRef) =>
      overrideCompControls[editorAPI.components.getType(compRef)],
    onControlClick: (
      compRef: CompRef,
      action: QuickEditControlActions | GFPPActionType,
    ) => {
      const compType = editorAPI.components.getType(compRef);
      const isConnectedToDataBinding =
        stateManagement.platform.selectors.isConnectedToDataBindingController(
          compRef,
          editorAPI.dsRead,
        );
      reportComponentClickEvent(
        store,
        editorAPI,
        compRef,
        compType,
        action,
        isConnectedToDataBinding,
      );
    },
    onComponentChanged: (compRef: CompRef) => {
      const compType = editorAPI.components.getType(compRef);
      reportComponentChanged(scope, compRef, compType);
    },
    isComponentFiltered: (compRef: CompRef) =>
      isCompFiltered(editorAPI, compRef),
    getPermissionsConfig,
    openComponentPanelDrillIn: (title?: string) =>
      openComponentPanelDrillIn(scope, title),
    onDrillInAction: (compRef: CompRef) => openQuickEditDrillIn(scope, compRef),
    onLinkAction: (compRef: CompRef) => {
      const { panel_correlation_id, section_correlation_id } =
        store.getQuickEditBiData();
      editorAPI.openLinkPanel({
        link: editorAPI.components.data.get(compRef).link,
        callback(newLinkData: Link) {
          editorAPI.components.data.update(compRef, { link: newLinkData });
        },
        origin: quickEditPanelOrigin,
        biOrigin: quickEditPanelOrigin,
        section_correlation_id,
        panel_correlation_id,
      });
    },
    getThumbnail: async (
      compDef: CompStructure,
      thumbnailDimensions: { width: number; height: number },
      onReady?: () => void,
    ) => {
      const { height, width } = getPreviewDimensions(
        compDef.layout.height,
        compDef.layout.width,
        thumbnailDimensions,
      );
      const thumbnailArray = await scope.previewerApi.createPreviewComponents({
        previewsData: [
          {
            structure: compDef,
            displayWidth: width,
            targetDefaultHeight: height,
            displayMaxHeight: height,
            baseWidth: compDef.layout.width,
            onReady,
          },
        ],
      });
      return thumbnailArray[0];
    },
  };
};

export const getTitleWithLanguage = (
  editorAPI: EditorAPI,
  languageCode?: string,
) => {
  if (!languageCode) {
    languageCode = editorAPI.language.current.get();
  }

  const groupName =
    WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES.EDIT_SECTION_MULTILINGUAL;
  const titleKey = WORKSPACE_RIGHT_PANEL_TAB_GROUP_TITLES[groupName];

  const language = editorAPI.language
    .get()
    .find((lang) => lang.code === languageCode).name;

  return translate(titleKey, { language });
};
