// @ts-nocheck
const DELAY_BETWEEN_CLICKS = 1000;

export default {
  setClickTimeStamp(e) {
    this.clickTimestamp = e.timeStamp;
  },
  isAnotherMouseActionAllowed(e) {
    return (
      !this.clickTimestamp ||
      e.timeStamp - this.clickTimestamp > DELAY_BETWEEN_CLICKS
    );
  },
};
