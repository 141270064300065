import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';
import type {
  MapDispatchToProps,
  DispatchMapperArgs,
  Dispatch,
} from 'types/redux';
import type { AdvancedMenusMigrationPanelOwnProps } from './advancedMenusMigrationPanel';

export interface AdvancedMenusMigrationPanelDispatchProps {
  openHelpCenter: (helpId: string) => void;
  closePanelByName: (panelName: string) => void;
}

const getEditorAPI = (
  _dispatch: Dispatch,
  _getState: () => EditorState,
  { editorAPI }: DispatchMapperArgs,
): EditorAPI => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  AdvancedMenusMigrationPanelDispatchProps,
  AdvancedMenusMigrationPanelOwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    openHelpCenter: editorAPI.help.openHelpCenter,
    closePanelByName: editorAPI.panelManager.closePanelByName,
  };
};
