import addPanelDataConsts, { type AddMenuSection } from '@/addPanelDataConsts';
import {
  LOGIN_BAR_ADD_PANEL_APP_WIDGET,
  MEMBERS_MENU_ADD_PANEL_APP_WIDGET,
} from './definitions';

// TODO Oded get preset data from members app manifest
const loginBarSection: AddMenuSection = {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: 'add_section_label_loginbar',
  subNavigationTitle: 'add_section_label_loginbar',
  presetTitle: 'add_section_label_loginbar',
  tooltipTitle: 'add_section_label_loginbar',
  subNavigationHide: false,
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
    iconEnabledForComps: {},
  },
  props: {
    image:
      'addPanelData/sections/membersLoginBarSection_en/membersLoginBarSection_en.png',
    items: [
      {
        id: 'Login_Button_1',
        structure: {
          layout: {
            x: 685,
            y: 69,
            fixedPosition: false,
            width: 230,
            height: 40,
            scale: 1,
            rotationInDegrees: 0,
          },
          componentType: 'wysiwyg.viewer.components.LoginSocialBar',
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin',
          data: {
            type: 'LoginSocialBar',
            iconItemsRef: null,
            language: 'en',
            loggedInMember: 'avatarAndText',
            loggedOutText: 'Log In',
            showLoggedInText: false,
            loggedInText: 'Hello',
            logOutText: 'Log Out',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
          },
          props: {
            type: 'LoginSocialBarProperties',
            buttonsDirection: 'ltr',
            iconSize: 26,
            dropDownTextAlignment: 'left',
            buttonsAlignment: 'right',
            showBellIcon: true,
            arrowShape: 'line',
            arrowSize: 14,
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
          },
          style: {
            type: 'TopLevelStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: {
              properties: {
                'alpha-bg': '0',
                'alpha-brd': '1',
                'alpha-brd-dd': '0.2',
                'badge-bg': '#E21C21',
                'badge-txt': 'color_11',
                bg: 'color_11',
                'bg-dd': 'color_11',
                'boxShadowToggleOn-shd': 'false',
                'boxShadowToggleOn-shd-dd': 'false',
                brd: 'color_15',
                'brd-dd': 'color_15',
                brw: '0',
                'brw-dd': '1px',
                fillcolor: 'color_18',
                fnt: 'font_8',
                'fnt-size-dd': '15px',
                rd: '0px',
                'rd-dd': '0px',
                shd: '0 0 0 transparent',
                'shd-dd': '0 0 0 transparent',
                txt: 'color_18',
                'txt-dd': 'color_15',
                'txt-slct-dd': 'color_19',
                txth: 'color_19',
                'txth-dd': 'color_19',
                avatarAndArrowStrokeWidth: '0',
                avatarCornerRadius: '100px',
              },
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wysiwyg.viewer.components.LoginSocialBar',
            pageId: '',
            styleType: 'custom',
            skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin',
          },
          activeModes: {},
        },
        preset: { rect: { width: 324, height: 86, x: 0, y: 0 } },
      },
    ],
  },
  help: { hide: false, text: 'add_section_label_loginbar_tooltip_description' },
};

const memberMenuSection: AddMenuSection = {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: 'add_section_label_membermenu',
  subNavigationTitle: 'add_section_label_membermenu',
  presetTitle: 'add_section_label_membermenu',
  tooltipTitle: 'add_section_label_membermenu',
  subNavigationHide: false,
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
    iconEnabledForComps: {},
  },
  props: {
    image:
      'addPanelData/sections/membersMenuSection_en/membersMenuSection_en.png',
    items: [
      {
        id: 'Member_Menu_1',
        structure: {
          layout: {
            x: 20,
            y: 273,
            fixedPosition: false,
            width: 250,
            height: 94,
            scale: 1,
            rotationInDegrees: 0,
          },
          componentType:
            'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu',
          type: 'Component',
          skin: 'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSolidColorSkin',
          data: {
            menuRef: '#MEMBERS_SUB_MENU',
            type: 'CustomMenuDataRef',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
          },
          props: {
            type: 'VerticalMenuProperties',
            itemsAlignment: 'left',
            subMenuOpenSide: 'right',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            menuItemHeight: 40,
          },
          style: {
            type: 'TopLevelStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: {
              properties: {
                SKINS_bgSubmenu: '#FFFFFF',
                SKINS_fntSubmenu: 'font_8',
                SKINS_submenuBR: '0',
                SKINS_submenuMargin: '0',
                'alpha-SKINS_bgSubmenu': '1',
                'alpha-bg': '1',
                'alpha-bgh': '1',
                'alpha-bgs': '1',
                'alpha-brd': '0.2',
                'alpha-sep': '1',
                bg: 'color_11',
                bgh: 'color_11',
                bgs: 'color_11',
                'boxShadowToggleOn-shd': 'false',
                brd: 'color_15',
                brw: '1px',
                fnt: 'font_8',
                rd: '0',
                sep: 'color_15',
                sepw: '0',
                shd: '0',
                textSpacing: '30',
                txt: 'color_15',
                txth: 'color_14',
                txts: 'color_18',
              },
              propertiesSource: {
                SKINS_bgSubmenu: 'theme',
                SKINS_fntSubmenu: 'theme',
                SKINS_submenuBR: 'value',
                SKINS_submenuMargin: 'value',
                'alpha-SKINS_bgSubmenu': 'value',
                'alpha-bg': 'value',
                'alpha-bgh': 'value',
                'alpha-bgs': 'value',
                'alpha-brd': 'value',
                'alpha-sep': 'value',
                bg: 'theme',
                bgh: 'theme',
                bgs: 'theme',
                'boxShadowToggleOn-shd': 'value',
                brd: 'theme',
                brw: 'value',
                fnt: 'theme',
                rd: 'value',
                sep: 'theme',
                sepw: 'value',
                shd: 'value',
                textSpacing: 'value',
                txt: 'theme',
                txth: 'value',
                txts: 'value',
              },
              groups: {},
            },
            componentClassName:
              'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu',
            pageId: '',
            compId: 'i765dc0m',
            styleType: 'custom',
            skin: 'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSolidColorSkin',
          },
          activeModes: {},
        },
        preset: { rect: { width: 324, height: 166, x: 0, y: 0 } },
      },
    ],
  },
  help: {
    hide: false,
    text: 'add_section_label_memberprofile_tooltip_description',
  },
};

const loginBarSectionAppWidget = {
  ...loginBarSection,
  props: {
    ...loginBarSection.props,
    items: [LOGIN_BAR_ADD_PANEL_APP_WIDGET],
  },
};

const memberMenuSectionAppWidget = {
  ...memberMenuSection,
  props: {
    ...memberMenuSection.props,
    items: [MEMBERS_MENU_ADD_PANEL_APP_WIDGET],
  },
};

export default {
  getCategorySections() {
    return {
      loginBarSection: loginBarSectionAppWidget,
      memberMenuSection: memberMenuSectionAppWidget,
    };
  },
};
