import React from 'react';
import { hoc } from '@/util';
import {
  ControlHeader,
  Composites,
  IconButton,
  TextInput,
  Tooltip,
} from '@wix/wix-base-ui';
import { DataSmall, EditSmall } from '@wix/wix-ui-icons-common/classic-editor';

import type { CompRef } from 'types/documentServices';
import type { StateMapperArgs } from 'types/redux';
import type { ControlHeaderProps } from '@wix/wix-base-ui';

interface TextWithRuntimeDataControlOwnProps {
  compRef: CompRef;
  labelProps: ControlHeaderProps['labelProps'];
  onMainActionClick: () => void;
}

interface TextWithRuntimeDataControlDispatchProps {
  getRuntimeLabel: () => string;
}

interface TextWithRuntimeDataControlProps
  extends TextWithRuntimeDataControlOwnProps,
    TextWithRuntimeDataControlDispatchProps {}

const TextWithRuntimeDataControl: React.FC<TextWithRuntimeDataControlProps> = ({
  labelProps,
  onMainActionClick,
  getRuntimeLabel,
}: TextWithRuntimeDataControlProps) => {
  return (
    <Composites.TextInputWithSuffix>
      <ControlHeader
        labelProps={labelProps}
        infoIconProps={{
          text: 'edit_section_text_data_connected_disabledtext_tooltip',
        }}
        suffix={[
          <Tooltip content={'edit_section_data_connected_label'}>
            <DataSmall />
          </Tooltip>,
        ]}
      />
      <TextInput value={getRuntimeLabel()} disabled />
      <Tooltip content={'edit_section_data_edit_label'}>
        <IconButton onClick={() => onMainActionClick()}>
          <EditSmall />
        </IconButton>
      </Tooltip>
    </Composites.TextInputWithSuffix>
  );
};

const mapDispatchToProps = (
  { editorAPI }: StateMapperArgs,
  { compRef }: TextWithRuntimeDataControlOwnProps,
) => {
  return {
    getRuntimeLabel: () => {
      const textSnapshot = editorAPI.snapshot.getSnapshot(compRef);
      const div = document.createElement('div');
      div.innerHTML = textSnapshot;
      return div.innerText.replace(/(\n|#snap)+.*/g, '').slice(0, 50);
    },
  };
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapDispatchToProps,
)(TextWithRuntimeDataControl);
