import React, { useEffect, useMemo } from 'react';
import { RawHtmlPreviewThumbnail } from '../addSection/components/RawHtmlPreviewThumbnail';
import HtmlPreviewThumbnailManager from '../htmlPreviewThumbnail/htmlPreviewThumbnailManager';

import { fedopsLogger } from '@/util';

import type { SectionPresetDefinition, ExtraPreviewProps } from '../types';

interface AddSectionRawHtmlPreviewProps extends ExtraPreviewProps {
  sectionDefinition: SectionPresetDefinition;
  stageEntryIndex: number;
  onReady: () => void;
}

const AddSectionRawHtmlPreview = ({
  sectionDefinition,
  onReady,
  height,
  width,
}: AddSectionRawHtmlPreviewProps) => {
  const { previewHtmlUrl, originalPresetWidth, previewResponsePath } =
    sectionDefinition;

  useEffect(() => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.ADD_SECTION_PREVIEW_LOAD
        .ADD_SECTION_RAW_HTML_PREVIEW_LOAD,
    );
  }, []);

  const htmlPreviewThumbnailManager = useMemo<HtmlPreviewThumbnailManager>(
    () =>
      new HtmlPreviewThumbnailManager('', previewHtmlUrl, previewResponsePath),
    [previewHtmlUrl, previewResponsePath],
  );

  const handleHTMLPreviewReady = () => {
    onReady();
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.ADD_SECTION_PREVIEW_LOAD
        .ADD_SECTION_RAW_HTML_PREVIEW_LOAD,
    );
  };
  return (
    <div style={{ height }}>
      <RawHtmlPreviewThumbnail
        previewHtmlManager={htmlPreviewThumbnailManager}
        originalPresetWidth={originalPresetWidth}
        displayWidth={width}
        onPreviewReady={handleHTMLPreviewReady}
      />
    </div>
  );
};

export default AddSectionRawHtmlPreview;
