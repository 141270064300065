export default {
  nestedBackgroundDesign: true,
  slidesContainer: true,
  focusable: true,
  canContain: false,
  exposesSlideAsInnerComponent: true,
  canContainByStructure: false,
  allowLassoOnContainer: true,
  pinnable: false,
};
