import { url as urlUtils } from '@/util';

import type { SectionsMigrationScope as Scope } from '../scope';

export const refreshWithForceMigration = async (scope: Scope) => {
  if (!urlUtils.isQA()) {
    await new Promise((resolve, reject) =>
      scope.editorAPI.saveManager.saveInBackground(
        resolve,
        reject,
        'optionalSectionsMigration',
        {
          forceOBMigration: true,
          sourceOfStart: 'optionalSectionsMigration_bgSave',
        },
      ),
    );
  }

  const trimmedUrl = window.location.href.split('#')[0];
  const migrationFlag = `&forceSectionsMigration=true`;

  window.onbeforeunload = null;
  window.location.href = trimmedUrl + migrationFlag;
};
