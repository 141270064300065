"use strict";

const _ = require("lodash");

const mergePropsItems = require("./merge-props-items");

function mergePresetVideo(item, video) {
  // equivalent to `_.merge({}, item, {preset: {video}}))`, but faster

  return {
    ...item,
    preset: {
      ...item.preset,
      video,
    },
  };
}

function getSectionVideo(videoSrcPrefix, srcSuffix) {
  return {
    poster: "poster-white-1x1.png",
    source: videoSrcPrefix + srcSuffix,
  };
}

function mergeSectionVideos(section, videos) {
  const { items } = section.props;

  const itemsWithVideos = _.map(items, (item, index) =>
    mergePresetVideo(item, getSectionVideo(videos.videoSrcPrefix, index + 1))
  );

  return mergePropsItems(section, itemsWithVideos);
}

module.exports = mergeSectionVideos;
