// @ts-nocheck
import _ from 'lodash';
import * as ckUtils from './ckUtils';
import CONSTANTS from './constants';

const TEXT_LIMIT_EXCEEDED_PANEL_NAME =
  'panels.focusPanels.createNewTextBoxPanel';
let cachedTextReadableLength;

function isTextLengthAboveMax(newLength) {
  return newLength > CONSTANTS.MAX_TEXT_LENGTH;
}

function isNewTextLongerThanPrevious(newLength) {
  return (
    Math.max(newLength, cachedTextReadableLength) > cachedTextReadableLength
  );
}

function handleTextLengthExceeded(panelManager, textEditor) {
  // While CK is in stages of applying 'setData' command, it cannot undo, hence the '_.defer'
  _.defer(function () {
    textEditor.undo();
    ckUtils.setCkReadOnly(textEditor, true);
  });

  bindEscKeyListener(panelManager, textEditor);

  panelManager.openPanel(
    TEXT_LIMIT_EXCEEDED_PANEL_NAME,
    { isSingleInstance: true },
    true,
  );
}

function bindEscKeyListener(panelManager, textEditor) {
  textEditor.onKey(function closePanelOnEscKey(event) {
    if (event && isTextExceededPanelClosed(panelManager)) {
      textEditor.unRegisterListener(closePanelOnEscKey());
    } else if (event && event.keyCode === 27) {
      panelManager.closePanelByName(TEXT_LIMIT_EXCEEDED_PANEL_NAME);
      textEditor.unRegisterListener(closePanelOnEscKey());
    }
  });
}

function isTextExceededPanelClosed(panelManager) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  return !_.find(panelManager.getOpenPanels(), {
    name: TEXT_LIMIT_EXCEEDED_PANEL_NAME,
  });
}

function setCachedTextReadableLength(newLength) {
  cachedTextReadableLength = newLength;
}

function isNewTextLengthLimitExceeded(newTextLength) {
  return (
    isTextLengthAboveMax(newTextLength) &&
    isNewTextLongerThanPrevious(newTextLength)
  );
}

export {
  handleTextLengthExceeded,
  setCachedTextReadableLength,
  isNewTextLengthLimitExceeded,
};
