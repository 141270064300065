import { createNonThemedAction } from '../utils/nonThemedAction';
import type { NonThemedActionCreationData } from '../utils/textAction';

const backColorActionCreationData: Readonly<
  NonThemedActionCreationData<string | undefined>
> = {
  execCommand: (textManager, color) => {
    textManager.commands.backColor(color);
  },
  getWixRichTextCommandState: (textManager) => {
    return textManager.getCommandsStates().backColor;
  },
  execRevertCommand: (textManager) => textManager.commands.backColor(''),
  createUpdatedStyleProperties: (backColor) => ({
    backgroundColor:
      backColor === '' ? undefined : backColor.replace('back', ''),
  }),
  getPropertyValue: (styleProperties) => styleProperties.backgroundColor,
  getDefaultValue: () => undefined,
};

export const backColorAction = createNonThemedAction(
  backColorActionCreationData,
);
