import styles from './emptyState.scss';
import React from 'react';
import { RichText, Heading, Text, TextButton } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

export interface EmptyStateProps {
  iconName: any;
  title: string;
  subtitle: string;
  linkText: string;
  onLinkClick: () => void;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  iconName,
  title,
  subtitle,
  linkText,
  onLinkClick,
}) => {
  return (
    <div
      data-hook="switch-layout-empty-state"
      className={styles['empty-state-container']}
    >
      <symbols.symbol name={iconName} className={styles.icon} />
      <RichText>
        <div className={styles['title-container']}>
          <Heading appearance="h4" multiline={true}>
            {title}
          </Heading>
        </div>
        <div className={styles.subtitle}>
          <Text size="small" weight="normal" enableEllipsis={false}>
            {subtitle}
          </Text>
        </div>
        <TextButton
          className={styles.link}
          onClick={onLinkClick}
          children={linkText}
        />
      </RichText>
    </div>
  );
};
