import _ from 'lodash';

import { openOrUpdatePanel } from '../utils/utils';

import type { EditorAPI } from '@/editorAPI';

export enum IntentType {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

interface AbstractIntent {
  type: IntentType;
  props?: object;
}

export const createPanelIntents = <Intent extends AbstractIntent>(
  editorAPI: EditorAPI,
  panelName: string,
) => {
  let lastIntent: Intent = null;

  const commit = () => {
    if (!lastIntent) {
      return;
    }

    const { type, props } = lastIntent;

    switch (type) {
      case IntentType.OPEN:
        openOrUpdatePanel(editorAPI, panelName, props, true);
        break;

      case IntentType.CLOSE:
        editorAPI.panelManager.closePanelByName(panelName);
        break;

      default:
        break;
    }
  };

  const debouncedCommit = _.debounce(commit, 200);

  const set = (intent: Intent) => {
    lastIntent = intent;
    debouncedCommit();
  };

  return {
    set,
  };
};
