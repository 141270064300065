:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_qq97a_7 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 42px;
  height: 42px; }

._ring_qq97a_15 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

._track_qq97a_22 {
  stroke: #dfe5eb;
  fill: none; }

._progress_qq97a_26 {
  stroke: #25a55a;
  fill: none;
  transition: stroke-dashoffset 0.25s ease;
  transform: rotate(-90deg);
  transform-origin: 50% 50%; }
