import React, { type FC } from 'react';
import { Text } from '@wix/wix-base-ui';
import styles from './BusinessAppHeaderLayout.scss';

export interface BusinessAppHeaderLayoutProps {
  illustration: React.ReactNode;
  title: string;
  subtitle: string;
  dataHook: string;
  action?: React.ReactNode;
}

export const BusinessAppHeaderLayout: FC<BusinessAppHeaderLayoutProps> = ({
  illustration,
  title,
  subtitle,
  dataHook,
  action,
}) => (
  <div className={styles.container} data-hook={dataHook}>
    <div className={styles.illustration}>{illustration}</div>
    <div className={styles.description}>
      <Text
        skin="standard"
        size="medium"
        weight="bold"
        shouldTranslate={false}
        dataHook={`${dataHook} title`}
      >
        {title}
      </Text>
      <Text
        skin="secondary"
        size="small"
        weight="thin"
        shouldTranslate={false}
        dataHook={`${dataHook} description`}
      >
        {subtitle}
      </Text>
    </div>
    {action ? <div className={styles.action}>{action}</div> : null}
  </div>
);
