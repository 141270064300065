import React, { type FC } from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import MessagePanelFrame from '../frames/messagePanelFrame/messagePanelFrame';
import { translate } from '@/i18n';

export interface AiSectionCreatorErrorPanelProps {
  closePanel: () => void;
}

const aiSectionCreatorErrorPanel: FC<AiSectionCreatorErrorPanelProps> = ({
  closePanel,
}) => (
  <MessagePanelFrame
    panelName={translate('ai_template_injection_high_traffic_error_title')}
    title="Discard changes"
    onConfirm={closePanel}
    onCancel={closePanel}
    confirmLabel={translate('ai_template_injection_high_traffic_error_cta')}
    illustration={<Symbol name="serverError_NewWorkspace" />}
    onSecondaryAction={closePanel}
    cancelOnClickOutside={true}
    cancelOnEscPress={true}
  >
    {translate('ai_template_injection_high_traffic_error_text')}
  </MessagePanelFrame>
);

export default aiSectionCreatorErrorPanel;
