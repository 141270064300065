import { sections as sectionsUtils } from '@/util';

import type {
  RegularSkinGetter,
  RegularSkinSchema,
  SOAPSkinSchema,
  SOAPSkinGetter,
} from '../skins/skins.types';
import {
  getCurrentEditorMode,
  getSectionLikeSelectionType,
  pickSkinFromSchema,
} from '../skins/skins.utils';

const sectionSchema: RegularSkinSchema = {
  desktop: {
    hovered: 'secondary',
    selected: 'primary',
    focused: 'secondary',
  },
  desktopZoomOut: {
    hovered: 'secondary',
    selected: 'primary',
  },
  mobile: {
    hovered: 'secondary',
    selected: 'primary',
    focused: 'secondary',
  },
  mobileZoomOut: {
    hovered: 'secondary',
    selected: 'primary',
  },
  desktopRightShrinked: {
    hovered: 'secondary',
    selected: 'secondary',
  },
};

const siteSegmentSchema: SOAPSkinSchema = {
  desktop: {
    hovered: 'secondary-soap',
    selected: 'primary-soap',
    focused: 'secondary-soap',
  },
  desktopZoomOut: {
    hovered: 'secondary-soap',
    selected: 'primary-soap',
  },
  mobile: {
    hovered: 'secondary-soap',
    selected: 'primary-soap',
    focused: 'secondary-soap',
  },
  mobileZoomOut: {
    hovered: 'secondary-soap',
    selected: 'primary-soap',
  },
};

export const getSectionResizeHandleSkin: RegularSkinGetter = (
  editorAPI,
  compRef,
  selectionData,
) => {
  if (!sectionsUtils.isSectionsControlsRedesignEnabled()) return 'legacy';

  const mode = getCurrentEditorMode(editorAPI);
  const selectionType = getSectionLikeSelectionType(
    editorAPI,
    compRef,
    selectionData,
  );

  return pickSkinFromSchema(sectionSchema, mode, selectionType);
};

export const getSiteSegmentResizeHandleSkin: SOAPSkinGetter = (
  editorAPI,
  compRef,
  selectionData,
) => {
  if (!sectionsUtils.isSectionsControlsRedesignEnabled()) return 'legacy-soap';

  const mode = getCurrentEditorMode(editorAPI);
  const selectionType = getSectionLikeSelectionType(
    editorAPI,
    compRef,
    selectionData,
  );

  return pickSkinFromSchema(siteSegmentSchema, mode, selectionType);
};
