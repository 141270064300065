'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_strip',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/stripsSection_en/stripsSection_en.png',
        'imageHover': null,
        'items': [{
            'id': 'Box_Strips_1',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.bgimagestrip.DoubleBorderScreenSkin',
                'layout': {
                    'width': 1905,
                    'height': 360,
                    'x': 0,
                    'y': 45,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.BgImageStrip',
                'props': {
                    'type': 'BgImageStripUnifiedProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fittingType': 'tile',
                    'alignType': 'center'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'alpha-brd2': '1',
                            'bg': '#FFFFFF',
                            'bgPosition': '0px',
                            'bordersPosition': '0px',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#000000',
                            'brd2': '#002E5D',
                            'innerLineSize': '0px',
                            'lineGap': '0px',
                            'outerLineSize': '6px',
                            'shd': '0px 0px 5px 0px rgba(0,0,0,0.7)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgPosition': 'value',
                            'bordersPosition': 'value',
                            'brd': 'value',
                            'brd2': 'value',
                            'innerLineSize': 'value',
                            'lineGap': 'value',
                            'outerLineSize': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.BgImageStrip',
                    'pageId': '',
                    'compId': 'i5z9sr6l',
                    'styleType': 'custom',
                    'skin': 'skins.viewer.bgimagestrip.DoubleBorderScreenSkin'
                },
                'id': 'i5z9sr6l'
            },
            'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 0}, 'label': '', 'tags': null}
        }, {
            'id': 'Box_Strips_2',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.bgimagestrip.IronScreenSkin',
                'layout': {
                    'width': 1905,
                    'height': 360,
                    'x': 0,
                    'y': 495,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.BgImageStrip',
                'props': {
                    'type': 'BgImageStripUnifiedProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fittingType': 'tile',
                    'alignType': 'center'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'bg': '#3D9BE9',
                            'boxShadowToggleOn-shd': 'true',
                            'shd': '0 0 5px rgba(0, 0, 0, 0.7)'
                        },
                        'propertiesSource': {'bg': 'value', 'shd': 'value'}
                    },
                    'componentClassName': 'wysiwyg.viewer.components.BgImageStrip',
                    'pageId': '',
                    'compId': 'i5z9vn1f',
                    'styleType': 'custom',
                    'skin': 'skins.viewer.bgimagestrip.IronScreenSkin'
                },
                'id': 'i5z9vn1f'
            },
            'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 90}, 'label': '', 'tags': null}
        }, {
            'id': 'Box_Strips_3',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.bgimagestrip.BgImageStripSkin',
                'layout': {
                    'width': 1905,
                    'height': 360,
                    'x': 0,
                    'y': 945,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.BgImageStrip',
                'props': {
                    'type': 'BgImageStripUnifiedProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fittingType': 'tile',
                    'alignType': 'center'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#2B6CA3',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#002E5D',
                            'brwb': '12px',
                            'brwt': '0px',
                            'shd': '0 0 5px rgba(0, 0, 0, 0.7)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'brd': 'value',
                            'brwb': 'value',
                            'brwt': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.BgImageStrip',
                    'pageId': '',
                    'compId': 'i5za2ice',
                    'styleType': 'custom',
                    'skin': 'skins.viewer.bgimagestrip.BgImageStripSkin'
                },
                'id': 'i5za2ice'
            },
            'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 180}, 'label': '', 'tags': null}
        }, {
            'id': 'Box_Strips_4',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.bgimagestrip.DoubleBorderScreenSkin',
                'layout': {
                    'width': 1905,
                    'height': 360,
                    'x': 0,
                    'y': 1395,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.BgImageStrip',
                'props': {
                    'type': 'BgImageStripUnifiedProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fittingType': 'tile',
                    'alignType': 'center'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'alpha-brd2': '1',
                            'bg': '#F2668F',
                            'bgPosition': '0px',
                            'bordersPosition': '15px',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#FFFFFF',
                            'brd2': '#FFFFFF',
                            'innerLineSize': '0px',
                            'lineGap': '15px',
                            'outerLineSize': '7px',
                            'shd': '0px 0px 5px 0px rgba(0,0,0,0.7)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgPosition': 'value',
                            'bordersPosition': 'value',
                            'brd': 'value',
                            'brd2': 'value',
                            'innerLineSize': 'value',
                            'lineGap': 'value',
                            'outerLineSize': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.BgImageStrip',
                    'pageId': '',
                    'compId': 'i5za55u7',
                    'styleType': 'custom',
                    'skin': 'skins.viewer.bgimagestrip.DoubleBorderScreenSkin'
                },
                'id': 'i5za55u7'
            },
            'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 270}, 'label': '', 'tags': null}
        }, {
            'id': 'Box_Strips_5',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.bgimagestrip.BgImageStripSkin',
                'layout': {
                    'width': 1905,
                    'height': 360,
                    'x': 0,
                    'y': 1845,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.BgImageStrip',
                'props': {
                    'type': 'BgImageStripUnifiedProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fittingType': 'tile',
                    'alignType': 'center'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#ED1566',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#FFFFFF',
                            'brwb': '0px',
                            'brwt': '0px',
                            'shd': '0 0 5px rgba(0, 0, 0, 0.7)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'brd': 'value',
                            'brwb': 'value',
                            'brwt': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.BgImageStrip',
                    'pageId': '',
                    'compId': 'i5za7hk5',
                    'styleType': 'custom',
                    'skin': 'skins.viewer.bgimagestrip.BgImageStripSkin'
                },
                'id': 'i5za7hk5'
            },
            'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 360}, 'label': '', 'tags': null}
        }, {
            'id': 'Box_Strips_6',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.bgimagestrip.BgImageStripSkin',
                'layout': {
                    'width': 1905,
                    'height': 360,
                    'x': 0,
                    'y': 2295,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.BgImageStrip',
                'props': {
                    'type': 'BgImageStripUnifiedProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fittingType': 'tile',
                    'alignType': 'center'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#D61043',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#FFFFFF',
                            'brwb': '0px',
                            'brwt': '0px',
                            'shd': '0 0 5px rgba(0, 0, 0, 0.7)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'brd': 'value',
                            'brwb': 'value',
                            'brwt': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.BgImageStrip',
                    'pageId': '',
                    'compId': 'i5za7y4w',
                    'styleType': 'custom',
                    'skin': 'skins.viewer.bgimagestrip.BgImageStripSkin'
                },
                'id': 'i5za7y4w'
            },
            'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 450}, 'label': '', 'tags': null}
        }, {
            'id': 'Box_Strips_7',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.bgimagestrip.DoubleBorderScreenSkin',
                'layout': {
                    'width': 1905,
                    'height': 360,
                    'x': 0,
                    'y': 2745,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.BgImageStrip',
                'props': {
                    'type': 'BgImageStripUnifiedProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fittingType': 'tile',
                    'alignType': 'center'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'alpha-brd2': '1',
                            'bg': '#BDBF37',
                            'bgPosition': '15px',
                            'bordersPosition': '0px',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#BDBF37',
                            'brd2': '#BDBF37',
                            'innerLineSize': '6px',
                            'lineGap': '9px',
                            'outerLineSize': '6px',
                            'shd': '0px 0px 5px 0px rgba(0,0,0,0.7)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgPosition': 'value',
                            'bordersPosition': 'value',
                            'brd': 'value',
                            'brd2': 'value',
                            'innerLineSize': 'value',
                            'lineGap': 'value',
                            'outerLineSize': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.BgImageStrip',
                    'pageId': '',
                    'compId': 'i5za8k6o',
                    'styleType': 'custom',
                    'skin': 'skins.viewer.bgimagestrip.DoubleBorderScreenSkin'
                },
                'id': 'i5za8k6o'
            },
            'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 540}, 'label': '', 'tags': null}
        }, {
            'id': 'Box_Strips_8',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.bgimagestrip.BgImageStripSkin',
                'layout': {
                    'width': 1905,
                    'height': 360,
                    'x': 0,
                    'y': 3195,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.BgImageStrip',
                'props': {
                    'type': 'BgImageStripUnifiedProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fittingType': 'tile',
                    'alignType': 'center'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#68B04D',
                            'boxShadowToggleOn-shd': 'true',
                            'brd': '#FFFFFF',
                            'brwb': '0px',
                            'brwt': '0px',
                            'shd': '0px 0px 10px 1px rgba(0,0,0,0.5)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'brd': 'value',
                            'brwb': 'value',
                            'brwt': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.BgImageStrip',
                    'pageId': '',
                    'compId': 'i5zaar3b',
                    'styleType': 'custom',
                    'skin': 'skins.viewer.bgimagestrip.BgImageStripSkin'
                },
                'id': 'i5zaar3b'
            },
            'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 630}, 'label': '', 'tags': null}
        }, {
            'id': 'Box_Strips_9',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.bgimagestrip.BgImageStripSkin',
                'layout': {
                    'width': 1905,
                    'height': 360,
                    'x': 0,
                    'y': 3645,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.BgImageStrip',
                'props': {
                    'type': 'BgImageStripUnifiedProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fittingType': 'tile',
                    'alignType': 'center'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#1C8A43',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#68B04D',
                            'brwb': '0px',
                            'brwt': '8px',
                            'shd': '0 0 5px rgba(0, 0, 0, 0.7)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'brd': 'value',
                            'brwb': 'value',
                            'brwt': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.BgImageStrip',
                    'pageId': '',
                    'compId': 'i5zadbdk',
                    'styleType': 'custom',
                    'skin': 'skins.viewer.bgimagestrip.BgImageStripSkin'
                },
                'id': 'i5zadbdk'
            },
            'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 720}, 'label': '', 'tags': null}
        }, {
            'id': 'Box_Strips_10',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.bgimagestrip.BevelScreenSkin',
                'layout': {
                    'width': 1905,
                    'height': 360,
                    'x': 0,
                    'y': 4095,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.BgImageStrip',
                'props': {
                    'type': 'BgImageStripUnifiedProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fittingType': 'tile',
                    'alignType': 'center'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#01B1AF',
                            'boxShadowToggleOn-shd': 'true',
                            'brd': '#FFFFFF',
                            'brwb': '0px',
                            'brwt': '0px',
                            'shd': 'inset 0 1px 1px rgba(255, 255, 255, 0.6), inset 0 -1px 1px rgba(0, 0, 0, 0.6), 0 0 5px rgba(0, 0, 0, 0.6);'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'brd': 'value',
                            'brwb': 'value',
                            'brwt': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.BgImageStrip',
                    'pageId': '',
                    'compId': 'i5zaempz',
                    'styleType': 'custom',
                    'skin': 'skins.viewer.bgimagestrip.BevelScreenSkin'
                },
                'id': 'i5zaempz'
            },
            'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 810}, 'label': '', 'tags': null}
        }, {
            'id': 'Box_Strips_11',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.bgimagestrip.DoubleBorderScreenSkin',
                'layout': {
                    'width': 1905,
                    'height': 360,
                    'x': 0,
                    'y': 4545,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.BgImageStrip',
                'props': {
                    'type': 'BgImageStripUnifiedProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'fittingType': 'tile',
                    'alignType': 'center'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'alpha-brd2': '1',
                            'bg': '#006C6D',
                            'bgPosition': '0px',
                            'bordersPosition': '15px',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#FFFFFF',
                            'brd2': '#FFFFFF',
                            'innerLineSize': '3px',
                            'lineGap': '12px',
                            'outerLineSize': '6px',
                            'shd': '0px 0px 5px 0px rgba(0,0,0,0.7)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgPosition': 'value',
                            'bordersPosition': 'value',
                            'brd': 'value',
                            'brd2': 'value',
                            'innerLineSize': 'value',
                            'lineGap': 'value',
                            'outerLineSize': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.BgImageStrip',
                    'pageId': '',
                    'compId': 'i5zaf6mk',
                    'styleType': 'custom',
                    'skin': 'skins.viewer.bgimagestrip.DoubleBorderScreenSkin'
                },
                'id': 'i5zaf6mk'
            },
            'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 900}, 'label': '', 'tags': null}
        }],
        'compTypes': ['wysiwyg.viewer.components.BgImageStrip']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_strip'}
}
