// @ts-nocheck
import _ from 'lodash';

import keyboardShortcutsActionMap from '@/keyboardShortcuts';

import editorDebugShortcutsUtil from '../util/editorDebugShortcutsUtil';
import {
  copyComponentsFromKeyboard,
  cutComponentFromKeyboard,
  duplicateComponentFromKeyboard,
} from './editorShortcuts';

const EDITOR_SHORTCUTS_CONFIG = {};

const keyboardMap = keyboardShortcutsActionMap.editor;

//TODO: implement BI for all shortcuts in comments
function addEditorShortcuts(editorAPI) {
  const keysToMerge = {};
  keysToMerge[keyboardMap.GROUP_COMPONENTS.key] = futureBIFn;
  keysToMerge[keyboardMap.UNGROUP_COMPONENTS.key] = futureBIFn;
  keysToMerge[keyboardMap.COPY.key] = copyComponentsFromKeyboard.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.CUT.key] = cutComponentFromKeyboard.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.DUPLICATE.key] = duplicateComponentFromKeyboard.bind(
    null,
    editorAPI,
  );
  keysToMerge[keyboardMap.MOVE_RIGHT.key] = futureBIFn;
  keysToMerge[keyboardMap.MOVE_LEFT.key] = futureBIFn;
  keysToMerge[keyboardMap.MOVE_UP.key] = futureBIFn;
  keysToMerge[keyboardMap.MOVE_DOWN.key] = futureBIFn;
  keysToMerge[keyboardMap.SHIFT_RIGHT.key] = futureBIFn;
  keysToMerge[keyboardMap.SHIFT_LEFT.key] = futureBIFn;
  keysToMerge[keyboardMap.SHIFT_UP.key] = futureBIFn;
  keysToMerge[keyboardMap.SHIFT_DOWN.key] = futureBIFn;
  const debugShortcuts = editorDebugShortcutsUtil(editorAPI);
  _.merge(keysToMerge, debugShortcuts);
  _.merge(EDITOR_SHORTCUTS_CONFIG, keysToMerge);
}

//TODO: implement BI for all shortcuts in comments
function futureBIFn(evt) {
  evt.preventDefault();
}

export default {
  init(editorAPI) {
    addEditorShortcuts(editorAPI);
  },
  shortcuts: EDITOR_SHORTCUTS_CONFIG,
};
