const APPOINTMENT_FIELD_APP_ID = '3e950e28-b054-4df6-ad7b-9e28ffc5072e';

const CONTROLLER_TYPES = {
  APPOINTMENT_FIELD: 'appointmentField',
};
const imageStyle = { height: '100%', width: '100%' };

const AppointmentPreset = {
  PRESET1: 'preset1',
  PRESET2: 'preset2',
  PRESET3: 'preset3',
  PRESET4: 'preset4',
  PRESET5: 'preset5',
  PRESET6: 'preset6',
};

export default {
  APPOINTMENT_FIELD_APP_ID,
  componentType: 'wysiwyg.viewer.components.RefComponent',
  dummyComponentType: 'mobile.core.components.Container',
  skin: 'skins.core.InlineSkin',
  CONTROLLER_TYPES,
  imageStyle,
  AppointmentPreset,
};
