import * as stateManagement from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';

const { isInInteractionMode } = stateManagement.interactions.selectors;
const { exitInteractionModeIfNeeded } = stateManagement.interactions.actions;

const BI_ORIGIN = 'editorTour';

export const exitModes = async (editorAPI: EditorAPI) => {
  const state = editorAPI.store.getState();
  const isZoomMode = editorAPI.zoomMode.isInZoomMode();
  const inMobileMode = editorAPI.isMobileEditor();
  const isMultiLingMode = editorAPI.language.multilingual.isEnabled();
  const isInteractionMode = isInInteractionMode(state);
  const isPreviewMode = stateManagement.preview.selectors.getPreviewMode(state);
  const isVeloModeEnabled = editorAPI.developerMode.isEnabled();

  editorAPI.panelManager.closeAllPanels();

  if (isPreviewMode) {
    await editorAPI.preview.togglePreview(undefined, {
      biParams: { origin: BI_ORIGIN },
    });
  }

  if (inMobileMode) {
    editorAPI.setEditorMode(false, BI_ORIGIN);
  }

  if (isZoomMode) {
    await editorAPI.zoomMode.exitZoomMode({
      biParams: { origin: BI_ORIGIN },
    });
  }

  if (isInteractionMode) {
    exitInteractionModeIfNeeded(editorAPI);
  }

  if (isMultiLingMode) {
    const primaryLang = editorAPI.language.original.get().code;
    const currentSecondaryLng = editorAPI.language.current.get();

    if (primaryLang !== currentSecondaryLng) {
      editorAPI.store.dispatch(
        stateManagement.multilingual.actions.switchToOriginalLanguageThunk(),
      );
    }
  }
  if (isVeloModeEnabled) {
    if (editorAPI.developerMode.ui.treePane.isOpen()) {
      editorAPI.developerMode.ui.treePane.toggle(() => {});
    }
    // @ts-expect-error
    editorAPI.developerMode.ui.idePane.minimize();
  }
};
