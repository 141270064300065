import { findClosest } from './utils';

import type { MobileComponent } from '../../types';

export function calculateMobileParents(
  allMobileChildren: MobileComponent[],
  parentsLength: number,
): MobileComponent[] {
  const children = [...allMobileChildren];
  const maxItemIndex = children.length - 1;

  for (let parentIndex = 0; parentIndex <= parentsLength; parentIndex++) {
    let lineStart: number;
    let lineEnd: number;
    let gapStart: number;
    let gapEnd: number;
    let shouldCalcGap: boolean = false;

    // calculate solid line order of one parent children, takeover gaps and alone child if needed
    children.forEach((item, itemIndex) => {
      const itemParentIndex = item.mobileParentIndex ?? item.desktopParentIndex;

      // calc gap vs start-end comparison is the main logic of calculation!
      if (
        (itemParentIndex !== parentIndex || itemIndex === maxItemIndex) &&
        shouldCalcGap
      ) {
        shouldCalcGap = false;

        if ((lineEnd - lineStart) / 2 > gapEnd - gapStart) {
          // takeover gap
          gapStart = undefined;
          gapEnd = undefined;
        } else {
          // line is the biggest part around the gap edges
          // eslint-disable-next-line no-lonely-if
          if (gapStart - lineStart > lineEnd - gapEnd) {
            lineEnd = gapStart - 1;
          } else {
            lineStart = gapEnd + 1;
            gapStart = undefined;
            gapEnd = undefined;
          }
        }

        for (let i = lineStart; i <= lineEnd; i++) {
          children[i].mobileParentIndex = parentIndex;
        }
      }

      if (itemParentIndex === parentIndex) {
        if (lineStart === undefined) {
          lineStart = itemIndex;
        }

        lineEnd = itemIndex;

        if (gapEnd > 0) {
          shouldCalcGap = true;
        }
      }

      if (itemParentIndex !== parentIndex && lineStart !== undefined) {
        if (gapStart === undefined) {
          gapStart = itemIndex;
        }
        gapEnd = itemIndex;
      }

      if (itemIndex === maxItemIndex && !shouldCalcGap) {
        for (let i = lineStart; i <= lineEnd; i++) {
          children[i].mobileParentIndex = parentIndex;
        }
      }
    });
  }

  // process uncalculated children
  children.forEach((item, itemIndex) => {
    if (item.keepParentIndex) {
      item.mobileParentIndex = item.keepParentIndex;
    } else if (item.mobileParentIndex === undefined) {
      // attach children to closest parent
      const closestItemWithMobileParent = findClosest(
        children,
        (i: MobileComponent) => i.mobileParentIndex !== undefined,
        itemIndex,
      );

      item.mobileParentIndex =
        closestItemWithMobileParent?.mobileParentIndex ?? 0;
    }
  });

  return children;
}
