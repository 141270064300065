'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_moregalleries',
    'subNavigationTitle': 'add_section_label_moregalleries',
    'presetTitle': 'add_section_label_moregalleries',
    'tooltipTitle': 'add_section_label_moregalleries',
    "automationId": "add-panel-section-moreGalleriesSection",
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.VIDEO,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/moreGalleriesSection_en/moreGalleriesSection_en.png',
        "retinaImage": {
            "src": "addPanelData/sections/moreGalleriesSection_en/moreGalleriesSection_en-@2x.png",
            "dimensions": {
                "width": 648,
                "height": 1148
            }
        },
        'imageHover': null,
        'items': [{
            'id': 'Gallery_More_Galleries_1',
            'structure': {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.TPAHoneycombSkin",
                "layout": {
                    "width": 586,
                    "height": 701,
                    "x": 0,
                    "y": 2,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "tpa.viewer.components.Honeycomb",
                "data": {
                    "type": "ImageList",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "items": [
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_e642e63607944440bc13a8a2ea61aefe~mv2_d_3000_1941_s_2.jpg",
                            "width": 3000,
                            "height": 1941,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_89a9544354da4226913c3727baf2a742~mv2_d_3000_1688_s_2.jpg",
                            "width": 3000,
                            "height": 1688,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_a2db3b22be0a4790a1eda133c543fb3c~mv2_d_2869_3586_s_4_2.jpg",
                            "width": 2869,
                            "height": 3586,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_bc3841f1dee44abf840a9a1b33591002~mv2_d_3000_1995_s_2.jpg",
                            "width": 3000,
                            "height": 1995,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_3352e742a2e5467aad7df422106fc278~mv2_d_3000_2000_s_2.jpg",
                            "width": 3000,
                            "height": 2000,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_6a8a65a12f0e48c8bf74c70634ed2e9a~mv2_d_3000_2000_s_2.jpg",
                            "width": 3000,
                            "height": 2000,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_2790fd3a23db403793dbf2c3c1c62723~mv2_d_3000_1946_s_2.jpg",
                            "width": 3000,
                            "height": 1946,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_f1ab68320b444a4ca3cc0b7dd2284bf5~mv2_d_3000_4500_s_4_2.jpg",
                            "width": 3000,
                            "height": 4500,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_24a35ec53c4a4d41a0da9c7b7cd6d2d3~mv2_d_3000_1965_s_2.jpg",
                            "width": 3000,
                            "height": 1965,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_bbd372e536434c859bf040766757df4a~mv2_d_3000_3000_s_4_2.jpg",
                            "width": 3000,
                            "height": 3000,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_daeb574706844015abbd510c472010a8~mv2_d_3000_2000_s_2.jpg",
                            "width": 3000,
                            "height": 2000,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_23ba699582354676ba0ab793abd1a23f~mv2_d_8192_7754_s_4_2.jpg",
                            "width": 8192,
                            "height": 7754,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_99f5cd6c9b6b4899aef6c2e486e291c7~mv2_d_3000_2930_s_4_2.jpg",
                            "width": 3000,
                            "height": 2930,
                            "alt": ""
                        }
                    ]
                },
                "props": {
                    "type": "HoneycombProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "expandEnabled": false,
                    "galleryImageOnClickAction": "zoomMode",
                    "font": "arial",
                    "alignText": "left",
                    "textMode": "titleAndDescription",
                    "margin": 15,
                    "numOfColumns": 3,
                    "numOfHoles": 2,
                    "layoutSeed": 50,
                    "rolloverAnimation": "colorOnly",
                    "imageShape": "square",
                    "imageScale": "x1"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-color1": "1",
                            "alpha-color2": "1",
                            "alpha-color3": "1",
                            "alpha-color4": "0.8",
                            "alpha-color5": "1",
                            "color1": "#30BDFF",
                            "color2": "#FFFFFF",
                            "color3": "#2F2E2E",
                            "color4": "#FFFFFF",
                            "color5": "#FFFFFF",
                            "version": "1"
                        },
                        "propertiesSource": {
                            "color1": "value",
                            "color2": "value",
                            "color3": "value",
                            "color4": "value",
                            "color5": "value",
                            "version": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "tpa.viewer.components.Honeycomb",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.TPAHoneycombSkin"
                },
                "mobileStructure": {
                    "layout": {
                        "width": 280,
                        "height": 291,
                        "x": 20,
                        "y": 10,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-jawjw09k",
                        "author": "duplicate"
                    }
                },
                "activeModes": {},
                'id': 'i5hylqvg'
            },
            'preset': {
                'rect': {'width': 162, 'height': 176, 'x': 0, 'y': 0},
                'label': 'add_preset_galleries_honeycomb_diamonds_label',
                'tags': null
            }
        }, {
            'id': 'Gallery_More_Galleries_2',
            'structure': {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.TPAHoneycombSkin",
                "layout": {
                    "width": 582,
                    "height": 623,
                    "x": 0,
                    "y": 5,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "tpa.viewer.components.Honeycomb",
                "data": {
                    "type": "ImageList",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "items": [
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_2790fd3a23db403793dbf2c3c1c62723~mv2_d_3000_1946_s_2.jpg",
                            "width": 3000,
                            "height": 1946,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_99f5cd6c9b6b4899aef6c2e486e291c7~mv2_d_3000_2930_s_4_2.jpg",
                            "width": 3000,
                            "height": 2930,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_e642e63607944440bc13a8a2ea61aefe~mv2_d_3000_1941_s_2.jpg",
                            "width": 3000,
                            "height": 1941,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_a2db3b22be0a4790a1eda133c543fb3c~mv2_d_2869_3586_s_4_2.jpg",
                            "width": 2869,
                            "height": 3586,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_23ba699582354676ba0ab793abd1a23f~mv2_d_8192_7754_s_4_2.jpg",
                            "width": 8192,
                            "height": 7754,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_daeb574706844015abbd510c472010a8~mv2_d_3000_2000_s_2.jpg",
                            "width": 3000,
                            "height": 2000,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_bc3841f1dee44abf840a9a1b33591002~mv2_d_3000_1995_s_2.jpg",
                            "width": 3000,
                            "height": 1995,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_bbd372e536434c859bf040766757df4a~mv2_d_3000_3000_s_4_2.jpg",
                            "width": 3000,
                            "height": 3000,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_24a35ec53c4a4d41a0da9c7b7cd6d2d3~mv2_d_3000_1965_s_2.jpg",
                            "width": 3000,
                            "height": 1965,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_3352e742a2e5467aad7df422106fc278~mv2_d_3000_2000_s_2.jpg",
                            "width": 3000,
                            "height": 2000,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_f1ab68320b444a4ca3cc0b7dd2284bf5~mv2_d_3000_4500_s_4_2.jpg",
                            "width": 3000,
                            "height": 4500,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_89a9544354da4226913c3727baf2a742~mv2_d_3000_1688_s_2.jpg",
                            "width": 3000,
                            "height": 1688,
                            "alt": ""
                        }
                    ]
                },
                "props": {
                    "type": "HoneycombProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "expandEnabled": false,
                    "galleryImageOnClickAction": "zoomMode",
                    "font": "arial",
                    "alignText": "left",
                    "textMode": "titleAndDescription",
                    "margin": 20,
                    "numOfColumns": 3,
                    "numOfHoles": 2,
                    "layoutSeed": 50,
                    "rolloverAnimation": "colorOnly",
                    "imageShape": "triangle",
                    "imageScale": "x1"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-color1": "1",
                            "alpha-color2": "1",
                            "alpha-color3": "1",
                            "alpha-color4": "0.8",
                            "alpha-color5": "1",
                            "color1": "#30BDFF",
                            "color2": "#FFFFFF",
                            "color3": "#2F2E2E",
                            "color4": "#FFFFFF",
                            "color5": "#FFFFFF",
                            "version": "1"
                        },
                        "propertiesSource": {
                            "color1": "value",
                            "color2": "value",
                            "color3": "value",
                            "color4": "value",
                            "color5": "value",
                            "version": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "tpa.viewer.components.Honeycomb",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.TPAHoneycombSkin"
                },
                "mobileStructure": {
                    "layout": {
                        "width": 280,
                        "height": 291,
                        "x": 20,
                        "y": 10,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-jawjwlwj",
                        "author": "duplicate"
                    }
                },
                "activeModes": {},
                'id': 'i5hylqvd_0'
            },
            'preset': {
                'rect': {'width': 162, 'height': 176, 'x': 162, 'y': 0},
                'label': 'add_preset_galleries_honeycomb_triangle_label',
                'tags': null
            }
        }, {
            'id': 'Gallery_More_Galleries_3',
            'structure': {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.TPAImpressSkin",
                "layout": {
                    "width": 1142,
                    "height": 312,
                    "x": -81,
                    "y": 116,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "tpa.viewer.components.Impress",
                "data": {
                    "type": "ImageList",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "items": [
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_2790fd3a23db403793dbf2c3c1c62723~mv2_d_3000_1946_s_2.jpg",
                            "description": "Describe your image",
                            "width": 3000,
                            "height": 1946,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_24a35ec53c4a4d41a0da9c7b7cd6d2d3~mv2_d_3000_1965_s_2.jpg",
                            "description": "Describe your image",
                            "width": 3000,
                            "height": 1965,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_bbd372e536434c859bf040766757df4a~mv2_d_3000_3000_s_4_2.jpg",
                            "description": "Describe your image",
                            "width": 3000,
                            "height": 3000,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_e642e63607944440bc13a8a2ea61aefe~mv2_d_3000_1941_s_2.jpg",
                            "description": "Describe your image",
                            "width": 3000,
                            "height": 1941,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_bc3841f1dee44abf840a9a1b33591002~mv2_d_3000_1995_s_2.jpg",
                            "description": "Describe your image",
                            "width": 3000,
                            "height": 1995,
                            "alt": ""
                        }
                    ]
                },
                "props": {
                    "type": "ImpressProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "font": "palatinoLinotype",
                    "textMode": "titleAndDescription",
                    "alignText": "left",
                    "autoplayInterval": 3,
                    "autoplay": true,
                    "galleryImageOnClickAction": "disabled",
                    "cropAndFitStage": "crop",
                    "transition": "none",
                    "showPagination": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-color1": "1",
                            "alpha-color2": "1",
                            "alpha-color3": "1",
                            "alpha-color4": "1",
                            "alpha-color5": "1",
                            "alpha-color6": "1",
                            "alpha-color7": "1",
                            "alpha-color8": "1",
                            "color1": "#F7D2D3",
                            "color2": "#D0F2D8",
                            "color3": "#FFF096",
                            "color4": "#C1EFEE",
                            "color5": "#FFDEB3",
                            "color6": "color_15",
                            "color7": "color_14",
                            "color8": "color_13"
                        },
                        "propertiesSource": {
                            "color1": "value",
                            "color2": "value",
                            "color3": "value",
                            "color4": "value",
                            "color5": "value",
                            "color6": "theme",
                            "color7": "theme",
                            "color8": "theme"
                        },
                        "groups": {}
                    },
                    "componentClassName": "tpa.viewer.components.Impress",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.TPAImpressSkin"
                },
                "mobileStructure": {
                    "layout": {
                        "width": 280,
                        "height": 93,
                        "x": 20,
                        "y": 10,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-jawjx16q",
                        "author": "duplicate"
                    }
                },
                "activeModes": {},
                'id': 'i5hylqvi'
            },
            'preset': {
                'rect': {'width': 324, 'height': 118, 'x': 0, 'y': 176},
                'label': 'add_preset_galleries_slide_label',
                'tags': null
            }
        }, {
            'id': 'Gallery_More_Galleries_4',
            'structure': {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.TPAFreestyleSkin",
                "layout": {
                    "width": 660,
                    "height": 600,
                    "x": 120,
                    "y": 52,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "tpa.viewer.components.Freestyle",
                "data": {
                    "type": "ImageList",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "items": [
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_24a35ec53c4a4d41a0da9c7b7cd6d2d3~mv2_d_3000_1965_s_2.jpg",
                            "width": 3000,
                            "height": 1965,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_2790fd3a23db403793dbf2c3c1c62723~mv2_d_3000_1946_s_2.jpg",
                            "width": 3000,
                            "height": 1946,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_bc3841f1dee44abf840a9a1b33591002~mv2_d_3000_1995_s_2.jpg",
                            "width": 3000,
                            "height": 1995,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_bbd372e536434c859bf040766757df4a~mv2_d_3000_3000_s_4_2.jpg",
                            "width": 3000,
                            "height": 3000,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_3352e742a2e5467aad7df422106fc278~mv2_d_3000_2000_s_2.jpg",
                            "width": 3000,
                            "height": 2000,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_89a9544354da4226913c3727baf2a742~mv2_d_3000_1688_s_2.jpg",
                            "width": 3000,
                            "height": 1688,
                            "alt": ""
                        }
                    ]
                },
                "props": {
                    "type": "FreestyleProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "galleryImageOnClickAction": "zoomMode",
                    "layoutSeed": 50,
                    "cropAndFitStage": "fit",
                    "imageDecoration": "none",
                    "borderWidth": 0,
                    "orientation": "horizontal",
                    "applyRotationInLayout": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-color1": "1",
                            "alpha-color2": "1",
                            "alpha-color3": "1",
                            "alpha-color4": "1",
                            "alpha-color5": "1",
                            "color1": "#2F2E2E",
                            "color2": "#2F2E2E",
                            "color3": "#2F2E2E",
                            "color4": "#2F2E2E",
                            "color5": "#2F2E2E",
                            "version": "1"
                        },
                        "propertiesSource": {
                            "color1": "value",
                            "color2": "value",
                            "color3": "value",
                            "color4": "value",
                            "color5": "value",
                            "version": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "tpa.viewer.components.Freestyle",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.TPAFreestyleSkin"
                },
                "mobileStructure": {
                    "layout": {
                        "width": 280,
                        "height": 171,
                        "x": 20,
                        "y": 10,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-jawjxc1a",
                        "author": "duplicate"
                    }
                },
                "activeModes": {},
                'id': 'i5hylqvi'
            },
            'preset': {
                'rect': {'width': 162, 'height': 150, 'x': 0, 'y': 294},
                'label': 'add_preset_galleries_freestyle_label',
                'tags': null
            }
        }, {
            'id': 'Gallery_More_Galleries_5',
            'structure': {
                "type": "Component",
                "layout": {
                    "width": 670,
                    "height": 530,
                    "x": 155,
                    "y": 7,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.tpapps.TPA3DGallery",
                "data": {
                    "type": "ImageList",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "items": [
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_99f5cd6c9b6b4899aef6c2e486e291c7~mv2_d_3000_2930_s_4_2.jpg",
                            "width": 3000,
                            "height": 2930,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_2790fd3a23db403793dbf2c3c1c62723~mv2_d_3000_1946_s_2.jpg",
                            "width": 3000,
                            "height": 1946,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_24a35ec53c4a4d41a0da9c7b7cd6d2d3~mv2_d_3000_1965_s_2.jpg",
                            "width": 3000,
                            "height": 1965,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_bc3841f1dee44abf840a9a1b33591002~mv2_d_3000_1995_s_2.jpg",
                            "width": 3000,
                            "height": 1995,
                            "alt": ""
                        }
                    ]
                },
                "props": {
                    "type": "SlideShowGalleryProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "expandEnabled": true,
                    "galleryImageOnClickAction": "disabled",
                    "goToLinkText": "Go to link",
                    "imageMode": "clipImage",
                    "transition": "swipeHorizontal",
                    "autoplayInterval": 3,
                    "autoplay": false,
                    "transDuration": 1,
                    "bidirectional": true,
                    "reverse": false,
                    "showAutoplay": true,
                    "showNavigation": true,
                    "showCounter": true,
                    "showExpand": false,
                    "showSocial": false,
                    "alignText": "left"
                },
                "style": "tfg1",
                "mobileStructure": {
                    "layout": {
                        "width": 280,
                        "height": 222,
                        "x": 20,
                        "y": 10,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-jawjxmo6",
                        "author": "duplicate"
                    }
                },
                "activeModes": {},
                'id': 'i5hylqvi'
            },
            'preset': {
                'rect': {'width': 162, 'height': 150, 'x': 162, 'y': 294},
                'label': 'add_preset_galleries_3D_slideshow_label',
                'tags': null
            }
        }, {
            'id': 'Gallery_More_Galleries_6',
            'structure': {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.TPA3DCarouselSkin",
                "layout": {
                    "width": 1216,
                    "height": 401,
                    "x": -118,
                    "y": 71,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.tpapps.TPA3DCarousel",
                "data": {
                    "type": "ImageList",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "items": [
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_24a35ec53c4a4d41a0da9c7b7cd6d2d3~mv2_d_3000_1965_s_2.jpg",
                            "width": 3000,
                            "height": 1965,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_f1ab68320b444a4ca3cc0b7dd2284bf5~mv2_d_3000_4500_s_4_2.jpg",
                            "width": 3000,
                            "height": 4500,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_2790fd3a23db403793dbf2c3c1c62723~mv2_d_3000_1946_s_2.jpg",
                            "width": 3000,
                            "height": 1946,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_bc3841f1dee44abf840a9a1b33591002~mv2_d_3000_1995_s_2.jpg",
                            "width": 3000,
                            "height": 1995,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_bbd372e536434c859bf040766757df4a~mv2_d_3000_3000_s_4_2.jpg",
                            "width": 3000,
                            "height": 3000,
                            "alt": ""
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "Add a Title",
                            "uri": "b25591_89a9544354da4226913c3727baf2a742~mv2_d_3000_1688_s_2.jpg",
                            "width": 3000,
                            "height": 1688,
                            "alt": ""
                        }
                    ]
                },
                "props": {
                    "type": "SlideShowGalleryProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "expandEnabled": true,
                    "galleryImageOnClickAction": "disabled",
                    "goToLinkText": "Go to link",
                    "imageMode": "clipImage",
                    "transition": "swipeHorizontal",
                    "autoplayInterval": 3,
                    "autoplay": true,
                    "transDuration": 1,
                    "bidirectional": true,
                    "reverse": false,
                    "showAutoplay": true,
                    "showNavigation": true,
                    "showCounter": true,
                    "showExpand": false,
                    "showSocial": false,
                    "alignText": "left"
                },
                "style": "tfc1",
                "mobileStructure": {
                    "layout": {
                        "width": 280,
                        "height": 92,
                        "x": 20,
                        "y": 10,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-jawjxx4h",
                        "author": "duplicate"
                    }
                },
                "activeModes": {},
                'id': 'i5hylqvi'
            },
            'preset': {
                'rect': {'width': 324, 'height': 130, 'x': 0, 'y': 444},
                'label': 'add_preset_galleries_3D_carousel_label',
                'tags': null
            }
        }],
        'compTypes': ['tpa.viewer.components.Honeycomb', 'tpa.viewer.components.Impress', 'tpa.viewer.components.Freestyle', 'wysiwyg.viewer.components.tpapps.TPA3DCarousel', 'wysiwyg.viewer.components.tpapps.TPA3DGallery']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_moregalleries'}
}
