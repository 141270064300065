import { LayoutConstraintsUtil } from '@/layoutUtils';
import { getAnchorLayoutConstraint } from './anchorLayout';
import { footerLayoutConstraint } from './footerLayout';
import { headerLayoutConstraint } from './headerLayout';
import { sectionLayoutConstraint } from './sectionLayout';

export const createLayoutConstraintsService = () => {
  // TODO add scopes and fix unit
  const constraints = {
    section: sectionLayoutConstraint,
    anchor: getAnchorLayoutConstraint(),
    header: headerLayoutConstraint,
    footer: footerLayoutConstraint,
  };

  return {
    bootstrap: () =>
      Object.values(constraints).forEach(
        LayoutConstraintsUtil.registerConstraint,
      ),
    destroy: () => {
      const { section, anchor } = constraints;
      LayoutConstraintsUtil.removeConstraint(section);
      LayoutConstraintsUtil.removeConstraint(anchor);
    },
  };
};
