import constants from '@/constants';

const { USER_PREFS } = constants;

export const NOTIFICATION_RATE = 4;

export const ORGANIZE_IMAGES_PANEL_NAME =
  'panels.focusPanels.organizeImagesPanel';
export const AVIARY_PANEL_NAME = 'panels.focusPanels.aviaryPanel';
export const PAGES_AND_POPUPS_PANEL_NAME = 'pages.pagesPanel';

export const OUT_OF_GRID_CONFIG = {
  FORBIDDEN_COMP_TYPES: [
    'wysiwyg.common.components.anchor.viewer.Anchor',
    'mobile.core.components.Page',
    'platform.components.AppController',
  ],
  DESKTOP: {
    NOTIFICATION_RATE: 4,
    DONT_SHOW_AGAIN_KEY:
      USER_PREFS.OUT_OF_GRID.DESKTOP.OUT_OF_GRID_DONT_SHOW_AGAIN,
    OUT_OF_GRID_COUNT_KEY: USER_PREFS.OUT_OF_GRID.DESKTOP.OUT_OF_GRID_COUNT,
    PANEL_NAME: 'panels.focusPanels.outOfGridDesktopPanel',
  },
  MOBILE: {
    NOTIFICATION_RATE: 10,
    DONT_SHOW_AGAIN_KEY:
      USER_PREFS.OUT_OF_GRID.MOBILE.OUT_OF_GRID_DONT_SHOW_AGAIN,
    OUT_OF_GRID_COUNT_KEY: USER_PREFS.OUT_OF_GRID.MOBILE.OUT_OF_GRID_COUNT,
    PANEL_NAME: 'panels.focusPanels.outOfGridMobilePanel',
  },
  CONTAINER: {
    NOTIFICATION_RATE: 4,
    DONT_SHOW_AGAIN_KEY:
      USER_PREFS.OUT_OF_GRID.CONTAINER.OUT_OF_GRID_DONT_SHOW_AGAIN,
    OUT_OF_GRID_COUNT_KEY: USER_PREFS.OUT_OF_GRID.CONTAINER.OUT_OF_GRID_COUNT,
    PANEL_NAME: 'panels.focusPanels.outOfContainerGridPanel',
  },
};
